"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "boxplot-chart"
  }, [_c("header", {
    staticClass: "boxplot-chart-title"
  }, [_vm._v(" " + _vm._s(_vm.eventName) + "情况 "), _c("div", {
    staticClass: "boxplot-chart-sub-title"
  }, [_vm._v(" 通过箱型图，来表示团队中" + _vm._s(_vm.eventName) + "能力的分布情况。 ")])]), _c("div", {
    ref: "boxplot-chart",
    staticClass: "boxplot",
    style: {
      height: _vm.height,
      width: _vm.width
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;