"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["knowledge-questions-content", {
      "full-screen": !_vm.isShowQuestionTricksLibrary
    }]
  }, [_c("div", {
    staticClass: "questions-content"
  }, [_c("div", {
    staticClass: "search-container"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("span", {
    staticClass: "left-title"
  }, [_vm._v(" 客户高频问题 ")]), _c("el-input", {
    attrs: {
      clearable: "",
      "prefix-icon": "el-icon-search",
      placeholder: "按关键词搜索问题"
    },
    on: {
      change: _vm.getQuestionList
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowQuestionTricksLibrary,
      expression: "!isShowQuestionTricksLibrary"
    }],
    staticClass: "right",
    attrs: {
      src: _vm.knowledgePointMiningEngine
    }
  })]), _c("div", {
    staticClass: "question-container"
  }, [_c("knowledge-questions-filter", {
    ref: "knowledge-questions-filter",
    staticClass: "knowledge-questions-filter",
    on: {
      change: _vm.handleFilterChange
    }
  }), _vm.questionList.length ? [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "question-list"
  }, _vm._l(_vm.questionList, function (item, index) {
    return _c("knowledge-question", {
      key: index,
      attrs: {
        question: item,
        "current-question": _vm.currentQuestion,
        "deal-all-count": _vm.dealAllCount
      },
      on: {
        questionClick: _vm.handleQuestionClick
      }
    }, [_c("i", {
      staticClass: "iconfont icon-eliminated",
      attrs: {
        slot: "hover-handler-button"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.ignoreQuestion(item);
        }
      },
      slot: "hover-handler-button"
    }, [_vm._v(" 忽略")])]);
  }), 1)] : !_vm.loading ? [_c("NoData", {
    attrs: {
      icon: _vm.searchValue.length ? "icon-a-Property1not-found" : "icon-a-Property1no-data",
      tips: _vm.searchValue.length ? "未搜索到相关结果" : _vm.$t("coaching.noContent")
    }
  })] : _vm._e()], 2)]), _vm.questionList.length ? _c("el-pagination", {
    attrs: {
      id: "knowledge-qa-list-pagination",
      layout: _vm.paginationLayout,
      "current-page": _vm.queryParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.queryParams.size,
      total: _vm.total,
      "pager-count": _vm.pagerCount
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.pageChange
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;