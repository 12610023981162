"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _ItemTemplate = _interopRequireDefault(require("./ItemTemplate.vue"));
var _KnowledgeItemAnswers = _interopRequireDefault(require("./KnowledgeItemAnswers.vue"));
var _KnowledgeItemTricks = _interopRequireDefault(require("./KnowledgeItemTricks.vue"));
var _KnowledgeMoveDialog = _interopRequireDefault(require("./KnowledgeMoveDialog.vue"));
var _DiscoveryEngine = _interopRequireDefault(require("./DiscoveryEngine.vue"));
var _KnowledgeOperation = _interopRequireDefault(require("./KnowledgeOperation.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import KnowledgeItemQuestions from './KnowledgeItemQuestions.vue';
var _default = {
  name: 'KnowledgeItem',
  components: {
    ItemTemplate: _ItemTemplate.default,
    // KnowledgeItemQuestions,
    KnowledgeItemAnswers: _KnowledgeItemAnswers.default,
    KnowledgeItemTricks: _KnowledgeItemTricks.default,
    KnowledgeMoveDialog: _KnowledgeMoveDialog.default,
    DiscoveryEngine: _DiscoveryEngine.default,
    KnowledgeOperation: _KnowledgeOperation.default
  },
  props: {
    knowledge: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moveDialogVisible: false,
      discoveryDialogVisible: false
    };
  },
  computed: {
    hasContent() {
      var _this$knowledge$quest, _this$knowledge$skill, _this$knowledge$scene;
      return ((_this$knowledge$quest = this.knowledge.question_list) === null || _this$knowledge$quest === void 0 ? void 0 : _this$knowledge$quest.length) || ((_this$knowledge$skill = this.knowledge.skill_list) === null || _this$knowledge$skill === void 0 ? void 0 : _this$knowledge$skill.length) || ((_this$knowledge$scene = this.knowledge.scene_reply_list) === null || _this$knowledge$scene === void 0 ? void 0 : _this$knowledge$scene.length);
    },
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission'])
  },
  methods: {
    operateKnowledge(operate) {
      switch (operate) {
        case 'recording-audio':
          this.recordingAudio();
          break;
        case 'edit':
          this.editKnowledge();
          break;
        case 'move':
          this.moveKnowledge();
          break;
        case 'delete':
          this.deleteKnowledge();
          break;
        default:
          break;
      }
    },
    recordingAudio() {
      this.$emit('recordingAudio', this.knowledge);
    },
    editKnowledge() {
      this.$emit('editKnowledge', this.knowledge);
    },
    deleteKnowledge() {
      this.$confirm('是否删除该知识点？', '删除知识点', {
        confirmButtonText: '删除',
        cancelButtonText: this.$t('coaching.cancel'),
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _knowledgeLibrary.deleteKnowledge)({
          id: this.knowledge.id,
          catalog_id: this.knowledge.catalog.id,
          space_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.$emit('deleteKnowledge');
        }
      });
    },
    moveKnowledge() {
      this.moveDialogVisible = true;
    },
    handleMoveSuccess(knowledgeId) {
      this.$emit('moveKnowledge', knowledgeId);
    },
    handleAddTricks() {
      this.$emit('addTricks');
    },
    openDiscoveryEngine() {
      this.discoveryDialogVisible = true;
    }
  }
};
exports.default = _default;