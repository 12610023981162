"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CommentInput = _interopRequireDefault(require("@/components/CommentInput"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vue = require("vue");
var _InsightClipSelector = _interopRequireDefault(require("./InsightClipSelector.vue"));
var _videoDetail = require("@/api/videoDetail");
var _library = require("@/api/library");
var _SelectedLabel = _interopRequireDefault(require("./SelectedLabel.vue"));
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EditCommentWidget',
  props: {
    comment: {
      type: Object,
      default: () => ({})
    },
    entityId: {
      type: Number,
      default: 0
    },
    entityType: {
      type: String,
      default: 'conversation'
    },
    isReply: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitSuccess', 'cancel'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const commentInputRef = (0, _vue.ref)(null);
    const insightClipSelectorRef = (0, _vue.ref)(null);
    const sendBtnLoading = (0, _vue.ref)(false);
    const sendComment = async () => {
      sendBtnLoading.value = true;
      const {
        content,
        imgs,
        mentionIds: mention_ids
      } = commentInputRef.value.getComment();
      if (!content) {
        _elementUi.Message.warning('评论内容不能为空');
        sendBtnLoading.value = false;
        return;
      }
      if (content.length > 1000) {
        this.$message.info('评论内容不能超过1000个字符, 当前字符数为' + content.length);
        return;
      }
      const proposals = getProposal();
      const params = {
        entity_id: props.entityId,
        content,
        imgs,
        mention_ids,
        open_scope: commentVisiblePermission.value ? 'some' : 'all',
        comment_id: props.comment.comment_id,
        proposals,
        visiable_users: [],
        visiable_trees: [],
        comment_timestamp: props.comment.comment_timestamp
      };
      // 商机的先不做
      const api = props.entityType === 'conversation' ? _videoDetail.editConversationComment : _library.editSnippetComment;
      const res = await api(params).finally(() => {
        sendBtnLoading.value = false;
      });
      if (res.code === 20000 || res.code === 200) {
        _elementUi.Message.success('修改成功');
        emit('submitSuccess', res.results);
      }
    };
    const cancel = () => {
      emit('cancel');
    };

    // 评论@权限
    const hasMention = (0, _vue.ref)(false);
    const commentVisiblePermission = (0, _vue.ref)(false);

    // 知识库和剪辑库的label
    const localProposal = (0, _vue.ref)([]);
    (0, _vue.onMounted)(() => {
      localProposal.value = (0, _cloneDeep.default)(props.comment.proposals);
    });
    const deleteProposal = index => {
      localProposal.value.splice(index, 1);
    };
    function getProposal() {
      let proposals = [];
      if (props.comment.advise === 'advisor') {
        const addedKnowledges = insightClipSelectorRef.value.getAddedKnowledges();
        const addedSnippets = insightClipSelectorRef.value.getAddedSnippets();
        proposals = [...localProposal.value, ...addedKnowledges, ...addedSnippets];
      } else {
        proposals = props.comment.proposals;
      }
      return proposals;
    }

    // 填充评论
    const fillComment = _comment => {
      const comment = (0, _cloneDeep.default)(_comment);
      commentInputRef.value.setComment(comment);
      // insightClipSelectorRef.value.fillProposals(comment.proposals);
      commentVisiblePermission.value = comment.open_scope === 'some';
    };
    expose({
      fillComment
    });
    return {
      __sfc: true,
      props,
      commentInputRef,
      insightClipSelectorRef,
      emit,
      sendBtnLoading,
      sendComment,
      cancel,
      hasMention,
      commentVisiblePermission,
      localProposal,
      deleteProposal,
      getProposal,
      fillComment,
      CommentInput: _CommentInput.default,
      InsightClipSelector: _InsightClipSelector.default,
      SelectedLabel: _SelectedLabel.default
    };
  }
};
exports.default = _default;