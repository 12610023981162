"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskPracticeCard = _interopRequireDefault(require("./TaskPracticeCard.vue"));
var _AudioRecorder = _interopRequireDefault(require("./AudioRecorder"));
var _recorderCore = _interopRequireDefault(require("recorder-core"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeContent',
  components: {
    AudioRecorder: _AudioRecorder.default,
    TaskPracticeCard: _TaskPracticeCard.default
  },
  props: {
    knowledge: {
      type: Object,
      default: () => ({})
    },
    loading: Boolean
  },
  data() {
    return {
      lowestScore: '',
      courseType: '',
      isScoring: false
    };
  },
  computed: {
    practiceRule() {
      const rule = this.courseType === 1 ? '朗读' : '背诵';
      return `${this.isCloseBookExam ? `闭卷考核，请根据提示${rule}完整话术` : `请${rule}以下话术`}${this.knowledge.repeat_count > 1 ? this.knowledge.repeat_count + '次' : ''}，且评分不低于 <span style="color:#E54D52;font-size: 16px">${this.lowestScore}</span> 分`;
    },
    isCloseBookExam() {
      return this.knowledge.is_closed_exam && this.courseType === 2;
    }
  },
  created() {
    const taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query') || '{}');
    this.lowestScore = taskConfig.score || 0;
    this.courseType = Number(taskConfig.type);
  },
  methods: {
    // 点击停止录制按钮
    stopRecord() {
      this.isScoring = true;
    },
    // 当前题目评分完成
    scoreFinished() {
      this.isScoring = false;
      this.$emit('scoreFinished');
    },
    jumpNextKnowledge: (0, _commonFunc.debounce)(function (operate) {
      if (_recorderCore.default.IsOpen()) {
        this.$message.warning('当前正在录音，请关闭录音后再尝试');
        return;
      }
      // 已经截止的任务
      if (this.knowledge.status === 1) {
        if (operate === 'next') {
          this.$emit('click-jump', 'next');
        } else {
          // 跳转任务详情页面
          this.$router.go(-1);
        }
      } else {
        // 未截止的练习任务，点击下一步和完成时判断是否有未完成的题目
        this.handlePracticeOperate(operate);
      }
    }, 500, true),
    handlePracticeOperate(operate) {
      if (this.isScoring) {
        this.$message({
          message: '录音上传中',
          type: 'warning'
        });
        return;
      }
      const incompleteIndex = this.$refs.taskPracticeCard.judgeIncompletePractice();
      if (incompleteIndex !== -1) {
        this.$message({
          message: '还有未完成的练习',
          type: 'warning'
        });
        this.scrollToIncompleteCard(incompleteIndex);
      } else {
        if (operate === 'next') {
          this.$emit('click-jump', 'next');
        } else {
          this.$emit('finish');
        }
      }
    },
    scrollToIncompleteCard(index) {
      const top = this.$refs.taskPracticeCard.$refs.trickItem[index].offsetTop;
      this.$refs.cardContainer.scrollTo({
        top,
        behavior: 'smooth'
      });
    },
    jumpLastKnowledge: (0, _commonFunc.debounce)(function () {
      this.isScoring = false;
      this.$emit('click-jump', 'last');
    }, 500, true)
  }
};
exports.default = _default;