"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ai-customer-effect-test"
  }, [_c("header", {
    staticClass: "effect-test__header"
  }, [_c("h1", {
    staticClass: "effect-test-title"
  }, [_vm._v("效果测试")]), _c("div", [_c("MgvTag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isShowAskRestartModel,
      expression: "isShowAskRestartModel"
    }],
    attrs: {
      size: 20,
      "max-width": "200px"
    }
  }, [_vm._v("配置有更新，点此重新测试 👉")]), _c("MgvButton", {
    attrs: {
      icon: "icon-refresh-left",
      square: "",
      color: "info",
      type: "text",
      size: "medium",
      "tooltip-content": "重新测试"
    },
    on: {
      click: _setup.restart
    }
  })], 1)]), _c("div", {
    staticClass: "effect-test-content"
  }, [_c("div", {
    ref: "conversationContainerRef",
    staticClass: "conversation-container"
  }, [_vm._m(0), _vm._l(_setup.sentenceList, function (_ref, index) {
    let {
      content,
      type
    } = _ref;
    return _c(_setup.EffectTestSentenceItem, {
      key: index,
      attrs: {
        sentence: content,
        "avatar-url": _vm.promptConfig.avatar_url,
        type: type
      }
    });
  })], 2), _c("div", {
    staticClass: "chat-input-container"
  }, [_c(_setup.ChatInput, {
    on: {
      change: _setup.onChatInputChange
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isShowStartTest,
      expression: "isShowStartTest"
    }],
    staticClass: "ask-restart-model"
  }, [_c("p", [_vm._v("请测试提示词 / 关键问题符合预期后再发布课程")]), _c("div", {
    staticClass: "btn-group"
  }, [_c("MgvButton", {
    attrs: {
      size: "medium"
    },
    on: {
      click: _setup.restart
    }
  }, [_vm._v("开始测试")])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "start-sentence"
  }, [_c("span"), _vm._v(" 开始 "), _c("span")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;