"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    staticClass: "log-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data,
      height: _vm.tableHeight,
      "default-sort": {
        prop: "operate_time",
        order: "descending"
      }
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "module",
      label: "操作模块",
      width: "200px"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "operate_time",
      label: "操作时间",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "200px"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "操作类型",
      width: "100px"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "operator",
      label: "操作人",
      width: "200px"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "detail",
      label: "操作内容",
      "show-overflow-tooltip": ""
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;