"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TreeSelect = _interopRequireDefault(require("./TreeSelect.vue"));
var _ReminderSelect = _interopRequireDefault(require("@/components/TaskCenterDrawer/components/ReminderSelect.vue"));
var _MemberGroupOperation = _interopRequireDefault(require("./MemberGroupOperation.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _routeMap = require("@/router/routeMap");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _timezone = require("@/utils/timezone");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateTaskDrawer',
  components: {
    TreeSelect: _TreeSelect.default,
    ReminderSelect: _ReminderSelect.default,
    MemberGroupOperation: _MemberGroupOperation.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [String, Number],
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    hasLink: {
      type: Boolean,
      default: false
    },
    currentCourseInfo: {
      type: Object,
      default: () => ({})
    },
    taskStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateCourseIds = (rule, value, callback) => {
      const currentValue = value || this.copyCourseIds;
      if (!currentValue.length) {
        callback(new Error(this.$t('coaching.pleaseSelectCourse')));
      } else if (currentValue.length > 20) {
        callback(new Error(this.$t('coaching.numberOfCoursesCannotExceed', [20])));
      } else {
        callback();
      }
    };
    const validateExecutor = (rule, value, callback) => {
      const currentValue = [...(value.tree_ids || []), ...(value.user_ids || [])];
      if (!currentValue.length) {
        callback(new Error(this.$t('coaching.pleaseSelectExecutor')));
      } else {
        callback();
      }
    };
    return {
      submitLoading: false,
      copyCourseIds: [],
      taskInfoConfig: {
        name: '',
        course_ids: [],
        course_name: '',
        executor: {},
        start_time: '',
        end_time: '',
        category_ids: []
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('coaching.pleaseEnterTaskName'),
          trigger: 'change'
        }],
        course_ids: [{
          validator: validateCourseIds
        }],
        executor: [{
          validator: validateExecutor
        }],
        start_time: [{
          required: true,
          message: this.$t('coaching.pleaseSelectTheTime'),
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: this.$t('coaching.pleaseSelectTheTime'),
          trigger: 'blur'
        }]
      },
      loading: false,
      numberList: [],
      defaultReminderTime: '',
      reminderInfo: {
        reminderAt: 0,
        reminderLabel: ''
      },
      taskCategoryList: []
    };
  },
  computed: {
    title() {
      return !this.taskId ? this.$t('coaching.newTask') : this.isEdit ? this.$t('coaching.editTask') : this.$t('coaching.copyTask');
    },
    strartPickerOptions() {
      const _this = this;
      return {
        disabledDate(date) {
          if (_this.taskInfoConfig.end_time) {
            // 开始时间不早于结束时间
            return !_this.$moment(date).isBetween(_this.$moment().subtract(1, 'day'), _this.taskInfoConfig.end_time, null, '[]');
          }
          // 开始时间不早于今天
          return _this.$moment().isAfter(date, 'day');
        }
      };
    },
    endPickerOptions() {
      const _this = this;
      return {
        disabledDate(date) {
          if (_this.taskInfoConfig.start_time) {
            return _this.$moment(date).isBefore(_this.taskInfoConfig.start_time, 'day');
          }
          return _this.$moment().isAfter(date, 'day');
        }
      };
    },
    disabledContent() {
      return function (gist_status) {
        const contentMap = {
          1: this.$t('coaching.scoringPointsAreBeingGenerated'),
          2: this.$t('coaching.unconfiguredScoringRules')
        };
        return contentMap[gist_status];
      };
    },
    ...(0, _vuex.mapGetters)(['userId'])
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.taskId && this.getCoachingTaskInfo();
          this.getTaskCategoryList();
          // 课程处新建任务
          const {
            name,
            id
          } = this.currentCourseInfo;
          if (id) {
            this.taskInfoConfig.name = name;
            this.copyCourseIds = [id];
            this.taskInfoConfig.course_ids = [id];
            this.taskInfoConfig.course_name = name;
          }
        }
        this.$nextTick(() => {
          this.$refs['form'].clearValidate();
        });
      }
    }
  },
  created() {
    this.setDefaultDate();
  },
  methods: {
    getCourseCategoryData: _coachingCenter.getCourseCategoryData,
    courseChange(checkedIds, checkedNodes) {
      this.taskInfoConfig.course_ids = this.formateData(checkedNodes);
      this.$refs['form'].validateField('course_ids');
    },
    handelReminderChange(reminderInfo) {
      console.log('handelReminderChange', reminderInfo);
      this.reminderInfo = reminderInfo;
    },
    formateData(data) {
      // 递归将nodes中的每一层的children存放到一个数组中
      const result = [];
      const recursionTreeNode = nodes => {
        nodes.forEach(item => {
          if (item.type === 'course') {
            result.push(item.id - 0);
          }
          if (item.children) {
            recursionTreeNode(item.children);
          }
        });
      };
      recursionTreeNode(data);
      return result;
    },
    getCourseConfig(type) {
      const courseTypes = new Map([[1, {
        icon: 'icon-reading-fill',
        color: '#0078FF'
      }], [2, {
        icon: 'icon-recite-fill',
        color: '#7852E0'
      }], [3, {
        icon: 'icon-robot-fill',
        color: '#FF5F33'
      }], [4, {
        icon: 'icon-ai-user',
        color: '#00B7B8'
      }], [5, {
        icon: 'icon-ai-user',
        color: '#00B7B8'
      }], [6, {
        icon: 'icon-ai-user',
        color: '#00B7B8'
      }], [7, {
        icon: 'icon-ai-user',
        color: '#00B7B8'
      }]]);
      return courseTypes.get(type);
    },
    goCourse() {
      this.$router.push({
        path: _routeMap.ROUTES.course
      });
    },
    async getCoachingTaskInfo() {
      this.loading = true;
      const res = await (0, _coachingCenter.getCoachingParentPlanDetail)({
        id: this.taskId
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          name,
          courses,
          start_time,
          end_time,
          users,
          course_name,
          alert_label,
          category_info
        } = res.results.data;
        this.numberList = users;
        this.copyCourseIds = courses.map(item => item.course_id);
        const executor = this.getExecutorCallBack(users);
        this.taskInfoConfig = {
          ...this.taskInfoConfig,
          name,
          course_ids: courses.map(item => item.course_id),
          course_name,
          executor,
          category_ids: category_info.map(item => item.id)
        };
        if (this.isEdit) {
          this.taskInfoConfig.start_time = start_time;
          this.taskInfoConfig.end_time = end_time;
          this.defaultReminderTime = alert_label;
        } else {
          // 复制任务数据处理
          this.taskInfoConfig = this.getCopyTaskInfo(this.taskInfoConfig);
        }
      }
    },
    setDefaultDate() {
      this.taskInfoConfig.start_time = this.$moment().format('YYYY-MM-DD HH:mm');
      this.taskInfoConfig.end_time = this.$moment().add(6, 'month').format('YYYY-MM-DD HH:mm');
    },
    getExecutorCallBack(val) {
      const tree_ids = val.filter(item => item.isDepartment).map(item => Number(item.id.split('_').pop()));
      const user_ids = val.filter(item => !item.isDepartment).map(item => Number(item.id.split('_').pop()));
      return {
        tree_ids,
        user_ids
      };
    },
    getCopyTaskInfo(val) {
      return {
        ...val,
        name: `${val.name}_${this.$t('coaching.duplicate')}`
      };
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    assigneeChange(val) {
      this.taskInfoConfig.executor = val;
      this.$refs.form.validateField('executor');
      // 如果分类列表中包含name为'self-test'的分类，执行人只有当前用户时，勾选自测分类
      if (this.taskCategoryList.length > 0 && this.taskCategoryList.find(item => item.name === 'self-test')) {
        this.setDefaultCategory();
      }
    },
    // 执行人只有当前用户时，勾选自测模型
    setDefaultCategory() {
      var _executor$tree_ids, _executor$user_ids, _this$taskInfoConfig$;
      const {
        executor
      } = this.taskInfoConfig;
      const isOnlyCurrentUser = ((_executor$tree_ids = executor.tree_ids) === null || _executor$tree_ids === void 0 ? void 0 : _executor$tree_ids.length) === 0 && ((_executor$user_ids = executor.user_ids) === null || _executor$user_ids === void 0 ? void 0 : _executor$user_ids.length) === 1 && executor.user_ids[0] === this.userId;
      if (isOnlyCurrentUser && ((_this$taskInfoConfig$ = this.taskInfoConfig.category_ids) === null || _this$taskInfoConfig$ === void 0 ? void 0 : _this$taskInfoConfig$.length) === 0) {
        const categoryId = this.taskCategoryList.find(item => item.name === 'self-test').id;
        this.taskInfoConfig.category_ids.push(categoryId);
      }
    },
    handleClose() {
      this.numberList = [];
      this.copyCourseIds = [];
      this.$refs.form.resetFields();
      this.$emit('update:visible', false);
    },
    importGroupMembers(results) {
      var _executor$user_ids2, _executor$tree_ids2;
      const {
        executor
      } = this.taskInfoConfig;
      // 先清已有的再导入
      if (((_executor$user_ids2 = executor.user_ids) === null || _executor$user_ids2 === void 0 ? void 0 : _executor$user_ids2.length) > 0 || ((_executor$tree_ids2 = executor.tree_ids) === null || _executor$tree_ids2 === void 0 ? void 0 : _executor$tree_ids2.length) > 0) {
        this.$refs['member-tree-select'].clear();
      }
      this.numberList = results;
    },
    createTask() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          var _this$reminderInfo, _this$reminderInfo2;
          const {
            name,
            course_ids,
            executor,
            start_time,
            end_time,
            category_ids
          } = this.taskInfoConfig;
          const params = {
            name,
            course_ids,
            start_time,
            end_time,
            category_ids,
            tree_ids: executor.tree_ids,
            user_ids: executor.user_ids,
            alert_at: (_this$reminderInfo = this.reminderInfo) === null || _this$reminderInfo === void 0 ? void 0 : _this$reminderInfo.reminderAt,
            alert_label: (_this$reminderInfo2 = this.reminderInfo) === null || _this$reminderInfo2 === void 0 ? void 0 : _this$reminderInfo2.reminderLabel
          };
          this.submitLoading = true;
          if (this.isEdit) params.id = this.taskId;
          (0, _timezone.datePickerWithTimeParse2Zone)(params, true);
          const requestFunction = this.isEdit ? _coachingCenter.updateCoachingTasks : _coachingCenter.createCoachingTasks;
          const res = await requestFunction(params).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 20000) {
            let message = this.isEdit ? this.$t('coaching.editedSuccessfully') : res.msg === 'Success' ? this.$t('coaching.createdSuccessfully') : res.msg;
            message += this.hasLink ? `，<a style="color:#4461ec" href="${_routeMap.ROUTES.coachingCenterLeader}">${this.$t('coaching.goToView')}</a>` : '';
            this.$message({
              dangerouslyUseHTMLString: true,
              message,
              type: 'success',
              duration: 5000
            });
            this.handleClose();
            this.$emit('refresh');
          }
        }
      });
    },
    async getTaskCategoryList() {
      const res = await (0, _coachingCenter.getTaskCategoryList)();
      if (res.code === 20000) {
        this.taskCategoryList = res.results.data;
      }
    }
  }
};
exports.default = _default;