"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batch-modify"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.$t("management.批量修改成员信息"),
      visible: _vm.dialogVisible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      closed: _vm.clearData
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("el-form", {
    ref: "batchModifyForm",
    staticClass: "modify-form",
    attrs: {
      model: _vm.batchModifyForm,
      rules: _vm.rules
    }
  }, [_c("div", {
    staticClass: "user-select"
  }, [_c("el-form-item", {
    attrs: {
      prop: "ids"
    }
  }, [_c("label", {
    staticClass: "step-title user-title"
  }, [_vm._v(_vm._s(_vm.$t("management.成员")))]), _vm.dialogVisible ? _c("members-finder", {
    ref: "membersfinder",
    attrs: {
      placeholder: _vm.$t("management.请选择成员"),
      "has-self": false,
      "tree-config": _vm.treeConfig
    },
    on: {
      change: _vm.handleMemberChange
    }
  }) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "step-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.请选择要修改的信息")) + " ")]), _c("div", {
    staticClass: "modify-tips"
  }, [_c("i", {
    staticClass: "el-icon-warning-outline"
  }), _vm._v(" " + _vm._s(_vm.$t("management.修改成员的信息，会清空之前的信息，替换为新的")) + " ")]), _c("div", {
    staticClass: "information-item"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.chooseDepartment,
      callback: function ($$v) {
        _vm.chooseDepartment = $$v;
      },
      expression: "chooseDepartment"
    }
  }, [_vm._v(_vm._s(_vm.$t("management.部门")))]), _vm.chooseDepartment ? _c("add-item", {
    staticClass: "department-content",
    attrs: {
      title: _vm.$t("management.部门"),
      data: _vm.departmentList
    },
    on: {
      delete: _vm.deleteDepartment,
      add: function ($event) {
        return _vm.addDepartment();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref) {
        let {
          item,
          index
        } = _ref;
        return [_c("el-form-item", {
          attrs: {
            prop: JSON.stringify([item.department]),
            label: index === 0 ? _vm.$t("management.主部门") : _vm.$t("management.部门"),
            rules: _vm.checkDept
          }
        }, [_c("department-select", {
          attrs: {
            "org-tree": _vm.getTree,
            "disabled-arr": _vm.disabledArr,
            "default-expanded-keys": _vm.defaultExpandedKeys
          },
          model: {
            value: item.department,
            callback: function ($$v) {
              _vm.$set(item, "department", $$v);
            },
            expression: "item.department"
          }
        })], 1)];
      }
    }], null, false, 2238682019)
  }) : _vm._e()], 1), _vm.hasWorkspace ? _c("div", {
    staticClass: "information-item"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.chooseWorkspace,
      callback: function ($$v) {
        _vm.chooseWorkspace = $$v;
      },
      expression: "chooseWorkspace"
    }
  }, [_vm._v(_vm._s(_vm.$t("management.空间")))]), _vm.chooseWorkspace ? _c("add-item", {
    staticClass: "department-content workspace-group",
    attrs: {
      title: _vm.$t("management.空间"),
      data: _vm.workspaceInfoGroup
    },
    on: {
      delete: _vm.deleteWorkspace,
      add: function ($event) {
        return _vm.addWorkspaceItem();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref2) {
        let {
          item,
          index
        } = _ref2;
        return [_c("el-form-item", {
          attrs: {
            prop: JSON.stringify([item.workspace]),
            label: index === 0 ? _vm.$t("management.主空间") : _vm.$t("management.空间"),
            rules: _vm.checkWorkspace
          }
        }, [_c("el-select", {
          attrs: {
            placeholder: _vm.$t("management.请选择空间"),
            "popper-class": "workspace-selector"
          },
          model: {
            value: item.workspace,
            callback: function ($$v) {
              _vm.$set(item, "workspace", $$v);
            },
            expression: "item.workspace"
          }
        }, _vm._l(_vm.localWorkspaceList, function (workspace) {
          return _c("el-option", {
            key: workspace.id,
            attrs: {
              label: workspace.name,
              value: workspace.id,
              disabled: workspace.disabled
            }
          }, [_c("i", {
            staticClass: "iconfont icon-workspace-3-fill",
            style: {
              color: workspace.icon_color,
              "margin-right": "4px"
            }
          }), _c("span", [_vm._v(_vm._s(workspace.name))])]);
        }), 1)], 1)];
      }
    }], null, false, 1537518070)
  }) : _vm._e()], 1) : _vm._e()])], 1), _c("div", {
    staticClass: "dialog-footer",
    staticStyle: {
      "margin-top": "22px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.modifyBtnLoading
    },
    on: {
      click: _vm.confirmBatchModify
    }
  }, [_vm._v(_vm._s(_vm.$t("management.确定")))])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;