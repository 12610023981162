"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceScoreFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceScoreFilter"));
var _ComplianceStatusFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceStatusFilter"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PersonalFilterGroup',
  components: {
    ComplianceScoreFilter: _ComplianceScoreFilter.default,
    ComplianceStatusFilter: _ComplianceStatusFilter.default
  },
  props: {
    scoreRuleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filtersGroupObj: {
        conversation_name: '',
        status: 0,
        score: []
      }
    };
  },
  methods: {
    changeStatusFilter(status) {
      this.filtersGroupObj.status = status;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    changeScoreFilter(score) {
      this.filtersGroupObj.score = score;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    changeConvName: (0, _commonFunc.debounce)(function () {
      this.$emit('changeFilter', this.filtersGroupObj);
    }, 300)
  }
};
exports.default = _default;