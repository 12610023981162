"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: ["sentence-box", {
      "sale-sentence-box": _vm.sentence.type === "sales"
    }]
  }, [_vm.sentence.type === "customer" ? _c("mgv-avatar-name", {
    attrs: {
      src: [4, 5, 6, 7].includes(_vm.taskType) || _vm.customerAvatar ? _vm.customerAvatar : require("@/assets/coach/mai-mai-avatar.png")
    }
  }) : _vm._e(), _c("div", {
    staticClass: "conversation-info"
  }, [_vm.isSentenceRecords ? _c("p", {
    staticClass: "info"
  }, [_c("span", [_vm._v(_vm._s(_vm.sentence.name || (_vm.sentence.type === "customer" ? "客户" : "销售")))]), _c("span", [_vm._v(_vm._s(_vm.sentence.date))])]) : _vm._e(), _c("div", {
    staticClass: "sentence-conatiner"
  }, [_vm.sentence.loading ? _c("div", {
    staticClass: "sentence"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/AIHelper/content_loading.gif")
    }
  })]) : _c("div", {
    class: ["sentence", {
      sale: _vm.sentence.type === "sales"
    }]
  }, [_c("p", [_vm._v(" " + _vm._s(_vm.sentence.content) + " "), _vm.sentence.type === "customer" && !_vm.practiceRule.practice_mode && _vm.sentence.trans_url ? _c("svg-icon", {
    staticClass: "voice-icon",
    attrs: {
      "icon-class": _vm.currentPlayingAudioId === _vm.sentence.child_id ? "voice-animation" : "voice"
    },
    on: {
      click: function ($event) {
        return _setup.emit("playAudio");
      }
    }
  }) : _vm._e()], 1), _vm.sentence.images ? _vm._l(_vm.sentence.images, function (item, index) {
    return _c(_setup.SentenceImage, {
      key: item,
      attrs: {
        "image-index": index,
        "image-list": _vm.sentence.images,
        "image-append-to-body": _vm.imageAppendToBody,
        "max-width": _vm.imageWidth,
        src: item
      }
    });
  }) : _vm._e()], 2), _vm.sentence.type === "customer" && _vm.sentence.knowledge_id ? _c("div", {
    staticClass: "knowledge-mark"
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.keyIssue")) + " ")]) : _vm._e(), _vm.sentence.score_rule_type === 1 && _vm.sentence.type === "sales" && [3, 5, 6].includes(_vm.taskType) ? _c("el-popover", {
    ref: "referencePopover",
    attrs: {
      trigger: "hover",
      "popper-class": "sentence-item-score-comment-popover",
      placement: _vm.popoverPlacement,
      disabled: _vm.sentence.score_loading
    }
  }, [_c("div", {
    staticClass: "header"
  }, [_c("span", [_vm._v(_vm._s(_setup.t("coaching.practiceScore")))]), _c("span", {
    staticClass: "score-contianer"
  }, [_c("span", {
    class: _setup.getScoreClass(_vm.sentence)
  }, [_vm._v(_vm._s(_vm.sentence.score))]), _c("span", [_vm._v(" / " + _vm._s(_vm.sentence.total_score))])])]), _c("div", {
    staticClass: "content"
  }, [_vm.isSentenceRecords ? [_c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_setup.t("coaching.standardScript")) + "：")]), _c("p", [_vm._v(" " + _vm._s(_vm.sentence.standard_reply) + " ")])] : _vm._e(), _vm.sentence.comment ? _c("p", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.intelligentReview")) + "： ")]) : _vm._e(), _c("p", {
    domProps: {
      innerHTML: _vm._s(_vm.sentence.comment || _setup.t("coaching.noContent"))
    }
  })], 2), _c("template", {
    slot: "reference"
  }, [_vm.sentence.score_loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.sentence.score_loading,
      expression: "sentence.score_loading"
    }],
    staticClass: "score"
  }) : _vm.sentence.score !== -1 ? [_vm.taskType === 3 ? _c("div", {
    class: ["score", _setup.getScoreClass(_vm.sentence)]
  }, [_c("span", [_vm._v(" " + _vm._s(_setup.t("coaching.pointsHasVar", [_vm.sentence.score])))]), _c("i", {
    staticClass: "iconfont icon-ai-assistant-fill"
  })]) : _vm._e()] : _vm._e()], 2)], 2) : _vm._e()], 1)]), _vm.sentence.type === "sales" ? _c("mgv-avatar-name", {
    attrs: {
      src: _vm.isSentenceRecords ? _vm.sentence.avatar : _setup.avatar,
      name: _vm.isSentenceRecords ? _vm.sentence.name : _setup.name
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;