"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _commonFunc = require("@/utils/commonFunc");
var _OptionTemplate = _interopRequireDefault(require("@/views/convDetail/components/OptionTemplate.vue"));
var _KnowledgeItem = _interopRequireDefault(require("./KnowledgeItem.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeSearch',
  components: {
    OptionTemplate: _OptionTemplate.default,
    KnowledgeItem: _KnowledgeItem.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    popoverDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      knowledgeOptions: [],
      currKnowledge: '',
      noMore: false,
      scroll_id: '',
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo']),
    disabled() {
      return this.loading || this.noMore;
    }
  },
  watch: {
    'knowledgeBaseInfo.id': {
      // 切换知识库
      handler: function (val) {
        if (val) {
          this.clearData();
        }
      }
    }
  },
  methods: {
    filterChange(name) {
      this.currKnowledge = name;
      this.knowledgeOptions = [];
      this.scroll_id = '';
      if (!name) return;
      this.getSearchKnowledges();
    },
    getSearchKnowledges: (0, _commonFunc.debounce)(async function () {
      this.loading = true;
      const res = await (0, _videoDetail.searchKnowledges)({
        name: this.currKnowledge,
        base_id: this.knowledgeBaseInfo.id,
        scroll_id: this.scroll_id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          knowledge_list,
          scroll_id
        } = res.results;
        this.noMore = knowledge_list.length < 20;
        this.scroll_id = scroll_id;
        this.knowledgeOptions.push(...knowledge_list);
      }
    }),
    handleChange(val) {
      if (!val) {
        this.knowledgeOptions = [];
        return;
      }
      const data = this.knowledgeOptions.find(i => i.id === val);
      this.$emit('search', data, val);
    },
    clearData() {
      this.currKnowledge = '';
      this.knowledgeOptions = [];
    }
  }
};
exports.default = _default;