"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'OrgSwitchList',
  components: {},
  props: {
    organizations: {
      type: Array,
      default: function () {
        return [];
      }
    },
    invitations: {
      type: Array,
      default: function () {
        return [];
      }
    },
    activeOrgId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    selectOrg(id, type) {
      this.$emit('selectOrg', id, type);
    }
  }
};
exports.default = _default;