"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-menu", {
    ref: "menu-item",
    attrs: {
      mode: "vertical",
      "default-active": _vm.activeMenu,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "active-text-color": _vm.variables.menuActiveText,
      router: ""
    }
  }, _vm._l(_vm.sideBarMenu, function (item) {
    return _c("el-menu-item", {
      key: item.path,
      attrs: {
        index: item.path,
        "data-v-step": item.name
      }
    }, [_c("template", {
      slot: "title"
    }, [_vm._t("menuItem", function () {
      return [_c("i", {
        class: ["iconfont", `icon-${item.meta.icon}`]
      }), _c("span", {
        staticClass: "menu-title",
        style: _vm.dynamicFontStyle(_vm.i18nTitle(item.meta.title))
      }, [_vm._v(_vm._s(_vm.i18nTitle(item.meta.title)))])];
    }, {
      data: item
    })], 2)], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;