"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _KnowledgeItem = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeItem.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SingleTreeSelect',
  components: {
    KnowledgeItem: _KnowledgeItem.default
  },
  props: {
    backfillData: {
      type: [Number, String],
      default: 0
    },
    treeData: {
      type: Array,
      default: () => []
    },
    searchParams: {
      type: Object,
      default: () => ({})
    },
    remoteMethod: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      checkedNode: {
        name: '',
        id: 0
      },
      searchValue: '',
      searchOptions: [],
      searchLoading: false,
      showKnowledgePopover: false,
      KnowldgeInfo: {},
      getKnowldgeInfoLoading: false,
      prePageY: 0 // 记录上次鼠标的位置
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'knowledgeBaseInfo'])
  },
  watch: {
    backfillData(val) {
      if (val !== this.checkedNode.id) {
        this.$nextTick(() => {
          var _this$$refs$tree$getN;
          this.checkedNode = ((_this$$refs$tree$getN = this.$refs['tree'].getNode(val)) === null || _this$$refs$tree$getN === void 0 ? void 0 : _this$$refs$tree$getN.data) || this.$options.data().checkedNode;
        });
      }
    }
  },
  methods: {
    filterChange: (0, _commonFunc.debounce)(async function (name) {
      if (!name) return;
      this.searchOptions = [];
      this.searchValue = name;
      this.searchLoading = true;
      const res = await this.remoteMethod({
        name,
        base_id: this.knowledgeBaseInfo.id || this.$route.query.knowledgeBaseId,
        only_name: true,
        ...this.searchParams
      }).finally(() => {
        this.searchLoading = false;
      });
      if (res.code === 20000) {
        this.searchOptions = res.results.knowledge_list;
      }
    }),
    getCheckedNodeParentNode() {
      if (this.checkedNode.id) {
        const parentNode = this.$refs['tree'].getNode(this.checkedNode.id).parent;
        // 根据parent的data是数组（无父节点）还是对象（有父节点）来区分是否和根节点同级挂载
        return parentNode.data.constructor === Object ? parentNode.data : this.checkedNode;
      }
    },
    getPathText(path) {
      return path.reduce((pre, current) => pre.length ? `${pre} > ${current}` : current, '');
    },
    checkedOptions(val) {
      this.throwData(val);
      this.initSearch();
    },
    clear() {
      this.checkedNode = this.$options.data().checkedNode;
    },
    initSearch() {
      this.searchValue = '';
    },
    handleNodeClick(_ref) {
      let {
        name,
        id,
        is_know
      } = _ref;
      if (is_know) {
        this.throwData({
          name,
          id
        });
      }
    },
    throwData(data) {
      this.checkedNode = data;
      this.$emit('change', this.checkedNode);
      this.$refs['select'].blur();
    },
    renderContent(h, _ref2) {
      let {
        node,
        data: {
          id,
          name,
          is_know,
          count
        },
        store
      } = _ref2;
      return h("span", {
        "class": ['tree-node-container', {
          is_konwledge: is_know
        }, id === this.checkedNode.id ? 'is-active' : ''],
        "on": {
          "mouseenter": $event => is_know && this.showDetailPopover($event, id),
          "mouseleave": () => is_know && this.hiddenDetailPopover()
        }
      }, [h("span", [h("i", {
        "class": is_know ? 'iconfont icon-reading' : ''
      }), name]), h("span", [h("i", {
        "class": id === this.checkedNode.id ? 'el-icon-check' : ''
      }), count])]);
    },
    showDetailPopover: (0, _commonFunc.debounce)(function ($event, id) {
      this.showKnowledgePopover = true;
      this.getKnowldgeInfo($event, id);
    }),
    hiddenDetailPopover: (0, _commonFunc.debounce)(function () {
      this.showKnowledgePopover = false;
    }),
    async getKnowldgeInfo($event, id) {
      if (id === this.KnowldgeInfo.id) return;
      this.KnowldgeInfo = {};
      this.getKnowldgeInfoLoading = true;
      this.setKnowledgePopoverOffset($event);
      const res = await (0, _knowledgeLibrary.getKnowledgeInfo)({
        id,
        space_id: this.workspaceInfo.id
      }).finally(() => {
        this.getKnowldgeInfoLoading = false;
      });
      if (res.code === 20000) {
        this.KnowldgeInfo = res.results.data;
        this.setKnowledgePopoverOffset($event);
      }
    },
    setKnowledgePopoverOffset($event) {
      this.$nextTick(() => {
        const pageY = $event.target.getBoundingClientRect().top;
        const targetElementHeight = $event.target.clientHeight;
        const element = this.$refs['knowledge-detail-popover'];
        if (!element) return;
        // // 自身实际高度 = 自身高度 / 缩放倍率（搭配css的scale使用）
        const popoverElementHeight = element.offsetHeight / 2;
        // popover相对文档的位置 = 触发元素相对文档的top - （popover元素的高度 + 触发元素的高度）/ 2
        element.style.top = pageY - (popoverElementHeight - targetElementHeight) / 2 + 'px';
      });
    }
  }
};
exports.default = _default;