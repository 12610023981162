"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart-container"
  }, [_c("div", {
    ref: "barChart",
    staticClass: "bar-chart"
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.chartValue.length,
      expression: "!chartValue.length"
    }],
    staticClass: "no-data-placeholder"
  }, [_vm._v("暂无数据")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;