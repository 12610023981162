"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "frequently-asked-question-container"
  }, [_c("span", {
    staticClass: "tip"
  }, [_vm._v("每周一更新上周数据")]), _vm.interfacePermission.showChatEntry ? _c("chat-btn", {
    nativeOn: {
      click: function ($event) {
        return _vm.changeChatDrawerVisible.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c("el-button", {
    staticClass: "ignore-button top-btn",
    on: {
      click: function ($event) {
        _vm.ignoreQuestionDrawerVisible = true;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated-already"
  }), _vm._v("已忽略问题")]), _c("guide-dialog", {
    attrs: {
      visible: _vm.guideDialogVisible
    },
    on: {
      handleClick: function ($event) {
        _vm.guideDialogVisible = false;
      }
    }
  }), _c("div", {
    staticClass: "tab-box"
  }, [_c("el-tabs", {
    on: {
      "tab-click": function ($event) {
        return _vm.filterChange({
          page: 1
        });
      }
    },
    model: {
      value: _vm.queryParams.status,
      callback: function ($$v) {
        _vm.$set(_vm.queryParams, "status", $$v);
      },
      expression: "queryParams.status"
    }
  }, _vm._l(_vm.getQuestionsCategoriesList(), function (_ref) {
    let {
      label,
      type
    } = _ref;
    return _c("el-tab-pane", {
      key: type,
      attrs: {
        label: label,
        name: type
      }
    });
  }), 1), _c("filters-group", {
    attrs: {
      filter: _vm.queryParams
    },
    on: {
      filterChange: function ($event) {
        return _vm.filterChange({
          ...$event,
          page: 1
        });
      },
      createQuestion: _vm.createQuestion
    }
  }), _c("div", {
    staticClass: "tab-container"
  }, [_c("div", {
    class: ["left", {
      "show-detail": _vm.showDetail
    }]
  }, [_c("NoData", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isNoData,
      expression: "isNoData"
    }],
    attrs: {
      icon: _vm.queryParams.filter ? "icon-a-Property1not-found" : "icon-a-Property1no-data",
      tips: _vm.queryParams.filter ? "未搜索到相关结果" : "暂无内容"
    }
  }), _c("transition", {
    attrs: {
      name: "fade-in-out"
    }
  }, [_c("keep-alive", [_vm.queryParams.type === "list" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getQuestionsLoading,
      expression: "getQuestionsLoading"
    }],
    staticClass: "list-view"
  }, [_c("div", {
    staticClass: "questions-list"
  }, _vm._l(_vm.questionsList, function (item) {
    return _c("question-item", {
      key: item.id,
      attrs: {
        question: item,
        "current-question": _vm.currentQuestion
      },
      on: {
        questionClick: _vm.toggleCurrentQuestion,
        handleOperation: _vm.handleOperation
      }
    });
  }), 1), _c("el-pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.questionsTotal,
      expression: "questionsTotal"
    }],
    attrs: {
      layout: _vm.paginationConfig.layout,
      "current-page": _vm.queryParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.queryParams.size,
      total: _vm.questionsTotal,
      "pager-count": _vm.paginationConfig.pageCount
    },
    on: {
      "size-change": function ($event) {
        return _vm.filterChange({
          size: $event,
          page: 1
        });
      },
      "current-change": function ($event) {
        return _vm.filterChange({
          page: $event
        });
      }
    }
  })], 1) : _vm._e()])], 1), _c("transition", {
    attrs: {
      name: "fade-in-out"
    }
  }, [_c("keep-alive", [_vm.queryParams.type === "chart" ? _c("chart-template", {
    ref: "pie-chart",
    class: ["chart-view", {
      "no-data": _vm.isNoData
    }],
    attrs: {
      "chart-loading": _vm.getChartDataLoading,
      "chart-config": _vm.chartConfig
    }
  }) : _vm._e()], 1)], 1)], 1), _c("div", {
    class: ["right", {
      "hiddent-detail": !_vm.showDetail
    }]
  }, [_c("div", {
    staticClass: "right-header"
  }, [_c("el-radio-group", {
    staticClass: "mg-radio-button-group",
    model: {
      value: _vm.detailActiveTab,
      callback: function ($$v) {
        _vm.detailActiveTab = $$v;
      },
      expression: "detailActiveTab"
    }
  }, _vm._l(_vm.tabs, function (value, key) {
    return _c("el-radio-button", {
      key: key,
      attrs: {
        label: key
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showDetail,
      expression: "showDetail"
    }],
    staticClass: "close-tabs",
    attrs: {
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        _vm.showDetail = false;
      }
    }
  })], 1), _c("div", {
    ref: "right-tab-container",
    staticClass: "right-tab-container"
  }, [_c("keep-alive", [_vm.detailActiveTab === "trend-analysis" ? _c("trend-analysis", {
    attrs: {
      params: _vm.formatParams,
      config: _vm.currentQuestion
    }
  }) : _vm._e()], 1), _c("keep-alive", [_vm.detailActiveTab === "answer-list" ? _c("answer-list", {
    attrs: {
      params: _vm.formatParams,
      "current-question": _vm.currentQuestion
    },
    on: {
      refreshCurrentPageQuestions: _vm.getQuestionsList
    }
  }) : _vm._e()], 1), _c("keep-alive", [_vm.detailActiveTab === "sub-question" ? [_c("sub-question-list", {
    attrs: {
      "current-question": _vm.currentQuestion
    },
    on: {
      refresh: _vm.getQuestionsList
    }
  })] : _vm._e()], 2)], 1)])])], 1), _c("ignore-question-drawer", {
    attrs: {
      "drawer-visible": _vm.ignoreQuestionDrawerVisible,
      "get-ignore-knowledge-question-list": _vm.getFAQIgnoreQuestionList,
      "edit-ignore-status": _vm.editFAQIgnoreStatus
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.ignoreQuestionDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.ignoreQuestionDrawerVisible = $event;
      }
    }
  }), _c("transition", {
    attrs: {
      name: "slide-right"
    }
  }, [_vm.chatDrawerVisible ? _c("AIAssistantChatDrawer", {
    key: _vm.workspaceInfo.id,
    attrs: {
      "prompt-config": _vm.chatPromptConfig,
      "request-config": _vm.chatRequestConfig
    },
    on: {
      close: function ($event) {
        _vm.chatDrawerVisible = false;
      }
    }
  }) : _vm._e()], 1), _c("CreateQuestionDialog", {
    attrs: {
      "is-edit": _vm.isEdit,
      visible: _vm.createQuestionDialogVisible,
      "edit-question": _vm.editQuestionInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.createQuestionDialogVisible = $event;
      },
      refreshCurrentPageQuestions: _vm.getQuestionsList
    }
  }), _c("MoveFreqQuestionDialog", {
    attrs: {
      "current-question": _vm.currentQuestion,
      visible: _vm.moveFreqQuestionDialogVisible,
      "operate-name": "并入"
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveFreqQuestionDialogVisible = $event;
      },
      moveFreqQuestion: _vm.handleMoveFreqQuestion
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;