"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "user-operation-card"
  }, [_c("div", {
    staticClass: "row user",
    on: {
      click: function ($event) {
        return _vm.handleClick("goPersonalCenter");
      }
    }
  }, [_c("figure", {
    staticClass: "user-info"
  }, [_c("MgvAvatarName", {
    attrs: {
      name: _vm.name,
      src: _vm.avatar,
      size: 40
    }
  }), _c("figcaption", {
    staticClass: "figcaption"
  }, [_c("h4", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))]), _c("div", {
    staticClass: "tel"
  }, [_vm._v(" " + _vm._s(_vm.loginTel) + " ")]), _c("div", {
    staticClass: "tel"
  }, [_vm._v(" " + _vm._s(_vm.email) + " ")])])], 1), _c("div", {
    staticClass: "right-link personal-center",
    on: {
      click: function ($event) {
        return _vm.handleClick("goPersonalCenter");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.personalCenter")) + " "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])]), _vm.userType === "trial" ? _c("div", {
    staticClass: "trial-feature"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("common.trialVersion")))]), _c("span", {
    staticClass: "time-end"
  }, [_vm._v(_vm._s(_vm.$t("common.probationaryPeriod", [_vm.trialExpireDate])))])]) : _vm._e(), _c("el-divider", {
    staticClass: "first"
  }), _c("div", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "row button-row",
    on: {
      click: function ($event) {
        return _vm.handleClick("openSwitchDialog", true);
      }
    }
  }, [_c("figure", [_c("svg-icon", {
    attrs: {
      "icon-class": "office-building"
    }
  }), _vm._v(_vm._s(_vm.organization) + " ")], 1), _c("div", {
    staticClass: "right-link"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.toggle")) + " "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])]), _vm.interfacePermission.getLanguageList ? _c("el-popover", {
    attrs: {
      "popper-class": "user-operation-switch-language-popover",
      placement: "left-start",
      width: "240",
      trigger: "hover",
      "visible-arrow": ""
    },
    model: {
      value: _vm.languagePopoverVisible,
      callback: function ($$v) {
        _vm.languagePopoverVisible = $$v;
      },
      expression: "languagePopoverVisible"
    }
  }, [_c("div", {
    staticClass: "language-list-content"
  }, _vm._l(_vm.languageList, function (item) {
    return _c("div", {
      key: item.code,
      class: ["language-item", {
        active: _vm.currentLanguage === item.code
      }],
      on: {
        click: function ($event) {
          return _vm.selectLanguage(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "row button-row",
    attrs: {
      slot: "reference"
    },
    on: {
      click: function ($event) {
        _vm.languagePopoverVisible = true;
      }
    },
    slot: "reference"
  }, [_c("figure", {
    staticClass: "language-title"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "language"
    }
  }), _vm._v(_vm._s(_vm.$t("common.language")) + " "), _c("p", [_vm._v(_vm._s(_vm.$t("common.languageToggleTips")))])], 1), _c("div", {
    staticClass: "right-link"
  }, [_c("i", {
    staticClass: "el-icon-arrow-right"
  })])])]) : _vm._e(), _vm.managementPagePermission ? _c("div", {
    staticClass: "row button-row",
    on: {
      click: function ($event) {
        return _vm.handleClick("goOrgManagement");
      }
    }
  }, [_c("figure", [_c("svg-icon", {
    attrs: {
      "icon-class": "setting"
    }
  }), _vm._v(_vm._s(_vm.$t("common.managementBackend")) + " ")], 1)]) : _vm._e(), _c("div", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "row button-row",
    on: {
      click: function ($event) {
        return _vm.handleClick("goWebsite");
      }
    }
  }, [_c("figure", [_c("svg-icon", {
    attrs: {
      "icon-class": "house"
    }
  }), _vm._v(_vm._s(_vm.$t("common.homePage")) + " ")], 1)]), _c("div", {
    staticClass: "row button-row logout",
    on: {
      click: function ($event) {
        return _vm.handleClick("logout", true);
      }
    }
  }, [_c("figure", [_c("svg-icon", {
    attrs: {
      "icon-class": "sign-out-1"
    }
  }), _vm._v(_vm._s(_vm.$t("common.logout")) + " ")], 1)])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;