"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DealCard = _interopRequireDefault(require("./DealCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealListDrawer',
  components: {
    DealCard: _DealCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    dealDrawerVisible: {
      type: Boolean,
      default: false
    },
    nodePathParams: {
      type: Object,
      default: () => ({})
    },
    realNodePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dealList: [],
      dealListLength: 0,
      dealTotal: 0,
      dealListParams: {
        page: 1,
        size: 10
      },
      dealListLoading: false
    };
  },
  computed: {
    noMore() {
      return this.dealListLength && this.dealListLength === this.dealTotal;
    },
    disabled() {
      return this.dealListLoading || this.noMore;
    }
  },
  watch: {
    dealDrawerVisible: {
      handler: function (val) {
        if (val) {
          this.initDealData();
          this.getDealListByRelPath();
        }
      }
    }
  },
  methods: {
    // 初始化商机数据
    initDealData() {
      this.dealList = [];
      this.dealListLength = 0;
      this.dealTotal = 0;
      this.dealListParams.page = 1;
    },
    // 获取商机列表
    async getDealListByRelPath() {
      this.dealListLoading = true;
      const requestParams = {
        ...this.nodePathParams,
        rel_node_path: this.realNodePath,
        ...this.dealListParams
      };
      const res = await (0, _businessIntelligence.getDealListByRelPath)(requestParams);
      if (res.code === 200) {
        this.dealList.push(...res.results.data);
        this.dealTotal = res.results.deal_counts;
        this.dealListLength += res.results.data.length;
        this.dealListParams.page++;
      }
      this.dealListLoading = false;
    },
    loadMore() {
      this.getDealListByRelPath();
    },
    handleClose() {
      this.$emit('closeDealDrawer');
    }
  }
};
exports.default = _default;