"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-media-container"
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-media",
    attrs: {
      id: "uploadMedia",
      action: "#",
      multiple: _vm.multiple,
      limit: _vm.limitCount,
      "file-list": _vm.fileList,
      "before-upload": _vm.beforeUploadFile,
      "http-request": _vm.uploadFile,
      "on-success": _vm.onSuccess,
      "on-progress": _vm.onProgress,
      "on-error": _vm.onError,
      "on-exceed": _vm.onExceed,
      "before-remove": _vm.beforeRemove,
      "on-remove": _vm.handleFileRemove,
      accept: _vm.acceptFileType,
      drag: ""
    }
  }, [_c("svg-icon", {
    staticClass: "upload-icon",
    attrs: {
      "icon-class": "upload"
    }
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("将文件拖到此处，或"), _c("em", [_vm._v(" 点击添加")])])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;