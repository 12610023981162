"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-knowledge-conversation"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("span", {
    staticClass: "header-title"
  }, [_vm._v("会话")]), _c("span", {
    staticClass: "header-link",
    on: {
      click: _vm.goConversation
    }
  }, [_vm._v("查看所有会话")])]), _vm.convList && _vm.convList.length ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("mg-infinite-scroll", {
    staticClass: "conversation-content",
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.convList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "conversation-item"
    }, [_c("span", {
      staticClass: "score"
    }, [_c("span", {
      staticClass: "score-item"
    }, [_vm._v(_vm._s(item.score))]), _c("span", {
      staticClass: "desc"
    }, [_vm._v("分")])]), _c("div", {
      staticClass: "info"
    }, [_c("p", {
      staticClass: "title",
      on: {
        click: function ($event) {
          return _vm.goConversationDetail(item.conversation_id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.conversation_name) + " ")]), _c("div", {
      staticClass: "info-desc"
    }, [_c("span", {
      staticClass: "duration"
    }, [_vm._v(_vm._s(_vm.secondToTime(item.duration)))]), _c("span", {
      staticClass: "date"
    }, [_vm._v(_vm._s(_vm.$moment(item.begin_time).format("LL")))])]), _c("div", {
      staticClass: "knowledge-desc"
    }, [_vm._v(" 知识点："), _c("span", {
      staticClass: "knowledge-name"
    }, [_vm._v(_vm._s(item.knowledge_name))])])])]);
  }), 0)], 1) : _c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无数据")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;