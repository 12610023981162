"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field-edit-form",
    class: {
      "static-form": !_vm.isEdit
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.formData,
      "label-width": _vm.labelWidth,
      "label-position": _vm.labelPosition,
      rules: _vm.rules,
      "hide-required-asterisk": ""
    }
  }, _vm._l(_vm.desc, function (item) {
    return _c("el-form-item", {
      key: item.id,
      class: [{
        "is-half-row": item.halfRow
      }, {
        "is-table": item.type === "table" && _vm.formData[item.id] && _vm.formData[item.id].length && !_vm.isEdit
      }, `from-item__${item.id.replace(/([A-Z])/g, "-$1").toLowerCase()}`, {
        "no-label": item.hideLabel || item.label === "none"
      }],
      attrs: {
        prop: item.id
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_vm._t(item.id + "-label", function () {
            return [_vm._v(" " + _vm._s(item.label) + " ")];
          }), _vm.isShowImageTips && item.tips ? _c("image-text-tips", {
            attrs: {
              list: JSON.parse(item.tips).tips
            }
          }) : _vm._e(), !_vm.isShowImageTips && item.tips ? _c("el-tooltip", {
            attrs: {
              content: item.tips,
              placement: "top"
            }
          }, [_c("i", {
            staticClass: "el-icon-info"
          })]) : _vm._e(), item.required && _vm.isEdit ? _c("span", {
            staticClass: "dot"
          }, [_vm._v("*")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_vm._t(item.id, function () {
      return [!_vm.isEdit && !_vm.isShow(_vm.formData[item.id]) ? _c("div", {
        attrs: {
          id: "is-empty"
        }
      }, [_vm._v(" - ")]) : _vm._e(), _c(_vm.handleComp(item), _vm._b({
        ref: item.id,
        refInFor: true,
        tag: "component",
        attrs: {
          "is-edit": _vm.isEdit,
          value: _vm.formData[item.id]
        },
        on: {
          input: function ($event) {
            return _vm.handleChange(item.id, $event);
          },
          change: function ($event) {
            return _vm.onEditEnd(item.id, $event);
          }
        }
      }, "component", item, false))];
    }, {
      defaultValue: item.default
    })], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;