"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading && !_vm.msgList.length,
      expression: "loading && !msgList.length"
    }],
    staticClass: "chat-unit"
  }, [_c("chat-top", _vm._g(_vm._b({}, "chat-top", _vm.$attrs, false), _vm.$listeners)), !_vm.isConfirmChatAgreement ? _c("chat-agreement", {
    on: {
      confirm: _vm.handleConfirm
    }
  }) : [_vm.checkIsSupport && !_vm.isSupport && !_vm.loading ? _c("not-support", _vm._b({}, "not-support", _vm.$attrs, false)) : [_c("chat-intro", _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.msgList.length && !_vm.loading,
      expression: "!msgList.length && !loading"
    }],
    on: {
      change: _vm.createQA
    }
  }, "chat-intro", _vm.promptConfig, false)), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.msgList.length,
      expression: "msgList.length"
    }],
    staticClass: "chat-window"
  }, [_c("div", {
    ref: "list",
    staticClass: "chat-content",
    on: {
      scroll: _vm.handleListScroll
    }
  }, [_vm.hasHistory && _vm.loading && !_vm.isNoMore ? _c("p", {
    staticClass: "loading-text"
  }, [_vm._v(" 加载中... ")]) : _vm._e(), _c("chat-message", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isNoMore,
      expression: "isNoMore"
    }]
  }, [_c("chat-guide", {
    attrs: {
      "guide-list": _vm.promptConfig.guideList,
      "guide-tips": _vm.promptConfig.guideTips,
      "hello-text": _vm.promptConfig.helloText
    },
    on: {
      change: _vm.createQA
    }
  })], 1), _vm._l(_vm.msgList, function (item, index) {
    return _c("chat-message", _vm._g(_vm._b({
      key: index,
      ref: `message_item_${index}`,
      refInFor: true,
      attrs: {
        message: item,
        "message-operate-list": _vm.messageOperateList
      },
      on: {
        commentScopeChange: function ($event) {
          return _vm.$emit("commentScopeChange", {
            scope: $event,
            index
          });
        }
      }
    }, "chat-message", _vm.$attrs, false), _vm.$listeners));
  })], 2), _c("div", {
    staticClass: "input-utils"
  }, [_c("chat-shortcuts", {
    attrs: {
      "shortcuts-list": _vm.promptConfig.shortcutsList,
      "show-stop-reply": _vm.showStopReply,
      "show-ask-again": _vm.showAskAgain
    },
    on: {
      change: _vm.createQA,
      stopReply: _vm.stopReply,
      askAgain: _vm.askAgain
    }
  }), _vm.isShowChatInput ? _c("chat-input", {
    attrs: {
      disabled: _vm.isAnswering
    },
    on: {
      change: _vm.createQA
    }
  }) : _vm._e()], 1)])]]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;