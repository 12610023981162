"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
var _organization = require("@/api/organization");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex.js");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'RowEditTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    contentPlaceholder: {
      type: String,
      default: '请输入指令内容，上限600字，例如：\n1.平均通话时长是多少？\n2.有几次会话中客户提到了竞品，占比多少？\n3.客户常见的顾虑是什么？'
    },
    contentMaxLength: {
      type: Number,
      default: 600
    }
  },
  emits: ['deleteRow', 'saveNewRow', 'saveEditRow'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();

    // 拿到空间信息
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const tableData = (0, _vue.ref)([]);

    // 监听props.data变化对tableData进行处理，每项增加editable: false
    (0, _vue.watchEffect)(() => {
      // 深拷贝，避免影响props.data
      tableData.value = props.data.map(item => {
        return {
          ...item,
          editable: false
        };
      });
    });
    const headerList = [{
      prop: 'name',
      label: '指令名称',
      editable: false,
      width: 240
    }, {
      prop: 'content',
      label: '指令内容',
      editable: false,
      minWidth: 400
    }, {
      prop: 'departments',
      label: '应用范围',
      editable: false,
      width: 320
    }, {
      prop: 'user_info',
      label: '创建人',
      editable: false,
      width: 180
    }, {
      prop: 'create_at',
      label: '创建时间',
      editable: false,
      width: 180
    }];
    const activeRow = (0, _vue.ref)(null);
    const textareaRef = (0, _vue.ref)(null);
    const addRow = async row => {
      await (0, _vue.nextTick)();
      const cloneRow = (0, _cloneDeep.default)(row);
      const index = tableData.value.findIndex(item => item.id === cloneRow.id);
      if (index > -1) {
        cloneRow.editable = true;
        emit('editStatusChange', true);
        cloneRow.isAdd = true;
        tableData.value.splice(index, 1, cloneRow);
        // 赋值后进入编辑模式
        editRow(cloneRow);
      }
    };
    const deleteRow = (row, index) => {
      // 确认是否删除
      _elementUi.MessageBox.confirm('是否删除该指令?', '删除指令', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        emit('deleteRow', row, index, () => {
          initRowStatus(row);
        });
      });
    };
    const editRow = async row => {
      if (activeRow.value) {
        // 如果有正在编辑的行处理
        const index = tableData.value.findIndex(item => item.id === activeRow.value.id);
        activeRow.value.editable = false;
        tableData.value.splice(index, 1, activeRow.value);
      }
      row.editable = true;
      emit('editStatusChange', true);
      activeRow.value = (0, _cloneDeep.default)(row); // 深拷贝row;
      await (0, _vue.nextTick)();
      textareaRef.value[0].focus();
    };
    const cancelEdit = (row, index) => {
      if (row.isAdd) {
        emit('deleteRow', row, index, () => {
          initRowStatus(row);
        });
      } else {
        activeRow.value.editable = false;
        tableData.value.splice(index, 1, activeRow.value);
        initRowStatus(row);
      }
    };
    const saveLoading = (0, _vue.ref)(false);
    const nameValidateDuplicate = name => {
      const validateHasSameKey = tableData.value.some(item => item.name === name);
      if (validateHasSameKey) {
        _elementUi.Message.warning('指令名称不能重复');
        return true;
      }
    };
    const saveRow = row => {
      if (!row.name || !row.content || !row.departments || !row.departments.length) {
        _elementUi.Message.warning('指令未填写完整');
        return;
      }
      const validateHasSameName = tableData.value.filter(item => item.name === row.name).length > 1;
      if (validateHasSameName) {
        _elementUi.Message.warning('团队中已存在相同名称');
        return true;
      }
      if (row.content.length > 2000) {
        _elementUi.Message.warning('指令内容不能超过2000字');
        return;
      }
      // 如果row.departments的数组项时对象，则将其转为id值的数组
      if (row.departments[0] && row.departments[0].id) {
        row.departments = getDepartmentsIds(row.departments);
      }
      saveLoading.value = true;

      // 保存请求
      if (row.isAdd) {
        emit('saveNewRow', row, isSuccess => {
          if (isSuccess) {
            row.isAdd = false;
            initRowStatus(row);
          }
          saveLoading.value = false;
        });
      } else {
        emit('saveEditRow', row, isSuccess => {
          if (isSuccess) {
            initRowStatus(row);
          }
          saveLoading.value = false;
        });
      }
    };
    const initRowStatus = row => {
      row.editable = false;
      saveLoading.value = false;
      activeRow.value = null;
      hasSelectDepartment.value = false;
      emit('editStatusChange', false);
    };
    const hasSelectDepartment = (0, _vue.ref)(false);
    const handleDepartmentValueChange = (val, row) => {
      row.departments = val;
      hasSelectDepartment.value = true;
    };
    const getDepartmentsIds = row => {
      var _activeRow$value;
      if (hasSelectDepartment.value) {
        // 如果是在选择过程中则返回当前行的departments
        return row.departments;
      }
      // 如果是切换到编辑模式时第一次回填则返回activeRow的departments
      const departments = (0, _cloneDeep.default)((_activeRow$value = activeRow.value) === null || _activeRow$value === void 0 ? void 0 : _activeRow$value.departments);
      if (!departments || !departments.length) return [];
      return departments.map(item => item.id);
    };
    expose({
      addRow
    });
    return {
      __sfc: true,
      props,
      emit,
      store,
      workspaceInfo,
      tableData,
      headerList,
      activeRow,
      textareaRef,
      addRow,
      deleteRow,
      editRow,
      cancelEdit,
      saveLoading,
      nameValidateDuplicate,
      saveRow,
      initRowStatus,
      hasSelectDepartment,
      handleDepartmentValueChange,
      getDepartmentsIds,
      VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default,
      getOrgTree: _organization.getOrgTree
    };
  }
};
exports.default = _default;