"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-scene"
  }, [_c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.inputVisible,
      expression: "!inputVisible"
    }],
    class: {
      "display-tag": _vm.displayOnly
    },
    attrs: {
      "disable-transitions": true
    },
    on: {
      click: function ($event) {
        return _vm.handleTagClick();
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-box-square"
  }), _vm.value ? _c("span", [_vm._v(_vm._s(_vm.value))]) : _c("span", {
    staticClass: "tips"
  }, [_vm._v("添加场景名称，选填")])]), _c("el-input", _vm._g({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputVisible,
      expression: "inputVisible"
    }],
    ref: "saveTagInput",
    attrs: {
      value: _vm.value,
      placeholder: "最多输入20个字",
      clearable: "",
      maxlength: "20"
    },
    on: {
      blur: _vm.handleInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm.apply(null, arguments);
      }
    }
  }, _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;