"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
var _default = {
  name: 'FollowupDynamicSelector',
  props: {
    conversationTitleInfo: {
      type: Object,
      default: () => ({})
    },
    convId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      convTitle: '',
      dataIndex: null,
      totalList: [],
      // 所有的会话列表
      totalCount: 0,
      currentList: [],
      // 当前的会话列表
      size: 10,
      loading: false,
      visible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone']),
    getDay() {
      return function (value) {
        return this.$moment(value).format('M-D');
      };
    },
    getCurrentDataIndex() {
      var _this$totalList$find;
      if (!this.currentList.length) return;
      return (_this$totalList$find = this.totalList.find(item => item.id === Number(this.convId))) === null || _this$totalList$find === void 0 ? void 0 : _this$totalList$find.data_index;
    },
    isShowPreBtn() {
      if (this.currentList && this.currentList.length > 0) {
        if (this.currentList[this.currentList.length - 1].data_index === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isShowNextBtn() {
      if (this.currentList && this.currentList.length > 0) {
        if (this.currentList[0].data_index === this.totalCount - 1) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    size() {
      if (this.totalList.length === this.totalCount && this.totalCount <= this.size) {
        return false;
      }
      this.dataIndex = null;
      this.getInterGroupList();
    },
    convId: {
      handler: function () {
        this.getInterGroupList();
      },
      immediate: true
    }
  },
  created() {
    this.convTitle = this.conversationTitleInfo.convName;
    const firstIndex = this.$route.query.index; // 是否切换时第一个的index值
    if (typeof firstIndex === 'number') {
      this.getInterGroupList('', this.size, firstIndex);
    } else {
      this.getInterGroupList();
    }
  },
  mounted() {},
  methods: {
    changeId(id) {
      const firstIndex = this.currentList[this.currentList.length - 1].data_index;
      this.$emit('changeId', id.toString(), firstIndex);
      this.visible = false;
    },
    getConvClass(type) {
      return {
        doc: 'icon-im',
        audio: 'icon-phone-outline',
        video: 'icon-video-camera'
      }[type];
    },
    async getInterGroupList(type, extendSize, extendIndex) {
      const params = {
        conversation_id: this.convId,
        size: typeof extendSize === 'number' ? extendSize : this.size,
        data_index: typeof extendIndex === 'number' ? extendIndex : this.dataIndex,
        time_zone: this.timezone
      };
      this.loading = true;
      const res = await (0, _videoDetail.getInterGroupList)(params);
      try {
        if (res.code === 20000) {
          this.totalCount = res.results.total_count;
          res.results.conversations.reverse();
          if (typeof this.dataIndex === 'number') {
            this.handleTotalList(type, res.results.conversations);
            this.handleCurrentList(type, res.results.conversations);
          } else {
            // 未点击时的请求
            this.totalList = res.results.conversations;
            this.currentList = res.results.conversations;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    handleTotalList(type, array) {
      if (type === 'pre') {
        this.totalList = [...this.totalList, ...array];
      } else {
        this.totalList = [...array, ...this.totalList];
      }
    },
    handleCurrentList(type, array) {
      const resIndex = this.totalList.findIndex(item => {
        return item.data_index === this.dataIndex;
      });
      if (this.dataIndex + this.size > this.totalList[0].data_index) {
        this.currentList = this.totalList.slice(0, this.size);
      } else {
        this.currentList = this.totalList.slice(resIndex + 1 - this.size, resIndex + 1);
      }
    },
    goPrePage() {
      this.dataIndex = this.currentList[this.currentList.length - 1].data_index - this.size;
      let extendSize = 0;
      if (this.dataIndex < 0) {
        this.dataIndex = 0;
      }
      const resIndex = this.totalList.findIndex(item => {
        return item.data_index === this.dataIndex;
      });
      if (resIndex !== -1) {
        // 如果存在，直接截取现有的数组
        this.currentList = this.totalList.slice(resIndex + 1 - this.size, resIndex + 1);
      } else {
        // 如果不存在，分为整页请求还是最后的补充请求
        const extendIndex = this.totalList[this.totalList.length - 1].data_index - this.size;
        if (extendIndex >= 0) {
          // 整页请求
          this.getInterGroupList('pre', this.size, extendIndex);
        } else {
          // 补充请求
          const firstIndex = this.totalList[this.totalList.length - 1].data_index;
          extendSize = firstIndex;
          this.getInterGroupList('pre', extendSize, 0);
        }
      }
    },
    goNextPage() {
      this.dataIndex = this.currentList[0].data_index + 1;
      let extendSize = 0;
      const resIndex = this.totalList.findIndex(item => {
        if (this.dataIndex + this.size <= this.totalCount - 1) {
          return item.data_index === this.dataIndex + this.size;
        } else {
          return item.data_index === this.totalCount - 1;
        }
      });
      if (resIndex !== -1) {
        // 如果存在，直接截取现有的数组
        if (this.dataIndex + this.size <= this.totalCount - 1) {
          // 如果不是最后一页
          this.currentList = this.totalList.slice(resIndex + 1, resIndex + 1 + this.size);
        } else {
          // 最后一页时显示最后的整页
          this.currentList = this.totalList.slice(0, this.size);
        }
      } else {
        // 如果存在，分为整页请求还是最后的补充请求
        const lastIndex = this.totalList[0].data_index;
        if (this.dataIndex + this.size <= this.totalCount - 1) {
          // 整页请求
          if (this.dataIndex >= lastIndex) {
            // 如果下一页第一个索引大于所有数组最后一个索引，则直接请求整页
            this.getInterGroupList('next');
          } else {
            extendSize = this.size - (lastIndex - this.dataIndex);
            this.getInterGroupList('next', extendSize, lastIndex + 1);
          }
        } else {
          // 补充请求
          extendSize = this.totalCount - 1 - lastIndex;
          this.getInterGroupList('next', extendSize, lastIndex + 1);
        }
      }
    }
  }
};
exports.default = _default;