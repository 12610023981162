"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _treeMixin = _interopRequireDefault(require("@/mixin/treeMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentTree',
  mixins: [_treeMixin.default],
  props: {
    isSetRoot: Boolean,
    value: {
      type: [Number, String],
      default: ''
    },
    orgTree: {
      type: Array,
      default: () => []
    },
    disabledArr: {
      type: Array,
      default: () => []
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name',
        disabled: this.setDisabled
      },
      loading: false
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.orgTree.length && this.setCurrentKey(val);
      } else {
        this.$refs.tree.setCurrentKey(null);
      }
    }
  },
  async mounted() {
    if (this.value) this.setCurrentKey(this.value);
    this.isSetRoot && this.setRoot();
  },
  methods: {
    setDisabled(value, data) {
      return this.disabledArr.includes(data.data.id);
    },
    setRoot() {
      const tree = this.$refs.tree;
      const key = this.orgTree[0].id;
      this.setCurrentKey(key);
      const node = tree.getNode(key);
      this.handleCurrentChange(node.data);
    },
    setCurrentKey(key) {
      const node = this.$refs.tree.getNode(key);
      this.$refs.tree.setCurrentKey(key);
      this.expandAllParents(node);
      this.handleCurrentChange(node.data);
    },
    getNode(key) {
      return this.$refs.tree.getNode(key);
    },
    handleCurrentChange(data) {
      this.$emit('change', data);
    }
  }
};
exports.default = _default;