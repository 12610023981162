"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "course-script"
  }, [_c("div", {
    staticClass: "script-header"
  }, [_c("div", {
    staticClass: "space-container"
  }, [_c("span", [_vm._v("选择话术")]), _c("KnowledgeBaseDropdown")], 1), _c("el-button", {
    staticClass: "hover-show-bg",
    attrs: {
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeScript
    }
  })], 1), _c("el-input", {
    ref: "searchInput",
    attrs: {
      placeholder: "搜索",
      clearable: ""
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })]), _c("div", {
    staticClass: "knowledge-container"
  }, [_c("KnowledgeMultipleTree", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchValue,
      expression: "!searchValue"
    }],
    on: {
      scrollToScriptEnd: _vm.scrollToScriptEnd
    }
  }), _c("KnowledgeSearchContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    attrs: {
      "search-value": _vm.searchValue
    },
    on: {
      scrollToScriptEnd: _vm.scrollToScriptEnd
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;