import { render, staticRenderFns } from "./MgCheckbox.vue?vue&type=template&id=59755fca&scoped=true&"
import script from "./MgCheckbox.vue?vue&type=script&lang=js&"
export * from "./MgCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./MgCheckbox.vue?vue&type=style&index=0&id=59755fca&lang=scss&scoped=true&"
import style1 from "./MgCheckbox.vue?vue&type=style&index=1&id=59755fca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59755fca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59755fca')) {
      api.createRecord('59755fca', component.options)
    } else {
      api.reload('59755fca', component.options)
    }
    module.hot.accept("./MgCheckbox.vue?vue&type=template&id=59755fca&scoped=true&", function () {
      api.rerender('59755fca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DynamicForm/components/MgCheckbox.vue"
export default component.exports