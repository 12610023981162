"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableResults.loading,
      expression: "tableResults.loading"
    }],
    staticClass: "conversation-recycle-bin"
  }, [_c(_setup.ConvRecycleBinHeader), _c(_setup.ConvRecycleBinTable, {
    attrs: {
      "table-data": _setup.tableResults.tableData
    },
    on: {
      recoverRecycleBin: _setup.recoverRecycleBin,
      deleteRecycleBin: _setup.deleteRecycleBin
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _setup.paginationParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _setup.paginationParams.size,
      total: _setup.tableResults.total
    },
    on: {
      "size-change": _setup.sizeChange,
      "current-change": _setup.currentChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;