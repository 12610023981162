"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
var _formatContactFormDescMixins = _interopRequireDefault(require("./formatContactFormDescMixins"));
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _timezone = require("@/utils/timezone");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateClientsDialog',
  components: {
    DynamicForm: _DynamicForm.default
  },
  mixins: [_formatContactFormDescMixins.default],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clientDynamicForm: null,
      formDesc: [],
      createClientForm: {},
      accountVal: null,
      getFormLoading: false,
      saveLoading: false,
      // 联系人
      createContact: true,
      contactFormData: {},
      contactFormDesc: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['contactDynamicFormDescList', 'api_permissions']),
    contactDynamicFormNoAccountList() {
      return this.contactDynamicFormDescList.filter(item => {
        return item.id !== 'customer_name';
      });
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.resetForm();
        this.formDesc = [];
        this.contactFormDesc = [];
      }
    },
    formDesc(val) {
      this.createClientForm = {
        ...this.formDesc['default_value']
      };
    }
  },
  created() {
    this.getClientDynamicForm();
    this.$store.dispatch('account/getContactDynamicForm');
  },
  methods: {
    async getClientDynamicForm() {
      this.getFormLoading = true;
      const clientDynamicForm = await (0, _client.getClientDynamicForm)();
      if (clientDynamicForm.code === 200) {
        this.clientDynamicForm = clientDynamicForm.results;
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.clientDynamicForm)));
        this.contactFormDesc = this.descContactFormat(this.contactDynamicFormNoAccountList, true);
      }
      this.getFormLoading = false;
    },
    async postCreateContact(data) {
      const res = await (0, _client.postCreateContact)(data);
      if (res.code === 200) {
        this.$message.success('新建成功');
        this.$emit('fetchAccountSuccess', this.accountVal);
        this.closeDialog();
      }
    },
    async postCreateAccountSeaContact(data) {
      const res = await (0, _client.postCreateAccountSeaContact)(data);
      if (res.code === 200) {
        this.$message.success('新建成功');
        this.closeDialog();
      }
    },
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    async submitForm() {
      const data = {
        account: this.createClientForm,
        is_public: this.isPublic
      };
      let flag = false;
      if (this.$refs['dynamicForm'].validate()) flag = true;
      if (this.createContact && !this.$refs['contactDynamicForm'].validate()) {
        flag = false;
      }
      if (flag) {
        if (this.isPublic) {
          // 创建公海客户
          await this.postCreateAccountSea(data);
          // 创建联系人
          if (this.createContact) {
            const contactData = {
              account_id: this.accountVal.id,
              contact: this.contactFormData
            };
            this.postCreateAccountSeaContact(contactData);
          }
        } else {
          // 创建客户
          await this.postCreateClient(data);
          if (this.createContact) {
            const contactData = {
              account_id: this.accountVal.id,
              contact: this.contactFormData
            };
            this.postCreateContact(contactData);
          }
        }
      }
    },
    // 客户新建客户的请求
    async postCreateClient(data) {
      this.saveLoading = true;
      (0, _timezone.datePickerWithTimeParse2Zone)(data.account);
      const res = await (0, _client.postCreateClient)(data);
      if (res.code === 200) {
        this.accountVal = res.results;
        this.$emit('freshList', this.accountVal);
        // 没有同时创建联系人发的
        if (!this.createContact) {
          this.$emit('fetchAccountSuccess', this.accountVal);
          this.closeDialog();
        }
        this.$message.success('新建客户成功');
      }
      this.saveLoading = false;
    },
    // 公海新建客户的请求
    async postCreateAccountSea(data) {
      this.saveLoading = true;
      const res = await (0, _client.postCreateAccountSea)(data);
      if (res.code === 200) {
        this.accountVal = res.results;
        this.$emit('freshList', this.accountVal);
        if (!this.createContact) {
          this.$emit('fetchAccountSuccess', this.accountVal);
          this.closeDialog();
        }
        this.$message.success('新建公海客户成功');
      }
      this.saveLoading = false;
    }
  }
};
exports.default = _default;