"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RuleGroupItem = _interopRequireDefault(require("./RuleGroupItem.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TriggerRulesFormItem',
  components: {
    RuleGroupItem: _RuleGroupItem.default
  },
  inject: {
    isReadOnly: {
      default: false
    }
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    ruleCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isReadonly() {
      return this.isReadOnly;
    }
  },
  methods: {
    handleAddRuleGroup() {
      // eslint-disable-next-line vue/no-mutating-props
      this.form.tracker_groups.push({
        key: String(this.form.tracker_groups.length + 1),
        rule: [{
          apply_to: 3,
          include: [{
            type: 'keywords',
            value: []
          }],
          exclude: [{
            type: 'keywords',
            value: []
          }]
        }],
        context: []
      });
    },
    handleDeleteRuleGroupItem(ruleGroupIndex) {
      console.log(ruleGroupIndex, 'ruleGroupIndex');
      // eslint-disable-next-line vue/no-mutating-props
      this.form.tracker_groups.splice(ruleGroupIndex, 1);
    }
  }
};
exports.default = _default;