"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useConvRecycleBin = useConvRecycleBin;
exports.useDeleteConvRecycleBin = useDeleteConvRecycleBin;
exports.useRecoverConvRecycleBin = useRecoverConvRecycleBin;
var _vue = require("vue");
var _convRecycleBin = require("@/api/convRecycleBin");
var _elementUi = require("element-ui");
function useConvRecycleBin(store) {
  const tableResults = (0, _vue.ref)({
    loading: false,
    tableData: [],
    total: 0
  });
  const paginationParams = (0, _vue.ref)({
    page: 1,
    size: 10
  });
  const getConvRecycleBinApi = async () => {
    tableResults.value.loading = true;
    const res = await (0, _convRecycleBin.getConvRecycleBin)({
      workspace_id: store.getters.workspaceInfo.id,
      ...paginationParams.value
    }).finally(() => {
      tableResults.value.loading = false;
    });
    if (res.code === 20000) {
      tableResults.value.tableData = res.results.datas;
      tableResults.value.total = res.results.total_count;
    }
  };
  const sizeChange = val => {
    paginationParams.value.size = val;
    paginationParams.value.page = 1;
    getConvRecycleBinApi();
  };
  const currentChange = val => {
    paginationParams.value.page = val;
    getConvRecycleBinApi();
  };
  (0, _vue.watch)(() => store.getters.workspaceInfo.id, val => {
    if (val) {
      getConvRecycleBinApi();
    }
  }, {
    immediate: true
  });
  return {
    getConvRecycleBinApi,
    sizeChange,
    currentChange,
    tableResults,
    paginationParams
  };
}
function useDeleteConvRecycleBin(store, getConvRecycleBinApi) {
  const deleteConvRecycleBinApi = async id => {
    const res = await (0, _convRecycleBin.deleteConvRecycleBin)({
      workspace_id: store.getters.workspaceInfo.id,
      id
    });
    if (res.code === 20000) {
      _elementUi.Message.success('删除成功');
      getConvRecycleBinApi();
    }
  };
  return {
    deleteConvRecycleBinApi
  };
}
function useRecoverConvRecycleBin(store, getConvRecycleBinApi) {
  const recoverConvRecycleBinApi = async id => {
    const res = await (0, _convRecycleBin.recoverConvRecycleBin)({
      workspace_id: store.getters.workspaceInfo.id,
      id
    });
    if (res.code === 20000) {
      _elementUi.Message.success('恢复成功');
      getConvRecycleBinApi();
    }
  };
  return {
    recoverConvRecycleBinApi
  };
}