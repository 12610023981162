"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _conversationList = require("@/api/conversationList");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CorrectTextContainer',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default,
    ImageAvatar: _ImageAvatar.default
  },
  data() {
    return {
      correctTextHistoryParams: {
        conversation_id: '',
        size: 10,
        page: 1
      },
      correctTextHistoryList: [],
      total: 0,
      correctTextHistoryListLength: 0,
      loading: false,
      activeCardIndex: null
    };
  },
  computed: {
    noMore() {
      return this.correctTextHistoryListLength && this.correctTextHistoryListLength === this.total;
    }
  },
  created() {
    const conversation_id = this.$route.query.id;
    this.correctTextHistoryParams.conversation_id = conversation_id;
    this.getCorrectTextHistory();
  },
  methods: {
    async getCorrectTextHistory(isInit) {
      this.loading = true;
      if (isInit) {
        this.correctTextHistoryParams.page = 1;
        this.correctTextHistoryList = [];
        this.correctTextHistoryListLength = 0;
      }
      const res = await (0, _conversationList.getCorrectTextHistory)({
        ...this.correctTextHistoryParams
      });
      if (res.code === 20000) {
        res.results.data.forEach(item => {
          this.correctTextHistoryList.push(item);
        });
        this.total = res.results.total;
        this.correctTextHistoryListLength += res.results.data.length;
        this.correctTextHistoryParams.page++;
      }
      this.loading = false;
    },
    clickCard(val, index) {
      this.activeCardIndex = index;
      const {
        order
      } = val;
      this.$emit('selectTransText');
      this.$store.commit('video/SET_IS_VIDEO_PAUSE', true);
      this.$store.dispatch('video/set_cur_order', {
        order: Number(order),
        time: new Date().getTime()
      });
    },
    load() {
      this.getCorrectTextHistory();
    }
  }
};
exports.default = _default;