"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DimensionList = _interopRequireDefault(require("./DimensionList.vue"));
var _vue = require("vue");
var _MetricsFilterTable = _interopRequireDefault(require("./MetricsFilterTable.vue"));
var _MetricCreateDialog = _interopRequireDefault(require("./MetricCreateDialog.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import
var _default = {
  __name: 'MetricsManegeDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const dimensionRef = (0, _vue.ref)(null);
    (0, _vue.watch)(() => props.visible, async val => {
      if (val) {
        var _dimensionRef$value;
        await (0, _vue.nextTick)();
        (_dimensionRef$value = dimensionRef.value) === null || _dimensionRef$value === void 0 ? void 0 : _dimensionRef$value.initData();
      }
    });
    const activeDimension = (0, _vue.ref)({});
    const metricTableRef = (0, _vue.ref)(null);
    const onDimensionNodeClick = async val => {
      activeDimension.value = val;
      await (0, _vue.nextTick)();
      getMetricList();
    };
    function getMetricList() {
      var _metricTableRef$value;
      (_metricTableRef$value = metricTableRef.value) === null || _metricTableRef$value === void 0 ? void 0 : _metricTableRef$value.fetchData();
    }
    const onCreateMetricSuccess = () => {
      getMetricList();
      dimensionRef.value.updateCount(activeDimension.value.id, 1);
    };
    const metricCreateDialogVisible = (0, _vue.ref)(false);
    const openCreateMetricDialog = () => {
      metricCreateDialogVisible.value = true;
    };
    const onMetricDeleteSuccess = metric => {
      dimensionRef.value.updateCount(metric.dimension_id, -1);
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      dimensionRef,
      activeDimension,
      metricTableRef,
      onDimensionNodeClick,
      getMetricList,
      onCreateMetricSuccess,
      metricCreateDialogVisible,
      openCreateMetricDialog,
      onMetricDeleteSuccess,
      DimensionList: _DimensionList.default,
      MetricsFilterTable: _MetricsFilterTable.default,
      MetricCreateDialog: _MetricCreateDialog.default
    };
  }
};
exports.default = _default;