"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.title,
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "1200px"
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c("el-tab-pane", {
      key: tab.name,
      attrs: {
        label: tab.label,
        name: tab.name
      }
    }, [tab.name === "modelDetails" ? [_c("dynamic-form", {
      attrs: {
        "is-edit": false,
        desc: _vm.modelDetailsdesc,
        "label-width": "60px"
      },
      scopedSlots: _vm._u([{
        key: "owner",
        fn: function () {
          return [_c("div", {
            staticClass: "avatar-name"
          }, [_c("mgv-avatar-name", {
            attrs: {
              size: 24,
              src: _vm.modelDetailFormData["owner"].avatar,
              name: _vm.modelDetailFormData["owner"].name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(_vm.modelDetailFormData["owner"].name))])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.modelDetailFormData,
        callback: function ($$v) {
          _vm.modelDetailFormData = $$v;
        },
        expression: "modelDetailFormData"
      }
    })] : _vm._e(), tab.name === "versionComparison" ? [_c("PrecisionDetailOverview")] : _vm._e(), tab.name === "history" ? [_c("HistoryTable", {
      attrs: {
        loading: _vm.historyTableLoading,
        "table-data": _vm.historyTableData
      }
    })] : _vm._e()], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;