"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'AnnotateResultsTabs',
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    activeTabs: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:activeTabs'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const tabClick = item => {
      emit('update:activeTabs', item);
      emit('activeTabsChange');
    };
    return {
      __sfc: true,
      props,
      emit,
      tabClick
    };
  }
};
exports.default = _default;