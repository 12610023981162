"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "space-button"
  }, [_c("el-popover", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/webapi/organization/workspace/change_list"],
      expression: "['/webapi/organization/workspace/change_list']"
    }],
    staticClass: "workspace-icon",
    attrs: {
      "popper-class": "workspace-list-popover",
      width: "240",
      trigger: "manual",
      placement: "bottom-start"
    },
    model: {
      value: _vm.workspacePopoverVisible,
      callback: function ($$v) {
        _vm.workspacePopoverVisible = $$v;
      },
      expression: "workspacePopoverVisible"
    }
  }, [_c("WorkspaceListBox", {
    ref: "workspaceListBox",
    attrs: {
      "workspace-list": _vm.workspaceList
    },
    on: {
      click: _vm.closePop
    }
  }), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("el-button", {
    staticClass: "message-icon workspace-button",
    style: {
      color: _vm.workspaceInfo.icon_color,
      "border-color": _vm.workspaceInfo.icon_color
    },
    attrs: {
      id: "workspace-icon-button",
      icon: "iconfont icon-workspace-3-fill",
      circle: "",
      disabled: _vm.isWorkspaceButtonDisabled
    },
    on: {
      click: _vm.openWorkspacePopover
    }
  }, [_vm._v(_vm._s(_vm.workspaceInfo.name))])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;