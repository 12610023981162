"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _ClientDetailDrawer = _interopRequireDefault(require("./ClientDetailDrawer"));
var _client = require("@/api/client");
var _LinkText = _interopRequireDefault(require("@/components/LinkText"));
var _vuex = require("vuex");
var _clueAndClientSortMixin = _interopRequireDefault(require("@/mixin/clueAndClientSortMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountSeaTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ClientDetailDrawer: _ClientDetailDrawer.default,
    LinkText: _LinkText.default
  },
  mixins: [_clueAndClientSortMixin.default],
  props: {
    headerList: {
      type: Array,
      default() {
        return null;
      }
    },
    accountSeaTableLoading: {
      type: Boolean
    },
    accountSeaList: {
      type: Array,
      default: () => []
    },
    sortColumnList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 126,
      accountSeaInfo: null,
      accountSeaDeawerVisible: false,
      rowInfo: null,
      // 是否显示一些按钮,通过isShow统一控制
      isCallUpAndCreateDealShow: false,
      activeAccountSeaId: null,
      // 不要删除lastSort
      lastSort: {
        prop: 'add_pool_time',
        order: 'descending'
      },
      pageType: 'accountSea',
      defaultSortColumn: 'add_pool_time'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'api_permissions']),
    commondVisible() {
      const arr = ['/api/account/account/account_pool_detail', '/api/account/account_pool/receive_follow_up_by', '/api/account/account_pool/assigns_follow_up_by'];
      const res = arr.some(element => this.api_permissions.includes(element));
      return res;
    }
  },
  watch: {
    accountSeaList() {
      this.$nextTick(() => {
        this.$refs.accountSeaTable.doLayout();
      });
    }
  },
  mounted() {
    var _this$$route, _this$$route$query;
    window.addEventListener('resize', this.setTableHeight);
    this.$bus.$on('freshAccountDetailAndCloseAccountSeaDrawer', this.closeAccountSeaDetailDrawer);
    if ((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id) {
      var _this$$route2;
      this.goAccountSeaDetail((_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.query);
    }
  },
  beforeDestroy() {
    this.$bus.$off('freshAccountDetailAndCloseAccountSeaDrawer');
  },
  destroyed() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    tableRowClassName(_ref) {
      let {
        row,
        rowIndex
      } = _ref;
      if (row.highlight === true && rowIndex === 0) {
        return 'highlight-row';
      }
      return '';
    },
    // 查看客户公海详情权限
    accountSeaDetailPermission() {
      return this.api_permissions.includes('/api/account/account/account_pool_detail');
    },
    closeAccountSeaDetailDrawer() {
      this.accountSeaDeawerVisible = false;
    },
    formatterTest(row, column, cellValue, index) {
      return cellValue || '-';
    },
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 126;
    },
    handleSelectionChange(val) {
      this.$emit('selectionChange', val);
    },
    handleCommand(row, command) {
      switch (command) {
        case 'edit':
          this.goAccountSeaDetail(row);
          break;
        case 'claim':
          {
            const claimIds = [row.id];
            this.claimToMe(claimIds);
          }
          break;
        case 'allocate':
          {
            const allocateIds = [row.id];
            this.allocateToAnyone(allocateIds);
          }
          break;
        default:
          break;
      }
    },
    goAccountSeaDetail(row) {
      this.activeAccountSeaId = row.id;
      if (this.accountSeaDetailPermission()) {
        this.accountSeaDeawerVisible = true;
      }
    },
    claimToMe(ids) {
      this.$confirm(`是否确认领取所选择的客户？`, '领取', {
        confirmButtonText: '领 取',
        confirmButtonClass: 'el-button--primary',
        cancelButtonText: '取 消'
      }).then(async () => {
        const res = await (0, _client.accountSeaReceiveFollowUpBy)({
          id: ids,
          follow_up_by: this.userId
        });
        if (res.code === 20000) {
          this.$message.success('领取成功！');
          this.$emit('freshAccountSeaList');
          if (this.accountSeaDeawerVisible) {
            this.closeAccountSeaDetailDrawer();
          }
        }
      });
    },
    allocateToAnyone(ids) {
      this.$emit('allocateToAnyone', ids, 'clientAllocateToAnyone');
    }
  }
};
exports.default = _default;