"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _commonFunc = require("@/utils/commonFunc");
var _ruleTypeOptionsMixin = require("@/mixin/ruleTypeOptionsMixin");
var _KeyEventDisplay = _interopRequireDefault(require("./KeyEventDisplay.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 最大值
const MAXSCORE = 9999.99;
var _default = {
  name: 'ScoreItemSet',
  components: {
    MgDraggable: _MgDraggable.default,
    KeyEventDisplay: _KeyEventDisplay.default
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isSaveValidate: {
      type: Boolean,
      default: false
    },
    hasAllowOptionsArr: {
      type: Array,
      default: () => []
    },
    accountTagOptionsList: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: 'conv'
    },
    currentScoreId: {
      type: [Number, String, Symbol],
      default: ''
    }
  },
  data() {
    return {
      marks: {
        0: '0%',
        20: '20%',
        40: '40%',
        60: '60%',
        80: '80%',
        100: '100%'
      },
      isEditFullMark: false,
      ruleItemsObj: {},
      scoreValidateTip: '',
      accountLabelArray: [],
      configUnitList: ['条', '%', '次', '秒', '字/分钟']
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId']),
    typeOptions() {
      const map = {};
      this.hasAllowOptionsArr.map(item => {
        map[item['item_type']] = _ruleTypeOptionsMixin.ruleTypeOptions[item['item_type']];
      });
      this.$store.commit('ruleSetting/SET_TYPE_OPTIONS', map || _ruleTypeOptionsMixin.ruleTypeOptions);
      return map || _ruleTypeOptionsMixin.ruleTypeOptions;
    },
    localScoreObj: {
      get() {
        var _this$value$score_ite;
        const v = this.value;
        if ((_this$value$score_ite = this.value.score_items) !== null && _this$value$score_ite !== void 0 && _this$value$score_ite.length) {
          this.value.score_items.forEach(item => {
            if (typeof item.config === 'string') {
              item.config = JSON.parse(item.config);
            }
          });
        }
        return v;
      },
      set(val) {
        let v = (0, _commonFunc.deepClone)(this.value);
        v = val;
        this.$emit('input', v);
      }
    },
    maxCount() {
      return this.orgId === 229 ? 100 : 30;
    }
  },
  watch: {
    accountTagOptionsList: {
      handler: function (val) {
        this.getAccountLabelArray(val);
      }
    },
    'localScoreObj.score_items': {
      handler: function () {
        this.validateRuleScore();
      },
      deep: true
    },
    'localScoreObj.scoring_mode': {
      handler: function (val) {
        this.localScoreObj.score_items.forEach(item => {
          if (item.score_ext) {
            const score_ext = JSON.parse(item.score_ext);
            score_ext.mode = val;
            item.score_ext = JSON.stringify(score_ext);
          }
        });
      }
    }
  },
  methods: {
    getTotalScore(score, score_type) {
      let total = 0;
      const scoreExtList = this.getScoreExtList(score);
      scoreExtList.forEach(item => {
        if (score_type === 2 && item.gte && item.lte && item.score) {
          total += (Number(item.lte) - Number(item.gte) + 1) * item.score;
        }
        if (score_type === 3 && item.score && total < item.score) {
          total = Number(item.score);
        }
      });
      return total;
    },
    getScoreExtList(scoreExt) {
      return scoreExt ? JSON.parse(scoreExt).items : [];
    },
    getAccountLabelArray(options) {
      options.forEach(ele => {
        this.accountLabelArray.push({
          id: ele.id,
          name: ele.name
        });
        if (ele.children) {
          this.getAccountLabelArray(ele.children, this.accountLabelArray);
        }
      });
    },
    getConvDuration(duration) {
      if (!duration.length) return;
      if (duration[0] === duration[1]) {
        return this.formatTime(duration[0]);
      } else if (duration[0] > 0) {
        if (duration[1] === -1) {
          return this.formatTime(duration[0]) + '以上';
        } else {
          return this.formatTime(duration[0]) + ' - ' + this.formatTime(duration[1]);
        }
      } else if (duration[0] === 0 && duration[1] > 0) {
        return this.formatTime(duration[1]) + '以内';
      }
    },
    formatTime(time) {
      const minute = parseInt(time / 60) ? parseInt(time / 60) + '分' : '';
      const second = time % 60 ? time % 60 + '秒' : '';
      return minute + second;
    },
    formatData(value, unit) {
      if (!value.length) return;
      if (value[0] === -1) {
        return '小于等于 ' + value[1] + unit;
      } else if (value[1] === -1) {
        return '大于等于 ' + value[0] + unit;
      } else if (value[0] === value[1]) {
        return value[0] + unit;
      } else {
        return value[0] + unit + ' - ' + value[1] + unit;
      }
    },
    // 设置合格分数input事件
    handleQualifiedScoreInput(value) {
      this.localScoreObj.qualified.qualify_standard = ('' + value // 第一步：转成字符串
      ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || '';
      if (Number(this.localScoreObj.qualified.qualify_standard) > this.localScoreObj.full_score) {
        this.localScoreObj.qualified.qualify_standard = this.localScoreObj.full_score;
      }
    },
    // 设置合格分数失焦事件
    handleQualifiedScoreBlur(event) {
      if (!event.target.value) {
        if (this.localScoreObj.full_score) {
          this.localScoreObj.qualified.qualify_standard = this.localScoreObj.full_score;
        } else {
          this.localScoreObj.qualified.qualify_standard = 60;
        }
      }
    },
    // 修改满分操作
    handleChangeFullMark() {
      this.isEditFullMark = true;
      this.$nextTick(() => {
        this.$refs.fullMarkInputRef.focus();
      });
    },
    // 修改分数失去焦点事件
    handleFullMarkBlur(event) {
      if (!event.target.value) {
        this.localScoreObj.full_score = 100;
      }
      this.isEditFullMark = false;
      // 修改分数的时候校验分数
      this.validateRuleScore();
      // 当设置合格分数超出修改的分数的时候，修改合格分数
      if (this.localScoreObj.qualified.is_on && Number(this.localScoreObj.qualified.qualify_standard) > this.localScoreObj.full_score) {
        this.localScoreObj.qualified.qualify_standard = this.localScoreObj.full_score;
      }
    },
    // 修改分数input事件
    handleFullMarkLimitInput(value) {
      this.localScoreObj.full_score = ('' + value // 第一步：转成字符串
      ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      if (Number(this.localScoreObj.full_score) > MAXSCORE) {
        this.localScoreObj.full_score = MAXSCORE;
      }
    },
    handleAccountTagChange(val, index) {
      if (val.account_label) {
        this.localScoreObj.score_items[index].config = val.account_label;
      } else {
        this.localScoreObj.score_items[index].config = {
          relation: 'and',
          value: []
        };
      }
    },
    validateRuleItem(item) {
      return this.isConditionValid(item) && item.name && item.score_type && this.isScoreValid(item);
    },
    isConditionValid(item) {
      let range = 0;
      if (item.type === 'key_words') {
        range = this.getTextSearchCount(item.config.textFilterList);
      } else if (item.type === 'event_engine') {
        range = this.getEventCount(item.config);
      } else if (item.type === 'event_engine_order') {
        range = this.getEventOrderCount(item.config);
      } else if (item.type === 'account_label') {
        range = this.getAccountLabelCount(item.config);
      } else {
        range = Array.isArray(item.config) ? item.config.length : item.config;
      }
      return item.type && range;
    },
    isScoreValid(item) {
      let scoreValid = false;
      if (item.score_type === 1) {
        scoreValid = !!item.score;
      }
      if (item.score_type === 2) {
        if (item.score_ext) {
          const score_ext = JSON.parse(item.score_ext);
          scoreValid = score_ext.score && score_ext.items.every(item => item.gte && item.lte && item.gte <= item.lte && item.score);
        } else {
          scoreValid = false;
        }
      }
      if (item.score_type === 3) {
        if (item.score_ext) {
          const score_ext = JSON.parse(item.score_ext);
          const hitsArray = score_ext.items.map(scoreItem => scoreItem.hits);
          scoreValid = score_ext.score && score_ext.items.every(scoreItem => {
            if (!(scoreItem.hits && scoreItem.score)) return false;
            const keyName = {
              key_words: 'textFilterList',
              event_engine: 'keyEventCascaderList'
            }[item.type];
            return item.config[keyName].length >= scoreItem.hits;
          }) && [...new Set(hitsArray)].length === hitsArray.length;
        } else {
          scoreValid = false;
        }
      }
      return scoreValid;
    },
    getTextSearchCount(list) {
      return list.every(item => item.pattern);
    },
    getEventCount(obj) {
      var _obj$keyEventCascader;
      return (_obj$keyEventCascader = obj.keyEventCascaderList) === null || _obj$keyEventCascader === void 0 ? void 0 : _obj$keyEventCascader.every(item => item.keyEventName.length);
    },
    getEventOrderCount(obj) {
      var _obj$keyEventOrderCas;
      return (_obj$keyEventOrderCas = obj.keyEventOrderCascaderList) === null || _obj$keyEventOrderCas === void 0 ? void 0 : _obj$keyEventOrderCas.every(item => item.keyEventName.length);
    },
    getAccountLabelCount(obj) {
      return obj.value.length;
    },
    handleEventFormat(config) {
      const {
        relation,
        value
      } = config;
      const handledValue = JSON.parse(JSON.stringify(value).replace(/ids/g, 'keyEventName'));
      return {
        keyEventCascaderList: handledValue,
        event_engine_relation: relation
      };
    },
    handleEventOrderFormat(config) {
      const {
        value
      } = config;
      const handledValue = JSON.parse(JSON.stringify(value).replace(/ids/g, 'keyEventName'));
      return {
        keyEventOrderCascaderList: handledValue
      };
    },
    handleKeywordsFormat(config) {
      const {
        relation,
        value
      } = config;
      return {
        textFilterList: value,
        filter_relation: relation
      };
    },
    addNewRule() {
      var _this$typeOptions$fir;
      const firstType = Object.keys(this.typeOptions)[0];
      const scoreItem = {
        id: Symbol(),
        name: '评分项' + (this.localScoreObj.score_items.length + 1),
        type: firstType,
        config: (0, _commonFunc.deepClone)((_this$typeOptions$fir = this.typeOptions[firstType]) === null || _this$typeOptions$fir === void 0 ? void 0 : _this$typeOptions$fir.initRange),
        score: '',
        score_type: 1,
        score_ext: ''
      };
      this.localScoreObj.score_items.push(scoreItem);
      this.$emit('editScore', scoreItem.id);
    },
    deleteRuleItem(index, id) {
      this.localScoreObj.score_items.splice(index, 1);
      this.$emit('deleteScoreRule', id);
      this.validateRuleScore();
    },
    validateRuleScore() {
      const maxValue = this.localScoreObj.full_score;
      let sum = 0;
      let validate = false;
      this.localScoreObj.score_items.forEach(item => {
        if (item.score_type === 1) {
          sum += Number(item.score * 100) || 0;
        } else {
          if (item.score_ext) {
            const score_ext = JSON.parse(item.score_ext);
            sum += Number(score_ext.score * 100) || 0;
          } else {
            sum += 0;
          }
        }
      });
      sum /= 100;
      if (sum > maxValue) {
        this.scoreValidateTip = '已超出' + (sum * 100 - maxValue * 100) / 100 + '分';
        validate = false;
      } else if (sum < maxValue) {
        this.scoreValidateTip = '还剩' + (maxValue * 100 - sum * 100) / 100 + '分待分配';
        validate = false;
      } else {
        this.scoreValidateTip = '';
        validate = true;
      }
      return validate;
    },
    equalDivision() {
      const score_items = this.localScoreObj.score_items;
      const average = Math.floor(this.localScoreObj.full_score / score_items.length);
      score_items.forEach(item => {
        item.score = average;
      });
      this.validateRuleScore();
    },
    resetScoreValid() {
      this.scoreValidateTip = '';
    }
  }
};
exports.default = _default;