"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _KnowledgeQuestionsFilter = _interopRequireDefault(require("./KnowledgeQuestionsFilter.vue"));
var _KnowledgeQuestion = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeQuestion.vue"));
var _knowledgePointMiningEngine = _interopRequireDefault(require("@/assets/knowledgePointMiningEngine.svg"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeQuestionsContent',
  components: {
    KnowledgeQuestionsFilter: _KnowledgeQuestionsFilter.default,
    KnowledgeQuestion: _KnowledgeQuestion.default,
    NoData: _NoData.default
  },
  data() {
    return {
      knowledgePointMiningEngine: _knowledgePointMiningEngine.default,
      loading: false,
      questionList: [],
      total: 0,
      currentQuestion: {},
      queryParams: {
        status: 'all',
        is_add: 0,
        page: 1,
        size: 10
      },
      searchValue: '',
      dealAllCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    isShowQuestionTricksLibrary() {
      return Object.keys(this.currentQuestion).length;
    },
    pagerCount() {
      return this.isShowQuestionTricksLibrary ? 5 : 7;
    },
    // 小屏不展示分页总数
    paginationLayout() {
      return this.isShowQuestionTricksLibrary ? 'prev, pager, next, sizes' : 'total, prev, pager, next, sizes';
    }
  },
  watch: {
    'workspaceInfo.id'() {
      this.queryParams.page = 1;
      this.getQuestionList();
    }
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    async ignoreQuestion(val) {
      const res = await (0, _knowledgeLibrary.editIgnoreStatus)({
        id: val.id,
        workspace_id: this.workspaceInfo.id,
        is_omit: true
      });
      if (res.code === 20000) {
        this.$message.success('操作成功');
        this.getQuestionList();
        if (!localStorage.getItem('knowledgePointMiningEngineKnown')) {
          this.$emit('ignoreQuestion');
        }
      }
    },
    async getQuestionList() {
      if (!this.workspaceInfo.id) {
        return;
      }
      this.loading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeQuestionList)({
        ...this.queryParams,
        space_id: this.workspaceInfo.id,
        filter: this.searchValue,
        time_zone: this.timezone
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          count,
          data,
          deal_all_count
        } = res.results;
        this.questionList = data;
        this.total = count;
        this.dealAllCount = deal_all_count;
      }
    },
    handleFilterChange(type, val) {
      this.queryParams.page = 1;
      this.queryParams[type] = val;
      this.getQuestionList();
    },
    handleQuestionClick(val) {
      if (this.currentQuestion.id === val.id) return;
      this.currentQuestion = val;
      this.$emit('questionClick', val);
    },
    sizeChange(val) {
      this.queryParams.size = val;
      this.getQuestionList();
    },
    pageChange(val) {
      this.queryParams.page = val;
      this.getQuestionList();
    },
    clearCurrentQuestion() {
      this.currentQuestion = {};
    }
  }
};
exports.default = _default;