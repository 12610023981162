"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RuleBasicBox = _interopRequireDefault(require("./RuleBasicBox.vue"));
var _RuleContextBox = _interopRequireDefault(require("./RuleContextBox.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RuleGroupItem',
  components: {
    RuleBasicBox: _RuleBasicBox.default,
    RuleContextBox: _RuleContextBox.default
  },
  inject: {
    isReadOnly: {
      default: false
    }
  },
  props: {
    ruleGroup: {
      type: Array,
      default: () => []
    },
    ruleCount: {
      type: Number,
      default: 0
    },
    ruleGroupIndex: {
      type: Number,
      default: 0
    },
    ruleGroupItem: {
      type: Object,
      default: () => ({
        rule: [{
          apply_to: 3,
          include: [{
            type: 'keywords',
            value: []
          }],
          exclude: [{
            type: 'keywords',
            value: []
          }]
        }]
      })
    }
  },
  data() {
    return {
      speakingRoleOption: [{
        label: '销售',
        value: 3
      }, {
        label: '客户',
        value: 2
      }, {
        label: '所有角色',
        value: 1
      }],
      typeOptions: [{
        value: 'keywords',
        label: '关键词'
      }, {
        value: 'regex',
        label: '正则表达式'
      }]
    };
  },
  computed: {
    isReadonly() {
      return this.isReadOnly;
    }
  },
  methods: {
    handleDeleteRuleGroupItem(ruleGroupIndex) {
      this.$emit('handleDeleteRuleGroupItem', ruleGroupIndex);
    },
    handleAddRule() {
      // eslint-disable-next-line vue/no-mutating-props
      this.ruleGroupItem.context.push({
        apply_to: 3,
        include: [{
          type: 'keywords',
          value: []
        }],
        exclude: [{
          type: 'keywords',
          value: []
        }],
        context_span: '',
        context_type: 'after',
        target_rule: 0
      });
    },
    deleteContextRule(val) {
      const {
        contextRuleIndex
      } = val;
      // eslint-disable-next-line vue/no-mutating-props
      this.ruleGroupItem.context.splice(contextRuleIndex, 1);
    }
  }
};
exports.default = _default;