"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trick"
  }, [_vm.trickCheckable ? _c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.$emit("trickItemCheckedChange");
      }
    },
    model: {
      value: _vm.reply.checked,
      callback: function ($$v) {
        _vm.$set(_vm.reply, "checked", $$v);
      },
      expression: "reply.checked"
    }
  }) : _vm._e(), _c("i", {
    staticClass: "iconfont icon-custom-01"
  }), _c("div", {
    staticClass: "text-container"
  }, [_vm._t("default"), _vm.isEdit ? _c("div", {
    staticClass: "text-edit"
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 1,
        maxRows: 5
      }
    },
    on: {
      input: _vm.handleInput
    },
    model: {
      value: _vm.convInfo.value,
      callback: function ($$v) {
        _vm.$set(_vm.convInfo, "value", $$v);
      },
      expression: "convInfo.value"
    }
  })], 1) : _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v(_vm._s(_vm.reply.reply_content))]), _vm._t("voice-play-icon")], 2)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;