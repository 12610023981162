"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessTransRankingList',
  components: {
    SvgIcon: _SvgIcon.default
  },
  mixins: [_ExportMixins.default],
  props: {
    kanbanId: {
      type: Number,
      default: null
    },
    kanbanType: {
      type: String,
      default: 'custom'
    }
  },
  data() {
    return {
      loading: false,
      tableHead: [{
        name: '关键事件',
        prop: 'event'
      }, {
        name: '转化情况',
        children: [{
          name: '转化率',
          prop: 'trans_other_rate'
        }, {
          name: '转化数量',
          prop: 'trans_other_count'
        }]
      }, {
        name: '流失情况',
        children: [{
          name: '流失率',
          prop: 'loss_rate'
        }, {
          name: '流失数量',
          prop: 'loss_count'
        }]
      }],
      tableData: [],
      page: 1,
      size: 20,
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'timezone', 'workspaceInfo']),
    eachPageData() {
      const start = (this.page - 1) * this.size;
      const end = this.page * this.size;
      return this.tableData.slice(start, end);
    }
  },
  watch: {
    biFiltersObj() {
      this.getTableData();
    },
    'workspaceInfo.id'() {
      this.getTableData();
    },
    kanbanType: {
      handler: function (val) {
        const type = {
          custom: '自定义字段',
          deal_stage: '交易阶段',
          events: '关键事件'
        }[val];
        this.tableHead[0].name = type;
      },
      immediate: true
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      this.clearSortStatus();
      this.loading = true;
      const {
        custom_filters,
        date,
        tree_user_ids: {
          tree_ids,
          user_ids
        }
      } = this.biFiltersObj;
      const customFilters = (0, _commonFunc.deepClone)(custom_filters);
      (0, _timezone.convertParams2UTCplus8)(customFilters);
      const params = {
        custom_filters: customFilters,
        date,
        tree_ids,
        user_ids,
        kanban_id: this.kanbanId,
        time_zone: this.timezone,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _businessIntelligence.getRankingListData)(params);
      this.loading = false;
      if (res.code === 200) {
        const {
          rows
        } = res.results;
        this.tableData = rows;
        this.total = rows.length;
      }
    },
    handleSortChange(_ref) {
      let {
        column,
        prop,
        order
      } = _ref;
      if (!order) return;
      order === 'ascending' ? this.tableData.sort((a, b) => parseFloat(a[prop]) - parseFloat(b[prop])) : this.tableData.sort((a, b) => parseFloat(b[prop]) - parseFloat(a[prop]));
    },
    handleSizeChange(size) {
      this.size = size;
    },
    handleCurrentChange(page) {
      this.page = page;
    },
    exportData() {
      const head = [['关键事件', '转化情况', '转化情况', '流失情况', '流失情况'], ['', '转化率', '转化数量', '流失率', '流失数量']];
      const data = this.tableData.map(_ref2 => {
        let {
          event,
          trans_other_count,
          trans_other_rate,
          loss_count,
          loss_rate
        } = _ref2;
        return [event, trans_other_rate, trans_other_count, loss_rate, loss_count];
      });
      const {
        activeTabName,
        startDate,
        endDate
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${startDate}至${endDate}.csv`;
      (0, _download.downloadBlob)(this.handleFileToBuf([...head, ...data]), fileName);
    },
    clearSortStatus() {
      this.$refs['table'] && this.$refs['table'].clearSort();
    }
  }
};
exports.default = _default;