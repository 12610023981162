"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _useVuex = require("@/hooks/use-vuex");
var _getDateRange = require("@/utils/getDateRange");
var _default = {
  __name: 'AnalysisFilters',
  emits: ['change', 'export-table'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    (0, _vue.defineComponent)({
      name: 'AnalysisFilters'
    });
    const store = (0, _useVuex.useStore)();
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const filter = (0, _vue.reactive)({
      dimension: 'trainee',
      plan_start_time: (0, _getDateRange.getDateRangePastToNow)(30, 'd'),
      user_start_time: [],
      tree_ids: [],
      user_ids: [],
      is_only_show_current: false
    });
    const dateType = (0, _vue.ref)('task_start_time');
    const dateTime = (0, _vue.ref)((0, _getDateRange.getDateRangePastToNow)(30, 'd'));
    const onDimensionChange = dimension => {
      filter.is_only_show_current = false;
      filter.tree_ids = [];
      filter.user_ids = [];
      customChange();
    };
    const onUserChange = (_ref2, backfillObj) => {
      let {
        tree_ids,
        user_ids
      } = _ref2;
      filter.tree_ids = tree_ids;
      filter.user_ids = user_ids;
      console.log('backfillObj,', backfillObj);
      customChange();
    };
    const onDepartmentChange = tree_ids => {
      filter.tree_ids = tree_ids;
      customChange();
    };
    const onDateTimeChange = datetime => {
      (0, _vue.nextTick)(() => {
        dateTime.value = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      });
      if (dateType.value === 'task_start_time') {
        filter.user_start_time = [];
        filter.plan_start_time = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      } else {
        filter.plan_start_time = [];
        filter.user_start_time = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      }
      customChange();
    };
    const customChange = () => {
      const _filter = {
        ...filter
      };
      emit('change', _filter);
    };
    (0, _vue.onActivated)(() => {
      customChange();
    });
    const exportTable = () => {
      emit('export-table');
    };
    return {
      __sfc: true,
      store,
      workspaceInfo,
      emit,
      filter,
      dateType,
      dateTime,
      onDimensionChange,
      onUserChange,
      onDepartmentChange,
      onDateTimeChange,
      customChange,
      exportTable,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      getOrgTree: _organization.getOrgTree
    };
  }
};
exports.default = _default;