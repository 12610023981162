"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'KnowledgeQuestion',
  props: {
    isIgnore: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => ({})
    },
    currentQuestion: {
      type: Object,
      default: () => ({})
    },
    dealAllCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    dealFreqTooltip() {
      var _this$question, _this$question$deal_c;
      const allCount = this.dealAllCount.toLocaleString();
      const occCount = (_this$question = this.question) === null || _this$question === void 0 ? void 0 : (_this$question$deal_c = _this$question.deal_count) === null || _this$question$deal_c === void 0 ? void 0 : _this$question$deal_c.toLocaleString();
      return `${allCount}笔交易中有${occCount}笔提到`;
    }
  },
  methods: {
    handleClick() {
      this.$emit('questionClick', this.question);
    }
  }
};
exports.default = _default;