"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataSetSelectors = _interopRequireDefault(require("./DataSetSelectors.vue"));
var _ModelSelect = _interopRequireDefault(require("./ModelSelect.vue"));
var _TrainSuperParamTable = _interopRequireDefault(require("@/components/TrainSuperParamTable"));
var _TrainAndTestDataSet = _interopRequireDefault(require("@/views/management/rapidModeling/components/TrainAndTestDataSet.vue"));
var _ModelPredictionResult = _interopRequireDefault(require("@/views/management/rapidModeling/components/ModelPredictionResult.vue"));
var _modelManagement = require("@/api/modelManagement");
var _useTrainSuperParams = require("../hooks/use-train-super-params");
var _elementUi = require("element-ui");
var _vue = require("vue");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrainModelDialog',
  props: {
    visible: Boolean,
    modelId: {
      type: Number,
      default: 0
    },
    dataSetId: {
      type: Number,
      default: 0
    },
    modelName: {
      type: String,
      default: ''
    },
    version: {
      type: Number,
      default: 0
    },
    isAutoTrial: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submitSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => {
      emit('update:visible', false);
      emit('update:isAutoTrial', false);
    };
    const localModelId = (0, _vue.ref)('');
    const {
      trainParamsFillValue,
      paramsChange,
      onAutoTuningParams,
      autoTuningParams,
      trainParam,
      trainParamMode
    } = (0, _useTrainSuperParams.useTrainSuperParams)();
    const {
      isAutoTest,
      trainAndTestDatasetRef,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType,
      getTrainAndTrailDatasetData
    } = (0, _useTrainSuperParams.useTrainAndTestDatasetParams)();

    // 大模型后处理hooks
    const {
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      promptContent,
      getPromptContent
    } = (0, _useTrainSuperParams.usePredictionResult)();
    (0, _vue.watch)([() => props.modelId || localModelId.value, () => props.visible], async _ref2 => {
      let [modelId, visible] = _ref2;
      if (modelId && visible) {
        var _postprocess$prompt_i;
        const params = {
          id: modelId,
          version: props.version
        };
        const res = await (0, _modelManagement.getModelDetail)(params);
        const {
          train_param_mode,
          auto_trial,
          train_datasets,
          trial_datasets,
          dataset_split_type,
          train_param,
          postprocess_type,
          postprocess_llm_enable,
          postprocess
        } = res.results;
        postProcessType.value = postprocess_type || 1;
        isEnableLLM.value = postprocess_llm_enable;
        promptContent.value = postprocess === null || postprocess === void 0 ? void 0 : (_postprocess$prompt_i = postprocess.prompt_info) === null || _postprocess$prompt_i === void 0 ? void 0 : _postprocess$prompt_i.prompt_content;
        isAutoTest.value = auto_trial;
        autoTuningParams.value = auto_trial ? !!train_param_mode : false;
        trainParamMode.value = train_param_mode;
        trainDatasetList.value = train_datasets.slice(0, 1);
        trialDatasetList.value = trial_datasets.slice(0, 1);
        datasetSplitType.value = dataset_split_type;
        trainParam.value = train_param;
        trainParamsFillValue.value = train_param;
        if (props.isAutoTrial) {
          isAutoTest.value = true;
        }
      } else {
        clearAll();
      }
    }, {
      immediate: true
    });
    (0, _vue.watch)([() => props.dataSetId, () => props.visible], _ref3 => {
      let [dataSetId, visible] = _ref3;
      if (dataSetId && visible) {
        const dataset = {
          dataset_id: dataSetId
        };
        trainDatasetList.value.push(dataset);
      }
    }, {
      immediate: true
    });
    (0, _vue.watch)(() => isAutoTest.value, val => {
      if (!val) {
        autoTuningParams.value = false;
      }
    });
    function clearAll() {
      isAutoTest.value = false;
      localModelId.value = '';
      trainDatasetList.value = [];
      trialDatasetList.value = [];
      trainParam.value = {};
      trainParamsFillValue.value = {};
    }
    const btnLoading = (0, _vue.ref)(false);
    const createTrainTaskAsync = async () => {
      if (!localModelId.value && !props.modelId) {
        _elementUi.Message.warning('请选择模型');
        return;
      }
      btnLoading.value = true;
      if (!autoTuningParams.value) {
        trainParamMode.value = 0;
      }
      const trainAndTestDatasetParams = getTrainAndTrailDatasetData();
      if (!trainAndTestDatasetParams) {
        return;
      }
      const {
        train_datasets,
        trial_datasets,
        dataset_split_type
      } = trainAndTestDatasetParams;

      // 模型预测结果模块
      const promptContent = getPromptContent();
      if (!promptContent) return;
      const params = {
        id: localModelId.value || props.modelId,
        train_datasets,
        trial_datasets,
        dataset_split_type,
        postprocess_type: postProcessType.value,
        prompt_info: {
          prompt_content: isEnableLLM.value ? promptContent : ''
        },
        postprocess_llm_enable: isEnableLLM.value,
        train_param_mode: trainParamMode.value,
        train_param: trainParam.value,
        auto_trial: isAutoTest.value
      };
      const res = await (0, _modelManagement.editModelConfig)(params).finally(() => {
        btnLoading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success('创建训练任务成功');
        emit('submitSuccess', res.results.id);
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      localModelId,
      trainParamsFillValue,
      paramsChange,
      onAutoTuningParams,
      autoTuningParams,
      trainParam,
      trainParamMode,
      isAutoTest,
      trainAndTestDatasetRef,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType,
      getTrainAndTrailDatasetData,
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      promptContent,
      getPromptContent,
      clearAll,
      btnLoading,
      createTrainTaskAsync,
      ModelSelect: _ModelSelect.default,
      TrainSuperParamTable: _TrainSuperParamTable.default,
      TrainAndTestDataSet: _TrainAndTestDataSet.default,
      ModelPredictionResult: _ModelPredictionResult.default
    };
  }
};
exports.default = _default;