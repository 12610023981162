"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _FolderSelect = _interopRequireDefault(require("./FolderSelect.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateDataSetDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentDataSet: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:visible', 'importData', 'refreshTable'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const rules = {
      name: [{
        required: true,
        message: '请输入数据集名称',
        trigger: 'change'
      }],
      folder_id: [{
        required: true,
        message: '请选择文件夹',
        trigger: 'change'
      }]
    };
    const form = (0, _vue.ref)({
      name: '',
      folder_id: '',
      annotation_type: 1,
      description: ''
    });
    const createDataSetFormRef = (0, _vue.ref)(null);
    const handleFolderChange = val => {
      form.value.folder_id = val;
    };
    const handleClose = () => {
      emit('update:visible', false);
    };
    const createDataSetApi = async () => {
      return await (0, _dataSet.createDataSet)(form.value);
    };
    const editDataSetApi = async () => {
      return await (0, _dataSet.editDataSet)({
        dataset_id: props.currentDataSet.id,
        ...form.value
      });
    };
    const loading = (0, _vue.ref)(false);
    const handleSubmit = async () => {
      const isPassValid = await createDataSetFormRef.value.validate();
      if (!isPassValid) return;
      loading.value = true;
      const requestFun = props.currentDataSet.id ? editDataSetApi : createDataSetApi;
      const res = await requestFun().finally(() => loading.value = false);
      if (res.code === 20000) {
        (0, _elementUi.Message)({
          message: props.currentDataSet.id ? '编辑数据集成功' : '创建数据集成功',
          type: 'success'
        });
        emit('refreshTable');
        handleClose();
      }
    };
    const handleSubmitAndImpartData = async () => {
      const isPassValid = await createDataSetFormRef.value.validate();
      if (!isPassValid) return;
      loading.value = true;
      const res = await createDataSetApi().finally(() => loading.value = false);
      if (res.code === 20000) {
        (0, _elementUi.Message)({
          message: '创建数据集成功',
          type: 'success'
        });
        emit('importData', {
          id: res.results.dataset_id
        });
        handleClose();
      }
    };
    const initForm = () => {
      var _props$currentDataSet;
      if ((_props$currentDataSet = props.currentDataSet) !== null && _props$currentDataSet !== void 0 && _props$currentDataSet.id) {
        form.value.name = props.currentDataSet.name;
        form.value.folder_id = props.currentDataSet.folder.id;
        form.value.annotation_type = props.currentDataSet.annotation_type;
        form.value.description = props.currentDataSet.description;
      }
    };
    (0, _vue.watchEffect)(() => {
      initForm();
    });
    return {
      __sfc: true,
      props,
      rules,
      form,
      createDataSetFormRef,
      emit,
      handleFolderChange,
      handleClose,
      createDataSetApi,
      editDataSetApi,
      loading,
      handleSubmit,
      handleSubmitAndImpartData,
      initForm,
      FolderSelect: _FolderSelect.default
    };
  }
};
exports.default = _default;