"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _LazyTree = _interopRequireDefault(require("./components/LazyTree"));
var _user = require("@/api/user");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentAndMemberTree',
  components: {
    LazyTree: _LazyTree.default
  },
  props: {
    isCheckedRoot: {
      type: Boolean
    },
    requestFunction: {
      type: Function,
      default: _user.getDataOrgMemberTree
    },
    params: {
      type: [Object, String],
      default: () => ({})
    },
    checkedNodesData: {
      type: Array,
      default: () => []
    },
    backfillIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      query: true,
      memberFilter: '',
      memberOptions: [],
      searchLoading: false
    };
  },
  computed: {
    // // 搜索列表中有树中已选项
    filterMemberIsSelected() {
      return function (item) {
        if (!this.memberOptions.length) return;
        const checkedNodesId = this.checkedNodesData.map(_ref => {
          let {
            id
          } = _ref;
          if (!id.includes('tree_id')) return id;
        });
        return checkedNodesId.includes(item.id);
      };
    }
  },
  methods: {
    getMember: (0, _commonFunc.debounce)(async function () {
      if (!this.memberFilter) return;
      try {
        this.searchLoading = true;
        const res = await (0, _conversationList.getHostPersonOrTeam)({
          filter: this.memberFilter
        });
        if (res.code === 200) {
          this.memberOptions = res.results.users;
          this.memberOptions.forEach(item => {
            item.id = String(item.id);
          });
        }
      } finally {
        this.searchLoading = false;
      }
    }),
    handleChange(checkedNodesData) {
      this.$emit('change', checkedNodesData);
    },
    handleMemberFilterChange(item) {
      var _this$$refs$tree$defa;
      const hasUser = ((_this$$refs$tree$defa = this.$refs['tree'].defaultChecked) === null || _this$$refs$tree$defa === void 0 ? void 0 : _this$$refs$tree$defa.includes(item.id)) || this.filterMemberIsSelected(item);
      if (hasUser) {
        const checkedNodesData = this.checkedNodesData.filter(_ref2 => {
          let {
            id
          } = _ref2;
          return id !== item.id;
        });
        this.$refs['tree'].defaultChecked = this.$refs['tree'].$refs['department-member-tree'].setCheckedNodes(checkedNodesData) || [];
      } else {
        this.$refs['tree'].defaultChecked = [item.id];
        this.$emit('memberFilterChange', item);
      }
      this.$nextTick(() => {
        hasUser && this.$refs['tree'].handleCheckChange();
      });
      this.memberOptions = [];
      this.memberFilter = '';
    }
  }
};
exports.default = _default;