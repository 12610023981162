"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AppointPart = _interopRequireDefault(require("./AppointPart"));
var _MeetingItem = _interopRequireDefault(require("./MeetingItem"));
var _dashboard = require("@/api/dashboard");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    AppointPart: _AppointPart.default,
    MeetingItem: _MeetingItem.default
  },
  data() {
    return {
      loading: false,
      list: []
    };
  },
  created() {
    this.getUpcomingMeetings();
  },
  methods: {
    async getUpcomingMeetings() {
      this.loading = true;
      const res = await (0, _dashboard.getUpcomingMeetings)().finally(() => this.loading = false);
      this.list = res.results.data;
    }
  }
};
exports.default = _default;