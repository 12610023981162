"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _annotate = require("../hooks/annotate");
var _vue = require("vue");
var _dataSet = require("@/api/dataSet");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateLabel',
  props: {
    dataSetLabelList: {
      type: Array,
      default: () => []
    },
    eventLabelOfSentenceMap: {
      type: Object,
      default: () => {}
    },
    activeDataSetData: {
      type: Object,
      default: () => {}
    },
    activeLabelIdsArr: {
      type: Array,
      default: () => []
    }
  },
  emits: ['createAnnotate', 'deleteAnnotate', 'changeActiveLabelArr', 'getSentenceOfEvent'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const dataSetId = (0, _vue.inject)('dataSetId');
    const handleChange = async activeLabelArr => {
      const labelId = activeLabelArr[activeLabelArr.length - 1];
      emit('changeActiveLabelArr', activeLabelArr);
      if (!labelId) return;
      if (props !== null && props !== void 0 && props.eventLabelOfSentenceMap[labelId]) return;
      emit('getSentenceOfEvent', labelId);
    };
    const activeNames = (0, _vue.ref)([]);
    (0, _vue.watch)(() => props.activeLabelIdsArr, val => {
      activeNames.value = val;
    }, {
      deep: true,
      immediate: true
    });
    const createAnnotate = item => {
      emit('createAnnotate', [item.id]);
    };
    const deleteAnnotate = item => {
      emit('deleteAnnotate', item);
    };
    const deleteDataSetLabelApi = async dataSetLabel => {
      const res = await (0, _dataSet.deleteDataSetLabel)({
        dataset_id: dataSetId.value,
        dataset_label_id: dataSetLabel.id
      });
      if (res.code === 20000) {
        _elementUi.Message.success('删除成功');
        emit('refreshDataSetLabel');
      }
    };
    const {
      annotateLabelTourRef,
      annotateLabelGuideSteps
    } = (0, _annotate.useAnnotateTour)();
    const newAnnotateLabelName = (0, _vue.ref)('');
    const isShowCreateAnnotateInput = (0, _vue.ref)(false);
    const newAnnotateLabelInputRef = (0, _vue.ref)(null);
    const onCreateAnnotateLabel = async () => {
      isShowCreateAnnotateInput.value = true;
      await (0, _vue.nextTick)();
      newAnnotateLabelInputRef.value.focus();
    };
    const updateIsShowCreateAnnotateInput = () => {
      isShowCreateAnnotateInput.value = false;
    };
    const onBlurNewAnnotateLabel = async () => {
      if (!newAnnotateLabelName.value) {
        updateIsShowCreateAnnotateInput();
        return;
      }
      const res = await (0, _dataSet.createDataSetLabel)({
        dataset_id: dataSetId.value,
        name: newAnnotateLabelName.value
      });
      if (res.code === 20000) {
        _elementUi.Message.success('创建成功');
        annotateLabelTourRef.value.start();
        newAnnotateLabelName.value = '';
        emit('refreshDataSetLabel');
        updateIsShowCreateAnnotateInput();
      }
    };
    const isRenameId = (0, _vue.ref)(0);
    const renameInputRefs = [];
    const setRenameInputRefs = el => {
      if (el) {
        renameInputRefs.push(el);
      }
    };
    const onRenameAnnotateLabel = async dataSetLabel => {
      const res = await (0, _dataSet.renameDataSetLabel)({
        dataset_id: dataSetId.value,
        dataset_label_id: dataSetLabel.id,
        name: dataSetLabel.name
      });
      if (res.code === 20000) {
        isRenameId.value = 0;
        _elementUi.Message.success('重命名成功');
        emit('refreshDataSetLabel');
      }
    };
    const onCommand = async (command, dataSetLabel, index) => {
      switch (command) {
        case 'rename':
          isRenameId.value = dataSetLabel.id;
          await (0, _vue.nextTick)();
          renameInputRefs[index].focus();
          break;
      }
    };
    return {
      __sfc: true,
      props,
      dataSetId,
      emit,
      handleChange,
      activeNames,
      createAnnotate,
      deleteAnnotate,
      deleteDataSetLabelApi,
      annotateLabelTourRef,
      annotateLabelGuideSteps,
      newAnnotateLabelName,
      isShowCreateAnnotateInput,
      newAnnotateLabelInputRef,
      onCreateAnnotateLabel,
      updateIsShowCreateAnnotateInput,
      onBlurNewAnnotateLabel,
      isRenameId,
      renameInputRefs,
      setRenameInputRefs,
      onRenameAnnotateLabel,
      onCommand,
      KeyEventDetailList: _keyEventDetailList.default
    };
  }
};
exports.default = _default;