"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailDrawer = _interopRequireDefault(require("@/components/DetailDrawer"));
var _DealContact = _interopRequireDefault(require("./DealContact"));
var _DealDetailInfo = _interopRequireDefault(require("./DealDetailInfo"));
var _AssociateContactDialog = _interopRequireDefault(require("./AssociateContactDialog"));
var _business = require("@/api/business");
var _vuex = require("vuex");
var _LogRecord = _interopRequireDefault(require("@/components/LogRecord"));
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealDetailDrawer',
  components: {
    DealContact: _DealContact.default,
    DetailDrawer: _DetailDrawer.default,
    DealDetailInfo: _DealDetailInfo.default,
    AssociateContactDialog: _AssociateContactDialog.default,
    LogRecord: _LogRecord.default
  },
  inject: ['init'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: [Number, String]
    },
    dealId: {
      type: [Number, String]
    },
    formDesc: {
      type: Array,
      default: () => []
    },
    business: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      associateContactDialogVisible: false,
      contactList: [],
      dealTab: 'dealDetailInfo',
      isDecisionMaker: false,
      formData: {},
      pageSize: 10,
      logItemProperties: {
        deal_create: {
          label: '新建商机',
          icon: 'iconfont icon-deal4'
        },
        edit_deal: {
          label: '编辑商机',
          icon: 'iconfont icon-edit'
        },
        deal_stage_modify: {
          label: '变更商机阶段',
          icon: 'iconfont icon-deal5'
        },
        deal_contact_rel: {
          label: '关联联系人',
          icon: 'iconfont icon-connection'
        },
        deal_contact_modify: {
          label: '编辑联系人',
          icon: 'iconfont icon-edit'
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'has_video_permission', 'contactDynamicFormDescList', 'api_permissions', 'workspaceInfo']),
    isStageReadonly() {
      const readonlyValues = ['win', 'loss', 'invalidate'];
      if (readonlyValues.includes(this.business.deal_stage)) {
        return true;
      } else {
        return false;
      }
    },
    operateButton() {
      return [{
        icon: 'iconfont icon-associate',
        label: '关联联系人',
        action: 'associateContact',
        permission: '/api/deal/contact/deal_add_contact'
      }];
    },
    info() {
      return {
        name: this.business.deal_name,
        subLabel: '跟进人：',
        subValue: this.business.follow_up_by_name,
        type: 'deal'
      };
    },
    title() {
      return '商机详情';
    },
    // eslint-disable-next-line vue/return-in-computed-property
    contactDynamicFormNoAccountList() {
      if (this.contactDynamicFormDescList) {
        return this.contactDynamicFormDescList.filter(item => {
          return item.id !== 'customer_name';
        });
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.dealTab = 'dealDetailInfo';
      }
    },
    workspaceInfo(val) {
      this.getDealFormInfo();
    }
  },
  created() {
    this.getDealFormInfo();
    this.$store.dispatch('account/getContactDynamicForm');
  },
  mounted() {},
  methods: {
    handleCancel(val) {
      this.formData = val;
    },
    handleInput(val) {
      this.formData = val;
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    closeAssociateContactDialog() {
      this.associateContactDialogVisible = false;
    },
    openAssociateContactDialog() {
      this.associateContactDialogVisible = true;
      this.getAssociteContactList({
        deal_id: this.dealId
      });
      this.$nextTick(() => {
        this.$refs['associateContactDialog'].$refs['associateContactForm'].clearValidate();
      });
    },
    async getAssociteContactList(params) {
      const res = await (0, _business.getAssociteContactList)(params);
      if (res.code === 200) {
        this.contactList = [];
        for (let i = 0; i < res.results.length; i++) {
          const obj = {
            label: res.results[i].name,
            value: res.results[i].id,
            disabled: !!res.results[i].in_deal
          };
          this.contactList.push(obj);
        }
      }
    },
    async getDealFormInfo() {
      const res = await (0, _business.getDealFormInfo)({
        deal_id: this.dealId,
        workspace_id: this.workspaceInfo.id
      });
      (0, _timezone.datePickerWithTimeConvert2Zone)(res.results);
      this.formData = res.results;
    },
    updateDealContactDecisionSuccess() {
      this.getDealFormInfo();
    },
    detailOperations(action) {
      switch (action) {
        case 'callOut':
        case 'scheduleMeeting':
        case 'changeFollowUpBy':
          this.$emit('detailOperations', action);
          break;
        case 'associateContact':
          this.openAssociateContactDialog();
          break;
        default:
          break;
      }
    },
    descContactFormat(formDesc, isHalfRow) {
      const desc = [];
      for (const item of formDesc) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: isHalfRow,
          tips: item.tips
        };
        desc.push(obj);
      }
      return desc;
    },
    fetchLogMethod() {
      return _business.getDealLogRecords;
    }
  }
};
exports.default = _default;