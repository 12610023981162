"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-correction-knowledge-list"
  }, [_c("el-menu", {
    staticClass: "menu-wrapper",
    attrs: {
      "default-active": _vm.defaultActiveKey
    },
    on: {
      select: _vm.handleSelect
    }
  }, _vm._l(_vm.allKnowledgeList, function (item) {
    return _c("submenu-item", {
      key: item.id,
      attrs: {
        item: item
      }
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;