"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "executor-info-tag",
    class: {
      "executor-info-more": _vm.isMoreTask
    }
  }, [_c("div", {
    class: _vm.isMoreTask ? "is-more-task" : "executor-info"
  }, [_vm.isDepartment ? _c("div", {
    staticClass: "department-avatar"
  }, [_c("i", {
    staticClass: "iconfont icon-department"
  })]) : _c("image-avatar", {
    staticClass: "upper-avatar",
    attrs: {
      name: _vm.source.name,
      avatar: _vm.source.avatar,
      shape: "circle"
    }
  }), _c("span", {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.source.name))])], 1), _vm._t("operate", function () {
    return [!_vm.isTaskDetail ? _c("el-button", {
      staticClass: "delete-btn",
      class: {
        "more-delete-btn": _vm.isMoreTask
      },
      attrs: {
        size: "mini"
      },
      on: {
        click: function ($event) {
          return _vm.deleteExecutor(_vm.source);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-close"
    })]) : _c("div", {
      staticClass: "check-btn"
    }, [!_vm.checkIsAccess || _vm.source.is_access ? _c("i", {
      staticClass: "iconfont",
      class: _vm.source.status === 1 ? "icon-circle-check" : "icon-time"
    }) : _vm._e()])];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;