"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.isEdit ? "编辑客户触达对比" : "创建客户触达对比",
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "600px",
      "custom-class": "business-reach-config",
      "wrapper-closable": false,
      "append-to-body": ""
    }
  }, [_c("div", {
    staticClass: "scroll-box"
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKanbanConfigLoading,
      expression: "getKanbanConfigLoading"
    }],
    ref: "kanbanConfigForm",
    attrs: {
      model: _vm.kanbanConfig
    }
  }, [_c("el-form-item", {
    staticClass: "kanban-name",
    attrs: {
      prop: "name",
      label: "看板名称",
      rules: [{
        required: true,
        message: "请输入看板名称",
        trigger: ["blur", "change"]
      }]
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      placeholder: "请输入看板名称"
    },
    model: {
      value: _vm.kanbanConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "name", $$v);
      },
      expression: "kanbanConfig.name"
    }
  })], 1), _c("lable-require-tooltip-remark", {
    attrs: {
      config: {
        label: "分析指标",
        require: true
      }
    }
  }), _c("el-radio-group", {
    staticClass: "index-radio",
    on: {
      change: _vm.changeAnalysisTarget
    },
    model: {
      value: _vm.kanbanConfig.kanban_target,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "kanban_target", $$v);
      },
      expression: "kanbanConfig.kanban_target"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "event",
      border: ""
    }
  }, [_vm._v(" 关键事件 ")]), _c("el-radio", {
    attrs: {
      label: "deal_target",
      border: ""
    },
    model: {
      value: _vm.kanbanConfig.kanban_target,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "kanban_target", $$v);
      },
      expression: "kanbanConfig.kanban_target"
    }
  }, [_vm._v("交易指标 ")])], 1), _vm.kanbanConfig.kanban_target === "event" ? _vm._l(_vm.kanbanConfig.kanban_info.events, function (eventItem, index) {
    return _c("el-form-item", {
      key: eventItem.key,
      staticClass: "key-event-item",
      attrs: {
        prop: "kanban_info.events." + index + ".event_id",
        rules: {
          required: true,
          message: "关键事件不能为空",
          trigger: ["blur", "change"]
        }
      }
    }, [_c("el-cascader", {
      attrs: {
        "show-all-levels": false,
        options: _vm.optionsList,
        props: {
          expandTrigger: "hover",
          label: "name",
          value: "id",
          emitPath: false
        }
      },
      on: {
        "visible-change": function ($event) {
          return _vm.setDisabled(_vm.optionsList);
        }
      },
      model: {
        value: eventItem.event_id,
        callback: function ($$v) {
          _vm.$set(eventItem, "event_id", $$v);
        },
        expression: "eventItem.event_id"
      }
    }), _vm.kanbanConfig.kanban_info.events.length > 2 ? _c("i", {
      staticClass: "el-icon-circle-close delete-btn",
      on: {
        click: function ($event) {
          return _vm.removeEventOrTarget(index);
        }
      }
    }) : _vm._e()], 1);
  }) : _vm.kanbanConfig.kanban_target === "deal_target" ? [_c("div", {
    staticClass: "event-select-container"
  }, [_c("span", {
    staticClass: "event-select-title"
  }, [_vm._v("触达关键事件")]), _c("event-select", {
    ref: "eventSelect",
    staticClass: "event-select",
    attrs: {
      "is-multiple": true,
      "collapse-tags": true,
      placeholder: "请选择"
    },
    on: {
      changeEvent: _vm.handleEventIds
    }
  })], 1), _c("lable-require-tooltip-remark", {
    attrs: {
      config: {
        label: "交易指标",
        require: true
      }
    }
  }), _c("el-form-item", {
    staticClass: "deal-target-select",
    attrs: {
      prop: `kanban_info.deal_field.id`,
      rules: {
        required: true,
        message: "交易指标不能为空",
        trigger: ["blur", "change"]
      }
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.dealFieldChange
    },
    model: {
      value: _vm.kanbanConfig.kanban_info.deal_field.id,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.kanban_info.deal_field, "id", $$v);
      },
      expression: "kanbanConfig.kanban_info.deal_field.id"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.field_name,
      attrs: {
        label: item.name,
        value: item.field_name
      }
    });
  }), 1)], 1), _vm._l(_vm.kanbanConfig.kanban_info.deal_targets, function (item, index) {
    return _c("el-form-item", {
      key: item.key,
      attrs: {
        prop: `kanban_info.deal_targets.${index}.options`,
        rules: {
          validator: _vm.validateTarget,
          trigger: _vm.triggerFlag
        }
      }
    }, [_c("deal-target-input", {
      ref: "dealTargetInput",
      refInFor: true,
      attrs: {
        "target-name": item.name,
        "target-input-options": _vm.targetOptions,
        "is-show-close-btn": _vm.kanbanConfig.kanban_info.deal_targets.length > 2,
        "disabled-target-list": _vm.disabledTargetList,
        "new-val": item.options
      },
      on: {
        changeDealTargetTitle: function ($event) {
          return _vm.changeDealTargetTitle($event, item);
        },
        deleteDealTargetEvent: function ($event) {
          return _vm.removeEventOrTarget(index);
        },
        assignDealTargetsProperty: function ($event) {
          return _vm.assignDealTargetsProperty($event, item);
        }
      }
    })], 1);
  })] : _vm._e(), _vm.isShowAddBtn ? _c("el-button", {
    staticClass: "add-btn",
    on: {
      click: _vm.addEventOrTarget
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" 添加 ")]) : _vm._e()], 2)], 1), _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 保存 ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;