"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TrickItem',
  inject: {
    trickCheckable: {
      from: 'trickCheckable',
      default: false
    }
  },
  props: {
    reply: {
      type: Object,
      default: () => ({})
    },
    convInfo: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput(val) {
      this.$emit('textChange', val);
    }
  }
};
exports.default = _default;