"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _routeMap = require("@/router/routeMap");
var _conversationItem = _interopRequireDefault(require("./components/conversationItem.vue"));
var _TrainingTestDrawer = _interopRequireDefault(require("./components/TrainingTestDrawer.vue"));
var _conversationListDialog = _interopRequireDefault(require("./components/conversationListDialog.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AiCourseEdit',
  components: {
    ConversationItem: _conversationItem.default,
    ConversationListDialog: _conversationListDialog.default,
    TrainingTestDrawer: _TrainingTestDrawer.default
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      this.$confirm('当前的更改未同步到模型，是否离开', this.$t('coaching.prompt'), {
        confirmButtonText: this.$t('coaching.exit'),
        cancelButtonText: this.$t('coaching.cancel')
      }).then(() => {
        next();
      });
    } else {
      next();
    }
  },
  data() {
    return {
      isEdit: false,
      title: '',
      originTitle: '',
      titleErrorMessage: '',
      submitLoading: false,
      conversationDialog: false,
      conversationList: [],
      characterList: [],
      accountLabel: [],
      trainingTestDrawerVisible: false,
      saved: true,
      loading: false,
      courseId: 0,
      modelId: 0
    };
  },
  created() {
    const id = this.$route.query.id;
    if (id) {
      this.isEdit = true;
      this.courseId = Number(id);
      // 获取配置详情
      this.getCourseDetail();
    }
    this.getAiCustomerCharacter();
  },
  methods: {
    async getCourseDetail() {
      let backfill = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.loading = true;
      const res = await (0, _coachingCenter.getCourseDetail)({
        id: this.courseId
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          name,
          account_label,
          conversation_list,
          model_id
        } = res.results;
        if (backfill) {
          this.title = name;
          this.originTitle = name;
          this.conversationList = conversation_list;
          this.accountLabel = account_label;
          this.trainingTestDrawerVisible = true;
        }
        this.modelId = model_id;
      }
    },
    async getAiCustomerCharacter() {
      const res = await (0, _coachingCenter.getAiCustomerCharacter)();
      if (res.code === 20000) {
        this.characterList = res.results.character;
      }
    },
    async submitTitleEdit() {
      if (!this.title) {
        this.titleErrorMessage = '请输入课程名称';
        return;
      } else {
        this.titleErrorMessage = '';
      }
      if (!this.courseId) return;
      this.saved = false;
      const res = await (0, _coachingCenter.editAiCustomerSopCourseName)({
        course_id: this.courseId,
        name: this.title
      });
      if (res.code === 20000) {
        this.saved = true;
        this.originTitle = this.title;
      } else {
        this.title = this.originTitle;
        this.titleErrorMessage = res.msg;
      }
    },
    deleteConversation(index) {
      this.saved = false;
      this.conversationList.splice(index, 1);
    },
    checkedConversation(val) {
      this.saved = false;
      if (!this.conversationList.some(item => item.id === val.id)) {
        this.conversationList.push(val);
      }
    },
    async saveCourse() {
      if (!this.conversationList.length) {
        this.$message.warning('请添加会话！');
        return;
      }
      const method = this.isEdit || this.courseId ? _coachingCenter.editAiCustomerCourse : _coachingCenter.createAiCustomerCourse;
      const params = {
        name: this.title,
        conversation_ids: this.conversationList.map(item => item.id),
        account_label: this.accountLabel
      };
      if (this.isEdit || this.courseId) {
        params.course_id = this.courseId;
      }
      this.submitLoading = true;
      const res = await method(params).finally(() => {
        this.submitLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success('保存成功！');
        this.saved = true;
        if (res.results.id) {
          this.courseId = res.results.id;
          this.originTitle = this.title;
        }
        this.trainingTestDrawerVisible = true;
        this.$nextTick(() => {
          this.$refs['training-test-drawer'].refresh();
          this.$refs['training-test-drawer'].practiceStatus = 'generating';
        });
      }
    },
    modelVerify() {
      if (!this.title) {
        this.titleErrorMessage = '请输入课程名称';
        return;
      } else {
        this.titleErrorMessage = '';
      }
      if (!this.isEdit && !this.courseId) {
        this.saveCourse();
      } else {
        this.getCourseDetail(false);
        if (!this.modelId) {
          this.$confirm('模型正在生成中, 是否重新生成?', this.$t('coaching.prompt'), {
            confirmButtonText: '重新生成',
            cancelButtonText: this.$t('coaching.cancel'),
            type: 'warning'
          }).then(() => {
            this.saveCourse();
          });
        } else {
          this.saveCourse();
        }
      }
    },
    checkedFeature(val) {
      this.saved = false;
      if (this.accountLabel.some(item => item === val)) {
        this.accountLabel = this.accountLabel.filter(item => item !== val);
      } else {
        this.accountLabel.push(val);
      }
    },
    goBack() {
      this.$router.push({
        path: _routeMap.ROUTES.course
      });
    }
  }
};
exports.default = _default;