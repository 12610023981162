"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "catalog-part"
  }, [_c("div", {
    staticClass: "upper-part"
  }, [_c("div", {
    staticClass: "head"
  }, [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu
    }
  }, [_c("el-button", {
    attrs: {
      slot: "preIcon",
      type: "text gray-bg"
    },
    on: {
      click: _vm.goBack
    },
    slot: "preIcon"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  }), _vm._v(" 返回 ")])], 1)], 1), _c("div", {
    staticClass: "knowledge-base-container"
  }, [_c("i", {
    staticClass: "iconfont icon-knowledge-base"
  }), _c("div", {
    staticClass: "knowledge-base-detail"
  }, [_c("KnowledgeBaseDropdown", {
    key: _vm.knowledgeBaseKey
  }), _c("el-tag", {
    attrs: {
      size: "mini",
      type: _vm.publicScope === 1 ? "" : "success"
    }
  }, [_vm._v(_vm._s(_vm.publicScopeLabel))])], 1), _vm.permission > 1 ? _c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      type: "text gray-bg",
      icon: "el-icon-more"
    }
  }), _c("el-dropdown-menu", {
    staticClass: "operate-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.getDropdownConfigs(), function (item) {
    return _c("el-dropdown-item", {
      key: item.type,
      attrs: {
        command: item.type,
        icon: `iconfont ${item.icon}`
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e()], 1), _vm.showKnowledgeDiscovery ? _c("div", {
    staticClass: "knowledge-discovery"
  }, [_c("div", {
    staticClass: "knowledge-discovery-btn",
    on: {
      click: _vm.goSessionDig
    }
  }, [_vm._m(0)])]) : _vm._e(), _c("el-divider"), _c("div", {
    staticClass: "catalog-top"
  }, [_vm._m(1), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "添加目录",
      placement: "bottom"
    }
  }, [_vm.permission ? _c("span", {
    staticClass: "add",
    on: {
      click: function ($event) {
        return _vm.$emit("createRootCatalog");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus"
  })]) : _vm._e()])], 1), _c("knowledge-search", {
    on: {
      search: _vm.searchKnowledge
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "catalog"
  }, [_vm.hasCatalog ? _c("knowledge-library-catalog", _vm._g({
    ref: "catalog",
    attrs: {
      "catalog-data": _vm.catalogData,
      "catalog-permisssion": _vm.permission,
      "current-node": _vm.currentNode,
      "show-knowledge-count": ""
    }
  }, _vm.$listeners)) : _c("p", {
    staticClass: "empty"
  }, [_c("span", [_vm._v(_vm._s(_vm.permission ? "暂无目录，" : "暂无目录"))]), _vm.permission ? _c("span", {
    staticClass: "create",
    on: {
      click: function ($event) {
        return _vm.$emit("createRootCatalog");
      }
    }
  }, [_vm._v("立即创建")]) : _vm._e()])], 1), _c("EditKnowledgeBaseConfig", {
    ref: "edit-knowledge-base-config",
    attrs: {
      visible: _vm.knowledgeBaseConfigVisible,
      "is-create": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.knowledgeBaseConfigVisible = $event;
      },
      handler: _vm.renameKnowledgeBase
    }
  }), _c("KnowledgeBasePermissionDrawer", {
    ref: "knowledgeBasePermissionDrawer",
    attrs: {
      visible: _vm.knowledgeBasePermissionVisible,
      "current-knowledge-base": _vm.currentKnowledgeBase
    },
    on: {
      "update:visible": function ($event) {
        _vm.knowledgeBasePermissionVisible = $event;
      },
      refresh: _vm.changeKnowledgeBasePermission
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "text"
  }, [_c("i", {
    staticClass: "iconfont icon-radar"
  }), _vm._v("知识点挖掘引擎")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "sub-title"
  }, [_c("i", {
    staticClass: "iconfont icon-catalogue"
  }), _vm._v("目录")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;