"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readExcel = void 0;
var XLSX = _interopRequireWildcard(require("xlsx"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
/**
 * 读取excel内容
 * @param {*} file
 * @returns
 */
const readExcel = file => {
  return new Promise((resolve, reject) => {
    try {
      // 获取上传对象
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const fileData = event.target.result;
        // 读取excel文件
        const workboot = XLSX.read(fileData, {
          type: 'binary'
        });
        // 暂默认读取第一个Sheet
        const sheel0 = workboot.SheetNames[0];
        // 第一行为注释，第二行为标题，删除
        delete workboot.Sheets[sheel0]['A1'];
        delete workboot.Sheets[sheel0]['A2'];
        delete workboot.Sheets[sheel0]['B2'];
        delete workboot.Sheets[sheel0]['C2'];
        const blockArr = XLSX.utils.sheet_to_json(workboot.Sheets[sheel0]);
        console.log(blockArr, 'blockArr');
        resolve(blockArr);
      };
      fileReader.readAsBinaryString(file);
    } catch (e) {
      reject(e);
    }
  });
};
exports.readExcel = readExcel;