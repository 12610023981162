"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 与剪辑库相关的数据状态   包括剪辑和文件夹管理
const state = {
  dateRange: []
};
const mutations = {
  SET_DATE_RANGE: (state, val) => {
    state.dateRange = val;
  }
};
var _default = {
  namespaced: true,
  state,
  mutations
};
exports.default = _default;