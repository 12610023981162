"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _SampleScopeSelector = _interopRequireDefault(require("./SampleScopeSelector"));
var _ConvDate = _interopRequireDefault(require("@/views/conversationPage/components/ConvDate"));
var _conversationList = require("@/api/conversationList");
var _routeMap = require("@/router/routeMap");
var _business = require("@/api/business");
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SampleScopeForm',
  components: {
    FormItemCard: _FormItemCard.default,
    SampleScopeSelector: _SampleScopeSelector.default,
    ConvDate: _ConvDate.default
  },
  props: {
    unit: {
      type: String,
      default: '条'
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    complianceItem: {
      type: Object,
      default: () => ({
        checkedItem: [],
        compliance_score_rule_ids: [],
        evaluation_template_id: ''
      })
    }
  },
  data() {
    return {
      unassignedCount: 0,
      assignedCount: 0,
      stageOptions: [],
      customFilterArray: [],
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'api_permissions']),
    hasScoreRule() {
      return this.complianceItem.checkedItem.includes('rule') && !!this.complianceItem.compliance_score_rule_ids.length && this.complianceItem.compliance_score_rule_ids;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getStageOptions();
          this.getDealCustomFilter();
        }
      },
      immediate: true
    },
    formData: {
      handler: function () {
        this.getStageOptions();
        this.getSampleConvCountMethod();
      },
      deep: true
    },
    hasScoreRule: {
      handler: function () {
        this.getSampleConvCountMethod();
      }
    }
  },
  methods: {
    getSampleConvCountMethod() {
      if (!this.workspaceInfo.id) return;
      const compliance_score_rule_ids = this.hasScoreRule ? {
        compliance_score_rule_ids: this.complianceItem.compliance_score_rule_ids
      } : {};
      if (this.formData.is_all_data) {
        this.getSampleConvCount({
          ...compliance_score_rule_ids
        });
      } else {
        const date = this.formData.date.range && this.formData.date.range.length ? {
          date: this.formData.date
        } : {};
        const filter = {
          ...this.formData.data_scope,
          ...date,
          ...compliance_score_rule_ids
        };
        this.getSampleConvCount(filter);
      }
    },
    async getDealCustomFilter() {
      const params = {
        access_type: _routeMap.ROUTES.conversation,
        workspace_id: this.workspaceInfo.id
      };
      (0, _business.getDealCustomFilter)(params).then(res => {
        this.customFilterArray = Object.values(res.results).flat();
      });
    },
    async getSampleConvCount(filter) {
      this.loading = true;
      const formatFilter = (0, _commonFunc.deepClone)(filter);
      if (formatFilter.date && formatFilter.date.range.length) {
        formatFilter.date.range = (0, _timezone.datePickerParseToZone)(formatFilter.date.range);
      }
      (0, _timezone.convertParams2UTCplus8)(formatFilter.custom_filters);
      const data = {
        ...formatFilter,
        account_sale: this.formData.is_all_data ? false : this.formData.account_sale,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _compliance.getSampleConvCount)(data);
      if (res.code === 200) {
        this.loading = false;
        this.unassignedCount = res.results.unassigned_count;
        this.assignedCount = res.results.assigned_count;
        this.$emit('getSampleConvScopeCount', this.unassignedCount);
      }
    },
    // 获取会话时商机阶段
    // TODO 后面需要删除source字段，后端做灰度用
    getStageOptions() {
      if (!this.workspaceInfo.id) return;
      (0, _conversationList.getStageOptions)({
        workspace_id: this.workspaceInfo.id,
        source: 'conversation'
      }).then(res => {
        this.stageOptions = res.results;
      });
    },
    getRoleInfo(rule, id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.formData.data_scope = rule;
      // eslint-disable-next-line vue/no-mutating-props
      this.formData.rule_id = id;
    }
  }
};
exports.default = _default;