"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _useMessage = require("@/hooks/use-message");
var _default = {
  __name: 'ManualImportDataSetDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    datasetLabelId: {
      type: Number,
      default: 0
    },
    datasetId: {
      type: Number,
      default: 0
    },
    conversationList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const modelInfo = (0, _vue.inject)('modelInfo', {});
    const sentenceNumber = (0, _vue.ref)(50);
    const annotation_status = (0, _vue.ref)(3);
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const sentenceNumberChange = val => {
      if (val > 200) {
        _useMessage.useMessage.warning('最多为200条');
      }
    };
    function formatConversationData() {
      const conversationData = props.conversationList.map(conversation => {
        const orders = [];
        conversation.sentences.forEach(item => {
          if (item.mode === 'text') {
            item.items.forEach(sentence => {
              orders.push(sentence.order);
            });
          }
          if (['event_nonleaf', 'event_leaf'].includes(item.mode)) {
            item.items.forEach(event => {
              event.data.forEach(_data => {
                _data.forEach(sentence => {
                  orders.push(sentence.order);
                });
              });
            });
          }
        });
        const newOrders = [...new Set(orders)];
        return {
          conversation_id: conversation.id,
          orders: newOrders
        };
      });
      return conversationData;
    }
    function getSentenceTotal() {
      const conversationData = formatConversationData();
      let ans = 0;
      for (const item of conversationData) {
        ans += item.orders.length;
      }
      return ans;
    }
    function getConversationData() {
      const conversationData = formatConversationData();
      const result = [];
      for (const item of conversationData) {
        for (const order of item.orders) {
          result.push({
            conversation_id: item.conversation_id,
            orders: [order]
          });
          if (result.length === sentenceNumber.value) {
            break;
          }
        }
        if (result.length === sentenceNumber.value) {
          break;
        }
      }
      return result;
    }
    const btnLoading = (0, _vue.ref)(false);
    const submit = async () => {
      if (sentenceNumber.value > 200) {
        _useMessage.useMessage.warning('最多为200条');
        return;
      }
      btnLoading.value = true;
      const res = await (0, _rapidCreateModel.expandTrainDatasetManual)({
        model_id: modelInfo.id || 0,
        dataset_label_id: props.datasetLabelId || 0,
        dataset_id: props.datasetId,
        conversation_data: getConversationData(),
        annotation_status: annotation_status.value
      }).finally(() => btnLoading.value = false);
      if (res.code === 20000) {
        emit('expandTrainDataSetSuccess');
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      modelInfo,
      sentenceNumber,
      annotation_status,
      emit,
      updateVisible,
      sentenceNumberChange,
      formatConversationData,
      getSentenceTotal,
      getConversationData,
      btnLoading,
      submit
    };
  }
};
exports.default = _default;