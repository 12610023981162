"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _business = require("@/api/business");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealDetailInfo',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    formDesc: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    dealId: {
      type: [Number, String]
    },
    isStageReadonly: {
      type: Boolean
    },
    business: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDealEdit: false,
      saveLoading: false,
      everData: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions', 'workspaceInfo'])
  },
  methods: {
    handleCancelClick() {
      this.isDealEdit = false;
      this.$emit('cancel', this.everData);
    },
    async submitForm() {
      if (this.$refs['form'].validate()) {
        this.saveLoading = true;
        const deal = (0, _commonFunc.deepClone)(this.value);
        (0, _timezone.datePickerWithTimeParse2Zone)(deal);
        const submitData = {
          deal_id: this.dealId,
          deal: deal,
          workspace_id: this.workspaceInfo.id
        };
        const res = await (0, _business.editBusiness)(submitData).finally(() => {
          this.saveLoading = false;
        });
        if (res.code == 200) {
          this.$message.success('保存成功');
          this.isDealEdit = false;
        }
      }
    },
    enterEdit() {
      this.everData = {
        ...this.value
      };
      this.isDealEdit = true;
    },
    handleInput(val) {
      this.$emit('handleInput', val);
    }
  }
};
exports.default = _default;