"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TemplateDialog = _interopRequireDefault(require("./components/TemplateDialog"));
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'NoteTemplate',
  components: {
    TemplateDialog: _TemplateDialog.default
  },
  props: {
    noteEmpty: Boolean,
    noteData: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      dialogVisible: false,
      templateName: '',
      visible: false
    };
  },
  methods: {
    handleShowNote(data) {
      this.$emit('showNote', data);
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    // 点击插入笔记模板按钮
    insertTemplate() {
      this.dialogVisible = true;
    },
    // 保存为新模板
    async addNoteTemplate() {
      if (this.templateName !== '') {
        const res = await (0, _videoDetail.addNoteTemplate)(this.templateName, this.noteData);
        if (res.code === 200) {
          this.$refs['template-dialog'].getNoteTemplateList();
          this.visible = false;
          this.$message.success('保存成功');
          this.templateName = '';
        }
      }
    }
  }
};
exports.default = _default;