"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chooseFile = _interopRequireDefault(require("./chooseFile"));
var _uploadProgress = _interopRequireDefault(require("./uploadProgress"));
var _uploadResult = _interopRequireDefault(require("./uploadResult"));
var _vuex = require("vuex");
var _organization = require("@/api/organization");
var _mediaUpload = require("@/api/mediaUpload");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const TYPE_MAP = {
  lead: {
    name: '线索',
    alias: 'lead',
    tempalteRouter: 'batch/lead',
    ruleText: '姓名及手机号',
    sourcePath: '/clue/index'
  },
  client: {
    name: '客户',
    alias: 'account',
    tempalteRouter: 'batch/account',
    ruleText: '公司名称',
    sourcePath: '/client'
  },
  deal: {
    name: '商机',
    alias: 'deal',
    tempalteRouter: 'batch/deal',
    ruleText: '商机名称及客户名称',
    sourcePath: '/business/index'
  },
  employee: {
    name: '员工',
    alias: 'employee',
    tempalteRouter: '/organization/user/load',
    ruleText: '',
    sourcePath: '/set/department'
  },
  contact: {
    name: '联系人',
    alias: 'account_contact',
    tempalteRouter: 'batch/account_contact',
    ruleText: ''
  }
};
var _default = {
  name: 'BatchUploadDialog',
  components: {
    chooseFile: _chooseFile.default,
    uploadProgress: _uploadProgress.default,
    uploadResult: _uploadResult.default
  },
  props: {
    visible: Boolean,
    type: {
      type: String,
      validator: val => ['lead', 'client', 'employee', 'deal', 'contact'].includes(val),
      require: true
    }
  },
  data() {
    return {
      active: 0,
      hasFile: false,
      timer: null,
      uploadResults: null,
      status: 'wait',
      percentage: 0,
      importLoading: null,
      importingNotify: null,
      importSuccessNotify: null
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.importingNotify && this.importingNotify.close();
        this.importSuccessNotify && this.importSuccessNotify.close();
      }
    },
    token(val) {
      if (!val) {
        this.initDialog();
      }
    },
    webSocketMsg: {
      handler: function () {},
      deep: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['token', 'webSocketMsg']),
    uploadName() {
      var _TYPE_MAP$this$type;
      return (_TYPE_MAP$this$type = TYPE_MAP[this.type]) === null || _TYPE_MAP$this$type === void 0 ? void 0 : _TYPE_MAP$this$type.name;
    },
    tempalteRouter() {
      var _TYPE_MAP$this$type2;
      return (_TYPE_MAP$this$type2 = TYPE_MAP[this.type]) === null || _TYPE_MAP$this$type2 === void 0 ? void 0 : _TYPE_MAP$this$type2.tempalteRouter;
    },
    alias() {
      var _TYPE_MAP$this$type3;
      return (_TYPE_MAP$this$type3 = TYPE_MAP[this.type]) === null || _TYPE_MAP$this$type3 === void 0 ? void 0 : _TYPE_MAP$this$type3.alias;
    },
    ruleText() {
      var _TYPE_MAP$this$type4;
      return (_TYPE_MAP$this$type4 = TYPE_MAP[this.type]) === null || _TYPE_MAP$this$type4 === void 0 ? void 0 : _TYPE_MAP$this$type4.ruleText;
    },
    lastStepIcon() {
      var _MAP$this$status;
      const MAP = {
        success: 'el-icon-circle-check',
        finish: 'el-icon-warning'
      };
      return (_MAP$this$status = MAP[this.status]) !== null && _MAP$this$status !== void 0 ? _MAP$this$status : '';
    },
    isMembers() {
      return this.type === 'employee';
    }
  },
  methods: {
    closeDialog() {
      // if (this.active === 0 && this.hasFile) {
      //   this.$confirm(`确认取消批量导入${this.isMembers?'成员':'线索'}？`, "提示", {
      //     confirmButtonText: "取消导入",
      //     confirmButtonClass: "el-button--danger",
      //     cancelButtonText: "继 续",
      //   })
      //     .then(() => {
      //       this.$emit("closeDialog");
      //     })
      //     .catch(() => {});
      //   return false;
      // }

      if (this.active !== 1) {
        setTimeout(() => {
          this.initDialog();
        }, 200);
      } else {
        this.setNotify();
      }
      this.$emit('closeDialog');
    },
    initDialog() {
      this.active = 0;
      this.status = 'wait';
      this.percentage = 0;
      this.hasFile = false;
      clearInterval(this.timer);
      this.importingNotify && this.importingNotify.close();
      this.importSuccessNotify && this.importSuccessNotify.close();
    },
    setNotify() {
      this.importingNotify && this.importingNotify.close();
      this.importingNotify = this.$notify({
        message: '<div class="loading-icon"></div> 导入中',
        customClass: 'importing-notify',
        dangerouslyUseHTMLString: true,
        duration: 0,
        position: 'bottom-right',
        offset: 100,
        showClose: false,
        onClick: this.openDialog
      });
      this.importLoading = _elementUi.Loading.service({
        target: '.loading-icon',
        customClass: 'import-loading-icon'
      });
    },
    setSuccessNotify() {
      this.importingNotify && this.importingNotify.close();
      const {
        fail
      } = this.uploadResults;
      this.importSuccessNotify = this.$notify({
        type: fail ? 'error' : 'success',
        message: fail ? '导入失败' : '导入成功',
        showClose: false,
        duration: 0,
        offset: 100,
        position: 'bottom-right',
        customClass: 'importing-notify',
        onClick: this.openDialog
      });
    },
    openDialog() {
      this.$emit('openDialog');
      this.importSuccessNotify && this.importSuccessNotify.close();
      this.importingNotify && this.importingNotify.close();
    },
    uploadStart(_ref) {
      let {
        task_id
      } = _ref;
      this.active++;
      this.timer = setInterval(() => {
        this.getFileInfo({
          task_id
        });
      }, 2000);
    },
    judgeStatus() {
      if (this.uploadResults.fail) {
        this.status = 'error';
      } else if (this.uploadResults.fail_id || this.uploadResults.abnormal_id || this.uploadResults.err_id) {
        this.status = 'finish';
      } else {
        this.status = 'success';
      }
    },
    renewData() {
      if (TYPE_MAP[this.type].sourcePath === this.$route.path) {
        this.$bus.$emit(`${this.type}UpdateList`);
      }
    },
    async getFileInfo(_ref2) {
      let {
        task_id
      } = _ref2;
      const getInfo = this.isMembers ? _organization.getMembersFileInfo : _mediaUpload.getBatchUpload;
      const res = await getInfo({
        task_id
      });
      if (res.code === 200) {
        if (typeof res.results === 'number') {
          this.percentage = parseInt(res.results * 100);
        } else {
          this.uploadResults = res.results;
          this.percentage = 100;
          clearInterval(this.timer);
          this.judgeStatus();
          this.active = 2;
          if (!this.visible) {
            this.setSuccessNotify();
          }
          this.renewData();
        }
      } else {
        this.$message.warning('数据导入失败');
        clearTimeout(this.timer);
        this.active = 0;
      }
    }
  }
};
exports.default = _default;