"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'MgAnchor',
  props: {
    anchorList: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    showCheckAll: {
      type: Boolean,
      default: false
    },
    isCheckAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: '',
      anchorContentElOffsetTopMap: {},
      isMenuHidden: false,
      offsetTopList: []
    };
  },
  computed: {
    scrollWrap() {
      return this.$refs['mgAnchorContent'];
    }
  },
  watch: {
    anchorList: {
      handler: function () {
        this.$nextTick(() => {
          this.init();
        });
      },
      immediate: true,
      deep: true
    },
    value: {
      handler: function (val) {
        if (val) {
          this.handleScroll(val);
          this.localValue = val;
        }
      }
    }
  },
  mounted() {
    this.scrollWrap.addEventListener('scroll', this.scrollFunction);
  },
  beforeDestroy() {
    this.scrollWrap.removeEventListener('scroll', this.scrollFunction);
  },
  methods: {
    toggleMenu() {
      this.isMenuHidden = !this.isMenuHidden;
    },
    init() {
      this.offsetTopList = this.$slots.default.map(vnode => ({
        id: vnode.key,
        offsetTop: vnode.elm.offsetTop
      }));
      this.offsetTopList.sort((a, b) => a.offsetTop - b.offsetTop);
      if (!this.value) {
        this.localValue = this.anchorList[0].id;
      }
    },
    handleScroll(key) {
      this.localValue = key;
      this.$nextTick(() => {
        var _this$$slots$default$;
        const currentVNodeOffsetTop = (_this$$slots$default$ = this.$slots.default.find(vnode => vnode.key === key)) === null || _this$$slots$default$ === void 0 ? void 0 : _this$$slots$default$.elm.offsetTop;
        this.$refs['mgAnchorContent'].scroll({
          top: currentVNodeOffsetTop,
          behavior: 'smooth'
        });
      });
    },
    scrollFunction: (0, _commonFunc.debounce)(function () {
      const fatherScrollTop = this.scrollWrap.scrollTop;
      for (let i = 0; i < this.offsetTopList.length; i++) {
        const item = this.offsetTopList[i];
        if (fatherScrollTop <= item.offsetTop) {
          this.localValue = item.id;
          return;
        }
      }
    })
  }
};
exports.default = _default;