"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useMessage = require("@/hooks/use-message");
var _DataSetSelectors = _interopRequireDefault(require("@/views/management/modelManagement/components/DataSetSelectors.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrainAndTestDataSet',
  props: {
    trainDatasetDisabled: {
      type: Boolean,
      default: false
    },
    trainDatasetList: {
      type: Array,
      default: () => []
    },
    trialDatasetList: {
      type: Array,
      default: () => []
    },
    datasetSplitType: {
      type: Number,
      default: 1
    },
    isAutoTest: {
      type: Boolean,
      default: true
    },
    showAutoTestSwitch: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:datasetSplitType', 'update:isAutoTest'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const datasetSplitTypeArr = [{
      label: '随机拆分训练集、测试集',
      value: 1,
      desc: '适用于前期丰富和修正数据集'
    }, {
      label: '固定拆分训练集、测试集',
      value: 2,
      desc: '继续使用前一版本拆分的测试集，适用于后期调整数据类型、超参数'
    }, {
      label: '自定义测试集',
      value: 3,
      desc: ''
    }];
    const localTrialDatasetList = (0, _vue.ref)([]);
    const localTrainDatasetList = (0, _vue.ref)([]);
    const changeDatasetSplitType = val => {
      emit('update:datasetSplitType', val);
    };
    const autoTestChange = val => {
      emit('update:isAutoTest', val);
    };
    const validTrainDatasetList = () => {
      var _localTrainDatasetLis, _localTrainDatasetLis2;
      return localTrainDatasetList.value.length && ((_localTrainDatasetLis = localTrainDatasetList.value[0]) === null || _localTrainDatasetLis === void 0 ? void 0 : _localTrainDatasetLis.dataset_id) && ((_localTrainDatasetLis2 = localTrainDatasetList.value[0]) === null || _localTrainDatasetLis2 === void 0 ? void 0 : _localTrainDatasetLis2.dataset_label_id);
    };
    const validTrialDatasetList = () => {
      if (props.datasetSplitType === 3) {
        var _localTrialDatasetLis, _localTrialDatasetLis2;
        return localTrialDatasetList.value.length && ((_localTrialDatasetLis = localTrialDatasetList.value[0]) === null || _localTrialDatasetLis === void 0 ? void 0 : _localTrialDatasetLis.dataset_id) && ((_localTrialDatasetLis2 = localTrialDatasetList.value[0]) === null || _localTrialDatasetLis2 === void 0 ? void 0 : _localTrialDatasetLis2.dataset_label_id);
      }
      return true;
    };
    const getTrainAndTestData = () => {
      if (!validTrainDatasetList()) {
        _useMessage.useMessage.warning('训练集不能为空');
        return false;
      }
      if (props.isAutoTest) {
        const res = validTrialDatasetList();
        if (!res) {
          _useMessage.useMessage.warning('测试集不能为空');
          return false;
        }
      }
      console.log(localTrialDatasetList.value, 'localTrialDatasetList');
      return {
        train_datasets: localTrainDatasetList.value,
        trial_datasets: props.datasetSplitType === 3 ? localTrialDatasetList.value : [],
        dataset_split_type: props.datasetSplitType
      };
    };
    expose({
      getTrainAndTestData
    });
    return {
      __sfc: true,
      props,
      datasetSplitTypeArr,
      localTrialDatasetList,
      localTrainDatasetList,
      emit,
      changeDatasetSplitType,
      autoTestChange,
      validTrainDatasetList,
      validTrialDatasetList,
      getTrainAndTestData,
      DataSetSelectors: _DataSetSelectors.default
    };
  }
};
exports.default = _default;