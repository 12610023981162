"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _clues = require("@/api/clues");
var _videoDetail = require("@/api/videoDetail");
var _workspace = _interopRequireDefault(require("./workspace"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function handleBusinessStatusOptions(options) {
  if (Array.isArray(options)) {
    options.forEach(item => {
      const {
        id,
        stage_name
      } = item;
      item.value = id;
      item.label = stage_name;
    });
  }
  return options;
}
const state = {
  businessStatusOptions: [],
  cluesStatusOptions: [],
  membersOptions: [],
  busiActiveDate: '',
  busiActiveConver: null
};
const mutations = {
  GET_BUSINESS_STATUS_OPTIONS: (state, options) => {
    state.businessStatusOptions = options;
  },
  GET_CLUES_STATUS_OPTIONS: (state, options) => {
    state.cluesStatusOptions = options;
  },
  SET_BUSI_ACTIVE_DATE: (state, val) => {
    state.busiActiveDate = val;
  },
  SET_BUSI_ACTIVE_CONVER: (state, val) => {
    state.busiActiveConver = val;
  },
  SET_MEMBER_OPTIONS: (state, options) => {
    state.membersOptions = options;
  }
};
const actions = {
  async get_business_status_options(_ref) {
    let {
      commit,
      state
    } = _ref;
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    const res = await (0, _business.getBusinessStatusOptions)({
      workspace_id: _workspace.default.state.workspaceInfo.id,
      type: type
    });
    const options = handleBusinessStatusOptions(res.results);
    commit('GET_BUSINESS_STATUS_OPTIONS', options);
  },
  async get_clues_status_options(_ref2) {
    let {
      commit,
      state
    } = _ref2;
    if (!state.cluesStatusOptions.length) {
      const res = await (0, _clues.getCluesStatusOptions)();
      commit('GET_CLUES_STATUS_OPTIONS', res.results.status_options);
    }
  },
  async get_member_options(_ref3) {
    let {
      commit,
      state
    } = _ref3;
    if (!state.membersOptions.length) {
      const res = await (0, _videoDetail.getShareNames)('');
      commit('SET_MEMBER_OPTIONS', res.results);
    }
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;