"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["knowledge-question", {
      active: _vm.currentQuestion.id === _vm.question.id
    }],
    attrs: {
      id: "knowledge-question-item"
    },
    on: {
      click: _vm.handleClick
    }
  }, [_c("div", {
    class: ["order", _vm.question.rank < 100 ? `order-${_vm.question.rank}` : "no-background-color"]
  }, [_vm._v(" " + _vm._s(_vm.question.rank) + " ")]), _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "name-info"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.question.name))]), _vm.question.status === "up" ? _c("el-tooltip", {
    attrs: {
      content: "热度较上周有上升",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-top"
  })]) : _vm._e(), _vm.question.status === "down" ? _c("el-tooltip", {
    attrs: {
      content: "热度较上周有下降",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-bottom"
  })]) : _vm._e(), _vm.question.status === "new" ? _c("span", {
    staticClass: "new"
  }, [_vm._v("new")]) : _vm._e()], 1), _c("div", {
    staticClass: "describe"
  }, [_vm.isIgnore ? [_c("span", [_vm._v("忽略时间：" + _vm._s(_vm.question.omit_time))])] : [_c("span", [_vm._v("出现频率：")]), _c("el-tooltip", {
    attrs: {
      content: _vm.dealFreqTooltip,
      placement: "top"
    }
  }, [_c("span", {
    staticClass: "appear-count"
  }, [_vm._v(_vm._s(_vm.question.deal_freq) + "%")])]), _c("span", {
    staticClass: "appear-precent"
  }, [_vm._v("问题占比：" + _vm._s(_vm.question.occ_percent) + "%")])]], 2), !_vm.isIgnore && _vm.question.knowledge_names.length ? _c("div", {
    staticClass: "added-konwledge-tag-container"
  }, _vm._l(_vm.question.knowledge_names, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(_vm._s(item))]);
  }), 0) : _vm._e()])]), _c("div", {
    staticClass: "right-part"
  }, [_vm.question.is_add ? _c("span", {
    staticClass: "is-added"
  }, [_vm._v("添加过")]) : _vm._e(), _vm._t("hover-handler-button")], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;