"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-folder"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/noContent/home-empty.png"),
      width: "100px",
      alt: "暂无剪辑"
    }
  }), _c("h1", {
    staticClass: "title"
  }, [_vm._v("暂无剪辑")]), _c("p", {
    directives: [{
      name: "route-permission",
      rawName: "v-route-permission",
      value: ["ConversationIndex"],
      expression: "['ConversationIndex']"
    }]
  }, [_vm._v(" 这是一个空文件夹，还没有剪辑哦～你可以前往"), _c("span", {
    staticClass: "link",
    on: {
      click: _vm.goConvPage
    }
  }, [_vm._v("会话")]), _vm._v("，选择一个会话添加片段至剪辑库。 ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;