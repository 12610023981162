"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SetOptionConvDrawer = _interopRequireDefault(require("@/views/conversationScoreRule/components/SetOptionConvDrawer"));
var _ManageSampleScopeDrawer = _interopRequireDefault(require("./ManageSampleScopeDrawer"));
var _SavedFiltersContent = _interopRequireDefault(require("@/views/conversationPage/components/SavedFiltersContent"));
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SampleScopeSelector',
  components: {
    SetOptionConvDrawer: _SetOptionConvDrawer.default,
    ManageSampleScopeDrawer: _ManageSampleScopeDrawer.default,
    SavedFiltersContent: _SavedFiltersContent.default
  },
  props: {
    stageOptions: {
      type: Array,
      default: () => []
    },
    customFilterArray: {
      type: Array,
      default: () => []
    },
    ruleId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      sampleScopeList: [],
      canCreate: true,
      count: 0,
      sampleScope: '',
      setOptionConvDrawerVisible: false,
      manageSampleScopeDrawerVisible: false,
      dataScopeBackFill: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getSampleRuleList();
        }
      },
      immediate: true
    },
    ruleId: {
      handler: function (val) {
        if (this.sampleScopeList.find(item => item.id === val)) {
          this.sampleScope = val;
        }
      }
    },
    sampleScopeList: {
      handler: function (val) {
        if (val.find(item => item.id === this.ruleId)) {
          this.sampleScope = this.ruleId;
        } else {
          this.sampleScope = '';
          this.$emit('change', {}, this.sampleScope);
        }
      }
    }
  },
  methods: {
    addSampleScope() {
      if (this.sampleScopeList.length >= 10) return;
      this.setOptionConvDrawerVisible = true;
    },
    manageSavedSampleScope() {
      this.manageSampleScopeDrawerVisible = true;
    },
    async getSampleRuleList() {
      const res = await (0, _compliance.getSampleRuleList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.sampleScopeList = res.results.rules;
        this.canCreate = res.results.can_create;
        this.count = res.results.total_count;
      }
    },
    async saveOptionConvFilters(filters, name) {
      const formatFilter = (0, _commonFunc.deepClone)(filters);
      if (formatFilter.date && formatFilter.date.range.length) {
        formatFilter.date.range = (0, _timezone.datePickerParseToZone)(formatFilter.date.range);
      }
      (0, _timezone.convertParams2UTCplus8)(formatFilter.custom_filters);
      const data = {
        name,
        rule: formatFilter,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _compliance.addSampleRule)(data);
      if (res.code === 20000) {
        const newRoleId = res.results.rule_id;
        this.$message.success('保存成功');
        await this.getSampleRuleList();
        this.sampleScope = newRoleId;
        this.getRuleInfo(newRoleId);
      }
    },
    getRuleInfo(id) {
      const rule = id ? this.sampleScopeList.find(item => item.id === id).rule : {};
      this.$emit('change', rule, id);
    }
  }
};
exports.default = _default;