"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _KnowledgeItem = _interopRequireDefault(require("./KnowledgeItem.vue"));
var _KnowledgeLibraryImportDrawer = _interopRequireDefault(require("./KnowledgeLibraryImportDrawer.vue"));
var _IntelligentTractDialog = _interopRequireDefault(require("./IntelligentTractDialog.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgePart',
  components: {
    KnowledgeLibraryImportDrawer: _KnowledgeLibraryImportDrawer.default,
    NoData: _index.default,
    KnowledgeItem: _KnowledgeItem.default,
    IntelligentTractDialog: _IntelligentTractDialog.default
  },
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    targetKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      knowledgeList: [],
      listLoading: false,
      toBeCheckedKnowledge: {},
      importDrawerVisible: false,
      IntelligentTractDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission', 'knowledgeBaseInfo']),
    knowledgePermission() {
      var _this$knowledgeBaseIn;
      return (_this$knowledgeBaseIn = this.knowledgeBaseInfo) === null || _this$knowledgeBaseIn === void 0 ? void 0 : _this$knowledgeBaseIn.permission;
    }
  },
  watch: {
    'currentNode.id': {
      handler(val) {
        val && this.getKnowledgeList();
      },
      immediate: true
    },
    'targetKnowledge.id': {
      handler: function () {
        this.$nextTick(() => {
          this.handleScroll();
        });
      }
    }
  },
  methods: {
    handleCommand(type) {
      this.$emit(type, this.currentNode);
    },
    async getKnowledgeList(targetKnowledgeId) {
      this.listLoading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeList)({
        catalog_id: this.currentNode.id
      }).finally(() => {
        this.listLoading = false;
      });
      if (res.code === 20000) {
        this.knowledgeList = res.results.data;
        this.handleScroll(targetKnowledgeId);
      }
    },
    handleScroll(targetKnowledgeId) {
      this.$nextTick(() => {
        var _this$$refs, _this$$refs$, _this$$refs$$$el;
        (_this$$refs = this.$refs[`knowledge-item-${targetKnowledgeId || this.targetKnowledge.id}`]) === null || _this$$refs === void 0 ? void 0 : (_this$$refs$ = _this$$refs[0]) === null || _this$$refs$ === void 0 ? void 0 : (_this$$refs$$$el = _this$$refs$.$el) === null || _this$$refs$$$el === void 0 ? void 0 : _this$$refs$$$el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      });
    },
    editKnowledge(id) {
      this.$emit('editKnowledge', id);
    },
    getCurrentKnowledge() {
      // ==================待后续细看
      // const id = this.$refs.anchor.localValue;
      // return this.knowledgeList.find((i) => i.id === id);
    },
    handleMoveKnowledge(knowledgeId) {
      this.$emit('moveKnowledge', knowledgeId);
      this.refreshData();
    },
    handleAddTricks(id) {
      this.getKnowledgeList(id);
    },
    refreshData() {
      this.$emit('refresh');
      this.getKnowledgeList();
    }
  }
};
exports.default = _default;