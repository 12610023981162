"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "move-account-sea-dialog-wrapper",
    attrs: {
      title: "转移至公海",
      visible: _vm.moveAccountSeaDialogVisible,
      width: "482px",
      "before-close": _vm.handleClose,
      "custom-class": "move-account-sea-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveAccountSeaDialogVisible = $event;
      }
    }
  }, [_c("div", [_c("header", {
    staticClass: "header"
  }, [_vm._v("是否将选中的客户退回公海？")]), _c("el-form", {
    ref: "moveAccountSeaForm",
    attrs: {
      model: _vm.formData,
      rules: _vm.formDataRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退回原因",
      "label-position": _vm.labelPosition,
      prop: "refund_reason"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择退回原因"
    },
    model: {
      value: _vm.formData.refund_reason,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "refund_reason", $$v);
      },
      expression: "formData.refund_reason"
    }
  }, _vm._l(_vm.reasonOption, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "30",
      "show-word-limit": "",
      placeholder: "备注"
    },
    model: {
      value: _vm.formData.add_reason,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "add_reason", $$v);
      },
      expression: "formData.add_reason"
    }
  })], 1)], 1), _c("p", {
    staticClass: "content-bottom"
  }, [_vm._v(" 放弃到公海后此客户数据将属于公共资源，原归属人员不能再维护跟进和更新此客户数据。“联系人”模块内相关的数据也随之退回。 ")])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitMoveAccountSeaForm
    }
  }, [_vm._v("确 定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;