"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelEditScoreRule = cancelEditScoreRule;
exports.changeScoreRuleStatus = changeScoreRuleStatus;
exports.createScoreRule = createScoreRule;
exports.deleteScoreRule = deleteScoreRule;
exports.getAccountTagOptions = getAccountTagOptions;
exports.getConversationCount = getConversationCount;
exports.getConversationScoreOptions = getConversationScoreOptions;
exports.getScoreInfo = getScoreInfo;
exports.getScoreRuleList = getScoreRuleList;
exports.startEditScoreRule = startEditScoreRule;
exports.updateScoreRule = updateScoreRule;
var _request = require("@/utils/request");
// 获取评分规则列表
function getScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/list',
    method: 'get',
    params
  });
}

// 开启、禁用评分规则
function changeScoreRuleStatus(data) {
  return (0, _request.request)({
    url: '/conversation/score_rule/change_status',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除评分规则
function deleteScoreRule(data) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑获取当前评分详情
function getScoreInfo(params) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/get_info',
    method: 'get',
    params
  });
}

// 更新评分规则
function updateScoreRule(data) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新建评分规则
function createScoreRule(data) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话评分类型列表
function getConversationScoreOptions() {
  return (0, _request.webService)({
    url: '/conversation/score_rule/item_type_list',
    method: 'get'
  });
}

// 获取客户标签列表options
function getAccountTagOptions(params) {
  return (0, _request.webService)({
    url: '/conversation/tag/list',
    method: 'get',
    params
  });
}

// 会话评分规则开始编辑
function startEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/start_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话评分规则取消编辑
function cancelEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/cancel_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话评分规则列表页 - 根据评分规则配置获取会话数量
function getConversationCount(params) {
  return (0, _request.request)({
    url: '/conversation/score_rule/get_count',
    method: 'get',
    params
  });
}