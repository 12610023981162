"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePagination = usePagination;
var _vue = require("vue");
function usePagination(getList) {
  const currentPage = (0, _vue.ref)(1);
  const pageSize = (0, _vue.ref)(10);
  const total = (0, _vue.ref)(0);
  const onSizeChange = val => {
    currentPage.value = 1;
    getList();
  };
  return {
    currentPage,
    pageSize,
    total,
    onSizeChange
  };
}