"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueVirtualScrollList = _interopRequireDefault(require("vue-virtual-scroll-list"));
var _SampleItem = _interopRequireDefault(require("./SampleItem.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateAllSample',
  props: {
    dataSetDataList: {
      type: Array,
      default: () => []
    },
    activeDataSetData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['changeDataSetData'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const convPopoverVisible = (0, _vue.ref)(false);
    const handleChangeDataSetData = item => {
      emit('changeDataSetData', item);
      convPopoverVisible.value = false;
    };
    const activeDataSetDataIndex = (0, _vue.computed)(() => {
      var _props$dataSetDataLis;
      return (_props$dataSetDataLis = props.dataSetDataList) === null || _props$dataSetDataLis === void 0 ? void 0 : _props$dataSetDataLis.findIndex(item => {
        var _props$activeDataSetD;
        return item.id === ((_props$activeDataSetD = props.activeDataSetData) === null || _props$activeDataSetD === void 0 ? void 0 : _props$activeDataSetD.id);
      });
    });
    return {
      __sfc: true,
      props,
      emit,
      convPopoverVisible,
      handleChangeDataSetData,
      activeDataSetDataIndex,
      VirtualList: _vueVirtualScrollList.default,
      SampleItem: _SampleItem.default
    };
  }
};
exports.default = _default;