"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "coach-overview"
  }, [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: "辅导概览"
    }
  }), _c("table-filters", {
    on: {
      change: _vm.handleFilterChange
    }
  }), _c("coach-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      list: _vm.coachList
    },
    on: {
      openDrawer: _vm.openDrawer
    }
  }), _c("el-pagination", {
    attrs: {
      "page-sizes": [10, 20, 30, 40],
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.listParams.page,
      "page-size": _vm.listParams.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  }), _c("coach-detail-drawer", {
    ref: "drawer",
    attrs: {
      visible: _vm.drawerVisible,
      user: _vm.activeUser,
      "coach-date": _vm.coachDate
    },
    on: {
      closeDrawer: _vm.closeDrawer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;