"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    staticClass: "model-select",
    attrs: {
      "remote-method": _setup.remoteMethod,
      remote: "",
      filterable: "",
      placeholder: "请输入模型名称/ID",
      value: _vm.value,
      clearable: ""
    },
    on: {
      input: _setup.onInput,
      change: _setup.onChange
    }
  }, _vm._l(_setup.options, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("div", {
      staticClass: "option-item"
    }, [_c("i", {
      staticClass: "iconfont icon-box-square",
      class: item.model_type === 1 ? "rule" : "ai"
    }), _c("span", [_vm._v(_vm._s(item.name))])])]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;