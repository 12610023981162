"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _modelInfo = require("../hooks/modelInfo");
var _TestDetailFilters = _interopRequireDefault(require("./TestDetailFilters.vue"));
var _EventResultTable = _interopRequireDefault(require("../../keyEventCreateRuleModel/components/ModelResult/EventResultTable.vue"));
var _FixTrainDatasetDialog = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/FixTrainDatasetDialog.vue"));
var _TestDetailDataDialog = _interopRequireDefault(require("./TestDetailDataDialog.vue"));
var _modelManagement = require("@/api/modelManagement.js");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _index = require("@/utils/index.js");
var _fixTrainDataset = require("@/views/management/rapidModeling/hooks/fixTrainDataset.js");
var _message = require("@/utils/message");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TestDetailDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: Number,
      default: 0
    },
    modelVersion: {
      type: Number,
      default: 0
    },
    prafTaskId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'reTraining', 'reCalcTargets'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const testTaskInfo = (0, _vue.ref)({
      id: 0,
      precision: 0,
      recall: 0,
      f1: 0,
      dataset: '',
      dataset_label: '',
      sentence_count: 0,
      model_cate: 0,
      dataset_id: 0
    });
    const getTestTaskDetailAsync = async () => {
      const params = {
        praf_task_id: props.prafTaskId,
        model_id: props.modelId,
        model_version: props.modelVersion
      };
      const res = await (0, _modelManagement.getTestTaskDetail)(params);
      testTaskInfo.value = res.results;
    };
    (0, _vue.watchEffect)(() => {
      getTestTaskDetailAsync();
    });
    // 筛选器变化
    const filterParams = (0, _vue.ref)({
      predict_result: 0,
      annotation_result: 0,
      rule_result: 0
    });
    const filterChange = val => {
      const {
        is_hit,
        is_annotated,
        is_right
      } = val;
      filterParams.value.predict_result = is_hit;
      filterParams.value.annotation_result = is_annotated;
      filterParams.value.rule_result = is_right;
      listParams.value.page = 1;
    };

    // 分页相关逻辑
    const listParams = (0, _vue.ref)({
      page: 1,
      size: 20
    });
    const handleSizeChange = val => {
      listParams.value.page = 1;
      listParams.value.size = val;
    };
    const handleCurrentChange = val => {
      listParams.value.page = val;
    };
    const tableLoading = (0, _vue.ref)(false);
    const tableData = (0, _vue.ref)([]);
    const headerTargetCounts = (0, _vue.ref)({});
    const total = (0, _vue.ref)(0);
    const getTestDetailList = async () => {
      tableLoading.value = true;
      const params = {
        praf_task_id: props.prafTaskId,
        ...filterParams.value,
        ...listParams.value
      };
      const res = await (0, _modelManagement.getTestRecordDetail)(params).finally(() => tableLoading.value = false);
      if (res.code === 20000) {
        const {
          total_count,
          data,
          ...counts
        } = res.results;
        tableData.value = data;
        total.value = total_count;
        headerTargetCounts.value = counts;
      }
    };
    (0, _vue.watchEffect)(() => {
      console.log('---------');
      getTestDetailList();
      getModelPredictAnnotationIdsApi();
    });

    // 抽屉关闭

    const updateVisible = () => emit('update:visible', false);

    // 跳转到数据集
    const {
      goDataSet
    } = (0, _modelInfo.useGoDataSet)();
    const handleChangeTestAnnotation = async (val, row) => {
      const params = {
        praf_task_id: props.prafTaskId,
        praf_task_detail_ids: [row.id],
        status: val
      };
      const res = await (0, _modelManagement.updateTrailAnnotation)(params);
      if (res.code === 20000) {
        _message.message.success('修改成功');
        getTestTaskDetailAsync();
        getTestDetailList();
      }
    };
    const onReTraining = () => {
      emit('reTraining');
    };
    const modelInfo = (0, _vue.ref)({
      id: props.modelId,
      version: props.modelVersion
    });
    (0, _vue.provide)('modelInfo', modelInfo.value);

    // 修正数据集
    const {
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      currentResultsRowInfo
    } = (0, _fixTrainDataset.useFixTrainDataset)(modelInfo);
    const testDetailDataDialogVisible = (0, _vue.ref)(false);
    const testDetailDataIds = (0, _vue.ref)([]);
    const currentTestDataDetailId = (0, _vue.ref)(0);
    async function getModelPredictAnnotationIdsApi() {
      const res = await (0, _rapidCreateModel.getModelPredictAnnotationIds)({
        praf_task_id: props.prafTaskId,
        predict_res: filterParams.value.predict_result,
        rule_results: filterParams.value.rule_result,
        status: filterParams.value.annotation_result
      });
      if (res.code === 20000) {
        testDetailDataIds.value = res.results.annotation_ids;
      }
    }
    const openTestDataDetailDialog = row => {
      testDetailDataDialogVisible.value = true;
      currentTestDataDetailId.value = row.id;
    };
    return {
      __sfc: true,
      props,
      testTaskInfo,
      getTestTaskDetailAsync,
      filterParams,
      filterChange,
      listParams,
      handleSizeChange,
      handleCurrentChange,
      tableLoading,
      tableData,
      headerTargetCounts,
      total,
      getTestDetailList,
      emit,
      updateVisible,
      goDataSet,
      handleChangeTestAnnotation,
      onReTraining,
      modelInfo,
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      currentResultsRowInfo,
      testDetailDataDialogVisible,
      testDetailDataIds,
      currentTestDataDetailId,
      getModelPredictAnnotationIdsApi,
      openTestDataDetailDialog,
      TestDetailFilters: _TestDetailFilters.default,
      EventResultTable: _EventResultTable.default,
      FixTrainDatasetDialog: _FixTrainDatasetDialog.default,
      TestDetailDataDialog: _TestDetailDataDialog.default,
      handlePercentage: _index.handlePercentage
    };
  }
};
exports.default = _default;