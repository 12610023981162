"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-media-dialog"
  }, [_vm.uploadMediaDialogVisible ? _c("el-dialog", {
    staticClass: "upload-media-dialog-wrapper",
    attrs: {
      width: "1000px",
      visible: _vm.uploadMediaDialogVisible,
      "before-close": _vm.closeUploadMediaDialog,
      "append-to-body": "",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "custom-class": "upload-media-dialog"
    }
  }, [_c("upload-media-dialog-head", {
    attrs: {
      slot: "title",
      "tooltip-list": ["格式支持：WAV、MP3、M4A、WMA、AAC、OGG、AMR、FLAC；AVI、RMVB、MKV、ASF、WMV、MP4、3GP、FLV、MOV、M4V", `单次最多上传${_vm.limitCount}个文件`, `单个音频文件最大${_vm.audioUploadLimit}MB`, "单个视频文件最大4GB", "单个文件最长3个小时"]
    },
    slot: "title"
  }), _c("upload-media", _vm._b({
    ref: "upload-media",
    staticClass: "upload-media",
    on: {
      select: _vm.updateFileList
    }
  }, "upload-media", _vm.$attrs, false)), _c("div", {
    staticClass: "form-title"
  }, [_vm._v("完善会话信息")]), _vm.isBase ? _c("base-media-form", {
    attrs: {
      file: _vm.mediaFileList
    },
    on: {
      cancel: _vm.abandonMediaForm,
      submitSucceed: _vm.handleBaseSubmitSucceed
    }
  }) : _c("upload-media-form", _vm._g(_vm._b({
    attrs: {
      file: _vm.mediaFileList
    },
    on: {
      cancel: _vm.abandonMediaForm,
      hostSalesManChange: _vm.hostSalesManChange
    }
  }, "upload-media-form", _vm.$attrs, false), _vm.$listeners))], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;