"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _default = {
  __name: 'DepartmentFilters',
  props: {
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['filterChange'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const filters = (0, _vue.ref)({
      filter: '',
      workspace_id: '',
      is_active: 'all',
      is_enable: 'all',
      user_type: 'all'
    });

    // 启用 select
    const activeSelectOptions = [{
      value: 'all',
      label: (0, _i18n.t)('management.全部状态')
    }, {
      value: 'active',
      label: (0, _i18n.t)('management.启用')
    }, {
      value: 'inactive',
      label: (0, _i18n.t)('management.禁用')
    }];
    const enableSelectOptions = [{
      value: 'all',
      label: (0, _i18n.t)('management.是否登录')
    }, {
      value: 'enable',
      label: (0, _i18n.t)('management.已登录')
    }, {
      value: 'disabled',
      label: (0, _i18n.t)('management.未登录')
    }];

    // 账号类型
    const accountTypeOptions = [{
      value: 'all',
      label: (0, _i18n.t)('management.全部类型')
    }, {
      value: 'formal',
      label: (0, _i18n.t)('management.正式版')
    }, {
      value: 'trial',
      label: (0, _i18n.t)('management.试用版')
    }];
    const formatSendFiltersValue = filters => {
      const obj = {
        is_active: {
          all: '',
          active: true,
          inactive: false
        },
        is_enable: {
          all: '',
          enable: true,
          disabled: false
        },
        user_type: {
          all: '',
          formal: 'formal',
          trial: 'trial'
        }
      };
      const res = {};
      for (const key in filters) {
        if (obj[key]) {
          res[key] = obj[key][filters[key]];
        } else {
          res[key] = filters[key];
        }
      }
      return res;
    };
    const handleFilterChange = () => {
      const res = formatSendFiltersValue(filters.value);
      emit('filterChange', res);
    };
    const initFilters = () => {
      filters.value = {
        filter: '',
        workspace_id: '',
        is_active: 'all',
        is_enable: 'all',
        user_type: 'all'
      };
    };
    expose({
      initFilters
    });
    return {
      __sfc: true,
      props,
      filters,
      activeSelectOptions,
      enableSelectOptions,
      accountTypeOptions,
      formatSendFiltersValue,
      emit,
      handleFilterChange,
      initFilters,
      t: _i18n.t
    };
  }
};
exports.default = _default;