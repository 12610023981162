"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-set-root"
  }, [_c("navbar", {
    attrs: {
      title: "商机设置",
      "back-button": ""
    }
  }), _c("div", {
    staticClass: "business-body"
  }, [_c("div", {
    staticClass: "business-subtitle"
  }, [_vm._v("风险提示规则")]), _vm._m(0), _c("risk-rule-table")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("span", [_vm._v(" 1.系统在每天的 00:00-02:00 统一更新商机风险数据；2.若您修改了商机风险规则，新的规则将于次日生效；3.此设置将应用于所有人。 ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;