"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _default = {
  name: 'LikeInfo',
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    type: {
      // freq:问题； qa:问答
      type: String,
      default: 'freq'
    }
  },
  data() {
    return {
      localInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    info: {
      handler: function (val) {
        this.localInfo = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleLikeOrDislike: (0, _commonFunc.throttle)(async function (type) {
      const params = this.getParams(type);
      const {
        op_type
      } = params;
      const res = await (0, _knowledgeLibrary.likeOrDislike)(params);
      if (res.code === 20000) {
        const {
          like_count
        } = res.results;
        this.localInfo.like_count = like_count;
        switch (op_type) {
          case 'like':
            this.localInfo.is_like = true;
            this.localInfo.is_dislike = false;
            break;
          case 'offlike':
            this.localInfo.is_like = false;
            break;
          case 'dislike':
            this.localInfo.is_dislike = true;
            this.localInfo.is_like = false;
            break;
          case 'offdislike':
            this.localInfo.is_dislike = false;
            break;
          default:
            return;
        }
      }
    }),
    getParams(type) {
      let op_type;
      if (type === 'like') {
        op_type = this.localInfo.is_like ? 'offlike' : 'like';
      } else {
        op_type = this.localInfo.is_dislike ? 'offdislike' : 'dislike';
      }
      const id = this.type === 'qa' ? this.localInfo.qa_id : this.localInfo.id;
      return {
        id: String(id),
        space_id: this.workspaceInfo.id,
        type: this.type,
        op_type
      };
    }
  }
};
exports.default = _default;