"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "scroll-box"
  }, [_c("div", {
    staticClass: "filter-wrap"
  }, [_c("bi-filter", {
    attrs: {
      layout: ["tree_id", "user_ids", _vm.closeTime],
      params: {
        type: JSON.stringify(["trade"])
      }
    },
    on: {
      filtersChange: _vm.getFunnelData
    }
  }), _vm.customFieldPermission ? _c("div", {
    staticClass: "setting"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handlePredictSettingClick
    }
  }, [_vm._v(" 设置预测赢单率 "), _c("i", {
    staticClass: "iconfont icon-top-right"
  })])], 1) : _vm._e()], 1), _c("div", {
    staticClass: "chart-wrapper"
  }, [_c("funnel-chart", {
    attrs: {
      data: _vm.funnelData,
      max: _vm.funnelMax
    }
  })], 1), _c("div", {
    staticClass: "funnel-info"
  }, [_c("span", {
    staticClass: "funnel-total"
  }, [_vm._v("合计交易数：" + _vm._s(_vm.totalDealCount))]), _c("span", [_vm._v("预测金额：" + _vm._s(_vm.predictAmount))])]), _c("funnel-table", {
    staticClass: "funnel-table",
    attrs: {
      data: _vm.tableData
    }
  }), _c("predict-setting-dialog", {
    ref: "predictDialogRef",
    on: {
      predictSetting: _vm.handlePredictChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;