"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BookMeetingDialog = _interopRequireDefault(require("./BookMeetingDialog"));
var _InviteSucceedDialog = _interopRequireDefault(require("@/components/InviteSucceedDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BookMeeting',
  components: {
    BookMeetingDialog: _BookMeetingDialog.default,
    InviteSucceedDialog: _InviteSucceedDialog.default
  },
  data() {
    return {
      bookMeetingDialogVisible: false,
      inviteSucceedDialogVisible: false,
      inviteText: ''
    };
  },
  methods: {
    openInviteDialog(meetingInfo) {
      const {
        meeting_code,
        meeting_name,
        meeting_url,
        interviewer_name,
        meeting_time_duration
      } = meetingInfo;
      this.inviteSucceedDialogVisible = true;
      this.inviteText = `
${interviewer_name} 邀请您参加腾讯会议
会议主题：${meeting_name}
会议时间：${meeting_time_duration}

点击链接入会，或添加至会议列表：
${meeting_url}

# 腾讯会议：${Number(meeting_code).toLocaleString().replace(/,/g, ' ')}
`;
    }
  }
};
exports.default = _default;