"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _client = require("@/api/client");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClientForm',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    accountId: {
      type: [Number, String]
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    isCallUpShow: {
      type: Boolean
    }
  },
  data() {
    return {
      localFormData: {},
      formDesc: [],
      // 是否显示编辑按钮
      isEdit: false,
      saveLoading: false // 保存按钮是否可用
    };
  },

  watch: {
    formData(val) {
      if (val) {
        this.localFormData = val.field;
      }
    },
    visible(val) {
      if (val) {
        this.isEdit = false;
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'api_permissions'])
  },
  created() {
    this.getClientDynamicForm();
  },
  methods: {
    async getClientDynamicForm() {
      const clientDynamicForm = await (0, _client.getClientDynamicForm)();
      if (clientDynamicForm.code === 200) {
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(clientDynamicForm.results)));
      }
    },
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    },
    handleCanelClick() {
      this.localFormData = this.formData.field;
      this.isEdit = false;
    },
    handleUpdataClientDetail() {
      const res = this.$refs.form.validate();
      const data = {
        account: this.localFormData,
        account_id: this.formData.id
      };
      if (res) {
        this.updataClientDetail(data);
      }
    },
    async updataClientDetail(data) {
      this.saveLoading = true;
      try {
        const res = await (0, _client.updataClientDetail)(data);
        if (res.code === 200) {
          this.$message.success('修改客户信息成功');
          this.isEdit = false;
          this.$emit('freshCurrentList');
          this.$emit('freshClientDetail');
        }
      } finally {
        this.saveLoading = false;
      }
    }
  }
};
exports.default = _default;