"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExecutorList = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/ExecutorList.vue"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _coachingCenter = require("@/api/coachingCenter");
var _timezone = require("@/utils/timezone");
var _vue = require("vue");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TaskRecordDrawerContent',
  props: {
    courseInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(__props) {
    const props = __props;
    const headerList = [{
      prop: 'name',
      label: (0, _i18n.t)('coaching.taskName'),
      width: 280,
      fixed: 'left',
      showOverflowTip: true
    }, {
      prop: 'status',
      label: (0, _i18n.t)('coaching.status'),
      width: 140
    }, {
      prop: 'creator_info',
      label: (0, _i18n.t)('coaching.creator'),
      width: 140
    }, {
      prop: 'user_infos',
      label: (0, _i18n.t)('coaching.executor'),
      width: 240
    }, {
      prop: 'start_time',
      label: (0, _i18n.t)('coaching.startTime'),
      width: 150
    }];
    const tableLoading = (0, _vue.ref)(false);
    const tableData = (0, _vue.ref)([]);
    (0, _vue.watchEffect)(() => {
      if (props.courseInfo.id) {
        getTaskRecordsList(props.courseInfo.id);
      }
    });
    async function getTaskRecordsList(courseId) {
      tableLoading.value = true;
      const res = await (0, _coachingCenter.getCourseDispatchTaskList)({
        course_id: courseId
      }).finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        tableData.value = res.results.data;
      }
    }
    function onRowClick(row) {}
    function getStatusClass(row) {
      const map = {
        0: 'status under-way',
        1: 'status has-as',
        2: 'status have-not-started',
        3: 'status have-all-done'
      };
      return map[row.status];
    }
    function getStatus(row) {
      const map = {
        0: (0, _i18n.t)('coaching.inProgress'),
        1: (0, _i18n.t)('coaching.deadlinePassed'),
        2: (0, _i18n.t)('coaching.notStarted'),
        3: (0, _i18n.t)('coaching.completed')
      };
      return map[row.status];
    }
    return {
      __sfc: true,
      props,
      headerList,
      tableLoading,
      tableData,
      getTaskRecordsList,
      onRowClick,
      getStatusClass,
      getStatus,
      ExecutorList: _ExecutorList.default,
      ImageAvatar: _ImageAvatar.default,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone,
      t: _i18n.t
    };
  }
};
exports.default = _default;