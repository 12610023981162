"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    text: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 50
    },
    noPrint: {
      // 是否需要打印效果
      type: Boolean,
      default: false
    },
    isStreamText: {
      // 是否是流式文本数据
      type: Boolean,
      default: false
    },
    streamTextStatus: {
      // 流式情况下文字状态，打印过程中printing，完毕done，
      type: String,
      default: 'done'
    }
  },
  data() {
    return {
      index: 1,
      showCursor: false,
      timer: null
    };
  },
  computed: {
    printingText() {
      if (this.isStreamText) {
        return this.text;
      } else {
        return this.text.substring(0, this.index);
      }
    }
  },
  mounted() {
    this.printText();
  },
  methods: {
    printText() {
      if (this.noPrint || this.isStreamText) return;
      if (this.index < this.text.length) {
        this.showCursor = true;
        this.index++;
        this.timer = setTimeout(this.printText, this.speed);
        this.$emit('onPrinting');
      }
      if (this.index === this.text.length) {
        clearTimeout(this.timer);
        this.showCursor = false;
      }
    }
  }
};
exports.default = _default;