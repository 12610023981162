"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _SelectViewType = _interopRequireDefault(require("./SelectViewType"));
var _num_formatter = require("@/utils/num_formatter");
var _trade = require("@/api/trade");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeKanban',
  components: {
    MgDraggable: _MgDraggable.default,
    SelectViewType: _SelectViewType.default
  },
  props: {
    list: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    activeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLeftArrowShow: false,
      isRightArrowShow: false,
      parseFormatNum: _num_formatter.parseFormatNum,
      localList: [],
      inputViewName: '',
      newViewType: 0,
      // 新建视图的视图类型
      editViewType: 0,
      copiedItem: null,
      // 被复制的视图对象
      errorTip: '' // 新建名称校验
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    isCreateButtonVisible() {
      return this.list.length < 50;
    }
  },
  watch: {
    list: {
      handler: function (val) {
        const newArr = val.map(item => {
          return {
            ...item,
            isNew: false,
            isEditing: false
          };
        });
        this.localList = newArr;
      },
      deep: true
    }
  },
  updated() {
    this.$nextTick(() => {
      this.calcArrowVisibility();
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.calcArrowVisibility);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcArrowVisibility);
  },
  methods: {
    isShowSpilit(activeId, index) {
      const activeIndex = this.localList.findIndex(item => item.id === activeId);
      return !(activeIndex === index || activeIndex === index + 1);
    },
    handleClick(item) {
      this.$emit('kanbanChange', item);
      this.postViewVisited(item.id);
    },
    postViewVisited(viewId) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId,
        action: 'in_view'
      };
      (0, _trade.postViewVisited)(data);
    },
    handleCreateKanbanOpen(item) {
      var _this$$refs$selectVie, _this$localList;
      (_this$$refs$selectVie = this.$refs.selectViewTypePop) === null || _this$$refs$selectVie === void 0 ? void 0 : _this$$refs$selectVie.doClose();
      const order = ((_this$localList = this.localList) === null || _this$localList === void 0 ? void 0 : _this$localList.length) + 1;
      this.inputViewName = item !== null && item !== void 0 && item.name ? (item === null || item === void 0 ? void 0 : item.name) + '的副本' + order : '视图' + order;
      const filter_config = (item === null || item === void 0 ? void 0 : item.filter_config) || {};
      const type = (item === null || item === void 0 ? void 0 : item.type) || this.newViewType;
      this.localList.push({
        isNew: true,
        id: 0,
        filter_config,
        type
      });
      this.$nextTick(() => {
        this.$refs.editViewInput[0].focus();
      });
    },
    handleSubmit(item) {
      item.isNew ? this.createTradeView(item) : this.renameTradeView(item);
    },
    handleCopyView(item) {
      this.copiedItem = item;
      this.handleCreateKanbanOpen(item);
    },
    handleRename(item) {
      item.isEditing = true;
      this.inputViewName = item.name;
      this.$nextTick(() => {
        this.$refs.editViewInput[0].focus();
      });
    },
    handleDelete(item) {
      this.$confirm(`确定删除“${item.name}”吗？`, '删除视图', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        this.$emit('deleteKanban', item.id);
      });
    },
    handleCommand(command, item) {
      switch (command) {
        case 'edit':
          console.log(item, 'item');
          this.handleRename(item);
          break;
        case 'copy':
          this.handleCopyView(item, true);
          break;
        case 'delete':
          this.handleDelete(item);
          break;
        default:
          break;
      }
    },
    handleArrowClick(isRight) {
      var _this$$refs$kanbanWra;
      const kanbanWrap = (_this$$refs$kanbanWra = this.$refs.kanbanWrap) === null || _this$$refs$kanbanWra === void 0 ? void 0 : _this$$refs$kanbanWra.$el.children[0];
      const kanban = kanbanWrap.children[0];
      const step = kanbanWrap.offsetWidth - kanban.offsetWidth - 64;
      const distance = isRight ? step : -step;
      kanbanWrap.scrollBy({
        left: distance,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.calcArrowVisibility();
      }, 500);
    },
    ScrollToEnd() {
      var _this$$refs$kanbanWra2;
      const kanbanWrap = (_this$$refs$kanbanWra2 = this.$refs.kanbanWrap) === null || _this$$refs$kanbanWra2 === void 0 ? void 0 : _this$$refs$kanbanWra2.$el.children[0];
      kanbanWrap === null || kanbanWrap === void 0 ? void 0 : kanbanWrap.scrollBy({
        left: kanbanWrap.scrollWidth,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.calcArrowVisibility();
      }, 500);
    },
    // 计算箭头是否显示
    calcArrowVisibility() {
      var _this$$refs$kanbanWra3;
      const kanbanWrap = (_this$$refs$kanbanWra3 = this.$refs.kanbanWrap) === null || _this$$refs$kanbanWra3 === void 0 ? void 0 : _this$$refs$kanbanWra3.$el.children[0];
      if (!kanbanWrap) return;
      const maxScrollDistance = kanbanWrap.scrollWidth - kanbanWrap.offsetWidth;
      const nowScrollDistance = kanbanWrap.scrollLeft;
      this.isLeftArrowShow = nowScrollDistance > 0;
      this.isRightArrowShow = nowScrollDistance < maxScrollDistance - 3; // 减3像素是为了提高容错性，有时差1像素右侧箭头还在，影响操作按钮
    },

    async dragEnd(event) {
      const {
        newIndex
      } = event;
      await (0, _trade.moveViewPosition)({
        filter_id: this.localList[newIndex].id,
        des_order: newIndex + 1,
        workspace_id: this.workspaceInfo.id
      });
      this.$emit('changeTradeViewListPosition', this.localList);
    },
    validateName() {
      if (!this.inputViewName) {
        this.errorTip = '视图名称要求1-20个字';
        this.$message.warning(this.errorTip);
        return;
      }
      const hasSameName = this.localList.some(item => item.name === this.inputViewName);
      if (hasSameName) {
        this.errorTip = '此名称与已有名称重复';
        this.$message.warning(this.errorTip);
        return;
      }
      this.errorTip = '';
    },
    async createTradeView(item) {
      this.validateName();
      if (this.errorTip) return;
      let data = {};
      if (this.copiedItem) {
        data = {
          ...this.copiedItem,
          name: this.inputViewName
        };
        const {
          user_ids
        } = this.copiedItem.filter_config;
        // 复制视图时需要把用户筛选信息处理成后端需要格式(另存为新视图时不需要)
        if (Array.isArray(user_ids)) {
          data.filter_config.user_ids = this.handleFilterConfig(user_ids);
        }
      } else {
        data = {
          workspace_id: this.workspaceInfo.id,
          name: this.inputViewName,
          type: this.newViewType,
          filter_config: {},
          sort_conf: [],
          head_conf: []
        };
      }
      const res = await (0, _trade.createTradeView)(data);
      if (res.code === 20000) {
        this.localList[this.localList.length - 1] = {
          ...res.results,
          isNew: false
        };
        this.newViewType = 0;
        this.copiedItem = null;
        this.$emit('createNewKanban');
      }
    },
    async renameTradeView(item) {
      if (item.name === this.inputViewName) {
        // 如果还是原名退出编辑状态
        item.isEditing = false;
        return;
      }
      this.validateName();
      if (this.errorTip) return;
      const {
        workspace_id,
        id
      } = item;
      const data = {
        workspace_id: workspace_id,
        filter_id: id,
        name: this.inputViewName
      };
      const res = await (0, _trade.updateTradeView)(data);
      if (res.code === 20000) {
        this.localList = this.localList.map(item => {
          if (item.id === id) {
            item.name = this.inputViewName;
            item.isEditing = false;
          }
          return item;
        });
      }
    },
    async changeViewType(typeValue, item) {
      const {
        workspace_id,
        id
      } = item;
      const data = {
        workspace_id: workspace_id,
        filter_id: id,
        type: typeValue
      };
      const res = await (0, _trade.changeViewType)(data);
      if (res.code === 20000) {
        item.type = typeValue;
        this.$refs.changeViewTypePop[0].doClose();
      }
    },
    // 复制视图时需要把用户筛选信息处理成后端需要格式
    handleFilterConfig(data) {
      const depList = [];
      const userList = [];
      data.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    }
  }
};
exports.default = _default;