"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LazyTree = _interopRequireDefault(require("./components/LazyTree"));
var _user = require("@/api/user");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentAndMemberSelectTree',
  components: {
    LazyTree: _LazyTree.default
  },
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    requestFunction: {
      type: Function,
      default: _user.getDataOrgMemberTree
    },
    backfillParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      labels: '',
      isShowMainSpaceMember: false
    };
  },
  watch: {
    'backfillParams.labels'(val) {
      this.labels = val;
    }
  },
  methods: {
    clearChecked() {
      this.labels = '';
      this.$emit('clearChecked');
    },
    handleChange(tree_user_ids, labels, checkedNodesData) {
      this.labels = labels;
      this.$emit('change', tree_user_ids, this.labels, checkedNodesData);
    },
    clearData() {
      this.$refs.lazyTree.clearData();
    }
  }
};
exports.default = _default;