"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dropdown", {
    staticClass: "hit-tag-dropdown",
    attrs: {
      trigger: "click",
      placement: "bottom-start"
    },
    on: {
      command: _setup.onCommand
    }
  }, [_c("div", {
    staticClass: "hit-tag-container"
  }, [_c(_setup.HitTag, {
    attrs: {
      status: _vm.status
    }
  }), _c("i", {
    staticClass: "iconfont icon-arrow-down"
  })], 1), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l([1, 2], function (item) {
    return _c("el-dropdown-item", {
      key: item,
      attrs: {
        command: item
      }
    }, [_c(_setup.HitTag, {
      attrs: {
        status: item
      }
    })], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;