"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "ranking-files-list"
  }, [_c("header", [_c("i", {
    staticClass: "iconfont",
    class: _vm.rankingInfo.icon,
    style: {
      color: _vm.rankingInfo.color
    }
  }), _c("span", {
    staticClass: "title-name"
  }, [_vm._v(_vm._s(_vm.rankingInfo.title))])]), _vm.list && _vm.list.length > 0 ? _c("ul", _vm._l(_vm.list, function (item, index) {
    return _c("li", {
      key: index
    }, [_vm.rankingInfo.cardType === "snippet" ? _c("ranking-snippest-list-card", {
      attrs: {
        snippet: item
      }
    }) : _c("folder-card", {
      attrs: {
        folder: item
      },
      nativeOn: {
        click: function ($event) {
          return _vm.changeCurrentNode(item);
        }
      }
    })], 1);
  }), 0) : _vm._e(), !_vm.list || _vm.list.length === 0 ? _c("no-content", {
    attrs: {
      title: "暂未出炉"
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;