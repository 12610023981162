"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("h1", {
    staticClass: "back-btn"
  }, [_c("span", {
    on: {
      click: function ($event) {
        return _vm.$emit("backTo");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-left"
  }), _vm._v(" 返回 ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;