"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _commonFunc = require("@/utils/commonFunc");
var _UserTable = _interopRequireDefault(require("./UserTable.vue"));
var _TeamTable = _interopRequireDefault(require("./TeamTable.vue"));
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessPathTable',
  components: {
    UserTable: _UserTable.default,
    TeamTable: _TeamTable.default
  },
  mixins: [_ExportMixins.default],
  props: {
    teamTableLoading: {
      type: Boolean,
      default: false
    },
    teamTopPathData: {
      type: Array,
      default: () => []
    },
    keyEventNames: {
      type: Array,
      default: () => []
    },
    nodePathParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      exportTableLoading: false,
      userTableLoading: false,
      userTop1PathData: [],
      pageUserMaxStep: 0,
      teamMaxStep: 0,
      listParams: {
        page: 1,
        size: 10
      },
      total: 0
    };
  },
  watch: {
    teamTopPathData: {
      handler(val) {
        if (val.length) {
          this.$nextTick(() => {
            const stepArray = this.teamTopPathData.map(item => {
              return item.path.length;
            });
            this.teamMaxStep = Math.max(...stepArray);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 获取个人top1路径
    async getUsersTop1Path(val) {
      this.userTableLoading = true;
      const params = {
        ...this.nodePathParams,
        ...this.listParams,
        type: val
      };
      const res = await (0, _businessIntelligence.getUsersTop1Path)(params);
      if (res.code === 200) {
        this.userTop1PathData = res.results.data;
        this.total = res.results.count;
        this.pageUserMaxStep = res.results.user_max_level;
      }
      this.userTableLoading = false;
    },
    // 导出相关代码
    getKeyEventProportion(val) {
      if (val > 0.001 || val === 0) {
        return (val * 100).toFixed(2) + '%';
      } else if (val > 0.000001) {
        return (val * 100).toFixed(4) + '%';
      } else {
        return 0.0001 + '%';
      }
    },
    // 导出表格数据
    async exportTableCsv() {
      this.exportTableLoading = true;
      // const tableData = await this.pollingRequest();
      // const exportUserTop1PathData = tableData.map(
      //   ({ avatar, ...item }) => item
      // );
      // const ExportData = [...this.teamTopPathData, ...exportUserTop1PathData];
      const ExportData = [...this.teamTopPathData];
      const data = [this.handleTableHead(), ...this.handleExportData(ExportData)];
      const {
        activeTabName,
        departmentName,
        startDate,
        endDate
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${departmentName}_${startDate}至${endDate}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(data), `${fileName}.csv`);
      this.exportTableLoading = false;
    },
    // async pollingRequest() {
    //   const size = 100;
    //   const count = Math.ceil(this.total / size);
    //   const promiseArr = [];
    //   for (let page = 1; page <= count; page++) {
    //     const params = {
    //       ...this.nodePathParams,
    //       page,
    //       size,
    //       type: 'page'
    //     };
    //     promiseArr.push(getUsersTop1Path(params));
    //   }
    //   const res = await Promise.all(promiseArr);
    //   return res.map((item) => item.results.data).flat();
    // },
    // 处理导出数据的表头
    handleTableHead() {
      // 导出数据暂且写为 13 步
      // const step = 13;
      const step = this.teamMaxStep;
      const exportTableHead = ['名称'];
      for (let i = 0; i < step; i++) {
        exportTableHead.push(`第${(0, _commonFunc.toChineseNumeral)(i + 1)}步`, '占比');
      }
      return exportTableHead;
    },
    // 处理团队top和预设和个人top1的table数据为二维数组后导出
    handleExportData(val) {
      const pathAnalysisData = JSON.parse(JSON.stringify(val));
      const businessPathExportData = pathAnalysisData.map(item => {
        const {
          name,
          path
        } = item;
        const pathAnalysisDataValue = [name, path];
        pathAnalysisDataValue.forEach((item, index) => {
          if (Array.isArray(item)) {
            item.forEach((child, index, arr) => {
              arr[index] = [child.name, this.getKeyEventProportion(child.value)];
            });
          }
        });
        return pathAnalysisDataValue;
      });
      // // 合并数组的第一二项，第一项为数组，第二项为名字
      businessPathExportData.forEach((child, index, arr) => {
        const step = Array.isArray(child[0]) ? child[0] : child[1];
        const name = Array.isArray(child[0]) ? child[1] : child[0];
        arr[index] = [name, ...step.flat()];
      });
      return businessPathExportData;
    },
    handleSizeChange(val) {
      this.listParams.size = val;
      this.currPage = 1;
      this.getUsersTop1Path('page');
    },
    handleCurrentChange(val) {
      this.listParams.page = val;
      this.getUsersTop1Path('page');
    }
  }
};
exports.default = _default;