"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _IgnoreQuestionDrawer = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgePointMiningEngine/components/IgnoreQuestionDrawer.vue"));
var _FiltersGroup = _interopRequireDefault(require("./components/FiltersGroup.vue"));
var _QuestionItem = _interopRequireDefault(require("./components/QuestionItem.vue"));
var _TrendAnalysis = _interopRequireDefault(require("./components/TrendAnalysis.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _AnswerList = _interopRequireDefault(require("./components/AnswerList.vue"));
var _GuideDialog = _interopRequireDefault(require("@/components/GuideDialog"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _AIAssistantChatDrawer = _interopRequireDefault(require("./components/AIAssistantChatDrawer.vue"));
var _CreateQuestionDialog = _interopRequireDefault(require("./components/CreateQuestionDialog"));
var _SubQuestionList = _interopRequireDefault(require("./components/SubQuestionList"));
var _ChatBtn = _interopRequireDefault(require("@/components/ChatBtn"));
var _MoveFreqQuestionDialog = _interopRequireDefault(require("./components/MoveFreqQuestionDialog.vue"));
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FAQ',
  components: {
    FiltersGroup: _FiltersGroup.default,
    IgnoreQuestionDrawer: _IgnoreQuestionDrawer.default,
    QuestionItem: _QuestionItem.default,
    NoData: _NoData.default,
    TrendAnalysis: _TrendAnalysis.default,
    AnswerList: _AnswerList.default,
    GuideDialog: _GuideDialog.default,
    ChartTemplate: _ChartTemplate.default,
    AIAssistantChatDrawer: _AIAssistantChatDrawer.default,
    ChatBtn: _ChatBtn.default,
    CreateQuestionDialog: _CreateQuestionDialog.default,
    SubQuestionList: _SubQuestionList.default,
    MoveFreqQuestionDialog: _MoveFreqQuestionDialog.default
  },
  data() {
    return {
      ignoreQuestionDrawerVisible: false,
      showDetail: false,
      detailActiveTab: '',
      guideDialogVisible: false,
      queryParams: {
        status: 'all',
        page: 1,
        size: 10
      },
      questionsList: [],
      questionsTotal: 0,
      getQuestionsLoading: false,
      currentQuestion: {},
      getChartDataLoading: false,
      chartConfig: {},
      chartSelectionIndex: null,
      chatDrawerVisible: false,
      createQuestionDialogVisible: false,
      editQuestionInfo: {},
      // 编辑的问题的信息
      isEdit: false,
      moveFreqQuestionDialogVisible: false,
      chatPromptConfig: {
        guideList: ['总结客户高频问题类型', '总结客户有哪些核心需求', '总结客户有哪些画像特征'],
        shortcutsList: [{
          label: '问题分类',
          value: '总结客户高频问题类型'
        }, {
          label: '需求总结',
          value: '总结客户有哪些核心需求'
        }, {
          label: '画像总结',
          value: '总结客户有哪些画像特征'
        }],
        guideTips: '注意：AI助手将根据左侧动态筛选出的问题清单进行回答~'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission', 'orgId']),
    paginationConfig() {
      return {
        layout: (this.showDetail ? '' : 'total, ') + 'prev, pager, next, sizes',
        count: this.showDetail ? 5 : 7
      };
    },
    // 组合趋势分析请求所需参数
    formatParams() {
      const {
        create_at,
        begin_time,
        user_ids,
        tree_ids,
        custom_filters,
        status,
        filter
      } = this.queryParams;
      return {
        create_at,
        begin_time,
        user_ids,
        tree_ids,
        custom_filters,
        workspace_id: this.workspaceInfo.id,
        status,
        filter
      };
    },
    isNoData() {
      if (this.queryParams.type === 'list') {
        return !this.getQuestionsLoading && !this.questionsList.length;
      } else {
        var _this$chartConfig, _this$chartConfig$ser, _this$chartConfig$ser2, _this$chartConfig$ser3;
        return !this.getChartDataLoading && !((_this$chartConfig = this.chartConfig) !== null && _this$chartConfig !== void 0 && (_this$chartConfig$ser = _this$chartConfig.series) !== null && _this$chartConfig$ser !== void 0 && (_this$chartConfig$ser2 = _this$chartConfig$ser[0]) !== null && _this$chartConfig$ser2 !== void 0 && (_this$chartConfig$ser3 = _this$chartConfig$ser2.data) !== null && _this$chartConfig$ser3 !== void 0 && _this$chartConfig$ser3.length);
      }
    },
    hasSubQuestion() {
      return [222, 269, 1].includes(this.orgId);
    },
    tabs() {
      const tabs = {
        'trend-analysis': '趋势分析',
        'answer-list': '销售回答'
      };
      if (this.hasSubQuestion) {
        tabs['sub-question'] = '细分问题';
      }
      return tabs;
    },
    chatRequestConfig() {
      return {
        getQuestionParams: {
          entity_type: 1,
          params: {
            filters: this.queryParams
          }
        },
        getHistoryParams: {
          entity_type: 1,
          space_id: this.workspaceInfo.id
        }
      };
    }
    // tabsKeys() {
    //   return Object.keys(this.tabs);
    // },
    // tabContainerStyle() {
    //   const index = this.tabsKeys.findIndex(
    //     (item) => item === this.detailActiveTab
    //   );
    //   return `transform: translateX(${
    //     -(100 / this.tabsKeys.length) * index
    //   }%);width: ${100 * this.tabsKeys.length}%;`;
    // },
    // tabItemStyle() {
    //   return `width: ${100 / this.tabsKeys.length}%;`;
    // }
  },

  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.clearDetail();
        this.queryParams.page = 1;
      }
    },
    ignoreQuestionDrawerVisible(val) {
      !val && this.filterChange({
        page: 1
      });
    },
    showDetail(val) {
      if (!val) {
        this.currentQuestion = {};
      }
      if (this.queryParams.type === 'list') return;
      this.$nextTick(() => {
        const timeId = setInterval(() => {
          var _this$$refs$pieChart, _this$$refs$pieChart$;
          (_this$$refs$pieChart = this.$refs['pie-chart']) === null || _this$$refs$pieChart === void 0 ? void 0 : (_this$$refs$pieChart$ = _this$$refs$pieChart.chart) === null || _this$$refs$pieChart$ === void 0 ? void 0 : _this$$refs$pieChart$.resize();
        }, 50);
        setTimeout(() => {
          clearTimeout(timeId);
        }, 300);
      });
    },
    'queryParams.type': {
      handler: function (val) {
        if (val === 'chart') {
          this.$nextTick(() => {
            var _this$$refs$pieChart2;
            const chart = (_this$$refs$pieChart2 = this.$refs['pie-chart']) === null || _this$$refs$pieChart2 === void 0 ? void 0 : _this$$refs$pieChart2.chart;
            if (!chart) return;
            chart.on('click', params => {
              if (params.data.id === -1) return;
              chart.dispatchAction({
                type: 'highlight',
                dataIndex: params.dataIndex
              });
              if (this.chartSelectionIndex !== null && params.dataIndex !== this.chartSelectionIndex) {
                chart.dispatchAction({
                  type: 'downplay',
                  dataIndex: this.chartSelectionIndex
                });
              }
              this.chartSelectionIndex = params.dataIndex;
              this.detailActiveTab = 'trend-analysis';
              this.toggleCurrentQuestion(params.data);
            });
          });
        }
      }
    }
  },
  methods: {
    createQuestion() {
      this.createQuestionDialogVisible = true;
      this.isEdit = false;
    },
    getFAQIgnoreQuestionList: _businessIntelligence.getFAQIgnoreQuestionList,
    editFAQIgnoreStatus: _businessIntelligence.editFAQIgnoreStatus,
    toggleCurrentQuestion(val) {
      var _this$$refs$rightTab;
      this.currentQuestion = val;
      this.showDetail = true;
      // 切换问题将回答列表恢复到顶部
      (_this$$refs$rightTab = this.$refs['right-tab-container']) === null || _this$$refs$rightTab === void 0 ? void 0 : _this$$refs$rightTab.scrollTo({
        top: 0
      });
      if (!this.detailActiveTab) {
        this.detailActiveTab = 'trend-analysis';
      }
    },
    clearDetail() {
      this.showDetail = false;
      this.currentQuestion = {};
    },
    handleOperation(type, item) {
      this.editQuestionInfo = item;
      switch (type) {
        case 'edit':
          if (item.name === '其他') {
            this.$message.warning('当前问题为系统固有问题，不可编辑');
            return;
          }
          this.createQuestionDialogVisible = true;
          this.isEdit = true;
          break;
        // 细分问题
        case 'subdivide':
          this.subdivideQuestion(item);
          break;
        case 'merge':
          if (item.name === '其他') {
            this.$message.warning('当前问题为系统固有问题，不可并入至其他问题');
            return;
          }
          this.mergeQuestion(item);
          break;
        case 'ignore':
          this.ignoreQuestion(item);
          break;
        case 'delete':
          if (item.name === '其他') {
            this.$message.warning('当前问题为系统固有问题，不可删除');
            return;
          }
          this.deleteQuestion(item);
          break;
        default:
          break;
      }
    },
    subdivideQuestion(item) {
      this.currentQuestion = item;
      this.showDetail = true;
      this.detailActiveTab = 'sub-question';
    },
    mergeQuestion(item) {
      this.currentQuestion = item;
      this.moveFreqQuestionDialogVisible = true;
    },
    async handleMoveFreqQuestion(target_q_id) {
      console.log('handleMoveFreqQuestion', target_q_id);
      const res = await (0, _businessIntelligence.mergeQuestion)({
        target_q_id,
        q_id: this.currentQuestion.id,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('并入成功，问题出现频率增加，排序更新');
        this.moveFreqQuestionDialogVisible = false;
        this.refreshCurrentPage();
      }
    },
    deleteQuestion(item) {
      this.$confirm('问题删除后将无法恢复，是否删除', '删除问题', {
        confirmButtonText: '删除',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteFreqQuestion)({
          q_id: item.id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.refreshCurrentPage();
        }
      }).catch(() => {});
    },
    async ignoreQuestion(val) {
      if (!localStorage.getItem('FAQIgnoreQuestionGuideDialogHidden')) {
        localStorage.setItem('FAQIgnoreQuestionGuideDialogHidden', true);
        this.guideDialogVisible = true;
      }
      if (val.id === this.currentQuestion.id) this.clearDetail();
      const res = await (0, _businessIntelligence.editFAQIgnoreStatus)({
        id: val.id,
        workspace_id: this.workspaceInfo.id,
        is_omit: true
      });
      if (res.code === 20000) {
        this.$message.success('操作成功');
        this.refreshCurrentPage();
      }
    },
    refreshCurrentPage() {
      let page = this.queryParams.page;
      // 忽略/删除操作：当前页只有一条数据且不是第一页，请求上一页的数据，否则请求当前页
      if (this.questionsList.length === 1 && this.queryParams.page !== 1) {
        page = this.queryParams.page - 1;
      }
      this.filterChange({
        page
      });
    },
    filterChange(val) {
      this.queryParams = {
        ...this.queryParams,
        ...val
      };
      this.queryParams.q_type = Number(this.queryParams.q_type);
      if (this.queryParams.type === 'chart') {
        // 请求图表数据
        this.getChartData();
      } else {
        // 请求列表数据
        this.getQuestionsList();
      }
    },
    async getQuestionsList() {
      this.getQuestionsLoading = true;
      const res = await (0, _businessIntelligence.getFAQQuestionList)({
        ...this.queryParams,
        include_omit: false
      }).finally(() => {
        this.getQuestionsLoading = false;
      });
      if (res.code === 20000) {
        const {
          data,
          count,
          all_count
        } = res.results;
        this.questionsList = data.map(item => ({
          ...item,
          all_count: all_count
        }));
        this.questionsTotal = count;
      }
    },
    async getChartData() {
      this.getChartDataLoading = true;
      const res = await (0, _businessIntelligence.getFAQPieChartData)(this.queryParams).finally(() => {
        this.getChartDataLoading = false;
      });
      if (res.code === 20000) {
        this.chartConfig = this.setChartConfig(res.results);
      }
    },
    setChartConfig(_ref) {
      let {
        data,
        conversation_count,
        question_count
      } = _ref;
      return {
        title: {
          text: `基于${conversation_count}次会话，共捕捉到${question_count}次问答`,
          bottom: 0,
          left: 'center',
          textStyle: {
            fontSize: 14,
            fontWeight: '400',
            color: '#2B323B'
          }
        },
        label: {
          fontSize: 12,
          color: '#2B323B',
          lineHeight: 16,
          formatter: value => `${value.name}\n${value.percent}%`
        },
        legend: {
          bottom: 40,
          textStyle: {
            fontSize: 14,
            color: '#576575'
          }
        },
        color: ['#3393FF', '#5CD6A3', '#E54D52', '#FFB433', '#F43F7C', '#00B7B8', '#F28740', '#9A7DE8', '#FF5F33', '#FFE500', '#99C9FF', '#ADEBD1', '#F2A6A8', '#FFDA99', '#F99FBD', '#C4F8F8', '#F8C3A0', '#DDD4F7', '#FFAF99', '#FFFACC', '#8A98A8'],
        tooltip: {
          appendToBody: true,
          formatter: value => this.setTooltip(value)
        },
        series: [{
          type: 'pie',
          top: 50,
          bottom: 200,
          minShowLabelAngle: 5,
          data: data.map(item => ({
            ...item,
            value: item.percent
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 15,
              shadowColor: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }]
      };
    },
    setTooltip(_ref2) {
      let {
        data: {
          count
        },
        marker,
        name,
        percent
      } = _ref2;
      const boldFontStyle = "style='color: #2B323B;font-weight: 600;'";
      return `<p ${boldFontStyle}>${marker} ${name}</p>
      <p>问题数量：<span ${boldFontStyle}>${count}</span></p>
      <p>问题占比：<span ${boldFontStyle}>${percent}%</span></p>`;
    },
    getQuestionsCategoriesList() {
      return [{
        label: '全部',
        type: 'all'
      }, {
        label: '热度上升',
        type: 'up'
      }, {
        label: '热度下降',
        type: 'down'
      }, {
        label: '本周新增',
        type: 'new'
      }];
    },
    async changeChatDrawerVisible() {
      await this.getCustomAiQuestionList();
      this.chatDrawerVisible = !this.chatDrawerVisible;
      if (this.chatDrawerVisible) {
        window.collectEvent('high_frequency_questions_ai_assistant', {
          page_path: location.pathname
        });
      }
    },
    async getCustomAiQuestionList() {
      const res = await (0, _videoDetail.getCustomAiQuestionList)({
        entity_type: 1 // 0代表会话详情页，1代表BI高频问题，2代表日程页面的
      });

      if (res.code === 20000) {
        var _res$results$qas;
        const resList = (_res$results$qas = res.results.qas) === null || _res$results$qas === void 0 ? void 0 : _res$results$qas.map(item => item.qa);
        this.chatPromptConfig.guideList.push(...resList);
      }
    }
  }
};
exports.default = _default;