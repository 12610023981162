"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header",
    class: {
      "default-style": !_vm.renderInfo.header_style.background
    },
    style: _vm.renderInfo.header_style
  }, [_c("div", {
    staticClass: "left-elements"
  }, [_c("text-joint", {
    attrs: {
      "render-info": _vm.renderInfo.left_elements
    }
  })], 1), _c("text-joint", {
    staticClass: "right-elements",
    attrs: {
      "render-info": _vm.renderInfo.right_elements
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;