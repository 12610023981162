"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    ref: "popover",
    attrs: {
      "popper-class": "image-text-tips",
      placement: "bottom",
      width: "300",
      trigger: "hover",
      disabled: _vm.disabled
    }
  }, [_c("div", {
    staticClass: "tips"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("section", {
      key: index
    }, [item.text ? _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(item.text))]) : _vm._e(), item.url ? _c("div", {
      staticClass: "image"
    }, [_c("el-image", {
      attrs: {
        src: item.url,
        "preview-src-list": _vm.srcList
      },
      on: {
        click: _vm.handleImageClick
      }
    })], 1) : _vm._e()]);
  }), 0), _c("i", {
    staticClass: "el-icon-info",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;