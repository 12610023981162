"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const complianceTableMixin = {
  methods: {
    getDate() {
      const start = this.$currentZoneToPlus8(`${this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss');
      const end = this.$currentZoneToPlus8(`${this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss');
      return [start, end];
    },
    controlIcon(type) {
      switch (type) {
        case 'audio':
          return 'icon-phone-outline-fill';
        case 'video':
          return 'icon-video-camera-solid-2';
        case 'doc':
          return 'icon-im-fill';
        default:
          break;
      }
    }
  }
};
var _default = complianceTableMixin;
exports.default = _default;