"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$taskConfig, _setup$taskConfig$use, _setup$taskConfig2, _setup$taskConfig2$us;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ai-coach-task-detail-container"
  }, [_c("header", [_c("div", {
    staticClass: "go-back",
    on: {
      click: _setup.goBack
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c(_setup.TaskInfo, {
    attrs: {
      "task-info": _setup.taskInfo
    }
  }), _c("div", [_c("el-select", {
    attrs: {
      "popper-append-to-body": false
    },
    on: {
      change: _setup.toggleHistory
    },
    model: {
      value: _setup.currentHistoryId,
      callback: function ($$v) {
        _setup.currentHistoryId = $$v;
      },
      expression: "currentHistoryId"
    }
  }, _vm._l(_setup.historyList, function (_ref) {
    let {
      conv_id,
      create_time,
      name,
      score,
      isMaxScore
    } = _ref;
    return _c("el-option", {
      key: conv_id,
      attrs: {
        label: name,
        value: conv_id
      }
    }, [_c("span", {
      staticClass: "option-item-left"
    }, [_c("span", [_vm._v(_vm._s(name) + "(" + _vm._s(_vm.$t("coaching.pointsHasVar", [score])) + ")")]), _c("span", [_vm._v(_vm._s(create_time))])]), isMaxScore ? _c("span", {
      staticClass: "option-item-right"
    }, [_vm._v("最高分")]) : _vm._e()]);
  }), 1), !((_setup$taskConfig = _setup.taskConfig) !== null && _setup$taskConfig !== void 0 && (_setup$taskConfig$use = _setup$taskConfig.userInfo) !== null && _setup$taskConfig$use !== void 0 && _setup$taskConfig$use.id) || ((_setup$taskConfig2 = _setup.taskConfig) === null || _setup$taskConfig2 === void 0 ? void 0 : (_setup$taskConfig2$us = _setup$taskConfig2.userInfo) === null || _setup$taskConfig2$us === void 0 ? void 0 : _setup$taskConfig2$us.id) === _setup.userId ? [_setup.currentIsComplete ? _c("el-button", {
    staticClass: "start-practice-btn",
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.restart
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.开始第x次练习", [_setup.historyList.length + 1])) + " ")]) : _c("el-button", {
    staticClass: "start-practice-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.continuePractice
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.继续第x次练习", [_setup.historyList.length])) + " ")])] : _vm._e()], 2)], 1), _c("article", [_c("div", {
    staticClass: "session-record"
  }, [_c("el-tabs", {
    model: {
      value: _setup.activeName,
      callback: function ($$v) {
        _setup.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_setup.isShowKeyScriptTab ? _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("coaching.keyScripts"),
      name: "keyScriptReview"
    }
  }, [_setup.commentsList.length ? [_setup.taskConfig.type === 7 ? _c(_setup.KeyScriptReviewNoScene, {
    attrs: {
      "script-list": _setup.scriptList
    }
  }) : _c(_setup.KeyScriptReview, {
    attrs: {
      "scene-list": _setup.sceneList
    }
  })] : [_c("div", {
    staticClass: "generating script-generating"
  }, [_vm._v(" 生成中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])])]], 2) : _vm._e(), _c("el-tab-pane", {
    attrs: {
      label: "对话记录",
      name: "dialogueList"
    }
  }, [_setup.commentsList.length ? [_c(_setup.DialogueList, {
    attrs: {
      "conversation-list": _setup.conversationList,
      narration: _setup.narration,
      "customer-avatar": _setup.customerAvatar
    }
  })] : [_c("div", {
    staticClass: "generating script-generating"
  }, [_vm._v(" 生成中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])])]], 2)], 1)], 1), _c("div", {
    staticClass: "task-evaluation"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.taskScore")) + "：")]), _c("div", {
    staticClass: "score"
  }, [!_setup.commentsList.length ? _c("div", {
    staticClass: "generating"
  }, [_vm._v(" 评分中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : [_vm._v(" " + _vm._s(_setup.score === -1 ? 0 : _setup.score)), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.points")))])]], 2), _c("div", {
    staticClass: "analysis-container"
  }, [_c("div", {
    staticClass: "comments"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-ai-assistant-fill"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.aIReview")) + "：")])]), _c("ul", {
    staticClass: "comments-list"
  }, [!_setup.commentsList.length ? [_vm._m(0)] : _vm._l(_setup.commentsList, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item || _vm.$t("coaching.noCommentsAvailable")))])]);
  })], 2)]), _setup.abilityAnalysis.length ? _c("div", {
    staticClass: "comments"
  }, [_vm._m(1), _c("ul", {
    staticClass: "comments-list"
  }, [!_setup.commentsList.length ? [_vm._m(2)] : _vm._l(_setup.abilityAnalysis, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item || "暂无分析"))])]);
  })], 2)]) : _vm._e()])])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generating"
  }, [_vm._v(" 点评中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-analysis-3-fill"
  }), _c("span", [_vm._v("能力分析：")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generating"
  }, [_vm._v(" 评分中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;