"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _ExportTableButton = _interopRequireDefault(require("@/views/sop/ScoreAnalysis/components/ExportTableButton.vue"));
var _MemberTable = _interopRequireDefault(require("./components/MemberTable"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _compliance = require("@/api/compliance");
var _useTableOperate = require("../hooks/use-table-operate");
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const params = (0, _vue.ref)({
      page: 1,
      size: 10,
      user_ids: {}
    });
    const {
      sizeChange,
      pageChange,
      memberChange,
      getTableList,
      tableInfo,
      loading,
      workspaceId
    } = (0, _useTableOperate.useTableOperate)(_compliance.getMyComplianceMember, params);
    getTableList();
    const formatComplianceCount = (0, _vue.computed)(() => {
      return tableInfo.value.total > 999 ? '999+' : tableInfo.value.total;
    });
    const exportTableRef = (0, _vue.ref)(null);
    const exportTableData = () => {
      exportTableRef.value.exportTable({
        workspace_id: workspaceId.value,
        user_ids: params.value.user_ids
      });
    };
    const goCompliance = row => {
      const routeData = router.resolve({
        path: _routeMap.ROUTES.conversation,
        query: {
          compliance_id: row.user_id,
          name: row.user_name
        }
      });
      window.open(routeData.href, '_blank');
    };
    const startGoCompliance = () => {
      goCompliance(tableInfo.value.data[0]);
    };
    return {
      __sfc: true,
      router,
      props,
      params,
      sizeChange,
      pageChange,
      memberChange,
      getTableList,
      tableInfo,
      loading,
      workspaceId,
      formatComplianceCount,
      exportTableRef,
      exportTableData,
      goCompliance,
      startGoCompliance,
      NoData: _NoData.default,
      ExportTableButton: _ExportTableButton.default,
      MemberTable: _MemberTable.default,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      exportMyComplianceMember: _compliance.exportMyComplianceMember
    };
  }
};
exports.default = _default;