"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "learn-card"
  }, _vm._l(_vm.localCardItem.scene_reply_list, function (item) {
    return _c("div", {
      key: item.scene_id,
      staticClass: "sentence-container"
    }, [item.scene_content ? _c("tricks-scene", {
      attrs: {
        "display-only": "",
        value: item.scene_content
      }
    }) : _vm._e(), item.reply_list && item.reply_list.length ? _c("div", {
      staticClass: "tricks"
    }, _vm._l(item.reply_list, function (reply) {
      return _c("div", {
        key: reply.reply_id,
        staticClass: "trick-item"
      }, [_c("p", [_vm._v("（" + _vm._s(reply.order) + "）")]), _c("p", {
        staticClass: "relpy-content"
      }, [_vm._v(_vm._s(reply.reply_content))])]);
    }), 0) : _vm._e()], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;