"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "parent-task-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "parent-task-table-content",
    attrs: {
      data: _vm.data,
      height: "calc(100vh - 244px)"
    },
    on: {
      "sort-change": _setup.handleSortChange
    }
  }, _vm._l(_vm.head, function (item) {
    return _c("el-table-column", {
      key: item.field_name,
      attrs: {
        label: item.name,
        fixed: item.direction || false,
        sortable: item.sortable || false,
        prop: item.field_name,
        "min-width": item.width || 180
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [item.field_name === "average_score" ? [_c("span", {
            staticClass: "average-score"
          }, [_vm._v(_vm._s(item.name) + " "), _c("el-tooltip", {
            attrs: {
              content: _setup.t("coaching.练习平均分=学员所有已完成课程得分的平均分"),
              effect: "dark",
              placement: "top"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-info-1"
          })])], 1)] : item.is_fixed ? [_c("div", {
            staticClass: "fixed-head",
            on: {
              click: function ($event) {
                return _setup.goChildrenDetail(item);
              }
            }
          }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("i", {
            staticClass: "iconfont icon-top-right"
          })])] : [_vm._v(_vm._s(item.name))]];
        },
        proxy: true
      }, item.field_name === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("MgvAvatarName", {
            attrs: {
              src: row.user.avatar,
              name: row.user.name,
              size: 28
            }
          }), _c("span", {
            staticClass: "avatar-name-text"
          }, [_vm._v(_vm._s(row.user.name))])];
        }
      } : ["total_score", "average_score"].includes(item.field_name) ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row[item.field_name] !== -1 ? _c("span", [_vm._v(_vm._s(_setup.t("coaching.pointsHasVar", [row[item.field_name]])))]) : _c("span", {
            staticClass: "no-score"
          }, [_vm._v(_vm._s(_setup.t("coaching.noScoreYet")))])];
        }
      } : item.field_name === "status" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row.status === 2 || !row.is_access ? _c("span", [_vm._v("-")]) : row.is_complete ? _c("span", {
            staticClass: "status-complete"
          }, [_vm._v(_vm._s(_setup.t("coaching.completed")))]) : _c("span", {
            staticClass: "status-no-complete"
          }, [_vm._v(_vm._s(_setup.t("coaching.notCompleted")))])];
        }
      } : {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [row[item.field_name].score !== -1 ? _c("MgvButton", {
            attrs: {
              type: "text"
            },
            on: {
              click: function ($event) {
                return _setup.openTaskDetailDrawer(row[item.field_name], row);
              }
            }
          }, [_vm._v(_vm._s(_setup.t("coaching.pointsHasVar", [row[item.field_name].score])))]) : _c("span", {
            staticClass: "no-score"
          }, [_vm._v(_vm._s(_setup.t("coaching.noScoreYet")))])];
        }
      }], null, true)
    });
  }), 1), _c(_setup.TaskResultDetailDrawer, {
    attrs: {
      visible: _setup.taskResultDetailDrawerVisible,
      "current-task-info": _setup.currentTaskInfo,
      "task-id": _setup.currentTaskId
    },
    on: {
      "update:visible": function ($event) {
        _setup.taskResultDetailDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;