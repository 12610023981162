"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'RuleBasicBox',
  inject: {
    isReadOnly: {
      default: false
    }
  },
  props: {
    ruleGroupIndex: {
      type: Number,
      default: 0
    },
    contextItem: {
      type: Object,
      default: () => ({})
    },
    contextIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      speakingRoleOption: [{
        label: '销售',
        value: 3
      }, {
        label: '客户',
        value: 2
      }, {
        label: '所有角色',
        value: 1
      }],
      typeOptions: [{
        value: 'keywords',
        label: '关键词'
      }, {
        value: 'regex',
        label: '正则表达式'
      }],
      contextTypeOptions: [{
        value: 'after',
        label: '后'
      }, {
        value: 'before',
        label: '前'
      }]
    };
  },
  computed: {
    isReadonly() {
      return this.isReadOnly;
    }
  },
  methods: {
    handleExampleItemBlur(val) {
      console.log(val, 'val');
    },
    handleDeleteIncludeItem(includeList, includeIndex) {
      includeList.splice(includeIndex, 1);
    },
    handleIncludeTypeChange(includeList, includeItem) {
      const type = includeItem.type;
      includeList.length = 1;
      this.$set(includeList[0], 'type', type);
      this.$set(includeList[0], 'value', []);
    },
    validateArrIsEmpty(rule, value, callback) {
      console.log(value, 'value');
      if (!(value && value.length)) {
        callback(new Error('请输入包含值'));
      } else {
        callback();
      }
    },
    handleDeleteContextRule() {
      this.$emit('deleteContextRule', {
        contextRuleIndex: this.contextIndex
      });
    },
    getItemTypeTooltip(type) {
      if (type === 'keywords') {
        return '检测发言角色的单条发言中是否出现了某一个或多个关键词。单个输入框的关键词间为“或”关系，不同输入框的关键词间为“且”关系';
      } else if (type === 'regex') {
        return '检测发言角色的单条发言是否符合正则表达式配置的规则内容。';
      }
    }
  }
};
exports.default = _default;