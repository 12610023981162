"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WorkspaceBadge = _interopRequireDefault(require("@/components/WorkspaceBadge"));
var _HeaderFilters = _interopRequireDefault(require("@/views/trade/components/HeaderFilters"));
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _tradeTable = _interopRequireDefault(require("@/views/trade/tradeTable"));
var _vuex = require("vuex");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
var _doubleCall = require("@/api/doubleCall");
var _trade = require("@/api/trade");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeOverviewDrawer',
  components: {
    WorkspaceBadge: _WorkspaceBadge.default,
    HeaderFilters: _HeaderFilters.default,
    DynamicPopover: _DynamicPopover.default,
    TradeTable: _tradeTable.default
  },
  provide() {
    return {
      isBaseTrade: true
    };
  },
  props: {
    tableHeightOffset: {
      type: Number,
      default: 0
    },
    backFillFilters: {
      type: Object,
      default: () => {}
    },
    onlyTradeList: {
      type: Boolean,
      default: false
    },
    fromScoreRule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      originHeaderList: [],
      headerList: [],
      list: null,
      table: null,
      // 当前页面所有的交易i
      trade_ids: [],
      listParams: {
        // 表格的分页、筛选、排序
        filter_params: {},
        page: 1,
        size: 10,
        workspace_id: 0,
        sort_by: [] //  排序如:[['speak_time','ascending'],['risk','descending']]
      },

      total: 0,
      doubleCallPermission: 0,
      customFilterObject: [],
      keyEvents: [],
      tableLoading: false,
      // 表格的loading
      tableHeight: document.body.clientHeight - 156,
      earliestTime: '',
      // 用于设置跟进动态时间线
      sortColumnList: [] // 可排序的列
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'webRoutes', 'workspaceInfo', 'timezone']),
    localBackFillFilters() {
      const localBackFillFilters = JSON.parse(JSON.stringify(this.backFillFilters));
      (0, _timezone.convertUTCplus82CurrentZone)(localBackFillFilters);
      return localBackFillFilters;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        this.tableLoading = true;
        this.getTradeFilterList();
        this.initData();
      }
    }
  },
  async created() {
    this.initData();
    await this.getTradeFilterList();
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
    if (this.has_outbound) {
      // 有外呼才可以有双呼
      this.getDoubleCallPermission();
    }
  },
  methods: {
    getTableHead(type, list) {
      this.headerList = list;
      if (!this.tableLoading) {
        this.setScaleLine();
      }
    },
    async initData() {
      this.tableLoading = true;
      this.handleViewChange();
    },
    async handleViewChange() {
      const config = JSON.parse(JSON.stringify(this.backFillFilters));
      (0, _timezone.convertUTCplus82CurrentZone)(config);
      // 处理user_ids格式，对象数组转id数组
      if (config && config.user_ids) {
        config.user_ids = this.handleUserValue(config.user_ids);
      }
      this.listParams.filter_params = config;
      this.listParams.page = 1;
      await this.getActiveViewSortInfo(0);
      await this.getActiveViewHeadList(0);
    },
    handleUserValue(initArray) {
      let depList = [];
      let userList = [];
      if (Object.prototype.toString.call(initArray) === '[object Array]') {
        initArray.forEach(item => {
          if (item.is_department) {
            depList.push(Number(item.id.replace('tree_id_', '')));
          } else {
            userList.push(item.user_id);
          }
        });
      } else {
        depList = initArray.tree_ids;
        userList = initArray.user_ids;
      }
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    async getActiveViewSortInfo(viewId) {
      const res = await (0, _trade.getViewSortInfo)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 20000) {
        this.sortColumnList = res.results.sort_field;
        const sortList = res.results.sort_field.filter(item => !!item.value);
        if (sortList.length) {
          var _this$$refs$tradeTabl;
          (_this$$refs$tradeTabl = this.$refs['tradeTable']) === null || _this$$refs$tradeTabl === void 0 ? void 0 : _this$$refs$tradeTabl.sortChange({
            prop: sortList[0].field_name,
            order: sortList[0].value
          });
        }
      }
    },
    async getActiveViewHeadList(viewId) {
      const res = await (0, _trade.getViewHeadList)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 20000) {
        this.originHeaderList = res.results.fields;
        this.handleAddColumnSortAttr();
      }
    },
    handleAddColumnSortAttr() {
      this.sortColumnList.forEach(column => {
        this.originHeaderList.forEach(head => {
          if (column.field_name === head.field_name) {
            head.sortable = 'custom';
          }
        });
      });
    },
    // 获取自定义的筛选项
    async getTradeFilterList() {
      const res = await (0, _trade.getTradeFilterList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        var _res$results$filter_l;
        const noScoreRuleFilters = (_res$results$filter_l = res.results.filter_list.find(item => item.name === '交易')) === null || _res$results$filter_l === void 0 ? void 0 : _res$results$filter_l.filters.filter(filter => filter.type !== 'score_rule');
        res.results.filter_list.find(item => item.name === '交易').filters = noScoreRuleFilters;
        this.customFilterObject = res.results.filter_list;
        this.$nextTick(() => {
          this.setTableHeight();
        });
      }
    },
    async getDoubleCallPermission() {
      const res = await (0, _doubleCall.getDoubleCallPermission)();
      if (res.code === 200) {
        this.doubleCallPermission = res.results.double_call;
      }
    },
    debounceGetList: (0, _commonFunc.debounce)(function (val) {
      this.getList();
    }, 200),
    async getList() {
      // 如果页面loading已有，则table的loading不需要了
      this.tableLoading = true;
      const listParams = (0, _commonFunc.deepClone)(this.listParams);
      listParams.workspace_id = this.workspaceInfo.id;
      listParams.time_zone = this.timezone;
      if (this.fromScoreRule) {
        listParams.from_score_rule = true;
      }
      (0, _timezone.convertParams2UTCplus8)(listParams.filter_params);
      try {
        const res = await (0, _trade.getTradeList)(listParams);
        this.tableLoading = true;
        if (res.code === 20000) {
          const {
            trade_list,
            table,
            total,
            total_amount
          } = res.results;
          [this.list, this.table, this.total, this.amount] = [trade_list, table, total, total_amount];
          this.$emit('getCount', total);
          this.trade_ids = this.list.map(item => item.trade_id);
          this.earliestTime = res.results.earliest_time;
          this.setScaleLine();
        }
      } finally {
        this.tableLoading = false;
      }
    },
    // 设置表格跟进动态时间轴刻度线 scaleLine
    setScaleLine() {
      // 把渲染负载放到下一次事件循环中
      setTimeout(() => {
        this.$nextTick(() => {
          const tradeTable = this.$refs['tradeTable'];
          if (tradeTable) {
            tradeTable.flipNum = 0;
            tradeTable.flipNumLoaded = 0;
            const scaleLine = tradeTable.$refs['scaleLine'];
            if (scaleLine) {
              scaleLine.forEach(element => {
                element.setDateList(this.earliestTime);
              });
            }
          }
        });
      }, 10);
    },
    // 跟进动态请求
    async getTradeListFollow() {
      var _this$$refs$tradeTabl2;
      if (JSON.stringify(this.listParams.filter_params) === '{}') return;
      const scaleLine = (_this$$refs$tradeTabl2 = this.$refs['tradeTable']) === null || _this$$refs$tradeTabl2 === void 0 ? void 0 : _this$$refs$tradeTabl2.$refs['scaleLine'];
      if (scaleLine) {
        const params = {
          date: scaleLine[0].dateRange,
          trade_ids: this.trade_ids
        };
        const situationRes = await (0, _trade.getTradeListFollow)(params);
        if (situationRes.code === 20000) {
          this.list.forEach((item, index) => {
            item.speak_time.unshift(...situationRes.results[index].conversation_speak_times);
          });
        }
      }
    },
    currentChange(val) {
      const maxPage = 10000 / this.listParams.size;
      this.listParams.page = this.listParams.size * val > 10000 ? maxPage : val;
      this.getList();
    },
    sizeChange(val) {
      this.listParams.size = val;
      this.listParams.page = 1;
      this.getList();
    },
    tableSortChange(prop, order) {
      if (!prop) return;
      this.listParams.sort_by = [[prop, order]];
      this.debounceGetList();
    },
    headerFiltersChange(params, filterNumber) {
      if (params.trade_name && !params.trade_name.value.length) {
        params.trade_name = undefined;
      }
      if (JSON.stringify(this.listParams.filter_params) === JSON.stringify(params)) {
        return;
      }
      this.$emit('tradeFiltersChange', params, filterNumber);
      this.listParams.page = 1;
      this.listParams.filter_params = params;
      this.debounceGetList();
    },
    setTableHeight() {
      const header = document.querySelector('.header-filter-container');
      this.tableHeight = document.body.clientHeight - (header ? 156 + header.clientHeight : 156) - this.tableHeightOffset;
    }
  }
};
exports.default = _default;