"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "compliance-team-conversation"
  }, [_c("div", {
    staticClass: "compliance-team-conversation-header"
  }, [_c("TeamConversationFilterGroup", {
    ref: "teamConversationFilterGroup",
    on: {
      changeFilter: _vm.changeFilter
    }
  }), _c("div", {
    staticClass: "btn-group"
  }, [_c("export-button", {
    attrs: {
      configurable: "",
      "export-api": _vm.exportComplianceTeamConversationApi,
      "filters-obj": _vm.filtersObj,
      disabled: !_vm.total,
      params: {
        type: "team_conv"
      }
    }
  })], 1)], 1), _c("div", {
    staticClass: "compliance-team-conversation-table"
  }, [_vm.tableData && _vm.tableData.length ? _c("ComplianceBaseTable", _vm._g(_vm._b({
    staticClass: "compliance-base-table-content",
    attrs: {
      "table-header": _vm.tableHeader,
      "table-data": _vm.tableData,
      sortable: _vm.sortable,
      "default-sort": _vm.defaultSort,
      "table-height": _vm.tableHeight,
      "is-conv-table": ""
    },
    on: {
      "sort-change": _vm.sortChange,
      currentChange: _vm.currentChange,
      sizeChange: _vm.sizeChange,
      refresh: function ($event) {
        return _vm.$emit("handleFiltersChange");
      }
    }
  }, "ComplianceBaseTable", _vm.$attrs, false), _vm.$listeners)) : _vm._e(), _vm.tableData && _vm.tableData.length ? _c("el-pagination", {
    staticClass: "pagination",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.filtersObj.page,
      "page-size": _vm.filtersObj.size,
      "page-sizes": [10, 20, 30, 40],
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  }) : _vm._e(), !(_vm.tableData && _vm.tableData.length) ? _c("NoData", {
    attrs: {
      tips: _vm.noFilter ? "还没有会话" : "暂无数据"
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;