import { render, staticRenderFns } from "./TradeTips.vue?vue&type=template&id=64b02d48&scoped=true&"
import script from "./TradeTips.vue?vue&type=script&lang=js&"
export * from "./TradeTips.vue?vue&type=script&lang=js&"
import style0 from "./TradeTips.vue?vue&type=style&index=0&id=64b02d48&lang=scss&scoped=true&"
import style1 from "./TradeTips.vue?vue&type=style&index=1&id=64b02d48&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b02d48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64b02d48')) {
      api.createRecord('64b02d48', component.options)
    } else {
      api.reload('64b02d48', component.options)
    }
    module.hot.accept("./TradeTips.vue?vue&type=template&id=64b02d48&scoped=true&", function () {
      api.rerender('64b02d48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trade/components/TradeTips.vue"
export default component.exports