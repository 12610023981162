"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "model-item-table"
  }, [_c("el-table", {
    staticClass: "model-list-table",
    attrs: {
      data: _vm.data
    }
  }, [_vm._l(_setup.headers, function (column) {
    return _c("el-table-column", {
      key: column.prop,
      attrs: {
        prop: column.prop,
        label: column.label,
        width: column.width,
        "min-width": column.minWidth
      },
      scopedSlots: _vm._u([column.prop === "version" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "column-last-version flex"
          }, [_c("span", [_vm._v("V" + _vm._s(row.version))]), row.is_online ? [_c("i", {
            staticClass: "icon iconfont icon-success"
          }), _c("span", [_vm._v("(已发布)")])] : _vm._e()], 2)];
        }
      } : column.prop === "train_status" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_vm.modelType === "ai" ? _c("div", {
            staticClass: "column-training-status flex"
          }, [row.train_status.status === 0 ? [_c("span", {
            staticClass: "dot waiting"
          }), _c("span", [_vm._v("等待训练")])] : _vm._e(), row.train_status.status === 1 ? [_c("span", {
            staticClass: "dot"
          }), _c("span", [_vm._v("训练中...")]), _c("span", {
            staticClass: "percentage"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row.train_status.progress)))]), _c("el-button", {
            attrs: {
              type: "text"
            },
            on: {
              click: _setup.refreshData
            }
          }, [_vm._v("刷新")])] : _vm._e(), row.train_status.status === 2 ? [_c("span", {
            staticClass: "dot success"
          }), _c("span", [_vm._v("训练完成")])] : _vm._e(), row.train_status.status === 3 ? [_c("span", {
            staticClass: "dot error"
          }), _c("span", [_vm._v("训练失败")]), _c("el-button", {
            attrs: {
              type: "text"
            },
            on: {
              click: function ($event) {
                return _setup.retryTrain(row);
              }
            }
          }, [_vm._v("重试")])] : _vm._e()], 2) : _c("div", [_vm._v(" ")])];
        }
      } : column.prop === "trial_status" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "column-trial-status flex"
          }, [row.trial_status.status === 0 ? [_c("span", {
            staticClass: "not-test"
          }, [_vm._v("未测试")])] : row.trial_status.status === 1 ? [_c("span", {
            staticClass: "dot"
          }), _c("span", [_vm._v("测试中...")]), _c("span", {
            staticClass: "percentage"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row.trial_status.progress)))]), _c("el-button", {
            attrs: {
              type: "text"
            },
            on: {
              click: _setup.refreshData
            }
          }, [_vm._v("刷新")])] : row.trial_status.status === 2 ? [_c("div", {
            staticClass: "trail-result-container flex",
            on: {
              click: function ($event) {
                return _setup.openTestDetailDrawer(row);
              }
            }
          }, [_c("span", {
            staticClass: "target-item"
          }, [_vm._v("精: "), _c("span", {
            staticClass: "target-value"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row.trial_status.precision)))])]), _c("span", {
            staticClass: "target-item"
          }, [_vm._v("召: "), _c("span", {
            staticClass: "target-value"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row.trial_status.recall)))])]), _c("span", {
            staticClass: "target-item"
          }, [_vm._v("F1: "), _c("span", {
            staticClass: "target-value"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row.trial_status.f1)))])]), _c("i", {
            staticClass: "iconfont icon-top-right"
          })])] : _vm._e()], 2)];
        }
      } : column.prop === "create_by" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("MgvAvatarName", {
            attrs: {
              size: 20,
              src: row.create_by.avatar,
              name: row.create_by.name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(row.create_by.name))])];
        }
      } : column.prop === "update_at" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("span", [_vm._v(_vm._s(row.update_at))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("div", {
          staticClass: "column-operation"
        }, [_c("el-tooltip", {
          attrs: {
            content: "训练完成后可测试",
            placement: "top",
            disabled: !(row.train_status.status !== 2 && _vm.modelType === "ai")
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            disabled: row.train_status.status !== 2 && _vm.modelType === "ai",
            "data-v-name": "test-model-btn"
          },
          on: {
            click: function ($event) {
              return _setup.openTestModelDialog(row);
            }
          }
        }, [_vm._v("测试")])], 1), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _setup.openTrainDetailDrawer(row);
            }
          }
        }, [_vm._v(_vm._s(["rule", "combine"].includes(_vm.modelType) ? "查看版本配置" : "查看训练记录"))]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _setup.openTestRecordsDrawer(row);
            }
          }
        }, [_vm._v("查看测试记录")])], 1)];
      }
    }])
  })], 2), _setup.trainDetailVisible ? _c(_setup.TrainDetailDrawer, {
    attrs: {
      visible: _setup.trainDetailVisible,
      "current-version": _setup.activeVersion,
      "model-id": _vm.modelId,
      "model-type": _vm.modelType
    },
    on: {
      "update:visible": function ($event) {
        _setup.trainDetailVisible = $event;
      }
    }
  }) : _vm._e(), _setup.testRecordsVisible ? _c(_setup.TestRecordsDrawer, {
    attrs: {
      visible: _setup.testRecordsVisible,
      "current-version": _setup.activeVersion,
      "model-id": _vm.modelId
    },
    on: {
      "update:visible": function ($event) {
        _setup.testRecordsVisible = $event;
      },
      reTraining: function ($event) {
        return _setup.emit("reTraining");
      }
    }
  }) : _vm._e(), _c(_setup.TestModelDialog, {
    attrs: {
      visible: _setup.TestModelVisible,
      version: _setup.activeVersion,
      "model-id": _vm.modelId
    },
    on: {
      "update:visible": function ($event) {
        _setup.TestModelVisible = $event;
      },
      submitSucceed: _setup.refreshData
    }
  }), _setup.testDetailVisible ? _c(_setup.TestDetailDrawer, {
    attrs: {
      visible: _setup.testDetailVisible,
      "model-id": _vm.modelId,
      "model-version": _setup.activeVersion,
      "praf-task-id": _setup.activePrafTaskId
    },
    on: {
      "update:visible": function ($event) {
        _setup.testDetailVisible = $event;
      },
      reTraining: function ($event) {
        return _setup.emit("reTraining");
      }
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;