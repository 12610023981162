"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'AnalysisHeaderButton',
  data() {
    return {
      isPopoverShow: false,
      list: [],
      initList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        val && this.getAnalysisShowList();
      },
      immediate: true
    }
  },
  methods: {
    async getAnalysisShowList() {
      const res = await (0, _businessIntelligence.getAnalysisShowList)({
        workspace_id: this.workspaceInfo.id
      });
      this.list = res.results;
      this.initList = (0, _commonFunc.deepClone)(res.results);
    },
    cancel() {
      this.isPopoverShow = false;
      this.list = (0, _commonFunc.deepClone)(this.initList);
    },
    async submit() {
      this.isPopoverShow = false;
      if (JSON.stringify(this.list) === JSON.stringify(this.initList)) return;
      const not_show_ids = this.list.reduce((arr, item) => arr.concat(item.is_show ? [] : [item.id]), []);
      const res = await (0, _businessIntelligence.updateAnalysisShowList)({
        not_show_ids,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.$message.success('修改成功');
        this.getAnalysisShowList();
        this.$store.dispatch('bi/get_statement_obj');
      }
    }
  }
};
exports.default = _default;