"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _EventSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/components/EventSelect"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChartConfig',
  components: {
    EventSelect: _EventSelect.default,
    ChartTemplate: _ChartTemplate.default
  },
  props: {
    chartConfigVisible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      getConfigLoading: false,
      submitLoading: false,
      radarOption: {},
      eventOptions: [],
      config: {
        ability: [],
        level: []
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    keyEventDisabledList() {
      return this.config.ability.reduce((pre, _ref) => {
        let {
          value
        } = _ref;
        return pre.concat(value.map(item => item[item.length - 1]));
      }, []);
    }
  },
  watch: {
    'config.level': {
      handler: function (val) {
        this.$nextTick(() => {
          this.$refs['configForm'].validate(valid => valid);
        });
      },
      deep: true
    }
  },
  methods: {
    changeKeyEvent(val, index) {
      this.config.ability[index].value = val;
    },
    removeConfigItem(type, index) {
      this.config[type].splice(index, 1);
      this.setRadarOptions();
    },
    addConfigItem(type) {
      this.config[type].push({
        id: Symbol(),
        name: '',
        value: type === 'ability' ? [] : 10
      });
    },
    setRadarOptions() {
      const colors = ['#3393FF'];
      this.radarOption = {
        color: colors,
        radar: [{
          indicator: this.config.ability.map(_ref2 => {
            let {
              name
            } = _ref2;
            return {
              text: name,
              max: 100
            };
          }),
          radius: 75,
          center: ['50%', '50%'],
          axisName: {
            color: '#576575'
          }
        }],
        series: {
          type: 'radar',
          data: [{
            value: this.config.ability.map(() => 70),
            areaStyle: {
              color: colors[0],
              opacity: 0.2
            },
            lineStyle: {
              color: colors[0]
            }
          }]
        }
      };
    },
    async getConfig() {
      this.getConfigLoading = true;
      try {
        const res = await (0, _businessIntelligence.getPersonalAbilityConfig)({
          userId: this.userId,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.config = res.results;
          this.config.ability.forEach(item => {
            item.id = Symbol();
          });
          this.config.level.forEach(item => {
            item.id = Symbol();
          });
          this.setRadarOptions();
          this.$nextTick(() => {
            this.config.ability.forEach((item, index) => {
              this.$refs['key-event-select'][index].defalutEvent = item.value;
            });
          });
        }
      } finally {
        this.getConfigLoading = false;
      }
    },
    handleClose() {
      this.config = this.$options.data().config;
      this.$refs['configForm'].resetFields();
      this.$emit('update:chartConfigVisible', false);
    },
    submit() {
      this.$refs['configForm'].validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true;
            this.config.level.forEach(item => {
              item.value = Number(item.value);
            });
            const res = await (0, _businessIntelligence.editPersonalAbility)({
              ...this.config,
              workspace_id: this.workspaceInfo.id
            });
            res.code === 20000 && this.$message.success('保存成功');
            this.$emit('handleConfigSuccess');
            this.handleClose();
          } finally {
            this.submitLoading = false;
          }
        }
      });
    },
    // 名称校验
    checkLabel(rule, value, callback, type, tooltip) {
      if (!value) {
        return callback(new Error(`${tooltip}不能为空`));
      }
      if (this.hasSameConfig(type, value)) {
        return callback(new Error(`${tooltip}不能重复`));
      }
      callback();
    },
    // 同名校验
    hasSameConfig(type, value) {
      return this.config[type].filter(_ref3 => {
        let {
          name
        } = _ref3;
        return name === value;
      }).length > 1;
    },
    checkLevelValue(rule, value, callback) {
      if (!value || !Number(value)) {
        return callback(new Error('占比不能为空或0'));
      }
      if (this.config.level.reduce((pre, _ref4) => {
        let {
          value
        } = _ref4;
        return pre + Number(value);
      }, 0) !== 100) {
        return callback(new Error('占比总和不等于100%'));
      }
      callback();
    }
  }
};
exports.default = _default;