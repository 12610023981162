"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dialogue-list"
  }, [_vm.conversationList.length ? [_vm.narration ? _c(_setup.DialogueItemNarration, [_vm._v(_vm._s(_vm.narration))]) : _vm._e(), _vm._l(_setup.sentenceList, function (item, index) {
    return [item.type === "narrator" ? _c(_setup.DialogueItemNarration, {
      key: index,
      attrs: {
        imgs: item.images
      }
    }, [_vm._v(_vm._s(item.content))]) : _c(_setup.SentenceItem, _vm._b({
      key: index,
      attrs: {
        sentence: item,
        "customer-avatar": _vm.customerAvatar,
        "popover-placement": "bottom-start",
        "is-sentence-records": ""
      }
    }, "SentenceItem", _vm.$attrs, false))];
  })] : _c(_setup.NoData, {
    attrs: {
      size: "120",
      tips: _setup.t("coaching.noData")
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;