"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary.js");
var _default = {
  name: 'KnowledgeLibraryCatalog',
  props: {
    draggable: {
      type: Boolean,
      default: true
    },
    catalogData: {
      type: Array,
      default: () => []
    },
    showKnowledgeCount: {
      type: Boolean,
      default: false
    },
    showOperationBtns: {
      type: Boolean,
      default: true
    },
    isShowFolderIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultExpandedKeys: [],
      draggingNodeParent: {},
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo']),
    knowledgePermission() {
      var _this$knowledgeBaseIn;
      return Boolean((_this$knowledgeBaseIn = this.knowledgeBaseInfo) === null || _this$knowledgeBaseIn === void 0 ? void 0 : _this$knowledgeBaseIn.permission);
    }
  },
  watch: {
    catalogData: {
      handler() {
        this.catalogData.forEach((item, index) => {
          this.$set(item, 'order', index + 1);
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getCatalogIconConfig(data, node) {
      console.log(data, 'data');
      if (data.is_know) {
        return {
          icon: 'icon-reading'
        };
      } else {
        return {
          icon: 'icon-folder-fill',
          expandedIcon: 'icon-folder-opened-fill'
        };
      }
    },
    getCatalogTreeOperationConfigs(data, node) {
      if (!this.showOperationBtns || !this.knowledgePermission || !data.is_know && node.level >= 6) {
        return [];
      } else {
        return data.is_know ? this.getKnowledgeDropdownConfigs() : this.getFolderDropdownConfigs(node);
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      // 知识点或者目录不能挪动到知识点里 & 知识点不能挪动到树的最外层
      if (dropNode.data.is_know) {
        return type !== 'inner';
      } else if (draggingNode.data.is_know && !dropNode.parent.level) {
        return type === 'inner';
      } else {
        return true;
      }
    },
    handleNodeDragStart(draggingNode, ev) {
      this.draggingNodeParent = draggingNode.parent;
    },
    async handleDrop(draggingNode, dropNode, dropType, ev) {
      try {
        this.loading = true;
        await this.moveKnowledgeTreePotion(draggingNode, dropNode, dropType, ev);
        await this.changeKnowledgeTreeOrder(draggingNode, dropNode, dropType, ev);
      } catch {
        this.loading = false;
      }
    },
    async changeKnowledgeTreeOrder(draggingNode, dropNode, dropType, ev) {
      // 目录排序:排序参数
      const orderData = dropType === 'inner' ? dropNode.data.children : dropNode.parent.level ? dropNode.parent.data.children : dropNode.parent.data;
      // 接口参数 知识点：knowledge_list，目录：catalog_list
      const orderParams = orderData.filter(item => item.is_know === draggingNode.data.is_know).map((item, index) => ({
        id: item.id,
        order: index + 1
      }));
      const res = await (0, _knowledgeLibrary.changeKnowledgeTreeOrder)({
        [draggingNode.data.is_know ? 'knowledge_list' : 'catalog_list']: orderParams
      });
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.successfullyMoved'));
        this.$emit('moveSuccess', draggingNode.data.id);
      }
    },
    async moveKnowledgeTreePotion(draggingNode, dropNode, dropType, ev) {
      // 父节点变化：目录迁移, 拖拽类型为 inner,最外层节点dropNode.parent.level === 0
      if (this.draggingNodeParent.id !== dropNode.parent.id || dropType === 'inner') {
        const params = {
          parent_catalog_id: dropType === 'inner' ? dropNode.data.id : dropNode.parent.level ? dropNode.parent.data.id : 0
        };
        draggingNode.data.is_know ? params.knowledge_id = draggingNode.data.id : params.catalog_id = draggingNode.data.id;
        await (0, _knowledgeLibrary.moveKnowledgeTreePotion)(params);
      }
    },
    handleCommand(node, type) {
      const data = node.data;
      switch (type) {
        case 'createKnowledge':
          this.$emit('createKnowledge', data);
          break;
        case 'createCatalog':
          this.$emit('createCatalog', data);
          break;
        case 'renameCatalog':
          this.$emit('renameCatalog', data);
          break;
        case 'deleteCatalog':
          this.deleteCatalog(data);
          break;
        case 'moveKnowledge':
          this.$emit('moveKnowledge', data, node.parent.data);
          break;
        case 'editKnowledge':
          this.$emit('editKnowledge', data);
          break;
        case 'deleteKnowledge':
          this.deleteKnowledge(data);
          break;
      }
    },
    deleteCatalog(_ref) {
      let {
        id,
        base_id
      } = _ref;
      this.$confirm(this.$t('coaching.removeFoldersWarning'), this.$t('coaching.note'), {
        confirmButtonText: this.$t('coaching.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('coaching.cancel')
      }).then(async () => {
        const res = await (0, _knowledgeLibrary.deleteCatalog)({
          id,
          base_id
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('coaching.deletedSuccessfully'));
          this.$emit('refreshCatalogData');
        }
      });
    },
    deleteKnowledge(_ref2) {
      let {
        id
      } = _ref2;
      this.$confirm(this.$t('coaching.deleteKnowledgePointWarning'), this.$t('coaching.note'), {
        confirmButtonText: this.$t('coaching.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('coaching.cancel')
      }).then(async () => {
        const node = this.$refs.mgvCatalogTree.getElTreeRef().getNode(id);
        const catalog_id = node.parent.data.id;
        const res = await (0, _knowledgeLibrary.deleteKnowledge)({
          id,
          catalog_id
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('coaching.deletedSuccessfully'));
          this.$emit('refreshCatalogData');
        }
      });
    },
    getKnowledgeDropdownConfigs() {
      return [{
        icon: 'icon-more',
        options: [[{
          type: 'moveKnowledge',
          label: '移动',
          icon: 'icon-folder-move'
        }, {
          type: 'editKnowledge',
          label: '编辑',
          icon: 'icon-edit'
        }, {
          type: 'deleteKnowledge',
          label: '删除',
          icon: 'icon-delete'
        }]]
      }];
    },
    getFolderDropdownConfigs(node) {
      return [{
        icon: 'icon-plus',
        options: [[{
          type: 'createKnowledge',
          label: '新建知识点',
          icon: 'icon-reading'
        }, {
          type: 'createCatalog',
          label: '新建文件夹',
          icon: 'icon-folder-add',
          disabled: node.level >= 5
        }]]
      }, {
        icon: 'icon-more',
        options: [[{
          type: 'renameCatalog',
          label: '重命名',
          icon: 'icon-edit-outline'
        }, {
          type: 'deleteCatalog',
          label: '删除',
          icon: 'icon-delete'
        }]]
      }];
    },
    handleCurrentNodeChange(val) {
      this.$refs.mgvCatalogTree.setCurrentNode(val);
    },
    handleNodeClick(data, node, tree) {
      var _node$parent;
      this.$emit('nodeClick', data, (_node$parent = node.parent) === null || _node$parent === void 0 ? void 0 : _node$parent.data);
    },
    deleteCatalogItem(data) {
      let execute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!data.count || execute) {
        this.$emit('deleteNode', data);
      }
    }
  }
};
exports.default = _default;