"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      title: _vm.$t("coaching.aiGeneratedCourse"),
      size: "960px",
      visible: _vm.visible,
      "wrapper-closable": false,
      "before-close": _setup.handleClose
    }
  }, [_c("article", [_c("transition", {
    attrs: {
      name: "slide-fade"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.aiSentenceContainerVisible,
      expression: "aiSentenceContainerVisible"
    }],
    staticClass: "ai-sentence-container"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isGenerating && !_setup.aiSentence,
      expression: "isGenerating && !aiSentence"
    }],
    staticClass: "no-data"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/generating.gif")
    }
  }), _c("p", [_vm._v(_vm._s(_vm.$t("coaching.intelligentGenerating")))])]), _c(_setup.NoData, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.generatingAiSentenceError,
      expression: "generatingAiSentenceError"
    }],
    attrs: {
      icon: "icon-a-Property1not-supported"
    }
  }, [_c("p", {
    staticClass: "tips",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.generationFailed")) + "，"), _c("span", {
    on: {
      click: _setup.generatingAiSentence
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.clickToRetry")))])])]), _c("el-input", {
    attrs: {
      type: "textarea",
      disabled: _setup.isGenerating,
      maxlength: 3000,
      resize: "none",
      placeholder: _vm.$t("coaching.pleaseEnterContent")
    },
    model: {
      value: _setup.aiSentence,
      callback: function ($$v) {
        _setup.aiSentence = $$v;
      },
      expression: "aiSentence"
    }
  })], 1)]), _c("div", {
    staticClass: "form-container"
  }, [_c("div", {
    staticClass: "scroll-container"
  }, [_c("p", {
    staticClass: "form-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.dialogueScene"))), _c("span", {
    staticClass: "require-dot"
  }, [_vm._v(" * ")])]), _c("el-input", {
    class: {
      "error-input": _setup.errorMessage.dialogueErrorMessage
    },
    attrs: {
      type: "textarea",
      rows: 4,
      resize: "none",
      placeholder: _vm.$t("coaching.pleaseEnterContent"),
      maxlength: 500
    },
    on: {
      change: _setup.verificationData
    },
    model: {
      value: _setup.dialogue,
      callback: function ($$v) {
        _setup.dialogue = $$v;
      },
      expression: "dialogue"
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.errorMessage.dialogueErrorMessage,
      expression: "errorMessage.dialogueErrorMessage"
    }],
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_setup.errorMessage.dialogueErrorMessage) + " ")]), _c("p", {
    staticClass: "form-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.dialogueMaterials"))), _c("span", {
    staticClass: "require-dot"
  }, [_vm._v(" * ")])]), _c(_setup.KnowledgeList, {
    attrs: {
      "knowledge-script-list": _setup.knowledgeScriptList
    }
  }), _c("el-button", {
    staticClass: "add-knowledge-button",
    attrs: {
      type: "text primary-bg",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        _setup.scriptSelectionDialogVisible = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.addKnowledgePointsAndScripts")))]), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.errorMessage.knowledgeListErrorMessage,
      expression: "errorMessage.knowledgeListErrorMessage"
    }],
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_setup.errorMessage.knowledgeListErrorMessage) + " ")])], 1), _c("el-button", {
    staticClass: "generating-sentence-button",
    attrs: {
      type: "primary",
      loading: _setup.isGenerating
    },
    on: {
      click: _setup.generatingAiSentence
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.intelligentGenerationConversation")) + " ")])], 1)], 1), _c("footer", [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-tooltip", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.canGeneratingNode,
      expression: "!canGeneratingNode"
    }],
    attrs: {
      content: _vm.$t("coaching.generateDialogueTip")
    }
  }, [_c("el-button", {
    staticClass: "disabled-button",
    attrs: {
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.generateNode")))])], 1), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.canGeneratingNode,
      expression: "canGeneratingNode"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.generateNode")))])], 1), _c(_setup.ScriptSelectionDialog, {
    attrs: {
      visible: _setup.scriptSelectionDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.scriptSelectionDialogVisible = $event;
      },
      change: _setup.changeKnowledgeList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;