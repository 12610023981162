"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-test"
  }, [_c("div", {
    staticClass: "rule-test-header"
  }, [_c("div", {
    staticClass: "rule-test-title"
  }, [_vm._v("规则测试")]), _c("el-popover", {
    attrs: {
      placement: "top",
      width: "440",
      trigger: "hover",
      "popper-class": "rule-test-example-popover"
    }
  }, [_c("div", {
    staticClass: "rule-test-example-content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("测试文本示例")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v(" 请确保您输入的测试文本里包含销售和客户角色及其对话内容。您可以参考下方提供的示例来准备测试文本。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 销售：您好。我是深维公司的销售顾问，很高兴能够与您取得联系。我注意到您昨天在我们的官方网站上提交了试用申请。"), _c("br"), _vm._v(" 客户：是的 "), _c("br"), _vm._v(" 销售：请问你主要想了解什么功能，我可以详细跟您介绍一下"), _c("br"), _vm._v(" 客户：主要想了解下质检相关的功能，你详细介绍下吧"), _c("br")])]), _c("div", {
    staticClass: "rule-test-example",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _vm._v("测试文本示例 ")])])], 1), _c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "输入测试内容可测试规则",
      rows: 4
    },
    model: {
      value: _vm.test_sentence,
      callback: function ($$v) {
        _vm.test_sentence = $$v;
      },
      expression: "test_sentence"
    }
  }), _c("div", {
    class: ["test-btn", {
      disabled: !_vm.test_sentence
    }],
    on: {
      click: _vm.handleTestRuleEvent
    }
  }, [_c("i", {
    staticClass: "iconfont icon-play"
  }), _c("span", [_vm._v("测试")])]), _vm.isTestRule ? [_vm.testResult.keys && _vm.testResult.keys.length ? _c("div", {
    staticClass: "hit-rule-result"
  }, [_c("div", [_vm._v("已触发的规则")]), _vm._l(_vm.testResult.keys, function (item) {
    return _c("div", {
      key: item,
      staticClass: "rule-item"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2) : _vm._e(), _c("div", {
    staticClass: "hit-sentence"
  }, [_vm.testResult.datas && _vm.testResult.datas.length ? _vm._l(_vm.testResult.datas, function (item) {
    return _c("div", {
      key: item.order,
      class: ["sentence-item", {
        "host-salesman": item.entity_type === "host_salesman"
      }]
    }, [_c("div", [_c("div", {
      class: ["sentence-item-top", {
        "host-salesman": item.entity_type === "host_salesman"
      }]
    }, [_c("div", {
      class: ["avatar", {
        "sales-avatar": item.entity_type === "host_salesman"
      }]
    }, [_vm._v(" " + _vm._s(item.entity_type === "customer_contact" ? "客" : "销") + " ")]), _c("div", {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.entity_type === "customer_contact" ? "客户" : "销售") + " ")])]), _c("div", {
      class: ["content", {
        "host-salesman-content": item.entity_type === "host_salesman"
      }],
      domProps: {
        innerHTML: _vm._s(_vm.handleHighLightContent(item.content, item.keywords))
      }
    })])]);
  }) : [_c("div", {
    staticClass: "no-data"
  }, [_vm._v("无触发结果")])]], 2)] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;