"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "progress-tags"
  }, [_vm._l(_vm.progressObj.progressArray, function (item, index) {
    return [index !== _vm.progressObj.progressArray.length - 1 ? _c("angle-tag", {
      key: item.label,
      attrs: {
        label: item.label,
        type: _vm.getTagType(index),
        colorObj: _vm.getTagColorObj(item, index)
      }
    }) : _c("angle-tag", {
      key: item.label,
      attrs: {
        label: item.label,
        type: _vm.getTagType(index),
        colorObj: _vm.getTagColorObj(item, index)
      }
    })];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;