"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _default = {
  name: 'GiveLikeOrUnlike',
  props: {
    convItem: {
      type: Object,
      default: () => ({})
    },
    activeKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      evaluateState: 0,
      likeCount: 0,
      unlikeCount: 0,
      loading: false
    };
  },
  watch: {
    convItem: {
      handler: function (val) {
        const {
          my_attitude,
          like_count,
          unlike_count
        } = val;
        this.evaluateState = my_attitude;
        this.likeCount = like_count;
        this.unlikeCount = unlike_count;
      },
      immediate: true
    }
  },
  methods: {
    async handleClick(state) {
      var _this$activeKnowledge, _this$convItem;
      if (this.loading) return;
      let attitude = '';
      if (state === 'like') {
        this.evaluateState === 1 ? attitude = 'off_like' : attitude = 'like';
      } else {
        this.evaluateState === -1 ? attitude = 'off_unlike' : attitude = 'unlike';
      }
      this.loading = true;
      const res = await (0, _businessIntelligence.postKnowledgeLike)({
        knowledge_id: (_this$activeKnowledge = this.activeKnowledge) === null || _this$activeKnowledge === void 0 ? void 0 : _this$activeKnowledge.knowledge_id,
        attitude,
        conversation_id: (_this$convItem = this.convItem) === null || _this$convItem === void 0 ? void 0 : _this$convItem.conversation_id
      });
      if (res.code === 200) {
        this.handleCount(attitude);
      }
      this.loading = false;
    },
    handleCount(val) {
      const state = {
        like: () => {
          ++this.likeCount;
          this.evaluateState === -1 && --this.unlikeCount;
          this.evaluateState = 1;
        },
        off_like: () => {
          this.evaluateState = 0;
          --this.likeCount;
        },
        unlike: () => {
          ++this.unlikeCount;
          this.evaluateState === 1 && --this.likeCount;
          this.evaluateState = -1;
        },
        off_unlike: () => {
          this.evaluateState = 0;
          --this.unlikeCount;
        }
      };
      return state[val]();
    }
  }
};
exports.default = _default;