"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.cancel,
      "append-to-body": "",
      "custom-class": "upload-library-file-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("upload-media-dialog-head", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }), _c("upload-media", {
    ref: "uploadMedia",
    attrs: {
      "source-type": "snippet",
      "limit-count": 10
    },
    on: {
      select: _vm.updateFileList
    }
  }), _c("div", {
    staticClass: "footer-btn",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "mg-round-button",
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-round-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("上传")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;