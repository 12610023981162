"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "smart-traning-edit"
  }, [_c("header", [_c("div", {
    staticClass: "left-part"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _vm.goBack
    }
  }), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t(_vm.isEdit ? "coaching.editCourse" : "coaching.newCourse")) + "-" + _vm._s(_vm.$t("coaching.aICustomer")) + "(Alpha)")]), _c("el-tooltip", {
    attrs: {
      content: "选择1至3条客户会话让AI学习，可免去搭建过程，一键生成一位可对练的AI客户"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "center"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])])]), _c("article", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "info-container"
  }, [_c("div", {
    staticClass: "scroll-container"
  }, [[_vm._m(0), _c("el-input", {
    staticClass: "edit-title-input",
    attrs: {
      placeholder: "请输入课程名称",
      maxlength: "50"
    },
    on: {
      change: _vm.submitTitleEdit
    },
    model: {
      value: _vm.title,
      callback: function ($$v) {
        _vm.title = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "title"
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.titleErrorMessage,
      expression: "titleErrorMessage"
    }],
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.titleErrorMessage) + " ")])], [_vm._m(1), _c("p", {
    staticClass: "description"
  }, [_vm._v("选择某个客户的会话让AI学习，1~3条即可")]), _vm._l(_vm.conversationList, function (item, index) {
    return _c("ConversationItem", {
      key: item.id,
      attrs: {
        item: item
      },
      on: {
        delete: function ($event) {
          return _vm.deleteConversation(index);
        }
      }
    });
  }), _c("el-button", {
    staticClass: "add-conversation-button",
    attrs: {
      icon: "el-icon-plus",
      size: "medium "
    },
    on: {
      click: function ($event) {
        _vm.conversationDialog = true;
      }
    }
  }, [_vm._v("添加会话")])], [_c("p", {
    staticClass: "title"
  }, [_vm._v("客户特征")]), _c("p", {
    staticClass: "description"
  }, [_vm._v("对AI客户进行更细化的设置，选填")]), _c("div", {
    staticClass: "feature-list"
  }, _vm._l(_vm.characterList, function (item) {
    return _c("span", {
      key: item,
      class: {
        active: _vm.accountLabel.includes(item)
      },
      on: {
        click: function ($event) {
          return _vm.checkedFeature(item);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  }), 0)]], 2)]), _c("footer", [_c("div", {
    staticClass: "footer-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.modelVerify
    }
  }, [_vm._v(_vm._s(_vm.courseId ? "提交模型修改" : "生成客户模型"))]), _c("el-button", {
    attrs: {
      disabled: !_vm.courseId || _vm.submitLoading
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.returnToList")))])], 1)])]), _c("TrainingTestDrawer", {
    ref: "training-test-drawer",
    attrs: {
      "drawer-visible": _vm.trainingTestDrawerVisible,
      "course-id": _vm.courseId
    }
  })], 1), _c("ConversationListDialog", {
    attrs: {
      "dialog-visible": _vm.conversationDialog
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.conversationDialog = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.conversationDialog = $event;
      },
      checkedConversation: _vm.checkedConversation
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "title"
  }, [_vm._v(" 课程名称 "), _c("span", {
    staticClass: "require-icon"
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "title"
  }, [_vm._v(" 添加会话素材 "), _c("span", {
    staticClass: "require-icon"
  }, [_vm._v("*")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;