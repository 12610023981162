"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _KnowledgeConvCard = _interopRequireDefault(require("./KnowledgeConvCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _ScoreSelect = _interopRequireDefault(require("@/components/Filters/components/ScoreSelect"));
var _knowledgeTextFilter = _interopRequireDefault(require("./knowledgeTextFilter.vue"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _vuex = require("vuex");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeConv',
  components: {
    SortCaret: _sortCaret.default,
    KnowledgeConvCard: _KnowledgeConvCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    ScoreSelect: _ScoreSelect.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default,
    KnowledgeTextFilter: _knowledgeTextFilter.default
  },
  props: {
    activeKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const self = this;
    return {
      date: '',
      loading: false,
      knowledgeConvListLength: 0,
      total: 0,
      knowledgeConvFilters: {
        page: 1,
        size: 10,
        sort_order: 'descending',
        sort_by: 'score',
        start_time: '',
        end_time: '',
        score: [1, 5],
        filter: {
          pattern: '',
          role: 'default',
          inverse: false
        }
      },
      customFieldParams: {},
      datePickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            self.setDatePickerOptions(picker, 7);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            self.setDatePickerOptions(picker, 30);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            self.setDatePickerOptions(picker, 90);
          }
        }],
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      knowledgeConvList: [],
      scoreRange: [1, 5]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone']),
    noMore() {
      return this.knowledgeConvListLength && this.knowledgeConvListLength === this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    isExpand() {
      return !!this.knowledgeConvFilters.filter.pattern;
    }
  },
  watch: {
    activeKnowledge: {
      handler: function (val) {
        this.initData();
        if (val) {
          this.getKnowledgeConvList();
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs['score_sort']) {
        this.$refs['score_sort'].activeStatus = 'descending';
      }
    });
    this.initTime();
  },
  methods: {
    customFilterChange(params) {
      this.customFieldParams = params;
      this.initData();
      this.getKnowledgeConvList();
    },
    handleScoreChange(val) {
      this.knowledgeConvFilters.score = val;
      this.initData();
      this.getKnowledgeConvList();
    },
    handleTextFilterChange(_ref) {
      let {
        pattern,
        inverse,
        role
      } = _ref;
      this.knowledgeConvFilters.filter = {
        pattern,
        role,
        inverse
      };
      this.initData();
      this.getKnowledgeConvList();
    },
    initData() {
      this.knowledgeConvList = [];
      this.knowledgeConvListLength = 0;
      this.knowledgeConvFilters.page = 1;
      this.loading = false;
      this.total = 0;
    },
    initTime() {
      if (!this.date) {
        const start = this.$moment(new Date()).subtract(90, 'days');
        const end = this.$moment(new Date());
        this.date = [start, end];
        this.$nextTick(() => {
          this.date = [start, end];
        });
        this.knowledgeConvFilters.start_time = this.$moment(this.date[0]).format('YYYY-MM-DD');
        this.knowledgeConvFilters.end_time = this.$moment(this.date[1]).format('YYYY-MM-DD');
      }
    },
    setDatePickerOptions(picker, count) {
      const start = this.$moment(new Date()).subtract(count, 'days');
      const end = this.$moment(new Date()).subtract(1, 'days');
      picker.$emit('pick', [start, end]);
    },
    filtersChange(val) {
      if (!val) {
        this.initTime();
      } else {
        this.knowledgeConvFilters.start_time = this.$moment(val[0]).format('YYYY-MM-DD');
        this.knowledgeConvFilters.end_time = this.$moment(val[1]).format('YYYY-MM-DD');
      }
      this.initData();
      this.getKnowledgeConvList();
    },
    sortData(sort_by, sort_order) {
      this.knowledgeConvFilters.sort_by = sort_by;
      this.knowledgeConvFilters.sort_order = sort_order;
      this.sortInitOther(sort_by);
      this.initData();
      this.getKnowledgeConvList();
    },
    sortInitOther(sort_by) {
      const refArray = ['time', 'score'];
      refArray.forEach(item => {
        if (item !== sort_by) {
          this.$refs[item + '_sort'].init();
        }
      });
    },
    async getKnowledgeConvList() {
      var _this$activeKnowledge;
      if (!this.activeKnowledge) return;
      this.loading = true;
      const knowledgeConvFilters = (0, _commonFunc.deepClone)(this.knowledgeConvFilters);
      knowledgeConvFilters.end_time = knowledgeConvFilters.end_time + ' 23:59:59';
      (0, _timezone.datePickerWithTimeParse2Zone)(knowledgeConvFilters);
      const customFieldParams = (0, _commonFunc.deepClone)(this.customFieldParams);
      (0, _timezone.convertParams2UTCplus8)(customFieldParams);
      const res = await (0, _businessIntelligence.getKnowledgeConvList)({
        ...knowledgeConvFilters,
        knowledge_id: (_this$activeKnowledge = this.activeKnowledge) === null || _this$activeKnowledge === void 0 ? void 0 : _this$activeKnowledge.knowledge_id,
        custom_filters: customFieldParams,
        time_zone: this.timezone
      });
      if (res.code === 200) {
        res.results.data.forEach(item => {
          this.knowledgeConvList.push(item);
        });
        this.total = res.results.count;
        this.knowledgeConvListLength += res.results.data.length;
        this.knowledgeConvFilters.page++;
      }
      this.loading = false;
    },
    load() {
      this.getKnowledgeConvList();
    }
  }
};
exports.default = _default;