"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _OptionsList = _interopRequireDefault(require("@/components/OptionsList"));
var _elementUi = require("element-ui");
var _coachingCenter = require("@/api/coachingCenter");
var _async = require("@antv/x6/lib/registry/marker/async");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TaskClassifyManagementBtn',
  setup(__props) {
    const drawerVisible = (0, _vue.ref)(false);
    function openManagementDialog() {
      getCategoryList();
      drawerVisible.value = true;
    }
    const classifyDeleteTipInfo = {
      title: (0, _i18n.t)('coaching.删除分类'),
      content: (0, _i18n.t)('coaching.删除分类后，已派发任务的该分类标签会一并清除，是否删除？')
    };
    const loading = (0, _vue.ref)(false);
    const classifyList = (0, _vue.ref)([]);
    const optionsListRef = (0, _vue.ref)(null);
    function addClassify() {
      optionsListRef.value.addOption();
    }
    async function submit() {
      const validateIsOK = optionsListRef.value.validateIsOK();
      if (!validateIsOK) {
        return;
      }
      loading.value = true;
      classifyList.value = handleOptionsParams(classifyList.value);
      const res = await (0, _coachingCenter.saveTaskCategory)({
        category_list: classifyList.value
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        (0, _elementUi.Message)({
          type: 'success',
          message: (0, _i18n.t)('coaching.savedSuccessfully')
        });
        drawerVisible.value = false;
      }
    }
    function handleOptionsParams(options) {
      // 需要过滤掉空值
      options = options.filter(item => item.name);
      options.forEach((item, index) => {
        item.order = index + 1;
      });
      // 并按照顺序从小到大排序
      options.sort((a, b) => {
        return a.order - b.order;
      });
      return options;
    }
    function closeDialog() {
      drawerVisible.value = false;
      classifyList.value = [];
    }
    const optionsListloading = (0, _vue.ref)(false);
    async function getCategoryList() {
      optionsListloading.value = true;
      const res = await (0, _coachingCenter.getTaskCategoryList)().finally(() => {
        optionsListloading.value = false;
      });
      if (res.code === 20000) {
        classifyList.value = res.results.data;
      }
    }
    return {
      __sfc: true,
      drawerVisible,
      openManagementDialog,
      classifyDeleteTipInfo,
      loading,
      classifyList,
      optionsListRef,
      addClassify,
      submit,
      handleOptionsParams,
      closeDialog,
      optionsListloading,
      getCategoryList,
      t: _i18n.t,
      OptionsList: _OptionsList.default
    };
  }
};
exports.default = _default;