"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "is-custom is-center",
    attrs: {
      visible: _vm.visible,
      width: "1000px",
      "custom-class": "intelligent-generation-dialog",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "intelligent-generation",
    class: {
      "is-normal-height": _setup.curStep !== "input"
    }
  }, [_c("div", {
    staticClass: "intelligent-generation__header"
  }, [_c("div", {
    staticClass: "intelligent-generation__header__title"
  }, [_setup.curStep === "selectConversation" ? [_c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "selectConversation",
      expression: "curStep === 'selectConversation'"
    }],
    attrs: {
      type: "text",
      icon: "icon-arrow-left",
      square: "",
      color: "info",
      size: "small"
    },
    on: {
      click: function ($event) {
        _setup.curStep = "input";
      }
    }
  }), _c("span", {
    staticClass: "intelligent-generation__header__title__text"
  }, [_vm._v("添加会话素材")]), _c(_setup.SpaceButton)] : [_c("i", {
    staticClass: "iconfont icon-magic-1 colorful"
  }), _c("span", {
    staticClass: "intelligent-generation__header__title__text"
  }, [_vm._v("智能编排")])]], 2), _c("MgvButton", {
    attrs: {
      icon: "icon-close",
      type: "text",
      color: "info",
      square: ""
    },
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  })], 1), _c("div", {
    staticClass: "content-container"
  }, [_c(_setup.IntelligentGenerationUserInput, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "input",
      expression: "curStep === 'input'"
    }],
    attrs: {
      description: _setup.description,
      conversation: _setup.conversationChecked
    },
    on: {
      "update:description": function ($event) {
        _setup.description = $event;
      },
      "update:conversation": function ($event) {
        _setup.conversationChecked = $event;
      },
      "add-conversation": function ($event) {
        _setup.curStep = "selectConversation";
      },
      "delete-conversation": function ($event) {
        _setup.conversationChecked = {};
      },
      close: function ($event) {
        return _setup.updateVisible(false);
      },
      submit: _setup.submitUserInput
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "generating",
      expression: "curStep === 'generating'"
    }],
    staticClass: "generating-block"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/generating1.gif")
    }
  }), _c("p", [_vm._v("AI解析中")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "generateFailed",
      expression: "curStep === 'generateFailed'"
    }],
    staticClass: "generating-block"
  }, [_c("p", [_vm._v(" AI解析超时，"), _c("MgvButton", {
    attrs: {
      type: "text",
      size: "tiny"
    },
    on: {
      click: _setup.submitUserInput
    }
  }, [_vm._v("点击重试")])], 1)]), _c(_setup.ConversationSelector, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "selectConversation",
      expression: "curStep === 'selectConversation'"
    }],
    on: {
      checkedConversation: _setup.checkConversation
    }
  }), _c(_setup.IntelligentGenerationForm, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.curStep === "form",
      expression: "curStep === 'form'"
    }],
    attrs: {
      "form-desc": _setup.formDesc
    },
    on: {
      cancel: function ($event) {
        _setup.curStep = "input";
      },
      generatePrompt: _setup.generatePrompt
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;