"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "filter-container sop-analysis-date-picker"
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      align: "left",
      "unlink-panels": "",
      "range-separator": " - ",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd",
      "picker-options": _vm.getDatePickerOptions(),
      "popper-class": "sop-date-picker"
    },
    on: {
      change: _vm.dateRangeChange
    },
    model: {
      value: _vm.filterDate,
      callback: function ($$v) {
        _vm.filterDate = $$v;
      },
      expression: "filterDate"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;