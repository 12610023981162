"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LazyTree = _interopRequireDefault(require("./components/LazyTree"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MemberWithDepartmentInput = _interopRequireDefault(require("./components/MemberWithDepartmentInput"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TranserMembersFinder',
  components: {
    LazyTree: _LazyTree.default,
    ImageAvatar: _ImageAvatar.default,
    MemberWithDepartmentInput: _MemberWithDepartmentInput.default
  },
  data() {
    return {
      selection: []
    };
  },
  computed: {
    fullDepartmentUsers() {
      return this.selection.map(item => item.isFull && item.id);
    }
  },
  watch: {
    selection: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true
    }
  },
  methods: {
    getId(id) {
      return id.split('_').pop() - 0;
    },
    handleCheckChange(_data, isCheck) {
      const data = {
        ..._data
      };
      data.id = this.getId(data.id);
      const node = this.$refs.tree.getNode(_data);
      const {
        label,
        key
      } = node.parent;
      const department = {
        name: label,
        id: this.getId(key)
      };
      if (isCheck) {
        let i = this.selection.length;
        for (; i--;) {
          if (this.selection[i].id == data.id) {
            if (this.selection[i].departments.some(item => item.id === department.id)) {
              return;
            }
            this.selection[i].departments.push(department);
            // this.$set(this.selection, i, this.selection[i]);
            break;
          }
        }
        if (i === -1) {
          this.selection = [...this.selection, {
            ...data,
            departments: [department]
          }];
        }
      } else {
        const user = this.selection.find(item => item.id === data.id);
        if (!user) return;
        user.departments = user.departments.filter(item => item.id !== department.id);
        if (!user.departments.length) {
          this.selection = this.selection.filter(item => item.id !== data.id);
        }
      }
    },
    handleSelect(selectItem) {
      const {
        avatar,
        name,
        id,
        department: departments
      } = selectItem;
      const selectUser = {
        avatar,
        name,
        id,
        departments,
        isFull: true
      };
      const user = this.selection.find(item => item.id === selectUser.id);
      if (user) {
        user.departments = selectUser.departments;
      } else {
        this.selection = [...this.selection, selectUser];
      }
      this.$refs.tree && this.$refs.tree.checkNode(selectUser.id, true);
    },
    removeUser(id) {
      this.selection = this.selection.filter(item => item.id !== id);
      this.$refs.tree && this.$refs.tree.checkNode(id, false);
    }
  }
};
exports.default = _default;