"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "mutual-exclusion-basic-info-form"
  }, [_c("div", {
    staticClass: "mutual-exclusion-basic-info-form__title"
  }, [_vm._v("基础信息")]), _c("el-form", {
    ref: "mutexBasicInfoFormRef",
    attrs: {
      model: _vm.mutualExclusionBasicInfoForm,
      rules: _setup.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "策略名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "最多输入20字",
      maxlength: "20",
      "show-word-limit": "",
      type: "text"
    },
    model: {
      value: _vm.mutualExclusionBasicInfoForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.mutualExclusionBasicInfoForm, "name", $$v);
      },
      expression: "mutualExclusionBasicInfoForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "策略描述"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      "show-word-limit": "",
      placeholder: "请输入策略描述",
      resize: "none",
      autosize: {
        minRows: 3,
        maxRows: 20
      },
      maxlength: "100"
    },
    model: {
      value: _vm.mutualExclusionBasicInfoForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.mutualExclusionBasicInfoForm, "description", $$v);
      },
      expression: "mutualExclusionBasicInfoForm.description"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;