"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "one-stop-call"
  }, [_c("div", {
    staticClass: "seat-card-wrapper"
  }, [_c("transition", {
    attrs: {
      name: "seat-card-fade"
    }
  }, [_c("seat-card", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openSeatCard && !_vm.isRinging,
      expression: "openSeatCard && !isRinging"
    }],
    ref: "seatCard",
    attrs: {
      "seat-status": _vm.seatStatus,
      "answer-type": _vm.answerType
    },
    on: {
      changeStatus: _vm.changeStatus,
      openSeatSetDialog: _vm.openSeatSetDialog,
      makeCall: _vm.makeCall,
      changeOffline: _vm.changeOffline
    }
  })], 1)], 1), !_vm.isRinging || _vm.isCallError() ? [_c("el-tooltip", {
    attrs: {
      content: _vm.seatTipText,
      placement: "top",
      "popper-class": "seat-status-tip"
    }
  }, [_c("div", {
    staticClass: "seat-status-button",
    style: {
      backgroundColor: _vm.seatStatusColor
    },
    on: {
      mousedown: _vm.setMoveVal,
      mouseup: _vm.clearMoveTimer,
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleClickSeatBtn.apply(null, arguments);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont seat-status-icon",
    class: [_vm.openSeatCard ? "hide-icon" : "show-icon", _vm.seatStatusIcon]
  }), _c("i", {
    staticClass: "iconfont icon-arrow-down",
    class: [_vm.openSeatCard ? "show-icon" : "hide-icon"]
  })])])] : [_c("el-tooltip", {
    attrs: {
      content: _vm.callTipText,
      placement: "top",
      "popper-class": "seat-status-tip"
    }
  }, [_c("div", {
    staticClass: "seat-status-button wave-wrapper",
    class: [_vm.showCallCard ? "call-card-show" : "call-card-hide"],
    on: {
      mousedown: _vm.setMoveVal,
      mouseup: _vm.clearMoveTimer,
      click: _vm.handleClickSeatBtn
    }
  }, [_vm.callStatus !== "INCOMINGRING" ? _c("i", {
    staticClass: "iconfont call-status-icon",
    class: [!_vm.isCalled ? "icon-outgoingcall" : "icon-phone-outline"]
  }) : _c("i", {
    staticClass: "iconfont call-status-icon income",
    class: [!_vm.isCalled ? "icon-incomingcall" : "icon-phone-outline"]
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.callStatus === "INCOMINGRING",
      expression: "callStatus === 'INCOMINGRING'"
    }],
    staticClass: "wave-border"
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.callStatus === "INCOMINGRING",
      expression: "callStatus === 'INCOMINGRING'"
    }],
    staticClass: "wave-border small"
  })])])], _c("el-dialog", {
    staticClass: "seat-dialog-wrapper",
    attrs: {
      title: _vm.seatDialogTitle,
      visible: _vm.seatFormVisible,
      width: "482px",
      "append-to-body": "",
      "custom-class": "seat-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.seatFormVisible = $event;
      }
    }
  }, [_c("seat-set-form", {
    ref: "seatForm",
    attrs: {
      type: _vm.dialogType,
      "is-login-o-k": _vm.isLoginOK
    },
    on: {
      closeSeatDialog: function ($event) {
        _vm.seatFormVisible = false;
      },
      getProxyUrl: _vm.getProxyUrl,
      formLogin: _vm.formLogin,
      formLogout: _vm.formLogout,
      formSetUpdata: _vm.formSetUpdata
    }
  })], 1), _c("phone-call", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCallCard,
      expression: "showCallCard"
    }],
    ref: "phone",
    attrs: {
      "seat-inited": _vm.isSeatInited,
      "answer-type": _vm.answerType
    },
    on: {
      "update:seatInited": function ($event) {
        _vm.isSeatInited = $event;
      },
      "update:seat-inited": function ($event) {
        _vm.isSeatInited = $event;
      },
      callStatusChange: _vm.callStatusChange,
      isCalled: function ($event) {
        _vm.isCalled = true;
      },
      isRinging: function ($event) {
        _vm.isRinging = true;
      },
      checkIsNewLead: _vm.checkIsNewLead
    }
  }), false ? _c("el-dialog", {
    attrs: {
      title: _vm.editDialogTitle,
      visible: _vm.editFormVisible,
      width: "1000px",
      "append-to-body": "",
      "custom-class": "after-call-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.editFormVisible = $event;
      }
    }
  }, [_c("edit-form", {
    attrs: {
      conv_id: _vm.callConverId,
      lead_id: _vm.callLeadId,
      deal_id: _vm.callDealId,
      "is-new-lead": _vm.isNewLead
    },
    on: {
      closeEditForm: _vm.closeEditForm
    }
  })], 1) : _vm._e(), !_vm.seatHasDestroyed ? _c("iframe", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "one-stop-call-iframe",
    attrs: {
      src: _vm.callPage,
      name: "oneStopCallIframe"
    }
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;