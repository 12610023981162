"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "stage-card"
  }, [_c("header", [_c("h3", [_vm._v("提示")]), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: function ($event) {
        return _vm.$emit("cancel");
      }
    }
  })]), _c("p", {
    staticClass: "row"
  }, [_vm._v(" 是否将"), _c("span", [_vm._v("交易阶段")]), _vm._v("由" + _vm._s(_vm.preStage) + "更新为"), _c("span", [_vm._v(_vm._s(_vm.curStage))])]), _c("div", {
    staticClass: "btn-row"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      plain: ""
    },
    on: {
      click: function ($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("submit");
      }
    }
  }, [_vm._v("确定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;