"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "pre-appoint"
  }, [_c("back-btn", {
    on: {
      backTo: function ($event) {
        return _vm.$emit("backTo");
      }
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("h1", {
    staticClass: "appoint-title"
  }, [_vm._v("预约注册")]), _c("div", {
    staticClass: "check-button",
    on: {
      click: function ($event) {
        return _vm.$emit("input", "个人");
      }
    }
  }, [_vm._v(" 个人使用 "), _c("i", {
    staticClass: "iconfont icon-right"
  })]), _c("div", {
    staticClass: "check-button",
    on: {
      click: function ($event) {
        return _vm.$emit("input", "团队");
      }
    }
  }, [_vm._v(" 团队使用 "), _c("i", {
    staticClass: "iconfont icon-right"
  })])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;