"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConvRecycleBinTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['recoverRecycleBin', 'deleteRecycleBin'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const tableHeader = [{
      prop: 'name',
      label: '名称'
    }, {
      prop: 'user',
      label: '操作人'
    }, {
      prop: 'left_time',
      label: '剩余时间'
    }, {
      prop: 'delete_at',
      label: '操作时间'
    }];
    const handleCommand = (command, row) => {
      switch (command) {
        case 'recover':
          emit('recoverRecycleBin', row);
          break;
        case 'delete':
          emit('deleteRecycleBin', row);
          break;
      }
    };
    return {
      __sfc: true,
      props,
      tableHeader,
      emit,
      handleCommand
    };
  }
};
exports.default = _default;