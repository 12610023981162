"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _canvasConfetti = _interopRequireDefault(require("canvas-confetti"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeFinish',
  props: {
    score: {
      type: [String, Number],
      default: 0
    }
  },
  watch: {
    score() {
      this.initAnimation();
    }
  },
  methods: {
    initAnimation() {
      const origin = {
        y: 0.65,
        x: 0.55
      };
      (0, _canvasConfetti.default)({
        particleCount: 100,
        spread: 70,
        origin,
        zIndex: 9999
      });
      function fire(particleRatio, opts) {
        (0, _canvasConfetti.default)(Object.assign({}, {
          origin
        }, opts, {
          particleCount: Math.floor(200 * particleRatio)
        }));
      }
      fire(0.25, {
        spread: 26,
        startVelocity: 55
      });
      fire(0.2, {
        spread: 60
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45
      });
    },
    // 返回我的任务
    goMyTask() {
      this.$emit('goMyTask');
    },
    // 查看练习详情
    goCoachDetail() {
      this.$emit('goCoachDetail');
    }
  }
};
exports.default = _default;