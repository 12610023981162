"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "customer-filters"
  }, _vm._l(_vm.accountFilterList, function (item, index) {
    return _c(_setup.ConversationFilterItem, {
      key: index,
      staticClass: "custom-filter",
      attrs: {
        label: item.name,
        "is-show-clear": _setup.showCustomFilterClear(_vm.filterParams[item.field_name])
      },
      on: {
        handleClear: function ($event) {
          return _setup.clearCustomFilterItem(item.field_name);
        }
      }
    }, [_c(_setup.DealCustomFilter, {
      attrs: {
        item: item,
        value: _vm.filterParams[item.field_name]
      },
      on: {
        customFilterChange: _setup.handleCustomFilter
      }
    })], 1);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;