"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$departmentCharge, _vm$departmentCharge2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "department-info"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentDepartmentName,
      expression: "currentDepartmentName"
    }],
    staticClass: "left-text"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" " + _vm._s(_vm.currentDepartmentName) + " "), _c("span", [_vm._v(_vm._s(_setup.t("management.的成员列表")))])]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("div", {
    staticClass: "member-count"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" " + _vm._s(_vm.currentDepartmentMemberCount) + " ")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("div", {
    staticClass: "department-head"
  }, [_vm._v(" " + _vm._s(_setup.t("management.部门负责人")) + "："), (_vm$departmentCharge = _vm.departmentCharge) !== null && _vm$departmentCharge !== void 0 && _vm$departmentCharge.name ? _c("span", [_vm._v(_vm._s((_vm$departmentCharge2 = _vm.departmentCharge) === null || _vm$departmentCharge2 === void 0 ? void 0 : _vm$departmentCharge2.name))]) : _c("span", {
    staticClass: "no-set"
  }, [_vm._v(_vm._s(_setup.t("management.未设置")))]), _c("i", {
    staticClass: "el-icon-edit",
    on: {
      click: _setup.setDepartmentCharge
    }
  })]), _vm.limitCount && _vm.limitCount != -1 ? _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }) : _vm._e(), _vm.limitCount && _vm.limitCount != -1 ? _c("div", {
    staticClass: "member-limit"
  }, [_vm._v(" " + _vm._s(_setup.t("management.公司已使用/人数上限")) + "： " + _vm._s(_vm.allMemberCounter) + " / " + _vm._s(_vm.limitCount) + " "), _vm.limitCount && _vm.limitCount != -1 ? _c("el-tooltip", {
    staticClass: "tooltip",
    attrs: {
      effect: "dark",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "tooltip-content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" " + _vm._s(_setup.t("management.已使用和上限说明")) + " ")]), _c("i", {
    staticClass: "iconfont icon-question2"
  })]) : _vm._e()], 1) : _vm._e()], 1), _c(_setup.ExportButton, {
    attrs: {
      content: `${_setup.t("management.确定导出当前{0}个成员信息吗？", [_vm.multipleSelect.length ? _vm.multipleSelect.length : _vm.memberCount])}`,
      "btn-content": _setup.t("management.导出成员"),
      "export-api": _vm.exportApi
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;