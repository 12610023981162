"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.scopes.length ? _c("div", {
    staticClass: "key-event-data-scope"
  }, [_c("el-popover", {
    attrs: {
      placement: _vm.placement,
      trigger: "hover",
      disabled: !_vm.showPopover,
      "popper-class": "event-data-scope-popper"
    }
  }, [_c("div", {
    staticClass: "popover-content"
  }, [_c("h1", {
    staticClass: "scope-title"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("应用范围")])]), _c(_setup.DataScopeTags, {
    attrs: {
      tags: _setup.scopes
    }
  })], 1), _vm._t("reference", function () {
    return [_c(_setup.DataScopeTags, {
      attrs: {
        tags: _setup.scopes.slice(0, 2)
      }
    })];
  }, {
    slot: "reference"
  })], 2)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;