"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormDisplayCascader = _interopRequireDefault(require("./components/FormDisplayCascader.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EvaluateFormDisplay',
  components: {
    FormDisplayCascader: _FormDisplayCascader.default
  },
  props: {
    evaluationRecord: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getSelectedOptions(val) {
      return val.options.filter(item => val.option_ids.includes(item.id));
    }
  }
};
exports.default = _default;