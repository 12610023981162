"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'NumberScopeInput',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    minNumber: {
      type: Number,
      default: 0
    },
    maxNumber: {
      type: Number,
      default: -1
    },
    precision: {
      type: Number,
      default: 2
    }
  },
  emits: ['change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const min = (0, _vue.ref)();
    const max = (0, _vue.ref)();
    const localValue = (0, _vue.ref)([]);
    (0, _vue.watchEffect)(() => {
      if (max.value < min.value) {
        [max.value, min.value] = [min.value, max.value];
      }
      localValue.value = [min.value, max.value].map(v => {
        return v === undefined ? -1 : v;
      });
      emit('input', localValue.value);
    });
    const valueToString = (0, _vue.computed)(() => {
      if (localValue.value[0] === -1 && localValue.value[1] === -1) {
        return '';
      } else if (localValue.value[0] === -1) {
        return `小于等于 ${localValue.value[1]}`;
      } else if (localValue.value[1] === -1) {
        return `大于等于 ${localValue.value[0]}`;
      } else {
        return `${localValue.value[0]} - ${localValue.value[1]}`;
      }
    });
    const handleClear = async () => {
      min.value = undefined;
      max.value = undefined;
      await (0, _vue.nextTick)();
      emit('change', localValue.value);
    };
    const handleBlur = val => {
      emit('change', localValue.value);
    };
    return {
      __sfc: true,
      emit,
      min,
      max,
      localValue,
      valueToString,
      handleClear,
      handleBlur
    };
  }
};
exports.default = _default;