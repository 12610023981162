"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$dataSetDataList;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "annotate-operate"
  }, [_c("div", {
    staticClass: "annotate-operate-content"
  }, [_c("transition", {
    attrs: {
      name: "fade-out"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.activeDataSetData.is_annotated,
      expression: "!activeDataSetData.is_annotated"
    }],
    staticClass: "annotate-status unannotated"
  }, [_c("i", {
    staticClass: "iconfont icon-time-fill"
  }), _c("span", [_vm._v("待标注")])])]), _c("transition", {
    attrs: {
      name: "fade-in"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeDataSetData.is_annotated,
      expression: "activeDataSetData.is_annotated"
    }],
    staticClass: "annotate-status annotated"
  }, [_c("i", {
    staticClass: "iconfont icon-success"
  }), _c("span", [_vm._v("已标注")])])]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "快捷键：Shift + S"
    }
  }, [_c("el-button", {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ["shift", "s"],
      expression: "['shift', 's']"
    }],
    staticClass: "annotate-complete-btn",
    attrs: {
      icon: "iconfont icon-circle-check",
      "data-v-step": "annotateCompleteBtn"
    },
    on: {
      click: function ($event) {
        return _setup.handleChangeDataSetDataOperate("completedAndNext");
      }
    },
    nativeOn: {
      shortkey: function ($event) {
        return _setup.handleChangeDataSetDataOperate("completedAndNext");
      }
    }
  }, [_vm._v(" 完成标注 ")])], 1)], 1), _c("div", {
    staticClass: "annotate-progress-buttons"
  }, [_c("div", {
    staticClass: "annotate-shortcut"
  }, [_c("el-checkbox", {
    on: {
      change: _setup.handleAutoToNext
    },
    model: {
      value: _setup.annotateAutoNext,
      callback: function ($$v) {
        _setup.annotateAutoNext = $$v;
      },
      expression: "annotateAutoNext"
    }
  }), _c("div", [_vm._v("自动进入下一个")])], 1), _c("div", {
    staticClass: "annotate-progress"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "快捷键：W"
    }
  }, [_c("el-button", {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ["w"],
      expression: "['w']"
    }],
    class: ["prev-btn", {
      "disabled-prev-btn": _setup.activeDataSetDataIndex < 1
    }],
    attrs: {
      disabled: _setup.activeDataSetDataIndex === 0,
      plain: "",
      icon: "iconfont icon-back"
    },
    on: {
      click: function ($event) {
        return _setup.handleChangeDataSetDataOperate("prev");
      }
    },
    nativeOn: {
      shortkey: function ($event) {
        return _setup.handleChangeDataSetDataOperate("prev");
      }
    }
  }, [_vm._v(" 上一个 ")])], 1), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "快捷键：S"
    }
  }, [_c("el-button", {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ["s"],
      expression: "['s']"
    }],
    staticClass: "next-btn",
    attrs: {
      disabled: _setup.activeDataSetDataIndex + 1 === ((_vm$dataSetDataList = _vm.dataSetDataList) === null || _vm$dataSetDataList === void 0 ? void 0 : _vm$dataSetDataList.length),
      plain: ""
    },
    on: {
      click: function ($event) {
        return _setup.handleChangeDataSetDataOperate("next");
      }
    },
    nativeOn: {
      shortkey: function ($event) {
        return _setup.handleChangeDataSetDataOperate("next");
      }
    }
  }, [_vm._v(" 下一个"), _c("i", {
    staticClass: "iconfont icon-right"
  })])], 1)], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;