"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "send-box",
    class: {
      "is-unfolded": _vm.isUnfolded || _vm.isEmpty(_vm.selectedSentenceOfComment)
    }
  }, [_vm.isUnfolded && _vm.commentStatus !== "default" ? _c("SendBoxHeader", {
    ref: "SendBoxHeader",
    on: {
      cancel: _vm.cancelHelpOrTeach,
      clearProposals: _vm.clearProposals
    },
    model: {
      value: _vm.teacherId,
      callback: function ($$v) {
        _vm.teacherId = $$v;
      },
      expression: "teacherId"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "send-container"
  }, [_c("div", {
    staticClass: "text-area-container",
    class: {
      disabled: _vm.commentInputDisabled
    }
  }, [_c("CommentInput", {
    ref: "commentInput",
    attrs: {
      disabled: _vm.commentInputDisabled,
      "is-unfolded": _vm.isUnfolded,
      "has-mention": _vm.hasMention,
      placeholder: _vm.placeholder,
      "entity-type": _vm.commentType,
      "department-manager-info": _vm.departmentManagerInfo
    },
    on: {
      "update:hasMention": function ($event) {
        _vm.hasMention = $event;
      },
      "update:has-mention": function ($event) {
        _vm.hasMention = $event;
      },
      focus: _vm.textFocus,
      pressEnter: _vm.sendComment
    }
  }), _vm.commentStatus === "teach" ? _c("InsightClipSelector", {
    ref: "insightClipSelector"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "bottom-row"
  }, [_vm.showCommentPermissionCheckbox ? _c("div", {
    staticClass: "comment-permission"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.handleCommentPermissionChange
    },
    model: {
      value: _vm.commentVisiblePermission,
      callback: function ($$v) {
        _vm.commentVisiblePermission = $$v;
      },
      expression: "commentVisiblePermission"
    }
  }, [_vm._v("仅被@的人可见该评论")])], 1) : _vm._e(), _c("div", {
    class: ["bottom-row-operate", {
      "hide-time": _vm.commentType === "business"
    }]
  }, [_c("div", {
    staticClass: "comment-time-container"
  }, [!_vm.isReply ? [_c("span", {
    staticClass: "comment-time-desc"
  }, [_vm._v("发言时间")]), _vm.commentType !== "business" ? _c("span", {
    staticClass: "comment-time"
  }, [_vm._v(_vm._s(_vm.currentTimeLabel))]) : _vm._e()] : _vm._e()], 2), _c("el-button", {
    directives: [{
      name: "debounce",
      rawName: "v-debounce",
      value: [_vm.sendComment],
      expression: "[sendComment]"
    }],
    staticClass: "mg-button",
    attrs: {
      type: "primary",
      loading: _vm.sendBtnLoading,
      disabled: _vm.sendDisabled
    }
  }, [_vm._v("发 送")])], 1)])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;