"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DataAnalysisTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Object,
      default: () => ({})
    },
    subColumns: {
      type: Array,
      default: () => []
    },
    mainColumn: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['sort-change', 'cell-click'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const parse2percent = num => {
      return `${Math.round(num * 10000) / 100}%`;
    };
    const reverseStr = str => {
      return str.replace(/>/g, '<').split('').reverse().join('');
    };
    const onCellClick = (column, row) => {
      emit('cell-click', column, row);
    };
    const sortChange = _ref2 => {
      let {
        prop,
        order
      } = _ref2;
      emit('sort-change', prop, order);
    };
    return {
      __sfc: true,
      props,
      parse2percent,
      reverseStr,
      emit,
      onCellClick,
      sortChange,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;