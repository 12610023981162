"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "root"
  }, [_c("navbar", {
    attrs: {
      "back-button": ""
    }
  }, [_c("UserInfoCard", {
    attrs: {
      slot: "prefix",
      "user-info": _vm.userInfo
    },
    slot: "prefix"
  })], 1), _c("article", {
    staticClass: "business-intelligence-personal"
  }, [_c("section", {
    staticClass: "content"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.biTab,
      callback: function ($$v) {
        _vm.biTab = $$v;
      },
      expression: "biTab"
    }
  }, [_c("bi-filter", {
    ref: "biFilter",
    attrs: {
      layout: _vm.layout,
      "has-deal-custom-field-filter": _vm.hasDealCustomFieldFilter
    },
    on: {
      filtersChange: _vm.handleFilterChange
    }
  }), _vm._l(_vm.tabArray, function (item) {
    return _c("el-tab-pane", {
      key: item.id,
      attrs: {
        label: item.meta.subtitle,
        name: item.name
      }
    }, [_vm.biTab === item.name ? _c("router-view", {
      key: item.path,
      attrs: {
        "person-name": _vm.$route.query.name,
        filters: _vm.filters,
        "bi-tab": _vm.biTab
      }
    }) : _vm._e()], 1);
  })], 2)], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;