"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _mediaUpload = require("@/api/mediaUpload");
var _compliance = require("@/api/compliance");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _elementUi = require("element-ui");
var _default = {
  __name: 'UploadMemberFile',
  emits: 'changeUploadFile',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const store = (0, _useVuex.useStore)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const downloadTemplateExcel = () => {
      (0, _download.download)('/batch/compliance', '待质检成员导入模板');
    };
    const fileInfo = (0, _vue.ref)({});
    const importResult = (0, _vue.ref)({});
    const loading = (0, _vue.ref)(false);
    // 轮询导入结果接口
    let getImportResultsTimer = null;
    const getImportResults = (task_id, item) => {
      getImportResultsTimer = setInterval(async () => {
        const res = await (0, _mediaUpload.getBatchUpload)({
          task_id
        }).catch(() => {
          clearInterval(getImportResultsTimer);
        });
        if (res.code === 200) {
          const {
            fail,
            fail_count,
            success_count,
            total
          } = res.results;
          if (res.results) {
            clearInterval(getImportResultsTimer);
            loading.value = false;
            if (fail) {
              _elementUi.Message.warning('导入失败');
            } else {
              importResult.value = {
                total,
                success_count,
                fail_count
              };
              fileInfo.value = item.file;
              if (success_count) {
                _elementUi.Message.success('导入成功');
              } else {
                _elementUi.Message.warning('导入成功 0 条，请重新上传');
              }
              emit('changeUploadFile', success_count);
            }
          }
        }
      }, 2000);
    };
    (0, _vue.onBeforeUnmount)(() => {
      clearInterval(getImportResultsTimer);
    });
    const uploadFile = async item => {
      if (!item.file) return;
      try {
        loading.value = true;
        const res = await (0, _compliance.uploadComplianceMember)({
          file: item.file,
          workspace_id: workspaceId.value
        });
        if (res.code === 200) {
          getImportResults(res.results.task_id, item);
        }
      } catch {
        _elementUi.Message.warning('导入失败');
        loading.value = false;
      }
    };
    const deleteUploadFile = () => {
      fileInfo.value = {};
      emit('changeUploadFile', null);
    };
    return {
      __sfc: true,
      store,
      workspaceId,
      emit,
      downloadTemplateExcel,
      fileInfo,
      importResult,
      loading,
      getImportResultsTimer,
      getImportResults,
      uploadFile,
      deleteUploadFile
    };
  }
};
exports.default = _default;