"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DragInputs = _interopRequireDefault(require("./DragInputs"));
var _field = require("@/api/field");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessCustomStatus',
  components: {
    DragInputs: _DragInputs.default
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inProcess: {
        data: []
      },
      end: {
        win: {
          name: '',
          data: []
        },
        loss: {
          name: '',
          data: []
        },
        invalidate: {
          name: '',
          data: []
        }
      },
      oldVal: '',
      initData: null
    };
  },
  watch: {
    inProcess: {
      handler: function (val) {
        if (this.initData) {
          const data = {
            in_process: val,
            ...this.end
          };
          this.compareIsSame(data, this.initData);
        }
      },
      deep: true
    },
    end: {
      handler: function (val) {
        if (this.initData) {
          const data = {
            in_process: this.inProcess,
            ...val
          };
          this.compareIsSame(data, this.initData);
        }
      },
      deep: true
    }
  },
  created() {
    this.getDealStages();
  },
  methods: {
    async getDealStages() {
      const res = await (0, _field.getDealStages)();
      this.resFormat(res.results);
    },
    formatNum(num) {
      return typeof num === 'number' ? num.toLocaleString('zh', {
        style: 'percent'
      }) : '';
    },
    resFormat(_ref) {
      let {
        in_process,
        win,
        loss,
        invalidate
      } = _ref;
      this.inProcess = in_process;
      this.end = {
        win,
        loss,
        invalidate
      };
      this.initData = JSON.parse(JSON.stringify({
        in_process: this.inProcess,
        ...this.end
      }));
    },
    recoveryData() {
      this.resFormat(this.initData);
    },
    async handleSender(type, index, item) {
      if (type === 'delete') {
        this.$confirm(`是否删除该阶段？`, '删除', {
          confirmButtonText: '删除',
          confirmButtonClass: 'confirm-right',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.checkDealStages(item.id, index);
        });
      }
    },
    async checkDealStages(id, index) {
      const res = await (0, _field.checkDealStages)({
        id
      });
      if (res.code === 200) {
        this.inProcess.splice(index, 1);
      }
    },
    async updateDealStage() {
      const inProcess = JSON.parse(JSON.stringify(this.inProcess)).map(item => {
        item.win_rate = item.win_rate / 100;
        return item;
      });
      const data = {
        in_process: inProcess,
        ...this.end
      };
      const res = await (0, _field.updateDealStage)(data);
      if (res.code === 200) {
        this.$message.success('保存成功！');
        this.getDealStages();
        this.$emit('updateSuccess');
      }
    },
    handleFocus(val) {
      this.oldVal = val;
    },
    handleBlur(val) {
      if (val !== this.oldVal) {
        this.handleSender();
      }
    },
    compareIsSame(val, initVal) {
      const isSame = JSON.stringify(val) === JSON.stringify(initVal);
      this.$emit('dataHasChange', isSame, val);
    }
  }
};
exports.default = _default;