"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _ConvTimesAmount = _interopRequireDefault(require("./ConvTimesAmount.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvTimesFilter',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    ConvTimesAmount: _ConvTimesAmount.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      btnList: [{
        label: '首次',
        value: [1, 1],
        isCustom: false
      }, {
        label: '第 2 次',
        value: [2, 2],
        isCustom: false
      }, {
        label: '第 3 次',
        value: [3, 3],
        isCustom: false
      }, {
        label: '自定义',
        value: [],
        isCustom: true
      }]
    };
  },
  computed: {
    isCustomConvTimes() {
      return this.filters.conv_times.is_custom;
    }
  },
  watch: {
    'filters.conv_times': {
      handler: function (val) {}
    }
  },
  methods: {
    handleClearConvTimes() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.conv_times.is_custom = false;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.conv_times.value = [-1, -1];
    },
    handleTimesChange(val) {
      this.$emit('selectConvTimes', val);
    },
    handleClick(item) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.conv_times.is_custom = item.isCustom;
      this.handleTimesChange(item.value);
    }
  }
};
exports.default = _default;