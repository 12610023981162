"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router.js");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ScoreRuleConfig',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: 'commonBatchUpdate',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const route = (0, _useRouter.useRoute)();
    const configs = (0, _vue.ref)({
      scoreRuleType: 1,
      scoreRules: []
    });
    const aiLoading = (0, _vue.ref)(false);
    (0, _vue.watch)(() => props.config, val => {
      configs.value = (0, _cloneDeep.default)(val);
      aiLoading.value = val.loading;
    }, {
      deep: true,
      immediate: true
    });
    const aiBtnDisabled = (0, _vue.computed)(() => {
      return !!configs.value.branches[0].text.replace(/\n/g, '');
    });
    const changeScoreRule = () => {
      emit('commonBatchUpdate', {
        scoreRules: configs.value.scoreRules,
        loading: false
      });
    };
    const toggleRuleType = () => {
      emit('commonBatchUpdate', {
        scoreRuleType: configs.value.scoreRuleType,
        loading: false
      });
    };
    const addAiScoreRule = async () => {
      if (!configs.value.branches[0].text) return;
      emit('addAiScoreRule', configs.value);
    };
    const addCustomScoreRule = () => {
      configs.value.scoreRules.push({
        text: '',
        score: 0
      });
      changeScoreRule();
    };
    const deleteRule = index => {
      configs.value.scoreRules.splice(index, 1);
      changeScoreRule();
    };

    // 按要点评分没有内容时，关闭抽屉自动 AI生成要点
    (0, _vue.watch)(() => props.visible, val => {
      if (!val && configs.value.branches[0].text) {
        if (configs.value.scoreRuleType === 1 && !configs.value.scoreRules.filter(item => item.text).length) {
          addAiScoreRule();
        }
      }
    });
    return {
      __sfc: true,
      route,
      props,
      configs,
      aiLoading,
      aiBtnDisabled,
      emit,
      changeScoreRule,
      toggleRuleType,
      addAiScoreRule,
      addCustomScoreRule,
      deleteRule
    };
  }
};
exports.default = _default;