"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _fetchRequest = _interopRequireDefault(require("@/utils/fetch-request"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _PrintText = _interopRequireDefault(require("./PrintText.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AICoachingContent',
  components: {
    NoData: _NoData.default,
    PrintText: _PrintText.default
  },
  props: {
    conversationDetailInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      applyContent: '',
      isNetworkError: false,
      isTooLong: false,
      isUncoach: false,
      AICoachingContent: '',
      requestLoading: false,
      isNoPrint: false,
      isStreamText: false,
      streamTextStatus: ''
    };
  },
  computed: {
    iconClassObj() {
      return {
        iconfont: true,
        'icon-a-Property1no-wifi': this.isNetworkError,
        'icon-not-supported': this.isTooLong,
        'icon-no-data': this.isUncoach
      };
    },
    isShowApply() {
      return this.conversationDetailInfo.isSeeker && !this.conversationDetailInfo.ai_evaluate_status;
    }
  },
  created() {
    console.log('created', JSON.parse(JSON.stringify(this.conversationDetailInfo)));
    this.checkIsTooLong();
  },
  mounted() {
    if (!this.isShowApply) {
      this.getAICoachingDetail();
    }
  },
  methods: {
    async getAICoachingDetail() {
      this.requestLoading = true;
      const res = await (0, _videoDetail.getAICoachingDetail)({
        conversation_id: this.conversationDetailInfo.id
      }).finally(() => {
        this.requestLoading = false;
      });
      if (res.code === 20000) {
        /** is_conv_host 是否会话归属人 true：是，false：否；
         *  status 是辅导状态 0：未辅导，1：已辅导；
         *  error_type  0:正常点评内容，1：错误点评内容,如太长无法点评*/
        const {
          is_conv_host,
          status,
          error_type,
          content
        } = res.results;
        this.requestLoading = false;
        if (!is_conv_host && !status) {
          // 非归属人并且未辅导的情况
          this.isUncoach = true;
        } else if (error_type) {
          this.isTooLong = true;
        } else {
          this.AICoachingContent = content;
          this.isNoPrint = true;
          this.isStreamText = false;
        }
      }
    },
    async applyAICoaching() {
      const data = {
        conversation_id: this.conversationDetailInfo.id,
        supplement_content: this.applyContent || ''
      };
      this.requestLoading = true;
      this.isNetworkError = false;
      const res = await (0, _fetchRequest.default)('/webapi/conversation/detail/evaluate/ask', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      // eslint-disable-next-line no-undef
      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
      while (this.streamTextStatus !== 'done') {
        const {
          done,
          value
        } = await reader.read();
        this.requestLoading = false;
        if (done) {
          this.streamTextStatus = 'done';
          this.applyContent = '';
          break;
        }
        if (value === '40708') {
          // 网络异常的情况
          this.isNetworkError = true;
          return;
        }
        this.$emit('applyAICoachingSuccess');
        this.AICoachingContent += value;
        this.isNoPrint = false;
        this.isStreamText = true;
        this.streamTextStatus = 'printing';
        this.onPrinting();
      }
    },
    onPrinting() {
      this.$nextTick(() => {
        const textContent = this.$refs.contentContainer;
        textContent.scrollTop = textContent.scrollHeight;
      });
    },
    checkIsTooLong() {
      var _this$conversationDet;
      const seconds = (_this$conversationDet = this.conversationDetailInfo.video) === null || _this$conversationDet === void 0 ? void 0 : _this$conversationDet.duration;
      const duration = this.$moment.duration(seconds, 'seconds');
      const isGreaterThanOneHour = duration.as('hours') > 1;
      this.isTooLong = isGreaterThanOneHour;
    }
  }
};
exports.default = _default;