"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.message = void 0;
var _elementUi = require("element-ui");
// 出现多次message提醒问题 重写message

let messageInstance = null;
const resetMessage = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = (0, _elementUi.Message)(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
const message = resetMessage;
exports.message = message;