"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-library-container"
  }, [_c("header", [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: "知识库"
    }
  })], 1), _c("article", [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getTopKnowledgeBaseListLoading,
      expression: "getTopKnowledgeBaseListLoading"
    }],
    staticClass: "topping"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("常用")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        _vm.isExpand = !_vm.isExpand;
      }
    }
  }, [_vm._v(_vm._s(_vm.isExpand ? "收起" : "展开")), _c("i", {
    class: ["el-icon-arrow-down", {
      expand: _vm.isExpand
    }]
  })])], 1), _c("el-collapse-transition", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isExpand,
      expression: "isExpand"
    }]
  }, [_c("div", {
    staticClass: "transition-box"
  }, [!_vm.getTopKnowledgeBaseListLoading && _vm.topKnowledgeBaseList.length ? _c("div", {
    staticClass: "knowledge-card-container"
  }, _vm._l(_vm.topKnowledgeBaseList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "knowledge-item"
    }, [_c("i", {
      staticClass: "iconfont icon-knowledge-base"
    }), _c("div", {
      staticClass: "detail",
      on: {
        click: function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        placement: "top",
        effect: "dark",
        content: item.name
      }
    }, [_c("p", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))])]), _c("el-tag", {
      attrs: {
        size: "mini",
        type: item.public_scope === 1 ? "" : "success"
      }
    }, [_vm._v(_vm._s(item.public_scope === 1 ? "公开" : "成员可见"))])], 1), _c("el-dropdown", {
      attrs: {
        trigger: "click",
        placement: "bottom-end"
      },
      on: {
        command: function ($event) {
          return _vm.handleCommand(item, $event);
        }
      }
    }, [_c("el-button", {
      attrs: {
        type: "text gray-bg",
        icon: "el-icon-more"
      }
    }), _c("el-dropdown-menu", {
      staticClass: "operate-dropdown",
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, _vm._l(_vm.getDropdownConfigs(item), function (_ref) {
      let {
        type,
        icon,
        label
      } = _ref;
      return _c("el-dropdown-item", {
        key: type,
        attrs: {
          command: type,
          icon: `iconfont ${icon}`
        }
      }, [_vm._v(_vm._s(label))]);
    }), 1)], 1)], 1);
  }), 0) : _c("p", {
    staticClass: "empty"
  }, [_vm._v(" 点击下方知识库右侧的 "), _c("i", {
    staticClass: "iconfont icon-thumbtack"
  }), _vm._v(" 即可设为常用 ")])])])])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKnowledgeBaseListLoading,
      expression: "getKnowledgeBaseListLoading"
    }],
    staticClass: "library-container"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("span", [_vm._v("全部知识库")]), _c("div", {
    staticClass: "right-buttons"
  }, [_vm.hasKnowledgeBasePermission ? _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.handleKnowledgeBaseConfig("create");
      }
    }
  }, [_vm._v("新建知识库")]) : _vm._e(), _c("SwitchViewButton", {
    attrs: {
      "view-type": _vm.viewType
    },
    on: {
      selectView: _vm.selectView
    }
  })], 1)]), !_vm.getKnowledgeBaseListLoading && _vm.knowledgeBaseList.length && _vm.viewType === "table" ? _c("el-table", {
    attrs: {
      data: _vm.knowledgeBaseList
    },
    on: {
      "row-click": _vm.goDetail
    }
  }, [_vm._l(_vm.getTableHeads(), function (_ref2) {
    let {
      label,
      prop,
      id,
      minWidth
    } = _ref2;
    return _c("el-table-column", {
      key: id,
      attrs: {
        label: label,
        width: prop === "is_top" ? 38 : "",
        "min-width": minWidth
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [prop === "name" ? _c("span", {
            staticClass: "knowledge-name-column"
          }, [_c("i", {
            staticClass: "iconfont icon-knowledge-base"
          }), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: scope.row[prop]
            }
          }, [_c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(scope.row[prop]))])]), _c("el-tag", {
            attrs: {
              size: "mini",
              type: scope.row.public_scope === 1 ? "" : "success"
            }
          }, [_vm._v(_vm._s(scope.row.public_scope === 1 ? "公开" : "成员可见"))])], 1) : prop === "is_top" ? _c("span", {
            staticClass: "top-btn"
          }, [_c("el-tooltip", {
            attrs: {
              placement: "top",
              effect: "dark",
              content: scope.row.is_top ? "移出常用" : "设为常用"
            }
          }, [_c("i", {
            class: ["iconfont", scope.row.is_top ? "icon-thumbtack-fill" : "icon-thumbtack"],
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.handleCommand(scope.row, "remove");
              }
            }
          })])], 1) : _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: scope.row[prop]
            }
          }, [_c("span", {
            staticClass: "content"
          }, [_vm._v(_vm._s(scope.row[prop] || "-"))])])];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "182"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.permission > 1 ? [_c("el-button", {
          attrs: {
            type: "text primary-bg"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.handleCommand(scope.row, "rename");
            }
          }
        }, [_vm._v("重命名")]), _c("el-button", {
          attrs: {
            type: "text primary-bg"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.modifyPermission(scope.row);
            }
          }
        }, [_vm._v("权限")]), _c("el-button", {
          attrs: {
            type: "text danger-bg"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.handleCommand(scope.row, "delete");
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("coaching.delete")))])] : _c("span", {
          staticClass: "empty-text"
        }, [_vm._v("-")])];
      }
    }], null, false, 2540372106)
  })], 2) : _vm._e(), !_vm.getKnowledgeBaseListLoading && _vm.knowledgeBaseList.length && _vm.viewType === "card" ? _c("KnowledgeLibraryCardView", {
    attrs: {
      "card-list": _vm.knowledgeBaseList
    },
    on: {
      handleCommand: _vm.handleCommand,
      goDetail: _vm.goDetail
    }
  }) : _vm._e(), !_vm.getKnowledgeBaseListLoading && !_vm.knowledgeBaseList.length ? _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-knowledge",
      tips: "暂无知识库"
    }
  }) : _vm._e()], 1)]), _c("EditKnowledgeBaseConfig", {
    ref: "edit-knowledge-base-config",
    attrs: {
      visible: _vm.knowledgeBaseConfigVisible,
      "is-create": _vm.handleKnowledgeBaseType === "create"
    },
    on: {
      "update:visible": function ($event) {
        _vm.knowledgeBaseConfigVisible = $event;
      },
      handler: _vm.editKnowledgeBaseConfig
    }
  }), _c("KnowledgeBasePermissionDrawer", {
    attrs: {
      visible: _vm.knowledgeBasePermissionVisible,
      "current-knowledge-base": _vm.currentKnowledgeBase
    },
    on: {
      "update:visible": function ($event) {
        _vm.knowledgeBasePermissionVisible = $event;
      },
      refresh: _vm.initKnowledgeBase
    }
  }), _c("DeleteConfirmationDialog", {
    attrs: {
      "current-knowledge-base": _vm.currentKnowledgeBase,
      visible: _vm.deleteConfirmationDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.deleteConfirmationDialogVisible = $event;
      },
      delete: _vm.deleteKnowledgeBase
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;