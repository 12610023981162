"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'HitCountScoreRule',
  props: {
    scoreItem: {
      type: Object,
      default: () => ({})
    },
    scoreMode: {
      type: String,
      default: 'increase'
    },
    scoreExtItems: {
      type: Array,
      default: () => []
    },
    totalScore: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    isCountValidError(item) {
      if (!item.hits) return false;
      const keyName = {
        key_words: 'textFilterList',
        event_engine: 'keyEventCascaderList'
      }[this.scoreItem.type];
      return this.scoreItem.config[keyName].length < item.hits;
    },
    isRepeatValidError(item) {
      return this.scoreExtItems.filter(scoreItem => scoreItem.hits && scoreItem.hits === item.hits).length > 1;
    }
  }
};
exports.default = _default;