"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "current-info"
  }, [_c("div", [_setup.ENV_TITLE === "image" ? _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.登录账号")))]), _c("div", [_vm._v(_vm._s(_setup.accountInfo.login_account))])]) : _setup.isTwoFactor ? _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.登录账号")))]), _c("div", [_vm._v(_vm._s(_setup.accountInfo.login_account))])]) : [_setup.ENV_TITLE !== "image" ? _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.登录手机号")))]), _setup.accountInfo.login_tel ? _c("div", [_vm._v(_vm._s(_setup.accountInfo.login_tel))]) : _vm._e(), !_setup.accountInfo.login_tel ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _setup.openBindTelOrEmailDialog("tel", "bind");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.绑定")))]) : _vm._e(), _setup.accountInfo.login_tel ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _setup.openBindTelOrEmailDialog("tel", "modify");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.修改")))]) : _vm._e()], 1) : _vm._e(), _setup.ENV_TITLE !== "image" ? _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.登录邮箱")))]), _setup.accountInfo.login_email ? _c("div", [_vm._v(" " + _vm._s(_setup.accountInfo.login_email) + " ")]) : _vm._e(), !_setup.accountInfo.login_email ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _setup.openBindTelOrEmailDialog("email", "bind");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.绑定")))]) : _vm._e(), _setup.accountInfo.login_email ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _setup.openBindTelOrEmailDialog("email", "modify");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.修改")))]) : _vm._e()], 1) : _vm._e()], _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.登录密码")))]), _setup.isTwoFactor || _setup.isSetPassword ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _setup.openUpdatePasswordDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("management.修改")))]) : _c("div", [_vm._v(" " + _vm._s(_vm.$t("management.您未设置登录密码，请")) + " "), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _setup.openUpdatePasswordDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("management.设置")))])], 1)], 1), _setup.isTwoFactor ? _c("div", {
    staticClass: "info-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("management.验证邮箱")))]), _c("div", [_vm._v(_vm._s(_setup.accountInfo.verify_email))])]) : _vm._e()], 2), _setup.bindTelOrEmailDialogVisible ? _c(_setup.BindTelOrEmailDialog, {
    attrs: {
      visible: _setup.bindTelOrEmailDialogVisible,
      type: _setup.who,
      tel: _setup.accountInfo.login_tel,
      email: _setup.accountInfo.login_email,
      operate: _setup.operate
    },
    on: {
      "update:visible": function ($event) {
        _setup.bindTelOrEmailDialogVisible = $event;
      },
      updateEmailAccountSuccess: _setup.getLatestData,
      updateTelAccountSuccess: _setup.getLatestData
    }
  }) : _vm._e(), _setup.updatePasswordDialogVisible ? _c(_setup.UpdatePasswordDialog, {
    attrs: {
      type: _setup.updatePasswordType,
      "dialog-visible": _setup.updatePasswordDialogVisible,
      tel: _setup.accountInfo.login_tel,
      email: _setup.accountInfo.login_email,
      "verify-email": _setup.accountInfo.verify_email
    },
    on: {
      updatePasswordSuccess: _setup.checkIsSetPasswordApi,
      closeUpdatePasswordDialog: _setup.closeUpdatePasswordDialog
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;