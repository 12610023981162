"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "catalog-select"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_c("p", {
    staticClass: "main-title"
  }, [_c("span", [_vm._v("知识库")]), _c("KnowledgeBaseDropdown")], 1), _vm.catalogData.length ? _c("p", {
    staticClass: "sub-title"
  }, [_vm._v("目录")]) : _vm._e()]), _vm.hasCatalog && _vm.hasKnowledgesSearch ? _c("knowledge-search", _vm._b({
    on: {
      search: _vm.searchKnowledge
    }
  }, "knowledge-search", _vm.$attrs, false)) : _vm._e(), _c("knowledge-library-catalog", _vm._g(_vm._b({
    attrs: {
      draggable: false,
      "catalog-data": _vm.catalogData,
      "current-node": _vm.currentNode,
      "show-operation-btns": false
    }
  }, "knowledge-library-catalog", _vm.$attrs, false), _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;