"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-event-management"
  }, [_c(_setup.KeyEventManagementHeader, {
    ref: "keyEventManagementHeaderRef"
  }), _c("div", {
    staticClass: "key-event-management__content"
  }, [_c("aside", {
    staticClass: "event-panel"
  }, [_c("div", {
    staticClass: "event-panel__header"
  }, [_c("div", {
    staticClass: "all-event-button-row",
    class: {
      active: !_setup.currentEvent.id
    },
    on: {
      click: _setup.getAllTableData
    }
  }, [_c("i", {
    staticClass: "iconfont icon-receiving"
  }), _vm._v("全部关键事件 ")]), _c("el-divider")], 1), _c("div", {
    staticClass: "event-tree-container"
  }, [_c("div", {
    staticClass: "event-tree__title"
  }, [_vm._m(0), _c("el-tooltip", {
    attrs: {
      content: "新建文件夹",
      placement: "top"
    }
  }, [_c("MgvButton", {
    attrs: {
      color: "info",
      plain: "",
      type: "text",
      icon: "icon-plus",
      size: "small"
    },
    on: {
      click: function ($event) {
        return _setup.openCreateKeyEventFolderDialog("0");
      }
    }
  })], 1)], 1), _c(_setup.EventTree, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.eventTreeLoading,
      expression: "eventTreeLoading"
    }],
    ref: "eventTreeRef",
    attrs: {
      "current-event": _setup.currentEvent,
      data: _setup.eventTree
    },
    on: {
      "update:currentEvent": function ($event) {
        _setup.currentEvent = $event;
      },
      "update:current-event": function ($event) {
        _setup.currentEvent = $event;
      },
      createSuccess: _setup.getAllKeyEventList,
      createFolder: _setup.openCreateKeyEventFolderDialog,
      deleteSuccess: _setup.initEventAndTable,
      nodeClick: _setup.handleTreeNodeClick,
      createKeyEvent: _setup.onCreateKeyEvent
    }
  })], 1)]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "event-table-part"
  }, [_c("div", {
    staticClass: "event-table__top"
  }, [_c("h1", {
    staticClass: "current-event-title"
  }, [_vm._v(" " + _vm._s(_setup.currentEvent.name) + " ")]), !_setup.currentEvent.is_leaf && _setup.currentEvent.event_type !== "system" ? _c("div", [_c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _setup.handleCommand
    }
  }, [_c("MgvButton", {
    attrs: {
      type: "primary",
      size: "medium",
      color: "info",
      icon: "icon-plus"
    }
  }, [_c("span", [_vm._v("新建")]), _vm._v(" "), _c("i", {
    staticClass: "iconfont icon-arrow-down"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "createKeyEvent",
      icon: "iconfont icon-key-event"
    }
  }, [_vm._v("新建关键事件")]), _c("el-dropdown-item", {
    attrs: {
      command: "createFolder",
      icon: "iconfont icon-folder-add"
    }
  }, [_vm._v("新建文件夹")])], 1)], 1)], 1) : _vm._e()]), _c("div", {
    staticClass: "event-table__content"
  }, [_setup.eventFolderList && _setup.eventFolderList.length ? _c(_setup.KeyEventFolderListView, {
    attrs: {
      "folder-list": _setup.eventFolderList
    },
    on: {
      onFolderClick: _setup.handleFolderClick
    }
  }) : _vm._e(), _c("div", {
    staticClass: "event-table__header"
  }, [_c(_setup.EventTableFilter, {
    ref: "eventFiltersRef",
    attrs: {
      "batch-events-list": _setup.batchEventsList
    },
    on: {
      filterChange: _setup.handleTableFilterChange,
      batchSetKeyEventApplyPermission: function ($event) {
        return _setup.batchSetKeyEventApplyPermission(_setup.batchEventsList);
      }
    }
  }, [_setup.unsetModelCount ? _c("el-tag", {
    staticClass: "unset-model",
    attrs: {
      type: "warning",
      effect: _setup.unsetModelTagClosable ? "dark" : "light",
      closable: _setup.unsetModelTagClosable
    },
    on: {
      click: _setup.handleTagClick,
      close: _setup.handleTagClick
    }
  }, [_vm._v(_vm._s(_setup.unsetModelCount) + "个关键事件无模型")]) : _vm._e()], 1)], 1), _c(_setup.EventTable, {
    ref: "eventTableRef",
    attrs: {
      "table-data": _setup.tableData
    },
    on: {
      moveEvent: _setup.onMoveEvent,
      editEvent: _setup.editEvent,
      deleteEvent: _setup.onDeleteEvent,
      setKeyEventApplyPermission: _setup.onSetKeyEventApplyPermission,
      selectionChange: _setup.handleSelectionChange,
      refreshTableData: _setup.refreshTableData,
      refreshTaskProgress: _setup.refreshTaskProgress,
      setModel: _setup.onSetModel
    }
  })], 1)])]), _setup.createKeyEventDialogVisible ? _c(_setup.CreateKeyEventDialog, {
    attrs: {
      visible: _setup.createKeyEventDialogVisible,
      "current-key-event": _setup.currentRow,
      "submit-loading": _setup.submitCreateKeyEventLoading
    },
    on: {
      "update:visible": function ($event) {
        _setup.createKeyEventDialogVisible = $event;
      },
      refreshTable: _setup.refreshTableData,
      submit: _setup.submitCreateKeyEvent
    }
  }) : _vm._e(), _c(_setup.SetModelDialog, {
    attrs: {
      visible: _setup.setModelVisible,
      "event-info": _setup.currentRow
    },
    on: {
      "update:visible": function ($event) {
        _setup.setModelVisible = $event;
      },
      submitSuccess: _setup.refreshTableData
    }
  }), _c(_setup.MoveEventDialog, {
    attrs: {
      visible: _setup.moveEventVisible,
      event: _setup.currentRow,
      "event-tree": _setup.eventTree
    },
    on: {
      "update:visible": function ($event) {
        _setup.moveEventVisible = $event;
      },
      moveSuccess: _setup.initEventAndTable
    }
  }), _setup.setKeyEventApplyPermissionVisible ? _c(_setup.SetKeyEventApplyPermission, {
    attrs: {
      "drawer-visible": _setup.setKeyEventApplyPermissionVisible,
      "event-ids": _setup.eventIds,
      "is-batch": _setup.isBatch,
      "is-system-event": _setup.isSystemEvent,
      "has-enable-key-event": _setup.hasEnableKeyEvent,
      "has-set-model": _setup.hasSetModel
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.setKeyEventApplyPermissionVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.setKeyEventApplyPermissionVisible = $event;
      },
      refreshTableData: _setup.refreshTableData,
      refreshTaskProgress: _setup.refreshTaskProgress
    }
  }) : _vm._e(), _setup.createKeyEventFolderDialogVisible ? _c(_setup.CreateKeyEventFolderDialog, {
    attrs: {
      visible: _setup.createKeyEventFolderDialogVisible,
      "create-folder-pid": _setup.createFolderPid
    },
    on: {
      "update:visible": function ($event) {
        _setup.createKeyEventFolderDialogVisible = $event;
      },
      createFolderSuccess: _setup.onCreateFolderSuccess
    }
  }) : _vm._e(), _c(_setup.MegaTranGuide)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("i", {
    staticClass: "iconfont icon-catalogue"
  }), _vm._v(" "), _c("span", [_vm._v("关键事件树")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;