"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LearnContent = _interopRequireDefault(require("./LearnContent.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _vuex = require("vuex");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CourseInfo',
  components: {
    LearnContent: _LearnContent.default
  },
  props: {
    scriptVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      courseInfoConfig: {
        name: '',
        practice_rule: {
          repeat_count: 1,
          score: 60,
          is_closed_exam: false
        }
      },
      confirmLoading: false,
      loading: false,
      isCheckingKnowledgeScript: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript']),
    practiceRule() {
      const courseType = [this.$t('coaching.all'), '朗读', '背诵'];
      return courseType[this.type];
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getCourseDetail();
    }
  },
  methods: {
    scrollToCourseInfoEnd() {
      this.$nextTick(() => {
        this.$refs.courseForm.scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
      });
    },
    scrollToCourseInfoTop() {
      this.$nextTick(() => {
        this.$refs.courseForm.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    async getCourseDetail() {
      this.loading = true;
      const res = await (0, _coachingCenter.getCourseDetail)({
        id: this.$route.query.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          name,
          practice_rule,
          content
        } = res.results;
        this.courseInfoConfig = {
          name,
          practice_rule
        };
        if (!this.courseInfoConfig.practice_rule.repeat_count) {
          this.courseInfoConfig.practice_rule.repeat_count = 1;
        }
        _store.default.commit('training/SET_KNOWLEDGE_SCRIPT', content);
      }
    },
    saveCourse() {
      this.isCheckingKnowledgeScript = true;
      if (!this.courseInfoConfig.name) {
        this.scrollToCourseInfoTop();
      }
      this.$refs['form'].validate(async valid => {
        if (valid && this.checkedKnowledgeScript.length && this.courseInfoConfig.name) {
          this.confirmLoading = true;
          const isEdit = this.$route.query.id;
          const content = this.$refs.learnContent.localCheckedKnowledgeScript.map(_ref => {
            let {
              knowledge_id,
              catelog_id
            } = _ref;
            return {
              catelog_id,
              knowledge_id
            };
          });
          const params = {
            category_id: Number(this.$route.query.categoryId),
            ...this.courseInfoConfig,
            content,
            type: this.type // 1:话术朗读; 2:背诵; 3:智能对练; 4:AI客户(Alpha), 5:AI客户, 6:AI客户加强版
          };

          if (isEdit) params.id = Number(this.$route.query.id);
          const requestFunction = isEdit ? _coachingCenter.updateCourse : _coachingCenter.createCourse;
          const res = await requestFunction(params).finally(() => {
            this.confirmLoading = false;
          });
          if (res.code === 20000) {
            this.$message.success(isEdit ? '编辑成功' : '新建成功');
            // 返回课程页面;
            this.$router.go(-1);
            _store.default.commit('training/SET_KNOWLEDGE_SCRIPT', []);
          }
        }
      });
    },
    addScript() {
      this.$emit('update:scriptVisible', true);
      this.$emit('addScript');
    },
    cancelEdit() {
      this.$emit('goBack');
    }
  }
};
exports.default = _default;