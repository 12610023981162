"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TranserDepartmentFinder = _interopRequireDefault(require("./TranserDepartmentFinder"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TranserDepartmentFinderDialog',
  components: {
    TranserDepartmentFinder: _TranserDepartmentFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    defaultChecked: {
      type: Array,
      default: () => []
    },
    manageTree: {
      type: Array,
      default: () => []
    },
    remoteMethod: {
      type: Function,
      default: _permissions.getDepartmentWithAuth
    },
    title: {
      type: String,
      default: '编辑管理范围'
    },
    noPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAllDepartment: true,
      selection: [],
      checkedIdList: [],
      rootTree: []
    };
  },
  watch: {
    visible(val) {
      if (!val) return;
      this.checkedIdList = this.defaultChecked.map(item => item.id);
      if (this.defaultChecked.some(item => item.fid === 0)) {
        this.isAllDepartment = true;
      } else {
        this.isAllDepartment = false;
      }
    }
  },
  methods: {
    getDepartmentWithAuth: _permissions.getDepartmentWithAuth,
    handleClose() {
      this.$emit('update:visible', false);
    },
    closedDialog() {
      this.isAllDepartment = true;
    },
    getManageTree(manageTree) {
      this.rootTree = manageTree;
    },
    async submit() {
      if (!this.noPermission) {
        this.rootTree = this.manageTree.filter(item => item.fid === 0);
      }
      if (this.isAllDepartment) {
        this.$emit('submit', this.rootTree);
      } else {
        this.$emit('submit', this.selection);
      }
      this.handleClose();
    },
    handleChange(selection, checkedIdList) {
      this.selection = selection;
      this.checkedIdList = checkedIdList;
    }
  }
};
exports.default = _default;