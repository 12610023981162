"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CompliancePersonalTable',
  components: {
    ComplianceBaseTable: _ComplianceBaseTable.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    scoreRuleList: {
      type: Array,
      default: () => []
    },
    evaluationItemList: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => [{}]
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    },
    tableHeader: {
      type: Array,
      default: () => [{
        label: '会话名称',
        prop: 'conv_name',
        width: 260
      }, {
        label: '质检状态',
        prop: 'status',
        width: 160
      }]
    },
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    goComplianceWorkSpace(row) {
      this.$emit('goComplianceWorkSpace', row);
    }
  }
};
exports.default = _default;