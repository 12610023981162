"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nanoid = require("nanoid");
var _vue = require("vue");
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'GeneratorCascader',
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  emits: ['dragEnd'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const renders = (0, _vue.ref)([]);
    const getRenders = function (tree) {
      let renders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      let _index = index;
      if (!index) {
        renders.push(tree);
        _index++;
      }
      const activeItem = tree.find(item => item.is_active);
      if (!activeItem) return renders;
      renders[_index] = activeItem.children || [];
      return getRenders(activeItem.children || [], renders, _index + 1);
    };
    (0, _vue.watch)(() => props.options, val => {
      renders.value = getRenders(val);
    }, {
      immediate: true
    });
    const NUMS_TRANS = ['一', '二', '三', '四'];
    const handleActive = (column, id, index) => {
      column.forEach(item => {
        item.is_active = item.id === id;
      });
      renders.value = getRenders(props.options);
    };
    const isColumnScoreDisable = column => {
      return column.every(item => item.children && item.children.length);
    };
    const handleScoreInput = (val, item) => {
      if (Number(val) > 999) {
        item.score = 999;
      } else if (Number(val) < -999) {
        item.score = -999;
      } else {
        item.score = val;
      }
    };
    const addOptionItem = column => {
      column.push({
        name: `选项${column.length + 1}`,
        option_order: column.length + 1,
        id: (0, _nanoid.nanoid)(),
        score: '',
        children: []
      });
      console.log(props.options);
    };
    const deleteOptionItem = (column, index) => {
      column.splice(index, 1);
      renders.value = getRenders(props.options);
      console.log(props.options);
    };
    const deleteColumn = column => {
      column.splice(0, column.length);
      renders.value = getRenders(props.options);
    };
    const handDragEnd = (list, columnIndex) => {
      list.forEach((item, index) => {
        item.option_order = index + 1;
      });
      if (columnIndex === 0) {
        emit('dragEnd', list);
      } else {
        const activeItem = renders.value[columnIndex - 1].find(item => item.is_active);
        activeItem.children = list;
        renders.value = getRenders(props.options);
      }
    };
    return {
      __sfc: true,
      props,
      renders,
      emit,
      getRenders,
      NUMS_TRANS,
      handleActive,
      isColumnScoreDisable,
      handleScoreInput,
      addOptionItem,
      deleteOptionItem,
      deleteColumn,
      handDragEnd,
      MgDraggable: _MgDraggable.default
    };
  }
};
exports.default = _default;