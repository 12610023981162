"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'TrailResultsHeader',
  props: {
    tableSelection: {
      type: Array,
      default: () => []
    }
  },
  emits: ['updateTrainDataStatus', 'filterChange', 'clearAnnotationResult'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const contentQuery = (0, _vue.ref)('');
    const isHit = (0, _vue.ref)('');
    const isRight = (0, _vue.ref)('');
    const isAnnotated = (0, _vue.ref)('');
    const dataStatusButtons = (0, _vue.ref)([{
      status: 1,
      text: '命中'
    }, {
      status: 2,
      text: '未命中'
    }, {
      status: 3,
      text: '不确定'
    }]);
    const onChange = () => {
      const filters = {
        content: contentQuery.value,
        predict_res: isHit.value || 0,
        rule_result: isRight.value || 0,
        status: isAnnotated.value || 0
      };
      emit('filterChange', filters);
    };
    const updateTrainDataStatus = status => {
      emit('updateTrainDataStatus', props.tableSelection, status);
    };
    const clearAnnotationResult = () => {
      emit('clearAnnotationResult', props.tableSelection);
    };
    return {
      __sfc: true,
      props,
      contentQuery,
      isHit,
      isRight,
      isAnnotated,
      dataStatusButtons,
      onChange,
      emit,
      updateTrainDataStatus,
      clearAnnotationResult
    };
  }
};
exports.default = _default;