"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _default = (0, _vue.defineComponent)({
  name: 'StartNode',
  data() {
    return {
      roleName: (0, _i18n.t)('coaching.start')
    };
  }
});
exports.default = _default;