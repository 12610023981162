"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ScoreRuleEnableDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    confirmBtnLoading: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'conv'
    }
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false);
    },
    dialogSubmit() {
      this.$emit('dialogSubmit');
    }
  }
};
exports.default = _default;