"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conversation-detail-account-tag"
  }, [!_vm.activeAccountTagId ? _c("events-list", {
    attrs: {
      "event-engine": _vm.accountTagList
    },
    on: {
      tagClick: _vm.handleTagClick
    }
  }) : _vm._e(), !_vm.accountTagCount && !_vm.loading ? _c("NoData", {
    attrs: {
      tips: "暂无客户标签",
      size: "120"
    }
  }) : _vm._e(), _vm.activeAccountTagId && _vm.activeAccountTagData ? _c("div", {
    staticClass: "key-event-detail"
  }, [_c("div", {
    staticClass: "key-event-detail-header"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: _vm.handleBackUp
    }
  }), _c("span", [_vm._v(_vm._s(_vm.activeAccountTag.name) + "(" + _vm._s(_vm.activeAccountTag.total) + ")")])]), _c("key-event-detail-list", {
    ref: "key-event-detail-list",
    attrs: {
      "media-type": _vm.mediaType,
      list: _vm.activeAccountTagData,
      participants: _vm.participantsInfo
    }
  })], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;