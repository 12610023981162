"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createInstruction = createInstruction;
exports.createScheduleFilter = createScheduleFilter;
exports.deleteInstruction = deleteInstruction;
exports.deleteScheduleFilter = deleteScheduleFilter;
exports.getInstructionResult = getInstructionResult;
exports.getInstructionSetList = getInstructionSetList;
exports.getKeyEventConfig = getKeyEventConfig;
exports.getMySchedule = getMySchedule;
exports.getScheduleDetail = getScheduleDetail;
exports.getScheduleFilter = getScheduleFilter;
exports.getTeamSchedule = getTeamSchedule;
exports.runInstruction = runInstruction;
exports.updateInstruction = updateInstruction;
exports.updateKeyEventConfig = updateKeyEventConfig;
exports.updateScheduleFilter = updateScheduleFilter;
var _request = require("@/utils/request");
// 获取团队日程
function getTeamSchedule(data) {
  return (0, _request.webService)({
    url: '/schedule/team_view',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 后端已停用，前端暂时注释
// //获取单个成员日程
// export function getOneSchedule(params) {
//   return request({
//     url: '/schedule/schedule/individual_view',
//     method: 'get',
//     params
//   })
// }
// 获取我的日程
function getMySchedule(params) {
  return (0, _request.webService)({
    url: '/schedule/my_view',
    method: 'get',
    params
  });
}

// 获取日程详情
function getScheduleDetail(params) {
  return (0, _request.webService)({
    url: '/schedule/conversation/info',
    method: 'get',
    params
  });
}

// 获取关键事件配置
function getKeyEventConfig(params) {
  return (0, _request.webService)({
    url: '/schedule/event_config/get',
    method: 'get',
    params
  });
}

// 更新关键事件配置
function updateKeyEventConfig(data) {
  return (0, _request.webService)({
    url: '/schedule/event_config/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 日程常用筛选器-获取
function getScheduleFilter(data) {
  return (0, _request.webService)({
    url: '/schedule_filter/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 日程常用筛选器-创建
function createScheduleFilter(data) {
  return (0, _request.webService)({
    url: '/schedule_filter/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 日程常用筛选器-更新
function updateScheduleFilter(data) {
  return (0, _request.webService)({
    url: '/schedule_filter/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 日程常用筛选器-删除
function deleteScheduleFilter(data) {
  return (0, _request.webService)({
    url: '/schedule_filter/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

/** 指令集相关接口 */
// 获取指令集列表
function getInstructionSetList(params) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/list',
    method: 'get',
    params
  });
}
// 创建指令集
function createInstruction(data) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 更新指令集
function updateInstruction(data) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除指令集
function deleteInstruction(data) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取指令结果
function getInstructionResult(params) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/result',
    method: 'get',
    params
  });
}

// 主动运行指令
function runInstruction(data) {
  return (0, _request.webService)({
    url: '/schedule/instruction_set/run',
    method: 'post',
    dataType: 'json',
    data
  });
}