"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _vue = require("vue");
var _CreateFolderDialog = _interopRequireDefault(require("./CreateFolderDialog.vue"));
var _useSelectBottomAction = _interopRequireDefault(require("@/hooks/use-select-bottom-action"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'FolderSelect',
  props: {
    externalFolderId: {
      type: Number,
      default: 0
    }
  },
  emits: ['folderChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const folder_id = (0, _vue.ref)('');
    const createFolderDialogVisible = (0, _vue.ref)(false);
    const openCreateFolderDialog = () => {
      createFolderDialogVisible.value = true;
    };
    const folderList = (0, _vue.ref)([]);
    const getFolderListApi = async val => {
      const res = await (0, _dataSet.getFolderList)({
        name: val
      });
      if (res.code === 20000) {
        folderList.value = res.results.items;
      }
    };
    const handleFolderChange = val => {
      emit('folderChange', folder_id.value);
    };
    const createDataSetFolderSuccess = val => {
      folderList.value.unshift(val);
      folder_id.value = val.id;
      handleFolderChange();
    };
    const folderSelectRef = (0, _vue.ref)(null);
    const handleVisibleChange = v => {
      (0, _useSelectBottomAction.default)(v, {
        ref: folderSelectRef,
        label: '新建文件夹',
        icon: 'iconfont icon-plus',
        click: openCreateFolderDialog
      });
    };
    (0, _vue.onMounted)(() => {
      getFolderListApi();
    });
    const initFolderId = () => {
      if (!props.externalFolderId) return;
      folder_id.value = props.externalFolderId;
    };
    (0, _vue.watchEffect)(() => {
      initFolderId();
    });
    return {
      __sfc: true,
      props,
      folder_id,
      emit,
      createFolderDialogVisible,
      openCreateFolderDialog,
      folderList,
      getFolderListApi,
      handleFolderChange,
      createDataSetFolderSuccess,
      folderSelectRef,
      handleVisibleChange,
      initFolderId,
      CreateFolderDialog: _CreateFolderDialog.default
    };
  }
};
exports.default = _default;