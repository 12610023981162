"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      "custom-class": "client-detail-drawer",
      visible: _vm.visible,
      "before-close": _vm.closeDrawer
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("header", {
          staticClass: "drawer-header"
        }, [_c("h1", [_vm._v(_vm._s(_vm.title))])])];
      },
      proxy: true
    }])
  }, [_c("detail-drawer", {
    attrs: {
      info: _vm.detailInfo,
      "operate-button": _vm.operateButton
    },
    on: {
      detailOperations: _vm.detailOperations
    }
  }, [_c("div", {
    staticClass: "client-tab",
    attrs: {
      slot: "default"
    },
    slot: "default"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "客户动态",
      name: "track_record"
    }
  }, [_c("div", {
    staticClass: "dynamic-records-tabs"
  }, [_c("div", {
    staticClass: "records-tabs followup-records",
    class: _vm.recordsActiveName === "followup_records" ? "record-active" : "",
    on: {
      click: function ($event) {
        return _vm.switchRecordsTab("followup_records");
      }
    }
  }, [_vm._v(" 跟进记录 ")])]), _vm.recordsActiveName === "followup_records" ? _c("conversation-detail", {
    attrs: {
      "account-id": _vm.id
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "详细信息",
      name: "client"
    }
  }, [_c("client-form", {
    attrs: {
      "account-id": _vm.accountId,
      "form-data": _vm.formData,
      visible: _vm.visible,
      "is-call-up-show": _vm.isCallUpAndCreateDealShow
    },
    on: {
      freshCurrentList: _vm.freshCurrentList,
      freshClientDetail: _vm.freshClientDetail
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "相关商机",
      name: "deal"
    }
  }, [_c("deal-detail", {
    attrs: {
      "deal-data": _vm.dealData,
      "is-create-deal-show": _vm.isCallUpAndCreateDealShow
    },
    on: {
      createDeal: _vm.createDeal
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "联系人",
      name: "contactPerson"
    }
  }, [_vm.activeName === "contactPerson" ? _c("contact-person-detail", {
    attrs: {
      "account-id": _vm.id,
      "form-desc": _vm.descContactFormat(_vm.contactDynamicFormNoAccountList, false),
      "is-show": _vm.isCallUpAndCreateDealShow
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "操作日志",
      name: "clientLog"
    }
  }, [_vm.activeName === "clientLog" ? _c("log-record", {
    attrs: {
      id: _vm.id,
      "page-size": _vm.pageSize,
      "log-item-properties": _vm.logItemProperties,
      "fetch-log-method": _vm.fetchLogMethod()
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          item
        } = _ref;
        return [item.type === "move_to_pool" ? _c("div", {
          staticClass: "subcontent move-reason"
        }, [_c("span", [_vm._v("退回原因：")]), _vm._v(_vm._s(item.reason) + " ")]) : _vm._e(), item.type === "move_to_pool" ? _c("div", {
          staticClass: "subcontent move-remark"
        }, [_c("span", [_vm._v("备注：")]), _vm._v(_vm._s(item.add_reason || "--") + " ")]) : _vm._e(), item.type === "assign" || item.type === "modify" ? _c("div", {
          staticClass: "subcontent other-followup"
        }, [_c("span", [_vm._v("跟进人：")]), item.per_follow_up_by ? _c("span", {
          staticClass: "pre-followup"
        }, [_vm._v(_vm._s(item.per_follow_up_by.name)), _c("i", {
          staticClass: "iconfont icon-right"
        })]) : _vm._e(), _vm._v(_vm._s(item.follow_up_by.name) + " ")]) : _vm._e(), item.type === "create_deal" ? _c("div", {
          staticClass: "subcontent create-deal"
        }, [_c("span", [_vm._v("商机名称：")]), _vm._v(_vm._s(item.deal_name) + " ")]) : _vm._e(), item.type === "create_contact" || item.type === "edit_contact" ? _c("div", {
          staticClass: "subcontent create_contact"
        }, [_c("span", [_vm._v("联系人：")]), _c("span", [_vm._v(_vm._s(item.contact.name))])]) : _vm._e(), item.type === "converted" ? _c("div", {
          staticClass: "subcontent create-deal"
        }, [_c("span", [_vm._v("来自线索：")]), _vm._v(_vm._s(item.lead_name) + " ")]) : _vm._e(), _c("div", {
          staticClass: "subcontent handler"
        }, [_c("span", [_vm._v("操作人：")]), _c("span", [_vm._v(_vm._s(item.handler.name))])])];
      }
    }], null, false, 4293630413)
  }) : _vm._e()], 1)], 1)], 1)]), _vm.contactPersonDialogVisible ? _c("contact-person-dialog", {
    ref: "createContactPersonDialog",
    attrs: {
      "client-detail-drawer-visible": _vm.visible,
      "account-id": _vm.id,
      "contact-form-desc": _vm.contactFormDesc,
      "contact-person-dialog-visible": _vm.contactPersonDialogVisible
    },
    on: {
      closeDialog: _vm.closeCreateContactPersonDialog
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;