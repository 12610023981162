"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserInfoCard',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getMainDepartmentInfo() {
      const {
        departments
      } = this.userInfo;
      return departments ? departments.find(_ref => {
        let {
          id
        } = _ref;
        return id === this.userInfo.main_department;
      }) : {};
    }
  }
};
exports.default = _default;