"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c(_setup.TaskDetailFIlters, {
    on: {
      change: _setup.onFilterChange,
      exportTable: _setup.exportTable
    }
  }), _c(_setup.TaskDetailTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _setup.taskList
    },
    on: {
      "sort-change": _setup.sortChange
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      total: _setup.total,
      "current-page": _setup.listParams.page,
      "page-size": _setup.listParams.size
    },
    on: {
      sizeChange: _setup.sizeChange,
      currentChange: _setup.currentChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;