"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabArray, function (item) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.label,
        name: item.name
      }
    }, [item.name === _vm.activeName ? _c("conv-list", {
      attrs: {
        "conv-list": _vm.convList,
        nomore: _vm.nomore,
        loading: _vm.loading,
        "list-loading": _vm.listLoading,
        "active-name": _vm.activeName
      },
      on: {
        loadMore: _vm.loadMore
      }
    }) : _vm._e()], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;