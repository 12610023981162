"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-guide"
  }, [_c("p", {
    staticClass: "hello"
  }, [_vm._v(_vm._s(_vm.helloText))]), _vm._l(_vm.guideList, function (item) {
    return _c("p", {
      key: item,
      staticClass: "guide-msg",
      on: {
        click: function ($event) {
          return _vm.handeClick(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-dot-2"
    }), _c("span", [_vm._v(_vm._s(item))])]);
  }), _vm.guideTips ? _c("p", {
    staticClass: "tips"
  }, [_vm._v(" " + _vm._s(_vm.guideTips) + " ")]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;