"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PersonalPlanHeader = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/PersonalPlanHeader"));
var _PersonalPlanTable = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/PersonalPlanTable"));
var _LeaderPlanHeaderFilter = _interopRequireDefault(require("../leaderPlan/LeaderPlanHeaderFilter.vue"));
var _PracticeKnowledgeDrawer = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/PracticeKnowledgeDrawer.vue"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachingSalesPlan',
  components: {
    PersonalPlanHeader: _PersonalPlanHeader.default,
    LeaderPlanHeaderFilter: _LeaderPlanHeaderFilter.default,
    PersonalPlanTable: _PersonalPlanTable.default,
    PracticeKnowledgeDrawer: _PracticeKnowledgeDrawer.default
  },
  data() {
    return {
      requestParams: {
        id: 0,
        user_id: 0,
        page: 1,
        size: 10,
        order_field: '',
        order_type: '',
        is_complete: -1
      },
      totalCount: 0,
      tableData: [],
      tableLoading: false,
      tableHeight: document.documentElement.clientHeight - 200,
      taskInfo: {},
      taskScoreInfo: {},
      practiceDrawerVisible: false,
      acticeDrawerKnowledgeId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'knowledgeDeleted'])
  },
  watch: {
    knowledgeDeleted(val) {
      if (val) {
        this.$message.warning(this.$t('coaching.deletedTask'));
      }
    }
  },
  created() {
    var _this$$route$query;
    this.requestParams.id = ((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.taskId) - 0;
    this.requestParams.user_id = this.userId;
    this.getTrainPlan({
      id: this.requestParams.id
    });
    this.getTaskScore({
      id: this.requestParams.id,
      user_id: this.userId
    });
    this.getSalesKnowledgeReplyDetail();
  },
  methods: {
    // 我的任务已完成的，重练一遍
    goPractice() {
      const {
        course_type,
        name,
        status,
        practice_rule
      } = this.taskInfo;
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        type: course_type,
        taskId: this.$route.query.taskId,
        knowledgeId: this.tableData[0].plan_knowledge_id,
        taskName: name,
        taskStatus: status,
        score: practice_rule === null || practice_rule === void 0 ? void 0 : practice_rule.score
      }));
      this.$router.push({
        path: _routeMap.ROUTES.practiceKnowledge,
        query: {
          taskId: this.$route.query.taskId
        }
      });
    },
    openPracticeDrawer(row) {
      this.acticeDrawerKnowledgeId = row.plan_knowledge_id;
      this.practiceDrawerVisible = true;
    },
    async getTaskScore(data) {
      const res = await (0, _coachingCenter.getTaskScore)(data);
      if (res.code === 20000) {
        this.taskScoreInfo = res.results.data;
      }
    },
    async getTrainPlan(data) {
      const res = await (0, _coachingCenter.getTrainPlan)(data);
      if (res.code === 20000) {
        this.taskInfo = res.results.data;
      }
    },
    async getSalesKnowledgeReplyDetail() {
      try {
        this.tableLoading = true;
        if (this.requestParams.is_complete === '') {
          this.requestParams.is_complete = -1;
        }
        this.requestParams.id = Number(this.requestParams.id);
        this.requestParams.user_id = Number(this.requestParams.user_id);
        const res = await (0, _coachingCenter.getSalesKnowledgeReplyDetail)(this.requestParams);
        if (res.code === 20000) {
          this.tableData = res.results.data;
          this.totalCount = res.results.total_count;
        }
      } finally {
        this.tableLoading = false;
      }
    },
    initData() {
      this.requestParams.page = 1;
      this.tableLoading = false;
      this.totalCount = 0;
      this.tableData = [];
    },
    tableSizeChange(val) {
      this.requestParams.size = val;
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    },
    tableCurrentPageChange(val) {
      this.requestParams.page = val;
      this.getSalesKnowledgeReplyDetail();
    },
    filterChange(val) {
      this.requestParams = {
        ...this.requestParams,
        ...val
      };
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    },
    tableSortChange(val) {
      this.requestParams.order_field = val.prop;
      this.requestParams.order_type = val.order;
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    }
  }
};
exports.default = _default;