"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    isMembers: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    percentage(val) {}
  }
};
exports.default = _default;