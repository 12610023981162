"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'WorkspaceBadge',
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  }
};
exports.default = _default;