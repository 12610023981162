"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "node-sidebar"
  }, [_c("div", {
    staticClass: "node-container",
    attrs: {
      "data-v-step": "nodeSidebar"
    }
  }, [_c("h1", {
    staticClass: "sidebar-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.addNode")))]), _vm._l(_vm.nodelist, function (node) {
    return _c("div", {
      key: node.value,
      staticClass: "node-item",
      on: {
        mousedown: function ($event) {
          return _vm.$emit("node-mouse-down", node.value, $event);
        }
      }
    }, [_c("i", {
      class: ["iconfont", `icon-${node.icon}`]
    }), _c("span", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t(node.label)))]), _c("i", {
      staticClass: "iconfont icon-drag-dot move"
    })]);
  }), _c("div", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/webapi/training/knowledge/list"],
      expression: "['/webapi/training/knowledge/list']"
    }]
  }, [_c("hr"), _c("el-button", {
    staticClass: "add-knowledge-button",
    attrs: {
      type: "text primary-bg",
      size: "default"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("AddScriptThroughKnowledgeBase");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.addThroughKnowledgeBase")))])], 1)], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;