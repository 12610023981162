"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep.js"));
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
var _LinkText = _interopRequireDefault(require("@/components/LinkText"));
var _isUrl = _interopRequireDefault(require("is-url"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    LinkText: _LinkText.default
  },
  mixins: [_formMixin.default],
  props: {},
  data() {
    return {
      newValue: {
        title: '',
        link: ''
      },
      popperValue: {
        title: '',
        link: ''
      },
      linkInputContent: '',
      isPoppershow: false
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          this.newValue = {
            title: '',
            link: ''
          };
        } else {
          this.newValue = val;
        }
      },
      immediate: true
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.newValue);
    },
    handleMainChange(val) {
      // 判断字符串是否为合法URL的正则表达式
      const reg = /^http(s)?:\/\/.+/;
      if (reg.test(val)) {
        if ((0, _isUrl.default)(val)) {
          this.newValue.link = val;
          this.newValue.title = val;
        } else {
          this.newValue.title = val;
        }
      } else {
        const str = 'http://' + val;
        if ((0, _isUrl.default)(str)) {
          this.newValue.link = str;
          this.newValue.title = val;
        } else {
          this.newValue.title = val;
        }
      }
      this.handleInput();
    },
    onPopperShow() {
      this.popperValue = (0, _cloneDeep.default)(this.newValue);
    },
    cancelEdit() {
      this.isPoppershow = false;
      this.onPopperShow();
    },
    confirmEdit() {
      if (this.popperValue.link && !this.popperValue.title) {
        this.popperValue.title = this.popperValue.link;
      }
      this.newValue = (0, _cloneDeep.default)(this.popperValue);
      this.handleInput();
      this.isPoppershow = false;
    },
    handleLinkInput(e) {},
    handleLinkBlur(e) {
      console.log('blurde');
    }
  }
};
exports.default = _default;