"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "events-list"
  }, _vm._l(_vm.eventEngine, function (headEvent, index) {
    return _c("div", {
      key: index,
      staticClass: "event-unit"
    }, [_c("h1", [_vm._v(_vm._s(headEvent.name))]), headEvent.level_count === 3 ? [_c("div", {
      staticClass: "tag-list"
    }, _vm._l(headEvent.children.filter(item => !item.children), function (item) {
      return _c("el-tag", {
        key: item.id,
        class: ["border", "circle", {
          "key-event-high-light": item.highlight
        }],
        attrs: {
          size: _vm.tagSize
        },
        on: {
          click: function ($event) {
            return _vm.$emit("tagClick", item.id, item);
          }
        }
      }, [_vm._v(_vm._s(item.name) + "｜" + _vm._s(item.total))]);
    }), 1), _vm._l(headEvent.children.filter(item => item.children), function (mediumEvent, index) {
      return _c("div", {
        key: index,
        staticClass: "second-tag-list"
      }, [mediumEvent.children ? _c("h2", [_vm._v(_vm._s(mediumEvent.name))]) : _vm._e(), _c("div", {
        staticClass: "tag-list"
      }, _vm._l(mediumEvent.children, function (item) {
        return _c("el-tag", {
          key: item.id,
          class: ["border", "circle", {
            "key-event-high-light": item.highlight
          }],
          attrs: {
            size: _vm.tagSize
          },
          on: {
            click: function ($event) {
              return _vm.$emit("tagClick", item.id, item);
            }
          }
        }, [_vm._v(_vm._s(item.name) + "｜" + _vm._s(item.total))]);
      }), 1)]);
    })] : _c("div", {
      staticClass: "tag-list"
    }, _vm._l(headEvent.children, function (item) {
      return _c("el-tag", {
        key: item.id,
        class: ["border", "circle", {
          "key-event-high-light": item.highlight
        }],
        attrs: {
          size: _vm.tagSize
        },
        on: {
          click: function ($event) {
            return _vm.$emit("tagClick", item.id, item);
          }
        }
      }, [_vm._v(_vm._s(item.name) + "｜" + _vm._s(item.total))]);
    }), 1)], 2);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;