"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LazyTree = _interopRequireDefault(require("./components/LazyTree"));
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MembersFinder',
  components: {
    LazyTree: _LazyTree.default,
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    maxLenth: {
      type: [Number, String],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multipleLimit: {
      type: Number,
      default: Infinity
    },
    organizationId: {
      type: Number,
      default: null
    },
    isSetCheckedRoot: Boolean,
    collapseTags: {
      type: Boolean,
      default: false
    },
    hasSelf: {
      type: Boolean,
      default: true
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    treeConfig: {
      type: Object,
      default: () => ({})
    },
    popperClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    getMemberApiType: {
      // lazytree的属性  请求的成员树是否是权限范围内的，默认是无权限的，返回所有组织架构成员
      type: String,
      default: '' // 默认为空，为无权限成员树。'data'为数据权限成员树。'manage'为管理部门范围内的成员树。
    },

    params: {
      type: [Object, String],
      default: () => ({})
    },
    isRepetitive: {
      // lazyree属性 是否为在一组件中循环多次使用，会影响数据请求，避免重复请求
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    // 选择器回填数据
    memberInfo: {
      type: Array,
      default: () => []
    },
    searchParams: {
      type: Object,
      default: () => ({})
    },
    isShowHaveDeletedMembers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOuterVal: false,
      selection: [],
      selectionObj: [],
      memberOptions: [],
      query: '',
      searchLoading: false,
      organizationIdOuter: null,
      isCheckedDeleted: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['avatar', 'userId', 'name'])
  },
  watch: {
    selection(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) return;
      if (this.isOuterVal) {
        this.isOuterVal = false;
      } else {
        this.selectionObj = this.selectionObj.filter(item => this.selection.includes(item === null || item === void 0 ? void 0 : item.id));
        this.isRadio && this.$refs.selectInput.blur();
        this.$emit('change', [...val], this.selectionObj);
        if (!this.$refs.tree) return;
        const checkNode = this.$refs.tree.checkNode;
        if (val.length > oldVal.length) {
          // 增加选项时
          for (const id of val) {
            checkNode(id, true);
          }
        } else {
          // 删除选项时
          for (const id of oldVal) {
            if (!val.includes(id)) {
              checkNode(id, false);
            }
          }
        }
      }
    },
    organizationId(val, oldVal) {
      if (val !== oldVal) {
        this.organizationIdOuter = val;
      }
    },
    memberInfo: {
      handler(val) {
        this.memberOptions = val;
        this.selection = val.map(_ref => {
          let {
            id
          } = _ref;
          return id;
        });
      },
      immediate: true
    }
  },
  methods: {
    async getMember(query) {
      this.query = query;
      if (!query) return;
      this.searchLoading = true;
      const res = await (0, _conversationList.getHostPersonOrTeam)({
        filter: query,
        ...this.searchParams,
        includes_deleted_members: this.isCheckedDeleted
      });
      this.memberOptions = res.results.users;
      this.searchLoading = false;
    },
    handleFocus() {
      this.query = ''; // 切换到树
      if (this.isRepetitive) {
        this.$refs.tree.getTreeData();
      }
    },
    handleSelectChange(val) {
      if (this.isRadio) this.selection = [val[val.length - 1]];
      const currentData = this.memberOptions.filter(item => {
        if (val.includes(item.id)) {
          return item;
        }
      });
      if (currentData[0]) this.selectionObj.push(currentData[0]);
    },
    handleCheck(data, _ref2) {
      let {
        checkedKeys,
        checkedNodes
      } = _ref2;
    },
    handleCheckChange(_data, isCheck) {
      var _this$treeConfig;
      if (this.isRadio) {
        this.memberOptions = [_data];
        if (isCheck) {
          // 选中则清空选择框选中项
          this.selection = [];
          this.selectionObj = [];
          // 选中则一直切换值到当前
          this.$refs.tree.$refs.tree.setCheckedKeys([_data.id]);
        }
        // 单选状态选择后自动折叠下拉选框
        this.$refs.selectInput.blur();
      }
      const data = JSON.parse(JSON.stringify(_data));
      if (!((_this$treeConfig = this.treeConfig) !== null && _this$treeConfig !== void 0 && _this$treeConfig.noTransId) && !(this.treeConfig.addDepartmentPre && data.id.indexOf('tree_id') !== -1)) {
        data.id = data.id.split('_').pop() - 0;
      }
      if (isCheck) {
        if (!this.selection.includes(data.id)) {
          this.memberOptions = [data];
          this.selection = this.selection.concat([data.id]);
          this.selectionObj = this.selectionObj.concat([data]);
          // this.selection.push(data.id);
        }
      } else {
        this.selection = this.selection.filter(id => id !== data.id);
        this.selectionObj = this.selectionObj.filter(item => item.id !== data.id);
      }
    },
    handleCheckDeleted(val) {
      this.$refs.tree.getTreeData();
      this.$emit('includesDeletedChange', val);
    }
  }
};
exports.default = _default;