"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _MembersTreeSelect = _interopRequireDefault(require("@/components/MgTreeSelect/MembersTreeSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentAccessableMemberDialog',
  components: {
    MembersTreeSelect: _MembersTreeSelect.default
  },
  inject: {
    sendComment: {
      from: 'sendComment',
      default: () => () => {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    footerVisible: {
      type: Boolean,
      default: false
    },
    tipsVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      btnLoading: false,
      memberList: []
    };
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    closeDialog() {
      this.$emit('update:visible', false);
    },
    handleMemberChange(ids, list) {
      this.memberList = list;
      this.$emit('memberChange', ids, list);
    },
    async syncComment() {
      this.btnLoading = true;
      try {
        await this.sendComment();
      } finally {
        this.btnLoading = false;
      }
      this.closeDialog();
    },
    clearMemberSelect() {
      this.$refs.memberSelect && this.$refs.memberSelect.clear();
    }
  }
};
exports.default = _default;