"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "snippet-check-box",
    class: {
      "snippet-check-box-bg": _vm.isInRange,
      "begin-check-box": _vm.isBeginOrder,
      "end-check-box": _vm.isEndOrder,
      "has-avatar": _vm.isShowAvatar
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: _vm.label
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;