"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-drawer", {
    attrs: {
      "custom-class": "trade-detail-drawer",
      visible: _vm.visible,
      "before-close": _vm.closeDrawer,
      size: "700px",
      title: "交易详情",
      "modal-append-to-body": false
    }
  }, [_c("trade-detail-header", {
    staticClass: "trade-detail-header",
    attrs: {
      info: _vm.tradeInfo
    }
  }), _c("detail-drawer", {
    attrs: {
      info: _vm.tradeInfo,
      "operate-button": _vm.operateButton
    },
    on: {
      detailOperations: _vm.detailOperations
    }
  }, [_c("div", {
    staticClass: "deal-detail-tabs",
    attrs: {
      slot: "default"
    },
    slot: "default"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTabName,
      callback: function ($$v) {
        _vm.activeTabName = $$v;
      },
      expression: "activeTabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "详细信息",
      name: "info"
    }
  }, [_vm.activeTabName === "info" ? _c("TradeDetailInfo", {
    attrs: {
      "form-desc": _vm.formDesc,
      value: _vm.formData,
      "deal-id": _vm.tradeId,
      business: _vm.tradeInfo,
      "is-stage-readonly": _vm.isStageReadonly
    },
    on: {
      cancel: _vm.handleCancel,
      handleInput: _vm.handleInput
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "交易联系人",
      name: "account"
    }
  }, [_vm.activeTabName === "account" ? _c("ContactPersonDetail", {
    attrs: {
      "deal-id": _vm.tradeId,
      "form-desc": _vm.descContactFormat(_vm.contactDynamicFormNoAccountList, false)
    }
  }) : _vm._e()], 1)], 1)], 1)]), _c("associate-contact-dialog", {
    ref: "associateContactDialog",
    attrs: {
      "deal-id": _vm.tradeId,
      "associate-contact-dialog-visible": _vm.associateContactDialogVisible,
      "contact-list": _vm.contactList
    },
    on: {
      closeDialog: _vm.closeAssociateContactDialog
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;