import { render, staticRenderFns } from "./main.vue?vue&type=template&id=9d69660e&scoped=true&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./main.vue?vue&type=style&index=0&id=9d69660e&lang=scss&"
import style1 from "./main.vue?vue&type=style&index=1&id=9d69660e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d69660e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9d69660e')) {
      api.createRecord('9d69660e', component.options)
    } else {
      api.reload('9d69660e', component.options)
    }
    module.hot.accept("./main.vue?vue&type=template&id=9d69660e&scoped=true&", function () {
      api.rerender('9d69660e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mgv-ui/packages/department-multi-select/src/main.vue"
export default component.exports