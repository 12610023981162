"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("bip-layout", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "person-name": _vm.personName
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_c("aside-list", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: _vm.convLoading,
            expression: "convLoading"
          }],
          attrs: {
            list: _vm.conversationList,
            "data-type": _vm.convParams.event_engine_filter_id ? "count" : "time",
            "aside-list-type": _vm.asideListType
          }
        }, [_c("div", {
          staticClass: "sort-row"
        }, [_vm._v(" 提及 "), _c("el-select", {
          staticClass: "mg-select",
          attrs: {
            clearable: "",
            placeholder: "请选择关键事件"
          },
          model: {
            value: _vm.convParams.event_engine_filter_id,
            callback: function ($$v) {
              _vm.$set(_vm.convParams, "event_engine_filter_id", $$v);
            },
            expression: "convParams.event_engine_filter_id"
          }
        }, _vm._l(_vm.sortOptions, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: {
              label: item.label,
              value: item.value
            }
          });
        }), 1)], 1)])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "title-line"
  }, [_c("h2", [_vm._v("关键事件")]), _c("h3", [_vm._v(" 被检测到的" + _vm._s(_vm.asideListType === "conversation" ? "会话" : "交易") + "个数占比 ")])]), _c("bi-chart", {
    attrs: {
      "info-array": _vm.infoArray,
      "average-value": _vm.averageValue,
      "horizontal-array": _vm.horizontalArray,
      "small-margin": "",
      "is-label-silent": ""
    },
    on: {
      sortTypeChange: _vm.sortTypeChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;