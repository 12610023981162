"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _default = {
  methods: {
    goConvPage() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversation}`);
    }
  }
};
exports.default = _default;