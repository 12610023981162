"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: _vm.title,
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "80%",
      "custom-class": "create-conv-flow-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "conv-list-page"
  }, [!_vm.noTip ? _c("div", {
    staticClass: "top-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v(" " + _vm._s(_vm.tipContent) + " ")]) : _vm._e(), _c("conv-list-page", {
    class: {
      "no-tip": _vm.noTip
    },
    attrs: {
      "back-fill-filters": _vm.backFillFilters,
      "create-conv-flow-visible": _vm.drawerVisible
    },
    on: {
      convFlowFiltersChange: _vm.convFlowFiltersChange,
      filterNumChange: _vm.filterNumChange
    }
  })], 1)]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    staticClass: "mg-button mg-round-button",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    class: ["mg-button", "mg-round-button", {
      "submit-disabled": !_vm.filterNum
    }],
    attrs: {
      disabled: !_vm.filterNum,
      type: "primary"
    },
    on: {
      click: _vm.handleNext
    }
  }, [_vm._v("下一步")])], 1), _c("set-name-dialog", {
    ref: "setNameDialog",
    attrs: {
      visible: _vm.setNameDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.setNameDialogVisible = $event;
      },
      save: _vm.handleSave
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;