"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban.vue"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _cardContainer = _interopRequireDefault(require("@/views/businessIntelligence/Overview/components/cardContainer"));
var _kanbanConfig = _interopRequireDefault(require("./components/kanbanConfig"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _forewarnConfig = _interopRequireDefault(require("./components/forewarnConfig"));
var _chartConfig = _interopRequireDefault(require("./components/chartConfig"));
var _formatDataUnit = require("@/components/Charts/formatDataUnit.js");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Overview',
  components: {
    BIKanban: _BIKanban.default,
    InitKanbanCardView: _InitKanbanCardView.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    CardContainer: _cardContainer.default,
    KanbanConfig: _kanbanConfig.default,
    ShareConfig: _shareConfig.default,
    ForewarnConfig: _forewarnConfig.default,
    ChartConfig: _chartConfig.default
  },
  data() {
    return {
      // 创建A/B测试选中的卡片
      checkedList: [],
      // 看板
      getKanbanListLoading: false,
      getKanbanConfigLoading: false,
      kanbanConfigVisible: false,
      isEditKanban: false,
      currentKanban: {},
      kanbanList: [],
      // 分享
      assignConfigVisible: false,
      // 预警设置
      currentCardId: '',
      forewarnConfigVisible: false,
      // 卡片
      getCardListLoading: false,
      cardList: [],
      // 配置
      chartConfigVisible: false,
      isEditChart: false,
      isEditCard: false,
      editChartConfig: {},
      pageConfig: {
        size: 6,
        page: 1
      },
      cardTotalCount: 0,
      isShowCheckbox: false,
      confirmLoading: false,
      // 订阅
      subscribeKanbanLoading: false,
      filterDateTimeRange: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions', 'workspaceInfo', 'workspaceList', 'modulePermission', 'timezone']),
    isSameDay() {
      return this.filterDateTimeRange ? this.$moment(this.filterDateTimeRange[0]).isSame(this.filterDateTimeRange[1], 'day') : false;
    },
    isOverDateRange() {
      // 判断时间间隔是否超过七天
      return this.filterDateTimeRange ? this.$moment(this.filterDateTimeRange[1]).diff(this.$moment(this.filterDateTimeRange[0]), 'day') + 1 > 7 : false;
    },
    noMore() {
      return this.cardList.length >= this.cardTotalCount;
    },
    isSubscribeKanban() {
      return this.currentKanban.is_daily || this.currentKanban.is_weekly || this.currentKanban.is_monthly;
    },
    pickerOptions() {
      const that = this;
      return {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', [new Date(that.$moment().startOf('day')), new Date()]);
          }
        }, {
          text: '本周',
          onClick(picker) {
            picker.$emit('pick', [new Date(that.$moment().startOf('week')), new Date()]);
          }
        }, {
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(that.$moment().startOf('month')), new Date()]);
          }
        }]
      };
    },
    subscribeOptions() {
      return [{
        label: '日报',
        value: 'daily'
      }, {
        label: '周报',
        value: 'weekly'
      }, {
        label: '月报',
        value: 'monthly'
      }];
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getKanbanList();
      }
    }
  },
  async created() {
    const urlParamsWorkspaceId = this.$route.query.workspaceId;
    this.$route.query.workspaceId = null;
    if (urlParamsWorkspaceId) {
      if (this.workspaceInfo.id === Number(urlParamsWorkspaceId)) {
        this.getKanbanList();
      }
    } else {
      this.workspaceInfo.id && this.getKanbanList();
    }
  },
  methods: {
    assignKanban: _businessIntelligence.assignKanban,
    cancelAssignKanban: _businessIntelligence.cancelAssignKanban,
    getAssigneeList: _businessIntelligence.getAssigneeList,
    // 切换全屏
    toggleFullScreen(isFullScreen, cardIndex) {
      const currentCard = this.cardList[cardIndex];
      if (['Line', 'Bar'].includes(currentCard.type)) {
        if (isFullScreen) {
          currentCard.cardConfig.dataZoomOld = (0, _commonFunc.deepClone)(currentCard.cardConfig.dataZoom);
          delete currentCard.cardConfig.dataZoom;
          currentCard.cardConfig.grid.bottom = '5%';
        } else {
          currentCard.cardConfig.dataZoom = (0, _commonFunc.deepClone)(currentCard.cardConfig.dataZoomOld);
          currentCard.cardConfig.grid.bottom = '20%';
        }
      }
    },
    // 跳转A/B测试
    async goABTest() {
      // A/B测试 监测指标回填
      const monitor_configs = [];
      this.cardList.forEach(_ref => {
        let {
          name,
          card_id,
          configs,
          filter_configs
        } = _ref;
        if (this.checkedList.includes(card_id)) {
          if (configs.analysis_target_vals === null) {
            configs.analysis_target_vals = '';
          }
          monitor_configs.push({
            name,
            configs,
            filter_config: filter_configs
          });
        }
      });
      // A/B测试 监测范围回填
      this.confirmLoading = true;
      const res = await (0, _businessIntelligence.getBiDataOverviewKanbanConfig)({
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.confirmLoading = false;
      });
      if (res.code === 20000) {
        const {
          trees,
          users
        } = res.results.config;
        trees.forEach(item => {
          item.id = item.id.split('_').pop();
        });
        users.forEach(item => {
          item.id = Number(item.id.split('_').pop());
        });
        const source_info = {
          trees,
          users
        };
        this.$router.push({
          name: 'AbTesting',
          params: {
            monitor_configs,
            source_info,
            source: 2
          }
        });
      }
    },
    changeKanbanStatus(val) {
      this.kanbanList.find(item => item.kanban_id === this.currentKanban.kanban_id).is_sharing = !!val.length;
    },
    handleCancle() {
      this.isShowCheckbox = false;
      this.checkedList = [];
      if (this.$refs['card-container']) {
        this.$refs['card-container'].checkList = [];
      }
    },
    // 订阅看板
    async changeSubscribeType(val, type) {
      this.$set(this.currentKanban, 'is_' + type, val);
      const {
        is_daily: daily,
        is_weekly: weekly,
        is_monthly: monthly
      } = this.currentKanban;
      this.subscribeKanbanLoading = true;
      const res = await (0, _businessIntelligence.subscribeKanban)({
        kanban_id: this.currentKanban.kanban_id,
        daily,
        weekly,
        monthly,
        time_zone: this.timezone,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.subscribeKanbanLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success(val ? '订阅成功' : '订阅已取消');
      } else {
        this.$set(this.currentKanban, 'is_' + type, !val);
      }
    },
    // 看板
    // 获取看板列表
    async getKanbanList() {
      this.kanbanList = [];
      this.getKanbanListLoading = true;
      const res = await (0, _businessIntelligence.getBiDataOverviewKanbanList)({
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.getKanbanListLoading = false;
      });
      if (res.code === 20000) {
        this.handleKanbanOpertateHidden(res.results);
        const urlParamsKanbanId = this.$route.query.kanbanId;
        this.$route.query.kanbanId = null;
        const urlTargetKanban = this.kanbanList.find(_ref2 => {
          let {
            kanban_id
          } = _ref2;
          return kanban_id === Number(urlParamsKanbanId);
        });
        if (urlParamsKanbanId && !urlTargetKanban) {
          this.$message.warning('目标看板已被删除');
        }
        this.toggleKanban(urlTargetKanban || this.kanbanList[0]);
      }
    },
    handleKanbanOpertateHidden(array) {
      this.kanbanList = array.map(item => {
        item.hiddenHandle = [];
        if (!this.api_permissions.includes('/webapi/bi/view/kanban_share')) {
          item.hiddenHandle.push('assign');
        }
        if (item.is_share) {
          item.hiddenHandle.push('edit', 'delete', 'assign');
          item.kanban_remark = `来自${item.share_user_name}的分享`;
        } else if (item.is_default) {
          item.hiddenHandle.push('delete');
        }
        return item;
      });
    },
    createKanban() {
      this.kanbanConfigVisible = true;
      this.isEditKanban = false;
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.filterDateTimeRange = null;
      this.currentKanban = activeKanban;
      this.resetCardList();
      this.handleCancle();
    },
    async handleKanban(type, _ref3) {
      let {
        kanban_id
      } = _ref3;
      if (type === 'edit') {
        this.isEditKanban = true;
        this.kanbanConfigVisible = true;
        this.getKanbanConfigLoading = true;
        const res = await (0, _businessIntelligence.getBiDataOverviewKanbanConfig)({
          kanban_id,
          workspace_id: this.workspaceInfo.id
        });
        this.getKanbanConfigLoading = false;
        if (res.code === 20000) {
          const {
            trees,
            users
          } = res.results.config;
          res.results.config.tree_ids = trees.map(_ref4 => {
            let {
              id
            } = _ref4;
            return id;
          });
          res.results.config.user_ids = users.map(_ref5 => {
            let {
              id
            } = _ref5;
            return id;
          });
          this.$refs['kanban-config'].kanbanConfig = {
            ...res.results,
            kanban_id
          };
        }
      }
      if (type === 'delete') {
        this.$confirm(`删除看板将会删除看板下的图表和预警设置，且被分享人员也将无法看到此看板，是否删除？`, '删除看板', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(async () => {
          const res = await (0, _businessIntelligence.deleteBiDataOverviewKanban)({
            kanban_id,
            workspace_id: this.workspaceInfo.id
          });
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== kanban_id);
            if (this.kanbanList.length) {
              this.toggleKanban(this.kanbanList[0]);
            }
          }
        });
      }
      if (type === 'assign') {
        this.assignConfigVisible = true;
      }
    },
    handleKanbanSuccess(kanbanConfig) {
      const {
        name,
        kanban_id,
        config: {
          label
        }
      } = kanbanConfig;
      const newKanban = {
        data_range_label: label,
        kanban_id,
        name: name
      };
      if (this.isEditKanban) {
        this.currentKanban = newKanban;
        this.kanbanList.find(item => {
          if (item.kanban_id === kanban_id) {
            item.name = name;
            item.data_range_label = label;
          }
        });
        this.resetCardList();
      } else {
        this.kanbanList.push(newKanban);
        this.handleKanbanOpertateHidden(this.kanbanList);
        this.toggleKanban(newKanban);
      }
    },
    // 卡片预警
    handleForewarn(id) {
      this.currentCardId = id;
      this.forewarnConfigVisible = true;
    },
    openForewarn(cardId, value) {
      this.cardList.find(_ref6 => {
        let {
          card_id
        } = _ref6;
        return card_id === cardId;
      }).enable_warning = value;
    },
    loadMore() {
      if (this.getCardListLoading) return;
      this.pageConfig.page += 1;
      this.getCardList();
    },
    resetCardList() {
      this.pageConfig.page = 1;
      this.cardList = [];
      this.getCardList();
    },
    addCard() {
      this.isEditCard = false;
      this.chartConfigVisible = true;
    },
    dragEnd(val) {
      this.cardList = val;
    },
    handleCard(type, card_id) {
      if (type === 'edit') {
        this.isEditCard = true;
        this.chartConfigVisible = true;
        this.editChartConfig = (0, _commonFunc.deepClone)(this.cardList.find(item => item.card_id === card_id));
      } else {
        // 删除卡片
        this.$confirm(`确认删除此卡片吗？`, '删除卡片', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(async () => {
          const res = await (0, _businessIntelligence.deleteBiDataOverviewCard)({
            card_id,
            workspace_id: this.workspaceInfo.id
          });
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.cardList = this.cardList.filter(item => item.card_id !== card_id);
          }
        });
      }
    },
    // 折线图切换分析维度
    dimensionChange(index, dimension) {
      const data = this.cardList[index];
      const {
        type,
        value,
        is_current
      } = data.filter_configs.date;
      let hasMessage = false;
      // 存在筛选时间：时间范围筛选器超过七天 || 不存在筛选时间：卡片配置 > 7(本月， 过去>7天，过去>1周，过去>1月)
      if (dimension === 'hour') {
        if (this.filterDateTimeRange) {
          hasMessage = this.isOverDateRange;
        } else {
          hasMessage = is_current && type === 'month' || !is_current && !(type === 'day' && value <= 7);
        }
      }
      if (hasMessage) {
        this.$message.warning('按小时查看图表时，时间范围不能超过7天');
        return;
      }
      data.configs.dimension = dimension;
      this.getBiDataOverviewCardData(data);
    },
    // 创建、编辑卡片成功
    handleChartSuccess(data) {
      if (this.isEditCard) {
        // 编辑卡片成功
        const currentCardIndex = this.cardList.findIndex(_ref7 => {
          let {
            card_id
          } = _ref7;
          return card_id === data.card_id;
        });
        this.$set(this.cardList, currentCardIndex, data);
      } else {
        // 创建卡片成功
        this.cardList.push(data);
      }
      // 刷新卡片
      this.getBiDataOverviewCardData(data);
    },
    getCardList() {
      if (this.getCardListLoading) return;
      this.getCardListLoading = true;
      (0, _businessIntelligence.getBiDataOverviewCardList)({
        ...this.pageConfig,
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      }).then(res => {
        if (res.code === 20000) {
          this.cardTotalCount = res.results.total_count;
          const newCardList = res.results.datas;
          // 请求每一项卡片的数据
          newCardList.forEach(async item => {
            item.filter_configs.custom_filters = item.filter_configs.custom_filters || {};
            item.configs.dimension = this.handleDimension(item.configs.dimension, item.filter_configs.date);
            if (!item.configs.conversation_type) {
              item.configs.conversation_type = [];
            }
            if (!item.configs.conversation_scene_type) {
              item.configs.conversation_scene_type = [];
            }
            await this.getBiDataOverviewCardData(item);
          });
          this.cardList = this.cardList.concat(newCardList);
        }
      }).finally(() => {
        this.getCardListLoading = false;
      });
    },
    // 改变筛选时间范围
    filterDateTimeRangeChange() {
      this.cardList.forEach(item => {
        this.$set(item.filter_configs, 'dateTimeRange', this.filterDateTimeRange);
        item.configs.dimension = this.handleDimension(item.configs.dimension, item.filter_configs.date);
        this.getBiDataOverviewCardData(item);
      });
    },
    handleDimension(dimensionType, data) {
      if (dimensionType === 'uid') return dimensionType;
      const {
        type,
        value,
        is_current
      } = data;
      // 日期选择器有选择时 && 日期筛选框选择同一天 || 日期选择器没有选择时 && 配置选择天级 && (过去1天 || 配置选择当天)    将卡片维度默认选择改为小时
      if (this.filterDateTimeRange) {
        return this.isSameDay ? 'hour' : 'day';
      } else {
        return type === 'day' && (is_current || value === 1) ? 'hour' : 'day';
      }
    },
    // 请求每一项卡片的数据
    async getBiDataOverviewCardData(item) {
      var _this$filterDateTimeR;
      this.$set(item, 'loading', true);
      const res = await (0, _businessIntelligence.getBiDataOverviewCardData)({
        date_interval: item.configs.dimension || 'day',
        card_id: item.card_id,
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone,
        date: (_this$filterDateTimeR = this.filterDateTimeRange) === null || _this$filterDateTimeR === void 0 ? void 0 : _this$filterDateTimeR.map(item => (0, _timezone.currentZoneToPlus8)(this.$moment(item)).format('YYYY-MM-DD HH:mm:ss'))
      }).finally(() => {
        this.$set(item, 'loading', false);
      });
      if (res.code === 20000) {
        if (item.type !== 'Digit') {
          // 时间范围筛选器超过七天-统一用年月日
          // 时间范围筛选器不超过七天-统一用年月日 时分
          let formatDateRule = this.isOverDateRange ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
          // 如果有选择时间维度则再将非小时的统一处理为年月日
          if (item.configs.dimension !== 'hour') {
            formatDateRule = 'YYYY-MM-DD';
          }
          const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
          res.results.data = res.results.data.map(_item => {
            return _item.map(val => {
              if (typeof val === 'string' && timeReg.test(val)) {
                return this.$moment(val).format(formatDateRule);
              }
              return val;
            });
          });
        }
        this.$set(item, 'cardConfig', item.type === 'Digit' ? res.results : this.setOptions(item, res.results) || {});
      }
    },
    // 卡片配置
    setOptions(_ref8, _ref9) {
      let {
        type,
        configs: {
          analysis_target,
          analysis_target_key,
          draw_style
        }
      } = _ref8;
      let {
        data
      } = _ref9;
      if (!data.length) return;
      let xAxis, yAxis;
      const unitConfig = {
        analysis_target,
        analysis_target_key,
        draw_style
      };
      const tooltip = {
        confine: true,
        appendToBody: true,
        formatter: _ref10 => {
          let {
            marker,
            name,
            data
          } = _ref10;
          return formatUnit(`<span style='line-height: 20px;'>${marker}</span> ${name}`, type === 'Line' ? data[1] : data[0], unitConfig);
        }
      };
      const AxisLableFormatter = {
        type: 'value',
        minInterval: 1,
        axisLabel: {
          formatter: val => {
            return formatUnit('', val, unitConfig).replace(/<[^>]+>/g, '');
          }
        }
      };
      // ECHARTS配置
      let dataZoom = {
        type: 'slider',
        show: true,
        zoomLock: true,
        moveHandleSize: 5,
        bottom: '5%',
        filterMode: 'none'
      };
      if (['Line', 'Bar'].includes(type)) {
        if (data.length > 10) {
          dataZoom.startValue = data.length;
          dataZoom.endValue = data.length - 10;
        }
      }
      if (type === 'Line') {
        dataZoom = {
          ...dataZoom,
          xAxisIndex: 0,
          width: '90%',
          height: 0,
          left: 'center'
        };
        xAxis = {
          type: 'category'
        };
        yAxis = AxisLableFormatter;
      }
      if (type === 'Bar') {
        dataZoom = {
          ...dataZoom,
          yAxisIndex: 0,
          width: 0,
          height: '100%',
          right: '5%'
        };
        yAxis = {
          type: 'category'
        };
        xAxis = AxisLableFormatter;
      }
      const option = {
        grid: {
          containLabel: true,
          top: '5%',
          left: 20,
          right: 20,
          bottom: '20%'
        },
        color: '#3393FF',
        tooltip,
        xAxis,
        yAxis,
        dataset: {
          source: data
        }
      };
      if (data.length > 8) {
        option.dataZoom = dataZoom;
      }
      option.series = {
        type: type.toLowerCase(),
        encode: {
          x: 0,
          y: 1
        },
        itemStyle: {
          normal: {
            // 这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
            barBorderRadius: [0, 5, 5, 0]
          }
        }
      };
      return option;
    }
  }
};
exports.default = _default;
function formatUnit(label, data, unitConfig) {
  return `${label}<span style="margin-left: 30px; font-weight: bold;">${(0, _formatDataUnit.formatDataUnit)(unitConfig, data)}</span>`;
}