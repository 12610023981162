"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "metrics-filter-table"
  }, [_c("div", {
    staticClass: "filter-row"
  }, [_c("MgvMemberMultiSelect", {
    staticClass: "member-select",
    attrs: {
      placeholder: "选择更新人",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["conversation"])
      }
    },
    on: {
      change: _setup.updateByChange
    }
  }), _c("MgvMemberMultiSelect", {
    staticClass: "member-select",
    attrs: {
      placeholder: "选择创建人",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["conversation"])
      }
    },
    on: {
      change: _setup.createByChange
    }
  })], 1), _c("el-table", {
    attrs: {
      data: _setup.tableData,
      height: "calc(100vh - 192px)"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "指标名称",
      prop: "name",
      fixed: "left",
      width: "220",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "指标要点",
      prop: "gists",
      width: "220"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "gist-box"
        }, [row.gists.length ? _vm._l(row.gists, function (gist, index) {
          return _c("p", {
            key: index
          }, [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(gist.content) + " ")]);
        }) : [_c("p", [_vm._v("-")])]], 2)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "更新时间",
      prop: "update_at",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "更新人",
      prop: "update_user",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var _row$update_user;
        let {
          row
        } = _ref2;
        return [(_row$update_user = row.update_user) !== null && _row$update_user !== void 0 && _row$update_user.id ? [_c("MgvAvatarName", {
          attrs: {
            name: row.update_user.name,
            src: row.update_user.avatar,
            size: 28
          }
        }), _c("span", {
          staticClass: "avatar-name-text"
        }, [_vm._v(_vm._s(row.update_user.name))])] : [_vm._v("-")]];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "创建时间",
      prop: "create_at",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "创建人",
      prop: "create_user",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _row$create_user;
        let {
          row
        } = _ref3;
        return [(_row$create_user = row.create_user) !== null && _row$create_user !== void 0 && _row$create_user.id ? [_c("MgvAvatarName", {
          attrs: {
            name: row.create_user.name,
            src: row.create_user.avatar,
            size: 28
          }
        }), _c("span", {
          staticClass: "avatar-name-text"
        }, [_vm._v(_vm._s(row.create_user.name))])] : [_vm._v("-")]];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "operation-header"
        }, [_vm._v("操作")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("MgvButton", {
          attrs: {
            type: "text",
            size: "small",
            disabled: row.is_system
          },
          on: {
            click: function ($event) {
              return _setup.editMetric(row);
            }
          }
        }, [_vm._v("编辑")]), _c("MgvButton", {
          attrs: {
            type: "text",
            size: "small",
            color: "danger",
            disabled: row.is_system
          },
          on: {
            click: function ($event) {
              return _setup.deleteMetric(row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c(_setup.MetricCreateDialog, {
    attrs: {
      visible: _setup.metricCreateDialogVisible,
      metric: _setup.activeMetric
    },
    on: {
      "update:visible": function ($event) {
        _setup.metricCreateDialogVisible = $event;
      },
      createSuccess: _setup.fetchData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;