"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTribute = useTribute;
var _tribute = _interopRequireDefault(require("@/components/CommentInput/tribute"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function useTribute(_ref) {
  let {
    inputRef,
    onSelectMenu,
    tagClassName = 'prompt-editor-active-script-tag'
  } = _ref;
  let tribute = null;
  tribute = new _tribute.default({
    trigger: '/',
    requireLeadingSpace: false,
    // replaceTextSuffix: '',
    menuTitle: '关键问题',
    values: [{
      key: '手动输入',
      value: 'type',
      icon: 'writing'
    }, {
      key: '从知识库添加',
      value: 'knowledge',
      icon: 'training'
    }],
    selectTemplate: function (item) {
      return `<span class="${tagClassName}" contenteditable="false"></span>`;
    },
    menuItemTemplate: function (item) {
      return `
        <div class="prompt-tribute-menu-item">
            <i class="iconfont icon-${item.original.icon}"></i>
            <span>${item.original.key}</span>
        </div>`;
    },
    noMatchTemplate: function () {
      return false;
    },
    onSelectMenu: function (item) {
      onSelectMenu && onSelectMenu(item.original.value);
    }
  });
  (0, _vue.onMounted)(() => {
    tribute.attach(inputRef.value);
  });
  return {
    tribute
  };
}