"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$configs$space_con;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "analysis-config"
  }, [_c("el-form-item", {
    attrs: {
      prop: "configs.analysis_target"
    }
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v(" " + _vm._s(_vm.formConfig.analysisTargetLabel || "分析对象") + " ")]), _c("div", {
    staticClass: "radio-inline"
  }, _vm._l(_vm.analysisTarget, function (_ref) {
    let {
      type,
      label
    } = _ref;
    return _c("el-radio", {
      key: type,
      attrs: {
        label: type
      },
      on: {
        change: _vm.changeAnalysisTargetType
      },
      model: {
        value: _vm.configs.analysis_target,
        callback: function ($$v) {
          _vm.$set(_vm.configs, "analysis_target", $$v);
        },
        expression: "configs.analysis_target"
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1)]), _c("div", {
    staticClass: "analysis-target"
  }, [_c("el-form-item", {
    attrs: {
      prop: "configs.analysis_target_key",
      rules: [{
        required: true,
        message: "请选择指标类型",
        trigger: "change"
      }]
    }
  }, [_vm.formConfig.analysisTargetKeyLabel ? _c("p", {
    staticClass: "form-item-label"
  }, [_vm._v(" " + _vm._s(_vm.formConfig.analysisTargetKeyLabel) + " ")]) : _vm._e(), _c("el-select", {
    ref: "analysis-target-key",
    attrs: {
      "popper-class": "bi-analysis-target-key",
      placeholder: "请选择指标类型"
    },
    on: {
      change: _vm.changeAnalysisTargetKey
    },
    model: {
      value: _vm.configs.analysis_target_key,
      callback: function ($$v) {
        _vm.$set(_vm.configs, "analysis_target_key", $$v);
      },
      expression: "configs.analysis_target_key"
    }
  }, _vm._l(_vm.getAnalysisTargetOptions, function (item) {
    return _c("el-option-group", {
      key: item[0],
      attrs: {
        label: item[0]
      }
    }, _vm._l(item[1], function (_ref2) {
      let {
        field_name,
        name,
        tooltip
      } = _ref2;
      return _c("el-option", {
        key: field_name,
        attrs: {
          label: name,
          value: field_name
        }
      }, [_c("span", [_vm._v(_vm._s(name))]), _c("el-tooltip", {
        attrs: {
          content: tooltip,
          placement: "top"
        }
      }, [tooltip ? _c("i", {
        staticClass: "iconfont icon-info-1"
      }) : _vm._e()])], 1);
    }), 1);
  }), 1)], 1), _vm.hasConversationType ? _c("el-form-item", {
    attrs: {
      prop: "configs.conversation_type",
      rules: [{
        type: "array",
        required: true,
        message: "请选择会话类型",
        trigger: "change"
      }]
    }
  }, [_c("div", {
    staticClass: "conversation-type-content"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("会话类型")]), _c("el-checkbox-group", {
    model: {
      value: _vm.configs.conversation_type,
      callback: function ($$v) {
        _vm.$set(_vm.configs, "conversation_type", $$v);
      },
      expression: "configs.conversation_type"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "audio"
    }
  }, [_vm._v("电话")]), _c("el-checkbox", {
    attrs: {
      label: "video"
    }
  }, [_vm._v("在线会议")]), _c("el-checkbox", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isDimensionTarget,
      expression: "!isDimensionTarget"
    }],
    attrs: {
      label: "doc"
    }
  }, [_vm._v("文字")])], 1)], 1)]) : _vm._e(), _vm.hasConversationType && !_vm.isDimensionTarget ? _c("el-form-item", {
    attrs: {
      prop: "configs.conversation_scene_type"
    }
  }, [_c("div", {
    staticClass: "conversation-type-content"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("会话场景")]), _c("el-checkbox-group", {
    model: {
      value: _vm.configs.conversation_scene_type,
      callback: function ($$v) {
        _vm.$set(_vm.configs, "conversation_scene_type", $$v);
      },
      expression: "configs.conversation_scene_type"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "single"
    }
  }, [_vm._v("单聊")]), _c("el-checkbox", {
    attrs: {
      label: "multiple"
    }
  }, [_vm._v("群聊")])], 1)], 1)]) : _vm._e(), _vm.showConvDuration && _vm.configs.analysis_target_key === "count" ? _c("el-form-item", {
    attrs: {
      prop: "configs.conversation_duration"
    }
  }, [_c("TimeRangeFilter", {
    attrs: {
      "key-name": "conversation_duration",
      filters: _vm.configs
    },
    on: {
      selectTime: _vm.conversationDurationChange,
      validStatusChange: _vm.durationValidStatusChange
    }
  })], 1) : _vm._e(), _vm.configs.analysis_target !== "conversation" && _vm.configs.analysis_target_key && _vm.configs.analysis_target_key !== "deal_count" ? _c("el-form-item", {
    attrs: {
      prop: "configs.analysis_target_vals",
      rules: _vm.validateAnalysixTargetValue
    }
  }, [["business_trans", "key_event", "conv_event"].includes(_vm.configs.analysis_target_key) ? _c("el-cascader", {
    attrs: {
      options: _vm.eventOptions,
      "collapse-tags": "",
      props: {
        expandTrigger: "hover",
        label: "name",
        value: "id",
        multiple: true
      },
      "show-all-levels": false,
      placeholder: _vm.configs.analysis_target_key === "business_trans" ? "请选择需要进行卡点分析的关键事件" : "请选择关键事件"
    },
    on: {
      change: _vm.resetPreview
    },
    model: {
      value: _vm.configs.analysis_target_vals,
      callback: function ($$v) {
        _vm.$set(_vm.configs, "analysis_target_vals", $$v);
      },
      expression: "configs.analysis_target_vals"
    }
  }) : _c("deal-custom-filter", {
    attrs: {
      value: _vm.getDealCustomFilterValue,
      item: _vm.getDealCustomConfig
    },
    on: {
      customFilterChange: _vm.customFilterChange
    }
  })], 1) : _vm._e()], 1), _vm.showConvDuration && _vm.configs.analysis_target_key === "deal_count" ? _c("el-form-item", {
    attrs: {
      prop: "configs.conversation_duration"
    }
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_c("span", [_vm._v("交易会话总时长")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "交易会话时长筛选仅在“按交易分析”时生效，“按会话分析”时无效；功能说明：若筛选范围为1分钟到2分钟，则筛选结果为【大于】1分钟且【小于等于】2分钟的时长；"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("time-range", {
    ref: "timeRange",
    attrs: {
      "max-value": "unlimit",
      "time-range": ((_vm$configs$space_con = _vm.configs.space_conv_total_duration) === null || _vm$configs$space_con === void 0 ? void 0 : _vm$configs$space_con.value) || ["unlimit", "unlimit"],
      "is-left-unlimit": ""
    },
    on: {
      timeChange: _vm.handleDealDuationChange,
      validStatusChange: _vm.durationValidStatusChange
    }
  })], 1) : _vm._e(), _vm.hasDrawStyle ? _c("el-form-item", {
    staticClass: "draw-style"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("统计方式")]), _c("div", {
    staticClass: "radio-inline"
  }, _vm._l(_vm.configs.analysis_target_key === "conv_event" ? _vm.conversationDrawStyleOption : _vm.dealDrawStyleOption, function (_ref3) {
    let {
      type,
      label
    } = _ref3;
    return _c("el-radio", {
      key: type,
      attrs: {
        label: type
      },
      on: {
        change: _vm.resetPreview
      },
      model: {
        value: _vm.configs.draw_style,
        callback: function ($$v) {
          _vm.$set(_vm.configs, "draw_style", $$v);
        },
        expression: "configs.draw_style"
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1)]) : _vm._e(), _vm.configs.analysis_target_key === "business_trans" ? _c("div", {
    staticClass: "analysis-value"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_c("span", [_vm._v("关键结果")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "上一步所选关键事件的转化指标"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("el-form-item", {
    attrs: {
      prop: "configs.strategy.results_type",
      rules: [{
        required: true,
        message: "请选择关键结果类型",
        trigger: "change"
      }]
    }
  }, [_c("div", {
    staticClass: "radio-inline"
  }, _vm._l(_vm.keyEventResultOption, function (_ref4) {
    let {
      type,
      label
    } = _ref4;
    return _c("el-radio", {
      key: type,
      attrs: {
        label: type
      },
      on: {
        change: _vm.changeKeyEventResultsType
      },
      model: {
        value: _vm.configs.strategy.results_type,
        callback: function ($$v) {
          _vm.$set(_vm.configs.strategy, "results_type", $$v);
        },
        expression: "configs.strategy.results_type"
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1)]), _vm.configs.strategy.results_type ? _c("el-form-item", {
    attrs: {
      prop: "configs.strategy.key",
      rules: [{
        required: true,
        message: "请选择关键结果指标",
        trigger: "change"
      }]
    }
  }, [_vm.configs.strategy.results_type === "events" ? _c("el-cascader", {
    staticClass: "events-cascader",
    attrs: {
      "show-all-levels": false,
      options: _vm.eventOptions,
      "collapse-tags": "",
      props: {
        multiple: true,
        expandTrigger: "hover",
        label: "name",
        value: "id"
      },
      placeholder: "请选择关键事件"
    },
    model: {
      value: _vm.configs.strategy.key,
      callback: function ($$v) {
        _vm.$set(_vm.configs.strategy, "key", $$v);
      },
      expression: "configs.strategy.key"
    }
  }) : _c("el-select", {
    attrs: {
      placeholder: "请选择关键结果指标"
    },
    on: {
      change: _vm.changeKeyEventResultsKey
    },
    model: {
      value: _vm.configs.strategy.key,
      callback: function ($$v) {
        _vm.$set(_vm.configs.strategy, "key", $$v);
      },
      expression: "configs.strategy.key"
    }
  }, _vm._l(_vm.getResultsOptions, function (_ref5) {
    let {
      field_name,
      name
    } = _ref5;
    return _c("el-option", {
      key: field_name,
      attrs: {
        label: name,
        value: field_name
      }
    });
  }), 1)], 1) : _vm._e(), _vm.configs.strategy.key && _vm.configs.strategy.results_type === "custom" ? _c("el-form-item", {
    attrs: {
      prop: "configs.strategy.vals",
      rules: [{
        required: true,
        message: "请选择关键结果指标值",
        trigger: "change"
      }]
    }
  }, [_c("deal-custom-filter", {
    attrs: {
      value: _vm.getStrategyKeyDealCustomFilterValue,
      item: _vm.getStrategyKeyDealCustomFilterConfig
    },
    on: {
      customFilterChange: _vm.resultsCustomFilterChange
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;