"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBindTels = getBindTels;
exports.getCallSeats = getCallSeats;
exports.getInfoByTel = getInfoByTel;
exports.getSeatSettings = getSeatSettings;
exports.postLogin = postLogin;
exports.postLogout = postLogout;
exports.sendPostCommand = sendPostCommand;
var _request = require("@/utils/request");
// 7m init webphone
function sendPostCommand(data) {
  return (0, _request.request)({
    url: '/micro_record/outbound/seat/send_post_command_proxy',
    method: 'post',
    data
  });
}

// 获取所有坐席
function getCallSeats() {
  return (0, _request.request)({
    url: '/micro_record/outbound/seats',
    method: 'get'
  });
}

// 获取最近绑定过的手机号
function getBindTels() {
  return (0, _request.request)({
    url: '/micro_record/outbound/the_last_tel',
    method: 'get'
  });
}

// 坐席登录
function postLogin(data) {
  return (0, _request.request)({
    url: '/micro_record/outbound/seat/login',
    method: 'post',
    data
  });
}

// 坐席退出配置更新
function postLogout(data) {
  return (0, _request.request)({
    url: '/micro_record/outbound/seat/logout',
    method: 'post',
    data
  });
}

// 获取坐席设置
function getSeatSettings() {
  return (0, _request.request)({
    url: '/micro_record/outbound/config/get',
    method: 'get'
  });
}

// 根据手机号判断线索或者商机信息
function getInfoByTel(tel) {
  return (0, _request.request)({
    url: '/deal/contact/get_by_tel',
    method: 'get',
    params: {
      tel
    }
  });
}