"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _user = require("@/api/user");
var _default = {
  name: 'LoginLayout',
  data() {
    return {
      redirect: undefined,
      type: 'login',
      // 默认：登录
      orgName: '',
      // 组织名称
      code: '',
      showTeamChoice: false,
      invitations: [],
      organizations: [],
      upCreateVisible: false,
      orgForm: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '公司名不能为空',
          trigger: 'blur'
        }]
      },
      userRole: [],
      logoUrl: '',
      loading: false,
      isTwoFactor: false // 是否开启双因子登录
    };
  },

  computed: {
    // 是否是ios并且从微信中打开
    isIosWexin() {
      const u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android终端或者uc浏览器
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      const ua = navigator.userAgent.toLowerCase();
      const isWexin = !!(ua.match(/MicroMessenger/i) === 'micromessenger');

      // this.$message.info(u.toLowerCase())
      // this.$message.info('当前浏览器是微信吗'+isWexin)
      if (isIOS && isWexin) {
        return true;
      }
      return false;
    },
    ...(0, _vuex.mapGetters)(['org_display_info'])
  },
  created() {
    if (this.$route.path.indexOf('xiaozhan') !== -1) {
      if (this.org_display_info.org_login_logo_url) {
        this.logoUrl = this.org_display_info.org_login_logo_url;
        return;
      }
      this.$store.dispatch('user/getXiaozhanInfo').then(res => {
        this.logoUrl = res.org_login_logo_url;
      }).catch(() => {
        this.logoUrl = require('../../assets/logo/logo.svg');
      });
    } else {
      this.getLoginLogo();
    }
  },
  methods: {
    async getLoginLogo() {
      try {
        this.loading = true;
        const res = await (0, _user.getLoginLogo)().finally(() => {
          this.loading = false;
        });
        if (res.code === 20000) {
          this.logoUrl = res.results.login_logo || require('../../assets/logo/logo.svg');
          this.isTwoFactor = res.results.is_two_factor;
          (0, _commonFunc.changeFavicon)(res.results.org_icon_url || '/favicon.ico');
        }
      } catch (err) {
        this.logoUrl = require('../../assets/logo/logo.svg');
      }
    }
  }
};
exports.default = _default;