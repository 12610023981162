"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "explain-card"
  }, [_vm._m(0), _c("el-divider"), _vm._m(1), _vm._m(2), _vm._m(3), _c("el-divider"), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "bubble blue medium"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "video-camera-solid-2"
    }
  })], 1), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("在线会议")]), _c("div", {
    staticClass: "second-item bubble blue medium"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "phone"
    }
  })], 1), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("电话")]), _c("div", {
    staticClass: "second-item bubble blue medium"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "im-fill"
    }
  })], 1), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("文字")])])], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "bubble blue medium"
  }), _c("span", {
    staticClass: "text-plain"
  }, [_vm._v("蓝色 - ")]), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("销售的发言")]), _c("div", {
    staticClass: "second-item bubble red medium"
  }), _c("span", {
    staticClass: "text-plain"
  }, [_vm._v("红色 - ")]), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("客户的发言")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "bubble-container"
  }, [_c("div", {
    staticClass: "bubble blue small"
  })]), _c("span", {
    staticClass: "text-plain"
  }, [_vm._v("小 - ")]), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("少于1分钟")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row second-row"
  }, [_c("div", {
    staticClass: "bubble-container"
  }, [_c("div", {
    staticClass: "bubble blue medium"
  })]), _c("span", {
    staticClass: "text-plain"
  }, [_vm._v("中 - ")]), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("1至5分钟")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row second-row"
  }, [_c("div", {
    staticClass: "bubble-container"
  }, [_c("div", {
    staticClass: "bubble blue large"
  })]), _c("span", {
    staticClass: "text-plain"
  }, [_vm._v("大 - ")]), _c("span", {
    staticClass: "text-main"
  }, [_vm._v("5分钟以上")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;