"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatInput = _interopRequireDefault(require("@/components/ChatUnit/components/ChatInput.vue"));
var _vuex = require("vuex");
var _SentenceItem = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/SentenceItem.vue"));
var _DialogueItemNarration = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/DialogueItemNarration.vue"));
var _MgAutoTooltip = _interopRequireDefault(require("@/components/MgAutoTooltip"));
var _coachingCenter = require("@/api/coachingCenter");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TrainingTestDrawer',
  components: {
    ChatInput: _ChatInput.default,
    SentenceItem: _SentenceItem.default,
    MgAutoTooltip: _MgAutoTooltip.default,
    DialogueItemNarration: _DialogueItemNarration.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    testContent: {
      type: String,
      default: ''
    },
    practiceRule: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      sentenceList: [],
      practiceStatus: 'connecting',
      conversationComplate: true,
      scoreComplate: false,
      currentPlayingVoiceId: 0,
      salesTalkUuid: 0,
      standardReplies: [],
      uploadLoading: false,
      averageScore: 0,
      unlock: true,
      currentReply: '',
      nodeHighLightTimer: null,
      nextSaleNodeIds: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'avatar', 'name'])
  },
  watch: {
    drawerVisible: {
      handler(val) {
        // 第一次打开测试抽屉请求接口
        if (val) {
          if (this.unlock) {
            this.getSmartTrainingTestBeginData();
            this.unlock = false;
          } else {
            this.nextSaleNodeIds.length & this.$emit('highLightRoleNode', this.nextSaleNodeIds);
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      this.sentenceList.forEach(item => {
        item.timer && clearInterval(item.timer);
      });
    },
    async getSmartTrainingTestBeginData() {
      const res = await (0, _coachingCenter.getSmartTrainingTestBeginData)({
        content: this.testContent
      });
      if (res.code === 20000) {
        this.practiceStatus = 'started';
        this.updateTalkConfig(res.results);
        this.salesTalkUuid = res.results.uuid;
      }
    },
    updateTalkConfig(val) {
      const {
        is_complete,
        standard_replies,
        data,
        next_node_ids,
        box_id
      } = val;
      this.nextSaleNodeIds = next_node_ids;
      this.conversationComplate = is_complete;
      this.standardReplies = standard_replies.filter(reply => reply);
      this.triggerNodeHighLight(data, box_id);
      this.sentenceList = this.sentenceList.concat(data);
      this.scrollToBottom();
    },
    async uploadSmartTrainingTest(val) {
      this.currentReply = val;
      this.uploadLoading = true;
      const id = (0, _nanoid.nanoid)();
      this.sentenceList.push({
        id,
        content: val,
        type: 'sales',
        score: 0,
        pass_score: 0,
        total_score: 0,
        comment: '',
        score_loading: true,
        timer: null,
        score_rule_type: 1
      });
      this.standardReplies = [];
      this.scoreComplate = false;
      this.scrollToBottom();
      const res = await (0, _coachingCenter.uploadSmartTrainingTest)({
        reply: val,
        conv_id: this.salesTalkUuid,
        lang: this.practiceRule.language
      }).finally(() => {
        this.uploadLoading = false;
      });
      if (res.code === 20000) {
        this.updateTalkConfig(res.results);
        const {
          box_id,
          score_rule_type
        } = res.results;
        let timer = null;
        if (score_rule_type === 1) {
          // 轮询获取分数
          timer = setInterval(() => {
            this.getSalesSayScore(box_id, id);
          }, 2000);
        }
        this.updateTalkContent({
          id,
          timer,
          score_rule_type,
          score_is_complete: !(score_rule_type === 1)
        });
        this.updateScoreComplate();
      }
      this.scrollToBottom();
    },
    async getSalesSayScore(box_id, id) {
      this.scoreComplate = false;
      const scoreRes = await (0, _coachingCenter.getSmartTrainingTestSalesSayScore)({
        box_id,
        conv_id: this.salesTalkUuid,
        course_id: Number(this.$route.query.id)
      });
      if (scoreRes.code === 20000) {
        const {
          score,
          pass_score,
          total_score,
          comment,
          score_is_complete,
          avg_score
        } = scoreRes.results;
        this.updateTalkContent({
          id,
          score,
          pass_score,
          total_score,
          comment,
          score_is_complete,
          score_loading: !score_is_complete
        });
        if (score_is_complete) {
          var _this$sentenceList$fi;
          this.averageScore = avg_score;
          clearInterval((_this$sentenceList$fi = this.sentenceList.find(item => item.id === id)) === null || _this$sentenceList$fi === void 0 ? void 0 : _this$sentenceList$fi.timer);
          this.updateScoreComplate();
          this.scrollToBottom();
        }
      }
    },
    updateScoreComplate() {
      // 判断所有的评分是否已完成
      this.scoreComplate = !this.sentenceList.some(item => item.type === 'sales' && !item.score_is_complete);
    },
    updateTalkContent(val) {
      this.sentenceList.find(item => {
        if (item.id === val.id) {
          item = Object.assign(item, val);
        }
      });
    },
    restartTest() {
      this.clearTimer();
      this.conversationComplate = false;
      this.scoreComplate = false;
      this.sentenceList = [];
      this.practiceStatus = 'connecting';
      this.getSmartTrainingTestBeginData();
    },
    triggerNodeHighLight(data, box_id) {
      this.nodeHighLightTimer && clearInterval(this.nodeHighLightTimer);
      const nodeIds = [box_id, ...data.map(item => item.node_id), this.nextSaleNodeIds];
      let index = 0;
      this.nodeHighLightTimer = setInterval(() => {
        this.$emit('highLightRoleNode', nodeIds[index]);
        index++;
        if (index >= nodeIds.length) {
          clearInterval(this.nodeHighLightTimer);
        }
      }, 500);
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs['conversation-container'];
        container.scrollTo({
          top: this.getElementHeight(container),
          behavior: 'smooth'
        });
      });
    },
    getElementHeight(ele) {
      return ele === null || ele === void 0 ? void 0 : ele.scrollHeight;
    }
  }
};
exports.default = _default;