"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'SelectedLabel',
  props: {
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      const map = {
        knowledge: 'icon-reading',
        snippet: 'icon-film'
      };
      return map[this.item.type];
    }
  },
  methods: {
    goDetail() {
      const {
        type,
        info: {
          router_params: {
            id,
            base_id
          }
        }
      } = this.item;
      let url = `${location.origin}`;
      if (type === 'knowledge') {
        url += `${_routeMap.ROUTES.knowledgeLibraryDetail}?id=${id}&knowledgeBaseId=${base_id}`;
      } else {
        url += `${_routeMap.ROUTES.libraryDetail}?id=${id}`;
      }
      window.open(url);
    }
  }
};
exports.default = _default;