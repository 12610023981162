"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _index = _interopRequireDefault(require("@/components/Anchor/index.vue"));
var _KnowledgeItem = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeItem.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeTricksSelect',
  components: {
    Anchor: _index.default,
    KnowledgeItem: _KnowledgeItem.default
  },
  inject: {
    isCheckByMap: {
      from: 'isCheckByMap',
      default: false
    }
  },
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    taskInfo: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    targetKnowledge: {
      type: Object,
      default: () => ({})
    },
    showCheckAll: {
      type: Boolean,
      default: false
    },
    selectedKnowledges: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      knowledgeList: [],
      listLoading: false,
      id2KnowledgeListMap: new Map(),
      // 目录id => 知识点列表映射,
      anchor: '',
      selectedList: [],
      backfillKnowledges: [],
      isCheckAll: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['selectedKnowledgeIds', 'id2KnowledgeMap', 'workspaceInfo'])
  },
  watch: {
    currentNode() {
      this.getKnowledgeList();
    },
    selectedKnowledgeIds(val) {
      this.handleSelectKnowledges(val);
    },
    selectedKnowledges(val) {
      const ids = val.map(item => item.id);
      this.handleSelectKnowledges(ids);
    },
    taskInfo: {
      handler(val) {
        if (!(0, _commonFunc.isValuableObj)(val)) return;
        this.backfillKnowledges = this.taskInfo.knowledges;
        this.emitKnowledges();
        this.getKnowledgeList();
      },
      immediate: true
    }
  },
  methods: {
    navigateToKnowledge() {
      if (!(0, _commonFunc.isValuableObj)(this.targetKnowledge)) return;
      this.anchor = this.targetKnowledge.id;
    },
    async getKnowledgeList() {
      if (this.isEdit && !(0, _commonFunc.isValuableObj)(this.taskInfo)) return;
      if (!(0, _commonFunc.isValuableObj)(this.currentNode)) {
        this.knowledgeList = [];
        return;
      }
      this.listLoading = true;
      const space_id = this.workspaceInfo.id;
      const res = await (0, _knowledgeLibrary.getKnowledgeList)({
        catalog_id: this.currentNode.id,
        space_id
      }).finally(() => {
        this.listLoading = false;
      });
      if (res.code === 20000) {
        const list = this.getInitKnowledgeList(res.results.data);
        this.knowledgeList = this.getCheckedKnowledgeList(list);
        this.checkIsCheckAll();
        this.$nextTick(() => {
          this.navigateToKnowledge();
          if (this.isCheckByMap) {
            this.handleKnowledgeCheckedChange(true, this.targetKnowledge.id);
          }
        });
      }
    },
    handleKnowledgeCheckedChange(checked, id) {
      const index = this.knowledgeList.findIndex(item => item.id === id);
      if (index === -1) return;
      const knowledgeItem = this.knowledgeList[index];
      this.$set(this.knowledgeList, index, this.getFormattedKnowledge(checked, knowledgeItem));
      this.checkIsCheckAll();
      const removeId = !checked ? id : '';
      this.buildMap(removeId);
      if (this.isCheckByMap) return;
      if (this.selectedList.some(item => item.id === id)) {
        this.selectedList = this.selectedList.filter(item => item.id !== id);
      } else {
        this.selectedList.push(knowledgeItem);
      }
      this.commitSelectedKnowledges(this.selectedList);
    },
    handleTrickItemCheckedChange(id) {
      let removeId;
      const knowledgeItem = this.knowledgeList.find(i => i.id === id);
      const checkedCount = this.getCheckedTricksCount(knowledgeItem);
      if (!checkedCount) {
        removeId = id;
        this.checkKnowledgeItem(id, false);
      } else if (!knowledgeItem.checked) {
        this.checkKnowledgeItem(id, true);
      }
      this.checkIsCheckAll();
      this.buildMap(removeId);
    },
    checkKnowledgeItem(id, checked) {
      this.knowledgeList.forEach(i => {
        if (i.id === id) {
          i.checked = checked;
        }
      });
    },
    handleSelectKnowledges(ids) {
      this.knowledgeList = this.knowledgeList.map(item => ({
        ...item,
        checked: ids.includes(item.id)
      }));
      this.selectedList = ids.map(id => this.id2KnowledgeMap.get(id)).filter(Boolean);
    },
    getFormattedKnowledge(checked, item) {
      // 给每个话术设置checked状态
      const copy = JSON.parse(JSON.stringify(item));
      return {
        ...copy,
        checked,
        scene_reply_list: copy.scene_reply_list.filter(item => item.reply_list).map(item => ({
          ...item,
          reply_list: item.reply_list.map(item => ({
            ...item,
            checked
          }))
        }))
      };
    },
    getCheckedTricksCount(item) {
      const list = item.scene_reply_list.map(item => item.reply_list).flat().filter(Boolean);
      return list.filter(item => item.checked).length;
    },
    buildMap(removeId) {
      // 构建目录id => 知识点列表映射
      const catalogId = this.currentNode.id;
      const hasCheckedKnowledge = this.knowledgeList.some(item => item.checked);
      if (!hasCheckedKnowledge && !(0, _commonFunc.isValuableObj)(this.taskInfo)) {
        this.id2KnowledgeListMap.delete(catalogId);
        return;
      }
      const list = this.knowledgeList.filter(item => item.checked);
      this.id2KnowledgeListMap.set(catalogId, list);
      if ((0, _commonFunc.isValuableObj)(this.taskInfo)) {
        this.handleBackfillKnowledges(removeId);
      }
    },
    commitSelectedKnowledges(val) {
      this.$emit('setSelectedKnowledges', val);
    },
    getInitKnowledgeList(list) {
      // 添加checked:false 为了解决创建任务后再打开抽屉会有目录仍然是勾选状态的问题
      return list.map(item => ({
        ...item,
        checked: false,
        fid: this.currentNode.id,
        scene_reply_list: item.scene_reply_list.map(item => ({
          ...item,
          reply_list: (item.reply_list || []).map(item => ({
            ...item,
            checked: false
          }))
        }))
      }));
    },
    getCheckedKnowledgeList(list) {
      if ((0, _commonFunc.isValuableObj)(this.taskInfo)) {
        return this.getListByTaskInfo(list);
      } else if (this.isCheckByMap) {
        return this.getListByMap(list);
      } else {
        return this.getListBySelectedKnowledges(list);
      }
    },
    getListByMap(list) {
      const catalogId = this.currentNode.id;
      if (!this.id2KnowledgeListMap.has(catalogId)) return list;
      const prevCheckedList = this.id2KnowledgeListMap.get(catalogId);
      return list.map(item => {
        const matchItem = prevCheckedList.find(i => i.id === item.id);
        return matchItem || item;
      });
    },
    // 日常辅导（留下辅导建议）模块的逻辑
    getListBySelectedKnowledges(list) {
      return list.map(item => ({
        ...item,
        checked: this.selectedKnowledgeIds.includes(item.id)
      }));
    },
    // 编辑任务时的回填
    getListByTaskInfo(list) {
      console.log('=====', this.backfillKnowledges);
      const checkedKnowledgeIds = this.backfillKnowledges.map(i => i.id);
      const checkedTrickIds = this.backfillKnowledges.map(i => {
        return i.scene_reply_list.map(i => i.reply_list).flat();
      }).flat();
      return list.map(i => ({
        ...i,
        checked: checkedKnowledgeIds.includes(i.id),
        scene_reply_list: i.scene_reply_list.map(i => ({
          ...i,
          reply_list: i.reply_list.map(i => ({
            ...i,
            checked: checkedTrickIds.includes(i.reply_id)
          }))
        }))
      }));
    },
    handleBackfillKnowledges(removeId) {
      // removeId：取消勾选的知识点id
      // 勾选知识点/话术后，数据同步到backfillKnowledges(增、删、改)
      const knowledges = [...this.id2KnowledgeListMap.values()].flat();
      knowledges.forEach(item => {
        const index = this.backfillKnowledges.findIndex(i => i.id === item.id);
        if (index !== -1) {
          this.backfillKnowledges[index] = this.formatKnowledgeItem(item);
        } else {
          this.backfillKnowledges.push(this.formatKnowledgeItem(item));
        }
      });
      if (removeId) {
        this.backfillKnowledges = this.backfillKnowledges.filter(i => i.id !== removeId);
      }
      this.emitKnowledges();
    },
    formatKnowledgeItem(item) {
      return {
        id: item.id,
        scene_reply_list: item.scene_reply_list.map(item => {
          return {
            scene_id: item.scene_id,
            reply_list: (item.reply_list || []).filter(item => item.checked).map(item => item.reply_id)
          };
        }).filter(item => item.reply_list.length)
      };
    },
    emitKnowledges() {
      this.$emit('knowledgesChange', this.backfillKnowledges);
    },
    clearCheckStatus() {
      this.id2KnowledgeListMap.clear();
      this.backfillKnowledges = [];
      this.getKnowledgeList();
    },
    handleCheckAll(isChecked) {
      this.isCheckAll = isChecked;
      this.knowledgeList = this.knowledgeList.map(i => {
        return this.getFormattedKnowledge(isChecked, i);
      });
      this.buildMap();
    },
    checkIsCheckAll() {
      this.isCheckAll = this.knowledgeList.every(i => i.checked);
    }
  }
};
exports.default = _default;