"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Audioline = _interopRequireDefault(require("@/components/Audioline"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
var _vuex = require("vuex");
require("video.js/dist/video-js.css");
var _IdentityModel = _interopRequireDefault(require("./IdentityModel"));
var _noviceGuide = _interopRequireDefault(require("./noviceGuide"));
var _sumbitDialog = _interopRequireDefault(require("./sumbitDialog"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function realFormatSecond(second) {
  var secondType = typeof second;
  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second);
    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    if (hours) {
      return ('0' + hours).slice(-2) + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
    } else {
      return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
    }
  } else {
    return '00:00';
  }
}
var _default = {
  name: 'ConvControlContent',
  components: {
    AudioLine: _Audioline.default,
    SvgIcon: _SvgIcon.default,
    NoviceGuide: _noviceGuide.default,
    IdentityModel: _IdentityModel.default,
    SumbitDialog: _sumbitDialog.default,
    ImageAvatar: _ImageAvatar.default
  },
  inject: ['reload'],
  props: {
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    url: {
      type: String,
      required: ''
    },
    sharePercent: {
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: ''
    },
    partiInfo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    shareScreen: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeRoundId: {
      type: [Number, String],
      default: null
    },
    uploadType: {
      type: String,
      default: ''
    },
    mediaType: {
      type: String,
      default: 'audio'
    },
    hasRole: {
      type: Boolean,
      default: true
    },
    localSliderTime: {
      type: Number,
      default: 0
    },
    videoDuration: {
      type: Number,
      default: 0
    },
    convDetailInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      voice: 100,
      originVoice: 100,
      modifyArr: [],
      sumbitVisible: false,
      spilitColor: ['#7186EF', '#EE779F', '#71C7C3', '#E3D37A', '#B57DCF', '#DE876B', '#83C074', '#EF7474'],
      canPlay: true,
      playbackRates: [1, 1.25, 1.5, 2],
      // 倍速播放配置
      currBackRate: 1,
      backrateVisible: false,
      audioList: [],
      peopleFlag: false,
      hasFirstPlayClick: false,
      isControlsShow: true,
      sliderTime: 0,
      isShow: true,
      localShareScreen: this.shareScreen
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['audio', 'curtime', 'sliderTimeRange', 'maxSilenceDuration', 'hangUp', 'player', 'buffTime', 'video', 'hasVideo', 'clickCommentBtnTimes']),
    getSpiltColor() {
      return function (index) {
        if (!index) {
          return this.spilitColor[0];
        }
        return this.spilitColor[index % 8];
      };
    },
    backrates() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.playbackRates.reverse();
    },
    getScreenInfo() {
      if (this.localShareScreen && this.localShareScreen.length > 0) {
        this.localShareScreen.forEach((item, index) => {
          if (!item.entity_id) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.localShareScreen.splice(index, 1);
          } else {
            const color = this.getColorById(item.entity_id);
            this.$set(item, 'color', color);
          }
        });
      }
      this.$store.commit('library/SET_SHARE_INFO_OBJ', {
        sharePercent: this.sharePercent,
        shareScreenList: this.localShareScreen
      });
      return this.localShareScreen;
    },
    getPresentLength() {
      let length = 0;
      this.audioList.forEach(item => {
        if (item.isPresent) {
          length++;
        }
      });
      return length;
    },
    getLineWrapperHeight() {
      var _this$convDetailInfo;
      return this.isShow ? (_this$convDetailInfo = this.convDetailInfo) !== null && _this$convDetailInfo !== void 0 && _this$convDetailInfo.is_group ? '140px' : '100px' : 0;
    }
  },
  watch: {
    uploadType: {
      handler: function (value) {
        if (!value) {
          this.peopleFlag = true;
        } else {
          this.peopleFlag = false;
        }
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    },
    clickCommentBtnTimes(val) {
      if (val) {
        const video = this.player.children_[0];
        video && video.pause();
      }
    },
    modifyArr(val) {
      const flag = val.every(item => {
        return item.id !== 0;
      });
      if (val.length === 2 && flag) {
        this.sumbitVisible = true;
      }
    },
    url(value) {
      this.$nextTick(() => {
        this.spiltAudio(this.audio);
      });
    },
    audio(val) {
      this.spiltAudio(val);
    },
    audioList(val) {
      this.$bus.$emit('audioList', val);
    },
    currBackRate(val) {
      this.player.children_[0].playbackRate = val;
    },
    localSliderTime(val) {
      this.sliderTime = val;
    }
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    formatSecond() {
      let second = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return realFormatSecond(second);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.player) {
        var _this$player;
        (_this$player = this.player) === null || _this$player === void 0 ? void 0 : _this$player.volume(this.voice / 100);
        this.player.on('fullscreenchange', e => {
          this.isControlsShow = !this.player.isFullscreen();
          this.player.controls(this.player.isFullscreen());
          // eslint-disable-next-line no-sequences
        });

        this.player.on('volumechange', e => {
          this.voice = Math.round(this.player.volume() * 100);
          if (this.player.muted()) {
            this.voice = 0;
          } else {
            this.originVoice = this.voice;
          }
        });
        this.player.on('ratechange', e => {
          this.currBackRate = this.player.playbackRate();
        });
      }
    });
    this.$bus.$on('openIdentityPopover', this.handleOpen);
    this.$bus.$on('dropVideo', this.dropCurrentTime);
  },
  beforeDestroy() {
    var _this$player2;
    if (this.player) {
      this.player.dispose();
    }
    if ((_this$player2 = this.player) !== null && _this$player2 !== void 0 && _this$player2.isInPictureInPicture()) {
      this.player.exitPictureInPicture();
    }
    this.$bus.$off('openIdentityPopover');
    this.$bus.$off('dropVideo');
  },
  methods: {
    handleAvatar(id, type) {
      var _this$partiInfo$find;
      return (_this$partiInfo$find = this.partiInfo.find(item => item.entity_id == id && item.entity_type === type)) === null || _this$partiInfo$find === void 0 ? void 0 : _this$partiInfo$find.avatar;
    },
    switchAudioLine() {
      this.isShow = !this.isShow;
    },
    isHangUpPerson(type) {
      const salesRoles = ['host_salesman', 'assistant_salesman', 'spectator_salesman'];
      if (salesRoles.includes(type)) {
        return this.hangUp === 2;
      } else {
        return this.hangUp === 1;
      }
    },
    handleSliderInput(range) {
      this.$store.commit('video/SET_SNIPPET_SLIDER_RANGE', range);
    },
    // 剪辑
    snippetVideoSliderChange(range) {
      if (this.mediaType === 'doc') {
        return;
      }
      const beginTime = parseInt(range[0] / 100 * this.video.maxTime);
      this.sliderTime = parseInt(this.player.children_[0].currentTime / this.video.maxTime * 100);
      this.$store.dispatch('video/get_cur_order', {
        current_time: beginTime,
        time: new Date().getTime()
      });
      this.$store.commit('video/SET_SNIPPET_VIDEO_TIME', {
        beginTime: range[0] / 100 * this.video.maxTime,
        endTime: range[1] / 100 * this.video.maxTime,
        range
      });
    },
    changeIsMuted() {
      var _this$player3;
      const isMuted = this.player.muted();
      if (isMuted) {
        this.voice = this.originVoice;
      } else {
        this.originVoice = this.voice;
        this.voice = 0;
      }
      // eslint-disable-next-line no-undef
      (_this$player3 = this.player) === null || _this$player3 === void 0 ? void 0 : _this$player3.muted(!isMuted);
    },
    handleVolumeSlider(val) {
      this.$nextTick(() => {
        var _this$player4, _this$player5;
        // eslint-disable-next-line no-undef
        (_this$player4 = this.player) === null || _this$player4 === void 0 ? void 0 : _this$player4.volume(val / 100);
        // eslint-disable-next-line no-undef
        if (val && (_this$player5 = this.player) !== null && _this$player5 !== void 0 && _this$player5.muted()) {
          var _this$player6;
          // eslint-disable-next-line no-undef
          (_this$player6 = this.player) === null || _this$player6 === void 0 ? void 0 : _this$player6.muted(false);
        }
      });
    },
    changeSumbitVisible() {
      this.sumbitVisible = false;
    },
    handleSelect(nameItem) {
      const pushFlag = this.modifyArr.some(item => {
        // eslint-disable-next-line eqeqeq
        return item.participantId == nameItem.participantId;
      });
      if (!pushFlag) {
        this.modifyArr.push(nameItem);
      } else {
        for (let i = 0; i < this.modifyArr.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (this.modifyArr[i].participantId == nameItem.participantId) {
            this.modifyArr.splice(i, 1, nameItem);
          }
        }
      }
      this.modifyArr.sort((a, b) => {
        return a.participantId - b.participantId;
      });
    },
    handleOpen() {
      if (this.$refs['popover0'].length > 0) {
        this.$refs['popover0'][0].popVisiable = true;
      }
    },
    rewind() {
      const newTime = this.player.children_[0].currentTime - 15;
      this.player.children_[0].currentTime = newTime > 0 ? newTime : 0;
      this.player.children_[0].play();
      this.$store.dispatch('video/get_cur_order', {
        current_time: newTime,
        time: new Date().getTime()
      });
    },
    forward() {
      const newTime = this.player.children_[0].currentTime + 15;
      this.player.children_[0].currentTime = newTime < this.video.maxTime ? newTime : this.video.maxTime;
      this.player.children_[0].play();
      this.$store.dispatch('video/get_cur_order', {
        current_time: newTime,
        time: new Date().getTime()
      });
    },
    // 进度条toolTip
    formatProcessToolTip() {
      let index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      index = parseInt(this.video.maxTime / 100 * index);
      return '当前时间: ' + realFormatSecond(index);
    },
    // 播放跳转
    changeCurrentTime(index) {
      if (this.mediaType === 'doc') return;
      var curTime = index / 100 * this.video.maxTime;
      if (this.hasVideo && this.player.children_[0]) {
        // 如果是音视频
        this.player.children_[0].currentTime = curTime;
        this.hasVideo && this.player.children_[0].play();
      } else {
        // 如果是无源音视频
        curTime = index / 100 * this.videoDuration;
        this.$store.dispatch('video/change_current', {
          current_time: curTime
        });
      }
      this.$store.dispatch('video/get_cur_order', {
        current_time: curTime,
        time: new Date().getTime()
      });
      this.$store.dispatch('video/change_begin', {
        begin_time: curTime,
        time: new Date().getTime()
      });
    },
    dropCurrentTime(time, isVideoPlay) {
      const video = this.player.children_[0];
      if (video) {
        video.currentTime = time;
        if (isVideoPlay) {
          video.play();
        } else {
          video.pause();
        }
      }
      this.$store.dispatch('video/get_cur_order', {
        current_time: time,
        time: new Date().getTime()
      });
    },
    inputFun(index) {
      this.slideInput = true;
      setTimeout(() => {
        this.slideInput = false;
      }, 500);
    },
    startPlayOrPause() {
      if (!this.hasFirstPlayClick) {
        this.$emit('firstPlay');
        this.hasFirstPlayClick = true;
      }
      return this.video.playing ? this.pausePlay() : this.startPlay();
    },
    // 开始播放
    startPlay() {
      console.log(this.player);
      this.player.children_[0].play();
    },
    // 暂停
    pausePlay() {
      var _this$player$children;
      (_this$player$children = this.player.children_[0]) === null || _this$player$children === void 0 ? void 0 : _this$player$children.pause();
    },
    spiltAudio(val) {
      this.audioList = [];
      this.partiInfo.forEach((item, index) => {
        const obj = {
          id: item.entity_id,
          name: item.name,
          type: item.entity_type,
          detail: [],
          maxSilenceDuration: this.maxSilenceDuration,
          percent: item.speak_percent.toFixed(2),
          isPresent: item.is_present ? item.is_present : true,
          participantId: item.participant_id,
          entityType: item.entity_type,
          speakTime: item.speak_time
        };
        if (item.speak_time) {
          this.audioList.push(obj);
        }
      });
      if (val) {
        val.forEach((item, index) => {
          this.audioList.forEach(audio => {
            if (item.entity_type === audio.entityType) {
              if (item.entity_id === audio.id) {
                audio.detail.push(item);
              }
            }
          });
        });
      }
      this.sortRoleItem();
      this.appendColor();
      this.$store.commit('library/SET_AUDIO_INFO_LIST', this.audioList);
    },
    sortRoleItem() {
      const candidateList = [];
      const interviewerList = [];
      const assignList = [];
      for (let i = 0; i < this.audioList.length; i++) {
        if (this.audioList[i].type === 'candidate') {
          candidateList.push(this.audioList[i]);
        } else if (this.audioList[i].type === 'interviewer') {
          interviewerList.push(this.audioList[i]);
        } else {
          assignList.push(this.audioList[i]);
        }
      }
      this.sortKey(assignList, 'percent');
      const flag = this.audioList.every(item => {
        return !item.type;
      });
      if (flag || !candidateList.length && !interviewerList.length) {
        // eslint-disable-next-line no-self-assign
        this.audioList = this.audioList;
      } else {
        this.audioList = candidateList.concat(interviewerList, assignList);
      }
    },
    appendColor() {
      this.audioList.forEach((item, index) => {
        item.color = this.getSpiltColor(index);
      });
    },
    getColorById(id) {
      for (var item of this.audioList) {
        // eslint-disable-next-line eqeqeq
        if (item.id == id) {
          return item.color;
        }
      }
    },
    sortKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    },
    changeBackrate(rate) {
      this.currBackRate = rate;
      this.backrateVisible = false;
    },
    getTypeStr(type, index) {
      var _this$$route$query;
      const detailType = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.type;
      if (detailType === 'media') return '';
      // 找到过滤后的list里第一个type === 'customer_contact'的索引
      const indexNum = this.audioList.findIndex(item => {
        return item.type === 'customer_contact';
      });
      if (type === 'customer_contact') {
        var _this$convDetailInfo2;
        return `客户${(_this$convDetailInfo2 = this.convDetailInfo) !== null && _this$convDetailInfo2 !== void 0 && _this$convDetailInfo2.is_group ? index - indexNum + 1 : ''}`;
      } else {
        var _this$convDetailInfo3;
        return `销售${(_this$convDetailInfo3 = this.convDetailInfo) !== null && _this$convDetailInfo3 !== void 0 && _this$convDetailInfo3.is_group ? index + 1 : ''}`;
      }
    }
  }
};
exports.default = _default;