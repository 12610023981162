"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/components/LoginProcessComp/layout"));
var _LoginComp = _interopRequireDefault(require("@/components/LoginProcessComp/LoginComp"));
var _twoFactorLogin = _interopRequireDefault(require("@/components/LoginProcessComp/LoginComp/twoFactorLogin.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Login',
  components: {
    LoginLayout: _layout.default,
    LoginComp: _LoginComp.default,
    // 常规登录
    TwoFactorLogin: _twoFactorLogin.default // 双因素登录
  }
};

exports.default = _default;