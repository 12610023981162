"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'AddKeyEventTrackerPopover',
  props: {
    showAddKeyEventTrackerPopover: {
      type: Boolean,
      default: false
    },
    eventOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectEventId: 0,
      loading: false
    };
  },
  watch: {
    showAddKeyEventTrackerPopover(val) {
      if (val) {
        this.$nextTick(() => {
          // 自动展开级联面板
          this.$refs['eventCascaderRef'].toggleDropDownVisible(true);
        });
      } else {
        this.loading = false;
      }
    }
  },
  methods: {
    handleCancelAddKeyEventTracker() {
      this.$emit('handleCancelAddKeyEventTracker');
    },
    handleChange(val) {
      this.selectEventId = val;
    },
    handleSubmitAddEventTracker() {
      this.loading = true;
      this.$emit('handleSubmitAddEventTracker', this.selectEventId);
    },
    changeSelectedEventId(ids) {
      this.selectEventId = ids;
    }
  }
};
exports.default = _default;