"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ConstMap = require("@/components/Message/components/ConstMap.js");
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TextJoint',
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  computed: {},
  methods: {
    getClassObject(ele) {
      return {
        'element-text': true,
        'element-icon': ele.type === 'icon',
        'element-time': ele.type === 'time',
        'element-link': ele.router_name
      };
    },
    getHtml(ele) {
      let html = '';
      switch (ele.type) {
        case 'icon':
          html = `<i class="iconfont icon-${_ConstMap.ICONMAP[ele.value]}"></i>`;
          break;
        case 'time':
          html = this.$plus8ToCurrentZone(ele.value).format('M-D HH:mm');
          break;
        default:
          html = ele.value;
          break;
      }
      return html;
    },
    getStyleObject(ele) {
      const obj = {};
      if (ele.style) {
        ele.style.forEach(item => {
          if (item === 'bold') {
            obj.fontWeight = 600;
          }
          if (item === 'italic') {
            obj.fontStyle = item;
          }
          if (['underline', 'line-through'].includes(item)) {
            obj.marginLeft = '0px';
            obj.textDecorationLine = item;
          }
        });
      }
      if (ele.color) {
        obj.color = ele.color;
      }
      if (ele.font_size) {
        obj.fontSize = _variablesModule.default[ele.font_size] || '14px';
      }
      return obj;
    },
    linkTo(ele) {
      if (!ele.router_name) return;
      const path = _routeMap.ROUTES[ele.router_name];
      const query = ele.router_params;
      const routeData = this.$router.resolve({
        path,
        query
      });
      this.$emit('updateReadStatus');
      window.open(routeData.href, '_blank');
    }
  }
};
exports.default = _default;