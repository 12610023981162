"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-permission-page"
  }, [_c("img", {
    staticClass: "megaview-logo",
    attrs: {
      src: require("@/assets/logo/logo.svg")
    }
  }), _c("div", {
    staticClass: "no-permission-info"
  }, [_vm._m(0), _c("span", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", {
    staticClass: "content"
  }, [_c("ul", _vm._l(_vm.tip, function (item) {
    return _c("li", {
      key: item,
      staticClass: "content-row"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]), _vm.info ? _c("div", {
    staticClass: "err-params"
  }, [_vm._v("问题参数：" + _vm._s(_vm.info))]) : _vm._e(), _vm._m(1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "iconfont icon-risk"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tech-support-qrcode"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/techSupport/tech-support.jpg")
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;