"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'FormItemHeader',
  props: {
    name: {
      type: String,
      default: '未命名评价单'
    },
    description: {
      type: String,
      default: ''
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    (0, _vue.useCssVars)((_vm, _setup) => ({
      "2ed581bb-headerStyle_paddingBottom": _setup.headerStyle.paddingBottom,
      "2ed581bb-headerStyle_fontSize": _setup.headerStyle.fontSize,
      "2ed581bb-headerStyle_lineHeight": _setup.headerStyle.lineHeight
    }));

    // 标题字体大小
    const titleStyle = {
      fontSize: '22px',
      lineHeight: '31px',
      paddingBottom: '24px'
    };
    const itemStyle = {
      fontSize: '14px',
      lineHeight: '20px',
      paddingBottom: '8px'
    };
    const headerStyle = props.isTitle ? titleStyle : itemStyle;
    return {
      __sfc: true,
      props,
      titleStyle,
      itemStyle,
      headerStyle
    };
  }
};
exports.default = _default;