"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.markNodeData = exports.getNodeKey = exports.findNearestComponent = exports.NODE_KEY = void 0;
const NODE_KEY = '$treeNodeId';
exports.NODE_KEY = NODE_KEY;
const markNodeData = function (node, data) {
  if (!data || data[NODE_KEY]) return;
  Object.defineProperty(data, NODE_KEY, {
    value: node.id,
    enumerable: false,
    configurable: false,
    writable: false
  });
};
exports.markNodeData = markNodeData;
const getNodeKey = function (key, data) {
  if (!key) return data[NODE_KEY];
  return data[key];
};
exports.getNodeKey = getNodeKey;
const findNearestComponent = (element, componentName) => {
  let target = element;
  while (target && target.tagName !== 'BODY') {
    if (target.__vue__ && target.__vue__.$options.name === componentName) {
      return target.__vue__;
    }
    target = target.parentNode;
  }
  return null;
};
exports.findNearestComponent = findNearestComponent;