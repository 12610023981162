"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MoreOptionDropdown = _interopRequireDefault(require("@/components/MoreOptionDropdown"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KnowledgeLibraryCardView',
  props: {
    cardList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['handleCommand'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const getDropdownConfigs = item => {
      const dropdownConfigs = [{
        type: 'remove',
        label: item.is_top ? '移出常用' : '设为常用',
        icon: item.is_top ? 'icon-thumbtack-fill' : 'icon-thumbtack',
        iconColor: item.is_top ? '#FFB433' : ''
      }];
      if (item.permission > 1) {
        dropdownConfigs.push({
          type: 'rename',
          label: '重命名',
          icon: 'icon-edit'
        }, {
          type: 'permission',
          label: '权限',
          icon: 'icon-unlock'
        }, {
          type: 'delete',
          label: '删除',
          icon: 'icon-delete'
        });
      }
      return dropdownConfigs;
    };
    return {
      __sfc: true,
      props,
      emit,
      getDropdownConfigs,
      MoreOptionDropdown: _MoreOptionDropdown.default
    };
  }
};
exports.default = _default;