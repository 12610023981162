"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ThirdStep',
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    editParams: {
      type: Object,
      default: () => ({})
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      form: {
        test_group: [],
        control_group: []
      },
      rules: {
        test_group: [{
          type: 'array',
          required: true,
          message: '请选择成员',
          trigger: 'change'
        }]
      },
      label: ['A', 'B', 'C', 'D', 'E'],
      // 用于选择器回填
      testMemberInfo: [],
      controlMemberInfo: []
    };
  },
  watch: {
    editParams: {
      handler(val) {
        const {
          range_configs
        } = val;
        if (range_configs) {
          const {
            contrast,
            source_info
          } = range_configs;
          // 实验组数据
          this.testMemberInfo = [...source_info.users, ...this.addTreeIdPrefix(source_info.trees)];
          this.form.test_group = this.testMemberInfo.map(_ref => {
            let {
              id
            } = _ref;
            return id;
          });
          // 对照组数据
          if (contrast) {
            this.controlMemberInfo = contrast.map(_ref2 => {
              let {
                trees,
                users
              } = _ref2;
              return [...users, ...this.addTreeIdPrefix(trees)];
            });
            this.form.control_group = this.controlMemberInfo.map(item => {
              return {
                member_ids: item.map(_ref3 => {
                  let {
                    id
                  } = _ref3;
                  return id;
                }),
                id: Symbol()
              };
            });
          }
        } else {
          this.reset();
        }
        this.clearValidate();
      },
      deep: true
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const source_info = {
            name: '实验组',
            ...this.getTreeUserIds(this.form.test_group)
          };
          const contrast = this.form.control_group.map((_ref4, index) => {
            let {
              member_ids
            } = _ref4;
            return {
              name: '对照组' + this.label[index],
              ...this.getTreeUserIds(member_ids)
            };
          });
          this.$emit('change', {
            range_configs: {
              source_info,
              contrast
            }
          });
        }
      });
    },
    deleteItem(index) {
      this.form.control_group.splice(index, 1);
    },
    addItem() {
      this.form.control_group.push({
        member_ids: [],
        id: Symbol()
      });
    },
    handleSelectChange(value) {
      this.form.test_group = value;
    },
    handleControlGroupChange(index, value) {
      this.form['control_group'][index].member_ids = value;
    },
    getTreeUserIds(tree_user_ids) {
      const tree_ids = [];
      const user_ids = [];
      tree_user_ids.forEach(item => {
        String(item).startsWith('tree_id_') ? tree_ids.push(Number(item.replace('tree_id_', ''))) : user_ids.push(Number(item));
      });
      return {
        tree_ids,
        user_ids
      };
    },
    addTreeIdPrefix(trees) {
      return trees.map(_ref5 => {
        let {
          id,
          name
        } = _ref5;
        return {
          id: 'tree_id_' + id,
          name
        };
      });
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
    reset() {
      this.form = this.$options.data().form;
      this.testMemberInfo = [];
      this.controlMemberInfo = [];
    }
  }
};
exports.default = _default;