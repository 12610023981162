"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-text-box"
  }, [_vm.cardConfig.data ? [_c("card-text-main-data", {
    attrs: {
      "card-config": _vm.cardConfig,
      "unit-config": _vm.unitConfig
    }
  }), _c("div", {
    staticClass: "ring-data"
  }, [_vm.cardConfig.data[1] !== null ? [_c("span", [_vm._v("环比")]), _vm.cardConfig.data[1] ? _c("div", {
    class: ["icon", _vm.cardConfig.data[1] > 0 ? "increase" : "reduce"]
  }) : _vm._e(), _c("span", {
    staticClass: "data"
  }, [_vm._v(_vm._s(Math.abs(_vm.cardConfig.data[1])) + "%")]), _c("el-tooltip", {
    attrs: {
      placement: "bottom",
      content: "该时间段的数值和上一时间段的数值的对比"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])] : [_vm._v(" 暂无环比数据 ")]], 2)] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;