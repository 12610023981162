"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _FuncRoleDrawer = _interopRequireDefault(require("./FuncRoleDrawer"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OperateMemberDialog',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MembersFinder: _MembersFinder.default,
    FuncRoleDrawer: _FuncRoleDrawer.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    roleList: {
      type: Array,
      default: () => []
    },
    operateType: {
      type: String,
      default: ''
    },
    memberInfo: {
      type: Object,
      default: () => ({})
    },
    multiMembersList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      membersFinderTreeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      operateMemberForm: {
        user_ids: [],
        role_ids: []
      },
      rules: {
        user_ids: [{
          required: true,
          message: '请选择成员',
          trigger: 'change'
        }],
        role_ids: [{
          required: true,
          message: '请选择业务角色',
          trigger: 'change'
        }]
      },
      newRoleList: [],
      funcRoleDrawerVisible: false
    };
  },
  computed: {
    dialogTitle() {
      const dialogTitle = {
        add: '添加成员',
        edit: '编辑成员',
        batchEdit: '批量修改业务角色'
      };
      return dialogTitle[this.operateType];
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.newRoleList = this.roleList.filter(item => item.id !== 0);
        switch (this.operateType) {
          case 'edit':
            // eslint-disable-next-line no-case-declarations
            const role_ids = this.memberInfo.role_list.map(item => item.id);
            if (role_ids.length) {
              this.operateMemberForm.role_ids = role_ids;
            }
            this.operateMemberForm.user_ids = [this.memberInfo.user_id];
            break;
          case 'batchEdit':
            this.$nextTick(() => {
              const selectedMemberObj = this.multiMembersList.map(item => {
                return {
                  id: item.user_id,
                  name: item.name
                };
              });
              const selectedMemberIds = this.multiMembersList.map(item => item.user_id);
              this.operateMemberForm.user_ids = selectedMemberIds;
              this.$refs['memberFinder'].selection = selectedMemberIds;
              this.$refs['memberFinder'].memberOptions = selectedMemberObj;
            });
            break;
          default:
            break;
        }
      }
    }
  },
  methods: {
    openPermissionDrawer() {
      this.funcRoleDrawerVisible = true;
    },
    closeFuncRoleDrawer() {
      this.funcRoleDrawerVisible = false;
    },
    submitFormData() {
      this.$refs['operateMemberForm'].validate(async valid => {
        if (valid) {
          const tree_ids = [];
          const user_ids = [];
          this.operateMemberForm.user_ids.forEach(item => {
            String(item).includes('tree_id') ? tree_ids.push(item.split('_').pop() - 0) : user_ids.push(item);
          });
          const params = {
            tree_ids,
            user_ids,
            role_ids: this.operateMemberForm.role_ids
          };
          const res = await (0, _permissions.updateMembersRole)(params);
          if (res.code === 200) {
            this.$message.success('修改成功');
            this.$emit('updateMemberRoleSuccess');
            this.closeDialog();
          }
        }
      });
    },
    closeDialog() {
      this.operateMemberForm.user_ids = [];
      this.resetForm();
      this.$emit('update:dialogVisible', false);
      this.operateMemberForm.role_ids = [];
    },
    resetForm() {
      if (this.operateType === 'batchEdit') {
        this.$refs['memberFinder'].selection = [];
      }
      this.$refs['operateMemberForm'].resetFields();
      this.$nextTick(() => {
        this.$refs['operateMemberForm'].clearValidate();
      });
    },
    handleMemberChange(val) {
      this.operateMemberForm.user_ids = val;
    },
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    }
  }
};
exports.default = _default;