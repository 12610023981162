"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
var _selectMixins = _interopRequireDefault(require("./selectMixins"));
var _SavedFiltersContent = _interopRequireDefault(require("./SavedFiltersContent.vue"));
var _getDateRange = require("@/utils/getDateRange");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SavedFilters',
  components: {
    SavedFiltersContent: _SavedFiltersContent.default
  },
  mixins: [_selectMixins.default],
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    }
  },
  props: {
    stageOptions: {
      type: Array,
      default: () => []
    },
    customFilterObject: {
      type: Object,
      default: () => ({})
    },
    conversationListCustomFilter: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择一个常用筛选'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    getFiltersMethod: {
      type: Function,
      default: _conversationList.getFilters
    }
  },
  data() {
    return {
      selectValue: '',
      filterList: [],
      customFilterArray: [],
      loading: false
    };
  },
  // 处理商机阶段回显
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    getStatusName() {
      return val => {
        var _current$;
        const current = this.stageOptions.filter(item => {
          return item.id === val;
        });
        return (_current$ = current[0]) === null || _current$ === void 0 ? void 0 : _current$.stage_name;
      };
    },
    isShowInvalidTips() {
      var _this$selectValue$fil, _this$selectValue$fil2;
      return typeof this.selectValue !== 'string' && ((_this$selectValue$fil = this.selectValue.filter) === null || _this$selectValue$fil === void 0 ? void 0 : (_this$selectValue$fil2 = _this$selectValue$fil.event_invalid) === null || _this$selectValue$fil2 === void 0 ? void 0 : _this$selectValue$fil2.length);
    }
  },
  watch: {
    'workspaceInfo.id'(val) {
      this.filterList = [];
    },
    customFilterObject(val) {
      if (JSON.stringify(val) !== '{}') {
        this.customFilterArray = Object.values(val).reduce((acc, curr) => acc.concat(curr), []);
      }
    }
  },
  methods: {
    getFilters: _conversationList.getFilters,
    // 筛选器底部管理按钮
    manageClick() {
      this.$emit('openFilterManage');
    },
    // 下拉框的下拉事件
    handleFocus(v) {
      // 下拉框底部的管理按钮的逻辑
      if (!this.isBaseConvInDrawer) {
        this.selectBottomAction(v, {
          ref: 'select',
          label: '管理',
          click: this.manageClick
        });
      }
      this.getFiltersList({
        name: '',
        page: 1,
        size: 30,
        workspace_id: this.workspaceInfo.id
      });
    },
    // select的远程搜索方法
    searchFilter(val) {
      this.getFiltersList({
        name: val,
        workspace_id: this.workspaceInfo.id,
        page: 1,
        size: 999
      });
    },
    // 筛选器的选中值发生变化时触发
    filterChange(val) {
      this.$emit('filterChange', val);
    },
    // 获取筛选器列表
    async getFiltersList(params) {
      this.loading = true;
      const res = await this.getFiltersMethod(params).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.filterList = res.results.filters.map(item => {
          var _temp$filter$date;
          // 会话评分规则（isBaseConvInDrawer）不展示会话时间筛选信息
          if (this.isBaseConvInDrawer) {
            item.filter.date.range = [];
            item.filter.date.tag = '';
          }
          const temp = JSON.parse(JSON.stringify(item));
          const tag = (_temp$filter$date = temp.filter.date) === null || _temp$filter$date === void 0 ? void 0 : _temp$filter$date.tag;
          const filter = item.filter.filter;
          if (filter && !Array.isArray(filter)) {
            temp.filter.textFilterList = [filter];
            temp.filter.filter = filter.pattern.length ? [filter] : [];
            temp.filter.filter_relation = 'and';
          }
          if (tag) {
            temp.filter.date.range = this.getDateRange(tag);
          }
          return temp;
        });
      }
    },
    getDateRange(val) {
      switch (val) {
        case 'today':
          return (0, _getDateRange.getToday)();
        case 'yesterday':
          return (0, _getDateRange.getYesterday)();
        case 'this week':
          return (0, _getDateRange.getThisWeek)();
        case 'last week':
          return (0, _getDateRange.getLastWeek)();
        case 'last month':
          return (0, _getDateRange.getLastMonth)();
        default:
          break;
      }
    },
    handleVisibleChange(val) {
      if (val) document.body.click();
    }
  }
};
exports.default = _default;