"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LineChart',
  components: {
    ChartTemplate: _ChartTemplate.default
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    chartLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateInterval: 'day'
    };
  },
  computed: {
    checkedLable() {
      return this.dataIntervalOptions().find(item => item.value === this.dateInterval).label;
    },
    formatDateMapping() {
      return {
        hour: 'YYYY-MM-DD HH:mm',
        day: 'YYYY-MM-DD',
        week: 'YYYY-MM-DD',
        month: 'YYYY-MM'
      }[this.dateInterval];
    },
    // 用于折线图legend，前五项默认高亮，其他的保持置灰
    highLightLegend() {
      var _this$data$list;
      const result = {};
      (_this$data$list = this.data.list) === null || _this$data$list === void 0 ? void 0 : _this$data$list.forEach((item, index) => {
        if (index < 5) {
          result[item.name] = true;
        } else {
          result[item.name] = false;
        }
      });
      return result;
    },
    chartOption() {
      var _this$data$list2;
      return {
        grid: {
          containLabel: true,
          top: '18%',
          bottom: 20,
          left: 10,
          right: 20
        },
        legend: {
          selected: this.highLightLegend,
          left: 0,
          top: 8,
          type: 'scroll'
        },
        color: ['#3393FF', '#5CD6A3', '#FFB433', '#F43F7C', '#00B7B8', '#F28740', '#9A7DE8', '#FF5F33', '#FFE500', '#8A98A8'],
        tooltip: {
          confine: true,
          appendToBody: true,
          trigger: 'axis',
          formatter: val => {
            const items = val.map(item => {
              return `<p style="display: flex;justify-content: space-between;gap: 20px;">
                <span>${item.marker} ${item.seriesName}</span>
                <span>${item.value[1]}
                  ${this.data.type === 'percent' ? '%' : ''}</span>
              </p>`;
            });
            return `<div style="color: #2B323B;">
              <p>${val[0].axisValueLabel}</p>
              ${items.join('')}
              </div>`;
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: `{value}${this.data.type === 'percent' ? '%' : ''}`,
            color: '#576575'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#E2E5E9'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#576575'
          },
          axisLine: {
            lineStyle: {
              color: '#E2E5E9'
            }
          }
        },
        series: ((_this$data$list2 = this.data.list) === null || _this$data$list2 === void 0 ? void 0 : _this$data$list2.map(item => ({
          data: item.value.map(_ref => {
            let [date, value] = _ref;
            return [this.$moment(date).format(this.formatDateMapping), value];
          }),
          type: 'line',
          encode: {
            x: 0,
            y: 1
          },
          name: item.name
        }))) || []
      };
    }
  },
  methods: {
    dataIntervalOptions() {
      return [{
        label: '小时',
        value: 'hour'
      }, {
        label: '天',
        value: 'day'
      }, {
        label: '周',
        value: 'week'
      }, {
        label: '月',
        value: 'month'
      }];
    }
  }
};
exports.default = _default;