"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("../../components/ConversationFilterItem"));
var _AmountRangeInput = _interopRequireDefault(require("@/components/AmountRange/AmountRangeInput"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 销售说话占比 （%，最多支持两位小数）
// 最长销售独白 （秒，最多支持两位小数）
// 最长客户叙述 （秒，最多支持两位小数）
// 深入讨论次数 （次，只支持整数）
// 销售提问次数 （次，只支持整数）
// 客户提问次数 （次，只支持整数）
// 销售语速     (字/分钟，最多支持两位小数)
// 客户语速     (字/分钟，最多支持两位小数)
var _default = {
  __name: 'ConversationDimensionFilters',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const conversationDimensionFilters = [{
      type: 'percent_speak',
      name: '销售说话占比',
      unit: '%',
      valueType: {
        type: 'float',
        precisions: 2 // 单位百分位
      },

      content: '销售发言时间/总时间'
    }, {
      type: 'max_sales',
      name: '最长销售独白',
      unit: '秒',
      valueType: {
        type: 'float',
        precisions: 2
      },
      content: '本次会话销售最长发言时间'
    }, {
      type: 'max_customer',
      name: '最长客户叙述',
      unit: '秒',
      valueType: {
        type: 'float',
        precisions: 2
      },
      content: '本次会话客户最长发言时间'
    }, {
      type: 'discuss',
      name: '深入讨论次数',
      unit: '次',
      content: '本次会话客户发言时间超过20秒的次数'
    }, {
      type: 'question',
      name: '销售提问次数',
      unit: '次',
      content: '本次会话销售提问的次数'
    }, {
      type: 'customer_question',
      name: '客户提问次数',
      unit: '次',
      content: '本次会话客户提问的次数'
    }, {
      type: 'sales_speed',
      name: '销售语速',
      unit: '字/分钟',
      valueType: {
        type: 'float',
        precisions: 2
      },
      content: '销售发言字数/销售发言分钟数'
    }, {
      type: 'customer_speed',
      name: '客户语速',
      unit: '字/分钟',
      valueType: {
        type: 'float',
        precisions: 2
      },
      content: '客户发言字数/客户发言分钟数'
    }];

    // 销售/客户发言次数筛选
    const speakCountsChange = (value, type) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters[type] = value;
    };
    return {
      __sfc: true,
      conversationDimensionFilters,
      props,
      speakCountsChange,
      ConversationFilterItem: _ConversationFilterItem.default,
      AmountRangeInput: _AmountRangeInput.default
    };
  }
};
exports.default = _default;