"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importBusinessRole = importBusinessRole;
exports.importDepartments = importDepartments;
exports.importManagerRole = importManagerRole;
exports.importMembers = importMembers;
var _request = require("@/utils/request");
function importMembers(data) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.webService)({
    url: '/organization/user/import',
    method: 'post',
    data,
    ...config
  });
}
function importDepartments(data) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.webService)({
    url: '/organization/tree/import',
    method: 'post',
    data,
    ...config
  });
}
function importManagerRole(data) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.webService)({
    url: '/organization/role/manage/user/import',
    method: 'post',
    data,
    ...config
  });
}
function importBusinessRole(data) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.webService)({
    url: '/organization/role/func/user/import',
    method: 'post',
    data,
    ...config
  });
}