"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "business-field"
  }, [_c("el-tabs", {
    staticClass: "field-tabs",
    attrs: {
      type: "card"
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "字段",
      name: "field"
    }
  }, [_c("CustomFieldFilter", {
    attrs: {
      "current-custom-field-type": _vm.currentMenu
    },
    on: {
      filterChange: _vm.filterChange
    }
  }), _c("CustomFieldHeader", {
    attrs: {
      title: "字段",
      count: _vm.customFieldList.length
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.openCreateDialog
    }
  }, [_vm._v("新建字段")])], 1), _c("CustomFieldTable", {
    ref: "table",
    attrs: {
      list: _vm.customFieldList,
      "table-loading": _vm.tableLoading
    },
    on: {
      editField: _vm.editField,
      freshCustomFieldList: _vm.freshCustomFieldList
    }
  })], 1), _vm.currentMenu === "deal" ? _c("el-tab-pane", {
    attrs: {
      label: "商机阶段",
      name: "deal"
    }
  }, [_c("CustomFieldHeader", {
    attrs: {
      title: "商机阶段",
      desc: "（预测赢单率将用于商机分析模块）",
      "no-count": ""
    }
  }, [!_vm.isStageEditing ? _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.switchEdit
    }
  }, [_vm._v("编辑")]) : _c("div", {
    staticClass: "stage-editing"
  }, [_c("el-button", {
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.cancelSaveStageChange
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "medium",
      disabled: _vm.isDataChanged,
      loading: _vm.saveStageLoading
    },
    on: {
      click: _vm.saveStageChange
    }
  }, [_vm._v("保存")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorTip,
      expression: "errorTip"
    }],
    staticClass: "error-tip"
  }, [_vm._v(_vm._s(_vm.errorTip))])], 1)], 1), _c("business-custom-status", {
    ref: "businessCustomStatus",
    attrs: {
      "is-editing": _vm.isStageEditing
    },
    on: {
      dataHasChange: _vm.dataHasChange,
      updateSuccess: function ($event) {
        _vm.isStageEditing = false;
      }
    }
  })], 1) : _vm._e()], 1), _c("CustomFieldDialog", {
    attrs: {
      type: _vm.currentMenu,
      visible: _vm.createDialogVisible,
      "is-edit": _vm.isEdit,
      "edit-form": _vm.editForm,
      "custom-field-list": _vm.customFieldList
    },
    on: {
      success: _vm.handleSuccess,
      closeDialog: _vm.closeDialog
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;