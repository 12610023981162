"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computeFolders = computeFolders;
exports.editSnippet = editSnippet;
exports.getFlowVideoList = getFlowVideoList;
exports.getFold = getFold;
exports.getVideoList = getVideoList;
exports.moreFolds = moreFolds;
exports.removeFolder = removeFolder;
exports.removeSnippet = removeSnippet;
exports.renameFolder = renameFolder;
exports.resize = resize;
var _library = require("@/api/library");
/* eslint-disable eqeqeq */

// 请求文件夹接口
async function getFold(size, reload, type, that, page) {
  if (size) {
    if (type == 'folder') {
      const params = {
        size: size,
        page: page || that.folderPage,
        folder_id: that.fatherId ? that.fatherId : that.currentNode.id,
        space_id: that.workspaceInfo.id
      };
      const res = await (0, _library.getFolds)(params);
      that.foldTotal = res.results.total_count;
      if (reload) {
        that.subFolders = [];
      }
      res.results.data.forEach(item => {
        that.subFolders.push(item);
      });
      localStorage.setItem('total', that.subFolders.length);
    } else if (type == 'collect') {
      const paramsColl = {
        size: size,
        page: that.folderPage,
        space_id: that.workspaceInfo.id
      };
      const res = await (0, _library.getMyCollectFolders)(paramsColl);
      that.foldTotal = res.results.total_count;
      if (reload) {
        that.subFolders = [];
      }
      res.results.data.forEach(item => {
        that.subFolders.push(item);
      });
      localStorage.setItem('total', that.subFolders.length);
    }
  }
}

// 请求剪辑接口
async function getVideoList(page, reload, type, that, size) {
  that.loading = true;
  if (type == 'folder') {
    const params = {
      size: size || 10,
      page: page,
      folder_id: that.fatherId ? that.fatherId : that.currentNode.id,
      space_id: that.workspaceInfo.id
    };
    const res = await (0, _library.getVideos)(params);
    const {
      total_count,
      data
    } = res.results;
    that.videoTotal = total_count;
    if (reload) {
      that.snippetsList = [];
    }
    that.snippetsList.push(...data);
    that.loading = false;
  } else if (type == 'collect') {
    const paramsColl = {
      size: 10,
      page: page,
      space_id: that.workspaceInfo.id
    };
    const res = await (0, _library.getMyCollectSnippets)(paramsColl);
    const {
      total_count,
      data
    } = res.results;
    that.videoTotal = total_count;
    if (reload) {
      that.snippetsList = [];
    }
    that.snippetsList.push(...data);
    that.loading = false;
  }
}

// 点击加载更多文件夹
function moreFolds(that) {
  if (that.subFolders.length < that.foldTotal) {
    const leftTotal = that.foldTotal - that.subFolders.length;
    if (Math.floor(leftTotal / that.getFolderSzie) >= 0) {
      that.folderPage++;
    }
    getFold(that.getFolderSzie, false, that.contentType, that);
  }
  that.moreFolderFlag = true;
}

// 改变窗口大小时改变文件数量
function resize(that) {
  if (that.subFolders.length < that.foldTotal) {
    const fenHeight = (document.body.clientHeight - 72) / 2;
    const fenWidth = document.body.clientWidth - 417;
    let gao = Math.round(fenHeight / 144);
    let kuan = Math.floor(fenWidth / 270);
    if (gao <= 0) {
      gao = 1;
    }
    if (kuan <= 0) {
      kuan = 1;
    }
    const reNum = kuan * gao;
    if (reNum !== that.getFolderSzie) {
      if (!that.moreFolderFlag) {
        that.getFolderSzie = kuan * gao;
        getFold(that.getFolderSzie, true, that.contentType, that);
      }
    }
  }
}

// 计算半屏文件夹数量
function computeFolders(that) {
  that.folderGao = Math.round(that.fenHeight / 144);
  that.folderKuan = Math.floor(that.fenWidth / 270);
  if (that.folderGao <= 0) {
    that.folderGao = 1;
  }
  if (that.folderKuan <= 0) {
    that.folderKuan = 1;
  }
  that.getFolderSzie = that.folderKuan * that.folderGao;
}

// 下拉加载剪辑
function getFlowVideoList(that) {
  if (that.videoTotal && that.snippetsList.length && that.snippetsList.length < that.videoTotal) {
    const leftTotal = that.videoTotal - that.snippetsList.length;
    if (Math.floor(leftTotal / that.getFolderSzie) >= 0) {
      that.videoPage++;
    }
    getVideoList(that.videoPage, false, that.contentType, that);
  }
}

// 编辑文件夹
function renameFolder(data, that) {
  that.subFolders.forEach(item => {
    if (item.id == data.id) {
      item.name = data.name;
    }
  });
  that.fetchTreeData();
}

// 移动文件夹
function removeFolder(data, that) {
  let isDiscollect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  for (let i = 0; i < that.subFolders.length; i++) {
    if (that.subFolders[i].id === data) {
      that.subFolders.splice(i, 1);
    }
  }
  that.foldTotal--;
  if (that.subFolders.length < that.foldTotal) {
    const page = that.subFolders.length + 1;
    getFold(1, false, that.contentType, that, page);
  }
  if (!isDiscollect) {
    that.fetchTreeData();
  }
}

// 编辑剪辑
function editSnippet(data, that) {
  that.snippetsList.forEach(item => {
    if (item.id == data.id) {
      item.name = data.name;
      item.description = data.description;
    }
  });
}

// 移动剪辑
function removeSnippet(data, that) {
  if (data === '移动完成') {
    getVideoList(that.videoPage, true, that.contentType, that);
  } else {
    for (let i = 0; i < that.snippetsList.length; i++) {
      if (that.snippetsList[i].id == data) {
        that.snippetsList.splice(i, 1);
      }
    }
    that.videoTotal--;
    if (that.snippetsList.length < that.videoTotal) {
      const page = that.snippetsList.length + 1;
      getVideoList(page, false, that.contentType, that, 1);
    }
  }
}