"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ShowCustomFilter = _interopRequireDefault(require("@/components/ShowFiltersDetail/ShowCustomFilter.vue"));
var _ShowEventTrackerFilter = _interopRequireDefault(require("@/components/ShowFiltersDetail/ShowEventTrackerFilter.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ShowFiltersDetail',
  components: {
    ShowCustomFilter: _ShowCustomFilter.default,
    ShowEventTrackerFilter: _ShowEventTrackerFilter.default
  },
  props: {
    filtersDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      eventFilters: {}
    };
  },
  computed: {
    getFiltersCount() {
      var _this$eventFilters, _this$eventFilters$ke;
      return (((_this$eventFilters = this.eventFilters) === null || _this$eventFilters === void 0 ? void 0 : (_this$eventFilters$ke = _this$eventFilters.keyEventCascaderList) === null || _this$eventFilters$ke === void 0 ? void 0 : _this$eventFilters$ke.length) || 0) + Object.keys(this.filtersDetail.custom_filters || {}).length || 0;
    },
    getDateTtip() {
      const type = this.filtersDetail.date.type;
      return type === 'day' ? '天' : type === 'week' ? '周' : '月';
    },
    getDateRange() {
      const startDate = this.$moment(new Date()).startOf('day').subtract(this.filtersDetail.date.value, this.filtersDetail.date.type).format('YYYY-MM-DD HH:mm');
      const endDate = this.$moment(new Date()).endOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm');
      return `${startDate} ~ ${endDate}`;
    },
    getCurrentDateRange() {
      return `${this.$moment().startOf(this.filtersDetail.date.type).format('YYYY-MM-DD HH:mm')} ~ ${this.$moment().format('YYYY-MM-DD HH:mm')}`;
    },
    getDateFiltersDetail() {
      if (this.filtersDetail.dateTimeRange) {
        return `${this.filtersDetail.dateTimeRange[0]} ~ ${this.filtersDetail.dateTimeRange[1]}`;
      } else {
        const space = '&nbsp;&nbsp;';
        const {
          value,
          is_current
        } = this.filtersDetail.date;
        if (is_current) {
          const prefixLabel = this.getDateTtip === '天' ? '今天' : `本${this.getDateTtip}`;
          return `${prefixLabel}${space}${this.getCurrentDateRange}`;
        } else {
          return `过去${value}${this.getDateTtip}${space}${this.getDateRange}`;
        }
      }
    }
  },
  watch: {
    'filtersDetail.event_filters': {
      handler: function (val) {
        if (val.keyEventCascaderList) {
          const keyEventCascaderList = val.keyEventCascaderList.filter(_ref => {
            let {
              keyEventName
            } = _ref;
            return Boolean(keyEventName.length);
          });
          this.eventFilters = {
            keyEventCascaderList,
            event_engine_relation: 'and'
          };
        }
      },
      immediate: true
    }
  }
};
exports.default = _default;