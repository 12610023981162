"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _commonFunc = require("@/utils/commonFunc");
var _field = require("@/api/field");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomFieldTable',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCustomFieldList: [],
      status: true,
      dragging: false,
      draggableOptions: {
        handle: '.col-move',
        animation: 200,
        ghostClass: 'ghost',
        dragClass: 'drag-item',
        forceFallback: true,
        filter: 'forbid'
      },
      fieldType: {
        text: '单行文本',
        textarea: '多行文本',
        radio: '单选',
        select: '下拉框单选',
        checkbox: '多选',
        number: '数字',
        floatnumber: '非整数',
        percent: '百分数',
        date: '日期',
        datetime: '日期和时间',
        tel: '手机号',
        email: '邮箱',
        file: '附件',
        table: '表格',
        event: '关键事件',
        follow_up_by: '跟进人',
        speak_time: '跟进动态',
        risk: '风险',
        comment: '评论',
        money: '金额',
        link: '超链接'
      }
    };
  },
  watch: {
    list: {
      handler(val) {
        this.localCustomFieldList = val;
      },
      deep: true
    }
  },
  methods: {
    // 获取字段创建人
    getCustomFieldCreator(item) {
      var _item$create_by;
      return (_item$create_by = item === null || item === void 0 ? void 0 : item.create_by) !== null && _item$create_by !== void 0 ? _item$create_by : {};
    },
    async dragEnd(evt) {
      this.dragging = false;
      const {
        newIndex
      } = evt;
      const res = await (0, _field.moveCustomFieldPosition)({
        field_id: this.localCustomFieldList[newIndex].id,
        des_order: newIndex + 1
      });
      if (res.code === 200) {
        this.$emit('freshCustomFieldList');
      }
    },
    async updateField(item) {
      if (!item.changeable) return;
      const cloneItem = (0, _commonFunc.deepClone)(item);
      cloneItem.status ? cloneItem.status = 0 : cloneItem.status = 1;
      const res = await (0, _field.editCustomField)(cloneItem);
      if (res.code === 200) {
        this.$emit('freshCustomFieldList');
      }
    },
    async deleteCustomField(field_id) {
      const res = await (0, _field.deleteCustomField)({
        field_id
      });
      if (res.code === 200) {
        this.$message.success('删除成功');
        this.$emit('freshCustomFieldList');
      }
      this.closePopover(field_id);
    },
    closePopover(id) {
      this.$refs['delete-popover' + id][0].doClose();
    }
  }
};
exports.default = _default;