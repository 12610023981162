"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "executor-info-list"
  }, [_c("VirtualMoreUserListInPopover", {
    attrs: {
      list: _vm.executorData
    },
    scopedSlots: _vm._u([{
      key: "exposed-avatar",
      fn: function (_ref) {
        let {
          exposedItem
        } = _ref;
        return [_c("member-info-tag", {
          staticClass: "executor-info",
          attrs: {
            source: exposedItem,
            "is-task-detail": _vm.isTaskDetail
          },
          on: {
            deleteExecutor: _vm.deleteExecutor
          }
        })];
      }
    }, {
      key: "virtual-list-item-suffix",
      fn: function (_ref2) {
        let {
          virtualListItem
        } = _ref2;
        return [_c("el-button", {
          staticClass: "delete-btn",
          attrs: {
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.deleteExecutor(virtualListItem);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-close"
        })])];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;