"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FolderHeader = _interopRequireDefault(require("./FolderHeader"));
var _listCardItem = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCardItem"));
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
var _ConvFlowDropdown = _interopRequireDefault(require("@/components/FolderTree/ConvFlowDropdown.vue"));
var _SnippetVideoDialog = _interopRequireDefault(require("@/views/convDetail/snippetWord/SnippetVideoDialog.vue"));
var _SavedFiltersContent = _interopRequireDefault(require("@/views/conversationPage/components/SavedFiltersContent.vue"));
var _library = require("@/api/library.js");
var _conversationList = require("@/api/conversationList");
var _business = require("@/api/business");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvFlowDetail',
  components: {
    FolderHeader: _FolderHeader.default,
    CardItem: _listCardItem.default,
    MoreOperations: _MoreOperations.default,
    ConvFlowDropdown: _ConvFlowDropdown.default,
    SnippetVideoDialog: _SnippetVideoDialog.default,
    SavedFiltersContent: _SavedFiltersContent.default
  },
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      curFolder: {},
      filters: {},
      stageOptions: [],
      conversationList: [],
      hasMore: false,
      folderOperateList: [{
        command: 'edit',
        label: '编辑'
      }],
      conversationOperateList: [{
        command: 'goClip',
        label: '去剪辑'
      }, {
        command: 'appendToFolder',
        label: '添加到文件夹'
      }, {
        command: 'removeConversation',
        label: '移除会话'
      }],
      snippetVideoDialogVisible: false,
      activeConversation: {
        id: null,
        conversation_type: ''
      },
      customFilterArray: []
    };
  },
  computed: {
    getTotalString() {
      return (this.conversationList || []).length.toString() + (this.hasMore ? '+ ' : ' ');
    }
  },
  watch: {
    currentNode() {
      this.getConvFlowDetail();
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getStageOptions();
        }
      },
      immediate: true
    }
  },
  created() {
    this.getDealCustomFilter();
    this.getConvFlowDetail();
  },
  methods: {
    // 获取自定义筛选器的信息
    async getDealCustomFilter() {
      const params = {
        access_type: _routeMap.ROUTES.conversation
      };
      const res = await (0, _business.getDealCustomFilter)(params);
      this.customFilterArray = Object.values(res.results).flat();
    },
    handleConvFlowCommand(type, data) {
      this.$emit('convFlowOperation', type, data);
    },
    handleNodeClick(data) {
      this.$emit('breadClick', data);
    },
    handleConversationCommand(item, type) {
      this.activeConversationId = item.id;
      switch (type) {
        case 'goClip':
          this.goClip(item.id);
          break;
        case 'appendToFolder':
          this.appendToFolder(item);
          break;
        case 'removeConversation':
          this.removeConversation(this.currentNode.id, item.id);
          break;
        default:
          break;
      }
    },
    getStageOptions() {
      (0, _conversationList.getStageOptions)({
        workspace_id: this.workspaceInfo.id,
        source: 'conversation'
      }).then(res => {
        this.stageOptions = res.results;
      });
    },
    getConvFlowDetail() {
      this.loading = true;
      const workspaceInfo = JSON.parse(sessionStorage.getItem('workspaceInfo'));
      (0, _library.getConvFlowDetail)({
        id: this.currentNode.id,
        workspace_id: workspaceInfo === null || workspaceInfo === void 0 ? void 0 : workspaceInfo.id
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          const results = res.results;
          const {
            filter,
            items,
            is_more
          } = results;
          this.filters = filter;
          this.curFolder = results;
          this.conversationList = items;
          this.hasMore = is_more;
        }
      });
    },
    goClip(id) {
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id,
          operate: 'clip'
        }
      });
      window.open(router.href, '_blank');
    },
    appendToFolder(item) {
      this.activeConversation = item;
      this.snippetVideoDialogVisible = true;
    },
    handleCloseSnippetVideoDialog() {
      this.snippetVideoDialogVisible = false;
    },
    removeConversation(id, conversation_id) {
      this.$confirm('是否在智能会话流中移除会话？（不会删除原始会话）', '移除会话', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        (0, _library.removeConversationFromConvFlow)({
          id,
          conversation_id
        }).then(res => {
          if (res.code === 200) {
            this.getConvFlowDetail();
          }
        });
      });
    },
    goConversationList() {
      const {
        space_id
      } = this.filters;
      sessionStorage.setItem('storedSpaceId', space_id);
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.conversation
      });
      sessionStorage.setItem('conversationFilter', JSON.stringify(this.filters));
      window.open(router.href, '_blank');
      sessionStorage.removeItem('storedSpaceId');
      sessionStorage.removeItem('conversationFilter');
    }
  }
};
exports.default = _default;