"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _conversationList = require("@/api/conversationList.js");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _default = {
  data() {
    return {
      params: {
        current_page: 1,
        size: 10,
        filter: '',
        event_engine: '',
        date: [],
        entity_type: '',
        conversation_deal_status: '',
        conversation_type: '',
        duration: [0, 'unlimit'],
        host_ids: [],
        organization_tree_ids: [],
        account_ids: [],
        // lead_ids: [],
        sort_by: '',
        sort_order: ''
      },
      list: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes']),
    conversationTypes() {
      return this.list.some(item => {
        return item.conversation_type !== 'doc';
      });
    }
  },
  mounted() {
    if (this.webRoutes.find(item => item.name === 'ConversationIndex')) {
      this.getList();
    }
  },
  methods: {
    getDuration: _commonFunc.getDuration,
    // 请求数据
    getList() {
      (0, _conversationList.getConversationList)(this.params).then(response => {
        if (response.code === 200) {
          const res = response.results;
          this.list = res.items;
          this.handleList(this.list);
        }
      }).catch(err => {
        throw new Error(err);
      });
    },
    handleList(list) {
      for (let i = 0; i < list.length; i++) {
        const arr = [];
        list[i].participants.map(item => {
          if (item.entity_type === 'host_salesman' || item.entity_type === '') {
            list[i].host = item;
          } else if (item.entity_type === 'assistant_salesman' || item.entity_type === 'spectator_salesman') {
            arr.push(item);
            list[i].others = arr;
          }
        });
      }
    },
    handleClick(item) {
      this.$router.push({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: item.id,
          from: this.type
        }
      });
    }
  }
};
exports.default = _default;