"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      size: "600px"
    }
  }, [_c("div", {
    staticClass: "drawer-head",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" 添加示例录音 "), _c("el-tooltip", {
    directives: [{
      name: "delTabIndex",
      rawName: "v-delTabIndex"
    }],
    attrs: {
      effect: "dark",
      content: "销售在培训任务中练习知识点时，示例录音可提供语气和节奏参考"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("TricksPractice", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "tricks-practice",
    staticClass: "drawer-body",
    attrs: {
      "is-template": "",
      list: _vm.currentKnowledge.scene_reply_list,
      "play-voice-icon": false
    }
  }), false ? _c("div", {
    staticClass: "drawer-footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 保存 ")])], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;