"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authApp = require("@/api/authApp");
var _auth = require("@/utils/auth");
var _rowConfig = require("./rowConfig.js");
var _ConversationDetail = _interopRequireDefault(require("@/views/clients/components/ConversationDetail"));
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _riskPopover = _interopRequireDefault(require("@/components/BusinessTable/riskPopover"));
var _MgTag = _interopRequireDefault(require("@/components/MgTag"));
var _RiskCard = _interopRequireDefault(require("./components/RiskCard"));
var _AppointForm = _interopRequireDefault(require("./components/AppointForm"));
var _AfterSubmitCard = _interopRequireDefault(require("./components/AfterSubmitCard"));
var _VideoTemplates = _interopRequireDefault(require("@/components/VideoTemplates"));
var _PreAppoint = _interopRequireDefault(require("./components/PreAppoint"));
var _validate = require("@/utils/validate");
var _commonFunc = require("@/utils/commonFunc");
var _errorLog = require("@/utils/error-log.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// eslint-disable-next-line no-unused-vars
// import SaveTimeTip from '@/components/NoteEditor/components/SaveTimeTip'
var _default = {
  name: 'MeetingHelper',
  components: {
    ConversationDetail: _ConversationDetail.default,
    NoteEditor: _NoteEditor.default,
    RiskPopover: _riskPopover.default,
    MgTag: _MgTag.default,
    RiskCard: _RiskCard.default,
    AppointForm: _AppointForm.default,
    AfterSubmitCard: _AfterSubmitCard.default,
    VideoTemplates: _VideoTemplates.default,
    PreAppoint: _PreAppoint.default
    // SaveTimeTip
  },

  data() {
    return {
      meetingInfo: {},
      entityType: 'deal',
      entityId: '',
      conversationId: '',
      page: 1,
      size: 10,
      activeName: 'timeline',
      noteSavedTime: '',
      displayStatus: '',
      isOnTrial: false,
      demoTimeline: null,
      noteIsEmpty: false,
      preForm: {
        tel: '',
        account_type: ''
      },
      preFormRules: {
        tel: [{
          validator: _validate.telValidator,
          trigger: 'blur'
        }]
      },
      isTabScroll2Top: false
    };
  },
  computed: {
    entityTitle() {
      const config = _rowConfig.ENTITY_TITLE[this.entityType];
      const title = {};
      title.icon = config.icon;
      title.name = this.meetingInfo[config.key];
      return title;
    },
    entityDetail() {
      return _rowConfig.ENTITY_DETAIL[this.entityType].map(_ref => {
        let {
          key,
          label
        } = _ref;
        return {
          label,
          key,
          value: this.meetingInfo[key]
        };
      });
    }
  },
  async created() {
    // eslint-disable-next-line no-undef
    const isInApp = wemeet.app.isTMWebview();
    if (!isInApp) {
      this.setTemplate();
      return;
    }
    const authCode = await this.getAuthcode();
    if (!authCode) {
      this.setTemplate();
      return;
    }
    const {
      JSConfig,
      ...userConfig
    } = await this.getJSConfigAndUserConfig(authCode);
    if (!JSConfig) {
      this.setTemplate();
      return;
    }
    this.setUserConfig(userConfig);
    const isGetJSAPIAuthSucceed = await this.getJSAPIAuth(JSConfig);
    if (!isGetJSAPIAuthSucceed) return;
    const isJSAPIAuth = await this.isJSAPIAuth();
    if (!isJSAPIAuth) return;
    const {
      meetingId
    } = await this.getCurrMeetingInfo();
    await this.getMeetingInfo(meetingId);
  },
  mounted() {
    this.$el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$el.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const top = this.$refs.tabs.$el.getBoundingClientRect().top;
      this.isTabScroll2Top = top <= 0;
    },
    postTrialForm: (0, _commonFunc.debounce)(function () {
      (0, _authApp.applyForTrial)(this.preForm);
    }, 5000),
    openService() {
      this.$refs.preForm.validate(valid => {
        if (valid) {
          this.preForm.account_type = '';
          this.displayStatus = 'preForm';
          this.postTrialForm();
        }
      });
    },
    handlePreInput(val) {
      this.preForm.account_type = val;
      this.displayStatus = 'form';
      this.postTrialForm();
    },
    async getAuthcode() {
      try {
        // eslint-disable-next-line no-undef
        const res = await wemeet.permission.requestAgentAuthCode({
          sdkId: '18260153034'
        });
        return res.authCode;
      } catch (error) {
        return false;
      }
    },
    handleShowNote(data) {
      this.$refs['note-editor'].destroy();
      this.$refs['note-editor'].originData = data;
      this.$refs['note-editor'].init();
      // 保存一下
      this.$refs['note-editor'].onChange();
    },
    async getJSConfigAndUserConfig(authCode) {
      const res = await (0, _authApp.getJSConfig)(authCode);
      if (res.code === 52205) {
        return {};
      }
      const {
        config: JSConfig,
        session,
        user_id,
        org_id
      } = res.results;
      return {
        JSConfig,
        session,
        user_id,
        org_id
      };
    },
    async getJSAPIAuth(JSConfig) {
      try {
        // eslint-disable-next-line no-undef
        await wemeet.permission.agentConfig(JSConfig);
        return true;
      } catch (error) {
        return false;
      }
    },
    async isJSAPIAuth() {
      try {
        // eslint-disable-next-line no-undef
        const res = await wemeet.permission.checkJsApiConfig();
        return res;
      } catch (error) {
        (0, _errorLog.postErrorLog)('查看是否授权失败', JSON.stringify(error));
      }
    },
    async getCurrMeetingInfo() {
      try {
        // eslint-disable-next-line no-undef
        const res = await wemeet.data.getCurrMeetingInfo();
        return res;
      } catch (error) {
        (0, _errorLog.postErrorLog)('获取当前会议信息失败', JSON.stringify(error));
      }
    },
    async getMeetingInfo(meetingId) {
      const res = await (0, _authApp.getMeetingInfo)(meetingId);
      if (res.code !== 200) {
        this.setTemplate();
        return;
      }
      this.setInfoRes(res);
    },
    async setTemplate() {
      this.isOnTrial = true;
      const res = await (0, _authApp.getTemplate)();
      this.setInfoRes(res);
      this.demoTimeline = res.results.timeline;
    },
    setInfoRes(res) {
      this.meetingInfo = res.results.entity_info;
      this.entityId = res.results.entity_id;
      this.entityType = res.results.entity_type;
      this.conversationId = res.results.conversation_id;
    },
    setUserConfig(_ref2) {
      let {
        session,
        org_id,
        user_id
      } = _ref2;
      this.$store.commit('user/SET_TOKEN', session);
      this.$store.commit('user/SET_ORG_ID', org_id);
      this.$store.commit('user/SET_ID', user_id);
      (0, _auth.setToken)(session);
    },
    handleNoteSaved(time) {
      this.noteSavedTime = time;
    },
    handleNoteChange(isEmpty) {
      this.noteIsEmpty = isEmpty;
    }
  }
};
exports.default = _default;