"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChooseRoleDialog = _interopRequireDefault(require("./ChooseRoleDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChooseRoleDesemsibilisation',
  components: {
    ChooseRoleDialog: _ChooseRoleDialog.default
  },
  props: {
    hasDesensitizationRoleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chooseRoleDialogVisible: false
    };
  },
  methods: {
    fetchRoleDesensitizationSuccess() {
      this.$emit('fetchRoleDesensitizationSuccess');
    },
    closeChooseRoleDialog() {
      this.chooseRoleDialogVisible = false;
    },
    openChooseRoleDialog() {
      this.chooseRoleDialogVisible = true;
    }
  }
};
exports.default = _default;