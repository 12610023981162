"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "dialog-fade"
    },
    on: {
      "after-enter": _vm.afterEnter,
      "after-leave": _vm.afterLeave
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "el-dialog__wrapper",
    on: {
      mousedown: function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.handleWrapperClick.apply(null, arguments);
      }
    }
  }, [_c("div", {
    key: _vm.key,
    ref: "dialog",
    class: ["el-dialog", {
      "is-fullscreen": _vm.fullscreen,
      "el-dialog--center": _vm.center
    }, _vm.customClass],
    style: _vm.style,
    attrs: {
      role: "dialog",
      "aria-modal": "true",
      "aria-label": _vm.title || "dialog"
    }
  }, [_c("div", {
    staticClass: "el-dialog__header"
  }, [_vm._t("title", function () {
    return [_c("span", {
      staticClass: "el-dialog__title"
    }, [_vm._v(_vm._s(_vm.title))])];
  }), _vm.showClose ? _c("button", {
    staticClass: "el-dialog__headerbtn",
    attrs: {
      type: "button",
      "aria-label": "Close"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_c("i", {
    staticClass: "el-dialog__close el-icon el-icon-close"
  })]) : _vm._e()], 2), _vm.rendered ? _c("div", {
    staticClass: "el-dialog__body"
  }, [_vm._t("default")], 2) : _vm._e(), _vm.$slots.footer ? _c("div", {
    staticClass: "el-dialog__footer"
  }, [_vm._t("footer")], 2) : _vm._e()])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;