"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-path-view-btn"
  }, [_c("el-radio-group", {
    staticClass: "mg-radio-button-group",
    attrs: {
      value: _vm.chartRadio
    },
    on: {
      input: function ($event) {
        return _vm.$emit("chartViewChange", $event);
      }
    }
  }, [_vm._l(_vm.viewConfig, function (item, index) {
    return [_c("el-tooltip", {
      key: index,
      attrs: {
        effect: "dark",
        content: item.content,
        placement: "top"
      }
    }, [_c("el-radio-button", {
      attrs: {
        label: item.name
      }
    }, [_c("img", {
      staticClass: "view-img",
      attrs: {
        src: item.img
      }
    }), _vm._v(_vm._s(item.name) + " ")])], 1)];
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;