"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeFavicon = changeFavicon;
exports.computerSizeToStr = computerSizeToStr;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.formatCommentContent = formatCommentContent;
exports.formatCommentContentWithMentionId = formatCommentContentWithMentionId;
exports.getDuration = getDuration;
exports.getLocationSearch = getLocationSearch;
exports.getTreeNodeById = getTreeNodeById;
exports.isInViewPortOfOne = isInViewPortOfOne;
exports.isValuableObj = isValuableObj;
exports.recursionGet = recursionGet;
exports.throttle = throttle;
exports.toChineseNumeral = toChineseNumeral;
var _moment = _interopRequireDefault(require("moment"));
var _timeFormatter = require("./time-formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function getDuration(seconds) {
  // eslint-disable-next-line prefer-const
  let {
    s
  } = (0, _timeFormatter.second2HMS)(seconds);
  const {
    m,
    h
  } = (0, _timeFormatter.second2HMS)(seconds);
  if (h) {
    return m ? `<span class="timer-number">${h}</span><span class="timer-text">小时</span><span class="timer-number"> ${m}</span><span class="timer-text">分</span>` : `<span class="timer-number">${h}</span><span class="timer-text">小时</span>`;
  } else if (m) {
    return s > 29 ? `<span class="timer-number">${m + 1}</span><span class="timer-text">分</span>` : `<span class="timer-number">${m}</span><span class="timer-text">分</span>`;
  } else if (s) {
    s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(0);
    return `<span class="timer-number"> ${s}</span><span class="timer-text">秒</span>`;
  } else {
    return `<span class="timer-number"> 0</span><span class="timer-text">秒</span>`;
  }
}

// 深拷贝方法优化（增加属性值为function的处理）
function deepClone(obj) {
  if (!obj) {
    return obj;
  }
  const res = obj instanceof Array ? [] : {};
  for (const [key, value] of Object.entries(obj)) {
    res[key] = typeof value !== 'object' ? value : deepClone(value);
  }
  return res;
}

// 获取阿拉伯数字对应的中文
function toChineseNumeral(num) {
  var numerals = {
    '-': '负',
    '.': '点',
    0: '零',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十',
    100: '百',
    1000: '千',
    10000: '万'
  };
  // 如果num为负数
  if (num < 0) {
    return numerals['-'] + toChineseNumeral(-num);
    // num 为 0或0点几的小数
  } else if (num < 1) {
    return num.toString().split('').reduce(function (p, n) {
      return p + numerals[n];
    }, '');
  } else if (num > Math.floor(num)) {
    return toChineseNumeral(Math.floor(num)) + toChineseNumeral(parseFloat(num.toString().replace(/^.*\./, '0.'))).slice(1);
  } else {
    return [10000, 1000, 100, 10, 1].reduce(function (p, n) {
      if (num >= n) {
        if (p.ling) p.ch += numerals[0];
        p.ch += numerals[Math.floor(num / n)];
        // eslint-disable-next-line eqeqeq
        if (n != 1) p.ch += numerals[n];
        p.ling = false;
      } else if (p.ch) {
        p.ling = true;
      }
      num %= n;
      return p;
    }, {
      ch: '',
      ling: false
    }).ch.replace(/^一十/, '十');
  }
}

// 获取原生路由参数
function getLocationSearch() {
  if (!location.search) return null;
  const locationSearch = new URLSearchParams(location.search);
  const auth_code = locationSearch.get('auth_code');
  const state = locationSearch.get('state');
  const type = locationSearch.get('type');
  return {
    auth_code,
    state,
    type
  };
}
// 节流
function throttle(fn) {
  let delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  let timeout = null;
  return function () {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        fn.apply(this, arguments);
      }, delay);
    }
  };
}
// 防抖
function debounce(fn) {
  let wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  let isImmediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let timerId = null;
  let flag = true;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timerId);
    if (isImmediate) {
      if (flag) {
        fn.apply(context, args);
        flag = false;
      }
      timerId = setTimeout(function () {
        flag = true;
      }, wait);
    } else {
      timerId = setTimeout(function () {
        fn.apply(context, args);
      }, wait);
    }
  };
}

// 递归根据一个确定的值，找到确定值所在对象中某一个值
function recursionGet(_ref) {
  let {
    recurArr = [],
    targetValue,
    targetAttr,
    getAttr
  } = _ref;
  let value = null;
  for (let index = 0; index < recurArr.length; index++) {
    if (recurArr[index][targetAttr] === targetValue) {
      value = recurArr[index][getAttr];
      break;
    }
    if (recurArr[index].children instanceof Array && recurArr[index].children.length > 0) {
      const text = recursionGet({
        recurArr: recurArr[index].children,
        targetValue,
        targetAttr,
        getAttr
      });
      if (text) return text;
    }
  }
  return value;
}

// 动态设置ico
function changeFavicon(url) {
  let favicon = document.querySelector('link[rel="icon"]');
  if (favicon !== null) {
    favicon.href = url;
  } else {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = url;
    document.head.appendChild(favicon);
  }
}

// 通过id查找树形结构中的某一项
function getTreeNodeById(tree, id) {
  let stack = [];
  stack = stack.concat(tree);
  while (stack.length) {
    const temp = stack.shift();
    if (temp.children) {
      stack = stack.concat(temp.children);
    }
    if (temp.id === id) {
      return temp;
    }
  }
}

// 判断数据为非空对象
// 非空对象返回true  非对象/空对象返回false
function isValuableObj(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]' && !!Object.keys(obj).length;
}

// 处理评论@用户的显示
function formatCommentContent(content) {
  const patterns = [/\[\[at\]\]/g, /\[\[\/at\]\]/g, /nbsp;/g];
  const replacements = ['<span style="color:#4461ec;">', '</span>', ' '];
  let temp = content;
  patterns.map((pattern, index) => {
    temp = temp.replace(pattern, replacements[index]);
  });
  console.log(temp, 'temp1');
  temp.replace(/@[^\)]*\)/g, str => `<span style="color:#4461ec">${str}</span>`);
  return temp;
}
// 处理评论@用户的显示，带mentionId
function formatCommentContentWithMentionId(content, mentionIds) {
  console.log(content, mentionIds, 'content, mentionIds');
  const atPattern = /\[\[at\]\]/;
  let temp = content;
  mentionIds.map(item => {
    temp = temp.replace(atPattern, `<span class="mention" data-id=${item.id} data-department=${item.department} contenteditable="false">`);
  });
  const atEndPattern = /\[\[\/at\]\]/g;
  temp = temp.replace(atEndPattern, '</span>');
  const brPattern = /\n/g;
  temp = temp.replace(brPattern, '<br/>');
  console.log(temp, 'contentEnd');
  return temp;
}
function computerSizeToStr(fileSize) {
  if (!fileSize) {
    return '0 B';
  }
  const unitArr = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  const srcSize = parseFloat(fileSize);
  index = Math.floor(Math.log(srcSize) / Math.log(1024));
  let size = srcSize / Math.pow(1024, index);
  size = size.toFixed(2);
  return size + unitArr[index];
}

// 判断元素是否在视窗内
function isInViewPortOfOne(dom) {
  // 兼容写法
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight;
  const viewPortWidth = window.innerWidth || document.documentElement.clientWidth;
  const {
    top,
    left,
    bottom,
    right
  } = dom.getBoundingClientRect();
  return top >= 0 && left >= 0 && bottom <= viewPortHeight && right <= viewPortWidth;
}

// 判断元素是否在父元素视窗内