"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "result-filters"
  }, [_c("div", [_c("el-select", {
    attrs: {
      placeholder: "模型预测结果",
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.isHit,
      callback: function ($$v) {
        _vm.isHit = $$v;
      },
      expression: "isHit"
    }
  }, [_c("el-option", {
    attrs: {
      label: "命中",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "未命中",
      value: 2
    }
  })], 1), _c("el-select", {
    attrs: {
      placeholder: "测试集标注结果",
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.isAnnotated,
      callback: function ($$v) {
        _vm.isAnnotated = $$v;
      },
      expression: "isAnnotated"
    }
  }, [_c("el-option", {
    attrs: {
      label: "命中",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "未命中",
      value: 2
    }
  }), _c("el-option", {
    attrs: {
      label: "不确定",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "未标注",
      value: 4
    }
  })], 1), _c("el-select", {
    attrs: {
      placeholder: "预测是否正确",
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.isRight,
      callback: function ($$v) {
        _vm.isRight = $$v;
      },
      expression: "isRight"
    }
  }, [_c("el-option", {
    attrs: {
      label: "正确",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "错误",
      value: 2
    }
  })], 1)], 1), _vm.testTaskInfo.model_cate !== 2 ? _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("reTraining");
      }
    }
  }, [_vm._v("重新训练")]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;