"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BatchSearchDialog = _interopRequireDefault(require("@/components/BatchSearchDialog"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BatchSearchInput',
  components: {
    BatchSearchDialog: _BatchSearchDialog.default
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        value: [],
        is_batch: false
      })
    },
    size: {
      type: String,
      default: 'large'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      batchSearchDialogVisible: false,
      batchSearchArr: [],
      isBatch: false,
      currentSearch: ''
    };
  },
  watch: {
    batchSearchArr(val) {
      if (!val.length) {
        this.$emit('input', {
          value: [],
          is_batch: false
        });
        this.isBatch = false;
      }
    },
    value: {
      handler: function (val, oldValue) {
        if (JSON.stringify(val) === JSON.stringify(oldValue)) return;
        if (val && val.is_batch !== undefined) {
          this.batchSearchArr = JSON.parse(JSON.stringify(val.value));
          this.isBatch = val.is_batch;
        } else {
          this.batchSearchArr = [];
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    openBatchSearchDialog() {
      this.batchSearchDialogVisible = true;
    },
    deleteTag() {
      this.batchSearchArr.shift();
      this.$emit('input', {
        value: this.batchSearchArr,
        is_batch: this.isBatch
      });
      this.debounceCustomChange();
    },
    switchSearchType() {
      if (this.value.value) {
        this.isBatch = true;
        this.$emit('input', {
          value: this.batchSearchArr,
          is_batch: this.isBatch
        });
        this.customChange();
        this.$nextTick(() => {
          this.$refs.currentSearchInput && this.$refs.currentSearchInput.focus();
        });
      }
    },
    addSearchItem() {
      if (this.currentSearch.trim()) {
        if (this.batchSearchArr.length < 1000 && !this.batchSearchArr.includes(this.currentSearch)) {
          this.batchSearchArr.push(this.currentSearch);
          this.$emit('input', {
            value: this.batchSearchArr,
            is_batch: this.isBatch
          });
        }
        this.currentSearch = '';
        this.debounceCustomChange();
      }
    },
    initInput() {
      this.batchSearchArr = [];
      this.debounceCustomChange();
    },
    search(val) {
      if (this.batchSearchArr === val) return;
      this.batchSearchArr = val;
      this.isBatch = !!val.length;
      this.$emit('input', {
        value: this.batchSearchArr,
        is_batch: this.isBatch
      });
      this.customChange();
    },
    handleInput(val) {
      this.$emit('input', {
        value: val.trim() ? [val.slice(0, 50)] : [],
        is_batch: false
      });
      this.debounceCustomChange();
    },
    debounceCustomChange: (0, _commonFunc.debounce)(function () {
      this.customChange();
    }, 500),
    customChange(val) {
      this.$emit('customChange', val);
    }
  }
};
exports.default = _default;