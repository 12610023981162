"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appCard = _interopRequireDefault(require("@/views/userInfo/components/appCard"));
var _authApp = require("@/api/authApp");
var _commonFunc = require("@/utils/commonFunc");
var _wemeet = _interopRequireDefault(require("@/assets/authApp/wemeet.svg"));
var _wework = _interopRequireDefault(require("@/assets/authApp/wework.svg"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AuthApp',
  components: {
    AppCard: _appCard.default
  },
  data() {
    return {
      wemeetIsBind: false,
      weworkIsBind: false,
      bindUserInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_tencent_meeting', 'has_wework']),
    app() {
      const apps = {
        wemeet: {
          appName: 'wemeet',
          appDescription: '云视频会议产品',
          appLogo: _wemeet.default,
          isBind: this.wemeetIsBind,
          hasPermission: this.has_tencent_meeting
        },
        wework: {
          appName: 'wework',
          appDescription: '',
          appLogo: _wework.default,
          isBind: this.weworkIsBind,
          hasPermission: this.has_wework
        }
      };
      for (const item in apps) {
        if (!apps[item].hasPermission) {
          delete apps[item];
        }
      }
      return apps;
    }
  },
  async created() {
    await this.checkAppBindState(this.app);
  },
  methods: {
    async checkAppBindState(appList) {
      for (const key in appList) {
        await this.checkBindState(appList[key].appName);
        this.afterScanningQRcode(appList[key].appName);
      }
    },
    // 点击关联账户
    async getAuthLink(appName) {
      let res = null;
      if (appName === 'wemeet') {
        res = await (0, _authApp.getAuthLinkTencent)();
      }
      if (appName === 'wework') {
        res = await (0, _authApp.getAuthLinkOfWework)();
      }
      window.location.href = res.results;
    },
    async checkBindState(appName) {
      let res = null;
      switch (appName) {
        case 'wemeet':
          res = await (0, _authApp.getIsBindTencent)();
          this.wemeetIsBind = res.results;
          if (this.wemeetIsBind) {
            this.getBindWemeetInfo();
          }
          break;
        case 'wework':
          res = await (0, _authApp.getBindStateOfWework)();
          this.weworkIsBind = res.results.is_bind;
          if (this.weworkIsBind) {
            const {
              user_name,
              organization_name
            } = res.results;
            this.$set(this.bindUserInfo, 'user_name', user_name);
            this.$set(this.bindUserInfo, 'organization_name', organization_name);
          }
          break;
        default:
          break;
      }
    },
    async getBindWemeetInfo() {
      const res = await (0, _authApp.getBindName)();
      if (res.code === 200) {
        const {
          nickname,
          corp_name
        } = res.results;
        this.$set(this.bindUserInfo, 'nickname', nickname);
        this.$set(this.bindUserInfo, 'corp_name', corp_name);
      }
    },
    async unbindApp(appName) {
      let res = null;
      switch (appName) {
        case 'wemeet':
          res = await (0, _authApp.unbindTencent)();
          // 腾讯视频解绑成功后返回的results为空
          if (res.code === 20000 && !res.results) {
            this.checkBindState('wemeet');
          } else {
            this.$message({
              type: 'warning',
              message: '解绑失败，请重试！'
            });
          }
          break;
        case 'wework':
          res = await (0, _authApp.unbindWework)();
          if (res.results.res) {
            this.checkBindState('wework');
          } else {
            this.$message({
              type: 'warning',
              message: '解绑失败，请重试！'
            });
          }
          break;
        default:
          break;
      }
    },
    async afterScanningQRcode(appName) {
      // 此时，路由变化，获取auth_code和state
      const query = (0, _commonFunc.getLocationSearch)();
      if (!query) {
        return;
      } else {
        const {
          auth_code,
          state,
          type
        } = query;
        if (!this.wemeetIsBind && type !== '6' && appName === 'wemeet') {
          await (0, _authApp.bindTencent)({
            auth_code,
            state
          });
        }
        if (!this.weworkIsBind && type === '6' && appName === 'wework') {
          // 企微绑定只需要auth_code
          await (0, _authApp.postAuthCodeAndGetBindInfo)({
            auth_code
          });
        }
        // 发送auth_code之后再次检查绑定状态
        this.checkBindState(appName);
      }
    }
  }
};
exports.default = _default;