"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _default = {
  name: 'ElCascader',
  extends: _elementUi.Cascader,
  mounted() {
    this.$on('visible-change', this.handlecascaderVisibleChange);
  },
  methods: {
    handlecascaderVisibleChange() {
      // 根据ElementUI的BUG提示  https://github.com/ElemeFE/element/issues/22060
      const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
      Array.from($el).map(item => item.removeAttribute('aria-owns'));
    }
  }
};
exports.default = _default;