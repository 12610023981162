"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$loaded, _setup$loaded2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "progress-viewer-item"
  }, [_c("div", {
    staticClass: "progress-viewer-item__header"
  }, [_c("i", {
    staticClass: "iconfont icon-phone-outline-fill progress-viewer-item__icon"
  }), _c("div", {
    staticClass: "progress-viewer-item__detail"
  }, [_c("p", {
    staticClass: "progress-viewer-item__name"
  }, [_vm._v(_vm._s(_vm.file.meeting_name))]), _c("p", {
    staticClass: "progress-viewer-item__tips"
  }, [_vm._v(" " + _vm._s(_setup.computerSizeToStr(_setup.loaded[_setup.loaded.length - 1])) + " / " + _vm._s(_setup.computerSizeToStr(_vm.file.size)) + " "), _vm.file.state === "uploading" ? _c("span", {
    staticClass: "separator"
  }, [_vm._v("·")]) : _vm._e(), _vm.file.state === "uploading" ? _c("span", [_vm._v(" " + _vm._s(`${_setup.computerSizeToStr(_setup.uploadSpeed)}/s`))]) : _vm._e()])]), _c("div", {
    staticClass: "progress-viewer-item__progress-wrapper"
  }, [_vm.file.state === "waiting" ? [_c("span", [_vm._v("等待上传")]), _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: _setup.cancelUpload
    }
  })] : _vm.file.state === "uploading" ? [_c("span", [_vm._v(" " + _vm._s(Math.round(((_setup$loaded = _setup.loaded) === null || _setup$loaded === void 0 ? void 0 : _setup$loaded[_setup.loaded.length - 1]) / _vm.file.size * 100)) + "%")]), _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: _setup.cancelUpload
    }
  })] : _vm.file.state === "success" ? [_c("el-button", {
    staticClass: "check-conv-btn",
    attrs: {
      type: "text"
    },
    on: {
      click: _setup.goConversationDetail
    }
  }, [_vm._v("查看会话"), _c("i", {
    staticClass: "iconfont icon-top-right"
  })]), _c("i", {
    staticClass: "iconfont icon-success"
  })] : _vm.file.state === "fail" ? [_c("span", [_vm._v(_vm._s(_vm.file.errorMsg))]), _c("i", {
    staticClass: "iconfont icon-error"
  })] : _vm.file.state === "processing" ? [_c("span", [_vm._v("处理中")]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: true,
      expression: "true"
    }],
    staticClass: "process-loading"
  })] : _vm.file.state === "cancel" ? [_c("span", [_vm._v("已取消")])] : _vm._e()], 2)]), _vm.file.state === "uploading" ? _c("div", {
    staticClass: "progress"
  }, [_c("el-progress", {
    attrs: {
      percentage: Math.round(((_setup$loaded2 = _setup.loaded) === null || _setup$loaded2 === void 0 ? void 0 : _setup$loaded2[_setup.loaded.length - 1]) / _vm.file.size * 100) || 0,
      "show-text": false
    }
  })], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;