"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conversation-info-title"
  }, [_c("span", {
    on: {
      click: _setup.openModifyConvInfoHistoryDrawer
    }
  }, [_vm._v("历史")]), _c("div", {
    staticClass: "divider"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;