"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeleteTrailAnnotation = useDeleteTrailAnnotation;
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _useMessage = require("@/hooks/use-message");
function useDeleteTrailAnnotation(_ref) {
  let {
    refreshTable
  } = _ref;
  const deleteTrailAnnotationApi = async data => {
    const res = await (0, _rapidCreateModel.deleteTrailAnnotation)(data);
    if (res.code === 20000) {
      _useMessage.useMessage.success('删除成功');
      refreshTable();
    }
  };
  return {
    deleteTrailAnnotationApi
  };
}