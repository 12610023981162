"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "copy-url"
  }, [_c("el-dialog", {
    attrs: {
      title: "客户邀请",
      visible: _vm.visible,
      width: "480px",
      "before-close": _vm.closeSelf,
      "custom-class": "copy-url-dialog",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.closeCopy.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type === "appoint",
      expression: "type === 'appoint'"
    }],
    staticClass: "tip-suc"
  }, [_c("el-button", {
    staticClass: "success-icon",
    attrs: {
      type: "success",
      icon: "el-icon-check",
      circle: ""
    }
  }), _c("span", {
    staticClass: "tip-text"
  }, [_vm._v("已添加至「会议日程」列表")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type === "update",
      expression: "type === 'update'"
    }],
    staticClass: "tip-suc"
  }, [_c("el-button", {
    staticClass: "success-icon",
    attrs: {
      type: "success",
      icon: "el-icon-check",
      circle: ""
    }
  }), _c("span", {
    staticClass: "tip-text"
  }, [_vm._v("修改成功")])], 1), _c("div", {
    staticClass: "qr"
  }, [_c("div", {
    staticClass: "qr-title"
  }, [_vm._v("微信扫描小程序码进入会议")]), _c("div", {
    staticClass: "qr-content"
  }, [_c("div", [_vm.showInteQr ? _c("el-image", {
    staticClass: "qrcode",
    attrs: {
      src: _vm.inteQr
    },
    on: {
      error: _vm.reloadInteImage
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_vm._v(" 正在加载中"), _c("span", {
    staticClass: "dot"
  }, [_vm._v("...")])])]) : _vm._e(), _c("div", {
    staticClass: "qr-text"
  }, [_vm._v("销售代表")])], 1), _c("div", [_vm.showCandQr ? _c("el-image", {
    staticClass: "qrcode",
    attrs: {
      src: _vm.candQr
    },
    on: {
      error: _vm.reloadCandImage
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_vm._v(" 正在加载中"), _c("span", {
    staticClass: "dot"
  }, [_vm._v("...")])])]) : _vm._e(), _c("div", {
    staticClass: "qr-text"
  }, [_vm._v("客户")])], 1)])]), _c("div", {
    staticClass: "invite-text"
  }, [_c("div", {
    staticClass: "invite-text-name"
  }, [_vm._v("销售参会链接")]), _c("div", {
    staticClass: "invite-text-item"
  }, [_c("el-input", {
    attrs: {
      resize: "none",
      readonly: ""
    },
    model: {
      value: _vm.inteUrl,
      callback: function ($$v) {
        _vm.inteUrl = $$v;
      },
      expression: "inteUrl"
    }
  }), _c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.inteUrl,
      expression: "inteUrl",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }],
    staticClass: "copy-button",
    attrs: {
      type: "primary"
    }
  }, [_vm._v("复制")])], 1)]), _c("div", {
    staticClass: "invite-text"
  }, [_c("div", {
    staticClass: "invite-text-name"
  }, [_vm._v("客户参会链接")]), _c("div", {
    staticClass: "invite-text-item"
  }, [_c("el-input", {
    attrs: {
      resize: "none",
      readonly: ""
    },
    model: {
      value: _vm.inviteText,
      callback: function ($$v) {
        _vm.inviteText = $$v;
      },
      expression: "inviteText"
    }
  }), _c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.inviteText,
      expression: "inviteText",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }],
    staticClass: "copy-button",
    attrs: {
      type: "primary"
    }
  }, [_vm._v("复制")])], 1)]), false ? _c("div", {
    staticClass: "link-title"
  }, [_c("label", [_vm._v("销售代表")]), _c("span", [_vm._v("视频会议链接")])]) : _vm._e(), false ? _c("div", {
    staticClass: "first-line"
  }, [_c("el-input", {
    attrs: {
      readonly: ""
    },
    model: {
      value: _vm.inteUrl,
      callback: function ($$v) {
        _vm.inteUrl = $$v;
      },
      expression: "inteUrl"
    }
  }), _c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.inteUrl,
      expression: "inteUrl",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }],
    attrs: {
      type: "primary",
      plain: ""
    }
  }, [_vm._v("复制链接")])], 1) : _vm._e()]), _c("div", {
    staticClass: "dialog-footer div-dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", {
    staticClass: "footer-button"
  }, [_vm.hasCalendarPermission ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.goInterview.apply(null, arguments);
      }
    }
  }, [_vm._v("查看会议日程")]) : _c("el-button", {
    staticClass: "finished",
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.closeSelf.apply(null, arguments);
      }
    }
  }, [_vm._v("完成")])], 1)])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;