"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _AddKeyEventTrackerPopover = _interopRequireDefault(require("@/components/Detail/DialogText/AddKeyEventTrackerPopover.vue"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _commonFunc = require("@/utils/commonFunc");
var _annotateText = require("../hooks/annotateText.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateText',
  props: {
    activeDataSetData: {
      type: Object,
      default: () => {}
    },
    // 所有句子命中的关键事件
    sentenceOfEventTagMap: {
      type: Object,
      default: () => ({})
    },
    dataSetLabelList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['deleteAnnotate'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const {
      updateSliceSentence,
      sliceSentence,
      contentWrapperRef,
      handleMouseWheel,
      handleMouseScroll,
      handleScroll
    } = (0, _annotateText.useConvTextVirtualList)(store);
    const {
      selectedSentenceOrderArr,
      clearSentenceOrderArr
    } = (0, _annotateText.useAnnotateSentence)();
    const {
      locateSentence
    } = (0, _annotateText.useLocateSentence)(store);
    const {
      showAddKeyEventTrackerPopover,
      addKeyEventTrackerPopoverRef,
      handleCancelAddKeyEventTracker,
      addKeyEventTracker,
      setContWrapRefs,
      setAddKeyEventTrackerPopoverOffset
    } = (0, _annotateText.useCreateAnnotate)({
      store,
      props,
      selectedSentenceOrderArr,
      clearSentenceOrderArr
    });
    const onSentenceOrderChange = val => {
      setAddKeyEventTrackerPopoverOffset(val[val.length - 1]);
    };
    const createAnnotate = label_ids => {
      const localSelectLabelIds = (0, _commonFunc.deepClone)(label_ids);
      const labelIds = localSelectLabelIds.map(item => item.pop());
      emit('createAnnotate', labelIds);
    };

    // 命中关键事件选中的句子
    const isSales = (0, _vue.computed)(() => {
      return function (item) {
        return item.entity_type !== 'customer_contact';
      };
    });
    const curorder = (0, _vue.computed)(() => {
      return store.getters.curorder;
    });
    const audio = (0, _vue.computed)(() => {
      return store.getters.audio;
    });
    const deleteHitKeyEventTracker = item => {
      emit('deleteAnnotate', item);
    };
    const getSelectedSentenceOrderArr = () => {
      return selectedSentenceOrderArr.value;
    };
    expose({
      getSelectedSentenceOrderArr,
      clearSentenceOrderArr,
      handleCancelAddKeyEventTracker
    });
    (0, _vue.watch)(() => curorder.value, async value => {
      updateSliceSentence(value.value);
      if (store.getters.dialogTextScrollable) {
        await (0, _vue.nextTick)();
        const oCurrentOrder = document.querySelector(`#sentence-item${value.value}`);
        if (audio.value && audio.value.length) {
          if (oCurrentOrder) {
            var _contentWrapperRef$va;
            const height = oCurrentOrder.offsetTop;
            (_contentWrapperRef$va = contentWrapperRef.value) === null || _contentWrapperRef$va === void 0 ? void 0 : _contentWrapperRef$va.scroll({
              top: height - 80,
              // 向上移动的距离，如果有fixed布局， 直接减去对应距离即可
              behavior: store.getters.is_video_pause ? 'instant' : 'smooth'
            });
          }
        }
      }
      store.commit('video/SET_DIALOG_TEXT_SCROLLABLE', true);
    }, {
      deep: true,
      immediate: true
    });
    return {
      __sfc: true,
      props,
      store,
      updateSliceSentence,
      sliceSentence,
      contentWrapperRef,
      handleMouseWheel,
      handleMouseScroll,
      handleScroll,
      selectedSentenceOrderArr,
      clearSentenceOrderArr,
      locateSentence,
      showAddKeyEventTrackerPopover,
      addKeyEventTrackerPopoverRef,
      handleCancelAddKeyEventTracker,
      addKeyEventTracker,
      setContWrapRefs,
      setAddKeyEventTrackerPopoverOffset,
      onSentenceOrderChange,
      createAnnotate,
      isSales,
      curorder,
      audio,
      emit,
      deleteHitKeyEventTracker,
      getSelectedSentenceOrderArr,
      ImageAvatar: _ImageAvatar.default,
      AddKeyEventTrackerPopover: _AddKeyEventTrackerPopover.default
    };
  }
};
exports.default = _default;