"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _default = {
  __name: 'MetricCreateDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dimensionId: {
      type: Number,
      default: 0
    },
    metric: {
      type: Object,
      default: () => null
    }
  },
  emits: 'update:visible',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    (0, _vue.watch)(() => props.visible, async () => {
      if (props.visible) {
        await (0, _vue.nextTick)();
        getScoreMetricsDimensionList();
        if (props.metric) {
          form.name = props.metric.name;
          form.dimension_id = props.metric.dimension_id;
          form.gists = props.metric.gists;
          form.metrics_id = props.metric.id;
        } else {
          form.name = '';
          form.dimension_id = props.dimensionId;
          form.gists = [{
            content: ''
          }];
        }
        formRef.value.clearValidate();
      }
    });
    const dimensionList = (0, _vue.ref)([]);
    async function getScoreMetricsDimensionList() {
      const res = await (0, _coachingCenter.scoreMetricsDimensionList)();
      dimensionList.value = res.results.items;
    }
    const addGist = () => {
      form.gists.push({
        content: ''
      });
    };
    const deleteGist = index => {
      form.gists.splice(index, 1);
    };
    const form = (0, _vue.reactive)({
      name: '',
      dimension_id: '',
      gists: [{
        content: ''
      }]
    });
    const gistsValidator = (rule, value, callback) => {
      const isEmpty = value.some(item => !item.content);
      if (isEmpty) {
        callback(new Error('指标要点不能为空'));
      } else {
        callback();
      }
    };
    const rule = {
      name: [{
        required: true,
        message: '请输入指标名称',
        trigger: 'change'
      }],
      dimension_id: [{
        required: true,
        message: '请选择归属维度',
        trigger: 'change'
      }],
      gists: [{
        validator: gistsValidator,
        trigger: 'change'
      }]
    };
    const formRef = (0, _vue.ref)(null);
    const submit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) return;
      const fn = props.metric ? _coachingCenter.scoreMetricsUpdate : _coachingCenter.scoreMetricsCreate;
      const res = await fn(form);
      emit('createSuccess');
      updateVisible(false);
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      dimensionList,
      getScoreMetricsDimensionList,
      addGist,
      deleteGist,
      form,
      gistsValidator,
      rule,
      formRef,
      submit
    };
  }
};
exports.default = _default;