"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conversation-base-filters"
  }, [!_setup.isInQuickModelingPage ? _c(_setup.ConversationFilterItem, {
    attrs: {
      label: "成员",
      "is-show-clear": !!_vm.filters.host_ids && !!_vm.filters.host_ids.length
    },
    on: {
      handleClear: _setup.clearHost
    }
  }, [_c(_setup.MembersFinder, {
    ref: "memberFinderRef",
    attrs: {
      placeholder: "请选择成员",
      "has-self": false,
      "get-member-api-type": "data",
      params: {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      },
      "tree-config": _setup.treeConfig,
      "is-show-have-deleted-members": ""
    },
    on: {
      includesDeletedChange: _setup.handleIncludesDeletedChange,
      change: _setup.hostChange
    }
  })], 1) : _vm._e(), _c(_setup.ConversationFilterItem, {
    attrs: {
      label: "部门",
      "is-show-clear": !!_vm.filters.organization_tree_ids && !!_vm.filters.organization_tree_ids.length
    },
    on: {
      handleClear: _setup.clearDepartment
    }
  }, [_c("MgvDepartmentMultiSelect", {
    ref: "mgvDepartmentMultiSelectRef",
    attrs: {
      "request-method": _setup.getOrgTree,
      "backfill-list": _vm.filters.organization_tree_ids,
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleDepartmentValueChange
    }
  })], 1), !_setup.isInQuickModelingPage ? _c(_setup.ConversationFilterItem, {
    attrs: {
      label: "客户",
      "popover-content": "当您的客户为个人时，请输入客户的姓名进行搜索；当您的客户为公司时，请输入公司名称进行搜索。",
      "is-show-clear": !!_vm.filters.account_ids && !!_vm.filters.account_ids.length
    },
    on: {
      handleClear: function ($event) {
        return _setup.clearFiltersByFields(["companyName", "account_ids"]);
      }
    }
  }, [_c(_setup.SelectCustomTag, {
    attrs: {
      value: _vm.filters.companyName,
      placeholder: "请输入客户名称",
      "remote-params": {
        type: "account"
      },
      "remote-method": _setup.getAccountOrDeal
    },
    on: {
      handleChange: val => _setup.filterChange(val, "account_ids", "companyName")
    }
  }, [_c("i", {
    staticClass: "iconfont icon-custom-01",
    attrs: {
      slot: "optionIcon"
    },
    slot: "optionIcon"
  })])], 1) : _vm._e(), _setup.modulePermission.trade && !_setup.isInQuickModelingPage ? _c(_setup.ConversationFilterItem, {
    attrs: {
      label: "交易",
      "is-show-clear": !!_vm.filters.deal_name.value && !!_vm.filters.deal_name.value.length
    },
    on: {
      handleClear: function ($event) {
        return _setup.clearFiltersByFields(["dealName", "deal_name"]);
      }
    }
  }, [_c(_setup.BatchSearchInput, {
    staticClass: "deal-name",
    attrs: {
      placeholder: "请输入交易名称"
    },
    on: {
      getFuzzySearchList: function ($event) {
        return _setup.getFuzzySearchListRequest($event.filter, $event.cb, "trade");
      }
    },
    model: {
      value: _vm.filters.deal_name,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "deal_name", $$v);
      },
      expression: "filters.deal_name"
    }
  })], 1) : _vm._e(), _vm.isShowTextSearch ? _c(_setup.TextSearch, {
    attrs: {
      filters: _vm.filters
    }
  }) : _vm._e(), _c("el-divider"), _vm.isShowEventTracker ? _c(_setup.EventTracker, {
    ref: "eventTracker",
    attrs: {
      filters: _vm.filters
    }
  }) : _vm._e(), _vm.isShowEventOrderTracker ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      id: "event-order-tracker"
    }
  }, [_c(_setup.EventOrderTracker, {
    ref: "eventOrderTracker",
    attrs: {
      filters: _vm.filters
    }
  })], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;