"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String,
    maxlength: Number
  },
  computed: {
    localMaxlength() {
      if (this.maxlength) {
        return this.maxlength;
      } else {
        return this.type === 'tel' ? 11 : 500;
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('input', val.trim());
    }
  }
};
exports.default = _default;