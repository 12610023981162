"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    eventEngine: {
      type: Array,
      default: () => [],
      required: true
    },
    tagSize: {
      type: String,
      default: 'medium'
    }
  }
};
exports.default = _default;