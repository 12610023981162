"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    ref: "dropdown",
    class: ["message-operate", _vm.isBtnVisible ? "visible" : ""],
    attrs: {
      placement: "bottom-end",
      trigger: "hover",
      "show-timeout": 0
    },
    on: {
      "visible-change": _vm.handleDropdownVisibleChange
    }
  }, [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "operate-btn"
    }, [_c("i", {
      staticClass: "iconfont icon-more-v"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "message-operate-menu",
    attrs: {
      slot: "dropdown"
    },
    nativeOn: {
      mouseenter: function ($event) {
        return _vm.handleEnterDropdown.apply(null, arguments);
      },
      mouseleave: function ($event) {
        return _vm.handleLeaveDropdown.apply(null, arguments);
      }
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.msg,
      expression: "msg",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }]
  }, [_c("i", {
    staticClass: "iconfont icon-copy-document"
  }), _vm._v(" 复制")]), _c("el-popover", {
    attrs: {
      "popper-class": "message-comment-popover",
      placement: "left-start",
      trigger: "hover",
      "visible-arrow": false
    },
    model: {
      value: _vm.popoverVisible,
      callback: function ($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c("div", {
    staticClass: "visible-scope"
  }, _vm._l(_vm.visibleScopeList, function (item) {
    return _c("p", {
      key: item.value,
      staticClass: "item",
      on: {
        click: function ($event) {
          return _vm.handeClick(item.value);
        }
      }
    }, [_c("i", {
      class: ["iconfont", item.icon]
    }), _c("span", [_vm._v(_vm._s(item.lable))])]);
  }), 0), _vm.messageOperateList.includes("comment") ? _c("el-dropdown-item", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "iconfont icon-chat-line-synchronization"
  }), _vm._v(" 同步至评论"), _c("i", {
    staticClass: "iconfont icon-arrow-right"
  })]) : _vm._e()], 1)], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;