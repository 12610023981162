"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tableHeightMixin = exports.paginationMixin = exports.handleTextHighLightMixin = void 0;
const paginationMixin = {
  data() {
    return {
      params: {
        current_page: 1,
        size: 10
      }
    };
  },
  methods: {
    handleSizeChange(val) {
      this.params.size = val;
      this.params.current_page = 1;
    },
    handleCurrentChange(currentPage) {
      this.params.current_page = currentPage;
    }
  }
};
exports.paginationMixin = paginationMixin;
const tableHeightMixin = {
  data() {
    return {
      screenHeight: document.body.clientHeight
    };
  },
  computed: {
    tableHeight() {
      const wh = this.screenHeight;
      const th = wh - 230;
      return th;
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenHeight = document.body.clientHeight;
      })();
    };
  }
};
exports.tableHeightMixin = tableHeightMixin;
const handleTextHighLightMixin = {
  methods: {
    handleContent(item, style_type) {
      if (!Object.prototype.hasOwnProperty.call(item, 'keywords')) {
        return [{
          word: item.content
        }];
      }
      let {
        content,
        keywords
      } = item;
      keywords = keywords.slice().sort((a, b) => {
        return b.begin_pos - a.begin_pos;
      });
      const resArr = [];
      let _keywords = null;
      let num = keywords.length - 1;
      if (['customize', 'context', 'account_tag'].includes(style_type)) {
        // 如果是自定义
        _keywords = [...keywords];
        const position = this.getKeywordsPosition(content, keywords[keywords.length - 1].begin_pos, keywords[0].end_pos);
        keywords = [{
          begin_pos: position.begin,
          end_pos: position.end,
          word: ''
        }];
      }
      for (const obj of keywords) {
        const [begin, end] = [obj.begin_pos, obj.end_pos];
        if (begin === end) continue;
        const endStr = content.substring(end);
        resArr.push({
          word: endStr,
          isKeyword: false
        });
        let keyword = content.substring(begin, end);
        if (_keywords) {
          keyword = this.handleUnderlineKeywords(keyword, keywords[0], _keywords);
        }
        resArr.push({
          word: keyword,
          isKeyword: true,
          num: num--
        });
        content = content.substring(0, begin);
      }
      content && resArr.push({
        word: content,
        isKeyword: false
      });
      return resArr.reverse();
    },
    // 将多关键词合并的句子中关键词加上下划线
    handleUnderlineKeywords(keyword, keywords, _keywords) {
      /**
       * keyword 高亮的词
       * keywords 包含处理（首尾相连）过的关键词的信息，只有一个begin_pos和end_pos
       * _keywords 原本keywords的副本，只有is_customize类型才有
       */
      let sentence = keyword;
      const strs = [];
      for (const item of _keywords) {
        let {
          begin_pos,
          end_pos
        } = item;

        // 把绝对位置变为相对位置
        begin_pos -= keywords.begin_pos;
        end_pos -= keywords.begin_pos;
        // 以下划线关键词为界切分字符串为3份，
        const endStr = sentence.substring(end_pos);
        const str = `<span class="underline">${sentence.substring(begin_pos, end_pos)}</span>`;
        const startStr = sentence.substring(0, begin_pos);
        // 将sentence重新赋值进入循环
        sentence = startStr;
        strs.push(endStr, str);
      }
      const str = sentence + strs.reverse().join('');
      return str;
    },
    // 获取标点符号索引的数组
    getPunctuationIndexArray(content) {
      let resultArr = [0, content.length - 1];
      const punctuationArray = ['，', '。', '？'];
      punctuationArray.forEach(item => {
        const strArray = this.getIndexInStr(content, item);
        resultArr = [...strArray, ...resultArr];
      });
      resultArr.sort(function (a, b) {
        return a - b;
      });
      return resultArr;
    },
    // 字符串查找
    getIndexInStr(str, findstr) {
      const array = [];
      // 字符出现的位置
      var index = -1;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // 从字符串出现的位置的下一位置开始继续查找
        index = str.indexOf(findstr, index + 1);
        if (index == -1) {
          break;
        }
        array.push(index);
      }
      return array;
    },
    // 获取关键字所在 内容段内的前后位置
    getKeywordsPosition(content, begin, end) {
      const position = {
        begin,
        end
      };
      const punctuationIndexArray = this.getPunctuationIndexArray(content);
      const contentLen = content.length - 1;
      let newBegin = 0;
      let newEnd = contentLen;
      for (const i in punctuationIndexArray) {
        if (punctuationIndexArray[i] <= begin && punctuationIndexArray[i] != 0) {
          newBegin = punctuationIndexArray[i] + 1;
        }
        if (punctuationIndexArray[i] >= end && newEnd == contentLen) {
          newEnd = punctuationIndexArray[i];
        }
      }
      position.begin = newBegin;
      position.end = newEnd;
      return position;
    }
  }
};
exports.handleTextHighLightMixin = handleTextHighLightMixin;