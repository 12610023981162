"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.route_permission = exports.help_center = exports.api_permission = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("@/store"));
var _config = require("@/utils/config");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const api_permission = _vue.default.directive('api-permission', {
  bind: (el, binding, vnode) => {
    const permissions = _store.default.getters.api_permissions;
    const value = binding.value;
    let flag = true;
    if (value) {
      for (const v of value) {
        if (!permissions.includes(v)) {
          flag = false;
          break;
        }
      }
    }
    if (!flag) {
      if (!el.parentNode) {
        el.style.display = 'none';
      } else {
        el.parentNode.removeChild(el);
      }
    }
  }
});
exports.api_permission = api_permission;
const route_permission = _vue.default.directive('route-permission', {
  bind: (el, binding, vnode) => {
    const webRoutes = _store.default.getters.webRoutes;
    const value = binding.value;
    let flag = true;
    if (value) {
      for (const v of value) {
        if (!webRoutes.find(item => item.name === v)) {
          flag = false;
          break;
        }
      }
    }
    if (!flag) {
      if (!el.parentNode) {
        el.style.display = 'none';
      } else {
        el.parentNode.removeChild(el);
      }
    }
  }
});
exports.route_permission = route_permission;
const help_center = _vue.default.directive('help-center', {
  bind: el => {
    if (!_config.config.isMegaview) {
      if (!el.parentNode) {
        el.style.display = 'none';
      } else {
        el.parentNode.removeChild(el);
      }
    }
    //   const org_display_info = store.getters.org_display_info;
    //   let showHelpCenter;
    //   const timer = setInterval(() => {
    //     showHelpCenter = org_display_info.is_show_help_center;
    //     console.log('showHelpCenter', showHelpCenter);
    //     if (showHelpCenter !== undefined) {
    //       clearInterval(timer);
    //       if (!showHelpCenter) {
    //         console.log('org_display_info', org_display_info.is_show_help_center);
    //         if (!el.parentNode) {
    //           el.style.display = 'none';
    //         } else {
    //           el.parentNode.removeChild(el);
    //         }
    //       }
    //     }
    //   }, 100);
    //   const timer1 = setTimeout(() => {
    //     clearInterval(timer);
    //     clearTimeout(timer1);
    //   }, 2000);
  }
});

exports.help_center = help_center;