"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEffectTestWebsocket = useEffectTestWebsocket;
var _vue = require("vue");
var _privateWebsocket = _interopRequireDefault(require("@/utils/privateWebsocket"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function useEffectTestWebsocket(_ref) {
  let {
    store,
    addSentence
  } = _ref;
  const websocketMsg = (0, _vue.computed)(() => store.getters.webSocketMsg);
  const websocket = (0, _vue.ref)(null);
  const closeWebsocket = () => {
    if (websocket.value) {
      websocket.value.close();
    }
  };
  const initWebsocket = params => {
    closeWebsocket();
    websocket.value = new _privateWebsocket.default('/webapi/training/plan/ai/customer/gamma');
    websocket.value.init(params);
  };
  (0, _vue.watch)(websocketMsg, val => {
    switch (val.type) {
      case 'plan_success':
        // 连接成功
        //   this.practiceStatus = 'started';
        break;
      case 'plan_chat_create_success':
        // 会话创建成功
        // 存储当前会话id
        //   this.currentConverId = newVal.data.content;
        //   this.websocket &&
        //     this.websocket.handlerPath({
        //       course_id: this.courseId,
        //       conv_id: this.currentConverId
        //     });
        //   // 销售主动发默认话术
        //   this.saleDefaultScript &&
        //     this.echo(this.$t('coaching.selfIntroduction', [this.orgName]));
        break;
      case 'plan_chat_create_failed':
        // 会话创建失败
        //   this.$message.error(this.$t('coaching.sessionCreationFailed'));
        //   this.closeWebsocket();
        break;
      case 'plan_chat_restore_success':
        // 会话恢复成功
        //   this.handleChatRestoreSuccess();
        break;
      case 'plan_chat_restore_failed':
        // 会话恢复失败
        //   this.$message.error(this.$t('coaching.sessionRecoveryFailed'));
        //   this.closeWebsocket();
        break;
      case 'plan_message':
        // 客户话术消息
        addSentence(val.data);
        break;
      case 'plan_course_delete':
        // 课程被删除
        //   this.handleCourseDelete();
        break;
      case 'plan_close':
        // 后端关闭连接消息
        // this.closeWebsocket();
        break;
      case 'plan_error':
        // 后端报错
        //   this.closeWebsocket();
        break;
      default:
        break;
    }
  }, {
    deep: true
  });
  return {
    ws: websocket,
    initWebsocket
  };
}