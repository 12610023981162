"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conversation-dimension-filters"
  }, _vm._l(_setup.conversationDimensionFilters, function (item) {
    return _c(_setup.ConversationFilterItem, {
      key: item.type,
      attrs: {
        label: item.name,
        "is-show-clear": !!_vm.filters[item.type].length,
        "popover-content": item.content
      },
      on: {
        handleClear: function ($event) {
          _vm.filters[item.type] = [];
        }
      }
    }, [_c(_setup.AmountRangeInput, {
      attrs: {
        value: _vm.filters[item.type],
        unit: item.unit,
        item: item.valueType
      },
      on: {
        amountChange: function ($event) {
          return _setup.speakCountsChange($event, item.type);
        }
      }
    })], 1);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;