"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MutualExclusionBasicInfoForm = _interopRequireDefault(require("./MutualExclusionBasicInfoForm"));
var _MutualExclusionRuleForm = _interopRequireDefault(require("./MutualExclusionRuleForm"));
var _useMessage = require("@/hooks/use-message");
var _useVuex = require("@/hooks/use-vuex");
var _keyEventMutex = require("@/api/keyEventMutex");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateMutualExclusionRule',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    strategyId: {
      type: Number,
      default: 0
    },
    usedIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:visible', 'refreshList'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const updateVisible = () => {
      initData();
      emit('update:visible', false);
    };
    const mutualExclusionBasicInfoForm = (0, _vue.ref)({
      name: '',
      description: ''
    });
    const mutualExclusionRuleForm = (0, _vue.ref)({
      config: {
        type: 1
      },
      mutex_details: [{
        event_id: '',
        order: 1
      }]
    });
    const mutexRuleFormValidRef = (0, _vue.ref)(null);
    function initData() {
      mutualExclusionBasicInfoForm.value = {
        name: '',
        desc: ''
      };
      mutualExclusionRuleForm.value = {
        config: {
          type: 1
        },
        mutex_details: [{
          event_id: '',
          order: 1
        }]
      };
      mutexRuleFormValidRef.value.resetFormValid();
    }
    const addMutexEventItem = () => {
      mutualExclusionRuleForm.value.mutex_details.push({
        event_id: '',
        order: mutualExclusionRuleForm.value.mutex_details.length + 1
      });
    };
    const deleteMutexEventItem = index => {
      mutualExclusionRuleForm.value.mutex_details.splice(index, 1);
    };

    // 获取提取参数
    const getRequestParams = () => {
      const {
        name,
        description
      } = mutualExclusionBasicInfoForm.value;
      const {
        config,
        mutex_details
      } = mutualExclusionRuleForm.value;
      const localMutexDetails = mutex_details.filter(item => item.event_id);
      localMutexDetails.forEach((item, index) => {
        item.order = index + 1;
      });
      const basicParams = {
        name,
        description,
        config,
        mutex_details: localMutexDetails,
        workspace_id: store.getters.workspaceInfo.id
      };
      return basicParams;
    };

    // 表单是否通过校验
    const mutexBasicInfoFormRef = (0, _vue.ref)(null);
    const getFormValid = async () => {
      const {
        name
      } = mutualExclusionBasicInfoForm.value;
      const {
        mutex_details
      } = mutualExclusionRuleForm.value;
      const basicInfoFormValid = await mutexBasicInfoFormRef.value.validFormResult();
      const mutexRuleFormValid = mutexRuleFormValidRef.value.getMutexRuleFormValidResult();
      console.log(basicInfoFormValid, mutexRuleFormValid);
      if (!name) {
        _useMessage.useMessage.warning('名称不能为空');
        return false;
      }
      const validEventIds = mutex_details.filter(item => item.event_id);
      if (validEventIds.length < 2) {
        _useMessage.useMessage.warning('至少填写两个关键事件');
        return false;
      }
      return true;
    };
    const submitLoading = (0, _vue.ref)(false);
    // 提交表单
    const submit = async () => {
      const validFormResult = await getFormValid();
      if (!validFormResult) {
        return;
      }
      submitLoading.value = true;
      const requestApi = props.strategyId ? editKeyEventMutexApi : createKeyEventMutexApi;
      const res = await requestApi().finally(() => submitLoading.value = false);
      if (res.code === 20000) {
        (0, _useMessage.useMessage)({
          type: 'success',
          message: props.strategyId ? '保存成功' : '创建成功'
        });
        updateVisible();
        initData();
        emit('refreshList');
      }
    };
    const createKeyEventMutexApi = () => {
      const params = getRequestParams();
      return (0, _keyEventMutex.createKeyEventMutex)({
        ...params
      });
    };
    const editKeyEventMutexApi = () => {
      const params = getRequestParams();
      return (0, _keyEventMutex.editKeyEventMutex)({
        ...params,
        id: props.strategyId
      });
    };
    const extraUsedIds = (0, _vue.ref)([]);
    const getExtraUsedIds = () => {
      const currentIds = mutualExclusionRuleForm.value.mutex_details.map(item => item.event_id);
      console.log(currentIds, 'currentIds');
      props.usedIds.forEach(item => {
        if (!currentIds.includes(item)) {
          extraUsedIds.value.push(item);
        }
      });
    };

    // 获取互斥策略详情
    const loading = (0, _vue.ref)(false);
    const getKeyEventMutexDetailApi = async id => {
      loading.value = true;
      const res = await (0, _keyEventMutex.getKeyEventMutexDetail)({
        id,
        workspace_id: store.getters.workspaceInfo.id
      }).finally(() => loading.value = false);
      if (res.code === 20000) {
        const {
          name,
          description,
          mutex_details,
          config
        } = res.results;
        mutualExclusionBasicInfoForm.value.name = name;
        mutualExclusionBasicInfoForm.value.description = description;
        mutualExclusionRuleForm.value.mutex_details = mutex_details;
        getExtraUsedIds();
        mutualExclusionRuleForm.value.config = config;
      }
    };
    expose({
      getKeyEventMutexDetailApi
    });
    return {
      __sfc: true,
      props,
      store,
      emit,
      updateVisible,
      mutualExclusionBasicInfoForm,
      mutualExclusionRuleForm,
      mutexRuleFormValidRef,
      initData,
      addMutexEventItem,
      deleteMutexEventItem,
      getRequestParams,
      mutexBasicInfoFormRef,
      getFormValid,
      submitLoading,
      submit,
      createKeyEventMutexApi,
      editKeyEventMutexApi,
      extraUsedIds,
      getExtraUsedIds,
      loading,
      getKeyEventMutexDetailApi,
      MutualExclusionBasicInfoForm: _MutualExclusionBasicInfoForm.default,
      MutualExclusionRuleForm: _MutualExclusionRuleForm.default
    };
  }
};
exports.default = _default;