"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog prompt-template-library-dialog",
    attrs: {
      title: "提示语模版",
      visible: _vm.visible,
      width: "1000px",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c(_setup.PromptTemplateLibraryFilters, {
    on: {
      filterChange: _setup.filterChange
    }
  }), _c("div", {
    staticClass: "prompt-template-library-content"
  }, [_c("MgvInfiniteScroll", {
    staticClass: "prompt-template-library-list",
    attrs: {
      loading: _setup.loading,
      "has-more": _setup.hasMore
    },
    on: {
      loadMore: _setup.getPromptTemplateListApi
    }
  }, _vm._l(_setup.promptsList, function (prompt) {
    var _prompt$create_by;
    return _c("div", {
      key: prompt.id,
      staticClass: "prompt-item"
    }, [_c("div", {
      staticClass: "prompt-item__header"
    }, [_c("div", {
      staticClass: "prompt-item__header__left"
    }, [_c("div", {
      staticClass: "prompt-item__title"
    }, [_vm._v(_vm._s(prompt.name))]), _c("div", {
      staticClass: "prompt-item__desc"
    }, [_c("span", [_vm._v("ID： " + _vm._s(prompt.id))]), _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }), _c("span", [_vm._v("创建时间: " + _vm._s(prompt.create_at))]), _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }), _c("span", [_vm._v("创建人： " + _vm._s((_prompt$create_by = prompt.create_by) === null || _prompt$create_by === void 0 ? void 0 : _prompt$create_by.name))])], 1)]), _c("div", {
      staticClass: "prompt-item__header__operate"
    }, [_c("MgvButton", {
      attrs: {
        size: "medium",
        type: "plain"
      },
      on: {
        click: function ($event) {
          return _setup.usePromptTemplate(prompt);
        }
      }
    }, [_vm._v("使用")]), _c("MgvButton", {
      attrs: {
        size: "medium",
        type: "plain",
        color: "danger"
      },
      on: {
        click: function ($event) {
          return _setup.deletePromptTemplateApi(prompt);
        }
      }
    }, [_vm._v("删除")])], 1)]), _c("el-divider"), _c("div", {
      staticClass: "prompt-item__content"
    }, [_c("div", [_vm._v(_vm._s(prompt.content))]), _c("MgvButton", {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: prompt.content,
        expression: "prompt.content",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _setup.onCopy,
        expression: "onCopy",
        arg: "success"
      }],
      attrs: {
        icon: "icon-copy-document",
        square: "",
        type: "text",
        color: "info",
        size: "small",
        "tooltip-content": "复制"
      }
    })], 1)], 1);
  }), 0)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;