"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  data() {
    return {
      visible: "",
      top: ""
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(["guideVisiable"])
  },
  methods: {
    hanldeClose() {
      this.$store.dispatch("video/set_guide_visiable", false);
      this.$emit("ctrlVisiable");
    },
    handleQuick() {
      this.$store.dispatch("video/set_guide_visiable", false);
      this.$emit("ctrlVisiable");
      this.$bus.$emit('openIdentityPopover');
    }
  }
};
exports.default = _default;