"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LoadingDataMessage = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/LoadingDataMessage.vue"));
var _ManualImportDataSetDialog = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/ManualImportDataSetDialog.vue"));
var _FilterConvDrawer = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ruleEventTest/filterConversation/FilterConvDrawer.vue"));
var _AnnotateResultsFilters = _interopRequireDefault(require("./AnnotateResultsFilters.vue"));
var _AnnotateResultsTable = _interopRequireDefault(require("./AnnotateResultsTable.vue"));
var _AnnotateResultsDialog = _interopRequireDefault(require("./AnnotateResultsDialog.vue"));
var _annotateResults = require("../hooks/annotateResults");
var _useImportAnnotation = require("../hooks/use-import-annotation");
var _fixTrainDataset = require("@/views/management/rapidModeling/hooks/fixTrainDataset");
var _HorizontalScroll = _interopRequireDefault(require("@/views/management/rapidModeling/components/ModelSentence/components/HorizontalScroll.vue"));
var _AnnotateResultsTabs = _interopRequireDefault(require("./AnnotateResultsTabs.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
var _useMessage = require("@/hooks/use-message");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateResultsDrawer',
  props: {
    drawerVisible: Boolean,
    dataSetId: Number,
    dataSetName: String,
    externalLabel: Object
  },
  emits: ['update:drawerVisible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    (0, _vue.provide)('isInQuickModelingPage', true);
    const {
      annotatorsList,
      getAnnotatorsListApi
    } = (0, _annotateResults.useGetAnnotator)(props.dataSetId);
    const {
      allLabelsList,
      activeTabs,
      getDataSetAllLabelsApi
    } = (0, _annotateResults.useGetDataSetAllLabels)({
      dataSetId: props.dataSetId,
      externalLabel: props.externalLabel
    });
    const {
      params,
      dataSetStatistics,
      tableResults,
      paginationParams,
      allAnnotatedIds,
      sizeChange,
      currentChange,
      filterChange,
      tableSortChange,
      getAnnotatedIdsApi,
      getSentenceOfEventApi
    } = (0, _annotateResults.useAnnotateResults)({
      dataSetId: props.dataSetId,
      activeTabs: activeTabs
    });
    const handleClose = () => {
      emit('update:drawerVisible', false);
    };
    const {
      batchUpdateDatasetAnnotationApi
    } = (0, _annotateResults.useBatchUpdateDatasetAnnotation)({
      dataSetId: props.dataSetId,
      successCb: getSentenceOfEventApi
    });
    const tableSelectionList = (0, _vue.ref)([]);
    const tableSelectionChange = val => {
      tableSelectionList.value = val;
    };
    const getAllData = () => {
      getAnnotatedIdsApi();
      getSentenceOfEventApi();
    };
    const {
      openConversationDrawer,
      closeConversationDrawer,
      conversationDrawerVisible,
      conversationListChange,
      conversationList,
      openManualImportDialog,
      manualImportDataSetDialogVisible,
      expandTrainDataSetSuccess,
      showLoadingDataTips
    } = (0, _useImportAnnotation.useImportAnnotation)({
      refreshDataFun: getAllData
    });
    const {
      exportAnnotationResultsApi
    } = (0, _annotateResults.useExportAnnotationResults)();
    const exportAnnotationResults = () => {
      exportAnnotationResultsApi({
        annotationCount: tableResults.value.total,
        params: {
          body: params.value,
          module: 'dataset_annotation'
        }
      });
    };
    const {
      openAnnotationResultsDialog,
      annotationResultsDialogVisible,
      currentAnnotationResultId
    } = (0, _annotateResults.useOpenAnnotateResultDialog)();
    const {
      deleteAnnotationApi
    } = (0, _fixTrainDataset.useDeleteAnnotation)({
      successCallBack: getAllData
    });

    // 删除标注
    const deleteAnnotation = _ref2 => {
      let {
        annotationIds,
        isBatch = false
      } = _ref2;
      if (isBatch) {
        _elementUi.MessageBox.confirm(`确定删除${annotationIds.length}条数据的人工标注结果吗？`, '批量删除', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          deleteAnnotationApi({
            annotation_ids: annotationIds
          });
        }).catch(e => {});
      } else {
        deleteAnnotationApi({
          annotation_ids: annotationIds
        });
      }
    };
    const updateTableListAnnotationStatus = _ref3 => {
      let {
        id,
        status,
        sentences
      } = _ref3;
      const row = tableResults.value.tableData.find(item => item.id === id);
      if (row) {
        row.status = status;
        row.sentence = sentences;
      }
      getSentenceOfEventApi();
    };
    const onCopy = () => {
      _useMessage.useMessage.success('复制成功');
    };
    (0, _vue.onMounted)(async () => {
      getAnnotatorsListApi();
      await getDataSetAllLabelsApi();
      getAnnotatedIdsApi();
      getSentenceOfEventApi();
    });
    return {
      __sfc: true,
      props,
      annotatorsList,
      getAnnotatorsListApi,
      allLabelsList,
      activeTabs,
      getDataSetAllLabelsApi,
      params,
      dataSetStatistics,
      tableResults,
      paginationParams,
      allAnnotatedIds,
      sizeChange,
      currentChange,
      filterChange,
      tableSortChange,
      getAnnotatedIdsApi,
      getSentenceOfEventApi,
      emit,
      handleClose,
      batchUpdateDatasetAnnotationApi,
      tableSelectionList,
      tableSelectionChange,
      getAllData,
      openConversationDrawer,
      closeConversationDrawer,
      conversationDrawerVisible,
      conversationListChange,
      conversationList,
      openManualImportDialog,
      manualImportDataSetDialogVisible,
      expandTrainDataSetSuccess,
      showLoadingDataTips,
      exportAnnotationResultsApi,
      exportAnnotationResults,
      openAnnotationResultsDialog,
      annotationResultsDialogVisible,
      currentAnnotationResultId,
      deleteAnnotationApi,
      deleteAnnotation,
      updateTableListAnnotationStatus,
      onCopy,
      LoadingDataMessage: _LoadingDataMessage.default,
      ManualImportDataSetDialog: _ManualImportDataSetDialog.default,
      FilterConvDrawer: _FilterConvDrawer.default,
      AnnotateResultsFilters: _AnnotateResultsFilters.default,
      AnnotateResultsTable: _AnnotateResultsTable.default,
      AnnotateResultsDialog: _AnnotateResultsDialog.default,
      HorizontalScroll: _HorizontalScroll.default,
      AnnotateResultsTabs: _AnnotateResultsTabs.default
    };
  }
};
exports.default = _default;