"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-all-versions"
  }, [_c("div", {
    staticClass: "page-header"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: _setup.handleBack
    }
  }), _c("h2", {
    staticClass: "page-title"
  }, [_vm._v("全部版本-" + _vm._s(_setup.modelName))])]), _c(_setup.ModelItemTable, {
    staticClass: "versions-list",
    attrs: {
      data: _setup.allVersionsList,
      "model-id": _setup.modelId,
      "model-type": _setup.modelType,
      "table-loading": _setup.tableLoading
    },
    on: {
      refreshData: _setup.getModelVersionListApi,
      retryTrain: _setup.retryTrain
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      "current-page": _setup.currentPage,
      "page-size": _setup.pageSize,
      total: _setup.total
    },
    on: {
      "update:currentPage": function ($event) {
        _setup.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _setup.currentPage = $event;
      },
      "update:pageSize": function ($event) {
        _setup.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _setup.pageSize = $event;
      },
      sizeChange: _setup.onSizeChange,
      currentChange: _setup.getModelVersionListApi
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;