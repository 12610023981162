"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-score-correction-right-part"
  }, [_c("div", {
    staticClass: "top-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.activeTabName))]), _c("div", [_vm.saveButtonState ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveCorrectScore
    }
  }, [_vm._v("保存")]) : _c("el-button", {
    staticClass: "no-save",
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.noChangeSave
    }
  }, [_vm._v("保存")])], 1)]), _c("div", [_vm.scoreCorrectionContentList.length ? _c("ScoreCorrectionContent", {
    attrs: {
      "score-correction-content-list": _vm.scoreCorrectionContentList
    },
    on: {
      scoreChange: _vm.handleScoreChange
    }
  }) : _c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无数据")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;