"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SearchResult = _interopRequireDefault(require("@/components/Detail/SearchResult"));
var _videoDetail = require("@/api/videoDetail");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateSearch',
  props: {
    activeDataSetData: {
      type: Object,
      default: () => ({})
    },
    dataSetDataInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const inputWords = (0, _vue.ref)('');
    const isShowResult = (0, _vue.ref)(false);
    const searchResultList = (0, _vue.ref)([]);
    const getWordsSearchApi = async () => {
      const res = await (0, _videoDetail.getWordsSearch)(props.dataSetDataInfo.conversation_id, inputWords.value, store.getters.workspaceInfo.id);
      if (res.code === 20000) {
        searchResultList.value = res.results.sentences;
      }
    };
    const clickHandler = e => {
      if (!e.target.closest('#conv-search-input') && !e.target.closest('#conv-search-result')) {
        isShowResult.value = false;
      }
    };
    (0, _vue.onMounted)(() => {
      document.addEventListener('mousedown', clickHandler);
    });
    (0, _vue.onBeforeUnmount)(() => {
      document.removeEventListener('mousedown', clickHandler, true);
    });
    const setCurOrder = (order, item) => {
      console.log(order, 'order');
      store.dispatch('video/set_cur_order', {
        order: order,
        time: new Date().getTime(),
        beginTime: item.begin_time
      });
    };
    return {
      __sfc: true,
      props,
      store,
      inputWords,
      isShowResult,
      searchResultList,
      getWordsSearchApi,
      clickHandler,
      setCurOrder,
      SearchResult: _SearchResult.default
    };
  }
};
exports.default = _default;