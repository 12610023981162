"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConvRecycleBinHeader = _interopRequireDefault(require("./components/ConvRecycleBinHeader.vue"));
var _ConvRecycleBinTable = _interopRequireDefault(require("./components/ConvRecycleBinTable.vue"));
var _convRecycleBin = require("./hooks/convRecycleBin.js");
var _useVuex = require("@/hooks/use-vuex");
var _useRouter = require("@/hooks/use-router");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const route = (0, _useRouter.useRoute)();
    const {
      tableResults,
      paginationParams,
      sizeChange,
      currentChange,
      getConvRecycleBinApi
    } = (0, _convRecycleBin.useConvRecycleBin)(store);

    // 永久删除
    const {
      deleteConvRecycleBinApi
    } = (0, _convRecycleBin.useDeleteConvRecycleBin)(store, getConvRecycleBinApi);
    // 恢复
    const {
      recoverConvRecycleBinApi
    } = (0, _convRecycleBin.useRecoverConvRecycleBin)(store, getConvRecycleBinApi);
    const recoverRecycleBin = val => {
      recoverConvRecycleBinApi(val.id);
    };
    const deleteRecycleBin = val => {
      deleteConvRecycleBinApi(val.id);
    };
    (0, _vue.onMounted)(async () => {
      if (route.query.workspace_id) {
        await store.dispatch('workspace/getWorkspaceList', route.query.workspace_id - 0);
        getConvRecycleBinApi();
      }
    });
    return {
      __sfc: true,
      store,
      route,
      tableResults,
      paginationParams,
      sizeChange,
      currentChange,
      getConvRecycleBinApi,
      deleteConvRecycleBinApi,
      recoverConvRecycleBinApi,
      recoverRecycleBin,
      deleteRecycleBin,
      ConvRecycleBinHeader: _ConvRecycleBinHeader.default,
      ConvRecycleBinTable: _ConvRecycleBinTable.default
    };
  }
};
exports.default = _default;