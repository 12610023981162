"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'PasswordInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      tips: {
        length: {
          color: '#8A98A8',
          text: this.$t('management.8-20个字符'),
          reg: /^.{8,20}$/
        },
        number: {
          color: '#8A98A8',
          text: this.$t('management.包含数字'),
          reg: /\d/
        },
        letter: {
          color: '#8A98A8',
          text: this.$t('management.包含字母'),
          reg: /[a-zA-Z]/
        },
        specialCharacters: {
          color: '#8A98A8',
          text: this.$t('management.包含特殊字符'),
          reg: /[~!@#$%^&*()=\-|{}_+':;,[\].\\<>/\"?]/
        }
      },
      valid: false,
      error: false,
      flag: false,
      flagType: 'password'
    };
  },
  watch: {
    value(val) {
      for (const key in this.tips) {
        this.tips[key].color = this.tips[key].reg.test(val) ? '#29A370' : '#8A98A8';
      }
      const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()=\-|{}_+':;,[\].\\<>/\"?])[0-9A-Za-z\u0021-\u002f~!@#$%^&*()=\-|{}_+':;,[\].\\<>/\"?]{8,20}$/;
      this.valid = reg.test(val);
      this.error = false;
    }
  },
  methods: {
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? 'text' : 'password';
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    validValue() {
      for (const key in this.tips) {
        this.tips[key].color = this.tips[key].reg.test(this.value) ? '#29A370' : '#E54D52';
      }
      this.error = !this.valid;
    }
  }
};
exports.default = _default;