"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _CreateClueDialog = _interopRequireDefault(require("./CreateClueDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'HeaderFilter',
  components: {
    CreateClueDialog: _CreateClueDialog.default
  },
  props: {
    clueMultipleVal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      createClueDialogVisible: false,
      clueValue: 0,
      clueValueOptions: [{
        label: '全部线索',
        value: 0
      }, {
        label: '我负责的线索',
        value: 1
      }],
      clueStatus: '',
      clueStatusOptions: [{
        label: '所有状态',
        value: ''
      }, {
        label: '未转换',
        value: 'unconverted'
      }, {
        label: '已转换',
        value: 'converted'
      }],
      clueSearchValue: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    filterParams() {
      return {
        only_my_leads: this.clueValue,
        status: this.clueStatus,
        search_val: this.clueSearchValue
      };
    },
    hasSearchFilterOption() {
      return this.clueValue || this.clueStatus || this.clueSearchValue;
    }
  },
  watch: {
    filterParams: {
      handler: function () {
        this.$emit('change', this.filterParams);
      },
      deep: true
    }
  },
  methods: {
    openCreateClueDialog() {
      this.createClueDialogVisible = true;
    },
    createClueSuccess(val) {
      this.$emit('createClueSuccess', val);
    },
    initDefaultValue() {
      this.clueValue = 0;
      this.clueStatus = '';
      this.clueSearchValue = '';
    },
    handleChangeFollower(action) {
      this.$emit('changeFollowerAction', action);
    }
  }
};
exports.default = _default;