"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _checkbox = _interopRequireDefault(require("element-ui/packages/checkbox"));
var _emitter = _interopRequireDefault(require("element-ui/src/mixins/emitter"));
var _node = _interopRequireDefault(require("./mixin/node"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TreeVirtualNode',
  componentName: 'TreeVirtualNode',
  components: {
    ElCheckbox: _checkbox.default,
    NodeContent: {
      props: {
        node: {
          required: true
        }
      },
      render(h) {
        const parent = this.$parent;
        const tree = parent.tree;
        const node = this.node;
        const {
          data,
          store
        } = node;
        return parent.renderContent ? parent.renderContent.call(parent._renderProxy, h, {
          _self: tree.$vnode.context,
          node,
          data,
          store
        }) : tree.$scopedSlots.default ? tree.$scopedSlots.default({
          node,
          data
        }) : h("span", {
          "class": 'el-tree-node__label'
        }, [node.label]);
      }
    }
  },
  mixins: [_emitter.default, _node.default],
  props: {
    source: {
      default() {
        return {};
      }
    },
    renderContent: Function,
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tree: null,
      expanded: false,
      childNodeRendered: false,
      oldChecked: null,
      oldIndeterminate: null
    };
  },
  watch: {
    'source.indeterminate'(val) {
      this.handleSelectChange(this.source.checked, val);
    },
    'source.checked'(val) {
      this.handleSelectChange(val, this.source.indeterminate);
    },
    'source.expanded'(val) {
      this.$nextTick(() => {
        this.expanded = val;
      });
      if (val) {
        this.childNodeRendered = true;
      }
    }
  },
  created() {
    const parent = this.$parent.$parent.$parent;
    this.node = this.source;
    this.creator(parent, 'source');
  }
};
exports.default = _default;