"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/AnalysisConfig"));
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConfigDialog',
  components: {
    AnalysisConfig: _AnalysisConfig.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    editConfigData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formConfig: {
        analysisTargetLabel: '指标类型',
        analysisTargetKeyLabel: '指标项'
      },
      configData: {
        name: '',
        configs: {
          analysis_target: 'conversation',
          analysis_target_key: '',
          analysis_target_vals: '',
          conversation_type: [],
          draw_style: 'count',
          strategy: {
            results_type: '',
            key: '',
            vals: ''
          }
        },
        filter_config: {
          event_filters: {
            keyEventCascaderList: [{
              keyEventName: [],
              inverse: false
            }],
            event_engine_relation: 'and'
          },
          custom_filters: {}
        }
      }
    };
  },
  computed: {
    analysisTargetName() {
      const analysisTarget = [{
        type: 'conversation',
        label: '会话指标'
      }, {
        type: 'deal',
        label: '交易指标'
      }, {
        type: 'sop',
        label: 'SOP检测项'
      }];
      return analysisTarget.filter(_ref => {
        let {
          type
        } = _ref;
        return type === this.configData.configs.analysis_target;
      })[0].label;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (!this.isEdit) {
          this.configData = this.$options.data().configData;
          this.handleClearValidate();
        } else {
          this.configData = (0, _commonFunc.deepClone)(this.editConfigData);
          // 以下处理回填数据
          if (['key_event', 'business_trans'].includes(this.configData.configs.analysis_target_key)) {
            this.configData.configs.analysis_target_vals = this.configData.configs.analysis_target_vals.map(_ref2 => {
              let {
                keyEventName
              } = _ref2;
              return keyEventName;
            });
          }
          // SOP =>业务卡点转化率 =>关键结果是关键事件
          if (this.configData.configs.strategy.results_type === 'events') {
            this.configData.configs.strategy.key = this.configData.configs.strategy.key.map(_ref3 => {
              let {
                keyEventName
              } = _ref3;
              return keyEventName;
            });
          }
        }
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const analysis_target_key_name = this.$refs['analysis-config'].$refs['analysis-target-key'].selected.label;
          // 字段名称不填,则取指标名称
          if (!this.configData.name) {
            this.configData.name = analysis_target_key_name;
          }
          this.configData.configs.analysis_target_key_name = analysis_target_key_name;
          this.configData.configs.analysis_target_name = this.analysisTargetName;
          this.configData.configs.dimension = this.configData.configs.draw_style;
          this.formatKeyEvent();
          this.$emit('handleConfig', this.configData);
          this.handleClose();
        }
      });
    },
    customFilterChange(params) {
      this.configData.filter_config.custom_filters = params;
    },
    handleClearValidate() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
    formatKeyEvent() {
      const {
        configs
      } = this.configData;
      if (['key_event', 'business_trans'].includes(configs.analysis_target_key)) {
        this.configData.configs.analysis_target_vals = configs.analysis_target_vals.map(item => {
          return {
            keyEventName: item,
            inverse: false
          };
        });
      }
      // SOP =>业务卡点转化率 =>关键结果是关键事件
      if (configs.strategy.results_type === 'events') {
        this.configData.configs.strategy.key = configs.strategy.key.map(item => {
          return {
            keyEventName: item,
            inverse: false
          };
        });
      }
    }
  }
};
exports.default = _default;