"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "suggestion-select-wrapper"
  }, [!_vm.canBatchSearch ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      content: _vm.tooltipContent,
      disabled: _vm.tooltipIsDisabled(_vm.localValue)
    }
  }, [_c("el-select", {
    attrs: {
      "popper-class": "suggestion-select-pop",
      size: "medium",
      multiple: "",
      filterable: "",
      remote: "",
      "collapse-tags": "",
      clearable: "",
      "default-first-option": "",
      "reserve-keyword": "",
      placeholder: `请搜索${_vm.item.name}`,
      "remote-method": _vm.remoteMethod,
      loading: _vm.loading
    },
    on: {
      change: _vm.handleChange,
      "~focus": function ($event) {
        return _vm.handleFocus.apply(null, arguments);
      }
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _c("batch-search-input", {
    attrs: {
      placeholder: `请搜索${_vm.item.name}`
    },
    on: {
      getFuzzySearchList: function ($event) {
        return _vm.getFuzzySearchList($event.filter, $event.cb);
      },
      handleClear: _vm.handleClear
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;