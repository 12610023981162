"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "edit-kanban-name-dialog",
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "400px"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.config
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "看板名称",
      prop: "name",
      rules: [{
        required: true,
        message: "请输入看板名称",
        trigger: "blur"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      maxlength: "20",
      placeholder: "请输入看板名称"
    },
    model: {
      value: _vm.config.name,
      callback: function ($$v) {
        _vm.$set(_vm.config, "name", $$v);
      },
      expression: "config.name"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.handleLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.title === "复制" ? _vm.title : "确定"))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;