"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "coach-course-create"
  }, [_c("header", {
    staticClass: "course-header"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _vm.goBack
    }
  }), _c("div", {
    staticClass: "course-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("CourseTips", {
    attrs: {
      description: _vm.courseDescription
    }
  }, [_c("img", {
    staticClass: "tip-gif",
    attrs: {
      src: _vm.imgSrc,
      alt: "演示教程"
    }
  })])], 1), _c("div", {
    staticClass: "course-container"
  }, [_c("CourseInfo", {
    ref: "course-info",
    staticClass: "left",
    attrs: {
      type: _vm.type,
      "script-visible": _vm.scriptVisible,
      "checked-knowledge-scripts": _vm.checkedKnowledgeScripts
    },
    on: {
      "update:scriptVisible": function ($event) {
        _vm.scriptVisible = $event;
      },
      "update:script-visible": function ($event) {
        _vm.scriptVisible = $event;
      },
      goBack: _vm.goBack,
      addScript: _vm.addScript
    }
  }), _c("CourseScript", {
    ref: "courseScript",
    staticClass: "right",
    class: {
      "show-course-script": _vm.scriptVisible
    },
    attrs: {
      "script-visible": _vm.scriptVisible
    },
    on: {
      closeScript: () => _vm.scriptVisible = false,
      scrollToScriptEnd: _vm.scrollToScriptEnd
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;