"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationScoreDetail = _interopRequireDefault(require("./ConversationScoreDetail.vue"));
var _ConversationDetailKeyEvent = _interopRequireDefault(require("./ConversationDetailKeyEvent.vue"));
var _ConversationDetailDimension = _interopRequireDefault(require("./ConversationDetailDimension.vue"));
var _ConversationDetailAccountTag = _interopRequireDefault(require("./ConversationDetailAccountTag.vue"));
var _ConversationDetailComplianceScore = _interopRequireDefault(require("./ConversationDetailComplianceScore.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetailLeftContent',
  components: {
    ConversationScoreDetail: _ConversationScoreDetail.default,
    ConversationDetailKeyEvent: _ConversationDetailKeyEvent.default,
    ConversationDetailDimension: _ConversationDetailDimension.default,
    ConversationDetailAccountTag: _ConversationDetailAccountTag.default,
    ConversationDetailComplianceScore: _ConversationDetailComplianceScore.default
  },
  props: {
    tagSequence: {
      type: Array,
      default: () => []
    },
    mediaType: {
      type: String,
      default: 'video'
    },
    convId: {
      type: [Number, String],
      default: 0
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    },
    apiProcess: {
      type: Object,
      default: () => ({})
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    excludeMenu: {
      type: Array,
      default: () => []
    },
    rightBarActive: {
      type: String,
      default: ''
    },
    externalActiveTab: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeTab: 'keyEvent',
      isHideMenu: false,
      externalKeyEventId: 0,
      externalAccountTag: {},
      isLeftArrowShow: false,
      isRightArrowShow: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    menuList() {
      const allMenuList = [{
        label: '质检评分',
        name: 'complianceScore',
        icon: 'scoring-compliance',
        activeIcon: 'scoring-compliance-fill',
        component: 'ConversationDetailComplianceScore'
      }, {
        label: '关键事件',
        name: 'keyEvent',
        icon: 'key-event',
        activeIcon: 'key-event-fill',
        component: 'ConversationDetailKeyEvent'
      }, {
        label: '会话评分',
        name: 'conversationScore',
        icon: 'conversation-scoring-2',
        activeIcon: 'conversation-scoring-fill-2',
        component: 'ConversationScoreDetail'
      }, {
        label: '会话维度',
        name: 'conversationDimension',
        icon: 'analysis-3',
        activeIcon: 'analysis-3-fill',
        component: 'ConversationDetailDimension'
      }, {
        label: '客户标签',
        name: 'accountTag',
        icon: 'price-tag',
        activeIcon: 'price-tag-fill',
        component: 'ConversationDetailAccountTag'
      }];
      const adjustAllMenuList = [];
      this.tagSequence.forEach(item => {
        allMenuList.forEach(menu => {
          if (item === menu.name) {
            adjustAllMenuList.push(menu);
          }
        });
      });
      const excludeMenu = this.excludeMenu;
      function handleExcludeMenu(name) {
        if (!excludeMenu.includes(name)) {
          excludeMenu.push(name);
        }
      }
      if (this.mediaType === 'doc') {
        handleExcludeMenu('conversation_dimension');
      }
      if (!this.interfacePermission.complianceScore) {
        handleExcludeMenu('compliance_score');
      }
      const res = adjustAllMenuList.filter(item => {
        return !excludeMenu.includes(item.name.replace(/([A-Z])/g, '_$1').toLowerCase());
      });
      return res;
    }
  },
  watch: {
    externalActiveTab: {
      handler: function (val) {
        if (val) {
          this.activeTab = val;
        }
      },
      immediate: true
    }
  },
  updated() {
    this.$nextTick(() => {
      this.calcArrowVisibility();
    });
  },
  created() {
    this.handleExternalEntryKeyEvent();
    const conversationLeftContentStatus = localStorage.getItem('conversationLeftContentStatus');
    this.isHideMenu = conversationLeftContentStatus && localStorage.getItem('conversationLeftContentStatus') !== 'false';
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.calcArrowVisibility);
      document.getElementById('menu-tabs').addEventListener('scroll', this.calcArrowVisibility);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcArrowVisibility);
  },
  methods: {
    handleArrowClick(isRight) {
      const menuTabs = this.$refs.menuTabs;
      const menuTab = menuTabs.children[0];
      const step = menuTabs.offsetWidth - menuTab.offsetWidth - 64;
      const distance = isRight ? step : -step;
      menuTabs.scrollBy({
        left: distance,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.calcArrowVisibility();
      }, 500);
    },
    // 计算箭头是否显示
    calcArrowVisibility() {
      const menuTabs = this.$refs.menuTabs;
      if (!menuTabs) return;
      const maxScrollDistance = menuTabs.scrollWidth - menuTabs.offsetWidth;
      const nowScrollDistance = menuTabs.scrollLeft;
      this.isLeftArrowShow = nowScrollDistance > 0;
      this.isRightArrowShow = nowScrollDistance < maxScrollDistance - 3; // 减3像素是为了提高容错性，有时差1像素右侧箭头还在，影响操作按钮
    },

    switchTab(menu) {
      this.activeTab = menu.name;
    },
    handleExternalEntryKeyEvent() {
      const externalScore = this.$route.query.tab;
      if (externalScore == 'score') {
        localStorage.setItem('conversationLeftContentStatus', false);
        this.activeTab = 'conversationScore';
      }
      if (externalScore == 'complianceScore') {
        localStorage.setItem('conversationLeftContentStatus', false);
        this.activeTab = 'complianceScore';
      }
      const externalKeyEventId = this.$route.query.keyWordId;
      if (externalKeyEventId) {
        localStorage.setItem('conversationLeftContentStatus', false);
        this.activeTab = 'keyEvent';
      }
    },
    handleExpandOrPackUp() {
      this.isHideMenu = !this.isHideMenu;
      localStorage.setItem('conversationLeftContentStatus', this.isHideMenu);
    },
    handleComponent() {
      var _this$menuList$find;
      const res = ((_this$menuList$find = this.menuList.find(item => item.name === this.activeTab)) === null || _this$menuList$find === void 0 ? void 0 : _this$menuList$find.component) || 'ScoreDetail';
      return res;
    }
  }
};
exports.default = _default;