"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "global-search-root"
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "prefix-icon": "el-icon-search",
      placeholder: "搜索交易、会话、关键事件或会话文本",
      "remote-method": _vm.remoteMethod,
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function () {
        return [_c("i", {
          staticClass: "iconfont icon-search"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.options, function (group, groupIndex) {
    return _c("el-option-group", {
      key: groupIndex,
      attrs: {
        label: group.label
      }
    }, _vm._l(group.options, function (item, index) {
      return _c("el-option", {
        key: index,
        attrs: {
          label: item.name,
          value: item.id
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleOptionClick(item);
          }
        }
      }, [_c("i", {
        class: ["iconfont option-icon", _vm.getIconClass(item.type)]
      }), item.type === "event" ? [_c("span", [_vm._v("含关键事件「")]), _c("span", {
        staticClass: "search-option-text ellipsis",
        domProps: {
          innerHTML: _vm._s(item.highlight_name)
        }
      }), _c("span", [_vm._v("」的会话")])] : [_c("span", {
        staticClass: "search-option-text ellipsis",
        domProps: {
          innerHTML: _vm._s(item.highlight_name)
        }
      })]], 2);
    }), 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;