"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "新建联系人",
      visible: _vm.contactPersonDialogVisible,
      "before-close": _vm.closeDialog,
      "custom-class": "contact-person-dialog",
      "modal-append-to-body": false,
      width: "700px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.contactPersonDialogVisible = $event;
      }
    }
  }, [_c("dynamic-form", {
    ref: "contactDynamicForm",
    attrs: {
      "label-position": "top",
      desc: _vm.contactFormDesc
    },
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;