"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "daily-detail-card"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "icon-container"
  }, [_c("div", {
    staticClass: "icon"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": _vm.getIcon(_vm.detail.conversation_type)
    }
  })], 1)]), _c("p", {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.detail.conversation_name || "-"))]), _vm.detail.conversation_status === "recording" ? _c("mg-tag", {
    attrs: {
      center: "",
      type: "primary",
      size: "smini"
    }
  }, [_c("icon-process"), _vm._v(" " + _vm._s(_vm.statusText))], 1) : _vm.detail.conversation_status !== "created" ? _c("mg-tag", {
    class: _vm.detail.conversation_status === "expire" ? "expire-status" : "",
    attrs: {
      center: ""
    }
  }, [_vm._v(_vm._s(_vm.statusText))]) : _vm._e()], 1), _c("div", {
    staticClass: "card-body"
  }, [_c("div", {
    staticClass: "content"
  }, [_vm.detail.trade_id ? [_c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-money2"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("金额（元）：")]), _c("span", {
    class: ["head-span", _vm.detail.trade_amount ? "amount" : ""]
  }, [_vm._v(_vm._s(_vm.detail.trade_amount || "-"))])]), _c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-deal5"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("交易阶段：")]), _c("span", {
    staticClass: "head-span"
  }, [_vm._v(_vm._s(_vm.detail.trade_stage || "-"))])]), _c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-date"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("预计成交日期：")]), _c("span", {
    staticClass: "head-span"
  }, [_vm._v(_vm._s(_vm.detail.trade_range || "-"))])])] : _vm._e(), _vm.detail.instruction_set_result && _vm.detail.instruction_set_result.length ? [_vm._m(0), _c("div", {
    staticClass: "summary-container extract-info-container"
  }, _vm._l(_vm.detail.instruction_set_result, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "extract-info-item"
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.question_name,
        expression: "item.question_name"
      }],
      staticClass: "extract-info-question"
    }, [_c("span", [_vm._v(_vm._s(item.question_name))])]), _c("div", {
      staticClass: "extract-info-llm_answer"
    }, [_vm._v(_vm._s(item.llm_answer))])]);
  }), 0), _c("el-divider")] : _vm._e(), _vm.detail.summary_result ? [_vm._m(1), _c("div", {
    staticClass: "summary-container"
  }, [_vm._v(_vm._s(_vm.detail.summary_result))]), _c("el-divider")] : _vm._e(), _c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-key-event"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("关键事件：")]), !_vm.eventEngineList.length ? _c("span", {
    staticClass: "head-span"
  }, [_vm._v(" - ")]) : _vm._e()]), _vm.eventEngineList.length ? _c("events-list", {
    attrs: {
      "event-engine": _vm.eventEngineList
    }
  }) : _vm._e(), _c("el-divider"), _c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("时间：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.$moment(_vm.detail.begin_time).format("M月D日 (ddd) HH:mm")))])]), _vm.detail.conversation_status !== "expire" && _vm.detail.conversation_type !== "doc" ? _c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("时长：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.getDuration(_vm.detail.duration)))])]) : _vm._e(), _vm.detail.account_name ? _c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("客户：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.detail.account_name || "-"))])]) : _vm._e(), _vm.detail.contact ? [_c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("联系人：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.detail.contact.name || "-"))])]), _c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("手机号：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.detail.contact.tel || "-"))])])] : _vm._e(), _c("p", {
    staticClass: "row"
  }, [_c("label", {
    staticClass: "body-label"
  }, [_vm._v("销售：")]), _c("span", {
    staticClass: "body-span"
  }, [_vm._v(_vm._s(_vm.detail.host_name || "-"))])])], 2)]), _c("div", {
    staticClass: "card-footer"
  }, [_vm.detail.trade_id ? _c("el-button", {
    staticClass: "mg-button",
    on: {
      click: _vm.goTradeDetail
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "deal4"
    }
  }), _vm._v("查看交易")], 1) : _vm._e(), _c("el-button", {
    staticClass: "mg-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.openMeetingDetail
    }
  }, [_c("i", {
    staticClass: "iconfont icon-video"
  }), _vm._v(" 查看会话详情")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-extract-information"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("信息提取")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "row"
  }, [_c("i", {
    staticClass: "iconfont icon-tickets-ai"
  }), _c("label", {
    staticClass: "head-label"
  }, [_vm._v("智能纪要：")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;