"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const common = {
  zh: {
    common: {
      personalCenter: '个人中心',
      trialVersion: '试用版',
      probationaryPeriod: '试用期至{0}',
      toggle: '切换',
      language: '语言',
      languageToggleTips: '仅支持培训部分功能的多语言切换',
      managementBackend: '管理后台',
      homePage: '官网首页',
      logout: '退出登录'
    }
  },
  en: {
    common: {
      personalCenter: 'Personal Center',
      trialVersion: 'Trial Version',
      probationaryPeriod: 'Trial Period Until {0}',
      toggle: 'Switch',
      language: 'Language',
      languageToggleTips: 'Supports Training Function Only',
      managementBackend: 'Management Backend',
      homePage: 'Official Website',
      logout: 'Logout'
    }
  },
  fr: {
    common: {
      personalCenter: 'Centre Personnel',
      trialVersion: "Version d'essai",
      probationaryPeriod: "Période d'essai jusqu'à {0}",
      toggle: 'Changer',
      language: 'Langue',
      languageToggleTips: "Supporte uniquement la fonction d'entraînement",
      managementBackend: 'Backend de gestion',
      homePage: 'Site officiel',
      logout: 'Déconnexion'
    }
  },
  de: {
    common: {
      personalCenter: 'Persönliches Zentrum',
      trialVersion: 'Testversion',
      probationaryPeriod: 'Testphase bis {0}',
      toggle: 'Wechseln',
      language: 'Sprache',
      languageToggleTips: 'Unterstützt nur die Trainingsfunktion',
      managementBackend: 'Verwaltungs-Backend',
      homePage: 'Offizielle Webseite',
      logout: 'Abmelden'
    }
  },
  es: {
    common: {
      personalCenter: 'Centro Personal',
      trialVersion: 'Versión de prueba',
      probationaryPeriod: 'Período de prueba hasta {0}',
      toggle: 'Cambiar',
      language: 'Idioma',
      languageToggleTips: 'Solo admite la función de entrenamiento',
      managementBackend: 'Backend de gestión',
      homePage: 'Sitio web oficial',
      logout: 'Cerrar sesión'
    }
  },
  pt: {
    common: {
      personalCenter: 'Centro Pessoal',
      trialVersion: 'Versão de teste',
      probationaryPeriod: 'Período de teste até {0}',
      toggle: 'Mudar',
      language: 'Idioma',
      languageToggleTips: 'Suporta apenas a função de treinamento',
      managementBackend: 'Backend de gerenciamento',
      homePage: 'Site oficial',
      logout: 'Sair'
    }
  },
  it: {
    common: {
      personalCenter: 'Centro Personale',
      trialVersion: 'Versione di prova',
      probationaryPeriod: 'Periodo di prova fino a {0}',
      toggle: 'Cambia',
      language: 'Lingua',
      languageToggleTips: 'Supporta solo la funzione di formazione',
      managementBackend: 'Backend di gestione',
      homePage: 'Sito web ufficiale',
      logout: 'Logout'
    }
  }
};
var _default = common;
exports.default = _default;