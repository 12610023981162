"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LableRequireTooltipRemark = _interopRequireDefault(require("@/views/businessIntelligence/components/portraitConfigComponents/LableRequireTooltipRemark"));
var _dealTargetInput = _interopRequireDefault(require("@/views/businessIntelligence/SOP/components/BusinessReach/components/dealTargetInput"));
var _conversationList = require("@/api/conversationList");
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _business = require("@/api/business");
var _EventSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/components/EventSelect"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ReachDrawer',
  components: {
    LableRequireTooltipRemark: _LableRequireTooltipRemark.default,
    DealTargetInput: _dealTargetInput.default,
    EventSelect: _EventSelect.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean
    },
    getKanbanConfigLoading: {
      type: Boolean
    },
    responseKanbanInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      triggerFlag: 'blur',
      options: [],
      targetInputOptions: [],
      dealTarget: '',
      kanbanConfig: {
        name: '',
        kanban_target: 'event',
        kanban_info: {
          events: [{
            key: Symbol(),
            event_id: ''
          }, {
            key: Symbol(),
            event_id: ''
          }],
          deal_events: [{
            key: Symbol(),
            event_id: ''
          }, {
            key: Symbol(),
            event_id: ''
          }],
          deal_field: {
            id: '',
            name: '',
            key: Symbol()
          },
          deal_targets: [{
            key: Symbol(),
            name: '指标1',
            options: []
          }, {
            key: Symbol(),
            name: '指标2',
            options: []
          }]
        }
      },
      evnetIds: '',
      submitLoading: false,
      optionsList: [],
      rules: {
        name: [{
          required: true,
          message: '请输入看板名称',
          trigger: ['blur', 'change']
        }]
      },
      validateTarget: (rule, value, callback) => {
        if (!value.length) {
          return callback(new Error('交易指标不能为空'));
        } else {
          callback();
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    isShowAddBtn() {
      if (this.kanbanConfig.kanban_target === 'deal_target') {
        var _this$kanbanConfig$ka, _this$kanbanConfig$ka2;
        return ((_this$kanbanConfig$ka = this.kanbanConfig.kanban_info) === null || _this$kanbanConfig$ka === void 0 ? void 0 : (_this$kanbanConfig$ka2 = _this$kanbanConfig$ka.deal_targets) === null || _this$kanbanConfig$ka2 === void 0 ? void 0 : _this$kanbanConfig$ka2.length) < 10;
      } else {
        var _this$kanbanConfig$ka3, _this$kanbanConfig$ka4;
        return ((_this$kanbanConfig$ka3 = this.kanbanConfig.kanban_info) === null || _this$kanbanConfig$ka3 === void 0 ? void 0 : (_this$kanbanConfig$ka4 = _this$kanbanConfig$ka3.events) === null || _this$kanbanConfig$ka4 === void 0 ? void 0 : _this$kanbanConfig$ka4.length) < 10;
      }
    },
    disabledList() {
      return this.kanbanConfig.kanban_info.events.map(_ref => {
        let {
          event_id
        } = _ref;
        return event_id;
      });
    },
    disabledTargetList() {
      let arr = [];
      this.kanbanConfig.kanban_info.deal_targets.forEach(_ref2 => {
        let {
          options
        } = _ref2;
        arr = arr.concat(options);
      });
      return arr;
    },
    targetOptions() {
      return this.options.find(_ref3 => {
        let {
          field_name
        } = _ref3;
        return field_name === this.kanbanConfig.kanban_info.deal_field.id;
      });
    }
  },
  watch: {
    drawerVisible: {
      handler: function (val) {
        if (val) {
          this.triggerFlag = 'blur';
        }
      }
    },
    'workspaceInfo.id': {
      handler: function () {
        this.getOptions();
      },
      immediate: true
    }
  },
  created() {
    this.getDealCustomFilter();
  },
  methods: {
    changeAnalysisTarget(val) {
      this.triggerFlag = 'blur';
      if (val === 'deal_target') {
        var _this$kanbanConfig$ka5;
        this.$refs.eventSelect.defalutEvent = (_this$kanbanConfig$ka5 = this.kanbanConfig.kanban_info) === null || _this$kanbanConfig$ka5 === void 0 ? void 0 : _this$kanbanConfig$ka5.full_events;
      }
    },
    // 触达关键事件ids（仅叶子节点）
    handleEventIds() {
      let evnetIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.evnetIds = evnetIds;
      this.kanbanConfig.kanban_info.deal_events = evnetIds.map(item => {
        return {
          event_id: item.length ? item[item.length - 1] : ''
        };
      });
      this.kanbanConfig.kanban_info.full_events = evnetIds;
    },
    // 商机指标
    dealFieldChange() {
      this.$refs.dealTargetInput.map(item => item.value = []);
    },
    // 修改指标选框name
    changeDealTargetTitle(val, item) {
      item.name = val;
    },
    // deal_targets 属性赋值
    assignDealTargetsProperty(_ref4, item) {
      let {
        name,
        options
      } = _ref4;
      item.name = name;
      item.options = options;
    },
    async getDealCustomFilter() {
      const res = await (0, _business.getDealCustomFilter)({
        access_type: '/old_seach_list',
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        console.log(res.results, 'res.results');
        const resData = res.results;
        // 二期暂不考虑AI智能分析字段
        // 商机字段的商机阶段字段
        const dealStatus = resData['交易字段'].filter(item => item.field_name === 'status');
        // 自定义字段type为select和checkbox的字段
        const customField = resData['自定义字段'].filter(item => item.type === 'select' || item.type === 'checkbox');
        this.options = [...dealStatus, ...customField];
      }
    },
    addEventOrTarget() {
      this.triggerFlag = 'blur';
      if (this.kanbanConfig.kanban_target === 'event') {
        this.kanbanConfig.kanban_info.events.push({
          key: Symbol(),
          event_id: ''
        });
      }
      if (this.kanbanConfig.kanban_target === 'deal_target') {
        const len = this.kanbanConfig.kanban_info.deal_targets.length;
        this.kanbanConfig.kanban_info.deal_targets.push({
          key: Symbol(),
          name: `指标${len + 1}`,
          options: []
        });
      }
    },
    removeEventOrTarget(index) {
      const type = this.kanbanConfig.kanban_target;
      switch (type) {
        case 'event':
          this.kanbanConfig.kanban_info.events.splice(index, 1);
          break;
        case 'deal_target':
          this.kanbanConfig.kanban_info.deal_targets.splice(index, 1);
          break;
        default:
          break;
      }
    },
    setDisabled(options) {
      options.forEach(item => {
        this.$set(item, 'disabled', this.disabledList.includes(item.id));
        if (item.children) {
          this.setDisabled(item.children);
        }
      });
    },
    handleClose() {
      this.kanbanConfig = this.$options.data().kanbanConfig;
      this.$refs['kanbanConfigForm'].resetFields();
      this.$emit('update:drawerVisible', false);
    },
    formatRequestData(obj) {
      if (this.kanbanConfig.kanban_target === 'event') {
        const copyObj = (0, _commonFunc.deepClone)(obj);
        delete copyObj.kanban_info.deal_field;
        delete copyObj.kanban_info.deal_targets;
        delete copyObj.kanban_info.deal_events;
        delete copyObj.kanban_info.full_events;
        return copyObj;
      }
      delete obj.kanban_info.events;
      return obj;
    },
    submit() {
      this.triggerFlag = 'change';
      this.$refs['kanbanConfigForm'].validate(async valid => {
        if (valid) {
          const requestFn = this.isEdit ? _businessIntelligence.editBusinessReachKanban : _businessIntelligence.createBusinessReachKanban;
          try {
            this.submitLoading = true;
            const data = this.formatRequestData({
              ...this.kanbanConfig,
              workspace_id: this.workspaceInfo.id
            });
            const res = await requestFn(data);
            res.code === 200 && this.$message.success(this.isEdit ? '编辑成功' : '新建成功');
            this.$emit('handleKanbanSuccess');
            this.handleClose();
          } catch (error) {
            throw new Error(error);
          } finally {
            this.submitLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    async getOptions() {
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.optionsList = res.results.events;
      }
    }
  }
};
exports.default = _default;