"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "filter-item"
  }, [_c("div", {
    staticClass: "filter-label"
  }, [_vm._t("prefix"), _vm._v(" " + _vm._s(_vm.label) + " "), _vm.popoverContent ? _c("el-popover", {
    attrs: {
      placement: "top",
      trigger: "hover",
      "popper-class": "hint",
      content: _vm.popoverContent
    }
  }, [_vm._t("popoverHtml"), _c("i", {
    staticClass: "iconfont icon-info-1",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })], 2) : _vm._e(), _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowClear || false,
      expression: "isShowClear || false"
    }],
    staticClass: "iconfont icon-circle-close",
    on: {
      click: _vm.handleClear
    }
  })], 2), _vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;