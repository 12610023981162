"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _organization = require("@/api/organization");
var _default = {
  name: "UserScaleDialog",
  props: {
    userScaleDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        scale: ""
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(["orgName"])
  },
  methods: {
    resetForm() {
      this.$refs["form"].resetFields();
    },
    handleCloseDialog() {
      this.$emit("closeUserScaleDialog");
      this.resetForm();
    },
    submit() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          const res = await (0, _organization.membersScale)({
            number: Number(this.form.scale)
          });
          if (res.code === 20000) {
            this.handleCloseDialog();
            this.$confirm("您的申请已成功提交，系统客服将尽快与您联系！", "申请成功", {
              showCancelButton: false,
              confirmButtonText: "知道了",
              type: "success"
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;