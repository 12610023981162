"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _collapseTransition = _interopRequireDefault(require("./collapseTransition"));
var _timeFormatter = require("@/utils/time-formatter");
var _hightlight = require("@/utils/hightlight");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeConvCardContent',
  components: {
    collapseTransition: _collapseTransition.default
  },
  props: {
    contentList: {
      type: Array,
      default: () => []
    },
    convItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isExpand: false
    };
  },
  computed: {
    firstContent() {
      return this.contentList && this.contentList[0];
    },
    openContent() {
      return this.contentList && this.contentList.slice(1);
    }
  },
  methods: {
    timeStampToDate(time) {
      return this.$moment(time * 1000).format('YYYY-MM-DD HH:mm');
    },
    goConvDetail(val) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.convItem.conversation_id}&order=${val.order}&noback=true`);
    },
    secondToTime: _timeFormatter.secondToTime,
    handleHighLight: _hightlight.handleHighLight,
    getHightlightContent(contents) {
      const text = (0, _hightlight.handleHighLight)(contents);
      const {
        pattern
      } = contents;
      const reg = new RegExp(pattern, 'g');
      const res = pattern ? text.replace(reg, `<span class='keyword-high-light'>${pattern}</span>`) : text;
      return res;
    }
  }
};
exports.default = _default;