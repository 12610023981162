"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "800px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("header", {
          staticClass: "header-title-data"
        }, [_c("span", {
          staticClass: "data-item"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v("序号")]), _c("span", [_vm._v(_vm._s(_setup.currentIndex + 1))])]), _c("span", {
          staticClass: "data-item"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v("可信度")]), _c("span", [_vm._v(_vm._s(_setup.context.confidence === -1 ? "-" : _setup.context.confidence))])]), _c("span", {
          staticClass: "data-item"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v("会话来源")]), _c(_setup.ConversationLink, {
          attrs: {
            id: _setup.context.conversation_id,
            width: "400px",
            type: _setup.context.conversation_type,
            name: _setup.context.conversation_name,
            path: _setup.getAnnotatePath()
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c("div", {
    ref: "trainDataDetailRef",
    staticClass: "train-data-detail"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.contentLoading,
      expression: "contentLoading"
    }],
    staticClass: "detail-content"
  }, [_c("el-checkbox-group", {
    attrs: {
      value: _setup.checkList
    },
    on: {
      input: _setup.handleCheckListInput,
      change: _setup.handleAnnotateChange
    }
  }, _vm._l(_setup.context.sentence, function (sentence, index) {
    return _c("div", {
      key: index,
      staticClass: "sentence"
    }, [_c("el-tooltip", {
      attrs: {
        content: "至少选择一个句子",
        disabled: _setup.checkList.length > 1 || !_setup.checkList.includes(index)
      }
    }, [_vm.canAnnotate ? _c("el-checkbox", {
      attrs: {
        disabled: _setup.checkList.length === 1 && _setup.checkList.includes(index),
        label: index
      }
    }, [_c("br")]) : _vm._e()], 1), _c("div", [sentence.entity_type === "host_salesman" ? _c("span", {
      key: "sale",
      staticClass: "speaker is-sale"
    }, [_vm._v(" 销售：")]) : _c("span", {
      key: "customer",
      staticClass: "speaker"
    }, [_vm._v("客户：")]), _c("span", {
      ref: "sentenceTextRef",
      refInFor: true,
      staticClass: "sentence-text",
      class: {
        active: sentence.is_similar_sentence
      }
    }, [_vm._v(_vm._s(sentence.content))])])], 1);
  }), 0)], 1), _c("div", {
    staticClass: "operate-buttons"
  }, [_setup.hasPre ? _c("MgvButton", {
    staticClass: "guide-btn",
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-back"
    },
    on: {
      click: _setup.pre
    }
  }) : _vm._e(), _c("div", {
    staticClass: "button-group"
  }, [_c("MgvButton", {
    class: ["hit-btn", {
      active: _setup.context.status === 1
    }],
    attrs: {
      size: "medium",
      color: "info",
      type: "plain",
      loading: _setup.btnLoading
    },
    on: {
      click: function ($event) {
        return _setup.updateTrainDataStatus(1);
      }
    }
  }, [_vm._v("命中")]), _c("MgvButton", {
    class: ["un-hit-btn", {
      active: _setup.context.status === 2
    }],
    attrs: {
      size: "medium",
      color: "info",
      type: "plain",
      loading: _setup.btnLoading
    },
    on: {
      click: function ($event) {
        return _setup.updateTrainDataStatus(2);
      }
    }
  }, [_vm._v("未命中")]), _c("MgvButton", {
    class: ["uncertain-btn", {
      active: _setup.context.status === 3
    }],
    attrs: {
      size: "medium",
      color: "info",
      type: "plain",
      loading: _setup.btnLoading
    },
    on: {
      click: function ($event) {
        return _setup.updateTrainDataStatus(3);
      }
    }
  }, [_vm._v("不确定")]), _c("MgvButton", {
    attrs: {
      size: "medium",
      color: "danger",
      type: "text",
      loading: _setup.btnLoading
    },
    on: {
      click: _setup.deleteAnnotation
    }
  }, [_vm._v("删除")])], 1), _setup.hasNext ? _c("MgvButton", {
    staticClass: "guide-btn",
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-right"
    },
    on: {
      click: _setup.next
    }
  }) : _vm._e()], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;