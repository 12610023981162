"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _nanoid = require("nanoid");
var _default = {
  __name: 'FieldItems',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    getKeyPlaceHolder: {
      type: [Function, String],
      default: () => ''
    },
    getValuePlaceHolder: {
      type: [Function, String],
      default: () => ''
    },
    needValidate: {
      type: Boolean,
      default: false
    },
    keyProps: {
      type: Object,
      default: () => ({
        maxlength: 30
      })
    },
    valueProps: {
      type: Object,
      default: () => ({
        maxlength: 300,
        rows: 1
      })
    },
    maxItemsLength: {
      type: Number,
      default: 100
    },
    itemLabel: {
      type: String,
      default: '字段'
    }
  },
  emits: ['fieldValueChange', 'update:value'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const fieldItems = (0, _vue.computed)({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('update:value', newValue);
      }
    });
    const getKeyPlaceHolderValue = item => {
      let keyPlaceHolder = '';
      if (typeof props.getKeyPlaceHolder === 'function') {
        keyPlaceHolder = props.getKeyPlaceHolder(item) || `请输入${props.itemLabel}名称`;
      } else {
        keyPlaceHolder = props.getKeyPlaceHolder || `请输入${props.itemLabel}名称`;
      }
      return keyPlaceHolder;
    };
    const getValuePlaceHolderValue = item => {
      let valuePlaceHolder = '';
      if (typeof props.getValuePlaceHolder === 'function') {
        valuePlaceHolder = props.getValuePlaceHolder(item) || '请输入内容';
      } else {
        valuePlaceHolder = props.getValuePlaceHolder || '请输入内容';
      }
      return valuePlaceHolder;
    };

    // const currentField = ref('');
    // const handleFocus = (e) => {
    //   currentField.value = e.target.value;
    // };
    // const handleBlur = (e, index) => {
    //   if (!e.target.value) {
    //     fieldItems.value[index].key = currentField.value;
    //   }
    // };

    function deleteField(index) {
      fieldItems.value.splice(index, 1);
    }
    function fieldValueChange() {
      emit('fieldValueChange', false);
    }
    const fieldNameInput = (0, _vue.ref)(null);
    function addNewFieldItem() {
      fieldItems.value.push({
        id: (0, _nanoid.nanoid)(),
        key: '',
        value: ''
      });
      (0, _vue.nextTick)(() => {
        var _fieldNameInput$value;
        (_fieldNameInput$value = fieldNameInput.value[fieldItems.value.length - 1]) === null || _fieldNameInput$value === void 0 ? void 0 : _fieldNameInput$value.select();
      });
    }
    const checkKeyIsSame = (key, index) => {
      return fieldItems.value.findIndex(item => item.key === key) !== index;
    };
    const validateIsOK = (0, _vue.ref)(true);
    // 判断数组中key或者value有任意为空都校验不通过
    const checkValidate = () => {
      const validateHasEmpty = fieldItems.value.some(item => {
        return item.key === '' || item.value === '';
      });
      // 如果需要校验key值不能相同,还需判断key值有没有重复，并且把重复的靠后的字段名报红
      const validateHasSameKey = fieldItems.value.some((item, index) => {
        return fieldItems.value.findIndex(item2 => item.key === item2.key) !== index;
      });
      validateIsOK.value = !validateHasEmpty && !validateHasSameKey;
      return validateIsOK.value;
    };

    // 向外暴露方法
    expose({
      checkValidate
    });
    return {
      __sfc: true,
      emit,
      props,
      fieldItems,
      getKeyPlaceHolderValue,
      getValuePlaceHolderValue,
      deleteField,
      fieldValueChange,
      fieldNameInput,
      addNewFieldItem,
      checkKeyIsSame,
      validateIsOK,
      checkValidate
    };
  }
};
exports.default = _default;