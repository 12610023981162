"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ChatGuide',
  props: {
    guideList: {
      type: Array,
      default: () => []
    },
    guideTips: {
      type: String,
      default: ''
    },
    helloText: {
      type: String,
      default: 'Hi～我是AI助手麦麦，你可以问我：'
    }
  },
  methods: {
    handeClick(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;