"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CardDetailMarkLine',
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      }
    }
  }
};
exports.default = _default;