"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-container"
  }, [_c("el-card", {
    staticClass: "card"
  }, [_c("header", {
    staticClass: "card-title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm.tagValue ? _c("h1", [_vm._v(_vm._s(_vm.labelName))]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.eventName || _vm.tagValue || _vm.labelName))])]), _vm.eventTime ? _c("div", {
    staticClass: "date"
  }, [_c("span", [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.eventTime).format("YYYY-MM-DD HH:mm")))])]) : _vm._e()]), _c("ul", _vm._l(_vm.eventSentences, function (tracker, idx) {
    return _c("li", {
      key: idx,
      staticClass: "event-item"
    }, _vm._l(tracker.data, function (item, index) {
      return _c("div", {
        key: index,
        class: {
          "account-label-border": _vm.activeTabName === "account_label"
        }
      }, [_c("div", {
        staticClass: "card-content"
      }, [_c("div", {
        staticClass: "card-sub-title"
      }, [_c("span", {
        staticClass: "name"
      }, [_vm._v(_vm._s(_vm.getEntityName(item.entity_id, item.entity_type)))])]), _c("div", {
        staticClass: "main-body"
      }, [_c("div", {
        staticClass: "sentences",
        on: {
          click: function ($event) {
            return _vm.goToKeyEventDetial(idx);
          }
        }
      }, [_c("div", {
        class: ["time-box", {
          "is-customer": item.entity_type === "customer_contact"
        }]
      }, [_c("div", {
        staticClass: "time-point"
      }), _c("div", {
        class: {
          "time-stamp": _vm.cardContentText.conversation_type === "doc"
        }
      }, [_vm._v(" " + _vm._s(_vm.cardContentText.conversation_type !== "doc" ? _vm.secondToTime(item.begin_time) : _vm.$moment(item.begin_time * 1000).format("YYYY-MM-DD HH:mm")) + " ")])]), _vm._l(_vm.handleContent(item, tracker.style_type), function (obj, index) {
        return [!obj.isKeyword ? _c("span", {
          key: index,
          attrs: {
            "data-sign": item.order
          }
        }, [_vm._v(" " + _vm._s(obj.word) + " ")]) : _c("span", {
          key: index,
          staticClass: "highlight highlightItem"
        }, [_c("span", {
          domProps: {
            innerHTML: _vm._s(obj.word)
          }
        })])];
      })], 2)])])]);
    }), 0);
  }), 0)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;