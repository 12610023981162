"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-input", _vm._g({
    ref: "input",
    attrs: {
      maxlength: _vm.localMaxlength,
      disabled: _vm.disabled,
      placeholder: `请输入${_vm.label}`
    },
    on: {
      input: _vm.handleChange
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleKeypress.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "newValue"
    }
  }, _vm.$listeners)) : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, [_vm._v(_vm._s(_vm.formatNewValue))]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;