"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UploadMediaDialogHead = _interopRequireDefault(require("@/components/UploadMediaDialog/UploadMediaDialogHead.vue"));
var _UploadMedia = _interopRequireDefault(require("./UploadMedia.vue"));
var _UploadConversationForm = _interopRequireDefault(require("./UploadConversationForm.vue"));
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'UploadConversationDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: 'update:dialogVisible',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const audioUploadLimit = (0, _vue.computed)(() => {
      var _store$getters$org_co;
      return ((_store$getters$org_co = store.getters.org_config) === null || _store$getters$org_co === void 0 ? void 0 : _store$getters$org_co.audio_upload_limit) / Math.pow(1024, 2) || 500;
    });
    const handleClose = () => {
      emit('update:dialogVisible', false);
    };
    const uploadBtnDisabled = (0, _vue.ref)(false);
    const canUpload = can_upload => {
      uploadBtnDisabled.value = can_upload;
    };
    const formatFormData = convBaseFormData => {
      const {
        begin_time
      } = convBaseFormData;
      const beginTime = (0, _timezone.currentZoneToPlus8)(begin_time).format('YYYY-MM-DD HH:mm');
      return {
        ...convBaseFormData,
        begin_time: beginTime,
        workspace_id: store.getters.workspaceInfo.id
      };
    };
    const uploadConvFormRef = (0, _vue.ref)(null);
    const uploadMediaRef = (0, _vue.ref)(null);
    const startUpload = () => {
      var _fileList;
      let fileList = uploadMediaRef.value.fileList;
      const uploadConvFormValid = uploadConvFormRef.value.uploadFormValid();
      if (!uploadConvFormValid) return;
      if (!((_fileList = fileList) !== null && _fileList !== void 0 && _fileList.length)) return;
      const convBaseFormData = uploadConvFormRef.value.form;
      const convDynamicFormData = uploadConvFormRef.value.conversationDynamicForm;
      fileList.forEach(item => {
        item.baseFormData = formatFormData(convBaseFormData);
        item.custom_field = convDynamicFormData;
        item.state = 'waiting';
      });
      // 文件存到全局上文件列表中
      fileList = (0, _vue.ref)(fileList);
      store.commit('upload/SET_GLOBAL_UPLOAD_FILE_LIST', fileList.value);
      handleClose();
    };
    return {
      __sfc: true,
      store,
      props,
      audioUploadLimit,
      emit,
      handleClose,
      uploadBtnDisabled,
      canUpload,
      formatFormData,
      uploadConvFormRef,
      uploadMediaRef,
      startUpload,
      UploadMediaDialogHead: _UploadMediaDialogHead.default,
      UploadMedia: _UploadMedia.default,
      UploadConversationForm: _UploadConversationForm.default
    };
  }
};
exports.default = _default;