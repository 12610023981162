"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BipLayout = _interopRequireDefault(require("./BipLayout.vue"));
var _LineChart = _interopRequireDefault(require("./LineChart"));
var _StatisticsCard = _interopRequireDefault(require("./StatisticsCard.vue"));
var _AsideList = _interopRequireDefault(require("./AsideList.vue"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    BipLayout: _BipLayout.default,
    StatisticsCard: _StatisticsCard.default,
    LineChart: _LineChart.default,
    AsideList: _AsideList.default,
    SortCaret: _sortCaret.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    personName: {
      type: String,
      default: ''
    },
    biTab: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      conversationList: [],
      chartData: {},
      loading: false,
      convLoading: false,
      sortOrder: 'descending'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    chartParams() {
      return {
        ...this.filters,
        workspace_id: this.workspaceInfo.id,
        user_id: Number(this.$route.query.id)
      };
    },
    noChartData() {
      return Object.values(this.chartDesc).some(item => JSON.stringify(item.value) !== '{}');
    },
    conversationParams() {
      return {
        workspace_id: this.workspaceInfo.id,
        ...this.chartParams,
        ...this.convParams,
        ...this.topicConvParams
      };
    }
  },
  watch: {
    convParams: {
      handler() {
        this.getConversationListEachTab();
      },
      deep: true
    },
    topicConvParams: {
      handler() {
        this.getBiTopicConversation();
      },
      deep: true
    },
    chartParams: {
      handler: async function (val) {
        if (!(val !== null && val !== void 0 && val.begin_date)) return;
        await this.getChartData();
        if (!this.topicConvParams || !this.topicConvParams.topic_name) {
          this.getConversationListEachTab();
        } else {
          // 如果是话题页面，用单独的接口
          this.getBiTopicConversation();
        }
      },
      deep: true
    }
  },
  methods: {
    getPersonConversationActivity: _businessIntelligence.getPersonConversationActivity,
    getPersonConversationDimension: _businessIntelligence.getPersonConversationDimension,
    getPersonKeyEvent: _businessIntelligence.getPersonKeyEvent,
    getPersonTopicDuration: _businessIntelligence.getPersonTopicDuration,
    getPersonTopicTime: _businessIntelligence.getPersonTopicTime,
    getConversationListEachTab() {
      if (this.biTab !== 'BIPersonalKeyEvent' || this.asideListType === 'conversation') {
        this.getBiConversation();
      } else {
        this.getBiDeal();
      }
    },
    async getBiConversation() {
      var _this$conversationPar, _this$conversationPar2;
      this.convLoading = true;
      // 拦截处理api/bi/conversation/list接口type参数
      this.conversationParams.type = this.conversationParams.analysis_basis || this.conversationParams.entity_type;
      this.conversationParams.conversation_type = (_this$conversationPar = this.conversationParams.conversation_type) === null || _this$conversationPar === void 0 ? void 0 : _this$conversationPar.join(',');
      this.conversationParams.conversation_scene_type = (_this$conversationPar2 = this.conversationParams.conversation_scene_type) === null || _this$conversationPar2 === void 0 ? void 0 : _this$conversationPar2.join(',');
      delete this.conversationParams.analysis_basis;
      delete this.conversationParams.entity_type;
      const res = await (0, _businessIntelligence.getBiConversation)(this.conversationParams);
      this.convLoading = false;
      this.conversationList = res.results.data;
    },
    // NOTE 获取右侧商机列表
    async getBiDeal() {
      this.convLoading = true;
      const res = await (0, _businessIntelligence.getBiDeal)(this.conversationParams);
      this.convLoading = false;
      this.conversationList = res.results.data;
    },
    async getBiTopicConversation() {
      // 如果是话题页面，用单独的接口
      this.convLoading = true;
      const res = await (0, _businessIntelligence.getBiTopicConversation)(this.conversationParams);
      this.convLoading = false;
      this.conversationList = res.results;
    },
    async getChartData() {
      this.loading = true;
      let conversationType = JSON.parse(sessionStorage.getItem('bi_activity_conversation_type') || '[]');
      const conversationSceneType = JSON.parse(sessionStorage.getItem('bi_activity_conversation_scene_type') || '[]');
      const hasDoc = sessionStorage.getItem('bi_activity_conversation_type_has_doc');
      switch (this.$options.name) {
        case 'ConversationDimension':
          // 如果conversationType中包括doc,则把doc去掉
          conversationType = conversationType.filter(type => type !== 'doc');
          this.chartParams.conversation_type = conversationType;
          if (this.chartParams.entity_type === 'all') {
            this.chartParams.entity_type = 'conversation';
          }
          break;
        case 'ConversationActivity':
          // 如果跳之前会话活动有doc，去到会话维度后再回活动需要把doc加回来
          if (hasDoc === 'true' && !conversationType.includes('doc')) {
            conversationType.push('doc');
          }
          this.chartParams.conversation_type = conversationType;
          this.chartParams.conversation_scene_type = conversationSceneType;
          if (this.chartParams.entity_type === 'all') {
            this.chartParams.entity_type = 'conversation';
          }
          break;
        case 'KeyEvent':
          delete this.chartParams.entity_type;
          delete this.chartParams.tree_id;
          break;
        default:
          break;
      }
      const chartParams = (0, _commonFunc.deepClone)(this.chartParams);
      (0, _timezone.convertParams2UTCplus8)(chartParams.custom_filters);
      chartParams.time_zone = this.timezone;
      const res = await this[`getPerson${this.$options.name}`](chartParams);
      this.loading = false;
      this.chartData = res.results;
      this.handleResults();
    },
    getCardArr(val, attr) {
      const type = this.getDataType(attr);
      switch (type) {
        case 'time':
          return (0, _timeFormatter.secondToCNTimeArr)(val);
        case 'percent':
          return [Math.round(val), '%'];
        default:
          return [Math.round(val)];
      }
    },
    getDataType(attr) {
      switch (attr) {
        case 'question':
          return 'countPerhour';
        case 'discuss':
        case 'sum_count':
          return 'count';
        case 'percent_speak':
          return 'percent';
        case 'max_sales':
        case 'max_customer':
        case 'sum_duration':
        case 'avg_duration':
          return 'time';
      }
    },
    handleSortChange(val) {
      this.convParams.sort_order = val;
    }
  }
};
exports.default = _default;