"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _TricksLibraryFilter = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksLibraryFilter.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _TricksLibraryConvCard = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksLibraryConvCard.vue"));
var _CreateKnowledgeDrawer = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/CreateKnowledgeDrawer.vue"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'QuestionTricksLibrary',
  components: {
    TricksLibraryFilter: _TricksLibraryFilter.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    TricksLibraryConvCard: _TricksLibraryConvCard.default,
    CreateKnowledgeDrawer: _CreateKnowledgeDrawer.default,
    SortCaret: _sortCaret.default
  },
  props: {
    currentQuestion: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      noMore: false,
      qaList: [],
      addedList: [],
      drawerVisible: false,
      qaIds: [],
      layout: [{
        type: 'tree_user_ids',
        isPreventNotSelected: false
      }, {
        type: 'date',
        tooltip: '会话时间',
        noDefaultDate: true
      }],
      hasFilters: false,
      queryParams: {
        page: 1,
        size: 10,
        time_sort: ''
      }
    };
  },
  computed: {
    showAddBtn() {
      return this.qaList.some(i => i.checked);
    },
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    currentQuestion(val) {
      if (!Object.keys(val).length) return;
      this.freshList();
    }
  },
  methods: {
    sortDate(val) {
      const map = {
        ascending: 'asc',
        descending: 'des'
      };
      this.queryParams.time_sort = map[val];
      this.freshList();
    },
    async getQaList() {
      this.loading = true;
      try {
        const res = await (0, _knowledgeLibrary.getKnowledgeQaList)({
          ...this.queryParams,
          space_id: this.workspaceInfo.id
        }).finally(() => {
          this.loading = false;
        });
        if (res.code === 20000) {
          const {
            has_more,
            cases
          } = res.results;
          if (cases.length) {
            const addedList = cases.filter(i => i.is_add);
            this.addedList.push(...addedList);
            const list = cases.map(i => ({
              ...i,
              checked: false
            }));
            this.qaList.push(...list);
          }
          this.noMore = !has_more;
          this.queryParams.page++;
        }
      } catch (error) {
        this.noMore = true;
      }
    },
    handleFiltersChange(val) {
      this.hasFilters = true;
      this.queryParams = {
        ...this.queryParams,
        ...val,
        date: JSON.parse(val.date || '[]'),
        q_id: this.currentQuestion.id
      };
      if (!val.status) {
        delete this.queryParams.status;
      }
      this.freshList();
    },
    freshList() {
      this.initData();
      this.getQaList();
    },
    handleCheckCard(checked, id) {
      if (!checked) return;
      const checkedList = this.qaList.filter(i => i.checked);
      if (checkedList.length > 10) {
        this.$message.warning({
          message: '最多只能选择10项',
          center: true
        });
        this.qaList.forEach(i => {
          if (i.id === id) {
            i.checked = false;
          }
        });
      }
    },
    addSingleQa(index) {
      const item = this.qaList[index];
      const checkedList = [{
        ...item,
        checked: true
      }];
      this.backfillDrawerData(checkedList);
    },
    closeLibrary() {
      this.$emit('closeLibrary');
    },
    initData() {
      this.noMore = false;
      this.queryParams.page = 1;
      this.queryParams.q_id = this.currentQuestion.id;
      this.qaList = [];
      this.addedList = [];
    },
    addMultipleQas() {
      const checkedList = this.qaList.filter(i => i.checked);
      this.backfillDrawerData(checkedList);
    },
    getKnowledgeConfig(list) {
      const qaList = list.map(i => i.replys).flat();
      const questions = qaList.filter(i => i.type === 'question').map(i => i.content);
      const replys = list.map(i => i.replys.filter(i => i.type === 'answer').reduce((a, b) => a + ' ' + b.content, '')).map(i => ({
        reply_id: 0,
        reply_content: i
      }));
      const replyList = [{
        scene_id: 0,
        scene_content: '',
        reply_list: replys
      }];
      return {
        name: '',
        description: '',
        position: 0,
        skill_list: [''],
        question_list: questions,
        scene_reply_list: replyList
      };
    },
    handleCreateKnowledgeSuccess(_ref) {
      let {
        name
      } = _ref;
      this.qaList = this.qaList.map(i => ({
        ...i,
        checked: false
      }));
      this.qaList.forEach(item => {
        if (this.qaIds.includes(item.qa_id)) {
          item.knowledge_names.push(name);
        }
      });
      setTimeout(() => {
        // 后端数据更新有延时，所以延迟一点时间再重新请求数据
        this.$emit('refreshQuestionList');
      }, 500);
    },
    backfillDrawerData(checkedList) {
      const config = this.getKnowledgeConfig(checkedList);
      this.qaIds = checkedList.map(_ref2 => {
        let {
          qa_id
        } = _ref2;
        return qa_id;
      });
      this.drawerVisible = true;
      this.$refs.drawer.backfillConfig(config);
    }
  }
};
exports.default = _default;