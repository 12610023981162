"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "confirmation-dialog",
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "480px"
    }
  }, [_c("div", {
    staticClass: "confirm-container"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.nameHasVar", [_vm.formType])))]), _c("el-input", {
    ref: "nameInput",
    class: ["validate-error", {
      show: _vm.isValidate && !_vm.value.length
    }],
    attrs: {
      placeholder: _vm.$t("coaching.pleaseEnterContent"),
      maxlength: "50"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c("span", {
    staticClass: "validate-error-message"
  }, [_vm._v(_vm._s(_vm.$t("coaching.cannotBeEmpty")))])], 1), _vm._t("default"), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.confirm")))])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;