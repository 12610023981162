"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'BipLayout',
  props: {
    personName: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    keyArr() {
      return Object.keys(this.data);
    }
  },
  methods: {
    handleClick(key) {
      const index = this.keyArr.indexOf(key);
      this.$nextTick(() => {
        this.$refs.chartContainer.scroll({
          top: index * 380,
          behavior: 'smooth'
        });
      });
    }
  }
};
exports.default = _default;