"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "create-task"
  }, [_c("div", {
    staticClass: "create-task-container",
    class: _vm.isCreateTask ? "unfold" : "fold"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCreateTask,
      expression: "!isCreateTask"
    }],
    staticClass: "create-task-button",
    on: {
      click: _vm.createTask
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" "), _c("span", [_vm._v("创建任务")])]), _c("create-task-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isCreateTask,
      expression: "isCreateTask"
    }],
    ref: "createTaskForm",
    on: {
      cancelCreate: function ($event) {
        _vm.isCreateTask = false;
      },
      creatNewTask: function ($event) {
        return _vm.$emit("creatNewTask");
      }
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;