"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "smart-traning-edit"
  }, [_c("header", [_c("div", {
    staticClass: "left-part"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _vm.confirmGoBack
    }
  }), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t(_vm.isEdit ? "coaching.editCourseScriptPractice" : "coaching.createCourseScriptPractice")))]), _c("CourseTips", {
    attrs: {
      description: _vm.courseDescription
    }
  }, [_c("video", {
    ref: "tipVideo",
    staticClass: "tip-gif",
    attrs: {
      autoplay: "",
      loop: "",
      muted: "",
      controls: ""
    },
    domProps: {
      muted: true
    }
  }, [_c("source", {
    attrs: {
      src: _vm.videoSrc,
      type: "video/mp4"
    }
  })])])], 1), _c("div", {
    staticClass: "center"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEditTitle,
      expression: "!isEditTitle"
    }],
    staticClass: "title"
  }, [_c("MgAutoTooltip", {
    attrs: {
      content: _vm.title
    }
  }), _c("el-button", {
    staticClass: "hover-show-bg-btn",
    attrs: {
      icon: "iconfont icon-edit-outline"
    },
    on: {
      click: _vm.editTitle
    }
  })], 1), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditTitle,
      expression: "isEditTitle"
    }],
    ref: "title-input",
    attrs: {
      placeholder: _vm.$t("coaching.courseNamePlaceholder"),
      maxlength: "50"
    },
    on: {
      change: _vm.modifyTitle,
      blur: function ($event) {
        _vm.isEditTitle = false;
      }
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.isEditTitle = false;
      }
    },
    model: {
      value: _vm.title,
      callback: function ($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1), _c("div", {
    staticClass: "right-part"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "medium",
      disabled: _vm.autoCompleteLoading
    },
    on: {
      click: function ($event) {
        return _vm.startAutoCreateScoreRule(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.autoCompletePoints")) + " ")]), _c("el-button", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/webapi/training/knowledge/list"],
      expression: "['/webapi/training/knowledge/list']"
    }],
    attrs: {
      size: "medium"
    },
    on: {
      click: function ($event) {
        _vm.aiGeneratingCourseDrawerVisible = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.aiGeneratedCourse")) + " ")]), _c("span", {
    staticClass: "line"
  }), _vm._l(_vm.operations, function (_ref) {
    let {
      icon,
      tip,
      operate
    } = _ref;
    return _c("el-tooltip", {
      key: operate,
      attrs: {
        content: _vm.$t(tip)
      }
    }, [_c("el-button", {
      staticClass: "hover-show-bg-btn",
      attrs: {
        icon: icon
      },
      on: {
        click: function ($event) {
          return _vm.openDrawer(operate);
        }
      }
    })], 1);
  }), _c("el-tooltip", {
    attrs: {
      content: _vm.$t("coaching.dialogueTest")
    }
  }, [_c("el-button", {
    staticClass: "hover-show-bg-btn",
    attrs: {
      "data-v-step": "smartTrainingTest",
      icon: "iconfont icon-play-line"
    },
    on: {
      click: function ($event) {
        return _vm.openDrawer("trainingTestDrawerVisible");
      }
    }
  })], 1)], 2)]), _c("article", [_c("DirectedGraph", {
    ref: "directed-graph",
    on: {
      submitGraph: _vm.submitGraph
    }
  }), _c("div", {
    staticClass: "upper-right-corner-operation"
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.submitLoading,
      expression: "submitLoading"
    }],
    staticClass: "el-icon-loading"
  }), _c("el-button", {
    staticClass: "save-template-btn",
    attrs: {
      "data-v-step": "saveTemplateBtn",
      icon: "iconfont icon-template"
    },
    on: {
      click: function ($event) {
        _vm.templateDialogVisible = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.saveAsTemplate")))])], 1), _c("ShortcutKeysDrawer", {
    attrs: {
      id: "shortcut-keys-drawer",
      "drawer-visible": _vm.shortcutKeysDrawerVisible
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.shortcutKeysDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.shortcutKeysDrawerVisible = $event;
      }
    }
  }), _c("TrainingTestDrawer", {
    attrs: {
      id: "training-test-drawer",
      "test-content": _vm.testContent,
      "practice-rule": _vm.practiceRule,
      "drawer-visible": _vm.trainingTestDrawerVisible
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.trainingTestDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.trainingTestDrawerVisible = $event;
      },
      highLightRoleNode: _vm.highLightRoleNode
    }
  }), _c("SettingDrawer", {
    attrs: {
      id: "setting-drawer",
      "practice-rule": _vm.practiceRule,
      "drawer-visible": _vm.settingDrawerVisible
    },
    on: {
      "update:practiceRule": function ($event) {
        _vm.practiceRule = $event;
      },
      "update:practice-rule": function ($event) {
        _vm.practiceRule = $event;
      },
      "update:drawerVisible": function ($event) {
        _vm.settingDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.settingDrawerVisible = $event;
      }
    }
  }), _c("ImportDrawer", {
    ref: "import-drawer",
    attrs: {
      id: "import-drawer",
      "drawer-visible": _vm.importDrawerVisible
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.importDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.importDrawerVisible = $event;
      },
      importData: _vm.importData
    }
  })], 1), _c("EditTemplate", {
    attrs: {
      "dialog-visible": _vm.templateDialogVisible,
      "graph-id": _vm.graphId
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.templateDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.templateDialogVisible = $event;
      }
    }
  }), _c("AiGeneratingCourseDrawer", {
    attrs: {
      visible: _vm.aiGeneratingCourseDrawerVisible,
      "directed-graph": _vm.$refs["directed-graph"]
    },
    on: {
      "update:visible": function ($event) {
        _vm.aiGeneratingCourseDrawerVisible = $event;
      },
      importData: _vm.importData
    }
  }), _c("MgvTour", {
    ref: "smartTrainingGuide",
    staticClass: "smart-training-tour",
    attrs: {
      name: "isShowSmartTrainingGuide",
      steps: _vm.steps,
      callbacks: _vm.myCallbacks
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref2) {
        let {
          tour
        } = _ref2;
        return [_vm._l(_vm.steps, function (item, index) {
          return [index === tour.currentStep ? _c("div", {
            key: index + "content",
            staticClass: "content-container"
          }, [_c("img", {
            attrs: {
              src: item.src
            }
          }), _c("div", [_vm._v(_vm._s(`${index + 1}/${_vm.steps.length} ${item.content}`))])]) : _vm._e()];
        })];
      }
    }])
  }), _c("ConfirmDialog", {
    attrs: {
      visible: _vm.confirmDialogVisible,
      count: _vm.noScoreNodeCards.length,
      loading: _vm.autoCompleteLoading
    },
    on: {
      startCreate: _vm.startAutoCreateScoreRule,
      close: _vm.closeConfirmDialog,
      goBack: _vm.goBack
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;