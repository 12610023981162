"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _OptionTemplate = _interopRequireDefault(require("./OptionTemplate.vue"));
var _CoachingTasksContent = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/CoachingTasksContent.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SelectKnowledgesDrawer',
  components: {
    CoachingTasksContent: _CoachingTasksContent.default,
    OptionTemplate: _OptionTemplate.default
  },
  props: {
    convId: {
      type: String,
      default: ''
    },
    selectedKnowledges: {
      type: Array,
      default: () => []
    },
    knowledgesDrawerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      recommendOptions: [],
      knowledgeArr: [],
      currSelectedKnowledges: [],
      query: '',
      isScroll: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curorder', 'knowledgeBaseInfo']),
    showRecommend() {
      return !this.query;
    },
    currOptions() {
      return this.showRecommend ? this.recommendOptions.map(item => item.options).flat() : this.options;
    }
  },
  watch: {
    knowledgesDrawerVisible(val) {
      if (val) {
        this.currSelectedKnowledges = JSON.parse(JSON.stringify(this.selectedKnowledges));
        this.query = '';
      }
    },
    selectedKnowledges(val) {
      this.handleMissingOptions(val);
      this.isScroll = false;
      this.knowledgeArr = val.map(item => item.id);
      this.commitKnowledgeId(this.knowledgeArr);
    },
    knowledgeArr: (0, _commonFunc.debounce)(function (newVal, oldVal) {
      if (!newVal.length || newVal.length < oldVal.length || !this.knowledgesDrawerVisible || !this.isScroll) {
        return;
      }
      const knowledge = this.currOptions.find(item => item.id === newVal[newVal.length - 1]);
      this.$refs.content.handleSearch(knowledge);
    }),
    'knowledgeBaseInfo.id': {
      // 切换知识库
      handler: function (val) {
        if (val) {
          this.getRecommendKnowledges();
        }
      }
    }
  },
  methods: {
    async getRecommendKnowledges() {
      const params = {
        conversation_id: Number(this.convId),
        begin_time: this.curorder.beginTime,
        base_id: this.knowledgeBaseInfo.id
      };
      const res = await (0, _videoDetail.getRecommendKnowledges)(params);
      if (res.code === 20000) {
        this.recommendOptions = this.formatRecommendList(res.results);
        const options = this.recommendOptions.map(item => item.options).flat();
        if (!this.showRecommend || !options.length) return;
        setTimeout(() => {
          this.expandSelect();
        }, 500);
      }
    },
    searchKnowledges: (0, _commonFunc.debounce)(async function (name) {
      this.query = name;
      const res = await (0, _videoDetail.searchKnowledges)({
        name,
        scroll_id: '',
        base_id: this.knowledgeBaseInfo.id
      });
      if (res.code === 20000) {
        this.options = res.results.knowledge_list;
      }
    }),
    addKnowledges() {
      const list = this.$refs.content.$refs.tricksSelect.selectedList;
      this.commitSelectedKnowledges(list);
      if (list.length > 10) {
        this.$message.warning('最多选择10条知识点');
        return;
      }
      this.closeDrawer();
    },
    cancel() {
      this.$emit('setSelectedKnowledges', this.currSelectedKnowledges);
      this.closeDrawer();
    },
    commitKnowledgeId(val) {
      this.$store.commit('video/SET_SELECTED_KNOWLEDGE_IDS', val);
      this.commitId2KnowledgeMap(val);
    },
    commitSelectedKnowledges(val) {
      this.$emit('setSelectedKnowledges', val);
    },
    commitId2KnowledgeMap(val) {
      const map = new Map();
      const allOptions = this.getAllOptions();
      val.forEach(id => {
        const val = allOptions.find(item => item.id === id);
        if (val) {
          map.set(id, val);
        }
      });
      this.$store.commit('video/SET_ID_TO_KNOWLEDGE_MAP', map);
    },
    closeDrawer() {
      this.$emit('update:knowledgesDrawerVisible', false);
    },
    getAllOptions() {
      const recommends = this.recommendOptions.map(item => item.options).flat();
      return [...recommends, ...this.options];
    },
    handleChange(val) {
      this.isScroll = true;
      this.commitKnowledgeId(val);
    },
    handleMissingOptions(val) {
      const missingList = val.filter(item => !this.currOptions.find(option => option.id === item.id));
      if (!missingList.length) return;
      if (this.showRecommend) {
        this.recommendOptions[0].options.push(...missingList);
      } else {
        this.options.push(...missingList);
      }
    },
    expandSelect() {
      this.$refs.select.focus();
    },
    formatRecommendList(val) {
      const {
        used,
        recommend
      } = val;
      const usedGroup = {
        label: '最近选择',
        options: used
      };
      const recommendGroup = {
        label: '推荐知识点',
        options: recommend
      };
      return [usedGroup, recommendGroup];
    }
  }
};
exports.default = _default;