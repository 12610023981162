"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "password-input"
  }, [_c("el-input", {
    class: {
      error: _vm.error
    },
    attrs: {
      value: _vm.value,
      placeholder: _vm.placeholder,
      type: _vm.flagType,
      tabindex: _vm.tabindex
    },
    on: {
      input: _vm.handleInput,
      blur: _vm.validValue
    }
  }, [_c("i", {
    class: _vm.flag ? "iconfont icon-view-close" : "iconfont icon-view",
    attrs: {
      slot: "suffix"
    },
    on: {
      click: _vm.getFlag
    },
    slot: "suffix"
  })]), _c("div", {
    staticClass: "password-verify-tips"
  }, _vm._l(_vm.tips, function (tip, name, index) {
    return _c("div", {
      key: name,
      staticClass: "tip"
    }, [_c("span", {
      style: {
        color: tip.color,
        fontSize: "12px"
      }
    }, [_vm._v(_vm._s(tip.text))]), index !== 3 ? _c("span", {
      staticClass: "dot"
    }, [_vm._v("·")]) : _vm._e()]);
  }), 0)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;