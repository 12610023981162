"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'SpaceSelect',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceList']),
    selectedItem() {
      return this.workspaceList.find(i => i.id === this.value);
    }
  }
};
exports.default = _default;