"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "container"
  }, [_c("el-collapse", {
    model: {
      value: _setup.activeScriptItems,
      callback: function ($$v) {
        _setup.activeScriptItems = $$v;
      },
      expression: "activeScriptItems"
    }
  }, _vm._l(_vm.scriptList, function (item, index) {
    var _item$sales, _item$sales2;
    return _c("el-collapse-item", {
      key: index,
      staticClass: "knowledge-collapse-container",
      attrs: {
        name: `${item.id}`
      }
    }, [item.hasOwnProperty("not_triggered") ? [_c("template", {
      slot: "title"
    }, [_c("i", {
      staticClass: "el-icon-caret-right"
    }), item.not_triggered ? _c("span", {
      staticClass: "not-triggered"
    }, [_vm._v(_vm._s(_setup.t("coaching.notTriggered")))]) : _vm._e(), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        content: item.customer.reply,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "question bold"
    }, [_vm._v(_vm._s(item.customer.reply))])]), _c("div", {
      class: ["score", item.sales.score < 60 ? "red" : "green"]
    }, [_c("span", [_vm._v(" " + _vm._s(item.sales.score) + "分"), _c("i", {
      staticClass: "iconfont icon-ai-assistant-fill"
    })])])], 1), _c("div", {
      staticClass: "content"
    }, [_c("p", {
      staticClass: "label"
    }, [_vm._v(_vm._s(_setup.t("coaching.standardScript")) + "：")]), _c("p", [_vm._v(" " + _vm._s(item.sales.content) + " ")]), (_item$sales = item.sales) !== null && _item$sales !== void 0 && _item$sales.reply ? [_c("p", {
      staticClass: "label"
    }, [_vm._v(_vm._s(_setup.t("coaching.salesResponse")) + "：")]), _c("p", [_vm._v(" " + _vm._s(item.sales.reply) + " ")])] : _vm._e(), (_item$sales2 = item.sales) !== null && _item$sales2 !== void 0 && _item$sales2.comment ? [_c("p", {
      staticClass: "label"
    }, [_vm._v(_vm._s(_setup.t("coaching.intelligentReview")) + ":")]), _c("p", {
      domProps: {
        innerHTML: _vm._s(item.sales.comment)
      }
    })] : _vm._e()], 2)] : [_c("div", [_vm._v("正在点评中...")])]], 2);
  }), 1), _c(_setup.NoData, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.scriptList.length,
      expression: "!scriptList.length"
    }],
    attrs: {
      icon: "icon-a-Property1no-data",
      size: "120",
      tips: _setup.t("coaching.noKeyPhrasesAvailable")
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;