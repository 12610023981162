"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'AnnotateResultsBatchBtn',
  props: {
    tableSelectionList: {
      type: Array,
      default: () => []
    },
    tooltipContent: {
      type: String,
      default: ''
    }
  },
  emits: ['updateAnnotate', 'deleteAnnotation'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const statusOptions = [{
      value: 1,
      label: '命中'
    }, {
      value: 2,
      label: '未命中'
    }, {
      value: 3,
      label: '不确定'
    }];
    const updateAnnotate = status => {
      emit('updateAnnotate', status);
    };
    const deleteAnnotation = () => {
      emit('deleteAnnotation');
    };
    return {
      __sfc: true,
      props,
      statusOptions,
      emit,
      updateAnnotate,
      deleteAnnotation
    };
  }
};
exports.default = _default;