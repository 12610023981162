"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-detail"
  }, [_c("detail-header", {
    attrs: {
      title: _vm.tradeInfo.trade_name,
      "back-button": _vm.backButton
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function () {
        return [_vm.tradeInfo.workspace ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "所属空间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "deal-workspace"
        }, [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: _vm.tradeInfo.workspace.icon_color,
            "margin-right": "4px"
          }
        }), _c("span", [_vm._v(_vm._s(_vm.tradeInfo.workspace.name))])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header_left_bottom",
      fn: function () {
        return [_c("div", {
          staticClass: "top-center"
        }, [_c("progress-tags", {
          attrs: {
            "progress-obj": _vm.progressObj
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_c("div", {
          staticClass: "right-btn"
        }, [_c("el-button", {
          staticClass: "mg-button hover-show-bg",
          attrs: {
            plain: ""
          },
          on: {
            click: _vm.openDealDrawer
          }
        }, [_vm._v("查看详情")])], 1)];
      },
      proxy: true
    }])
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "main-part"
  }, [_c("div", {
    staticClass: "calendar-directory",
    class: {
      "calender-fold": _vm.isCalenderFold
    }
  }, [_c("busi-calender-directoty", {
    attrs: {
      "is-calender-fold": _vm.isCalenderFold,
      "deal-object": _vm.tradeInfo,
      "expand-persons": ""
    },
    on: {
      "update:isCalenderFold": function ($event) {
        _vm.isCalenderFold = $event;
      },
      "update:is-calender-fold": function ($event) {
        _vm.isCalenderFold = $event;
      }
    }
  })], 1), _c("div", {
    staticClass: "flex-container",
    class: {
      "fold-height": _vm.isCalenderFold
    }
  }, [_c("div", {
    staticClass: "timeline-container"
  }, [_vm.timelineArray && _vm.timelineArray.length > 0 ? [_c("div", {
    staticClass: "left-list"
  }, [_c("items-flow", {
    attrs: {
      "timeline-array": _vm.timelineArray,
      "customer-name": _vm.tradeInfo.account_name
    }
  })], 1), _c("div", {
    staticClass: "center-detail"
  }, [_c("item-detail", {
    attrs: {
      "customer-name": _vm.tradeInfo.account_name,
      "detail-obj": _vm.getActiveDetail
    }
  })], 1)] : _vm._e(), !_vm.timelineArray || _vm.timelineArray && _vm.timelineArray.length === 0 ? _c("div", {
    staticClass: "no-content"
  }, [_c("p", {
    staticClass: "no-text"
  }, [_vm._v("暂无会话")])]) : _vm._e()], 2)])]), _c("trade-detail-drawer", {
    attrs: {
      visible: _vm.dealDrawerVisible,
      "trade-id": _vm.tradeId,
      "form-desc": _vm.formDesc,
      "trade-info": _vm.tradeInfo,
      "is-stage-readonly": true
    },
    on: {
      closeDrawer: function ($event) {
        _vm.dealDrawerVisible = false;
      }
    }
  }), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible,
      type: "deal"
    },
    on: {
      closeDialog: _vm.closeMeetingDialog
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;