"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$data;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "task-detail-table"
  }, [(_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.length ? _c("el-table", {
    staticClass: "task-detail-table__inner",
    attrs: {
      data: _vm.data,
      height: "calc(100vh - 264px)",
      "default-sort": {
        prop: "plan_start_time",
        order: "descending"
      }
    },
    on: {
      "sort-change": _setup.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "plan_name",
      fixed: "left",
      "min-width": "240",
      "show-overflow-tooltip": "",
      label: "任务名称"
    }
  }), _vm._l(_setup.headers, function (header) {
    return _c("el-table-column", {
      key: header.prop,
      attrs: {
        prop: header.prop,
        "min-width": header.minWidth || 160,
        label: header.label,
        "show-overflow-tooltip": header.isShowOverflowTooltip,
        "sort-orders": ["descending", "ascending"],
        sortable: header.sortable
      },
      scopedSlots: _vm._u([header.prop === "user_info" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("MgvAvatarName", {
            attrs: {
              avatar: row.user_info.avatar,
              name: row.user_info.name
            }
          }), _c("span", {
            staticClass: "name-span"
          }, [_vm._v(_vm._s(row.user_info.name))])];
        }
      } : ["practice_times", "score"].includes(header.prop) ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("span", [_vm._v(_vm._s(row[header.prop] === -1 ? "-" : row[header.prop]))])];
        }
      } : header.prop === "is_complete" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row.is_complete ? _c("MgvTag", {
            attrs: {
              size: 28,
              type: "success"
            }
          }, [_vm._v("已完成")]) : _c("MgvTag", {
            attrs: {
              size: 28
            }
          }, [_vm._v("未完成")])];
        }
      } : header.prop === "category" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("span", [_vm._v(_vm._s(row.category.join(", ") || "-"))])];
        }
      } : header.prop === "course_type" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("span", [_vm._v(_vm._s(_setup.courseType.getLabel(row.course_type)))])];
        }
      } : ["plan_start_time", "user_start_time"].includes(header.prop) ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_c("span", [_vm._v(_vm._s(row[header.prop] ? _setup.plus8ToCurrentZone(row[header.prop]).format("YYYY-MM-DD HH:mm:ss") : "-"))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right",
      width: "68",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("MgvButton", {
          attrs: {
            type: "text",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _setup.openDetailDrawer(row);
            }
          }
        }, [_vm._v("详情")])];
      }
    }], null, false, 2454301654)
  })], 2) : _c(_setup.NoData), _c(_setup.TaskResultDetailDrawer, {
    attrs: {
      visible: _setup.TaskResultDetailDrawerVisible,
      "current-task-info": _setup.currentTaskInfo,
      "task-id": _setup.currentTaskId
    },
    on: {
      "update:visible": function ($event) {
        _setup.TaskResultDetailDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;