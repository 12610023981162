import { render, staticRenderFns } from "./ChooseFolderSelect.vue?vue&type=template&id=07c03b12&"
import script from "./ChooseFolderSelect.vue?vue&type=script&lang=js&"
export * from "./ChooseFolderSelect.vue?vue&type=script&lang=js&"
import style0 from "./ChooseFolderSelect.vue?vue&type=style&index=0&id=07c03b12&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07c03b12')) {
      api.createRecord('07c03b12', component.options)
    } else {
      api.reload('07c03b12', component.options)
    }
    module.hot.accept("./ChooseFolderSelect.vue?vue&type=template&id=07c03b12&", function () {
      api.rerender('07c03b12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/knowledge/snippetsLibrary/components/ChooseFolderSelect.vue"
export default component.exports