"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _ConversationDetailInfo = _interopRequireDefault(require("./ConversationDetailInfo"));
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Overview',
  components: {
    eventsList: _eventsList.default,
    ConversationDetailInfo: _ConversationDetailInfo.default
  },
  props: {
    detailResult: {
      type: Object,
      default: function () {
        return null;
      }
    },
    apiProcess: {
      type: Object,
      default: function () {
        return null;
      }
    },
    dimensionInfo: {
      type: Array,
      default: function () {
        return null;
      }
    },
    analyOverView: {
      type: Object,
      default: function () {
        return null;
      }
    },
    eventEngine: {
      type: Array,
      default: function () {
        return [];
      }
    },
    evaluateArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    eventEngineVersion: {
      type: String,
      default: null
    },
    noEvents: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infoArray: null,
      openDelay: 400,
      candSpeakPercent: '',
      orderText: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五'],
      // navTitles: ["会话信息", "会话评价", "分析概览"],
      activeNav: 0,
      isShowNav: false,
      infoTop: 0,
      evalTop: 0,
      anayTop: 0,
      biComplete: '',
      eventEngineComplete: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['modulePermission']),
    conversationAssociationInfoPermission() {
      return this.modulePermission[this.detailResult.type];
    },
    handleAvatar() {
      return function (name) {
        if (this.isChiStr(name)) {
          return name.substring(name.length - 2);
        } else {
          return name.substring(0, 4);
        }
      };
    },
    getIsPassedText() {
      return function (status) {
        if (status === 'passed') {
          return '已通过';
        } else if (status === 'denied') {
          return '未通过';
        } else {
          return '未选择';
        }
      };
    },
    getEvalTime() {
      return function (time) {
        return this.$moment(time).format('YYYY-MM-DD HH:mm');
      };
    }
  },
  watch: {
    apiProcess: {
      handler: function (value) {
        if (value.is_bi_complete) {
          this.biComplete = true;
        } else {
          this.biComplete = false;
        }
        if (value.is_event_engine_complete) {
          this.eventEngineComplete = true;
        } else {
          this.eventEngineComplete = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openEvalDrawer() {
      this.$emit('openEvalDrawer', true);
    },
    goKeyQa() {
      this.$emit('openKeyQa');
    },
    goMarkItem(markType, count) {
      if (!count) {
        return false;
      }
      const mark = markType || null;
      this.$emit('openMark', mark);
    },
    secondToTime(second) {
      const h = Math.floor(second / 3600);
      const m = Math.floor(second / 60 % 60);
      const s = Math.floor(second % 60);
      if (!second) {
        return 0;
      }
      if (h > 0) {
        return h + ' 小时 ' + m + ' 分 ' + s + ' 秒';
      } else if (m > 0) {
        return m + ' 分 ' + s + ' 秒';
      } else {
        return Math.floor(second) + ' 秒';
      }
    },
    formateDate(date) {
      return this.$moment(date).format('YYYY年M月D日');
    },
    getRoundText(number) {
      const num = number || 1;
      return `第${this.orderText[num - 1]}次`;
    },
    isChiStr(str) {
      const pattern = new RegExp('[\u4E00-\u9FA5]+');
      if (pattern.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    // 锚点跳转
    goAnchor(selector, index) {
      this.activeNav = index;
      const height = this.$refs[selector].offsetTop;
      document.querySelector('#pane-overview').scroll({
        top: height,
        // 向上移动的距离，如果有fixede布局， 直接减去对应距离即可
        behavior: 'smooth' // 平滑移动
      });
    }
  }
};

exports.default = _default;