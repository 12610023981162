import { render, staticRenderFns } from "./DialogueItemNarration.vue?vue&type=template&id=3a533740&scoped=true&"
import script from "./DialogueItemNarration.vue?vue&type=script&setup=true&lang=js&"
export * from "./DialogueItemNarration.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./DialogueItemNarration.vue?vue&type=style&index=0&id=3a533740&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a533740",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a533740')) {
      api.createRecord('3a533740', component.options)
    } else {
      api.reload('3a533740', component.options)
    }
    module.hot.accept("./DialogueItemNarration.vue?vue&type=template&id=3a533740&scoped=true&", function () {
      api.rerender('3a533740', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coach/coachTask/components/DialogueItemNarration.vue"
export default component.exports