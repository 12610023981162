"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _library = require("@/api/library");
var _client = require("@/api/client");
// 根据当前时间获取order
function getOrderFromCurTime(curTime, initArray, mediaType) {
  if (mediaType === 'doc') {
    var _initArray$find;
    return initArray === null || initArray === void 0 ? void 0 : (_initArray$find = initArray.find(item => curTime === item.begin_time)) === null || _initArray$find === void 0 ? void 0 : _initArray$find.order;
  } else {
    var _initArray$find2;
    return initArray === null || initArray === void 0 ? void 0 : (_initArray$find2 = initArray.find(item => curTime >= item.begin_time && curTime < item.end_time)) === null || _initArray$find2 === void 0 ? void 0 : _initArray$find2.order;
  }
}
// 根据当前时间获取到order 视频剪辑和文字剪辑联动需要
function getOrderFromOriginCurTime(_ref, initArray) {
  var _beginSentence, _endSentence;
  let {
    beginTime,
    endTime
  } = _ref;
  let beginSentence;
  let endSentence;
  for (let i = 0; i < initArray.length; i++) {
    const item = initArray[i];
    if (item.end_time >= beginTime) {
      beginSentence = item;
      break;
    }
  }
  for (let i = initArray.length - 1; i >= 0; i--) {
    const item = initArray[i];
    if (item.begin_time <= endTime) {
      endSentence = item;
      break;
    }
  }
  return [(_beginSentence = beginSentence) === null || _beginSentence === void 0 ? void 0 : _beginSentence.order, (_endSentence = endSentence) === null || _endSentence === void 0 ? void 0 : _endSentence.order];
}
// 根据当前order排序获取剪辑的开始时间和结束时间
function getTimeFromOrderSort(orderList, initArray) {
  const res = initArray.filter(item => orderList.includes(item.order)).sort((a, b) => a.order - b.order);
  const snippetBeginTime = res[0].begin_time;
  const snippetEndTime = res.length < 2 ? res[0].end_time : res[1].end_time;
  return [snippetBeginTime, snippetEndTime];
}
// 根据当前时间和结束时间获取到进度条百分比
function getSliderRangeFromSnippetTimePercent(snippetTime, videoMaxTime) {
  const [snippetBeginTime, snippetEndTime] = [...snippetTime];
  const beginTimePercent = snippetBeginTime / videoMaxTime * 100;
  const endTimePercent = snippetEndTime / videoMaxTime * 100;
  return [beginTimePercent, endTimePercent];
}
// 根据当前order获取当前order的结束时间
function getEndTimeFromOrder(order, initArray) {
  // 根据当前时间获取当前order
  let time = 0;
  if (Array.isArray(initArray)) {
    initArray.forEach(item => {
      if (item.order === order) {
        time = item.end_time;
      }
      if (order === initArray.length - 1) {
        // 播放结束时，再次点击时文字需要从头开始显示
        time = 0;
      }
    });
  }
  return time;
}
/**
 * 处理空白时间短问题
 * 上一段结束时间、作为下一段视频开始时间
 *  */
function handleData(data, hasEntityId) {
  let i;
  const audioList = data.array;
  const contactNameObj = data.contacts;
  const salesInfoObj = data.sales_persons;
  const salesRoles = ['host_salesman', 'assistant_salesman', 'spectator_salesman'];
  for (i = 0; i < audioList.length; i++) {
    if (hasEntityId) {
      var _audioList;
      let name = '';
      let avatar = '';
      if (salesRoles.includes(audioList[i].entity_type)) {
        // 判断如果是销售人员
        for (const user in salesInfoObj) {
          if (user == audioList[i].entity_id) {
            name = salesInfoObj[user].name;
            avatar = salesInfoObj[user].avatar;
            audioList[i].name = name;
            audioList[i].avatar = avatar;
          }
        }
      } else {
        // 是客户联系人
        audioList[i].name = contactNameObj[audioList[i].entity_id];
        audioList[i].avatar = '';
      }
      if (audioList[i].end_time < ((_audioList = audioList[i + 1]) === null || _audioList === void 0 ? void 0 : _audioList.begin_time)) {
        audioList[i].end_time = audioList[i + 1].begin_time;
      }
    } else {
      for (i = 0; i < audioList.length; i++) {
        if (i < audioList.length - 1) {
          audioList[i + 1].begin_time = audioList[i].end_time;
        }
      }
    }
  }
  setIsShowAvatar(audioList);
  return audioList;
}
function setIsShowAvatar() {
  let sentence = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (!sentence.length) return;
  sentence[0].isShowAvatar = true;
  for (let i = 1; i < sentence.length; i++) {
    sentence[i].isShowAvatar = sentence[i].entity_id !== sentence[i - 1].entity_id || sentence[i].entity_type !== sentence[i - 1].entity_type;
  }
}
// eslint修复删除无用代码-李朋
// import {
//   cosh
// } from 'core-js/fn/number'
const state = {
  begin_time: {
    value: 0,
    time: 0
  },
  current_time: 0,
  order_end_time: 0,
  cur_order: {
    value: 0,
    time: 0,
    beginTime: 0
  },
  cur_conv_id: '',
  cur_media_type: '',
  audio_all: null,
  audio_all_origin: null,
  max_silence_duration: {},
  hang_up: 0,
  isEsInsertComplete: false,
  point_obj: null,
  tab_name: 'my',
  is_user_scroll: false,
  dialogTextScrollable: true,
  // 转录文字是否可滚动（当点击添加至剪辑库时暂停一下滚动）
  is_video_pause: true,
  guideVisiable: '',
  top: '',
  finishLeft: '',
  finishTopLeft: '',
  conversationTitleInfo: {
    convName: '',
    convTime: '',
    convDuration: ''
  },
  isChoose: false,
  defaultItem: {
    order: 0,
    event: null
  },
  commentStatus: 'default',
  keyEventName: {
    name: '',
    isBackButton: false
  },
  // 剪辑视频的起止时间
  snippetVideoTime: {
    beginTime: '',
    endTime: ''
  },
  // 视频剪辑绑定的v-model的值
  sliderTimeRange: [0, 100],
  // 文字剪辑v-model绑定的order数组
  snippetCheckList: [],
  // 视频的时间
  videoMaxTime: '',
  // 保存剪辑时传递给后端的参数
  saveSnippetVideoTime: {
    beginTime: '',
    endTime: ''
  },
  selectedKnowledgeIds: [],
  id2KnowledgeMap: new Map(),
  player: null,
  buffTime: 0,
  video: {
    currentTime: 0,
    maxTime: 0,
    playing: false,
    muted: false,
    speed: 1,
    waiting: true,
    preload: 'auto'
  },
  transTextLoading: false,
  has_video: false,
  // 评论选中的句子
  selectedSentenceOfComment: {},
  // 点击评论按钮，右侧展开聚焦
  clickCommentBtnTimes: 0,
  // 当前操作是剪辑还是添加关键事件命中
  currentOperateName: '',
  // 会话详情页面是否显示关键事件 tag 标签
  isShowEventTag: false
};
const mutations = {
  SET_IS_SHOW_EVENT_TAG: (state, isShowEventTag) => {
    state.isShowEventTag = isShowEventTag;
  },
  SET_CURRENT_OPERATE_NAME: (state, operateName) => {
    state.currentOperateName = operateName;
  },
  // 点击评论按钮次数
  SET_CLICK_COMMENT_BTN_TIMES: function (state) {
    let isSetZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (isSetZero) {
      state.clickCommentBtnTimes = 0;
    } else {
      state.clickCommentBtnTimes++;
    }
  },
  // 评论选中的句子
  SET_SELECTED_SENTENCE_OF_COMMENT: (state, sentence) => {
    state.selectedSentenceOfComment = sentence;
  },
  SET_HAS_VIDEO: (state, has_video) => {
    state.has_video = has_video;
  },
  SET_VIDEO: (state, video) => {
    state.video = video;
  },
  SET_PLAYER: (state, player) => {
    state.player = player;
  },
  SET_BUFF_TIME: (state, buffTime) => {
    state.buffTime = buffTime;
  },
  SET_SNIPPET_VIDEO_TIME: (state, _ref2) => {
    let {
      beginTime,
      endTime
    } = _ref2;
    state.snippetVideoTime.beginTime = beginTime;
    state.snippetVideoTime.endTime = endTime;
    const orderList = getOrderFromOriginCurTime({
      beginTime,
      endTime
    }, state.audio_all);
    state.snippetCheckList = orderList;
    const snippetTime = getTimeFromOrderSort(state.snippetCheckList, state.audio_all);
    state.saveSnippetVideoTime = {
      beginTime: snippetTime[0],
      endTime: snippetTime[1] === 0 ? state.videoMaxTime : snippetTime[1]
    };
  },
  SET_SNIPPET_SLIDER_RANGE: (state, range) => {
    state.sliderTimeRange = range;
  },
  SET_SNIPPET_CHECK_LIST: (state, checkList) => {
    state.snippetCheckList = checkList;
    if (state.snippetCheckList.length === 2) {
      const res = getTimeFromOrderSort(state.snippetCheckList, state.audio_all);
      const range = getSliderRangeFromSnippetTimePercent(res, state.videoMaxTime);
      state.saveSnippetVideoTime = {
        beginTime: res[0],
        endTime: res[1]
      };
      state.sliderTimeRange = range;
    }
  },
  SET_VIDEO_MAX_TIME: (state, maxTime) => {
    state.videoMaxTime = maxTime;
  },
  CHANGE_BEGIN: (state, val) => {
    state.begin_time = {
      value: val.begin_time,
      time: val.time
    };
  },
  CHANGE_CURRENT: (state, current_time) => {
    state.current_time = current_time;
  },
  SET_ORDER_END_TIME: (state, order_end_time) => {
    state.order_end_time = order_end_time;
  },
  SET_CUR_CONV_ID: (state, cur_conv_id) => {
    state.cur_conv_id = cur_conv_id;
  },
  SET_AUDIO_ALL: (state, audio_all) => {
    state.audio_all = audio_all;
    state.audio_all_origin = audio_all;
  },
  SET_MAX_SILENCE_DURATION: (state, max_silence_duration) => {
    state.max_silence_duration = max_silence_duration;
  },
  SET_IS_ES_INSERT_COMPLETE: (state, is_es_insert_complete) => {
    state.isEsInsertComplete = is_es_insert_complete;
  },
  SET_HANG_UP: (state, hang_up) => {
    state.hang_up = hang_up;
  },
  RESTORE_AUDIO_ALL: (state, params) => {
    const {
      changeId,
      order,
      beginPos,
      origin_word,
      word
    } = params;
    const arr = state.audio_all[order];

    // 将content的变更为修改前的内容
    const beginStr = arr.content.substring(0, beginPos);
    const endStr = arr.content.substring(beginPos + word.length);
    arr.content = beginStr + origin_word + endStr;
    for (let i = 0; i < arr.changes.length; i++) {
      if (arr.changes[i].change_id === changeId) {
        // 修正word_begin_pos
        const LEN = origin_word.length - word.length;
        for (let j = 0; j < i; j++) {
          arr.changes[j].change_begin_pos += LEN;
        }
        arr.changes.splice(i, 1);
        break;
      }
    }
  },
  REPLACE_AUDIO_ALL: (state, _ref3) => {
    let {
      arr,
      is_customize
    } = _ref3;
    state.audio_all = [].concat(state.audio_all_origin);
    for (const item of arr) {
      const {
        keywords,
        order
      } = item;
      state.audio_all[order] = {
        ...state.audio_all[order],
        keywords,
        is_customize
      };
    }
  },
  SET_CUR_ORDER: (state, val) => {
    state.cur_order = {
      value: val.order,
      time: val.time,
      beginTime: val.beginTime
    };
  },
  SET_CUR_MEDIA_TYPE: (state, type) => {
    state.cur_media_type = type;
  },
  SET_POINT_OBJ: (state, point_obj) => {
    state.point_obj = point_obj;
  },
  SET_TAB_NAME: (state, tab_name) => {
    state.tab_name = tab_name;
  },
  SET_IS_USER_SCROLL: (state, is_user_scroll) => {
    state.is_user_scroll = is_user_scroll;
  },
  SET_DIALOG_TEXT_SCROLLABLE: (state, val) => {
    state.dialogTextScrollable = val;
  },
  SET_IS_VIDEO_PAUSE: (state, is_video_pause) => {
    state.is_video_pause = is_video_pause;
  },
  SER_GUIDE_VISIABLE: (state, val) => {
    state.guideVisiable = val;
  },
  SER_GUIDE_VISIABLE_TOP: (state, val) => {
    state.top = val;
  },
  SER_GUIDE_VISIABLE_LEFT: (state, val) => {
    state.finishLeft = val;
  },
  SER_GUIDE_VISIABLE_TOP_LEFT: (state, val) => {
    state.finishTopLeft = val;
  },
  // 获取会话的名称
  SET_CONVERSATION_INFO: (state, val) => {
    state.conversationTitleInfo = {
      convName: val.convName,
      convTime: val.convTime,
      convDuration: val.convDuration
    };
  },
  // 是否选择语句
  SET_CHOOSE: (state, val) => {
    state.isChoose = val;
  },
  SET_EVENT: (state, val) => {
    state.defaultItem = {
      order: val.order,
      event: val.event,
      type: val.type
    };
  },
  SET_COMMENT_STATUS: (state, val) => {
    state.commentStatus = val;
  },
  SET_SELECTED_KNOWLEDGE_IDS(state, val) {
    state.selectedKnowledgeIds = val;
  },
  SET_ID_TO_KNOWLEDGE_MAP(state, val) {
    state.id2KnowledgeMap = val;
  },
  SET_TRANS_TEXT_LOADING(state, val) {
    state.transTextLoading = val;
  }
};
const actions = {
  change_begin(_ref4, _ref5) {
    let {
      commit
    } = _ref4;
    let {
      begin_time,
      time
    } = _ref5;
    commit('CHANGE_BEGIN', {
      begin_time: begin_time,
      time: time
    });
    commit('SET_IS_USER_SCROLL', false);
  },
  change_current(_ref6, _ref7) {
    let {
      commit
    } = _ref6;
    let {
      current_time
    } = _ref7;
    commit('CHANGE_CURRENT', current_time);
  },
  set_cur_conv_id(_ref8, _ref9) {
    let {
      commit
    } = _ref8;
    let {
      cur_conv_id
    } = _ref9;
    return new Promise((resolve, reject) => {
      commit('SET_CUR_CONV_ID', cur_conv_id);
      resolve();
    });
  },
  // 获取所有的音频信息
  set_audio_all(_ref10, _ref11) {
    let {
      state,
      commit,
      rootState
    } = _ref10;
    let {
      cur_conv_id,
      type,
      has_workspace,
      has_entity_id
    } = _ref11;
    return new Promise((resolve, reject) => {
      if (type === 'conversation') {
        const params = {
          conversation_id: cur_conv_id
        };
        if (has_workspace) {
          params.workspace_id = rootState.workspace.workspaceInfo.id;
        }
        state.transTextLoading = true;
        (0, _videoDetail.getAudioList)(params).then(res => {
          const data = handleData(res.results, has_entity_id);
          const maxSilenceDuration = res.results.silence;
          const hangUp = res.results.hang_up;
          const isEsInsertComplete = res.results.is_es_insert_complete;
          commit('SET_HANG_UP', hangUp);
          commit('SET_MAX_SILENCE_DURATION', maxSilenceDuration);
          commit('SET_AUDIO_ALL', data);
          commit('SET_IS_ES_INSERT_COMPLETE', isEsInsertComplete);
          // 初始化数据
          commit('SET_CUR_ORDER', {
            order: 0,
            time: 0,
            beginTime: 0
          });
          commit('SET_ORDER_END_TIME', 0);
          resolve(data);
        }).catch(error => {
          reject(error);
        }).finally(() => {
          state.transTextLoading = false;
        });
      } else if (type === 'snippet') {
        (0, _library.getSnippetSentences)(cur_conv_id).then(res => {
          const data = handleData(res.results, has_entity_id);
          const isEsInsertComplete = res.results.is_es_insert_complete;
          commit('SET_AUDIO_ALL', data);
          commit('SET_IS_ES_INSERT_COMPLETE', isEsInsertComplete);
          // 初始化数据
          commit('SET_CUR_ORDER', {
            order: 0,
            time: 0,
            beginTime: 0
          });
          commit('SET_ORDER_END_TIME', 0);
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      } else if (type === 'customer') {
        (0, _client.getConvSentences)(cur_conv_id).then(res => {
          const data = handleData(res.results, has_entity_id);
          const isEsInsertComplete = res.results.is_es_insert_complete;
          commit('SET_AUDIO_ALL', data);
          commit('SET_IS_ES_INSERT_COMPLETE', isEsInsertComplete);
          // 初始化数据
          commit('SET_CUR_ORDER', {
            order: 0,
            time: 0,
            beginTime: 0
          });
          commit('SET_ORDER_END_TIME', 0);
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      }
    });
  },
  restore_audio_all(_ref12, params) {
    let {
      commit
    } = _ref12;
    commit('RESTORE_AUDIO_ALL', params);
  },
  replace_audio_all(_ref13, _ref14) {
    let {
      commit
    } = _ref13;
    let {
      arr,
      is_customize
    } = _ref14;
    commit('REPLACE_AUDIO_ALL', {
      arr,
      is_customize
    });
  },
  get_cur_order(_ref15, _ref16) {
    let {
      commit,
      state
    } = _ref15;
    let {
      current_time,
      time
    } = _ref16;
    const order = getOrderFromCurTime(current_time, state.audio_all, state.cur_media_type);
    if (!order && time === 0) {
      return;
    }
    if (!state.isChoose) {
      commit('SET_CUR_ORDER', {
        order: order,
        time: time,
        beginTime: state.cur_order.beginTime
      });
    }
    const end_time = getEndTimeFromOrder(order, state.audio_all);
    commit('SET_ORDER_END_TIME', end_time);
  },
  set_order_end_time(_ref17, _ref18) {
    let {
      commit
    } = _ref17;
    let {
      end_time
    } = _ref18;
    commit('SET_ORDER_END_TIME', end_time);
  },
  set_cur_order(_ref19, val) {
    let {
      commit,
      state
    } = _ref19;
    const end_time = getEndTimeFromOrder(val.order, state.audio_all);
    commit('SET_CUR_ORDER', val);
    commit('SET_ORDER_END_TIME', end_time);
  },
  set_point_obj(_ref20, _ref21) {
    let {
      commit
    } = _ref20;
    let {
      point_obj
    } = _ref21;
    commit('SET_POINT_OBJ', point_obj);
  },
  set_tab_name(_ref22, _ref23) {
    let {
      commit
    } = _ref22;
    let {
      tab_name
    } = _ref23;
    commit('SET_TAB_NAME', tab_name);
  },
  set_guide_visiable(_ref24, val) {
    let {
      commit
    } = _ref24;
    commit('SER_GUIDE_VISIABLE', val);
  },
  set_guide_visiable_top(_ref25, val) {
    let {
      commit
    } = _ref25;
    commit('SER_GUIDE_VISIABLE_TOP', val);
  },
  set_guide_visiable_left(_ref26, val) {
    let {
      commit
    } = _ref26;
    commit('SER_GUIDE_VISIABLE_LEFT', val);
  },
  set_guide_visiable_top_left(_ref27, val) {
    let {
      commit
    } = _ref27;
    commit('SER_GUIDE_VISIABLE_TOP_LEFT', val);
  },
  set_conversation_info(_ref28, val) {
    let {
      commit
    } = _ref28;
    commit('SET_CONVERSATION_INFO', val);
  },
  set_choose(_ref29, val) {
    let {
      commit
    } = _ref29;
    commit('SET_CHOOSE', val);
  },
  set_event(_ref30, val) {
    let {
      commit
    } = _ref30;
    commit('SET_EVENT', val);
  },
  set_comment_status(_ref31, val) {
    let {
      commit
    } = _ref31;
    commit('SET_COMMENT_STATUS', val);
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;