"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AvatarImage = _interopRequireDefault(require("./leftElements/AvatarImage.vue"));
var _AvatarIcon = _interopRequireDefault(require("./leftElements/AvatarIcon.vue"));
var _CardButton = _interopRequireDefault(require("./CardButton.vue"));
var _CardHeader = _interopRequireDefault(require("./CardHeader.vue"));
var _SourceTag = _interopRequireDefault(require("./SourceTag.vue"));
var _ReportDataTable = _interopRequireDefault(require("./overViewElements/ReportDataTable.vue"));
var _ReportDataChart = _interopRequireDefault(require("./overViewElements/ReportDataChart"));
var _TaskCenterCard = _interopRequireDefault(require("./overViewElements/TaskCenterCard"));
var _ConversationContext = _interopRequireDefault(require("./overViewElements/ConversationContext.vue"));
var _LinkTags = _interopRequireDefault(require("./overViewElements/LinkTags.vue"));
var _TextJoint = _interopRequireDefault(require("../TextJoint.vue"));
var _routeMap = require("@/router/routeMap");
var _message = require("@/api/message");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'StructuringCardTemplate',
  components: {
    AvatarImage: _AvatarImage.default,
    AvatarIcon: _AvatarIcon.default,
    CardButton: _CardButton.default,
    CardHeader: _CardHeader.default,
    SourceTag: _SourceTag.default,
    ReportDataTable: _ReportDataTable.default,
    ReportDataChart: _ReportDataChart.default,
    TaskCenterCard: _TaskCenterCard.default,
    ConversationContext: _ConversationContext.default,
    LinkTags: _LinkTags.default,
    TextJoint: _TextJoint.default
  },
  props: {
    templateInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceList']),
    layout() {
      return this.templateInfo.layout;
    },
    HasMainLink() {
      var _this$templateInfo, _this$templateInfo$co, _this$templateInfo$co2;
      return (_this$templateInfo = this.templateInfo) === null || _this$templateInfo === void 0 ? void 0 : (_this$templateInfo$co = _this$templateInfo.config) === null || _this$templateInfo$co === void 0 ? void 0 : (_this$templateInfo$co2 = _this$templateInfo$co.main_link) === null || _this$templateInfo$co2 === void 0 ? void 0 : _this$templateInfo$co2.router_name;
    },
    hasContent() {
      return this.layout.card_overview.length || this.layout.card_source.length || this.layout.card_space.length || this.layout.operate_btns.length;
    }
  },
  methods: {
    cardBtnOperate(routerInfo) {
      // 如果卡片是未读状态触发已读请求
      this.setReadMessage();
      if (!routerInfo.router_name) return;
      switch (routerInfo.router_name) {
        case 'assist':
          this.goAssist(routerInfo);
          break;
        case 'briefReportDetail':
          this.$emit('goBriefReportDetail', this.templateInfo.card_id, this.templateInfo.message_type, this.templateInfo.message_sub_type);
          break;
        case 'taskList':
        case 'taskCompleted':
          // this.goTaskCenterDetail(routerInfo);
          break;
        case 'convRecycleBin':
          this.goConvRecycleBin(routerInfo);
          break;
        default:
          this.goDetail(routerInfo.router_name, routerInfo.router_params);
          break;
      }
    },
    goConvRecycleBin(routerInfo) {
      // 去会话回收站
      const {
        workspace_id
      } = routerInfo.router_params;
      const hasWorkspace = this.workspaceList.find(item => item.id === workspace_id);
      if (hasWorkspace) {
        this.goDetail(routerInfo.router_name, routerInfo.router_params);
      } else {
        this.$message.warning('无访问权限，请联系管理员');
      }
    },
    goTaskCenterDetail(routerInfo) {
      // if (this.templateInfo.message_type === 4) {
      //   this.goDetail(routerInfo.router_name, routerInfo.router_params);
      // } else {
      // 任务打开任务中心-任务详情
      const taskInfo = {
        id: routerInfo.router_params.id,
        task_type: routerInfo.router_params.task_type
      };
      this.$bus.$emit('closeMessageCenter');
      setTimeout(() => {
        this.$bus.$emit('openTaskCenter', taskInfo);
      }, 500);
      // }
    },

    goAssist(routerInfo) {
      // 打开助手
      const todoInfo = {
        id: routerInfo.router_params.id
      };
      this.$bus.$emit('closeMessageCenter');
      setTimeout(() => {
        this.$bus.$emit('openAssist', todoInfo);
      }, 500);
    },
    goDetail(routeType, routeParams) {
      const config = this.templateInfo.config;
      if (!config.is_access) {
        this.$message.warning('无访问权限，请联系管理员');
        return;
      }
      const path = _routeMap.ROUTES[routeType];
      const query = Object.assign({
        from: 'message_center'
      }, routeParams);
      const routeData = this.$router.resolve({
        path,
        query
      });
      window.open(routeData.href, '_blank');
    },
    isEmptyObj(obj) {
      return Object.keys(obj).length === 0;
    },
    async setReadMessage() {
      if (this.templateInfo.config.is_read) return;
      const {
        card_id
      } = this.templateInfo;
      const data = {
        card_id
      };
      const res = await (0, _message.setReadMessage)(data);
      if (res.code === 20000) {
        // eslint-disable-next-line vue/no-mutating-props
        this.templateInfo.config.is_read = true;
        this.$emit('updateUnreadCount');
      }
    }
  }
};
exports.default = _default;