"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorLog = errorLog;
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function errorLog() {
  let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'error';
  let data = arguments.length > 1 ? arguments[1] : undefined;
  return _axios.default.get('/clog.log', {
    params: {
      type,
      data
    }
  });
}