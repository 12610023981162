"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-detail"
  }, [_vm.dealData.close_deal && _vm.dealData.close_deal.length || _vm.dealData.open_deal && _vm.dealData.open_deal.length ? _c("div", {
    staticClass: "deal-item"
  }, [_vm.dealData.open_deal_count ? _c("div", {
    staticClass: "deal-card"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("h1", {
    staticClass: "header-title"
  }, [_vm._v(" 未关闭的商机"), _c("span", [_vm._v("（" + _vm._s(_vm.dealData.open_deal_count) + "）")])])]), _vm._l(_vm.dealData.open_deal, function (item) {
    return _c("figure", {
      key: item.id,
      staticClass: "content"
    }, [_c("header", {
      staticClass: "content-progress"
    }, [_c("progress-tags", {
      attrs: {
        "progress-obj": _vm.handleProgressObj(item.conv_deal_stage)
      }
    })], 1), _c("div", {
      staticClass: "content-title"
    }, [_c("h1", [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "content-time"
    }, [_vm._v(" 商机创建时间"), _c("span", [_vm._v(_vm._s(item.create_time))])])]), _c("footer", {
      staticClass: "footer"
    }, [_c("div", {
      staticClass: "footer-left"
    }, [_c("image-avatar", {
      attrs: {
        avatar: item.follow_up_by.avatar,
        name: item.follow_up_by.name
      }
    }), _c("div", {
      staticClass: "footer-left-info"
    }, [_c("h1", [_vm._v(_vm._s(item.follow_up_by.name))]), _c("div", [_vm._v("跟进人")])])], 1), _c("div", {
      staticClass: "footer-middle"
    }, [_c("div", [_c("span", {
      staticClass: "footer-middle-desc"
    }, [_vm._v(_vm._s(item.pre_deal_money_name) + "：")]), _c("span", [_vm._v(_vm._s(_vm.money(item.pre_deal_money || 0)))])]), _c("div", [_c("span", {
      staticClass: "footer-middle-desc"
    }, [_vm._v(_vm._s(item.pre_deal_date_name) + "：")]), _c("span", [_vm._v(_vm._s(_vm.getPreDealDateString(item.pre_deal_date) || "-"))])])]), _c("div", {
      staticClass: "footer-right",
      on: {
        click: function ($event) {
          return _vm.goDealDetail(item.id);
        }
      }
    }, [_vm._m(0, true)])])]);
  })], 2) : _vm._e(), _vm.dealData.close_deal_count && _vm.dealData.open_deal_count ? _c("el-divider") : _vm._e(), _vm.dealData.close_deal_count ? _c("div", {
    staticClass: "deal-card"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("h1", {
    staticClass: "header-title"
  }, [_vm._v(" 已关闭的商机"), _c("span", [_vm._v("（" + _vm._s(_vm.dealData.close_deal_count) + "）")])])]), _vm._l(_vm.dealData.close_deal, function (item) {
    return _c("figure", {
      key: item.id,
      staticClass: "content"
    }, [_c("header", {
      staticClass: "content-progress"
    }, [_c("progress-tags", {
      attrs: {
        "progress-obj": _vm.handleProgressObj(item.conv_deal_stage)
      }
    })], 1), _c("div", {
      staticClass: "content-title"
    }, [_c("h1", [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "content-time"
    }, [_vm._v(" 商机创建时间"), _c("span", [_vm._v(_vm._s(item.create_time))])])]), _c("footer", {
      staticClass: "footer"
    }, [_c("div", {
      staticClass: "footer-left"
    }, [_c("image-avatar", {
      attrs: {
        avatar: item.follow_up_by.avatar,
        name: item.follow_up_by.name
      }
    }), _c("div", {
      staticClass: "footer-left-info"
    }, [_c("h1", [_vm._v(_vm._s(item.follow_up_by.name))]), _c("div", [_vm._v("跟进人")])])], 1), _c("div", {
      staticClass: "footer-middle"
    }, [_c("div", [_c("span", {
      staticClass: "footer-middle-desc"
    }, [_vm._v(_vm._s(item.pre_deal_money_name) + "：")]), _c("span", [_vm._v(_vm._s(_vm.money(item.pre_deal_money || 0)))])]), _c("div", [_c("span", {
      staticClass: "footer-middle-desc"
    }, [_vm._v(_vm._s(item.pre_deal_date_name) + "：")]), _c("span", [_vm._v(_vm._s(_vm.getPreDealDateString(item.pre_deal_date) || "-"))])])]), _c("div", {
      staticClass: "footer-right",
      on: {
        click: function ($event) {
          return _vm.goDealDetail(item.id);
        }
      }
    }, [_vm._m(1, true)])])]);
  })], 2) : _vm._e()], 1) : _c("div", {
    staticClass: "deal-card"
  }, [_c("div", {
    staticClass: "empty-deal"
  }, [_vm._m(2), _c("p", [_vm._v("客户暂无商机")]), _vm.isCreateDealShow && _vm.createDealPermission ? _c("p", [_vm._v(" 客户有明确的购买需求，可以 "), _c("span", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.createDeal.apply(null, arguments);
      }
    }
  }, [_vm._v("新建商机")]), _vm._v(" 进行管理 ")]) : _vm._e()])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_vm._v("查看详情"), _c("i", {
    staticClass: "iconfont icon-top-right"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_vm._v("查看详情"), _c("i", {
    staticClass: "iconfont icon-top-right"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("i", {
    staticClass: "iconfont icon-receiving-fill"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;