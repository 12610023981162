"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: `daily-preview-card-${_vm.detail.conversation_id}`,
    class: ["daily-preview-card", _vm.detail.conversation_status === "expire" ? "expire-daily-preview-card" : ""],
    on: {
      click: function ($event) {
        return _vm.$emit("click");
      }
    }
  }, [_c("div", {
    ref: `detail-info-${_vm.detail.conversation_id}`,
    staticClass: "detail-info"
  }, [_c("i", {
    class: ["iconfont", _vm.getIcon(_vm.detail.conversation_type)]
  }), _c("div", {
    class: ["contact-name", {
      "show-all-name": _vm.showAllName(_vm.detail.contact_name)
    }]
  }, [_vm._v(" " + _vm._s(_vm.getContactName(_vm.detail.contact_name)) + " ")]), _vm.detail.conversation_type !== "doc" ? [_c("span", {
    staticClass: "divide"
  }, [_vm._v("|")]), _c("span", {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.getDuration(_vm.detail.duration)) + " ")])] : _vm._e(), _vm.convShowKeyEvent.length ? _c("span", {
    staticClass: "divide"
  }, [_vm._v("|")]) : _vm._e()], 2), _c("KeyEvent", {
    class: `show-conv-key-event-${_vm.detail.conversation_id}`,
    attrs: {
      "show-number": _vm.showNumber,
      "key-event": _vm.convShowKeyEvent
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;