import { render, staticRenderFns } from "./businessExplain.vue?vue&type=template&id=50eca610&scoped=true&"
import script from "./businessExplain.vue?vue&type=script&lang=js&"
export * from "./businessExplain.vue?vue&type=script&lang=js&"
import style0 from "./businessExplain.vue?vue&type=style&index=0&id=50eca610&lang=scss&scoped=true&"
import style1 from "./businessExplain.vue?vue&type=style&index=1&id=50eca610&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50eca610",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50eca610')) {
      api.createRecord('50eca610', component.options)
    } else {
      api.reload('50eca610', component.options)
    }
    module.hot.accept("./businessExplain.vue?vue&type=template&id=50eca610&scoped=true&", function () {
      api.rerender('50eca610', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Business/businessExplain.vue"
export default component.exports