"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversation"
  }, [_c("div", {
    staticClass: "left-filter"
  }, [_c("div", {
    staticClass: "page-title-content"
  }, [_c("div", {
    staticClass: "page-title"
  }, [_vm._v("会话")]), _vm.isBaseConvInDrawer && !_vm.isInQuickModelingPage ? _c("workspace-badge") : _c("SpaceButton"), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "bottom",
      enterable: true,
      content: "评分规则设置"
    }
  }, [_vm.modulePermission.conversationScoreSetting && !_vm.isBaseConvInDrawer ? _c("el-button", {
    staticClass: "rule-set-btn hover-show-bg",
    attrs: {
      icon: "iconfont icon-setting",
      circle: ""
    },
    on: {
      click: _vm.goScoreRuleSettingPage
    }
  }) : _vm._e()], 1)], 1), _c("conversation-filter", {
    key: _vm.workspaceInfo.id,
    ref: "conversationFilter",
    attrs: {
      "back-fill-filters": _vm.backFillFilters
    },
    on: {
      filterChange: _vm.filterChange,
      filterNumChange: function ($event) {
        return _vm.$emit("filterNumChange", $event);
      },
      convFlowFiltersChange: function ($event) {
        return _vm.$emit("convFlowFiltersChange", $event);
      },
      openFilterManage: _vm.openFilterManage
    }
  })], 1), _c("div", {
    staticClass: "right-results"
  }, [_c("div", {
    staticClass: "conver-echarts-title"
  }, [_c("div", {
    staticClass: "charts-title"
  }, [_c("span", {
    staticClass: "title-text page-subtitle"
  }, [_vm._v(_vm._s(_vm.echartsTitle))]), _c("span", {
    staticClass: "title-number"
  }, [_vm._v(_vm._s(_vm.resultsTotal))])]), _c("div", {
    staticClass: "conversation-btns"
  }, [false ? _c("book-meeting") : _vm._e(), false ? _c("upload-conversation") : _vm._e(), !_vm.isBaseConvInDrawer ? _c("div", {
    staticClass: "right-view-change"
  }, [!_vm.workspaceInfo.type ? _c("UploadConversation") : _vm._e(), _c("el-tooltip", {
    attrs: {
      slot: "reference",
      content: "查看当前页会话所属交易",
      "popper-class": "conversation-list-head-tooltip"
    },
    slot: "reference"
  }, [_vm.modulePermission.trade ? _c("i", {
    staticClass: "iconfont icon-deallink",
    on: {
      click: _vm.goCurrentConvBelongDeal
    }
  }) : _vm._e()]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "导出内容以筛选条件为准",
      "popper-class": "conversation-list-head-tooltip"
    }
  }, [_c("i", {
    directives: [{
      name: "debounce",
      rawName: "v-debounce",
      value: [_vm.fetchExportContent],
      expression: "[fetchExportContent]"
    }],
    staticClass: "iconfont icon-export"
  })]), _c("div", {
    staticClass: "toggle-view"
  }, [_c("el-tooltip", {
    attrs: {
      content: "卡片视图"
    }
  }, [_c("i", {
    class: ["iconfont", "icon-view-card", {
      is_active: _vm.viewType === "card"
    }],
    on: {
      click: function ($event) {
        return _vm.selectView("card");
      }
    }
  })]), _c("el-tooltip", {
    attrs: {
      content: "列表视图"
    }
  }, [_c("i", {
    class: ["iconfont", "icon-drag-line", {
      is_active: _vm.viewType === "table"
    }],
    on: {
      click: function ($event) {
        return _vm.selectView("table");
      }
    }
  })])], 1), _c("el-dropdown", {
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    staticClass: "hover-show-bg"
  }, [_c("i", {
    staticClass: "el-icon-more"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.interfacePermission.getExtractInfoInstructionList ? _c("el-dropdown-item", {
    attrs: {
      command: "extractInfo",
      icon: "iconfont icon-configuration"
    }
  }, [_vm._v("配置信息提取指令")]) : _vm._e(), _vm.interfacePermission.getAutoProcessSetting ? _c("el-dropdown-item", {
    attrs: {
      command: "automationProcess",
      icon: "iconfont icon-autoauto-process"
    }
  }, [_vm._v("自动化流程")]) : _vm._e(), _vm.hasWorkspace && _vm.hasBatchTransferPermission && !_vm.workspaceInfo.type ? _c("el-dropdown-item", {
    attrs: {
      command: "transferWorkspace",
      icon: "iconfont icon-workspace-3"
    }
  }, [_vm._v("转移会话到其他空间")]) : _vm._e(), _c("el-dropdown-item", {
    attrs: {
      command: "goConvRecycleBin",
      icon: "iconfont icon-delete"
    }
  }, [_vm._v("会话回收站")])], 1)], 1)], 1) : _vm._e()], 1)]), !_vm.isBaseConvInDrawer ? _c("conversation-echarts-bar", {
    attrs: {
      "chart-data": _vm.chartData
    }
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "conversation-list-container"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.viewType === "card",
      expression: "viewType === 'card'"
    }],
    staticClass: "sort-row"
  }, [_c("div", {
    staticClass: "left-sort"
  }, [_c("sort-caret", {
    ref: "begin_time_sort",
    attrs: {
      label: "会话时间"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("begin_time", $event);
      }
    }
  }), _c("sort-caret", {
    ref: "duration_sort",
    attrs: {
      label: "会话时长"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("duration", $event);
      }
    }
  }), _c("sort-caret", {
    ref: "host_id_sort",
    attrs: {
      label: "销售"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("host_id", $event);
      }
    }
  })], 1), _c("sort-caret", {
    ref: "event_engine_count_sort",
    staticClass: "key-event",
    attrs: {
      label: "关键事件"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("event_engine_count", $event);
      }
    }
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasExtractInfo,
      expression: "hasExtractInfo"
    }],
    staticClass: "extract-info-header"
  }, [_vm._v("信息提取")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasSummary,
      expression: "hasSummary"
    }],
    staticClass: "summary-header"
  }, [_vm._v("智能纪要")]), !_vm.isInQualityTesting && !_vm.isBaseConvInDrawer ? [_vm._l(_vm.conversationScoreHead, function (_ref) {
    let {
      id,
      name,
      description,
      mode,
      highlight
    } = _ref;
    return [_c("span", {
      key: id,
      class: ["score-rule-sort", `column_${id}`, {
        "high-light": highlight
      }]
    }, [_c("sort-caret", {
      ref: `score_rule_${id}_sort`,
      refInFor: true,
      staticClass: "score",
      attrs: {
        label: name,
        description: `${mode === "increase" ? "加分模式" : "减分模式"}${description ? " · " + description : ""}`
      },
      on: {
        sortCallBack: function ($event) {
          return _vm.sortData(`score_rule_${id}`, $event);
        }
      }
    })], 1)];
  }), _vm._l(_vm.complianceScoreHead, function (_ref2) {
    let {
      id,
      name,
      description,
      mode,
      highlight
    } = _ref2;
    return [_c("span", {
      key: id,
      class: ["score-rule-sort", `column_${id}`, {
        "high-light": highlight
      }]
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "质检评分",
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-protect-line"
    })]), _c("sort-caret", {
      ref: `compliance_score_${id}_sort`,
      refInFor: true,
      staticClass: "score",
      attrs: {
        label: name,
        description: `${mode === "increase" ? "加分模式" : "减分模式"}${description ? " · " + description : ""}`
      },
      on: {
        sortCallBack: function ($event) {
          return _vm.sortData(`compliance_score_${id}`, $event);
        }
      }
    })], 1)];
  })] : _vm._e()], 2), _vm.viewType === "table" ? _c("div", {
    staticClass: "table-view view-height"
  }, [_c("conversation-table", {
    ref: "listTable",
    attrs: {
      list: _vm.list,
      "conversation-list-table-head": _vm.conversationListTableHead,
      "list-refresh": _vm.listRefresh
    },
    on: {
      sortChange: _vm.sortData
    }
  })], 1) : _c("div", {
    ref: "cardView",
    staticClass: "card-view view-height",
    class: [_vm.isBaseConvInDrawer ? "is-in-conv-flow" : ""]
  }, [_vm.isNone === "false" ? _c("ConversationListCard", {
    ref: "listCard",
    attrs: {
      list: _vm.list,
      "conversation-score-head": _vm.conversationScoreHead,
      "compliance-score-head": _vm.complianceScoreHead
    },
    on: {
      openEventKeyDrawer: _vm.openEventKeyDrawer
    }
  }) : _vm._e(), _vm.isNone === "true" ? _c("NoData", {
    attrs: {
      tips: "暂无会话"
    }
  }) : _vm._e()], 1)]), _c("el-pagination", {
    staticClass: "pagination conver-list-pagination",
    class: _vm.resultsTotal >= 10000 ? "hide-last-page" : "",
    attrs: {
      "current-page": _vm.pageParams.current_page,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.pageParams.size,
      layout: "total, prev, pager, next, sizes",
      total: _vm.resultsTotal
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1), _c("filter-manage-drawer", {
    attrs: {
      "filter-manage-visible": _vm.filterManageVisible
    },
    on: {
      "update:filterManageVisible": function ($event) {
        _vm.filterManageVisible = $event;
      },
      "update:filter-manage-visible": function ($event) {
        _vm.filterManageVisible = $event;
      }
    }
  }), _c("event-key-drawer", {
    attrs: {
      "event-key-drawer-visible": _vm.eventKeyDrawerVisible,
      "conv-id": _vm.convId,
      "media-type": _vm.mediaType,
      participants: _vm.participants,
      "is-key-event-detail": _vm.isKeyEventDetail,
      "no-event-click": true,
      "event-id": _vm.eventId
    },
    on: {
      goBack: _vm.goBack,
      handleTagClick: _vm.handleTagClick,
      closeDrawer: function ($event) {
        _vm.eventKeyDrawerVisible = false;
      }
    }
  }), _c("upload-media-dialog", {
    attrs: {
      "upload-media-dialog-visible": _vm.uploadMediaDialogVisible
    },
    on: {
      closeUploadMediaDialog: function ($event) {
        _vm.uploadMediaDialogVisible = false;
      }
    }
  }), _c("TransferConversationDialog", {
    ref: "transferConversationDialog",
    attrs: {
      "dialog-visible": _vm.transferConversationDialogVisible,
      "workspace-item": _vm.workspaceInfo,
      "conversation-count": _vm.transferConvCount
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      submit: _vm.transferConversation
    }
  }), _c("ExtractInfoSettingDrawer", {
    attrs: {
      visible: _vm.extractInfoSettingDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.extractInfoSettingDrawerVisible = $event;
      }
    }
  }), _c("AutomationProcessDrawer", {
    attrs: {
      visible: _vm.automationProcessDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.automationProcessDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;