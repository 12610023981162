"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _taskCenter = require("@/api/taskCenter");
const ICONMAP = {
  coaching: 'training'
};
var _default = {
  name: 'TaskTitleLink',
  components: {},
  props: {
    taskInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isCompleteMenu: {
      // 如果是已完成tab，样式都要删除线置灰
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    taskTitleClass() {
      return {
        'complete-task': [1, 2].includes(this.taskInfo.cur_user_status) || this.isCompleteMenu || this.taskInfo.task_type === 'complete',
        link: this.taskInfo.router_name
      };
    },
    contentIconClass() {
      return `iconfont icon-${ICONMAP[this.taskInfo.entity_type]}`;
    },
    getCompleteDisable() {
      return [1, 2].includes(this.taskInfo.cur_user_status) || this.taskInfo.complete_disable;
    },
    getCheckValue() {
      return this.taskInfo.cur_user_status === 1;
    }
  },
  methods: {
    goToDetail() {
      if (!this.taskInfo.router_name) return;
      const path = _routeMap.ROUTES[this.taskInfo.router_name] ? _routeMap.ROUTES[this.taskInfo.router_name] : '/404';
      const query = this.taskInfo.router_params;
      const routeData = this.$router.resolve({
        path,
        query
      });
      this.$emit('updateReadStatus');
      window.open(routeData.href, '_blank');
    },
    getTitleHtml(titleInfo) {
      let html = '';
      titleInfo.forEach(item => {
        if (item.router_name) {
          const router = this.$router.resolve({
            path: _routeMap.ROUTES[item.router_name],
            query: Object.assign(item.router_params, {
              openDetailDrawer: true
            })
          });
          html += `<a href="${router.href}" target="_blank" contenteditable="false"
          >${item.text}</a>&nbsp;`;
        } else {
          html += item.text;
        }
      });
      return html;
    },
    async completeTask(task_id) {
      const params = {
        task_id,
        status: 1
      };
      const res = await (0, _taskCenter.completeTask)(params);
      if (res.code === 20000) {
        this.$message({
          message: '已完成',
          type: 'success',
          center: true
        });
        this.$set(this.taskInfo, 'cur_user_status', 1);
        this.$set(this.taskInfo, 'task_type', 'complete');
        this.$emit('updateExecutorStatus', this.taskInfo.task_id);
      }
    },
    handleIsDoneChange() {
      if (this.getCompleteDisable) {
        return;
      }
      const task_id = this.taskInfo.task_id;
      // 弹框提醒：我分配的任务 || 我的任务中（我分配的多人任务且其他人中有未完成的）
      const isMultTaskUsers = this.currentMenu === 'mytask' && this.cardItem.create_user.id === this.userId && this.cardItem.task_users.length > 1 && this.cardItem.task_users.filter(item => item.id !== this.userId).some(_ref => {
        let {
          status
        } = _ref;
        return !status;
      });
      if (isMultTaskUsers || this.currentMenu === 'mydistribute') {
        this.$confirm('还有执行者未完成这个任务，是否完成此任务', '完成任务', {
          confirmButtonText: '确认完成',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: async (action, ctx, close) => {
            if (action !== 'confirm') {
              close();
              return;
            }
            ctx.confirmButtonLoading = true;
            try {
              await this.completeTask(task_id);
              close();
            } finally {
              ctx.confirmButtonLoading = false;
            }
          }
        }).finally(() => {});
      } else {
        this.completeTask(task_id);
      }
    }
  }
};
exports.default = _default;