"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _ValueContainer = _interopRequireDefault(require("./components/ValueContainer"));
var _SearchContainer = _interopRequireDefault(require("./components/SearchContainer"));
var _MemberLazyTree = _interopRequireDefault(require("./components/MemberLazyTree"));
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MembersTreeSelect',
  components: {
    ValueContainer: _ValueContainer.default,
    SearchContainer: _SearchContainer.default,
    MemberLazyTree: _MemberLazyTree.default
  },
  props: {
    isPreventNotSelected: {
      type: Boolean,
      default: true
    },
    backfillList: {
      type: Array,
      default: () => []
      /** 回填参数示例
        backfillList: [
          {
            name: '🙂朱凯强',
            id: '1_3',
            avatar:
              'https://sale-test.oss-cn-zhangjiakou.aliyuncs.com/avatar/ba739ccbb8ade76b0de34e374fbb75ad6249c9823.jpg',
            isDepartment: false,
            preNode: {
              name: '  🙃 深维智信NO.1    (◍•ᴗ•◍)   ',
              id: 'tree_id_1'
            }
          }
        ],
      */
    },

    removePrefixId: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '成员'
    },
    requestSearchParams: {
      type: Object,
      default: () => ({})
    },
    searchParamsNoSpace: {
      type: Boolean,
      default: false
    },
    removeDuplicates: {
      // 是否在显示框内去除重复成员  （存在统一用户在不同部门）
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lazyTreeElement: null,
      isSearching: false,
      remoteLoading: false,
      popoverVisible: false,
      selectionList: [],
      searchOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    backfillList: {
      immediate: true,
      handler(val) {
        // 如果有数据要回填则回填数据
        this.selectionList = val;
        this.$nextTick(() => {
          this.lazyTreeElement.backFillData(val);
        });
      }
    },
    selectionList() {
      this.setOptionsIsChecked();
    },
    popoverVisible(val) {
      this.$nextTick(() => {
        this.$emit('popoverVisibleChange', val);
      });
    }
  },
  mounted() {
    this.lazyTreeElement = this.$refs['member-lazy-tree'];
    // 设置popover最小宽度和输入框一致
    this.$refs['tree-select-options-container'].style.minWidth = this.$refs['value-container'].$el.offsetWidth + 'px';
  },
  methods: {
    throwData: (0, _commonFunc.debounce)(function (val) {
      // 处理数据
      const selectionIds = this.formateData(this.selectionList.map(_ref => {
        let {
          id
        } = _ref;
        return id;
      }));
      if (this.isPreventNotSelected && !this.selectionList.length) {
        this.$message.warning('请选择成员');
        return;
      }
      this.$emit('change', selectionIds, this.selectionList);
    }, 50),
    formateData(tree_user_ids) {
      const tree_ids = [];
      const user_ids = [];
      tree_user_ids.forEach(item => {
        item = String(item);
        const id = this.removePrefixId ? item.split('_').pop() - 0 : item;
        if (item.indexOf('tree_id_') !== -1) {
          tree_ids.push(id);
        } else {
          if (this.removeDuplicates && user_ids.includes(id)) {
            return;
          }
          user_ids.push(id);
        }
      });
      return {
        tree_ids,
        user_ids
      };
    },
    getNodeIndex(id) {
      return this.selectionList.findIndex(item => item.id === id);
    },
    removeTag(nodeData) {
      if (this.getNodeIndex(nodeData.id) !== -1) {
        // 列表中存在该项则直接删除即可
        this.selectionList.splice(this.getNodeIndex(nodeData.id), 1);
        this.throwData();
      }
      // 需要到树里面去取消选中，触发树的反选
      this.triggerTreeNodeCheck(nodeData.id, false);
    },
    addTag(nodeData) {
      // 在树上已选中项不再添加到选中框
      if (this.getNodeIndex(nodeData.id) === -1 && !this.hasCheckedInTheTree(nodeData.id)) {
        this.selectionList.push(nodeData);
        this.throwData();
        // 需要到树里面去取消选中，触发树的反选
        this.triggerTreeNodeCheck(nodeData.id, true);
      }
    },
    treeCheckChange(val) {
      this.selectionList = val;
      this.throwData();
    },
    clear() {
      var _this$lazyTreeElement, _this$lazyTreeElement2;
      this.selectionList = [];
      (_this$lazyTreeElement = this.lazyTreeElement) === null || _this$lazyTreeElement === void 0 ? void 0 : (_this$lazyTreeElement2 = _this$lazyTreeElement.treeElement) === null || _this$lazyTreeElement2 === void 0 ? void 0 : _this$lazyTreeElement2.setCheckedKeys([]);
      this.throwData();
    },
    // 处理搜索成员的结果
    async getSearchOptions(filter) {
      this.searchOptions = [];
      if (!filter.length) return;
      this.remoteLoading = true;
      const params = {
        filter,
        page_size: 10,
        ...this.requestSearchParams
      };
      if (!this.searchParamsNoSpace) {
        params.workspace_id = this.workspaceInfo.id;
        params.is_main_space = Number(this.$refs['member-lazy-tree'].isCheckedSpace);
      }
      const res = await (0, _organization.getUserList)(params).finally(() => {
        this.remoteLoading = false;
      });
      if (res.code === 20000) {
        // 这里处理一遍每个人的id拼接部门id，信息增加部门信息
        this.searchOptions = res.results.list.map(item => {
          if (item.type === 1) {
            // 成员
            return {
              avatar: item.avatar,
              name: item.name,
              id: `${item.department_id}_${item.id}`,
              preNode: {
                name: item.department_name,
                id: `tree_id_${item.department_id}`
              }
            };
          } else {
            // 部门
            return {
              avatar: item.avatar,
              name: item.name,
              id: `tree_id_${item.id}`,
              isDepartment: true,
              preNode: {
                name: item.parent_department_name,
                id: `tree_id_${item.parent_department_id}`
              }
            };
          }
        });
      }
      this.setOptionsIsChecked();
    },
    setOptionsIsChecked() {
      this.searchOptions.forEach((item, index) => {
        this.$set(this.searchOptions[index], 'isChecked', this.selectionList.some(_ref2 => {
          let {
            id
          } = _ref2;
          return id === item.id;
        }) || this.hasCheckedInTheTree(item.id));
      });
    },
    // 判断搜索项是否在树上 && 已选
    hasCheckedInTheTree(nodeId) {
      var _this$lazyTreeElement3, _this$lazyTreeElement4;
      const treeNode = (_this$lazyTreeElement3 = this.lazyTreeElement) === null || _this$lazyTreeElement3 === void 0 ? void 0 : (_this$lazyTreeElement4 = _this$lazyTreeElement3.treeElement) === null || _this$lazyTreeElement4 === void 0 ? void 0 : _this$lazyTreeElement4.getNode(nodeId);
      return treeNode && treeNode.checked;
    },
    triggerTreeNodeCheck(id, isCheck) {
      var _this$lazyTreeElement5, _this$lazyTreeElement6, _this$lazyTreeElement7;
      (_this$lazyTreeElement5 = this.lazyTreeElement) === null || _this$lazyTreeElement5 === void 0 ? void 0 : (_this$lazyTreeElement6 = _this$lazyTreeElement5.treeElement) === null || _this$lazyTreeElement6 === void 0 ? void 0 : _this$lazyTreeElement6.setChecked(id, isCheck, true);
      (_this$lazyTreeElement7 = this.lazyTreeElement) === null || _this$lazyTreeElement7 === void 0 ? void 0 : _this$lazyTreeElement7.checkNode();
    },
    handleSelectionList() {
      let list = this.selectionList;
      if (this.removeDuplicates) {
        list = this.deduplicateById(this.selectionList);
      }
      return list;
    },
    deduplicateById(arr) {
      const resultMap = arr.reduce((acc, obj) => {
        const userId = obj.id.split('_').pop();
        acc[userId] = obj;
        return acc;
      }, {});
      return Object.values(resultMap);
    }
  }
};
exports.default = _default;