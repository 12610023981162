"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _AssistRecommend = _interopRequireDefault(require("@/components/AssistRecommend"));
var _lottieWebVue = _interopRequireDefault(require("lottie-web-vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AssistantButton',
  components: {
    LottieAnimation: _lottieWebVue.default,
    AssistRecommend: _AssistRecommend.default
  },
  data() {
    return {
      assistantDrawerVisible: false,
      assistKey: +new Date()
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['assistUnreadCount'])
  },
  created() {
    this.$store.dispatch('user/getAssistUnreadCount');
  },
  mounted() {
    this.$bus.$on('openAssist', info => {
      this.openAssistantDrawer();
    });
  },
  methods: {
    openAssistantDrawer() {
      this.assistKey = +new Date();
      this.assistantDrawerVisible = true;
    },
    closeDrawer() {
      this.assistantDrawerVisible = false;
    }
  }
};
exports.default = _default;