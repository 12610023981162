"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _library = require("@/api/library");
// 与剪辑库相关的数据状态   包括剪辑和文件夹管理

const state = {
  clipVideoUrl: '',
  // 会话对应的视频或者音频资源的url
  audioInfoList: null,
  // 处理后的音频信息
  shareInfoObj: null,
  // 有关共享屏幕的数据
  clipConversationId: null,
  folderTree: null // 文件树
};

const mutations = {
  SET_VIDEO_URL: (state, val) => {
    state.clipVideoUrl = val;
  },
  SET_CONVERSATION_ID: (state, val) => {
    state.clipConversationId = val;
  },
  SET_AUDIO_INFO_LIST: (state, val) => {
    state.audioInfoList = val;
  },
  SET_SHARE_INFO_OBJ: (state, val) => {
    state.shareInfoObj = val;
  },
  SET_TREE: (state, val) => {
    state.folderTree = val;
  }
};
const actions = {
  async get_tree(_ref, spaceId) {
    let {
      commit,
      rootGetters
    } = _ref;
    const defaultSpaceId = rootGetters.workspaceInfo.id;
    const space_id = spaceId || defaultSpaceId; // 优先使用指定的spaceId 其次使用全局的spaceId
    if (!space_id) return;
    const res = await (0, _library.getFolderTree)({
      type: 'all',
      space_id
    });
    commit('SET_TREE', res.results.folder_list);
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;