"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "train-and-publish"
  }, [_c("div", {
    staticClass: "train-and-publish-container"
  }, [_c("header", {
    staticClass: "train-header"
  }, [_c("h1", {
    staticClass: "train-header-title"
  }, [_vm._v("训练模型")]), _c("MgvButton", {
    attrs: {
      type: "text",
      size: "tiny"
    },
    on: {
      click: _setup.openTrainDetailDrawer
    }
  }, [_vm._v("查看训练记录")])], 1), _c("div", {
    staticClass: "train-status"
  }, [_c("h2", {
    staticClass: "train-status-label"
  }, [_vm._v(" " + _vm._s(_setup.statusLabel) + " - V" + _vm._s(_setup.modelInfo.version) + " ")]), _setup.trainStatus === 3 ? [_c("div", {
    staticClass: "train-error"
  }, [_c("el-button", {
    staticClass: "retry-btn",
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: _setup.retryTrain
    }
  }, [_vm._v("重新训练")])], 1)] : [_c("div", {
    staticClass: "row"
  }, [_c("el-progress", {
    attrs: {
      percentage: _setup.formatProgress(_setup.trainDataPercentage)
    }
  })], 1), _setup.trainStatus === 2 ? _c("div", [_vm._v(" 您可以直接发布模型或 "), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _setup.openTrailResultDialog
    }
  }, [_vm._v("预览模型效果")])], 1) : _vm._e()]], 2), _setup.trainStatus === 2 ? [_c("h1", {
    staticClass: "train-header-title"
  }, [_vm._v("发布模型")]), _c(_setup.PublishModelContent, {
    ref: "publishModelRef",
    attrs: {
      id: _setup.modelInfo.id,
      version: _setup.modelInfo.version
    }
  })] : _vm._e(), _c(_setup.TrailResultsDrawer, {
    attrs: {
      visible: _setup.trailResultDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.trailResultDialogVisible = $event;
      },
      reCreateTrianTask: _setup.reCreateTrianTask
    }
  }), _c(_setup.TrainDetailDrawer, {
    attrs: {
      "model-type": "ai",
      "model-id": _setup.modelInfo.id,
      "current-version": _setup.modelInfo.version,
      visible: _setup.trainDetailDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.trainDetailDrawerVisible = $event;
      }
    }
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;