"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    staticClass: "member-with-department-input",
    attrs: {
      value: "",
      remote: "",
      "remote-method": _vm.getOrgUserList,
      filterable: "",
      loading: _vm.loading,
      placeholder: _vm.$t("management.请输入姓名"),
      "value-key": "id"
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.userOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item,
        label: item.name
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;