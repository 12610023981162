"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    tableConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pageSize: 20,
      currentPage: 1
    };
  },
  computed: {
    eachPageData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableConfig.rows.slice(start, end);
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    formatCellValue(cellValue, item) {
      if (item.includes('占比')) {
        return Number(cellValue).toLocaleString('zh', {
          style: 'percent',
          maximumFractionDigits: 2
        });
      } else {
        return cellValue;
      }
    },
    handleSortChange(params) {
      this.$emit('handleSortChange', params);
    }
  }
};
exports.default = _default;