"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'SettingSubtitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props
    };
  }
};
exports.default = _default;