"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'DepartmentMultipleSelect',
  model: {
    event: 'change'
  },
  props: {
    noDefault: Boolean,
    disabledSelect: {
      type: Boolean,
      default: false
    },
    isShowTags: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    strictly: {
      type: Boolean,
      default: false
    },
    isHideStrictly: {
      type: Boolean,
      default: false
    },
    isStricklyDisabled: {
      type: Boolean,
      default: false
    },
    defaultExpandAll: {
      type: Boolean,
      default: true
    },
    showSpaceName: {
      type: Boolean,
      default: true
    },
    requestMethod: {
      type: Function,
      default: _organization.getOrgTree
    },
    params: {
      type: [Object, String],
      default: () => ({})
    },
    checkedCanBeExpanded: {
      type: Boolean,
      default: true
    },
    treeData: {
      // 对于同一组件多次使用该组件时传入部门树对象，避免多次请求
      type: Array,
      default: null
    }
  },
  data() {
    return {
      departmentTreeNode: null,
      departmentLabels: '',
      orgTree: [],
      defaultChecked: [],
      defaultExpandedKeys: [],
      checkedNodesData: [],
      switchStrictly: false,
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      }
    };
  },
  watch: {
    treeData: {
      handler: function (val) {
        if (val !== null && val !== void 0 && val.length) {
          this.orgTree = val;
          this.handleResult();
        }
      },
      immediate: true
    }
  },
  created() {
    this.switchStrictly = this.strictly;
    this.getOrgTreeOptions();
  },
  mounted() {
    this.departmentTreeNode = this.$refs['department-tree'];
  },
  methods: {
    getTreeNodeLabel(data) {
      return this.showSpaceName && data.space_info ? `${data.name}（${data.space_info.name}）` : data.name;
    },
    handleRemoveTag(label) {
      const currentNodeKey = this.checkedNodesData.find(_ref => {
        let {
          name
        } = _ref;
        return name === label;
      });
      this.departmentTreeNode.setChecked(currentNodeKey, false, true);
      // 如果选中项不可展开则删除后可展开
      if (!this.checkedCanBeExpanded) {
        this.handleCheckedNodeExpanded(currentNodeKey);
      }
      this.handleCheckChange();
    },
    backFillData(ids) {
      const treeDom = this.$refs['department-tree'];
      treeDom.setCheckedKeys(ids);
      // 如果选中项不可展开则回填项改为不可展开
      if (!this.checkedCanBeExpanded) {
        ids.forEach(item => {
          this.handleCheckedNodeExpanded(item);
        });
      }
      this.handleCheckChange();
    },
    // 获取部门树数据并设置默认选中根节点
    async getOrgTreeOptions() {
      if (this.treeData || this.orgTree.length) return;
      let res;
      if (JSON.stringify(this.params) === '{}') {
        res = await this.requestMethod();
      } else {
        res = await this.requestMethod(this.params);
      }
      if (res.code === 200) {
        this.orgTree = res.results;
        this.handleResult();
      }
    },
    handleResult() {
      const {
        id,
        name
      } = this.orgTree[0];
      if (!this.defaultExpandAll) {
        this.defaultExpandedKeys = [id];
      }
      if (this.noDefault) return;
      this.defaultChecked = [id];
      this.departmentLabels = this.isShowTags ? [name] : name;
      this.handleCheckChange();
    },
    changeStrictly() {
      this.departmentTreeNode.setCheckedKeys([]);
      this.handleCheckChange();
    },
    handleCheckChange(currentData) {
      this.$nextTick(() => {
        if (!this.departmentTreeNode) return;
        // 选中项是否可以展开
        if (!this.checkedCanBeExpanded && currentData) {
          this.handleCheckedNodeExpanded(currentData);
        }
        // 当父子不关联时：选中项 = tree中选中的节点
        // 父子关联时：选中项要根据子项是否全选来判断是选中父项还是子项
        this.checkedNodesData = this.switchStrictly ? this.departmentTreeNode.getCheckedNodes() : this.formaterData();
        const departmentIds = this.checkedNodesData.map(_ref2 => {
          let {
            id
          } = _ref2;
          return id;
        });
        const departmentNames = this.checkedNodesData.map(_ref3 => {
          let {
            name
          } = _ref3;
          return name;
        });
        this.departmentLabels = this.isShowTags ? departmentNames : departmentNames.join(',');
        this.$emit('change', departmentIds, this.departmentLabels, this.switchStrictly);
      });
    },
    // 选中项不可展开
    handleCheckedNodeExpanded(data) {
      const node = this.$refs['department-tree'].getNode(data);
      if (node.checked) {
        node.expanded = false;
        node.isLeaf = true;
        this.unCheckChildNodes(node);
      } else if (node.data.children.length) {
        node.isLeaf = false;
      }
    },
    // 取消选中所有子节点
    unCheckChildNodes(currentNode) {
      if (currentNode.childNodes.length) {
        currentNode.childNodes.forEach(item => {
          item.checked = false;
          if (item.childNodes.length) {
            this.unCheckChildNodes(item);
          }
        });
      }
    },
    formaterData() {
      // 获取树的配置属性信息
      const store = this.departmentTreeNode.store;
      const checkedNodes = [];
      const traverseTreeNode = function (node) {
        // 获取根节点，从根节点开始递归，将全选的节点加入到选中项数组中
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          // 判断节点的子树中是否有被选中的节点
          if (child.indeterminate) {
            traverseTreeNode(child);
          }
        });
      };
      traverseTreeNode(store);
      return checkedNodes;
    }
  }
};
exports.default = _default;