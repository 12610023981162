"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "merge-dialog",
    attrs: {
      title: "选择要合并的联系人",
      "destroy-on-close": "",
      visible: _vm.mergeDialogVisible,
      "before-close": _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "selector"
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人手机号或名称",
      clearable: "",
      "prefix-icon": "el-icon-search"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _c("el-form", {
    ref: "mergeForm",
    attrs: {
      model: _vm.form
    }
  }, [_c("span", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.recommendTitle) + "：")]), _c("el-form-item", [_c("div", {
    staticClass: "recommend-list"
  }, [!_vm.recommendList.length ? _c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无数据")]) : _vm._e(), _c("ul", _vm._l(_vm.recommendList, function (item) {
    return _c("li", {
      key: item.contact_id,
      staticClass: "merge-list-row",
      attrs: {
        "data-user-id": item.contact_id
      },
      on: {
        click: function ($event) {
          return _vm.selectUserToMerge(item);
        }
      }
    }, [_c("show-long-content", {
      staticClass: "user-info",
      attrs: {
        content: item.name,
        len: 25,
        placement: `top`
      },
      scopedSlots: _vm._u([{
        key: "extra-info",
        fn: function () {
          return [_c("image-avatar", {
            attrs: {
              avatar: item.avatar,
              name: item.name
            }
          })];
        },
        proxy: true
      }], null, true)
    }), _c("span", {
      staticClass: "user-tel"
    }, [_vm._v(_vm._s(item.tel))]), _c("div", {
      staticClass: "checked-icon"
    }, [_c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.selectedId === item.contact_id,
        expression: "selectedId === item.contact_id"
      }],
      staticClass: "iconfont icon-success-green"
    })])], 1);
  }), 0)])]), _c("span", {
    staticClass: "item-title"
  }, [_vm._v("请确定合并后的联系人名称")]), _c("el-form-item", {
    attrs: {
      prop: "confirm_contact_name"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.confirm_contact_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "confirm_contact_name", $$v);
      },
      expression: "form.confirm_contact_name"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "outbound"
    }
  }, [_vm._v("外呼中心名称")]), _c("el-radio", {
    attrs: {
      label: "wework"
    }
  }, [_vm._v("企业微信名称")])], 1)], 1), _c("el-form-item", {
    staticClass: "info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _vm._v(" 合并后联系人所在商机其他字段以外呼中心数据为主。每个外呼联系人只能和一个企业微信联系人合并。 ")])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeMergeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;