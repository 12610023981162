"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "directed-graph"
  }, [_c("NodeSidebar", {
    ref: "dnd-container",
    on: {
      toJSON: _vm.toJSON,
      "node-mouse-down": _vm.onNodeMouseDown,
      undo: _vm.undo,
      redo: _vm.redo,
      AddScriptThroughKnowledgeBase: _vm.AddScriptThroughKnowledgeBase
    }
  }), _c("div", {
    ref: "graph",
    staticClass: "graph__inner"
  }), _c("TalkConfigDrawer", {
    ref: "talk-config-drawer",
    attrs: {
      id: "talk-config-drawer",
      "drawer-visible": _vm.talkConfigDrawerVisible,
      config: _vm.currentNodeData
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.talkConfigDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.talkConfigDrawerVisible = $event;
      },
      addBranch: _vm.addBranch,
      removeBranch: _vm.removeBranch,
      updateRoleNodeData: _vm.updateRoleNodeData,
      addRule: _vm.addRule,
      deleteRule: _vm.deleteRule,
      toggleRuleType: _vm.toggleRuleType,
      updateRule: _vm.updateRoleNodeData,
      searchScript: _vm.searchScript,
      commonBatchUpdate: _vm.commonBatchUpdate,
      addAiScoreRule: _vm.addAiScoreRule
    }
  }), _c("SearchScriptDialog", {
    attrs: {
      visible: _vm.searchScriptDialogVisible,
      "current-script": _vm.currentScript
    },
    on: {
      "update:visible": function ($event) {
        _vm.searchScriptDialogVisible = $event;
      },
      addScript: _vm.addScript
    }
  }), _c("AddScriptDialog", {
    attrs: {
      visible: _vm.addScriptDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.addScriptDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;