"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-intro"
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/AIHelper/robot_head.gif")
    }
  }), _c("div", {
    staticClass: "hello-container"
  }, [_c("p", {
    staticClass: "hello"
  }, [_vm._v(_vm._s(_vm.helloText))])]), _c("chat-input", {
    on: {
      change: _vm.emitMsg
    }
  }), _vm._l(_vm.guideList, function (item) {
    return _c("div", {
      key: item,
      staticClass: "guide-msg",
      on: {
        click: function ($event) {
          return _vm.emitMsg(item);
        }
      }
    }, [_vm._v(" " + _vm._s(`🌟 ${item}`) + " ")]);
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;