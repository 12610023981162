"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _convTab = _interopRequireDefault(require("./components/convTab.vue"));
var _commentTab = _interopRequireDefault(require("./components/commentTab.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    convTab: _convTab.default,
    commentTab: _commentTab.default
  },
  data() {
    return {};
  },
  provide() {
    return {
      getConvTime: this.getConvTime
    };
  },
  methods: {
    getConvTime(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    }
  }
};
exports.default = _default;