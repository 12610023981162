"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      "visible-arrow": false,
      placement: "bottom-start",
      width: "400",
      trigger: "click",
      "popper-class": "followup-dynamic-list-popover"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "followup-dynamic-content"
  }, [_c("h6", [_vm._v("跟进动态")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowNextBtn,
      expression: "isShowNextBtn"
    }],
    staticClass: "top-button",
    on: {
      click: _vm.goNextPage
    }
  }, [_c("div", {
    staticClass: "top-button-text"
  }, [_c("i", {
    staticClass: "el-icon-arrow-up"
  }), _c("span", [_vm._v("最近")])])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "followup-dynamic-list"
  }, _vm._l(_vm.currentList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "followup-dynamic-item",
      class: {
        "active-item": item.id === Number(_vm.convId)
      },
      on: {
        click: function ($event) {
          return _vm.changeId(item.id);
        }
      }
    }, [_c("span", {
      staticClass: "conv-time"
    }, [_vm._v(_vm._s(_vm.getDay(item.begin_time)))]), _vm._v(" · "), _c("i", {
      class: "iconfont " + _vm.getConvClass(item.type)
    }), _c("span", {
      staticClass: "conv-name"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowPreBtn,
      expression: "isShowPreBtn"
    }],
    staticClass: "bottom-button",
    on: {
      click: _vm.goPrePage
    }
  }, [_c("div", {
    staticClass: "bottom-button-text"
  }, [_c("i", {
    staticClass: "el-icon-arrow-down"
  }), _c("span", [_vm._v("较早")])])])]), _c("div", {
    staticClass: "conv-title",
    attrs: {
      slot: "reference",
      id: "conv-detail-header-title"
    },
    slot: "reference"
  }, [_c("h1", [_vm._v(_vm._s(_vm.conversationTitleInfo.convName))]), _vm.totalCount ? _c("span", [_vm._v(_vm._s(_vm.getCurrentDataIndex + 1) + " / " + _vm._s(_vm.totalCount))]) : _vm._e(), _c("i", {
    staticClass: "el-icon-arrow-down"
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;