"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRouter = exports.useRoute = void 0;
var _vue = require("vue");
// 访问router
const useRouter = () => {
  const vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$router;
};
// 访问route
exports.useRouter = useRouter;
const useRoute = () => {
  const vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$route;
};
exports.useRoute = useRoute;