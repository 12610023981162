"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "bip-layout"
  }, [_c("header", _vm._l(_vm.data, function (value, key) {
    return _c("div", {
      key: key,
      staticClass: "card",
      on: {
        click: function ($event) {
          return _vm.handleClick(key);
        }
      }
    }, [_vm._t("card", null, {
      value: value,
      attr: key
    })], 2);
  }), 0), _c("section", {
    staticClass: "main-content",
    class: {
      "no-data": !_vm.keyArr.length
    }
  }, [_c("div", {
    ref: "chartContainer",
    staticClass: "scroll-container chart"
  }, [_vm._l(_vm.data, function (value, key) {
    return _c("div", {
      key: key
    }, [_vm._t("chart", null, {
      value: value,
      attr: key
    })], 2);
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.keyArr.length,
      expression: "keyArr.length"
    }],
    staticClass: "blank-placeholder-box"
  }), _vm._t("default")], 2), _c("div", {
    staticClass: "scroll-container conv"
  }, [_vm._t("aside")], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;