"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    attrs: {
      clearable: "",
      placeholder: `请选择${_vm.item.name}`
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, _vm._l(_vm.item.options, function (option) {
    return _c("el-option", {
      key: (option.label || option) + "index",
      attrs: {
        label: option.label || option,
        value: option.value !== undefined ? option.value : option
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;