"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScatterDiagramChart = _interopRequireDefault(require("@/components/Charts/ScatterDiagramChart"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PreviewPortrait',
  components: {
    ScatterDiagramChart: _ScatterDiagramChart.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    portraitConfig: {
      type: Object,
      default: () => [{}]
    }
  },
  computed: {
    chartData() {
      return [this.portraitConfig.table.heads, ...this.portraitConfig.table.rows];
    },
    tableConfig() {
      this.portraitConfig.table.column_config.forEach((_ref, index) => {
        let {
          type,
          mapping
        } = _ref;
        if (type === 'mapping') {
          const mappingObj = {};
          mapping.forEach(itemMapping => {
            mappingObj[itemMapping.column_value] = itemMapping;
          });
          this.portraitConfig.table.column_config[index].mapping = mappingObj;
        }
      });
      return this.portraitConfig.table;
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
exports.default = _default;