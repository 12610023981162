"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BoxplotChart = _interopRequireDefault(require("@/components/BoxplotChart"));
var _BusinessAnalysisTable = _interopRequireDefault(require("@/views/businessIntelligence/components/BusinessAnalysisTable"));
var _boxplotOption = _interopRequireDefault(require("@/components/BoxplotChart/options/boxplotOption.js"));
var _SuggestionBox = _interopRequireDefault(require("@/views/businessIntelligence/components/SuggestionBox"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessAnalysis',
  components: {
    BoxplotChart: _BoxplotChart.default,
    BusinessAnalysisTable: _BusinessAnalysisTable.default,
    SuggestionBox: _SuggestionBox.default
  },
  mixins: [_cancelTokenMixin.default],
  data() {
    return {
      loading: false,
      departmentNames: {},
      option: {},
      quartile: {},
      sourceData: [],
      tableData: [],
      tableTabName: '',
      eventName: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone'])
  },
  watch: {
    biFiltersObj: {
      handler: function () {
        this.eventName = sessionStorage.getItem('bi-boxplot-event-label');
        this.getBusinessAnalysisData();
      },
      deep: true
    },
    'workspaceInfo.id': {
      handler: function () {
        this.getBusinessAnalysisData();
      }
    }
  },
  methods: {
    async getBusinessAnalysisData() {
      if (!this.biFiltersObj && !this.workspaceInfo.id) return;
      // 取消上一次请求
      this.axiosCancelRequest();
      // 使用新的cancelToken
      this.newCancelToken();
      this.loading = true;
      const biFiltersObj = (0, _commonFunc.deepClone)(this.biFiltersObj);
      (0, _timezone.convertParams2UTCplus8)(biFiltersObj.custom_filters);
      const res = await (0, _businessIntelligence.getBusinessAnalysis)({
        ...biFiltersObj,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      }, {
        cancelToken: this.axiosCancelToken
      });
      if (res.code === 200) {
        const data = res.results;
        this.sourceData = [];
        this.tableData = [];
        this.departmentNames = {};
        data.forEach((item, index) => {
          this.sourceData.push(item.data.rate_list || []);
          this.tableData.push(item.data.all_info || []);
          this.departmentNames[index] = item.tree_name;
        });
        this.tableTabName = this.departmentNames[0] + 0;
        let len = 1;
        const map = new Map();
        for (const key in this.departmentNames) {
          if (!map.has(this.departmentNames[key])) {
            map.set(this.departmentNames[key], this.departmentNames[key]);
          } else {
            this.departmentNames[key] += new Array(len).fill(' ').join('');
            len++;
          }
        }

        // prettier-ignore
        this.option = (0, _boxplotOption.default)(this.sourceData, this.departmentNames, this.eventName);
      }
      this.loading = false;
    }
  }
};
exports.default = _default;