"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _ConvListDrawer = _interopRequireDefault(require("../../components/ConvListDrawer"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SalesApiSampleMethod',
  components: {
    FormItemCard: _FormItemCard.default,
    ConvListDrawer: _ConvListDrawer.default
  },
  props: {
    unit: {
      type: String,
      default: '条'
    },
    sampleRule: {
      type: Object,
      default: () => ({})
    },
    sampleConvScopeCount: {
      type: Number,
      default: 0
    },
    sampleScope: {
      type: Object,
      default: () => ({})
    },
    sampleMethod: {
      type: Object,
      default: () => ({
        method: 'random_n',
        sampleData: {
          random_n: '',
          random_percent: '',
          per_sale: ''
        }
      })
    }
  },
  data() {
    var validateMethod = (rule, value, callback) => {
      const ruleList = {
        random_n: 1500,
        random_percent: 100,
        per_sale: 1500
      };
      const n = ruleList[rule.type];
      if (this.sampleMethod.method === rule.type) {
        if (value === '') {
          callback(new Error(`请填写1~${n}的整数`));
        } else {
          if (Number(value) < 1 || Number(value) > n) {
            callback(new Error(`请填写1~${n}的整数`));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    return {
      rules: {
        random_n: [{
          validator: validateMethod,
          trigger: 'change',
          type: 'random_n'
        }],
        random_percent: [{
          validator: validateMethod,
          trigger: 'change',
          type: 'random_percent'
        }],
        per_sale: [{
          validator: validateMethod,
          trigger: 'change',
          type: 'per_sale'
        }]
      },
      count: 0,
      loading: false,
      convListDrawerVisible: false,
      convIds: [],
      isFirst: true,
      params: {
        current_page: 1,
        size: 10,
        workspace_id: 1,
        conversation_ids: []
      },
      list: [],
      total: 0,
      listLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'sampleMethod.method': {
      handler: function () {
        this.$refs.ruleForm.validate();
      }
    },
    sampleConvScopeCount() {
      this.startSample();
    },
    count() {
      this.params.current_page = 1;
    }
  },
  methods: {
    startSample() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (!this.sampleScope.is_all_data && this.sampleScope.account_sale && this.sampleConvScopeCount > 40000) {
            this.$emit('getSampleMethodConvCount', 0);
            return;
          }
          if (!this.sampleConvScopeCount) {
            this.$message.warning('当前抽样会话数量为0，请选择抽样范围');
            this.count = 0;
          } else {
            this.isFirst = false;
            const formatFilter = (0, _commonFunc.deepClone)(this.sampleRule);
            if (formatFilter.date && formatFilter.date.range.length) {
              formatFilter.date.range = (0, _timezone.datePickerParseToZone)(formatFilter.date.range);
            }
            (0, _timezone.convertParams2UTCplus8)(formatFilter.custom_filters);
            const data = {
              ...formatFilter,
              sampling_params: {
                method: this.sampleMethod.method,
                number: Number(this.sampleMethod.sampleData[this.sampleMethod.method]),
                total: this.sampleConvScopeCount
              },
              workspace_id: this.workspaceInfo.id,
              account_sale: this.sampleScope.is_all_data ? false : this.sampleScope.account_sale
            };
            this.loading = true;
            try {
              const res = await (0, _compliance.sampleConv)(data);
              if (res.code === 200) {
                const {
                  total,
                  ids,
                  first_ids,
                  set_ids
                } = res.results;
                this.count = total;
                this.convIds = ids;
                this.$emit('sampleComplete', this.count, {
                  conv_ids: this.convIds,
                  first_ids,
                  set_ids
                });
              }
            } finally {
              this.loading = false;
            }
          }
        }
      });
    },
    openConvDrawer() {
      if (this.loading) return;
      this.convListDrawerVisible = true;
      this.params.conversation_ids = this.convIds;
      this.params.workspace_id = this.workspaceInfo.id;
      this.getSampleConvList();
    },
    async getSampleConvList(val) {
      if (val) {
        const {
          current_page,
          size,
          sort_by,
          sort_order
        } = val;
        this.params.sort_by = sort_by;
        this.params.current_page = current_page;
        this.params.size = size;
        this.params.sort_order = sort_order;
      }
      this.listLoading = true;
      const res = await (0, _compliance.getSampleConvList)(this.params).finally(() => {
        this.listLoading = false;
      });
      if (res.code === 200) {
        this.list = res.results.items;
        this.total = res.results.total_count;
      }
    },
    clearConvList() {
      this.isFirst = true;
      this.convIds = [];
      this.count = 0;
    }
  }
};
exports.default = _default;