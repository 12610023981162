"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _DepartmentAndMemberSelectTree = _interopRequireDefault(require("@/components/DepartmentAndMemberSelectTree"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OverviewKanbanConfig',
  components: {
    DepartmentAndMemberSelectTree: _DepartmentAndMemberSelectTree.default
  },
  props: {
    kanbanConfigVisible: {
      type: Boolean,
      default: false
    },
    isEditKanban: {
      type: Boolean,
      default: false
    },
    getKanbanConfigLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatorDataRangeFn = (rule, value, callback) => {
      if (!(this.kanbanConfig.config.tree_ids.length || this.kanbanConfig.config.user_ids.length)) {
        return callback(new Error('请选择数据范围'));
      }
      callback();
    };
    return {
      submitLoading: false,
      kanbanConfig: {
        name: '',
        config: {
          label: '',
          tree_ids: [],
          user_ids: []
        }
      },
      backfillDepartmentAndMemberSelectParams: {
        ids: [],
        labels: ''
      },
      validatorDataRange: {
        validator: validatorDataRangeFn,
        trigger: 'blur'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    kanbanConfigVisible(val) {
      !val && this.clearDataRange();
    },
    getKanbanConfigLoading(val) {
      // 编辑看板时回填部门成员混选选择器
      !val && this.initDataRange();
    }
  },
  methods: {
    initDataRange() {
      this.$nextTick(() => {
        this.backfillDepartmentAndMemberSelectParams = {
          ids: [...this.kanbanConfig.config.trees.map(_ref => {
            let {
              id
            } = _ref;
            return id;
          }), ...this.kanbanConfig.config.users.map(_ref2 => {
            let {
              id
            } = _ref2;
            return id;
          })],
          labels: this.kanbanConfig.config.label
        };
      });
    },
    clearDataRange() {
      this.kanbanConfig.config = this.$options.data().kanbanConfig.config;
      this.backfillDepartmentAndMemberSelectParams = this.$options.data().backfillDepartmentAndMemberSelectParams;
    },
    daraRangeChange(tree_user_ids, labels, checkedNodesData) {
      this.$refs['department-and-member-select-tree'].$refs['lazyTree'].defaultChecked = [];
      const tree_ids = [];
      const user_ids = [];
      checkedNodesData.forEach(_ref3 => {
        let {
          id
        } = _ref3;
        if (id.startsWith('tree_id_')) {
          tree_ids.push(id);
        } else {
          user_ids.push(id);
        }
      });
      this.$set(this.kanbanConfig, 'config', {
        tree_ids,
        user_ids,
        label: labels
      });
    },
    clearForm() {
      this.kanbanConfig = this.$options.data().kanbanConfig;
      this.$refs['department-and-member-select-tree'].labels = '';
      this.$nextTick(() => {
        this.$refs['kanbanConfigForm'].clearValidate();
      });
    },
    handleClose() {
      this.clearForm();
      this.$emit('update:kanbanConfigVisible', false);
    },
    submit() {
      this.$refs['kanbanConfigForm'].validate(async valid => {
        if (valid) {
          const requestFn = this.isEditKanban ? _businessIntelligence.editBiDataOverviewKanban : _businessIntelligence.createBiDataOverviewKanban;
          try {
            this.submitLoading = true;
            const res = await requestFn({
              ...this.kanbanConfig,
              workspace_id: this.workspaceInfo.id
            });
            if (res.code === 20000) {
              this.$message.success(this.isEditKanban ? '编辑成功' : '新建成功');
              if (!this.isEditKanban) {
                this.kanbanConfig.kanban_id = res.results.kanban_id;
              }
              this.$emit('handleKanbanSuccess', this.kanbanConfig);
              this.handleClose();
            }
          } catch (error) {
            throw new Error(error);
          } finally {
            this.submitLoading = false;
          }
        }
      });
    }
  }
};
exports.default = _default;