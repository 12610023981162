"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealCard',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    dealCardContent: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    // 跳转到商机详情页
    goDealDetail(id) {
      window.open(`${location.origin}${_routeMap.ROUTES.dealDetail}?id=${id}&noback=true`);
    }
  }
};
exports.default = _default;