"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.handleComp(), {
    key: _vm.item.field_name,
    tag: "component",
    attrs: {
      item: _vm.item,
      value: _vm.value,
      "other-page-account-label": _vm.accountLabel,
      "time-range-callback": _vm.value
    },
    on: {
      change: _vm.handleChange
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;