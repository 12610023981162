"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$detailInfo$sal;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "miss-point-detail"
  }, [_c("div", {
    staticClass: "standard-speech"
  }, [_c("h3", {
    staticClass: "standard-title"
  }, [_vm._v(_vm._s(_setup.t("coaching.standardScript")))]), _c("div", {
    staticClass: "standard-speech-content",
    class: {
      "is-expanded": _setup.isExpanded,
      transition: _setup.isTextLongerThanMaxLines(_setup.detailInfo.standard_content, 3)
    }
  }, [_c("p", [_vm._v(" " + _vm._s(_setup.detailInfo.standard_content) + " ")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isTextLongerThanMaxLines(_setup.detailInfo.standard_content, 3),
      expression: "isTextLongerThanMaxLines(detailInfo.standard_content, 3)"
    }],
    staticClass: "fold-switch",
    on: {
      click: function ($event) {
        _setup.isExpanded = !_setup.isExpanded;
      }
    }
  }, [_vm._v(" " + _vm._s(_setup.isExpanded ? "收起" : "展开") + " ")])])]), _c("div", {
    staticClass: "sales-answer"
  }, [_c("h5", {
    staticClass: "sales-answer-title"
  }, [_vm._v(_vm._s(_setup.t("coaching.salesResponse")))]), (_setup$detailInfo$sal = _setup.detailInfo.sales_answer_data) !== null && _setup$detailInfo$sal !== void 0 && _setup$detailInfo$sal.length ? _vm._l(_setup.detailInfo.sales_answer_data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "answer-card"
    }, [_c("div", {
      staticClass: "sales-info"
    }, [_c("div", {
      staticClass: "sales-avatar"
    }, [_c(_setup.ImageAvatar, {
      staticClass: "user-avatar",
      attrs: {
        name: item.user_info.name,
        avatar: item.user_info.avatar,
        shape: "circle"
      }
    }), _c("span", [_vm._v(_vm._s(item.user_info.name))])], 1), _c(_setup.MgvTag, {
      attrs: {
        color: "cyan"
      }
    }, [_vm._v(" " + _vm._s(_setup.t("coaching.pointsHasVar", [item.score]))), _c("i", {
      staticClass: "iconfont icon-ai-assistant-fill"
    })])], 1), _c("p", {
      staticClass: "sales-answer-content"
    }, [_vm._v(_vm._s(item.reply))]), _c("div", {
      staticClass: "miss-point-container"
    }, [_c("h5", {
      staticClass: "miss-point-title"
    }, [_vm._v(_vm._s(_setup.t("coaching.missingPoints")) + ":")]), _c("div", {
      staticClass: "tag-container"
    }, _vm._l(item.miss_point, function (tag, tagIndex) {
      return _c(_setup.MgvTag, {
        key: tagIndex,
        attrs: {
          "max-width": "520px"
        }
      }, [_vm._v(_vm._s(tag) + " ")]);
    }), 1)])]);
  }) : _c("div", {
    staticClass: "no-data"
  }, [_vm._v(_vm._s(_setup.t("coaching.noIncorrectAnswers")))])], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;