"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _organization = require("@/api/organization");
var _permissions = require("@/api/permissions");
var _vuex = require("vuex");
var _default = {
  name: 'OrgInvitation',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    inviteText: {
      type: String,
      default: ''
    },
    orgId: {
      type: Number,
      default: null
    },
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const loginAccountValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('management.登录账号不能为空')));
      } else {
        if (/^[0-9a-zA-Z_]{1,}$/.test(value) == false) {
          callback(new Error(this.$t('management.仅支持字母、数字、下划线')));
        } else if ((value === null || value === void 0 ? void 0 : value.length) < 4) {
          callback(new Error(this.$t('management.账号长度为4-20字符')));
        } else {
          callback();
        }
      }
    };
    return {
      invitePersonForm: {
        name: '',
        tel: '',
        email: '',
        departmentName: '',
        workspaceId: '',
        account: '',
        // 通过账号邀请
        verify_email: '' // 通过账号邀请时填写的验证邮箱【目前只有希音有】
      },

      inviteMethodOptions: [{
        value: 'tel',
        label: this.$t('management.手机号')
      }, {
        value: 'email',
        label: this.$t('management.邮箱')
      }],
      inviteMethod: 'tel',
      rules: {
        name: [{
          required: true,
          message: this.$t('management.请输入姓名'),
          trigger: 'change'
        }],
        tel: [{
          required: true,
          validator: _validate.telValidator,
          trigger: 'change'
        }],
        email: [{
          required: true,
          validator: _validate.emailValidator,
          trigger: 'change'
        }],
        departmentName: [{
          required: true,
          message: this.$t('management.请选择主部门'),
          trigger: 'change'
        }],
        workspaceId: [{
          required: true,
          message: this.$t('management.请选择主空间'),
          trigger: 'change'
        }],
        account: [{
          required: true,
          validator: loginAccountValidator,
          trigger: 'change'
        }],
        verify_email: [{
          required: false,
          validator: _validate.emailValidatorAllowNone,
          trigger: 'change'
        }]
      },
      currIndex: null,
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultExpandedKeys: [],
      orgTree: [],
      departments: [],
      departmentLoading: false,
      inviteBtnLoading: false,
      inviteBtnDisabled: false,
      inviteContinueBtnLoading: false,
      inviteContinueBtnDisabled: false,
      isViolation: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['hasWorkspace', 'isTwoFactor']),
    getTree() {
      return this.orgTree.filter(item => item.node_type !== 'unassigned');
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.fetchDepartmentData();
      }
    }
  },
  methods: {
    handleChangeInviteMethod() {
      this.$refs['invitePersonForm'].clearValidate();
    },
    onCopy() {
      this.$message({
        message: this.$t('management.复制成功'),
        type: 'success'
      });
    },
    onError() {
      this.$message({
        message: this.$t('management.复制失败'),
        type: 'warning'
      });
    },
    closeSelf() {
      this.reset();
      this.$emit('closeInviteDialog', 'self');
    },
    invitePerson(formName, is_continue) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const {
            name,
            tel,
            email,
            account,
            verify_email,
            workspaceId
          } = this.invitePersonForm;
          const _data = {
            name,
            tel,
            email,
            method: this.isTwoFactor ? 'account' : this.inviteMethod,
            account,
            verify_email,
            departments: this.departments,
            main_department: this.departments[0] || 0,
            main_workspace_id: workspaceId,
            workspace_ids: [workspaceId]
          };
          this.setBtnStatus(is_continue);
          try {
            const res = await (0, _organization.orgInvite)(_data);
            if (res.code === 20000) {
              this.$bus.$emit('freshUserList');
              var message = this.$t('management.添加成功');
              if (this.isTwoFactor) {
                message = this.invitePersonForm.verify_email ? this.$t('management.添加成功，账号及密码已发送到验证邮箱') : this.$t('management.添加成功，给该成员添加验证邮箱后可登录');
              }
              this.$message({
                message,
                type: 'success',
                customClass: 'org-add-new-member-message'
              });
              if (is_continue) {
                this.invitePersonForm.name = '';
                this.invitePersonForm.tel = '';
                this.invitePersonForm.email = '';
                this.invitePersonForm.account = '';
                this.invitePersonForm.verify_email = '';
                this.$nextTick(() => {
                  this.$refs['invitePersonForm'].clearValidate();
                });
                this.fetchDepartmentData();
              } else {
                this.closeSelf();
                !this.isTwoFactor && this.$emit('openCopy');
              }
            }
          } finally {
            this.setBtnStatus(is_continue, false);
          }
        }
      });
    },
    setBtnStatus(isContinue) {
      let bool = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (isContinue) {
        this.inviteContinueBtnLoading = bool;
        this.inviteBtnDisabled = bool;
      } else {
        this.inviteBtnLoading = bool;
        this.inviteContinueBtnDisabled = bool;
      }
    },
    async fetchDepartmentData() {
      this.departmentLoading = true;
      const res = await (0, _permissions.getDepartmentWithAuth)();
      if (res.code === 200) {
        var _this$orgTree$;
        this.orgTree = res.results;
        this.defaultExpandedKeys = [(_this$orgTree$ = this.orgTree[0]) === null || _this$orgTree$ === void 0 ? void 0 : _this$orgTree$.id];
      }
      this.departmentLoading = false;
    },
    handleCurrentChange(data) {
      var _this$$refs$treeSele;
      this.invitePersonForm.workspaceId = data.space_info.id;
      this.departments = [data.id];
      this.invitePersonForm.departmentName = data.name;
      (_this$$refs$treeSele = this.$refs['tree-select']) === null || _this$$refs$treeSele === void 0 ? void 0 : _this$$refs$treeSele.blur();
    },
    reset() {
      this.invitePersonForm = {
        name: '',
        tel: '',
        email: '',
        departmentName: '',
        workspaceId: ''
      };
      this.departments = [];
      setTimeout(() => {
        this.$refs['invitePersonForm'].clearValidate();
      }, 100);
    }
  }
};
exports.default = _default;