"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActivateScript = useActivateScript;
exports.useKeyScripts = useKeyScripts;
exports.usePromptDataStruct = usePromptDataStruct;
exports.usePromptTextBlocks = usePromptTextBlocks;
exports.useScoreMetrics = useScoreMetrics;
exports.useTextHandlers = useTextHandlers;
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _commonFunc = require("@/utils/commonFunc");
var _lodash = require("lodash");
var _message = require("@/utils/message");
var _nanoid = require("nanoid");
function usePromptDataStruct(_ref) {
  let {
    scripts,
    prompt,
    totalWordCount = 0,
    evaluates = []
  } = _ref;
  const promptStruct = (0, _vue.ref)({
    name: '',
    // 练习名称
    language: 'zh',
    // 语言 ['zh','en']
    avatar_type: 4,
    avatar_url: '',
    // 头像
    prologue: {
      role: 'customer',
      // 开场发言角色
      content: '你好，请问找我有什么事？' // 开场白
    },

    description: '',
    // 练习说明
    input_mode: 'voice',
    // 输入方式 ['text','voice','both']
    is_keypoints_display: true,
    is_evaluates_display: true
  });
  (0, _vue.provide)('promptStruct', promptStruct);
  const promptConfig = (0, _vue.computed)(() => ({
    ...promptStruct.value,
    keypoints: scripts.value,
    prompt: prompt.value,
    evaluates: evaluates.value
  }));
  const exportStruct = () => {
    console.log(promptConfig.value);
  };
  const createAiCustomerFn = async _ref2 => {
    let {
      category_id
    } = _ref2;
    const res = await (0, _coachingCenter.createAiCustomer)({
      category_id,
      ...promptConfig.value
    });
    return res;
  };
  const updateAiCustomerFn = async course_id => {
    if (totalWordCount.value > 1000) {
      _message.message.warning('提示词总字数不能超过1000字');
      return;
    }
    if (!promptConfig.value.evaluates.length) {
      _message.message.warning('评分指标不能为空');
      return;
    }
    if (promptConfig.value.evaluates.some(item => !item.weight && item.weight !== 0)) {
      _message.message.warning('评分指标权重不能为空');
      return;
    }
    for (const script of promptConfig.value.keypoints) {
      if (!script.content) {
        _message.message.warning('关键问题不能为空');
        return;
      } else if (script.content) {
        const isTextEmpty = script.replies.some(r => (0, _lodash.isEmpty)(r.content));
        const isTextInValid = script.replies.some(r => /^[ \t\n\r]+$/gm.test(r.content));
        const text = script.content.length > 10 ? `${script.content.slice(0, 10)}...` : script.content;
        if (isTextEmpty) {
          _message.message.warning(`关键问题 “${text}” 的标准话术不能为空`);
          return;
        } else if (isTextInValid) {
          _message.message.warning(`关键问题 “${text}” 的标准话术不是有效值`);
          return;
        }
      }
    }
    const res = await (0, _coachingCenter.updateAiCustomer)({
      course_id,
      ...promptConfig.value
    });
    return res;
  };
  const getAiCustomerConfigFn = async course_id => {
    const res = await (0, _coachingCenter.getAiCustomerConfig)({
      course_id
    });
    return res;
  };
  return {
    promptConfig,
    exportStruct,
    createAiCustomerFn,
    updateAiCustomerFn,
    getAiCustomerConfigFn,
    promptStruct
  };
}
function useKeyScripts() {
  const keyScripts = (0, _vue.ref)([]);
  const scriptId = (0, _vue.ref)(0);
  const repliesId = (0, _vue.ref)(0);
  const scriptCache = [];
  const addScript = _ref3 => {
    let {
      el = null
    } = _ref3;
    if (keyScripts.value.length) {
      const maxScriptId = Math.max(...keyScripts.value.map(s => s.id));
      scriptId.value = maxScriptId;
    }
    const script = {
      id: ++scriptId.value,
      content: '',
      replies: [{
        id: ++repliesId.value,
        content: ''
      }],
      disabled: false,
      el
    };
    keyScripts.value.push(script);
    scriptCache.push(script);
    return scriptId.value;
  };
  const addScriptReply = id => {
    if (keyScripts.value.length) {
      const maxRepliesId = Math.max(...keyScripts.value.map(s => s.replies.map(r => r.id)).flat());
      repliesId.value = maxRepliesId;
    }
    const script = keyScripts.value.find(script => script.id === id);
    if (script) {
      script.replies.push({
        id: ++repliesId.value,
        content: ''
      });
    }
  };
  const updateScript = (id, newScriptObj) => {
    const script = keyScripts.value.find(script => script.id === id);
    if (script) {
      for (const key in newScriptObj) {
        script[key] = newScriptObj[key];
      }
      if (newScriptObj.content) {
        script.el.classList.remove('is-empty');
        script.el.innerText = ` “${newScriptObj.content}” `;
      }
      keyScripts.value.splice(keyScripts.value.indexOf(script), 1, script);
    }
  };
  const recoverScript = id => {
    if (keyScripts.value.find(script => script.id === id)) return;
    const script = scriptCache.find(script => script.id === id);
    if (script) {
      keyScripts.value.push(script);
    }
  };
  const pasteScript = (newId, oldId) => {
    const script = scriptCache.find(script => script.id === oldId);
    if (script) {
      updateScript(newId, {
        content: script.content,
        replies: (0, _lodash.cloneDeep)(script.replies)
      });
    }
  };
  const setScripts = _scripts => {
    const scripts = _scripts.map(script => {
      script.disabled = false;
      return script;
    });
    keyScripts.value = scripts;
    scriptCache.push(...scripts);
  };
  const removeScript = id => {
    keyScripts.value = keyScripts.value.filter(script => script.id !== id);
  };
  const formatScripts = (0, _vue.computed)(() => keyScripts.value.map(_ref4 => {
    let {
      id,
      content,
      replies
    } = _ref4;
    return {
      id,
      content,
      replies
    };
  }));
  return {
    keyScripts,
    formatScripts,
    addScriptReply,
    addScript,
    setScripts,
    updateScript,
    pasteScript,
    recoverScript,
    removeScript
  };
}
function useActivateScript(activeSetting) {
  const activeScriptId = (0, _vue.ref)(0);
  // 点击非关键问题设置时使其他失焦
  document.body.addEventListener('click', e => {
    const scriptSetDom = document.querySelector('.key-script-set');
    if (scriptSetDom && !scriptSetDom.contains(e.target) && !e.target.classList.contains('prompt-editor-active-script-tag') && e.target !== document.body) {
      activeScriptId.value = 0;
    }
  });
  (0, _vue.watch)(activeScriptId, id => {
    const tagEl = document.querySelector(`.prompt-editor-active-script-tag[data-script-id="${id}"]`);
    if (tagEl && !(0, _commonFunc.isInViewPortOfOne)(tagEl)) {
      tagEl.scrollIntoView();
    }
    const scriptItemEl = document.querySelector(`.key-script-set .script-item[data-script-id="${id}"]`);
    if (scriptItemEl && !(0, _commonFunc.isInViewPortOfOne)(scriptItemEl)) {
      scriptItemEl.scrollIntoView();
    }
    if (!activeSetting.value.includes('keyScriptSetting') && id) {
      activeSetting.value.push('keyScriptSetting');
    }
  });
  return {
    activeScriptId
  };
}
function usePromptTextBlocks() {
  const inputRef = (0, _vue.reactive)({
    customer_info: null,
    progress: null,
    etc: null
  });
  const promptTextBlocks = (0, _vue.ref)([{
    name: '客户信息提示词',
    key: 'customer_info',
    placeholder: {
      empty: '',
      title: '在此输入客户信息提示词标题',
      content: '在此输入角色定义和基本信息提示词'
    },
    disabled: false,
    content: '',
    wordCount: 0,
    isSwitchDisabled: true,
    isShow: true
  }, {
    name: '对话流程提示词',
    key: 'progress',
    tip: '输入 “/” 可添加用于练习后评分的AI客户关键问题',
    placeholder: {
      empty: '',
      title: '在此输入对话流程提示词标题',
      content: '在此输入对话流程提示词'
    },
    content: '',
    wordCount: 0,
    disabled: false,
    isSwitchDisabled: true,
    isShow: true
  }, {
    name: '其他提示词',
    placeholder: {
      empty: '在此输入其他提示词',
      title: '',
      content: ''
    },
    key: 'etc',
    content: '',
    wordCount: 0,
    disabled: false,
    isSwitchDisabled: false,
    isShow: false
  }]);
  const setPromptTextBlocks = (obj, scripts) => {
    promptTextBlocks.value.forEach(async block => {
      var _inputRef$key;
      const {
        key
      } = block;
      if (!obj[key]) return;
      block.content = transSharpToSpan(obj[key]);
      if (scripts) {
        block.content = transScriptMarkToSpan(block.content, scripts);
      }
      (_inputRef$key = inputRef[key]) === null || _inputRef$key === void 0 ? void 0 : _inputRef$key.setPrompt(block.content);
      if (scripts) {
        await (0, _vue.nextTick)();
        scripts.forEach(script => {
          var _inputRef$key2;
          const el = document.querySelector(`.prompt-editor-active-script-tag[data-script-id="${script.id}"]`);
          (_inputRef$key2 = inputRef[key]) === null || _inputRef$key2 === void 0 ? void 0 : _inputRef$key2.formatScriptTag(el);
          script.el = el;
        });
      }
    });
  };
  function transSharpToSpan(text) {
    return text.replace(/^## /, '<span contenteditable=false data-ispromptpre="true">## </span>');
  }
  function transScriptMarkToSpan(text, scripts) {
    const scriptIds = scripts.map(script => script.id);
    const scriptMarkReg = new RegExp(`\\[\\[script\\]\\](${scriptIds.join('|')})\\[\\[/script\\]\\]`, 'g');
    return text.replace(scriptMarkReg, (match, p1) => {
      const script = scripts.find(script => script.id === Number(p1));
      return `<span class="prompt-editor-active-script-tag" contenteditable=false data-script-id="${p1}"> “${script.content}” </span>`;
    });
  }
  const formatPromptText = (0, _vue.computed)(() => {
    const obj = {};
    promptTextBlocks.value.forEach(block => {
      const div = document.createElement('div');
      div.innerHTML = block.content;
      const scriptTags = div.querySelectorAll('span[data-script-id]');
      Array.from(scriptTags).forEach(tag => {
        tag.innerText = `[[script]]${tag.dataset.scriptId}[[/script]]`;
      });
      obj[block.key] = div.innerText;
    });
    return obj;
  });
  const bindPromptRef = (el, key) => {
    inputRef[key] = el;
  };
  const totalWordCount = (0, _vue.computed)(() => promptTextBlocks.value.reduce((total, block) => total + block.wordCount, 0));
  return {
    inputRef,
    totalWordCount,
    bindPromptRef,
    promptTextBlocks,
    formatPromptText,
    setPromptTextBlocks
  };
}

// 评分指标
function useScoreMetrics() {
  const scoreMetrics = (0, _vue.ref)([]);
  const addScoreMetric = () => {
    scoreMetrics.value.push({
      name: '',
      weight: 0,
      id: (0, _nanoid.nanoid)()
    });
  };
  const removeScoreMetric = id => {
    scoreMetrics.value = scoreMetrics.value.filter(m => m.metrics_id !== id);
  };
  return {
    scoreMetrics,
    addScoreMetric,
    removeScoreMetric
  };
}
function useTextHandlers() {
  const getValidTextLength = text => {
    return text.replace(/[ \n]/g, '').length;
  };
  return {
    getValidTextLength
  };
}