"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TricksScene',
  props: {
    value: {
      type: String,
      default: ''
    },
    displayOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputVisible: false
    };
  },
  methods: {
    handleTagClick() {
      if (this.displayOnly) return;
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs['saveTagInput'].focus();
      });
    },
    handleInputConfirm() {
      this.inputVisible = false;
    }
  }
};
exports.default = _default;