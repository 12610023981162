"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'LogTable',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 210
    };
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 210;
    },
    handleSortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.$emit('sortChange', prop, order);
    }
  }
};
exports.default = _default;