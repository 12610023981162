"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$configs$conversat;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      trigger: "hover",
      placement: _vm.placement,
      "popper-class": "config-detail-show"
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "config-name"
  }, [_vm._v(_vm._s(_vm.configName) + " - 字段配置")]), _c("div", {
    staticClass: "configs-container"
  }, [_c("div", {
    staticClass: "target-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("指标类型")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "target"
  }, [_vm._v(_vm._s(_vm.getTargetTypeLable))])])]), _c("div", {
    staticClass: "target-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("指标项")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "target"
  }, [_vm._v(_vm._s(_vm.getTargetKeyLable))]), _vm.configs.analysis_target !== "conversation" && _vm.configs.analysis_target_key && _vm.configs.analysis_target_key !== "deal_count" ? _c("div", {
    staticClass: "value"
  }, [["business_trans", "key_event", "conv_event"].includes(_vm.configs.analysis_target_key) ? _vm._l(_vm.configs.analysis_target_vals.filter(item => item.keyEventLabel), function (item, index) {
    return _c("span", {
      key: index,
      staticClass: "item"
    }, [_vm._v(_vm._s(item.keyEventLabel))]);
  }) : _vm.configs.analysis_target_key === "high_customer" ? _c("span", {
    staticClass: "item"
  }, [_vm._v(_vm._s(_vm.configs.analysis_target_vals ? "是" : "否"))]) : _c("span", {
    staticClass: "item"
  }, [_vm._v(" " + _vm._s(_vm.getAnalysisTargetValsToLabels) + " ")])], 2) : _vm._e()])]), _vm.hasConversationType ? _c("div", {
    staticClass: "target-type conversation-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("会话类型")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "value"
  }, _vm._l(_vm.configs.conversation_type, function (item, index) {
    return _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(_vm.isDimensionTarget && item === "doc"),
        expression: "!(isDimensionTarget && item === 'doc')"
      }],
      key: index,
      staticClass: "item"
    }, [_vm._v(_vm._s(_vm.getConversationType(item)))]);
  }), 0)])]) : _vm._e(), _vm.hasConversationType && (_vm$configs$conversat = _vm.configs.conversation_scene_type) !== null && _vm$configs$conversat !== void 0 && _vm$configs$conversat.length ? _c("div", {
    staticClass: "target-type conversation-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("会话场景")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "value"
  }, _vm._l(_vm.configs.conversation_scene_type, function (item, index) {
    return _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(_vm.isDimensionTarget && item === "doc"),
        expression: "!(isDimensionTarget && item === 'doc')"
      }],
      key: index,
      staticClass: "item"
    }, [_vm._v(_vm._s(_vm.getConversationSceneType(item)))]);
  }), 0)])]) : _vm._e(), _vm.configs.analysis_target_key === "count" ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getConvDurationLabel(_vm.configs.conversation_duration),
      expression: "getConvDurationLabel(configs.conversation_duration)"
    }],
    staticClass: "target-type conversation-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("会话时长")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "value",
    domProps: {
      innerHTML: _vm._s(_vm.getConvDurationLabel(_vm.configs.conversation_duration))
    }
  })])]) : _vm._e(), _vm.configs.analysis_target_key === "deal_count" ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getConvDurationLabel(_vm.configs.space_conv_total_duration, true),
      expression: "getConvDurationLabel(configs.space_conv_total_duration, true)"
    }],
    staticClass: "target-type conversation-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("会话总时长")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "value",
    domProps: {
      innerHTML: _vm._s(_vm.getConvDurationLabel(_vm.configs.space_conv_total_duration, true))
    }
  })])]) : _vm._e(), _vm.hasDrawStyle ? _c("div", {
    staticClass: "target-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("统计方式")]), _c("div", {
    staticClass: "target-value"
  }, [_c("div", {
    staticClass: "target"
  }, [_vm._v(" " + _vm._s(_vm.dimensionLabel) + " ")])])]) : _vm._e(), _vm.configs.analysis_target_key === "business_trans" ? _c("div", {
    staticClass: "target-type"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("关键结果")]), _c("div", {
    staticClass: "target-value"
  }, [_vm.configs.strategy.results_type ? _c("div", {
    attrs: {
      "enter-class": "strategy-results-key"
    }
  }, [_vm._v(" " + _vm._s(_vm.getLabelByKey({
    options: _vm.keyEventResultOption,
    findKey: "type",
    value: _vm.configs.strategy.results_type,
    label: "label"
  })) + " ")]) : _vm._e(), _vm.configs.strategy.key && _vm.configs.strategy.results_type === "custom" ? _c("ShowCustomFilter", {
    attrs: {
      "custom-filter-array": _vm.getAllDealCustomerFieldList,
      "custom-filters": {
        [_vm.configs.strategy.key]: _vm.configs.strategy.vals
      }
    }
  }) : _vm._e(), _vm.configs.strategy.results_type === "events" ? _c("div", {
    staticClass: "value"
  }, _vm._l(_vm.configs.strategy.key.filter(item => item.keyEventLabel), function (item, index) {
    return _c("span", {
      key: index,
      staticClass: "item"
    }, [_vm._v(_vm._s(item.keyEventLabel))]);
  }), 0) : _c("div", [_vm._v(" " + _vm._s(_vm.getLabelByKey({
    options: _vm.dealStageOptions,
    findKey: "id",
    value: _vm.configs.strategy.key,
    label: "stage_name"
  })) + " ")])], 1)]) : _vm._e()]), _vm.isHiddenFiltersContainer ? _c("div", {
    staticClass: "target-type filters-container"
  }, [_c("div", {
    staticClass: "type-title"
  }, [_vm._v("筛选条件")]), _c("div", {
    staticClass: "target-value"
  }, [_vm.filtersConfigs.event_filters.keyEventCascaderList && _vm.filtersConfigs.event_filters.keyEventCascaderList.length ? _c("ShowEventTrackerFilter", {
    attrs: {
      "filtes-config": _vm.filtersConfigs.event_filters
    }
  }) : _vm._e(), _vm.filtersConfigs.custom_filters ? _c("ShowCustomFilter", {
    attrs: {
      "custom-filter-array": _vm.getAllDealCustomerFieldList,
      "custom-filters": _vm.filtersConfigs.custom_filters
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "iconfont icon-configuration"
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;