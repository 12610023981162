"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "score-rules-config-container"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.scoringRules")))]), _c("el-radio-group", {
    staticClass: "rule-type-group",
    on: {
      change: _setup.toggleRuleType
    },
    model: {
      value: _setup.configs.scoreRuleType,
      callback: function ($$v) {
        _vm.$set(_setup.configs, "scoreRuleType", $$v);
      },
      expression: "configs.scoreRuleType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.scoreByKeyPoints")))]), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.noScoring")))])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.configs.scoreRuleType === 1,
      expression: "configs.scoreRuleType === 1"
    }],
    staticClass: "config-container"
  }, [_c("p", {
    staticClass: "score-tip"
  }, [_vm._v(_vm._s(_vm.$t("coaching.courseUnavailableTip")))]), _vm._l(_setup.configs.scoreRules, function (ruleItem, index) {
    return _c("div", {
      key: index,
      staticClass: "rule-item-container"
    }, [_c("el-input", {
      attrs: {
        maxlength: "300",
        type: "textarea",
        resize: "none",
        autosize: {
          minRows: 1,
          maxRows: 5
        },
        placeholder: _vm.$t("coaching.scorePointPlaceholder")
      },
      on: {
        change: _setup.changeScoreRule
      },
      model: {
        value: ruleItem.text,
        callback: function ($$v) {
          _vm.$set(ruleItem, "text", $$v);
        },
        expression: "ruleItem.text"
      }
    }), _c("div", {
      staticClass: "score-separator"
    }, [_c("el-input", {
      staticClass: "score-input",
      attrs: {
        oninput: "value=value.replace(/[^\\d]/g,'')",
        maxlength: "3"
      },
      on: {
        change: _setup.changeScoreRule
      },
      model: {
        value: ruleItem.score,
        callback: function ($$v) {
          _vm.$set(ruleItem, "score", _vm._n($$v));
        },
        expression: "ruleItem.score"
      }
    }, [_c("span", {
      attrs: {
        slot: "suffix"
      },
      slot: "suffix"
    }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])]), _c("i", {
      staticClass: "iconfont icon-delete",
      on: {
        click: function ($event) {
          return _setup.deleteRule(index);
        }
      }
    })], 1)], 1);
  }), _c("div", {
    staticClass: "btn-container"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.$t("coaching.salesSaysContentTip"),
      disabled: _setup.aiBtnDisabled
    }
  }, [_c("el-button", {
    class: ["add-ai-btn", {
      "loading-add-ai-btn": _setup.aiLoading
    }],
    attrs: {
      size: "medium",
      icon: _setup.aiLoading ? "" : "iconfont icon-magic-1",
      disabled: _setup.aiLoading || !_setup.configs.branches[0].text
    },
    on: {
      click: _setup.addAiScoreRule
    }
  }, [_setup.aiLoading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.aiLoading,
      expression: "aiLoading"
    }],
    staticClass: "loading-container"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t(_setup.aiLoading ? "coaching.generating" : "coaching.aiGenerateKeyPoint")))])], 1), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.configs.scoreRules.length < 10,
      expression: "configs.scoreRules.length < 10"
    }],
    staticClass: "add-rule",
    attrs: {
      size: "medium",
      icon: "iconfont icon-plus"
    },
    on: {
      click: _setup.addCustomScoreRule
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.addKeyPoint")))])], 1)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;