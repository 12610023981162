"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _elementUi = require("element-ui");
var _i18n = require("@/i18n");
var _default = {
  __name: 'GroupImportButton',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['getImportMemberList', 'updateTableData'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const drawerVisible = (0, _vue.ref)(false);
    function openDialog() {
      drawerVisible.value = true;
    }
    (0, _vue.watch)(drawerVisible, val => {
      if (val) {
        emit('updateTableData');
      }
    });
    async function deleteGroup(item) {
      const res = await (0, _coachingCenter.deleteMemberGroup)({
        id: item.id
      });
      if (res.code === 20000) {
        _elementUi.Message.success(`${(0, _i18n.t)('coaching.deletedSuccessfully')}`);
        emit('updateTableData');
      }
    }
    const activeGroupId = (0, _vue.ref)(0);
    async function importGroup(item) {
      activeGroupId.value = item.id;
      const res = await (0, _coachingCenter.getMemberGroupMemberList)({
        id: item.id,
        type: JSON.stringify(['training_edit'])
      }).finally(() => {
        activeGroupId.value = 0;
      });
      if (res.code === 20000) {
        const ids = res.results.user_ids;
        if (!(ids !== null && ids !== void 0 && ids.length)) {
          _elementUi.Message.warning(`${(0, _i18n.t)('coaching.因成员或权限变动，暂无可用执行人')}`);
          return;
        }
        emit('getImportMemberList', ids);
        drawerVisible.value = false;
      }
    }
    function closeDialog() {
      drawerVisible.value = false;
    }
    return {
      __sfc: true,
      props,
      drawerVisible,
      openDialog,
      deleteGroup,
      emit,
      activeGroupId,
      importGroup,
      closeDialog,
      t: _i18n.t
    };
  }
};
exports.default = _default;