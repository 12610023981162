"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CustomAssignTable',
  props: {
    memberList: {
      type: Array,
      default: () => []
    },
    lowestRatio: {
      type: [Number, String],
      default: 0
    }
  },
  emits: 'changeRatio',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const localMemberList = (0, _vue.ref)([]);
    (0, _vue.watch)(() => props.memberList, val => {
      localMemberList.value = (0, _cloneDeep.default)(val);
    }, {
      immediate: true
    });
    const totalRatio = (0, _vue.computed)(() => {
      return localMemberList.value.reduce((total, item) => {
        const radio = item.ratio ? Number(item.ratio) : 0;
        return total + radio;
      }, 0);
    });
    const errorRatio = ratio => {
      return ratio > 100 || ratio < props.lowestRatio;
    };
    const changeRatio = item => {
      emit('changeRatio', item, totalRatio.value);
    };
    return {
      __sfc: true,
      props,
      localMemberList,
      totalRatio,
      errorRatio,
      emit,
      changeRatio
    };
  }
};
exports.default = _default;