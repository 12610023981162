"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "before-close": _vm.handleClose,
      width: "60%",
      "custom-class": "smart-training-dialog"
    }
  }, [_c("header", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("MgAutoTooltip", {
    staticClass: "title",
    attrs: {
      content: _vm.$t("coaching.newScriptPracticeCourse")
    }
  }), _c("el-input", {
    staticClass: "course-search",
    attrs: {
      placeholder: _vm.$t("coaching.search"),
      clearable: "",
      "prefix-icon": "iconfont icon-search"
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), !_vm.filterCourseList.length && _vm.searchValue ? _c("NoData", {
    staticClass: "no-data",
    attrs: {
      icon: "icon-a-Property1not-found",
      tips: _vm.$t("coaching.noSearchResult")
    }
  }) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getCourseListLoading,
      expression: "getCourseListLoading"
    }],
    staticClass: "smart-training-dialog-container"
  }, [!_vm.searchValue ? _c("div", {
    staticClass: "course-card new-course",
    on: {
      click: function ($event) {
        return _vm.goSmartTrainingEdit(undefined);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.newBlankCourse")))])]) : _vm._e(), _vm._l(_vm.filterCourseList, function (_ref) {
    let {
      id,
      name,
      description,
      is_edit
    } = _ref;
    return _c("div", {
      key: id,
      staticClass: "course-card"
    }, [_c("MgAutoTooltip", {
      staticClass: "course-title",
      attrs: {
        content: name
      }
    }), _c("MgAutoTooltip", {
      staticClass: "description",
      attrs: {
        content: description || "-"
      }
    }), _c("img", {
      attrs: {
        src: require("@/assets/coach/smart-training-graph.png")
      }
    }), _c("div", {
      staticClass: "operate-btns"
    }, [_c("el-button", {
      on: {
        click: function ($event) {
          return _vm.deleteTemplate(id);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("coaching.delete")))]), _c("el-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.goSmartTrainingEdit(id);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("coaching.useTemplate")))])], 1), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("coaching.编辑模板公开范围"),
        effect: "dark",
        placement: "top"
      }
    }, [_c("MgvButton", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: is_edit,
        expression: "is_edit"
      }],
      staticClass: "edit-range-btn",
      attrs: {
        type: "text",
        color: "info",
        icon: "icon-unlock"
      },
      on: {
        click: function ($event) {
          return _vm.openPublicScopeDialog(id);
        }
      }
    })], 1)], 1);
  })], 2), _c("SetPublicScopeDialog", {
    attrs: {
      "is-public": _vm.isPublic,
      "backfill-list": _vm.backfillList,
      "append-to-body": true,
      visible: _vm.editPublicScopeDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.editPublicScopeDialogVisible = $event;
      },
      closeDialog: function ($event) {
        _vm.editPublicScopeDialogVisible = false;
      },
      submitEditPublicScope: _vm.handleSubmitEditPublicScope
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;