"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "data-overview"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("目标群体占比")]), _c("div", {
    staticClass: "container"
  }, [_c("el-progress", {
    staticClass: "left",
    attrs: {
      type: "circle",
      width: 70,
      "stroke-width": 8,
      percentage: _vm.getPercentage
    }
  }), _c("div", {
    staticClass: "right"
  }, [_c("p", {
    staticClass: "total"
  }, [_c("span", {
    staticClass: "main-num"
  }, [_vm._v(_vm._s(_vm.overViewData.count || 0)), _vm._m(0)]), _c("span", [_vm._v("共" + _vm._s(_vm.overViewData.all_count || 0) + "个")])]), _c("p", {
    staticClass: "describe"
  }, [_vm._v(" 目标群体在所筛选全部交易中占比：" + _vm._s(_vm.getPercentage) + "% ")])])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_vm._v("个 "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;