"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "other-setting"
  }, [_c(_setup.SettingSubtitle, {
    attrs: {
      title: "发言输入方式",
      "sub-title": "设置学员发言的输入方式"
    }
  }), _c("el-radio-group", {
    staticClass: "radio-container",
    model: {
      value: _setup.promptStruct.input_mode,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "input_mode", $$v);
      },
      expression: "promptStruct.input_mode"
    }
  }, [_c("el-radio", {
    staticClass: "mg-common-radio",
    attrs: {
      label: "voice"
    }
  }, [_vm._v("语音")]), _c("el-radio", {
    staticClass: "mg-common-radio",
    attrs: {
      label: "text"
    }
  }, [_vm._v("文字")])], 1), _c(_setup.SettingSubtitle, {
    attrs: {
      title: "练习说明",
      "sub-title": "(选填) 在对练页面向学员介绍客户信息、沟通目标等"
    }
  }), _c("el-input", {
    staticClass: "other-setting-input",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      placeholder: "请输入练习说明",
      maxlength: "100",
      resize: "none"
    },
    model: {
      value: _setup.promptStruct.description,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "description", $$v);
      },
      expression: "promptStruct.description"
    }
  }), _c(_setup.SettingSubtitle, {
    attrs: {
      title: "谁先发言",
      "sub-title": "(选填) 设置对话开始时的发言方和发言内容"
    }
  }), _c("el-radio-group", {
    staticClass: "radio-container second-row",
    model: {
      value: _setup.promptStruct.prologue.role,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct.prologue, "role", $$v);
      },
      expression: "promptStruct.prologue.role"
    }
  }, [_c("el-radio", {
    staticClass: "mg-common-radio",
    attrs: {
      label: "customer"
    }
  }, [_vm._v("AI客户先说")])], 1), _c("el-input", {
    staticClass: "other-setting-input",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 10
      },
      maxlength: "300",
      placeholder: "请输入发言内容",
      resize: "none"
    },
    model: {
      value: _setup.promptStruct.prologue.content,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct.prologue, "content", $$v);
      },
      expression: "promptStruct.prologue.content"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;