"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = formatTime;
exports.handleMinutes = handleMinutes;
exports.handlePercentage = handlePercentage;
exports.msgDispatcher = msgDispatcher;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * 全局消息分发
 * 参数说明
 * 其中data是全局的消息对象
 * type为消息的类型值，具体如下分类
 * callBack是需要更新数据的回调，如果只是更新消息中心的红点是否显示，不需要此回调
    # 开始录制
    ‘record_start’
    # 会话转录完成
    ‘record_end’
    # 视频录制结束
   'conversation_completed'
    # 裁剪更新
    'snippet_done'
    ## 以下是消息中心的类型，具体的value是int类型，在接受消息时只识别大类
notify_report_all = 1         # 简报类            大类（type）
notify_daily = 1              # 简报-日报         小类（sub_type）
notify_weekly = 2             # 简报-周报         小类
notify_monthly = 3            # 简报-月报         小类

notify_comment_all = 2        # 评论类            大类
notify_reply = 1              # 评论-回复         小类
notify_appoint = 2            # 评论-@我          小类
notify_comment_general = 3    # 评论-普通评论      小类

notify_coach_all = 3          # 辅导类            大类
notify_coach_apply = 1        # 辅导-申请辅导      小类
notify_coach_suggestions = 2  # 辅导-辅导建议      小类

notify_task_all = 4           # 任务类             大类
notify_task_perform = 1       # 任务-执行任务通知   小类
notify_task_completed = 2     # 任务-完成任务通知   小类

notify_todo_all = 5           # todo类             大类

notify_share_all = 6          # 分享类              大类
notify_share_conversation = 1 # 目前只有会话分享  1个小类
 */
function msgDispatcher(data, type, callBack, cbArguments) {
  if (data.type !== type) {
    return false;
  }
  switch (type) {
    case 'snippet_done':
    case 'notify_csv_created':
      return callBack();
    case 'record_start':
    case 'record_end':
    case 'conversation_completed':
      return callBack(cbArguments);
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      _store.default.dispatch('user/getMessageUnreadCount');
      return callBack ? callBack() : false;
    default:
      return false;
  }
}

// 处理浮点数字为百分比的方法
function handlePercentage(val) {
  const percentage = Math.round(val * 10000) / 100; // 保留两位小数
  return percentage + '%';
}

// 处理传入分钟显示小时和分钟的方法
function handleMinutes(duration) {
  if (duration <= 0) {
    return '0分';
  }
  const hour = Math.floor(duration / 60);
  const minutes = parseInt((duration / 60 - hour) * 60);
  const time = hour ? minutes ? `${hour}小时 ${minutes}分` : `${hour}小时` : `${minutes}分`;
  return time;
}