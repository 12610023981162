"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
var _num_formatter = require("@/utils/num_formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String,
    is_money: Number,
    precisions: [String, Number],
    is_thousand_separate: Number
  },
  data() {
    return {
      isActived: false
    };
  },
  computed: {
    formatNewValue() {
      return this.newValue ? '￥' + (0, _num_formatter.parseFormatNum)(this.newValue, this.precisions, !this.is_thousand_separate) : '-';
    },
    maxlengthLocal() {
      if (this.newValue && this.newValue.includes('.')) {
        return 15;
      }
      return 14;
    }
  },
  methods: {
    handleChange(val) {
      if (this.type === 'floatnumber' || this.type === 'number') {
        val = val.replace(/[^\d\.]/g, '');
        this.newValue = val;
      }
      this.$emit('input', val.trim());
    },
    handleKeypress() {
      this.isActived = false;
      this.$refs.input.blur();
    },
    handleBlur(val) {
      this.isActived = false;
      this.$emit('blur', val);
    },
    handleSimulateBoxClick() {
      this.isActived = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  }
};
exports.default = _default;