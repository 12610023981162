"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFeedback = createFeedback;
exports.uploadFeedbackImages = uploadFeedbackImages;
var _request = require("@/utils/request");
function uploadFeedbackImages(data) {
  return (0, _request.webService)({
    url: 'feedback/image/upload',
    method: 'post',
    data
  });
}
function createFeedback(data) {
  return (0, _request.webService)({
    url: 'feedback/create',
    method: 'post',
    dataType: 'json',
    data
  });
}