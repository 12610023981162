"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _elementUi = require("element-ui");
var _i18n = require("@/i18n");
var _default = {
  __name: 'GroupSaveButton',
  props: {
    membersIds: {
      type: Object,
      default: () => {
        return {
          user_ids: [],
          tree_ids: []
        };
      }
    },
    isExceededLimit: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const hasNoMemeber = (0, _vue.computed)(() => {
      const obj = props.membersIds;
      // 检查user_ids和tree_ids是否为null或undefined
      if (!obj.user_ids && !obj.tree_ids) {
        return true; // 如果其中任意一个为null或undefined，则代表没有数据
      }
      // 检查user_ids和tree_ids是否都是空数组
      if (obj.user_ids.length === 0 && obj.tree_ids.length === 0) {
        return true; // 如果都是空数组，则代表没有数据
      }

      return false; // 其他情况都代表有数据
    });

    const drawerVisible = (0, _vue.ref)(false);
    function saveToMemberGroup() {
      drawerVisible.value = true;
    }
    const form = (0, _vue.ref)({
      name: ''
    });
    const rules = (0, _vue.ref)({
      name: [{
        required: true,
        message: '请输入名称',
        trigger: 'change'
      }]
    });
    const formRef = (0, _vue.ref)(null);
    (0, _vue.watch)(drawerVisible, async () => {
      if (drawerVisible.value) {
        var _formRef$value, _formRef$value2;
        await (0, _vue.nextTick)();
        (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.clearValidate();
        (_formRef$value2 = formRef.value) === null || _formRef$value2 === void 0 ? void 0 : _formRef$value2.resetFields();
        form.value.name = '';
      }
    });
    const loading = (0, _vue.ref)(false);
    function closeDialog() {
      drawerVisible.value = false;
    }
    function submit() {
      formRef.value.validate(async valid => {
        if (valid) {
          loading.value = true;
          const {
            user_ids,
            tree_ids
          } = props.membersIds;
          const res = await (0, _coachingCenter.createMemberGroup)({
            user_ids,
            tree_ids,
            name: form.value.name
          }).finally(() => {
            loading.value = false;
          });
          if (res.code === 20000) {
            _elementUi.Message.success(`${(0, _i18n.t)('coaching.savedSuccessfully')}`);
            loading.value = false;
            drawerVisible.value = false;
          }
        }
      });
    }
    return {
      __sfc: true,
      props,
      hasNoMemeber,
      drawerVisible,
      saveToMemberGroup,
      form,
      rules,
      formRef,
      loading,
      closeDialog,
      submit,
      t: _i18n.t
    };
  }
};
exports.default = _default;