"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conv-flow-detail"
  }, [_c("folder-header", {
    staticClass: "detail-header",
    attrs: {
      folder: _vm.curFolder,
      breadcrumbs: _vm.breadcrumbs
    },
    on: {
      breadClick: _vm.handleNodeClick,
      uploadFile: function ($event) {
        return _vm.$emit("uploadFile");
      }
    }
  }, [_c("conv-flow-dropdown", {
    staticClass: "operate-item",
    attrs: {
      slot: "more-operate"
    },
    on: {
      command: function ($event) {
        return _vm.handleConvFlowCommand($event, _vm.curFolder);
      }
    },
    slot: "more-operate"
  })], 1), _c("div", {
    staticClass: "conversation-part"
  }, [_c("div", [_c("div", {
    staticClass: "filters-part"
  }, [_c("saved-filters-content", {
    attrs: {
      filter: _vm.filters,
      "stage-options": _vm.stageOptions,
      "custom-filter-array": _vm.customFilterArray
    }
  }), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "符合筛选条件的会话自动加入到这个智能会话流中",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "conversation-title"
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera"
  }), _c("span", {
    staticClass: "title-content"
  }, [_c("span", {
    staticClass: "conversation-number"
  }, [_vm._v(_vm._s(_vm.getTotalString))]), _vm._v("个会话 ")])])]), _c("div", {
    staticClass: "conversations-container"
  }, [!_vm.conversationList || !_vm.conversationList.length ? _c("div", {
    staticClass: "no-data-container"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-empty"
  }), _c("p", {
    staticClass: "no-data-tip"
  }, [_vm._v(" 暂无会话满足该智能会话流筛选条件或您无权限查看 ")])]) : _c("ul", _vm._l(_vm.conversationList, function (item) {
    return _c("li", {
      key: item.id
    }, [_c("card-item", {
      attrs: {
        item: item
      }
    }), _c("more-operations", {
      directives: [{
        name: "api-permission",
        rawName: "v-api-permission",
        value: ["/webapi/training/library/folder/smart/create"],
        expression: "[\n              '/webapi/training/library/folder/smart/create'\n            ]"
      }],
      staticClass: "operate-btn",
      attrs: {
        list: _vm.conversationOperateList,
        "menu-class": "snippets-library-dropdown-menu-common"
      },
      on: {
        command: function ($event) {
          return _vm.handleConversationCommand(item, $event);
        }
      }
    })], 1);
  }), 0), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasMore,
      expression: "hasMore"
    }],
    staticClass: "view-all"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      plain: "",
      size: "min"
    },
    on: {
      click: _vm.goConversationList
    }
  }, [_vm._v("查看全部"), _c("i", {
    staticClass: "iconfont icon-top-right"
  })])], 1)])]), _c("snippet-video-dialog", {
    attrs: {
      title: "添加到文件夹",
      "snippet-type": "all",
      "snippet-video-dialog-visible": _vm.snippetVideoDialogVisible,
      "conversation-id": _vm.activeConversation.id,
      "media-type": _vm.activeConversation.conversation_type
    },
    on: {
      closeDialog: _vm.handleCloseSnippetVideoDialog,
      snippetWordSuccess: _vm.handleCloseSnippetVideoDialog
    }
  }, [_c("div", {
    staticClass: "append-tip",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("p", {
    staticClass: "tip-content"
  }, [_vm._v(" 该操作会把会话的全部内容添加到目标文件夹中，若您想对该会话进行剪辑请选择去剪辑。 ")])])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;