"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDealContactList = getDealContactList;
exports.getExternalContactList = getExternalContactList;
exports.mergeContact = mergeContact;
exports.revertMergence = revertMergence;
var _request = require("@/utils/request");
// 获取用户企微客户列表
function getExternalContactList(data) {
  return (0, _request.request)({
    url: '/app/we_work/external_contact_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 通过模糊查询客户名称和手机号，获取系统内的商机客户列表
function getDealContactList(params) {
  return (0, _request.request)({
    url: '/app/we_work/deal_contact_list',
    method: 'get',
    params
  });
}

// 用户合并企微客户接口
function mergeContact(data) {
  return (0, _request.request)({
    url: '/app/we_work/merge_contact',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 撤销合并
function revertMergence(data) {
  return (0, _request.request)({
    url: '/app/we_work/merge_revert',
    method: 'post',
    dataType: 'json',
    data
  });
}