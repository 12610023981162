"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-cascader", {
    ref: "key-event-cascader",
    staticClass: "filter-cascader",
    attrs: {
      value: _vm.value,
      placeholder: "请输入关键事件",
      "popper-class": "single-key-event-cascader",
      options: _setup.cascaderOptions,
      props: _setup.defaultProps,
      filterable: "",
      "show-all-levels": false,
      clearable: ""
    },
    on: {
      input: _setup.onInput,
      change: _setup.onChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "key-event-custom-node"
        }, [_c("span", {
          staticClass: "key-event-name"
        }, [_vm._v(_vm._s(data.name))]), data.data_scope ? _c(_setup.KeyEventDataScope, {
          attrs: {
            scope: data.data_scope
          }
        }, [_c("i", {
          staticClass: "iconfont icon-filter",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        })]) : _vm._e()], 1)];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;