"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatBtn = _interopRequireDefault(require("@/components/ChatBtn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChatEntry',
  components: {
    ChatBtn: _ChatBtn.default
  },
  props: {
    disabledKey: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    videoSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disabled: false
    };
  },
  created() {
    this.disabled = JSON.parse(localStorage.getItem(this.disabledKey));
  },
  methods: {
    hideTips() {
      this.disabled = true;
      localStorage.setItem(this.disabledKey, true);
    }
  }
};
exports.default = _default;