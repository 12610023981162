"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'AddFolderDropdown',
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    visible() {
      return this.interfacePermission.createStandardFolder || this.interfacePermission.createConvFlow;
    }
  }
};
exports.default = _default;