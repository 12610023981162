"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _MyTaskDetailContent = _interopRequireDefault(require("./components/MyTaskDetailContent"));
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _workspace = require("@/api/workspace");
var _routeMap = require("@/router/routeMap");
var _index = _interopRequireDefault(require("@/store/index"));
var _RemovedRecordDrawer = _interopRequireDefault(require("../components/RemovedRecordDrawer.vue"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MyTaskDetail',
  components: {
    DetailHeader: _DetailHeader.default,
    MyTaskDetailContent: _MyTaskDetailContent.default,
    RemovedRecordDrawer: _RemovedRecordDrawer.default
  },
  data() {
    return {
      taskId: 0,
      taskName: '',
      scoreRuleList: [],
      evaluationItemList: [],
      filtersObj: {
        workspace_id: 0,
        task_id: 0,
        conversation_name: '',
        status: 0,
        score: [],
        sort_by: [['status', 'ascending']],
        page: 1,
        size: 20
      },
      tableDataList: [],
      total: 0,
      loading: false,
      removedRecordDrawerVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission']),
    samplingType() {
      return this.$route.query.sampling_type;
    },
    sortConfig() {
      const sortable = ['status', 'conversation_time', 'score'];
      if (this.samplingType) {
        // sortable.unshift('conv_name');
      }
      return {
        sortable,
        defaultSort: {
          // prop: this.samplingType ? 'conv_name' : 'status',
          prop: this.samplingType ? '' : 'status',
          order: 'descending'
        }
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.taskId = Number(this.$route.query.id);
          this.filtersObj.task_id = this.taskId;
          this.getScoreRuleList(val);
          this.getEvaluationItemList(val);
          this.initParams(val);
        }
      },
      immediate: true
    }
  },
  async beforeRouteEnter(to, from, next) {
    const urlParamsWorkspaceId = to.query.workspace_id;
    let workspaceItem;
    if (urlParamsWorkspaceId) {
      const res = await (0, _workspace.switchWorkspace)({
        id: Number(urlParamsWorkspaceId)
      });
      workspaceItem = res.results;
      _index.default.dispatch('workspace/changeWorkspace', workspaceItem);
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
    }
    next();
  },
  methods: {
    openRemovedRecordDrawer() {
      this.removedRecordDrawerVisible = true;
    },
    goComplianceMyTask() {
      this.$router.push({
        path: _routeMap.ROUTES.complianceMyTask
      });
    },
    async getScoreRuleList(val) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getScoreRuleList)(data);
      if (res.code === 20000) {
        this.scoreRuleList = res.results.score_rule_filters;
      }
    },
    async getEvaluationItemList() {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getEvaluationItemList)(data);
      if (res.code === 20000) {
        this.evaluationItemList = res.results.evaluation_items;
      }
    },
    initParams(workspaceId) {
      this.filtersObj.workspace_id = workspaceId;
      if (this.samplingType) {
        // const { prop, order } = this.sortConfig.defaultSort;
        // this.filtersObj.sort_by = [[prop, order]];
        this.filtersObj.sort_by = [];
      }
      this.handleFiltersChange(this.filtersObj);
    },
    goComplianceWorkSpace(_ref) {
      let {
        params,
        query
      } = _ref;
      this.$router.push({
        name: 'ComplianceWorkSpace',
        query: {
          ...query,
          task_id: Number(this.$route.query.id),
          sampling_type: this.$route.query.sampling_type || query.sampling_type
        },
        params
      });
    },
    handleFiltersChange(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.getMyConvTaskList(this.filtersObj);
    },
    sortChange(_ref2) {
      let {
        prop,
        order
      } = _ref2;
      this.filtersObj.sort_by = [[prop, order]];
      this.filtersObj.page = 1;
      this.getMyConvTaskList(this.filtersObj);
    },
    async getMyConvTaskList(filters) {
      this.loading = true;
      const requestFilters = (0, _cloneDeep.default)(filters);
      (0, _timezone.convertParams2UTCplus8)(requestFilters);
      try {
        const res = await (0, _compliance.getMyConvTaskList)(requestFilters);
        if (res.code === 20000) {
          const {
            datas,
            total,
            task_name
          } = res.results;
          this.tableDataList = datas;
          this.total = total;
          this.taskName = task_name;
          if (!this.taskName) {
            this.$message.warning('任务已删除');
            this.$router.replace({
              path: _routeMap.ROUTES.complianceMyTask
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    sizeChange(size) {
      this.filtersObj.size = size;
      this.filtersObj.page = 1;
      this.getMyConvTaskList(this.filtersObj);
    },
    currentChange(page) {
      this.filtersObj.page = page;
      this.getMyConvTaskList(this.filtersObj);
    }
  }
};
exports.default = _default;