"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotateResults = useAnnotateResults;
exports.useBatchUpdateDatasetAnnotation = useBatchUpdateDatasetAnnotation;
exports.useExportAnnotationResults = useExportAnnotationResults;
exports.useGetAnnotator = useGetAnnotator;
exports.useGetDataSetAllLabels = useGetDataSetAllLabels;
exports.useOpenAnnotateResultDialog = useOpenAnnotateResultDialog;
var _dataSet = require("@/api/dataSet");
var _lodash = require("lodash");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _vue = require("vue");
var _elementUi = require("element-ui");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
function useAnnotateResults(_ref) {
  let {
    dataSetId,
    activeTabs
  } = _ref;
  const requestParams = (0, _vue.ref)({
    content: '',
    annotator_ids: [],
    status: 0,
    create_at: [],
    update_at: [],
    sort_by: 'create_at',
    sort_order: 'descending'
  });
  const tableResults = (0, _vue.ref)({
    loading: false,
    tableData: [],
    total: 0
  });
  const dataSetStatistics = (0, _vue.ref)({
    annotate_hit_count: 0,
    annotate_miss_count: 0,
    annotate_uncertain_count: 0
  });
  const paginationParams = (0, _vue.ref)({
    page: 1,
    size: 100
  });
  const params = (0, _vue.computed)(() => {
    return {
      dataset_id: dataSetId,
      dataset_data_id: 0,
      label_id: activeTabs.value.id,
      ...requestParams.value,
      annotator_ids: requestParams.value.annotator_ids || [],
      status: requestParams.value.status ? [requestParams.value.status] : []
    };
  });
  const getSentenceOfEventApi = async () => {
    tableResults.value.loading = true;
    const res = await (0, _dataSet.getSentenceOfEvent)({
      ...params.value,
      ...paginationParams.value
    }).finally(() => {
      tableResults.value.loading = false;
    });
    if (res.code === 20000) {
      const {
        annotate_hit_count,
        annotate_miss_count,
        annotate_uncertain_count,
        annotations,
        total
      } = res.results;
      tableResults.value.tableData = annotations;
      tableResults.value.total = total;
      dataSetStatistics.value = {
        annotate_hit_count,
        annotate_miss_count,
        annotate_uncertain_count
      };
    }
  };
  const allAnnotatedIds = (0, _vue.ref)([]);
  const getAnnotatedIdsApi = async () => {
    const res = await (0, _dataSet.getAnnotatedIds)(params.value);
    if (res.code === 20000) {
      allAnnotatedIds.value = res.results.annotation_ids;
    }
  };
  const sizeChange = val => {
    paginationParams.value.size = val;
    paginationParams.value.page = 1;
    getAnnotatedIdsApi();
    getSentenceOfEventApi();
  };
  const currentChange = val => {
    paginationParams.value.page = val;
    getAnnotatedIdsApi();
    getSentenceOfEventApi();
  };
  const filterChange = filters => {
    console.log(filters, 'filters');
    requestParams.value = filters;
    paginationParams.value.page = 1;
    getAnnotatedIdsApi();
    getSentenceOfEventApi();
  };
  const tableSortChange = (prop, order) => {
    requestParams.value.sort_by = prop;
    requestParams.value.sort_order = order;
    paginationParams.value.page = 1;
    getAnnotatedIdsApi();
    getSentenceOfEventApi();
  };
  return {
    dataSetStatistics,
    params,
    allAnnotatedIds,
    sizeChange,
    currentChange,
    filterChange,
    tableSortChange,
    getAnnotatedIdsApi,
    getSentenceOfEventApi,
    tableResults,
    paginationParams
  };
}
function useGetDataSetAllLabels(_ref2) {
  let {
    dataSetId,
    externalLabel = {}
  } = _ref2;
  // 请求当前列表下所有labels
  const allLabelsList = (0, _vue.ref)([]);
  const activeTabs = (0, _vue.ref)({});
  const getDataSetAllLabelsApi = async () => {
    const res = await (0, _dataSet.getDataSetLabels)({
      dataset_id: dataSetId
    });
    if (res.code === 20000) {
      allLabelsList.value = res.results.labels;
      activeTabs.value = !(0, _lodash.isEmpty)(externalLabel) ? externalLabel : res.results.labels[0];
    }
  };
  return {
    allLabelsList,
    activeTabs,
    getDataSetAllLabelsApi
  };
}
function useGetAnnotator(dataSetId) {
  // 获取当前数据集所有标注人
  const annotatorsList = (0, _vue.ref)([]);
  const getAnnotatorsListApi = async () => {
    const res = await (0, _dataSet.getAnnotatorsList)({
      dataset_id: dataSetId
    });
    if (res.code === 20000) {
      annotatorsList.value = res.results.annotators;
    }
  };
  return {
    annotatorsList,
    getAnnotatorsListApi
  };
}
function useBatchUpdateDatasetAnnotation(_ref3) {
  let {
    dataSetId,
    successCb
  } = _ref3;
  const batchUpdateDatasetAnnotationApi = async params => {
    const res = await (0, _rapidCreateModel.batchUpdateDatasetAnnotation)({
      dataset_id: dataSetId,
      ...params
    });
    if (res.code === 20000) {
      successCb();
    }
  };
  return {
    batchUpdateDatasetAnnotationApi
  };
}
function useExportAnnotationResults() {
  const router = (0, _useRouter.useRouter)();
  const goExportRecords = () => {
    router.push({
      path: _routeMap.ROUTES.exportRecords
    });
  };
  const vnode = (0, _vue.h)('div', {
    class: 'notify-content'
  }, [(0, _vue.h)('span', '文件正在准备中，您可以在个人中心 - '), (0, _vue.h)('a', {
    class: 'jump-link',
    on: {
      click: goExportRecords
    }
  }, '导出记录'), (0, _vue.h)('span', '中查看。')]);
  const exportAnnotationResultsApi = async _ref4 => {
    let {
      annotationCount,
      params
    } = _ref4;
    _elementUi.MessageBox.confirm(`确定导出当前 ${annotationCount} 条标注吗？`, '导出标注结果', {
      confirmButtonText: '导出',
      cancelButtonText: '取消'
    }).then(async () => {
      const res = await (0, _dataSet.exportAnnotationResults)(params);
      if (res.code === 20000) {
        if (_elementUi.Notification) {
          _elementUi.Notification.closeAll();
        }
        (0, _elementUi.Notification)({
          title: '导出文件',
          message: vnode,
          duration: 5000,
          customClass: 'conversation-list-export-file-notify'
        });
      }
    });
  };
  return {
    exportAnnotationResultsApi
  };
}
function useOpenAnnotateResultDialog() {
  const annotationResultsDialogVisible = (0, _vue.ref)(false);
  const currentAnnotationResultId = (0, _vue.ref)(0);
  const openAnnotationResultsDialog = row => {
    annotationResultsDialogVisible.value = true;
    currentAnnotationResultId.value = row.id;
  };
  return {
    openAnnotationResultsDialog,
    currentAnnotationResultId,
    annotationResultsDialogVisible
  };
}