"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "org-management-left"
  }, [_c("div", {
    staticClass: "org-management-title"
  }, [_vm.org_display_info.org_logo_url ? _c("img", {
    staticClass: "icon-megaview",
    attrs: {
      src: _vm.org_display_info.org_logo_url,
      width: "30px",
      height: "30px"
    }
  }) : _c("i", {
    staticClass: "iconfont icon-megaview"
  }), _c("div", {
    staticClass: "manage-page-title"
  }, [_vm._v("管理后台")])]), _c("div", {
    staticClass: "management-nav"
  }, _vm._l(_vm.menuMap, function (val, name) {
    return _c("div", {
      key: name,
      staticClass: "nav-group",
      attrs: {
        "data-v-step": name
      }
    }, [_c("div", {
      staticClass: "top-title"
    }, [_vm._v(_vm._s(name))]), _c("el-menu", {
      attrs: {
        "default-active": _vm.activeMenu,
        "text-color": "#576575",
        router: ""
      }
    }, _vm._l(val, function (item) {
      return _c("div", {
        key: item.name
      }, [_c("el-menu-item", {
        attrs: {
          index: item.link
        }
      }, [_c("svg-icon", {
        class: [`icon-${item.meta.icon}`],
        attrs: {
          "icon-class": item.meta.icon
        }
      }), _c("MgAutoTooltip", {
        staticClass: "auto-tooltip",
        attrs: {
          slot: "title",
          content: item.meta.subtitle === "部门和成员" ? _vm.$t("management.部门和成员") : item.meta.subtitle
        },
        slot: "title"
      })], 1)], 1);
    }), 0)], 1);
  }), 0)]), _c("div", {
    staticClass: "org-management"
  }, [_c("div", {
    staticClass: "top-part"
  }, [_c("el-button", {
    staticClass: "go-dashboard-button",
    on: {
      click: _vm.goDashboard
    }
  }, [_vm._v("进入工作台")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("message-center-button"), _c("task-center-button"), _c("el-tooltip", {
    staticClass: "tooltip",
    attrs: {
      slot: "suffix",
      effect: "dark",
      placement: "bottom",
      enterable: true,
      content: "帮助中心"
    },
    slot: "suffix"
  }, [_c("el-button", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "help-center-btn hover-show-bg",
    attrs: {
      icon: "iconfont icon-question3"
    },
    on: {
      click: _vm.goHelpCenter
    }
  })], 1), _c("user-avatar")], 1), _c("div", {
    staticClass: "bottom-part"
  }, [[_c("router-view")]], 2)]), _c("MgvTour", {
    ref: "megaTranTourRef",
    attrs: {
      name: "isShowMegaTranGuide",
      steps: _vm.megaTranStep
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;