"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "multipleTable",
    staticClass: "resigned-member-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "tooltip-effect": "dark"
    }
  }, [_vm._l(_vm.tableHead, function (item, index) {
    return [_c("el-table-column", {
      key: index,
      attrs: {
        prop: item.field_name,
        label: item.label,
        "min-width": item.width || 150,
        fixed: item.fixed
      },
      scopedSlots: _vm._u([item.field_name === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("image-avatar", {
            staticClass: "upper-avatar",
            attrs: {
              name: row.name,
              avatar: row.avatar,
              shape: "circle"
            }
          }), _c("div", {
            staticClass: "user-name"
          }, [_vm._v(_vm._s(row.name))])];
        }
      } : item.field_name === "tel" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "login-account"
          }, [_vm.isTwoFactor ? _c("div", [_vm._v(_vm._s(row.login_account))]) : [row.login_tel ? _c("div", [_c("i", {
            staticClass: "iconfont icon-mobile-phone"
          }), _vm._v(_vm._s(row.login_tel) + " ")]) : _vm._e(), row.login_email ? _c("div", [_c("i", {
            staticClass: "iconfont icon-message"
          }), _vm._v(_vm._s(row.login_email) + " ")]) : _vm._e()]], 2)];
        }
      } : item.field_name === "department" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "user-department"
          }, [!row.department.length ? _c("p", {
            staticClass: "unassigned"
          }, [_vm._v(" " + _vm._s(_vm.$t("management.未分配")) + " ")]) : _vm._l(row.department, function (departmentItem) {
            return _c("p", {
              key: departmentItem.id,
              staticClass: "table-text"
            }, [_vm._v(" " + _vm._s(departmentItem.name)), _c("span", {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: departmentItem.is_main,
                expression: "departmentItem.is_main"
              }],
              staticClass: "main-depart"
            }, [_vm._v("（" + _vm._s(_vm.$t("management.主部门")) + "）")])]);
          })], 2)];
        }
      } : item.field_name === "workspace" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("div", {
            staticClass: "user-department"
          }, [!row.workspaces.length ? _c("p", {
            staticClass: "unassigned"
          }, [_vm._v(" " + _vm._s(_vm.$t("management.未分配")) + " ")]) : _vm._e(), _c("div", {
            staticClass: "workspace"
          }, _vm._l(row.workspaces, function (workspace) {
            return _c("p", {
              key: workspace.id,
              staticClass: "table-text"
            }, [_c("i", {
              staticClass: "iconfont icon-workspace-3-fill",
              style: {
                color: workspace.icon_color,
                "margin-right": "4px"
              }
            }), _vm._v(" " + _vm._s(workspace.name)), _c("span", {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: workspace.is_main,
                expression: "workspace.is_main"
              }],
              staticClass: "main-depart"
            }, [_vm._v("（" + _vm._s(_vm.$t("management.主空间")) + "）")])]);
          }), 0)])];
        }
      } : item.field_name === "operate" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("el-button", {
            staticClass: "data-transfer-btn",
            attrs: {
              type: "text"
            },
            on: {
              click: function ($event) {
                return _vm.debounceOpenDataTransferDrawer(row);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t("management.转移数据")))])];
        }
      } : null], null, true)
    })];
  })], 2), _c("data-transfer-drawer", {
    attrs: {
      visible: _vm.dataTransferDrawerVisible,
      "transfer-type": "user",
      title: _vm.$t("management.转移成员数据"),
      "transfer-from-user-info": _vm.transferFromUserInfo,
      "can-transfer-data": _vm.userCanTransferData
    },
    on: {
      "update:visible": function ($event) {
        _vm.dataTransferDrawerVisible = $event;
      },
      refresh: _vm.handleRefresh
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;