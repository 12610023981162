"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["question-card", {
      active: _vm.currentQuestion.id === _vm.question.id
    }],
    on: {
      click: _vm.handleClick
    }
  }, [_c("div", {
    class: ["order", _vm.question.rank < 100 ? `order-${_vm.question.rank}` : "no-background-color"]
  }, [_vm._v(" " + _vm._s(_vm.question.rank) + " ")]), _c("div", {
    staticClass: "question-container"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "name-info"
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.question.name
    }
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.question.name))])]), _vm.question.status ? [_vm.question.status === "new" ? _c("span", {
    staticClass: "type new"
  }, [_vm._v("new")]) : _c("el-tooltip", {
    attrs: {
      content: _vm.statusConfig.tip,
      placement: "top"
    }
  }, [_c("i", {
    class: ["iconfont", _vm.statusConfig.icon]
  })])] : _vm._e(), _vm.question.q_type === 2 ? _c("span", {
    staticClass: "type create"
  }, [_vm._v("用户创建")]) : _vm._e()], 2), _c("div", {
    staticClass: "describe"
  }, [_c("span", [_vm._v("出现频率：")]), _c("el-tooltip", {
    attrs: {
      content: _vm.dealFreqTooltip,
      placement: "top"
    }
  }, [_c("span", {
    staticClass: "appear-count"
  }, [_vm._v(_vm._s(_vm.question.deal_freq) + "%")])]), _c("span", {
    staticClass: "appear-precent"
  }, [_vm._v("问题占比：" + _vm._s(_vm.question.occ_percent + "%"))])], 1)])]), _c("more-operations", {
    staticClass: "operate-btn",
    attrs: {
      list: _vm.dropdownList,
      "menu-class": "knowledge-operation-dropdown-menu"
    },
    on: {
      command: function ($event) {
        return _vm.$emit("handleOperation", $event, _vm.question);
      }
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;