"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ValueList = _interopRequireDefault(require("./ValueList"));
var _throttleDebounce = require("throttle-debounce");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SearchContainer',
  components: {
    ValueList: _ValueList.default
  },
  props: {
    popoverVisible: {
      type: Boolean,
      default: false
    },
    selectionList: {
      type: Array,
      default: () => []
    },
    searchOptions: {
      type: Array,
      default: () => []
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    remoteLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: ''
    };
  },
  watch: {
    popoverVisible(val) {
      if (!val) {
        this.searchValue = '';
      } else {
        this.$refs['input-container'].focus();
      }
    },
    searchValue(val) {
      // 解决输入防抖导致在loading前闪一下暂无数据问题
      this.$emit('update:remoteLoading', !!val.length);
      this.$emit('update:isSearching', !!val.length);
    }
  },
  methods: {
    remote: (0, _throttleDebounce.debounce)(300, false, async function (filter) {
      this.$emit('filterChange', filter);
    })
  }
};
exports.default = _default;