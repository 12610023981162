"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    class: ["compliance-status", _vm.getStatusClassName(_vm.status)]
  }, [_c("i", {
    class: ["iconfont", _vm.getIconfont(_vm.status)]
  }), _vm._v(" " + _vm._s(_vm.getComplianceStatus(_vm.status)) + " ")]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;