"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["grade-progress-container", _vm.size, _vm.getTypeClass]
  }, [_c("div", {
    staticClass: "head"
  }, [_c("span", {
    staticClass: "left"
  }, [_vm._v(_vm._s(_vm.score))]), _c("span", {
    staticClass: "right"
  }, [_vm._t("rightTop"), _vm._t("rightBottom")], 2)]), _c("div", {
    staticClass: "progress-bar-outer"
  }, [_c("div", {
    staticClass: "progress-bar-inner",
    style: {
      width: _vm.percentage + "%"
    }
  })]), _vm._t("suffix")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;