"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      width: "240",
      trigger: "hover",
      "popper-class": "study-status-popover",
      "visible-arrow": false
    }
  }, [_c("div", {
    staticClass: "popover-content",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "iconfont icon-checkbox"
  }), _c("span", [_vm._v(_vm._s(_vm.info.waiting_study.length))])]), _c("el-tabs", {
    model: {
      value: _setup.activeName,
      callback: function ($$v) {
        _setup.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_setup.tabsHeaders, function (_ref) {
    let {
      label,
      name
    } = _ref;
    return _c("el-tab-pane", {
      key: name,
      attrs: {
        label: `${label}(${_vm.info[name].length})`,
        name: name
      }
    }, [_vm.info[name].length ? _vm._l(_vm.info[name], function (_ref2, index) {
      let {
        name,
        avatar,
        study_time
      } = _ref2;
      return _c("div", {
        key: index,
        staticClass: "user-info"
      }, [_c("mgv-avatar-name", {
        attrs: {
          name: name,
          src: avatar,
          size: 24
        }
      }), _c("span", [_vm._v(_vm._s(name))]), study_time ? _c("span", {
        staticClass: "study-time"
      }, [_vm._v(_vm._s(_setup.secondToCNTime(study_time, ["小时", "分钟", "秒"])))]) : _vm._e()], 1);
    }) : _c("div", {
      staticClass: "no-data"
    }, [_vm._v("暂无数据")])], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;