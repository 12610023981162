"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _FolderSelect = _interopRequireDefault(require("./FolderSelect.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CopyDataSetDialog',
  props: {
    visible: Boolean,
    dataSetName: String,
    dataSetId: Number,
    folderId: Number
  },
  emits: ['update:visible', 'refreshTable'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const nameValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入数据集名称'));
      } else {
        if (value.length > 20) {
          callback(new Error('最长支持输入20个字符'));
        }
      }
      callback();
    };
    const rules = {
      name: [{
        required: true,
        message: '请输入数据集名称',
        trigger: 'change'
      }, {
        validator: nameValidator
      }],
      folder_id: [{
        required: true,
        message: '请选择文件夹',
        trigger: 'change'
      }]
    };
    const form = (0, _vue.ref)({
      name: '',
      folder_id: '',
      is_copy_annotate_results: true
    });
    const copyDataSetFormRef = (0, _vue.ref)(null);
    const handleFolderChange = val => {
      form.value.folder_id = val;
    };
    const handleClose = () => {
      emit('update:visible', false);
    };
    const initDataSet = () => {
      form.value.name = props.dataSetName + '_副本';
      form.value.folder_id = props.folderId;
    };
    (0, _vue.watchEffect)(() => {
      initDataSet();
    });
    const loading = (0, _vue.ref)(false);
    const handleSubmit = async () => {
      const isPassValid = await copyDataSetFormRef.value.validate();
      if (!isPassValid) return;
      if (loading.value) return;
      loading.value = true;
      const res = await (0, _dataSet.copyDataSet)({
        target_dataset_id: props.dataSetId,
        ...form.value
      }).finally(() => loading.value = false);
      if (res.code === 20000) {
        (0, _elementUi.Message)({
          message: '复制数据集成功',
          type: 'success'
        });
        emit('refreshTable');
        handleClose();
      }
    };
    return {
      __sfc: true,
      props,
      nameValidator,
      rules,
      form,
      copyDataSetFormRef,
      emit,
      handleFolderChange,
      handleClose,
      initDataSet,
      loading,
      handleSubmit,
      FolderSelect: _FolderSelect.default
    };
  }
};
exports.default = _default;