"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-input", _vm._g({
    staticClass: "password-input",
    attrs: {
      value: _vm.value,
      type: _vm.flagType,
      autocomplete: "on",
      name: "password",
      placeholder: _vm.placeholder
    }
  }, _vm.$listeners), [_c("i", {
    class: _vm.flag ? "iconfont icon-view-close" : "iconfont icon-view",
    attrs: {
      slot: "suffix"
    },
    on: {
      click: _vm.getFlag
    },
    slot: "suffix"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;