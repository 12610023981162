"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeleteAnnotation = useDeleteAnnotation;
exports.useFixTrainDataset = useFixTrainDataset;
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _dataSet = require("@/api/dataSet");
var _useMessage = require("@/hooks/use-message");
/* eslint-disable indent */

function useFixTrainDataset(modelInfo) {
  const {
    id: model_id,
    version: model_version
  } = modelInfo;
  const noDataModel = (0, _vue.ref)('precision'); // 提高精确模式precision,提高召回recall
  const contentLoading = (0, _vue.ref)(false);
  const annotationList = (0, _vue.ref)([]);
  const currentResultsRowInfo = (0, _vue.ref)({});
  const fixTrainDatasetDialogVisible = (0, _vue.ref)(false);
  const fixTrainDatasetTableRef = (0, _vue.ref)(null);
  const openFixTrainDatasetDialog = rowInfo => {
    currentResultsRowInfo.value = rowInfo;
    fixTrainDatasetDialogVisible.value = true;
  };

  // 获取训练集相似句错误标注接口
  const getTrainDatasetAnnotationApi = async rowInfo => {
    console.log('getTrainDatasetAnnotationApi', rowInfo, modelInfo);
    const {
      sentence
    } = rowInfo;
    // 处理sentence数据,只保留数组对象中的content和entity_type字段
    const sentenceArray = sentence.map(item => {
      return {
        content: item.content,
        entity_type: item.entity_type
      };
    });
    const data = {
      model_id,
      model_version,
      annotation_status: rowInfo.status || rowInfo.annotation_result,
      sentences: sentenceArray
    };
    contentLoading.value = true;
    const res = await (0, _rapidCreateModel.getTrainDatasetAnnotation)(data).finally(() => contentLoading.value = false);
    if (res.code === 20000) {
      annotationList.value = res.results.similar_annotations;
    }
  };
  const updateAnnotationStatus = async (datasetInfo, annotationInfoArr, status) => {
    const dataset_annotation_ids = annotationInfoArr.map(item => item.id);
    const data = {
      dataset_annotation_ids,
      dataset_id: datasetInfo.dataset_id,
      status
    };
    const res = await (0, _rapidCreateModel.batchUpdateDatasetAnnotation)(data);
    if (res.code === 20000) {
      annotationInfoArr.forEach(item => {
        item.status = status;
      });
      const currentTableIndex = annotationList.value.findIndex(item => item.dataset_id === datasetInfo.dataset_id);
      if (currentTableIndex === -1) return;
      fixTrainDatasetTableRef.value[currentTableIndex].clearSelection();
    }
  };
  return {
    fixTrainDatasetTableRef,
    fixTrainDatasetDialogVisible,
    openFixTrainDatasetDialog,
    currentResultsRowInfo,
    contentLoading,
    noDataModel,
    getTrainDatasetAnnotationApi,
    annotationList,
    updateAnnotationStatus
  };
}
function useDeleteAnnotation(_ref) {
  let {
    successCallBack
  } = _ref;
  const deleteAnnotationApi = async data => {
    const res = await (0, _dataSet.deleteAnnotate)(data);
    if (res.code === 20000) {
      _useMessage.useMessage.success('删除成功');
      await successCallBack();
    }
  };
  return {
    deleteAnnotationApi
  };
}