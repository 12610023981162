import { render, staticRenderFns } from "./KeyScriptReviewNoScene.vue?vue&type=template&id=74780500&scoped=true&"
import script from "./KeyScriptReviewNoScene.vue?vue&type=script&setup=true&lang=js&"
export * from "./KeyScriptReviewNoScene.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./KeyScriptReviewNoScene.vue?vue&type=style&index=0&id=74780500&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74780500",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74780500')) {
      api.createRecord('74780500', component.options)
    } else {
      api.reload('74780500', component.options)
    }
    module.hot.accept("./KeyScriptReviewNoScene.vue?vue&type=template&id=74780500&scoped=true&", function () {
      api.rerender('74780500', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coachTaskPractice/components/KeyScriptReviewNoScene.vue"
export default component.exports