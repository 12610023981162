"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ContactPersonDetail = _interopRequireDefault(require("./components/ContactPersonDetail"));
var _ContactPersonDialog = _interopRequireDefault(require("./components/ContactPersonDialog"));
var _index = _interopRequireDefault(require("@/components/DetailDrawer/index"));
var _ClientForm = _interopRequireDefault(require("./components/ClientForm"));
var _DealDetail = _interopRequireDefault(require("./components/DealDetail"));
var _ConversationDetail = _interopRequireDefault(require("./components/ConversationDetail"));
var _LogRecord = _interopRequireDefault(require("@/components/LogRecord"));
var _client = require("@/api/client");
var _vuex = require("vuex");
var _formatContactFormDescMixins = _interopRequireDefault(require("./formatContactFormDescMixins"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClientDetailDrawer',
  components: {
    ContactPersonDetail: _ContactPersonDetail.default,
    ContactPersonDialog: _ContactPersonDialog.default,
    DetailDrawer: _index.default,
    ClientForm: _ClientForm.default,
    DealDetail: _DealDetail.default,
    ConversationDetail: _ConversationDetail.default,
    LogRecord: _LogRecord.default
  },
  mixins: [_formatContactFormDescMixins.default],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: [Number, String]
    },
    isCallUpAndCreateDealShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: 'track_record',
      recordsActiveName: 'followup_records',
      formData: {},
      dealData: {},
      id: '',
      detailInfo: {},
      contactPersonDialogVisible: false,
      contactFormDesc: [],
      // 操作日志
      pageSize: 10,
      logItemProperties: {
        receive: {
          label: '领取',
          icon: 'iconfont icon-thumb'
        },
        assign: {
          label: '分配',
          icon: 'iconfont icon-team-switch-2'
        },
        modify: {
          label: '变更跟进人',
          icon: 'iconfont icon-change-follower'
        },
        move_to_pool: {
          label: '转移至公海',
          icon: 'iconfont icon-giveup'
        },
        create_account: {
          label: '新建客户',
          icon: 'iconfont icon-office-building'
        },
        create_deal: {
          label: '新建商机',
          icon: 'iconfont icon-deal4'
        },
        converted: {
          label: '线索转客户',
          icon: 'iconfont icon-transform'
        },
        create_contact: {
          label: '新建联系人',
          icon: 'iconfont icon-custom-01'
        },
        edit_contact: {
          label: '编辑联系人',
          icon: 'iconfont icon-edit'
        },
        edit_account: {
          label: '编辑客户',
          icon: 'iconfont icon-edit'
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'contactDynamicFormDescList']),
    title() {
      return '客户详情';
    },
    operateButton() {
      if (this.$route.query.tab === 'accountSea') {
        return [{
          icon: 'iconfont icon-thumb',
          label: '领取',
          action: 'claimToMe',
          permission: '/api/account/account_pool/receive_follow_up_by'
        }, {
          icon: 'iconfont icon-team-switch',
          label: '分配',
          action: 'allocateToAnyone',
          permission: '/api/account/account_pool/assigns_follow_up_by'
        }];
      } else {
        return [{
          icon: 'iconfont icon-plus',
          label: '新建商机',
          action: 'createDeal',
          permission: '/api/account/account/create_deal'
        }, {
          icon: 'iconfont icon-change-follower',
          label: '变更跟进人',
          disabled: !this.formData.has_edit,
          action: 'changeFollowUpBy',
          permission: '/api/account/account_pool/edit_follow_up_by'
        }, {
          icon: 'iconfont icon-giveup',
          label: '转移至公海',
          disabled: !this.formData.has_edit,
          action: 'moveAccountSea',
          permission: '/api/account/account/move_to_pool'
        }, {
          icon: 'iconfont  icon-plus',
          label: '客户联系人',
          action: 'createContactPerson',
          permission: '/api/account/account/contact'
        }];
      }
    },
    contactDynamicFormNoAccountList() {
      return this.contactDynamicFormDescList.filter(item => {
        return item.id !== 'customer_name';
      });
    }
  },
  watch: {
    visible(val) {
      if (val) {
        var _this$$route$query;
        this.init();
        this.activeName = 'track_record';
        this.id = this.accountId;
        // eslint-disable-next-line no-undef
        if (((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.tab) === 'accountSea') {
          this.getAccountSeaDetail();
        } else {
          this.getClientDetail();
        }
      } else {
        var _this$$route, _this$$route$query2;
        // eslint-disable-next-line no-undef
        const res = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query2 = _this$$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.tab;
        this.$router.replace({
          query: {
            tab: res
          }
        });
        this.recordsActiveName = '';
      }
    },
    activeName(val) {
      if (val === 'track_record') {
        this.recordsActiveName = 'followup_records';
      }
    }
  },
  created() {
    this.$store.dispatch('account/getContactDynamicForm');
  },
  methods: {
    accountDetailOperation(operation, ids) {
      this.$emit(operation, ids);
    },
    detailOperations(action) {
      switch (action) {
        case 'callOut':
          this.callOut();
          break;
        case 'createDeal':
          this.createDeal();
          break;
        case 'changeFollowUpBy':
        case 'moveAccountSea':
        case 'claimToMe':
        case 'allocateToAnyone':
          this.$emit(action, [this.formData.id]);
          break;
        case 'createContactPerson':
          this.openContactPersonDialog();
          break;
        default:
          break;
      }
    },
    createDeal() {
      this.$emit('createDeal', {
        id: this.id,
        name: this.formData.name
      });
    },
    callOut() {
      let _data = {};
      _data = {
        id: this.formData.id,
        type: 'account',
        company: this.formData.company,
        contact_id: this.formData.contact_id,
        contact_person: this.formData.name,
        phone: this.formData.tel
      };
      this.$bus.$emit('openPhoneCall', _data);
    },
    init() {
      this.recordsActiveName = 'followup_records';
    },
    getClientDetail() {
      (0, _client.getClientDetail)(this.id).then(res => {
        if (res.code === 200) {
          this.formData = res.results.detail_account;
          this.detailInfo = {
            avatarType: 'customer_contact',
            name: res.results.detail_account.name,
            subLabel: '跟进人：',
            subValue: res.results.detail_account.follow_up_by_name,
            type: 'account'
          };
          this.dealData = res.results.detail_deal;
          if (this.formData.gender) {
            this.formData.gender = [this.formData.gender];
          }
        }
      });
    },
    getAccountSeaDetail() {
      (0, _client.getAccountSeaDetail)(this.id).then(res => {
        if (res.code === 200) {
          this.formData = res.results.detail_account;
          this.detailInfo = {
            avatarType: 'customer_contact',
            name: res.results.detail_account.name,
            subLabel: '跟进人：',
            subValue: res.results.detail_account.follow_up_by_name,
            type: 'account'
          };
          this.dealData = res.results.detail_deal;
          if (this.formData.gender) {
            this.formData.gender = [this.formData.gender];
          }
        }
      });
    },
    freshClientDetail() {
      this.id = this.accountId;
      this.init();
      this.getClientDetail();
    },
    goDeal() {
      this.$router.push({
        path: _routeMap.ROUTES.dealDetail,
        query: {
          id: this.dealData.id
        }
      });
    },
    freshCurrentList() {
      this.$emit('freshCurrentList');
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    switchRecordsTab(tab) {
      this.recordsActiveName = tab;
    },
    //  ============== 联系人 =============
    closeCreateContactPersonDialog() {
      this.contactPersonDialogVisible = false;
      this.$refs['createContactPersonDialog'].formData = {};
    },
    openContactPersonDialog() {
      this.contactPersonDialogVisible = true;
      this.$nextTick(() => {
        this.$refs['createContactPersonDialog'].clearValidate();
      });
      this.contactFormDesc = this.descContactFormat(this.contactDynamicFormDescList, true, this.formData);
    },
    fetchLogMethod() {
      return _client.getClientLogRecords;
    }
  }
};
exports.default = _default;