"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.label,
        name: item.name
      }
    }, [item.name === "todo" ? _c("todo-filter", {
      on: {
        typeChange: _vm.handleTodoTypeChange,
        statusChange: _vm.handleTodoStatusChange
      }
    }) : _vm._e(), item.name === "todo" ? _c("todo-list", {
      attrs: {
        "comment-list": _vm.commentList,
        "active-name": _vm.activeName,
        loading: _vm.loading,
        "list-loading": _vm.listLoading,
        nomore: _vm.pageNomore && !_vm.loading
      },
      on: {
        loadMore: function ($event) {
          return _vm.getCommentList(_vm.activeName);
        },
        handleRead: _vm.handleRead,
        handleIgnore: _vm.handleIgnore,
        delete: _vm.handleDelete,
        refresh: _vm.handleRefresh
      }
    }) : item.name === "meeting" && _vm.activeName === "meeting" ? _c("upcoming-meeting") : _c("comment-list", {
      attrs: {
        "comment-list": _vm.commentList,
        "active-name": _vm.activeName,
        loading: _vm.loading,
        "list-loading": _vm.listLoading,
        nomore: _vm.pageNomore && !_vm.loading
      },
      on: {
        loadMore: function ($event) {
          return _vm.getCommentList(_vm.activeName);
        }
      }
    })], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;