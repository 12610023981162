"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnnotateResultsBatchBtn = _interopRequireDefault(require("./AnnotateResultsBatchBtn.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateResultsFilters',
  props: {
    annotatorsList: {
      type: Array,
      default: () => []
    },
    tableSelectionList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['handleFilterChange', 'updateAnnotate', 'openConversationDrawer', 'exportAnnotationResults'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const filters = (0, _vue.ref)({
      content: '',
      annotator_ids: '',
      create_at: [],
      update_at: [],
      status: ''
    });
    const dateType = (0, _vue.ref)('create_at');
    const dateTime = (0, _vue.ref)([]);
    const statusOptions = [{
      value: 1,
      label: '命中'
    }, {
      value: 2,
      label: '未命中'
    }, {
      value: 3,
      label: '不确定'
    }];
    const handleFilterChange = () => {
      if (dateType.value === 'create_at') {
        filters.value.update_at = [];
        filters.value.create_at = dateTime.value;
      } else {
        filters.value.create_at = [];
        filters.value.update_at = dateTime.value;
      }
      emit('filterChange', filters.value);
    };
    const updateAnnotate = status => {
      emit('updateAnnotate', {
        status,
        dataset_annotation_ids: props.tableSelectionList.map(item => item.id)
      });
    };
    const deleteAnnotation = () => {
      emit('deleteAnnotation', {
        isBatch: true,
        annotationIds: props.tableSelectionList.map(item => item.id)
      });
    };
    const openConversationDrawer = () => {
      emit('openConversationDrawer');
    };
    const exportAnnotationResults = () => {
      emit('exportAnnotationResults');
    };
    return {
      __sfc: true,
      props,
      filters,
      dateType,
      dateTime,
      statusOptions,
      emit,
      handleFilterChange,
      updateAnnotate,
      deleteAnnotation,
      openConversationDrawer,
      exportAnnotationResults,
      AnnotateResultsBatchBtn: _AnnotateResultsBatchBtn.default
    };
  }
};
exports.default = _default;