"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "knowledge-base-permission-drawer",
    attrs: {
      title: "权限设置",
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      size: "600px"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("公开范围")]), _c("el-radio-group", {
    staticClass: "radio-group",
    on: {
      input: _vm.handleRadioChange
    },
    model: {
      value: _vm.publicScope,
      callback: function ($$v) {
        _vm.publicScope = $$v;
      },
      expression: "publicScope"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("仅知识库成员可见")]), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("全公司可见")])], 1), _c("div", {
    staticClass: "member-permission-container"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("成员及权限")]), _c("div", {
    staticClass: "member-select-container"
  }, [_c("div", {
    staticClass: "member-select"
  }, [_c("mgv-member-multi-select", {
    staticClass: "mgv-member-multi-select",
    attrs: {
      "is-prevent-not-selected": false,
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-params": {
        type: JSON.stringify(["training_edit"])
      },
      "show-tag-count": 3,
      "backfill-list": _vm.memberBackfillList
    },
    on: {
      change: _vm.assigneeChange
    }
  }), _c("OperateDropdown", {
    staticClass: "operate-dropdown",
    attrs: {
      list: _vm.baseOperateList
    },
    on: {
      change: _vm.changeMembersPermission
    }
  })], 1), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.addPermission
    }
  }, [_vm._v("添加")])], 1), _c("div", {
    staticClass: "tips"
  }, [_vm._v("以下协作者可访问该知识库")])]), _vm._l(_vm.memberList, function (item, index) {
    return _c("div", {
      key: `${item.id}_${item.type}`,
      staticClass: "member-item"
    }, [_c("mgv-avatar-name", {
      attrs: {
        name: item.name,
        src: item.avatar,
        icon: item.type ? "iconfont icon-department" : "",
        size: 28
      }
    }), _c("span", [_vm._v(_vm._s(item.name))]), !index ? _c("span", {
      staticClass: "create-by"
    }, [_vm._v("所有者")]) : _vm._e(), _c("div", {
      staticClass: "operate"
    }, [!index ? _c("span", {
      staticClass: "permission"
    }, [_vm._v("可管理")]) : _c("OperateDropdown", {
      staticClass: "operate-dropdown-button",
      attrs: {
        list: _vm.memberOperateList(item),
        permission: item.permission
      },
      on: {
        change: function ($event) {
          return _vm.editPermission($event, item);
        }
      }
    })], 1)], 1);
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;