"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _client = require("@/api/client");
var _filterSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/filterSelect"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TricksLibraryFilter',
  components: {
    BiFilter: _filterSelect.default
  },
  props: {
    layout: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dealStageOptions: [],
      defaultMoreFilter: [{
        field_name: 'is_add',
        name: '添加情况',
        show: 0,
        type: 'select',
        options: [{
          label: '未添加',
          value: 0
        }, {
          label: '添加过',
          value: 1
        }]
      }, {
        field_name: 'events',
        name: '关键事件',
        show: 0,
        type: 'cascader'
      }, {
        field_name: 'status',
        name: '交易阶段',
        show: 0,
        type: 'select'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getDealStageOptions();
  },
  methods: {
    showDefaultFilter(val) {
      this.defaultMoreFilter.forEach(item => {
        item.show = val.includes(item.field_name);
      });
    },
    async getDealStageOptions() {
      const res = await (0, _client.getBusinessStatusOptions)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.dealStageOptions = res.results;
        this.defaultMoreFilter.filter(item => {
          if (item.field_name === 'status') {
            this.$set(item, 'options', res.results.map(item => ({
              label: item.stage_name,
              value: item.id
            })));
          }
        });
      }
    },
    handleBiFiltersChange(val) {
      const {
        custom_filters,
        date,
        tree_user_ids: {
          tree_ids,
          user_ids
        }
      } = val;
      // 处理默认筛选条件
      const defaultFilters = {};
      ['is_add', 'events', 'status'].forEach(item => {
        if (Object.hasOwnProperty.call(custom_filters, item)) {
          defaultFilters[item] = custom_filters[item];
          if (item === 'events') {
            defaultFilters[item] = [...new Set(custom_filters[item].flat())];
          }
        }
      });
      if (typeof defaultFilters.is_add === 'string') defaultFilters.is_add = -1;
      this.emitFilters({
        custom_filters,
        date,
        tree_ids,
        user_ids,
        ...defaultFilters
      });
    },
    emitFilters: (0, _commonFunc.throttle)(function (val) {
      this.$emit('filtersChange', val);
    })
  }
};
exports.default = _default;