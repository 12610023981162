"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _timeFormatter = require("@/utils/time-formatter");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvInfo',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes']),
    salesman() {
      if (JSON.stringify(this.info) === '{}') return;
      const salesRoles = ['host_salesman', 'assistant_salesman', 'spectator_salesman'];
      return this.info.participants.find(item => salesRoles.includes(item.entity_type));
    },
    tradeName() {
      return this.info.type_info.name;
    },
    convDuration() {
      var _this$info$video;
      const {
        s,
        m,
        h
      } = (0, _timeFormatter.second2HMS)((_this$info$video = this.info.video) === null || _this$info$video === void 0 ? void 0 : _this$info$video.duration);
      if (h) {
        return m ? `${h}小时${m}分` : `${h}小时`;
      } else if (m) {
        return s ? `${m}分${s}秒` : `${m}分`;
      } else if (s) {
        return `${s}秒`;
      } else {
        return `0秒`;
      }
    },
    convDate() {
      return this.$plus8ToCurrentZone(this.info.date).format('YYYY-MM-DD');
    },
    convTime() {
      return this.$plus8ToCurrentZone(this.info.date).format('HH:mm');
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    }
  },
  methods: {
    goTradePage() {
      if (this.info.type !== 'trade') return;
      const {
        is_access,
        id
      } = this.info.type_info;
      if (!is_access) {
        this.$message.warning('无交易详情查看权限');
        return;
      }
      window.open(`${location.origin}${_routeMap.ROUTES.tradeDetail}?id=${id}&noback=true`);
    }
  }
};
exports.default = _default;