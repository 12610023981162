"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "single-tree-select-container"
  }, [_c("el-select", {
    ref: "select",
    attrs: {
      value: _vm.checkedNode.name,
      placeholder: "选择知识点",
      "popper-class": "single-tree-select-options-container"
    },
    on: {
      focus: _vm.initSearch
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKnowldgeInfoLoading,
      expression: "getKnowldgeInfoLoading"
    }],
    ref: "knowledge-detail-popover",
    class: ["knowledge-detail-popover", {
      show: _vm.showKnowledgePopover
    }]
  }, [_c("knowledge-item", {
    attrs: {
      knowledge: _vm.KnowldgeInfo,
      disabled: ""
    }
  })], 1), _c("div", {
    staticClass: "search-container"
  }, [_c("el-input", {
    attrs: {
      placeholder: "搜索",
      "prefix-icon": "el-icon-search"
    },
    on: {
      input: _vm.filterChange
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchValue.length,
      expression: "searchValue.length"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.searchLoading,
      expression: "searchLoading"
    }],
    staticClass: "search-options-container"
  }, [_vm._l(_vm.searchOptions, function (item) {
    return _c("li", {
      key: item.id,
      on: {
        click: function ($event) {
          return _vm.checkedOptions(item);
        },
        mouseenter: function ($event) {
          return _vm.showDetailPopover($event, item.id);
        },
        mouseleave: _vm.hiddenDetailPopover
      }
    }, [_c("p", {
      domProps: {
        innerHTML: _vm._s(item.highlight_name || item.name)
      }
    }), _c("p", {
      staticClass: "path"
    }, [_vm._v(_vm._s(_vm.getPathText(item.path)))]), _c("i", {
      class: {
        "el-icon-check": _vm.checkedNode.id === item.id
      }
    })]);
  }), _c("li", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchOptions.length,
      expression: "!searchOptions.length"
    }],
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.noMatchingResults")) + " ")])], 2)], 1), _c("el-option", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchValue.length,
      expression: "!searchValue.length"
    }],
    attrs: {
      value: 0
    }
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.treeData,
      "node-key": "id",
      accordion: "",
      "render-content": _vm.renderContent
    },
    on: {
      "node-click": _vm.handleNodeClick
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;