"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: "任务中心",
      visible: _vm.visible,
      "custom-class": "task-center-drawer",
      direction: "rtl",
      size: "800px",
      "append-to-body": "",
      "destroy-on-close": "",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "task-center-container"
  }, [_c("div", {
    staticClass: "left-content-container"
  }, [_c("ul", {
    staticClass: "left-content"
  }, _vm._l(_vm.menuList, function (item) {
    return _c("li", {
      key: item.value,
      staticClass: "task-menu-item",
      class: {
        "actve-menu": item.value === _vm.activeMenu
      },
      on: {
        click: function ($event) {
          return _vm.handleSelect(item);
        }
      }
    }, [_c("div", [_c("i", {
      staticClass: "iconfont",
      class: item.icon
    }), _c("span", {
      staticClass: "task-type"
    }, [_vm._v(_vm._s(item.label))])]), item.count ? _c("span", {
      staticClass: "task-count"
    }, [_vm._v(_vm._s(item.count))]) : _vm._e()]);
  }), 0), _c("el-divider"), _c("div", {
    staticClass: "collapse-tab-container"
  }, [_c("collapse-tab", {
    attrs: {
      title: "团队视图",
      "tab-array": _vm.manageViewTabs
    },
    model: {
      value: _vm.activeManageViewTab,
      callback: function ($$v) {
        _vm.activeManageViewTab = $$v;
      },
      expression: "activeManageViewTab"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.newTagVisible,
      expression: "newTagVisible"
    }],
    staticClass: "new-tag",
    on: {
      click: _vm.hiddenNewTag
    }
  }, [_vm._v(" 新 ")])], 1)], 1), _c("div", {
    staticClass: "right-content"
  }, [_c("div", {
    staticClass: "right-content"
  }, [_c("task-menu-content", {
    ref: "task-menu-content",
    attrs: {
      "current-menu": _vm.activeMenu,
      "current-task-detail-id": _vm.currentTaskDetailId
    },
    on: {
      taskCountsChange: _vm.handleTaskCountsChange,
      clearCurrentTaskDetailId: _vm.clearCurrentTaskDetailId
    }
  })], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;