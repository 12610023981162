"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-reach"
  }, [_vm.kanbanList.length ? _c("BI-kanban", {
    ref: "bi-kanban",
    attrs: {
      "active-id": _vm.currentKanban.kanban_id,
      "extra-command-list": _vm.getExtraCommandlist(),
      "kanban-list": _vm.kanbanList
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }) : _vm._e(), !_vm.getKanbanListLoading && !_vm.kanbanList.length ? _c("init-kanban-card-view", {
    attrs: {
      config: {
        title: "看板",
        tooltipTitle: "客户触达对比看板",
        tooltip: ["选择两个关键事件，比较二者的交易触发情况", "选择部门和成员维度，进行自由的效果比对", "创建成功后，系统将自动生成条形图表，也可根据实际情况自行编辑"]
      }
    },
    on: {
      createKanban: _vm.createKanban
    }
  }, [_c("img", {
    staticClass: "right",
    attrs: {
      slot: "right-img",
      src: require("@/assets/BI/barChart.svg")
    },
    slot: "right-img"
  })]) : _c("chart-container", {
    attrs: {
      title: _vm.currentKanban.name
    }
  }, [!_vm.currentKanban.is_share ? _c("el-button", {
    staticClass: "bi-kanban-edit-btn",
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: function ($event) {
        return _vm.handleKanban("edit", _vm.currentKanban);
      }
    }
  }, [_vm._v("编辑")]) : _vm._e(), _c("chart-template", {
    staticClass: "chart-content",
    attrs: {
      "chart-config": _vm.chartConfig,
      "chart-loading": _vm.getChartDataLoading
    }
  }), !_vm.getChartDataLoading && _vm.tableConfig.heads ? _c("business-chart-table", {
    attrs: {
      "table-config": _vm.tableConfig
    },
    on: {
      handleSortChange: _vm.handleSortChange
    }
  }) : _vm._e()], 1), _c("reach-drawer", {
    ref: "reachDrawer",
    attrs: {
      "is-edit": _vm.isEdit,
      "response-kanban-info": _vm.responseKanbanInfo,
      "drawer-visible": _vm.drawerVisible,
      "get-kanban-config-loading": _vm.getKanbanConfigLoading
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.drawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.drawerVisible = $event;
      },
      handleKanbanSuccess: _vm.getKanbanList
    }
  }), _c("ShareConfig", {
    attrs: {
      visible: _vm.shareKanbanConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        kanban_id: _vm.currentKanban.kanban_id,
        type: 2
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareKanbanConfigVisible = $event;
      },
      handleClose: _vm.changeKanbanStatus
    }
  }), _c("EditKanbanNameDialog", {
    attrs: {
      visible: _vm.copyKanbanConfigVisible,
      "kanban-name": `${_vm.currentKanban.name}_副本`,
      params: {
        id: _vm.currentKanban.kanban_id,
        type: 2
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.copyKanbanConfigVisible = $event;
      },
      copyKanbanSuccess: _vm.getKanbanList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;