"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "precision-detail-overview"
  }, [_c("header", {
    staticClass: "header"
  }, [_vm._t("header-title"), _c("div", {
    staticClass: "version-list"
  }, [_c("el-checkbox-group", {
    attrs: {
      value: _vm.selectedVersions
    },
    on: {
      input: _vm.handleSelectedVersionInput
    }
  }, _vm._l(_vm.modelVersionList, function (item) {
    return _c("div", {
      key: item.version,
      class: ["version", {
        selected: _vm.selectedVersions.includes(item.version)
      }]
    }, [_c("el-checkbox", {
      attrs: {
        label: item.version
      }
    }, [_vm._v(_vm._s(item.name)), item.version === _vm.modelInfo.version ? _c("span", [_vm._v("（当前）")]) : _vm._e()])], 1);
  }), 0)], 1)], 2), _c("PrecisionDetailTable", {
    attrs: {
      "table-data": _vm.tableData,
      "selected-versions": _vm.selectedVersions
    },
    on: {
      openEventAnnotationDetail: _vm.openEventAnnotationDetail
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;