"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "summary-result-display"
  }, [_vm.info.isRuning ? _c("div", {
    staticClass: "run-loading"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/generating.gif")
    }
  }), _c("p", [_vm._v("指令运行中，请稍候几分钟再查看结果")])]) : !_setup.hasResult ? _c("div", {
    staticClass: "no-result"
  }, [_c(_setup.NoData, [_c("p", {
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_c("span", [_vm._v("暂无内容，您是否想 "), _c("span", {
    staticClass: "can-click-text",
    on: {
      click: _setup.manualExecution
    }
  }, [_vm._v("现在运行指令")])])])])], 1) : [_vm.rightBarActive === "AISummary" ? _c("div", {
    staticClass: "ai-summary"
  }, [_c("span", [_vm._v(_vm._s(_vm.info.data))])]) : _vm._e(), _vm.rightBarActive === "extractInfo" ? _c("div", {
    staticClass: "extract-info"
  }, _vm._l(_vm.info.data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "extract-info-item"
    }, [_c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.question_name,
        expression: "item.question_name"
      }],
      staticClass: "extract-info-question"
    }, [_vm._v(" " + _vm._s(item.question_name) + " ")]), _c("p", {
      staticClass: "extract-info-llm_answer"
    }, [_vm._v(_vm._s(item.llm_answer))])]);
  }), 0) : _vm._e()]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;