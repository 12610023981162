"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = require("@/mixin/mixin");
var _conversationList = require("@/api/conversationList");
var _RenameDialog = _interopRequireDefault(require("@/components/CommonComponents/RenameDialog.vue"));
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FilterManageDrawer',
  components: {
    CommonRenameDialog: _RenameDialog.default,
    MgOperateColumn: _MgOperateColumn.default,
    NoData: _NoData.default
  },
  mixins: [_mixin.paginationMixin, _mixin.tableHeightMixin],
  props: {
    filterManageVisible: {
      type: Boolean,
      default: false
    },
    getFiltersMethod: {
      type: Function,
      default: _conversationList.getFilters
    },
    deleteMethod: {
      type: Function,
      default: _conversationList.deleteFilter
    },
    renameMethod: {
      type: Function,
      default: _conversationList.renameFilter
    }
  },
  data() {
    return {
      drawerWidth: '600px',
      filterName: '',
      filterListLoading: false,
      filterList: [],
      total: 2,
      renameDialogVisible: false,
      currentRow: null,
      operateList: [{
        command: 'rename',
        label: '重命名',
        isShow: true
      }, {
        command: 'delete',
        label: '删除',
        class: 'delete',
        isShow: true
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    tableHeight() {
      const wh = this.screenHeight;
      const th = wh - 244;
      return th;
    },
    renameInputInfo() {
      const info = {
        title: '重命名',
        label: '常用筛选名称',
        errorText: '请输入常用筛选名称',
        placeholder: '请输入常用筛选名称'
      };
      if (this.currentRow) {
        info.name = this.currentRow.name;
      }
      return info;
    }
  },
  watch: {
    filterManageVisible: {
      handler: function (val) {
        if (val) {
          this.getManageFilters();
        }
      },
      immediate: true
    },
    params: {
      handler: function (val) {
        this.getManageFilters();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getFilters: _conversationList.getFilters,
    deleteFilter: _conversationList.deleteFilter,
    renameFilter: _conversationList.renameFilter,
    async getManageFilters() {
      const {
        current_page,
        size
      } = this.params;
      const data = {
        page: current_page,
        size,
        name: this.filterName,
        workspace_id: this.workspaceInfo.id
      };
      this.filterListLoading = true;
      const res = await this.getFiltersMethod(data);
      if (res.code === 20000) {
        this.filterList = res.results.filters;
        this.total = res.results.count;
        this.filterListLoading = false;
      }
    },
    closeManageDrawer() {
      this.$emit('update:filterManageVisible', false);
    },
    handleCommand(row, command) {
      if (command === 'rename') {
        this.resetFilterName(row);
      } else {
        this.deleteCurrentFilter(row);
      }
    },
    resetFilterName(row) {
      this.currentRow = row;
      this.renameDialogVisible = true;
    },
    deleteCurrentFilter(row) {
      const id = row.id;
      this.$confirm('你是否要永久删除此常用筛选？', '删除常用筛选', {
        confirmButtonText: '删除',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '取消',
        customClass: 'delete-filter',
        type: 'warning'
      }).then(async () => {
        const res = await this.deleteMethod({
          id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 200 || res.code === 20000) {
          this.$message.success('删除成功');
          this.getManageFilters();
        }
      }).catch(() => {});
    },
    debounceFilterNameChange: (0, _commonFunc.debounce)(function () {
      this.getManageFilters();
    }),
    async renameSubmit(form) {
      const data = {
        id: this.currentRow.id,
        name: form.name,
        workspace_id: this.workspaceInfo.id
      };
      const res = await this.renameMethod(data);
      if (res.code === 200 || res.code === 20000) {
        this.$message.success('保存成功');
        this.currentRow = null;
        this.$refs.renameDialog.$refs.form.resetFields();
        this.renameDialogVisible = false;
        this.getManageFilters();
      }
    },
    getIsShowTooltip(id) {
      let isShow = true;
      if (this.$refs['filterName' + id]) {
        const width = this.$refs['filterName' + id].$el.clientWidth;
        if (width < 260) {
          isShow = false;
        }
      }
      return isShow;
    }
  }
};
exports.default = _default;