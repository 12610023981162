"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RuleItemCard = _interopRequireDefault(require("./RuleItemCard.vue"));
var _RuleCardContent = _interopRequireDefault(require("./RuleCardContent.vue"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _elementUi = require("element-ui");
var _nanoid = require("nanoid");
var _conversationList = require("@/api/conversationList.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ProcessModule',
  props: {
    processType: {
      type: Number,
      default: 1 // 1: 自动填写自定义字段
    }
  },

  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    // 拿到空间信息
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const defaultContent = {
      1: {
        conditions: [{
          type: 'conv_score',
          content: {
            score_rule_id: null,
            score_range: [60, 100]
          }
        }],
        action: {
          type: 'fill_select_field',
          content: {
            field_id: null,
            option_key: null
          }
        }
      }
    };
    const initRuleList = (0, _vue.ref)([]);
    const ruleList = (0, _vue.ref)([]);
    const listLoading = (0, _vue.ref)(false);
    const getRuleList = async () => {
      listLoading.value = true;
      const res = await (0, _conversationList.getAutomationProcessRuleList)({
        space_id: workspaceInfo.value.id,
        type: props.processType
      }).finally(() => {
        listLoading.value = false;
      });
      if (res.code === 20000) {
        initRuleList.value = res.results.rules;
        ruleList.value = (0, _cloneDeep.default)(initRuleList.value);
        activeRuleKey.value = 0;
      }
    };
    const ruleListRef = (0, _vue.ref)(null);
    const addRule = () => {
      const idStr = (0, _nanoid.nanoid)();
      ruleList.value.unshift({
        id: idStr,
        content: (0, _cloneDeep.default)(defaultContent[props.processType])
      });
      activeRuleKey.value = idStr;
      // 新增时如果有滚动条，滚动到顶部
      if (ruleListRef.value) {
        ruleListRef.value.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
    const editRule = item => {
      activeRuleKey.value = item.id;
    };
    const deleteRule = async (item, index) => {
      if (typeof item.id === 'string') {
        // 如果是新增的直接删除
        ruleList.value.splice(index, 1);
        activeRuleKey.value = 0;
        return;
      }
      _elementUi.MessageBox.confirm('删除后，你将无法使用当前自动化流程，且该操作不可撤销。确认删除吗？', '删除规则', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _conversationList.deleteAutomationProcessRule)({
          ids: [item.id]
        });
        if (res.code === 20000) {
          ruleList.value.splice(index, 1);
          activeRuleKey.value = 0;
        }
      });
    };
    const cancelEdit = (item, index) => {
      if (typeof item.id === 'string') {
        ruleList.value.splice(index, 1);
      } else {
        item.content = (0, _cloneDeep.default)(initRuleList.value[index].content);
      }
      activeRuleKey.value = 0;
    };
    const activeRuleKey = (0, _vue.ref)(0);
    const ruleCardContentRefs = (0, _vue.ref)({});
    const setRuleCardContentRef = id => {
      return ref => {
        if (ref) {
          ruleCardContentRefs.value[id] = ref;
        }
      };
    };
    const createRule = async (rule, cb) => {
      const res = await (0, _conversationList.createAutomationProcessRule)({
        space_id: workspaceInfo.value.id,
        type: props.processType,
        content: rule
      }).finally(() => {
        activeSubmitRuleKey.value = 0;
      });
      if (res.code === 20000) {
        handleSubmitSucceed(cb);
      }
    };
    const updateRule = async (item, cb) => {
      const res = await (0, _conversationList.updateAutomationProcessRule)({
        space_id: workspaceInfo.value.id,
        type: props.processType,
        content: item.content,
        id: item.id
      }).finally(() => {
        activeSubmitRuleKey.value = 0;
      });
      if (res.code === 20000) {
        handleSubmitSucceed(cb);
      }
    };
    const handleSubmitSucceed = cb => {
      _elementUi.Message.success('保存成功');
      getRuleList();
      cb && cb();
    };
    const activeSubmitRuleKey = (0, _vue.ref)(0);
    const submitEdit = async (item, cb) => {
      const valid = await ruleCardContentRefs.value[item.id].validateForm();
      if (!valid) return;
      activeSubmitRuleKey.value = item.id;
      // 如果id是字符串则代表新增，否则代表修改
      if (typeof item.id === 'string') {
        createRule(item.content, cb);
      } else {
        updateRule(item, cb);
      }
    };

    // 检查是否有未保存的card项
    const hasUnsavedItem = () => {
      // 如果有待保存的项或者新增没编辑的项，返回true
      return activeRuleKey.value || ruleList.value.some(item => {
        return typeof item.id === 'string';
      });
    };
    const scoreRuleOptions = (0, _vue.ref)([]);
    const getScoreRuleList = async spaceId => {
      listLoading.value = true;
      const res = await (0, _conversationList.getConversationScoreRuleList)({
        space_id: spaceId
      });
      if (res.code === 20000) {
        scoreRuleOptions.value = res.results.rules;
      }
    };
    (0, _vue.provide)('scoreRuleOptions', scoreRuleOptions);
    const selectFields = (0, _vue.ref)([]);
    const getSelectCustomField = async () => {
      const res = await (0, _conversationList.getConversationSelectCustomField)({
        type: 'conversation' // 目前只支持会话
      });

      if (res.code === 20000) {
        selectFields.value = res.results.fields;
      }
    };
    (0, _vue.provide)('selectFields', selectFields);
    (0, _vue.watchEffect)(async () => {
      await getScoreRuleList(workspaceInfo.value.id);
      await getSelectCustomField();
      await getRuleList();
      if (!initRuleList.value.length) {
        addRule();
      }
    });
    expose({
      hasUnsavedItem
    });
    return {
      __sfc: true,
      props,
      store,
      workspaceInfo,
      defaultContent,
      initRuleList,
      ruleList,
      listLoading,
      getRuleList,
      ruleListRef,
      addRule,
      editRule,
      deleteRule,
      cancelEdit,
      activeRuleKey,
      ruleCardContentRefs,
      setRuleCardContentRef,
      createRule,
      updateRule,
      handleSubmitSucceed,
      activeSubmitRuleKey,
      submitEdit,
      hasUnsavedItem,
      scoreRuleOptions,
      getScoreRuleList,
      selectFields,
      getSelectCustomField,
      RuleItemCard: _RuleItemCard.default,
      RuleCardContent: _RuleCardContent.default
    };
  }
};
exports.default = _default;