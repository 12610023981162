"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex.js");
var _debounce = _interopRequireDefault(require("lodash/debounce"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EventTableFilter',
  props: {
    batchEventsList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['filterChange', 'batchSetKeyEventApplyPermission'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const filters = (0, _vue.reactive)({
      name: '',
      status: ''
    });
    const batchSetKeyEventApplyPermission = () => {
      emit('batchSetKeyEventApplyPermission');
    };
    const handleChange = () => {
      emit('filterChange', filters);
    };
    const handleInput = (0, _debounce.default)(() => {
      emit('filterChange', filters);
    }, 300);
    const getStatus = val => {
      let map = {};
      if (store.getters.modulePermission.ModelManagement) {
        map = {
          1: '已禁用',
          2: '已启用'
        };
      } else {
        map = {
          1: '待上线',
          2: '已上线'
        };
      }
      return map[val] || '';
    };
    const clearFilters = () => {
      filters.name = '';
      filters.status = '';
    };
    expose({
      clearFilters
    });
    return {
      __sfc: true,
      store,
      props,
      filters,
      emit,
      batchSetKeyEventApplyPermission,
      handleChange,
      handleInput,
      getStatus,
      clearFilters
    };
  }
};
exports.default = _default;