"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PrecisionDetailTable = _interopRequireDefault(require("./PrecisionDetailTable.vue"));
var _keyEventManagement = require("@/api/keyEventManagement");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PrecisionDetailOverview',
  components: {
    PrecisionDetailTable: _PrecisionDetailTable.default
  },
  inject: ['modelInfo'],
  data() {
    return {
      modelVersionList: [],
      selectedVersions: [],
      tableData: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getModelPrecisionValue();
  },
  methods: {
    openEventAnnotationDetail(row) {
      this.$emit('openEventAnnotationDetail', row);
    },
    handleSelectedVersionInput(val) {
      val.sort((a, b) => a - b);
      this.selectedVersions = val;
    },
    formatModelInfo() {
      const {
        id,
        type,
        version
      } = this.modelInfo;
      const target_id = id;
      return {
        target_id,
        target_type: type
      };
    },
    async getModelPrecisionValue() {
      const res = await (0, _keyEventManagement.getModelPrecisionValue)({
        workspace_id: this.workspaceInfo.id,
        ...this.formatModelInfo()
      });
      if (res.code === 20000) {
        var _versions$find;
        const {
          versions,
          event_analysis
        } = res.results;
        this.modelVersionList = versions;
        this.tableData = event_analysis;
        this.selectedVersions.push((_versions$find = versions.find(item => item.version === this.modelInfo.version)) === null || _versions$find === void 0 ? void 0 : _versions$find.version);
      }
    }
  }
};
exports.default = _default;