"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExecutorInfoList = _interopRequireDefault(require("./ExecutorInfoList"));
var _DepartmentAndMemberFilterTree = _interopRequireDefault(require("@/components/DepartmentAndMemberSelectTree/DepartmentAndMemberFilterTree.vue"));
var _ReminderSelect = _interopRequireDefault(require("./ReminderSelect.vue"));
var _taskCenter = require("@/api/taskCenter");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateTaskForm',
  components: {
    ExecutorInfoList: _ExecutorInfoList.default,
    DepartmentAndMemberFilterTree: _DepartmentAndMemberFilterTree.default,
    ReminderSelect: _ReminderSelect.default
  },
  props: {
    taskType: {
      type: Number,
      default: 1 // 1为任务中心创建 3为助手todo创建
    },

    entityInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    textOnly: {
      type: Boolean,
      default: true
    },
    defaultTextInfo: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      departmentAndMemberTreeParams: {
        type: '["assist"]'
      },
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      content: '',
      todoTime: '',
      isAddExecutor: false,
      executorData: [],
      // 执行人员信息
      createBtnLoading: false,
      backfillIds: [],
      editTextInfo: [],
      // 编辑后的非纯文字的内容信息数组
      defaultReminderTime: '截止前30分钟提醒',
      alertAbsoluteTime: null,
      alertRelativeLabel: '截止前30分钟提醒'
    };
  },
  computed: {
    confirmButtonDisabled() {
      const hasTitle = this.content.replace(/\s*/g, '') || this.editTextInfo.some(item => !!item.text);
      return !(hasTitle && this.todoTime && this.executorData.length);
    },
    textHtml() {
      let html = '';
      this.defaultTextInfo.forEach(item => {
        if (item.router_name) {
          const router = this.$router.resolve({
            path: _routeMap.ROUTES[item.router_name],
            query: Object.assign(item.router_params, {
              openDetailDrawer: true
            })
          });
          html += `<a href="${router.href}" target="_blank" contenteditable="false"
          >${item.text}</a>&nbsp;`;
        } else {
          html += item.text;
        }
      });
      return html;
    }
  },
  watch: {
    content(val) {
      if (val.length === 400) {
        this.$message({
          type: 'warning',
          message: '超过字数上限',
          center: true
        });
      }
    },
    defaultTextInfo: {
      handler: function (val) {
        this.editTextInfo = (0, _commonFunc.deepClone)(val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleMemberFilterChange(item) {
      if (!this.executorData.some(_ref => {
        let {
          id
        } = _ref;
        return item.id === id;
      })) {
        this.executorData.push(item);
      }
    },
    // 编辑执行人员代码
    addExecutor() {
      this.isAddExecutor = true;
    },
    // 树成员选择变化
    handleDepartmentAndMemberChange(checkedNodesData) {
      const defaulteIds = [...this.$refs['departmentAndMemberTree'].$refs.tree.defaultChecked, ...this.backfillIds] || [];
      this.executorData = this.executorData.filter(_ref2 => {
        let {
          id
        } = _ref2;
        return defaulteIds.includes(id);
      });
      this.executorData.push(...checkedNodesData.filter(_ref3 => {
        let {
          id
        } = _ref3;
        return !this.executorData.some(item => item.id === id);
      }));
    },
    // 执行人tag展示处，删除执行人
    handleDeleteExecutor(executor) {
      const {
        id
      } = executor;
      this.executorData = this.executorData.filter(item => item.id !== id);
      this.$refs['departmentAndMemberTree'].$refs.tree.defaultChecked.filter(item => item.id !== id);
      this.setDepartmentAndMemberCheckedNodes();
    },
    // 部门成员树回填
    setDepartmentAndMemberCheckedNodes() {
      this.$refs['departmentAndMemberTree'].$refs.tree && this.$refs['departmentAndMemberTree'].$refs.tree.$refs['department-member-tree'].setCheckedNodes(this.executorData);
    },
    deleteTodoTime() {
      this.todoTime = '';
      this.defaultReminderTime = {
        time: '30 minutes',
        label: '截止前30分钟提醒'
      }; // 恢复提醒的默认选中值
    },

    handelReminderChange(obj) {
      const {
        reminderAt,
        reminderLabel
      } = obj;
      this.alertAbsoluteTime = reminderAt;
      this.alertRelativeLabel = reminderLabel;
    },
    async handleConfirm() {
      const executors = this.executorData.map(_ref4 => {
        let {
          id
        } = _ref4;
        return id.includes('tree_id') ? id : Number(id);
      });
      const contentParam = this.getContentParam();
      const absoluteTimeAt = this.alertAbsoluteTime;
      const relativeTimeLabel = this.alertRelativeLabel;
      const baseParams = {
        task_type: this.taskType,
        content: contentParam,
        deadline_at: this.todoTime,
        executors: executors,
        alert_at: absoluteTimeAt,
        alert_label: relativeTimeLabel
      };
      const params = Object.assign(baseParams, this.entityInfo);
      try {
        this.createBtnLoading = true;
        (0, _timezone.datePickerWithTimeParse2Zone)(params, true);
        // 发接口,创建新任务
        const res = await (0, _taskCenter.creatNewTask)(params);
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '新建成功',
            center: true
          });
          this.handleCancle();
          this.$emit('creatNewTask');
        }
      } finally {
        this.createBtnLoading = false;
      }
    },
    getContentParam() {
      let content = [this.getPureTextObj(this.content)];
      if (!this.textOnly) {
        content = this.editTextInfo;
      }
      return content;
    },
    handleCancle() {
      this.$emit('cancelCreate');
      Object.assign(this.$data, this.$options.data.call(this));
      this.setDepartmentAndMemberCheckedNodes();
    },
    setDefaultValue(content, executorData, todoTime) {
      this.content = content;
      this.todoTime = todoTime ? this.$moment(todoTime).format('YYYY-MM-DD HH:mm') : '';
      this.executorData = executorData;
      const ids = executorData.map(item => {
        return item.id;
      });
      this.backfillIds = ids;
    },
    getPureTextObj(text) {
      return {
        text,
        router_name: '',
        router_params: {}
      };
    },
    inputChange() {
      const regValue = /<a(?:(?!<\/a>).|\n)*?<\/a>/gm;
      let splitArray = this.$refs.editor.innerHTML.split(regValue);
      splitArray = splitArray.map(item => {
        item = item.replace(/&nbsp;/, '');
        return item && item.trim();
      });
      if (splitArray.length === 1) {
        // 数组长度为1时，说明链接已删除
        this.editTextInfo = [this.getPureTextObj(splitArray[0])];
      }
      if (splitArray.length === 2) {
        // 数组长度为2时，说明链接在
        this.editTextInfo = [this.getPureTextObj(splitArray[0]), this.defaultTextInfo[1], this.getPureTextObj(splitArray[1])];
      }
    }
  }
};
exports.default = _default;