"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      visible: _vm.visible,
      title: "新建AI客户",
      width: "600px"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      "label-position": "top",
      model: _setup.formData,
      rules: _setup.rules
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
        return _setup.createAiCustomer.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "课程名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入课程名称",
      maxlength: "50"
    },
    model: {
      value: _setup.formData.name,
      callback: function ($$v) {
        _vm.$set(_setup.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "type",
      label: "课程类型"
    }
  }, [_c("el-radio-group", {
    staticClass: "course-type-group",
    model: {
      value: _setup.formData.type,
      callback: function ($$v) {
        _vm.$set(_setup.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "qa",
      border: ""
    }
  }, [_c("p", {
    staticClass: "radio-title"
  }, [_vm._v("问答对练")]), _c("span", {
    staticClass: "radio-description"
  }, [_vm._v("借助知识库的客户问题 & 标准话术，快速创建考核学员标准话术能力的AI客户")])]), _c("el-tooltip", {
    attrs: {
      content: "即将上线",
      disabled: _setup.modulePermission.aiCustomerGenerator,
      placement: "top"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "profession",
      border: "",
      disabled: !_setup.modulePermission.aiCustomerGenerator
    }
  }, [_c("p", {
    staticClass: "radio-title"
  }, [_vm._v("专业对练")]), _c("span", {
    staticClass: "radio-description"
  }, [_vm._v("通过提示信息配置AI客户要表现的内容，考核学员更多维度能力，需调试课程效果 ")])])], 1)], 1)], 1)], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  }, [_vm._v("取消")]), _c("MgvButton", {
    attrs: {
      loading: _setup.createBtnLoading
    },
    on: {
      click: _setup.createAiCustomer
    }
  }, [_vm._v("创建")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;