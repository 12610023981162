"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TextTag',
  props: {
    text: {
      type: [String, Number],
      default: ''
    },
    bgColor: {
      type: String,
      default: 'blue'
    },
    color: {
      type: String,
      default: ''
    },
    shape: {
      type: String,
      default: ''
    }
  }
};
exports.default = _default;