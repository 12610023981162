"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _listCard = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCard"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvListDrawer',
  components: {
    ConversationListCard: _listCard.default,
    SortCaret: _sortCaret.default
  },
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    },
    isInQualityTesting: {
      from: 'isInQualityTesting',
      default: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '会话结果'
    },
    listLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      params: {
        current_page: 1,
        size: 10,
        workspace_id: 1
      },
      isNone: false,
      conversationListTableHead: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    conversationScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 1);
    },
    complianceScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 2);
    }
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.params.workspace_id = this.workspaceInfo.id;
        }
      }
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getConversationListTableHead();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    // 获取会话列表页面表头
    async getConversationListTableHead() {
      const res = await (0, _conversationList.getConversationListTableHead)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.conversationListTableHead = res.results.fields;
      }
    },
    sortData(sort_by, sort_order) {
      if (!sort_order) {
        this.params.sort_by = '';
      } else {
        this.params.sort_by = sort_by;
      }
      this.params.sort_order = sort_order;
      this.sortInitOther(sort_by);
      this.params.current_page = 1;
      this.$emit('refresh', this.params);
    },
    sortInitOther(sort_by) {
      const scoreList = [];
      this.complianceScoreHead.map(item => {
        scoreList.push(`compliance_score_${item.id}`);
      });
      this.conversationScoreHead.map(item => {
        scoreList.push(`score_rule_${item.id}`);
      });
      const refArray = ['begin_time', 'duration', 'event_engine_count', 'host_id'].concat(scoreList);
      refArray.forEach(item => {
        if (item !== sort_by) {
          const refs = this.$refs[item + '_sort'];
          (Array.isArray(refs) ? refs[0] : refs).init();
        }
      });
    },
    handleSizeChange(val) {
      this.params.size = val;
      this.params.current_page = 1;
      this.$emit('refresh', this.params);
    },
    handleCurrentChange(currentPage) {
      this.params.current_page = currentPage;
      this.$emit('refresh', this.params);
    }
  }
};
exports.default = _default;