"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TradeTips',
  data() {
    return {
      helpUrl: 'https://www.megaview.com/help-center/doc?docx=PkDBdf7eRolwOixqKB1c3mwenvf&anchor=PkDBdf7eRolwOixqKB1c3mwenvf',
      showGif: false
    };
  }
};
exports.default = _default;