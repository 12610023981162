"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "feedback-button"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "反馈",
      "popper-class": "sidebar-bottom-button-tooltip",
      placement: "bottom",
      "hide-after": 1000
    }
  }, [_c("el-button", {
    staticClass: "feedback-btn hover-show-bg",
    attrs: {
      icon: "iconfont icon-feedback",
      circle: ""
    },
    on: {
      click: _vm.openFeedbackDialog
    }
  })], 1), _c("el-dialog", {
    attrs: {
      title: "反馈",
      visible: _vm.feedbackDialogVisible,
      direction: "rtl",
      "before-close": _vm.closeDialog,
      "custom-class": "sidebar-feedback-dialog",
      width: "480px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.feedbackDialogVisible = $event;
      },
      closed: _vm.resetForm
    }
  }, [_c("feedback-form", {
    ref: "feedbackForm",
    on: {
      closeDialog: _vm.closeDialog
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;