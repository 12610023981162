"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mediaUpload = require("@/api/mediaUpload");
const state = {
  maxDuration: 0,
  usedDuration: 0,
  orgMaxDuration: 0,
  orgUsedDuration: 0,
  isUseOrgMaxDuration: false,
  uploadStatus: true,
  uploadHostSalesmanInfo: {},
  globalUploadFileList: []
};
const mutations = {
  SET_UPLOAD_DURATION: (state, val) => {
    state.maxDuration = val.max_duration;
    state.usedDuration = val.used_duration;
    state.orgMaxDuration = val.org_max_duration;
    state.orgUsedDuration = val.org_used_duration;
    state.isUseOrgMaxDuration = val.is_use_org_max_duration;
    state.uploadStatus = val.status;
  },
  SET_UPLOAD_HOST_SALESMAN_INFO: (state, val) => {
    state.uploadHostSalesmanInfo = val;
  },
  SET_GLOBAL_UPLOAD_FILE_LIST: (state, val) => {
    state.globalUploadFileList = [...state.globalUploadFileList, ...val];
  },
  SET_GLOBAL_UPLOAD_FILE_STATE: (state, _ref) => {
    let {
      index,
      val
    } = _ref;
    state.globalUploadFileList.splice(index, 1, val);
  },
  CLEAR_GLOBAL_UPLOAD_FILE_LIST: () => {
    state.globalUploadFileList = [];
  }
};
const actions = {
  async getUploadDuration(_ref2) {
    let {
      commit,
      state
    } = _ref2;
    let host_salesman_id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const res = await (0, _mediaUpload.getUploadDuration)({
      host_salesman_id
    });
    commit('SET_UPLOAD_DURATION', res.results);
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;