"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-and-version-select"
  }, [_c("el-select", {
    staticClass: "model-select",
    attrs: {
      remote: "",
      "remote-method": _setup.getModelListRequest,
      filterable: "",
      clearable: "",
      "reserve-keyword": "",
      placeholder: "请选择模型",
      disabled: _vm.disabled,
      "popper-append-to-body": false
    },
    on: {
      change: _setup.selectModel
    },
    model: {
      value: _setup.model_id,
      callback: function ($$v) {
        _setup.model_id = $$v;
      },
      expression: "model_id"
    }
  }, _vm._l(_setup.modelList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("div", {
      staticClass: "option-item"
    }, [_c("i", {
      staticClass: "iconfont icon-box-square",
      class: item.model_type === 1 ? "rule" : "ai"
    }), _c("span", [_vm._v(_vm._s(item.name))])])]);
  }), 1), _c(_setup.ModelVersionSelect, {
    staticClass: "model-version-select",
    attrs: {
      "model-id": _setup.model_id,
      "show-status": _vm.showStatus,
      disabled: !_setup.model_id || _vm.disabled,
      "default-selected-published-version": _vm.defaultSelectedPublishedVersion
    },
    model: {
      value: _setup.model_version,
      callback: function ($$v) {
        _setup.model_version = $$v;
      },
      expression: "model_version"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;