"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      title: "历史",
      visible: _vm.drawerVisible,
      size: "1000px",
      "before-close": _setup.closeDrawer
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _setup.tableData
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "create_at",
      label: "操作日期",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "operator",
      label: "操作人",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _row$editor, _row$editor2, _row$editor3;
        let {
          row
        } = _ref;
        return [_c("MgvAvatarName", {
          attrs: {
            name: row === null || row === void 0 ? void 0 : (_row$editor = row.editor) === null || _row$editor === void 0 ? void 0 : _row$editor.name,
            size: 24,
            src: row === null || row === void 0 ? void 0 : (_row$editor2 = row.editor) === null || _row$editor2 === void 0 ? void 0 : _row$editor2.avatar
          }
        }), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$editor3 = row.editor) === null || _row$editor3 === void 0 ? void 0 : _row$editor3.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "field_label",
      label: "字段"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "old_value",
      label: "变更前"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c(_setup.ModifyConvInfoHistoryValue, {
          attrs: {
            row: row,
            "field-value": row.old_val
          },
          scopedSlots: _vm._u([{
            key: "host_salesman",
            fn: function () {
              var _row$old_val, _row$old_val2, _row$old_val3;
              return [_c("MgvAvatarName", {
                attrs: {
                  size: 24,
                  src: row === null || row === void 0 ? void 0 : (_row$old_val = row.old_val) === null || _row$old_val === void 0 ? void 0 : _row$old_val.avatar,
                  name: row === null || row === void 0 ? void 0 : (_row$old_val2 = row.old_val) === null || _row$old_val2 === void 0 ? void 0 : _row$old_val2.name
                }
              }), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$old_val3 = row.old_val) === null || _row$old_val3 === void 0 ? void 0 : _row$old_val3.name))])];
            },
            proxy: true
          }, {
            key: "workspace",
            fn: function () {
              return [_c("i", {
                staticClass: "iconfont icon-workspace-3-fill",
                style: {
                  color: row.old_val.icon_color
                }
              }), _c("span", [_vm._v(_vm._s(row.old_val.name))])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "50"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-right"
  })]), _c("el-table-column", {
    attrs: {
      prop: "new_value",
      label: "变更后"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c(_setup.ModifyConvInfoHistoryValue, {
          attrs: {
            row: row,
            "field-value": row.new_val
          },
          scopedSlots: _vm._u([{
            key: "host_salesman",
            fn: function () {
              var _row$new_val, _row$new_val2, _row$new_val3;
              return [_c("div", {
                staticClass: "host-salesman"
              }, [_c("MgvAvatarName", {
                attrs: {
                  size: 24,
                  src: row === null || row === void 0 ? void 0 : (_row$new_val = row.new_val) === null || _row$new_val === void 0 ? void 0 : _row$new_val.avatar,
                  name: row === null || row === void 0 ? void 0 : (_row$new_val2 = row.new_val) === null || _row$new_val2 === void 0 ? void 0 : _row$new_val2.name
                }
              }), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$new_val3 = row.new_val) === null || _row$new_val3 === void 0 ? void 0 : _row$new_val3.name))])], 1)];
            },
            proxy: true
          }, {
            key: "workspace",
            fn: function () {
              return [_c("i", {
                staticClass: "iconfont icon-workspace-3-fill",
                style: {
                  color: row.new_val.icon_color
                }
              }), _c("span", [_vm._v(_vm._s(row.new_val.name))])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;