"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "filter-select"
  }, [_c("div", {
    staticClass: "default-filter"
  }, [_vm.isShowDepartmentOrMembersSelect ? _c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.filters.departmentOrMembersType,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "departmentOrMembersType", $$v);
      },
      expression: "filters.departmentOrMembersType"
    }
  }, _vm._l(_vm.departmentOrMembersOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1) : _vm._e(), _vm._l(_vm.filtersConfig, function (filterItem, index) {
    return _c("div", {
      key: index,
      class: [filterItem.customClass, {
        "analysis-basis-content": filterItem.type === "analysis_basis"
      }]
    }, [filterItem.type === "tree_id" ? _c("department-select", {
      attrs: {
        "no-default": false,
        params: filterItem.params || _vm.params
      },
      on: {
        labelChange: _vm.departmentLabelChange
      },
      model: {
        value: _vm.filters.tree_id,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "tree_id", $$v);
        },
        expression: "filters.tree_id"
      }
    }) : _vm._e(), filterItem.type === "tree_ids" ? _c("mgv-department-multi-select", {
      attrs: {
        "request-method": _vm.getOrgTree,
        "request-params": filterItem.params,
        "set-checked-root-node": filterItem.setCheckedRootNode
      },
      on: {
        change: _vm.departmentMultipleChange
      }
    }) : _vm._e(), filterItem.type === "user_ids" ? _c("members-finder", {
      ref: "membersFinder",
      refInFor: true,
      attrs: {
        placeholder: filterItem.placeholder || "请选择成员",
        "get-member-api-type": "data",
        "collapse-tags": "",
        params: _vm.params,
        "has-self": filterItem.hasSelf || false
      },
      on: {
        change: function ($event) {
          return _vm.setFiltersValue($event, "user_ids");
        }
      }
    }) : _vm._e(), filterItem.type === "tree_user_ids" ? _c("mgv-member-multi-select", {
      attrs: {
        "request-function": _vm.getDataOrgMemberTree,
        "request-search-function": _vm.getUserList,
        "request-params": filterItem.params || {
          type: JSON.stringify([_vm.filters.analysis_basis])
        },
        "is-show-main-space-member": "",
        "set-checked-root-node": filterItem.isSetCheckedRoot,
        "is-prevent-not-selected": filterItem.isPreventNotSelected
      },
      on: {
        change: _vm.departmentAndMemberChange
      }
    }) : _vm._e(), _c("div", {
      staticClass: "date-box"
    }, [filterItem.type === "date" ? _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: filterItem.tooltip,
        placement: "top",
        value: filterItem.showTooltip,
        disabled: !filterItem.tooltip.length
      }
    }, [_c("el-date-picker", {
      attrs: {
        type: "daterange",
        align: "right",
        "unlink-panels": "",
        "range-separator": "至",
        "start-placeholder": "开始日期",
        "end-placeholder": "结束日期",
        "value-format": "yyyy-MM-dd",
        "picker-options": filterItem.options || _vm.datePickerOptions
      },
      on: {
        change: _vm.handleDateRangeChange
      },
      model: {
        value: _vm.filters.date,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "date", $$v);
        },
        expression: "filters.date"
      }
    })], 1) : _vm._e(), filterItem.type === "month" ? _c("el-date-picker", {
      staticClass: "month-picker",
      attrs: {
        type: "month",
        placeholder: filterItem.placeholder || "选择月份",
        "value-format": filterItem.valueFormat || "yyyy-MM",
        "picker-options": filterItem.options || _vm.pickerOptions,
        clearable: false
      },
      on: {
        change: _vm.filtersChange
      },
      model: {
        value: _vm.filters.month,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "month", $$v);
        },
        expression: "filters.month"
      }
    }) : _vm._e(), filterItem.type === "day" ? _c("el-select", {
      attrs: {
        placeholder: filterItem.placeholder || "请选择"
      },
      on: {
        change: _vm.filtersChange
      },
      model: {
        value: _vm.filters.day,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "day", $$v);
        },
        expression: "filters.day"
      }
    }, _vm._l(filterItem.options || _vm.daySelectOption, function (item) {
      return _c("el-option", {
        key: item,
        attrs: {
          label: `过去${item}天`,
          value: item
        }
      });
    }), 1) : _vm._e(), filterItem.type === "create_time" ? _c("create-date", {
      staticClass: "create-date",
      attrs: {
        item: filterItem.options || _vm.createTimeOptions
      },
      on: {
        change: _vm.createTimeChange
      }
    }) : _vm._e()], 1), filterItem.type === "conversation_type" ? _c("el-select", {
      staticClass: "conversation-type-selector",
      attrs: {
        multiple: "",
        "collapse-tags": "",
        "popper-class": "conversation-type-select-popover",
        placeholder: filterItem.placeholder || "会话类型",
        clearable: ""
      },
      on: {
        change: _vm.filtersChange
      },
      model: {
        value: _vm.conversationType,
        callback: function ($$v) {
          _vm.conversationType = $$v;
        },
        expression: "conversationType"
      }
    }, [_vm._l(filterItem.options || _vm.conversationTypeOptions, function (item) {
      return [!(_vm.isDimensionPage && item.value === "doc") ? _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value
        }
      }, [_c("i", {
        class: `iconfont icon-${item.icon}`
      }), _c("span", [_vm._v(_vm._s(item.label))])]) : _vm._e()];
    })], 2) : _vm._e(), filterItem.type === "type" ? _c("el-select", {
      attrs: {
        placeholder: filterItem.placeholder || "请选择会话类型"
      },
      on: {
        change: _vm.filtersChange
      },
      model: {
        value: _vm.filters.conversation_scene_type,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "conversation_scene_type", $$v);
        },
        expression: "filters.conversation_scene_type"
      }
    }, _vm._l(filterItem.options || _vm.conversationOptions, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value
        }
      });
    }), 1) : _vm._e(), filterItem.type === "conversation_scene_type" ? _c("el-select", {
      attrs: {
        placeholder: filterItem.placeholder || "请选择会话场景",
        multiple: ""
      },
      on: {
        change: _vm.filtersChange
      },
      model: {
        value: _vm.filters.conversation_scene_type,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "conversation_scene_type", $$v);
        },
        expression: "filters.conversation_scene_type"
      }
    }, _vm._l(filterItem.options || _vm.conversationSceneOptions, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value
        }
      });
    }), 1) : _vm._e(), filterItem.type === "event_id" || filterItem.type === "event_list" ? _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: filterItem.tooltip,
        disabled: !filterItem.tooltip,
        placement: "top"
      }
    }, [_c("event-select", {
      attrs: {
        "is-checked-root": true,
        "is-strictly": filterItem.isStrictly,
        "is-filter-event-id": _vm.isFilterEventId,
        "is-multiple": filterItem.isMultiple,
        placeholder: filterItem.placeholder
      },
      on: {
        changeEvent: function ($event) {
          return _vm.setFiltersValue($event, filterItem.type);
        }
      }
    })], 1) : _vm._e(), filterItem.type === "event_tracker" ? _c("deal-key-event-filter", {
      attrs: {
        value: _vm.filters.event_tracker
      }
    }) : _vm._e(), filterItem.type === "exclude_event_list" ? _c("div", {
      staticClass: "exclude-event"
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        disabled: !filterItem.tooltip,
        content: filterItem.tooltip,
        placement: "top"
      }
    }, [_c("event-select", {
      attrs: {
        "is-multiple": "",
        placeholder: filterItem.label
      },
      on: {
        changeEvent: function ($event) {
          return _vm.setFiltersValue($event, "exclude_event_list");
        }
      }
    })], 1)], 1) : _vm._e(), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: filterItem.tooltip,
        placement: "top"
      }
    }, [filterItem.type === "conversation_counts" ? _c("amount-range-select", {
      staticClass: "conversation-counts",
      attrs: {
        item: _vm.conversationCount
      },
      on: {
        changeEvent: function ($event) {
          return _vm.setFiltersValue($event, "conversation_counts");
        }
      }
    }) : _vm._e()], 1), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: filterItem.tooltip,
        placement: "top"
      }
    }, [filterItem.type === "node_counts" ? _c("score-select", {
      staticClass: "node-counts",
      attrs: {
        placeholder: "节点数",
        maxlength: "2",
        range: _vm.scoreRange,
        "default-range": _vm.defaultRange,
        "score-suggestion": _vm.scoreSuggestion
      },
      on: {
        scoreChange: function ($event) {
          return _vm.setFiltersValue($event, "node_counts");
        }
      }
    }) : _vm._e()], 1), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: filterItem.tooltip,
        placement: "top"
      }
    }, [filterItem.type === "trade_status" ? _c("el-select", {
      attrs: {
        multiple: "",
        "collapse-tags": "",
        placeholder: "请选择交易阶段"
      },
      on: {
        change: _vm.tradeStatusChange
      },
      model: {
        value: _vm.filters.trade_status,
        callback: function ($$v) {
          _vm.$set(_vm.filters, "trade_status", $$v);
        },
        expression: "filters.trade_status"
      }
    }, _vm._l(_vm.dealStageOptions, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.stage_name,
          value: item.id
        }
      });
    }), 1) : _vm._e()], 1)], 1);
  }), _c("deal-custom-field-filter", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCustomFieldFilter,
      expression: "showCustomFieldFilter"
    }],
    staticClass: "deal-custom-field-filter",
    attrs: {
      "custom-filters": _vm.getStorageCustomFilters,
      "default-more-filter": _vm.defaultMoreFilter
    },
    on: {
      customFilterChange: _vm.customFilterChange,
      showDefaultFilter: _vm.showDefaultFilter
    },
    scopedSlots: _vm._u([{
      key: "more-filter-suffix",
      fn: function () {
        return [_c("transition", {
          attrs: {
            name: "slide"
          }
        }, [_vm.needLocalStorageFiltersPage ? _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showSaveFilterBar,
            expression: "showSaveFilterBar"
          }],
          staticClass: "filter-save-bar"
        }, [_c("span", {
          staticClass: "save-tip"
        }, [_vm._v("调整了更多筛选")]), _c("MgvButton", {
          attrs: {
            type: "text",
            size: "smini"
          },
          on: {
            click: _vm.saveToLoacalStorage
          }
        }, [_vm._v("保存")]), _c("i", {
          staticClass: "iconfont icon-close-small",
          on: {
            click: function ($event) {
              _vm.showSaveFilterBar = false;
            }
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }])
  })], 2), _c("div", {
    staticClass: "basis-select-container"
  }, [_vm.analysisBasis ? [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: _vm.analysisBasis.content,
      "popper-class": "bi-filter-analysis-basis-tooltip"
    }
  }, [_vm.analysisBasis.contentList ? _c("div", {
    staticClass: "content-list",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, _vm._l(_vm.analysisBasis.contentList, function (item) {
    return _c("p", {
      key: item
    }, [_c("span", [_vm._v("· ")]), _c("span", [_vm._v(_vm._s(item))])]);
  }), 0) : _vm._e(), _vm.analysisBasis.content || _vm.analysisBasis.contentList ? _c("i", {
    staticClass: "iconfont icon-info-1 analysis-basis-info-icon"
  }) : _vm._e()]), _c("el-radio-group", {
    on: {
      change: _vm.analysisBasisChange
    },
    model: {
      value: _vm.filters.analysis_basis,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "analysis_basis", $$v);
      },
      expression: "filters.analysis_basis"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "conversation"
    }
  }, [_vm._v(" 按会话分析 ")]), _c("el-radio-button", {
    attrs: {
      label: "deal"
    }
  }, [_vm._v(" 按交易分析 ")])], 1)] : _vm._e(), _vm.hasBasisSelect ? _c("filter-basis-select", {
    on: {
      change: function ($event) {
        return _vm.handleFilterBasisChange($event, "entity_type");
      }
    }
  }) : _vm._e(), _vm.hasExportCsv ? _c("el-button", {
    staticClass: "export-button",
    on: {
      click: _vm.exportCsv
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]) : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;