"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keyEvent = require("@/api/keyEvent");
var _EditDepartmentDialog = _interopRequireDefault(require("./EditDepartmentDialog.vue"));
var _ScoreRuleDateFilter = _interopRequireDefault(require("@/components/ScoreRuleDateFilter"));
var _ConvListDrawer = _interopRequireDefault(require("@/views/compliance/components/ConvListDrawer.vue"));
var _setKeyEventApplyPermission = require("../hooks/set-key-event-apply-permission");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SetKeyEventApplyPermission',
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    eventIds: {
      type: Array,
      default: () => []
    },
    isBatch: {
      type: Boolean,
      default: false
    },
    isSystemEvent: {
      type: Boolean,
      default: false
    },
    hasSetModel: {
      type: Boolean,
      default: true
    },
    hasEnableKeyEvent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:drawerVisible', 'refreshTableData', 'refreshTaskProgress'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const isShowOrgError = (0, _vue.ref)(false);
    const {
      scope,
      eventName,
      getScopeOfKeyEventApi
    } = (0, _setKeyEventApplyPermission.useGetKeyEventApplyPermission)(props, store);
    const {
      convCount,
      getConvCountApi
    } = (0, _setKeyEventApplyPermission.useGetConvCount)(scope, store);
    const {
      conversationTypeList,
      handleConvTypeChange,
      handleOrgTypeChange,
      selectConversationType,
      dateFilterChange
    } = (0, _setKeyEventApplyPermission.useApplyPermissionChange)(scope);
    const {
      editDepartmentDialogVisible,
      getConversationOrgList,
      openEditDepartmentDialog,
      handleChangeDepartment
    } = (0, _setKeyEventApplyPermission.useEditDepartmentDialog)(scope, store);
    const {
      listLoading,
      convListDrawerVisible,
      conversationList,
      conversationTotal,
      openConvDrawer
    } = (0, _setKeyEventApplyPermission.useOpenConvDrawer)(scope, store);
    (0, _vue.onMounted)(() => {
      getScopeOfKeyEventApi();
    });
    (0, _vue.watch)(() => scope, val => {
      if (val.organization_tree_ids.length) {
        isShowOrgError.value = false;
      }
      getConvCountApi();
    }, {
      deep: true,
      immediate: true
    });
    const handleClose = () => {
      emit('update:drawerVisible', false);
    };
    const loading = (0, _vue.ref)(false);
    const setScopeOfKeyEventApi = async function () {
      let redo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (scope.orgType === 'limit') {
        if (!scope.organization_tree_ids.length) {
          isShowOrgError.value = true;
          return;
        }
      }
      const scopeObj = {
        date_new: scope.date_new,
        organization_tree_ids: scope.organization_tree_ids,
        conversation_types: scope.conversation_types
      };
      if (loading.value) return;
      loading.value = true;
      const res = await (0, _keyEvent.setScopeOfKeyEvent)({
        event_ids: props.eventIds,
        workspace_id: store.getters.workspaceInfo.id,
        scope: JSON.stringify(scopeObj),
        redo: redo,
        is_all_scope: false
      }).finally(() => loading.value = false);
      if (res.code === 20000) {
        if (redo) {
          if (convCount.value > 0) {
            _elementUi.Message.success('设置成功，重跑已开始，完成时将消息通知您”，自动生成重跑任务开始重跑历史会话，完成后消息通知');
            emit('refreshTaskProgress');
          } else {
            _elementUi.Message.success('设置成功');
          }
        } else {
          _elementUi.Message.success('设置成功');
        }
        handleClose();
        emit('refreshTableData');
      }
    };
    const handleSaveScopeAndRedo = redo => {
      if (redo) {
        if (!props.hasSetModel) {
          _elementUi.Message.warning('请先配置模型');
          return;
        }
        if (convCount.value > 300000) {
          _elementUi.Message.warning('会话数量不能超过30万条，请缩小范围后再试');
          return;
        }
        const firstTip = convCount.value < 5000 ? `将应用此事件重跑` : `因会话数量较大，只有其中的5000条会话将应用此事件重跑`;
        if (props.hasEnableKeyEvent) {
          _elementUi.MessageBox.confirm(`1. 共${convCount.value}条符合以上筛选条件的会话，${firstTip}。</br>2. 新上传或导入的会话，符合筛选条件的，也将自动应用此事件`, '保存并应用到已有会话', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true
          }).then(() => {
            setScopeOfKeyEventApi(redo);
          });
        } else {
          _elementUi.MessageBox.confirm(`当前关键事件是禁用状态,无法应用已有会话,是否启用关键事件?<br />1. 共${convCount.value}条符合以上筛选条件的会话，${firstTip}。<br />2. 新上传或导入的会话，符合筛选条件的，也将自动应用此事件`, '无法应用已有会话', {
            confirmButtonText: '启用并应用会话',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true
          }).then(async () => {
            // 启用关键事件
            const enableRes = await (0, _keyEvent.enableKeyEvent)({
              workspace_id: store.getters.workspaceInfo.id,
              event_ids: props.eventIds
            });
            if (enableRes.code === 20000) {
              setScopeOfKeyEventApi(redo);
            }
          });
        }
      } else {
        setScopeOfKeyEventApi();
      }
    };
    return {
      __sfc: true,
      props,
      store,
      isShowOrgError,
      scope,
      eventName,
      getScopeOfKeyEventApi,
      convCount,
      getConvCountApi,
      conversationTypeList,
      handleConvTypeChange,
      handleOrgTypeChange,
      selectConversationType,
      dateFilterChange,
      editDepartmentDialogVisible,
      getConversationOrgList,
      openEditDepartmentDialog,
      handleChangeDepartment,
      listLoading,
      convListDrawerVisible,
      conversationList,
      conversationTotal,
      openConvDrawer,
      emit,
      handleClose,
      loading,
      setScopeOfKeyEventApi,
      handleSaveScopeAndRedo,
      EditDepartmentDialog: _EditDepartmentDialog.default,
      ScoreRuleDateFilter: _ScoreRuleDateFilter.default,
      ConvListDrawer: _ConvListDrawer.default
    };
  }
};
exports.default = _default;