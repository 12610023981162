"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mgv-avatar-name"
  }, [_c("el-avatar", {
    class: _vm.avatarClass,
    attrs: {
      size: _vm.size,
      shape: "circle",
      src: _vm.src,
      icon: _vm.icon
    }
  }, [!_vm.src && !_vm.icon ? _c("span", {
    style: _vm.nameStyle
  }, [_vm._v(_vm._s(_vm.handleName(_vm.name)))]) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;