"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  name: 'PersonalPlanTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: Number,
      default: 0
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      taskInfo: {}
    };
  },
  computed: {
    tableHeads() {
      return [{
        prop: 'name',
        label: this.$t('coaching.questionName'),
        fixed: 'left'
      }, {
        prop: 'count',
        label: this.$t('coaching.completionStatus'),
        fixed: 'left'
      }, {
        prop: 'average_score',
        label: this.$t('coaching.questionScore'),
        sortable: true,
        fixed: 'left'
      }];
    }
  },
  created() {
    this.getTaskDetail();
  },
  methods: {
    async getTaskDetail() {
      const res = await (0, _coachingCenter.getCoachingTaskInfo)({
        id: this.taskId
      });
      if (res.code === 20000) {
        this.taskInfo = res.results.data;
      }
    },
    goPracticeDetail(row) {
      this.$emit('openPracticeDrawer', row);
    },
    handleSortChange(val) {
      this.$emit('tableSortChange', val);
    },
    getStatus(row) {
      if (this.taskInfo.status === 2) {
        return "<span class='cannot-view'>--</span>";
      }
      const {
        finished,
        count
      } = row;
      if (count === finished) {
        return `<span class='status-complete'>${this.$t('coaching.completed')}</span>`;
      } else {
        return `<span class='status-no-complete'>${this.$t('coaching.notCompleted')}（${count - finished}）</span>`;
      }
    }
  }
};
exports.default = _default;