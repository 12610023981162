"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    attrs: {
      value: _vm.value,
      placeholder: "选择关键事件",
      "popper-class": "data-set-select-popper",
      filterable: "",
      clearable: "",
      disabled: _vm.disabled
    },
    on: {
      input: _setup.onInput,
      change: _setup.onChange
    }
  }, _vm._l(_setup.keyEventList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        disabled: item.positive_count === 0,
        label: item.name
      }
    }, [_c("div", {
      staticClass: "custom-option"
    }, [_c("div", {
      staticClass: "key-event-name"
    }, [_c("i", {
      staticClass: "iconfont icon-key-event"
    }), _vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
      staticClass: "key-event-describe"
    }, [_vm._v("正样本 " + _vm._s(item.positive_count))])])]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;