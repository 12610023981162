"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "allot-dialog-wrapper",
    attrs: {
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog,
      width: "474px",
      "custom-class": "allot-dialog"
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticClass: "title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("el-form", {
    ref: "distributeForm",
    attrs: {
      model: _vm.formData
    }
  }, [_vm.type === "clientAllocateToAnyone" || _vm.type === "clientChangeFollower" || _vm.type === "clueChangeFollower" ? _c("el-form-item", {
    attrs: {
      prop: "userId",
      label: "指定跟进人",
      rules: [{
        required: true,
        message: "请选择跟进人",
        trigger: "change"
      }]
    }
  }, [_c("members-finder", {
    ref: "selectMembers",
    attrs: {
      placeholder: "请选择成员",
      "is-radio": true
    },
    on: {
      change: _vm.handleSelectChange
    }
  })], 1) : _c("el-form-item", [_c("el-cascader", {
    attrs: {
      placeholder: "搜索跟进人",
      options: _vm.options,
      filterable: "",
      props: {
        expandTrigger: "hover"
      }
    },
    model: {
      value: _vm.val,
      callback: function ($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  })], 1)], 1), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;