"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.visible ? _c("el-dialog", {
    staticClass: "mg-common-dialog manual-import-data-set-dialog-wrapper",
    attrs: {
      visible: _vm.visible,
      width: "400px",
      "close-on-click-modal": false,
      title: "导入数据"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "similarity"
  }, [_c("span", [_vm._v("当前页去重后共" + _vm._s(_setup.getSentenceTotal()) + "条数据，导入当前页的前")]), _c("el-input-number", {
    attrs: {
      controls: false,
      min: 0
    },
    on: {
      change: _setup.sentenceNumberChange
    },
    model: {
      value: _setup.sentenceNumber,
      callback: function ($$v) {
        _setup.sentenceNumber = $$v;
      },
      expression: "sentenceNumber"
    }
  }), _c("span", [_vm._v("条数据，默认标注为")])], 1), _c("div", {
    staticClass: "annotation-status"
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("命中")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("未命中")]), _c("el-radio", {
    attrs: {
      label: 3
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("不确定")])], 1)]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  }, [_vm._v("取消")]), _c("MgvButton", {
    attrs: {
      loading: _setup.btnLoading,
      disabled: !_setup.sentenceNumber
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("确定")])], 1)]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;