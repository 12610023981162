"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _RuleEventFormItem = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/RuleEventFormItem.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateKeyEventDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentKeyEvent: {
      type: Object,
      default: () => {}
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submit'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const CUSTOM_MARK = '✏️ ';
    const rules = {
      name: [{
        required: true,
        message: '请输入关键事件名称',
        trigger: 'change'
      }]
    };
    const form = (0, _vue.ref)({
      name: '',
      desc: ''
    });
    const createKeyEventRef = (0, _vue.ref)(null);
    const handleClose = () => {
      emit('update:visible', false);
    };
    const createDataSetApi = async () => {
      return await (0, _dataSet.createDataSet)(form.value);
    };
    const handleSubmit = async () => {
      var _props$currentKeyEven;
      const isPassValid = await createKeyEventRef.value.validate();
      if (!isPassValid) return;
      const formatForm = {
        ...form.value
      };
      formatForm.name = CUSTOM_MARK + formatForm.name;
      emit('submit', (_props$currentKeyEven = props.currentKeyEvent) !== null && _props$currentKeyEven !== void 0 && _props$currentKeyEven.id ? 'edit' : 'create', formatForm);
    };
    const initForm = () => {
      var _props$currentKeyEven2;
      if ((_props$currentKeyEven2 = props.currentKeyEvent) !== null && _props$currentKeyEven2 !== void 0 && _props$currentKeyEven2.id) {
        form.value.name = props.currentKeyEvent.name.replace(CUSTOM_MARK, '');
        form.value.desc = props.currentKeyEvent.messages;
      }
    };
    (0, _vue.watchEffect)(() => {
      initForm();
    });
    return {
      __sfc: true,
      CUSTOM_MARK,
      props,
      rules,
      form,
      createKeyEventRef,
      emit,
      handleClose,
      createDataSetApi,
      handleSubmit,
      initForm,
      RuleEventFormItem: _RuleEventFormItem.default
    };
  }
};
exports.default = _default;