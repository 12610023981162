"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'FormDisplayCascader',
  props: {
    option: {
      type: Array,
      default: () => []
    },
    ids: {
      type: Array,
      default: () => []
    },
    level: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const isShow = id => {
      return props.ids.some(item => item[props.level] === id);
    };
    return {
      __sfc: true,
      props,
      isShow
    };
  }
};
exports.default = _default;