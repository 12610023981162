"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _TaskInfo = _interopRequireDefault(require("./TaskInfo.vue"));
var _ToggleTaskHistorySelect = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskResultDetail/ToggleTaskHistorySelect.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PersonalPlanHeader',
  components: {
    ImageAvatar: _ImageAvatar.default,
    TaskInfo: _TaskInfo.default,
    ToggleTaskHistorySelect: _ToggleTaskHistorySelect.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  props: {
    type: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    taskInfo: {
      type: Object,
      default: () => ({})
    },
    taskScoreInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ROUTES: _routeMap.ROUTES
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    sourceUrlConfig() {
      return {
        teamView: {
          path: _routeMap.ROUTES.coachingCenterTeamView,
          label: this.$t('coaching.teamView')
        },
        leader: {
          path: _routeMap.ROUTES.coachingCenterLeader,
          label: this.$t('coaching.assignedByMe')
        },
        coachTodo: {
          path: this.isEmbed ? this.getEmbedPath(_routeMap.ROUTES.coachingCenterSales) : _routeMap.ROUTES.coachingCenterSales,
          label: this.$t('coaching.myTasks')
        }
      };
    }
  },
  methods: {
    getSourceInfo(key) {
      const source = this.$route.query.source || 'coachTodo';
      return this.sourceUrlConfig[source][key];
    },
    goBack() {
      this.$router.replace({
        path: this.getSourceInfo('path')
      });
    },
    toggleHistory(convId, params) {
      this.$emit('toggleHistory', convId, params);
    }
  }
};
exports.default = _default;