"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "manage-view-list-filter"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择任务状态"
    },
    on: {
      change: _vm.handleStatusChange
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, _vm._l(_vm.statusOptions, function (option) {
    return _c("el-option", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !option.hiddenType.includes(_vm.taskTypeValue),
        expression: "!option.hiddenType.includes(taskTypeValue)"
      }],
      key: option.value,
      attrs: {
        label: option.label,
        value: option.value
      }
    }, [_c("div", {
      staticClass: "option"
    }, [_c("div", {
      staticClass: "option-left"
    }, [_c("span", [_vm._v(_vm._s(option.label))]), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "到期未完成的培训任务，因不可再完成，状态显示为“已截止”",
        placement: "top"
      }
    }, [option.value === 3 ? _c("i", {
      staticClass: "iconfont icon-info-1"
    }) : _vm._e()])], 1), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: option.value === _vm.status,
        expression: "option.value === status"
      }],
      staticClass: "iconfont icon-check selected"
    })])]);
  }), 1), _c("members-finder", {
    ref: "selectMembers",
    attrs: {
      placeholder: "请选择执行人",
      "has-self": false,
      "get-member-api-type": "data",
      params: {
        type: JSON.stringify(["assist"]),
        workspace_id: _vm.workspaceInfo.id
      },
      "collapse-tags": "",
      "tree-config": _vm.treeConfig
    },
    on: {
      change: _vm.handleExecutorChange
    }
  }), _c("create-date", {
    staticClass: "create-date",
    attrs: {
      item: _vm.deadlineOptions,
      "show-tooltip": false
    },
    on: {
      change: _vm.deadlineChange
    },
    model: {
      value: _vm.deadline,
      callback: function ($$v) {
        _vm.deadline = $$v;
      },
      expression: "deadline"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;