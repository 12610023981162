"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    link: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      popperShow: false
    };
  },
  methods: {
    onCopySuccess() {
      this.$message.success('已复制');
      this.popperShow = false;
    }
  }
};
exports.default = _default;