"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generator-cascader"
  }, [_c("el-input", {
    staticClass: "fake-input",
    attrs: {
      placeholder: "请选择",
      readonly: ""
    }
  }), _c("div", {
    staticClass: "cascader-option-generator"
  }, _vm._l(_setup.renders, function (column, columnIndex) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: columnIndex < 3,
        expression: "columnIndex < 3"
      }],
      key: columnIndex,
      staticClass: "option-column"
    }, [_c("div", {
      staticClass: "column-header"
    }, [_c("span", {
      staticClass: "column-name"
    }, [_vm._v(_vm._s(_setup.NUMS_TRANS[columnIndex]) + "级选项")]), _c("div", {
      staticClass: "column-operation"
    }, [_c("span", {
      staticClass: "info",
      class: {
        "is-disabled": _setup.isColumnScoreDisable(column)
      }
    }, [_vm._v("分值")]), _c("el-tooltip", {
      attrs: {
        content: "仅最后一级选项可设置分值，减分请用“-10”形式",
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-info-1"
    })]), _c("span", {
      staticClass: "divider"
    }, [_vm._v("｜")]), _c("el-popconfirm", {
      staticClass: "delete-column",
      attrs: {
        title: `删除“${_setup.NUMS_TRANS[columnIndex]}级选项”及其全部子级`,
        icon: "el-icon-info",
        "confirm-button-type": "danger"
      },
      on: {
        onConfirm: function ($event) {
          return _setup.deleteColumn(column);
        }
      }
    }, [_c("el-button", {
      staticClass: "delete-button",
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_c("i", {
      staticClass: "iconfont icon-delete"
    })])], 1)], 1)]), _c(_setup.MgDraggable, {
      attrs: {
        value: column
      },
      on: {
        input: function ($event) {
          return _setup.handDragEnd($event, columnIndex);
        }
      }
    }, _vm._l(column, function (item, itemIndex) {
      var _item$children;
      return _c("div", {
        key: item.id,
        class: ["option-item", {
          "is-active": item.is_active
        }],
        on: {
          click: function ($event) {
            return _setup.handleActive(column, item.id, columnIndex);
          }
        }
      }, [_c("i", {
        staticClass: "iconfont move icon-drag-dot operation"
      }), _c("el-input", {
        staticClass: "option-item-input",
        model: {
          value: item.name,
          callback: function ($$v) {
            _vm.$set(item, "name", $$v);
          },
          expression: "item.name"
        }
      }), !((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) ? _c("el-input", {
        staticClass: "score-input",
        attrs: {
          value: item.score,
          type: "number"
        },
        on: {
          input: function ($event) {
            return _setup.handleScoreInput($event, item);
          }
        }
      }) : _vm._e(), _c("el-popconfirm", {
        attrs: {
          title: `删除“${item.name}”及其全部子级`,
          icon: "el-icon-info",
          "confirm-button-type": "danger"
        },
        on: {
          onConfirm: function ($event) {
            return _setup.deleteOptionItem(column, itemIndex);
          }
        }
      }, [_c("i", {
        staticClass: "iconfont icon-close operation",
        attrs: {
          slot: "reference"
        },
        slot: "reference"
      })])], 1);
    }), 0), _c("el-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: column.length < 10,
        expression: "column.length < 10"
      }],
      staticClass: "add-option-item",
      attrs: {
        type: "text",
        disabled: column.length > 9
      },
      on: {
        click: function ($event) {
          return _setup.addOptionItem(column);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-plus"
    }), _c("span", [_vm._v("添加子选项（" + _vm._s(column.length) + "/10）")])])], 1);
  }), 0)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;