"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueTour = _interopRequireDefault(require("vue-tour"));
var _vueVirtualScrollList = _interopRequireDefault(require("vue-virtual-scroll-list"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const components = [_vueTour.default];

// 虚拟滚动组件

const r = require.context('.', true, /index.js/);
r.keys().filter(item => item !== './index.js').map(path => {
  components.push(r(path).default || r(path));
});

// 定义 install 方法，接收 Vue 作为参数
const install = function (Vue) {
  // 判断是否安装，安装过就不继续往下执行
  if (install.installed) return;
  install.installed = true;
  // 遍历注册所有组件
  // components.map(component => Vue.component(component.name, component))
  // 下面这个写法也可以
  components.map(component => Vue.use(component));
};
// 检测到 Vue 才执行
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
var _default = {
  install,
  // 所有组件，必须具有 install，才能使用 Vue.use()
  ...components,
  VirtualList: _vueVirtualScrollList.default
};
exports.default = _default;