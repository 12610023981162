"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "upload-result"
  }, [_c("div", {
    staticClass: "content",
    class: {
      isMembers: _vm.isMembers
    }
  }, [_c("svg-icon", {
    class: [`icon-${_vm.iconClass}`],
    attrs: {
      iconClass: _vm.iconClass
    }
  }), _vm.status !== "error" ? _c("p", {
    staticClass: "tip"
  }, [_vm._v("数据导入完成")]) : _c("p", {
    staticClass: "tip"
  }, [_vm._v("数据导入失败")]), !_vm.isMembers ? [_vm.status !== "error" ? _c("p", {
    staticClass: "tip describe"
  }, [_vm._v(" 导入总数据"), _c("span", {
    staticClass: "total"
  }, [_vm._v(" " + _vm._s(_vm.results.total) + " ")]), _vm._v("条，覆盖"), _c("span", {
    staticClass: "cover"
  }, [_vm._v(" " + _vm._s(_vm.results.cover_count) + " ")]), _vm._v("条，导入成功"), _c("span", {
    staticClass: "success"
  }, [_vm._v(" " + _vm._s(_vm.results.success_count) + " ")]), _vm._v("条，导入失败"), _c("span", {
    staticClass: "fail",
    class: {
      "not-empty": _vm.results.fail_count
    }
  }, [_vm._v(" " + _vm._s(_vm.results.fail_count) + " ")]), _vm._v("条 ")]) : _vm._e()] : [_vm.status !== "error" ? _c("p", {
    staticClass: "tip describe"
  }, [_vm._v(" 识别总数据"), _c("span", {
    staticClass: "total"
  }, [_vm._v(" " + _vm._s(_vm.results.total) + " ")]), _vm._v("条，导入成功"), _c("span", {
    staticClass: "success"
  }, [_vm._v(" " + _vm._s(_vm.results.success_count) + " ")]), _vm._v("条"), _vm.results.abnormal_count ? [_vm._v("（含 "), _c("span", {
    staticClass: "unusual"
  }, [_vm._v(" " + _vm._s(_vm.results.abnormal_count) + " ")]), _vm._v("条异常）")] : _vm._e(), _vm._v("，导入失败"), _c("span", {
    staticClass: "fail",
    class: {
      "not-empty": _vm.results.fail_count
    }
  }, [_vm._v(" " + _vm._s(_vm.results.fail_count) + " ")]), _vm._v("条 ")], 2) : _vm._e()], _vm.results.err_id ? _c("p", {
    staticClass: "tip"
  }, [_c("el-link", {
    staticClass: "download-link",
    on: {
      click: function ($event) {
        return _vm.downloadError("error", "错误数据");
      }
    }
  }, [_vm._v("下载错误数据")])], 1) : _vm._e(), _vm.results.fail_id ? _c("p", {
    staticClass: "tip"
  }, [_c("el-link", {
    staticClass: "download-link",
    on: {
      click: function ($event) {
        return _vm.downloadError("fail", "失败报告");
      }
    }
  }, [_vm._v("下载失败报告")])], 1) : _vm._e(), _vm.results.abnormal_id ? _c("p", {
    staticClass: "tip"
  }, [_c("el-link", {
    staticClass: "download-link",
    on: {
      click: function ($event) {
        return _vm.downloadError("abnormal", "异常报告");
      }
    }
  }, [_vm._v("下载异常报告")])], 1) : _vm._e(), _vm.status === "error" ? _c("p", {
    staticClass: "tip"
  }, [_vm._v(" 模板格式不正确，请"), _c("el-link", {
    staticClass: "download-link",
    on: {
      click: _vm.downloadTemplate
    }
  }, [_vm._v("下载最新模板")])], 1) : _vm._e()], 2), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("complete");
      }
    }
  }, [_vm._v("完 成")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;