"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-entry"
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom",
      "popper-class": "chat-entry-tips",
      trigger: "hover",
      disabled: _vm.disabled
    }
  }, [_c("video", {
    attrs: {
      autoplay: "",
      loop: "",
      muted: ""
    },
    domProps: {
      muted: true
    }
  }, [_c("source", {
    attrs: {
      src: _vm.videoSrc,
      type: "video/mp4"
    }
  })]), _c("div", {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c("div", {
    staticClass: "got-it",
    on: {
      click: _vm.hideTips
    }
  }, [_vm._v("我知道了")]), _c("chat-btn", {
    attrs: {
      slot: "reference"
    },
    nativeOn: {
      click: function ($event) {
        return _vm.$emit("openChatDrawer");
      }
    },
    slot: "reference"
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;