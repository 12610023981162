"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-root"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_c("div", {
    staticClass: "page-title"
  }, [_vm._v("商机")]), _c("space-button"), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "商机设置",
      placement: "top"
    }
  }, [_vm.hasDealSetPermission ? _c("el-button", {
    staticClass: "deal-setting hover-show-bg",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleSettingClick
    }
  }, [_c("i", {
    staticClass: "iconfont icon-setting"
  })]) : _vm._e()], 1)], 1), _c("kanban", {
    ref: "kanban",
    staticClass: "viewer-container",
    attrs: {
      list: _vm.kanbanList,
      "active-id": _vm.activeKanban.id
    },
    on: {
      deleteKanban: _vm.handleDeleteKanban,
      kanbanChange: _vm.handleKanbanChange,
      createNewKanban: _vm.createNewKanban
    }
  })], 1), _c("div", {
    staticClass: "business-main"
  }, [_c("header", {
    staticClass: "header-filter-container"
  }, [_c("div", {
    staticClass: "header-row"
  }, [_c("header-filters", {
    key: _vm.workspaceInfo.id,
    ref: "businessFilter",
    attrs: {
      "custom-filter-object": _vm.customFilterObject,
      "key-events": _vm.keyEvents,
      "active-kanban": _vm.activeKanban
    },
    on: {
      filterChange: _vm.headerFiltersChange
    }
  }), _c("dynamic-popover", {
    attrs: {
      type: "business",
      "incoming-list": _vm.activeKanban.head_conf,
      "popper-class": "head-set-pop"
    },
    on: {
      getTableHead: _vm.getTableHead,
      confirmAndSaveTableHead: _vm.confirmAndSaveTableHead
    }
  }, [_c("el-button", {
    staticClass: "column-btn",
    attrs: {
      size: "small",
      icon: "iconfont icon-setting"
    }
  }, [_vm._v("列设置")])], 1), _c("el-dropdown", {
    staticClass: "save-view-btn",
    attrs: {
      "split-button": "",
      size: "small",
      placement: "bottom-start",
      "visible-arrow": false
    },
    on: {
      click: function ($event) {
        return _vm.handleSave();
      },
      command: _vm.handelSaveCommand
    }
  }, [_vm._v(" 保存 "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "isSaveAsNew"
    }
  }, [_vm._v("另存为新视图")])], 1)], 1), _vm.currentDealIds.length ? _c("other-page-carrying-filter", {
    attrs: {
      label: _vm.otherPageCarryingFilterLabel
    },
    on: {
      clearFilter: _vm.handleClearOtherPageFilter
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "business-buttons"
  }, [_c("create-deal"), _c("table-export", {
    attrs: {
      "request-method": _vm.exportBusinessList,
      "request-params": _vm.exportRequestParams
    }
  })], 1)]), _c("business-table", {
    ref: "businessTable",
    attrs: {
      "header-list": _vm.headerList,
      list: _vm.list,
      table: _vm.table,
      "list-params": _vm.fresh,
      loading: _vm.tableLoading,
      "double-call-permission": _vm.doubleCallPermission,
      "table-height": _vm.tableHeight
    },
    on: {
      sortChange: _vm.tableSortChange,
      rowClick: _vm.goBusinessDetail,
      objectionClick: _vm.handleObjectionClick,
      getNextSituation: _vm.getBusinessSituation
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("mg-pagination", {
    attrs: {
      total: _vm.total,
      "current-page": _vm.listParams.current_page,
      "page-size": _vm.listParams.size,
      "hide-last-page": ""
    },
    on: {
      sizeChange: _vm.sizeChange,
      currentChange: _vm.currentChange
    }
  })], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;