"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MultipleCascaderTracker = _interopRequireDefault(require("./MultipleCascaderTracker.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountLabelCascaderSelect',
  components: {
    MultipleCascaderTracker: _MultipleCascaderTracker.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [String, Object],
      default: () => ({})
    },
    otherPageAccountLabel: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    chosenCount() {
      var _this$value;
      if ((_this$value = this.value) !== null && _this$value !== void 0 && _this$value.value) {
        return this.value.value.reduce((total, item) => {
          return total + item.length;
        }, 0);
      } else {
        return false;
      }
    }
  },
  methods: {
    addKeyEventCascader() {
      this.$refs['accountLabelPopoverRef'].updatePopper();
    }
  }
};
exports.default = _default;