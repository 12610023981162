"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    attrs: {
      content: _setup.getTipContent,
      disabled: _vm.urgeStatus === 0 && _vm.planStatus === 0
    }
  }, [_c("MgvButton", {
    staticClass: "urge-button",
    attrs: {
      type: "plain",
      color: "info",
      icon: "iconfont icon-bell",
      disabled: _vm.urgeStatus === 1 || _vm.planStatus !== 0,
      loading: _setup.urgeLoading
    },
    on: {
      click: _setup.allUrge
    }
  }, [_vm._v(_vm._s(_setup.t("coaching.remindAll")))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;