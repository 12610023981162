"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _i18n = require("@/i18n");
var _default = {
  __name: 'AllUrgeButton',
  props: {
    planId: {
      type: Number,
      default: 0
    },
    planStatus: {
      type: Number,
      default: 1
    },
    urgeStatus: {
      type: Number,
      default: 0
    }
  },
  emits: ['allUrgeSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const allUrge = () => {
      _elementUi.MessageBox.confirm((0, _i18n.t)('coaching.confirmReminderContent'), (0, _i18n.t)('coaching.confirmReminderTitle'), {
        type: '',
        confirmButtonText: (0, _i18n.t)('coaching.confirm'),
        cancelButtonText: (0, _i18n.t)('coaching.cancel')
      }).then(async () => {
        postUrgeAllTask();
      }).catch(() => {});
    };
    const getTipContent = (0, _vue.computed)(() => {
      if (props.urgeStatus === 1) {
        return (0, _i18n.t)('coaching.hasReminded');
      } else {
        if (props.planStatus !== 0) {
          const map = {
            1: `${(0, _i18n.t)('coaching.task')}${(0, _i18n.t)('coaching.deadlinePassed')}`,
            2: `${(0, _i18n.t)('coaching.task')}${(0, _i18n.t)('coaching.notStarted')}`,
            3: `${(0, _i18n.t)('coaching.task')}${(0, _i18n.t)('coaching.completed')}`
          };
          return map[props.planStatus];
        }
      }
      return '';
    });
    const urgeLoading = (0, _vue.ref)(false);
    async function postUrgeAllTask() {
      urgeLoading.value = true;
      const res = await (0, _coachingCenter.urgeTask)({
        plan_id: props.planId
      }).finally(() => {
        urgeLoading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success((0, _i18n.t)('coaching.全部催促成功'));
        emit('allUrgeSuccess');
      }
    }
    return {
      __sfc: true,
      props,
      allUrge,
      getTipContent,
      emit,
      urgeLoading,
      postUrgeAllTask,
      t: _i18n.t
    };
  }
};
exports.default = _default;