"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AccountManagement = _interopRequireDefault(require("./components/AccountManagement"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Bill',
  components: {
    AccountManagement: _AccountManagement.default
  }
};
exports.default = _default;