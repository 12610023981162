"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _default = {
  __name: 'PlanAverageScore',
  props: {
    score: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    return {
      __sfc: true,
      t: _i18n.t
    };
  }
};
exports.default = _default;