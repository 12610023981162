"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "send-box-header"
  }, [_setup.commentStatus === "help" ? _c("div", {
    staticClass: "select-teacher"
  }, [_c("div", {
    staticClass: "select-container"
  }, [_vm._m(0), _c("div", [_vm._v("向")]), _c("el-select", {
    ref: "teacher-select",
    staticStyle: {
      width: "140px",
      height: "36px"
    },
    attrs: {
      value: _vm.value,
      filterable: "",
      remote: "",
      placeholder: "请输入",
      "remote-method": _setup.remoteTeacherName
    },
    on: {
      change: function ($event) {
        return _setup.emit("input", $event);
      }
    }
  }, _vm._l(_setup.nameOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("div", [_vm._v("申请辅导")])], 1), _c("el-link", {
    attrs: {
      underline: false,
      type: "primary"
    },
    on: {
      click: _setup.cancelAskHelp
    }
  }, [_vm._v("取消")])], 1) : _vm._e(), _setup.commentStatus === "teach" ? _c("div", {
    staticClass: "teach-container"
  }, [_vm._m(1), _c("el-link", {
    attrs: {
      underline: false,
      type: "primary"
    },
    on: {
      click: _setup.cancelTeach
    }
  }, [_vm._v("取消")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ask-help-icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-hand-2"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "teach-icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-suggest"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;