"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.showDepartmentAndMember ? _c("div", [_c("DepartmentHeader", {
    on: {
      addPerson: _vm.addPerson,
      invitePerson: _vm.invitePerson,
      openBatchModifyInfo: _vm.openBatchModifyInfo,
      goResignedMemberPage: _vm.goResignedMemberPage
    }
  }), _c("div", {
    staticClass: "org-card"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("div", {
    staticClass: "content-top"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.$t("management.组织架构")))]), _vm.orgTree.length ? _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      "tooltip-content": _vm.$t("management.添加子部门")
    },
    on: {
      click: function ($event) {
        return _vm.appendDepartment(_vm.currNode);
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "tree-box"
  }, [_c("el-tree", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.orgTreeLoading,
      expression: "orgTreeLoading"
    }],
    ref: "department-tree",
    attrs: {
      data: _vm.orgTree,
      props: _vm.defaultProps,
      "node-key": "id",
      "expand-on-click-node": false,
      "highlight-current": true,
      "default-expanded-keys": _vm.departmentExpanded
    },
    on: {
      "node-click": _vm.handleDepartmentNodeClick
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          node,
          data
        } = _ref;
        return [_c("span", {
          class: {
            "custom-tree-node": !(data.editType === "edit" || data.editType === "add")
          },
          staticStyle: {
            "z-index": "1"
          }
        }, [data.node_type === "root" ? _c("svg-icon", {
          attrs: {
            "icon-class": "office-building"
          }
        }) : _c("svg-icon", {
          attrs: {
            "icon-class": "department"
          }
        }), data.editType === "edit" || data.editType === "add" ? [_c("el-input", {
          ref: "department-input",
          staticClass: "edit-input",
          attrs: {
            value: _vm.departmentName
          },
          on: {
            input: _vm.handleNameInput,
            blur: function ($event) {
              return _vm.submitDepartmentEdit(node, data);
            }
          },
          nativeOn: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.focusMethod();
            },
            keypress: [function ($event) {
              if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              $event.stopPropagation();
              return _vm.submitDepartmentEdit(node, data);
            }, function ($event) {
              $event.stopPropagation();
              return _vm.nameKeypress($event);
            }]
          }
        }), _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isViolation,
            expression: "isViolation"
          }],
          staticClass: "hint"
        }, [_vm._v(" " + _vm._s(_vm.$t("management.特殊字符不支持")) + ",/ ")])] : [_c("span", [_vm._v(_vm._s(data.name))]), _c("span", {
          staticClass: "dropdown-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("el-dropdown", {
          attrs: {
            trigger: "click",
            placement: "bottom-start"
          }
        }, [_c("div", {
          staticClass: "icon-wrapper"
        }, [_c("i", {
          staticClass: "el-icon-more more"
        })]), _c("el-dropdown-menu", {
          staticClass: "org-dropdown",
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-plus"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.appendDepartment(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.添加子部门")))]), _c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-edit"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.editDepartmentName(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.重命名")))]), data.fid ? _c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-folder-move"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.moveDepartment(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.移动部门")))]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-roles",
            divided: ""
          },
          nativeOn: {
            click: function ($event) {
              return _vm.setDepartmentCharge(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.设置部门负责人")))]), data.fid ? _c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-team-switch-2"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.transferDepartmentData(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.转移数据")))]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "iconfont icon-export"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.exportDepartment(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.导出部门")))]), data.fid ? _c("el-dropdown-item", {
          staticClass: "dropdown-item-danger",
          attrs: {
            divided: "",
            icon: "iconfont icon-delete"
          },
          nativeOn: {
            click: function ($event) {
              return _vm.handleDepartmentData(node, data);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("management.删除部门")))]) : _vm._e()], 1)], 1)], 1)]], 2)];
      }
    }], null, false, 2957435515)
  })], 1)]), _c("div", {
    staticClass: "mid-line"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  })], 1), _c("div", {
    staticClass: "right-content"
  }, [_c("DepartmentInfo", {
    attrs: {
      "current-department-name": _vm.currName,
      "current-department-member-count": _vm.memberCount,
      "multiple-select": _vm.multipleSelect,
      "department-charge": _vm.departmentCharge,
      "all-member-counter": _vm.allMemberCounter,
      "limit-count": _vm.limitCount,
      "member-count": _vm.memberCount,
      "export-api": _vm.exportMembersApi
    },
    on: {
      setDepartmentCharge: _vm.setDepartmentCharge
    }
  }), _c("DepartmentFilters", {
    ref: "departmentFiltersRef",
    attrs: {
      "workspace-list": _vm.workspaceList
    },
    on: {
      filterChange: _vm.handleFilterChange
    }
  }), _c("div", {
    staticClass: "table-view view-height"
  }, [_c("DepartmentMembers", {
    ref: "department-members",
    attrs: {
      "list-loading": _vm.listLoading,
      "user-list": _vm.userList,
      "curr-data": _vm.currData,
      "org-tree": _vm.orgTree,
      "org-id": _vm.orgId,
      "workspace-list": _vm.workspaceList
    },
    on: {
      multipleSelect: _vm.handleMultipleSelect,
      freshCurrentList: _vm.freshCurrentList,
      fetchDepartmentData: _vm.fetchDepartmentData,
      openAddDialog: function ($event) {
        _vm.membersFinderVisible = true;
      },
      openCopy: _vm.openMemberInfoDialog
    }
  })], 1), _c("div", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "direct-member-switch"
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#4461EC",
      disabled: _vm.isSwitchDisabled,
      width: 34
    },
    on: {
      change: _vm.fetchUserInfoList
    },
    model: {
      value: _vm.isDirectMember,
      callback: function ($$v) {
        _vm.isDirectMember = $$v;
      },
      expression: "isDirectMember"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.$t("management.仅显示当前部门直属人员")))])], 1), _c("el-pagination", {
    staticClass: "pagination list-pagination",
    attrs: {
      "current-page": _vm.params.current_page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.params.size,
      layout: "total, prev, pager, next, sizes",
      total: _vm.resultsTotal
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1), _c("batch-modify", {
    attrs: {
      "dialog-visible": _vm.batchModifyVisible,
      "user-ids": _vm.multipleSelect,
      "user-multiple-array": _vm.userMultipleArray,
      "workspace-list": _vm.workspaceList
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.batchModifyVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.batchModifyVisible = $event;
      },
      refresh: _vm.fetchDepartmentData
    }
  }), _c("org-invitation", {
    staticClass: "org-invitation",
    attrs: {
      visible: _vm.inviteVisible,
      "org-id": _vm.orgId,
      "workspace-list": _vm.workspaceList
    },
    on: {
      closeInviteDialog: _vm.closeInviteDialog,
      openCopy: function ($event) {
        _vm.copyVisible = true;
      }
    }
  }), _c("add-new-member", {
    staticClass: "add-new-member",
    attrs: {
      visible: _vm.addMemberVisible,
      "org-id": _vm.orgId,
      "workspace-list": _vm.workspaceList
    },
    on: {
      closeInviteDialog: _vm.closeAddMemberDialog,
      openCopy: _vm.openMemberInfoDialog
    }
  }), _c("copy-invitation", {
    staticClass: "copy-invitation",
    attrs: {
      visible: _vm.copyVisible,
      "org-name": _vm.orgName
    },
    on: {
      closeCopyDialog: _vm.closeCopy
    }
  }), _c("member-info", {
    attrs: {
      visible: _vm.memberInfoVisible,
      type: _vm.showType,
      password: _vm.userPassword,
      account: _vm.account
    },
    on: {
      "update:visible": function ($event) {
        _vm.memberInfoVisible = $event;
      }
    }
  }), _c("inactivated", {
    staticClass: "inactivated",
    attrs: {
      visible: _vm.inactivatedVisible,
      "org-name": _vm.orgName
    },
    on: {
      closeInactivated: _vm.closeInactivated
    }
  }), _c("members-finder-dialog", {
    attrs: {
      "tree-id": _vm.deptId
    },
    on: {
      submitSucceed: _vm.fetchDepartmentData
    },
    model: {
      value: _vm.membersFinderVisible,
      callback: function ($$v) {
        _vm.membersFinderVisible = $$v;
      },
      expression: "membersFinderVisible"
    }
  }), _c("user-scale-dialog", {
    attrs: {
      "user-scale-dialog-visible": _vm.userScaleDialogVisible
    },
    on: {
      closeUserScaleDialog: _vm.closeUserScaleDialog
    }
  }), _c("member-single-select-dialog", {
    ref: "setChargeDom",
    attrs: {
      visible: _vm.departmentChargeDialogVisible,
      title: _vm.$t("management.设置部门负责人"),
      "current-user": _vm.departmentCharge,
      "is-set-head": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.departmentChargeDialogVisible = $event;
      },
      getUserId: _vm.getUserId
    }
  }), _c("data-transfer-drawer", {
    attrs: {
      visible: _vm.dataTransferDrawerVisible,
      title: _vm.transferDepartmentTitle,
      "transfer-type": "department",
      "is-delete-department": _vm.isDeleteDepartment,
      "can-transfer-data": _vm.canTransferData,
      "transfer-from-user-info": _vm.currentDepartmentInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.dataTransferDrawerVisible = $event;
      }
    }
  }), _c("MoveDepartmentDialog", {
    attrs: {
      visible: _vm.moveDepartmentDialogVisible,
      "move-department-id": _vm.operateDepartmentId
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveDepartmentDialogVisible = $event;
      },
      moveDepartmentSuccess: _vm.onMoveDepartmentSuccess
    }
  })], 1)], 1) : _c("resigned-member", {
    on: {
      goBack: _vm.goDepartmentAndMember
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;