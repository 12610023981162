"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "department-header"
  }, [_c("h5", [_vm._v(_vm._s(_setup.t("management.部门和成员管理")))]), _c("div", {
    staticClass: "invite-btn-wrapper"
  }, [_setup.ENV_TITLE === "image" ? _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.addPerson
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "user-add"
    }
  }), _vm._v(_vm._s(_setup.t("management.邀请新成员")) + " ")], 1) : _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.invitePerson
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "user-add"
    }
  }), _vm._v(" " + _vm._s(_setup.t("management.邀请新成员")) + " ")], 1), _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.importMembers
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "import"
    }
  }), _vm._v(_vm._s(_setup.t("management.导入成员")) + " ")], 1), _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.importDepartment
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "import"
    }
  }), _vm._v(_vm._s(_setup.t("management.导入部门")) + " ")], 1), _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.openBatchModifyInfo
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "edit"
    }
  }), _vm._v(_vm._s(_setup.t("management.批量修改成员信息")) + " ")], 1), _c("el-button", {
    staticClass: "icon-button el-button-standard",
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.goResignedMemberPage
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "resignation"
    }
  }), _vm._v(_vm._s(_setup.t("management.已离职成员")) + " ")], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;