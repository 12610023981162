"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _TaskResultDetailDrawer = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskResultDetail/TaskResultDetailDrawer.vue"));
var _vue = require("vue");
var _courseType = _interopRequireDefault(require("@/views/coaching/coach/course/course-type.js"));
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TaskDetailTable',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  emits: 'sort-change',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const TaskResultDetailDrawerVisible = (0, _vue.ref)(false);
    const headers = [{
      prop: 'user_info',
      label: '学员',
      minWidth: 200
    }, {
      prop: 'score',
      label: '得分',
      sortable: 'custom'
    }, {
      prop: 'practice_times',
      sortable: 'custom',
      label: '练习次数'
    }, {
      prop: 'is_complete',
      label: '任务状态'
    }, {
      prop: 'plan_start_time',
      label: '任务开始时间',
      sortable: 'custom',
      minWidth: 200
    }, {
      prop: 'user_start_time',
      label: '练习开始时间',
      sortable: 'custom',
      minWidth: 200
    }, {
      prop: 'category',
      label: '任务分类',
      isShowOverflowTooltip: true
    }, {
      prop: 'course_name',
      label: '课程名称',
      minWidth: 240,
      isShowOverflowTooltip: true
    }, {
      prop: 'course_type',
      label: '课程类型'
    }];
    const currentTaskInfo = (0, _vue.ref)({});
    const currentTaskId = (0, _vue.ref)(0);
    const openDetailDrawer = row => {
      console.log(row);
      currentTaskId.value = row.plan_id;
      currentTaskInfo.value = {
        courseType: row.course_type,
        executorInfo: row.user_info
      };
      TaskResultDetailDrawerVisible.value = true;
    };
    const sortChange = _ref2 => {
      let {
        prop,
        order
      } = _ref2;
      emit('sort-change', {
        prop,
        order: order === 'ascending' ? 'asc' : 'desc'
      });
    };
    return {
      __sfc: true,
      props,
      TaskResultDetailDrawerVisible,
      headers,
      currentTaskInfo,
      currentTaskId,
      openDetailDrawer,
      emit,
      sortChange,
      NoData: _NoData.default,
      TaskResultDetailDrawer: _TaskResultDetailDrawer.default,
      courseType: _courseType.default,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone
    };
  }
};
exports.default = _default;