"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "copy-invitation"
  }, [_c("el-dialog", {
    attrs: {
      title: "邀请成员",
      visible: _vm.visible,
      "before-close": _vm.closeSelf
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.closeSelf.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "tip-suc"
  }, [_c("el-button", {
    staticClass: "success-icon el-button-icon",
    attrs: {
      type: "success",
      icon: "el-icon-check",
      circle: ""
    }
  }), _c("span", {
    staticClass: "text-main first-line"
  }, [_vm._v("邀请已发送")]), _c("span", {
    staticClass: "text-common second-line"
  }, [_vm._v("您也可以通过发送链接告知团队成员")])], 1), _c("div", {
    staticClass: "invite-text"
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      resize: "none",
      readonly: ""
    },
    model: {
      value: _vm.inviteText,
      callback: function ($$v) {
        _vm.inviteText = $$v;
      },
      expression: "inviteText"
    }
  })], 1), _c("div", [_c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.inviteText,
      expression: "inviteText",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    staticClass: "copy-invite",
    attrs: {
      icon: "el-icon-copy-document"
    }
  }, [_vm._v("复制邀请")])], 1)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "el-button--primary",
    on: {
      click: _vm.closeSelf
    }
  }, [_vm._v("完 成")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;