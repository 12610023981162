"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "transer-members-finder"
  }, [_c("div", {
    staticClass: "part-left"
  }, [_c("div", {
    staticClass: "input-container"
  }, [_c("member-with-department-input", {
    on: {
      select: _vm.handleSelect
    }
  })], 1), _c("lazy-tree", {
    ref: "tree",
    attrs: {
      value: _vm.fullDepartmentUsers,
      "is-unrelated": "",
      "get-member-api-type": "manage"
    },
    on: {
      checkChange: _vm.handleCheckChange
    }
  })], 1), _c("div", {
    staticClass: "part-right"
  }, [_c("h5", [_vm._v(_vm._s(_vm.$t("management.已选成员")) + "(" + _vm._s(_vm.selection.length) + ")")]), _c("div", {
    staticClass: "user-container"
  }, _vm._l(_vm.selection, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "user-box"
    }, [_c("figure", [_c("image-avatar", {
      attrs: {
        size: "small",
        avatar: item.avatar,
        name: item.name
      }
    }), _c("figcaption", [_vm._v(" " + _vm._s(item.name) + " "), _c("span", {
      staticClass: "departments"
    }, [_vm._v("（"), _vm._l(item.departments, function (department, index) {
      return _c("span", {
        key: department.id
      }, [index ? _c("span", [_vm._v("、")]) : _vm._e(), _vm._v(_vm._s(department.name))]);
    }), _vm._v("）")], 2)])], 1), _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          return _vm.removeUser(item.id);
        }
      }
    })]);
  }), 0)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;