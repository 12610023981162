"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TranserMembersFinder',
  components: {},
  props: {
    defaultChecked: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: Function,
      require: true
    },
    noPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selection: [],
      orgTree: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultExpanded: [],
      checkedIdList: [],
      treeLoading: false
    };
  },
  computed: {},
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getOrgTreeOptions();
        }
      },
      immediate: true
    },
    selection: {
      handler(val) {
        this.$emit('handleChange', val, this.checkedIdList);
      },
      deep: true
    }
  },
  created() {},
  methods: {
    async getOrgTreeOptions() {
      this.treeLoading = true;
      const res = await this.remoteMethod();
      this.treeLoading = false;
      if (res.code === 200) {
        this.orgTree = res.results;
        if (this.noPermission) {
          this.$emit('getManageTree', res.results.map(item => {
            return {
              id: item.id,
              name: item.name,
              fid: item.fid
            };
          }));
        }
        if (!this.orgTree.length) return;
        const {
          id
        } = res.results[0];
        this.checkedIdList = this.defaultChecked;
        if (!this.orgTree[0].fid) {
          this.orgTree[0].disabled = true;
          if (this.defaultChecked.includes(id)) {
            this.checkedIdList = [];
          }
        }
        this.defaultExpanded = [id];
        this.deleteCheckbox();
        this.handleCheckChange();
      }
    },
    deleteCheckbox() {
      this.$nextTick(() => {
        const rootNode = document.getElementsByClassName('manage-scope-root-department');
        for (let i = 0; i < rootNode.length; i++) {
          rootNode.item(i).previousSibling.previousSibling.style.display = 'none';
        }
      });
    },
    handleCheckChange(_data, isCheck) {
      // 点击节点逻辑
      if (isCheck) {
        const node = this.$refs.departmentTree.getNode(_data);
        if (node.checked) {
          node.expanded = false;
          node.isLeaf = true;
        } else {
          if (_data.children && _data.children.length) {
            node.isLeaf = false;
            this.handleCheckedChildren([node]);
          }
        }
        this.checkedIdList = this.$refs.departmentTree.getCheckedKeys();
        this.selection = this.$refs.departmentTree.getCheckedNodes();
      } else {
        // 弹窗打开后的逻辑
        this.$nextTick(() => {
          this.selection = this.$refs.departmentTree.getCheckedNodes();
          this.selection.forEach(item => {
            const node = this.$refs.departmentTree.getNode(item);
            node.expanded = false;
            node.isLeaf = true;
          });
        });
      }
    },
    handleCheckedChildren(node) {
      node.forEach(item => {
        if (item.checked) {
          item.checked = false;
          if (item.childNodes.length) {
            item.isLeaf = false;
          }
        }
        if (item.childNodes && item.childNodes.length) {
          this.handleCheckedChildren(item.childNodes);
        }
      });
    },
    removeDepartment(id) {
      const data = this.selection.filter(item => item.id === id);
      this.selection = this.selection.filter(item => item.id !== id);
      const index = this.checkedIdList.findIndex(c => c === id);
      this.checkedIdList.splice(index, 1);
      this.$refs.departmentTree.setCheckedKeys(this.checkedIdList);
      const node = this.$refs.departmentTree.getNode(data[0]);
      if (data[0].children && data[0].children.length) {
        node.isLeaf = false;
        this.handleCheckedChildren([node]);
      }
    },
    clearDepartment() {
      this.selection.forEach(item => {
        const node = this.$refs.departmentTree.getNode(item);
        if (item.children && item.children.length) {
          node.isLeaf = false;
        }
      });
      this.checkedIdList = [];
      this.selection = [];
      this.$refs.departmentTree.setCheckedKeys(this.checkedIdList);
    }
  }
};
exports.default = _default;