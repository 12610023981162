"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
var _num_formatter = require("@/utils/num_formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "",
  mixins: [_chartMixins.default],
  components: {},
  props: {
    max: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "item",
          formatter: params => {
            const data = params.data;
            return `交易${data.value}个<br />总计${(0, _num_formatter.parseFormatNum)(data.amount)}元`;
          }
        },
        series: [{
          name: "交易漏斗预测",
          type: "funnel",
          left: "10%",
          width: "80%",
          min: 0,
          sort: "none",
          label: {
            show: true,
            position: "outside",
            formatter: params => {
              const data = params.data;
              return `交易${data.value}个\n总计${(0, _num_formatter.parseFormatNum)(data.amount)}元`;
            }
          },
          labelLine: {
            length: 60,
            lineStyle: {
              width: 1,
              type: "solid"
            }
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1
          },
          emphasis: {
            label: {
              fontSize: 16
            }
          }
        }]
      }
    };
  },
  computed: {},
  watch: {
    data: {
      handler(val) {
        this.options.series[0].data = val;
        // 数据为0时，设一个最大值才能让图标宽度为0
        this.options.series[0].max = this.max;
        // copy用来设置第二个label
        const copy = JSON.parse(JSON.stringify(this.options.series[0]));
        copy.label = {
          show: true,
          position: "inside"
        };
        this.options.series[1] = copy;
        this.init();
      },
      deep: true
    }
  },
  created() {},
  methods: {}
};
exports.default = _default;