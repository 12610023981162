"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "scatter-table"
  }, [_vm.isCustomer ? _c("el-table", {
    attrs: {
      data: _vm.summaryData,
      border: ""
    }
  }, _vm._l(_vm.summaryDataHead, function (item) {
    return _c("el-table-column", {
      key: item.name,
      attrs: {
        prop: item.prop,
        label: item.label
      }
    });
  }), 1) : _vm._e(), _vm.tableData.rows.length ? _c("el-button", {
    staticClass: "export-scatter-table",
    on: {
      click: _vm.exportTableCsv
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]) : _vm._e(), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.eachPageTableData,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortByAmount
    }
  }, [_vm._l(_vm.tableData.heads, function (item, index) {
    return [_vm.columnIsVisible(index) ? _c("el-table-column", {
      key: index,
      ref: "columnItem",
      refInFor: true,
      attrs: {
        sortable: _vm.columnIsSort(index),
        "show-overflow-tooltip": "",
        label: item,
        "sort-orders": ["ascending", "descending"],
        prop: String(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_vm.getColumnConfig(index).type === "default" ? _c("span", [_vm._v(" " + _vm._s(_vm.formattedDecimal(row, index)) + " ")]) : _vm._e(), _vm.getColumnConfig(index).type === "link" ? _c("span", [_c("a", {
            attrs: {
              target: "__blank",
              href: _vm.getURLParams(row, index)
            }
          }, [_vm._v(" " + _vm._s(_vm.formattedDecimal(row, index)) + " ")])]) : _vm._e(), _vm.getColumnConfig(index).type === "mapping" ? _c("span", {
            style: {
              background: _vm.legendColumn !== index ? _vm.getTypeColor(row[index], index) : ""
            }
          }, [_vm.legendColumn === index ? _c("i", {
            staticClass: "dot",
            style: {
              background: _vm.getTypeColor(row[index], index)
            }
          }) : _vm._e(), _vm._v(" " + _vm._s(_vm.getTypeText(row[index], index)) + " ")]) : _vm._e()];
        }
      }], null, true)
    }) : _vm._e()];
  })], 2), _vm.tableData.rows.length && _vm.tableData.column_config.length ? _c("el-pagination", {
    staticClass: "pagination",
    attrs: {
      "current-page": _vm.currPage,
      "page-sizes": [20, 40, 60, 80],
      "page-size": _vm.pageSize,
      layout: "total, prev, pager, next, sizes ",
      total: _vm.tableData.rows.length
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;