"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _horCalendar = _interopRequireDefault(require("@/components/BusinessDetail/horCalendar"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CluesCalenderDirectoty',
  components: {
    HorizontalCalendar: _horCalendar.default
  },
  props: {
    infoObject: {
      type: Object,
      default: function () {
        return {};
      }
    },
    clueObject: {
      type: Object,
      default: () => ({})
    },
    isCalenderFold: {
      type: Boolean,
      default: false
    },
    expandPersons: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfFold: true,
      customerFold: true
      // 阶段数据
      // progessInfo: {
      //   validate: {
      //     label: "客户验证",
      //     date: "2020-12-20",
      //   },
      //   analy: {
      //     label: "需求分析",
      //     date: "2020-12-25",
      //   },
      //   solution: {
      //     label: "方案/报价",
      //     date: "2020-12-30",
      //   },
      //   talks: {
      //     label: "谈判审核",
      //     date: "",
      //   },
      //   close: {
      //     label: "结束",
      //     date: "",
      //   },
      //   predeal: {
      //     label: "预计成交",
      //     date: "2021-1-12",
      //   },
      // },
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'busiActiveDate', 'busiActiveConver']),
    companyTimeline() {
      return this.infoObject.timeline ? this.infoObject.timeline : [];
    },
    sellerPersonsTimeline() {
      return this.infoObject.salesman_timeline;
    },
    customerPersonsTimeline() {
      return this.infoObject.customer_timeline;
    },
    progessInfo() {
      const obj = JSON.parse(JSON.stringify(this.infoObject));
      const milestone = obj.milestone;
      if (milestone) {
        let lastDate = milestone && milestone.validate;
        for (const k in milestone) {
          milestone[k] = {
            label: this.getMilestoneLabel(k),
            date: milestone[k]
          };
          if (milestone[k]) {
            lastDate = this.$moment(milestone[k]).isAfter(lastDate) ? milestone[k] : lastDate;
          }
        }
        milestone['predeal'] = {
          label: '预计成交',
          date: obj.predeal,
          lastDate: lastDate
        };
      }
      return milestone;
    },
    getFoldText() {
      return this.isCalenderFold ? '展开' : '收起';
    },
    getFoldIcon() {
      return this.isCalenderFold ? 'arrow-right' : 'arrow-down';
    },
    getDefaultDay() {
      let choosedDate = '';
      if (this.busiActiveDate) {
        choosedDate = this.busiActiveDate;
      } else {
        choosedDate = this.$moment().format('YYYY-MM-DD');
      }
      return choosedDate;
    },
    getIconType() {
      return function (type, status) {
        if (type === 'audio') {
          return 'phone';
        } else {
          if (status === 'expire') {
            return 'video-camera';
          } else {
            return 'video-camera-solid-2';
          }
        }
      };
    },
    getSelfInfoIcon() {
      if (this.selfFold) {
        return 'plus';
      } else {
        return 'minus';
      }
    },
    getCustomerInfoIcon() {
      if (this.customerFold) {
        return 'plus';
      } else {
        return 'minus';
      }
    },
    getBothSidesConverList() {
      return this.companyTimeline;
    }
    // getContactName() {
    //   if (!this.infoObject.contact) {
    //     return;
    //   }
    //   if (this.infoObject.contact && this.infoObject.contact.name) {
    //     return this.infoObject.contact.name;
    //   } else {
    //     return this.infoObject.contact.tel
    //       ? this.infoObject.contact.tel
    //       : this.infoObject.contact.email;
    //   }
    // },
  },

  mounted() {
    // 点击其他地方时收起日历目录
    const _this = this;
    document.addEventListener('click', e => {
      if (_this.$refs.busiCalender && !_this.$refs.busiCalender.contains(e.target)) {
        _this.foldPersonsInfo();
      }
    });
  },
  methods: {
    // 根据业务数据会话相关返回详情对象
    getDetailItem(date) {
      if (this.getBothSidesConverList.length > 0) {
        const itemDetail = this.getBothSidesConverList.find(item => {
          const isSameDate = this.$moment(item.date).isSame(date);
          if (isSameDate) {
            return item;
          }
        });
        return itemDetail || null;
      }
    },
    selectItem(id, date) {
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$moment(date).format('YYYY-MM-DD'));
    },
    changeCalenderFoldStatus() {
      this.$emit('update:isCalenderFold', !this.isCalenderFold);
      // this.calenderFold = !this.calenderFold;
    },

    foldPersonsInfo() {
      this.selfFold = true;
      this.customerFold = true;
    },
    changeFoldStatus(type) {
      if (type === 'self') {
        this.selfFold = !this.selfFold;
        if (!this.selfFold) {
          this.customerFold = true;
        }
      } else if (type === 'customer') {
        this.customerFold = !this.customerFold;
        if (!this.customerFold) {
          this.selfFold = true;
        }
      }
    },
    firstDayChange(day) {
      this.currentFirstDay = day;
    },
    changeDate(day) {
      if (this.getDetailItem(day) && this.getDetailItem(day).infos) {
        const defaultConvId = this.getDetailItem(day).infos[0].id; // 如果点击正个日期，默认选中第一个会话
        this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$moment(day).format('YYYY-MM-DD'));
        this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', defaultConvId);
      }
    },
    getMilestoneLabel(key) {
      const status = this.infoObject.stage;
      if (key === 'validate') {
        return '客户验证';
      } else if (key === 'analy') {
        return '需求分析';
      } else if (key === 'solution') {
        return '方案/报价';
      } else if (key === 'talks') {
        return '谈判审核';
      } else if (key === 'predeal') {
        return '预计成交';
      } else if (key === 'close' && status === 'win') {
        return '赢单';
      } else if (key === 'close' && status === 'loss') {
        return '输单';
      } else if (key === 'close' && status === 'invalidate') {
        return '无效';
      } else {
        return '';
      }
    }
    // openEdit() {
    //   this.$emit('openEdit')
    // }
  }
};

exports.default = _default;