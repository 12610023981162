"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelEditScoreRule = cancelEditScoreRule;
exports.changeTradeScoreRuleStatus = changeTradeScoreRuleStatus;
exports.createTradeScoreRule = createTradeScoreRule;
exports.deleteTradeScoreRule = deleteTradeScoreRule;
exports.getAccountTagOptions = getAccountTagOptions;
exports.getTradeCount = getTradeCount;
exports.getTradeListCount = getTradeListCount;
exports.getTradeScoreOptions = getTradeScoreOptions;
exports.getTradeScoreRuleDetail = getTradeScoreRuleDetail;
exports.getTradeScoreRuleInfo = getTradeScoreRuleInfo;
exports.getTradeScoreRuleList = getTradeScoreRuleList;
exports.startEditScoreRule = startEditScoreRule;
exports.updateTradeScoreRule = updateTradeScoreRule;
var _request = require("@/utils/request");
function getTradeScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/trade/score_rule/list',
    method: 'get',
    params
  });
}
function getTradeScoreRuleInfo(params) {
  return (0, _request.webService)({
    url: '/trade/score_rule/get_info',
    method: 'get',
    params
  });
}

// 获取交易
function getTradeScoreOptions(params) {
  return (0, _request.webService)({
    url: '/trade/score_rule/item_type_list',
    method: 'get',
    params
  });
}

// 获取客户标签列表options
function getAccountTagOptions(params) {
  return (0, _request.webService)({
    url: '/conversation/tag/list',
    method: 'get',
    params
  });
}

// 新建评分规则
function createTradeScoreRule(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新评分规则
function updateTradeScoreRule(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除评分规则
function deleteTradeScoreRule(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 开启、禁用评分规则
function changeTradeScoreRuleStatus(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/change_status',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取评分规则详情
function getTradeScoreRuleDetail(params) {
  return (0, _request.webService)({
    url: '/trade/score_rule/score_result',
    method: 'get',
    params
  });
}

// 交易评分规则开始编辑
function startEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/start_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易评分规则取消编辑
function cancelEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/cancel_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易评分规则详情页 - 交易列表数量
function getTradeListCount(data) {
  return (0, _request.webService)({
    url: '/trade/score_rule/trade_list_count',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话评分规则列表页 - 根据评分规则配置获取会话数量
function getTradeCount(params) {
  return (0, _request.webService)({
    url: '/trade/score_rule/get_count',
    method: 'get',
    params
  });
}