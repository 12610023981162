"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "coach-data-analysis"
  }, [_c("header", {
    staticClass: "content-header"
  }, [_c(_setup.MenuToggleBtn, {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: _setup.routeTitle
    }
  })], 1), _c("el-tabs", {
    staticClass: "content-tab",
    on: {
      "tab-click": _setup.handleClick
    },
    model: {
      value: _setup.activeName,
      callback: function ($$v) {
        _setup.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_setup.dataAnalysisChildrenRoutes, function (item) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.meta.subtitle,
        name: item.name
      }
    });
  }), 1), _c("div", {
    staticClass: "main-content"
  }, [_c("keep-alive", [_c("router-view", {
    on: {
      "route-change": _setup.onRouteChange
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;