"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Password',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入密码'
    }
  },
  data() {
    return {
      flag: false,
      flagType: 'password'
    };
  },
  methods: {
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? 'text' : 'password';
    }
  }
};
exports.default = _default;