"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "items-flow-temp"
  }, [_c("div", {
    staticClass: "item-wrapper"
  }, [_c("div", {
    staticClass: "flow-date-item"
  }, [_c("div", {
    staticClass: "left-icons",
    class: {
      connected: _vm.item.status !== "expire",
      reserved: _vm.item.status === "plan"
    }
  }, [_c("i", {
    staticClass: "icon-wrapper self-icon",
    class: {
      "no-finish": _vm.item.status === "plan",
      absent: _vm.item.status === "expire"
    }
  }, [_c("svg-icon", {
    staticClass: "conv-icon",
    attrs: {
      "icon-class": _vm.getIconType(_vm.item.type, _vm.item.status)
    }
  })], 1), _c("i", {
    staticClass: "icon-wrapper customer-icon",
    class: {
      "no-finish": _vm.item.status === "plan",
      absent: _vm.item.status === "expire"
    }
  }, [_c("svg-icon", {
    staticClass: "conv-icon",
    attrs: {
      "icon-class": _vm.getIconType(_vm.item.type, _vm.item.status)
    }
  })], 1)]), _c("div", {
    staticClass: "right-infos"
  }, [_c("div", {
    staticClass: "title-line"
  }, [_c("p", {
    staticClass: "title-name"
  }, [_vm.item.origin_type === "wework_internal_app" ? _c("img", {
    staticClass: "title-logo",
    attrs: {
      src: require("@/assets/wework.svg"),
      alt: "logo"
    }
  }) : _vm.item.origin_type === "tencent_meeting" ? _c("img", {
    staticClass: "title-logo",
    attrs: {
      src: require("@/assets/tencent_meeting.svg"),
      alt: "logo"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "title-text"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.status === "attend",
      expression: "item.status === 'attend'"
    }],
    staticClass: "conv-duration"
  }, [_vm._v(" " + _vm._s(_vm.getDuration(_vm.item.talk_time)) + " ")])])]), _c("div", {
    staticClass: "person-line"
  }, [_c("div", {
    staticClass: "sales-info person-info"
  }, [_c("image-avatar", {
    attrs: {
      avatar: _vm.item.salesman_info.avatar,
      name: _vm.item.salesman_info.name
    }
  }), _c("div", {
    staticClass: "right-person-info"
  }, [_c("div", {
    staticClass: "person-name"
  }, [_vm._v(_vm._s(_vm.item.salesman_info.name))]), _c("div", {
    staticClass: "other-person-info"
  }, [_c("span", [_vm._v("销售代表")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.orgName,
      expression: "orgName"
    }]
  }, [_vm._v(" / ")]), _c("span", [_vm._v(_vm._s(_vm.orgName))])])])], 1), _c("div", {
    staticClass: "customer-info person-info"
  }, [_c("image-avatar", {
    attrs: {
      name: _vm.item.customer_info.name ? _vm.item.customer_info.name : "客户",
      type: "customer_contact"
    }
  }), _c("div", {
    staticClass: "right-person-info"
  }, [_c("div", {
    staticClass: "person-name"
  }, [_vm._v(" " + _vm._s(_vm.item.customer_info.name || "未知姓名") + " ")]), _vm._m(0)])], 1)])]), _vm.getIsShowButton(_vm.item) ? _c("div", {
    staticClass: "hover-button",
    on: {
      click: function ($event) {
        return _vm.goTargetPage(_vm.item);
      }
    }
  }, [_c("el-button", {
    attrs: {
      icon: _vm.getHoverIcon(_vm.item),
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.getHoverText(_vm.item)))])], 1) : _vm._e()]), _c("div", {
    staticClass: "note-book"
  }, [_c("el-collapse", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_vm.item.note && _vm.item.note.version ? _c("el-collapse-item", {
    attrs: {
      name: "note"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon iconfont icon-notebook-"
  }), _vm._v("私人笔记 ")]), _c("note-editor", {
    attrs: {
      "read-only": "",
      note: _vm.item.note
    }
  })], 2) : _vm._e(), _vm.item.event_engine && _vm.item.event_engine.total ? _c("el-collapse-item", {
    attrs: {
      name: "keyevent"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon iconfont icon-key-event"
  }), _vm._v("关键事件 ")]), _c("events-list", {
    attrs: {
      "event-engine": _vm.item.event_engine.data,
      "tag-size": "small"
    },
    on: {
      tagClick: _vm.onTagClick
    }
  })], 2) : _vm._e()], 1)], 1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "other-person-info"
  }, [_c("span", [_vm._v("客户")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;