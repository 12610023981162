"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'CopyUrl',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    candUrl: {
      type: String,
      default: ''
    },
    inteUrl: {
      type: String,
      default: ''
    },
    inviteText: {
      type: String,
      default: ''
    },
    tel: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    convName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'appoint'
    },
    conversationId: {
      type: Number,
      default: 0
    },
    candQr: {
      type: String,
      default: ''
    },
    inteQr: {
      type: String,
      default: ''
    },
    conversationType: {
      type: String,
      default: 'deal'
    }
  },
  data() {
    return {
      hasSend: false,
      sendLoading: false,
      showCandQr: true,
      showInteQr: true
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes']),
    hasCalendarPermission() {
      return this.$route.path !== _routeMap.ROUTES.calendar && this.webRoutes.find(item => item.name === 'CalendarIndex');
    }
  },
  methods: {
    onCopy() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
    },
    onError() {
      this.$message({
        message: '复制失败！',
        type: 'warning'
      });
    },
    closeSelf() {
      this.$emit('closeUrlDialog', 'self');
      this.hasSend = false;
    },
    sureSend() {
      this.$emit('closeUrlDialog', 'all');
    },
    goVideoRoom() {
      window.open(this.inteUrl, '_blank');
    },
    goInterview() {
      this.$router.push({
        path: _routeMap.ROUTES.calendar
      });
    },
    sendEmail() {
      this.sendLoading = true;
      (0, _business.sendEmail)(this.conversationId, this.inviteText).then(res => {
        this.$message({
          message: '发送成功！',
          type: 'success'
        });
        this.hasSend = true;
        this.sendLoading = false;
      }).catch(() => {
        this.sendLoading = false;
      });
    },
    closeCopy() {
      this.$emit('closeCopy');
    },
    reloadInteImage() {
      setTimeout(() => {
        this.showInteQr = false;
        this.$nextTick(function () {
          this.showInteQr = true;
        });
      }, 1500);
    },
    reloadCandImage() {
      setTimeout(() => {
        this.showCandQr = false;
        this.$nextTick(function () {
          this.showCandQr = true;
        });
      }, 1500);
    }
  }
};
exports.default = _default;