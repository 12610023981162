"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _doubleCallMixins = _interopRequireDefault(require("@/views/clients/doubleCallMixins"));
var _vuex = require("vuex");
var _MgTag = _interopRequireDefault(require("@/components/MgTag"));
var _bubbleLine = _interopRequireDefault(require("./bubbleLine"));
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _scaleLine = _interopRequireDefault(require("./scaleLine"));
var _datePopover = _interopRequireDefault(require("./datePopover"));
var _business = require("@/api/business");
var _client = require("@/api/client");
var _riskPopover = _interopRequireDefault(require("./riskPopover"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _num_formatter = require("@/utils/num_formatter");
var _businessExplain = _interopRequireDefault(require("@/components/Business/businessExplain"));
var _DealOperateButton = _interopRequireDefault(require("@/views/business/components/DealOperateButton"));
var _AssociateContactDialog = _interopRequireDefault(require("@/views/businessDetail/components/AssociateContactDialog.vue"));
var _UploadMediaDialog = _interopRequireDefault(require("@/components/UploadMediaDialog"));
var _LinkText = _interopRequireDefault(require("@/components/LinkText"));
var _keyEventColumnContent = _interopRequireDefault(require("./keyEventColumnContent.vue"));
var _routeMap = require("@/router/routeMap");
var _dealTabsDrawer = _interopRequireDefault(require("./components/dealTabsDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessTable',
  components: {
    MgTag: _MgTag.default,
    BubbleLine: _bubbleLine.default,
    ScaleLine: _scaleLine.default,
    DatePopover: _datePopover.default,
    RiskPopover: _riskPopover.default,
    ImageAvatar: _ImageAvatar.default,
    BusinessExplain: _businessExplain.default,
    DealOperateButtons: _DealOperateButton.default,
    MeetingDialog: _MeetingDialog.default,
    AssociateContactDialog: _AssociateContactDialog.default,
    UploadMediaDialog: _UploadMediaDialog.default,
    KeyEventColumnContent: _keyEventColumnContent.default,
    DealTabsDrawer: _dealTabsDrawer.default,
    LinkText: _LinkText.default
  },
  mixins: [_doubleCallMixins.default],
  props: {
    headerList: {
      type: Array,
      default: () => []
    },
    table: {
      type: Object,
      default: () => ({})
    },
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: null
    },
    doubleCallPermission: {
      type: Number,
      default: 0
    },
    tableHeight: {
      type: Number,
      default: document.body.clientHeight - 156
    }
  },
  data() {
    return {
      isShowDrawer: false,
      dealId: '',
      pageType: 'business',
      mediaRowInfo: {},
      uploadMediaDialogVisible: false,
      flipNum: 0,
      flipNumLoaded: 0,
      maxPage: 0,
      calendarFormat: {
        sameDay: '[今天]',
        nextDay: '[明天]',
        lastDay: '[昨天]'
      },
      popoverDate: '',
      datePopoverVisible: false,
      popoverProps: {
        left: 750,
        top: 500
      },
      lastSort: {
        prop: 'speak_time',
        order: 'descending'
      },
      issafariBrowser: false,
      isRiskDrawerOpen: false,
      isCommentDrawerOpen: false,
      currentRow: null,
      currentFieldName: '',
      activeDeaId: null,
      clientContactList: [],
      associateContactDialogVisible: false,
      meetingDialogTitle: '安排在线会议',
      meetingDialogVisible: false,
      meetingProps: {},
      // new 商机抽屉相关变量
      isDrawerOpen: false,
      activeTabName: '',
      activeConvDate: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_video_permission', 'has_outbound', 'api_permissions', 'webRoutes']),
    operateButtons() {
      if (this.doubleCallPermission) {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'doubleCall',
          text: '双呼',
          isCircle: true,
          isShowList: true,
          isShow: this.has_outbound
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isCircle: true,
          isShowList: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isCircle: true,
          isShowList: false,
          isShow: this.has_video_permission
        }];
      } else {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isCircle: true,
          isShowList: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isCircle: true,
          isShowList: false,
          isShow: this.has_video_permission
        }];
      }
    },
    operateColumnVisible() {
      const arr = this.operateButtons.filter(item => item.isShow);
      return arr === null || arr === void 0 ? void 0 : arr.length;
    },
    operateColumnWidth() {
      const arr = this.operateButtons.filter(item => item.isShow);
      return (arr === null || arr === void 0 ? void 0 : arr.length) * 70;
    },
    rightBtnDisabled() {
      return !this.flipNum;
    },
    ColumnShadowClass() {
      return this.rightBtnDisabled ? 'leftShadow' : 'rightShadow';
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    },
    bubbleSelected() {
      return function (row) {
        return row.deal_id === this.dealId;
      };
    }
  },
  watch: {
    headerList(val, oldVal) {
      const {
        prop,
        order
      } = this.lastSort;
      const lastSortColumn = val.find(item => item.field_name === prop);
      if (lastSortColumn) {
        this.$nextTick(() => {
          this.$refs[`column_${prop}`][0].columnConfig.order = order;
        });
      }
      // 修复增加列设置后固定按钮位置错位
      const tableBody = this.$el.querySelector('.el-table__body-wrapper');
      if (tableBody) {
        tableBody.scrollLeft = 0 + 'px';
      }
      if (oldVal.length) {
        return;
      }
    },
    list(val) {
      this.$nextTick(() => {
        // 修复固定列错位
        this.$refs.table.doLayout();
        // 商机列表固定居中按钮
        const tbodyHeight = document.querySelector('.el-table__body tbody') ? document.querySelector('.el-table__body tbody').clientHeight : 0;
        const btnBoxes = document.getElementsByClassName('btn-flip-box');
        if (tbodyHeight > 0 && btnBoxes.length !== 0) {
          if (tbodyHeight < 700) {
            const tableRef = this.$refs.table.$el;
            if (!tableRef) return;
            const topHeight = tableRef.getBoundingClientRect().top;
            const thHeight = 35;
            const btns = btnBoxes[0].children;
            const btnHeight = btns ? btns[0].offsetHeight : 0;
            btnBoxes[0].style.top = topHeight + thHeight + tbodyHeight / 2 - btnHeight / 2 + 'px';
          } else {
            btnBoxes[0].style.top = '50.5vh';
          }
        }
      });
    }
  },
  mounted() {
    this.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    let left = 0;
    const tableBody = this.$el.querySelector('.el-table__body-wrapper');
    tableBody.onscroll = () => {
      left = tableBody.scrollLeft;
      const scaleLine = this.$el.querySelector('.scale-line');
      const btnFlipBox = this.$el.querySelector('.btn-flip-box');
      if (scaleLine && btnFlipBox) {
        scaleLine.style.marginLeft = -(left + 11) + 'px';
        btnFlipBox.style.marginLeft = -(left + 11) + 'px';
      }
    };
    const clickHandler = e => {
      // NOTE <<<点击空白处关闭eventDrawer
      if (!e.target.closest('#objection-label-optimize') && !e.target.closest('#next_step-label-optimize') && !e.target.closest('#key-events') && !e.target.closest('#account-label') && !e.target.closest('#comment-button-style') && !e.target.closest('#speak-time-optimize') && !e.target.closest('#risk-optimize') && !e.target.closest('#deal-tabs-drawer')) {
        this.isDrawerOpen = false;
        this.activeTabName = '';
        this.activeConvDate = '';
      }
      // 点击空白处关闭eventDrawer>>>
    };

    document.addEventListener('mousedown', clickHandler);
    // 卸载全局click事件
    this.$once('hook:beforeDestroy', () => document.removeEventListener('mousedown', clickHandler, true));
  },
  methods: {
    formatMoney(data, item) {
      if (data !== '') {
        return `￥${(0, _num_formatter.parseFormatNum)(data, item.precisions, !item.is_thousand_separate)}`;
      } else {
        return '-';
      }
    },
    formatNumber(data, item) {
      if (data !== '') {
        return (0, _num_formatter.parseFormatNum)(data, item.precisions, true);
      } else {
        return '-';
      }
    },
    goCustomerPage(account_id) {
      if (this.hasCustomerPermission) {
        const routeData = this.$router.resolve({
          path: _routeMap.ROUTES.customer,
          query: {
            id: account_id,
            from: 'deal'
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    isTableDraggable(field_name) {
      if (field_name === 'speak_time') {
        return false;
      }
    },
    customDealDataShow(val) {
      return Object.keys(val).filter(item => item !== 'display');
    },
    getDetailHref(id) {
      return `${_routeMap.ROUTES.dealDetail}?id=${id}`;
    },
    parseFormatNum(number, n) {
      return (0, _num_formatter.parseFormatNum)(number, n);
    },
    tiptool() {},
    formatterTest(row, column, cellValue, index) {
      return cellValue || '-';
    },
    setCalendarDom() {
      this.$nextTick(() => {
        const cell = document.querySelector('.scale-line-container');
        const line = document.querySelector('.scale-line');
        cell.insertAdjacentElement('afterbegin', line);
      });
    },
    // 只有点击商机名称进入商机详情页
    goDealDetail(row) {
      this.$emit('rowClick', row.deal_id);
    },
    cellClassName(_ref) {
      let {
        row,
        column,
        rowIndex,
        columnIndex
      } = _ref;
      if (rowIndex === 0 && column.property === 'speak_time') {
        return 'scale-line-container';
      }
      if (rowIndex !== 0 && column.property === 'speak_time' && this.dealId !== row.deal_id) {
        return 'normal-bubble-line';
      }
      // 选中行的跟进动态cell
      if (rowIndex !== 0 && column.property === 'speak_time' && this.dealId === row.deal_id) {
        return 'selected-bubble-line';
      }
      if (column.property === 'operate') {
        return 'operation-cell';
      }
    },
    flip(num) {
      this.flipNum += num;
    },
    handleObjectionClick(businessId, conversationId) {
      this.$emit('objectionClick', businessId, conversationId);
    },
    handlePopoverListener(e) {
      if (e.target.dataset.date || e.target.parentElement.dataset.date) {
        this.popoverDate = e.target.dataset.date || e.target.parentElement.dataset.date;
        this.datePopoverVisible = true;
        this.popoverProps = {
          left: e.x - 40 + 'px',
          top: e.y - 40 + 'px'
        };
      } else {
        this.datePopoverVisible = false;
      }
    },
    handleNextConnectTime(conversation) {
      if (!conversation || !conversation.time) return '-';
      const diff = this.$plus8ToCurrentZone(conversation.time).diff(this.$moment(), 'days');
      const elseTime = diff > 0 ? `${diff}天内` : `${-diff}天前`;
      const formatTime = this.$plus8ToCurrentZone(conversation.time).calendar(null, {
        sameDay: '今天 HH:mm',
        nextDay: '明天 HH:mm',
        lastDay: '昨天 HH:mm',
        lastWeek: elseTime,
        nextWeek: elseTime,
        sameElse: elseTime
      });
      return formatTime;
    },
    sortChange(_ref2) {
      let {
        column,
        prop,
        order
      } = _ref2;
      if (prop !== this.lastSort.prop) {
        const column = this.headerList.find(item => item.field_name === this.lastSort.prop);
        if (column) {
          this.$nextTick(() => {
            this.$refs[`column_${this.lastSort.prop}`][0].columnConfig.order = null;
          });
        }
      }
      this.$nextTick(() => {
        if (!order) {
          column.order = this.lastSort.order;
        } else {
          this.lastSort = {
            prop,
            order
          };
          this.$emit('sortChange', prop, order);
        }
      });
    },
    async goVideoRoom(_ref3) {
      let {
        conversation_id
      } = _ref3;
      const res = await (0, _business.getConversationHostCode)({
        conversation_id
      });
      if (res.results && res.results.host_code) {
        const url = `${window.location.protocol}//${window.location.host}/vrc?c=${res.results.host_code}`;
        window.open(url, '_blank');
      }
    },
    handleStatusMsg(status) {
      switch (status) {
        case 'win':
          return {
            iconClass: 'el-icon-circle-check',
            type: 'success'
          };
        case 'loss':
          return {
            iconClass: 'el-icon-circle-close',
            type: 'failure'
          };
        case 'invalidate':
          return {
            iconClass: 'el-icon-remove-outline',
            type: 'invalid'
          };
        default:
          break;
      }
      return {
        iconClass: '',
        type: ''
      };
    },
    formatDateTime(time) {
      if (time) {
        return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
      } else {
        return '-';
      }
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      } else {
        return '-';
      }
    },
    changeActiveDealId(dealId) {
      this.activeDeaId = dealId;
    },
    async openContactPersonDialog(row) {
      const res = await (0, _client.getAllContactPersonList)(row.account_id);
      if (res.code === 200) {
        this.clientContactList = [];
        for (let i = 0; i < res.results.length; i++) {
          const obj = {
            label: res.results[i].name,
            value: res.results[i].id
          };
          this.clientContactList.push(obj);
        }
      }
      this.associateContactDialogVisible = true;
      this.$nextTick(() => {
        this.$refs['associateContactDialog'].$refs['associateContactForm'].clearValidate();
      });
    },
    clickContactPersonItem(contactItem, dealInfo, type, contactList, meetingType) {
      if (type === 'callout') {
        if (!this.has_outbound) {
          this.$message.warning('暂无坐席');
          return;
        }
        const _data = {
          id: dealInfo.deal_id,
          type: 'deal',
          company: '',
          contact_id: contactItem.id,
          contact_person: contactItem.name,
          phone: contactItem.tel
        };
        this.$bus.$emit('openPhoneCall', _data);
      } else if (type === 'doubleCall') {
        this.doubleCallOut(contactItem, dealInfo, 'deal');
      } else if (type === 'upload') {
        this.openUploadMediaDialog(contactItem, dealInfo, contactList);
      } else {
        this.createMeeting(contactItem, dealInfo, contactList, meetingType);
      }
    },
    createMeeting(contactItem, dealInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      const {
        deal_id,
        customer_name
      } = dealInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName
      } = contactItem;
      this.meetingProps = {
        type: 'business',
        businessId: deal_id,
        customerName: customer_name,
        contactOptions,
        contactId,
        contactName,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    // 打开上传Dialog
    openUploadMediaDialog(contactItem, dealInfo, contactList) {
      const {
        id,
        name
      } = contactItem;
      const currentContactInfo = {
        id,
        name
      };
      const contactInfo = {
        currentContactInfo,
        contactList
      };
      const clientInfo = {
        id: dealInfo.deal_id,
        name: dealInfo.deal_name
      };
      this.mediaRowInfo = {
        contactInfo,
        clientInfo,
        rowInfo: dealInfo
      };
      this.uploadMediaDialogVisible = true;
    },
    // 关闭上传Dialog
    closeUploadMediaDialog() {
      this.$refs['upload-media-dialog'].mediaFileList = [];
      this.uploadMediaDialogVisible = false;
    },
    // 点击跟进动态条
    handleBubbleLineClick(row) {
      this.openDealTabsDrawer('speak_time', row);
    },
    // 点击跟进动态气泡
    bubbleClick(date, row) {
      this.activeConvDate = date;
      this.openDealTabsDrawer('speak_time', row);
    },
    // NOTE 点击或者切换tab 关键事件 ，下一步 ，客户异议
    openDealTabsDrawer(eventType, row, fieldName) {
      this.isDrawerOpen = true;
      this.activeTabName = eventType;
      this.dealId = row.deal_id;
      this.currentRow = row;
      this.currentFieldName = fieldName || '';
      // table当前行高亮
      this.$refs.table.setCurrentRow(row, true);
    }
  }
};
exports.default = _default;