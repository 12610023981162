"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "course-card",
    on: {
      click: function ($event) {
        return _vm.editCourse();
      }
    }
  }, [_c("div", {
    class: ["course-tag", _vm.courseClass]
  }, [_vm._v(" " + _vm._s(_vm.courseType) + " ")]), _c("img", {
    staticClass: "course-img",
    attrs: {
      src: _vm.courseInfo.avatar_url || _vm.courseCoverList[_vm.courseInfo.type - 1]
    }
  }), _c("div", {
    staticClass: "operate-btns"
  }, [_c("div", {
    staticClass: "flex-btn"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      enterable: false,
      content: _vm.tooltipContent,
      disabled: !_vm.courseInfo.gist_status,
      "open-delay": 300
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "iconfont icon-receiving-plus",
      disabled: !!_vm.courseInfo.gist_status
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.createTask.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.newTask")))])], 1)], 1), _c("el-dropdown", {
    attrs: {
      placement: "bottom-start"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    staticClass: "el-icon-more more-dropdown-button",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }), _c("el-dropdown-menu", {
    staticClass: "operate-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "edit",
      icon: "iconfont icon-edit"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.edit")))]), _c("el-dropdown-item", {
    attrs: {
      command: "copy",
      icon: "iconfont icon-copy-document"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.copy")))]), _c("el-dropdown-item", {
    attrs: {
      command: "move",
      icon: "iconfont icon-folder-move"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.changeClassification")))]), _c("el-dropdown-item", {
    attrs: {
      command: "taskRecord",
      icon: "iconfont icon-record"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.taskRecord")))]), _c("el-dropdown-item", {
    attrs: {
      command: "delete",
      icon: "iconfont icon-delete"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.delete")))])], 1)], 1)], 1), _c("div", {
    staticClass: "course-title"
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      content: _vm.courseInfo.name
    }
  }, [_c("p", {
    class: ["course-name", {
      "single-row-name": _vm.courseInfo.gist_status === 1 || true
    }]
  }, [_vm._v(" " + _vm._s(_vm.courseInfo.name) + " ")])]), _c("div", {
    staticClass: "loading-text"
  }, [_vm.courseInfo.gist_status === 1 ? [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.ruleIsGenerating")))]), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])] : _vm.courseInfo.create_by ? _c("span", {
    staticClass: "creator-text"
  }, [_vm._v(_vm._s(_vm.$t("coaching.creator")) + "：" + _vm._s(_vm.courseInfo.create_by))]) : _vm._e()], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;