"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _CreateClientsDialog = _interopRequireDefault(require("./CreateClientsDialog"));
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'HeaderFilters',
  components: {
    CreateClientsDialog: _CreateClientsDialog.default
  },
  props: {
    accountMultipleVal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      createClientDialogVisible: false,
      departmentValue: '全部客户',
      searchValue: '',
      options: [{
        value: '全部客户',
        id: 1
      }, {
        value: '我负责的客户',
        id: 2
      }],
      remoteLoading: false,
      userList: [],
      userId: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    filterParams() {
      return {
        only_my_accounts: this.departmentValue === '全部客户' ? 0 : 1,
        search_val: this.searchValue,
        user_id: this.userId
      };
    }
  },
  watch: {
    filterParams: {
      handler() {
        this.$emit('change', this.filterParams);
      },
      deep: true
    }
  },
  methods: {
    fetchAccountSuccess(val) {
      this.$emit('fetchAccountSuccess', val);
    },
    hanleCreateClientClick() {
      this.createClientDialogVisible = true;
    },
    handleAccountBulkAction(action) {
      this.$emit('AccountBulkAction', action);
    },
    freshList(val) {
      this.$emit('freshList', val);
    },
    remoteUserList: (0, _commonFunc.debounce)(async function (filter) {
      const params = {
        current_page: 1,
        size: 10,
        filter
      };
      this.remoteLoading = true;
      const res = await (0, _organization.getOrgUserList)(params).finally(() => {
        this.remoteLoading = false;
      });
      if (res.code === 200) {
        this.userList = res.results.users;
      }
    })
  }
};
exports.default = _default;