"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetModelAllVersions = useGetModelAllVersions;
exports.useGetModelInfo = useGetModelInfo;
exports.useGoDataSet = useGoDataSet;
exports.useRetryTrain = useRetryTrain;
var _useRouter = require("@/hooks/use-router");
var _elementUi = require("element-ui");
var _routeMap = require("@/router/routeMap");
var _modelManagement = require("@/api/modelManagement.js");
var _vue = require("vue");
function useGetModelInfo() {
  var _route$query, _route$query2, _route$query3;
  const route = (0, _useRouter.useRoute)();
  const modelId = Number((route === null || route === void 0 ? void 0 : (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.id) || 0);
  const modelName = route === null || route === void 0 ? void 0 : (_route$query2 = route.query) === null || _route$query2 === void 0 ? void 0 : _route$query2.modelName;
  const modelType = route === null || route === void 0 ? void 0 : (_route$query3 = route.query) === null || _route$query3 === void 0 ? void 0 : _route$query3.modelType;
  return {
    modelId,
    modelName,
    modelType
  };
}
function useGetModelAllVersions(_ref) {
  let {
    modelId,
    currentPage,
    pageSize
  } = _ref;
  const tableLoading = (0, _vue.ref)(false);
  const allVersionsList = (0, _vue.ref)([]);
  const getModelVersionListApi = async () => {
    tableLoading.value = true;
    const res = await (0, _modelManagement.getModelVersionList)({
      model_id: modelId,
      page: currentPage,
      size: pageSize
    }).finally(() => tableLoading.value = false);
    if (res.code === 20000) {
      allVersionsList.value = res.results.versions;
    }
  };
  return {
    getModelVersionListApi,
    allVersionsList,
    tableLoading
  };
}
function useGoDataSet() {
  const router = (0, _useRouter.useRouter)();
  const goDataSet = dataSetId => {
    const url = router.resolve({
      path: _routeMap.ROUTES.dataSet,
      query: {
        dataset_id: dataSetId
      }
    });
    window.open(url.href, '_blank');
  };
  return {
    goDataSet
  };
}

// 重试训练
function useRetryTrain(successCallBack) {
  const retryTrainAsync = async (id, version) => {
    const res = await (0, _modelManagement.retryTrain)({
      id,
      version
    });
    if (res.code === 20000) {
      _elementUi.Message.success('训练已重试');
      successCallBack(id);
    }
  };
  return {
    retryTrainAsync
  };
}