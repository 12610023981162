"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnnotateResultsDrawer = _interopRequireDefault(require("@/views/management/annotate/components/AnnotateResultsDrawer.vue"));
var _DataSetFilter = _interopRequireDefault(require("./components/DataSetFilter.vue"));
var _DataSetTable = _interopRequireDefault(require("./components/DataSetTable.vue"));
var _ImportDataDialog = _interopRequireDefault(require("./components/ImportDataDialog.vue"));
var _CopyDataSetDialog = _interopRequireDefault(require("./components/CopyDataSetDialog.vue"));
var _CreateDataSetDialog = _interopRequireDefault(require("./components/CreateDataSetDialog.vue"));
var _TrainModelDialog = _interopRequireDefault(require("@/views/management/modelManagement/components/TrainModelDialog.vue"));
var _TestModelDialog = _interopRequireDefault(require("@/views/management/modelManagement/components/TestModelDialog.vue"));
var _MegaTranGuide = _interopRequireDefault(require("../components/MegaTranGuide.vue"));
var _dataSet = require("./hooks/dataSet.js");
var _dataSetTableOperate = require("./hooks/dataSetTableOperate.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const currentDataSet = (0, _vue.ref)({});
    const {
      getDataSetListApi,
      sizeChange,
      currentChange,
      filterChange,
      sortChange,
      refreshTable,
      tableResults,
      paginationParams
    } = (0, _dataSet.useDataSet)();

    // 创建&编辑数据集
    const {
      createDataSetDialogVisible,
      createDataSet,
      editDataSet
    } = (0, _dataSetTableOperate.useCreateOrEditDataSet)(currentDataSet);

    // 导入数据
    const {
      importDataDialogVisible,
      openImportDataDialog
    } = (0, _dataSetTableOperate.useImportData)(currentDataSet);

    // 复制数据集
    const {
      copyDataSetDialogVisible,
      openCopyDataSetDialog
    } = (0, _dataSetTableOperate.useCopyDataSet)(currentDataSet);

    // 删除数据集
    const {
      onDeleteDataSet
    } = (0, _dataSetTableOperate.useDeleteDataSet)(refreshTable);

    // 标注
    const {
      goAnnotatePage
    } = (0, _dataSetTableOperate.useAnnotate)();

    // 训练模型
    const {
      trainingModel,
      trainModelDialogVisible
    } = (0, _dataSetTableOperate.useTrainingModel)(currentDataSet);

    // 测试模型
    const {
      testModel,
      testModelDialogVisible
    } = (0, _dataSetTableOperate.useTestModel)(currentDataSet);
    const annotateResultsDrawerVisible = (0, _vue.ref)(false);
    const activeTabs = (0, _vue.ref)({});
    const openAnnotateResultsDrawer = (row, label) => {
      currentDataSet.value = row;
      activeTabs.value = label;
      annotateResultsDrawerVisible.value = true;
    };
    (0, _vue.onMounted)(() => {
      getDataSetListApi();
    });
    return {
      __sfc: true,
      currentDataSet,
      getDataSetListApi,
      sizeChange,
      currentChange,
      filterChange,
      sortChange,
      refreshTable,
      tableResults,
      paginationParams,
      createDataSetDialogVisible,
      createDataSet,
      editDataSet,
      importDataDialogVisible,
      openImportDataDialog,
      copyDataSetDialogVisible,
      openCopyDataSetDialog,
      onDeleteDataSet,
      goAnnotatePage,
      trainingModel,
      trainModelDialogVisible,
      testModel,
      testModelDialogVisible,
      annotateResultsDrawerVisible,
      activeTabs,
      openAnnotateResultsDrawer,
      AnnotateResultsDrawer: _AnnotateResultsDrawer.default,
      DataSetFilter: _DataSetFilter.default,
      DataSetTable: _DataSetTable.default,
      ImportDataDialog: _ImportDataDialog.default,
      CopyDataSetDialog: _CopyDataSetDialog.default,
      CreateDataSetDialog: _CreateDataSetDialog.default,
      TrainModelDialog: _TrainModelDialog.default,
      TestModelDialog: _TestModelDialog.default,
      MegaTranGuide: _MegaTranGuide.default
    };
  }
};
exports.default = _default;