"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SendBox = _interopRequireDefault(require("./SendBox"));
var _CommentUnit = _interopRequireDefault(require("./CommentUnit"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _videoDetail = require("@/api/videoDetail");
var _business = require("@/api/business");
var _vuex = require("vuex");
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentsArea',
  components: {
    SendBox: _SendBox.default,
    CommentUnit: _CommentUnit.default,
    NoData: _NoData.default
  },
  inject: ['getActiveCommentId'],
  props: {
    commentType: {
      type: String,
      default: 'conversation'
    },
    departmentManagerInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isUnfolded: true,
      commentsActive: false,
      sendBoxProps: {},
      commentsList: [],
      commentLoading: false,
      activeId: '',
      isReply: false,
      commentSingle: {
        step: [{
          title: '支持编辑评论啦！',
          target: '[data-v-name="comment-isOwnComment"]',
          placement: 'bottom'
        }],
        img: require('@/assets/convDetail/comment-edit-guide.png')
      }
    };
  },
  computed: {
    activeCommentId() {
      return this.getActiveCommentId();
    },
    ...(0, _vuex.mapGetters)(['commentStatus', 'clickCommentBtnTimes', 'userId'])
  },
  watch: {
    activeId(val) {
      this.moveActiveComment(val);
    },
    clickCommentBtnTimes: {
      handler: function (val) {
        if (val) {
          this.isUnfolded = true;
          this.$store.commit('video/SET_COMMENT_STATUS', 'default');
          this.$nextTick(() => {
            var _this$$refs$sendBox, _this$$refs$sendBox$$;
            (_this$$refs$sendBox = this.$refs['send-box']) === null || _this$$refs$sendBox === void 0 ? void 0 : (_this$$refs$sendBox$$ = _this$$refs$sendBox.$refs['text-area']) === null || _this$$refs$sendBox$$ === void 0 ? void 0 : _this$$refs$sendBox$$.focus();
          });
        }
      },
      immediate: true
    }
  },
  async created() {
    this.commentLoading = true;
    try {
      await this.getComments();
    } finally {
      this.commentLoading = false;
    }
    this.handleFindComment();
  },
  mounted() {
    this.activeId = this.activeCommentId;
  },
  beforeDestroy() {
    this.$store.commit('video/SET_CLICK_COMMENT_BTN_TIMES', true);
  },
  methods: {
    handleContainerClick() {
      // eslint-disable-next-line no-sequences
      this.isUnfolded = false;
      this.isReply = false;
    },
    handleFindComment() {
      const id = this.$route.query.commentId;
      if (id && !this.findComment(id)) {
        this.$message.warning('这条评论已被删除或无权限');
      }
    },
    findComment(id) {
      for (const item of this.commentsList) {
        if (
        // eslint-disable-next-line eqeqeq
        item.comment_id == id ||
        // eslint-disable-next-line eqeqeq
        item.replies.some(reply => reply.comment_id == id)) {
          return true;
        }
      }
      return false;
    },
    moveActiveComment(val) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (val) {
            var _this$$refs$commentsW;
            let listComp = null;
            let height = 0;
            this.$children.forEach(item => {
              const targetComp = item.$refs['commentBox' + val];
              if (targetComp) {
                if (Array.isArray(targetComp)) {
                  listComp = [...targetComp];
                  height = listComp[0].$el.offsetTop;
                } else {
                  listComp = targetComp;
                  height = listComp.$el.offsetTop;
                }
              }
            });
            (_this$$refs$commentsW = this.$refs['commentsWrapper']) === null || _this$$refs$commentsW === void 0 ? void 0 : _this$$refs$commentsW.scroll({
              top: height - 65,
              // 向上移动的距离，如果有fixede布局， 直接减去对应距离即可
              behavior: 'smooth' // 平滑移动
            });
          }
        }, 200);
      });
    },
    changeIsUnfolded() {
      this.isUnfolded = true;
    },
    highlightAllSeekers() {
      const seekers = this.commentsList.filter(item => item.advise === 'seeker');
      if (seekers.length) {
        this.moveActiveComment(seekers[0].comment_id);
        seekers.forEach(item => {
          this.$set(item, 'highlight', true);
          setTimeout(() => {
            this.$set(item, 'highlight', false);
          }, 3000);
        });
      }
      this.isReply = false;
    },
    replyComment(_ref) {
      let {
        comment_id,
        sender_name,
        advise
      } = _ref;
      this.$store.dispatch('video/set_comment_status', advise === 'seeker' ? 'teach' : 'default');
      this.isUnfolded = true;
      this.isReply = true;
      this.sendBoxProps = {
        recipientName: sender_name,
        replyCommentId: comment_id,
        advise
      };
    },
    async getComments() {
      let res;
      const conversation_id = this.$route.query.id;
      if (!/^[0-9]*$/.test(conversation_id)) return;
      let commentsCount;
      switch (this.commentType) {
        case 'conversation':
          res = await (0, _videoDetail.getConvComments)({
            conversation_id
          });
          this.commentsList = res.results.list;
          commentsCount = res.results.total;
          this.$emit('getCommentsCount', commentsCount);
          break;
        case 'business':
          res = await (0, _business.getBusiComments)({
            deal_id: conversation_id
          });
          this.commentsList = res.results;
          break;
        case 'snippet':
          res = await (0, _library.getSnipComments)({
            snippet_id: conversation_id
          });
          this.commentsList = res.results.data;
          break;
      }
      this.formatRepliesTime();
      if (localStorage.isShowCommentCanEditGuide !== 'true') {
        setTimeout(() => {
          if (this.commentsList.length) {
            let id = -1;
            for (const comment of this.commentsList) {
              if (this.userId === comment.sender_id) {
                id = comment.comment_id;
                break;
              } else {
                const item = comment.replies.find(reply => {
                  return this.userId === reply.sender_id;
                });
                if (item) {
                  id = item.comment_id;
                  break;
                }
              }
            }
            const comment = document.getElementById('comment-box' + id);
            if (comment) {
              comment.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
              this.$refs['commentCanEditGuide'].start();
            }
          }
        }, 800);
      }
    },
    formatRepliesTime() {
      this.commentsList.forEach(item => {
        item.replies.forEach(reply => {
          reply.comment_timestamp = item.comment_timestamp;
        });
      });
    },
    sendSuccess() {
      this.getComments();
      this.isReply = false;
      if (this.commentStatus === 'teach') {
        this.$emit('getUnTeachList');
      }
    },
    async deleteComment(commentId) {
      const res = await (0, _videoDetail.deleteComment)(this.$route.query.id, commentId);
      if (res.code === 200) {
        this.$message.success('删除成功');
        this.getComments();
      }
    }
  }
};
exports.default = _default;