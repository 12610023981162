"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$tabList$;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "compliance-team-conversation"
  }, [_c("ComplianceKanban", {
    staticClass: "compliance-kanban",
    attrs: {
      "tab-list": _vm.tabList,
      "active-tab": (_vm$tabList$ = _vm.tabList[0]) === null || _vm$tabList$ === void 0 ? void 0 : _vm$tabList$.label,
      loading: _vm.loading
    },
    on: {
      handleTabClick: _vm.handleTabClick
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref) {
        var _vm$tabList$_vm$activ;
        let {
          content
        } = _ref;
        return [_vm.tabList.length && content.label === ((_vm$tabList$_vm$activ = _vm.tabList[_vm.activeTabIndex]) === null || _vm$tabList$_vm$activ === void 0 ? void 0 : _vm$tabList$_vm$activ.label) ? [_c("ComplianceTeamConversationContent", {
          attrs: {
            total: _vm.total,
            "table-data": _vm.tableDataList,
            "is-total": !_vm.activeTabIndex
          },
          on: {
            handleDistribute: _vm.handleDistribute,
            handleFiltersChange: _vm.handleFiltersChange,
            goComplianceWorkSpace: _vm.goComplianceWorkSpace
          }
        })] : _vm._e()];
      }
    }])
  }), _vm.interfacePermission.getRemovedRecord ? _c("el-button", {
    staticClass: "hover-show-bg remove-list-button",
    attrs: {
      icon: "iconfont icon-eliminated-already"
    },
    on: {
      click: _vm.openRemovedRecordDrawer
    }
  }, [_vm._v("已移除记录")]) : _vm._e(), _c("removed-record-drawer", {
    attrs: {
      visible: _vm.removedRecordDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.removedRecordDrawerVisible = $event;
      },
      refresh: _vm.handleFiltersChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;