import { render, staticRenderFns } from "./CardHeader.vue?vue&type=template&id=cbf8603e&scoped=true&"
import script from "./CardHeader.vue?vue&type=script&lang=js&"
export * from "./CardHeader.vue?vue&type=script&lang=js&"
import style0 from "./CardHeader.vue?vue&type=style&index=0&id=cbf8603e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbf8603e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cbf8603e')) {
      api.createRecord('cbf8603e', component.options)
    } else {
      api.reload('cbf8603e', component.options)
    }
    module.hot.accept("./CardHeader.vue?vue&type=template&id=cbf8603e&scoped=true&", function () {
      api.rerender('cbf8603e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Message/components/layout/CardHeader.vue"
export default component.exports