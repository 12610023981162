"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  name: 'EditTemplate',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    graphId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      config: {
        name: '',
        description: ''
      },
      loading: false
    };
  },
  methods: {
    async submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true;
          const res = await (0, _coachingCenter.createSmartTrainingCourseTemplate)({
            ...this.config,
            course_id: this.graphId
          }).finally(() => {
            this.loading = false;
          });
          if (res.code === 20000) {
            this.handleClose();
            this.$message.success(this.$t('coaching.savedSuccessfully'));
          }
        }
      });
    },
    handleClose() {
      this.$refs['form'].resetFields();
      this.$emit('update:dialogVisible', false);
    }
  }
};
exports.default = _default;