"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "pointDialog",
  data() {
    return {
      knownFlag: false
    };
  },
  created() {
    this.knownFlag = localStorage.getItem('known');
  },
  methods: {
    handleEnter() {
      localStorage.setItem('known', true);
      this.knownFlag = true;
    }
  }
};
exports.default = _default;