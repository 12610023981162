"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'PlayVoice',
  props: {
    isPlay: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltipContent: {
      type: String,
      default: ''
    }
  },
  computed: {
    playVoiceContent() {
      return this.disabled ? '暂无示例' : '示例';
    }
  },
  methods: {
    play() {
      if (this.disabled) return;
      this.$emit('play');
    }
  }
};
exports.default = _default;