"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteConvRecycleBin = deleteConvRecycleBin;
exports.getConvRecycleBin = getConvRecycleBin;
exports.recoverConvRecycleBin = recoverConvRecycleBin;
var _request = require("@/utils/request");
function getConvRecycleBin(params) {
  return (0, _request.webService)({
    url: '/recycle_bin/list/conversation',
    method: 'get',
    params
  });
}
function deleteConvRecycleBin(data) {
  return (0, _request.webService)({
    url: '/recycle_bin/delete/conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}
function recoverConvRecycleBin(data) {
  return (0, _request.webService)({
    url: '/recycle_bin/restore/conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}