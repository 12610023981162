"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-folder"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/noContent/home-empty.png"),
      width: "100px",
      alt: "暂无收藏"
    }
  }), _c("h1", {
    staticClass: "title"
  }, [_vm._v("暂无收藏")]), _c("p", [_vm._v(" 你的收藏夹空空如也~不如去 "), _c("span", {
    staticClass: "link",
    on: {
      click: function ($event) {
        return _vm.$emit("skipRanking");
      }
    }
  }, [_vm._v("排行榜")]), _vm._v("看看吧 ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;