"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PieChart',
  mixins: [_chartMixins.default],
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top: 40,
          type: 'scroll'
        },
        label: {
          formatter: '{b}\n{d}%',
          color: '#29304A',
          fontSize: 14,
          lineHeight: 18
        },
        series: [{
          name: '',
          type: 'pie',
          radius: 148,
          data: this.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          selectedMode: true,
          select: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          right: 0
        }]
      },
      selection: 0
    };
  },
  watch: {
    data: {
      handler(val) {
        // 清空选项
        this.chart && this.chart.dispatchAction({
          type: 'unselect',
          dataIndex: this.selection
        });
        this.options.series[0].data = val;
        this.init();
      },
      deep: true
    }
  },
  mounted() {
    this.chart.on('selectchanged', this.handleSelectchanged);
    this.chart.on('click', this.handleClick);
  },
  methods: {
    handleClick(params) {
      this.$emit('pieClick', params);
    },
    handleSelectchanged(params) {
      if (params.fromAction === 'select') {
        const index = params.selected[0].dataIndex[0];
        this.selection = index;
        // 提前判断是否有子项
        // if (!this.data[index].has_children) {
        //   this.chart &&
        //     this.chart.dispatchAction({
        //       type: "unselect",
        //       dataIndex: index,
        //     });
        //   return;
        // }
      }

      this.$emit('selectChange', params);
    }
  }
};
exports.default = _default;