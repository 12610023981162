"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-container"
  }, [_vm.noPrint ? _c("div", {
    staticClass: "text-content"
  }, [_vm._v(_vm._s(_vm.text))]) : _c("div", {
    staticClass: "text-content"
  }, [_c("span", [_vm._v(_vm._s(_vm.printingText) + " "), _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCursor || _vm.streamTextStatus === "printing",
      expression: "showCursor || streamTextStatus === 'printing'"
    }],
    staticClass: "text-cursor"
  })])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;