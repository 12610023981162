"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "tts-avatar-name-select"
  }, _vm._l(_setup.avatarNames, function (item) {
    return _c("div", {
      key: item.avatar_type,
      staticClass: "avatar-container",
      class: {
        "is-selected": _vm.value === item.avatar_type
      },
      on: {
        click: function ($event) {
          return _setup.onAvatarClick(item.avatar_type, item);
        }
      }
    }, [_c("MgvAvatarName", {
      attrs: {
        src: item.url,
        size: 40
      }
    })], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;