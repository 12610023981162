"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "knowledge-point-extract"
  }, [_c("header", [_c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      size: "small",
      icon: "icon-arrow-left",
      square: ""
    },
    on: {
      click: function ($event) {
        return _setup.closeKnowledgeExtract("goBack");
      }
    }
  }), _c("span", [_vm._v("提取知识点")]), _c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-close",
      square: "",
      size: "small"
    },
    on: {
      click: function ($event) {
        return _setup.closeKnowledgeExtract("close");
      }
    }
  })], 1), _c("div", {
    ref: "knowledgePointExtractRef",
    staticClass: "knowledge-point-content"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isGenerating,
      expression: "isGenerating"
    }],
    staticClass: "no-data"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/generating1.gif")
    }
  }), _c("p", [_vm._v("智能提取中")])]), !_setup.isGenerating && !_setup.isExtractDone ? _vm._l(_setup.aiSentences, function (item, index) {
    return _c("el-input", {
      key: index,
      staticClass: "generating-input",
      attrs: {
        value: item.sentence,
        type: "textarea",
        disabled: "",
        autosize: "",
        resize: "none"
      }
    });
  }) : _vm._e(), _setup.isExtractDone ? _vm._l(_setup.aiKnowledgePoints, function (knowledge, index) {
    return _c("div", {
      key: index,
      staticClass: "knowledge-item"
    }, [_c("header", [_c("el-input", {
      staticClass: "knowledge-name-input",
      attrs: {
        type: "textarea",
        autosize: "",
        resize: "none",
        maxlength: 300
      },
      model: {
        value: knowledge.name,
        callback: function ($$v) {
          _vm.$set(knowledge, "name", $$v);
        },
        expression: "knowledge.name"
      }
    }), _c("MgvButton", {
      staticClass: "delete-button",
      attrs: {
        type: "text",
        color: "info",
        icon: "icon-delete",
        square: "",
        size: "small"
      },
      on: {
        click: function ($event) {
          return _setup.deleteKnowledge(index);
        }
      }
    })], 1), _vm._l(knowledge.reply, function (reply, replyIndex) {
      return _c("div", {
        key: replyIndex,
        staticClass: "relay-item"
      }, [_c("span", [_vm._v("(" + _vm._s(replyIndex + 1) + ")")]), _c("el-input", {
        attrs: {
          type: "textarea",
          autosize: "",
          resize: "none",
          maxlength: 300
        },
        model: {
          value: knowledge.reply[replyIndex],
          callback: function ($$v) {
            _vm.$set(knowledge.reply, replyIndex, $$v);
          },
          expression: "knowledge.reply[replyIndex]"
        }
      }), _c("MgvButton", {
        staticClass: "delete-reply-button",
        attrs: {
          type: "text",
          color: "info",
          icon: "icon-delete",
          square: "",
          size: "small"
        },
        on: {
          click: function ($event) {
            return _setup.deleteReply(index, replyIndex);
          }
        }
      })], 1);
    })], 2);
  }) : _vm._e()], 2), _c("div", {
    staticClass: "footer"
  }, [_setup.isExtractDone ? [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-refresh-left",
      disabled: _setup.saveKnowledgePointLoading
    },
    on: {
      click: _setup.reextract
    }
  }, [_vm._v("重新提取")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-upload1"
    },
    on: {
      click: function ($event) {
        return _setup.closeKnowledgeExtract("goBack");
      }
    }
  }, [_vm._v("重新上传")]), _c("MgvButton", {
    attrs: {
      loading: _setup.saveKnowledgePointLoading,
      icon: "icon-preserve"
    },
    on: {
      click: _setup.saveKnowledgePoint
    }
  }, [_vm._v("保存知识点")])] : _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-call-zoom"
    },
    on: {
      click: function ($event) {
        return _setup.closeKnowledgeExtract("goBack");
      }
    }
  }, [_vm._v("停止提取")])], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;