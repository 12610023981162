"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RiskTabContent = _interopRequireDefault(require("./components/RiskTabContent"));
var _SpeakTimeTabContent = _interopRequireDefault(require("./components/SpeakTimeTabContent"));
var _CommentTabContent = _interopRequireDefault(require("./components/CommentTabContent"));
var _EventsTabContent = _interopRequireDefault(require("./components/EventsTabContent"));
var _ScoreRuleTabContent = _interopRequireDefault(require("./components/ScoreRuleTabContent.vue"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeTabsDrawer',
  components: {
    RiskTabContent: _RiskTabContent.default,
    EventsTabContent: _EventsTabContent.default,
    CommentTabContent: _CommentTabContent.default,
    NextStepTabContent: _EventsTabContent.default,
    ObjectionTabContent: _EventsTabContent.default,
    SpeakTimeTabContent: _SpeakTimeTabContent.default,
    AccountLabelTabContent: _EventsTabContent.default,
    ImageAvatar: _ImageAvatar.default,
    ScoreRuleTabContent: _ScoreRuleTabContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    headerList: {
      type: Array,
      default: () => []
    },
    activeTabName: {
      type: String,
      default: ''
    },
    currentRow: {
      type: Object,
      default: () => ({})
    },
    activeConvDate: {
      type: String,
      default: ''
    },
    tradeId: {
      type: [String, Number],
      default: ''
    },
    currentFieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes']),
    tabsHeaders() {
      const headers = [{
        field_name: 'risk',
        icon: 'risk-2',
        activeIcon: 'risk'
      }, {
        field_name: 'speak_time',
        icon: 'video-camera',
        activeIcon: 'video-camera-solid-2'
      }, {
        field_name: 'events',
        icon: 'key-event',
        activeIcon: 'key-event-fill'
      }, {
        field_name: 'next_step',
        icon: 'key-event',
        activeIcon: 'key-event-fill'
      }, {
        field_name: 'objection',
        icon: 'key-event',
        activeIcon: 'key-event-fill'
      }, {
        field_name: 'comment',
        icon: 'chat-line-square',
        activeIcon: 'chat-line-square-fill'
      }, {
        field_name: 'account_label',
        icon: 'price-tag',
        activeIcon: 'price-tag-fill'
      }, {
        field_name: 'score_rule',
        icon: 'conversation-scoring-2',
        activeIcon: 'conversation-scoring-fill-2'
      }];
      const headerList = this.headerList.filter(item => {
        if (headers.map(header => header.field_name).includes(item.field_name)) {
          const currentHead = headers.find(header => header.field_name === item.field_name);
          item.icon = currentHead.icon;
          item.activeIcon = currentHead.activeIcon;
          return true;
        }
      });
      // 购车时间是特殊的客户标签,返回的是 event_type === 2
      if (this.headerList.some(item => item.event_type === 2) && !headerList.some(item => item.field_name === 'account_label')) {
        headerList.push({
          field_name: 'account_label',
          icon: 'price-tag',
          activeIcon: 'price-tag-fill',
          name: '客户标签'
        });
      }
      // 交易评分的展示与否
      const hasScoreRule = this.headerList.some(item => item.field_type === 'score_rule');
      if (hasScoreRule) {
        headerList.push({
          field_name: 'score_rule',
          icon: 'conversation-scoring-2',
          activeIcon: 'conversation-scoring-fill-2',
          name: '交易评分'
        });
      }
      return headerList;
    },
    imageAvatar() {
      return this.currentRow.own_by.avatar || this.currentRow.own_by.name;
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    }
  },
  methods: {
    goCustomerPage(account_id) {
      if (this.hasCustomerPermission) {
        const routeData = this.$router.resolve({
          path: _routeMap.ROUTES.customer,
          query: {
            id: account_id
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    getComponentsName(field_name) {
      const reg = new RegExp('_', 'g');
      return field_name.replace(reg, '-');
    },
    //  点击跟进动态气泡tab滚动到对应会话
    scrollToActiveConv(top) {
      const tabs_content = document.getElementsByClassName('el-tabs__content')[0];
      setTimeout(() => {
        tabs_content.scrollTo({
          top,
          behavior: 'smooth'
        });
      }, 500);
    },
    handleTitleClick() {
      window.open(`${location.origin}${_routeMap.ROUTES.tradeDetail}?id=${this.currentRow.trade_id}&noback=true`);
    },
    toggleTab(tab, event) {
      this.$emit('update:activeTabName', tab.name);
    },
    closeDrawer() {
      this.$emit('update:activeTabName', '');
      this.$emit('update:activeConvDate', '');
      this.$emit('update:visible', false);
    },
    loadMore() {
      this.$emit('loadMore');
    }
  }
};
exports.default = _default;