"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OperateMemberDialog = _interopRequireDefault(require("@/components/OperateMemberDialog"));
var _DivertMemberDialog = _interopRequireDefault(require("./components/DivertMemberDialog"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _export = require("@/api/export");
var _ExportButton = _interopRequireDefault(require("@/views/management/components/ExportButton"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    OperateMemberDialog: _OperateMemberDialog.default,
    DivertMemberDialog: _DivertMemberDialog.default,
    ImageAvatar: _ImageAvatar.default,
    ExportButton: _ExportButton.default
  },
  props: {},
  data() {
    return {
      filterName: '',
      operateDialogVisible: false,
      divertDialogVisible: false,
      dialogType: '',
      memberInfo: {},
      roleList: [],
      tableData: [],
      listParams: {
        page: 1,
        size: 10
      },
      total: 0,
      permissions: [],
      permissionsList: [],
      adminPermission: '',
      adminRole: '',
      tableLoading: false,
      manageTree: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getAdminRoleList();
    this.getAdminRolePermissions();
    this.getAdminMembers();
  },
  methods: {
    exportApi() {
      return (0, _export.exportManagerRole)({
        filter: this.filterName,
        permission: this.adminPermission || 0,
        role_id: this.adminRole || 0
      });
    },
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    async getAdminRolePermissions() {
      const res = await (0, _permissions.getAdminRolePermissions)();
      if (res.code === 200) {
        this.permissions = res.results.permission_tree;
        this.handlePermissionsList();
      }
    },
    getRoleNames(roleList) {
      const roleNameList = roleList.map(item => item.name);
      return roleNameList.join('；');
    },
    searchMember() {
      this.listParams.page = 1;
      this.getAdminMembers();
    },
    async getAdminRoleList() {
      const res = await (0, _permissions.getAdminRoleList)();
      if (res.code === 20000) {
        this.roleList = res.results.role_list;
      }
    },
    async getAdminMembers() {
      this.tableLoading = true;
      const data = {
        filter: this.filterName,
        permission: this.adminPermission,
        role_id: this.adminRole,
        ...this.listParams
      };
      const res = await (0, _permissions.getAdminMembers)(data);
      this.tableLoading = false;
      if (res.code === 200) {
        this.tableData = res.results.user_list;
        this.total = res.results.count;
        this.manageTree = res.results.manage_tree;
      }
    },
    sortTree(tree) {
      tree.forEach(item => {
        if (item.children && item.children.length) {
          item.children.sort(this.compare('order'));
          this.sortTree(item.children);
        }
      });
    },
    compare(property) {
      return function (obj1, obj2) {
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2; // 升序
      };
    },

    handlePermissionsList() {
      // 给根节点排序
      this.permissions.sort(this.compare('order'));
      // 给每个根节点下的孩子节点排序
      this.sortTree(this.permissions);
      this.permissions.forEach((item, index) => {
        this.permissionsList[index] = {
          label: item.name,
          options: []
        };
        if (item.children && item.children.length) {
          item.children.forEach(child => {
            this.permissionsList[index].options.push({
              value: child.id,
              name: child.name
            });
          });
        }
      });
    },
    openDialog(type, row) {
      this.memberInfo = {};
      this.dialogType = type;
      this.operateDialogVisible = true;
      if (!row) return;
      if (type === 'edit') {
        this.memberInfo = row;
      }
    },
    openDivertDialog(row) {
      this.memberInfo = row;
      this.divertDialogVisible = true;
    },
    removeMember(row) {
      this.$confirm(`确定移除${row.name}的所有管理员权限吗？`, '移除管理员', {
        confirmButtonText: '移除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _permissions.deleteMemberRole)({
          user_ids: [row.user_id]
        });
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.tableData = this.tableData.filter(item => item.user_id !== row.user_id);
        }
      });
    },
    handleListParamsChange(val, type) {
      this.listParams[type] = val;
      this.getAdminMembers();
    }
  }
};
exports.default = _default;