"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchUpdateDatasetAnnotation = batchUpdateDatasetAnnotation;
exports.batchUpdateTestData = batchUpdateTestData;
exports.batchUpdateTrainData = batchUpdateTrainData;
exports.createPromptTemplate = createPromptTemplate;
exports.createRapidModel = createRapidModel;
exports.createTrainTask = createTrainTask;
exports.deletePromptTemplate = deletePromptTemplate;
exports.deleteTrailAnnotation = deleteTrailAnnotation;
exports.editRapidModel = editRapidModel;
exports.exampleSentenceExpand = exampleSentenceExpand;
exports.expandTrainDatasetAi = expandTrainDatasetAi;
exports.expandTrainDatasetManual = expandTrainDatasetManual;
exports.getAnnotationContext = getAnnotationContext;
exports.getDataSetDetailList = getDataSetDetailList;
exports.getDatasetGenerateStatus = getDatasetGenerateStatus;
exports.getModelPredictAnnotationIds = getModelPredictAnnotationIds;
exports.getModelTestPrecision = getModelTestPrecision;
exports.getModelTestResultDetail = getModelTestResultDetail;
exports.getModelTrainAnnotationIds = getModelTrainAnnotationIds;
exports.getModelTrainDataDetail = getModelTrainDataDetail;
exports.getPromptTemplateList = getPromptTemplateList;
exports.getRapidModelInfo = getRapidModelInfo;
exports.getRapidModelSentence = getRapidModelSentence;
exports.getTrailAnnotationContext = getTrailAnnotationContext;
exports.getTrainDataStatistics = getTrainDataStatistics;
exports.getTrainDatasetAnnotation = getTrainDatasetAnnotation;
exports.modelCreateTrainTaskApi = modelCreateTrainTaskApi;
exports.storeRapidModelStep = storeRapidModelStep;
exports.updateAnnotate = updateAnnotate;
var _request = require("@/utils/request");
function getDataSetDetailList(data) {
  return (0, _request.webService)({
    url: '/event/annotation/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建快速建模
function createRapidModel(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑模型
function editRapidModel(data) {
  return (0, _request.webService)({
    url: '/model/edit_info',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 例句智能扩充
function exampleSentenceExpand(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/example/expand',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取训练数据
function createTrainTask(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/example/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 查询模型训练数据明细
function getModelTrainDataDetail(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_train_data',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取模型例句
function getRapidModelSentence(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/example/get',
    method: 'get',
    params
  });
}

// 获取快速模型信息
function getRapidModelInfo(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/info',
    method: 'get',
    params
  });
}

// 存储快速建模在哪一个页面
function storeRapidModelStep(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/action',
    method: 'post',
    dataType: 'json',
    data
  });
}

//  查询会话标注句子的上下文
function getAnnotationContext(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_annotation_context',
    method: 'get',
    params
  });
}

// 批量修改训练集标注状态
function batchUpdateTrainData(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/update_train_annotation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改检验弹窗中句子命中情况
function updateAnnotate(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/update_annotation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建训练任务
function modelCreateTrainTaskApi(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/create_train_task',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 查询快速模型测试结果明细
function getModelTestResultDetail(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_trial_detail',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 批量修改测试集标注
function batchUpdateTestData(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/update_trial_annotation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 训练数据集标注统计
function getTrainDataStatistics(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/train_dataset_stats',
    method: 'get',
    params
  });
}
// 查询模型训练数据标注id列表
function getModelTrainAnnotationIds(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_train_annotation_ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 相似句数据集生成状态
function getDatasetGenerateStatus(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/example/dataset_status',
    method: 'get',
    params
  });
}

// 查询模型测试精确率
function getModelTestPrecision(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_model_trial_precision',
    method: 'get',
    params
  });
}

/* ----------------------------------- 修正训练集 ----------------------------------- */

// 获取训练集相似句错误标注
function getTrainDatasetAnnotation(data) {
  return (0, _request.webService)({
    url: '/model/get_similar_train_annotation',
    method: 'post',
    dataType: 'json',
    timeout: 60,
    data
  });
}

// 批量修改数据集标注
function batchUpdateDatasetAnnotation(data) {
  return (0, _request.webService)({
    url: '/event/annotation/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 模型预测预测数据集标注id列表
function getModelPredictAnnotationIds(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_trial_annotation_ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 效果预览页面弹窗中获取模型上下文
function getTrailAnnotationContext(params) {
  return (0, _request.webService)({
    url: '/model_shortcut/get_trial_annotation_context',
    method: 'get',
    params
  });
}

// 扩充训练句子
function expandTrainDatasetAi(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/dataset/ai_import',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 手动扩充训练集
function expandTrainDatasetManual(data) {
  return (0, _request.webService)({
    url: '/event/annotation/conv_import',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除测试集中的标注
function deleteTrailAnnotation(data) {
  return (0, _request.webService)({
    url: '/model_shortcut/delete_trial_annotation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建prompt模版
function createPromptTemplate(data) {
  return (0, _request.webService)({
    url: '/model/prompt_tpl/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取模版列表
function getPromptTemplateList(data) {
  return (0, _request.webService)({
    url: '/model/prompt_tpl/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除模版
function deletePromptTemplate(data) {
  return (0, _request.webService)({
    url: '/model/prompt_tpl/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}