"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-cascader", _vm._g(_vm._b({
    staticClass: "key-event-cascader",
    attrs: {
      placeholder: "请选择关键事件",
      options: _setup.localKeyEventOptions,
      "show-all-levels": false,
      filterable: "",
      "filter-method": _setup.filterKeyEvent,
      "popper-class": "key-event-cascader-popper",
      props: {
        expandTrigger: "hover",
        children: "children",
        value: "id",
        label: "name",
        emitPath: false,
        disabled: "is_disabled"
      }
    }
  }, "el-cascader", _vm.$attrs, false), _vm.$listeners));
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;