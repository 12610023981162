"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversation-wrap"
  }, [_vm.list.length && _vm.conversationTypes ? _c("div", {
    staticClass: "conversation-title"
  }, [_c("span", {
    staticClass: "conversation-name"
  }, [_vm._v("会话名称")]), _c("span", {
    staticClass: "sales-representative"
  }, [_vm._v("销售")])]) : _vm._e(), _c("div", {
    staticClass: "conversation-item"
  }, [_vm._l(_vm.list, function (item) {
    return [item.conversation_type !== "doc" ? _c("div", {
      key: item.id,
      staticClass: "conversation-detail"
    }, [_c("div", {
      staticClass: "conversation-item-left"
    }, [item.conversation_type !== "audio" ? _c("el-image", {
      staticClass: "conversation-item-left-img",
      attrs: {
        src: item.thumbnail,
        alt: ""
      },
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }) : _c("div", {
      staticClass: "conversation-item-left-icon",
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-phone-outline"
    })]), _c("div", {
      staticClass: "conversation-minute"
    }, [_c("span", {
      staticClass: "conversation-minute-number",
      domProps: {
        innerHTML: _vm._s(_vm.getDuration(item.duration))
      }
    })])], 1), _c("span", {
      staticClass: "conversation-desc",
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_vm._v(_vm._s(item.name))]), item.host.name ? _c("span", {
      staticClass: "sales-representative-name"
    }, [_vm._v(_vm._s(item.host.name))]) : _c("span", {
      staticClass: "sales-representative-name-null"
    }, [_vm._v("未设定")])]) : _vm._e()];
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;