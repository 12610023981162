"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _RankingNoSnippet = _interopRequireDefault(require("./RankingNoSnippet"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RankingPersonsList',
  components: {
    ImageAvatar: _ImageAvatar.default,
    NoContent: _RankingNoSnippet.default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
exports.default = _default;