"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "not-support"
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/AIHelper/no_support.png")
    }
  }), _c("h3", [_vm._v(_vm._s(_vm.tipTitle))]), _c("p", [_vm._v(" " + _vm._s(_vm.tipContent) + " ")])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;