"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-popover", {
    attrs: {
      placement: "bottom-end",
      "popper-class": "ai-conversation-sync-popover",
      trigger: "hover"
    }
  }, [_c("div", {
    staticClass: "content-container"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/aiConversation.png")
    }
  }), _c("ul", [_c("li", [_vm._v(" 开启后，成员在AI客户中的练习录音会自动上传至会话-培训空间下，以便主管进行进一步分析 ")]), _c("li", [_vm._v("若成员练习多次，只会保留得分最高的一次记录到会话中")]), _c("li", [_vm._v(" 若有成员想查看培训空间的会话，需前往管理后台-"), _c("el-link", {
    attrs: {
      underline: false,
      type: "primary"
    },
    on: {
      click: _setup.goDepartmentAndMember
    }
  }, [_vm._v("部门和成员")]), _vm._v("页面，为该成员配置培训空间为副空间 ")], 1)])]), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("div", {
    staticClass: "sync-btn"
  }, [_c("span", [_vm._v("同步到会话")]), _c("el-switch", {
    attrs: {
      value: _setup.syncConfig,
      disabled: _setup.loading
    },
    on: {
      change: _setup.changeConfig
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;