"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
var _default = {
  __name: 'IntelligentExtension',
  props: {
    exampleSentenceList: {
      type: Array,
      default: () => []
    },
    intelligentSentenceDrawerVisible: {
      type: Boolean,
      default: false
    },
    gptLoading: {
      type: Boolean,
      default: false
    },
    entityTypeLabelObj: {
      type: Object,
      default: () => ({})
    },
    exampleType: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:intelligentSentenceDrawerVisible', 'handleIntelligentSentence', 'deleteExampleSentence', 'addExtendedSentenceToExample'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const entityTypeObj = (0, _vue.computed)(() => {
      var _props$entityTypeLabe;
      return ((_props$entityTypeLabe = props.entityTypeLabelObj) === null || _props$entityTypeLabe === void 0 ? void 0 : _props$entityTypeLabe[props.exampleType]) || {};
    });
    const addExtendedSentenceToExample = () => {
      emit('addExtendedSentenceToExample');
    };
    const handleIntelligentSentence = () => {
      if (localIntelligentSentence.value.length > 1000) {
        _useMessage.useMessage.warning('最多支持1000个字符，当前已超出');
        return;
      }
      emit('handleIntelligentSentence');
    };
    const deleteExampleSentence = idx => {
      emit('deleteExampleSentence', idx);
    };
    const closeIntelligentSentenceDrawer = () => {
      emit('update:intelligentSentenceDrawerVisible', false);
    };
    const editableDivRef = (0, _vue.ref)();
    const localIntelligentSentence = (0, _vue.ref)('');
    const divInput = ev => {
      if (ev.target.innerText.length > 1000) {
        _useMessage.useMessage.warning('最多支持1000个字符');
        return;
      }
      localIntelligentSentence.value = ev.target.innerText;
    };
    expose({
      getLocalIntelligentSentence: () => {
        return localIntelligentSentence.value;
      },
      setLocalIntelligentSentence: val => {
        localIntelligentSentence.value = val;
        editableDivRef.value.innerText = val;
      }
    });
    return {
      __sfc: true,
      props,
      entityTypeObj,
      emit,
      addExtendedSentenceToExample,
      handleIntelligentSentence,
      deleteExampleSentence,
      closeIntelligentSentenceDrawer,
      editableDivRef,
      localIntelligentSentence,
      divInput
    };
  }
};
exports.default = _default;