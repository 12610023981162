"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'DataOverview',
  props: {
    overViewData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getPercentage() {
      return Number((this.overViewData.count / this.overViewData.all_count * 100).toFixed(2)) || 0;
    }
  },
  methods: {
    // 跳转商机列表
    goTargetDeals(dealIds) {
      const otherPageCarryingFilter = {
        dealIds,
        label: '客户群画像筛选条件'
      };
      sessionStorage.setItem('otherPageCarryingFilter', JSON.stringify(otherPageCarryingFilter));
      window.open(`${location.origin}${_routeMap.ROUTES.deal}`);
      sessionStorage.removeItem('otherPageCarryingFilter');
    }
  }
};
exports.default = _default;