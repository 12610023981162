"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "upload-media-dialog-wrapper",
    attrs: {
      visible: _vm.dialogVisible,
      "before-close": _setup.handleClose,
      "append-to-body": "",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "custom-class": "upload-conversation-dialog"
    }
  }, [_c(_setup.UploadMediaDialogHead, {
    attrs: {
      slot: "title",
      "tooltip-list": ["格式支持：WAV、MP3、M4A、WMA、AAC、OGG、AMR、FLAC；AVI、RMVB、MKV、ASF、WMV、MP4、3GP、FLV、MOV、M4V", `单个音频文件最大${_setup.audioUploadLimit}MB`, "单个视频文件最大4GB", "单个文件最长3个小时"]
    },
    slot: "title"
  }), _c("div", {
    staticClass: "upload-conversation__content"
  }, [_c(_setup.UploadMedia, {
    ref: "uploadMediaRef",
    on: {
      canUpload: _setup.canUpload
    }
  }), _c(_setup.UploadConversationForm, {
    ref: "uploadConvFormRef"
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _setup.uploadBtnDisabled
    },
    on: {
      click: _setup.startUpload
    }
  }, [_vm._v("上传")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;