"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "members-finder",
    class: {
      "is-collapse-tags": _vm.collapseTags
    }
  }, [_c("el-select", {
    ref: "selectInput",
    attrs: {
      filterable: _vm.filterable,
      multiple: "",
      remote: "",
      "collapse-tags": _vm.collapseTags,
      "remote-method": _vm.getMember,
      loading: _vm.searchLoading,
      placeholder: _vm.placeholder,
      "multiple-limit": _vm.multipleLimit,
      "popper-class": `${_vm.popperClass} members-finder-popover ${_vm.isShowHaveDeletedMembers ? "has-bottom-check" : ""}`,
      disabled: _vm.disabled
    },
    on: {
      focus: _vm.handleFocus,
      change: _vm.handleSelectChange
    },
    model: {
      value: _vm.selection,
      callback: function ($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }, [_vm._l(_vm.memberOptions, function (item) {
    return _c("el-option", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.query,
        expression: "query"
      }],
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        disabled: !_vm.query
      }
    }, [_c("div", {
      staticClass: "search-item"
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("MgvTag", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowHaveDeletedMembers && item.is_delete,
        expression: "isShowHaveDeletedMembers && item.is_delete"
      }]
    }, [_vm._v("已离职")])], 1)]);
  }), _c("el-option", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.query && _vm.hasSelf,
      expression: "!query && hasSelf"
    }],
    key: "0",
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      label: _vm.name,
      value: _vm.userId,
      disabled: !_vm.hasSelf
    }
  }, [_c("div", {
    staticClass: "custom-option"
  }, [_c("div", {
    staticClass: "self-info"
  }, [_c("image-avatar", {
    attrs: {
      size: "small",
      avatar: _vm.avatar,
      name: _vm.name
    }
  }), _c("span", [_vm._v(_vm._s(_vm.name))])], 1), _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v("自己")])], 1)]), _c("el-option", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.query && _vm.hasSelf,
      expression: "!query && hasSelf"
    }],
    key: "disable",
    staticStyle: {
      padding: "0",
      height: "auto",
      cursor: "default"
    },
    attrs: {
      disabled: "",
      value: ""
    }
  }, [_c("el-divider")], 1), _c("el-option", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.query,
      expression: "!query"
    }],
    key: "-1",
    staticClass: "tree-option",
    class: {
      "set-max-height": _vm.hasSelf
    },
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: 0,
      disabled: ""
    }
  }, [_c("lazy-tree", {
    key: _vm.organizationId,
    ref: "tree",
    class: {
      "is-radio-style": _vm.isRadio
    },
    attrs: {
      "tree-config": _vm.treeConfig,
      "organization-id-outer": _vm.organizationIdOuter,
      loading: _vm.searchLoading,
      value: _vm.selection,
      "is-disabled": _vm.selection.length >= _vm.multipleLimit,
      "is-set-checked-root": _vm.isSetCheckedRoot,
      "is-repetitive": _vm.isRepetitive,
      "get-member-api-type": _vm.getMemberApiType,
      params: _vm.params,
      "is-show-have-deleted-members": _vm.isCheckedDeleted
    },
    on: {
      "update:loading": function ($event) {
        _vm.searchLoading = $event;
      },
      treeRendered: function ($event) {
        return _vm.$emit("treeRendered");
      },
      checkChange: _vm.handleCheckChange,
      check: _vm.handleCheck
    }
  })], 1), _vm.isShowHaveDeletedMembers ? _c("el-checkbox", {
    staticClass: "deleted-member-check",
    on: {
      change: _vm.handleCheckDeleted
    },
    model: {
      value: _vm.isCheckedDeleted,
      callback: function ($$v) {
        _vm.isCheckedDeleted = $$v;
      },
      expression: "isCheckedDeleted"
    }
  }, [_vm._v("显示60天内离职成员")]) : _vm._e(), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.query && !_vm.searchLoading && !_vm.memberOptions.length,
      expression: "query && !searchLoading && !memberOptions.length"
    }],
    staticClass: "no-data"
  }, [_vm._v(" 无相关结果 ")])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;