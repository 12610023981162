"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _call = require("@/api/call");
var _default = {
  name: 'SeatSetForm',
  props: {
    type: {
      type: String,
      default: 'set' // set 设置弹框， login 登入弹框， logout 退出弹框
    },

    isLoginOK: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateBindTel = (rule, value, callback) => {
      if (this.seatForm.answerType !== 'Local') {
        callback();
      } else {
        if (!value) {
          callback(new Error('请输入手机号'));
        } else if (/^1[3456789]\d{9}$/.test(value) == false) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      }
    };
    const validateOffBindTel = (rule, value, callback) => {
      if (!this.seatForm.offlinePhoneAnswer) {
        callback();
      } else {
        if (!value) {
          callback(new Error('请输入手机号'));
        } else if (/^1[3456789]\d{9}$/.test(value) == false) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      }
    };
    return {
      seatForm: {
        seatNumber: '',
        onlineStatus: 'free',
        answerType: 'sip',
        // 接听方式 sip 在线接听， Local 手机接听
        bindTel: '',
        offlinePhoneAnswer: 0,
        askNoMore: 0,
        offlineBindTel: ''
      },
      rules: {
        seatNumber: [{
          required: true,
          message: '请选择坐席号',
          trigger: 'change'
        }],
        onlineStatus: [{
          required: true,
          message: '请选择在线状态',
          trigger: 'change'
        }],
        answerType: [{
          required: true,
          message: '请选择接听方式',
          trigger: 'change'
        }],
        bindTel: [{
          validator: validateBindTel,
          trigger: 'blur'
        }],
        offlineBindTel: [{
          validator: validateOffBindTel,
          trigger: 'blur'
        }]
      },
      initForm: null,
      seatsOptions: [],
      bindTelOptions: [],
      proxy_url: ''
    };
  },
  computed: {
    sureText() {
      if (this.type === 'login') {
        return '登入';
      } else if (this.type === 'logout') {
        return '退出';
      } else {
        return '保存';
      }
    }
  },
  watch: {
    isLoginOK(val) {
      if (val) {
        this.closeDialog();
      }
    },
    'seatForm.bindTel': {
      handler(val) {
        if (val) {
          this.seatForm.offlineBindTel = val;
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    async getCallSeats() {
      const res = await (0, _call.getCallSeats)();
      if (res.code === 200) {
        this.seatsOptions = res.results;
        if (res.results && res.results.length > 0) {
          this.seatForm.seatNumber = this.seatsOptions[0].id;
        }
      }
      this.getSeatSettings();
    },
    async getBindTels() {
      const res = await (0, _call.getBindTels)();
      if (res.code === 200) {
        const initArray = res.results;
        const newArray = [];
        initArray.forEach(item => {
          const tel = item;
          newArray.push({
            value: tel
          });
        });
        this.bindTelOptions = newArray;
      }
    },
    telsSearch(queryString, cb) {
      var restaurants = this.bindTelOptions;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;

      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleBindTelSelect(val) {},
    handleOfflineBindTelSelect(val) {},
    closeDialog() {
      this.seatForm = this.initForm;
      this.$emit('closeSeatDialog');
    },
    submitForm() {
      this.$refs['seatForm'].validate(valid => {
        if (valid) {
          if (this.type === 'login') {
            this.$emit('formLogin', this.seatForm);
          } else if (this.type === 'set') {
            this.$emit('formSetUpdata', this.seatForm);
          } else if (this.type === 'logout') {
            this.$emit('formLogout', this.seatForm);
          }
        } else {
          return false;
        }
      });
    },
    seatNumberChange() {
      const activeItem = this.getSeatActiveObj();
      if (activeItem) {
        this.proxy_url = activeItem.proxy_url;
        this.$emit('getProxyUrl', activeItem.proxy_url);
      }
    },
    getSeatActiveObj() {
      const activeItem = this.seatsOptions.find(this.getCurSeatActiveItem);
      return activeItem;
    },
    getCurSeatActiveItem(item) {
      return item.id == this.seatForm.seatNumber;
    },
    getSeatSettings() {
      (0, _call.getSeatSettings)().then(res => {
        const {
          seat_id,
          default_tel,
          seat_login_type,
          seat_status,
          is_login_default
        } = res.results;
        // 判断原来的seat_id是否在当前获取的坐席列表中
        if (seat_id) {
          this.seatForm.seatNumber = seat_id;
        }
        let seatIdVal = null;
        const activeItem = this.getSeatActiveObj();
        if (activeItem) {
          seatIdVal = activeItem.id;
        }
        this.seatForm = {
          seatNumber: seatIdVal,
          onlineStatus: seat_status || 'free',
          answerType: seat_login_type || 'sip',
          bindTel: default_tel,
          offlinePhoneAnswer: 0,
          askNoMore: is_login_default,
          offlineBindTel: default_tel
        };
        this.initForm = {
          ...this.seatForm
        };
        this.seatNumberChange();
      });
    }
  }
};
exports.default = _default;