"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'FiltersGroup',
  data() {
    return {
      filterDate: ''
    };
  },
  created() {
    this.filterDate = [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
    // this.$emit('dateChange', this.filterDate);
    this.dateRangeChange();
  },
  methods: {
    dateRangeChange() {
      this.$store.commit('sop/SET_DATE_RANGE', this.filterDate);
    },
    getDatePickerOptions() {
      const that = this;
      const todayDate = that.$moment().format('YYYY-MM-DD');
      return {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', [todayDate, todayDate]);
          }
        }, {
          text: '昨天',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().subtract(1, 'days').format('YYYY-MM-DD'), that.$moment().subtract(1, 'days').format('YYYY-MM-DD')]);
          }
        }, {
          text: '本周',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().startOf('week').format('YYYY-MM-DD'), todayDate]);
          }
        }, {
          text: '上周',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().week(that.$moment().week() - 1).startOf('week').format('YYYY-MM-DD'), that.$moment().week(that.$moment().week() - 1).endOf('week').format('YYYY-MM-DD')]);
          }
        }, {
          text: '过去7天',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().subtract(7, 'days').format('YYYY-MM-DD'), todayDate]);
          }
        }, {
          text: '过去30天',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().subtract(30, 'days').format('YYYY-MM-DD'), todayDate]);
          }
        }, {
          text: '过去12个月',
          onClick(picker) {
            picker.$emit('pick', [that.$moment().subtract(12, 'month').format('YYYY-MM-DD'), todayDate]);
          }
        }]
      };
    }
  }
};
exports.default = _default;