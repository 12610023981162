"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ComplianceStatusFilter',
  data() {
    return {
      options: [{
        value: 1,
        label: '进行中'
      }, {
        value: 2,
        label: '已完成'
      }, {
        value: 3,
        label: '已取消'
      }],
      value: ''
    };
  },
  methods: {
    changeComplianceStatus(status) {
      this.$emit('change', Number(status));
    }
  }
};
exports.default = _default;