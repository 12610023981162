"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _fullScreenBtn = _interopRequireDefault(require("@/components/FullScreen/fullScreenBtn"));
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CardChart',
  components: {
    ChartTemplate: _ChartTemplate.default,
    MgDraggable: _MgDraggable.default,
    fullScreenBtn: _fullScreenBtn.default,
    InitKanbanCardView: _InitKanbanCardView.default
  },
  mixins: [_ExportMixins.default],
  props: {
    chartList: {
      type: Array,
      default: () => []
    },
    getChartListLoading: {
      type: Boolean,
      default: false
    },
    moveCustomerBaseProfileChart: {
      type: Function,
      default: () => {}
    },
    hasHandlePremission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localChartList: []
    };
  },
  computed: {
    getTotal() {
      return val => {
        var _val$originData;
        return (_val$originData = val.originData) === null || _val$originData === void 0 ? void 0 : _val$originData.reduce((a, b) => a + b.value, 0);
      };
    }
  },
  watch: {
    chartList(val) {
      this.localChartList = val;
    }
  },
  methods: {
    async dragEnd(_ref) {
      let {
        newIndex
      } = _ref;
      await this.moveCustomerBaseProfileChart({
        chart_id: this.localChartList[newIndex].chart_id,
        des_order: newIndex + 1
      });
      this.$emit('dragEnd', this.localChartList);
    },
    addChart() {
      this.$emit('addChart');
    },
    handleCommand(type, data) {
      this.$emit('handleCommand', type, data);
    },
    exportData(val) {
      const {
        activeTabName,
        startDate,
        endDate
      } = this.getBiFilter();
      const {
        indicator,
        originData
      } = val;
      const hasValue = originData.find(item => item.value);
      if (!hasValue) {
        this.$message.warning('无数据');
        return;
      }
      originData.sort((a, b) => b.value - a.value);
      const fileName = `${activeTabName}_${indicator}_${startDate}至${endDate}.csv`;
      const head = [indicator, '客户人数', '占比'];
      const total = this.getTotal(val);
      const data = originData.map(item => [item.name, item.value, `${(item.value * 100 / total).toFixed(2)}%`]);
      data.push(['总计', total, '100%']);
      (0, _download.downloadBlob)(this.handleFileToBuf([head, ...data]), fileName);
    }
  }
};
exports.default = _default;