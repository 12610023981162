"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'index',
  setup(__props) {
    const getEmbedPath = path => '/embed/' + path.split('/').pop();
    (0, _vue.provide)('isEmbed', true);
    (0, _vue.provide)('getEmbedPath', getEmbedPath);
    return {
      __sfc: true,
      getEmbedPath
    };
  }
};
exports.default = _default;