"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ReportOverview = _interopRequireDefault(require("./ReportOverview"));
var _DetailFilter = _interopRequireDefault(require("./DetailFilter"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _DetailTable = _interopRequireDefault(require("./DetailTable"));
var _InterposeDialog = _interopRequireDefault(require("./InterposeDialog"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _workspace = require("@/api/workspace");
var _formatDataUnit = require("@/components/Charts/formatDataUnit.js");
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ExperimentDetail',
  components: {
    ReportOverview: _ReportOverview.default,
    DetailFilter: _DetailFilter.default,
    ChartTemplate: _ChartTemplate.default,
    DetailTable: _DetailTable.default,
    InterposeDialog: _InterposeDialog.default,
    shareConfig: _shareConfig.default
  },
  props: {},
  data() {
    return {
      shareConfigVisible: false,
      lineOptions: {},
      interposeDialogVisible: false,
      experimentData: {},
      detailLoading: false,
      chartLoading: false,
      lineData: [],
      // 折线图数据
      timexAxis: [],
      monitorGroups: [],
      // 所有实验组别
      monitorTargets: [],
      // 所有监测指标
      interpose: [],
      // 干预点数据
      monitorParams: {
        id: 0,
        monitor_config: {},
        group_name: '',
        user_ids: [],
        date_interval: 'day'
      },
      memberOptions: [],
      testStateOption: Object.freeze([{
        value: 1,
        label: '未开始'
      }, {
        value: 2,
        label: '进行中'
      }, {
        value: 3,
        label: '已完成'
      }]),
      testSourceOption: Object.freeze([{
        value: 1,
        label: 'A/B测试'
      }, {
        value: 2,
        label: '总览'
      }, {
        value: 3,
        label: '团队概览'
      }, {
        value: 4,
        label: '培训'
      }])
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    getUnitConfig() {
      var _this$monitorParams$m;
      const {
        analysis_target,
        analysis_target_key,
        dimension
      } = ((_this$monitorParams$m = this.monitorParams.monitor_config) === null || _this$monitorParams$m === void 0 ? void 0 : _this$monitorParams$m.configs) || {};
      return {
        analysis_target,
        analysis_target_key,
        draw_style: dimension || 'count'
      };
    },
    judgeTestState() {
      return function (val) {
        var _this$testStateOption;
        return (_this$testStateOption = this.testStateOption.filter(_ref => {
          let {
            value
          } = _ref;
          return value === val;
        })[0]) === null || _this$testStateOption === void 0 ? void 0 : _this$testStateOption.label;
      };
    },
    judgeTestSource() {
      return function (val) {
        var _this$testSourceOptio;
        return (_this$testSourceOptio = this.testSourceOption.filter(_ref2 => {
          let {
            value
          } = _ref2;
          return value === val;
        })[0]) === null || _this$testSourceOptio === void 0 ? void 0 : _this$testSourceOptio.label;
      };
    },
    interposeLabelWidth() {
      return function (val) {
        return 12 * val.length;
      };
    },
    // 是否来自培训任务
    isFromCoaching() {
      return this.$route.query.source === 'coaching';
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.init();
      }
    }
  },
  async created() {
    const urlParamsWorkspaceId = this.$route.query.workspaceId;
    let workspaceItem;
    if (urlParamsWorkspaceId) {
      try {
        const res = await (0, _workspace.switchWorkspace)({
          id: Number(urlParamsWorkspaceId)
        });
        workspaceItem = res.results;
        this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
      } catch (error) {
        const isExist = await (0, _workspace.judgeWorkspaceExist)(Number(urlParamsWorkspaceId));
        if (!isExist.results.exists) {
          workspaceItem = this.workspaceList[0];
          this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
        }
      }
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
      if (Number(this.workspaceInfo.id) === Number(urlParamsWorkspaceId)) {
        this.init();
      }
    } else {
      this.workspaceInfo.id && this.init();
    }
  },
  methods: {
    shareExperiment: _businessIntelligence.shareExperiment,
    cancelShareExperiment: _businessIntelligence.cancelShareExperiment,
    getShareExperimentList: _businessIntelligence.getShareExperimentList,
    plus8ToCurrentZone(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    async init() {
      this.monitorParams.id = Number(this.$route.query.id);
      await this.getExperimentConfig();
      await this.getInterpose();
      // 获取干预点信息
      this.getExperimentDetail();
    },
    handleShareExperiment() {
      this.shareConfigVisible = true;
    },
    async refresh() {
      await this.getInterpose();
      // 获取干预点信息
      this.getExperimentDetail();
    },
    // 成员筛选
    handleMemberChange(tree_ids, user_ids) {
      this.monitorParams.tree_ids = tree_ids;
      this.monitorParams.user_ids = user_ids;
      this.getExperimentDetail();
    },
    // 切换展示组别
    async handleGroupChange(key, value) {
      if (value === '整体情况') {
        // 整体情况 group_name字段传空，无成员筛选
        this.monitorParams.group_name = '';
        this.memberOptions = [];
        this.monitorParams.user_ids = [];
        this.getExperimentDetail();
      } else {
        this.monitorParams.group_name = value;
        // 获取成员筛选数据
        this.chartLoading = true;
        const params = {
          id: this.monitorParams.id,
          name: value,
          workspace_id: this.workspaceInfo.id
        };
        const res = await (0, _businessIntelligence.getGroupMembers)(params).finally(() => {
          this.chartLoading = false;
        });
        if (res.code === 20000) {
          this.memberOptions = res.results.data;
          // 清空人员筛选
          this.$refs['detail-filter'].filterMembers = [];
        }
      }
    },
    // 切换监测指标/成员筛选
    filterChange(key, value) {
      this.monitorParams[key] = value;
      this.getExperimentDetail();
    },
    // 折线图及表格数据
    async getExperimentDetail() {
      this.chartLoading = true;
      this.monitorParams.workspace_id = this.workspaceInfo.id;
      this.monitorParams.time_zone = this.timezone;
      this.monitorParams.monitor_config_name = this.monitorParams.monitor_config.name;
      const res = await (0, _businessIntelligence.getExperimentDetail)(this.monitorParams).finally(() => {
        this.chartLoading = false;
      });
      if (res.code === 20000) {
        var _list$;
        const {
          list
        } = res.results;
        this.lineData = list.map(_ref3 => {
          let {
            name,
            data
          } = _ref3;
          return {
            name,
            data: Object.values(data)
          };
        });
        this.timexAxis = Object.keys((_list$ = list[0]) === null || _list$ === void 0 ? void 0 : _list$.data);
        this.lineOptions = this.setLineOptions();
      }
    },
    // 获取A/B 测试 基本配置
    async getExperimentConfig() {
      this.detailLoading = true;
      const res = await (0, _businessIntelligence.getExperimentConfig)({
        id: this.monitorParams.id,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.detailLoading = false;
      });
      if (res.code === 20000) {
        res.results.monitor_configs.forEach(item => {
          if (!item.configs.conversation_type) {
            item.configs.conversation_type = [];
          }
        });
        this.experimentData = res.results;
        // 用于设置干预点禁选时间
        const {
          start_at,
          end_at
        } = res.results;
        this.experimentData.id = this.monitorParams.id;
        this.experimentData.start_at = start_at;
        this.experimentData.end_at = end_at;
        const {
          monitor_configs,
          range_configs: {
            source_info,
            contrast
          }
        } = res.results;
        // 监测指标
        this.monitorTargets = monitor_configs;
        this.monitorParams.monitor_config = monitor_configs[0];
        // 实验组别
        this.monitorGroups = ['整体情况', source_info.name, ...contrast.map(_ref4 => {
          let {
            name
          } = _ref4;
          return name;
        })];
      }
    },
    // 获取干预点
    async getInterpose() {
      const res = await (0, _businessIntelligence.getInterpose)({
        id: this.monitorParams.id,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.interpose = res.results.data;
      }
    },
    // 设置干预点
    setInterpose() {
      this.interposeDialogVisible = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    setLineOptions() {
      return {
        dataZoom: {},
        legend: {},
        tooltip: {
          trigger: 'axis',
          // 将tooltip框限制在图表区域内
          confine: true,
          appendToBody: true,
          formatter: params => {
            return `<h4 style="font-weight: bold;">${params[0].axisValue}</h4>` + params.reduce((pre, _ref5) => {
              let {
                marker,
                seriesName,
                value
              } = _ref5;
              return pre + formatUnit(`<span>${marker} ${seriesName}</span>`, value, this.getUnitConfig);
            }, '');
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          // 类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
          data: this.getxAxisByDateInterval()
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLabel: {
            formatter: val => {
              return formatUnit('', val, this.getUnitConfig).replace(/<[^>]+>/g, '');
            }
          },
          name: this.monitorParams.monitor_config.name,
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: '14',
            padding: 50
          }
        },
        series: [...this.getInterposeOffset().map(item => {
          return {
            type: 'line',
            smooth: true,
            markLine: {
              symbol: ['none', 'none'],
              label: {
                borderWidth: '1',
                borderColor: '#fff',
                position: 'end',
                distance: 0,
                offset: [item.width / 2 + 5 + item.offset, 1],
                formatter: item.name,
                width: item.width,
                backgroundColor: '#E54D52',
                color: '#fff',
                padding: 5,
                overflow: 'truncate'
              },
              lineStyle: {
                type: 'dash',
                color: '#E54D52'
              },
              data: [{
                xAxis: item.date
              }]
            }
          };
        }), ...this.lineData.map(_ref6 => {
          let {
            name,
            data
          } = _ref6;
          return {
            type: 'line',
            name,
            data
          };
        })]
      };
    },
    // 根据监测时间粒度，折线图展示不同的横轴
    getxAxisByDateInterval() {
      switch (this.monitorParams.date_interval) {
        // 周维度展示 年+月+日+“当周”
        case 'week':
          return this.timexAxis.map(item => this.$moment(item).format('YYYY-MM-DD') + '当周');
        // 月维度展示 年+月 '2022-12-24' => '2022-12'
        case 'month':
          return this.timexAxis.map(item => item.slice(0, 7));
        default:
          // 默认 day
          return this.timexAxis.map(item => this.$moment(item).format('YYYY-MM-DD'));
      }
    },
    // 同一个位置干预点的offset计算
    getInterposeOffset() {
      const interpose = this.getInterposeByDateInterval();
      interpose.forEach((item, index) => {
        // 同一个时间有多个干预点宽度固定，否则宽度弹性设置
        const multiInterpose = interpose.filter(c => c.date === item.date).length > 1;
        if (multiInterpose) {
          item.width = 50;
          item.offset = interpose.slice(index + 1).filter(c => c.date === item.date).length * 10;
        } else {
          item.width = 14 * item.name.length;
          item.offset = 0;
        }
      });
      return interpose;
    },
    // 根据监测时间粒度，干预点展示不同的横轴
    getInterposeByDateInterval() {
      switch (this.monitorParams.date_interval) {
        // 周维度展示年+月+日+“当周”,干预点所属的周
        case 'week':
          return this.interpose.map(_ref7 => {
            let {
              name,
              date
            } = _ref7;
            const week = this.timexAxis.filter(item => this.$moment(item).isSameOrBefore(date)).pop();
            return {
              name,
              date: week.slice(0, 10) + '当周'
            };
          });
        // 月维度展示年+月
        case 'month':
          return this.interpose.map(_ref8 => {
            let {
              name,
              date
            } = _ref8;
            return {
              name,
              date: date.slice(0, 7)
            };
          });
        default:
          // 默认 day
          return this.interpose;
      }
    }
  }
};
exports.default = _default;
function formatUnit(label, data, unitConfig) {
  return `${label}<span style="margin-left: 30px; font-weight: bold;">${(0, _formatDataUnit.formatDataUnit)(unitConfig, data)}</span></br>`;
}