"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tabs", {
    staticClass: "simple-tabs",
    attrs: {
      value: _vm.value
    },
    on: {
      input: function ($event) {
        return _setup.emit("input", $event);
      }
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.label,
        name: item.name
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;