"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/AnalysisConfig"));
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _previewChart = _interopRequireDefault(require("./previewChart"));
var _barIcon = _interopRequireDefault(require("@/assets/BI/bar-icon.svg"));
var _textChartIcon = _interopRequireDefault(require("@/assets/BI/text-chart-icon.svg"));
var _lineChartIcon = _interopRequireDefault(require("@/assets/BI/line-chart-icon.svg"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChartConfig',
  components: {
    AnalysisConfig: _AnalysisConfig.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default,
    previewChart: _previewChart.default
  },
  props: {
    chartConfigVisible: {
      type: Boolean,
      default: false
    },
    isEditCard: {
      type: Boolean,
      default: false
    },
    kanbanId: {
      type: Number,
      default: 0
    },
    editChartConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartConfig: {
        name: '新卡片',
        description: '',
        type: 'Digit',
        configs: {
          // 分析维度：非指标卡片有,默认值为时间day
          dimension: 'day',
          // 分析对象：会话指标，商机指标，SOP监测项
          analysis_target: 'conversation',
          // 分析对象一级选中类型：会话指标，SOP监测项里的商机总数
          analysis_target_key: '',
          conversation_type: [],
          conversation_scene_type: [],
          // 分析对象一级选中值：
          // 会话指标无，SOP监测项里的商机总数无
          // SOP监测项里的关键事件和业务转化卡点选择关键事件级联(后端的event_id字段仅在sop时才对应关键事件)，其他为自定义关键事件组件
          analysis_target_vals: '',
          // 绘图方式：会话指标无，SOP监测项里的业务卡点转化无
          draw_style: 'count',
          // 关键结果：仅业务卡点展示
          strategy: {
            // 关键结果类型：自定义字段，商机阶段
            // 非业务卡点是默认值为none
            results_type: '',
            // 关键结果key
            key: '',
            // 关键结果value
            vals: []
          }
        },
        filter_configs: {
          date: {
            // 日期筛选 需按type 与 value 转换为时间类型
            type: 'day',
            // 筛选类型
            value: 7,
            // 天/周/月
            is_current: false
          },
          event_filters: {
            keyEventCascaderList: [{
              keyEventName: [],
              inverse: false
            }],
            event_engine_relation: 'and'
          },
          custom_filters: {}
        }
      },
      previewChartData: {
        isPreview: false,
        isShowMask: false,
        loading: false
      },
      submitLoading: false,
      chartTypeList: Object.freeze([{
        imgSrc: _textChartIcon.default,
        label: '指标卡',
        type: 'Digit',
        tooltip: ['请选择一个指标', '生成图表后会展示环比数据', '环比对比的是与上一周期相同天数的数据比较']
      }, {
        imgSrc: _lineChartIcon.default,
        label: '折线图',
        type: 'Line',
        tooltip: ['建议用该图表对分析对象进行时间维度分析', '横坐标为分析维度，纵坐标为分析指标', '请选择一个指标进行分析']
      }, {
        imgSrc: _barIcon.default,
        label: '条形图',
        type: 'Bar',
        tooltip: ['建议用该图表对分析对象进行成员维度分析', '纵坐标为分析维度，横坐标为分析对象', '请选择指标生成图表进行分析']
      }]),
      isEditCardName: false,
      dimensionOptions: Object.freeze([{
        label: '时间',
        value: 'day' // 默认值
      }, {
        label: '成员',
        value: 'uid'
      }]),
      dateType: Object.freeze([{
        label: '天级',
        value: 'day'
      }, {
        label: '周级',
        value: 'week'
      }, {
        label: '月级',
        value: 'month'
      }])
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    getInitCardPreviewConfig() {
      return this.chartTypeList.find(_ref => {
        let {
          type
        } = _ref;
        return type === this.chartConfig.type;
      });
    },
    getDateTip() {
      const type = this.chartConfig.filter_configs.date.type;
      return type === 'day' ? '天' : type === 'week' ? '周' : '月';
    },
    getCurrentDateTip() {
      return this.getDateTip === '天' ? '今天' : `本${this.getDateTip}`;
    }
  },
  watch: {
    chartConfigVisible(val) {
      if (!val) return;
      if (!this.isEditCard) {
        this.chartConfig = this.$options.data().chartConfig;
        this.clearValidate();
      } else {
        this.$nextTick(() => {
          this.chartConfig = this.editChartConfig;
          if (!this.chartConfig.filter_configs.event_filters.keyEventCascaderList) {
            this.chartConfig.filter_configs.event_filters = {
              keyEventCascaderList: [{
                keyEventName: [],
                inverse: false
              }],
              event_engine_relation: 'and'
            };
          }
          if (['key_event', 'conv_event', 'business_trans'].includes(this.chartConfig.configs.analysis_target_key)) {
            this.chartConfig.configs.analysis_target_vals = this.chartConfig.configs.analysis_target_vals.map(_ref2 => {
              let {
                keyEventName
              } = _ref2;
              return keyEventName;
            });
          }
          if (['hour', 'day', 'week', 'month'].includes(this.editChartConfig.configs.dimension)) {
            this.chartConfig.configs.dimension = 'day';
          }
        });
      }
      // 延迟触发关闭预览遮罩层：防止遮罩层关闭被配置项改变的watch里的打开覆盖
      this.$nextTick(() => {
        this.previewChartData = this.$options.data().previewChartData;
      });
    },
    'chartConfig.filter_configs.event_filters': {
      handler: function (val) {
        this.resetPreview();
      },
      deep: true
    },
    'chartConfig.configs.conversation_type': {
      handler: function (val) {
        if (val.length) {
          this.updatePreview();
        }
      }
    },
    'chartConfig.configs.conversation_scene_type': {
      handler: function () {
        this.updatePreview();
      }
    }
  },
  methods: {
    // 打开预览
    resetPreview() {
      this.previewChartData.isShowMask = true;
      this.previewChartData.isPreview = false;
    },
    updatePreview() {
      var _this$$refs$chartConf;
      (_this$$refs$chartConf = this.$refs['chartConfigForm']) === null || _this$$refs$chartConf === void 0 ? void 0 : _this$$refs$chartConf.validate(async valid => {
        if (valid) {
          this.$set(this.previewChartData, 'loading', true);
          const params = (0, _commonFunc.deepClone)(this.chartConfig);
          delete params.cardConfig;
          this.formatKeyEvent(params);
          const res = await (0, _businessIntelligence.getBiDataOverviewPreviewCardData)({
            card_config: params,
            date_interval: params.filter_configs.date.type,
            kanban_id: this.kanbanId,
            workspace_id: this.workspaceInfo.id,
            time_zone: this.timezone
          }).finally(() => {
            this.previewChartData.isShowMask = false;
            this.$set(this.previewChartData, 'loading', false);
          });
          if (res.code === 20000) {
            this.previewChartData = {
              ...this.chartConfig,
              isPreview: true,
              data: params.type === 'Digit' ? res.results : this.$parent.setOptions(params, res.results) || {}
            };
          }
        }
      });
    },
    // 修改卡片类型
    changeChartType(type) {
      this.chartConfig.type = type;
      this.previewChartData = this.$options.data().previewChartData;
    },
    changeFilterDate(val) {
      this.chartConfig.filter_configs.date.value = Number(val.replace(/[^\d]/g, '')) || 1;
      this.resetPreview();
    },
    customFilterChange(params) {
      this.chartConfig.filter_configs.custom_filters = params;
      this.resetPreview();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs['chartConfigForm'].clearValidate();
      });
    },
    handleClose() {
      this.chartConfig = this.$options.data().chartConfig;
      this.$refs['chartConfigForm'].resetFields();
      this.$emit('update:chartConfigVisible', false);
    },
    editChartName(element) {
      this.isEditCardName = true;
      this.$nextTick(() => {
        this.$refs[element].focus();
      });
    },
    submit() {
      this.$refs['chartConfigForm'].validate(async valid => {
        if (valid) {
          // 校验通过后对于选择类型为select的商机自定义字段，要将vals值改为数组类型
          const requestFn = this.isEditCard ? _businessIntelligence.editBiDataOverviewCard : _businessIntelligence.createBiDataOverviewCard;
          const params = (0, _commonFunc.deepClone)(this.chartConfig);
          delete params.cardConfig;
          this.formatKeyEvent(params);
          // 创建需要看板id
          if (!this.isEditCard) {
            params.kanban_id = this.kanbanId;
          }
          params.workspace_id = this.workspaceInfo.id;
          params.time_zone = this.timezone;
          params.filter_configs.date.value = Number(params.filter_configs.date.value);
          this.submitLoading = true;
          (0, _timezone.convertParams2UTCplus8)(params.configs);
          (0, _timezone.convertParams2UTCplus8)(params.filter_configs.custom_filters);
          const res = await requestFn(params).finally(() => {
            this.submitLoading = false;
          });
          res.code === 20000 && this.$message.success(this.isEditCard ? '编辑成功' : '新建成功');
          if (!this.isEditCard) {
            params.card_id = res.results.card_id;
          }
          this.$emit('handleChartSuccess', params);
          this.handleClose();
        }
      });
    },
    formatKeyEvent(params) {
      if (['key_event', 'conv_event', 'business_trans'].includes(params.configs.analysis_target_key)) {
        params.configs.analysis_target_vals = params.configs.analysis_target_vals.map(item => {
          return {
            keyEventName: item,
            inverse: false
          };
        });
      }
    }
  }
};
exports.default = _default;