"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_c("sidebar", {
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "main-container detail-container"
  }, [_c("app-navbar"), _c("app-main")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;