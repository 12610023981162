"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EditKnowledgeNameDialog = _interopRequireDefault(require("./EditKnowledgeNameDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgePointCard',
  components: {
    EditKnowledgeNameDialog: _EditKnowledgeNameDialog.default
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    activeKnowledgeIndex: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editKnowledgeNameDialogVisible: false
    };
  },
  methods: {
    closeEditKnowledgeNameDialog() {
      this.editKnowledgeNameDialogVisible = false;
    },
    openEditKnowledgeNameDialog() {
      this.editKnowledgeNameDialogVisible = true;
    },
    changeKnowledgeType(value) {
      this.$emit('changeKnowledgeType', value);
    }
  }
};
exports.default = _default;