"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mg-anchor"
  }, [_c("div", {
    staticClass: "mg-anchor-left"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.isMenuHidden ? "展开目录" : "收起目录",
      placement: "top"
    }
  }, [_c("i", {
    class: ["iconfont", _vm.isMenuHidden ? "icon-d-arrow-right" : "icon-d-arrow-left"],
    on: {
      click: _vm.toggleMenu
    }
  })]), _c("ul", {
    class: ["mg-anchor-nav", {
      "menu-hidden": _vm.isMenuHidden
    }]
  }, [_vm.showCheckAll ? _c("li", {
    staticClass: "check-all"
  }, [_c("el-checkbox", {
    attrs: {
      value: _vm.isCheckAll
    },
    on: {
      change: function ($event) {
        return _vm.$emit("checkAllChange", $event);
      }
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("全选")])], 1) : _vm._e(), _vm._l(_vm.anchorList, function (item) {
    return _c("li", {
      key: item.id,
      class: {
        active: item.id == _vm.localValue
      }
    }, [_vm.showCheckbox ? _c("el-checkbox", {
      on: {
        change: function ($event) {
          return _vm.$emit("knowledgeCheckedChange", $event, item.id);
        }
      },
      model: {
        value: item.checked,
        callback: function ($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }) : _vm._e(), _c("span", {
      staticClass: "text",
      on: {
        click: function ($event) {
          return _vm.handleScroll(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name))])], 1);
  })], 2)], 1), _c("div", {
    ref: "mgAnchorContent",
    staticClass: "mg-anchor-content"
  }, [_vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;