"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-btn",
    attrs: {
      tabindex: "-1"
    }
  }, [_c("div", {
    staticClass: "wrapper"
  }, [_c("i", {
    staticClass: "iconfont icon-magic-1 colorful"
  }), _c("span", {
    staticClass: "content"
  }, [_vm._v("AI助手")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;