"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _default = {
  name: 'EditKnowledgeBaseConfig',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitLoading: false,
      knowledgeBaseConfig: {
        name: '',
        profile: '',
        public_scope: 0 // 0 部分可见 1 公司可见
      }
    };
  },

  methods: {
    handleClose() {
      this.knowledgeBaseConfig = this.$options.data().knowledgeBaseConfig;
      this.$refs['configForm'].resetFields();
      this.$emit('update:visible', false);
    },
    submit() {
      this.$refs['configForm'].validate(async valid => {
        if (valid) {
          const requestFn = this.isCreate ? _knowledgeLibrary.createKnowledgeBase : _knowledgeLibrary.editKnowledgeBase;
          try {
            this.submitLoading = true;
            const {
              name,
              profile,
              public_scope,
              id
            } = this.knowledgeBaseConfig;
            const params = {
              name,
              profile,
              public_scope
            };
            if (!this.isCreate) {
              params.id = id;
            }
            const res = await requestFn(params);
            res.code === 20000 && this.$message.success(this.isCreate ? '新建成功' : '保存成功');
            this.$emit('handler', params);
            this.handleClose();
          } finally {
            this.submitLoading = false;
          }
        }
      });
    }
  }
};
exports.default = _default;