"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "key-event-detail-list"
  }, _vm._l(_vm.list, function (tracker, index) {
    return _c("div", {
      key: tracker.id,
      staticClass: "unit-container",
      class: {
        active: index === _vm.activeIndex && !_vm.noEventClick && !_vm.forbidSelectDefaultEvent
      }
    }, [_c("div", {
      staticClass: "tracker-unit",
      class: {
        "event-none": _vm.noEventClick
      },
      on: {
        click: function ($event) {
          return _vm.setCurTracker(index);
        }
      }
    }, [_vm._l(tracker.data, function (item, idx) {
      var _item$recommend_answe;
      return _c("div", {
        key: idx,
        staticClass: "sentence-item",
        on: {
          click: function ($event) {
            return _vm.handleKeyToDetail(index);
          }
        }
      }, [_c("div", {
        staticClass: "name-row"
      }, [_c("span", {
        staticClass: "name"
      }, [_vm._v(_vm._s(_vm.nameMap[item.entity_id + item.entity_type] || item.name || "未知姓名") + "：")])]), _c("p", {
        staticClass: "sentence-content"
      }, [_c("span", {
        class: ["time-box", {
          "is-customer": item.entity_type === "customer_contact"
        }]
      }, [_c("span", {
        staticClass: "time-point"
      }), _vm._v(" " + _vm._s(_vm.mediaType === "doc" ? _vm.$plus8ToCurrentZone(item.begin_time * 1000).format("YYYY-MM-DD HH:mm") : _vm.secondToTime(item.begin_time)) + " ")]), _vm._l(_vm.handleContent(item, tracker.style_type), function (obj, contentIndex) {
        return [!obj.isKeyword ? _c("span", {
          key: contentIndex,
          class: ["sentence-word", {
            "delete-word": tracker.is_delete
          }],
          attrs: {
            "data-sign": item.order
          }
        }, [_vm._v(_vm._s(obj.word))]) : _c("span", {
          key: contentIndex,
          staticClass: "highlight highlightItem",
          class: {
            "delete-word": tracker.is_delete
          }
        }, [_c("span", {
          domProps: {
            innerHTML: _vm._s(obj.word)
          }
        })])];
      })], 2), ((_item$recommend_answe = item.recommend_answer) === null || _item$recommend_answe === void 0 ? void 0 : _item$recommend_answe.knowledge_id) > 0 ? _c("RecommendedAnswer", {
        attrs: {
          list: item.recommend_answer.content
        },
        on: {
          openKnowLedgeDetail: function ($event) {
            return _vm.openKnowLedgeDetail(item.recommend_answer.knowledge_id);
          }
        }
      }) : _vm._e()], 1);
    }), tracker.edit_info || tracker.annotateType ? _c("div", {
      class: ["footer", {
        "flex-end": !tracker.edit_info
      }]
    }, [tracker.edit_info ? _c("div", {
      staticClass: "customer-add-info"
    }, [_c("span", [_vm._v(_vm._s(tracker.edit_info.editor))]), _c("span", [_vm._v(_vm._s(tracker.is_delete ? "删除于" : "添加于"))]), _c("span", [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(tracker.edit_info.edit_time).format("YYYY-MM-DD HH:mm")))])]) : _vm._e(), _c("div", {
      class: ["annotation-type", {
        "is-artificial": tracker.annotateType === "artificial"
      }, {
        "is-rule": tracker.annotateType === "rule"
      }]
    }, [_vm._v(" " + _vm._s(_vm.annotateTypeObj[tracker.annotateType]) + " ")])]) : _vm._e()], 2), _vm.canEditKeyEvent && _vm.correctKeyEventPermission && !tracker.is_delete ? _c("el-popover", {
      ref: "delete-popover" + index,
      refInFor: true,
      attrs: {
        width: "210",
        trigger: "click",
        "popper-class": "key-event-detail-list-popper"
      }
    }, [_c("div", {
      staticClass: "delete-popover-content"
    }, [_c("div", {
      staticClass: "warning"
    }, [_c("p", {
      staticClass: "warning-icon"
    }, [_c("i", {
      staticClass: "iconfont icon-warning"
    })]), _c("p", {
      staticClass: "warning-content"
    }, [_vm._v("确定删除此关键事件命中文本吗？")])]), _c("div", {
      staticClass: "operate-btn"
    }, [_c("el-button", {
      attrs: {
        plain: ""
      },
      on: {
        click: function ($event) {
          return _vm.closePopover(index);
        }
      }
    }, [_vm._v("取消")]), _c("el-button", {
      attrs: {
        type: "danger",
        loading: _vm.deleteOperateLoading
      },
      on: {
        click: function ($event) {
          return _vm.handleDeleteKeyEventDetailTracker(tracker, index);
        }
      }
    }, [_vm._v("删除")])], 1)]), _c("el-tooltip", {
      staticClass: "delete-btn",
      attrs: {
        slot: "reference",
        effect: "dark",
        content: "删除",
        placement: "top"
      },
      slot: "reference"
    }, [_c("i", {
      staticClass: "iconfont icon-delete"
    })])], 1) : _vm._e()], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;