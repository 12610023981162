"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GuideDialog = _interopRequireDefault(require("@/components/GuideDialog"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _KnowledgeQuestionsContent = _interopRequireDefault(require("./components/KnowledgeQuestionsContent.vue"));
var _QuestionTricksLibrary = _interopRequireDefault(require("./components/QuestionTricksLibrary.vue"));
var _IgnoreQuestionDrawer = _interopRequireDefault(require("./components/IgnoreQuestionDrawer"));
var _colorfulRadar = _interopRequireDefault(require("@/assets/colorfulRadar.svg"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgePointMiningEngine',
  components: {
    KnowledgeQuestionsContent: _KnowledgeQuestionsContent.default,
    QuestionTricksLibrary: _QuestionTricksLibrary.default,
    MenuToggleBtn: _MenuToggleBtn.default,
    SpaceButton: _SpaceButton.default,
    IgnoreQuestionDrawer: _IgnoreQuestionDrawer.default,
    GuideDialog: _GuideDialog.default
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colorfulRadar: _colorfulRadar.default,
      currentQuestion: {},
      drawerVisible: false,
      guideDialogVisible: false
    };
  },
  computed: {
    isShowQuestionTricksLibrary() {
      return Object.keys(this.currentQuestion).length;
    }
  },
  watch: {
    drawerVisible(val) {
      !val && this.refreshQuestionList();
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        path: _routeMap.ROUTES.knowledgeLibraryDetail,
        query: this.$route.query
      });
    },
    ignoreQuestion() {
      this.guideDialogVisible = true;
    },
    handleKnown() {
      localStorage.setItem('knowledgePointMiningEngineKnown', true);
      this.guideDialogVisible = false;
    },
    handleQuestionClick(val) {
      this.currentQuestion = {
        ...val
      };
    },
    refreshQuestionList() {
      this.$refs.questionContent.getQuestionList();
    },
    closeLibrary() {
      this.currentQuestion = {};
      this.$refs.questionContent.clearCurrentQuestion();
    }
  }
};
exports.default = _default;