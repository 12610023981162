"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conversation-dimension"
  }, [_vm.biComplete && !_vm.activeLabel ? _c("div", [_vm._l(_vm.info, function (item, index) {
    return _c("div", {
      key: index,
      class: ["card", {
        clickable: item.clickable
      }],
      on: {
        click: function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c("div", {
      staticClass: "card-item"
    }, [_c("div", {
      staticClass: "font-box"
    }, [_c("p", {
      staticClass: "row analy-item-title"
    }, [_vm._v(_vm._s(item.label))]), _c("p", {
      staticClass: "row anally-item-value",
      domProps: {
        innerHTML: _vm._s(item.value)
      }
    })]), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(_vm._s(item.introduce))]), _c("div", {
      staticClass: "introduce"
    }, [_c("i", {
      staticClass: "el-icon-info"
    })])])], 1), item.clickable ? _c("i", {
      staticClass: "iconfont icon-arrow-right"
    }) : _vm._e(), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(" " + _vm._s(item.isImprove == "vigilant" ? "推荐范围外" : "推荐范围内") + " ")]), false ? _c("div", {
      staticClass: "point-nice",
      class: {
        "point-improve": item.isImprove == "vigilant"
      }
    }) : _vm._e()])], 1);
  }), _c("div", {
    staticClass: "go-bi-box"
  }, [_vm.goBiPersonPermission ? _c("el-link", {
    staticClass: "go-bi-link",
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        return _vm.$emit("goBiperson");
      }
    }
  }, [_vm._v(" 查看个人分析数据 ")]) : _vm._e()], 1)], 2) : _vm._e(), _vm.activeLabel && _vm.activeDimensionDetailData ? _c("div", {
    staticClass: "key-event-detail"
  }, [_c("div", {
    staticClass: "key-event-detail-header"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: function ($event) {
        _vm.activeLabel = "";
      }
    }
  }), _c("span", [_vm._v(_vm._s(_vm.activeLabel) + " (" + _vm._s(_vm.count) + ")")])]), _c("key-event-detail-list", {
    ref: "key-event-detail-list",
    attrs: {
      "media-type": _vm.mediaType,
      participants: _vm.participantsInfo,
      list: _vm.activeDimensionDetailData
    },
    on: {
      openKnowLedgeDetail: _vm.openKnowLedgeDetail
    }
  }), !_vm.activeDimensionDetailData ? _c("div", {
    staticClass: "empty-status"
  }, [_c("NoData", {
    attrs: {
      tips: "暂无数据"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), !_vm.biComplete ? _c("div", {
    staticClass: "is-loading"
  }, [_c("mgv-progress-loading", {
    attrs: {
      text: "正在努力生成中...",
      "stroke-width": 18
    }
  })], 1) : _vm._e(), _c("KnowledgeDetailDrawer", {
    attrs: {
      visible: _vm.knowledgeDetailDrawerVisible,
      "knowledge-id": _vm.activeKnowledgeId
    },
    on: {
      "update:visible": function ($event) {
        _vm.knowledgeDetailDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;