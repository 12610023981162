"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.info.type === "account" || _vm.info.type === "clue" ? _c("section", {
    staticClass: "detail-info"
  }, [_c("div", [_vm.info.avatarType ? _c("image-avatar", {
    attrs: {
      type: _vm.info.avatarType,
      name: _vm.info.name,
      size: "large"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "detail-info-content"
  }, [_c("h1", [_vm._v(_vm._s(_vm.info.name))]), _c("span", {
    staticClass: "detail-info-follow-desc"
  }, [_vm._v(_vm._s(_vm.info.subLabel))]), _c("span", {
    staticClass: "detail-info-follow-name"
  }, [_vm._v(_vm._s(_vm.info.subValue || "--"))])])]) : _vm.info.type === "deal" ? _c("section", {
    staticClass: "detail-info"
  }, [_vm._m(0), _c("div", {
    staticClass: "detail-info-content"
  }, [_c("h1", [_vm._v(_vm._s(_vm.info.name))]), _c("span", {
    staticClass: "detail-info-follow-desc"
  }, [_vm._v(_vm._s(_vm.info.subLabel))]), _c("span", {
    staticClass: "detail-info-follow-name"
  }, [_vm._v(_vm._s(_vm.info.subValue || "--"))])])]) : _vm.info.type === "customer_contact" ? _c("section", {
    staticClass: "detail-info"
  }, [_c("div", [_c("image-avatar", {
    attrs: {
      type: _vm.info.type,
      name: _vm.info.name,
      size: "large",
      shape: "square",
      avatar: _vm.info.avatar
    }
  })], 1), _c("div", {
    staticClass: "detail-info-content"
  }, [_c("div", {
    staticClass: "contact-row"
  }, [_c("h1", [_vm._v(_vm._s(_vm.info.name))]), _vm.info.origin === "wework" ? _c("img", {
    attrs: {
      src: _vm.weChatImg
    }
  }) : _vm._e(), _c("span", {
    staticClass: "company"
  }, [_vm._v("@" + _vm._s(_vm.info.company))])])])]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "detail-info-icon"
  }, [_c("i", {
    staticClass: "iconfont icon-deal4"
  })])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;