"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TemplateDialog',
  components: {
    NoteEditor: _NoteEditor.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templatesList: [],
      // 笔记模板列表
      currentId: '',
      // 当前笔记模板的id,title,data
      currentTitle: '',
      currentData: {},
      templateName: '',
      // 笔记模板名称
      addTemplateName: '',
      switchType: '',
      noteTemplateData: {},
      // 展示在note的上笔记模板数据
      inputShow: false,
      isChange: false,
      editorReady: false,
      // 避免快速点击，编辑器正在加载中数据错乱
      disabled: false
    };
  },
  async created() {
    await this.getNoteTemplateList();
  },
  methods: {
    // 获取笔记模板列表
    async getNoteTemplateList() {
      const res = await (0, _videoDetail.getNoteTemplateList)();
      if (res.code === 200) {
        this.templatesList = res.results.templates;
        // 初次打开默认指向第一个模板
        const firstTemplate = this.templatesList[0];
        // this.noteTemplateData传入noteEditor用来init
        this.noteTemplateData = firstTemplate.content;
        this.currentId = firstTemplate.id;
        this.currentTitle = firstTemplate.name;
      }
    },
    // 编辑笔记模板名称
    editTemplateName(data) {
      this.$set(data, 'type', 'edit');
      setTimeout(() => {
        this.$refs.edit[0].focus();
      }, 0);
      // 编辑模板名称
      this.templateName = data.name;
    },
    // 提交笔记模板名称修改（失焦或enter后触发）
    async submitNameEdit(data) {
      // 修改笔记名称
      // 未修改或者为空则不作任何操作
      if (data.name === this.templateName.replace(/[\(\)@]/g, '') || this.templateName === '') {
        // 未修改或者为空则不作任何操作
        this.$set(data, 'type', '');
      } else {
        // 修改笔记名称
        // 笔记模板名称不能重复
        const res = await (0, _videoDetail.modifyNoteTemplateName)(data.id, this.templateName);
        if (res.code === 200) {
          this.$set(data, 'type', '');
          data.name = this.templateName;
          if (data.id === this.currentId) {
            // 修改当前模板名称，模板标题同时改变
            this.currentTitle = this.templateName;
          }
        }
      }
    },
    // 点击添加笔记模板
    addTemplate() {
      this.inputShow = true;
      setTimeout(() => {
        this.$refs['add-template'].focus();
      }, 0);
    },
    // 添加新笔记（失焦或enter后触发）
    async addNewTemplate() {
      // 添加新笔记
      if (this.addTemplateName === '') {
        // 未设置名字则删除这个节点
        this.inputShow = false;
      } else {
        const res = await (0, _videoDetail.addNoteTemplate)(this.addTemplateName);
        if (res.code === 200) {
          // 重新获取笔记列表
          await this.getNoteTemplateList();
          this.addTemplateName = '';
          this.inputShow = false;
          this.switchTemplateNote(this.templatesList[this.templatesList.length - 1]);
        }
      }
    },
    // 笔记有改动时，记录状态
    templateIsChange(e) {
      if (e) {
        this.isChange = true;
      }
    },
    handleEditorReady() {
      this.editorReady = true;
      this.disabled = false;
    },
    // 点击模板名称,切换笔记模板
    async switchTemplateNote(data) {
      this.currentData = data;
      // 模板有改动
      if (this.isChange) {
        this.switchType = 'switch';
        this.open();
      } else {
        this.disabled = true;
        if (this.editorReady) {
          this.editorReady = false;
          this.currentId = data.id;
          this.currentTitle = data.name;
          const res = await (0, _videoDetail.getNoteTemplateById)(this.currentId);
          if (res.code === 200) {
            this.noteTemplateData = res.results.content;
            this.$nextTick(() => {
              try {
                this.$refs['edit-inner'].destroy();
                this.$refs['edit-inner'].originData = this.noteTemplateData;
                this.$refs['edit-inner'].init();
              } catch (error) {
                throw new Error(error);
              }
            });
          }
        }
      }
    },
    // 点击使用此模板按钮
    async useThisTemplate() {
      // 如果修改了笔记模板,出提示弹框
      if (this.isChange) {
        this.switchType = 'useTemplate';
        this.open();
      } else {
        // 模板内容出现在笔记上
        this.$emit('showNote', this.noteTemplateData);
        this.$emit('closeDialog');
        this.switchTemplateNote(this.templatesList[0]);
      }
    },
    // 点击取消按钮
    handleClose() {
      if (this.isChange) {
        this.switchType = 'cancel';
        this.open();
      } else {
        this.$emit('closeDialog');
        this.switchTemplateNote(this.templatesList[0]);
      }
    },
    open() {
      this.$confirm('你修改了模板内容，是否覆盖原模板?', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonClass: 'el-button--danger',
        confirmButtonText: '放弃',
        cancelButtonText: '覆盖'
      }).then(() => {
        this.handleAbandon();
      }).catch(action => {
        if (action === 'cancel') {
          this.coverNoteTemplate();
        }
      });
    },
    // 笔记模板有更改的操作
    // 点击放弃修改按钮，笔记模板恢复初始值
    async handleAbandon() {
      this.isChange = false;
      switch (this.switchType) {
        case 'useTemplate':
          // this.getTemplateNote(this.editData);
          this.useThisTemplate();
          this.switchTemplateNote(this.templatesList[0]);
          break;
        case 'cancel':
          // this.getTemplateNote(this.editData);
          this.$emit('closeDialog');
          this.switchTemplateNote(this.templatesList[0]);
          break;
        case 'switch':
          this.switchTemplateNote(this.currentData);
          break;
      }
    },
    // 点击覆盖按钮,保存笔记更改
    coverNoteTemplate() {
      setTimeout(() => {
        this.$refs['edit-inner'].save();
      }, 0);
    },
    // 获取笔记变动后的模板内容
    async saveTemplateChange(data) {
      const res = await (0, _videoDetail.modifyNoteTemplate)(this.currentId, data);
      if (res.code === 200) {
        this.noteTemplateData = data;
        this.handleAbandon();
      }
    }
  }
};
exports.default = _default;