"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "prompt-example"
  }, [_c("el-collapse", {
    staticClass: "prompt-collapse",
    model: {
      value: _setup.actives,
      callback: function ($$v) {
        _setup.actives = $$v;
      },
      expression: "actives"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "example"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "example-title-container"
        }, [_c("span", [_vm._v("示例")]), _c("span", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("el-link", {
          directives: [{
            name: "help-center",
            rawName: "v-help-center"
          }],
          staticClass: "small",
          attrs: {
            type: "primary",
            underline: false,
            href: "https://www.megaview.com/help-center/doc?docx=XgSIde74noecqJxNbrjcc8Usnbb&edition_id=hOw95E&anchor=doxcndPeGKUc0XI9GUKMq9MQYpe",
            target: "_blank"
          }
        }, [_vm._v("更多示例")])], 1)])];
      },
      proxy: true
    }])
  }, [_c("pre", {
    staticClass: "example-text",
    domProps: {
      textContent: _vm._s(_setup.exampleFormat)
    }
  }), _c("MgvButton", {
    staticClass: "replace-button",
    attrs: {
      type: "plain",
      color: "info",
      size: "small"
    },
    on: {
      click: _setup.onClick
    }
  }, [_vm._v("替换当前提示词")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;