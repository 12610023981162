"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DealFilterInput',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number]
    }
  },
  data() {
    return {
      localValue: ''
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.localValue = val;
      },
      immediate: true
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('change', {
        [this.item.field_name]: val
      });
    }
  }
};
exports.default = _default;