"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    attrs: {
      placeholder: _vm.placeholder || _setup.t("coaching.taskScoreRange"),
      clearable: "",
      "popper-append-to-body": false
    },
    on: {
      change: _setup.selectChange
    },
    model: {
      value: _setup.currentSelect,
      callback: function ($$v) {
        _setup.currentSelect = $$v;
      },
      expression: "currentSelect"
    }
  }, [_vm._l(_setup.options, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), _c("hr"), _c("el-option", {
    staticClass: "score-container",
    attrs: {
      label: _setup.scoreRangeStr,
      value: _setup.scoreRange,
      disabled: ""
    }
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.custom")))]), _c("div", {
    staticClass: "score-input-container",
    on: {
      click: function ($event) {
        _setup.currentSelect = _setup.scoreRangeStr;
      }
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "3"
    },
    on: {
      change: val => _setup.scoreRangeChange(val, 0)
    },
    model: {
      value: _setup.scoreRange[0],
      callback: function ($$v) {
        _vm.$set(_setup.scoreRange, 0, $$v);
      },
      expression: "scoreRange[0]"
    }
  }, [_c("i", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])]), _vm._v(" - "), _c("el-input", {
    attrs: {
      maxlength: "4"
    },
    on: {
      change: val => _setup.scoreRangeChange(val, 1)
    },
    model: {
      value: _setup.scoreRange[1],
      callback: function ($$v) {
        _vm.$set(_setup.scoreRange, 1, $$v);
      },
      expression: "scoreRange[1]"
    }
  }, [_c("i", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])])], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;