"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _TextSearchInput = _interopRequireDefault(require("./TextSearchInput"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TextSearch',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    TextSearchInput: _TextSearchInput.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    showCloseIcon() {
      const length = this.filters.textFilterList.length;
      const {
        pattern,
        role,
        inverse
      } = this.filters.textFilterList[0];
      return length !== 1 || !!pattern || role !== 'default' || !!inverse;
    }
  },
  methods: {
    // 清空会话文本
    clearText() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.textFilterList = [{
        pattern: '',
        role: 'default',
        inverse: false
      }];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.filter_relation = 'and';
    }
  }
};
exports.default = _default;