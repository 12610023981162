"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TricksScene = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksScene.vue"));
var _AudioRecorder = _interopRequireDefault(require("./AudioRecorder.vue"));
var _PlayVoice = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoice.vue"));
var _PlayVoiceMixin = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoiceMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TricksPractice',
  components: {
    TricksScene: _TricksScene.default,
    AudioRecorder: _AudioRecorder.default,
    PlayVoice: _PlayVoice.default
  },
  mixins: [_PlayVoiceMixin.default],
  inject: {
    getPracticeDisabled: {
      from: 'getPracticeDisabled',
      default: () => () => {}
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    playVoiceIcon: {
      type: Boolean,
      default: true
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    showIntelligentReview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localList: [],
      currentReplyId: ''
    };
  },
  watch: {
    list: {
      handler: function (val) {
        if (val) {
          this.localList = this.formatLocalList(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    formatLocalList(list) {
      let order = 1;
      const resList = JSON.parse(JSON.stringify(list));
      resList.forEach(trick => {
        var _trick$reply_list;
        (_trick$reply_list = trick.reply_list) === null || _trick$reply_list === void 0 ? void 0 : _trick$reply_list.forEach(i => {
          i.order = order++;
        });
      });
      return resList;
    },
    handleRecording(val) {
      this.pause();
      this.currentReplyId = val;
    },
    handleFinished() {
      this.currentReplyId = '';
    }
  }
};
exports.default = _default;