"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'PermissionsTree',
  props: {
    permissions: {
      type: Array,
      default: () => []
    },
    checkedList: {
      type: Array,
      default: () => []
    },
    roleDrawerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    permissions: {
      handler: function (val) {
        this.setTreeStyle();
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    setTreeStyle() {
      this.$nextTick(() => {
        const leafNode = document.getElementsByClassName('is-permissions-tree-leaf');
        const rootNode = document.getElementsByClassName('is-permissions-tree-root');
        for (let i = 0; i < leafNode.length; i++) {
          leafNode.item(i).parentNode.parentNode.style.cssFloat = 'left';
          leafNode.item(i).parentNode.parentNode.style.marginRight = '20px';
          leafNode.item(i).parentNode.parentNode.style.minWidth = '167px';
        }
        for (let i = 0; i < rootNode.length; i++) {
          rootNode.item(i).style.marginLeft = '0';
          rootNode.item(i).previousSibling.previousSibling.style.display = 'none';
        }
      });
    },
    handleCheckChange(data, node) {
      this.$emit('handleCheckChange', data, node);
    },
    setLeafNodeClass() {}
  }
};
exports.default = _default;