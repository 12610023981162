"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FolderTree = _interopRequireDefault(require("@/components/FolderTree"));
var _SnippetsList = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/SnippetsList.vue"));
var _EmptyFolder = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/EmptyFolder.vue"));
var _FolderHeader = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/FolderHeader.vue"));
var _OptionTemplate = _interopRequireDefault(require("./OptionTemplate.vue"));
var _library = require("@/api/library");
var _videoDetail = require("@/api/videoDetail");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SelectSnippetsDrawer',
  components: {
    FolderTree: _FolderTree.default,
    FolderHeader: _FolderHeader.default,
    SnippetsList: _SnippetsList.default,
    EmptyFolder: _EmptyFolder.default,
    OptionTemplate: _OptionTemplate.default
  },
  props: {
    selectedSnippets: {
      type: Array,
      default: () => []
    },
    snippetsDrawerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectSnippetsArray: [],
      options: [],
      usedOptions: [],
      currentNode: {},
      // 当前正在操作的文件夹的data
      curFolder: {},
      // 仅用于folder-header的信息展示
      snippetsList: [],
      videoTotal: 0,
      snippetsLoading: false,
      breadcrumbs: [],
      query: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['folderTree', 'workspaceInfo']),
    showUsed() {
      return !this.query;
    }
  },
  watch: {
    currentNode: {
      handler: function (val) {
        // eslint-disable-next-line no-prototype-builtins
        if (val.hasOwnProperty('id')) {
          this.openFolder(val.id);
        }
      },
      deep: true
    },
    snippetsDrawerVisible(val) {
      if (val) {
        this.getUsedSnippets();
      }
    },
    selectedSnippets(val) {
      if (!val.length) {
        this.initData();
        if (Object.keys(this.currentNode).length) {
          this.getSnippetsList(this.currentNode.id);
        }
      }
      this.selectToChangeListChecked();
    }
  },
  methods: {
    initData() {
      this.selectSnippetsArray = [];
      this.options = [];
      this.curFolder = {};
      this.snippetsList = [];
      this.videoTotal = 0;
      this.breadcrumbs = [];
    },
    handleNodeClick(data) {
      if (!this.currentNode || this.currentNode.id !== data.id) {
        this.currentNode = {
          ...data
        };
      }
    },
    treeFetchSucceed() {
      this.currentNode = this.getPureSnippetsFolders(this.folderTree)[0];
    },
    async getSnippetsList(folder_id) {
      const params = {
        size: 9999,
        page: 1,
        folder_id,
        space_id: this.workspaceInfo.id
      };
      const res = await (0, _library.getVideos)(params);
      if (res.code === 20000) {
        const {
          data,
          total_count
        } = res.results;
        this.snippetsList = data.map(item => ({
          ...item,
          checked: false,
          fid: this.currentNode.id
        }));
        this.videoTotal = total_count;
      }
    },
    async openFolder(folder_id) {
      const params = {
        folder_id
      };
      this.snippetsLoading = true;
      const res = await (0, _library.openFolder)(params);
      try {
        if (res.code === 20000) {
          this.curFolder = res.results;
          this.getSnippetsList(folder_id).finally(() => {
            this.snippetsLoading = false;
            if (this.selectSnippetId) {
              this.selectToChangeListChecked(this.selectSnippetId);
            }
          });
        }
      } catch {
        this.snippetsLoading = false;
      }
    },
    async getUsedSnippets() {
      const res = await (0, _videoDetail.getUsedSnippets)({
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        const options = res.results.snippet_list;
        this.usedOptions = [{
          label: '最近选择',
          options
        }];
        if (!this.showUsed || !options.length) return;
        setTimeout(() => {
          this.expandSelect();
        }, 500);
      }
    },
    handleClose() {
      this.$emit('update:snippetsDrawerVisible', false);
    },
    addSnippets() {
      this.$emit('setSelectedSnippets', this.selectSnippetsArray);
      this.handleClose();
    },
    remoteMethod: (0, _commonFunc.debounce)(function (query) {
      this.query = query;
      (0, _videoDetail.getSearchSnippet)({
        name: query,
        space_id: this.workspaceInfo.id
      }).then(res => {
        this.options = res.results.snippet_list;
      });
    }),
    snippetCheckedChange(data) {
      const index = this.selectSnippetsArray.findIndex(item => {
        return item.id === data.id;
      });
      if (index !== -1) {
        if (data.checked) {
          this.selectSnippetsArray.push(data);
        } else {
          this.selectSnippetsArray.splice(index, 1);
        }
      } else {
        if (data.checked) {
          this.pushSnippet(data, true);
        }
      }
    },
    pushSnippet(data) {
      let isPushOption = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const checkExceeding = this.exceedingTip();
      if (!checkExceeding) {
        if (isPushOption) {
          if (this.showUsed) {
            if (!this.usedOptions[0].options.find(item => item.id === data.id)) {
              this.usedOptions[0].options.push(data);
            }
          } else {
            const index = this.options.findIndex(item => {
              return item.id === data.id;
            });
            if (index === -1) {
              this.options.push(data);
            }
          }
        }
        this.selectSnippetsArray.push(data);
      }
    },
    selectSnippets(data) {
      const lastIndex = data.length - 1;
      if (data.length) {
        if (data[lastIndex].fid) {
          this.currentNode = {
            id: data[lastIndex].fid
          };
          this.selectSnippetId = data[lastIndex].id;
        }
      } else {
        this.selectToChangeListChecked();
      }
    },
    exceedingTip() {
      if (this.selectSnippetsArray.length >= 10) {
        this.$message.warning('最多可选择10条剪辑片段');
        return true;
      } else {
        return false;
      }
    },
    removeSnippet(data) {
      this.selectToChangeListChecked();
    },
    handleTreeNodeChange(node) {
      const arr = [];
      this.getNodeAllParants(node, arr);
      this.breadcrumbs = arr.reverse();
    },
    getNodeAllParants(node, arr) {
      if (node && node.parent) {
        arr.push(node.data);
        this.getNodeAllParants(node.parent, arr);
      }
      return false;
    },
    selectToChangeListChecked(snippet_id) {
      this.snippetsList.forEach(item => {
        if (this.selectSnippetsArray.find(i => i.id === item.id)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      if (snippet_id) {
        var _this$$refs$snippetsC;
        (_this$$refs$snippetsC = this.$refs.snippetsComp) === null || _this$$refs$snippetsC === void 0 ? void 0 : _this$$refs$snippetsC.scrollTargrtItem(snippet_id); // 滚动到制定位置
      }
    },

    expandSelect() {
      this.$refs.select.focus();
    },
    getPureSnippetsFolders(tree) {
      let arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      if (!tree.length) return [];
      for (const item of tree) {
        if (item.type) continue;
        const node = {
          ...item,
          children: []
        };
        arr.push(node);
        if (item.children && item.children.length) {
          this.getPureSnippetsFolders(item.children, node.children);
        }
      }
      return arr;
    }
  }
};
exports.default = _default;