"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    width: {
      type: [Number, String],
      default: '16px'
    }
  }
};
exports.default = _default;