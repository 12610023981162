"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _progressTags = _interopRequireDefault(require("@/components/BusinessDetail/progressTags"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealDetail',
  components: {
    progressTags: _progressTags.default,
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    dealData: {
      type: Object,
      default: () => ({})
    },
    isCreateDealShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progressObj: {
        status: '',
        curLabel: '',
        progressArray: [{
          label: '客户验证',
          visible: false,
          date: false
        }, {
          label: '需求分析',
          visible: false,
          date: false
        }, {
          label: '方案/报价',
          visible: false,
          date: false
        }, {
          label: '谈判审核',
          visible: false,
          date: false
        }, {
          label: '结束',
          visible: false,
          date: false
        }]
      },
      stageType: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['businessStatusOptions', 'api_permissions', 'webRoutes', 'modulePermission']),
    getPreDealDateString() {
      return function (date) {
        if (date) {
          return this.$moment(date).format('YYYY-MM-DD');
        } else {
          return date;
        }
      };
    },
    createDealPermission() {
      return this.api_permissions.includes('/api/account/account/create_deal');
    }
  },
  methods: {
    createDeal() {
      this.$emit('createDeal');
    },
    handlePreDealDate(date) {
      if (date) {
        return date.split(' ')[0];
      }
    },
    handleProgressObj(currentStageId) {
      const currentType = this.getStageTypeByid(currentStageId);
      const progressArray = this.handleBusinessStatusOptions(currentStageId, currentType);
      let curIndex = 0;
      let curLabel = '';
      progressArray.forEach((item, index) => {
        if (item.name === currentStageId) {
          curLabel = item.label;
          item.date = true;
          curIndex = index;
        }
      });
      progressArray.forEach((item, index) => {
        if (index <= curIndex) {
          item.date = true;
        } else {
          item.date = false;
        }
      });
      const progressObj = {
        curLabel: curLabel,
        status: currentStageId,
        type: currentType,
        progressArray
      };
      return progressObj;
    },
    // 根据stage id值名获取该商机类型
    getStageTypeByid(stage) {
      function checkStage(item) {
        return item.id === stage;
      }
      const stageItem = this.businessStatusOptions.find(checkStage);
      // eslint-disable-next-line no-undef
      return stageItem === null || stageItem === void 0 ? void 0 : stageItem.type;
    },
    handleBusinessStatusOptions(currentStage, currentStageType) {
      const readonlyValues = ['win', 'loss', 'invalidate'];
      const progressArray = [];
      this.businessStatusOptions.forEach(item => {
        if (item.type === 'in_process') {
          const itemObj = {
            label: item.stage_name,
            name: item.id,
            date: false,
            visible: false
          };
          progressArray.push(itemObj);
        } else {
          if (currentStage === item.id) {
            const closeItemObj = {
              label: item.stage_name,
              name: item.id,
              date: false,
              visible: false
            };
            progressArray.push(closeItemObj);
          }
        }
      });
      if (!readonlyValues.includes(currentStageType)) {
        progressArray.push({
          label: '结束',
          name: 'close',
          date: false,
          visible: false
        });
      }
      return progressArray;
    },
    goDealDetail(id) {
      if (this.modulePermission.deal) {
        this.$router.push({
          path: _routeMap.ROUTES.dealDetail,
          query: {
            id
          }
        });
      } else {
        this.$message.warning('无商机查看权限');
      }
    },
    money(value, num) {
      num = num > 0 && num <= 20 ? num : 2;
      value = parseFloat((value + '').replace(/[^\d\.-]/g, '')).toFixed(num) + ''; // 将金额转成比如 123.45的字符串
      var valueArr = value.split('.')[0].split('').reverse(); // 将字符串的数变成数组
      const valueFloat = value.split('.')[1]; // 取到 小数点后的值
      let valueString = '';
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : ''); // 循环 取数值并在每三位加个','
      }

      const money = valueString.split('').reverse().join('') + '.' + valueFloat; // 拼接上小数位
      return money;
    }
  }
};
exports.default = _default;