"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCoachOverviewList = getCoachOverviewList;
exports.getCoachRecord = getCoachRecord;
exports.getOtherUndoAdvise = getOtherUndoAdvise;
exports.getUndoAdvise = getUndoAdvise;
exports.getUndoCoachCount = getUndoCoachCount;
exports.searchUser = searchUser;
var _request = require("@/utils/request");
// 获取概览列表
function getCoachOverviewList(params) {
  return (0, _request.webService)({
    url: '/training/coach/overview',
    method: 'get',
    params
  });
}
// 获取未完成的辅导申请数量
function getUndoCoachCount(params) {
  return (0, _request.request)({
    url: '/coach/coach/overview/person/undo_coach_count',
    method: 'get',
    params
  });
}
// 获取该成员向我辅导的数据
function getUndoAdvise(params) {
  return (0, _request.webService)({
    url: '/training/coach/overview/person/undo_advise',
    method: 'get',
    params
  });
}
// 获取该成员向其他人辅导的数据
function getOtherUndoAdvise(params) {
  return (0, _request.webService)({
    url: '/training/coach/overview/person/other_coach',
    method: 'get',
    params
  });
}
// 模糊搜索
function searchUser(params) {
  return (0, _request.webService)({
    url: '/training/coach/overview/user_search',
    method: 'get',
    params
  });
}
// 获取30天内接受辅导记录
function getCoachRecord(params) {
  return (0, _request.webService)({
    url: '/training/coach/overview/person/coach_record',
    method: 'get',
    params
  });
}