"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NoData',
  props: {
    icon: {
      type: String,
      default: 'icon-a-Property1no-data'
    },
    tips: {
      type: String,
      default() {
        return this.$t('coaching.noData');
      }
    },
    size: {
      type: [String, Number],
      default: 160
    }
  }
};
exports.default = _default;