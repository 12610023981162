"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MANAGEMENT_ROUTES = exports.MANAGEMENT_PAGES = void 0;
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
const MANAGEMENT_PAGES = {
  Management: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Management" */'@/views/management'))),
  // 部门和成员
  Department: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Department" */
  '@/views/management/department'))),
  // 空间
  Workspace: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Workspace" */'@/views/workspace'))),
  // 账户信息
  Bill: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Bill" */'@/views/bills'))),
  // 企业信息管理
  CompanyInfoManagement: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CompanyInfoManagement" */
  '@/views/management/CompanyInfoManagement'))),
  // 系统管理权限
  SystemPermission: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "SystemPermission" */
  '@/views/systemAdminPrivileges'))),
  // 业务功能权限
  BusinessPermission: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BusinessPermission" */
  '@/views/funcPrivileges'))),
  // 应用管理
  AppManagement: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AppManagement" */'@/views/appManagement'))),
  // 应用集成
  AppIntegration: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AppIntegration" */
  '@/views/appManagement/components/AppIntegration'))),
  // 开发者管理
  ManageDeveloper: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ManageDeveloper" */
  '@/views/appManagement/components/ManageDeveloper'))),
  // 数据权限
  DataPermission: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DataPermission" */'@/views/dataPermission'))),
  // 关键事件
  CustomKeyEvent: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"KeyEventManagement" */
  '@/views/management/keyEventManagement'))),
  // 对象与字段
  CustomField: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CustomField" */'@/views/customField'))),
  // 手机号隐私保护
  NumberMasking: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "NumberMasking" */
  '@/views/management/numberDesensibilisation/index.vue'))),
  // 智能纪要模板
  SummaryTemplate: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "SeatManagement" */'@/views/summaryTemplate'))),
  // 坐席管理
  SeatManagement: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "SeatManagement" */'@/views/seatManagement'))),
  // 后台操作日志
  OperationLog: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "OperationLog" */'@/views/operationLog'))),
  // 成员使用纪录
  LoginLog: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "LoginLog" */'@/views/management/loginLog'))),
  // 快速建模
  RapidModeling: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"RapidModeling" */
  '@/views/management/rapidModeling'))),
  // 数据集管理
  DataSet: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"DataSet" */
  '@/views/management/dataSet'))),
  // 数据集标注
  DataSetAnnotate: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"DataSetAnnotate" */
  '@/views/management/annotate'))),
  // 模型管理
  ModelManagement: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"ModelManagement" */'@/views/management/modelManagement'))),
  // 模型全部版本
  ModelAllVersions: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"ModelAllVersions" */'@/views/management/modelAllVersions'))),
  // 组织管理导入
  ImportMembers: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ImportMembers" */'@/views/management/organizationalManagementImport'))),
  ImportDepartment: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ImportMembers" */'@/views/management/organizationalManagementImport'))),
  ImportBusinessRole: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ImportMembers" */'@/views/management/organizationalManagementImport'))),
  ImportManagerRole: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ImportMembers" */'@/views/management/organizationalManagementImport'))),
  KeyEventMutualExclusion: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KeyEventMutualExclusion" */'@/views/management/KeyEventMutualExclusion')))
};
exports.MANAGEMENT_PAGES = MANAGEMENT_PAGES;
const MANAGEMENT_ROUTES = {
  set: '/set',
  // 后台设置页面
  departmentManagement: '/set/department',
  // 部门和成员管理
  dataPermission: '/set/data-permission',
  // 数据权限
  billManagement: '/set/bill',
  // 计费
  customEvent: '/set/key-event',
  // 自定义关键事件
  customField: '/set/custom-field',
  // 自定义字段
  mergeAppData: '/merge-app-data',
  // 个人中心app数据管理
  dataSet: '/set/data-set',
  // 数据集管理页面
  annotate: '/set/annotate',
  // 标注页面
  ModelManagement: '/set/model-management',
  // 模型管理页面
  ModelAllVersions: '/set/model-all-versions',
  // 模型全部版本
  keyEventManagement: '/set/key-event',
  // 关键事件管理
  ImportMembers: '/set/import/members',
  // 导入成员
  ImportDepartment: '/set/import/department',
  // 导入部门
  ImportBusinessRole: '/set/import/business-role',
  // 导入业务角色
  ImportManagerRole: '/set/import/manager-role',
  // 导入管理员角色
  KeyEventMutualExclusion: '/set/key-event-mutual-exclusion',
  // 关键事件互斥策略
  RapidModeling: '/set/quick-modeling' // 快速建模
};

exports.MANAGEMENT_ROUTES = MANAGEMENT_ROUTES;