"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "rapid-modeling-footer"
  }, [_vm._t("cancel"), _vm._l([1, 2, 3, 4], function (item) {
    return [_setup.modelInfo.step === item ? _vm._t("step-" + item) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;