"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EaseSteps = _interopRequireDefault(require("@/components/EaseSteps"));
var _SuperParamsSetDrawer = _interopRequireDefault(require("./components/SuperParamsSetDrawer.vue"));
var _RapidModelingHeader = _interopRequireDefault(require("./components/RapidModelingHeader.vue"));
var _RapidModelingFooter = _interopRequireDefault(require("./components/RapidModelingFooter.vue"));
var _RapidModelInfo = _interopRequireDefault(require("./components/RapidModelInfo"));
var _ModelSentence = _interopRequireDefault(require("./components/ModelSentence"));
var _ValidTrainData = _interopRequireDefault(require("./components/ValidTrainData"));
var _TrainAndPublish = _interopRequireDefault(require("./components/TrainAndPublish"));
var _elementUi = require("element-ui");
var _modelInfo = require("./hooks/modelInfo");
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router");
var _modelInfOperate = require("./hooks/modelInfOperate");
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const {
      modelInfo,
      setModelInfo,
      resetModelInfo,
      rapidModelInfo
    } = (0, _modelInfo.useModelInfo)();

    // 获取数据
    const getRapidModelInfoApi = async () => {
      const res = await (0, _rapidCreateModel.getRapidModelInfo)({
        model_id: modelInfo.id
      });
      if (res.code === 20000) {
        const {
          id,
          name,
          description,
          current_stage,
          version,
          is_continue_train,
          example_type
        } = res.results;
        if (!id) {
          goStep(1);
        } else {
          rapidModelInfo.value.name = name;
          rapidModelInfo.value.description = description;
          setModelInfo({
            id,
            name,
            description,
            example_type,
            step: current_stage,
            version,
            isContinue: is_continue_train
          });
          goStep(current_stage);
        }
      }
    };

    // 存储当前在哪一步
    const storeRapidModelStepApi = async step => {
      if (!modelInfo.id) return;
      await (0, _rapidCreateModel.storeRapidModelStep)({
        model_id: modelInfo.id,
        current_stage: step,
        is_continue_train: modelInfo.isContinue
      });
    };
    function goStep(step) {
      setModelInfo({
        step
      });
      storeRapidModelStepApi(step);
    }
    (0, _vue.provide)('goStep', goStep);

    // 取消建模
    const {
      cancelRapidModel
    } = (0, _modelInfOperate.useDeleteRapidModel)({
      modelInfo,
      resetModelInfo,
      goStep
    });

    // 第一步 创建模型
    const {
      rapidModelFormRef,
      nextStep,
      createModelBtnLoading
    } = (0, _modelInfOperate.useRapidModel)(rapidModelInfo, modelInfo, setModelInfo, goStep);

    // 第二步 训练数据
    const {
      modelSentenceRef,
      createTrainTaskApi,
      trainTaskBtnLoading
    } = (0, _modelInfOperate.useTrainTask)(modelInfo, goStep, setModelInfo);

    // 是否为修改例句
    const isModifyModelSentence = (0, _vue.ref)(false);
    const changeModifyModelSentence = val => {
      isModifyModelSentence.value = val;
    };

    // 是否展示修改例句按钮
    const isShowModifySentenceBtn = (0, _vue.ref)(false);
    const canModifySentence = val => {
      isShowModifySentenceBtn.value = val;
    };
    (0, _vue.onMounted)(() => {
      var _useRoute, _useRoute$query;
      const modelId = ((_useRoute = (0, _useRouter.useRoute)()) === null || _useRoute === void 0 ? void 0 : (_useRoute$query = _useRoute.query) === null || _useRoute$query === void 0 ? void 0 : _useRoute$query.modelId) - 0;
      setModelInfo({
        id: modelId
      });
      if (modelId) {
        getRapidModelInfoApi();
      }
    });

    // 第三步，创建训练任务
    const {
      superParamsSetDrawerVisible,
      superParamsSetDrawerRef,
      validTrainDataRef,
      openParamsSetDrawer,
      createTrainModelTask
    } = (0, _modelInfOperate.useTrainModel)({
      setModelInfo,
      modelInfo,
      goStep
    });

    // 第四步，发布
    const {
      trainAndPublishRef,
      publish
    } = (0, _modelInfOperate.usePublishModel)({
      resetModelInfo,
      modelInfo
    });
    return {
      __sfc: true,
      modelInfo,
      setModelInfo,
      resetModelInfo,
      rapidModelInfo,
      getRapidModelInfoApi,
      storeRapidModelStepApi,
      goStep,
      cancelRapidModel,
      rapidModelFormRef,
      nextStep,
      createModelBtnLoading,
      modelSentenceRef,
      createTrainTaskApi,
      trainTaskBtnLoading,
      isModifyModelSentence,
      changeModifyModelSentence,
      isShowModifySentenceBtn,
      canModifySentence,
      superParamsSetDrawerVisible,
      superParamsSetDrawerRef,
      validTrainDataRef,
      openParamsSetDrawer,
      createTrainModelTask,
      trainAndPublishRef,
      publish,
      EaseSteps: _EaseSteps.default,
      SuperParamsSetDrawer: _SuperParamsSetDrawer.default,
      RapidModelingHeader: _RapidModelingHeader.default,
      RapidModelingFooter: _RapidModelingFooter.default,
      RapidModelInfo: _RapidModelInfo.default,
      ModelSentence: _ModelSentence.default,
      ValidTrainData: _ValidTrainData.default,
      TrainAndPublish: _TrainAndPublish.default
    };
  }
};
exports.default = _default;