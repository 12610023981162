"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "trainee-overview"
  }, [_c(_setup.AnalysisFilters, {
    on: {
      change: _setup.onAnalysisFilterChange,
      "export-table": _setup.exportTable
    }
  }), _c(_setup.DataAnalysisTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    ref: "tableRef",
    attrs: {
      headers: _setup.tableHeader,
      "main-column": _setup.mainColumn,
      "sub-columns": _setup.subColumns,
      data: _setup.tableDataDisplay
    },
    on: {
      "cell-click": _setup.cellClick,
      "sort-change": _setup.sortChange
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      total: _setup.total,
      "current-page": _setup.listParams.current_page,
      "page-size": _setup.listParams.size
    },
    on: {
      sizeChange: _setup.sizeChange,
      currentChange: _setup.currentChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;