"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      title: "示例",
      visible: _vm.visible,
      width: "600px",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("MgvButton", {
          on: {
            click: function ($event) {
              return _setup.usePromptTemplate();
            }
          }
        }, [_vm._v("使用示例文案")])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "desc"
  }, [_vm._v("使用时，替换灰色部分")]), _c("div", {
    staticClass: "content"
  }, [_c("span", [_vm._v('你是一名销售，与客户的对话文本如下："""{text}"""，')]), _c("span", {
    staticClass: "place-holder"
  }, [_vm._v(" #这里替换为：数据集标签（或事件）描述定义、和期望大语言模型如何判断。")]), _c("br"), _c("span", [_vm._v(" 最终结果以JSON格式返回 示例格式如下: "), _c("br"), _vm._v('{ "关键事件": "", "关键事件对话上下文": [""] }')])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;