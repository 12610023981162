"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _elementUi = require("element-ui");
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
var _default = {
  __name: 'ExportButton',
  props: {
    content: {
      type: String,
      default: ''
    },
    btnContent: {
      type: String,
      default: ''
    },
    exportApi: {
      type: Function
    }
  },
  emits: ['handleExport'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const visible = (0, _vue.ref)(false);
    const router = (0, _useRouter.useRouter)();
    const closePopover = () => {
      visible.value = false;
    };
    const goExportRecords = () => {
      router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    };
    const vnode = (0, _vue.h)('div', {
      class: 'notify-content'
    }, [(0, _vue.h)('span', '文件正在准备中，您可以在个人中心 - '), (0, _vue.h)('a', {
      class: 'jump-link',
      on: {
        click: goExportRecords
      }
    }, '导出记录'), (0, _vue.h)('span', '中查看。')]);
    const handleExport = async () => {
      const res = await props.exportApi();
      console.log(res, 'res');
      if (res.code === 20000) {
        closePopover();
        if (_elementUi.Notification) {
          _elementUi.Notification.closeAll();
        }
        (0, _elementUi.Notification)({
          title: '导出文件',
          message: vnode,
          duration: 5000,
          customClass: 'conversation-list-export-file-notify'
        });
      }
    };
    return {
      __sfc: true,
      props,
      visible,
      router,
      closePopover,
      emit,
      goExportRecords,
      vnode,
      handleExport,
      t: _i18n.t
    };
  }
};
exports.default = _default;