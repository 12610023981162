"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["text-tag", "tag-" + _vm.bgColor, _vm.color ? "text-" + _vm.color : "", _vm.shape]
  }, [_vm._t("icon"), _vm._v(" " + _vm._s(_vm.text) + " ")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;