"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    ref: "evaluateFormGenerator",
    staticClass: "evaluate-form-generator"
  }, [_c("div", {
    staticClass: "generator-header"
  }, [_c(_setup.FormItemHeaderEditable, {
    attrs: {
      name: _setup.schema.name,
      description: _setup.schema.description,
      "is-title": ""
    },
    on: {
      "update:name": function ($event) {
        return _vm.$set(_setup.schema, "name", $event);
      },
      "update:description": function ($event) {
        return _vm.$set(_setup.schema, "description", $event);
      }
    }
  })], 1), _c("el-form", [_c(_setup.draggable, _vm._b({
    on: {
      end: _setup.handleDragEnd,
      add: _setup.handleDragAdd
    },
    model: {
      value: _setup.schema.fields,
      callback: function ($$v) {
        _vm.$set(_setup.schema, "fields", $$v);
      },
      expression: "schema.fields"
    }
  }, "draggable", {
    group: "people",
    ghostClass: "evaluate-form-generator__ghost",
    animation: 200,
    handle: ".move"
  }, false), [_c("transition-group", {
    staticClass: "widget-form-list",
    attrs: {
      name: "fade",
      tag: "div"
    }
  }, _vm._l(_setup.schema.fields, function (formItem, index) {
    return _c("div", {
      key: index,
      staticClass: "form-item-container",
      class: {
        "is-active": formItem === _setup.activeFormItem
      },
      on: {
        click: function ($event) {
          return _setup.addActiveFormItem(formItem);
        }
      }
    }, [_c(_setup.GeneratorFormItemOperation, {
      attrs: {
        "is-edit-status": _vm.isEditStatus,
        "field-type-id": formItem.field_type,
        required: formItem.required
      },
      on: {
        "update:fieldTypeId": function ($event) {
          return _vm.$set(formItem, "field_type", $event);
        },
        "update:field-type-id": function ($event) {
          return _vm.$set(formItem, "field_type", $event);
        },
        "update:required": function ($event) {
          return _vm.$set(formItem, "required", $event);
        },
        "delete-item": function ($event) {
          return _setup.deleteFormItem(formItem.id);
        }
      }
    }), _c("div", {
      staticClass: "form-item-content"
    }, [_c(_setup.FormItemHeaderEditable, {
      attrs: {
        name: formItem.name,
        description: formItem.prompt,
        required: !!formItem.required,
        index: index + 1
      },
      on: {
        "update:name": function ($event) {
          return _vm.$set(formItem, "name", $event);
        },
        "update:description": function ($event) {
          return _vm.$set(formItem, "prompt", $event);
        }
      }
    }), _c("el-form-item", [_c(_setup.GeneratorFormItem, {
      attrs: {
        "is-edit-status": _vm.isEditStatus,
        "option-ids": formItem.option_ids,
        options: formItem.options,
        "field-type": formItem.field_type
      },
      on: {
        onOptionFieldChange: function ($event) {
          return _setup.handleOptionFieldChange($event, formItem);
        },
        onOptionDrag: function ($event) {
          return _setup.handleOptionDrag($event, formItem);
        },
        onDeleteOption: function ($event) {
          return _setup.handleDeleteOption($event, formItem);
        }
      }
    }), !_vm.isEditStatus && _setup.isOptionsField(formItem.field_type) && formItem === _setup.activeFormItem ? _c("el-button", {
      staticClass: "add-option-button",
      attrs: {
        type: "primary",
        plain: "",
        size: "small"
      },
      on: {
        click: function ($event) {
          return _setup.handleAddOption(formItem);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-plus"
    }), _vm._v(" 添加选项")]) : _vm._e()], 1)], 1)], 1);
  }), 0)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;