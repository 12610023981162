"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      "custom-class": "course-move-dialog",
      title: _vm.$t("coaching.moveTo"),
      width: "400px"
    }
  }, [_c("CourseCategoryTreeSelect", {
    ref: "course-category-tree-select",
    attrs: {
      "current-node": {
        id: _vm.courseInfo.category_id
      }
    },
    on: {
      positionSelect: _vm.handlePositionSelect
    }
  }), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    staticClass: "mg-roung-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    staticClass: "mg-roung-button",
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.confirm")))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;