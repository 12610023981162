"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SingleSelect = _interopRequireDefault(require("@/components/Filters/components/SingleSelect"));
var _MultiSelect = _interopRequireDefault(require("@/components/Filters/components/MultiSelect"));
var _CustomInput = _interopRequireDefault(require("@/components/Filters/components/CustomInput"));
var _index = _interopRequireDefault(require("@/components/AmountRange/index"));
var _CreateDate = _interopRequireDefault(require("@/components/Filters/components/CreateDate"));
var _SingleCheckbox = _interopRequireDefault(require("@/components/Filters/components/SingleCheckbox"));
var _AccountLabelCascaderSelect = _interopRequireDefault(require("@/components/Filters/components/AccountLabelCascaderSelect"));
var _SuggestionSelect = _interopRequireDefault(require("@/components/Filters/components/SuggestionSelect"));
var _TradeScorePopoverFilter = _interopRequireDefault(require("@/views/trade/components/TradeScorePopoverFilter.vue"));
var _SpaceEventSelect = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/SpaceEventSelect.vue"));
var _TimeRangeSelect = _interopRequireDefault(require("@/components/TimeRangeSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealCustomFilter',
  components: {
    DealFilterSingleSelect: _SingleSelect.default,
    DealFilterMultiSelect: _MultiSelect.default,
    DealFilterInput: _CustomInput.default,
    AmountRangeSelect: _index.default,
    DealFilterDate: _CreateDate.default,
    DealFilterCheckbox: _SingleCheckbox.default,
    DealFilterCascaderSelect: _AccountLabelCascaderSelect.default,
    DealFilterSuggestionSelect: _SuggestionSelect.default,
    TradeScorePopoverFilter: _TradeScorePopoverFilter.default,
    SpaceEventSelect: _SpaceEventSelect.default,
    TimeRangeSelect: _TimeRangeSelect.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String, Number, Boolean, Object]
    },
    accountLabel: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleComp() {
      if (!this.item) return;
      switch (this.item.type) {
        case 'select':
        case 'table':
          return 'DealFilterSingleSelect';
        case 'checkbox':
          return 'DealFilterMultiSelect';
        case 'integer':
        case 'float':
          return 'AmountRangeSelect';
        case 'date':
          return 'DealFilterDate';
        case 'single_checkbox':
          return 'DealFilterCheckbox';
        case 'button':
          return 'DealFilterCascaderSelect';
        case 'text':
          return 'DealFilterSuggestionSelect';
        // 交易评分筛选器
        case 'score_rule':
          return 'TradeScorePopoverFilter';
        // TODO 培训高频问题筛选关键事件（临时方案）
        case 'cascader':
          return 'SpaceEventSelect';
        case 'duration':
          return 'TimeRangeSelect';
        default:
          return 'DealFilterInput';
      }
    },
    handleChange(val) {
      this.$emit('customFilterChange', val);
    }
  }
};
exports.default = _default;