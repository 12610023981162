"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "llm-post-processing"
  }, [_c("div", {
    staticClass: "llm-post-processing__header"
  }, [_c("div", {
    staticClass: "llm-post-processing__title"
  }, [_vm._v(" 额外使用大语言模型判断是否命中 ")]), _c("el-switch", {
    attrs: {
      disabled: _vm.readOnly,
      value: _vm.isEnableLLM,
      "active-value": 1,
      "inactive-value": 0
    },
    on: {
      change: _setup.isEnableLLMChange
    }
  }), _vm.isEnableLLM && !_setup.hasTextVariable ? _c("div", {
    staticClass: "llm-post-processing__tips"
  }, [_vm._v(" 请插入变量，否则提示语无法生效 ")]) : _vm._e()], 1), _vm.isEnableLLM ? _c("div", {
    staticClass: "llm-post-processing__content"
  }, [_c("div", {
    staticClass: "prompt-input"
  }, [_c("el-input", {
    ref: "promptInputRef",
    attrs: {
      type: "textarea",
      placeholder: "请输入提示语",
      maxlength: "1000",
      "show-word-limit": "",
      disabled: _vm.readOnly,
      autosize: {
        minRows: 6,
        maxRows: 20
      },
      resize: "none"
    },
    model: {
      value: _setup.prompt,
      callback: function ($$v) {
        _setup.prompt = $$v;
      },
      expression: "prompt"
    }
  }), !_vm.readOnly ? _c("div", {
    staticClass: "prompt-input-operate"
  }, [_c("div", [!_setup.hasTextVariable ? _c("MgvButton", {
    attrs: {
      type: "text",
      icon: "icon-code-brackets",
      color: "info",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _setup.insertText("{text}");
      }
    }
  }, [_vm._v("文本句变量")]) : _vm._e()], 1), _c("div", [_c("MgvButton", {
    attrs: {
      type: "text",
      color: "primary",
      size: "mini"
    },
    on: {
      click: _setup.openPromptExampleDialog
    }
  }, [_vm._v("示例")]), _c("MgvButton", {
    attrs: {
      type: "text",
      icon: "icon-template",
      color: "info",
      size: "mini"
    },
    on: {
      click: _setup.openPromptListDialog
    }
  }, [_vm._v("使用模版")]), _c("MgvButton", {
    attrs: {
      type: "text",
      icon: "icon-preserve",
      disabled: !_setup.prompt,
      color: "info",
      size: "mini"
    },
    on: {
      click: _setup.savePromptAsTemplate
    }
  }, [_vm._v("存为模版")])], 1)]) : _vm._e()], 1)]) : _vm._e(), _setup.promptExampleDialogVisible ? _c(_setup.PromptExampleDialog, {
    attrs: {
      visible: _setup.promptExampleDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.promptExampleDialogVisible = $event;
      },
      usePromptTemplate: _setup.usePromptTemplate
    }
  }) : _vm._e(), _setup.savePromptDialogVisible ? _c(_setup.SavePromptDialog, {
    attrs: {
      visible: _setup.savePromptDialogVisible,
      prompt: _setup.prompt
    },
    on: {
      "update:visible": function ($event) {
        _setup.savePromptDialogVisible = $event;
      }
    }
  }) : _vm._e(), _setup.promptListDialogVisible ? _c(_setup.PromptTemplateDialog, {
    attrs: {
      visible: _setup.promptListDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.promptListDialogVisible = $event;
      },
      usePromptTemplate: _setup.usePromptTemplate
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;