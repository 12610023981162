"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "edit-column-dialog",
    attrs: {
      title: _vm.isEdit ? "编辑指标" : "新建指标",
      visible: _vm.visible,
      "modal-append-to-body": false,
      "before-close": _vm.handleClose,
      width: "600px"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.configData,
      "label-position": "top",
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("指标名称")]), _c("el-input", {
    attrs: {
      maxlength: "10",
      placeholder: "请输入指标名称"
    },
    model: {
      value: _vm.configData.name,
      callback: function ($$v) {
        _vm.$set(_vm.configData, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "configData.name"
    }
  })], 1), _c("analysis-config", {
    ref: "analysis-config",
    attrs: {
      "show-events-result-type": "",
      configs: _vm.configData.configs,
      "form-config": _vm.formConfig
    },
    on: {
      clearValidate: _vm.handleClearValidate
    }
  }), _c("div", {
    staticClass: "el-form-item"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("筛选条件")]), _c("deal-key-event-filter", {
    staticClass: "deal-key-event-filter",
    model: {
      value: _vm.configData.filter_config.event_filters,
      callback: function ($$v) {
        _vm.$set(_vm.configData.filter_config, "event_filters", $$v);
      },
      expression: "configData.filter_config.event_filters"
    }
  }), _c("deal-custom-field-filter", {
    attrs: {
      "custom-filters": _vm.configData.filter_config.custom_filters
    },
    on: {
      customFilterChange: _vm.customFilterChange
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;