"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const TokenKey = 'session';
let domain = '';
const isNeedlessSettingEnv = process.env.VUE_APP_TITLE === 'image' || process.env.NODE_ENV === 'development'; // 是否是不需要配置参数的环境，即开发环境或者私有化部署环境

if (!isNeedlessSettingEnv) {
  const domainStrArray = window.location.host.split('.');
  if (domainStrArray[1].includes('test')) {
    domain = domainStrArray[domainStrArray.length - 3] + '.' + domainStrArray[domainStrArray.length - 2] + '.' + domainStrArray[domainStrArray.length - 1];
  } else {
    domain = domainStrArray[domainStrArray.length - 2] + '.' + domainStrArray[domainStrArray.length - 1];
  }
}
function getToken() {
  if (isNeedlessSettingEnv) {
    return _jsCookie.default.get(TokenKey);
  } else {
    return _jsCookie.default.get(TokenKey, {
      domain: domain
    });
  }
}
function setToken(token) {
  if (isNeedlessSettingEnv) {
    return _jsCookie.default.set(TokenKey, token);
  } else {
    if (_jsCookie.default.get(TokenKey)) {
      // 如果已存在token，先移除
      removeToken();
    }
    return _jsCookie.default.set(TokenKey, token, {
      expires: 100,
      domain: domain,
      secure: true,
      sameSite: 'None'
    });
  }
}
function removeToken() {
  if (isNeedlessSettingEnv) {
    return _jsCookie.default.remove(TokenKey);
  } else {
    return _jsCookie.default.remove(TokenKey, {
      expires: -1,
      domain: domain,
      secure: true,
      sameSite: 'None'
    });
  }
}