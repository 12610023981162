"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _timeFormatter = require("@/utils/time-formatter");
var _ConversationDimension = _interopRequireDefault(require("@/components/Detail/ConversationDimension"));
var _routeMap = require("@/router/routeMap");
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetailDimension',
  components: {
    ConversationDimension: _ConversationDimension.default
  },
  props: {
    convId: {
      type: [String, Number],
      default: 0
    },
    apiProcess: {
      type: Object,
      default: () => ({})
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    mediaType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dimensionInfo: [],
      loading: false
    };
  },
  watch: {
    convId(val) {
      if (val) {
        this.getConversationAnalySisData();
      }
    }
  },
  created() {
    this.getConversationAnalySisData();
  },
  methods: {
    async getConversationAnalySisData() {
      this.loading = true;
      const res = await (0, _videoDetail.getConversationAnalySisData)({
        conversation_id: this.convId
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.dimensionInfo = this.handleDimension(res.results.conv_dimension);
      }
    },
    goBiPerson() {
      for (const item of this.participantsInfo) {
        if (item.entity_type === 'host_salesman') {
          const {
            entity_id: id,
            name
          } = item;
          this.$router.push({
            path: _routeMap.ROUTES.biPersonalDimension,
            query: {
              id,
              name
            }
          });
        }
      }
    },
    handleDimension(dimension) {
      return ['percent_speak', 'max_sales', 'max_customer', 'discuss', 'question', 'customer_question', 'sales_speed', 'customer_speed'].map(item => {
        return {
          label: dimension[item].label,
          type: dimension[item].key,
          introduce: dimension[item].explanation,
          isImprove: dimension[item].mark,
          clickable: dimension[item].clickable && dimension[item].value,
          value: (() => {
            switch (item) {
              case 'percent_speak':
                return `<span class="bold-text">${dimension[item].value.toFixed(2)}</span> <span>%</span>`;
              case 'max_sales':
              case 'max_customer':
                return this.getTimeHtml(dimension[item].value);
              case 'discuss':
                return `<span class="bold-text">${parseFloat(dimension[item].value.toFixed(2))} </span><span>次</span>`;
              case 'question':
                return `<span class="bold-text">
                ${parseFloat(dimension[item].value.toFixed(2))} </span><span>次</span>`;
              case 'customer_question':
                return `<span class="bold-text">
                ${parseFloat(dimension[item].value.toFixed(2))} </span><span>次</span>`;
              case 'sales_speed':
                return `<span class="bold-text">
                ${parseFloat(dimension[item].value.toFixed(2))} </span><span>字/分钟</span>`;
              case 'customer_speed':
                return `<span class="bold-text">
                ${parseFloat(dimension[item].value.toFixed(2))} </span><span>字/分钟</span>`;
            }
          })()
        };
      });
    },
    getTimeHtml(seconds) {
      let {
        s
      } = (0, _timeFormatter.second2HMS)(seconds);
      const {
        m,
        h
      } = (0, _timeFormatter.second2HMS)(seconds);
      if (h) {
        return m ? `<span class="bold-text">${h}</span><span>小时</span><span class="bold-text"> ${m}</span><span>分</span>` : `<span class="bold-text">${h}</span><span>小时</span>`;
      } else if (m) {
        return s ? `<span class="bold-text">${m}</span><span>分</span><span class="bold-text"> ${s}</span><span>秒</span>` : `<span class="bold-text">${m}</span><span>分</span>`;
      } else if (s) {
        s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(2);
        return `<span class="bold-text">${s}</span><span>秒</span>`;
      } else {
        return `<span class="bold-text">0</span><span>秒</span>`;
      }
    }
  }
};
exports.default = _default;