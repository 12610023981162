"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _busiCalendarDirectory = _interopRequireDefault(require("@/components/BusinessDetail/busiCalendarDirectory"));
var _dealSpeakTimeTabContent = _interopRequireDefault(require("@/components/BusinessTable/components/dealSpeakTimeTabContent"));
var _OverView = _interopRequireDefault(require("@/components/Detail/OverView"));
var _DialogText = _interopRequireDefault(require("@/components/Detail/DialogText"));
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
var _client = require("@/api/client");
var _num_formatter = require("@/utils/num_formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomerFollowUpDeal',
  components: {
    BusiCalenderDirectoty: _busiCalendarDirectory.default,
    DealSpeakTimeTabContent: _dealSpeakTimeTabContent.default,
    OverView: _OverView.default,
    DialogText: _DialogText.default
  },
  props: {
    accountId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      hasConvDetail: true,
      hasConvDetailPermission: true,
      convDetailLoading: false,
      tabActiveName: 'overview',
      dealOptions: [],
      dealId: '',
      textTip: '',
      isCalenderFold: false,
      dealObject: {},
      hasAccountLabelPermission: false,
      uploadType: '',
      // 是否绑定商机或线索
      mediaType: '',
      // 会话类型
      conversationIds: [],
      selectedTagMark: '',
      customerLabelList: [],
      customerObjectionList: [],
      overviewInfo: {},
      dimensionInfo: null,
      apiProcess: {
        is_es_insert_complete: false,
        // 音频信息
        is_qa_complete: false,
        // 问答
        is_event_engine_complete: false,
        // 关键事件
        is_keyword_complete: false,
        // 关键词
        is_qa_keyword_complete: false,
        // 报告关键词
        is_abstract_complete: false // 报告摘要
      } // 返回的其他接口的请求进度
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['audio', 'busiActiveConver', 'timezone', 'isEsInsertComplete'])
  },
  watch: {
    busiActiveConver: {
      handler: function (val) {
        if (val) {
          this.hasConvDetail = true;
          this.getConvDetail(val);
        } else {
          this.convDetailLoading = false;
          this.hasConvDetail = false;
        }
      },
      immediate: true
    },
    dealId(val) {
      this.$refs.dealSpeakTimeTabContent.getConversationList(this.accountId, this.dealId);
      if (this.hasAccountLabelPermission) {
        this.getCustomerLabelList(val);
      }
      this.getCustomerObjectionList(val);
    }
  },
  created() {
    this.getDealDetail();
    this.getDealList();
    this.getCustomerObjectionList();
  },
  methods: {
    goConvDetail() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.busiActiveConver}&noback=true`);
    },
    initCalendar() {
      this.$nextTick(() => {
        this.$refs.busiCalenderDirectoty.$refs['horizontal-calendar'].init();
      });
    },
    cancelDealFilter() {
      this.dealId = '';
    },
    async getDealList() {
      const res = await (0, _client.getDealList)(this.accountId);
      this.dealOptions = res.results.deal_list;
    },
    async getCustomerLabelList(dealId) {
      const data = {
        account_id: this.accountId
      };
      if (dealId) {
        data.deal_id = dealId;
      }
      const res = await (0, _client.getCustomerLabelList)(data);
      this.customerLabelList = res.results.tags;
    },
    async getCustomerObjectionList(dealId) {
      const data = {
        account_id: this.accountId
      };
      if (dealId) {
        data.deal_id = dealId;
      }
      const res = await (0, _client.getCustomerObjectionList)(data);
      this.customerObjectionList = res.results.events;
    },
    parseFormatNum(number, n) {
      return (0, _num_formatter.parseFormatNum)(number, n);
    },
    switchConvTab(id, date) {
      this.saveConvInfo(id, date);
      this.tabActiveName = 'transtext';
    },
    saveConvInfo(id, date) {
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$plus8ToCurrentZone(date).format('YYYY-MM-DD'));
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
      this.initCalendar();
    },
    getLabel(item) {
      return `${item.name}${item.stage ? ' · ' : ''}${item.stage}${item.plan_to_deal_at ? ' · ' : ''}${item.plan_to_deal_at ? this.$moment(item.plan_to_deal_at).format('YYYY-MM-DD') : ''} · ${(0, _num_formatter.parseFormatNum)(item.amount, 2)}元`;
    },
    getConversationIds(tag, conversationIds, type) {
      this.$refs.dealSpeakTimeTabContent.goConvCard();
      this.selectedTagMark = this.selectedTagMark === tag[`${type}_id`] + tag[`${type}_name`] ? '' : tag[`${type}_id`] + tag[`${type}_name`];
      this.conversationIds = this.selectedTagMark ? conversationIds : [];
    },
    cancelFilter() {
      this.selectedTagMark = '';
      this.conversationIds = [];
    },
    scrollToActiveConv(top) {
      const tabs_content = document.getElementsByClassName('bottom-center-part')[0];
      setTimeout(() => {
        tabs_content.scrollTo({
          top,
          behavior: 'smooth'
        });
      }, 500);
    },
    getDealDetail() {
      (0, _client.getAccountBubble)({
        account_id: this.accountId,
        time_zone: this.timezone
      }).then(res => {
        if (res.code === 20000) {
          this.dealObject = res.results;
          this.hasAccountLabelPermission = res.results.has_account_label_right;
          if (this.hasAccountLabelPermission) {
            this.getCustomerLabelList();
          }
          this.$emit('getCustomerName', this.dealObject.customer_name);
        }
      });
    },
    getConvDetail(val) {
      if (val) {
        this.convDetailLoading = true;
        const params = {
          conversation_id: val,
          time_zone: this.timezone
        };
        (0, _client.getConvDetail)(params).then(async res => {
          if (res.code === 20000) {
            this.hasConvDetailPermission = true;
            const results = res.results;
            this.overviewInfo = results;
            this.apiProcess = results.process;
            this.uploadType = results.type;
            this.partiInfo = results.participants;
            this.mediaType = results.media_type;
            this.$store.commit('video/SET_CUR_MEDIA_TYPE', this.mediaType);
            this.$store.dispatch('video/set_audio_all', {
              cur_conv_id: val,
              type: 'customer',
              has_workspace: false,
              has_entity_id: this.partiInfo[0].entity_id
            }).then(() => {
              if (this.audio.length || this.isEsInsertComplete) {
                if (this.$refs.dealSpeakTimeTabContent && this.$refs.dealSpeakTimeTabContent.isKeyEventDetail) {
                  var _this$$refs$dealSpeak, _this$$refs$dealSpeak2;
                  (_this$$refs$dealSpeak = this.$refs.dealSpeakTimeTabContent.$refs) === null || _this$$refs$dealSpeak === void 0 ? void 0 : (_this$$refs$dealSpeak2 = _this$$refs$dealSpeak.keyEventDetailList) === null || _this$$refs$dealSpeak2 === void 0 ? void 0 : _this$$refs$dealSpeak2.setCurTracker(0);
                }
              } else {
                this.eventEngine = [];
                this.dimensionInfo = null;
                this.$store.commit('video/SET_AUDIO_ALL', null);
              }
              this.handleDimensionInfo(results.conv_dimension);
            });
          } else if (res.code === 40723 || res.code === 43009) {
            this.hasConvDetailPermission = false;
            this.textTip = res.msg;
          }
        }).finally(() => {
          this.convDetailLoading = false;
        });
      }
    },
    handleDimensionInfo(dimensionInfo) {
      const dimension = [];
      for (const key in dimensionInfo) {
        if (Object.hasOwnProperty.call(dimensionInfo, key)) {
          const dimIndex = this.getDimensionLabel(key, dimensionInfo[key]).index;
          const dimLabel = this.getDimensionLabel(key, dimensionInfo[key]).label;
          const dimValue = this.getDimensionLabel(key, dimensionInfo[key]).value;
          const isImprove = this.getDimensionLabel(key, dimensionInfo[key]).isImprove;
          const introduce = this.getDimensionLabel(key, dimensionInfo[key]).introduce;
          dimension[dimIndex] = {
            label: dimLabel,
            value: dimValue,
            isImprove: isImprove,
            introduce: introduce
          };
        }
      }
      this.dimensionInfo = dimension;
    },
    getDimensionLabel(key, value) {
      switch (key) {
        case 'percent_speak':
          return {
            label: '销售说话占比',
            index: 0,
            value: `<span class="bold-text">${value.value.toFixed(2)}</span> %`,
            isImprove: value.mark,
            introduce: value.explanation
          };
          // eslint-disable-next-line no-unreachable
          break;
        case 'max_sales':
          return {
            label: '最长销售独白',
            index: 1,
            value: this.getTimeHtml(value.value),
            isImprove: value.mark,
            introduce: value.explanation
          };
          // eslint-disable-next-line no-unreachable
          break;
        case 'max_customer':
          return {
            label: '最长客户叙述',
            index: 2,
            value: this.getTimeHtml(value.value),
            isImprove: value.mark,
            introduce: value.explanation
          };
          // eslint-disable-next-line no-unreachable
          break;
        case 'discuss':
          return {
            label: '深入讨论次数',
            index: 3,
            value: `<span class="bold-text">${parseFloat(value.value.toFixed(2))} </span>次`,
            isImprove: value.mark,
            introduce: value.explanation
          };
          // eslint-disable-next-line no-unreachable
          break;
        case 'question':
          return {
            label: '销售提问次数',
            index: 4,
            value: `<span class="bold-text">${parseFloat(value.value.toFixed(2))} </span>次`,
            isImprove: value.mark,
            introduce: value.explanation
          };
          // eslint-disable-next-line no-unreachable
          break;
        default:
          return {
            label: '',
            index: 0
          };
          // eslint-disable-next-line no-unreachable
          break;
      }
    },
    getTimeHtml(seconds) {
      // eslint-disable-next-line prefer-const
      let {
        s,
        m,
        h
      } = (0, _timeFormatter.second2HMS)(seconds);
      if (h) {
        return m ? `<span class="bold-text">${h}</span><span>小时</span><span class="bold-text"> ${m}</span><span>分</span>` : `<span class="bold-text">${h}</span><span>小时</span>`;
      } else if (m) {
        return s ? `<span class="bold-text">${m}</span><span>分</span><span class="bold-text"> ${s}</span><span>秒</span>` : `<span class="bold-text">${m}</span><span>分</span>`;
      } else if (s) {
        s = this.$moment.duration(seconds * 1000).asSeconds().toFixed(2);
        return `<span class="bold-text"> ${s}</span><span>秒</span>`;
      } else {
        return `<span class="bold-text"> 0</span><span>秒</span>`;
      }
    }
  }
};
exports.default = _default;