"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SubmenuItem = _interopRequireDefault(require("./SubmenuItem"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    SubmenuItem: _SubmenuItem.default
  },
  props: {
    allKnowledgeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    defaultActiveKey() {
      var _this$allKnowledgeLis, _this$allKnowledgeLis2;
      return String(this.allKnowledgeList.length && ((_this$allKnowledgeLis = this.allKnowledgeList[0]) === null || _this$allKnowledgeLis === void 0 ? void 0 : (_this$allKnowledgeLis2 = _this$allKnowledgeLis.children[0]) === null || _this$allKnowledgeLis2 === void 0 ? void 0 : _this$allKnowledgeLis2.id));
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$emit('acitveTabsChange', key);
    }
  }
};
exports.default = _default;