"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "edit-evaluate"
  }, [_c("header", {
    staticClass: "evaluate-header"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _setup.goBack
    }
  }), _c("h1", [_vm._v(_vm._s(_setup.title))])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "evaluate-content"
  }, [_c("div", {
    staticClass: "evaluate-form-aside"
  }, [_c("h1", {
    staticClass: "aside-title"
  }, [_vm._v("评价项")]), _c(_setup.EvaluateFormGenerateAside, {
    attrs: {
      "data-v-step": "evaluate-form-generate-aside",
      disabled: _setup.schema.fields.length >= 30,
      "disabled-tip": "最多可添加30项"
    },
    on: {
      "item-click": _setup.addFormItem
    }
  })], 1), _c("div", {
    staticClass: "evaluate-form-content"
  }, [_c("div", {
    staticClass: "content-inner"
  }, [_c("div", {
    staticClass: "form-info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v(_vm._s(_setup.editInfoText) + " ")]), _c("div", {
    staticClass: "evaluate-form-container"
  }, [_c(_setup.EvaluateFormGenerator, {
    ref: "generator",
    attrs: {
      schema: _setup.schema,
      "is-edit-status": false
    }
  })], 1)])])]), _c("footer", {
    staticClass: "evaluate-footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.goBack
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.openPreviewDialog
    }
  }, [_vm._v("预览")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.handleSaveEvaluateForm
    }
  }, [_vm._v("保存")])], 1), _c(_setup.EvaluatePreviewDialog, {
    attrs: {
      visible: _setup.previewDialogVisible,
      "no-edit-button": "",
      schema: _setup.previewSchema
    },
    on: {
      "update:visible": function ($event) {
        _setup.previewDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;