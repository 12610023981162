"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ImageTextTips',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      disabled: false
    };
  },
  computed: {
    srcList() {
      return this.list.map(item => item.url).filter(Boolean);
    }
  },
  mounted() {
    this.handleTooltipHover();
  },
  methods: {
    handleImageClick() {
      this.$nextTick(() => {
        // 关闭大图预览，tooltip也消失
        const close = document.querySelector('.el-image-viewer__close');
        close.addEventListener('click', () => {
          this.disabled = true;
        });
      });
    },
    handleTooltipHover() {
      const tooltip = this.$refs['popover'];
      tooltip.$el.addEventListener('mouseenter', () => {
        this.disabled = false;
      });
    }
  }
};
exports.default = _default;