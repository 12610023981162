"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _default = {
  name: 'ElPopover',
  extends: _elementUi.Popover,
  mounted() {
    this.$refs['popper'].addEventListener('click', () => {
      const traversePopper = nodes => {
        nodes.forEach(item => {
          const currentNode = item.$refs['popper'];
          if (currentNode) {
            item.doClose && item.doClose();
          }
          if (item.$children.length) {
            traversePopper(item.$children);
          }
        });
      };
      traversePopper(this.$children);
    }, true);
  }
};
exports.default = _default;