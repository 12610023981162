"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _default = {
  name: 'RiskRuleTable',
  components: {},
  data() {
    return {
      editRow: null,
      selectValue: '',
      loading: false,
      list: []
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await (0, _business.getDealRiskConfig)();
      if (res.code === 200) {
        this.list = res.results;
        this.loading = false;
      }
    },
    formatCondition(risk) {
      if (!risk.condition_choices) return;
      return risk.condition.replace('<>', ` ${risk.condition_value} `);
    },
    handleEdit(index, row) {
      if (!row.condition_choices) return;
      this.editRow = index;
      this.selectValue = row.condition_value;
    },
    handleSave(index, row) {
      const params = {
        id: row.id,
        value: this.selectValue
      };
      (0, _business.updateDealRiskConfig)(params);
      this.list[index].condition_value = this.selectValue;
      this.editRow = null;
    },
    handleCancle() {
      this.editRow = null;
    },
    handleSwitch(row) {
      const params = {
        id: row.id,
        is_enabled: row.is_enabled
      };
      (0, _business.setDealRiskEnable)(params);
    }
  }
};
exports.default = _default;