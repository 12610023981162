"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$dataSetDataList, _vm$dataSetDataList2, _vm$dataSetDataList3, _vm$dataSetDataList4;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-set-annotate__header"
  }, [_c("div", {
    staticClass: "data-set-annotate__header-content"
  }, [_c("div", {
    staticClass: "data-set-annotate__back",
    on: {
      click: _setup.goDataSet
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("div", {
    staticClass: "data-set-annotate__title"
  }, [_vm._v("标注")]), _c("div", {
    staticClass: "data-set-name"
  }, [_c("i", {
    staticClass: "iconfont icon-dataset-fill"
  }), _c("span", [_vm._v(_vm._s(_vm.dataSetName))])]), _c("div", {
    staticClass: "data-set-annotate__status",
    attrs: {
      "data-v-step": "annotateProgress"
    }
  }, [_setup.annotatedDataCount === ((_vm$dataSetDataList = _vm.dataSetDataList) === null || _vm$dataSetDataList === void 0 ? void 0 : _vm$dataSetDataList.length) ? _c("span", {
    staticClass: "data-set-annotate__status-label"
  }, [_c("i", {
    staticClass: "iconfont icon-dot-4"
  }), _c("span", [_vm._v("已完成")])]) : _c("span", {
    staticClass: "data-set-annotate__status-label"
  }, [_vm._v("标注中")]), _setup.annotatedDataCount !== ((_vm$dataSetDataList2 = _vm.dataSetDataList) === null || _vm$dataSetDataList2 === void 0 ? void 0 : _vm$dataSetDataList2.length) ? _c("el-progress", {
    attrs: {
      percentage: _setup.annotatedRadio || 0,
      "show-text": false,
      "stroke-width": 4
    }
  }) : _vm._e(), _setup.annotatedDataCount !== ((_vm$dataSetDataList3 = _vm.dataSetDataList) === null || _vm$dataSetDataList3 === void 0 ? void 0 : _vm$dataSetDataList3.length) ? _c("span", [_vm._v(_vm._s(_setup.annotatedRadio) + "%")]) : _vm._e(), _c("span", [_vm._v(_vm._s(_setup.annotatedDataCount) + "/" + _vm._s((_vm$dataSetDataList4 = _vm.dataSetDataList) === null || _vm$dataSetDataList4 === void 0 ? void 0 : _vm$dataSetDataList4.length))])], 1)]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: _setup.openAnnotateResult
    }
  }, [_vm._v("数据集标注详情")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;