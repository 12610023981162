"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HitTag = _interopRequireDefault(require("./HitTag.vue"));
var _HitButtons = _interopRequireDefault(require("./HitButtons.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventResultTable',
  components: {
    HitTag: _HitTag.default,
    HitButtons: _HitButtons.default
  },
  props: {
    eventName: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasOperate: {
      type: Boolean,
      default: true
    },
    headerTargetCounts: {
      type: Object,
      default: () => {
        return {
          event_count: 0,
          // 事件数量
          conv_count: 0,
          // 会话数量
          predict_hit_count: 0,
          // AI命中数量
          predict_miss_count: 0,
          // AI未命中数量
          annotate_hit_count: 0,
          // 人工命中数量
          annotate_miss_count: 0,
          // 人工未命中数量
          annotate_uncertain_count: 0 // 人工不确定数量
        };
      }
    },

    tableHeight: {
      type: [Number, String],
      default: ''
    },
    datasetId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isRightObj: {
        1: '正确',
        2: '错误',
        3: '未知'
      }
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val);
    },
    onChangeAnnotationTag(val, row) {
      this.$emit('changeTestAnnotation', val, row);
    },
    handleFixTrainData(row) {
      this.$emit('fixTrainData', row);
    },
    clickModelSentence(row) {
      this.$emit('clickModelSentence', row);
    }
  }
};
exports.default = _default;