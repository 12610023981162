"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailInfo = _interopRequireDefault(require("./components/DetailInfo"));
var _DetailOperation = _interopRequireDefault(require("./components/DetailOperation"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DetailDrawer',
  components: {
    DetailInfo: _DetailInfo.default,
    DetailOperation: _DetailOperation.default
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    operateButton: {
      type: Array
    }
  },
  methods: {
    detailOperations(action) {
      this.$emit('detailOperations', action);
    }
  }
};
exports.default = _default;