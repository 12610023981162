"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CoachDetailList = _interopRequireDefault(require("./CoachDetailList"));
var _ConversationList = _interopRequireDefault(require("./ConversationList"));
var _CoachRecordList = _interopRequireDefault(require("./CoachRecordList"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachDetailDrawer',
  components: {
    CoachDetailList: _CoachDetailList.default,
    CoachRecordList: _CoachRecordList.default,
    ConversationList: _ConversationList.default
  },
  props: {
    visible: Boolean,
    user: {
      type: Object,
      default: () => ({
        id: 0,
        name: ''
      })
    },
    coachDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      activeTab: 'todoCoach',
      todoCount: 0,
      coachedCount: 0
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.loading = true;
      } else {
        [this.todoCount, this.coachedCount] = [0, 0];
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDrawer');
    },
    goConvList() {
      this.$router.push({
        path: _routeMap.ROUTES.conversation,
        query: this.user
      });
    },
    getTodoCount(val) {
      this.todoCount = val;
    },
    getCoachedCount(val) {
      this.coachedCount = val;
    },
    handleTodoCoachRequestSucceed() {
      this.activeTab === 'todoCoach' && (this.loading = false);
    },
    handleCoachRecordRequestSucceed() {
      this.activeTab === 'coachRecord' && (this.loading = false);
    },
    handleConversationsRequestSucceed() {
      this.activeTab === 'conversations' && (this.loading = false);
    }
  }
};
exports.default = _default;