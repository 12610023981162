"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "chart-config",
    attrs: {
      title: _vm.isEditChart ? "编辑图表" : "添加图表",
      visible: _vm.chartConfigVisible,
      "close-on-click-modal": false,
      width: "600px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.chartConfigVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chartConfigLoading,
      expression: "chartConfigLoading"
    }],
    ref: "chartConfigForm",
    attrs: {
      model: _vm.chartConfig
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "图表名称"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      placeholder: "请输入图表名称"
    },
    model: {
      value: _vm.chartConfig.chart_name,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig, "chart_name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "chartConfig.chart_name"
    }
  })], 1), _c("p", {
    staticClass: "chart-type-title"
  }, [_vm._v("图表类型")]), _c("div", {
    staticClass: "chart-type-box"
  }, [_vm._l(_vm.chartTypeList, function (item) {
    return [_c("el-popover", {
      key: item.type,
      attrs: {
        "popper-class": "customer-base-profile-chart-config",
        placement: "top",
        width: "200",
        "visible-arrow": false,
        trigger: "hover"
      }
    }, [_c("ul", _vm._l(item.tooltip, function (tooltip) {
      return _c("li", {
        key: tooltip
      }, [_vm._v(" " + _vm._s(tooltip) + " ")]);
    }), 0), _c("div", {
      staticClass: "chart-type-item",
      class: {
        active: _vm.chartConfig.type === item.type
      },
      attrs: {
        slot: "reference"
      },
      on: {
        click: function ($event) {
          return _vm.changeChartType(item.type);
        }
      },
      slot: "reference"
    }, [_c("img", {
      attrs: {
        src: item.imgSrc
      }
    }), _c("span", [_vm._v(_vm._s(item.label))])])])];
  })], 2), _c("lable-require-tooltip-remark", {
    attrs: {
      config: {
        label: "分析指标",
        require: true
      }
    }
  }), _c("el-form-item", {
    staticClass: "form-metrics-item"
  }, [_c("div", {
    staticClass: "metrics-item"
  }, [_c("el-form-item", {
    attrs: {
      prop: "field.type",
      rules: [{
        required: true,
        message: "请选择指标类型",
        trigger: "change"
      }]
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择指标类型"
    },
    on: {
      change: _vm.changeChartConfigType
    },
    model: {
      value: _vm.chartConfig.field.type,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.field, "type", $$v);
      },
      expression: "chartConfig.field.type"
    }
  }, _vm._l(_vm.metricsTypeList, function (item) {
    return _c("el-option", {
      key: item.type,
      attrs: {
        label: item.label,
        value: item.type
      }
    });
  }), 1)], 1), _vm.chartConfig.field.type ? _c("el-form-item", {
    attrs: {
      prop: "field.indicator",
      rules: [{
        required: true,
        message: "请选择指标名",
        trigger: "change"
      }]
    }
  }, [_vm.chartConfig.field.type === "deal_indicator" ? _c("el-select", {
    attrs: {
      placeholder: "请选择指标名"
    },
    model: {
      value: _vm.chartConfig.field.indicator,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.field, "indicator", $$v);
      },
      expression: "chartConfig.field.indicator"
    }
  }, _vm._l(_vm.customFiled, function (item) {
    return _c("el-option", {
      key: item.field_name,
      attrs: {
        label: item.name,
        value: item.field_name
      }
    });
  }), 1) : _c("el-cascader", {
    key: _vm.resetCascaderKey,
    ref: "key-event-cascader",
    attrs: {
      "show-all-levels": false,
      options: _vm.cascaderOptions(_vm.chartConfig.field.type),
      "collapse-tags": "",
      props: {
        expandTrigger: "hover",
        label: "name",
        value: "id",
        emitPath: false,
        multiple: true
      }
    },
    model: {
      value: _vm.chartConfig.field.indicator,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.field, "indicator", $$v);
      },
      expression: "chartConfig.field.indicator"
    }
  })], 1) : _vm._e()], 1)])], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;