import { render, staticRenderFns } from "./UploadFileList.vue?vue&type=template&id=632b87c3&scoped=true&"
import script from "./UploadFileList.vue?vue&type=script&setup=true&lang=js&"
export * from "./UploadFileList.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./UploadFileList.vue?vue&type=style&index=0&id=632b87c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632b87c3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('632b87c3')) {
      api.createRecord('632b87c3', component.options)
    } else {
      api.reload('632b87c3', component.options)
    }
    module.hot.accept("./UploadFileList.vue?vue&type=template&id=632b87c3&scoped=true&", function () {
      api.rerender('632b87c3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/conversationPage/uploadConversation/UploadFileList.vue"
export default component.exports