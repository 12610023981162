"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SelectCustomTag',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    remoteParams: {
      type: Object,
      default: () => ({})
    },
    remoteMethod: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selected: '',
      options: [],
      loading: false
    };
  },
  watch: {
    value(val) {
      this.selected = val;
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('handleChange', val);
    },
    async getOptions(filter) {
      if (!filter) return;
      this.loading = true;
      const res = await this.remoteMethod({
        ...this.remoteParams,
        filter
      });
      this.loading = false;
      if (res.code === 200) {
        // 必须要转参数名，否则保存的筛选器回填就有问题
        this.options = res.results.map(_ref => {
          let {
            name: label,
            id
          } = _ref;
          return {
            label,
            id
          };
        });
      }
    }
  }
};
exports.default = _default;