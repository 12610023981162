"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: "我是标题",
      visible: _vm.eventKeyDrawerVisible,
      "before-close": _vm.closeDrawer
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "drawer-title"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isKeyEventDetail,
      expression: "isKeyEventDetail"
    }],
    staticClass: "go-back-btn",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  }), _c("span", [_vm._v("返回")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  })], 1), _c("span", {
    staticClass: "key-name"
  }, [_vm._v(_vm._s(_vm.drawerTitle))])])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "event-key-drawer-content"
  }, [!_vm.isKeyEventDetail ? _c("events-list", {
    attrs: {
      "event-engine": _vm.eventEngine
    },
    on: {
      tagClick: _vm.handleTagClick
    }
  }) : _vm._e(), _vm.isKeyEventDetail ? _c("key-event-detail-list", {
    ref: "key-event-detail-list",
    attrs: {
      "media-type": _vm.mediaType,
      list: _vm.activeEventData,
      participants: _vm.participants
    },
    on: {
      clickToDetail: _vm.keyEventToDetail
    }
  }) : _vm._e()], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;