"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-conversation-form"
  }, [_c("div", {
    staticClass: "upload-conversation-form__title"
  }, [_vm._v("完善会话信息")]), _c("el-form", {
    ref: "uploadMediaFormRef",
    staticClass: "upload-conversation-form__content",
    attrs: {
      rules: _setup.rules,
      model: _setup.form,
      "label-width": "80px",
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "会话归属人",
      prop: "host_salesman_id"
    }
  }, [_c(_setup.MembersFinder, {
    ref: "membersFinderRef",
    attrs: {
      placeholder: "搜索成员",
      "is-radio": true,
      "get-member-api-type": "data",
      "has-self": false,
      params: {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.store.getters.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleMemberChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "会话时间",
      prop: "begin_time"
    }
  }, [_c("el-date-picker", {
    attrs: {
      "picker-options": {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      type: "datetime",
      placeholder: "请选择日期",
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _setup.form.begin_time,
      callback: function ($$v) {
        _vm.$set(_setup.form, "begin_time", $$v);
      },
      expression: "form.begin_time"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "关联联系人",
      prop: "independent_contact_id"
    }
  }, [_c(_setup.ConnectedContact, {
    on: {
      contactChange: _setup.handleIndependentContactChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "关联交易",
      prop: "independent_trade_id"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "请先关联联系人",
      placement: "top",
      disabled: !!_setup.form.independent_contact_id
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "搜索交易名称",
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _setup.searchTradeApi,
      disabled: !_setup.form.independent_contact_id
    },
    on: {
      focus: _setup.searchTradeApi
    },
    model: {
      value: _setup.form.independent_trade_id,
      callback: function ($$v) {
        _vm.$set(_setup.form, "independent_trade_id", $$v);
      },
      expression: "form.independent_trade_id"
    }
  }, _vm._l(_setup.independentTradeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c(_setup.DynamicForm, {
    ref: "conversationDynamicFormRef",
    attrs: {
      desc: _setup.conversationDynamicFieldsDesc,
      "label-position": "top"
    },
    model: {
      value: _setup.conversationDynamicForm,
      callback: function ($$v) {
        _setup.conversationDynamicForm = $$v;
      },
      expression: "conversationDynamicForm"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;