"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotateSentence = useAnnotateSentence;
exports.useConvTextVirtualList = useConvTextVirtualList;
exports.useCreateAnnotate = useCreateAnnotate;
exports.useLocateSentence = useLocateSentence;
var _vue = require("vue");
function useConvTextVirtualList(store) {
  const sliceStartIndex = (0, _vue.ref)(0);
  const sliceEndIndex = (0, _vue.ref)(30);
  const contentWrapperRef = (0, _vue.ref)(null);
  const updateSliceSentence = order => {
    if (order < sliceStartIndex.value || order > sliceEndIndex.value) {
      sliceStartIndex.value = order - 5;
      sliceEndIndex.value = order + 20;
    }
  };
  const _sliceStartIndex = (0, _vue.computed)(() => {
    return sliceStartIndex.value < 0 ? 0 : sliceStartIndex.value;
  });
  const sliceSentence = (0, _vue.computed)(() => {
    var _store$getters$audio;
    return (_store$getters$audio = store.getters.audio) === null || _store$getters$audio === void 0 ? void 0 : _store$getters$audio.slice(_sliceStartIndex.value, sliceEndIndex.value);
  });
  const loadMoreSentence = async e => {
    // 上拉加载
    if (!sliceSentence.value || !sliceSentence.value.length) return;
    if (e.target.scrollTop === 0) {
      const order = sliceSentence.value[0].order;
      sliceStartIndex.value -= 15;
      // 保证当前句子视觉位置不变
      await (0, _vue.nextTick)();
      const oCurrentOrder = document.querySelector(`#sentence-item${order}`);
      contentWrapperRef.value.scroll({
        top: oCurrentOrder.offsetTop
      });
      // 下拉加载
    } else if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 150) {
      sliceEndIndex.value += 15;
    }
  };
  const handleMouseWheel = () => {
    store.commit('video/SET_IS_USER_SCROLL', true);
  };
  const handleMouseScroll = (e, className) => {
    const isDialogWrapper = e.target.className === className;
    if (isDialogWrapper) {
      // 先判断鼠标点击元素是否是class为"dialog-wrapper"的滚动元素
      const wrapperWidth = e.target.clientWidth;
      const mouseOffsetX = e.offsetX;
      const isInScrollBar = wrapperWidth - mouseOffsetX <= 14; // mac的滚动条宽度是14

      if (isInScrollBar) {
        // 以上主要是判断用户是否点击的是滚动条区域
        handleMouseWheel();
      }
    }
  };
  const handleScroll = e => {
    loadMoreSentence(e);
  };
  return {
    handleMouseWheel,
    handleScroll,
    handleMouseScroll,
    sliceSentence,
    contentWrapperRef,
    updateSliceSentence
  };
}
function useAnnotateSentence() {
  const selectedSentenceOrderArr = (0, _vue.ref)([]);
  const clearSentenceOrderArr = () => {
    selectedSentenceOrderArr.value = [];
  };
  return {
    selectedSentenceOrderArr,
    clearSentenceOrderArr
  };
}
function useLocateSentence(store) {
  const locateSentence = sentence => {
    store.dispatch('video/change_begin', {
      begin_time: sentence.begin_time,
      time: new Date().getTime()
    });
    store.dispatch('video/set_cur_order', {
      order: sentence.order,
      time: new Date().getTime()
    });
  };
  return {
    locateSentence
  };
}
function useCreateAnnotate(_ref) {
  let {
    store,
    props,
    selectedSentenceOrderArr,
    clearSentenceOrderArr
  } = _ref;
  const addKeyEventTrackerPopoverRef = (0, _vue.ref)(null);
  const showAddKeyEventTrackerPopover = (0, _vue.ref)(false);
  const contWrapRefs = [];
  const setContWrapRefs = el => {
    if (el) {
      contWrapRefs.push(el);
    }
  };
  const setAddKeyEventTrackerPopoverOffset = async order => {
    await (0, _vue.nextTick)();
    const ele = addKeyEventTrackerPopoverRef.value;
    if (ele.$el.dataset.hasUserDrag === '1') {
      return;
    }
    const sentenceEle = contWrapRefs[order];
    if (!sentenceEle) return;
    const {
      left,
      top,
      width,
      right
    } = sentenceEle.getBoundingClientRect();
    const contentEle = sentenceEle.querySelector('.content');
    const {
      width: contentWidth
    } = contentEle.getBoundingClientRect();
    if (sentenceEle.className.split(' ').includes('reverse')) {
      const offsetY = top + 36;
      ele.$el.style.top = offsetY + 'px';
      if (right - contentWidth + 400 > window.innerWidth) {
        ele.$el.style.left = window.innerWidth - 400 + 'px';
      } else {
        ele.$el.style.left = right - contentWidth - 132 - 10 + 'px';
      }
    } else {
      const offsetY = top + 36;
      ele.$el.style.top = offsetY + 'px';
      ele.$el.style.left = left + width - 132 + 'px';
    }
  };
  const getLastSelectedAnnotateLabel = () => {
    const userId = store.getters.userId;
    const orgId = store.getters.orgId;
    const annotateLabelStr = localStorage.getItem(`${orgId}-${userId}-annotate-selected-label`);
    if (annotateLabelStr) {
      const annotateLabelArr = JSON.parse(annotateLabelStr);
      const res = props.dataSetLabelList.find(item => item.id === annotateLabelArr[0]);
      if (res) {
        if (annotateLabelArr) return annotateLabelArr.map(item => [item]);
      }
    }
    return '';
  };
  const addKeyEventTracker = item => {
    selectedSentenceOrderArr.value.push(item.order);
    showAddKeyEventTrackerPopover.value = true;
    const lastSelectedAnnotateLabelArr = getLastSelectedAnnotateLabel();
    addKeyEventTrackerPopoverRef.value.changeSelectedEventId(lastSelectedAnnotateLabelArr);
    setAddKeyEventTrackerPopoverOffset(item.order);
  };
  const handleCancelAddKeyEventTracker = async () => {
    clearSentenceOrderArr();
    showAddKeyEventTrackerPopover.value = false;
    await (0, _vue.nextTick)();
    const ele = addKeyEventTrackerPopoverRef.value;
    ele.$el.dataset.hasUserDrag = '0';
    ele.selectEventId = 0;
  };
  return {
    addKeyEventTrackerPopoverRef,
    showAddKeyEventTrackerPopover,
    setContWrapRefs,
    addKeyEventTracker,
    handleCancelAddKeyEventTracker,
    setAddKeyEventTrackerPopoverOffset
  };
}