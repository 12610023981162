"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _doubleCall = require("@/api/doubleCall");
var _clues = require("@/api/clues");
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _HeaderFilter = _interopRequireDefault(require("./components/HeaderFilter"));
var _ClueTable = _interopRequireDefault(require("./components/ClueTable"));
var _CluesAllotDialog = _interopRequireDefault(require("@/components/CluesAllotDialog"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Clues',
  components: {
    HeaderFilters: _HeaderFilter.default,
    DynamicPopover: _DynamicPopover.default,
    ClueTable: _ClueTable.default,
    CluesAllotDialog: _CluesAllotDialog.default
  },
  data() {
    return {
      // leadIds: [],
      tableLoading: false,
      list: null,
      headerList: null,
      listParams: {
        // 客户表格的分页、筛选
        page_at: 1,
        page_size: 10,
        search_val: '',
        status: '',
        only_my_leads: 0,
        sort_column: 'update_at',
        sort_order: 'desceding'
      },
      total: 0,
      clueMultipleVal: null,
      // 批量操作选取的客户的信息  客户批量操作
      allotDialogVisible: false,
      allotDialogProps: {},
      // 双呼按钮的权限
      doubleCallPermission: 0,
      sortColumnList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'api_permissions'])
  },
  mounted() {
    if (this.has_outbound) {
      this.getDoubleCallPermission();
    }
    this.$bus.$on('leadUpdateList', this.freshCurrentList);
  },
  created() {
    this.getClueSortColumn();
  },
  beforeDestroy() {
    this.$bus.$off('leadUpdateList');
  },
  methods: {
    async getClueSortColumn() {
      const res = await (0, _clues.getClueSortColumn)();
      if (res.code === 20000) {
        this.sortColumnList = res.results.sort_fields;
      }
    },
    getTableHead(type, val) {
      if (type === 'clue') {
        this.headerList = val;
        this.getClueLists(this.listParams);
      }
    },
    async getDoubleCallPermission() {
      const res = await (0, _doubleCall.getDoubleCallPermission)();
      if (res.code === 200) {
        this.doubleCallPermission = res.results.double_call;
      }
    },
    goClueDetail(id) {
      this.$router.push({
        path: _routeMap.ROUTES.leadDetail,
        query: {
          id
        }
      });
    },
    // 新建线索成功后添加至第一项
    createClueSuccess(data) {
      data.highlight = true;
      this.list.unshift(data);
    },
    // 变更数据成功之后刷新页面
    freshCurrentList() {
      if (this.$refs['clueFilter'].hasSearchFilterOption) {
        this.$refs['clueFilter'].initDefaultValue();
      } else {
        this.initListParams();
        this.getClueLists(this.listParams);
      }
    },
    // 添加新建线索后刷新页面并重置当前页面未首页
    initListParams() {
      this.listParams.page_at = 1;
    },
    // 客户分页数量变化
    sizeChange(val) {
      this.listParams.page_size = val;
      this.listParams.page_at = 1;
      this.getClueLists(this.listParams);
    },
    // 线索当前页数发生变化
    currentChange(val) {
      const maxPage = 10000 / this.listParams.page_size;
      this.listParams.page_at = this.listParams.page_size * val > 10000 ? maxPage : val;
      this.getClueLists(this.listParams);
    },
    // 线索的筛选项发生变化
    headerFiltersChange(params) {
      this.listParams.status = params.status;
      this.listParams.only_my_leads = params.only_my_leads;
      this.listParams.search_val = params.search_val;
      this.listParams.page_at = 1;
      this.getClueLists(this.listParams);
    },
    // 获取线索列表
    async getClueLists(data) {
      this.tableLoading = true;
      const res = await (0, _clues.getClueList)(data);
      if (res.code === 20000) {
        this.list = res.results.items;
        this.total = res.results.items_total;
      }
      this.tableLoading = false;
    },
    changeFollowerAction(action) {
      // action是HeaderFilter组件点击变更跟进人按钮时emit的字符串 ‘changeFollower’
      const clueArray = [];
      if (this.clueMultipleVal) {
        this.clueMultipleVal.forEach(item => {
          clueArray.push(item.id);
        });
        if (action) {
          this.$refs['clueTable'].changeFollowUpBy(clueArray);
        }
      }
    },
    clueSelectionChange(val) {
      this.clueMultipleVal = val;
    },
    // 分配或者更改跟进人
    allocateToAnyone(ids, type) {
      this.allotDialogProps = {
        type,
        leadIds: ids
      };
      this.allotDialogVisible = true;
    },
    // 线索转客户成功之后刷新列表
    clueTranslateAccountSuccess() {
      this.freshCurrentList();
    },
    refreshFollowerData() {
      this.freshCurrentList();
    },
    getClueTableHead() {
      return _clues.getClueTableHead;
    },
    resetClueHead() {
      return _clues.resetClueHead;
    },
    handleSortOrder(prop, order) {
      this.listParams.page_at = 1;
      this.listParams.sort_column = order === 'default' ? '' : prop;
      this.listParams.sort_order = order;
      this.getClueLists(this.listParams);
    }
  }
};
exports.default = _default;