"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-operate-button"
  }, [_vm._l(_vm.operateButtons, function (item, index) {
    return [item.operateType === "createMeeting" && item.isShow ? _c("meeting-button", {
      key: index,
      attrs: {
        "popper-show": item.trade_id === _vm.activeId,
        "custom-class": _vm.classObject(_vm.rowInfo, item)
      },
      on: {
        click: function ($event) {
          return _vm.clickOperateBtn(_vm.rowInfo, item, $event);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.text))])]) : _c("el-popover", {
      key: index,
      attrs: {
        placement: "bottom",
        width: "250",
        trigger: "manual",
        value: _vm.getIsShowPopover(_vm.rowInfo, item),
        "popper-class": "drop-call-pop"
      }
    }, [_vm.getIsShowPopover(_vm.rowInfo, item) ? _c("contact-person-list", {
      ref: "contactListcallout",
      refInFor: true,
      staticClass: "contact-list-comp",
      attrs: {
        "is-show-tel": ["callout", "doubleCall"].includes(item.operateType),
        "contact-list": _vm.contactList
      },
      on: {
        clickContactPersonItem: function ($event) {
          return _vm.clickContactPersonItem($event, _vm.rowInfo, item.operateType);
        }
      }
    }) : _vm._e(), _c("template", {
      slot: "reference"
    }, [_vm._t("clickBtn", function () {
      return [item.text ? _c("span", {
        directives: [{
          name: "preventReClick",
          rawName: "v-preventReClick"
        }],
        class: ["operate-item", _vm.classObject(_vm.rowInfo, item)],
        attrs: {
          slot: "reference"
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
            return _vm.clickOperateBtn(_vm.rowInfo, item);
          }
        },
        slot: "reference"
      }, [_vm._v(_vm._s(item.text))]) : _vm._e()];
    })], 2)], 2)];
  }), _c("associate-contact-dialog", {
    ref: "associateContactDialog",
    attrs: {
      "deal-id": _vm.rowInfo.trade_id,
      "associate-contact-dialog-visible": _vm.associateContactDialogVisible,
      "contact-list": _vm.clientContactList
    },
    on: {
      closeDialog: function ($event) {
        _vm.associateContactDialogVisible = false;
      }
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;