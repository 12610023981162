"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TradeTips = _interopRequireDefault(require("./components/TradeTips.vue"));
var _HeaderFilters = _interopRequireDefault(require("./components/HeaderFilters"));
var _CreateTrade = _interopRequireDefault(require("./components/CreateTrade"));
var _Kanban = _interopRequireDefault(require("./components/Kanban"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _tradeTable = _interopRequireDefault(require("./tradeTable"));
var _OtherPageCarryingFilter = _interopRequireDefault(require("@/views/business/components/OtherPageCarryingFilter"));
var _TableExport = _interopRequireDefault(require("@/components/TableExport.vue"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
var _doubleCall = require("@/api/doubleCall");
var _trade = require("@/api/trade");
var _workspace = require("@/api/workspace");
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
var _performanceTracking = _interopRequireDefault(require("@/utils/performance-tracking"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeOverview',
  components: {
    TradeTips: _TradeTips.default,
    HeaderFilters: _HeaderFilters.default,
    createTrade: _CreateTrade.default,
    Kanban: _Kanban.default,
    SpaceButton: _SpaceButton.default,
    DynamicPopover: _DynamicPopover.default,
    TradeTable: _tradeTable.default,
    TableExport: _TableExport.default,
    MgPagination: _MgPagination.default,
    OtherPageCarryingFilter: _OtherPageCarryingFilter.default
  },
  mixins: [_cancelTokenMixin.default],
  data() {
    return {
      headerList: [],
      kanbanList: [],
      activeKanban: {},
      list: null,
      table: null,
      trade_ids: [],
      listParams: {
        // 表格的分页、筛选、排序
        filter_params: {},
        page: 1,
        size: 10,
        workspace_id: 0,
        sort_by: [],
        //  排序如:[['speak_time','ascending'],['risk','descending']]
        filter_id: 0,
        filter_fields: []
      },
      total: 0,
      amount: 0,
      doubleCallPermission: 0,
      customFilterObject: [],
      keyEvents: [],
      tableLoading: false,
      // 表格的loading
      tableHeight: document.body.clientHeight - 156,
      earliestTime: '',
      // 用于设置跟进动态时间线
      sortColumnList: [],
      // 可排序的列
      otherPageCarryingFilter: {},
      // 其他页面跳转商机携带的交易参数
      currentListParams: {},
      originTradeAmount: [],
      // 商机原始金额,
      scoreRuleId: '',
      // 交易评分跳转过来时携带的id
      highlightColumnId: 0,
      headAllList: [] // 列设置中的完整列表
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'webRoutes', 'workspaceInfo', 'timezone', 'orgId']),
    hasTradeSetPermission() {
      const hasTradeScorePermission = this.webRoutes.find(item => item.name === 'TradeSetScore');
      const hasTradeRiskRulesPermission = this.webRoutes.find(item => item.name === 'TradeSetRiskRule');
      return hasTradeScorePermission || hasTradeRiskRulesPermission;
    },
    exportRequestParams() {
      return {
        body: this.currentListParams,
        filter_id: this.activeKanban.id,
        module: 'trade_list',
        head_field: this.headerList.map(item => item.field_name)
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        this.tableLoading = true;
        this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', true);
        this.activeKanban = {};
        this.getTradeFilterList();
        this.initData();
      }
    },
    $route: {
      handler: function (val) {
        const workspaceId = val.query.workspace_id;
        if (workspaceId !== undefined) {
          (0, _workspace.switchWorkspace)({
            id: parseInt(workspaceId)
          }).then(res => {
            const workspaceItem = res.results;
            this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
            sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
            this.$router.replace({
              path: _routeMap.ROUTES.trade
            });
          });
        }
      },
      immediate: true
    }
  },
  async created() {
    this.handleTradeScoreToTrade();
    const globalPageSize = localStorage.getItem('globalPageSize');
    if (globalPageSize) {
      const parsedPageSize = JSON.parse(globalPageSize);
      const routePageSize = parsedPageSize[this.$route.name] || 10;
      this.listParams.size = routePageSize;
    }
    this.getOtherPageCarryingFilter();
    this.initData();
    await this.getTradeFilterList();
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
    if (this.has_outbound) {
      // 有外呼才可以有双呼
      this.getDoubleCallPermission();
    }
  },
  methods: {
    // 列设置 确定并保存
    confirmAndSaveTableHead(list) {
      this.headAllList = list;
      this.handleTableHead(list);
      this.handleSave();
    },
    // 列设置 确定
    updateTableHead(postData, list) {
      this.handleTableHead(list);
      this.getList();
    },
    handleTableHead(list) {
      this.headerList = list.filter(item => item.is_activate);
      // 交易列表接口增加filter_fields字段
      this.listParams.filter_fields = this.headerList.map(item => item.field_name);
    },
    highlightColumn(id) {
      this.highlightColumnId = id;
    },
    recursionGet: _commonFunc.recursionGet,
    // 拖拽看板更新外面的kanbanList
    changeTradeViewListPosition(val) {
      this.kanbanList = val;
    },
    handleTradeScoreToTrade() {
      var _this$$route, _this$$route$query, _this$$route2, _this$$route2$query;
      this.scoreRuleId = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query = _this$$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.score_rule_id;
      this.scoreRuleName = (_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : (_this$$route2$query = _this$$route2.query) === null || _this$$route2$query === void 0 ? void 0 : _this$$route2$query.score_rule_name;
      if (this.scoreRuleId) {
        window.history.pushState(null, null, this.$route.path);
      }
    },
    handleOtherPageFilterCallback(listParams) {
      // 会话页面/BI业务转化卡点分析页面跳转参数
      const {
        tradeIds,
        userId
      } = this.otherPageCarryingFilter;
      if (tradeIds) {
        listParams.filter_params.trade_ids = tradeIds;
      }
      // BI个人分析页面跳转参数
      if (userId) {
        if (listParams.filter_params.user_ids) {
          var _listParams$filter_pa;
          (_listParams$filter_pa = listParams.filter_params.user_ids.user_ids) === null || _listParams$filter_pa === void 0 ? void 0 : _listParams$filter_pa.push(userId);
        } else {
          this.$set(listParams.filter_params, 'user_ids', {
            user_ids: [userId]
          });
        }
      }
      return tradeIds || userId;
    },
    handleClearOtherPageFilter() {
      this.otherPageCarryingFilter = {};
      this.listParams.page = 1;
      this.getList(true);
    },
    getOtherPageCarryingFilter() {
      // 获取其他页面跳转交易页携带的商机id和label
      const otherPageCarryingFilter = JSON.parse(sessionStorage.getItem('otherPageCarryingFilter'));
      if (otherPageCarryingFilter) {
        this.otherPageCarryingFilter = otherPageCarryingFilter;
      }
      sessionStorage.removeItem('otherPageCarryingFilter');
    },
    // 保存
    handelSaveCommand(command) {
      if (command === 'isSaveAsNew') {
        this.handleSave(true);
      }
    },
    // 保存全部筛选器
    async handleSave() {
      let isSaveAsNew = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const {
        id,
        ...other
      } = this.activeKanban;
      const localFilterData = (0, _commonFunc.deepClone)(this.listParams.filter_params);
      (0, _timezone.convertParams2UTCplus8)(localFilterData);
      const data = {
        filter_id: id,
        ...other,
        filter_config: localFilterData,
        head_conf: this.headAllList
      };
      data.name = '';
      if (isSaveAsNew) {
        var _this$$refs$kanban;
        (_this$$refs$kanban = this.$refs.kanban) === null || _this$$refs$kanban === void 0 ? void 0 : _this$$refs$kanban.handleCopyView(data);
      } else {
        const res = await (0, _trade.updateTradeView)(data);
        if (res.code === 20000) {
          this.$message.success('已将当前筛选项、排序、列设置保存到视图中');
          this.initData();
        }
      }
    },
    createNewKanban() {
      this.initData('create').then(() => {
        var _this$$refs$kanban2;
        (_this$$refs$kanban2 = this.$refs.kanban) === null || _this$$refs$kanban2 === void 0 ? void 0 : _this$$refs$kanban2.ScrollToEnd();
      });
    },
    // 切换看板重新请求看板视图数据
    handleKanbanChange(item) {
      if (item.id === this.activeKanban.id) return;
      this.activeKanban = item;
      this.tableLoading = true;
      this.listParams.filter_fields = [];
      this.handleViewChange();
    },
    async initData(type) {
      var _this$workspaceInfo;
      this.tableLoading = true;
      if (!((_this$workspaceInfo = this.workspaceInfo) !== null && _this$workspaceInfo !== void 0 && _this$workspaceInfo.id)) return;
      const res = await (0, _trade.getTradeViewList)({
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      });
      if (res.code === 20000) {
        const list = res.results.view_list;
        list.forEach(kanban => {
          const filterConfig = (0, _commonFunc.deepClone)(kanban.filter_config);
          (0, _timezone.convertUTCplus82CurrentZone)(filterConfig);
          kanban.filter_config = filterConfig;
        });
        this.kanbanList = list;
      }
      if (type === 'create') {
        this.activeKanban = this.kanbanList[this.kanbanList.length - 1];
      }
      if (!this.activeKanban.id) {
        this.activeKanban = this.kanbanList[0];
      } else {
        this.activeKanban = this.kanbanList.find(item => {
          var _this$activeKanban;
          return item.id === ((_this$activeKanban = this.activeKanban) === null || _this$activeKanban === void 0 ? void 0 : _this$activeKanban.id);
        });
      }
      await this.handleViewChange();
      const {
        tradeIds,
        userId
      } = this.otherPageCarryingFilter;
      await this.getTradeAmount(!(tradeIds || userId));
    },
    async handleViewChange() {
      this.setTradeAmount();
      const config = JSON.parse(JSON.stringify(this.activeKanban.filter_config));
      // 处理user_ids格式，对象数组转id数组
      if (config && config.user_ids) {
        config.user_ids = this.handleUserValue(config.user_ids);
      }
      this.listParams.filter_params = config;
      this.listParams.page = 1;
      this.listParams.filter_id = this.activeKanban.id;
      await this.getActiveViewSortInfo(this.activeKanban.id);
      await this.getActiveViewHeadList(this.activeKanban.id);
      this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', false);
    },
    handleUserValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    async getActiveViewSortInfo(viewId) {
      const res = await (0, _trade.getViewSortInfo)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 20000) {
        this.sortColumnList = res.results.sort_field;
        const sortList = res.results.sort_field.filter(item => !!item.value);
        if (sortList.length) {
          var _this$$refs$tradeTabl;
          (_this$$refs$tradeTabl = this.$refs['tradeTable']) === null || _this$$refs$tradeTabl === void 0 ? void 0 : _this$$refs$tradeTabl.sortChange({
            prop: sortList[0].field_name,
            order: sortList[0].value
          });
        }
      }
    },
    async getActiveViewHeadList(viewId) {
      const res = await (0, _trade.getViewHeadList)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 20000) {
        this.$set(this.activeKanban, 'head_conf', res.results.fields);
        this.handleTableHead(res.results.fields);
        this.handleAddColumnSortAttr();
      }
    },
    handleAddColumnSortAttr() {
      this.sortColumnList.forEach(column => {
        this.activeKanban['head_conf'].forEach(head => {
          if (column.field_name === head.field_name) {
            head.sortable = 'custom';
          }
        });
      });
    },
    // 获取自定义的筛选项
    async getTradeFilterList() {
      const res = await (0, _trade.getTradeFilterList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.customFilterObject = res.results.filter_list;
        this.$nextTick(() => {
          this.setTableHeight();
        });
      }
    },
    async getDoubleCallPermission() {
      const res = await (0, _doubleCall.getDoubleCallPermission)();
      if (res.code === 200) {
        this.doubleCallPermission = res.results.double_call;
      }
    },
    async getList(isFilterChange) {
      this.tableLoading = true;
      this.axiosCancelRequest();
      this.newCancelToken();
      const listParams = (0, _commonFunc.deepClone)(this.listParams);
      listParams.workspace_id = this.workspaceInfo.id;
      listParams.time_zone = this.timezone;
      // 只针对 244 团队，增加一个参数 keyEventName
      if (this.orgId === 244) {
        const {
          keyEventCascaderList
        } = listParams.filter_params;
        listParams.keyEventName = keyEventCascaderList !== null && keyEventCascaderList !== void 0 && keyEventCascaderList.length ? keyEventCascaderList[0].keyEventName.join(',') : '';
      }
      // 处理其他页面跳转交易时的筛选字段
      const isHandledOtherPageFilter = this.handleOtherPageFilterCallback(listParams);
      const isNeedToSetAmount = isFilterChange || isHandledOtherPageFilter;
      (0, _timezone.convertParams2UTCplus8)(listParams.filter_params);
      this.currentListParams = listParams;
      const res = await (0, _trade.getTradeList)(listParams, {
        cancelToken: this.axiosCancelToken
      });
      this.tableLoading = true;
      if (res.code === 20000) {
        const {
          trade_list,
          table,
          total,
          total_amount
        } = res.results;
        [this.list, this.table, this.total, this.amount] = [trade_list, table, total, total_amount];
        if (isNeedToSetAmount) {
          // 看板信息金额和总数随着筛选项变化
          this.$set(this.kanbanList.find(item => {
            var _this$activeKanban2;
            return item.id === ((_this$activeKanban2 = this.activeKanban) === null || _this$activeKanban2 === void 0 ? void 0 : _this$activeKanban2.id);
          }), 'amount', total_amount);
          this.$set(this.kanbanList.find(item => {
            var _this$activeKanban3;
            return item.id === ((_this$activeKanban3 = this.activeKanban) === null || _this$activeKanban3 === void 0 ? void 0 : _this$activeKanban3.id);
          }), 'total', total);
        }
        this.trade_ids = this.list.map(item => item.trade_id);
        this.earliestTime = res.results.earliest_time;
        this.setScaleLine();
        _performanceTracking.default.end();
      }
      this.tableLoading = false;
    },
    // 设置表格跟进动态时间轴刻度线 scaleLine
    setScaleLine() {
      // 把渲染负载放到下一次事件循环中
      setTimeout(() => {
        this.$nextTick(() => {
          const tradeTable = this.$refs['tradeTable'];
          if (tradeTable) {
            tradeTable.flipNum = 0;
            tradeTable.flipNumLoaded = 0;
            const scaleLine = tradeTable.$refs['scaleLine'];
            if (scaleLine) {
              scaleLine.forEach(element => {
                element.setDateList(this.earliestTime);
              });
            }
          }
        });
      }, 10);
    },
    // 跟进动态请求
    async getTradeListFollow() {
      var _this$$refs$tradeTabl2;
      if (JSON.stringify(this.listParams.filter_params) === '{}') return;
      const scaleLine = (_this$$refs$tradeTabl2 = this.$refs['tradeTable']) === null || _this$$refs$tradeTabl2 === void 0 ? void 0 : _this$$refs$tradeTabl2.$refs['scaleLine'];
      if (scaleLine) {
        const params = {
          date: scaleLine[0].dateRange,
          trade_ids: this.trade_ids
        };
        const situationRes = await (0, _trade.getTradeListFollow)(params);
        if (situationRes.code === 20000) {
          this.list.forEach((item, index) => {
            item.speak_time.unshift(...situationRes.results[index].conversation_speak_times);
          });
        }
      }
    },
    currentChange(val) {
      this.listParams.page = val;
      this.getList();
    },
    sizeChange(val) {
      this.listParams.size = val;
      this.listParams.page = 1;
      this.getList();
    },
    tableSortChange(prop, order) {
      if (!prop) return;
      this.listParams.sort_by = [[prop, order]];
      if (order !== 'default') {
        this.activeKanban.sort_conf = [{
          field_name: prop,
          value: order,
          order: 1
        }];
      }
      this.getList();
    },
    headerFiltersChange(params) {
      if (params.trade_name && !params.trade_name.value.length) {
        params.trade_name = undefined;
      }
      if (JSON.stringify(this.listParams.filter_params) === JSON.stringify(params)) {
        return;
      }
      this.listParams.filter_params = params;
      this.listParams.page = 1;
    },
    headerFiltersCustomChange() {
      this.getList(true);
    },
    handleSettingClick() {
      this.$router.push({
        path: _routeMap.ROUTES.tradeSet
      });
    },
    async handleDeleteKanban(id) {
      const res = await (0, _trade.deleteTradeView)({
        filter_id: id,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('删除成功');
        if (!this.kanbanList.length) return;
        this.activeKanban = {};
        this.kanbanList = this.kanbanList.filter(item => {
          return item.id !== id;
        });
        this.listParams.filter_fields = [];
        this.initData();
      }
    },
    setTableHeight() {
      const header = document.querySelector('.header-filter-container');
      this.tableHeight = document.body.clientHeight - (header ? 156 + header.clientHeight : 156);
    },
    setTradeAmount() {
      let amountViewList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.originTradeAmount;
      let isInit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      for (const item of this.kanbanList) {
        const view = amountViewList.find(v => v.id === item.id);
        if (view && (view.id !== this.activeKanban.id || isInit)) {
          item.amount = view.amount;
          item.total = view.total;
        }
      }
    },
    async getTradeAmount() {
      let isInit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const params = {
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      const res = await (0, _trade.getTradeAmount)(params);
      const viewList = res.results.view_list;
      this.originTradeAmount = viewList;
      // 给视图添加商机金额和数量
      this.setTradeAmount(viewList, isInit);
    }
  }
};
exports.default = _default;