"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "export-operate-btn"
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom-end",
      "popper-class": "export-popover",
      offset: 50
    },
    model: {
      value: _setup.visible,
      callback: function ($$v) {
        _setup.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("p", {
    staticClass: "tip-content"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v(_vm._s(_vm.content))])]), _c("div", {
    staticClass: "operate"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _setup.closePopover
    }
  }, [_vm._v(_vm._s(_setup.t("management.取消")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.handleExport
    }
  }, [_vm._v(_vm._s(_setup.t("management.确定")))])], 1), _c("el-button", {
    staticClass: "export-btn",
    attrs: {
      slot: "reference",
      icon: "iconfont icon-export",
      plain: ""
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.btnContent))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;