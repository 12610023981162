"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'MutualExclusionBasicInfoForm',
  props: {
    mutualExclusionBasicInfoForm: {
      type: Object,
      default: () => {
        return {
          name: '',
          description: ''
        };
      }
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const rules = {
      name: [{
        required: true,
        message: '请输入策略名称',
        trigger: 'blur'
      }]
    };
    const mutexBasicInfoFormRef = (0, _vue.ref)(null);
    const validFormResult = async () => {
      return await mutexBasicInfoFormRef.value.validate();
    };
    expose({
      validFormResult
    });
    return {
      __sfc: true,
      props,
      rules,
      mutexBasicInfoFormRef,
      validFormResult
    };
  }
};
exports.default = _default;