"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "correct-flip-role-history"
  }, [_c("MgvInfiniteScroll", {
    staticClass: "correct-flip-role-history-container",
    attrs: {
      loading: _setup.loading,
      "has-more": _setup.hasMore
    },
    on: {
      loadMore: _setup.getCorrectFlipRoleHistoryApi
    }
  }, [_c("el-timeline", _vm._l(_setup.correctFlipRoleHistoryList, function (item) {
    var _item$modified_user;
    return _c("el-timeline-item", {
      key: item.id,
      attrs: {
        icon: "iconfont icon-switch-roles"
      }
    }, [_c("div", {
      staticClass: "content-header"
    }, [_c("MgvAvatarName", {
      attrs: {
        size: 24,
        name: item.create_by
      }
    }), _c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.create_by))]), _c("span", {
      staticClass: "date"
    }, [_vm._v(_vm._s(_setup.plus8ToCurrentZone(item.create_at).format("YYYY-MM-DD HH:mm")))])], 1), _c("div", {
      staticClass: "content-desc"
    }, [[2, 3].includes(item.type) ? _c("span", {
      staticClass: "operate-desc"
    }, [_c("b", [_vm._v("翻转")]), _c("span", [_vm._v("了以下文本的说话角色")]), _c("span", {
      staticClass: "operate-name"
    }, [_vm._v(_vm._s(item.origin_user.name))]), _vm._v("  "), _c("i", {
      staticClass: "iconfont icon-right"
    }), _vm._v("  "), _c("span", [_vm._v(_vm._s(item.modified_user.name))])]) : _c("span", [_c("b", [_vm._v("翻转")]), _c("span", [_vm._v("了会话全文的角色")])])]), [2, 3].includes(item.type) ? _c(_setup.SingleSentence, {
      attrs: {
        "media-type": _vm.mediaType,
        sentence: {
          content: item.origin_content,
          begin_time: item.begin_time,
          entity_type: item.type === 2 ? "customer_contact" : "host_salesman",
          name: item === null || item === void 0 ? void 0 : (_item$modified_user = item.modified_user) === null || _item$modified_user === void 0 ? void 0 : _item$modified_user.name
        }
      },
      nativeOn: {
        click: function ($event) {
          return _setup.locationSentence(item);
        }
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;