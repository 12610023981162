"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createKeyEventMutex = createKeyEventMutex;
exports.deleteKeyEventMutex = deleteKeyEventMutex;
exports.editKeyEventMutex = editKeyEventMutex;
exports.getKeyEventMutexDetail = getKeyEventMutexDetail;
exports.getKeyEventMutexList = getKeyEventMutexList;
exports.getUsedKeyEventIds = getUsedKeyEventIds;
var _request = require("@/utils/request");
function createKeyEventMutex(data) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
function editKeyEventMutex(data) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/update',
    method: 'post',
    dataType: 'json',
    data
  });
}
function deleteKeyEventMutex(data) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getKeyEventMutexDetail(params) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/info',
    method: 'get',
    params
  });
}
function getKeyEventMutexList(params) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/list',
    method: 'get',
    params
  });
}
function getUsedKeyEventIds(params) {
  return (0, _request.webService)({
    url: '/mp_event/mutex/event_ids',
    method: 'get',
    params
  });
}