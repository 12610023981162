"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'BusinessTransChartFootnote',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editVisisble: false,
      editLoading: false,
      form: {}
    };
  },
  computed: {
    formKeys() {
      return ['title', 'total', 'trans_other_rate', 'trans_other_count', 'loss_rate', 'loss_count'];
    },
    tooltipList() {
      const {
        trans_other_count,
        trans_other_rate,
        loss_count,
        loss_rate
      } = this.data;
      return [`${trans_other_count.name}：触达该关键事件的交易中到达关键结果的交易数量。`, `${trans_other_rate.name}：触达该关键事件的交易中到达关键结果的交易数量占触达该关键事件的交易总数。`, `${loss_count.name}：触达该关键事件的交易中没有到达关键结果的交易数量。`, `${loss_rate.name}：触达该关键事件的交易中没有到达关键结果的交易数量占触达该关键事件的交易总数。`];
    }
  },
  methods: {
    editField() {
      this.editVisisble = true;
      this.form = (0, _commonFunc.deepClone)(this.data);
    },
    formatData() {
      return this.formKeys.reduce((item, key) => {
        const {
          field_key,
          name
        } = this.form[key];
        item[field_key] = name;
        return item;
      }, {});
    },
    async handlerSubmit() {
      this.editLoading = true;
      this.$emit('submit', this.formatData(), () => {
        this.editVisisble = false;
        this.editLoading = false;
      }, true);
    }
  }
};
exports.default = _default;