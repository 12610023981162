"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _CombineRuleGroup = _interopRequireDefault(require("./CombineRuleGroup.vue"));
var _vue = require("vue");
var _combineModel = require("./hooks/combineModel.js");
var _useMessage = require("@/hooks/use-message");
var _modelManagement = require("@/api/modelManagement");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CombineModelDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelInfo: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:visible', 'createSuccess'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const {
      basicInfoFormRef,
      basicModelInfo,
      basicModelInfoDesc,
      basicModelFormValidate
    } = (0, _combineModel.useCombineModelBasicInfoFrom)();
    const {
      combineConfig,
      addRuleGroup,
      configsValidate,
      hasLeastTwoAtomModel
    } = (0, _combineModel.useCombineModelConfig)();
    const deleteRuleGroup = index => {
      combineConfig.value.configs.splice(index, 1);
    };

    // 创建 or 编辑 模型
    const submitBtnLoading = (0, _vue.ref)(false);
    const submitCount = (0, _vue.ref)(0);
    const submitCombineModelAsync = async () => {
      submitCount.value++;
      if (!props.modelInfo.id) {
        const basicInfoValidateRes = await basicModelFormValidate();
        if (!basicInfoValidateRes) {
          _useMessage.useMessage.warning('模型名称不能为空');
          return;
        }
      }
      const configsValidateRes = configsValidate();
      if (!configsValidateRes) {
        return;
      }
      submitBtnLoading.value = true;
      const requestFunction = props.modelInfo.id ? _modelManagement.editCombineModel : _modelManagement.createCombineModel;
      const basicRequestParams = {
        use_to: 1,
        name: basicModelInfo.value.name,
        description: basicModelInfo.value.description,
        combine_config: combineConfig.value
      };
      const params = props.modelInfo.id ? {
        ...basicRequestParams,
        id: props.modelInfo.id
      } : basicRequestParams;
      const res = await requestFunction(params).finally(() => submitBtnLoading.value = false);
      if (res.code === 20000) {
        props.modelInfo.id ? _useMessage.useMessage.success('编辑成功') : _useMessage.useMessage.success('创建成功');
        emit('createSuccess', res.results.id || props.modelInfo.id);
        updateVisible();
      }
    };

    // 获取模型详情
    const loading = (0, _vue.ref)(false);
    const getCombineModelDetailAsync = async () => {
      loading.value = true;
      const res = await (0, _modelManagement.getCombineModelDetail)({
        id: props.modelInfo.id,
        version: props.modelInfo.version
      }).finally(() => setTimeout(() => loading.value = false, 500));
      if (res.code === 20000) {
        const {
          name,
          description,
          combine_config
        } = res.results;
        basicModelInfo.value = {
          name,
          description
        };
        combineConfig.value = combine_config;
      }
    };
    expose({
      getCombineModelDetailAsync
    });
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      basicInfoFormRef,
      basicModelInfo,
      basicModelInfoDesc,
      basicModelFormValidate,
      combineConfig,
      addRuleGroup,
      configsValidate,
      hasLeastTwoAtomModel,
      deleteRuleGroup,
      submitBtnLoading,
      submitCount,
      submitCombineModelAsync,
      loading,
      getCombineModelDetailAsync,
      DynamicForm: _DynamicForm.default,
      CombineRuleGroup: _CombineRuleGroup.default
    };
  }
};
exports.default = _default;