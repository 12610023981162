"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "table",
    staticClass: "coaching-center-leader-plan-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight,
      "row-style": {
        height: "72px"
      }
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_vm._l(_vm.tableHead, function (item, index) {
    return _c("el-table-column", {
      key: index + item.field_name,
      attrs: {
        prop: item.field_name,
        label: item.name,
        sortable: item.sortable,
        fixed: item.direction || false,
        "sort-orders": ["descending", "ascending"],
        "min-width": 200
      },
      scopedSlots: _vm._u([item.field_name === "name" ? {
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "custom-table-head"
          }, [_c("div", {
            staticClass: "head-content"
          }, [_vm._v(" " + _vm._s(item.name) + " "), _c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: _vm.$t("coaching.onlyTheTaskCompletionStatus"),
              placement: "bottom"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-info-1"
          })])], 1)])];
        },
        proxy: true
      } : item.field_name === "summary_score" ? {
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "custom-table-head"
          }, [_c("div", {
            staticClass: "head-content"
          }, [_vm._v(" " + _vm._s(item.name) + " "), _c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: _vm.tableHeadTooltip,
              placement: "bottom"
            }
          }, [_c("i", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.tableHeadTooltip,
              expression: "tableHeadTooltip"
            }],
            staticClass: "iconfont icon-info-1"
          })])], 1)])];
        },
        proxy: true
      } : null, item.field_name === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("image-avatar", {
            staticClass: "upper-avatar",
            attrs: {
              name: row.user.name,
              avatar: row.user.avatar,
              shape: "circle"
            }
          }), _c("span", [_vm._v(_vm._s(row.user.name))])];
        }
      } : item.field_name === "total_score" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row.is_access ? [row.total_score >= 0 ? _c("span", [_vm._v(_vm._s(_vm.$t("coaching.pointsHasVar", [row.total_score])))]) : _c("span", {
            staticClass: "no-score"
          }, [_vm._v(_vm._s(_vm.$t("coaching.noScoreYet")))])] : _c("span", {
            staticClass: "cannot-view"
          }, [_vm._v("--")])];
        }
      } : item.field_name === "summary_score" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row.is_access ? [row.summary_score >= 0 ? _c("span", [_vm._v(_vm._s(_vm.$t("coaching.pointsHasVar", [row.summary_score])))]) : _c("span", {
            staticClass: "no-score"
          }, [_vm._v(_vm._s(_vm.$t("coaching.noScoreYet")))])] : _c("span", {
            staticClass: "cannot-view"
          }, [_vm._v("--")])];
        }
      } : item.field_name === "practice_count" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [row.is_access ? [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.times", [row.practice_count])))])] : _c("span", {
            staticClass: "cannot-view"
          }, [_vm._v("--")])];
        }
      } : item.field_name === "practice_duration" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [row.practice_duration ? [_c("span", [_vm._v(_vm._s(row.practice_duration))])] : _c("span", {
            staticClass: "cannot-view"
          }, [_vm._v("-")])];
        }
      } : item.field_name === "status" ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.getStatus(row))
            }
          })];
        }
      } : item.field_name === "lost_gist_count" ? {
        key: "default",
        fn: function (_ref7) {
          let {
            row
          } = _ref7;
          return [row.lost_gist_count ? _c("MgvButton", {
            attrs: {
              type: "text",
              size: "tiny"
            },
            on: {
              click: function ($event) {
                return _vm.openPointDeductionItemDrawer(row);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t("coaching.totalOfSectionsHasVar", [row.lost_gist_count])))]) : _c("span", [_vm._v("-")])];
        }
      } : {
        key: "default",
        fn: function (_ref8) {
          let {
            row
          } = _ref8;
          return [row.is_access ? [row[item.field_name]["is_complete"] ? _c("span", [_vm._v(_vm._s(row[item.field_name]["score"] >= 0 ? _vm.$t("coaching.pointsHasVar", [row[item.field_name]["score"]]) : _vm.$t("coaching.noScoreYet")))]) : _c("div", [row[item.field_name]["score"] >= 0 ? _c("div", {
            staticClass: "no-complete"
          }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.pointsHasVar", [row[item.field_name]["score"]])))]), _c("el-tooltip", {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: _vm.$t("coaching.someRhetoricIsNotCompleted"),
              placement: "bottom"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-time"
          })])], 1) : _c("div", {
            staticClass: "no-score"
          }, [_vm._v(_vm._s(_vm.$t("coaching.noScoreYet")))])])] : _c("span", {
            staticClass: "cannot-view"
          }, [_vm._v("--")])];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("coaching.operation"),
      prop: "operate",
      fixed: "right",
      width: 200,
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        let {
          row
        } = _ref9;
        return [row.is_access ? _c("span", {
          staticClass: "operate-content",
          on: {
            click: function ($event) {
              return _vm.goPersonDetail(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("coaching.details")))]) : _c("span", {
          staticClass: "cannot-view"
        }, [_vm._v(_vm._s(_vm.$t("coaching.noViewingPermission")))])];
      }
    }])
  })], 2), _c("TaskResultDetailDrawer", {
    attrs: {
      visible: _vm.TaskResultDetailDrawerVisible,
      "current-task-info": _vm.currentTaskInfo,
      "task-id": _vm.$route.query.id - 0
    },
    on: {
      "update:visible": function ($event) {
        _vm.TaskResultDetailDrawerVisible = $event;
      }
    }
  }), _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      visible: _vm.pointDeductionItemDrawerVisible,
      title: _vm.$t("coaching.pointsLostSection"),
      size: "600px",
      "destroy-on-close": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.pointDeductionItemDrawerVisible = $event;
      }
    }
  }, [_c("PointDeductionItemsDrawerContent", {
    attrs: {
      "plan-id": Number(_vm.$route.query.id),
      "user-id": _vm.currentRow.user.id
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;