"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _ModelVersionSelect = _interopRequireDefault(require("./ModelVersionSelect.vue"));
var _ModelSelect = _interopRequireDefault(require("./ModelSelect.vue"));
var _modelManagement = require("@/api/modelManagement");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModelAndVersionSelect',
  props: {
    modelId: {
      type: [String, Number],
      default: ''
    },
    version: {
      type: [Number, String],
      default: ''
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    filterCombineModel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultSelectedPublishedVersion: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selectChange'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const model_id = (0, _vue.ref)('');
    const modelList = (0, _vue.ref)([]);
    const model_version = (0, _vue.ref)('');
    const getModelListRequest = query => {
      const params = {
        model_name: query,
        page: 1,
        size: 200
      };
      (0, _modelManagement.getModelList)(params).then(res => {
        if (props.filterCombineModel) {
          modelList.value = res.results.models.filter(item => item.model_type !== 4);
        } else {
          modelList.value = res.results.models;
        }
      });
    };
    const initData = () => {
      model_id.value = props.modelId;
      model_version.value = props.version;
    };
    (0, _vue.watchEffect)(() => {
      getModelListRequest('');
      emit('selectChange', {
        model_id: model_id.value,
        model_version: model_version.value
      });
    });
    const selectModel = () => {
      model_version.value = '';
    };
    (0, _vue.watchEffect)(() => {
      initData();
    });
    expose({
      initData
    });
    return {
      __sfc: true,
      props,
      model_id,
      modelList,
      model_version,
      getModelListRequest,
      initData,
      emit,
      selectModel,
      ModelVersionSelect: _ModelVersionSelect.default
    };
  }
};
exports.default = _default;