"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchAnnotate = batchAnnotate;
exports.cancelEditLock = cancelEditLock;
exports.createKeyEvent = createKeyEvent;
exports.createKeyEventFolder = createKeyEventFolder;
exports.createRuleEvent = createRuleEvent;
exports.deleteEvent = deleteEvent;
exports.deleteEventFolder = deleteEventFolder;
exports.deleteModelVersion = deleteModelVersion;
exports.getConvIds = getConvIds;
exports.getCurrentModelStage = getCurrentModelStage;
exports.getCustomEventProgress = getCustomEventProgress;
exports.getEditLock = getEditLock;
exports.getEventFolders = getEventFolders;
exports.getKeyEventList = getKeyEventList;
exports.getKeyEventTree = getKeyEventTree;
exports.getModelInfoBySpace = getModelInfoBySpace;
exports.getModelPrecisionValue = getModelPrecisionValue;
exports.getModelResultList = getModelResultList;
exports.getModelVersionList = getModelVersionList;
exports.getRuleEventInfo = getRuleEventInfo;
exports.moveKeyEvent = moveKeyEvent;
exports.publishModel = publishModel;
exports.renameEventFolder = renameEventFolder;
exports.setEventScope = setEventScope;
exports.testRuleEvent = testRuleEvent;
exports.updateKeyEvent = updateKeyEvent;
exports.updateRuleEvent = updateRuleEvent;
var _request = require("@/utils/request");
function getKeyEventList(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_event_list',
    method: 'get',
    params
  });
}
function createKeyEventFolder(data) {
  return (0, _request.webService)({
    url: '/mp_event/create_folder',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getModelInfoBySpace() {}
function getKeyEventTree() {}
function setEventScope() {}
function getCurrentModelStage(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_current_stage',
    method: 'get',
    params
  });
}
// 编辑锁
function getEditLock(data) {
  return (0, _request.webService)({
    url: '/mp_event/edit/start',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 取消编辑锁
function cancelEditLock(data) {
  return (0, _request.webService)({
    url: '/mp_event/edit/cancel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 上线模型
function publishModel(data) {
  return (0, _request.webService)({
    url: '/mp_event/publish',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移动事件
function moveKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建关键事件
function createKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/create_event_simple',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 更新关键事件
function updateKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/update_event_simple',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建规则事件
function createRuleEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/edit/create_event',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 更新规则事件
function updateRuleEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/edit/update_event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 测试事件规则
function testRuleEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/test_event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 结果页
function getModelPrecisionValue(data) {
  return (0, _request.webService)({
    url: '/mp_event/get_events_analysis',
    method: 'post',
    dataType: 'json',
    data
  });
}

// getModelResultList,
// getModelVersionList,
// batchAnnotate

// 获取事件模型任务结果列表
function getModelResultList(data) {
  return (0, _request.webService)({
    url: '/mp_event/get_training_result',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 获取模型版本列表
function getModelVersionList(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_version_list',
    method: 'get',
    params
  });
}
// 删除版本
function deleteModelVersion(data) {
  return (0, _request.webService)({
    url: '/mp_event/delete_version',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 批量标注
function batchAnnotate(data) {
  return (0, _request.webService)({
    url: '/mp_event/batch_annotate',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getConvIds(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/get_list_ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取编辑事件信息
function getRuleEventInfo(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_event',
    method: 'get',
    params
  });
}

// 事件文件夹重命名
function renameEventFolder(data) {
  return (0, _request.webService)({
    url: '/mp_event/rename_folder',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 事件文件夹删除
function deleteEventFolder(data) {
  return (0, _request.webService)({
    url: '/mp_event/delete_folder',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 自定义事件重跑进度
function getCustomEventProgress(params) {
  return (0, _request.webService)({
    url: '/mp_event/task_progress',
    method: 'get',
    params
  });
}

// 删除事件
function deleteEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/delete_event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取事件文件夹下的子文件夹
function getEventFolders(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_event_folder',
    method: 'get',
    params
  });
}