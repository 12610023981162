"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      "custom-class": "comment-member-dialog",
      title: "指定可见成员",
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      width: "475px",
      "modal-append-to-body": false,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.tipsVisible ? _c("p", {
    staticClass: "tips"
  }, [_vm._v(" 操作实时生效，选择完成后，可关闭弹窗继续评论 ")]) : _vm._e(), _c("members-tree-select", {
    ref: "memberSelect",
    attrs: {
      "is-show-main-space-member": "",
      "request-function": _vm.getDataOrgMemberTree,
      "request-params": {
        type: JSON.stringify(["conversation"])
      },
      "is-prevent-not-selected": false
    },
    on: {
      change: _vm.handleMemberChange
    }
  }), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.footerVisible ? [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.memberList.length,
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.syncComment
    }
  }, [_vm._v("同步")])] : _vm._e()], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;