"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _DimensionCreateDialog = _interopRequireDefault(require("./DimensionCreateDialog.vue"));
var _MetricSelector = _interopRequireDefault(require("./MetricSelector.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DimensionList',
  emits: ['node-click'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const dimensions = (0, _vue.ref)([]);
    const getScoreMetricsDimensionList = async () => {
      const res = await (0, _coachingCenter.scoreMetricsDimensionList)();
      console.log(res);
      res.results.items.forEach(item => {
        item.isEdit = false;
        item.inputRef = null;
      });
      dimensions.value = res.results.items;
    };
    const activeId = (0, _vue.ref)(0);
    const nodeClick = item => {
      activeId.value = item.id;
      emit('node-click', item);
    };
    const createDialogVisible = (0, _vue.ref)(false);
    const addDimension = async () => {
      // createDialogVisible.value = true;
      const item = {
        isEdit: true,
        inputRef: null,
        name: '',
        metrics_count: 0
      };
      dimensions.value.push(item);
      await (0, _vue.nextTick)();
      item.inputRef.focus();
    };
    async function initData(cb) {
      await getScoreMetricsDimensionList();
      if (!dimensions.value.length) return;
      nodeClick(dimensions.value[0]);
      cb && cb();
    }
    const handleCommand = async (val, item) => {
      switch (val) {
        case 'edit':
          item.isEdit = true;
          nameTemp.value = item.name;
          await (0, _vue.nextTick)();
          item.inputRef.focus();
          break;
        case 'delete':
          if (item.metrics_count) {
            (0, _elementUi.Message)({
              message: '该维度下有指标，不能删除',
              type: 'warning'
            });
            return;
          } else {
            _elementUi.MessageBox.confirm(`确认删除维度 ${item.name}？`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const res = await (0, _coachingCenter.scoreMetricsDimensionDelete)({
                dimension_id: item.id
              });
              (0, _elementUi.Message)({
                message: '删除成功',
                type: 'success'
              });
              dimensions.value = dimensions.value.filter(ele => ele !== item);
              if (dimensions.value.length) {
                nodeClick(dimensions.value[0]);
              }
            });
          }
          break;
      }
    };
    const nameTemp = (0, _vue.ref)('');
    const onInputFocus = () => {};
    const getInputRef = (el, item) => {
      item.inputRef = el;
    };
    const onInputBlur = async item => {
      item.isEdit = false;
      if (item.id) {
        // 编辑
        if (!nameTemp.value || item.name === nameTemp.value) return;
        const res = await (0, _coachingCenter.scoreMetricsDimensionUpdate)({
          dimension_id: item.id,
          name: nameTemp.value
        });
        if (res.code === 20000) {
          item.name = nameTemp.value;
        }
      } else {
        // 新增
        if (!nameTemp.value || item.name === nameTemp.value) {
          dimensions.value.pop();
          return;
        }
        const res = await (0, _coachingCenter.scoreMetricsDimensionCreate)({
          name: nameTemp.value
        });
        if (res.code === 20000) {
          item.id = res.results.dimension_id;
          item.name = nameTemp.value;
        } else {
          dimensions.value.pop();
        }
      }
      nameTemp.value = '';
    };
    const updateCount = (dimensionId, count) => {
      const index = dimensions.value.findIndex(item => item.id === dimensionId);
      dimensions.value[index].metrics_count += count;
    };
    const onMetricSelectorChange = metric => {
      const dimension = dimensions.value.find(item => item.id === metric.dimension_id);
      nodeClick(dimension, metric);
    };
    expose({
      initData,
      updateCount
    });
    return {
      __sfc: true,
      dimensions,
      getScoreMetricsDimensionList,
      activeId,
      emit,
      nodeClick,
      createDialogVisible,
      addDimension,
      initData,
      handleCommand,
      nameTemp,
      onInputFocus,
      getInputRef,
      onInputBlur,
      updateCount,
      onMetricSelectorChange,
      DimensionCreateDialog: _DimensionCreateDialog.default,
      MetricSelector: _MetricSelector.default
    };
  }
};
exports.default = _default;