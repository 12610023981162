"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("conversation-filter-item", {
    attrs: {
      label: "会话文本搜索",
      "popover-content": "*",
      "is-show-clear": _vm.showCloseIcon
    },
    on: {
      handleClear: _vm.clearText
    }
  }, [_c("div", {
    attrs: {
      slot: "popoverHtml"
    },
    slot: "popoverHtml"
  }, [_c("p", [_vm._v(" 默认在会话全文中搜索，如需单条发言内同时包含多个关键词，请以空格分隔。 ")])]), _c("text-search-input", {
    ref: "textSearchInput",
    attrs: {
      filters: _vm.filters,
      disabled: _vm.disabled
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;