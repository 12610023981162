"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelExport = cancelExport;
exports.exportBusinessList = exportBusinessList;
exports.exportBusinessRole = exportBusinessRole;
exports.exportConvList = exportConvList;
exports.exportDepartment = exportDepartment;
exports.exportManagerRole = exportManagerRole;
exports.exportMembers = exportMembers;
exports.exportTradeList = exportTradeList;
exports.getExportList = getExportList;
var _request = require("@/utils/request");
// 与导出相关的接口

// 获取导出记录列表
function getExportList(params) {
  return (0, _request.request)({
    url: '/profile/export_list',
    method: 'get',
    params
  });
}

// 会话列表页导出
function exportConvList(data) {
  return (0, _request.webService)({
    url: '/profile/conversation_list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 商机列表导出
function exportBusinessList(data) {
  return (0, _request.webService)({
    url: '/profile/deal_list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易列表导出
function exportTradeList(data) {
  return (0, _request.webService)({
    url: '/profile/trade_list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 取消导出任务
function cancelExport(data) {
  return (0, _request.webService)({
    url: '/profile/export/cancel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 成员导出
function exportMembers(data) {
  return (0, _request.webService)({
    url: '/organization/user/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 部门导出
function exportDepartment(data) {
  return (0, _request.webService)({
    url: '/organization/tree/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 业务功能角色导出
function exportBusinessRole(data) {
  return (0, _request.webService)({
    url: '/organization/role/func/user/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 管理角色导出
function exportManagerRole(data) {
  return (0, _request.webService)({
    url: '/organization/role/manage/user/export',
    method: 'post',
    dataType: 'json',
    data
  });
}