"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-message"
  }, [_vm.message.type === "question" ? _c("div", {
    staticClass: "message-container question",
    class: {
      "question-import": _vm.questionImport
    }
  }, [_c("div", {
    staticClass: "msg"
  }, [_vm._v(" " + _vm._s(_vm.message.msg) + " "), _c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.questionImport,
      expression: "questionImport"
    }],
    attrs: {
      type: "plain",
      color: "info",
      size: "mini",
      icon: "icon-plus",
      "tooltip-content": _vm.importTooltip
    },
    on: {
      click: function ($event) {
        return _vm.handleImportClick(_vm.message);
      }
    }
  })], 1), _c("image-avatar", {
    attrs: {
      avatar: _vm.avatar,
      name: _vm.name
    }
  })], 1) : _c("div", {
    staticClass: "message-container answer"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/AIHelper/ai_avatar.png")
    }
  }), _c("div", {
    class: ["msg", _vm.message.status === "loading" && _vm.message.msg ? "writing" : ""]
  }, [_vm.message.status === "loading" && !_vm.message.msg ? _c("img", {
    staticClass: "loading-img",
    attrs: {
      src: require("@/assets/AIHelper/content_loading.gif")
    }
  }) : _vm._e(), _vm.message.msg ? [_vm._v(_vm._s(_vm.message.msg) + " "), _vm.message.status === "loading" ? _c("i", {
    staticClass: "flicker"
  }) : _vm._e()] : _vm._e(), _vm._t("default")], 2), _vm.message.msg && _vm.message.status !== "loading" ? _c("message-operate", _vm._g(_vm._b({
    attrs: {
      msg: _vm.message.msg
    }
  }, "message-operate", _vm.$attrs, false), _vm.$listeners)) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;