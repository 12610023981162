"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _default = {
  __name: 'MegaTranGuide',
  setup(__props) {
    const router = (0, _useRouter.useRouter)();
    const route = (0, _useRouter.useRoute)();
    const collapse = (0, _vue.ref)(false);
    const handleCollapse = () => {
      collapse.value = !collapse.value;
      localStorage.setItem('megaTranCollapse', collapse.value);
    };
    const store = (0, _useVuex.useStore)();
    const apiPermission = (0, _vue.computed)(() => store.getters.api_permissions);
    const tabList = (0, _vue.computed)(() => {
      const result = [];
      if (apiPermission.value.includes(_routeMap.INTERFACE.createAiModel)) {
        result.push({
          id: 'AI',
          name: 'AI模型'
        });
      }
      if (apiPermission.value.includes(_routeMap.INTERFACE.createRuleModel)) {
        result.push({
          id: 'regular',
          name: '规则模型'
        });
      }
      return result;
    });
    const activeTab = (0, _vue.ref)(tabList.value[0].id);
    const handleTabChange = tab => {
      localStorage.setItem('megaTranActiveTab', tab.id);
      activeTab.value = tab.id;
    };
    const activeCollapse = (0, _vue.ref)('');
    const init = () => {
      const isCollapse = localStorage.getItem('megaTranCollapse');
      if (isCollapse === 'true') {
        collapse.value = true;
      } else {
        collapse.value = false;
      }
      activeTab.value = localStorage.getItem('megaTranActiveTab') || 'AI';
      activeCollapse.value = localStorage.getItem('megaTranActiveCollapse');
    };
    (0, _vue.onMounted)(() => {
      init();
    });
    const goModelManagementPage = () => {
      router.push({
        path: _routeMap.ROUTES.ModelManagement
      });
    };
    const goDataSetPage = () => {
      router.push({
        path: _routeMap.ROUTES.dataSet
      });
    };
    const goKeyEventManagementPage = () => {
      router.push({
        path: _routeMap.ROUTES.keyEventManagement
      });
    };
    const createDataSetGuideRef = (0, _vue.ref)();
    const createModelGuideRef = (0, _vue.ref)();
    const trainModelGuideRef = (0, _vue.ref)();
    const testModelGuideRef = (0, _vue.ref)();
    const publishModelGuideRef = (0, _vue.ref)();
    const configModelGuideRef = (0, _vue.ref)();
    const closeOtherGuide = tour => {
      const tours = [createDataSetGuideRef, createModelGuideRef, trainModelGuideRef, testModelGuideRef, publishModelGuideRef, configModelGuideRef];
      tours.map(item => {
        if (item !== tour) {
          var _item$value;
          (_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.closeTour();
        }
      });
    };
    const hasGuideDom = name => {
      const selector = `[data-v-name=${name}]`;
      const allElement = Array.from(document.querySelectorAll(selector));
      return allElement.length;
    };
    const openHideDom = name => {
      const selector = `.el-table__fixed-right [data-v-name=${name}]`;
      const allElement = Array.from(document.querySelectorAll(selector));
      if (allElement.length) {
        const elDropdown = allElement[0].parentElement.parentElement.parentElement;
        const moreBtn = elDropdown.querySelector('#more-btn');
        moreBtn === null || moreBtn === void 0 ? void 0 : moreBtn.click();
      }
    };
    const handleCollapseChange = async val => {
      var _createDataSetGuideRe, _createModelGuideRef$, _trainModelGuideRef$v, _testModelGuideRef$va, _publishModelGuideRef, _configModelGuideRef$;
      await (0, _vue.nextTick)();
      localStorage.setItem('megaTranActiveCollapse', val);
      switch (val) {
        case 'AI-1':
          hasGuideDom('create-data-set-btn') && ((_createDataSetGuideRe = createDataSetGuideRef.value) === null || _createDataSetGuideRe === void 0 ? void 0 : _createDataSetGuideRe.start());
          closeOtherGuide(createDataSetGuideRef);
          break;
        case 'AI-2':
        case 'regular-1':
          hasGuideDom('create-model-btn') && ((_createModelGuideRef$ = createModelGuideRef.value) === null || _createModelGuideRef$ === void 0 ? void 0 : _createModelGuideRef$.start());
          closeOtherGuide(createModelGuideRef);
          break;
        case 'AI-3':
          // openHideDom('train-model-btn');
          hasGuideDom('train-model-btn') && ((_trainModelGuideRef$v = trainModelGuideRef.value) === null || _trainModelGuideRef$v === void 0 ? void 0 : _trainModelGuideRef$v.start());
          closeOtherGuide(trainModelGuideRef);
          break;
        case 'AI-4':
          // openHideDom('test-model-btn');
          hasGuideDom('test-model-btn') && ((_testModelGuideRef$va = testModelGuideRef.value) === null || _testModelGuideRef$va === void 0 ? void 0 : _testModelGuideRef$va.start());
          closeOtherGuide(testModelGuideRef);
          break;
        case 'AI-5':
        case 'regular-2':
          hasGuideDom('publish-model-btn') && ((_publishModelGuideRef = publishModelGuideRef.value) === null || _publishModelGuideRef === void 0 ? void 0 : _publishModelGuideRef.start());
          closeOtherGuide(publishModelGuideRef);
          hasGuideDom('config-model-btn') && ((_configModelGuideRef$ = configModelGuideRef.value) === null || _configModelGuideRef$ === void 0 ? void 0 : _configModelGuideRef$.start());
          closeOtherGuide(configModelGuideRef);
          break;
        default:
          break;
      }
    };
    const createDataSetStep = [{
      title: '点击新建数据集并导入数据',
      target: '[data-v-name="create-data-set-btn"]',
      placement: 'bottom'
    }];
    const createAIModelStep = [{
      title: '点击新建模型',
      target: '[data-v-name="create-model-btn"]',
      placement: 'bottom'
    }];
    const trainAIModelStep = [{
      title: '点击训练模型',
      target: route.path === _routeMap.ROUTES.ModelManagement ? '[data-v-name="train-model-btn"]' : '.el-table__fixed-right [data-v-name="train-model-btn"]',
      placement: 'bottom'
    }];
    const testAIModelStep = [{
      title: '点击测试模型效果',
      target: route.path === _routeMap.ROUTES.ModelManagement ? '[data-v-name="test-model-btn"]' : '.el-table__fixed-right [data-v-name="test-model-btn"]',
      placement: 'bottom'
    }];
    const publishAIModelStep = [{
      title: '点击“发布模型”，填写关键事件信息',
      target: '[data-v-name="publish-model-btn"]',
      placement: 'bottom'
    }];
    const configModelStep = [{
      title: '点击“模型配置”，选择模型',
      target: '.el-table__fixed-right [data-v-name="config-model-btn"]',
      placement: 'bottom'
    }];
    return {
      __sfc: true,
      router,
      route,
      collapse,
      handleCollapse,
      store,
      apiPermission,
      tabList,
      activeTab,
      handleTabChange,
      activeCollapse,
      init,
      goModelManagementPage,
      goDataSetPage,
      goKeyEventManagementPage,
      createDataSetGuideRef,
      createModelGuideRef,
      trainModelGuideRef,
      testModelGuideRef,
      publishModelGuideRef,
      configModelGuideRef,
      closeOtherGuide,
      hasGuideDom,
      openHideDom,
      handleCollapseChange,
      createDataSetStep,
      createAIModelStep,
      trainAIModelStep,
      testAIModelStep,
      publishAIModelStep,
      configModelStep,
      INTERFACE: _routeMap.INTERFACE
    };
  }
};
exports.default = _default;