"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    title: {
      type: String,
      default: '暂无内容'
    }
  },
  data() {
    return {};
  }
};
exports.default = _default;