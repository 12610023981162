"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _conversationList = require("@/api/conversationList");
var _business = require("@/api/business");
var _ConversationFilterItem = _interopRequireDefault(require("../../components/ConversationFilterItem"));
var _EventOrderTracker = _interopRequireDefault(require("../../components/EventOrderTracker.vue"));
var _SelectCustomTag = _interopRequireDefault(require("../../components/SelectCustomTag"));
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _TextSearch = _interopRequireDefault(require("../../components/TextSearch.vue"));
var _EventTracker = _interopRequireDefault(require("../../components/EventTracker"));
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router.js");
var _useVuex = require("@/hooks/use-vuex.js");
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConversationBaseFilters',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    isShowTextSearch: {
      type: Boolean,
      default: true
    },
    isShowEventTracker: {
      type: Boolean,
      default: true
    },
    isShowEventOrderTracker: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const route = (0, _useRouter.useRoute)();
    const isInQuickModelingPage = (0, _vue.inject)('isInQuickModelingPage', false);

    // 拿到空间信息
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    // 拿到接口权限信息
    const modulePermission = (0, _vue.computed)(() => store.getters.modulePermission);
    const orgId = (0, _vue.computed)(() => store.getters.orgId);
    /**
     * 成员树的逻辑
     *  */
    const treeConfig = {
      noDisabled: true,
      noCheckDept: true,
      checkIsExpanded: true,
      addDepartmentPre: true
    };
    // 清空客户选项/交易选项/销售
    const clearFiltersByFields = arr => {
      arr.forEach(i => {
        if (i === 'deal_name') {
          // eslint-disable-next-line vue/no-mutating-props
          props.filters[i] = {
            value: [],
            is_batch: false
          };
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          props.filters[i] = [];
        }
      });
    };
    const memberFinderRef = (0, _vue.ref)(null);
    // 选择销售
    const hostChange = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.host_ids = val;
    };
    const handleIncludesDeletedChange = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.hosts_includes_deleted_members = val;
    };
    const clearHost = () => {
      clearFiltersByFields(['host', 'host_ids']);
      if (memberFinderRef.value) memberFinderRef.value.selection = [];
    };

    /**
     * 部门选择器的逻辑
     */
    const mgvDepartmentMultiSelectRef = (0, _vue.ref)(null);
    const clearDepartment = () => {
      (0, _vue.nextTick)(() => {
        mgvDepartmentMultiSelectRef.value && mgvDepartmentMultiSelectRef.value.clear();
      });
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.organization_tree_ids = [];
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.departmentLabel = '';
    };
    // 选择部门
    const handleDepartmentValueChange = (treeIds, treeAllData) => {
      if ((0, _isEqual.default)(props.filters.organization_tree_ids, treeIds)) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.organization_tree_ids = treeIds;
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.departmentLabel = treeAllData.map(item => item.name).join('，');
      clearFiltersByFields(['hosts']);
    };

    /**
     * 客户选择器逻辑
     */
    const filterChange = (val, type, labelObj) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters[labelObj] = val;
      // eslint-disable-next-line vue/no-mutating-props
      props.filters[type] = val.map(_ref => {
        let {
          id,
          icon
        } = _ref;
        if (icon !== 'icon-leads') {
          return id;
        }
      });
    };

    /**
     * 交易选择器逻辑
     */
    // 搜索交易推荐项
    const getFuzzySearchListRequest = async (filter, cb, type) => {
      const res = await (0, _business.getFuzzySearchList)({
        filter,
        type,
        workspace_id: workspaceInfo.value.id,
        source: route.path === _routeMap.ROUTES.conversation ? 'conversation' : ''
      });
      cb(res.results.map(value => {
        return {
          value
        };
      }));
    };
    return {
      __sfc: true,
      store,
      route,
      props,
      isInQuickModelingPage,
      workspaceInfo,
      modulePermission,
      orgId,
      treeConfig,
      clearFiltersByFields,
      memberFinderRef,
      hostChange,
      handleIncludesDeletedChange,
      clearHost,
      mgvDepartmentMultiSelectRef,
      clearDepartment,
      handleDepartmentValueChange,
      filterChange,
      getFuzzySearchListRequest,
      getOrgTree: _organization.getOrgTree,
      getAccountOrDeal: _conversationList.getAccountOrDeal,
      ConversationFilterItem: _ConversationFilterItem.default,
      EventOrderTracker: _EventOrderTracker.default,
      SelectCustomTag: _SelectCustomTag.default,
      MembersFinder: _MembersFinder.default,
      TextSearch: _TextSearch.default,
      EventTracker: _EventTracker.default,
      BatchSearchInput: _BatchSearchInput.default
    };
  }
};
exports.default = _default;