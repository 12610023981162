"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: _vm.placement,
      enterable: _vm.enterable,
      content: _vm.content,
      "open-delay": _vm.openDelay,
      effect: "dark"
    }
  }, [_vm._t("content", function () {
    return [_c("div", {
      staticClass: "content-container"
    }, [_vm._v(_vm._s(_vm.content))])];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;