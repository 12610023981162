"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "valid-train-data"
  }, [_setup.datasetCurrentStatus === 3 ? _c("div", {
    staticClass: "train-data-content"
  }, [_setup.showLoadingDataTips ? _c(_setup.LoadingDataMessage, {
    staticClass: "loading-data"
  }, [_vm._v("数据加载中")]) : _vm._e(), _c("MgvInfoAlert", {
    staticClass: "alert",
    attrs: {
      "alert-key": "quick-model-train-data-tip"
    }
  }, [_vm._v(" 请检查以下文本句是否与模型语义一致，符合语义的请标为命中；容易误理解为符合但实际不符合语义的，请标为未命中；与模型语义完全无关的，请删除；暂时无法确认语义的，可以先标为不确定，训练前再标为命中/未命中即可"), _c("br"), _vm._v(" 正样本（命中）数量不能少于100条，负样本（未命中）数量不用太多，能覆盖易混淆的场景即可")]), _c("div", {
    staticClass: "train-data-header"
  }, [_c(_setup.ValidTrainDataHeader, {
    ref: "dataFiltersRef",
    attrs: {
      "train-data-selection": _setup.trainDataSelection
    },
    on: {
      change: _setup.filterChange,
      updateTrainDataStatus: _setup.updateTrainDataStatus,
      deleteAnnotation: _setup.deleteAnnotation
    }
  }), _c("div", {
    staticClass: "button-group"
  }, [_c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _setup.handleCommand
    }
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      size: "medium",
      color: "info"
    }
  }, [_c("span", [_vm._v("补充导入数据")]), _vm._v(" "), _c("i", {
    staticClass: "iconfont icon-arrow-down"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "manual"
    }
  }, [_vm._v("手动筛选会话")]), _c("el-dropdown-item", {
    attrs: {
      command: "ai"
    }
  }, [_vm._v("AI自动挖掘")])], 1)], 1)], 1)], 1), _c(_setup.ValidTrainDataTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _setup.tableData,
      "dataset-id": _setup.datasetId,
      "start-index": _setup.pageSize * (_setup.currentPage - 1) + 1,
      "dataset-stats": _setup.datasetStats
    },
    on: {
      "selection-change": _setup.onSelectionChange,
      clickModelSentence: _setup.openTrainDataDetailDialog,
      annotateMark: _setup.updateTrainDataStatus,
      deleteAnnotation: _setup.deleteAnnotation
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      total: _setup.total,
      "page-size": _setup.pageSize,
      "page-sizes": [100, 200, 400],
      "current-page": _setup.currentPage
    },
    on: {
      "update:pageSize": function ($event) {
        _setup.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _setup.pageSize = $event;
      },
      "update:currentPage": function ($event) {
        _setup.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _setup.currentPage = $event;
      },
      "size-change": _setup.handleSizeChange,
      "current-change": _setup.getTrainData
    }
  }), _c(_setup.TrainDataDetailDialog, {
    attrs: {
      id: _setup.currentTrainDataDetailId,
      "dataset-id": _setup.datasetId,
      "get-all-data": _setup.getAllData,
      visible: _setup.dialogVisible,
      ids: _setup.trainDataIds
    },
    on: {
      "update:visible": function ($event) {
        _setup.dialogVisible = $event;
      },
      updateTableListAnnotationStatus: _setup.updateTableListAnnotationStatus
    }
  }), _c(_setup.ImportDataSetDialog, {
    attrs: {
      visible: _setup.importDataSetDialogVisible,
      title: _setup.importDataSetDialogTitle,
      "dataset-id": _setup.datasetId
    },
    on: {
      "update:visible": function ($event) {
        _setup.importDataSetDialogVisible = $event;
      },
      expandTrainDataSetSuccess: _setup.expandTrainDataSetSuccess
    }
  }), _setup.conversationDrawerVisible ? _c(_setup.FilterConvDrawer, {
    ref: "filterConvDrawerRef",
    attrs: {
      title: "手动筛选会话",
      "drawer-visible": _setup.conversationDrawerVisible,
      "modal-append-to-body": false,
      "no-tip": "",
      "page-sizes": [200]
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.conversationDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.conversationDrawerVisible = $event;
      },
      conversationListChange: _setup.conversationListChange
    },
    scopedSlots: _vm._u([{
      key: "footer-operate",
      fn: function () {
        return [_c("el-button", {
          staticClass: "mg-button mg-round-button",
          on: {
            click: function ($event) {
              _setup.conversationDrawerVisible = false;
            }
          }
        }, [_vm._v("取消")]), _c("el-button", {
          class: ["mg-button", "mg-round-button"],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _setup.openManualImportDialog.apply(null, arguments);
            }
          }
        }, [_vm._v("导入")])];
      },
      proxy: true
    }], null, false, 3292744969)
  }) : _vm._e(), _c(_setup.ManualImportDataSetDialog, {
    attrs: {
      visible: _setup.manualImportDataSetDialogVisible,
      "dataset-id": _setup.datasetId,
      "conversation-list": _setup.conversationList
    },
    on: {
      "update:visible": function ($event) {
        _setup.manualImportDataSetDialogVisible = $event;
      },
      expandTrainDataSetSuccess: _setup.expandTrainDataSetSuccess
    }
  })], 1) : [_c("div", {
    staticClass: "empty-box"
  }, [_c("h1", {
    staticClass: "text"
  }, [_vm._v(" 训练数据生成中 "), _c("span", [_vm._v(_vm._s(_setup.formatProgress(_setup.generateProgress)) + "%")])]), _c("div", {
    staticClass: "row"
  }, [_c("el-progress", {
    attrs: {
      percentage: _setup.formatProgress(_setup.generateProgress),
      "show-text": false
    }
  })], 1)])]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;