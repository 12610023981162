"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _IntelligentGenerationPromptForm = _interopRequireDefault(require("./IntelligentGenerationPromptForm.vue"));
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentGenerationForm',
  props: {
    formDesc: {
      type: Object,
      default: () => ({})
    }
  },
  emits: 'cancel',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const customerInfoFormDesc = (0, _vue.ref)([{
      id: (0, _nanoid.nanoid)(),
      key: '客户信息',
      isPreset: true,
      value: ''
    }, {
      id: (0, _nanoid.nanoid)(),
      key: '沟通方式',
      isPreset: true,
      value: ''
    }, {
      id: (0, _nanoid.nanoid)(),
      key: '',
      value: ''
    }]);
    const progressFormDesc = (0, _vue.ref)([{
      id: (0, _nanoid.nanoid)(),
      key: '对话开始阶段',
      value: ''
    }, {
      id: (0, _nanoid.nanoid)(),
      key: '销售挖需阶段',
      value: ''
    }]);
    (0, _vue.watch)(() => props.formDesc, val => {
      customerInfoFormDesc.value = val.customer_info.map(item => ({
        id: (0, _nanoid.nanoid)(),
        isPreset: ['角色定义', '沟通方式'].includes(item.key),
        key: item.key,
        value: item.value
      }));
      progressFormDesc.value = val.progress.map(item => ({
        id: (0, _nanoid.nanoid)(),
        key: item.key,
        value: item.value
      }));
    }, {
      immediate: true
    });
    const addField = desc => {
      desc.push({
        id: (0, _nanoid.nanoid)(),
        key: ''
      });
    };
    const removeField = (id, desc) => {
      const index = desc.findIndex(item => item.id === id);
      desc.splice(index, 1);
    };
    const cancel = () => {
      emit('cancel');
    };
    const customerInfoFormRef = (0, _vue.ref)(null);
    const progressFormRef = (0, _vue.ref)(null);
    const generatePrompt = async () => {
      const res = await Promise.all([customerInfoFormRef.value.validate(), progressFormRef.value.validate()]);
      if (res.every(item => item)) {
        const params = {
          customer_info: customerInfoFormDesc.value,
          progress: progressFormDesc.value
        };
        emit('generatePrompt', params);
      }
    };
    return {
      __sfc: true,
      props,
      customerInfoFormDesc,
      progressFormDesc,
      addField,
      removeField,
      emit,
      cancel,
      customerInfoFormRef,
      progressFormRef,
      generatePrompt,
      IntelligentGenerationPromptForm: _IntelligentGenerationPromptForm.default
    };
  }
};
exports.default = _default;