"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _routeMap = require("@/router/routeMap");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScatterTable',
  mixins: [_ExportMixins.default],
  inject: ['getBiFilters'],
  props: {
    tableData: {
      type: Object,
      default: () => ({})
    },
    legendColumn: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      currPage: 1,
      pageSize: 40,
      total: 0,
      // 汇总数据
      summaryDataHead: [{
        label: '客户评级',
        prop: 'name'
      }, {
        label: '数量',
        prop: 'count'
      }, {
        label: '占比',
        prop: 'proportion'
      }]
    };
  },
  computed: {
    getColumnConfig() {
      return index => this.tableData.column_config && this.tableData.column_config[index];
    },
    columnIsVisible() {
      return index => this.getColumnConfig(index).visiable;
    },
    columnIsSort() {
      return index => this.getColumnConfig(index).is_sorted && 'custom';
    },
    eachPageTableData() {
      const start = (this.currPage - 1) * this.pageSize;
      const end = this.currPage * this.pageSize;
      return this.tableData.rows.slice(start, end);
    },
    summaryData() {
      const index = this.tableData.heads.findIndex(item => item === '评级');
      if (index === -1) return;
      const summaryDataRows = Object.values(this.tableData.column_config[index].mapping).map(item => {
        return Object.assign(item, {
          count: 0
        });
      });
      this.tableData.rows.forEach(item => summaryDataRows.forEach(itemScore => {
        if (itemScore.column_value === item[index]) {
          itemScore.count++;
        }
      }));
      summaryDataRows.forEach(item => {
        if (this.tableData.rows.length) {
          item.proportion = Math.round(item.count / this.tableData.rows.length * 10000) / 100 + '%';
        } else {
          item.proportion = 0 + '%';
        }
      });
      return summaryDataRows;
    },
    isCustomer() {
      return this.$route.fullPath === _routeMap.ROUTES.biIntentAnalysis;
    }
  },
  methods: {
    formattedDecimal(row, index) {
      return String(row[index]).indexOf('.') !== -1 && Number(row[index]) ? parseFloat(parseFloat(row[index]).toFixed(4)) : row[index];
    },
    getURLParams(row, index) {
      const config = this.tableData.column_config;
      const targetColumn = config[index].target_column;
      const params = {};
      for (const key in targetColumn) {
        params[key] = row[targetColumn[key]];
      }
      return config[index].link + '?' + new URLSearchParams(params);
    },
    exportTableCsv() {
      var _this$tableData;
      const {
        heads,
        rows,
        column_config
      } = this.tableData;
      const res = rows.map(itemArr => {
        const tr = [];
        itemArr.map((ele, index) => {
          const {
            visiable,
            type,
            mapping
          } = column_config[index];
          if (visiable) {
            var _mapping$ele;
            tr.push(type === 'mapping' ? (_mapping$ele = mapping[ele]) === null || _mapping$ele === void 0 ? void 0 : _mapping$ele.name : typeof ele === 'number' ? String(ele).indexOf('.') !== -1 && Number(ele) ? parseFloat(parseFloat(ele).toFixed(4)) : ele : `${ele}\t`);
          }
        });
        return tr;
      });
      res.unshift(heads.filter((_, index) => column_config[index].visiable));
      const {
        date
      } = this.getBiFilters();
      const dateArr = JSON.parse(date);
      const fileName = `${(_this$tableData = this.tableData) === null || _this$tableData === void 0 ? void 0 : _this$tableData.name}_${dateArr[0]}至${dateArr[1]}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    // 数据的自定义排序
    sortByAmount() {
      const idx = arguments[0].prop;
      const orderType = arguments[0].order;
      if (!orderType) return;
      this.currPage = 1;
      // eslint-disable-next-line vue/no-mutating-props
      this.tableData.rows.sort((a, b) => {
        if (orderType === 'ascending') {
          return a[idx] - b[idx];
        } else {
          return b[idx] - a[idx];
        }
      });
    },
    getTypeText(val, index) {
      var _this$getColumnConfig;
      return (_this$getColumnConfig = this.getColumnConfig(index).mapping[val]) === null || _this$getColumnConfig === void 0 ? void 0 : _this$getColumnConfig.name;
    },
    getTypeColor(val, index) {
      var _this$getColumnConfig2;
      return (_this$getColumnConfig2 = this.getColumnConfig(index).mapping[val]) === null || _this$getColumnConfig2 === void 0 ? void 0 : _this$getColumnConfig2.background;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currPage = 1;
    },
    handleCurrentChange(val) {
      this.currPage = val;
    }
  }
};
exports.default = _default;