"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModelInfo = useModelInfo;
var _vue = require("vue");
function useModelInfo() {
  const keyEventModelInfo = sessionStorage.getItem('keyEventModelInfo');
  const INITIAL = {
    id: 0,
    version: '',
    status: 0,
    type: 1,
    step: 1,
    name: '',
    isNew: true,
    folderId: '',
    folderName: ''
  };
  const _modelInfo = keyEventModelInfo ? {
    ...INITIAL,
    ...JSON.parse(keyEventModelInfo)
  } : INITIAL;
  const modelInfo = (0, _vue.reactive)(_modelInfo);
  storeModelInfo();
  const setModelInfo = info => {
    Object.assign(modelInfo, info);
    storeModelInfo();
  };
  const resetModelInfo = () => {
    Object.assign(modelInfo, INITIAL);
    storeModelInfo();
  };
  (0, _vue.provide)('modelInfo', modelInfo);
  (0, _vue.provide)('setModelInfo', setModelInfo);
  (0, _vue.provide)('resetModelInfo', resetModelInfo);
  function storeModelInfo() {
    sessionStorage.setItem('keyEventModelInfo', JSON.stringify(modelInfo));
  }
  return {
    modelInfo,
    setModelInfo,
    resetModelInfo
  };
}