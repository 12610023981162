"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _client = require("@/api/client");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "ContactPersonDialog",
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    contactPersonDialogVisible: {
      type: Boolean,
      default: false
    },
    contactFormDesc: {
      type: Array,
      default: () => []
    },
    accountId: {
      type: String | Number
    },
    clientDetailDrawerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {},
      saveLoading: false
    };
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("closeDialog");
    },
    resetForm() {
      this.$refs["contactDynamicForm"].resetFields();
    },
    clearValidate() {
      this.$refs["contactDynamicForm"].clearValidate();
    },
    submitForm() {
      if (this.$refs["contactDynamicForm"].validate()) {
        this.formData.hasOwnProperty("customer_name") && delete this.formData.customer_name;
        this.postCreateContact({
          contact: this.formData,
          account_id: this.accountId
        });
      } else {
        return false;
      }
    },
    async postCreateContact(data) {
      this.saveLoading = true;
      const res = await (0, _client.postCreateContact)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.closeDialog();
        this.$message.success("新建客户联系人成功");
        if (this.clientDetailDrawerVisible && this.contactPersonDialogVisible) {
          this.$bus.$emit("fetchContactSuccess");
        }
      }
    }
  }
};
exports.default = _default;