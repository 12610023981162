"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FieldItems = _interopRequireDefault(require("./FieldItems.vue"));
var _vue = require("vue");
var _nanoid = require("nanoid");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CustomerPersona',
  props: {
    backfillCustomerPersonaList: {
      type: Array,
      default: () => []
    },
    saved: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:saved'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const customerPersonaList = (0, _vue.ref)([{
      id: (0, _nanoid.nanoid)(),
      key: '客户背景',
      value: ''
    }, {
      id: (0, _nanoid.nanoid)(),
      key: '客户需求',
      value: ''
    }]);
    (0, _vue.watch)(() => props.backfillCustomerPersonaList, val => {
      customerPersonaList.value = (0, _commonFunc.deepClone)(val);
    });
    const getPlaceHolder = item => {
      console.log('getPlaceHolder :>> ', item);
      return {
        客户背景: '如：客户是一家规模50~100人的互联网公司',
        客户需求: '如：客户想要购买一款价格较低的产品'
      }[item.key];
    };
    function triggerSavedStatus() {
      emit('update:saved', false);
    }
    expose({
      customerPersonaList
    });
    return {
      __sfc: true,
      props,
      emit,
      customerPersonaList,
      getPlaceHolder,
      triggerSavedStatus,
      FieldItems: _FieldItems.default
    };
  }
};
exports.default = _default;