"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ClientContactPersonList = _interopRequireDefault(require("@/views/clients/components/ClientContactPersonList.vue"));
var _MeetingButton = _interopRequireDefault(require("@/components/MeetingButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ContactPersonList: _ClientContactPersonList.default,
    MeetingButton: _MeetingButton.default
  },
  props: {
    rowInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    operateButtons: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: [Number, String],
      default: ''
    },
    isInRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clueId: null,
      contactList: [],
      contactFormDesc: [],
      currentOperateType: ''
    };
  },
  computed: {
    classObject() {
      return function (row, item) {
        return {
          'el-button-text--primary row-icon': item.isCircle,
          'hover-show-bg': !item.isCircle
        };
      };
    }
  },
  mounted() {
    // 如果有联系人列表出现时，点其他地方隐藏popover
    document.addEventListener('click', this.clickOtherHiddenPopover);
  },
  destroyed() {
    document.removeEventListener('click', this.clickOtherHiddenPopover);
  },
  methods: {
    getIsShowPopover(row, type) {
      var _this$contactList;
      return this.activeId === row.id && !!((_this$contactList = this.contactList) !== null && _this$contactList !== void 0 && _this$contactList.length) && this.currentOperateType === type;
    },
    async clickOperateBtn(row, type, meetingType) {
      const {
        id,
        name,
        tel
      } = row;
      this.clueId = row.id;
      this.currentOperateType = type;
      const contactInfo = {
        id,
        name,
        tel
      };
      this.contactList = [contactInfo];
      this.$emit('changeactiveClueId', row.id);
      this.clickContactPersonItem(contactInfo, row, type, meetingType);
    },
    clickContactPersonItem(contactItem, row, type, meetingType) {
      this.$emit('clickContactPersonItem', contactItem, row, type, this.contactList, meetingType);
      this.clueId = null;
      this.hiddenPopover();
    },
    hiddenPopover() {
      this.currentOperateType = '';
    },
    clickOtherHiddenPopover(e) {
      if (e.target.className !== 'contact-list-comp') {
        this.hiddenPopover();
      }
    }
  }
};
exports.default = _default;