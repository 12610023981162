"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TeamTaskFilterGroup = _interopRequireDefault(require("./TeamTaskFilterGroup"));
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _compliance = require("@/api/compliance");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _TeamTaskCardView = _interopRequireDefault(require("./TeamTaskCardView.vue"));
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
var _SwitchViewButton = _interopRequireDefault(require("../../components/SwitchViewButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamTaskContent',
  components: {
    TeamTaskFilterGroup: _TeamTaskFilterGroup.default,
    ComplianceBaseTable: _ComplianceBaseTable.default,
    NoData: _NoData.default,
    TeamTaskCardView: _TeamTaskCardView.default,
    ExportButton: _ExportButton.default,
    SwitchViewButton: _SwitchViewButton.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    total: {
      type: Number,
      default: 0
    },
    isTotal: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    isTodayMission: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({
        prop: 'create_at',
        order: 'descending'
      })
    },
    sortable: {
      type: Array,
      default: () => ['status', 'score_rule', 'template', 'create_at', 'percent', 'finish', 'wait', 'reject', 'total']
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    }
  },
  data() {
    return {
      pageSize: [10, 20, 30, 40],
      filtersObj: {
        task_name: '',
        create_time: [],
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        percent: [],
        sort_by: [['create_at', 'descending']],
        page: 1,
        size: 20
      },
      viewType: 'card'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission']),
    noFilter() {
      const isSame = this.filtersObj.task_name === '' && JSON.stringify(this.filtersObj.user_ids) === '{"tree_ids":[],"user_ids":[]}' && JSON.stringify(this.filtersObj.percent) === '[]' && JSON.stringify(this.filtersObj.create_time) === '[]';
      if (!this.isTotal) {
        return isSame;
      }
      return this.filtersObj.status === 0 && isSame;
    },
    tableHeader() {
      const headerList = [{
        label: '任务名称',
        prop: 'name',
        width: 190,
        fixed: 'left'
      }, {
        label: '质检员',
        prop: 'user_infos',
        width: 163
      }, {
        label: '状态',
        prop: 'task_status',
        width: 120
      }, {
        label: '进度',
        prop: 'percent',
        width: 260
      }, {
        label: '待质检',
        prop: 'wait',
        width: 100
      }, {
        label: '已质检',
        prop: 'finish',
        width: 100
      }, {
        label: '已驳回',
        prop: 'reject',
        width: 100
      }, {
        label: '会话总数',
        prop: 'total',
        width: 100
      }, {
        label: '质检评分规则',
        prop: 'score_rule',
        width: 200
      }, {
        label: '质检评价单',
        prop: 'template',
        width: 200
      }, {
        label: '分配人',
        prop: 'create_by',
        width: 120
      }, {
        label: '分配时间',
        prop: 'create_at',
        width: 160
      }];
      if (this.interfacePermission.cancelTask || this.interfacePermission.deleteTask) {
        headerList.push({
          label: '操作',
          prop: 'operate',
          width: 146,
          fixed: 'right'
        });
      }
      return headerList;
    }
  },
  watch: {
    filters: {
      handler: function (val, oldValue) {
        if (JSON.stringify(val) === JSON.stringify(oldValue)) return;
        this.filtersObj = val;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.viewType = sessionStorage.getItem('teamTaskView') || 'card';
  },
  methods: {
    exportComplianceTeamTaskApi: _compliance.exportComplianceTeamTaskApi,
    cancelComplianceTask(id) {
      this.$confirm(`确定取消任务？取消任务后待质检的会话可再次分配。`, '取消任务', {
        confirmButtonText: '取消任务',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          workspace_id: this.workspaceInfo.id,
          task_id: id
        };
        const res = await (0, _compliance.cancelComplianceTask)(data);
        if (res.code === 20000) {
          this.$message.success('取消成功');
          this.$emit('refresh');
        }
      });
    },
    deleteComplianceTask(id) {
      this.$confirm(`确定删除任务？删除团队任务将同步删除该任务下的成员任务和会话质检记录。`, '删除任务', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const data = {
          workspace_id: this.workspaceInfo.id,
          task_id: id
        };
        const res = await (0, _compliance.deleteComplianceTask)(data);
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.$emit('refresh');
        }
      });
    },
    goTaskDetail(row) {
      this.$router.push({
        path: _routeMap.ROUTES.complianceTeamTaskDetail,
        query: {
          id: row.id,
          sampling_type: row.sampling_type
        }
      });
    },
    selectView(command) {
      sessionStorage.setItem('teamTaskView', command);
      this.viewType = command;
    },
    handleDistribute() {
      this.$emit('handleDistribute');
    },
    changeFilter(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.$emit('handleFiltersChange', this.filtersObj);
    },
    goCompliancePersonal(rowInfo) {
      this.$emit('goCompliancePersonal', rowInfo);
    },
    sizeChange(size) {
      this.$emit('sizeChange', size);
    },
    currentChange(page) {
      this.$emit('currentChange', page);
    }
  }
};
exports.default = _default;