"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConvRecycleBinHeader',
  setup(__props) {
    const goBack = () => {
      window.history.go(-1);
    };
    const dataPermissionPath = `${window.location.origin}${_routeMap.ROUTES.dataPermission}`;
    return {
      __sfc: true,
      goBack,
      dataPermissionPath,
      SpaceButton: _SpaceButton.default
    };
  }
};
exports.default = _default;