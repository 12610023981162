"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trade = require("@/api/trade");
var _CreateTradeDialog = _interopRequireDefault(require("./CreateTradeDialog"));
var _ChooseAccountDialog = _interopRequireDefault(require("./ChooseAccountDialog"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateTrade',
  components: {
    createTradeDialog: _CreateTradeDialog.default,
    ChooseAccountDialog: _ChooseAccountDialog.default
  },
  data() {
    return {
      createTradeDialogVisible: false,
      chooseAccountDialogVisible: false,
      tradeForm: {},
      formDesc: [],
      dynamicForm: [],
      tradeStageOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['contactDynamicFormDescList', 'workspaceInfo'])
  },
  created() {
    this.$store.dispatch('account/getContactDynamicForm');
  },
  mounted() {
    this.getTradeStageOptions();
  },
  methods: {
    createTradeFormChange(val) {
      this.tradeForm = val || {};
    },
    fetchAccountSuccess(val) {
      this.chooseAccountDialogVisible = false;
      this.$refs['createTradeDialog'].customerChange({
        name: val.name,
        id: val.id
      });
    },
    chooseAccountSuccess(val) {
      if (val) {
        this.$refs['createTradeDialog'].customerChange(val);
      }
    },
    openChooseAccountDialog() {
      this.chooseAccountDialogVisible = true;
    },
    closeCreateTradeDialog() {
      this.createTradeDialogVisible = false;
      this.formDesc = [];
    },
    closeChooseAccountDialog() {
      this.chooseAccountDialogVisible = false;
    },
    openCreateTradeDialog() {
      this.createTradeDialogVisible = true;
      this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)));
    },
    // 获取交易阶段
    async getTradeStageOptions() {
      const res = await (0, _trade.getTradeStageList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.tradeStageOptions = res.results.stages;
      }
      // 获取动态表单
      if (this.$refs['createTradeDialog']) {
        this.$refs['createTradeDialog'].loading = true;
      }
      const dynamicForm = await (0, _trade.getTradeForm)();
      if (dynamicForm.code === 20000) {
        this.dynamicForm = dynamicForm.results.fields;
        if (this.$refs['createTradeDialog']) {
          this.$refs['createTradeDialog'].loading = false;
        }
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)));
      }
    },
    handleTradeStageOptions(options) {
      if (Array.isArray(options)) {
        options.forEach(item => {
          const {
            id,
            stage_name
          } = item;
          item.value = id;
          item.label = stage_name;
        });
      }
      return options;
    },
    // 表单描述格式化
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'select' && item.name === '交易阶段') {
          obj.options = this.handleTradeStageOptions(this.tradeStageOptions);
        }
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
        }
        desc.push(obj);
      }
      return desc;
    }
  }
};
exports.default = _default;