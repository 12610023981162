"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-detail"
  }, [_c("div", {
    staticClass: "top-infos"
  }, [_c("div", {
    staticClass: "title-line"
  }, [_c("p", {
    staticClass: "title-name"
  }, [_vm.detailObj.origin_type === "wework_internal_app" ? _c("img", {
    staticClass: "title-logo",
    attrs: {
      src: require("@/assets/wework.svg"),
      alt: "logo"
    }
  }) : _vm.detailObj.origin_type === "tencent_meeting" ? _c("img", {
    staticClass: "title-logo",
    attrs: {
      src: require("@/assets/tencent_meeting.svg"),
      alt: "logo"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "title-text-time"
  }, [_c("span", {
    staticClass: "title-text"
  }, [_vm._v(_vm._s(_vm.detailObj.name))]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailObj.conversation_status === "attend",
      expression: "detailObj.conversation_status === 'attend'"
    }],
    staticClass: "conv-duration"
  }, [_vm._v(_vm._s(_vm.getDuration(_vm.detailObj.duration)))])])]), _c("span", {
    staticClass: "conv-time"
  }, [_c("svg-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailObj.conver_status === "plan",
      expression: "detailObj.conver_status === 'plan'"
    }],
    staticClass: "date-icon",
    attrs: {
      "icon-class": "reservation-2"
    }
  }), _vm._v(" " + _vm._s(_vm.getTimeText(_vm.detailObj.begin_time)) + " ")], 1)]), _c("div", {
    staticClass: "link-line"
  }, [_c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailObj.conversation_status === "attend",
      expression: "detailObj.conversation_status === 'attend'"
    }],
    staticClass: "conv-text-btn",
    attrs: {
      type: "text",
      icon: "el-icon-document"
    },
    on: {
      click: function ($event) {
        return _vm.goConvDetail(true);
      }
    }
  }, [_vm._v("查看转录文字")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailObj.conversation_status === "attend",
      expression: "detailObj.conversation_status === 'attend'"
    }],
    staticClass: "conv-detail-btn",
    attrs: {
      size: "mini",
      icon: _vm.getBtnIcon,
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.goConvDetail(false);
      }
    }
  }, [_vm._v("会话详情")])], 1), _c("div", {
    staticClass: "person-line"
  }, [_c("div", {
    staticClass: "sales-info-wrapper person-wrapper"
  }, [_c("div", {
    staticClass: "company-wrapper-title"
  }, [_vm._v(_vm._s(_vm.orgName))]), _c("div", {
    staticClass: "person-info-wrapper"
  }, _vm._l(_vm.detailObj.host_salesman, function (person, index) {
    return _c("div", {
      key: index,
      staticClass: "sales-info person-info"
    }, [_c("image-avatar", {
      attrs: {
        name: person.name,
        avatar: person.avatar
      }
    }), _c("div", {
      staticClass: "right-person-info"
    }, [_c("div", {
      staticClass: "person-name"
    }, [_vm._v(_vm._s(person.name))]), _c("div", {
      staticClass: "other-person-info"
    }, [_c("span", [_vm._v(_vm._s(person.label || "销售代表"))])])])], 1);
  }), 0), _vm.detailObj.conversation_status === "plan" && _vm.detailObj.type === "video" && _vm.detailObj.assistant_salesman && _vm.detailObj.assistant_salesman.length > 0 ? _c("div", {
    staticClass: "parti-persons"
  }, [_vm.detailObj.assistant_salesman && _vm.detailObj.assistant_salesman.length > 0 ? _c("div", {
    staticClass: "assistant-persons"
  }, [false ? _c("div", {
    staticClass: "sub-person-title"
  }, [_vm._v("部门同事")]) : _vm._e(), _c("div", {
    staticClass: "person-info-flex"
  }, _vm._l(_vm.detailObj.assistant_salesman, function (person, index) {
    return _c("div", {
      key: index,
      staticClass: "person-info"
    }, [_c("image-avatar", {
      attrs: {
        name: person.name,
        avatar: person.avatar
      }
    }), _c("div", {
      staticClass: "right-person-info"
    }, [_c("div", {
      staticClass: "person-name"
    }, [_vm._v(_vm._s(person.name))]), _c("div", {
      staticClass: "other-person-info"
    }, [_c("span", [_vm._v(_vm._s(person.label))])])])], 1);
  }), 0)]) : _vm._e(), _vm.detailObj.spectator_salesman && _vm.detailObj.spectator_salesman.length > 0 ? _c("div", {
    staticClass: "spectator-persons"
  }, [false ? _c("div", {
    staticClass: "sub-person-title"
  }, [_vm._v("关注者")]) : _vm._e(), _c("div", {
    staticClass: "person-info-flex"
  }, _vm._l(_vm.detailObj.spectator_salesman, function (person, index) {
    return _c("div", {
      key: index,
      staticClass: "person-info"
    }, [_c("image-avatar", {
      attrs: {
        name: person.name,
        avatar: person.avatar
      }
    }), _c("div", {
      staticClass: "right-person-info"
    }, [_c("div", {
      staticClass: "person-name"
    }, [_vm._v(_vm._s(person.name))]), _c("div", {
      staticClass: "other-person-info"
    }, [_c("span", [_vm._v(_vm._s(person.label))])])])], 1);
  }), 0)]) : _vm._e()]) : _vm._e()]), _c("div", {
    staticClass: "customer-info-wrapper person-wrapper"
  }, [_c("div", {
    staticClass: "company-wrapper-title"
  }, [_vm._v(_vm._s(_vm.customerName || "-"))]), _c("div", {
    staticClass: "person-info-wrapper"
  }, _vm._l(_vm.detailObj.customer_contact, function (person, index) {
    return _c("div", {
      key: index,
      staticClass: "customer-info person-info"
    }, [_c("image-avatar", {
      attrs: {
        name: person.name ? person.name : "客户",
        avatar: person.avatar,
        type: "customer_contact"
      }
    }), _c("div", {
      staticClass: "right-person-info"
    }, [_c("div", {
      staticClass: "person-name"
    }, [_vm._v(_vm._s(person.name || "未知姓名"))]), _c("div", {
      staticClass: "other-person-info"
    }, [_c("span", [_vm._v(_vm._s(person.label || "联系人"))])])])], 1);
  }), 0)])])]), _vm.detailObj.conversation_status === "attend" ? _c("div", {
    staticClass: "middle-notes"
  }, [_c("div", {
    staticClass: "part-title"
  }, [_vm._v("私人笔记")]), _c("div", {
    staticClass: "note-wrapper"
  }, [_c("note-editor", {
    staticClass: "note-editor",
    attrs: {
      "read-only": "",
      "conversation-id": _vm.busiActiveConver
    }
  })], 1)]) : _vm._e(), _vm.detailObj.conversation_status === "attend" ? _c("div", {
    staticClass: "bottom-event"
  }, [_c("div", {
    staticClass: "part-title"
  }, [_vm._v("关键事件 (" + _vm._s(_vm.eventCount) + ")")]), _c("div", {
    staticClass: "event-wrapper",
    attrs: {
      id: "event-wrapper"
    }
  }, [_c("events-list", {
    attrs: {
      "event-engine": _vm.eventEngine,
      "tag-size": "large"
    },
    on: {
      tagClick: _vm.handleTagClick
    }
  })], 1)]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;