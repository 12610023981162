"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "create-member-assign"
  }, [_c(_setup.DetailHeader, {
    attrs: {
      title: "新建任务",
      "back-button": ""
    }
  }), _c("main", [_c("el-form", {
    ref: "form",
    staticClass: "form-container",
    attrs: {
      rules: _setup.rules,
      model: _setup.formData,
      "hide-required-asterisk": ""
    }
  }, [_c(_setup.FormItemCard, {
    staticClass: "select-member",
    attrs: {
      title: "待质检成员"
    }
  }, [_c("el-radio-group", {
    on: {
      input: _setup.changeSelectType
    },
    model: {
      value: _setup.selectType,
      callback: function ($$v) {
        _setup.selectType = $$v;
      },
      expression: "selectType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "select"
    }
  }, [_vm._v("手动选择")]), _c("el-radio", {
    attrs: {
      label: "import"
    }
  }, [_vm._v("批量导入")])], 1), _c("el-form-item", {
    attrs: {
      prop: "inspected_user_ids"
    }
  }, [_c("MgvMemberMultiSelect", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.selectType === "select",
      expression: "selectType === 'select'"
    }],
    staticClass: "member-select",
    attrs: {
      placeholder: "待质检成员",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "request-search-params": {
        module_type: "compliance"
      },
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["compliance"])
      }
    },
    on: {
      change: _setup.inspectedUserChange
    }
  }), _c(_setup.UploadMemberFile, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.selectType === "import",
      expression: "selectType === 'import'"
    }],
    on: {
      changeUploadFile: _setup.changeUploadFile
    }
  })], 1)], 1), _c(_setup.FormItemCard, {
    staticClass: "select-member",
    attrs: {
      title: "质检员",
      tooltip: "最多可选100人"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "compliance_user_ids"
    }
  }, [_c("MgvMemberMultiSelect", {
    staticClass: "member-select",
    attrs: {
      placeholder: "质检员",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "request-search-params": {
        module_type: "compliance"
      },
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["compliance"])
      }
    },
    on: {
      change: _setup.complianceUserChange
    }
  })], 1)], 1), _c(_setup.FormItemCard, {
    staticClass: "select-member",
    attrs: {
      title: "分配策略"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _setup.formData.allocate_type,
      callback: function ($$v) {
        _vm.$set(_setup.formData, "allocate_type", $$v);
      },
      expression: "formData.allocate_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("平均分配")]), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("自定义分配")])], 1), _setup.disabled ? _c("div", {
    staticClass: "average-tip"
  }, [_vm._v(" 请先选择待质检成员和质检员 ")]) : [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.formData.allocate_type === 2,
      expression: "formData.allocate_type === 2"
    }],
    staticClass: "lowest-ratio-tip"
  }, [_vm._v("分配比例至少 " + _vm._s(_setup.lowestRatio) + "%，否则将分配 0 人")]), _c(_setup.CustomAssignTable, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.formData.allocate_type === 2,
      expression: "formData.allocate_type === 2"
    }],
    attrs: {
      "member-list": _setup.complianceUserList,
      "lowest-ratio": _setup.lowestRatio
    },
    on: {
      changeRatio: _setup.changeRatio
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.formData.allocate_type === 1,
      expression: "formData.allocate_type === 1"
    }],
    staticClass: "average-tip"
  }, [_c("span", [_vm._v("质检员共 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_setup.complianceUserList.length) + " 人")])]), _c("span", [_vm._v("每人约分配 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_setup.averageRatio))]), _vm._v(" 个待质检成员")]), _setup.remainder > 0 ? _c("span", [_vm._v("有 " + _vm._s(_setup.remainder) + " 人分配 0 个待质检成员")]) : _vm._e()])]], 2)], 1)], 1), _c("footer", [_c("el-button", {
    on: {
      click: _setup.goBack
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.btnLoading
    },
    on: {
      click: _setup.confirm
    }
  }, [_vm._v("完成新建")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;