"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/views/business/components/dealFilter/index"));
var _TimeRangeFilter = _interopRequireDefault(require("@/views/conversationPage/components/TimeRangeFilter.vue"));
var _TimeRange = _interopRequireDefault(require("@/components/TimeRange"));
var _conversationList = require("@/api/conversationList");
var _AnalysisConfigMixin = _interopRequireDefault(require("./AnalysisConfigMixin"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const conversationDimensionTarget = ['max_sales', 'max_customer', 'percent_speak', 'discuss', 'question'];
const conversationActivityTarget = ['count', 'sum_duration', 'avg_duration', 'avg_person_count', 'avg_person_duration'];
var _default = {
  name: 'AnalysisConfig',
  components: {
    DealCustomFilter: _index.default,
    TimeRangeFilter: _TimeRangeFilter.default,
    TimeRange: _TimeRange.default
  },
  mixins: [_AnalysisConfigMixin.default],
  props: {
    configs: {
      type: Object,
      default: () => ({})
    },
    formConfig: {
      type: Object,
      default: () => ({})
    },
    showConvDuration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateAnalysixTargetValue = (rule, value, callback) => {
      if (!this.configs.analysis_target_vals.toString().length) {
        callback(new Error('请选择指标'));
      } else {
        callback();
      }
    };
    return {
      orignConfig: {
        // 分析维度：非指标卡片有,默认值为时间day
        dimension: 'day',
        // 分析对象：会话指标，商机指标，SOP监测项
        analysis_target: 'conversation',
        // 分析对象一级选中类型：会话指标，SOP监测项里的商机总数
        analysis_target_key: '',
        conversation_type: [],
        conversation_scene_type: [],
        conversation_duration: {
          // 会话时长
          unit: 'second',
          value: [0, 'unlimit']
        },
        space_conv_total_duration: {
          // 交易会话总时长
          unit: 'second',
          value: ['unlimit', 'unlimit']
        },
        // 分析对象一级选中值：
        // 会话指标无，SOP监测项里的商机总数无
        // SOP监测项里的关键事件和业务转化卡点选择关键事件级联(后端的event_id字段仅在sop时才对应关键事件)，其他为自定义关键事件组件
        analysis_target_vals: '',
        // 绘图方式：会话指标无，SOP监测项里的业务卡点转化无
        draw_style: 'count',
        // 关键结果：仅业务卡点展示
        strategy: {
          // 关键结果类型：自定义字段，商机阶段
          // 非业务卡点是默认值为none
          results_type: '',
          // 关键结果key
          key: '',
          // 关键结果value
          vals: []
        }
      },
      validateAnalysixTargetValue: {
        validator: validateAnalysixTargetValue,
        message: '请选择指标',
        trigger: 'change'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    // 根据分析对象选择的指标值返回对应的指标值
    getDealCustomFilterValue() {
      var _this$getDealCustomCo;
      return ((_this$getDealCustomCo = this.getDealCustomConfig) === null || _this$getDealCustomCo === void 0 ? void 0 : _this$getDealCustomCo.type) === 'select' ? this.configs.analysis_target_vals.toString() : this.configs.analysis_target_vals;
    },
    hasConversationType() {
      return [...conversationActivityTarget, ...conversationDimensionTarget].includes(this.configs.analysis_target_key);
    },
    isDimensionTarget() {
      return conversationDimensionTarget.includes(this.configs.analysis_target_key);
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getEventOptions();
      },
      immediate: true
    }
  },
  methods: {
    resetPreview() {
      this.$emit('resetPreview');
    },
    // 修改分析对象
    changeAnalysisTargetType() {
      this.resetChartConfig(['analysis_target', 'dimension', 'name']);
    },
    // 修改分析对象指标
    changeAnalysisTargetKey() {
      this.resetChartConfig(['analysis_target', 'analysis_target_key', 'dimension', 'name']);
    },
    // 改变分析对象指标值
    customFilterChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.analysis_target_vals = val[this.configs.analysis_target_key];
      this.$emit('resetPreview');
    },
    // 改变关键结果指标值
    resultsCustomFilterChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.strategy.vals = val[this.configs.strategy.key];
      this.$emit('resetPreview');
    },
    // 修改关键结果类型
    changeKeyEventResultsType(val) {
      const eventId = this.configs.analysis_target_vals;
      this.resetChartConfig(['analysis_target', 'analysis_target_key', 'dimension', 'name']);
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.strategy.results_type = val;
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.analysis_target_vals = eventId;
    },
    // 修改关键结果key
    changeKeyEventResultsKey() {
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.strategy.vals = this.$options.data().orignConfig.strategy.vals;
      this.$emit('resetPreview');
    },
    /*
     * 用途：重置配置数据:
     * params: Array 包含不清空的key
     */
    resetChartConfig() {
      let whiteList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      Object.keys(this.configs).filter(item => !whiteList.includes(item)).forEach(item => {
        this.$set(this.configs, item, this.$options.data().orignConfig[item]);
      });
      this.$emit('clearValidate');
      this.$emit('resetPreview');
    },
    async getEventOptions() {
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        if (!res.results.events) return;
        this.eventOptions = res.results.events;
      }
    },
    conversationDurationChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.conversation_duration.value = val;
    },
    durationValidStatusChange(val) {
      this.$emit('durationValidStatusChange', val);
    },
    handleDealDuationChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.configs.space_conv_total_duration.value = val;
    }
  }
};
exports.default = _default;