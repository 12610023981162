"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list.length ? _c("div", {
    staticClass: "media-list"
  }, [_c("div", {
    staticClass: "number"
  }, [_c("i", {
    staticClass: "iconfont icon-Mediafiles"
  }), _vm._v(" " + _vm._s(_vm.total) + "个音视频文件 ")]), _vm.list.length ? _c("div", {
    staticClass: "container"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("snippet-list-card", {
      key: index,
      attrs: {
        snippet: item
      },
      on: {
        operation: function ($event) {
          return _vm.$emit("operation", $event);
        }
      }
    });
  }), 1) : _vm._e(), _vm.list.length !== _vm.total ? _c("div", {
    staticClass: "load-more",
    on: {
      click: function ($event) {
        return _vm.$emit("moreMedia");
      }
    }
  }, [_vm._v(" 加载更多 ")]) : _vm._e()]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;