"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HotData = _interopRequireDefault(require("./HotData"));
var _ConvFlowDropdown = _interopRequireDefault(require("@/components/FolderTree/ConvFlowDropdown"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvFlowCard',
  components: {
    HotData: _HotData.default,
    ConvFlowDropdown: _ConvFlowDropdown.default
  },
  props: {
    folder: {
      type: Object,
      default: () => ({})
    }
  }
};
exports.default = _default;