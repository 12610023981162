"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _coachingCenter = require("@/api/coachingCenter");
var _ScoreRangeSelect = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/ScoreRangeSelect"));
var _vue = require("vue");
var _index = require("@/i18n/index.js");
var _courseType = _interopRequireDefault(require("@/views/coaching/coach/course/course-type.js"));
var _taskStatus = _interopRequireDefault(require("@/views/coaching/coach/coachTask/task-status.js"));
var _useRouter = require("@/hooks/use-router");
var _getDateRange = require("@/utils/getDateRange");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TaskDetailFIlters',
  props: {},
  emits: 'change',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const INIT_FILTER = {
      plan_name: '',
      is_complete: '',
      category: '',
      course_name: '',
      course_type: '',
      user_ids: [],
      tree_ids: [],
      score: [],
      // 任务开始时间
      plan_start_time: (0, _getDateRange.getDateRangePastToNow)(30, 'd'),
      // 练习开始时间
      user_start_time: []
    };
    const dateType = (0, _vue.ref)('task_start_time');
    const dateTime = (0, _vue.ref)((0, _getDateRange.getDateRangePastToNow)(30, 'd'));
    const cloneInitFilter = () => (0, _cloneDeep.default)(INIT_FILTER);
    const filters = (0, _vue.ref)(cloneInitFilter());
    const statusOptions = [{
      label: '未完成',
      value: 0
    }, {
      label: '已完成',
      value: 1
    }];
    const courseTypeOptions = _courseType.default.getOptions();
    const taskTypeOptions = (0, _vue.ref)([]);
    (0, _vue.onMounted)(() => {
      getTaskTypeOptions();
    });
    const backfillList = (0, _vue.ref)([]);
    const route = (0, _useRouter.useRoute)();
    (0, _vue.onActivated)(() => {
      const coachDataAnalysisTaskDetailFilter = sessionStorage.getItem('coachDataAnalysisTaskDetailFilter');
      if (coachDataAnalysisTaskDetailFilter) {
        const {
          fillArr,
          transFilter
        } = JSON.parse(coachDataAnalysisTaskDetailFilter);
        console.log(fillArr, 'fillArr');
        console.log(transFilter, 'transFilter');
        filters.value = {
          ...cloneInitFilter(),
          ...transFilter
        };
        dateType.value = filters.value.user_start_time.length ? 'task_practice_time' : 'task_start_time';
        dateTime.value = filters.value.user_start_time.length ? filters.value.user_start_time : filters.value.plan_start_time || (0, _getDateRange.getDateRangePastToNow)(30, 'd');
        backfillList.value = fillArr;
        sessionStorage.removeItem('coachDataAnalysisTaskDetailFilter');
      } else {
        customChange();
      }
    });
    const customChange = () => {
      const _filter = {
        ...filters.value,
        date: filters.value.date || [],
        is_complete: filters.value.is_complete === '' ? -1 : filters.value.is_complete,
        category: filters.value.category === '' ? -1 : filters.value.category,
        course_type: filters.value.course_type || 0
      };
      emit('change', _filter);
    };
    async function getTaskTypeOptions() {
      const res = await (0, _coachingCenter.getTaskCategoryList)();
      if (res.code === 20000) {
        taskTypeOptions.value = [...res.results.data, {
          id: 0,
          name: (0, _index.t)('coaching.无分类')
        }];
      }
    }
    const onDatePickerChange = () => {
      (0, _vue.nextTick)(() => {
        dateTime.value = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      });
      if (dateType.value === 'task_start_time') {
        filters.value.user_start_time = [];
        filters.value.plan_start_time = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      } else {
        filters.value.plan_start_time = [];
        filters.value.user_start_time = dateTime.value ? dateTime.value : (0, _getDateRange.getDateRangePastToNow)(30, 'd');
      }
      customChange();
    };
    const onScoreRangeChange = scoreRange => {
      filters.value.score = scoreRange;
      customChange();
    };
    const onMemberChange = _ref2 => {
      let {
        user_ids,
        tree_ids
      } = _ref2;
      filters.value.user_ids = user_ids;
      filters.value.tree_ids = tree_ids;
      customChange();
    };
    const exportTable = () => {
      emit('exportTable');
    };
    const moreFilterCount = (0, _vue.computed)(() => {
      const {
        category,
        score,
        course_name,
        course_type,
        is_complete
      } = filters.value;
      return [category !== '', score.length, course_name, course_type, is_complete !== ''].reduce((acc, cur) => cur ? acc + 1 : acc, 0);
    });
    return {
      __sfc: true,
      props,
      emit,
      INIT_FILTER,
      dateType,
      dateTime,
      cloneInitFilter,
      filters,
      statusOptions,
      courseTypeOptions,
      taskTypeOptions,
      backfillList,
      route,
      customChange,
      getTaskTypeOptions,
      onDatePickerChange,
      onScoreRangeChange,
      onMemberChange,
      exportTable,
      moreFilterCount,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      ScoreRangeSelect: _ScoreRangeSelect.default
    };
  }
};
exports.default = _default;