"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TimeRange = _interopRequireDefault(require("@/components/TimeRange"));
var _AmountRangeInput = _interopRequireDefault(require("@/components/AmountRange/AmountRangeInput"));
var _EventOrderTracker = _interopRequireDefault(require("@/views/conversationPage/components/EventOrderTracker.vue"));
var _TextSearch = _interopRequireDefault(require("@/views/conversationPage/components/TextSearch"));
var _EventTracker = _interopRequireDefault(require("@/views/conversationPage/components/EventTracker.vue"));
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _MultipleCascaderTracker = _interopRequireDefault(require("@/components/Filters/components/MultipleCascaderTracker.vue"));
var _CumulativeScoreRule = _interopRequireDefault(require("./CumulativeScoreRule.vue"));
var _HitCountScoreRule = _interopRequireDefault(require("./HitCountScoreRule.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScoreItemConfig',
  components: {
    TimeRange: _TimeRange.default,
    AmountRangeInput: _AmountRangeInput.default,
    TextSearch: _TextSearch.default,
    EventTracker: _EventTracker.default,
    EventOrderTracker: _EventOrderTracker.default,
    MultipleCascaderTracker: _MultipleCascaderTracker.default,
    CumulativeScoreRule: _CumulativeScoreRule.default,
    HitCountScoreRule: _HitCountScoreRule.default
  },
  props: {
    scoreItem: {
      type: Object,
      default: () => ({})
    },
    scoreMode: {
      type: String,
      default: 'increase'
    }
  },
  data() {
    return {
      rules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }]
      },
      scoreTypeList: [{
        value: 1,
        label: '按是否命中计分'
      }, {
        value: 2,
        label: '按累计命中计分'
      }, {
        value: 3,
        label: '按命中项数计分'
      }],
      configUnitList: ['条', '%', '次', '字/分钟'],
      roleOptions: [{
        value: 1,
        label: '客户'
      }, {
        value: 2,
        label: '销售'
      }],
      scoreExtItems: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['typeOptions', 'accountTagOptionsList']),
    getTotalScore() {
      let total = 0;
      this.scoreExtItems.forEach(item => {
        if (this.scoreItem.score_type === 2 && item.gte && item.lte && item.score) {
          total += (Number(item.lte) - Number(item.gte) + 1) * item.score;
        }
        if (this.scoreItem.score_type === 3 && item.score && total < item.score) {
          total = Number(item.score);
        }
      });
      return total;
    }
  },
  watch: {
    'scoreItem.score_ext': {
      handler: function (val) {
        if (val) {
          this.scoreExtItems = JSON.parse(val).items;
        } else if (this.scoreItem.score_type === 2) {
          this.scoreExtItems = [{
            gte: '',
            lte: '',
            score: ''
          }];
        } else if (this.scoreItem.score_type === 3) {
          this.scoreExtItems = [{
            hits: '',
            score: ''
          }];
        }
      },
      immediate: true
    },
    scoreExtItems: {
      handler: function (val) {
        const score_ext = {
          score: this.getTotalScore,
          mode: this.scoreMode,
          items: val
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.score_ext = JSON.stringify(score_ext);
      },
      deep: true
    }
  },
  methods: {
    handleScoreType(type) {
      if (type === 2) {
        this.scoreExtItems = [{
          gte: '',
          lte: '',
          score: ''
        }];
        if (this.scoreItem.type !== 'key_words' && this.scoreItem.type !== 'event_engine') {
          this.initCondition();
        } else {
          if (this.scoreItem.type === 'key_words') {
            this.scoreItem.config.textFilterList.forEach(item => {
              item.inverse = false;
            });
          }
          if (this.scoreItem.type === 'event_engine') {
            this.scoreItem.config.keyEventCascaderList.forEach(item => {
              item.inverse = false;
            });
          }
        }
      }
      if (type === 3) {
        this.scoreExtItems = [{
          hits: '',
          score: ''
        }];
        this.initCondition();
      }
    },
    initCondition() {
      const type = Object.values(this.typeOptions)[0];
      // eslint-disable-next-line vue/no-mutating-props
      this.scoreItem.type = ['key_words', 'event_engine'].includes(type === null || type === void 0 ? void 0 : type.value) ? type.value : '';
      if (this.scoreItem.type) {
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.config = (0, _commonFunc.deepClone)(type.initRange);
      }
      if (type.value === 'key_words') {
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.config.filter_relation = 'or';
      }
      if (type.value === 'event_engine') {
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.config.event_engine_relation = 'or';
      }
    },
    addScoreRule() {
      if (this.scoreItem.score_type === 2) {
        const maxScore = this.scoreExtItems[this.scoreExtItems.length - 1].lte;
        this.scoreExtItems.push({
          gte: maxScore ? Number(maxScore) + 1 : '',
          lte: '',
          score: ''
        });
      }
      if (this.scoreItem.score_type === 3) {
        this.scoreExtItems.push({
          hits: '',
          score: ''
        });
      }
    },
    deleteScoreRules(index) {
      this.scoreExtItems.splice(index, 1);
    },
    handleScore(item, index) {
      if (item.lte && this.scoreExtItems[index + 1]) {
        this.scoreExtItems[index + 1].gte = Number(item.lte) + 1;
      }
    },
    handleScoreInput(val, item) {
      item.score = ('' + val // 第一步：转成字符串
      ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },

    changeRuleType(type, item) {
      var _this$typeOptions$typ;
      const initRange = (_this$typeOptions$typ = this.typeOptions[type]) === null || _this$typeOptions$typ === void 0 ? void 0 : _this$typeOptions$typ.initRange;
      item.config = typeof initRange === 'object' ? (0, _commonFunc.deepClone)(initRange) : initRange;
    },
    handleAccountTagChange(val) {
      if (val.account_label) {
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.config = val.account_label;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.scoreItem.config = {
          relation: 'and',
          value: []
        };
      }
    },
    handleEventEngineChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.scoreItem.config = val;
    }
  }
};
exports.default = _default;