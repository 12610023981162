"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RankingSnippestListCard = _interopRequireDefault(require("./RankingSnippestListCard"));
var _FolderCard = _interopRequireDefault(require("./FolderCard"));
var _RankingNoSnippet = _interopRequireDefault(require("./RankingNoSnippet"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetsList',
  components: {
    RankingSnippestListCard: _RankingSnippestListCard.default,
    FolderCard: _FolderCard.default,
    NoContent: _RankingNoSnippet.default
  },
  inject: ['changeCurrentNode'],
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rankingInfo: {
      type: Object,
      default: () => {
        return {
          icon: 'icon-hot',
          color: '#F43F7C',
          title: '本月最热剪辑',
          cardType: 'snippet'
        };
      }
    }
  }
};
exports.default = _default;