"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentMultipleSelect = _interopRequireDefault(require("@/components/DepartmentMultipleSelect"));
var _ShowLongContent = _interopRequireDefault(require("@/components/ShowLongContent"));
var _organization = require("@/api/organization");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentAndWorkspaceMappingDialog',
  components: {
    DepartmentMultipleSelect: _DepartmentMultipleSelect.default,
    ShowLongContent: _ShowLongContent.default
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    departmentAndWorkspaceMappingList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 180,
      mappingInfo: {
        departmentIds: [],
        workspaceId: ''
      },
      treeData: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['hasWorkspace'])
  },
  async created() {
    this.getOrgTree();
  },
  methods: {
    async getOrgTree() {
      const res = await (0, _organization.getOrgTree)();
      this.treeData = res.results;
    },
    openPopover(row) {
      var _row$tree_list;
      let orgIdsArr = [];
      orgIdsArr = (_row$tree_list = row['tree_list']) === null || _row$tree_list === void 0 ? void 0 : _row$tree_list.reduce((pre, cur) => {
        pre = pre.concat(cur.id);
        return pre;
      }, []);
      orgIdsArr && this.$nextTick(() => {
        this.$refs[`org-tree${row.id}`].$refs['department-tree'].setCheckedKeys(orgIdsArr);
        this.$refs[`org-tree${row.id}`].handleCheckChange();
      });

      // 别问为什么doShow不加(),问就是element的坑
      this.$refs[`popover-${row.id}`].doShow;
      this.mappingInfo.workspaceId = row.id;
    },
    closePopover(index) {
      this.$refs[`popover-${index}`].doClose();
    },
    openConfirmDialog(index) {
      this.closePopover(index);
      this.$confirm('更新后，部门成员的主空间会发生改变，新产生的会话会进入新的空间里，之前的会话需要手动转移空间。确认更新？', '更新', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmToChangeDepartmentAndSpace();
        this.getOrgTree();
      }).catch(_ => {});
    },
    formatDepartmentList(list) {
      return list === null || list === void 0 ? void 0 : list.reduce((pre, cur) => {
        pre += `${cur.name}；`;
        return pre.replace(/，/g, ',');
      }, '');
    },
    selectDepartment(departmentIds) {
      this.mappingInfo.departmentIds = departmentIds;
    },
    closeDrawer() {
      this.$emit('closeDepartmentAndWorkspaceMappingDialog');
    },
    confirmToChangeDepartmentAndSpace() {
      this.$emit('confirmToChangeDepartmentAndSpace', this.mappingInfo);
    }
  }
};
exports.default = _default;