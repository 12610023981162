"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ResignedMemberTable = _interopRequireDefault(require("./components/ResignedMemberTable"));
var _commonFunc = require("@/utils/commonFunc");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ResignedMember',
  components: {
    ResignedMemberTable: _ResignedMemberTable.default
  },
  data() {
    return {
      listParams: {
        page: 1,
        size: 10
      },
      total: 0,
      filterName: '',
      tableData: [],
      tableLoading: false
    };
  },
  created() {
    this.getResignedMemberList();
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    handleRefresh() {
      this.listParams.page = 1;
      this.getResignedMemberList();
    },
    async getResignedMemberList() {
      this.tableLoading = true;
      const params = {
        page: this.listParams.page,
        size: this.listParams.size
      };
      if (this.filterName) params.filter = this.filterName;
      try {
        const res = await (0, _organization.getResignedMemberList)(params);
        if (res.code === 200) {
          this.tableData = res.results.users;
          this.total = res.results.count;
        }
      } finally {
        this.tableLoading = false;
      }
    },
    handleSizeChange(val) {
      this.listParams.size = val;
      this.listParams.page = 1;
      this.getResignedMemberList();
    },
    handleCurrentChange(val) {
      this.listParams.page = val;
      this.getResignedMemberList();
    },
    getFilterList: (0, _commonFunc.debounce)(function () {
      this.listParams.page = 1;
      this.getResignedMemberList();
    })
  }
};
exports.default = _default;