"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberInfoTag',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    source: {
      type: Object,
      default: () => ({})
    },
    isTaskDetail: {
      type: Boolean,
      default: false
    },
    isMoreTask: {
      type: Boolean,
      default: false
    },
    // 是否检查有无查看某成员数据的权限
    checkIsAccess: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDepartment() {
      return this.source.id.toString().includes('tree_id');
    }
  },
  methods: {
    deleteExecutor(item) {
      this.$emit('deleteExecutor', item);
    }
  }
};
exports.default = _default;