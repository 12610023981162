"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDataSet = useDataSet;
var _vue = require("vue");
var _dataSet = require("@/api/dataSet");
function useDataSet() {
  const requestParams = (0, _vue.ref)({
    dataset_name_or_id: '',
    dataset_folder_name: '',
    import_status: 0,
    annotation_type: 0,
    annotator: {},
    annotation_status: [],
    creator: {},
    create_at: [],
    update_at: [],
    order_by: 'update_at',
    order_type: 'descending'
  });
  const tableResults = (0, _vue.ref)({
    loading: false,
    tableData: [],
    total: 0
  });
  const paginationParams = (0, _vue.ref)({
    page: 1,
    size: 10
  });
  const getDataSetListApi = async () => {
    tableResults.value.loading = true;
    const params = {
      ...requestParams.value,
      annotation_type: requestParams.value.annotation_type || 0,
      import_status: requestParams.value.import_status || 0
    };
    const res = await (0, _dataSet.getDataSetList)({
      ...params,
      ...paginationParams.value
    }).finally(() => {
      tableResults.value.loading = false;
    });
    if (res.code === 20000) {
      tableResults.value.tableData = res.results.items;
      tableResults.value.total = res.results.total_count;
    }
  };
  const sizeChange = val => {
    paginationParams.value.size = val;
    paginationParams.value.page = 1;
    getDataSetListApi();
  };
  const currentChange = val => {
    paginationParams.value.page = val;
    getDataSetListApi();
  };
  const sortChange = (prop, order) => {
    requestParams.value.order_by = prop;
    requestParams.value.order_type = order;
    paginationParams.value.page = 1;
    getDataSetListApi();
  };
  const filterChange = filters => {
    requestParams.value = filters;
    paginationParams.value.page = 1;
    getDataSetListApi();
  };
  const refreshTable = () => {
    paginationParams.value.page = 1;
    getDataSetListApi();
  };
  return {
    getDataSetListApi,
    sizeChange,
    filterChange,
    currentChange,
    refreshTable,
    sortChange,
    tableResults,
    paginationParams
  };
}