"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "score-confirm-dialog",
    attrs: {
      title: _vm.$t("coaching.leavingTheCourse"),
      visible: _vm.visible,
      width: "400px",
      "before-close": _setup.closeDialog
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("i", {
    staticClass: "iconfont icon-warning"
  }), _c("p", [_vm._v(" " + _vm._s(_vm.$t("coaching.autoCompleteConfirmContent", [_vm.count])) + " ")])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _setup.autoComplete
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.autoCompletePoints")))]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("goBack");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.leave")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;