"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "stage-box"
  }, [_c("div", {
    staticClass: "filter-wrap"
  }, [_c("bi-filter", {
    attrs: {
      layout: ["tree_id", "user_ids", "create_time"],
      params: {
        type: JSON.stringify(["trade"])
      }
    },
    on: {
      filtersChange: _vm.getStageData
    }
  })], 1), _c("div", {
    staticClass: "chart-wrapper"
  }, [_c("stage-chart", {
    attrs: {
      data: _vm.chartData,
      "y-axis": _vm.yAxis,
      "transfer-rate": _vm.transferRate,
      "loss-rate": _vm.lossRate,
      "max-chart-value": _vm.maxChartValue
    }
  }), _c("div", {
    staticClass: "data-list"
  }, [_vm._m(0), _vm._l(_vm.rightList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "data-line",
      style: {
        marginBottom: `${405 / _vm.yAxis.length - 16}px`,
        marginTop: `${(405 / _vm.yAxis.length - 16) / 2}px`
      }
    }, [_c("div", {
      staticClass: "data-percent"
    }, [_vm._v(_vm._s(item.totalRate))]), _c("div", {
      staticClass: "data-time"
    }, [_vm._v(_vm._s(item.stayTime))])]);
  })], 2)], 1), _c("stage-table", {
    attrs: {
      data: _vm.tableData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "data-line data-title"
  }, [_c("div", {
    staticClass: "data-percent"
  }, [_vm._v("总转化率")]), _c("div", {
    staticClass: "data-time"
  }, [_vm._v("平均停留时长")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;