"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-prediction-result"
  }, [_c("div", {
    staticClass: "model-prediction-result__title"
  }, [_vm._v("模型预测结果")]), _c("div", {
    staticClass: "role-container"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v("按角色保留发言")]), _c("div", {
    staticClass: "role-container-content"
  }, _vm._l(_setup.postProcessTypeList, function (_ref) {
    let {
      value,
      label
    } = _ref;
    return _c("el-radio", {
      key: value,
      attrs: {
        disabled: _vm.readOnly,
        label: value,
        value: _vm.postProcessType
      },
      on: {
        input: _setup.postProcessTypeChange
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1)]), _c(_setup.LlmPostProcessing, _vm._g(_vm._b({
    ref: "LlmPostProcessingRef",
    attrs: {
      "read-only": _vm.readOnly
    }
  }, "LlmPostProcessing", _vm.$attrs, false), _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;