"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    attrs: {
      disabled: _vm.disabled,
      value: _vm.value,
      "no-data-text": "无版本"
    },
    on: {
      input: _setup.onInput,
      change: _setup.onChange
    }
  }, _vm._l(_setup.options, function (item) {
    return _c("el-option", {
      key: item.version,
      staticClass: "custom-options",
      attrs: {
        value: item.version,
        label: `V${item.version}${item.is_online ? "(已发布)" : ""}`,
        disabled: _vm.strictVersionDisable && item.train_status.status !== 2
      }
    }, [_c("span", [_vm._v("V" + _vm._s(item.version))]), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showStatus,
        expression: "showStatus"
      }]
    }, [_c("span", {
      staticClass: "dot",
      style: {
        background: _setup.getVersionStatus(item.train_status.status).color
      }
    }), _c("span", [_vm._v(_vm._s(_setup.getVersionStatus(item.train_status.status).text))])]), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.showStatus && item.is_online,
        expression: "!showStatus && item.is_online"
      }],
      staticClass: "is-published-label"
    }, [_vm._v(" 已发布 ")])]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;