"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    flipNum: {
      type: Number,
      default: 0
    },
    flipNumLoaded: {
      type: Number,
      default: 0
    },
    maxPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dateList: [],
      dateRange: [],
      today: this.$moment().format('YYYY-MM-DD'),
      scrollTrans: 0
    };
  },
  watch: {
    flipNum(val) {
      this.scrollTrans = val * 17 * 14;
      if (val > this.flipNumLoaded) {
        this.setDateList();
        this.$emit('update:flipNumLoaded', val);
      }
    }
  },
  methods: {
    setDateList(earliest_time) {
      // 根据当前页所有商机里面时间最早的数据设置分页的最后一页
      if (earliest_time !== undefined) {
        this.dateList = [];
        if (earliest_time == '') {
          this.$emit('update:maxPage', 0);
        } else {
          const dateOfInterval = this.$moment().diff(earliest_time, 'days');
          const maxPage = dateOfInterval < 45 ? 1 : Math.ceil((dateOfInterval - 44) / 15) + 2;
          this.$emit('update:maxPage', maxPage);
        }
      }
      // 根据当前已加载的数据量来去确定分页的数量，第一页45条数据，其后每次分页15条
      const totleDays = this.dateList.length;
      const nextStep = totleDays >= 44 ? 14 : 44;
      // 根据下次要请求的数据量填充时间轴
      const dateArr = [];
      for (let i = totleDays + nextStep; i >= totleDays; i--) {
        const time = this.$moment().subtract(i, 'days');
        dateArr.push({
          date: time.format('YYYY-MM-DD'),
          isBold: parseInt(time.format('DD')) % 5 === 0
        });
      }
      this.dateList.unshift(...dateArr);

      // 当非第一页且最后一页未加载时触发加载分页的请求
      if (this.flipNum && this.maxPage - this.flipNum > 1 && dateArr.length) {
        this.dateRange = [dateArr[0].date, dateArr[dateArr.length - 1].date];
        this.$emit('getNextSituation');
      }
    },
    isVisibleDate(date) {
      const tomorrow = this.$moment().add(1, 'days').format('YYYY-MM-DD');
      const yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
      return date !== this.today && date !== tomorrow && date !== yesterday;
    }
  }
};
exports.default = _default;