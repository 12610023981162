"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item) {
    return _c("el-tab-pane", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading"
      }],
      key: item.name,
      attrs: {
        label: item.label,
        name: item.name || item.label,
        lazy: ""
      }
    }, [_c("div", {
      staticClass: "tab-label-container",
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v(_vm._s(item.label))]), item.number ? _c("span", {
      staticClass: "num"
    }, [_vm._v(" (" + _vm._s(item.number) + ") ")]) : _vm._e()]), _vm._t("content", null, {
      content: item
    })], 2);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;