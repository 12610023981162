"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "AssociateContactForm",
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    dealId: {
      type: Number | String
    },
    contactList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: {
        account_id: "",
        is_main: "",
        contact_id: ""
      }
    };
  },
  computed: {
    formDesc() {
      return [{
        id: "contact_id",
        label: "联系人",
        type: "select",
        required: true,
        halfRow: true,
        options: this.contactList
      }, {
        id: "is_main",
        label: "关键决策人",
        type: "select",
        halfRow: true,
        options: [{
          label: "是",
          value: "1"
        }, {
          label: "否",
          value: "0"
        }]
      }];
    }
  },
  watch: {
    contactList() {
      this.$nextTick(() => {
        this.$refs["dynamicForm"] && this.$refs["dynamicForm"].clearValidate();
      });
    }
  },
  methods: {
    resetForm() {
      this.$refs["dynamicForm"].resetFields();
    },
    clearValidate() {
      this.$refs["dynamicForm"].clearValidate();
    }
  }
};
exports.default = _default;