"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    ref: "dealKeyEventOrderPopoverRef",
    attrs: {
      placement: "bottom-start",
      "popper-class": _vm.popperClass,
      trigger: "click"
    }
  }, [_c("event-order-tracker", {
    attrs: {
      filters: _vm.value,
      "key-events": _vm.keyEvents
    },
    on: {
      addKeyEventCascader: _vm.addKeyEventOrderCascader,
      change: _vm.handleChange
    }
  }), _c("el-button", {
    staticClass: "button-pretend-input",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("el-tooltip", {
    attrs: {
      content: _vm.orderText,
      placement: "top",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "order-text-input"
  }, [_vm._v(_vm._s(_vm.orderText))])]), !_vm.orderText ? _c("span", {
    staticClass: "label-text"
  }, [_vm._v("请选择关键事件顺序")]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;