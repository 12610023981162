"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ItemsFlowTemp',
  components: {
    ImageAvatar: _ImageAvatar.default,
    VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default
  },
  props: {
    customerName: {
      type: String,
      default: ''
    },
    infos: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'busiActiveConver', 'has_video_permission']),
    getDuration() {
      return function (duration) {
        const {
          h,
          m,
          s
        } = (0, _timeFormatter.second2HMS)(duration);
        if (h) {
          if (m) {
            return h + 'h ' + m + 'm';
          } else {
            return h + 'h';
          }
        } else {
          if (m) {
            return m + 'm';
          } else {
            return s + 's';
          }
        }
      };
    },
    getTimeText() {
      return function (time) {
        return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
      };
    },
    getIconType() {
      return function (type, status) {
        if (type === 'audio') {
          return 'phone';
        } else if (type === 'doc') {
          return 'im-fill';
        } else {
          if (status === 'expire') {
            return 'video-camera';
          } else {
            return 'video-camera-solid-2';
          }
        }
      };
    },
    getIsShowButton() {
      return function (dateItem) {
        return dateItem.conversation_status === 'attend' || dateItem.conversation_status === 'recorded' || dateItem.conversation_status === 'gotfiles' || dateItem.conversation_status !== 'expire' && dateItem.type === 'video' && this.has_video_permission;
      };
    },
    getHoverIcon() {
      return function (item) {
        switch (item.type) {
          case 'audio':
            return 'iconfont icon-phone-outline';
          case 'doc':
            return 'iconfont icon-im';
          default:
            return 'iconfont icon-video-camera';
        }
      };
    },
    getHoverText() {
      return function (item) {
        if (item.conversation_status === 'attend' || item.conversation_status === 'recorded' || item.conversation_status === 'gotfiles') {
          return '会话详情';
        }
        if ((item.conversation_status === 'plan' || item.conversation_status === 'recording') && item.type === 'video') {
          return '进入会议';
        }

        // if (item.type === "audio") {
        //   return "呼叫";
        // } else if (item.type === "video") {
        //   return "进入会议";
        // }
      };
    }
  },

  methods: {
    goTargetPage(item) {
      if (item.conversation_status === 'attend' || item.conversation_status === 'recorded' || item.conversation_status === 'gotfiles') {
        this.$router.push({
          path: _routeMap.ROUTES.conversationDetail,
          query: {
            id: item.id
          }
        });
      } else {
        if (item.type === 'audio') {
          this.$emit('callOut', item);
        } else if (item.type === 'video') {
          if (!this.has_video_permission) {
            this.$message.error('暂无访问视频会议权限');
            return;
          }
          if (item.meeting_type === 'tencent_meeting') {
            window.open(item.meeting_url, '_blank');
            return;
          }
          const interUrl = window.location.protocol + '//' + window.location.host + '/vri?c=' + item.code;
          window.open(interUrl, '_blank');
        }
      }
    },
    selectItem(id) {
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
    }
  }
};
exports.default = _default;