"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.interfacePermission.createConvFlow ? _c("el-dropdown", {
    staticClass: "conv-flow-dropdown",
    attrs: {
      placement: "bottom-end",
      trigger: "click"
    },
    on: {
      command: function ($event) {
        return _vm.$emit("command", $event);
      }
    }
  }, [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "conv-flow-operate-btn"
    }, [_c("i", {
      staticClass: "iconfont icon-more"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "snippets-library-dropdown-menu-common",
    staticStyle: {
      "min-width": "182px"
    },
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.getContentType() === "collect" && !_vm.isTree ? _c("el-dropdown-item", {
    attrs: {
      command: "discollect"
    }
  }, [_vm._v("取消收藏")]) : [_c("el-dropdown-item", {
    attrs: {
      command: "edit"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit"
  }), _vm._v("编辑")]), _c("el-dropdown-item", {
    attrs: {
      command: "delete"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-delete"
  }), _vm._v("删除")])]], 2)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;