"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-badge", {
    staticClass: "message-dot",
    attrs: {
      "is-dot": "",
      hidden: !_vm.unread
    }
  }, [_c("div", {
    staticClass: "avatar-icon",
    class: _vm.renderInfo.icon_type
  }, [_c("i", {
    class: ["iconfont", _vm.getIconName(_vm.renderInfo.icon_type)]
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;