"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "workspace-list-box"
  }, [_c("div", {
    staticClass: "box-title-content"
  }, [_c("div", {
    staticClass: "box-title"
  }, [_vm._v("空间")]), _c("space-description-popover")], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.workspaceLoading,
      expression: "workspaceLoading"
    }],
    staticClass: "workspace-list"
  }, _vm._l(_vm.workspaceList, function (item, index) {
    return _c("div", {
      key: item.id
    }, [_c("div", {
      staticClass: "workspace-item",
      class: {
        selected: item.id === _vm.curWorkspaceId
      },
      on: {
        click: function ($event) {
          return _vm.switchWorkspace(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color
      }
    }), _c("div", {
      staticClass: "workspace-info"
    }, [_c("div", {
      staticClass: "workspace-title"
    }, [_c("div", [_vm._v(" " + _vm._s(item.name)), item.is_main ? _c("span", {
      staticClass: "main-workspace-tag"
    }, [_vm._v("主空间")]) : _vm._e()])]), _c("p", [_vm._v(_vm._s(item.description))])]), _c("i", {
      staticClass: "el-icon-check"
    })]), !index && _vm.workspaceList.length > 1 ? _c("el-divider") : _vm._e()], 1);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;