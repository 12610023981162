"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RenameDialog = _interopRequireDefault(require("@/components/CommonComponents/RenameDialog.vue"));
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FiltersManage',
  components: {
    CommonRenameDialog: _RenameDialog.default
  },
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    }
  },
  props: {
    selectValue: {
      type: String,
      default: ''
    },
    filterNumber: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      // 筛选器保存按钮能否点击
      saveFilterDisable: true,
      submitLoading: false,
      rules: {
        filterName: [{
          required: true,
          message: '请输入名字',
          trigger: 'blur'
        }]
      },
      inputInfo: {
        title: '保存为常用筛选',
        tips: '将当前设定条件保存为一个组合，保存成功后在顶端的“常用筛选”中选择可立即执行筛选',
        label: '常用筛选名称',
        errorText: '请输入常用筛选名称',
        placeholder: '请输入常用筛选名称',
        name: ''
      },
      filterName: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  methods: {
    clearAll() {
      this.$emit('clearAll');
    },
    // 打开保存筛选器的dialog
    handleSaveClick() {
      this.filterName = '';
      this.dialogVisible = true;
    },
    // 保存按钮里面的Form提交
    submitForm(_ref) {
      let {
        name
      } = _ref;
      this.filterName = name;
      this.submitLoading = true;
      // 告诉父组件格式化数据，再通过父组件触发保存数据的方法
      this.$emit('formatData', true);
    },
    // 保存筛选器请求
    async postSaveFilter(filters) {
      const res = await (0, _conversationList.postSaveFilter)(this.filterName, filters, this.workspaceInfo.id).finally(() => {
        this.submitLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success('保存成功');
        this.dialogVisible = false;
        this.$emit('update:selectValue', this.filterName);
        this.saveFilterDisable = true;
      }
    },
    cancel() {
      this.dialogVisible = false;
      this.submitLoading = false;
    }
  }
};
exports.default = _default;