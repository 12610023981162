"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TransferUserInfo',
  props: {
    transferFromUserInfo: {
      type: Object,
      default: () => ({})
    },
    isDepartment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowTooltip: false
    };
  },
  computed: {
    mainDepartmentName() {
      const mainDepartment = this.transferFromUserInfo.department.find(item => item.is_main);
      if (mainDepartment) {
        return mainDepartment.name;
      } else {
        return '';
      }
    },
    otherDepartmentName() {
      const otherDepartmentName = [];
      const {
        department
      } = this.transferFromUserInfo;
      if (department.length) {
        department.forEach(item => {
          if (!item.is_main) {
            otherDepartmentName.push(item.name);
          }
        });
      }
      return otherDepartmentName;
    }
  },
  methods: {
    tooltipVisibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth;
      const content_weight = ev.clientWidth;
      if (ev_weight > content_weight) {
        this.isShowTooltip = true;
      } else {
        this.isShowTooltip = false;
      }
    }
  }
};
exports.default = _default;