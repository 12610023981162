"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conv-info"
  }, [_vm.salesman ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "销售",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "salesman"
  }, [_c("image-avatar", {
    attrs: {
      avatar: _vm.salesman.avatar,
      name: _vm.salesman.name
    }
  }), _c("span", {
    staticClass: "describe-size"
  }, [_vm._v(_vm._s(_vm.salesman.name))])], 1)]) : _vm._e(), _vm.convDuration ? [_c("b", [_vm._v("·")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话时长",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "describe-size"
  }, [_vm._v(_vm._s(_vm.convDuration))])])] : _vm._e(), _vm.convTime ? [_c("b", [_vm._v("·")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话时间",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "conv-time"
  }, [_c("div", {
    staticClass: "conv-date describe-size"
  }, [_vm._v(_vm._s(_vm.convDate))]), _c("div", {
    staticClass: "describe-size"
  }, [_vm._v(_vm._s(_vm.convTime))])])])] : _vm._e(), _vm.tradeName ? [_c("b", [_vm._v("·")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "交易",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "trade-name",
    on: {
      click: _vm.goTradePage
    }
  }, [_c("i", {
    staticClass: "iconfont icon-deal4"
  }), _c("span", {
    staticClass: "describe-size"
  }, [_vm._v(_vm._s(_vm.tradeName))])])])] : _vm._e(), _vm.info.workspace ? [_c("b", [_vm._v("·")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "所属空间",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "conv-workspace"
  }, [_c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.info.workspace.icon_color
    }
  }), _c("span", {
    staticClass: "describe-size"
  }, [_vm._v(_vm._s(_vm.info.workspace.name))])])])] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;