"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-score-correction"
  }, [_c("navbar", {
    attrs: {
      title: "分数矫正",
      "back-button": true
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-score-correction-wrapper"
  }, [_c("KnowledgeScoreCorrectionAside", {
    attrs: {
      "all-knowledge-list": _vm.allKnowledgeList
    },
    on: {
      activeTabsChange: _vm.activeTabsChange
    }
  }), _c("KnowledgeScoreCorrectionRightPart", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.rightPartLoading,
      expression: "rightPartLoading"
    }],
    staticClass: "right-part",
    attrs: {
      "active-tab-name": _vm.activeTabName,
      "active-tab-knowledge-id": _vm.activeTabKnowledgeId,
      "score-correction-content-list": _vm.scoreCorrectionContentList
    },
    on: {
      correctScoreSuccess: _vm.correctScoreSuccess
    }
  })], 1), _c("KnowledgeScoreTipsDialog", {
    attrs: {
      visible: _vm.knowledgeScoreTipsDialogVisible
    },
    on: {
      closeKnowledgeScoreTipsDialog: _vm.closeKnowledgeScoreTipsDialog
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;