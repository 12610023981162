"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "sentence-image"
  }, [_c("el-image", {
    ref: "imgRef",
    class: [`type-${_setup.imgType}`],
    style: {
      minWidth: `${_setup.min}px`,
      minHeight: `${_setup.min}px`
    },
    attrs: {
      src: _vm.src,
      fit: "cover",
      "append-to-body": _vm.imageAppendToBody,
      "preview-src-list": _vm.imageList,
      "initail-index": _vm.imageIndex
    },
    on: {
      load: _setup.imageLoaded
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: true,
      expression: "true"
    }],
    staticClass: "image-placeholder",
    attrs: {
      slot: "placeholder"
    },
    slot: "placeholder"
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;