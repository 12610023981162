"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "intelligent-extract-container"
  }, [_c("div", {
    staticClass: "upload-container"
  }, [_c("div", {
    staticClass: "upload-title"
  }, [_vm._v("选择素材")]), _c("el-upload", {
    ref: "uploadRef",
    attrs: {
      action: "",
      multiple: "",
      drag: "",
      accept: _setup.ACCEPT_FILE_TYPE,
      "file-list": _setup.uploadFileList,
      "show-file-list": false,
      "auto-upload": false,
      "on-change": _setup.onChange
    }
  }, [_c("div", {
    staticClass: "upload-center-container"
  }, [_c("i", {
    staticClass: "iconfont icon-document-add-fill"
  }), _c("div", {
    staticClass: "drag-text"
  }, [_vm._v("将文件拖到此处，或"), _c("span", [_vm._v("选择文件")])]), _c("span", {
    staticClass: "tip"
  }, [_vm._v("文件最多5个，每个文件的有效提取范围为前3000字，字数超出时建议拆为多个文件上传；")]), _c("span", {
    staticClass: "tip"
  }, [_vm._v("文件内容为客户提问+销售回答的结构时，提取效果最好。"), _c("span", {
    staticClass: "download",
    on: {
      click: _setup.downloadTemplate
    }
  }, [_vm._v("下载参考模板")])]), _c("span", {
    staticClass: "tip"
  }, [_vm._v("支持文件类型：.doc .docx .pdf .txt .text .pptx .xml")])])])], 1), _setup.uploadFileList.length ? _c("div", {
    staticClass: "file-list-container"
  }, _vm._l(_setup.uploadFileList, function (file, index) {
    return _c(_setup.UploadFileItem, {
      key: file === null || file === void 0 ? void 0 : file.uid,
      attrs: {
        file: file
      },
      on: {
        deleteFile: _setup.deleteFile,
        changeFileState: function ($event) {
          return _setup.changeFileState($event, index);
        }
      }
    });
  }), 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;