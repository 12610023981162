"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SubQuestionDetailDrawer = _interopRequireDefault(require("./SubQuestionDetailDrawer"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _useVuex = require("@/hooks/use-vuex");
var _elementUi = require("element-ui");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SubQuestionList',
  props: {
    currentQuestion: {
      type: Object,
      default: () => ({})
    }
  },
  emits: 'refresh',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const subQuestionsList = (0, _vue.ref)([]);
    const isNewSplitQuestion = (0, _vue.ref)(false);
    const loading = (0, _vue.ref)(false);
    const fetchSubQuestionList = async () => {
      loading.value = true;
      const res = await (0, _businessIntelligence.getSubQuestionList)({
        page: 1,
        size: 10,
        workspace_id: workspaceId.value,
        q_f_id: props.currentQuestion.id
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        const {
          is_new_split,
          data
        } = res.results;
        subQuestionsList.value = data;
        isNewSplitQuestion.value = is_new_split;
      }
    };
    (0, _vue.watch)(() => props.currentQuestion, val => {
      if (val.id) {
        fetchSubQuestionList();
      }
    }, {
      immediate: true
    });
    const confirmSubdivideQuestion = item => {
      _elementUi.MessageBox.confirm('确认将该子问题拆分出来吗？', '拆分问题', {
        confirmButtonText: '确认拆分',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.subdivideQuestion)({
          workspace_id: workspaceId.value,
          q_c_id: item.id
        });
        if (res.code === 20000) {
          _elementUi.Message.success('拆分成功');
          fetchSubQuestionList();
          emit('refresh');
        }
      }).catch(() => {});
    };
    const subQuestionDetailVisible = (0, _vue.ref)(false);
    const subQuestionItem = (0, _vue.ref)({});
    const showDetail = item => {
      subQuestionItem.value = item;
      subQuestionDetailVisible.value = true;
    };
    return {
      __sfc: true,
      store,
      workspaceId,
      props,
      emit,
      subQuestionsList,
      isNewSplitQuestion,
      loading,
      fetchSubQuestionList,
      confirmSubdivideQuestion,
      subQuestionDetailVisible,
      subQuestionItem,
      showDetail,
      SubQuestionDetailDrawer: _SubQuestionDetailDrawer.default,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;