"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _xlsx = _interopRequireDefault(require("xlsx"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj'])
  },
  methods: {
    // 一或多sheet表格处理
    handleFileToBuf(data, isMultiple) {
      // 创建空book
      const workbook = _xlsx.default.utils.book_new();
      // 将sheet加入book
      // 将非数组类型的data转化为数组统一处理多sheet类型
      let sheetQueue;
      if (isMultiple) {
        sheetQueue = data.map(_ref => {
          let {
            sheetName,
            data
          } = _ref;
          return {
            sheetName,
            sheetData: _xlsx.default.utils.aoa_to_sheet(data)
          };
        });
      } else {
        sheetQueue = [{
          sheetData: _xlsx.default.utils.aoa_to_sheet(data)
        }];
      }
      sheetQueue.forEach(_ref2 => {
        let {
          sheetName,
          sheetData
        } = _ref2;
        _xlsx.default.utils.book_append_sheet(workbook, sheetData, sheetName);
      });
      const wpts = {
        bookType: 'xlsx',
        // csv格式无法支持多sheet
        bookSST: true,
        type: 'binary'
      };
      const workbookOut = _xlsx.default.write(workbook, wpts);
      return new Blob([s2ab(workbookOut)], {
        type: 'application/octet-stream'
      });
      function s2ab(s) {
        let sLength = s.length;
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        while (sLength--) {
          view[sLength] = s.charCodeAt(sLength) & 0xff;
        }
        return buf;
      }
    },
    getBiFilter() {
      var _this$$route;
      const biFiltersObj = {};
      const biFilters = JSON.parse(sessionStorage.getItem('biFilters'));
      biFiltersObj.activeTabName = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : _this$$route.meta.subtitle;
      biFiltersObj.departmentName = sessionStorage.getItem('tree_id_label');
      biFiltersObj.startDate = this.$moment(biFilters.date[0]).format('YYYY-MM-DD');
      biFiltersObj.endDate = this.$moment(biFilters.date[1]).format('YYYY-MM-DD');
      biFiltersObj.day = biFilters.day;
      biFiltersObj.conversationFrom = this.biFiltersObj.type === 'all' ? '所有会话' : '商机的会话';
      biFiltersObj.conversationType = this.biFiltersObj.entity_type === 'all' ? '会话' : '交易';
      return biFiltersObj;
    }
  }
};
exports.default = _default;