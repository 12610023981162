"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _collapseTransition = _interopRequireDefault(require("element-ui/src/transitions/collapse-transition"));
var _checkbox = _interopRequireDefault(require("element-ui/packages/checkbox"));
var _emitter = _interopRequireDefault(require("element-ui/src/mixins/emitter"));
var _util = require("./model/util");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ElTreeNode',
  componentName: 'ElTreeNode',
  components: {
    ElCollapseTransition: _collapseTransition.default,
    ElCheckbox: _checkbox.default,
    NodeContent: {
      props: {
        node: {
          required: true
        }
      },
      render(h) {
        const parent = this.$parent;
        const tree = parent.tree;
        const node = this.node;
        const {
          data,
          store
        } = node;
        return parent.renderContent ? parent.renderContent.call(parent._renderProxy, h, {
          _self: tree.$vnode.context,
          node,
          data,
          store
        }) : tree.$scopedSlots.default ? tree.$scopedSlots.default({
          node,
          data
        }) : h("span", {
          "class": 'el-tree-node__label'
        }, [node.label]);
      }
    }
  },
  mixins: [_emitter.default],
  props: {
    node: {
      default() {
        return {};
      }
    },
    data: {
      type: Array
    },
    props: {},
    renderContent: Function,
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    fatherDisable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tree: null,
      expanded: false,
      childNodeRendered: false,
      oldChecked: null,
      oldIndeterminate: null
    };
  },
  watch: {
    'node.indeterminate'(val) {
      this.handleSelectChange(this.node.checked, val);
    },
    'node.checked'(val) {
      this.handleSelectChange(val, this.node.indeterminate);
    },
    'node.expanded'(val) {
      this.$nextTick(() => this.expanded = val);
      if (val) {
        this.childNodeRendered = true;
      }
    }
  },
  created() {
    const parent = this.$parent;
    if (parent.isTree) {
      this.tree = parent;
    } else {
      this.tree = parent.tree;
    }
    const tree = this.tree;
    if (!tree) {
      console.warn("Can not find node's tree.");
    }
    const props = tree.props || {};
    const childrenKey = props['children'] || 'children';
    this.$watch(`node.data.${childrenKey}`, () => {
      this.node.updateChildren();
    });
    if (this.node.expanded) {
      this.expanded = true;
      this.childNodeRendered = true;
    }
    if (this.tree.accordion) {
      this.$on('tree-node-expand', node => {
        if (this.node !== node) {
          this.node.collapse();
        }
      });
    }
  },
  methods: {
    getNodeKey(node) {
      return (0, _util.getNodeKey)(this.tree.nodeKey, node.data);
    },
    handleSelectChange(checked, indeterminate) {
      if (this.oldChecked !== checked && this.oldIndeterminate !== indeterminate) {
        this.tree.$emit('check-change', this.node.data, checked, indeterminate);
      }
      this.oldChecked = checked;
      this.indeterminate = indeterminate;
    },
    handleClick() {
      if (this.fatherDisable) {
        if (this.node.isLeaf && this.node.data.node_type !== 'department') {
          const store = this.tree.store;
          store.setCurrentNode(this.node);
          this.tree.$emit('current-change', store.currentNode ? store.currentNode.data : null, store.currentNode);
          this.tree.currentNode = this;
          if (this.tree.expandOnClickNode) {
            this.handleExpandIconClick();
          }
          if (this.tree.checkOnClickNode && !this.node.disabled) {
            this.handleCheckChange(null, {
              target: {
                checked: !this.node.checked
              }
            });
          }
          this.tree.$emit('node-click', this.node.data, this.node, this);
        } else {
          return;
        }
      } else {
        const store = this.tree.store;
        store.setCurrentNode(this.node);
        this.tree.$emit('current-change', store.currentNode ? store.currentNode.data : null, store.currentNode);
        this.tree.currentNode = this;
        if (this.tree.expandOnClickNode) {
          this.handleExpandIconClick();
        }
        if (this.tree.checkOnClickNode && !this.node.disabled) {
          this.handleCheckChange(null, {
            target: {
              checked: !this.node.checked
            }
          });
        }
        this.tree.$emit('node-click', this.node.data, this.node, this);
      }
    },
    handleContextMenu(event) {
      if (this.tree._events['node-contextmenu'] && this.tree._events['node-contextmenu'].length > 0) {
        event.stopPropagation();
        event.preventDefault();
      }
      this.tree.$emit('node-contextmenu', event, this.node.data, this.node, this);
    },
    handleExpandIconClick() {
      if (this.node.isLeaf) return;
      if (this.expanded) {
        this.tree.$emit('node-collapse', this.node.data, this.node, this);
        this.node.collapse();
      } else {
        this.node.expand();
        this.$emit('node-expand', this.node.data, this.node, this);
      }
    },
    handleCheckChange(value, ev) {
      this.node.setChecked(ev.target.checked, !this.tree.checkStrictly);
      this.$nextTick(() => {
        const store = this.tree.store;
        this.tree.$emit('check', this.node.data, {
          checkedNodes: store.getCheckedNodes(),
          checkedKeys: store.getCheckedKeys(),
          halfCheckedNodes: store.getHalfCheckedNodes(),
          halfCheckedKeys: store.getHalfCheckedKeys()
        });
      });
    },
    handleChildNodeExpand(nodeData, node, instance) {
      this.broadcast('ElTreeNode', 'tree-node-expand', node);
      this.tree.$emit('node-expand', nodeData, node, instance);
    },
    handleDragStart(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-start', event, this);
    },
    handleDragOver(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-over', event, this);
      event.preventDefault();
    },
    handleDrop(event) {
      event.preventDefault();
    },
    handleDragEnd(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-end', event, this);
    }
  }
};
exports.default = _default;