"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _CollapseAvatar = _interopRequireDefault(require("@/components/CollapseAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DataSetTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: Number,
      default: document.body.clientHeight - 156
    }
  },
  emits: ['importData', 'copyDataSet', 'deleteDataSet', 'editDataSet', 'sortChange', 'trainingModel', 'testModel', 'refreshCurrentPage', 'clickLabel'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const tableHeader = [{
      prop: 'name',
      label: '数据集名称/ID',
      fixed: 'left',
      width: 200
    }, {
      prop: 'dataset_folder_name',
      label: '文件夹'
    }, {
      prop: 'description',
      label: '描述'
    }, {
      prop: 'import_status',
      label: '导入状态',
      width: 200
    },
    // { prop: 'annotation_type', label: '标注类型' },
    {
      prop: 'annotation_users',
      label: '标注人'
    }, {
      prop: 'annotation_status',
      label: '标注进度',
      width: 200
    }, {
      prop: 'labels',
      label: '标注结果',
      width: 270
    }, {
      prop: 'creator',
      label: '创建人'
    }, {
      prop: 'create_at',
      label: '创建时间',
      sortable: 'custom'
    }, {
      prop: 'update_at',
      label: '更新时间',
      sortable: 'custom'
    }];
    const operateList = row => {
      return [{
        label: '标注',
        command: 'annotate',
        isShow: row.import_status !== 1 && row.total_data_count
      }, {
        label: '导入',
        command: 'import_data',
        isShow: true
      }, {
        label: '编辑',
        command: 'edit',
        icon: 'icon-edit',
        isShow: true
      }, {
        label: '复制',
        command: 'copy',
        icon: 'icon-copy-document',
        isShow: true
      },
      // {
      //   label: '训练',
      //   command: 'train',
      //   icon: 'icon-model-training',
      //   'data-v-name': 'train-model-btn',
      //   isShow: row.annotation_status !== 1
      // },
      // {
      //   label: '测试',
      //   command: 'test',
      //   'data-v-name': 'test-model-btn',
      //   icon: 'icon-conversation-scoring-2',
      //   isShow: row.annotation_status !== 1
      // },

      {
        label: '删除',
        command: 'delete',
        icon: 'icon-delete',
        class: 'delete',
        isShow: true
      }];
    };
    const importStatusArr = ['未导入', '导入中', '已完成', '导入失败'];
    const annotationStatusArr = ['未标注', '标注中', '已完成'];
    const refreshCurrentPage = () => {
      emit('refreshCurrentPage');
    };
    const handleSortChange = _ref2 => {
      let {
        prop,
        order
      } = _ref2;
      console.log(prop, 'prop');
      emit('sortChange', prop, order);
    };
    const onClickLabels = (row, label) => {
      emit('clickLabel', row, label);
    };
    const handleCommand = (val, row) => {
      switch (val) {
        case 'import_data':
          emit('importData', row);
          break;
        case 'copy':
          emit('copyDataSet', row);
          break;
        case 'edit':
          emit('editDataSet', row);
          break;
        case 'delete':
          emit('deleteDataSet', row);
          break;
        case 'annotate':
          emit('goAnnotate', {
            id: row.id
          });
          break;
        case 'train':
          emit('trainingModel', row);
          break;
        case 'test':
          emit('testModel', row);
          break;
        default:
          break;
      }
    };
    return {
      __sfc: true,
      props,
      tableHeader,
      operateList,
      importStatusArr,
      annotationStatusArr,
      emit,
      refreshCurrentPage,
      handleSortChange,
      onClickLabels,
      handleCommand,
      MgOperateColumn: _MgOperateColumn.default,
      CollapseAvatar: _CollapseAvatar.default
    };
  }
};
exports.default = _default;