// 保存原始的 window.open 方法
const originalWindowOpen = window.open;

// 重写 window.open 方法
window.open = function (url, target, features, replace) {
  window.log('是不是钉钉浏览器', navigator.userAgent.indexOf('DingTalk') !== -1, navigator.userAgent, url);
  // 检查是否为钉钉浏览器
  const isDingTalk = navigator.userAgent.indexOf('DingTalk') !== -1;

  // 如果是钉钉浏览器，则在原始的 url 上添加参数
  if (isDingTalk) {
    // 如果url只是路径，需要拼接成完整路径
    if (!url.includes('http')) {
      const baseUrl = window.location.origin;
      url = `${baseUrl}${url}`;
    }
    const urlObj = new URL(url);
    urlObj.searchParams.append('ddtab', 'true');
    url = urlObj.href;
  }
  // 调用原始的 window.open 方法
  originalWindowOpen.call(this, url, target, features, replace);
};