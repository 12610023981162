"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueVirtualScrollList = _interopRequireDefault(require("vue-virtual-scroll-list"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    exposedAvatarSize: {
      type: Number,
      default: 20
    },
    virtualListItemAvatarSize: {
      type: Number,
      default: 24
    },
    totalCount: {
      type: Number,
      default: 0
    },
    exposedCount: {
      type: Number,
      default: 3
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    virtualListDataKey: {
      type: String,
      default: 'id'
    }
  },
  emits: ['request-more'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const collapasCount = (0, _vue.computed)(() => {
      return (props.totalCount || props.list.length) - props.exposedCount;
    });
    const loading = (0, _vue.ref)(false);
    const showMoreUserList = () => {
      if (props.totalCount && props.totalCount > props.list.length) {
        emit('request-more', () => {
          loading.value = true;
        }, () => {
          loading.value = false;
        });
      }
    };
    return {
      __sfc: true,
      props,
      collapasCount,
      emit,
      loading,
      showMoreUserList,
      VirtualList: _vueVirtualScrollList.default
    };
  }
};
exports.default = _default;