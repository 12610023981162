"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _CustomEventInput = _interopRequireDefault(require("./CustomEventInput.vue"));
var _keyEventManagement = require("@/api/keyEventManagement");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventTree',
  components: {
    CustomEventInput: _CustomEventInput.default
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    currentEvent: {
      type: Object,
      default: () => ({})
    },
    isSilence: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showInput: false,
      folderName: '',
      currNode: {},
      renameDialogVisible: false,
      inputOriginName: '',
      defaultExpandedKeys: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'workspaceInfo'])
  },
  watch: {
    currentEvent: {
      handler: function (val) {
        if (!val.id) {
          this.cancelSelect();
        } else {
          if (val.id === this.currNode.id) return;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(val.id);
            setTimeout(() => {
              this.handleNodeExpand(val.id);
              this.scrollToCurrNode();
            }, 500);
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    scrollToCurrNode() {
      this.$nextTick(() => {
        var _this$currentEvent;
        if (!this.currentEvent.id) return;
        const node = document.getElementById((_this$currentEvent = this.currentEvent) === null || _this$currentEvent === void 0 ? void 0 : _this$currentEvent.id);
        const {
          top
        } = (node === null || node === void 0 ? void 0 : node.getBoundingClientRect()) || {
          top: 0
        };
        if (top && top < 0) {
          node === null || node === void 0 ? void 0 : node.scrollIntoView({
            block: 'center'
          });
        }
      });
    },
    updateExpandedNodeKey(node, isExpanded) {
      if (node) {
        node.expanded = isExpanded;
      }
      console.log(node, isExpanded);
      this.defaultExpandedKeys = [];
      Object.values(this.$refs['tree'].store.nodesMap).forEach(item => {
        if (item.expanded) {
          this.defaultExpandedKeys.push(item.data.id);
        }
      });
    },
    handleNodeExpand(id) {
      console.log(id);
      this.openAllParent(id);
      this.updateExpandedNodeKey();
    },
    openAllParent(id) {
      const node = this.$refs.tree.getNode(id);
      console.log(this.$refs.tree, 'this.$refs.tree');
      console.log(node, 'node');
      recursionNode(node);
      function recursionNode(node) {
        if (node) {
          node.expanded = true;
          if (node.parent) {
            node.parent.expanded = true;
            recursionNode(node.parent);
          }
        }
      }
    },
    handleNodeClick(data, node) {
      this.$emit('update:currentEvent', data);
      this.$emit('nodeClick', data, node);
      this.currNode = node;
      if (node) {
        node.expanded = true;
      }
    },
    createFolder() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.nameInput && this.$refs.nameInput.focus();
      });
    },
    async submitCreate() {
      if (!this.folderName.trim()) {
        this.hideInput();
        return;
      }
      const res = await (0, _keyEventManagement.createKeyEventFolder)({
        organization_id: this.orgId,
        workspace_id: this.workspaceInfo.id,
        name: this.$refs.nameInput.getName()
      });
      if (res.code === 20000) {
        this.$message.success('创建文件夹成功');
        this.hideInput();
        this.$emit('createSuccess');
      }
    },
    hideInput() {
      this.showInput = false;
      this.folderName = '';
    },
    cancelSelect() {
      if (this.currNode) {
        this.currNode.isCurrent = false;
      }
    },
    renameFolder(data) {
      this.inputOriginName = data.name;
      this.$set(data, 'showInput', true);
      this.$nextTick(() => {
        this.$refs[`rename${data.folder_id}`] && this.$refs[`rename${data.folder_id}`].focus();
      });
    },
    async deleteFolder(data) {
      const res = await (0, _keyEventManagement.deleteEventFolder)({
        workspace_id: this.workspaceInfo.id,
        folder_id: data.id
      });
      if (res.code === 20000) {
        this.$message.success('删除成功');
        this.$emit('deleteSuccess');
      }
    },
    handleCommand(command, data, node) {
      switch (command) {
        case 'rename':
          this.renameFolder(data);
          break;
        case 'delete':
          this.deleteFolder(data);
          break;
        case 'createFolder':
          this.$emit('createFolder', data.id);
          break;
        case 'createKeyEvent':
          this.$emit('createKeyEvent', data);
          break;
      }
    },
    async handleRenameInputBlur(data, arg) {
      this.$set(data, 'showInput', false);
      if (!data.name || data.name === this.inputOriginName) {
        data.name = this.inputOriginName;
        return;
      }
      data.name = arg[1];
      const res = await (0, _keyEventManagement.renameEventFolder)({
        workspace_id: this.workspaceInfo.id,
        folder_id: data.id,
        name: arg[1]
      });
      if (res.code === 20000) {
        this.$message.success('重命名成功');
      } else {
        data.name = this.inputOriginName;
      }
    },
    setCurrentKey(id) {
      this.$refs.tree.setCurrentKey(id);
    }
  }
};
exports.default = _default;