"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const useSelectBottomAction = (visible, _ref2) => {
  let {
    ref,
    click,
    label = '',
    icon = '',
    arrow = false
  } = _ref2;
  if (visible) {
    const _ref = ref;
    let popper = _ref.value.$refs.popper;
    if (popper.$el) popper = popper.$el;
    if (!Array.from(popper.children).some(v => v.className === 'select-bottom-action')) {
      const el = document.createElement('ul');
      el.className = 'select-bottom-action';
      el.style = 'border-top: solid 1px #E4E7ED; padding: 12px;';
      el.innerHTML = `<li>
                    ${icon ? `<i class="${icon}"></i>` : ''}
                    <span class='label-content'>${label}</span>
                    ${arrow ? '<i class="el-icon-arrow-right"/>' : ''}
                    </li>`;
      popper.appendChild(el);
      el.onclick = () => {
        click && click();
        console.log(_ref.value.toggleDropDownVisible, 'toggleDropDownVisible');
        if (_ref.value.toggleDropDownVisible) {
          _ref.value.toggleDropDownVisible(false);
        } else {
          _ref.value.visible = false;
        }
      };
    }
  }
};
var _default = useSelectBottomAction;
exports.default = _default;