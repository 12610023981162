"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("figure", {
    staticClass: "ranking-snippet-list-card",
    on: {
      click: _vm.goSnippetDetail
    }
  }, [_c("div", {
    staticClass: "img-container"
  }, [_vm.snippet.type === "video" ? _c("el-image", {
    attrs: {
      src: _vm.snippet.thumbnail,
      fit: "fill"
    }
  }) : _vm._e(), _vm.snippet.type === "audio" ? _c("div", {
    staticClass: "audio-img"
  }, [_c("i", {
    staticClass: "iconfont icon-phone-outline"
  })]) : _vm._e(), _vm.snippet.type === "doc" ? _c("div", {
    staticClass: "audio-img"
  }, [_c("i", {
    staticClass: "iconfont icon-im"
  })]) : _vm._e(), _vm.snippet.type !== "doc" ? _c("div", {
    staticClass: "absolute-time",
    domProps: {
      innerHTML: _vm._s(_vm.getDuration(_vm.snippet.duration))
    }
  }) : _vm._e()], 1), _c("figcaption", [_c("h1", [_vm._v(_vm._s(_vm.snippet.name))]), _c("div", {
    staticClass: "row"
  }, [_c("p", {
    staticClass: "create-info"
  }, [_vm.convOrigin ? _c("span", [_vm._v("会话来源 " + _vm._s(_vm.convOrigin))]) : _vm._e(), _c("span", [_vm._v("创建者 " + _vm._s(_vm.snippet.create_user.name))])])]), _c("hot-data", {
    attrs: {
      info: _vm.snippet,
      type: "snippet"
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;