"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "client-tabs"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h1", [_vm._v("客户")]), !_vm.isEdit && _vm.isCallUpShow && _vm.formData.has_edit ? _c("div", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/account/account/edit"],
      expression: "['/api/account/account/edit']"
    }],
    staticClass: "edit-client",
    on: {
      click: function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "edit-outline"
    }
  }), _c("span", [_vm._v("编辑")])], 1) : _vm._e(), _vm.isEdit ? _c("div", {
    staticClass: "client-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.handleCanelClick
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: _vm.handleUpdataClientDetail
    }
  }, [_vm._v("保存")])], 1) : _vm._e()]), _c("dynamic-form", {
    ref: "form",
    attrs: {
      "is-edit": _vm.isEdit,
      desc: _vm.formDesc,
      "label-position": "left"
    },
    model: {
      value: _vm.localFormData,
      callback: function ($$v) {
        _vm.localFormData = $$v;
      },
      expression: "localFormData"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;