"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "compliance-workspace"
  }, [_c("ComplianceWorkSpaceHeader", {
    ref: "convDetailHeader",
    attrs: {
      "origin-type": _vm.originType,
      workspace: _vm.workspace,
      "is-snippet-status": _vm.isSnippetStatus,
      "is-video-error": _vm.isVideoError,
      "conv-id": _vm.convId,
      "media-type": _vm.mediaType,
      "detail-result": _vm.overviewInfo,
      "comments-count": _vm.commentsCount,
      "has-evaluation": _vm.hasEvaluation,
      "right-bar-active": _vm.rightBarActive
    },
    on: {
      openRightDrawer: _vm.selectActiveBar,
      addVideoToSnippet: _vm.addVideoToSnippet,
      onCancelSnippet: _vm.onCancelSnippet
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.hasDetailInfo,
      expression: "!hasDetailInfo"
    }],
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("ConversationDetailLeftContent", {
    attrs: {
      "conv-id": _vm.convId,
      "media-type": _vm.mediaType,
      "participants-info": _vm.partiInfo,
      "tag-sequence": ["complianceScore", "keyEvent", "accountTag"],
      "external-active-tab": "complianceScore",
      "api-process": _vm.apiProcess,
      "exclude-menu": _vm.excludeMenu,
      "right-bar-active": _vm.rightBarActive,
      "can-edit-key-event": _vm.canEditKeyEvent
    },
    on: {
      triggerSearch: _vm.goTransText
    }
  })], 1), _c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "main-top-content"
  }, [_c("conv-play-content", {
    ref: "mainContent",
    staticClass: "conv-play-content",
    attrs: {
      "conversation-detail-info": _vm.overviewInfo,
      "video-url": _vm.videoUrl,
      "is-snippet-status": _vm.isSnippetStatus,
      "tab-active-name": _vm.tabActiveName,
      "search-words": _vm.searchWords,
      "result-list": _vm.resultList,
      origin: _vm.origin,
      "conv-id": _vm.convId,
      "upload-type": _vm.uploadType,
      "has-role": _vm.hasRole,
      "media-type": _vm.mediaType,
      "parti-info": _vm.partiInfo,
      "can-edit-key-event": _vm.canEditKeyEvent,
      "conv-in-work-space-id": _vm.workspace.id
    },
    on: {
      switchTab: _vm.switchTab,
      videoError: function ($event) {
        _vm.isVideoError = $event;
      },
      changeSliderTime: _vm.changeSliderTime,
      goKeyDetail: _vm.goKeyDetail,
      goTransText: _vm.goTransText,
      openRightDrawer: _vm.selectActiveBar,
      correctTextSuccess: _vm.correctTextSuccess,
      handleComment: _vm.handleComment
    }
  })], 1), _c("div", {
    staticClass: "main-bottom-content"
  }, [_c("conv-control-content", {
    ref: "convControlContent",
    staticClass: "video-wrapper",
    attrs: {
      url: _vm.videoUrl,
      "is-snippet-status": _vm.isSnippetStatus,
      "parti-info": _vm.partiInfo,
      "active-round-id": _vm.convId,
      "share-percent": _vm.sharePercent,
      "share-screen": _vm.shareScreen,
      "video-duration": _vm.videoDuration,
      origin: _vm.origin,
      "upload-type": _vm.uploadType,
      "media-type": _vm.mediaType,
      "has-role": _vm.hasRole,
      "local-slider-time": _vm.localSliderTime,
      "conv-detail-info": _vm.overviewInfo
    }
  })], 1)]), _c("div", {
    ref: "rightContent",
    class: ["right-content", _vm.isRightContentExpand ? "expand" : "close"]
  }, [_c("comment-drawer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightBarActive,
      expression: "rightBarActive"
    }],
    ref: "rightDrawer",
    staticClass: "comment-drawer",
    attrs: {
      "right-bar-active": _vm.rightBarActive,
      "department-manager-info": _vm.departmentManagerInfo,
      "conv-id": _vm.convId,
      "participants-info": _vm.partiInfo,
      "media-type": _vm.mediaType
    },
    on: {
      closeDrawer: _vm.closeDrawer,
      getCommentsCount: function ($event) {
        _vm.commentsCount = $event;
      },
      selectTransText: _vm.selectTransText,
      changeRightBar: _vm.changeRightBar
    }
  })], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;