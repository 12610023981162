"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "client-header-filter"
  }, [_c("div", {
    staticClass: "header-filter-content"
  }, [!(_vm.accountMultipleVal && _vm.accountMultipleVal.length) ? _c("div", {
    staticClass: "header-filter-content-top"
  }, [_c("el-select", {
    model: {
      value: _vm.departmentValue,
      callback: function ($$v) {
        _vm.departmentValue = $$v;
      },
      expression: "departmentValue"
    }
  }, [_c("svg-icon", {
    attrs: {
      slot: "prefix",
      "icon-class": "office-building"
    },
    slot: "prefix"
  }), _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.value,
        value: item.value
      }
    });
  })], 2), _c("el-select", {
    staticClass: "search-input",
    attrs: {
      multiple: "",
      clearable: "",
      filterable: "",
      "collapse-tags": "",
      remote: "",
      "reserve-keyword": "",
      placeholder: "搜索跟进人姓名或手机号",
      "remote-method": _vm.remoteUserList,
      loading: _vm.remoteLoading
    },
    model: {
      value: _vm.userId,
      callback: function ($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  }, _vm._l(_vm.userList, function (_ref) {
    let {
      id,
      name
    } = _ref;
    return _c("el-option", {
      key: id,
      attrs: {
        label: name,
        value: id
      }
    });
  }), 1), _c("el-input", {
    staticClass: "search-input",
    attrs: {
      placeholder: "请输入客户名称",
      clearable: ""
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, [_c("svg-icon", {
    attrs: {
      slot: "prefix",
      "icon-class": "search"
    },
    slot: "prefix"
  })], 1)], 1) : _c("div", {
    staticClass: "header-filter-content-button"
  }, [_c("el-button", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/account/account_pool/edit_follow_up_by"],
      expression: "['/api/account/account_pool/edit_follow_up_by']"
    }],
    attrs: {
      icon: "iconfont icon-change-follower"
    },
    on: {
      click: function ($event) {
        return _vm.handleAccountBulkAction("changeFollower");
      }
    }
  }, [_vm._v("变更跟进人")]), _c("el-button", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/account/account/move_to_pool"],
      expression: "['/api/account/account/move_to_pool']"
    }],
    attrs: {
      icon: "iconfont icon-giveup"
    },
    on: {
      click: function ($event) {
        return _vm.handleAccountBulkAction("transferToTheAccountSea");
      }
    }
  }, [_vm._v("转移至公海")])], 1)]), _c("div", {
    staticClass: "btn-container"
  }, [_c("el-button", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/batch/upload/account"],
      expression: "['/api/batch/upload/account']"
    }],
    staticClass: "import-button",
    on: {
      click: function ($event) {
        return _vm.$bus.$emit("openBatchUploadDialog", "client");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-import"
  }), _vm._v("导入客户")]), _c("el-button", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/account/account/create"],
      expression: "['/api/account/account/create']"
    }],
    attrs: {
      icon: "el-icon-plus",
      type: "primary",
      plain: ""
    },
    on: {
      click: _vm.hanleCreateClientClick
    }
  }, [_vm._v("新建客户")])], 1), _vm.createClientDialogVisible ? _c("create-clients-dialog", {
    attrs: {
      "dialog-visible": _vm.createClientDialogVisible
    },
    on: {
      fetchAccountSuccess: _vm.fetchAccountSuccess,
      closeDialog: function ($event) {
        _vm.createClientDialogVisible = false;
      },
      freshList: _vm.freshList
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;