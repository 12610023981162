"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _dialogMixins = _interopRequireDefault(require("./dialogMixins.js"));
var _ChooseFolderSelect = _interopRequireDefault(require("./ChooseFolderSelect"));
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ChooseFolderSelect: _ChooseFolderSelect.default
  },
  mixins: [_dialogMixins.default],
  data() {
    return {
      form: {
        targetFolderId: ''
      },
      rules: {
        targetFolderId: [{
          required: true,
          message: '请选择文件夹',
          trigger: 'change'
        }]
      },
      targetFolder: {},
      moveFolderToFolder: _library.moveFolderToFolder,
      moveAllSnippetsToFolder: _library.moveAllSnippetsToFolder,
      moveSnippetToFolder: _library.moveSnippetToFolder
    };
  },
  computed: {
    title() {
      const map = {
        moveFolderToFolder: '移动文件夹到文件夹',
        moveAllSnippetsToFolder: '移动全部内容到文件夹',
        moveSnippetToFolder: '移动到文件夹'
      };
      return map[this.type] || '';
    },
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  methods: {
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = {
            folder_id: this.currentNode.id,
            move_to_folder_id: this.form.targetFolderId,
            space_id: this.workspaceInfo.id
          };
          if (this.isSnippet && this.currentSnippet) {
            params.snippet_id = this.currentSnippet.id;
          }
          // 根据所传type确定要调用的接口
          const fn = this[this.type];
          const res = await fn(params);
          if (res.code === 20000) {
            var _this$currentSnippet;
            this.$message.success(this.$t('coaching.successfullyMoved'));
            this.closeDialog();
            if ((_this$currentSnippet = this.currentSnippet) !== null && _this$currentSnippet !== void 0 && _this$currentSnippet.isMedia) {
              this.$emit('mediaSubmitSucceed', this.currentSnippet.id);
            } else {
              this.$emit('submitSucceed', this.isSnippet, 'move', res.results);
            }
          }
        }
      });
    },
    handleSelect(val) {
      this.targetFolder = val;
      this.form.targetFolderId = val.id;
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.targetFolder = {};
      this.$refs.form.resetFields();
    }
  }
};
exports.default = _default;