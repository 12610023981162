"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formConfig = require("./form-config");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EvaluateFormGenerateAside',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledTip: {
      type: String,
      default: ''
    }
  },
  emits: ['item-click'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const INPUT = [1, 2, 5];
    const SELECT = [3, 4, 6, 7, 8];
    const fieldTypes = () => {
      const arr = [{
        text: '输入类型',
        ids: INPUT
      }, {
        text: '选择类型',
        ids: SELECT
      }];
      return arr.map(item => {
        return {
          ...item,
          list: _formConfig.fieldTypeMap.filter(field => item.ids.includes(field.id))
        };
      });
    };
    const onItemClick = id => {
      if (props.disabled) return;
      emit('item-click', id);
    };
    const handleMoveEnd = evt => {
      console.log('end', evt);
    };
    const handleMoveStart = _ref2 => {
      let {
        oldIndex
      } = _ref2;
      console.log('start', oldIndex);
    };
    const handleMove = () => {
      return true;
    };
    return {
      __sfc: true,
      props,
      INPUT,
      SELECT,
      fieldTypes,
      emit,
      onItemClick,
      handleMoveEnd,
      handleMoveStart,
      handleMove,
      draggable: _vuedraggable.default
    };
  }
};
exports.default = _default;