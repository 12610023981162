"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _WorkspaceSelector = _interopRequireDefault(require("@/components/Filters/WorkspaceSelector/WorkspaceSelector"));
var _modelManagement = require("@/api/modelManagement");
var _elementUi = require("element-ui");
var _RuleEventFormItem = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/RuleEventFormItem.vue"));
var _KeyEventCascader = _interopRequireDefault(require("./KeyEventCascader.vue"));
var _ModelVersionSelect = _interopRequireDefault(require("./ModelVersionSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import KeyeventSelect from '@/views/businessIntelligence/components/ExperimentCreateSteps/KeyeventSelect.vue';
var _default = {
  __name: 'PublishModelContent',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: 0
    },
    version: {
      type: [Number, String],
      default: ''
    },
    isAiModel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'publishSuccess'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const CUSTOM_MARK = '✏️ ';
    const updateVisible = () => emit('update:visible', false);
    const onBeforeClose = done => {
      clearFormModel();
      done();
    };
    const INIT_FORM = {
      version: '',
      create_new_event: true,
      event_name: '',
      event_id: '',
      workspace_id: '',
      event_description: ''
    };
    const formModel = (0, _vue.ref)(INIT_FORM);
    function clearFormModel() {
      console.log('befirecode');
      formModel.value = INIT_FORM;
      formRef.value.clearValidate();
    }
    const isCreateNewEvent = (0, _vue.computed)(() => formModel.value.create_new_event);
    const rules = (0, _vue.computed)(() => {
      return {
        version: [{
          required: true,
          message: '请选择要发布的版本',
          trigger: 'change'
        }],
        workspace_id: [{
          required: true,
          message: '请选择所属空间',
          trigger: 'change'
        }],
        event_name: [{
          required: isCreateNewEvent.value,
          message: '请输入关键事件名称',
          trigger: 'blur'
        }],
        event_id: [{
          required: !isCreateNewEvent.value,
          message: '请选择关键事件',
          trigger: 'change'
        }]
      };
    });
    const onKeyeventChange = val => {
      // console.log(val, 'keyevent');
      // formModel.value.event_id = val.join('');
      // console.log(val);
    };
    const formRef = (0, _vue.ref)();
    const publishLoading = (0, _vue.ref)(false);
    const publishModelAsync = function () {
      let isEnable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      formRef.value.validate(async valid => {
        if (!valid) return;
        publishLoading.value = true;
        const params = {
          ...formModel.value
        };
        params.event_name = CUSTOM_MARK + params.event_name;
        const res = await (0, _modelManagement.publishModel)({
          id: props.id,
          is_enable: isEnable,
          ...params
        }).finally(() => {
          publishLoading.value = false;
        });
        if (res.code === 20000) {
          updateVisible();
          _elementUi.Message.success('发布成功');
          emit('publishSuccess', props.id);
        }
      });
    };
    function validateForm() {
      return new Promise((resolve, reject) => {
        formRef.value.validate(valid => {
          if (!valid) {
            reject(false);
          } else {
            resolve(true);
          }
        });
      });
    }
    function getForm() {
      const params = {
        ...formModel.value
      };
      params.event_name = CUSTOM_MARK + params.event_name;
      return params;
    }
    expose({
      validateForm,
      getForm
    });
    return {
      __sfc: true,
      CUSTOM_MARK,
      props,
      emit,
      updateVisible,
      onBeforeClose,
      INIT_FORM,
      formModel,
      clearFormModel,
      isCreateNewEvent,
      rules,
      onKeyeventChange,
      formRef,
      publishLoading,
      publishModelAsync,
      validateForm,
      getForm,
      WorkspaceSelector: _WorkspaceSelector.default,
      RuleEventFormItem: _RuleEventFormItem.default,
      KeyEventCascader: _KeyEventCascader.default,
      ModelVersionSelect: _ModelVersionSelect.default
    };
  }
};
exports.default = _default;