"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WorkspaceListBox = _interopRequireDefault(require("@/components/WorkspaceListBox"));
var _workspace = require("@/api/workspace");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SpaceButton',
  components: {
    WorkspaceListBox: _WorkspaceListBox.default
  },
  data() {
    return {
      workspaceList: [],
      workspacePopoverVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'isWorkspaceButtonDisabled'])
  },
  mounted() {
    const clickHandler = e => {
      if (!e.target.closest('.workspace-list-popover') && !e.target.closest('#workspace-icon-button')) {
        this.workspacePopoverVisible = false;
      }
    };
    document.addEventListener('mousedown', clickHandler);
    // 卸载全局click事件
    this.$once('hook:beforeDestroy', () => document.removeEventListener('mousedown', clickHandler, true));
  },
  methods: {
    closePop() {
      this.workspacePopoverVisible = false;
    },
    openWorkspacePopover() {
      this.workspacePopoverVisible = !this.workspacePopoverVisible;
      if (this.workspacePopoverVisible) {
        this.$refs.workspaceListBox.startLoading();
        (0, _workspace.getSwitchWorkspaceList)().then(res => {
          if (res.code === 20000) {
            this.workspaceList = res.results.workspaces;
            const newWorkspaceInfo = this.workspaceList.find(i => i.id === this.workspaceInfo.id);
            this.$store.dispatch('workspace/changeWorkspace', newWorkspaceInfo);
            sessionStorage.setItem('workspaceInfo', JSON.stringify(newWorkspaceInfo));
          }
        }).finally(() => {
          this.$refs.workspaceListBox.endLoading();
        });
      }
    }
  }
};
exports.default = _default;