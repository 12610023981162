"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDateRangePastToNow = getDateRangePastToNow;
exports.getLastMonth = getLastMonth;
exports.getLastWeek = getLastWeek;
exports.getThisWeek = getThisWeek;
exports.getTimeRange = getTimeRange;
exports.getToday = getToday;
exports.getYesterday = getYesterday;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 会话筛选获取时间
// 获取今天
function getToday() {
  const start = (0, _moment.default)().startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const end = (0, _moment.default)().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  return [start, end];
}

// 获取昨天
function getYesterday() {
  const start = (0, _moment.default)().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const end = (0, _moment.default)().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
  return [start, end];
}

// 获取这周
function getThisWeek() {
  const start = (0, _moment.default)().week((0, _moment.default)().week()).startOf('week').format('YYYY-MM-DD HH:mm:ss');
  const end = (0, _moment.default)().week((0, _moment.default)().week()).endOf('week').format('YYYY-MM-DD HH:mm:ss');
  return [start, end];
}

// 获取上周
function getLastWeek() {
  const start = (0, _moment.default)().week((0, _moment.default)().week() - 1).startOf('week').format('YYYY-MM-DD HH:mm:ss');
  const end = (0, _moment.default)().week((0, _moment.default)().week() - 1).endOf('week').format('YYYY-MM-DD HH:mm:ss');
  return [start, end];
}

// 获取上个月
function getLastMonth() {
  const start = (0, _moment.default)().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const end = (0, _moment.default)().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
  return [start, end];
}

/*
 * @param unit {String} 可选:years, y, quarters, Q, months, M, weeks, w, days, d, hours, h, minutes, m, seconds, s, milliseconds,	ms
 * @param offset {number} 与当前时间的偏移量，比如 unit = quarters, offset = -1，代表上个季度
 * 理论上这个函数足够了 :)
 */
function getTimeRange(_ref) {
  let {
    unit,
    offset = 0,
    format = 'YYYY-MM-DD HH:mm:ss'
  } = _ref;
  return [(0, _moment.default)().add(offset, unit).startOf(unit).format(format), (0, _moment.default)().add(offset, unit).endOf(unit).format(format)];
}
function getDateRangePastToNow() {
  let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let unit = arguments.length > 1 ? arguments[1] : undefined;
  let format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'YYYY-MM-DD HH:mm:ss';
  return [(0, _moment.default)().subtract(count - 1, unit).startOf(unit).format(format), (0, _moment.default)().endOf(unit).format(format)];
}