"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MgPagination',
  props: {
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40]
    },
    hideLastPage: {
      type: Boolean,
      default: false
    },
    hideCount: {
      type: Number,
      default: 10000
    }
  },
  data() {
    return {};
  },
  methods: {
    handleSizeChange(pageSize) {
      const globalPageSize = localStorage.getItem('globalPageSize');
      if (globalPageSize) {
        const currentPageSize = {
          ...JSON.parse(globalPageSize),
          [this.$route.name]: pageSize
        };
        localStorage.setItem('globalPageSize', JSON.stringify(currentPageSize));
      } else {
        localStorage.setItem('globalPageSize', JSON.stringify({
          [this.$route.name]: pageSize
        }));
      }
      this.$emit('sizeChange', pageSize);
    },
    handleCurrentChange(currentPage) {
      let page;
      if (this.hideLastPage && this.pageSize * currentPage > 10000) {
        page = 10000 / this.pageSize;
      } else {
        page = currentPage;
      }
      this.$emit('currentChange', page);
    }
  }
};
exports.default = _default;