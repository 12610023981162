"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "container"
  }, [_c("el-collapse", {
    staticClass: "scene-collapse-container",
    model: {
      value: _setup.activeSceneName,
      callback: function ($$v) {
        _setup.activeSceneName = $$v;
      },
      expression: "activeSceneName"
    }
  }, _vm._l(_vm.sceneList, function (itemScene) {
    return _c("el-collapse-item", {
      key: itemScene.id,
      attrs: {
        name: itemScene.id
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("i", {
      staticClass: "el-icon-caret-right"
    }), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        content: itemScene.name,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "question"
    }, [_c("span", {
      staticClass: "bold"
    }, [_vm._v(_vm._s(itemScene.name))]), itemScene.conv_comment ? _c("span", {
      staticClass: "scene-score"
    }, [_vm._v("（" + _vm._s(_setup.t("coaching.sceneAverageScore", [itemScene.score])) + "）")]) : _vm._e()])])], 1), itemScene.conv_comment ? _c("el-collapse", {
      model: {
        value: _setup.activeKnowledgeName,
        callback: function ($$v) {
          _setup.activeKnowledgeName = $$v;
        },
        expression: "activeKnowledgeName"
      }
    }, _vm._l(itemScene.conv_comment, function (item, index) {
      return _c("el-collapse-item", {
        key: index,
        staticClass: "knowledge-collapse-container",
        attrs: {
          name: `${itemScene.id}-${index}`
        }
      }, [_c("template", {
        slot: "title"
      }, [_c("i", {
        staticClass: "el-icon-caret-right"
      }), item.not_triggered ? _c("span", {
        staticClass: "not-triggered"
      }, [_vm._v(_vm._s(_setup.t("coaching.notTriggered")))]) : _vm._e(), _c("el-tooltip", {
        directives: [{
          name: "mg-tooltip-auto-show",
          rawName: "v-mg-tooltip-auto-show"
        }],
        attrs: {
          content: item.customer.reply,
          placement: "top"
        }
      }, [_c("div", {
        staticClass: "question bold"
      }, [_vm._v(_vm._s(item.customer.reply))])]), _c("div", {
        class: ["score", item.sales.score < 60 ? "red" : "green"]
      }, [_c("span", [_vm._v(" " + _vm._s(item.sales.score) + "分"), _c("i", {
        staticClass: "iconfont icon-ai-assistant-fill"
      })])])], 1), _c("div", {
        staticClass: "content"
      }, [_c("p", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_setup.t("coaching.standardScript")) + "：")]), _c("p", [_vm._v(" " + _vm._s(item.sales.content) + " ")]), item.sales.reply ? [_c("p", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_setup.t("coaching.salesResponse")) + "：")]), _c("p", [_vm._v(" " + _vm._s(item.sales.reply) + " ")])] : _vm._e(), item.sales.comment ? [_c("p", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_setup.t("coaching.intelligentReview")) + ":")]), _c("p", {
        domProps: {
          innerHTML: _vm._s(item.sales.comment)
        }
      })] : _vm._e()], 2)], 2);
    }), 1) : _c("div", {
      staticClass: "generating"
    }, [_vm._v(" " + _vm._s(_setup.t("coaching.scenarioPhrasesBeingGenerated")) + " "), _c("span", {
      staticClass: "ellipsis"
    }, [_vm._v("…")])])], 2);
  }), 1), _c(_setup.NoData, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.sceneList.length,
      expression: "!sceneList.length"
    }],
    attrs: {
      icon: "icon-a-Property1no-data",
      size: "120",
      tips: _setup.t("coaching.noKeyPhrasesAvailable")
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;