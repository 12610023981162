"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ManageViewListFilter = _interopRequireDefault(require("./ManageViewListFilter"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _TaskCard = _interopRequireDefault(require("./TaskCard"));
var _taskCenter = require("@/api/taskCenter.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ManageViewList',
  components: {
    ManageViewListFilter: _ManageViewListFilter.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    TaskCard: _TaskCard.default
  },
  inject: ['getActiveManageViewTab'],
  data() {
    return {
      taskList: [],
      taskListLoading: false,
      noMoreTask: false,
      filterObj: {
        task_status: 0,
        deadline_ats: [],
        executors: []
      },
      baseParams: {
        page: 1,
        size: 10
      }
    };
  },
  computed: {
    noMore() {
      return this.taskList.length && this.noMoreTask;
    },
    disabled() {
      return this.taskListLoading || this.noMore;
    },
    taskTypeValue() {
      var _this$getActiveManage;
      return (_this$getActiveManage = this.getActiveManageViewTab()) === null || _this$getActiveManage === void 0 ? void 0 : _this$getActiveManage.value;
    },
    taskFilterParams() {
      const filterParams = {
        task_type: this.taskTypeValue,
        ...this.filterObj
      };
      return filterParams;
    }
  },
  watch: {
    taskTypeValue() {
      this.initListData();
    },
    filterObj: {
      handler: function () {
        this.getManageViewTaskList();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initListData() {
      this.taskList = [];
      this.baseParams.page = 1;
    },
    getManageViewTaskList() {
      const params = {
        ...this.baseParams,
        ...this.taskFilterParams
      };
      this.taskListLoading = true;
      (0, _taskCenter.getManageViewTaskList)(params).then(res => {
        if (res.code === 20000) {
          var _res$results;
          const list = ((_res$results = res.results) === null || _res$results === void 0 ? void 0 : _res$results.data_list) || [];
          this.noMoreTask = list.length < 10;
          if (!this.noMoreTask) {
            this.baseParams.page++;
          }
          this.taskList.push(...list);
        }
      }).finally(() => {
        this.taskListLoading = false;
      });
    },
    goTaskDetail(data) {
      this.$emit('goTaskDetail', data.task_info.task_id);
    },
    handleOperateTask(item, id) {
      this.$emit('operateTask', item.tag, id);
    },
    filterChange(val) {
      this.filterObj = val;
      this.baseParams.page = 1;
      this.taskList = [];
    }
  }
};
exports.default = _default;