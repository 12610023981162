"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "seat-set-form"
  }, [_c("el-form", {
    ref: "seatForm",
    staticClass: "seat-form",
    attrs: {
      model: _vm.seatForm,
      rules: _vm.rules,
      "label-width": "80px",
      "hide-required-asterisk": ""
    }
  }, [_vm.type !== "logout" ? _c("el-form-item", {
    attrs: {
      label: "座席号：",
      prop: "seatNumber"
    }
  }, [_c("el-select", {
    attrs: {
      "default-first-option": "",
      placeholder: "请选择坐席"
    },
    on: {
      change: _vm.seatNumberChange
    },
    model: {
      value: _vm.seatForm.seatNumber,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "seatNumber", $$v);
      },
      expression: "seatForm.seatNumber"
    }
  }, _vm._l(_vm.seatsOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.type !== "logout" ? _c("el-form-item", {
    attrs: {
      label: "在线状态：",
      prop: "onlineStatus"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.seatForm.onlineStatus,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "onlineStatus", $$v);
      },
      expression: "seatForm.onlineStatus"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "free"
    }
  }, [_vm._v("空闲")])], 1)], 1) : _vm._e(), _vm.type !== "logout" ? _c("el-form-item", {
    attrs: {
      label: "接听方式：",
      prop: "answerType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.seatForm.answerType,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "answerType", $$v);
      },
      expression: "seatForm.answerType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "sip"
    }
  }, [_vm._v("在线接听")]), _c("el-radio", {
    attrs: {
      label: "Local"
    }
  }, [_vm._v("手机接听")])], 1)], 1) : _vm._e(), _vm.type !== "logout" && _vm.seatForm.answerType === "Local" ? _c("el-form-item", {
    staticClass: "on-bindtel",
    attrs: {
      label: "绑定手机：",
      prop: "bindTel"
    }
  }, [_c("el-autocomplete", {
    staticClass: "inline-input",
    attrs: {
      "fetch-suggestions": _vm.telsSearch,
      placeholder: "请输入手机号",
      oninput: "value=value.replace(/[^\\d]/g,''); if(value.length>11)value=value.slice(0,11)"
    },
    on: {
      select: _vm.handleBindTelSelect
    },
    model: {
      value: _vm.seatForm.bindTel,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "bindTel", $$v);
      },
      expression: "seatForm.bindTel"
    }
  })], 1) : _vm._e(), _vm.type === "logout" ? _c("el-form-item", {
    staticClass: "check-off-answer"
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.seatForm.offlinePhoneAnswer,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "offlinePhoneAnswer", $$v);
      },
      expression: "seatForm.offlinePhoneAnswer"
    }
  }, [_vm._v("退出后仍然使用手机接听来电")])], 1) : _vm._e(), _vm.type === "logout" && _vm.seatForm.offlinePhoneAnswer ? _c("el-form-item", {
    staticClass: "off-bindtel",
    attrs: {
      label: "绑定手机：",
      prop: "offlineBindTel"
    }
  }, [_c("el-autocomplete", {
    staticClass: "inline-input",
    attrs: {
      "fetch-suggestions": _vm.telsSearch,
      placeholder: "请输入手机号",
      oninput: "value=value.replace(/[^\\d]/g,''); if(value.length>11)value=value.slice(0,11)"
    },
    on: {
      select: _vm.handleOfflineBindTelSelect
    },
    model: {
      value: _vm.seatForm.offlineBindTel,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "offlineBindTel", $$v);
      },
      expression: "seatForm.offlineBindTel"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "form-footer"
  }, [_c("div", {
    staticClass: "footer-left",
    style: {
      visibility: _vm.type === "login" ? "visible" : "hidden"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.seatForm.askNoMore,
      callback: function ($$v) {
        _vm.$set(_vm.seatForm, "askNoMore", $$v);
      },
      expression: "seatForm.askNoMore"
    }
  }, [_vm._v("不再询问")])], 1), _c("div", {
    staticClass: "footer-right"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(_vm._s(_vm.sureText))])], 1)])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;