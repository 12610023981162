import { render, staticRenderFns } from "./GroupSaveButton.vue?vue&type=template&id=be9358b2&scoped=true&"
import script from "./GroupSaveButton.vue?vue&type=script&setup=true&lang=js&"
export * from "./GroupSaveButton.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./GroupSaveButton.vue?vue&type=style&index=0&id=be9358b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be9358b2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be9358b2')) {
      api.createRecord('be9358b2', component.options)
    } else {
      api.reload('be9358b2', component.options)
    }
    module.hot.accept("./GroupSaveButton.vue?vue&type=template&id=be9358b2&scoped=true&", function () {
      api.rerender('be9358b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coach/course/components/GroupSaveButton.vue"
export default component.exports