"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-member-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: "添加成员",
      visible: _vm.visible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("el-form", {
    ref: "batchAddForm",
    staticClass: "modify-form",
    attrs: {
      model: _vm.batchAddForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ids"
    }
  }, [_vm.visible ? _c("members-finder", {
    ref: "memberFinder",
    attrs: {
      placeholder: "请选择成员",
      "has-self": false,
      "tree-config": _vm.treeConfig
    },
    on: {
      change: _vm.handleMemberChange
    }
  }) : _vm._e()], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;