"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HeaderFilters = _interopRequireDefault(require("@/views/clients/HeaderFilters"));
var _AccountTable = _interopRequireDefault(require("./AccountTable"));
var _client = require("@/api/client");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChooseAccountDialog',
  components: {
    AccountHeaderFilters: _HeaderFilters.default,
    AccountTable: _AccountTable.default
  },
  props: {
    chooseAccountDialogVisible: {
      type: Boolean,
      default: false
    },
    dealForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableLoading: false,
      accountList: null,
      accountListParams: {
        page_at: 1,
        page_size: 10,
        search_val: '',
        only_my_accounts: 0,
        in_deal_list: 1
      },
      total: 0,
      accountTableSelect: null,
      initAccountTableSelect: null
    };
  },
  watch: {
    chooseAccountDialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs['accountHeaderFilter']) {
            this.$refs['accountHeaderFilter'].departmentValue = '全部客户';
            this.$refs['accountHeaderFilter'].searchValue = '';
          }
        });
        this.initAccountTableSelect = this.dealForm.id;
        !this.dealForm.id ? this.accountTableSelect = JSON.parse(JSON.stringify(this.dealForm)) : '';
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions'])
  },
  mounted() {
    if (this.api_permissions.includes('/api/account/account/deal_create_deal')) {
      this.getClientList(this.accountListParams);
    }
  },
  methods: {
    fetchAccountSuccess(val) {
      this.$emit('fetchAccountSuccess', val);
      // 新建客户成功之后重新请求客户列表页
      this.accountListParams.page_at = 1;
      this.getClientList(this.accountListParams);
    },
    accountTableChange(val) {
      this.accountTableSelect = val;
    },
    handleEnterClick() {
      this.$emit('chooseAccountSuccess', this.accountTableSelect);
      this.closeDialog();
    },
    // 获取客户列表
    async getClientList(data) {
      this.tableLoading = true;
      const res = await (0, _client.getClientList)(data);
      if (res.code === 200) {
        this.accountList = res.results.items;
        this.total = res.results.items_total;
      }
      this.tableLoading = false;
    },
    // 客户分页数量变化
    sizeChange(val) {
      this.accountListParams.page_size = val;
      this.getClientList(this.accountListParams);
    },
    // 客户当前页数发生变化
    currentChange(val) {
      const maxPage = 10000 / this.accountListParams.page_size;
      this.accountListParams.page_at = this.accountListParams.page_size * val > 10000 ? maxPage : val;
      this.getClientList(this.accountListParams);
    },
    // 客户的筛选项发生变化
    headerFiltersChange(params) {
      this.accountListParams.search_val = params.search_val;
      this.accountListParams.only_my_accounts = params.only_my_accounts;
      this.accountListParams.page_at = 1;
      this.getClientList(this.accountListParams);
    },
    closeDialog() {
      // eslint-disable-next-line vue/no-mutating-props
      this.dealForm.id = this.initAccountTableSelect;
      this.accountTableSelect = null;
      this.$emit('closeChooseAccountDialog');
    }
  }
};
exports.default = _default;