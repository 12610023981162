"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HeaderFilter = _interopRequireDefault(require("./components/HeaderFilter"));
var _ExperimentTable = _interopRequireDefault(require("./components/ExperimentTable"));
var _ExperimentDrawer = _interopRequireDefault(require("./components/ExperimentDrawer"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ControlExperiment',
  components: {
    HeaderFilter: _HeaderFilter.default,
    ExperimentTable: _ExperimentTable.default,
    ExperimentDrawer: _ExperimentDrawer.default,
    shareConfig: _shareConfig.default
  },
  data() {
    return {
      listParams: {
        page: 1,
        size: 10,
        source: [],
        status: []
      },
      total: 0,
      tableData: [],
      tableLoading: false,
      experimentDrawerVisible: false,
      isEdit: false,
      isCopy: false,
      editInfo: {},
      currentExperiment: {},
      shareConfigVisible: false,
      currentExperimentId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.tableData = [];
        this.total = 0;
        this.listParams.page = 1;
        this.getExperimentList();
      },
      immediate: true
    }
  },
  methods: {
    shareExperiment: _businessIntelligence.shareExperiment,
    cancelShareExperiment: _businessIntelligence.cancelShareExperiment,
    getShareExperimentList: _businessIntelligence.getShareExperimentList,
    handleShareExperiment(data) {
      this.currentExperimentId = data.id;
      this.shareConfigVisible = true;
    },
    goExperimentDetail(row) {
      this.$router.push({
        path: _routeMap.ROUTES.biExperimentDetail,
        query: {
          id: row.id
        }
      });
    },
    handleCopyExperiment(row) {
      this.editInfo = row;
      this.isEdit = true;
      this.isCopy = true;
      this.experimentDrawerVisible = true;
    },
    handleEditExperiment(row) {
      this.editInfo = row;
      this.isEdit = true;
      this.isCopy = false;
      this.experimentDrawerVisible = true;
    },
    // 创建成功
    refresh() {
      this.listParams.page = 1;
      this.getExperimentList();
    },
    creatExperiment() {
      this.isEdit = false;
      this.experimentDrawerVisible = true;
    },
    handleFilterChange(source, status, userIds) {
      this.listParams.source = source;
      this.listParams.status = status;
      this.listParams.user_ids = userIds;
      this.listParams.page = 1;
      this.getExperimentList();
    },
    async getExperimentList() {
      if (!this.workspaceInfo.id) return;
      this.listParams.workspace_id = this.workspaceInfo.id;
      this.tableLoading = true;
      const res = await (0, _businessIntelligence.getExperimentList)(this.listParams).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        this.tableData = res.results.data;
        this.total = res.results.count;
      }
    },
    handleSizeChange(val) {
      this.listParams.size = val;
      this.listParams.page = 1;
      this.getExperimentList();
    },
    handleCurrentChange(val) {
      this.listParams.page = val;
      this.getExperimentList();
    }
  }
};
exports.default = _default;