"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _convItem = _interopRequireDefault(require("@/views/dashBoard/components/convItem"));
var _conversationList = require("@/api/conversationList");
var _routeMap = require("@/router/routeMap");
var _NoDataHolder = _interopRequireDefault(require("@/components/NoDataHolder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationList',
  components: {
    ConvItem: _convItem.default,
    NoDataHolder: _NoDataHolder.default
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    userId: Number,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      convList: []
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getConversationList();
        } else {
          this.resetData();
        }
      },
      immediate: true
    }
  },
  methods: {
    async getConversationList() {
      const params = {
        current_page: this.page++,
        size: 10,
        host_ids: [this.userId]
      };
      try {
        const res = await (0, _conversationList.getConversationList)(params);
        this.$emit('requestSucceed');
        if (res.code === 200) {
          this.convList = res.results.items.map(item => {
            item.type = item.conversation_type;
            return item;
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    goDetail(id) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&operate=advise`);
    },
    resetData() {
      this.page = 1;
      this.convList = [];
    }
  }
};
exports.default = _default;