"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.source.visible,
      expression: "source.visible"
    }],
    ref: "node",
    staticClass: "el-tree-node",
    class: {
      "is-expanded": _vm.expanded,
      "is-current": _vm.source.isCurrent,
      "is-hidden": !_vm.source.visible,
      "is-focusable": !_vm.source.disabled,
      "is-checked": !_vm.source.disabled && _vm.source.checked
    },
    attrs: {
      role: "treeitem",
      tabindex: "-1",
      "aria-expanded": _vm.expanded,
      "aria-disabled": _vm.source.disabled,
      "aria-checked": _vm.source.checked,
      draggable: _vm.tree.draggable
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      },
      contextmenu: $event => _vm.handleContextMenu($event),
      dragstart: function ($event) {
        $event.stopPropagation();
        return _vm.handleDragStart.apply(null, arguments);
      },
      dragover: function ($event) {
        $event.stopPropagation();
        return _vm.handleDragOver.apply(null, arguments);
      },
      dragend: function ($event) {
        $event.stopPropagation();
        return _vm.handleDragEnd.apply(null, arguments);
      },
      drop: function ($event) {
        $event.stopPropagation();
        return _vm.handleDrop.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "el-tree-node__content"
  }, [_c("span", {
    style: {
      "min-width": (_vm.source.level - 1) * _vm.tree.indent + "px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }), _c("span", {
    class: [{
      "is-leaf": _vm.source.isLeaf,
      expanded: !_vm.source.isLeaf && _vm.expanded
    }, "el-tree-node__expand-icon", _vm.tree.iconClass ? _vm.tree.iconClass : "el-icon-caret-right"],
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleExpandIconClick.apply(null, arguments);
      }
    }
  }), _vm.showCheckbox ? _c("el-checkbox", {
    attrs: {
      indeterminate: _vm.source.indeterminate,
      disabled: !!_vm.source.disabled
    },
    on: {
      change: _vm.handleCheckChange
    },
    nativeOn: {
      click: function ($event) {
        $event.stopPropagation();
      }
    },
    model: {
      value: _vm.source.checked,
      callback: function ($$v) {
        _vm.$set(_vm.source, "checked", $$v);
      },
      expression: "source.checked"
    }
  }) : _vm._e(), _vm.source.loading ? _c("span", {
    staticClass: "el-tree-node__loading-icon el-icon-loading"
  }) : _vm._e(), _c("node-content", {
    attrs: {
      node: _vm.source
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;