"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-table-filter"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("coaching.searchByName"),
      clearable: ""
    },
    model: {
      value: _vm.filters.name,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "name", $$v);
      },
      expression: "filters.name"
    }
  }), ["teamView", "leader"].includes(_vm.type) ? _c("mgv-member-multi-select", {
    staticClass: "members-tree-select",
    attrs: {
      placeholder: _vm.$t("coaching.executor"),
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-params": {
        type: JSON.stringify(["training_read"])
      },
      "backfill-list": _vm.memberBackfillList,
      "is-prevent-not-selected": false
    },
    on: {
      change: _vm.selectMember
    }
  }) : _vm._e(), ["teamView", "sales"].includes(_vm.type) ? _c("el-select", {
    attrs: {
      placeholder: _vm.$t("coaching.searchByAssignee"),
      clearable: "",
      filterable: "",
      remote: "",
      "remote-method": _vm.getMember,
      loading: _vm.searchLoading
    },
    on: {
      change: _vm.distributorChange
    },
    model: {
      value: _vm.filters.creator_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "creator_id", $$v);
      },
      expression: "filters.creator_id"
    }
  }, _vm._l(_vm.memberOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1) : _vm._e(), _c("el-select", {
    attrs: {
      placeholder: _vm.$t("coaching.status"),
      clearable: ""
    },
    model: {
      value: _vm.filters.status,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status", $$v);
      },
      expression: "filters.status"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _c("el-select", {
    attrs: {
      placeholder: _vm.$t("coaching.courseType"),
      clearable: ""
    },
    model: {
      value: _vm.filters.course_type,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "course_type", $$v);
      },
      expression: "filters.course_type"
    }
  }, _vm._l(_vm.courseTypeOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), ["teamView", "leader"].includes(_vm.type) ? _c("el-select", {
    attrs: {
      placeholder: _vm.$t("coaching.任务分类"),
      clearable: ""
    },
    model: {
      value: _vm.filters.category_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "category_id", $$v);
      },
      expression: "filters.category_id"
    }
  }, _vm._l(_vm.taskCategoryOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;