"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      width: "1000px",
      "custom-class": "coaching-conversation-dialog",
      "before-close": _vm.handleClose
    }
  }, [_c("header", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticClass: "header-top"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("添加会话")]), _c("space-button")], 1), _c("el-input", {
    attrs: {
      placeholder: "搜索会话",
      clearable: ""
    },
    on: {
      change: _vm.initGetData
    },
    model: {
      value: _vm.searchVal,
      callback: function ($$v) {
        _vm.searchVal = $$v;
      },
      expression: "searchVal"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])], 1), _c("article", [_c("ConversationFilter", {
    ref: "conversation-filter",
    on: {
      filterChange: _vm.filterChange
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conversation-container"
  }, [_c("div", {
    staticClass: "list-container"
  }, [_vm.searchVal.length && !_vm.loading && !_vm.conversationList.length ? _c("NoData", {
    attrs: {
      icon: "icon-a-Property1not-found"
    }
  }, [_c("span", {
    staticClass: "tips",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_vm._v("未搜索到“"), _c("span", [_vm._v(_vm._s(_vm.searchVal))]), _vm._v("”相关结果")])]) : [_c("mgv-infinite-scroll", {
    attrs: {
      loading: _vm.loading,
      "has-more": _vm.hasMore
    },
    on: {
      loadMore: _vm.getConversationList
    }
  }, _vm._l(_vm.conversationList, function (item) {
    return _c("ConversationItem", {
      key: item.id,
      attrs: {
        item: item,
        "is-show-delete-button": false
      },
      nativeOn: {
        click: function ($event) {
          return _vm.checkedConversation(item);
        }
      }
    });
  }), 1)]], 2)])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;