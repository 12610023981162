"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "multi-select-wrapper"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      "visible-arrow": false,
      disabled: _vm.tooltipIsDisabled(_vm.localValue),
      content: _vm.tipContent,
      "popper-class": "multi-select-wrapper-tooltip"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: `请选择${_vm.item.name}`,
      multiple: "",
      "collapse-tags": ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, _vm._l(_vm.item.options, function (option, index) {
    return _c("el-option", {
      key: option + index,
      attrs: {
        label: option.label || option,
        value: option.value || option
      }
    });
  }), 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;