"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _recorderCore = _interopRequireDefault(require("recorder-core"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _coachingCenter = require("@/api/coachingCenter");
var _HeaderTitle = _interopRequireDefault(require("./components/HeaderTitle"));
var _KnowledgeAside = _interopRequireDefault(require("./components/KnowledgeAside.vue"));
var _KnowledgeContent = _interopRequireDefault(require("./components/KnowledgeContent"));
var _AnswerSheet = _interopRequireDefault(require("./components/AnswerSheet"));
var _PracticeFinish = _interopRequireDefault(require("./components/PracticeFinish"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeKnowledge',
  components: {
    HeaderTitle: _HeaderTitle.default,
    KnowledgeAside: _KnowledgeAside.default,
    KnowledgeContent: _KnowledgeContent.default,
    AnswerSheet: _AnswerSheet.default,
    PracticeFinish: _PracticeFinish.default
  },
  beforeRouteEnter(to, from, next) {
    if (!sessionStorage.getItem('task_practice_query')) {
      next({
        path: _routeMap.ROUTES.coachingCenterSales
      });
      return;
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (_recorderCore.default.IsOpen()) {
      this.$confirm('正在离开本页面，本页面内未完成的录音将不会保存', this.$t('coaching.prompt'), {
        confirmButtonText: '确认',
        cancelButtonText: this.$t('coaching.cancel'),
        type: 'warning'
      }).then(() => {
        _recorderCore.default.Destroy();
        next();
      });
    } else if (!this.knowledge.status && !this.isFinished) {
      // 进行中的任务退出拦截 status :0
      this.$confirm('还有未完成的练习，是否离开培训？', this.$t('coaching.prompt'), {
        confirmButtonText: '离开',
        cancelButtonText: '继续练习'
      }).then(() => {
        next();
      });
    } else {
      next();
    }
  },
  data() {
    return {
      taskConfig: {},
      knowledge: {},
      loading: false,
      isFinished: false,
      averageScore: '',
      answerSheetVisible: false // 答题卡
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'knowledgeDeleted'])
  },
  watch: {
    knowledgeDeleted(val) {
      if (val) {
        this.handleKnowledgeDeleted();
      }
    }
  },
  mounted() {
    window.onbeforeunload = this.beforeunloadHandler;
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  },
  created() {
    this.taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query'));
    this.taskConfig.taskId = Number(this.taskConfig.taskId);
    this.taskConfig.type = Number(this.taskConfig.type);
    this.taskConfig.taskStatus = Number(this.taskConfig.taskStatus);
    this.taskConfig.userInfo = this.taskConfig.userInfo ? JSON.parse(this.taskConfig.userInfo) : {
      id: this.userId
    };
    this.getPracticeDetail(this.taskConfig.knowledgeId);
    this.handleDocumentTitle();
  },
  methods: {
    // 答完当前题后需要重新计算答题卡的平均分
    scoreFinished() {
      this.$refs.answerSheet.getSalesKnowledgeReplyDetail();
    },
    // 展示答题卡
    showAnswerSheet() {
      this.answerSheetVisible = true;
    },
    // 返回我的任务
    goMyTask() {
      this.$router.push({
        path: _routeMap.ROUTES.coachingCenterSales
      });
    },
    // 查看练习详情
    goCoachDetail() {
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        taskId: this.taskConfig.taskId,
        type: this.taskConfig.type,
        score: this.taskConfig.score,
        userInfo: JSON.stringify(this.taskConfig.userInfo)
      }));
      this.$router.replace({
        path: _routeMap.ROUTES.coachingCenterSalesPlan,
        query: {
          taskId: this.taskConfig.taskId
        }
      });
    },
    async handleFinish() {
      const res = await (0, _coachingCenter.getTaskScore)({
        id: this.taskConfig.taskId,
        user_id: Number(this.userId)
      });
      if (res.code === 20000) {
        this.isFinished = true;
        this.averageScore = res.results.data.average_score;
      }
    },
    beforeunloadHandler(e) {
      if (_recorderCore.default.IsOpen()) {
        return this.$t('coaching.sureToLeave');
      }
    },
    // 点击上一题下一题
    clickJump(direction) {
      var _this$knowledge;
      this.getKnowledge((_this$knowledge = this.knowledge) === null || _this$knowledge === void 0 ? void 0 : _this$knowledge[direction + '_id']);
    },
    getKnowledge(id) {
      if (_recorderCore.default.IsOpen()) {
        this.$message.warning('当前正在录音，请关闭录音后再尝试');
      } else {
        // 清空问题列表，解决进度条渲染由1到0的问题
        this.knowledge.scene_reply_list = [];
        this.getPracticeDetail(id);
      }
    },
    async getPracticeDetail(id) {
      const params = {
        plan_knowledge_id: id,
        user_id: this.userId
      };
      this.loading = true;
      const res = await (0, _coachingCenter.getPracticeDetail)(params).finally(() => {
        this.loading = false;
      });
      this.knowledge = res.results;
    },
    handleDocumentTitle() {
      if (this.taskConfig.taskStatus) {
        document.title = `${this.$t('coaching.detailsOfKnowledgePointExercises')}`;
      }
    },
    handleKnowledgeDeleted() {
      this.$confirm('该任务已被删除，无法继续练习！', '删除任务', {
        confirmButtonText: '回到我的任务',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        this.$router.push({
          path: _routeMap.ROUTES.coachingCenterSales
        });
      }).catch(() => {});
    }
  }
};
exports.default = _default;