import { render, staticRenderFns } from "./ConversationActivity.vue?vue&type=template&id=63e5c190&scoped=true&"
import script from "./ConversationActivity.vue?vue&type=script&lang=js&"
export * from "./ConversationActivity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e5c190",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63e5c190')) {
      api.createRecord('63e5c190', component.options)
    } else {
      api.reload('63e5c190', component.options)
    }
    module.hot.accept("./ConversationActivity.vue?vue&type=template&id=63e5c190&scoped=true&", function () {
      api.rerender('63e5c190', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessIntelligencePersonal/ConversationActivity.vue"
export default component.exports