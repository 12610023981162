"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskCenterExportButton = _interopRequireDefault(require("@/components/TaskCenterExportButton"));
var _CreateDate = _interopRequireDefault(require("@/components/Filters/components/CreateDate"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _routeMap = require("@/router/routeMap");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FiltersGroup',
  components: {
    CreateDate: _CreateDate.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default,
    TaskCenterExportButton: _TaskCenterExportButton.default
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      filtesParams: {
        type: 'list',
        filter: '',
        tree_ids: [],
        user_ids: [],
        create_at: '',
        begin_time: '',
        custom_filters: {},
        q_type: ''
      },
      questionTypes: [{
        value: 1,
        label: '系统创建'
      }, {
        value: 2,
        label: '用户创建'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    exportParams() {
      const filter = (0, _commonFunc.deepClone)(this.filter);
      delete filter.size;
      delete filter.page;
      return filter;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        const BIFAQViewType = localStorage.getItem('BIFAQViewType');
        if (BIFAQViewType) {
          this.filtesParams.type = BIFAQViewType;
        }
        val && this.filterChange();
      },
      immediate: true
    }
  },
  methods: {
    createQuestion() {
      this.$emit('createQuestion');
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    async exportData(callback) {
      const res = await (0, _businessIntelligence.exportFAQList)(this.exportParams);
      if (res.code === 20000) {
        callback();
      }
    },
    customFilterChange(params) {
      this.filtesParams.custom_filters = params;
      this.filterChange();
    },
    toggleViewType(val) {
      localStorage.setItem('BIFAQViewType', val);
      this.filterChange({
        type: val
      });
    },
    filterChange(val) {
      this.filtesParams = {
        ...this.filtesParams,
        ...val,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      this.$emit('filterChange', this.filtesParams);
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    createTimeOptions(name, field_name) {
      return {
        field_name,
        name,
        options: [['本周', '本月', '上周', '上月']],
        type: 'date'
      };
    },
    getViewTypesConfig() {
      return [{
        type: 'list',
        tip: '列表视图',
        icon: 'icon-view-card'
      }, {
        type: 'chart',
        tip: '图表视图',
        icon: 'icon-pie-chart'
      }];
    }
  }
};
exports.default = _default;