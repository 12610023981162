"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "client-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "clientTable",
    attrs: {
      data: _vm.list,
      height: _vm.tableHeight,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "selection-change": _vm.handleSelectionChange,
      "row-click": _vm.goClientDetail,
      "sort-change": function ($event) {
        return _vm.handleSortChange($event, "client");
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "75",
      selectable: _vm.selectable
    }
  }), _vm._l(_vm.headerList, function (item, index) {
    return _c("el-table-column", {
      key: index + item.field_name,
      ref: `client-${item.field_name}`,
      refInFor: true,
      attrs: {
        label: item.name,
        prop: item.field_name,
        formatter: _vm.formatterTest,
        "show-overflow-tooltip": item.field_type !== "link",
        "min-width": "120px",
        width: _vm.setColumnWidth(item.field_name),
        sortable: _vm.customSortable(item.field_name),
        "sort-orders": ["descending", "ascending"]
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("span", {
            staticClass: "header-name"
          }, [_vm._v(_vm._s(item.name))])];
        },
        proxy: true
      }, item.field_name === "follow_up_by_name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "follow-up-by"
          }, [_c("image-avatar", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: row.follow_up_by_name,
              expression: "row.follow_up_by_name"
            }],
            attrs: {
              name: row.follow_up_by_name,
              avatar: row.follow_up_by_avatar
            }
          }), _c("span", [_vm._v(_vm._s(row.follow_up_by_name || "-"))])], 1)];
        }
      } : item.field_type === "radio" || item.field_type === "checkbox" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_vm._v(" " + _vm._s(row[item.field_name] ? row[item.field_name].toString() : "-") + " ")];
        }
      } : item.field_type === "datetime" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_vm._v(" " + _vm._s(row[item.field_name] ? _vm.$moment(row[item.field_name]).format("YYYY-MM-DD HH:mm") : "-") + " ")];
        }
      } : item.field_type === "date" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_vm._v(" " + _vm._s(row[item.field_name] ? _vm.$moment(row[item.field_name]).format("YYYY-MM-DD") : "-") + " ")];
        }
      } : item.field_type === "link" ? {
        key: "default",
        fn: function (_ref5) {
          var _row$item$field_name;
          let {
            row
          } = _ref5;
          return [(_row$item$field_name = row[item.field_name]) !== null && _row$item$field_name !== void 0 && _row$item$field_name.link ? _c("span", {
            on: {
              click: function ($event) {
                $event.stopPropagation();
              }
            }
          }, [_c("LinkText", _vm._b({}, "LinkText", row[item.field_name], false))], 1) : _c("span", [_vm._v("-")])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      prop: "operate",
      width: _vm.operateColumnWidth,
      fixed: "right",
      "class-name": "operate-column"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticClass: "operate-column-cell"
        }, [_c("client-operate-buttons", {
          attrs: {
            "operate-buttons": _vm.operateButtons,
            "row-info": scope.row,
            "active-id": _vm.activeClientId,
            "is-in-row": ""
          },
          on: {
            clickContactPersonItem: _vm.clickContactPersonItem,
            changeActiveClientId: _vm.changeActiveClientId,
            openContactPersonDialog: _vm.openContactPersonDialog
          }
        }), _c("el-tooltip", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/account/account/create_deal"],
            expression: "['/api/account/account/create_deal']"
          }],
          attrs: {
            "popper-class": "create-business-tooltip",
            content: "新建商机",
            placement: "top",
            "open-delay": 400
          }
        }, [_c("div", {
          staticClass: "btn"
        }, [_c("el-button", {
          directives: [{
            name: "preventReClick",
            rawName: "v-preventReClick"
          }],
          staticClass: "el-button-text--primary",
          attrs: {
            circle: ""
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.openCreateBusinessDialog(scope.row);
            }
          }
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "deal5"
          }
        })], 1)], 1)]), _c("el-dropdown", {
          staticClass: "btn",
          on: {
            command: function ($event) {
              return _vm.handleCommand(scope.row, $event);
            }
          }
        }, [_c("el-button", {
          staticClass: "el-button-text--primary",
          attrs: {
            circle: "",
            size: "small"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "more"
          }
        })], 1), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            command: "edit"
          }
        }, [_c("div", {
          staticClass: "drop-item"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "edit"
          }
        }), _vm._v("查看客户信息 ")], 1)]), _c("el-dropdown-item", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/account/account_pool/edit_follow_up_by"],
            expression: "[\n                  '/api/account/account_pool/edit_follow_up_by'\n                ]"
          }],
          attrs: {
            command: "changeFollowUpBy",
            disabled: !scope.row.has_edit
          }
        }, [_c("div", {
          staticClass: "drop-item"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "change-follower"
          }
        }), _vm._v("变更跟进人 ")], 1)]), _c("el-dropdown-item", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/account/account/move_to_pool"],
            expression: "['/api/account/account/move_to_pool']"
          }],
          attrs: {
            command: "moveAccountSea",
            disabled: !scope.row.has_edit
          }
        }, [_c("div", {
          staticClass: "drop-item"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "giveup"
          }
        }), _vm._v("转移至公海 ")], 1)])], 1)], 1)], 1)];
      }
    }])
  })], 2), _c("client-detail-drawer", {
    ref: "clientDetail",
    attrs: {
      visible: _vm.clientDetailDrawerVisible,
      "account-id": _vm.activeClientId,
      "is-call-up-and-create-deal-show": _vm.isCallUpAndCreateDealShow
    },
    on: {
      changeFollowUpBy: _vm.changeFollowUpBy,
      moveAccountSea: _vm.moveAccountSeaMultiple,
      createDeal: _vm.createDeal,
      freshCurrentList: _vm.freshCurrentList,
      closeDrawer: _vm.closeClientDetailDrawer
    }
  }), _c("upload-media-dialog", {
    ref: "upload-media-dialog",
    attrs: {
      "page-type": _vm.pageType,
      "upload-media-dialog-visible": _vm.uploadMediaDialogVisible,
      "media-row-info": _vm.mediaRowInfo,
      "limit-count": 5,
      multiple: ""
    },
    on: {
      closeUploadMediaDialog: _vm.closeUploadMediaDialog
    }
  }), _c("create-business-dialog", {
    ref: "createBusinessDeal",
    attrs: {
      "form-desc": _vm.formDesc,
      "business-dialog-visible": _vm.businessDialogVisible,
      "client-detail-drawer-visible": _vm.clientDetailDrawerVisible,
      "business-status-options": _vm.businessStatusOptions
    },
    on: {
      closeDialog: _vm.closeBusinessDialog,
      freshCustomerDetail: _vm.freshCustomerDetail
    }
  }), _c("move-account-sea-dialog", {
    ref: "moveAccountSeaDialog",
    attrs: {
      "move-account-sea-dialog-visible": _vm.moveAccountSeaDialogVisible
    },
    on: {
      freshClientTable: _vm.freshClientTable,
      closeDialog: _vm.closeMoveAccountSeaDialog
    }
  }), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible
    },
    on: {
      closeDialog: function ($event) {
        _vm.meetingDialogVisible = false;
      }
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e(), _vm.contactPersonDialogVisible ? _c("contact-person-dialog", {
    ref: "createContactPersonDialog",
    attrs: {
      "account-id": _vm.activeClientId,
      "contact-person-dialog-visible": _vm.contactPersonDialogVisible,
      "contact-form-desc": _vm.contactFormDesc
    },
    on: {
      closeDialog: _vm.closeContactPersonDialog
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;