"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EvaluateFormWidget = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormWidget"));
var _EvaluateFormDisplay = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormDisplay.vue"));
var _compliance = require("@/api/compliance");
var _orderBy = _interopRequireDefault(require("lodash/orderBy"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EvaluateForm',
  components: {
    EvaluateFormWidget: _EvaluateFormWidget.default,
    EvaluateFormDisplay: _EvaluateFormDisplay.default
  },
  props: {
    complianceStatus: {
      type: Number,
      default: 0
    },
    onlyEvaluation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      evaluationRecord: {},
      schema: {},
      evaluationVersion: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getEvaluationRecord();
      },
      immediate: true
    },
    complianceStatus: {
      handler: function (val) {
        if (val === 2) {
          this.getEvaluationRecord();
        }
      }
    }
  },
  methods: {
    async getEvaluationRecord() {
      const params = {
        conversation_id: Number(this.$route.query.id),
        workspace_id: this.workspaceInfo.id
      };
      if (this.$route.query.task_id) {
        params.task_id = Number(this.$route.query.task_id);
      }
      const method = this.onlyEvaluation ? _compliance.getConvEvaluationRecord : _compliance.getEvaluationRecord;
      const res = await method(params);
      if (res.code === 20000) {
        res.results.field_values = (0, _orderBy.default)(res.results.field_values, 'field_order', 'asc');
        this.evaluationRecord = res.results;
        this.evaluationVersion = res.results.template_version;
        this.$emit('getEvaluationId', this.evaluationRecord.template_id);
        this.schema = this.formatData(this.evaluationRecord);
      }
    },
    formatData(row) {
      var _row$field_values;
      const schema = {
        name: row.template_name,
        description: row.template_description,
        fields: []
      };
      row === null || row === void 0 ? void 0 : (_row$field_values = row.field_values) === null || _row$field_values === void 0 ? void 0 : _row$field_values.forEach(field => {
        const {
          field_id: id,
          field_value: value,
          field_name: name,
          field_propt: prompt,
          field_required: required,
          ...etc
        } = field;
        const item = {
          ...etc,
          field_value: value,
          [id]: value,
          id,
          name,
          prompt,
          required
        };
        schema.fields.push(item);
      });
      return schema;
    }
  }
};
exports.default = _default;