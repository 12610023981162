"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "practice-finish"
  }, [_c("div", {
    staticClass: "coach-content"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/coachFinish.png")
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.score === "",
      expression: "score === ''"
    }],
    staticClass: "score"
  }, [_c("span", {
    staticClass: "score-num"
  }, [_vm._v(_vm._s(_vm.score))]), _c("span", {
    staticClass: "points"
  }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])])]), _c("div", {
    staticClass: "finish-tip"
  }, [_vm._v(_vm._s(_vm.$t("coaching.completeCoachingTips")))]), _c("div", {
    staticClass: "operate-btns"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goMyTask
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.backToTaskList")))]), _c("el-button", {
    staticClass: "detail-btn",
    on: {
      click: _vm.goCoachDetail
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.viewPracticeDetails")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;