"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "kanban-config",
    attrs: {
      title: _vm.isEditKanban ? "编辑看板" : "添加看板",
      visible: _vm.kanbanConfigVisible,
      "close-on-click-modal": false,
      "before-close": _vm.handleClose,
      width: "400px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.kanbanConfigVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKanbanConfigLoading,
      expression: "getKanbanConfigLoading"
    }],
    staticClass: "container"
  }, [_c("el-form", {
    ref: "kanbanConfigForm",
    attrs: {
      model: _vm.kanbanConfig
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "看板名称",
      prop: "name",
      rules: {
        required: true,
        message: "请输入看板名称",
        trigger: "change"
      }
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "10",
      placeholder: "请输入看板名称"
    },
    model: {
      value: _vm.kanbanConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "name", $$v);
      },
      expression: "kanbanConfig.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "config",
      rules: _vm.validatorDataRange
    }
  }, [_c("template", {
    slot: "label"
  }, [_c("div", {
    staticClass: "slot-label"
  }, [_c("span", [_vm._v("数据范围")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "数据范围对此看板下所有卡片生效",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1)]), _vm.kanbanConfigVisible ? _c("department-and-member-select-tree", {
    ref: "department-and-member-select-tree",
    attrs: {
      "backfill-params": _vm.backfillDepartmentAndMemberSelectParams,
      "is-checked-root": !_vm.isEditKanban,
      params: {
        type: JSON.stringify(["conversation", "deal"])
      }
    },
    on: {
      change: _vm.daraRangeChange,
      clearChecked: _vm.clearDataRange
    }
  }) : _vm._e()], 2)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;