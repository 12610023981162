"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.t = exports.i18n = void 0;
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _fr = _interopRequireDefault(require("element-ui/lib/locale/lang/fr"));
var _de = _interopRequireDefault(require("element-ui/lib/locale/lang/de"));
var _es = _interopRequireDefault(require("element-ui/lib/locale/lang/es"));
var _pt = _interopRequireDefault(require("element-ui/lib/locale/lang/pt"));
var _it = _interopRequireDefault(require("element-ui/lib/locale/lang/it"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _coaching = _interopRequireDefault(require("./coaching"));
var _common = _interopRequireDefault(require("./common"));
var _management = _interopRequireDefault(require("./management"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
_vue.default.use(_vueI18n.default);
const messages = {
  zh: _zhCN.default,
  en: _en.default,
  fr: _fr.default,
  de: _de.default,
  es: _es.default,
  pt: _pt.default,
  it: _it.default
};
for (const key in messages) {
  messages[key] = {
    ...messages[key],
    ..._coaching.default[key],
    ..._management.default[key],
    ..._common.default[key]
  };
}
function getLanguage() {
  const lang = localStorage.getItem('lang');
  if (!lang) localStorage.setItem('lang', 'zh');
  return lang || 'zh';
}
const i18n = new _vueI18n.default({
  locale: getLanguage(),
  // set locale
  messages // set locale messages
});

exports.i18n = i18n;
const t = i18n.t.bind(i18n);
exports.t = t;