"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layoutMixins = _interopRequireDefault(require("./components/layoutMixins.js"));
var _biChart = _interopRequireDefault(require("@/components/BusinessIntelligence/biChart"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const {
  mapMutations
} = (0, _vuex.createNamespacedHelpers)('bi');
var _default = {
  name: 'KeyEvent',
  components: {
    biChart: _biChart.default
  },
  mixins: [_layoutMixins.default],
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('biKeyEventSelectedState');
    next();
  },
  data() {
    return {
      infoArray: [],
      averageValue: {},
      horizontalArray: [],
      convParams: {
        event_engine_filter_id: ''
      }
    };
  },
  computed: {
    sortOptions() {
      const arr = this.infoArray.map(item => {
        return {
          label: item.key,
          value: item.id
        };
      });
      return arr;
    },
    asideListType() {
      var _this$filters;
      return (_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : _this$filters.analysis_basis;
    }
  },
  methods: {
    ...mapMutations(['handleKeyeventObj']),
    sortTypeChange(type) {
      switch (type) {
        case 'ascending':
          this.infoArray.sort((a, b) => a.value - b.value);
          break;
        case 'descending':
          this.infoArray.sort((b, a) => a.value - b.value);
          break;
      }
    },
    handleResults() {
      if (!Object.prototype.hasOwnProperty.call(this.chartData, 'avg')) {
        this.infoArray = [];
        return;
      }
      this.handleKeyeventObj(this.chartData);
      const {
        table,
        avg,
        avgStr,
        xAxis
      } = this.chartData;
      this.infoArray = table;
      this.averageValue = {
        value: avg,
        label: avgStr
      };
      this.horizontalArray = xAxis;
    }
  }
};
exports.default = _default;