"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-event-mutual-exclusion"
  }, [_c(_setup.KeyEventMutualExclusionHeader, {
    on: {
      createMutualExclusionRule: function ($event) {
        return _setup.openMutualExclusionDrawer(0);
      }
    }
  }), _c("div", {
    staticClass: "key-event-mutual-exclusion-content"
  }, [_c("MgvInfoAlert", {
    attrs: {
      closable: false
    }
  }, [_vm._v("当多个语义矛盾的关键事件同时命中会话，比如客户先同意了报价，会话快结束时又对报价提出了异议，此时可以设定取最晚触发等互斥规则保留最合理的关键事件 ")]), _c(_setup.KeyEventMutualExclusionTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    ref: "mutexTableRef",
    attrs: {
      "mutual-exclusion-key-event-list": _setup.mutexRuleList
    },
    on: {
      deleteMutexRule: _setup.deleteMutexRuleAsync,
      editMutexRule: _setup.editMutexRule
    }
  })], 1), _c(_setup.CreateMutualExclusionRule, {
    ref: "mutexDrawerRef",
    attrs: {
      "used-ids": _setup.usedIds,
      "strategy-id": _setup.operateMutexRuleId,
      visible: _setup.createMutualExclusionRuleDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createMutualExclusionRuleDrawerVisible = $event;
      },
      refreshList: _setup.getMutualExclusionListApi
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;