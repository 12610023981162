"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppMain", {
  enumerable: true,
  get: function () {
    return _AppMain.default;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function () {
    return _Sidebar.default;
  }
});
var _Sidebar = _interopRequireDefault(require("./Sidebar"));
var _AppMain = _interopRequireDefault(require("./AppMain"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}