"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'EventTabTags',
  props: {
    tagsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    toggleSelectTag(isSelected, index) {
      this.$emit('toggleSelectTag', isSelected, index);
    }
  }
};
exports.default = _default;