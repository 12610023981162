"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MgInfiniteScroll',
  props: {
    disabled: {
      type: [Boolean, Number],
      default: null
    },
    distance: {
      type: Number,
      default: 100
    },
    immediate: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 400
    },
    loading: {
      type: Boolean,
      default: false
    },
    nomore: {
      type: [Boolean, Number],
      default: false
    },
    nomoreText: {
      type: String,
      default: '没有更多了'
    }
  },
  computed: {
    scrollDisabled() {
      if (typeof this.disabled !== 'boolean') {
        return this.loading || this.nomore;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    }
  }
};
exports.default = _default;