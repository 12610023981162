"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dimension-list"
  }, [_c("div", {
    staticClass: "aside-title title-row"
  }, [_vm._m(0), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "mini",
      color: "info",
      type: "text",
      square: ""
    },
    on: {
      click: _setup.addDimension
    }
  })], 1), _c("div", {
    staticClass: "title-row"
  }, [_c(_setup.MetricSelector, {
    on: {
      change: _setup.onMetricSelectorChange
    }
  })], 1), _c("ul", {
    staticClass: "dimension-list__inner"
  }, _vm._l(_setup.dimensions, function (item) {
    return _c("li", {
      key: item.id,
      staticClass: "dimension-item",
      class: {
        "is-active": item.id === _setup.activeId
      },
      on: {
        click: function ($event) {
          return _setup.nodeClick(item);
        }
      }
    }, [!item.isEdit ? [_c("div", {
      staticClass: "item-left"
    }, [_c("i", {
      staticClass: "iconfont icon-folder-fill"
    }), _c("span", {
      staticClass: "item-text"
    }, [_vm._v(_vm._s(item.name))])]), _c("div", {
      staticClass: "item-right count"
    }, [_vm._v(_vm._s(item.metrics_count))]), _c("span", {
      staticClass: "operation-button",
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c("el-dropdown", {
      attrs: {
        placement: "bottom-start",
        trigger: "click"
      },
      on: {
        command: function ($event) {
          return _setup.handleCommand($event, item);
        }
      }
    }, [_c("MgvButton", {
      attrs: {
        icon: "icon-more",
        square: "",
        size: "mini",
        type: "text"
      }
    }), _c("el-dropdown-menu", [_c("el-dropdown-item", {
      attrs: {
        icon: "iconfont icon-edit",
        command: "edit",
        disabled: item.is_system
      }
    }, [_vm._v("编辑")]), _c("el-dropdown-item", {
      staticClass: "cancel",
      attrs: {
        icon: "iconfont icon-delete",
        command: "delete",
        disabled: item.is_system
      }
    }, [_vm._v("删除")])], 1)], 1)], 1)] : [_c("el-input", {
      ref: el => {
        _setup.getInputRef(el, item);
      },
      refInFor: true,
      attrs: {
        maxlength: "10"
      },
      on: {
        blur: function ($event) {
          return _setup.onInputBlur(item);
        }
      },
      model: {
        value: _setup.nameTemp,
        callback: function ($$v) {
          _setup.nameTemp = $$v;
        },
        expression: "nameTemp"
      }
    })]], 2);
  }), 0), _c(_setup.DimensionCreateDialog, {
    attrs: {
      visible: _setup.createDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createDialogVisible = $event;
      },
      createSuccess: _setup.getScoreMetricsDimensionList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("span", [_c("i", {
    staticClass: "iconfont icon-catalogue"
  }), _vm._v(" 评分维度目录 ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;