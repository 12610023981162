"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "container"
  }, [_vm._m(0), _c("p", {
    staticClass: "description"
  }, [_vm._v("客户画像字段有助于学员提出问题时，AI客户据此回答")]), _c(_setup.FieldItems, {
    attrs: {
      "get-value-place-holder": _setup.getPlaceHolder
    },
    on: {
      fieldValueChange: _setup.triggerSavedStatus
    },
    model: {
      value: _setup.customerPersonaList,
      callback: function ($$v) {
        _setup.customerPersonaList = $$v;
      },
      expression: "customerPersonaList"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "header-container"
  }, [_c("span", {
    staticClass: "bold"
  }, [_vm._v("客户画像设置")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;