"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useVuex = require("@/hooks/use-vuex.js");
var _vue = require("vue");
var _default = {
  __name: 'RuleCardContent',
  props: {
    processType: {
      type: Number,
      default: 1
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    cardInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    // 拿到空间信息
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const validateScoreRange = (rule, value, callback) => {
      // 校验两个值均不能为空
      if (!value[0] && typeof value[0] !== 'number' || !value[1] && typeof value[1] !== 'number') {
        callback(new Error('评分范围不能为空'));
      } else if (value[0] > value[1]) {
        callback(new Error('最小值不能大于最大值'));
      } else {
        callback();
      }
    };
    const scoreRuleOptions = (0, _vue.inject)('scoreRuleOptions', (0, _vue.ref)([]));
    const selectFields = (0, _vue.inject)('selectFields', (0, _vue.ref)([]));
    const content = (0, _vue.computed)(() => {
      return props.cardInfo.content || {};
    });
    const getActionInfo = (0, _vue.computed)(() => {
      return props.cardInfo.content.action || {};
    });
    const activeFiedOption = (0, _vue.ref)([]);
    const setActiveFiedOption = () => {
      if (getActionInfo.value.content.field_id) {
        var _selectFields$value$f;
        activeFiedOption.value = ((_selectFields$value$f = selectFields.value.find(item => item.id === getActionInfo.value.content.field_id)) === null || _selectFields$value$f === void 0 ? void 0 : _selectFields$value$f.options) || [];
      }
    };

    // 遍历content.conditions中的content.score_rule_id是否存在于scoreRuleOptions中
    // 如果不存在，则设为null
    const checkScoreRuleId = () => {
      content.value.conditions.forEach(item => {
        if (item.content.score_rule_id && !scoreRuleOptions.value.find(scoreRule => scoreRule.id === item.content.score_rule_id)) {
          item.content.score_rule_id = null;
        }
      });
    };

    // 检查getActionInfo.value.content.field_id的值是否存在于selectFields.value中
    // 如果不存在，则设为null
    const checkFieldId = () => {
      if (getActionInfo.value.content.field_id && !selectFields.value.find(item => item.id === getActionInfo.value.content.field_id)) {
        getActionInfo.value.content.field_id = null;
      }
    };

    // 检查getActionInfo.value.content.option_key的值是否存在于activeFiedOption.value中
    // 如果不存在，则设为null
    const checkOptionKey = () => {
      if (getActionInfo.value.content.option_key && !activeFiedOption.value.find(item => item.key === getActionInfo.value.content.option_key)) {
        getActionInfo.value.content.option_key = null;
      }
    };

    // 监听selectFields.value变化，来设置activeFiedOption
    (0, _vue.watchEffect)(() => {
      setActiveFiedOption();
      checkScoreRuleId();
      checkFieldId();
      checkOptionKey();
    });
    const handleSelectFieldChange = () => {
      getActionInfo.value.content.option_key = null;
      setActiveFiedOption();
    };
    const contentTypeMap = {
      1: {
        conditionsType: 'conv_score',
        actionType: 'fill_select_field',
        conditionsFormRules: {
          score_rule_id: [{
            required: true,
            message: '规则不能为空',
            trigger: 'change'
          }],
          score_range: [{
            required: true,
            message: '评分范围不能为空',
            trigger: 'change'
          }, {
            validator: validateScoreRange,
            trigger: 'change'
          }]
        },
        actionFormRules: {
          field_id: [{
            required: true,
            message: '字段不能为空',
            trigger: 'change'
          }],
          option_key: [{
            required: true,
            message: '选项不能为空',
            trigger: 'change'
          }]
        }
      }
    };
    const conditionsFormRef = (0, _vue.ref)([]);
    const actionFormRef = (0, _vue.ref)(null);
    const validateForm = async () => {
      const formRefs = [...conditionsFormRef.value, actionFormRef.value];
      const res = await Promise.all(formRefs.map(item => item.validate()));
      return res.every(item => item);
    };
    expose({
      validateForm
    });
    return {
      __sfc: true,
      props,
      store,
      workspaceInfo,
      validateScoreRange,
      scoreRuleOptions,
      selectFields,
      content,
      getActionInfo,
      activeFiedOption,
      setActiveFiedOption,
      checkScoreRuleId,
      checkFieldId,
      checkOptionKey,
      handleSelectFieldChange,
      contentTypeMap,
      conditionsFormRef,
      actionFormRef,
      validateForm
    };
  }
};
exports.default = _default;