"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-link", {
    staticClass: "conversation-link",
    style: {
      width: _vm.width
    },
    attrs: {
      underline: false,
      href: _setup.hrefPath,
      target: "_blank"
    }
  }, [_c("div", {
    staticClass: "conversation-link__inner"
  }, [_c("i", {
    staticClass: "iconfont icon-phone-outline",
    class: ["iconfont", _setup.conversationIcon]
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.name))]), _c("i", {
    staticClass: "iconfont icon-top-right"
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;