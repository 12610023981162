"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.visible,
      "append-to-body": "",
      size: "600px",
      "before-close": () => _vm.$emit("update:visible", false)
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            tabindex: -1,
            placement: "top",
            effect: "dark",
            content: _vm.subQuestionItem.name
          }
        }, [_c("span", {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.subQuestionItem.name))])])];
      },
      proxy: true
    }])
  }, [!_setup.loading && !_setup.answerList.length ? _c(_setup.NoData, {
    attrs: {
      tips: "暂无数据"
    }
  }) : _c(_setup.MgInfiniteScroll, {
    attrs: {
      loading: _setup.loading,
      nomore: !_setup.hasMore,
      distance: 200,
      "nomore-text": "没有更多内容"
    },
    on: {
      loadMore: _setup.getAnswerList
    }
  }, _vm._l(_setup.answerList, function (item, index) {
    return _c(_setup.AnswerItem, {
      key: index,
      attrs: {
        "conv-item": item,
        "is-show-operate": false
      }
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;