"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExampleSentence = useExampleSentence;
exports.useIntelligentExtension = useIntelligentExtension;
exports.useModelSentenceForm = useModelSentenceForm;
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _elementUi = require("element-ui");
function useModelSentenceForm(exampleType) {
  const modelSentenceForm = (0, _vue.ref)({
    workspace_ids: [],
    sentences: [[{
      entity_type: 'host_salesman',
      content: ''
    }]]
  });
  const modelSentenceFormRef = (0, _vue.ref)(null);
  const initModelSentenceForm = () => {
    switch (exampleType.value) {
      case 1:
        modelSentenceForm.value.sentences = [[{
          entity_type: 'host_salesman',
          content: ''
        }]];
        return;
      case 2:
        modelSentenceForm.value.sentences = [[{
          entity_type: 'customer_contact',
          content: ''
        }]];
        return;
      case 3:
      case 5:
        modelSentenceForm.value.sentences = [[{
          entity_type: 'host_salesman',
          content: ''
        }, {
          entity_type: 'customer_contact',
          content: ''
        }]];
        return;
      case 4:
      case 6:
        modelSentenceForm.value.sentences = [[{
          entity_type: 'customer_contact',
          content: ''
        }, {
          entity_type: 'host_salesman',
          content: ''
        }]];
        return;
      case 7:
        modelSentenceForm.value.sentences = [[{
          entity_type: '',
          content: ''
        }]];
    }
  };
  const addModelSentenceItem = () => {
    if (modelSentenceForm.value.sentences.length >= 100) {
      _elementUi.Message.warning('例句最多为100条');
      return;
    }
    switch (exampleType.value) {
      case 1:
        modelSentenceForm.value.sentences.push([{
          entity_type: 'host_salesman',
          content: ''
        }]);
        return;
      case 2:
        modelSentenceForm.value.sentences.push([{
          entity_type: 'customer_contact',
          content: ''
        }]);
        return;
      case 3:
      case 5:
        modelSentenceForm.value.sentences.push([{
          entity_type: 'host_salesman',
          content: ''
        }, {
          entity_type: 'customer_contact',
          content: ''
        }]);
        return;
      case 4:
      case 6:
        modelSentenceForm.value.sentences.push([{
          entity_type: 'customer_contact',
          content: ''
        }, {
          entity_type: 'host_salesman',
          content: ''
        }]);
        return;
      case 7:
        modelSentenceForm.value.sentences.push([{
          entity_type: '',
          content: ''
        }]);
    }
  };
  const deleteModelSentenceItem = index => {
    modelSentenceForm.value.sentences.splice(index, 1);
  };
  return {
    modelSentenceForm,
    modelSentenceFormRef,
    initModelSentenceForm,
    addModelSentenceItem,
    deleteModelSentenceItem
  };
}
function useIntelligentExtension(exampleType) {
  const exampleSentenceList = (0, _vue.ref)([]);
  const intelligentSentenceDrawerVisible = (0, _vue.ref)(false);
  const gptLoading = (0, _vue.ref)(false);
  const intelligentSentenceRef = (0, _vue.ref)(null);
  const initIntelligentExtension = () => {
    var _intelligentSentenceR;
    exampleSentenceList.value = [];
    (_intelligentSentenceR = intelligentSentenceRef.value) === null || _intelligentSentenceR === void 0 ? void 0 : _intelligentSentenceR.setLocalIntelligentSentence('');
  };
  const exampleSentenceExpandApi = async () => {
    gptLoading.value = true;
    const prompt = intelligentSentenceRef.value.getLocalIntelligentSentence();
    const res = await (0, _rapidCreateModel.exampleSentenceExpand)({
      prompt,
      example_type: exampleType.value
    }).finally(() => gptLoading.value = false);
    if (res.code === 20000) {
      exampleSentenceList.value = res.results.sentences;
    }
  };
  const handleIntelligentSentence = () => {
    exampleSentenceExpandApi();
  };
  const deleteExampleSentence = idx => {
    exampleSentenceList.value.splice(idx, 1);
  };
  return {
    gptLoading,
    intelligentSentenceRef,
    exampleSentenceList,
    intelligentSentenceDrawerVisible,
    initIntelligentExtension,
    handleIntelligentSentence,
    deleteExampleSentence
  };
}
function useExampleSentence() {
  const exampleType = (0, _vue.ref)(0);
  return {
    exampleType
  };
}