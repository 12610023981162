"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskTitleLink = _interopRequireDefault(require("@/components/TaskCenterDrawer/components/TaskTitleLink.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskCenterCard',
  components: {
    TaskTitleLink: _TaskTitleLink.default
  },
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  computed: {
    getRenderInfoObj() {
      return this.renderInfo[0];
    }
  },
  methods: {
    plus8ToCurrentZone(date) {
      return this.$t('coaching.deadlineHasVar', [this.$plus8ToCurrentZone(date.replace(/[^\d-\s:]/g, '')).format('YYYY-MM-DD HH:mm')]);
    }
  }
};
exports.default = _default;