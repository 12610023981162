"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stopdrag = exports.preventReClick = exports.drag = exports.domResize = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _domResize = _interopRequireDefault(require("./dom-resize"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const preventReClick = _vue.default.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  }
});
exports.preventReClick = preventReClick;
const drag = _vue.default.directive('drag', {
  inserted: function (el, binding) {
    const oDiv = el; // 当前元素
    // let self = this // 上下文
    // 禁止选择当前元素的文字
    oDiv.onselectstart = function () {
      return false;
    };
    oDiv.onmousedown = function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - oDiv.offsetLeft;
      const disY = e.clientY - oDiv.offsetTop;
      // 获取当前窗口宽高
      const winWidth = document.body.clientWidth;
      const winHeight = document.body.clientHeight;
      // 获取当前元素的宽高
      const elWidth = el.offsetWidth;
      const elHeight = el.offsetHeight;
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let l = e.clientX - disX;
        let t = e.clientY - disY;
        if (l < 0) {
          l = 0;
        } else if (l > winWidth - elWidth) {
          l = winWidth - elWidth;
        }
        if (t < 0) {
          t = 0;
        } else if (t > winHeight - elHeight) {
          t = winHeight - elHeight;
        }
        // 移动当前元素
        oDiv.style.left = l + 'px';
        oDiv.style.top = t + 'px';
        el.setAttribute('data-has-user-drag', 1);
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
      return true;
    };
  }
});
exports.drag = drag;
const stopdrag = _vue.default.directive('stopdrag', {
  inserted: function (el, binding, vnode) {
    const element = el;
    element.onmousedown = function (e) {
      e.stopPropagation();
    };
  }
});
exports.stopdrag = stopdrag;
const domResize = _vue.default.directive('resize', _domResize.default);

/*
 * 解决tooltips，在弹框之后再次出现的问题，原因是由于tabindex导致
 */
exports.domResize = domResize;
_vue.default.directive('delTabIndex', {
  bind(el) {
    el.__vueSetTimeoutIndex__ = setTimeout(() => {
      el.removeAttribute('tabindex');
      // 清除当前tabIndex
      clearTimeout(el.__vueSetTimeoutIndex__);
    }, 0);
  },
  unbind(el) {
    clearTimeout(el.__vueSetTimeoutIndex__);
  }
});

/**
 * mg-tooltip-auto-show
 */
_vue.default.directive('mg-tooltip-auto-show', {
  inserted(el, binding, vnode) {
    el.addEventListener('mouseenter', function () {
      const defaultSilent = !!_vue.default.config.silent;
      _vue.default.config.silent = true;
      vnode.componentInstance && (vnode.componentInstance.disabled = true);
      // 获取元素的宽度
      const currentWidth = el.offsetWidth;
      // 将文本设置为不换行，获取文本总宽度，用于计算实际占用行数是否超过设置最大行数
      el.style.whiteSpace = 'nowrap';
      el.style.overflow = 'visible';
      const maxWidth = el.style.maxWidth;
      el.style.maxWidth = 'unset';
      const totalWidth = el.offsetWidth;
      // 最大行数
      let maxLine = window.getComputedStyle(el).webkitLineClamp;
      maxLine = maxLine === 'none' ? 1 : maxLine;
      el.style.overflow = 'hidden';
      el.style.width = 'fit-content';
      el.style.maxWidth = maxWidth;
      if (maxLine > 1) {
        el.style.whiteSpace = 'normal';
      }
      if ((totalWidth + 10 * (maxLine - 1)) / currentWidth > maxLine) {
        vnode.componentInstance && (vnode.componentInstance.disabled = false);
      }
      _vue.default.config.silent = defaultSilent;
    });
  }
});
_vue.default.directive('right-bottom-drag', {
  inserted: function (el, binding) {
    const oDiv = el;
    oDiv.onselectstart = function () {
      return false;
    };
    oDiv.onmousedown = function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - oDiv.offsetLeft;
      const disY = e.clientY - oDiv.offsetTop;
      // 获取当前窗口宽高
      const winWidth = document.body.clientWidth;
      const winHeight = document.body.clientHeight;
      // 获取当前元素的宽高
      const elWidth = el.offsetWidth;
      const elHeight = el.offsetHeight;
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let l = e.clientX - disX;
        let t = e.clientY - disY;
        if (l < 0) {
          l = 0;
        } else if (l > winWidth - elWidth) {
          l = winWidth - elWidth;
        }
        if (t < 0) {
          t = 0;
        } else if (t > winHeight - elHeight) {
          t = winHeight - elHeight;
        }
        // 移动当前元素
        oDiv.style.right = winWidth - elWidth - l + 'px';
        oDiv.style.bottom = winHeight - elHeight - t + 'px';
        el.setAttribute('data-has-user-drag', 1);
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
      return true;
    };
  }
});