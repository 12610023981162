"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-box-container"
  }, [_c("div", {
    staticClass: "app-box"
  }, [_c("figure", [_c("img", {
    attrs: {
      src: _vm.appLogo,
      alt: `${_vm.appName}`
    }
  }), _c("figcaption", [_c("h3", [_vm._v(_vm._s(_vm.convertAppName()))]), _c("h4", [_vm._v(_vm._s(_vm.appDescription))])])]), _c("div", {
    staticClass: "btn-box"
  }, [!_vm.isBind ? _c("el-button", {
    staticClass: "btn-link",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.getAuthLink
    }
  }, [_vm._v("关联账户 ")]) : [_c("el-button", {
    staticClass: "btn-linked",
    attrs: {
      plain: ""
    }
  }, [_c("i", {
    staticClass: "iconfont icon-success"
  }), _vm._v(" 已关联 ")]), _c("el-dropdown", {
    staticClass: "more",
    attrs: {
      placement: "start-bottom",
      "append-to-body": "false"
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_c("i", {
    staticClass: "el-icon-more"
  })]), _c("el-dropdown-menu", {
    staticClass: "more-item",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.appName === "wework" ? _c("el-dropdown-item", [_c("button", {
    staticClass: "btn-common",
    on: {
      click: _vm.goMergeAppDataPage
    }
  }, [_vm._v(" 合并联系人 ")])]) : _vm._e(), _c("el-dropdown-item", [_c("button", {
    staticClass: "btn-common",
    on: {
      click: _vm.removeBinding
    }
  }, [_vm._v(" 解除关联 ")])])], 1)], 1)]], 2)]), _vm.isBind ? _c("p", {
    staticClass: "bind-name"
  }, [_vm._v(" 绑定账号：" + _vm._s(_vm.appName === "wemeet" ? _vm.bindUserInfo.nickname : _vm.bindUserInfo.user_name) + " " + _vm._s(_vm.appName === "wemeet" ? _vm.bindUserInfo.corp_name : _vm.bindUserInfo.organization_name) + " ")]) : _vm._e(), _vm.appName === "wework" && _vm.isBind ? _c("p", {
    staticClass: "wework-tips"
  }, [_c("span", {
    staticClass: "tips-content"
  }, [_vm._v(" 提示： ")]), _vm._m(0)]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_c("p", {
    staticClass: "tips-content"
  }, [_vm._v("1. 当日会话数据会在第二天同步至系统中")]), _c("p", {
    staticClass: "tips-content"
  }, [_vm._v(" 2. 只能同步联系人同意授权部分的聊天记录，若联系人拒绝则不能同步。 ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;