"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.cancelAdd,
      width: "60%",
      "custom-class": "add-script-dialog"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "label"
        }, [_vm._v("添加客户关键问题")]), _c("KnowledgeBaseDropdown")];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("el-input", {
    ref: "searchInput",
    attrs: {
      placeholder: "搜索",
      clearable: ""
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })]), _c("div", {
    staticClass: "knowledge-container"
  }, [_c("KnowledgeMultipleTree", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchValue,
      expression: "!searchValue"
    }],
    on: {
      scrollToScriptEnd: _vm.scrollToScriptEnd
    }
  }, [_c("NoData", {
    attrs: {
      slot: "no-data",
      tips: "知识库为空，创建知识点或切换至其他知识库"
    },
    slot: "no-data"
  })], 1), _c("KnowledgeSearchContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    attrs: {
      "search-value": _vm.searchValue
    },
    on: {
      scrollToScriptEnd: _vm.scrollToScriptEnd
    }
  })], 1)], 1), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "right-header"
  }, [_vm._v(" 已选：" + _vm._s(_vm.checkedKnowledgeScript.length) + "个客户关键问题 ")]), _c("div", {
    ref: "script-container",
    staticClass: "script-container"
  }, [_c("LearnContent", {
    ref: "learnContent"
  })], 1)])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.cancelAdd
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("添加")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;