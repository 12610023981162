"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "data-permission-card"
  }, [_c("h4", [_vm._v(_vm._s(_vm.typeMap[_vm.profileType].title))]), _c("div", {
    staticClass: "permission-card"
  }, [_c("div", {
    staticClass: "base-rules rules"
  }, [_c("h6", [_vm._v("基础规则")]), _vm._l(_vm.localPermisson.basic_rules, function (item) {
    return _c("div", {
      key: item.operate_type,
      staticClass: "rules-item"
    }, [_c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("用户可" + _vm._s(_vm.getOperateLabel(item.operate_type)))]), _c("el-select", {
      staticClass: "scope-select",
      attrs: {
        placeholder: "请选择",
        disabled: !_vm.isEdit
      },
      model: {
        value: item.auth_scope,
        callback: function ($$v) {
          _vm.$set(item, "auth_scope", $$v);
        },
        expression: "item.auth_scope"
      }
    }, _vm._l(_vm.getScopeOptions(item.operate_type), function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value,
          disabled: item.disabled
        }
      });
    }), 1), _c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("的" + _vm._s(_vm.typeMap[_vm.profileType].label))])], 1);
  })], 2), _c("div", {
    staticClass: "add-rules rules"
  }, [_c("div", {
    staticClass: "add-rules-title"
  }, [_c("h6", [_vm._v("补充规则")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      content: "在基础规则之上，扩充特定部门、成员的数据权限",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1), _vm.localPermisson.supplement_rules && _vm.localPermisson.supplement_rules.length ? _vm._l(_vm.localPermisson.supplement_rules, function (customizeRules, index) {
    return _c("div", {
      key: customizeRules.id,
      staticClass: "main-wrapper"
    }, [_c("div", {
      staticClass: "rules-item"
    }, [_c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("补充规则" + _vm._s(index + 1) + "：")]), _c("members-finder", {
      ref: "authTargetMembersFinder",
      refInFor: true,
      staticClass: "department-member-select",
      class: _vm.isEdit ? "" : "is-disabled",
      attrs: {
        placeholder: "请选择部门、成员",
        "has-self": false,
        "tree-config": _vm.treeConfig,
        "is-repetitive": true,
        disabled: !_vm.isEdit
      },
      on: {
        change: function ($event) {
          return _vm.handleMemberChange($event, customizeRules);
        }
      }
    }), _c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("可")]), !_vm.getCanEdit ? _c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("查看")]) : _vm._e(), _vm.getCanEdit ? _c("el-select", {
      staticClass: "operate-select",
      attrs: {
        placeholder: "请选择",
        disabled: !_vm.isEdit
      },
      model: {
        value: customizeRules.operate_type,
        callback: function ($$v) {
          _vm.$set(customizeRules, "operate_type", $$v);
        },
        expression: "customizeRules.operate_type"
      }
    }, _vm._l(_vm.operateOptions, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value
        }
      });
    }), 1) : _vm._e(), _c("department-multi-select", {
      ref: "departmentMultipleSelect",
      refInFor: true,
      attrs: {
        "is-edit": _vm.isEdit
      },
      on: {
        submitManageScope: function ($event) {
          return _vm.submitManageScope($event, customizeRules);
        }
      }
    }), _c("span", {
      staticClass: "flex-fixed"
    }, [_vm._v("的" + _vm._s(_vm.typeMap[_vm.profileType].label))]), _c("el-popconfirm", {
      attrs: {
        icon: "el-icon-info",
        "confirm-button-text": "删除",
        "cancel-button-text": "取消",
        title: "确认删除吗？",
        "icon-color": "#FFA200",
        "confirm-button-type": "danger",
        "popper-class": "data-permisson-popper"
      },
      on: {
        onConfirm: function ($event) {
          return _vm.removeRule(customizeRules, index);
        }
      }
    }, [_c("el-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isEdit,
        expression: "isEdit"
      }],
      staticClass: "close-rules-button",
      attrs: {
        slot: "reference",
        icon: "el-icon-close"
      },
      slot: "reference"
    })], 1)], 1), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.getIncompleteRules(customizeRules, index) && _vm.isValidate,
        expression: "getIncompleteRules(customizeRules, index) && isValidate"
      }],
      staticClass: "error-tip"
    }, [_vm._v(" 请填写完整补充规则" + _vm._s(index + 1) + " ")])]);
  }) : _vm._e(), _c("el-button", {
    staticClass: "add-rules-button",
    attrs: {
      icon: "el-icon-plus",
      disabled: !_vm.isEdit
    },
    on: {
      click: _vm.addCustomizeRules
    }
  }, [_vm._v("新建补充规则")])], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;