"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DealDetailDrawer = _interopRequireDefault(require("@/views/businessDetail/components/DealDetailDrawer"));
var _busiCalendarDirectory = _interopRequireDefault(require("@/components/BusinessDetail/busiCalendarDirectory"));
var _itemsFlow = _interopRequireDefault(require("@/components/BusinessDetail/itemsFlow"));
var _itemDetail = _interopRequireDefault(require("@/components/BusinessDetail/itemDetail"));
var _CommentsArea = _interopRequireDefault(require("@/components/Detail/CommentsArea"));
var _BusinessAllotDialog = _interopRequireDefault(require("@/components/BusinessAllotDialog"));
var _progressTags = _interopRequireDefault(require("@/components/BusinessDetail/progressTags.vue"));
var _DealStageLine = _interopRequireDefault(require("@/components/DealForm/DealStageLine.vue"));
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _business = require("@/api/business");
var _workspace = require("@/api/workspace");
var _client = require("@/api/client");
var _vuex = require("vuex");
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _DealOperateButton = _interopRequireDefault(require("@/views/business/components/DealOperateButton"));
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessDetail',
  components: {
    DealDetailDrawer: _DealDetailDrawer.default,
    BusiCalenderDirectoty: _busiCalendarDirectory.default,
    ItemsFlow: _itemsFlow.default,
    ItemDetail: _itemDetail.default,
    BusinessAllotDialog: _BusinessAllotDialog.default,
    ProgressTags: _progressTags.default,
    CommentsArea: _CommentsArea.default,
    DealStageLine: _DealStageLine.default,
    DetailHeader: _DetailHeader.default,
    DealOperateButton: _DealOperateButton.default,
    MeetingDialog: _MeetingDialog.default
  },
  inject: ['reload'],
  provide() {
    return {
      getActiveCommentId: this.getActiveCommentId,
      commentType: 'business',
      init: this.init
    };
  },
  async beforeRouteEnter(to, from, next) {
    let workspaceInfo = JSON.parse(sessionStorage.getItem('workspaceInfo'));
    if (!workspaceInfo) {
      (0, _workspace.getLatestWorkspaceInfo)().then(async ans => {
        if (ans.code === 20000) {
          workspaceInfo = ans.results;
          sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
          const res = await (0, _business.getDealCheck)(to.query.id, workspaceInfo.id);
          if (res.code === 20000) {
            next();
          }
        }
      });
    } else {
      const res = await (0, _business.getDealCheck)(to.query.id, workspaceInfo.id);
      if (res.code === 20000) {
        next();
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.dealId = to.query.id;
    next();
  },
  data() {
    return {
      dealId: null,
      isCalenderFold: false,
      business: {},
      dealObject: {},
      rightBarActive: false,
      // 评论显隐
      activeCommentId: null,
      // 消息转过来的打开评论的页面传递的commentId
      allotDialogProps: {
        dialogVisible: false
      },
      activeDate: '',
      activeConver: null,
      detailLoading: false,
      callVisible: false,
      // 有多个联系人时显示
      callOtherInfo: null,
      // 多个联系人时，传递的相关的信息
      callDialogPosition: null,
      // 多人外呼对话框的位置信息
      progressObj: {
        status: 'init',
        curLabel: '客户验证',
        type: 'in_process',
        progressArray: []
      },
      dealDrawerVisible: false,
      stageValue: 1,
      // 当前商机阶段id值
      stageType: '',
      // 当前商机阶段type
      dealDynamicForm: [],
      formDesc: [],
      activeDealId: null,
      meetingDialogVisible: false,
      meetingDialogTitle: '预约会议',
      meetingProps: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'busiActiveDate', 'busiActiveConver', 'has_outbound', 'has_video_permission', 'businessStatusOptions', 'api_permissions', 'workspaceInfo', 'timezone']),
    backButton() {
      var _this$$route, _this$$route$query;
      return !((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.noback);
    },
    isStagePermission() {
      return this.api_permissions.includes('/api/deal/detail/change_stage');
    },
    isStageReadonly() {
      return ['win', 'loss', 'invalidate'].includes(this.stageType);
    },
    timelineArray() {
      // 深拷贝出对象，避免公用对象间的混乱
      const obj = JSON.parse(JSON.stringify(this.dealObject));
      return obj.timeline;
    },
    getActiveDetail() {
      const timelineArray = this.dealObject.timeline;
      if (timelineArray) {
        let itemObj = {};
        timelineArray.forEach(item => {
          if (item.date === this.busiActiveDate) {
            item.infos.forEach(itemInfo => {
              if (itemInfo.id === this.busiActiveConver) {
                itemObj = itemInfo;
              }
            });
          }
        });
        return itemObj;
      } else {
        return {};
      }
    },
    getCallListFromInfo() {
      const callList = [];
      const info = JSON.parse(JSON.stringify(this.business));
      if (info.decision_maker && info.decision_maker.tel) {
        callList.push(info.decision_maker);
      }
      if (info.other_contacts) {
        info.other_contacts.forEach(item => {
          if (item.tel) {
            callList.push(item);
          }
        });
      }
      return callList;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getRightDialogTitle() {
      if (this.rightBarActive) {
        return '评论';
      }
    },
    variables() {
      return _variablesModule.default;
    },
    slotOperateButtons() {
      return [{
        operateType: 'createMeeting',
        icon: 'reservation-2',
        text: '预约会议',
        isShow: this.has_video_permission
      }, {
        operateType: 'callout',
        icon: 'phone-outline',
        text: '呼叫',
        isShow: this.api_permissions.includes('/api/micro_record/outbound/config/get'),
        isShowList: true
      }];
    }
  },
  watch: {
    workspaceInfo(val) {
      (0, _business.getDealCheck)(this.$route.query.id, this.workspaceInfo.id);
      this.init();
    },
    rightBarActive(val) {
      if (!val) {
        this.activeCommentId = null;
      }
    },
    webSocketMsg: {
      handler: function (newVal) {
        var _newVal$extra_info, _newVal$extra_info2;
        // 如果是评论相关的消息，是本会话的需要刷新评论数据
        if (newVal.type === 2 &&
        // eslint-disable-next-line eqeqeq
        ((_newVal$extra_info = newVal.extra_info) === null || _newVal$extra_info === void 0 ? void 0 : _newVal$extra_info.entity_type) === 'deal' && ((_newVal$extra_info2 = newVal.extra_info) === null || _newVal$extra_info2 === void 0 ? void 0 : _newVal$extra_info2.entity_id) === Number(this.dealId)) {
          if (this.rightBarActive) {
            if (this.$refs.comments) {
              this.$refs.comments.getComments();
            }
          }
        }
      },
      deep: true
    },
    dealId(val) {
      this.init();
      this.getDynamicForm();
    }
  },
  created() {
    this.dealId = this.$route.query.id;
  },
  mounted() {
    // 如果页面上有评论id, 则打开右侧的评论
    const commentId = this.$route.query.commentId ? this.$route.query.commentId : '';
    if (commentId) {
      this.rightBarActive = true;
      this.activeCommentId = Number(commentId);
    }
    // 点击其他地方时收起日历目录
    // let _this = this;
    // document.addEventListener("click", (e) => {
    //   if (
    //     _this.$refs.communicate &&
    //     !_this.$refs.communicate.contains(e.target)
    //   ) {
    //     _this.changeCommuDrawer(false);
    //   }
    // });
    // window.onresize = function () {
    //   _this.callVisible = false;
    // };
  },

  methods: {
    init() {
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', '');
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', null);
      this.getOneBusiness();
      this.$store.dispatch('business/get_business_status_options', 'deal').then(() => {
        this.getDealDetail();
      });
    },
    async getDynamicForm() {
      const res = await (0, _client.getDynamicForm)({
        in_account: 1
      });
      if (res.code === 200) {
        var _this$$route$query2;
        this.dealDynamicForm = res.results;
        const isOpenDetailDrawer = (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.openDetailDrawer;
        if (isOpenDetailDrawer) {
          this.openDealDrawer();
        }
      }
    },
    // 表单描述格式化
    descFormat(results, currentAccount) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          default: item.default_value,
          tips: item.tips,
          table: []
        };
        if (item.name === '客户名称') {
          obj.default = currentAccount.customer_name;
        }
        if (item.from_type === 'table') {
          obj.table.push(...item.table);
        }
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    },
    openDealDrawer() {
      this.dealDrawerVisible = true;
      const dealDynamicForm = this.dealDynamicForm.filter(item => {
        return item.field_name !== 'deal_stage';
      });
      this.formDesc = this.descFormat(dealDynamicForm, this.dealObject);
    },
    async getOneBusiness() {
      const res = await (0, _business.getOneBusiness)(this.$route.query.id, this.workspaceInfo.id);
      if (res.code === 200) {
        this.business = res.results;
      }
    },
    changeCommuDrawer(val) {
      this.rightBarActive = val;
    },
    getDealDetail() {
      this.detailLoading = true;
      const params = {
        deal_id: this.$route.query.id,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      (0, _business.getDealDetail)(params).then(res => {
        if (res.code === 200) {
          if (this.$route.query.date) {
            this.setDefaultActiveConverBydate(this.$route.query.date, res.results.timeline);
          } else if (this.$route.query.convId) {
            this.setDefaultActiveConverById(Number(this.$route.query.convId), res.results.timeline);
          } else {
            this.setDefaultActiveVal(res.results.timeline);
          }
          this.stageValue = res.results.stage;
          this.getStageTypeByid(res.results.stage);
          this.handleProgressObj(this.stageValue, res.results.predeal);
          this.dealObject = res.results;
          this.detailLoading = false;
        }
      });
    },
    closeCallDialog() {
      this.callVisible = false;
    },
    setDefaultActiveVal(timelineArray) {
      timelineArray.forEach((item, index) => {
        if (index === 0) {
          this.$store.commit('business/SET_BUSI_ACTIVE_DATE', item.date);
          this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', item.infos[0].id);
        }
      });
    },
    setDefaultActiveConverBydate(date, timelineArray) {
      timelineArray.forEach(item => {
        if (this.$moment(item.date).isSame(date)) {
          this.$store.commit('business/SET_BUSI_ACTIVE_DATE', date);
          this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', item.infos[0].id);
        }
      });
    },
    setDefaultActiveConverById(id, timelineArray) {
      timelineArray.forEach(item => {
        item.infos.forEach(conv => {
          if (conv.id === id) {
            this.$store.commit('business/SET_BUSI_ACTIVE_DATE', item.date);
            this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
          }
        });
      });
    },
    handleProgressObj(stage, predeal) {
      this.progressObj.status = stage;
      this.progressObj.type = this.stageType;
      if (predeal) {
        this.progressObj.predeal = predeal;
      }
      let curIndex = 0;
      this.progressObj.progressArray = this.handleBusinessStatusOptions(this.stageValue, this.stageType);
      this.progressObj.progressArray.forEach((item, index) => {
        if (item.name === stage) {
          this.progressObj.curLabel = item.label;
          item.date = true;
          curIndex = index;
        }
      });
      this.progressObj.progressArray.forEach((item, index) => {
        if (index <= curIndex) {
          item.date = true;
        } else {
          item.date = false;
        }
      });
    },
    handleBusinessStatusOptions(currentStage, currentStageType) {
      const readonlyValues = ['win', 'loss', 'invalidate'];
      const progressArray = [];
      this.businessStatusOptions.forEach(item => {
        if (item.type === 'in_process') {
          const itemObj = {
            label: item.stage_name,
            name: item.id,
            date: false,
            visible: false
          };
          progressArray.push(itemObj);
        } else {
          if (currentStage === item.id) {
            const closeItemObj = {
              label: item.stage_name,
              name: item.id,
              date: false,
              visible: false
            };
            progressArray.push(closeItemObj);
          }
        }
      });
      if (!readonlyValues.includes(currentStageType)) {
        progressArray.push({
          label: '结束',
          name: 'close',
          date: false,
          visible: false
        });
      }
      return progressArray;
    },
    openAllotDialog(type) {
      this.allotDialogProps = {
        dialogVisible: true,
        type,
        dealId: this.dealId
      };
    },
    deleteDeal() {
      this.$confirm(`是否删除商机？`, '提示', {
        confirmButtonText: '删 除',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: '取 消'
      }).then(async () => {
        const res = await (0, _business.deleteDeal)({
          deal_id: this.$route.query.id
        });
        if (res.code === 200 && res.msg === 'Success') {
          this.$message.success('删除成功');
          this.$router.push({
            path: _routeMap.ROUTES.deal
          });
        }
      }).catch(() => null);
    },
    handleMoreCommand(command) {
      switch (command) {
        case 'edit':
          this.openDealDrawer();
          break;
      }
    },
    handleDealOperation(type) {
      switch (type) {
        case 'allocate_from_me_to_anyone':
          this.dealDrawerVisible = false;
          this.openAllotDialog('normal');
          break;
        case 'allocate_from_A_to_B':
          this.dealDrawerVisible = false;
          this.openAllotDialog('super');
          break;
        case 'delete':
          this.deleteDeal();
          break;
      }
    },
    getActiveCommentId() {
      return this.activeCommentId;
    },
    // 根据stage id值名获取该商机类型
    getStageTypeByid(stage) {
      function checkStage(item) {
        return item.id === stage;
      }
      const stageItem = this.businessStatusOptions.find(checkStage);
      // eslint-disable-next-line no-undef
      this.stageType = stageItem === null || stageItem === void 0 ? void 0 : stageItem.type;
    },
    // 修改商机阶段
    async changeStage(val) {
      const res = await (0, _business.changeDealStage)({
        deal_id: this.dealId,
        stage: val
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('修改成功');
        this.stageValue = val;
        this.getStageTypeByid(val);
        this.handleProgressObj(val);
        this.getOneBusiness();
      }
    },
    clickContactPersonItem(contactItem, dealInfo, type, contactList, meetingType) {
      if (type === 'createMeeting') {
        this.createMeeting(contactItem, dealInfo, contactList, meetingType);
      } else {
        this.callOut(contactItem, dealInfo);
      }
    },
    callOut(contactItem, info) {
      if (!this.has_outbound) {
        this.$message.warning('暂无坐席');
        return;
      }
      const _data = {
        id: info.deal_id,
        type: 'deal',
        company: '',
        contact_id: contactItem.id,
        contact_person: contactItem.name,
        phone: contactItem.tel
      };
      this.$bus.$emit('openPhoneCall', _data);
    },
    createMeeting(contactItem, dealInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      const {
        deal_id,
        customer_name
      } = dealInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName
      } = contactItem;
      this.meetingProps = {
        type: 'business',
        businessId: deal_id,
        customerName: customer_name,
        contactOptions,
        contactId,
        contactName,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    closeMeetingDialog() {
      this.meetingDialogVisible = false;
      this.init();
    }
  }
};
exports.default = _default;