"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      title: _vm.modelId ? `版本测试- V${_vm.version} - ${_setup.modelDetail.name} ` : "测试模型",
      visible: _vm.visible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [!_vm.modelId ? [_c("div", {
    staticClass: "row-1"
  }, [_c("h1", {
    staticClass: "dialog-title"
  }, [_vm._v("模型")]), _vm.visible ? _c(_setup.ModelAndVersionSelect, {
    ref: "modelAndVersionSelectRef",
    on: {
      selectChange: _setup.ModelAndVersionSelectChange
    }
  }) : _vm._e()], 1)] : _vm._e(), _c("h1", {
    staticClass: "dialog-title"
  }, [_vm._v("测试集")]), _c(_setup.DataSetSelectors, {
    attrs: {
      "fill-value": _setup.testDataFill,
      "disabled-data-sets": _setup.trainDataSet,
      "tip-count": "100",
      single: ""
    },
    model: {
      value: _setup.testDataSet,
      callback: function ($$v) {
        _setup.testDataSet = $$v;
      },
      expression: "testDataSet"
    }
  }), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.startTest
    }
  }, [_vm._v("开始测试")])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;