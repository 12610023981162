"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-script-set"
  }, [_c("p", {
    staticClass: "script-set-tip"
  }, [_vm._v(" 在对话流程提示词输入 “/” 添加关键问题，在此设置关键问题和标准话术 ")]), _vm._l(_vm.value, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "script-item",
      class: {
        "is-active": item.id === _vm.activeScriptId
      },
      attrs: {
        "data-script-id": item.id
      },
      on: {
        click: function ($event) {
          return _setup.focusScript(item.id);
        }
      }
    }, [_c("div", {
      staticClass: "script-index"
    }, [_vm._v(_vm._s(index + 1))]), _c("div", {
      staticClass: "script-content"
    }, [_c("el-input", {
      staticClass: "script-qa",
      attrs: {
        type: "textarea",
        placeholder: "请输入AI客户的关键问题",
        resize: "none",
        autosize: {
          minRows: 1,
          maxRows: 5
        },
        disabled: item.disabled
      },
      on: {
        input: function ($event) {
          return _setup.onContentInput($event, item.el);
        },
        focus: function ($event) {
          return _setup.focusScript(item.id);
        }
      },
      model: {
        value: item.content,
        callback: function ($$v) {
          _vm.$set(item, "content", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "item.content"
      }
    }), _vm._l(item.replies, function (reply, replyIndex) {
      return _c("div", {
        key: replyIndex,
        staticClass: "reply-container"
      }, [_c("el-input", {
        staticClass: "script-qa",
        attrs: {
          type: "textarea",
          resize: "none",
          autosize: {
            minRows: 1,
            maxRows: 5
          },
          placeholder: "请输入学员的标准话术（用于练习后评分）",
          maxlength: "1000"
        },
        on: {
          focus: function ($event) {
            return _setup.focusScript(item.id);
          }
        },
        model: {
          value: reply.content,
          callback: function ($$v) {
            _vm.$set(reply, "content", $$v);
          },
          expression: "reply.content"
        }
      }), !replyIndex ? _c("el-tooltip", {
        attrs: {
          placement: "top",
          content: "添加学员标准话术"
        }
      }, [_c("MgvButton", {
        attrs: {
          icon: "icon-plus",
          color: "info",
          type: "plain",
          size: "medium",
          square: ""
        },
        on: {
          click: function ($event) {
            return _setup.addReply(item);
          }
        }
      })], 1) : _c("MgvButton", {
        attrs: {
          icon: "icon-delete",
          size: "medium",
          color: "info",
          type: "plain",
          square: ""
        },
        on: {
          click: function ($event) {
            return _setup.deleteReply(item, replyIndex);
          }
        }
      })], 1);
    })], 2)]);
  }), _c(_setup.PracticeProcessTipsSwitch, {
    attrs: {
      title: "对练话术提示",
      description: "开启后，学员可在练习过程中查看话术提示"
    },
    model: {
      value: _setup.promptStruct.is_keypoints_display,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "is_keypoints_display", $$v);
      },
      expression: "promptStruct.is_keypoints_display"
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;