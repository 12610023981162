"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'DealMoreFilters',
  props: {
    customFilterObject: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      checkList: [],
      visible: false
    };
  },
  watch: {},
  methods: {
    handleChange: (0, _commonFunc.debounce)(function (val) {
      this.$emit('saveFilters', val);
    }, 500),
    handleClick() {
      const res = [];
      this.checkList = [];
      for (const key in this.customFilterObject) {
        res.push(...this.customFilterObject[key]);
      }
      res.filter(item => {
        if (item.show) {
          this.checkList.push(item.field_name);
        }
      });
    }
  }
};
exports.default = _default;