"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelPublishModel = cancelPublishModel;
exports.changeModelVersion = changeModelVersion;
exports.createAiModel = createAiModel;
exports.createCombineModel = createCombineModel;
exports.createModel = createModel;
exports.createTestTask = createTestTask;
exports.createTrainTask = createTrainTask;
exports.deleteModel = deleteModel;
exports.editCombineModel = editCombineModel;
exports.editModelBaseInfo = editModelBaseInfo;
exports.editModelConfig = editModelConfig;
exports.eventAssociateModel = eventAssociateModel;
exports.getCombineModelDetail = getCombineModelDetail;
exports.getModelBindInfo = getModelBindInfo;
exports.getModelDetail = getModelDetail;
exports.getModelItem = getModelItem;
exports.getModelList = getModelList;
exports.getModelTestRecordList = getModelTestRecordList;
exports.getModelTrainRecordList = getModelTrainRecordList;
exports.getModelVersionList = getModelVersionList;
exports.getTestRecordDetail = getTestRecordDetail;
exports.getTestTaskDetail = getTestTaskDetail;
exports.publishModel = publishModel;
exports.retryTrain = retryTrain;
exports.updateTrailAnnotation = updateTrailAnnotation;
exports.validateModelCanCreate = validateModelCanCreate;
var _request = require("@/utils/request");
function getModelList(data) {
  return (0, _request.webService)({
    url: '/model/list',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getModelItem(params) {
  return (0, _request.webService)({
    url: '/model/refresh_item_info',
    method: 'get',
    params
  });
}
function validateModelCanCreate(params) {
  return (0, _request.webService)({
    url: '/model/can_create',
    method: 'get',
    params
  });
}
function createModel(data) {
  return (0, _request.webService)({
    url: '/model/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建ai模型
function createAiModel(data) {
  return (0, _request.webService)({
    url: '/model/create_ai_model',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑模型基础信息
function editModelBaseInfo(data) {
  return (0, _request.webService)({
    url: '/model/edit_info',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑模型配置
function editModelConfig(data) {
  return (0, _request.webService)({
    url: '/model/edit_config',
    method: 'post',
    dataType: 'json',
    data
  });
}
function publishModel(data) {
  return (0, _request.webService)({
    url: '/model/online',
    method: 'post',
    dataType: 'json',
    data
  });
}
function cancelPublishModel(data) {
  return (0, _request.webService)({
    url: '/model/offline',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建训练任务
function createTrainTask(data) {
  return (0, _request.webService)({
    url: '/model/create_train_task',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取模型详情
function getModelDetail(params) {
  return (0, _request.webService)({
    url: '/model/detail',
    method: 'get',
    params
  });
}

// 重试训练
function retryTrain(data) {
  return (0, _request.webService)({
    url: '/model/train_retry',
    method: 'post',
    dataType: 'json',
    data,
    timeout: 120
  });
}

/**
 * 全部版本页面
 */
// 获取模型版本
function getModelVersionList(params) {
  return (0, _request.webService)({
    url: '/model/get_versions',
    method: 'get',
    params
  });
}

// 创建测试任务
function createTestTask(data) {
  return (0, _request.webService)({
    url: '/model/create_trial_task',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取模型测试记录
function getModelTestRecordList(params) {
  return (0, _request.webService)({
    url: '/model/get_trial_task',
    method: 'get',
    params
  });
}

// 模型测试记录详情
function getTestRecordDetail(params) {
  return (0, _request.webService)({
    url: '/model/get_trial_task_detail',
    method: 'get',
    params
  });
}

// 获取模型训练记录
function getModelTrainRecordList(params) {
  return (0, _request.webService)({
    url: '/model/get_train_task',
    method: 'get',
    params
  });
}
function changeModelVersion(data) {
  return (0, _request.webService)({
    url: '/model/change_version',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 关键事件关联模型
function eventAssociateModel(data) {
  return (0, _request.webService)({
    url: '/mp_event/associate_model',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 修改测试集标注
function updateTrailAnnotation(data) {
  return (0, _request.webService)({
    url: '/model/update_trial_annotation',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 获取测试任务详情
function getTestTaskDetail(params) {
  return (0, _request.webService)({
    url: '/model/get_trial_task_info',
    method: 'get',
    params
  });
}

// 创建组合模型
function createCombineModel(data) {
  return (0, _request.webService)({
    url: '/model/create_combine_model',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 编辑组合模型
function editCombineModel(data) {
  return (0, _request.webService)({
    url: '/model/edit_combine_model',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 获取组合模型配置
function getCombineModelDetail(params) {
  return (0, _request.webService)({
    url: '/model/combine_model_detail',
    method: 'get',
    params
  });
}

// 校验模型是否可删除
function getModelBindInfo(params) {
  return (0, _request.webService)({
    url: '/model/bind_info',
    method: 'get',
    params
  });
}

// 删除模型
function deleteModel(data) {
  return (0, _request.webService)({
    url: '/model/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}