"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "department-select",
    staticClass: "department-select",
    attrs: {
      placeholder: _vm.placeholder,
      clearable: _vm.clearable,
      "popper-class": "department-select-options"
    },
    on: {
      clear: _vm.clear
    },
    model: {
      value: _vm.departmentLabel,
      callback: function ($$v) {
        _vm.departmentLabel = $$v;
      },
      expression: "departmentLabel"
    }
  }, [_c("el-option", {
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: 0
    }
  }, [_c("el-tree", {
    ref: "department-tree",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.orgTree,
      "node-key": "id",
      "show-checkbox": "",
      props: _vm.defaultProps,
      "check-strictly": true,
      "expand-on-click-node": false,
      "check-on-click-node": "",
      "default-checked-keys": _vm.defaultChecked,
      "default-expanded-keys": _vm.defaultExpanded
    },
    on: {
      "check-change": _vm.handleCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": data.path === "001" ? "office-building" : "department"
          }
        }), _vm._v(" " + _vm._s(data.name) + " ")], 1)];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;