"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _workspace = require("@/api/workspace");
var _SpaceDescriptionPopover = _interopRequireDefault(require("@/components/SpaceDescriptionPopover"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'WorkspaceListBox',
  components: {
    SpaceDescriptionPopover: _SpaceDescriptionPopover.default
  },
  props: {
    workspaceList: {
      type: Array,
      default: () => []
    },
    logoUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      curWorkspaceId: 0,
      workspaceLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        this.curWorkspaceId = val;
      },
      immediate: true
    }
  },
  methods: {
    switchWorkspace(item) {
      this.$emit('click');
      let workspaceInfo = item;
      (0, _workspace.judgeWorkspaceExist)(item.id).then(res => {
        if (res.code === 20000) {
          if (!res.results.exists) {
            workspaceInfo = this.workspaceList[0];
          }
          this.$store.dispatch('workspace/changeWorkspace', workspaceInfo);
          sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
          (0, _workspace.switchWorkspace)({
            id: workspaceInfo.id
          });
        }
      });
    },
    startLoading() {
      this.workspaceLoading = true;
    },
    endLoading() {
      this.workspaceLoading = false;
    }
  }
};
exports.default = _default;