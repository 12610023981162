"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "rapid-modeling-header"
  }, [_c("div", {
    staticClass: "rapid-modeling-header__title"
  }, [_vm._v("快速建模")]), _setup.modelInfo.name ? _c("div", {
    staticClass: "rapid-modeling-header__name"
  }, [_c("i", {
    staticClass: "iconfont icon-box-square"
  }), _c("span", [_vm._v(_vm._s(_setup.modelInfo.name))])]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;