"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "analysis-header-button"
  }, [_c("el-popover", {
    attrs: {
      trigger: "click",
      "popper-class": "analysis-header-popover",
      "visible-arrow": false,
      placement: "bottom-end"
    },
    model: {
      value: _vm.isPopoverShow,
      callback: function ($$v) {
        _vm.isPopoverShow = $$v;
      },
      expression: "isPopoverShow"
    }
  }, [_c("div", {
    staticClass: "header-card"
  }, [_c("div", {
    staticClass: "checkbox-container"
  }, _vm._l(_vm.list, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      staticClass: "analysis-checkbox",
      attrs: {
        label: item.name,
        "true-label": 1,
        "false-label": 0
      },
      model: {
        value: item.is_show,
        callback: function ($$v) {
          _vm.$set(item, "is_show", $$v);
        },
        expression: "item.is_show"
      }
    });
  }), 1), _c("div", {
    staticClass: "button-container"
  }, [_c("el-button", {
    staticClass: "mg-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)]), _c("el-button", {
    staticClass: "analysis-button mg-button",
    attrs: {
      slot: "reference",
      plain: ""
    },
    slot: "reference"
  }, [_vm._v(" 分析模块 "), _c("i", {
    staticClass: "el-icon-arrow-right",
    class: {
      "is-popover-show": _vm.isPopoverShow
    }
  })])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;