"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "474px",
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      "custom-class": "move-folder-dialog"
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "footer"
        }, [_c("el-button", {
          staticClass: "mg-button",
          attrs: {
            plain: ""
          },
          on: {
            click: _vm.closeDialog
          }
        }, [_vm._v("取消")]), _c("el-button", {
          staticClass: "mg-button",
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("确定")])], 1)];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "targetFolderId"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" 选择文件夹 "), _c("span", {
          staticClass: "dot"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c("choose-folder-select", {
    attrs: {
      value: _vm.targetFolder,
      "is-snippet": _vm.isSnippet
    },
    on: {
      input: _vm.handleSelect
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;