"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModelPredictionResult = _interopRequireDefault(require("./ModelPredictionResult.vue"));
var _TrainAndTestDataSet = _interopRequireDefault(require("./TrainAndTestDataSet.vue"));
var _TrainSuperParamTable = _interopRequireDefault(require("@/components/TrainSuperParamTable"));
var _vue = require("vue");
var _modelManagement = require("@/api/modelManagement");
var _useTrainSuperParams = require("@/views/management/modelManagement/hooks/use-train-super-params");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SuperParamsSetDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelInfo: {
      type: Object,
      default: () => ({})
    },
    hasDataType: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:visible', 'modelCreateTrainTask'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;

    // 超參设置hooks
    const {
      initDrawer,
      paramsChange,
      onAutoTuningParams,
      originTrainParams,
      autoTuningParams,
      trainParam,
      trainParamMode,
      trainSuperParamTableRef
    } = (0, _useTrainSuperParams.useTrainSuperParams)();

    // 训练和测试 hooks
    const {
      trialDatasetList,
      trainDatasetList,
      datasetSplitType,
      trainAndTestDatasetRef,
      getTrainAndTrailDatasetData
    } = (0, _useTrainSuperParams.useTrainAndTestDatasetParams)();

    // 大模型后处理hooks
    const {
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      getPromptContent,
      promptContent
    } = (0, _useTrainSuperParams.usePredictionResult)();
    const btnLoading = (0, _vue.ref)(false);
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const contextScene = (0, _vue.ref)(2);
    const changeContextScene = val => {
      contextScene.value = val ? 1 : 0;
    };
    const modelCreateTrainTask = () => {
      if (!autoTuningParams.value) {
        trainParamMode.value = 0;
      }

      // 模型预测结果模块
      const promptContent = getPromptContent();
      if (!promptContent) return;

      // 训练和测试模块
      const trainAndTestDatasetParams = getTrainAndTrailDatasetData();
      if (!trainAndTestDatasetParams) {
        return;
      }
      const {
        train_datasets,
        trial_datasets,
        dataset_split_type
      } = trainAndTestDatasetParams;
      const params = {
        train_datasets,
        trial_datasets,
        dataset_split_type,
        postprocess_type: postProcessType.value,
        prompt_info: {
          prompt_content: isEnableLLM.value ? promptContent : ''
        },
        postprocess_llm_enable: isEnableLLM.value,
        train_params: trainParam.value,
        context_scene: contextScene.value,
        train_param_mode: trainParamMode.value
      };
      emit('modelCreateTrainTask', params);
    };
    const handleBtnLoading = val => {
      btnLoading.value = val;
    };
    const contains = (a, b) => Object.entries(b).every(_ref2 => {
      let [k, v] = _ref2;
      return a[k] === v;
    });
    const fillValue = (0, _vue.ref)({});
    const getTrainParamsApi = async () => {
      const res = await (0, _modelManagement.getModelDetail)({
        id: props.modelInfo.id,
        version: props.modelInfo.version
      });
      if (res.code === 20000) {
        var _postprocess$prompt_i;
        const {
          train_param,
          context_scene,
          postprocess,
          train_param_mode,
          train_datasets,
          trial_datasets,
          dataset_split_type,
          postprocess_type,
          postprocess_llm_enable
        } = res.results;
        contextScene.value = context_scene;
        postProcessType.value = postprocess_type || 1;
        isEnableLLM.value = postprocess_llm_enable;
        promptContent.value = postprocess === null || postprocess === void 0 ? void 0 : (_postprocess$prompt_i = postprocess.prompt_info) === null || _postprocess$prompt_i === void 0 ? void 0 : _postprocess$prompt_i.prompt_content;
        // 参数是自动还是手动设置
        autoTuningParams.value = !!train_param_mode;
        // 自动参数选项
        trainParamMode.value = train_param_mode;
        // 手动参数选项
        fillValue.value = train_param;
        // 自动参数选项
        trainDatasetList.value = train_datasets.slice(0, 1);
        datasetSplitType.value = dataset_split_type || 1;
        trialDatasetList.value = trial_datasets.slice(0, 1);
      }
    };
    expose({
      handleBtnLoading,
      initDrawer
    });
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        getTrainParamsApi();
      }
    });
    return {
      __sfc: true,
      props,
      initDrawer,
      paramsChange,
      onAutoTuningParams,
      originTrainParams,
      autoTuningParams,
      trainParam,
      trainParamMode,
      trainSuperParamTableRef,
      trialDatasetList,
      trainDatasetList,
      datasetSplitType,
      trainAndTestDatasetRef,
      getTrainAndTrailDatasetData,
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      getPromptContent,
      promptContent,
      btnLoading,
      emit,
      updateVisible,
      contextScene,
      changeContextScene,
      modelCreateTrainTask,
      handleBtnLoading,
      contains,
      fillValue,
      getTrainParamsApi,
      ModelPredictionResult: _ModelPredictionResult.default,
      TrainAndTestDataSet: _TrainAndTestDataSet.default,
      TrainSuperParamTable: _TrainSuperParamTable.default
    };
  }
};
exports.default = _default;