"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useVuex = require("@/hooks/use-vuex");
var _default = {
  __name: 'EffectTestSentenceItem',
  props: {
    avatarUrl: {
      type: String,
      default: ''
    },
    sentence: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    return {
      __sfc: true,
      props,
      store
    };
  }
};
exports.default = _default;