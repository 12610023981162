"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskCenterExportButton = _interopRequireDefault(require("@/components/TaskCenterExportButton"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _fullScreenBtn = _interopRequireDefault(require("@/components/FullScreen/fullScreenBtn"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TrendAnalysis',
  components: {
    ChartTemplate: _ChartTemplate.default,
    fullScreenBtn: _fullScreenBtn.default,
    TaskCenterExportButton: _TaskCenterExportButton.default
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      getChartDataLoading: false,
      chartContainer: null,
      dimension: 'month',
      chartName: '',
      chartConfig: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone']),
    getSelectValue() {
      var _this$getDimensionOpt;
      return (_this$getDimensionOpt = this.getDimensionOptions().find(_ref => {
        let {
          value
        } = _ref;
        return value === this.dimension;
      })) === null || _this$getDimensionOpt === void 0 ? void 0 : _this$getDimensionOpt.label;
    },
    requestParams() {
      return {
        ...this.params,
        q_id: this.config.id,
        date_interval: this.dimension,
        time_zone: this.timezone
      };
    }
  },
  watch: {
    'config.id': {
      handler: function () {
        this.getChartData();
      },
      immediate: true
    },
    params() {
      this.getChartData();
    }
  },
  mounted() {
    this.chartContainer = this.$refs['chart'];
  },
  methods: {
    async exportData(callback) {
      const res = await (0, _businessIntelligence.exportFAQTrendAnalysisList)(this.requestParams);
      if (res.code === 20000) {
        callback();
      }
    },
    async getChartData() {
      this.getChartDataLoading = true;
      if (!this.config.id) return;
      const res = await (0, _businessIntelligence.getFAQChartData)(this.requestParams).finally(() => {
        this.getChartDataLoading = false;
      });
      if (res.code === 20000) {
        this.formatData(res.results);
      }
    },
    formatData(_ref2) {
      let {
        data
      } = _ref2;
      this.setChartConfig(data.map(_ref3 => {
        let {
          time,
          deal_freq,
          count,
          QOQ,
          all_count
        } = _ref3;
        time = this.$moment(time).format(this.dimension === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD');
        return [time, deal_freq, count, all_count, QOQ];
      }));
    },
    setChartConfig(source) {
      this.chartConfig = {
        grid: {
          containLabel: true,
          top: '5%',
          left: 0,
          right: 10,
          bottom: '5%'
        },
        color: '#4461EC',
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => value + '%'
          }
        },
        dataset: {
          source
        },
        series: {
          type: 'line',
          encode: {
            x: 0,
            y: 1
          }
        },
        tooltip: {
          borderColor: 'transparent',
          trigger: 'axis',
          formatter: value => this.setTooltip(value[0])
        }
      };
    },
    setTooltip(_ref4) {
      let {
        data: [time, deal_freq, count, deal_count, QOQ]
      } = _ref4;
      const weightStyle = "style='font-weight: 600;color:#2B323B;'";
      const normalStyle = "style='font-size: 12px;font-weight: normal;color:#8A98A8;'";
      const deltaStyle = 'display: inline-block;width:0;height: 16px;border: 8px solid transparent;';
      const increaseStyle = 'border-bottom-color:#5CD6A3;margin-bottom: 2px;';
      const decreaseStyle = 'border-top-color:#e54d52;margin-bottom: -6px;';
      const QOQHtml = `<p ${weightStyle}>${typeof QOQ === 'number' ? `<span style='${deltaStyle} ${QOQ > 0 ? increaseStyle : decreaseStyle};'></span>${Math.abs(QOQ)}%` : `<span style='${normalStyle}'>${QOQ}</span>`}
        <span ${normalStyle}>较上${this.dimension === 'month' ? '月' : '周'}</span>
      </p>`;
      return `<p ${weightStyle}>${time}</p>
      <p ${weightStyle}>
        ${deal_freq}%
        <span ${normalStyle}>(${deal_count}笔交易中有${count}笔提到)</span>
      </p>
      ${QOQHtml}`;
    },
    getDimensionOptions() {
      return [{
        label: '月',
        value: 'month'
      }, {
        label: '周',
        value: 'week'
      }];
    }
  }
};
exports.default = _default;