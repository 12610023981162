"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DayCalendar',
  components: {
    NoData: _NoData.default
  },
  props: {
    data: {
      type: [Array, Object],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    needLoadMore: {
      type: Boolean,
      default: false
    },
    leftWidth: {
      type: Number,
      default: 88
    },
    topHeight: {
      type: Number,
      default: 56
    },
    startHour: {
      type: Number,
      default: 0
    },
    endHour: {
      type: Number,
      default: 23
    },
    initHour: {
      type: Number,
      default: 9
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: '',
      dayHours: [],
      // 一天的日期数组
      distance: 0,
      clientWidth: 0,
      timeLineRoadHeight: 0,
      timeLineTop: 0,
      timer: null,
      now: null
    };
  },
  computed: {
    leftBtnVisible() {
      return this.distance !== 0 && this.maxTranslatex > 0;
    },
    rightBtnVisible() {
      return this.distance !== this.maxTranslatex && this.maxTranslatex > 0;
    },
    // 移动方块的实际占用距离
    scrollWidth() {
      return this.$props.data.length * 300;
    },
    // 最大可移动距离
    maxTranslatex() {
      return this.scrollWidth - this.clientWidth;
    },
    // 根据屏幕宽度适配单次拉动的距离
    // eslint-disable-next-line vue/return-in-computed-property
    singleTransDistance() {
      if (this.clientWidth <= 900) {
        return 300;
      } else if (this.clientWidth > 900 && this.clientWidth <= 1800) {
        return 900;
      } else if (this.clientWidth > 1800) {
        return 1500;
      }
    }
  },
  watch: {
    date(val) {
      this.$emit('dateChange', val);
      sessionStorage.setItem('dailyDate', val);
    }
  },
  mounted() {
    this.setClientWidth();
    this.setScrollTop();
    window.addEventListener('resize', this.setClientWidth);
  },
  created() {
    const dailyDate = sessionStorage.getItem('dailyDate');
    if (dailyDate) {
      this.setDate(dailyDate);
    } else {
      this.setNow();
    }
    this.setDayTime();
    this.setTimeLine();
    this.timer = setInterval(this.setTimeLine, 25000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    setNow() {
      this.date = this.$moment().format('YYYY-MM-DD');
    },
    setDate(date) {
      this.date = this.$moment(date).format('YYYY-MM-DD');
    },
    switchDate(num) {
      this.date = this.$moment(this.date).add(num, 'd').format('YYYY-MM-DD');
    },
    flip(num) {
      this.handleFlip(num);
      if (this.needLoadMore) {
        this.$emit('loadMore');
      }
      this.$emit('clickFixedElement');
    },
    handleFlip(num) {
      this.distance += num * this.singleTransDistance;
      // 如果移动距离超出可移动距离，或者剩余的可移动距离小于300，则直接移动至边界
      if (this.distance > this.maxTranslatex || this.maxTranslatex - this.distance < 300) {
        this.distance = this.maxTranslatex;
      } else if (this.distance < 300) {
        this.distance = 0;
      }
    },
    setClientWidth() {
      this.clientWidth = window.innerWidth - this.$props.leftWidth - 48;
      // 在最右侧贴边，屏幕从窄变宽时，最右侧能保持贴边
      if (this.maxTranslatex > 0 && this.distance > this.maxTranslatex) {
        this.distance = this.maxTranslatex;
      }
    },
    setDayTime() {
      const {
        startHour,
        endHour
      } = this.$props;
      for (let i = startHour; i <= endHour; i++) {
        const diff = i - 8;
        const hour = this.$moment(new Date(diff * 3600000)).format('HH:mm');
        const getHour = this.$moment(new Date(diff * 3600000)).hour();
        this.dayHours.push({
          hour,
          getHour
        });
      }
    },
    setTimeLine() {
      // 设置时间线位置
      const {
        startHour,
        endHour
      } = this.$props;
      this.timeLineRoadHeight = (endHour - startHour + 1) * 122; // 获取总高度
      const now = this.$moment();
      this.now = now;
      const seconds = (endHour - startHour + 1) * 3600; // 总秒数
      const hour = now.hour();
      const minute = now.minute();
      const second = now.second();
      const nowSeconds = (hour - startHour) * 3600 + minute * 60 + second; // 已经经过的秒数
      this.timeLineTop = parseInt(nowSeconds / seconds * this.timeLineRoadHeight);
    },
    // 设置滚动条位置
    setScrollTop() {
      const {
        startHour,
        endHour,
        initHour
      } = this.$props;
      const ratio = initHour / (endHour - startHour + 1);
      this.$nextTick(() => {
        if (this.$refs['calendarBody']) {
          this.$refs['calendarBody'].scrollTop = this.$refs['calendarBody'].scrollHeight * ratio;
        }
      });
    }
  }
};
exports.default = _default;