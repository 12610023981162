"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChartTemplate',
  mixins: [_chartMixins.default],
  computed: {
    isNoData() {
      var _this$chartConfig, _this$chartConfig$dat, _this$chartConfig$dat2, _this$chartConfig2, _this$chartConfig2$se, _this$chartConfig3, _this$chartConfig3$se, _this$chartConfig3$se2;
      return !((_this$chartConfig = this.chartConfig) !== null && _this$chartConfig !== void 0 && (_this$chartConfig$dat = _this$chartConfig.dataset) !== null && _this$chartConfig$dat !== void 0 && (_this$chartConfig$dat2 = _this$chartConfig$dat.source) !== null && _this$chartConfig$dat2 !== void 0 && _this$chartConfig$dat2.length || (_this$chartConfig2 = this.chartConfig) !== null && _this$chartConfig2 !== void 0 && (_this$chartConfig2$se = _this$chartConfig2.series) !== null && _this$chartConfig2$se !== void 0 && _this$chartConfig2$se.length || (_this$chartConfig3 = this.chartConfig) !== null && _this$chartConfig3 !== void 0 && (_this$chartConfig3$se = _this$chartConfig3.series) !== null && _this$chartConfig3$se !== void 0 && (_this$chartConfig3$se2 = _this$chartConfig3$se.data) !== null && _this$chartConfig3$se2 !== void 0 && _this$chartConfig3$se2.length);
    }
  },
  watch: {
    data: {
      handler() {
        this.init();
      },
      deep: true
    },
    chartConfig() {
      this.init();
    }
  },
  methods: {
    init() {
      this.chart.setOption(this.chartConfig || {}, true);
    }
  }
};
exports.default = _default;