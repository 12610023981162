"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "info-edit-card"
  }, [_c("div", {
    staticClass: "card-title-container"
  }, [_c("div", {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("div", {
    staticClass: "btns"
  }, [_c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isEditing && !_vm.directlyEditable,
      expression: "!isEditing && !directlyEditable"
    }],
    attrs: {
      icon: "iconfont icon-edit",
      type: "text",
      size: "medium"
    },
    on: {
      click: function ($event) {
        _setup.isEditing = true;
      }
    }
  }, [_vm._v("编辑")]), _c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isEditing && !_vm.directlyEditable,
      expression: "isEditing && !directlyEditable"
    }],
    attrs: {
      type: "plain",
      color: "info",
      size: "medium"
    },
    on: {
      click: _setup.cancel
    }
  }, [_vm._v("取消")]), _c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isEditing,
      expression: "isEditing"
    }],
    attrs: {
      size: "medium",
      loading: _vm.submitLoading
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("保存")])], 1)]), _vm._t("card-content", null, {
    isEditing: _setup.isEditing
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;