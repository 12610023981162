"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generator-form-item"
  }, [_setup.fieldType === "input" ? [_c("el-input", {
    attrs: {
      value: _vm.value,
      placeholder: "请输入",
      maxlength: 20
    },
    on: {
      input: _setup.onInput
    }
  })] : _setup.fieldType === "textarea" ? [_c("el-input", {
    attrs: {
      type: "textarea",
      value: _vm.value,
      placeholder: "请输入",
      maxlength: "500",
      "show-word-limit": "",
      autosize: {
        minRows: 3,
        maxRows: 20
      }
    },
    on: {
      input: _setup.onInput
    }
  })] : _setup.fieldType === "number" ? [_c("el-input", {
    attrs: {
      value: _vm.value,
      placeholder: "请输入",
      type: "number"
    },
    on: {
      input: _setup.onInput
    }
  })] : _setup.fieldType === "radio" ? [_c("div", [_c("el-radio-group", {
    attrs: {
      value: _setup.props.optionIds[0]
    },
    on: {
      input: _setup.handleRadioChange
    }
  }, _vm._l(_setup.props.options, function (item) {
    return _c("el-radio", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)] : _setup.fieldType === "checkbox" ? [_c("div", [_c("el-checkbox-group", {
    attrs: {
      value: _setup.props.optionIds
    },
    on: {
      input: _setup.handleCheckboxChange
    }
  }, _vm._l(_setup.props.options, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)] : _setup.fieldType === "date" ? [_c("el-date-picker", {
    attrs: {
      value: _vm.value,
      placeholder: "请选择",
      "value-format": "yyyy-MM-dd",
      format: "yyyy-MM-dd"
    },
    on: {
      input: _setup.onInput
    }
  })] : _setup.fieldType === "datetime" ? [_c("el-date-picker", {
    attrs: {
      value: _vm.value,
      type: "datetime",
      placeholder: "请选择",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      format: "yyyy-MM-dd HH:mm:ss"
    },
    on: {
      input: _setup.onInput
    }
  })] : _setup.fieldType === "cascader" ? [_c(_setup.WidgetCascader, {
    attrs: {
      options: _setup.props.options,
      "cascader-ids": _setup.props.cascaderIds
    },
    on: {
      cascaderFieldChange: _setup.handleCascaderFieldChange
    }
  })] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;