"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-input", {
    ref: "inputRef",
    attrs: {
      value: _setup.localValue
    },
    on: {
      input: _setup.onInput,
      blur: _setup.onBlur
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("el-tooltip", {
    attrs: {
      content: "自定义关键事件标识，使用时与系统关键事件做区分",
      placement: "top"
    }
  }, [_c("span", [_vm._v("✏️")])])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;