"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.$t("management.提示"),
      visible: _vm.disableMemberDialogVisible,
      "before-close": _vm.closeDialog,
      width: "490px"
    }
  }, [_c("div", [_vm._v(" " + _vm._s(_vm.$t("management.是否禁用{0}账号？", [_vm.rowInfo.name || ""])) + " ")]), _c("section", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "content-item"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.该账号将无法登录Megaview访问本企业")) + " ")]), _c("div", {
    staticClass: "content-item"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.系统会保留该员工的所有数据")) + " ")]), _c("div", {
    staticClass: "content-item"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.禁用期间，组织内员工依然可以查看该员工数据")) + " ")]), _c("div", {
    staticClass: "content-item"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.禁用账号可以启用恢复")) + " ")])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.disableLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("management.禁用")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;