"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    ref: "timeRangeSelect",
    staticClass: "time-range-select",
    attrs: {
      placeholder: "请选择会话时长",
      clearable: "",
      "popper-class": "conv-time-range-select-popover"
    },
    on: {
      change: _setup.handleDateChange,
      "visible-change": _setup.handleVisibleChange
    },
    model: {
      value: _setup.currentSelect,
      callback: function ($$v) {
        _setup.currentSelect = $$v;
      },
      expression: "currentSelect"
    }
  }, [_c("el-option-group", {
    staticClass: "time-option"
  }, _vm._l(_setup.options, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.label,
        value: item.label
      }
    });
  }), 1), _c("el-option-group", {
    staticClass: "custom-time"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("自定义")]), _c(_setup.TimeRange, {
    attrs: {
      "time-range": _setup.customTimeRange,
      "max-value": -1
    },
    on: {
      timeChange: _setup.customTimeChange,
      timeLabelChange: _setup.timeLabelChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;