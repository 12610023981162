"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'AppMain',
  provide: {
    isEmbed: false,
    getEmbedPath: null
  },
  data() {
    return {
      include: [],
      hasNav: false
    };
  },
  computed: {
    key() {
      return `/${this.$route.fullPath.split('/')[1]}`;
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        // this.appFlag = to.meta.type == "detail";
        this.hasNav = Boolean(to.meta.has_nav);
        if (to.meta.keepAlive) {
          !this.include.includes(to.name) && this.include.push(to.name);
          const arr = to.matched.filter(item => {
            return item.name !== 'layout' && item.meta.unify !== false;
          });
          if (arr && arr.length === 1) {
            this.$nextTick(() => {
              const getList = arr[0].instances.default.getList;
              getList();
            });
          }
        }
        if (from && from.meta.keepAlive && (to.meta.deepth === from.meta.deepth || !to.meta.deepth)) {
          const index = this.include.indexOf(from.name);
          index !== -1 && this.include.splice(index, 1);
        }
        if (to && from && to.meta.family !== from.meta.family && to.meta.deepth !== from.meta.deepth && from.meta.family && from.meta.deepth) {
          const index = this.include.indexOf(to.name);
          index !== -1 && this.include.splice(index, 1);
        }
      },
      deep: true,
      immediate: true
    }
  }
};
exports.default = _default;