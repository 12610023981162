"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-rule-card",
    on: {
      click: _vm.editScore
    }
  }, [_c("i", {
    staticClass: "iconfont icon-scoring-rules-video"
  }), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "card-head"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("div", {
    staticClass: "score-name"
  }, [_vm._v(_vm._s(_vm.info.name))]), _vm.info.is_on && _vm.linkText ? _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("goPage");
      }
    }
  }, [_vm._v(_vm._s(_vm.linkText)), _c("i", {
    staticClass: "el-icon-top-right"
  })]) : _vm._e()], 1), _c("div", {
    staticClass: "right-operate",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("el-switch", {
    attrs: {
      value: _vm.info.is_on,
      "active-color": _vm.variables.active,
      "inactive-color": _vm.variables.inactive,
      "active-value": true,
      "inactive-value": false
    },
    on: {
      change: function ($event) {
        return _vm.changeStatus($event, _vm.info);
      }
    }
  }), _c("mg-operate-column", {
    attrs: {
      list: _vm.getOperateList,
      "show-operate-count": 2,
      "show-icon": "",
      placement: "bottom-start"
    },
    on: {
      command: _vm.handleCommand
    }
  })], 1)]), _c("div", {
    staticClass: "describe"
  }, [_vm._v(" " + _vm._s(_vm.info.description) + " ")]), _c("div", {
    staticClass: "card-footer"
  }, [_c("image-avatar", {
    attrs: {
      name: _vm.info.update_by && _vm.info.update_by.name,
      avatar: _vm.info.update_by && _vm.info.update_by.avatar
    }
  }), _c("span", {
    staticClass: "name footer-text"
  }, [_vm._v(_vm._s(_vm.info.update_by && _vm.info.update_by.name))]), _c("span", {
    staticClass: "update-at footer-text"
  }, [_vm._v("更新于 " + _vm._s(_vm.getTimeText(_vm.info.update_at)))])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;