"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "org-invitation"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.$t("management.添加新成员"),
      visible: _vm.visible,
      "before-close": _vm.closeSelf,
      width: "600px"
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.invitePerson("invitePersonForm");
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_vm.isTwoFactor ? _c("div", {
    staticClass: "two-factor-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("p", [_vm._v(" " + _vm._s(_vm.$t("management.账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。")) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "tel-input-content"
  }, [_c("el-form", {
    ref: "invitePersonForm",
    staticClass: "invite-form",
    attrs: {
      model: _vm.invitePersonForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      rules: _vm.rules.name
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.姓名")) + " "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-input", {
    ref: "name",
    attrs: {
      type: "name",
      placeholder: _vm.$t("management.请输入姓名")
    },
    model: {
      value: _vm.invitePersonForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "name", $$v);
      },
      expression: "invitePersonForm.name"
    }
  })], 1), _vm.isTwoFactor ? _c("el-form-item", {
    attrs: {
      prop: "account",
      rules: _vm.rules.account
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.登录账号")) + " "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-input", {
    ref: "account",
    attrs: {
      maxlength: "20",
      placeholder: _vm.$t("management.请输入登录账号")
    },
    model: {
      value: _vm.invitePersonForm.account,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "account", $$v);
      },
      expression: "invitePersonForm.account"
    }
  })], 1) : _c("div", {
    staticClass: "invite-method"
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" " + _vm._s(`${_vm.$t("management.手机号")}/${_vm.$t("management.邮箱")}`) + " "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("div", {
    staticClass: "invite-method-content"
  }, [_c("el-select", {
    on: {
      change: _vm.handleChangeInviteMethod
    },
    model: {
      value: _vm.inviteMethod,
      callback: function ($$v) {
        _vm.inviteMethod = $$v;
      },
      expression: "inviteMethod"
    }
  }, _vm._l(_vm.inviteMethodOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _vm.inviteMethod === "tel" ? _c("el-form-item", {
    attrs: {
      prop: "tel",
      rules: _vm.rules.tel
    }
  }, [_c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: _vm.$t("management.请输入手机号")
    },
    model: {
      value: _vm.invitePersonForm.tel,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "tel", $$v);
      },
      expression: "invitePersonForm.tel"
    }
  })], 1) : _vm._e(), _vm.inviteMethod === "email" ? _c("el-form-item", {
    attrs: {
      prop: "email",
      rules: _vm.rules.email
    }
  }, [_c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: _vm.$t("management.请输入邮箱")
    },
    model: {
      value: _vm.invitePersonForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "email", $$v);
      },
      expression: "invitePersonForm.email"
    }
  })], 1) : _vm._e()], 1)]), _c("el-form-item", {
    attrs: {
      prop: "departmentName",
      rules: _vm.rules.departmentName
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.主部门")) + " "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-select", {
    ref: "tree-select",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "popper-class": "tree-select",
      loading: _vm.departmentLoading,
      placeholder: _vm.$t("management.请选择主部门")
    },
    model: {
      value: _vm.invitePersonForm.departmentName,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "departmentName", $$v);
      },
      expression: "invitePersonForm.departmentName"
    }
  }, [_c("el-option", {
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: _vm.departments,
      disabled: _vm.orgTree.length == 0
    }
  }, [_c("el-tree", {
    ref: "department-tree",
    staticClass: "department-radio-tree",
    staticStyle: {
      width: "100%",
      "pointer-events": "auto"
    },
    attrs: {
      data: _vm.getTree,
      "node-key": "id",
      "highlight-current": "",
      "expand-on-click-node": false,
      "check-strictly": "",
      props: _vm.defaultProps,
      "default-expanded-keys": _vm.defaultExpandedKeys
    },
    on: {
      "current-change": _vm.handleCurrentChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return _c("div", {
          staticClass: "custom-tree-node"
        }, [data.node_type === "root" ? _c("svg-icon", {
          attrs: {
            "icon-class": "office-building"
          }
        }) : _c("svg-icon", {
          attrs: {
            "icon-class": "department"
          }
        }), [_vm._v(" " + _vm._s(data.name) + " ")]], 2);
      }
    }])
  })], 1)], 1)], 1), _vm.hasWorkspace ? _c("el-form-item", {
    attrs: {
      prop: "workspaceId",
      rules: _vm.rules.workspaceId
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.主空间")) + " "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "popper-class": "workspace-selector",
      placeholder: _vm.$t("management.请选择主空间")
    },
    model: {
      value: _vm.invitePersonForm.workspaceId,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "workspaceId", $$v);
      },
      expression: "invitePersonForm.workspaceId"
    }
  }, _vm._l(_vm.workspaceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color,
        "margin-right": "4px"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1) : _vm._e(), _vm.isTwoFactor ? _c("el-form-item", {
    attrs: {
      prop: "verify_email",
      rules: _vm.rules.verify_email
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(_vm._s(_vm.$t("management.双因素验证方式-验证邮箱")))]), _c("el-input", {
    attrs: {
      placeholder: _vm.$t("management.请输入验证邮箱")
    },
    model: {
      value: _vm.invitePersonForm.verify_email,
      callback: function ($$v) {
        _vm.$set(_vm.invitePersonForm, "verify_email", $$v);
      },
      expression: "invitePersonForm.verify_email"
    }
  })], 1) : _vm._e()], 1)], 1)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeSelf
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    attrs: {
      loading: _vm.inviteBtnLoading,
      disabled: _vm.inviteBtnDisabled
    },
    on: {
      click: function ($event) {
        return _vm.invitePerson("invitePersonForm", false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.确定")) + " ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.inviteContinueBtnLoading,
      disabled: _vm.inviteContinueBtnDisabled
    },
    on: {
      click: function ($event) {
        return _vm.invitePerson("invitePersonForm", true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.确定并继续添加")) + " ")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;