"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layoutMixins = _interopRequireDefault(require("./components/layoutMixins.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDimension',
  mixins: [_layoutMixins.default],
  data() {
    return {
      convParams: {
        sort_type: 'percent_speak',
        sort_order: 'descending'
      },
      chartDesc: {
        percent_speak: {
          title: '销售说话占比',
          subTitle: '',
          suggestion_label: '建议65%以内',
          cardValue: 0,
          value: []
        },
        max_sales: {
          title: '平均最长销售独白',
          subTitle: '',
          suggestion_label: '建议2分半以下',
          cardValue: 0,
          value: []
        },
        max_customer: {
          title: '平均最长客户叙述',
          subTitle: '',
          suggestion_label: '建议1分钟以上',
          cardValue: 0,
          value: []
        },
        discuss: {
          title: '深入讨论次数',
          subTitle: '',
          suggestion_label: '建议4次以上',
          cardValue: 0,
          value: []
        },
        question: {
          title: '提问频率',
          subTitle: '',
          suggestion_label: '建议每10分钟8次以上',
          cardValue: 0,
          value: []
        }
      },
      active: 'percent_speak',
      xAxisList: []
    };
  },
  computed: {
    sortOptions() {
      const arr = [];
      for (const key in this.chartDesc) {
        arr.push({
          label: this.chartDesc[key].title,
          value: key
        });
      }
      return arr;
    }
  },
  methods: {
    handleResults() {
      this.xAxisList = this.chartData.dates ? this.chartData.dates.map(item => item.date) : [];
      for (const key in this.chartDesc) {
        this.chartDesc[key].cardValue = this.chartData[`${key}_avg`] || 0;
        this.chartDesc[key].subTitle = this.chartData.labels[key].explanation;
        this.chartDesc[key].value = this.chartData.dates ? this.chartData.dates.map(item => item[key]) : [];
      }
    }
  }
};
exports.default = _default;