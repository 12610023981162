"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "upload-progress"
  }, [_c("div", {
    staticClass: "content",
    class: {
      isMembers: _vm.isMembers
    }
  }, [_c("el-progress", {
    staticClass: "mg-upload-progress",
    attrs: {
      "text-inside": true,
      "stroke-width": 26,
      percentage: _vm.percentage
    }
  }), _c("p", {
    staticClass: "tip"
  }, [_vm._v("上传文件可能需要几分钟，取决于文件大小和网速")]), _c("p", {
    staticClass: "describe"
  }, [_vm._v("当前数据正在导入，你可以点击「最小化」隐藏该页面，数据导入不受影响")])], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("minimize");
      }
    }
  }, [_vm._v("最小化")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;