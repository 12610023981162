"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PersonalPlanHeader = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/PersonalPlanHeader"));
var _LeaderPlanHeaderFilter = _interopRequireDefault(require("../leaderPlan/LeaderPlanHeaderFilter.vue"));
var _PersonalPlanTable = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/PersonalPlanTable"));
var _PracticeKnowledgeDrawer = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/PracticeKnowledgeDrawer.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LeaderPlanDetail',
  components: {
    PersonalPlanHeader: _PersonalPlanHeader.default,
    LeaderPlanHeaderFilter: _LeaderPlanHeaderFilter.default,
    PersonalPlanTable: _PersonalPlanTable.default,
    PracticeKnowledgeDrawer: _PracticeKnowledgeDrawer.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  data() {
    return {
      taskConfig: {},
      requestParams: {
        id: 0,
        user_id: 0,
        page: 1,
        size: 10,
        order_field: '',
        order_type: '',
        is_complete: -1
      },
      totalCount: 0,
      tableData: [],
      tableLoading: false,
      tableHeight: document.documentElement.clientHeight - 200,
      taskScoreInfo: {},
      practiceDrawerVisible: false,
      acticeDrawerKnowledgeId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId'])
  },
  created() {
    this.taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query') || '{}');
    this.taskConfig.taskId = Number(this.taskConfig.taskId);
    this.taskConfig.status = Number(this.taskConfig.status);
    this.taskConfig.userInfo = JSON.parse(this.taskConfig.userInfo || '{}');
    this.requestParams.user_id = this.taskConfig.userInfo.id || this.userId;
    this.requestParams.id = this.taskConfig.taskId;
    this.getTaskScore({
      id: Number(this.requestParams.id),
      user_id: Number(this.requestParams.user_id)
    });
    this.getSalesKnowledgeReplyDetail();
  },
  methods: {
    // 我的任务已完成的，重练一遍
    goPractice() {
      const {
        course_type,
        name,
        status,
        practice_rule
      } = this.$refs['personalPlanTable'].taskInfo;
      const taskId = this.taskConfig.taskId;
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        type: course_type,
        taskId: this.taskConfig.taskId,
        knowledgeId: this.tableData[0].plan_knowledge_id,
        taskName: name,
        taskStatus: this.taskConfig.status,
        score: practice_rule === null || practice_rule === void 0 ? void 0 : practice_rule.score,
        userInfo: JSON.stringify(this.taskConfig.userInfo)
      }));
      const path = _routeMap.ROUTES.practiceKnowledge;
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          taskId,
          from: this.taskConfig.from
        }
      });
    },
    async getTaskScore(data) {
      const res = await (0, _coachingCenter.getTaskScore)(data);
      if (res.code === 20000) {
        this.taskScoreInfo = res.results.data;
      }
    },
    async getSalesKnowledgeReplyDetail() {
      try {
        this.tableLoading = true;
        if (this.requestParams.is_complete === '') {
          this.requestParams.is_complete = -1;
        }
        this.requestParams.id = Number(this.requestParams.id);
        this.requestParams.user_id = Number(this.requestParams.user_id);
        const res = await (0, _coachingCenter.getSalesKnowledgeReplyDetail)(this.requestParams);
        if (res.code === 20000) {
          this.tableData = res.results.data;
          this.totalCount = res.results.total_count;
        }
      } finally {
        this.tableLoading = false;
      }
    },
    initData() {
      this.requestParams.page = 1;
      this.tableLoading = false;
      this.totalCount = 0;
    },
    tableSizeChange(val) {
      this.requestParams.size = val;
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    },
    tableCurrentPageChange(val) {
      this.requestParams.page = val;
      this.getSalesKnowledgeReplyDetail();
    },
    filterChange(val) {
      this.requestParams = {
        ...this.requestParams,
        ...val
      };
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    },
    tableSortChange(val) {
      this.requestParams.order_field = val.prop;
      this.requestParams.order_type = val.order;
      this.initData();
      this.getSalesKnowledgeReplyDetail();
    },
    openPracticeDrawer(row) {
      this.acticeDrawerKnowledgeId = row.plan_knowledge_id;
      this.practiceDrawerVisible = true;
    }
  }
};
exports.default = _default;