"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/components/LoginProcessComp/layout"));
var _RegisterComp = _interopRequireDefault(require("@/components/LoginProcessComp/RegisterComp"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "Login",
  components: {
    LoginLayout: _layout.default,
    //布局组件
    RegisterComp: _RegisterComp.default //组件：忘记密码 & 注册
  },

  data() {
    return {};
  },
  created() {},
  methods: {}
};
exports.default = _default;