"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-popover", {
    ref: "keyEventShowConfigPopover",
    attrs: {
      "popper-class": "key-event-show-config",
      trigger: "click",
      placement: "bottom-start",
      "visible-arrow": false
    },
    on: {
      show: _setup.handlePopoverShow,
      hide: _setup.handlePopoverHide
    }
  }, [!_setup.loading ? _c("el-cascader-panel", {
    ref: "keyEventCascaderPanel",
    attrs: {
      options: _setup.keyEventOptions,
      props: {
        expandTrigger: "hover",
        children: "children",
        label: "name",
        value: "id",
        multiple: true,
        emitPath: false
      }
    },
    on: {
      change: _setup.handleChange,
      "expand-change": _setup.expandChange
    },
    model: {
      value: _setup.localKeyEventConfig,
      callback: function ($$v) {
        _setup.localKeyEventConfig = $$v;
      },
      expression: "localKeyEventConfig"
    }
  }) : _vm._e(), _c("el-button", {
    staticClass: "reset-btn",
    on: {
      click: _setup.reset
    }
  }, [_vm._v("清空")]), _c("el-button", {
    staticClass: "key-event-btn",
    attrs: {
      slot: "reference",
      icon: "iconfont icon-setting"
    },
    slot: "reference"
  }, [_vm._v(" 关键事件显示 "), _setup.count ? _c("span", [_vm._v(" " + _vm._s(_setup.count))]) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;