"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contain"
  }, [_c("div", {
    staticClass: "header"
  }, [_vm.mediaType === "video" && _vm.hasVideo ? _c("div", {
    staticClass: "switch-tab"
  }, [_c("el-button", {
    class: {
      "active-tab": _vm.tabActiveName === "transtext"
    },
    attrs: {
      icon: "iconfont icon-text"
    },
    on: {
      click: function ($event) {
        return _vm.switchTab("transtext");
      }
    }
  }, [_vm._v("文本 ")]), _c("el-button", {
    class: {
      "active-tab": _vm.tabActiveName === "video"
    },
    attrs: {
      icon: "iconfont icon-video"
    },
    on: {
      click: function ($event) {
        return _vm.switchTab("video");
      }
    }
  }, [_vm._v("视频 ")])], 1) : _vm._e(), _c("div", {
    staticClass: "search-text"
  }, [_c("el-input", {
    attrs: {
      id: "conv-search-input",
      "prefix-icon": "el-icon-search",
      placeholder: "搜索会话内容",
      clearable: ""
    },
    on: {
      focus: function ($event) {
        _vm.isShowResult = true;
      },
      input: function ($event) {
        return _vm.goTransText(_vm.inputWords);
      }
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.goTransText(_vm.inputWords);
      }
    },
    model: {
      value: _vm.inputWords,
      callback: function ($$v) {
        _vm.inputWords = $$v;
      },
      expression: "inputWords"
    }
  }), _c("search-result", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputWords && _vm.isShowResult,
      expression: "inputWords && isShowResult"
    }],
    ref: "searchResult",
    staticClass: "search-result-content",
    attrs: {
      id: "conv-search-result",
      "conversation-type": _vm.mediaType,
      words: _vm.searchWords,
      "result-list": _vm.resultList,
      "parti-info": _vm.partiInfo
    },
    on: {
      goKeyDetail: _vm.goKeyDetail
    }
  })], 1), !_vm.hideFixOperates ? _c("div", {
    staticClass: "buttons"
  }, [_c("div", {
    staticClass: "show-event-tag-btn"
  }, [_c("span", [_vm._v("关键事件")]), _c("el-switch", {
    attrs: {
      value: _vm.isShowEventTag
    },
    on: {
      change: _vm.handleShowEventTag
    }
  })], 1), _c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      icon: "iconfont icon-download"
    }
  }), _c("el-dropdown-menu", [_c("el-dropdown-item", {
    attrs: {
      command: "exportTransText",
      icon: "iconfont icon-document"
    }
  }, [_vm._v("导出文本 ")]), _vm.mediaType !== "doc" ? _c("el-dropdown-item", {
    attrs: {
      command: "downloadVideo",
      icon: "iconfont icon-Mediafiles"
    }
  }, [_vm._v("下载音视频 ")]) : _vm._e()], 1)], 1), _vm.hasCorrectTextPermission && !_vm.conversationDetailInfo.is_group ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      content: "翻转角色"
    }
  }, [_c("el-button", {
    attrs: {
      "data-v-step": "flipRoleBtn",
      icon: "iconfont icon-switch-roles"
    },
    on: {
      click: _vm.flipRole
    }
  })], 1) : _vm._e(), _c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      icon: "iconfont icon-more"
    }
  }), _c("el-dropdown-menu", [_c("el-dropdown-item", {
    attrs: {
      command: "correctKeyEventHistory",
      icon: "iconfont icon-key-event-history"
    }
  }, [_vm._v("关键事件修正历史 ")]), _vm.mediaType !== "doc" ? _c("el-dropdown-item", {
    attrs: {
      command: "correctTextHistory",
      icon: "iconfont icon-text-history"
    }
  }, [_vm._v("转录文字修正历史 ")]) : _vm._e(), !_vm.conversationDetailInfo.is_group ? _c("el-dropdown-item", {
    attrs: {
      command: "correctFlipRoleHistory",
      icon: "iconfont icon-switch-roles"
    }
  }, [_vm._v("翻转角色修正历史 ")]) : _vm._e()], 1)], 1)], 1) : _vm._e()]), _c("div", {
    staticClass: "main-content"
  }, [_c("trans-text", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabActiveName === "transtext",
      expression: "tabActiveName === 'transtext'"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.transTextLoading,
      expression: "transTextLoading"
    }],
    ref: "transtext",
    staticClass: "trans-text",
    attrs: {
      "is-snippet-status": _vm.isSnippetStatus,
      words: _vm.searchWords,
      "result-list": _vm.resultList,
      origin: _vm.origin,
      "active-round-id": _vm.convId,
      "upload-type": _vm.uploadType,
      "has-role": _vm.hasRole,
      "media-type": _vm.mediaType,
      "right-bar-active": _vm.rightBarActive,
      "can-edit-key-event": _vm.canEditKeyEvent,
      "conv-in-work-space-id": _vm.convInWorkSpaceId
    },
    on: {
      correctTextSuccess: function ($event) {
        return _vm.$emit("correctTextSuccess");
      },
      handleComment: function ($event) {
        return _vm.$emit("handleComment");
      }
    }
  }), _c("video-player", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabActiveName === "video",
      expression: "tabActiveName === 'video'"
    }],
    ref: "myVideo",
    staticClass: "video-wrapper",
    attrs: {
      url: _vm.videoUrl,
      "media-type": _vm.mediaType,
      "tab-active-name": _vm.tabActiveName
    },
    on: {
      videoError: _vm.videoError,
      changeSliderTime: _vm.changeSliderTime
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;