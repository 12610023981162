"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'BatchSearchDialog',
  inject: {
    batchSearchDialogAppendToBody: {
      form: 'batchSearchDialogAppendToBody',
      default: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    batchSearchArr: {
      type: Array,
      default: () => []
    },
    isBatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      batchSearchValue: ''
    };
  },
  computed: {
    currentCount() {
      if (this.batchSearchValue.trim()) {
        return this.batchSearchValue.split('\n').length;
      }
      return 0;
    }
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val & this.isBatch) {
          this.batchSearchValue = this.batchSearchArr.join('\n');
        } else {
          this.batchSearchValue = '';
        }
      }
    },
    batchSearchValue(val) {
      if (!val.trim()) return;
      let arr = val.split('\n');
      if (arr.length > 1000) {
        arr = arr.slice(0, 1000);
      }
      arr = arr.map(item => {
        if (item.length > 50) {
          return item.slice(0, 50);
        }
        return item;
      });
      this.batchSearchValue = arr.join('\n');
    }
  },
  mounted() {},
  methods: {
    search() {
      if (!(!this.isBatch && !this.batchSearchValue.trim())) {
        const value = [];
        if (this.batchSearchValue.trim()) {
          const batchSearchArr = this.batchSearchValue.split('\n');
          batchSearchArr.forEach(item => {
            if (item.trim()) {
              value.push(item.trim());
            }
          });
        }
        this.$emit('search', [...new Set(value)]);
      }
      this.closeDialog();
    },
    clear() {
      this.batchSearchValue = '';
    },
    closeDialog() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;