"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDoubleCallPermission = getDoubleCallPermission;
exports.postDoubleCall = postDoubleCall;
var _request = require("@/utils/request");
function postDoubleCall(conversation_id, tel) {
  return (0, _request.request)({
    url: '/micro_record/double_call/double/call',
    method: 'post',
    data: {
      conversation_id,
      tel
    }
  });
}
function getDoubleCallPermission(params) {
  return (0, _request.request)({
    url: '/micro_record/double_call/double/call/permission',
    method: 'get',
    params
  });
}