"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-summary"
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera-solid-2"
  }), _c("span", [_vm.count || _vm.loading ? _c("p", {
    staticClass: "conversations",
    on: {
      click: _vm.countClick
    }
  }, [_vm._v(" 共"), _c("span", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.count))]), _vm._v("条符合以上筛选条件的" + _vm._s(_vm.type === "trade" ? "交易" : "会话") + "，将进行评分； ")]) : _c("p", [_vm._v(" 当前没有符合以上筛选条件的" + _vm._s(_vm.type === "trade" ? "交易" : "会话") + "； ")]), _c("p", [_vm._v(" 新" + _vm._s(_vm.type === "trade" ? "导入的交易" : "上传或导入的会话") + "，符合以上筛选条件的，也将自动评分 ")])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;