"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _CourseCategoryTreeSelect = _interopRequireDefault(require("./CourseCategoryTreeSelect.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MoveCourseDialog',
  components: {
    CourseCategoryTreeSelect: _CourseCategoryTreeSelect.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    courseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      submitLoading: false,
      position: null
    };
  },
  watch: {
    visible(val) {
      this.$nextTick(() => {
        val && this.$refs['course-category-tree-select'].getCourseCategoryData();
      });
    }
  },
  methods: {
    async submit() {
      var _this$position;
      if (!this.position || this.position === this.courseInfo) {
        this.handleClose();
        this.clearData();
        return;
      }
      const res = await (0, _coachingCenter.moveCourse)({
        id: this.courseInfo.id,
        category_id: (_this$position = this.position) === null || _this$position === void 0 ? void 0 : _this$position.id
      });
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.successfullyMoved'));
        this.$emit('moveSuccess', this.position);
        this.handleClose();
        this.clearData();
      }
    },
    handlePositionSelect(val) {
      this.position = val;
    },
    handleClose() {
      this.clearData();
      this.$emit('update:visible', false);
    },
    clearData() {
      this.position = null;
    }
  }
};
exports.default = _default;