"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDateRangeHandler = useDateRangeHandler;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function useDateRangeHandler() {
  const getDateRange = shortcuts => {
    const shortcutsList = [{
      text: '今天',
      onClick(picker) {
        picker.$emit('pick', [new Date((0, _moment.default)().startOf('day')), new Date((0, _moment.default)().endOf('day'))]);
      }
    }, {
      text: '昨天',
      onClick(picker) {
        const yesterday = (0, _moment.default)().subtract(1, 'day');
        picker.$emit('pick', [new Date(yesterday.startOf('day')), new Date(yesterday.endOf('day'))]);
      }
    }, {
      text: '近7天',
      onClick(picker) {
        const end = new Date();
        const start = new Date((0, _moment.default)().subtract(6, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '过去7天',
      onClick(picker) {
        const end = new Date((0, _moment.default)().subtract(1, 'day').endOf('day'));
        const start = new Date((0, _moment.default)().subtract(7, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '近30天',
      onClick(picker) {
        const end = new Date();
        const start = new Date((0, _moment.default)().subtract(29, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '过去30天',
      onClick(picker) {
        const end = new Date((0, _moment.default)().subtract(1, 'day').endOf('day'));
        const start = new Date((0, _moment.default)().subtract(30, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '近3个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date((0, _moment.default)().subtract(89, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '过去3个月',
      onClick(picker) {
        const end = new Date((0, _moment.default)().subtract(1, 'day').endOf('day'));
        const start = new Date((0, _moment.default)().subtract(90, 'day').startOf('day'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '上个月',
      onClick(picker) {
        const end = new Date((0, _moment.default)().subtract(1, 'month').endOf('month'));
        const start = new Date((0, _moment.default)().subtract(1, 'month').startOf('month'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '上周',
      onClick(picker) {
        const end = new Date((0, _moment.default)().subtract(1, 'week').endOf('week'));
        const start = new Date((0, _moment.default)().subtract(1, 'week').startOf('week'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '本月',
      onClick(picker) {
        const end = new Date((0, _moment.default)().endOf('month'));
        const start = new Date((0, _moment.default)().startOf('month'));
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '本周',
      onClick(picker) {
        const end = new Date((0, _moment.default)().endOf('week'));
        const start = new Date((0, _moment.default)().startOf('week'));
        picker.$emit('pick', [start, end]);
      }
    }];
    if (Array.isArray(shortcuts)) {
      if (!shortcuts.length) return null;
      return {
        shortcuts: shortcuts.map(text => shortcutsList.find(item => item.text === text)).filter(Boolean)
      };
    }
    return {
      shortcuts: shortcutsList
    };
  };
  return {
    getDateRange
  };
}