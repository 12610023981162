"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'IconColorSelect',
  props: {
    predefineColors: {
      type: Array,
      default: () => []
    },
    iconColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localIconColor: ''
    };
  },
  watch: {
    iconColor: {
      handler: function (val) {
        this.localIconColor = val;
      },
      immediate: true
    },
    localIconColor(val) {
      this.$emit('select', val);
    }
  },
  methods: {
    selectColor(item) {
      this.localIconColor = item;
    }
  }
};
exports.default = _default;