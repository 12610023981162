"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    ref: "department-member-tree",
    attrs: {
      lazy: "",
      load: _vm.loadTreeData,
      "node-key": "id",
      "show-checkbox": "",
      props: _vm.defaultProps,
      "check-on-click-node": "",
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "default-checked-keys": _vm.defaultChecked
    },
    on: {
      check: _vm.handleCheckChange,
      "check-change": _vm.handleSelfCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node"
        }, [_vm.isDepartment(data.id) ? _c("svg-icon", {
          attrs: {
            "icon-class": data.is_root ? "office-building" : "department"
          }
        }) : _c("image-avatar", {
          attrs: {
            size: "small",
            avatar: data.avatar,
            name: data.name
          }
        }), _vm._v(" " + _vm._s(data.name) + " ")], 1)];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;