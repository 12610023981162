"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatUnit = _interopRequireDefault(require("@/components/ChatUnit"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AIAssistantChatDrawer',
  components: {
    ChatUnit: _ChatUnit.default
  },
  data() {
    return {
      isDrawerFold: false
    };
  },
  methods: {
    toggleFold() {
      this.isDrawerFold = !this.isDrawerFold;
    }
  }
};
exports.default = _default;