"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "note-editor"
  }, [_c("div", {
    staticClass: "editorjs",
    class: {
      "read-only": _vm.readOnly
    },
    attrs: {
      id: _vm.editorId
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;