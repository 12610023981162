"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCombineTaskList = formatCombineTaskList;
function formatCombineTaskList(list) {
  return list.map(item => {
    var _item$children;
    if (((_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) === 1) {
      const {
        name,
        ...restChildren
      } = item.children[0];
      const {
        children,
        ...restItem
      } = item;
      return {
        pid: item.id,
        ...restItem,
        ...restChildren
      };
    } else {
      return {
        pid: item.id,
        ...item
      };
    }
  });
}