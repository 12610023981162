"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _library = require("@/api/library");
var _OptionTemplate = _interopRequireDefault(require("@/views/convDetail/components/OptionTemplate.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetsLibrarySearch',
  components: {
    OptionTemplate: _OptionTemplate.default
  },
  data() {
    return {
      options: [],
      selectedVal: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  methods: {
    handleVisibleChange(val) {
      if (val && !this.selectedVal && this.options.length) {
        this.options = [];
      }
    },
    handleSearch: (0, _commonFunc.debounce)(async function (name) {
      if (!name) {
        this.options = [];
        this.selectedVal = '';
        return;
      }
      const res = await (0, _library.snippetsLibrarySearch)({
        name,
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.options = this.formatData(res.results.data);
      }
    }, 200),
    handleChange(val) {
      if (!val) return;
      const data = this.options.find(i => i.id === val);
      this.$emit('search', data);
    },
    formatData(list) {
      if (!list.length) return [];
      const iconMap = {
        folder: 'icon-folder',
        smart: 'icon-filter',
        snippet: 'icon-film',
        media: 'icon-Mediafiles'
      };
      return list.map(i => ({
        ...i,
        icon: iconMap[i.type]
      }));
    }
  }
};
exports.default = _default;