"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "edit-template-container",
    attrs: {
      title: _vm.$t("coaching.saveAsTemplate"),
      visible: _vm.dialogVisible,
      "before-close": _vm.handleClose,
      width: "400px"
    }
  }, [_c("el-form", {
    ref: "form",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.config,
      "label-width": "100px",
      "label-position": "top"
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("coaching.templateName"),
      prop: "name",
      rules: [{
        required: true,
        message: _vm.$t("coaching.templateNameTip"),
        trigger: "blur"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("coaching.inputMaxLength", [50]),
      maxlength: 50,
      autofocus: ""
    },
    model: {
      value: _vm.config.name,
      callback: function ($$v) {
        _vm.$set(_vm.config, "name", $$v);
      },
      expression: "config.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("coaching.templateRemarks")
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 8
      },
      resize: "none",
      placeholder: _vm.$t("coaching.inputMaxLength", [100]),
      maxlength: 100,
      "show-word-limit": ""
    },
    model: {
      value: _vm.config.description,
      callback: function ($$v) {
        _vm.$set(_vm.config, "description", $$v);
      },
      expression: "config.description"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.save")))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;