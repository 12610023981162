"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _index = require("@/utils/index");
var _routeMap = require("@/router/routeMap");
var _Message = _interopRequireDefault(require("@/components/Message"));
var _ConstMap = require("@/components/Message/components/ConstMap.js");
var _download = require("@/utils/download");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MessageCenterButton',
  components: {
    MessageCenter: _Message.default
  },
  data() {
    return {
      messageKey: +new Date(),
      messageDrawer: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['messageUnreadCount', 'webSocketMsg'])
  },
  watch: {
    webSocketMsg: {
      handler: function (newVal) {
        const notification_type = newVal.type;
        [1, 2, 3, 4, 5, 6].forEach(item => {
          (0, _index.msgDispatcher)(newVal, item);
        });
        if (typeof notification_type === 'number') {
          // 消息类型为数字类型时，代表是消息中心的推送消息。如果是消息内部的即时消息则在消息中心抽屉关闭情况下再弹，高级别的消息无需关心消息中心抽屉是否关闭
          if ((newVal.high_level || !newVal.high_level && this.messageDrawer === false) && newVal.duration) {
            // 推送消息的duration有值才代表需要弹框提示
            this.createNotificationTemplate(newVal);
          }
        }
        if (notification_type === 'notify_export_task') {
          const data = newVal.data;
          this.createExportNotification(data);
        }
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch('user/getMessageUnreadCount');
  },
  mounted() {
    this.$bus.$on('closeMessageCenter', () => {
      this.closeDrawer();
    });
  },
  beforeDestroy() {
    this.$bus.$off('closeMessageCenter');
  },
  methods: {
    openMessageDrawer() {
      this.messageKey = +new Date();
      this.messageDrawer = true;
    },
    closeDrawer() {
      this.messageDrawer = false;
    },
    createNotificationTemplate(data) {
      // 保证新弹窗弹出时，旧弹窗关闭, 20230130新需求要求信息排列呈现
      // if (this.notifyInstance) {
      //   this.notifyInstance.close();
      // }
      this.createNotify(data);
    },
    createNotify(data) {
      const _this = this;
      const h = this.$createElement;
      const {
        icon,
        duration,
        extra_info,
        type
      } = data;
      let {
        title,
        content,
        sender
      } = data;
      if (type === 4) {
        // 任务类型的需要处理国际化
        title = this.$t(`coaching.${title}`);
        const contentConstantText = ['给我分配了一个任务', '完成了任务', '完成了任务，整个任务已完成', '删除了任务'];
        if (contentConstantText.includes(content)) {
          content = this.$t(`coaching.${content}`);
        }
        if (sender.includes('截止，请及时完成')) {
          sender = this.$t(`coaching.${sender}`);
        }
        const senderConstantText = '提醒你尽快完成任务';
        if (sender.includes(senderConstantText)) {
          const userName = sender.split(senderConstantText)[0];
          sender = `${userName} ${this.$t(`coaching.${senderConstantText}`)} `;
        }
      }
      this.notifyInstance = this.$notify({
        iconClass: icon + ' iconfont icon-' + _ConstMap.ICONMAP[icon] || 'bell',
        title: title,
        customClass: 'message-notify',
        offset: 40,
        message: h('div', {
          class: 'notify'
        }, [h('span', {
          class: 'notify-name'
        }, sender), h('span', {
          class: 'notify-content'
        }, content)]),
        duration: duration,
        onClick() {
          if (extra_info.router_name) {
            switch (extra_info.router_name) {
              case 'taskCenter':
                _this.goTaskDetail(extra_info);
                break;
              default:
                _this.goDetail(extra_info);
                break;
            }
          } else {
            _this.openMessageDrawer();
          }
          this.close();
        }
      });
    },
    goTaskDetail(routerInfo) {
      const taskInfo = {
        id: routerInfo.router_params.id,
        task_type: routerInfo.router_params.task_type
      };
      this.$bus.$emit('openTaskCenter', taskInfo);
    },
    goDetail(extra_info) {
      const path = _routeMap.ROUTES[extra_info.router_name];
      const query = Object.assign({
        from: 'message_notify'
      }, extra_info.router_params);
      const routeData = this.$router.resolve({
        path,
        query
      });
      window.open(routeData.href, '_blank');
    },
    createExportNotification(data) {
      if (this.$notify) {
        this.$notify.closeAll();
      }
      const h = this.$createElement;
      const name = data.name;
      const href = data.doc_url;
      const isSuccess = data.status === 'finished';
      const text = isSuccess ? ' 已经准备完成。' : ' 导出失败，请重新导出。';
      this.notifyInstance = this.$notify({
        title: isSuccess ? this.$t('coaching.exportSuccessful') : '导出失败',
        message: h('div', {
          class: 'export-notify'
        }, [h('div', {
          class: 'export-notify-content'
        }, name + text), h('a', {
          class: 'export-notify-view-records',
          on: {
            click: this.goExportRecords
          }
        }, '查看导出记录'), h('a', {
          class: {
            'export-notify-download': true,
            'download-display-none': !isSuccess
          },
          on: {
            click: this.downloadFile.bind(this, href)
          }
        }, '下载')]),
        type: isSuccess ? 'success' : 'error',
        duration: 0,
        customClass: 'overall-export-file-notify'
      });
    },
    downloadFile: (0, _commonFunc.debounce)(function (url) {
      (0, _download.downloadFile)(url);
    }, 500, true),
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    },
    closeNotify() {
      const notifyInstance = this.notifyInstance;
      notifyInstance.close();
    }
  }
};
exports.default = _default;