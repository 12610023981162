"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _routeMap = require("@/router/routeMap");
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SuggestionSelect',
  components: {
    BatchSearchInput: _BatchSearchInput.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Array, String, Object]
    }
  },
  data() {
    return {
      loading: false,
      localValue: '',
      options: [],
      firstOptionList: []
    };
  },
  computed: {
    canBatchSearch() {
      const whiteRouteArr = [_routeMap.ROUTES.conversation, _routeMap.ROUTES.deal, _routeMap.ROUTES.trade, _routeMap.ROUTES.biTeamAnalysis];
      return whiteRouteArr.includes(this.$route.path) && this.item.is_batch;
    },
    tooltipContent() {
      if (!Array.isArray(this.localValue)) return;
      const text = this.localValue.join('，');
      if (text.length > 100) {
        return text.slice(0, 100) + '...';
      } else {
        return text;
      }
    },
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    value: {
      handler: function (val) {
        if (this.canBatchSearch && Array.isArray(val)) {
          this.localValue = {
            value: val,
            is_batch: true
          };
        } else if (!this.canBatchSearch && Object.prototype.toString.call(val) === '[object Object]') {
          this.localValue = val.value;
        } else {
          this.localValue = val;
        }
      },
      immediate: true
    },
    localValue: {
      handler: function (val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) return;
        if (this.canBatchSearch && Object.prototype.toString.call(val) === '[object Object]') {
          this.$emit('change', {
            [this.item.field_name]: val.value.length ? val : undefined
          });
        }
      }
    },
    item: {
      handler: function () {
        if (this.canBatchSearch && !this.value) {
          this.localValue = {
            value: [],
            is_batch: false
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    tooltipIsDisabled(val) {
      return !(val !== null && val !== void 0 && val.length);
    },
    handleFocus() {
      this.getFuzzySearchList();
    },
    async getFuzzySearchList(val, cb) {
      this.loading = true;
      let type = '';
      if (this.item.source === 'conversation') {
        type = 'conversation';
      } else {
        type = this.$route.path === _routeMap.ROUTES.trade || this.$route.path === _routeMap.ROUTES.conversation ? 'trade' : 'deal';
      }
      const data = this.item.source === 'conversation' ? {
        source: this.$route.path === _routeMap.ROUTES.conversation ? 'conversation' : '',
        type,
        name: this.item.field_name,
        filter: val,
        workspace_id: this.workspaceInfo.id
      } : {
        source: this.$route.path === _routeMap.ROUTES.conversation ? 'conversation' : '',
        type,
        name: this.item.field_name,
        filter: val
      };
      const res = await (0, _business.getFuzzySearchList)(data);
      if (this.canBatchSearch) {
        cb(res.results.map(value => {
          return {
            value
          };
        }));
      } else {
        if (res.code === 200) {
          if (!val) {
            this.firstOptionList = res.results;
          }
          this.options = res.results;
        }
      }
      this.loading = false;
    },
    async remoteMethod(val) {
      if (!val) {
        if (!this.localValue.length) {
          this.options = this.firstOptionList;
        }
        return;
      }
      this.getFuzzySearchList(val);
    },
    handleChange(val) {
      if (!this.localValue.length) {
        this.options = this.firstOptionList;
      }
      this.$emit('change', {
        [this.item.field_name]: val
      });
    },
    handleClear() {
      this.localValue = {
        value: [],
        is_batch: false
      };
    }
  }
};
exports.default = _default;