"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.drawerVisible,
      size: "600px",
      "before-close": _setup.handleClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "drawer-title"
        }, [!_vm.isBatch ? _c("span", [_vm._v("设置"), _setup.eventName ? _c("span", [_vm._v(_vm._s(`“${_setup.eventName}”`))]) : _vm._e(), _vm._v("应用范围")]) : _c("span", [_vm._v("批量设置应用范围")]), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "哪些会话应用此关键事件？",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "drawer-body"
  }, [_c("div", {
    staticClass: "drawer-content"
  }, [_c("div", {
    staticClass: "content-item"
  }, [_c("div", {
    staticClass: "content-item-title"
  }, [_vm._v("会话时间")]), _c(_setup.ScoreRuleDateFilter, {
    attrs: {
      "date-filter": _setup.scope.date_new
    },
    on: {
      change: _setup.dateFilterChange
    }
  })], 1), _c("div", {
    staticClass: "content-item"
  }, [_c("div", {
    staticClass: "content-item-title"
  }, [_vm._v("部门")]), _c("el-radio-group", {
    on: {
      input: _setup.handleOrgTypeChange
    },
    model: {
      value: _setup.scope.orgType,
      callback: function ($$v) {
        _vm.$set(_setup.scope, "orgType", $$v);
      },
      expression: "scope.orgType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "limit"
    }
  }, [_vm._v("特定部门")]), _c("el-radio", {
    attrs: {
      label: "unlimit"
    }
  }, [_vm._v("不限部门")])], 1), _setup.scope.orgType === "limit" && _setup.isShowOrgError ? _c("div", {
    staticClass: "org-error-text"
  }, [_vm._v(" 请选择部门 ")]) : _vm._e(), _setup.scope.orgType === "limit" && _setup.scope.selectedDepartment.length ? _c("div", {
    staticClass: "selected-department"
  }, _vm._l(_setup.scope.selectedDepartment, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "department-item"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0) : _vm._e(), _setup.scope.orgType === "limit" ? _c("el-button", {
    staticClass: "edit-department-btn",
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: _setup.openEditDepartmentDialog
    }
  }, [_vm._v("编辑")]) : _vm._e()], 1), _c("div", {
    staticClass: "content-item"
  }, [_c("div", {
    staticClass: "content-item-title"
  }, [_vm._v("会话类型")]), _c("el-radio-group", {
    on: {
      input: _setup.handleConvTypeChange
    },
    model: {
      value: _setup.scope.conversationType,
      callback: function ($$v) {
        _vm.$set(_setup.scope, "conversationType", $$v);
      },
      expression: "scope.conversationType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "limit"
    }
  }, [_vm._v("特定类型")]), _c("el-radio", {
    attrs: {
      label: "unlimit"
    }
  }, [_vm._v("不限类型")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.scope.conversationType === "limit",
      expression: "scope.conversationType === 'limit'"
    }],
    staticClass: "conv-type"
  }, _vm._l(_setup.conversationTypeList, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "conv-type-item",
      class: {
        "select-activated": _setup.scope.conversation_types.includes(item.type)
      },
      on: {
        click: function ($event) {
          return _setup.selectConversationType(item.type);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont",
      class: item.icon
    }), _vm._v(_vm._s(item.label) + " ")]);
  }), 0)], 1), _c("div", {
    staticClass: "content-item"
  }, [_c("div", {
    staticClass: "content-item-title"
  }, [_vm._v("范围结果")]), _c("div", {
    staticClass: "scope-results",
    on: {
      click: _setup.openConvDrawer
    }
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera-solid-2"
  }), _c("div", {
    staticClass: "scope-results-content"
  }, [_c("div", {
    staticClass: "scope-results-item"
  }, [_c("p", {
    staticClass: "point"
  }), _setup.convCount < 5000 ? _c("span", [_vm._v("共"), _c("span", {
    staticClass: "conversation-count"
  }, [_vm._v(_vm._s(_setup.convCount))]), _vm._v(" 条符合以上筛选条件的会话，“确定并应用已有会话”后，这部分会话将应用此事件重跑；")]) : _vm._e(), _setup.convCount >= 5000 ? _c("span", [_vm._v("共"), _c("span", {
    staticClass: "conversation-count"
  }, [_vm._v(_vm._s(_setup.convCount))]), _vm._v(" 条符合以上筛选条件的会话，因会话数量较大，“确定并应用已有会话”后，"), _c("span", {
    staticClass: "warning-text"
  }, [_vm._v("只有其中的5000条")]), _vm._v("会话将应用此事件重跑；若需要应用大批量会话请联系Megaview人员")]) : _vm._e()]), _c("div", {
    staticClass: "scope-results-item"
  }, [_c("p", {
    staticClass: "point"
  }), _c("span", [_vm._v("新上传或导入的会话，符合以上筛选条件的，将自动应用此事件")])]), _vm.isBatch || _vm.isSystemEvent ? _c("div", {
    staticClass: "scope-results-item"
  }, [_c("p", {
    staticClass: "point"
  }), _c("span", [_vm._v("系统关键事件暂不支持应用已有会话重跑")])]) : _vm._e()])])])]), _c("footer", [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _setup.handleSaveScopeAndRedo(false);
      }
    }
  }, [_vm._v("确定")]), !_vm.isSystemEvent ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _setup.handleSaveScopeAndRedo(true);
      }
    }
  }, [_vm._v("确定并应用已有会话")]) : _vm._e()], 1), _c(_setup.EditDepartmentDialog, {
    attrs: {
      visible: _setup.editDepartmentDialogVisible,
      "remote-method": _setup.getConversationOrgList,
      "default-checked": _setup.scope.organization_tree_ids
    },
    on: {
      "update:visible": function ($event) {
        _setup.editDepartmentDialogVisible = $event;
      },
      submit: _setup.handleChangeDepartment
    }
  }), _c(_setup.ConvListDrawer, {
    attrs: {
      visible: _setup.convListDrawerVisible,
      list: _setup.conversationList,
      total: _setup.conversationTotal,
      "list-loading": _setup.listLoading,
      title: `符合筛选条件的会话${_setup.conversationTotal}条`
    },
    on: {
      "update:visible": function ($event) {
        _setup.convListDrawerVisible = $event;
      }
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;