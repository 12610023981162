"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "batch-search-input-content"
  }, [!_vm.isBatch ? _c("el-autocomplete", {
    ref: "dealNameInput",
    staticClass: "deal-name",
    attrs: {
      value: _vm.value && _vm.value.value ? _vm.value.value[0] : "",
      placeholder: _vm.placeholder,
      "fetch-suggestions": (filter, cb) => _vm.$emit("getFuzzySearchList", {
        filter,
        cb
      }),
      "trigger-on-focus": false,
      clearable: "",
      maxlength: 50
    },
    on: {
      input: _vm.handleInput,
      select: function ($event) {
        return _vm.$emit("input", {
          value: [$event.value],
          is_batch: true
        });
      },
      clear: function ($event) {
        return _vm.$emit("handleClear");
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.switchSearchType.apply(null, arguments);
      }
    }
  }) : _c("div", {
    staticClass: "batch-search-input",
    class: _vm.size + "-input"
  }, [_vm.batchSearchArr.length ? _c("span", {
    staticClass: "batch-search-tags"
  }, [_c("el-tag", {
    attrs: {
      closable: "",
      hit: false,
      type: "info",
      size: "small",
      "disable-transitions": ""
    },
    on: {
      close: _vm.deleteTag
    }
  }, [_c("span", {
    staticClass: "el-select__tags-text"
  }, [_vm._v(_vm._s(_vm.batchSearchArr[0]))])]), _vm.batchSearchArr.length > 1 ? _c("el-tag", {
    attrs: {
      closable: false,
      hit: false,
      type: "info",
      size: "small",
      "disable-transitions": ""
    }
  }, [_c("span", {
    staticClass: "el-select__tags-text"
  }, [_vm._v("+ " + _vm._s(_vm.batchSearchArr.length - 1))])]) : _vm._e()], 1) : _vm._e(), _c("el-input", {
    ref: "currentSearchInput",
    attrs: {
      maxlength: 50
    },
    on: {
      blur: _vm.addSearchItem
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addSearchItem.apply(null, arguments);
      }
    },
    model: {
      value: _vm.currentSearch,
      callback: function ($$v) {
        _vm.currentSearch = $$v;
      },
      expression: "currentSearch"
    }
  }), _c("i", {
    staticClass: "el-icon-circle-close",
    on: {
      click: _vm.initInput
    }
  })], 1), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "批量搜索",
      placement: "top"
    }
  }, [_c("el-button", {
    staticClass: "batch-search-button",
    class: _vm.size + "-button",
    attrs: {
      icon: "iconfont icon-search-bulk"
    },
    on: {
      click: _vm.openBatchSearchDialog
    }
  })], 1)], 1), _c("batch-search-dialog", {
    attrs: {
      visible: _vm.batchSearchDialogVisible,
      "batch-search-arr": _vm.batchSearchArr,
      "is-batch": _vm.isBatch,
      placeholder: _vm.placeholder
    },
    on: {
      "update:visible": function ($event) {
        _vm.batchSearchDialogVisible = $event;
      },
      search: _vm.search
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;