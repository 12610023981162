"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _MultipleCascaderOrderSelect = _interopRequireDefault(require("@/components/MultipleCascaderOrderSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventOrderTracker',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    MultipleCascaderOrderSelect: _MultipleCascaderOrderSelect.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    keyEvents: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 级联数据
      keyEventOptions: [],
      defaultProps: {
        expandTrigger: 'hover',
        checkStrictly: true,
        children: 'children',
        label: 'name',
        value: 'id'
      },
      filtersOrigin: {
        keyEventOrderCascaderList: [{
          inverse: false,
          keyEventName: []
        }, {
          inverse: false,
          keyEventName: []
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    isDeleteIconShow() {
      return JSON.stringify(this.filters.keyEventOrderCascaderList) !== JSON.stringify(this.filtersOrigin.keyEventOrderCascaderList);
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getAllKeyEvent();
      },
      immediate: true
    }
  },
  methods: {
    onMore() {
      let url;
      const currentRoute = this.$route.path;
      const tradeArr = ['/trade', '/trade/score-rule-edit'];
      const convArr = ['/conversation/score-rule-edit', '/conversation', '/compliance/score-rule-edit', '/compliance/score-rule-edit'];
      if (tradeArr.includes(currentRoute)) {
        url = 'https://www.megaview.com/help-center/doc?docx=PkDBdf7eRolwOixqKB1c3mwenvf&anchor=PrVfdaEgkolfKsxgpBCcjKaJnuf';
      } else if (convArr.includes(currentRoute)) {
        url = 'https://www.megaview.com/help-center/doc?docx=N90ydsVayoQ7RtxQRVbc9Rr6nUe&anchor=H9cbdrOUEo4Moox0qlkcEudlnQh';
      }
      window.open(url);
    },
    getAllKeyEvent() {
      // 获取级联数据
      (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      }).then(res => {
        this.keyEventOptions = res.results.events;
        if (this.keyEvents.length) {
          // this.getOtherPageKeyEvents();
        }
      });
    },
    getOtherPageKeyEvents() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.event_engine_relation = 'or';
      const keyEventIdList = this.getKeyEventDefaultIds([], [], this.keyEventOptions)[0];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList = [];
      this.keyEvents.forEach(item => {
        keyEventIdList.forEach(ele => {
          if (ele.slice(-1) === item) {
            // eslint-disable-next-line vue/no-mutating-props
            this.filters.keyEventOrderCascaderList.push({
              keyEventName: ele,
              inverse: false
            });
          }
        });
      });
    },
    // 递归获取关键事件id数组
    getKeyEventDefaultIds(list, prevArray, source) {
      return source.map(item => {
        const array = [...prevArray, item.id];
        item.children ? this.getKeyEventDefaultIds(list, array, item.children) : list.push(array);
        return list;
      });
    },
    // 清空关键事件选择器级联数据
    deleteKeyEventOrder() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList = JSON.parse(JSON.stringify(this.filtersOrigin.keyEventOrderCascaderList));
      this.$refs['orderSelect'].infoText = '';
      this.$refs['orderSelect'].infoTextStatus = 0;
      this.customChange([]);
    },
    customChange(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;