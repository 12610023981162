"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-drawer", {
    attrs: {
      "append-to-body": false,
      "custom-class": "data-transfer-drawer",
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleDataTranferDrawerClose,
      "wrapper-closable": false,
      size: "600px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("TransferUserInfo", {
    attrs: {
      "is-department": _vm.transferType === "department",
      "transfer-from-user-info": _vm.transferFromUserInfo
    }
  }), _c("div", {
    staticClass: "transfer-data-table"
  }, [_c("div", {
    staticClass: "transfer-type-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.请选择要转移的数据")) + " ")]), _c("el-checkbox-group", {
    staticClass: "transfer-type-checkbox",
    model: {
      value: _vm.checkedTransferType,
      callback: function ($$v) {
        _vm.checkedTransferType = $$v;
      },
      expression: "checkedTransferType"
    }
  }, _vm._l(_vm.canTransferData, function (item, index) {
    return _c("el-tooltip", {
      key: index,
      staticClass: "item",
      attrs: {
        effect: "dark",
        disabled: item.can_edit,
        placement: "top-start",
        "popper-class": "transfer-data-no-permission-tooltip"
      }
    }, [_c("div", {
      staticClass: "no-permission-tooltip-content",
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(" 你没有此" + _vm._s(_vm.transferType === "department" ? "部门" : "成员所在部门") + "的" + _vm._s(_vm.getPermissionType(item.type)) + "编辑权限，请先前往"), _c("a", {
      on: {
        click: _vm.goDataPermissionPage
      }
    }, [_vm._v("数据权限")]), _vm._v("页面配置 ")]), _c("el-checkbox", {
      attrs: {
        label: item.type,
        disabled: !item.can_edit
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "data-count"
    }, [_vm._v(_vm._s(_vm.$t("management.{0}条", [item.count])))])])], 1);
  }), 1), _vm.transferType === "department" ? _c("div", {
    staticClass: "description-text"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("management.转移部门不影响数据归属成员")))])]) : _vm._e(), _vm.transferType === "user" ? _c("div", {
    staticClass: "description-text"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("management.归属部门和空间，会同步更新为新归属人的主部门和主空间")))])]) : _vm._e(), _vm.checkedTransferType.length ? _c("div", {
    staticClass: "transfer-data"
  }, [_c("transfer-list", {
    attrs: {
      "show-table-data": _vm.showTableData,
      "is-department": _vm.transferType === "department"
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.handleDataTranferDrawerClose
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    class: {
      "resign-btn": _vm.transferType === "resign",
      "confirm-disabled-btn": _vm.confirmButtonDisabled
    },
    attrs: {
      type: "primary",
      disabled: _vm.confirmButtonDisabled
    },
    on: {
      click: _vm.handleClickConfirm
    }
  }, [_vm._v(" " + _vm._s(_vm.transferType === "resign" ? _vm.$t("management.离职") : _vm.$t("management.转移")) + " ")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;