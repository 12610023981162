"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ChatShortcuts',
  props: {
    showStopReply: {
      type: Boolean,
      default: false
    },
    showAskAgain: {
      type: Boolean,
      default: false
    },
    shortcutsList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handeClick(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;