"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router");
var _timeFormatter = require("@/utils/time-formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TaskTable',
  props: {
    tableHeads: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const operateList = [{
      label: '去学习',
      command: 'goSnippetDetail',
      isShow: true
    }];
    const handleCommand = (command, row) => {
      router.push({
        path: _routeMap.ROUTES.libraryDetail,
        query: {
          id: row.snippet_id,
          type: 'snippet'
        }
      });
    };
    const getIcon = type => {
      return {
        doc: 'iconfont icon-im-fill',
        audio: 'iconfont icon-phone-outline-fill',
        video: 'iconfont icon-video-camera-solid-2'
      }[type];
    };
    return {
      __sfc: true,
      props,
      router,
      operateList,
      handleCommand,
      getIcon,
      MgOperateColumn: _MgOperateColumn.default,
      secondToCNTime: _timeFormatter.secondToCNTime
    };
  }
};
exports.default = _default;