"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    ref: "instructionSetDrawer",
    attrs: {
      "custom-class": "instruction-set-drawer",
      visible: _vm.visible,
      size: "90%",
      "before-close": _setup.closeDialog
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticClass: "drawer-header"
  }, [_c("span", {
    staticClass: "header-title"
  }, [_vm._v("指令集")]), _c("span", {
    staticClass: "header-tip"
  }, [_vm._v("可对所选成员每天的会话纪要进行总结分析，次日凌晨更新")])])]), _c("div", {
    staticClass: "content-container"
  }, [_c("div", {
    staticClass: "content-top"
  }, [_c("mgv-button", {
    attrs: {
      icon: "icon-plus",
      "tooltip-content": _setup.getAddBtnTip(),
      disabled: _setup.tableIsEditing
    },
    on: {
      click: _setup.addInstruction
    }
  }, [_vm._v("添加指令")])], 1), _vm.visible ? _c(_setup.RowEditTable, {
    ref: "rowEditTableRef",
    attrs: {
      data: _setup.tableData,
      loading: _setup.tableLoading
    },
    on: {
      saveNewRow: _setup.saveNewRow,
      saveEditRow: _setup.saveEditRow,
      editStatusChange: _setup.editStatusChange,
      deleteRow: _setup.deleteRow
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;