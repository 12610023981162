"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _angleTag = _interopRequireDefault(require("@/components/BusinessDetail/angleTag"));
var _UpdateStageCard = _interopRequireDefault(require("./UpdateStageCard"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  dealStage: String,
  components: {
    angleTag: _angleTag.default,
    UpdateStageCard: _UpdateStageCard.default
  },
  props: {
    stage: [String, Number],
    canClick: {
      type: [Number, Boolean],
      default: 0
    }
  },
  data() {
    return {
      popoverVisible: {},
      stageIcon: {
        win: 'el-icon-circle-check',
        loss: 'el-icon-circle-close',
        invalidate: 'el-icon-remove-outline'
      },
      colors: {
        win: {
          main: {
            backgroudColor: '#00B8B0',
            borderColor: 'transparent',
            fontColor: '#fff'
          },
          plain: {
            backgroudColor: '#EBF9F8',
            borderColor: '#EBF9F8',
            fontColor: '#00B8B0'
          }
        },
        loss: {
          main: {
            backgroudColor: '#8E95B0',
            borderColor: 'transparent',
            fontColor: '#fff'
          },
          plain: {
            backgroudColor: '#F6F6F9',
            borderColor: '#F6F6F9',
            fontColor: '#8E95B0'
          }
        },
        invalidate: {
          main: {
            backgroudColor: '#C7CCDC',
            borderColor: 'transparent',
            fontColor: '#fff'
          },
          plain: {
            backgroudColor: '#F6F6F9',
            borderColor: '#F6F6F9',
            fontColor: '#C7CCDC'
          }
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['businessStatusOptions']),
    statusList() {
      return this.businessStatusOptions;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    curIndex() {
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].id === this.stage) {
          return i;
        }
      }
    },
    isClose() {
      return ['win', 'loss', 'invalidate'].includes(this.stage);
    },
    endLabel() {
      return this.isClose ? this.getStatusLabel(this.stage) : '结束';
    },
    angleColors() {
      return function (index) {
        if (index < this.curIndex) {
          return this.colors[this.isClose ? this.stage : 'win'].plain;
        } else if (index === this.curIndex) {
          return this.colors[this.isClose ? this.stage : 'win'].main;
        }
      };
    },
    closeColors() {
      return this.isClose ? this.colors[this.stage].main : undefined;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    preStage() {
      for (const item of this.statusList) {
        if (item.id === this.stage) {
          return item.stage_name;
        }
      }
    },
    dealStages() {
      return this.statusList.slice(0, this.statusList.length - 3);
    },
    endedStages() {
      return this.statusList.slice(this.statusList.length - 3);
    }
  },
  methods: {
    closePopover(index) {
      this.$refs[`popover${index}`][0].doClose();
    },
    changeStage(val) {
      this.$emit('submit', val);
      this.popoverVisible[val] = false;
    },
    popoverShow(value) {
      if (value === this.stage) {
        this.popoverVisible[value] = false;
      }
    },
    getStatusLabel(val) {
      for (const item of this.statusList) {
        if (item.id === val) {
          return item.stage_name;
        }
      }
    },
    selectClose(value) {
      const label = this.getStatusLabel(value);
      this.$confirm(`是否设为${label}?${label}后该交易的详情，交易阶段不可再变更`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('submit', value);
      });
    }
  }
};
exports.default = _default;