"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MissPointDetailDrawerContent = _interopRequireDefault(require("./MissPointDetailDrawerContent.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CourseAnalysisTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    showCourseName: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sortChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const missPointDetailDrawerVisible = (0, _vue.ref)(false);
    const currentCard = (0, _vue.ref)({});
    function openDetailDrawer(row) {
      console.log('row', row);
      currentCard.value = row;
      missPointDetailDrawerVisible.value = true;
    }
    function handleSortChange(_ref2) {
      let {
        column,
        prop,
        order
      } = _ref2;
      console.log('handleSortChange', column, prop, order);
      const orderMap = {
        ascending: 'asc',
        descending: 'desc'
      };
      const orderProp = order ? prop : '';
      emit('sortChange', orderProp, order ? orderMap[order] : '');
    }
    return {
      __sfc: true,
      props,
      emit,
      missPointDetailDrawerVisible,
      currentCard,
      openDetailDrawer,
      handleSortChange,
      t: _i18n.t,
      ImageAvatar: _ImageAvatar.default,
      MissPointDetailDrawerContent: _MissPointDetailDrawerContent.default
    };
  }
};
exports.default = _default;