// 重写 ResizeObserver，解决报错问题
// const _ResizeObserver = window.ResizeObserver;
// class CalmResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     super((entries, observer) => {
//       requestAnimationFrame(() => {
//         callback(entries, observer);
//       });
//     });
//   }
// }
// window.ResizeObserver = CalmResizeObserver;
// 阻止全局的promise报错
// window.addEventListener('unhandledrejection', function (e) {
//   e.preventDefault();
// });