import { render, staticRenderFns } from "./dealTabsDrawer.vue?vue&type=template&id=182f455a&scoped=true&"
import script from "./dealTabsDrawer.vue?vue&type=script&lang=js&"
export * from "./dealTabsDrawer.vue?vue&type=script&lang=js&"
import style0 from "./dealTabsDrawer.vue?vue&type=style&index=0&id=182f455a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182f455a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('182f455a')) {
      api.createRecord('182f455a', component.options)
    } else {
      api.reload('182f455a', component.options)
    }
    module.hot.accept("./dealTabsDrawer.vue?vue&type=template&id=182f455a&scoped=true&", function () {
      api.rerender('182f455a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BusinessTable/components/dealTabsDrawer.vue"
export default component.exports