"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.disabled ? _c("el-select", _vm._g(_vm._b({
    ref: "select",
    attrs: {
      value: _vm.localDepartmentName
    }
  }, "el-select", _vm.$attrs, false), _vm.$listeners), [_c("el-option", {
    style: {
      height: _vm.popperHeight,
      padding: 0
    },
    attrs: {
      value: false
    }
  }, [_c("department-tree", {
    ref: "tree",
    attrs: {
      "org-tree": _vm.orgTree,
      "disabled-arr": _vm.disabledArr,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      value: _vm.value
    },
    on: {
      change: _vm.handleChange
    }
  })], 1)], 1) : _c("el-select", {
    attrs: {
      value: _vm.departmentName,
      disabled: ""
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;