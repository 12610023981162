"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "create-task-drawer",
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      size: "600px"
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "form",
    staticClass: "task-form",
    attrs: {
      model: _vm.taskInfoConfig,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("coaching.taskName"),
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("coaching.pleaseEnterTaskName"),
      maxlength: "50"
    },
    model: {
      value: _vm.taskInfoConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.taskInfoConfig, "name", $$v);
      },
      expression: "taskInfoConfig.name"
    }
  })], 1), _c("el-form-item", {
    staticClass: "course_select",
    attrs: {
      label: _vm.$t("coaching.learnCourse"),
      prop: "course_ids"
    }
  }, [_c("TreeSelect", {
    attrs: {
      placeholder: _vm.$t("coaching.pleaseSelectCourse"),
      "show-tag-count": -1,
      "request-method": _vm.getCourseCategoryData,
      "request-params": {
        has_course: true
      },
      "backfill-list": _vm.copyCourseIds
    },
    on: {
      change: _vm.courseChange
    },
    scopedSlots: _vm._u([{
      key: "value-list-item-prefix-icon",
      fn: function (_ref) {
        let {
          nodeData
        } = _ref;
        return [nodeData.course_type ? _c("i", {
          class: ["iconfont", "tree-select-icon", _vm.getCourseConfig(nodeData.course_type).icon]
        }) : _c("i")];
      }
    }, {
      key: "search-list-item-prefix-icon",
      fn: function (_ref2) {
        let {
          nodeData
        } = _ref2;
        return [nodeData.course_type ? _c("i", {
          class: ["iconfont", "tree-select-icon", _vm.getCourseConfig(nodeData.course_type).icon],
          style: {
            color: _vm.getCourseConfig(nodeData.course_type).color
          }
        }) : _c("i")];
      }
    }, {
      key: "search-list-item-suffix-description",
      fn: function (_ref3) {
        let {
          nodeData
        } = _ref3;
        return [_c("el-tooltip", {
          attrs: {
            disabled: !nodeData.gist_status,
            placement: "top",
            content: _vm.disabledContent(nodeData.gist_status)
          }
        }, [_c("div", {
          staticClass: "search-disabled-tooltip"
        })]), _c("p", {
          staticClass: "search-list-item-description"
        }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.creator")) + "：" + _vm._s(nodeData.creater || "-"))]), _c("span", [_vm._v(" | ")]), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.creationTime")) + "：" + _vm._s(nodeData.create_time || "-"))])])];
      }
    }, {
      key: "tree-custom-node",
      fn: function (_ref4) {
        let {
          nodeData
        } = _ref4;
        return [_c("el-tooltip", {
          attrs: {
            disabled: !nodeData.gist_status,
            placement: "top",
            content: _vm.disabledContent(nodeData.gist_status)
          }
        }, [_c("span", {
          class: ["tree-select-tree-custom-node", {
            "disabled-tree-custom-node": nodeData.disabled
          }]
        }, [nodeData.course_type ? _c("i", {
          class: ["iconfont", "tree-select-icon", _vm.getCourseConfig(nodeData.course_type).icon],
          style: {
            color: _vm.getCourseConfig(nodeData.course_type).color
          }
        }) : _vm._e(), _c("span", {
          staticClass: "node-label"
        }, [_vm._v(" " + _vm._s(nodeData.name) + " ")]), nodeData.type === "category" ? _c("span", {
          staticClass: "course-count"
        }, [_vm._v(_vm._s(nodeData.count))]) : _vm._e()])])];
      }
    }])
  }, [_c("span", {
    attrs: {
      slot: "search-list-empty"
    },
    slot: "search-list-empty"
  }, [_c("span", {
    staticClass: "search-list-empty"
  }, [_vm._v(_vm._s(_vm.$t("coaching.noMatchingResults")))])]), _c("span", {
    attrs: {
      slot: "tree-empty"
    },
    slot: "tree-empty"
  }, [_c("div", {
    staticClass: "empty-course"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.noCourses")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goCourse
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.goToCreate"))), _c("i", {
    staticClass: "iconfont icon-right"
  })])], 1)])])], 1), _c("div", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.executor")))]), _c("el-form-item", {
    attrs: {
      prop: "executor"
    }
  }, [true ? _c("mgv-member-multi-select", {
    ref: "member-tree-select",
    attrs: {
      "is-prevent-not-selected": false,
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-params": {
        type: JSON.stringify(["training_edit"])
      },
      "backfill-list": _vm.numberList,
      "show-tag-count": 3
    },
    on: {
      change: _vm.assigneeChange
    }
  }) : _vm._e(), _c("MemberGroupOperation", {
    attrs: {
      "members-ids": _vm.taskInfoConfig.executor
    },
    on: {
      getImportMemberList: _vm.importGroupMembers
    }
  })], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.taskCategoryList.length,
      expression: "taskCategoryList.length"
    }],
    staticClass: "task-category",
    attrs: {
      label: _vm.$t("coaching.分类"),
      prop: "category_ids"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.taskInfoConfig.category_ids,
      callback: function ($$v) {
        _vm.$set(_vm.taskInfoConfig, "category_ids", $$v);
      },
      expression: "taskInfoConfig.category_ids"
    }
  }, _vm._l(_vm.taskCategoryList, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.startAndEndTime")))]), _c("div", {
    staticClass: "date-tips"
  }, [_vm._v(_vm._s(_vm.$t("coaching.startAndEndTimeTip")))]), _c("div", {
    staticClass: "date-item"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.$t("coaching.startTime"),
      placement: "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "start_time"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetime",
      disabled: _vm.taskStatus === 0,
      "value-format": "yyyy-MM-dd HH:mm",
      placeholder: _vm.$t("coaching.pleaseSelectTheStartTime"),
      align: "left",
      "picker-options": _vm.strartPickerOptions,
      "default-time": "00:00:00"
    },
    model: {
      value: _vm.taskInfoConfig.start_time,
      callback: function ($$v) {
        _vm.$set(_vm.taskInfoConfig, "start_time", $$v);
      },
      expression: "taskInfoConfig.start_time"
    }
  })], 1)], 1), _c("div", [_vm._v("-")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.$t("coaching.deadline"),
      placement: "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "end_time"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetime",
      "value-format": "yyyy-MM-dd HH:mm",
      placeholder: _vm.$t("coaching.pleaseSelectTheEndTime"),
      align: "right",
      "picker-options": _vm.endPickerOptions,
      "default-time": "23:59:59"
    },
    model: {
      value: _vm.taskInfoConfig.end_time,
      callback: function ($$v) {
        _vm.$set(_vm.taskInfoConfig, "end_time", $$v);
      },
      expression: "taskInfoConfig.end_time"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "alert_at",
      label: _vm.$t("coaching.提醒时间")
    }
  }, [_c("ReminderSelect", {
    attrs: {
      "is-longer-interval": "",
      "default-reminder-time": _vm.defaultReminderTime,
      "todo-time": _vm.taskInfoConfig.end_time
    },
    on: {
      reminderChange: _vm.handelReminderChange
    }
  })], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.createTask
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.save")) + " ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;