"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-table",
    style: {
      height: _vm.tableHeight + "px"
    },
    on: {
      mousemove: _vm.handlePopoverListener
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    attrs: {
      "empty-text": _vm.loading ? " " : "暂无数据",
      border: "",
      data: _vm.list,
      height: _vm.tableHeight,
      "cell-class-name": _vm.cellClassName,
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_vm._l(_vm.headerList, function (item, index) {
    return [_c("el-table-column", {
      key: index + item.field_name,
      ref: "column_" + item.field_name,
      refInFor: true,
      attrs: {
        label: item.name,
        prop: item.field_name,
        resizable: _vm.isTableDraggable(item.field_name),
        "class-name": "column_" + item.field_name + ` ${_vm.maxPage ? "left-no-shadow" : ""}` + ` ${_vm.rightBtnDisabled ? "right-no-shadow" : ""}`,
        "min-width": item.field_name == "speak_time" ? "" : Number(item.width),
        width: item.field_name == "speak_time" ? "500px" : "",
        formatter: _vm.formatterTest,
        align: "left",
        sortable: item.sortable,
        fixed: item.fixed,
        "sort-orders": ["ascending", "descending"]
      },
      scopedSlots: _vm._u([item.customHeader ? {
        key: "header",
        fn: function () {
          return [item.field_name === "speak_time" ? _c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("跟进动态")]), _c("business-explain")], 1) : _vm._e(), item.field_name === "next_step" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("下一步")]), _c("el-popover", {
            attrs: {
              placement: "top",
              width: "300",
              trigger: "hover",
              "popper-class": "hint",
              content: "近3次会话中出现的「下一步」，根据事件跟踪器自动捕获（仅提取3个月内的会话）"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e(), item.field_name === "objection" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("客户异议")]), _c("el-popover", {
            attrs: {
              placement: "top",
              width: "300",
              trigger: "hover",
              "popper-class": "hint",
              content: "会话中出现的客户拒绝或不满意，根据事件跟踪器自动捕获"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e(), item.field_name === "comment" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("评论")]), _c("el-popover", {
            attrs: {
              placement: "top",
              width: "300",
              trigger: "hover",
              "popper-class": "hint",
              content: "评论数=交易评论数+交易下所有会话的评论数。【评论内容可能因权限问题无法查看】"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e(), item.field_type === "score_rule" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: item.name
            }
          }, [_c("span", {
            staticClass: "score-rule-name"
          }, [_vm._v(_vm._s(item.name))])]), _c("el-popover", {
            attrs: {
              placement: "top",
              trigger: "hover",
              "popper-class": "hint",
              content: `${item.scoring_mode === "increase" ? "加分模式" : "减分模式"}${item.score_rule_description ? " · " + item.score_rule_description : ""}`
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e(), item.field_name === "space_conv_total" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("会话总数")]), _c("el-popover", {
            attrs: {
              placement: "top",
              trigger: "hover",
              "popper-class": "hint",
              content: "包括电话、视频和文本会话"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e(), item.field_name === "space_conv_total_duration" ? [_c("div", {
            staticClass: "objection-wrapper"
          }, [_c("span", [_vm._v("会话总时长")]), _c("el-popover", {
            attrs: {
              placement: "top",
              trigger: "hover",
              "popper-class": "hint",
              content: "仅电话会话时长，不包含视频和文本会话时长"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-question2 icon-size",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })])], 1)] : _vm._e()];
        },
        proxy: true
      } : null, item.special ? {
        key: "default",
        fn: function (_ref) {
          var _row$item$field_name;
          let {
            row,
            $index
          } = _ref;
          return [item.field_name == "trade_name" ? [_c("div", {
            staticClass: "multi-row"
          }, [_c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: row.trade_name,
              placement: "top",
              "visible-arrow": false
            }
          }, [_c("span", {
            staticClass: "text-title",
            on: {
              click: function ($event) {
                return _vm.goTradeDetail(row);
              }
            }
          }, [_vm._v(_vm._s(row.trade_name))])])], 1)] : _vm._e(), item.field_name === "account_name" ? [_c("div", {
            staticClass: "multi-row"
          }, [_c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: row.account_name,
              placement: "top-start",
              "visible-arrow": false
            }
          }, [_c("span", {
            staticClass: "text-subtitle",
            class: {
              "has-customer-permission": _vm.hasCustomerPermission
            },
            on: {
              click: function ($event) {
                return _vm.goCustomerPage(row.account_id);
              }
            }
          }, [_vm._v(_vm._s(row.account_name))])])], 1)] : _vm._e(), item.field_name === "risk" ? [_c("div", {
            ref: "risk",
            refInFor: true,
            attrs: {
              id: "risk-optimize"
            }
          }, [_c("risk-popover", {
            attrs: {
              list: row.risk || []
            },
            on: {
              riskDrawerClick: function ($event) {
                return _vm.openTradeTabsDrawer("risk", row);
              }
            }
          })], 1)] : _vm._e(), item.field_name == "speak_time" ? [$index === 0 ? _c("scale-line", {
            ref: "scaleLine",
            refInFor: true,
            class: {
              "is-safari-browser": _vm.issafariBrowser
            },
            attrs: {
              "flip-num": _vm.flipNum,
              "flip-num-loaded": _vm.flipNumLoaded,
              "max-page": _vm.maxPage
            },
            on: {
              "update:flipNumLoaded": function ($event) {
                _vm.flipNumLoaded = $event;
              },
              "update:flip-num-loaded": function ($event) {
                _vm.flipNumLoaded = $event;
              },
              "update:maxPage": function ($event) {
                _vm.maxPage = $event;
              },
              "update:max-page": function ($event) {
                _vm.maxPage = $event;
              },
              getNextSituation: function ($event) {
                return _vm.$emit("getNextSituation");
              }
            }
          }) : _vm._e(), _c("bubble-line", {
            ref: "bubble-line",
            refInFor: true,
            attrs: {
              id: "speak-time-optimize",
              speech: row.speak_time,
              "active-conv-date": _vm.activeConvDate,
              "flip-num": _vm.flipNum,
              text: row.trade_name,
              selected: _vm.bubbleSelected(row)
            },
            on: {
              bubbleClick: function ($event) {
                return _vm.bubbleClick($event, row);
              }
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleBubbleLineClick(row);
              }
            }
          }), $index === 0 ? [_c("div", {
            ref: "btn-flip-box",
            refInFor: true,
            staticClass: "btn-flip-box"
          }, [_c("el-button", {
            staticClass: "btn-flip left el-icon-arrow-left",
            class: {
              noBottom: _vm.list.length / 2 % 1 !== 0
            },
            attrs: {
              disabled: _vm.maxPage <= _vm.flipNum
            },
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.flip(1);
              }
            }
          }), _c("el-button", {
            staticClass: "btn-flip right el-icon-arrow-right",
            class: {
              noBottom: _vm.list.length / 2 % 1 !== 0
            },
            attrs: {
              disabled: _vm.rightBtnDisabled
            },
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.flip(-1);
              }
            }
          })], 1)] : _vm._e()] : _vm._e(), item.field_name == "next_connect_time" ? [_c("div", [_vm._v(" " + _vm._s(_vm.handleNextConnectTime(row.next_connect_time)) + " "), row.next_conversation && row.next_conversation.time && row.next_conversation.type === "video" ? _c("el-button", {
            staticClass: "mg-button el-button-text--info el-icon-video-camera",
            attrs: {
              circle: ""
            },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.goVideoRoom(row.next_connect_time);
              }
            }
          }) : _vm._e()], 1)] : item.field_name === "account_label" || item.event_type === 2 ? [_c("div", {
            staticClass: "key-event-column",
            attrs: {
              id: "account-label"
            }
          }, [_c("key-event-column-content", {
            attrs: {
              "column-data-arr": row[item.field_name] && row[item.field_name].data || [],
              type: item.field_type,
              "name-key": "account_label_name"
            },
            on: {
              openKeyEventDrawer: function ($event) {
                return _vm.openTradeTabsDrawer("account_label", row, item.field_name);
              }
            }
          })], 1)] : item.field_type === "date" ? [_vm._v(" " + _vm._s(_vm.formatDate(row[item.field_name])) + " ")] : item.field_type === "datetime" ? [_vm._v(" " + _vm._s(_vm.formatDateTime(row[item.field_name])) + " ")] : _vm._e(), item.field_name === "next_step" ? [_c("div", {
            staticClass: "key-event-column",
            attrs: {
              id: "next_step-label-optimize"
            }
          }, [_c("key-event-column-content", {
            attrs: {
              "column-data-arr": row.next_step && row.next_step.data
            },
            on: {
              openKeyEventDrawer: function ($event) {
                return _vm.openTradeTabsDrawer("next_step", row);
              }
            }
          })], 1)] : _vm._e(), item.field_name === "objection" ? [_c("div", {
            staticClass: "key-event-column",
            attrs: {
              id: "objection-label-optimize"
            }
          }, [_c("key-event-column-content", {
            attrs: {
              "column-data-arr": row.objection && row.objection.data
            },
            on: {
              openKeyEventDrawer: function ($event) {
                return _vm.openTradeTabsDrawer("objection", row);
              }
            }
          })], 1)] : _vm._e(), item.field_name === "events" ? [_c("div", {
            staticClass: "key-event-column",
            attrs: {
              id: "key-events"
            }
          }, [_c("key-event-column-content", {
            attrs: {
              "column-data-arr": row.events && row.events.data
            },
            on: {
              openKeyEventDrawer: function ($event) {
                return _vm.openTradeTabsDrawer("events", row);
              }
            }
          })], 1)] : item.field_name === "trade_stage" ? [_c("mg-tag", {
            attrs: {
              center: "",
              size: "small",
              type: _vm.handleStatusMsg(row.trade_stage).type
            }
          }, [_vm.handleStatusMsg(row.trade_stage).iconClass ? _c("i", {
            class: _vm.handleStatusMsg(row.trade_stage).iconClass
          }) : _vm._e(), _vm._v(" " + _vm._s(row.trade_stage) + " ")])] : item.field_name === "own_by" ? [_c("div", {
            staticClass: "follow-box"
          }, [_c("image-avatar", {
            attrs: {
              avatar: row.own_by && row.own_by.avatar,
              name: row.own_by && row.own_by.name
            }
          }), _c("span", {
            staticClass: "multi-row"
          }, [_c("el-tooltip", {
            staticClass: "itemFollow",
            attrs: {
              effect: "dark",
              content: row.own_by && row.own_by.name,
              placement: "top",
              disabled: row.own_by && row.own_by.name < 5
            }
          }, [_c("span", {
            staticClass: "text-follower"
          }, [_vm._v(_vm._s(row.own_by && row.own_by.name))])])], 1)], 1)] : item.field_type === "file" ? [_vm._v(" " + _vm._s(row[item.field_name][0].split("/").pop()) + " ")] : item.field_type === "radio" || item.field_type === "select" ? [row[item.field_name] && row[item.field_name].length ? _c("div", {
            staticClass: "radio-select"
          }, [_vm._v(" " + _vm._s(row[item.field_name].toString()) + " ")]) : _c("div", [_vm._v("-")])] : item.field_type === "checkbox" ? [row[item.field_name] && row[item.field_name].length ? _c("el-tooltip", {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: row[item.field_name].join("，"),
              placement: "top",
              "open-delay": 200
            }
          }, [_c("div", {
            staticClass: "multi-select"
          }, _vm._l(row[item.field_name], function (columnItem, itemIndex) {
            return _c("span", {
              key: itemIndex,
              staticClass: "multi-select-item"
            }, [_vm._v(" " + _vm._s(columnItem) + " ")]);
          }), 0)]) : _c("div", [_vm._v("-")])] : item.field_type === "table" ? [row[item.field_name] && row[item.field_name].length && Array.isArray(row[item.field_name]) ? _c("el-popover", {
            attrs: {
              placement: "left",
              width: "442",
              "popper-class": "popover-class",
              trigger: "hover"
            }
          }, [_c("div", {
            staticClass: "table-title"
          }, [_vm._v(" " + _vm._s(row.trade_name) + " "), _c("span", [_vm._v("|")]), _vm._v(" " + _vm._s(item.name) + "  (" + _vm._s(row[item.field_name].length) + "条) ")]), _c("el-table", {
            attrs: {
              data: row[item.field_name] || [],
              "max-height": "230"
            }
          }, _vm._l(_vm.customDealDataShow(_vm.table[item.field_name]), function (attr) {
            return _c("el-table-column", {
              key: attr,
              attrs: {
                width: "200",
                property: attr,
                label: _vm.table[item.field_name][attr]
              }
            });
          }), 1), _c("el-link", {
            attrs: {
              slot: "reference",
              type: "primary",
              underline: false
            },
            slot: "reference"
          }, [_vm._v(_vm._s(row[item.field_name][0][_vm.table[item.field_name].display]))])], 1) : _c("span", [_vm._v("-")])] : item.field_name == "comment" ? [Number(row.comment) ? _c("div", {
            attrs: {
              id: "comment-button-style"
            }
          }, [_c("el-button", {
            staticClass: "comment-button",
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.openTradeTabsDrawer("comment", row);
              }
            }
          }, [_c("div", {
            staticClass: "comment-text"
          }, [_c("i", {
            staticClass: "info-icon iconfont icon-chat-line-square"
          }), _c("span", [_vm._v(_vm._s(row.comment))])])])], 1) : _c("span", [_vm._v("-")])] : item.field_name === "customer_score" ? [_vm._v(" " + _vm._s(row[item.field_name] ? parseFloat(parseFloat(row[item.field_name]).toFixed(4)) : "-") + " ")] : item.field_name === "space_conv_total_duration" ? [row.space_conv_total_duration ? _c("div", [_vm._v(" " + _vm._s(_vm.secondToTime(row.space_conv_total_duration)) + " ")]) : _c("div", [_vm._v("-")])] : item.field_type === "money" ? [_vm._v(" " + _vm._s(_vm.formatMoney(row[item.field_name], item)) + " ")] : item.field_type === "number" ? [_vm._v(" " + _vm._s(_vm.formatNumber(row[item.field_name], item)) + " ")] : item.field_type === "score_rule" ? [row[item.field_name] && row[item.field_name].item.length ? _c("GradeProgressPopover", {
            attrs: {
              id: "trade-score-rule",
              "score-rule": row[item.field_name]
            },
            on: {
              goScoreDetail: function ($event) {
                return _vm.goTradeScoreDetail(row);
              }
            }
          }) : _c("span", {
            staticClass: "no-trade-score"
          }, [_vm._v("无评分")])] : item.field_type === "link" ? [(_row$item$field_name = row[item.field_name]) !== null && _row$item$field_name !== void 0 && _row$item$field_name.link ? _c("LinkText", _vm._b({}, "LinkText", row[item.field_name], false)) : _c("span", [_vm._v("-")])] : _vm._e()];
        }
      } : null], null, true)
    })];
  }), _vm.operateColumnVisible && !_vm.isBaseTrade ? _c("el-table-column", {
    attrs: {
      label: "操作",
      prop: "operate",
      width: _vm.operateColumnWidth,
      fixed: "right",
      align: "left",
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("trade-operate-buttons", {
          attrs: {
            "operate-buttons": _vm.operateButtons,
            "row-info": row,
            "active-id": _vm.activeTradeId,
            "is-in-row": ""
          },
          on: {
            clickContactPersonItem: _vm.clickContactPersonItem,
            changeActiveDealId: _vm.changeActiveTradeId,
            openContactPersonDialog: _vm.openContactPersonDialog
          }
        })];
      }
    }], null, false, 1662142097)
  }) : _vm._e()], 2), _c("transition", {
    attrs: {
      name: "date-popover"
    }
  }, [_vm.datePopoverVisible ? _c("date-popover", _vm._b({}, "date-popover", _vm.popoverProps, false), [_vm._v(_vm._s(_vm.popoverDate))]) : _vm._e()], 1), _vm.currentRow ? _c("trade-tabs-drawer", {
    attrs: {
      visible: _vm.isDrawerOpen,
      "header-list": _vm.headerList,
      "trade-id": _vm.tradeId,
      "active-tab-name": _vm.activeTabName,
      "current-row": _vm.currentRow,
      "current-field-name": _vm.currentFieldName,
      "active-conv-date": _vm.activeConvDate
    },
    on: {
      "update:visible": function ($event) {
        _vm.isDrawerOpen = $event;
      },
      "update:activeTabName": function ($event) {
        _vm.activeTabName = $event;
      },
      "update:active-tab-name": function ($event) {
        _vm.activeTabName = $event;
      },
      "update:currentRow": function ($event) {
        _vm.currentRow = $event;
      },
      "update:current-row": function ($event) {
        _vm.currentRow = $event;
      },
      "update:activeConvDate": function ($event) {
        _vm.activeConvDate = $event;
      },
      "update:active-conv-date": function ($event) {
        _vm.activeConvDate = $event;
      }
    }
  }) : _vm._e(), _c("upload-media-dialog", {
    ref: "upload-media-dialog",
    attrs: {
      "page-type": _vm.pageType,
      "upload-media-dialog-visible": _vm.uploadMediaDialogVisible,
      "media-row-info": _vm.mediaRowInfo,
      "limit-count": 5,
      multiple: ""
    },
    on: {
      closeUploadMediaDialog: _vm.closeUploadMediaDialog
    }
  }), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible,
      type: "deal"
    },
    on: {
      closeDialog: function ($event) {
        _vm.meetingDialogVisible = false;
      }
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e(), _c("associate-contact-dialog", {
    ref: "associateContactDialog",
    attrs: {
      "deal-id": _vm.activeTradeId,
      "associate-contact-dialog-visible": _vm.associateContactDialogVisible,
      "contact-list": _vm.clientContactList
    },
    on: {
      closeDialog: function ($event) {
        _vm.associateContactDialogVisible = false;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;