"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentSelect = _interopRequireDefault(require("@/components/DepartmentSelect"));
var _coach = require("@/api/coach");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TableFilters',
  components: {
    DepartmentSelect: _DepartmentSelect.default
  },
  data() {
    return {
      departmentId: [],
      userId: '',
      query: '',
      userOptions: []
    };
  },
  watch: {
    departmentId(val) {
      this.$emit('change', {
        department_id: val[0]
      });
      this.userId = '';
      this.userOptions = [];
    }
  },
  methods: {
    async getUserOptions(query) {
      if (!query) return;
      const res = await (0, _coach.searchUser)({
        department_id: this.departmentId[0],
        filter: query
      });
      this.userOptions = res.results.users;
    },
    handleChange() {
      this.$emit('change', {
        department_id: this.departmentId[0],
        user_id: this.userId
      });
    }
  }
};
exports.default = _default;