// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuText": "#6c7e93",
	"menuActiveText": "#4461ec",
	"subMenuActiveText": "#666f8d",
	"menuBg": "#e8eaee",
	"menuHover": "rgba(68, 97, 236, 0.1)",
	"subMenuBg": "#fff",
	"subMenuHover": "#eee",
	"sideBarWidth": "60px",
	"headerHeight": "40px",
	"titleHeight": "67px",
	"pageMainText": "#37446f",
	"pageCommonText": "#666f8d",
	"pageActiveText": "#4461ec",
	"title": "18px",
	"subtitle": "16px",
	"body": "14px",
	"describe": "12px",
	"active": "#4461ec",
	"inactive": "#e2e5e9",
	"graphBg": "rgba(68, 97, 236, 0.1019607843)",
	"graphEdge": "#c4cbd4"
};
export default ___CSS_LOADER_EXPORT___;
