"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _recorderCore = _interopRequireDefault(require("recorder-core"));
require("recorder-core/src/engine/mp3");
require("recorder-core/src/engine/mp3-engine");
require("recorder-core/src/extensions/lib.fft");
require("recorder-core/src/extensions/frequency.histogram.view");
var _nanoid = require("nanoid");
var _timeFormatter = require("@/utils/time-formatter");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import 'recorder-core/src/extensions/waveview';
var _default = {
  name: 'AudioRecorder',
  inject: {
    uploadVoiceMethod: {
      form: 'uploadVoiceMethod',
      default: () => _coachingCenter.uploadReplyRecord
    },
    deleteVoiceMethod: {
      from: 'deleteVoiceMethod',
      default: () => () => {}
    },
    audioFilePathPrefix: {
      from: 'audioFilePathPrefix',
      default: 'reply_record'
    }
  },
  props: {
    isTest: Boolean,
    isTemplate: Boolean,
    disabled: Boolean,
    reply: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      audioSrc: '',
      status: '',
      mediaRecorder: null,
      duration: 0,
      wave: '',
      score: -1,
      waveBoxId: 'wave-box-' + (0, _nanoid.nanoid)(),
      isScoring: false
    };
  },
  watch: {
    reply: {
      handler(val) {
        const url = `${this.audioFilePathPrefix}_url`;
        this.status = val[url] ? 'finished' : '';
        this.audioSrc = val[url];
        this.score = val.reply_score;
      },
      immediate: true
    }
  },
  methods: {
    async startRecord() {
      if (_recorderCore.default.IsOpen()) {
        this.$message.warning(this.isTest ? this.$t('coaching.conductingTestWarning') : this.$t('coaching.proceedingWithOtherRecordingsWarning'));
        return;
      }
      this.mediaRecorder = (0, _recorderCore.default)({
        type: 'mp3',
        sampleRate: 16000,
        bitRate: 16,
        onProcess: this.onProcess
      });
      this.mediaRecorder.open(() => {
        this.status = 'recording';
        this.$emit('recording');
        this.mediaRecorder.start();
        this.$nextTick(() => {
          // this.wave = Recorder.WaveView({
          //   elem: `#${this.waveBoxId}`,
          //   width: 30,
          //   height: 50
          // });
          this.wave = _recorderCore.default.FrequencyHistogramView({
            elem: `#${this.waveBoxId}`,
            lineCount: 25,
            // 柱子数量
            spaceWidth: 10,
            // 柱子间隔
            minHeight: 2,
            position: 0,
            stripeEnable: false,
            // 柱子顶部的峰值小条（被柱子顶着跳动的小块）
            mirrorEnable: true,
            // 左右镜像
            linear: [0, '#008df3', 0.5, '#00ebec', 1, '#008df3']
          });
        });
      }, () => {
        this.$message.warning(this.$t('coaching.pleaseOpenTheMicrophone'));
      });
    },
    onProcess(buffers, powerLevel, duration, sampleRate) {
      var _this$wave;
      this.duration = (0, _timeFormatter.secondToTime)(parseInt(duration / 1000));
      (_this$wave = this.wave) === null || _this$wave === void 0 ? void 0 : _this$wave.input(buffers[buffers.length - 1], powerLevel, sampleRate);
    },
    endRecord() {
      this.duration && this.mediaRecorder.stop((blob, duration) => {
        this.mediaRecorder.close();
        this.mediaRecorder = null;
        this.status = 'finished';
        this.$emit('finished');
        this.audioSrc = window.URL.createObjectURL(blob);
        this.duration = '';
        if (!this.isTest) {
          this.uploadRecord(blob, duration);
        }
      });
    },
    async uploadRecord(blob, duration) {
      const params = {
        file: blob,
        duration,
        reply_id: this.reply.reply_id
      };
      this.isScoring = true;
      const res = await this.uploadVoiceMethod(params);
      if (res.code === 20000) {
        const {
          is_async,
          score
        } = res.results;
        this.isScoring = is_async;
        this.score = score;
        this.$message.success('上传成功！');
      }
    },
    async closeAudio() {
      this.status = '';
      if (this.isTest) return;
      const res = await this.deleteVoiceMethod({
        reply_id: this.reply.reply_id
      });
      if (res.code === 20000) {
        this.$message.success('删除成功');
      }
    }
  }
};
exports.default = _default;