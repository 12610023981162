"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "publish-model-content"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_vm._m(0), _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _setup.formModel,
      rules: _setup.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "version",
      label: "选择要发布的版本"
    }
  }, [_c(_setup.ModelVersionSelect, {
    attrs: {
      "model-id": _vm.id,
      "strict-version-disable": _vm.isAiModel,
      "show-status": _vm.isAiModel
    },
    model: {
      value: _setup.formModel.version,
      callback: function ($$v) {
        _vm.$set(_setup.formModel, "version", $$v);
      },
      expression: "formModel.version"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "create_new_event"
    }
  }, [_c("el-radio-group", {
    staticClass: "is-new-event-radios",
    model: {
      value: _setup.formModel.create_new_event,
      callback: function ($$v) {
        _vm.$set(_setup.formModel, "create_new_event", $$v);
      },
      expression: "formModel.create_new_event"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true
    }
  }, [_vm._v("发布到新关键事件")]), _c("el-radio", {
    attrs: {
      label: false
    }
  }, [_vm._v("替换已有关键事件的模型")])], 1)], 1), _setup.isCreateNewEvent ? [_c(_setup.RuleEventFormItem, {
    staticClass: "rule-event-form-item",
    attrs: {
      "name-prop": "event_name",
      name: _setup.formModel.event_name,
      description: _setup.formModel.event_description
    },
    on: {
      "update:name": function ($event) {
        return _vm.$set(_setup.formModel, "event_name", $event);
      },
      "update:description": function ($event) {
        return _vm.$set(_setup.formModel, "event_description", $event);
      }
    }
  }), _c("el-form-item", {
    key: "workspace-selector-new-event",
    attrs: {
      prop: "workspace_id",
      label: "所属空间"
    }
  }, [_c(_setup.WorkspaceSelector, {
    model: {
      value: _setup.formModel.workspace_id,
      callback: function ($$v) {
        _vm.$set(_setup.formModel, "workspace_id", $$v);
      },
      expression: "formModel.workspace_id"
    }
  })], 1)] : [_c("el-form-item", {
    key: "workspace-selector",
    attrs: {
      prop: "workspace_id",
      label: "所属空间"
    }
  }, [_c(_setup.WorkspaceSelector, {
    model: {
      value: _setup.formModel.workspace_id,
      callback: function ($$v) {
        _vm.$set(_setup.formModel, "workspace_id", $$v);
      },
      expression: "formModel.workspace_id"
    }
  })], 1), _c("el-form-item", {
    key: "event-cascader-selector",
    attrs: {
      prop: "event_id",
      label: "关键事件"
    }
  }, [_c(_setup.KeyEventCascader, {
    staticClass: "key-event-select",
    attrs: {
      "workspace-id": _setup.formModel.workspace_id
    },
    on: {
      change: _setup.onKeyeventChange
    },
    model: {
      value: _setup.formModel.event_id,
      callback: function ($$v) {
        _vm.$set(_setup.formModel, "event_id", $$v);
      },
      expression: "formModel.event_id"
    }
  })], 1)]], 2)], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _setup.publishLoading
    },
    on: {
      click: function ($event) {
        return _setup.publishModelAsync(false);
      }
    }
  }, [_vm._v("仅发布")]), _c("el-button", {
    attrs: {
      loading: _setup.publishLoading,
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _setup.publishModelAsync(true);
      }
    }
  }, [_vm._v("发布并启用")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "info-row"
  }, [_c("i", {
    staticClass: "iconfont icon-info"
  }), _c("span", [_vm._v("模型发布后，关键事件需为启用状态，才能应用到会话")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;