"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'EditNameDialog',
  props: {
    formType: {
      type: String,
      default: '文件夹'
    },
    visible: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    editCatalogType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      isValidate: false
    };
  },
  computed: {
    title() {
      return this.editCatalogType === 'createCatalog' ? `${this.$t('coaching.newHasVar', [this.formType])}` : `${this.$t('coaching.renameHasVar', [this.formType])}`;
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs['nameInput'].focus();
          });
          if (this.editCatalogType === 'createCatalog') {
            this.value = '';
          } else {
            this.value = this.label;
          }
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
      this.isValidate = false;
    },
    submit() {
      this.isValidate = true;
      if (this.value) {
        this.$emit('submit', this.value);
        this.handleClose();
      }
    }
  }
};
exports.default = _default;