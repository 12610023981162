"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-sentence-item"
  }, [_c("div", {
    staticClass: "model-sentence-item__content"
  }, _vm._l(_vm.modelSentence, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "model-sentence-item__content-item"
    }, [_c("div", {
      staticClass: "model-sentence-item__content-item-entity"
    }, [_vm._v(" " + _vm._s(_setup.entityTypeObj[item["entity_type"]]) + " ")]), _c("el-input", {
      staticClass: "sentence-input",
      attrs: {
        type: "textarea",
        resize: "none",
        autosize: {
          minRows: 1
        },
        maxlength: 256,
        placeholder: "请输入"
      },
      on: {
        change: _setup.changeModelSentenceItem
      },
      model: {
        value: item.content,
        callback: function ($$v) {
          _vm.$set(item, "content", $$v);
        },
        expression: "item.content"
      }
    })], 1);
  }), 0), _c("div", {
    staticClass: "delete-btn",
    on: {
      click: _setup.deleteModelSentenceItem
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })]), _c("el-tooltip", {
    attrs: {
      content: "请填写例句",
      placement: "top",
      disabled: !!_setup.sentenceDisabled
    }
  }, [_c("div", {
    class: ["intelligent-extension-btn", {
      disabled: !_setup.sentenceDisabled
    }],
    on: {
      click: function ($event) {
        return _setup.handleAIExpand();
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-magic-1"
  }), _c("span", [_vm._v("智能扩充")])])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;