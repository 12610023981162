"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$data;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-analysis-table"
  }, [(_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.length ? _c("el-table", {
    staticClass: "data-analysis-table__inner",
    attrs: {
      data: _vm.data,
      "default-sort": {
        prop: "plan_count",
        order: "descending"
      },
      height: "calc(100vh - 264px)"
    },
    on: {
      "sort-change": _setup.sortChange
    }
  }, [_vm.mainColumn.key === "trainee" ? _c("el-table-column", {
    attrs: {
      prop: "name",
      width: "200px",
      fixed: "left"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$headers$trainee;
        return [_c("div", {
          staticClass: "header-cell first-header-cell"
        }, [_c("h1", {
          staticClass: "title-row"
        }, [_vm._v(_vm._s(_vm.mainColumn.label))]), _c("p", {
          staticClass: "calc-row"
        }, [_c("span", [_vm._v("总计")]), _c("span", {
          staticClass: "calc-num"
        }, [_vm._v(_vm._s((_vm$headers$trainee = _vm.headers.trainee) === null || _vm$headers$trainee === void 0 ? void 0 : _vm$headers$trainee.total))]), _c("span", [_vm._v("人")])])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "avatar-name-container"
        }, [_c("MgvAvatarName", {
          attrs: {
            name: row.trainee.name,
            avatar: row.trainee.avatar,
            size: 28
          }
        }), _c("div", {
          staticClass: "name-department"
        }, [_c("p", {
          staticClass: "name-text"
        }, [_vm._v(_vm._s(row.trainee.name))]), _c("p", {
          staticClass: "department-text"
        }, [_vm._v(" " + _vm._s(_setup.reverseStr(row.trainee.tree)) + " ")])])], 1)];
      }
    }], null, false, 2502078183)
  }) : _c("el-table-column", {
    attrs: {
      width: "200",
      fixed: "left",
      prop: "department"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header-cell first-header-cell"
        }, [_c("h1", {
          staticClass: "title-row"
        }, [_vm._v(_vm._s(_vm.mainColumn.label))]), _c("p", {
          staticClass: "calc-row"
        }, [_c("span", [_vm._v("总计")]), _c("span", {
          staticClass: "calc-num"
        }, [_vm._v(_vm._s(_vm.headers.department.total))]), _c("span", [_vm._v("个")])]), _c("p", {
          staticClass: "calc-row"
        }, [_c("span", [_vm._v("共")]), _c("span", {
          staticClass: "calc-num"
        }, [_vm._v(_vm._s(_vm.headers.department.total_user_count))]), _c("span", [_vm._v("人练习")])])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "name-department"
        }, [_c("p", {
          staticClass: "name-text"
        }, [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            placement: "top",
            effect: "dark",
            content: row.department.name
          }
        }, [_c("span", {
          staticClass: "department-name"
        }, [_vm._v(_vm._s(row.department.name))])]), _c("span", {
          staticClass: "count-desc"
        }, [_vm._v("共" + _vm._s(row.department.user_count) + "人")])], 1), _c("el-tooltip", {
          attrs: {
            content: row.department.tree,
            placement: "top",
            enterable: false
          }
        }, [_c("p", {
          staticClass: "department-text"
        }, [_vm._v(" " + _vm._s(_setup.reverseStr(row.department.tree)) + " ")])])], 1)];
      }
    }], null, false, 1927935855)
  }), _vm._l(_vm.subColumns, function (column) {
    return _c("el-table-column", {
      key: column.key,
      attrs: {
        "min-width": "200",
        prop: column.key,
        sortable: "custom",
        "sort-orders": ["descending", "ascending"]
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "header-cell"
          }, [_c("h1", {
            staticClass: "title-row"
          }, [_vm._v(_vm._s(column.label))]), _vm.headers[column.key].total !== -1 ? _c("p", {
            staticClass: "calc-row"
          }, [_c("span", [_vm._v("总计")]), _c("span", {
            staticClass: "calc-num"
          }, [_vm._v(_vm._s(_vm.headers[column.key].total))]), _c("span", [_vm._v("个")])]) : _vm._e(), _vm.headers[column.key].average !== -1 ? _c("p", {
            staticClass: "calc-row"
          }, [_c("span", [_vm._v("平均")]), column.isRatio ? _c("span", {
            staticClass: "calc-num"
          }, [_vm._v(_vm._s(_setup.parse2percent(_vm.headers[column.key].average)))]) : [_c("span", {
            staticClass: "calc-num"
          }, [_vm._v(_vm._s(_vm.headers[column.key].average))]), _c("span", [_vm._v("个")])]], 2) : _vm._e()])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row[column.key] === -1 ? _c("span", [_vm._v("-")]) : column.isDisableClick ? _c("span", [_vm._v(_vm._s(column.isRatio ? _setup.parse2percent(row[column.key]) : row[column.key]))]) : _c("MgvButton", {
            attrs: {
              type: "text",
              size: "tiny"
            },
            on: {
              click: function ($event) {
                return _setup.onCellClick(column, row);
              }
            }
          }, [_vm._v(_vm._s(row[column.key]))])];
        }
      }], null, true)
    });
  })], 2) : _c(_setup.NoData)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;