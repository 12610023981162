"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _RiskRuleTable = _interopRequireDefault(require("./components/RiskRuleTable.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeRiskRuleSet',
  components: {
    RiskRuleTable: _RiskRuleTable.default
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name'])
  }
};
exports.default = _default;