"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _conversationTable = _interopRequireDefault(require("./conversationTable"));
var _listCard = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCard"));
var _conversationFilter = _interopRequireDefault(require("./conversationFilter"));
var _conversationEchartsBar = _interopRequireDefault(require("./conversationEchartsBar"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _filterManageDrawer = _interopRequireDefault(require("./filterManageDrawer"));
var _eventKeyDrawer = _interopRequireDefault(require("./eventKeyDrawer"));
var _TransferConversationDialog = _interopRequireDefault(require("@/components/TransferConversationDialog"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _WorkspaceBadge = _interopRequireDefault(require("@/components/WorkspaceBadge"));
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
var _uploadConversation = _interopRequireDefault(require("./uploadConversation"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _extractInfoSettingDrawer = _interopRequireDefault(require("./extractInfoSettingDrawer"));
var _automationProcessDrawer = _interopRequireDefault(require("./automationProcessDrawer"));
var _conversationList = require("@/api/conversationList");
var _index = require("@/utils/index");
var _export = require("@/api/export");
var _timezone = require("@/utils/timezone");
var _vuex = require("vuex");
var _workspace = require("@/api/workspace");
var _BookMeeting = _interopRequireDefault(require("@/components/BookMeeting"));
var _UploadMediaDialog = _interopRequireDefault(require("@/components/UploadMediaDialog"));
var _routeMap = require("@/router/routeMap");
var _performanceTracking = _interopRequireDefault(require("@/utils/performance-tracking"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import UploadConversation from '@/components/UploadConversation';
var _default = {
  name: 'ConversationListPage',
  components: {
    ConversationTable: _conversationTable.default,
    ConversationFilter: _conversationFilter.default,
    ConversationEchartsBar: _conversationEchartsBar.default,
    ConversationListCard: _listCard.default,
    SortCaret: _sortCaret.default,
    FilterManageDrawer: _filterManageDrawer.default,
    EventKeyDrawer: _eventKeyDrawer.default,
    BookMeeting: _BookMeeting.default,
    UploadConversation: _uploadConversation.default,
    UploadMediaDialog: _UploadMediaDialog.default,
    TransferConversationDialog: _TransferConversationDialog.default,
    SpaceButton: _SpaceButton.default,
    WorkspaceBadge: _WorkspaceBadge.default,
    NoData: _NoData.default,
    ExtractInfoSettingDrawer: _extractInfoSettingDrawer.default,
    AutomationProcessDrawer: _automationProcessDrawer.default
  },
  mixins: [_cancelTokenMixin.default],
  provide() {
    return {
      scrollList: this.scrollList,
      goTimeDetail: this.goTimeDetail,
      getHasSummary: this.getHasSummary,
      getHasExtractInfo: this.getHasExtractInfo
    };
  },
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    },
    isInQualityTesting: {
      from: 'isInQualityTesting',
      default: false
    },
    isInQuickModelingPage: {
      from: 'isInQuickModelingPage',
      default: false
    }
  },
  props: {
    createConvFlowVisible: {
      type: Boolean,
      default: false
    },
    backFillFilters: {
      type: Object,
      default: () => ({})
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 100]
    },
    hasExtraPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transferConversationDialogVisible: false,
      uploadFlag: false,
      params: {
        current_page: 1,
        size: 10,
        filter: [],
        filter_relation: 'and',
        event_engine_relation: 'and',
        event_engine: [],
        event_engine_order: [],
        date: {
          range: [],
          tag: ''
        },
        entity_type: '',
        conversation_deal_status: '',
        conversation_type: '',
        conversation_scene_type: '',
        duration: {
          unit: 'second',
          value: [0, 'unlimit']
        },
        max_silence_duration: [0, 'unlimit'],
        hang_up: 0,
        // 0无 1销售 2客户
        host_ids: [],
        hosts_includes_deleted_members: false,
        organization_tree_ids: [],
        account_ids: [],
        deal_name: {
          value: [],
          is_batch: false
        },
        sort_by: '',
        sort_order: '',
        custom_filters: {},
        // 商机自定义字段筛选,
        workspace_id: 0,
        custom_sentence_count: [],
        // 客户发言条数
        sale_sentence_count: [],
        // 销售发言条数
        sentence_count: [],
        // 总发言条数
        // 会话自定义字段
        conv_custom_filters: {},
        conversation_id: {
          value: [],
          is_batch: false
        },
        // 会话ids
        compliance_score_filters: [],
        // 质检评分
        conv_score_filters: [],
        // 会话评分
        conv_times: {
          value: [-1, -1],
          is_custom: false
        },
        // 第几次沟通
        // 会话维度字段
        max_sales: [],
        // 最大销售说话时长
        max_customer: [],
        // 最大客户说话时长
        percent_speak: [],
        // 销售说话占比
        discuss: [],
        // 深入讨论次数
        question: [],
        // 销售提问次数
        customer_question: [],
        // 客户提问次数
        sales_speed: [],
        // 销售说话语速
        customer_speed: [] // 客户说话语速
      },

      pageParams: {
        current_page: 1,
        size: 1
      },
      resultsTotal: 0,
      type: '',
      isNone: '',
      // default表示还没请求, true表示请求完没数据, false有数据
      listLoading: false,
      list: [],
      viewType: 'card',
      chartData: null,
      // echarts 数据
      listRefresh: [],
      filterManageVisible: false,
      previousParams: null,
      eventKeyDrawerVisible: false,
      uploadMediaDialogVisible: false,
      mediaType: '',
      convId: '',
      participants: [],
      isKeyEventDetail: false,
      eventId: '',
      transferConvCount: 0,
      scoreRuleId: 0,
      scoreRuleName: '',
      conversationListTableHead: [],
      // 会话列表页面表头
      hasSummary: false,
      hasExtractInfo: false,
      extractInfoSettingDrawerVisible: false,
      automationProcessDrawerVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg', 'pageSize', 'modulePermission', 'has_tencent_meeting', 'interfacePermission', 'workspaceInfo', 'hasWorkspace', 'api_permissions', 'timezone', 'orgId']),
    conversationScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 1);
    },
    complianceScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 2);
    },
    echartsTitle() {
      const {
        ...filterObj
      } = this.params;
      const initObj = {
        filter: [],
        event_engine: [],
        date: [],
        entity_type: '',
        conversation_deal_status: '',
        conversation_type: '',
        duration: {
          unit: 'second',
          value: [0, 'unlimit']
        },
        max_silence_duration: [0, 'unlimit'],
        hang_up: 0,
        // 0无 1销售 2客户
        host_ids: [],
        hosts_includes_deleted_members: false,
        organization_tree_ids: [],
        account_ids: []
      };
      if (JSON.stringify(filterObj) !== JSON.stringify(initObj)) {
        return '筛选出的会话';
      } else {
        return '所有会话';
      }
    },
    hasBatchTransferPermission() {
      return this.api_permissions.includes('/api/conversation/conversation/list/workspace/transfer/batch');
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        const workspaceId = val.query.workspace_id;
        if (workspaceId !== undefined) {
          (0, _workspace.switchWorkspace)({
            id: parseInt(workspaceId)
          }).then(res => {
            const workspaceItem = res.results;
            this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
            sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
            this.$router.replace({
              path: _routeMap.ROUTES.conversation
            });
          });
        }
      },
      immediate: true
    },
    pageSize(val) {
      this.pageParams.size = val;
      this.pageParams.current_page = 1;
    },
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          this.params = this.$options.data().params;
          this.params.size = this.pageSize;
          this.pageParams.current_page = 1;
          this.params.workspace_id = val;
          this.scoreRuleId = Number(this.$route.query.score_rule_id);
          this.scoreRuleName = this.$route.query.score_rule_name;
          await this.getConversationListTableHead();
          if (this.scoreRuleId) {
            this.highlightColumn(this.scoreRuleId, this.scoreRuleName);
            window.history.pushState(null, null, this.$route.path);
          }
        }
      },
      immediate: true
    },
    webSocketMsg: {
      handler: function (newVal) {
        (0, _index.msgDispatcher)(newVal, 'conversation_completed', this.getList, this.params);
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        // 当有全局搜索参数传入时，第一次val还是默认值，过滤第一次请求
        if (val.event_engine.length === 0 && val.filter.length === 0 && JSON.stringify(this.$route.params) !== '{}') {
          return;
        }
        this.getList(val);
        if (this.$refs.cardView) {
          this.$refs.cardView.scrollTop = 0;
        } else {
          const tableDom = document.getElementsByClassName('el-table__body-wrapper');
          if (tableDom && tableDom[0]) {
            tableDom[0].scrollTop = 0;
          }
        }
      },
      deep: true
    },
    pageParams: {
      handler: function (val) {
        this.params.size = val.size;
        this.params.current_page = val.current_page;
      },
      deep: true
    },
    createConvFlowVisible(val) {
      if (!val) {
        this.$refs.conversationFilter.clearAll();
      }
    }
  },
  created() {
    // 获取质检配置
    this.pageParams.size = this.hasExtraPage ? this.pageSizes[0] : this.pageSize;
    this.getList();
  },
  mounted() {
    this.$bus.$on('fetchConData', this.fetchConData);
    this.$nextTick(() => {
      if (this.$refs['begin_time_sort']) {
        this.$refs['begin_time_sort'].activeStatus = 'descending';
      }
    });
    this.$bus.$on('uploadConversationSuccess', val => {
      this.list.unshift(val);
    });
  },
  beforeDestroy() {
    this.$bus.$off('fetchConData');
  },
  methods: {
    // 获取会话列表页面表头
    async getConversationListTableHead() {
      const res = await (0, _conversationList.getConversationListTableHead)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.conversationListTableHead = res.results.fields;
      }
    },
    activateColumn(id) {
      const columns = this.$el.querySelectorAll(`.column_${id}`);
      [...columns].forEach(column => {
        column.classList.add('is-active-column');
      });
      const commentHeader = this.$el.querySelector(`.column_${id}`);
      const commentHeaderLeft = commentHeader.getBoundingClientRect().left;
      const tableHeader = this.$el.querySelector('.conversation-list-container');
      tableHeader.scrollLeft = commentHeaderLeft - document.body.clientWidth / 2;
    },
    highlightColumn(id, name) {
      const currentColumn = this.conversationListTableHead.filter(item => item.field_type === 'score_rule').find(_item => _item.id === id);
      if (currentColumn) {
        this.$set(currentColumn, 'highlight', true);
        setTimeout(() => {
          this.activateColumn(currentColumn.id);
        }, 2000);
      } else {
        setTimeout(() => {
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            showClose: true,
            message: `<span>无${name}查看权限，请调整规则可见范围，回到<a style="color:#4461ec" href="${_routeMap.ROUTES.conversationScoreSetting}">会话评分规则</a></span>`
          });
        }, 3000);
      }
    },
    goScoreRuleSettingPage() {
      this.$router.push({
        path: _routeMap.ROUTES.conversationScoreSetting
      });
    },
    handleCommand(command) {
      switch (command) {
        case 'transferWorkspace':
          this.checkTransfer();
          break;
        case 'goConvRecycleBin':
          this.$router.push({
            path: _routeMap.ROUTES.convRecycleBin
          });
          break;
        case 'extractInfo':
          this.extractInfoSettingDrawerVisible = true;
          break;
        case 'automationProcess':
          this.automationProcessDrawerVisible = true;
          break;
      }
    },
    async checkTransfer() {
      const res = await (0, _conversationList.checkTransfer)();
      if (res.code === 20000) {
        this.transferConversationDialogVisible = true;
        this.transferConvCount = '获取中';
        const time = setInterval(() => {
          if (this.transferConvCount.length < 6) {
            this.transferConvCount += '.';
          } else {
            this.transferConvCount = '获取中';
          }
        }, 300);
        const results = await (0, _conversationList.getConversationListCount)(this.params);
        if (results.code === 200) {
          clearInterval(time);
          this.transferConvCount = results.results.count;
        }
      }
    },
    async transferConversation(targetWorkspaceId) {
      const data = {
        conver_filter: this.params,
        target_workspace_id: targetWorkspaceId
      };
      try {
        const res = await (0, _conversationList.batchTransferConversation)(data);
        if (res.code === 200) {
          this.$message({
            message: '转移已开始，请耐心等候，完成时消息通知',
            type: 'success'
          });
          this.$refs.transferConversationDialog.closeDialog();
        }
      } finally {
        this.$refs.transferConversationDialog.endButtonLoading();
      }
    },
    async fetchExportContent() {
      this.$confirm(`确定导出当前 ${this.resultsTotal} 条会话吗？`, '导出会话', {
        confirmButtonText: '导出',
        cancelButtonText: '取消'
      }).then(async () => {
        const params = (0, _commonFunc.deepClone)(this.params);
        (0, _timezone.convertParams2UTCplus8)(params.date);
        (0, _timezone.convertParams2UTCplus8)(params.custom_filters);
        const data = {
          module: 'conversation_list',
          body: params
        };
        const res = await (0, _export.exportConvList)(data);
        if (res.code === 20000) {
          if (this.$notify) {
            this.$notify.closeAll();
          }
          const h = this.$createElement;
          this.$notify({
            title: '导出文件',
            message: h('div', {
              class: 'notify-content'
            }, [h('span', '文件正在准备中，您可以在个人中心 - '), h('a', {
              class: 'jump-link',
              on: {
                click: this.goExportRecords
              }
            }, '导出记录'), h('span', '中查看。')]),
            duration: 5000,
            customClass: 'conversation-list-export-file-notify'
          });
        }
      }).catch(() => {});
    },
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    },
    goCurrentConvBelongDeal() {
      const otherPageCarryingFilter = {
        tradeIds: this.list.map(_ref => {
          let {
            trade_id
          } = _ref;
          return trade_id;
        }),
        label: '会话筛选条件'
      };
      sessionStorage.setItem('otherPageCarryingFilter', JSON.stringify(otherPageCarryingFilter));
      window.open(`${location.origin}${_routeMap.ROUTES.trade}`);
      sessionStorage.removeItem('otherPageCarryingFilter');
    },
    openEventKeyDrawer(params) {
      this.eventKeyDrawerVisible = true;
      this.convId = params.id;
      this.mediaType = params.conversationType;
      this.participants = params.participants;
      this.isKeyEventDetail = params.isKeyEventDetail;
      if (params.eventId) {
        this.eventId = params.eventId;
      }
    },
    goBack() {
      this.isKeyEventDetail = false;
    },
    handleTagClick() {
      this.isKeyEventDetail = true;
    },
    // 处理数据的方法
    handleList(list) {
      for (let i = 0; i < list.length; i++) {
        const arr = [];
        list[i].participants.map(item => {
          if (['host_salesman', ''].includes(item.entity_type)) {
            list[i].host = item;
          } else if (['assistant_salesman', 'spectator_salesman'].includes(item.entity_type)) {
            arr.push(item);
            list[i].others = arr;
          }
        });
        if (this.hasExtractInfo && list[i].instruction_set_result) {
          list[i].instruction_set_result = list[i].instruction_set_result.map(item => `${item.question_name ? item.question_name + ': ' : ''}${item.llm_answer}`);
        } else {
          list[i].instruction_set_result = [];
        }
      }
    },
    fetchConData() {
      this.getList(this.params);
    },
    // 打开上传弹窗
    openUploadDialog() {
      this.$bus.$emit('openUploadMedia');
    },
    changeUploadDialogFlag() {
      this.uploadFlag = false;
    },
    getList: (0, _commonFunc.debounce)(function () {
      if (!this.params.workspace_id) return;
      if ((0, _isEqual.default)(this.params, this.previousParams)) return;
      this.previousParams = JSON.parse(JSON.stringify(this.params));
      const params = (0, _commonFunc.deepClone)(this.params);
      if (this.params.date.range.length) {
        params.date.range = (0, _timezone.datePickerParseToZone)(params.date.range);
      }
      (0, _timezone.convertParams2UTCplus8)(params.custom_filters);
      params.time_zone = this.timezone;
      this.listLoading = true;
      this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', true);
      this.getEchartsData(params);
      // 取消上一次请求
      this.axiosCancelRequest();
      // 使用新的cancelToken
      this.newCancelToken();
      (0, _conversationList.getConversationList)(params, {
        cancelToken: this.axiosCancelToken
      }).then(response => {
        const res = response.results;
        this.list = res.items;
        this.hasSummary = res.has_summary;
        this.hasExtractInfo = res.has_instruction_set_result;
        this.$emit('conversationListChange', res.items);
        this.handleList(this.list);
        this.resultsTotal = res.total_count;
        this.$emit('convTotalCount', res.total_count);
        this.listLoading = false;
        this.isNone = this.list.length ? 'false' : 'true';
        _performanceTracking.default.end();
      }).catch(err => {
        // 临时方案：筛选器失效后的搜索结果呈现。后期需要变
        if (err.message === '由于系统更新，旧版关键事件已失效，请重新选择') {
          this.list = [];
          this.resultsTotal = 0;
          this.isNone = 'true';
          this.listLoading = false;
        }
      }).finally(() => {
        this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', false);
      });
    }, 300),
    getEchartsData(params) {
      // 取消上一次请求
      this.axiosCancelRequest();
      // 使用新的cancelToken
      this.newCancelToken();
      (0, _conversationList.getEchartsData)(params).then(res => {
        if (res.code === 200) {
          const resArray = res.results;
          const dateArray = [];
          const numberArray = [];
          for (const itemObj of resArray) {
            const key = Object.keys(itemObj)[0];
            dateArray.push(this.$moment(key).endOf('week').format('YYYY-MM-DD'));
            numberArray.push(itemObj[key]);
          }
          this.chartData = {
            dateArray,
            numberArray
          };
        }
      });
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.pageParams.current_page = 1;
      if (!this.hasExtraPage) {
        this.$store.dispatch('user/updatePageSize', val);
      }
    },
    handleCurrentChange(currentPage) {
      if (this.pageParams.size * currentPage > 10000) {
        this.pageParams.current_page = 10000 / this.pageParams.size;
      } else {
        this.pageParams.current_page = currentPage;
      }
    },
    filterChange(filters) {
      this.$emit('convFlowFiltersChange', filters);
      const {
        ...filterObj
      } = filters;
      for (const filterKey in filterObj) {
        if (Object.prototype.hasOwnProperty.call(this.params, filterKey)) {
          this.params[filterKey] = filterObj[filterKey];
        }
      }
      for (const pageKey in this.pageParams) {
        if (Object.prototype.hasOwnProperty.call(this.params, pageKey)) {
          this.params[pageKey] = this.pageParams[pageKey];
        }
      }
      this.initOtherParams();
    },
    initOtherParams() {
      this.pageParams.current_page = 1;
    },
    selectView(command) {
      this.viewType = command;
      if (command === 'table') {
        setTimeout(() => {
          if (this.$refs['listTable'] && this.$refs['listTable'].$refs['table']) {
            this.$refs['listTable'].$refs['table'].sort(this.params.sort_by, this.params.sort_order);
          }
        }, 100);
      } else {
        setTimeout(() => {
          if (this.$refs[this.params.sort_by + '_sort']) {
            this.$refs[this.params.sort_by + '_sort'].activeStatus = this.params.sort_order;
          }
        }, 100);
      }
    },
    sortData(sort_by, sort_order) {
      if (!sort_order) {
        this.params.sort_by = '';
      } else {
        this.params.sort_by = sort_by;
      }
      this.params.sort_order = sort_order;
      this.sortInitOther(sort_by);
      this.pageParams.current_page = 1;
    },
    sortInitOther(sort_by) {
      const scoreList = [];
      this.complianceScoreHead.map(item => {
        scoreList.push(`compliance_score_${item.id}`);
      });
      this.conversationScoreHead.map(item => {
        scoreList.push(`score_rule_${item.id}`);
      });
      const refArray = ['begin_time', 'duration', 'event_engine_count', 'host_id'].concat(scoreList);
      refArray.forEach(item => {
        if (item !== sort_by) {
          const refs = this.$refs[item + '_sort'];
          (Array.isArray(refs) ? refs[0] : refs).init();
        }
      });
    },
    scrollList(height) {
      this.$refs['cardView'].scroll({
        top: height,
        // 向上移动的距离，如果有fixed的布局， 直接减去对应距离即可
        behavior: 'smooth' // 平滑移动
      });
    },

    goTimeDetail(id, order, keyword) {
      window.open(encodeURI(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&order=${order}&keywords=${keyword}&noback=true`));
    },
    openFilterManage() {
      this.filterManageVisible = true;
    },
    getHasSummary() {
      return this.hasSummary;
    },
    getHasExtractInfo() {
      return this.hasExtractInfo;
    }
  }
};
exports.default = _default;