"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LableRequireTooltipRemark = _interopRequireDefault(require("@/views/businessIntelligence/components/portraitConfigComponents/LableRequireTooltipRemark"));
var _pieIcon = _interopRequireDefault(require("@/assets/BI/pie-icon.svg"));
var _pieDoughnutIcon = _interopRequireDefault(require("@/assets/BI/pie-doughnut-icon.svg"));
var _barIcon = _interopRequireDefault(require("@/assets/BI/bar-icon.svg"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChartConfig',
  components: {
    LableRequireTooltipRemark: _LableRequireTooltipRemark.default
  },
  props: {
    chartConfigVisible: {
      type: Boolean,
      default: false
    },
    chartConfigLoading: {
      type: Boolean,
      default: false
    },
    isEditChart: {
      type: Boolean,
      default: false
    },
    eventOptions: {
      type: Array,
      default: () => []
    },
    metricsTypeList: {
      type: Array,
      default: () => []
    },
    customFiled: {
      type: Array,
      default: () => []
    },
    kanbanId: {
      type: Number,
      default: null
    },
    chartId: {
      type: Number,
      default: null
    },
    accountLabelOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      submitLoading: false,
      chartTypeList: [{
        imgSrc: _pieIcon.default,
        label: '饼图',
        type: 'pie',
        tooltip: ['用于强调各项数据占总体的占比，强调个体和整体的比较', '建议类别不超过10个']
      }, {
        imgSrc: _barIcon.default,
        label: '条形图',
        type: 'bar',
        tooltip: ['用于比较各组数据的差异性，强调进行个体间的比较']
      }, {
        imgSrc: _pieDoughnutIcon.default,
        label: '环形图',
        type: 'doughnut',
        tooltip: ['用于强调各项数据占总体的占比，强调个体和整体的比较', '建议类别不超过10个']
      }],
      chartConfig: {
        chart_name: '',
        type: 'pie',
        field: {
          type: '',
          indicator: ''
        }
      },
      resetCascaderKey: Symbol() // 级联选择器切换options时，改变key值重新渲染
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  methods: {
    cascaderOptions(type) {
      return type === 'event' ? this.eventOptions : this.accountLabelOptions;
    },
    handleClose() {
      this.chartConfig = this.$options.data().chartConfig;
      this.$refs['chartConfigForm'].resetFields();
      this.$emit('update:chartConfigVisible', false);
    },
    changeChartType(type) {
      this.chartConfig.type = type;
    },
    changeChartConfigType() {
      this.chartConfig.field.indicator = '';
      this.resetCascaderKey = Symbol();
    },
    submit() {
      this.$refs['chartConfigForm'].validate(async valid => {
        if (valid) {
          const requestFn = this.isEditChart ? _businessIntelligence.editCustomerBaseProfileChartConfig : _businessIntelligence.createCustomerBaseProfileChartConfig;
          let params = this.isEditChart ? {
            chart_id: this.chartId
          } : {
            kanban_id: this.kanbanId
          };
          params = {
            ...params,
            ...this.chartConfig,
            workspace_id: this.workspaceInfo.id
          };
          try {
            this.submitLoading = true;
            const res = await requestFn(params);
            res.code === 200 && this.$message.success(this.isEditChart ? '编辑成功' : '新建成功');
            this.$emit('handleChartSuccess');
            this.handleClose();
          } catch (error) {
            throw new Error(error);
          } finally {
            this.submitLoading = false;
          }
        }
      });
    }
  }
};
exports.default = _default;