import { render, staticRenderFns } from "./businessAnalysis.vue?vue&type=template&id=281f5082&scoped=true&"
import script from "./businessAnalysis.vue?vue&type=script&lang=js&"
export * from "./businessAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./businessAnalysis.vue?vue&type=style&index=0&id=281f5082&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281f5082",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('281f5082')) {
      api.createRecord('281f5082', component.options)
    } else {
      api.reload('281f5082', component.options)
    }
    module.hot.accept("./businessAnalysis.vue?vue&type=template&id=281f5082&scoped=true&", function () {
      api.rerender('281f5082', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessIntelligence/businessAnalysis.vue"
export default component.exports