"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-content"
  }, [_c("div", {
    ref: "cardContainer",
    staticClass: "left-container"
  }, [_c("div", {
    staticClass: "container"
  }, [_vm.knowledge.status !== 1 ? _c("div", {
    staticClass: "top-part"
  }, [_c("span", {
    staticClass: "bold-text",
    domProps: {
      innerHTML: _vm._s(_vm.practiceRule)
    }
  }), _c("div", {
    staticClass: "test-record"
  }, [_c("audio-recorder", {
    attrs: {
      "is-test": ""
    }
  })], 1)]) : _vm._e(), _c("TaskPracticeCard", {
    ref: "taskPracticeCard",
    attrs: {
      list: _vm.knowledge.scene_reply_list,
      disabled: _vm.knowledge.status === 1,
      "course-type": _vm.courseType,
      "practice-count": _vm.knowledge.repeat_count,
      "lowest-score": Number(_vm.lowestScore)
    },
    on: {
      scrollToIncompleteCard: _vm.scrollToIncompleteCard,
      stopRecord: _vm.stopRecord,
      scoreFinished: _vm.scoreFinished
    }
  })], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "toggle-question"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      plain: "",
      disabled: !_vm.knowledge.last_id
    },
    on: {
      click: _vm.jumpLastKnowledge
    }
  }, [_c("i", {
    staticClass: "iconfont icon-back"
  }), _vm._v("上一题")]), _vm.knowledge.next_id ? _c("el-button", {
    staticClass: "next-button",
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: function ($event) {
        return _vm.jumpNextKnowledge("next");
      }
    }
  }, [_vm._v(" 下一题"), _c("i", {
    staticClass: "iconfont icon-right"
  })]) : _c("el-button", {
    staticClass: "finish",
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.jumpNextKnowledge("done");
      }
    }
  }, [_vm._v(" 完成练习 ")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;