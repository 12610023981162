"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-media-file-head"
  }, [_c("div", {
    staticClass: "title-content"
  }, [_c("div", {
    staticClass: "title-name"
  }, [_vm._v("上传音视频")]), _c("div", {
    staticClass: "title-support-name upload-require"
  }, [_vm._v("上传要求")]), _c("el-tooltip", {
    staticClass: "format-support",
    attrs: {
      "popper-class": "upload-media-file-support-tooltip",
      effect: "dark",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, _vm._l(_vm.tooltipList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "content-row"
    }, [_c("div", {
      staticClass: "index"
    }, [_vm._v(".")]), _c("span", [_vm._v(_vm._s(item))])]);
  }), 0), _c("span", {
    staticClass: "icon-container"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])]), _c("div", {
    staticClass: "split-line"
  }), _vm.uploadHostSalesmanInfo && _vm.uploadHostSalesmanInfo.id ? [_c("div", {
    staticClass: "upload-duration"
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.uploadHostSalesmanInfo.name
    }
  }, [_c("span", {
    staticClass: "host-salesman-name"
  }, [_vm._v(_vm._s(_vm.uploadHostSalesmanInfo.name))])]), _c("span", [_vm._v("本月上传用量剩余 " + _vm._s(_vm.handleMinutes(_vm.maxDuration - _vm.usedDuration)))]), _c("span", [_vm._v("·")]), _c("span", [_vm._v("上限 " + _vm._s(_vm.handleMinutes(_vm.maxDuration)))])], 1), _c("div", {
    staticClass: "split-line"
  }), _c("div", {
    staticClass: "org-useage-info"
  }, [_vm._v(" " + _vm._s(_vm.orgMaxDuration === -1 ? "企业本月上传用量无上限" : `企业本月上传用量剩余${_vm.handleMinutes(_vm.orgMaxDuration - _vm.orgUsedDuration)}`) + " ")])] : [_c("div", {
    staticClass: "no-host-salesman"
  }, [_vm._v("选择归属人后查看该归属人本月上传用量")])]], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;