"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeleteRapidModel = useDeleteRapidModel;
exports.usePublishModel = usePublishModel;
exports.useRapidModel = useRapidModel;
exports.useTrainModel = useTrainModel;
exports.useTrainTask = useTrainTask;
var _elementUi = require("element-ui");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _modelManagement = require("@/api/modelManagement");
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _useMessage = require("@/hooks/use-message");
function useRapidModel(rapidModelInfo, modelInfo, setModelInfo, goStep) {
  const rapidModelFormRef = (0, _vue.ref)(null);
  const createModelBtnLoading = (0, _vue.ref)(false);
  const createRapidModelApi = async val => {
    const res = await (0, _rapidCreateModel.createRapidModel)({
      name: rapidModelInfo.value.name,
      description: rapidModelInfo.value.description
    });
    if (res.code === 20000) {
      console.log('创建快速建模成功');
      setModelInfo({
        id: res.results.id,
        name: rapidModelInfo.value.name,
        description: rapidModelInfo.value.description
      });
      _elementUi.Message.success('创建成功');
      const url = `${_routeMap.ROUTES.RapidModeling}?modelId=${res.results.id}`;
      window.history.pushState('', '', url);
      goStep(2);
    }
  };
  const editRapidModelApi = async () => {
    const res = await (0, _rapidCreateModel.editRapidModel)({
      id: modelInfo.id,
      name: rapidModelInfo.value.name,
      description: rapidModelInfo.value.description
    });
    if (res.code === 20000) {
      setModelInfo({
        name: rapidModelInfo.value.name,
        description: rapidModelInfo.value.description
      });
      _elementUi.Message.success('编辑成功');
      goStep(2);
    }
  };
  const nextStep = async () => {
    const formValid = await rapidModelFormRef.value.rapidModelFormValidate();
    if (!formValid) return;
    createModelBtnLoading.value = true;
    try {
      modelInfo.id ? editRapidModelApi() : createRapidModelApi();
    } finally {
      createModelBtnLoading.value = false;
    }
  };
  return {
    rapidModelFormRef,
    nextStep,
    createModelBtnLoading
  };
}
function useTrainTask(modelInfo, goStep, setModelInfo) {
  const modelSentenceRef = (0, _vue.ref)(null);
  const trainTaskBtnLoading = (0, _vue.ref)(false);
  const createTrainTaskApi = async () => {
    const {
      exampleType,
      modelSentenceFormData: {
        workspace_ids,
        sentences
      }
    } = modelSentenceRef.value.getModelSentenceData();
    const leastOneHasValue = sentences.some(item => {
      return item.every(_item => _item.content);
    });
    if (!leastOneHasValue) {
      _elementUi.Message.warning('至少一项有内容');
      return;
    }
    const examples = sentences.filter(sentence => sentence.every(_item => _item.content));
    if (!examples.length) {
      _elementUi.Message.warning('例句数量范围为1-100');
      return;
    }
    if (!(workspace_ids && workspace_ids.length)) {
      _elementUi.Message.warning('至少选择一个空间');
      return;
    }
    trainTaskBtnLoading.value = true;
    const res = await (0, _rapidCreateModel.createTrainTask)({
      model_id: modelInfo.id,
      workspace_ids,
      examples,
      example_type: exampleType
    }).finally(() => trainTaskBtnLoading.value = false);
    if (res.code === 20000) {
      goStep(3);
      setModelInfo({
        example_type: exampleType
      });
      for (const key in sessionStorage) {
        if (key.includes(`quick-model-${modelInfo.id}-example-type`)) {
          sessionStorage.removeItem(key);
        }
      }
      setModelInfo({
        sentences: {}
      });
    }
  };
  return {
    modelSentenceRef,
    createTrainTaskApi,
    trainTaskBtnLoading
  };
}
function useDeleteRapidModel(_ref) {
  let {
    modelInfo,
    resetModelInfo,
    goStep
  } = _ref;
  const cancelRapidModel = async () => {
    _elementUi.MessageBox.confirm(`确定删除模型${modelInfo.name}的所有版本吗？</br> 如果模型已发布到关键事件，模型删除后，关键事件无模型将被置为禁用状态`, '删除模型', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning',
      confirmButtonClass: 'el-button--danger'
    }).then(async () => {
      const res = await (0, _modelManagement.deleteModel)({
        id: modelInfo.id
      });
      if (res.code === 20000) {
        resetModelInfo();
        goStep(1);
        _elementUi.Message.success('删除成功');
        window.history.pushState('', '', _routeMap.ROUTES.RapidModeling);
      }
    }).catch(e => {
      console.log(e);
    });
  };
  return {
    cancelRapidModel
  };
}
function useTrainModel(_ref2) {
  let {
    modelInfo,
    setModelInfo,
    goStep
  } = _ref2;
  const superParamsSetDrawerVisible = (0, _vue.ref)(false);
  const validTrainDataRef = (0, _vue.ref)(null);
  const openParamsSetDrawer = () => {
    // if (validTrainDataRef.value?.getPositiveData() < 100) {
    //   useMessage.warning('正样本数不足100条，请补充正样本或考虑换用规则模型');
    //   return;
    // }
    superParamsSetDrawerVisible.value = true;
  };
  const superParamsSetDrawerRef = (0, _vue.ref)(null);
  const createTrainModelTask = async _ref3 => {
    let {
      train_params,
      context_scene,
      postprocess_type,
      train_param_mode,
      train_datasets,
      trial_datasets,
      dataset_split_type,
      prompt_info,
      postprocess_llm_enable
    } = _ref3;
    superParamsSetDrawerRef.value.handleBtnLoading(true);
    const params = {
      model_id: modelInfo.id,
      context_scene,
      postprocess_type,
      train_params,
      train_param_mode,
      train_datasets,
      trial_datasets,
      dataset_split_type,
      prompt_info,
      postprocess_llm_enable
    };
    const res = await (0, _rapidCreateModel.modelCreateTrainTaskApi)(params).finally(() => {
      superParamsSetDrawerRef.value.initDrawer();
      superParamsSetDrawerRef.value.handleBtnLoading(false);
      superParamsSetDrawerVisible.value = false;
    });
    if (res.code === 20000) {
      setModelInfo({
        version: res.results.version
      });
      goStep(4);
    }
  };
  return {
    superParamsSetDrawerVisible,
    openParamsSetDrawer,
    superParamsSetDrawerRef,
    createTrainModelTask,
    validTrainDataRef
  };
}
function usePublishModel(_ref4) {
  let {
    modelInfo,
    resetModelInfo
  } = _ref4;
  const trainAndPublishRef = (0, _vue.ref)(null);
  const router = (0, _useRouter.useRouter)();
  const publish = async isEnable => {
    const valid = await trainAndPublishRef.value.validateForm();
    if (valid) {
      const form = trainAndPublishRef.value.getForm();
      const params = {
        ...form,
        id: modelInfo.id,
        is_enable: !!isEnable
      };
      const res = await (0, _modelManagement.publishModel)(params);
      if (res.code === 20000) {
        resetModelInfo();
        _elementUi.Message.success('发布成功');
        router.replace(_routeMap.ROUTES.ModelManagement);
      }
    }
  };
  return {
    trainAndPublishRef,
    publish
  };
}