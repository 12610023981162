"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String
  },
  data() {
    return {
      newValue: []
    };
  },
  computed: {
    noEditContent() {
      var _this$options$find;
      return (_this$options$find = this.options.find(item => item.value === this.newValue[0])) === null || _this$options$find === void 0 ? void 0 : _this$options$find.label;
    }
  },
  watch: {
    value: {
      handler: function (value) {
        if (Array.isArray(value)) {
          this.newValue = value;
        } else {
          this.newValue = [value] || [];
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange(value) {
      const val = value ? [value] : [];
      this.$emit('input', val);
      this.$emit('change', val);
    }
  }
};
exports.default = _default;