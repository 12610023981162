"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OpenNumberDesensibilisation = _interopRequireDefault(require("./components/OpenNumberDesensibilisation"));
var _ChooseRoleDesemsibilisation = _interopRequireDefault(require("./components/ChooseRoleDesemsibilisation"));
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'NumberDesensitization',
  components: {
    OpenNumberDesensibilisation: _OpenNumberDesensibilisation.default,
    ChooseRoleDesemsibilisation: _ChooseRoleDesemsibilisation.default
  },
  data() {
    return {
      hasDesensitizationRoleList: [],
      isDesensitizationOpen: false
    };
  },
  created() {
    this.getDesensitizationInfo();
  },
  methods: {
    fetchRoleDesensitizationSuccess() {
      this.getDesensitizationInfo();
    },
    async getDesensitizationInfo() {
      const res = await (0, _organization.getDesensitizationInfo)();
      if (res.code === 200) {
        this.hasDesensitizationRoleList = res.results.role_list;
        this.isDesensitizationOpen = Boolean(res.results.status);
      }
    }
  }
};
exports.default = _default;