"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "lead"
  }, [_c("div", {
    staticClass: "page-title"
  }, [_vm._v("线索")]), _c("div", {
    staticClass: "lead-content"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-row"
  }, [_c("header-filters", {
    ref: "clueFilter",
    attrs: {
      "clue-multiple-val": _vm.clueMultipleVal
    },
    on: {
      change: _vm.headerFiltersChange,
      freshList: _vm.freshCurrentList,
      createClueSuccess: _vm.createClueSuccess,
      changeFollowerAction: _vm.changeFollowerAction
    }
  })], 1)]), _c("section", {
    staticClass: "dynamic-table-head"
  }, [_c("dynamic-popover", {
    attrs: {
      type: "clue",
      "fetch-table-head-method": _vm.getClueTableHead(),
      "reset-head-data-method": _vm.resetClueHead()
    },
    on: {
      getTableHead: _vm.getTableHead
    }
  })], 1), _c("clue-table", {
    ref: "clueTable",
    attrs: {
      "header-list": _vm.headerList,
      list: _vm.list,
      "table-loading": _vm.tableLoading,
      "double-call-permission": _vm.doubleCallPermission,
      "sort-column-list": _vm.sortColumnList
    },
    on: {
      getClueList: _vm.getClueLists,
      freshCurrentList: _vm.freshCurrentList,
      clueSelectionChange: _vm.clueSelectionChange,
      changeFollowUpBy: _vm.allocateToAnyone,
      clueTranslateAccountSuccess: _vm.clueTranslateAccountSuccess,
      rowClick: _vm.goClueDetail,
      handleSortOrder: _vm.handleSortOrder
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    class: _vm.total >= 10000 ? "hide-last-page" : "",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.listParams.page_at,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.listParams.page_size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  })], 1), _c("clues-allot-dialog", _vm._b({
    attrs: {
      "dialog-visible": _vm.allotDialogVisible
    },
    on: {
      closeDialog: function ($event) {
        _vm.allotDialogVisible = false;
      },
      refreshFollower: _vm.refreshFollowerData
    }
  }, "clues-allot-dialog", _vm.allotDialogProps, false))], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;