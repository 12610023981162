"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mobile-guide"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("i", {
    staticClass: "iconfont icon-megaview"
  }), _c("p", [_vm._v("请在电脑端的企业微信使用Megaview")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;