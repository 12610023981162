"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'AddItem',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    hasPopConfirm: {
      type: Boolean,
      default: false
    },
    checkEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    deleteItem(index) {
      this.$emit('delete', index);
    },
    addItem() {
      this.$emit('add');
    }
  }
};
exports.default = _default;