"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SnippetVideoDialog = _interopRequireDefault(require("./SnippetVideoDialog"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetVideoButton',
  components: {
    SnippetVideoDialog: _SnippetVideoDialog.default
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    conversationId: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: ''
    },
    cancelType: {
      type: String,
      default: ''
    },
    mediaType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      snippetVideoDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['snippetCheckList'])
  },
  methods: {
    handleCancelSnippet() {
      this.$emit('cancelSnippet');
    },
    snippetWordSuccess() {
      this.handleCancelSnippet();
    },
    handleSaveSnippet() {
      if (this.snippetCheckList.length < 2) {
        this.$message.warning('请选取两个节点');
      } else {
        this.snippetVideoDialogVisible = true;
      }
    },
    handleCloseSnippetVideoDialog() {
      this.snippetVideoDialogVisible = false;
    }
  }
};
exports.default = _default;