"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'NewCoaching',
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes('/coaching/')) {
      for (const key in sessionStorage) {
        // 如果键名以指定前缀开头，则移除该键
        if (key.startsWith('coachingTaskListParamsBy')) {
          sessionStorage.removeItem(key);
        }
      }
    }
    next();
  },
  data() {
    return {
      menuList: [],
      isHiddenMenu: false,
      singleStep: [{
        content: this.$t('coaching.taskAllocationTour'),
        target: '[data-v-name="CoachTask"]',
        placement: 'right'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree']),
    defaultOpeneds() {
      const openedMenu = [];
      const traverseMenuList = node => {
        node.forEach(_ref => {
          let {
            link,
            children
          } = _ref;
          openedMenu.push(link);
          if (children) {
            traverseMenuList(children);
          }
        });
      };
      traverseMenuList(this.menuList);
      return openedMenu;
    }
  },
  created() {
    this.getMenuList();
  },
  methods: {
    floatingMenuHidden() {
      var _this$$refs$navMenu;
      (_this$$refs$navMenu = this.$refs['nav-menu']) === null || _this$$refs$navMenu === void 0 ? void 0 : _this$$refs$navMenu.floatingMenuHidden();
    },
    // 在课程页面首次新建任务成功后展示引导
    showMyTour() {
      this.$refs['taskManagementGuide'].start();
    },
    recursionGet: _commonFunc.recursionGet,
    updateIsHiddenMenu(val) {
      this.isHiddenMenu = val;
    },
    getMenuList() {
      var _this$menuList, _this$menuList$find, _this$menuList$find$c, _this$menuList$find$c2, _this$menuList2, _this$menuList2$find, _this$menuList2$find$, _this$menuList2$find$2, _this$menuList3, _this$menuList3$find, _this$menuList3$find$, _this$menuList3$find$2;
      const routesTree = (0, _commonFunc.deepClone)(this.routesTree);
      const showRouteName = ['CoachingCoach', 'CoachingTutor', 'CoachingKnowledge'];
      // 当routesTree为空数组时res=null, 当父节点下无子节点时应该不展示父节点
      this.menuList = (this.recursionGet({
        recurArr: routesTree,
        targetValue: 'Coaching',
        targetAttr: 'name',
        getAttr: 'children'
      }) || []).filter(_ref2 => {
        let {
          name,
          children
        } = _ref2;
        return showRouteName.includes(name) && children;
      });
      // 删除任务详情页面路由：我分配的和团队视图
      (_this$menuList = this.menuList) === null || _this$menuList === void 0 ? true : (_this$menuList$find = _this$menuList.find(_ref3 => {
        let {
          name
        } = _ref3;
        return name === 'CoachingCoach';
      })) === null || _this$menuList$find === void 0 ? true : (_this$menuList$find$c = _this$menuList$find.children) === null || _this$menuList$find$c === void 0 ? true : (_this$menuList$find$c2 = _this$menuList$find$c.find(_ref4 => {
        let {
          name
        } = _ref4;
        return name === 'CoachTask';
      })) === null || _this$menuList$find$c2 === void 0 ? true : delete _this$menuList$find$c2.children;
      // 删除数据分析children
      (_this$menuList2 = this.menuList) === null || _this$menuList2 === void 0 ? true : (_this$menuList2$find = _this$menuList2.find(_ref5 => {
        let {
          name
        } = _ref5;
        return name === 'CoachingCoach';
      })) === null || _this$menuList2$find === void 0 ? true : (_this$menuList2$find$ = _this$menuList2$find.children) === null || _this$menuList2$find$ === void 0 ? true : (_this$menuList2$find$2 = _this$menuList2$find$.find(_ref6 => {
        let {
          name
        } = _ref6;
        return name === 'CoachDataAnalysis';
      })) === null || _this$menuList2$find$2 === void 0 ? true : delete _this$menuList2$find$2.children;

      // 删除知识库下知识库和知识点挖掘引擎
      (_this$menuList3 = this.menuList) === null || _this$menuList3 === void 0 ? true : (_this$menuList3$find = _this$menuList3.find(_ref7 => {
        let {
          name
        } = _ref7;
        return name === 'CoachingKnowledge';
      })) === null || _this$menuList3$find === void 0 ? true : (_this$menuList3$find$ = _this$menuList3$find.children) === null || _this$menuList3$find$ === void 0 ? true : (_this$menuList3$find$2 = _this$menuList3$find$.find(_ref8 => {
        let {
          name
        } = _ref8;
        return name === 'KnowledgeScript';
      })) === null || _this$menuList3$find$2 === void 0 ? true : delete _this$menuList3$find$2.children;
      this.sortMenu(this.menuList);

      // redirect router
      this.redirectRouter();
    },
    sortMenu(list) {
      list.sort((a, b) => a.meta.order - b.meta.order);
      list.forEach(_ref9 => {
        let {
          children
        } = _ref9;
        if (children) {
          this.sortMenu(children);
        }
      });
    },
    redirectRouter() {
      const getFirstLeafNode = node => {
        return node.children ? getFirstLeafNode(node.children[0]) : node.link;
      };
      const firstLeafNode = getFirstLeafNode(this.menuList[0]);
      const categoryNodes = [_routeMap.ROUTES.coach, _routeMap.ROUTES.tutor, _routeMap.ROUTES.knowledge];
      if (categoryNodes.includes(this.$route.path)) {
        this.$router.replace(firstLeafNode);
      }
    }
  }
};
exports.default = _default;