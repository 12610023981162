"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'FormItemHeaderEditable',
  props: {
    name: {
      type: String,
      default: '未命名评价单'
    },
    description: {
      type: String,
      default: ''
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:name', 'update:description'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateName = value => {
      emit('update:name', value);
    };
    const updateDescription = value => {
      emit('update:description', value);
    };
    const tempText = (0, _vue.ref)('');
    const handleFocus = e => {
      console.log(e.target.value);
      tempText.value = e.target.value;
    };
    const handleNameBlur = e => {
      if (!e.target.value) {
        updateName(tempText.value);
      }
    };
    const handleDescriptionBlur = e => {
      if (!e.target.value) {
        updateDescription(tempText.value);
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateName,
      updateDescription,
      tempText,
      handleFocus,
      handleNameBlur,
      handleDescriptionBlur
    };
  }
};
exports.default = _default;