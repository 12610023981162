"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "customer-business-dialog-wrapper",
    attrs: {
      title: "新建商机",
      visible: _vm.businessDialogVisible,
      "before-close": _vm.closeDialog,
      "custom-class": "business-dialog",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.businessDialogVisible = $event;
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm();
      }
    }
  }, [_c("div", {
    staticClass: "business-title"
  }, [_vm._v("商机信息")]), _c("dynamic-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "dynamicForm",
    attrs: {
      desc: _vm.formDesc,
      "is-edit": _vm.isEdit,
      "label-position": "top"
    },
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }), _c("div", [_c("div", {
    staticClass: "associate-contact"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.isAssociateContact,
      callback: function ($$v) {
        _vm.isAssociateContact = $$v;
      },
      expression: "isAssociateContact"
    }
  }, [_vm._v("同时关联联系人")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAssociateContact,
      expression: "isAssociateContact"
    }]
  }, [_c("AssociateContactForm", {
    ref: "associateContactForm",
    attrs: {
      "contact-list": _vm.contactList
    }
  })], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;