"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["app", "mgv-scroll-bar", {
      "iframe-page": _vm.isIframe
    }],
    attrs: {
      id: "app"
    }
  }, [_c("router-view"), _vm.isShowOutbound ? _c("div", {
    directives: [{
      name: "drag",
      rawName: "v-drag"
    }],
    staticClass: "call-wrapper"
  }, [_c("one-stop-call")], 1) : _vm._e(), _vm.isShowOutbound && !_vm.callIsCloseNote ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.callNoteVisible,
      expression: "callNoteVisible"
    }],
    staticClass: "call-note-wrapper"
  }, [_c("call-note", {
    staticClass: "call-note",
    attrs: {
      title: "销售助手",
      conversation_id: _vm.callConverId,
      time: _vm.callNoteTime,
      "customer-name": _vm.callCustomerName
    }
  })], 1) : _vm._e(), _c("upload-dialog", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadFlag,
      expression: "uploadFlag"
    }],
    attrs: {
      "upload-flag": _vm.uploadFlag
    },
    on: {
      changeUploadDialogFlag: _vm.changeUploadDialogFlag,
      openUploadDialog: _vm.openUploadMedia
    }
  }), _c("batch-upload"), _vm.globalUploadFileList && _vm.globalUploadFileList.length ? _c("GlobalUploadFile") : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;