"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "comment-input"
  }, [_c("div", {
    ref: "input__inner",
    staticClass: "comment-input__inner",
    attrs: {
      contenteditable: !_setup.props.disabled,
      "data-placeholder": _vm.placeholder
    },
    on: {
      focus: _setup.handleFocus,
      input: _setup.handleInput,
      keypress: _setup.onKeyPress
    }
  }), _c(_setup.PicUploader, {
    ref: "picRef",
    attrs: {
      disabled: _vm.disabled
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;