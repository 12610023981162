"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationBaseFilters = _interopRequireDefault(require("@/views/conversationPage/conversationFiltersForm/components/ConversationBaseFilters.vue"));
var _TimeRangeFilter = _interopRequireDefault(require("@/views/conversationPage/components/TimeRangeFilter.vue"));
var _ConvDate = _interopRequireDefault(require("@/views/conversationPage/components/ConvDate.vue"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationFilter',
  components: {
    ConversationBaseFilters: _ConversationBaseFilters.default,
    TimeRangeFilter: _TimeRangeFilter.default,
    ConvDate: _ConvDate.default
  },
  data() {
    return {
      filters: {
        host_ids: [],
        organization_tree_ids: [],
        account_ids: [],
        deal_name: {
          value: [],
          is_batch: false
        },
        duration: {
          unit: 'second',
          value: [0, 'unlimit']
        },
        date: {
          range: [],
          tag: ''
        }
      }
    };
  },
  watch: {
    filters: {
      deep: true,
      handler: (0, _commonFunc.debounce)(function (val) {
        this.$emit('filterChange', val);
      }, 100)
    }
  },
  methods: {
    init() {
      for (const key in this.filters) {
        this.$set(this.filters, key, this.$options.data().filters[key]);
      }
      const conversationBaseFilters = this.$refs['conversation-base-filters'];
      const departmentRef = conversationBaseFilters.$refs['mgvDepartmentMultiSelectRef'];
      if (departmentRef) {
        departmentRef.selectionList = [];
      }
      const memberFinderRef = conversationBaseFilters.$refs['memberFinderRef'];
      if (memberFinderRef) {
        memberFinderRef.selection = [];
      }
    },
    selectConversationTime(val) {
      this.filters.duration.value = val;
    }
  }
};
exports.default = _default;