"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "lazy-tree"
  }, [_c("el-tree", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.treeLoading,
      expression: "treeLoading"
    }],
    ref: "tree",
    attrs: {
      data: _vm.tree,
      lazy: "",
      props: _vm.treeProps,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "default-checked-keys": _vm.defaultCheckedKeys,
      "show-checkbox": "",
      "check-on-click-node": "",
      "expand-on-click-node": false,
      "check-strictly": true,
      "node-key": "id"
    },
    on: {
      check: _vm.handleCheck,
      "check-change": _vm.handleCheckChange,
      "node-expand": _vm.handleNodeExpand,
      "node-collapse": _vm.handleNodeCollapse
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node",
          attrs: {
            id: data.id
          }
        }, [_vm.isDepartment(data.id) ? _c("svg-icon", {
          attrs: {
            "icon-class": data.is_root ? "office-building" : "department"
          }
        }) : _c("image-avatar", {
          attrs: {
            size: "small",
            avatar: data.avatar,
            name: data.name
          }
        }), _c("span", [_vm._v(_vm._s(data.name))]), _c("MgvTag", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isShowHaveDeletedMembers && data.is_delete,
            expression: "isShowHaveDeletedMembers && data.is_delete"
          }]
        }, [_vm._v("已离职")])], 1)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;