"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _user = require("@/api/user");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberLazyTree',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    setCheckedRootNode: {
      type: Boolean
    },
    requestFunction: {
      type: Function,
      default: _user.getDataOrgMemberTree
    },
    requestParams: {
      type: [Object, String],
      default: () => ({})
    },
    selectionList: {
      type: Array,
      default: () => []
    },
    isShowMainSpaceMember: {
      type: Boolean,
      default: false
    },
    checkableList: {
      // 可勾选的节点
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      treeElement: null,
      defaultExpandedKeys: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name',
        isLeaf: data => this.isLeaf(data),
        disabled: this.getDisabled
      },
      treeNode: null,
      treeResolve: null,
      isCheckedSpace: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    selectionIdList() {
      return this.selectionList.map(_ref => {
        let {
          id
        } = _ref;
        return id;
      });
    }
  },
  watch: {
    'params.type'(val) {
      val.workspace_id && this.initTreeData();
    },
    workspaceInfo() {
      this.$nextTick(() => {
        this.initTreeData();
      });
    },
    isCheckedSpace() {
      this.$nextTick(() => {
        this.initTreeData();
        this.$nextTick(() => {
          this.checkNode();
        });
      });
    }
  },
  mounted() {
    this.treeElement = this.$refs['department-member-tree'];
  },
  methods: {
    initTreeData() {
      this.loadTreeData(this.treeNode, this.treeResolve);
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    async getTreeData() {
      const res = await this.requestFunction(this.requestParams).finally(() => {
        this.treeElement.setCheckedKeys([]);
      });
      if (res.code === 200 || res.code === 20000) {
        const {
          results
        } = res;
        const list = Array.isArray(results) ? results : [results];
        if (!list.length) {
          return [];
        }
        // 默认展开一级节点
        const {
          id: rootId
        } = list[0];
        this.defaultExpandedKeys = [rootId];
        // 默认选中根节点
        if (this.setCheckedRootNode || this.selectionIdList.includes(rootId)) {
          setTimeout(() => {
            this.treeElement.setChecked(rootId, true);
            this.checkNode();
          }, 0);
        }
        this.treeNode.childNodes = [];
        return list;
      }
    },
    async loadTreeData(node, resolve) {
      const requestParams = this.requestParams;
      requestParams.filter_space = Number(this.isCheckedSpace);
      requestParams.is_main_space = Number(this.isCheckedSpace);
      requestParams.workspace_id = this.requestParams.workspace_id || this.workspaceInfo.id;
      if (!node || node.level === 0) {
        this.treeNode = node;
        this.treeResolve = resolve;
        const data = await this.getTreeData();
        return resolve(data);
      }
      const res = await this.requestFunction({
        tree_id: node.data.id.split('_').pop() - 0,
        page: 1,
        size: 9999,
        ...requestParams
      });
      if (res.code === 200 || res.code === 20000) {
        const currentLevelNodeData = res.results.children || res.results;
        this.backFillData(currentLevelNodeData);
        return resolve(currentLevelNodeData);
      } else {
        return resolve([]);
      }
    },
    isDepartment(id) {
      return /^tree/.test(id);
    },
    isLeaf(data) {
      return !this.isDepartment(data.id) || this.isDepartment(data.id) && !data.has_children;
    },
    getTreeUserIds(tree_user_ids) {
      const tree_ids = [];
      const user_ids = [];
      tree_user_ids && tree_user_ids.forEach(item => {
        item = String(item);
        const id = item.split('_').pop() - 0;
        if (item.indexOf('tree_id_') !== -1) {
          tree_ids.push(id);
        } else {
          user_ids.push(id);
        }
      });
      return {
        tree_ids,
        user_ids
      };
    },
    backFillData(currentLevelNodeData) {
      // 找当前节点的数据和选中项的交集，设置选中
      currentLevelNodeData.forEach(item => {
        if (this.selectionIdList.includes(item.id)) {
          // 当前节点需要回填
          this.$nextTick(() => {
            this.treeElement.setChecked(item.id, true);
          });
        }
      });
    },
    checkNode() {
      // 将选中项中在树里面已加载的节点删掉 + 树里面选中的结果 = 最终结果
      const searchCheckedAreNotInTreeList = this.selectionList.filter(_ref2 => {
        let {
          id
        } = _ref2;
        return !this.treeElement.getNode(id);
      });
      this.$emit('checkChange', this.getCheckedNodeData().concat(searchCheckedAreNotInTreeList));
    },
    getCheckedNodeData() {
      const checkedNodes = [];
      this.recursionTreeNode(child => {
        if (child.checked) {
          checkedNodes.push(this.formateNodeData(child));
        }
      });
      return checkedNodes;
    },
    // 递归树
    recursionTreeNode(callback) {
      // 获取树的配置属性信息
      const store = this.treeElement.store;
      const traverseTreeNode = node => {
        // 获取根节点，从根节点开始递归，将全选的节点加入到选中项数组中
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          callback(child);
          // 判断节点的子树中是否有被选中的节点
          if (child.indeterminate) {
            traverseTreeNode(child);
          }
        });
      };
      traverseTreeNode(store);
    },
    formateNodeData(child) {
      return {
        name: child.data.name,
        id: child.data.id,
        avatar: child.data.avatar,
        isDepartment: child.data.id.includes('tree_id_'),
        preNode: child.parent.data ? {
          name: child.parent.data.name,
          id: child.parent.data.id
        } : null
      };
    },
    getDisabled(data) {
      if (!this.checkableList.length) return false;
      return !this.checkableList.includes(data.id);
    }
  }
};
exports.default = _default;