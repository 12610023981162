import { render, staticRenderFns } from "./MoreFilters.vue?vue&type=template&id=50a13ade&scoped=true&"
import script from "./MoreFilters.vue?vue&type=script&lang=js&"
export * from "./MoreFilters.vue?vue&type=script&lang=js&"
import style0 from "./MoreFilters.vue?vue&type=style&index=0&id=50a13ade&lang=scss&scoped=true&"
import style1 from "./MoreFilters.vue?vue&type=style&index=1&id=50a13ade&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a13ade",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50a13ade')) {
      api.createRecord('50a13ade', component.options)
    } else {
      api.reload('50a13ade', component.options)
    }
    module.hot.accept("./MoreFilters.vue?vue&type=template&id=50a13ade&scoped=true&", function () {
      api.rerender('50a13ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/MoreFilters.vue"
export default component.exports