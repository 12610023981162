"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "right-start",
      width: "294",
      trigger: "hover",
      "popper-class": "space-description-popover",
      transition: "fade-in-linear",
      "close-delay": 200
    },
    on: {
      show: function ($event) {
        _vm.showGif = true;
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_vm.showGif ? _c("img", {
    staticClass: "workspace-gif",
    attrs: {
      src: require("@/assets/workspace.gif"),
      alt: "空间说明"
    }
  }) : _vm._e(), _c("p", [_vm._v("空间是助力企业内不同业务、不同职能等多团队分工协同的提效工具。")]), _c("p", [_vm._v(" 各团队分配好各自的主空间后，会话、商机自动导入对应空间，并使用各空间独立设置的关键事件、自定义关键事件等进行分析。 ")]), _c("a", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "know-more",
    attrs: {
      href: _vm.helpUrl,
      target: "_blank"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-question3"
  }), _c("span", [_vm._v("了解更多")])])]), _c("i", {
    staticClass: "iconfont icon-info-1",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;