"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _fetchRequest = _interopRequireDefault(require("@/utils/fetch-request"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _elementUi = require("element-ui");
var _ScriptSelectionDialog = _interopRequireDefault(require("./ScriptSelectionDialog.vue"));
var _KnowledgeList = _interopRequireDefault(require("@/views/coaching/aiSopCourseEdit/components/KnowledgeList.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AiGeneratingCourseDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    directedGraph: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const aiSentence = (0, _vue.ref)('');
    const aiSentenceContainerVisible = (0, _vue.ref)(false);
    const isGenerating = (0, _vue.ref)(false);
    const dialogue = (0, _vue.ref)((0, _i18n.t)('coaching.dialogueExample'));
    const scriptSelectionDialogVisible = (0, _vue.ref)(false);
    const knowledgeScriptList = (0, _vue.ref)([]);
    function changeKnowledgeList(val) {
      knowledgeScriptList.value = val;
      store.commit('training/SET_KNOWLEDGE_SCRIPT', val);
      aiSentence.value = '';
      val.length && verificationData();
    }
    const generatingAiSentenceError = (0, _vue.ref)(false);
    (0, _vue.watch)(() => aiSentence.value, val => {
      if (val === '服务器内部错误') {
        generatingAiSentenceError.value = true;
      }
    });
    async function generatingAiSentence() {
      if (!verificationData()) return;
      generatingAiSentenceError.value = false;
      aiSentenceContainerVisible.value = true;
      aiSentence.value = '';
      isGenerating.value = true;
      const res = await (0, _fetchRequest.default)('/webapi/training/course/smart/conv/text', {
        method: 'POST',
        body: JSON.stringify({
          scene: dialogue.value,
          knowledge_ids: knowledgeScriptList.value.map(item => item.knowledge_id)
        })
      });
      // eslint-disable-next-line no-undef
      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
      let isFineshed = false;
      while (!isFineshed) {
        const {
          done,
          value
        } = await reader.read();
        isFineshed = done;
        if (done) {
          isGenerating.value = !done;
          break;
        }
        aiSentence.value += value;
      }
    }
    const canGeneratingNode = (0, _vue.computed)(() => dialogue.value.length && knowledgeScriptList.value.length && !isGenerating.value && aiSentence.value.length);
    function submit() {
      const matches = aiSentence.value.split(/(销售：|客户：)/).filter(sentence => sentence.trim() !== '');
      const aiSentenceList = [];
      for (let i = 0; i < matches.length; i += 2) {
        const roleName = matches[i] === '销售：' ? (0, _i18n.t)('coaching.salespersonSays') : (0, _i18n.t)('coaching.customerSays');
        aiSentenceList.push([i / 2 + 1, roleName, matches[i + 1].replace(/\n+$/g, '')]);
      }
      console.log('aiSentenceList==', aiSentenceList);
      props.directedGraph.renderSentenceListOnGraph(aiSentenceList);
      _elementUi.Message.success((0, _i18n.t)('coaching.generatedSuccessfully'));
      handleClose();
    }
    const errorMessage = (0, _vue.ref)({
      dialogueErrorMessage: '',
      knowledgeListErrorMessage: ''
    });
    function verificationData() {
      setTimeout(() => {
        errorMessage.value.dialogueErrorMessage = dialogue.value.length ? '' : (0, _i18n.t)('coaching.conversationSceneEmptyTip');
        errorMessage.value.knowledgeListErrorMessage = knowledgeScriptList.value.length ? '' : (0, _i18n.t)('coaching.conversationMaterialEmptyTip');
      }, 200);
      return dialogue.value.length && knowledgeScriptList.value.length;
    }
    function initVerificationData() {
      errorMessage.value.dialogueErrorMessage = '';
      errorMessage.value.knowledgeListErrorMessage = '';
    }
    function handleClose() {
      // dialogue.value = '';
      // aiSentenceContainerVisible.value = false;
      // changeKnowledgeList([]);
      initVerificationData();
      emit('update:visible', false);
    }
    return {
      __sfc: true,
      store,
      props,
      emit,
      aiSentence,
      aiSentenceContainerVisible,
      isGenerating,
      dialogue,
      scriptSelectionDialogVisible,
      knowledgeScriptList,
      changeKnowledgeList,
      generatingAiSentenceError,
      generatingAiSentence,
      canGeneratingNode,
      submit,
      errorMessage,
      verificationData,
      initVerificationData,
      handleClose,
      ScriptSelectionDialog: _ScriptSelectionDialog.default,
      KnowledgeList: _KnowledgeList.default,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;