"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "field-items"
  }, [_vm._l(_setup.fieldItems, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "field-item",
      class: {
        "has-validate": _vm.needValidate
      }
    }, [_c("div", {
      staticClass: "item"
    }, [_c("div", {
      staticClass: "field-name-content"
    }, [_c("el-input", {
      ref: "fieldNameInput",
      refInFor: true,
      staticClass: "label",
      class: {
        "has-error": !_setup.validateIsOK && (!item.key || _setup.checkKeyIsSame(item.key, index))
      },
      attrs: {
        placeholder: _setup.getKeyPlaceHolderValue(item),
        maxlength: _vm.keyProps.maxlength
      },
      model: {
        value: item.key,
        callback: function ($$v) {
          _vm.$set(item, "key", $$v);
        },
        expression: "item.key"
      }
    }), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.needValidate && !_setup.validateIsOK && !item.key,
        expression: "needValidate && !validateIsOK && !item.key"
      }],
      staticClass: "error-tip"
    }, [_vm._v(" 请填写" + _vm._s(_vm.itemLabel) + "名称 ")]), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.needValidate && !_setup.validateIsOK && item.key && _setup.checkKeyIsSame(item.key, index),
        expression: "\n            needValidate &&\n            !validateIsOK &&\n            item.key &&\n            checkKeyIsSame(item.key, index)\n          "
      }],
      staticClass: "error-tip"
    }, [_vm._v(" " + _vm._s(_vm.itemLabel) + "名称重复 ")])], 1), _c("el-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _setup.fieldItems.length > 1,
        expression: "fieldItems.length > 1"
      }],
      staticClass: "hover-show-bg",
      attrs: {
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          return _setup.deleteField(index);
        }
      }
    })], 1), _c("div", {
      staticClass: "relative"
    }, [_c("el-input", {
      class: {
        "has-error": !_setup.validateIsOK && !item.key
      },
      attrs: {
        type: "textarea",
        rows: _vm.valueProps.rows,
        autosize: {
          minRows: 1,
          maxRows: 20
        },
        resize: "none",
        maxlength: _vm.valueProps.maxlength,
        placeholder: _setup.getValuePlaceHolderValue(item)
      },
      on: {
        change: _setup.fieldValueChange
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.needValidate && !_setup.validateIsOK && !item.value,
        expression: "needValidate && !validateIsOK && !item.value"
      }],
      staticClass: "error-tip"
    }, [_vm._v(" 请填写" + _vm._s(_vm.itemLabel) + "内容 ")])], 1)]);
  }), _c("MgvButton", {
    staticClass: "add-new-field",
    attrs: {
      icon: "icon-plus",
      type: "plain",
      disabled: _setup.fieldItems.length >= _vm.maxItemsLength,
      "tooltip-content": _setup.fieldItems.length >= _vm.maxItemsLength ? `${_vm.itemLabel}最多创建${_vm.maxItemsLength}个` : ""
    },
    on: {
      click: _setup.addNewFieldItem
    }
  }, [_vm._v("添加" + _vm._s(_vm.itemLabel))])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;