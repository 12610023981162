"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: "添加剪辑片段",
      visible: _vm.snippetsDrawerVisible,
      "before-close": _vm.handleClose,
      size: "1000px",
      "custom-class": "select-snippets-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "search-part"
  }, [_c("el-select", {
    ref: "select",
    attrs: {
      "popper-class": "coaching-snippets-select",
      multiple: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.remoteMethod,
      placeholder: "搜索剪辑片段",
      "value-key": "id",
      "multiple-limit": 10
    },
    on: {
      change: _vm.selectSnippets,
      "remove-tag": _vm.removeSnippet
    },
    model: {
      value: _vm.selectSnippetsArray,
      callback: function ($$v) {
        _vm.selectSnippetsArray = $$v;
      },
      expression: "selectSnippetsArray"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-search"
  })]), _vm.showUsed ? _vm._l(_vm.usedOptions, function (group) {
    return _c("el-option-group", {
      key: group.label,
      attrs: {
        label: group.label
      }
    }, _vm._l(group.options, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.name,
          value: item
        }
      }, [item.path ? _c("option-template", {
        attrs: {
          item: item
        }
      }) : _vm._e()], 1);
    }), 1);
  }) : _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item
      }
    }, [item.path ? _c("option-template", {
      attrs: {
        item: item
      }
    }) : _vm._e()], 1);
  })], 2)], 1), _c("main", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.snippetsLoading,
      expression: "snippetsLoading"
    }],
    staticClass: "main-content"
  }, [_c("aside", {
    staticClass: "left-aside"
  }, [_c("div", {
    staticClass: "page-subtitle"
  }, [_vm._v("剪辑库")]), _c("folder-tree", {
    ref: "folder-tree",
    attrs: {
      "current-node": _vm.currentNode,
      "filter-conv-flow": ""
    },
    on: {
      nodeClick: _vm.handleNodeClick,
      fetchSucceed: _vm.treeFetchSucceed,
      nodeChange: _vm.handleTreeNodeChange
    }
  })], 1), _c("section", {
    staticClass: "right-content"
  }, [_c("folder-header", {
    attrs: {
      folder: _vm.curFolder,
      breadcrumbs: _vm.breadcrumbs,
      readonly: ""
    }
  }), _vm.snippetsList.length ? _c("snippets-list", {
    ref: "snippetsComp",
    staticClass: "snippets",
    attrs: {
      list: _vm.snippetsList,
      "video-total": _vm.videoTotal,
      readonly: "",
      "show-checkbox": ""
    },
    on: {
      snippetCheckedChange: _vm.snippetCheckedChange
    }
  }) : _c("empty-folder")], 1)])]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    staticClass: "mg-button mg-round-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-button mg-round-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addSnippets
    }
  }, [_vm._v("添加")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;