"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIframeCoversationList = getIframeCoversationList;
var _request = require("@/utils/request");
/**
 * 嵌入页面根据code获取跳转信息值
 */
//  export function getInfoByCode(params) {
//   return request({
//     url: '/hub/fxiaoke/get_code',
//     method: 'get',
//     params
//   })
// }

/**
 *嵌入页面获取会话列表
 */
function getIframeCoversationList(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/deal',
    method: 'post',
    dataType: 'json',
    data
  });
}