"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskInfo = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskInfo.vue"));
var _DialogueList = _interopRequireDefault(require("@/views/coaching/coach/coachTask/salesPlan/DialogueList.vue"));
var _KeyScriptReview = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/KeyScriptReview.vue"));
var _KeyScriptReviewNoScene = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/KeyScriptReviewNoScene.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeFinishAiCustomer',
  components: {
    TaskInfo: _TaskInfo.default,
    DialogueList: _DialogueList.default,
    KeyScriptReview: _KeyScriptReview.default,
    KeyScriptReviewNoScene: _KeyScriptReviewNoScene.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  props: {
    convId: {
      type: String,
      default: ''
    },
    isFinished: {
      type: Boolean,
      default: false
    },
    sceneList: {
      type: Array,
      default: () => []
    },
    scriptList: {
      type: Array,
      default: () => []
    },
    courseType: {
      type: Number,
      default: 0
    },
    customerAvatar: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      taskInfo: {},
      activeName: 'dialogueList',
      conversationList: [],
      narration: '',
      // 旁白
      taskId: '',
      aiCommentAndScore: {},
      // ai客户最终分数和智能点评
      abilityAnalysis: [] // ai客户能力分析
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'webSocketMsg']),
    isGenerating() {
      return !Object.keys(this.aiCommentAndScore).length;
    }
  },
  watch: {
    isFinished: {
      handler: function (val) {
        if (val) {
          const {
            taskId
          } = this.$route.query;
          if (taskId) {
            this.taskId = taskId;
            this.getSmartTrainingList();
          }
        }
      },
      immediate: true
    },
    sceneList(val) {
      if (val.length) {
        this.activeName = 'keyScriptReview';
      }
    },
    scriptList(val) {
      if (val.length) {
        this.activeName = 'keyScriptReview';
      }
    },
    webSocketMsg: {
      handler: function (newVal) {
        if (newVal !== null && newVal !== void 0 && newVal.data) {
          switch (newVal.type) {
            case 'plan_ability_analysis':
              // ai客户-能力分析返回消息
              this.abilityAnalysis = newVal.data.content.ability_analysis;
              break;
            case 'plan_score_comment':
              // ai客户-最终评分和点评返回消息
              this.aiCommentAndScore = newVal.data.content;
              if (this.courseType === 7) {
                this.abilityAnalysis = newVal.data.content.ability_analysis;
              }
              break;
            case 'plan_dialog_finished':
              // ai客户-对话记录
              this.getSmartTrainingList();
              break;
            default:
              break;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    async getSmartTrainingList() {
      const res = await (0, _coachingCenter.getSmartTrainingList)({
        plan_id: this.taskId,
        user_id: this.userId,
        conv_id: this.convId
      });
      if (res.code === 20000) {
        const {
          creator,
          data,
          end_time,
          start_time,
          name,
          scene
        } = res.results;
        this.taskInfo = {
          name,
          start_time,
          end_time,
          user_info: creator
        };
        this.narration = scene;
        this.conversationList = data;
      }
    },
    // 返回我的任务
    goMyTask() {
      const path = _routeMap.ROUTES.coachingCenterSales;
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path
      });
    }
  }
};
exports.default = _default;