"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const management = {
  zh: {
    management: {
      个人中心: '个人中心',
      个人信息: '个人信息',
      应用: '应用',
      导出记录: '导出记录',
      姓名: '姓名',
      头像: '头像',
      上传: '上传',
      '支持jpg、jpeg、png格式小于200KB': '支持jpg、jpeg、png格式小于200KB',
      工作邮箱: '工作邮箱',
      请输入邮箱: '请输入邮箱',
      '工作邮箱仅为个人信息项，不是登录账号': '工作邮箱仅为个人信息项，不是登录账号',
      主部门: '主部门',
      保存修改: '保存修改',
      修改信息成功: '修改信息成功',
      请输入正确的邮箱: '请输入正确的邮箱',
      姓名不能为空: '姓名不能为空',
      登录账号: '登录账号',
      登录手机号: '登录手机号',
      登录邮箱: '登录邮箱',
      登录密码: '登录密码',
      验证当前已登录手机号: '验证当前已登录手机号',
      请输入验证码: '请输入验证码',
      发送验证码: '发送验证码',
      验证码不能为空: '验证码不能为空',
      验证码格式不正确: '验证码格式不正确',
      取消: '取消',
      下一步: '下一步',
      拖动下方滑块完成拼图: '拖动下方滑块完成拼图',
      '只用了{0}s，简直比闪电还快': '只用了{0}s，简直比闪电还快',
      请控制拼图块对齐缺口: '请控制拼图块对齐缺口',
      '这题有点难呢，已为您更换题目': '这题有点难呢，已为您更换题目',
      绑定新手机号: '绑定新手机号',
      请输入正确的手机号: '请输入正确的手机号',
      '账号已被绑定，请确认账户信息': '账号已被绑定，请确认账户信息',
      '验证码已发送，请查收': '验证码已发送，请查收',
      绑定新邮箱: '绑定新邮箱',
      确定: '确定',
      '您未设置登录密码，请': '您未设置登录密码，请',
      设置: '设置',
      修改: '修改',
      绑定: '绑定',
      设置密码: '设置密码',
      设置新密码: '设置新密码',
      请输入新密码: '请输入新密码',
      '8-20个字符': '8-20个字符',
      包含数字: '包含数字',
      包含字母: '包含字母',
      包含特殊字符: '包含特殊字符',
      确认新密码: '确认新密码',
      请再次输入新密码: '请再次输入新密码',
      保存: '保存',
      密码设置成功: '密码设置成功',
      修改密码: '修改密码',
      当前账户密码: '当前账户密码',
      切换为验证码验证: '切换为验证码验证',
      请输入当前账户密码: '请输入当前账户密码',
      保存更改: '保存更改',
      两次密码输入不一致: '两次密码输入不一致',
      密码修改成功: '密码修改成功',
      切换为密码验证: '切换为密码验证',
      部门和成员: '部门和成员',
      部门和成员管理: '部门和成员管理',
      组织架构: '组织架构',
      添加子部门: '添加子部门',
      重命名: '重命名',
      设置部门负责人: '设置部门负责人',
      部门负责人拥有部门内所有数据权限: '部门负责人拥有部门内所有数据权限',
      导出部门: '导出部门',
      移动部门: '移动部门',
      转移数据: '转移数据',
      删除部门: '删除部门',
      移动成功: '移动成功',
      此部门没有可转移数据: '此部门没有可转移数据',
      转移部门数据: '转移部门数据',
      请选择要转移的数据: '请选择要转移的数据',
      会话: '会话',
      '{0}条': '{0}条',
      转移部门不影响数据归属成员: '转移部门不影响数据归属成员',
      数据: '数据',
      接收部门: '接收部门',
      转移: '转移',
      '确定要转移数据吗？': '确定要转移数据吗？',
      '转移已开始，请耐心等候，完成时会再次消息通知您': '转移已开始，请耐心等候，完成时会再次消息通知您',
      会话转移归属部门已完成: '会话转移归属部门已完成',
      转移部门: '转移部门',
      '会话转移成功：{0}条': '会话转移成功：{0}条',
      '确定导出部门信息吗？': '确定导出部门信息吗？',
      导出成功: '导出成功',
      '部门列表_{0}已经准备完成。': '部门列表_{0}已经准备完成。',
      查看导出记录: '查看导出记录',
      下载: '下载',
      '是否删除该部门？': '是否删除该部门？',
      删除: '删除',
      删除成功: '删除成功',
      提示: '提示',
      '您的权限已发生变动，是否刷新页面': '您的权限已发生变动，是否刷新页面',
      刷新: '刷新',
      的成员列表: '的成员列表',
      部门负责人: '部门负责人',
      未设置: '未设置',
      '公司已使用/人数上限': '公司已使用/人数上限',
      已使用和上限说明: '“已使用”是启用、禁用、未登录三种状态的账号个数之和；“人数上限”是指每个企业可用的账号数上限。',
      导出成员: '导出成员',
      '确定导出当前{0}个成员信息吗？': '确定导出当前{0}个成员信息吗？',
      导出文件: '导出文件',
      '文件正在准备中，您可以在个人中心-{0}中查看': '文件正在准备中，您可以在个人中心-{0}中查看',
      搜索成员姓名或登录账号: '搜索成员姓名或登录账号',
      请选择空间: '请选择空间',
      是否登录: '是否登录',
      已登录: '已登录',
      未登录: '未登录',
      全部状态: '全部状态',
      启用: '启用',
      禁用: '禁用',
      全部类型: '全部类型',
      正式版: '正式版',
      试用版: '试用版',
      登录账户: '登录账户',
      部门: '部门',
      空间: '空间',
      主空间: '主空间',
      状态: '状态',
      已启用: '已启用',
      已禁用: '已禁用',
      账号类型: '账号类型',
      到期时间: '到期时间',
      操作: '操作',
      编辑: '编辑',
      编辑成员信息: '编辑成员信息',
      '此邮箱仅为个人信息项，不是登录账号': '此邮箱仅为个人信息项，不是登录账号',
      添加部门: '添加部门',
      确认删除: '确认删除',
      添加空间: '添加空间',
      设置离职: '设置离职',
      '确定要将{0}设置为离职吗？': '确定要将{0}设置为离职吗？',
      离职成功: '离职成功',
      请选择部门: '请选择部门',
      请输入正确的邮箱地址: '请输入正确的邮箱地址',
      保存成功: '保存成功',
      此成员没有可转移的数据: '此成员没有可转移的数据',
      转移成员数据: '转移成员数据',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': '归属部门和空间，会同步更新为新归属人的主部门和主空间',
      操作离职: '操作离职',
      '是否禁用{0}账号？': '是否禁用{0}账号？',
      该账号将无法登录Megaview访问本企业: '该账号将无法访问当前企业',
      系统会保留该员工的所有数据: '系统会保留该员工的所有数据',
      '禁用期间，组织内员工依然可以查看该员工数据': '禁用期间，组织内员工依然可以查看该员工数据',
      禁用账号可以启用恢复: '禁用账号可以启用恢复',
      '确定要启用{0}账号？': '确定要启用{0}账号？',
      邀请新成员: '邀请新成员',
      添加新成员: '添加新成员',
      请输入姓名: '请输入姓名',
      '手机号/邮箱': '手机号/邮箱',
      手机号: '手机号',
      请输入手机号: '请输入手机号',
      邮箱: '邮箱',
      请选择主部门: '请选择主部门',
      请选择主空间: '请选择主空间',
      确定并继续添加: '确定并继续添加',
      邀请已发送: '邀请已发送',
      您也可以通过发送链接告知团队成员: '您也可以通过发送链接告知团队成员',
      '': '',
      复制邀请: '复制邀请',
      复制成功: '复制成功',
      完成: '完成',
      手机号不能为空: '手机号不能为空',
      邮箱不能为空: '邮箱不能为空',
      导入成员: '导入成员',
      '点击下载按钮，下载需要导入的模板文件': '点击下载按钮，下载需要导入的模板文件',
      '请仔细阅读模板中的说明，': '请仔细阅读模板中的说明，',
      并按要求格式填写导入的数据: '并按要求格式填写导入的数据',
      下载模板: '下载模板',
      '文件填写完成后，在下方区域上传文件': '文件填写完成后，在下方区域上传文件',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': '系统会校验数据格式准确性，校验无误后，会将数据导入系统中',
      点击或将文件拖拽到这里上传: '点击或将文件拖拽到这里上传',
      '支持扩展名：': '支持扩展名：',
      导入: '导入',
      导入部门: '导入部门',
      批量修改成员信息: '批量修改成员信息',
      成员: '成员',
      请选择成员: '请选择成员',
      请选择要修改的信息: '请选择要修改的信息',
      '修改成员的信息，会清空之前的信息，替换为新的': '修改成员的信息，会清空之前的信息，替换为新的',
      修改成功: '修改成功',
      请选择要修改的部门: '请选择要修改的部门',
      请选择要修改的空间: '请选择要修改的空间',
      已离职成员: '已离职成员',
      搜索成员: '搜索成员',
      未分配: '未分配',
      仅显示当前部门直属人员: '仅显示当前部门直属人员',
      '共{0}条': '共{0}条',
      验证邮箱: '验证邮箱',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': '修改后会自动重置登录密码，并向邮箱发送邀请链接',
      子部门: '子部门',
      暂无数据: '暂无数据',
      请输入密码: '请输入密码',
      请再次输入密码: '请再次输入密码',
      '两次输入密码不一致!': '两次输入密码不一致!',
      验证当前已登录邮箱: '验证当前已登录邮箱',
      绑定成功: '绑定成功',
      特殊字符不支持: '特殊字符不支持',
      此部门数据已在转移中: '此部门数据已在转移中',
      此部门没有可转移的数据: '此部门没有可转移的数据',
      '该部门下已有成员或部门，不能被删除。': '该部门下已有成员或部门，不能被删除。',
      '此部门数据正在转移中，无法删除': '此部门数据正在转移中，无法删除',
      交易: '交易',
      '删除部门前需转移部门数据，你没有此部门的': '删除部门前需转移部门数据，你没有此部门的',
      '编辑权限，请先前往': '编辑权限，请先前往',
      数据权限: '数据权限',
      页面配置: '页面配置',
      无编辑权限: '无编辑权限',
      我知道了: '我知道了',
      删除前转移部门数据: '删除前转移部门数据',
      离职: '离职',
      验证码已过期或未发送: '验证码已过期或未发送',
      请先添加验证邮箱: '请先添加验证邮箱',
      是否重置该成员密码: '是否重置该成员密码',
      重置密码: '重置密码',
      请先添加验证邮箱后再重置密码: '请先添加验证邮箱后再重置密码',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。',
      密码重置成功: '密码重置成功',
      查看邮箱验证码: '查看邮箱验证码',
      '将于{0}过期': '将于{0}过期',
      复制: '复制',
      '会话、商机默认导入归属人的主空间': '会话、商机默认导入归属人的主空间',
      '请输入1-32个字符': '请输入1-32个字符',
      离职成员: '离职成员',
      添加: '添加',
      '你没有此{0}的{1}编辑权限': '你没有此{0}的{1}编辑权限',
      '请先前往{0}页面配置': '请先前往{0}页面配置',
      '还有未转移的数据，确定要将{0}设置为离职吗？': '还有未转移的数据，确定要将{0}设置为离职吗？',
      '未转移数据将保留，所有者仍展示离职成员': '未转移数据将保留，所有者仍展示离职成员',
      请选择数据接收: '请选择数据接收',
      人: '人',
      接收人: '接收人',
      资源: '资源',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。',
      '双因素验证方式-验证邮箱': '双因素验证方式-验证邮箱',
      请输入验证邮箱: '请输入验证邮箱',
      登录账号不能为空: '登录账号不能为空',
      '仅支持字母、数字、下划线': '仅支持字母、数字、下划线',
      '账号长度为4-20字符': '账号长度为4-20字符',
      通知: '通知',
      复制失败: '复制失败',
      '添加成功，账号及密码已发送到验证邮箱': '添加成功，账号及密码已发送到验证邮箱',
      '添加成功，给该成员添加验证邮箱后可登录': '添加成功，给该成员添加验证邮箱后可登录',
      添加成功: '添加成功',
      导入管理员角色: '导入管理员角色',
      导入成员角色: '导入成员角色',
      '导入成功{0}条': '导入成功{0}条',
      请输入工作邮箱: '请输入工作邮箱',
      子部门和成员也将随之移动: '子部门和成员也将随之移动',
      请选择移动到的部门: '请选择移动到的部门',
      请选择要移动后的父部门: '请选择要移动后的父部门',
      从其他部门移入成员: '从其他部门移入成员',
      已选成员: '已选成员',
      '加入成功！': '加入成功！',
      重新选择: '重新选择',
      正在导入: '正在导入',
      '请不要刷新或离开，同时保持网络畅通': '请不要刷新或离开，同时保持网络畅通',
      上传失败: '上传失败',
      '上传失败，请重新上传': '上传失败，请重新上传',
      重新上传: '重新上传',
      请选择xlsx或xls格式的文件: '请选择xlsx或xls格式的文件',
      解析中: '解析中',
      '上传尚未完成。要取消正在进行的上传吗？': '上传尚未完成。要取消正在进行的上传吗？',
      取消上传: '取消上传',
      继续上传: '继续上传',
      已取消上传: '已取消上传',
      导入完成: '导入完成',
      '成功{0}条': '成功{0}条',
      '失败{0}条': '失败{0}条',
      Excel行号: 'Excel行号',
      失败内容: '失败内容',
      失败原因: '失败原因',
      重新导入: '重新导入',
      父部门不存在: '父部门不存在',
      请选择要修改的成员信息: '请选择要修改的成员信息',
      文件名称: '文件名称',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。',
      创建时间: '创建时间',
      请输入登录账号: '请输入登录账号',
      确认: '确认',
      员工数已达上限: '员工数已达上限',
      申请扩容: '申请扩容',
      '上传失败，请重试！': '上传失败，请重试！',
      '上传头像图片大小不能超过200KB!': '上传头像图片大小不能超过200KB!',
      '上传头像图片格式暂不支持!': '上传头像图片格式暂不支持!',
      重新发送: '重新发送',
      '您的权限已发生变动，是否刷新页面？': '您的权限已发生变动，是否刷新页面？'
    }
  },
  en: {
    management: {
      个人中心: 'Personal Center',
      个人信息: 'Personal Information',
      应用: 'Applications',
      导出记录: 'Export Records',
      姓名: 'Name',
      头像: 'Avatar',
      上传: 'Upload',
      '支持jpg、jpeg、png格式小于200KB': 'Supports jpg, jpeg, png formats, less than 200KB',
      工作邮箱: 'Work Email',
      请输入邮箱: 'Please enter email',
      '工作邮箱仅为个人信息项，不是登录账号': 'Work email is for personal information only, not for login',
      主部门: 'Main Department',
      保存修改: 'Save Changes',
      修改信息成功: 'Information updated successfully',
      请输入正确的邮箱: 'Please enter a valid email',
      姓名不能为空: 'Name cannot be empty',
      登录账号: 'Login Account',
      登录手机号: 'Login Mobile Number',
      登录邮箱: 'Login Email',
      登录密码: 'Login Password',
      验证当前已登录手机号: 'Verify current logged-in mobile number',
      请输入验证码: 'Please enter captcha',
      发送验证码: 'Send captcha',
      验证码不能为空: 'Captcha cannot be empty',
      验证码格式不正确: 'Captcha format is incorrect',
      取消: 'Cancel',
      下一步: 'Next Step',
      拖动下方滑块完成拼图: 'Drag the slider below to complete the puzzle',
      '只用了{0}s，简直比闪电还快': 'Completed in just {0} seconds, faster than lightning',
      请控制拼图块对齐缺口: 'Please align the puzzle piece with the gap',
      '这题有点难呢，已为您更换题目': "This one is a bit challenging, we've changed the question for you",
      绑定新手机号: 'Bind New Mobile Number',
      请输入正确的手机号: 'Please enter the correct mobile number',
      '账号已被绑定，请确认账户信息': 'Account has been bound, please confirm account information',
      '验证码已发送，请查收': 'Captcha sent, please check',
      绑定新邮箱: 'Bind New Email',
      确定: 'Confirm',
      '您未设置登录密码，请': 'You have not set a login password, please',
      设置: 'Set',
      修改: 'Modify',
      绑定: 'Bind',
      设置密码: 'Set Password',
      设置新密码: 'Set New Password',
      请输入新密码: 'Please enter new password',
      '8-20个字符': '8-20 characters',
      包含数字: 'Includes numbers',
      包含字母: 'Includes letters',
      包含特殊字符: 'Includes special characters',
      确认新密码: 'Confirm New Password',
      请再次输入新密码: 'Please re-enter new password',
      保存: 'Save',
      密码设置成功: 'Password set successfully',
      修改密码: 'Change Password',
      当前账户密码: 'Current Account Password',
      切换为验证码验证: 'Switch to captcha verification',
      请输入当前账户密码: 'Please enter current account password',
      保存更改: 'Save Changes',
      两次密码输入不一致: 'The two password entries do not match.',
      密码修改成功: 'Password changed successfully',
      切换为密码验证: 'Switch to password verification',
      部门和成员: 'Departments and Members',
      部门和成员管理: 'Department and Member Management',
      组织架构: 'Organizational Structure',
      添加子部门: 'Add Sub-department',
      重命名: 'Rename',
      设置部门负责人: 'Set Department Head',
      部门负责人拥有部门内所有数据权限: 'Department head has all data permissions within the department',
      导出部门: 'Export Department',
      移动部门: 'Move department',
      转移数据: 'Transfer Data',
      删除部门: 'Delete Department',
      移动成功: 'Move Successful',
      此部门没有可转移数据: 'No transferable data in this department',
      转移部门数据: 'Transfer Department Data',
      请选择要转移的数据: 'Please select data to transfer',
      会话: 'Conversations',
      '{0}条': '{0} items',
      转移部门不影响数据归属成员: 'Transferring departments does not affect data ownership',
      数据: 'Data',
      接收部门: 'Receiving Department',
      转移: 'Transfer',
      '确定要转移数据吗？': 'Are you sure you want to transfer data?',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'Transfer has started, please wait patiently, you will be notified when complete',
      会话转移归属部门已完成: 'Department transfer of conversations completed',
      转移部门: 'Transferred Department',
      '会话转移成功：{0}条': 'Successful conversation transfer: {0} items',
      '确定导出部门信息吗？': 'Are you sure you want to export department information?',
      导出成功: 'Export Successful',
      '部门列表_{0}已经准备完成。': 'Department List_{0} is ready.',
      查看导出记录: 'View Export Records',
      下载: 'Download',
      '是否删除该部门？': 'Delete this department?',
      删除: 'Delete',
      删除成功: 'Deleted Successfully',
      提示: 'Notice',
      '您的权限已发生变动，是否刷新页面': 'Your permissions have changed, refresh the page?',
      刷新: 'Refresh',
      的成员列表: 'Member List',
      部门负责人: 'Department Head',
      未设置: 'Not Set',
      '公司已使用/人数上限': 'Company Usage/Maximum Number of Users',
      已使用和上限说明: '"Usage" is the sum of active, disabled, and never logged in accounts; "Maximum Number of Users" refers to the account limit for each company.',
      导出成员: 'Export Members',
      '确定导出当前{0}个成员信息吗？': 'Are you sure you want to export information for the current {0} members?',
      导出文件: 'Export File',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'File is being prepared, you can check in Personal Center-{0}',
      搜索成员姓名或登录账号: 'Search Name',
      请选择空间: 'select space',
      是否登录: 'Login Status',
      已登录: 'Logged In',
      未登录: 'Not Logged In',
      全部状态: 'All Statuses',
      启用: 'Enable',
      禁用: 'Disable',
      全部类型: 'All Types',
      正式版: 'Official Version',
      试用版: 'Trial Version',
      登录账户: 'Login Account',
      部门: 'Department',
      空间: 'Space',
      主空间: 'Main Space',
      状态: 'Status',
      已启用: 'Enabled',
      已禁用: 'Disabled',
      账号类型: 'Account Type',
      到期时间: 'Expiration Date',
      操作: 'Action',
      编辑: 'Edit',
      编辑成员信息: 'Edit Member Information',
      '此邮箱仅为个人信息项，不是登录账号': 'This email is for personal information only, not for login',
      添加部门: 'Add Department',
      确认删除: 'Confirm deletion',
      添加空间: 'Add Space',
      设置离职: 'Set Departure',
      '确定要将{0}设置为离职吗？': 'Are you sure you want to set {0} as departed?',
      离职成功: 'Departure Successful',
      请选择部门: 'Please select a department',
      请输入正确的邮箱地址: 'Please enter a valid email address',
      保存成功: 'Saved Successfully',
      此成员没有可转移的数据: 'This member has no transferable data',
      转移成员数据: 'Transfer Member Data',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': "Belonging department and space will be updated to the new owner's main department and space",
      操作离职: 'Operate Departure',
      '是否禁用{0}账号？': 'Disable {0} account?',
      该账号将无法登录Megaview访问本企业: 'This account will not be able to access the current enterprise',
      系统会保留该员工的所有数据: 'The system will retain all data of the employee',
      '禁用期间，组织内员工依然可以查看该员工数据': "During the disablement period, other employees within the organization can still view this employee's data",
      禁用账号可以启用恢复: 'Disabled accounts can be enabled and restored',
      '确定要启用{0}账号？': 'Are you sure you want to enable the {0} account?',
      邀请新成员: 'Invite New Members',
      添加新成员: 'Add New Members',
      请输入姓名: 'Please enter the name',
      '手机号/邮箱': 'Mobile Number/Email',
      手机号: 'Mobile Number',
      请输入手机号: 'Please enter mobile number',
      邮箱: 'Email',
      请选择主部门: 'Please select main department',
      请选择主空间: 'Please select main space',
      确定并继续添加: 'Confirm and continue adding',
      邀请已发送: 'Invitation Sent',
      您也可以通过发送链接告知团队成员: 'You can also send the link to inform team members',
      '': 'Department ID',
      复制邀请: 'Copy Invitation',
      复制成功: 'Copy Successful',
      完成: 'Complete',
      手机号不能为空: 'Mobile number cannot be empty',
      邮箱不能为空: 'Email cannot be empty',
      导入成员: 'Import Members',
      '点击下载按钮，下载需要导入的模板文件': 'Click the download button to download the template file needed for import',
      '请仔细阅读模板中的说明，': 'Please read the instructions in the template carefully,',
      并按要求格式填写导入的数据: 'and fill in the imported data as required.',
      下载模板: 'Download Template',
      '文件填写完成后，在下方区域上传文件': 'After filling in the file, upload it in the area below',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': 'The system will verify the accuracy of the data format, and after verification, the data will be imported into the system',
      点击或将文件拖拽到这里上传: 'Click or drag files here to upload',
      '支持扩展名：': 'Supported extensions:',
      导入: 'Import',
      导入部门: 'Import Department',
      批量修改成员信息: 'Batch Modify Member Information',
      成员: 'Members',
      请选择成员: 'Please select members',
      请选择要修改的信息: 'Please select the information to modify',
      '修改成员的信息，会清空之前的信息，替换为新的': 'Modifying member information will clear previous information and replace it with new',
      修改成功: 'Modified Successfully',
      请选择要修改的部门: 'Please select the department to modify',
      请选择要修改的空间: 'Please select the space to modify',
      已离职成员: 'Departed Members',
      搜索成员: 'Search Members',
      未分配: 'Unassigned',
      仅显示当前部门直属人员: 'Only show direct members of the current department',
      '共{0}条': 'Total {0} items',
      验证邮箱: 'Verify Email',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': 'After modification, the login password will be reset automatically, and an invitation link will be sent to the email',
      子部门: 'Sub-department',
      暂无数据: 'No Data',
      请输入密码: 'Please enter password',
      请再次输入密码: 'Please re-enter password',
      '两次输入密码不一致!': 'Passwords do not match!',
      验证当前已登录邮箱: 'Verify current logged-in email',
      绑定成功: 'Binding Successful',
      特殊字符不支持: 'Special characters not supported',
      此部门数据已在转移中: "This department's data is in the process of being transferred",
      此部门没有可转移的数据: 'No transferable data in this department',
      '该部门下已有成员或部门，不能被删除。': 'This department has members or sub-departments and cannot be deleted.',
      '此部门数据正在转移中，无法删除': "This department's data is being transferred and cannot be deleted",
      交易: 'Transaction',
      '删除部门前需转移部门数据，你没有此部门的': 'Before deleting a department, transfer its data; ',
      '编辑权限，请先前往': 'you do not have edit permissions for this department, please go to',
      数据权限: 'Data Permissions',
      页面配置: 'Page Configuration',
      无编辑权限: 'No Edit Permissions',
      我知道了: 'I Understand',
      删除前转移部门数据: 'Before deletion, transfer department data',
      离职: 'Resigned',
      验证码已过期或未发送: 'The captcha has expired or was not sent',
      请先添加验证邮箱: 'Please add a verification email first',
      是否重置该成员密码: "Should the member's password be reset?",
      重置密码: 'Reset Password',
      请先添加验证邮箱后再重置密码: 'Please add a verification email before resetting the password',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': "Should the member's password be reset? A new password will be sent to the member's verification email after reset.",
      密码重置成功: 'Password reset successful',
      查看邮箱验证码: 'View email captcha',
      '将于{0}过期': 'Will expire at {0}',
      复制: 'Copy',
      '会话、商机默认导入归属人的主空间': 'Session and opportunity default to the main space of the owner',
      '请输入1-32个字符': 'Please enter 1-32 characters',
      离职成员: 'Former members',
      添加: 'Add',
      '你没有此{0}的{1}编辑权限': "You do not have edit permission for this {0}'s {1}",
      '请先前往{0}页面配置': 'Please go to the {0} page settings first',
      '还有未转移的数据，确定要将{0}设置为离职吗？': 'There is still untransferred data, are you sure you want to set {0} as resigned?',
      '未转移数据将保留，所有者仍展示离职成员': 'Untransferred data will be retained, and the owner will still be displayed as a resigned member',
      请选择数据接收: 'Please select a data recipient',
      人: 'Person',
      接收人: 'Recipient',
      资源: 'Resources',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': 'The account and password will be automatically sent to the verification email after adding it. If the verification email is not filled in, the member cannot log in. The verification email can be added later in member editing to invite login.',
      '双因素验证方式-验证邮箱': 'Two-factor authentication method - Verification email',
      请输入验证邮箱: 'Please enter the verification email',
      登录账号不能为空: 'The login account cannot be empty',
      '仅支持字母、数字、下划线': 'Only supports letters, numbers, and underscores',
      '账号长度为4-20字符': 'Account length is 4-20 characters',
      通知: 'Notification',
      复制失败: 'Copy failed',
      '添加成功，账号及密码已发送到验证邮箱': 'Added successfully, account and password have been sent to the verification email',
      '添加成功，给该成员添加验证邮箱后可登录': 'Added successfully, the member can log in after adding a verification email',
      添加成功: 'Added successfully',
      导入管理员角色: 'Import admin role',
      导入成员角色: 'Import member role',
      '导入成功{0}条': 'Imported successfully {0} items',
      请输入工作邮箱: 'Please enter your work email',
      子部门和成员也将随之移动: 'Sub-departments and members will also be moved',
      请选择移动到的部门: 'Please select the department to move to',
      请选择要移动后的父部门: 'Please select the parent department after moving',
      从其他部门移入成员: 'Import members from other departments',
      已选成员: 'Selected members',
      '加入成功！': 'Joined successfully!',
      重新选择: 'Reselect',
      正在导入: 'Importing',
      '请不要刷新或离开，同时保持网络畅通': 'Please do not refresh or leave the page, and ensure a stable internet connection',
      上传失败: 'Upload Failed',
      '上传失败，请重新上传': 'Upload failed, please re-upload',
      重新上传: 'Re-upload',
      请选择xlsx或xls格式的文件: 'Please select a file in xlsx or xls format',
      解析中: 'Parsing',
      '上传尚未完成。要取消正在进行的上传吗？': 'The upload is not yet complete. Do you want to cancel the ongoing upload?',
      取消上传: 'Cancel Upload',
      继续上传: 'Continue Upload',
      已取消上传: 'Upload Canceled',
      导入完成: 'Import Complete',
      '成功{0}条': 'Successful {0} entries',
      '失败{0}条': 'Failed {0} entries',
      Excel行号: 'Excel Row Number',
      失败内容: 'Failure Details',
      失败原因: 'Reason for Failure',
      重新导入: 'Re-import',
      父部门不存在: 'Parent Department Does Not Exist',
      请选择要修改的成员信息: 'Please Select the Member Information to Modify',
      文件名称: 'File Name',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': 'The file you export will be temporarily stored in the export records and will be automatically deleted after 3 days.',
      创建时间: 'Creation Time',
      请输入登录账号: 'Enter account number',
      确认: 'Confirm',
      员工数已达上限: 'Employee limit reached',
      申请扩容: 'Request expansion',
      '上传失败，请重试！': 'Upload failed, please retry!',
      '上传头像图片大小不能超过200KB!': 'Profile picture size must not exceed 200KB!',
      '上传头像图片格式暂不支持!': 'Profile picture format is not supported!',
      重新发送: 'Resend',
      '您的权限已发生变动，是否刷新页面？': 'Your permissions have changed, would you like to refresh the page?'
    }
  },
  fr: {
    management: {
      个人中心: 'Centre Personnel',
      个人信息: 'Informations Personnelles',
      应用: 'Applications',
      导出记录: 'Historique des Exportations',
      姓名: 'Nom',
      头像: 'Avatar',
      上传: 'Télécharger',
      '支持jpg、jpeg、png格式小于200KB': 'Formats jpg, jpeg, png acceptés, moins de 200 Ko',
      工作邮箱: 'Email Professionnel',
      请输入邮箱: "Veuillez saisir l'email",
      '工作邮箱仅为个人信息项，不是登录账号': "L'email professionnel est uniquement pour les informations personnelles, pas pour la connexion",
      主部门: 'Département Principal',
      保存修改: 'Enregistrer les Modifications',
      修改信息成功: 'Informations mises à jour avec succès',
      请输入正确的邮箱: 'Veuillez saisir un email valide',
      姓名不能为空: 'Le nom ne peut pas être vide',
      登录账号: 'Compte de Connexion',
      登录手机号: 'Numéro de Mobile de Connexion',
      登录邮箱: 'Email de Connexion',
      登录密码: 'Mot de Passe de Connexion',
      验证当前已登录手机号: 'Vérifier le numéro de mobile connecté actuellement',
      请输入验证码: 'Veuillez saisir le captcha',
      发送验证码: 'Envoyer le captcha',
      验证码不能为空: 'Le captcha ne peut pas être vide',
      验证码格式不正确: 'Le format du captcha est incorrect',
      取消: 'Annuler',
      下一步: 'Étape Suivante',
      拖动下方滑块完成拼图: 'Faites glisser le curseur ci-dessous pour compléter le puzzle',
      '只用了{0}s，简直比闪电还快': "Complété en seulement {0} secondes, plus rapide que l'éclair",
      请控制拼图块对齐缺口: "Veuillez aligner la pièce du puzzle avec l'espace",
      '这题有点难呢，已为您更换题目': 'Celui-ci est un peu difficile, nous avons changé la question pour vous',
      绑定新手机号: 'Lier un Nouveau Numéro de Mobile',
      请输入正确的手机号: 'Veuillez saisir le bon numéro de mobile',
      '账号已被绑定，请确认账户信息': 'Le compte a été lié, veuillez confirmer les informations du compte',
      '验证码已发送，请查收': 'Captcha envoyé, veuillez vérifier',
      绑定新邮箱: 'Lier un Nouvel Email',
      确定: 'Confirmer',
      '您未设置登录密码，请': "Vous n'avez pas défini de mot de passe de connexion, veuillez",
      设置: 'Définir',
      修改: 'Modifier',
      绑定: 'Lier',
      设置密码: 'Définir le Mot de Passe',
      设置新密码: 'Définir un Nouveau Mot de Passe',
      请输入新密码: 'Veuillez saisir le nouveau mot de passe',
      '8-20个字符': '8-20 caractères',
      包含数字: 'Inclut des chiffres',
      包含字母: 'Inclut des lettres',
      包含特殊字符: 'Inclut des caractères spéciaux',
      确认新密码: 'Confirmer le Nouveau Mot de Passe',
      请再次输入新密码: 'Veuillez ressaisir le nouveau mot de passe',
      保存: 'Enregistrer',
      密码设置成功: 'Mot de passe défini avec succès',
      修改密码: 'Changer de Mot de Passe',
      当前账户密码: 'Mot de Passe du Compte Actuel',
      切换为验证码验证: 'Passer à la vérification par captcha',
      请输入当前账户密码: 'Veuillez saisir le mot de passe du compte actuel',
      保存更改: 'Enregistrer les Modifications',
      两次密码输入不一致: 'Les deux saisies de mot de passe ne correspondent pas.',
      密码修改成功: 'Mot de passe changé avec succès',
      切换为密码验证: 'Passer à la vérification par mot de passe',
      部门和成员: 'Départements et Membres',
      部门和成员管理: 'Gestion des Départements et des Membres',
      组织架构: 'Structure Organisationnelle',
      添加子部门: 'Ajouter un Sous-département',
      重命名: 'Renommer',
      设置部门负责人: 'Définir le Chef de Département',
      部门负责人拥有部门内所有数据权限: 'Le chef de département a toutes les permissions de données au sein du département',
      导出部门: 'Exporter le Département',
      移动部门: 'Déplacer le département',
      转移数据: 'Transférer les Données',
      删除部门: 'Supprimer le Département',
      移动成功: 'Déplacement Réussi',
      此部门没有可转移数据: 'Aucune donnée transférable dans ce département',
      转移部门数据: 'Transférer les Données du Département',
      请选择要转移的数据: 'Veuillez sélectionner les données à transférer',
      会话: 'Conversations',
      '{0}条': '{0} éléments',
      转移部门不影响数据归属成员: "Le transfert de départements n'affecte pas la propriété des données",
      数据: 'Données',
      接收部门: 'Département Récepteur',
      转移: 'Transférer',
      '确定要转移数据吗？': 'Êtes-vous sûr de vouloir transférer les données ?',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'Le transfert a commencé, veuillez patienter, vous serez notifié à la fin',
      会话转移归属部门已完成: 'Transfert de conversations du département terminé',
      转移部门: 'Département Transféré',
      '会话转移成功：{0}条': 'Transfert de conversations réussi : {0} éléments',
      '确定导出部门信息吗？': 'Êtes-vous sûr de vouloir exporter les informations du département ?',
      导出成功: 'Exportation Réussie',
      '部门列表_{0}已经准备完成。': 'Liste des Départements_{0} est prête.',
      查看导出记录: "Voir l'Historique des Exportations",
      下载: 'Télécharger',
      '是否删除该部门？': 'Supprimer ce département ?',
      删除: 'Supprimer',
      删除成功: 'Suppression Réussie',
      提示: 'Avis',
      '您的权限已发生变动，是否刷新页面': 'Vos permissions ont changé, rafraîchir la page ?',
      刷新: 'Rafraîchir',
      的成员列表: 'Liste des membres',
      部门负责人: 'Chef de Département',
      未设置: 'Non Défini',
      '公司已使用/人数上限': "Utilisation de l'Entreprise/Nombre Maximum d'Utilisateurs",
      已使用和上限说明: '"L\'utilisation" est la somme des comptes actifs, désactivés et jamais connectés ; "Nombre Maximum d\'Utilisateurs" se réfère à la limite de compte pour chaque entreprise.',
      导出成员: 'Exporter les Membres',
      '确定导出当前{0}个成员信息吗？': 'Êtes-vous sûr de vouloir exporter les informations pour les {0} membres actuels ?',
      导出文件: 'Exporter le Fichier',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'Le dossier est en cours de préparation, vous pouvez le consulter dans le Personal Center-{0}',
      搜索成员姓名或登录账号: 'Rechercher un nom',
      请选择空间: 'espace sélectionné',
      是否登录: 'Statut de Connexion',
      已登录: 'Connecté',
      未登录: 'Non Connecté',
      全部状态: 'Tous les Statuts',
      启用: 'Activer',
      禁用: 'Désactiver',
      全部类型: 'Tous les Types',
      正式版: 'Version Officielle',
      试用版: "Version d'Essai",
      登录账户: 'Compte de Connexion',
      部门: 'Département',
      空间: 'Espace',
      主空间: 'Espace Principal',
      状态: 'Statut',
      已启用: 'Activé',
      已禁用: 'Désactivé',
      账号类型: 'Type de Compte',
      到期时间: "Date d'Expiration",
      操作: 'Action',
      编辑: 'Éditer',
      编辑成员信息: 'Éditer les Informations du Membre',
      '此邮箱仅为个人信息项，不是登录账号': 'Cet email est uniquement pour les informations personnelles, pas pour la connexion',
      添加部门: 'Ajouter un Département',
      确认删除: 'Confirmer la suppression',
      添加空间: 'Ajouter un Espace',
      设置离职: 'Définir le Départ',
      '确定要将{0}设置为离职吗？': 'Êtes-vous sûr de vouloir définir {0} comme parti ?',
      离职成功: 'Départ Réussi',
      请选择部门: 'Veuillez sélectionner un département',
      请输入正确的邮箱地址: 'Veuillez saisir une adresse email valide',
      保存成功: 'Enregistré avec Succès',
      此成员没有可转移的数据: "Ce membre n'a pas de données transférables",
      转移成员数据: 'Transférer les Données du Membre',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': "Le département et l'espace d'appartenance seront mis à jour pour le nouveau propriétaire principal",
      操作离职: 'Opérer le Départ',
      '是否禁用{0}账号？': 'Désactiver le compte de {0}?',
      该账号将无法登录Megaview访问本企业: "Ce compte ne pourra pas accéder à l'entreprise actuelle",
      系统会保留该员工的所有数据: "Le système conservera toutes les données de l'employé",
      '禁用期间，组织内员工依然可以查看该员工数据': "Pendant la période de désactivation, les autres employés de l'organisation pourront toujours consulter les données de cet employé",
      禁用账号可以启用恢复: 'Les comptes désactivés peuvent être réactivés et restaurés',
      '确定要启用{0}账号？': 'Êtes-vous sûr de vouloir réactiver le compte {0} ?',
      邀请新成员: 'Inviter de Nouveaux Membres',
      添加新成员: 'Ajouter de Nouveaux Membres',
      请输入姓名: 'Veuillez entrer le nom',
      '手机号/邮箱': 'Numéro de Mobile/Email',
      手机号: 'Numéro de Mobile',
      请输入手机号: 'Veuillez saisir le numéro de mobile',
      邮箱: 'Email',
      请选择主部门: 'Veuillez sélectionner le département principal',
      请选择主空间: "Veuillez sélectionner l'espace principal",
      确定并继续添加: 'Confirmer et continuer à ajouter',
      邀请已发送: 'Invitation Envoyée',
      您也可以通过发送链接告知团队成员: "Vous pouvez aussi envoyer le lien pour informer les membres de l'équipe",
      '': 'ID du Département',
      复制邀请: "Copier l'Invitation",
      复制成功: 'Copie Réussie',
      完成: 'Terminer',
      手机号不能为空: 'Le numéro de mobile ne peut pas être vide',
      邮箱不能为空: "L'email ne peut pas être vide",
      导入成员: 'Importer des Membres',
      '点击下载按钮，下载需要导入的模板文件': "Cliquez sur le bouton de téléchargement pour télécharger le fichier modèle nécessaire à l'importation",
      '请仔细阅读模板中的说明，': 'Veuillez lire attentivement les instructions dans le modèle,',
      并按要求格式填写导入的数据: 'et remplir les données à importer selon le format requis.',
      下载模板: 'Télécharger le Modèle',
      '文件填写完成后，在下方区域上传文件': 'Après avoir rempli le fichier, téléchargez-le dans la zone ci-dessous',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': "Le système vérifiera l'exactitude du format des données, et après vérification, les données seront importées dans le système",
      点击或将文件拖拽到这里上传: 'Cliquez ou faites glisser des fichiers ici pour télécharger',
      '支持扩展名：': 'Extensions supportées : ',
      导入: 'Importer',
      导入部门: 'Importer le Département',
      批量修改成员信息: 'Modifier en Masse les Informations des Membres',
      成员: 'Membres',
      请选择成员: 'Veuillez sélectionner les membres',
      请选择要修改的信息: 'Veuillez sélectionner les informations à modifier',
      '修改成员的信息，会清空之前的信息，替换为新的': 'Modifier les informations des membres effacera les informations précédentes et les remplacera par de nouvelles',
      修改成功: 'Modification Réussie',
      请选择要修改的部门: 'Veuillez sélectionner le département à modifier',
      请选择要修改的空间: "Veuillez sélectionner l'espace à modifier",
      已离职成员: 'Membres Partis',
      搜索成员: 'Rechercher des Membres',
      未分配: 'Non Assigné',
      仅显示当前部门直属人员: 'Afficher uniquement les membres directs du département actuel',
      '共{0}条': 'Total {0} éléments',
      验证邮箱: "Vérifier l'Email",
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': "Après modification, le mot de passe de connexion sera réinitialisé automatiquement, et un lien d'invitation sera envoyé à l'email",
      子部门: 'Sous-département',
      暂无数据: 'Pas de Données',
      请输入密码: 'Veuillez saisir le mot de passe',
      请再次输入密码: 'Veuillez ressaisir le mot de passe',
      '两次输入密码不一致!': 'Les mots de passe ne correspondent pas !',
      验证当前已登录邮箱: "Vérifier l'email connecté actuellement",
      绑定成功: 'Liaison Réussie',
      特殊字符不支持: 'Caractères spéciaux non supportés',
      此部门数据已在转移中: 'Les données de ce département sont en cours de transfert',
      此部门没有可转移的数据: 'Aucune donnée transférable dans ce département',
      '该部门下已有成员或部门，不能被删除。': 'Ce département a des membres ou des sous-départements et ne peut pas être supprimé.',
      '此部门数据正在转移中，无法删除': 'Les données de ce département sont en cours de transfert et ne peuvent pas être supprimées',
      交易: 'Transaction',
      '删除部门前需转移部门数据，你没有此部门的': 'Avant de supprimer un département, transférez ses données ;',
      '编辑权限，请先前往': "vous n'avez pas les permissions d'édition pour ce département, veuillez aller à",
      数据权限: 'Permissions de Données',
      页面配置: 'Configuration de la Page',
      无编辑权限: "Pas de Permissions d'Édition",
      我知道了: 'Je Comprends',
      删除前转移部门数据: 'Avant la suppression, transférez les données du département',
      离职: 'Démissionné',
      验证码已过期或未发送: "Le captcha a expiré ou n'a pas été envoyé",
      请先添加验证邮箱: "Veuillez d'abord ajouter un e-mail de vérification",
      是否重置该成员密码: 'Le mot de passe du membre doit-il être réinitialisé ?',
      重置密码: 'Réinitialiser le mot de passe',
      请先添加验证邮箱后再重置密码: 'Veuillez ajouter un e-mail de vérification avant de réinitialiser le mot de passe',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': "Le mot de passe du membre doit-il être réinitialisé ? Un nouveau mot de passe sera envoyé à l'e-mail de vérification du membre après réinitialisation.",
      密码重置成功: 'Réinitialisation du mot de passe réussie',
      查看邮箱验证码: "Voir le captcha de l'e-mail",
      '将于{0}过期': 'Expire à {0}',
      复制: 'Copier',
      '会话、商机默认导入归属人的主空间': "La session et l'opportunité se réfèrent par défaut à l'espace principal du propriétaire",
      '请输入1-32个字符': 'Veuillez entrer de 1 à 32 caractères',
      离职成员: 'Anciens membres',
      添加: 'Ajouter',
      '你没有此{0}的{1}编辑权限': "Vous n'avez pas la permission d'éditer {1} de ce {0}",
      '请先前往{0}页面配置': "Veuillez d'abord configurer la page {0}",
      '还有未转移的数据，确定要将{0}设置为离职吗？': "Il reste des données non transférées, êtes-vous sûr de vouloir définir {0} comme ayant quitté l'entreprise ?",
      '未转移数据将保留，所有者仍展示离职成员': "Les données non transférées seront conservées, et le propriétaire apparaîtra toujours comme un membre ayant quitté l'entreprise",
      请选择数据接收: 'Veuillez choisir le destinataire des données',
      人: 'Personne',
      接收人: 'Destinataire',
      资源: 'Ressources',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': "Le compte et le mot de passe seront automatiquement envoyés à l'adresse e-mail de vérification après l'ajout, si aucune adresse e-mail de vérification n'est renseignée, le membre ne pourra pas se connecter, vous pourrez ajouter une adresse e-mail de vérification plus tard dans l'édition du membre pour inviter à se connecter.",
      '双因素验证方式-验证邮箱': 'Méthode de double authentification - Adresse e-mail de vérification',
      请输入验证邮箱: "Veuillez entrer l'adresse e-mail de vérification",
      登录账号不能为空: "Le nom d'utilisateur de connexion ne peut pas être vide",
      '仅支持字母、数字、下划线': 'Supporte uniquement les lettres, chiffres, et tirets bas',
      '账号长度为4-20字符': "La longueur du nom d'utilisateur doit être de 4 à 20 caractères",
      通知: 'Notification',
      复制失败: 'Échec de la copie',
      '添加成功，账号及密码已发送到验证邮箱': "Ajout réussi, le compte et le mot de passe ont été envoyés à l'adresse e-mail de vérification",
      '添加成功，给该成员添加验证邮箱后可登录': 'Ajout réussi, vous pourrez ajouter une adresse e-mail de vérification pour ce membre pour se connecter ultérieurement',
      添加成功: 'Ajout réussi',
      导入管理员角色: "Importer un rôle d'administrateur",
      导入成员角色: 'Importer un rôle de membre',
      '导入成功{0}条': 'Importation réussie de {0} éléments',
      请输入工作邮箱: "Veuillez entrer l'adresse e-mail professionnelle",
      子部门和成员也将随之移动: 'Les sous-départements et les membres seront également déplacés',
      请选择移动到的部门: 'Veuillez choisir le département de destination',
      请选择要移动后的父部门: 'Veuillez choisir le nouveau département parent après le déplacement',
      从其他部门移入成员: "Ajouter des membres d'autres départements",
      已选成员: 'Membres sélectionnés',
      '加入成功！': 'Ajout réussi !',
      重新选择: 'Sélectionner à nouveau',
      正在导入: 'Importation en cours',
      '请不要刷新或离开，同时保持网络畅通': 'Veuillez ne pas rafraîchir ou quitter la page, tout en maintenant une connexion réseau stable',
      上传失败: "Échec de l'upload",
      '上传失败，请重新上传': "Échec de l'upload, veuillez réessayer",
      重新上传: 'Téléverser à nouveau',
      请选择xlsx或xls格式的文件: 'Veuillez choisir un fichier au format xlsx ou xls',
      解析中: 'Analyse en cours',
      '上传尚未完成。要取消正在进行的上传吗？': "L'upload n'est pas encore terminé. Souhaitez-vous annuler l'upload en cours ?",
      取消上传: "Annuler l'upload",
      继续上传: "Continuer l'upload",
      已取消上传: 'Upload annulé',
      导入完成: 'Importation terminée',
      '成功{0}条': 'Succès pour {0} entrées',
      '失败{0}条': 'Échec pour {0} entrées',
      Excel行号: 'Numéro de ligne Excel',
      失败内容: "Contenu de l'échec",
      失败原因: "Raison de l'échec",
      重新导入: 'Réimporter',
      父部门不存在: "Département Parent N'existe Pas",
      请选择要修改的成员信息: 'Veuillez Sélectionner les Informations du Membre à Modifier',
      文件名称: 'Nom du Fichier',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': "Le fichier que vous exportez sera temporairement stocké dans les enregistrements d'exportation et sera automatiquement supprimé après 3 jours.",
      创建时间: 'Heure de Création',
      请输入登录账号: 'Entrez le numéro de compte',
      确认: 'Confirmer',
      员工数已达上限: "Limite d'employés atteinte",
      申请扩容: 'Demander une extension',
      '上传失败，请重试！': 'Téléversement échoué, veuillez réessayer !',
      '上传头像图片大小不能超过200KB!': 'La taille de la photo de profil ne doit pas dépasser 200 Ko !',
      '上传头像图片格式暂不支持!': "Le format de la photo de profil n'est pas pris en charge !",
      重新发送: 'Renvoyer',
      '您的权限已发生变动，是否刷新页面？': 'Vos permissions ont changé, souhaitez-vous rafraîchir la page ?'
    }
  },
  de: {
    management: {
      个人中心: 'Persönliches Zentrum',
      个人信息: 'Persönliche Informationen',
      应用: 'Anwendungen',
      导出记录: 'Exportprotokolle',
      姓名: 'Name',
      头像: 'Avatar',
      上传: 'Hochladen',
      '支持jpg、jpeg、png格式小于200KB': 'Unterstützt jpg, jpeg, png Formate, weniger als 200KB',
      工作邮箱: 'Arbeitsemail',
      请输入邮箱: 'Bitte Email eingeben',
      '工作邮箱仅为个人信息项，不是登录账号': 'Arbeitsemail dient nur persönlichen Informationen, nicht zum Einloggen',
      主部门: 'Hauptabteilung',
      保存修改: 'Änderungen speichern',
      修改信息成功: 'Informationen erfolgreich aktualisiert',
      请输入正确的邮箱: 'Bitte geben Sie eine gültige Email ein',
      姓名不能为空: 'Name darf nicht leer sein',
      登录账号: 'Login-Konto',
      登录手机号: 'Login-Handynummer',
      登录邮箱: 'Login-Email',
      登录密码: 'Login-Passwort',
      验证当前已登录手机号: 'Aktuell eingeloggte Handynummer verifizieren',
      请输入验证码: 'Bitte Captcha eingeben',
      发送验证码: 'Captcha senden',
      验证码不能为空: 'Captcha darf nicht leer sein',
      验证码格式不正确: 'Captcha-Format ist falsch',
      取消: 'Abbrechen',
      下一步: 'Nächster Schritt',
      拖动下方滑块完成拼图: 'Ziehen Sie den Schieberegler unten, um das Puzzle zu vervollständigen',
      '只用了{0}s，简直比闪电还快': 'In nur {0} Sekunden abgeschlossen, schneller als der Blitz',
      请控制拼图块对齐缺口: 'Bitte passen Sie das Puzzleteil in die Lücke ein',
      '这题有点难呢，已为您更换题目': 'Dieses ist ein wenig herausfordernd, wir haben die Frage für Sie geändert',
      绑定新手机号: 'Neue Handynummer binden',
      请输入正确的手机号: 'Bitte geben Sie die korrekte Handynummer ein',
      '账号已被绑定，请确认账户信息': 'Konto wurde gebunden, bitte Kontoinformationen bestätigen',
      '验证码已发送，请查收': 'Captcha gesendet, bitte prüfen',
      绑定新邮箱: 'Neue Email binden',
      确定: 'Bestätigen',
      '您未设置登录密码，请': 'Sie haben kein Login-Passwort festgelegt, bitte',
      设置: 'Setzen',
      修改: 'Ändern',
      绑定: 'Binden',
      设置密码: 'Passwort festlegen',
      设置新密码: 'Neues Passwort festlegen',
      请输入新密码: 'Bitte neues Passwort eingeben',
      '8-20个字符': '8-20 Zeichen',
      包含数字: 'Beinhaltet Zahlen',
      包含字母: 'Beinhaltet Buchstaben',
      包含特殊字符: 'Beinhaltet Sonderzeichen',
      确认新密码: 'Neues Passwort bestätigen',
      请再次输入新密码: 'Bitte neues Passwort erneut eingeben',
      保存: 'Speichern',
      密码设置成功: 'Passwort erfolgreich festgelegt',
      修改密码: 'Passwort ändern',
      当前账户密码: 'Aktuelles Kontopasswort',
      切换为验证码验证: 'Zu Captcha-Verifizierung wechseln',
      请输入当前账户密码: 'Bitte aktuelles Kontopasswort eingeben',
      保存更改: 'Änderungen speichern',
      两次密码输入不一致: ' Die beiden Passworteingaben stimmen nicht überein.',
      密码修改成功: 'Passwort erfolgreich geändert',
      切换为密码验证: 'Zu Passwort-Verifizierung wechseln',
      部门和成员: 'Abteilungen und Mitglieder',
      部门和成员管理: 'Abteilungs- und Mitgliederverwaltung',
      组织架构: 'Organisationsstruktur',
      添加子部门: 'Unterabteilung hinzufügen',
      重命名: 'Umbenennen',
      设置部门负责人: 'Abteilungsleiter festlegen',
      部门负责人拥有部门内所有数据权限: 'Abteilungsleiter hat alle Datenberechtigungen innerhalb der Abteilung',
      导出部门: 'Abteilung exportieren',
      移动部门: 'Abteilung verschieben',
      转移数据: 'Daten übertragen',
      删除部门: 'Abteilung löschen',
      移动成功: 'Verschiebung erfolgreich',
      此部门没有可转移数据: 'Keine übertragbaren Daten in dieser Abteilung',
      转移部门数据: 'Abteilungsdaten übertragen',
      请选择要转移的数据: 'Bitte zu übertragende Daten auswählen',
      会话: 'Konversationen',
      '{0}条': '{0} Elemente',
      转移部门不影响数据归属成员: 'Abteilungswechsel beeinflusst nicht den Datenbesitz',
      数据: 'Daten',
      接收部门: 'Empfangende Abteilung',
      转移: 'Übertragen',
      '确定要转移数据吗？': 'Sind Sie sicher, dass Sie Daten übertragen möchten?',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'Übertragung hat begonnen, bitte geduldig warten, Sie werden benachrichtigt, wenn abgeschlossen',
      会话转移归属部门已完成: 'Abteilungsübertragung von Konversationen abgeschlossen',
      转移部门: 'Übertragene Abteilung',
      '会话转移成功：{0}条': 'Erfolgreiche Konversationsübertragung: {0} Elemente',
      '确定导出部门信息吗？': 'Sind Sie sicher, dass Sie Abteilungsinformationen exportieren möchten?',
      导出成功: 'Export erfolgreich',
      '部门列表_{0}已经准备完成。': 'Abteilungsliste_{0} ist fertig.',
      查看导出记录: 'Exportprotokolle anzeigen',
      下载: 'Herunterladen',
      '是否删除该部门？': 'Diese Abteilung löschen?',
      删除: 'Löschen',
      删除成功: 'Erfolgreich gelöscht',
      提示: 'Hinweis',
      '您的权限已发生变动，是否刷新页面': 'Ihre Berechtigungen haben sich geändert, Seite aktualisieren?',
      刷新: 'Aktualisieren',
      的成员列表: 'Mitgliederliste',
      部门负责人: 'Abteilungsleiter',
      未设置: 'Nicht festgelegt',
      '公司已使用/人数上限': 'Firmennutzung/Maximale Benutzeranzahl',
      已使用和上限说明: '"Nutzung" ist die Summe von aktiven, deaktivierten und nie eingeloggten Konten; "Maximale Benutzeranzahl" bezieht sich auf das Kontolimit für jede Firma.',
      导出成员: 'Mitglieder exportieren',
      '确定导出当前{0}个成员信息吗？': 'Sind Sie sicher, dass Sie Informationen für die aktuellen {0} Mitglieder exportieren möchten?',
      导出文件: 'Exportdatei',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'Datei vorbereitet wird, können Sie in Personal Center-{0}',
      搜索成员姓名或登录账号: 'Name suchen',
      请选择空间: 'usgewählter Raum',
      是否登录: 'Login-Status',
      已登录: 'Eingeloggt',
      未登录: 'Nicht eingeloggt',
      全部状态: 'Alle Status',
      启用: 'Aktivieren',
      禁用: 'Deaktivieren',
      全部类型: 'Alle Typen',
      正式版: 'Offizielle Version',
      试用版: 'Testversion',
      登录账户: 'Login-Konto',
      部门: 'Abteilung',
      空间: 'Bereich',
      主空间: 'Hauptbereich',
      状态: 'Status',
      已启用: 'Aktiviert',
      已禁用: 'Deaktiviert',
      账号类型: 'Kontotyp',
      到期时间: 'Ablaufdatum',
      操作: 'Aktion',
      编辑: 'Bearbeiten',
      编辑成员信息: 'Mitgliedsinformationen bearbeiten',
      '此邮箱仅为个人信息项，不是登录账号': 'Diese Email dient nur persönlichen Informationen, nicht zum Einloggen',
      添加部门: 'Abteilung hinzufügen',
      确认删除: 'Bestätigen Sie das Löschen',
      添加空间: 'Bereich hinzufügen',
      设置离职: 'Abgang festlegen',
      '确定要将{0}设置为离职吗？': 'Sind Sie sicher, dass Sie {0} als abgegangen festlegen möchten?',
      离职成功: 'Abgang erfolgreich',
      请选择部门: 'Bitte wählen Sie eine Abteilung aus',
      请输入正确的邮箱地址: 'Bitte geben Sie eine gültige Email-Adresse ein',
      保存成功: 'Erfolgreich gespeichert',
      此成员没有可转移的数据: 'Dieses Mitglied hat keine übertragbaren Daten',
      转移成员数据: 'Mitgliedsdaten übertragen',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': 'Zugehörige Abteilung und Bereich werden auf die Hauptabteilung und den Hauptbereich des neuen Besitzers aktualisiert',
      操作离职: 'Abgang durchführen',
      '是否禁用{0}账号？': '{0} Konto deaktivieren?',
      该账号将无法登录Megaview访问本企业: 'Dieses Konto wird keinen Zugang zum aktuellen Unternehmen haben',
      系统会保留该员工的所有数据: 'Das System wird alle Daten des Mitarbeiters behalten',
      '禁用期间，组织内员工依然可以查看该员工数据': 'Während der Deaktivierungszeit können andere Mitarbeiter innerhalb der Organisation weiterhin auf die Daten dieses Mitarbeiters zugreifen',
      禁用账号可以启用恢复: 'Deaktivierte Konten können wieder aktiviert und wiederhergestellt werden',
      '确定要启用{0}账号？': 'Sind Sie sicher, dass Sie das Konto {0} aktivieren möchten?',
      邀请新成员: 'Neue Mitglieder einladen',
      添加新成员: 'Neue Mitglieder hinzufügen',
      请输入姓名: 'Bitte geben Sie den Namen ein',
      '手机号/邮箱': 'Handynummer/Email',
      手机号: 'Handynummer',
      请输入手机号: 'Bitte Handynummer eingeben',
      邮箱: 'Email',
      请选择主部门: 'Bitte Hauptabteilung auswählen',
      请选择主空间: 'Bitte Hauptbereich auswählen',
      确定并继续添加: 'Bestätigen und weiter hinzufügen',
      邀请已发送: 'Einladung gesendet',
      您也可以通过发送链接告知团队成员: 'Sie können auch den Link senden, um Teammitglieder zu informieren',
      '': 'Abteilungs-ID',
      复制邀请: 'Einladung kopieren',
      复制成功: 'Kopieren erfolgreich',
      完成: 'Fertig',
      手机号不能为空: 'Handynummer darf nicht leer sein',
      邮箱不能为空: 'Email darf nicht leer sein',
      导入成员: 'Mitglieder importieren',
      '点击下载按钮，下载需要导入的模板文件': 'Klicken Sie auf den Download-Button, um die Vorlagendatei für den Import herunterzuladen',
      '请仔细阅读模板中的说明，': 'Bitte lesen Sie die Anweisungen im Template sorgfältig durch',
      并按要求格式填写导入的数据: 'und füllen Sie die zu importierenden Daten gemäß den Anforderungen aus',
      下载模板: 'Vorlage herunterladen',
      '文件填写完成后，在下方区域上传文件': 'Nachdem Sie die Datei ausgefüllt haben, laden Sie sie im untenstehenden Bereich hoch',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': 'Das System wird die Genauigkeit des Datenformats überprüfen und nach der Überprüfung werden die Daten in das System importiert',
      点击或将文件拖拽到这里上传: 'Klicken oder Dateien hierher ziehen zum Hochladen',
      '支持扩展名：': 'Unterstützte Erweiterungen:',
      导入: 'Importieren',
      导入部门: 'Abteilung importieren',
      批量修改成员信息: 'Mitgliederinformationen massenhaft ändern',
      成员: 'Mitglieder',
      请选择成员: 'Bitte Mitglieder auswählen',
      请选择要修改的信息: 'Bitte auswählen, welche Informationen geändert werden sollen',
      '修改成员的信息，会清空之前的信息，替换为新的': 'Ändern von Mitgliederinformationen wird vorherige Informationen löschen und mit neuen ersetzen',
      修改成功: 'Erfolgreich geändert',
      请选择要修改的部门: 'Bitte wählen Sie die zu ändernde Abteilung aus',
      请选择要修改的空间: 'Bitte wählen Sie den zu ändernden Bereich aus',
      已离职成员: 'Ausgeschiedene Mitglieder',
      搜索成员: 'Mitglieder suchen',
      未分配: 'Nicht zugewiesen',
      仅显示当前部门直属人员: 'Nur direkte Mitglieder der aktuellen Abteilung anzeigen',
      '共{0}条': 'Insgesamt {0} Elemente',
      验证邮箱: 'Email verifizieren',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': 'Nach Änderung wird das Login-Passwort automatisch zurückgesetzt und ein Einladungslink an die Email gesendet',
      子部门: 'Unterabteilung',
      暂无数据: 'Keine Daten',
      请输入密码: 'Bitte Passwort eingeben',
      请再次输入密码: 'Bitte Passwort erneut eingeben',
      '两次输入密码不一致!': 'Passwörter stimmen nicht überein!',
      验证当前已登录邮箱: 'Aktuell eingeloggte Email verifizieren',
      绑定成功: 'Bindung erfolgreich',
      特殊字符不支持: 'Sonderzeichen nicht unterstützt',
      此部门数据已在转移中: 'Daten dieser Abteilung werden gerade übertragen',
      此部门没有可转移的数据: 'Keine übertragbaren Daten in dieser Abteilung',
      '该部门下已有成员或部门，不能被删除。': 'Diese Abteilung hat Mitglieder oder Unterabteilungen und kann nicht gelöscht werden.',
      '此部门数据正在转移中，无法删除': 'Daten dieser Abteilung werden übertragen und können nicht gelöscht werden',
      交易: 'Transaktion',
      '删除部门前需转移部门数据，你没有此部门的': 'Vor dem Löschen einer Abteilung deren Daten übertragen;',
      '编辑权限，请先前往': 'Sie haben keine Bearbeitungsberechtigungen für diese Abteilung, bitte gehen Sie zu',
      数据权限: 'Datenberechtigungen',
      页面配置: 'Seitenkonfiguration',
      无编辑权限: 'Keine Bearbeitungsberechtigungen',
      我知道了: 'Ich verstehe',
      删除前转移部门数据: 'Vor Löschung Abteilungsdaten übertragen',
      离职: 'Ausgeschieden',
      验证码已过期或未发送: 'Das Captcha ist abgelaufen oder wurde nicht gesendet',
      请先添加验证邮箱: 'Bitte fügen Sie zuerst eine Verifizierungs-E-Mail hinzu',
      是否重置该成员密码: 'Soll das Passwort des Mitglieds zurückgesetzt werden?',
      重置密码: 'Passwort zurücksetzen',
      请先添加验证邮箱后再重置密码: 'Bitte fügen Sie eine Verifizierungs-E-Mail hinzu, bevor Sie das Passwort zurücksetzen',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': 'Soll das Passwort des Mitglieds zurückgesetzt werden? Ein neues Passwort wird nach dem Zurücksetzen an die Verifizierungs-E-Mail des Mitglieds gesendet.',
      密码重置成功: 'Passwort erfolgreich zurückgesetzt',
      查看邮箱验证码: 'E-Mail-Captcha anzeigen',
      '将于{0}过期': 'Läuft ab um {0}',
      复制: 'Kopieren',
      '会话、商机默认导入归属人的主空间': 'Sitzung und Gelegenheit sind standardmäßig im Hauptbereich des Eigentümers',
      '请输入1-32个字符': 'Bitte geben Sie 1-32 Zeichen ein',
      离职成员: 'Ehemalige Mitglieder',
      添加: 'Hinzufügen',
      '你没有此{0}的{1}编辑权限': 'Sie haben keine Bearbeitungsberechtigung für {1} von {0}',
      '请先前往{0}页面配置': 'Bitte gehen Sie zuerst zur Konfigurationsseite von {0}',
      '还有未转移的数据，确定要将{0}设置为离职吗？': 'Es gibt noch nicht übertragene Daten, sind Sie sicher, dass Sie {0} als ausgeschieden markieren möchten?',
      '未转移数据将保留，所有者仍展示离职成员': 'Nicht übertragene Daten bleiben erhalten, der Eigentümer wird weiterhin als ausgeschiedenes Mitglied angezeigt',
      请选择数据接收: 'Bitte wählen Sie den Datenempfänger',
      人: 'Person',
      接收人: 'Empfänger',
      资源: 'Ressourcen',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': 'Konto und Passwort werden nach Hinzufügen einer Bestätigungs-E-Mail automatisch an diese gesendet. Wenn keine Bestätigungs-E-Mail angegeben ist, kann das Mitglied sich nicht anmelden. Eine Bestätigungs-E-Mail kann später im Mitglieder-Editor hinzugefügt und eine Einladung zum Login versendet werden.',
      '双因素验证方式-验证邮箱': 'Zweifaktor-Authentifizierungsmethode - Bestätigungs-E-Mail',
      请输入验证邮箱: 'Bitte geben Sie die Bestätigungs-E-Mail ein',
      登录账号不能为空: 'Login-Konto darf nicht leer sein',
      '仅支持字母、数字、下划线': 'Unterstützt nur Buchstaben, Zahlen, Unterstriche',
      '账号长度为4-20字符': 'Kontolänge von 4-20 Zeichen',
      通知: 'Benachrichtigung',
      复制失败: 'Kopieren fehlgeschlagen',
      '添加成功，账号及密码已发送到验证邮箱': 'Erfolgreich hinzugefügt, Konto und Passwort wurden an die Bestätigungs-E-Mail gesendet',
      '添加成功，给该成员添加验证邮箱后可登录': 'Erfolgreich hinzugefügt, fügen Sie eine Bestätigungs-E-Mail für das Mitglied hinzu, um sich anzumelden',
      添加成功: 'Erfolgreich hinzugefügt',
      导入管理员角色: 'Administratorrolle importieren',
      导入成员角色: 'Mitgliederrolle importieren',
      '导入成功{0}条': 'Erfolgreich {0} Einträge importiert',
      请输入工作邮箱: 'Bitte geben Sie die Arbeits-E-Mail ein',
      子部门和成员也将随之移动: 'Unterabteilungen und Mitglieder werden ebenfalls verschoben',
      请选择移动到的部门: 'Bitte wählen Sie die Abteilung, in die verschoben werden soll',
      请选择要移动后的父部门: 'Bitte wählen Sie die übergeordnete Abteilung nach dem Umzug',
      从其他部门移入成员: 'Mitglieder aus anderen Abteilungen hinzufügen',
      已选成员: 'Ausgewählte Mitglieder',
      '加入成功！': 'Erfolgreich beigetreten!',
      重新选择: 'Auswahl',
      正在导入: 'Importieren',
      '请不要刷新或离开，同时保持网络畅通': 'Bitte aktualisieren Sie die Seite nicht und verlassen Sie sie nicht, stellen Sie außerdem eine stabile Internetverbindung sicher',
      上传失败: 'Hochladen fehlgeschlagen',
      '上传失败，请重新上传': 'Hochladen fehlgeschlagen, bitte erneut hochladen',
      重新上传: 'Erneut hochladen',
      请选择xlsx或xls格式的文件: 'Bitte wählen Sie eine Datei im xlsx- oder xls-Format aus',
      解析中: 'Verarbeitung',
      '上传尚未完成。要取消正在进行的上传吗？': 'Der Upload ist noch nicht abgeschlossen. Möchten Sie den laufenden Upload abbrechen?',
      取消上传: 'Upload abbrechen',
      继续上传: 'Upload fortsetzen',
      已取消上传: 'Upload abgebrochen',
      导入完成: 'Import abgeschlossen',
      '成功{0}条': 'Erfolgreiche Einträge {0}',
      '失败{0}条': 'Fehlgeschlagene Einträge {0}',
      Excel行号: 'Excel-Zeilennummer',
      失败内容: 'Fehlerdetails',
      失败原因: 'Fehlergrund',
      重新导入: 'Erneut importieren',
      父部门不存在: 'Übergeordnete Abteilung existiert nicht',
      请选择要修改的成员信息: 'Bitte wählen Sie die Mitgliederinformationen zur Änderung aus',
      文件名称: 'Dateiname',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': 'Die von Ihnen exportierte Datei wird vorübergehend in den Exportaufzeichnungen gespeichert und nach 3 Tagen automatisch gelöscht.',
      创建时间: 'Erstellungszeit',
      请输入登录账号: 'Kontonummer eingeben',
      确认: 'Bestätigen',
      员工数已达上限: 'Mitarbeiterlimit erreicht',
      申请扩容: 'Erweiterung beantragen',
      '上传失败，请重试！': 'Hochladen fehlgeschlagen, bitte erneut versuchen!',
      '上传头像图片大小不能超过200KB!': 'Profilbildgröße darf 200KB nicht überschreiten!',
      '上传头像图片格式暂不支持!': 'Profilbildformat wird nicht unterstützt!',
      重新发送: 'Erneut senden',
      '您的权限已发生变动，是否刷新页面？': 'Ihre Berechtigungen haben sich geändert, möchten Sie die Seite aktualisieren?'
    }
  },
  es: {
    management: {
      个人中心: 'Centro Personal',
      个人信息: 'Información Personal',
      应用: 'Aplicaciones',
      导出记录: 'Registros de Exportación',
      姓名: 'Nombre',
      头像: 'Avatar',
      上传: 'Subir',
      '支持jpg、jpeg、png格式小于200KB': 'Soporta formatos jpg, jpeg, png, menos de 200KB',
      工作邮箱: 'Correo de Trabajo',
      请输入邮箱: 'Por favor, introduce un correo electrónico',
      '工作邮箱仅为个人信息项，不是登录账号': 'El correo de trabajo es solo para información personal, no para iniciar sesión',
      主部门: 'Departamento Principal',
      保存修改: 'Guardar Cambios',
      修改信息成功: 'Información actualizada con éxito',
      请输入正确的邮箱: 'Por favor, introduce un correo electrónico válido',
      姓名不能为空: 'El nombre no puede estar vacío',
      登录账号: 'Cuenta de Inicio de Sesión',
      登录手机号: 'Número de Móvil de Inicio de Sesión',
      登录邮箱: 'Correo Electrónico de Inicio de Sesión',
      登录密码: 'Contraseña de Inicio de Sesión',
      验证当前已登录手机号: 'Verificar el número de móvil actualmente conectado',
      请输入验证码: 'Por favor, introduce el captcha',
      发送验证码: 'Enviar captcha',
      验证码不能为空: 'El captcha no puede estar vacío',
      验证码格式不正确: 'El formato del captcha es incorrecto',
      取消: 'Cancelar',
      下一步: 'Siguiente Paso',
      拖动下方滑块完成拼图: 'Arrastra el deslizador abajo para completar el rompecabezas',
      '只用了{0}s，简直比闪电还快': 'Completado en solo {0} segundos, más rápido que un rayo',
      请控制拼图块对齐缺口: 'Por favor, alinea la pieza del rompecabezas con el hueco',
      '这题有点难呢，已为您更换题目': 'Este es un poco desafiante, hemos cambiado la pregunta para ti',
      绑定新手机号: 'Vincular Nuevo Número de Móvil',
      请输入正确的手机号: 'Por favor, introduce el número de móvil correcto',
      '账号已被绑定，请确认账户信息': 'La cuenta ha sido vinculada, por favor confirma la información de la cuenta',
      '验证码已发送，请查收': 'Captcha enviado, por favor revisa',
      绑定新邮箱: 'Vincular Nuevo Correo Electrónico',
      确定: 'Confirmar',
      '您未设置登录密码，请': 'No has establecido una contraseña de inicio de sesión, por favor',
      设置: 'Establecer',
      修改: 'Modificar',
      绑定: 'Vincular',
      设置密码: 'Establecer Contraseña',
      设置新密码: 'Establecer Nueva Contraseña',
      请输入新密码: 'Por favor, introduce la nueva contraseña',
      '8-20个字符': '8-20 caracteres',
      包含数字: 'Incluye números',
      包含字母: 'Incluye letras',
      包含特殊字符: 'Incluye caracteres especiales',
      确认新密码: 'Confirmar Nueva Contraseña',
      请再次输入新密码: 'Por favor, reintroduce la nueva contraseña',
      保存: 'Guardar',
      密码设置成功: 'Contraseña establecida con éxito',
      修改密码: 'Cambiar Contraseña',
      当前账户密码: 'Contraseña Actual de la Cuenta',
      切换为验证码验证: 'Cambiar a verificación por captcha',
      请输入当前账户密码: 'Por favor, introduce la contraseña actual de la cuenta',
      保存更改: 'Guardar Cambios',
      两次密码输入不一致: 'Las dos entradas de contraseña no coinciden.',
      密码修改成功: 'Contraseña cambiada con éxito',
      切换为密码验证: 'Cambiar a verificación por contraseña',
      部门和成员: 'Departamentos y Miembros',
      部门和成员管理: 'Gestión de Departamentos y Miembros',
      组织架构: 'Estructura Organizacional',
      添加子部门: 'Añadir Subdepartamento',
      重命名: 'Renombrar',
      设置部门负责人: 'Establecer Jefe de Departamento',
      部门负责人拥有部门内所有数据权限: 'El jefe de departamento tiene todos los permisos de datos dentro del departamento',
      导出部门: 'Exportar Departamento',
      移动部门: 'Mover departamento',
      转移数据: 'Transferir Datos',
      删除部门: 'Eliminar Departamento',
      移动成功: 'Movimiento Exitoso',
      此部门没有可转移数据: 'No hay datos transferibles en este departamento',
      转移部门数据: 'Transferir Datos del Departamento',
      请选择要转移的数据: 'Por favor, selecciona los datos a transferir',
      会话: 'Conversaciones',
      '{0}条': '{0} elementos',
      转移部门不影响数据归属成员: 'Transferir departamentos no afecta la propiedad de los datos',
      数据: 'Datos',
      接收部门: 'Departamento Receptor',
      转移: 'Transferir',
      '确定要转移数据吗？': '',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'La transferencia ha comenzado, por favor espera pacientemente, serás notificado al completarse',
      会话转移归属部门已完成: 'Transferencia de conversaciones del departamento completada',
      转移部门: 'Departamento Transferido',
      '会话转移成功：{0}条': 'Transferencia de conversaciones exitosa: {0} elementos',
      '确定导出部门信息吗？': '',
      导出成功: 'Exportación Exitosa',
      '部门列表_{0}已经准备完成。': 'Lista de Departamentos_{0} está lista.',
      查看导出记录: 'Ver Registros de Exportación',
      下载: 'Descargar',
      '是否删除该部门？': '',
      删除: 'Eliminar',
      删除成功: 'Eliminado con Éxito',
      提示: 'Aviso',
      '您的权限已发生变动，是否刷新页面': '',
      刷新: 'Refrescar',
      的成员列表: 'Lista de miembros',
      部门负责人: 'Jefe de Departamento',
      未设置: 'No Establecido',
      '公司已使用/人数上限': 'Uso de la Empresa/Número Máximo de Usuarios',
      已使用和上限说明: '"Uso" es la suma de cuentas activas, deshabilitadas y nunca iniciadas; "Número Máximo de Usuarios" se refiere al límite de cuentas para cada empresa.',
      导出成员: 'Exportar Miembros',
      '确定导出当前{0}个成员信息吗？': '¿Estás seguro de que quieres exportar información de los actuales {0} miembros?',
      导出文件: 'Exportar Archivo',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'Se está preparando el expediente, puede comprobarlo en el Centro Personal-{0}',
      搜索成员姓名或登录账号: 'Buscar nombre',
      请选择空间: 'espacio seleccionado',
      是否登录: 'estado de conexión',
      已登录: 'Conectado',
      未登录: 'No Conectado',
      全部状态: 'Todos los Estados',
      启用: 'Habilitar',
      禁用: 'Deshabilitar',
      全部类型: 'Todos los Tipos',
      正式版: 'Versión Oficial',
      试用版: 'Versión de Prueba',
      登录账户: 'Cuenta de Inicio de Sesión',
      部门: 'Departamento',
      空间: 'Espacio',
      主空间: 'Espacio Principal',
      状态: 'Estado',
      已启用: 'Habilitado',
      已禁用: 'Deshabilitado',
      账号类型: 'Tipo de Cuenta',
      到期时间: 'Fecha de Expiración',
      操作: 'Acción',
      编辑: 'Editar',
      编辑成员信息: 'Editar Información del Miembro',
      '此邮箱仅为个人信息项，不是登录账号': 'Este correo es solo para información personal, no para iniciar sesión',
      添加部门: 'Añadir Departamento',
      确认删除: 'Confirmar eliminación',
      添加空间: 'Añadir Espacio',
      设置离职: 'Establecer Salida',
      '确定要将{0}设置为离职吗？': '¿Estás seguro de que quieres establecer la salida de {0}?',
      离职成功: 'Salida Exitosa',
      请选择部门: 'Por favor, selecciona un departamento',
      请输入正确的邮箱地址: 'Por favor, introduce una dirección de correo electrónico válida',
      保存成功: 'Guardado con Éxito',
      此成员没有可转移的数据: 'Este miembro no tiene datos transferibles',
      转移成员数据: 'Transferir Datos del Miembro',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': 'El departamento y espacio de pertenencia se actualizarán al departamento y espacio principal del nuevo propietario',
      操作离职: 'Operar Salida',
      '是否禁用{0}账号？': '¿Deshabilitar la cuenta de {0}?',
      该账号将无法登录Megaview访问本企业: 'Esta cuenta no podrá acceder a la empresa actual',
      系统会保留该员工的所有数据: 'El sistema retendrá todos los datos del empleado',
      '禁用期间，组织内员工依然可以查看该员工数据': 'Durante el período de desactivación, otros empleados dentro de la organización aún pueden ver los datos de este empleado',
      禁用账号可以启用恢复: 'Las cuentas desactivadas pueden ser habilitadas y restauradas',
      '确定要启用{0}账号？': '',
      邀请新成员: 'Invitar Nuevos Miembros',
      添加新成员: 'Añadir Nuevos Miembros',
      请输入姓名: 'Por favor, introduce el nombre',
      '手机号/邮箱': 'Número de Móvil/Correo Electrónico',
      手机号: 'Número de Móvil',
      请输入手机号: 'Por favor, introduce el número de móvil',
      邮箱: 'Correo Electrónico',
      请选择主部门: 'Por favor, selecciona el departamento principal',
      请选择主空间: 'Por favor, selecciona el espacio principal',
      确定并继续添加: 'Confirmar y continuar añadiendo',
      邀请已发送: 'Invitación Enviada',
      您也可以通过发送链接告知团队成员: 'También puedes enviar el enlace para informar a los miembros del equipo',
      '': 'ID del Departamento',
      复制邀请: 'Copiar Invitación',
      复制成功: 'Copia Exitosa',
      完成: 'Completar',
      手机号不能为空: 'El número de móvil no puede estar vacío',
      邮箱不能为空: 'El correo electrónico no puede estar vacío',
      导入成员: 'Importar Miembros',
      '点击下载按钮，下载需要导入的模板文件': 'Haz clic en el botón de descarga para descargar el archivo de plantilla necesario para la importación',
      '请仔细阅读模板中的说明，': 'Por favor, lee atentamente las instrucciones en la plantilla,',
      并按要求格式填写导入的数据: 'y rellena los datos de importación según el formato requerido.',
      下载模板: 'Descargar Plantilla',
      '文件填写完成后，在下方区域上传文件': 'Después de llenar el archivo, súbelo en el área de abajo',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': 'El sistema verificará la precisión del formato de los datos, y después de la verificación, los datos serán importados al sistema',
      点击或将文件拖拽到这里上传: 'Haz clic o arrastra archivos aquí para subir',
      '支持扩展名：': 'Extensiones soportadas:',
      导入: 'Importar',
      导入部门: 'Importar Departamento',
      批量修改成员信息: 'Modificar en Masa la Información del Miembro',
      成员: 'Miembros',
      请选择成员: 'Por favor, selecciona los miembros',
      请选择要修改的信息: 'Por favor, selecciona la información a modificar',
      '修改成员的信息，会清空之前的信息，替换为新的': 'Modificar la información del miembro borrará la información previa y la reemplazará con la nueva',
      修改成功: 'Modificado con Éxito',
      请选择要修改的部门: 'Por favor, selecciona el departamento a modificar',
      请选择要修改的空间: 'Por favor, selecciona el espacio a modificar',
      已离职成员: 'Miembros que Han Salido',
      搜索成员: 'Buscar Miembros',
      未分配: 'No Asignado',
      仅显示当前部门直属人员: 'Mostrar solo miembros directos del departamento actual',
      '共{0}条': 'Total {0} elementos',
      验证邮箱: 'Verificar Correo Electrónico',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': 'Después de la modificación, la contraseña de inicio de sesión se restablecerá automáticamente y se enviará un enlace de invitación al correo electrónico',
      子部门: 'Subdepartamento',
      暂无数据: 'Sin Datos',
      请输入密码: 'Por favor, introduce la contraseña',
      请再次输入密码: 'Por favor, reintroduce la contraseña',
      '两次输入密码不一致!': '',
      验证当前已登录邮箱: 'Verificar el correo electrónico actualmente conectado',
      绑定成功: 'Vinculación Exitosa',
      特殊字符不支持: 'Caracteres especiales no soportados',
      此部门数据已在转移中: 'Los datos de este departamento están en proceso de ser transferidos',
      此部门没有可转移的数据: 'No hay datos transferibles en este departamento',
      '该部门下已有成员或部门，不能被删除。': 'Este departamento tiene miembros o subdepartamentos y no puede ser eliminado.',
      '此部门数据正在转移中，无法删除': 'Los datos de este departamento están siendo transferidos y no pueden ser eliminados',
      交易: 'Transacción',
      '删除部门前需转移部门数据，你没有此部门的': 'Antes de eliminar un departamento, transfiere sus datos;',
      '编辑权限，请先前往': 'no tienes permisos de edición para este departamento, por favor ve a',
      数据权限: 'Permisos de Datos',
      页面配置: 'Configuración de Página',
      无编辑权限: 'Sin Permisos de Edición',
      我知道了: 'Entiendo',
      删除前转移部门数据: 'Antes de la eliminación, transfiere los datos del departamento',
      离职: 'Renunciado',
      验证码已过期或未发送: 'El captcha ha expirado o no fue enviado',
      请先添加验证邮箱: 'Por favor, añada primero un correo electrónico de verificación',
      是否重置该成员密码: '',
      重置密码: 'Restablecer Contraseña',
      请先添加验证邮箱后再重置密码: 'Por favor, añada un correo electrónico de verificación antes de restablecer la contraseña',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': '',
      密码重置成功: 'Restablecimiento de contraseña exitoso',
      查看邮箱验证码: 'Ver captcha de correo electrónico',
      '将于{0}过期': 'Expirará a las {0}',
      复制: 'Copiar',
      '会话、商机默认导入归属人的主空间': 'La sesión y la oportunidad se asignan por defecto al espacio principal del propietario',
      '请输入1-32个字符': 'Por favor, introduzca de 1 a 32 caracteres',
      离职成员: 'Exmiembros',
      添加: 'Añadir',
      '你没有此{0}的{1}编辑权限': 'No tienes permiso de edición de {1} para este {0}',
      '请先前往{0}页面配置': 'Por favor, ve primero a la configuración de la página de {0}',
      '还有未转移的数据，确定要将{0}设置为离职吗？': '',
      '未转移数据将保留，所有者仍展示离职成员': 'Los datos no transferidos se conservarán, y el propietario seguirá apareciendo como miembro dado de baja',
      请选择数据接收: 'Por favor, selecciona la recepción de datos',
      人: 'Persona',
      接收人: 'Receptor',
      资源: 'Recursos',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': 'La cuenta y contraseña se enviarán automáticamente al correo electrónico de verificación después de añadirlo, si no se proporciona un correo electrónico de verificación, el miembro no podrá iniciar sesión, y se podrá invitar a iniciar sesión añadiendo un correo electrónico de verificación más adelante en la edición de miembros.',
      '双因素验证方式-验证邮箱': 'Método de verificación de doble factor - Correo electrónico de verificación',
      请输入验证邮箱: 'Por favor, introduce el correo electrónico de verificación',
      登录账号不能为空: 'La cuenta de inicio de sesión no puede estar vacía',
      '仅支持字母、数字、下划线': 'Solo se admiten letras, números y guiones bajos',
      '账号长度为4-20字符': 'La longitud de la cuenta debe ser de 4-20 caracteres',
      通知: 'Notificación',
      复制失败: 'Copia fallida',
      '添加成功，账号及密码已发送到验证邮箱': 'Añadido con éxito, la cuenta y contraseña han sido enviadas al correo electrónico de verificación',
      '添加成功，给该成员添加验证邮箱后可登录': 'Añadido con éxito, se podrá iniciar sesión después de añadir un correo electrónico de verificación al miembro',
      添加成功: 'Añadido con éxito',
      导入管理员角色: 'Importar rol de administrador',
      导入成员角色: 'Importar rol de miembro',
      '导入成功{0}条': 'Importación exitosa de {0} registros',
      请输入工作邮箱: 'Por favor, introduce el correo electrónico de trabajo',
      子部门和成员也将随之移动: 'Los subdepartamentos y miembros también se moverán',
      请选择移动到的部门: 'Por favor, selecciona el departamento al que mover',
      请选择要移动后的父部门: 'Por favor, selecciona el departamento padre después de mover',
      从其他部门移入成员: 'Incorporar miembros de otros departamentos',
      已选成员: 'Miembros seleccionados',
      '加入成功！': '',
      重新选择: 'Reelección',
      正在导入: 'Importando',
      '请不要刷新或离开，同时保持网络畅通': 'Por favor, no actualice ni abandone la página, y asegúrese de tener una conexión a internet estable',
      上传失败: 'Carga Fallida',
      '上传失败，请重新上传': 'La carga ha fallado, por favor vuelva a subir el archivo',
      重新上传: 'Volver a cargar',
      请选择xlsx或xls格式的文件: 'Seleccione un archivo en formato xlsx o xls',
      解析中: 'Analizando',
      '上传尚未完成。要取消正在进行的上传吗？': '',
      取消上传: 'Cancelar Carga',
      继续上传: 'Continuar Carga',
      已取消上传: 'Carga Cancelada',
      导入完成: 'Importación Completa',
      '成功{0}条': 'Entradas exitosas {0}',
      '失败{0}条': 'Entradas fallidas {0}',
      Excel行号: 'Número de Fila de Excel',
      失败内容: 'Detalles del Fallo',
      失败原因: 'Motivo del Fallo',
      重新导入: 'Reimportar',
      父部门不存在: 'Departamento Principal No Existe',
      请选择要修改的成员信息: 'Por Favor Seleccione la Información del Miembro para Modificar',
      文件名称: 'Nombre del Archivo',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': 'El archivo que exporte se almacenará temporalmente en los registros de exportación y se eliminará automáticamente después de 3 días.',
      创建时间: 'Hora de Creación',
      请输入登录账号: 'Ingrese número de cuenta',
      确认: 'Confirmar',
      员工数已达上限: 'Límite de empleados alcanzado',
      申请扩容: 'Solicitar ampliación',
      '上传失败，请重试！': 'Carga fallida, ¡por favor reintente!',
      '上传头像图片大小不能超过200KB!': '¡El tamaño de la foto de perfil no debe exceder los 200KB!',
      '上传头像图片格式暂不支持!': '¡Formato de foto de perfil no soportado!',
      重新发送: 'Reenviar',
      '您的权限已发生变动，是否刷新页面？': 'Tus permisos han cambiado, ¿quieres actualizar la página?'
    }
  },
  pt: {
    management: {
      个人中心: 'Centro Pessoal',
      个人信息: 'Informações Pessoais',
      应用: 'Aplicações',
      导出记录: 'Registros de Exportação',
      姓名: 'Nome',
      头像: 'Avatar',
      上传: 'Carregar',
      '支持jpg、jpeg、png格式小于200KB': 'Suporta formatos jpg, jpeg, png, menos de 200KB',
      工作邮箱: 'Email de Trabalho',
      请输入邮箱: 'Por favor, insira o email',
      '工作邮箱仅为个人信息项，不是登录账号': 'Email de trabalho é apenas para informações pessoais, não para login',
      主部门: 'Departamento Principal',
      保存修改: 'Salvar Alterações',
      修改信息成功: 'Informações atualizadas com sucesso',
      请输入正确的邮箱: 'Por favor, insira um email válido',
      姓名不能为空: 'Nome não pode estar vazio',
      登录账号: 'Conta de Login',
      登录手机号: 'Número de Celular de Login',
      登录邮箱: 'Email de Login',
      登录密码: 'Senha de Login',
      验证当前已登录手机号: 'Verificar número de celular logado atual',
      请输入验证码: 'Por favor, insira o captcha',
      发送验证码: 'Enviar captcha',
      验证码不能为空: 'Captcha não pode estar vazio',
      验证码格式不正确: 'Formato do captcha está incorreto',
      取消: 'Cancelar',
      下一步: 'Próximo Passo',
      拖动下方滑块完成拼图: 'Arraste o controle deslizante abaixo para completar o quebra-cabeça',
      '只用了{0}s，简直比闪电还快': 'Completado em apenas {0} segundos, mais rápido que um relâmpago',
      请控制拼图块对齐缺口: 'Por favor, alinhe a peça do quebra-cabeça com o espaço',
      '这题有点难呢，已为您更换题目': 'Este é um pouco desafiador, mudamos a pergunta para você',
      绑定新手机号: 'Vincular Novo Número de Celular',
      请输入正确的手机号: 'Por favor, insira o número de celular correto',
      '账号已被绑定，请确认账户信息': 'Conta foi vinculada, por favor confirme as informações da conta',
      '验证码已发送，请查收': 'Captcha enviado, por favor verifique',
      绑定新邮箱: 'Vincular Novo Email',
      确定: 'Confirmar',
      '您未设置登录密码，请': 'Você não definiu uma senha de login, por favor',
      设置: 'Definir',
      修改: 'Modificar',
      绑定: 'Vincular',
      设置密码: 'Definir Senha',
      设置新密码: 'Definir Nova Senha',
      请输入新密码: 'Por favor, insira a nova senha',
      '8-20个字符': '8-20 caracteres',
      包含数字: 'Inclui números',
      包含字母: 'Inclui letras',
      包含特殊字符: 'Inclui caracteres especiais',
      确认新密码: 'Confirmar Nova Senha',
      请再次输入新密码: 'Por favor, reinsira a nova senha',
      保存: 'Salvar',
      密码设置成功: 'Senha definida com sucesso',
      修改密码: 'Alterar Senha',
      当前账户密码: 'Senha Atual da Conta',
      切换为验证码验证: 'Mudar para verificação por captcha',
      请输入当前账户密码: 'Por favor, insira a senha atual da conta',
      保存更改: 'Salvar Alterações',
      两次密码输入不一致: 'As duas entradas de senha não coincidem.',
      密码修改成功: 'Senha alterada com sucesso',
      切换为密码验证: 'Mudar para verificação por senha',
      部门和成员: 'Departamentos e Membros',
      部门和成员管理: 'Gestão de Departamentos e Membros',
      组织架构: 'Estrutura Organizacional',
      添加子部门: 'Adicionar Subdepartamento',
      重命名: 'Renomear',
      设置部门负责人: 'Definir Chefe de Departamento',
      部门负责人拥有部门内所有数据权限: 'Chefe de departamento tem todas as permissões de dados dentro do departamento',
      导出部门: 'Exportar Departamento',
      移动部门: 'Mover departamento',
      转移数据: 'Transferir Dados',
      删除部门: 'Excluir Departamento',
      移动成功: 'Movimento Bem-sucedido',
      此部门没有可转移数据: 'Sem dados transferíveis neste departamento',
      转移部门数据: 'Transferir Dados do Departamento',
      请选择要转移的数据: 'Por favor, selecione os dados para transferir',
      会话: 'Conversas',
      '{0}条': '{0} itens',
      转移部门不影响数据归属成员: 'Transferir departamentos não afeta a propriedade dos dados',
      数据: 'Dados',
      接收部门: 'Departamento Receptor',
      转移: 'Transferir',
      '确定要转移数据吗？': 'Tem certeza de que deseja transferir os dados?',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'Transferência iniciada, por favor aguarde pacientemente, você será notificado quando estiver completa',
      会话转移归属部门已完成: 'Transferência de conversas do departamento concluída',
      转移部门: 'Departamento Transferido',
      '会话转移成功：{0}条': 'Transferência de conversas bem-sucedida: {0} itens',
      '确定导出部门信息吗？': 'Tem certeza de que deseja exportar as informações do departamento?',
      导出成功: 'Exportação Bem-sucedida',
      '部门列表_{0}已经准备完成。': 'Lista de Departamentos_{0} está pronta.',
      查看导出记录: 'Ver Registros de Exportação',
      下载: 'Baixar',
      '是否删除该部门？': 'Excluir este departamento?',
      删除: 'Excluir',
      删除成功: 'Excluído com Sucesso',
      提示: 'Aviso',
      '您的权限已发生变动，是否刷新页面': 'Suas permissões mudaram, atualizar a página?',
      刷新: 'Atualizar',
      的成员列表: 'Lista de membros',
      部门负责人: 'Chefe de Departamento',
      未设置: 'Não Definido',
      '公司已使用/人数上限': 'Uso da Empresa/Número Máximo de Usuários',
      已使用和上限说明: '"Uso" é a soma de contas ativas, desativadas e nunca logadas; "Número Máximo de Usuários" refere-se ao limite de contas para cada empresa.',
      导出成员: 'Exportar Membros',
      '确定导出当前{0}个成员信息吗？': 'Tem certeza de que deseja exportar informações para os atuais {0} membros?',
      导出文件: 'Exportar Arquivo',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'O ficheiro está a ser preparado, pode verificar no Centro Pessoal-{0}',
      搜索成员姓名或登录账号: 'Procurar nome',
      请选择空间: 'espaço selecionado',
      是否登录: 'Status de Login',
      已登录: 'Logado',
      未登录: 'Não Logado',
      全部状态: 'Todos os Status',
      启用: 'Habilitar',
      禁用: 'Desabilitar',
      全部类型: 'Todos os Tipos',
      正式版: 'Versão Oficial',
      试用版: 'Versão de Teste',
      登录账户: 'Conta de Login',
      部门: 'Departamento',
      空间: 'Espaço',
      主空间: 'Espaço Principal',
      状态: 'Status',
      已启用: 'Habilitado',
      已禁用: 'Desabilitado',
      账号类型: 'Tipo de Conta',
      到期时间: 'Data de Expiração',
      操作: 'Ação',
      编辑: 'Editar',
      编辑成员信息: 'Editar Informações do Membro',
      '此邮箱仅为个人信息项，不是登录账号': 'Este email é apenas para informações pessoais, não para login',
      添加部门: 'Adicionar Departamento',
      确认删除: 'Confirmar exclusão',
      添加空间: 'Adicionar Espaço',
      设置离职: 'Definir Saída',
      '确定要将{0}设置为离职吗？': 'Tem certeza de que deseja definir {0} como desligado?',
      离职成功: 'Saída Bem-sucedida',
      请选择部门: 'Por favor, selecione um departamento',
      请输入正确的邮箱地址: 'Por favor, insira um endereço de email válido',
      保存成功: 'Salvo com Sucesso',
      此成员没有可转移的数据: 'Este membro não tem dados transferíveis',
      转移成员数据: 'Transferir Dados do Membro',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': 'O departamento e espaço de pertencimento serão atualizados para o departamento e espaço principal do novo proprietário',
      操作离职: 'Operar Saída',
      '是否禁用{0}账号？': 'Desabilitar conta de {0}?',
      该账号将无法登录Megaview访问本企业: 'Esta conta não poderá acessar a empresa atual',
      系统会保留该员工的所有数据: 'O sistema reterá todos os dados do funcionário',
      '禁用期间，组织内员工依然可以查看该员工数据': 'Durante o período de desativação, outros funcionários da organização ainda podem visualizar os dados deste funcionário',
      禁用账号可以启用恢复: 'Contas desativadas podem ser habilitadas e restauradas',
      '确定要启用{0}账号？': 'Tem certeza de que deseja habilitar a conta {0}?',
      邀请新成员: 'Convidar Novos Membros',
      添加新成员: 'Adicionar Novos Membros',
      请输入姓名: 'Por favor, insira o nome',
      '手机号/邮箱': 'Número de Celular/Email',
      手机号: 'Número de Celular',
      请输入手机号: 'Por favor, insira o número de celular',
      邮箱: 'Email',
      请选择主部门: 'Por favor, selecione o departamento principal',
      请选择主空间: 'Por favor, selecione o espaço principal',
      确定并继续添加: 'Confirmar e continuar adicionando',
      邀请已发送: 'Convite Enviado',
      您也可以通过发送链接告知团队成员: 'Você também pode enviar o link para informar os membros da equipe',
      '': 'ID do Departamento',
      复制邀请: 'Copiar Convite',
      复制成功: 'Cópia Bem-sucedida',
      完成: 'Concluir',
      手机号不能为空: 'Número de celular não pode estar vazio',
      邮箱不能为空: 'Email não pode estar vazio',
      导入成员: 'Importar Membros',
      '点击下载按钮，下载需要导入的模板文件': 'Clique no botão de download para baixar o arquivo de modelo necessário para importação',
      '请仔细阅读模板中的说明，': 'Por favor, leia atentamente as instruções no modelo',
      并按要求格式填写导入的数据: 'e preencha os dados a serem importados conforme solicitado.',
      下载模板: 'Baixar Modelo',
      '文件填写完成后，在下方区域上传文件': 'Após preencher o arquivo, faça o upload na área abaixo',
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': 'O sistema verificará a precisão do formato dos dados e, após a verificação, os dados serão importados para o sistema',
      点击或将文件拖拽到这里上传: 'Clique ou arraste arquivos aqui para fazer upload',
      '支持扩展名：': 'Extensões suportadas:',
      导入: 'Importar',
      导入部门: 'Importar Departamento',
      批量修改成员信息: 'Modificar em Lote Informações do Membro',
      成员: 'Membros',
      请选择成员: 'Por favor, selecione os membros',
      请选择要修改的信息: 'Por favor, selecione as informações para modificar',
      '修改成员的信息，会清空之前的信息，替换为新的': 'Modificar informações do membro limpará informações anteriores e substituirá com novas',
      修改成功: 'Modificado com Sucesso',
      请选择要修改的部门: 'Por favor, selecione o departamento para modificar',
      请选择要修改的空间: 'Por favor, selecione o espaço para modificar',
      已离职成员: 'Membros Desligados',
      搜索成员: 'Pesquisar Membros',
      未分配: 'Não Atribuído',
      仅显示当前部门直属人员: 'Mostrar apenas membros diretos do departamento atual',
      '共{0}条': 'Total de {0} itens',
      验证邮箱: 'Verificar Email',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': 'Após modificação, a senha de login será redefinida automaticamente e um link de convite será enviado para o email',
      子部门: 'Subdepartamento',
      暂无数据: 'Sem Dados',
      请输入密码: 'Por favor, insira a senha',
      请再次输入密码: 'Por favor, reinsira a senha',
      '两次输入密码不一致!': 'Senhas não coincidem!',
      验证当前已登录邮箱: 'Verificar email logado atual',
      绑定成功: 'Vinculação Bem-sucedida',
      特殊字符不支持: 'Caracteres especiais não suportados',
      此部门数据已在转移中: 'Os dados deste departamento estão em processo de transferência',
      此部门没有可转移的数据: 'Sem dados transferíveis neste departamento',
      '该部门下已有成员或部门，不能被删除。': 'Este departamento tem membros ou subdepartamentos e não pode ser excluído.',
      '此部门数据正在转移中，无法删除': 'Os dados deste departamento estão sendo transferidos e não podem ser excluídos',
      交易: 'Transação',
      '删除部门前需转移部门数据，你没有此部门的': 'Antes de excluir um departamento, transfira seus dados;',
      '编辑权限，请先前往': 'você não tem permissões de edição para este departamento, por favor vá para',
      数据权限: 'Permissões de Dados',
      页面配置: 'Configuração de Página',
      无编辑权限: 'Sem Permissões de Edição',
      我知道了: 'Entendi',
      删除前转移部门数据: 'Antes da exclusão, transfira os dados do departamento',
      离职: 'Demitido',
      验证码已过期或未发送: 'O captcha expirou ou não foi enviado',
      请先添加验证邮箱: 'Por favor, adicione um e-mail de verificação primeiro',
      是否重置该成员密码: 'A senha do membro deve ser redefinida?',
      重置密码: 'Redefinir Senha',
      请先添加验证邮箱后再重置密码: 'Por favor, adicione um e-mail de verificação antes de redefinir a senha',
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': 'A senha do membro deve ser redefinida? Uma nova senha será enviada para o e-mail de verificação do membro após a redefinição.',
      密码重置成功: 'Redefinição de senha bem-sucedida',
      查看邮箱验证码: 'Visualizar captcha do e-mail',
      '将于{0}过期': 'Expirará às {0}',
      复制: 'Copiar',
      '会话、商机默认导入归属人的主空间': 'Sessão e oportunidade são padrão no espaço principal do proprietário',
      '请输入1-32个字符': 'Por favor, insira de 1 a 32 caracteres',
      离职成员: 'Ex-membros',
      添加: 'Adicionar',
      '你没有此{0}的{1}编辑权限': 'Você não tem permissão de edição para {1} deste {0}',
      '请先前往{0}页面配置': 'Por favor, vá primeiro à configuração da página {0}',
      '还有未转移的数据，确定要将{0}设置为离职吗？': 'Ainda há dados não transferidos, tem certeza de que deseja definir {0} como desligado?',
      '未转移数据将保留，所有者仍展示离职成员': 'Os dados não transferidos serão mantidos, o proprietário ainda será exibido como membro desligado',
      请选择数据接收: 'Por favor, selecione o receptor dos dados',
      人: 'Pessoa',
      接收人: 'Receptor',
      资源: 'Recursos',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': 'A conta e senha serão enviadas automaticamente para o e-mail de verificação após adicionar um e-mail de verificação, se não for preenchido, o membro não poderá fazer login, e o e-mail de verificação poderá ser adicionado posteriormente na edição do membro para convidar para o login.',
      '双因素验证方式-验证邮箱': 'Método de verificação em dois fatores - E-mail de verificação',
      请输入验证邮箱: 'Por favor, insira o e-mail de verificação',
      登录账号不能为空: 'A conta de login não pode estar vazia',
      '仅支持字母、数字、下划线': 'Suporta apenas letras, números e sublinhados',
      '账号长度为4-20字符': 'O comprimento da conta deve ser de 4-20 caracteres',
      通知: 'Notificação',
      复制失败: 'Falha na cópia',
      '添加成功，账号及密码已发送到验证邮箱': 'Adicionado com sucesso, a conta e senha foram enviadas para o e-mail de verificação',
      '添加成功，给该成员添加验证邮箱后可登录': 'Adicionado com sucesso, adicione um e-mail de verificação ao membro para permitir o login',
      添加成功: 'Adicionado com sucesso',
      导入管理员角色: 'Importar papel de administrador',
      导入成员角色: 'Importar papel de membro',
      '导入成功{0}条': 'Importação bem-sucedida de {0} itens',
      请输入工作邮箱: 'Por favor, insira o e-mail de trabalho',
      子部门和成员也将随之移动: 'Subdepartamentos e membros também serão movidos',
      请选择移动到的部门: 'Por favor, selecione o departamento para o qual mover',
      请选择要移动后的父部门: 'Por favor, selecione o departamento pai após a mudança',
      从其他部门移入成员: 'Mover membros de outros departamentos',
      已选成员: 'Membros selecionados',
      '加入成功！': 'Adicionado com sucesso!',
      重新选择: 'Re-selecionar',
      正在导入: 'Importando',
      '请不要刷新或离开，同时保持网络畅通': 'Por favor, não atualize ou deixe a página e garanta uma conexão estável com a internet',
      上传失败: 'Falha no Upload',
      '上传失败，请重新上传': 'Falha no upload, por favor, faça o reenvio',
      重新上传: 'Reenviar',
      请选择xlsx或xls格式的文件: 'Selecione um arquivo no formato xlsx ou xls',
      解析中: 'Analisando',
      '上传尚未完成。要取消正在进行的上传吗？': 'O upload ainda não foi concluído. Deseja cancelar o upload em andamento?',
      取消上传: 'Cancelar Upload',
      继续上传: 'Continuar Upload',
      已取消上传: 'Upload Cancelado',
      导入完成: 'Importação Completa',
      '成功{0}条': '{0} entradas bem-sucedidas',
      '失败{0}条': '{0} entradas falharam',
      Excel行号: 'Número da Linha do Excel',
      失败内容: 'Detalhes da Falha',
      失败原因: 'Motivo da Falha',
      重新导入: 'Reimportar',
      父部门不存在: 'Departamento Principal Inexistente',
      请选择要修改的成员信息: 'Por favor, Selecione as Informações do Membro para Modificar',
      文件名称: 'Nome do Arquivo',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': 'O arquivo que você exportar será temporariamente armazenado nos registros de exportação e será automaticamente excluído após 3 dias.',
      创建时间: 'Tempo de Criação',
      请输入登录账号: 'Digite o número da conta',
      确认: 'Confirmar',
      员工数已达上限: 'Limite de funcionários atingido',
      申请扩容: 'Solicitar expansão',
      '上传失败，请重试！': 'Falha no upload, por favor tente novamente!',
      '上传头像图片大小不能超过200KB!': 'O tamanho da foto de perfil não deve exceder 200KB!',
      '上传头像图片格式暂不支持!': 'Formato da foto de perfil não é suportado!',
      重新发送: 'Reenviar',
      '您的权限已发生变动，是否刷新页面？': 'Suas permissões mudaram, você gostaria de atualizar a página?'
    }
  },
  it: {
    management: {
      个人中心: 'Centro Personale',
      个人信息: 'Informazioni Personali',
      应用: 'Applicazioni',
      导出记录: 'Storico Esportazioni',
      姓名: 'Nome',
      头像: 'Avatar',
      上传: 'Carica',
      '支持jpg、jpeg、png格式小于200KB': 'Supporta formati jpg, jpeg, png, meno di 200KB',
      工作邮箱: 'Email Lavorativa',
      请输入邮箱: 'Inserisci email',
      '工作邮箱仅为个人信息项，不是登录账号': "L'email lavorativa è solo per informazioni personali, non per l'accesso",
      主部门: 'Dipartimento Principale',
      保存修改: 'Salva Modifiche',
      修改信息成功: 'Informazioni aggiornate con successo',
      请输入正确的邮箱: "Inserisci un'email valida",
      姓名不能为空: 'Il nome non può essere vuoto',
      登录账号: 'Account di Accesso',
      登录手机号: "Numero di Cellulare per l'Accesso",
      登录邮箱: 'Email di Accesso',
      登录密码: 'Password di Accesso',
      验证当前已登录手机号: 'Verifica il numero di cellulare attualmente connesso',
      请输入验证码: 'Inserisci captcha',
      发送验证码: 'Invia captcha',
      验证码不能为空: 'Il captcha non può essere vuoto',
      验证码格式不正确: 'Formato captcha errato',
      取消: 'Annulla',
      下一步: 'Prossimo Passo',
      拖动下方滑块完成拼图: 'Trascina il cursore qui sotto per completare il puzzle',
      '只用了{0}s，简直比闪电还快': 'Completato in soli {0} secondi, più veloce del fulmine',
      请控制拼图块对齐缺口: 'Allinea il pezzo del puzzle con lo spazio',
      '这题有点难呢，已为您更换题目': "Questa è un po' difficile, abbiamo cambiato la domanda per te",
      绑定新手机号: 'Associa Nuovo Numero di Cellulare',
      请输入正确的手机号: 'Inserisci il numero di cellulare corretto',
      '账号已被绑定，请确认账户信息': "Account associato, si prega di confermare le informazioni dell'account",
      '验证码已发送，请查收': 'Captcha inviato, si prega di controllare',
      绑定新邮箱: 'Associa Nuova Email',
      确定: 'Conferma',
      '您未设置登录密码，请': 'Non hai impostato una password di accesso, per favore',
      设置: 'Imposta',
      修改: 'Modifica',
      绑定: 'Associa',
      设置密码: 'Imposta Password',
      设置新密码: 'Imposta Nuova Password',
      请输入新密码: 'Inserisci nuova password',
      '8-20个字符': '8-20 caratteri',
      包含数字: 'Include numeri',
      包含字母: 'Include lettere',
      包含特殊字符: 'Include caratteri speciali',
      确认新密码: 'Conferma Nuova Password',
      请再次输入新密码: 'Reinserisci nuova password',
      保存: 'Salva',
      密码设置成功: 'Password impostata con successo',
      修改密码: 'Cambia Password',
      当前账户密码: 'Password Account Corrente',
      切换为验证码验证: 'Passa alla verifica captcha',
      请输入当前账户密码: "Inserisci la password dell'account corrente",
      保存更改: 'Salva Modifiche',
      两次密码输入不一致: 'Le due inserimenti della password non corrispondono.',
      密码修改成功: 'Password cambiata con successo',
      切换为密码验证: 'Passa alla verifica password',
      部门和成员: 'Dipartimenti e Membri',
      部门和成员管理: 'Gestione Dipartimenti e Membri',
      组织架构: 'Struttura Organizzativa',
      添加子部门: 'Aggiungi Sotto-dipartimento',
      重命名: 'Rinomina',
      设置部门负责人: 'Imposta Responsabile Dipartimento',
      部门负责人拥有部门内所有数据权限: "Il responsabile del dipartimento ha tutti i permessi sui dati all'interno del dipartimento",
      导出部门: 'Esporta Dipartimento',
      移动部门: 'Sposta dipartimento',
      转移数据: 'Trasferisci Dati',
      删除部门: 'Elimina Dipartimento',
      移动成功: 'Spostamento Riuscito',
      此部门没有可转移数据: 'Nessun dato trasferibile in questo dipartimento',
      转移部门数据: 'Trasferisci Dati Dipartimento',
      请选择要转移的数据: 'Seleziona i dati da trasferire',
      会话: 'Conversazioni',
      '{0}条': '{0} elementi',
      转移部门不影响数据归属成员: 'Il trasferimento di dipartimenti non influisce sulla proprietà dei dati',
      数据: 'Dati',
      接收部门: 'Dipartimento Ricevente',
      转移: 'Trasferisci',
      '确定要转移数据吗？': 'Sei sicuro di voler trasferire i dati?',
      '转移已开始，请耐心等候，完成时会再次消息通知您': 'Il trasferimento è iniziato, attendere pazientemente, verrai notificato al termine',
      会话转移归属部门已完成: 'Trasferimento conversazioni del dipartimento completato',
      转移部门: 'Dipartimento Trasferito',
      '会话转移成功：{0}条': 'Trasferimento conversazioni riuscito: {0} elementi',
      '确定导出部门信息吗？': 'Sei sicuro di voler esportare le informazioni del dipartimento?',
      导出成功: 'Esportazione Riuscita',
      '部门列表_{0}已经准备完成。': 'Elenco Dipartimenti_{0} pronto.',
      查看导出记录: 'Visualizza Storico Esportazioni',
      下载: 'Scarica',
      '是否删除该部门？': 'Eliminare questo dipartimento?',
      删除: 'Elimina',
      删除成功: 'Eliminazione Riuscita',
      提示: 'Avviso',
      '您的权限已发生变动，是否刷新页面': 'I tuoi permessi sono cambiati, aggiornare la pagina?',
      刷新: 'Aggiorna',
      的成员列表: 'Elenco dei membri',
      部门负责人: 'Responsabile Dipartimento',
      未设置: 'Non Impostato',
      '公司已使用/人数上限': 'Utilizzo Aziendale/Numero Massimo di Utenti',
      已使用和上限说明: '"L\'Utilizzo" è la somma degli account attivi, disabilitati e mai connessi; "Numero Massimo di Utenti" si riferisce al limite di account per ogni azienda.',
      导出成员: 'Esporta Membri',
      '确定导出当前{0}个成员信息吗？': 'Sei sicuro di voler esportare le informazioni degli attuali {0} membri?',
      导出文件: 'Esporta File',
      '文件正在准备中，您可以在个人中心-{0}中查看': 'Il file è in fase di preparazione, è possibile controllare nel Centro personale-{0}',
      搜索成员姓名或登录账号: 'Cerca nome',
      请选择空间: 'spazio selezionato',
      是否登录: 'Stato di Accesso',
      已登录: 'Connesso',
      未登录: 'Non Connesso',
      全部状态: 'Tutti gli Stati',
      启用: 'Abilita',
      禁用: 'Disabilita',
      全部类型: 'Tutti i Tipi',
      正式版: 'Versione Ufficiale',
      试用版: 'Versione di Prova',
      登录账户: 'Account di Accesso',
      部门: 'Dipartimento',
      空间: 'Spazio',
      主空间: 'Spazio Principale',
      状态: 'Stato',
      已启用: 'Abilitato',
      已禁用: 'Disabilitato',
      账号类型: 'Tipo di Account',
      到期时间: 'Data di Scadenza',
      操作: 'Azione',
      编辑: 'Modifica',
      编辑成员信息: 'Modifica Informazioni Membro',
      '此邮箱仅为个人信息项，不是登录账号': "Questa email è solo per informazioni personali, non per l'accesso",
      添加部门: 'Aggiungi Dipartimento',
      确认删除: 'Conferma eliminazione',
      添加空间: 'Aggiungi Spazio',
      设置离职: 'Imposta Partenza',
      '确定要将{0}设置为离职吗？': 'Sei sicuro di voler impostare {0} come partito?',
      离职成功: 'Partenza Riuscita',
      请选择部门: 'Seleziona un dipartimento',
      请输入正确的邮箱地址: 'Inserisci un indirizzo email valido',
      保存成功: 'Salvato con Successo',
      此成员没有可转移的数据: 'Questo membro non ha dati trasferibili',
      转移成员数据: 'Trasferisci Dati Membro',
      '归属部门和空间，会同步更新为新归属人的主部门和主空间': 'Il dipartimento e lo spazio di appartenenza saranno aggiornati al dipartimento e spazio principale del nuovo proprietario',
      操作离职: 'Gestisci Partenza',
      '是否禁用{0}账号？': "Disabilitare l'account di {0}?",
      该账号将无法登录Megaview访问本企业: "Questo account non sarà in grado di accedere all'impresa corrente",
      系统会保留该员工的所有数据: 'Il sistema conserverà tutti i dati del dipendente',
      '禁用期间，组织内员工依然可以查看该员工数据': "Durante il periodo di disabilitazione, altri dipendenti dell'organizzazione potranno ancora visualizzare i dati di questo dipendente",
      禁用账号可以启用恢复: 'Gli account disabilitati possono essere riabilitati e ripristinati',
      '确定要启用{0}账号？': "Sei sicuro di voler riabilitare l'account {0}?",
      邀请新成员: 'Invita Nuovi Membri',
      添加新成员: 'Aggiungi Nuovi Membri',
      请输入姓名: 'Si prega di inserire il nome',
      '手机号/邮箱': 'Numero di Cellulare/Email',
      手机号: 'Numero di Cellulare',
      请输入手机号: 'Inserisci numero di cellulare',
      邮箱: 'Email',
      请选择主部门: 'Seleziona dipartimento principale',
      请选择主空间: 'Seleziona spazio principale',
      确定并继续添加: 'Conferma e continua ad aggiungere',
      邀请已发送: 'Invito Inviato',
      您也可以通过发送链接告知团队成员: 'Puoi anche inviare il link per informare i membri del team',
      '': 'ID Dipartimento',
      复制邀请: 'Copia Invito',
      复制成功: 'Copia Riuscita',
      完成: 'Completo',
      手机号不能为空: 'Il numero di cellulare non può essere vuoto',
      邮箱不能为空: "L'email non può essere vuota",
      导入成员: 'Importa Membri',
      '点击下载按钮，下载需要导入的模板文件': "Clicca il pulsante di download per scaricare il file modello necessario per l'importazione",
      '请仔细阅读模板中的说明，': 'Si prega di leggere attentamente le istruzioni nel modello,',
      并按要求格式填写导入的数据: 'e compilare i dati da importare secondo i requisiti.',
      下载模板: 'Scarica Modello',
      '文件填写完成后，在下方区域上传文件': "Dopo aver compilato il file, caricalo nell'area qui sotto",
      '系统会校验数据格式准确性，校验无误后，会将数据导入系统中': "Il sistema verificherà l'accuratezza del formato dei dati e, dopo la verifica, i dati saranno importati nel sistema",
      点击或将文件拖拽到这里上传: 'Clicca o trascina i file qui per caricarli',
      '支持扩展名：': 'Estensioni supportate:',
      导入: 'Importa',
      导入部门: 'Importa Dipartimento',
      批量修改成员信息: 'Modifica in Batch Informazioni Membro',
      成员: 'Membri',
      请选择成员: 'Seleziona membri',
      请选择要修改的信息: 'Seleziona le informazioni da modificare',
      '修改成员的信息，会清空之前的信息，替换为新的': 'La modifica delle informazioni del membro cancellerà le informazioni precedenti e le sostituirà con le nuove',
      修改成功: 'Modifica Riuscita',
      请选择要修改的部门: 'Seleziona il dipartimento da modificare',
      请选择要修改的空间: 'Seleziona lo spazio da modificare',
      已离职成员: 'Membri Partiti',
      搜索成员: 'Cerca Membri',
      未分配: 'Non Assegnato',
      仅显示当前部门直属人员: 'Mostra solo i membri diretti del dipartimento corrente',
      '共{0}条': 'Totale {0} elementi',
      验证邮箱: 'Verifica Email',
      '修改后会自动重置登录密码，并向邮箱发送邀请链接': "Dopo la modifica, la password di accesso sarà reimpostata automaticamente e un link di invito sarà inviato all'email",
      子部门: 'Sotto-dipartimento',
      暂无数据: 'Nessun Dato',
      请输入密码: 'Inserisci password',
      请再次输入密码: 'Reinserisci password',
      '两次输入密码不一致!': 'Le password non corrispondono!',
      验证当前已登录邮箱: "Verifica l'email attualmente connessa",
      绑定成功: 'Associazione Riuscita',
      特殊字符不支持: 'Caratteri speciali non supportati',
      此部门数据已在转移中: 'I dati di questo dipartimento sono in fase di trasferimento',
      此部门没有可转移的数据: 'Nessun dato trasferibile in questo dipartimento',
      '该部门下已有成员或部门，不能被删除。': 'Questo dipartimento ha membri o sotto-dipartimenti e non può essere eliminato.',
      '此部门数据正在转移中，无法删除': 'I dati di questo dipartimento sono in trasferimento e non possono essere eliminati',
      交易: 'Transazione',
      '删除部门前需转移部门数据，你没有此部门的': 'Prima di eliminare un dipartimento, trasferisci i suoi dati;',
      '编辑权限，请先前往': 'non hai permessi di modifica per questo dipartimento, per favore vai a',
      数据权限: 'Permessi Dati',
      页面配置: 'Configurazione Pagina',
      无编辑权限: 'Nessun Permesso di Modifica',
      我知道了: 'Ho Capito',
      删除前转移部门数据: "Prima dell'eliminazione, trasferisci i dati del dipartimento",
      离职: 'Dimesso',
      验证码已过期或未发送: 'Il captcha è scaduto o non è stato inviato',
      请先添加验证邮箱: "Si prega di aggiungere prima un'email di verifica",
      是否重置该成员密码: 'La password del membro deve essere reimpostata?',
      重置密码: 'Reimposta Password',
      请先添加验证邮箱后再重置密码: "Si prega di aggiungere un'email di verifica prima di reimpostare la password",
      '是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。': "La password del membro deve essere reimpostata? Una nuova password sarà inviata all'email di verifica del membro dopo il reset.",
      密码重置成功: 'Reimpostazione della password riuscita',
      查看邮箱验证码: 'Visualizza captcha email',
      '将于{0}过期': 'Scadrà alle {0}',
      复制: 'Copia',
      '会话、商机默认导入归属人的主空间': "La sessione e l'opportunità si impostano di default nello spazio principale del proprietario",
      '请输入1-32个字符': 'Inserisci da 1 a 32 caratteri',
      离职成员: 'Ex membri',
      添加: 'Aggiungi',
      '你没有此{0}的{1}编辑权限': 'Non hai il permesso di modificare {1} di questo {0}',
      '请先前往{0}页面配置': 'Si prega di andare prima alla configurazione della pagina {0}',
      '还有未转移的数据，确定要将{0}设置为离职吗？': 'Ci sono ancora dati non trasferiti, sei sicuro di voler impostare {0} come dimesso?',
      '未转移数据将保留，所有者仍展示离职成员': 'I dati non trasferiti saranno conservati, il proprietario sarà ancora mostrato come membro dimesso',
      请选择数据接收: 'Si prega di scegliere la ricezione dei dati',
      人: 'Persona',
      接收人: 'Ricevente',
      资源: 'Risorse',
      '账号和密码会在添加验证邮箱后自动发送到验证邮箱中，若未填写验证邮箱，该成员无法登录，后续可在成员编辑中添加验证邮箱邀请登录。': "L'account e la password verranno inviati automaticamente all'email di verifica dopo l'aggiunta, se non viene fornita un'email di verifica, il membro non potrà accedere, successivamente si potrà aggiungere un'email di verifica nell'editor dei membri per invitare all'accesso.",
      '双因素验证方式-验证邮箱': 'Metodo di autenticazione a due fattori - Email di verifica',
      请输入验证邮箱: "Si prega di inserire l'email di verifica",
      登录账号不能为空: "L'account di accesso non può essere vuoto",
      '仅支持字母、数字、下划线': 'Supporta solo lettere, numeri, underscore',
      '账号长度为4-20字符': "La lunghezza dell'account deve essere di 4-20 caratteri",
      通知: 'Notifica',
      复制失败: 'Copia fallita',
      '添加成功，账号及密码已发送到验证邮箱': "Aggiunta riuscita, account e password inviati all'email di verifica",
      '添加成功，给该成员添加验证邮箱后可登录': "Aggiunta riuscita, aggiungi un'email di verifica al membro per consentire l'accesso",
      添加成功: 'Aggiunta riuscita',
      导入管理员角色: 'Importa ruolo di amministratore',
      导入成员角色: 'Importa ruolo di membro',
      '导入成功{0}条': 'Importazione riuscita di {0} voci',
      请输入工作邮箱: "Si prega di inserire l'email di lavoro",
      子部门和成员也将随之移动: 'Anche i sottodipartimenti e i membri saranno spostati',
      请选择移动到的部门: 'Si prega di scegliere il dipartimento di destinazione',
      请选择要移动后的父部门: 'Si prega di selezionare il dipartimento genitore dopo lo spostamento',
      从其他部门移入成员: 'Importa membri da altri dipartimenti',
      已选成员: 'Membri selezionati',
      '加入成功！': 'Aggiunto con successo!',
      重新选择: 'Riseleziona',
      正在导入: 'Importazione',
      '请不要刷新或离开，同时保持网络畅通': 'Si prega di non aggiornare o lasciare la pagina e di assicurare una connessione internet stabile',
      上传失败: 'Caricamento Fallito',
      '上传失败，请重新上传': 'Caricamento fallito, si prega di ricaricare',
      重新上传: 'Ricarica',
      请选择xlsx或xls格式的文件: 'Si prega di selezionare un file in formato xlsx o xls',
      解析中: 'Analisi',
      '上传尚未完成。要取消正在进行的上传吗？': 'Il caricamento non è ancora completato. Vuoi annullare il caricamento in corso?',
      取消上传: 'Annulla Caricamento',
      继续上传: 'Continua Caricamento',
      已取消上传: 'Caricamento Annullato',
      导入完成: 'Importazione Completata',
      '成功{0}条': '{0} voci importate con successo',
      '失败{0}条': '{0} voci fallite',
      Excel行号: 'Numero di Riga Excel',
      失败内容: 'Dettagli del Fallimento',
      失败原因: 'Motivo del Fallimento',
      重新导入: 'Re-importa',
      父部门不存在: 'Dipartimento Principale Non Esiste',
      请选择要修改的成员信息: 'Seleziona le Informazioni del Membro da Modificare',
      文件名称: 'Nome del File',
      '您导出的文件生成后会暂存在导出记录中，3天后会自动删除。': 'Il file esportato sarà temporaneamente conservato nei record di esportazione e verrà automaticamente cancellato dopo 3 giorni.',
      创建时间: 'Tempo di Creazione',
      请输入登录账号: 'Inserire il numero di conto',
      确认: 'Confermare',
      员工数已达上限: 'Raggiunto il limite di dipendenti',
      申请扩容: 'Richiedere un ampliamento',
      '上传失败，请重试！': 'Caricamento fallito, per favore riprova!',
      '上传头像图片大小不能超过200KB!': "La dimensione dell'immagine del profilo non deve superare i 200KB!",
      '上传头像图片格式暂不支持!': "Formato dell'immagine del profilo non supportato!",
      重新发送: 'Reinvia',
      '您的权限已发生变动，是否刷新页面？': 'I tuoi permessi sono cambiati, vuoi aggiornare la pagina?'
    }
  }
};
var _default = management;
exports.default = _default;