"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-stage-line"
  }, [_vm._l(_vm.dealStages, function (item, index) {
    return _c("el-popover", {
      key: item.id,
      attrs: {
        disabled: !_vm.canClick
      },
      on: {
        show: function ($event) {
          return _vm.popoverShow(item.id);
        }
      },
      model: {
        value: _vm.popoverVisible[item.id],
        callback: function ($$v) {
          _vm.$set(_vm.popoverVisible, item.id, $$v);
        },
        expression: "popoverVisible[item.id]"
      }
    }, [_c("update-stage-card", {
      attrs: {
        "pre-stage": _vm.preStage,
        "cur-stage": item.stage_name
      },
      on: {
        cancel: function ($event) {
          _vm.popoverVisible[item.id] = false;
        },
        submit: function ($event) {
          return _vm.changeStage(item.id, item.stage_name);
        }
      }
    }), _c("angle-tag", {
      attrs: {
        slot: "reference",
        "color-obj": _vm.angleColors(index),
        type: index === 0 ? "first" : "center",
        "can-click": _vm.canClick,
        label: item.stage_name
      },
      slot: "reference"
    })], 1);
  }), _c("el-dropdown", {
    attrs: {
      trigger: "click",
      placement: "bottom-end"
    },
    on: {
      command: _vm.selectClose
    }
  }, [_c("angle-tag", {
    attrs: {
      type: "last",
      "color-obj": _vm.closeColors,
      "can-click": true
    }
  }, [_vm._v(_vm._s(_vm.endLabel)), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    staticClass: "progress-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.endedStages, function (item) {
    return _c("el-dropdown-item", {
      key: item.id,
      attrs: {
        command: item.id
      }
    }, [_c("i", {
      class: _vm.stageIcon[item.id]
    }), _vm._v(_vm._s(item.stage_name) + " ")]);
  }), 1)], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;