"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  name: 'AnswerSheet',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    knowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      answerList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    answerItemClassName() {
      return function (item) {
        if (item.plan_knowledge_id === this.knowledge.id) {
          return 'active-item';
        } else if (item.average_score < 0 && !item.isUnlocked) {
          // 上一题都已经答完可解锁
          return 'locked-item';
        }
        return '';
      };
    },
    localAnswerList() {
      this.answerList.forEach((item, index) => {
        if (item.count === item.finished) {
          // 当前题答完可以解锁下一题
          this.answerList[index + 1] && (this.answerList[index + 1].isUnlocked = 1);
        }
      });
      return this.answerList;
    }
  },
  watch: {},
  created() {
    this.getSalesKnowledgeReplyDetail();
  },
  methods: {
    changeKnowledge(item) {
      // 当前题
      if (item.plan_knowledge_id === this.knowledge.id) return;
      // 未解锁的题不能点击跳转
      if (item.average_score < 0 && !item.isUnlocked) {
        this.$message({
          message: '请按顺序完成练习',
          type: 'warning'
        });
        return;
      }
      this.$emit('changeKnowledge', item.plan_knowledge_id);
    },
    closeAnswerSheet() {
      this.$emit('update:visible', false);
    },
    async getSalesKnowledgeReplyDetail() {
      const res = await (0, _coachingCenter.getSalesKnowledgeReplyDetail)({
        page: 1,
        size: 999,
        id: Number(this.$route.query.taskId),
        user_id: Number(this.userId),
        is_complete: -1
      });
      if (res.code === 20000) {
        this.answerList = res.results.data;
      }
    }
  }
};
exports.default = _default;