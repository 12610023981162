"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    SvgIcon: _SvgIcon.default
  },
  props: {
    backButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    backMethod: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      hasHistory: true
    };
  },
  created() {
    if (history.length === 1) {
      this.hasHistory = false;
    }
  },
  methods: {
    goBack() {
      if (this.backMethod === undefined) {
        this.$router.go(-1);
      } else {
        this.backMethod();
      }
    }
  }
};
exports.default = _default;