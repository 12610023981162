"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "knowledge-part"
  }, [_c("header", [_c("i", {
    staticClass: "iconfont icon-folder-fill"
  }), _c("div", {
    staticClass: "detail"
  }, [_c("span", {
    staticClass: "info"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.currentNode.name))]), _c("span", {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.knowledgeList.length) + "个知识点 ")])])]), _c("div", {
    staticClass: "operate-container"
  }, [_vm.knowledgePermission ? _c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "medium"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" 新建 "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    staticClass: "operate-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "createKnowledge",
      icon: "iconfont icon-reading"
    }
  }, [_vm._v("新建知识点")]), _c("el-dropdown-item", {
    attrs: {
      command: "createCatalog",
      icon: "iconfont icon-folder-add"
    }
  }, [_vm._v("新建文件夹")])], 1)], 1) : _vm._e(), _vm.knowledgePermission ? _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      size: "medium",
      icon: "icon-import"
    },
    on: {
      click: function ($event) {
        _vm.importDrawerVisible = true;
      }
    }
  }, [_vm._v("导入数据")]) : _vm._e(), _vm.knowledgePermission ? _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-magic-1",
      size: "medium"
    },
    on: {
      click: function ($event) {
        _vm.IntelligentTractDialogVisible = true;
      }
    }
  }, [_vm._v("智能提取")]) : _vm._e()], 1)]), _c("article", [!_vm.listLoading && _vm.knowledgeList.length ? _vm._l(_vm.knowledgeList, function (item) {
    return _c("knowledge-item", {
      key: item.id,
      ref: `knowledge-item-${item.id}`,
      refInFor: true,
      attrs: {
        knowledge: item,
        disabled: !_vm.knowledgePermission
      },
      on: {
        editKnowledge: _vm.editKnowledge,
        deleteKnowledge: _vm.refreshData,
        moveKnowledge: _vm.handleMoveKnowledge,
        addTricks: function ($event) {
          return _vm.handleAddTricks(item.id);
        },
        recordingAudio: function ($event) {
          return _vm.$emit("recordingAudio", $event);
        }
      }
    });
  }) : _vm._e(), !_vm.listLoading && !_vm.knowledgeList.length ? _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-knowledge",
      tips: "暂无知识点"
    }
  }, [_vm.knowledgePermission ? _c("el-button", {
    staticClass: "flex-center mg-round-button",
    attrs: {
      slot: "button",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.handleCommand("createKnowledge");
      }
    },
    slot: "button"
  }, [_vm._v(" 知识点 ")]) : _vm._e()], 1) : _vm._e()], 2), _c("KnowledgeLibraryImportDrawer", {
    attrs: {
      "catalog-id": _vm.currentNode.id,
      visible: _vm.importDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.importDrawerVisible = $event;
      },
      refresh: _vm.refreshData
    }
  }), _c("IntelligentTractDialog", {
    attrs: {
      "catalog-id": _vm.currentNode.id,
      visible: _vm.IntelligentTractDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.IntelligentTractDialogVisible = $event;
      },
      refreshKnowledgeList: _vm.refreshData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;