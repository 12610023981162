"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("detail-header", {
    staticClass: "detail-header",
    attrs: {
      "back-button": _vm.backButton
    },
    scopedSlots: _vm._u([{
      key: "title_log",
      fn: function () {
        return [_c("i", {
          class: ["medium-icon", "iconfont", _vm.getMediumIcon(_vm.mediaType)]
        })];
      },
      proxy: true
    }, {
      key: "prefix",
      fn: function () {
        return [_vm.originType === "wework_internal_app" ? _c("img", {
          staticClass: "nav-logo",
          attrs: {
            src: require("@/assets/wework.svg"),
            alt: "logo"
          }
        }) : _vm.originType === "tencent_meeting" ? _c("img", {
          staticClass: "nav-logo",
          attrs: {
            src: require("@/assets/tencent_meeting.svg"),
            alt: "logo"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_c("followup-dynamic-selector", {
          attrs: {
            "conversation-title-info": _vm.conversationTitleInfo,
            "conv-id": _vm.convId
          },
          on: {
            changeId: _vm.changeConversation
          }
        })];
      },
      proxy: true
    }, {
      key: "header_left_bottom",
      fn: function () {
        return [_vm.conversationTitleInfo.convTime ? _c("div", {
          staticClass: "header-left-bottom"
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话归属人",
            placement: "top"
          }
        }, [_vm.getSalesman ? _c("div", {
          staticClass: "salesman"
        }, [_c("image-avatar", {
          attrs: {
            avatar: _vm.getSalesman.avatar,
            name: _vm.getSalesman.name
          }
        }), _c("span", [_vm._v(_vm._s(_vm.getSalesman.name))])], 1) : _vm._e()]), _vm.convDuration ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.convDuration ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话时长",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-dur"
        }, [_vm._v(_vm._s(_vm.convDuration))])]) : _vm._e(), _c("b", [_vm._v("·")]), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话时间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-time"
        }, [_c("div", {
          staticClass: "conv-date"
        }, [_vm._v(_vm._s(_vm.convdate))]), _c("div", [_vm._v(_vm._s(_vm.convtime))])])]), _vm.getDealName ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.getDealName ? _c("el-popover", {
          attrs: {
            placement: "top",
            trigger: "hover",
            "popper-class": "conv-detail-copy-name-button",
            "visible-arrow": false
          }
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "复制交易名称",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.getDealName,
            expression: "getDealName",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.onCopy,
            expression: "onCopy",
            arg: "success"
          }],
          staticClass: "iconfont icon-copy-document"
        })]), _c("div", {
          staticClass: "deal-name",
          attrs: {
            slot: "reference"
          },
          on: {
            click: _vm.goTradePage
          },
          slot: "reference"
        }, [_c("i", {
          staticClass: "iconfont icon-deal4"
        }), _vm._v(" " + _vm._s(_vm.getDealName) + " ")])], 1) : _vm._e(), _vm.getDealName ? _c("div", {
          staticClass: "deal-name-content"
        }) : _vm._e(), _vm.getCustomerName ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.getCustomerName ? _c("el-popover", {
          attrs: {
            placement: "top",
            trigger: "hover",
            "popper-class": "conv-detail-copy-name-button",
            "visible-arrow": false
          }
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "复制客户名称",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.getCustomerName,
            expression: "getCustomerName",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.onCopy,
            expression: "onCopy",
            arg: "success"
          }],
          staticClass: "iconfont icon-copy-document"
        })]), _c("div", {
          staticClass: "customer-name",
          attrs: {
            slot: "reference"
          },
          on: {
            click: _vm.goCustomerPage
          },
          slot: "reference"
        }, [_c("i", {
          staticClass: "iconfont icon-office-building"
        }), _vm._v(" " + _vm._s(_vm.getCustomerName) + " ")])], 1) : _vm._e(), _vm.workspace ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.workspace ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "所属空间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-workspace"
        }, [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: _vm.workspace.icon_color,
            "margin-right": "4px"
          }
        }), _c("span", [_vm._v(_vm._s(_vm.workspace.name))])])]) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [!_vm.isSnippetStatus ? _c("div", {
          staticClass: "top-btns"
        }, [_vm.interfacePermission.showChatEntry ? _c("chat-entry", _vm._g({
          attrs: {
            "disabled-key": "convDetailChatTipDisabled",
            "video-src": _vm.chatTipVideoSrc,
            text: "会话AI助手，开启问答模式，轻松整合会话信息，高效工作事半功倍。"
          }
        }, _vm.$listeners)) : _vm._e(), _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "comments"
          },
          attrs: {
            id: "conv-detail-right-comment-button",
            plain: "",
            icon: "iconfont icon-chat-line-square"
          },
          on: {
            click: _vm.openCommentDrawer
          }
        }, [_vm._v("评论 (" + _vm._s(_vm.commentsCount) + ")")]), _vm.audio && _vm.audio.length ? _c("el-button", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/library/snippet/create"],
            expression: "['/api/library/snippet/create']"
          }],
          class: ["hover-show-bg", "snippet-btn", {
            "snippet-btn-disabled": _vm.currentOperateName === "hitKeyEvent"
          }],
          attrs: {
            plain: "",
            icon: "iconfont icon-scissors",
            disabled: _vm.currentOperateName === "hitKeyEvent"
          },
          on: {
            click: _vm.addVideoToSnippet
          }
        }, [_vm._v("剪辑")]) : _vm._e(), _c("el-button", {
          staticClass: "hover-show-bg",
          attrs: {
            plain: "",
            icon: "iconfont icon-share-line"
          },
          on: {
            click: function ($event) {
              _vm.shareFormVisible = true;
            }
          }
        }, [_vm._v("分享")]), _vm.hasCoaches && _vm.isSeeker && !_vm.interfacePermission.AICoaching ? _c("el-button", {
          staticClass: "see-later-button hover-show-bg",
          attrs: {
            plain: "",
            icon: "iconfont icon-hand-2"
          },
          on: {
            click: _vm.askForAdvice
          }
        }, [_vm._v("申请辅导")]) : _vm._e(), _vm.hasCoaches && _vm.isSeeker && _vm.interfacePermission.AICoaching ? _c("el-dropdown", {
          attrs: {
            trigger: "click"
          }
        }, [_c("el-button", {
          staticClass: "coach-button hover-show-bg",
          attrs: {
            plain: ""
          }
        }, [_c("i", {
          staticClass: "iconfont icon-hand-2"
        }), _vm._v(" 申请辅导 "), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          staticClass: "coach-dropdown",
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.askForAdvice.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-hand-2"
        }), _vm._v(" 申请人工辅导 ")]), _c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.viewAICoaching.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-ai-assistant"
        }), _vm._v(_vm._s(_vm.detailResult.ai_evaluate_status ? "查看" : "申请") + "智能辅导 ")])], 1)], 1) : _vm._e(), _vm.hasCoaches && !_vm.isSeeker ? _c("el-dropdown", {
          attrs: {
            trigger: "click"
          }
        }, [_c("el-button", {
          staticClass: "coach-button hover-show-bg",
          attrs: {
            plain: ""
          }
        }, [_vm.unTeachTotal && !_vm.defaultStatus ? _c("i", {
          staticClass: "iconfont icon-info-2"
        }) : _vm._e(), !_vm.unTeachTotal && !_vm.defaultStatus ? _c("i", {
          staticClass: "iconfont icon-success"
        }) : _vm._e(), _vm.defaultStatus ? _c("i", {
          staticClass: "iconfont icon-coaching"
        }) : _vm._e(), _vm._v(" 辅导 "), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          staticClass: "coach-dropdown",
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [!_vm.unTeachTotal ? _c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.changeUnTeach.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        }), _vm._v("标为“待辅导” ")]) : _vm._e(), _vm.unTeachTotal ? _c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.changeFinishTeach.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-circle-check"
        }), _vm._v("标为“已辅导”")]) : _vm._e(), _c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.teachAdvise.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-suggest"
        }), _vm._v("留下辅导建议 ")]), _vm.interfacePermission.AICoaching ? _c("el-dropdown-item", {
          nativeOn: {
            click: function ($event) {
              return _vm.viewAICoaching.apply(null, arguments);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-ai-assistant"
        }), _vm._v("查看智能辅导 ")]) : _vm._e()], 1)], 1) : _vm._e(), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.getExtractInfoDetail().isShow,
            expression: "getExtractInfoDetail().isShow"
          }],
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "extractInfo"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-extract-information"
          },
          on: {
            click: _vm.showExtractInfo
          }
        }, [_vm._v("信息提取")]), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.getAISummaryDetail().isShow,
            expression: "getAISummaryDetail().isShow"
          }],
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "AISummary"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-tickets-ai"
          },
          on: {
            click: _vm.showAISummary
          }
        }, [_vm._v("智能纪要")]), _vm.hasEvaluation ? _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "evaluation"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-compliance-evaluation-form"
          },
          on: {
            click: _vm.openEvaluation
          }
        }, [_vm._v("评价单")]) : _vm._e(), _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "convInfo"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-info-1"
          },
          on: {
            click: _vm.showConvInfo
          }
        }, [_vm._v("会话信息")]), _c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: _vm.handleCommand
          }
        }, [_c("el-button", {
          staticClass: "hover-show-bg"
        }, [_c("i", {
          staticClass: "el-icon-more"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_vm.hasWorkspace && !_vm.workspaceInfo.type ? _c("el-dropdown-item", {
          attrs: {
            command: "transferWorkspace",
            icon: "iconfont icon-workspace-3"
          }
        }, [_vm._v("转移会话到其他空间")]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            command: "seeLater",
            icon: "iconfont icon-time"
          }
        }, [_vm._v("稍后再看")]), _c("el-dropdown-item", {
          attrs: {
            command: "openNotes",
            icon: "iconfont icon-notebook-"
          }
        }, [_vm._v("私人笔记")]), _vm.detailResult.can_delete ? _c("el-dropdown-item", {
          staticClass: "delete-dropdown-item",
          attrs: {
            command: "deleteConv",
            icon: "iconfont icon-delete"
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)], 1), _c("share-conv", {
          attrs: {
            visible: _vm.shareFormVisible,
            "conv-id": _vm.convId,
            location: "detail"
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareFormVisible = $event;
            }
          }
        })], 1) : _vm._e(), _vm.isSnippetStatus ? _c("snippet-video-button", {
          staticClass: "snippet-video-button",
          attrs: {
            type: "primary",
            "media-type": _vm.mediaType,
            "conversation-id": _vm.convId
          },
          on: {
            cancelSnippet: _vm.onCancelSnippet
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _c("TransferConversationDialog", {
    ref: "transferConversationDialog",
    attrs: {
      "dialog-visible": _vm.transferConversationDialogVisible,
      "workspace-item": _vm.workspace,
      "is-single": ""
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      submit: _vm.transferConversation
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;