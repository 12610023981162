"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PublishModelContent = _interopRequireDefault(require("@/views/management/modelManagement/components/PublishModelContent"));
var _TrailResultsDrawer = _interopRequireDefault(require("./components/TrailResultsDrawer.vue"));
var _TrainDetailDrawer = _interopRequireDefault(require("@/views/management/modelAllVersions/components/TrainDetailDrawer.vue"));
var _modelManagement = require("@/api/modelManagement");
var _useAutoRefresh = require("@/views/management/rapidModeling/hooks/use-auto-refresh.js");
var _modelInfo = require("@/views/management/modelAllVersions/hooks/modelInfo.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  emits: ['reCreateTrianTask'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const modelInfo = (0, _vue.inject)('modelInfo');
    const setModelInfo = (0, _vue.inject)('setModelInfo');
    const trainStatus = (0, _vue.ref)(0);
    const statusLabel = (0, _vue.computed)(() => {
      const labels = ['未开始', '训练中', '训练完成', '训练失败'];
      return labels[trainStatus.value];
    });
    const publishModelRef = (0, _vue.ref)(null);
    const refreshBtnLoading = (0, _vue.ref)(false);
    const trainDataPercentage = (0, _vue.ref)(0);
    const formatProgress = progress => Math.round(progress * 100);
    const {
      startAutoRefresh,
      stopAutoRefresh
    } = (0, _useAutoRefresh.useAutoRefresh)(getModelDetail);
    async function getModelDetail() {
      if (!modelInfo.id) {
        stopAutoRefresh();
        return;
      }
      refreshBtnLoading.value = true;
      const res = await (0, _modelManagement.getModelItem)({
        id: modelInfo.id
      }).finally(() => {
        refreshBtnLoading.value = false;
      });
      trainDataPercentage.value = res.results.last_version.train_status.progress;
      trainStatus.value = res.results.last_version.train_status.status;
      if (trainStatus.value === 2) {
        stopAutoRefresh();
      }
      setModelInfo({
        trainStatus: trainStatus.value
      });
    }

    // 重试训练
    const {
      retryTrainAsync
    } = (0, _modelInfo.useRetryTrain)(getModelDetail);
    const retryTrain = () => {
      const {
        id,
        version
      } = modelInfo;
      retryTrainAsync(id, version);
    };
    (0, _vue.onMounted)(() => {
      startAutoRefresh();
    });
    const trainDetailDrawerVisible = (0, _vue.ref)(false);
    const openTrainDetailDrawer = () => {
      trainDetailDrawerVisible.value = true;
    };
    const trailResultDialogVisible = (0, _vue.ref)(false);
    const openTrailResultDialog = () => {
      trailResultDialogVisible.value = true;
    };
    const reCreateTrianTask = () => {
      emit('reCreateTrianTask');
    };
    expose({
      validateForm: () => {
        return publishModelRef.value.validateForm();
      },
      getForm: () => {
        return publishModelRef.value.getForm();
      }
    });
    return {
      __sfc: true,
      modelInfo,
      setModelInfo,
      trainStatus,
      statusLabel,
      publishModelRef,
      refreshBtnLoading,
      trainDataPercentage,
      formatProgress,
      startAutoRefresh,
      stopAutoRefresh,
      getModelDetail,
      retryTrainAsync,
      retryTrain,
      trainDetailDrawerVisible,
      openTrainDetailDrawer,
      trailResultDialogVisible,
      openTrailResultDialog,
      emit,
      reCreateTrianTask,
      PublishModelContent: _PublishModelContent.default,
      TrailResultsDrawer: _TrailResultsDrawer.default,
      TrainDetailDrawer: _TrainDetailDrawer.default
    };
  }
};
exports.default = _default;