"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-header",
    class: {
      "is-title": _vm.isTitle
    }
  }, [_c("div", {
    staticClass: "header-name"
  }, [!_vm.isTitle ? _c("span", {
    staticClass: "form-item-order"
  }, [_vm._v(_vm._s(_setup.props.index < 10 ? "0" + _setup.props.index : _setup.props.index))]) : _vm._e(), _setup.props.required ? _c("span", {
    staticClass: "required-dot"
  }, [_vm._v("*")]) : _vm._e(), _c("el-input", {
    attrs: {
      value: _setup.props.name,
      maxlength: 20,
      placeholder: `请输入${_vm.isTitle ? "标题" : "评价项名称"}`
    },
    on: {
      input: _setup.updateName,
      focus: _setup.handleFocus,
      blur: _setup.handleNameBlur
    }
  })], 1), _c("el-input", {
    staticClass: "header-description",
    attrs: {
      value: _setup.props.description,
      maxlength: 100,
      placeholder: `请输入评价${_vm.isTitle ? "单" : "项"}说明（选填）`
    },
    on: {
      input: _setup.updateDescription,
      focus: _setup.handleFocus,
      blur: _setup.handleDescriptionBlur
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;