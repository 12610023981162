"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mediaUpload = require("@/api/mediaUpload");
var _elementUi = require("element-ui");
var _download = require("@/utils/download");
var _vue = require("vue");
var _default = {
  __name: 'KnowledgeLibraryImportDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    catalogId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'refresh'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const status = (0, _vue.ref)('');
    const currentStatusContent = (0, _vue.computed)(() => {
      const options = {
        importing: {
          icon: 'iconfont icon-refresh',
          text: '正在导入...',
          tip: '请不要刷新或离开，同时保持网络畅通'
        },
        success: {
          icon: 'iconfont icon-success-green',
          text: '导入完成',
          tip: '',
          reload: '继续导入'
        },
        error: {
          icon: 'iconfont icon-warning',
          text: '解析失败',
          tip: '文件内容不匹配，请修改后重新上传',
          reload: '重新上传'
        },
        'analysis-fail': {
          icon: 'iconfont icon-error-red',
          text: '解析失败',
          tip: '解析失败，请重新上传',
          reload: '重新上传'
        },
        'upload-fail': {
          icon: 'iconfont icon-error-red',
          text: '上传失败',
          tip: '上传失败，请重新上传',
          reload: '重新上传'
        }
      };
      return options[status.value];
    });
    const downloadTemplateExcel = () => {
      (0, _download.download)('/batch/knowledge', '知识点导入模板');
    };
    const fileInfo = (0, _vue.ref)({});
    const beforeFileUpload = _ref2 => {
      let {
        name
      } = _ref2;
      if (/\.(?:xlsx|xls)$/.test(name)) {
        fileInfo.value.name = name;
        status.value = 'importing';
      } else {
        _elementUi.Message.warning('请选择xlsx或xls格式的文件');
      }
    };
    let uploadRequestController = null;
    const uploadRateLabel = (0, _vue.ref)('');
    const importResult = (0, _vue.ref)({});
    const isCompleteUpload = (0, _vue.ref)(true);
    const uploadProgress = (0, _vue.ref)(0);
    const uploadFile = async item => {
      if (!item.file) return;
      uploadRequestController = new AbortController();
      const signal = uploadRequestController.signal;
      try {
        isCompleteUpload.value = false;
        const res = await (0, _mediaUpload.batchUpload)({
          catalog_id: props.catalogId,
          file: item.file
        }, 'knowledge', {
          timeout: 0,
          signal,
          onUploadProgress: function (progressEvent) {
            if (progressEvent.lengthComputable) {
              fileInfo.value.size = progressEvent.total;
              fileInfo.value.uploadSize = progressEvent.loaded;
              uploadProgress.value = parseInt(progressEvent.loaded / progressEvent.total * 100);
              formatUploadRateLabel();
            }
          }
        });
        if (res.code === 200) {
          uploadProgress.value = 0;
          uploadRateLabel.value = '解析中...';
          getImportResults(res.results.task_id);
        }
        isCompleteUpload.value = true;
      } catch (error) {
        isCompleteUpload.value = true;
        if (!uploadRequestController.signal.aborted) {
          handleUploadFail(error.message);
        }
      }
    };

    // 轮询导入结果接口
    let getImportResultsTimer = null;
    let errorFileId = 0;
    const getImportResults = task_id => {
      getImportResultsTimer = setInterval(async () => {
        const importRes = await (0, _mediaUpload.getBatchUpload)({
          task_id
        });
        if (importRes.code === 200) {
          const {
            fail,
            fail_count,
            success_count,
            total,
            err_id
          } = importRes.results;
          if (importRes.results) {
            clearInterval(getImportResultsTimer);
            if (fail) {
              handleAnalysisFail();
            } else {
              handleSuccess();
              importResult.value = {
                total,
                success_count,
                fail_count
              };
              emit('refresh');
            }
          }
          errorFileId = err_id;
        }
      }, 2000);
    };
    const downloadErrorFile = () => {
      if (errorFileId) {
        (0, _download.download)(`/batch/error?err_id=${errorFileId}`, '导入知识库错误报告');
      }
    };
    const formatUploadRateLabel = () => {
      const formatNum = function () {
        let bytes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        const unitArr = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let size = bytes;
        let index = 0;
        while (size > 1024) {
          size /= 1024;
          index++;
        }
        return size.toLocaleString(undefined, {
          maximumFractionDigits: 2
        }) + unitArr[index];
      };
      const {
        size,
        uploadSize
      } = fileInfo.value;
      if (size && uploadSize) {
        if (size >= uploadSize) {
          uploadRateLabel.value = `${formatNum(uploadSize)} / ${formatNum(size)}`;
        }
      }
    };
    const handleAnalysisFail = () => {
      status.value = 'analysis-fail';
      uploadRateLabel.value = '解析失败';
    };
    const handleSuccess = () => {
      status.value = 'success';
      uploadRateLabel.value = '导入完成';
      _elementUi.Message.success('导入成功');
    };
    const handleUploadFail = msg => {
      status.value = 'error';
      uploadRateLabel.value = msg || '上传失败';
    };
    const handleClose = async () => {
      if (!isCompleteUpload.value) {
        await abortUpload(() => {
          emit('update:visible', false);
        });
      } else {
        initDrawer();
        emit('update:visible', false);
      }
    };
    const deleteFile = () => {
      clearInterval(getImportResultsTimer);
      if (!isCompleteUpload.value) {
        abortUpload();
      } else {
        initDrawer();
      }
    };
    const abortUpload = callback => {
      _elementUi.MessageBox.confirm('上传尚未完成。要取消正在进行的上传吗？', '取消上传', {
        confirmButtonText: '取消上传',
        cancelButtonText: '继续上传',
        type: 'warning'
      }).then(() => {
        if (!isCompleteUpload.value) {
          uploadRequestController.abort();
          (0, _elementUi.Message)({
            type: 'success',
            message: '已取消上传'
          });
        }
        initDrawer();
        callback && callback();
      });
    };
    const initDrawer = () => {
      status.value = '';
      fileInfo.value = {};
      uploadRateLabel.value = '';
      clearInterval(getImportResultsTimer);
    };
    return {
      __sfc: true,
      props,
      emit,
      status,
      currentStatusContent,
      downloadTemplateExcel,
      fileInfo,
      beforeFileUpload,
      uploadRequestController,
      uploadRateLabel,
      importResult,
      isCompleteUpload,
      uploadProgress,
      uploadFile,
      getImportResultsTimer,
      errorFileId,
      getImportResults,
      downloadErrorFile,
      formatUploadRateLabel,
      handleAnalysisFail,
      handleSuccess,
      handleUploadFail,
      handleClose,
      deleteFile,
      abortUpload,
      initDrawer
    };
  }
};
exports.default = _default;