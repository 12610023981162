"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _modelManagement = require("@/api/modelManagement");
var _elementUi = require("element-ui");
var _default = {
  __name: 'ModelItemHeader',
  props: {
    modelInfo: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['buttonClick'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const isRuleType = (0, _vue.computed)(() => props.modelInfo.model_type === 1);
    const isAIType = (0, _vue.computed)(() => [2, 3].includes(props.modelInfo.model_type));
    const isCombineType = (0, _vue.computed)(() => props.modelInfo.model_type === 4);
    const router = (0, _useRouter.useRouter)();
    const goAllVersions = () => {
      router.push({
        path: _routeMap.ROUTES.ModelAllVersions,
        query: {
          id: props.modelInfo.id,
          modelName: props.modelInfo.name,
          modelType: props.modelInfo.model_type === 1 ? 'rule' : 'ai'
        }
      });
    };
    const BUTTONS = [{
      id: 0,
      icon: 'delete',
      text: '删除',
      onclick: () => {
        emit('buttonClick', 'delete');
      }
    }, {
      id: 1,
      icon: 'model-training',
      text: '训练',
      'data-v-name': 'train-model-btn',
      onclick: () => {
        emit('buttonClick', 'training');
      }
    }, {
      id: 2,
      icon: 'setting-config',
      text: '模型配置',
      onclick: () => {
        emit('buttonClick', 'editModel');
      }
    }, {
      id: 3,
      icon: 'view-list',
      text: '全部版本',
      onclick: () => {
        console.log('全部版本');
        goAllVersions();
      }
    }, {
      id: 4,
      icon: 'team-switch-2',
      text: '切换版本',
      onclick: () => {
        emit('buttonClick', 'version');
      }
    }, {
      id: 5,
      icon: 'launch',
      text: '发布',
      'data-v-name': 'publish-model-btn',
      onclick: () => {
        emit('buttonClick', 'publish');
      }
    }, {
      id: 6,
      icon: 'close',
      text: '取消发布',
      onclick: () => {
        emit('buttonClick', 'cancelPublish');
      }
    }];
    const buttons = (0, _vue.computed)(() => {
      let btns = [];
      // model_type 为 1:规则模型， 2:llm， 3:自有模型, 4:组合模型
      // status: 1:已创建，2:训练中，3:待发布，4:发布中，5:已发布，6:等待训练
      // model_cate 2:快速建模

      const {
        model_type,
        status,
        model_cate,
        last_version
      } = props.modelInfo;
      switch (model_type) {
        case 1:
        case 4:
          if (status === 5) {
            btns = [0, 2, 3, 4, 6];
          } else {
            btns = [0, 2, 3, 5];
          }
          break;
        case 3:
          if (model_cate === 2) {
            if (last_version) {
              if (status === 5) {
                btns = [0, 2, 3, 4, 6];
              } else {
                btns = [0, 2, 3, 5];
              }
            } else {
              btns = [0, 2];
            }
          } else {
            if (status === 5) {
              btns = [0, 1, 3, 4, 6];
            } else {
              btns = [0, 1, 3, 5];
            }
          }
          break;
      }
      return BUTTONS.filter(item => btns.includes(item.id));
    });
    const modelBaseInfo = (0, _vue.reactive)({
      name: '',
      description: ''
    });
    const modelBaseInfoOrigin = (0, _vue.reactive)({
      name: '',
      description: ''
    });
    const nameInputRef = (0, _vue.ref)(null);
    const descriptionInputRef = (0, _vue.ref)(null);
    const isNameEdit = (0, _vue.ref)(false);
    const isDescriptionEdit = (0, _vue.ref)(false);
    (0, _vue.watch)([() => props.modelInfo.name, () => props.modelInfo.description], _ref2 => {
      let [name, description] = _ref2;
      modelBaseInfo.name = name;
      modelBaseInfo.description = description;
    }, {
      immediate: true
    });
    const startEdit = async type => {
      switch (type) {
        case 'name':
          isNameEdit.value = true;
          await (0, _vue.nextTick)();
          nameInputRef.value.focus();
          break;
        case 'description':
          isDescriptionEdit.value = true;
          await (0, _vue.nextTick)();
          descriptionInputRef.value.focus();
          break;
      }
      modelBaseInfoOrigin[type] = modelBaseInfo[type];
    };
    const onInputBlur = type => {
      switch (type) {
        case 'name':
          isNameEdit.value = false;
          break;
        case 'description':
          isDescriptionEdit.value = false;
          break;
      }
      if (!modelBaseInfo[type] || modelBaseInfo[type] === modelBaseInfoOrigin[type]) {
        modelBaseInfo[type] = modelBaseInfoOrigin[type];
        return;
      }
      (0, _modelManagement.editModelBaseInfo)({
        id: props.modelInfo.id,
        name: modelBaseInfo.name,
        description: modelBaseInfo.description
      }).then(res => {
        if (res.code === 20000) {
          _elementUi.Message.success('修改成功');
        }
      });
    };
    return {
      __sfc: true,
      props,
      isRuleType,
      isAIType,
      isCombineType,
      emit,
      router,
      goAllVersions,
      BUTTONS,
      buttons,
      modelBaseInfo,
      modelBaseInfoOrigin,
      nameInputRef,
      descriptionInputRef,
      isNameEdit,
      isDescriptionEdit,
      startEdit,
      onInputBlur
    };
  }
};
exports.default = _default;