"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("article", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.commentLoading,
      expression: "commentLoading"
    }],
    staticClass: "comments-area"
  }, [_vm.commentsList.length ? _c("div", {
    ref: "commentsWrapper",
    staticClass: "comments-content",
    class: {
      "is-unfolded": _vm.isUnfolded
    },
    on: {
      click: _vm.handleContainerClick
    }
  }, _vm._l(_vm.commentsList, function (item, index) {
    return _c("comment-unit", {
      key: index,
      ref: "commentUnit",
      refInFor: true,
      attrs: {
        index: index,
        unit: item,
        "active-id": Number(_vm.activeId),
        "has-timestamp": _vm.commentType !== "business",
        "entity-type": _vm.commentType
      },
      on: {
        delComment: _vm.deleteComment,
        replyComment: _vm.replyComment,
        onCommentEditSuccess: _vm.getComments
      }
    });
  }), 1) : _vm._e(), !_vm.commentsList.length && !_vm.commentLoading ? _c("div", {
    staticClass: "no-comment",
    on: {
      click: function ($event) {
        _vm.isUnfolded = false;
      }
    }
  }, [_c("NoData", {
    attrs: {
      size: "120",
      icon: "icon-a-Property1no-comment",
      tips: "暂无评论"
    }
  })], 1) : _vm._e(), _c("send-box", _vm._b({
    ref: "send-box",
    attrs: {
      "is-reply": _vm.isReply,
      "comment-type": _vm.commentType,
      "department-manager-info": _vm.departmentManagerInfo
    },
    on: {
      "update:isReply": function ($event) {
        _vm.isReply = $event;
      },
      "update:is-reply": function ($event) {
        _vm.isReply = $event;
      },
      success: _vm.sendSuccess,
      changeIsUnfolded: _vm.changeIsUnfolded
    }
  }, "send-box", _vm.sendBoxProps, false)), _c("MgvTour", {
    ref: "commentCanEditGuide",
    attrs: {
      name: "isShowCommentCanEditGuide",
      steps: _vm.commentSingle.step
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div", {
          staticClass: "content-container"
        }, [_c("img", {
          attrs: {
            src: _vm.commentSingle.img
          }
        })])];
      },
      proxy: true
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;