"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'ConfirmDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: 'close',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const closeDialog = () => {
      emit('close');
    };
    const autoComplete = async () => {
      emit('startCreate');
    };
    return {
      __sfc: true,
      props,
      emit,
      closeDialog,
      autoComplete
    };
  }
};
exports.default = _default;