"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.abortLead = abortLead;
exports.activateLead = activateLead;
exports.batchAbortLead = batchAbortLead;
exports.batchActivateLead = batchActivateLead;
exports.batchDelete = batchDelete;
exports.batchInvalidateLead = batchInvalidateLead;
exports.batchModifyFollower = batchModifyFollower;
exports.clueCreateMeeting = clueCreateMeeting;
exports.clueTranslateAccount = clueTranslateAccount;
exports.createTimeSet = createTimeSet;
exports.downloadErrorData = downloadErrorData;
exports.getAllocateList = getAllocateList;
exports.getAvailableAllocatorList = getAvailableAllocatorList;
exports.getClueConversation = getClueConversation;
exports.getClueDetail = getClueDetail;
exports.getClueDynamicForm = getClueDynamicForm;
exports.getClueFormInfo = getClueFormInfo;
exports.getClueList = getClueList;
exports.getClueLogRecords = getClueLogRecords;
exports.getClueMapping = getClueMapping;
exports.getClueSortColumn = getClueSortColumn;
exports.getClueTableHead = getClueTableHead;
exports.getCluesList = getCluesList;
exports.getCluesStatusOptions = getCluesStatusOptions;
exports.getDepartmentList = getDepartmentList;
exports.getDynamicForm = getDynamicForm;
exports.getFiltersStatusList = getFiltersStatusList;
exports.getIsSetExist = getIsSetExist;
exports.getLeadDetail = getLeadDetail;
exports.getLeadFormInfo = getLeadFormInfo;
exports.getLeadsFileInfo = getLeadsFileInfo;
exports.getOneCluesInfo = getOneCluesInfo;
exports.getTimeSetDetail = getTimeSetDetail;
exports.getVisibleFilters = getVisibleFilters;
exports.invalidateLead = invalidateLead;
exports.modifyFollower = modifyFollower;
exports.postClueFollowUpBy = postClueFollowUpBy;
exports.postCreateClue = postCreateClue;
exports.postEditClue = postEditClue;
exports.resetClueHead = resetClueHead;
exports.softDelete = softDelete;
exports.updateTimeSet = updateTimeSet;
exports.uploadBatchFile = uploadBatchFile;
var _request = require("@/utils/request");
// 线索详情
function getClueDetail(params) {
  return (0, _request.request)({
    url: '/lead/lead/detail',
    method: 'get',
    params
  });
}
// 获取线索列表
function getCluesList(params) {
  return (0, _request.request)({
    url: '/deal/lead/list',
    method: 'get',
    params
  });
}

// 获取单个线索信息
function getOneCluesInfo(lead_id) {
  return (0, _request.request)({
    url: '/deal/lead/get_one',
    method: 'get',
    params: {
      lead_id
    }
  });
}

// 获取商机状态列表
function getCluesStatusOptions(params) {
  return (0, _request.request)({
    url: '/deal/lead/get_status_options',
    method: 'get',
    params
  });
}

/**
 *
 * 线索详情页
 */
// 获取线索详情
function getLeadDetail(lead_id) {
  return (0, _request.request)({
    url: '/deal/lead/info',
    method: 'get',
    params: {
      lead_id
    }
  });
}

// 设置可预约时间
// 获取是否安排过预约窗口
function getIsSetExist() {
  return (0, _request.request)({
    url: 'deal/appoint',
    method: 'get'
  });
}
// 获取预约窗口详情
function getTimeSetDetail() {
  return (0, _request.request)({
    url: 'deal/appoint/detail',
    method: 'get'
  });
}
// 创建预约窗口
function createTimeSet(data) {
  return (0, _request.request)({
    url: 'deal/appoint/create',
    method: 'post',
    data
  });
}
// 更新预约窗口
function updateTimeSet(data) {
  return (0, _request.request)({
    url: 'deal/appoint/update',
    method: 'post',
    data
  });
}
// 软删除
function softDelete(data) {
  return (0, _request.request)({
    url: 'deal/lead/soft_delete',
    method: 'post',
    data
  });
}
// 批量删除
function batchDelete(data) {
  return (0, _request.request)({
    url: 'deal/lead/soft_delete/batch',
    method: 'post',
    data
  });
}
// 修改跟进人
function modifyFollower(data) {
  return (0, _request.request)({
    url: 'deal/lead/modify_follower',
    method: 'post',
    data
  });
}
// 批量修改跟进人
function batchModifyFollower(data) {
  return (0, _request.request)({
    url: 'deal/lead/modify_follower/batch',
    method: 'post',
    data
  });
}
// 激活
function activateLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/activate',
    method: 'post',
    data
  });
}
// 批量激活
function batchActivateLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/activate/batch',
    method: 'post',
    data
  });
}
// 放弃
function abortLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/drop',
    method: 'post',
    data
  });
}
// 批量放弃
function batchAbortLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/drop/batch',
    method: 'post',
    data
  });
}
// 标为无效
function invalidateLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/inactivate',
    method: 'post',
    data
  });
}
// 批量标为无效
function batchInvalidateLead(data) {
  return (0, _request.request)({
    url: 'deal/lead/inactivate/batch',
    method: 'post',
    data
  });
}
// 获取可显示的筛选框列表
function getVisibleFilters() {
  return (0, _request.request)({
    url: 'deal/lead/get_visible_filters',
    method: 'get'
  });
}
// 获取可选部门（默认主部门）
function getDepartmentList() {
  return (0, _request.request)({
    url: 'deal/lead/get_department_options',
    method: 'get'
  });
}
// 获取选定部门下的所有同事
function getAllocateList(params) {
  return (0, _request.request)({
    url: 'deal/lead/get_allocate_options',
    method: 'get',
    params
  });
}
// 获取状态（包含数量）
function getFiltersStatusList(params) {
  return (0, _request.request)({
    url: 'deal/lead/get_status_filter_options',
    method: 'get',
    params
  });
}
// 获取可分配人列表
function getAvailableAllocatorList() {
  return (0, _request.request)({
    url: 'deal/lead/get_available_allocator_list',
    method: 'get'
  });
}
/**
  批量导入线索
 */
// 下载模板文件

// 文件上传接口
function uploadBatchFile(data) {
  return (0, _request.request)({
    url: 'deal/lead/upload',
    method: 'post',
    data
  });
}
// 获取文件上传后的数据
function getLeadsFileInfo(params) {
  return (0, _request.request)({
    url: 'deal/lead/upload',
    method: 'get',
    params
  });
}
// 错误数据下载
function downloadErrorData() {
  return (0, _request.request)({
    url: 'deal/lead/download',
    method: 'get'
  });
}

/**
 * 自定义字段
 */

// 获取线索自定义表单
function getClueDynamicForm() {
  return (0, _request.request)({
    url: '/lead/lead/form',
    method: 'get'
  });
}

// 线索自定义字段的值
function getClueFormInfo(params) {
  return (0, _request.request)({
    url: '/lead/lead/field/info',
    method: 'get',
    params
  });
}

// 线索编辑
function postEditClue(data) {
  return (0, _request.request)({
    url: '/lead/lead/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取动态表单配置
function getDynamicForm() {
  return (0, _request.request)({
    url: 'deal/lead/field',
    method: 'get'
  });
}
// 获取线索详情
function getLeadFormInfo(params) {
  return (0, _request.request)({
    url: '/deal/lead/field/info',
    method: 'get',
    params
  });
  // 动态表头
}
// 设置表头
function resetClueHead(data) {
  return (0, _request.request)({
    url: '/lead/lead/head/on',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取线索表头
function getClueTableHead() {
  return (0, _request.request)({
    url: '/lead/lead/head',
    method: 'get'
  });
}
// 新建线索
function postCreateClue(data) {
  return (0, _request.request)({
    url: 'lead/lead/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取线索列表
function getClueList(params) {
  return (0, _request.request)({
    url: '/lead/lead/list',
    method: 'get',
    params
  });
}
// 编辑跟进人
function postClueFollowUpBy(data) {
  return (0, _request.request)({
    url: '/lead/lead/edit_follow_up_by',
    method: 'post',
    data
  });
}

// 线索创建会话
function clueCreateMeeting(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_in_lead',
    method: 'post',
    data
  });
}

// 获取线索转客户值回填的接口
function getClueMapping(params) {
  return (0, _request.request)({
    url: '/lead/lead/field_mapping',
    method: 'get',
    params
  });
}

// 线索转客户
function clueTranslateAccount(data) {
  return (0, _request.request)({
    url: '/lead/lead/lead_to_account',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取线索会话详情
function getClueConversation(params) {
  return (0, _request.request)({
    url: '/lead/lead/detail_conversation',
    method: 'get',
    params
  });
}

// 获取线索操作日志
function getClueLogRecords(id, page, size) {
  return (0, _request.request)({
    url: '/account/account_pool/lead_handle_log',
    method: 'get',
    params: {
      id,
      page,
      size
    }
  });
}

//  获取线索可排序的列
function getClueSortColumn() {
  return (0, _request.webService)({
    url: '/lead/view/sort_conf',
    method: 'get'
  });
}