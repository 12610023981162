"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROUTES = exports.PAGES = exports.INTERFACE = void 0;
var _management = require("./management");
var _coaching = require("./coaching");
var _embedPage = require("./embedPage");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
const ROUTES = {
  login: '/login',
  // 登录
  iframeConversationList: '/conversationList',
  // 嵌入iframe会话列表
  chooseTeam: '/chooseTeam',
  // 选择团队
  resetPassword: '/resetPassword',
  dashboard: '/',
  // 首页
  personalCenter: '/user-info',
  // 个人中心页面
  application: '/user-info/auth-app',
  // 个人中心-应用
  exportRecords: '/user-info/export-records',
  // 导出记录
  // 会话
  conversation: '/conversation',
  // 会话列表
  conversationDetail: '/conversation/detail',
  // 会话详情
  conversationScoreSetting: '/conversation/score-setting',
  // 会话评分规则设置
  conversationCreateScoreRule: '/conversation/score-rule-edit',
  // 会话新建/编辑评分规则
  convRecycleBin: '/conversation/recycle-bin',
  // 会话回收站
  lead: '/lead',
  // 线索
  leadDetail: '/lead/detail',
  // 线索详情
  account: '/client',
  // 客户
  deal: '/deal',
  // 商机列表
  dealDetail: '/deal/detail',
  // 商机详情
  dealSet: '/deal/set',
  // 商机设置页
  calendar: '/calendar',
  // 日程
  calendarTeam: '/calendar/team',
  // 团队日程
  calendarMy: '/calendar/my',
  // 我的日程
  customer: '/customer',
  // 客户页面（非CRM）

  trade: '/trade',
  // 交易列表
  tradeDetail: '/trade/detail',
  // 交易详情
  tradeSet: '/trade/set',
  // 交易设置页
  tradeScoreSetting: '/trade/set/score',
  // 交易评分规则设置
  tradeCreateScoreRule: '/trade/score-rule-edit',
  // 交易新建/编辑评分规则

  // BI
  // 总览
  biOverview: '/bi/overview/dashboard',
  // 总览
  // 客户洞察
  biPersona: '/bi/customer/persona',
  // 客户群画像
  biIntentAnalysis: '/bi/customer/intent-analysis',
  // 客户评级(客户画像)
  biHighFrequencyProblems: '/bi/customer/FAQ',
  // 高频问题
  // 执行力分析
  // 执行力分析 - 会话指标
  biActivity: '/bi/execution/conversation/activity',
  // 会话活动
  biDimension: '/bi/execution/conversation/metrics',
  // 会话维度
  // 执行力分析 - 关键事件
  biKeyEvent: '/bi/execution/tracker/bar-chart',
  // 关键事件排行(关键事件)
  biStatement: '/bi/execution/tracker/pie-chart',
  // 关键事件分布（分析报表）
  // 执行力分析 - 销售SOP
  biSOPAnalysis: '/bi/execution/SOP/analysis',
  // 客户触达情况
  biSOPComparison: '/bi/execution/SOP/comparison',
  // 客户触达对比
  biSOPSalesPath: '/bi/execution/SOP/sales-path',
  // 业务路径
  biSOPFunnel: '/bi/execution/SOP/funnel',
  // 业务转换卡点分析
  // 商机分析
  // 商机分析 - 商机阶段分析
  biDealState: '/bi/deal-analysis/deal-stage',
  // 商机阶段分析
  // 商机分析 - 商机漏斗分析
  biDealFunnel: '/bi/deal-analysis/deal-funnel',
  // 商机漏斗预测
  // 团队全景
  // 团队全景 - 团队概览
  biTeamAnalysis: '/bi/team/team-analysis',
  // 团队概览
  // 团队全景 - A/B测试
  biExperiment: '/bi/team/ab-testing',
  // A/B测试
  biExperimentDetail: '/bi/ab-testing-detail',
  // A/B测试详情
  // 团队全景 - 执行力对比
  biBusinessAnalysis: '/bi/team/execution-comparison',
  // 原业务分析
  // 团队全景 - 销售人才盘点
  biTalentReview: '/bi/team/talent-review',
  // 原销售画像
  // 优秀话术挖掘
  // 优秀话术挖掘 - 知识点
  biKnowledge: '/bi/conv-analysis/knowledge',
  // 知识点
  // 个人BI
  biKnowledgeTeamData: '/bi/knowledge-team-data',
  // 知识点团队数据
  biKnowledgeScoreCorrection: '/bi/knowledge-score-correction',
  // 知识点矫正分数页面
  biPersonal: '/bi/personal',
  // 个人bi
  biPersonalOverview: '/bi/personal/overview',
  // 个人bi个人分析
  biPersonalActivity: '/bi/personal/activity',
  // 个人bi会话活动
  biPersonalDimension: '/bi/personal/metrics',
  // 个人bi会话维度
  biPersonalKeyEvent: '/bi/personal/bar-chart',
  // 个人bi关键事件
  biPersonalKnowledge: '/bi/personal/knowledge',
  // 个人bi知识点
  manageDeveloper: '/set/app-management/manage-developer',
  // 应用管理=>开发者管理,
  // 质检
  complianceTeamTaskDetail: '/compliance/task-detail',
  // 团队质检任务详情
  compliancePersonalConversation: '/compliance/personal-conversation',
  // 成员的质检
  complianceTeamTask: '/compliance/task/team-task',
  // 团队质检任务
  complianceMyTaskDetail: '/compliance/my-task-detail',
  // 我的质检任务详情
  complianceDistribution: '/compliance/distribution',
  // 质检分配
  complianceWorkspace: '/compliance/operation',
  // 质检工作台
  complianceMyTask: '/compliance/task/my-task',
  // 我的质检任务
  complianceScoreSetting: '/compliance/rule/rules',
  // 质检评分规则设置
  complianceCreateScoreRule: '/compliance/score-rule-edit',
  // 质检新建/编辑评分规则
  complianceAssign: '/compliance/member/assign',
  // 质检成员分配列表
  complianceCreateMemberAssign: '/compliance/member-assign',
  // 质检新建成员分配

  // SOP评分
  SopAnalysisDashboard: '/sop/score-analysis/dashboard',
  // sop/评分情况概览
  SopAnalysisConversation: '/sop/score-analysis/conversation',
  // sop/会话评分分析
  SopAnalysisTrade: '/sop/score-analysis/trade',
  // sop/交易评分分析
  complianceEvaluation: '/compliance/rule/evalute',
  // 质检评价单
  complianceEditEvaluation: '/compliance/evaluation-edit',
  // 新建/编辑评价单
  ..._coaching.COACHING_ROUTES,
  ..._management.MANAGEMENT_ROUTES
};
exports.ROUTES = ROUTES;
const INTERFACE = {
  // 外呼
  telephoneOutbound: '/api/micro_record/outbound/config/get',
  // 内置视频
  builtInVideo: '/api/record/video_record/info',
  // 上传视频
  uploadConversation: '/api/conversation/conversation/upload',
  // base-上传视频（可不关联商机/客户/线索）
  uploadConversationBase: '/api/conversation/conversation/upload_conversation',
  // base-预约会议
  bookMeetingBase: '/api/conversation/conversation/create_conversation',
  // base-编辑会话
  editConversationInfo: '/api/conversation/conversation/edit_conversation',
  /**
   * 会话页面
   */
  // 会话详情页文字修正
  correctText: '/api/conversation/conversation/upload',
  /**
   * 剪辑库
   */
  // 指派学习
  assignSnippetStudyTask: '/webapi/training/library/study/assign',
  // 移动剪辑到文件夹
  moveSnippetToFolder: '/webapi/training/library/snippet/move',
  // 编辑剪辑
  editSnippet: '/webapi/training/library/snippet/edit',
  // 删除剪辑
  deleteSnippet: '/webapi/training/library/snippet/delete',
  // 移动所有剪辑到文件夹
  moveAllSnippetToFolder: '/webapi/training/library/folder/move_all',
  // 移动文件夹到文件夹
  moveFolderToFolder: '/webapi/training/library/folder/move',
  // 编辑公开范围
  editPublicScope: '/webapi/training/library/folder/edit_access_user',
  // 文件夹重命名
  renameFolder: '/webapi/training/library/folder/update/name',
  // 删除文件夹
  deleteFolder: '/webapi/training/library/folder/standard/delete',
  // 配置部门与空间关系 [管理后台——>空间]
  setDepartmentAndSpaceMapping: '/webapi/organization/workspace/tree_list',
  // 访问管理者权限下的成员
  managerViewMember: '/api/user/below_auth',
  // 知识点挖掘引擎
  knowledgeDiscovery: '/webapi/training/freq_question/list',
  // 创建培训任务
  createCoachingTask: '/webapi/training/plan/my_distribute',
  // 创建文件夹
  createStandardFolder: '/webapi/training/library/folder/standard/create',
  // 创建智能会话流
  createConvFlow: '/webapi/training/library/folder/smart/create',
  // 上传音视频
  uploadMedia: '/webapi/training/library/snippet/create/upload',
  // 设置Ai对练会话同步功能
  setAiConversationSync: '/webapi/organization/tree/practice_sync/update',
  saveTaskClassify: '/webapi/training/plan/category/save',
  /**
   * 知识库
   */
  // 编辑知识库目录
  editKnowledgeCatalog: '/webapi/training/catalog/update',
  // 操作知识点（创建/编辑/移动/删除/更改场景/话术顺序）
  operateKnowledge: '/webapi/training/knowledge/create',
  // 话术挖掘引擎
  discoveryTricks: '/webapi/training/dig_reply_engine',
  /**
   * 会话详情页
   */
  // 添加剪辑
  createSnippet: '/webapi/training/library/snippet/create',
  // 分享会话
  shareConv: '/webapi/conversation/share',
  // 会话文字修正
  correctConvText: '/api/conversation/detail/correction/edit',
  // chat-gpt入口
  showChatEntry: '/webapi/ai/qa/list',
  // 质检评分
  complianceScore: '/webapi/compliance/conv/score_result',
  /**
   * 剪辑详情页
   */
  // 剪辑文字修正
  correctSnippetText: '/webapi/training/library/snippet/sentence/edit',
  // 下载剪辑
  downloadSnippet: '/webapi/training/library/snippet/download',
  // 智能辅导
  AICoaching: '/webapi/conversation/detail/evaluate/detail',
  /**
   * 质检
   */
  // 会话详情页获取评价单
  getConvEvaluationRecord: '/webapi/evaluation/record/conversation/get',
  // 新建任务
  newTask: '/webapi/compliance/conv/assign',
  // 取消任务
  cancelTask: '/webapi/compliance_task/cancel',
  // 删除任务
  deleteTask: '/webapi/compliance_task/delete',
  // 完成质检
  completeCompliance: '/webapi/compliance/conv/result_update',
  // 会话详情页完成质检
  completeConvDetailCompliance: '/webapi/compliance/conv/conversation/result_update',
  // 质检驳回
  rejectCompliance: '/webapi/compliance/conv/result_overrule',
  // 会话详情页驳回质检
  rejectConvDetailCompliance: '/webapi/compliance/conv/conversation/result_overrule',
  // 质检-移除会话
  removeComplianceConv: '/webapi/compliance_task/conv_list/remove/update',
  // 质检-获取移除会话记录
  getRemovedRecord: '/webapi/compliance_task/conv_list/remove/list',
  // 质检-质检成员分配
  createComplianceMemberAssign: '/webapi/compliance_member/create',
  // 知识库列表
  knowledgeBaseList: '/webapi/training/knowledge_base/list',
  // 创建AI模型
  createAiModel: '/webapi/model/create_ai_model',
  // 创建规则模型
  createRuleModel: '/webapi/model/create',
  // 创建组合模型
  createCombineModel: '/webapi/model/create_combine_model',
  // 支持多语种
  getLanguageList: '/webapi/profile/language/list',
  // 指令集
  getIntructionSet: '/webapi/schedule/instruction_set/list',
  // 信息提取指令管理
  getExtractInfoInstructionList: '/webapi/conversation/instruction_set/list',
  // 自动化流程管理
  getAutoProcessSetting: '/webapi/automation/rule/list'
};
exports.INTERFACE = INTERFACE;
const PAGES = {
  DashBoard: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DashBoard" */'@/layout'))),
  UserInfo: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "UserInfo" */'@/layout'))),
  Conversation: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Conversation" */'@/layout'))),
  Lead: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Lead" */'@/layout'))),
  Client: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Client" */'@/layout'))),
  Deal: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Deal" */'@/layout'))),
  Calendar: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Calendar" */'@/layout'))),
  BI: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BI" */'@/layout'))),
  ConvAfter: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConvAfter" */'@/views/afterCloseConv'))),
  DashboardIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DashboardIndex" */'@/views/dashBoard'))),
  UserInfoIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "UserInfoIndex" */'@/views/userInfo'))),
  ConversationIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationIndex" */
  '@/views/conversationPage'))),
  ConversationDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationDetail" */'@/views/convDetail'))),
  ConvRecycleBin: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationRecycleBin" */'@/views/convRecycleBin'))),
  LeadIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "LeadIndex" */'@/views/clues'))),
  LeadDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "LeadDetail" */'@/views/clueDetail'))),
  ClientIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ClientIndex" */'@/views/clients'))),
  DealIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealIndex" */'@/views/business'))),
  DealDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealDetail" */'@/views/businessDetail'))),
  DealSet: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealSet" */'@/views/businessSet'))),
  // CalendarIndex: () =>
  //   import(/* webpackChunkName: "CalendarIndex" */ '@/views/calendarNew'),
  CalendarMy: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CalendarIndex" */'@/views/calendarNew'))),
  CalendarTeam: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CalendarIndex" */'@/views/calendarNew'))),
  ConvAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConvAnalysis" */
  '@/views/businessIntelligence'))),
  StatementAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "StatementAnalysis" */
  '@/views/businessIntelligence'))),
  BusinessAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BusinessAnalysis" */
  '@/views/businessIntelligence'))),
  PicAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "PicAnalysis" */
  '@/views/businessIntelligence'))),
  CustomerAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CustomerAnalysis" */
  '@/views/businessIntelligence'))),
  BIPersonal: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonal" */
  '@/views/businessIntelligencePersonal'))),
  AccountInfo: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AccountInfo" */
  '@/views/userInfo/accountInfo'))),
  AuthApp: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AuthApp" */'@/views/userInfo/authApp'))),
  exportRecords: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "exportRecords" */
  '@/views/userInfo/exportRecords'))),
  AccountSea: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AccountSea" */'@/views/clients'))),
  BiOverview: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BiOverview" */'@/views/businessIntelligence'))),
  BiOverviewDashboard: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BiOverviewDashboard" */
  '@/views/businessIntelligence/Overview/index'))),
  CustomerInsight: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CustomerInsight" */
  '@/views/businessIntelligence'))),
  IntentAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "IntentAnalysis" */
  '@/views/businessIntelligence/CustomerProfile'))),
  Persona: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Persona" */
  '@/views/businessIntelligence/CustomerBaseProfile/index'))),
  DealAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealAnalysis" */
  '@/views/businessIntelligence'))),
  DealStage: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealStage" */
  '@/views/businessIntelligence/dealStage'))),
  DealFunnel: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "DealFunnel" */
  '@/views/businessIntelligence/dealFunnel'))),
  Execution: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Execution" */'@/views/businessIntelligence'))),
  ConversationIndicators: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationIndicators" */
  '@/views/businessIntelligence/ConversationIndicators'))),
  Activity: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Activity" */
  '@/views/businessIntelligence/ConversationIndicators/components/activity'))),
  Metrics: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Metrics" */
  '@/views/businessIntelligence/ConversationIndicators/components/dimension'))),
  Tracker: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Tracker" */
  '@/views/businessIntelligence/Tracker'))),
  BarChart: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BarChart" */
  '@/views/businessIntelligence/Tracker/components/keyevent'))),
  PieChart: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "PieChart" */
  '@/views/businessIntelligence/Tracker/components/analyticalStatement'))),
  Sop: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Sop" */'@/views/businessIntelligence/SOP'))),
  Analysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Analysis" */
  '@/views/businessIntelligence/SOP/components/CustomerReach'))),
  Comparison: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Comparison" */
  '@/views/businessIntelligence/SOP/components/BusinessReach/index'))),
  SalesPath: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "SalesPath" */
  '@/views/businessIntelligence/SOP/components/BusinessPath/index'))),
  Funnel: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Funnel" */
  '@/views/businessIntelligence/SOP/components/TransAndLoss/index'))),
  Team: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Team" */'@/views/businessIntelligence'))),
  ExecutionComparison: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ExecutionComparison" */
  '@/views/businessIntelligence/businessAnalysis'))),
  TalentReview: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TalentReview" */
  '@/views/businessIntelligence/SalesmanProfile'))),
  AbTesting: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AbTesting" */
  '@/views/businessIntelligence/ControlExperiment/index.vue'))),
  ConversationInsights: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationInsights" */
  '@/views/businessIntelligence'))),
  Knowledge: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Knowledge" */
  '@/views/businessIntelligence/knowledge/knowledgeOverview'))),
  FrequentlyAskedQuestion: () => /* webpackChunkName: "FrequentlyAskedQuestion" */Promise.resolve().then(() => _interopRequireWildcard(require('@/views/businessIntelligence/FAQ'))),
  BIPersonalDimension: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonalDimension" */
  '@/views/businessIntelligencePersonal/ConversationDimension'))),
  BIPersonalKeyEvent: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonalKeyEvent" */
  '@/views/businessIntelligencePersonal/KeyEvent'))),
  BIPersonalKnowledge: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonalKnowledge" */
  '@/views/businessIntelligencePersonal/knowledge'))),
  BIPersonalActivity: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonalActivity" */
  '@/views/businessIntelligencePersonal/ConversationActivity'))),
  KnowledgeTeamData: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeTeamData" */
  '@/views/businessIntelligence/knowledge/teamDataAnalysis'))),
  KnowledgeScoreCorrection: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeScoreCorrection" */
  '@/views/businessIntelligence/knowledge/knowledgeScoreCorrection'))),
  LibraryDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "LibraryDetail" */'@/views/snippetsDetail'))),
  MergeAppData: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "MergeAppData" */
  '@/views/userInfo/components/mergeAppDataPage'))),
  TeamAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TeamAnalysis" */
  '@/views/businessIntelligence/TeamAnalysis'))),
  BIPersonalOverview: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "BIPersonalOverview" */
  '@/views/businessIntelligencePersonal/PersonalOverview'))),
  PersonalInfo: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "PersonalInfo" */
  '@/views/userInfo/personalInfo'))),
  Customer: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */'@/layout'))),
  CustomerIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CustomerIndex" */'@/views/customer'))),
  ABTestDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ABTestDetail" */
  '@/views/businessIntelligence/ControlExperiment/components/ExperimentDetail.vue'))),
  ConversationScoreSetting: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationScoreSetting" */
  '@/views/conversationScoreRule'))),
  Trade: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Trade" */'@/layout'))),
  TradeIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeIndex" */'@/views/trade'))),
  TradeDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeDetail" */'@/views/tradeDetail'))),
  TradeSet: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeSet" */'@/views/tradeSet'))),
  TradeSetScore: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeSetScore" */
  '@/views/tradeSet/tradeSetScore'))),
  TradeSetRiskRule: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeSetRiskRule" */
  '@/views/tradeSet/tradeSetRiskRule'))),
  // 质检模块
  Compliance: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Compliance" */'@/layout'))),
  ComplianceIndex: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceIndex" */'@/views/compliance'))),
  // 任务模式
  ComplianceTaskMode: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceTeamTask" */
  '@/views/compliance/TaskMode'))),
  // 团队质检任务
  ComplianceTeamTask: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceTeamTask" */
  '@/views/compliance/teamTask'))),
  // 团队质检会话
  ComplianceTeamConversation: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceTeamConversation" */
  '@/views/compliance/teamConversation'))),
  // 我的质检任务
  ComplianceMyTask: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceMyTask" */
  '@/views/compliance/myTask'))),
  // 质检规则
  ComplianceRuleMode: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceRuleMode" */
  '@/views/compliance/ruleMode'))),
  // 质检评分规则
  ComplianceRules: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceRules" */
  '@/views/compliance/rules'))),
  // 质检评价单
  ComplianceEvalute: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceEvalute" */
  '@/views/compliance/evalute'))),
  ComplianceWorkSpace: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceWorkSpace" */
  '@/views/compliance/workspace'))),
  ComplianceSampleDistribution: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceSampleDistribution" */
  '@/views/compliance/distribution'))),
  ComplianceTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceTaskDetail" */
  '@/views/compliance/taskDetail'))),
  ComplianceMyTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceMyTaskDetail" */
  '@/views/compliance/myTaskDetail'))),
  ConversationCreateScoreRule: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ConversationCreateScoreRule" */
  '@/views/conversationScoreRule/createScoreRule'))),
  TradeCreateScoreRule: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TradeCreateScoreRule" */
  '@/views/tradeSet/tradeSetScore/createScoreRule'))),
  ComplianceCreateScoreRule: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceCreateScoreRule" */
  '@/views/compliance/rules/createScoreRule'))),
  // 成员模式
  ComplianceMemberMode: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceMemberMode" */
  '@/views/compliance/MemberMode'))),
  // 成员分配列表
  ComplianceAssign: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceAssign" */
  '@/views/compliance/memberAssign'))),
  // 新建成员分配
  ComplianceCreateMemberAssign: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceCreateMemberAssign" */
  '@/views/compliance/createMemberAssign'))),
  // 我的质检成员
  ComplianceMyMember: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceMyMember" */
  '@/views/compliance/myMember'))),
  // SOP
  SopAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */'@/layout'))),
  ScoreAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */'@/views/sop'))),
  SopAnalysisDashboard: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */
  '@/views/sop/ScoreAnalysis/SopAnalysisDashboard'))),
  SopAnalysisConversation: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */
  '@/views/sop/ScoreAnalysis/SopAnalysisConversation'))),
  SopAnalysisTrade: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Customer" */
  '@/views/sop/ScoreAnalysis/SopAnalysisTrade'))),
  ComplianceEditEvalute: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceEditEvalute" */
  '@/views/compliance/evalute/editEvalute'))),
  CompliancePersonalConversation: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CompliancePersonalConversation" */
  '@/views/compliance/personalConversation'))),
  ComplianceGuide: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ComplianceGuide" */'@/layout'))),
  CoachingGuide: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachingGuide" */'@/layout'))),
  AIComplianceGuidePage: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"AIComplianceGuidePage" */'@/views/compliance/guidePage'))),
  AICoachingGuidePage: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"AICoachingGuidePage" */'@/views/coaching/guidePage'))),
  MobileGuide: () => Promise.resolve().then(() => _interopRequireWildcard(require( /** webpackChunkName:"MobileGuide" */'@/views/mobileGuide'))),
  ..._coaching.COACHING_PAGES,
  ..._management.MANAGEMENT_PAGES,
  ..._embedPage.EMBED_PAGES
};
exports.PAGES = PAGES;