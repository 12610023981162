"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-setting-form"
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "form-container",
    class: {
      "no-click": !_vm.isEdit
    },
    attrs: {
      model: _vm.localFormData,
      "hide-required-asterisk": ""
    }
  }, [!_vm.noBaseInformation ? _c("FormItemCard", {
    attrs: {
      title: "基础信息"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "规则名称",
      "label-width": "70px",
      rules: [{
        required: true,
        message: "请输入规则名称",
        trigger: "blur"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入规则名称",
      maxlength: 20,
      "show-word-limit": ""
    },
    model: {
      value: _vm.localFormData.name,
      callback: function ($$v) {
        _vm.$set(_vm.localFormData, "name", $$v);
      },
      expression: "localFormData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "description",
      label: "描述",
      "label-width": "70px"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      resize: "none",
      autosize: {
        minRows: 4,
        maxRows: 4
      },
      placeholder: "请输入规则描述",
      maxlength: 100,
      "show-word-limit": ""
    },
    model: {
      value: _vm.localFormData.description,
      callback: function ($$v) {
        _vm.$set(_vm.localFormData, "description", $$v);
      },
      expression: "localFormData.description"
    }
  })], 1)], 1) : _vm._e(), _c("FormItemCard", {
    attrs: {
      title: "评分范围",
      tooltip: "哪些会话被此规则评分"
    }
  }, [_c("el-form-item", {
    staticClass: "data-scope",
    attrs: {
      prop: "data_scope"
    }
  }, [_c("div", {
    staticClass: "filter-label"
  }, [_vm._v("会话时间")]), _c("ScoreRuleDateFilter", {
    attrs: {
      "date-filter": _vm.localFormData.data_scope.config.date_new
    },
    on: {
      change: _vm.dateFilterChange
    }
  }), _c("div", {
    staticClass: "filter-label"
  }, [_vm._v("更多筛选")]), _c("SavedFiltersContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filterNum,
      expression: "filterNum"
    }],
    staticClass: "filter-tags",
    attrs: {
      filter: _vm.localFormData.data_scope.config,
      "stage-options": _vm.stageOptions,
      "custom-filter-array": _vm.customFilterArray
    }
  }), _c("el-button", {
    staticClass: "filter-btn",
    attrs: {
      size: "medium",
      icon: "iconfont icon-filter"
    },
    on: {
      click: _vm.handleOpenSetOptionConvDrawer
    }
  }, [_vm._v("配置筛选条件")]), _c("ScoreSummary", {
    staticClass: "score-summary",
    attrs: {
      count: _vm.count,
      loading: _vm.countLoading
    },
    on: {
      countClick: _vm.openConvListDrawer
    }
  })], 1)], 1), _c("FormItemCard", {
    attrs: {
      title: "公开范围",
      tooltip: "不处于公开范围的成员，将无法查看此规则的评分结果"
    }
  }, [_c("ScopeOfOpen", {
    attrs: {
      "back-fill-value": _vm.backMemberObj,
      "require-validate": "",
      "is-edit": _vm.isEdit
    },
    on: {
      scopeChange: _vm.handleScopeChange,
      handleScopeError: _vm.handleScopeError
    }
  })], 1), _c("FormItemCard", {
    staticClass: "score-set-container",
    attrs: {
      title: "评分项"
    }
  }, [_c("ScoreItemSet", {
    ref: "scoreItemSet",
    attrs: {
      "has-allow-options-arr": _vm.hasAllowOptionsArr,
      "account-tag-options-list": _vm.accountTagOptionsList,
      "is-save-validate": _vm.isSaveValidate,
      "is-edit": _vm.isEdit,
      "current-score-id": _vm.currentScoreId
    },
    on: {
      "update:isSaveValidate": function ($event) {
        _vm.isSaveValidate = $event;
      },
      "update:is-save-validate": function ($event) {
        _vm.isSaveValidate = $event;
      },
      editScore: function ($event) {
        return _vm.$emit("editScore", $event);
      },
      deleteScoreRule: function ($event) {
        return _vm.$emit("deleteScoreRule", $event);
      }
    },
    model: {
      value: _vm.localFormData.score_item_config,
      callback: function ($$v) {
        _vm.$set(_vm.localFormData, "score_item_config", $$v);
      },
      expression: "localFormData.score_item_config"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;