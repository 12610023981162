"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BatchUploadDialog = _interopRequireDefault(require("@/components/BatchUploadDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    BatchUploadDialog: _BatchUploadDialog.default
  },
  data() {
    return {
      dialogList: [],
      clientVisible: false,
      employeeVisible: false,
      contactVisible: false,
      leadVisible: false,
      dealVisible: false
    };
  },
  mounted() {
    this.$bus.$on('openBatchUploadDialog', this.openDialog);
  },
  beforeDestroy() {
    this.$bus.$off('openBatchUploadDialog');
  },
  methods: {
    openDialog(type) {
      if (!this.dialogList.includes(type)) {
        this.dialogList.push(type);
      }
      this[`${type}Visible`] = true;
    },
    closeDialog(type) {
      this[`${type}Visible`] = false;
    }
  }
};
exports.default = _default;