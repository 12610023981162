"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _listCardItem = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCardItem"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConverListCard',
  components: {
    CardItem: _listCardItem.default
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      activeUnfoldIndex: null
    };
  },
  computed: {},
  mounted() {},
  methods: {
    openEventKeyDrawer(params) {
      this.$emit('openEventKeyDrawer', params);
    }
  }
};
exports.default = _default;