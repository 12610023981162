"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _KeyEventCascader = _interopRequireDefault(require("./KeyEventCascader"));
var _conversationList = require("@/api/conversationList");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'MutualExclusionRuleForm',
  props: {
    mutualExclusionRuleForm: {
      type: Object,
      default: () => ({
        config: {
          type: 1
        },
        mutex_details: [{
          event_id: '',
          order: 1
        }]
      })
    },
    usedIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['addMutexEventItem', 'deleteMutexEventItem'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const mutexRuleFormValidResult = (0, _vue.ref)(false);
    const getMutexRuleFormValidResult = () => {
      var _mutex_details$filter;
      const {
        mutex_details
      } = props.mutualExclusionRuleForm;
      const isLeastTwoEvents = ((_mutex_details$filter = mutex_details.filter(item => item.event_id)) === null || _mutex_details$filter === void 0 ? void 0 : _mutex_details$filter.length) >= 2;
      if (isLeastTwoEvents) {
        mutexRuleFormValidResult.value = false;
        return false;
      } else {
        mutexRuleFormValidResult.value = true;
        return true;
      }
    };
    const resetFormValid = () => {
      mutexRuleFormValidResult.value = false;
    };
    expose({
      getMutexRuleFormValidResult,
      resetFormValid
    });
    const selectedList = eventId => {
      const disableList = [...props.usedIds, ...props.mutualExclusionRuleForm.mutex_details.map(item => item.event_id)];
      console.log(props.usedIds, 'props.usedIds');
      return disableList.filter(_item => _item !== eventId);
    };
    const options = [{
      label: '触发时间最早',
      value: 1
    }, {
      label: '触发时间最晚',
      value: 2
    }, {
      label: '优先级最高',
      value: 3
    }];
    const store = (0, _useVuex.useStore)();
    const keyEventOptions = (0, _vue.ref)([]);
    const getAllKeyEventApi = async () => {
      // 获取级联数据
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: store.getters.workspaceInfo.id,
        show_without_perm: true
      });
      if (res.code === 20000) {
        keyEventOptions.value = res.results.events;
      }
    };
    const getEventLabel = (tree, id) => {
      let result = null;
      tree.forEach(item => {
        if (item.id === id) {
          result = item.name;
        }
        if (!result && item.children) {
          result = getEventLabel(item.children, id);
        }
      });
      return result;
    };
    const getSelectEventLabel = () => {
      let res = '';
      props.mutualExclusionRuleForm.mutex_details.reduce((pre, cur) => {
        if (cur.event_id) {
          const name = getEventLabel(keyEventOptions.value, cur.event_id);
          res = res ? `${res} > ${name}` : name;
        }
      }, '');
      return res;
    };
    const addMutexEventItem = () => {
      emit('addMutexEventItem');
    };
    const deleteMutexEventItem = index => {
      emit('deleteMutexEventItem', index);
    };
    (0, _vue.watch)(() => store.getters.workspaceInfo.id, val => {
      if (val) {
        getAllKeyEventApi();
      }
    }, {
      immediate: true
    });
    return {
      __sfc: true,
      props,
      mutexRuleFormValidResult,
      getMutexRuleFormValidResult,
      resetFormValid,
      selectedList,
      options,
      store,
      keyEventOptions,
      getAllKeyEventApi,
      getEventLabel,
      getSelectEventLabel,
      emit,
      addMutexEventItem,
      deleteMutexEventItem,
      MgDraggable: _MgDraggable.default,
      KeyEventCascader: _KeyEventCascader.default
    };
  }
};
exports.default = _default;