"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemHeaderEditable = _interopRequireDefault(require("./components/FormItemHeaderEditable.vue"));
var _GeneratorFormItemOperation = _interopRequireDefault(require("./components/GeneratorFormItemOperation.vue"));
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _GeneratorFormItem = _interopRequireDefault(require("@/components/EvaluateForm/components/GeneratorFormItem"));
var _WidgetFormItem = _interopRequireDefault(require("./components/WidgetFormItem.vue"));
var _nanoid = require("nanoid");
var _vue = require("vue");
var _formConfig = require("./form-config");
var _useEvaluateForm = require("./hooks/use-evaluate-form.js");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EvaluateFormGenerator',
  props: {
    schema: {
      type: Object,
      default: () => ({})
    },
    isEditStatus: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const schema = (0, _vue.ref)(props.schema);
    (0, _vue.watch)(() => props.schema, val => {
      schema.value = val;
    });
    const OPTIONS_IDS = [3, 4];
    const isOptionsField = id => OPTIONS_IDS.includes(id);
    const handleAddOption = formItem => {
      formItem.options.push({
        id: (0, _nanoid.nanoid)(),
        name: `选项${formItem.options.length + 1}`,
        option_order: formItem.options.length + 1
      });
    };
    // 判断引入的组件
    const getComponentName = key => 'FormItem' + _formConfig.fieldTypeMap.find(item => item.id === key).type.replace(/^\S/, s => s.toUpperCase());
    const resetOrder = () => {
      schema.value.fields.forEach((item, index) => {
        item.field_order = index + 1;
      });
    };
    // 添加表单项
    const addFormItem = function (id) {
      let index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : schema.value.fields.length;
      const filed = _formConfig.fieldTypeMap.find(item => item.id === id);
      const item = {
        id: (0, _nanoid.nanoid)(),
        name: filed.text,
        prompt: '',
        required: 0,
        field_order: index + 1,
        field_type: id,
        option_ids: []
      };
      if (OPTIONS_IDS.includes(id)) {
        item.options = [{
          id: (0, _nanoid.nanoid)(),
          name: '选项1',
          option_order: 1
        }, {
          id: (0, _nanoid.nanoid)(),
          name: '选项2',
          option_order: 2
        }];
      }
      if (id === 8) {
        item.options = (0, _cloneDeep.default)(_formConfig.cascaderDefaultOptions);
      }
      schema.value.fields.splice(index, 0, item);
      resetOrder();
    };
    const evaluateFormGenerator = (0, _vue.ref)(null);
    const {
      activeFormItem,
      addActiveFormItem,
      removeActiveFormItem
    } = (0, _useEvaluateForm.useActiveFormItem)(evaluateFormGenerator);
    const handleDragAdd = evt => {
      const id = evt.item.dataset.fieldType - 0;
      schema.value.fields.splice(evt.newIndex, 1);
      addFormItem(id, evt.newIndex);
    };
    const deleteFormItem = id => {
      const index = schema.value.fields.findIndex(item => item.id === id);
      schema.value.fields.splice(index, 1);
      removeActiveFormItem();
      resetOrder();
    };
    const handleDragEnd = () => {
      schema.value.fields.forEach((item, index) => {
        item.field_order = index + 1;
      });
      resetOrder();
    };
    const handleOptionDrag = (data, formItem) => {
      formItem.options = (0, _cloneDeep.default)(data);
      formItem.options.forEach((item, index) => {
        item.option_order = index + 1;
      });
    };
    const handleOptionFieldChange = (data, formItem) => {
      formItem.option_ids = data;
    };
    const handleDeleteOption = (id, formItem) => {
      formItem.options = formItem.options.filter(item => item.id !== id);
      formItem.option_ids = formItem.option_ids.filter(item => item !== id);
    };
    const getSchema = () => {
      return (0, _cloneDeep.default)(schema.value);
    };
    //  暴露方法
    expose({
      getSchema,
      addFormItem
    });
    return {
      __sfc: true,
      props,
      schema,
      OPTIONS_IDS,
      isOptionsField,
      handleAddOption,
      getComponentName,
      resetOrder,
      addFormItem,
      evaluateFormGenerator,
      activeFormItem,
      addActiveFormItem,
      removeActiveFormItem,
      handleDragAdd,
      deleteFormItem,
      handleDragEnd,
      handleOptionDrag,
      handleOptionFieldChange,
      handleDeleteOption,
      getSchema,
      FormItemHeaderEditable: _FormItemHeaderEditable.default,
      GeneratorFormItemOperation: _GeneratorFormItemOperation.default,
      draggable: _vuedraggable.default,
      GeneratorFormItem: _GeneratorFormItem.default
    };
  }
};
exports.default = _default;