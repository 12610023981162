"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'AccountTable',
  props: {
    tableLoading: {
      type: Boolean
    },
    accountList: {
      type: Array,
      default: () => []
    },
    dealForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableHeight: 330,
      tableHead: [{
        name: '客户姓名',
        field_name: 'name'
      }, {
        name: '创建时间',
        field_name: 'create_at'
      }],
      catchCurrentRow: null
    };
  },
  methods: {
    handleCurrentChange(currentRow) {
      if (currentRow) {
        this.catchCurrentRow = JSON.parse(JSON.stringify(currentRow));
        this.$set(this.dealForm, 'id', currentRow.id);
      }
      this.$emit('accountTableChange', this.catchCurrentRow);
    },
    formatterTest(row, column, cellValue, index) {
      if (column.property === 'create_at') {
        return cellValue ? this.$plus8ToCurrentZone(cellValue).format('YYYY-MM-DD HH:mm:ss') : '-';
      }
      return cellValue || '-';
    }
  }
};
exports.default = _default;