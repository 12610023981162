"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "operate-member-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.getDialogTitle,
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_vm.type === "edit" ? _c("div", {
    staticClass: "member-info"
  }, [_c("div", {
    staticClass: "member-card"
  }, [_c("image-avatar", {
    attrs: {
      avatar: _vm.memberInfo.avatar,
      name: _vm.memberInfo.name,
      size: "little-large"
    }
  }), _c("div", {
    staticClass: "avatar-text"
  }, [_c("span", [_vm._v(_vm._s(_vm.memberInfo.name))]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      placement: "top"
    }
  }, [_c("div", {
    ref: "content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.getDepartmentNames(_vm.memberInfo.departments))
    },
    slot: "content"
  }), _c("div", {
    staticClass: "departments"
  }, _vm._l(_vm.memberInfo.departments, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(_vm._s(item.super_tree_names[item.super_tree_names.length - 1])), item.is_main_tree ? _c("i", {
      staticClass: "main-department"
    }, [_vm._v("主部门")]) : _vm._e(), _vm._v(_vm._s(index !== _vm.memberInfo.departments.length - 1 ? "、" : ""))]);
  }), 0)])], 1)], 1), _vm.page === "管理员" && _vm.memberInfo.create_by ? _c("div", {
    staticClass: "card-info"
  }, [_vm._v(" 由" + _vm._s(_vm.memberInfo.create_by) + "于" + _vm._s(_vm.toCNTime(_vm.memberInfo.create_at)) + "添加 ")]) : _vm._e()]) : _vm._e(), _c("el-form", {
    ref: "operateMemberForm",
    attrs: {
      model: _vm.operateMemberForm,
      "hide-required-asterisk": true,
      rules: _vm.rules
    }
  }, [_vm.type !== "edit" ? _c("el-form-item", {
    attrs: {
      label: "选择成员",
      prop: "user_ids"
    }
  }, [_vm.page === "管理员" ? _c("members-finder", {
    ref: "memberFinder",
    staticClass: "single-select-member",
    attrs: {
      placeholder: "请选择成员",
      "has-self": false,
      "is-radio": true
    },
    on: {
      change: _vm.handleMemberChange
    }
  }) : _c("members-finder", {
    ref: "memberFinder",
    staticClass: "member-finder",
    attrs: {
      placeholder: "请选择成员",
      "tree-config": _vm.treeConfig,
      "has-self": false
    },
    on: {
      change: _vm.handleMemberChange
    }
  })], 1) : _vm._e(), _c("div", [_vm._v(" " + _vm._s(_vm.type !== "batchEdit" ? `${_vm.page}角色` : `修改后的${_vm.page}角色`) + " ")]), _vm.page === "管理员" ? _c("el-radio-group", {
    staticClass: "admin-radio",
    model: {
      value: _vm.adminRadio,
      callback: function ($$v) {
        _vm.adminRadio = $$v;
      },
      expression: "adminRadio"
    }
  }, [_vm.roleList.length && _vm.roleList.find(item => item.is_super_role).addable ? _c("el-radio", {
    attrs: {
      label: "superAdmin"
    }
  }, [_vm._v("超级管理员 "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      content: "拥有系统管理的所有权限",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1) : _vm._e(), _c("el-radio", {
    attrs: {
      label: "admin"
    }
  }, [_vm._v("管理员")])], 1) : _vm._e(), _vm.page !== "管理员" || _vm.adminRadio === "admin" ? _c("el-form-item", {
    attrs: {
      prop: "role_ids"
    }
  }, [_c("el-checkbox-group", {
    on: {
      change: _vm.getAdminRolesPermissionList
    },
    model: {
      value: _vm.operateMemberForm.role_ids,
      callback: function ($$v) {
        _vm.$set(_vm.operateMemberForm, "role_ids", $$v);
      },
      expression: "operateMemberForm.role_ids"
    }
  }, _vm._l(_vm.roleList.filter(item => !item.is_super_role), function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id,
        name: "funcRole",
        disabled: !item.addable
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1) : _vm._e(), _vm.page === "管理员" && _vm.adminRadio === "admin" ? _c("div", [_c("div", [_vm._v("权限预览")]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.permissionLoading,
      expression: "permissionLoading"
    }],
    staticClass: "permission-card"
  }, [_vm.permissionList.length ? _c("div", _vm._l(_vm.permissionList, function (item) {
    return _c("span", {
      key: item.id,
      staticClass: "permission-item"
    }, [_vm._v(_vm._s(item.name))]);
  }), 0) : _c("span", {
    staticClass: "no-permission"
  }, [_vm._v("无权限")])])]) : _vm._e(), _vm.page === "管理员" && _vm.hasManageScope && _vm.adminRadio === "admin" ? _c("el-form-item", {
    staticClass: "manage-scope",
    attrs: {
      prop: "manage_scope"
    }
  }, [_vm._v(" 部门和成员页面管理范围 "), _c("div", {
    staticClass: "scope-options"
  }, [_vm.scopeList.some(item => item.fid === 0) ? _c("span", [_vm._v("全部")]) : !_vm.scopeList.length ? _c("span", [_vm._v("未设置")]) : _vm._l(_vm.scopeList, function (scope) {
    return _c("span", {
      key: scope.id,
      staticClass: "manage-scope-item"
    }, [_vm._v(_vm._s(scope.name))]);
  }), _c("el-button", {
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.openEditScopeDialog
    }
  }, [_vm._v("编辑")])], 2)]) : _vm._e(), _vm.page === "管理员" && _vm.adminRadio === "admin" ? _c("div", {
    staticClass: "advanced-setting"
  }, [_c("div", {
    staticClass: "advanced-setting-title"
  }, [_vm._v("高级设置")]), _c("el-checkbox", {
    model: {
      value: _vm.isDistributable,
      callback: function ($$v) {
        _vm.isDistributable = $$v;
      },
      expression: "isDistributable"
    }
  }, [_vm._v("可以分配自己的权限给其他成员 "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      content: "勾选后，此管理员可以添加新管理员，但只能授予自己权限范围内的角色",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1)], 1) : _vm._e()], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确定")])], 1)], 1), _c("transer-department-finder-dialog", {
    attrs: {
      visible: _vm.departmentsFinderDialogVisible,
      "default-checked": _vm.scopeList,
      "manage-tree": _vm.manageTree
    },
    on: {
      "update:visible": function ($event) {
        _vm.departmentsFinderDialogVisible = $event;
      },
      submit: _vm.submitManageScope
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;