"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GroupSaveButton = _interopRequireDefault(require("./GroupSaveButton.vue"));
var _GroupImportButton = _interopRequireDefault(require("./GroupImportButton.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'MemberGroupOperation',
  props: {
    membersIds: {
      type: Object,
      default: () => {
        return {
          user_ids: [],
          tree_ids: []
        };
      }
    }
  },
  emits: ['getImportMemberList'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const tableData = (0, _vue.ref)([]);
    const tableLoading = (0, _vue.ref)(false);
    async function getTableList() {
      tableLoading.value = true;
      const res = await (0, _coachingCenter.getMemberGroupList)().finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        tableData.value = res.results.filters;
      }
    }
    (0, _vue.watchEffect)(() => {
      getTableList();
    });
    return {
      __sfc: true,
      props,
      emit,
      tableData,
      tableLoading,
      getTableList,
      GroupSaveButton: _GroupSaveButton.default,
      GroupImportButton: _GroupImportButton.default
    };
  }
};
exports.default = _default;