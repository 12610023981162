"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("./tribute.scss");
var _Tribute = _interopRequireDefault(require("./Tribute"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Tribute.js
 * Native ES6 JavaScript @mention Plugin
 **/
var _default = _Tribute.default;
exports.default = _default;