"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "choose-file"
  }, [_vm.type !== "employee" ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("h5", [_vm._v(" 一、请按照数据模板的格式准备要导入的数据。 "), _c("el-link", {
    staticClass: "download-link",
    on: {
      click: _vm.downloadTemplate
    }
  }, [_vm._v("下载" + _vm._s(_vm.uploadName) + "导入模板")])], 1), _c("p", {
    staticClass: "plain"
  }, [_vm._v("注意事项：请按照表内格式要求填写表格")])]), _c("div", {
    staticClass: "row"
  }, [_c("h5", [_vm._v("二、请选择需要导入的文件")]), _c("div", {
    staticClass: "plain upload"
  }, [_c("el-upload", {
    staticClass: "batch-uploader",
    attrs: {
      action: "",
      accept: ".xlsx,.xls",
      "show-file-list": false,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("选择文件")])], 1), _c("el-input", {
    staticClass: "file-input",
    attrs: {
      readonly: "",
      disabled: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.fileName,
      callback: function ($$v) {
        _vm.fileName = $$v;
      },
      expression: "fileName"
    }
  })], 1)])]) : _c("div", {
    staticClass: "members-content"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("h5", [_vm._v(" 一、请按照模板格式准备要导入的数据。"), _c("el-link", {
    staticClass: "download-link",
    on: {
      click: _vm.downloadTemplate
    }
  }, [_vm._v("下载《员工导入模板》")])], 1), _vm._m(0)]), _c("div", {
    staticClass: "row"
  }, [_c("h5", [_vm._v("三、请选择需要导入的文件")]), _c("div", {
    staticClass: "plain upload"
  }, [_c("el-upload", {
    staticClass: "batch-uploader",
    attrs: {
      action: "",
      accept: ".xlsx,.xls",
      "show-file-list": false,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("选择文件")])], 1), _c("el-input", {
    staticClass: "file-input",
    attrs: {
      readonly: "",
      disabled: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.fileName,
      callback: function ($$v) {
        _vm.fileName = $$v;
      },
      expression: "fileName"
    }
  })], 1), _vm._m(1)])]), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: function ($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.file
    },
    on: {
      click: _vm.startImport
    }
  }, [_vm._v("开始导入")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "plain"
  }, [_vm._v(" 说明："), _c("br"), _vm._v(" 1.请勿修改表格结构；"), _c("br"), _vm._v(" 2.主部门：只有一个，请注意与系统内组织架构部门保持一致；不填写则默认根部门；"), _c("br"), _vm._v(" 3.角色：可写入主部门下的一个角色名；不填写则默认普通成员。 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "plain"
  }, [_vm._v(" 说明："), _c("br"), _vm._v(" 1.添加成员以手机号来查重；"), _c("br"), _vm._v(" 2.手机号重复时，将跳过该数据；"), _c("br"), _vm._v(" 3.主部门或角色填写有误时，将置空相应信息、继续添加该成员。 ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;