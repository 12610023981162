"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "single-sentence"
  }, [_c("div", {
    staticClass: "single-sentence-author"
  }, [_vm._v(_vm._s(_vm.sentence.name))]), _c("div", {
    staticClass: "single-sentence-content"
  }, [_c("p", {
    staticClass: "sentence-content"
  }, [_c("span", {
    class: ["time-box", {
      "is-customer": _vm.sentence.entity_type === "customer_contact"
    }]
  }, [_c("span", {
    staticClass: "time-point"
  }), _vm._v(" " + _vm._s(_vm.mediaType === "doc" ? _setup.plus8ToCurrentZone(_vm.sentence.begin_time * 1000).format("YYYY-MM-DD HH:mm") : _setup.secondToTime(_vm.sentence.begin_time)) + " ")]), _c("span", {
    staticClass: "sentence-word"
  }, [_vm._v(_vm._s(_vm.sentence.content))])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;