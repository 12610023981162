"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mgv-tour"
  }, [_c("v-tour", {
    ref: "v-tour",
    attrs: {
      name: _vm.name,
      steps: _vm.customSteps,
      callbacks: _vm.callbacks,
      options: _vm.options
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (tour) {
        return [_c("Transition", {
          attrs: {
            name: "fade"
          }
        }, [tour.steps[tour.currentStep] ? _c("v-step", {
          key: tour.currentStep,
          attrs: {
            step: tour.steps[tour.currentStep],
            "previous-step": tour.previousStep,
            "next-step": tour.nextStep,
            stop: tour.stop,
            skip: tour.skip,
            finish: tour.finish,
            "is-first": tour.isFirst,
            "is-last": tour.isLast,
            labels: tour.labels
          },
          scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slotName) {
            return {
              key: slotName,
              fn: function (slotProps) {
                return [_vm._t(slotName, null, {
                  tour: tour
                }, slotProps)];
              }
            };
          })], null, true)
        }, [_c("template", {
          slot: "header"
        }, [_c("div", {
          staticClass: "v-step__header"
        }, [_c("div", [_vm._v(_vm._s(tour.currentStep + 1) + "/" + _vm._s(_vm.steps.length))]), _c("div", {
          staticClass: "close-btn",
          on: {
            click: function ($event) {
              return _vm.closeTour(tour);
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-close"
        })])]), tour.steps[tour.currentStep].header.title ? _c("div", {
          staticClass: "v-step__title"
        }, [_vm._v(" " + _vm._s(tour.steps[tour.currentStep].header.title) + " ")]) : _vm._e()]), _c("div", {
          attrs: {
            slot: "actions"
          },
          slot: "actions"
        }, [_vm.steps.length === 1 ? _c("div", {
          staticClass: "v-step__buttons v-step__single-buttons"
        }, [_vm.knowMore ? _c("div", {
          staticClass: "v-step__button-more v-step__button",
          on: {
            click: function ($event) {
              return _vm.callbacks.onMore(tour);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("coaching.learnMore")) + " ")]) : _vm._e(), _c("div", {
          staticClass: "v-step__button-know v-step__button",
          on: {
            click: function ($event) {
              return _vm.handleFinish(tour);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("coaching.gotIt")) + " ")])]) : _c("div", {
          staticClass: "v-step__buttons",
          class: {
            "no-know-more-btn": !_vm.knowMore
          }
        }, [_vm.knowMore ? _c("div", {
          staticClass: "v-step__button-more v-step__button",
          on: {
            click: function ($event) {
              return _vm.callbacks.onMore(tour);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("coaching.learnMore")) + " ")]) : _vm._e(), _c("div", {
          staticClass: "btns-content"
        }, [tour.currentStep !== 0 ? _c("div", {
          staticClass: "v-step__button v-step__button-previous",
          on: {
            click: tour.previousStep
          }
        }, [_c("i", {
          staticClass: "iconfont icon-back"
        }), _vm._v(" " + _vm._s(_vm.$t("coaching.previous")) + " ")]) : _vm._e(), tour.currentStep !== _vm.steps.length - 1 ? _c("div", {
          staticClass: "v-step__button v-step__button-next",
          on: {
            click: tour.nextStep
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("coaching.next")) + " "), _c("i", {
          staticClass: "iconfont icon-right"
        })]) : _vm._e(), tour.currentStep === _vm.steps.length - 1 ? _c("div", {
          staticClass: "v-step__button v-step__button-finish",
          on: {
            click: function ($event) {
              return _vm.handleFinish(tour);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("coaching.gotIt")) + " ")]) : _vm._e()])])])], 2) : _vm._e()], 1)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;