"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["shortcut-keys-drawer", {
      show: _vm.drawerVisible
    }]
  }, [_c("header", [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.shortcutKeys")))]), _c("el-button", {
    staticClass: "close-drawer hover-show-bg",
    attrs: {
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("update:drawerVisible", false);
      }
    }
  })], 1), _c("article", _vm._l(_vm.formatShortcutKeysList(), function (_ref, listKey) {
    let {
      label,
      description,
      icons
    } = _ref;
    return _c("div", {
      key: listKey,
      staticClass: "shortcut-key-item"
    }, [_c("div", {
      staticClass: "left"
    }, [_c("p", [_vm._v(_vm._s(_vm.$t(label)))]), _c("p", [_vm._v(_vm._s(_vm.$t(description)))])]), _c("div", {
      staticClass: "right"
    }, [_vm._l(icons, function (_ref2, index) {
      let {
        type,
        data
      } = _ref2;
      return [index && index < icons.length ? _c("span", {
        key: index
      }, [_vm._v(" + ")]) : _vm._e(), type === "text" ? _c("span", {
        key: listKey * 10 + index,
        staticClass: "has-border"
      }, [_vm._v(_vm._s(data))]) : _vm._e(), type === "img" ? _c("img", {
        key: listKey * 10 + index,
        attrs: {
          src: data
        }
      }) : _vm._e()];
    })], 2)]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;