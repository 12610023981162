"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    staticClass: "my-member-table",
    attrs: {
      data: _vm.tableData,
      height: "200"
    }
  }, _vm._l(_setup.tableHeads, function (_ref) {
    let {
      prop,
      label,
      width
    } = _ref;
    return _c("el-table-column", {
      key: prop,
      attrs: {
        prop: prop,
        label: label,
        width: width,
        fit: ""
      },
      scopedSlots: _vm._u([prop === "name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "member-info"
          }, [_c("mgv-avatar-name", {
            attrs: {
              size: 28,
              src: row.avatar,
              name: row.user_name
            }
          }), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.user_name
            }
          }, [_c("div", {
            staticClass: "member-name"
          }, [_vm._v(" " + _vm._s(row.user_name) + " ")])])], 1)];
        }
      } : prop === "tree_path" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "department-info"
          }, [_c("el-tooltip", {
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.tree_path
            }
          }, [_c("span", {
            staticClass: "department-name"
          }, [_vm._v(" " + _vm._s(row.tree_path.split("/").pop()) + " ")])])], 1)];
        }
      } : prop === "operate" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("span", {
            staticClass: "operate-content",
            on: {
              click: function ($event) {
                return _setup.goCompliance(row);
              }
            }
          }, [_vm._v("开始质检")])];
        }
      } : null], null, true)
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;