"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "root"
  }, [_c("detail-header", {
    staticClass: "snip-detail-header",
    attrs: {
      "back-button": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            placement: "top",
            effect: "dark",
            content: _vm.conversationTitleInfo.convName,
            "popper-class": "snip-detail-tooltip"
          }
        }, [_c("span", {
          staticClass: "detail-title"
        }, [_vm._v(_vm._s(_vm.conversationTitleInfo.convName))])])];
      },
      proxy: true
    }, {
      key: "header_left_bottom",
      fn: function () {
        return [_vm.conversationTitleInfo.convTime ? _c("div", {
          staticClass: "header-left-bottom"
        }, [_c("span", [_vm._v(_vm._s(_vm.timeDescribe))]), _c("div", {
          staticClass: "conv-date"
        }, [_vm._v(_vm._s(_vm.convdate))]), _c("div", {
          staticClass: "conv-time"
        }, [_vm._v(_vm._s(_vm.convtime))]), _c("div", {
          staticClass: "split-line"
        }), _c("div", {
          staticClass: "conv-dur"
        }, [_vm._v(_vm._s(_vm.convDuration))])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_c("div", {
          staticClass: "top-btns"
        }, [_c("div", {
          directives: [{
            name: "debounce",
            rawName: "v-debounce",
            value: [_vm.handleThumbsUp],
            expression: "[handleThumbsUp]"
          }],
          staticClass: "interaction thumbs_up",
          class: {
            active: _vm.hasLiked
          }
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.hasLiked ? "icon-great-3" : "icon-great-2"
        }), _c("span", [_vm._v(_vm._s(_vm.hasLiked ? "已点赞" : "点赞"))]), _c("span", {
          staticClass: "padding-left4"
        }, [_vm._v(_vm._s(_vm.thumbsUpNumber))])]), _c("div", {
          staticClass: "interaction collect",
          class: {
            active: _vm.hasCollected
          },
          on: {
            click: _vm.handleCollect
          }
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.hasCollected ? "icon-star-on-2" : "icon-star-off"
        }), _c("span", [_vm._v(_vm._s(_vm.hasCollected ? "已收藏" : "收藏"))]), _c("span", {
          staticClass: "padding-left4"
        }, [_vm._v(_vm._s(_vm.collectNumber))])]), _c("el-button", {
          staticClass: "interaction",
          class: {
            active: _vm.rightBarActive === "comments"
          },
          attrs: {
            icon: "iconfont icon-chat-line-square"
          },
          on: {
            click: function ($event) {
              return _vm.selectActiveBar("comments");
            }
          }
        }, [_vm._v("评论")]), _vm.interfacePermission.downloadSnippet && _vm.mediaType !== "doc" ? _c("el-button", {
          staticClass: "interaction download",
          attrs: {
            plain: "",
            icon: "iconfont icon-download"
          },
          on: {
            click: _vm.downloadSnippet
          }
        }, [_vm._v("下载")]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.hasDetailInfo,
      expression: "!hasDetailInfo"
    }],
    staticClass: "snippet-detail"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "main-top-content"
  }, [_c("conv-play-content", {
    ref: "mainContent",
    staticClass: "conv-play-content",
    attrs: {
      "hide-fix-operates": "",
      "video-url": _vm.videoUrl,
      "tab-active-name": _vm.tabActiveName,
      "search-words": _vm.searchWords,
      "result-list": _vm.resultList,
      origin: _vm.origin,
      "conv-id": _vm.snippetId,
      "media-type": _vm.mediaType,
      "parti-info": _vm.partiInfo,
      "right-bar-active": _vm.rightBarActive
    },
    on: {
      switchTab: _vm.switchTab,
      changeSliderTime: _vm.changeSliderTime,
      goKeyDetail: _vm.goKeyDetail,
      goTransText: _vm.goTransText,
      openRightDrawer: _vm.selectActiveBar,
      handleComment: _vm.handleComment
    }
  })], 1), _c("div", {
    staticClass: "main-bottom-content"
  }, [_c("conv-control-content", {
    ref: "convControlContent",
    staticClass: "video-wrapper",
    attrs: {
      url: _vm.videoUrl,
      "parti-info": _vm.partiInfo,
      "active-round-id": _vm.snippetId,
      "share-percent": _vm.sharePercent,
      "share-screen": _vm.shareScreen,
      origin: _vm.origin,
      "media-type": _vm.mediaType,
      "local-slider-time": _vm.localSliderTime,
      "conv-detail-info": _vm.overviewInfo
    }
  })], 1)]), _c("div", {
    ref: "rightContent",
    class: ["right-content", _vm.rightBarActive ? "expand" : "close"]
  }, [_c("comment-drawer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightBarActive,
      expression: "rightBarActive"
    }],
    ref: "rightDrawer",
    staticClass: "comment-drawer",
    attrs: {
      "right-bar-active": _vm.rightBarActive,
      "conv-id": _vm.snippetId,
      "participants-info": _vm.partiInfo,
      "media-type": _vm.mediaType,
      "only-evaluation": "",
      "comment-type": "snippet"
    },
    on: {
      closeDrawer: _vm.closeDrawer,
      getCommentsCount: function ($event) {
        _vm.commentsCount = $event;
      },
      selectTransText: _vm.selectTransText
    }
  })], 1)]), _vm.isDownloading ? _c("div", {
    staticClass: "downloading-snippet"
  }, [_c("i", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDownloading,
      expression: "isDownloading"
    }]
  }), _c("span", [_vm._v("正在下载视频")])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;