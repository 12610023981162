"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSampleRule = addSampleRule;
exports.assignCompliance = assignCompliance;
exports.cancelComplianceTask = cancelComplianceTask;
exports.cancelEditScoreRule = cancelEditScoreRule;
exports.changeComplianceScoreRuleStatus = changeComplianceScoreRuleStatus;
exports.createComplianceAssign = createComplianceAssign;
exports.createComplianceScoreRule = createComplianceScoreRule;
exports.createEvaluationTemplate = createEvaluationTemplate;
exports.deleteComplianceScoreRule = deleteComplianceScoreRule;
exports.deleteComplianceTask = deleteComplianceTask;
exports.deleteEvaluationTemplate = deleteEvaluationTemplate;
exports.deleteSampleRule = deleteSampleRule;
exports.downloadComplianceTemplate = downloadComplianceTemplate;
exports.exportComplianceAssignMember = exportComplianceAssignMember;
exports.exportComplianceConversationPersonalApi = exportComplianceConversationPersonalApi;
exports.exportComplianceMyConversationApi = exportComplianceMyConversationApi;
exports.exportComplianceMyTaskApi = exportComplianceMyTaskApi;
exports.exportComplianceTeamConversationApi = exportComplianceTeamConversationApi;
exports.exportComplianceTeamTaskApi = exportComplianceTeamTaskApi;
exports.exportComplianceTeamTaskDetailMemberApi = exportComplianceTeamTaskDetailMemberApi;
exports.exportMyComplianceMember = exportMyComplianceMember;
exports.getAccountSaleConvList = getAccountSaleConvList;
exports.getAllComplianceOfConversationIds = getAllComplianceOfConversationIds;
exports.getComplianceAssignMember = getComplianceAssignMember;
exports.getComplianceConversationPersonalApi = getComplianceConversationPersonalApi;
exports.getComplianceCount = getComplianceCount;
exports.getComplianceDetail = getComplianceDetail;
exports.getComplianceHeadConfig = getComplianceHeadConfig;
exports.getComplianceMyConversationApi = getComplianceMyConversationApi;
exports.getComplianceRuleList = getComplianceRuleList;
exports.getComplianceScore = getComplianceScore;
exports.getComplianceScoreInfo = getComplianceScoreInfo;
exports.getComplianceTeamConversationApi = getComplianceTeamConversationApi;
exports.getComplianceTeamProgressApi = getComplianceTeamProgressApi;
exports.getConvEvaluationRecord = getConvEvaluationRecord;
exports.getEvaluationItemList = getEvaluationItemList;
exports.getEvaluationRecord = getEvaluationRecord;
exports.getEvaluationTemplate = getEvaluationTemplate;
exports.getEvaluationTemplateList = getEvaluationTemplateList;
exports.getMyComplianceMember = getMyComplianceMember;
exports.getMyComplianceTaskList = getMyComplianceTaskList;
exports.getMyConvTaskList = getMyConvTaskList;
exports.getMyTaskTabCount = getMyTaskTabCount;
exports.getRemoveConvIds = getRemoveConvIds;
exports.getRemoveList = getRemoveList;
exports.getSampleConvCount = getSampleConvCount;
exports.getSampleConvList = getSampleConvList;
exports.getSampleRuleList = getSampleRuleList;
exports.getSamplingScoreRuleList = getSamplingScoreRuleList;
exports.getScoreRuleList = getScoreRuleList;
exports.getTeamComplianceTaskList = getTeamComplianceTaskList;
exports.getTeamConvTaskList = getTeamConvTaskList;
exports.getTeamConversationTabCount = getTeamConversationTabCount;
exports.getTeamMemberTaskList = getTeamMemberTaskList;
exports.getTeamTaskTabCount = getTeamTaskTabCount;
exports.getUserInfo = getUserInfo;
exports.handleDefaultEvaluation = handleDefaultEvaluation;
exports.rejectCompliance = rejectCompliance;
exports.rejectConvDetailCompliance = rejectConvDetailCompliance;
exports.removeOrRestoreComplianceConv = removeOrRestoreComplianceConv;
exports.renameSampleRule = renameSampleRule;
exports.sampleConv = sampleConv;
exports.startEditScoreRule = startEditScoreRule;
exports.updateComplianceDetail = updateComplianceDetail;
exports.updateComplianceHeadConfig = updateComplianceHeadConfig;
exports.updateComplianceScoreRule = updateComplianceScoreRule;
exports.updateConvDetailComplianceDetail = updateConvDetailComplianceDetail;
exports.updateEvaluationTemplate = updateEvaluationTemplate;
exports.uploadComplianceMember = uploadComplianceMember;
var _request = require("@/utils/request");
// 团队质检进度
function getComplianceTeamProgressApi(data) {
  return (0, _request.webService)({
    url: '/compliance/progress/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队质检会话
function getComplianceTeamConversationApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/team',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队质检会话-个人
function getComplianceConversationPersonalApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/person',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 我的质检会话
function getComplianceMyConversationApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/my',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出团队质检会话——个人
function exportComplianceConversationPersonalApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/person/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出我的质检会话
function exportComplianceMyConversationApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/my/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出团队质检任务
function exportComplianceTeamTaskApi(data) {
  return (0, _request.webService)({
    url: '/compliance_task/team/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出团队质检详情-成员视图
function exportComplianceTeamTaskDetailMemberApi(data) {
  return (0, _request.webService)({
    url: '/compliance_task/team/member_list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出团队质检会话 || 导出团队质检详情-会话视图
function exportComplianceTeamConversationApi(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/team/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出我的质检任务
function exportComplianceMyTaskApi(data) {
  return (0, _request.webService)({
    url: '/compliance_task/my/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新增抽样规则
function addSampleRule(data) {
  return (0, _request.webService)({
    url: '/compliance/sample_rule/add',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取抽样规则列表
function getSampleRuleList(params) {
  return (0, _request.request)({
    url: '/compliance/sample_rule/list',
    method: 'get',
    params
  });
}

// 重命名抽样规则
function renameSampleRule(data) {
  return (0, _request.webService)({
    url: '/compliance/sample_rule/rename',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除抽样规则
function deleteSampleRule(data) {
  return (0, _request.webService)({
    url: '/compliance/sample_rule/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 根据筛选条件获取可供抽样的会话总数
function getSampleConvCount(data) {
  return (0, _request.request)({
    url: '/compliance/sampling/count',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 抽样会话
function sampleConv(data) {
  return (0, _request.request)({
    url: '/compliance/sampling',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 抽样会话详情列表
function getSampleConvList(data) {
  return (0, _request.request)({
    url: '/compliance/sampling/detail/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取质检评分
function getComplianceScore(params) {
  return (0, _request.webService)({
    url: 'compliance/conv/score_result',
    method: 'get',
    params
  });
}

// 获取质检会话ids
function getAllComplianceOfConversationIds(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 分配
function assignCompliance(data) {
  return (0, _request.webService)({
    url: '/compliance/conv/assign',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 驳回质检
function rejectCompliance(data) {
  return (0, _request.webService)({
    url: '/compliance/conv/result_overrule',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话详情驳回质检
function rejectConvDetailCompliance(data) {
  return (0, _request.webService)({
    url: '/compliance/conv/conversation/result_overrule',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取质检详情
function getComplianceDetail(params) {
  return (0, _request.webService)({
    url: '/compliance/conv/result',
    method: 'get',
    params
  });
}

// 更新质检状态
function updateComplianceDetail(data) {
  return (0, _request.webService)({
    url: '/compliance/conv/result_update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话详情更新质检状态
function updateConvDetailComplianceDetail(data) {
  return (0, _request.webService)({
    url: '/compliance/conv/conversation/result_update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取ai质检规则列表
function getComplianceRuleList(params) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/list',
    method: 'get',
    params
  });
}

// 获取ai评分规则详情
function getComplianceScoreInfo(params) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/get_info',
    method: 'get',
    params
  });
}

// 更新ai质检评分规则
function updateComplianceScoreRule(data) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建ai质检评分规则
function createComplianceScoreRule(data) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更改ai质检评分规则状态
function changeComplianceScoreRuleStatus(data) {
  return (0, _request.request)({
    url: '/compliance/score_rule/change_status',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除质检规则
function deleteComplianceScoreRule(data) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队质检任务列表
function getTeamComplianceTaskList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/list/team',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 我的质检任务列表
function getMyComplianceTaskList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/list/my',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队质检任务 - 成员视图列表
function getTeamMemberTaskList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/member_list/team',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队质检任务 - 会话视图列表
function getTeamConvTaskList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/conv_list/team',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 我的质检任务 - 会话视图列表
function getMyConvTaskList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/conv_list/my',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检任务的质检规则表头
function getScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/compliance_task/score_rule/visible_list',
    method: 'get',
    params
  });
}

// 抽样会话时获取已启用质检规则列表
function getSamplingScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/compliance/sampling/score_rule/list',
    method: 'get',
    params
  });
}

// 质检评分规则开始编辑
function startEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/start_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检评分规则取消编辑
function cancelEditScoreRule(data) {
  return (0, _request.webService)({
    url: '/compliance/score_rule/cancel_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检评分规则列表页 - 根据评分规则获取会话数量
function getComplianceCount(params) {
  return (0, _request.request)({
    url: '/compliance/score_rule/get_count',
    method: 'get',
    params
  });
}

// 获取评价单模板列表
function getEvaluationTemplateList(data) {
  return (0, _request.webService)({
    url: '/evaluation/template/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新评价单模板
function updateEvaluationTemplate(data) {
  return (0, _request.webService)({
    url: '/evaluation/template/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除评价单模板
function deleteEvaluationTemplate(data) {
  return (0, _request.webService)({
    url: '/evaluation/template/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检任务关联评价项
function getEvaluationItemList(params) {
  return (0, _request.webService)({
    url: '/compliance_task/evaluation_item/list',
    method: 'get',
    params
  });
}

// 创建评价单模板
function createEvaluationTemplate(data) {
  return (0, _request.webService)({
    url: '/evaluation/template/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取评价单模板
function getEvaluationTemplate(params) {
  return (0, _request.webService)({
    url: '/evaluation/template/get',
    method: 'get',
    params
  });
}

// 获取评价记录
function getEvaluationRecord(params) {
  return (0, _request.webService)({
    url: '/evaluation/record/get',
    method: 'get',
    params
  });
}

// 团队质检任务tab状态数量
function getTeamTaskTabCount(params) {
  return (0, _request.webService)({
    url: '/compliance_task/list/team/count',
    method: 'get',
    params
  });
}

// 团队质检会话——tab数量
function getTeamConversationTabCount(params) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/team/count',
    method: 'get',
    params
  });
}

// 我的质检任务tab状态数量
function getMyTaskTabCount(params) {
  return (0, _request.webService)({
    url: '/compliance_task/list/my/count',
    method: 'get',
    params
  });
}

// 取消任务
function cancelComplianceTask(data) {
  return (0, _request.webService)({
    url: '/compliance_task/cancel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除任务
function deleteComplianceTask(data) {
  return (0, _request.webService)({
    url: '/compliance_task/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话详情页-获取评价单
function getConvEvaluationRecord(params) {
  return (0, _request.webService)({
    url: '/evaluation/record/conversation/get',
    method: 'get',
    params
  });
}

// 设置/取消默认评价单
function handleDefaultEvaluation(data) {
  return (0, _request.webService)({
    url: '/evaluation/template/default',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检工作台-同客同销售会话列表
function getAccountSaleConvList(data) {
  return (0, _request.webService)({
    url: '/compliance/account_sale/conv/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移除/恢复会话记录
function removeOrRestoreComplianceConv(data) {
  return (0, _request.webService)({
    url: '/compliance_task/conv_list/remove/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取移除会话记录
function getRemoveList(data) {
  return (0, _request.webService)({
    url: '/compliance_task/conv_list/remove/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取已移除记录ids
function getRemoveConvIds(data) {
  return (0, _request.webService)({
    url: '/compliance_task/conv_list/remove/ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取质检会话列表头配置
function getComplianceHeadConfig(params) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/head/get',
    method: 'get',
    params
  });
}

// 更新质检会话列表头配置
function updateComplianceHeadConfig(data) {
  return (0, _request.webService)({
    url: '/compliance/conv_list/head/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取质检成员分配列表
function getComplianceAssignMember(data) {
  return (0, _request.webService)({
    url: '/compliance_member/compliance_user',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 质检成员分配列表导出
function exportComplianceAssignMember(data) {
  return (0, _request.webService)({
    url: '/compliance_member/compliance_user/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取我的质检成员列表
function getMyComplianceMember(data) {
  return (0, _request.webService)({
    url: '/compliance_member/member_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 我的质检成员列表导出
function exportMyComplianceMember(data) {
  return (0, _request.webService)({
    url: '/compliance_member/member_list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 根据部门或用户获取用户信息
function getUserInfo(data) {
  return (0, _request.webService)({
    url: '/compliance_member/user_info',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 下载质检成员分配模板文件
function downloadComplianceTemplate(params) {
  return (0, _request.request)({
    url: '/batch/compliance',
    method: 'get',
    params
  });
}

// 质检成员分配创建
function createComplianceAssign(data) {
  return (0, _request.webService)({
    url: '/compliance_member/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 上传成员名单
function uploadComplianceMember(data) {
  return (0, _request.request)({
    url: '/batch/upload/compliance',
    method: 'post',
    data
  });
}