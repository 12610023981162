"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer create-rule-model-drawer",
    attrs: {
      visible: _vm.visible,
      title: `配置模型 - ${_setup.ruleEventForm.name}`,
      size: "850px"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("el-form", {
    ref: "ruleEventRef",
    attrs: {
      model: _setup.ruleEventForm,
      rules: _setup.rules
    }
  }, [_c(_setup.TriggerRulesFormItem, {
    attrs: {
      form: _setup.ruleEventForm,
      "rule-count": _setup.ruleCount
    }
  })], 1), _c(_setup.RuleTest, {
    attrs: {
      "test-result": _setup.testResult
    },
    on: {
      handleTestRuleEvent: _setup.handleTestRuleEvent
    }
  })], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.editModelAsync
    }
  }, [_vm._v("保存")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;