"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ttsAvatarNameSelect = _interopRequireDefault(require("./ttsAvatarNameSelect.vue"));
var _vue = require("vue");
var _SettingSubtitle = _interopRequireDefault(require("./SettingSubtitle"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'PersonalizedSetting',
  setup(__props) {
    const promptStruct = (0, _vue.inject)('promptStruct');
    const onAvatarChange = (val, item) => {
      console.log(promptStruct.avatar_url, item);
      promptStruct.value.avatar_url = item.url;
    };
    return {
      __sfc: true,
      promptStruct,
      onAvatarChange,
      ttsAvatarNameSelect: _ttsAvatarNameSelect.default,
      SettingSubtitle: _SettingSubtitle.default
    };
  }
};
exports.default = _default;