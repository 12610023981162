"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "rule-card-content"
  }, [_c("div", {
    staticClass: "conditions"
  }, _vm._l(_setup.content["conditions"], function (item) {
    return _c("el-form", {
      key: item.type,
      ref: "conditionsFormRef",
      refInFor: true,
      staticClass: "condition-item conv-score",
      attrs: {
        model: item.content,
        rules: _setup.contentTypeMap[_vm.processType].conditionsFormRules
      }
    }, [item.type === "conv_score" ? [_c("span", [_vm._v("当会话评分规则")]), _c("el-form-item", {
      key: _vm.cardInfo.id,
      attrs: {
        prop: "score_rule_id"
      }
    }, [_c("el-select", {
      attrs: {
        disabled: !_vm.isEditing
      },
      model: {
        value: item.content.score_rule_id,
        callback: function ($$v) {
          _vm.$set(item.content, "score_rule_id", $$v);
        },
        expression: "item.content.score_rule_id"
      }
    }, _vm._l(_setup.scoreRuleOptions, function (option) {
      return _c("el-option", {
        key: option.id,
        attrs: {
          label: option.name,
          value: option.id
        }
      });
    }), 1)], 1), _c("span", [_vm._v("的评分在")]), _c("el-form-item", {
      key: _vm.cardInfo.id,
      attrs: {
        prop: "score_range"
      }
    }, [_c("el-input-number", {
      attrs: {
        disabled: !_vm.isEditing,
        controls: false,
        precision: 0,
        min: 0,
        max: 10000
      },
      model: {
        value: item.content.score_range[0],
        callback: function ($$v) {
          _vm.$set(item.content.score_range, 0, $$v);
        },
        expression: "item.content.score_range[0]"
      }
    }), _c("span", {
      staticClass: "score-range-delimiter"
    }, [_vm._v("-")]), _c("el-input-number", {
      attrs: {
        disabled: !_vm.isEditing,
        controls: false,
        precision: 0,
        min: 0,
        max: 10000
      },
      model: {
        value: item.content.score_range[1],
        callback: function ($$v) {
          _vm.$set(item.content.score_range, 1, $$v);
        },
        expression: "item.content.score_range[1]"
      }
    })], 1), _c("span", [_vm._v("时,")])] : _vm._e()], 2);
  }), 1), _c("div", {
    staticClass: "action"
  }, [_c("el-form", {
    ref: "actionFormRef",
    attrs: {
      model: _setup.getActionInfo.content,
      rules: _setup.contentTypeMap[_vm.processType].actionFormRules
    }
  }, [_setup.getActionInfo.type === "fill_select_field" ? [_c("div", {
    staticClass: "action-item fill-field"
  }, [_c("span", [_vm._v("自动填写自定义字段")]), _c("el-form-item", {
    key: _vm.cardInfo.id,
    attrs: {
      prop: "field_id"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.isEditing
    },
    on: {
      change: _setup.handleSelectFieldChange
    },
    model: {
      value: _setup.getActionInfo.content.field_id,
      callback: function ($$v) {
        _vm.$set(_setup.getActionInfo.content, "field_id", $$v);
      },
      expression: "getActionInfo.content.field_id"
    }
  }, _vm._l(_setup.selectFields, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("span", [_vm._v("的内容为")]), _c("el-form-item", {
    key: _vm.cardInfo.id,
    attrs: {
      prop: "option_key"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.isEditing
    },
    model: {
      value: _setup.getActionInfo.content.option_key,
      callback: function ($$v) {
        _vm.$set(_setup.getActionInfo.content, "option_key", $$v);
      },
      expression: "getActionInfo.content.option_key"
    }
  }, _vm._l(_setup.activeFiedOption, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.value,
        value: item.key
      }
    });
  }), 1)], 1)], 1)] : _vm._e()], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;