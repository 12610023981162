"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    on: {
      mousemove: _vm.handleMousemove
    }
  }, [_c("el-table", {
    staticClass: "coach-table",
    attrs: {
      data: _vm.list,
      height: _vm.tableHeight
    },
    on: {
      "cell-click": _vm.handleCellClick
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "user",
      label: "成员",
      width: "165px",
      "show-overflow-tooltip": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("image-avatar", {
          attrs: {
            avatar: row.user.avatar,
            name: row.user.name
          }
        }), _vm._v(" " + _vm._s(row.user.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "last_coach_time",
      label: "最近一次接受辅导",
      "min-width": "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("span", [_vm._v(_vm._s(_vm.getLastCoachTime(row.last_coach_time)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      "min-width": "600px",
      prop: "coach_record",
      "class-name": "hover-blue"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("el-popover", {
          attrs: {
            placement: "top",
            width: "204",
            trigger: "hover"
          }
        }, [_c("table-head-tips"), _c("i", {
          staticClass: "iconfont icon-question2",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        })], 1), _vm._v(" 30天内接受辅导记录 ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("coach-activities", {
          class: [row.coach_record.count ? "clickable-column" : "not-clickable"],
          attrs: {
            record: row.coach_record
          },
          on: {
            tagClick: function ($event) {
              return _vm.handleTimelineTagClick($event, row.user);
            }
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "todo_coach_count",
      "min-width": "100px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("el-popover", {
          attrs: {
            placement: "top",
            width: "208",
            trigger: "hover"
          }
        }, [_c("table-head-tips", {
          attrs: {
            type: "todoCoach"
          }
        }), _c("i", {
          staticClass: "iconfont icon-question2",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        })], 1), _vm._v(" 待辅导 ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("div", {
          class: [row.undo_coach_to_me_count || row.undo_coach_to_other_count ? "clickable-column" : "not-clickable"]
        }, [row.undo_coach_to_me_count ? _c("text-tag", {
          attrs: {
            text: row.undo_coach_to_me_count,
            "bg-color": "yellow",
            shape: "round"
          }
        }) : _vm._e(), row.undo_coach_to_other_count ? _c("text-tag", {
          attrs: {
            text: row.undo_coach_to_other_count,
            "bg-color": "light-yellow",
            color: "yellow",
            shape: "round"
          }
        }) : _vm._e(), !row.undo_coach_to_me_count && !row.undo_coach_to_other_count ? _c("text-tag", {
          attrs: {
            text: "0",
            "bg-color": "gray",
            color: "dark",
            shape: "round"
          }
        }) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("coaching.operation"),
      "min-width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_c("el-button", {
          staticClass: "go-coach no-focus",
          attrs: {
            type: "primary",
            plain: "",
            icon: "iconfont icon-coaching"
          },
          on: {
            click: function ($event) {
              return _vm.goCoach(row);
            }
          }
        }, [_vm._v("去辅导")])];
      }
    }])
  })], 1), _c("transition", {
    attrs: {
      name: "date-popover"
    }
  }, [_vm.datePopoverVisible ? _c("date-popover", _vm._b({}, "date-popover", _vm.popoverProps, false), [_vm._v(_vm._s(_vm.popoverDate))]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;