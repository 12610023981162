"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$currentDataSet, _vm$currentDataSet$fo, _vm$currentDataSet2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: (_vm$currentDataSet = _vm.currentDataSet) !== null && _vm$currentDataSet !== void 0 && _vm$currentDataSet.id ? "编辑数据集" : "创建数据集",
      visible: _vm.visible,
      width: "600px",
      "before-close": _setup.handleClose,
      "append-to-body": "",
      "close-on-click-modal": false
    }
  }, [_c("el-form", {
    ref: "createDataSetFormRef",
    attrs: {
      model: _setup.form,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "数据集名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入",
      maxlength: "20",
      type: "text",
      "show-word-limit": ""
    },
    model: {
      value: _setup.form.name,
      callback: function ($$v) {
        _vm.$set(_setup.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文件夹",
      prop: "folder_id"
    }
  }, [_c(_setup.FolderSelect, {
    attrs: {
      "external-folder-id": (_vm$currentDataSet$fo = _vm.currentDataSet.folder) === null || _vm$currentDataSet$fo === void 0 ? void 0 : _vm$currentDataSet$fo.id
    },
    on: {
      folderChange: _setup.handleFolderChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "标注类型",
      prop: "annotation_type"
    }
  }, [_c("div", {
    staticClass: "annotation-type"
  }, [_vm._v("关键事件")])]), _c("el-form-item", {
    attrs: {
      label: "数据集描述",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入",
      type: "textarea",
      maxlength: "100",
      "show-word-limit": ""
    },
    model: {
      value: _setup.form.description,
      callback: function ($$v) {
        _vm.$set(_setup.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: _vm.currentDataSet.id ? "primary" : "",
      loading: _setup.loading
    },
    on: {
      click: _setup.handleSubmit
    }
  }, [_vm._v(_vm._s((_vm$currentDataSet2 = _vm.currentDataSet) !== null && _vm$currentDataSet2 !== void 0 && _vm$currentDataSet2.id ? "保存" : "仅创建"))]), !_vm.currentDataSet.id ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.handleSubmitAndImpartData
    }
  }, [_vm._v("创建并导入数据")]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;