"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
var _default = {
  name: 'UploadMedia',
  props: {
    limitCount: {
      type: Number,
      default: 5
    },
    sourceType: {
      type: String,
      default: 'conversation'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: [],
      cancel: '',
      axiosCancel: [],
      dataArr: [],
      disabledModifyMeetingName: false,
      acceptFileType: '.mp3, .wav, .m4a, .aiff, .aac, .ogg, .wma, .mp4, .m4v, .flac, .avi, .rmvb, .mkv, .asf, .wmv, .3gp, .flv, .mov, .amr'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['org_config'])
  },
  created() {
    this.$bus.$on('abortUpload', () => {
      this.handleRemove();
    });
    this.$once('hook:beforeDestroy', () => {
      this.$bus.$off('abortUpload');
    });
  },
  methods: {
    // 上传文件前的钩子函数，返回false或者Promise被reject时停止上传
    beforeUploadFile(file) {
      // avi 、wmv、mpeg、3gp、flv 格式当前不支持播放
      // 各格式文件的file.type如下
      // mp3  audio/mpeg
      // wav  audio/wav
      // m4a  audio/x-m4a
      // aiff  audio/aiff
      // aac  audio/vnd.dlna.adts
      // ogg  audio/ogg
      // wma  audio/x-ms-wma
      // amr  空
      // mp4  video/mp4
      // m4v  video/mp4
      // flac audio/flac
      // avi video/avi
      // rmvb 空
      // mkv video/x-matroska
      // asf video/x-ms-asf
      // wmv video/x-ms-wmv
      // 3gp audio/3gpp, video/3gpp
      // flv 空
      // mov video/quicktime
      const isTypeValid = this.validateType(file);
      const webStatus = navigator.onLine;
      const sizeLimit = this.validateSize(file);
      if (!isTypeValid) {
        this.$message.error('文件格式不符合，请确认文件格式符合要求');
      }
      if (!sizeLimit.isSizeValid) {
        this.$message.warning(sizeLimit.message);
      }
      if (!webStatus) {
        this.$message.error('网络异常');
      }
      return isTypeValid && sizeLimit.isSizeValid && webStatus;
    },
    // 上传文件
    uploadFile(item) {
      const file = item.file;
      const formData = new FormData();
      const callback = {
        onUploadProgress: ProgressEvent => {
          const progressPercent = ProgressEvent.loaded / ProgressEvent.total * 100;
          item.onProgress({
            percent: Math.round(progressPercent)
          });
        }
      };
      formData.append('file', file);
      console.log(formData, 'formData');
      (0, _conversationList.postMedia)(formData, callback, this).then(res => {
        if (res.data.code === 20000) {
          item.onSuccess(res.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning',
            customClass: 'upload-media-warning-message'
          });
          this.fileList = [];
        }
      }).catch(err => {
        // 上传失败时，中止上传，返回错误
        if (err.message === '中止上传') {
          item.onError(err);
          throw new Error(err.message);
        }
      });
    },
    beforeRemove(file) {
      if (!file.video_id) return;
      const res = this.$confirm(`确定移除 ${file.name}？`).then(res => {
        this.$nextTick(() => {
          this.handleRemove();
          this.showUploadDraggerDom();
        });
      }).catch(err => {
        throw new Error(err);
      });
      return res;
    },
    handleFileRemove(file, fileList) {
      var _this$axiosCancel$;
      (_this$axiosCancel$ = this.axiosCancel[0]) === null || _this$axiosCancel$ === void 0 ? void 0 : _this$axiosCancel$.cancel();
      this.fileList = fileList;
      this.$emit('select', this.fileList);
    },
    // 父组件 UploadMediaDialog调用
    handleRemove() {
      var _this$axiosCancel$2;
      (_this$axiosCancel$2 = this.axiosCancel[0]) === null || _this$axiosCancel$2 === void 0 ? void 0 : _this$axiosCancel$2.cancel('终止请求');
      this.axiosCancel = [];
    },
    onProgress(event, file, fileList) {
      this.fileList = fileList;
      this.$emit('select', this.fileList);
      this.disabledModifyMeetingName = false;
      this.$bus.$emit('disabledModifyMeetingName', this.disabledModifyMeetingName);
    },
    onSuccess(res, file, fileList) {
      this.fileList = fileList;
      this.fileList.forEach(item => {
        if (item.status === 'success') {
          item.text = item.response.msg;
          item.video_id = item.response.results.video_id;
        }
      });
      this.$emit('select', this.fileList);
      this.disabledModifyMeetingName = true;
      this.$bus.$emit('disabledModifyMeetingName', this.disabledModifyMeetingName);
      this.hideUploadDraggerDom();
    },
    hideUploadDraggerDom() {
      if (this.fileList.length === this.limitCount) {
        const oUploadMedia = document.querySelector('#uploadMedia');
        const oUploadMediaText = oUploadMedia.querySelector('.el-upload--text');
        oUploadMediaText.style.height = '0px';
        oUploadMediaText.style.opacity = 0;
      }
    },
    showUploadDraggerDom() {
      if (this.fileList.length < this.limitCount) {
        const oUploadMedia = document.querySelector('#uploadMedia');
        const oUploadMediaText = oUploadMedia.querySelector('.el-upload--text');
        oUploadMediaText.style.height = '200px';
        oUploadMediaText.style.opacity = 1;
      }
    },
    onError(err, file, fileList) {
      if (err) {
        throw new Error(err);
      }
      this.fileList.forEach(item => {
        if (item.uid === file.uid) {
          item.text = '上传失败!';
        }
      });
      this.$emit('select', this.fileList);
    },
    // 文件上传超出限制处理
    onExceed(file, fileList) {
      this.$message.warning(`单次只能上传${this.limitCount}个文件`);
    },
    clearData() {
      this.$refs.upload.clearFiles();
    },
    // 单个音频文件最大500MB,单个视频文件最大4GB
    validateSize(file) {
      var _this$org_config, _file$type;
      const audioMaxSize = ((_this$org_config = this.org_config) === null || _this$org_config === void 0 ? void 0 : _this$org_config.audio_upload_limit) / Math.pow(1024, 2) || 500;
      if (((_file$type = file.type) === null || _file$type === void 0 ? void 0 : _file$type.split('/')[0]) === 'audio') {
        return {
          isSizeValid: file.size / Math.pow(1024, 2) < audioMaxSize,
          message: `音频文件大小不能超过${audioMaxSize}MB`
        };
      }
      return {
        isSizeValid: file.size / Math.pow(1024, 3) < 4,
        message: '视频文件大小不能超过4G'
      };
    },
    validateType(file) {
      const isTypeValid = /(audio|video)\/(mpeg|wav|x-wav|x-m4a|aiff|vnd.dlna.adts|aac|x-aac|ogg|x-ms-wma|mp4|flac|avi|x-matroska|x-ms-asf|x-ms-wmv|3gpp|quicktime)/.test(file.type);
      const isSpecialTypeValid = this.validateSpecialType(file.name);
      return isTypeValid || isSpecialTypeValid;
    },
    // 对file.type为空的文件类型，进行特殊判断
    validateSpecialType(name) {
      const suffix = name.slice(name.lastIndexOf('.') + 1);
      return ['rmvb', 'flv', 'amr'].includes(suffix);
    }
  }
};
exports.default = _default;