"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import Sortable from 'sortablejs';
var _default = {
  __name: 'InstructionTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['editInstruction', 'deleteInstruction'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const headers = [
    // {
    //   prop: 'drag',
    //   label: '',
    //   minWidth: '30px'
    // },
    // {
    //   prop: 'order',
    //   label: '序号',
    //   minWidth: '80px'
    // },
    {
      prop: 'name',
      label: '名称',
      minWidth: '300px'
    }, {
      prop: 'content',
      label: '指令内容',
      minWidth: 400
    }, {
      prop: 'departments',
      label: '应用范围',
      minWidth: '330px'
    }, {
      prop: 'user_info',
      label: '创建人',
      minWidth: '120px'
    }, {
      prop: 'create_at',
      label: '创建时间',
      minWidth: '160px'
    }];
    const getContentString = (0, _vue.computed)(() => {
      return (content, isBr) => {
        let result = '';
        content.forEach(item => {
          var _item$enum;
          result += `${item.key} ${item.value ? '：' + item.value : ''}；${(_item$enum = item.enum) !== null && _item$enum !== void 0 && _item$enum.length ? '【' + item.enum.join('、') + '】;' : ''}${isBr ? '\n' : ''} `;
        });
        // 移除结果字符串最后的换行符\n、分号和空格
        result = result.replace(/[\n; ]+$/, '');
        return result;
      };
    });
    const openEditInstructionDialog = row => {
      emit('editInstruction', row);
    };
    const deleteInstruction = (row, index) => {
      emit('deleteInstruction', row, index);
    };

    // const tableRef = ref(null);
    // onMounted(() => {
    //   initDragSort();
    // });

    // const initDragSort = () => {
    //   const el = tableRef.value?.$el.querySelector(
    //     '.el-table__body-wrapper > table > tbody'
    //   );
    //   new Sortable(el, {
    //     animation: 150, // 动画
    //     handle: '.move',
    //     onEnd: (evt) => {
    //       emit('rowDragSort', evt);
    //     }
    //   });
    // };

    return {
      __sfc: true,
      props,
      headers,
      emit,
      getContentString,
      openEditInstructionDialog,
      deleteInstruction,
      MgvButton: _index.default,
      VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default
    };
  }
};
exports.default = _default;