"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "comment-unit"
  }, [_c("comment-box", {
    ref: "commentBox" + _vm.unit.comment_id,
    attrs: {
      comment: _vm.unit,
      "is-only-display": _vm.isOnlyDisplay,
      "has-timestamp": _vm.hasTimestamp,
      "entity-type": _vm.entityType
    },
    on: {
      reply: _vm.replyComment,
      delComment: function ($event) {
        return _vm.$emit("delComment", $event);
      },
      onCommentEditSuccess: function ($event) {
        return _vm.$emit("onCommentEditSuccess", $event);
      }
    }
  }), _vm.unit.replies.length ? _c("div", {
    ref: "reply-container",
    staticClass: "reply-container"
  }, _vm._l(_vm.unit.replies, function (reply, index) {
    return _c("comment-box", {
      key: index,
      ref: "commentBox" + reply.comment_id,
      refInFor: true,
      attrs: {
        index: index,
        "is-reply": "",
        comment: reply,
        "entity-type": _vm.entityType,
        "is-only-display": _vm.isOnlyDisplay,
        "has-timestamp": _vm.hasTimestamp
      },
      on: {
        reply: _vm.replyComment,
        changeEdit: _vm.replyChangeEdit,
        delComment: function ($event) {
          return _vm.$emit("delComment", $event);
        },
        onCommentEditSuccess: function ($event) {
          return _vm.$emit("onCommentEditSuccess", $event);
        }
      }
    });
  }), 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;