"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-recommend"
  }, [_c("div", {
    staticClass: "recommend-title"
  }, [_c("div", {
    staticClass: "title-left"
  }, [_c("span", {
    staticClass: "title-text"
  }, [_vm._v("待办推荐")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "为保证您的推荐最新且相关，旧的推荐会在7天后自动删除"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "title-right"
  }, [_c("space-button")], 1)]), _c("div", {
    staticClass: "recommend-filter"
  }, [_c("div", {
    staticClass: "filter-left"
  }, [_c("filter-tab", {
    ref: "filterTab",
    attrs: {
      list: _vm.eventList
    },
    on: {
      tabChange: _vm.filterTabClick
    }
  })], 1), _vm.hasTeamPermisson ? _c("div", {
    staticClass: "filter-right"
  }, [_c("el-select", {
    attrs: {
      "popper-class": "assist-filter-view-select",
      "popper-append-to-body": false
    },
    model: {
      value: _vm.viewRangeType,
      callback: function ($$v) {
        _vm.viewRangeType = $$v;
      },
      expression: "viewRangeType"
    }
  }, _vm._l(_vm.viewRangeOptions, function (_ref) {
    let {
      value,
      label
    } = _ref;
    return _c("el-option", {
      key: value,
      attrs: {
        label: label,
        value: value
      }
    });
  }), 1)], 1) : _vm._e()]), _vm.hasTeamPermisson ? _c("div", {
    staticClass: "select-members",
    class: _vm.viewRangeType === "team" ? "show-select-member" : ""
  }, [_vm.viewRangeType === "team" ? _c("members-finder", {
    ref: "selectMembers",
    attrs: {
      placeholder: "搜索",
      "is-set-checked-root": "",
      "has-self": false,
      "get-member-api-type": "data",
      params: {
        type: JSON.stringify(["assist"]),
        workspace_id: _vm.workspaceInfo.id
      },
      "tree-config": _vm.treeConfig
    },
    on: {
      change: _vm.handleMembersChange
    }
  }) : _vm._e()], 1) : _vm._e(), _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.recommendListLoading && !_vm.recommendList.length,
      expression: "recommendListLoading && !recommendList.length"
    }],
    staticClass: "recommend-list"
  }, [!_vm.recommendListLoading && !_vm.recommendList.length ? _c("NoData", {
    attrs: {
      size: "120",
      icon: "icon-a-Property1no-todo",
      tips: "暂无可推荐的待办"
    }
  }) : _c("mg-infinite-scroll", {
    staticClass: "message",
    attrs: {
      disabled: _vm.disabled,
      loading: _vm.recommendListLoading && !!_vm.recommendList.length,
      nomore: _vm.listPagingNomore
    },
    on: {
      loadMore: _vm.getRecommendlist
    }
  }, [_vm.recommendList && _vm.recommendList.length ? [_c("transition-group", {
    attrs: {
      name: "list",
      tag: "div"
    }
  }, _vm._l(_vm.recommendList, function (item) {
    return _c("account-recommend-item", {
      key: item.id,
      attrs: {
        item: item,
        "event-list": _vm.eventList,
        "active-item-id": _vm.activeItemId
      },
      on: {
        changeActiveDialogId: function ($event) {
          return _vm.changeActiveDialogId($event);
        }
      }
    });
  }), 1)] : _vm._e()], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;