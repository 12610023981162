"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _UserScaleDialog = _interopRequireDefault(require("@/views/management/components/UserScaleDialog"));
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountManagement',
  components: {
    UserScaleDialog: _UserScaleDialog.default
  },
  data() {
    return {
      userScaleDialogVisible: false,
      usageData: {}
    };
  },
  created() {
    this.usage();
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName']),
    limitCount() {
      var _this$usageData, _this$usageData2;
      return ((_this$usageData = this.usageData) === null || _this$usageData === void 0 ? void 0 : _this$usageData.license_upper_limit) === -1 ? '--' : (_this$usageData2 = this.usageData) === null || _this$usageData2 === void 0 ? void 0 : _this$usageData2.license_upper_limit;
    },
    activeCount() {
      var _this$usageData3;
      return (_this$usageData3 = this.usageData) === null || _this$usageData3 === void 0 ? void 0 : _this$usageData3.license_used;
    }
  },
  methods: {
    async usage() {
      const res = await (0, _organization.usage)();
      if (res.code === 20000) {
        this.usageData = res.results;
      }
    },
    openUserScaleDialog() {
      this.userScaleDialogVisible = true;
    },
    closeUserScaleDialog() {
      this.userScaleDialogVisible = false;
    }
  }
};
exports.default = _default;