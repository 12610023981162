"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _PrecisionDetailOverview = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/PrecisionDetailOverview.vue"));
var _HistoryTable = _interopRequireDefault(require("./HistoryTable"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ModelDetailDrawer',
  components: {
    DynamicForm: _DynamicForm.default,
    PrecisionDetailOverview: _PrecisionDetailOverview.default,
    HistoryTable: _HistoryTable.default
  },
  provide: function () {
    return {
      modelInfo: this.modelVersionInfo
    };
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '测试指标详情'
    }
  },
  data() {
    return {
      activeName: 'modelDetails',
      tabs: [{
        label: '模型详情',
        name: 'modelDetails'
      }, {
        label: '版本对比',
        name: 'versionComparison'
      }, {
        label: '训练详情',
        name: 'trainingDetails'
      }, {
        label: '测试详情',
        name: 'testingDetails'
      }, {
        label: '验证详情',
        name: 'validationDetails'
      }, {
        label: '历史',
        name: 'history'
      }],
      modelDetailsdesc: [{
        id: 'model_type',
        label: '模型类型',
        type: 'radio',
        options: [{
          label: 'AI模型',
          value: 1
        }, {
          label: '规则模型',
          value: 2
        }],
        customClass: 'model-type-tag'
      }, {
        id: 'model_useto',
        label: '模型用途',
        type: 'radio',
        options: [{
          label: '关键事件',
          value: 1
        }, {
          label: '客户标签',
          value: 2
        }]
      }, {
        id: 'owner',
        label: '归属人'
      }, {
        id: 'create_time',
        label: '创建时间',
        type: 'date'
      }, {
        id: 'online_version',
        label: '上线版本',
        type: 'text'
      }],
      modelDetailFormData: {
        model_type: 1,
        model_useto: 1,
        owner: {
          avatar: 'https://sale-test.oss-cn-zhangjiakou.aliyuncs.com/avatar/ee98701f464261b4cd7c893fb31050a4eab086b81.jpg?x-oss-process=style/avatar',
          name: '张三'
        },
        create_time: '2023-09-20 14:50',
        online_version: 'V4'
      },
      historyTableLoading: false,
      historyTableData: [{
        operate_time: '2023-09-23 20:00:00',
        operate_user: {
          avatar: 'https://sale-test.oss-cn-zhangjiakou.aliyuncs.com/avatar/ee98701f464261b4cd7c893fb31050a4eab086b81.jpg?x-oss-process=style/avatar',
          name: '张三'
        },
        operate_type: '新增',
        operate_version: 'V4',
        add_info: '新增了1个商品'
      }],
      modelVersionInfo: {
        id: 2262,
        version: 3,
        status: 1,
        type: 1,
        step: 2,
        name: '✏️ 测试123',
        isNew: true,
        folderId: '',
        folderName: ''
      }
    };
  },
  methods: {
    isEmpty: _isEmpty.default,
    handleClose() {
      this.$emit('update:drawerVisible', false);
    },
    refreshPrecisionData() {
      this.$emit('refreshPrecisionData');
    }
  }
};
exports.default = _default;