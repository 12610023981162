"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 培训模块
const state = {
  knowledgeDeleted: null,
  coachingTaskSpaceId: null,
  // 创建/编辑培训任务时选择/回填的空间id
  // checkedKnowledgeScript: new Map() // 创建课程的已选知识点话术
  checkedKnowledgeScript: [],
  // 创建课程的已选知识点话术
  knowledgeBaseInfo: {},
  // 当前选中知识库信息
  taskCategoryList: []
};
const mutations = {
  SET_KNOWLEDGE_DELETED: (state, val) => {
    state.knowledgeDeleted = val;
  },
  SET_COACHING_TASK_SPACE_ID: (state, val) => {
    state.coachingTaskSpaceId = val;
  },
  SET_KNOWLEDGE_SCRIPT_ADD: (state, val) => {
    state.checkedKnowledgeScript.push(val);
  },
  SET_KNOWLEDGE_SCRIPT_DELETE: (state, id) => {
    state.checkedKnowledgeScript = state.checkedKnowledgeScript.filter(item => item.knowledge_id !== id);
  },
  SET_KNOWLEDGE_SCRIPT: (state, val) => {
    state.checkedKnowledgeScript = val;
  },
  SET_KNOWLEDGE_BASE_INFO: (state, val) => {
    state.knowledgeBaseInfo = val;
  },
  SET_TASK_CATEGORY_LIST: (state, val) => {
    state.taskCategoryList = val;
  }
};
var _default = {
  namespaced: true,
  state,
  mutations
};
exports.default = _default;