"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useRouter = require("@/hooks/use-router");
var _default = {
  __name: 'ImportHeader',
  props: {
    pageTitle: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const goBack = () => {
      router.go(-1);
    };
    return {
      __sfc: true,
      props,
      router,
      goBack
    };
  }
};
exports.default = _default;