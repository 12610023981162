"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("bip-layout", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.chartDesc,
      "person-name": _vm.personName
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function (_ref) {
        let {
          attr,
          value
        } = _ref;
        return [_c("statistics-card", {
          attrs: {
            active: _vm.active === attr,
            label: value.title,
            "value-arr": _vm.getCardArr(value.cardValue, attr)
          },
          nativeOn: {
            click: function ($event) {
              _vm.active = attr;
            }
          }
        })];
      }
    }, {
      key: "chart",
      fn: function (_ref2) {
        let {
          attr,
          value
        } = _ref2;
        return [_c("person-bar-chart", _vm._b({
          attrs: {
            "is-count": attr === "sum_count",
            "data-type": _vm.getDataType(attr),
            "x-axis-list": _vm.xAxisList
          }
        }, "person-bar-chart", value, false))];
      }
    }, {
      key: "aside",
      fn: function () {
        return [_c("aside-list", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: _vm.convLoading,
            expression: "convLoading"
          }],
          attrs: {
            list: _vm.conversationList,
            "data-type": "time"
          }
        }, [_c("sort-caret", {
          attrs: {
            "default-status": "descending",
            label: "排序"
          },
          on: {
            sortCallBack: _vm.handleSortChange
          }
        })], 1)];
      },
      proxy: true
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;