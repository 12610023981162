"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FolderCard = _interopRequireDefault(require("./FolderCard"));
var _ConvFlowCard = _interopRequireDefault(require("./ConvFlowCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    FolderCard: _FolderCard.default,
    ConvFlowCard: _ConvFlowCard.default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    convFlowList: {
      type: Array,
      default: () => []
    },
    foldTotal: {
      type: Number,
      default: 0
    },
    convFlowTotal: {
      type: Number,
      default: 0
    }
  }
};
exports.default = _default;