"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearchContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeSearchContent"));
var _KnowledgeMultipleTree = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeMultipleTree"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _LearnContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/LearnContent.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AddScriptDialog',
  components: {
    NoData: _NoData.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default,
    KnowledgeMultipleTree: _KnowledgeMultipleTree.default,
    KnowledgeSearchContent: _KnowledgeSearchContent.default,
    LearnContent: _LearnContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript'])
  },
  watch: {
    visible(val) {
      val && this.$nextTick(() => {
        this.$refs['searchInput'].focus();
      });
    }
  },
  methods: {
    submit() {
      if (!this.checkedKnowledgeScript.length) {
        this.$message(this.$t('coaching.addScriptTip'));
        return;
      }
      const directedGraph = this.$parent;
      const scriptList = [];
      // 知识点数据格式化为卡片需要的数据结构
      this.$refs.learnContent.localCheckedKnowledgeScript.forEach((item, index) => {
        console.log('item', item);
        scriptList.push([2 * index + 1, this.$t('coaching.customerSays'), item.knowledge_name], [2 * index + 2, this.$t('coaching.salespersonSays'), item.scene_reply_list.map(_ref => {
          let {
            reply_list
          } = _ref;
          return reply_list.map(_ref2 => {
            let {
              reply_content
            } = _ref2;
            return reply_content;
          }).join('');
        }).join('')]);
      });
      directedGraph.renderSentenceListOnGraph(scriptList);
      this.handleClose();
    },
    scrollToScriptEnd() {
      this.$nextTick(() => {
        this.$refs['script-container'].scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
      });
    },
    handleClose() {
      this.$store.commit('training/SET_KNOWLEDGE_SCRIPT', []);
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;