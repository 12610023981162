"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "pic-display"
  }, _vm._l(_vm.picList, function (pic, index) {
    return _c("div", {
      key: pic.id,
      staticClass: "image-container"
    }, [_c("el-image", {
      ref: "picRef",
      refInFor: true,
      attrs: {
        fit: "cover",
        src: pic,
        "preview-src-list": _vm.picList,
        "initial-index": index,
        "append-to-body": _vm.appendToBody
      }
    }), !_vm.readonly ? _c("i", {
      staticClass: "close-image iconfont icon-close-small",
      on: {
        click: function ($event) {
          return _setup.deletePic(index);
        }
      }
    }) : _vm._e()], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;