"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("table", {
    staticClass: "custom-assign-table"
  }, [_vm._m(0), _c("tbody", _vm._l(_setup.localMemberList, function (item, index) {
    return _c("tr", {
      key: item.user_id
    }, [_c("td", [_vm._v(_vm._s(index + 1))]), _c("td", [_c("mgv-avatar-name", {
      attrs: {
        src: item.avatar,
        name: item.name,
        size: 28
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])], 1), _c("td", {
      staticClass: "col-ratio"
    }, [_c("el-input", {
      class: {
        "error-ratio": _setup.errorRatio(item.ratio)
      },
      attrs: {
        oninput: "value=value.replace(/[^\\d]/g,'')",
        maxlength: "3"
      },
      on: {
        input: function ($event) {
          return _setup.changeRatio(item);
        }
      },
      model: {
        value: item.ratio,
        callback: function ($$v) {
          _vm.$set(item, "ratio", $$v);
        },
        expression: "item.ratio"
      }
    }, [_c("template", {
      slot: "suffix"
    }, [_vm._v("%")])], 2)], 1)]);
  }), 0), _c("tr", {
    staticClass: "table-footer"
  }, [_c("td", [_vm._v("合计")]), _c("td", [_vm._v(_vm._s(_setup.localMemberList.length) + " 人")]), _c("td", {
    class: {
      "total-ratio": _setup.totalRatio > 100
    }
  }, [_vm._v(" " + _vm._s(_setup.totalRatio + "%") + " ")])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("tr", {
    staticClass: "table-header"
  }, [_c("th", [_vm._v("序号")]), _c("th", [_vm._v("质检员")]), _c("th", [_vm._v("质检成员分配")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;