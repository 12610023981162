"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatInput = _interopRequireDefault(require("@/components/ChatUnit/components/ChatInput.vue"));
var _recorderCore = _interopRequireDefault(require("recorder-core"));
var _SentenceItem = _interopRequireDefault(require("./components/SentenceItem"));
require("recorder-core/src/engine/mp3");
require("recorder-core/src/engine/mp3-engine");
require("recorder-core/src/extensions/lib.fft");
require("recorder-core/src/extensions/frequency.histogram.view");
var _timeFormatter = require("@/utils/time-formatter");
var _vuex = require("vuex");
require("@/utils/speechrecognizer");
var _PracticeFinish = _interopRequireDefault(require("./components/PracticeFinish"));
var _PracticeFinishAiCustomer = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/PracticeFinishAiCustomer.vue"));
var _routeMap = require("@/router/routeMap");
var _privateWebsocket = _interopRequireDefault(require("@/utils/privateWebsocket"));
var _LeftPracticeProcessTips = _interopRequireDefault(require("./components/LeftPracticeProcessTips.vue"));
var _PracticeProcessScriptHint = _interopRequireDefault(require("./components/PracticeProcessScriptHint.vue"));
var _HintContentIntelligentPracticeProcess = _interopRequireDefault(require("./components/HintContentIntelligentPracticeProcess.vue"));
var _HintContentAiCustomerProcess = _interopRequireDefault(require("./components/HintContentAiCustomerProcess"));
var _CompletePracticeButton = _interopRequireDefault(require("./components/CompletePracticeButton.vue"));
var _BackToTaskDialog = _interopRequireDefault(require("./components/BackToTaskDialog.vue"));
var _DialogueItemNarration = _interopRequireDefault(require("../coach/coachTask/components/DialogueItemNarration.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeSmartTraining',
  components: {
    PracticeFinish: _PracticeFinish.default,
    ChatInput: _ChatInput.default,
    PracticeFinishAiCustomer: _PracticeFinishAiCustomer.default,
    SentenceItem: _SentenceItem.default,
    LeftPracticeProcessTips: _LeftPracticeProcessTips.default,
    PracticeProcessScriptHint: _PracticeProcessScriptHint.default,
    HintContentIntelligentPracticeProcess: _HintContentIntelligentPracticeProcess.default,
    HintContentAiCustomerProcess: _HintContentAiCustomerProcess.default,
    CompletePracticeButton: _CompletePracticeButton.default,
    BackToTaskDialog: _BackToTaskDialog.default,
    DialogueItemNarration: _DialogueItemNarration.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  beforeRouteEnter(to, from, next) {
    if (!sessionStorage.getItem('task_practice_query')) {
      next(vm => {
        const _path = _routeMap.ROUTES.coachingCenterSales;
        const path = vm.isEmbed ? vm.getEmbedPath(_path) : _path;
        next({
          path
        });
      });
      return;
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isFinished && !this.conversationComplate) {
      this.tempStore();
      // this.$confirm(
      //   this.$t('coaching.quitHalfwayWarning'),
      //   this.$t(
      //     this.isAiCustomer
      //       ? 'coaching.leaveAICustomer'
      //       : this.$t('coaching.leavingTheScriptForPractice')
      //   ),
      //   {
      //     confirmButtonText: this.$t('coaching.leave'),
      //     cancelButtonText: this.$t('coaching.cancel'),
      //     type: 'warning'
      //   }
      // ).then(() => {
      _recorderCore.default.IsOpen() && _recorderCore.default.Destroy();
      this.closeWebsocket();
      next();
      // });
    } else {
      next();
    }
  },
  data() {
    return {
      taskConfig: {
        avatar_url: ''
      },
      title: this.$t('coaching.scriptPractice'),
      isFinished: false,
      conversationComplate: false,
      overallScore: '',
      practiceStatus: 'connecting',
      sentenceList: [],
      mediaRecorder: null,
      duration: '00:00:00',
      customerSayLoading: false,
      practiceDuration: '00:00:00',
      salesTalkUuid: 0,
      connectionId: '',
      timeId: null,
      courseName: '',
      standardReply: [],
      webSpeechRecognizer: null,
      isRecognizerConnecting: false,
      isCloseRecognizer: false,
      recognizerStatus: 0,
      recognizerStatusMap: {
        0: '',
        1: 'recognizer-in',
        2: 'recognizer-out',
        3: 'recognizer-finish'
      },
      isRecognizerContent: false,
      recognizerNoneText: this.$t('coaching.voiceNotRecognized'),
      recognizerTextDefault: this.$t('coaching.pleaseStartSpeaking'),
      recognizerText: '',
      frequencyHistogramView: null,
      authUrl: '',
      currentSentenceUuid: 0,
      audioFiles: new Map(),
      isCanSendData: 0,
      // 自动播放语音
      playAudioLock: false,
      currentPlayingAudioId: '',
      audioPlayer: new Audio(),
      practiceRule: {},
      tourName: '',
      // AI客户(Alpha)
      websocket: null,
      currentConverId: '',
      aiCoustomerResult: {},
      // AI客户
      knowledgeId: 0,
      sceneId: 0,
      recordDisabled: false,
      noMoreQuestion: false,
      // ai客户-关键问题全部提问完成
      sceneList: [],
      // ai客户场景列表 ai客户 tpye-6,
      scriptList: [],
      // ai客户话术列表 ai客户 type-7
      practiceDescription: '',
      // 练习说明
      practiceScoreMetrics: [],
      // 练习评分指标
      conversationComplateDisabled: false,
      // 是否可以点击完成：所有的评分都出来了
      // 对练过程提示
      aiCustomerProcessHints: [],
      singletonExitConfirm: null,
      backToTaskDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'avatar', 'name', 'webSocketMsg', 'orgName']),
    tourSteps() {
      //  练习模式 0:语音模式 1: 文字模式
      const target = this.practiceRule.practice_mode ? '[data-v-step="practiceChatInput"]' : '[data-v-step="trainingRecord"]';
      return [{
        target,
        placement: 'top'
      }];
    },
    tourContent() {
      if (this.practiceRule.practice_mode) {
        const currentLanguage = localStorage.getItem('lang') || 'zh';
        return {
          text: this.$t('coaching.conversationWithCustomersByEnteringText'),
          img: require(`@/assets/coach/smartTrainingCreateGuide/smartTrainingPractice/${currentLanguage}.jpg`)
        };
      } else {
        return {
          text: this.$t('coaching.ByUploadingRecording'),
          img: require('@/assets/coach/smartTrainingRecordGuide.jpg')
        };
      }
    },
    isAiCustomer() {
      return [4, 5, 6, 7].includes(this.taskConfig.type);
    },
    isShowPracticeProcessScriptHint() {
      var _this$standardReply, _this$aiCustomerProce;
      return ((_this$standardReply = this.standardReply) === null || _this$standardReply === void 0 ? void 0 : _this$standardReply.length) || ((_this$aiCustomerProce = this.aiCustomerProcessHints) === null || _this$aiCustomerProce === void 0 ? void 0 : _this$aiCustomerProce.length);
    },
    isShowLeftPracticeProcessTips() {
      var _this$practiceScoreMe;
      return this.isAiCustomer && (this.practiceDescription || ((_this$practiceScoreMe = this.practiceScoreMetrics) === null || _this$practiceScoreMe === void 0 ? void 0 : _this$practiceScoreMe.length));
    }
  },
  watch: {
    webSocketMsg: {
      handler: function (newVal) {
        if (this.isAiCustomer && newVal !== null && newVal !== void 0 && newVal.data) {
          newVal.data.child_id = new Date().getTime();
          switch (newVal.type) {
            case 'plan_start':
              // 连接开始
              this.handlePlanStart(newVal);
              break;
            case 'connection_failed':
              // 连接失败(存在进行中的连接)
              this.handleConnectionFailed();
              break;
            case 'plan_chat_restore_success':
              // 会话连接时都会有这样一个消息，用于恢复会话
              // this.handleChatRestoreSuccess();
              // 拿到后端给的时间，开始计时

              this.practiceTime = newVal.data.duration ? newVal.data.duration * 1000 : 0;
              this.startTiming();
              if (!newVal.data.content.length) {
                // if (this.taskConfig.type === 4) {
                //   this.uploadRecord(
                //     this.$t('coaching.selfIntroduction', [this.orgName])
                //   );
                // }
              } else {
                // 把历史消息加入到列表中
                const narrator = this.sentenceList.filter(item => item.type === 'narrator');
                const filterNarrator = newVal.data.content.filter(item => item.type !== 'narrator');
                this.sentenceList = [...narrator, ...filterNarrator];
                this.scrollToBottom();
              }
              break;
            case 'plan_model_empty':
              // 对练会话模型不存在
              this.handleTaskError(this.$t('coaching.sessionModelDoesNotExist'));
              break;
            case 'plan_task_not_exist':
              // 对练任务不存在
              this.handleTaskError(this.$t('coaching.taskHasBeenDeleted'));
              break;
            case 'plan_chat_create_success':
              // 会话创建成功
              // 存储当前会话id
              // this.currentConverId = newVal.data.content;
              // const params = {
              //   ...this.$route.query,
              //   conversation_id: this.currentConverId
              // };
              // const qString = new URLSearchParams(params).toString();
              // history.replaceState(
              //   null,
              //   '',
              //   `${location.pathname}?${qString}${location.hash}`
              // );
              // this.websocket &&
              //   this.websocket.handlerPath({
              //     plan_id: this.taskConfig.taskId,
              //     conv_id: this.currentConverId,
              //     connection_id: this.connectionId
              //   });

              // // 主动给服务端发一条默认话术
              // if (this.taskConfig.type === 4) {
              //   this.uploadRecord(
              //     this.$t('coaching.selfIntroduction', [this.orgName])
              //   );
              // }
              break;
            case 'plan_chat_create_failed':
              // 会话创建失败
              this.$message.error(this.$t('coaching.sessionCreationFailed'));
              this.closeWebsocket();
              break;
            // case 'plan_chat_restore_success': // 会话恢复成功
            //   this.handleChatRestoreSuccess();
            //   break;
            case 'plan_chat_restore_failed':
              // 会话恢复失败
              this.$message.error(this.$t('coaching.sessionRecoveryFailed'));
              this.closeWebsocket();
              break;
            case 'plan_message':
              // 客户话术消息
              // 不处理旁白，旁白已变更为练习说明在页面左侧
              if (newVal.data.type === 'sales') {
                this.sentenceList.push(newVal.data, {
                  type: 'customer',
                  loading: true
                });
              } else if (newVal.data.type === 'customer') {
                this.handleCustomerSentence(newVal.data);
              }
              break;
            case 'plan_tts_result':
              this.handleTtsResult(newVal.data);
              break;
            case 'plan_revise_sales_saying':
              // 后端返回ASR修正后的销售说
              this.updateSalesSentence({
                ...newVal.data,
                loading: false
              });
              break;
            case 'plan_knowledge_comment':
              // 销售话术ai点评
              this.updateSentenceContent({
                ...newVal.data.content
                // score_loading: false
              });

              break;
            case 'plan_comment':
              // alpha- 最终评分和点评和能力分析消息
              this.aiCoustomerResult = newVal.data.content;
              this.closeWebsocket();
              break;
            case 'plan_send_scene_info':
              // ai客户-场景列表返回消息
              this.sceneList = newVal.data.content;
              break;
            case 'plan_keypoints':
              // ai客户-场景列表返回消息
              this.scriptList = newVal.data.content.keypoints;
              break;
            case 'plan_scene_score_end':
              // ai客户-场景评分和场景话术信息
              this.updateAiSceneScore(newVal.data.content);
              break;
            case 'plan_keypoint_score':
              // ai客户-场景评分和场景话术信息
              this.updateAiScriptList(newVal.data.content.keypoints);
              break;
            case 'plan_score_comment':
              // ai客户-最终评分和点评返回消息
              if (this.taskConfig.type === 7) {
                this.closeWebsocket();
              }
              break;
            case 'plan_close':
              // 后端关闭连接消息
              this.closeWebsocket();
              break;
            case 'plan_error':
              // 后端报错
              this.closeWebsocket();
              break;
            case 'plan_question_empty':
              // ai客户-关键问题全部提问完成
              this.handleNoMoreQuestion();
              break;
            case 'plan_dialog_finished':
              // ai客户-对话记录
              break;
            default:
              break;
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    window.onbeforeunload = this.beforeunloadHandler;
    document.addEventListener('visibilitychange', this.activePageWebsocketCheck);
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    clearInterval(this.timeId);
    window.removeEventListener('beforeunload', this.pauseAudio());
    document.removeEventListener('visibilitychange', this.activePageWebsocketCheck);
    this.closeWebsocket();
    this.sentenceList.forEach(item => {
      item.timer && clearInterval(item.timer);
    });
  },
  async created() {
    console.log('????------???----');
    this.handleDocumentTitle();
    this.taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query'));
    this.taskConfig.taskId = Number(this.taskConfig.taskId);
    this.taskConfig.type = Number(this.taskConfig.type);
    this.taskConfig.userInfo = this.taskConfig.userInfo ? JSON.parse(this.taskConfig.userInfo) : {
      id: this.userId
    };
    if (!this.$route.query.connection_id) {
      const connectionId = (0, _nanoid.nanoid)();
      this.replaceHistory({
        connection_id: connectionId
      });
      console.log(connectionId, location, this.$route);
    }
    const search = new URLSearchParams(location.search);
    this.connectionId = search.get('connection_id') || '';
    this.currentConverId = search.get('conv_id') || '';
    if (this.isAiCustomer) {
      this.practiceRule.practice_mode = this.taskConfig.practice_mode;
      this.title = this.taskConfig.planName;
      if (!this.taskConfig.avatar_url) {
        this.getCoachingTaskInfo();
      }
      this.initWebSocket();
      this.getPracticeProcessHint();
    } else {
      await this.getTaskScore();
      window.addEventListener('beforeunload', () => this.pauseAudio());
      setTimeout(() => {
        setTimeout(() => {
          var _this$$refs$smartTrai;
          !this.conversationComplate && ((_this$$refs$smartTrai = this.$refs['smartTrainingRecordGuide']) === null || _this$$refs$smartTrai === void 0 ? void 0 : _this$$refs$smartTrai.start());
        }, 1000);
        if (!this.conversationComplate) {
          this.startTiming();
        }
      }, 1000);
    }
  },
  methods: {
    async getCoachingTaskInfo() {
      const res = await (0, _coachingCenter.getCoachingTaskInfo)({
        id: this.taskConfig.taskId
      });
      if (res.code === 20000) {
        this.taskConfig.avatar_url = res.results.data.avatar_url;
        console.log('res', this.taskConfig);
      }
    },
    handleNoMoreQuestion() {
      this.noMoreQuestion = true;
      setTimeout(() => {
        this.sentenceList.push({
          content: this.$t('coaching.practiceIsCompleted'),
          type: 'end',
          child_id: this.currentSentenceUuid
        });
        this.scrollToBottom();
      }, 500);
    },
    // ai客户 - 场景评分和ai点评返回消息
    updateAiSceneScore(content) {
      this.sceneList.find(item => {
        if (item.id === content.scene_id) {
          this.$set(item, 'conv_comment', content.conv_comment);
          this.$set(item, 'score', content.score);
        }
      });
    },
    // AI客户 type = 7 AI对话术评分
    updateAiScriptList(scripts) {
      if (scripts.length === 1) {
        const firstEmptyItem = this.scriptList.find(item => !Object.hasOwnProperty.call(item, 'not_triggered'));
        if (firstEmptyItem) {
          this.scriptList.splice(this.scriptList.indexOf(firstEmptyItem), scripts.length, ...scripts);
        }
      } else if (scripts.length > 1) {
        this.scriptList = scripts;
      }
    },
    handleTaskError(tips) {
      this.currentConverId = '';
      this.closeWebsocket();
      this.$alert(tips, this.$t('coaching.note'), {
        confirmButtonText: this.$t('coaching.confirm'),
        callback: () => {
          const path = _routeMap.ROUTES.coachingCenterSales;
          this.$router.replace({
            path: this.isEmbed ? this.getEmbedPath(path) : path
          });
        }
      });
    },
    activePageWebsocketCheck() {
      if (!document.hidden) {
        var _this$websocket, _this$websocket$ws;
        const wsStatus = (_this$websocket = this.websocket) === null || _this$websocket === void 0 ? void 0 : (_this$websocket$ws = _this$websocket.ws) === null || _this$websocket$ws === void 0 ? void 0 : _this$websocket$ws.readyState;
        if (this.practiceStatus === 'started' && wsStatus === 3) {
          this.$alert(this.$t('coaching.connectionHasBeenDisconnected'), this.$t('coaching.note'), {
            confirmButtonText: this.$t('coaching.confirm'),
            callback: () => {
              this.closeWebsocket();
              this.initWebSocket();
              return;
            },
            showCancelButton: false
          });
        }
      }
    },
    initWebSocket() {
      const path = this.taskConfig.type === 7 ? '/webapi/training/plan/ai/customer/gamma' : '/webapi/training/plan/ai/customer/begin';
      this.websocket = new _privateWebsocket.default(path);
      this.websocket.init({
        plan_id: this.taskConfig.taskId,
        conv_id: this.currentConverId,
        connection_id: this.connectionId
      });
    },
    onCompletePractice() {
      if (!this.noMoreQuestion && this.taskConfig.type === 6) {
        this.$confirm(this.$t('coaching.endThePracticeEarlyWarning'), this.$t('coaching.endSessionEarly'), {
          confirmButtonText: this.$t('coaching.endConversation'),
          cancelButtonText: this.$t('coaching.continuePractice'),
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(() => {
          this.endConversation();
        }).catch(() => {});
      } else {
        this.$confirm(this.$t('coaching.确认提交本次练习？'), this.$t('coaching.完成练习'), {
          confirmButtonText: this.$t('coaching.确认提交'),
          cancelButtonText: this.$t('coaching.继续练习'),
          type: 'warning'
        }).then(() => {
          this.endConversation();
        });
      }
    },
    async onSaveAndLeave() {
      await this.tempStore();
      const path = _routeMap.ROUTES.coachingCenterSales;
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          id: this.taskConfig.taskId
        }
      });
    },
    async giveUpPractice(cb) {
      const res = await (0, _coachingCenter.giveUpPractice)({
        conv_id: this.currentConverId
      });
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.放弃练习成功'));
        this.isFinished = true;
        this.conversationComplate = true;
        const path = _routeMap.ROUTES.coachingCenterSales;
        this.$router.push({
          path: this.isEmbed ? this.getEmbedPath(path) : path,
          query: {
            id: this.taskConfig.taskId
          }
        });
      }
    },
    hangUp() {
      if (!this.noMoreQuestion && this.taskConfig.type === 6) {
        this.$confirm(this.$t('coaching.endThePracticeEarlyWarning'), this.$t('coaching.endSessionEarly'), {
          confirmButtonText: this.$t('coaching.endConversation'),
          cancelButtonText: this.$t('coaching.continuePractice'),
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(() => {
          this.endConversation();
        }).catch(() => {});
      } else {
        this.endConversation();
      }
    },
    endConversation() {
      var _this$websocket2, _this$websocket2$ws;
      if (((_this$websocket2 = this.websocket) === null || _this$websocket2 === void 0 ? void 0 : (_this$websocket2$ws = _this$websocket2.ws) === null || _this$websocket2$ws === void 0 ? void 0 : _this$websocket2$ws.readyState) === 1) {
        this.websocket.sendMessage({
          // 前端挂断
          type: 'plan_finished'
        });
        this.conversationComplate = true;
        this.isFinished = true;
        this.pauseAudio();
        this.closeRecording();
      }
    },
    closeWebsocket() {
      if (this.websocket) {
        this.websocket.close();
        // this.websocket = null;
      }
    },

    handlePlanStart(newVal) {
      this.currentConverId = newVal.data.content;
      this.replaceHistory({
        conv_id: this.currentConverId
      });
      this.websocket && this.websocket.handlerPath({
        plan_id: this.taskConfig.taskId,
        conv_id: this.currentConverId,
        connection_id: this.connectionId
      });
    },
    replaceHistory(params) {
      const search = new URLSearchParams(location.search);
      for (const key in params) {
        search.set(key, params[key]);
      }
      const qString = new URLSearchParams(search.toString()).toString();
      history.replaceState(null, '', `${location.pathname}?${qString}${location.hash}`);
    },
    startTiming() {
      clearInterval(this.timeId);
      this.practiceStatus = 'not-started';
      this.closeRecording();
      this.isCloseRecognizer = false;
      this.recognizerStatus = 3;
      this.timeId = setInterval(() => {
        this.practiceTime += 1000;
        this.practiceDuration = this.$moment.utc(this.practiceTime).format('HH:mm:ss');
      }, 1000);
    },
    async speechRecognizerInit() {
      const param = {
        // signCallback: this.signCallback(), // 鉴权函数
        // 用户参数
        secretid: 'AKIDPgonZ9Y3Wnup6Piw4C9SaUBf07ziHdlk',
        appid: '1304148053',
        // 实时识别接口参数
        engine_model_type: `16k_${this.taskConfig.language === 'english' ? 'en' : 'zh'}`,
        // 以下为非必填参数，可跟据业务自行修改
        voice_format: 1,
        hotword_id: '08003a00000000000000000000000000',
        needvad: 1,
        filter_dirty: 1,
        filter_modal: 2,
        filter_punc: 0,
        convert_num_mode: 1,
        word_info: 2
      };
      // eslint-disable-next-line no-undef
      const speechRecognizer = new SpeechRecognizer(param);
      const signStr = await speechRecognizer.getSdkSignStr();
      const dataRes = await (0, _coachingCenter.getTempToken)({
        url: signStr
      });
      this.authUrl = await speechRecognizer.getSdkSignature(signStr, dataRes.results.signature);
      return speechRecognizer;
    },
    recognizerStatusChange(status) {
      return this.recognizerStatusMap[status];
    },
    stopRecognize() {
      var _this$webSpeechRecogn;
      (_this$webSpeechRecogn = this.webSpeechRecognizer) === null || _this$webSpeechRecogn === void 0 ? void 0 : _this$webSpeechRecogn.stop();
      this.webSpeechRecognizer = null;
    },
    async startRecognize() {
      this.isRecognizerConnecting = true;
      this.isCloseRecognizer = false;
      let resultText = '';
      this.webSpeechRecognizer = await this.speechRecognizerInit();
      this.webSpeechRecognizer.start(this.authUrl);
      this.webSpeechRecognizer.OnRecognitionStart = res => {
        this.isCanSendData = 1;
        this.isRecognizerConnecting = false;
      };
      // 一句话开始
      this.webSpeechRecognizer.OnSentenceBegin = res => {};
      // 识别变化时
      this.webSpeechRecognizer.OnRecognitionResultChange = res => {
        const currentText = `${resultText}${res.result.voice_text_str}`;
        this.recognizerText = currentText;
        this.scrollToNew();
      };
      // 一句话结束
      this.webSpeechRecognizer.OnSentenceEnd = res => {
        resultText += res.result.voice_text_str;
        this.recognizerText = resultText;
        this.scrollToNew();
      };
      // 识别结束
      this.webSpeechRecognizer.OnRecognitionComplete = res => {
        this.uploadRecord(resultText);
        this.isCanSendData = 0;
      };
      // 识别错误
      this.webSpeechRecognizer.OnError = res => {
        this.isCanSendData = 0;
      };
    },
    playAudio(_ref) {
      let {
        child_id,
        trans_url
      } = _ref;
      if (this.playAudioLock) return;
      this.playAudioLock = true;
      setTimeout(() => {
        this.playAudioLock = false;
      }, 500);
      // 如果有正在录制的录音则禁止播放
      if (_recorderCore.default.IsOpen()) return;
      if (this.currentPlayingAudioId === child_id) {
        this.pauseAudio();
      } else {
        this.currentPlayingAudioId = child_id;
        this.audioPlayer.src = trans_url;
        this.audioPlayer.play();
        this.audioPlayer.onended = () => {
          this.currentPlayingAudioId = '';
          const index = this.sentenceList.findIndex(item => item.child_id === child_id);
          if (!this.practiceRule.practice_mode && index > -1 && index < this.sentenceList.length - 1) {
            this.autoPlayCustomerContent(this.sentenceList.slice(index + 1));
          }
        };
      }
    },
    pauseAudio() {
      var _this$audioPlayer;
      (_this$audioPlayer = this.audioPlayer) === null || _this$audioPlayer === void 0 ? void 0 : _this$audioPlayer.pause();
      this.currentPlayingAudioId = '';
    },
    startRecording() {
      this.pauseAudio();
      if (this.customerSayLoading) return;
      if (_recorderCore.default.IsOpen()) {
        this.$message.warning(this.isTest ? this.$t('coaching.conductingTestWarning') : this.$t('coaching.proceedingWithOtherRecordingsWarning'));
        this.recognizerStatus = 1;
        return;
      }
      this.currentSentenceUuid = (0, _nanoid.nanoid)();
      this.startRecognize();
      this.mediaRecorder = (0, _recorderCore.default)({
        type: 'mp3',
        sampleRate: 16000,
        bitRate: 16,
        onProcess: this.onProcess
      });
      this.mediaRecorder.open(() => {
        this.$nextTick(() => {
          this.frequencyHistogramView = _recorderCore.default.FrequencyHistogramView({
            elem: '#wave-view',
            lineCount: 5,
            // 柱子数量
            spaceWidth: 4,
            // 柱子间隔
            minHeight: 2,
            position: 0,
            fps: 30,
            stripeEnable: false,
            // 柱子顶部的峰值小条（被柱子顶着跳动的小块）
            mirrorEnable: true,
            // 左右镜像
            linear: [0, '#ffffff', 0.5, '#ffffff', 1, '#ffffff']
          });
          this.recognizerTextDefault = `“${this.$t('coaching.pleaseStartSpeaking')}”`;
        });
        this.mediaRecorder.start();
        this.practiceStatus = 'started';
        this.recognizerStatus = 1;
        this.recognizerText = '';
      }, () => {
        this.$message.warning(this.$t('coaching.pleaseOpenTheMicrophone'));
      });
    },
    onProcess(buffers, powerLevel, duration, sampleRate) {
      var _this$frequencyHistog;
      this.duration = (0, _timeFormatter.secondToTime)(parseInt(duration / 1000));
      if (!buffers.length) return;
      (_this$frequencyHistog = this.frequencyHistogramView) === null || _this$frequencyHistog === void 0 ? void 0 : _this$frequencyHistog.input(buffers[buffers.length - 1], powerLevel, sampleRate);
      if (this.isCanSendData) {
        this.webSpeechRecognizer.write(buffers[buffers.length - 1]);
      }
    },
    endRecording() {
      // 结束录录音的逻辑是：先判断isUploadRecord，true代表点击的停止，再判断是否收录到了语音，
      // 如果收到的话再判断是否完成文字转录，是的话停止录音，上传转录文字；false代表点击的取消，直接停止转录，停止录音即可
      if (!this.isCloseRecognizer) {
        if (this.duration && !this.recognizerText) {
          this.recognizerTextDefault = this.$t('coaching.voiceNotRecognized');
        } else {
          this.webSpeechRecognizer.stop();
          this.stopMediaRecord(true);
        }
      } else {
        this.stopMediaRecord();
        this.stopRecognize();
      }
    },
    /**
     * 结束录音
     * @param {boolean} isUpload 是否上传音频
     * */
    stopMediaRecord(isUpload) {
      var _this$mediaRecorder;
      (_this$mediaRecorder = this.mediaRecorder) === null || _this$mediaRecorder === void 0 ? void 0 : _this$mediaRecorder.stop((blob, duration) => {
        this.mediaRecorder.close();
        this.mediaRecorder = null;
        this.practiceStatus = 'ended';
        this.duration = '00:00';
        if (this.isAiCustomer && isUpload) {
          // 缓存并上传录音文件和时长
          this.audioFiles.set(this.currentSentenceUuid, {
            blob,
            duration
          });
        }
      });
    },
    async uploadRecord(content) {
      content = String(content);
      if (!content.length || this.isCloseRecognizer) return;
      // 文本对练模式
      if (this.practiceRule.practice_mode) {
        this.currentSentenceUuid = (0, _nanoid.nanoid)();
      }
      this.recognizerStatus = 3;
      if (this.isAiCustomer) {
        var _this$websocket3, _this$websocket3$ws;
        if (((_this$websocket3 = this.websocket) === null || _this$websocket3 === void 0 ? void 0 : (_this$websocket3$ws = _this$websocket3.ws) === null || _this$websocket3$ws === void 0 ? void 0 : _this$websocket3$ws.readyState) === 1) {
          let data = {
            type: 'sales',
            content
          };
          if ([5, 6, 7].includes(this.taskConfig.type)) {
            data = {
              ...data,
              knowledge_id: this.knowledgeId,
              scene_id: this.sceneId,
              uuid: this.currentSentenceUuid,
              comment: {},
              loading: true
              // score_loading: true
            };
          }
          // 通过ws发送消息
          this.websocket.sendMessage({
            type: 'plan_message',
            data
          });
          this.knowledgeId = 0;
          this.updateSentenceList([data, {
            type: 'customer',
            loading: true
          }]);
          this.customerSayLoading = true;
        }
      } else {
        const sentence = {
          content,
          uuid: this.currentSentenceUuid,
          child_id: (0, _nanoid.nanoid)(),
          type: 'sales',
          score: 0,
          pass_score: 0,
          total_score: 0,
          comment: '',
          score_loading: true,
          timer: null,
          score_rule_type: 1
        };
        this.updateSentenceList([sentence]);
        this.scrollToBottom();
        this.customerSayLoading = true;
        const res = await (0, _coachingCenter.uploadSmartTrainingAudio)({
          content,
          conv_id: this.salesTalkUuid,
          connection_id: this.connectionId
        });
        this.recognizerStatus = 0;
        if (res.code === 20000) {
          const {
            is_complete,
            content,
            data,
            standard_replies,
            score_rule_type,
            box_id
          } = res.results;
          this.standardReply = standard_replies === null || standard_replies === void 0 ? void 0 : standard_replies.filter(reply => reply);
          this.conversationComplate = is_complete;
          this.customerSayLoading = false;
          if (is_complete) {
            clearInterval(this.timeId);
          }
          this.updateSentenceList([...data]);
          if (!this.practiceRule.practice_mode) {
            this.autoPlayCustomerContent(data);
          }
          this.scrollToBottom();
          // 要评分
          const needScore = score_rule_type === 1;
          if (needScore) {
            sentence.timer = setInterval(() => {
              this.getSalesSayScore(box_id, sentence.uuid);
            }, 2000);
          }
          this.updateSentenceContent({
            ...sentence,
            content,
            score_rule_type,
            score_loading: needScore,
            score_is_complete: !needScore
          }, this.updateScoreComplate);
        } else if (res.code === 43617) {
          this.handleConnectionFailed();
        }
      }
    },
    async getSalesSayScore(box_id, uuid) {
      const scoreRes = await (0, _coachingCenter.getSmartTrainingSalesSayScore)({
        box_id,
        conv_id: this.salesTalkUuid
      });
      if (scoreRes.code === 20000) {
        const {
          score,
          pass_score,
          total_score,
          comment,
          score_is_complete
        } = scoreRes.results;
        this.updateSentenceContent({
          uuid,
          score,
          pass_score,
          total_score,
          comment,
          score_is_complete,
          score_loading: !score_is_complete
        });
        if (score_is_complete) {
          var _this$sentenceList$fi;
          clearInterval((_this$sentenceList$fi = this.sentenceList.find(item => item.uuid === uuid)) === null || _this$sentenceList$fi === void 0 ? void 0 : _this$sentenceList$fi.timer);
          if (this.taskConfig.type === 3) {
            setTimeout(() => {
              this.updateScoreComplate();
            }, 200);
          }
        }
      }
    },
    updateScoreComplate() {
      // 判断所有的评分是否已完成
      this.conversationComplateDisabled = this.sentenceList.some(item => item.type === 'sales' && item.score_rule_type === 1 && !item.comment);
      this.scrollToBottom();
    },
    async updateSalesSentence(val) {
      const {
        content,
        loading,
        uuid
      } = val;
      this.updateSentenceContent({
        content,
        loading,
        uuid
      });
      if (this.practiceRule.practice_mode) return;
      const {
        blob,
        duration
      } = this.audioFiles.get(this.currentSentenceUuid);
      await (0, _coachingCenter.uploadTrainingAudio)({
        file: blob,
        uuid: this.currentSentenceUuid,
        duration
      });
    },
    closeRecording() {
      this.recognizerStatus = 2;
      this.isCloseRecognizer = true;
      this.endRecording();
    },
    complateConversation() {
      this.isFinished = true;
      this.pauseAudio();
      this.getTaskScore(false);
    },
    handleConnectionFailed() {
      if (this.singletonExitConfirm) return;
      this.singletonExitConfirm = this.$confirm(this.$t('coaching.其他途径正在进行或已完成本次练习，当前途径无法继续操作'), this.$t('coaching.失败提示'), {
        confirmButtonText: this.$t('coaching.exit'),
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).finally(() => {
        this.closeWebsocket();
        this.isFinished = true;
        this.conversationComplate = true;
        const path = _routeMap.ROUTES.coachingCenterSales;
        this.$router.replace({
          path: this.isEmbed ? this.getEmbedPath(path) : path
        });
        this.singletonExitConfirm = null;
      });
    },
    handleChatRestoreSuccess() {
      this.$message.success(this.$t('coaching.sessionRecoverySuccessful'));
      if (this.isFinished) {
        this.websocket.sendMessage({
          type: 'plan_finished'
        });
      } else {
        if (this.sentenceList.length) {
          const lastSentence = this.sentenceList.concat().reverse().find(item => ['customer', 'sales'].includes(item.type) && !item.loading);
          if ((lastSentence === null || lastSentence === void 0 ? void 0 : lastSentence.type) === 'sales') {
            // 通过ws发送消息
            this.websocket.sendMessage({
              type: 'plan_reconnect_message',
              data: lastSentence
            });
          }
        }
      }
    },
    async getTaskScore() {
      let needBeginData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const res = await (0, _coachingCenter.getTaskScore)({
        id: this.taskConfig.taskId,
        user_id: Number(this.userId),
        conv_id: this.salesTalkUuid || undefined
      });
      if (res.code === 20000) {
        const {
          plan_name,
          summary_score
        } = res.results.data;
        this.title = plan_name;
        if (needBeginData && summary_score === -1 || this.taskConfig.restart && !this.sentenceList.length) {
          this.getSmartTrainingBeginData();
        } else {
          this.isFinished = true;
          this.overallScore = summary_score;
        }
      }
    },
    updateSentenceContent(val, callback) {
      setTimeout(() => {
        this.sentenceList.find(item => {
          if (item.uuid === val.uuid) {
            item = Object.assign(item, val);
          }
        });
        callback && callback();
      }, 100);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    async getSmartTrainingBeginData() {
      const res = await (0, _coachingCenter.getSmartTrainingBeginData)({
        plan_id: this.taskConfig.taskId,
        conv_id: this.currentConverId,
        connection_id: this.connectionId
      });
      if (res.code === 20000) {
        this.currentConverId = res.results.uuid;
        this.replaceHistory({
          conv_id: res.results.uuid
        });
        this.practiceStatus = 'not-started';
        // practiceRule.practice_mode 练习模式 0:语音模式 1: 文字模式
        this.practiceRule = res.results.rule;
        this.tourName = this.practiceRule.practice_mode ? 'smartTrainingTextPracticeGuide' : 'isShowSmartTrainingRecordGuide';
        // 更新计时
        this.practiceTime = res.results.duration ? res.results.duration * 1000 : 0;
        this.updateTalk(res.results);
      } else if (res.code === 43617) {
        this.handleConnectionFailed();
      }
    },
    updateTalk(val) {
      const {
        is_complete,
        uuid,
        data,
        standard_replies
      } = val;
      this.standardReply = standard_replies === null || standard_replies === void 0 ? void 0 : standard_replies.filter(reply => reply);
      this.conversationComplate = is_complete;
      if (is_complete) {
        clearInterval(this.timeId);
      }
      this.salesTalkUuid = uuid;
      this.updateSentenceList(data);
      if (data.some(item => item.type === 'sales')) return; // 如果是继续练习，不自动播放

      setTimeout(() => {
        // 语音模式 0 自动播放客户话术
        !this.practiceRule.practice_mode && this.autoPlayCustomerContent(data);
      }, 3000);
    },
    handleCustomerSentence(data) {
      this.customerSayLoading = false;
      const {
        knowledge_id,
        scene_id
      } = data;
      this.knowledgeId = knowledge_id;
      this.sceneId = scene_id;
      const isInitSentence = !this.sentenceList.some(item => item.type === 'sales');
      if (isInitSentence) {
        this.sentenceList.push(data);
      } else {
        const firstLoading = this.sentenceList.find(item => item.type === 'customer' && item.loading);
        if (firstLoading) {
          this.sentenceList.splice(this.sentenceList.indexOf(firstLoading), 1, {
            ...data,
            loading: false
          });
        } else {
          this.sentenceList.push(data);
        }
      }
      data.trans_url && this.playAudio(data);
      if (data.is_finished) {
        this.recordDisabled = true;
        setTimeout(() => {
          this.hangUp();
        }, 5000);
      }
    },
    handleTtsResult(data) {
      const sentence = this.sentenceList.find(item => item.uuid === data.uuid);
      if (sentence) {
        sentence.trans_url = data.trans_url;
        this.playAudio(sentence);
      }
    },
    updateSentenceList(data) {
      data.forEach(item => {
        item.child_id = item.child_id + (0, _nanoid.nanoid)();
      });
      // 为了让句子加入有动画
      let index = 0;
      const timer = setInterval(() => {
        if (index < data.length) {
          this.sentenceList.push(data[index]);
          this.scrollToBottom();
          index += 1;
        } else {
          clearInterval(timer);
        }
      }, 100);
    },
    autoPlayCustomerContent(data) {
      const customerData = data.find(item => item.type === 'customer');
      if (customerData && customerData.content.length) {
        this.playAudio(customerData);
      }
    },
    // 滚动到底部
    scrollToBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          const container = this.$refs['sentence-container'];
          container === null || container === void 0 ? void 0 : container.scrollTo({
            top: this.getElementHeight(container),
            behavior: 'smooth'
          });
        });
      }, 500);
    },
    scrollToNew() {
      this.$nextTick(() => {
        const container = this.$refs['recognizer-text'];
        container === null || container === void 0 ? void 0 : container.scrollTo({
          top: this.getElementHeight(container),
          behavior: 'smooth'
        });
      });
    },
    getElementHeight(ele) {
      return ele === null || ele === void 0 ? void 0 : ele.scrollHeight;
    },
    beforeunloadHandler() {
      this.tempStore();
      return this.$t('coaching.sureToLeave');
    },
    handleDocumentTitle() {
      document.title = `${this.$t('coaching.detailsOfKnowledgePointExercises')}`;
    },
    // 返回我的任务
    goMyTask() {
      const path = _routeMap.ROUTES.coachingCenterSales;
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          id: this.taskConfig.taskId
        }
      });
    },
    goBack() {
      // isFinish: ai客户完成 conversationComplate: 剧本对练完成
      if (this.isFinished || this.conversationComplate) {
        this.goMyTask();
      } else {
        this.backToTaskDialogVisible = true;
      }
    },
    async tempStore() {
      if (this.isAiCustomer) {
        var _this$websocket4, _this$websocket4$ws;
        if (((_this$websocket4 = this.websocket) === null || _this$websocket4 === void 0 ? void 0 : (_this$websocket4$ws = _this$websocket4.ws) === null || _this$websocket4$ws === void 0 ? void 0 : _this$websocket4$ws.readyState) === 1) {
          this.websocket.sendMessage({
            // 前端挂断
            type: 'plan_stash'
          });
        }
      } else {
        const res = await (0, _coachingCenter.tempStoreAiCustomerConversation)({
          conv_id: this.currentConverId,
          connection_id: this.connectionId
        });
      }
    },
    // 查看练习详情
    goCoachDetail() {
      let path;
      switch (this.taskConfig.type) {
        case 3:
          path = _routeMap.ROUTES.couseTaskDetailSmartTraining;
          break;
        case 4:
        case 5:
        case 6:
        case 7:
          path = _routeMap.ROUTES.aiCoachTaskDetail;
          break;
        default:
          path = _routeMap.ROUTES.aiCoachTaskDetail;
          break;
      }
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        taskId: this.taskConfig.taskId,
        userInfo: JSON.stringify(this.taskConfig.userInfo),
        type: this.taskConfig.type,
        convId: this.salesTalkUuid
      }));
      this.$router.replace({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          taskId: this.taskConfig.taskId,
          from: 'coach-todo',
          type: this.taskConfig.type
        }
      });
    },
    async getPracticeProcessHint() {
      const isAiCustomer = [5, 6, 7].includes(this.taskConfig.type);
      if (!isAiCustomer) return;
      const isGmma = this.taskConfig.type === 7;
      if (isGmma) {
        const res = await (0, _coachingCenter.getPracticeProcessHitGamma)({
          plan_id: this.taskConfig.taskId
        });
        if (res.code === 20000) {
          this.aiCustomerProcessHints = res.results.keypoints;
          this.practiceDescription = res.results.description;
          this.practiceScoreMetrics = res.results.evaluates;
        }
      } else {
        const res = await (0, _coachingCenter.getPracticeProcessHitBeta)({
          plan_id: this.taskConfig.taskId
        });
        if (res.code === 20000) {
          this.aiCustomerProcessHints = res.results.knowledges;
          this.practiceDescription = res.results.description;
        }
      }
    }
  }
};
exports.default = _default;