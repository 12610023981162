"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _TaskDetailFIlters = _interopRequireDefault(require("./components/TaskDetailFIlters.vue"));
var _TaskDetailTable = _interopRequireDefault(require("./components/TaskDetailTable.vue"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _coachingCenter = require("@/api/coachingCenter");
var _download = require("@/utils/download");
var _timezone = require("@/utils/timezone");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const total = (0, _vue.ref)(0);
    const taskList = (0, _vue.ref)([]);
    const listParams = (0, _vue.ref)({
      page: 1,
      size: 10
    });
    const sizeChange = val => {
      listParams.value.size = val;
      fetchData();
    };
    const currentChange = val => {
      listParams.value.page = val;
      fetchData();
    };
    const filter = (0, _vue.ref)({});
    const tableLoading = (0, _vue.ref)(false);
    const onFilterChange = _filter => {
      filter.value = _filter;
      listParams.value.page = 1;
      fetchData();
    };
    const orderParams = (0, _vue.ref)({
      order_field: 'plan_start_time',
      order_type: 'desc'
    });
    const sortChange = _ref => {
      let {
        prop,
        order
      } = _ref;
      orderParams.value.order_field = prop;
      orderParams.value.order_type = order;
      listParams.value.page = 1;
      fetchData();
    };
    async function fetchData() {
      tableLoading.value = true;
      const res = await (0, _coachingCenter.getTraineeTaskDetail)({
        ...(0, _timezone.convertParams2UTCplus8)(filter.value),
        ...listParams.value,
        ...orderParams.value
      }).finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        taskList.value = res.results.data;
        total.value = res.results.total_count;
      }
    }

    // 导出
    const exportTable = async () => {
      const params = {
        ...filter.value,
        ...orderParams.value
      };
      const res = await (0, _coachingCenter.exportTraineeTaskDetail)(params);
      if (res.code === 20000) {
        (0, _download.downloadFileRename)(res.results.file_url, `数据分析_任务明细${filter.value.date.map(item => (0, _moment.default)(item).format('yyyyMMDD')).join('_')}`);
      }
    };
    return {
      __sfc: true,
      total,
      taskList,
      listParams,
      sizeChange,
      currentChange,
      filter,
      tableLoading,
      onFilterChange,
      orderParams,
      sortChange,
      fetchData,
      exportTable,
      TaskDetailFIlters: _TaskDetailFIlters.default,
      TaskDetailTable: _TaskDetailTable.default,
      MgPagination: _MgPagination.default
    };
  }
};
exports.default = _default;