"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
// import { getTimeRange } from '@/utils/getDateRange';
// const OPTION_MAP = {
//   本月: ['month'],
//   本季度: ['quarter'],
//   今年: ['year'],
//   上个月: ['month', -1],
//   上个季度: ['quarter', -1],
//   去年: ['year', -1],
//   下个月: ['month', 1],
//   下个季度: ['quarter', 1],
//   明年: ['year', 1]
// };
var _default = {
  name: 'DealFilterDate',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String, Number]
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dateRangeValue: '',
      customDate: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId']),
    isCreateTimeAndGuaZi() {
      var _this$item;
      return this.orgId === 97 && ((_this$item = this.item) === null || _this$item === void 0 ? void 0 : _this$item.field_name) === 'create_time';
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (!val || !val.length) {
          this.dateRangeValue = '';
          this.customDate = null;
        } else {
          if (Array.isArray(val)) {
            this.customDate = val;
            this.dateRangeValue = val.join(' 至 ');
          } else {
            this.dateRangeValue = val;
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    const dealPicker = document.querySelector('.deal-date-picker');
    if (dealPicker) {
      dealPicker.removeEventListener('mouseup', this.stopPropagation);
    }
  },
  methods: {
    dateTimeText() {
      const timeText = this.dateRangeValue ? this.dateRangeValue : this.customDate;
      if (!this.dateRangeValue && !this.customDate) {
        return `${this.item.name}`;
      } else {
        return `${this.item.name}:${timeText}`;
      }
    },
    handleDateChange() {
      this.customDate = null;
      this.$emit('change', {
        [this.item.field_name]: this.dateRangeValue
      });
    },
    handleCustomDate() {
      const dateStr = this.customDate.join(' 至 ');
      this.dateRangeValue = dateStr;
      this.$emit('change', {
        [this.item.field_name]: this.customDate
      });
      // 选完日期后关闭下拉框
      const select = this.$refs['deal-time-select'];
      select.blur();
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    // 选择日期时保持下拉框打开
    handleDatePickerFocus() {
      this.$nextTick(() => {
        const dealPicker = document.querySelector('.deal-date-picker');
        if (!dealPicker) return;
        dealPicker.addEventListener('mouseup', this.stopPropagation);
      });
    }
  }
};
exports.default = _default;