"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _num_formatter = require("@/utils/num_formatter");
var _default = {
  name: 'ConversationDetailInfo',
  props: {
    detailResult: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      info: [],
      fromType: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions', 'webRoutes']),
    customizeField() {
      var _this$detailResult, _this$detailResult$ty;
      // eslint-disable-next-line no-undef
      return (_this$detailResult = this.detailResult) === null || _this$detailResult === void 0 ? void 0 : (_this$detailResult$ty = _this$detailResult.type_info) === null || _this$detailResult$ty === void 0 ? void 0 : _this$detailResult$ty.customize_field;
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    }
  },
  watch: {
    detailResult: {
      handler: function (value) {
        this.handleDetailResult(value);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // 暂时兼容商机页与交易页跳转客户页面
    const from_type = this.$route.query.from;
    if (from_type) {
      this.fromType = from_type;
    }
  },
  methods: {
    formatValue(item) {
      if (item.label === '预计成交金额' && item.value !== '') {
        return `￥${(0, _num_formatter.parseFormatNum)(item.value)}`;
      }
      return item.value;
    },
    hasAccountSeaDetailPermission() {
      return this.api_permissions.includes('/api/account/account/account_pool_detail');
    },
    itemParse(item) {
      const parseList = ['会话时商机阶段', '当前商机阶段'];
      return parseList.includes(item.label);
    },
    itemName(item) {
      if (item.label === '客户名称' || item.label === '商机所属客户') {
        return this.hasCustomerPermission;
      }
      const nameList = ['商机名称', '线索名称'];
      return nameList.includes(item.label);
    },
    goDetail(item) {
      var _this$detailResult2;
      const res = this.itemName(item);
      if (!res) return;
      const {
        is_access,
        id,
        account_is_access,
        account_id
      } = this.detailResult.type_info;
      // eslint-disable-next-line no-undef
      switch ((_this$detailResult2 = this.detailResult) === null || _this$detailResult2 === void 0 ? void 0 : _this$detailResult2.type) {
        case 'deal':
          switch (item.label) {
            case '商机名称':
              if (is_access) {
                const routeData = this.$router.resolve({
                  path: _routeMap.ROUTES.dealDetail,
                  query: {
                    id,
                    isBack: true
                  }
                });
                window.open(routeData.href, '_blank');
              } else {
                this.$message.warning('无商机详情查看权限');
              }
              break;
            case '商机所属客户':
              if (account_is_access) {
                // 暂时兼容商机页与交易页跳转客户页面
                const query = {
                  id: account_id
                };
                if (this.fromType) {
                  query.from = this.fromType;
                }
                const routeData = this.$router.resolve({
                  path: _routeMap.ROUTES.customer,
                  query
                });
                window.open(routeData.href, '_blank');
              } else {
                this.$message.warning('无客户详情查看权限');
              }
              break;
          }
          break;
        case 'lead':
          if (is_access) {
            this.$router.push({
              path: _routeMap.ROUTES.leadDetail,
              query: {
                id,
                isBack: true
              }
            });
          } else {
            this.$message.warning('无线索详情查看权限');
          }
          break;
        case 'account':
          if (is_access) {
            const query = {
              id: account_id
            };
            // 暂时兼容商机页与交易页跳转客户页面
            if (this.fromType) {
              query.from = this.fromType;
            }
            const routeData = this.$router.resolve({
              path: _routeMap.ROUTES.customer,
              query
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message.warning('无客户详情查看权限');
          }
          break;
      }
    },
    handleDetailResult(detailResult) {
      if (Object.hasOwnProperty.call(detailResult, 'type_info')) {
        const type_info = detailResult.type_info;
        const info = [];
        for (const key in type_info) {
          if (Object.hasOwnProperty.call(type_info, key)) {
            switch (detailResult.type) {
              case 'deal':
                {
                  const dealIndex = this.getDealLabel(key).index;
                  const dealLabel = this.getDealLabel(key).label;
                  info[dealIndex] = {
                    label: dealLabel,
                    value: type_info[key]
                  };
                  if (dealLabel === '预计成交日期') {
                    info[dealIndex] = {
                      label: dealLabel,
                      value: type_info[key]
                    };
                  }
                  break;
                }
              case 'lead':
                {
                  const leadIndex = this.getLeadLabel(key).index;
                  const leadLabel = this.getLeadLabel(key).label;
                  info[leadIndex] = {
                    label: leadLabel,
                    value: type_info[key]
                  };
                  break;
                }
              case 'account':
                {
                  const accountIndex = this.getAccountLabel(key).index;
                  const accountLabel = this.getAccountLabel(key).label;
                  info[accountIndex] = {
                    label: accountLabel,
                    value: type_info[key]
                  };
                  break;
                }
            }
          }
        }
        this.info = info;
      }
    },
    getDealLabel(key) {
      switch (key) {
        case 'name':
          return {
            label: '商机名称',
            index: 0
          };
        case 'customer_name':
          return {
            label: '商机所属客户',
            index: 1
          };
        case 'decision_maker_name':
          return {
            label: '决策人姓名',
            index: 2
          };
        case 'pre_deal_date':
          return {
            label: '预计成交日期',
            index: 3
          };
        case 'pre_deal_money':
          return {
            label: '预计成交金额',
            index: 4
          };
        case 'conv_deal_stage':
          return {
            label: '会话时商机阶段',
            index: 5
          };
        case 'stage':
          return {
            label: '当前商机阶段',
            index: 6
          };
        default:
          return {
            label: '',
            index: 0
          };
      }
    },
    getLeadLabel(key) {
      switch (key) {
        case 'name':
          return {
            label: '线索名称',
            index: 0
          };
        case 'company':
          return {
            label: '公司名称',
            index: 1
          };
        case 'industry':
          return {
            label: '行业',
            index: 2
          };
        case 'scale':
          return {
            label: '企业规模',
            index: 3
          };
        default:
          return {
            label: '',
            index: 0
          };
      }
    },
    getAccountLabel(key) {
      switch (key) {
        case 'name':
          return {
            label: '客户名称',
            index: 0
          };
        case 'level':
          return {
            label: '客户级别',
            index: 1
          };
        case 'industry':
          return {
            label: '行业',
            index: 2
          };
        case 'scale':
          return {
            label: '企业规模',
            index: 3
          };
        default:
          return {
            label: '',
            index: 0
          };
      }
    }
  }
};
exports.default = _default;