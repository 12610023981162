"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "edit-comment-widget"
  }, [_c("div", {
    staticClass: "input-container"
  }, [_c(_setup.CommentInput, {
    ref: "commentInputRef",
    attrs: {
      "has-mention": _setup.hasMention,
      "entity-type": _vm.entityType
    },
    on: {
      "update:hasMention": function ($event) {
        _setup.hasMention = $event;
      },
      "update:has-mention": function ($event) {
        _setup.hasMention = $event;
      },
      pressEnter: _setup.sendComment
    }
  }), _c("div", {
    staticClass: "selected-label-container"
  }, _vm._l(_setup.localProposal, function (item, idx) {
    return _c(_setup.SelectedLabel, {
      key: idx,
      attrs: {
        "show-close": "",
        item: item
      },
      on: {
        delete: function ($event) {
          return _setup.deleteProposal(idx);
        }
      }
    });
  }), 1), _vm.comment.advise === "advisor" ? _c(_setup.InsightClipSelector, {
    ref: "insightClipSelectorRef"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "bottom-row"
  }, [_c("div", [_c("el-checkbox", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.hasMention && !_vm.isReply,
      expression: "hasMention && !isReply"
    }],
    staticClass: "scope-checkbox",
    model: {
      value: _setup.commentVisiblePermission,
      callback: function ($$v) {
        _setup.commentVisiblePermission = $$v;
      },
      expression: "commentVisiblePermission"
    }
  }, [_vm._v("仅被@的人可见该评论")])], 1), _c("div", {
    staticClass: "button-group"
  }, [_c("el-button", {
    on: {
      click: _setup.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _setup.sendBtnLoading,
      type: "primary"
    },
    on: {
      click: _setup.sendComment
    }
  }, [_vm._v("确定")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;