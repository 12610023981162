"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useEvaluateForm = require("../hooks/use-evaluate-form");
var _vue = require("vue");
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _GeneratorCascader = _interopRequireDefault(require("./GeneratorCascader"));
var _WidgetCascader = _interopRequireDefault(require("./WidgetCascader.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'GeneratorFormItem',
  props: {
    optionIds: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    fieldType: {
      type: Number,
      default: 1
    },
    isEditStatus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onOptionFieldChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const fieldType = (0, _vue.computed)(() => (0, _useEvaluateForm.getField)(props.fieldType).type);
    const handleRadioChange = val => {
      if (props.isEditStatus) return;
      emit('onOptionFieldChange', [val]);
    };
    const handleCheckboxChange = val => {
      if (props.isEditStatus) return;
      emit('onOptionFieldChange', val);
    };
    const onOptionDrag = val => {
      emit('onOptionDrag', val);
    };
    const deleteOption = id => {
      emit('onDeleteOption', id);
    };
    return {
      __sfc: true,
      props,
      fieldType,
      emit,
      handleRadioChange,
      handleCheckboxChange,
      onOptionDrag,
      deleteOption,
      MgDraggable: _MgDraggable.default,
      GeneratorCascader: _GeneratorCascader.default,
      WidgetCascader: _WidgetCascader.default
    };
  }
};
exports.default = _default;