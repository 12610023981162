"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    ref: "automationProcessDrawer",
    staticClass: "mg-common-drawer",
    attrs: {
      visible: _vm.visible,
      "custom-class": "automation-process-drawer",
      title: "自动化流程",
      size: "1000px",
      "before-close": _setup.closeDialog,
      "append-to-body": ""
    }
  }, [_c("div", {
    staticClass: "drawer-content"
  }, [_vm.visible ? _c(_setup.ProcessModule, {
    ref: "processModuleRef"
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;