"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSummaryTemplate = createSummaryTemplate;
exports.deleteSummaryTemplate = deleteSummaryTemplate;
exports.getSummaryTemplateList = getSummaryTemplateList;
exports.updateSummaryTemplate = updateSummaryTemplate;
exports.updateSummaryTemplateOrder = updateSummaryTemplateOrder;
var _request = require("@/utils/request");
// 获取纪要模板列表
function getSummaryTemplateList(params) {
  return (0, _request.webService)({
    url: '/conversation/summary/template/list',
    method: 'get',
    params
  });
}

// 创建纪要模板
function createSummaryTemplate(data) {
  return (0, _request.webService)({
    url: '/conversation/summary/template/create',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 更新纪要模板
function updateSummaryTemplate(data) {
  return (0, _request.webService)({
    url: '/conversation/summary/template/update',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 删除纪要模板
function deleteSummaryTemplate(data) {
  return (0, _request.webService)({
    url: '/conversation/summary/template/delete',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 修改纪要模板顺序
function updateSummaryTemplateOrder(data) {
  return (0, _request.webService)({
    url: '/conversation/summary/template/change_serial_number',
    method: 'post',
    data,
    dataType: 'json'
  });
}