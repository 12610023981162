"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HorizontalScroll = _interopRequireDefault(require("./HorizontalScroll.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SentenceType',
  props: {
    exampleType: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:exampleType', 'handleExampleTypeChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handleChange = val => {
      emit('update:exampleType', val);
      emit('handleExampleTypeChange', val);
    };
    const modelSentenceTypeList = (0, _vue.ref)([{
      imageUrl: {
        backgroundImage: 'url(' + require('@/assets/quickModel/quick-model-sales-question.jpg') + ')'
      },
      label: '销售说',
      value: 1
    }, {
      imageUrl: {
        backgroundImage: 'url(' + require('@/assets/quickModel/quick-model-customer-question.jpg') + ')'
      },
      label: '客户说',
      value: 2
    }, {
      imageUrl: {
        backgroundImage: 'url(' + require('@/assets/quickModel/quick-model-any.jpg') + ')'
      },
      label: '任意角色说',
      value: 7
    }, {
      imageUrl: {
        backgroundImage: 'url(' + require('@/assets/quickModel/quick-model-sales-question-customer-answer.jpg') + ')'
      },
      label: '销售说 - 客户答',
      value: 3
    }, {
      imageUrl: {
        backgroundImage: 'url(' + require('@/assets/quickModel/quick-model-customer-question-sales-answer.jpg') + ')'
      },
      label: '客户说 - 销售答',
      value: 4
    }]);
    return {
      __sfc: true,
      props,
      emit,
      handleChange,
      modelSentenceTypeList,
      HorizontalScroll: _HorizontalScroll.default
    };
  }
};
exports.default = _default;