"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgePoint = _interopRequireDefault(require("./components/KnowledgePoint"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Knowledge',
  components: {
    KnowledgePoint: _KnowledgePoint.default
  },
  data() {
    return {};
  },
  methods: {}
};
exports.default = _default;