"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clues = require("@/api/clues");
var _index = _interopRequireDefault(require("@/components/DetailDrawer/index"));
var _ClueDetailInfo = _interopRequireDefault(require("./ClueDetailInfo"));
var _ClueTranslateAccountDialog = _interopRequireDefault(require("./ClueTranslateAccountDialog"));
var _LogRecord = _interopRequireDefault(require("@/components/LogRecord"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClueDetailDrawer',
  components: {
    DetailDrawer: _index.default,
    ClueDetailInfo: _ClueDetailInfo.default,
    ClueTranslateAccountDialog: _ClueTranslateAccountDialog.default,
    LogRecord: _LogRecord.default
  },
  inject: ['init'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    clueObject: {
      type: Object,
      default: () => ({})
    },
    clueDynamicFormDesc: {
      type: Array,
      default: () => []
    },
    clueId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      activeName: '',
      id: '',
      clueDetailTab: 'clueInfo',
      clueFormData: {},
      // 线索转客户dialog控制
      clueTranslateAccountDialogVisible: false,
      // 操作日志
      pageSize: 10,
      logItemProperties: {
        create_lead: {
          label: '新建线索',
          icon: 'iconfont icon-leads'
        },
        edit_lead: {
          label: '编辑线索',
          icon: 'iconfont icon-edit'
        },
        lead_fub_modify: {
          label: '变更跟进人',
          icon: 'iconfont icon-change-follower'
        },
        converted: {
          label: '线索转客户',
          icon: 'iconfont icon-transform'
        }
      }
    };
  },
  computed: {
    title() {
      return '线索详情';
    },
    operateButton() {
      if (this.clueObject.status === 'converted') {
        return;
      } else {
        return [{
          icon: 'iconfont icon-change-follower',
          label: '变更跟进人',
          action: 'changeFollowUpby',
          disabled: !this.clueObject.has_edit,
          permission: '/api/lead/lead/edit_follow_up_by'
        }, {
          icon: 'iconfont icon-transform',
          label: '线索转客户',
          disabled: !this.clueObject.has_edit,
          action: 'clueTranslateAccount',
          permission: '/api/lead/lead/lead_to_account'
        }];
      }
    },
    detailInfo() {
      var _this$clueObject$foll;
      return {
        avatarType: 'customer_contact',
        name: this.clueObject.name,
        subLabel: '跟进人：',
        subValue: (_this$clueObject$foll = this.clueObject.follow_up_by) === null || _this$clueObject$foll === void 0 ? void 0 : _this$clueObject$foll.name,
        type: 'clue'
      };
    }
  },
  created() {
    this.getClueFormInfo();
  },
  methods: {
    clueTranslateAccountSuccess() {
      this.init();
    },
    handleCancel(val) {
      this.clueFormData = val;
    },
    handleInput(val) {
      this.clueFormData = val;
    },
    openClueTranslateAccountDialog() {
      this.clueTranslateAccountDialogVisible = true;
    },
    closeClueTranslateAccountDialog() {
      this.clueTranslateAccountDialogVisible = false;
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    detailOperations(action) {
      switch (action) {
        case 'clueTranslateAccount':
          this.openClueTranslateAccountDialog();
          break;
        case 'changeFollowUpby':
          this.$emit('changeFollowUpby', [this.clueId], 'clueChangeFollower');
          break;
        default:
          break;
      }
    },
    // 获取线索详情值接口
    async getClueFormInfo() {
      const res = await (0, _clues.getClueFormInfo)({
        lead_id: this.clueId
      });
      this.clueFormData = res.results;
    },
    fetchLogMethod() {
      return _clues.getClueLogRecords;
    }
  }
};
exports.default = _default;