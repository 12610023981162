"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'Vue3Example',
  setup(__props) {
    (0, _vue.useCssVars)((_vm, _setup) => ({
      "5f2fc754-blockWidth": _vm.blockWidth,
      "5f2fc754-blockStyle_width": _setup.blockStyle.width,
      "5f2fc754-blockStyle_color": _setup.blockStyle.color
    }));
    const count = (0, _vue.ref)(0);
    const addCount = () => {
      count.value++;
    };
    const blockStyle = (0, _vue.reactive)({
      width: '100px',
      color: 'red',
      isActive: false
    });
    const toggleBlock = () => {
      if (blockStyle.isActive) {
        blockStyle.width = '100px';
        blockStyle.color = 'red';
        blockStyle.isActive = false;
      } else {
        blockStyle.width = '150px';
        blockStyle.color = 'blue';
        blockStyle.isActive = true;
      }
    };
    // 目录树
    const treeData = (0, _vue.ref)([{
      name: '文件夹1',
      creater: '',
      count: 541,
      fid: 0,
      type: 'category',
      create_time: '',
      order: 0,
      id: 0,
      children: []
    }, {
      name: '文件夹2',
      creater: '李朋',
      count: 2,
      fid: 0,
      type: 'category',
      create_time: '2023-09-22 16:39:54',
      order: 1,
      id: 74,
      children: [{
        name: '文件夹21',
        creater: '王瑞东',
        count: 1,
        fid: 74,
        type: 'category',
        create_time: '2023-09-27 14:21:24',
        order: 2,
        id: 75,
        children: [{
          name: '内容1',
          creater: '王瑞东',
          count: 0,
          fid: 75,
          type: 'content',
          create_time: '2023-09-27 14:21:29',
          order: 1,
          id: 76
        }, {
          name: '内容2',
          creater: '陈歆哲',
          count: 0,
          fid: 75,
          type: 'content',
          create_time: '2023-10-30 15:38:00',
          order: 2,
          id: 93,
          children: []
        }]
      }]
    }]);
    (0, _vue.onMounted)(() => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
    const getCatalogIconConfig = (data, node) => {
      if (data.type === 'content') {
        return {
          icon: 'icon-course'
        };
      } else {
        return {
          icon: 'icon-folder-fill',
          expandedIcon: 'icon-folder-opened-fill'
        };
      }
    };
    const getCatalogTreeOperationConfigs = (data, node) => {
      return [{
        icon: 'icon-plus',
        options: [[{
          type: 'createCatalog',
          label: '新建文件夹',
          icon: 'icon-folder-add',
          disabled: node.level >= 3
        }]]
      }, {
        icon: 'icon-more',
        options: [[{
          type: 'renameCatalog',
          label: '重命名',
          icon: 'icon-edit-outline'
        }, {
          type: 'deleteCatalog',
          label: '删除',
          icon: 'icon-delete'
        }]]
      }];
    };
    const mgvCatalogTreeRef = (0, _vue.ref)(null);
    const handleOperateCommand = (node, type) => {
      switch (type) {
        case 'createCatalog':
          console.log('createCatalog', node);
          break;
        case 'renameCatalog':
          mgvCatalogTreeRef.value.renameCatalog(node);
          break;
        case 'deleteCatalog':
          console.log('deleteCatalog', node);
          break;
      }
    };
    const handleRename = (newName, node) => {
      setTimeout(() => {
        // 请求重命名接口
        node.data.name = newName;
      }, 1000);
    };
    const handleClick = event => {
      console.log('点击', event);
    };
    const handleClose = event => {
      console.log('删除', event);
    };
    const handleCurrentNodeChange = val => {
      mgvCatalogTreeRef.value.setCurrentNode(val);
    };
    // 自定义单选框组
    const customRadioValue = (0, _vue.ref)(1);
    const customRadioValue2 = (0, _vue.ref)(2);
    const customRadioValue3 = (0, _vue.ref)('');
    const customRadioList = (0, _vue.ref)([{
      value: 1,
      label: '选项一'
    }, {
      value: 2,
      label: '选项二',
      icon: 'el-icon-bell'
    }, {
      value: 3,
      label: '选项三'
    }, {
      value: 4,
      label: '选项四',
      icon: 'iconfont icon-pie-chart'
    }, {
      value: 5,
      label: '选项五',
      disabled: true
    }]);

    // 日期选择器
    const dateValue = (0, _vue.ref)('');
    const dateValue2 = (0, _vue.ref)('');
    const dateValue3 = (0, _vue.ref)('');
    const dateValue4 = (0, _vue.ref)('');
    const datePickerChange = value => {
      console.log('value', value);
    };
    return {
      __sfc: true,
      count,
      addCount,
      blockStyle,
      toggleBlock,
      treeData,
      getCatalogIconConfig,
      getCatalogTreeOperationConfigs,
      mgvCatalogTreeRef,
      handleOperateCommand,
      handleRename,
      handleClick,
      handleClose,
      handleCurrentNodeChange,
      customRadioValue,
      customRadioValue2,
      customRadioValue3,
      customRadioList,
      dateValue,
      dateValue2,
      dateValue3,
      dateValue4,
      datePickerChange
    };
  }
};
exports.default = _default;