"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsencrypt = _interopRequireDefault(require("jsencrypt"));
var _user = require("@/api/user");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// RSA加密
var _default = {
  async getRsaCode(str) {
    // 获取公钥
    const res = await (0, _user.getPubKey)();
    if (res.code == 20000) {
      const pubKey = res.results.pub_key;
      // 加密公钥
      return this.encryptData(str, pubKey);
    } else {
      (0, _elementUi.Message)({
        message: res.msg,
        type: 'info',
        duration: 3 * 1000
      });
    }
  },
  encryptData(str, pub_key) {
    // 加密公钥
    const encryptStr = new _jsencrypt.default();
    encryptStr.setPublicKey(pub_key);
    const data = encryptStr.encrypt(str.toString());
    return {
      pub_key,
      data
    };
  }
};
exports.default = _default;