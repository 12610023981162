"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "insight-clip-selector"
  }, [_c("div", {
    staticClass: "labels-container"
  }, [_vm._l(_setup.getAddedKnowledges(), function (item) {
    return _c(_setup.SelectedLabel, {
      key: item.id,
      attrs: {
        item: item,
        "show-close": ""
      },
      on: {
        delete: _setup.deleteKnowledge
      }
    });
  }), _vm._l(_setup.getAddedSnippets(), function (item) {
    return _c(_setup.SelectedLabel, {
      key: item.id,
      attrs: {
        item: item,
        "show-close": ""
      },
      on: {
        delete: _setup.deleteSnippet
      }
    });
  })], 2), _c("div", {
    staticClass: "btns"
  }, [_setup.modulePermission.knowledgeLibrary ? _c("div", {
    staticClass: "btn-item",
    on: {
      click: _setup.addKnowledges
    }
  }, [_c("i", {
    staticClass: "iconfont icon-reading"
  }), _vm._v(" 添加知识点 ")]) : _vm._e(), _setup.modulePermission.snippetsLibrary ? _c("div", {
    staticClass: "btn-item",
    on: {
      click: _setup.addSnippets
    }
  }, [_c("i", {
    staticClass: "iconfont icon-film"
  }), _vm._v(" 添加剪辑片段 ")]) : _vm._e()]), _c(_setup.SelectKnowledgesDrawer, {
    attrs: {
      "knowledges-drawer-visible": _setup.knowledgesDrawerVisible,
      "conv-id": _setup.convId,
      "selected-knowledges": _setup.selectedKnowledges
    },
    on: {
      "update:knowledgesDrawerVisible": function ($event) {
        _setup.knowledgesDrawerVisible = $event;
      },
      "update:knowledges-drawer-visible": function ($event) {
        _setup.knowledgesDrawerVisible = $event;
      },
      setSelectedKnowledges: _setup.setSelectedKnowledges
    }
  }), _c(_setup.SelectSnippetsDrawer, {
    attrs: {
      "snippets-drawer-visible": _setup.snippetsDrawerVisible,
      "selected-snippets": _setup.selectedSnippets
    },
    on: {
      "update:snippetsDrawerVisible": function ($event) {
        _setup.snippetsDrawerVisible = $event;
      },
      "update:snippets-drawer-visible": function ($event) {
        _setup.snippetsDrawerVisible = $event;
      },
      setSelectedSnippets: _setup.setSelectedSnippets
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;