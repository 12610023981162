"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-select", {
    staticClass: "select",
    attrs: {
      clearable: "",
      size: "medium",
      placeholder: "联系人状态"
    },
    model: {
      value: _vm.filterParams.merge_status,
      callback: function ($$v) {
        _vm.$set(_vm.filterParams, "merge_status", $$v);
      },
      expression: "filterParams.merge_status"
    }
  }, _vm._l(_vm.mergeStatusOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _c("el-select", {
    staticClass: "select",
    attrs: {
      clearable: "",
      size: "medium",
      placeholder: "联系人类型"
    },
    model: {
      value: _vm.filterParams.type,
      callback: function ($$v) {
        _vm.$set(_vm.filterParams, "type", $$v);
      },
      expression: "filterParams.type"
    }
  }, _vm._l(_vm.contactTypeOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;