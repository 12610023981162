"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.config.label) + " "), _vm.config.require ? _c("span", {
    staticClass: "require-dot"
  }, [_vm._v("*")]) : _vm._e(), _vm.config.tooltip ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_vm._l(_vm.config.tooltip, function (tooltipItem) {
    return _c("div", {
      key: tooltipItem.title,
      staticClass: "tooltip-content-item",
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(" " + _vm._s(tooltipItem.title)), _c("br"), _vm._v(_vm._s(tooltipItem.content) + " ")]);
  }), _c("i", {
    staticClass: "iconfont icon-info-2"
  })], 2) : _vm._e(), _vm.config.remark ? _c("span", {
    staticClass: "remark"
  }, [_vm._v("（" + _vm._s(_vm.config.remark) + "）")]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;