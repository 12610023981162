"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permissions = require("@/api/permissions");
var _DepartmentMultipleSelect = _interopRequireDefault(require("@/components/DepartmentMultipleSelect"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _ExportButton = _interopRequireDefault(require("@/views/management/components/ExportButton"));
var _commonFunc = require("@/utils/commonFunc");
var _export = require("@/api/export");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DepartmentMultipleSelect: _DepartmentMultipleSelect.default,
    ImageAvatar: _ImageAvatar.default,
    ExportButton: _ExportButton.default
  },
  props: {
    roleInfo: {
      type: Object,
      default: () => ({})
    },
    roleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      listParams: {
        filter: '',
        tree_ids: [],
        role_id: 1,
        only_direct: false
      },
      pageParams: {
        page: 1,
        size: 10
      },
      total: 0,
      adminRole: '',
      membersInfo: [],
      memberMultiArray: [],
      currentMemberIds: []
    };
  },
  computed: {},
  watch: {
    roleInfo: {
      handler: function (val) {
        if (val) {
          this.listParams.role_id = val.id;
        }
      },
      immediate: true
    },
    listParams: {
      handler: (0, _commonFunc.debounce)(function (val) {
        this.initData();
        this.getMembersList();
      }, 200),
      deep: true
    }
  },
  created() {},
  methods: {
    // 导出业务角色
    exportApi() {
      const {
        filter,
        tree_ids,
        only_direct,
        role_id
      } = this.listParams;
      return (0, _export.exportBusinessRole)({
        filter,
        tree_ids,
        only_direct,
        role_id
      });
    },
    editMember(memberList) {
      this.$emit('openOperateMemberDialog', memberList);
    },
    openRoleDrawer() {
      this.$emit('openRoleDrawer', this.roleInfo, 'edit');
    },
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    getDepartmentsWithOutAuth: _permissions.getDepartmentsWithOutAuth,
    getRoleName(roleList) {
      const roleNameList = roleList.map(item => item.name);
      return roleNameList.join('；');
    },
    // 获取成员列表
    async getMembersList() {
      this.tableLoading = true;
      const data = {
        ...this.listParams,
        ...this.pageParams
      };
      const res = await (0, _permissions.getMembersList)(data);
      this.tableLoading = false;
      if (res.code === 200) {
        const {
          count,
          user_list
        } = res.results;
        this.tableData = user_list;
        this.total = count;
      }
    },
    openMemberDialog(membersObj) {
      this.$emit('openDialog', membersObj);
    },
    batchRemoveMember() {
      if (this.membersInfo.length) {
        this.$confirm(`确定从${this.roleInfo.name}角色中移除吗？`, '移除成员', {
          confirmButtonText: '移除',
          cancelButtonText: '取消',
          confirmButtonClass: 'confirm-right',
          type: 'warning'
        }).then(async () => {
          this.tableLoading = true;
          const res = await (0, _permissions.deleteFuncMembers)({
            role_id: this.roleInfo.id,
            user_ids: this.currentMemberIds
          });
          this.tableLoading = false;
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            const minusData = this.tableData.filter(item => this.currentMemberIds.includes(item.user_id));
            this.tableData = this.tableData.filter(item => !this.currentMemberIds.includes(item.user_id));
            this.total = this.total - minusData.length;
          }
        });
      } else {
        this.$message({
          message: '请先选择成员',
          center: true,
          type: 'warning'
        });
      }
    },
    removeMember(row) {
      this.$confirm(`确定从${this.roleInfo.name}角色中移除吗？`, '移除成员', {
        confirmButtonText: '移除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        this.tableLoading = true;
        const res = await (0, _permissions.deleteFuncMembers)({
          role_id: this.roleInfo.id,
          user_ids: [row.user_id]
        });
        this.tableLoading = false;
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.tableData = this.tableData.filter(item => item.user_id !== row.user_id);
        }
      });
    },
    departmentMultipleChange(ids, labels, strictly) {
      this.listParams.tree_ids = ids;
      this.listParams.only_direct = strictly;
    },
    handleSelectionChange(val) {
      this.membersInfo = val;
      this.currentMemberIds = this.membersInfo.map(item => item.user_id);
      this.$emit('selectionChange', val);
    },
    initData() {
      this.pageParams.page = 1;
      this.tableData = [];
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.getMembersList();
    },
    handlePageChange(val) {
      this.pageParams.page = val;
      this.getMembersList();
    }
  }
};
exports.default = _default;