"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contact-form"
  }, [_c("el-checkbox", {
    staticClass: "toggle-checkbox",
    on: {
      change: _vm.handleCheckboxChange
    },
    model: {
      value: _vm.hasContactForm,
      callback: function ($$v) {
        _vm.hasContactForm = $$v;
      },
      expression: "hasContactForm"
    }
  }, [_vm._v("同时关联联系人")]), _c("dynamic-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasContactForm,
      expression: "hasContactForm"
    }],
    ref: "form",
    attrs: {
      desc: _vm.desc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "id",
      fn: function () {
        return [_c("el-select", {
          ref: "name-form-item",
          attrs: {
            remote: "",
            filterable: "",
            loading: _vm.loading,
            "remote-method": _vm.searchContact,
            placeholder: "请选择或创建联系人",
            "popper-class": "contact-form-select-popper"
          },
          on: {
            change: _vm.handleSelectChange
          },
          model: {
            value: _vm.form.id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id", $$v);
            },
            expression: "form.id"
          }
        }, [_vm._l(_vm.contactList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              value: item.id,
              label: item.name
            }
          }, [_c("image-avatar", {
            attrs: {
              name: item.name
            }
          }), _c("div", {
            staticClass: "option-item-info"
          }, [_c("p", {
            staticClass: "name"
          }, [_vm._v(_vm._s(item.name))]), _c("p", {
            staticClass: "tel"
          }, [_vm._v(_vm._s(item.tel))])])], 1);
        }), _vm.query ? [_c("el-option", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }]
        }), _vm.contactList.length ? _c("el-divider", {
          staticClass: "contact-form-option-divider"
        }) : _vm._e(), _c("el-button", {
          staticClass: "contact-form-create-contact-button",
          attrs: {
            type: "text",
            icon: "el-icon-plus"
          },
          on: {
            click: _vm.createContact
          }
        }, [_vm._v("创建 "), _c("strong", [_vm._v(_vm._s(_vm.query))]), _vm._v(" 为联系人")])] : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "tel",
      fn: function () {
        return [_c("el-input", {
          attrs: {
            placeholder: "请输入手机号",
            disabled: !_vm.form.id,
            maxlength: "11"
          },
          on: {
            blur: _vm.handleTelBlur
          },
          model: {
            value: _vm.form.tel,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tel", $$v);
            },
            expression: "form.tel"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;