"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-date-picker", _vm._g(_vm._b({
    staticClass: "mgv-date-time-picker",
    attrs: {
      type: "datetimerange",
      "value-format": "yyyy-MM-dd HH:mm",
      format: "yyyy-MM-dd HH:mm",
      "start-placeholder": _vm.startPlaceholder,
      "end-placeholder": _vm.endPlaceholder,
      "default-time": ["00:00:00", "23:59:59"],
      "popper-class": "mgv-date-picker-panel",
      "picker-options": _setup.localPickerOptions
    }
  }, "el-date-picker", _vm.$attrs, false), _vm.$listeners));
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;