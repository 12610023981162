"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getDateRange = require("@/utils/getDateRange");
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDate',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    noTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      convDateList: [{
        tag: 'today',
        label: '今天',
        fn: _getDateRange.getToday
      }, {
        tag: 'yesterday',
        label: '昨天',
        fn: _getDateRange.getYesterday
      }, {
        tag: 'this week',
        label: '本周',
        fn: _getDateRange.getThisWeek
      }, {
        tag: 'last week',
        label: '上周',
        fn: _getDateRange.getLastWeek
      }, {
        tag: 'last month',
        label: '上个月',
        fn: _getDateRange.getLastMonth
      }]
    };
  },
  computed: {
    range() {
      return this.filters.date.range;
    }
  },
  watch: {
    range(val) {
      if (!val) this.clearDateChoose();
    }
  },
  methods: {
    clearDateChoose() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.date.range = [];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.date.tag = '';
    },
    handleActiveDate(val) {
      return this.filters.date.range.length && this.filters.date.tag === val;
    },
    selectDate(_ref) {
      let {
        tag,
        fn
      } = _ref;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.date.tag = tag;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.date.range = fn();
    },
    handleChange() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.date.tag = '';
    }
  }
};
exports.default = _default;