"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["todo-item", {
      "in-progress": _vm.item.status === "created"
    }, {
      done: _vm.item.status === "done"
    }, {
      ignore: _vm.item.status === "ignored"
    }]
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("el-tooltip", {
    attrs: {
      content: "完成",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "read"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: _vm.isDisabled || _vm.isReadonly
    },
    on: {
      change: _vm.handleIsDoneChange
    },
    model: {
      value: _vm.isDone,
      callback: function ($$v) {
        _vm.isDone = $$v;
      },
      expression: "isDone"
    }
  })], 1)])], 1), _c("div", {
    staticClass: "right-part"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "item-type"
  }, [_vm.item.type !== "call_back" ? _c("div", {
    class: ["conv-icon", `icon-${_vm.markIcon}-container`]
  }, [_c("i", {
    class: `iconfont icon-${_vm.markIcon}`
  })]) : _vm._e(), _c("span", {
    class: ["type-text", {
      done: _vm.item.status === "done"
    }]
  }, [_vm._v(_vm._s(_vm.getType(_vm.item)))]), _c("el-tooltip", {
    attrs: {
      content: "截止时间",
      "popper-class": "conv-list-tip",
      placement: "top",
      trigger: "click",
      "open-delay": 200,
      disabled: !_vm.item.todo_time || _vm.isReadonly
    }
  }, [_vm.showDatePicker ? _c("el-date-picker", {
    staticClass: "todo-time-picker",
    class: [_vm.todoTime ? "have-todo-time" : "no-todo-time", {
      timeout: _vm.todoTime && _vm.isTimeout,
      "not-timeout": _vm.todoTime && !_vm.isTimeout,
      "in-progress": _vm.isInProgress,
      "is-readonly": _vm.isReadonly
    }],
    attrs: {
      format: "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm",
      type: "datetime",
      "prefix-icon": "iconfont icon-date",
      "popper-class": "dashboard-todo-item-time-select",
      clearable: false,
      editable: false,
      placeholder: _vm.todoTimeContent,
      disabled: !_vm.isInProgress || _vm.isReadonly
    },
    on: {
      change: _vm.submitTodoTime,
      input: _vm.handleTodoTimeChange
    },
    model: {
      value: _vm.todoTime,
      callback: function ($$v) {
        _vm.todoTime = $$v;
      },
      expression: "todoTime"
    }
  }) : _vm._e()], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "operate"
  }, [_vm.item.status === "created" ? _c("div", {
    staticClass: "ignore",
    on: {
      click: function ($event) {
        return _vm.handleIgnore(_vm.item);
      }
    }
  }, [_vm._v(" 忽略 ")]) : _vm._e(), _c("more-operations", {
    staticClass: "operate-btn",
    attrs: {
      list: _vm.operateList
    },
    on: {
      command: _vm.handleCommand
    }
  })], 1)]), _vm.item.type === "call_back" && _vm.showBusinessAndAccount ? _c("div", {
    staticClass: "business-and-account to-be-contact"
  }, [_c("el-tooltip", {
    attrs: {
      content: "客户",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.account_name ? _c("span", {
    staticClass: "account",
    on: {
      click: function ($event) {
        return _vm.goAccountDetail(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-office-building"
  }), _vm._v(_vm._s(_vm.item.account_name) + " ")]) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      content: "商机",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.deal_name ? _c("span", {
    staticClass: "business",
    on: {
      click: function ($event) {
        return _vm.goBusinessDetail(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-deal4"
  }), _vm._v(_vm._s(_vm.item.deal_name) + " ")]) : _vm._e()])], 1) : _vm._e(), _c("div", {
    class: ["conv-info", _vm.item.type === "call_back" ? "to-be-contact" : ""]
  }, [_c("div", {
    staticClass: "conv-name",
    on: {
      click: function ($event) {
        return _vm.goConv(_vm.item);
      }
    }
  }, [_c("i", {
    class: _vm.getConvIcon(_vm.item)
  }), _c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.conversation.name))])]), _c("el-tooltip", {
    attrs: {
      content: "会话日期和时间",
      "popper-class": "conv-list-tip",
      placement: "top",
      trigger: "click",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "conv-date"
  }, [_vm._v(" " + _vm._s(_vm.getConvTime(_vm.item.conversation.conversation_date)) + " ")])]), _c("el-tooltip", {
    attrs: {
      content: "销售",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.conversation.conversation_user_name ? _c("div", {
    staticClass: "salesman"
  }, [_vm._v(" " + _vm._s(_vm.item.conversation.conversation_user_name) + " ")]) : _vm._e()])], 1), _vm.item.detail && _vm.item.detail.length ? _c("div", {
    staticClass: "todo"
  }, _vm._l(_vm.item.detail, function (todoItem, index) {
    return _c("div", {
      key: index,
      staticClass: "sentence",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(_vm.item, todoItem);
        }
      }
    }, [_c("span", {
      staticClass: "speaker"
    }, [_vm._v(" 【" + _vm._s(todoItem.user_name) + "】 ")]), _c("span", {
      staticClass: "text",
      domProps: {
        innerHTML: _vm._s(_vm.getHighlightContent(todoItem))
      }
    })]);
  }), 0) : _vm._e(), _vm.item.type !== "call_back" && _vm.showBusinessAndAccount ? _c("div", {
    staticClass: "business-and-account other"
  }, [_c("el-tooltip", {
    attrs: {
      content: "客户",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.account_name ? _c("span", {
    staticClass: "account",
    on: {
      click: function ($event) {
        return _vm.goAccountDetail(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-office-building"
  }), _vm._v(_vm._s(_vm.item.account_name) + " ")]) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      content: "商机",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.deal_name ? _c("span", {
    staticClass: "business",
    on: {
      click: function ($event) {
        return _vm.goBusinessDetail(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-deal4"
  }), _vm._v(_vm._s(_vm.item.deal_name) + " ")]) : _vm._e()])], 1) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;