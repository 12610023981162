"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _calendar = require("@/api/calendar");
var _elementUi = require("element-ui");
var _useVuex = require("@/hooks/use-vuex.js");
var _vue = require("vue");
var _default = {
  __name: 'CreateInstructionDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    backInfo: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const closeDialog = () => emit('update:visible', false);
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const initForm = {
      content: '',
      departments: []
    };
    const form = (0, _vue.ref)({
      ...initForm
    });

    // 监听抽屉打开时，清空数据并清空校验
    const formRef = (0, _vue.ref)(null);
    const mgvDepartmentMultiSelectRef = (0, _vue.ref)(null);
    (0, _vue.watch)(() => props.visible, val => {
      if (val && props.type === 'create') {
        form.value = {
          ...initForm
        };
        (0, _vue.nextTick)(() => {
          mgvDepartmentMultiSelectRef.value && mgvDepartmentMultiSelectRef.value.clear();
          formRef.value.clearValidate();
        });
      }
    }, {
      immediate: true
    });

    // 监听backInfo,将backInfo的值赋值给templateForm
    (0, _vue.watch)(() => props.backInfo, val => {
      console.log('watch', val);
      if (val) {
        form.value = {
          ...form.value,
          ...val
        };
      }
    }, {
      deep: true,
      immediate: true
    });
    const validateTreeIds = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请选择生效范围'));
      } else {
        callback();
      }
    };
    const rules = {
      name: [{
        required: true,
        message: '请输入指令名称',
        trigger: 'change'
      }],
      content: [{
        required: true,
        message: '请输入指令内容',
        trigger: 'change'
      }],
      departments: [{
        required: true,
        validator: validateTreeIds
      }]
    };
    const handleDepartmentValueChange = val => {
      form.value.departments = val;
      formRef.value.validateField('departments');
    };
    const saveLoading = (0, _vue.ref)(false);
    const saveForm = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          saveLoading.value = true;
          createInstructionRequest();
        }
      });
    };
    const createInstructionRequest = async () => {
      const res = await (0, _calendar.createInstruction)({
        space_id: workspaceInfo.value.id,
        content: form.value.content,
        tree_ids: form.value.departments,
        name: form.value.name
      }).finally(() => {
        saveLoading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success('保存成功');
        closeDialog();
      }
    };
    return {
      __sfc: true,
      props,
      store,
      emit,
      closeDialog,
      workspaceInfo,
      initForm,
      form,
      formRef,
      mgvDepartmentMultiSelectRef,
      validateTreeIds,
      rules,
      handleDepartmentValueChange,
      saveLoading,
      saveForm,
      createInstructionRequest,
      getOrgTree: _organization.getOrgTree
    };
  }
};
exports.default = _default;