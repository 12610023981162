"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("el-dropdown", {
    staticClass: "add-folder-dropdown",
    attrs: {
      placement: "bottom-end",
      trigger: "click"
    },
    on: {
      command: function ($event) {
        return _vm.$emit("create", $event);
      }
    }
  }, [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "add-btn"
    }, [_c("i", {
      staticClass: "iconfont icon-plus"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "snippets-library-dropdown-menu-common snippets-library-add-folder-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.interfacePermission.createStandardFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "createFolder"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-folder"
  }), _c("span", [_vm._v("新建文件夹")])]) : _vm._e(), _vm.interfacePermission.createConvFlow ? _c("el-dropdown-item", {
    attrs: {
      command: "createConvFlow"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-filter"
  }), _c("span", [_vm._v("新建智能会话流")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "智能会话流可以根据您保存的筛选条件，自动将最新会话加入该文件夹中。",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1) : _vm._e()], 1)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;