"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKanbanListLoading,
      expression: "getKanbanListLoading"
    }],
    staticClass: "bi-overview"
  }, [_vm.kanbanList.length ? [_c("BI-kanban", {
    ref: "bi-kanban",
    attrs: {
      "is-draggable": false,
      "extra-command-list": [{
        type: "assign",
        label: "分享",
        order: 2,
        icon: "icon-share-line"
      }],
      "active-id": _vm.currentKanban.kanban_id,
      "kanban-list": _vm.kanbanList
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getCardListLoading,
      expression: "getCardListLoading"
    }],
    staticClass: "card-box"
  }, [_c("div", {
    staticClass: "card-head"
  }, [_c("div", {
    staticClass: "left-container"
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      format: "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm",
      "picker-options": _vm.pickerOptions,
      "range-separator": "至",
      "start-placeholder": "开始时间",
      "end-placeholder": "结束时间",
      "popper-class": "bi-overview-card-head-datetime-range-box",
      "default-time": ["00:00:00", "23:59:59"]
    },
    on: {
      change: _vm.filterDateTimeRangeChange
    },
    model: {
      value: _vm.filterDateTimeRange,
      callback: function ($$v) {
        _vm.filterDateTimeRange = $$v;
      },
      expression: "filterDateTimeRange"
    }
  }), _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.currentKanban.data_range_label
    }
  }, [_c("span", {
    staticClass: "data-range"
  }, [_vm._v("数据范围：" + _vm._s(_vm.currentKanban.data_range_label))])])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowCheckbox,
      expression: "!isShowCheckbox"
    }]
  }, [!_vm.currentKanban.is_share ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCard
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" 添加卡片")]) : _vm._e(), _vm.cardList.length && _vm.modulePermission.biExperiment ? _c("el-button", {
    on: {
      click: function ($event) {
        _vm.isShowCheckbox = true;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-abtest"
  }), _vm._v("创建 A/B 测试")]) : _vm._e(), _c("el-popover", {
    attrs: {
      placement: "bottom-end",
      width: "200",
      trigger: "click",
      "popper-class": "subscribe-container",
      "visible-arrow": false
    }
  }, [_c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.subscribeKanbanLoading,
      expression: "subscribeKanbanLoading"
    }]
  }, _vm._l(_vm.subscribeOptions, function (_ref) {
    let {
      label,
      value
    } = _ref;
    return _c("li", {
      key: value
    }, [_c("span", [_vm._v(_vm._s(label))]), _c("el-switch", {
      attrs: {
        value: _vm.currentKanban["is_" + value],
        "active-color": "#4461EC",
        "inactive-color": "#E2E5E9"
      },
      on: {
        change: $event => _vm.changeSubscribeType($event, value)
      }
    })], 1);
  }), 0), _c("el-button", {
    class: ["subscribe-button", {
      active: _vm.isSubscribeKanban
    }],
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    class: _vm.isSubscribeKanban ? "el-icon-message-solid" : "el-icon-bell"
  }), _vm._v(" 订阅")])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowCheckbox,
      expression: "isShowCheckbox"
    }]
  }, [_c("el-button", {
    on: {
      click: _vm.handleCancle
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.confirmLoading,
      disabled: !_vm.checkedList.length
    },
    on: {
      click: _vm.goABTest
    }
  }, [_vm._v("确定")])], 1)]), _c("div", {
    staticClass: "card-body"
  }, [!_vm.getCardListLoading && !_vm.cardList.length && !_vm.currentKanban.is_share ? _c("init-kanban-card-view", {
    attrs: {
      config: {
        title: "卡片"
      }
    },
    on: {
      createKanban: _vm.addCard
    }
  }) : _c("mg-infinite-scroll", {
    attrs: {
      disabled: _vm.noMore
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, [_c("card-container", {
    ref: "card-container",
    attrs: {
      "is-show-checkbox": _vm.isShowCheckbox,
      "is-subscribe": _vm.currentKanban.is_subscribe,
      "is-assign": _vm.currentKanban.is_share,
      "card-list": _vm.cardList
    },
    on: {
      checkedChange: val => _vm.checkedList = val,
      dimensionChange: _vm.dimensionChange,
      dragEnd: _vm.dragEnd,
      handleCommand: _vm.handleCard,
      handleForewarn: _vm.handleForewarn,
      toggleFullScreen: _vm.toggleFullScreen
    }
  })], 1)], 1)])] : _vm._e(), _c("ChartConfig", {
    ref: "chart-config",
    attrs: {
      "chart-config-visible": _vm.chartConfigVisible,
      "is-edit-card": _vm.isEditCard,
      "kanban-id": _vm.currentKanban.kanban_id,
      "edit-chart-config": _vm.editChartConfig
    },
    on: {
      "update:chartConfigVisible": function ($event) {
        _vm.chartConfigVisible = $event;
      },
      "update:chart-config-visible": function ($event) {
        _vm.chartConfigVisible = $event;
      },
      handleChartSuccess: _vm.handleChartSuccess
    }
  }), _c("KanbanConfig", {
    ref: "kanban-config",
    attrs: {
      "kanban-config-visible": _vm.kanbanConfigVisible,
      "get-kanban-config-loading": _vm.getKanbanConfigLoading,
      "is-edit-kanban": _vm.isEditKanban
    },
    on: {
      "update:kanbanConfigVisible": function ($event) {
        _vm.kanbanConfigVisible = $event;
      },
      "update:kanban-config-visible": function ($event) {
        _vm.kanbanConfigVisible = $event;
      },
      handleKanbanSuccess: _vm.handleKanbanSuccess
    }
  }), _c("ShareConfig", {
    attrs: {
      visible: _vm.assignConfigVisible,
      title: "分享",
      description: "对看板和卡片内容的修改都将自动同步至被分享人",
      params: {
        kanban_id: _vm.currentKanban.kanban_id
      },
      "handle-fn": _vm.assignKanban,
      "cancel-handle-fn": _vm.cancelAssignKanban,
      "get-list": _vm.getAssigneeList,
      "is-show-sync-data": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.assignConfigVisible = $event;
      },
      handleClose: _vm.changeKanbanStatus
    }
  }), _c("ForewarnConfig", {
    attrs: {
      "forewarn-config-visible": _vm.forewarnConfigVisible,
      "card-id": _vm.currentCardId
    },
    on: {
      "update:forewarnConfigVisible": function ($event) {
        _vm.forewarnConfigVisible = $event;
      },
      "update:forewarn-config-visible": function ($event) {
        _vm.forewarnConfigVisible = $event;
      },
      openForewarn: _vm.openForewarn
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;