"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$combineConfig, _setup$combineConfig$;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    class: ["mg-common-drawer", "train-detail-drawer", ["rule", "combine"].includes(_vm.modelType) ? "train-detail_rule-drawer" : ""],
    attrs: {
      visible: _vm.visible,
      title: ["rule", "combine"].includes(_vm.modelType) ? "查看版本配置" : "查看训练记录",
      size: _vm.modelType === "ai" ? "600px" : "800px",
      "before-close": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "select-version"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择模型版本"
    },
    model: {
      value: _setup.activeVersion,
      callback: function ($$v) {
        _setup.activeVersion = $$v;
      },
      expression: "activeVersion"
    }
  }, _vm._l(_setup.allVersionsList, function (item) {
    return _c("el-option", {
      key: item.version,
      attrs: {
        label: `V${item.version}`,
        value: item.version
      }
    });
  }), 1)], 1), _vm.modelType === "ai" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }]
  }, [_c(_setup.ModelPredictionResult, {
    attrs: {
      "post-process-type": _setup.postProcessType,
      "is-enable-l-l-m": _setup.isEnableLLM,
      "prompt-content": _setup.promptContent,
      "read-only": ""
    },
    on: {
      "update:postProcessType": function ($event) {
        _setup.postProcessType = $event;
      },
      "update:post-process-type": function ($event) {
        _setup.postProcessType = $event;
      }
    }
  }), _c(_setup.TrainAndTestDataSet, {
    attrs: {
      "train-dataset-list": _setup.trainDatasetList,
      "trial-dataset-list": _setup.trialDatasetList,
      "dataset-split-type": _setup.datasetSplitType,
      "is-auto-test": _setup.isAutoTest,
      "read-only": ""
    },
    on: {
      "update:datasetSplitType": function ($event) {
        _setup.datasetSplitType = $event;
      },
      "update:dataset-split-type": function ($event) {
        _setup.datasetSplitType = $event;
      },
      "update:isAutoTest": function ($event) {
        _setup.isAutoTest = $event;
      },
      "update:is-auto-test": function ($event) {
        _setup.isAutoTest = $event;
      }
    }
  }), _c("div", {
    staticClass: "super-params-content"
  }, [_c("h2", [_vm._v("超参数设置")]), _c("div", {
    staticClass: "train-params-mode"
  }, [_c("el-radio", {
    attrs: {
      disabled: "",
      label: true
    },
    model: {
      value: _setup.autoTuningParams,
      callback: function ($$v) {
        _setup.autoTuningParams = $$v;
      },
      expression: "autoTuningParams"
    }
  }, [_vm._v("自动调优参数")]), _c("el-radio", {
    attrs: {
      disabled: "",
      label: false
    },
    model: {
      value: _setup.autoTuningParams,
      callback: function ($$v) {
        _setup.autoTuningParams = $$v;
      },
      expression: "autoTuningParams"
    }
  }, [_vm._v("手动配置参数")])], 1), !_setup.autoTuningParams ? _c(_setup.TrainSuperParamTable, {
    attrs: {
      "fill-value": _setup.ruleEventForm.train_param,
      "only-read": ""
    }
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.autoTuningParams,
      expression: "autoTuningParams"
    }],
    staticClass: "set-item-content"
  }, [_c("el-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _setup.ruleEventForm.train_param_mode,
      callback: function ($$v) {
        _vm.$set(_setup.ruleEventForm, "train_param_mode", $$v);
      },
      expression: "ruleEventForm.train_param_mode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "兼顾精确率和召回率",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "高精确率优先",
      value: 2
    }
  }), _c("el-option", {
    attrs: {
      label: "高召回率优先",
      value: 3
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.modelType === "rule" ? _c("div", {
    staticClass: "rule-form-container"
  }, [_c("el-form", {
    attrs: {
      model: _setup.ruleEventForm,
      disabled: ""
    }
  }, [_c(_setup.TriggerRulesFormItem, {
    attrs: {
      form: _setup.ruleEventForm,
      "rule-count": _setup.ruleCount
    }
  })], 1)], 1) : _vm._e(), _vm.modelType === "combine" ? _c("div", {
    staticClass: "combine-form"
  }, [_c("div", {
    staticClass: "combine-form-title"
  }, [_vm._v("触发规则")]), _c("div", {
    staticClass: "combine-form-container"
  }, [((_setup$combineConfig = _setup.combineConfig) === null || _setup$combineConfig === void 0 ? void 0 : (_setup$combineConfig$ = _setup$combineConfig.configs) === null || _setup$combineConfig$ === void 0 ? void 0 : _setup$combineConfig$.length) > 1 ? _c("div", {
    staticClass: "combine-form-container-left"
  }, [_c("div", {
    staticClass: "combine-form-container-left-text"
  }, [_vm._v("或")])]) : _vm._e(), _c("div", {
    staticClass: "combine-form-container-right"
  }, _vm._l(_setup.combineConfig.configs, function (ruleGroup, index) {
    return _c(_setup.CombineRuleGroup, {
      key: index,
      attrs: {
        disabled: "",
        "rule-group": ruleGroup,
        "rule-group-index": index,
        "rule-group-length": _setup.combineConfig.configs.length
      },
      on: {
        deleteRuleGroup: function ($event) {
          return _vm.deleteRuleGroup(index);
        }
      }
    });
  }), 1)])]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;