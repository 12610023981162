"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "audio-recorder",
    class: {
      "is-test": _vm.isTest
    }
  }, [_vm.disabled && _vm.score < 0 ? _c("div", {
    staticClass: "no-score"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.noScoreYet")) + " ")]) : [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.status,
      expression: "!status"
    }],
    staticClass: "button-container"
  }, [_c("div", {
    staticClass: "empty"
  }), !_vm.disabled || _vm.isTest ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "页面请求麦克风时，请点击允许。若仍无法录音，请检查系统设置-声音中，输入设备-麦克风是否已开启。",
      placement: "bottom"
    }
  }, [!_vm.disabled || _vm.isTest ? _c("div", {
    class: ["record-button", {
      test: _vm.isTest
    }],
    on: {
      click: _vm.startRecord
    }
  }, [_c("i", {
    staticClass: "iconfont icon-microphone"
  }), _vm._v(_vm._s(_vm.isTest ? "测试录音" : "开始录音") + " ")]) : _vm._e()]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.status === "recording",
      expression: "status === 'recording'"
    }]
  }, [_c("div", {
    staticClass: "wave-box",
    attrs: {
      id: _vm.waveBoxId
    }
  }), !_vm.disabled || _vm.isTest ? _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.endRecord
    }
  }, [_c("i", {
    staticClass: "iconfont icon-stop"
  }), _vm._v(_vm._s(_vm.isTest ? "停止录音" : "结束录音"))]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.status === "finished",
      expression: "status === 'finished'"
    }],
    class: {
      "finish-container": _vm.isTest
    }
  }, [!_vm.isTemplate && !_vm.isTest ? _c("div", {
    staticClass: "score-box"
  }, [_vm.isScoring ? _c("span", [_vm._v("评分中...")]) : _vm._e(), !_vm.isScoring && _vm.score > -1 ? _c("span", {
    staticClass: "score"
  }, [_vm._v(_vm._s(_vm.score) + "分")]) : _vm._e()]) : _vm._e(), !_vm.disabled && _vm.isTest || _vm.isTemplate ? _c("div", {
    staticClass: "close-audio",
    on: {
      click: _vm.closeAudio
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })]) : _vm._e(), _c("audio", {
    attrs: {
      src: _vm.audioSrc,
      controls: ""
    }
  }), !_vm.disabled || _vm.isTest ? _c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.startRecord
    }
  }, [_c("i", {
    staticClass: "iconfont icon-microphone"
  }), _vm._v(_vm._s(_vm.isTest ? "重新测试" : "重新录音"))]) : _vm._e()], 1)]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;