"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: "转移归属人",
      width: "600px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      close: _setup.handleClose
    }
  }, [_c("div", {
    staticClass: "transferee-container"
  }, [_c("div", {
    staticClass: "transferee-content"
  }, [_c("div", [_vm._v("将会话" + _vm._s(_vm.conversationName) + "的归属人从" + _vm._s(_vm.salesName) + "转移给：")]), _c(_setup.MembersFinder, {
    ref: "membersFinderRef",
    attrs: {
      "popper-class": "data-transfer-drawer-members-finder",
      placeholder: "搜索成员",
      "is-radio": true,
      "get-member-api-type": "data",
      "has-self": false,
      params: {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleMemberChange
    }
  })], 1), _c("div", {
    staticClass: "transfer-data"
  }, [_c("el-checkbox", {
    model: {
      value: _setup.transfer_department,
      callback: function ($$v) {
        _setup.transfer_department = $$v;
      },
      expression: "transfer_department"
    }
  }, [_vm._v("归属部门和空间，同步更新为新归属人的主部门和主空间")])], 1), _c("div", {
    staticClass: "transfer-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-info"
  }), _c("span", [_vm._v("会话的关键事件等将重新分析")])])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.submitLoading
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;