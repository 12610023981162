"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "intelligent-generation-prompt-form"
  }, [_c("div", {
    staticClass: "form-title"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.labelTitle))]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.valueTitle))])]), _c(_setup.MgDraggable, {
    attrs: {
      value: _vm.desc,
      disabled: _vm.dragDisabled
    },
    on: {
      input: _setup.handleDragEnd
    }
  }, _vm._l(_vm.desc, function (item) {
    return _c("el-form", {
      key: item.id,
      ref: "formRef",
      refInFor: true,
      staticClass: "prompt-form",
      attrs: {
        model: item,
        rules: _vm.rules
      }
    }, [!_vm.dragDisabled && !item.isPreset ? _c(_setup.MgvButton, {
      staticClass: "move",
      attrs: {
        icon: "icon-drag-dot",
        type: "text",
        color: "info",
        square: ""
      }
    }) : _vm._e(), _c("el-form-item", {
      staticClass: "label-form-item",
      attrs: {
        prop: "key"
      }
    }, [_c("el-input", {
      attrs: {
        maxlength: _vm.keyProps.maxlength,
        placeholder: _vm.keyProps.placeholder,
        disabled: item.isPreset,
        type: _vm.keyProps.inputType,
        resize: "none",
        autosize: {
          minRows: 1,
          maxRows: 10
        }
      },
      model: {
        value: item.key,
        callback: function ($$v) {
          _vm.$set(item, "key", $$v);
        },
        expression: "item.key"
      }
    })], 1), _c("el-form-item", {
      staticClass: "value-form-item",
      attrs: {
        prop: "value"
      }
    }, [_c("el-input", {
      attrs: {
        type: "textarea",
        resize: "none",
        autosize: {
          minRows: 1,
          maxRows: 10
        },
        maxlength: _vm.valueProps.maxlength,
        placeholder: _vm.valueProps.placeholder
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1), _setup.isShowRemoveButton(item) ? _c(_setup.MgvButton, {
      staticClass: "delete-button",
      attrs: {
        icon: "icon-delete",
        square: "",
        type: "text",
        color: "info"
      },
      on: {
        click: function ($event) {
          return _setup.removeField(item.id);
        }
      }
    }) : _vm._e()], 1);
  }), 1), _c(_setup.MgvButton, {
    staticClass: "add-button",
    attrs: {
      type: "text",
      icon: "icon-plus",
      size: "small",
      disabled: _vm.addDisabled
    },
    on: {
      click: _setup.addField
    }
  }, [_vm._v("添加" + _vm._s(_vm.fieldLabel))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;