"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _amountRangeMixin = _interopRequireDefault(require("./amountRangeMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AmountRangeSelect',
  mixins: [_amountRangeMixin.default]
};
exports.default = _default;