"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TencentCaptcha = _interopRequireDefault(require("TencentCaptcha"));
var _validate = require("@/utils/validate");
var _organization = require("@/api/organization");
var _user = require("@/api/user");
var _crypto = _interopRequireDefault(require("crypto"));
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _Password = _interopRequireDefault(require("@/components/Password"));
var _errorLog = require("@/utils/error-log");
var _auth = require("@/utils/auth");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Login',
  components: {
    verificationCodeInput: _VerificationCodeInput.default,
    Password: _Password.default
  },
  props: {
    type: {
      type: String,
      default: 'login'
    },
    arrays: {
      type: Array,
      default: function () {
        return [];
      }
    },
    teamInfo: {
      type: Object,
      default: function () {
        return {
          id: 0,
          name: '',
          code: ''
        };
      }
    }
  },
  data() {
    return {
      google: '',
      readChecked: true,
      // 是否阅读协议和隐私
      wrapperShow: true,
      currentMode: '',
      telForm: {
        tel: '',
        email: ''
      },
      loginForm: {
        tel: '',
        email: '',
        password: '',
        code: ''
      },
      codeForm: {
        tel: '',
        email: '',
        code: ''
      },
      loginRules: {
        tel: [{
          required: true,
          validator: _validate.internationalTelValidator,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: _validate.emailValidator,
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'change',
          validator: _validate.validatePasswordOnlyHas
        }]
      },
      codeRules: {
        tel: [{
          required: true,
          validator: _validate.internationalTelValidator,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: _validate.emailValidator,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          validator: _validate.codeValidator,
          trigger: 'change'
        }]
      },
      passLoading: false,
      codeLoading: false,
      redirect: undefined,
      // url上的重定向path
      query: undefined,
      // 重定向url携带的参数
      ssoError: false,
      // 单点登录是否有报错
      developerFromUrl: ['localhost', 'open.test.mgvai.cn', 'prepublish-open.megaview.com', 'open.megaview.com'],
      needResetPassword: false,
      loginMethod: 'tel',
      retryCount: 0 // 验证码报错后重试次数
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['isDeveloper'])
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
        this.query = route.query && route.query.query;
      },
      immediate: true
    },
    telForm: {
      handler: function (val) {
        this.loginForm = {
          ...this.loginForm,
          ...val
        };
        this.codeForm = {
          ...this.codeForm,
          ...val
        };
      },
      deep: true
    },
    currentMode(val) {
      this.wrapperShow = false;
      setTimeout(() => {
        this.wrapperShow = true;
      }, 200);
    }
  },
  created() {
    // 判断如果本地有嵌入页面的app信息，跳转到指定页面
    const iframeApp = localStorage.getItem('iframeApp');
    const iframeOriginId = localStorage.getItem('iframeOriginId');
    if (iframeApp && this.$route.meta.is_iframe) {
      if (iframeApp === 'neocrm') {
        // 销售易区别对待，直接访问新接口
        const user_id = localStorage.getItem('iframeUserId');
        this.isNeocrmLogin({
          origin_id: iframeOriginId,
          user_id
        });
      } else {
        this.trySsoLogin(iframeApp, iframeOriginId);
      }
    }
  },
  mounted() {
    window.tencentVerifyCallback = this.tencentVerifyCallback;
    this.initGoogleLogin();
    this.checkIsDeveloper();
  },
  methods: {
    getLoginTitle() {
      switch (this.currentMode) {
        case 'password':
          return '密码登录';
        case 'code':
          return this.loginMethod === 'tel' ? '手机验证码登录' : '邮箱验证码登录';
        case 'equalInsurance':
          return '输入密码及验证码';
        default:
          return '注册/登录';
      }
    },
    // 切换登录方式 手机号 or 邮箱
    handleChangeLoginMethod() {
      this.$refs.telForm.clearValidate();
      this.telForm = this.$options.data().telForm;
    },
    skip() {
      this.needResetPassword = false;
    },
    checkIsDeveloper() {
      const hostName = this.$route.query.redirect_uri && new URL(this.$route.query.redirect_uri).hostname;
      if (this.developerFromUrl.includes(hostName)) {
        this.$store.commit('user/SET_IS_DEVELOPER', true);
      }
    },
    initGoogleLogin() {
      if (!window.google) {
        return;
      }
      this.google = window.google;
      // eslint-disable-next-line no-undef
      this.google.accounts.id.initialize({
        client_id: this.$config.googleClientId,
        callback: this.handleCredentialResponse
      });
      // eslint-disable-next-line no-undef
      this.google.accounts.id.renderButton(document.getElementById('google-login-btn'), {
        theme: 'outline',
        size: 'large',
        logo_alignment: 'center',
        width: '320px',
        text: '使用 Google 登录'
      });
      // eslint-disable-next-line no-undef
      this.google.accounts.id.prompt();
    },
    handleCredentialResponse(response) {
      this.$store.dispatch('user/loginByEmail', {
        id_token: response.credential
      }).then(() => {
        this.handleOrgList();
      }).catch(error => {
        throw new Error(error);
      });
    },
    humanMachineCheck() {
      this.$refs['telForm'].validate(async valid => {
        if (valid && this.readChecked) {
          // 处理人机校验
          try {
            //  绑定一个元素
            // 验证码会读取dom上的`data-appid`和`data-cbfn`以及`data-biz-state`(可选)自动初始化
            // eslint-disable-next-line no-undef
            this.passwordTencentCaptcha = new _TencentCaptcha.default(document.getElementById('tencentCaptchaPassword'), this.$config.passCaptchaAppId, this.tencentVerifyCallback);
            this.passwordTencentCaptcha.show();
          } catch (err) {
            this.tencentCaptchaLoadErrorCallback(err);
          }
        }
      });
    },
    async checkEmail(ticket, randstr) {
      const email = this.loginForm.email;
      const data = {
        email,
        ticket,
        randstr
      };
      const res = await (0, _user.checkEmail)(data);
      if (res.code === 20000) {
        const equalInsurance = res.results.security_protection;
        if (equalInsurance) {
          this.currentMode = 'equalInsurance';
          this.needResetPassword = res.results.password_expired;
          return;
        }
        for (const key in localStorage) {
          if (key === email) {
            var loginMode = localStorage.getItem(key);
          }
        }
        if (loginMode === 'passwordLogin') {
          this.currentMode = 'password';
        } else {
          this.openCodeMode();
        }
      }
    },
    async checkTel(ticket, randstr) {
      var _res$results;
      const {
        tel
      } = this.loginForm;
      const data = {
        tel: tel,
        ticket: ticket,
        randstr: randstr
      };
      const res = await (0, _user.checkTel)(data);
      const equalInsurance = (_res$results = res.results) === null || _res$results === void 0 ? void 0 : _res$results.security_protection;
      if (equalInsurance) {
        this.currentMode = 'equalInsurance';
        this.needResetPassword = res.results.password_expired;
        return;
      }
      for (const key in localStorage) {
        if (key === tel) {
          var loginMode = localStorage.getItem(key);
        }
      }
      if (loginMode === 'passwordLogin') {
        this.openPasswordMode();
      } else {
        this.openCodeMode();
      }
    },
    handleFocus() {
      this.$refs.telForm.clearValidate();
    },
    openCodeMode() {
      this.currentMode = 'code';
      this.$refs.telForm.clearValidate();
      this.$refs.codeForm.clearValidate();
    },
    openPasswordMode() {
      this.currentMode = 'password';
    },
    goFirstStep() {
      this.$refs.codeInput.reset();
      this.$refs.telForm.clearValidate();
      this.$refs.codeForm.clearValidate();
      this.codeForm.code = '';
      this.currentMode = '';
      this.needResetPassword = false;
    },
    // 发送验证码
    sendCode() {
      this.$refs.codeInput.postSendCode();
    },
    // 密码登录 || 等保登录
    async postPasswordLoginRequest() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const {
            tel,
            password,
            code,
            email
          } = this.loginForm;
          const {
            data: rsaPass
          } = await this.Rsa.getRsaCode(password);
          const form = {
            tel,
            email,
            method: this.loginMethod,
            password: rsaPass
          };
          if (this.currentMode === 'equalInsurance') {
            form.code = code;
          }
          this.passLoading = true;
          this.$store.dispatch('user/login', {
            userInfo: form,
            currentMode: this.currentMode
          }).then(async () => {
            this.passLoading = false;
            if (this.loginMethod === 'tel') {
              localStorage.setItem(tel, 'passwordLogin');
            } else if (this.loginForm === 'email') {
              localStorage.setItem(email, 'passwordLogin');
            }
            this.handleOrgList();
          }).catch(() => {
            this.passLoading = false;
          });
        }
      });
    },
    // 验证码登录
    handleCodeLogin() {
      if (!this.codeForm.code) return;
      const {
        tel,
        email
      } = this.codeForm;
      const data = {
        ...this.codeForm,
        method: this.loginMethod
      };
      this.codeLoading = true;
      this.$store.dispatch('user/loginByCode', data).then(async () => {
        if (this.loginMethod === 'tel') {
          localStorage.setItem(tel, 'codeLogin');
        } else if (this.loginForm === 'email') {
          localStorage.setItem(email, 'codeLogin');
        }
        this.codeLoading = false;
        this.handleOrgList();
      }).catch(() => {
        this.codeLoading = false;
      });
    },
    // 单点登录
    async trySsoLogin(app, origin_id) {
      let code, state;
      if (!app) return;
      if (localStorage.getItem('iframeApp') === 'fxiaoke') {
        ({
          code,
          state
        } = this.$route.query);
      }
      if (localStorage.getItem('iframeApp') === 'authing') {
        state = localStorage.getItem('state');
        if (state) {
          code = this.$route.query.code;
        }
      }
      if (code && !state) {
        // 从应用进入
        this.handleSSOLogin(code, app, origin_id);
      } else if (code && state) {
        // 从iframe进入,第二步，单点登录
        this.handleSSOLogin(code, app, origin_id, state);
      } else {
        // 从iframe进入,第一步，先通过app和origin_id跳转外部网站，获取code和state
        const res = await (0, _user.getSSOURL)({
          app,
          origin_id
        });
        if (res.code === 200) {
          if (res.results.sso_url) {
            if (res.results.state) {
              localStorage.setItem('state', res.results.state);
            }
            window.location.href = res.results.sso_url;
          }
        }
      }
    },
    // 判断销售易是否登录
    async isNeocrmLogin(params) {
      const res = await (0, _user.isNeocrmLogin)(params);
      if (res.code === 200) {
        (0, _auth.setToken)(res.results.session);
        this.$store.commit('user/SET_TOKEN', res.results.session);
        this.$store.commit('user/SET_ID', res.results.user_id);
        this.$store.commit('user/SET_ORG_ID', res.results.organization_id);
        this.$router.push({
          path: _routeMap.ROUTES.iframeConversationList,
          query: {
            deal_code: localStorage.getItem('iframeDealCode')
          }
        });
      }
    },
    handleSSOLogin(code, app, origin_id, state) {
      if (code && app) {
        this.$store.dispatch('user/loginBySso', {
          code,
          app,
          origin_id,
          state
        }).then(async resolve => {
          (0, _auth.setToken)(resolve.results.session);
          this.$store.commit('user/SET_TOKEN', resolve.results.session);
          this.$store.commit('user/SET_ID', resolve.results.id);
          const res = await this.$store.dispatch('user/getInfo');
          this.$store.commit('user/SET_ORG_ID', res.organization_id);
          switch (app) {
            case 'authing':
              this.$router.push({
                path: _routeMap.ROUTES.chooseTeam
              });
              break;
            // eslint-disable-next-line no-fallthrough
            case 'fxiaoke':
              this.$router.push({
                path: _routeMap.ROUTES.iframeConversationList,
                query: {
                  deal_code: localStorage.getItem('iframeDealCode')
                }
              });
              break;
          }
        }).catch(() => {
          this.ssoError = true;
        });
      }
    },
    handleSSOError() {
      if (!this.ssoError) return;
      window.open(`${location.origin}${_routeMap.ROUTES.login}`);
    },
    handleOrgList() {
      const params = {
        is_developer: this.isDeveloper
      };
      (0, _user.getOrgList)(params).then(async res => {
        const organizations = res.results.organizations;
        const invitations = res.results.invitations;

        // 如果只有一个团队且没有邀请团队,则直接进入系统
        if ((invitations === null || invitations === void 0 ? void 0 : invitations.length) === 0 && (organizations === null || organizations === void 0 ? void 0 : organizations.length) === 1) {
          // RE:
          if (this.$route.query.redirect_uri) {
            if (this.$route.hash) {
              // 从分享链接进入帮助中心文档的重定向，需要给redirect_uri后面拼接 hash（即文章标题锚点）
              this.$route.query.redirect_uri += decodeURI(this.$route.hash);
            }
          }
          this.goOrg(organizations[0].organization_id);
        } else if (!(invitations !== null && invitations !== void 0 && invitations.length) && !(organizations !== null && organizations !== void 0 && organizations.length)) {
          this.$router.push({
            path: _routeMap.ROUTES.chooseTeam
          });
        } else {
          // 有多个团队或者有邀请团队则进入团队选择页
          if (this.$route.hash) {
            // 从分享链接进入帮助中心文档的重定向，需要给redirect_uri后面拼接 hash（即文章标题锚点）
            this.$route.query.redirect_uri += decodeURI(this.$route.hash);
          }
          let toPath = _routeMap.ROUTES.chooseTeam;
          if (this.$route.path.indexOf('xiaozhan') !== -1) {
            toPath = `/xiaozhan${_routeMap.ROUTES.chooseTeam}`;
          }
          this.$router.push({
            path: toPath,
            // RE:
            query: this.$route.query
          });
        }
        // 存本地判断是否用户只有一个团队,如果只有一个团队,导航栏不显示切换公司
        if (organizations.length < 2) {
          window.sessionStorage.setItem('isOnlyOneOrg', 'true');
        } else {
          window.sessionStorage.setItem('isOnlyOneOrg', '');
        }
      });
    },
    getCheckCreated() {
      return new Promise((resolve, reject) => {
        (0, _user.getCheckCreated)().then(res => {
          if (res.code === 200) {
            resolve(res.results.is_trail_exist);
          } else {
            resolve(false);
          }
        }).catch(err => {
          reject(err);
        });
      });
    },
    goOrg(id) {
      const postData = {
        organization_id: id,
        is_developer: this.isDeveloper
      };
      (0, _organization.switchOrg)(postData).then(res => {
        this.$store.commit('user/SET_ORG_ID', id);
        if (this.$route.query.redirect_uri) {
          location.href = this.$route.query.redirect_uri;
        } else {
          if (window.self !== window.top) {
            if (sessionStorage.getItem('embed') || this.$route.query.redirect) {
              this.$router.push({
                path: sessionStorage.getItem('embed') || this.$route.query.redirect || _routeMap.ROUTES.dashboard
              });
            } else {
              this.$router.push('/conversationList');
            }
          } else {
            this.$router.push({
              path: this.$route.query.redirect || _routeMap.ROUTES.dashboard
            });
          }
        }
      });
    },
    goReset() {
      this.$router.push({
        path: _routeMap.ROUTES.resetPassword
      });
    },
    md5Password(password) {
      var md5 = _crypto.default.createHash('md5');
      md5.update(password); // 需要加密的密码
      var md5Password = md5.digest('hex'); // password 加密完的密码
      return md5Password;
    },
    tencentVerifyCallback(res) {
      if (res.ticket) {
        if (this.loginMethod === 'tel') {
          this.checkTel(res.ticket, res.randstr);
        } else if (this.loginMethod === 'email') {
          this.checkEmail(res.ticket, res.randstr);
        }
        localStorage.setItem('loginMethod', this.loginMethod);
      }
      if (res.errorCode) {
        // eslint-disable-next-line no-undef
        if (this.retryCount < 3) {
          var _this$passwordTencent;
          (_this$passwordTencent = this.passwordTencentCaptcha) === null || _this$passwordTencent === void 0 ? void 0 : _this$passwordTencent.show();
          this.retryCount++;
        }
        (0, _errorLog.postErrorLog)(`tencentCaptchaLoadError:{code:${res.errorCode},message:${res.errorMessage}}`);
      } else {
        this.retryCount = 0;
      }
    },
    tencentCaptchaLoadErrorCallback(error) {
      this.$message.warning('验证码加载失败');
      (0, _errorLog.postErrorLog)('tencentCaptchaLoadError: 验证码js加载失败', error.stack);
    }
  }
};
exports.default = _default;