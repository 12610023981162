"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'ConversationItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isShowDeleteButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    salesInfo() {
      return this.item.participants.find(item => item.entity_type === 'host_salesman');
    },
    mediumIcon() {
      return {
        doc: 'icon-im-fill',
        audio: 'icon-phone-outline-fill',
        video: 'icon-video-camera-solid-2'
      }[this.item.conversation_type];
    }
  },
  methods: {
    getDuration: _commonFunc.getDuration,
    getDate(value) {
      return this.$plus8ToCurrentZone(value).format('yyyy-MM-DD HH:mm');
    }
  }
};
exports.default = _default;