"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.appLoading,
      expression: "appLoading"
    }],
    staticClass: "outer-layer"
  }, [_c("header", {
    staticClass: "app-management-header"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_vm._v("应用集成")]), _vm.modulePermission.manageDeveloper ? _c("div", {
    staticClass: "header-btn"
  }, [_c("span", {
    staticClass: "header-btn-text",
    on: {
      click: _vm.goMegaviewOpenPlatform
    }
  }, [_c("i", {
    staticClass: "iconfont icon-developer"
  }), _vm._v(" 进入开放平台 ")]), _c("span", {
    staticClass: "header-btn-text",
    on: {
      click: _vm.openDeveloperManagement
    }
  }, [_c("i", {
    staticClass: "iconfont icon-decisionmaker"
  }), _vm._v(" 开发者管理 ")])]) : _vm._e()]), _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "app-module-title"
  }, [_vm._v("应用集成")]), _vm.appList.length ? _c("div", [_c("article", {
    staticClass: "app-integration"
  }, [_vm._l(_vm.appList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "app-card"
    }, [_c("section", {
      staticClass: "left-part"
    }, [_c("img", {
      attrs: {
        src: item.url
      }
    })]), _c("section", {
      staticClass: "right-part"
    }, [_c("div", {
      staticClass: "status"
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: item.is_invalid ? "失效" : "正常",
        placement: "top"
      }
    }, [_c("img", {
      attrs: {
        src: item.is_invalid ? _vm.invalidIcon : _vm.normalIcon
      }
    })])], 1), _c("div", {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.nick_name) + " ")]), _c("div", {
      staticClass: "config"
    }, [_c("el-button", {
      staticClass: "config-btn",
      on: {
        click: function ($event) {
          return _vm.openDrawer(item);
        }
      }
    }, [_vm._v(" 配置 ")])], 1)])]);
  }), _c("el-drawer", {
    ref: "drawer",
    attrs: {
      title: "配置信息",
      visible: _vm.drawerVisible,
      direction: "rtl",
      "before-close": _vm.handleClose,
      size: "600px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.drawerLoading,
      expression: "drawerLoading"
    }],
    staticClass: "container"
  }, [_c("div", {
    staticClass: "app-logo"
  }, [_c("img", {
    attrs: {
      src: _vm.currentApp.logo,
      alt: ""
    }
  }), _c("span", [_vm._v(_vm._s(_vm.currentApp.name))])]), _vm.currentApp.name.includes("企业微信") ? _c("div", {
    staticClass: "tips"
  }, [_vm._v(" 除了会话存档授权，将会话分析结果显示到对应用户和客户还需要通讯录和客户联系的授权信息 ")]) : _vm._e(), _c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.desc,
      value: _vm.value,
      "is-edit": true,
      "is-show-image-tips": true
    },
    on: {
      input: _vm.handleInput
    }
  }), _vm.showKeySettings ? _c("key-settings", {
    ref: "privateKey",
    attrs: {
      "private-key-desc": _vm.privateKeyDesc,
      "private-key-value": _vm.privateKeyValue,
      "is-show-image-tips": true
    },
    on: {
      hasPrivateKeyChange: _vm.handleHasPrivateKeyChange,
      privateKeyChange: _vm.handlePrivateKeyChange
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "footer-btns"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDrawer
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      "native-type": "submit",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 更新 ")])], 1)])], 2)]) : !_vm.appListLoading ? _c("NoData", {
    staticClass: "no-data",
    attrs: {
      tips: "暂无集成应用"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "app-module-title private-app-title"
  }, [_vm._v("自建应用")]), _vm.privateAppList.length ? _c("div", [_c("article", {
    staticClass: "app-integration"
  }, _vm._l(_vm.privateAppList, function (item) {
    return _c("div", {
      key: item.app_key,
      staticClass: "app-card"
    }, [_c("section", {
      staticClass: "left-part"
    }, [_c("img", {
      attrs: {
        src: item.icon
      }
    })]), _c("section", {
      staticClass: "right-part"
    }, [_c("div", {
      staticClass: "status"
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "正常",
        placement: "top"
      }
    }, [_c("img", {
      attrs: {
        src: _vm.normalIcon
      }
    })])], 1), _c("div", {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("el-tooltip", {
      attrs: {
        content: "自建应用暂不可配置",
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "config"
    }, [_c("el-button", {
      staticClass: "config-btn private-app-config-btn",
      attrs: {
        disabled: !item.app_type
      }
    }, [_vm._v(" 配置 ")])], 1)])], 1)]);
  }), 0)]) : !_vm.privateAppListLoading ? _c("NoData", {
    staticClass: "no-data",
    attrs: {
      tips: "暂无自建应用"
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;