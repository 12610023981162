"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CollapseAvatar = _interopRequireDefault(require("@/components/CollapseAvatar"));
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _GradeProgressPopover = _interopRequireDefault(require("@/components/ConversationPageComp/GradeProgressPopover"));
var _orderBy = _interopRequireDefault(require("lodash/orderBy"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _ComplianceStatusTag = _interopRequireDefault(require("./ComplianceStatusTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceBaseTable',
  components: {
    CollapseAvatar: _CollapseAvatar.default,
    GradeProgressPopover: _GradeProgressPopover.default,
    ComplianceStatusTag: _ComplianceStatusTag.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    tableHeight: {
      type: String,
      default: '100%'
    },
    scoreRuleList: {
      type: Array,
      default: () => []
    },
    evaluationItemList: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({})
    },
    sortable: {
      type: Array,
      default: () => []
    },
    headerCellStyle: {
      type: [Function, Object],
      default: () => {
        return {
          backgroundColor: '#fff',
          borderBottom: '1px #E2E5E9 solid',
          padding: '8px auto',
          fontSize: '14px',
          color: '#576575'
        };
      }
    },
    cellStyle: {
      type: [Function, Object],
      default: () => {
        return {
          height: '72px',
          fontSize: '14px',
          color: '#2B323B'
        };
      }
    },
    tableHeader: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    isConvTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowTooltip: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission', 'workspaceInfo', 'userId']),
    scoreRuleHeader() {
      return this.scoreRuleList.map(item => {
        return {
          label: item.name,
          prop: 'compliance_score_' + item.id
        };
      });
    },
    evaluationItemHeader() {
      return this.evaluationItemList.map(item => {
        return {
          label: item.name,
          prop: 'compliance_evaluation_' + item.id,
          order: item.field_order
        };
      });
    }
  },
  methods: {
    handleRemove(row) {
      const noRemindAgain = localStorage.getItem(`removeConvNoRemindAgain-${this.userId}`);
      if (!noRemindAgain) {
        this.$confirm('<div>移除会话不会删除质检记录，移除的会话将移至“已移除记录”中，导出表格时不会导出已移除的会话。</div><div class="no-remind-content"><input type="checkbox" id="no-remind-checkbox">不再提示</div>', '确认移除该会话吗？', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '移除',
          cancelButtonText: '取消'
        }).then(() => {
          const checked = document.getElementById('no-remind-checkbox').checked;
          if (checked) {
            localStorage.setItem(`removeConvNoRemindAgain-${this.userId}`, true);
          }
          this.removeConv(row);
        }).catch(() => {
          document.getElementById('no-remind-checkbox').checked = false;
        });
      } else {
        this.removeConv(row);
      }
    },
    async removeConv(row) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: Number(this.$route.query.id) || row.task_id,
        conv_id: row.conv_id,
        is_remove: true
      };
      const res = await (0, _compliance.removeOrRestoreComplianceConv)(data);
      if (res.code === 20000) {
        this.$message.success('移除成功');
        this.$emit('refresh');
      }
    },
    getTaskStatus(status) {
      return ['进行中', '已完成', '已取消'][status - 1];
    },
    onMouseOver(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth;
      const content_weight = ev.clientWidth;
      if (ev_weight > content_weight) {
        this.isShowTooltip = true;
      } else {
        this.isShowTooltip = false;
      }
    },
    handleLabel(item) {
      return String(item.order).padStart(2, '0') + ` ${item.label}`;
    },
    tableRowClassName(_ref) {
      let {
        row
      } = _ref;
      if (row.highLight) {
        return 'high-light';
      }
    },
    formatText(options, fieldType) {
      if (fieldType === 8) {
        return options.map(item => {
          return `${item.name} ${item.score || 0}`;
        }).join(', ');
      } else {
        return options.map(item => item.name).join(', ');
      }
    },
    handleEvaluationRecord(evaluationRecord) {
      return (0, _orderBy.default)(evaluationRecord, 'field_order', 'asc');
    }
  }
};
exports.default = _default;