"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("div", {
    staticClass: "nav-left"
  }, [_vm.backButton && _vm.hasHistory ? _c("el-button", {
    staticClass: "goback-box hover-show-bg",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  })], 1) : _vm._e(), _vm._t("prefix"), _c("span", {
    staticClass: "page-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._t("suffix")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;