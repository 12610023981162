"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LazyTree',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    organizationIdOuter: {
      type: Number,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    isUnrelated: Boolean,
    loading: Boolean,
    isDisabled: Boolean,
    isSetCheckedRoot: Boolean,
    treeConfig: {
      type: Object,
      default: () => ({})
    },
    getMemberApiType: {
      // 请求的成员树是否是权限范围内的，默认是无权限的，返回所有组织架构成员
      type: String,
      default: '' // 默认为空，为无权限成员树。'data'为数据权限成员树。'manage'为管理部门范围内的成员树。
    },

    params: {
      type: [Object, String],
      default: () => ({})
    },
    isRepetitive: {
      // 是否为在一组件中循环多次使用，会影响数据请求，避免重复请求
      type: Boolean,
      default: false
    },
    isShowHaveDeletedMembers: {
      // 是否显示60天内离职成员
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeProps: {
        label: 'name',
        isLeaf: this.getIsLeaf,
        disabled: this.getDisabled
      },
      tree: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      checkedKeys: [],
      treeLoading: false,
      timer: null,
      loadList: [],
      // 加载队列
      currentLoadNode: null,
      id2NodeMap: new Map(),
      currentLoadingDistance: 200,
      getTree: this.getTreeDataFunction(),
      isCheckedLeft: false
    };
  },
  watch: {
    value(val) {
      // 删除默认选中根部门时清空defaultCheckedKeys
      if (this.isSetCheckedRoot && !val.length) {
        this.$refs.tree.setCheckedKeys([]);
      }
    }
  },
  created() {
    if (!this.isRepetitive) {
      this.getTreeData();
    }
  },
  methods: {
    getTreeDataFunction() {
      switch (this.getMemberApiType) {
        case 'data':
          return _user.getDataOrgMemberTree;
        case 'manage':
          return _user.getDepartmentAndMemberTree;
        default:
          return _user.getOrgMemberTree;
      }
    },
    async getTreeData() {
      this.loadList = [];
      this.treeLoading = true;
      try {
        const res = await this.getTree({
          tree_id: this.organizationIdOuter,
          ...this.params,
          includes_deleted_members: this.isShowHaveDeletedMembers,
          size: 9999
        });
        if (Array.isArray(res.results)) {
          this.tree = res.results;
          this.isSetCheckedRoot && this.setCheckedRoot([res.results[0].id]);
          if (res.results.length === 1) {
            this.defaultExpandedKeys = [res.results[0].id];
            this.loadList.unshift(res.results[0]);
          } else {
            this.treeLoading = false;
          }
        } else {
          this.tree = [res.results];
          this.defaultExpandedKeys = [res.results.id];
          this.loadList.unshift(res.results);
          this.isSetCheckedRoot && this.setCheckedRoot([res.results.id]);
        }
        this.$nextTick(() => {
          this.$emit('treeRendered');
        });
      } catch (error) {
        this.treeLoading = false;
      }
      this.loadData();
    },
    setCheckedRoot(keys) {
      this.defaultCheckedKeys = keys;
      this.$nextTick(() => {
        this.handleCheckChange(this.$refs.tree.getCheckedNodes()[0], true);
      });
    },
    isDepartment(id) {
      return /^tree/.test(id);
    },
    checkAll(ids) {
      let nodeKeys = [];
      const m = this.id2NodeMap;
      for (const id of ids) {
        if (m.has(id)) {
          nodeKeys = nodeKeys.concat(m.get(id).map(node => node.data));
        }
      }
      this.$refs.tree.setCheckedNodes(nodeKeys);
    },
    checkNode(key, isCheck) {
      if (this.id2NodeMap.has(key)) {
        // 保证获取的结果是一个数组
        const value = this.id2NodeMap.get(key);
        const arr = Array.isArray(value) ? value : [value];
        arr.forEach(node => {
          if (node.checked !== isCheck) {
            node.checked = isCheck;
          }
        });
      }
    },
    handleScroll(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.scrollLoad(e)) {
          this.loadData();
        }
      }, 300);
    },
    getLoadingDistance() {
      var _this$loadList$;
      const currentLoadDataId = (_this$loadList$ = this.loadList[0]) === null || _this$loadList$ === void 0 ? void 0 : _this$loadList$.id;
      if (!currentLoadDataId) return 400;
      const nodeDom = document.getElementById(currentLoadDataId).parentElement.parentElement;
      const tree = this.$refs.tree.$el;
      const d = tree.getBoundingClientRect().bottom - nodeDom.getBoundingClientRect().bottom;
      return d + 400;
    },
    scrollLoad(e) {
      const {
        offsetHeight,
        scrollTop,
        scrollHeight
      } = e.target;
      const flag = offsetHeight + scrollTop - scrollHeight >= -this.currentLoadingDistance;
      return flag;
    },
    handleCheckChange(data, isCheck) {
      if (this.treeConfig.checkIsExpanded) this.checkIsExpanded(data); // 创建和编辑剪辑库权限时，部门勾选后不可展开
      if (!this.treeConfig.noCheckDept && /^tree_id/.test(data.id)) return; // 创建和编辑剪辑库权限时，点击部门，input框要出现标签
      !this.isUnrelated && this.checkNode(this.getId(data.id), isCheck);
      this.$emit('checkChange', data, isCheck);
    },
    checkIsExpanded(data) {
      // 部门勾选后不可展开
      const node = this.$refs.tree.getNode(data);
      if (node.checked) {
        node.expanded = false;
        node.isLeaf = true;
      } else if (data.has_children) {
        node.isLeaf = false;
      }
    },
    getId(id) {
      // 创建和编辑剪辑库权限时 不过滤id
      if (this.treeConfig.noTransId || this.treeConfig.addDepartmentPre && id.indexOf('tree_id') !== -1) {
        return id;
      }
      return id.split('_').pop() - 0;
    },
    handleCheck(data, _ref) {
      let {
        checkedKeys,
        checkedNodes
      } = _ref;
      this.checkedKeys = checkedKeys;
    },
    getIsLeaf(data) {
      return !data.has_children;
    },
    getDisabled(data) {
      // 创建和编辑剪辑库权限时 不禁用部门选择
      if (this.treeConfig.noDisabled) {
        if (this.treeConfig.allowNode) {
          return !this.treeConfig.allowNode.includes(data.id);
        } else {
          return false;
        }
      }
      return this.isDisabled || /^tree_id/.test(data.id);
    },
    isIdEqualsItemId(itemId, id) {
      const itemIdArr = itemId.split('_');
      return !/^tree_id/.test(itemId) && itemIdArr[itemIdArr.length - 1] == id;
    },
    // 获取id值对应的NodeKeys数组
    getNodeKeys(id, nodeKeys, tree) {
      for (const item of tree) {
        if (this.isIdEqualsItemId(item.id, id)) {
          nodeKeys.push(item.id);
        }
        if (item.children && item.children.length) {
          this.getNodeKeys(id, nodeKeys, item.children);
        }
      }
    },
    handleNodeCollapse(data, node) {
      // 收起节点时收起所有子节点
      for (const item of node.childNodes) {
        item.expanded = false;
        this.loadList = this.loadList.filter(ele => ele.id !== item.data.id);
        if (item.childNodes.length) {
          this.handleNodeCollapse(data, item);
        }
      }
    },
    async handleNodeExpand(data, node) {
      // 控制节点的加载动画
      if (data.children && data.children.length) {
        data.children = [];
      }
      node.loading = true;
      this.loadList.unshift(data); // 压入加载队列
      await this.loadData();
      node.loading = false;
    },
    getNode(key) {
      return this.$refs.tree.getNode(key);
    },
    writeMap(childrens) {
      var _this$$refs$tree;
      const getNode = (_this$$refs$tree = this.$refs.tree) === null || _this$$refs$tree === void 0 ? void 0 : _this$$refs$tree.getNode;
      if (!getNode) return;
      const m = this.id2NodeMap;
      for (const item of childrens) {
        const node = getNode(item.id);
        if (!node) continue;
        const key = this.getId(node === null || node === void 0 ? void 0 : node.key);
        if (/^tree_id/.test(key)) {
          m.set(key, node);
        } else {
          if (m.has(key)) {
            m.get(key).push(node);
          } else {
            m.set(key, [node]);
          }
        }
        // 如果当前节点已被选择，则勾选
        if (this.value.includes(key)) {
          node.checked = true;
        }
      }
    },
    async loadData() {
      if (!this.loadList.length) return; // 没有要加载的数据了
      const data = this.loadList[0];
      if (!data.current_page) {
        var _data$children;
        data.current_page = (_data$children = data.children) !== null && _data$children !== void 0 && _data$children.length ? 2 : 1;
      }
      const {
        id
      } = data;
      const tree_id = id.replace('tree_id_', '');
      const params = {
        tree_id,
        page: data.current_page++,
        size: 9999,
        includes_deleted_members: this.isShowHaveDeletedMembers,
        ...this.params
      };
      const res = await this.getTree(params);
      if (data.children) {
        const firstDepartmentIndex = data.children.findIndex(_ref2 => {
          let {
            id
          } = _ref2;
          return this.isDepartment(id);
        });
        if (firstDepartmentIndex === -1) {
          data.children = data.children.concat(res.results.children);
        } else {
          data.children.splice(firstDepartmentIndex, 0, ...res.results.children);
        }
      } else {
        this.$set(data, 'children', res.results.children);
      }
      this.$nextTick(() => {
        this.writeMap([res.results].concat(res.results.children));
        this.treeLoading = false;
      });
      if (res.results.children.length < 30) {
        this.loadList.shift();
      }
    }
  }
};
exports.default = _default;