"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _user = require("@/api/user");
var _default = {
  __name: 'PromptTemplateLibraryFilters',
  emits: ['filterChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const store = (0, _useVuex.useStore)();
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const filter = (0, _vue.ref)({
      name: '',
      content: '',
      create_by: {}
    });
    const onChange = () => {
      emit('filterChange', filter.value);
    };
    const membersChange = val => {
      filter.value.create_by = val;
      onChange();
    };
    return {
      __sfc: true,
      store,
      workspaceInfo,
      filter,
      emit,
      onChange,
      membersChange,
      getUserList: _organization.getUserList,
      getOrgMemberTree: _user.getOrgMemberTree
    };
  }
};
exports.default = _default;