"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "layout-container"
  }, [_vm.isIosWexin ? _c("div", {
    staticClass: "tip-safari-open"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/videoRoomImg/safariTip.jpg"),
      alt: ""
    }
  })]) : _vm._e(), _c("transition", {
    attrs: {
      name: "el-fade-in-linear"
    }
  }, [!_vm.isIosWexin ? _c("div", {
    staticClass: "center-part"
  }, [!_vm.isIosWexin ? _c("div", {
    staticClass: "top-logo"
  }, [_vm.logoUrl ? _c("img", {
    attrs: {
      src: _vm.logoUrl,
      alt: "logo-image"
    }
  }) : _vm._e()]) : _vm._e(), !_vm.loading ? _c("div", {
    staticClass: "slot-wrapper"
  }, [_vm._t("default", null, {
    isTwoFactor: _vm.isTwoFactor
  })], 2) : _vm._e()]) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;