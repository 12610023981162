"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MgvTour',
  inheritAttrs: false,
  props: {
    // 唯一
    name: {
      type: String,
      default: ''
    },
    steps: {
      type: Array,
      default: () => []
    },
    // 了解更多按钮可配置，默认不展示
    knowMore: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    callbacks: {
      onStart: () => {},
      onPreviousStep: () => {},
      onNextStep: () => {},
      onStop: () => {},
      onSkip: () => {},
      onFinish: () => {},
      onMore: () => {}
    }
  },
  computed: {
    customSteps() {
      return this.steps.map(item => {
        const formatStepOption = {
          header: {
            title: item.title
          },
          content: item.content,
          target: item.target,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: item.placement || 'bottom'
          }
        };
        return formatStepOption;
      });
    }
  },
  methods: {
    start() {
      if (this.name && localStorage.getItem(this.name) !== 'true') {
        this.$tours[this.name].start();
      }
    },
    closeTour(tour) {
      this.$refs['v-tour'].finish();
    },
    handleFinish(tour) {
      localStorage.setItem(this.name, true);
      tour.finish();
    }
  }
};
exports.default = _default;