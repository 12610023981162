"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$tableData, _vm$tableData$;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "event-result-table",
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_vm.hasOperate ? _c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55",
      fixed: "left"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      prop: "event_engine.data",
      label: "模型预测句",
      "min-width": "350px",
      fixed: "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "sentence-container",
          on: {
            click: function ($event) {
              return _vm.clickModelSentence(row);
            }
          }
        }, _vm._l(row.sentence, function (sentence, index) {
          return _c("p", {
            key: index
          }, [_c("span", {
            class: ["character", {
              "is-sale": sentence.entity_type === "host_salesman"
            }]
          }, [_vm._v(_vm._s(sentence.entity_type === "host_salesman" ? "销售" : "客户") + "：")]), _c("span", [_vm._v(" " + _vm._s(sentence.content) + " ")])]);
        }), 0)];
      }
    }])
  }), (_vm$tableData = _vm.tableData) !== null && _vm$tableData !== void 0 && (_vm$tableData$ = _vm$tableData[0]) !== null && _vm$tableData$ !== void 0 && _vm$tableData$.model_predict_result ? _c("el-table-column", {
    attrs: {
      label: "模型原始结果",
      width: "180px"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180px",
      label: `命中${_vm.headerTargetCounts.model_predict_hit_count}个 未命中${_vm.headerTargetCounts.model_predict_miss_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("HitTag", {
          attrs: {
            status: row.model_predict_result
          }
        })];
      }
    }], null, false, 869682851)
  })], 1) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "模型预测结果"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180px",
      label: `命中${_vm.headerTargetCounts.predict_hit_count}个 未命中${_vm.headerTargetCounts.predict_miss_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("HitTag", {
          attrs: {
            status: row.predict_result
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "是否正确"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [row.rule_result === 1 ? _c("MgvTag", {
          attrs: {
            color: "success",
            icon: "iconfont icon-circle-check"
          }
        }, [_vm._v("正确")]) : _c("MgvTag", {
          attrs: {
            color: "error",
            icon: "iconfont icon-circle-close"
          }
        }, [_vm._v("错误")])];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "测试集标注结果",
      width: "490px"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "标注人",
      width: "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_c("MgvAvatarName", {
          attrs: {
            avatar: row.annotation_user_avatar,
            name: row.annotation_user
          }
        }), _c("span", [_vm._v(_vm._s(row.annotation_user))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "350px",
      label: `命中${_vm.headerTargetCounts.annotate_hit_count}个 未命中${_vm.headerTargetCounts.annotate_miss_count}个 不确定${_vm.headerTargetCounts.annotate_uncertain_count}个 未标注${_vm.headerTargetCounts.un_annotate_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("HitButtons", {
          attrs: {
            status: row.annotation_result
          },
          on: {
            change: function ($event) {
              return _vm.onChangeAnnotationTag($event, row);
            }
          }
        }), row.rule_result !== 1 ? _c("MgvButton", {
          staticClass: "modify-train-data-set-btn",
          attrs: {
            type: "text",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _vm.handleFixTrainData(row);
            }
          }
        }, [_vm._v("修正训练集")]) : _vm._e()];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;