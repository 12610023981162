"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MergeAppDataFilter',
  data() {
    return {
      filterParams: {
        merge_status: '',
        type: ''
      },
      mergeStatusOptions: [{
        value: 1,
        label: '未合并'
      }, {
        value: 2,
        label: '已合并'
      }],
      contactTypeOptions: [{
        value: 'wework',
        label: '企业微信联系人'
      }, {
        value: 'wechat',
        label: '微信联系人'
      }]
    };
  },
  watch: {
    filterParams: {
      handler: function (newVal) {
        if (newVal) {
          this.$emit('handleFilterChange', newVal);
        }
      },
      deep: true
    }
  },
  methods: {
    changeDepartment(id) {
      this.filterParams.departmentId = id;
    }
  }
};
exports.default = _default;