"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _default = {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      numList: 0,
      stages: [],
      loading: false
    };
  },
  computed: {},
  created() {},
  methods: {
    async getConfig() {
      const res = await (0, _businessIntelligence.getWinRateConfig)();
      if (res.code === 200) {
        const date = res.results.map(item => {
          item.rate = item.rate * 100;
          return item;
        });
        this.stages = date;
      }
    },
    async handleConfirm() {
      this.dialogVisible = false;
      const data = JSON.parse(JSON.stringify(this.stages)).map(item => {
        item.rate = item.rate / 100;
        return item;
      });
      const res = await (0, _businessIntelligence.editWinRateConfig)(data);
      if (res.code === 200) {
        this.$emit('predictSetting');
      }
    },
    openDialog() {
      this.getConfig();
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;