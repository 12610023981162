"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mgv-department-multi-select",
    class: {
      "is-disabled": _vm.disabled
    }
  }, [_c("el-popover", {
    ref: "optionsPopover",
    attrs: {
      placement: "bottom-start",
      "popper-class": _vm.popoverVisible ? "tree-select-popover collapase popover-show" : "tree-select-popover collapase",
      "visible-arrow": false,
      trigger: "click"
    },
    on: {
      "after-enter": function ($event) {
        return _vm.$refs["optionsPopover"].updatePopper();
      }
    },
    model: {
      value: _vm.popoverVisible,
      callback: function ($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c("ValueContainer", {
    ref: "value-container",
    attrs: {
      slot: "reference",
      "show-tag-count": _vm.showTagCount,
      placeholder: _vm.placeholder,
      "is-department": "",
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.selectionList,
      disabled: _vm.disabled
    },
    on: {
      removeItem: _vm.removeTag,
      clear: _vm.clear
    },
    slot: "reference",
    scopedSlots: _vm._u([{
      key: "value-list-item-prefix-icon",
      fn: function (_ref) {
        let {
          nodeData
        } = _ref;
        return [_vm._t("value-list-item-prefix-icon", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "value-list-item-suffix-description",
      fn: function (_ref2) {
        let {
          nodeData
        } = _ref2;
        return [_vm._t("value-list-item-suffix-description", null, {
          nodeData: nodeData
        })];
      }
    }], null, true)
  }), _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.hanldeClosePopover,
      expression: "hanldeClosePopover"
    }],
    ref: "tree-select-options-container",
    staticClass: "tree-select-options-container"
  }, [_c("SearchContainer", {
    attrs: {
      "is-department": "",
      "is-searching": _vm.isSearching,
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.selectionList,
      "search-options": _vm.searchOptions
    },
    on: {
      "update:isSearching": function ($event) {
        _vm.isSearching = $event;
      },
      "update:is-searching": function ($event) {
        _vm.isSearching = $event;
      },
      removeItem: _vm.removeTag,
      chekedItem: _vm.addTag,
      filterChange: _vm.getDepartmentTreeData
    },
    scopedSlots: _vm._u([{
      key: "value-list-item-prefix-icon",
      fn: function (_ref3) {
        let {
          nodeData
        } = _ref3;
        return [_vm._t("search-list-item-prefix-icon", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "value-list-item-suffix-description",
      fn: function (_ref4) {
        let {
          nodeData
        } = _ref4;
        return [_vm._t("search-list-item-suffix-description", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "search-list-empty",
      fn: function () {
        return [_vm._t("search-list-empty")];
      },
      proxy: true
    }], null, true)
  }), _c("DepartmentTree", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSearching,
      expression: "!isSearching"
    }],
    ref: "department-tree",
    attrs: {
      "popover-visible": _vm.popoverVisible,
      "request-params": _vm.requestParams,
      "request-method": _vm.requestMethod,
      "selection-list": _vm.selectionList,
      "set-checked-root-node": _vm.setCheckedRootNode
    },
    on: {
      checkedChange: _vm.treeCheckedChange
    },
    scopedSlots: _vm._u([{
      key: "tree-custom-node",
      fn: function (_ref5) {
        let {
          nodeData
        } = _ref5;
        return [_vm._t("tree-custom-node", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "tree-empty",
      fn: function () {
        return [_vm._t("tree-empty")];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;