"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EvaluateFormGenerator = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormGenerator"));
var _EvaluateFormWidget = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormWidget"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router.js");
var _routeMap = require("@/router/routeMap");
var _formMixin = _interopRequireDefault(require("@/components/DynamicForm/components/formMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EvaluatePreviewDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    noEditButton: {
      type: Boolean,
      default: false
    },
    schema: Object,
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const closeDialog = () => {
      emit('update:visible', false);
    };
    const editForm = () => {
      const routeData = router.resolve({
        path: _routeMap.ROUTES.complianceEditEvaluation,
        query: {
          type: 'edit',
          id: props.schema.id
        }
      });
      window.open(routeData.href, '_blank');
      closeDialog();
    };
    const formWidget = (0, _vue.ref)(null);
    const exportForm = () => {
      console.log('getFormData', formWidget.value.getFormData());
    };
    const clearForm = () => {
      console.log(formWidget.value.clearFormData());
    };
    const validateForm = () => {
      console.log('validateForm', formWidget.value.validateForm());
    };
    return {
      __sfc: true,
      props,
      router,
      emit,
      closeDialog,
      editForm,
      formWidget,
      exportForm,
      clearForm,
      validateForm,
      EvaluateFormWidget: _EvaluateFormWidget.default
    };
  }
};
exports.default = _default;