"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.webService = exports.request = exports.mockService = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _download = require("./download");
var _nanoid = require("nanoid");
var _interceptorHandlers = require("./interceptor-handlers");
var _websocket = _interopRequireDefault(require("@/utils/websocket"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function serializeFormData(data) {
  const formData = new FormData();
  if (data) {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i++) {
          // 假设这个数组对应的字段为box
          formData.append(`${key}`, data[key][i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    });
  }
  return formData;
}
function setCustomHeader(config) {
  if (_store.default.getters.token) {
    // 如果
    const {
      orgId,
      userId
    } = _store.default.getters;
    config.headers['X-Org-Id'] = orgId;
    config.headers['X-User-Id'] = userId;

    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['X-Token'] = (0, _auth.getToken)();
  } else if (_store.default.getters.userId) {
    config.headers['X-User-Id'] = _store.default.getters.userId;
  }
  config.headers['X-Trace-Id'] = (0, _nanoid.nanoid)();
}

// create an axios instance
const service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // baseURL: 'http://127.0.0.1:4523/m1/1472484-0-default', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

exports.request = service;
const webService = _axios.default.create({
  baseURL: '/webapi',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

exports.webService = webService;
const mockService = _axios.default.create({
  baseURL: '/server/index.php?s=/mock-path/5&path=/',
  timeout: 20000
});
exports.mockService = mockService;
const requestInterceptor = config => {
  // 处理post发送数组格式的文件
  if (config.method === 'post' && config.dataType !== 'json') {
    config.data = serializeFormData(config.data);
  }
  if (config.timeout) {
    config.timeout = config.timeout * 1000;
  }
  setCustomHeader(config);
  return config;
};

// 请求错误处理
const requestErrorHandler = error => {
  return Promise.reject(error);
};
const responseInterceptor = async response => {
  const res = response.data;
  const xNewSession = response.headers['x-new-session'];
  if (xNewSession) {
    (0, _auth.setToken)(xNewSession);
    _store.default.commit('user/SET_TOKEN', xNewSession);
    // 重新创建webSocket,销毁旧的webSocket，创建新的webSocket
    const oldWebSocket = _websocket.default.getInstance();
    oldWebSocket.close();
    const newWebSocket = _websocket.default.getInstance();
    newWebSocket.create();
  }
  // setToken()
  if (res instanceof Blob) {
    (0, _download.handleBlob)(response);
    return res;
  }
  return await _interceptorHandlers.responseHandler.run(response);
};
const responseErrorHandler = error => _interceptorHandlers.errorHandler.run(error);

// request interceptor
service.interceptors.request.use(requestInterceptor, requestErrorHandler);
service.interceptors.response.use(responseInterceptor, responseErrorHandler);
webService.interceptors.request.use(requestInterceptor, requestErrorHandler);
webService.interceptors.response.use(responseInterceptor, responseErrorHandler);
mockService.interceptors.request.use(requestInterceptor, requestErrorHandler);
mockService.interceptors.response.use(responseInterceptor, responseErrorHandler);