"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _setup.handleClose,
      width: "400px"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "dialog-title"
        }, [_c("span", {
          staticClass: "title-text"
        }, [_vm._v(_vm._s(_setup.t("management.查看邮箱验证码")) + " - " + _vm._s(_vm.rowInfo.name))])])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "dialog-body"
  }, [_c("div", {
    staticClass: "code"
  }, [_vm._v(_vm._s(_vm.loginCaptchaObj.code))]), _c("div", {
    staticClass: "expire-time"
  }, [_vm._v(" " + _vm._s(_setup.t("management.将于{0}过期", [_vm.$plus8ToCurrentZone(_vm.loginCaptchaObj.expire_at).format("YYYY-MM-DD HH:mm")])) + " ")])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.loginCaptchaObj.code,
      expression: "loginCaptchaObj.code",
      arg: "copy"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.copyCaptcha
    }
  }, [_vm._v(_vm._s(_setup.t("management.复制")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;