"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conversation-info-container"
  }, [_c(_setup.FieldEditForm, {
    attrs: {
      desc: _setup.formDesc,
      "is-edit": _vm.conversationDetailInfo.can_edit
    },
    on: {
      "field-change-succeed": _setup.onFieldChangeSucceed
    },
    scopedSlots: _vm._u([{
      key: "host_salesman",
      fn: function (_ref) {
        let {
          defaultValue
        } = _ref;
        return [_c("div", {
          staticClass: "avatar-name",
          class: {
            "can-edit": _setup.canChangeHost
          },
          on: {
            click: _setup.openTransfereeDialog
          }
        }, [_c("mgv-avatar-name", {
          attrs: {
            size: 24,
            src: defaultValue.avatar,
            name: defaultValue.name
          }
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(defaultValue.name))])], 1)];
      }
    }, {
      key: "tree_info",
      fn: function (_ref2) {
        let {
          defaultValue
        } = _ref2;
        return [_c("div", {
          staticClass: "avatar-name"
        }, [_c("mgv-avatar-name", {
          attrs: {
            icon: "iconfont icon-department",
            size: 24
          }
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(defaultValue.name))])], 1)];
      }
    }, {
      key: "workspace",
      fn: function (_ref3) {
        let {
          defaultValue
        } = _ref3;
        return [_c("div", {
          staticClass: "avatar-name",
          class: {
            "can-edit": _vm.conversationDetailInfo.can_edit
          },
          on: {
            click: _setup.openTransferWorkspace
          }
        }, [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: _vm.conversationDetailInfo.workspace.icon_color,
            "margin-right": "4px"
          }
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(defaultValue.name))])])];
      }
    }]),
    model: {
      value: _setup.formModel,
      callback: function ($$v) {
        _setup.formModel = $$v;
      },
      expression: "formModel"
    }
  }), _c(_setup.TransfereeDialog, {
    attrs: {
      "dialog-visible": _setup.transfereeDialogVisible,
      "conversation-name": _vm.conversationDetailInfo.name,
      "conversation-id": _vm.conversationDetailInfo.id,
      "sales-name": _vm.conversationDetailInfo.host_salesman.name
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.transfereeDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.transfereeDialogVisible = $event;
      },
      submitSucceed: _setup.onTransferSubmit
    }
  }), _c(_setup.TransferConversationDialog, {
    ref: "transferConversationDialog",
    attrs: {
      "dialog-visible": _setup.transferConversationDialogVisible,
      "workspace-item": _vm.conversationDetailInfo.workspace,
      "is-single": ""
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.transferConversationDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.transferConversationDialogVisible = $event;
      },
      submit: _setup.transferConversation
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;