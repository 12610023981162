"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _scoreCorrectionContentCard = _interopRequireDefault(require("./scoreCorrectionContentCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScoreCorrectionContent',
  components: {
    ScoreCorrectionContentCard: _scoreCorrectionContentCard.default
  },
  props: {
    scoreCorrectionContentList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleScoreChange(val) {
      this.$emit('scoreChange', val);
    }
  }
};
exports.default = _default;