"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SelectRole = _interopRequireDefault(require("@/components/SelectRole"));
var _FuncRoleDrawer = _interopRequireDefault(require("./components/FuncRoleDrawer"));
var _FuncTable = _interopRequireDefault(require("./components/FuncTable.vue"));
var _AddMemberDialog = _interopRequireDefault(require("./components/AddMemberDialog"));
var _OperateMemberDialog = _interopRequireDefault(require("./components/OperateMemberDialog"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    SelectRole: _SelectRole.default,
    FuncRoleDrawer: _FuncRoleDrawer.default,
    FuncTable: _FuncTable.default,
    AddMemberDialog: _AddMemberDialog.default,
    OperateMemberDialog: _OperateMemberDialog.default
  },
  props: {},
  data() {
    return {
      filterName: '',
      listParams: {
        page: 1,
        size: 10
      },
      total: 0,
      funcRoleDrawerVisible: false,
      addMemberDialogVisible: false,
      operateMemberDialogVisible: false,
      roleInfo: {},
      funcRoleList: [],
      allFuncRoleList: [],
      currentRoleInfo: {},
      operateRoleType: '',
      operateMemberType: '',
      roleLoading: false,
      multiMembersList: [],
      memberInfo: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getBusinessRoles();
  },
  methods: {
    openOperateMemberDialog(val) {
      this.operateMemberDialogVisible = true;
      this.operateMemberType = val.type;
      this.memberInfo = val.membersList ? val.membersList : {};
    },
    handleSelectionChange(val) {
      this.multiMembersList = val;
    },
    async fetchRoleList(roleInfo) {
      await this.getBusinessRoles();
      if (roleInfo) {
        if (this.roleInfo.id === roleInfo.id) {
          this.$refs.funcTable.getMembersList();
        } else {
          this.roleInfo = roleInfo;
          this.$refs.selectRole.scrollToCurrentRole();
        }
      }
    },
    fetchMemberData() {
      this.$refs.funcTable.getMembersList();
    },
    async getBusinessRoles(filter) {
      this.roleLoading = true;
      let res;
      if (filter) {
        res = await (0, _permissions.getBusinessRoles)({
          filter
        });
      } else {
        res = await (0, _permissions.getBusinessRoles)();
        this.allFuncRoleList = res.results.role_list;
      }
      this.roleLoading = false;
      if (res.code === 20000) {
        this.funcRoleList = res.results.role_list;
        if (!this.funcRoleList.length) return;
        if (JSON.stringify(this.roleInfo) === '{}') {
          this.roleInfo = this.funcRoleList[0];
        } else {
          const roleInfo = this.funcRoleList.find(item => item.id === this.roleInfo.id);
          if (roleInfo) {
            this.roleInfo = roleInfo;
          } else {
            this.roleInfo = this.funcRoleList[0];
          }
        }
      }
    },
    getRoleList(filter) {
      this.getBusinessRoles(filter);
    },
    openDialog() {
      this.addMemberDialogVisible = true;
    },
    switchRightPage(roleInfo) {
      this.$refs.funcTable.pageParams.page = 1;
      this.roleInfo = roleInfo;
    },
    openFuncRoleDrawer(roleInfo, type) {
      this.funcRoleDrawerVisible = true;
      this.currentRoleInfo = roleInfo;
      this.operateRoleType = type;
    },
    closeFuncRoleDrawer() {
      this.funcRoleDrawerVisible = false;
    }
  }
};
exports.default = _default;