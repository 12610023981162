"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String,
    value: {
      type: Array | String,
      default: () => []
    },
    actions: String
  },
  watch: {
    value: {
      handler(val) {
        this.fileList = Array.isArray(val) && val.length ? val : [];
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(["orgId", "userId"]),
    extraData() {
      return {
        lead_id: 11
      };
    },
    uploadHeaders() {
      return {
        "X-Org-Id": this.orgId,
        "X-User-Id": this.userId
      };
    }
  },
  data() {
    return {
      file: "",
      fileList: []
    };
  },
  methods: {
    handleBefore() {
      if (this.fileList.length) {
        this.$message.warning("只能上传一个文件");
        return false;
      }
    },
    getFileName(item) {
      const arr = item.split("/");
      return arr[arr.length - 1];
    },
    deleteFile() {
      this.handleChange("");
    },
    handleSuccess(response) {
      this.fileList = [response.results];
      this.handleChange(this.fileList);
    },
    downloadFile(href) {
      const a = document.createElement("a");
      a.href = href;
      a.click();
      a.remove();
    }
  }
};
exports.default = _default;