"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MgvAvatarName',
  components: {},
  props: {
    avatarType: {
      type: String,
      default: 'salesman'
    },
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 30
    }
  },
  computed: {
    nameStyle() {
      return {
        'font-size': this.size / 2 + 'px'
      };
    },
    avatarClass() {
      const {
        avatarType,
        src,
        icon
      } = this;
      const classList = [];
      classList.push('mgv-avatar');
      if (!src && !icon && avatarType) {
        classList.push(`${avatarType}-avatar`);
      }
      if (!src && icon) {
        classList.push(`icon-avatar`);
      }
      return classList.join(' ');
    }
  },
  methods: {
    handleName() {
      return this.name && this.name.slice(0, 1);
    }
  }
};
exports.default = _default;