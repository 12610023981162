"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _setup.handleClose,
      width: "400px"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "dialog-title"
        }, [_c("div", {
          staticClass: "dialog-title"
        }, [_c("div", {
          staticClass: "title-text"
        }, [_vm._v(_vm._s(_vm.$t("management.移动部门")))]), _c("div", {
          staticClass: "desc-text"
        }, [_vm._v(" " + _vm._s(_vm.$t("management.子部门和成员也将随之移动")) + " ")])])])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "dialog-body"
  }, [_c("div", {
    staticClass: "dialog-body__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.请选择移动到的部门")) + " ")]), _vm.visible ? _c(_setup.DepartmentSingleSelector, {
    attrs: {
      "request-method": _setup.getDepartmentWithAuth,
      "no-cache": "",
      placeholder: _vm.$t("management.请选择要移动后的父部门")
    },
    on: {
      change: _setup.onDepartmentChange
    }
  }) : _vm._e()], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_setup.newDepartmentPid
    },
    on: {
      click: _setup.moveDepartmentApi
    }
  }, [_vm._v(_vm._s(_vm.$t("management.确定")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;