"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fieldTypeMap = exports.cascaderDefaultOptions = void 0;
var _nanoid = require("nanoid");
const fieldTypeMap = [{
  id: 1,
  type: 'input',
  text: '单行文本',
  icon: 'Single-line-text'
}, {
  id: 2,
  type: 'textarea',
  text: '多行文本',
  icon: 'Multi-line-text'
}, {
  id: 5,
  type: 'number',
  text: '数字',
  icon: 'number'
}, {
  id: 3,
  type: 'radio',
  text: '单选',
  icon: 'radio'
}, {
  id: 4,
  type: 'checkbox',
  text: '多选',
  icon: 'checkbox'
}, {
  id: 8,
  type: 'cascader',
  text: '多级联动',
  icon: 'multistage'
}, {
  id: 6,
  type: 'date',
  text: '日期',
  icon: 'date'
}, {
  id: 7,
  type: 'datetime',
  text: '日期时间',
  icon: 'time'
}];
exports.fieldTypeMap = fieldTypeMap;
const cascaderDefaultOptions = [{
  name: '选项1',
  id: (0, _nanoid.nanoid)(),
  option_order: 1,
  score: '',
  is_active: true,
  children: [{
    name: '选项1-1',
    option_order: 1,
    id: (0, _nanoid.nanoid)(),
    score: '',
    children: []
  }, {
    name: '选项1-2',
    option_order: 2,
    id: (0, _nanoid.nanoid)(),
    score: '',
    children: []
  }]
}, {
  name: '选项2',
  option_order: 2,
  id: (0, _nanoid.nanoid)(),
  score: '',
  children: [{
    name: '选项2-1',
    option_order: 1,
    id: (0, _nanoid.nanoid)(),
    score: '',
    children: []
  }, {
    name: '选项2-2',
    option_order: 2,
    id: (0, _nanoid.nanoid)(),
    score: '',
    children: []
  }]
}, {
  name: '选项3',
  option_order: 3,
  id: (0, _nanoid.nanoid)(),
  score: '',
  children: []
}];
exports.cascaderDefaultOptions = cascaderDefaultOptions;