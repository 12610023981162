"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _KnowledgeQuestion = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeQuestion.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'IgnoreQuestionDrawer',
  components: {
    KnowledgeQuestion: _KnowledgeQuestion.default,
    NoData: _NoData.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    getIgnoreKnowledgeQuestionList: {
      type: Function,
      default: _knowledgeLibrary.getIgnoreKnowledgeQuestionList
    },
    editIgnoreStatus: {
      type: Function,
      default: _knowledgeLibrary.editIgnoreStatus
    }
  },
  data() {
    return {
      ignoreQuestionList: [],
      total: null,
      ignoreQuestionListLoading: false,
      queryParams: {
        page: 1,
        size: 10
      },
      dealAllCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id'() {
      this.init();
    },
    drawerVisible: {
      handler: function (val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.ignoreQuestionList = [];
      this.queryParams.page = 1;
      this.getIgnoreQuestionList();
    },
    async cancelIgnoreQuestion(val) {
      const res = await this.editIgnoreStatus({
        id: val.id,
        workspace_id: this.workspaceInfo.id,
        is_omit: false
      });
      if (res.code === 20000) {
        this.$message.success('操作成功');
        this.init();
      }
    },
    async getIgnoreQuestionList() {
      if (!this.workspaceInfo.id) {
        return;
      }
      this.ignoreQuestionListLoading = true;
      const res = await this.getIgnoreKnowledgeQuestionList({
        ...this.queryParams,
        space_id: this.workspaceInfo.id
      }).finally(() => {
        this.ignoreQuestionListLoading = false;
      });
      if (res.code === 20000) {
        const {
          count,
          data,
          deal_all_count
        } = res.results;
        this.ignoreQuestionList = this.ignoreQuestionList.concat(data);
        this.total = count;
        this.dealAllCount = deal_all_count;
        this.queryParams.page += 1;
      }
    }
  }
};
exports.default = _default;