"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dropdown", _vm._b({
    staticClass: "more-option-dropdown",
    attrs: {
      placement: "bottom-end",
      trigger: "click"
    },
    on: {
      command: function ($event) {
        return _vm.$emit("command", $event);
      }
    }
  }, "el-dropdown", _vm.$attrs, false), [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "conv-flow-operate-btn",
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-more"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "operate-dropdown",
    staticStyle: {
      "min-width": "182px"
    },
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.optionList, function (_ref) {
    let {
      type,
      icon,
      label
    } = _ref;
    return _c("el-dropdown-item", {
      key: type,
      attrs: {
        command: type,
        icon: `iconfont ${icon}`
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;