"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _setup.dialogVisible,
      width: "680px",
      "custom-class": "mgv-tour-dialog",
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _setup.dialogVisible = $event;
      }
    }
  }, [_vm._t("default"), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowMoreUrl,
      expression: "knowMoreUrl"
    }],
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: _setup.readMore
    }
  }, [_vm._v(" 了解更多")]), _c("MgvButton", {
    on: {
      click: _setup.confirmTour
    }
  }, [_vm._v("我知道了")])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;