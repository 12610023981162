"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "after-submit-card"
  }, [_c("i", {
    staticClass: "iconfont icon-success"
  }), _c("h2", [_vm._v("您的信息已提交，我们会尽快与您取得联络")]), _c("img", {
    attrs: {
      width: "128px",
      src: _vm.QRImg
    }
  }), _c("h3", [_vm._v("您也可以扫码加工作人员微信联系我们")]), _c("el-button", {
    staticClass: "back-btn",
    on: {
      click: _vm.backTo
    }
  }, [_vm._v("返回示例数据")]), _c("el-link", {
    attrs: {
      underline: false,
      type: "primary",
      href: "https://www.megaview.com",
      target: "blank"
    }
  }, [_vm._v("访问 Megaview 官网")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;