"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _x = require("@antv/x6");
var _vue = _interopRequireDefault(require("vue"));
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _NodeSidebar = _interopRequireDefault(require("./NodeSidebar"));
var _TalkConfigDrawer = _interopRequireDefault(require("./TalkConfigDrawer.vue"));
var _SearchScriptDialog = _interopRequireDefault(require("./SearchScriptDialog.vue"));
var _AddScriptDialog = _interopRequireDefault(require("./AddScriptDialog.vue"));
var _register = _interopRequireDefault(require("./utils/register.js"));
var _x6PluginDnd = require("@antv/x6-plugin-dnd");
var _x6PluginHistory = require("@antv/x6-plugin-history");
var _x6PluginSnapline = require("@antv/x6-plugin-snapline");
var _x6PluginKeyboard = require("@antv/x6-plugin-keyboard");
var _x6PluginSelection = require("@antv/x6-plugin-selection");
var _x6PluginScroller = require("@antv/x6-plugin-scroller");
var _x6PluginClipboard = require("@antv/x6-plugin-clipboard");
var _commonFunc = require("@/utils/commonFunc");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 拖拽插件
// 撤销重做插件
// 对齐线插件
// 快捷键插件
// 框选插件
// 滚动画布
// 复制粘贴

(0, _register.default)(); // 注册自定义图元
var _default = _vue.default.extend({
  name: 'DirectedGraph',
  components: {
    NodeSidebar: _NodeSidebar.default,
    TalkConfigDrawer: _TalkConfigDrawer.default,
    SearchScriptDialog: _SearchScriptDialog.default,
    AddScriptDialog: _AddScriptDialog.default
  },
  data() {
    return {
      graph: null,
      // 图实例
      dnd: null,
      // 拖拽实例
      activeNode: null,
      // 当前选中的节点
      talkConfigDrawerVisible: false,
      currentNodeData: {},
      graphElement: null,
      searchScriptDialogVisible: false,
      currentScript: '',
      isMultipleSelect: false,
      addScriptDialogVisible: false
    };
  },
  watch: {
    talkConfigDrawerVisible(val) {
      if (!val) {
        var _this$activeNode;
        (_this$activeNode = this.activeNode) === null || _this$activeNode === void 0 ? void 0 : _this$activeNode.setData({
          isActive: false
        });
        this.graph.resetSelection();
      }
    }
  },
  mounted() {
    this.initGraph();
    this.graphElement = document.getElementsByClassName('x6-graph-scroller')[0];
    // 设置为框选的多选模式
    document.addEventListener('mousedown', this.setMultipleSelect);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.setMultipleSelect);
  },
  methods: {
    // AI 生成要点
    async addAiScoreRule(node) {
      if (!this.graph.getCellById(node.id)) return;
      this.commonBatchUpdate({
        loading: true
      }, node.id);
      const res = await (0, _coachingCenter.createAiScoreRule)({
        course_id: Number(this.$route.query.id),
        card_id: node.id,
        card_text: node.branches[0].text
      }).finally(() => {
        this.commonBatchUpdate({
          loading: false
        }, node.id);
      });
      if (res.code === 20000) {
        const {
          finished_list,
          total_count
        } = res.results;
        if (total_count && finished_list) {
          const {
            id,
            gist_list
          } = finished_list[0];
          if (gist_list.length) {
            this.commonBatchUpdate({
              loading: false,
              scoreRules: gist_list
            }, id);
            this.$message.success(this.$t('coaching.generatedSuccessfully'));
          } else {
            this.$message.warning(this.$t('coaching.keyPointExtractionFailed'));
          }
        } else {
          this.commonBatchUpdate({
            loading: false
          }, node.id);
        }
      } else {
        this.commonBatchUpdate({
          loading: false
        }, node.id);
      }
    },
    // 更新单个销售说卡片的ai要点
    commonBatchUpdate(data) {
      let id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.currentNodeData.id;
      this.graph.getNodes().forEach(item => {
        if (item.id === id) {
          item.setData(data);
        }
      });
      if (this.currentNodeData.id === id) {
        this.graph.batchUpdate(() => {
          this.activeNode.updateData(data);
        });
        this.updataCurrentRoleData(this.activeNode.store.data);
      }
    },
    setMultipleSelect(e) {
      this.isMultipleSelect = e.shiftKey;
    },
    AddScriptThroughKnowledgeBase() {
      this.addScriptDialogVisible = true;
    },
    // 智能对练导入模板生成对应的节点卡片和连线
    constructdirectedGraphCardData(template) {
      const map = {
        [this.$t('coaching.start')]: 'start',
        [this.$t('coaching.salespersonSays')]: 'salesman',
        [this.$t('coaching.customerSays')]: 'customer',
        [this.$t('coaching.narration')]: 'narrator'
      };
      // 构造角色节点卡片
      const cards = template.map((item, i) => {
        // item 值类型为 [1,'旁白','你好']
        const shape = map[item[1]];
        const node = this.graph.createNode({
          shape
        });
        this.addDeleteCellToNode(node);
        this.addBranch(node);
        this.addDefaultRules(node);
        const data = node.store.data;
        data.position = item[3];
        // “旁白”内容上限100字，“客户说”内容上限300字，“销售说”内容上限1000字；
        const textLimit = shape === 'narrator' ? 500 : shape === 'customer' ? 500 : 1000;
        data.data.branches[0].text = item[2] ? String(item[2]).slice(0, textLimit) : '';
        data.zIndex = i + 1;
        if (shape === 'start') {
          data.ports.items = [{
            group: 'start',
            id: 'start'
          }];
        }
        return data;
      });
      // 构造连接线
      const edges = [];
      cards.forEach((item, index) => {
        if (index < cards.length - 1) {
          const edge = this.graph.createEdge({
            shape: 'custom-edge'
          });
          edge.store.data.zIndex = 0;
          edge.store.data.source = {
            cell: item.id,
            port: item.ports.items[0].id
          };
          edge.store.data.target = {
            cell: cards[index + 1].id
          };
          edges.push(edge.store.data);
        }
      });
      return cards.concat(edges);
    },
    renderSentenceListOnGraph(sentenceList) {
      const {
        cells
      } = this.graph.toJSON();
      const maxPositionX = Math.max.apply(null, cells.map(_ref => {
        let {
          position
        } = _ref;
        return (position === null || position === void 0 ? void 0 : position.x) || 0;
      }));
      // 只有开始节点
      const isOnlyStartNode = cells.length === 1 && cells[0].shape === 'start';
      isOnlyStartNode && sentenceList.unshift([0, this.$t('coaching.start'), this.$t('coaching.start')]);
      // 卡片位置计算
      sentenceList.forEach((item, index) => {
        item.push({
          x: maxPositionX + (index + 1) * 353,
          y: 60
        });
      });
      const importCells = this.constructdirectedGraphCardData(sentenceList);
      const cellsList = isOnlyStartNode ? importCells : cells.concat(importCells);
      // 画布渲染
      this.renderGraph({
        cells: cellsList
      });
      // 画布移动到导入数据的第一个并高亮该卡片
      setTimeout(() => {
        this.focusOnNode(importCells.find(_ref2 => {
          let {
            type
          } = _ref2;
          return type === 'node';
        }).id);
      }, 10 * cellsList.length);
      this.triggerSave();
    },
    // 销售说添加话术
    addScript(val) {
      this.currentNodeData.branches[0].text = val;
      this.$nextTick(() => {
        this.$refs['talk-config-drawer'].$refs['text-textarea'][0].$refs['textarea'].scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
        this.$refs['talk-config-drawer'].updateRoleConfigData();
      });
    },
    searchScript(val) {
      this.searchScriptDialogVisible = true;
      this.currentScript = val;
    },
    // 初始化图
    initGraph() {
      const _this = this;
      this.graph = new _x.Graph({
        container: this.$refs.graph,
        autoResize: true,
        // 自动更新画布大小
        background: {
          color: _variablesModule.default.graphBg
        },
        // ctrl + 鼠标滚轮 画布缩放
        mousewheel: {
          enabled: true,
          modifiers: ['ctrl', 'meta']
        },
        connecting: {
          // 连线配置
          snap: {
            // 连线吸附
            radius: 140
          },
          allowBlank: false,
          // 是否允许连接到画布空白位置
          allowEdge: false,
          // 是否允许连接到边
          allowMulti: 'AllowMulti',
          // 是否允许多条连线
          targetAnchor: 'left',
          connector: {
            name: 'smooth'
          },
          createEdge() {
            // 自定义连线
            return _this.graph.createEdge({
              shape: 'custom-edge'
            });
          },
          // 当停止拖动边的时候根据 validateEdge 返回值来判断边是否生效，如果返回 false, 该边会被清除
          validateEdge(_ref3) {
            let {
              edge
            } = _ref3;
            //  多线连接的目标卡片类型只能是销售说  & 一个端口最多连接5条线
            const isCorrectTargetCell = _this.validateCardConnection(_this, edge);
            if (!isCorrectTargetCell) {
              _this.$message({
                message: _this.$t('coaching.validateEdgeMessage'),
                duration: 5000
              });
            }
            return isCorrectTargetCell;
          },
          // 连线过程校验
          validateConnection(_ref4) {
            let {
              targetPort,
              sourceCell,
              targetCell,
              sourcePort
            } = _ref4;
            const isTargetPort = !!targetPort; // 是否连接到端口
            const isConnectSelf = sourceCell === targetCell; // 是否连接到自己
            const isConnectStart = targetCell.shape === 'start'; // 是否连接到开始节点
            // 不能重复连线
            const isRepeatEdge = _this.graph.getEdges().find(edge => edge.getTargetCellId() === targetCell.store.data.id && edge.getSourcePortId() === sourcePort);
            return !(isTargetPort || isConnectSelf || isConnectStart || isRepeatEdge);
          }
        }
      });
      this.graph.addNode({
        // 添加开始节点
        shape: 'start',
        x: 350,
        y: 60
      });
      this.addDnd(); // 添加拖拽
      this.graph.use(new _x6PluginHistory.History({
        enabled: true,
        beforeAddCommand(e, _ref5) {
          let {
            key
          } = _ref5;
          return !['data', 'ports', 'size', 'attrs'].includes(key);
        }
      })); // 添加撤销重做
      this.graph.use(new _x6PluginSnapline.Snapline({
        enabled: true
      })); // 添加对齐线
      this.graph.use(new _x6PluginSelection.Selection({
        className: 'selection-cell',
        enabled: true,
        multiple: true,
        // 是否启用点击多选
        multipleSelectionModifiers: ['shift'],
        // 用于设置上面点击多选配套的修饰键
        rubberband: true,
        // 是否启用框选节点功能
        movable: true,
        // 拖动选框时框选的节点是否一起移动
        modifiers: ['shift'],
        // 用于设置框选配套的修饰键
        filter: ['salesman', 'narrator', 'customer', 'custom-edge'] // 节点过滤器(过滤掉开始节点)
      })); // 框选
      this.graph.use(new _x6PluginScroller.Scroller({
        enabled: true,
        pannable: true
      })); // 滚动画布, 开启画布平移
      this.graph.use(new _x6PluginClipboard.Clipboard({
        enabled: true
      })); // 复制粘贴
      this.addCellChange();
      this.addNodeChecked(); // 添加框选事件
      this.addNodeUnChecked(); // 添加取消框选事件
      this.addEdgeChecked(); // 添加连线选中事件
      this.addNodeClickEvent(); // 添加节点点击事件
      this.graph.use(new _x6PluginKeyboard.Keyboard({
        enabled: true
      })); // 快捷键
      // 撤销快捷键
      this.graph.bindKey(['ctrl+z', 'meta+z'], () => {
        if (this.graph.canUndo()) {
          this.graph.undo();
          this.triggerSave();
        }
      });
      // 重做快捷键
      this.graph.bindKey(['ctrl+shift+z', 'meta+shift+z'], () => {
        if (this.graph.canRedo()) {
          this.graph.redo();
          this.triggerSave();
        }
      });
      // 删除快捷键
      this.graph.bindKey(['backspace', 'delete'], this.deleteCell);
      // 复制快捷键
      this.graph.bindKey(['ctrl+c', 'meta+c'], this.copyCell);
      // 粘贴快捷键
      this.graph.bindKey(['ctrl+v', 'meta+v'], this.pasteCell);
    },
    // 校验卡片是否可连接
    validateCardConnection(_this, currentEdge) {
      // 一个端口最多连接5条线 & 多线连接的目标卡片类型只能是销售说
      const edges = _this.graph.getEdges();
      // 当前端口的所有连接线
      const sourcePortEdges = edges.filter(edge => edge.getSourcePortId() === currentEdge.getSourcePortId());
      if (sourcePortEdges.length > 5) return false;
      if (sourcePortEdges.length > 1) {
        // 多次连接的目标卡片只能是销售说
        if (currentEdge.getTargetNode().store.data.shape !== 'salesman') {
          return false;
        }
        const portTargetCells = sourcePortEdges.map(edge => edge.getTargetNode()).filter(item => item);
        return portTargetCells.every(cell => cell.store.data.shape === 'salesman');
      }
      return true;
    },
    addDeleteCellToNode(node) {
      node.setData({
        deleteCell: this.deleteCell
      });
    },
    deleteCell() {
      var _cells$, _cells$$store, _cells$$store$data;
      const cells = this.graph.getSelectedCells();
      // 开始节点不可删除
      if (cells.length && ((_cells$ = cells[0]) === null || _cells$ === void 0 ? void 0 : (_cells$$store = _cells$.store) === null || _cells$$store === void 0 ? void 0 : (_cells$$store$data = _cells$$store.data) === null || _cells$$store$data === void 0 ? void 0 : _cells$$store$data.shape) !== 'start') {
        this.graph.removeCells(cells);
        this.triggerSave();
      }
    },
    getPureCards(cells) {
      return cells.filter(item => {
        var _item$store, _item$store$data;
        return (item === null || item === void 0 ? void 0 : (_item$store = item.store) === null || _item$store === void 0 ? void 0 : (_item$store$data = _item$store.data) === null || _item$store$data === void 0 ? void 0 : _item$store$data.shape) !== 'custom-edge';
      });
    },
    copyCell() {
      const cells = this.getPureCards(this.graph.getSelectedCells());
      if (cells !== null && cells !== void 0 && cells.length) {
        this.graph.copy(cells, {
          offset: {
            dx: 0,
            dy: 200
          }
        });
        this.$message.success(this.$t('coaching.copySuccessfully'));
      } else {
        this.$message.info(this.$t('coaching.cannotCopyInfo'));
      }
    },
    createCopyCells() {
      const cells = this.getPureCards(this.graph.getCellsInClipboard());
      if (cells !== null && cells !== void 0 && cells.length) {
        // 根据复制的卡片信息生成新的卡片
        const copyCells = cells.map(cell => {
          const copyData = cell.store.data;
          const {
            shape
          } = copyData;
          // 生成节点卡片
          const node = this.graph.createNode({
            shape
          });
          this.addDeleteCellToNode(node);
          // 批量修改，否则history会记录多次，导致撤销时出错
          this.graph.batchUpdate(() => {
            // 复制分支
            copyData.data.branches.forEach(cell => {
              this.addBranch(node, cell);
            });
            // 复制销售说的规则并移除话术的桩
            if (copyData.data.ruleType) {
              var _copyData$data$rules;
              (_copyData$data$rules = copyData.data.rules) === null || _copyData$data$rules === void 0 ? void 0 : _copyData$data$rules.forEach(copyRule => {
                this.addRule(node, '', copyRule);
              });
              // 按条件跳转: 删除句子上的连接桩，给每个规则新增连接桩
              const branchPorts = node.port.ports.find(item => item.group === 'branch');
              branchPorts && node.removePort(branchPorts.id);
            }
          });
          const {
            scoreRuleType,
            scoreRules,
            ruleType
          } = copyData.data;
          if (scoreRuleType) {
            node.store.data.data.scoreRuleType = scoreRuleType;
            node.store.data.data.scoreRules = scoreRules;
          }
          node.store.data.data.ruleType = ruleType;
          node.store.data.position = copyData.position;
          return node;
        });
        this.graph.copy(copyCells, {
          offset: {
            dx: 0,
            dy: 200
          }
        });
      }
    },
    pasteCell() {
      this.createCopyCells();
      if (this.graph.isClipboardEmpty()) {
        this.$message.info(this.$t('coaching.cannotPasteInfo'));
      } else {
        const cells = this.graph.paste({
          offset: {
            dx: 0,
            dy: 200
          }
        });
        this.graph.cleanSelection();
        this.graph.select(cells);
        this.$message.success(this.$t('coaching.pasteSuccessfully'));
      }
    },
    // 渲染已有配置
    renderGraph(data) {
      this.graph.fromJSON(data);
      const nodes = this.graph.getNodes();
      nodes.forEach(node => {
        this.addDeleteCellToNode(node);
      });
      this.clearAllhighLight();
    },
    // 清空所有高亮
    clearAllhighLight() {
      this.graph.getNodes().forEach(item => {
        item.setData({
          isActive: false
        });
      });
      this.graph.getEdges().forEach(item => {
        item.attr('line/stroke', '#C4CBD4');
      });
    },
    // 添加拖拽
    addDnd() {
      this.dnd = new _x6PluginDnd.Dnd({
        target: this.graph,
        dndContainer: this.$refs['dnd-container'].$el
      });
    },
    // 添加框选事件
    addNodeChecked() {
      this.graph.on('node:selected', _ref6 => {
        let {
          node
        } = _ref6;
        node.setData({
          isActive: true
        });
      });
    },
    // 添加取消框选事件
    addNodeUnChecked() {
      this.graph.on('node:unselected', _ref7 => {
        let {
          node
        } = _ref7;
        this.clearAllhighLight();
        this.talkConfigDrawerVisible = false;
      });
    },
    // 添加边框选事件
    addEdgeChecked() {
      this.graph.on('edge:selected', _ref8 => {
        let {
          edge
        } = _ref8;
        console.log(edge, 'edge');
        edge.attr('line/stroke', '#4461ec');
        edge.toFront();
      });
      this.graph.on('edge:unselected', _ref9 => {
        let {
          edge
        } = _ref9;
        edge.attr('line/stroke', '#C4CBD4');
        edge.toBack();
      });
    },
    // 监听所有变化
    addCellChange() {
      // 所有变化都会触发此事件，可以用于实时保存，记得防抖防抖防抖
      this.graph.on('cell:change:*', (0, _commonFunc.debounce)(() => {
        this.triggerSave();
      }, 1000));
      this.graph.on('node:added', (0, _commonFunc.debounce)(() => {
        this.triggerSave();
      }, 1000));
    },
    triggerSave() {
      this.$emit('submitGraph', JSON.stringify(this.graph.toJSON().cells));
    },
    // 添加节点点击事件
    addNodeClickEvent() {
      this.graph.on('node:click', _ref10 => {
        let {
          node
        } = _ref10;
        if (this.isMultipleSelect) return;
        this.activeNode = node;
        this.clearAllhighLight();
        this.activeNode.setData({
          isActive: true
        });
        const roleData = node.store.data.data;
        if (roleData.isActive && roleData.role) {
          this.talkConfigDrawerVisible = true;
          this.updataCurrentRoleData(node.store.data);
        }
      });
    },
    createNewBranch(node, copyCell) {
      const portList = node.getPortsByGroup('branch');
      let newBranch = null;
      if (node.store.data.shape === 'customer') {
        newBranch = {
          id: portList[portList.length - 1].id,
          text: (copyCell === null || copyCell === void 0 ? void 0 : copyCell.text) || '',
          weight: (copyCell === null || copyCell === void 0 ? void 0 : copyCell.weight) || 1
        };
      } else {
        newBranch = {
          id: portList[portList.length - 1].id,
          text: (copyCell === null || copyCell === void 0 ? void 0 : copyCell.text) || ''
        };
      }
      return newBranch;
    },
    // 添加一句话
    addBranch() {
      let node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.activeNode;
      let copyCell = arguments.length > 1 ? arguments[1] : undefined;
      const {
        branches
      } = node.getData();
      // 批量修改，否则history会记录多次，导致撤销时出错
      this.graph.batchUpdate(() => {
        node.addPort({
          group: 'branch',
          args: {
            x: 240,
            y: 96 + 56 * branches.length
          }
        });
        if (branches.length) {
          const size = node.size();
          node.size(size.width, size.height + 56);
        }
        // 只能通过setData修改data，否则history不记录
        const newBranch = this.createNewBranch(node, copyCell);
        node.setData({
          branches: [...branches, newBranch]
        });
        !copyCell && this.updataCurrentRoleData(node.store.data);
      });
    },
    // 删除一句话
    removeBranch() {
      let node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.activeNode;
      let id = arguments.length > 1 ? arguments[1] : undefined;
      const {
        branches
      } = node.getData();
      this.graph.batchUpdate(() => {
        node.removePort(id);
        node.updateData({
          branches: branches.filter(item => item.id !== id)
        });
      });
      this.updataCurrentRoleData(node.store.data);
      this.$nextTick(() => {
        this.updatePortPosition();
      });
    },
    addRule() {
      let node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.activeNode;
      let data = arguments.length > 1 ? arguments[1] : undefined;
      let copyCell = arguments.length > 2 ? arguments[2] : undefined;
      let {
        rules
      } = node.getData();
      // 批量修改，否则history会记录多次，导致撤销时出错
      this.graph.batchUpdate(() => {
        var _portList;
        node.addPort({
          group: 'rule',
          args: {
            x: 240,
            y: 96 + 56 * (rules.length + 1)
          }
        });
        const portList = node.getPortsByGroup('rule');
        const createRule = data || {
          id: (_portList = portList[portList.length - 1]) === null || _portList === void 0 ? void 0 : _portList.id,
          type: (copyCell === null || copyCell === void 0 ? void 0 : copyCell.type) || 0,
          keyword: (copyCell === null || copyCell === void 0 ? void 0 : copyCell.keyword) || []
        };
        // 复制操作
        if (copyCell) {
          node.setData({
            rules: [...rules, createRule]
          });
        } else {
          // 如果存在"以上条件都不满足" 类型时：需要始终保持该项为最后一项
          const lastRule = rules.find(item => item.type === 2);
          rules = rules.filter(item => item.type !== 2);
          rules = lastRule ? [...rules, createRule, lastRule] : [...rules, createRule];
          if (data) {
            rules.forEach((item, index) => {
              item.id = portList[index].id;
            });
          }
          node.setData({
            rules
          });
          if (rules.length) {
            const size = node.size();
            node.size(size.width, 96 + 40 + (rules.length + 1) * 56); // 第一个句子的40 + 新增一条规则的56
          }

          if (!data) {
            // 拿着rule的id对port全部重新计算位置
            this.updatePortPosition(undefined, 'rules');
          }
          this.updataCurrentRoleData(node.store.data);
        }
      });
    },
    deleteRule(id) {
      const {
        rules
      } = this.activeNode.getData();
      this.graph.batchUpdate(() => {
        this.activeNode.removePort(id);
        this.activeNode.updateData({
          rules: rules.filter(item => item.id !== id)
        });
      });
      this.updataCurrentRoleData(this.activeNode.store.data);
      this.$nextTick(() => {
        this.updatePortPosition(undefined, 'rules');
      });
    },
    toggleRuleType(ruleType) {
      const {
        branches,
        rules
      } = this.activeNode.getData();
      const size = this.activeNode.size();
      this.graph.batchUpdate(() => {
        this.activeNode.updateData({
          ruleType
        });
        if (ruleType) {
          if (!rules.length) {
            rules.push({
              type: 0,
              keyword: []
            }, {
              type: 2,
              keyword: []
            });
          }
          // 按条件跳转: 删除句子上的连接桩，给每个规则新增连接桩
          const branchPorts = this.activeNode.port.ports.find(item => item.group === 'branch');
          branchPorts && this.activeNode.removePort(branchPorts.id);
          // 保存当前规则数据，然后将角色卡片中规则和规则连接桩都删掉重新生成
          this.activeNode.updateData({
            rules: []
          });
          rules.forEach(item => {
            this.addRule(undefined, item);
          });
          this.activeNode.size(size.width, 96 + 40 + rules.length * 56);
        } else {
          // 正常往后跳转：删除每个规则上的连接桩，给句子新增连接桩
          const rulePorts = this.activeNode.port.ports.filter(item => item.group === 'rule');
          rulePorts.forEach(item => {
            this.activeNode.removePort(item.id);
          });
          // 注意此处句子id和句子连接桩id的对应关系
          const branch = branches[0];
          this.activeNode.addPort({
            group: 'branch',
            id: branch.id,
            args: {
              x: 240,
              y: 96
            }
          });
          this.activeNode.size(size.width, 96 + 40);
        }
      });
    },
    // 更新端口位置
    updatePortPosition() {
      var _node$getData;
      let node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.activeNode;
      let protType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'branches';
      const nodeData = (_node$getData = node.getData()) === null || _node$getData === void 0 ? void 0 : _node$getData[protType];
      this.graph.batchUpdate(() => {
        const size = node.size();
        node.size(size.width, size.height - 56);
      });
      nodeData.forEach((item, index) => {
        node.setPortProp(item.id, 'args', {
          x: 240,
          y: 96 + 56 * (index + (protType === 'branches' ? 0 : 1))
        });
      });
    },
    // 更新节点数据
    updateRoleNodeData(data) {
      if (!this.activeNode) return;
      this.graph.batchUpdate(() => {
        this.activeNode.updateData(data);
      });
      this.updataCurrentRoleData(this.activeNode.store.data);
    },
    updataCurrentRoleData(data) {
      this.currentNodeData = {
        ...data.data,
        id: data.id
      };
    },
    onNodeMouseDown(type, e) {
      this.graph.resetSelection();
      this.activeNode = null;
      const node = this.graph.createNode({
        shape: type
      });
      this.addDeleteCellToNode(node);
      this.addBranch(node);
      this.addDefaultRules(node);
      this.dnd.start(node, e);
    },
    addDefaultRules(node) {
      // 销售节点创建时就要加入规则数据
      if (node.store.data.shape === 'salesman') {
        // 以上条件都不满足时：不可删除项
        // // 添加的默认空条件项目
        node.setData({
          rules: [{
            type: 0,
            keyword: []
          }, {
            type: 2,
            keyword: []
          }]
        });
      }
    },
    focusOnNodeOrEdge(data) {
      // 对话测试时，连接多个销售说卡片时，高亮卡片连线，否则高亮卡片
      // 后端返回需要高亮的id数组，数组只有一项时是卡片id，否则是连线id
      if (Array.isArray(data)) {
        const isAllEdges = data.every(edgeId => this.graph.isEdge(this.graph.getCellById(edgeId)));
        if (isAllEdges) {
          this.focusOnEdge(data);
        } else {
          this.focusOnNode(data[0]);
        }
      } else {
        this.focusOnNode(data);
      }
    },
    focusOnEdge(data) {
      data.forEach(edgeId => {
        const edge = this.graph.getCellById(edgeId);
        edge.attr('line/stroke', '#4461ec');
      });
    },
    focusOnNode(nodeId) {
      const node = this.graph.getCellById(nodeId);
      if (!node) return;
      this.graph.zoomTo(1);
      this.clearAllhighLight();
      const {
        x,
        y
      } = node.position();
      const {
        width,
        height
      } = node.size();
      this.graphElement.scrollTo({
        left: this.graph.options.x + this.graphElement.clientWidth / 2 + x + width / 2,
        top: this.graph.options.y + this.graphElement.clientHeight / 2 + y + height / 2,
        behavior: 'smooth'
      });
      node.updateData({
        isActive: true
      });
      // this.graph.centerCell(node);
    },

    centerContent() {
      this.graph.zoomTo(1);
      this.graph.centerContent();
    },
    toJSON() {
      console.log(this.graph.toJSON());
    },
    undo() {
      this.graph.undo();
    },
    redo() {
      this.graph.redo();
    }
  }
});
exports.default = _default;