"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Compliance',
  components: {
    MenuToggleBtn: _MenuToggleBtn.default,
    SpaceButton: _SpaceButton.default
  },
  data() {
    return {
      isHiddenMenu: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree', 'workspaceInfo']),
    complianceMenuList() {
      const showRouteName = ['ComplianceTaskMode', 'ComplianceMemberMode', 'ComplianceRuleMode'];
      const complianceRoute = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: 'ComplianceIndex',
        targetAttr: 'name',
        getAttr: 'children'
      }).filter(_ref => {
        let {
          name,
          children
        } = _ref;
        return showRouteName.includes(name) && children;
      });
      this.sortMenu(complianceRoute);
      return complianceRoute;
    },
    getTabTitle() {
      if (['ComplianceRules', 'ComplianceEvalute', 'ComplianceAssign', 'ComplianceMyMember'].includes(this.$route.name)) {
        return '';
      }
      return this.$route.meta.subtitle;
    }
  },
  watch: {
    isHiddenMenu: {
      handler() {
        this.setTabPaddingLeft();
      }
    }
  },
  methods: {
    sortMenu(list) {
      list.sort((a, b) => a.meta.order - b.meta.order);
      list.forEach(_ref2 => {
        let {
          children
        } = _ref2;
        if (children) {
          this.sortMenu(children);
        }
      });
    },
    recursionGet: _commonFunc.recursionGet,
    updateIsHiddenMenu(val) {
      this.isHiddenMenu = val;
    },
    toggleMenu() {
      this.$refs['nav-menu'].toggleMenu();
    },
    floatingMenuShow() {
      this.$refs['nav-menu'].floatingMenuShow();
    },
    floatingMenuHidden() {
      this.$refs['nav-menu'].floatingMenuHidden();
    },
    setTabPaddingLeft() {
      const tab = document.querySelector('.el-tabs__nav-wrap');
      tab === null || tab === void 0 ? void 0 : tab.classList.toggle('tab-padding');
    }
  }
};
exports.default = _default;