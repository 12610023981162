"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "aside-list"
  }, [_c("h1", [_vm._v(" " + _vm._s(_vm.asideListType === "conversation" ? "会话" : "交易") + " "), _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        return _vm.goConversationOrDeal(_vm.asideListType);
      }
    }
  }, [_vm._v("查看所有" + _vm._s(_vm.asideListType === "conversation" ? "会话" : "交易") + " ")])], 1), _vm._t("default"), _vm.asideListType === "conversation" && _vm.list.length ? [_c("div", {
    staticClass: "conversation-list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list-item",
      class: {
        short: ["percent", "count"].includes(_vm.dataType)
      }
    }, [_c("div", {
      staticClass: "left-time"
    }, _vm._l(_vm.getLeftData(item), function (item, index) {
      return _c("span", {
        key: index
      }, [_vm._v(_vm._s(item))]);
    }), 0), _c("div", {
      staticClass: "conv-item"
    }, [_c("p", {
      staticClass: "item-name",
      on: {
        click: function ($event) {
          return _vm.goConvDetail(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("p", {
      staticClass: "item-time"
    }, [_c("el-tag", {
      staticClass: "time",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(_vm.secondToCNTimeArr(item.duration).join("")))]), _c("span", {
      staticClass: "date"
    }, [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(item.date).format("yyyy年M月D日")))])], 1)])]);
  }), 0)] : _vm.asideListType === "deal" && _vm.list.length ? _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.trade_id,
      staticClass: "deal-list"
    }, [_c("h1", {
      staticClass: "deal-name",
      on: {
        click: function ($event) {
          return _vm.goDealDetail(item.trade_id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.trade_name) + " ")]), _c("p", {
      staticClass: "deal-stamp"
    }, [_c("span", [_vm._v("交易创建时间：")]), _c("span", [_vm._v(_vm._s(item.trade_date))])])]);
  }) : _c("div", {
    staticClass: "no-data-placeholder"
  }, [_vm._v("暂无数据")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;