"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "container"
  }, [_c("p", {
    staticClass: "sticky-header"
  }, _vm._l(_setup.stautsList, function (item, index) {
    return _c("span", {
      key: index,
      class: {
        active: _setup.currentActive === index
      },
      on: {
        click: function ($event) {
          return _setup.toggleStatus(index);
        }
      }
    }, [_vm._v(_vm._s(item) + " "), index === 2 && _setup.notCompletedCount ? _c("span", [_vm._v(_vm._s(_setup.notCompletedCount))]) : _vm._e()]);
  }), 0), _c("MgvInfiniteScroll", {
    attrs: {
      "has-more": _setup.knowledgeList.length < _setup.taskDetail.total,
      "nomore-text": !_setup.loading && _setup.knowledgeList.length ? undefined : " "
    },
    on: {
      loadMore: _setup.getSmartTrainingListData
    }
  }, [!_setup.loading && _setup.knowledgeList.length ? _vm._l(_setup.knowledgeList, function (item, index) {
    return _c("div", {
      key: item.scene_id,
      staticClass: "question-container"
    }, [_c("p", {
      staticClass: "question-header"
    }, [_c("span", {
      staticClass: "bold-text"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c("span", {
      staticClass: "bias"
    }, [_vm._v("/")]), _c("span", [_vm._v(_vm._s(_setup.taskDetail.total))]), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        placement: "top",
        effect: "dark",
        content: item.name
      }
    }, [_c("span", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.name))])])], 1), _c("ul", {
      staticClass: "question-body"
    }, _vm._l(item.scene_reply_list, function (sceneItem, sceneItemIndex) {
      return _c("li", {
        key: sceneItemIndex
      }, [sceneItem.scene_content ? _c(_setup.TricksScene, {
        attrs: {
          "display-only": "",
          value: sceneItem.scene_content
        }
      }) : _vm._e(), _vm._l(sceneItem.reply_list, function (replyItem, scenceIndex) {
        return _c("div", {
          key: scenceIndex,
          staticClass: "question-item-card"
        }, [_c("div", {
          staticClass: "card-header"
        }, [_vm._v(" (" + _vm._s(replyItem.order) + ") " + _vm._s(_setup.t("coaching.score")) + "："), _c("span", {
          staticClass: "score"
        }, [_vm._v(" " + _vm._s(replyItem.is_complete ? replyItem.reply_record.score : "- ") + " ")]), _vm._v(" " + _vm._s(_vm.$t("coaching.points")) + " "), _c(_setup.TaskScoreHistoryList, {
          attrs: {
            reply: replyItem.reply_record
          }
        })], 1), _c("p", {
          staticClass: "card-body"
        }, [_vm._v(" " + _vm._s(replyItem.reply_content) + " ")]), replyItem.is_complete ? _c("div", {
          staticClass: "card-footer"
        }, [_c("p", {
          staticClass: "label"
        }, [_vm._v(_vm._s(_setup.t("coaching.salesResponse")) + "：")]), _c("el-collapse", {
          staticClass: "sales-reply"
        }, [_c("el-collapse-item", [_c("template", {
          slot: "title"
        }, [_c("el-tooltip", {
          attrs: {
            content: "播放回答",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.currentPlayAudioId !== replyItem.reply_record_url,
            expression: "\n                            currentPlayAudioId !== replyItem.reply_record_url\n                          "
          }],
          staticClass: "iconfont icon-play",
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _setup.playAudio(replyItem.reply_record_url, replyItem.reply_record_url);
            }
          }
        })]), _c("el-tooltip", {
          attrs: {
            content: "停止播放",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.currentPlayAudioId === replyItem.reply_record_url,
            expression: "\n                            currentPlayAudioId === replyItem.reply_record_url\n                          "
          }],
          staticClass: "iconfont icon-stop",
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _setup.pauseAudio(replyItem.reply_record_url);
            }
          }
        })]), _c("div", {
          staticClass: "audio-animation-container"
        }, [_c("div", {
          staticClass: "progress",
          style: {
            width: _setup.currentPlayAudioId === replyItem.reply_record_url ? _setup.audioPlayProgree + "%" : 0
          }
        }), _c("svg", {
          attrs: {
            width: "44",
            height: "12",
            viewBox: "0 0 44 12",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        }, [_c("path", {
          attrs: {
            d: "M1 5V7",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M7 2V10",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M13 4.5V7.5",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M19 1V11",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M25 2V10",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M31 4.5V7.5",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M37 1V11",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        }), _c("path", {
          attrs: {
            d: "M43 4.5V7.5",
            stroke: "#2B323B",
            "stroke-linecap": "round"
          }
        })])]), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.currentPlayAudioId === replyItem.reply_record_url,
            expression: "\n                          currentPlayAudioId === replyItem.reply_record_url\n                        "
          }]
        }, [_vm._v(_vm._s(_setup.currentPlayTime))])], 1), _c("div", [_vm._v(" " + _vm._s(replyItem.content) + " ")])], 2)], 1), replyItem.comment ? [_c("p", {
          staticClass: "label comment-label"
        }, [_vm._v(" " + _vm._s(_setup.t("coaching.intelligentReview")) + "： ")]), _c("p", {
          domProps: {
            innerHTML: _vm._s(replyItem.comment)
          }
        })] : _vm._e()], 2) : _vm._e()]);
      })], 2);
    }), 0)]);
  }) : _c(_setup.NoData, {
    attrs: {
      size: "120",
      tips: _setup.t("coaching.noData")
    }
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;