"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _parivatizationDeployment = require("@/api/parivatizationDeployment");
var _DisableMemberDialog = _interopRequireDefault(require("./DisableMemberDialog"));
var _CheckCaptchaDialog = _interopRequireDefault(require("./CheckCaptchaDialog"));
var _UserInformationDrawer = _interopRequireDefault(require("@/components/Department/UserInformationDrawer"));
var _DataTransferDrawer = _interopRequireDefault(require("@/components/DataTransferDrawer"));
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentMembers',
  components: {
    DisableMemberDialog: _DisableMemberDialog.default,
    UserInformationDrawer: _UserInformationDrawer.default,
    DataTransferDrawer: _DataTransferDrawer.default,
    MgOperateColumn: _MgOperateColumn.default,
    CheckCaptchaDialog: _CheckCaptchaDialog.default
  },
  props: {
    userList: {
      type: Array,
      default: () => []
    },
    listLoading: {
      type: Boolean,
      default: false
    },
    currData: {
      type: Object,
      default: null
    },
    orgTree: {
      type: Array,
      default: null
    },
    orgId: {
      type: Number,
      default: null
    },
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      userInformationDrawerVisible: false,
      departmentMultiple: [],
      // 部门成员多选结果
      treeUserMultiple: [],
      // 多选对应树节点的ids
      UserMultipleArray: [],
      // 多选对应树节点的对象数组
      joinVisible: false,
      screenHeight: document.body.clientHeight,
      requestComplete: false,
      disableMemberDialogVisible: false,
      rowInfo: {},
      dataTransferDrawerVisible: false,
      isResign: false,
      // 是否为离职操作
      editUserInfo: {},
      // 需要数据转移的人信息
      userCanTransferData: [],
      loginCaptchaObj: {},
      checkCaptchaDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['isTwoFactor']),
    tableHeight() {
      const wh = this.screenHeight;
      const th = wh - 294;
      return th;
    }
  },
  watch: {
    userList() {
      this.requestComplete = true;
    }
  },
  mounted() {
    window.addEventListener('resize', this.changeHeight);
    this.$bus.$on('freshUserList', () => {
      this.fetchDepartmentData();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeHeight);
    this.$bus.$off('freshUserList');
  },
  methods: {
    getOperateList(data) {
      return [{
        label: this.$t('management.编辑'),
        command: 'editMember',
        isShow: true
      }, {
        label: this.$t('management.转移数据'),
        command: 'transferData',
        icon: 'icon-share-line',
        isShow: true
      }, {
        label: this.$t('management.重置密码'),
        command: 'resetPassword',
        isShow: data.login_method === 'account',
        icon: 'icon-refresh-left'
      }, {
        label: this.$t('management.查看邮箱验证码'),
        command: 'checkEmailCode',
        isShow: this.isTwoFactor,
        icon: 'icon-view-code'
      }, {
        label: this.$t('management.启用'),
        command: 'enableMember',
        isShow: !data.is_active,
        icon: 'icon-circle-check'
      }, {
        label: this.$t('management.禁用'),
        command: 'disableMember',
        isShow: data.is_active,
        icon: 'icon-eliminated'
      }, {
        label: this.$t('management.操作离职'),
        command: 'operateResign',
        isShow: true,
        icon: 'icon-resignation'
      }];
    },
    handleDataTransferRefresh() {
      this.dataTransferDrawerVisible = false;
    },
    getDepartmentNames(department) {
      return department.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    formatCellValue(row, column, cellValue, index) {
      switch (cellValue) {
        case 'trial':
          return this.$t('management.试用版');
        case 'formal':
          return this.$t('management.正式版');
        default:
          break;
      }
    },
    setStatusClass(type, status) {
      switch (type) {
        case 'isActive':
          return status ? 'active' : 'inactive';
        case 'isEnable':
          return status ? 'enable' : 'disabled';
        default:
          break;
      }
    },
    judgeStatusClassName(type, status) {
      switch (type) {
        case 'isEnable':
          return status ? 'icon-circle-check' : 'icon-warning-outline';
        case 'isActive':
          return status ? 'icon-circle-check' : 'icon-eliminated';
        default:
          break;
      }
    },
    judgeActiveStatus(type, status) {
      switch (type) {
        case 'isEnable':
          return status ? this.$t('management.已登录') : this.$t('management.未登录');
        case 'isActive':
          return status ? this.$t('management.已启用') : this.$t('management.已禁用');
        default:
          break;
      }
    },
    // 点击某一行进行成员信息编辑
    goUserInformation(row) {
      this.editUserInfo = row;
      this.userInformationDrawerVisible = true;
    },
    closeDisableMemberDialog() {
      this.disableMemberDialogVisible = false;
    },
    openDisableMemberDialog(row) {
      this.rowInfo = row;
      this.disableMemberDialogVisible = true;
    },
    handleCommand(command, row) {
      switch (command) {
        case 'editMember':
          this.goUserInformation(row);
          break;
        case 'transferData':
          this.transferData(row, false);
          break;
        case 'resetPassword':
          this.resetPassword(row);
          break;
        case 'enableMember':
          this.enableMembers(row);
          break;
        case 'disableMember':
          this.openDisableMemberDialog(row);
          break;
        case 'operateResign':
          this.transferData(row, true);
          break;
        case 'checkEmailCode':
          this.checkEmailCode(row);
          break;
        default:
          break;
      }
    },
    // 查看邮箱验证码
    async checkEmailCode(row) {
      this.rowInfo = row;
      if (!row.verify_email) {
        this.$message.warning(this.$t('management.请先添加验证邮箱'));
        return;
      }
      const res = await (0, _organization.getLoginCaptcha)({
        user_id: row.id
      });
      if (res.code === 20000) {
        const {
          code
        } = res.results;
        if (!code) {
          this.$message.warning(this.$t('management.验证码已过期或未发送'));
          return;
        }
        this.loginCaptchaObj = res.results;
        this.checkCaptchaDialogVisible = true;
      }
    },
    async transferData(row, isResign) {
      this.isResign = isResign;
      this.editUserInfo = row;
      // 判断是否有可转移的数据
      const res = await (0, _organization.getUserCanTransferData)({
        user_id: row.id
      });
      if (res.code === 20000) {
        this.userCanTransferData = res.results.data;
        // 离职操作
        if (isResign) {
          if (this.userCanTransferData.length) {
            this.dataTransferDrawerVisible = true;
          } else {
            // 直接离职
            this.operateQuit(row);
          }
        } else {
          // 数据转移操作
          if (this.userCanTransferData.length) {
            this.dataTransferDrawerVisible = true;
          } else {
            this.$message({
              message: this.$t('management.此成员没有可转移的数据'),
              center: true,
              type: 'warning'
            });
          }
        }
      }
    },
    resetPassword(row) {
      if (this.isTwoFactor) {
        // 重置成员密码【目前只有希音有这个功能】
        this.resetAccountPassword(row);
      } else {
        // 私有化部署
        this.$confirm(this.$t('management.是否重置该成员密码'), this.$t('management.重置密码'), {
          customClass: 'operate-user-quit-confirm',
          confirmButtonText: this.$t('management.确定'),
          cancelButtonText: this.$t('management.取消'),
          type: 'warning',
          beforeClose: async (action, ctx, close) => {
            if (action !== 'confirm') {
              close();
              return;
            }
            ctx.confirmButtonLoading = true;
            (0, _parivatizationDeployment.resetPassword)({
              user_id: row.id,
              account: row.login_account
            }).then(res => {
              if (res.code === 20000) {
                const password = res.results.newPassword;
                close();
                this.$emit('openCopy', {
                  password,
                  type: 'reset'
                });
              }
            }).finally(() => {
              ctx.confirmButtonLoading = false;
            });
          }
        }).finally(() => {});
      }
    },
    resetAccountPassword(row) {
      if (!row.verify_email) {
        this.$message.warning('请先添加验证邮箱后再重置密码');
        return;
      }
      this.$confirm(this.$t('management.是否重置该成员密码？重置后会向成员的验证邮箱发送新密码。'), this.$t('management.重置密码'), {
        confirmButtonText: this.$t('management.确认'),
        cancelButtonText: this.$t('management.取消'),
        type: 'warning'
      }).then(async () => {
        const res = await (0, _organization.resetUserPassword)({
          user_id: row.id
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('management.密码重置成功'));
        }
      }).catch(() => {});
    },
    operateQuit(row) {
      this.$confirm(this.$t('management.确定要将{0}设置为离职吗？', [row.name]), this.$t('management.离职成员'), {
        customClass: 'operate-user-quit-confirm',
        confirmButtonText: this.$t('management.确定'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('management.取消'),
        type: 'warning',
        beforeClose: async (action, ctx, close) => {
          if (action !== 'confirm') {
            close();
            return;
          }
          ctx.confirmButtonLoading = true;
          (0, _organization.deleteUser)(row.id, this.orgId).then(res => {
            if (res.code === 200) {
              this.$message({
                message: this.$t('management.离职成功'),
                center: true,
                type: 'success'
              });
              close();
              // 成功之后刷新页面
              this.fetchDepartmentData();
            }
          }).finally(() => {
            ctx.confirmButtonLoading = false;
          });
        }
      }).finally(() => {});
    },
    enableMembers(row) {
      if (typeof row.name !== 'string') row.name = '';
      this.$confirm(this.$t('management.确定要启用{0}账号？', [row.name ? `<span style:'font-weight:599'>&nbsp;${row.name}&nbsp;</span>` : '']), this.$t('management.提示'), {
        confirmButtonText: this.$t('management.确定'),
        cancelButtonText: this.$t('management.取消'),
        dangerouslyUseHTMLString: true
      }).then(() => {
        const enableMembersData = {
          enable: true,
          user_ids: row.name ? [row.id] : this.departmentMultiple
        };
        this.enableAndDisableUser(enableMembersData);
      }).catch(() => {});
    },
    disableMembers() {
      this.disableMemberDialogVisible = true;
      this.rowInfo = {};
    },
    async enableAndDisableUser(data) {
      const res = await (0, _organization.enableAndDisableUser)(data);
      if (res.code === 20000) {
        this.fetchDepartmentData();
      }
    },
    changeHeight() {
      this.screenHeight = document.body.clientHeight;
    },
    refresh() {
      this.fetchDepartmentData();
    },
    openAddDialog() {
      this.$emit('openAddDialog');
    },
    DepartmentSelectionChange(val) {
      const _ids = [];
      const user_Multiple = [];
      val.forEach(item => {
        _ids.push(item.id);
      });
      val.forEach(item => {
        user_Multiple.push({
          id: item.id,
          name: item.name
        });
      });
      this.departmentMultiple = _ids;
      this.UserMultipleArray = user_Multiple;
      this.$emit('multipleSelect', this.departmentMultiple, this.UserMultipleArray);
    },
    clearDepartmentMulti() {
      // 清空部门成员多选
      this.$refs['department-user'].clearSelection();
    },
    fetchDepartmentData() {
      this.$emit('fetchDepartmentData');
    }
  }
};
exports.default = _default;