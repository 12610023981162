"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "app-main",
    class: {
      "detail-main": _vm.hasNav
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.include
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;