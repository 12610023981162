"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "fixTrainDataSetTableRef",
    staticClass: "fix-train-table",
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight
    },
    on: {
      "selection-change": _setup.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "序号",
      width: "48px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          $index
        } = _ref;
        return [_vm._v(" " + _vm._s($index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "sentences",
      label: "文本句子",
      "min-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "sentence-container"
        }, _vm._l(row.sentences, function (sentence, index) {
          return _c("p", {
            key: index
          }, [_c("span", {
            class: ["character", {
              "is-sale": sentence.entity_type === "host_salesman"
            }]
          }, [_vm._v(_vm._s(sentence.entity_type === "host_salesman" ? "销售" : "客户") + "：")]), _c("span", [_vm._v(" " + _vm._s(sentence.content) + " ")])]);
        }), 0)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "conversation_id",
      width: "200px",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("span", [_vm._v("数据集会话")]), _c("span", {
          staticClass: "header-tip"
        }, [_vm._v("总计" + _vm._s(_vm.headerSummaryData.conv_cnt || 0) + "个")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c(_setup.ConversationLink, {
          attrs: {
            id: row.conversation_id,
            name: row.conversation_name,
            type: row.conversation_type,
            path: _setup.getAnnotationPath(row)
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "annotation_user_name",
      width: "160px",
      label: "标注人"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("MgvAvatarName", {
          attrs: {
            size: 24,
            src: row.annotation_user_avatar,
            name: row.annotation_user
          }
        }), _c("span", {
          staticClass: "annotation-name"
        }, [_vm._v(_vm._s(row.annotation_user))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "annotation_time",
      width: "160px",
      label: "标注时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.annotation_time).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "250",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$tableData$filter, _vm$tableData$filter2, _vm$tableData$filter3;
        return [_c("div", {
          staticClass: "operation-header"
        }, [_c("span", [_vm._v("命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s((_vm$tableData$filter = _vm.tableData.filter(item => item.status === 1)) === null || _vm$tableData$filter === void 0 ? void 0 : _vm$tableData$filter.length))]), _c("span", [_vm._v("未命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s((_vm$tableData$filter2 = _vm.tableData.filter(item => item.status === 2)) === null || _vm$tableData$filter2 === void 0 ? void 0 : _vm$tableData$filter2.length))]), _c("span", [_vm._v("不确定")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s((_vm$tableData$filter3 = _vm.tableData.filter(item => item.status === 3)) === null || _vm$tableData$filter3 === void 0 ? void 0 : _vm$tableData$filter3.length))])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c(_setup.HitButtons, {
          attrs: {
            status: row.status
          },
          on: {
            change: function ($event) {
              return _setup.updateAnnotationStatus($event, row);
            }
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "operate",
      label: "操作",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("el-popconfirm", {
          attrs: {
            title: "确定删除此标注吗？",
            "confirm-button-text": "删除",
            "cancel-button-text": "取消",
            "popper-class": "delete-popover"
          },
          on: {
            onConfirm: function ($event) {
              return _setup.deleteAnnotation(row);
            }
          }
        }, [_c("MgvButton", {
          attrs: {
            slot: "reference",
            type: "text",
            color: "danger",
            size: "medium"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;