"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _business = require("@/api/business.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    type: String,
    value: Object,
    isAfterConv: Boolean
  },
  inject: ['descFormat', 'isEmptyForm'],
  data() {
    return {
      isEdit: false,
      formData: {},
      everData: {},
      formDesc: [{
        id: 'name',
        type: 'text',
        label: '姓名'
      }, {
        id: 'tel',
        type: 'tel',
        label: '手机号'
      }, {
        id: 'email',
        type: 'email',
        label: '邮箱'
      }, {
        id: 'position',
        type: 'text',
        label: '职位'
      }]
    };
  },
  created() {
    if (this.type !== 'create' && !this.isAfterConv) {
      this.isEdit = false;
    }
  },
  methods: {
    handleCommand(command) {
      this.$emit('command', command);
    },
    handleKeyChange(key, value) {
      this.$emit('keyChange', {
        key,
        value
      });
    },
    cancel() {
      this.$refs.form.clearValidate();
      this.$emit('cancel', this.everData);
      this.isEdit = false;
    },
    enterEdit() {
      this.everData = {
        ...this.value
      };
      this.isEdit = true;
    },
    async submit() {
      const flag = this.isEmptyForm(this.value);
      // const valid = this.$refs.form.validate()
      if (!flag) {
        const res = await (0, _business.updateDealContacts)(this.value);
        if (res.code === 200 && res.msg === 'Success') {
          /** 触发获取联系人@function emit('submitSuccess') 时会导致出错
           * 如果存在两个及以上的编辑状态卡片，获取数据时远程的卡片数量会覆盖本地的卡片数量，并且数据会错位
           * 解决方案:直接本地修改。
           */
          this.value.id = res.results.contact_id;
          this.$message.success('保存成功！');
          this.isEdit = false;
        }
      }
    }
  }
};
exports.default = _default;