"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-detail-card",
    class: {
      "is-full-screen": _vm.isEmbed
    }
  }, [_c("div", {
    class: ["card-content", {
      "card-content-recite": _vm.taskDetail.course_type === 2
    }, {
      "card-content-smart-training": _vm.taskDetail.course_type === 3
    }, {
      "card-content-ai-custom": [4, 5, 6, 7].includes(Number(_vm.taskDetail.course_type))
    }]
  }, [_c("div", {
    staticClass: "left"
  }), _c("div", {
    staticClass: "content-container"
  }, [_c("el-button", {
    staticClass: "back-btn",
    attrs: {
      type: "text",
      icon: "iconfont icon-arrow-left",
      size: "mini"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.back")))]), _c("div", {
    staticClass: "content"
  }, [_vm.taskDetail.course_type ? _c("p", {
    class: ["task-type", {
      "smart-training": _vm.taskDetail.course_type === 3
    }, {
      "ai-customer": [4, 5, 6, 7].includes(Number(_vm.taskDetail.course_type))
    }]
  }, [_vm._v(" " + _vm._s(_vm.taskCourseType) + " ")]) : _vm._e(), _c("div", {
    staticClass: "task-title"
  }, [_vm._v(_vm._s(_vm.taskDetail.name))]), !_vm.smartOrAi ? _c("div", [_vm._v(" " + _vm._s(_vm.practiceRule) + " ")]) : _vm._e(), !_vm.smartOrAi ? _c("div", {
    staticClass: "progress detail-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.progress")) + ":")]), _c("el-progress", {
    attrs: {
      percentage: _vm.percentage,
      "show-text": false
    }
  }), _c("span", [_vm._v(_vm._s(_vm.taskProgress))])], 1) : _vm._e(), _c("div", {
    class: ["detail-item", {
      "smart-training": _vm.smartOrAi
    }]
  }, [_c("i", {
    staticClass: "iconfont icon-time"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.deadlineHasVar", [_vm.taskDetail.end_time])))])]), _c("div", {
    staticClass: "detail-item"
  }, [_c("i", {
    staticClass: "iconfont icon-user-assign"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.assignedBy", [_vm.taskDetail.user_info.name])))])]), _c("div", {
    staticClass: "detail-item"
  }, [_c("i", {
    staticClass: "iconfont icon-user-dbl"
  }), _c("div", {
    staticClass: "avatar-container"
  }, _vm._l(_vm.taskDetail.users.slice(0, 3), function (item) {
    return _c("image-avatar", {
      key: item.id,
      staticClass: "image-avatar",
      attrs: {
        name: item.name,
        avatar: item.avatar
      }
    });
  }), 1), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.participantsHasVar", [_vm.taskDetail.users.length])))])])]), _c("div", {
    staticClass: "footer"
  }, [_vm.taskDetail.course_type === 3 ? _c("el-button", {
    staticClass: "practice-btn",
    on: {
      click: _vm.goCoachingDetail
    }
  }, [_vm._v(_vm._s(_vm.smartTrainingButtonTitle))]) : _c("el-button", {
    staticClass: "practice-btn",
    attrs: {
      type: [4, 5, 6, 7].includes(Number(_vm.taskDetail.course_type)) ? "primary" : ""
    },
    on: {
      click: _vm.goCoachingDetail
    }
  }, [_vm._v(_vm._s(_vm.taskDetail.is_first_answer && !_vm.taskDetail.conv_id ? _vm.$t("coaching.startPractice") : _vm.$t("coaching.continuePractice")))]), _vm.$config.isMegaview && !_vm.isEmbed ? _c("p", {
    class: ["logo", {
      "ai-customer": [4, 5, 6, 7].includes(Number(_vm.taskDetail.course_type))
    }]
  }, [_c("i", {
    staticClass: "iconfont icon-megaview"
  }), _c("span", [_vm._v("Megaview")])]) : _vm._e()], 1)], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;