"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "study-task"
  }, [_c("header", [_c("span", {
    staticClass: "name"
  }, [_vm._v("我的学习")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "主管将剪辑库片段指派给我学习后，对应的学习内容展示在此处",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "type-container"
  }, _vm._l(_setup.taskTypes, function (_ref) {
    let {
      type,
      label
    } = _ref;
    return _c("el-button", {
      key: type,
      class: ["type-btn", {
        active: _setup.listParams.type === type
      }],
      attrs: {
        type: "text gray-bg"
      },
      on: {
        click: function ($event) {
          return _setup.changeType(type);
        }
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_setup.tableData.length ? [_c(_setup.TaskTable, {
    attrs: {
      "table-heads": _setup.getTableHeads(),
      "table-data": _setup.tableData
    }
  }), _c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _setup.listParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _setup.listParams.size,
      total: _setup.count
    },
    on: {
      "size-change": _setup.sizeChange,
      "current-change": _setup.pageChange
    }
  })] : _vm._e(), !_setup.tableData.length && !_setup.loading ? _c(_setup.NoData) : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;