"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.activeName === "todo" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }]
  }, [!_vm.listLoading && !_vm.commentList.length && !_vm.loading ? _c("NoData", {
    staticClass: "no-content",
    attrs: {
      tips: "您还没有待办事项"
    }
  }) : _c("mg-infinite-scroll", {
    staticClass: "message",
    staticStyle: {
      overflow: "auto"
    },
    attrs: {
      disabled: _vm.disabled,
      loading: _vm.loading,
      nomore: _vm.nomore
    },
    on: {
      loadMore: _vm.load
    }
  }, [_vm.commentList && _vm.commentList.length ? [_c("transition-group", {
    attrs: {
      name: "list",
      tag: "div"
    }
  }, _vm._l(_vm.commentList, function (item) {
    return _c("todo-item", {
      key: item.id,
      attrs: {
        item: item
      },
      on: {
        handleRead: _vm.handleRead,
        handleIgnore: _vm.handleIgnore,
        delete: _vm.handleDelete,
        refresh: _vm.handleRefresh
      }
    });
  }), 1)] : _vm._e()], 2)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;