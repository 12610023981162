"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskResultDetailDrawer = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskResultDetail/TaskResultDetailDrawer.vue"));
var _i18n = require("@/i18n");
var _vue = require("vue");
var _prettier = require("prettier");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ParentTaskTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    head: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sort-change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const tableHeight = document.documentElement.clientHeight - 347;
    const handleSortChange = sort => {
      emit('sort-change', sort);
    };
    const taskResultDetailDrawerVisible = (0, _vue.ref)(false);
    const currentTaskInfo = (0, _vue.ref)({
      courseType: 0,
      executorInfo: {}
    });
    const currentTaskId = (0, _vue.ref)(0);
    const openTaskDetailDrawer = (taskItem, row) => {
      currentTaskInfo.value.courseType = taskItem.course_type;
      currentTaskInfo.value.executorInfo = row.user;
      currentTaskId.value = taskItem.children_plan_id;
      taskResultDetailDrawerVisible.value = true;
    };
    const router = (0, _useRouter.useRouter)();
    const route = (0, _useRouter.useRoute)();
    function goChildrenDetail(item) {
      console.log('item', item);
      const url = router.resolve({
        path: _routeMap.ROUTES.coachingCenterLeaderPlan,
        query: {
          id: Number(item.field_name),
          source: route.query.source
        }
      });
      window.open(url.href);
    }
    return {
      __sfc: true,
      props,
      tableHeight,
      emit,
      handleSortChange,
      taskResultDetailDrawerVisible,
      currentTaskInfo,
      currentTaskId,
      openTaskDetailDrawer,
      router,
      route,
      goChildrenDetail,
      TaskResultDetailDrawer: _TaskResultDetailDrawer.default,
      t: _i18n.t
    };
  }
};
exports.default = _default;