"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coaching-center-leader-detail"
  }, [_c("personal-plan-header", {
    attrs: {
      type: "leader",
      "user-info": _vm.taskConfig.userInfo,
      "task-score-info": _vm.taskScoreInfo
    }
  }), _c("p", {
    staticClass: "divide-line"
  }), _c("leader-plan-header-filter", {
    attrs: {
      "task-score-info": _vm.taskScoreInfo,
      "show-export-button": false
    },
    on: {
      filterChange: _vm.filterChange,
      goPractice: _vm.goPractice
    }
  }), _c("personal-plan-table", {
    ref: "personalPlanTable",
    attrs: {
      "table-data": _vm.tableData,
      "table-height": _vm.tableHeight,
      "task-id": _vm.requestParams.id,
      "table-loading": _vm.tableLoading
    },
    on: {
      tableSortChange: _vm.tableSortChange,
      openPracticeDrawer: _vm.openPracticeDrawer
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    class: _vm.totalCount >= 10000 ? "hide-last-page" : "",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.requestParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.requestParams.size,
      total: _vm.totalCount
    },
    on: {
      "size-change": _vm.tableSizeChange,
      "current-change": _vm.tableCurrentPageChange
    }
  })], 1), _c("practice-knowledge-drawer", {
    attrs: {
      visible: _vm.practiceDrawerVisible,
      "user-id": _vm.requestParams.user_id,
      "knowledge-id": _vm.acticeDrawerKnowledgeId
    },
    on: {
      "update:visible": function ($event) {
        _vm.practiceDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;