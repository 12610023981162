"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _routeMap = require("@/router/routeMap");
var _default = {
  __name: 'CreateModelButton',
  emits: ['itemClick'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const MODELS = [{
      type: 'rule',
      title: '规则模型',
      describe: '使用关键词、正则表达式等直接定义关键事件的触发规则',
      tip: '适用场景：关键词固定、场景明确、无需复杂语义'
    }, {
      type: 'AI',
      title: 'AI模型',
      describe: '使用标注后的数据训练MegaAI模型，由模型判断关键事件的命中结果',
      tip: '适用场景：关键词不固定、需一定语义理解'
    }, {
      type: 'combine',
      title: '组合模型',
      describe: '组合使用多个模型，实现更强大的语义理解能力',
      tip: '适用场景：场景复杂，需长上下文、多层的语义理解'
    }];
    const store = (0, _useVuex.useStore)();
    const apiPermission = (0, _vue.computed)(() => store.getters.api_permissions);
    const models = (0, _vue.computed)(() => {
      const result = [];
      if (apiPermission.value.includes(_routeMap.INTERFACE.createRuleModel)) {
        result.push(MODELS[0]);
      }
      if (apiPermission.value.includes(_routeMap.INTERFACE.createAiModel)) {
        result.push(MODELS[1]);
      }

      // TODO: 222,338 团队单独开组合模型 后期需要删除
      if ([222, 338].includes(store.getters.orgId)) {
        result.push(MODELS[2]);
      }

      // if (apiPermission.value.includes(INTERFACE.createCombineModel)) {
      //   result.push(MODELS[2]);
      // }
      return result;
    });
    const onClick = type => {
      emit('itemClick', type);
    };
    return {
      __sfc: true,
      MODELS,
      store,
      apiPermission,
      models,
      emit,
      onClick
    };
  }
};
exports.default = _default;