"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalSearch = globalSearch;
exports.resumeSearch = resumeSearch;
var _request = require("@/utils/request");
function resumeSearch(params) {
  return (0, _request.request)({
    url: '/search/deal',
    method: 'get',
    params
  });
}
function globalSearch(params) {
  return (0, _request.request)({
    url: '/search',
    method: 'get',
    params
  });
}