"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _dynamicTabChart = _interopRequireDefault(require("./components/dynamicTabChart.vue"));
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban.vue"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _SalesPortraitConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/SalesPortraitConfig"));
var _PreviewPortrait = _interopRequireDefault(require("@/views/businessIntelligence/components/PreviewPortrait"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _chartTabMixin = _interopRequireDefault(require("./chartTabMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SalesmanProfile',
  components: {
    DynamicTabChart: _dynamicTabChart.default,
    BIKanban: _BIKanban.default,
    SalesPortraitConfig: _SalesPortraitConfig.default,
    InitKanbanCardView: _InitKanbanCardView.default,
    PreviewPortrait: _PreviewPortrait.default
  },
  mixins: [_chartTabMixin.default],
  data() {
    return {
      drawerVisible: false,
      dialogVisible: false,
      isEdit: false,
      isShowKanban: false,
      kanbanList: [],
      portraitConfig: {},
      previewLoading: false,
      getPortraitConfigLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions', 'workspaceInfo']),
    hasHandleKanbanPermission() {
      return this.api_permissions.includes('/api/bi_salesman/config/create');
    }
  },
  methods: {
    createKanban() {
      this.drawerVisible = true;
      this.$refs.SalesPortraitConfig.kanbanConfig.config_name = `销售画像看板${this.kanbanList.length + 1}`;
      this.isEdit = false;
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.currentKanban = activeKanban;
    },
    async handleKanban(type, data) {
      if (!this.hasHandleKanbanPermission) return;
      if (type === 'edit') {
        this.isEdit = true;
        this.drawerVisible = true;
        this.getPortraitConfigLoading = true;
        const res = await (0, _businessIntelligence.getPortraitConfig)({
          origin_config_id: data.kanban_id,
          workspace_id: this.workspaceInfo.id
        }).finally(() => {
          this.getPortraitConfigLoading = false;
        });
        if (res.code === 200) {
          // 历史数据兼容处理
          res.results.axis_config.forEach(item => {
            item.config_items.forEach(configItem => {
              if (configItem.metrics_type === 'keyEventOptions' && !Array.isArray(configItem.metrics_id)) {
                configItem.metrics_id = [configItem.metrics_id];
              }
            });
          });
          this.$refs.SalesPortraitConfig.kanbanConfig = res.results;
        }
      } else {
        this.$confirm(`是否删除看板“${data.name}”?`, '删除看板', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          confirmButtonClass: 'confirm-right',
          type: 'warning'
        }).then(async () => {
          const res = await (0, _businessIntelligence.deleteBiKanban)({
            kanban_id: data.kanban_id,
            workspace_id: this.workspaceInfo.id
          });
          if (res.code === 200) {
            this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== data.kanban_id);
            if (this.kanbanList.length) {
              this.currentKanban = this.kanbanList[0];
            }
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      }
    },
    portraitSuccess(data) {
      if (this.isEdit) {
        this.kanbanList.forEach(item => {
          if (item.kanban_id === Number(data.kanban_id)) {
            item.name = data.name;
          }
        });
        this.getChartData([this.currentKanban.bi_chart_id]);
      } else {
        this.kanbanList.push(data);
        this.currentKanban = data;
      }
    },
    async previewPortrait(config) {
      this.dialogVisible = true;
      this.previewLoading = true;
      const res = await (0, _businessIntelligence.previewProtrait)({
        ...this.biFiltersObj,
        ...config,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.previewLoading = false;
        this.portraitConfig = res.results;
      }
    }
  }
};
exports.default = _default;