"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-info"
  }, [_c("div", {
    staticClass: "task-title"
  }, [_vm._v(_vm._s(_vm.taskInfo.name))]), _c("div", {
    staticClass: "mid-row"
  }, [_c("div", {
    staticClass: "time"
  }, [_c("span", {
    staticClass: "date-time"
  }, [_vm._v(_vm._s(_vm.plus8ToCurrentZone(_vm.taskInfo.start_time)))]), _c("span", {
    staticClass: "time-separator"
  }, [_vm._v(_vm._s(_vm.$t("coaching.to")))]), _c("span", {
    staticClass: "date-time"
  }, [_vm._v(_vm._s(_vm.plus8ToCurrentZone(_vm.taskInfo.end_time)))])]), _vm.taskInfo.alert_at ? _c("div", {
    staticClass: "info-text left-border reminder"
  }, [_c("i", {
    staticClass: "iconfont icon-bell"
  }), _vm._v(_vm._s(_vm.getReminderLabel) + " ")]) : _vm._e(), _c("div", {
    staticClass: "info-text left-border"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.assigner")) + "：" + _vm._s(_vm.taskInfo.user_info && _vm.taskInfo.user_info.name) + " ")]), _vm.taskInfo.space_info ? _c("space-label", {
    staticClass: "left-border",
    attrs: {
      "space-info": _vm.taskInfo.space_info
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.taskInfo.description) + " ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;