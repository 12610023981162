"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisConfigMixin = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/AnalysisConfigMixin"));
var _ShowCustomFilter = _interopRequireDefault(require("@/components/ShowFiltersDetail/ShowCustomFilter"));
var _ShowEventTrackerFilter = _interopRequireDefault(require("@/components/ShowFiltersDetail/ShowEventTrackerFilter"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const conversationDimensionTarget = ['max_sales', 'max_customer', 'percent_speak', 'discuss', 'question'];
const conversationActivityTarget = ['count', 'sum_duration', 'avg_duration', 'avg_person_count', 'avg_person_duration'];
var _default = {
  name: 'ConfigDetailShow',
  components: {
    ShowCustomFilter: _ShowCustomFilter.default,
    ShowEventTrackerFilter: _ShowEventTrackerFilter.default
  },
  mixins: [_AnalysisConfigMixin.default],
  props: {
    formConfig: {
      type: Object,
      default: () => ({})
    },
    filtersConfigs: {
      type: Object,
      default: () => ({})
    },
    configs: {
      type: Object,
      default: () => ({})
    },
    configName: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'right-start'
    }
  },
  data() {
    return {
      eventFilters: {}
    };
  },
  computed: {
    getAnalysisTargetValsToLabels() {
      var _customFilters$find;
      const {
        analysis_target_key,
        analysis_target_vals
      } = this.configs;
      const customFilters = this.getAnalysisTargetOptions.reduce((pre, current) => {
        return pre.concat(current[1]);
      }, []);
      const options = ((_customFilters$find = customFilters.find(item => item.field_name === analysis_target_key)) === null || _customFilters$find === void 0 ? void 0 : _customFilters$find.options) || [];
      const res = [];
      options.map(item => {
        if (analysis_target_vals.includes(item.value)) {
          res.push(item.label);
        }
      });
      return res.length ? res.map(item => item).join('、') : analysis_target_vals;
    },
    isHiddenFiltersContainer() {
      var _keyEventCascaderList;
      if (JSON.stringify(this.filtersConfigs) === '{}') return false;
      const {
        custom_filters,
        event_filters
      } = this.filtersConfigs;
      const keyEventCascaderList = event_filters.keyEventCascaderList;
      return Object.keys(custom_filters).length || (keyEventCascaderList === null || keyEventCascaderList === void 0 ? void 0 : (_keyEventCascaderList = keyEventCascaderList[0]) === null || _keyEventCascaderList === void 0 ? void 0 : _keyEventCascaderList.keyEventLabel.length);
    },
    getTargetTypeLable() {
      return this.getLabelByKey({
        options: this.analysisTarget,
        findKey: 'type',
        key: 'analysis_target',
        label: 'label'
      });
    },
    getTargetKeyLable() {
      let options;
      if (this.configs.analysis_target_key === 'deal') {
        options = this.getAllDealCustomerFieldList;
      } else {
        options = this.getAnalysisTargetOptions.reduce((pre, current) => {
          return pre.concat(current[1]);
        }, []);
      }
      return this.getLabelByKey({
        options,
        findKey: 'field_name',
        key: 'analysis_target_key',
        label: 'name'
      });
    },
    dimensionLabel() {
      const type = this.configs.analysis_target_key === 'conv_event' ? '会话' : '交易';
      return this.configs.dimension === 'count' ? `${type}个数` : `占总${type}数比例`;
    },
    hasConversationType() {
      return [...conversationActivityTarget, ...conversationDimensionTarget].includes(this.configs.analysis_target_key);
    },
    isDimensionTarget() {
      return conversationDimensionTarget.includes(this.configs.analysis_target_key);
    }
  },
  methods: {
    getLabelByKey(_ref) {
      var _options$find;
      let {
        options,
        findKey,
        value,
        key,
        label
      } = _ref;
      value = value || this.configs[key];
      return (_options$find = options.find(item => item[findKey] === value)) === null || _options$find === void 0 ? void 0 : _options$find[label];
    },
    getConversationType(type) {
      return {
        audio: '电话',
        video: '在线会议',
        doc: '文字'
      }[type];
    },
    getConversationSceneType(type) {
      return {
        single: '单聊',
        multiple: '群聊'
      }[type];
    },
    formatTime(time) {
      let isShowZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const minute = parseInt(time / 60) ? parseInt(time / 60) + '分钟' : '';
      let second = time % 60 ? time % 60 + '秒' : '';
      if (!minute && time % 60 === 0 && isShowZero) {
        // 在统计商机下所有会话时长时需要显示0秒以上
        second = '0秒';
      }
      return minute + second;
    },
    getConvDurationLabel(duration) {
      let isShowZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!duration) return '';
      if (Array.isArray(duration)) {
        if (duration[0] === duration[1]) {
          return duration[0] + '分钟';
        } else if (duration[0] > 0) {
          if (duration[1] === 'unlimit') {
            return duration[0] + '分钟以上';
          } else {
            return duration[0] + '分钟 -' + duration[1] + '分钟';
          }
        }
      } else {
        if (duration.value[0] === duration.value[1]) {
          return this.formatTime(duration.value[0], isShowZero);
        } else if (duration.value[0] > 0 || duration.value[0] === 0 && isShowZero) {
          if (duration.value[1] === 'unlimit' || duration.value[1] === -1) {
            if (isShowZero || duration.value[0] > 0) {
              return this.formatTime(duration.value[0], isShowZero) + '以上';
            } else {
              return '';
            }
          } else {
            return this.formatTime(duration.value[0]) + ' - ' + this.formatTime(duration.value[1]);
          }
        } else if ([0, 'unlimit'].includes(duration.value[0]) && (duration.value[1] > 0 || duration.value[1] === 0 && isShowZero)) {
          return this.formatTime(duration.value[1], isShowZero) + '以内';
        }
      }
    }
  }
};
exports.default = _default;