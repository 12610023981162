"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OperateDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/OperateDropdown.vue"));
var _keyEvent = require("@/api/keyEvent");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vuex = require("vuex");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ShareKeyEventDrawer',
  components: {
    OperateDropdown: _OperateDropdown.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: String,
      default: ''
    },
    eventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      publicScope: 0,
      memberList: [],
      loading: false,
      submitLoading: false,
      memberOptions: [],
      membersPermission: 1,
      memberBackfillList: [],
      owner: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'workspaceInfo']),
    title() {
      return `分享关键事件：${this.eventName}`;
    },
    baseOperateList() {
      return [{
        value: 3,
        label: '可管理',
        icon: 'icon-setting',
        tips: '可分享/管理/删除关键事件'
      }, {
        value: 2,
        label: '可编辑',
        icon: ' icon-edit-outline',
        tips: '可编辑关键事件'
      }, {
        value: 1,
        label: '可查看',
        icon: 'icon-view',
        tips: '只可查看关键事件'
      }];
    },
    memberOperateList() {
      return function (item) {
        // 所有者权限：所有者的管理权限不能被更改
        // 只有所有者才能更改其他人(人：type = 1，部门type = 2)为所有者，更改后自己的权限变成可管理
        // 管理者是系统除外（id = 0），所有人都可对单人进行设为所有者操作
        const operate = (0, _cloneDeep.default)(this.baseOperateList);
        console.log(this.owner.id, item.type);
        if (this.userId === this.owner.id) {
          operate.push({
            value: 4,
            label: '设为所有者',
            icon: 'icon-change-follower'
          });
        }
        operate.push({
          value: -1,
          label: '移除',
          icon: 'icon-delete'
        });
        return operate;
      };
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getKeyEventPermission();
        }
      }
    }
  },
  methods: {
    getItemIcon(type) {
      const iconObj = {
        1: '',
        2: 'iconfont icon-department',
        3: 'iconfont icon-roles'
      };
      return iconObj[type];
    },
    getUserList: _organization.getUserList,
    getOrgMemberTree: _user.getOrgMemberTree,
    // 统一修改添加成员权限类型
    changeMembersPermission(val) {
      this.membersPermission = val;
    },
    // 统一添加多个成员权限
    addPermission() {
      const {
        tree_ids,
        user_ids
      } = this.memberOptions;
      if (!(tree_ids !== null && tree_ids !== void 0 && tree_ids.length) && !(user_ids !== null && user_ids !== void 0 && user_ids.length)) return;
      let users = [];
      let trees = [];
      if (tree_ids.length) {
        trees = tree_ids.map(item => ({
          id: item,
          type: 2
        }));
      }
      if (user_ids.length) {
        users = user_ids.map(item => ({
          id: item,
          type: 1
        }));
      }
      const members = [...trees, ...users];
      const params = {
        members,
        event_id: this.eventId,
        permission: this.membersPermission,
        workspace_id: this.workspaceInfo.id
      };
      this.editKeyEventPermission(params, 'isAdd');
    },
    // 修改或删除单个人的权限
    editPermission(permission, item) {
      console.log(item);
      // 删除权限
      if (permission === -1) {
        this.deleteKeyEventPermission(item);
        return;
      }
      if (permission === 4) {
        const params = {
          workspace_id: this.workspaceInfo.id,
          event_id: this.eventId,
          user_id: item.id
        };
        this.transferOwnerOfKeyEvent(params);
        return;
      }
      // 编辑权限
      const params = {
        members: [{
          id: item.id,
          type: item.type
        }],
        event_id: this.eventId,
        permission,
        workspace_id: this.workspaceInfo.id
      };
      // permission 1:可查看 2:可编辑 3:可管理,4:更改所有者
      this.editKeyEventPermission(params, permission);
    },
    assigneeChange(val) {
      this.memberOptions = val;
    },
    async deleteKeyEventPermission(_ref) {
      let {
        id,
        type
      } = _ref;
      const res = await (0, _keyEvent.deleteKeyEventPermission)({
        workspace_id: this.workspaceInfo.id,
        event_id: this.eventId,
        members: [{
          id: id,
          type: type
        }]
      });
      if (res.code === 20000) {
        this.$message.success('移除成功');
        this.memberList = this.memberList.filter(item => item.id !== id);
        // 删除自己的权限
        if (this.userId === id) {
          this.handleClose();
          this.$emit('refreshTableData');
        }
      }
    },
    async editKeyEventPermission(params, operate) {
      const res = await (0, _keyEvent.editKeyEventPermission)(params);
      if (res.code === 20000) {
        const message = operate === 'isAdd' ? '添加成功' : '修改成功';
        this.$message.success(message);
        // operate 1:可查看 2:可编辑 3:可管理 3:设为所有者
        // 添加操作和设为所有者操作需要重新请求权限列表
        if (operate === 'isAdd') {
          this.memberOptions = [];
          this.memberBackfillList = [];
          this.getKeyEventPermission();
        }
        // 如果修改自己的权限，改成 1:可查看 2:可编辑，关闭弹窗并刷新知识库列表
        if (operate < 3 && this.userId === params.members[0].id && params.members[0].type === 1) {
          this.handleClose();
          this.$emit('refreshTableData');
        }
      }
    },
    async transferOwnerOfKeyEvent(params) {
      const res = await (0, _keyEvent.transferOwnerOfKeyEvent)(params);
      if (res.code === 20000) {
        this.$message.success('修改成功');
        this.getKeyEventPermission();
      }
    },
    async getKeyEventPermission() {
      this.loading = true;
      const res = await (0, _keyEvent.getKeyEventPermission)({
        event_id: this.eventId,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.owner = res.results.owner;
        this.memberList = [res.results.owner, {
          name: '超级管理员',
          type: 3
        }, ...res.results.other];
        this.publicScope = res.results.public_scope;
      }
    },
    // 修改公开范围
    async handleRadioChange(val) {
      const res = await (0, _keyEvent.changeKeyEventPublicScope)({
        event_id: this.eventId,
        public_scope: val,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('修改成功');
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.memberOptions = [];
      this.memberBackfillList = [];
    }
  }
};
exports.default = _default;