"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    ref: "inputRef",
    staticClass: "prompt-input",
    class: [`is-placeholder-${_setup.inputStatus}`, "is-user-modify"],
    attrs: {
      "data-placeholder-empty": _vm.placeholder.empty,
      "data-placeholder-title": _vm.placeholder.title,
      "data-placeholder-content": _vm.placeholder.content
    },
    on: {
      input: _setup.onInput
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;