"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("el-select", {
    staticClass: "deal-selector",
    attrs: {
      placeholder: "请选择交易",
      clearable: "",
      "popper-class": "customer-deal-selector"
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function () {
        return [_c("i", {
          staticClass: "iconfont icon-deal4"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.tradeId,
      callback: function ($$v) {
        _vm.tradeId = $$v;
      },
      expression: "tradeId"
    }
  }, _vm._l(_vm.tradeOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: _vm.getLabel(item),
        value: item.id
      }
    }, [_c("span", {
      staticClass: "deal-name"
    }, [_vm._v(_vm._s(item.name))]), item.stage ? _c("b", [_vm._v("·")]) : _vm._e(), _c("span", {
      staticClass: "deal-stage"
    }, [_vm._v(_vm._s(item.stage))]), item.plan_to_deal_at ? _c("b", [_vm._v("·")]) : _vm._e(), item.plan_to_deal_at ? _c("span", [_vm._v(_vm._s(_vm.$moment(item.plan_to_deal_at).format("YYYY-MM-DD")))]) : _vm._e(), _c("b", [_vm._v("·")]), _c("span", [_vm._v(_vm._s(_vm.parseFormatNum(item.amount, 2)) + "元")]), _vm._v(" "), _c("b", [_vm._v("·")]), _c("div", {
      staticClass: "workspace"
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.workspace.icon_color,
        "margin-right": "4px"
      }
    }), _c("span", [_vm._v(_vm._s(item.workspace.name))])])]);
  }), 1), _c("div", {
    staticClass: "calendar-directory"
  }, [_c("busi-calender-directoty", {
    ref: "busiCalenderDirectoty",
    attrs: {
      "is-calender-fold": _vm.isCalenderFold,
      "deal-object": _vm.dealObject,
      "deal-id": _vm.tradeId,
      "expand-persons": "",
      "hidden-amount": ""
    },
    on: {
      "update:isCalenderFold": function ($event) {
        _vm.isCalenderFold = $event;
      },
      "update:is-calender-fold": function ($event) {
        _vm.isCalenderFold = $event;
      },
      cancelFilter: _vm.cancelDealFilter
    }
  })], 1), _c("div", {
    staticClass: "bottom-part"
  }, [_c("div", {
    staticClass: "bottom-left-part"
  }, [_vm.hasAccountLabelPermission ? _c("div", {
    staticClass: "customer-label"
  }, [_c("div", {
    staticClass: "event-title"
  }, [_c("h2", [_vm._v("客户标签")]), _vm.tradeId ? _c("p", [_c("i", {
    staticClass: "iconfont icon-deal4"
  }), _c("span", [_vm._v(_vm._s(_vm.tradeOptions.find(item => item.id === _vm.tradeId).name))])]) : _vm._e()]), _vm.customerLabelList.length ? _vm._l(_vm.customerLabelList, function (item) {
    return _c("div", {
      key: item.tag_id,
      staticClass: "event-unit"
    }, [_c("h3", [_vm._v(_vm._s(item.tag_name))]), _c("div", {
      staticClass: "tag-list"
    }, _vm._l(item.children, function (tag) {
      return _c("el-tag", {
        key: tag.tag_id,
        staticClass: "border circle",
        class: {
          "selected-tag": _vm.selectedTagMark === tag.tag_id + tag.tag_name
        },
        on: {
          click: function ($event) {
            return _vm.getConversationIds(tag, tag.conversation_ids, "tag");
          }
        }
      }, [_vm._v(_vm._s(tag.tag_name) + " | " + _vm._s(tag.total))]);
    }), 1)]);
  }) : _c("div", {
    staticClass: "objection-empty"
  }, [_vm._m(0)])], 2) : _vm._e(), _c("div", {
    staticClass: "customer-objection"
  }, [_c("div", {
    staticClass: "event-title"
  }, [_c("h2", [_vm._v("客户异议")]), _vm.tradeId ? _c("p", [_c("i", {
    staticClass: "iconfont icon-deal4"
  }), _c("span", [_vm._v(_vm._s(_vm.tradeOptions.find(item => item.id === _vm.tradeId).name))])]) : _vm._e()]), _vm.customerObjectionList.length ? _c("div", {
    staticClass: "tag-list"
  }, _vm._l(_vm.customerObjectionList, function (tag) {
    return _c("el-tag", {
      key: tag.event_id,
      staticClass: "border circle",
      class: {
        "selected-tag": _vm.selectedTagMark === tag.event_id + tag.event_name
      },
      on: {
        click: function ($event) {
          return _vm.getConversationIds(tag, tag.conversation_ids, "event");
        }
      }
    }, [_vm._v(_vm._s(tag.event_name) + " | " + _vm._s(tag.total))]);
  }), 1) : _c("div", {
    staticClass: "objection-empty"
  }, [_vm._m(1)])])]), _c("div", {
    staticClass: "bottom-center-part"
  }, [_c("speak-time-tab-content", {
    ref: "SpeakTimeTabContent",
    attrs: {
      "account-id": _vm.accountId,
      "conversation-ids": _vm.conversationIds,
      "has-account-label-permission": _vm.hasAccountLabelPermission,
      "click-card": ""
    },
    on: {
      clickTag: _vm.switchConvTab,
      clickConvCard: _vm.saveConvInfo,
      scrollToActiveConv: _vm.scrollToActiveConv,
      cancelFilter: _vm.cancelFilter,
      initCalendar: _vm.initCalendar
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.convDetailLoading,
      expression: "convDetailLoading"
    }],
    staticClass: "bottom-right-part"
  }, [_vm.hasConvDetail && _vm.hasConvDetailPermission ? [_c("h2", {
    on: {
      click: function ($event) {
        return _vm.goConvDetail();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.overviewInfo.name) + " ")]), Object.keys(_vm.convInfo).length ? _c("conv-info", {
    attrs: {
      info: _vm.convInfo
    }
  }) : _vm._e(), _c("el-divider"), _c("div", {
    staticClass: "conv-detail"
  }, [_c("dialog-text", {
    ref: "transtext",
    attrs: {
      arrays: _vm.audio,
      "active-round-id": _vm.busiActiveConver,
      "upload-type": _vm.uploadType,
      "api-process": _vm.apiProcess,
      "media-type": _vm.mediaType,
      "is-pure": ""
    }
  })], 1)] : _c("div", {
    staticClass: "objection-empty"
  }, [_c("div", {
    staticClass: "empty-content"
  }, [_c("i", {
    class: _vm.hasConvDetailPermission ? "iconfont icon-receiving-empty" : "iconfont icon-lock-fill"
  }), _c("span", {
    staticClass: "empty-text"
  }, [_vm._v(_vm._s(_vm.hasConvDetailPermission ? "暂无会话" : _vm.textTip))])])])], 2)])], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-content"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-empty"
  }), _c("span", {
    staticClass: "empty-text"
  }, [_vm._v("暂无客户标签")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-content"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-empty"
  }), _c("span", {
    staticClass: "empty-text"
  }, [_vm._v("暂无客户异议")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;