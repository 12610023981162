"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailNavbar = _interopRequireDefault(require("@/layout/components/DetailNavbar"));
var _mergeAppDataFilter = _interopRequireDefault(require("@/views/userInfo/components/mergeAppDataFilter"));
var _mergeAppDataTable = _interopRequireDefault(require("@/views/userInfo/components/mergeAppDataTable"));
var _mergeAppDataDialog = _interopRequireDefault(require("@/views/userInfo/components/mergeAppDataDialog"));
var _revertMergenceDialog = _interopRequireDefault(require("@/views/userInfo/components/revertMergenceDialog"));
var _mergeAppData = require("@/api/mergeAppData");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MergeAppDataPage',
  components: {
    Navbar: _DetailNavbar.default,
    MergeAppDataFilter: _mergeAppDataFilter.default,
    MergeAppDataTable: _mergeAppDataTable.default,
    MergeAppDataDialog: _mergeAppDataDialog.default,
    RevertMergenceDialog: _revertMergenceDialog.default
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 204,
      mergeDialogVisible: false,
      revertDialogVisible: false,
      revertMergenceInfo: {},
      recommendList: [],
      recommendTitle: '您可能想要选择',
      appIconUrl: 'http://sale-test.oss-cn-zhangjiakou.aliyuncs.com/appicon/wework.jpg',
      filterParams: {
        type: '',
        merge_status: 0
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loading: false,
      externalUserId: ''
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      const params = {
        page: this.currentPage,
        size: this.pageSize,
        filter: {
          ...this.filterParams
        }
      };
      this.loading = true;
      // NOTE 请求表格数据
      const res = await (0, _mergeAppData.getExternalContactList)(params);
      this.loading = false;
      if (res.code === 200) {
        this.tableData = res.results.contact_list;
        this.total = res.results.total_count;
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(curPage) {
      this.currentPage = curPage;
      this.getTableData();
    },
    handleFilterChange(filters) {
      this.filterParams = {
        ...filters
      };
      // 后端 merge_status 默认值需要传 0
      if (!this.filterParams.merge_status) {
        this.filterParams.merge_status = 0;
      }
      this.getTableData();
    },
    openMergeDialog(rowInfo) {
      this.externalUserId = rowInfo.external_user_id;
      const {
        remark_mobile: remarkMobile,
        name
      } = rowInfo;
      this.mergeDialogVisible = true;
      this.getDealContactList({
        filter: `${remarkMobile}` || name
      });
    },
    openRevertMergenceDialog(rowInfo) {
      this.revertMergenceInfo = rowInfo;
      this.revertDialogVisible = true;
    },
    async confirmToCancelMergence(params) {
      const res = await (0, _mergeAppData.revertMergence)({
        external_user_id: params
      });
      if (res.code === 200 && res.results.revert_result) {
        this.revertDialogVisible = false;
        this.getTableData();
      }
    },
    async getDealContactList(filter) {
      const res = await (0, _mergeAppData.getDealContactList)(filter);
      if (res.code === 200) {
        this.recommendTitle = '您可能想要选择';
        this.recommendList = res.results.deal_contact_list;
      }
    },
    closeMergeDialog() {
      this.externalUserId = '';
      this.mergeDialogVisible = false;
    },
    closeCancelMergenceDialog() {
      this.revertDialogVisible = false;
    },
    async submitForm(formData) {
      const res = await (0, _mergeAppData.mergeContact)(formData);
      if (res.code === 200 && res.results.merge_result) {
        this.getTableData();
        this.$message({
          type: 'success',
          message: '合并成功'
        });
      } else {
        this.$message({
          type: 'warning',
          message: '合并失败'
        });
      }
    },
    async filterDealContactList(filter) {
      if (!filter) {
        this.recommendTitle = '搜索结果';
        this.recommendList = [];
        return;
      }
      // NOTE  搜索框请求数据
      const res = await (0, _mergeAppData.getDealContactList)({
        filter
      });
      if (res.code === 200) {
        this.recommendTitle = '搜索结果';
        this.recommendList = res.results.deal_contact_list;
      }
    }
  }
};
exports.default = _default;