import { render, staticRenderFns } from "./ReminderSelect.vue?vue&type=template&id=024fd3b3&scoped=true&"
import script from "./ReminderSelect.vue?vue&type=script&setup=true&lang=js&"
export * from "./ReminderSelect.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024fd3b3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('024fd3b3')) {
      api.createRecord('024fd3b3', component.options)
    } else {
      api.reload('024fd3b3', component.options)
    }
    module.hot.accept("./ReminderSelect.vue?vue&type=template&id=024fd3b3&scoped=true&", function () {
      api.rerender('024fd3b3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TaskCenterDrawer/components/ReminderSelect.vue"
export default component.exports