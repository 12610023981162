"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskCenterDrawer = _interopRequireDefault(require("@/components/TaskCenterDrawer"));
var _taskCenter = require("@/api/taskCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskCenterButton',
  components: {
    TaskCenterDrawer: _TaskCenterDrawer.default
  },
  data() {
    return {
      taskCenterDrawerVisible: false,
      currentTaskDetailData: {},
      overtimeTaskNumber: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getOvertimeTaskNumber();
  },
  mounted() {
    this.$bus.$on('openTaskCenter', info => {
      this.currentTaskDetailData = info;
      this.openTaskCenterDrawer();
    });
  },
  beforeDestroy() {
    this.$bus.$off('openTaskCenter');
  },
  methods: {
    handelCloseTaskDrawer() {
      this.currentTaskDetailData = {};
      this.getOvertimeTaskNumber();
    },
    async getOvertimeTaskNumber() {
      const res = await (0, _taskCenter.getOvertimeTaskNumber)();
      if (res.code === 20000) {
        this.overtimeTaskNumber = res.results.overtime_number;
      }
    },
    // 任务中心
    openTaskCenterDrawer() {
      this.taskCenterDrawerVisible = true;
    }
  }
};
exports.default = _default;