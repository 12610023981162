"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-knowledge"
  }, [_c("div", {
    staticClass: "personal-knowledge-left"
  }, [_c("knowledge-filter", {
    attrs: {
      "knowledge-list": _vm.allKnowledgeList
    },
    on: {
      knowledgeChange: _vm.knowledgeChange
    }
  }), _c("personal-knowledge-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "personal-knowledge-table",
    attrs: {
      list: _vm.knowledgeTableList
    },
    on: {
      tableSortChange: _vm.tableSortChange
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.knowledgeTableParams.page_num,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.knowledgeTableParams.page_size,
      total: _vm.knowledgeTableTotal
    },
    on: {
      "size-change": _vm.tableSizeChange,
      "current-change": _vm.tableCurrentChange
    }
  })], 1)], 1), _c("personal-knowledge-conv", {
    staticClass: "personal-knowledge-conv",
    attrs: {
      "knowledge-list": _vm.allKnowledgeList,
      "knowledge-ids": _vm.knowledgeTableParams.knowledge_ids,
      filters: _vm.filters
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;