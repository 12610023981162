"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.title || (_vm.isEdit ? "编辑知识点" : "新建知识点"),
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      size: "600px",
      "custom-class": "create-knowledge-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "knowledgeConfig",
    staticClass: "knowledge-config"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.knowledgeConfig,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "form-item-container knowledge-base-info"
  }, [_vm.isHiddenType ? _c("el-form-item", {
    staticClass: "type required-right",
    attrs: {
      prop: "type",
      label: "添加方式",
      rules: {
        required: true
      }
    }
  }, [_c("el-radio", {
    attrs: {
      label: "create"
    },
    on: {
      change: _vm.toggleType
    },
    model: {
      value: _vm.knowledgeConfig.type,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeConfig, "type", $$v);
      },
      expression: "knowledgeConfig.type"
    }
  }, [_vm._v("新建知识点")]), _c("el-radio", {
    attrs: {
      label: "update"
    },
    on: {
      change: _vm.toggleType
    },
    model: {
      value: _vm.knowledgeConfig.type,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeConfig, "type", $$v);
      },
      expression: "knowledgeConfig.type"
    }
  }, [_vm._v("添加至已有知识点")])], 1) : _vm._e(), _c("el-form-item", {
    staticClass: "required-right",
    attrs: {
      prop: "name",
      label: "客户问题"
    }
  }, [_c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowledgeConfig.type === "create",
      expression: "knowledgeConfig.type === 'create'"
    }],
    attrs: {
      clearable: "",
      type: "textarea",
      autosize: {
        minRows: 1
      },
      placeholder: "请输入客户问题",
      maxlength: 300
    },
    model: {
      value: _vm.knowledgeConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeConfig, "name", $$v);
      },
      expression: "knowledgeConfig.name"
    }
  }), _vm.variableKnowledgeBase ? [_c("div", {
    staticClass: "knowledge-base-position"
  }, [_vm.visible && _vm.knowledgeConfig.type === "update" ? _c("KnowledgeBaseDropdown", {
    attrs: {
      "depend-permission": "",
      icon: "icon-arrow-down"
    }
  }) : _vm._e(), _c("SingleTreeSelect", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowledgeConfig.type === "update",
      expression: "knowledgeConfig.type === 'update'"
    }],
    ref: "radio-tree-select",
    attrs: {
      "tree-data": _vm.treeData,
      "remote-method": _vm.searchKnowledges,
      "search-params": {
        scroll_id: ""
      }
    },
    on: {
      change: _vm.knowledgeIdChange
    }
  })], 1)] : [_c("SingleTreeSelect", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowledgeConfig.type === "update",
      expression: "knowledgeConfig.type === 'update'"
    }],
    ref: "radio-tree-select",
    attrs: {
      "tree-data": _vm.treeData,
      "remote-method": _vm.searchKnowledges,
      "search-params": {
        scroll_id: ""
      }
    },
    on: {
      change: _vm.knowledgeIdChange
    }
  })]], 2), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowledgeConfig.type === "create",
      expression: "knowledgeConfig.type === 'create'"
    }],
    staticClass: "required-right",
    attrs: {
      prop: "position",
      label: "所在目录位置"
    },
    model: {
      value: _vm.knowledgeConfig.position,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeConfig, "position", $$v);
      },
      expression: "knowledgeConfig.position"
    }
  }, [_vm.variableKnowledgeBase ? _c("div", {
    staticClass: "knowledge-base-position"
  }, [_vm.visible && _vm.knowledgeConfig.type === "create" ? _c("KnowledgeBaseDropdown", {
    attrs: {
      "depend-permission": "",
      icon: "icon-arrow-down"
    }
  }) : _vm._e(), _c("knowledge-catalog-position-select", {
    ref: "positionSelect",
    attrs: {
      "current-node": _vm.knowledgePosition
    },
    on: {
      positionSelect: _vm.selectPosition
    }
  })], 1) : [_vm.visible ? _c("knowledge-catalog-position-select", {
    ref: "positionSelect",
    attrs: {
      "current-node": _vm.knowledgePosition
    },
    on: {
      positionSelect: _vm.selectPosition
    }
  }) : _vm._e()]], 2)], 1), _c("div", {
    staticClass: "main"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.knowledgeConfig.type === "create",
      expression: "knowledgeConfig.type === 'create'"
    }],
    staticClass: "form-item-container"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("回答技巧")]), _c("div", {
    staticClass: "answers-tech"
  }, _vm._l(_vm.knowledgeConfig.skill_list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "answer-item"
    }, [_c("el-form-item", {
      attrs: {
        prop: `skill_list.${index}`,
        rules: [{
          max: 100,
          message: "长度不能超过100个字符",
          trigger: "change"
        }]
      }
    }, [_c("el-input", {
      staticClass: "knowledge-qa-input",
      attrs: {
        type: "textarea",
        autosize: "",
        resize: "none",
        placeholder: "选填"
      },
      model: {
        value: _vm.knowledgeConfig.skill_list[index],
        callback: function ($$v) {
          _vm.$set(_vm.knowledgeConfig.skill_list, index, $$v);
        },
        expression: "knowledgeConfig.skill_list[index]"
      }
    })], 1), index ? _c("div", {
      staticClass: "operate-btn",
      on: {
        click: function ($event) {
          return _vm.deleteItem("skill_list", index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-delete"
    })]) : _c("div", {
      class: ["operate-btn", _vm.knowledgeConfig.skill_list.length >= 10 ? "disabled" : ""],
      on: {
        click: _vm.addAnswers
      }
    }, [_c("i", {
      staticClass: "iconfont icon-plus"
    })])], 1);
  }), 0)]), _c("div", {
    staticClass: "standard-tricks-container"
  }, [_c("div", {
    staticClass: "label"
  }, [_c("span", [_vm._v("标准话术")]), _c("span", {
    staticClass: "required-mark"
  }, [_vm._v("*")])]), _c("div", {
    staticClass: "standard-tricks"
  }, [_vm._l(_vm.knowledgeConfig.scene_reply_list, function (item, index) {
    return [_c("div", {
      key: index,
      staticClass: "content"
    }, [_c("div", {
      staticClass: "scene"
    }, [_c("el-form-item", {
      attrs: {
        prop: `scene_reply_list.${index}.scene_content`,
        rules: [{
          max: 20,
          message: "长度不能超过20个字符",
          trigger: "change"
        }]
      }
    }, [_c("tricks-scene", {
      on: {
        clear: function ($event) {
          item.scene_content = "";
        }
      },
      model: {
        value: item.scene_content,
        callback: function ($$v) {
          _vm.$set(item, "scene_content", $$v);
        },
        expression: "item.scene_content"
      }
    })], 1), _c("div", {
      staticClass: "scene-operate"
    }, [_c("move-knowledge-scene", {
      attrs: {
        order: index,
        list: _vm.knowledgeConfig.scene_reply_list
      },
      on: {
        move: _vm.handleSceneMove
      }
    }), _vm.scenesNum > 1 ? _c("div", {
      staticClass: "operate-btn no-border",
      on: {
        click: function ($event) {
          return _vm.deleteItem("scene_reply_list", index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()], 1)], 1), item.reply_list.length ? _c("div", {
      staticClass: "trick-list"
    }, [_c("mg-draggable", {
      model: {
        value: item.reply_list,
        callback: function ($$v) {
          _vm.$set(item, "reply_list", $$v);
        },
        expression: "item.reply_list"
      }
    }, _vm._l(item.reply_list, function (reply, replyIndex) {
      return _c("div", {
        key: replyIndex,
        staticClass: "trick-item"
      }, [_vm.showDragIcon(item.reply_list) ? _c("i", {
        staticClass: "iconfont move icon-drag-dot"
      }) : _vm._e(), _c("el-form-item", {
        attrs: {
          prop: `scene_reply_list.${index}.reply_list.${replyIndex}.reply_content`,
          rules: [{
            required: true,
            message: "请输入话术",
            trigger: "change"
          }, {
            max: 1000,
            message: "长度不能超过1000个字符",
            trigger: "change"
          }]
        }
      }, [_c("el-input", {
        staticClass: "knowledge-qa-input",
        attrs: {
          placeholder: "标准话术，必填",
          type: "textarea",
          autosize: {
            minRows: 1,
            maxRows: 5
          },
          resize: "none"
        },
        model: {
          value: _vm.knowledgeConfig.scene_reply_list[index].reply_list[replyIndex].reply_content,
          callback: function ($$v) {
            _vm.$set(_vm.knowledgeConfig.scene_reply_list[index].reply_list[replyIndex], "reply_content", $$v);
          },
          expression: "\n                            knowledgeConfig.scene_reply_list[index]\n                              .reply_list[replyIndex].reply_content\n                          "
        }
      })], 1), replyIndex ? _c("div", {
        staticClass: "operate-btn",
        on: {
          click: function ($event) {
            return _vm.deleteTrickItem(index, replyIndex);
          }
        }
      }, [_c("i", {
        staticClass: "iconfont icon-delete"
      })]) : _c("div", {
        class: ["operate-btn", _vm.knowledgeConfig.scene_reply_list[index].reply_list.length >= 10 ? "disabled" : ""],
        on: {
          click: function ($event) {
            return _vm.addTrickToScene(index);
          }
        }
      }, [_c("i", {
        staticClass: "iconfont icon-plus"
      })])], 1);
    }), 0)], 1) : _vm._e()])];
  }), _c("div", {
    staticClass: "btn-container"
  }, [_c("el-button", {
    staticClass: "add-btn",
    attrs: {
      icon: "iconfont icon-plus",
      disabled: _vm.scenesNum >= 10
    },
    on: {
      click: function ($event) {
        return _vm.addScene();
      }
    }
  }, [_vm._v("添加场景话术")])], 1)], 2)])])])], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    staticClass: "mg-round-button",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    staticClass: "mg-round-button",
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 保存 ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;