"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _EditKnowledgeBaseConfig = _interopRequireDefault(require("./components/knowledgeLibrary/EditKnowledgeBaseConfig"));
var _KnowledgeBasePermissionDrawer = _interopRequireDefault(require("./components/knowledgeLibrary/KnowledgeBasePermissionDrawer"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _DeleteConfirmationDialog = _interopRequireDefault(require("./components/knowledgeLibrary/DeleteConfirmationDialog"));
var _KnowledgeLibraryCardView = _interopRequireDefault(require("./components/knowledgeLibrary/KnowledgeLibraryCardView"));
var _SwitchViewButton = _interopRequireDefault(require("@/views/compliance/components/SwitchViewButton"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary.js");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeBaseLibrary',
  components: {
    MenuToggleBtn: _MenuToggleBtn.default,
    KnowledgeBasePermissionDrawer: _KnowledgeBasePermissionDrawer.default,
    NoData: _index.default,
    EditKnowledgeBaseConfig: _EditKnowledgeBaseConfig.default,
    DeleteConfirmationDialog: _DeleteConfirmationDialog.default,
    SwitchViewButton: _SwitchViewButton.default,
    KnowledgeLibraryCardView: _KnowledgeLibraryCardView.default
  },
  beforeRouteEnter(to, from, next) {
    const {
      id,
      fid
    } = to.query;
    if (id && fid) {
      next({
        path: _routeMap.ROUTES.knowledgeLibraryDetail,
        query: to.query
      });
    }
    next();
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpand: true,
      getKnowledgeBaseListLoading: false,
      knowledgeBaseList: [],
      topKnowledgeBaseList: [],
      knowledgeBaseConfigVisible: false,
      handleKnowledgeBaseType: 'create',
      knowledgeBasePermissionVisible: false,
      currentKnowledgeBase: {},
      deleteConfirmationDialogVisible: false,
      getTopKnowledgeBaseListLoading: false,
      viewType: 'card'
    };
  },
  created() {
    this.initKnowledgeBase();
    this.viewType = localStorage.getItem('knowledgeLibraryView') || 'card';
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    hasKnowledgeBasePermission() {
      return this.api_permissions.includes('/webapi/training/knowledge_base/create');
    }
  },
  methods: {
    selectView(command) {
      localStorage.setItem('knowledgeLibraryView', command);
      this.viewType = command;
    },
    initKnowledgeBase() {
      // 获取常用知识库列表
      this.getTopKnowledgeBaseList();
      // 获取知识库列表
      this.getKnowledgeBaseList();
    },
    // 编辑知识库的名字和简介 / 新建知识库
    editKnowledgeBaseConfig(_ref) {
      let {
        id,
        name,
        profile
      } = _ref;
      // 新建知识库，只需要更新知识库表格
      if (this.handleKnowledgeBaseType === 'create') {
        this.getKnowledgeBaseList();
      } else {
        // 重命名修改对应的知识库/常用知识库的名称和简介即可
        this.knowledgeBaseList.find(item => {
          if (item.id === id) {
            item.name = name;
            item.profile = profile;
          }
        });
        this.topKnowledgeBaseList.find(item => {
          if (item.id === id) {
            item.name = name;
            item.profile = profile;
          }
        });
      }
    },
    goDetail(_ref2) {
      let {
        id
      } = _ref2;
      this.$router.push({
        path: _routeMap.ROUTES.knowledgeLibraryDetail,
        query: {
          knowledgeBaseId: id
        }
      });
    },
    handleKnowledgeBaseConfig(type, val) {
      this.handleKnowledgeBaseType = type;
      this.knowledgeBaseConfigVisible = true;
      if (type === 'rename') {
        this.$refs['edit-knowledge-base-config'].knowledgeBaseConfig = (0, _commonFunc.deepClone)(val);
      }
    },
    modifyPermission(val) {
      this.knowledgeBasePermissionVisible = true;
      this.currentKnowledgeBase = val;
    },
    getDropdownConfigs(item) {
      const dropdownConfigs = [{
        type: 'remove',
        label: '移出常用',
        icon: 'icon-thumbtack-fill'
      }];
      if (item.permission > 1) {
        dropdownConfigs.push({
          type: 'rename',
          label: '重命名',
          icon: 'icon-edit'
        }, {
          type: 'permission',
          label: '权限',
          icon: 'icon-unlock'
        }, {
          type: 'delete',
          label: '删除',
          icon: 'icon-delete'
        });
      }
      return dropdownConfigs;
    },
    getTableHeads() {
      return [{
        label: '名称',
        prop: 'name',
        minWidth: 200
      }, {
        label: '简介',
        prop: 'profile',
        minWidth: 200
      }, {
        label: '创建人',
        prop: 'create_by',
        minWidth: 128
      }, {
        label: '最近创建',
        prop: 'create_at',
        minWidth: 160
      }, {
        label: '',
        prop: 'is_top',
        minWidth: 30
      }];
    },
    handleCommand(data, type) {
      console.log('==data, event==', data, type);
      this.currentKnowledgeBase = data;
      switch (type) {
        case 'remove':
          this.updateKnowledgeBaseTopStatus(data);
          break;
        case 'rename':
          this.handleKnowledgeBaseConfig('rename', data);
          break;
        case 'permission':
          this.modifyPermission(data);
          break;
        case 'delete':
          this.deleteConfirmationDialogVisible = true;
          break;
      }
    },
    async updateKnowledgeBaseTopStatus(data) {
      const currentStatus = !data.is_top;
      const res = await (0, _knowledgeLibrary.updateKnowledgeBaseTopStatus)({
        base_id: data.id,
        is_top: currentStatus
      });
      if (res.code === 20000) {
        this.$message.success(currentStatus ? '添加成功' : '移出成功');
        this.knowledgeBaseList.find(item => {
          if (item.id === data.id) {
            item.is_top = currentStatus;
          }
        });
        if (currentStatus) {
          this.topKnowledgeBaseList.unshift(data);
        } else {
          this.deleteTopKnowledgeBase();
        }
      }
    },
    deleteKnowledgeBase() {
      this.knowledgeBaseList = this.knowledgeBaseList.filter(item => item.id !== this.currentKnowledgeBase.id);
      this.deleteTopKnowledgeBase();
    },
    deleteTopKnowledgeBase() {
      this.topKnowledgeBaseList = this.topKnowledgeBaseList.filter(item => item.id !== this.currentKnowledgeBase.id);
    },
    async getKnowledgeBaseList() {
      this.getKnowledgeBaseListLoading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeBaseList)().finally(() => {
        this.getKnowledgeBaseListLoading = false;
      });
      if (res.code === 20000) {
        this.knowledgeBaseList = res.results.data;
      }
    },
    async getTopKnowledgeBaseList() {
      // 常用知识库列表
      this.getTopKnowledgeBaseListLoading = true;
      const res = await (0, _knowledgeLibrary.getTopKnowledgeBaseList)().finally(() => {
        this.getTopKnowledgeBaseListLoading = false;
      });
      if (res.code === 20000) {
        this.topKnowledgeBaseList = res.results.data;
      }
    }
  }
};
exports.default = _default;