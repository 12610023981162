"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FilterTab = _interopRequireDefault(require("@/components/FilterTab"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _AccountRecommendItem = _interopRequireDefault(require("./AccountRecommendItem"));
var _vuex = require("vuex");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _assist = require("@/api/assist.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountRecommend',
  components: {
    FilterTab: _FilterTab.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    AccountRecommendItem: _AccountRecommendItem.default,
    MembersFinder: _MembersFinder.default,
    SpaceButton: _SpaceButton.default,
    NoData: _NoData.default
  },
  data() {
    return {
      accountRecommendCount: 0,
      activeFilterTab: '',
      viewRangeOptions: [{
        label: '我的',
        value: 'my'
      }, {
        label: '团队',
        value: 'team'
      }],
      viewRangeType: 'my',
      recommendList: [],
      recommendListLoading: false,
      listPagingLoading: false,
      listPagingNomore: false,
      listPagingCursor: 0,
      listPagingSize: 10,
      eventList: [],
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      hasTeamPermisson: false,
      // 是否有查看团队其他人数据权限
      owners: null,
      activeItemId: null // 打开创建任务时的需要激活id
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    disabled() {
      return this.recommendListLoading || this.listPagingNomore;
    }
  },
  watch: {
    activeFilterTab() {
      this.initData();
      this.getRecommendlist();
    },
    viewRangeType(val) {
      if (val === 'my') {
        this.owners = null;
      }
    },
    workspaceInfo: {
      handler: function () {
        this.initData();
        this.getAssistTodoEventList();
      },
      deep: true
    },
    owners: {
      handler: function () {
        this.initData();
        this.getRecommendlist();
      },
      deep: true
    }
  },
  created() {
    this.getAssistTodoEventList();
    this.getAssistDataPermisison();
  },
  methods: {
    filterTabClick(val) {
      this.activeFilterTab = val;
    },
    getRecommendlist() {
      const data = {
        cursor: this.listPagingCursor,
        size: this.listPagingSize,
        owners: this.owners,
        space_id: this.workspaceInfo.id,
        event_id: this.activeFilterTab || 0
      };
      this.recommendListLoading = true;
      const beforeActiveTab = this.activeFilterTab;
      (0, _assist.getAssistAccountRecommendList)(data).then(res => {
        const afterActiveTab = this.activeFilterTab;
        if (res.code === 20000) {
          if (beforeActiveTab === afterActiveTab) {
            const results = res.results;
            const {
              cursor,
              has_more,
              data_list
            } = results;
            this.listPagingCursor = cursor || 0;
            this.listPagingNomore = !has_more;
            this.recommendList.push(...data_list);
          }
          // 清空助手未读数
          this.$store.commit('user/SET_ASSIST_UNREAD_COUNT', 0);
        }
      }).finally(() => {
        this.recommendListLoading = false;
      });
    },
    getAssistTodoEventList() {
      (0, _assist.getAssistTodoEventList)({
        space_id: this.workspaceInfo.id
      }).then(res => {
        if (res.code === 20000) {
          this.eventList = res.results.event_list;
          this.getRecommendlist();
        }
      });
    },
    handleMembersChange(members) {
      this.owners = members;
    },
    initData() {
      this.listPagingLoading = false;
      this.listPagingNomore = false;
      this.listPagingCursor = 0;
      this.recommendList = [];
    },
    changeActiveDialogId(id) {
      this.activeItemId = id;
    },
    getAssistDataPermisison() {
      (0, _assist.getAssistDataPermisison)().then(res => {
        this.hasTeamPermisson = !res.results.is_self;
      });
    }
  }
};
exports.default = _default;