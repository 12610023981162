"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ruleTypeOptions = void 0;
const ruleTypeOptions = {
  key_words: {
    value: 'key_words',
    label: '关键词',
    unit: '',
    initRange: {
      filter_relation: 'and',
      textFilterList: [{
        inverse: false,
        pattern: '',
        role: 'default'
      }]
    }
  },
  event_engine: {
    value: 'event_engine',
    label: '关键事件',
    unit: '',
    initRange: {
      event_engine_relation: 'and',
      keyEventCascaderList: [{
        keyEventName: [],
        inverse: false
      }]
    }
  },
  event_engine_order: {
    value: 'event_engine_order',
    label: '关键事件顺序',
    unit: '',
    initRange: {
      keyEventOrderCascaderList: [{
        keyEventName: [],
        inverse: false
      }, {
        keyEventName: [],
        inverse: false
      }]
    }
  },
  account_label: {
    value: 'account_label',
    label: '客户标签',
    unit: '',
    initRange: {
      relation: 'and',
      value: []
    }
  },
  duration: {
    value: 'duration',
    label: '会话时长',
    unit: 'ms',
    initRange: []
  },
  sale_sentence_count: {
    value: 'sale_sentence_count',
    label: '销售发言条数',
    unit: '条',
    initRange: []
  },
  custom_sentence_count: {
    value: 'custom_sentence_count',
    label: '客户发言条数',
    unit: '条',
    initRange: []
  },
  percent_speak: {
    value: 'percent_speak',
    label: '销售说话占比',
    unit: '%',
    initRange: []
  },
  max_sales: {
    value: 'max_sales',
    label: '最长销售独白',
    unit: 'ms',
    initRange: []
  },
  max_customer: {
    value: 'max_customer',
    label: '最长客户叙述',
    unit: 'ms',
    initRange: []
  },
  discuss: {
    value: 'discuss',
    label: '深入讨论次数',
    unit: '次',
    initRange: []
  },
  question: {
    value: 'question',
    label: '销售提问次数',
    unit: '次',
    initRange: []
  },
  customer_question: {
    value: 'customer_question',
    label: '客户提问次数',
    unit: '次',
    initRange: []
  },
  sales_speed: {
    value: 'sales_speed',
    label: '销售语速',
    unit: '字/分钟',
    initRange: []
  },
  customer_speed: {
    value: 'customer_speed',
    label: '客户语速',
    unit: '字/分钟',
    initRange: []
  },
  max_silence_duration: {
    value: 'max_silence_duration',
    label: '最长无人发言时间',
    unit: '秒',
    initRange: []
  },
  hang_up: {
    value: 'hang_up',
    label: '挂断方',
    unit: '',
    initRange: 1,
    mappings: {
      1: {
        value: 1,
        label: '客户'
      },
      2: {
        value: 2,
        label: '销售'
      }
    }
  }
};
exports.ruleTypeOptions = ruleTypeOptions;