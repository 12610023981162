"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _ModelItemTable = _interopRequireDefault(require("../modelManagement/components/ModelItemTable.vue"));
var _usePagination = require("../modelManagement/hooks/use-pagination.js");
var _modelManagement = require("@/api/modelManagement.js");
var _modelInfo = require("./hooks/modelInfo");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 获取当前模型id和名字
var _default = {
  __name: 'index',
  setup(__props) {
    const {
      modelId,
      modelName,
      modelType
    } = (0, _modelInfo.useGetModelInfo)();
    const tableLoading = (0, _vue.ref)(false);
    const allVersionsList = (0, _vue.ref)([]);
    const getModelVersionListApi = async () => {
      tableLoading.value = true;
      const res = await (0, _modelManagement.getModelVersionList)({
        model_id: modelId,
        page: currentPage.value,
        size: pageSize.value
      }).finally(() => tableLoading.value = false);
      if (res.code === 20000) {
        allVersionsList.value = res.results.versions;
        total.value = res.results.count;
      }
    };

    // 重新训练
    const {
      retryTrainAsync
    } = (0, _modelInfo.useRetryTrain)(getModelVersionListApi);
    const retryTrain = model => {
      retryTrainAsync(modelId, model.version);
    };

    // 分页相关方法
    const {
      currentPage,
      pageSize,
      total,
      onSizeChange
    } = (0, _usePagination.usePagination)(getModelVersionListApi);

    // 返回上一页
    const handleBack = () => {
      window.history.back();
    };
    (0, _vue.onMounted)(() => {
      getModelVersionListApi();
    });
    return {
      __sfc: true,
      modelId,
      modelName,
      modelType,
      tableLoading,
      allVersionsList,
      getModelVersionListApi,
      retryTrainAsync,
      retryTrain,
      currentPage,
      pageSize,
      total,
      onSizeChange,
      handleBack,
      MgPagination: _MgPagination.default,
      ModelItemTable: _ModelItemTable.default
    };
  }
};
exports.default = _default;