"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'MoveKnowledgeScene',
  props: {
    order: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    visible() {
      return this.interfacePermission.operateKnowledge && this.list.length > 1;
    }
  },
  methods: {
    moveUp() {
      if (!this.order) return;
      this.$emit('move', 'up', this.order);
    },
    moveDown() {
      if (this.order === this.list.length - 1) return;
      this.$emit('move', 'down', this.order);
    }
  }
};
exports.default = _default;