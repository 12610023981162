"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-scope-tags"
  }, _vm._l(_vm.tags, function (tag, index) {
    return _c("span", {
      key: index,
      staticClass: "scope__tag"
    }, [_c("span", {
      staticClass: "tag__label"
    }, [_vm._v(_vm._s(tag.label) + "：")]), _c("span", {
      staticClass: "tag__value"
    }, [_vm._v(_vm._s(tag.value))])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;