"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "event-tab-tags"
  }, _vm._l(_vm.tagsList, function (tag, index) {
    return _c("div", {
      key: tag.id,
      class: ["tags", {
        active: tag.is_selected
      }],
      on: {
        click: function ($event) {
          return _vm.toggleSelectTag(!tag.is_selected, index);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;