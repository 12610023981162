"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _parivatizationDeployment = require("@/api/parivatizationDeployment");
var _organization = require("@/api/organization");
var _message = require("@/api/message");
var _assist = require("@/api/assist");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _default2 = _interopRequireDefault(require("@/assets/default.svg"));
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _handleRoute = require("@/utils/handleRoute");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _settings = _interopRequireDefault(require("@/settings"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const getDefaultState = () => {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: _default2.default,
    id: null,
    message_unread_count: 0,
    // 消息中心未读数
    assist_unread_count: 0,
    // 助手未读数
    api_permissions: [],
    email: '',
    loginTel: '',
    login_account: '',
    login_method: '',
    need_refresh_router: false,
    root_path: '/',
    main_department_id: 0,
    org_id: 0,
    has_outbound: false,
    has_video_permission: false,
    has_tencent_meeting: false,
    has_wework: false,
    trial_expire_date: '',
    user_type: '',
    webRoutes: [],
    routesTree: [],
    has_workspace: false,
    is_two_factor: false,
    time_zone: _momentTimezone.default.tz.guess(true)
  };
};
const state = Object.assign({
  page_size: 10,
  org_name: '',
  orgs: [],
  permission: [],
  history_activity: '',
  history_conversation: '',
  trial_expire_date: '',
  user_type: '',
  org_display_info: {
    // 团队的logo,icon,title信息
    org_logo_url: '',
    org_icon_url: '',
    org_title: '',
    org_login_logo_url: '',
    org_name: ''
  },
  org_config: {
    audio_upload_limit: 0
  },
  // 根据前端路由控制
  modulePermission: {},
  // 根据后端路由控制
  interfacePermission: {},
  is_developer: false,
  redirect_uri: '',
  isConfirmChatAgreement: false
}, getDefaultState());
const mutations = {
  SET_TIME_ZONE: (state, timeZone) => {
    state.time_zone = timeZone;
  },
  SET_MODULE_PERMISSION: (state, modulePermissionMap) => {
    state.modulePermission = modulePermissionMap;
  },
  SET_INTERFACE_PERMISSION: (state, interfacePermission) => {
    state.interfacePermission = interfacePermission;
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    (0, _auth.setToken)(token);
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_PAGE_SIZE: (state, page_size) => {
    state.page_size = page_size;
  },
  SET_ORG_ID: (state, org_id) => {
    state.org_id = org_id;
  },
  SET_ORG_NAME: (state, org_name) => {
    state.org_name = org_name;
  },
  SET_ORGS: (state, orgs) => {
    state.orgs = orgs;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_LOGIN_TEL: (state, tel) => {
    state.loginTel = tel;
  },
  SET_LOGIN_ACCOUNT: (state, account) => {
    state.login_account = account;
  },
  SET_LOGIN_METHOD: (state, method) => {
    state.login_method = method;
  },
  SET_API_PERMISSIONS: (state, permissions) => {
    state.api_permissions = permissions;
  },
  SET_REFRESHROUTER: (state, need_refresh_router) => {
    state.need_refresh_router = need_refresh_router;
  },
  SET_MAIN_DEPARTMENT_ID: (state, main_department_id) => {
    state.main_department_id = main_department_id;
  },
  SET_HAS_OUTBOUND: state => {
    state.has_outbound = state.api_permissions.includes('/api/micro_record/outbound/config/get');
    state.has_outbound = false;
  },
  SET_HAS_TENCENT_MEETING: (state, tencent_meeting) => {
    state.has_tencent_meeting = tencent_meeting;
  },
  SET_HAS_WEWORK: (state, wework) => {
    state.has_wework = wework;
  },
  SET_HAS_VIDEO_PERMISSION: state => {
    state.has_video_permission = state.has_tencent_meeting;
  },
  SET_HISTORY_ACTIVITY: (state, history_activity) => {
    state.history_activity = history_activity;
  },
  SET_HISTORY_CONVERSATION: (state, history_conversation) => {
    state.history_conversation = history_conversation;
  },
  SET_USER_TYPE: (state, user_type) => {
    state.user_type = user_type;
  },
  SET_TRIAL_EXPIRE_DATE: (state, trial_expire_date) => {
    state.trial_expire_date = trial_expire_date;
  },
  SET_HAS_WORKSPACE: (state, has_workspace) => {
    state.has_workspace = has_workspace;
  },
  SET_IS_TWO_FACTOR: (state, is_two_factor) => {
    state.is_two_factor = is_two_factor;
  },
  SET_WEB_ROUTES: (state, web_routes) => {
    state.webRoutes = web_routes;
  },
  SET_ROUTES_TREE: (state, routes_tree) => {
    state.routesTree = routes_tree;
  },
  SET_REDIRECT_URI: (state, redirect_uri) => {
    state.redirect_uri = redirect_uri;
  },
  SET_IS_CONFIRM_CHAT_AGREEMENT: (state, isConfirm) => {
    state.isConfirmChatAgreement = isConfirm;
  },
  RESET_INFO: () => {
    (0, _auth.removeToken)(); // 移除cookie
    (0, _router.resetRouter)(); // 重置路由
    setTimeout(() => {
      Object.assign(state, getDefaultState()); // 重置信息
      sessionStorage.clear(); // 清除本地缓存
    }, 10);
  },
  SET_ORG_DISPLAY_INFO: (state, org_info) => {
    const {
      org_icon_url,
      org_logo_url,
      org_title,
      org_name,
      org_login_logo_url,
      audio_upload_limit,
      is_show_help_center
    } = org_info;
    state.org_display_info.org_icon_url = org_icon_url;
    state.org_display_info.org_logo_url = org_logo_url;
    state.org_display_info.org_title = org_title;
    state.org_display_info.org_login_logo_url = org_login_logo_url;
    state.org_display_info.org_name = org_name;
    state.org_config.audio_upload_limit = audio_upload_limit;
    state.org_display_info.is_show_help_center = !!is_show_help_center;
  },
  SET_MESSAGE_UNREAD_COUNT: (state, count_info) => {
    state.message_unread_count = count_info[0];
  },
  SET_ASSIST_UNREAD_COUNT: (state, count) => {
    state.assist_unread_count = count;
  },
  SET_IS_DEVELOPER: (state, val) => {
    state.is_developer = val;
  }
};
const actions = {
  // 注册
  register(_ref, userInfo) {
    let {
      commit
    } = _ref;
    return new Promise((resolve, reject) => {
      (0, _user.register)(userInfo).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user login
  login(_ref2, _ref3) {
    let {
      commit
    } = _ref2;
    let {
      userInfo,
      currentMode
    } = _ref3;
    // const { username, password } = userInfo
    const mothod = currentMode === 'equalInsurance' ? _user.equalInsuranceLogin : _user.login;
    return new Promise((resolve, reject) => {
      mothod(userInfo).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        commit('SET_ID', results.id);
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 私有化部署 登录
  accountLogin(_ref4, userInfo) {
    let {
      commit
    } = _ref4;
    // const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      (0, _parivatizationDeployment.accountLogin)(userInfo).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        commit('SET_ID', results.id);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user loginbycode
  loginByCode(_ref5, userInfo) {
    let {
      commit
    } = _ref5;
    return new Promise((resolve, reject) => {
      console.log(userInfo, 'userInfo');
      (0, _user.loginByCode)(userInfo).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        commit('SET_ID', results.id);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // login with google email
  loginByEmail(_ref6, token) {
    let {
      commit
    } = _ref6;
    return new Promise((resolve, reject) => {
      (0, _user.loginByEmail)(token).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        commit('SET_ID', results.id);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 单点登录
  loginBySso(_ref7, userInfo) {
    let {
      commit
    } = _ref7;
    return new Promise((resolve, reject) => {
      (0, _user.loginBySso)(userInfo).then(response => {
        const {
          results
        } = response;
        commit('SET_TOKEN', results.session);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  setTimeZone(_ref8, timeZone) {
    let {
      commit
    } = _ref8;
    if (timeZone) {
      commit('SET_TIME_ZONE', timeZone);
    } else {
      const zone = _momentTimezone.default.tz.guess(true);
      commit('SET_TIME_ZONE', zone);
    }
  },
  // get user info
  getInfo(_ref9) {
    let {
      commit,
      state
    } = _ref9;
    return new Promise((resolve, reject) => {
      (0, _user.getInfo)().then(response => {
        const {
          code,
          results
        } = response;
        if (code === 20000) {
          if (!results) {
            reject('获取用户信息失败');
          }
          const {
            id,
            name,
            main,
            avatar,
            page_size,
            organization_id,
            organization_name,
            email,
            tel,
            login_account,
            login_method,
            permissions,
            app_permissions,
            history_activity,
            history_conversation,
            user_type,
            trial_expire_date,
            web_routes,
            has_workspace,
            configs,
            is_two_factor
          } = results;
          const avatarUrl = avatar || '';
          commit('SET_API_PERMISSIONS', permissions);
          commit('SET_NAME', name);
          commit('SET_AVATAR', avatarUrl);
          commit('SET_ID', id);
          commit('SET_PAGE_SIZE', page_size);
          commit('SET_ORG_ID', organization_id);
          commit('SET_ORG_NAME', organization_name);
          commit('SET_EMAIL', email);
          commit('SET_LOGIN_TEL', tel);
          commit('SET_LOGIN_ACCOUNT', login_account);
          commit('SET_LOGIN_METHOD', login_method);
          commit('SET_HAS_TENCENT_MEETING', app_permissions.tencent_meeting);
          commit('SET_HAS_WEWORK', app_permissions.wework_internal_app);
          commit('SET_HAS_OUTBOUND');
          commit('SET_HAS_VIDEO_PERMISSION');
          commit('SET_MAIN_DEPARTMENT_ID', main);
          commit('SET_HISTORY_ACTIVITY', history_activity);
          commit('SET_HISTORY_CONVERSATION', history_conversation);
          commit('SET_USER_TYPE', user_type);
          commit('SET_TRIAL_EXPIRE_DATE', trial_expire_date);
          commit('SET_HAS_WORKSPACE', has_workspace);
          // 希音双因子验证
          commit('SET_IS_TWO_FACTOR', is_two_factor);
          // 后端返回来的数据结构
          commit('SET_WEB_ROUTES', web_routes);
          // 前端处理之后树形结构
          commit('SET_ROUTES_TREE', (0, _handleRoute.formatRouterTree)(JSON.parse(JSON.stringify(web_routes))));
          // 生成模块权限
          // generateModulePermissionMap(state.routersTree)
          commit('SET_MODULE_PERMISSION', generateModulePermissionMap(state.routesTree));
          commit('SET_INTERFACE_PERMISSION', generateInterfacePermissionMap(state.api_permissions));
          commit('SET_IS_CONFIRM_CHAT_AGREEMENT', !configs.includes('ai_assistant'));
          resolve(results);
          if (id && organization_id && window.collectEvent && location.host.includes('megaview.com')) {
            // 打点
            window.collectEvent('init', {
              app_id: 20001640,
              channel_domain: 'https://gator.volces.com',
              log: true,
              // true:开启日志，false:关闭日志
              autotrack: false,
              // 全埋点开关，true开启，false关闭
              enable_stay_duration: true,
              spa: true
            });
            // 此处可添加设置uuid、设置公共属性等代码
            window.collectEvent('config', {
              user_unique_id: id
            });
            window.collectEvent('profileSet', {
              org_id: organization_id,
              // 值支持字符串，数字，数组
              org_name: organization_name,
              name,
              login_account
            });
            window.collectEvent('start'); // 通知SDK设置完毕，可以真正开始发送事件了
          }
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user logout
  logout(_ref10) {
    let {
      commit,
      state
    } = _ref10;
    return new Promise((resolve, reject) => {
      (0, _user.logout)(state.id).then(() => {
        commit('RESET_INFO');
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 更新用户pagesize
  updatePageSize(_ref11, page_size) {
    let {
      commit
    } = _ref11;
    return new Promise((resolve, reject) => {
      (0, _user.updatePageSize)(page_size).then(() => {
        commit('SET_PAGE_SIZE', page_size);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 获取团队显示信息
  getOrgInfo(_ref12) {
    let {
      commit
    } = _ref12;
    return new Promise((resolve, reject) => {
      (0, _organization.getOrgInfo)().then(res => {
        commit('SET_ORG_DISPLAY_INFO', res.results);
        (0, _commonFunc.changeFavicon)(res.results.org_icon_url);
        if (res.results.org_name === '小站教育') {
          document.title = res.results.org_title;
        }
        resolve(res.results);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 获取小站的团队显示信息
  getXiaozhanInfo(_ref13) {
    let {
      commit
    } = _ref13;
    return new Promise((resolve, reject) => {
      (0, _organization.getXiaozhanInfo)({
        org: 'xiaozhan'
      }).then(res => {
        commit('SET_ORG_DISPLAY_INFO', res.results);
        (0, _commonFunc.changeFavicon)(res.results.org_icon_url);
        document.title = res.results.org_title;
        resolve(res.results);
      }).catch(error => {
        document.title = _settings.default.title;
        reject(error);
      });
    });
  },
  async getMessageUnreadCount(_ref14) {
    let {
      commit
    } = _ref14;
    const res = await (0, _message.getUnreadCount)();
    commit('SET_MESSAGE_UNREAD_COUNT', res.results);
  },
  async getAssistUnreadCount(_ref15) {
    let {
      commit
    } = _ref15;
    const res = await (0, _assist.getAssistUnreadCount)();
    commit('SET_ASSIST_UNREAD_COUNT', res.results.count);
  }
};
function generateModulePermissionMap(routesTree) {
  const map = {};
  const modulePermissionMap = {};
  for (const path in _routeMap.ROUTES) {
    map[_routeMap.ROUTES[path]] = path;
  }
  generateRoutesArray(routesTree);
  function generateRoutesArray(routersTree) {
    routersTree.map(item => {
      if (map[item.link || item.path]) {
        modulePermissionMap[map[item.link || item.path]] = true;
      }
      if (item !== null && item !== void 0 && item.children) {
        generateRoutesArray(item.children);
      }
    });
  }
  return modulePermissionMap;
}
function generateInterfacePermissionMap(interfaceArr) {
  const map = {};
  const interfacePermissionMap = {};
  for (const path in _routeMap.INTERFACE) {
    map[_routeMap.INTERFACE[path]] = path;
  }
  interfaceArr.map(item => {
    if (map[item]) {
      interfacePermissionMap[map[item]] = true;
    }
  });
  return interfacePermissionMap;
}
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;