"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _LearnCard = _interopRequireDefault(require("./LearnCard.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LearnContent',
  components: {
    MgDraggable: _MgDraggable.default,
    LearnCard: _LearnCard.default
  },
  data() {
    return {
      localCheckedKnowledgeScript: [],
      activeNames: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript'])
  },
  watch: {
    checkedKnowledgeScript: {
      handler(val) {
        this.localCheckedKnowledgeScript = (0, _commonFunc.deepClone)(val);
      },
      immediate: true
    }
  },
  methods: {
    async dragEnd() {},
    deleteKnowledge(item) {
      _store.default.commit('training/SET_KNOWLEDGE_SCRIPT_DELETE', item.knowledge_id);
    }
  }
};
exports.default = _default;