"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    ref: "tableRef",
    attrs: {
      data: _vm.data,
      height: _vm.tableHeight,
      "row-class-name": _setup.rowClassName
    },
    on: {
      "selection-change": _setup.onSelectionChange,
      "row-click": _setup.onRowClick,
      "cell-click": _setup.onCellClick
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      selectable: _setup.handleSelectable
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "metrics_name",
      label: "名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "gists",
      label: "指标要点"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "gist-box"
        }, [row.gists.length ? _vm._l(row.gists, function (gist, index) {
          return _c("p", {
            key: index
          }, [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(gist.content) + " ")]);
        }) : [_c("p", [_vm._v("-")])]], 2)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;