"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TricksPractice = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/TricksPractice.vue"));
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RecordingAudioDrawer',
  components: {
    TricksPractice: _TricksPractice.default
  },
  provide() {
    return {
      uploadVoiceMethod: _coachingCenter.uploadTemplateRecord,
      deleteVoiceMethod: _coachingCenter.deleteTemplateRecord,
      audioFilePathPrefix: 'reply_record_standard'
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      var _this$$refs$tricksPr, _this$$refs$tricksPr$;
      const hasRecording = (_this$$refs$tricksPr = this.$refs['tricks-practice']) === null || _this$$refs$tricksPr === void 0 ? void 0 : (_this$$refs$tricksPr$ = _this$$refs$tricksPr.$refs['audio-recorder']) === null || _this$$refs$tricksPr$ === void 0 ? void 0 : _this$$refs$tricksPr$.some(item => item.status === 'recording');
      if (hasRecording) {
        this.$message.warning('请结束当前录音后再关闭');
        return;
      }
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;