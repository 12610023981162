"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conv-flow-card"
  }, [_c("div", [_c("h1", {
    staticClass: "folder-name"
  }, [_c("nobr", [_vm._v(_vm._s(_vm.folder.name))])], 1), _c("p", [_c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.folder.conversation_count >= 20 ? "20+" : _vm.folder.conversation_count) + " ")]), _vm._v("个会话 ")]), _c("p", [_c("span", {
    staticClass: "name"
  }, [_vm._v("创建人 " + _vm._s(_vm.folder.create_by.name))]), _c("span", {
    staticClass: "date"
  }, [_vm._v("更新日期 " + _vm._s(_vm.$plus8ToCurrentZone(_vm.folder.update_at).format("YYYY-MM-DD")))])])]), _c("div", {
    staticClass: "bottom-part"
  }, [_c("hot-data", {
    attrs: {
      info: _vm.folder,
      "no-play": ""
    }
  }), _c("div", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("conv-flow-dropdown", {
    on: {
      command: function ($event) {
        return _vm.$emit("command", $event);
      }
    }
  })], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;