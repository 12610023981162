"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("conversation-filter-item", {
    attrs: {
      label: "最长无人发言时间",
      "is-show-clear": !(_vm.filters.max_silence_duration[0] === 0 && _vm.filters.max_silence_duration[1] === "unlimit") || _vm.invalid
    },
    on: {
      handleClear: _vm.clearTimeChoose
    }
  }, [_c("div", {
    staticClass: "tag-group"
  }, _vm._l(_vm.timeOption, function (item) {
    return _c("span", {
      key: item.label,
      staticClass: "tag-group-item",
      class: {
        "active-item-tag": _vm.handleActiveTime(item.value)
      },
      on: {
        click: function ($event) {
          return _vm.selectTime(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _c("time-range", {
    ref: "timeRange",
    attrs: {
      "time-range": _vm.filters.max_silence_duration,
      "only-second": "",
      "max-value": "unlimit"
    },
    on: {
      timeChange: _vm.selectTime,
      verifyError: function ($event) {
        _vm.invalid = true;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;