"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _client = require("@/api/client");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'InformationCard',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    accountId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      localFormData: {},
      formDesc: []
    };
  },
  created() {
    this.getCustomerDetail();
    this.getClientDynamicForm();
  },
  methods: {
    getCustomerDetail() {
      (0, _client.getClientInfo)(this.accountId).then(res => {
        if (res.code === 200) {
          this.localFormData = res.results.detail_account.field;
        }
      });
    },
    async getClientDynamicForm() {
      const clientDynamicForm = await (0, _client.getClientDynamicForm)();
      if (clientDynamicForm.code === 200) {
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(clientDynamicForm.results)));
      }
    },
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    }
  }
};
exports.default = _default;