"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.type === "coachRecord" ? _vm._l(_vm.coachRecordList, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "row"
    }, [_c("dialog-tag", {
      attrs: {
        text: "1",
        type: item.type
      }
    }), _c("span", [_vm._v(_vm._s(item.text))])], 1);
  }) : _vm._l(_vm.todoCoachList, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "row"
    }, [_c("div", {
      class: ["dot", item.type]
    }, [_vm._v("1")]), _c("span", [_vm._v(_vm._s(item.text))])]);
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;