"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TextSearch',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logicBtnList: [{
        type: 'and',
        label: '且'
      }, {
        type: 'or',
        label: '或'
      }]
    };
  },
  watch: {
    disabled: {
      handler: function (val) {
        if (val) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters.filter_relation = 'or';
          this.$emit('change', this.filters);
        }
      },
      immediate: true
    }
  },
  methods: {
    isOpen(item) {
      const {
        role,
        inverse
      } = item;
      return role !== 'default' || inverse;
    },
    // 清空会话文本
    clearText() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.textFilterList = [{
        pattern: '',
        role: 'default',
        inverse: false
      }];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.filter_relation = 'and';
    },
    addSearchText() {
      if (this.filters.textFilterList.length < 20) {
        const textItem = {
          pattern: '',
          role: 'default',
          inverse: false
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.filters.textFilterList.push(textItem);
      } else {
        this.$message({
          message: '超出上限',
          type: 'warning'
        });
      }
    },
    deleteCurrentSearchText(index) {
      const {
        pattern
      } = this.filters.textFilterList[index];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.textFilterList.splice(index, 1);
      if (pattern.length) this.$emit('change', this.filters);
    },
    handleActiveLogicBtn(val) {
      return this.filters.filter_relation === val;
    },
    selectLogicBtn(val) {
      if (this.disabled && val === 'and') return;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.filter_relation = val;
      this.$emit('change', this.filters);
    }
  }
};
exports.default = _default;