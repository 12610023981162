"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "number-desensitization"
  }, [_c("header", {
    staticClass: "number-desensitization-title"
  }, [_vm._v("手机号隐私保护")]), _c("section", {
    staticClass: "number-desebsitization-wrapper"
  }, [_c("open-number-desensibilisation", {
    attrs: {
      "is-desensitization-open": _vm.isDesensitizationOpen
    }
  }), _c("choose-role-desemsibilisation", {
    attrs: {
      "has-desensitization-role-list": _vm.hasDesensitizationRoleList
    },
    on: {
      fetchRoleDesensitizationSuccess: _vm.fetchRoleDesensitizationSuccess
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;