"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "plan-average-score"
  }, [_c("div", {
    staticClass: "label-row"
  }, [_c("span", [_vm._v(_vm._s(_setup.t("coaching.averageStudentScore")))]), _c("el-tooltip", {
    attrs: {
      content: _setup.t("coaching.averageStudentScoreTip")
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "score-row"
  }, [_c("span", {
    staticClass: "score"
  }, [_vm._v(_vm._s(_vm.score))]), _c("span", {
    staticClass: "score-label"
  }, [_vm._v(_vm._s(_setup.t("coaching.points")))])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;