"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getContactConversation = getContactConversation;
exports.getContactList = getContactList;
exports.getContactTableHead = getContactTableHead;
exports.saveContactTableHead = saveContactTableHead;
exports.searchContact = searchContact;
exports.updateContact = updateContact;
var _request = require("@/utils/request");
function getContactList(params) {
  return (0, _request.request)({
    url: '/contact/contact/list',
    method: 'get',
    params
  });
}
function getContactConversation(contact_id, page, size) {
  return (0, _request.request)({
    url: '/contact/contact/contact_conversation',
    method: 'get',
    params: {
      contact_id,
      page,
      size
    }
  });
}
function getContactTableHead() {
  return (0, _request.request)({
    url: '/contact/contact/head',
    method: 'get'
  });
}
function saveContactTableHead(data) {
  return (0, _request.request)({
    url: '/contact/contact/head/on',
    method: 'post',
    dataType: 'json',
    data
  });
}
function updateContact(data) {
  return (0, _request.request)({
    url: '/contact/contact/create_contact',
    method: 'post',
    dataType: 'json',
    data
  });
}
function searchContact(params) {
  return (0, _request.request)({
    url: '/contact/contact/search',
    method: 'get',
    params
  });
}