"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'VueHorizontalCalendar',
  props: {
    // 传入的业务数据
    // icon区域
    bothSidesConverList: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    // 进度条
    progessInfo: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    // 当前的状态
    stage: {
      type: [String, Number],
      required: false,
      default: ''
    },
    // 默认选中的日期, 今天； 可接收格式如 ‘2019/12/01’ 或 ‘2019-12-01’ 标准UTC格式时间
    choosedDate: {
      type: [String, Date],
      required: false,
      default: () => {
        return new Date();
      }
    },
    // 日期刻度的宽度
    scaleWidth: {
      type: [String, Number],
      required: false,
      default: 40
    },
    // 点击左右箭头，切换的日期间隔天数
    swipeSpace: {
      type: [String, Number],
      required: false,
      default: 7
    },
    // 当前默认选中的日期所处的位置，'left，center'，'right'，默认左
    choosedDatePos: {
      type: String,
      required: false,
      default: 'center'
    },
    // 最小日期，可接收格式如 ‘2019/12/01’ 或 ‘2019-12-01’ 或 标准UTC格式时间
    minDate: {
      type: [String, Date],
      required: false,
      default: '2020/12/01'
    },
    // 最大日期，可接收格式如 ‘2019/12/01’ 或 ‘2019-12-01’ 或 标准UTC格式时间
    maxDate: {
      type: [String, Date],
      required: false,
      default: '2030/01/05'
    },
    // 选中的日期背景色
    choosedItemColor: {
      type: String,
      required: false,
      default: 'transparent'
    },
    // ‘今天’未选中时的背景色
    todayItemBgColor: {
      type: String,
      required: false,
      default: '#F5F7FA'
    },
    // ‘今天’未选中时的字体色
    todayItemTextColor: {
      type: String,
      required: false,
      default: '#F43F7C'
    },
    // 星期天的中文字，默认‘日’，可自定义，如‘天’
    sundayText: {
      type: String,
      required: false,
      default: '日'
    },
    // 是否显示日历组件的顶部边框
    showBorderTop: {
      type: Boolean,
      required: false,
      default: true
    },
    // 屏幕尺寸改变时，是否重绘日历组件
    resizeable: {
      type: Boolean,
      required: false,
      default: true
    },
    // 语言；可选值：zh(中文)，en(英文)
    lang: {
      type: String,
      required: false,
      default: 'zh'
    }
  },
  data() {
    return {
      // 每一个日期对象的数据结构如下
      // {
      //   dateFormat: "2019/07/20",
      //   year: "2019",
      //   month: "07",
      //   date: "20",
      //   timestamp: 1564046915797,
      //   day: "六"
      // }

      firstDay: {},
      // 当前显示的第一个日期
      visibleDay: '',
      // 计算屏幕可放置的日期方块的数量
      changeCount: Number(this.swipeSpace),
      // 点击左右箭头，增量的日期数量，默认为7天
      dateList: [],
      // 当前的日期列表
      today: {},
      // 今天

      // 动画数据
      translateX: -this.changeCount * this.scaleWidth,
      transitionDuration: '300ms',
      domWidth: 0,
      // 日历组件宽度

      swipeLeftMore: true,
      // 是否还能左滑
      swipeRightMore: true // 是否还能右滑
    };
  },

  computed: {
    // 最小日期的0点时间戳
    minDateTimestamp() {
      if (this.minDate) {
        const day = this.formatOneDay(this.minDate);
        return day.timestamp;
      }
      return null;
    },
    // 最大日期的0点时间戳
    maxDateTimestamp() {
      if (this.maxDate) {
        const day = this.formatOneDay(this.maxDate);
        return day.timestamp;
      }
      return null;
    },
    // "当前选中的日期"
    choosedDay: {
      get() {
        return this.formatOneDay(this.choosedDate);
      },
      set() {}
    }
  },
  watch: {
    bothSidesConverList: {
      handler: function (newVal) {
        this.init();
      },
      deep: true
    },
    lang(n) {
      this.lang = n;
      this.creatList();
    }
  },
  mounted() {
    this.init();
    // 触发change事件
    this.$emit('change', this.choosedDay);

    /**
     * @@author jacques
     * @@date 2019.12.06
     * @@desc 监听屏幕宽度变化事件，动态更新日期条
     * 因为onresize事件在一次拖拽行为中，会调用n多次；为了不必要的数据更替和界面渲染，此处定义a,b两个变量，通过延时判断，来消除这种无谓的消耗
     * */
    if (this.resizeable) {
      let a = 1;
      window.addEventListener('resize', () => {
        a++;
        const b = a;
        setTimeout(() => {
          if (b == a) {
            this.init();
          }
        }, 200);
      });
    }
  },
  methods: {
    init() {
      // 根据屏幕宽度，判断可显示的日期方块的数量
      this.domWidth = this.$el.scrollWidth;
      let n = (this.domWidth - 60) / this.scaleWidth;
      // 可显示日期数量 = 可完全显示的数量+1 （除非div的宽度刚刚好被this.scaleWidth整除）
      if (n % 1 != 0) {
        n = parseInt(n) + 2;
      }
      this.visibleDay = n;

      // 如果单次滑动的天数，超过可显示的天数，则限制最大值为可显示天数；
      if (this.changeCount > this.visibleDay) {
        this.changeCount = this.visibleDay;
      }

      // "今天"
      this.today = this.formatOneDay(this.$moment().format('YYYY-MM-DD'));
      // // "当前选中的日期"
      // this.choosedDay = this.formatOneDay(this.choosedDate);

      // 第一天
      const firstDay = this.formatOneDay(this.choosedDate);
      if (this.choosedDatePos === 'center') {
        const ts1 = firstDay.timestamp - parseInt(this.visibleDay / 2) * 1000 * 60 * 60 * 24;
        this.firstDay = this.formatOneDay(ts1);
      } else if (this.choosedDatePos === 'right') {
        const ts2 = firstDay.timestamp - parseInt(this.visibleDay - 1) * 1000 * 60 * 60 * 24;
        this.firstDay = this.formatOneDay(ts2);
      } else {
        this.firstDay = firstDay;
      }
      this.creatList();

      // 事件回调: 当前显示的第一天的数据
      this.$emit('firstDayChange', this.firstDay);
    },
    // 初始化，生成一列日期
    creatList() {
      const list = [];
      const firstDayTime = this.firstDay.timestamp;
      for (let i = 0; i < this.visibleDay; i++) {
        const timestamp = firstDayTime + 1000 * 60 * 60 * 24 * i; // 用第一天的时间戳+24小时*i天
        const info = this.formatOneDay(new Date(timestamp));
        list.push(info);
      }
      this.dateList = list;
      this.translateX = 0;

      // 重置左右滑动限制
      this.swipeLeftMore = true;
      this.swipeRightMore = true;
    },
    // 日期点击事件
    changeChoosedDay(day) {
      // this.choosedDay = day;
      this.$emit('changeDate', day.dateFormat);
    },
    // 左右滑动翻页 1：往后加载7天，-1：往前加载7天
    dateFlip(type) {
      // 数据变更前的部分数据
      const beforeChangeX = this.translateX;
      const beforeChangeLen = this.dateList.length;

      // 左边按钮
      if (type === -1) {
        // 1，如果此时translateX< 单次滚动的日期长度，说明左侧有可滚动的日期，不需要生成更多日期
        if (this.translateX <= -this.scaleWidth * this.changeCount) {
          this.translateX = this.translateX + this.scaleWidth * this.changeCount;

          // 2，如果由于最小日期限制，加载已经到头，则不再加载新的日期,直接滚动到最左边
        } else if (!this.swipeLeftMore) {
          this.translateX = 0;
          this.$emit('swipeToEnd', 'left');
        } else {
          //, 3，以上条件都不满足，则说明左侧已经没有可展示的日期了，要新增数据
          const fdt = this.dateList[0].timestamp;
          const list = [];
          let i;
          for (i = 0; i < this.changeCount; i++) {
            // 用数组第一项的时间戳 - 24小时*(i+1)天
            const timestamp = fdt - 1000 * 60 * 60 * 24 * (i + 1);
            const info = this.formatOneDay(new Date(timestamp));
            // 如果存在最小日期限制,且当前这个日期小于最小限制，则停止循环
            if (this.minDate && info.timestamp < this.minDateTimestamp) {
              this.swipeLeftMore = false;
              break;
            } else {
              list.unshift(info);
            }
          }
          // 消除过渡效果，插入新日期，变更x轴位置，以抵消插入数据带来的位置变化
          this.transitionDuration = '0ms';
          this.dateList.unshift(...list);
          // i 表示新增的日期数量； 因为循环可能会被最大最小值中断，所以 i 相对于 changeCount 更准确
          this.translateX = this.translateX - this.scaleWidth * i;

          // 异步重置过渡效果，位移div
          setTimeout(() => {
            this.transitionDuration = '300ms';
            this.translateX = this.translateX + this.scaleWidth * i;
          }, 1);
        }
        // 右边按钮
      } else if (type === 1) {
        // 判断右侧是否有可滚动的日期，有的话则直接滚动
        const hasSpace = this.dateList.length * this.scaleWidth - this.domWidth + this.translateX;

        // 1，有完整可滚动的日期，则直接滚动
        if (hasSpace > this.scaleWidth * this.changeCount) {
          this.translateX = this.translateX - this.scaleWidth * this.changeCount;
        } else {
          // 2，如果由于最大日期限制，加载已经到头，则不再加载新的日期; 直接滚动到末端；
          if (!this.swipeRightMore) {
            this.translateX = (this.dateList.length - this.visibleDay) * -this.scaleWidth;
            this.$emit('swipeToEnd', 'right');
            return;
          }
          // 3，以上都不满足，则需要生成新日期，然后滚动
          const fdt = this.dateList[this.dateList.length - 1].timestamp;
          let i;
          for (i = 0; i < this.changeCount; i++) {
            // 用数组第一天的时间戳 + 24小时*(i+1)天
            const timestamp = fdt + 1000 * 60 * 60 * 24 * (i + 1);
            const info = this.formatOneDay(new Date(timestamp));
            // 如果存在最大日期限制,且当前日期大于最大限制，则停止循环
            if (this.maxDate && info.timestamp > this.maxDateTimestamp) {
              this.swipeRightMore = false;
              break;
            } else {
              this.dateList.push(info);
            }
          }
          // 如果i小于固定移动单位，则说明由于最大日期限制，最后位移的单位并不完全充足；此时i的值，受最小日期和屏幕宽度以及移动距离影响，是不定的值，因此只能借助以下计算方式。
          if (i < this.changeCount) {
            this.translateX = (this.dateList.length - this.visibleDay) * -this.scaleWidth;
          } else {
            this.translateX = this.translateX - this.scaleWidth * i;
          }
        }
      }

      /**
       * 计算当前显示的第一天 的变化
       * 因为当前function执行中存在异步过程，且动画执行也需要时间，因此延时执行
       */
      setTimeout(() => {
        // 数据变更后的部分数据
        const afterChangeX = this.translateX;
        const afterChangeLen = this.dateList.length;
        // 如果x前后都相等，则计算当前日期列表的长度变化
        if (beforeChangeX === afterChangeX) {
          const changeDay = afterChangeLen - beforeChangeLen;
          // 如果点击之后日历没变化(当最大或最小值存在的时候，就可能出现此种情况)，则不往下执行
          if (changeDay === 0) return;
          this.firstDay = this.formatOneDay(this.formatDateTime(this.firstDay.timestamp - 3600 * 1000 * 24 * changeDay));
        } else {
          const changeDay = (afterChangeX - beforeChangeX) / this.scaleWidth;
          this.firstDay = this.formatOneDay(this.formatDateTime(this.firstDay.timestamp - 3600 * 1000 * 24 * changeDay));
        }
        // 事件回调: 当前显示的第一天的数据
        this.$emit('firstDayChange', this.firstDay);
      }, 300);

      // 事件回调，返回滑动事件类型
      this.$emit('swipeClick', type === 1 ? 'right' : 'left');
    },
    // 格式化单个日期的数据
    formatOneDay(day) {
      const timestamp = new Date(day).getTime();
      const date = this.formatDateTime(timestamp); // 2019/06/01
      const dateArray = date.split('/'); // [2019,06,01]
      // 去掉补位的0
      for (const key in dateArray) {
        if (dateArray[key].indexOf('0') == 0) {
          dateArray[key] = dateArray[key].substr(1, 1);
        }
      }
      const week = new Date(timestamp).getDay();
      return {
        dateFormat: date,
        year: dateArray[0],
        month: dateArray[1],
        date: dateArray[2],
        timestamp: new Date(date).getTime(),
        day: this.getWeekName(week),
        isWeekend: week == 0 || week == 6
      };
    },
    // 返回“星期”的文字
    getWeekName(day) {
      const dirt = {
        0: this.sundayText,
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六'
      };
      const dirt_en = {
        0: 'Su',
        1: 'Mo',
        2: 'Tu',
        3: 'We',
        4: 'Th',
        5: 'Fr',
        6: 'Sa'
      };
      const dirt_es = {
        0: 'Do',
        1: 'Lu',
        2: 'Ma',
        3: 'Mi',
        4: 'Ju',
        5: 'Vi',
        6: 'Sa'
      };
      // 如果是英文显示
      switch (this.lang) {
        case 'en':
          return dirt_en[day];
        case 'es':
          return dirt_es[day];
        default:
          return dirt[day];
      }
    },
    // 输入时间戳，返回 YY/MM/DD 日期格式
    formatDateTime(timestamp) {
      if (!timestamp) return '';
      // timestamp = parseInt(timestamp); // 防止传入字符串类型
      const fdt = this.$currentZoneToPlus8(timestamp).format('YYYY/MM/DD');
      return fdt;
    }
  }
};
exports.default = _default;