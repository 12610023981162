"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _DataScopeTags = _interopRequireDefault(require("./DataScopeTags.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: 'top'
    },
    showPopover: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const props = __props;
    const MAP = {
      audio: '音频',
      video: '视频',
      doc: '文字'
    };
    const scopes = (0, _vue.computed)(() => {
      const _scopes = [];
      const {
        conversation_types,
        date_new,
        organization_tree_ids,
        organization_tree_infos
      } = props.scope;
      if (organization_tree_ids !== null && organization_tree_ids !== void 0 && organization_tree_ids.length) {
        _scopes.push({
          label: '部门',
          value: organization_tree_ids.map(item => {
            var _organization_tree_in;
            return (_organization_tree_in = organization_tree_infos.find(info => info.id === item)) === null || _organization_tree_in === void 0 ? void 0 : _organization_tree_in.name;
          }).join(', ')
        });
      }
      if (conversation_types !== null && conversation_types !== void 0 && conversation_types.length) {
        _scopes.push({
          label: '会话类型',
          value: conversation_types.map(item => MAP[item]).join(',')
        });
      }
      if ((date_new === null || date_new === void 0 ? void 0 : date_new.type) !== 'unlimit') {
        let val;
        if ((date_new === null || date_new === void 0 ? void 0 : date_new.type) === 'before') {
          val = `早于 ${date_new === null || date_new === void 0 ? void 0 : date_new.range[0]}`;
        } else if ((date_new === null || date_new === void 0 ? void 0 : date_new.type) === 'after') {
          val = `晚于 ${date_new === null || date_new === void 0 ? void 0 : date_new.range[0]}`;
        } else {
          val = `${date_new === null || date_new === void 0 ? void 0 : date_new.range[0]} - ${date_new === null || date_new === void 0 ? void 0 : date_new.range[1]}`;
        }
        _scopes.push({
          label: '会话时间',
          value: val
        });
      }
      return _scopes;
    });
    return {
      __sfc: true,
      props,
      MAP,
      scopes,
      DataScopeTags: _DataScopeTags.default
    };
  }
};
exports.default = _default;