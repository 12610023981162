"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
var _clues = require("@/api/clues");
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "ClueTranslateAccountDialog",
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    clueId: {
      type: Number | String
    }
  },
  data() {
    return {
      getAccountFormloading: false,
      // 客户
      accountFormDesc: [],
      createAccountFormData: {},
      // 联系人
      createContactFormData: {},
      contactFormDesc: [],
      // 商机
      createDealFormData: {},
      // 商机阶段
      dealFormDesc: [],
      accountVal: null,
      isCreateContact: true,
      isCreateDeal: true,
      saveLoading: false
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.accountFormDesc = [];
        this.dealFormDesc = [];
        this.contactFormDesc = [];
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(["businessStatusOptions"])
  },
  created() {
    this.getClientDynamicForm();
    this.getBusinessStatusOptions();
    this.getContactDynamicForm();
    this.getClueMapping();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    // 获取对应的值
    getMappingValue(val, type) {
      let mapValue = {};
      let obj = Object.keys(val);
      if (obj.length) {
        switch (type) {
          case "account":
            for (let key in val) {
              mapValue[val[key].account_field_id] = val[key].value;
            }
            break;
          case "contact":
            for (let key in val) {
              mapValue[val[key].contact_field_id] = val[key].value;
            }
            break;
        }
        return mapValue;
      }
    },
    // 获取转客户对应的映射值
    async getClueMapping() {
      const res = await (0, _clues.getClueMapping)({
        lead_id: this.clueId
      });
      this.createAccountFormData = this.getMappingValue(res.results.account, "account");
      this.createContactFormData = this.getMappingValue(res.results.contact, "contact");
    },
    // 获取商机阶段
    async getBusinessStatusOptions() {
      await this.$store.dispatch("business/get_business_status_options");
      this.getAccountFormloading = true;
      const dynamicForm = await (0, _client.getDynamicForm)({
        in_account: 1
      });
      if (dynamicForm.code === 200) {
        let dealFormDescList = dynamicForm.results.filter(item => item.id !== "customer_name");
        this.dealFormDesc = this.formatAccountFormDesc(dealFormDescList);
      }
      this.getAccountFormloading = false;
    },
    // 获取客户自定义表单
    async getClientDynamicForm() {
      this.getAccountFormloading = true;
      const res = await (0, _client.getClientDynamicForm)();
      if (res.code === 200) {
        this.accountFormDesc = this.formatAccountFormDesc(res.results);
      }
      this.getAccountFormloading = false;
    },
    // 获取联系人自定义表单
    async getContactDynamicForm() {
      this.getAccountFormloading = false;
      const res = await (0, _client.getContactDynamicForm)({
        type: "contact",
        has_account: 1
      });
      if (res.code === 200) {
        let contactFormDescList = res.results.filter(item => item.id !== "customer_name");
        this.contactFormDesc = this.formatAccountFormDesc(contactFormDescList);
      }
    },
    submit() {
      if (!this.$refs["accountDynamicForm"].validate()) {
        return;
      }
      if (this.isCreateContact && !this.$refs["contactDynamicForm"].validate()) {
        return;
      }
      if (this.isCreateDeal && !this.$refs["dealDynamicForm"].validate()) {
        return;
      }
      const fetchData = {
        lead_id: this.clueId,
        account: this.createAccountFormData,
        contact: this.isCreateContact ? this.createContactFormData : "",
        deal: this.isCreateDeal ? this.createDealFormData : ""
      };
      this.clueTranslateAccount(fetchData);
    },
    async clueTranslateAccount(data) {
      this.saveLoading = true;
      const res = await (0, _clues.clueTranslateAccount)(data);
      if (res.code === 20000) {
        this.$message.success("线索转客户成功");
        setTimeout(() => {
          this.$emit("clueTranslateAccountSuccess");
          this.closeDialog();
        }, 500);
      }
      this.saveLoading = false;
    },
    // 格式化客户的自定义表单
    formatAccountFormDesc(results) {
      const desc = [];
      for (const item of results) {
        let obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: item.is_require ? true : false,
          unique: item.is_unique,
          action: "",
          options: item.options,
          disabled: item.is_edit === 0 ? true : false,
          halfRow: true,
          tips: item.tips
        };
        if (item.from_type === "select" && item.name === "商机阶段") {
          obj.options = this.businessStatusOptions;
        }
        desc.push(obj);
      }
      return desc;
    }
  }
};
exports.default = _default;