"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ExperimentTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MgOperateColumn: _MgOperateColumn.default
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headerList: [{
        prop: 'name',
        field_name: '监测名称',
        fixed: 'left',
        minWidth: 200
      }, {
        prop: 'creator_info',
        field_name: '创建人',
        minWidth: 100
      }, {
        prop: 'source',
        field_name: '监测来源',
        minWidth: 100
      }, {
        prop: 'status',
        field_name: '当前状态',
        minWidth: 100
      }, {
        prop: 'start_at',
        field_name: '开始时间',
        minWidth: 200
      }, {
        prop: 'end_at',
        field_name: '结束时间',
        minWidth: 200
      }, {
        prop: 'operate',
        field_name: '操作',
        fixed: 'right',
        minWidth: 200
      }],
      testStateOption: Object.freeze([{
        value: 1,
        label: '未开始'
      }, {
        value: 2,
        label: '进行中'
      }, {
        value: 3,
        label: '已完成'
      }])
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    judgeTestState() {
      return function (val) {
        return this.testStateOption.filter(_ref => {
          let {
            value
          } = _ref;
          return value === val;
        })[0].label;
      };
    }
  },
  methods: {
    getOperateList(data) {
      return [{
        label: '编辑',
        command: 'editExperiment',
        isShow: data.status !== 3 && !data.is_share_display
      }, {
        label: '详情',
        command: 'goExperimentDetail',
        isShow: data.status !== 1
      }, {
        label: '分享',
        command: 'shareExperiment',
        isShow: !data.is_share_display,
        icon: 'icon-share-line'
      }, {
        label: '复制',
        command: 'copyExperiment',
        isShow: true,
        icon: 'icon-copy-document'
      }, {
        label: '删除',
        command: 'delete',
        isShow: !data.is_share_display,
        icon: 'icon-delete',
        class: 'delete'
      }];
    },
    handleCommand(command, data) {
      if (command === 'delete') {
        this.deleteExperiment(data.id);
      } else {
        this.$emit(command, data);
      }
    },
    plus8ToCurrentZone(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    async deleteExperiment(id) {
      this.$confirm('删除该测试将会删除该测试下的所有配置和分析报告，是否删除?', '删除A/B测试', {
        confirmButtonText: '删除',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async (action, ctx, close) => {
          if (action !== 'confirm') {
            close();
            return;
          }
          ctx.confirmButtonLoading = true;
          const res = await (0, _businessIntelligence.deleteExperiment)({
            id,
            workspace_id: this.workspaceInfo.id
          }).finally(() => {
            ctx.confirmButtonLoading = false;
          });
          if (res.code === 20000) {
            this.$message({
              message: '删除成功',
              center: true,
              type: 'success'
            });
            close();
            this.$emit('refresh');
          }
        }
      });
    }
  }
};
exports.default = _default;