"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conver-echarts"
  }, [_vm.chartData && _vm.chartData.numberArray.length === 0 ? _c("div", {
    staticClass: "no-data"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e(), _c("div", {
    staticClass: "conver-echarts-bar",
    class: {
      "hidden-echarts": _vm.chartData && _vm.chartData.numberArray.length === 0
    },
    style: {
      height: _vm.height,
      width: _vm.width
    },
    attrs: {
      id: "conversation-echarts-bar"
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;