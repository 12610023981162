"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _applicationIntegration = require("@/api/applicationIntegration");
var _ImageTextTips = _interopRequireDefault(require("@/components/ImageTextTips.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KeySettings',
  components: {
    ImageTextTips: _ImageTextTips.default
  },
  props: {
    privateKeyDesc: {
      type: Object,
      default: () => ({})
    },
    privateKeyValue: {
      type: Boolean,
      default: false
    },
    isShowImageTips: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasPrivateKey: false,
      privateKey: '',
      publicKey: '',
      btnLoading: false
    };
  },
  watch: {
    privateKeyValue: {
      handler(val) {
        this.hasPrivateKey = val;
      },
      immediate: true
    },
    hasPrivateKey(val) {
      this.$emit('hasPrivateKeyChange', val);
    },
    privateKey(val) {
      this.$emit('privateKeyChange', val);
    }
  },
  methods: {
    async createPublicKey() {
      this.btnLoading = true;
      const res = await (0, _applicationIntegration.getPublicKey)();
      if (res.code === 200) this.publicKey = res.results;
      this.btnLoading = false;
    },
    onCopy() {
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    resetValue() {
      this.privateKey = '';
      this.publicKey = '';
      this.hasPrivateKey = this.privateKeyValue;
    }
  }
};
exports.default = _default;