"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _coachingCenter = require("@/api/coachingCenter");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SettingDrawer',
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    practiceRule: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      languageList: [{
        value: 'zh',
        label: 'coaching.simplifiedChinese'
      }, {
        value: 'en',
        label: 'coaching.englishLanguage'
      }, {
        value: 'fr',
        label: 'coaching.frenchLanguage'
      }, {
        value: 'de',
        label: 'coaching.germanLanguage'
      }, {
        value: 'es',
        label: 'coaching.spanishLanguage'
      }, {
        value: 'pt',
        label: 'coaching.portugueseLanguage'
      }, {
        value: 'it',
        label: 'coaching.italianLanguage'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    variables() {
      return _variablesModule.default;
    }
  },
  methods: {
    updateSmartTrainingSet: (0, _commonFunc.debounce)(async function (message) {
      const course_id = Number(this.$route.query.id);
      if (course_id) {
        const res = await (0, _coachingCenter.updateSmartTrainingSet)({
          course_id,
          practice_rule: this.practiceRule
        });
        if (res.code === 20000 && message) {
          this.$message.success(message);
        }
      }
    }),
    closeDrawer() {
      this.$emit('update:drawerVisible', false);
    }
  }
};
exports.default = _default;