"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    ref: "departmentMultipleSelect",
    staticClass: "data-department-multiple-select",
    class: {
      "not-editable": !_vm.isEdit
    },
    on: {
      click: function ($event) {
        return _vm.openEditScopeDialog(_vm.selectedDepartmentInfo);
      }
    }
  }, [_vm.selectedDepartmentInfo.length ? _c("div", {
    staticClass: "department-card"
  }, _vm._l(_vm.selectedDepartmentInfo, function (scope) {
    return _c("span", {
      key: scope.id,
      staticClass: "manage-scope-item"
    }, [_vm._v(_vm._s(scope.name))]);
  }), 0) : _c("div", {
    staticClass: "no-department"
  }, [_vm._v("请选择部门")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEdit,
      expression: "isEdit"
    }],
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: function ($event) {
        return _vm.openEditScopeDialog();
      }
    }
  })], 1), _c("transer-department-finder-dialog", {
    attrs: {
      visible: _vm.departmentsFinderDialogVisible,
      "default-checked": _vm.selectedDepartmentInfo,
      "remote-method": _vm.getDepartmentsWithOutAuth,
      "no-permission": "",
      title: "数据范围"
    },
    on: {
      "update:visible": function ($event) {
        _vm.departmentsFinderDialogVisible = $event;
      },
      submit: _vm.submitManageScope
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;