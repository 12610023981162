"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HotData = _interopRequireDefault(require("./HotData"));
var _FolderDropdown = _interopRequireDefault(require("@/components/FolderTree/FolderDropdown"));
var _AddFolderDropdown = _interopRequireDefault(require("./AddFolderDropdown.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FolderCard',
  components: {
    HotData: _HotData.default,
    FolderDropdown: _FolderDropdown.default,
    AddFolderDropdown: _AddFolderDropdown.default
  },
  inject: ['getContentType'],
  props: {
    folder: {
      type: Object,
      default: () => ({})
    }
  }
};
exports.default = _default;