// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/AIHelper/chat_intro_bg.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-37fa2364]:export {\n  menuText: #6c7e93;\n  menuActiveText: #4461ec;\n  subMenuActiveText: #666f8d;\n  menuBg: #e8eaee;\n  menuHover: rgba(68, 97, 236, 0.1);\n  subMenuBg: #fff;\n  subMenuHover: #eee;\n  sideBarWidth: 60px;\n  headerHeight: 40px;\n  titleHeight: 67px;\n  pageMainText: #37446f;\n  pageCommonText: #666f8d;\n  pageActiveText: #4461ec;\n  title: 18px;\n  subtitle: 16px;\n  body: 14px;\n  describe: 12px;\n  active: #4461ec;\n  inactive: #e2e5e9;\n  graphBg: rgba(68, 97, 236, 0.1019607843);\n  graphEdge: #c4cbd4;\n}\n.chat-intro[data-v-37fa2364] {\n  display: flex;\n  justify-content: center;\n  overflow: auto;\n  padding: 20px;\n  height: calc(100% - 48px);\n  flex-direction: column;\n  animation: fade-in-37fa2364 0.2s ease-in;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: cover;\n}\n.chat-intro img[data-v-37fa2364] {\n  display: block;\n  margin: 0 auto 10px;\n  width: 60px;\n  height: 60px;\n}\n.chat-intro .hello-container[data-v-37fa2364] {\n  display: flex;\n  justify-content: center;\n}\n.chat-intro .hello[data-v-37fa2364] {\n  display: inline-block;\n  margin-bottom: 24px;\n  font-weight: 600;\n  line-height: 20px;\n  color: #2b323b;\n}\n.chat-intro .chat-input[data-v-37fa2364] {\n  margin-bottom: 20px;\n}\n.chat-intro .guide-msg[data-v-37fa2364] {\n  padding: 10px 12px;\n  width: -moz-fit-content;\n  width: fit-content;\n  height: 36px;\n  border-radius: 7px;\n  color: #7852e0;\n  background: rgba(108, 126, 147, 0.0392156863);\n  line-height: 14px;\n  cursor: pointer;\n}\n.chat-intro .guide-msg[data-v-37fa2364]:not(:last-child) {\n  margin-bottom: 12px;\n}\n.chat-intro .guide-msg[data-v-37fa2364]:hover {\n  background: rgba(86, 38, 217, 0.1019607843);\n}\n@keyframes fade-in-37fa2364 {\n0% {\n    opacity: 0;\n}\n100% {\n    opacity: 1;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
