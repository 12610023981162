"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnnotateHeader = _interopRequireDefault(require("./components/AnnotateHeader.vue"));
var _AnnotateAllSample = _interopRequireDefault(require("./components/AnnotateAllSample.vue"));
var _AnnotateSearch = _interopRequireDefault(require("./components/AnnotateSearch.vue"));
var _AnnotateText = _interopRequireDefault(require("./components/AnnotateText.vue"));
var _ConvControlContent = _interopRequireDefault(require("@/components/Video/ConvControlContent.vue"));
var _Video = _interopRequireDefault(require("@/components/Video"));
var _AnnotateLabel = _interopRequireDefault(require("./components/AnnotateLabel.vue"));
var _AnnotateResultsDrawer = _interopRequireDefault(require("./components/AnnotateResultsDrawer.vue"));
var _AnnotateOperate = _interopRequireDefault(require("./components/AnnotateOperate.vue"));
var _useVuex = require("@/hooks/use-vuex");
var _useRouter = require("@/hooks/use-router");
var _vue = require("vue");
var _annotate = require("./hooks/annotate");
var _annotateOperate = require("./hooks/annotateOperate");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const activeDataSetData = (0, _vue.ref)({});

    // 获取dataSetId 获取当前数据集下信息
    const {
      dataSetId,
      dataSetName,
      getDataSetDataListApi,
      getDataSetInfoApi,
      dataSetDataList,
      dataSetLoading
    } = (0, _annotate.useDataSet)();

    // 获取数据集具体样本的信息
    const {
      dataSetDataInfo,
      getDataSetDataInfoApi,
      dataSetLabelList,
      getDataSetLabelsApi
    } = (0, _annotate.useDataSetDataInfo)(store, dataSetId, activeDataSetData);

    // 获取会话信息
    const {
      localSliderTime,
      changeSliderTime
    } = (0, _annotate.useConvInfo)();

    // 标注相关
    const {
      createAnnotateApi,
      deleteAnnotateApi,
      getEventListOfSentenceApi,
      getSentenceOfEventApi,
      initCurrentSampleAnnotateInfo,
      changeActiveLabelArr,
      initEventLabelOfSentenceMap,
      activeLabelIdsArr,
      sentenceOfEventTagMap,
      eventLabelOfSentenceMap,
      annotateTextRef
    } = (0, _annotate.useAnnotate)({
      store,
      dataSetId,
      activeDataSetData,
      dataSetDataInfo,
      dataSetLabelList,
      getDataSetLabelsApi
    });
    const {
      changeDataSetDataOperate,
      changeDataSetData
    } = (0, _annotateOperate.useSwitchAnnotateData)({
      store,
      dataSetId,
      dataSetDataInfo,
      dataSetDataList,
      activeDataSetData,
      getDataSetLabelsApi,
      getEventListOfSentenceApi,
      getDataSetDataInfoApi,
      initCurrentSampleAnnotateInfo
    });

    // 查看标签详情
    const {
      checkAnnotateResultDrawerVisible,
      checkAnnotateResults
    } = (0, _annotate.useCheckAnnotateResults)();
    const renameAnnotateLabel = dataSetLabel => {
      dataSetLabelList.value.find(item => item.id === dataSetLabel.id).isEdit = true;
    };
    const refreshDataSetLabel = () => {
      getDataSetLabelsApi();
      getEventListOfSentenceApi();
    };
    const {
      annotateTourRef,
      annotateGuideSteps
    } = (0, _annotate.useAnnotateTour)();
    const route = (0, _useRouter.useRoute)();
    (0, _vue.onMounted)(async () => {
      var _route$query;
      getDataSetInfoApi();
      await getDataSetDataListApi();
      const datasetDataId = (0, _vue.ref)(Number(route === null || route === void 0 ? void 0 : (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.datasetDataId));
      if (datasetDataId.value) {
        var _dataSetDataList$valu;
        activeDataSetData.value = (dataSetDataList === null || dataSetDataList === void 0 ? void 0 : (_dataSetDataList$valu = dataSetDataList.value) === null || _dataSetDataList$valu === void 0 ? void 0 : _dataSetDataList$valu.find(item => item.id === datasetDataId.value)) || (dataSetDataList === null || dataSetDataList === void 0 ? void 0 : dataSetDataList.value[0]);
      } else {
        var _dataSetDataList$valu2;
        activeDataSetData.value = (dataSetDataList === null || dataSetDataList === void 0 ? void 0 : (_dataSetDataList$valu2 = dataSetDataList.value) === null || _dataSetDataList$valu2 === void 0 ? void 0 : _dataSetDataList$valu2.find(item => !item.is_annotated)) || (dataSetDataList === null || dataSetDataList === void 0 ? void 0 : dataSetDataList.value[0]);
      }
      await getDataSetLabelsApi();
      await getDataSetDataInfoApi();
      getEventListOfSentenceApi();
      annotateTourRef.value.start();
    });
    return {
      __sfc: true,
      store,
      activeDataSetData,
      dataSetId,
      dataSetName,
      getDataSetDataListApi,
      getDataSetInfoApi,
      dataSetDataList,
      dataSetLoading,
      dataSetDataInfo,
      getDataSetDataInfoApi,
      dataSetLabelList,
      getDataSetLabelsApi,
      localSliderTime,
      changeSliderTime,
      createAnnotateApi,
      deleteAnnotateApi,
      getEventListOfSentenceApi,
      getSentenceOfEventApi,
      initCurrentSampleAnnotateInfo,
      changeActiveLabelArr,
      initEventLabelOfSentenceMap,
      activeLabelIdsArr,
      sentenceOfEventTagMap,
      eventLabelOfSentenceMap,
      annotateTextRef,
      changeDataSetDataOperate,
      changeDataSetData,
      checkAnnotateResultDrawerVisible,
      checkAnnotateResults,
      renameAnnotateLabel,
      refreshDataSetLabel,
      annotateTourRef,
      annotateGuideSteps,
      route,
      AnnotateHeader: _AnnotateHeader.default,
      AnnotateAllSample: _AnnotateAllSample.default,
      AnnotateSearch: _AnnotateSearch.default,
      AnnotateText: _AnnotateText.default,
      ConvControlContent: _ConvControlContent.default,
      VideoPlayer: _Video.default,
      AnnotateLabel: _AnnotateLabel.default,
      AnnotateResultsDrawer: _AnnotateResultsDrawer.default,
      AnnotateOperate: _AnnotateOperate.default
    };
  }
};
exports.default = _default;