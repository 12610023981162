"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _default = {
  name: 'ManageSampleScopeDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sampleScopeList: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      oldName: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    handleRename(row) {
      this.$set(row, 'isEdit', true);
      this.$nextTick(() => {
        this.$refs.renameInput.focus();
        this.oldName = row.name;
      });
    },
    async rename(row) {
      this.$set(row, 'isEdit', false);
      if (this.oldName === row.name) return;
      if (!row.name.trim()) {
        row.name = this.oldName;
        return;
      }
      const data = {
        workspace_id: this.workspaceInfo.id,
        rule_id: row.id,
        name: row.name
      };
      const res = await (0, _compliance.renameSampleRule)(data);
      try {
        if (res.code === 20000) {
          this.$message.success('修改名称成功');
          this.$emit('refresh');
        } else {
          row.name = this.oldName;
        }
      } catch {
        row.name = this.oldName;
      }
    },
    async handleDelete(row) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        rule_id: row.id
      };
      const res = await (0, _compliance.deleteSampleRule)(data);
      if (res.code === 20000) {
        const sampleScopeList = this.sampleScopeList.filter(item => item.id !== row.id);
        this.$message.success('删除成功');
        this.$emit('changeList', sampleScopeList);
        this.$emit('refresh');
      }
    }
  }
};
exports.default = _default;