"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _default = {
  __name: 'AnnotateHeader',
  props: {
    dataSetDataList: {
      type: Array,
      default: () => []
    },
    dataSetName: {
      type: String,
      default: ''
    }
  },
  emits: ['checkAnnotateResults'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const goDataSet = () => {
      router.push({
        path: _routeMap.ROUTES.dataSet
      });
    };
    const annotatedDataCount = (0, _vue.computed)(() => {
      var _props$dataSetDataLis;
      return (_props$dataSetDataLis = props.dataSetDataList.filter(item => item.is_annotated)) === null || _props$dataSetDataLis === void 0 ? void 0 : _props$dataSetDataLis.length;
    });
    const annotatedRadio = (0, _vue.computed)(() => {
      var _props$dataSetDataLis2;
      return Math.round(annotatedDataCount.value / ((_props$dataSetDataLis2 = props.dataSetDataList) === null || _props$dataSetDataLis2 === void 0 ? void 0 : _props$dataSetDataLis2.length) * 10000) / 100;
    });
    const openAnnotateResult = () => {
      emit('checkAnnotateResults');
    };
    return {
      __sfc: true,
      props,
      router,
      goDataSet,
      annotatedDataCount,
      annotatedRadio,
      emit,
      openAnnotateResult
    };
  }
};
exports.default = _default;