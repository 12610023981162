"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.convRuleOptionsLoading && !_vm.convRuleOptions.length ? _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-monitoring-01"
    }
  }, [_c("span", {
    staticClass: "empty-page-tips",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_vm._v("当前空间未设置评分规则或无权限查看"), _c("br"), _vm._v(" 请 申请权限 或 "), _c("a", {
    attrs: {
      href: _vm.getSetRulePageLink()
    }
  }, [_vm._v("设置会话评分规则")])])]) : [_c("ExportTableButton", {
    ref: "export-table-button",
    attrs: {
      "export-method": _vm.exportSopAnalysisConversationTableData
    },
    on: {
      exportTable: _vm.exportTableData
    }
  }), _c("div", {
    ref: "container",
    staticClass: "container"
  }, [_c("div", {
    staticClass: "card-container"
  }, [_c("BarChart", {
    attrs: {
      "chart-loading": _vm.barChartLoading,
      data: _vm.barChartData
    }
  }), _c("LineChart", {
    ref: "convLineChart",
    attrs: {
      "chart-loading": _vm.lineChartLoading,
      data: _vm.lineChartData
    },
    on: {
      dateIntervalChange: _vm.dateIntervalChange
    }
  })], 1), _c("div", {
    staticClass: "sop-analysis-data"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    key: _vm.changKey,
    ref: "sop-analysis-table",
    staticClass: "sop-analysis-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: "",
      "show-overflow-tooltip": true,
      "default-sort": _vm.defaultSort
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_vm.tableDataHead && _vm.tableDataHead.length ? _c("el-table-column", {
    attrs: {
      "min-width": "180",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.dimension == 2,
            expression: "dimension == 2"
          }],
          staticClass: "table-head-dp with-img"
        }, [_c("div", {
          staticClass: "div-with-img"
        }, [_c("mgv-avatar-name", {
          attrs: {
            name: row["tree"]["name"],
            src: row["tree"]["avatar"],
            size: 28
          }
        })], 1), _c("div", {
          staticClass: "div-with-content"
        }, [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            content: row["tree"]["name"]
          }
        }, [_c("span", {
          staticClass: "table-head-dp-text"
        }, [_vm._v(_vm._s(row["tree"]["name"]))])]), _c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            content: row["tree"]["tree_path"]
          }
        }, [_c("span", {
          staticClass: "table-head-dp-content"
        }, [_vm._v(" " + _vm._s(row["tree"]["tree_path"]) + " ")])])], 1)]), _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.dimension == 1,
            expression: "dimension == 1"
          }],
          staticClass: "table-head-dp"
        }, [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            content: row["tree"]["name"]
          }
        }, [_c("span", {
          staticClass: "table-head-dp-text"
        }, [_vm._v(_vm._s(row["tree"]["name"]))])]), _c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            content: row["tree"]["tree_path"]
          }
        }, [_c("span", {
          staticClass: "table-head-dp-content"
        }, [_vm._v(" " + _vm._s(row["tree"]["tree_path"]) + " ")])])], 1)];
      }
    }], null, false, 456910274)
  }, [_c("template", {
    slot: "header"
  }, [_c("div", [_c("el-dropdown", {
    ref: "sop-analysis-dorp-down",
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      trigger: "click",
      placement: "bottom-start"
    },
    on: {
      command: _vm.dropDownChange
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link",
    attrs: {
      "data-v-step": "multiple-1"
    }
  }, [_vm._v(_vm._s(_vm.dimension === 1 ? "按部门" : "按成员")), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: 1
    }
  }, [_vm._v("按部门")]), _c("el-dropdown-item", {
    attrs: {
      command: 2
    }
  }, [_vm._v("按成员")])], 1)], 1), _c("mgv-department-multi-select", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dimension == 1,
      expression: "dimension == 1"
    }],
    attrs: {
      "request-method": _vm.getOrgTree,
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _vm.workspaceInfo.id
      },
      "backfill-list": _vm.treeIds
    },
    on: {
      change: _vm.departmentMultipleChange
    }
  }), _c("mgv-member-multi-select", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dimension == 2,
      expression: "dimension == 2"
    }],
    attrs: {
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _vm.workspaceInfo.id
      },
      "is-prevent-not-selected": false,
      "backfill-list": _vm.fillBackValue
    },
    on: {
      change: _vm.handleMemberChange
    }
  })], 1)])], 2) : _vm._e(), _vm.tableDataHead && _vm.tableDataHead.length ? _c("el-table-column", {
    attrs: {
      "min-width": "180",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            content: row.score_name,
            placement: "top"
          }
        }, [_c("span", [_vm._v(_vm._s(row.score_name))])])];
      }
    }], null, false, 3922742742)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-bottom": "13px"
    },
    attrs: {
      "data-v-step": "multiple-2"
    }
  }, [_vm._v("会话评分规则")]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择规则",
      "popper-class": "sop-rule-select"
    },
    on: {
      change: _vm.handleConvRuleChange
    },
    model: {
      value: _vm.convRuleValue,
      callback: function ($$v) {
        _vm.convRuleValue = $$v;
      },
      expression: "convRuleValue"
    }
  }, _vm._l(_vm.convRuleOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("el-tooltip", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      content: _vm.scoreRuleTip,
      placement: "top"
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scoreRuleTip,
      expression: "scoreRuleTip"
    }],
    staticClass: "iconfont icon-info-1 el-popover__reference"
  })])], 1)])], 2) : _vm._e(), _vm._l(_vm.tableDataHead, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        "min-width": "200",
        prop: item.key,
        sortable: "custom"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("span", [_vm._v(_vm._s(row[item.key].type === "percent" ? row[item.key].percent + "%" : row[item.key].count))]), item.key === "conv_count" ? _c("el-popover", {
            attrs: {
              placement: "top",
              width: "160",
              trigger: "hover",
              "popper-class": "popover-sop"
            }
          }, [_c("p", {
            staticClass: "popover-static"
          }, [_vm._v("会话数")]), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              content: `${row["tree"]["name"]} - ${row["score_name"]}`
            }
          }, [_c("p", {
            staticClass: "popover-static-content"
          }, [_vm._v(" " + _vm._s(row["tree"]["name"]) + " - " + _vm._s(row["score_name"]) + " ")])]), _c("div", {
            staticClass: "popover-text-bg"
          }, [_c("p", {
            staticClass: "popover-text-content"
          }, [_vm._v(" " + _vm._s(row["conv_count"].count) + " ")])]), _c("div", {
            staticClass: "popover-reference",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })], 1) : _vm._e(), item.key === "qualified_percent" ? _c("el-popover", {
            attrs: {
              placement: "top",
              width: "160",
              trigger: "hover",
              "popper-class": "popover-sop-qualified_percent"
            }
          }, [_c("p", {
            staticClass: "popover-static"
          }, [_vm._v("合格率")]), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              content: `${row["tree"]["name"]} - ${row["score_name"]} - ${row["conv_count"].count}`
            }
          }, [_c("p", {
            staticClass: "popover-static-content"
          }, [_vm._v(" " + _vm._s(row["tree"]["name"]) + " - " + _vm._s(row["score_name"]) + " - " + _vm._s(row["conv_count"].count) + " ")])]), _c("div", {
            staticClass: "qualified-percent-bar"
          }, [_c("div", {
            staticClass: "percent-bar-count",
            style: {
              width: row["qualified_percent"].percent + "%"
            }
          })]), _c("div", {
            staticClass: "popover-text-bg"
          }, [_c("div", {
            staticClass: "left-part"
          }, [_c("div", {
            staticClass: "left-part-red"
          }), _c("span", {
            staticClass: "part-content"
          }, [_vm._v("不合格")])]), _c("div", {
            staticClass: "right-part"
          }, [_c("p", {
            staticClass: "popover-text-content"
          }, [_vm._v(" " + _vm._s(row["qualified_percent"].total_count - row["qualified_percent"].count) + " ")])])]), _c("div", {
            staticClass: "popover-text-bg"
          }, [_c("div", {
            staticClass: "left-part"
          }, [_c("div", {
            staticClass: "left-part-green"
          }), _c("span", {
            staticClass: "part-content"
          }, [_vm._v("合格")])]), _c("div", {
            staticClass: "right-part"
          }, [_c("p", {
            staticClass: "popover-text-content"
          }, [_vm._v(" " + _vm._s(row["qualified_percent"].count) + " ")])])]), _c("div", {
            staticClass: "popover-reference",
            attrs: {
              slot: "reference"
            },
            slot: "reference"
          })], 1) : _vm._e()];
        }
      }], null, true)
    }, [_c("template", {
      slot: "header"
    }, [_c("div", {
      ref: "sort-caret-" + item.key,
      refInFor: true,
      staticClass: "sop-analysis-table-head",
      attrs: {
        "data-col-name": item.key
      }
    }, [_c("div", {
      staticClass: "table-head-text"
    }, [_c("span", {
      class: {
        "sop-table-head-highlight": item.key === _vm.propDefault
      },
      attrs: {
        "data-v-step": item.key === "conv_count" ? "multiple-3" : ""
      }
    }, [_vm._v(_vm._s(item.name))]), _c("el-tooltip", {
      staticStyle: {
        "margin-left": "5px"
      },
      attrs: {
        content: item.tip,
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-info-1 el-popover__reference"
    })])], 1), _c("div", {
      staticStyle: {
        position: "relative",
        display: "flex",
        "flex-direction": "column"
      }
    }, [_c("span", {
      staticClass: "table-head-text-dark"
    }, [_vm._v(_vm._s(item.value))]), _c("span", {
      staticClass: "table-head-text-light text-light-void"
    }), _c("span", {
      staticClass: "table-head-text-light text-light-absolute"
    }, [_vm._v(_vm._s(item.percent))])])])])], 2);
  })], 2)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    class: _vm.total >= 10000 ? "hide-last-page" : "",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableGuideShow < 2,
      expression: "tableGuideShow < 2"
    }]
  }, [_c("MgvTour", {
    ref: "multiple",
    attrs: {
      name: "isShowSopTableGuide",
      steps: _vm.multiSteps,
      callbacks: _vm.mgvCallbacks
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref4) {
        let {
          tour
        } = _ref4;
        return [_vm._l(_vm.multiSteps, function (item, index) {
          return [index === tour.currentStep ? _c("div", {
            key: index,
            staticClass: "multiple-sop-guide"
          }, [_c("div", {
            staticClass: "content"
          }, [_vm._v(" （" + _vm._s(`${index + 1}/${_vm.multiSteps.length}`) + "）" + _vm._s(item.content) + " ")])]) : _vm._e()];
        })];
      }
    }])
  })], 1)])]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;