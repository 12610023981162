"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "row-edit-table",
    attrs: {
      data: _setup.tableData,
      "row-class-name": _ref => {
        let {
          row
        } = _ref;
        return row.editable ? "editing-row" : "";
      },
      "row-key": "id",
      height: "calc(100vh - 46px - 56px - 16px - 36px)"
    }
  }, [_vm._l(_setup.headerList, function (item, index) {
    return _c("el-table-column", {
      key: item.prop + index,
      attrs: {
        prop: item.prop,
        label: item.label,
        "min-width": item.minWidth ? item.minWidth : undefined,
        width: item.width ? item.width : undefined
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [item.prop === "name" ? [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.name,
              "open-delay": 200
            }
          }, [_c("div", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !row.editable,
              expression: "!row.editable"
            }],
            staticClass: "content-read"
          }, [_vm._v(" " + _vm._s(row.name || "-") + " ")])]), row.editable ? _c("div", {
            staticClass: "text-input"
          }, [_c("el-input", {
            ref: "textareaRef",
            refInFor: true,
            attrs: {
              maxlength: 30,
              placeholder: "请输入名称"
            },
            model: {
              value: row.name,
              callback: function ($$v) {
                _vm.$set(row, "name", $$v);
              },
              expression: "row.name"
            }
          })], 1) : _vm._e()] : _vm._e(), item.prop === "content" ? [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              "open-delay": 200,
              "popper-class": "instruction-content-read-tooltip"
            }
          }, [_c("div", {
            staticClass: "tooltip-content",
            attrs: {
              slot: "content"
            },
            slot: "content"
          }, [_vm._v(_vm._s(row.content))]), _c("div", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !row.editable,
              expression: "!row.editable"
            }],
            staticClass: "content-read"
          }, [_vm._v(" " + _vm._s(row.content) + " ")])]), row.editable ? _c("div", {
            staticClass: "text-area-container"
          }, [_c("el-input", {
            ref: "textareaRef",
            refInFor: true,
            attrs: {
              type: "textarea",
              resize: "none",
              autosize: {
                minRows: 1,
                maxRows: 20
              },
              maxlength: _vm.contentMaxLength,
              placeholder: _vm.contentPlaceholder
            },
            model: {
              value: row.content,
              callback: function ($$v) {
                _vm.$set(row, "content", $$v);
              },
              expression: "row.content"
            }
          })], 1) : _vm._e()] : _vm._e(), item.prop === "departments" ? [!row.editable ? _c(_setup.VirtualMoreUserListInPopover, {
            attrs: {
              list: row.departments,
              "exposed-count": 3,
              "append-to-body": ""
            },
            scopedSlots: _vm._u([{
              key: "exposed-avatar",
              fn: function (_ref3) {
                let {
                  exposedItem
                } = _ref3;
                return [_c("MgvTag", {
                  attrs: {
                    icon: "iconfont icon-department",
                    round: ""
                  }
                }, [_vm._v(" " + _vm._s(exposedItem.name) + " ")])];
              }
            }, {
              key: "virtual-list-item",
              fn: function (_ref4) {
                let {
                  virtualListItem
                } = _ref4;
                return [_c("MgvTag", {
                  attrs: {
                    icon: "iconfont icon-department",
                    round: ""
                  }
                }, [_vm._v(" " + _vm._s(virtualListItem.name) + " ")])];
              }
            }], null, true)
          }) : _vm._e(), row.editable ? _c("MgvDepartmentMultiSelect", {
            ref: "mgvDepartmentMultiSelectRef",
            refInFor: true,
            attrs: {
              "request-method": _setup.getOrgTree,
              "show-tag-count": 3,
              placeholder: "请选择部门",
              "backfill-list": _setup.getDepartmentsIds(row),
              "request-params": {
                type: JSON.stringify(["conversation"]),
                workspace_id: _setup.workspaceInfo.id
              }
            },
            on: {
              change: function ($event) {
                return _setup.handleDepartmentValueChange($event, row);
              }
            }
          }) : _vm._e()] : _vm._e(), item.prop === "user_info" ? [_c("MgvAvatarName", {
            attrs: {
              size: 20,
              src: row.user_info.avatar,
              name: row.user_info.name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(row.user_info.name))])] : _vm._e(), item.prop === "create_at" ? [_vm._v(" " + _vm._s(row.create_at ? _vm.$plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm") : "-") + " ")] : _vm._e()];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right",
      width: "140",
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var _setup$activeRow;
        let {
          row,
          $index
        } = _ref5;
        return [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !row.editable,
            expression: "!row.editable"
          }],
          staticClass: "btns-container"
        }, [_c("MgvButton", {
          attrs: {
            type: "text",
            size: "small",
            "tooltip-content": _setup.activeRow && _setup.activeRow.id !== row.id ? "请先完成正在编辑的指令" : "",
            disabled: _setup.activeRow && _setup.activeRow.id !== row.id
          },
          on: {
            click: function ($event) {
              return _setup.editRow(row);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("MgvButton", {
          attrs: {
            type: "text",
            color: "danger",
            size: "small",
            "tooltip-content": _setup.activeRow && _setup.activeRow.id !== row.id ? "请先完成正在编辑的指令" : "",
            disabled: _setup.activeRow && _setup.activeRow.id !== row.id
          },
          on: {
            click: function ($event) {
              return _setup.deleteRow(row, $index);
            }
          }
        }, [_vm._v(" 删除 ")])], 1), _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: row.editable,
            expression: "row.editable"
          }],
          staticClass: "btns-container"
        }, [_c("MgvButton", {
          attrs: {
            type: "plain",
            color: "info",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _setup.cancelEdit(row, $index);
            }
          }
        }, [_vm._v(" 取消 ")]), _c("MgvButton", {
          attrs: {
            size: "small",
            loading: ((_setup$activeRow = _setup.activeRow) === null || _setup$activeRow === void 0 ? void 0 : _setup$activeRow.id) === row.id && _setup.saveLoading
          },
          on: {
            click: function ($event) {
              return _setup.saveRow(row);
            }
          }
        }, [_vm._v(" 保存 ")])], 1)];
      }
    }])
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;