"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _default = {
  name: 'EditKnowledgeNameDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activeKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        name: ''
      },
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '不可为空',
          trigger: 'change'
        }, {
          min: 1,
          max: 20,
          message: '长度不能超过 20 个字符',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.form.name = this.activeKnowledge.knowledge_name;
      }
    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeEditKnowledgeNameDialog');
    },
    submit() {
      this.$refs['renameForm'].validate(async vaild => {
        if (vaild) {
          this.loading = true;
          try {
            const res = await (0, _businessIntelligence.postKnowledgeRename)({
              name: this.form.name,
              knowledge_id: this.activeKnowledge.knowledge_id
            });
            if (res.code === 200) {
              this.$message.success('修改成功');
              this.handleCloseDialog();
              // eslint-disable-next-line vue/no-mutating-props
              this.activeKnowledge.knowledge_name = this.form.name;
            }
          } catch (error) {
            throw new Error(error);
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
exports.default = _default;