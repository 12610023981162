"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PersonalPlanHeader = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/PersonalPlanHeader"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SmartTrainingDetail',
  components: {
    PersonalPlanHeader: _PersonalPlanHeader.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  beforeRouteEnter(to, from, next) {
    if (!sessionStorage.getItem('task_practice_query')) {
      next({
        path: _routeMap.ROUTES.coachingCenterSales
      });
      return;
    }
    next();
  },
  data() {
    return {
      taskConfig: {},
      executor: {},
      taskInfo: {},
      taskScoreInfo: {},
      tableData: [],
      tableLoading: false,
      source: '',
      currentConvId: '',
      totalCount: 0,
      isComplete: true,
      maxConvId: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    isShowRestartButton() {
      var _this$taskConfig$user;
      // 我的任务进入： 判断任务状态0 或者3&&未过期
      // 团队视图或我分配的：本人是执行人 && 判断任务状态0 或者3&&未过期
      return (((_this$taskConfig$user = this.taskConfig.userInfo) === null || _this$taskConfig$user === void 0 ? void 0 : _this$taskConfig$user.id) === this.userId || this.source === 'coach-todo') && (this.taskInfo.status === 0 || this.taskInfo.status === 3 && this.$moment().isBefore(this.taskInfo.end_time, 'minute'));
    }
  },
  created() {
    this.taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query'));
    this.taskConfig.taskId = Number(this.taskConfig.taskId);
    this.taskConfig.type = Number(this.taskConfig.type);
    this.taskConfig.userInfo = JSON.parse(this.taskConfig.userInfo || '{}');
    // 我分配的和团队视图会带source
    this.source = this.taskConfig.source || this.taskConfig.from;
    this.currentConvId = this.taskConfig.convId || '';
    this.getTrainPlan({
      id: this.taskConfig.taskId
    });
    console.log('走create了吗', this.taskConfig);
  },
  methods: {
    getScoreClass(_ref) {
      let {
        score,
        pass_score
      } = _ref;
      return score >= pass_score ? 'green' : 'red';
    },
    goSmartTrainingPractice() {
      const taskId = this.taskConfig.taskId;
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        taskId,
        type: 3,
        restart: 1,
        language: this.taskInfo.language,
        practice_mode: this.taskInfo.practice_mode
      }));
      const path = _routeMap.ROUTES.practiceSmartTraining;
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          taskId,
          conv_id: this.maxConvId
        }
      });
    },
    handleSortChange() {},
    async getTrainPlan(data) {
      const res = await (0, _coachingCenter.getTrainPlan)(data);
      if (res.code === 20000) {
        this.taskInfo = {
          ...res.results.data,
          id: this.taskConfig.taskId,
          convId: this.taskConfig.convId || ''
        };
        const _userId = this.source === 'coach-todo' ? this.userId : this.taskConfig.userInfo.id;
        this.getTaskScore({
          id: this.taskConfig.taskId,
          user_id: _userId || this.userId
        });
        this.getSmartTrainingList();
      }
    },
    async getTaskScore(data) {
      const res = await (0, _coachingCenter.getTaskScore)(data);
      if (res.code === 20000) {
        this.taskScoreInfo = res.results.data;
      }
    },
    async getSmartTrainingList() {
      if (!this.currentConvId) return;
      try {
        this.tableLoading = true;
        const _userId = this.source === 'coach-todo' ? this.userId : this.taskConfig.userInfo.id;
        const res = await (0, _coachingCenter.getSmartTrainingList)({
          plan_id: this.taskConfig.taskId,
          user_id: _userId || this.userId,
          conv_id: this.currentConvId
        });
        if (res.code === 20000) {
          this.tableData = res.results.data.filter(item => item.speaker_party === 'host_salesman');
        }
      } finally {
        this.tableLoading = false;
      }
    },
    toggleHistory(convId, _ref2) {
      let {
        isUndone,
        totalCount,
        maxConvId
      } = _ref2;
      this.currentConvId = convId;
      this.totalCount = totalCount;
      this.isComplete = isUndone;
      this.maxConvId = maxConvId;
      this.getSmartTrainingList();
    }
  }
};
exports.default = _default;