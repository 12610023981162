"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", _vm._b({
    attrs: {
      title: _vm.$t("coaching.editPublicScope"),
      visible: _vm.visible,
      "before-close": _setup.closeDialog,
      width: "482px"
    }
  }, "el-dialog", _vm.$attrs, false), [_c(_setup.SetPublicScope, _vm._b({
    ref: "SetPublicScopeRef"
  }, "SetPublicScope", _vm.$attrs, false)), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.confirm")))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;