"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'TradeSet',
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'routesTree', 'workspaceInfo']),
    menuList() {
      const res = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: '/trade/set',
        targetAttr: 'link',
        getAttr: 'children'
      });
      if (res && res.length) {
        return res.sort((a, b) => a.meta.order - b.meta.order);
      }
      return [];
    }
  },
  methods: {
    recursionGet: _commonFunc.recursionGet,
    handleBackTrade() {
      this.$router.push({
        path: _routeMap.ROUTES.trade
      });
    }
  }
};
exports.default = _default;