"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dashboard = require("@/api/dashboard");
var _commentList = _interopRequireDefault(require("./commentList.vue"));
var _TodoList = _interopRequireDefault(require("./TodoList.vue"));
var _TodoFilter = _interopRequireDefault(require("./TodoFilter.vue"));
var _upcomingMeeting = _interopRequireDefault(require("./upcomingMeeting"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentTab',
  components: {
    commentList: _commentList.default,
    TodoList: _TodoList.default,
    TodoFilter: _TodoFilter.default,
    UpcomingMeeting: _upcomingMeeting.default
  },
  data() {
    return {
      activeName: 'todo',
      pageSize: 10,
      commentList: [],
      loading: false,
      listLoading: false,
      todoType: 'coach',
      todoStatus: 'created',
      pageCursor: 0,
      // 分页加载时的游标
      pageNomore: false // 分页加载时是否还有更多
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg', 'historyActivity', 'has_tencent_meeting', 'interfacePermission']),
    tabList() {
      return [{
        label: '待办',
        name: 'todo',
        show: true
      }, {
        label: '会议',
        name: 'meeting',
        show: false
      }, {
        label: '收件箱',
        name: 'inbox',
        show: true
      }, {
        label: '已发送',
        name: 'outbox',
        show: true
      }].filter(item => item.show);
    }
  },
  created() {
    this.initTab();
  },
  methods: {
    getCommentList(activeName) {
      if (this.activeName === 'meeting') return;
      this.loading = true;
      this.listLoading = true;
      let params = {};
      let requestApi = null;
      if (activeName === 'todo') {
        requestApi = _dashboard.getTodoList;
        params = {
          cursor: this.pageCursor,
          size: this.pageSize,
          type: this.todoType,
          status: this.todoStatus,
          owners: null
        };
      } else {
        requestApi = _dashboard.getBoxList;
        params = {
          cursor: this.pageCursor,
          size: this.pageSize,
          box_type: activeName
        };
      }
      requestApi(params).then(res => {
        if (res.code === 20000) {
          this.handlePageloadingResult(res.results, activeName);
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },
    handlePageloadingResult(results, activeName) {
      this.loading = false;
      if (activeName !== this.activeName) return;
      const {
        has_more,
        cursor,
        data_list
      } = results;
      this.commentList.push(...data_list);
      this.pageCursor = cursor;
      this.pageNomore = !has_more;
    },
    initTab() {
      const session = sessionStorage.getItem('activityTab');
      const queryFrom = this.$route.query.from;
      if (queryFrom === 'message_center') {
        this.activeName = 'todo';
      } else if (session) {
        this.activeName = session;
      } else if (this.historyActivity) {
        const history = this.historyActivity.split('/').pop();
        this.activeName = history;
      } else {
        this.activeName = 'todo';
      }
      this.listLoading = true;
      this.getCommentList(this.activeName);
    },
    handleTabClick() {
      this.initData();
      sessionStorage.setItem('activityTab', this.activeName);
      this.getCommentList(this.activeName);
    },
    async handleRead(id) {
      try {
        const res = await (0, _dashboard.viewSeeLater)(id);
        const index = this.commentList.findIndex(c => c.id === id);
        if (index < 0) return;
        if (res.code === 20000) {
          this.commentList.splice(index, 1);
        }
      } catch (error) {
        this.initData();
        this.getCommentList(this.activeName);
      }
    },
    handleIgnore(id) {
      const index = this.commentList.findIndex(c => c.id === id);
      if (index < 0) return;
      (0, _dashboard.ignoreSeeLater)(id).then(res => {
        if (res.code === 20000) {
          this.commentList.splice(index, 1);
        }
      });
    },
    handleDelete(id) {
      const index = this.commentList.findIndex(c => c.id === id);
      if (index < 0) return;
      (0, _dashboard.deleteSeeLater)(id).then(res => {
        if (res.code === 20000) {
          this.commentList.splice(index, 1);
        }
      });
    },
    handleTodoTypeChange(type) {
      this.todoType = type;
      this.handleRefresh();
    },
    handleTodoStatusChange(status) {
      this.todoStatus = status;
      this.handleRefresh();
    },
    handleRefresh() {
      this.initData();
      this.getCommentList(this.activeName);
    },
    initData() {
      this.commentList = [];
      this.pageCursor = 0;
      this.pageNomore = false;
    }
  }
};
exports.default = _default;