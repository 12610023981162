"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _timeFormatter = require("@/utils/time-formatter");
var _businessIntelligence = require("@/api/businessIntelligence");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PersonalKnowledgeConv',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    knowledgeList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    knowledgeIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      knowledgeConvParams: {
        host_id: '',
        start_time: '',
        end_time: '',
        page_num: 1,
        page_size: 10,
        knowledge_ids: []
      },
      loading: false,
      convList: [],
      total: 0,
      convListLength: 0
    };
  },
  computed: {
    noMore() {
      return this.convListLength && this.convListLength === this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.initData();
        if (val) {
          this.knowledgeConvParams.start_time = val.begin_date;
          this.knowledgeConvParams.end_time = val.end_date;
          this.knowledgeConvParams.host_id = Number(this.$route.query.id);
          // 请求会话列表
          this.getPersonalKnowledgeConv();
        }
      },
      deep: true
    },
    knowledgeIds: {
      handler: function (val) {
        this.knowledgeConvParams.knowledge_ids = val;
        this.initData();
        this.getPersonalKnowledgeConv();
      },
      deep: true
    }
  },
  methods: {
    goConversationDetail(id) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&noback=true`);
    },
    goConversation() {
      const {
        query
      } = this.$route;
      this.$router.push({
        path: _routeMap.ROUTES.conversation,
        query
      });
    },
    secondToTime: _timeFormatter.secondToTime,
    initData() {
      this.knowledgeConvParams.page_num = 1;
      this.convList = [];
      this.convListLength = 0;
      this.loading = false;
    },
    // 获取右侧会话列表
    async getPersonalKnowledgeConv() {
      this.loading = true;
      const res = await (0, _businessIntelligence.personalKnowledgeConv)({
        ...this.knowledgeConvParams
      });
      if (res.code === 200) {
        res.results.data.forEach(item => {
          this.convList.push(item);
        });
        this.total = res.results.count;
        this.convListLength += res.results.data.length;
        this.knowledgeConvParams.page_num++;
      }
      this.loading = false;
    },
    load() {
      this.getPersonalKnowledgeConv();
    }
  }
};
exports.default = _default;