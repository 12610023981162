"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "avatar-border",
    class: [`size-${_vm.size}`]
  }, [_vm.avatar == "" ? _c("el-avatar", {
    class: _vm.type === "customer_contact" ? "customer-avatar" : "host-avatar",
    attrs: {
      size: _vm.sizeValue,
      shape: _vm.shape
    }
  }, [_vm._v(_vm._s(_vm.handleAvatar(_vm.name)))]) : _vm._e(), _vm.avatar !== "" ? _c("el-avatar", {
    staticClass: "image-avatar",
    attrs: {
      shape: _vm.shape,
      size: _vm.sizeValue,
      src: _vm.avatar || _vm.defaultPhoto
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;