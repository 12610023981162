"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const defaultCommandList = [{
  type: 'edit',
  label: '编辑',
  icon: 'icon-edit',
  order: 1
}, {
  type: 'delete',
  label: '删除',
  icon: 'icon-delete',
  order: 6
}];
var _default = {
  name: 'BIKanban',
  components: {
    MgDraggable: _MgDraggable.default
  },
  props: {
    isCreateKanbanVisible: {
      type: Boolean,
      default: true
    },
    hasPermisstion: {
      type: Boolean,
      default: true
    },
    extraCommandList: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: Number,
      default: 0
    },
    kanbanList: {
      type: Array,
      default: () => []
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    dragKanbanMethod: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLeftArrowShow: false,
      isRightArrowShow: false,
      kanbanWrap: null,
      localKanbanList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'orgId']),
    commandList() {
      return [...defaultCommandList, ...this.extraCommandList].sort((a, b) => a.order - b.order);
    },
    filterCommandList() {
      return function (hiddenHandle) {
        if (hiddenHandle) {
          return this.commandList.filter(item => !hiddenHandle.includes(item.type));
        }
        return this.commandList;
      };
    },
    // 展示看板副标题（来自...的分享），对demo团队隐藏
    showKanbanRemark() {
      return this.orgId !== 244;
    }
  },
  watch: {
    kanbanList: {
      handler: function (val) {
        this.localKanbanList = val;
        this.$nextTick(() => {
          if (val.length) {
            const left = this.$refs[`kanban-item-${this.activeId}`][0].offsetLeft;
            this.scrollKanban(left);
          }
        });
      },
      immediate: true
    }
  },
  mounted() {
    var _this$$refs$kanbanWra;
    this.kanbanWrap = (_this$$refs$kanbanWra = this.$refs.kanbanWrap) === null || _this$$refs$kanbanWra === void 0 ? void 0 : _this$$refs$kanbanWra.$el.children[0];
    if (!this.kanbanWrap) return;
    this.handleArrowButtonShow(0);
    window.addEventListener('resize', this.handleArrowButtonShow);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleArrowButtonShow);
  },
  methods: {
    async dragEnd(_ref) {
      let {
        newIndex
      } = _ref;
      await this.dragKanbanMethod({
        kanban_id: this.localKanbanList[newIndex].kanban_id,
        workspace_id: this.workspaceInfo.id,
        des_order: newIndex + 1
      });
    },
    handleClick(event) {
      this.$emit('toggleKanban', event);
    },
    createKanban() {
      this.$emit('createKanban');
    },
    handleCommand(type, data) {
      this.$emit('handleKanban', type, data);
    },
    handleArrowClick(isRight) {
      const kanbanWrapWidth = this.kanbanWrap.offsetWidth;
      const left = this.kanbanWrap.scrollLeft + (isRight ? kanbanWrapWidth : -kanbanWrapWidth);
      this.scrollKanban(left);
    },
    scrollKanban(scrollLeft) {
      this.kanbanWrap.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.handleArrowButtonShow(scrollLeft);
      }, 500);
    },
    handleArrowButtonShow(scrollLeft) {
      this.$nextTick(() => {
        // resize方法触发时需要将参数转化
        if (typeof scrollLeft !== 'number') {
          scrollLeft = this.kanbanWrap.scrollLeft;
        }
        const totalWidth = this.kanbanWrap.scrollWidth;
        const kanbanWrapWidth = this.kanbanWrap.offsetWidth;
        if (totalWidth <= kanbanWrapWidth) {
          // 总宽度 < 看板可视区域宽度时： 隐藏左右切换箭头
          this.isLeftArrowShow = this.isRightArrowShow = false;
        } else {
          // 总宽度 < 看板可视区域宽度
          // 当看板在最左侧时即scrollLeft<=0时：隐藏左侧箭头，展示右侧箭头
          if (scrollLeft <= 0) {
            this.isLeftArrowShow = false;
            this.isRightArrowShow = true;
          } else {
            this.isLeftArrowShow = this.isRightArrowShow = true;
            // 当看板已滚动到最右侧时：隐藏右侧箭头
            if (scrollLeft + kanbanWrapWidth >= totalWidth) {
              this.isRightArrowShow = false;
            }
          }
        }
      });
    }
  }
};
exports.default = _default;