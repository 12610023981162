"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _elementUi = require("element-ui");
var _vue = require("vue");
var _default = {
  __name: 'AiCustomerGeneratorHeader',
  props: {
    updateCourse: {
      type: Function,
      default: () => {}
    },
    aiCustomerId: {
      type: [String, Number],
      default: ''
    },
    promptConfig: {
      type: Object,
      default: () => ({})
    },
    startWatch: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const isUnsavedContent = (0, _vue.ref)(false);
    (0, _vue.watch)(() => props.promptConfig, () => {
      if (props.startWatch) {
        isUnsavedContent.value = true;
      }
    }, {
      deep: true
    });
    const router = (0, _useRouter.useRouter)();
    const route = (0, _useRouter.useRoute)();
    const promptStruct = (0, _vue.inject)('promptStruct');
    const goBack = () => {
      if (isUnsavedContent.value) {
        _elementUi.MessageBox.confirm('最新编辑的课程内容还未发布，确认返回？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.replace(_routeMap.ROUTES.course);
        }).catch(() => {
          return;
        });
      } else {
        router.replace(_routeMap.ROUTES.course);
      }
    };
    window.onbeforeunload = function (e) {
      if (isUnsavedContent.value) {
        return '您有未保存的内容，是否确认离开？';
      }
    };
    (0, _vue.onUnmounted)(() => {
      window.onbeforeunload = null;
    });
    const isEdit = (0, _vue.ref)(false);
    const originalName = (0, _vue.ref)('');
    const nameInput = (0, _vue.ref)(null);
    const enterEditStatus = async () => {
      isEdit.value = true;
      await (0, _vue.nextTick)();
      nameInput.value.focus();
      originalName.value = promptStruct.value.name;
    };
    const onBlur = async () => {
      if (!promptStruct.value.name || promptStruct.value.name === originalName.value) {
        promptStruct.value.name = originalName.value;
        isEdit.value = false;
        return;
      }
      const res = await props.updateCourse(props.aiCustomerId);
      if (res.code === 20000) {
        _elementUi.Message.success('修改成功');
        isEdit.value = false;
        isUnsavedContent.value = false;
      } else {
        promptStruct.value.name = originalName;
      }
    };
    const publishLoading = (0, _vue.ref)(false);
    const publish = async () => {
      publishLoading.value = true;
      const res = await props.updateCourse(props.aiCustomerId).finally(() => {
        publishLoading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success(route.query.isCreate ? '发布成功' : '发布成功，如有已分配的任务不受影响');
        isUnsavedContent.value = false;
      }
    };
    return {
      __sfc: true,
      props,
      isUnsavedContent,
      router,
      route,
      promptStruct,
      goBack,
      isEdit,
      originalName,
      nameInput,
      enterEditStatus,
      onBlur,
      publishLoading,
      publish
    };
  }
};
exports.default = _default;