"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DialogTag = _interopRequireDefault(require("./DialogTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachActivities',
  components: {
    DialogTag: _DialogTag.default
  },
  props: {
    record: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    plus8ToCurrentZone(date) {
      return this.$plus8ToCurrentZone(date).format('M月D日');
    },
    handleTagClick() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this.$emit('tagClick', args);
    }
  }
};
exports.default = _default;