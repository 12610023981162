"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "table",
    staticClass: "team-view-table",
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight,
      "row-key": "id",
      "tree-props": {
        children: "children"
      }
    },
    on: {
      "sort-change": _vm.handleSortChange,
      "row-click": row => _vm.$refs.table.toggleRowExpansion(row)
    }
  }, [_vm._l(_vm.tableHead, function (item, index) {
    return _c("el-table-column", {
      key: item.prop + index,
      attrs: {
        prop: item.prop,
        label: item.label,
        sortable: item.sortable,
        "sort-orders": ["descending", "ascending"],
        fixed: item.fixed,
        "min-width": item.width ? item.width : 180,
        "class-name": item.prop,
        "show-overflow-tooltip": item.showOverflowTip
      },
      scopedSlots: _vm._u([item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "task-name"
          }, [_vm._v(" " + _vm._s(row.name) + " ")])];
        }
      } : item.prop === "user_infos" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("ExecutorList", {
            attrs: {
              list: row.user_infos
            }
          }), _c("span", {
            staticClass: "finish-count"
          }, [_vm._v("( "), _c("span", {
            staticClass: "count"
          }, [_vm._v(_vm._s(`${row.finished}/${row.count}`))]), _vm._v(" " + _vm._s(_vm.$t("coaching.finish")) + ")")])];
        }
      } : item.prop === "course_type" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "course-type"
          }, [_vm._v(_vm._s(_vm.courseType(row)))])];
        }
      } : item.prop === "course_name" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_vm._v(" " + _vm._s(row.children ? `${_vm.$t("coaching.coursesVar", [row.children.length])}` : row.course_name || "-") + " ")];
        }
      } : item.prop === "creator" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("div", {
            staticClass: "creator-info"
          }, [_c("image-avatar", {
            staticClass: "upper-avatar",
            attrs: {
              name: row.creator_info.name,
              avatar: row.creator_info.avatar,
              shape: "circle"
            }
          }), _c("span", [_vm._v(_vm._s(row.creator_info.name))])], 1)];
        }
      } : item.prop === "start_time" ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.start_time)) + " ")];
        }
      } : item.prop === "end_time" ? {
        key: "default",
        fn: function (_ref7) {
          let {
            row
          } = _ref7;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.end_time)) + " ")];
        }
      } : item.prop === "status" ? {
        key: "default",
        fn: function (_ref8) {
          let {
            row
          } = _ref8;
          return [_c("div", {
            class: _vm.getStatusClass(row)
          }, [_vm._v(_vm._s(_vm.getStatus(row)))])];
        }
      } : item.prop === "category_info" ? {
        key: "default",
        fn: function (_ref9) {
          let {
            row
          } = _ref9;
          return [_c("div", {
            staticClass: "category-names"
          }, [_vm._v(_vm._s(_vm.getCategoryNames(row)))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      "min-width": 240,
      prop: "operate",
      fixed: "right",
      label: _vm.$t("coaching.operation"),
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        let {
          row
        } = _ref10;
        return [_c("leader-table-operate", {
          attrs: {
            row: row
          },
          on: {
            goDetail: function ($event) {
              return _vm.goTaskDetail(row);
            },
            command: function ($event) {
              return _vm.handleCommand($event, row);
            }
          }
        })];
      }
    }])
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;