"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'KeyEvent',
  props: {
    showNumber: {
      type: Number,
      default: 0
    },
    keyEvent: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const getName = item => {
      if (item.id.startsWith('c')) {
        return item.name.slice(3, 4);
      }
      return item.name.slice(0, 1);
    };
    return {
      __sfc: true,
      props,
      getName
    };
  }
};
exports.default = _default;