"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'DepartmentSelect',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: () => []
    },
    noDefault: Boolean,
    placeholder: {
      type: String,
      default: '请选择部门'
    },
    page: {
      type: String,
      default: ''
    },
    requestMethod: {
      type: Function,
      default: _organization.getOrgTree
    },
    params: {
      type: [Object, String],
      default: () => ({})
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      departmentLabel: '',
      departmentValue: '',
      orgTree: [],
      defaultChecked: [],
      defaultExpanded: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultDepartmentId: '',
      defaultDepartmentName: ''
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$nextTick(async () => {
          if (val.length) {
            await this.getOrgTree();
            const node = this.$refs['department-tree'].getNode(val[0]);
            if (!node) return;
            this.setCheckedNode(node.data);
          } else {
            this.setCheckedNode();
          }
        });
      }
    }
  },
  created() {
    this.getOrgTree();
  },
  methods: {
    async getOrgTree() {
      if (this.orgTree.length) return;
      let res;
      if (JSON.stringify(this.params) === '{}') {
        res = await this.requestMethod();
      } else {
        res = await this.requestMethod(this.params);
      }
      if (res.code === 200) {
        this.orgTree = res.results;
        const {
          id,
          name
        } = res.results[0];
        this.defaultExpanded = [id];
        if (this.page === 'noDefault') return;
        this.defaultDepartmentName = name;
        this.defaultDepartmentId = id;
        this.setCheckedNode();
        if (!this.noDefault) {
          this.handleChange();
        }
        this.defaultChecked = [id];
      }
    },
    handleCheckChange(data, is_checked) {
      if (is_checked) {
        if (data.id === this.value[0]) return; // 如果没有改变，return
        this.departmentLabel = data.name;
        this.$refs['department-tree'].setCheckedKeys([data.id]); // 保证只有一个选项选中
        this.departmentValue = data.id;
        this.handleChange();
      } else {
        // 如果没有选中的项，则选中默认值
        const checkedArr = this.$refs['department-tree'].getCheckedKeys();
        if (!checkedArr.length) {
          this.setCheckedNode();
          if (this.page === 'noDefault') {
            this.$emit('change', []);
          }
        }
      }
    },
    handleChange() {
      // 修复清空筛选项不能清空问题
      if (this.noDefault) {
        this.$emit('change', this.departmentValue === this.defaultDepartmentId ? [] : [this.departmentValue]);
      } else {
        this.$emit('change', [this.departmentValue]);
      }
      this.$emit('labelChange', this.departmentLabel);
      if (this.$refs['department-select']) {
        this.$refs['department-select'].blur();
      }
    },
    setCheckedNode(data) {
      // 不传值就给default
      this.departmentValue = data ? data.id : this.defaultDepartmentId;
      this.departmentLabel = data ? data.name : this.defaultDepartmentName;
      this.$refs['department-tree'] && this.$refs['department-tree'].setCheckedKeys([data ? data.id : this.defaultDepartmentId]);
    },
    clear() {
      this.$refs['department-tree'] && this.$refs['department-tree'].setCheckedKeys([]);
      this.$emit('change', []);
    }
  }
};
exports.default = _default;