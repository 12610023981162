"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mediaUpload = require("@/api/mediaUpload");
var _organization = require("@/api/organization");
var _download = require("@/utils/download");
// import { uploadBatchFile, downloadErrorData } from "@/api/clues";
var _default = {
  props: {
    type: String,
    alias: String,
    tempalteRouter: String,
    uploadName: String,
    ruleText: String
  },
  data() {
    return {
      form: {
        importType: ''
      },
      fileName: '',
      file: null,
      uploadRules: {
        importType: [{
          required: true,
          message: '请选择处理方式',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    beforeUpload(file) {
      if (/\.(?:xlsx|xls)$/.test(file.name)) {
        this.file = file;
        this.fileName = file.name;
        this.$emit('hasFile');
      } else {
        this.$message.warning('请选择xlsx格式的文件');
      }
      return false;
    },
    async batchUpload() {
      // this.$refs["upload-form"].validate(async (valid) => {
      // if (valid) {
      const res = await (0, _mediaUpload.batchUpload)({
        // import_type:this.form.importType,
        file: this.file
        // upload_type: this.alias,
      }, this.alias);
      if (res.code === 200 && res.msg === 'Success') {
        this.$emit('success', res.results);
      }
      // }
      // });
    },

    async uploadMembers() {
      const res = await (0, _organization.uploadMembers)({
        file: this.file
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$emit('success', res.results);
      }
    },
    startImport() {
      this.type === 'employee' ? this.uploadMembers() : this.batchUpload();
    },
    downloadTemplate() {
      (0, _download.download)(this.tempalteRouter, this.uploadName + '导入模板');
    }
  }
};
exports.default = _default;