"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-app"
  }, [_vm._m(0), _vm._l(_vm.app, function (item) {
    return _c("app-card", {
      key: item.appName,
      attrs: {
        "app-name": item.appName,
        "app-description": item.appDescription,
        "app-logo": item.appLogo,
        "is-bind": item.isBind,
        "bind-user-info": _vm.bindUserInfo
      },
      on: {
        getAuthLink: _vm.getAuthLink,
        unbindApp: _vm.unbindApp
      }
    });
  })], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "desc"
  }, [_c("h1", [_vm._v("连接第三方应用")]), _c("h2", [_vm._v("将 Megaview 与第三方应用连接来提高工作效率节省时间")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;