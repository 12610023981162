"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireWildcard(require("axios"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _commonFunc = require("@/utils/commonFunc");
var _vue = require("vue");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  __name: 'UploadFileItem',
  props: {
    file: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['deleteFile'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const loaded = (0, _vue.ref)([]);
    const uploadSpeed = (0, _vue.computed)(() => {
      if (!loaded.value.length) return 0;
      return (loaded.value[loaded.value.length - 1] - loaded.value[0]) / loaded.value.length;
    });
    let cancelRequest = null;
    const uploadFileApi = async () => {
      const config = {
        onUploadProgress: ProgressEvent => {
          if (loaded.value.length >= 5) {
            loaded.value.shift();
          }
          loaded.value.push(ProgressEvent.loaded);
        },
        cancelToken: new _axios.CancelToken(cancel => {
          cancelRequest = cancel;
        })
      };
      try {
        const res = await (0, _knowledgeLibrary.uploadKnowledgeFile)({
          file: props.file.raw
        }, config);
        if (res.code === 20000) {
          emit('changeFileState', {
            state: 'success',
            file_path: res.results.file_path
          });
        }
      } catch (error) {
        if (_axios.default.isCancel(error)) {
          return;
        }
        emit('changeFileState', {
          state: 'fail',
          errorMsg: error.message
        });
      }
    };
    const deleteFile = () => {
      if (props.file.state === 'uploading') {
        // 取消上传
        cancelRequest && cancelRequest();
        // 删除文件
      }

      emit('deleteFile', props.file);
    };
    (0, _vue.watch)(() => props.file, async val => {
      if (val.state === 'uploading') {
        await uploadFileApi();
      }
    }, {
      deep: true,
      immediate: true
    });
    return {
      __sfc: true,
      props,
      loaded,
      uploadSpeed,
      cancelRequest,
      uploadFileApi,
      emit,
      deleteFile,
      computerSizeToStr: _commonFunc.computerSizeToStr
    };
  }
};
exports.default = _default;