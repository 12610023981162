"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SearchResult',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    conversationType: {
      type: String,
      default: ''
    },
    resultList: {
      type: Array,
      default: function () {
        return null;
      }
    },
    words: {
      type: String,
      default: ''
    },
    partiInfo: {
      type: Array,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      isShowSearch: false,
      // 是否在搜索模式下
      isShowSearchList: false,
      // 在搜索模式下，是否展示搜索列表
      dialogArray: [],
      searchWords: '',
      activeOrder: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curtime', 'curconvid', 'curorder', 'audio', 'is_user_scroll', 'is_video_pause'])
  },
  watch: {
    words(value) {
      this.searchWords = value;
      this.handleShowSearch();
    }
  },
  mounted() {
    if (!this.words) {
      return false;
    } else {
      this.searchWords = this.words;
      this.handleShowSearch();
    }
  },
  methods: {
    handleShowSearch() {
      this.isShowSearch = true;
      this.isShowSearchList = true;
    },
    realFormatSecond(second) {
      var secondType = typeof second;
      if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);
        var hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        var mimute = Math.floor(second / 60);
        second = second - mimute * 60;
        return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
      } else {
        return '00:00';
      }
    },
    goKeyDetail(order, item) {
      console.log('----------f');
      if (this.resultList && this.resultList.length > 0) {
        if (!item) {
          item = this.resultList.filter(item => {
            return item.order === order;
          })[0];
        }
        this.activeOrder = order;
        // this.$store.dispatch('video/set_choose', true);
        this.$store.dispatch('video/set_event', {
          order: order,
          event: item,
          type: 'keyword'
        });
        this.$emit('goKeyDetail', order, item);
        // this.$emit("changeKeyWordBack")
      }

      this.activeOrder = order;
      // this.$store.dispatch('video/set_choose', true);
      this.$store.dispatch('video/set_event', {
        order: order,
        event: item,
        type: 'keyword'
      });
      this.$emit('goKeyDetail', order, item);
    },
    isChiStr(str) {
      const pattern = new RegExp('[\u4E00-\u9FA5]+');
      if (pattern.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
exports.default = _default;