"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _default = {
  __name: 'TaskScoreHistoryList',
  props: {
    reply: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      t: _i18n.t
    };
  }
};
exports.default = _default;