"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "mgv-catalog-tree"
  }, [_c("el-tree", _vm._g(_vm._b({
    ref: "treeRef",
    attrs: {
      "icon-class": "iconfont icon-caret-right",
      "node-key": "id",
      "highlight-current": "",
      data: _vm.treeData,
      "default-expanded-keys": _setup.defaultExpandedKeys,
      "expand-on-click-node": false,
      "auto-expand-parent": false
    },
    on: {
      "node-click": _setup.handleNodeClick,
      "node-expand": (data, node) => _setup.updateExpandedNodeKey(node, true),
      "node-collapse": (data, node) => _setup.updateExpandedNodeKey(node, false)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data,
          node
        } = _ref;
        return [_c("div", {
          staticClass: "node-item"
        }, [_vm.iconConfigMethod ? _c("i", {
          staticClass: "iconfont",
          class: _setup.getIconValue(data, node)
        }) : _vm._e(), _setup.activeInputNodeId === node.id ? _c("el-input", {
          ref: "nodeInputRef",
          staticClass: "node-name-input",
          on: {
            blur: function ($event) {
              $event.stopPropagation();
              return _setup.handleInputBlur(data, node);
            }
          },
          nativeOn: {
            keypress: function ($event) {
              if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return $event.target.blur();
            }
          },
          model: {
            value: _setup.inputValue,
            callback: function ($$v) {
              _setup.inputValue = typeof $$v === "string" ? $$v.trim() : $$v;
            },
            expression: "inputValue"
          }
        }) : _c("div", {
          class: ["node-content", {
            "has-permission": _vm.operateBtnsOptionsMethod(data, node).length
          }]
        }, [_c("span", {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(data.name) + " ")]), _vm.showCount ? _c("span", {
          staticClass: "node-count"
        }, [_vm._v(_vm._s(data.count))]) : _vm._e(), _vm._t("operateBtns", function () {
          return [_vm.operateBtnsOptionsMethod(data, node).length ? _c("div", {
            staticClass: "operation-btns",
            on: {
              click: function ($event) {
                $event.stopPropagation();
              }
            }
          }, [_vm._l(_vm.operateBtnsOptionsMethod(data, node), function (itemDropdown) {
            return [itemDropdown.options.length === 1 && itemDropdown.options[0].length === 1 ? _c("MgvButton", {
              key: itemDropdown.icon,
              attrs: {
                icon: itemDropdown.icon,
                type: "text",
                size: "mini",
                square: ""
              },
              on: {
                click: function ($event) {
                  var _itemDropdown$options;
                  return _setup.handleCommand(node, (_itemDropdown$options = itemDropdown.options[0][0]) === null || _itemDropdown$options === void 0 ? void 0 : _itemDropdown$options.type);
                }
              }
            }) : _c("el-dropdown", {
              key: itemDropdown.icon,
              attrs: {
                trigger: "click"
              },
              on: {
                command: function ($event) {
                  return _setup.handleCommand(node, $event);
                }
              }
            }, [_c("MgvButton", {
              attrs: {
                icon: itemDropdown.icon,
                type: "text",
                size: "mini",
                square: ""
              }
            }), _c("el-dropdown-menu", {
              staticClass: "mgv-catalog-operate-dropdown",
              attrs: {
                slot: "dropdown"
              },
              slot: "dropdown"
            }, _vm._l(itemDropdown.options, function (optionsGroupItem, index) {
              return _c("div", {
                key: index,
                class: ["option-group-item", itemDropdown.options.length > 1 && index !== itemDropdown.options.length - 1 ? "divided" : ""]
              }, [_vm._l(optionsGroupItem, function (item) {
                return [_c("el-dropdown-item", {
                  key: item.type,
                  attrs: {
                    disabled: item.disabled,
                    command: item.type
                  }
                }, [_c("i", {
                  class: ["iconfont", item.icon],
                  style: {
                    color: item.color
                  }
                }), _vm._v(_vm._s(item.label)), item.isBeta ? _c("span", {
                  staticClass: "beta-tag"
                }, [_vm._v("Beta")]) : _vm._e()])];
              })], 2);
            }), 0)], 1)];
          })], 2) : _vm._e()];
        }, {
          nodeInfo: node
        })], 2)], 1)];
      }
    }])
  }, "el-tree", _vm.$attrs, false), _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;