"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'PicDisplay',
  props: {
    picList: {
      type: Array,
      default: () => []
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['deletePic'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const deletePic = index => {
      emit('deletePic', index);
    };
    const picRef = (0, _vue.ref)([]);
    (0, _vue.watch)(() => picRef, (newVal, oldVal) => {
      console.log(picRef.value);
    }, {
      immediate: true,
      deep: true
    });
    return {
      __sfc: true,
      props,
      emit,
      deletePic,
      picRef
    };
  }
};
exports.default = _default;