"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ShowEventTrackerFilter',
  props: {
    filtesConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    // 处理关键事件显示
    getKeyEventFilterHtml() {
      const {
        event_engine_relation: relation,
        keyEventCascaderList
      } = this.filtesConfig;
      const validKeyEventCascaderList = keyEventCascaderList.filter(item => item.keyEventName.length);
      const isAddRemarks = validKeyEventCascaderList.length > 1;
      let str = validKeyEventCascaderList.reduce((pre, current) => {
        return pre + (current.inverse ? `<span class="inverse-show">(不包含) </span>${current.keyEventLabel}；` : `${current.keyEventLabel}；`);
      }, '');
      if (isAddRemarks) {
        str = `<span class="filter-key-event-list">${relation === 'or' ? '或' : '且'} [ ` + str + `]</span>`;
      }
      return str.length ? `关键事件跟踪器：${str}` : '';
    }
  }
};
exports.default = _default;