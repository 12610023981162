"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "choose-role-desemsibilisation"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("span", [_vm._v("选择适用角色")]), _c("el-button", {
    attrs: {
      icon: "el-icon-edit",
      type: "text"
    },
    on: {
      click: _vm.openChooseRoleDialog
    }
  }, [_vm._v("修改")])], 1), _c("section", {
    staticClass: "content"
  }, [_vm._l(_vm.hasDesensitizationRoleList, function (item) {
    return [_c("div", {
      key: item.id,
      staticClass: "role-item"
    }, [_vm._v(_vm._s(item.name))])];
  })], 2), _c("choose-role-dialog", {
    attrs: {
      "choose-role-dialog-visible": _vm.chooseRoleDialogVisible,
      "has-desensitization-role-list": _vm.hasDesensitizationRoleList
    },
    on: {
      closeChooseRoleDialog: _vm.closeChooseRoleDialog,
      fetchRoleDesensitizationSuccess: _vm.fetchRoleDesensitizationSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;