"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "prompt-template-library-filters"
  }, [_c("el-input", {
    attrs: {
      placeholder: "搜索模版名称"
    },
    on: {
      change: _setup.onChange
    },
    model: {
      value: _setup.filter.name,
      callback: function ($$v) {
        _vm.$set(_setup.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  }), _c("el-input", {
    attrs: {
      placeholder: "搜索提示语内容"
    },
    on: {
      change: _setup.onChange
    },
    model: {
      value: _setup.filter.content,
      callback: function ($$v) {
        _vm.$set(_setup.filter, "content", $$v);
      },
      expression: "filter.content"
    }
  }), _c("MgvMemberMultiSelect", {
    staticClass: "mgv-member-multi-select",
    attrs: {
      placeholder: "请选择创建人",
      "request-function": _setup.getOrgMemberTree,
      "is-prevent-not-selected": false,
      "request-search-function": _setup.getUserList,
      "request-params": {
        size: 9999
      }
    },
    on: {
      change: _setup.membersChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;