"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _default = {
  __name: 'SetPublicScope',
  props: {
    isPublic: {
      type: Number,
      default: 0
    },
    backfillList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const currentIsPublic = (0, _vue.ref)(1);
    (0, _vue.watch)(() => props.isPublic, newVal => {
      currentIsPublic.value = newVal;
    });
    const assignee = (0, _vue.ref)({});
    const assigneeChange = val => {
      assignee.value = val;
    };
    expose({
      assignee,
      isPublic: currentIsPublic
    });
    return {
      __sfc: true,
      props,
      emit,
      currentIsPublic,
      assignee,
      assigneeChange,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList
    };
  }
};
exports.default = _default;