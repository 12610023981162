"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      title: "新增评分维度",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("MgvButton", {
          staticClass: "cancel",
          attrs: {
            type: "plain",
            color: "info"
          },
          on: {
            click: function ($event) {
              return _setup.updateVisible(false);
            }
          }
        }, [_vm._v("取消")]), _c("MgvButton", {
          staticClass: "submit-button",
          on: {
            click: _setup.submit
          }
        }, [_vm._v("保存")])];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "formRef",
    staticClass: "form-content",
    attrs: {
      model: _setup.form,
      rules: _setup.rule
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "评分维度名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "最多输入10个字"
    },
    model: {
      value: _setup.form.name,
      callback: function ($$v) {
        _vm.$set(_setup.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;