"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConstMap = require("@/components/Message/components/ConstMap.js");
var _default = {
  name: 'SourceTag',
  props: {
    renderInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    operateData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    getDynamicTagClass() {
      var _this$operateData, _this$operateData$con;
      return {
        'can-click': !!this.operateData.router_name,
        'is-disable': (_this$operateData = this.operateData) === null || _this$operateData === void 0 ? void 0 : (_this$operateData$con = _this$operateData.config) === null || _this$operateData$con === void 0 ? void 0 : _this$operateData$con.is_disable
      };
    }
  },
  methods: {
    getIconName(type) {
      return 'icon-' + _ConstMap.ICONMAP[type];
    },
    sourceClick() {
      if (this.operateData.router_name && !this.operateData.config.is_disable) {
        this.$emit('click', this.operateData);
      }
    }
  }
};
exports.default = _default;