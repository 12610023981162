"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useConversationCustomFieldForm = useConversationCustomFieldForm;
exports.useFormValid = useFormValid;
exports.useHostSalesMan = useHostSalesMan;
exports.useIndependentTrade = useIndependentTrade;
var _mediaUpload = require("@/api/mediaUpload");
var _vue = require("vue");
function useConversationCustomFieldForm() {
  const conversationDynamicFieldsDesc = (0, _vue.ref)([]);
  const conversationDynamicForm = (0, _vue.ref)({});
  const formatConversationCustomFieldFormDesc = formDesc => {
    const desc = [];
    for (const item of formDesc) {
      const obj = {
        id: String(item.id),
        label: item.name,
        type: item.from_type,
        required: !!item.is_require,
        unique: item.is_unique,
        preset: !!item.field_type,
        action: '',
        options: item.options,
        disabled: item.is_edit === 0,
        halfRow: false,
        default: item.default_value,
        tips: item.tips,
        precisions: item.precisions,
        is_thousand_separate: item.is_thousand_separate,
        is_money: item.is_money
      };
      desc.push(obj);
    }
    return desc;
  };
  const getConversationCustomFieldApi = async () => {
    const res = await (0, _mediaUpload.getConversationCustomField)();
    if (res.code === 20000) {
      conversationDynamicFieldsDesc.value = formatConversationCustomFieldFormDesc(res.results.fields);
      conversationDynamicForm.value = {
        ...conversationDynamicFieldsDesc.value['default_value']
      };
    }
  };
  return {
    getConversationCustomFieldApi,
    conversationDynamicFieldsDesc,
    conversationDynamicForm
  };
}
function useIndependentTrade(form, store) {
  const independentTradeList = (0, _vue.ref)([]);
  const searchTradeApi = async val => {
    if (!form.value.independent_contact_id) return;
    const res = await (0, _mediaUpload.searchTrade)({
      filter: val,
      workspace_id: store.getters.workspaceInfo.id,
      contact_id: form.value.independent_contact_id
    });
    if (res.code === 20000) {
      independentTradeList.value = res.results.datas;
    }
  };
  return {
    searchTradeApi,
    independentTradeList
  };
}
function useHostSalesMan(form, store) {
  const membersFinderRef = (0, _vue.ref)();
  const handleDefaultHostSalesMan = async (memberId, memberObj) => {
    await (0, _vue.nextTick)();
    const node = membersFinderRef.value;
    if (node) {
      node.selection = [memberId];
      node.selectionObj = [memberObj];
    }
  };
  const handleMemberChange = (value, selectionObj) => {
    const [hostSalesManId] = value;
    const [hostSalesManInfo] = selectionObj;

    // 存储会话列表页面会话归属人信息
    if (hostSalesManId) {
      localStorage.setItem(`${store.getters.orgId}${store.getters.userId}conversationListUploaderInfo`, JSON.stringify({
        hostSalesManId,
        hostSalesManInfo
      }));
      store.dispatch('upload/getUploadDuration', hostSalesManId);
    } else {
      localStorage.removeItem(`${store.getters.orgId}${store.getters.userId}conversationListUploaderInfo`);
      store.commit('upload/SET_UPLOAD_DURATION', {
        max_duration: 0,
        used_duration: 0
      });
    }
    store.commit('upload/SET_UPLOAD_HOST_SALESMAN_INFO', hostSalesManInfo);
    form.value.host_salesman_id = hostSalesManId;
  };
  const initialHostSalesMan = () => {
    const uploaderInfo = localStorage.getItem(`${store.getters.orgId}${store.getters.userId}conversationListUploaderInfo`);
    if (uploaderInfo) {
      const {
        hostSalesManId,
        hostSalesManInfo
      } = JSON.parse(uploaderInfo);
      handleDefaultHostSalesMan(hostSalesManId, hostSalesManInfo);
    } else {
      handleDefaultHostSalesMan(store.getters.userId, {
        id: store.getters.userId,
        name: store.getters.name,
        avatar: store.getters.avatar
      });
    }
  };
  return {
    membersFinderRef,
    initialHostSalesMan,
    handleMemberChange
  };
}
function useFormValid() {
  const uploadMediaFormRef = (0, _vue.ref)(null);
  const conversationDynamicFormRef = (0, _vue.ref)(null);
  const uploadFormValid = () => {
    const conversationDynamicFormValid = conversationDynamicFormRef.value.validate();
    let flag = false;
    uploadMediaFormRef.value.validate(valid => flag = valid);
    return conversationDynamicFormValid && flag;
  };
  return {
    uploadFormValid,
    uploadMediaFormRef,
    conversationDynamicFormRef
  };
}