"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-link", _vm._b({
    staticClass: "mgv-link",
    attrs: {
      type: _vm.type,
      disabled: _vm.disabled,
      href: _vm.href,
      icon: _vm.icon
    }
  }, "el-link", _vm.$attrs, false), [_vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;