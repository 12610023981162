"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clues = require("@/api/clues");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _client = require("@/api/client");
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'allocateToAnyone'
    },
    leadId: {
      type: Number,
      default: 0
    },
    leadIds: {
      type: Array,
      default: () => []
    },
    accountId: {
      type: Array,
      default: () => []
    },
    batch: {
      type: Boolean,
      default: false
    },
    originUserId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: [],
      options: [],
      formData: {
        userId: null // 模糊搜索方式的选择值
      },

      userOptions: [],
      // 模糊搜索的选择推荐项
      submitLoading: false
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    title() {
      switch (this.$props.type) {
        case 'allocateToAnyone':
        case 'batchAllocateToAnyone':
          return '分配线索';
        case 'allocateFromAToB':
        case 'batchAllocateFromAToB':
        case 'clientChangeFollower':
        case 'clueChangeFollower':
          return '变更跟进人';
        case 'allocateFromMeToAnyone':
          return '移交线索';
        case 'clientAllocateToAnyone':
          return '分配';
      }
    },
    ...(0, _vuex.mapGetters)(['api_permissions']),
    choosedId() {
      return this.val[this.val.length - 1];
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['selectMembers'].selection = [];
        });
        if (this.type === 'clientAllocateToAnyone' || this.type === 'clientChangeFollower' || this.type === 'clueChangeFollower') {
          this.userId = null; // 重置输入框
        } else {
          this.getAvailableAllocatorList();
        }
      }
    }
  },
  methods: {
    handleSelectChange(data) {
      this.formData.userId = data[0];
    },
    async getAvailableAllocatorList() {
      const res = await (0, _clues.getAvailableAllocatorList)();
      this.options = this.treeFormating(res.results.options);
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.resetForm();
    },
    async allocateToAnyone() {
      const res = await (0, _clues.modifyFollower)({
        lead_id: this.$props.leadId,
        origin_follow_up_by: 0,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('分配成功');
      }
    },
    async allocateFromAToB() {
      const res = await (0, _clues.modifyFollower)({
        lead_id: this.$props.leadId,
        origin_follow_up_by: this.$props.originUserId,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('变更成功');
      }
    },
    async allocateFromMeToAnyone() {
      const res = await (0, _clues.modifyFollower)({
        lead_id: this.$props.leadId,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('移交成功');
      }
    },
    async batchAllocateToAnyone() {
      const res = await (0, _clues.batchModifyFollower)({
        lead_id: this.$props.leadIds,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('分配成功');
      }
    },
    async batchAllocateFromAToB() {
      const res = await (0, _clues.batchModifyFollower)({
        lead_id: this.$props.leadIds,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('变更成功');
      }
    },
    async editClientFollowUpBy() {
      const res = await (0, _client.editClientFollowUpBy)({
        id: this.accountId,
        follow_up_by: this.formData.userId
      });
      if (res.code === 20000) {
        if (this.$props.type === 'clientChangeFollower') {
          this.$message.success('变更跟进人成功');
        }
        this.closeDialog();
        this.$emit('renew');
        if (this.api_permissions.includes('/api/account/account_pool/detail')) {
          this.$bus.$emit('freshAccountDetailAndCloseAccountSeaDrawer', ...this.accountId);
        }
      }
    },
    async accountSeaAssignsFollowUpBy() {
      const res = await (0, _client.accountSeaAssignsFollowUpBy)({
        id: this.accountId,
        follow_up_by: this.formData.userId
      });
      if (res.code === 20000) {
        if (this.$props.type === 'clientAllocateToAnyone') {
          this.$message.success('分配成功');
        }
        this.closeDialog();
        this.$emit('renew');
        if (this.api_permissions.includes('/api/account/account/account_pool_detail')) {
          this.$bus.$emit('freshAccountDetailAndCloseAccountSeaDrawer', ...this.accountId);
        }
      }
    },
    // 编辑变更线索跟进人
    async editClueFollowUpBy() {
      const res = await (0, _clues.postClueFollowUpBy)({
        lead_id: this.$props.leadIds,
        follow_up_by: this.formData.userId
      });
      if (res.code === 20000) {
        if (this.$props.type === 'clueChangeFollower') {
          this.$message.success('变更跟进人成功');
        }
        this.closeDialog();
        this.$emit('refreshFollower');
      }
    },
    async editCluetFollowUpBySubmit() {
      this.$refs['distributeForm'].validate(valid => {
        if (valid) {
          this.editCluetFollowUpBy();
        } else {
          return false;
        }
      });
    },
    async getUserOptions(filter) {
      const res = await (0, _conversationList.getHostPersonOrTeam)({
        filter
      });
      if (res.code === 200) {
        this.userOptions = res.results.users;
      }
    },
    resetForm() {
      this.$refs['distributeForm'].resetFields();
    },
    async editClientFollowUpBySubmit() {
      this.$refs['distributeForm'].validate(valid => {
        if (valid) {
          this.editClientFollowUpBy();
        } else {
          return false;
        }
      });
    },
    // 提交变更跟进人信息
    async editClueFollowUpBySubmit() {
      this.$refs['distributeForm'].validate(valid => {
        if (valid) {
          this.editClueFollowUpBy();
        } else {
          return false;
        }
      });
    },
    // 客户公海分配
    async accountSeaAssignsFollowUpBySubmit() {
      this.$refs['distributeForm'].validate(valid => {
        if (valid) {
          this.accountSeaAssignsFollowUpBy();
        } else {
          return false;
        }
      });
    },
    async submitForm() {
      this.submitLoading = true;
      switch (this.$props.type) {
        case 'allocateToAnyone':
          await this.allocateToAnyone();
          break;
        case 'allocateFromAToB':
          await this.allocateFromAToB();
          break;
        case 'batchAllocateToAnyone':
          await this.batchAllocateToAnyone();
          break;
        case 'batchAllocateFromAToB':
          await this.batchAllocateFromAToB();
        // eslint-disable-next-line no-fallthrough
        case 'allocateFromMeToAnyone':
          await this.allocateFromMeToAnyone();
        // eslint-disable-next-line no-fallthrough
        case 'clientAllocateToAnyone':
          await this.accountSeaAssignsFollowUpBySubmit();
          break;
        case 'clientChangeFollower':
          await this.editClientFollowUpBySubmit();
          break;
        case 'clueChangeFollower':
          await this.editClueFollowUpBySubmit();
          break;
      }
      this.submitLoading = false;
    },
    treeFormating(tree) {
      const resArr = [];
      const formatChildren = tree => {
        const obj = this.handleCurrent(tree.this);
        // eslint-disable-next-line no-prototype-builtins
        if (tree.hasOwnProperty('children')) {
          for (const item of tree.children) {
            // 将部门插入至成员之前
            let _index = 0;
            for (const elem of obj.children) {
              // eslint-disable-next-line no-prototype-builtins
              if (!elem.hasOwnProperty('children')) {
                _index = obj.children.indexOf(elem);
                break;
              }
            }
            obj.children.splice(_index, 0, formatChildren(item));
          }
        }
        return obj;
      };
      resArr.push(formatChildren(tree));
      return resArr;
    },
    handleCurrent(current) {
      const {
        department_info,
        users
      } = current;
      const obj = {
        label: department_info.name,
        value: department_info.tree_id,
        children: []
      };
      for (const user of users) {
        obj.children.push({
          label: user.name,
          value: user.user_id
        });
      }
      return obj;
    }
  }
};
exports.default = _default;