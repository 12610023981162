"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.schema.fields ? _c("div", {
    staticClass: "evaluate-form-content"
  }, [_vm.complianceStatus !== 2 ? _c("EvaluateFormWidget", {
    ref: "evaluateFormWidget",
    attrs: {
      schema: _vm.schema
    }
  }) : _c("evaluate-form-display", {
    attrs: {
      "evaluation-record": _vm.evaluationRecord
    }
  })], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;