"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authWechat = _interopRequireDefault(require("@/assets/authApp/authWechat.svg"));
var _authWework = _interopRequireDefault(require("@/assets/authApp/authWework.svg"));
var _authAvatar = _interopRequireDefault(require("@/assets/authApp/authAvatar.svg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MergeAppDataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      configObj: {
        fieldName: 'field_name',
        headerFieldValue: 'merge',
        placement: 'top',
        contentText: '已合并状态表示联系人已合并到已有的Megaview外呼联系人中；<br />未合并状态表示联系人未与任何外呼联系人进行合并。',
        iconClass: 'icon-question2'
      },
      headerFieldLabel: 'label',
      wechatLogo: _authWechat.default,
      weworkLogo: _authWework.default,
      defaultAvatar: _authAvatar.default,
      tableHeader: [{
        field_name: 'name',
        label: '联系人名称'
      }, {
        field_name: 'type',
        label: '联系人类型'
      }, {
        field_name: 'merge',
        label: '联系人状态'
      }, {
        field_name: 'remark_mobile',
        label: '手机号'
      }, {
        field_name: 'merged_contact_name',
        label: '合并后联系人名称'
      }, {
        field_name: 'merge_by',
        label: '操作人'
      }, {
        field_name: 'operate',
        label: '操作'
      }]
    };
  },
  methods: {
    openMergeDialog(rowInfo) {
      this.$emit('openMergeDialog', rowInfo);
    },
    openRevertMergenceDialog(rowInfo) {
      this.$emit('openRevertMergenceDialog', rowInfo);
    },
    setStatusClassName(status) {
      return status ? 'icon-circle-check' : 'icon-warning-outline';
    }
  }
};
exports.default = _default;