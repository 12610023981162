"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'InviteSucceedDialog',
  props: {
    visible: Boolean,
    inviteText: String
  },
  watch: {
    visible() {
      this.localInviteText = this.inviteText;
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes', 'modulePermission']),
    showButton() {
      return this.$route.path !== _routeMap.ROUTES.calendar && this.modulePermission.calendar;
    }
  },
  data() {
    return {
      localInviteText: ''
    };
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false);
    },
    onCopySuccess() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.warning('复制失败');
    },
    goCalendar() {
      this.$router.push({
        path: _routeMap.ROUTES.calendar
      });
    }
  }
};
exports.default = _default;