"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    month: {
      type: String,
      default: ''
    },
    dataType: {
      type: String,
      default: ''
    },
    xAxisList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.clearChart();
        this.$nextTick(() => {
          this.handleValue();
          this.render();
        });
      },
      deep: true
    }
  },
  data() {
    return {
      chart: null,
      chartValue: [],
      xAxisData: []
    };
  },
  computed: {},
  mounted() {
    window.addEventListener('resize', this.chartResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartResize);
  },
  methods: {
    chartResize() {
      this.chart && this.chart.resize();
    },
    clearChart() {
      this.chart && this.chart.dispose();
    },
    handleValue() {
      this.xAxisData = this.xAxisList;
      this.chartValue = this.value;
    }
  }
};
exports.default = _default;