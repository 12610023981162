"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _TaskCard = _interopRequireDefault(require("../../components/TaskCard.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MyTaskCardView',
  components: {
    TaskCard: _TaskCard.default
  },
  props: {
    cardList: {
      type: Array,
      default: () => {}
    }
  },
  methods: {
    goTaskDetail(id, sampling_type) {
      this.$router.push({
        path: _routeMap.ROUTES.complianceMyTaskDetail,
        query: {
          id: id,
          sampling_type
        }
      });
    }
  }
};
exports.default = _default;