"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "key-form"
  }, [_c("el-form", [_c("el-form-item", {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.privateKeyDesc.label)), _c("span", {
          staticClass: "dot"
        }, [_vm._v("*")]), _vm.isShowImageTips && _vm.privateKeyDesc.tips ? _c("image-text-tips", {
          attrs: {
            list: JSON.parse(_vm.privateKeyDesc.tips).tips
          }
        }) : _vm._e(), !_vm.isShowImageTips && _vm.privateKeyDesc.tips ? _c("el-tooltip", {
          attrs: {
            content: _vm.privateKeyDesc.tips,
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-info"
        })]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c("el-radio-group", {
    staticClass: "flex-row",
    model: {
      value: _vm.hasPrivateKey,
      callback: function ($$v) {
        _vm.hasPrivateKey = $$v;
      },
      expression: "hasPrivateKey"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true
    }
  }, [_vm._v(" 已有私钥 ")]), _c("el-radio", {
    attrs: {
      label: false
    }
  }, [_vm._v(" 没有私钥 ")])], 1)], 1), _vm.hasPrivateKey ? _c("section", {
    staticClass: "flex-row"
  }, [_c("span", [_vm._v("私钥")]), _c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入已有私钥",
      disabled: !_vm.hasPrivateKey,
      "auto-complete": "new-password"
    },
    model: {
      value: _vm.privateKey,
      callback: function ($$v) {
        _vm.privateKey = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "privateKey"
    }
  })], 1) : _c("section", {
    staticClass: "flex-row"
  }, [_c("span", [_vm._v("公钥")]), _c("el-input", {
    attrs: {
      placeholder: "点击右侧按钮生成公钥",
      disabled: "",
      "show-password": "",
      "auto-complete": "new-password"
    },
    model: {
      value: _vm.publicKey,
      callback: function ($$v) {
        _vm.publicKey = $$v;
      },
      expression: "publicKey"
    }
  }), !_vm.publicKey ? _c("el-button", {
    attrs: {
      plain: "",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.createPublicKey
    }
  }, [_vm._v(" 生成 ")]) : _c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.publicKey,
      expression: "publicKey",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    attrs: {
      plain: ""
    }
  }, [_vm._v(" 复制 ")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;