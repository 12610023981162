"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "answers-list-container"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "filters-group"
  }, [_c("mgv-member-multi-select", {
    attrs: {
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-params": {
        type: JSON.stringify(["trade"]),
        workspace_id: _vm.workspaceInfo.id
      },
      "is-prevent-not-selected": false
    },
    on: {
      change: _vm.memberChange
    }
  }), _c("create-date", {
    attrs: {
      item: _vm.createTimeOptions("会话时间", "begin_time")
    },
    on: {
      change: _vm.dateChange
    }
  })], 1), _c("TaskCenterExportButton", {
    attrs: {
      size: "small",
      "button-label": "导出回答"
    },
    on: {
      exportData: _vm.exportData
    }
  })], 1), _c("mgv-infinite-scroll", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.convList.length,
      expression: "convList.length"
    }],
    attrs: {
      loading: _vm.loading,
      "has-more": _vm.hasMore
    },
    on: {
      loadMore: _vm.getConvList
    }
  }, _vm._l(_vm.convList, function (item, index) {
    return _c("answer-item", {
      key: index,
      attrs: {
        "conv-item": item
      },
      on: {
        addConv: function ($event) {
          return _vm.addSingleQa(item);
        },
        moveFreqQuestion: function ($event) {
          return _vm.moveToFreqQuestion(item);
        }
      }
    });
  }), 1), !_vm.loading && !_vm.convList.length ? _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-data",
      tips: "暂无数据"
    }
  }) : _vm._e(), _c("create-knowledge-drawer", {
    ref: "drawer",
    attrs: {
      visible: _vm.drawerVisible,
      title: "添加到知识库",
      "is-hidden-type": "",
      "variable-knowledge-base": "",
      "extra-submit-params": {
        q_id: _vm.currentQuestion.id,
        qa_ids: _vm.qaIds
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  }), _c("MoveFreqQuestionDialog", {
    attrs: {
      "current-qa-item": _vm.currentQaItem,
      "current-question": _vm.currentQuestion,
      visible: _vm.moveFreqQuestionDialogVisible,
      "has-footer-operate": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveFreqQuestionDialogVisible = $event;
      },
      moveFreqQuestion: _vm.handleMoveFreqQuestion,
      refreshQuestionAndQa: _vm.refreshQuestionAndQa
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;