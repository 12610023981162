"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeTradeStage = changeTradeStage;
exports.changeViewType = changeViewType;
exports.createTrade = createTrade;
exports.createTradeView = createTradeView;
exports.deleteTradeView = deleteTradeView;
exports.getAccountTradeList = getAccountTradeList;
exports.getComment = getComment;
exports.getContactByTradeId = getContactByTradeId;
exports.getEventEngine = getEventEngine;
exports.getTradeAccountLabel = getTradeAccountLabel;
exports.getTradeAmount = getTradeAmount;
exports.getTradeDetail = getTradeDetail;
exports.getTradeFilterList = getTradeFilterList;
exports.getTradeForm = getTradeForm;
exports.getTradeList = getTradeList;
exports.getTradeListFollow = getTradeListFollow;
exports.getTradeSpeakTime = getTradeSpeakTime;
exports.getTradeStage = getTradeStage;
exports.getTradeStageConf = getTradeStageConf;
exports.getTradeStageList = getTradeStageList;
exports.getTradeTimeline = getTradeTimeline;
exports.getTradeViewList = getTradeViewList;
exports.getViewHeadList = getViewHeadList;
exports.getViewSortInfo = getViewSortInfo;
exports.moveViewPosition = moveViewPosition;
exports.postViewVisited = postViewVisited;
exports.setTradeStage = setTradeStage;
exports.updateTrade = updateTrade;
exports.updateTradeView = updateTradeView;
var _request = require("@/utils/request");
// 交易视图部分
// 交易视图列表
function getTradeViewList(params) {
  return (0, _request.webService)({
    url: '/trade/view/list',
    method: 'get',
    params
  });
}

// 交易视图创建
function createTradeView(data) {
  return (0, _request.webService)({
    url: '/trade/view/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易视图更改
function updateTradeView(data) {
  return (0, _request.webService)({
    url: '/trade/view/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改交易视图类型
function changeViewType(data) {
  return (0, _request.webService)({
    url: '/trade/view/change_type',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易视图移动
function moveViewPosition(data) {
  return (0, _request.webService)({
    url: '/trade/view/move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易视图删除
function deleteTradeView(data) {
  return (0, _request.webService)({
    url: '/trade/view/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易视图浏览上报
function postViewVisited(data) {
  return (0, _request.webService)({
    url: '/trade/view/action',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取交易金额和数量
function getTradeAmount(params) {
  return (0, _request.webService)({
    url: '/trade/view/list_stat',
    method: 'get',
    params
  });
}

// 获取视图列表排序配置
function getViewSortInfo(params) {
  return (0, _request.webService)({
    url: '/trade/view/sort_conf',
    method: 'get',
    params
  });
}

// 获取交易列表表头字段
function getViewHeadList(params) {
  return (0, _request.webService)({
    url: '/trade/trade/head',
    method: 'get',
    params
  });
}

// 交易阶段部分
// 获取交易阶段配置
function getTradeStageConf(params) {
  return (0, _request.webService)({
    url: '/trade/stage/conf',
    method: 'get',
    params
  });
}

// 获取已设置的交易阶段
function getTradeStage(params) {
  return (0, _request.webService)({
    url: '/trade/stage/get',
    method: 'get',
    params
  });
}

// 设置交易阶段
function setTradeStage(data) {
  return (0, _request.webService)({
    url: '/trade/stage/set',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易获取
// 交易列表
function getTradeList(data, config) {
  return (0, _request.webService)({
    url: '/trade/trade/list',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

// 交易列表筛选项
function getTradeFilterList(params) {
  return (0, _request.webService)({
    url: '/trade/trade/filter',
    method: 'get',
    params
  });
}

// 交易列表: 跟进动态
function getTradeListFollow(data) {
  return (0, _request.webService)({
    url: '/trade/trade/scatter',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易抽屉:关键事件、下一步、客户异议
function getEventEngine(data) {
  return (0, _request.webService)({
    url: '/trade/trade/event_engine',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易抽屉:交易评论
function getComment(params) {
  return (0, _request.webService)({
    url: '/trade/trade/comment',
    method: 'get',
    params
  });
}

// 交易抽屉:客户标签
function getTradeAccountLabel(data) {
  return (0, _request.webService)({
    url: '/trade/trade/account_label',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易抽屉: 查询交易跟进动态
function getTradeSpeakTime(params) {
  return (0, _request.webService)({
    url: '/trade/trade/follow',
    method: 'get',
    params
  });
}

// 修改交易阶段
function changeTradeStage(data) {
  return (0, _request.webService)({
    url: '/trade/trade/change_stage',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 交易详情页面接口
// 交易详情
function getTradeDetail(params) {
  return (0, _request.webService)({
    url: '/trade/trade/detail',
    method: 'get',
    params
  });
}

// 交易详情时间线
function getTradeTimeline(params) {
  return (0, _request.webService)({
    url: '/trade/detail/timeline',
    method: 'get',
    params
  });
}

// 获取交易联系人
function getContactByTradeId(params) {
  return (0, _request.webService)({
    url: '/trade/trade/contact',
    method: 'get',
    params
  });
}

// 获取当前交易阶段
function getTradeStageList(params) {
  return (0, _request.webService)({
    url: '/trade/stage/list',
    method: 'get',
    params
  });
}

// 客户详情页获取交易列表
function getAccountTradeList(params) {
  return (0, _request.webService)({
    url: '/account/detail/trade',
    method: 'get',
    params
  });
}

/**
 * 创建交易相关接口
 */
// 获取交易表单接口
function getTradeForm() {
  return (0, _request.webService)({
    url: '/trade/trade/form',
    method: 'get'
  });
}

// 创建交易
function createTrade(data) {
  return (0, _request.webService)({
    url: '/trade/trade/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新交易
function updateTrade(data) {
  return (0, _request.webService)({
    url: '/trade/trade/update',
    method: 'post',
    dataType: 'json',
    data
  });
}