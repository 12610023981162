"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CommentUnit = _interopRequireDefault(require("@/components/Detail/CommentsArea/CommentUnit"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _trade = require("@/api/trade");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentTabContent',
  components: {
    commentUnit: _CommentUnit.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  provide() {
    return {
      getActiveCommentId: () => null,
      commentType: 'conversation'
    };
  },
  props: {
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      commentsList: [],
      loading: false,
      commentParams: {
        trade_id: '',
        page: 1,
        size: 10,
        workspace_id: 0
      },
      hasNext: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    noMore() {
      return !this.hasNext;
    },
    noComment() {
      return this.commentsList.every(item => item.comments.length === 0) && this.commentsList.length !== 0;
    }
  },
  watch: {
    currentRow: {
      handler: async function (val) {
        if (val.trade_id) {
          this.commentsList = [];
          this.commentParams.page = 1;
          this.getCommentsList(val.trade_id);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    loadMore() {
      this.getCommentsList(this.currentRow.trade_id);
    },
    async getCommentsList(trade_id) {
      this.loading = true;
      if (!/^[0-9]*$/.test(trade_id)) return;
      this.commentParams.trade_id = trade_id;
      this.commentParams.workspace_id = this.workspaceInfo.id;
      const res = await (0, _trade.getComment)(this.commentParams);
      if (res.code === 20000) {
        this.loading = false;
        const {
          comments,
          has_next
        } = res.results;
        this.commentsList.push(...comments);
        this.hasNext = has_next;
        this.commentParams.page++;
      }
    },
    handleClose() {
      this.$emit('closeDrawer');
    },
    judgeCommentType(type) {
      switch (type) {
        case 'business':
          return '商机';
        case 'conversation':
          return '会话';
      }
    },
    goDetails(id, type) {
      switch (type) {
        case 'business':
          window.open(`${location.origin}${_routeMap.ROUTES.dealDetail}?id=${id}&noback=true`);
          break;
        case 'conversation':
          window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&noback=true`);
          break;
      }
    }
  }
};
exports.default = _default;