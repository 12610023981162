"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HitButtons = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitButtons.vue"));
var _ConversationLink = _interopRequireDefault(require("./ConversationLink.vue"));
var _routeMap = require("@/router/routeMap");
var _HitTagDropdown = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitTagDropdown.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'FixTrainDatasetTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    // 汇总数据
    headerSummaryData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: [Number, String],
      default: '100%'
    }
  },
  emits: ['updateAnnotationStatus', 'batchUpdateAnnotationStatus'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const fixTrainDataSetTableRef = (0, _vue.ref)(null);
    const clearSelection = () => {
      fixTrainDataSetTableRef.value.clearSelection();
    };
    expose({
      clearSelection
    });
    const updateAnnotationStatus = (status, row) => {
      emit('updateAnnotationStatus', [row], status);
    };
    const deleteAnnotation = row => {
      emit('deleteAnnotation', [row.id]);
    };
    const handleSelectionChange = val => {
      console.log(val);
      emit('tableSelectionChange', val);
    };
    const getAnnotationPath = row => {
      const basePath = _routeMap.ROUTES.annotate;
      const {
        dataset_id
      } = props.headerSummaryData;
      return `${basePath}?id=${dataset_id}&datasetDataId=${row.dataset_data_id}`;
    };
    return {
      __sfc: true,
      props,
      fixTrainDataSetTableRef,
      clearSelection,
      emit,
      updateAnnotationStatus,
      deleteAnnotation,
      handleSelectionChange,
      getAnnotationPath,
      HitButtons: _HitButtons.default,
      ConversationLink: _ConversationLink.default
    };
  }
};
exports.default = _default;