"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-set__table"
  }, [_c("el-table", {
    ref: "dataSetTableRef",
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight,
      "default-sort": {
        prop: "update_at",
        order: "descending"
      }
    },
    on: {
      "sort-change": _setup.handleSortChange
    }
  }, [_vm._l(_setup.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.prop,
      attrs: {
        label: item.label,
        prop: item.prop,
        "min-width": "140px",
        height: "100%",
        width: item.width,
        fixed: item.fixed,
        sortable: item.sortable,
        "sort-orders": ["ascending", "descending"],
        "show-overflow-tooltip": ""
      },
      scopedSlots: _vm._u([item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "data-set__name"
          }, [_c("div", {
            staticClass: "data-set__name-icon"
          }, [_c("i", {
            staticClass: "iconfont icon-dataset-fill"
          })]), _c("div", {
            staticClass: "data-set__name-text"
          }, [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.name
            }
          }, [_c("p", [_vm._v(_vm._s(row.name))])]), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: String(row.id)
            }
          }, [_c("p", [_vm._v("ID:" + _vm._s(row.id))])])], 1)])];
        }
      } : item.prop === "dataset_folder_name" ? {
        key: "default",
        fn: function (_ref2) {
          var _row$folder;
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "data-set__folder-name"
          }, [_c("i", {
            staticClass: "iconfont icon-folder-fill"
          }), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$folder = row.folder) === null || _row$folder === void 0 ? void 0 : _row$folder.name))])])];
        }
      } : item.prop === "import_status" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "data-set__import-status"
          }, [row.import_status !== 2 ? [_c("i", {
            class: ["iconfont", "icon-dot-4", {
              success: row.import_status === 3
            }, {
              fail: row.import_status === 4
            }]
          }), _c("span", [_vm._v(_vm._s(_setup.importStatusArr[row.import_status - 1]))])] : [_c("el-progress", {
            attrs: {
              percentage: row.import_progress,
              "stroke-width": 4,
              "show-text": false
            }
          }), _c("span", [_vm._v(_vm._s(row.import_progress) + "%")]), _c("el-button", {
            attrs: {
              type: "text"
            },
            on: {
              click: _setup.refreshCurrentPage
            }
          }, [_vm._v("刷新")])]], 2)];
        }
      } : item.prop === "annotation_type" ? {
        key: "default",
        fn: function (undefined) {
          return [_c("div", {
            staticClass: "data-set__annotation-type"
          }, [_vm._v("关键事件")])];
        }
      } : item.prop === "annotation_users" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("div", {
            staticClass: "data-set__annotation-users"
          }, [_c(_setup.CollapseAvatar, {
            attrs: {
              list: row.annotation_users
            }
          })], 1)];
        }
      } : item.prop === "labels" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("div", {
            staticClass: "results-container"
          }, [_vm._l(row.labels, function (result, index) {
            return [index < 5 ? _c("div", {
              key: result.id,
              staticClass: "annotation-result-item",
              on: {
                click: function ($event) {
                  return _setup.onClickLabels(row, result);
                }
              }
            }, [_c("span", [_vm._v(_vm._s(result.name))]), _c("div", {
              staticClass: "divider"
            }, [_vm._v("|")]), _c("span", [_c("span", {
              staticClass: "annotation-text-desc"
            }, [_vm._v("正")]), _vm._v(" " + _vm._s(result.positive_count))])]) : _vm._e(), row.labels.length > 5 && index >= 5 ? [_c("el-popover", {
              key: index,
              attrs: {
                placement: "bottom-start",
                width: "192",
                trigger: "hover",
                "visible-arrow": false,
                "popper-class": "annotation-result-pop"
              }
            }, [_c("div", {
              key: result.id,
              staticClass: "annotation-result-item"
            }, [_c("span", [_vm._v(_vm._s(result.name))]), _c("div", {
              staticClass: "divider"
            }, [_vm._v("|")]), _c("span", [_c("span", {
              staticClass: "annotation-text-desc"
            }, [_vm._v("正")]), _vm._v(" " + _vm._s(result.positive_count) + " ")])]), index === 5 ? _c("div", {
              key: result.id,
              staticClass: "annotation-result-more",
              attrs: {
                slot: "reference"
              },
              slot: "reference"
            }, [_vm._v(" +" + _vm._s(row.labels.length - 5) + " ")]) : _vm._e()])] : _vm._e()];
          })], 2)];
        }
      } : item.prop === "creator" ? {
        key: "default",
        fn: function (_ref6) {
          var _row$create_user, _row$create_user2;
          let {
            row
          } = _ref6;
          return [_c("MgvAvatarName", {
            attrs: {
              size: 24,
              src: (_row$create_user = row.create_user) === null || _row$create_user === void 0 ? void 0 : _row$create_user.avatar,
              name: (_row$create_user2 = row.create_user) === null || _row$create_user2 === void 0 ? void 0 : _row$create_user2.name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(row.create_user.name))])];
        }
      } : ["create_at", "update_at"].includes(item.prop) ? {
        key: "default",
        fn: function (_ref7) {
          let {
            row
          } = _ref7;
          return [_c("div", {
            staticClass: "data-set__annotation-times"
          }, [_vm._v(" " + _vm._s(_vm.$moment(row[item.prop]).format("YYYY-MM-DD HH:mm")) + " ")])];
        }
      } : item.prop === "annotation_status" ? {
        key: "default",
        fn: function (_ref8) {
          let {
            row
          } = _ref8;
          return [_c("div", {
            staticClass: "data-set__annotation-status"
          }, [row.annotation_status !== 2 ? [_c("div", {
            staticClass: "data-set__annotation-status-left"
          }, [_c("i", {
            class: ["iconfont", "icon-dot-4", {
              success: row.annotation_status === 3
            }]
          }), _c("span", [_vm._v(_vm._s(_setup.annotationStatusArr[row.annotation_status - 1]))])]), _c("span", [_vm._v(_vm._s(row.annotated_data_count) + " / " + _vm._s(row.total_data_count))])] : [_c("div", {
            staticClass: "data-set__annotation-status-left"
          }, [_c("el-progress", {
            attrs: {
              percentage: Math.round(row.annotated_data_count / row.total_data_count * 10000) / 100,
              "stroke-width": 4,
              "show-text": false
            }
          }), row.annotation_status !== 1 ? _c("span", [_vm._v(_vm._s(Math.round(row.annotated_data_count / row.total_data_count * 10000) / 100) + "%")]) : _vm._e()], 1), row.annotation_status !== 1 ? _c("span", [_vm._v(_vm._s(row.annotated_data_count) + " / " + _vm._s(row.total_data_count))]) : _vm._e()]], 2)];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "230px",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        let {
          row
        } = _ref9;
        return [_c(_setup.MgOperateColumn, {
          attrs: {
            list: _setup.operateList(row)
          },
          on: {
            command: function ($event) {
              return _setup.handleCommand($event, row);
            }
          }
        })];
      }
    }])
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;