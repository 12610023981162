"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-dialog",
    attrs: {
      visible: _vm.visible,
      title: _vm.modelId ? `训练 - ${_vm.modelName}` : "训练模型",
      size: "600px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [!_vm.modelId ? _c("div", {
    staticClass: "set-item model-content"
  }, [_c("h1", {
    staticClass: "set-title first-title"
  }, [_vm._v("模型")]), _c(_setup.ModelSelect, {
    attrs: {
      "is-only-ai": ""
    },
    model: {
      value: _setup.localModelId,
      callback: function ($$v) {
        _setup.localModelId = $$v;
      },
      expression: "localModelId"
    }
  })], 1) : _vm._e(), _c(_setup.ModelPredictionResult, {
    ref: "predictionResultRef",
    attrs: {
      "post-process-type": _setup.postProcessType,
      "is-enable-l-l-m": _setup.isEnableLLM,
      "prompt-content": _setup.promptContent
    },
    on: {
      "update:postProcessType": function ($event) {
        _setup.postProcessType = $event;
      },
      "update:post-process-type": function ($event) {
        _setup.postProcessType = $event;
      },
      isEnableLLMChange: _setup.isEnableLLMChange
    }
  }), _c(_setup.TrainAndTestDataSet, {
    ref: "trainAndTestDatasetRef",
    attrs: {
      "train-dataset-list": _setup.trainDatasetList,
      "trial-dataset-list": _setup.trialDatasetList,
      "dataset-split-type": _setup.datasetSplitType,
      "is-auto-test": _setup.isAutoTest
    },
    on: {
      "update:datasetSplitType": function ($event) {
        _setup.datasetSplitType = $event;
      },
      "update:dataset-split-type": function ($event) {
        _setup.datasetSplitType = $event;
      },
      "update:isAutoTest": function ($event) {
        _setup.isAutoTest = $event;
      },
      "update:is-auto-test": function ($event) {
        _setup.isAutoTest = $event;
      }
    }
  }), _c("div", {
    staticClass: "set-item"
  }, [_c("div", {
    staticClass: "set-switch-content"
  }, [_c("span", [_vm._v("超参数设置")])]), _c("div", {
    staticClass: "train-params-mode"
  }, [_c("el-tooltip", {
    attrs: {
      content: "请先开启训练完成后的自动测试",
      disabled: _setup.isAutoTest,
      placement: "top"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true,
      disabled: !_setup.isAutoTest
    },
    on: {
      input: _setup.onAutoTuningParams
    },
    model: {
      value: _setup.autoTuningParams,
      callback: function ($$v) {
        _setup.autoTuningParams = $$v;
      },
      expression: "autoTuningParams"
    }
  }, [_vm._v("自动调优参数")])], 1), _c("el-radio", {
    attrs: {
      label: false
    },
    on: {
      input: _setup.onAutoTuningParams
    },
    model: {
      value: _setup.autoTuningParams,
      callback: function ($$v) {
        _setup.autoTuningParams = $$v;
      },
      expression: "autoTuningParams"
    }
  }, [_vm._v("手动配置参数")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.autoTuningParams,
      expression: "!autoTuningParams"
    }],
    staticClass: "set-item-content"
  }, [_c(_setup.TrainSuperParamTable, {
    attrs: {
      "fill-value": _setup.trainParamsFillValue
    },
    on: {
      paramsChange: _setup.paramsChange
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.autoTuningParams,
      expression: "autoTuningParams"
    }],
    staticClass: "set-item-content"
  }, [_c("el-select", {
    model: {
      value: _setup.trainParamMode,
      callback: function ($$v) {
        _setup.trainParamMode = $$v;
      },
      expression: "trainParamMode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "兼顾精确率和召回率",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "高精确率优先",
      value: 2
    }
  }), _c("el-option", {
    attrs: {
      label: "高召回率优先",
      value: 3
    }
  })], 1)], 1)])], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.btnLoading
    },
    on: {
      click: _setup.createTrainTaskAsync
    }
  }, [_vm._v("开始训练")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;