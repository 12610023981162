"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trigger-rules-form"
  }, [_c("div", {
    staticClass: "form-header"
  }, [_c("div", {
    staticClass: "form-title"
  }, [_vm._v("触发规则")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "form-header-tips"
  }, [_vm._m(0), _vm._m(1)])]), _c("div", {
    staticClass: "rule-group"
  }, [_vm._m(2), _c("div", {
    staticClass: "rule-group-content"
  }, [_vm._l(_vm.form.tracker_groups, function (item, index) {
    return _c("RuleGroupItem", {
      key: index,
      attrs: {
        "rule-group-item": item,
        "rule-group-index": index,
        "rule-group": _vm.form.tracker_groups,
        "rule-count": _vm.ruleCount
      },
      on: {
        handleDeleteRuleGroupItem: _vm.handleDeleteRuleGroupItem
      }
    });
  }), _vm.ruleCount < 50 ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "add-rule-group-btn",
    attrs: {
      icon: "iconfont icon-plus"
    },
    on: {
      click: _vm.handleAddRuleGroup
    }
  }, [_vm._v("规则组")]) : _vm._e()], 2)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    attrs: {
      href: "https://regexlearn.com/zh-cn/learn",
      target: "_blank"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-notebook-"
  }), _c("span", [_vm._v("正则表达式入门")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    attrs: {
      href: "https://regex101.com/",
      target: "_blank"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-play-line"
  }), _c("span", [_vm._v("正则表达式快速测试")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-group-aside"
  }, [_c("div", {
    staticClass: "rule-group-aside-text"
  }, [_vm._v("或")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;