"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conv-table"
  }, [_c("el-table", {
    ref: "table",
    attrs: {
      data: _vm.list,
      "element-loading-text": "加载中",
      fit: "",
      border: "",
      "row-class-name": _vm.rowStyle,
      height: _vm.tableHeight,
      "default-sort": {
        prop: "begin_time",
        order: "descending"
      }
    },
    on: {
      "row-click": _vm.goDetails,
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "会话名称",
      width: "250px",
      prop: "name",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("i", {
          class: ["iconfont", "medium-icon", _vm.getMediumIcon(row.conversation_type)]
        }), _c("span", {
          staticClass: "contact-name-ellipsis"
        }, [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "归属人",
      prop: "host_id",
      width: "100px",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [row.host.avatar || row.host.name ? _c("mgv-avatar-name", {
          attrs: {
            src: row.host.avatar,
            name: row.host.name
          }
        }) : _vm._e(), _c("span", {
          staticClass: "multi-row"
        }, [_c("el-tooltip", {
          ref: "toolDeal",
          staticClass: "itemName",
          attrs: {
            effect: "dark",
            content: row.host.name,
            placement: "top-start",
            disabled: row.nameWidth <= row.fatherNameWidth,
            "visible-arrow": true
          }
        }, [_c("span", [_vm._v(_vm._s(row.host.name || "-"))])])], 1), row.others && row.others.length > 0 ? _c("el-popover", {
          attrs: {
            placement: "top-start",
            trigger: "hover",
            "open-delay": _vm.openDelay,
            width: "180"
          }
        }, [_c("div", {
          staticClass: "others-inte"
        }, _vm._l(row.others, function (item, index) {
          return _c("div", {
            key: index,
            staticClass: "inte-name"
          }, [item.avatar || item.name ? _c("mgv-avatar-name", {
            attrs: {
              name: item.name,
              avatar: item.avatar
            }
          }) : _vm._e(), _c("span", {
            staticClass: "multi-row"
          }, [_c("span", [_vm._v(_vm._s(item.name || "-"))])])], 1);
        }), 0), _c("span", {
          staticClass: "others-tip",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_vm._v("+" + _vm._s(row.others.length))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "归属部门",
      width: "150px",
      prop: "organization_tree",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _row$organization_tre;
        let {
          row
        } = _ref3;
        return [_c("span", {
          staticClass: "contact-name-ellipsis"
        }, [_vm._v(_vm._s(((_row$organization_tre = row.organization_tree) !== null && _row$organization_tre !== void 0 ? _row$organization_tre : {}).name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "客户",
      prop: "company_name",
      width: "100px",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("span", {
          staticClass: "contact-name-ellipsis"
        }, [_vm._v(_vm._s(row.company_name || "-"))])];
      }
    }])
  }), _vm.modulePermission.trade ? _c("el-table-column", {
    attrs: {
      label: "交易",
      prop: "deal_name",
      width: "100px",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_c("span", {
          staticClass: "contact-name-ellipsis"
        }, [_vm._v(_vm._s(row.deal_name || "-"))])];
      }
    }], null, false, 1766938569)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "关键事件",
      prop: "event_engine_count",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("span", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.goKeyevent(row);
            }
          }
        }, [_vm._v(_vm._s(row.event_engine_count))])];
      }
    }])
  }), _vm.getHasExtractInfo() ? _c("el-table-column", {
    attrs: {
      label: "信息提取",
      prop: "instruction_set_result",
      width: "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var _row$instruction_set_, _row$instruction_set_2, _row$instruction_set_3;
        let {
          row
        } = _ref7;
        return [_c("el-popover", {
          attrs: {
            "popper-class": "extract-info-popover-container",
            placement: "bottom-start",
            width: "350",
            trigger: "hover",
            "open-delay": 500,
            disabled: !((_row$instruction_set_ = row.instruction_set_result) !== null && _row$instruction_set_ !== void 0 && _row$instruction_set_.length)
          }
        }, [_c("div", {
          staticClass: "extract-info-results"
        }, _vm._l(row.instruction_set_result, function (str, index) {
          return _c("p", {
            key: index
          }, [_vm._v(" " + _vm._s(str) + " ")]);
        }), 0), _c("div", {
          staticClass: "extract-info-review",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [(_row$instruction_set_2 = row.instruction_set_result) !== null && _row$instruction_set_2 !== void 0 && _row$instruction_set_2.length ? _c("span", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(" " + _vm._s((_row$instruction_set_3 = row.instruction_set_result) === null || _row$instruction_set_3 === void 0 ? void 0 : _row$instruction_set_3.join(";")) + " ")]) : _c("span", [_vm._v("-")])])])];
      }
    }], null, false, 3698649866)
  }) : _vm._e(), _vm.getHasSummary() ? _c("el-table-column", {
    attrs: {
      label: "智能纪要",
      prop: "summary_result",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var _row$summary_result, _row$summary_result2, _row$summary_result2$, _row$summary_result3, _row$summary_result4, _row$summary_result4$;
        let {
          row
        } = _ref8;
        return [_c("el-popover", {
          attrs: {
            "popper-class": "ai-summary-popover-container",
            placement: "bottom-start",
            width: "350",
            trigger: "hover",
            content: (_row$summary_result = row.summary_result) === null || _row$summary_result === void 0 ? void 0 : _row$summary_result.result,
            "open-delay": 500,
            disabled: !((_row$summary_result2 = row.summary_result) !== null && _row$summary_result2 !== void 0 && (_row$summary_result2$ = _row$summary_result2.result) !== null && _row$summary_result2$ !== void 0 && _row$summary_result2$.length)
          }
        }, [_c("div", {
          staticClass: "words-counts",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_vm._v(" " + _vm._s((_row$summary_result3 = row.summary_result) !== null && _row$summary_result3 !== void 0 && _row$summary_result3.result ? `${(_row$summary_result4 = row.summary_result) === null || _row$summary_result4 === void 0 ? void 0 : (_row$summary_result4$ = _row$summary_result4.result) === null || _row$summary_result4$ === void 0 ? void 0 : _row$summary_result4$.length}字` : "-") + " ")])])];
      }
    }], null, false, 621783662)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "会话时间",
      prop: "begin_time",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        let {
          row
        } = _ref9;
        return [_c("div", {
          staticClass: "last-colum"
        }, [_c("span", {
          staticClass: "last-cont"
        }, [_vm._v(_vm._s(_vm.getDate(row.date)))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "会话时长",
      prop: "duration",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        let {
          row
        } = _ref10;
        return [_vm._v(_vm._s(_vm.secondToTime(row.duration) || "-"))];
      }
    }])
  }), _vm.modulePermission.trade ? _c("el-table-column", {
    attrs: {
      label: "交易阶段",
      prop: "entity_status",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        let {
          row
        } = _ref11;
        return [_vm._v(_vm._s(_vm.getEntityStatus(row)))];
      }
    }], null, false, 1664034825)
  }) : _vm._e(), _vm._l(_vm.conversationCustomFilterHead, function (_ref12) {
    let {
      field_name,
      name,
      field_type,
      precisions,
      is_thousand_separate
    } = _ref12;
    return _c("el-table-column", {
      key: field_name,
      attrs: {
        label: name,
        prop: field_name,
        resizable: "",
        width: "120px",
        "show-overflow-tooltip": ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref13) {
          let {
            row
          } = _ref13;
          return [field_name === "conversation_id" ? [_vm._v(" " + _vm._s(row[field_name]) + " ")] : ["radio", "select"].includes(field_type) ? [row[field_name] && row[field_name].length ? _c("div", {
            staticClass: "radio-select"
          }, [_vm._v(" " + _vm._s(row[field_name].toString()) + " ")]) : _c("div", [_vm._v("-")])] : field_type === "checkbox" ? [row[field_name] && row[field_name].length ? _c("el-tooltip", {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: row[field_name].join("，"),
              placement: "top",
              "open-delay": 200
            }
          }, [_c("div", {
            staticClass: "multi-select"
          }, _vm._l(row[field_name], function (columnItem, itemIndex) {
            return _c("span", {
              key: itemIndex,
              staticClass: "multi-select-item"
            }, [_vm._v(" " + _vm._s(columnItem) + " ")]);
          }), 0)]) : _c("div", [_vm._v("-")])] : field_type === "date" ? [_vm._v(" " + _vm._s(_vm.formatDate(row[field_name])) + " ")] : field_type === "datetime" ? [_vm._v(" " + _vm._s(_vm.formatDateTime(row[field_name])) + " ")] : field_type === "file" ? [_vm._v(" " + _vm._s(row[field_name][0].split("/").pop()) + " ")] : field_type === "money" ? [_vm._v(" " + _vm._s(_vm.formatMoney({
            data: row[field_name],
            precisions,
            is_thousand_separate
          })) + " ")] : field_type === "number" ? [_vm._v(" " + _vm._s(_vm.formatNumber({
            data: row[field_name],
            precisions
          })) + " ")] : [_vm._v(" " + _vm._s(row[field_name]) + " ")]];
        }
      }], null, true)
    });
  }), !_vm.isInQualityTesting ? _vm._l(_vm.conversationScoreHead, function (_ref14) {
    let {
      field_name,
      name
    } = _ref14;
    return _c("el-table-column", {
      key: field_name,
      attrs: {
        label: name,
        prop: field_name,
        sortable: "custom",
        "sort-orders": ["descending", "ascending"],
        width: "200px"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref15) {
          let {
            row
          } = _ref15;
          return [_vm._v(_vm._s(row[field_name] && row[field_name].item.length ? row[field_name].score + "分" : "无评分"))];
        }
      }], null, true)
    });
  }) : _vm._e(), !_vm.isInQualityTesting ? _vm._l(_vm.complianceScoreHead, function (_ref16) {
    let {
      field_name,
      name
    } = _ref16;
    return _c("el-table-column", {
      key: field_name,
      attrs: {
        label: name,
        prop: field_name,
        sortable: "custom",
        "sort-orders": ["descending", "ascending"],
        width: "200px"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref17) {
          let {
            row
          } = _ref17;
          return [_vm._v(_vm._s(row[field_name] && row[field_name].item.length ? row[field_name].score + "分" : "无评分"))];
        }
      }], null, true)
    });
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "销售发言条数",
      prop: "sale_sentence_count",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(_vm.getConversationValue(scope.row.sale_sentence_count)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "客户发言条数",
      prop: "customer_sentence_count",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(_vm.getConversationValue(scope.row.customer_sentence_count)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "总发言条数",
      prop: "sentence_count",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(_vm.getConversationValue(scope.row.sentence_count)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "最长无人发言时长",
      prop: "max_silence_duration",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "160px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(scope.row.max_silence_duration !== "" ? Math.round(scope.row.max_silence_duration) + "秒" : "-"))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "会话挂断方",
      prop: "hang_up",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        let {
          row
        } = _ref18;
        return [_c("span", {
          staticClass: "hang-up-tag",
          class: _vm.getClassName(row.hang_up)
        }, [_vm._v(_vm._s(_vm.getHangUpPerson(row.hang_up)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "销售说话占比",
      prop: "percent_speak",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        let {
          row
        } = _ref19;
        return [_vm._v(_vm._s(row.percent_speak !== "" ? row.percent_speak.toFixed(2) + "%" : "-"))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "最长销售独白",
      prop: "max_sales",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref20) {
        let {
          row
        } = _ref20;
        return [_vm._v(_vm._s(row.max_sales !== "" ? row.max_sales.toFixed(2) + "秒" : "-"))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "最长客户叙述",
      prop: "max_customer",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref21) {
        let {
          row
        } = _ref21;
        return [_vm._v(_vm._s(row.max_customer !== "" ? row.max_customer.toFixed(2) + "秒" : "-"))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "深入讨论次数",
      prop: "discuss",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref22) {
        let {
          row
        } = _ref22;
        return [_vm._v(_vm._s(_vm.getConversationValue(row.discuss)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "销售提问次数",
      prop: "question",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref23) {
        let {
          row
        } = _ref23;
        return [_vm._v(_vm._s(_vm.getConversationValue(row.question)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "客户提问次数",
      prop: "customer_question",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref24) {
        let {
          row
        } = _ref24;
        return [_vm._v(_vm._s(_vm.getConversationValue(row.customer_question)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "销售语速",
      prop: "sales_speed",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref25) {
        let {
          row
        } = _ref25;
        return [_vm._v(" " + _vm._s(row.sales_speed ? `${row.sales_speed.toFixed(2)}字/分钟` : "-") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "客户语速",
      prop: "customer_speed",
      sortable: "custom",
      "sort-orders": ["descending", "ascending"],
      width: "130px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref26) {
        let {
          row
        } = _ref26;
        return [_vm._v(" " + _vm._s(row.customer_speed ? `${row.customer_speed.toFixed(2)}字/分钟` : "-") + " ")];
      }
    }])
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;