"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coaching-task-team-view"
  }, [_c("task-table-filter", {
    attrs: {
      type: "teamView"
    },
    on: {
      filterChange: _vm.filterChange
    }
  }), _c("team-view-table", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.count || _vm.tableLoading,
      expression: "count || tableLoading"
    }],
    attrs: {
      "table-data": _vm.tableData,
      "table-loading": _vm.tableLoading
    },
    on: {
      delete: _vm.handleDelete,
      editTask: function ($event) {
        return _vm.$emit("editTask", $event);
      },
      copyTask: function ($event) {
        return _vm.$emit("copyTask", $event);
      },
      sortChange: _vm.tableSortChange
    }
  }), _c("NoData", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.count && !_vm.tableLoading,
      expression: "!count && !tableLoading"
    }],
    staticClass: "no-data",
    attrs: {
      icon: "icon-a-Property1no-todo",
      tips: _vm.$t("coaching.noTrainingTasksYet")
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.count,
      expression: "count"
    }],
    staticClass: "pagination-container"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.params.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.params.size,
      total: _vm.count
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.pageChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;