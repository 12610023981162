"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTableOperate = useTableOperate;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
function useTableOperate(getresultsAsync, params) {
  const store = (0, _useVuex.useStore)();
  const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
  const tableInfo = (0, _vue.ref)({});
  const loading = (0, _vue.ref)(false);
  const getTableList = async () => {
    loading.value = true;
    const res = await getresultsAsync({
      workspace_id: workspaceId.value,
      ...params.value
    }).finally(() => {
      loading.value = false;
    });
    if (res.code === 20000) {
      tableInfo.value = res.results;
      console.log(tableInfo.value, 'tableInfo.value');
    }
  };
  const sizeChange = val => {
    params.value.page = 1;
    params.value.size = val;
    getTableList();
  };
  const pageChange = val => {
    params.value.page = val;
    getTableList();
  };
  const memberChange = (ids, item, aaa) => {
    console.log(ids, item, 'ids,item,');
    params.value.page = 1;
    params.value.user_ids = ids;
    getTableList();
  };
  (0, _vue.watch)(workspaceId, async val => {
    if (!val) return;
    params.value.page = 1;
    getTableList();
  });
  return {
    sizeChange,
    pageChange,
    memberChange,
    getTableList,
    tableInfo,
    loading,
    workspaceId
  };
}