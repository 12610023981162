"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      size: "600px",
      title: "知识点详情",
      visible: _vm.visible,
      "append-to-body": ""
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "content-container"
  }, [_setup.knowledge ? _c(_setup.KnowledgeItem, {
    staticClass: "single-knowledge-item",
    attrs: {
      disabled: "",
      knowledge: _setup.knowledge
    }
  }) : _c(_setup.NoData, {
    attrs: {
      icon: "icon-a-Property1no-permission",
      tips: "该知识库无访问权限"
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;