"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "rapid-model-info"
  }, [_c("div", {
    staticClass: "rapid-model-info__title"
  }, [_vm._v("模型信息")]), _c("div", {
    staticClass: "rapid-model-info__content"
  }, [_c("el-form", {
    ref: "rapidModelFormRef",
    attrs: {
      model: _setup.modelInfoForm,
      rules: _setup.rules,
      "label-width": "100px",
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "模型名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "show-word-limit": "",
      placeholder: "请输入模型名称",
      maxlength: "20"
    },
    model: {
      value: _setup.modelInfoForm.name,
      callback: function ($$v) {
        _vm.$set(_setup.modelInfoForm, "name", $$v);
      },
      expression: "modelInfoForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "模型描述",
      prop: "desc"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入模型描述",
      maxlength: "200",
      "show-word-limit": "",
      rows: 5
    },
    model: {
      value: _setup.modelInfoForm.description,
      callback: function ($$v) {
        _vm.$set(_setup.modelInfoForm, "description", $$v);
      },
      expression: "modelInfoForm.description"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "模型类型",
      prop: "type"
    }
  }, [_c("div", {
    staticClass: "model-types"
  }, _vm._l(_setup.modelTypesArr, function (modelType) {
    return _c("div", {
      key: modelType.id,
      staticClass: "model-type"
    }, [_vm._v(" " + _vm._s(modelType.name) + " ")]);
  }), 0)]), _c("el-form-item", {
    attrs: {
      label: "模型用途",
      prop: "use"
    }
  }, [_c("div", {
    staticClass: "model-uses"
  }, _vm._l(_setup.modelUsesArr, function (modelType) {
    return _c("div", {
      key: modelType.id,
      staticClass: "model-use"
    }, [_vm._v(" " + _vm._s(modelType.name) + " ")]);
  }), 0)])], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;