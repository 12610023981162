"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversation-card"
  }, [_vm.accountConversation && _vm.accountConversation.length > 0 ? _c("mg-infinite-scroll", {
    staticClass: "content",
    attrs: {
      disabled: _vm.disabled,
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.load
    }
  }, [_c("el-timeline", _vm._l(_vm.accountConversation, function (item, index) {
    return _c("el-timeline-item", {
      key: index,
      attrs: {
        placement: "top",
        icon: _vm.getIconType(item.type),
        timestamp: _vm.getTimeString(item.datetime)
      }
    }, [_c("conversation-card", {
      attrs: {
        "is-silent": !!_vm.demoData,
        item: item
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _c("figure", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.accountConversation.length,
      expression: "!accountConversation.length"
    }],
    staticClass: "empty-conversation"
  }, [_vm._m(0), _c("p", [_vm._v("暂无跟进记录")])])], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("i", {
    staticClass: "iconfont icon-receiving-fill"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;