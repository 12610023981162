"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _vue = require("vue");
var _elementUi = require("element-ui");
var _default = {
  __name: 'CreateFolderDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'createDataSetFolderSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const rules = {
      name: [{
        required: true,
        message: '请输入数据集名称',
        trigger: 'blur'
      }]
    };
    const form = (0, _vue.ref)({
      name: ''
    });
    const createFolderFormRef = (0, _vue.ref)(null);
    const handleClose = () => {
      emit('update:visible', false);
    };
    const loading = (0, _vue.ref)(false);
    const handleSubmit = async () => {
      const valid = await createFolderFormRef.value.validate();
      if (!valid) return;
      loading.value = true;
      const res = await (0, _dataSet.createDataSetFolder)(form.value).finally(() => loading.value = false);
      if (res.code === 20000) {
        _elementUi.Message.success('创建成功');
        emit('createDataSetFolderSuccess', {
          id: res.results.dataset_folder_id,
          name: form.value.name
        });
        handleClose();
      }
    };
    return {
      __sfc: true,
      props,
      rules,
      form,
      createFolderFormRef,
      emit,
      handleClose,
      loading,
      handleSubmit
    };
  }
};
exports.default = _default;