"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-wrapper"
  }, [_c("div", {
    staticClass: "detail-top"
  }, [_c("detail-info", {
    attrs: {
      info: _vm.info
    }
  }), _c("div", {
    staticClass: "operation-buttons-wrapper"
  }, [_vm._t("operation-slot"), _c("detail-operation", {
    attrs: {
      "operate-button": _vm.operateButton
    },
    on: {
      detailOperations: _vm.detailOperations
    }
  })], 2)], 1), _c("div", {
    staticClass: "mid-line"
  }), _c("div", {
    staticClass: "detail-tab"
  }, [_vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;