"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ShowCustomFilter',
  props: {
    customFilterArray: {
      type: Array,
      default: () => []
    },
    customFilters: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // 获取保存到筛选器中商机自定义筛选器的信息
    getCustomFiltersInformation() {
      const customFiltersInformation = {};
      for (const key in this.customFilters) {
        this.customFilterArray.find(item => {
          if (item.field_name === key) {
            if (Array.isArray(this.customFilters[key])) {
              switch (item.type) {
                // 关于时间和选择范围的得做处理
                case 'date':
                  customFiltersInformation[item.name] = this.getCustomFilterDateRange(this.customFilters[key]);
                  break;
                case 'integer':
                case 'float':
                  // 关于有选择范围的筛选器处理
                  customFiltersInformation[item.name] = this.getCustomFilterAmountRange(this.customFilters[key]);
                  break;
                case 'checkbox':
                  customFiltersInformation[item.name] = this.checkboxValuesToLabels({
                    field_name: item.field_name,
                    values: this.customFilters[key]
                  });
                  break;
                default:
                  customFiltersInformation[item.name] = this.arrayToString(this.customFilters[key]);
                  break;
              }
            } else {
              customFiltersInformation[item.name] = this.customFilters[key];
            }
          }
        });
      }
      return customFiltersInformation;
    }
  },
  methods: {
    // 通过 field_name 找到对应的 customFilterArray 中 type 为  checkbox 的 label
    checkboxValuesToLabels(_ref) {
      var _this$customFilterArr;
      let {
        field_name,
        values
      } = _ref;
      const options = (_this$customFilterArr = this.customFilterArray.find(item => item.field_name === field_name)) === null || _this$customFilterArr === void 0 ? void 0 : _this$customFilterArr.options;
      const res = [];
      options.map(option => {
        values.map(value => {
          if (option.value === value) {
            res.push(option.label);
          }
        });
      });
      return this.arrayToString(res);
    },
    // 商机自定义筛选器 范围数据 的特殊处理
    getCustomFilterAmountRange(value) {
      if (!value) return;
      if (value[0] === -1) {
        return '小于等于 ' + value[1];
      } else if (value[1] === -1) {
        return '大于等于 ' + value[0];
      } else if (value[0] === value[1]) {
        return value[0];
      } else {
        return value[0] + ' - ' + value[1];
      }
    },
    // 商机自定义筛选器 日期数据 的特殊处理
    getCustomFilterDateRange(value) {
      return value && value.join(' 至 ');
    },
    arrayToString(value) {
      // 兼容旧数据：当拓展筛选项后，保存的旧的筛选项会缺少新添加的筛选项字段而报错
      return value && value.map(item => item.name || item.label || item).join('；');
    }
  }
};
exports.default = _default;