"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
var _num_formatter = require("@/utils/num_formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String,
    maxlength: Number,
    is_money: Number,
    precisions: [String, Number],
    is_thousand_separate: Number
  },
  computed: {
    localMaxlength() {
      if (this.maxlength) {
        return this.maxlength;
      } else {
        switch (this.type) {
          case 'tel':
            return 11;
          case 'floatnumber':
          case 'number':
            return this.handleFloatNumberLength();
          default:
            return 500;
        }
      }
    },
    formatNewValue() {
      if (this.type === 'floatnumber') {
        if (this.is_money) {
          return `￥${(0, _num_formatter.parseFormatNum)(this.newValue, this.precisions, !this.is_thousand_separate)}`;
        } else {
          return (0, _num_formatter.parseFormatNum)(this.newValue, this.precisions, true);
        }
      }
      return this.newValue;
    }
  },
  methods: {
    handleChange(val) {
      if (this.type === 'floatnumber' || this.type === 'number') {
        val = val.replace(/[^\d\.]/g, '');
        this.newValue = val;
      }
      this.$emit('input', val.trim());
    },
    handleFloatNumberLength() {
      if (this.newValue && this.newValue.includes('.')) {
        return 15;
      }
      return 14;
    },
    handleKeypress() {
      this.$refs.input.blur();
    }
  }
};
exports.default = _default;