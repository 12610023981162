"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OptionTemplate = _interopRequireDefault(require("@/views/convDetail/components/OptionTemplate.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _commonFunc = require("@/utils/commonFunc");
var _store = _interopRequireDefault(require("@/store"));
var _videoDetail = require("@/api/videoDetail");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeSearchContent',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default,
    OptionTemplate: _OptionTemplate.default,
    NoData: _NoData.default
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      scroll_id: '',
      noMore: false,
      knowledgeOptions: [],
      loading: false
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo'])
  },
  watch: {
    searchValue: {
      handler(val) {
        if (!val) return;
        this.loading = true;
        this.filterChange(val);
      },
      immediate: true
    },
    'knowledgeBaseInfo.id': {
      handler: function (val) {
        val && this.getSearchKnowledges();
      }
    }
  },
  methods: {
    async handleCheckKnowledge(item, checked) {
      if (!checked) {
        // 获取当前知识点的话术
        this.getKnowledgeInfo(item.id);
      } else {
        _store.default.commit('training/SET_KNOWLEDGE_SCRIPT_DELETE', item.id);
      }
    },
    async getKnowledgeInfo(id) {
      const res = await (0, _knowledgeLibrary.getKnowledgeInfo)({
        id
      });
      if (res.code === 20000) {
        const {
          id: knowledge_id,
          catalog_id,
          scene_reply_list,
          name: knowledge_name
        } = res.results.data;
        _store.default.commit('training/SET_KNOWLEDGE_SCRIPT_ADD', {
          knowledge_id,
          knowledge_name,
          catalog_id,
          scene_reply_list
        });
        this.$emit('scrollToScriptEnd');
      }
    },
    filterChange: (0, _commonFunc.debounce)(async function () {
      this.scroll_id = '';
      this.knowledgeOptions = [];
      this.getSearchKnowledges();
    }),
    async getSearchKnowledges() {
      this.loading = true;
      const res = await (0, _videoDetail.searchKnowledges)({
        name: this.searchValue,
        base_id: this.knowledgeBaseInfo.id,
        // 分页的后端标识
        scroll_id: this.scroll_id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          knowledge_list,
          scroll_id
        } = res.results;
        this.noMore = knowledge_list.length < 20;
        this.scroll_id = scroll_id;
        this.knowledgeOptions.push(...knowledge_list);
      }
    }
  }
};
exports.default = _default;