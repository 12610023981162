"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title note-template"
  }, [!_vm.noteEmpty ? _c("el-popover", {
    attrs: {
      "popper-class": "note-template-save-popover",
      placement: "bottom",
      trigger: "click"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", [_c("el-input", {
    attrs: {
      placeholder: "请输入模板名称"
    },
    model: {
      value: _vm.templateName,
      callback: function ($$v) {
        _vm.templateName = $$v;
      },
      expression: "templateName"
    }
  })], 1), _c("div", {
    staticClass: "popover-btn"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "text"
    },
    on: {
      click: function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addNoteTemplate
    }
  }, [_vm._v("保存")])], 1), _c("el-button", {
    staticClass: "template-button",
    attrs: {
      slot: "reference",
      type: "text"
    },
    slot: "reference"
  }, [_vm._v("保存为新模板")])], 1) : _c("el-button", {
    staticClass: "template-button",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.insertTemplate
    }
  }, [_vm._v("插入模板")]), _c("template-dialog", {
    ref: "template-dialog",
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      closeDialog: _vm.handleCloseDialog,
      showNote: _vm.handleShowNote
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;