"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "call-note"
  }, [_c("div", {
    staticClass: "cand-info"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "close-btn",
    on: {
      click: _vm.closeNote
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-down"
  })])]), _c("el-divider", {
    staticClass: "divider"
  })], 1), _c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" 私人笔记 "), _c("save-time-tip", {
    attrs: {
      "save-time": _vm.noteSaveTime
    }
  })], 1), _c("div", {
    staticClass: "note-content-wrapper"
  }, [_c("note-editor", {
    attrs: {
      "conversation-id": _vm.conversation_id
    },
    on: {
      saveSucceed: _vm.handleNoteSaved
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;