"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$fileList, _setup$fileList2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-media"
  }, [_c("el-upload", {
    ref: "uploadRef",
    attrs: {
      action: "",
      multiple: "",
      drag: "",
      accept: _setup.ACCEPT_FILE_TYPE,
      "file-list": _setup.fileList,
      "show-file-list": false,
      "on-change": _setup.onChange,
      "auto-upload": false
    }
  }, [_c("div", {
    staticClass: "upload-center-container description",
    class: {
      "has-file-list": (_setup$fileList = _setup.fileList) === null || _setup$fileList === void 0 ? void 0 : _setup$fileList.length
    }
  }, [_c("i", {
    staticClass: "iconfont icon-document-add-fill"
  }), _c("div", {
    staticClass: "drag-text"
  }, [_vm._v("将文件拖到此处，或"), _c("span", [_vm._v("选择文件")])])]), (_setup$fileList2 = _setup.fileList) !== null && _setup$fileList2 !== void 0 && _setup$fileList2.length ? _c("div", {
    staticClass: "upload-center-container file-list-container",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c(_setup.UploadFileList, {
    attrs: {
      "file-list": _setup.fileList
    },
    on: {
      addFile: _setup.addFile,
      deleteFile: _setup.deleteFile,
      canUpload: _setup.canUpload
    }
  })], 1) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;