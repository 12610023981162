"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var echarts = _interopRequireWildcard(require("echarts/core"));
var _components = require("echarts/components");
var _charts = require("echarts/charts");
var _renderers = require("echarts/renderers");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
echarts.use([_components.TitleComponent, _components.TooltipComponent, _components.GridComponent, _components.DatasetComponent, _components.TransformComponent, _components.MarkPointComponent, _charts.BoxplotChart, _charts.ScatterChart, _renderers.CanvasRenderer]);
var _default = {
  name: 'BoxplotChart',
  props: {
    width: {
      type: String,
      default: '1200px'
    },
    height: {
      type: String,
      default: '580px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartOption: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'canvas'
    }
  },
  data() {
    return {
      chart: null,
      eventName: ''
    };
  },
  watch: {
    chartOption: {
      deep: true,
      handler(newVal) {
        this.setOptions(newVal);
      }
    },
    biFiltersObj: {
      handler: function (filterObj) {
        this.eventName = sessionStorage.getItem('bi-boxplot-event-label');
      },
      deep: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj'])
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      window.addEventListener('resize', this.resizeHandler);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.resizeHandler);
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs['boxplot-chart'], '', {
        renderer: this.type
      });
      this.chart.setOption(this.chartOption);
    },
    resizeHandler() {
      this.chart.resize();
    },
    setOptions(option) {
      this.clearChart();
      this.resizeHandler();
      if (this.chart) {
        this.chart.setOption(option);
      }
    },
    refresh() {
      this.setOptions(this.chartOption);
    },
    clearChart() {
      this.chart && this.chart.clear();
    }
  }
};
exports.default = _default;