"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyTrial = applyTrial;
exports.checkEmail = checkEmail;
exports.checkEmailCaptcha = checkEmailCaptcha;
exports.checkIsSetPassword = checkIsSetPassword;
exports.checkOldTel = checkOldTel;
exports.checkTel = checkTel;
exports.confirmChatAgreement = confirmChatAgreement;
exports.createTeam = createTeam;
exports.equalInsuranceLogin = equalInsuranceLogin;
exports.firstStepLogin = firstStepLogin;
exports.getCheckCreated = getCheckCreated;
exports.getDataOrgMemberTree = getDataOrgMemberTree;
exports.getDepartmentAndMemberTree = getDepartmentAndMemberTree;
exports.getDetailInfo = getDetailInfo;
exports.getInfo = getInfo;
exports.getLanguageList = getLanguageList;
exports.getLoginLogo = getLoginLogo;
exports.getMember = getMember;
exports.getMultiDepMember = getMultiDepMember;
exports.getOrgList = getOrgList;
exports.getOrgMemberTree = getOrgMemberTree;
exports.getPubKey = getPubKey;
exports.getSSOURL = getSSOURL;
exports.isNeocrmLogin = isNeocrmLogin;
exports.login = login;
exports.loginByCode = loginByCode;
exports.loginByEmail = loginByEmail;
exports.loginBySso = loginBySso;
exports.logout = logout;
exports.register = register;
exports.resetPassword = resetPassword;
exports.secondStepLogin = secondStepLogin;
exports.sendEmailCode = sendEmailCode;
exports.sendEmailCodeWithGraphCode = sendEmailCodeWithGraphCode;
exports.sendTelCode = sendTelCode;
exports.sendTelCodeWithGraphCode = sendTelCodeWithGraphCode;
exports.trialInfo = trialInfo;
exports.updateDetailInfo = updateDetailInfo;
exports.updateLoginEmailAccount = updateLoginEmailAccount;
exports.updatePageSize = updatePageSize;
exports.updatePassWord = updatePassWord;
exports.updateTelInfo = updateTelInfo;
exports.uploadAvatar = uploadAvatar;
var _request = require("@/utils/request");
function getPubKey() {
  return (0, _request.webService)({
    url: '/reglogin/pub_key/get',
    method: 'get'
  });
}
function sendTelCodeWithGraphCode(data) {
  return (0, _request.webService)({
    url: '/profile/sms/send',
    method: 'post',
    dataType: 'json',
    data
  });
}
function sendEmailCodeWithGraphCode(data) {
  return (0, _request.webService)({
    url: '/profile/email/send',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 发送邮箱验证码
function sendEmailCode(data) {
  return (0, _request.webService)({
    url: '/reglogin/email/send',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新发送验证码（适应等保登录）,不携带图形验证码校验
function sendTelCode(data) {
  return (0, _request.webService)({
    url: '/profile/new/sms/send',
    method: 'post',
    dataType: 'json',
    data
  });
}
function register(data) {
  return (0, _request.request)({
    url: '/profile/register',
    method: 'post',
    dataType: 'json',
    data
  });
}
function resetPassword(data) {
  return (0, _request.webService)({
    url: '/profile/password/reset',
    method: 'post',
    dataType: 'json',
    data
  });
}
function login(data) {
  return (0, _request.webService)({
    url: '/reglogin/new/password/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 校验账号
function checkTel(data) {
  return (0, _request.webService)({
    url: '/reglogin/tel/check',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 登录校验邮箱
function checkEmail(data) {
  return (0, _request.webService)({
    url: '/reglogin/email/check',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 等保登录
function equalInsuranceLogin(data) {
  return (0, _request.webService)({
    url: '/reglogin/security/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// export function loginByCode(data) {
//   return request({
//     url: '/profile/login/bycode',
//     method: 'post',
//     data
//   });
// }

function loginByCode(data) {
  return (0, _request.webService)({
    url: '/reglogin/code/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 单点登录

function getSSOURL(params) {
  return (0, _request.request)({
    url: '/sso/type',
    method: 'get',
    params
  });
}
function loginBySso(data) {
  return (0, _request.request)({
    url: '/sso/fxiaoke',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 判断销售易是否登录
function isNeocrmLogin(data) {
  return (0, _request.request)({
    url: '/sso/neocrm',
    method: 'post',
    dataType: 'json',
    data
  });
}

//

// 创建企业
function createTeam(data) {
  return (0, _request.request)({
    url: '/profile/trial/apply',
    method: 'post',
    data
  });
}

// 获取是否已申请创建企业
function getCheckCreated() {
  return (0, _request.request)({
    url: '/profile/trial/check',
    method: 'get'
  });
}

/** 获取导航头部的信息 此接口能返回userid**/
function getInfo() {
  return (0, _request.webService)({
    url: '/profile/info',
    method: 'get'
  });
}

/** 获取个人信息页的详细内容**/
function getDetailInfo() {
  return (0, _request.webService)({
    url: '/profile/get',
    method: 'get'
  });
}

/** 更新个人信息页的详细内容**/
function updateDetailInfo(data) {
  return (0, _request.webService)({
    url: '/profile/user_info/update',
    method: 'post',
    data
  });
}

/** 个人中心登录账号页登录账号修改**/
function updateTelInfo(data) {
  return (0, _request.webService)({
    url: '/profile/mobile/update',
    method: 'post',
    data
  });
}
function updateLoginEmailAccount(data) {
  return (0, _request.webService)({
    url: '/profile/email/update',
    method: 'post',
    data
  });
}

/** 修改手机号前验证旧手机号**/
function checkOldTel(data) {
  return (0, _request.webService)({
    url: '/profile/mobile/check',
    method: 'post',
    data
  });
}

// 修改邮箱前验证邮箱验证码
function checkEmailCaptcha(data) {
  return (0, _request.webService)({
    url: '/profile/email/check',
    method: 'post',
    data
  });
}
function uploadAvatar(data) {
  return (0, _request.webService)({
    url: '/profile/avatar/upload',
    method: 'post',
    data
  });
}
/** 获取个人信息页的修改密码**/
function updatePassWord(data) {
  return (0, _request.webService)({
    url: '/profile/password/update',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 用户是否设置密码
function checkIsSetPassword() {
  return (0, _request.webService)({
    url: '/profile/password/is_set',
    method: 'get'
  });
}
// 退出登录
function logout(id) {
  return (0, _request.webService)({
    url: '/profile/logout',
    method: 'post',
    data: {
      id
    }
  });
}

// 更新用户使用的pagesize
function updatePageSize(page_size) {
  return (0, _request.request)({
    url: '/profile/update/pagesize',
    method: 'post',
    data: {
      page_size
    }
  });
}

// 获取团队列表
function getOrgList(params) {
  return (0, _request.webService)({
    url: '/profile/team/list',
    method: 'get',
    params
  });
}

// 提交用户申请信息
function applyTrial(data) {
  return (0, _request.request)({
    url: '/profile/trial/apply',
    method: 'post',
    data: data
  });
}

// 获取用户申请信息
function trialInfo() {
  return (0, _request.request)({
    url: '/profile/trial/info',
    method: 'get'
  });
}
/**
 * 查找部门下的人(精确查找)
 * @params tree_id, user_name
 */
function getMember(params) {
  return (0, _request.request)({
    url: '/profile/organization_tree_user',
    method: 'get',
    params
  });
}
function getMultiDepMember(data) {
  return (0, _request.request)({
    url: '/organization/tree/get_user_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取成员树（无权限）
function getOrgMemberTree(params) {
  return (0, _request.webService)({
    url: '/organization/user/search',
    method: 'get',
    params
  });
}

// 获取成员树（所管理的部门成员-设置页）
function getDepartmentAndMemberTree(params) {
  return (0, _request.request)({
    url: '/user/below_auth',
    method: 'get',
    params
  });
}

// 获取成员树（数据权限）
function getDataOrgMemberTree(params) {
  return (0, _request.webService)({
    url: '/organization/user/below_data_auth',
    method: 'get',
    params
  });
}

/** 腾讯会议授权 */

function loginByEmail(data) {
  return (0, _request.webService)({
    url: '/reglogin/email/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取登录页面图标
function getLoginLogo() {
  return (0, _request.webService)({
    url: '/organization/conf/login',
    method: 'get'
  });
}

// 同意ai助手的协议
function confirmChatAgreement(data) {
  return (0, _request.webService)({
    url: '/organization/user/config/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

/* 双因子登录 **/
// 第一步登录
function firstStepLogin(data) {
  return (0, _request.webService)({
    url: '/reglogin/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 第二步登录
function secondStepLogin(data) {
  return (0, _request.webService)({
    url: '/reglogin/verify/login',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取语言列表
function getLanguageList() {
  return (0, _request.webService)({
    url: '/profile/language/list',
    method: 'get'
  });
}