"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "train-and-test-dataset"
  }, [_c("div", {
    staticClass: "train-and-test-dataset__title"
  }, [_vm._v("训练和测试数据")]), _c("div", {
    staticClass: "train-dataset"
  }, [_c("div", {
    staticClass: "train-dataset-desc"
  }, [_vm._v("数据集")]), _c("div", {
    staticClass: "train-dataset-content"
  }, [_c(_setup.DataSetSelectors, {
    attrs: {
      "fill-value": _vm.trainDatasetList,
      single: "",
      disabled: _vm.trainDatasetDisabled || _vm.readOnly
    },
    model: {
      value: _setup.localTrainDatasetList,
      callback: function ($$v) {
        _setup.localTrainDatasetList = $$v;
      },
      expression: "localTrainDatasetList"
    }
  })], 1), _c("div", {
    staticClass: "test-dataset"
  }, [_c("div", {
    staticClass: "test-dataset-header"
  }, [_c("div", {
    staticClass: "test-data-desc"
  }, [_vm._v("训练完成后，自动开始测试")]), _vm.showAutoTestSwitch ? _c("el-switch", {
    attrs: {
      disabled: _vm.readOnly,
      value: _vm.isAutoTest
    },
    on: {
      change: _setup.autoTestChange
    }
  }) : _vm._e()], 1), _vm.isAutoTest ? _c("div", {
    staticClass: "test-dataset-content"
  }, [_c("el-radio-group", {
    attrs: {
      value: _vm.datasetSplitType
    },
    on: {
      input: _setup.changeDatasetSplitType
    }
  }, _vm._l(_setup.datasetSplitTypeArr, function (item) {
    return _c("div", {
      key: item.value,
      staticClass: "dataset-split-type"
    }, [_c("el-radio", {
      attrs: {
        label: item.value,
        disabled: _vm.readOnly
      }
    }, [_vm._v(_vm._s(item.label) + " ")]), item.desc ? _c("div", {
      staticClass: "desc"
    }, [_vm._v(_vm._s(item.desc))]) : _vm._e()], 1);
  }), 0), _vm.datasetSplitType === 3 ? [_c(_setup.DataSetSelectors, {
    attrs: {
      "fill-value": _vm.trialDatasetList,
      single: "",
      disabled: _vm.readOnly,
      "disabled-data-sets": _setup.localTrainDatasetList,
      "tip-count": 50
    },
    model: {
      value: _setup.localTrialDatasetList,
      callback: function ($$v) {
        _setup.localTrialDatasetList = $$v;
      },
      expression: "localTrialDatasetList"
    }
  })] : _vm._e()], 2) : _vm._e()])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;