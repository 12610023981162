"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    staticClass: "valid-train-data-table",
    attrs: {
      data: _vm.data,
      height: _vm.tableHeight
    },
    on: {
      "selection-change": _setup.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "序号",
      width: "48px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          $index
        } = _ref;
        return [_vm._v(" " + _vm._s(_vm.startIndex + $index) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "sentence",
      label: "模型预测句",
      "min-width": "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "sentence-container"
        }, _vm._l(row.sentence, function (item, index) {
          return _c("div", {
            key: index
          }, [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: item.content
            }
          }, [_c("div", {
            staticClass: "model-sentence",
            on: {
              click: function ($event) {
                return _setup.onClickModelSentence(row);
              }
            }
          }, [item.entity_type === "host_salesman" ? _c("span", {
            staticClass: "sale-role"
          }, [_vm._v("销售：")]) : _c("span", {
            staticClass: "customer-role"
          }, [_vm._v("客户：")]), _c("span", [_vm._v(_vm._s(item.content))])])])], 1);
        }), 0)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "example",
      label: "例句",
      "show-overflow-tooltip": "",
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("div", {
          staticClass: "model-example"
        }, [row.example.length ? _vm._l(row.example, function (item, index) {
          return _c("div", {
            key: index
          }, [item.entity_type ? [item.entity_type === "customer_contact" ? _c("span", {
            staticClass: "customer-role"
          }, [_vm._v("客户：")]) : _c("span", {
            staticClass: "sale-role"
          }, [_vm._v("销售：")])] : _vm._e(), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: item.content
            }
          }, [_c("span", [_vm._v(_vm._s(item.content))])])], 2);
        }) : [_vm._v("-")]], 2)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "confidence",
      label: "可信度",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "operation-header"
        }, [_vm._v(" 可信度 "), _c("el-tooltip", {
          attrs: {
            placement: "top",
            content: ""
          }
        }, [_c("div", {
          attrs: {
            slot: "content"
          },
          slot: "content"
        }, [_vm._v(" 可信度越趋近1，模型预测句正确的可能性越大，"), _c("br"), _vm._v("可信度越趋近0，模型预测句错误的可能性越大 ")]), _c("i", {
          staticClass: "iconfont icon-info-1"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [row.confidence === -1 ? _c("span", [_vm._v("-")]) : _c("span", [_vm._v(_vm._s(row.confidence))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: 250,
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "operation-header"
        }, [_c("span", [_vm._v("命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.datasetStats.positive_num))]), _c("span", [_vm._v("未命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.datasetStats.negative_num))]), _c("span", [_vm._v("不确定")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.datasetStats.uncertain_num))])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_c("div", {
          staticClass: "operate-line"
        }, [_c(_setup.HitButtons, {
          attrs: {
            status: row.status
          },
          on: {
            change: function ($event) {
              return _setup.annotateMark($event, row);
            }
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right",
      width: 70
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("el-popconfirm", {
          attrs: {
            title: "确定删除此标注吗？",
            "confirm-button-text": "删除",
            "cancel-button-text": "取消",
            "popper-class": "delete-popover"
          },
          on: {
            onConfirm: function ($event) {
              return _setup.deleteAnnotation(row);
            }
          }
        }, [_c("MgvButton", {
          attrs: {
            slot: "reference",
            type: "text",
            color: "danger",
            size: "medium"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;