"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _client = require("@/api/client");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _default = {
  props: {
    showConvKeyEventOption: {
      // 是否展示会话关键事件指标
      type: Boolean,
      default: false
    },
    showPerConvOption: {
      // 是否展示人均通话数量&人均通话时长指标
      type: Boolean,
      default: false
    },
    // 是否展示sop 关键结果的关键事件选项
    showEventsResultType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dealDrawStyleOption: Object.freeze([{
        type: 'count',
        label: '交易个数'
      }, {
        type: 'percent',
        label: '占总交易数比例'
      }]),
      conversationDrawStyleOption: Object.freeze([{
        type: 'count',
        label: '会话个数'
      }, {
        type: 'percent',
        label: '占总会话数比例'
      }]),
      eventOptions: [],
      dealStageOptions: [],
      // 以下数据，总览和A/B 测试都用得到
      analysisTarget: Object.freeze([{
        type: 'conversation',
        label: '会话指标'
      }, {
        type: 'deal',
        label: '交易指标'
      }, {
        type: 'sop',
        label: 'SOP检测项'
      }]),
      indicatorsOptions: {
        conversation: [['会话活动', [{
          field_name: 'count',
          name: '总通话数'
        }, {
          field_name: 'sum_duration',
          name: '总通话时长'
        }, {
          field_name: 'avg_duration',
          name: '平均通话时长'
        }]], ['会话维度', [{
          field_name: 'max_sales',
          name: '平均最长销售独白',
          tooltip: '平均每次会话的销售最长发言时间'
        }, {
          field_name: 'max_customer',
          name: '平均最长客户叙述',
          tooltip: '平均每次会话的客户最长发言时间'
        }, {
          field_name: 'percent_speak',
          name: '销售说话占比',
          tooltip: '平均每次会话的销售发言时间/总时间'
        }, {
          field_name: 'discuss',
          name: '深入讨论次数',
          tooltip: '平均每次会话，客户发言时间超过20秒的次数'
        }, {
          field_name: 'question',
          name: '提问频率',
          tooltip: '平均每10分钟销售提问的次数'
        }]]],
        deal: [],
        sop: [['SOP检测项', [{
          field_name: 'deal_count',
          name: '交易总数'
        }, {
          field_name: 'key_event',
          name: '交易关键事件',
          options: this.eventOptions,
          type: 'cascader'
        }, {
          field_name: 'business_trans',
          name: '业务卡点转化率',
          options: this.eventOptions,
          type: 'cascader'
        }]]]
      }
    };
  },
  // 给vue实例上添加hasCache，cacheResult属性用于组件多次使用时缓存请求数据
  privateData: {
    hasCache: false,
    cacheResult: null,
    dealStageCache: false,
    dealStageResult: null,
    dealCustomOptionsCache: false,
    dealCustomOptionsResult: null
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    // 关键结果
    keyEventResultOption() {
      const keyEventResult = [{
        type: 'custom',
        label: '自定义字段'
      }, {
        type: 'deal_stage',
        label: '交易阶段'
      }];
      this.showEventsResultType && keyEventResult.push({
        type: 'events',
        label: '关键事件'
      });
      return keyEventResult;
    },
    // 作图方式
    hasDrawStyle() {
      const hiddenDrawStyle = ['business_trans'];
      if (_routeMap.ROUTES.biTeamAnalysis !== this.$route.path) {
        hiddenDrawStyle.push('conv_event');
      }
      return this.configs.analysis_target !== 'conversation' && !hiddenDrawStyle.includes(this.configs.analysis_target_key);
    },
    // 根据分析对象选择的指标值返回对应的指标值选项
    getDealCustomConfig() {
      return this.getAllDealCustomerFieldList.find(_ref => {
        let {
          field_name
        } = _ref;
        return field_name === this.configs.analysis_target_key;
      });
    },
    // 将所有的商机自定义字段改为key: value形式，方便快速找到指定项的config
    getAllDealCustomerFieldList() {
      return this.indicatorsOptions.deal.reduce((pre, current) => {
        return pre.concat(current[1]);
      }, []);
    },
    // 分析指标列表
    getAnalysisTargetOptions() {
      return this.indicatorsOptions[this.configs.analysis_target];
    },
    // 根据关键结果类型返回指定的指标值选项
    getStrategyKeyDealCustomFilterConfig() {
      return this.getAllDealCustomerFieldList.find(_ref2 => {
        let {
          field_name
        } = _ref2;
        return field_name === this.configs.strategy.key;
      });
    },
    // 根据关键结果指标值返回对应的指标值
    getStrategyKeyDealCustomFilterValue() {
      var _this$getDealCustomFi;
      return ((_this$getDealCustomFi = this.getDealCustomFilterValue) === null || _this$getDealCustomFi === void 0 ? void 0 : _this$getDealCustomFi.type) === 'select' ? this.configs.strategy.vals.toString() : this.configs.strategy.vals;
    },
    // 关键结果指标列表
    getResultsOptions() {
      if (this.configs.strategy.results_type === 'custom') {
        var _this$indicatorsOptio;
        // 关键结果为：自定义字段
        return (_this$indicatorsOptio = this.indicatorsOptions.deal.find(_ref3 => {
          let [label] = _ref3;
          return label === '自定义字段';
        })) === null || _this$indicatorsOptio === void 0 ? void 0 : _this$indicatorsOptio[1];
      } else {
        // 关键结果为：商机阶段
        return this.dealStageOptions.map(_ref4 => {
          let {
            id,
            stage_name
          } = _ref4;
          return {
            name: stage_name,
            field_name: id
          };
        });
      }
    },
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    showConvKeyEventOption: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.indicatorsOptions.sop[0][1].splice(2, 0, {
              field_name: 'conv_event',
              name: '会话关键事件',
              options: this.eventOptions,
              type: 'cascader'
            });
          });
        }
      },
      immediate: true
    },
    showPerConvOption: {
      handler(val) {
        if (val) {
          const options = [{
            field_name: 'avg_person_count',
            name: '人均通话数（仅对部门生效）'
          }, {
            field_name: 'avg_person_duration',
            name: '人均通话时长（仅对部门生效）'
          }];
          this.indicatorsOptions.conversation[0][1].push(...options);
        }
      },
      immediate: true
    }
  },
  async created() {
    if (this.$options.privateData.dealStageCache) {
      this.handleDealStageResults(this.$options.privateData.dealStageResult);
    } else {
      this.getDealStageOptions();
    }
    if (this.$options.privateData.dealCustomOptionsCache) {
      this.handleRequestResults(this.$options.privateData.dealCustomOptionsResult);
    } else {
      this.getDealCustomOptions();
    }
  },
  methods: {
    async getDealCustomOptions() {
      this.$options.privateData.dealCustomOptionsCache = true;
      this.$options.privateData.dealCustomOptionsResult = (0, _business.getDealCustomFilter)({
        access_type: '/old_seach_list',
        workspace_id: this.workspaceInfo.id
      });
      await this.handleRequestResults(this.$options.privateData.dealCustomOptionsResult);
    },
    handleRequestResults(results) {
      results.then(res => {
        if (res.code === 200) {
          res.results['交易字段'] = res.results['交易字段'].filter(_ref5 => {
            let {
              field_name
            } = _ref5;
            return field_name === 'status';
          });
          res.results['AI智能分析字段'] = res.results['AI智能分析字段'].filter(_ref6 => {
            let {
              field_name
            } = _ref6;
            return field_name !== 'account_label';
          });
          this.indicatorsOptions.deal = Object.entries(res.results);
        }
      });
    },
    async getDealStageOptions() {
      this.$options.privateData.dealStageCache = true;
      this.$options.privateData.dealStageResult = (0, _client.getBusinessStatusOptions)({
        workspace_id: this.workspaceInfo.id
      });
      await this.handleDealStageResults(this.$options.privateData.dealStageResult);
    },
    handleDealStageResults(results) {
      results.then(res => {
        if (res.code === 200) {
          this.dealStageOptions = res.results;
        }
      });
    }
  }
};
exports.default = _default;