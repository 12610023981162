"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTeamAnalysisColumn = addTeamAnalysisColumn;
exports.assignKanban = assignKanban;
exports.cancelAssignKanban = cancelAssignKanban;
exports.cancelShareExperiment = cancelShareExperiment;
exports.changeLikeStatus = changeLikeStatus;
exports.createBiDataOverviewCard = createBiDataOverviewCard;
exports.createBiDataOverviewKanban = createBiDataOverviewKanban;
exports.createBusinessReachKanban = createBusinessReachKanban;
exports.createCustomerBaseProfileChartConfig = createCustomerBaseProfileChartConfig;
exports.createCustomerBaseProfileKanban = createCustomerBaseProfileKanban;
exports.createExperiment = createExperiment;
exports.createFreqQuestion = createFreqQuestion;
exports.createKanban = createKanban;
exports.createPortraitConfig = createPortraitConfig;
exports.createTeamAnalysisKanban = createTeamAnalysisKanban;
exports.deleteBiDataOverviewCard = deleteBiDataOverviewCard;
exports.deleteBiDataOverviewKanban = deleteBiDataOverviewKanban;
exports.deleteBiKanban = deleteBiKanban;
exports.deleteBusinessReachKanban = deleteBusinessReachKanban;
exports.deleteCustomerBaseProfileChartConfig = deleteCustomerBaseProfileChartConfig;
exports.deleteCustomerBaseProfileKanban = deleteCustomerBaseProfileKanban;
exports.deleteExperiment = deleteExperiment;
exports.deleteFreqQuestion = deleteFreqQuestion;
exports.deleteKanban = deleteKanban;
exports.deleteTeamAnalysisColumn = deleteTeamAnalysisColumn;
exports.deleteTeamAnalysisKanban = deleteTeamAnalysisKanban;
exports.editBiDataOverviewCard = editBiDataOverviewCard;
exports.editBiDataOverviewKanban = editBiDataOverviewKanban;
exports.editBusinessReachKanban = editBusinessReachKanban;
exports.editCustomerBaseProfileChartConfig = editCustomerBaseProfileChartConfig;
exports.editCustomerBaseProfileKanban = editCustomerBaseProfileKanban;
exports.editExperiment = editExperiment;
exports.editFAQIgnoreStatus = editFAQIgnoreStatus;
exports.editForewarnConfig = editForewarnConfig;
exports.editFreqQuestion = editFreqQuestion;
exports.editFunnelFieldName = editFunnelFieldName;
exports.editInterpose = editInterpose;
exports.editKanban = editKanban;
exports.editPersonalAbility = editPersonalAbility;
exports.editPortraitConfig = editPortraitConfig;
exports.editTeamAnalysisColumn = editTeamAnalysisColumn;
exports.editTeamAnalysisKanban = editTeamAnalysisKanban;
exports.editWinRateConfig = editWinRateConfig;
exports.exportBusinessAndLossdata = exportBusinessAndLossdata;
exports.exportExperimentTable = exportExperimentTable;
exports.exportFAQAnswerList = exportFAQAnswerList;
exports.exportFAQList = exportFAQList;
exports.exportFAQTrendAnalysisList = exportFAQTrendAnalysisList;
exports.getAllFreqQuestionList = getAllFreqQuestionList;
exports.getAllKnowledge = getAllKnowledge;
exports.getAnalysisShowList = getAnalysisShowList;
exports.getAnalyticalStatement = getAnalyticalStatement;
exports.getAssigneeList = getAssigneeList;
exports.getBiConversation = getBiConversation;
exports.getBiDataOverviewCardData = getBiDataOverviewCardData;
exports.getBiDataOverviewCardList = getBiDataOverviewCardList;
exports.getBiDataOverviewKanbanConfig = getBiDataOverviewKanbanConfig;
exports.getBiDataOverviewKanbanList = getBiDataOverviewKanbanList;
exports.getBiDataOverviewPreviewCardData = getBiDataOverviewPreviewCardData;
exports.getBiDeal = getBiDeal;
exports.getBiTopicConversation = getBiTopicConversation;
exports.getBiViewConfig = getBiViewConfig;
exports.getBusinessAnalysis = getBusinessAnalysis;
exports.getBusinessPathChart = getBusinessPathChart;
exports.getBusinessPresetPathChart = getBusinessPresetPathChart;
exports.getBusinessReachChartData = getBusinessReachChartData;
exports.getBusinessReachKanbanDetail = getBusinessReachKanbanDetail;
exports.getBusinessReachKanbanList = getBusinessReachKanbanList;
exports.getBusinessSankeyChart = getBusinessSankeyChart;
exports.getChartById = getChartById;
exports.getConversatioDimensionBiInfo = getConversatioDimensionBiInfo;
exports.getConversationBiInfo = getConversationBiInfo;
exports.getCustomerBaseProfileChartConfig = getCustomerBaseProfileChartConfig;
exports.getCustomerBaseProfileChartData = getCustomerBaseProfileChartData;
exports.getCustomerBaseProfileChartList = getCustomerBaseProfileChartList;
exports.getCustomerBaseProfileKanbanDetail = getCustomerBaseProfileKanbanDetail;
exports.getCustomerBaseProfileKanbanList = getCustomerBaseProfileKanbanList;
exports.getCustomerReachData = getCustomerReachData;
exports.getDealListByRelPath = getDealListByRelPath;
exports.getDealOptions = getDealOptions;
exports.getExperimentConfig = getExperimentConfig;
exports.getExperimentDetail = getExperimentDetail;
exports.getExperimentList = getExperimentList;
exports.getFAQChartData = getFAQChartData;
exports.getFAQIgnoreQuestionList = getFAQIgnoreQuestionList;
exports.getFAQPieChartData = getFAQPieChartData;
exports.getFAQQuestionList = getFAQQuestionList;
exports.getForewarnConfig = getForewarnConfig;
exports.getFreqQuestionDetail = getFreqQuestionDetail;
exports.getFunnelChartData = getFunnelChartData;
exports.getFunnelPrediction = getFunnelPrediction;
exports.getGroupMembers = getGroupMembers;
exports.getInterpose = getInterpose;
exports.getKanbanConfig = getKanbanConfig;
exports.getKanbanList = getKanbanList;
exports.getKeyeventBiInfo = getKeyeventBiInfo;
exports.getKeyeventBiPersonInfo = getKeyeventBiPersonInfo;
exports.getKnowledgeAllType = getKnowledgeAllType;
exports.getKnowledgeConvList = getKnowledgeConvList;
exports.getKnowledgeList = getKnowledgeList;
exports.getMemberData = getMemberData;
exports.getMemberDealIds = getMemberDealIds;
exports.getMonitorSource = getMonitorSource;
exports.getMyDepartment = getMyDepartment;
exports.getOrderedPath = getOrderedPath;
exports.getPersenAnalysis = getPersenAnalysis;
exports.getPersonComparisonKeyEvent = getPersonComparisonKeyEvent;
exports.getPersonConversationActivity = getPersonConversationActivity;
exports.getPersonConversationDimension = getPersonConversationDimension;
exports.getPersonKeyEvent = getPersonKeyEvent;
exports.getPersonTopicDuration = getPersonTopicDuration;
exports.getPersonTopicTime = getPersonTopicTime;
exports.getPersonalAbilityConfig = getPersonalAbilityConfig;
exports.getPersonalAbilityData = getPersonalAbilityData;
exports.getPersonalAbilityTrendConfig = getPersonalAbilityTrendConfig;
exports.getPersonalAbilityTrendData = getPersonalAbilityTrendData;
exports.getPortraitConfig = getPortraitConfig;
exports.getRankingListData = getRankingListData;
exports.getRelPathChildPercent = getRelPathChildPercent;
exports.getReport = getReport;
exports.getScoreCorrectionKnowledgeList = getScoreCorrectionKnowledgeList;
exports.getScoreCorrectionTemplateConv = getScoreCorrectionTemplateConv;
exports.getShareExperimentList = getShareExperimentList;
exports.getStageAnalysis = getStageAnalysis;
exports.getStatementSubData = getStatementSubData;
exports.getSubQuestionList = getSubQuestionList;
exports.getTeamAnalysisKanbanList = getTeamAnalysisKanbanList;
exports.getTeamAnalysisList = getTeamAnalysisList;
exports.getTeamAnalysisTableHead = getTeamAnalysisTableHead;
exports.getTeamDataHeader = getTeamDataHeader;
exports.getTeamDataList = getTeamDataList;
exports.getTop5BusinessPath = getTop5BusinessPath;
exports.getTopicDuration = getTopicDuration;
exports.getTopicTime = getTopicTime;
exports.getUsersTop1Path = getUsersTop1Path;
exports.getWinRateConfig = getWinRateConfig;
exports.mergeQuestion = mergeQuestion;
exports.modifyPresetPath = modifyPresetPath;
exports.moveBiDataOverviewCard = moveBiDataOverviewCard;
exports.moveCustomerBaseProfileChart = moveCustomerBaseProfileChart;
exports.moveCustomerBaseProfileKanban = moveCustomerBaseProfileKanban;
exports.moveFreqQuestion = moveFreqQuestion;
exports.personalKnowledgeConv = personalKnowledgeConv;
exports.personalKnowledgeTable = personalKnowledgeTable;
exports.postCorrectTemplateScore = postCorrectTemplateScore;
exports.postKnowledgeLike = postKnowledgeLike;
exports.postKnowledgeRename = postKnowledgeRename;
exports.previewProtrait = previewProtrait;
exports.shareExperiment = shareExperiment;
exports.subdivideQuestion = subdivideQuestion;
exports.subscribeKanban = subscribeKanban;
exports.universalCancelShareKanban = universalCancelShareKanban;
exports.universalCopyKanban = universalCopyKanban;
exports.universalGetshareKanbanUserList = universalGetshareKanbanUserList;
exports.universalShareKanban = universalShareKanban;
exports.updateAnalysisShowList = updateAnalysisShowList;
exports.updateTeamAnalysisTableHead = updateTeamAnalysisTableHead;
var _request = require("@/utils/request");
// 获取会话活动
function getConversationBiInfo(data) {
  return (0, _request.request)({
    url: '/bi/conversation/old',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取当前用户的部门
function getMyDepartment() {
  return (0, _request.request)({
    url: '/organization/user/get_tree',
    method: 'get'
  });
}

// 获取会话维度
function getConversatioDimensionBiInfo(data) {
  return (0, _request.request)({
    url: '/bi/conversation_detail',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取分析报显示项
function getAnalysisShowList(params) {
  return (0, _request.request)({
    url: '/bi/report/show',
    method: 'get',
    params
  });
}
// 设置分析报表不显示项
function updateAnalysisShowList(data) {
  return (0, _request.request)({
    url: '/bi/report/notshow',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取分析报表
function getAnalyticalStatement(data) {
  return (0, _request.request)({
    url: '/bi/report',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getStatementSubData(data) {
  return (0, _request.request)({
    url: '/bi/second_report',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取bi关键事件
function getKeyeventBiInfo(data) {
  return (0, _request.request)({
    url: '/bi/event_engine/event_statistics',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取bi关键事件成员
function getKeyeventBiPersonInfo(data) {
  return (0, _request.request)({
    url: '/bi/event_engine/user_statistics',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取话题时长
function getTopicDuration(filters) {
  return (0, _request.request)({
    url: '/bi/topic/duration',
    method: 'get',
    params: filters
  });
}
// 获取话题出现时间
function getTopicTime(filters) {
  return (0, _request.request)({
    url: '/bi/topic/time',
    method: 'get',
    params: filters
  });
}
// 获取会话信息
function getBiConversation(params) {
  return (0, _request.request)({
    url: '/bi/conversation/list',
    method: 'get',
    params
  });
}
function getBiDeal(data) {
  return (0, _request.request)({
    url: '/deal/deal/list_sample',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取话题会话信息（目前话题没有id，只有name，需要单独接口）
function getBiTopicConversation(params) {
  return (0, _request.request)({
    url: '/bi/topic/conversation/list',
    method: 'get',
    params
  });
}

// 个人分析模块
// 获取个人能力洞察详情
function getPersonalAbilityData(data) {
  return (0, _request.webService)({
    url: 'bi/personal/overview/insight/info',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑个人洞察配置
function editPersonalAbility(data) {
  return (0, _request.webService)({
    url: 'bi/personal/overview/insight/config/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取个人洞察配置
function getPersonalAbilityConfig(params) {
  return (0, _request.webService)({
    url: 'bi/personal/overview/insight/config/list',
    method: 'get',
    params
  });
}
// 获取个人能力变化趋势配置
function getPersonalAbilityTrendConfig(data) {
  return (0, _request.webService)({
    url: 'bi/personal/overview/trend/label/list',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取个人能力变化趋势折线图数据
function getPersonalAbilityTrendData(data) {
  return (0, _request.webService)({
    url: 'bi/personal/overview/trend/label/get',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取个人会话活动
function getPersonConversationActivity(data) {
  return (0, _request.request)({
    url: '/bi/conversation/person',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取个人会话维度
function getPersonConversationDimension(data) {
  return (0, _request.request)({
    url: '/bi/conversation_detail/person',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取个人关键事件
function getPersonKeyEvent(data) {
  return (0, _request.request)({
    url: '/bi/event_engine/person',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getPersonTopicDuration(params) {
  return (0, _request.request)({
    url: '/bi/topic/duration/person',
    method: 'get',
    params
  });
}
function getPersonTopicTime(params) {
  return (0, _request.request)({
    url: '/bi/topic/time/person',
    method: 'get',
    params
  });
}

// 该接口已删除  获取业务数据、会话活动、维度数据(分组对比用)
function getPersenAnalysis(params) {
  return (0, _request.request)({
    url: '/bi/comparison/business',
    method: 'get',
    params
  });
}
// 该接口以删除 获取关键事件
function getPersonComparisonKeyEvent(data) {
  return (0, _request.request)({
    url: '/bi/comparison/event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取漏斗预测数据
function getFunnelPrediction(params) {
  return (0, _request.request)({
    url: '/bi/deal_analyze/funnel_prediction',
    method: 'get',
    params
  });
}

// 获取预测赢单率
function getWinRateConfig() {
  return (0, _request.request)({
    url: '/bi/deal_analyze/win_rate_config',
    mehtod: 'get'
  });
}

// 设置预测赢单率
function editWinRateConfig(data) {
  return (0, _request.request)({
    url: '/bi/deal_analyze/win_rate_config/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取商机阶段分析数据
function getStageAnalysis(params) {
  return (0, _request.request)({
    url: 'bi/deal_stage',
    method: 'get',
    params
  });
}

//  获取业务分析数据
function getBusinessAnalysis(data, config) {
  return (0, _request.request)({
    url: 'bi/conversation/boxplot',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

//  获取客户触达情况数据
function getCustomerReachData(data) {
  return (0, _request.request)({
    url: '/bi/conversation/stacked_map',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取业务路径思维导图
function getBusinessPathChart(data) {
  return (0, _request.request)({
    url: '/bi/business_path/mind_map',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取业务路径桑基图
function getBusinessSankeyChart(data) {
  return (0, _request.request)({
    url: '/bi/business_path/sankey',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取业务 Top5 路径
function getTop5BusinessPath(data) {
  return (0, _request.request)({
    url: '/bi/business_path/get_top5_path',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取个人top1路径
function getUsersTop1Path(data) {
  return (0, _request.request)({
    url: '/bi/business_path/get_users_top1_path',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取业务路径的预设路径
function getBusinessPresetPathChart(data) {
  return (0, _request.request)({
    url: '/bi/business_path/preset_path',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 添加、编辑、删除、清空预设路径
function modifyPresetPath(data) {
  return (0, _request.request)({
    url: '/bi/business_path/preset_path/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// top5路径下钻展示有序路径
function getOrderedPath(data) {
  return (0, _request.request)({
    url: '/bi/business_path/get_top5_rel_path',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 有序路径下钻展示子节点占比
function getRelPathChildPercent(data) {
  return (0, _request.request)({
    url: '/bi/business_path/get_rel_path_child_percent',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 根据有序路径获取对应商机
function getDealListByRelPath(data) {
  return (0, _request.request)({
    url: '/bi/business_path/get_deal_list_by_relpath',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 根据路由获取要获取的图表信息
function getBiViewConfig(params) {
  return (0, _request.request)({
    url: '/bi/get_bi_view_config',
    method: 'get',
    params
  });
}

// 根据id获取指定的图表数据
function getChartById(data) {
  return (0, _request.request)({
    url: '/bi/get_chart_by_id',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取商机字段
function getDealOptions() {
  return (0, _request.request)({
    url: '/bi_salesman/config/deal_options',
    method: 'get'
  });
}
// 获取画像看板配置
function getPortraitConfig(params) {
  return (0, _request.request)({
    url: '/bi_salesman/config/get_origin_config',
    method: 'get',
    params
  });
}
// 创建画像看板配置
function createPortraitConfig(data) {
  return (0, _request.request)({
    url: '/bi_salesman/config/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑画像看板配置
function editPortraitConfig(data) {
  return (0, _request.request)({
    url: '/bi_salesman/config/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除画像看板
function deleteBiKanban(data) {
  return (0, _request.request)({
    url: '/bi_salesman/config/delete',
    method: 'post',
    data
  });
}
// 预览画像
function previewProtrait(data) {
  return (0, _request.request)({
    url: '/bi_salesman/config/preview',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识点类型
function getKnowledgeAllType() {
  return (0, _request.request)({
    url: '/bi/knowledge/type',
    method: 'GET'
  });
}

// 知识点列表
function getKnowledgeList(params) {
  return (0, _request.request)({
    url: '/bi/knowledge/list',
    method: 'GET',
    params
  });
}

// 知识点会话列表
function getKnowledgeConvList(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 个人bi知识点筛选器
function getAllKnowledge() {
  return (0, _request.request)({
    url: '/bi/knowledge/filter/type_knowledge',
    method: 'GET'
  });
}

// 个人知识点表格
function personalKnowledgeTable(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/list/my_view',
    method: 'POST',
    dataType: 'json',
    data
  });
}

// 个人知识点会话列表
function personalKnowledgeConv(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/conversation_list/personal_view',
    method: 'POST',
    dataType: 'json',
    data
  });
}

// 团队数据知识点表头
function getTeamDataHeader(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/header/type_knowledge',
    method: 'POST',
    dataType: 'json',
    data
  });
}

// 团队数据知识点列表
function getTeamDataList(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/list/team_view',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识点点赞点踩
function postKnowledgeLike(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/conversation/update_attitude',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改问题名称
function postKnowledgeRename(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/rename',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识点矫正分数左侧知识点列表
function getScoreCorrectionKnowledgeList() {
  return (0, _request.request)({
    url: '/bi/knowledge/score_correction/type_knowledge',
    method: 'get'
  });
}

// 知识点矫正分数右侧样本数据
function getScoreCorrectionTemplateConv(knowledge_id) {
  return (0, _request.request)({
    url: '/bi/knowledge/score_correction/conversation',
    method: 'get',
    params: {
      knowledge_id
    }
  });
}

// 知识点矫正分数修改分数
function postCorrectTemplateScore(data) {
  return (0, _request.request)({
    url: '/bi/knowledge/score_correction/update_score',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 客户触达对比模块
// 获取客户触达对比看板列表
function getBusinessReachKanbanList(params) {
  return (0, _request.request)({
    url: '/bi/deal_compare/kanban/list',
    method: 'get',
    params
  });
}
// 创建客户触达对比看板
function createBusinessReachKanban(data) {
  return (0, _request.request)({
    url: '/bi/deal_compare/kanban/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除客户触达对比看板
function deleteBusinessReachKanban(data) {
  return (0, _request.request)({
    url: '/bi/deal_compare/kanban/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改客户触达对比看板
function editBusinessReachKanban(data) {
  return (0, _request.request)({
    url: '/bi/deal_compare/kanban/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取客户触达对比看板详情
function getBusinessReachKanbanDetail(params) {
  return (0, _request.request)({
    url: '/bi/deal_compare/kanban/detail',
    method: 'get',
    params
  });
}

// 获取图表数据
function getBusinessReachChartData(data) {
  return (0, _request.request)({
    url: '/bi/deal_compare/deal_compare',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 客户群画像模块
// 获取客户群画像看板列表
function getCustomerBaseProfileKanbanList(params) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_list',
    method: 'get',
    params
  });
}
// 创建客户群画像看板
function createCustomerBaseProfileKanban(data) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除客户群画像看板
function deleteCustomerBaseProfileKanban(data) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改客户群画像看板
function editCustomerBaseProfileKanban(data) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 拖拽客户群画像看板
function moveCustomerBaseProfileKanban(data) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取客户群画像看板详情
function getCustomerBaseProfileKanbanDetail(params) {
  return (0, _request.request)({
    url: '/bi/account_group/kanban_detail',
    method: 'get',
    params
  });
}

// 创建客户群画像图表配置
function createCustomerBaseProfileChartConfig(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除客户群画像图表配置
function deleteCustomerBaseProfileChartConfig(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改客户群画像图表配置
function editCustomerBaseProfileChartConfig(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 拖拽客户群画像看板
function moveCustomerBaseProfileChart(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取客户群画像图表配置
function getCustomerBaseProfileChartConfig(params) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_config',
    method: 'get',
    params
  });
}
// 获取客户群画像图表列表
function getCustomerBaseProfileChartList(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改客户群画像图表数据
function getCustomerBaseProfileChartData(data) {
  return (0, _request.request)({
    url: '/bi/account_group/chart_detail',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 业务转化卡点分析模块
// 创建看板
function createKanban(data) {
  return (0, _request.request)({
    url: '/bi/event_conversion/kanban/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑看板
function editKanban(data) {
  return (0, _request.request)({
    url: 'bi/event_conversion/kanban/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取看板列表
function getKanbanList(params) {
  return (0, _request.request)({
    url: '/bi/event_conversion/kanban/list',
    method: 'get',
    params
  });
}

// 获取看板详情
function getKanbanConfig(params) {
  return (0, _request.request)({
    url: 'bi/event_conversion/kanban/detail',
    method: 'get',
    params
  });
}

// 删除看板
function deleteKanban(data) {
  return (0, _request.request)({
    url: 'bi/event_conversion/kanban/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑表头字段
function editFunnelFieldName(data) {
  return (0, _request.webService)({
    url: '/bi/event_conversion/edit_field_name',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取排行榜数据
function getRankingListData(data) {
  return (0, _request.request)({
    url: '/bi/event_conversion/event_ranking',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取漏斗图数据
function getFunnelChartData(data) {
  return (0, _request.request)({
    url: 'bi/event_conversion/funnel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取成员数据
function getMemberData(data) {
  return (0, _request.request)({
    url: 'bi/event_conversion/user',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取业务转化卡点分析 成员转化/流失的商机id列表
function getMemberDealIds(data) {
  return (0, _request.request)({
    url: 'bi/event_conversion/user/get_deal_ids',
    method: 'post',
    dataType: 'json',
    data
  });
}
function exportBusinessAndLossdata(data) {
  return (0, _request.webService)({
    url: '/bi/transform/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// BI概览
// 看板
// 创建看板
function subscribeKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_subscribe',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 创建看板
function createBiDataOverviewKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑看板
function editBiDataOverviewKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除看板
function deleteBiDataOverviewKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 查询看板配置
function getBiDataOverviewKanbanConfig(params) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_detail',
    method: 'get',
    params
  });
}
// 获取看板列表
function getBiDataOverviewKanbanList(params) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_list',
    method: 'get',
    params
  });
}

// 指派
// 指派看板
function assignKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_share',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 取消指派看板
function cancelAssignKanban(data) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_cancel_share',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取已指派人员列表
function getAssigneeList(params) {
  return (0, _request.webService)({
    url: 'bi/view/kanban_share_user',
    method: 'get',
    params
  });
}

// 获取预警配置
function getForewarnConfig(params) {
  return (0, _request.webService)({
    url: 'bi/view/card_warning_config',
    method: 'get',
    params
  });
}

// 编辑预警配置
function editForewarnConfig(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_warning_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取BI总览每个卡片的数据
function getBiDataOverviewCardData(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_detail',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 卡片预览
function getBiDataOverviewPreviewCardData(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_preview',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新建卡片
function createBiDataOverviewCard(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除卡片
function deleteBiDataOverviewCard(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取所有卡片配置
function getBiDataOverviewCardList(params) {
  return (0, _request.webService)({
    url: '/bi/view/card_list',
    method: 'get',
    params
  });
}

// 编辑卡片
function editBiDataOverviewCard(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 拖拽卡片顺序
function moveBiDataOverviewCard(data) {
  return (0, _request.webService)({
    url: 'bi/view/card_move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队全景 -> 团队概览
// 获取团队概览看板列表
function getTeamAnalysisKanbanList(params) {
  return (0, _request.webService)({
    url: '/bi/team/analysis/kanban/list',
    method: 'get',
    params
  });
}
// 创建团队概览看板
function createTeamAnalysisKanban(data) {
  return (0, _request.webService)({
    url: '/bi/team/analysis/kanban/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除团队概览看板
function deleteTeamAnalysisKanban(data) {
  return (0, _request.webService)({
    url: '/bi/team/analysis/kanban/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改团队概览看板
function editTeamAnalysisKanban(data) {
  return (0, _request.webService)({
    url: '/bi/team/analysis/kanban/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取团队概览数据
function getTeamAnalysisList(data, config) {
  return (0, _request.webService)({
    url: 'bi/team/analysis/list',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

// 增加团队概览列
function addTeamAnalysisColumn(data) {
  return (0, _request.webService)({
    url: 'bi/team/analysis/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除团队概览列
function deleteTeamAnalysisColumn(data) {
  return (0, _request.webService)({
    url: `bi/team/analysis/delete`,
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑团队概览列配置
function editTeamAnalysisColumn(data) {
  return (0, _request.webService)({
    url: `bi/team/analysis/update`,
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取团队概览动态列
function getTeamAnalysisTableHead(params) {
  return (0, _request.webService)({
    url: 'bi/team/analysis/head/list',
    method: 'get',
    params
  });
}
// 更新团队概览动态列
function updateTeamAnalysisTableHead(data) {
  return (0, _request.webService)({
    url: 'bi/team/analysis/head/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队全景 -> A/B 测试
// A/Btest 来源
function getMonitorSource(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_source',
    method: 'get',
    params
  });
}

// A/Btest列表
function getExperimentList(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建 A/B 测试
function createExperiment(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑 A/Btest
function editExperiment(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除 A/Btest
function deleteExperiment(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 查询某个A/Btest
function getExperimentConfig(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_detail',
    method: 'get',
    params
  });
}

// 获取干预点
function getInterpose(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/interpose_list',
    method: 'get',
    params
  });
}

// 修改干预点
function editInterpose(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/interpose_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 查看已完成A/Btest的报告概览
function getReport(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_report',
    method: 'get',
    params
  });
}

// A/Btest查看详情
function getExperimentDetail(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/monitor_info',
    method: 'post',
    dataType: 'json',
    data
  });
}

// A/Btest获取人员列表
function getGroupMembers(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/user_list',
    method: 'get',
    params
  });
}

// A/Btest获取分享人员列表
function getShareExperimentList(params) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/share/user_list',
    method: 'get',
    params
  });
}
// A/Btest分享
function shareExperiment(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/share',
    method: 'post',
    dataType: 'json',
    data
  });
}

// A/Btest取消分享
function cancelShareExperiment(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/share/cancel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// A/Btest导出表格
function exportExperimentTable(data) {
  return (0, _request.webService)({
    url: 'bi/ab_testing/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

/**
 * @function BI看板复制、分享功能通用接口
 * @description 客户群画像, 客户触达对比,业务转化卡点分析, 团队概览
 * @param { type } number: 团队概览0，客户群画像1，客户触达对比2，业务转化卡点3
 */
// 看板分享
function universalShareKanban(data) {
  return (0, _request.webService)({
    url: 'bi/kanban/share',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 取消分享看板
function universalCancelShareKanban(data) {
  return (0, _request.webService)({
    url: 'bi/kanban/cancel_share',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取看板已分享人员列表
function universalGetshareKanbanUserList(params) {
  return (0, _request.webService)({
    url: 'bi/kanban/user_list',
    method: 'get',
    params
  });
}

// 复制看板
function universalCopyKanban(data) {
  return (0, _request.webService)({
    url: 'bi/kanban/copy',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题
// 修改高频问题忽略状态
function editFAQIgnoreStatus(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/omit/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取已忽略高频问题列表
function getFAQIgnoreQuestionList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/omit/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取高频问题问题列表
function getFAQQuestionList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取高频问题饼图数据
function getFAQPieChartData(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/pie',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取高频问题趋势分析图表数据
function getFAQChartData(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/analysis',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题导出
function exportFAQList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题趋势分析导出
function exportFAQTrendAnalysisList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/analysis/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题销售回答列表导出
function exportFAQAnswerList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/detail/list/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题销售回答 问答对移动
function moveFreqQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/qa/move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取所有高频问题
function getAllFreqQuestionList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/all_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题 问题详情
function getFreqQuestionDetail(params) {
  return (0, _request.webService)({
    url: '/bi/freq_question/detail',
    method: 'get',
    params
  });
}

// 高频问题新建
function createFreqQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题编辑
function editFreqQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题删除
function deleteFreqQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题合理状态切换(点赞点踩)
function changeLikeStatus(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/qa/status',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题子类问题列表
function getSubQuestionList(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/child_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题子类问题拆分
function subdivideQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/child_split',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 高频问题合并
function mergeQuestion(data) {
  return (0, _request.webService)({
    url: '/bi/freq_question/merge',
    method: 'post',
    dataType: 'json',
    data
  });
}