"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-drawer"
  }, [_c("chat-unit", _vm._g({
    ref: "chatUnit",
    attrs: {
      "check-is-support": "",
      "prompt-config": _vm.promptConfig,
      "request-config": _vm.requestConfig,
      "message-operate-list": ["comment"],
      "is-show-chat-input": ""
    },
    on: {
      commentScopeChange: _vm.commentScopeChange
    }
  }, _vm.$listeners)), _c("comment-accessable-member-dialog", {
    ref: "commentMember",
    attrs: {
      visible: _vm.commentMemberDialogVisible,
      "footer-visible": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.commentMemberDialogVisible = $event;
      },
      memberChange: _vm.handleMemberChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;