"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _index = require("@/utils/index.js");
var _default = {
  name: 'UploadMediaDialogHead',
  props: {
    tooltipList: {
      type: Array,
      default: () => ['格式支持：WAV、MP3、M4A、WMA、AAC、OGG、AMR、FLAC；AVI、RMVB、MKV、ASF、WMV、MP4、3GP、FLV、MOV、M4V', '单次最多上传10个文件', '单个音频文件最大500MB', '单个视频文件最大4GB', '单个文件最长3个小时']
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['usedDuration', 'maxDuration', 'orgUsedDuration', 'orgMaxDuration', 'isUseOrgMaxDuration', 'uploadStatus', 'uploadHostSalesmanInfo'])
  },
  methods: {
    handleMinutes: _index.handleMinutes
  }
};
exports.default = _default;