"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    value: {},
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    isEdit: Boolean,
    prop: String | Number,
    required: Boolean,
    options: Array,
    placeholder: String
  },
  watch: {
    // 当值发生变化时
    value: {
      handler: function (value) {
        this.handleValueChanged(value);
      },
      immediate: true
    }
  },
  data() {
    return {
      // 默认事件
      defaultOn: {
        input: this.handleChange
      },
      // 新值, 因为Vue不能修改props传递过来的值, 所以借助新的值进行传递
      newValue: this.value
    };
  },
  methods: {
    // 变化处理
    handleChange(value) {
      this.$emit('input', value);
    },
    // 初始化数据
    handleValueChanged(value) {
      this.newValue = value;
    }
  }
};
exports.default = _default;