"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-library-filter"
  }, [_c("bi-filter", {
    staticClass: "filter-select",
    attrs: {
      "has-deal-custom-field-filter": "",
      layout: _vm.layout,
      "default-more-filter": _vm.defaultMoreFilter
    },
    on: {
      showDefaultFilter: _vm.showDefaultFilter,
      filtersChange: _vm.handleBiFiltersChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;