"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog is-center",
    attrs: {
      title: _setup.t("coaching.离开练习"),
      visible: _vm.visible,
      "custom-class": "back-to-task-dialog"
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("MgvButton", {
          attrs: {
            type: "plain",
            color: "info"
          },
          on: {
            click: _setup.cancel
          }
        }, [_vm._v(_vm._s(_setup.t("coaching.继续练习")))]), _c("MgvButton", {
          attrs: {
            type: "plain",
            color: "info"
          },
          on: {
            click: _setup.abandon
          }
        }, [_vm._v(_vm._s(_setup.t("coaching.放弃练习")))]), _c("MgvButton", {
          on: {
            click: _setup.stash
          }
        }, [_vm._v(_vm._s(_setup.t("coaching.暂存离开")))])];
      },
      proxy: true
    }])
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.任务截止前可继续练习，确认离开？")) + " ")]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;