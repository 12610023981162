"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-practice"
  }, _vm._l(_vm.localList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tricks-card"
    }, [item.scene_content ? _c("div", {
      staticClass: "scene-name"
    }, [_c("tricks-scene", {
      attrs: {
        "display-only": "",
        value: item.scene_content
      }
    })], 1) : _vm._e(), item.reply_list && item.reply_list.length ? _c("div", {
      staticClass: "tricks"
    }, _vm._l(item.reply_list, function (reply, replyIndex) {
      return _c("div", {
        key: replyIndex,
        staticClass: "trick-item"
      }, [_c("p", {
        class: ["trick-content", {
          current: reply.reply_id === _vm.currentReplyId
        }]
      }, [_c("span", [!_vm.isTemplate ? _c("span", [_vm._v("(" + _vm._s(reply.order) + ") ")]) : _vm._e(), _vm._v(" " + _vm._s(reply.reply_content))]), _vm.playVoiceIcon && reply.reply_record_standard_url ? _c("PlayVoice", {
        attrs: {
          "is-play": _vm.currentTemplateVoiceId === reply.reply_id
        },
        on: {
          play: function ($event) {
            return _vm.play(reply.reply_id, reply.reply_record_standard_url, reply.duration);
          },
          pause: _vm.pause
        }
      }) : _vm._e()], 1), _c("audio-recorder", {
        ref: "audio-recorder",
        refInFor: true,
        attrs: {
          reply: reply,
          "is-template": _vm.isTemplate,
          disabled: _vm.getPracticeDisabled()
        },
        on: {
          recording: function ($event) {
            return _vm.handleRecording(reply.reply_id);
          },
          finished: _vm.handleFinished
        }
      }), _vm.showIntelligentReview ? _c("div", {
        staticClass: "comment-container"
      }, [_c("p", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_vm.$t("coaching.salesResponse")) + "：")]), _c("p", [_vm._v(_vm._s(reply.content || _vm.$t("coaching.noContent")))]), _c("p", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_vm.$t("coaching.intelligentReview")) + "：")]), _c("p", {
        domProps: {
          innerHTML: _vm._s(reply.comment || _vm.$t("coaching.noCommentsAvailable"))
        }
      })]) : _vm._e()], 1);
    }), 0) : _vm._e()]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;