"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "scroll-box"
  }, [_c("dynamic-tab-chart", {
    ref: "dynamicTab",
    attrs: {
      "is-loading": _vm.isLoading,
      "page-type": _vm.pageType,
      "page-configs": _vm.pageConfigs,
      "current-tab": _vm.currentTab,
      "chart-data": _vm.chartData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;