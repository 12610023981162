"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.hideSavedFilters ? _c("div", {
    staticClass: "conversation-score-filters"
  }, _vm._l(_vm.complianceScoreFiltersList, function (scoreItem, scoreIndex) {
    return _c(_setup.ConversationFilterItem, {
      key: scoreItem.id,
      attrs: {
        label: scoreItem.name,
        "popover-content": scoreItem.description,
        "is-show-clear": !!scoreItem.type
      },
      on: {
        handleClear: function ($event) {
          return _setup.handlerScoreChange(scoreIndex);
        }
      }
    }, [_c(_setup.GradeRange, {
      attrs: {
        params: scoreItem
      },
      on: {
        filterChange: function ($event) {
          return _setup.handlerScoreChange(scoreIndex, $event);
        }
      }
    })], 1);
  }), 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;