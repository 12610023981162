"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkKnowledgeLength = checkKnowledgeLength;
exports.copyCourse = copyCourse;
exports.createAiCustomer = createAiCustomer;
exports.createAiCustomerCourse = createAiCustomerCourse;
exports.createAiCustomerSopCourse = createAiCustomerSopCourse;
exports.createAiCustomerSopPlusCourse = createAiCustomerSopPlusCourse;
exports.createAiScoreRule = createAiScoreRule;
exports.createCoachingTasks = createCoachingTasks;
exports.createCourse = createCourse;
exports.createCourseCategory = createCourseCategory;
exports.createMemberGroup = createMemberGroup;
exports.createSmartTrainingCourse = createSmartTrainingCourse;
exports.createSmartTrainingCourseTemplate = createSmartTrainingCourseTemplate;
exports.deleteCoachingTask = deleteCoachingTask;
exports.deleteCourse = deleteCourse;
exports.deleteCourseCategory = deleteCourseCategory;
exports.deleteMemberGroup = deleteMemberGroup;
exports.deleteSmartTrainingCourse = deleteSmartTrainingCourse;
exports.deleteSmartTrainingCourseTemplate = deleteSmartTrainingCourseTemplate;
exports.deleteTemplateRecord = deleteTemplateRecord;
exports.dragCourseCategory = dragCourseCategory;
exports.editAiCustomerCourse = editAiCustomerCourse;
exports.editAiCustomerSopCourse = editAiCustomerSopCourse;
exports.editAiCustomerSopCourseName = editAiCustomerSopCourseName;
exports.editAiCustomerSopPlusCourse = editAiCustomerSopPlusCourse;
exports.editCourseCategoryPublicScope = editCourseCategoryPublicScope;
exports.editSmartTrainingCourse = editSmartTrainingCourse;
exports.editTemplatePublicScope = editTemplatePublicScope;
exports.exportPlanResults = exportPlanResults;
exports.exportTraineeTaskDetail = exportTraineeTaskDetail;
exports.formMerge2Prompt = formMerge2Prompt;
exports.getAiConversationComment = getAiConversationComment;
exports.getAiCustomerCharacter = getAiCustomerCharacter;
exports.getAiCustomerConfig = getAiCustomerConfig;
exports.getAiCustomerHistoryList = getAiCustomerHistoryList;
exports.getAiCustomerKeyScriptReview = getAiCustomerKeyScriptReview;
exports.getAiCustomerPracticeResultDetail = getAiCustomerPracticeResultDetail;
exports.getAiCustomerPracticeScriptsResult = getAiCustomerPracticeScriptsResult;
exports.getAiScoreRule = getAiScoreRule;
exports.getAiSopCourseDetail = getAiSopCourseDetail;
exports.getAiWriteForm = getAiWriteForm;
exports.getCoachParentTaskDetail = getCoachParentTaskDetail;
exports.getCoachingParentPlanDetail = getCoachingParentPlanDetail;
exports.getCoachingTaskInfo = getCoachingTaskInfo;
exports.getConversationList = getConversationList;
exports.getCourseAnalysisList = getCourseAnalysisList;
exports.getCourseAnalysisMissPointsDetail = getCourseAnalysisMissPointsDetail;
exports.getCourseCategoryData = getCourseCategoryData;
exports.getCourseCategoryPublicScope = getCourseCategoryPublicScope;
exports.getCourseDetail = getCourseDetail;
exports.getCourseDispatchTaskList = getCourseDispatchTaskList;
exports.getCourseList = getCourseList;
exports.getCourseSearchData = getCourseSearchData;
exports.getLeaderPlanList = getLeaderPlanList;
exports.getLeaderTaskList = getLeaderTaskList;
exports.getMemberGroupList = getMemberGroupList;
exports.getMemberGroupMemberList = getMemberGroupMemberList;
exports.getPracticeDetail = getPracticeDetail;
exports.getPracticeProcessHitBeta = getPracticeProcessHitBeta;
exports.getPracticeProcessHitGamma = getPracticeProcessHitGamma;
exports.getReadingAndRecitingCourseTaskDetail = getReadingAndRecitingCourseTaskDetail;
exports.getSalesKnowledgeReplyDetail = getSalesKnowledgeReplyDetail;
exports.getSalesTaskList = getSalesTaskList;
exports.getSmartLostGistsAsync = getSmartLostGistsAsync;
exports.getSmartTrainingBeginData = getSmartTrainingBeginData;
exports.getSmartTrainingCourseDetail = getSmartTrainingCourseDetail;
exports.getSmartTrainingCourseTemplateList = getSmartTrainingCourseTemplateList;
exports.getSmartTrainingList = getSmartTrainingList;
exports.getSmartTrainingSalesSayScore = getSmartTrainingSalesSayScore;
exports.getSmartTrainingSet = getSmartTrainingSet;
exports.getSmartTrainingTestBeginData = getSmartTrainingTestBeginData;
exports.getSmartTrainingTestSalesSayScore = getSmartTrainingTestSalesSayScore;
exports.getTaskCategoryList = getTaskCategoryList;
exports.getTaskScore = getTaskScore;
exports.getTeamViewList = getTeamViewList;
exports.getTempToken = getTempToken;
exports.getTemplatePublicScope = getTemplatePublicScope;
exports.getTrainPlan = getTrainPlan;
exports.getTraineeOverview = getTraineeOverview;
exports.getTraineeTaskDetail = getTraineeTaskDetail;
exports.getTtsAvatar = getTtsAvatar;
exports.giveUpPractice = giveUpPractice;
exports.moveCourse = moveCourse;
exports.saveTaskCategory = saveTaskCategory;
exports.scoreMetricsCreate = scoreMetricsCreate;
exports.scoreMetricsDelete = scoreMetricsDelete;
exports.scoreMetricsDimensionCreate = scoreMetricsDimensionCreate;
exports.scoreMetricsDimensionDelete = scoreMetricsDimensionDelete;
exports.scoreMetricsDimensionList = scoreMetricsDimensionList;
exports.scoreMetricsDimensionUpdate = scoreMetricsDimensionUpdate;
exports.scoreMetricsList = scoreMetricsList;
exports.scoreMetricsUpdate = scoreMetricsUpdate;
exports.setAiConversationOverride = setAiConversationOverride;
exports.tempStoreAiCustomerConfig = tempStoreAiCustomerConfig;
exports.tempStoreAiCustomerConversation = tempStoreAiCustomerConversation;
exports.updateAiCustomer = updateAiCustomer;
exports.updateCoachingTasks = updateCoachingTasks;
exports.updateCourse = updateCourse;
exports.updateCourseCategory = updateCourseCategory;
exports.updateSmartTrainingSet = updateSmartTrainingSet;
exports.uploadReplyRecord = uploadReplyRecord;
exports.uploadSmartTrainingAudio = uploadSmartTrainingAudio;
exports.uploadSmartTrainingTest = uploadSmartTrainingTest;
exports.uploadTemplateRecord = uploadTemplateRecord;
exports.uploadTrainingAudio = uploadTrainingAudio;
exports.urgeTask = urgeTask;
var _request = require("@/utils/request");
// 创建培训任务
function createCoachingTasks(data) {
  return (0, _request.webService)({
    url: '/training/plan/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑培训任务
function updateCoachingTasks(data) {
  return (0, _request.webService)({
    url: '/training/plan/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除培训任务
function deleteCoachingTask(data) {
  return (0, _request.webService)({
    url: '/training/plan/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取培训任务信息
function getCoachingTaskInfo(params) {
  return (0, _request.webService)({
    url: '/training/plan',
    method: 'get',
    params
  });
}

// 获取单个父任务详情
function getCoachingParentPlanDetail(params) {
  return (0, _request.webService)({
    url: '/training/parentplan',
    method: 'get',
    params
  });
}

// 团队视图列表
function getTeamViewList(data) {
  return (0, _request.webService)({
    url: '/training/plan/team_view',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 我分配的培训计划列表
function getLeaderTaskList(data) {
  return (0, _request.webService)({
    url: '/training/plan/my_distribute',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 上传录音
function uploadReplyRecord(data) {
  return (0, _request.webService)({
    url: '/training/plan/record/upload',
    method: 'post',
    data
  });
}

// 上传模板录音
function uploadTemplateRecord(data) {
  return (0, _request.webService)({
    url: '/training/reply/record/upload',
    method: 'post',
    data
  });
}
// 删除模板录音
function deleteTemplateRecord(data) {
  return (0, _request.webService)({
    url: '/training/reply/record/delete',
    method: 'post',
    data
  });
}

// 分配给我的列表
function getSalesTaskList(data) {
  return (0, _request.webService)({
    // url: '/training/plan/my_attend',
    url: '/training/plan/my/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识点练习详情
function getPracticeDetail(params) {
  return (0, _request.webService)({
    url: '/training/plan/result/detail',
    method: 'get',
    params
  });
}

// 导出任务完成结果
function exportPlanResults(data) {
  return (0, _request.webService)({
    url: '/training/plan/user_results/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取员工培训作答列表
function getLeaderPlanList(data) {
  return (0, _request.webService)({
    url: '/training/plan/user_results/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取课程分析列表
function getCourseAnalysisList(params) {
  return (0, _request.webService)({
    url: '/training/plan/smart/analysis',
    method: 'get',
    params
  });
}

// 获取课程分析失分详情
function getCourseAnalysisMissPointsDetail(params) {
  return (0, _request.webService)({
    url: '/training/plan/smart/analysis/lost_detail',
    method: 'get',
    params
  });
}

// 获取单个任务详情
function getTrainPlan(params) {
  return (0, _request.webService)({
    url: '/training/plan',
    method: 'get',
    params
  });
}

// 获取员工培训知识点作答概念
function getSalesKnowledgeReplyDetail(data) {
  return (0, _request.webService)({
    url: '/training/plan/user_result',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取任务得分概览
function getTaskScore(params) {
  return (0, _request.webService)({
    url: '/training/plan/user_result/score',
    method: 'get',
    params
  });
}

// 主动催促任务
function urgeTask(data) {
  return (0, _request.webService)({
    url: '/training/plan/urge',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 培训-课程搭建
// 课程分类树操作
// 获取课程分类树
function getCourseCategoryData(params) {
  return (0, _request.webService)({
    url: '/training/course_category/list',
    method: 'get',
    params
  });
}

// 创建课程分类
function createCourseCategory(data) {
  return (0, _request.webService)({
    url: '/training/course_category/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑课程分类
function updateCourseCategory(data) {
  return (0, _request.webService)({
    url: '/training/course_category/update',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除课程分类
function deleteCourseCategory(data) {
  return (0, _request.webService)({
    url: '/training/course_category/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 拖拽排序课程分类
function dragCourseCategory(data) {
  return (0, _request.webService)({
    url: '/training/course_category/move',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑课程分类公开范围
function editCourseCategoryPublicScope(data) {
  return (0, _request.webService)({
    url: '/training/course_category/scope/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取课程分类公开范围配置
function getCourseCategoryPublicScope(params) {
  return (0, _request.webService)({
    url: '/training/course_category/permission/list',
    method: 'get',
    params
  });
}

// 课程操作
// 课程列表
function getCourseList(data) {
  return (0, _request.webService)({
    url: '/training/course/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 课程删除
function deleteCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/delete',
    method: 'post',
    data
  });
}

// 移动课程分类
function moveCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/category/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新建课程
function createCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 课程编辑
function updateCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/update',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 复制课程
function copyCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/copy',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取课程详情
function getCourseDetail(params) {
  return (0, _request.webService)({
    url: '/training/course/detail',
    method: 'get',
    params
  });
}

// 课程搜索
function getCourseSearchData(params) {
  return (0, _request.webService)({
    url: '/training/course/search',
    method: 'get',
    params
  });
}

// 智能对练
// 创建智能对练课程
function createSmartTrainingCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新智能对练课程
function editSmartTrainingCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除智能对练课程
function deleteSmartTrainingCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/delete',
    method: 'post',
    data
  });
}

// 创建智能对练课程模板
function createSmartTrainingCourseTemplate(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/templete/create',
    method: 'post',
    data
  });
}

// 删除智能对练课程模板
function deleteSmartTrainingCourseTemplate(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/templete/delete',
    method: 'post',
    data
  });
}

// 获取智能对练课程模板列表
function getSmartTrainingCourseTemplateList(params) {
  return (0, _request.webService)({
    url: '/training/course/smart/templete/list',
    method: 'get',
    params
  });
}

// 获取智能对练课程详情
function getSmartTrainingCourseDetail(params) {
  return (0, _request.webService)({
    url: '/training/course/smart/detail',
    method: 'get',
    params
  });
}

// 获取智能对练任务开始数据
function getSmartTrainingBeginData(params) {
  return (0, _request.webService)({
    url: '/training/plan/smart/begin',
    method: 'get',
    params
  });
}

// 智能对练上传录音
function uploadSmartTrainingAudio(data) {
  return (0, _request.webService)({
    url: '/training/plan/smart/upload',
    method: 'post',
    data
  });
}

// 智能对练获取销售说智能点评
function getSmartTrainingSalesSayScore(data) {
  return (0, _request.webService)({
    url: '/training/plan/smart/score',
    method: 'post',
    data
  });
}

// 智能对练测试练习获取销售说智能点评
function getSmartTrainingTestSalesSayScore(data) {
  return (0, _request.webService)({
    url: '/training/test/score',
    method: 'post',
    data
  });
}

// 获取智能对练培训任务触达话术, 获取AI客户对练记录详情
function getSmartTrainingList(params) {
  return (0, _request.webService)({
    url: '/training/plan/smart/list',
    method: 'get',
    params
  });
}

// 获取智能对练课程配置
function getSmartTrainingSet(params) {
  return (0, _request.webService)({
    url: '/training/course/smart/rule/get',
    method: 'get',
    params
  });
}
// 获取腾讯云临时token
function getTempToken(params) {
  return (0, _request.webService)({
    url: '/training/plan/tencent_cloud_signature',
    method: 'get',
    params
  });
}

// 更新智能对练课程配置
function updateSmartTrainingSet(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 开始测试流程
function getSmartTrainingTestBeginData(data) {
  return (0, _request.webService)({
    url: '/training/test/begin',
    method: 'post',
    data
  });
}

// 继续测试流程
function uploadSmartTrainingTest(data) {
  return (0, _request.webService)({
    url: '/training/test/upload',
    method: 'post',
    data
  });
}

// AI客户(Alpha)
// 新建AI客户(Alpha)课程
function createAiCustomerCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/ai/customer/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑AI客户(Alpha)课程
function editAiCustomerCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/ai/customer/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取AI客户(Alpha)课程会话筛选列表
function getConversationList(data) {
  return (0, _request.webService)({
    url: '/training/course/ai/customer/conversation_list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取AI客户(Alpha)特征
function getAiCustomerCharacter() {
  return (0, _request.webService)({
    url: '/training/course/ai/customer/character',
    method: 'get'
  });
}

// 获取AI客户(Alpha)对练AI点评
function getAiCustomerPracticeResultDetail(params) {
  return (0, _request.webService)({
    url: '/training/plan/user_result/score',
    method: 'get',
    params
  });
}

// AI客户(Beta)

// 校验知识点长度
function checkKnowledgeLength(data) {
  return (0, _request.webService)({
    url: '/training/course/full_ai/check_scene_tokens',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建AI客户(Beta)课程
function createAiCustomerSopCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/scene_ai/customer/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑AI客户(Beta)课程
function editAiCustomerSopCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/scene_ai/customer/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取AI客户(Beta)课程详情
function getAiSopCourseDetail(params) {
  return (0, _request.webService)({
    url: '/training/course/scene_ai/customer/get',
    method: 'get',
    params
  });
}

// 编辑AI客户(Beta)课程名称
function editAiCustomerSopCourseName(data) {
  return (0, _request.webService)({
    url: '/training/course/scene_ai/customer/update/name',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取智能对练培训任务触达话术, 获取AI客户对练记录详情
function getAiCustomerKeyScriptReview(params) {
  return (0, _request.webService)({
    url: '/training/plan/ai/customer/comment',
    method: 'get',
    params
  });
}

// AI客户(Beta)加强版
// 创建AI客户(Beta)加强版课程
function createAiCustomerSopPlusCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/full_ai/customer/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑AI客户(Beta)加强版课程
function editAiCustomerSopPlusCourse(data) {
  return (0, _request.webService)({
    url: '/training/course/full_ai/customer/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 查询任务生成的会话是否存在评论
function getAiConversationComment(params) {
  return (0, _request.webService)({
    url: '/training/plan/ai/customer/conversation/has_comment',
    method: 'get',
    params
  });
}

// 设置重新练习ai会话是否覆盖原会话
function setAiConversationOverride(data) {
  return (0, _request.webService)({
    url: '/training/plan/ai/customer/is_override',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 对练上传录音
function uploadTrainingAudio(data) {
  return (0, _request.webService)({
    url: '/training/plan/ai/upload_sales',
    method: 'post',
    data
  });
}

// 获取ai客户练习历史记录列表
function getAiCustomerHistoryList(params) {
  return (0, _request.webService)({
    url: '/training/plan/user/record_list',
    method: 'get',
    params
  });
}

// 剧本对练- 创建AI要点
function createAiScoreRule(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/gist/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 剧本对练- 获取自动补齐AI要点结果
function getAiScoreRule(params) {
  return (0, _request.webService)({
    url: '/training/course/smart/gist/list',
    method: 'get',
    params,
    timeout: 60
  });
}

// 获取朗读背诵课程练习结果详情
function getReadingAndRecitingCourseTaskDetail(data) {
  return (0, _request.webService)({
    url: '/training/plan/user_result/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取失分环节
function getSmartLostGistsAsync(params) {
  return (0, _request.webService)({
    url: '/training/plan/smart/user/lost_gists',
    dataType: 'json',
    params
  });
}

// 获取父任务详情
function getCoachParentTaskDetail(data) {
  return (0, _request.webService)({
    url: '/training/plan/total_results/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 课程派发任务列表
function getCourseDispatchTaskList(params) {
  return (0, _request.webService)({
    url: '/training/plan/course_view',
    method: 'get',
    params
  });
}

/** 常用执行人组相关接口 */
// 新建成员组
function createMemberGroup(data) {
  return (0, _request.webService)({
    url: '/organization/user/filter/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除成员组
function deleteMemberGroup(data) {
  return (0, _request.webService)({
    url: '/organization/user/filter/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取成员组列表
function getMemberGroupList(params) {
  return (0, _request.webService)({
    url: '/organization/user/filter/list',
    method: 'get',
    params
  });
}

// 获取成员组成员列表
function getMemberGroupMemberList(params) {
  return (0, _request.webService)({
    url: '/organization/user/filter/info',
    method: 'get',
    params
  });
}

// 编辑剧本对练模板公开范围
function editTemplatePublicScope(data) {
  return (0, _request.webService)({
    url: '/training/course/smart/template/member/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取剧本对练模板公开范围
function getTemplatePublicScope(params) {
  return (0, _request.webService)({
    url: '/training/course/smart/template/member/list',
    method: 'get',
    params
  });
}

// 获取任务分类列表
function getTaskCategoryList(params) {
  return (0, _request.webService)({
    url: '/training/plan/category/list',
    method: 'get',
    params
  });
}

// 保存任务分类
function saveTaskCategory(data) {
  return (0, _request.webService)({
    url: '/training/plan/category/save',
    method: 'post',
    dataType: 'json',
    data
  });
}

/**
 * ai 客户v1.00
 */
// 创建ai客户
function createAiCustomer(data) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑ai客户
function updateAiCustomer(data) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取ai客户配置详情
function getAiCustomerConfig(params) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/get',
    method: 'get',
    params
  });
}

// 暂存AI客户配置, 以便测试使用
function tempStoreAiCustomerConfig(data) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/config',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取AI客户练习话术评分结果
function getAiCustomerPracticeScriptsResult(params) {
  return (0, _request.webService)({
    url: '/training/plan/ai/customer/result',
    method: 'get',
    params
  });
}

// 智能编排
function getAiWriteForm(data) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/prompt/ai_design',
    method: 'post',
    dataType: 'json',
    data,
    timeout: 600
  });
}

// 合并为prompt
function formMerge2Prompt(data) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/prompt/merge',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取tts对应头像
function getTtsAvatar(params) {
  return (0, _request.webService)({
    url: '/training/course/avatar_map',
    method: 'get',
    params
  });
}

/**
 * 评分指标
 */

// 创建
function scoreMetricsCreate(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/metrics/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑
function scoreMetricsUpdate(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/metrics/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 指标列表
function scoreMetricsList(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/metrics/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除
function scoreMetricsDelete(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/metrics/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建指标维度
function scoreMetricsDimensionCreate(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/dimension/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑指标维度
function scoreMetricsDimensionUpdate(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/dimension/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除指标维度
function scoreMetricsDimensionDelete(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/dimension/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 指标维度列表
function scoreMetricsDimensionList(data) {
  return (0, _request.webService)({
    url: '/training/course/evaluate/dimension/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 对练过程话术提示

// beta
function getPracticeProcessHitBeta(params) {
  return (0, _request.webService)({
    url: '/training/course/full_ai/customer/tips',
    method: 'get',
    params
  });
}
// gamma
function getPracticeProcessHitGamma(params) {
  return (0, _request.webService)({
    url: '/training/course/ai_customer/tips',
    method: 'get',
    params
  });
}

/** 培训数据分析 start*/

// 学员概览
function getTraineeOverview(data) {
  return (0, _request.webService)({
    url: '/training/analysis/user',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 任务明细
function getTraineeTaskDetail(data) {
  return (0, _request.webService)({
    url: '/training/analysis/plan',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 任务明细导出
function exportTraineeTaskDetail(data) {
  return (0, _request.webService)({
    url: '/training/analysis/plan/export',
    method: 'post',
    dataType: 'json',
    data
  });
}
/** 培训数据分析 end*/

/** 继续练习  start*/

// 暂存会话 剧本对练
function tempStoreAiCustomerConversation(data) {
  return (0, _request.webService)({
    url: '/training/plan/smart/save',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 放弃练习 通用
function giveUpPractice(data) {
  return (0, _request.webService)({
    url: '/training/plan/record/abandon',
    method: 'post',
    dataType: 'json',
    data
  });
}
/** 继续练习  end*/