"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.getOperateType.label,
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "800px",
      "custom-class": "customer-base-profile",
      "wrapper-closable": false,
      "append-to-body": ""
    }
  }, [_c("div", {
    staticClass: "scroll-box"
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKanbanConfigLoading,
      expression: "getKanbanConfigLoading"
    }],
    ref: "kanbanConfigForm",
    attrs: {
      model: _vm.kanbanConfig
    }
  }, [_c("el-form-item", {
    staticClass: "kanban-name",
    attrs: {
      prop: "name",
      label: "看板名称",
      rules: [{
        required: true,
        message: "请输入看板名称",
        trigger: "change"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      placeholder: "请输入看板名称"
    },
    model: {
      value: _vm.kanbanConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "kanbanConfig.name"
    }
  })], 1), _c("el-form-item", {
    staticClass: "kanban-name",
    attrs: {
      prop: "description",
      label: "描述"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "200",
      placeholder: "请输入描述内容",
      "show-word-limit": ""
    },
    model: {
      value: _vm.kanbanConfig.description,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "description", $$v);
      },
      expression: "kanbanConfig.description"
    }
  })], 1), _c("lable-require-tooltip-remark", {
    attrs: {
      config: {
        label: "分群规则",
        remark: "未选择分群规则时，默认选中全部交易"
      }
    }
  }), _vm._l(_vm.kanbanConfig.filter_config, function (itemMetrics, index) {
    return _c("el-form-item", {
      key: itemMetrics.id,
      staticClass: "form-metrics-item"
    }, [_c("div", {
      staticClass: "metrics-item"
    }, [_c("el-form-item", {
      attrs: {
        prop: `filter_config.${index}.type`,
        rules: [{
          required: true,
          message: "请选择指标类型",
          trigger: "change"
        }]
      }
    }, [_c("el-select", {
      attrs: {
        placeholder: "请选择指标类型"
      },
      on: {
        change: function ($event) {
          return _vm.changeMetricsType(itemMetrics);
        }
      },
      model: {
        value: itemMetrics.type,
        callback: function ($$v) {
          _vm.$set(itemMetrics, "type", $$v);
        },
        expression: "itemMetrics.type"
      }
    }, _vm._l(_vm.metricsTypeList, function (item) {
      return _c("el-option", {
        key: item.type,
        attrs: {
          label: item.label,
          value: item.type,
          disabled: _vm.keyEventDisabled.includes(item.type)
        }
      });
    }), 1)], 1), _vm.kanbanConfig.filter_config[index].type ? _c("el-form-item", {
      attrs: {
        prop: `filter_config.${index}.key`,
        rules: [{
          required: true,
          message: "请选择指标名",
          trigger: "change"
        }]
      }
    }, [itemMetrics.type === "deal_indicator" ? _c("el-select", {
      attrs: {
        "popper-class": "customer-base-profile-config-metrics-key",
        placeholder: "请选择指标名"
      },
      on: {
        change: function ($event) {
          return _vm.changeMetricsKey(itemMetrics);
        }
      },
      model: {
        value: itemMetrics.key,
        callback: function ($$v) {
          _vm.$set(itemMetrics, "key", $$v);
        },
        expression: "itemMetrics.key"
      }
    }, _vm._l(_vm.customFiled, function (item) {
      return _c("el-option", {
        key: item.field_name,
        attrs: {
          label: item.name,
          value: item.field_name,
          disabled: _vm.disabledArr.includes(item.field_name)
        }
      });
    }), 1) : _c("el-cascader", {
      key: _vm.resetCascaderKey,
      ref: `key-event-cascader-${index}`,
      refInFor: true,
      attrs: {
        "show-all-levels": false,
        options: _vm.cascaderOptions(itemMetrics.type),
        "collapse-tags": "",
        props: {
          expandTrigger: "hover",
          label: "name",
          value: "id",
          emitPath: false,
          multiple: true
        }
      },
      on: {
        change: function ($event) {
          return _vm.changeKeyEvent(itemMetrics, index);
        }
      },
      model: {
        value: itemMetrics.key,
        callback: function ($$v) {
          _vm.$set(itemMetrics, "key", $$v);
        },
        expression: "itemMetrics.key"
      }
    })], 1) : _vm._e(), _vm.isShowDealCustomFilter(itemMetrics) ? _c("el-form-item", {
      attrs: {
        prop: `filter_config.${index}.value`,
        rules: [{
          required: true,
          message: "请选择指标值",
          trigger: "change"
        }]
      }
    }, [_c("deal-custom-filter", {
      attrs: {
        item: _vm.getDealCustomConfig(itemMetrics),
        value: itemMetrics.value
      },
      on: {
        customFilterChange: function ($event) {
          return _vm.customFilterChange($event, itemMetrics);
        }
      }
    })], 1) : _vm._e()], 1), _c("i", {
      staticClass: "el-icon-circle-close delete-btn",
      on: {
        click: function ($event) {
          return _vm.removeMetrics(index);
        }
      }
    })]);
  }), _vm.kanbanConfig.filter_config.length < 10 ? _c("el-button", {
    staticClass: "add-btn",
    on: {
      click: _vm.addMetrics
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" 添加规则")]) : _vm._e()], 2)], 1), _vm.operateType !== "detail" ? _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 保存 ")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;