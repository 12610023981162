"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UploadMediaDialogHead = _interopRequireDefault(require("./UploadMediaDialogHead"));
var _UploadMedia = _interopRequireDefault(require("./UploadMedia"));
var _UploadMediaForm = _interopRequireDefault(require("./UploadMediaForm"));
var _BaseMediaForm = _interopRequireDefault(require("./BaseMediaForm"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UploadMediaDialog',
  components: {
    UploadMediaDialogHead: _UploadMediaDialogHead.default,
    UploadMedia: _UploadMedia.default,
    UploadMediaForm: _UploadMediaForm.default,
    BaseMediaForm: _BaseMediaForm.default
  },
  props: {
    uploadMediaDialogVisible: {
      type: Boolean,
      default: null
    },
    isBase: {
      type: Boolean,
      default: false
    },
    limitCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      mediaFileList: [],
      // 已添加并显示的视频条目
      filesStatus: false // 文件上传状态
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['org_config']),
    audioUploadLimit() {
      var _this$org_config;
      return ((_this$org_config = this.org_config) === null || _this$org_config === void 0 ? void 0 : _this$org_config.audio_upload_limit) / Math.pow(1024, 2) || 500;
    }
  },
  watch: {
    mediaFileList: {
      handler: function (val) {
        this.mediaFileLIst = {
          ...val
        };
      },
      immediate: true
    }
  },
  methods: {
    hostSalesManChange(_ref) {
      let {
        host_salesman_id
      } = _ref;
      if (host_salesman_id) {
        this.$store.dispatch('upload/getUploadDuration', host_salesman_id);
      } else {
        this.$store.commit('upload/SET_UPLOAD_DURATION', {});
      }
    },
    closeUploadMediaDialog() {
      if (this.mediaFileList.length) {
        this.$confirm('确认取消上传？', '提示', {
          confirmButtonText: '取消上传',
          cancelButtonText: '返回',
          type: 'warning',
          confirmButtonClass: 'confirmClass',
          cancelButtonClass: 'cancelClass'
        }).then(() => {
          this.$nextTick(() => {
            this.$refs['upload-media'].handleRemove();
          });
          this.mediaFileList = [];
          this.$emit('closeUploadMediaDialog');
        });
      } else {
        this.$emit('closeUploadMediaDialog');
      }
    },
    updateFileList(item) {
      this.mediaFileList = item;
      this.$forceUpdate();
    },
    removeFile(item, index) {
      this.fileMediaList.splice(index, 1);
      this.finalFlag = this.fileMediaList.some(item => {
        return item.status === 'uploading' || item.text !== 'Success';
      });
      this.$refs['upload'].handleRemove(index);
    },
    abandonMediaForm() {
      if (this.mediaFileList.length > 0) {
        this.$confirm('确认取消上传？', '提示', {
          confirmButtonText: '取消上传',
          cancelButtonText: '返回',
          type: 'warning',
          confirmButtonClass: 'confirmClass',
          cancelButtonClass: 'cancelClass'
        }).then(_ => {
          this.$nextTick(() => {
            this.$bus.$emit('abortUpload');
          });
          this.closeMediaDialog();
        }).catch(_ => {});
      } else {
        this.closeMediaDialog();
      }
    },
    closeMediaDialog() {
      this.$emit('closeUploadMediaDialog');
      this.mediaFileList = [];
    },
    handleBaseSubmitSucceed() {
      this.$message.success('上传成功');
      this.closeMediaDialog();
    }
  }
};
exports.default = _default;