"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
let prevOverflow = '';
var _default = {
  name: 'ElPopover',
  extends: _elementUi.Image,
  props: {
    initialIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageIndex() {
      if (this.initialIndex >= 0) {
        return this.initialIndex;
      }
      let previewIndex = 0;
      const srcIndex = this.previewSrcList.indexOf(this.src);
      if (srcIndex >= 0) {
        previewIndex = srcIndex;
      }
      return previewIndex;
    }
  },
  methods: {
    clickHandler() {
      // don't show viewer when preview is false
      if (!this.preview) {
        return;
      }
      // prevent body scroll
      prevOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      this.showViewer = true;
      if (this.appendToBody) {
        this.$nextTick(() => {
          document.body.appendChild(this.$refs.imageViewer.$el);
        });
      }
    }
  }
};
exports.default = _default;