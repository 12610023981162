"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SetPublicScope = _interopRequireDefault(require("./SetPublicScope.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SetPublicScopeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submitEditPublicScope'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const loading = (0, _vue.ref)(false);
    const closeDialog = () => {
      emit('update:visible', false);
      loading.value = false;
    };
    const SetPublicScopeRef = (0, _vue.ref)();
    const handleSubmit = () => {
      loading.value = true;
      const {
        isPublic,
        assignee
      } = SetPublicScopeRef.value;
      emit('submitEditPublicScope', {
        isPublic,
        assignee
      }, () => {
        loading.value = false;
        closeDialog();
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      loading,
      closeDialog,
      SetPublicScopeRef,
      handleSubmit,
      SetPublicScope: _SetPublicScope.default
    };
  }
};
exports.default = _default;