"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "second-step"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("监测指标")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editDisabled && _vm.tableData.length < 20,
      expression: "!editDisabled && tableData.length < 20"
    }],
    staticClass: "add-config",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addConfig
    }
  }, [_vm._v(" 添加指标 ")])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    }
  }, _vm._l(_vm.headerList, function (_ref) {
    let {
      prop,
      field_name
    } = _ref;
    return _c("el-table-column", {
      key: prop,
      attrs: {
        prop: prop,
        label: field_name,
        "show-overflow-tooltip": ""
      },
      scopedSlots: _vm._u([prop === "operate" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row,
            $index
          } = _ref2;
          return [_c("div", {
            staticClass: "operate-btns"
          }, [_c("el-button", {
            attrs: {
              type: "text",
              disabled: _vm.editDisabled
            },
            on: {
              click: function ($event) {
                return _vm.editConfig(row, $index);
              }
            }
          }, [_vm._v("编辑")]), _c("el-button", {
            staticClass: "delete-btn",
            attrs: {
              type: "text",
              disabled: _vm.editDisabled
            },
            on: {
              click: function ($event) {
                return _vm.deleteConfig($index);
              }
            }
          }, [_vm._v("删除")])], 1)];
        }
      } : null], null, true)
    });
  }), 1), _c("config-dialog", {
    attrs: {
      "is-edit": _vm.isEdit,
      visible: _vm.configDialogvisible,
      "edit-config-data": _vm.editConfigData
    },
    on: {
      "update:visible": function ($event) {
        _vm.configDialogvisible = $event;
      },
      handleConfig: _vm.handleConfig
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;