"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AmountRangeInput = _interopRequireDefault(require("@/components/AmountRange/AmountRangeInput"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceScheduleFilter',
  components: {
    AmountRangeInput: _AmountRangeInput.default
  },
  data() {
    return {
      process: [],
      localValue: ''
    };
  },
  watch: {
    process: {
      handler: function (val) {
        if (!val || !val.length) {
          this.localValue = '';
        } else {
          const min = val[0];
          const max = val[1];
          if (min !== -1 && max !== -1) {
            this.localValue = `${min}% - ${max}%`;
          } else if (min !== -1 && max === -1) {
            if (min === 0) {
              this.localValue = '';
            } else {
              this.localValue = `大于等于 ${min}%`;
            }
          } else if (max !== -1 && min === -1) {
            if (max === 0) {
              this.localValue = `等于 ${max}%`;
            } else {
              this.localValue = `小于等于 ${max}%`;
            }
          }
        }
        this.$emit('change', val);
      }
    }
  },
  methods: {
    processChange(value) {
      this.process = value;
    },
    handleClear() {
      this.process = [];
    }
  }
};
exports.default = _default;