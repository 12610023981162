"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _SaveTimeTip = _interopRequireDefault(require("@/components/NoteEditor/components/SaveTimeTip"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CallNote',
  components: {
    NoteEditor: _NoteEditor.default,
    SaveTimeTip: _SaveTimeTip.default
  },
  directives: {
    stopdrag: {
      inserted: function (el, binding, vnode) {
        const element = el;
        element.onmousedown = function (e) {
          e.stopPropagation();
        };
      }
    }
  },
  props: {
    title: String,
    time: {
      type: String,
      default: '00:00'
    },
    customerName: {
      type: String,
      default: ''
    },
    conversation_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      bodyHeight: null,
      noteSaveTime: ''
    };
  },
  computed: {
    getWrapperHeight() {
      if (this.bodyHeight && this.bodyHeight < 680) {
        return this.bodyHeight + 'px';
      } else {
        return '680px';
      }
    }
  },
  mounted() {
    this.bodyHeight = document.body.offsetHeight;
  },
  methods: {
    handleNoteSaved(time) {
      this.noteSaveTime = time;
    },
    closeNote() {
      this.$store.commit('call/SET_NOTE_VISIBLE', false);
    }
  }
};
exports.default = _default;