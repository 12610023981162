"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("figure", {
    class: ["snippet-list-card", {
      "prepare-snippet-list-card": _vm.snippet.status !== "complete"
    }]
  }, [_vm.showCheckbox ? _c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.checkChange($event, _vm.snippet);
      }
    },
    model: {
      value: _vm.snippet.checked,
      callback: function ($$v) {
        _vm.$set(_vm.snippet, "checked", $$v);
      },
      expression: "snippet.checked"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "img-container",
    on: {
      click: _vm.goSnippetDetail
    }
  }, [_vm.snippet.status === "complete" ? [_vm.snippet.type === "video" && _vm.snippet.thumbnail ? _c("el-image", {
    attrs: {
      src: _vm.snippet.thumbnail,
      fit: "fill"
    }
  }) : _vm._e(), _vm.snippet.type === "video" && !_vm.snippet.thumbnail ? _c("div", {
    staticClass: "no-img-content"
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera"
  })]) : _vm._e(), _vm.snippet.type === "audio" ? _c("div", {
    staticClass: "no-img-content"
  }, [_c("i", {
    staticClass: "iconfont icon-phone-outline"
  })]) : _vm._e(), _vm.snippet.type === "doc" ? _c("div", {
    staticClass: "no-img-content"
  }, [_c("i", {
    staticClass: "iconfont icon-im"
  })]) : _vm._e()] : _vm._e(), _vm.snippet.status !== "complete" ? [_c("div", {
    staticClass: "no-img-content"
  }, [_c("i", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.snippet.status !== "complete",
      expression: "snippet.status !== 'complete'"
    }],
    staticClass: "loading"
  })])] : _vm._e(), _vm.snippet.type !== "doc" ? _c("div", {
    staticClass: "absolute-time",
    domProps: {
      innerHTML: _vm._s(_vm.getDuration(_vm.snippet.duration))
    }
  }) : _vm._e()], 2), _c("figcaption", [_c("div", {
    staticClass: "clip-name"
  }, [_c("div", {
    class: _vm.snippet.status !== "complete" ? "prepare" : "already",
    on: {
      click: _vm.goSnippetDetail
    }
  }, [_vm._v(" " + _vm._s(_vm.snippet.name) + " ")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.snippet.status !== "complete",
      expression: "snippet.status !== 'complete'"
    }],
    staticClass: "clip-process"
  }, [_vm._v(" 剪辑生成中... ")])]), _c("div", {
    staticClass: "row"
  }, [_c("hot-data", {
    attrs: {
      info: _vm.snippet,
      type: "snippet"
    }
  }), _c("p", {
    staticClass: "create-info"
  }, [_vm.snippet.origin_user && _vm.snippet.origin_user.name ? _c("span", [_vm._v("会话来源 "), _c("span", {
    staticClass: "snippet-name"
  }, [_vm._v(_vm._s(_vm.snippet.origin_user.name))])]) : _vm._e(), _c("span", [_vm._v("创建者 "), _c("span", {
    staticClass: "snippet-name"
  }, [_vm._v(_vm._s(_vm.snippet.create_user.name))])]), _c("span", [_vm._v("创建日期 "), _c("span", {
    staticClass: "snippet-name"
  }, [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.snippet.create_at).format("YYYY-MM-DD")))])])])], 1), _vm.snippet.description ? _c("div", {
    staticClass: "abstract-row"
  }, [_c("div", {
    staticClass: "abstract"
  }, [_c("i", {
    staticClass: "iconfont icon-document"
  }), _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.snippet.description,
      "popper-class": "description-tooltip"
    }
  }, [_c("span", {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.snippet.description))])])], 1)]) : _vm._e()]), !_vm.readonly ? _c("el-dropdown", {
    staticClass: "snippet-more",
    attrs: {
      trigger: "click",
      placement: "bottom-end",
      "append-to-body": false
    },
    on: {
      command: function ($event) {
        return _vm.handleCommand($event, _vm.snippet);
      }
    }
  }, [_vm._t("default", function () {
    return [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: (_vm.btnVisible || _vm.getContentType() === "collect") && (_vm.snippet.status === "complete" || _vm.snippet.isMedia),
        expression: "\n          (btnVisible || getContentType() === 'collect') &&\n          (snippet.status === 'complete' || snippet.isMedia)\n        "
      }],
      staticClass: "operate-btn"
    }, [_c("i", {
      staticClass: "iconfont icon-more"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "snippets-library-dropdown-menu-common",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.getContentType() === "collect" ? _c("el-dropdown-item", {
    attrs: {
      command: "discollect"
    }
  }, [_vm._v("取消收藏")]) : _c("div", [_vm.interfacePermission.assignSnippetStudyTask ? [_c("el-dropdown-item", {
    attrs: {
      command: "assignSnippetTask"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-user-assign"
  }), _vm._v("指派学习")]), _c("el-divider")] : _vm._e(), _vm.interfacePermission.moveSnippetToFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "moveSnippetToFolder"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-folder-move"
  }), _vm._v("移动到文件夹")]) : _vm._e(), _c("el-divider"), _vm.interfacePermission.editSnippet ? _c("el-dropdown-item", {
    attrs: {
      command: "editSnippet"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit"
  }), _vm._v("编辑")]) : _vm._e(), _vm.interfacePermission.deleteSnippet ? _c("el-dropdown-item", {
    staticClass: "dropdown-item-danger",
    attrs: {
      command: "deteleSnippet"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-delete"
  }), _vm._v(_vm._s(_vm.$t("coaching.delete")))]) : _vm._e()], 2)], 1)], 2) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;