"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "el-select-dropdown el-popper",
    class: [{
      "is-multiple": _vm.$parent.multiple
    }, _vm.popperClass],
    style: {
      minWidth: _vm.minWidth
    }
  }, [_vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;