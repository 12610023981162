"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ComplianceStatusTag',
  props: {
    status: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getComplianceStatus(status) {
      return ['待质检', '已质检', '已驳回'][status - 1];
    },
    getStatusClassName(status) {
      return ['no-check', 'is-check', 'rejected'][status - 1];
    },
    getIconfont(status) {
      return ['icon-time', 'icon-circle-check', 'icon-circle-close'][status - 1];
    }
  }
};
exports.default = _default;