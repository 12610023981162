"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ConstMap = require("@/components/Message/components/ConstMap.js");
var _default = {
  name: 'LinkTags',
  components: {},
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    getIconName(type) {
      return 'icon-' + _ConstMap.ICONMAP[type];
    },
    goLinkPage(item) {
      const path = _routeMap.ROUTES[item.router_name];
      const query = item.router_params;
      const routeData = this.$router.resolve({
        path,
        query
      });
      this.$emit('updateReadStatus');
      window.open(routeData.href, '_blank');
    }
  }
};
exports.default = _default;