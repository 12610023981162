"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ShortcutKeysDrawer',
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatShortcutKeysList() {
      const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      const commandOrCtrl = `${isMac ? 'command' : 'ctrl'}.svg`;
      const shortcutKeysConfigs = [{
        label: 'coaching.moveCanvas',
        description: 'coaching.moveCanvasTip',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/arrow.svg')
        }]
      }, {
        label: 'coaching.zoomCanvas',
        description: 'coaching.zoomCanvasTip',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/' + commandOrCtrl)
        }, {
          type: 'img',
          data: require('@/assets/coach/mouse.svg')
        }]
      }, {
        label: 'coaching.boxSelection',
        description: 'coaching.boxSelectionTip',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/shift.svg')
        }, {
          type: 'img',
          data: require('@/assets/coach/mouse.svg')
        }]
      }, {
        label: 'coaching.copy',
        description: 'Control + C',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/' + commandOrCtrl)
        }, {
          type: 'text',
          data: 'C'
        }]
      }, {
        label: 'coaching.paste',
        description: 'Control + V',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/' + commandOrCtrl)
        }, {
          type: 'text',
          data: 'V'
        }]
      }, {
        label: 'coaching.delete',
        description: 'coaching.deleteTip',
        icons: [{
          type: 'text',
          data: 'Backspace'
        }]
      }, {
        label: 'coaching.undo',
        description: 'Control + Z',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/' + commandOrCtrl)
        }, {
          type: 'text',
          data: 'Z'
        }]
      }, {
        label: 'coaching.redo',
        description: 'Control + Shift + Z',
        icons: [{
          type: 'img',
          data: require('@/assets/coach/' + commandOrCtrl)
        }, {
          type: 'img',
          data: require('@/assets/coach/shift.svg')
        }, {
          type: 'text',
          data: 'Z'
        }]
      }];
      return shortcutKeysConfigs.map(_ref => {
        let {
          label,
          description,
          icons
        } = _ref;
        return {
          label,
          description: isMac ? description.replace('Control', 'Command') : description,
          icons: icons.reverse()
        };
      });
    }
  }
};
exports.default = _default;