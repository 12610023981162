"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MemberInfo',
  props: {
    type: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    account: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    title() {
      const titleList = {
        get: '成员账号及密码',
        reset: '重置成功'
      };
      return titleList[this.type];
    },
    getCopycopyContent() {
      if (this.type === 'get') {
        return `登录账号：${this.account}
密码：${this.password}`;
      } else {
        return this.password;
      }
    }
  },
  mounted() {},
  methods: {
    onCopy() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
      this.closeSelf();
    },
    closeSelf() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;