"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["setting-drawer", {
      show: _vm.drawerVisible
    }]
  }, [_c("header", [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.courseSettings")))]), _c("el-button", {
    staticClass: "close-drawer hover-show-bg",
    attrs: {
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeDrawer
    }
  })], 1), _c("article", [_c("div", {
    staticClass: "practice-mode"
  }, [_c("div", {
    staticClass: "set-label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.practiceMode")))]), _c("el-radio-group", {
    staticClass: "radio-group",
    on: {
      change: function ($event) {
        _vm.updateSmartTrainingSet(_vm.$t("coaching.switchoverSuccessful"));
      }
    },
    model: {
      value: _vm.practiceRule.practice_mode,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "practice_mode", $$v);
      },
      expression: "practiceRule.practice_mode"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.voiceMode")))]), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.textMode")))])], 1)], 1), _c("div", {
    staticClass: "training-set"
  }, [_c("div", [_c("div", {
    staticClass: "set-label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.scriptPrompt")))]), _c("div", {
    staticClass: "set-tip"
  }, [_vm._v(_vm._s(_vm.$t("coaching.scriptPromptTip")))])]), _c("el-switch", {
    attrs: {
      "active-color": _vm.variables.active,
      "inactive-color": _vm.variables.inactive
    },
    on: {
      change: function ($event) {
        _vm.updateSmartTrainingSet(_vm.$t($event ? "coaching.voicePromptIsTurnedOn" : "coaching.voicePromptIsTurnedOff"));
      }
    },
    model: {
      value: _vm.practiceRule.access_note,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "access_note", $$v);
      },
      expression: "practiceRule.access_note"
    }
  })], 1), _c("div", {
    staticClass: "set-score"
  }, [_c("div", {
    staticClass: "set-label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.courseScore")))]), _c("p", [_vm._v(_vm._s(_vm.$t("coaching.courseTotalScoreCalculationRule")))]), _c("el-radio-group", {
    staticClass: "radio-group",
    on: {
      change: function ($event) {
        _vm.updateSmartTrainingSet(_vm.$t("coaching.switchoverSuccessful"));
      }
    },
    model: {
      value: _vm.practiceRule.summary_score_type,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "summary_score_type", $$v);
      },
      expression: "practiceRule.summary_score_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.theAverageScoreOfEachSentence")))]), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.theSumOfPointsForEachSentence")))])], 1), _c("p", {
    staticClass: "passing-score-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.passingScore")) + " "), _c("el-tooltip", {
    attrs: {
      content: _vm.$t("coaching.scoreReminder"),
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "practice-rule"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.singleSentencePassMark")))]), _vm._v(": "), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.theProportionOfTheSentenceFullMarks")))]), _c("div", {
    staticClass: "input-container"
  }, [_c("el-input-number", {
    attrs: {
      controls: false,
      min: 1,
      max: 100,
      "step-strictly": "",
      step: 0.1,
      maxlength: "4"
    },
    on: {
      change: function ($event) {
        return _vm.updateSmartTrainingSet();
      }
    },
    model: {
      value: _vm.practiceRule.sentence_pass_rate,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "sentence_pass_rate", $$v);
      },
      expression: "practiceRule.sentence_pass_rate"
    }
  }), _c("i", {
    staticClass: "sufix-icon"
  }, [_vm._v("%")])], 1)]), _c("div", {
    staticClass: "practice-rule"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.courseTotalPassingMark")))]), _vm._v(": "), _c("div", {
    staticClass: "input-container"
  }, [_c("el-input-number", {
    attrs: {
      controls: false,
      min: 1,
      max: 9999,
      "step-strictly": "",
      step: 1,
      maxlength: "3"
    },
    on: {
      change: function ($event) {
        return _vm.updateSmartTrainingSet();
      }
    },
    model: {
      value: _vm.practiceRule.score,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "score", $$v);
      },
      expression: "practiceRule.score"
    }
  }), _c("i", {
    staticClass: "sufix-icon"
  }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])], 1)])], 1), _vm.interfacePermission.getLanguageList ? _c("div", {
    staticClass: "model-language"
  }, [_c("div", {
    staticClass: "set-label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.modelLanguage")))]), _c("div", {
    staticClass: "set-tip"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.modelLanguageTip")) + " ")]), _c("el-select", {
    attrs: {
      placeholder: _vm.$t("coaching.chooseLanguagePlaceholder"),
      "popper-class": "smart-training-language-list-select-dropdown"
    },
    on: {
      change: function ($event) {
        _vm.updateSmartTrainingSet(_vm.$t("coaching.switchoverSuccessful"));
      }
    },
    model: {
      value: _vm.practiceRule.language,
      callback: function ($$v) {
        _vm.$set(_vm.practiceRule, "language", $$v);
      },
      expression: "practiceRule.language"
    }
  }, _vm._l(_vm.languageList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: _vm.$t(item.label),
        value: item.value
      }
    });
  }), 1)], 1) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;