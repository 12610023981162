"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _initCardPreview = _interopRequireDefault(require("./initCardPreview"));
var _CardText = _interopRequireDefault(require("@/components/Charts/CardText"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PreviewChart',
  components: {
    initCardPreview: _initCardPreview.default,
    CardText: _CardText.default,
    ChartTemplate: _ChartTemplate.default
  },
  props: {
    previewChartData: {
      type: Object,
      default: () => ({})
    },
    initCardPreviewConfig: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updatePreview() {
      this.$emit('updatePreview');
    }
  }
};
exports.default = _default;