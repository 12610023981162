"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-404"
  }, [_c("div", {
    staticClass: "page-404-content"
  }, [_vm.$config.isMegaview ? _c("i", {
    staticClass: "iconfont icon-megaview"
  }) : _vm._e(), _c("h1", [_vm._v("页面找不到了")]), _c("p", [_vm._v(" 您可能没有该页面的访问权限，或者网址输入错误，或者该页面的地址已更新。 ")]), _c("el-button", {
    staticClass: "return-home-button",
    attrs: {
      type: "primary",
      icon: "iconfont icon-home2"
    },
    on: {
      click: _vm.goHome
    }
  }, [_vm._v("返回首页")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;