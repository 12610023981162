"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _chartConfig = _interopRequireDefault(require("./components/chartConfig"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _personalAbilityInsightTable = _interopRequireDefault(require("./components/personalAbilityInsightTable"));
var _contrastGrowthOrDecrease = _interopRequireDefault(require("./components/contrastGrowthOrDecrease"));
var _formatDataUnit = require("@/components/Charts/formatDataUnit.js");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const baseConfig = [{
  name: '个人',
  color: '#3393FF',
  value: [],
  level: []
}, {
  name: '本部门平均',
  color: '#5CD6A3',
  value: [],
  level: []
}, {
  name: '全公司平均',
  color: '#FFB433',
  value: [],
  level: []
}];
var _default = {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Overview',
  components: {
    ChartConfig: _chartConfig.default,
    ChartTemplate: _ChartTemplate.default,
    PersonalAbilityInsightTable: _personalAbilityInsightTable.default,
    ContrastGrowthOrDecrease: _contrastGrowthOrDecrease.default
  },
  data() {
    return {
      chartConfigVisible: false,
      personalAbilityLoading: false,
      personalAbilityTrendLoading: false,
      requestParams: {},
      radarOptions: {},
      level: '',
      abilityList: [],
      radarDatas: [],
      radarLevelConfig: [],
      lineChartLoading: false,
      lineDatas: [],
      lineOptions: {},
      navMenuList: [],
      activeNavMenuId: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biPersonalFilterTime', 'workspaceInfo', 'timezone']),
    getUrlParams() {
      return this.$route.query;
    },
    getUnitConfig() {
      const config = this.navMenuList.find(_ref => {
        let {
          id
        } = _ref;
        return id === this.activeNavMenuId;
      }).config;
      const {
        analysis_target,
        analysis_target_key,
        dimension
      } = JSON.parse(config);
      return {
        analysis_target,
        analysis_target_key,
        draw_style: dimension || 'count'
      };
    }
  },
  watch: {
    biPersonalFilterTime() {
      // 参数直接放到请求函数里面再获取
      this.paramsChange();
    },
    'workspaceInfo.id': {
      handler: function () {
        this.paramsChange();
      }
    }
  },
  methods: {
    paramsChange() {
      if (!this.workspaceInfo.id || !this.biPersonalFilterTime) return;
      this.requestParams = {
        date: this.biPersonalFilterTime,
        user_id: Number(this.getUrlParams.id),
        workspace_id: this.workspaceInfo.id
      };
      this.getPersonalAbilityData();
      this.getPersonalAbilityTrendConfig();
    },
    changeMenu(id) {
      this.activeNavMenuId = id;
      this.getPersonalAbilityTrendData();
    },
    async getPersonalAbilityData() {
      this.personalAbilityLoading = true;
      const requestParams = (0, _commonFunc.deepClone)(this.requestParams);
      (0, _timezone.convertParams2UTCplus8)(requestParams);
      requestParams.time_zone = this.timezone;
      try {
        const res = await (0, _businessIntelligence.getPersonalAbilityData)(requestParams);
        this.abilityList = [];
        if (res.code === 20000) {
          this.radarLevelConfig = res.results.level_config;
          this.level = this.radarLevelConfig[this.radarLevelConfig.length - res.results.level].name;
          this.radarDatas = (0, _commonFunc.deepClone)(baseConfig);
          res.results.data.forEach(_ref2 => {
            let {
              name,
              person,
              tree_avg,
              organization_avg,
              person_level,
              tree_level,
              organization_level
            } = _ref2;
            this.abilityList.push(name);
            [person, tree_avg, organization_avg].forEach((item, index) => {
              this.radarDatas[index].value.push(item);
            });
            [person_level, tree_level, organization_level].forEach((item, index) => {
              this.radarDatas[index].level.push(item);
            });
          });
          this.radarOptions = this.setRadarOptions();
        }
      } finally {
        this.personalAbilityLoading = false;
      }
    },
    async getPersonalAbilityTrendConfig() {
      this.personalAbilityTrendLoading = true;
      this.navMenuList = [];
      const requestParams = (0, _commonFunc.deepClone)(this.requestParams);
      (0, _timezone.convertParams2UTCplus8)(requestParams);
      requestParams.time_zone = this.timezone;
      const kanbanId = await this.getKanbanId();
      if (!kanbanId) {
        this.personalAbilityTrendLoading = false;
        return;
      }
      try {
        const res = await (0, _businessIntelligence.getPersonalAbilityTrendConfig)({
          ...requestParams,
          kanban_id: kanbanId
        });
        if (res.code === 20000) {
          this.navMenuList = res.results.list;
          if (!this.navMenuList.length) return;
          this.changeMenu(this.navMenuList[0].id);
        }
      } finally {
        this.personalAbilityTrendLoading = false;
      }
    },
    async getKanbanId() {
      if (this.$route.query.kanban_id) {
        return Number(this.$route.query.kanban_id);
      } else {
        const res = await (0, _businessIntelligence.getTeamAnalysisKanbanList)({
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          var _res$results$data$;
          return (_res$results$data$ = res.results.data[0]) === null || _res$results$data$ === void 0 ? void 0 : _res$results$data$.kanban_id;
        }
      }
    },
    async getPersonalAbilityTrendData() {
      this.lineChartLoading = true;
      const requestParams = (0, _commonFunc.deepClone)(this.requestParams);
      (0, _timezone.convertParams2UTCplus8)(requestParams);
      try {
        const res = await (0, _businessIntelligence.getPersonalAbilityTrendData)({
          ...requestParams,
          id: this.activeNavMenuId,
          time_zone: this.timezone
        });
        if (res.code === 20000) {
          this.lineDatas = (0, _commonFunc.deepClone)(baseConfig);
          res.results.list.forEach(_ref3 => {
            let {
              date,
              person,
              department,
              organization
            } = _ref3;
            [person, department, organization].forEach((item, index) => {
              this.lineDatas[index].value.push([date, item]);
            });
          });
          this.lineOptions = this.seLinetOptions();
        }
      } finally {
        this.lineChartLoading = false;
      }
    },
    setRadarOptions() {
      if (!this.abilityList.length) return {};
      return {
        color: this.radarDatas.map(_ref4 => {
          let {
            color
          } = _ref4;
          return color;
        }),
        title: [{
          text: '整体等级：',
          textStyle: {
            fontSize: 14,
            color: '#464646'
          }
        }, {
          text: this.level,
          textStyle: {
            fontSize: 18,
            color: '#464646'
          },
          left: '70px'
        }],
        tooltip: {
          appendToBody: true,
          formatter: _ref5 => {
            let {
              name,
              value,
              marker
            } = _ref5;
            return `<h4 style="font-weight: bold;">${name}能力等级</h4>` + value.reduce((pre, current, index) => {
              return pre + `<p style="display: flex;justify-content: space-between;">
                    <span>${marker} ${this.abilityList[index]}</span>
                    <span style="font-weight: bold;padding-left: 30px;">${this.radarLevelConfig[this.radarLevelConfig.length - current].name}</span>
                  </p>`;
            }, '');
          }
        },
        legend: {
          right: '0'
        },
        radar: [{
          indicator: this.abilityList.map(item => {
            return {
              text: item,
              max: this.radarLevelConfig.length
            };
          }),
          radius: 100,
          center: ['50%', '60%'],
          axisName: {
            color: '#576575'
          }
        }],
        series: {
          type: 'radar',
          data: this.radarDatas.map(_ref6 => {
            let {
              name,
              color,
              level
            } = _ref6;
            return {
              value: level,
              name,
              areaStyle: {
                color,
                opacity: 0.2
              },
              lineStyle: {
                color
              }
            };
          })
        }
      };
    },
    seLinetOptions() {
      if (!this.lineDatas.length) return;
      return {
        grid: {
          containLabel: true,
          left: 30,
          right: 30,
          top: '15%',
          bottom: 80
        },
        dataZoom: {},
        color: this.lineDatas.map(_ref7 => {
          let {
            color
          } = _ref7;
          return color;
        }),
        tooltip: {
          trigger: 'axis',
          // 将tooltip框限制在图表区域内
          confine: true,
          appendToBody: true,
          formatter: params => {
            return `<h4 style="font-weight: bold;">${this.$moment(params[0].axisValue).format('YYYY-MM-DD')}</h4>` + params.reduce((pre, _ref8) => {
              let {
                marker,
                seriesName,
                value
              } = _ref8;
              return pre + formatUnit(`<span>${marker} ${seriesName}</span>`, value[1], this.getUnitConfig);
            }, '');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          axisLabel: {
            formatter: val => {
              return this.$moment(val).format('YYYY-MM-DD');
            }
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: this.lineDatas.map(_ref9 => {
          let {
            name,
            value
          } = _ref9;
          return {
            name,
            type: 'line',
            encode: {
              x: 0,
              y: 1
            },
            data: value
          };
        })
      };
    }
  }
};
exports.default = _default;
function formatUnit(label, data, unitConfig) {
  return `<p style="display: flex; justify-content: space-between;">${label}<span style="margin-left: 30px; font-weight: bold;">${(0, _formatDataUnit.formatDataUnit)(unitConfig, data)}</span></p>`;
}