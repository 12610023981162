"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _TricksLibraryFilter = _interopRequireDefault(require("./TricksLibraryFilter.vue"));
var _TricksLibraryConvCard = _interopRequireDefault(require("./TricksLibraryConvCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _commonFunc = require("@/utils/commonFunc");
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TricksLibrary',
  components: {
    TricksLibraryFilter: _TricksLibraryFilter.default,
    TricksLibraryConvCard: _TricksLibraryConvCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    NoData: _index.default,
    SortCaret: _sortCaret.default
  },
  props: {
    knowledgeId: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      convList: [],
      addedList: [],
      // 添加到标准话术的列表（前端暂存的状态）
      originAddedList: [],
      // 已添加过的列表（服务端返回的）
      page: 1,
      total: 0,
      loading: false,
      noMore: false,
      filters: {},
      layout: [{
        type: 'tree_user_ids',
        isPreventNotSelected: false
      }, {
        type: 'date',
        tooltip: '会话时间'
      }],
      time_sort: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    queryParams() {
      return {
        ...this.filters,
        knowledge_id: this.knowledgeId,
        space_id: this.workspaceInfo.id,
        page: this.page,
        size: 10,
        time_sort: this.time_sort
      };
    }
  },
  mounted() {
    this.loading = true;
  },
  methods: {
    sortDate(val) {
      const map = {
        ascending: 'asc',
        descending: 'des'
      };
      this.time_sort = map[val];
      this.freshList();
    },
    freshList() {
      this.initData();
      this.getConvList();
    },
    getConvList: (0, _commonFunc.throttle)(async function () {
      this.loading = true;
      const res = await (0, _knowledgeLibrary.getConvList)(this.queryParams).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          total,
          cases
        } = res.results;
        this.total = total;
        if (cases.length) {
          const addedList = cases.filter(i => i.is_add);
          this.originAddedList.push(...addedList);
          this.convList.push(...cases);
        }
        if (this.convList.length === this.total) {
          this.noMore = true;
        }
        this.page++;
      }
    }, 300),
    loadMore() {
      this.getConvList();
    },
    handleFiltersChange(val) {
      this.filters = val;
      this.loading = true;
      this.freshList();
    },
    handleAddConv(index) {
      const convItem = this.convList[index];
      convItem.value = convItem.replys.filter(i => i.type === 'answer').reduce((a, b) => {
        return a + ' ' + b.content;
      }, '');
      this.addedList.push(convItem);
      this.$emit('listChange', this.addedList);
    },
    initData() {
      this.noMore = false;
      this.page = 1;
      this.convList = [];
      this.originAddedList = [];
      this.addedList = [];
    }
  }
};
exports.default = _default;