"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-filter-group"
  }, [_c("MgvMemberMultiSelect", {
    staticClass: "member-filter",
    attrs: {
      placeholder: "质检员",
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.getUserList,
      "request-search-params": {
        module_type: "compliance"
      },
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["compliance"])
      }
    },
    on: {
      change: _vm.departmentMultipleChange
    }
  }), _c("ComplianceScheduleFilter", {
    on: {
      change: _vm.changeScheduleFilter
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;