"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["talk-config-drawer", {
      show: _vm.drawerVisible
    }]
  }, [_c("header", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEditRoleName,
      expression: "!isEditRoleName"
    }],
    staticClass: "role-name"
  }, [_c("MgAutoTooltip", {
    staticClass: "node-title",
    attrs: {
      content: _vm.roleConfig.roleName
    }
  }), _c("i", {
    staticClass: "iconfont icon-edit-outline",
    on: {
      click: _vm.editRoleName
    }
  })], 1), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditRoleName,
      expression: "isEditRoleName"
    }],
    ref: "role-name-input",
    attrs: {
      maxlength: "30",
      placeholder: _vm.$t("coaching.roleNamePlaceholder")
    },
    on: {
      blur: _vm.updateRoleConfigData
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.updateRoleConfigData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.roleConfig.roleName,
      callback: function ($$v) {
        _vm.$set(_vm.roleConfig, "roleName", $$v);
      },
      expression: "roleConfig.roleName"
    }
  }), _c("el-button", {
    staticClass: "close-drawer hover-show-bg",
    attrs: {
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("update:drawerVisible", false);
      }
    }
  })], 1), _c("article", [_c("span", {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.$t(_vm.getRoleConfig.description)))]), _c("div", {
    ref: "cardContainerRef",
    staticClass: "card-container"
  }, _vm._l(_vm.roleConfig.branches, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "talk-card-container"
    }, [_c("div", {
      staticClass: "talk-card"
    }, [_c("div", {
      staticClass: "talk-card-head"
    }, [_vm.getRoleConfig.icon ? _c("i", {
      class: ["iconfont", _vm.getRoleConfig.icon]
    }) : _c("mgv-avatar-name", {
      attrs: {
        size: 28,
        src: require("@/assets/coach/mai-mai-avatar.png")
      }
    }), _c("span", [_vm._v(_vm._s(_vm.$t(_vm.roleName)))]), _vm.canUploadPic ? _c("PicUploadButton", {
      ref: "pic-upload-button",
      refInFor: true,
      attrs: {
        type: "smartpractice",
        disabled: item.images && item.images.length > 2,
        "pic-list": item.images
      },
      on: {
        "update:picList": function ($event) {
          return _vm.$set(item, "images", $event);
        },
        "update:pic-list": function ($event) {
          return _vm.$set(item, "images", $event);
        },
        change: _vm.updateRoleConfigData
      }
    }) : _vm._e(), index || _vm.roleConfig.branches.length > 1 ? _c("MgvButton", {
      attrs: {
        icon: "icon-delete",
        type: "text",
        square: "",
        size: "small",
        color: "info transparent"
      },
      on: {
        click: function ($event) {
          return _vm.deleteTalk(index);
        }
      }
    }) : _vm._e(), _vm.roleConfig.role === "salesman" ? _c("el-button", {
      directives: [{
        name: "api-permission",
        rawName: "v-api-permission",
        value: ["/webapi/training/knowledge/list"],
        expression: "['/webapi/training/knowledge/list']"
      }],
      staticClass: "search-script-btn",
      attrs: {
        type: "text"
      },
      on: {
        click: _vm.searchScript
      }
    }, [_vm._v(_vm._s(_vm.$t("coaching.searchScript")) + " ")]) : _vm._e()], 1), _c("el-input", {
      ref: "text-textarea",
      refInFor: true,
      attrs: {
        "data-refindex": index,
        type: "textarea",
        placeholder: _vm.$t(_vm.getRoleConfig.branchePlaceholder),
        rows: 5,
        "show-word-limit": ""
      },
      on: {
        input: _vm.updateRoleConfigData
      },
      nativeOn: {
        "!keyup": function ($event) {
          return _vm.keywordInput(index);
        }
      },
      model: {
        value: _vm.roleConfig.branches[index].text,
        callback: function ($$v) {
          _vm.$set(_vm.roleConfig.branches[index], "text", $$v);
        },
        expression: "roleConfig.branches[index].text"
      }
    }), _vm.canUploadPic && item.images ? _c("PicDisplay", {
      attrs: {
        "pic-list": item.images,
        "append-to-body": ""
      },
      on: {
        deletePic: function ($event) {
          return _vm.deletePic($event, item);
        }
      }
    }) : _vm._e()], 1), _vm.roleConfig.role === "customer" && _vm.roleConfig.branches.length > 1 ? _c("div", {
      staticClass: "sales-weight"
    }, [_vm._v(" " + _vm._s(_vm.$t("coaching.weight")) + " "), _c("el-input-number", {
      attrs: {
        min: 1,
        max: 100,
        step: 1,
        size: "mini",
        "step-strictly": ""
      },
      on: {
        input: function ($event) {
          return _vm.onWeightInputNumberInput($event, index);
        }
      },
      model: {
        value: _vm.roleConfig.branches[index].weight,
        callback: function ($$v) {
          _vm.$set(_vm.roleConfig.branches[index], "weight", $$v);
        },
        expression: "roleConfig.branches[index].weight"
      }
    }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.probability")) + _vm._s(_vm.getBranchRatio(index)))])], 1) : _vm._e()]);
  }), 0), _vm.roleConfig.role === "customer" && _vm.roleConfig.branches.length < 5 ? _c("el-button", {
    staticClass: "add-talk",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addTalk
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.addScript")))]) : _vm._e(), _vm.roleConfig.role === "salesman" ? [_c("ScoreRuleConfig", {
    attrs: {
      config: _vm.config,
      visible: _vm.drawerVisible
    },
    on: {
      commonBatchUpdate: function ($event) {
        return _vm.$emit("commonBatchUpdate", $event);
      },
      addAiScoreRule: function ($event) {
        return _vm.$emit("addAiScoreRule", $event);
      }
    }
  }), _c("RulesConfig", {
    attrs: {
      config: _vm.config
    },
    on: {
      addRule: function ($event) {
        return _vm.$emit("addRule");
      },
      deleteRule: $event => _vm.$emit("deleteRule", $event),
      updateRule: function ($event) {
        return _vm.$emit("updateRule", $event);
      },
      toggleRuleType: function ($event) {
        return _vm.$emit("toggleRuleType", $event);
      }
    }
  })] : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;