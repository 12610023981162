"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KeyEventDataScope = _interopRequireDefault(require("@/components/KeyEventDataScope"));
var _conversationList = require("@/api/conversationList");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyEventCascader',
  props: {
    value: {
      type: String,
      default: ''
    },
    workspaceId: {
      type: String,
      default: ''
    }
  },
  emits: ['input', 'change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onInput = val => {
      emit('input', val);
    };
    const onChange = val => {
      emit('change', val);
    };
    const defaultProps = {
      expandTrigger: 'hover',
      checkStrictly: true,
      children: 'children',
      label: 'name',
      value: 'id',
      emitPath: false
    };
    const cascaderOptions = (0, _vue.ref)([]);
    (0, _vue.watchEffect)(() => {
      const params = {
        workspace_id: props.workspaceId,
        show_disable: true
      };
      onInput('');
      (0, _conversationList.getAllKeyEvent)(params).then(res => {
        cascaderOptions.value = res.results.events;
      });
    });
    return {
      __sfc: true,
      props,
      emit,
      onInput,
      onChange,
      defaultProps,
      cascaderOptions,
      KeyEventDataScope: _KeyEventDataScope.default
    };
  }
};
exports.default = _default;