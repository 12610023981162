"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_setup.isHide ? _c("div", {
    staticClass: "mgv-info-alert"
  }, [_c("i", {
    staticClass: "iconfont",
    class: _vm.icon
  }), _c("span", {
    staticClass: "text"
  }, [_vm._t("default", function () {
    return [_vm._v("提示信息")];
  })], 2), _vm.closable ? _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: _setup.close
    }
  }) : _vm._e()]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;