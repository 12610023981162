"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-data"
  }, [_c("svg", {
    staticClass: "icon",
    style: {
      width: `${_vm.size}px`,
      height: `${_vm.size}px`
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": `#${_vm.icon}`
    }
  })]), _vm._t("tips", function () {
    return [_c("span", {
      staticClass: "tips"
    }, [_vm._v(_vm._s(_vm.tips))])];
  }), _vm._t("button")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;