"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _elementUi = require("element-ui");
var _ProcessModule = _interopRequireDefault(require("./ProcessModule.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const processModuleRef = (0, _vue.ref)(null);
    const closeDialog = () => {
      var _processModuleRef$val;
      if ((_processModuleRef$val = processModuleRef.value) !== null && _processModuleRef$val !== void 0 && _processModuleRef$val.hasUnsavedItem()) {
        _elementUi.MessageBox.confirm('有自动化流程设置尚未保存，确定要退出吗？', '设置未保存', {
          confirmButtonText: '退出',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          emit('update:visible', false);
        });
      } else {
        emit('update:visible', false);
      }
    };
    return {
      __sfc: true,
      props,
      store,
      emit,
      processModuleRef,
      closeDialog,
      ProcessModule: _ProcessModule.default
    };
  }
};
exports.default = _default;