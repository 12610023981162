"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _itemsFlowTemp = _interopRequireDefault(require("./itemsFlowTemp"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ItemFlow',
  components: {
    FlowItem: _itemsFlowTemp.default
  },
  props: {
    customerName: {
      type: String,
      default: ''
    },
    timelineArray: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      // futureItems: [],
      isFutureFold: true
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'busiActiveDate', 'busiActiveConver']),
    handleArray() {
      const initArray = this.timelineArray.slice(0);
      // this.futureItems = []
      initArray.forEach(item => {
        this.handleItemDate(item);
      });
      return initArray;
    },
    getFutureFoldIcon() {
      return this.isFutureFold ? 'arrow-down' : 'arrow-up';
    },
    getTimeText() {
      return function (time) {
        return this.$moment(time).format('YYYY-MM-DD HH:mm');
      };
    },
    getIconType() {
      return function (type) {
        if (type === 'audio') {
          return 'phone';
        } else {
          return 'video-camera-solid-2';
        }
      };
    }
  },
  watch: {
    busiActiveDate(val) {
      this.judgeDate(val);
    }
  },
  mounted() {
    this.judgeDate(this.busiActiveDate);
  },
  methods: {
    // goCall(item) {
    //   this.$emit('callOut', item)
    // },
    judgeDate(val) {
      // const today = this.$moment().format('YYYY-MM-DD');
      const isAfterToday = this.$moment(val).isAfter();
      // 如果当前激活日期是未来时间，自动展开未来日程
      if (isAfterToday) {
        this.isFutureFold = false;
      }
    },
    handleItemDate(item) {
      const today = this.$moment().format('YYYY-MM-DD');
      const yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
      const date = this.$moment(item.date).format('YYYY-MM-DD');
      const isAfterToday = this.$moment(item.date).isAfter(today);
      if (isAfterToday) {
        item.dateText = date;
        item.isFuture = true;
        // this.futureItems.push(item);
      } else if (this.$moment(item.date).isSame(today)) {
        item.dateText = '今天';
        item.isFuture = false;
      } else if (this.$moment(item.date).isSame(yesterday)) {
        item.dateText = '昨天';
        item.isFuture = false;
      } else {
        item.dateText = date;
        item.isFuture = false;
      }
    },
    changeFutureFold() {
      this.isFutureFold = !this.isFutureFold;
    },
    selectDate(date) {
      const formateDate = this.$moment(date).format('YYYY-MM-DD');
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', formateDate);
    }
  }
};
exports.default = _default;