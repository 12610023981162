"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
var _default = {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    dataType: {
      type: String,
      default: ''
    },
    asideListType: {
      type: String,
      default: 'conversation'
    }
  },
  methods: {
    secondToCNTime: _timeFormatter.secondToCNTime,
    secondToCNTimeArr: _timeFormatter.secondToCNTimeArr,
    getLeftData(_ref) {
      let {
        duration,
        sort_value: val
      } = _ref;
      switch (this.dataType) {
        case 'time':
          return (0, _timeFormatter.secondToCNTimeArr)(val);
        case 'countPerhour':
          return [val, '次/时'];
        case 'count':
          return [val, '次'];
        case 'percent':
          return [Math.round(val * 100), '%'];
        default:
          return [val];
      }
    },
    // 某一条会话详情
    goConvDetail(id) {
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id
        }
      });
      window.open(router.href, '_blank');
    },
    goDealDetail(id) {
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.dealDetail,
        query: {
          id
        }
      });
      window.open(router.href, '_blank');
    },
    // 查看所有会话/交易
    goConversationOrDeal(type) {
      const {
        query
      } = this.$route;
      let path = null;
      let router = null;
      switch (type) {
        case 'conversation':
          path = _routeMap.ROUTES.conversation;
          router = this.$router.resolve({
            path,
            query
          });
          window.open(router.href, '_blank');
          break;
        case 'deal':
          this.goTrade();
          break;
        default:
          break;
      }
    },
    goTrade() {
      const otherPageCarryingFilter = {
        userId: Number(this.$route.query.id),
        label: '个人分析筛选条件'
      };
      sessionStorage.setItem('otherPageCarryingFilter', JSON.stringify(otherPageCarryingFilter));
      window.open(`${location.origin}${_routeMap.ROUTES.trade}`);
      sessionStorage.removeItem('otherPageCarryingFilter');
    }
  }
};
exports.default = _default;