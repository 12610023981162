"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("navbar", {
    attrs: {
      "back-button": true,
      "is-show-global-search": false
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function () {
        return [_c("div", {
          staticClass: "app-info"
        }, [_c("img", {
          staticClass: "app-logo",
          attrs: {
            src: _vm.appIconUrl,
            alt: "集成应用logo"
          }
        }), _vm._v(" 合并联系人 ")])];
      },
      proxy: true
    }])
  }), _c("main", {
    staticClass: "main"
  }, [_c("section", {
    staticClass: "table-container"
  }, [_c("div", {
    staticClass: "table-title-description"
  }, [_c("span", {
    staticClass: "user-count"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" " + _vm._s(_vm.total) + " "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "通过企业微信添加的联系人约10分钟后将会出现在列表中。",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1), _vm._v(" 联系人合并后，系统将自动把企业微信联系人合并到外呼Megaview联系人上，打通IM与外呼数据全链路。 ")]), _c("merge-app-data-filter", {
    on: {
      handleFilterChange: _vm.handleFilterChange
    }
  }), _c("div", {
    staticClass: "table"
  }, [_c("merge-app-data-table", {
    attrs: {
      loading: _vm.loading,
      "table-data": _vm.tableData,
      "table-height": _vm.tableHeight
    },
    on: {
      openMergeDialog: _vm.openMergeDialog,
      openRevertMergenceDialog: _vm.openRevertMergenceDialog
    }
  })], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      "current-page": _vm.currentPage,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.pageSize,
      layout: "total, sizes, prev, pager, next",
      total: _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]), _c("merge-app-data-dialog", {
    attrs: {
      "merge-dialog-visible": _vm.mergeDialogVisible,
      "recommend-title": _vm.recommendTitle,
      "recommend-list": _vm.recommendList,
      "external-user-id": _vm.externalUserId
    },
    on: {
      closeMergeDialog: _vm.closeMergeDialog,
      filterDealContactList: _vm.filterDealContactList,
      submitForm: _vm.submitForm
    }
  }), _c("revert-mergence-dialog", {
    attrs: {
      "revert-dialog-visible": _vm.revertDialogVisible,
      "revert-mergence-info": _vm.revertMergenceInfo
    },
    on: {
      closeCancelMergenceDialog: _vm.closeCancelMergenceDialog,
      confirmToCancelMergence: _vm.confirmToCancelMergence
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;