"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "group-import-button"
  }, [_c("MgvButton", {
    attrs: {
      type: "text",
      size: "smini"
    },
    on: {
      click: _setup.openDialog
    }
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.导入常用")) + " ")]), _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      width: "600px",
      visible: _setup.drawerVisible,
      "modal-append-to-body": false,
      "before-close": _setup.closeDialog,
      title: _setup.t("coaching.导入常用执行人组合")
    },
    on: {
      "update:visible": function ($event) {
        _setup.drawerVisible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _vm.tableData,
      height: "500px"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: _setup.t("coaching.组合名称"),
      prop: "name",
      "min-width": "200"
    }
  }), _c("el-table-column", {
    attrs: {
      label: _setup.t("coaching.operation"),
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "operate-btns"
        }, [_c("MgvButton", {
          attrs: {
            type: "text",
            size: "mini",
            loading: _setup.activeGroupId === row.id
          },
          on: {
            click: function ($event) {
              return _setup.importGroup(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_setup.t("coaching.导入")) + " ")]), _c("MgvButton", {
          attrs: {
            type: "text",
            size: "mini",
            color: "danger"
          },
          on: {
            click: function ($event) {
              return _setup.deleteGroup(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_setup.t("coaching.delete")) + " ")])], 1)];
      }
    }])
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;