"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _InfoEditCard = _interopRequireDefault(require("./components/InfoEditCard.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _organization = require("@/api/organization");
var _user = require("@/api/user");
var _index = require("@/utils/index.js");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CompanyInfoManagement',
  components: {
    ImageAvatar: _ImageAvatar.default,
    InfoEditCard: _InfoEditCard.default
  },
  data() {
    return {
      formData: {
        logoUrl: '',
        organizationName: ''
      },
      initLogoForm: {},
      rules: {
        organizationName: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }, {
          max: 50,
          message: '团队名称不得超过50个字符',
          trigger: 'blur'
        }]
      },
      logoLoading: false,
      uploadLimitLoading: false,
      usageInfo: {
        org_max_duration: 1,
        org_used_duration: 0,
        is_use_org_max_duration: false,
        user_max_duration: 60 * 60,
        user_infos: []
      },
      initUsageInfo: {},
      membersIds: {
        user_ids: [],
        tree_ids: []
      }
    };
  },
  computed: {
    getUsedPercentage() {
      return Math.round(this.usageInfo.org_used_duration / this.usageInfo.org_max_duration * 1000 / 10);
    }
  },
  created() {
    this.getOrgInfo();
    this.getUsageInfo();
  },
  methods: {
    handleMinutes: _index.handleMinutes,
    getUserList: _organization.getUserList,
    getOrgMemberTree: _user.getOrgMemberTree,
    async getOrgInfo() {
      this.$store.dispatch('user/getOrgInfo').then(res => {
        const {
          org_logo_url,
          org_name
        } = res;
        this.formData.logoUrl = org_logo_url;
        this.formData.organizationName = org_name;
        this.initLogoForm = (0, _cloneDeep.default)(this.formData);
      });
    },
    async updateOrgInfo(org_name, org_logo_url, callback) {
      this.logoLoading = true;
      const res = await (0, _organization.updateOrgInfo)({
        org_name,
        org_logo_url: this.getLogoUrlSuffix(org_logo_url)
      });
      this.logoLoading = false;
      if (res.code === 20000) {
        this.$message.success({
          message: '保存成功',
          center: true
        });
        callback();
        this.getOrgInfo();
        _store.default.dispatch('user/getInfo');
      }
    },
    async restoreDefaultLogo() {
      const res = await (0, _organization.getDefaultLogo)();
      if (res.code === 200) {
        this.formData.logoUrl = res.results.default_logo_url;
      }
    },
    uploadLogo: _organization.uploadLogo,
    beforeAvatarUpload(file) {
      const isImg = /^image\/(jpeg|png|jpg|svg)$/.test(file.type);
      const isSizeFit = file.size / 1024 < 200;
      !isSizeFit && this.$message.warning({
        message: '文件大小不得超过200KB',
        center: true
      });
      return isImg && isSizeFit;
    },
    handleUploadSuccess(res) {
      if (res.code === 200) {
        const {
          pre_fix,
          sur_logo_url
        } = res.results;
        this.formData.logoUrl = pre_fix + sur_logo_url;
      }
    },
    submitLogoInfo(callback) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm('是否保存logo设置和企业名称设置？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            this.updateOrgInfo(this.formData.organizationName, this.formData.logoUrl, callback);
          }).catch(() => {});
        }
      });
    },
    getLogoUrlSuffix(str) {
      return str.match(/.com\/(\S*)/)[1];
    },
    resetLogoForm() {
      this.formData = (0, _cloneDeep.default)(this.initLogoForm);
    },
    async getUsageInfo() {
      const res = await (0, _organization.getUploadLimitInfo)();
      if (res.code === 20000) {
        this.usageInfo = res.results;
        this.initUsageInfo = (0, _cloneDeep.default)(res.results);
      }
    },
    membersChange(val) {
      this.membersIds = val;
    },
    async submitUploadLimit(callback) {
      const {
        user_max_duration,
        is_use_org_max_duration
      } = this.usageInfo;
      if (!is_use_org_max_duration) {
        this.membersIds = {
          user_ids: [],
          tree_ids: []
        };
      }
      const data = {
        ...this.membersIds,
        user_max_duration,
        is_use_org_max_duration
      };
      this.uploadLimitLoading = true;
      const res = await (0, _organization.setUploadLimit)(data).finally(() => {
        this.uploadLimitLoading = false;
      });
      if (res.code === 20000) {
        this.getUsageInfo();
        this.$message.success({
          message: '保存成功'
        });
        callback();
      }
    },
    memberLimitChange(val) {
      this.usageInfo.user_max_duration = val * 60 || 60 * 60;
    },
    resetUsageInfo() {
      this.usageInfo = (0, _cloneDeep.default)(this.initUsageInfo);
    }
  }
};
exports.default = _default;