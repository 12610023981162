"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.VirtualMoreUserListInPopover, {
    attrs: {
      list: _vm.list
    },
    scopedSlots: _vm._u([{
      key: "exposed-avatar-suffix",
      fn: function (_ref) {
        let {
          exposedItem
        } = _ref;
        return [exposedItem.is_finished ? _c("i", {
          staticClass: "el-icon-success"
        }) : _vm._e()];
      }
    }, {
      key: "virtual-list-item-suffix",
      fn: function (_ref2) {
        let {
          virtualListItem
        } = _ref2;
        return [_c("i", {
          staticClass: "iconfont",
          class: virtualListItem.is_finished ? "icon-circle-check" : "icon-time"
        })];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;