"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "answer-sheet"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.answerSheet")))]), _c("el-button", {
    staticClass: "hover-show-bg",
    on: {
      click: _vm.closeAnswerSheet
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })])], 1), _c("section", {
    staticClass: "content"
  }, _vm._l(_vm.localAnswerList, function (item, index) {
    return _c("div", {
      key: index,
      class: ["answer-item", _vm.answerItemClassName(item)],
      on: {
        click: function ($event) {
          return _vm.changeKnowledge(item);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(index + 1) + ".")]), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        placement: "top",
        effect: "dark",
        content: item.name
      }
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))])]), _c("span", {
      staticClass: "score"
    }, [_c("span", [_vm._v(_vm._s(item.average_score > -1 ? item.average_score : "-"))]), _c("span", {
      staticClass: "unit"
    }, [_vm._v(_vm._s(_vm.$t("coaching.points")))])])], 1);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;