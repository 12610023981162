"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _default = {
  __name: 'ToggleTaskHistorySelect',
  props: {
    userId: {
      type: Number,
      default: 0
    },
    taskId: {
      type: Number,
      default: 0
    },
    convId: {
      type: String,
      default: ''
    }
  },
  emits: ['toggleHistory'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const historyList = (0, _vue.ref)([]);
    const filterUndoneList = (0, _vue.ref)([]);
    const currentHistoryId = (0, _vue.ref)(props.convId);
    const getAiCustomerHistoryListData = async () => {
      const res = await (0, _coachingCenter.getAiCustomerHistoryList)({
        plan_id: Number(props.taskId),
        user_id: props.userId
      });
      if (res.code === 20000) {
        var _filterUndoneList$val;
        const {
          list,
          max_score_conv_id
        } = res.results;
        historyList.value = list;
        filterUndoneList.value = list.filter(item => item.is_complete);
        list.find(item => {
          if (item.conv_id === max_score_conv_id) {
            item.isMaxScore = true;
          }
        });
        currentHistoryId.value = props.convId ? props.convId : (_filterUndoneList$val = filterUndoneList.value[filterUndoneList.value.length - 1]) === null || _filterUndoneList$val === void 0 ? void 0 : _filterUndoneList$val.conv_id;
        toggleHistory(currentHistoryId.value);
      }
    };
    (0, _vue.watchEffect)(() => {
      if (props.taskId && props.userId) {
        getAiCustomerHistoryListData();
      }
    });
    const toggleHistory = id => {
      const lastConv = historyList.value[historyList.value.length - 1];
      emit('toggleHistory', id, {
        isUndone: lastConv.is_complete,
        totalCount: historyList.value.length,
        maxConvId: lastConv.is_complete ? '' : lastConv === null || lastConv === void 0 ? void 0 : lastConv.conv_id
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      historyList,
      filterUndoneList,
      currentHistoryId,
      getAiCustomerHistoryListData,
      toggleHistory,
      t: _i18n.t
    };
  }
};
exports.default = _default;