"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseHandler = exports.errorHandler = void 0;
var _elementUi = require("element-ui");
var _errorLog = require("@/utils/error-log");
var _store = _interopRequireDefault(require("@/store"));
var _index = _interopRequireDefault(require("@/router/index"));
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
class Handler {
  constructor() {
    if (new.target === Handler) throw new TypeError('Handler 无法被构建。');
    Handler.messageBox = null;
    this.map = new Map();
    this.codes = [];
  }
  async run(response) {
    const res = response.data;
    if (this.next) {
      this.codes.includes(res.code) ? await this.gethandlerOnMap(res.code)(res) : await this.next.run(response);
      return res;
    } else {
      await this.gethandlerOnMap(res.code)(res, response);
      return Promise.reject(new Error(res.msg || 'Error'));
    }
  }
  defaultHandler() {}
  setNextHandler(handler) {
    this.next = handler;
  }
  popupMessage(message) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'warning';
    (0, _elementUi.Message)({
      message,
      type,
      duration: 2000
    });
  }
  popupMessageBox() {}
  postErrorLog(data, text, type) {
    var _data$data, _data$config, _data$config2;
    const msg = data instanceof Error ? data.message : (_data$data = data.data) === null || _data$data === void 0 ? void 0 : _data$data.msg;
    const logType = data instanceof Error ? 'error' : 'warning';
    (0, _errorLog.postErrorLog)(text || 'response Error', 'apiurl:' + ((_data$config = data.config) === null || _data$config === void 0 ? void 0 : _data$config.url) + ' | trace_id:' + ((_data$config2 = data.config) === null || _data$config2 === void 0 ? void 0 : _data$config2.headers['X-Trace-Id']) + '----' + msg, type || logType);
  }
  resetPage() {
    // 清除ssessionstorage 内容
    sessionStorage.clear();
    const homeUrl = window.location.protocol + '//' + window.location.host + '/';
    if (_index.default.app._route.meta.is_iframe) {
      location.reload(true);
    } else {
      window.open(homeUrl, '_self');
    }
  }
  // 可防止连续触发多个messageBox，注意：不论产生多少个实例，都只会有一个singletonMessageBox
  async singletonMessageBox() {
    if (!Handler.messageBox) {
      try {
        Handler.messageBox = _elementUi.MessageBox.confirm(...arguments);
        await Handler.messageBox;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        Handler.messageBox = null;
      }
    }
  }
  gethandlerOnMap(code) {
    return this.map.has(code) ? this.map.get(code) : this.defaultHandler;
  }
}
// 不需要拦截器做任何操作
/**
 * 43617: 剧本对练，其他连接正在进行该任务
 */
class SuccessHandler extends Handler {
  constructor() {
    super();
    this.codes = [200, 20000, 40605, 40803, 52205, 40723, 43009, 43102, 43206, 40708, 43617];
  }
  defaultHandler(res) {
    if (res.results === null) {
      res.results = {};
    }
  }
}
/**
 * 将会正常返回res
 * 如果需要进行警告或者额外操作，请将code补充至 @parmas this.codes
 * 默认情况下将会调用 @method defaultHandler 弹出警告
 * 如果需要特殊警告（包含跳转链接等）或操作请在 @params this.map 中设置并引用方法。
 */
class WarningHandler extends Handler {
  constructor() {
    super();
    this.codes = [40202, 40802, 41621, 41631, 42707, 42212, 42708, 40119, 40120, 41302, 52204, 42208, 42209, 42210, 43601, 43602, 43603, 43604, 43605, 41312, 43606, 43607, 40801];
    this.map.set(41302, this.setTimeEnd).set(43606, this.setKnowledgeDeleted).set(52204, this.noticeToConnectAccount).set(42208, this.warningToBindWemeet).set(42209, this.warningToBindWemeet).set(42210, this.warningToBindWemeet);
  }
  setTimeEnd() {
    _store.default.commit('settings/SET_TIME_END', true);
  }
  setKnowledgeDeleted() {
    _store.default.commit('training/SET_KNOWLEDGE_DELETED', Date.now());
  }
  noticeToConnectAccount() {
    (0, _elementUi.Message)({
      dangerouslyUseHTMLString: true,
      message: `未授权应用，请前往<a style="color:#4461ec" href="${_routeMap.ROUTES.application}">个人中心-应用</a>关联账户`
    });
  }
  warningToBindWemeet(res) {
    (0, _elementUi.Message)({
      dangerouslyUseHTMLString: true,
      message: `${res.msg}，请前往<a style="color:#4461ec" href="${_routeMap.ROUTES.application}">个人中心-应用</a>重新绑定`,
      type: 'warning'
    });
  }
  defaultHandler(res) {
    super.popupMessage(res.msg);
  }
}

/**
 * 接口将会返回Promise.reject()
 * 如果需要进行警告或者额外操作，请将code补充至 @parmas this.codes
 * 默认情况下将会调用 @method defaultHandler 弹出警告 并上报日志
 * 如果需要特殊警告（包含跳转链接等）或操作请在 @params this.map 中设置并引用方法。
 */

class AbnormalHandler extends Handler {
  constructor() {
    super();
    this.map.set(401, this.deniedAccess).set(40101, this.deniedAccess).set(40102, this.deniedAccess).set(420, this.goLogin).set(404, this.goNotFound).set(40901, this.goABTestList).set(50001, this.forceRefresh((0, _i18n.t)('coaching.loginStatusChangeTip'))).set(40602, this.forceRefresh('商机不存在，即将回到首页')).set(40704, this.forceRefresh('会话不存在，即将回到首页')).set(43301, this.forceRefresh('交易不存在或无查看权限，即将回到首页')).set(40705, this.forceRefresh((0, _i18n.t)('coaching.noAccessRights'))).set(41001, this.forceRefresh((0, _i18n.t)('coaching.customerNotExist'))).set(40611, this.forceRefresh()).set(40709, this.forceRefresh('请安装在线会议应用，以便继续使用该功能')).set(41309, this.setTimeEnd);
  }
  setTimeEnd() {
    _store.default.commit('settings/SET_TIME_END', true);
  }
  forceRefresh(text) {
    return async res => {
      await _elementUi.MessageBox.confirm(text || `${res.msg}，${(0, _i18n.t)('coaching.contactAdministrator')}`, (0, _i18n.t)('coaching.note'), {
        confirmButtonText: (0, _i18n.t)('coaching.confirm'),
        callback: super.resetPage,
        showCancelButton: false
      });
    };
  }
  async deniedAccess(res) {
    await super.singletonMessageBox(res.msg, (0, _i18n.t)('coaching.loginFailureTip'), {
      confirmButtonText: (0, _i18n.t)('coaching.loginAgain'),
      cancelButtonText: (0, _i18n.t)('coaching.cancel'),
      type: 'warning'
    });
    _store.default.commit('user/RESET_INFO');
    // NOTE 获取state中存储的redirect_uri
    const redirect_uri = _store.default.state.user.redirect_uri;
    _index.default.push({
      path: _routeMap.ROUTES.login,
      query: {
        ..._index.default.app._route.query,
        redirect_uri
      }
    });
    // NOTE 清空state中存储的redirect_uri
    _store.default.commit('user/SET_REDIRECT_URI', '');
  }
  goLogin() {
    _index.default.push(_routeMap.ROUTES.login);
  }
  goNotFound() {
    if (_index.default.app._route.meta.is_iframe) return;
    _index.default.replace('/404');
  }
  goABTestList(res) {
    super.popupMessage(res.msg);
    _index.default.replace('/bi/team/ab-testing');
  }
  defaultHandler(res, response) {
    super.popupMessage(res.msg);
    super.postErrorLog(response);
  }
}
class ErrorHandler extends Handler {
  run(error) {
    if (error.response) {
      const res = error.response;
      const requestUrl = res.config.url;
      if (requestUrl === '/api/record/video_record/transcribe/status') {
        if (error.message.indexOf('Network') !== -1) {
          super.popupMessage((0, _i18n.t)('coaching.noNetwork'));
        }
        return;
      }
      if (error.response.status === 502 || error.response.status === 500) {
        if (!navigator.onLine) {
          super.popupMessage((0, _i18n.t)('coaching.noNetwork'));
        } else {
          super.popupMessage((0, _i18n.t)('coaching.serverException'));
        }
        return Promise.reject(error);
      }
      if (error.response.status === 400 || error.response.status === 402) {
        super.popupMessage((0, _i18n.t)('coaching.parameterError'));
        super.postErrorLog(error);
        return Promise.reject(error);
      }
      let messageText = '';
      const resData = error.response.data;
      messageText = resData.msg || error.response.statusText;
      super.popupMessage(messageText);
      return Promise.reject(error);
    } else {
      if (!error.message || error.message.indexOf('Request aborted') !== -1 || error.message.indexOf('canceled') !== -1) {
        return Promise.reject(error);
      }
      if (error.message.indexOf('timeout') !== -1) {
        super.popupMessage((0, _i18n.t)('coaching.requestTimeoutTryAgain'));
        super.postErrorLog(error, (0, _i18n.t)('coaching.requestTimeout'), 'warning');
      } else if (error.message.indexOf('Network') !== -1) {
        super.popupMessage((0, _i18n.t)('coaching.noNetwork'));
      } else {
        super.popupMessage(error.message);
        super.postErrorLog(error);
      }
    }
    return Promise.reject(error);
  }
}
const handlers = [new SuccessHandler(), new WarningHandler(), new AbnormalHandler()].map((item, index, arr) => {
  item.setNextHandler(arr[index + 1]);
  return item;
});
const responseHandler = handlers[0];
exports.responseHandler = responseHandler;
const errorHandler = new ErrorHandler();
exports.errorHandler = errorHandler;