"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBeforeUpload = useBeforeUpload;
exports.useComputedFileDuration = useComputedFileDuration;
var _elementUi = require("element-ui");
var _file2 = require("@/utils/file");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _errorLog = require("@/utils/error-log");
function useBeforeUpload(fileList) {
  const store = (0, _useVuex.useStore)();
  // 校验上传文件 size
  const validateSize = file => {
    var _file$type;
    if (file.name.split('.').pop() === 'amr' || ((_file$type = file.type) === null || _file$type === void 0 ? void 0 : _file$type.split('/')[0]) === 'audio') {
      return {
        isSizeValid: file.size / Math.pow(1024, 2) < 500,
        message: '文件大小不能超过500MB!'
      };
    }
    return {
      isSizeValid: file.size / Math.pow(1024, 3) < 4,
      message: '文件大小不能超过4G!'
    };
  };

  // 对file.type为空的文件类型，进行特殊判断
  const validateSpecialType = name => {
    const suffix = name.slice(name.lastIndexOf('.') + 1);
    return ['rmvb', 'flv', 'amr'].includes(suffix);
  };
  const validateType = file => {
    const isTypeValid = /(audio|video)\/(mpeg|wav|x-wav|x-m4a|aiff|vnd.dlna.adts|aac|x-aac|ogg|x-ms-wma|mp4|flac|avi|x-matroska|x-ms-asf|x-ms-wmv|3gpp|quicktime)/.test(file.raw.type);
    const isSpecialTypeValid = validateSpecialType(file.name);
    return isTypeValid || isSpecialTypeValid;
  };

  // 上传文件change时间
  const onChange = async _file => {
    const file = {
      ..._file,
      duration: 0,
      meeting_name: _file.name,
      md5: '',
      isEditFileName: false
    };
    if (file.status !== 'ready') return;
    const typeCheck = validateType(file);
    const sizeLimit = validateSize(file);
    const webStatus = navigator.onLine;
    if (!typeCheck) {
      (0, _errorLog.postErrorLog)('上传文件格式不符合', `文件名称：${file.name}——文件格式：${file.raw.type}`, 'warning');
      _elementUi.Message.error('上传文件格式不符合,请查看上传要求');
    }
    if (!sizeLimit.isSizeValid) {
      (0, _errorLog.postErrorLog)('上传文件大小不符合', `文件名称：${file.name}——文件大小：${file.size / Math.pow(1024, 2)}MB`, 'warning');
      _elementUi.Message.error(sizeLimit.message);
    }
    if (!webStatus) {
      (0, _errorLog.postErrorLog)('上传文件', `文件名称：${file.name}——用户网络异常`, 'warning');
      _elementUi.Message.warning('网络异常');
    }
    // const md5 = await computeFileMD5(file.raw);
    // file.md5 = md5;
    // const list = [...fileList.value, ...store.getters.globalUploadFileList];
    // const isDuplicate = list.find((item) => item.md5 === file.md5);
    // if (isDuplicate) {
    //   Message.warning(`${file.name} 已存在`);
    // }

    const isValidFile = typeCheck && sizeLimit.isSizeValid && webStatus;
    // && !isDuplicate;
    if (isValidFile) {
      fileList.value.push(file);
    }
  };
  return {
    onChange
  };
}
function useComputedFileDuration(fileList) {
  // 计算视频或者是音频的时长
  const computedFileDuration = file => {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = e => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.decodeAudioData(e.target.result, function (buffer) {
          resolve(buffer.duration);
        });
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const progressingListQueue = (0, _vue.ref)([]);
  const computedFileDurationQueue = () => {
    if (progressingListQueue.value.length >= 1) return;
    const file = fileList.value.find(item => !item.duration);
    if (file) {
      progressingListQueue.value.push(file.uid);
      computedFileDuration(file.raw).then(res => {
        file.duration = res;
        console.log(res, 'res');
        progressingListQueue.value.pop();
      });
    }
  };
  (0, _vue.watchEffect)(() => {
    computedFileDurationQueue();
  });
}