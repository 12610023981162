"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _store = _interopRequireDefault(require("@/store"));
var _config = require("@/utils/config");
var _elementUi = require("element-ui");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import { postErrorLog } from './error-log';

// import axios from 'axios';

class PrivateWebsocket {
  constructor(path, params) {
    this.path = path;
    this.params = params;
    this.ws = null;
    this.timeout = 5000; // 心跳检测时间
    this.maxReconnetCount = 10; // 最大重连次数
    this.reconnectCount = 0; // 当前重连次数
    this.heartCheckTimer = null; // 心跳检测定时器
    this.heartTimeOutTimer = null; // 心跳超时定时器
    this.reconnetTimer = null; // 断线重连定时器
    this.lockReconnect = false;
    PrivateWebsocket.messageBox = null;
  }
  init(params) {
    console.log('init======', params);
    if ('WebSocket' in window) {
      // 建立连接
      this.ws = new WebSocket(this.handlerPath(params || this.params));
      // 连接成功
      this.ws.onopen = this.handlerOpen.bind(this);
      // 连接错误
      this.ws.onerror = this.handlerError.bind(this);
      // 接收信息
      this.ws.onmessage = this.handlerMessage.bind(this);
      // 连接关闭
      this.ws.onclose = this.onclose.bind(this);
    }
  }
  handlerPath(params) {
    this.params = params;
    const session = (0, _auth.getToken)();
    return `${_config.config.wsUrl}${this.path || '/ws'}?${new URLSearchParams({
      session,
      trace_id: (0, _nanoid.nanoid)() + '_front',
      ...params
    })}`;
  }
  handlerOpen() {
    console.log('-----连接成功------');
    // 重置
    this.reset();
  }
  startHeartCheck() {
    this.heartCheckTimer = setTimeout(() => {
      var _this$ws;
      // 这里发送一个心跳，后端收到后，返回一个心跳消息
      if (((_this$ws = this.ws) === null || _this$ws === void 0 ? void 0 : _this$ws.readyState) === 1) {
        // 如果连接正常
        this.ws.send('ping');
        window.log('ping!');
      } else {
        // 否则重连
        this.lockReconnect = false;
        this.reconnect();
      }
      this.heartTimeOutTimer = setTimeout(() => {
        // 超时关闭
        console.log('======超时关闭');
        _elementUi.Message.error('会话重连中...（网络 / 服务异常）');
        this.ws.close();
        this.lockReconnect = false;
        this.reconnect();
      }, this.timeout);
    }, this.timeout);
  }
  sendMessage(data) {
    var _this$ws2;
    if (((_this$ws2 = this.ws) === null || _this$ws2 === void 0 ? void 0 : _this$ws2.readyState) === 1) {
      this.ws.send(JSON.stringify(data));
    } else {
      this.lockReconnect = false;
      this.reconnect();
    }
  }
  isJSON(str) {
    if (typeof str === 'string') {
      try {
        var obj = JSON.parse(str);
        return typeof obj === 'object';
      } catch (e) {
        return false;
      }
    }
  }
  handlerMessage(_ref) {
    let {
      data
    } = _ref;
    // 收到服务器信息，重置
    console.log('收到服务器信息，重置', data);
    this.reset();
    if (this.isJSON(data)) {
      const message = JSON.parse(data);
      if (message.type === 'plan_error') {
        this.reconnect();
      } else {
        _store.default.commit('app/SET_WS_MSG', message);
      }
    }
  }

  // 可防止连续触发多个messageBox，注意：不论产生多少个实例，都只会有一个singletonMessageBox
  async singletonMessageBox() {
    if (!PrivateWebsocket.messageBox) {
      try {
        PrivateWebsocket.messageBox = _elementUi.MessageBox.confirm(...arguments);
        await PrivateWebsocket.messageBox;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        PrivateWebsocket.messageBox = null;
      }
    }
  }
  async reconnect() {
    var _this$ws3, _this$ws4;
    if (this.lockReconnect) return;
    this.lockReconnect = true;
    if (((_this$ws3 = this.ws) === null || _this$ws3 === void 0 ? void 0 : _this$ws3.readyState) === 1) return;
    if (this.maxReconnetCount < this.reconnectCount) {
      await this.singletonMessageBox('连接已断开，请重新连接', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.resetReconnect();
          this.lockReconnect = false;
          this.reconnect();
          return;
        },
        showCancelButton: false
      }).then(() => {
        PrivateWebsocket.messageBox = null;
      });
    }
    console.log('重连', ((_this$ws4 = this.ws) === null || _this$ws4 === void 0 ? void 0 : _this$ws4.readyState) === 1, this.maxReconnetCount, this.reconnectCount);
    this.reconnectCount++;
    // 重连，设置延迟避免请求过多
    this.reconnetTimer && clearTimeout(this.reconnetTimer);
    this.reconnetTimer = setTimeout(() => {
      // 新连接
      this.init();
    }, 3000);
  }
  close() {
    this.ws.close(3999);
    this.resetHeartTimer();
    this.reconnetTimer && clearTimeout(this.reconnetTimer);
  }
  onclose(e) {
    console.log('-----关闭连接------', e);
    if ([3999, 1006, 1000].includes(e.code)) {
      this.reconnetTimer && clearTimeout(this.reconnetTimer);
    } else {
      this.lockReconnect = false;
      this.reconnect();
    }
  }
  handlerError(e) {
    console.log('WebSocket连接发生错误', e);
    // axios.get('https://www.baidu.com/').then(() => {
    //   postErrorLog(
    //     'ai客户websocket Error ' +
    //       'netIsOnline:' +
    //       navigator.onLine +
    //       ' userId:' +
    //       store.getters.userId +
    //       ' orgId:' +
    //       store.getters.orgId +
    //       ' userName:' +
    //       store.getters.name,
    //     e
    //   );
    // });
  }

  reset() {
    // 清除定时器
    console.log('reset', this.heartTimeOutTimer);
    this.resetHeartTimer();
    this.resetReconnect();
    this.startHeartCheck();
  }
  resetHeartTimer() {
    this.heartCheckTimer && clearTimeout(this.heartCheckTimer);
    this.heartTimeOutTimer && clearTimeout(this.heartTimeOutTimer);
  }
  resetReconnect() {
    this.reconnectCount = 0;
    this.reconnetTimer && clearTimeout(this.reconnetTimer);
  }
}
var _default = PrivateWebsocket;
exports.default = _default;