"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-drawer", {
    attrs: {
      title: _vm.$t("management.编辑成员信息"),
      "custom-class": "user-drawer",
      visible: _vm.userInformationDrawerVisible,
      "before-close": _vm.handleUserInfomationDrawerClose,
      "wrapper-closable": false,
      size: "600px",
      "append-to-body": ""
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.userInfoDrawerLoading,
      expression: "userInfoDrawerLoading"
    }],
    staticClass: "member-profile"
  }, [_c("div", {
    staticClass: "member-profile-content"
  }, [_c("div", {
    staticClass: "content-inner"
  }, [_c("el-form", {
    ref: "info-form",
    attrs: {
      rules: _vm.rules,
      model: _vm.userInfo,
      "hide-required-asterisk": ""
    }
  }, [_c("div", {
    staticClass: "information-item"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("management.姓名"),
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("management.请输入姓名")
    },
    model: {
      value: _vm.userInfo.name,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "name", $$v);
      },
      expression: "userInfo.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.登录账号"),
      prop: _vm.userInfo.login_account
    }
  }, [_vm.isTwoFactor ? _c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.userInfo.login_account,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "login_account", $$v);
      },
      expression: "userInfo.login_account"
    }
  }) : [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.userInfo.login_tel,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "login_tel", $$v);
      },
      expression: "userInfo.login_tel"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-mobile-phone",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })]), _c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.userInfo.login_email,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "login_email", $$v);
      },
      expression: "userInfo.login_email"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-message",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])]], 2), _vm.isTwoFactor ? _c("el-form-item", {
    attrs: {
      prop: "verify_email",
      label: _vm.$t("management.验证邮箱")
    }
  }, [_c("template", {
    slot: "label"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("management.验证邮箱")))]), _c("span", {
    staticClass: "login-email-tip"
  }, [_vm._v(_vm._s(_vm.$t("management.修改后会自动重置登录密码，并向邮箱发送邀请链接")))])]), _c("el-input", {
    attrs: {
      placeholder: _vm.$t("management.请输入验证邮箱")
    },
    model: {
      value: _vm.userInfo.verify_email,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "verify_email", $$v);
      },
      expression: "userInfo.verify_email"
    }
  })], 2) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("management.工作邮箱")
    }
  }, [_c("template", {
    slot: "label"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("management.工作邮箱")))]), _c("el-tooltip", {
    staticClass: "item label-tooltip",
    attrs: {
      effect: "dark",
      content: _vm.$t("management.此邮箱仅为个人信息项，不是登录账号"),
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1), _c("el-input", {
    attrs: {
      placeholder: _vm.$t("management.请输入工作邮箱")
    },
    model: {
      value: _vm.userInfo.email,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "email", $$v);
      },
      expression: "userInfo.email"
    }
  })], 2)], 1), _c("div", {
    staticClass: "information-item"
  }, [_c("h3", [_vm._v(_vm._s(_vm.$t("management.部门")))]), _c("add-item", {
    attrs: {
      title: _vm.$t("management.部门"),
      data: _vm.departmentAndRole,
      "has-pop-confirm": "",
      "check-edit": ""
    },
    on: {
      delete: _vm.confirmDeleteDeptAndRole,
      add: function ($event) {
        return _vm.addDeptAndRole();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref) {
        let {
          item,
          index
        } = _ref;
        return [_c("el-form-item", {
          attrs: {
            prop: JSON.stringify([item.department]),
            label: index === 0 ? _vm.$t("management.主部门") : _vm.$t("management.部门"),
            rules: _vm.checkDept
          }
        }, [_c("department-select", {
          attrs: {
            "department-name": item.departmentName,
            "org-tree": _vm.orgTree,
            "disabled-arr": _vm.disabledArr,
            disabled: !item.editable,
            "default-expanded-keys": _vm.defaultExpandedKeys
          },
          model: {
            value: item.department,
            callback: function ($$v) {
              _vm.$set(item, "department", $$v);
            },
            expression: "item.department"
          }
        })], 1)];
      }
    }])
  })], 1), _vm.hasWorkspace ? _c("div", {
    staticClass: "information-item workspace-select-part"
  }, [_c("h3", [_vm._v(_vm._s(_vm.$t("management.空间")))]), _c("add-item", {
    attrs: {
      title: _vm.$t("management.空间"),
      data: _vm.userWorkspaceList,
      "has-pop-confirm": ""
    },
    on: {
      delete: _vm.confirmDeleteWorkspace,
      add: function ($event) {
        return _vm.addWorkspaceItem();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref2) {
        let {
          item,
          index
        } = _ref2;
        return [_c("el-form-item", {
          attrs: {
            prop: JSON.stringify([item.workspace]),
            rules: _vm.checkWorkspace
          }
        }, [_c("div", {
          staticClass: "workspace-label"
        }, [_c("h4", [_vm._v(" " + _vm._s(index === 0 ? _vm.$t("management.主空间") : _vm.$t("management.空间")) + " ")]), !index ? _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            enterable: false,
            effect: "dark",
            content: _vm.$t("management.会话、商机默认导入归属人的主空间"),
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-2"
        })]) : _vm._e()], 1), _c("el-select", {
          attrs: {
            placeholder: _vm.$t("management.请选择空间"),
            "popper-class": "workspace-selector"
          },
          model: {
            value: item.workspace,
            callback: function ($$v) {
              _vm.$set(item, "workspace", $$v);
            },
            expression: "item.workspace"
          }
        }, _vm._l(_vm.localWorkspaceList, function (workspace) {
          return _c("el-option", {
            key: workspace.id,
            attrs: {
              label: workspace.name,
              value: workspace.id,
              disabled: workspace.disabled
            }
          }, [_c("i", {
            staticClass: "iconfont icon-workspace-3-fill",
            style: {
              color: workspace.icon_color,
              "margin-right": "4px"
            }
          }), _c("span", [_vm._v(_vm._s(workspace.name))])]);
        }), 1)], 1)];
      }
    }], null, false, 4015129198)
  })], 1) : _vm._e()]), _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    attrs: {
      type: "text danger"
    },
    on: {
      click: _vm.setResign
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.设置离职")) + " ")]), _c("el-button", {
    on: {
      click: _vm.handleUserInfomationDrawerClose
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.取消")) + " ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.保存")) + " ")])], 1)], 1)])])]), _c("data-transfer-drawer", {
    attrs: {
      visible: _vm.dataTransferDrawerVisible,
      "transfer-type": "resign",
      title: _vm.$t("management.离职"),
      "transfer-from-user-info": _vm.editUserInfo,
      "can-transfer-data": _vm.userCanTransferData
    },
    on: {
      "update:visible": function ($event) {
        _vm.dataTransferDrawerVisible = $event;
      },
      refresh: _vm.handleDataTransferRefresh
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;