"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AsideTop = _interopRequireDefault(require("./components/AsideTop"));
var _FolderTree = _interopRequireDefault(require("@/components/FolderTree"));
var _FolderHeader = _interopRequireDefault(require("./components/FolderHeader"));
var _SubFolders = _interopRequireDefault(require("./components/SubFolders"));
var _SnippetsList = _interopRequireDefault(require("./components/SnippetsList"));
var _CreateFolderDialog = _interopRequireDefault(require("./components/CreateFolderDialog"));
var _FolderMoveDialog = _interopRequireDefault(require("./components/FolderMoveDialog"));
var _RenameDialog = _interopRequireDefault(require("./components/RenameDialog"));
var _SetAccessDialog = _interopRequireDefault(require("./components/SetAccessDialog"));
var _EmptyFolder = _interopRequireDefault(require("./components/EmptyFolder"));
var _Recommend = _interopRequireDefault(require("./components/Recommend"));
var _RankingBoard = _interopRequireDefault(require("./components/RankingBoard"));
var _EmptyCollectFolder = _interopRequireDefault(require("./components/EmptyCollectFolder"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _CreateConvFlowDrawer = _interopRequireDefault(require("./components/CreateConvFlowDrawer"));
var _ConvFlowDetail = _interopRequireDefault(require("./components/ConvFlowDetail"));
var _UploadLibraryFileDialog = _interopRequireDefault(require("./components/UploadLibraryFileDialog"));
var _MediaList = _interopRequireDefault(require("./components/MediaList"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _NoDataHolder = _interopRequireDefault(require("@/components/NoDataHolder"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _SnippetStudyTask = _interopRequireDefault(require("./components/SnippetStudyTask"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _methods = require("./components/methods");
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Library',
  components: {
    AsideTop: _AsideTop.default,
    FolderTree: _FolderTree.default,
    FolderHeader: _FolderHeader.default,
    SubFolders: _SubFolders.default,
    SnippetsList: _SnippetsList.default,
    CreateFolderDialog: _CreateFolderDialog.default,
    MoveFolderDialog: _FolderMoveDialog.default,
    RenameDialog: _RenameDialog.default,
    SetAccessDialog: _SetAccessDialog.default,
    EmptyFolder: _EmptyFolder.default,
    RankingBoard: _RankingBoard.default,
    Recommend: _Recommend.default,
    EmptyCollectFolder: _EmptyCollectFolder.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    MenuToggleBtn: _MenuToggleBtn.default,
    CreateConvFlowDrawer: _CreateConvFlowDrawer.default,
    ConvFlowDetail: _ConvFlowDetail.default,
    UploadLibraryFileDialog: _UploadLibraryFileDialog.default,
    MediaList: _MediaList.default,
    SpaceButton: _SpaceButton.default,
    NoDataHolder: _NoDataHolder.default,
    shareConfig: _shareConfig.default,
    SnippetStudyTask: _SnippetStudyTask.default
  },
  inject: ['reload'],
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      createFolderVisible: false,
      createSubfolder: false,
      createConvFlowVisible: false,
      isEditConvFlow: false,
      editConvFlowNode: {},
      accessProps: {},
      moveFolderProps: {},
      currentNode: {},
      // 当前正在操作的文件夹的data
      createFolderNode: null,
      // 创建子文件夹/子智能会话流的节点
      renameProps: {},
      curFolder: {},
      // 仅用于folder-header的信息展示
      subFolders: [],
      snippets: [],
      contentLoading: false,
      contentType: 'folder',
      // 三种视图类型： 常规 folder, 收藏 collect, 排行 ranking, 我的学习 task
      breadcrumbs: [],
      currrentContent: {},
      requestFinished: false,
      getFolderSzie: 0,
      screenHeight: document.body.clientHeight || document.documentElement.clientHeight,
      screenWidth: document.body.clientWidth || document.documentElement.clientWidth,
      folderPage: 1,
      foldTotal: 0,
      videoPage: 1,
      videoTotal: 0,
      loading: false,
      snippetsList: [],
      moreFolderFlag: false,
      scrollTop: 0,
      fatherId: '',
      convFlowTotal: 0,
      convFlowPage: 1,
      convFlowSubfolders: [],
      uploadFileVisible: false,
      mediaList: [],
      mediaPage: 1,
      mediaListTotal: 0,
      assignConfigVisible: false,
      assignParams: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['folderTree', 'orgId', 'workspaceInfo']),
    contentVisible() {
      var _this$subFolders, _this$snippetsList;
      return ((_this$subFolders = this.subFolders) === null || _this$subFolders === void 0 ? void 0 : _this$subFolders.length) || this.convFlowSubfolders.length || ((_this$snippetsList = this.snippetsList) === null || _this$snippetsList === void 0 ? void 0 : _this$snippetsList.length) || this.mediaList.length;
    },
    folderParent() {
      return this.createFolderNode || this.currentNode;
    },
    fenHeight() {
      return (document.body.clientHeight - 72) / 2;
    },
    fenWidth() {
      return this.screenWidth - 417;
    },
    noMore() {
      return this.snippetsList.length === this.videoTotal && this.videoTotal > 10;
    },
    successMsg() {
      const {
        folder_id
      } = this.assignParams;
      return folder_id ? '批量指派中，请10s后刷新查看' : '';
    }
  },
  provide() {
    return {
      changeCurrentNode: this.changeCurrentNode,
      getContentType: this.getContentType
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === _routeMap.ROUTES.libraryDetail) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  watch: {
    orgId() {
      this.$store.commit('library/SET_TREE', []);
    },
    currentNode() {
      this.scrollContainerToTop();
    },
    contentType(val) {
      this.scrollContainerToTop();
      val === 'collect' && this.getMyCollect();
      if (val !== 'folder') {
        this.currentNode = {};
        const newUrl = this.$route.path + `?content_type=${val}`;
        window.history.pushState('', '', newUrl);
      }
    },
    'workspaceInfo.id'() {
      this.handleWorkspaceChange();
    }
  },
  activated() {
    if (!this.$route.meta.isBack) {
      return;
    } else {
      this.$route.meta.isBack = false;
      if (this.contentType === 'collect') {
        (0, _methods.getVideoList)(this.videoPage, true, this.contentType, this);
      } else if (this.contentType === 'ranking') {
        this.$refs.rankBoard.getSnippetsRankingList();
      }
      if (this.$refs['scroll-container']) {
        this.$refs['scroll-container'].$el.scrollTop = this.scrollTop;
      }
    }
  },
  mounted() {
    (0, _methods.computeFolders)(this);
    window.addEventListener('resize', this.resize);
    if (this.$refs['scroll-container']) {
      this.$refs['scroll-container'].$el.addEventListener('scroll', e => {
        this.scrollTop = e.target.scrollTop;
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    // 指派学习之后刷新文件夹内容
    refresh() {
      this.currentNode.id && this.openFolder();
    },
    assignLibraryStudy: _library.assignLibraryStudy,
    cancelLibraryStudy: _library.cancelLibraryStudy,
    getLibraryStudyAssignList: _library.getLibraryStudyAssignList,
    openUploadDialog() {
      this.uploadFileVisible = true;
    },
    moreSubfolders() {
      (0, _methods.moreFolds)(this);
    },
    resize() {
      (0, _methods.resize)(this);
    },
    getFlowVideoList() {
      (0, _methods.getFlowVideoList)(this);
    },
    getContentType() {
      return this.contentType;
    },
    scrollContainerToTop() {
      if (this.$refs['scroll-container']) {
        this.$refs['scroll-container'].$el.scrollTop = 0;
      }
    },
    treeFetchSucceed() {
      const {
        content_type,
        folder_id
      } = this.$route.query;
      if (content_type) {
        // 剪辑库收藏 collect, 排行 ranking, 我的学习 task
        this.contentType = content_type;
        return;
      }
      if (this.folderTree && this.folderTree.length) {
        if (folder_id) {
          const node = this.$refs['folder-tree'].getNode(folder_id);
          if (node) {
            this.currentNode = node.data;
            this.openFolder(false);
          } else {
            this.$message.warning('无此文件夹');
            this.currentNode = this.folderTree[0];
            this.openFolder();
          }
        } else {
          this.currentNode = this.folderTree[0];
          this.openFolder();
        }
      } else {
        this.requestFinished = true;
      }
    },
    async getMyCollect() {
      this.contentLoading = true;
      this.initPages();
      this.clearFolders();
      await this.getMyCollectConvFlow();
      (0, _methods.getFold)(this.getFolderSzie, true, this.contentType, this);
      (0, _methods.getVideoList)(this.videoPage, true, this.contentType, this);
      this.getMyCollectMediaList();
      this.contentLoading = false;
    },
    openCreateDialog() {
      this.createFolderVisible = true;
      this.createSubfolder = false;
    },
    async openFolder() {
      let isRecord = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.contentType = 'folder';
      this.contentLoading = true;
      let foldId = '';
      if (this.folderTree.length) {
        foldId = this.currentNode.id;
      }
      if (isRecord) {
        // 只有第一次打开不需要记录，避免后退时重复记录
        const newUrl = this.$route.path + `?folder_id=${foldId}`;
        window.history.pushState('', '', newUrl);
      }
      const res = await (0, _library.openFolder)({
        folder_id: foldId || 'null'
      });
      if (res.code === 20000) {
        const {
          ...curFolder
        } = res.results;
        this.curFolder = curFolder;
        if (foldId) {
          if (!this.curFolder.type) {
            // 当文件夹类型为普通文件夹时
            this.initPages();
            this.clearFolders();
            await (0, _methods.getFold)(this.getFolderSzie, true, this.contentType, this);
            await this.getConvFlowSubfolders();
            await this.getMediaList();
            (0, _methods.getVideoList)(this.videoPage, true, this.contentType, this);
          }
        } else {
          this.breadcrumbs = [];
        }
      }
      await res;
      this.contentLoading = false;
      this.requestFinished = true;
    },
    changeCurrentNode(data) {
      this.contentType = 'folder';
      this.currentNode = data;
      this.openFolder();
    },
    handleNodeClick(data) {
      this.contentType = 'folder';
      if (!this.currentNode || this.currentNode.id !== data.id) {
        this.currentNode = {
          ...data
        };
        this.initPages();
        this.openFolder();
      }
    },
    async fetchTreeData() {
      await this.$store.dispatch('library/get_tree');
    },
    async handleCreateSuccess(data) {
      await this.fetchTreeData();
      this.currentNode = data;
      this.openFolder();
    },
    async discollectFolder(folderId) {
      const res = await (0, _library.discollectFolder)(folderId);
      if (res.code === 20000) {
        (0, _methods.removeFolder)(folderId, this, true);
      }
    },
    async discollectSnippet(_ref) {
      let {
        id,
        isMedia
      } = _ref;
      const res = await (0, _library.discollectSnippet)(id);
      if (res.code === 20000) {
        isMedia ? this.moveMedia(id) : (0, _methods.removeSnippet)(id, this);
      }
    },
    folderOperation(type, data) {
      switch (type) {
        case 'assignSnippetTask':
          this.assignConfigVisible = true;
          this.assignParams = {
            folder_id: data.id
          };
          break;
        case 'discollect':
          this.discollectFolder(data.id);
          break;
        case 'rename':
          this.renameProps = {
            visible: true,
            currentNode: data,
            type: 'renameFolder'
          };
          break;
        case 'moveAllSnippetsToFolder':
          this.moveFolderProps = {
            visible: true,
            type,
            isSnippet: true,
            currentNode: data
          };
          break;
        case 'deleteFolder':
          this.deleteFolder(data.id);
          break;
        case 'editAccessUser':
          this.accessProps = {
            visible: true,
            currentNode: data
          };
          break;
        default:
          this.moveFolderProps = {
            visible: true,
            type,
            currentNode: data
          };
          break;
      }
    },
    convFlowOperation(type, data) {
      switch (type) {
        case 'edit':
          this.editConvFlowNode = data;
          this.editConvFlow();
          break;
        case 'discollect':
          this.disCollectConvFlow(data.id);
          break;
        case 'delete':
          this.deleteConvFlow(data);
          break;
      }
    },
    deleteFolder(folder_id) {
      this.$confirm(`是否删除该文件夹`, '提示', {
        confirmButtonText: '确 定',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: '取 消'
      }).then(async () => {
        const parentNode = this.$refs['folder-tree'].getNode(folder_id).parent;
        const curNode = this.$refs['folder-tree'].getNode(this.currentNode.id);
        const res = await (0, _library.deteleFolds)({
          folder_id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.subFolders = this.subFolders.filter(item => item.id !== folder_id);
          this.$refs['folder-tree'].remove(folder_id);
          /**
           * 1.当前节点为要删除的节点，回到父级文件夹
           * 2.要删除的节点是当前节点的父级及以上，回到删除节点的父级
           * 3.其他情况不动
           */
          if (this.currentNode.id === folder_id || this.isChildNode(folder_id, curNode)) {
            this.$refs['folder-tree'].setCurrentKey(parentNode.level === 0 ? this.folderTree[0] : parentNode.data.id);
            this.currentNode = parentNode.level === 0 ? this.folderTree[0] : parentNode.data;
          }
          if (this.contentType === 'folder') {
            this.foldTotal--;
            this.openFolder();
          }
        }
      });
    },
    deleteConvFlow(_ref2) {
      let {
        id
      } = _ref2;
      this.$confirm('是否删除该智能会话流？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _library.deleteConvFlow)({
          folder_id: id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          await this.fetchTreeData();
          this.currentNode = this.folderTree[0];
          this.openFolder();
        }
      });
    },
    // 递归判断folder_id是否为node的高级节点
    isChildNode(folder_id, node) {
      if (!node) return false;
      if (node.parent && node.parent.data.id === folder_id) return true;
      return this.isChildNode(folder_id, node.parent);
    },
    handleSnippetOperation(_ref3) {
      let {
        type,
        data
      } = _ref3;
      if (type === 'editSnippet') {
        this.renameProps = {
          type,
          isSnippet: true,
          visible: true,
          currentSnippet: data
        };
      } else if (type === 'discollect') {
        this.discollectSnippet(data);
      } else if (type === 'deteleSnippet') {
        this.deleteVideos(data);
      } else if (type === 'assignSnippetTask') {
        this.assignConfigVisible = true;
        this.assignParams = {
          snippet_id: data.id
        };
      } else {
        this.moveFolderProps = {
          visible: true,
          isSnippet: true,
          type,
          currentNode: this.currentNode,
          currentSnippet: data
        };
      }
    },
    // 删除剪辑
    deleteVideos(_ref4) {
      let {
        id,
        isMedia
      } = _ref4;
      this.$confirm(`是否删除该${isMedia ? '音视频' : '剪辑'}`, '提示', {
        confirmButtonText: '确 定',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: '取 消'
      }).then(async () => {
        const res = await (0, _library.deleteVideos)({
          folder_id: this.currentNode.id,
          snippet_id: id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          if (isMedia) {
            this.mediaList = this.mediaList.filter(i => i.id !== id);
            this.mediaListTotal--;
          } else {
            this.snippetsList = this.snippetsList.filter(i => i.id !== id);
            this.videoTotal--;
          }
        }
      });
    },
    // 剪辑相关的就更新剪辑，文件夹相关更新全部
    async handleDialogSucceed(isSnippet, type, data) {
      if (!isSnippet && data) {
        switch (type) {
          case 'move':
            await this.fetchTreeData();
            this.currentNode = {
              ...this.currentNode
            };
            this.openFolder();
            break;
          case 'rename':
            (0, _methods.renameFolder)(data, this);
            break;
        }
      } else if (isSnippet && data) {
        switch (type) {
          case 'move':
            this.openFolder();
            break;
          case 'rename':
            (0, _methods.editSnippet)(data, this);
            break;
        }
      }
    },
    async fetchAll() {
      await this.fetchTreeData();
      this.$refs['folder-tree'].$refs['folder-tree'].setCurrentKey(this.currentNode.id);
      await this.openFolder();
    },
    handleFolderCardClick(folder) {
      this.currentNode = folder;
      this.openFolder();
    },
    handleCreate(type, data) {
      if (data) {
        this.createFolderNode = data;
      }
      switch (type) {
        case 'createFolder':
          this.openCreateDialog();
          break;
        case 'createConvFlow':
          this.createConvFlow();
      }
      this.createSubfolder = true;
    },
    getNodeAllParants(node, arr) {
      if (node && node.parent) {
        arr.push(node.data);
        this.getNodeAllParants(node.parent, arr);
      }
      return false;
    },
    handleTreeNodeChange(node) {
      const arr = [];
      this.getNodeAllParants(node, arr);
      this.breadcrumbs = arr.reverse();
    },
    createConvFlow() {
      this.createConvFlowVisible = true;
      this.createSubfolder = false;
      this.isEditConvFlow = false;
    },
    editConvFlow() {
      this.isEditConvFlow = true;
      this.createSubfolder = false;
      this.createConvFlowVisible = true;
    },
    async handleConvFlowSuccess(data) {
      await this.fetchTreeData();
      if (data) {
        this.currentNode = data;
      }
      this.openFolder();
    },
    async getConvFlowSubfolders() {
      const res = await (0, _library.getConvFlowSubfolders)({
        space_id: this.workspaceInfo.id,
        folder_id: this.curFolder.id,
        page: this.convFlowPage++,
        size: 10
      });
      if (res.code === 200) {
        const {
          data,
          total_count
        } = res.results;
        this.convFlowSubfolders.push(...data);
        this.convFlowTotal = total_count;
      }
    },
    async getMediaList() {
      const res = await (0, _library.getMediaList)({
        size: 10,
        page: this.mediaPage++,
        folder_id: this.currentNode.id,
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.handleMediaListResults(res);
      }
    },
    async getMyCollectConvFlow() {
      const res = await (0, _library.getMyCollectConvFlow)({
        space_id: this.workspaceInfo.id,
        page: this.convFlowPage++,
        size: 10
      });
      if (res.code === 200) {
        const {
          data,
          total_count
        } = res.results;
        this.convFlowSubfolders.push(...data);
        this.convFlowTotal = total_count;
      }
    },
    async disCollectConvFlow(folderId) {
      const res = await (0, _library.discollectFolder)(folderId);
      if (res.code === 20000) {
        const index = this.convFlowSubfolders.findIndex(f => f.id === folderId);
        this.convFlowSubfolders.splice(index, 1);
        this.convFlowTotal--;
      }
    },
    async getMyCollectMediaList() {
      const res = await (0, _library.getMyCollectMediaList)({
        page: this.mediaPage++,
        size: 10,
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.handleMediaListResults(res);
      }
    },
    clearFolders() {
      this.convFlowTotal = 0;
      this.mediaListTotal = 0;
      this.convFlowSubfolders = [];
      this.mediaList = [];
    },
    initPages() {
      this.folderPage = 1;
      this.videoPage = 1;
      this.convFlowPage = 1;
      this.mediaPage = 1;
    },
    moveMedia(id) {
      const index = this.mediaList.findIndex(i => i.id === id);
      this.mediaList.splice(index, 1);
      this.mediaListTotal--;
    },
    editMedia(_ref5) {
      let {
        id,
        description,
        name
      } = _ref5;
      this.mediaList.forEach(i => {
        if (i.id === id) {
          i.description = description;
          i.name = name;
        }
      });
    },
    handleMediaListResults(res) {
      const {
        data,
        total_count
      } = res.results;
      const list = data.map(item => ({
        ...item,
        isMedia: true
      }));
      this.mediaList.push(...list);
      this.mediaListTotal = total_count;
    },
    handleSearch(data) {
      if (['snippet', 'media'].includes(data.type)) {
        const {
          id,
          type
        } = data;
        this.$router.push({
          path: _routeMap.ROUTES.libraryDetail,
          query: {
            id,
            type
          }
        });
      } else {
        const node = this.$refs['folder-tree'].getNode(data.id);
        if (!node) {
          this.$message.warning('无此文件夹');
          return;
        }
        this.currentNode = node.data;
        this.openFolder();
      }
    },
    async handleWorkspaceChange() {
      this.contentType = 'folder';
      await this.fetchTreeData();
      this.currentNode = this.folderTree[0];
      if (this.currentNode) {
        this.openFolder();
      }
    }
  }
};
exports.default = _default;