"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-item-type-tag"
  }, [_setup.isOptionField && !_vm.isEditStatus ? _c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _setup.handleCommand
    }
  }, [_c("el-tag", {
    attrs: {
      type: "info"
    }
  }, [_c("i", {
    class: ["iconfont", `icon-${_setup.field.icon}`]
  }), _vm._v(" " + _vm._s(_setup.field.text)), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_setup.optionFieldIds, function (optionId) {
    return _c("el-dropdown-item", {
      key: optionId,
      attrs: {
        command: optionId
      }
    }, [_vm._v(_vm._s(_setup.getField(optionId).text))]);
  }), 1)], 1) : _c("el-tag", {
    attrs: {
      type: "info"
    }
  }, [_c("i", {
    class: ["iconfont", `icon-${_setup.field.icon}`]
  }), _vm._v(" " + _vm._s(_setup.field.text) + " ")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;