"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: "转移会话到其他空间",
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "info-form",
    attrs: {
      rules: _vm.rules,
      model: _vm.formData,
      "hide-required-asterisk": ""
    }
  }, [!_vm.isSingle ? _c("div", {
    staticClass: "form-item"
  }, [_c("label", [_vm._v("会话")]), _c("div", {
    staticClass: "conversation-count"
  }, [_vm._v(_vm._s(_vm.text))])]) : _vm._e(), !_vm.isSingle && _vm.recycleConvCount ? _c("div", {
    staticClass: "form-item"
  }, [_c("label", [_vm._v("回收站会话")]), _c("div", {
    staticClass: "conversation-count"
  }, [_vm._v(_vm._s(_vm.recycleConvCount) + "条")])]) : _vm._e(), _c("div", {
    staticClass: "form-item"
  }, [_c("label", [_vm._v("当前空间")]), _c("div", {
    staticClass: "current-workspace"
  }, [_c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.workspaceItem.icon_color,
      "margin-right": "4px"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.workspaceItem.name))])])]), _c("div", {
    staticClass: "form-item"
  }, [_c("label", [_vm._v("转移到")]), _c("el-form-item", {
    attrs: {
      prop: "workspaceId",
      rules: _vm.rules.workspaceId
    }
  }, [_c("el-select", {
    attrs: {
      "popper-class": "workspace-selector",
      placeholder: "请选择转移后的空间"
    },
    model: {
      value: _vm.formData.workspaceId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "workspaceId", $$v);
      },
      expression: "formData.workspaceId"
    }
  }, _vm._l(_vm.workspaceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name,
        disabled: item.id === _vm.workspaceItem.id
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color,
        "margin-right": "4px"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1)], 1), _c("div", {
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("ul", [_c("li", [_vm._v("会话将应用新空间的关键事件重新分析")]), _c("li", [_vm._v(" 如需修改成员的会话默认导入的空间，请前往管理后台-"), _c("el-link", {
    attrs: {
      underline: false,
      type: "primary"
    },
    on: {
      click: _vm.goDepartmentAndMember
    }
  }, [_vm._v("部门和成员")]), _vm._v("页面，修改成员的主空间 ")], 1)])])]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      disabled: !_vm.isSingle && !(_vm.conversationCount || _vm.recycleConvCount) || isNaN(_vm.conversationCount),
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;