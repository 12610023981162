"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchUpload = batchUpload;
exports.bindClueBusiness = bindClueBusiness;
exports.bindCust = bindCust;
exports.bindSaleman = bindSaleman;
exports.checkProcess = checkProcess;
exports.createRole = createRole;
exports.getBatchUpload = getBatchUpload;
exports.getConversationCustomField = getConversationCustomField;
exports.getUploadDuration = getUploadDuration;
exports.searchClueBusiness = searchClueBusiness;
exports.searchContact = searchContact;
exports.searchTrade = searchTrade;
exports.submitAnalysis = submitAnalysis;
exports.tradeAssociateToContact = tradeAssociateToContact;
exports.uploadMedia = uploadMedia;
var _request = require("@/utils/request");
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function uploadMedia(data, params, cb, that) {
  const axiosConfig = {
    url: '/api/conversation/conversation/upload',
    method: 'post',
    data,
    params
  };
  axiosConfig.cancelToken = new _axios.default.CancelToken(cancel => {
    that.axiosCancel.push({
      cancel
    });
  });
  if (cb instanceof Object) {
    for (const key in cb) {
      axiosConfig[key] = cb[key];
    }
  }
  return (0, _axios.default)(axiosConfig);
}

// 上传会话分析
function submitAnalysis(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_by_file',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话人身份设定
function createRole(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_role_and_set_name',
    method: 'post',
    data
  });
}

// 查询商机或线索
function searchClueBusiness(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/search_deal_lead',
    method: 'post',
    data
  });
}

// 会话绑定商机或线索
function bindClueBusiness(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/conversation_connect_deal_lead',
    method: 'post',
    data
  });
}

// 绑定商机后可以选择的销售人物
function bindSaleman(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/get_salesman_name',
    method: 'post',
    data
  });
}

// 绑定商机后可以选择的客户人物
function bindCust(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/get_customer_name',
    method: 'post',
    data
  });
}

// 检查会话是否在重跑转录
function checkProcess(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/check_ai_process',
    method: 'post',
    data
  });
}

// 上传接口
function batchUpload(data, type) {
  let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (0, _request.request)({
    url: `/batch/upload/${type}`,
    method: 'post',
    data,
    ...config
  });
}

// 获取上传数据接口
function getBatchUpload(params) {
  return (0, _request.request)({
    url: '/batch/upload',
    method: 'get',
    params
  });
}

// 获取上传总时长
function getUploadDuration(params) {
  return (0, _request.webService)({
    url: '/conversation/upload_duration',
    method: 'get',
    params
  });
}

// 获取会话上传会话自定义字段
function getConversationCustomField(params) {
  return (0, _request.webService)({
    url: '/conversation/conversation/form',
    method: 'get',
    params
  });
}

// 创建会话上传过程中关联交易搜索
function searchTrade(params) {
  return (0, _request.webService)({
    url: '/conversation/trade/list',
    method: 'get',
    params
  });
}

// 创建会话上传过程中关联联系人搜索
function searchContact(params) {
  return (0, _request.webService)({
    url: '/conversation/contact/list',
    method: 'get',
    params
  });
}

// 交易关联联系人
function tradeAssociateToContact(data) {
  return (0, _request.webService)({
    url: '/trade/trade/bind_contact',
    method: 'post',
    dataType: 'json',
    data
  });
}