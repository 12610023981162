"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentMultipleSelect = _interopRequireDefault(require("@/components/DepartmentMultipleSelect"));
var _permissions = require("@/api/permissions");
var _ExportButton = _interopRequireDefault(require("@/views/management/components/ExportButton"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberHeaderFilter',
  components: {
    DepartmentMultipleSelect: _DepartmentMultipleSelect.default,
    ExportButton: _ExportButton.default
  },
  props: {
    roleList: {
      type: Array,
      default: () => []
    },
    unassignedCount: {
      type: Number,
      default: 0
    },
    membersCount: {
      type: Number,
      default: 0
    },
    exportApi: {
      type: Function
    }
  },
  data() {
    return {
      listParams: {
        filter: '',
        tree_ids: [],
        role_id: '',
        only_direct: false
      },
      orgTree: [],
      closable: false
    };
  },
  watch: {
    listParams: {
      handler: (0, _commonFunc.debounce)(function (val) {
        if (val.role_id === 0) {
          this.closable = true;
        } else {
          this.closable = false;
        }
        const params = JSON.parse(JSON.stringify(val));
        this.$emit('filterChange', params);
      }, 200),
      deep: true
    }
  },
  created() {},
  methods: {
    getDepartmentsWithOutAuth: _permissions.getDepartmentsWithOutAuth,
    openOperateMemberDialog(val) {
      this.$emit('openOperateMemberDialog', val);
    },
    departmentMultipleChange(ids, departmentLabels, switchStrictly) {
      this.listParams.tree_ids = ids;
      this.listParams.only_direct = switchStrictly;
    },
    getUnassignedList() {
      if (this.closable) {
        this.listParams.role_id = '';
      } else {
        this.listParams.role_id = 0;
      }
    },
    cancelSelect() {
      this.listParams.role_id = '';
    }
  }
};
exports.default = _default;