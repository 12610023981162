"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icon icon-live",
    style: {
      width: _vm.width,
      height: _vm.width
    }
  }, [_c("div", {
    staticClass: "rectangle3"
  }), _c("div", {
    staticClass: "rectangle1"
  }), _c("div", {
    staticClass: "rectangle2"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;