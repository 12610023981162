"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-avatar"
  }, [_c("el-popover", {
    ref: "poper-user-operation",
    staticClass: "avatar-container",
    attrs: {
      "popper-class": "poper-user-operation",
      "popper-options": _vm.userPopperOptions,
      placement: "bottom-end",
      "visible-arrow": false
    }
  }, [_c("user-operation-card", _vm._b({
    on: {
      operation: _vm.userOperation
    }
  }, "user-operation-card", _vm.userCardProps, false)), _c("div", {
    staticClass: "avatar-wrapper",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("image-avatar", {
    staticClass: "user-avatar-comp",
    attrs: {
      avatar: _vm.avatar,
      name: _vm.name,
      "size-value": 28
    }
  })], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "切换公司",
      visible: _vm.switchVisible,
      width: "475px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.switchVisible = $event;
      }
    }
  }, [_c("org-switch-list", {
    attrs: {
      organizations: _vm.organizations,
      invitations: _vm.invitations,
      "active-org-id": _vm.activeOrgId
    },
    on: {
      selectOrg: _vm.selectOrg
    }
  }), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.switchVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.switchOrg
    }
  }, [_vm._v(_vm._s(_vm.sureText))])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;