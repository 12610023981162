"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateTask = _interopRequireDefault(require("./CreateTask"));
var _TaskList = _interopRequireDefault(require("./TaskList"));
var _IsDoneTaskList = _interopRequireDefault(require("./IsDoneTaskList"));
var _ManageViewList = _interopRequireDefault(require("./ManageViewList"));
var _TaskDetail = _interopRequireDefault(require("./TaskDetail"));
var _taskCenter = require("@/api/taskCenter");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskMenuContent',
  components: {
    CreateTask: _CreateTask.default,
    TaskList: _TaskList.default,
    TaskDetail: _TaskDetail.default,
    IsDoneTaskList: _IsDoneTaskList.default,
    ManageViewList: _ManageViewList.default
  },
  inject: ['getActiveManageViewTab'],
  props: {
    currentMenu: {
      type: [String, Number],
      default: ''
    },
    currentTaskDetailId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      activeDoneTaskType: 'myTask',
      isDoneTaskType: [{
        name: 'myTask',
        label: '我的任务'
      }, {
        name: 'myDistribution',
        label: '我分配的'
      }],
      isTaskDetail: false,
      currentTaskData: {},
      taskTypeList: [],
      taskListLoading: false,
      taskDetailLoading: false,
      taskUsersList: [] // 执行人数组
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    currentActiveManageViewTab() {
      return this.getActiveManageViewTab();
    },
    currentTaskTitle() {
      if (this.currentMenu.includes('manageView')) {
        var _this$currentActiveMa;
        return (_this$currentActiveMa = this.currentActiveManageViewTab) === null || _this$currentActiveMa === void 0 ? void 0 : _this$currentActiveMa.label;
      } else {
        switch (this.currentMenu) {
          case 'mytask':
            return '我的任务';
          case 'mydistribute':
            return '我分配的';
          case 'complete':
            return '已完成';
          default:
            return '';
        }
      }
    },
    // 已完成任务类型：我分配的？我的任务
    currentTaskType() {
      return this.taskUsersList.find(item => item.id === this.userId) ? 'myTask' : 'myDistribution';
    }
  },
  watch: {
    // 切换任务类型是，已完成任务页默认展示'我的任务'
    currentMenu: {
      handler(val) {
        if (this.currentTaskDetailId) {
          this.isTaskDetail = true;
          this.getCurrentTaskDetail(this.currentTaskDetailId);
        } else {
          if (val === 'complete') {
            this.activeDoneTaskType = 'myTask';
          } else {
            // 获取我的任务/我分配的任务列表（不分页）
            this.getTodoTaskList();
          }
          this.$nextTick(() => {
            this.isTaskDetail = false;
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    changeActiveDoneTaskType(item) {
      this.activeDoneTaskType = item.name;
    },
    // 任务完成
    handleOperateTask(tag, task_id) {
      this.taskTypeList.forEach((item, index) => {
        if (item.tag === tag) {
          // 删除点击完成的任务
          item.data.forEach((e, taskindex) => {
            if (e.task_info.task_id === task_id) {
              item.data.splice(taskindex, 1);
            }
          });
          if (!item.data.length) {
            // 全部完成某一类任务
            this.taskTypeList.splice(index, 1);
          }
          this.$emit('taskCountsChange');
        }
      });
    },
    // 创建任务后刷新列表
    handleCreatNewTask() {
      this.getTodoTaskList();
      this.$emit('taskCountsChange');
    },
    // 获取任务列表
    async getTodoTaskList() {
      // this.taskTypeList = []取消数据变化折叠面板动画
      this.taskTypeList = [];
      const params = {
        task_type: this.currentMenu === 'mytask' ? 1 : 0
      };
      try {
        this.taskListLoading = true;
        const res = await (0, _taskCenter.getTodoTaskList)(params);
        if (res.code === 20000) {
          this.taskTypeList = res.results.tasks;
        }
      } finally {
        this.taskListLoading = false;
      }
    },
    goBackToTaskList() {
      this.isTaskDetail = false;
      if (this.currentMenu !== 'complete') {
        this.getTodoTaskList();
      } else {
        this.activeDoneTaskType = this.currentTaskType;
      }
    },
    goTaskDetail(task_id) {
      this.isTaskDetail = true;
      this.getCurrentTaskDetail(task_id);
      if (this.$refs['create-task']) {
        this.$refs['create-task'].isCreateTask = false;
      }
    },
    // 获取任务详情
    async getCurrentTaskDetail(task_id) {
      this.currentTaskData = {};
      this.taskDetailLoading = true;
      try {
        const res = await (0, _taskCenter.getCurrentTaskDetail)({
          task_id
        });
        if (res.code === 20000) {
          this.currentTaskData = res.results;
          this.taskUsersList = res.results.task_users;
        }
      } finally {
        this.$emit('clearCurrentTaskDetailId');
        this.taskDetailLoading = false;
      }
    }
  }
};
exports.default = _default;