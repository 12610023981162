"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String,
    value: {
      type: [Array, String],
      default: () => []
    },
    table: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeNames: ['Alan']
    };
  }
};
exports.default = _default;