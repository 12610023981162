"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MessageCenterButton = _interopRequireDefault(require("@/layout/components/NavBar/MessageCenterButton"));
var _UserAvatar = _interopRequireDefault(require("@/layout/components/NavBar/UserAvatar"));
var _TaskCenterButton = _interopRequireDefault(require("@/layout/components/NavBar/TaskCenterButton"));
var _MgAutoTooltip = _interopRequireDefault(require("@/components/MgAutoTooltip"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MessageCenterButton: _MessageCenterButton.default,
    UserAvatar: _UserAvatar.default,
    TaskCenterButton: _TaskCenterButton.default,
    MgAutoTooltip: _MgAutoTooltip.default
  },
  data() {
    return {
      megaTranStep: [{
        title: '欢迎来到Megatran建模平台',
        content: '通过MegaAI的能力注入，您现在可以自行建模语义级关键事件啦！原关键事件的运行机理被拆分为模型这一实体，同时新增了模型管理、数据集管理，方便您标注数据，并训练、测试AI模型。跟随引导一起试试吧！',
        target: '[data-v-step="关键事件"]',
        placement: 'right'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree', 'org_display_info']),
    activeMenu() {
      switch (this.$route.path) {
        case _routeMap.ROUTES.annotate:
          return _routeMap.ROUTES.dataSet;
        default:
          {
            const reg = /(\/[\w|-]+){2}/g;
            return this.$route.path.match(reg)[0];
          }
      }
    },
    menuMap() {
      var _allMenu, _allMenu2, _allMenu3;
      let allMenu = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: 'Management',
        targetAttr: 'name',
        getAttr: 'children'
      });
      allMenu = (_allMenu = allMenu) === null || _allMenu === void 0 ? void 0 : _allMenu.filter(item => item.meta.order);
      (_allMenu2 = allMenu) === null || _allMenu2 === void 0 ? void 0 : _allMenu2.sort((a, b) => a.meta.order - b.meta.order);
      const menuMap = {};
      (_allMenu3 = allMenu) === null || _allMenu3 === void 0 ? void 0 : _allMenu3.map(item => {
        if (menuMap[item.meta.category]) {
          menuMap[item.meta.category].push(item);
        } else {
          menuMap[item.meta.category] = [item];
        }
      });
      return menuMap;
    }
  },
  watch: {
    menuMap: {
      handler: function (val) {
        // 关键事件模块加引导
        if (val['关键事件']) {
          this.$nextTick(() => {
            this.$refs['megaTranTourRef'].start();
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getOrgInfo();
  },
  methods: {
    recursionGet: _commonFunc.recursionGet,
    async getOrgInfo() {
      this.$store.dispatch('user/getOrgInfo');
    },
    goDashboard() {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.dashboard
      });
      window.open(routeData.href, '_blank');
    },
    goHelpCenter() {
      window.open('https://www.megaview.com/help-center/doc?docx=XfJ2dqdaXoHZduxPVuTcKPHXnzf&anchor=KUEcdy2OMokoS2xOIqNc14zunNb', '_blank');
    }
  }
};
exports.default = _default;