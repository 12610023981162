"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "contact-form-card"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("h5", [_c("em", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEdit,
      expression: "!isEdit"
    }]
  }, [_vm._v(_vm._s(_vm.value.name || ""))])])]), _c("div", {
    staticClass: "content"
  }, [_c("dynamic-form", {
    ref: "form",
    staticClass: "contact-form un-edit",
    attrs: {
      desc: _vm.formDesc,
      value: _vm.value,
      "is-edit": false
    },
    on: {
      keyChange: _vm.handleKeyChange
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;