"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sop-container"
  }, [_c("MgvMultiLevelMenu", {
    ref: "nav-menu",
    attrs: {
      "menu-title": "SOP",
      "menu-list": _vm.biMenuList,
      "default-openeds": _vm.defaultOpeneds
    },
    on: {
      updateIsHiddenMenu: _vm.updateIsHiddenMenu
    }
  }, [_c("space-button", {
    staticClass: "space-button",
    attrs: {
      slot: "title-suffix"
    },
    slot: "title-suffix"
  })], 1), _c("div", {
    staticClass: "right-part",
    on: {
      mouseover: _vm.floatingMenuHidden
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: _vm.getTabTitle
    }
  })], 1), _c("FiltersGroup"), _c("router-view", {
    staticClass: "sop-router-view",
    attrs: {
      "default-openeds": _vm.defaultOpeneds
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;