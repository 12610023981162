"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _SettingSubtitle = _interopRequireDefault(require("./SettingSubtitle"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'OtherSetting',
  setup(__props) {
    const promptStruct = (0, _vue.inject)('promptStruct');
    return {
      __sfc: true,
      promptStruct,
      SettingSubtitle: _SettingSubtitle.default
    };
  }
};
exports.default = _default;