"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _store = _interopRequireDefault(require("@/store"));
var _routeMap = require("@/router/routeMap");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeMultipleTree',
  components: {
    NoData: _NoData.default
  },
  data() {
    return {
      treeData: [],
      treeLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript', 'api_permissions', 'knowledgeBaseInfo']),
    hasKnowledgePermission() {
      return this.api_permissions.includes('/webapi/training/catalog/get');
    },
    noDataTip() {
      return this.hasKnowledgePermission ? '知识库为空' : '知识库未开启，请联系管理员开启功能';
    }
  },
  watch: {
    'knowledgeBaseInfo.id': {
      // 切换知识库
      handler: function (val) {
        if (val === -1) return;
        if (val && this.hasKnowledgePermission) {
          this.getCatalogData(val);
        }
      },
      immediate: true
    },
    checkedKnowledgeScript: {
      handler(val) {
        var _this$$refs$tree;
        (_this$$refs$tree = this.$refs.tree) === null || _this$$refs$tree === void 0 ? void 0 : _this$$refs$tree.setCheckedKeys(val.map(_ref => {
          let {
            knowledge_id
          } = _ref;
          return knowledge_id;
        }));
      },
      immediate: true
    }
  },
  methods: {
    goKnowledgeLibrary() {
      this.$router.push({
        path: _routeMap.ROUTES.knowledgeLibrary
      });
    },
    handleCheck(node, data) {
      const checkedKeys = data.checkedKeys.concat(data.halfCheckedKeys);
      if (checkedKeys.includes(node.id)) {
        // 勾选节点
        if (node.children) {
          // 非叶子节点通过知识点目录接口获取知识点话术
          this.getKnowledgeList(node.id);
        } else {
          // 叶子节点通过单个知识点接口获取知识点话术
          this.getKnowledgeInfo(node.id);
        }
      } else {
        // 取消勾选节点
        this.deleteCheckedScript(node);
      }
    },
    // 删除节点
    deleteCheckedScript(node) {
      var _node$children;
      _store.default.commit('training/SET_KNOWLEDGE_SCRIPT_DELETE', node.id);
      if ((_node$children = node.children) !== null && _node$children !== void 0 && _node$children.length) {
        node.children.forEach(item => this.deleteCheckedScript(item));
      }
    },
    async getKnowledgeList(id) {
      const res = await (0, _knowledgeLibrary.getKnowledgeList)({
        catalog_id: id.split('_').pop() - 0,
        space_id: this.knowledgeBaseId
      });
      if (res.code === 20000) {
        const checkedKeys = this.checkedKnowledgeScript.map(_ref2 => {
          let {
            knowledge_id
          } = _ref2;
          return knowledge_id;
        });
        res.results.data.length && res.results.data.forEach(item => !checkedKeys.includes(item.id) && this.addToScript(item));
      }
    },
    // 获取单个知识点
    async getKnowledgeInfo(id) {
      const res = await (0, _knowledgeLibrary.getKnowledgeInfo)({
        id
      });
      if (res.code === 20000) {
        this.addToScript(res.results.data);
      }
    },
    // 添加到知识点话术
    addToScript(data) {
      const {
        id: knowledge_id,
        catalog_id,
        scene_reply_list,
        name: knowledge_name
      } = data;
      _store.default.commit('training/SET_KNOWLEDGE_SCRIPT_ADD', {
        knowledge_id,
        knowledge_name,
        catalog_id,
        scene_reply_list
      });
      this.$emit('scrollToScriptEnd');
    },
    async getCatalogData(id) {
      this.treeLoading = true;
      const res = await (0, _knowledgeLibrary.getCatalogData)({
        id,
        is_know: true
      }).finally(() => {
        this.treeLoading = false;
      });
      if (res.code === 20000) {
        this.treeData = res.results.datas;
        this.treeData.length && this.setDisabled(this.treeData);
        // 知识树的数据请求后回填勾选
        this.$nextTick(() => {
          var _this$$refs$tree2;
          (_this$$refs$tree2 = this.$refs.tree) === null || _this$$refs$tree2 === void 0 ? void 0 : _this$$refs$tree2.setCheckedKeys(this.checkedKnowledgeScript.map(_ref3 => {
            let {
              knowledge_id
            } = _ref3;
            return knowledge_id;
          }));
        });
      }
    },
    setDisabled(node) {
      node.forEach(item => {
        var _item$children;
        if (item.is_know) {
          item.disabled = false;
        } else {
          item.disabled = !item.count;
          // 目录id拼接c_，防止与其他空间知识点id相同，影响回填
          item.id = `c_${item.id}`;
        }
        ((_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) && this.setDisabled(item.children);
      });
    }
  }
};
exports.default = _default;