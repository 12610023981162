"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _PasswordInput = _interopRequireDefault(require("@/components/PasswordInput"));
var _Password = _interopRequireDefault(require("@/components/Password"));
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _user = require("@/api/user");
var _parivatizationDeployment = require("@/api/parivatizationDeployment");
var _validate = require("@/utils/validate");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UpdatePasswordDialog',
  components: {
    passwordInput: _PasswordInput.default,
    VerificationCodeInput: _VerificationCodeInput.default,
    Password: _Password.default
  },
  props: {
    type: {
      type: String,
      default: 'change'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    tel: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    verifyEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    const repasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('management.请再次输入密码')));
      } else if (value !== this.form.newPassword) {
        callback(new Error(this.$t('management.两次输入密码不一致!')));
      } else {
        callback();
      }
    };
    return {
      form: {
        originPassword: '',
        tel: '',
        email: '',
        code: '',
        newPassword: '',
        repassword: '',
        login_account: '',
        // 希音独有-登录账号
        verifyEmail: '' // 希音独有-验证邮箱
      },

      submitLoading: false,
      rules: {
        originPassword: [{
          required: true,
          message: this.$t('management.请输入密码'),
          trigger: 'change'
        }],
        code: [{
          required: true,
          validator: _validate.codeValidator,
          trigger: 'change'
        }],
        repassword: [{
          validator: repasswordValidator,
          trigger: 'change'
        }]
      },
      isMessageVerification: false
    };
  },
  watch: {
    dialogVisible: {
      handler: function (val) {
        if (val) {
          if (this.isTwoFactor) {
            this.form.verifyEmail = this.verifyEmail;
          } else {
            this.form.tel = this.tel;
            this.form.email = this.email;
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['login_account', 'isTwoFactor']),
    buttonDisabled() {
      if (this.isTwoFactor) {
        return ['verifyEmail', 'code', 'newPassword', 'repassword'].some(key => this.form[key] === '');
      }
      if (this.type === 'change') {
        let activeKeys = [];
        if (this.isMessageVerification) {
          if (this.tel) {
            activeKeys = ['tel', 'code', 'newPassword', 'repassword'];
          } else {
            activeKeys = ['email', 'code', 'newPassword', 'repassword'];
          }
        } else {
          activeKeys = ['originPassword', 'newPassword', 'repassword'];
        }
        return activeKeys.some(key => this.form[key] === '');
      } else {
        return this.form.newPassword === '' || this.form.repassword === '';
      }
    },
    ENV_TITLE() {
      return process.env.VUE_APP_TITLE;
    },
    sendingDestination() {
      return this.isTwoFactor ? 'email' : this.tel ? 'tel' : 'email';
    }
  },
  methods: {
    handleClose() {
      var _this$$refs$codeInput;
      this.$emit('closeUpdatePasswordDialog');
      (_this$$refs$codeInput = this.$refs.codeInput) === null || _this$$refs$codeInput === void 0 ? void 0 : _this$$refs$codeInput.reset();
      this.form.code = '';
      this.form.originPassword = '';
      this.resetForm();
    },
    switchVerificationMethod(isMessageVerification) {
      console.log('-------');
      this.isMessageVerification = isMessageVerification;
      this.$refs.passwordInput.error = false;
      const tips = this.$refs.passwordInput.tips;
      for (const tip in tips) {
        tips[tip].color = '#8A98A8';
      }
      this.$refs.passwordForm.resetFields();
      this.form.code = '';
      this.form.originPassword = '';
      this.form.newPassword = '';
      this.form.repassword = '';
      console.log(this.form, 'this.form');
    },
    sendCode() {
      this.$refs.codeInput.showTencentVerify();
    },
    async submit() {
      this.$refs.passwordForm.validate(async valid => {
        const passValid = this.$refs.passwordInput.valid;
        if (valid && passValid) {
          this.submitLoading = true;
          const {
            originPassword,
            newPassword,
            tel,
            code,
            email,
            verifyEmail
          } = this.form;
          const {
            pub_key,
            data: origin_password
          } = await this.Rsa.getRsaCode(originPassword);
          const {
            data: new_password
          } = this.Rsa.encryptData(newPassword, pub_key);
          const data = {
            new_password,
            pub_key
          };
          if (originPassword) data.origin_password = origin_password;
          // 如果code 存在，证明是验证码修改
          if (code) {
            data.method = this.isTwoFactor ? 'email' : tel ? 'tel' : 'email';
          }
          if (this.isTwoFactor) {
            data.verify_email = verifyEmail;
            data.code = code;
          } else if (tel || email) {
            data.tel = tel;
            data.code = code;
            data.email = email;
          }
          const method = this.ENV_TITLE === 'image' ? _parivatizationDeployment.updateAccountPassWord : _user.updatePassWord;
          method(data).then(res => {
            this.submitLoading = false;
            if (res.code === 20000 || res.code === 200) {
              if (this.type === 'change') {
                this.$message.success(this.$t('management.密码修改成功'));
              } else {
                this.$message.success(this.$t('management.密码设置成功'));
              }
              this.$emit('updatePasswordSuccess');
              this.handleClose();
            }
          }).catch(() => {
            this.submitLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.passwordForm.resetFields();
    }
  }
};
exports.default = _default;