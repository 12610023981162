"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _ExecutorList = _interopRequireDefault(require("../components/ExecutorList"));
var _LeaderTableOperate = _interopRequireDefault(require("../components/LeaderTableOperate.vue"));
var _taskOperateMixins = _interopRequireDefault(require("../taskOperateMixins.js"));
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamViewTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ExecutorList: _ExecutorList.default,
    LeaderTableOperate: _LeaderTableOperate.default
  },
  mixins: [_taskOperateMixins.default],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableHeight: document.documentElement.clientHeight - 267,
      tableHead: [{
        prop: 'name',
        label: (0, _i18n.t)('coaching.taskName'),
        fixed: 'left',
        width: 240,
        showOverflowTip: true
      }, {
        prop: 'user_infos',
        label: (0, _i18n.t)('coaching.executor'),
        width: 240
      }, {
        prop: 'course_name',
        label: (0, _i18n.t)('coaching.learnCourse'),
        width: 200
      }, {
        prop: 'course_type',
        label: (0, _i18n.t)('coaching.courseType'),
        width: 200
      }, {
        prop: 'creator',
        label: (0, _i18n.t)('coaching.assigner'),
        width: 140
      }, {
        prop: 'category_info',
        label: (0, _i18n.t)('coaching.任务分类'),
        width: 200,
        showOverflowTip: true
      }, {
        prop: 'start_time',
        label: (0, _i18n.t)('coaching.startTime'),
        sortable: 'custom'
      }, {
        prop: 'end_time',
        label: (0, _i18n.t)('coaching.deadline'),
        sortable: 'custom'
      }, {
        prop: 'status',
        label: (0, _i18n.t)('coaching.status'),
        width: 120
      }]
    };
  },
  methods: {
    courseType(row) {
      const types = ['-', this.$t('coaching.scriptReading'), this.$t('coaching.scriptRecitation'), this.$t('coaching.scriptPractice'), this.$t('coaching.aICustomer') + '(Alpha)', this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer')];
      return types[row.course_type];
    },
    plus8ToCurrentZone(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    handleSortChange(val) {
      this.$emit('sortChange', val);
    },
    goTaskDetail(_ref) {
      let {
        id,
        children
      } = _ref;
      const showErrorSummary = children === null || children === void 0 ? void 0 : children.some(item => item.course_type === 3);
      this.$router.push({
        path: children ? _routeMap.ROUTES.coachParentTaskDetail : _routeMap.ROUTES.coachingCenterLeaderPlan,
        query: {
          id,
          source: 'teamView',
          showErrorSummary: showErrorSummary || undefined
        }
      });
    },
    getStatusClass(_ref2) {
      let {
        status
      } = _ref2;
      const map = {
        0: 'status ongoing',
        1: 'status has-expired',
        2: 'status not-started',
        3: 'status have-all-done'
      };
      return map[status];
    },
    getStatus(_ref3) {
      let {
        status
      } = _ref3;
      const map = {
        0: this.$t('coaching.inProgress'),
        1: this.$t('coaching.deadlinePassed'),
        2: this.$t('coaching.notStarted'),
        3: this.$t('coaching.completed')
      };
      return map[status];
    },
    getCategoryNames(row) {
      if (!row.category_info.length) return '-';
      return row.category_info.map(item => item.name).join(',');
    }
  }
};
exports.default = _default;