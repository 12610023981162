"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'ScoreSelect',
  props: {
    range: {
      type: Array,
      default: () => []
    },
    defaultRange: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [String, Number],
      default: 0
    },
    hasScoreSuggestion: {
      type: Boolean,
      default: false
    },
    scoreSuggestion: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      score: '',
      scoreRange: [],
      min: 1,
      max: 5,
      rangeMin: 1,
      rangeMax: 5
    };
  },
  created() {
    this.scoreRange = (0, _commonFunc.deepClone)(this.range);
    this.min = this.range[0];
    this.max = this.range[1];
    this.rangeMin = this.range[0];
    this.rangeMax = this.range[1];
    this.defaultRange.length && this.handleSliderChange(this.defaultRange);
  },
  methods: {
    handleMinInput() {
      this.min = this.min.toString().replace(/[^\d]/g, '');
      if (this.min > this.rangeMax) this.min = this.rangeMax;
      if (this.min < this.rangeMin) this.min = this.rangeMin;
      if (this.max < this.min) {
        const temp = this.max;
        this.max = this.min;
        this.min = temp;
      }
      this.handleScore(this.min, this.max);
    },
    handleMaxInput() {
      this.max = this.max.toString().replace(/[^\d]/g, '');
      if (this.max > this.rangeMax || this.max < this.rangeMin) {
        this.max = this.rangeMax;
      }
      if (this.max < this.min) {
        const temp = this.max;
        this.max = this.min;
        this.min = temp;
      }
      this.handleScore(this.min, this.max);
    },
    handleClear() {
      this.min = this.rangeMin;
      this.max = this.rangeMax;
      this.handleScore(this.min, this.max);
      this.$nextTick(() => {
        this.score = '';
      });
    },
    compareMax(a, b) {
      return a < b;
    },
    handleScore(a, b) {
      this.scoreRange = [Number(this.min), Number(this.max)];
      this.score = this.compareMax(a, b) ? `${a} - ${b}` : `${b} - ${a}`;
      this.$emit('scoreChange', this.scoreRange);
    },
    handleSliderChange(val) {
      this.min = val[0];
      this.max = val[1];
      this.handleScore(this.min, this.max);
    }
  }
};
exports.default = _default;