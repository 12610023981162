"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    onlyRead: {
      type: Boolean,
      default: false
    },
    fillValue: {
      type: Object,
      default: () => ({})
    },
    hasDataType: {
      type: Boolean,
      default: true
    }
  },
  emits: ['paramsChange'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const initialParamsList = (0, _vue.ref)([{
      key: 'resample_num',
      parameterName: '正例增倍数',
      valueType: 'int',
      valueRange: [1, 100],
      isShow: true,
      value: 2,
      explain: '在一定范围内，通过提高参数值，可能提升召回率',
      tips: '对标注正样本进行重复采样的倍数。例如标注了5个正样本，当正例增倍数设为2时，实际训练中会将5个正样本*2（即复制1次）得到10个正样本，提高模型对这些正样本的特征学习程度。'
    }, {
      key: 'negative_selected_resample_num',
      parameterName: '负例增倍数',
      valueType: 'int',
      valueRange: [0, 100],
      isShow: true,
      value: 1,
      explain: '在一定范围内，通过提高参数值，可能提升精确率',
      tips: '对标注负样本进行重复采样的倍数。具体原理同正例增倍数，帮助模型强化对标注的特殊负例的特征的学习'
    }, {
      key: 'negative_surround_ratio',
      parameterName: '边界负例采样率',
      valueType: 'float',
      valueRange: [0, 1],
      precision: 1,
      isShow: true,
      step: 0.1,
      value: 0.5,
      explain: '值越大，预测结果的上下文边界越准确',
      tips: '在正样本的上下文边界处选取负样本，帮助模型在预测时更好的把握预测正例的边界'
    }, {
      key: 'positive_negative_ratio',
      parameterName: '负例:正例比值',
      valueType: 'int',
      valueRange: [1, 100],
      value: 4,
      isShow: true,
      explain: '在一定范围内，通过提高参数值，可能提升精确率',
      tips: '负样本最终保留数量：正样本数量的比值。<br>适用于负样本远远多于正样本的场景，训练时只保留部分的负例样本，以平衡模型对正负样本的学习倾向。'
    }, {
      key: 'epoch',
      parameterName: '训练轮次',
      valueType: 'int',
      valueRange: [1, 30],
      value: 8,
      isShow: true,
      explain: '在一定范围内，通过提高参数值，可能提升精确率和召回率',
      tips: '模型对训练数据的重复学习次数，数值越大，模型越能学习到训练数据的特征，从而更加精准的分辨其中正例和负例。不推荐过小值或过大值，过小可能造成模型对训练数据学习不足；过大可能造成模型过拟合，泛化能力降低，无法应对多样化场景'
    }, {
      key: 'data_type',
      parameterName: '文本组合方式',
      isShow: props.hasDataType,
      valueType: 'select',
      value: 2,
      explain: '对话数据的组合方式',
      tips: '同时影响模型的输入和输出。<br>1：适合无需场景的单句<br>2：适合短上下文场景<br>3：适合双方角色都参与的对话场景，销售先发言，客户后发言<br>4：适合双方角色都参与的对话场景，客户先发言，销售后发言<br> 5：适合无需上下文的对话场景，销售先发言，客户后发言<br>6：适合无需上下文的对话场景，客户先发言，销售后发言<br>10：适合销售连续发言场景<br>11：适合客户连续发言场景'
    }]);
    const paramsList = (0, _vue.ref)({});
    paramsList.value = (0, _cloneDeep.default)(initialParamsList.value);
    const assignValuesToParamItems = (paramItems, resultObject) => {
      return paramItems.map(item => {
        const value = resultObject[item.key];
        return {
          ...item,
          value
        };
      });
    };
    (0, _vue.watch)(() => props.fillValue, val => {
      if ((0, _isEmpty.default)(val)) return;
      paramsList.value = assignValuesToParamItems(paramsList.value, val);
    }, {
      deep: true,
      immediate: true
    });
    const formatParamItemsToObject = paramItems => {
      return paramItems.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});
    };
    (0, _vue.watch)(paramsList, val => {
      const params = formatParamItemsToObject(val);
      emit('paramsChange', params);
    }, {
      deep: true,
      immediate: true
    });
    const dataTypeOption = [{
      value: 1,
      label: '1.将单条文本作为一个训练数据'
    }, {
      value: 2,
      label: '2. 将连续两条文本作为一个训练数据'
    }, {
      value: 3,
      label: '3.根据角色，将上下相邻的“销售”发言与“客户”发言整体作为一个训练数据'
    }, {
      value: 4,
      label: '4.根据角色，将上下相邻的“客户”发言与“销售”发言整体作为一个训练数据'
    }, {
      value: 5,
      label: '5.销售说客户答，不合并上下相邻的同角色连续发言'
    }, {
      value: 6,
      label: '6.客户说销售答，不合并上下相邻的同角色连续发言'
    }, {
      value: 9,
      label: '9.任意角色说，合并上下文相邻的同角色连续发言'
    }, {
      value: 10,
      label: '10.销售说，合并上下相邻的同角色连续发言'
    }, {
      value: 11,
      label: '11.客户说，合并上下文相邻的同角色连续发言'
    }];
    const getDataType = val => {
      return dataTypeOption.find(item => item.value === val).label;
    };
    const initParams = () => {
      paramsList.value = (0, _cloneDeep.default)(initialParamsList.value);
      const params = formatParamItemsToObject(paramsList.value);
      return params;
    };
    expose({
      initParams
    });
    return {
      __sfc: true,
      props,
      emit,
      initialParamsList,
      paramsList,
      assignValuesToParamItems,
      formatParamItemsToObject,
      dataTypeOption,
      getDataType,
      initParams
    };
  }
};
exports.default = _default;