"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _horCalendar = _interopRequireDefault(require("./horCalendar"));
var _num_formatter = require("@/utils/num_formatter");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusiCalenderDirectoty',
  components: {
    HorizontalCalendar: _horCalendar.default
  },
  props: {
    dealObject: {
      type: Object,
      default: function () {
        return {};
      }
    },
    isCalenderFold: {
      type: Boolean,
      default: false
    },
    expandPersons: {
      type: Boolean,
      default: false
    },
    hiddenAmount: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      selfFold: true,
      customerFold: true,
      // 阶段初始数据
      initProgessInfo: {
        validate: {
          label: '客户验证',
          date: ''
        },
        analy: {
          label: '需求分析',
          date: ''
        },
        solution: {
          label: '方案/报价',
          date: ''
        },
        talks: {
          label: '谈判审核',
          date: ''
        },
        close: {
          label: '结束',
          date: ''
        },
        predeal: {
          label: '预计成交',
          date: ''
        }
      },
      parseFormatNum: _num_formatter.parseFormatNum
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'busiActiveDate', 'busiActiveConver', 'businessStatusOptions']),
    preDealTime() {
      if (!this.dealObject.predeal) return;
      return this.$moment(this.dealObject.predeal).format('YYYY-MM-DD');
    },
    getInfoByday() {
      return function (day) {};
    },
    dealStage() {
      const obj = {};
      for (const {
        label,
        value
      } of this.businessStatusOptions) {
        obj[value] = label;
      }
      obj.close = {};
      return obj;
    },
    companyTimeline() {
      return this.dealObject.timeline ? this.dealObject.timeline : [];
    },
    sellerPersonsTimeline() {
      return this.dealObject.salesman_timeline;
    },
    customerPersonsTimeline() {
      return this.dealObject.customer_timeline;
    },
    getDefaultDay() {
      let choosedDate = '';
      if (this.busiActiveDate) {
        choosedDate = this.busiActiveDate;
      } else {
        choosedDate = this.$moment().format('YYYY-MM-DD');
      }
      return choosedDate;
    },
    getIconType() {
      return function (type, status) {
        if (type === 'audio') {
          return 'phone';
        } else if (type === 'doc') {
          return 'im-fill';
        } else {
          if (status === 'absent' || status === 'expire') {
            return 'video-camera';
          } else {
            return 'video-camera-solid-2';
          }
        }
      };
    },
    getSelfInfoIcon() {
      if (this.selfFold) {
        return 'plus';
      } else {
        return 'minus';
      }
    },
    getCustomerInfoIcon() {
      if (this.customerFold) {
        return 'plus';
      } else {
        return 'minus';
      }
    },
    getBothSidesConverList() {
      this.companyTimeline.forEach(compItem => {
        const companyDate = compItem.date;
        compItem.self_person_info = [];
        compItem.customer_person_info = [];
        // 增加我方公司的人员信息
        this.addSelfPersonToInfo(compItem.self_person_info, companyDate);
        // 增加客户公司的人员信息
        this.addCustomerPersonToInfo(compItem.customer_person_info, companyDate);
      });
      return this.companyTimeline;
    }
  },
  watch: {
    dealId: {
      handler: function (val) {
        if (val) {
          const selectedList = this.getBothSidesConverList.filter(item => item.infos[0].entity_id === val);
          if (selectedList.length) {
            this.$store.commit('business/SET_BUSI_ACTIVE_DATE', selectedList[0].date);
            this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', selectedList[0].infos[0].id);
            this.$nextTick(() => {
              this.$refs['horizontal-calendar'].init();
            });
          } else {
            this.$store.commit('business/SET_BUSI_ACTIVE_DATE', '');
            this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', '');
          }
        }
      }
    }
  },
  mounted() {
    // 点击其他地方时收起日历目录
    const _this = this;
    document.addEventListener('click', e => {
      if (_this.$refs.busiCalender && !_this.$refs.busiCalender.contains(e.target)) {
        _this.foldPersonsInfo();
      }
    });
  },
  methods: {
    // 根据业务数据会话相关返回详情对象
    getDetailItem(date) {
      if (this.getBothSidesConverList.length > 0) {
        const itemDetail = this.getBothSidesConverList.find(item => {
          const isSameDate = this.$moment(item.date).isSame(date);
          if (isSameDate) {
            return item;
          }
        });
        return itemDetail || null;
      }
    },
    selectItem(id, date) {
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$moment(date).format('YYYY-MM-DD'));
    },
    changeCalenderFoldStatus() {
      this.$emit('update:isCalenderFold', !this.isCalenderFold);
      // this.calenderFold = !this.calenderFold;
    },

    foldPersonsInfo() {
      this.selfFold = true;
      this.customerFold = true;
    },
    changeFoldStatus(type) {
      if (type === 'self') {
        this.selfFold = !this.selfFold;
        if (!this.selfFold) {
          this.customerFold = true;
        }
      } else if (type === 'customer') {
        this.customerFold = !this.customerFold;
        if (!this.customerFold) {
          this.selfFold = true;
        }
      }
    },
    firstDayChange(day) {
      this.currentFirstDay = day;
    },
    changeDate(day) {
      if (this.getDetailItem(day) && this.getDetailItem(day).infos) {
        const defaultConvId = this.getDetailItem(day).infos[0].id; // 如果点击正个日期，默认选中第一个会话
        if (this.getDetailItem(day).infos[0].entity_id !== this.dealId) {
          this.$emit('cancelFilter');
        }
        this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$moment(day).format('YYYY-MM-DD'));
        this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', defaultConvId);
      }
    },
    addSelfPersonToInfo(self_person_info, companyDate) {
      const self_persons = this.sellerPersonsTimeline;
      if (self_persons && self_persons.length > 0) {
        self_persons.forEach(selfPerson => {
          const personObj = {
            id: selfPerson.id,
            name: selfPerson.name,
            position: selfPerson.label,
            convInfo: []
          };
          selfPerson.timeline.forEach(selfConv => {
            const selfConvDate = selfConv.date;
            const isSameDate = this.$moment(selfConvDate).isSame(companyDate);
            if (isSameDate) {
              personObj.convInfo = selfConv.infos;
            }
          });
          self_person_info.push(personObj);
        });
      }
    },
    addCustomerPersonToInfo(customer_person_info, companyDate) {
      const customer_persons = this.customerPersonsTimeline;
      if (customer_persons && customer_persons.length > 0) {
        customer_persons.forEach(custPerson => {
          const personObj = {
            id: custPerson.id,
            name: custPerson.name,
            role: custPerson.label,
            convInfo: []
          };
          custPerson.timeline.forEach(custConv => {
            const custConvDate = this.$moment(custConv.date).format('YYYY-MM-DD');
            const isSameDate = this.$moment(custConvDate).isSame(companyDate);
            if (isSameDate) {
              personObj.convInfo = custConv.infos;
            }
          });
          customer_person_info.push(personObj);
        });
      }
    }
  }
};
exports.default = _default;