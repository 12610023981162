import { render, staticRenderFns } from "./MyTaskCardView.vue?vue&type=template&id=065e7c0b&scoped=true&"
import script from "./MyTaskCardView.vue?vue&type=script&lang=js&"
export * from "./MyTaskCardView.vue?vue&type=script&lang=js&"
import style0 from "./MyTaskCardView.vue?vue&type=style&index=0&id=065e7c0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065e7c0b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('065e7c0b')) {
      api.createRecord('065e7c0b', component.options)
    } else {
      api.reload('065e7c0b', component.options)
    }
    module.hot.accept("./MyTaskCardView.vue?vue&type=template&id=065e7c0b&scoped=true&", function () {
      api.rerender('065e7c0b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/compliance/myTask/components/MyTaskCardView.vue"
export default component.exports