"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _vuex = require("vuex");
var _keyEventManagement = require("@/api/keyEventManagement");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KeyEventManagementHeader',
  components: {
    SpaceButton: _SpaceButton.default
  },
  data() {
    return {
      timer: null,
      taskProcessList: [],
      taskProcessTimer: null,
      isScroll: false,
      taskDeleteList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getTaskProgress();
  },
  mounted() {
    setTimeout(() => {
      this.roll(30);
    }, 1000);
  },
  destroyed() {
    this.clearTimer();
    this.clearTaskProcessTimer();
  },
  methods: {
    goHelpCenter() {
      window.open('https://www.megaview.com/help-center/doc?docx=XfJ2dqdaXoHZduxPVuTcKPHXnzf&anchor=Oj8gdzPyQoHDOvxrmZScGvu1nbU', '_blank');
    },
    goKeyEventMutualExclusion() {
      this.$router.push({
        path: _routeMap.ROUTES.KeyEventMutualExclusion
      });
    },
    roll(t) {
      this.$nextTick(() => {
        const process = document.getElementById('process');
        if (process) {
          process.scrollLeft = 0;
          this.rollStart(t);
        }
      });
    },
    rollStart(t) {
      const task1 = document.getElementById('task1');
      const process = document.getElementById('process');
      if (task1.clientWidth <= process.clientWidth) {
        this.isScroll = false;
        return;
      }
      this.isScroll = true;
      this.rollStop();
      this.taskProcessTimer = setInterval(() => {
        // 当滚动高度大于列表内容高度时恢复为0
        if (process.scrollLeft >= task1.scrollWidth) {
          process.scrollLeft = 0;
        } else {
          process.scrollLeft++;
        }
      }, t);
    },
    rollStop() {
      clearInterval(this.taskProcessTimer);
    },
    deleteProgressBar(currentItem) {
      this.taskProcessList.forEach(item => {
        if (item.task_id === currentItem.task_id) {
          this.$set(item, 'is_delete', true);
        }
      });
      this.taskDeleteList.push(currentItem.task_id);
    },
    getTaskProgress() {
      this.getCustomEventProgress();
      this.clearTimer();
      this.timer = setInterval(() => {
        this.getCustomEventProgress();
      }, 10000);
    },
    isInProcess(task_status) {
      return task_status === 'doing';
    },
    async getCustomEventProgress() {
      const res = await (0, _keyEventManagement.getCustomEventProgress)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.taskProcessList = res.results.task_result;
        this.taskProcessList.forEach(item => {
          if (this.taskDeleteList.includes(item.task_id)) {
            this.$set(item, 'is_delete', true);
          }
        });
        if (this.taskProcessList.every(item => item.task_status === 'complete')) {
          this.clearTimer();
        }
        if (this.taskProcessList.length === this.taskDeleteList.length) {
          this.clearTimer();
        }
      }
    },
    clearTimer() {
      this.timer && clearInterval(this.timer);
    },
    clearTaskProcessTimer() {
      this.taskProcessTimer && clearInterval(this.taskProcessTimer);
    }
  }
};
exports.default = _default;