"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _vue = require("vue");
var _default = (0, _vue.defineComponent)({
  name: 'RoleNode',
  inject: ['getNode'],
  data() {
    return {
      loading: false,
      scoreRuleType: 1,
      scoreRules: [],
      ruleType: 0,
      role: 'salesman',
      roleName: (0, _i18n.t)('coaching.salespersonSays'),
      branches: [],
      rules: [],
      isActive: false,
      type: 'random',
      deleteCell: () => {}
    };
  },
  computed: {
    defaultText() {
      const map = {
        salesman: '',
        customer: '',
        narrator: ''
      };
      return map[this.role];
    },
    ruleIsGenerating() {
      return (0, _i18n.t)('coaching.generating');
    },
    rulesDescription() {
      const typeLabel = [(0, _i18n.t)('coaching.whenRuleHit'), (0, _i18n.t)('coaching.whenRuleMiss'), (0, _i18n.t)('coaching.noConditionsHit')];
      const currentLanguage = localStorage.getItem('lang') || 'zh';
      return this.rules.map(item => {
        let results = typeLabel[item.type] + ' ';
        if (item.type !== 2) {
          var _item$keyword;
          results += ((_item$keyword = item.keyword) === null || _item$keyword === void 0 ? void 0 : _item$keyword.join(',')) + ' ';
        }
        if (currentLanguage === 'zh') {
          return results += '时';
        }
        return results;
      }) || [];
    },
    getScoreStatus() {
      if (this.scoreRuleType === 2) {
        return (0, _i18n.t)('coaching.noScoring');
      } else {
        var _this$branches, _this$branches$;
        return this.scoreRules.filter(item => item.text).length || !((_this$branches = this.branches) !== null && _this$branches !== void 0 && (_this$branches$ = _this$branches[0]) !== null && _this$branches$ !== void 0 && _this$branches$.text) ? '' : (0, _i18n.t)('coaching.noScoreRuleConfigured');
      }
    },
    roleIcon() {
      const iconMap = {
        narrator: 'narration',
        customer: 'robot',
        salesman: 'chat-dot-square'
      };
      return iconMap[this.role];
    }
  },
  mounted() {
    const node = this.getNode();
    this.updateData(node.getData());
    node.on('change:data', _ref => {
      let {
        current
      } = _ref;
      this.updateData(current);
    });
  },
  methods: {
    deleteNodeMethod() {
      this.deleteCell();
    },
    updateData(data) {
      for (const key in data) {
        this.$set(this, key, data[key]);
      }
    }
  }
});
exports.default = _default;