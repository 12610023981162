"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MenuContainer = _interopRequireDefault(require("./components/MenuContainer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
let isHiddenMenuBarKeyName = '';
var _default = {
  name: 'MgvMultiLevelMenu',
  components: {
    MenuContainer: _MenuContainer.default
  },
  props: {
    menuTitle: {
      type: String,
      default: ''
    },
    menuList: {
      type: Array,
      default: () => []
    },
    defaultOpeneds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isHiddenMenu: false
    };
  },
  watch: {
    isHiddenMenu(val) {
      this.$emit('updateIsHiddenMenu', val);
    }
  },
  created() {
    isHiddenMenuBarKeyName = this.$route.path.split('/')[1] + 'IsHiddenMenuBar';
    this.isHiddenMenu = !!JSON.parse(sessionStorage.getItem(isHiddenMenuBarKeyName));
  },
  methods: {
    toggleMenu() {
      this.isHiddenMenu = !this.isHiddenMenu;
      sessionStorage.setItem(isHiddenMenuBarKeyName, this.isHiddenMenu);
      this.$nextTick(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
      });
    },
    floatingMenuShow() {
      this.$refs['menu-container'].classList.value = 'menu-container floating-menu-show';
    },
    floatingMenuHidden() {
      if (this.isHiddenMenu) {
        this.$refs['menu-container'].classList.value = 'menu-container floating-menu-hidden hidden-menu';
      }
    }
  }
};
exports.default = _default;