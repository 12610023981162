"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GlobalSearch = _interopRequireDefault(require("./GlobalSearch"));
var _MessageCenterButton = _interopRequireDefault(require("./MessageCenterButton.vue"));
var _UserAvatar = _interopRequireDefault(require("./UserAvatar.vue"));
var _TaskCenterButton = _interopRequireDefault(require("./TaskCenterButton.vue"));
var _AssistButton = _interopRequireDefault(require("./AssistButton.vue"));
var _FeedbackButton = _interopRequireDefault(require("./FeedbackButton.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'NavBar',
  components: {
    MessageCenterButton: _MessageCenterButton.default,
    GlobalSearch: _GlobalSearch.default,
    UserAvatar: _UserAvatar.default,
    TaskCenterButton: _TaskCenterButton.default,
    AssistantButton: _AssistButton.default,
    FeedbackButton: _FeedbackButton.default
  },
  props: {
    isShowGlobalSearch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['isTwoFactor'])
  },
  methods: {
    goHelpCenter() {
      switch (location.hostname) {
        case 'localhost':
        case 'jirui.test.mgvai.cn':
          window.open('https://site.test.mgvai.cn/help-center/login', '_blank');
          break;
        case 'prepublish.megaview.com':
        case 'app.megaview.com':
          window.open('https://www.megaview.com/help-center/login', '_blank');
          break;
        default:
          break;
      }
    }
  }
};
exports.default = _default;