"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _videoDetail = require("@/api/videoDetail");
var _ChatUnit = _interopRequireDefault(require("@/components/ChatUnit"));
var _CommentAccessableMemberDialog = _interopRequireDefault(require("@/components/CommentAccessableMemberDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDetailChatDrawer',
  components: {
    ChatUnit: _ChatUnit.default,
    CommentAccessableMemberDialog: _CommentAccessableMemberDialog.default
  },
  provide() {
    return {
      sendComment: this.sendComment
    };
  },
  props: {
    convId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      promptConfig: {
        guideList: ['这通会话沟通了哪些内容？', '这通会话的五个关键点是什么？', '这通会话提及了哪些待办？', '客户总体的情绪是什么？', '客户的顾虑有哪些？'],
        shortcutsList: [{
          icon: 'icon-tickets-fill',
          color: '#7852e0',
          label: '会话总结',
          value: '这通会话沟通了哪些内容？'
        }, {
          icon: 'icon-reservation-2-fill',
          color: '#4461ec',
          label: '待办总结',
          value: '这通会话提及了哪些待办？'
        }, {
          icon: 'icon-smile',
          color: '#f28740',
          label: '客户情绪',
          value: '客户总体的情绪是什么？'
        }, {
          icon: 'icon-question',
          color: '#FFB433',
          label: '客户顾虑',
          value: '客户的顾虑有哪些？'
        }]
      },
      requestConfig: {
        getQuestionParams: {
          params: {
            conversation_id: this.convId
          },
          entity_type: 0
        },
        getHistoryParams: {
          conversation_id: this.convId,
          entity_type: 0
        },
        checkIsSupportParams: {
          conversation_id: this.convId,
          entity_type: 0
        }
      },
      // 以下是评论功能相关数据
      commentMemberDialogVisible: false,
      openScope: 'all',
      commentContent: '',
      visibleUserIds: [],
      visibleTreeIds: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getCustomAiQuestionList();
  },
  methods: {
    async getCustomAiQuestionList() {
      const res = await (0, _videoDetail.getCustomAiQuestionList)({
        entity_type: 0 // 0代表会话详情页，1代表BI高频问题，2代表日程页面的
      });

      if (res.code === 20000) {
        var _res$results$qas;
        const resList = (_res$results$qas = res.results.qas) === null || _res$results$qas === void 0 ? void 0 : _res$results$qas.map(item => item.qa);
        this.promptConfig.guideList.push(...resList);
      }
    },
    commentScopeChange(_ref) {
      let {
        scope,
        index
      } = _ref;
      this.openScope = scope;
      this.setcommentContent(index);
      if (scope === 'some') {
        this.commentMemberDialogVisible = true;
      } else {
        this.visibleUserIds = [];
        this.visibleTreeIds = [];
        this.sendComment();
      }
    },
    setcommentContent(index) {
      const {
        msgList
      } = this.$refs.chatUnit;
      this.commentContent = msgList[index - 1].msg + '\n' + msgList[index].msg;
    },
    handleMemberChange(ids) {
      const {
        tree_ids,
        user_ids
      } = ids;
      this.visibleUserIds = user_ids;
      this.visibleTreeIds = tree_ids;
    },
    async sendComment() {
      const params = {
        entity_id: this.convId,
        comment_id: 0,
        content: this.commentContent,
        comment_timestamp: 0,
        visiable_users: this.visibleUserIds,
        visiable_trees: this.visibleTreeIds,
        open_scope: this.openScope,
        advisor: null,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _videoDetail.commentConversation)(params);
      if (res.code === 200) {
        this.$message.success('同步至评论成功');
        this.$emit('commentSuccess');
        this.$refs.commentMember.clearMemberSelect();
      }
    }
  }
};
exports.default = _default;