"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _DialogText = _interopRequireDefault(require("@/components/Detail/DialogText"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TransText',
  components: {
    DialogText: _DialogText.default
  },
  props: {
    convInWorkSpaceId: {
      type: [Number, String],
      default: ''
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    },
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    resultList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    words: {
      type: String,
      default: ''
    },
    cutId: {
      type: String,
      default: ''
    },
    activeRoundId: {
      type: [Number, String],
      default: null
    },
    origin: {
      type: String,
      default: 'native'
    },
    uploadType: {
      type: String,
      default: ''
    },
    hasRole: {
      type: Boolean,
      default: true
    },
    mediaType: {
      type: String,
      default: 'audio'
    },
    rightBarActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // resultList: [],
      isShowSearch: false,
      // 是否在搜索模式下
      isShowSearchList: false,
      // 在搜索模式下，是否展示搜索列表
      dialogArray: [],
      searchWords: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curtime', 'curconvid', 'curorder', 'is_user_scroll', 'is_video_pause'])
  },
  watch: {
    words(value) {
      this.searchWords = value;
      this.handleShowSearch();
    }
  },
  mounted() {
    if (!this.words) {
      return false;
    } else {
      this.searchWords = this.words;
      this.handleShowSearch();
    }
  },
  methods: {
    changeEventBack(val) {
      this.$emit('changeEventBack', val);
    },
    changeKeyWordBack(val) {
      this.$emit('changeKeyWordBack', val);
    },
    handleShowSearch() {
      this.isShowSearch = true;
      this.isShowSearchList = true;
    },
    realFormatSecond(second) {
      var secondType = typeof second;
      if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);
        var hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        var mimute = Math.floor(second / 60);
        second = second - mimute * 60;
        return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
      } else {
        return '00:00';
      }
    },
    goBack() {
      if (this.isShowSearchList) {
        this.isShowSearch = false;
        this.isShowSearchList = false;
        this.$store.dispatch('video/get_cur_order', {
          current_time: this.curtime,
          time: new Date().getTime()
        });
      } else {
        this.isShowSearchList = true;
      }
    },
    goKeyDetail(order, item) {
      this.isShowSearch = true;
      this.isShowSearchList = false;
      this.$nextTick(() => {
        this.$refs.dialogText.goKeyDetail(order, item);
      });
    },
    isChiStr(str) {
      const pattern = new RegExp('[\u4E00-\u9FA5]+');
      if (pattern.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
exports.default = _default;