"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "other-page-carrying-filter"
  }, [_c("span", [_vm._v(_vm._s(_vm.label))]), _c("el-button", {
    staticClass: "clear-btn",
    attrs: {
      type: "text",
      size: "small",
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("clearFilter");
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;