"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'HintContentAiCustomerProcess',
  props: {
    hints: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const activeNames = (0, _vue.ref)([]);
    (0, _vue.watch)(() => props.hints, val => {
      if (val.length) {
        activeNames.value = val[0].id;
      }
    }, {
      immediate: true
    });
    return {
      __sfc: true,
      props,
      activeNames
    };
  }
};
exports.default = _default;