"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer test-records-drawer",
    attrs: {
      visible: _vm.visible,
      title: "查看测试记录",
      size: "1000px",
      "before-close": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "select-version"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择模型版本"
    },
    model: {
      value: _setup.activeVersion,
      callback: function ($$v) {
        _setup.activeVersion = $$v;
      },
      expression: "activeVersion"
    }
  }, _vm._l(_setup.allVersionsList, function (item) {
    return _c("el-option", {
      key: item.version,
      attrs: {
        label: `V${item.version}`,
        value: item.version
      }
    });
  }), 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "records-list",
    attrs: {
      data: _setup.recordsList,
      height: "calc(100vh - 178px)"
    }
  }, [_vm._l(_setup.recordsHeader, function (column) {
    return [column.prop !== "operate" ? _c("el-table-column", {
      key: column.prop,
      attrs: {
        prop: column.prop,
        label: column.label,
        "min-width": column.minWidth
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [column.prop === "create_at" ? [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm:ss")) + " ")] : ["precision", "recall", "f1"].includes(column.prop) ? [_c("span", {
            staticClass: "strong-text"
          }, [_vm._v(_vm._s(_setup.handlePercentage(row[column.prop])))])] : column.prop === "model_version" ? [_vm._v(" V" + _vm._s(row.model_version) + " ")] : column.prop === "dataset" ? [_c("div", {
            staticClass: "dataset-name-container",
            on: {
              click: function ($event) {
                return _setup.goDataSet(row.dataset_id);
              }
            }
          }, [_c("i", {
            staticClass: "iconfont icon-dataset-fill"
          }), _c("span", {
            staticClass: "dataset-name"
          }, [_vm._v(_vm._s(row.dataset))])])] : column.prop === "dataset_label" ? [_c("div", {
            staticClass: "label-tag"
          }, [_c("span", {
            staticClass: "label-name"
          }, [_vm._v(_vm._s(row.dataset_label))]), _c("el-divider", {
            attrs: {
              direction: "vertical"
            }
          }), _c("span", {
            staticClass: "positive-count"
          }, [_vm._v(_vm._s(row.sentence_count))])], 1)] : column.prop === "create_user" ? [_c("MgvAvatarName", {
            attrs: {
              size: 24,
              src: row.create_user.avatar,
              name: row.create_user.name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(row.create_user.name))])] : [_vm._v(" " + _vm._s(row[column.prop]) + " ")]];
        }
      }], null, true)
    }) : _c("el-table-column", {
      key: column.prop,
      attrs: {
        prop: column.prop,
        label: column.label,
        "min-width": column.minWidth,
        fixed: "right"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("el-button", {
            attrs: {
              type: "text"
            },
            on: {
              click: function ($event) {
                return _setup.openTestDetail(row);
              }
            }
          }, [_vm._v("查看测试详情")])];
        }
      }], null, true)
    })];
  })], 2), _setup.detailDrawerVisible ? _c(_setup.TestDetailDrawer, {
    attrs: {
      visible: _setup.detailDrawerVisible,
      "model-id": _vm.modelId,
      "model-version": _setup.activeVersion,
      "praf-task-id": _setup.activeRow.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.detailDrawerVisible = $event;
      },
      reTraining: function ($event) {
        return _setup.emit("reTraining");
      }
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;