"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _client = require("@/api/client");
var _doubleCall = require("@/api/doubleCall");
var _routeMap = require("@/router/routeMap");
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _HeaderFilters = _interopRequireDefault(require("@/views/clients/HeaderFilters"));
var _ClientTable = _interopRequireDefault(require("@/views/clients/ClientTable"));
var _AccountSeaHeaderFilters = _interopRequireDefault(require("@/views/clients/AccountSeaHeaderFilters"));
var _AccountSeaTable = _interopRequireDefault(require("@/views/clients/AccountSeaTable"));
var _CluesAllotDialog = _interopRequireDefault(require("@/components/CluesAllotDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClientsPage',
  components: {
    HeaderFilters: _HeaderFilters.default,
    DynamicPopover: _DynamicPopover.default,
    ClientTable: _ClientTable.default,
    AccountSeaHeaderFilters: _AccountSeaHeaderFilters.default,
    AccountSeaTable: _AccountSeaTable.default,
    AllotDialog: _CluesAllotDialog.default
  },
  data() {
    return {
      activeName: 'account',
      tableLoading: false,
      headerList: null,
      seaHeaderList: null,
      list: null,
      listParams: {
        // 客户表格的分页、筛选、排序
        page_at: 1,
        page_size: 10,
        search_val: '',
        only_my_accounts: 0,
        sort_column: 'create_at',
        sort_order: 'descending'
      },
      total: 0,
      accountMultipleVal: null,
      // 批量操作选取的客户的信息  客户批量操作
      accountSeaTableLoading: false,
      accountSeaList: null,
      accountSeaListParams: {
        // 客户公海表格的分页、筛选、排序
        page_at: 1,
        page_size: 10,
        search_val: '',
        sort_column: 'add_pool_time',
        sort_order: 'descending'
      },
      accountSeaListTotal: 0,
      accountSeaVal: null,
      // 批量操作选取的客户公海信息  客户公海批量操作
      allotDialogVisible: false,
      allotDialogProps: {},
      // 双呼按钮的权限
      doubleCallPermission: 0,
      sortColumnList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_video_permission', 'has_outbound', 'webRoutes', 'api_permissions'])
  },
  watch: {
    activeName(val) {
      var _this$$route, _this$$route$query;
      // eslint-disable-next-line no-undef
      if (!((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id)) {
        this.$router.replace({
          query: {
            tab: val
          }
        });
      }
    }
  },
  created() {
    this.getSortColumn();
    if (!this.webRoutes.find(item => item.name === 'ClientIndex') && this.webRoutes.find(item => item.name === 'AccountSea')) {
      this.$router.replace(`${_routeMap.ROUTES.account}?tab=accountSea`);
    }
    if (this.$route.query && this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    }
  },
  mounted() {
    this.$bus.$on('clientUpdateList', this.freshCurrentList);
  },
  beforeDestroy() {
    this.$bus.$off('clientUpdateList');
  },
  methods: {
    async getSortColumn() {
      const res = await (0, _client.getSortColumn)();
      if (res.code === 20000) {
        this.sortColumnList = res.results.sort_field;
      }
    },
    getTableHead(type, val) {
      if (type === 'account') {
        this.headerList = val;
        this.getClientList(this.listParams);
        if (this.has_outbound) {
          this.getDoubleCallPermission();
        }
      } else {
        this.seaHeaderList = val;
        this.getAccountSeaList(this.accountSeaListParams);
      }
    },
    async getDoubleCallPermission() {
      const res = await (0, _doubleCall.getDoubleCallPermission)();
      if (res.code === 200) {
        this.doubleCallPermission = res.results.double_call;
      }
    },
    // 客户公海多选操作
    selectionChange(val) {
      this.accountSeaVal = val;
    },
    // 客户多选操作
    accountSelectionChange(val) {
      this.accountMultipleVal = val;
    },
    // 新建客户成功后添加至第一项
    createClientSuccess(val) {
      val.highlight = true;
      this.list.unshift(val);
    },
    // 变更数据成功之后刷新页面
    freshCurrentList() {
      if (this.$refs['clientFilter'].searchValue === '' && this.$refs['clientFilter'].departmentValue === '全部客户') {
        this.initListParams();
        this.getClientList(this.listParams);
      } else {
        this.$refs['clientFilter'].searchValue = '';
        this.$refs['clientFilter'].departmentValue = '全部客户';
      }
    },
    initListParams() {
      this.listParams.search_val = '';
      this.listParams.only_my_accounts = 0;
      this.listParams.page_at = 1;
    },
    initAccountSeaListParams() {
      this.accountSeaListParams.search_val = '';
      this.accountSeaListParams.page_at = 1;
    },
    // 新建公海客户成功后添加至第一项
    createAccountSeaSuccess(val) {
      val.highlight = true;
      this.accountSeaList.unshift(val);
    },
    // 变更公海客户数据成功之后刷新页面
    freshAccountSeaList() {
      if (this.$refs['accountSeaHeaderFilter'].searchValue === '') {
        this.initAccountSeaListParams();
        this.getAccountSeaList(this.accountSeaListParams);
      } else {
        this.$refs['accountSeaHeaderFilter'].searchValue = '';
      }
    },
    refreshData() {
      if (this.activeName === 'account') {
        this.freshCurrentList();
      } else {
        this.freshAccountSeaList();
      }
    },
    // 把客户移入公海成功刷新客户列表
    freshClientTable() {
      this.listParams.page_at = 1;
      this.getClientList(this.listParams);
    },
    // 客户分页数量变化
    sizeChange(val) {
      this.listParams.page_size = val;
      this.listParams.page_at = 1;
      this.getClientList(this.listParams);
    },
    // 客户当前页数发生变化
    currentChange(val) {
      const maxPage = 10000 / this.listParams.page_size;
      this.listParams.page_at = this.listParams.page_size * val > 10000 ? maxPage : val;
      this.getClientList(this.listParams);
    },
    // 客户公海数量变化
    accountSeaSizeChange(val) {
      this.accountSeaListParams.page_size = val;
      this.accountSeaListParams.page_at = 1;
      this.getAccountSeaList(this.accountSeaListParams);
    },
    // 客户公海当前页数发生变化
    accountSeaCurrentChange(val) {
      const maxPage = 10000 / this.accountSeaListParams.page_size;
      this.accountSeaListParams.page_at = this.accountSeaListParams.page_size * val > 10000 ? maxPage : val;
      this.getAccountSeaList(this.accountSeaListParams);
    },
    // 客户的筛选项发生变化
    headerFiltersChange(_ref) {
      let {
        search_val,
        only_my_accounts,
        user_id
      } = _ref;
      this.listParams.search_val = search_val;
      this.listParams.only_my_accounts = only_my_accounts;
      this.listParams.page_at = 1;
      this.listParams.user_id = JSON.stringify(user_id);
      this.getClientList(this.listParams);
    },
    // 客户公海的筛选项发生变化
    accountSeaHeaderFilterChange(params) {
      this.accountSeaListParams.search_val = params.search_val;
      this.accountSeaListParams.page_at = 1;
      this.getAccountSeaList(this.accountSeaListParams);
    },
    // 获取客户列表
    async getClientList(data) {
      this.tableLoading = true;
      const res = await (0, _client.getClientList)(data);
      if (res.code === 200) {
        this.list = res.results.items;
        this.total = res.results.items_total;
      }
      this.tableLoading = false;
    },
    // 获取客户公海列表
    async getAccountSeaList(data) {
      this.accountSeaTableLoading = true;
      const res = await (0, _client.getAccountSeaList)(data);
      if (res.code === 200) {
        this.accountSeaList = res.results.items;
        this.accountSeaListTotal = res.results.items_total;
      }
      this.accountSeaTableLoading = false;
    },
    // 处理客户公海的批量操作
    handleBulkAction(action) {
      // 先处理好客户ids
      const bulkIdsArray = [];
      if (this.accountSeaVal) {
        this.accountSeaVal.forEach(item => {
          bulkIdsArray.push(item.id);
        });
      }
      switch (action) {
        case 'claimToMe':
          this.$refs.accountSeaTable.claimToMe(bulkIdsArray);
          break;
        case 'allocateToAnyone':
          this.$refs.accountSeaTable.allocateToAnyone(bulkIdsArray);
          break;
        default:
          break;
      }
    },
    // 处理客户的批量操作
    handleAccountBulkAction(action) {
      const bulkIdsArray = [];
      if (this.accountMultipleVal) {
        this.accountMultipleVal.forEach(item => {
          bulkIdsArray.push(item.id);
        });
      }
      switch (action) {
        case 'changeFollower':
          this.$refs['clientTable'].changeFollowUpBy(bulkIdsArray);
          break;
        case 'transferToTheAccountSea':
          this.$refs['clientTable'].moveAccountSeaMultiple(bulkIdsArray);
          break;
        default:
          break;
      }
    },
    // 分配或者更改跟进人
    allocateToAnyone(ids, type) {
      this.allotDialogProps = {
        type,
        accountId: ids
      };
      this.allotDialogVisible = true;
    },
    getClientTableHead() {
      return _client.getClientTableHead;
    },
    getClientSeaTableHead() {
      return _client.getClientSeaTableHead;
    },
    saveClientTableHead() {
      return _client.saveClientTableHead;
    },
    saveClientPoolTableHead() {
      return _client.saveClientPoolTableHead;
    },
    handleSortOrder(prop, order, currentPage) {
      this.listParams.page_at = 1;
      this.listParams.sort_column = order === 'default' ? '' : prop;
      this.listParams.sort_order = order;
      if (currentPage === 'accountSea') {
        this.getAccountSeaList(this.listParams);
      } else {
        this.getClientList(this.listParams);
      }
    }
  }
};
exports.default = _default;