"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PlayVoice = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoice.vue"));
var _PlayVoiceMixin = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoiceMixin.js"));
var _recorderCore = _interopRequireDefault(require("recorder-core"));
require("recorder-core/src/engine/mp3");
require("recorder-core/src/engine/mp3-engine");
require("recorder-core/src/extensions/lib.fft");
require("recorder-core/src/extensions/frequency.histogram.view");
var _timeFormatter = require("@/utils/time-formatter");
var _coachingCenter = require("@/api/coachingCenter");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeAudioRecorder',
  components: {
    PlayVoice: _PlayVoice.default
  },
  mixins: [_PlayVoiceMixin.default],
  props: {
    reply: {
      type: Object,
      default: () => ({})
    },
    courseType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isRecording: false,
      duration: 0,
      isScoring: false,
      score: -1,
      comment: '',
      // 智能点评
      content: '',
      // 销售回答
      frequencyHistogramView: null,
      frequencyHistogramViewBoxId: 'frequency-histogram-view-box-' + (0, _nanoid.nanoid)()
    };
  },
  computed: {
    contentClass() {
      return this.isRecording ? this.courseType === 2 ? 'hidden' : 'current' : '';
    },
    scoreClass() {
      const taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query') || '{}');
      return this.score > -1 ? this.score < taskConfig.score ? 'danger-score' : 'success-score' : '';
    }
  },
  watch: {
    reply: {
      handler(val) {
        this.score = val.reply_score;
        this.comment = val.comment;
        this.content = val.content;
      },
      immediate: true
    }
  },
  methods: {
    async startRecord() {
      if (_recorderCore.default.IsOpen()) {
        this.$message.warning('请结束当前录音后再进行其他录音');
        return;
      }
      this.mediaRecorder = (0, _recorderCore.default)({
        type: 'mp3',
        sampleRate: 16000,
        bitRate: 16,
        onProcess: this.onProcess
      });
      this.mediaRecorder.open(() => {
        this.$emit('recording');
        this.mediaRecorder.start();
        this.isRecording = true;
        if (this.courseType === 2) {
          this.$nextTick(() => {
            this.frequencyHistogramView = _recorderCore.default.FrequencyHistogramView({
              elem: `#${this.frequencyHistogramViewBoxId}`,
              lineCount: 24,
              // 柱子数量
              spaceWidth: 10,
              // 柱子间隔
              minHeight: 4,
              position: 0,
              stripeEnable: false,
              // 柱子顶部的峰值小条（被柱子顶着跳动的小块）
              mirrorEnable: true,
              // 左右镜像
              linear: [0, '#00B7B8', 0.5, '#00B7B8', 1, '#00B7B8']
            });
          });
        }
      }, () => {
        this.$message.warning(this.$t('coaching.pleaseOpenTheMicrophone'));
      });
    },
    onProcess(buffers, powerLevel, duration, sampleRate) {
      var _this$frequencyHistog;
      this.duration = (0, _timeFormatter.secondToTime)(parseInt(duration / 1000));
      (_this$frequencyHistog = this.frequencyHistogramView) === null || _this$frequencyHistog === void 0 ? void 0 : _this$frequencyHistog.input(buffers[buffers.length - 1], powerLevel, sampleRate);
      // 录制上限改为5min
      duration > 5 * 60 * 1000 && this.endRecord();
    },
    endRecord() {
      this.duration && this.mediaRecorder.stop((blob, duration) => {
        this.mediaRecorder.close();
        this.mediaRecorder = null;
        this.isRecording = false;
        this.$emit('stopRecord');
        this.duration = '';
        this.uploadRecord(blob, duration);
      });
    },
    async uploadRecord(blob, duration) {
      const params = {
        file: blob,
        duration,
        reply_id: this.reply.reply_id
      };
      this.isScoring = true;
      const res = await (0, _coachingCenter.uploadReplyRecord)(params).finally(() => {
        this.isScoring = false;
      });
      if (res.code === 20000) {
        const {
          is_async,
          score,
          repeat_count,
          comment,
          content,
          reply_record
        } = res.results;
        this.score = score;
        this.comment = comment;
        this.content = content;
        this.$message.success('上传成功');
        this.$emit('finished', this.score, is_async, this.reply.reply_id, repeat_count, reply_record);
      }
    }
  }
};
exports.default = _default;