"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "inter-info"
  }, [_c("header", {
    staticClass: "item-title"
  }, [_vm.detailResult.type === "deal" ? _c("div", {
    staticClass: "title-text"
  }, [_vm._v("相关商机")]) : _vm._e(), _vm.detailResult.type === "lead" ? _c("div", {
    staticClass: "title-text"
  }, [_vm._v("相关线索")]) : _vm._e(), _vm.detailResult.type === "account" ? _c("div", {
    staticClass: "title-text"
  }, [_vm._v(" 相关客户 ")]) : _vm._e()]), _vm._l(_vm.info, function (item, index) {
    return _c("section", {
      key: index,
      staticClass: "info-content"
    }, [_c("div", {
      staticClass: "info-item"
    }, [_c("div", {
      staticClass: "info-item-title"
    }, [_vm._v(_vm._s(item.label))]), _c("div", {
      staticClass: "info-item-content",
      class: {
        "info-item-content-tag": _vm.itemParse(item),
        "info-item-content-color": _vm.itemName(item)
      },
      on: {
        click: function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.formatValue(item) || "--") + " ")])])]);
  }), _vm.customizeField && _vm.customizeField.length ? _vm._l(_vm.customizeField, function (item, index) {
    return _c("section", {
      key: "key" + index,
      staticClass: "info-content"
    }, [_c("div", {
      staticClass: "info-item"
    }, [_c("div", {
      staticClass: "info-item-title"
    }, [_vm._v(_vm._s(Object.keys(item)[0]))]), _c("div", {
      staticClass: "info-item-content"
    }, [_vm._v(" " + _vm._s(Object.values(item)[0] || "--") + " ")])])]);
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;