"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-event-mutual-exclusion-header"
  }, [_c("div", {
    staticClass: "key-event-mutual-exclusion-header__title"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-arrow-left",
      color: "info",
      type: "text",
      size: "medium"
    },
    on: {
      click: _setup.goBack
    }
  }), _c("span", [_vm._v("互斥策略")]), _c(_setup.SpaceButton)], 1), _c("div", {
    staticClass: "key-event-mutual-exclusion-header__operate"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "primary"
    },
    on: {
      click: _setup.createMutualExclusion
    }
  }, [_vm._v("互斥策略")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;