"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _default = {
  __name: 'KeyEventConfig',
  props: {
    keyEventConfig: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change', 'keyEventVisible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const {
      keyEventConfig
    } = (0, _vue.toRefs)(props);
    const localKeyEventConfig = (0, _vue.ref)([]);
    (0, _vue.watch)(() => keyEventConfig.value, val => {
      localKeyEventConfig.value = val;
    });
    const store = (0, _useVuex.useStore)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const count = (0, _vue.computed)(() => localKeyEventConfig.value.length);

    // 获取级联数据
    const keyEventOptions = (0, _vue.ref)([]);
    const loading = (0, _vue.ref)(false);
    const getKeyEventOptions = async () => {
      loading.value = true;
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: workspaceId.value
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        keyEventOptions.value = res.results.events;
      }
    };
    (0, _vue.watch)(workspaceId, () => getKeyEventOptions(), {
      immediate: true
    });

    // 清空关键事件
    const reset = () => {
      localKeyEventConfig.value = [];
      emit('change', []);
    };
    const handleChange = () => {
      emit('change', localKeyEventConfig.value);
    };

    // 更新级联面板的宽度
    const cascaderWidth = (0, _vue.ref)('');
    const handlePopoverShow = async () => {
      emit('keyEventVisible', true);
      cascaderWidth.value = 130;
      updatePopperWidth();
    };
    const keyEventShowConfigPopover = (0, _vue.ref)(null);
    const keyEventCascaderPanel = (0, _vue.ref)(null);
    const expandChange = async () => {
      await (0, _vue.nextTick)();
      const cascaderPanelWidth = keyEventCascaderPanel.value.$el.offsetWidth;
      if (cascaderWidth.value < cascaderPanelWidth) {
        cascaderWidth.value = cascaderPanelWidth;
      }
      updatePopperWidth();
    };
    const updatePopperWidth = async () => {
      await (0, _vue.nextTick)();
      keyEventCascaderPanel.value.$el.style.minWidth = cascaderWidth.value + 'px';
      keyEventShowConfigPopover.value.updatePopper();
    };
    const handlePopoverHide = () => {
      emit('keyEventVisible', false);
    };
    return {
      __sfc: true,
      props,
      keyEventConfig,
      localKeyEventConfig,
      store,
      workspaceId,
      emit,
      count,
      keyEventOptions,
      loading,
      getKeyEventOptions,
      reset,
      handleChange,
      cascaderWidth,
      handlePopoverShow,
      keyEventShowConfigPopover,
      keyEventCascaderPanel,
      expandChange,
      updatePopperWidth,
      handlePopoverHide
    };
  }
};
exports.default = _default;