"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _fetchRequest = _interopRequireDefault(require("@/utils/fetch-request"));
var _elementUi = require("element-ui");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _errorLog = require("@/utils/error-log");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KnowledgePointExtract',
  props: {
    catalogId: {
      type: Number,
      default: 0
    }
  },
  emits: ['close', 'goBack', 'reextract'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const isGenerating = (0, _vue.ref)(false);
    const aiSentences = (0, _vue.ref)([]);
    const aiKnowledgePoints = (0, _vue.ref)([]);
    const cancelRequests = [];
    const knowledgePointExtractRef = (0, _vue.ref)(null);
    const scrollToBottom = function () {
      let bottom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const el = knowledgePointExtractRef.value;
      el && el.scrollTo({
        top: bottom ? el.scrollHeight : 0
      });
    };

    // 获取ai生成知识点流式数据
    const generatingAiSentence = async (body, index) => {
      const controller = new AbortController();
      cancelRequests.push(controller);
      try {
        const res = await (0, _fetchRequest.default)('/webapi/training/knowledge/extract', {
          method: 'POST',
          body: JSON.stringify(body),
          signal: controller.signal
        }).finally(() => {
          isGenerating.value = false;
        });
        // eslint-disable-next-line no-undef
        const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
        while (!aiSentences.value[index].done) {
          const {
            done,
            value
          } = await reader.read();
          if (done) {
            aiSentences.value[index].done = done;
            break;
          }
          aiSentences.value[index].sentence += value;
          scrollToBottom();
        }
      } catch (error) {
        (0, _errorLog.postErrorLog)('智能提取知识点解析数据失败', error);
        aiSentences.value[index].done = true;
      }
    };
    const isExtractDone = (0, _vue.computed)(() => {
      return aiSentences.value.every(item => item.done);
    });

    // 删除知识点
    const deleteKnowledge = index => {
      aiKnowledgePoints.value.splice(index, 1);
    };
    // 删除知识点话术
    const deleteReply = (index, replyIndex) => {
      aiKnowledgePoints.value[index].reply.splice(replyIndex, 1);
      if (!aiKnowledgePoints.value[index].reply.length) deleteKnowledge(index);
    };
    const stopAllRequest = () => {
      cancelRequests.forEach(request => {
        request && request.abort();
      });
    };
    const init = () => {
      cancelRequests.length = 0;
      aiKnowledgePoints.value = [];
      aiSentences.value = [];
    };
    const closeKnowledgeExtract = event => {
      if (isGenerating.value) {
        // 停止接口请求
        stopAllRequest();
        init();
        emit(event);
      } else {
        _elementUi.MessageBox.confirm('退出后，已生成的内容将会清空', '确认退出？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'intelligent-extract-confirm'
        }).then(() => {
          stopAllRequest();
          emit(event);
        }).catch(() => {});
      }
    };
    const saveKnowledgePointLoading = (0, _vue.ref)(false);
    function saveKnowledgePoint() {
      let requestsData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : aiKnowledgePoints.value;
      let index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : aiKnowledgePoints.value.length - 1;
      saveKnowledgePointLoading.value = true;
      if (index < 0) {
        // 所有请求已发送完成
        saveKnowledgePointLoading.value = false;
        emit('refreshKnowledgeList');
        return;
      }
      const {
        name,
        reply
      } = requestsData[index];
      const params = {
        name,
        catalog_id: props.catalogId,
        scene_reply_list: [{
          scene_id: 0,
          scene_content: '',
          reply_list: reply.map(replyItem => {
            return {
              reply_id: 0,
              reply_content: replyItem
            };
          })
        }]
      };
      (0, _knowledgeLibrary.createKnowledge)(params).finally(() => {
        saveKnowledgePoint(undefined, --index);
      });
    }
    // 保存知识点
    // const saveKnowledgePoint = () => {
    // Promise.all(
    //   aiKnowledgePoints.value.map(async ({ name, reply }) => {
    //     return await createKnowledge({
    //       name,
    //       catalog_id: props.catalogId,
    //       scene_reply_list: [
    //         {
    //           scene_id: 0,
    //           scene_content: '',
    //           reply_list: reply.map((replyItem) => {
    //             return {
    //               reply_id: 0,
    //               reply_content: replyItem
    //             };
    //           })
    //         }
    //       ]
    //     });
    //   })
    // ).then(() => {
    //   saveKnowledgePointLoading.value = false;
    //   emit('refreshKnowledgeList');
    // });
    // };

    // 提取知识点-根据文件循环发起请求
    const getAiExtractKnowledgeList = function () {
      let fileList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      aiKnowledgePoints.value = [];
      isGenerating.value = true;
      fileList.forEach((_ref2, index) => {
        let {
          file_path
        } = _ref2;
        aiSentences.value.push({
          sentence: '',
          done: false
        });
        generatingAiSentence({
          file_path
        }, index);
      });
    };

    // 将ai提取的知识点解析为数组
    const transformStringToArray = str => {
      const lines = str.split('\n');
      const result = [];
      let currentObj = {
        name: '',
        reply: []
      };
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.startsWith('客户说的话')) {
          if (currentObj.name && currentObj.reply.length) {
            result.push(currentObj);
          }
          currentObj = {
            name: line.substring(line.indexOf('：') + 1),
            reply: []
          };
        } else if (line.startsWith('销售对客户给出的回答')) {
          currentObj.reply.push(line.substring(line.indexOf('：') + 1));
        }
      }
      if (currentObj.name && currentObj.reply.length) {
        result.push(currentObj);
      }
      return result;
    };

    // 重新提取
    const reextract = () => {
      init();
      emit('reextract');
    };
    (0, _vue.watch)(() => isExtractDone.value, val => {
      if (val) {
        scrollToBottom(false);
        aiKnowledgePoints.value = aiSentences.value.map(item => transformStringToArray(item.sentence)).flat();
      }
    });
    expose({
      getAiExtractKnowledgeList
    });
    return {
      __sfc: true,
      props,
      isGenerating,
      aiSentences,
      aiKnowledgePoints,
      cancelRequests,
      knowledgePointExtractRef,
      scrollToBottom,
      generatingAiSentence,
      isExtractDone,
      deleteKnowledge,
      deleteReply,
      emit,
      stopAllRequest,
      init,
      closeKnowledgeExtract,
      saveKnowledgePointLoading,
      saveKnowledgePoint,
      getAiExtractKnowledgeList,
      transformStringToArray,
      reextract
    };
  }
};
exports.default = _default;