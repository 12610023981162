"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    attrs: {
      size: "small",
      type: _vm.renderInfo.btn_type ? _vm.renderInfo.btn_type : "primary",
      plain: "",
      disabled: _vm.operateData.config && _vm.operateData.config.is_disable
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click", _vm.operateData);
      }
    }
  }, [_vm._v(_vm._s(_vm.renderInfo.text))]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;