"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "konwledge-search-content"
  }, [_vm.knowledgeOptions.length ? [_c("mg-infinite-scroll", {
    attrs: {
      nomore: _vm.noMore,
      loading: _vm.loading,
      disabled: _vm.disabled
    },
    on: {
      loadMore: _vm.getSearchKnowledges
    }
  }, _vm._l(_vm.knowledgeOptions, function (item) {
    return _c("div", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("option-template", {
      attrs: {
        "is-multiple": "",
        item: item
      },
      on: {
        checkKnowledge: _vm.handleCheckKnowledge
      }
    })], 1);
  }), 0)] : _vm._e(), !_vm.knowledgeOptions.length && !_vm.loading ? _c("NoData", {
    attrs: {
      icon: "icon-a-Property1not-found"
    }
  }, [_c("span", {
    staticClass: "tips",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_vm._v("未搜索到“"), _c("span", [_vm._v(_vm._s(_vm.searchValue))]), _vm._v("”相关结果")])]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;