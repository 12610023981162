"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-task-card-view"
  }, [_c("div", {
    staticClass: "team-task-card-view-container"
  }, _vm._l(_vm.cardList, function (cardItem, index) {
    return _c("task-card", {
      key: index,
      attrs: {
        "card-item": cardItem
      },
      on: {
        clickTaskCard: _vm.goTaskDetail,
        cancelComplianceTask: function ($event) {
          return _vm.$emit("cancelComplianceTask", $event);
        },
        deleteComplianceTask: function ($event) {
          return _vm.$emit("deleteComplianceTask", $event);
        }
      }
    });
  }), 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;