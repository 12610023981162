"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HotData = _interopRequireDefault(require("./HotData"));
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetListCard',
  components: {
    HotData: _HotData.default
  },
  props: {
    snippet: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    btnVisible() {
      const {
        moveSnippetToFolder,
        editSnippet,
        deleteSnippet
      } = this.interfacePermission;
      return [moveSnippetToFolder, editSnippet, deleteSnippet].includes(true);
    }
  },
  inject: {
    getContentType: {
      from: 'getContentType',
      default: () => () => {}
    }
  },
  methods: {
    getDuration: _commonFunc.getDuration,
    goSnippetDetail() {
      if (this.snippet.status !== 'complete') return;
      const routerObj = {
        path: _routeMap.ROUTES.libraryDetail,
        query: {
          id: this.snippet.id,
          type: this.snippet.isMedia ? 'media' : 'snippet'
        }
      };
      if (this.showCheckbox) {
        const routeData = this.$router.resolve(routerObj);
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routerObj);
      }
    },
    handleCommand(type, data) {
      this.$emit('operation', {
        type,
        data
      });
    },
    checkChange($event, item) {
      this.$emit('snippetCheckedChange', item);
    }
  }
};
exports.default = _default;