"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _UserOperationPop = _interopRequireDefault(require("./UserOperationPop"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
var _organization = require("@/api/organization");
var _user = require("@/api/user");
var _OrgSwitchList = _interopRequireDefault(require("./OrgSwitchList.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserAvatar',
  components: {
    UserOperationCard: _UserOperationPop.default,
    ImageAvatar: _ImageAvatar.default,
    OrgSwitchList: _OrgSwitchList.default
  },
  data() {
    return {
      userPopperOptions: {},
      switchVisible: false,
      organizations: [],
      invitations: [],
      activeOrgId: null,
      selectType: '',
      sureText: '切 换'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['avatar', 'name', 'orgId', 'login_account', 'login_method', 'orgName', 'userType', 'trialExpireDate', 'isDeveloper', 'org_display_info']),
    userCardProps() {
      return {
        name: this.name,
        loginAccount: this.login_account,
        loginMethod: this.login_method,
        organization: this.org_display_info.org_name,
        userType: this.userType,
        trialExpireDate: this.trialExpireDate
      };
    }
  },
  watch: {
    orgId(val) {
      this.activeOrgId = val;
    }
  },
  methods: {
    async userOperation(type) {
      let isProtect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.$refs['poper-user-operation'].showPopper = false;
      if (isProtect) {
        const res = await this.createMeetingProtect();
        if (!res) return;
      }
      this[type] && this[type]();
    },
    async createMeetingProtect() {
      if (!localStorage.getItem('isInMeeting')) return true;
      try {
        await this.$confirm('您有会议未结束，继续切换登录状态可能会无法正常进行会议。', '提 示', {
          confirmButtonText: '继 续',
          cancelButtonText: '取 消'
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    goOrgManagement() {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.set
      });
      window.open(routeData.href, '_blank');
    },
    goWebsite() {
      window.open(this.$config.website, '_blank');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      if (this.$route.path === _routeMap.ROUTES.conversationDetail || this.$route.path === _routeMap.ROUTES.dealDetail) {
        this.$router.push(_routeMap.ROUTES.login);
      } else {
        this.$router.push(`${_routeMap.ROUTES.login}?redirect=${this.$route.fullPath}`);
      }
    },
    goPersonalCenter() {
      this.$router.push({
        path: _routeMap.ROUTES.personalCenter
      });
    },
    async openSwitchDialog() {
      await this.getOrgList({
        is_developer: this.isDeveloper
      });
      this.switchVisible = true;
      this.activeOrgId = this.orgId;
    },
    async getOrgList(params) {
      const res = await (0, _user.getOrgList)(params);
      this.organizations = res.results.organizations;
      this.invitations = res.results.invitations;
    },
    selectOrg(id, type) {
      this.activeOrgId = id;
      this.selectType = type;
      if (this.selectType === 'invit') {
        this.sureText = '加入并切换';
      } else {
        this.sureText = '切 换';
      }
    },
    switchOrg() {
      const id = this.activeOrgId;
      this.swichOrgApi(id);
    },
    // 切换团队
    swichOrgApi(id) {
      const postData = {
        organization_id: id,
        is_developer: this.isDeveloper
      };
      (0, _organization.switchOrg)(postData).then(res => {
        if (res.code === 40402) {
          return;
        }
        if (res.code === 20000) {
          this.$store.commit('user/SET_ORG_ID', id);
          // 在切换团队时，如果有外呼，先把外呼功能禁掉，再刷新页面，否则会被浏览器拦截
          if (this.has_outbound) {
            this.$store.commit('user/SET_HAS_OUTBOUND', false);
          }
          const homeUrl = window.location.protocol + '//' + window.location.host + '/';
          window.open(homeUrl, '_self');
          this.switchVisible = false;
          // 清除ssessionstorage 内容
          sessionStorage.clear();
        }
      });
    }
  }
};
exports.default = _default;