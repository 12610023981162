"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _default = {
  __name: 'DimensionCreateDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: 'update:visible',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const form = (0, _vue.reactive)({
      name: ''
    });
    const rule = {
      name: [{
        required: true,
        message: '请输入维度名称',
        trigger: 'change'
      }]
    };
    const formRef = (0, _vue.ref)(null);
    const submit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) return;
      const res = await (0, _coachingCenter.scoreMetricsDimensionCreate)(form);
      updateVisible(false);
      emit('createSuccess', {
        ...form,
        id: res.results.dimension_id
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      form,
      rule,
      formRef,
      submit
    };
  }
};
exports.default = _default;