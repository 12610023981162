"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      height: "calc(100vh - 209px)",
      data: _vm.developerList
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "开发者"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("image-avatar", {
          attrs: {
            type: "'host-avatar'",
            name: row.name,
            avatar: row.avatar
          }
        }), _c("span", [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "login_account",
      label: "登录账号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          class: {
            "no-tel": !row.login_account
          }
        }, [_c("i", {
          class: ["iconfont", {
            "icon-mobile-phone": row.login_account && row.login_method === "tel",
            "icon-message": row.login_account && row.login_method === "email"
          }]
        }), _vm._v(" " + _vm._s(row.login_account) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("el-popconfirm", {
          attrs: {
            placement: "bottom-start",
            title: "确认移除？",
            icon: "iconfont icon-warning",
            "confirm-button-text": "移除",
            "confirm-button-type": "danger",
            "popper-class": "manage-developer-confirm__delete"
          },
          on: {
            onConfirm: function ($event) {
              return _vm.deleteDeveloper(row.user_id);
            }
          }
        }, [_c("el-button", {
          staticClass: "delete-btn",
          attrs: {
            slot: "reference",
            type: "text"
          },
          slot: "reference"
        }, [_vm._v(" 移除 ")])], 1)];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;