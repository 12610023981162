"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _user = require("@/api/user");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LazyTree',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    isCheckedRoot: {
      type: Boolean
    },
    requestFunction: {
      type: Function,
      default: _user.getDataOrgMemberTree
    },
    params: {
      type: [Object, String],
      default: () => ({})
    },
    // 可以不选择部门或成员
    isTaskCenter: {
      type: Boolean,
      default: false
    },
    backfillCheckedIds: {
      type: Array,
      default: () => []
    },
    isShowMainSpaceMember: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultExpandedKeys: [],
      defaultChecked: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name',
        isLeaf: data => this.isLeaf(data)
      },
      treeNode: null,
      treeResolve: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'params.type'(val) {
      val.workspace_id && this.initTreeData();
    },
    workspaceInfo() {
      this.$nextTick(() => {
        this.initTreeData();
      });
    },
    isShowMainSpaceMember() {
      this.initTreeData();
    }
  },
  methods: {
    initTreeData() {
      this.loadTreeData(this.treeNode, this.treeResolve);
    },
    handleSelfCheckChange(checkedNodes, hasChecked) {
      if (hasChecked) return;
      this.defaultChecked = this.defaultChecked.filter(item => item !== checkedNodes.id);
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    async getTreeData() {
      const res = await this.requestFunction(this.params);
      if (res.code === 200 || res.code === 20000) {
        if (!res.results.length) {
          return [];
        }
        // 默认展开一级节点
        const {
          id: rootId,
          name: rootName
        } = res.results[0];
        this.defaultExpandedKeys = [rootId];
        // 默认选中根节点
        if (this.isCheckedRoot) {
          const labels = rootName;
          const tree_user_ids = this.getTreeUserIds([rootId]);
          this.$emit('change', tree_user_ids, labels, [res.results[0]]);
        }
        if (this.isCheckedRoot || this.backfillCheckedIds[0] === rootId) {
          this.defaultChecked = [rootId];
        }
        this.treeNode.childNodes = [];
        return res.results;
      }
    },
    async loadTreeData(node, resolve) {
      // eslint-disable-next-line vue/no-mutating-props
      this.params.filter_space = this.isShowMainSpaceMember;
      // eslint-disable-next-line vue/no-mutating-props
      this.params.workspace_id = this.workspaceInfo.id;
      if (!node || node.level === 0) {
        this.treeNode = node;
        this.treeResolve = resolve;
        const data = await this.getTreeData();
        return resolve(data);
      }
      const res = await this.requestFunction({
        tree_id: node.data.id.split('_').pop() - 0,
        page: 1,
        size: 9999,
        ...this.params
      });
      if (res.code === 200 || res.code === 20000) {
        // 任务中心成员只要uid
        let newData = res.results.children || res.results;
        if (this.isTaskCenter) {
          newData = this.handleUserId(res.results.children || res.results);
        }
        this.backfillCheckedNode(newData);
        return resolve(newData);
      } else {
        return resolve([]);
      }
    },
    // 处理树的成员只有id,不加部门
    handleUserId(data) {
      data.forEach(item => {
        if (!item.id.includes('tree_id')) {
          item.id = item.id.split('_').pop();
        }
      });
      return data;
    },
    isDepartment(id) {
      return /^tree/.test(id);
    },
    isLeaf(data) {
      return !this.isDepartment(data.id) || this.isDepartment(data.id) && !data.has_children;
    },
    handleCheckChange() {
      // 如需记忆功能，可直接将checkedNodesData传递出去
      const checkedNodesData = this.formaterData();
      const labels = checkedNodesData.map(item => item.name).join(',');
      const checkedId = checkedNodesData.map(item => item.id);
      const {
        tree_ids,
        user_ids
      } = this.getTreeUserIds(checkedId);
      if (!tree_ids.length && !user_ids.length && !this.isTaskCenter) {
        this.$message({
          message: '请选择团队/成员',
          center: true,
          type: 'warning'
        });
        this.$emit('clearChecked');
        return;
      }
      const tree_user_ids = {
        tree_ids,
        user_ids
      };
      this.$emit('change', tree_user_ids, labels, checkedNodesData);
      this.$emit('filterChange', checkedNodesData);
    },
    formaterData() {
      // 获取树的配置属性信息
      const store = this.$refs['department-member-tree'].store;
      const checkedNodes = [];
      const traverseTreeNode = function (node) {
        // 获取根节点，从根节点开始递归，将全选的节点加入到选中项数组中
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          // 判断节点的子树中是否有被选中的节点
          if (child.indeterminate) {
            traverseTreeNode(child);
          }
        });
      };
      traverseTreeNode(store);
      return checkedNodes;
    },
    getTreeUserIds(tree_user_ids) {
      const tree_ids = [];
      const user_ids = [];
      tree_user_ids && tree_user_ids.forEach(item => {
        item = String(item);
        const id = item.split('_').pop() - 0;
        if (item.indexOf('tree_id_') !== -1) {
          tree_ids.push(id);
        } else {
          user_ids.push(id);
        }
      });
      return {
        tree_ids,
        user_ids
      };
    },
    backfillCheckedNode(data) {
      let keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.backfillCheckedIds;
      if (keys.length) {
        const currentTreeLevelCheckedKeys = data.filter(_ref => {
          let {
            id
          } = _ref;
          return keys.includes(id);
        }).map(_ref2 => {
          let {
            id
          } = _ref2;
          return id;
        });
        this.$nextTick(() => {
          currentTreeLevelCheckedKeys.forEach(item => {
            this.$refs['department-member-tree'].setChecked(item, true);
          });
        });
      }
    },
    clearData() {
      this.$refs['department-member-tree'].setCheckedNodes([]);
      this.$emit('clearChecked');
    }
  }
};
exports.default = _default;