"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ai-customer-generator-header"
  }, [_c("div", {
    staticClass: "left-container"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-arrow-left",
      type: "text",
      size: "medium",
      square: "",
      color: "info"
    },
    on: {
      click: _setup.goBack
    }
  }), _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      content: _setup.promptStruct.name,
      placement: "top",
      effect: "dark"
    }
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isEdit,
      expression: "!isEdit"
    }],
    staticClass: "prompt-struct-name"
  }, [_vm._v(_vm._s(_setup.promptStruct.name))])]), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isEdit,
      expression: "isEdit"
    }],
    ref: "nameInput",
    attrs: {
      maxlength: "50"
    },
    on: {
      blur: _setup.onBlur
    },
    model: {
      value: _setup.promptStruct.name,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "name", $$v);
      },
      expression: "promptStruct.name"
    }
  }), _c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isEdit,
      expression: "!isEdit"
    }],
    attrs: {
      icon: "icon-edit",
      type: "text",
      size: "medium",
      square: "",
      color: "info"
    },
    on: {
      click: _setup.enterEditStatus
    }
  })], 1), _c("div", {
    staticClass: "right-container"
  }, [_c("el-link", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "help-tutorial",
    attrs: {
      type: "primary",
      underline: false,
      href: "https://www.megaview.com/help-center/doc?docx=XgSIde74noecqJxNbrjcc8Usnbb&edition_id=hOw95E&anchor=doxcndXcZzp55m71NAYm4c3aXeh",
      target: "_blank"
    }
  }, [_vm._v("帮助教程")]), _c("MgvButton", {
    attrs: {
      size: "medium",
      loading: _setup.publishLoading
    },
    on: {
      click: _setup.publish
    }
  }, [_vm._v("发布课程")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;