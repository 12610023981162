"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _SeatTable = _interopRequireDefault(require("./components/SeatTable"));
var _ChangeBindDialog = _interopRequireDefault(require("./components/ChangeBindDialog"));
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SeatManagement',
  components: {
    SeatTable: _SeatTable.default,
    ChangeBindDialog: _ChangeBindDialog.default,
    NoData: _index.default
  },
  data() {
    return {
      activeTab: '',
      // 当前供应商的英文名称，用于同步坐席
      seatNumber: '',
      // 用于展示和搜索的坐席号
      userId: 0,
      // 用于坐席绑定操作的用户id
      outboundId: '',
      // 用于坐席绑定操作的坐席id
      total: 0,
      listParams: {
        page_no: 1,
        page_size: 10,
        // app_permission_id: '', // 当前供应商的id
        filter: '',
        if_bind: ''
      },
      timer: null,
      seatTabsList: [],
      tableData: [],
      tableLoading: false,
      refreshLoading: false,
      changeBindVisible: false,
      isChangeBind: false,
      highlightRow: false,
      agentStatus: '',
      agentStatusOptions: [{
        value: '',
        label: '全部状态'
      }, {
        value: 1,
        label: '已绑定'
      }, {
        value: 0,
        label: '未绑定'
      }],
      currentPermissionId: ''
    };
  },
  created() {
    // 获取 table list
    this.getSeatTabsList();
  },
  methods: {
    handleTagClick(val) {
      this.currentPermissionId = val.app_permission_id;
      this.getSeatList();
    },
    handleAgentStatus(val) {
      this.listParams.if_bind = val;
      this.listParams.page_no = 1;
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      this.getSeatList();
    },
    // 获取坐席供应商的tabs列表
    async getSeatTabsList() {
      const res = await (0, _organization.getSeatTabsList)();
      if (res.code === 200) {
        this.seatTabsList = res.results;
        // 当前坐席对用公司名称，英文的
        if (this.seatTabsList.length) {
          this.activeTab = this.seatTabsList[0].app_id + this.seatTabsList[0].app_name;
          // 当前坐席 id
          this.currentPermissionId = this.seatTabsList[0].app_perm_list[0].app_permission_id;
          this.getSeatList();
        }
      }
    },
    // 根据不同供应商获取table列表
    handleTabClick(tab) {
      // 当前坐席对用公司名称，英文的
      this.activeTab = String(this.seatTabsList[tab.index].app_id + this.seatTabsList[tab.index].app_name);
      this.listParams.page_no = 1;
      this.listParams.filter = '';
      this.currentPermissionId = this.seatTabsList[tab.index].app_perm_list[0].app_permission_id;
      this.getSeatList();
    },
    // 搜索坐席号或成员姓名
    searchMethod() {
      this.listParams.page_no = 1;
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      this.getSeatList();
    },
    // 同步坐席
    refreshSeat() {
      this.tableLoading = true;
      this.refreshLoading = true;
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      const formData = {
        app_permission_id: this.currentPermissionId
      };
      (0, _organization.refreshSeat)(formData).then(res => {
        if (res.code === 200) {
          this.listParams.page_no = 1;
          this.getSeatList();
          this.refreshLoading = false;
          const number = res.results.newly_stored_count;
          if (number === 0) {
            this.$message({
              message: '当前已是最新坐席',
              center: true,
              type: 'success'
            });
          } else {
            this.$message({
              message: `新增 ${number} 个坐席`,
              center: true,
              type: 'success'
            });
          }
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => {
        this.tableLoading = false;
        this.refreshLoading = false;
      });
    },
    // 切换列表页码或者条数，获取列表
    handleListParamsChange(val, type) {
      this.listParams[type] = val;
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      this.getSeatList();
    },
    // 获取公司对应的坐席列表
    async getSeatList() {
      this.tableLoading = true;
      this.listParams.app_permission_id = this.currentPermissionId;
      const res = await (0, _organization.getSeatList)(this.listParams);
      if (res.code === 200) {
        // this.tableData = res.results.outbounds;
        this.total = res.results.total_count;
        this.tableLoading = false;
        if (!this.highlightRow) {
          this.tableData = res.results.outbounds;
        } else {
          // 操作行高亮
          this.highlightRow = false;
          this.tableData = res.results.outbounds.map(item => {
            if (item.outbound_number === this.seatNumber) {
              this.$set(item, 'highlight', true);
            }
            return item;
          });
          this.timer = setTimeout(() => {
            this.tableData = res.results.outbounds.map(item => {
              if (item.outbound_number === this.seatNumber) {
                this.$set(item, 'highlight', false);
              }
              return item;
            });
            this.timer = null;
          }, 5000);
        }
      }
    },
    // 绑定/修改/解除
    async seatOperation() {
      this.highlightRow = true;
      const formData = {
        user_id: this.userId,
        outbound_id: this.outboundId
      };
      const res = await (0, _organization.seatOperation)(formData);
      if (res.code === 200) {
        this.closeChangeBindDialog();
        this.getSeatList();
      }
    },
    // 点击修改绑定
    handleChangeBind(row) {
      // 当前编辑的 id
      this.seatNumber = row.outbound_number;
      this.outboundId = row.outbound_id;
      this.isChangeBind = true;
      this.changeBindVisible = true;
    },
    // 点击绑定/解绑
    handleBindOrUnbind(row) {
      this.seatNumber = row.outbound_number;
      this.outboundId = row.outbound_id;
      this.isChangeBind = false;
      // 解绑
      if (row.enable_disconnect) {
        this.$confirm('解除绑定后，原账号将不再同步该坐席对应的会话，是否解除?', `坐席号 ${this.seatNumber}`, {
          customClass: 'unbind-seat-confirm',
          confirmButtonClass: 'el-button--primary',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.userId = 0;
          this.seatOperation();
          this.$message({
            message: '解绑成功',
            center: true,
            type: 'success'
          });
        }).catch(() => {});
      } else {
        // 绑定
        this.changeBindVisible = true;
      }
    },
    // 绑定创建 OR 修改绑定关系
    async confirmChangeBind(val) {
      this.userId = val;
      await this.seatOperation();
      if (this.isChangeBind) {
        this.$message({
          message: '修改绑定成功',
          center: true,
          type: 'success'
        });
      } else {
        this.$message({
          message: '绑定成功',
          center: true,
          type: 'success'
        });
      }
    },
    // 关闭绑定弹框
    closeChangeBindDialog() {
      this.changeBindVisible = false;
    }
  }
};
exports.default = _default;