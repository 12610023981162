"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _index = require("@/utils/index");
var _download = require("@/utils/download");
var _timeFormatter = require("@/utils/time-formatter");
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _ConvPlayContent = _interopRequireDefault(require("@/components/ConvPlayContent"));
var _ConvControlContent = _interopRequireDefault(require("@/components/Video/ConvControlContent"));
var _CommentDrawer = _interopRequireDefault(require("@/components/CommentDrawer"));
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DetailHeader: _DetailHeader.default,
    ConvPlayContent: _ConvPlayContent.default,
    ConvControlContent: _ConvControlContent.default,
    CommentDrawer: _CommentDrawer.default
  },
  inject: ['reload'],
  provide() {
    return {
      getActiveCommentId: this.getActiveCommentId
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('video/set_conversation_info', {
      convName: null,
      convTime: null,
      convDuration: null
    });
    next();
  },
  data() {
    return {
      hasDetailInfo: false,
      leftBarActive: '',
      rightBarActive: 'comments',
      isToTrans: true,
      // 切换为转录文字
      videoUrl: '',
      // 视频的url
      partiInfo: [],
      // 参与者信息
      overviewInfo: {},
      // 概览基本信息
      assistants: [],
      // 辅助销售代表
      sharePercent: null,
      // 共享屏幕的占比
      shareScreen: [],
      // 共享屏幕的音频
      tabActiveName: 'transtext',
      // tab激活栏
      snippetId: '',
      // conversation_id
      qaArrays: null,
      // 问答数组
      analyArray: [],
      // 关键词数组
      inputWords: '',
      // 外部的搜索词
      searchWords: '',
      // 搜索词
      activeRoundId: null,
      // 当前轮次的会话id
      resultList: [],
      // 搜索文字结果
      newCommentsCount: 0,
      // 新评论的数量
      hasLiked: false,
      // 是否已点赞
      hasCollected: false,
      // 是否已收藏
      thumbsUpNumber: 0,
      // 点赞数
      collectNumber: 0,
      // 收藏数
      origin: 'native',
      // 视频的来源类型
      activeCommentId: null,
      // 消息转过来的打开评论的页面传递的commentId,
      mediaType: '',
      videoExtension: '',
      apiProcess: {
        is_es_insert_complete: false
      },
      isDownloading: false,
      // 是否正在下载视频
      localSliderTime: 0,
      commentsCount: 0,
      timer: null,
      isSnippetStudy: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curtime', 'order_end_time', 'audio', 'curorder', 'orgId', 'is_user_scroll', 'webSocketMsg', 'conversationTitleInfo', 'isChoose', 'interfacePermission']),
    getBarDialogTitle() {
      const map = {
        search: '搜索',
        keyevent: '关键事件',
        analysis: '会话维度'
      };
      return map[this.leftBarActive] || '';
    },
    getRightDialogTitle() {
      const map = {
        notes: '私人笔记',
        comments: '评论'
      };
      return map[this.rightBarActive] || '';
    },
    convdate() {
      return this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('YYYY-MM-DD');
    },
    convtime() {
      return this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('HH:mm');
    },
    // eslint-disable-next-line vue/return-in-computed-property
    convDuration() {
      const seconds = this.conversationTitleInfo.convDuration;
      if (seconds) {
        return (0, _timeFormatter.secondToCNTimeOfFixedTwo)(seconds);
      }
      return '';
    },
    timeDescribe() {
      const {
        type
      } = this.$route.query;
      return type && type === 'media' ? '上传时间：' : '剪辑时间：';
    }
  },
  watch: {
    leftBarActive(val) {
      if (!val) {
        this.searchWords = '';
        this.inputWords = '';
        this.isToTrans = true;
      }
    },
    rightBarActive(val) {
      if (!val) {
        this.activeCommentId = null;
      }
      if (val !== 'comments') {
        this.$store.commit('video/SET_SELECTED_SENTENCE_OF_COMMENT', {});
      }
    },
    curtime(val) {
      if (val >= this.order_end_time - 1 && !this.is_user_scroll) {
        this.$store.dispatch('video/get_cur_order', {
          current_time: val,
          time: new Date().getTime()
        });
      }
    },
    $route: {
      handler: function (val, oldVal) {
        this.snippetId = val.query.id;
      },
      deep: true
    },
    snippetId(val) {
      this.changeConvId(val);
    },
    webSocketMsg: {
      handler: function (newVal) {
        var _newVal$extra_info, _newVal$extra_info2;
        if (newVal.type === 'conversation_completed' &&
        // eslint-disable-next-line eqeqeq
        newVal.data.conversation_id == this.snippetId) {
          (0, _index.msgDispatcher)(newVal, 'conversation_completed', this.detailInfoFun);
        }
        // 如果是评论相关的消息，是本会话的需要刷新评论数据
        if (newVal.type === 2 &&
        // eslint-disable-next-line eqeqeq
        ((_newVal$extra_info = newVal.extra_info) === null || _newVal$extra_info === void 0 ? void 0 : _newVal$extra_info.entity_type) === 'snippet' && ((_newVal$extra_info2 = newVal.extra_info) === null || _newVal$extra_info2 === void 0 ? void 0 : _newVal$extra_info2.entity_id) === Number(this.snippetId)) {
          if (this.rightBarActive) {
            if (this.$refs.comments) {
              this.$refs.comments.getComments();
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    const conv_id = this.$route.query.id ? this.$route.query.id : '';
    this.snippetId = conv_id.toString();
    const keyWords = this.$route.query.keywords ? this.$route.query.keywords : '';
    if (keyWords) {
      this.goTransText(keyWords);
    }
    this.$store.commit('video/SET_HAS_VIDEO', true);
    this.firstPlay();
  },
  mounted() {
    this.shareOptions = [];
    document.addEventListener('click', this.clickEvent, true);
    // 如果页面上有评论id, 则打开右侧的评论
    const commentId = this.$route.query.commentId ? this.$route.query.commentId : '';
    if (commentId) {
      this.rightBarActive = 'comments';
      this.activeCommentId = Number(commentId);
    }
  },
  beforeDestroy() {
    // 组件销毁时清除定时任务
    this.stopInterval();
    document.removeEventListener('click', this.clickEvent, true);
    // 移除页面切换到后台时的事件
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        // 页面切换到后台，停止定时任务
        this.stopInterval();
      } else {
        // 页面切回前台，重新开始定时任务
        this.startInterval();
      }
    },
    startInterval() {
      this.timer = setInterval(async () => {
        // 剪辑库学习任务时长统计，每隔20s一更新，页面切换到后台时不算
        await (0, _library.libraryStudyTime)({
          snippet_id: Number(this.snippetId),
          duration: 20
        });
      }, 20000);
    },
    stopInterval() {
      clearInterval(this.timer);
    },
    closeDrawer() {
      this.rightBarActive = '';
    },
    selectTransText() {
      this.tabActiveName = 'transtext';
    },
    handleComment() {
      this.rightBarActive = 'comments';
    },
    changeSliderTime(sliderTime) {
      this.localSliderTime = sliderTime;
    },
    switchTab(type) {
      this.tabActiveName = type;
    },
    downloadSnippet() {
      const filename = `${this.conversationTitleInfo.convName}.${this.videoExtension}`;
      this.isDownloading = true;
      (0, _download.downloadVideo)(this.videoUrl, filename, () => {
        this.isDownloading = false;
      });
    },
    clickEvent(e) {
      if (this.leftBarActive) {
        if (this.$refs.leftDialog && !this.$refs.keyevent && !(this.$refs.leftDialog.contains(e.target) || this.$refs.leftBar.contains(e.target))) {
          this.leftBarActive = '';
        }
      }
      if (this.isChoose) {
        if (this.$refs.rightDialog && !(this.$refs.rightDialog.contains(e.target) || this.$refs.rightBar.contains(e.target))) {
          if (this.$refs.transtext.$refs.dialogText.$refs.contWrap && !this.$refs.transtext.$refs.dialogText.$refs.contWrap[this.curorder.value].contains(e.target) && this.$refs.transtext.$refs.dialogText) {
            this.$store.dispatch('video/set_choose', false);
          } else {
            this.$store.dispatch('video/set_choose', true);
          }
        }
      }
    },
    getTimeHtml(seconds) {
      // eslint-disable-next-line prefer-const
      let {
        s,
        m,
        h
      } = (0, _timeFormatter.second2HMS)(seconds);
      if (h) {
        return m ? `<span class="bold-text">${h}</span><span>小时</span><span class="bold-text"> ${m}</span><span>分</span>` : `<span class="bold-text">${h}</span><span>小时</span>`;
      } else if (m) {
        return s ? `<span class="bold-text">${m}</span><span>分</span><span class="bold-text"> ${s}</span><span>秒</span>` : `<span class="bold-text">${m}</span><span>分</span>`;
      } else if (s) {
        s = this.$moment.duration(seconds * 1000).asSeconds().toFixed(2);
        return `<span class="bold-text"> ${s}</span><span>秒</span>`;
      } else {
        return `<span class="bold-text"> 0</span><span>秒</span>`;
      }
    },
    goTransText(words) {
      this.searchWords = words;
      this.$refs['mainContent'].inputWords = words;
      this.getSearchResult(words);
    },
    changeConvId(snippetId) {
      const convIdStr = snippetId.toString();
      if (!convIdStr) {
        return false;
      } else {
        this.detailInfoFun();
      }
    },
    openTextTab() {
      this.tabActiveName = 'transtext';
    },
    goKeyDetail(order, item) {
      this.openTextTab();
      this.$refs.mainContent.$refs.transtext.goKeyDetail(order, item);
    },
    getSearchResult(filter) {
      (0, _library.getSnippetSearch)({
        content: filter,
        snippet_id: this.snippetId
      }).then(res => {
        this.resultList = res.results;
        //
        this.handleSearchResultAvater();
        this.$refs.mainContent.showResults();
      });
    },
    handleSearchResultAvater() {
      if (this.resultList && this.resultList.length) {
        this.resultList.forEach(resItem => {
          if (this.partiInfo && this.partiInfo.length) {
            this.partiInfo.forEach(partItem => {
              if (partItem.entity_id === resItem.entity_id && partItem.entity_type === resItem.entity_type) {
                resItem.name = partItem.name;
                resItem.avatar = partItem.avatar;
              }
            });
          }
        });
      }
    },
    detailInfoFun() {
      (0, _library.getSnippetDetailInfo)(this.snippetId).then(async res => {
        if (res.code === 20000) {
          this.hasDetailInfo = true;
          const results = res.results;
          this.overviewInfo = results;
          this.mediaType = results.type;
          this.videoUrl = results.video.url;
          this.videoExtension = results.video.extension;
          this.apiProcess = results.process;
          this.partiInfo = results.participants;
          this.$store.commit('video/SET_CUR_MEDIA_TYPE', this.mediaType);
          this.$store.dispatch('video/set_guide_visiable', false);
          this.sharePercent = typeof results.share_percent === 'number' ? results.share_percent.toString() : '';
          this.shareScreen = results.share_screen;
          this.origin = results.origin;
          this.$store.dispatch('video/set_audio_all', {
            cur_conv_id: this.snippetId,
            type: 'snippet',
            has_workspace: false,
            has_entity_id: results.participants.length ? results.participants[0].entity_id : ''
          });
          // 点赞收藏信息
          this.hasLiked = results.is_like;
          this.hasCollected = results.is_collect;
          this.thumbsUpNumber = results.like_amount;
          this.collectNumber = results.collect_amount;
          this.$store.dispatch('video/set_conversation_info', {
            convName: results.name,
            convTime: results.create_at,
            convDuration: results.video.duration
          });
          // 该剪辑库为我的学习任务
          this.isSnippetStudy = results.has_study;
          if (this.isSnippetStudy) {
            await (0, _library.libraryStudyTime)({
              snippet_id: Number(this.snippetId),
              duration: 1
            });
            this.startInterval();
            // 注册页面切换到后台时的事件
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
          }
        }
      });
    },
    selectActiveBar(name) {
      if (name === 'notes' || name === 'comments') {
        if (name === 'comments') {
          this.newCommentsCount = 0;
        }
        if (this.rightBarActive === name) {
          this.rightBarActive = '';
        } else {
          this.rightBarActive = name;
        }
      } else {
        if (this.leftBarActive === name) {
          this.leftBarActive = '';
        } else {
          this.leftBarActive = name;
          this.isToTrans = true;
        }
      }
    },
    handleThumbsUp() {
      // 请求接口成功后
      if (this.hasLiked) {
        (0, _library.dislikeSnippet)(this.snippetId).then(res => {
          if (res.code === 20000) {
            this.hasLiked = !this.hasLiked;
            this.thumbsUpNumber = res.results.count;
          }
        });
      } else {
        (0, _library.likeSnippet)(this.snippetId).then(res => {
          if (res.code === 20000) {
            this.hasLiked = !this.hasLiked;
            this.thumbsUpNumber = res.results.count;
          }
        });
      }
    },
    handleCollect() {
      // 请求接口成功后
      if (this.hasCollected) {
        (0, _library.discollectSnippet)(this.snippetId).then(res => {
          if (res.code === 20000) {
            this.hasCollected = !this.hasCollected;
            this.collectNumber = res.results.count;
          }
        });
      } else {
        (0, _library.colletSnippet)(this.snippetId).then(res => {
          if (res.code === 20000) {
            this.hasCollected = !this.hasCollected;
            this.collectNumber = res.results.count;
          }
        });
      }
    },
    firstPlay() {
      (0, _library.postSnippetPlayCount)(this.snippetId).then(res => {});
    },
    getActiveCommentId() {
      return this.activeCommentId;
    }
  }
};
exports.default = _default;