"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _business = require("@/api/business");
var _vuex = require("vuex");
var _TimePicker = _interopRequireDefault(require("@/components/TimePicker"));
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MeetingForm',
  components: {
    TimePicker: _TimePicker.default,
    MembersFinder: _MembersFinder.default
  },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      }
    },
    contactOptions: Array,
    type: {
      type: String,
      default: 'clue'
    },
    isBase: {
      type: Boolean,
      default: false
    },
    conversationId: {
      type: Number,
      default: 0
    },
    meetingType: String
  },
  data() {
    return {
      hasSelf: true,
      rules: {
        meeting_name: [{
          required: true,
          message: '会议名称不能为空',
          trigger: 'blur'
        }],
        date: [{
          required: true,
          message: '请选择日期'
        }],
        begin_time: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'change'
        }, {
          validator: this.validateBeginTime,
          trigger: 'change'
        }],
        begin_date: [{
          required: true,
          message: '请选择开始日期',
          trigger: 'change'
        }],
        end_time: [{
          required: true,
          message: '请选择结束时间',
          trigger: 'change'
        }, {
          validator: this.validateEndTime,
          trigger: 'change'
        }],
        end_date: [{
          required: true,
          message: '请选择结束日期',
          trigger: 'change'
        }]
      },
      flag: true,
      memberOptions: [],
      conflictList: ['15:30-15:40', '16:30-16:40'],
      confilct_conversations_as_host: [],
      confilct_conversations_not_as_host: [],
      selectLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name']),
    limitNumber() {
      return this.meetingType === 'tencent_meeting' ? 98 : 6;
    },
    nowTime() {
      const todayDate = this.$moment().format('YYYY-MM-DD');
      if (this.$moment(todayDate).isSame(this.form.begin_date)) {
        return this.$moment().format('HH:mm');
      } else {
        return null;
      }
    },
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          return _this.isEarlyThan(time);
        }
      };
    },
    dateRange() {
      const {
        begin_date,
        begin_time,
        end_date,
        end_time
      } = this.form;
      return {
        begin_date,
        begin_time,
        end_date,
        end_time
      };
    },
    conflictContent() {
      const _range_list = [];
      this.confilct_conversations_as_host.forEach(item => {
        const _begin = this.$moment(item.begin_time).format('HH:mm');
        const _end = this.$moment(item.end_time).format('HH:mm');
        _range_list.push(_begin + '-' + _end);
      });
      this.confilct_conversations_not_as_host.forEach(item => {
        const _begin = this.$moment(item.begin_time).format('HH:mm');
        const _end = this.$moment(item.end_time).format('HH:mm');
        _range_list.push(_begin + '-' + _end);
      });
      return _range_list.join('<br/>');
    },
    sameDay() {
      return this.$moment(this.form.begin_date).isSame(this.form.end_date);
    },
    memberOptionsNew() {
      return this.memberOptions.filter(item => item.id !== this.form.host_salesman_id);
    }
  },
  watch: {
    dateRange(val) {
      if (!val.begin_date || !val.begin_time || !val.end_date || !val.end_time) {
        return;
      }
      const _begin = this.form.begin_date + ' ' + this.form.begin_time;
      const _end = this.form.end_date + ' ' + this.form.end_time;
      const _earlier = this.$moment(_begin).isBefore(_end);
      if (!_earlier && this.flag) {
        const _time = this.$moment(_begin).add(30, 'minutes');
        this.form.end_date = _time.format('YYYY-MM-DD');
        this.form.end_time = _time.format('HH:mm');
      }
      this.checkDateConfliction();
    },
    'form.host_salesman_id': {
      handler: function () {
        this.checkDateConfliction();
        this.getSalesMemeberOptions(); // 销售id变化后需要重新获取对应的选项列表
      }
    },

    confilct_conversations_as_host(val) {
      if (this.confilct_conversations_as_host.length === 0) {
        // 如果没有作为host的会议冲突则可以提交表单
        this.$emit('changeSubmitStatus', true);
      } else {
        // 不能提交表单并提示
        this.$emit('changeSubmitStatus', false);
      }
    }
  },
  mounted() {
    this.$bus.$on('setMembersList', this.fillSalesName);
    this.$nextTick(() => {
      this.getSalesMemeberOptions();
    });
  },
  beforeDestroy() {
    this.$bus.$off('setMembersList', this.fillSalesName);
  },
  methods: {
    select(val) {
      this.form.end_time = val;
    },
    // 标记符，用来监听是否执行半个小时延迟事件
    timeFlag(val) {
      this.flag = val;
    },
    // 校验开始时间
    validateBeginTime(rule, value, callback) {
      const todayDate = this.$moment().format('YYYY-MM-DD');
      const todayTime = this.$moment().format('HH:mm');
      const _end = this.form.begin_date + ' ' + value;
      const _begin = todayDate + ' ' + todayTime;
      if (this.$moment(_end).isBefore(_begin, 'minute')) {
        callback(new Error('开始时间不可小于当前时间'));
      } else {
        callback();
      }
    },
    // 校验结束时间
    validateEndTime(rule, value, callback) {
      const _begin = this.form.begin_date + ' ' + this.form.begin_time;
      const _end = this.form.end_date + ' ' + value;
      if (this.$moment(_end).isBefore(_begin, 'minute')) {
        callback(new Error('结束时间不可小于开始时间'));
      } else {
        callback();
      }
    },
    validateTime(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择时间'));
      } else {
        var nowTime = new Date();
        nowTime = nowTime.getTime();
        var getTime = this.form.begin_date + ' ' + value;
        if (this.$moment(getTime).valueOf() < nowTime - 60000) {
          callback(new Error('请选择未来的时间'));
        }
        callback();
      }
    },
    isEarlyThan(time) {
      const _time = parseInt(this.$moment(time).format('YYYYMMDD'));
      const now = parseInt(this.$moment().format('YYYYMMDD'));
      return _time < now;
    },
    focusGetMemberList() {
      this.searchGetMemberList('');
    },
    getSalesMemeberOptions() {
      var _this$form, _this$form2;
      const query = (_this$form = this.form) !== null && _this$form !== void 0 && _this$form.host_salesman_name ? (_this$form2 = this.form) === null || _this$form2 === void 0 ? void 0 : _this$form2.host_salesman_name : this.name;
      this.searchGetMemberList(query);
    },
    async searchGetMemberList(query) {
      this.selectLoading = true;
      const res = await (0, _videoDetail.getShareNames)(query);
      this.selectLoading = false;
      this.memberOptions = res.results;
    },
    setNow() {
      const now = this.$moment();
      this.form.begin_date = now.format('YYYY-MM-DD');
      this.form.begin_time = now.format('HH:mm');
      this.$forceUpdate();
    },
    checkDateConfliction() {
      if (this.isBase) return;
      const _begin = this.form.begin_date + ' ' + this.form.begin_time;
      const _end = this.form.end_date + ' ' + this.form.end_time;
      (0, _business.checkDateConfliction)(this.form.host_salesman_id, _begin, _end, this.conversationId).then(response => {
        const res = response.results;
        this.confilct_conversations_as_host = res.confilct_conversations_as_host;
        this.confilct_conversations_not_as_host = res.confilct_conversations_not_as_host;
      });
    },
    fillSalesName(selectionList, membersList) {
      if (this.$refs['select-members']) {
        this.$refs['select-members'].selection = selectionList;
        this.$refs['select-members'].memberOptions = membersList;
      }
    }
  }
};
exports.default = _default;