"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commentItem = _interopRequireDefault(require("./commentItem.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _num_formatter = require("@/utils/num_formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: '',
  components: {
    commentItem: _commentItem.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    NoData: _NoData.default
  },
  props: ['commentList', 'activeName', 'loading', 'listLoading', 'nomore'],
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg'])
  },
  methods: {
    load() {
      this.$emit('loadMore');
    },
    handleClick(item) {
      if (item.params.entity_type === 'conversation') {
        this.goDetail(item);
      } else if (item.params.entity_type === 'deal') {
        this.goBusinessDetail(item);
      } else if (item.params.entity_type === 'snippet') {
        this.goSnippetDetail(item);
      }
    },
    goSnippetDetail(item) {
      if (!item.is_access) {
        this.$message({
          message: '无访问权限，请联系管理员',
          type: 'warning'
        });
        return;
      }
      this.$router.push({
        path: _routeMap.ROUTES.libraryDetail,
        query: {
          id: item.params.entity_id,
          commentId: item.params.comment_id
        }
      });
    },
    goBusinessDetail(item) {
      if (!item.is_access) {
        this.$message({
          message: '无访问权限，请联系管理员',
          type: 'warning'
        });
        return;
      }
      this.$router.push({
        path: _routeMap.ROUTES.dealDetail,
        query: {
          id: item.params.entity_id,
          commentId: item.params.comment_id
        }
      });
    },
    goDetail(item) {
      const type = item.type;
      if (!item.is_access) {
        this.$message({
          message: '无访问权限，请联系管理员',
          type: 'warning'
        });
        return;
      }
      if (type === 'notify_share_conversation') {
        // 类型是分享会话
        this.routerViewDetail(item, 'share');
      } else if ([2, 3, 4].includes(type)) {
        // 是评论的相关类型
        if (item.is_access) {
          this.routerViewDetail(item, 'comment');
        }
      }
    },
    routerViewDetail(item, fromName, isSnippet) {
      if (item.is_delete) {
        this.$confirm(name + '会话已不存在', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {}).catch(() => {});
      } else {
        if (isSnippet) {
          // 如果是剪辑详情页
          this.$router.push({
            path: _routeMap.ROUTES.libraryDetail,
            query: {
              id: item.params.id,
              from: fromName
            }
          });
          if (this.$route.path === _routeMap.ROUTES.libraryDetail) {
            location.reload();
          }
          this.$emit('goDetail');
        } else {
          if (this.$route.path === _routeMap.ROUTES.conversationDetail &&
          // eslint-disable-next-line eqeqeq
          this.$route.query.id == item.params.entity_id &&
          // eslint-disable-next-line eqeqeq
          this.$route.query.commentId == item.params.comment_id) {
            location.reload();
          } else {
            this.$router.push({
              path: _routeMap.ROUTES.conversationDetail,
              query: {
                id: item.params.entity_id,
                commentId: item.params.comment_id
              }
            });
          }
          this.$emit('goDetail');
        }
      }
    },
    parseFormatNum(num, n) {
      return (0, _num_formatter.parseFormatNum)(num, n);
    },
    handleStatusMsg(status) {
      switch (status) {
        case 'win':
          return {
            iconClass: 'el-icon-circle-check',
            type: 'success'
          };
        case 'loss':
          return {
            iconClass: 'el-icon-circle-close',
            type: 'failure'
          };
        case 'invalidate':
          return {
            iconClass: 'el-icon-remove-outline',
            type: 'invalid'
          };
        default:
          break;
      }
      return {
        iconClass: '',
        type: ''
      };
    },
    handleConvTitle(item) {
      if (item.entity_type === 'video') {
        return 'iconfont icon-video-camera';
      } else if (item.entity_type === 'audio') {
        return 'iconfont icon-phone-outline';
      } else if (item.params && item.params.entity_type === 'deal') {
        return 'iconfont icon-deal4';
      }
    }
  }
};
exports.default = _default;