"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _vue = require("vue");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyScriptReviewNoScene',
  props: {
    scriptList: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const activeScriptItems = (0, _vue.ref)([]);
    (0, _vue.watch)(() => props.scriptList, val => {
      if (val.length) {
        activeScriptItems.value = val.filter(item => !item.not_triggered).map(item => `${item.id}`);
      }
    }, {
      immediate: true,
      deep: true
    });
    return {
      __sfc: true,
      props,
      activeScriptItems,
      t: _i18n.t,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;