"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "converstion-container"
  }, [_c("div", {
    staticClass: "question-part"
  }, [_c("div", {
    staticClass: "questions-wrap"
  }, _vm._l(_vm.questionList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "question-item"
    }, [_vm._m(0, true), _c("div", {
      staticClass: "content"
    }, [_c("span", {
      staticClass: "asker"
    }, [_vm._v(_vm._s(_vm.getSpeakerType(item.entity_type)) + "：")]), _c("span", {
      class: _vm.isShowOperate ? "words" : "",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(item.order);
        }
      }
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0)]), _c("div", {
    staticClass: "answer-part"
  }, _vm._l(_vm.answerList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "answer-item"
    }, [_vm._m(1, true), _c("div", {
      staticClass: "content"
    }, [_c("span", {
      staticClass: "speaker-label"
    }, [_vm._v(_vm._s(_vm.getSpeakerType(item.entity_type)))]), _c("span", {
      class: _vm.isShowOperate ? "words" : "",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(item.order);
        }
      }
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0), _vm.isShowDesc ? _c("div", {
    staticClass: "other-info"
  }, [_c("span", {
    staticClass: "sales-man"
  }, [_vm._v("销售：" + _vm._s(_vm.convItem.sale_name))]), _c("span", {
    staticClass: "dot"
  }, [_vm._v("·")]), _c("span", {
    staticClass: "date"
  }, [_vm._v("时间：" + _vm._s(_vm.getConvTime(_vm.convItem.conversation_time)))])]) : _vm._e(), _vm.isShowOperate ? _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    staticClass: "add-btn",
    on: {
      click: function ($event) {
        return _vm.$emit("moveFreqQuestion");
      }
    }
  }, [_vm._v("移动至其他问题")]), _c("el-button", {
    staticClass: "add-btn",
    on: {
      click: function ($event) {
        return _vm.$emit("addConv");
      }
    }
  }, [_vm._v("添加至知识库")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-type"
  }, [_c("i", {
    staticClass: "iconfont icon-ask"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-type"
  }, [_c("i", {
    staticClass: "iconfont icon-answer"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;