"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "menu-container",
    class: ["mgv-multi-level-menu", "menu-container", {
      "hidden-menu": _vm.isHiddenMenu
    }],
    on: {
      mouseleave: _vm.floatingMenuHidden
    }
  }, [_c("div", {
    staticClass: "menu-box"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.$t("coaching.collapse"),
      placement: "top",
      enterable: false
    }
  }, [_c("i", {
    staticClass: "iconfont icon-menu-collapse toogle-btn",
    on: {
      click: _vm.toggleMenu
    }
  })]), _c("div", {
    staticClass: "menu-logo"
  }, [_vm.menuTitle.length ? _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.menuTitle))]) : _vm._e(), _vm._t("title-suffix")], 2), _c("MenuContainer", {
    attrs: {
      "menu-list": _vm.menuList,
      "default-openeds": _vm.defaultOpeneds
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;