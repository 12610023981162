"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  name: 'BusinessPathSankeyChart',
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    },
    sankeyTopPath: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: {
          type: 'sankey',
          layout: 'none',
          top: '14',
          left: 30,
          bottom: '7%',
          right: 70,
          nodeGap: 10,
          lineStyle: {
            color: 'rgba(0,0,0,0.3)',
            curveness: 0.5
          },
          nodeAlign: 'left',
          label: {
            fontSize: 12,
            color: '#2B323B',
            formatter: param => {
              const labelName = param.name.replace(/\d_*/g, '');
              return labelName;
            }
          },
          emphasis: {
            disabled: true
          },
          data: [],
          links: [],
          nodeWidth: 20,
          draggable: false,
          layoutIterations: 0 // 每层节点的顺序是按照 data 中的顺序排列
        }
      }
    };
  },

  watch: {
    chartData: {
      handler(val) {
        if (val.data && val.data.length) {
          this.$nextTick(() => {
            // 解决加载没有动画问题
            this.chart.clear();
            this.options.series.data = val.data;
            this.options.series.links = val.links;
            this.options.tooltip.formatter = this.tooltipFormatter;
            this.chart.setOption(this.options);
          });
        }
      },
      deep: true,
      immediate: true
    },
    // top路径高亮
    sankeyTopPath: {
      handler(val) {
        this.$nextTick(() => {
          if (val.length) {
            const topPathLinks = this.getTopPathLinks(val);
            this.options.series.data = this.getNewAllData(topPathLinks);
            this.options.series.links = this.getNewAllLinks(topPathLinks);
            this.options.series.emphasis = {
              disabled: true,
              focus: 'none'
            };
          } else {
            this.options.series.data = this.chartData.data;
            this.options.series.links = this.chartData.links;
            this.options.series.emphasis = {
              disabled: false,
              focus: 'adjacency'
            };
          }
          this.chart.setOption(this.options);
        });
      },
      deep: true
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.chartResize);
    window.addEventListener('beforeunload', this.clearChart);
    this.chart.on('click', this.handleClick);
  },
  beforeDestroy() {
    this.clearChart();
    window.removeEventListener('resize', this.chartResize);
    window.removeEventListener('beforeunload', this.clearChart);
  },
  methods: {
    // 获取高亮 topn 的data名字和links流入流出占比
    getTopPathLinks(val) {
      const data = this.chart.getOption().series[0].data;
      const dataName = data.map(item => {
        return item.name;
      });
      // 处理桑基图其他高亮，source 或者 target 在data中不存在则替换为对应步骤的 其他
      const topPathLinks = val.map(item => {
        if (!dataName.includes(item.source)) {
          item.source = item.source.split('_')[0] + '_其他';
        }
        if (item.target && !dataName.includes(item.target)) {
          item.target = item.target.split('_')[0] + '_其他';
        }
        return item;
      });
      // 高亮数据的links的流入流出计算
      topPathLinks.forEach(item => {
        data.forEach(child => {
          if (child.name === item.source) {
            item.outflow_percent = item.value / child.value;
          }
          if (child.name === item.target) {
            item.inflow_percent = item.value / child.value;
          }
        });
      });
      return topPathLinks;
    },
    // 获取新的links(包括高亮数据和不高亮数据)
    getNewAllLinks(topPathLinks) {
      // originLinks 桑基图的初始值，也是全部值
      const originLinks = JSON.parse(JSON.stringify(this.chartData.links));
      // 先去除路线颜色或者节点的颜色
      originLinks.forEach(item => delete item.lineStyle);
      // 如果top路径只有一层关键事件
      if (!topPathLinks[0].target) {
        return originLinks;
      } else {
        // 原始的value减去topn的value
        topPathLinks.forEach(item => {
          // 非top路径的Links数据
          originLinks.forEach(child => {
            if (child.source === item.source && child.target === item.target) {
              // 原始的value减去topn的value
              child.value -= item.value;
              // 流入流出占比计算
              child.inflow_percent -= item.inflow_percent;
              child.outflow_percent -= item.outflow_percent;
            }
          });
          // 高亮颜色和label
          item.lineStyle = {
            color: 'gradient',
            opacity: 0.3,
            borderColor: '#fff',
            borderWidth: 3
          };
          item.label = {
            fontWeight: 600
          };
        });
        return [...topPathLinks, ...originLinks];
      }
    },
    // 获取新的Data(包括label加粗)
    getNewAllData(topPathLinks) {
      const data = this.chart.getOption().series[0].data;
      // 先去除label的加粗效果
      data.forEach(item => {
        delete item.label;
      });
      if (!topPathLinks[0].target) {
        // 找到top路线对应的节点,改变颜色
        data.find(child => {
          if (child.name === topPathLinks[0].source) {
            child.itemStyle = {
              borderColor: '#fff',
              borderWidth: 3
            };
          }
        });
      } else {
        // 找到top路线对应的top data,改变 fontWeight
        topPathLinks.forEach(item => {
          data.find(child => {
            if (child.name === item.source || child.name === item.target) {
              child.label = {
                fontWeight: 600
              };
            }
          });
        });
      }
      return data;
    },
    getKeyEventProportion(val) {
      if (val > 0.001 || val === 0) {
        return (val * 100).toFixed(2) + '%';
      } else if (val > 0.000001) {
        return (val * 100).toFixed(4) + '%';
      } else {
        return 0.0001 + '%';
      }
    },
    tooltipFormatter(param) {
      const toolName = param.name.replace(/\d_*/g, '');
      let tooltipHtml;
      // hover边或者hover节点
      if (param.dataType === 'node') {
        const step = param.name.split('_')[0];
        tooltipHtml = `<div class="business-path-sankey-tooltip">
        <div> 第 ${step} 步 </div>
        <div> ${toolName} </div>
        <div class="total-count">
        <div>总数</div>
        <div class="count">${param.data.value}</div>
        </div>
        </div>`;
      } else if (param.dataType === 'edge') {
        const outflow_percent = this.getKeyEventProportion(param.data.outflow_percent);
        const inflow_percent = this.getKeyEventProportion(param.data.inflow_percent);
        tooltipHtml = `<div class="business-path-sankey-tooltip">
        <div>${toolName}</div>
        <div class="total-count"><div>数量</div><div class="count">${param.data.value}</div></div>
        <div class="flow-proportion">
        <div class="outflow"><span class="flow-title">流出占比</span><span>${outflow_percent}</span></div>
        <div><span class="flow-title">流入占比</span><span>${inflow_percent}</span></div>
        </div>
        </div>`;
      }
      return tooltipHtml;
    },
    // 初始化 Chart
    initChart() {
      if (!this.$refs.chart) return;
      // 获取容器的dom并初始化图表
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption(this.options);
    },
    // 窗口大小变化时resize chart
    chartResize() {
      setTimeout(() => {
        this.chart.resize();
      }, 0);
    },
    clearChart() {
      // 销毁实例，实例销毁后无法再被使用
      this.chart && this.chart.dispose();
    }
  }
};
exports.default = _default;