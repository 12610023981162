"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _default = {
  name: 'ChatAgreement',
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async handleConfirm() {
      this.loading = true;
      const res = await (0, _user.confirmChatAgreement)({
        config_type: 'ai_assistant'
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.$store.commit('user/SET_IS_CONFIRM_CHAT_AGREEMENT', true);
        this.$emit('confirm');
      }
    }
  }
};
exports.default = _default;