"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$activeUserInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mg-calendar"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("span", {
    staticClass: "page-title"
  }, [_vm._v("日程")]), _c("space-button"), _c("span", {
    staticClass: "divider"
  }, [_vm._v("|")]), _c("el-button-group", [_c("el-button", {
    staticClass: "button group-button",
    class: {
      active: _vm.visibleCalendar === "month"
    },
    on: {
      click: function ($event) {
        return _vm.switchCalender("month");
      }
    }
  }, [_vm._v("我的日程")]), _c("el-button", {
    staticClass: "button group-button",
    class: {
      active: _vm.visibleCalendar === "day"
    },
    on: {
      click: function ($event) {
        return _vm.switchCalender("day");
      }
    }
  }, [_vm._v("团队日程")])], 1), _vm.visibleCalendar === "day" ? _c("el-date-picker", {
    staticClass: "flex-item select-time",
    attrs: {
      type: "date",
      format: "yyyy年 MM月 dd日 周ddd",
      placeholder: "选择日期",
      editable: false,
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.getDay,
      focus: _vm.changeSuffix,
      blur: _vm.restoreSuffix
    },
    model: {
      value: _vm.titleDate,
      callback: function ($$v) {
        _vm.titleDate = $$v;
      },
      expression: "titleDate"
    }
  }) : _c("el-date-picker", {
    staticClass: "flex-item select-time",
    attrs: {
      type: "month",
      format: "yyyy年 MM月",
      placeholder: "选择月",
      editable: false
    },
    on: {
      change: _vm.getMonth,
      focus: _vm.changeSuffix,
      blur: _vm.restoreSuffix
    },
    model: {
      value: _vm.titleMonth,
      callback: function ($$v) {
        _vm.titleMonth = $$v;
      },
      expression: "titleMonth"
    }
  }), _c("i", {
    staticClass: "el-icon-arrow-down",
    class: {
      "change-arrow-direction": _vm.changeArrowDirection
    }
  }), _c("el-button", {
    staticClass: "flex-item",
    attrs: {
      plain: "",
      circle: "",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: function ($event) {
        return _vm.switchDate(-1);
      }
    }
  }), _c("el-button", {
    attrs: {
      plain: "",
      circle: "",
      icon: "el-icon-arrow-right"
    },
    on: {
      click: function ($event) {
        return _vm.switchDate(1);
      }
    }
  }), _c("el-button", {
    staticClass: "today-button flex-item",
    on: {
      click: _vm.switchToday
    }
  }, [_vm._v("今天")]), _c("saved-filters", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleCalendar === "day",
      expression: "visibleCalendar === 'day'"
    }],
    key: _vm.workspaceInfo.id,
    ref: "savedFilters",
    staticClass: "saved-filters",
    attrs: {
      clearable: "",
      "get-filters-method": _vm.getScheduleFilter
    },
    on: {
      openFilterManage: _vm.openFilterManage,
      filterChange: _vm.savedFilterChange
    }
  }), _c("el-popover", {
    attrs: {
      placement: "bottom",
      "popper-class": "key-event-config-popover",
      trigger: "hover",
      disabled: _vm.disabled
    }
  }, [_c("div", {
    staticClass: "content-container"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/calendarShowKeyEvent.png")
    }
  }), _c("div", {
    staticClass: "title"
  }, [_vm._v("关键事件显示")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 设置后，若会话命中所选的关键事件，则在会话列表中显示关键事件的首字。 ")]), _c("el-button", {
    staticClass: "know-btn",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.hideTips
    }
  }, [_vm._v("我知道了")])], 1), _c("KeyEventConfig", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleCalendar === "day",
      expression: "visibleCalendar === 'day'"
    }],
    attrs: {
      slot: "reference",
      "key-event-config": _vm.keyEventConfig
    },
    on: {
      change: _vm.keyEventShowChange,
      keyEventVisible: _vm.handleKeyEventVisible
    },
    slot: "reference"
  })], 1), _vm.interfacePermission.getIntructionSet ? _c("mgv-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleCalendar === "day",
      expression: "visibleCalendar === 'day'"
    }],
    staticClass: "instruction-set-btn",
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-configuration",
      size: "medium"
    },
    on: {
      click: _vm.openInstructionSetDrawer
    }
  }, [_vm._v("指令集")]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleCalendar === "day",
      expression: "visibleCalendar === 'day'"
    }],
    staticClass: "filters-container"
  }, [_c("TeamScheduleFilter", {
    key: _vm.workspaceInfo.id,
    attrs: {
      "call-back-filters": _vm.callBackFilters
    },
    on: {
      change: _vm.debounceTeamScheduleFilterChange
    }
  }), _c("el-button", {
    attrs: {
      size: "medium",
      disabled: _vm.saveDisabled
    },
    on: {
      click: _vm.saveAsFilter
    }
  }, [_vm._v("保存为常用筛选")])], 1), _vm.visibleCalendar === "day" ? _c("day-calendar", {
    ref: "day-calendar",
    attrs: {
      data: _vm.membersOptions,
      "need-load-more": _vm.needTeamLoadMore,
      loading: _vm.loading
    },
    on: {
      dateChange: _vm.dateChange,
      loadMore: _vm.getTeamSchedule,
      clickFixedElement: _vm.clickFixedElement
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var _column$copilot_instr, _column$copilot_instr2;
        let {
          column
        } = _ref;
        return [_c("div", {
          staticClass: "avatar-container"
        }, [!_vm.interfacePermission.getIntructionSet || !((_column$copilot_instr = column.copilot_instruction_set_ids) !== null && _column$copilot_instr !== void 0 && _column$copilot_instr.length) ? _c("div", {
          staticClass: "left-info"
        }, [_c("image-avatar", {
          attrs: {
            avatar: column.avatar,
            name: column.name
          }
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(column.name))])], 1) : _vm._e(), _vm.interfacePermission.getIntructionSet && (_column$copilot_instr2 = column.copilot_instruction_set_ids) !== null && _column$copilot_instr2 !== void 0 && _column$copilot_instr2.length ? _c("InstructionResIconPopover", {
          key: column.id,
          attrs: {
            "request-method": _vm.getInstructionResult,
            "run-request-method": _vm.runInstruction,
            "request-params": {
              user_id: column.id,
              space_id: _vm.workspaceInfo.id,
              date: _vm.titleDate
            }
          }
        }, [_c("div", {
          staticClass: "popover-avatar-container"
        }, [_c("image-avatar", {
          attrs: {
            avatar: column.avatar,
            name: column.name
          }
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(column.name))]), _c("i", {
          staticClass: "iconfont icon-tickets-ai"
        })], 1)]) : _vm._e(), _c("div", {
          staticClass: "right-info"
        }, [_vm.interfacePermission.showChatEntry && column.has_ai_assistant ? _c("mgv-button", {
          attrs: {
            type: "text",
            color: "info",
            icon: "icon-magic-1 colorful",
            size: "smini",
            "tooltip-content": "AI助手"
          },
          on: {
            click: function ($event) {
              return _vm.openChatDrawer(column);
            }
          }
        }) : _vm._e()], 1)], 1), _vm.meetingCount(column) ? _c("div", {
          staticClass: "count-container"
        }, [_vm._l(_vm.meetingCount(column), function (value, keyName) {
          return [keyName === "total" ? _c("span", {
            key: keyName,
            staticClass: "tatal-meeting"
          }, [_vm._v(_vm._s(_vm.meetingCount(column).total) + "个会话")]) : value ? _c("span", {
            key: keyName,
            staticClass: "meeting-count"
          }, [_c("svg-icon", {
            staticClass: "meeting-type-icon",
            attrs: {
              "icon-class": _vm.getConversationIcon(keyName)
            }
          }), _vm._v(_vm._s(value) + " ")], 1) : _vm._e()];
        })], 2) : _c("span", {
          staticClass: "no-meeting"
        }, [_vm._v("0个会话")])];
      }
    }, {
      key: "content",
      fn: function (_ref2) {
        let {
          hour,
          column
        } = _ref2;
        return [_c("div", {
          staticClass: "daily-content"
        }, _vm._l(_vm.handleDailyContent(hour, column), function (item, index) {
          return _c("div", {
            key: index,
            staticClass: "preview-card"
          }, [_c("el-popover", {
            key: index,
            ref: `detail-card-popover-${column.id}-${hour.hour}-${index}`,
            refInFor: true,
            attrs: {
              "popper-class": "detail-card-popover",
              "visible-arrow": false,
              trigger: "click",
              placement: "left-end",
              "open-delay": 600
            }
          }, [item === _vm.activeDailyItem ? _c("daily-detail-card", {
            directives: [{
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailCardLoading,
              expression: "detailCardLoading"
            }],
            key: index,
            attrs: {
              detail: _vm.calendarDetail,
              "event-engine-list": _vm.eventEngineList
            },
            on: {
              cancelSucceed: _vm.renewData
            }
          }) : _vm._e(), _c("daily-preview-card", {
            attrs: {
              slot: "reference",
              detail: item,
              "key-event-config": _vm.keyEventConfig
            },
            on: {
              click: function ($event) {
                return _vm.openDailyItemDetail(item, `detail-card-popover-${column.id}-${hour.hour}-${index}`);
              }
            },
            slot: "reference"
          })], 1)], 1);
        }), 0)];
      }
    }], null, false, 370474099)
  }) : _c("month-calendar", {
    ref: "month-calendar",
    on: {
      monthChange: _vm.monthChange
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref3) {
        let {
          date
        } = _ref3;
        return [_c("div", {
          staticClass: "monthly-content"
        }, _vm._l(_vm.handleContent(date), function (item, index) {
          return _c("el-popover", {
            key: index,
            ref: `monthly-card-popover-${date}-${index}`,
            refInFor: true,
            class: _vm.getItemClass(item.conversation_status),
            attrs: {
              trigger: "click",
              placement: "left-end",
              "popper-class": "monthly-popover",
              "visible-arrow": false
            }
          }, [item === _vm.activeDailyItem ? _c("daily-detail-card", {
            directives: [{
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailCardLoading,
              expression: "detailCardLoading"
            }],
            key: index,
            attrs: {
              detail: _vm.calendarDetail,
              "event-engine-list": _vm.eventEngineList
            },
            on: {
              cancelSucceed: _vm.renewData
            }
          }) : _vm._e(), _c("mg-tag", {
            attrs: {
              slot: "reference",
              clickable: "",
              size: "small"
            },
            on: {
              click: function ($event) {
                return _vm.openDailyItemDetail(item, `monthly-card-popover-${date}-${index}`);
              }
            },
            slot: "reference"
          }, [_c("span", {
            staticClass: "line-over-ellipsis"
          }, [_vm._v(_vm._s(_vm.$moment(item.conversation_date).format("HH:mm")) + " "), _c("span", [_vm._v("|")]), _c("svg-icon", {
            attrs: {
              "icon-class": _vm.getConversationIcon(item.conversation_type)
            }
          }), _vm._v(" " + _vm._s(item.contact_name))], 1)])], 1);
        }), 1)];
      }
    }])
  }), _c("meeting-dialog", _vm._b({
    on: {
      closeDialog: function ($event) {
        _vm.meetingProps.dialogVisible = false;
      },
      submitSucceed: _vm.renewData
    }
  }, "meeting-dialog", _vm.meetingProps, false)), _c("copy-url", _vm._b({
    on: {
      closeUrlDialog: function ($event) {
        _vm.copyUrlProps.visible = false;
      }
    }
  }, "copy-url", _vm.copyUrlProps, false)), _c("invite-succeed-dialog", {
    attrs: {
      "invite-text": _vm.inviteText,
      visible: _vm.inviteSucceedDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.inviteSucceedDialogVisible = $event;
      }
    }
  }), _c("filter-manage-drawer", {
    attrs: {
      "get-filters-method": _vm.getScheduleFilter,
      "delete-method": _vm.deleteScheduleFilter,
      "rename-method": _vm.updateScheduleFilter,
      "filter-manage-visible": _vm.filterManageVisible
    },
    on: {
      "update:filterManageVisible": function ($event) {
        _vm.filterManageVisible = $event;
      },
      "update:filter-manage-visible": function ($event) {
        _vm.filterManageVisible = $event;
      }
    }
  }), _c("common-rename-dialog", {
    ref: "renameDialog",
    attrs: {
      loading: _vm.saveLoading,
      visible: _vm.saveFilterDialogVisible,
      "input-info": _vm.inputInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.saveFilterDialogVisible = $event;
      },
      submitForm: _vm.createFilter,
      closeDialog: function ($event) {
        _vm.saveFilterDialogVisible = false;
      }
    }
  }), _c("InstructionSetDrawer", {
    attrs: {
      visible: _vm.instructionSetVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.instructionSetVisible = $event;
      }
    }
  }), (_vm$activeUserInfo = _vm.activeUserInfo) !== null && _vm$activeUserInfo !== void 0 && _vm$activeUserInfo.has_ai_assistant && _vm.chatDrawerVisible ? _c("AIAssistantChatDrawer", {
    key: _vm.workspaceInfo.id,
    attrs: {
      "prompt-config": _vm.chatPromptConfig,
      "request-config": _vm.chatRequestConfig,
      "entity-type": 2,
      "question-import": "",
      "import-tooltip": "添加至指令集",
      "is-show-chat-input": "",
      "check-is-support": ""
    },
    on: {
      importClick: _vm.chatImport,
      close: _vm.closeChatDrawer
    }
  }) : _vm._e(), _vm.createInstructionDialogVisible ? _c("CreateInstructionDialog", {
    attrs: {
      visible: _vm.createInstructionDialogVisible,
      "back-info": _vm.instructionInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.createInstructionDialogVisible = $event;
      }
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;