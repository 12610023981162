"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "items-flow-temp"
  }, _vm._l(_vm.infos, function (dateItem) {
    return _c("div", {
      key: dateItem.id,
      staticClass: "flow-date-item",
      class: {
        "active-item": dateItem.id === _vm.busiActiveConver
      },
      on: {
        click: function ($event) {
          return _vm.selectItem(dateItem.id);
        }
      }
    }, [_c("div", {
      staticClass: "left-icons",
      class: {
        connected: dateItem.conversation_status !== "expire",
        reserved: dateItem.conversation_status === "plan"
      }
    }, [_c("i", {
      staticClass: "icon-wrapper self-icon",
      class: {
        "no-finish": dateItem.conversation_status === "plan",
        absent: dateItem.conversation_status === "expire"
      }
    }, [_c("svg-icon", {
      staticClass: "conv-icon",
      attrs: {
        "icon-class": _vm.getIconType(dateItem.type, dateItem.conversation_status)
      }
    })], 1), _c("i", {
      staticClass: "icon-wrapper customer-icon",
      class: {
        "no-finish": dateItem.conversation_status === "plan",
        absent: dateItem.conversation_status === "expire"
      }
    }, [_c("svg-icon", {
      staticClass: "conv-icon",
      attrs: {
        "icon-class": _vm.getIconType(dateItem.type, dateItem.conversation_status)
      }
    })], 1)]), _c("div", {
      staticClass: "right-infos"
    }, [_c("div", {
      staticClass: "title-line"
    }, [_c("p", {
      staticClass: "title-name"
    }, [dateItem.origin_type === "wework_internal_app" ? _c("img", {
      staticClass: "title-logo",
      attrs: {
        src: require("@/assets/wework.svg"),
        alt: "logo"
      }
    }) : dateItem.origin_type === "tencent_meeting" ? _c("img", {
      staticClass: "title-logo",
      attrs: {
        src: require("@/assets/tencent_meeting.svg"),
        alt: "logo"
      }
    }) : _vm._e(), _c("span", {
      staticClass: "title-text-time"
    }, [_c("span", {
      staticClass: "title-text"
    }, [_vm._v(_vm._s(dateItem.name))]), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: dateItem.conversation_status === "attend",
        expression: "dateItem.conversation_status === 'attend'"
      }],
      staticClass: "conv-duration"
    }, [_vm._v(_vm._s(_vm.getDuration(dateItem.duration)))])])]), _c("span", {
      staticClass: "conv-time"
    }, [_c("svg-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: dateItem.conver_status === "plan",
        expression: "dateItem.conver_status === 'plan'"
      }],
      staticClass: "date-icon",
      attrs: {
        "icon-class": "reservation-2"
      }
    }), _vm._v(" " + _vm._s(_vm.getTimeText(dateItem.begin_time)) + " ")], 1)]), _c("div", {
      staticClass: "person-line"
    }, [_c("VirtualMoreUserListInPopover", {
      attrs: {
        list: dateItem.host_salesman,
        "exposed-count": 1,
        "virtual-list-data-key": "name",
        "append-to-body": ""
      },
      scopedSlots: _vm._u([{
        key: "exposed-avatar",
        fn: function (_ref) {
          let {
            exposedItem
          } = _ref;
          return [_c("div", {
            staticClass: "sales-info person-info"
          }, [_c("image-avatar", {
            attrs: {
              name: exposedItem.name,
              avatar: exposedItem.avatar
            }
          }), _c("div", {
            staticClass: "right-person-info"
          }, [_c("div", {
            staticClass: "person-name"
          }, [_vm._v(_vm._s(exposedItem.name))]), _c("div", {
            staticClass: "other-person-info"
          }, [_c("span", [_vm._v(_vm._s(exposedItem.label || "销售代表"))]), _c("span", [_vm._v(" / ")]), _c("span", [_vm._v(_vm._s(_vm.orgName))])])])], 1)];
        }
      }, {
        key: "virtual-list-item",
        fn: function (_ref2) {
          let {
            virtualListItem
          } = _ref2;
          return [_c("div", {
            staticClass: "sales-info person-info"
          }, [_c("image-avatar", {
            attrs: {
              name: virtualListItem.name,
              avatar: virtualListItem.avatar
            }
          }), _c("div", {
            staticClass: "right-person-info"
          }, [_c("div", {
            staticClass: "person-name"
          }, [_vm._v(_vm._s(virtualListItem.name))]), _c("div", {
            staticClass: "other-person-info"
          }, [_c("span", [_vm._v(_vm._s(virtualListItem.label || "销售代表"))])])])], 1)];
        }
      }], null, true)
    }), _c("VirtualMoreUserListInPopover", {
      attrs: {
        list: dateItem.customer_contact,
        "exposed-count": 1,
        "virtual-list-data-key": "name",
        "append-to-body": ""
      },
      scopedSlots: _vm._u([{
        key: "exposed-avatar",
        fn: function (_ref3) {
          let {
            exposedItem
          } = _ref3;
          return [_c("div", {
            staticClass: "customer-info person-info"
          }, [_c("image-avatar", {
            attrs: {
              name: exposedItem.name ? exposedItem.name : "客户",
              avatar: exposedItem.avatar,
              type: "customer_contact"
            }
          }), _c("div", {
            staticClass: "right-person-info"
          }, [_c("div", {
            staticClass: "person-name"
          }, [_vm._v(" " + _vm._s(exposedItem.name || "未知姓名") + " ")]), _c("div", {
            staticClass: "other-person-info"
          }, [_c("span", [_vm._v(_vm._s(exposedItem.label || "联系人"))]), _c("span", [_vm._v(" / ")]), _c("span", [_vm._v(_vm._s(_vm.customerName || "未知公司"))])])])], 1)];
        }
      }, {
        key: "virtual-list-item",
        fn: function (_ref4) {
          let {
            virtualListItem
          } = _ref4;
          return [_c("div", {
            staticClass: "customer-info person-info"
          }, [_c("image-avatar", {
            attrs: {
              name: virtualListItem.name ? virtualListItem.name : "客户",
              avatar: virtualListItem.avatar,
              type: "customer_contact"
            }
          }), _c("div", {
            staticClass: "right-person-info"
          }, [_c("div", {
            staticClass: "person-name"
          }, [_vm._v(" " + _vm._s(virtualListItem.name || "未知姓名") + " ")]), _c("div", {
            staticClass: "other-person-info"
          }, [_c("span", [_vm._v(_vm._s(virtualListItem.label || "联系人"))])])])], 1)];
        }
      }], null, true)
    })], 1)]), _vm.getIsShowButton(dateItem) ? _c("div", {
      staticClass: "hover-button",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.goTargetPage(dateItem);
        }
      }
    }, [_c("el-button", {
      attrs: {
        icon: _vm.getHoverIcon(dateItem),
        type: "primary"
      }
    }, [_vm._v(_vm._s(_vm.getHoverText(dateItem)))])], 1) : _vm._e()]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;