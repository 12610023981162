"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mgv-member-multi-select"
  }, [_c("el-popover", {
    ref: "member-tree-select-popover",
    attrs: {
      placement: "bottom-start",
      "popper-class": _vm.popoverVisible ? "tree-select-popover collapase popover-show" : "tree-select-popover collapase",
      "visible-arrow": false,
      trigger: "click",
      disabled: _vm.readOnly
    },
    on: {
      "after-enter": function ($event) {
        return _vm.$refs["member-tree-select-popover"].updatePopper();
      }
    },
    model: {
      value: _vm.popoverVisible,
      callback: function ($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c("ValueContainer", {
    ref: "value-container",
    attrs: {
      slot: "reference",
      "show-tag-count": _vm.showTagCount,
      placeholder: _vm.placeholder,
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.handleSelectionList(),
      "read-only": _vm.readOnly
    },
    on: {
      removeItem: _vm.removeTag,
      clear: _vm.clear
    },
    slot: "reference"
  }), _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.hanldeClosePopover,
      expression: "hanldeClosePopover"
    }],
    ref: "tree-select-options-container",
    staticClass: "tree-select-options-container"
  }, [_c("SearchContainer", {
    attrs: {
      "is-searching": _vm.isSearching,
      "popover-visible": _vm.popoverVisible,
      "remote-loading": _vm.remoteLoading,
      "selection-list": _vm.selectionList,
      "search-options": _vm.searchOptions
    },
    on: {
      "update:isSearching": function ($event) {
        _vm.isSearching = $event;
      },
      "update:is-searching": function ($event) {
        _vm.isSearching = $event;
      },
      "update:remoteLoading": function ($event) {
        _vm.remoteLoading = $event;
      },
      "update:remote-loading": function ($event) {
        _vm.remoteLoading = $event;
      },
      removeItem: _vm.removeTag,
      chekedItem: _vm.addTag,
      filterChange: _vm.getSearchOptions
    }
  }), _c("MemberLazyTree", _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSearching,
      expression: "!isSearching"
    }],
    ref: "member-lazy-tree",
    attrs: {
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.selectionList
    },
    on: {
      checkChange: _vm.treeCheckChange
    }
  }, "MemberLazyTree", _vm.$attrs, false))], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;