"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "share-config",
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "400px"
    }
  }, [_vm.description ? _c("div", {
    staticClass: "explain"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("span", [_vm._v(_vm._s(_vm.description))])]) : _vm._e(), _c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.title) + "给")]), _c("div", {
    staticClass: "share-select-box"
  }, [_c("mgv-member-multi-select", {
    ref: "member-tree-select",
    attrs: {
      "is-prevent-not-selected": false,
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.searchUserFuntion,
      "request-params": {
        type: JSON.stringify(["conversation", "deal"])
      },
      placeholder: "请选择部门或成员"
    },
    on: {
      change: _vm.assigneeChange
    }
  }), _c("el-button", {
    attrs: {
      disabled: _vm.shareBtnDisabled,
      loading: _vm.handleLoading,
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.title))])], 1), _vm.isShowSyncData ? _c("div", {
    staticClass: "sync-data"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.isSyncData,
      callback: function ($$v) {
        _vm.isSyncData = $$v;
      },
      expression: "isSyncData"
    }
  }, [_vm._v("同步数据范围")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "选中后，所有被分享者将拥有与您一致的数据范围，反之，则只能查看各自权限范围内的数据"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getListLoading,
      expression: "getListLoading"
    }],
    staticClass: "has-share-box"
  }, [_vm.hasData ? _c("p", {
    staticClass: "label"
  }, [_vm._v(" 已" + _vm._s(_vm.title) + " "), _vm.clearable ? _c("el-button", {
    staticClass: "cancel-btn",
    attrs: {
      loading: _vm.cancelAllConfig.loading
    },
    on: {
      click: function ($event) {
        return _vm.cancel(_vm.cancelAllConfig, "all", "全部");
      }
    }
  }, [_vm._v("全部取消")]) : _vm._e()], 1) : _vm._e(), _c("ul", {
    staticClass: "assingee-list"
  }, [_vm._l(_vm.list.user_list, function (item) {
    return _c("li", {
      key: item.user_id,
      staticClass: "item"
    }, [_c("span", {
      staticClass: "info"
    }, [_c("mgv-avatar-name", {
      attrs: {
        name: item.name,
        src: item.avatar
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")], 1), _c("el-button", {
      staticClass: "cancel-btn",
      attrs: {
        loading: item.loading
      },
      on: {
        click: function ($event) {
          return _vm.cancel(item, "user");
        }
      }
    }, [_vm._v("取消" + _vm._s(_vm.title))])], 1);
  }), _vm._l(_vm.list.tree_list, function (item) {
    return _c("li", {
      key: item.tree_id,
      staticClass: "item"
    }, [_c("span", {
      staticClass: "info"
    }, [_c("mgv-avatar-name", {
      attrs: {
        name: item.name,
        icon: `iconfont ${item.is_root ? "icon-office-building" : "icon-department"}`
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")], 1), _c("el-button", {
      staticClass: "cancel-btn",
      attrs: {
        loading: item.loading
      },
      on: {
        click: function ($event) {
          return _vm.cancel(item, "department");
        }
      }
    }, [_vm._v("取消" + _vm._s(_vm.title))])], 1);
  })], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;