"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "resigned-member"
  }, [_c("div", {
    staticClass: "resigned-member-title"
  }, [_c("div", {
    staticClass: "go-back-box hover-show-bg",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back-btn",
    attrs: {
      "icon-class": "arrow-left"
    }
  })], 1), _c("div", {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("management.已离职成员")))])]), _c("div", {
    staticClass: "resigned-member-container"
  }, [_c("el-input", {
    staticClass: "filter-input",
    attrs: {
      "prefix-icon": "el-icon-search",
      placeholder: _vm.$t("management.搜索成员")
    },
    on: {
      input: _vm.getFilterList
    },
    model: {
      value: _vm.filterName,
      callback: function ($$v) {
        _vm.filterName = $$v;
      },
      expression: "filterName"
    }
  }), _c("resigned-member-table", {
    attrs: {
      "table-loading": _vm.tableLoading,
      "table-data": _vm.tableData
    },
    on: {
      refresh: _vm.handleRefresh
    }
  })], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-sizes": [10, 20, 30, 40],
      "current-page": _vm.listParams.page,
      "page-size": _vm.listParams.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;