"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "lead-form",
    class: {
      "after-conv": _vm.isAfterConv
    }
  }, [_vm._m(0), _c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.formDesc,
      isEdit: _vm.isEdit
    },
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }), _vm.type === "create" ? _c("div", {
    staticClass: "button-row"
  }, [_c("el-button", {
    staticClass: "mg-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    directives: [{
      name: "debounce",
      rawName: "v-debounce",
      value: [_vm.submit],
      expression: "[submit]"
    }],
    staticClass: "mg-button",
    attrs: {
      type: "primary"
    }
  }, [_vm._v("创建")])], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header"
  }, [_c("h5", [_vm._v("线索信息")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;