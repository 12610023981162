"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MultipleCascaderSelect = _interopRequireDefault(require("@/components/MultipleCascaderSelect"));
var _ConversationFilterItem = _interopRequireDefault(require("@/views/conversationPage/components/ConversationFilterItem.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MultipleCascaderTracker',
  components: {
    MultipleCascaderSelect: _MultipleCascaderSelect.default,
    ConversationFilterItem: _ConversationFilterItem.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [String, Object],
      default: () => ({})
    },
    otherPageAccountLabel: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultProps: {
        expandTrigger: 'hover',
        emitPath: false,
        multiple: true,
        children: 'children',
        label: 'name',
        value: 'id'
      },
      accountLabelFilter: {
        keyEventCascaderList: [{
          keyEventName: []
        }],
        event_engine_relation: 'and'
      }
    };
  },
  computed: {
    isDeleteIconShow() {
      const originFilter = this.$options.data().accountLabelFilter;
      return JSON.stringify(this.accountLabelFilter) !== JSON.stringify(originFilter);
    }
  },
  watch: {
    value: {
      handler(val) {
        if (!val || JSON.stringify(val) === '{}') {
          this.accountLabelFilter = this.$options.data().accountLabelFilter;
        } else {
          const {
            relation,
            value
          } = val;
          if (!value.length) {
            this.accountLabelFilter = this.$options.data().accountLabelFilter;
          } else {
            this.accountLabelFilter.event_engine_relation = relation;
            this.accountLabelFilter.keyEventCascaderList = value.map(item => {
              return {
                keyEventName: item
              };
            });
          }
        }
      },
      immediate: true
    },
    // 客户群画像回填数据
    otherPageAccountLabel(val) {
      if (val.length) {
        this.accountLabelFilter.keyEventCascaderList = val.map(item => {
          return {
            keyEventName: item.value
          };
        });
      }
    }
  },
  methods: {
    handleMultipleCascaderchange(val) {
      const value = val.keyEventCascaderList.map(item => item.keyEventName).filter(item => item.length);
      const account_label_params = {
        relation: val.event_engine_relation,
        value
      };
      this.$emit('change', {
        [this.item.field_name]: account_label_params
      });
    },
    // 清空客户标签选择器级联数据
    clearAccountLabel() {
      this.accountLabelFilter = this.$options.data().accountLabelFilter;
      this.$emit('change', {
        [this.item.field_name]: {
          relation: 'and',
          value: []
        }
      });
    }
  }
};
exports.default = _default;