"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("detail-header", {
    staticClass: "detail-header",
    attrs: {
      "back-button": true
    },
    scopedSlots: _vm._u([{
      key: "title_log",
      fn: function () {
        return [_c("i", {
          class: ["medium-icon", "iconfont", _vm.getMediumIcon(_vm.mediaType)]
        })];
      },
      proxy: true
    }, {
      key: "prefix",
      fn: function () {
        return [_vm.originType === "wework_internal_app" ? _c("img", {
          staticClass: "nav-logo",
          attrs: {
            src: require("@/assets/wework.svg"),
            alt: "logo"
          }
        }) : _vm.originType === "tencent_meeting" ? _c("img", {
          staticClass: "nav-logo",
          attrs: {
            src: require("@/assets/tencent_meeting.svg"),
            alt: "logo"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [Number(_vm.$route.query.sampling_type) ? _c("ConversationSelector", {
          ref: "ConversationSelector",
          attrs: {
            "conversation-title-info": _vm.conversationTitleInfo,
            "customer-name": _vm.getCustomerName,
            "sales-name": _vm.getSalesman.name,
            "conv-id": _vm.convId
          }
        }) : _c("div", {
          staticClass: "conversation-title"
        }, [_vm._v(" " + _vm._s(_vm.conversationTitleInfo.convName) + " ")])];
      },
      proxy: true
    }, {
      key: "header_left_bottom",
      fn: function () {
        return [_vm.conversationTitleInfo.convTime ? _c("div", {
          staticClass: "header-left-bottom"
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话归属人",
            placement: "top"
          }
        }, [_vm.getSalesman ? _c("div", {
          staticClass: "salesman"
        }, [_c("image-avatar", {
          attrs: {
            avatar: _vm.getSalesman.avatar,
            name: _vm.getSalesman.name
          }
        }), _c("span", [_vm._v(_vm._s(_vm.getSalesman.name))])], 1) : _vm._e()]), _vm.convDuration ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.convDuration ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话时长",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-dur"
        }, [_vm._v(_vm._s(_vm.convDuration))])]) : _vm._e(), _c("b", [_vm._v("·")]), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "会话时间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-time"
        }, [_c("div", {
          staticClass: "conv-date"
        }, [_vm._v(_vm._s(_vm.convDate))]), _c("div", [_vm._v(_vm._s(_vm.convTime))])])]), _vm.getDealName ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.getDealName ? _c("el-popover", {
          attrs: {
            placement: "top",
            trigger: "hover",
            "popper-class": "conv-detail-copy-name-button",
            "visible-arrow": false
          }
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "复制交易名称",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.getDealName,
            expression: "getDealName",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.onCopy,
            expression: "onCopy",
            arg: "success"
          }],
          staticClass: "iconfont icon-copy-document"
        })]), _c("div", {
          staticClass: "deal-name",
          attrs: {
            slot: "reference"
          },
          on: {
            click: _vm.goTradePage
          },
          slot: "reference"
        }, [_c("i", {
          staticClass: "iconfont icon-deal4"
        }), _vm._v(" " + _vm._s(_vm.getDealName) + " ")])], 1) : _vm._e(), _vm.getDealName ? _c("div", {
          staticClass: "deal-name-content"
        }) : _vm._e(), _vm.getCustomerName ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.getCustomerName ? _c("el-popover", {
          attrs: {
            placement: "top",
            trigger: "hover",
            "popper-class": "conv-detail-copy-name-button",
            "visible-arrow": false
          }
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "复制客户名称",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.getCustomerName,
            expression: "getCustomerName",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.onCopy,
            expression: "onCopy",
            arg: "success"
          }],
          staticClass: "iconfont icon-copy-document"
        })]), _c("div", {
          staticClass: "customer-name",
          attrs: {
            slot: "reference"
          },
          on: {
            click: _vm.goCustomerPage
          },
          slot: "reference"
        }, [_c("i", {
          staticClass: "iconfont icon-office-building"
        }), _vm._v(" " + _vm._s(_vm.getCustomerName) + " ")])], 1) : _vm._e(), _vm.workspace ? _c("b", [_vm._v("·")]) : _vm._e(), _vm.workspace ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "所属空间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "conv-workspace"
        }, [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: _vm.workspace.icon_color,
            "margin-right": "4px"
          }
        }), _c("span", [_vm._v(_vm._s(_vm.workspace.name))])])]) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [!_vm.isSnippetStatus ? _c("div", {
          staticClass: "top-btns"
        }, [_vm.hasEvaluation ? _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "evaluation"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-compliance-evaluation-form"
          },
          on: {
            click: function ($event) {
              return _vm.openRightDrawer("evaluation");
            }
          }
        }, [_vm._v("评价单")]) : _vm._e(), _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "compliance"
          },
          attrs: {
            plain: "",
            icon: "iconfont icon-protect-line"
          },
          on: {
            click: function ($event) {
              return _vm.openRightDrawer("compliance");
            }
          }
        }, [_vm._v("任务信息")]), _c("el-button", {
          staticClass: "hover-show-bg",
          class: {
            active: _vm.rightBarActive === "comments"
          },
          attrs: {
            id: "conv-detail-right-comment-button",
            plain: "",
            icon: "iconfont icon-chat-line-square"
          },
          on: {
            click: function ($event) {
              return _vm.openRightDrawer("comments");
            }
          }
        }, [_vm._v("评论 (" + _vm._s(_vm.commentsCount) + ")")]), _vm.audio && _vm.audio.length ? _c("el-button", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/library/snippet/create"],
            expression: "['/api/library/snippet/create']"
          }],
          staticClass: "hover-show-bg",
          attrs: {
            plain: "",
            icon: "iconfont icon-folder"
          },
          on: {
            click: _vm.addVideoToSnippet
          }
        }, [_vm._v("添加至剪辑库")]) : _vm._e()], 1) : _vm._e(), _vm.isSnippetStatus ? _c("snippet-video-button", {
          staticClass: "snippet-video-button",
          attrs: {
            type: "primary",
            "media-type": _vm.mediaType,
            "conversation-id": _vm.convId
          },
          on: {
            cancelSnippet: _vm.onCancelSnippet
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;