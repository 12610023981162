"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _DealForm = _interopRequireDefault(require("@/components/DealForm"));
var _LeadForm = _interopRequireDefault(require("@/components/LeadForm"));
var _clues = require("@/api/clues");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DealForm: _DealForm.default,
    LeadForm: _LeadForm.default
  },
  beforeRouteLeave(to, from, next) {
    // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
    if (this.hasChange) {
      setTimeout(() => {
        this.$confirm('正在离开本页面，本页面内未保存数据将会丢失', '警告', {
          confirmButtonText: '放弃修改',
          cancelButtonText: '继续修改',
          type: 'warning'
        }).then(() => {
          // 正常跳转
          next();
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          this.hasChange = false;
          throw new Error('不跳转');
        });
      }, 200);
    } else {
      next();
    }
  },
  data() {
    return {
      convTitle: '第一次视频会话',
      saveLoading: false,
      notes: null,
      conv_id: null,
      deal_id: null,
      lead_id: null,
      dealFormData: {
        customer_name: '',
        deal_name: '',
        amount: '',
        status: '',
        deal_time: '',
        next_connect_time: '',
        decision_maker: {
          name: '',
          tel: '',
          email: ''
        },
        other_contacts: [{
          name: '',
          tel: '',
          email: ''
        }, {
          name: '',
          tel: '',
          email: ''
        }]
      },
      leadFormData: null,
      initForm: null,
      hasChange: false
    };
  },
  computed: {},
  watch: {
    dealFormData: {
      deep: true,
      handler: function (newV, oldV) {
        const initStr = JSON.stringify(this.initForm);
        if (initStr === JSON.stringify(newV)) {
          this.hasChange = false;
        } else {
          this.hasChange = true;
        }
      }
    }
  },
  created() {
    this.conv_id = this.$route.query && this.$route.query.conv_id ? this.$route.query.conv_id : 0;
    this.deal_id = this.$route.query && this.$route.query.deal_id ? this.$route.query.deal_id : 0;
    this.lead_id = this.$route.query && this.$route.query.lead_id ? this.$route.query.lead_id : 0;
    this.convTitle = sessionStorage.getItem('video_room_conv_name') ? sessionStorage.getItem('video_room_conv_name') : '会话信息';
    // this.getNotes();
    if (this.deal_id) {
      this.getBusiChanceInfo();
    }
    if (this.lead_id) {
      this.getOneCluesInfo();
    }
  },
  mounted() {
    window.addEventListener('beforeunload', e => {
      if (this.hasChange && this.$route.path === _routeMap.ROUTES.personalCenter) {
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '关闭提示';
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return '关闭提示';
      }
    });
  },
  beforeDestroy() {
    sessionStorage.setItem('video_room_conv_name', '');
  },
  methods: {
    getBusiChanceInfo() {
      (0, _business.getOneBusiness)(this.deal_id).then(infoRes => {
        this.initForm = JSON.parse(JSON.stringify(infoRes.results));
        this.dealFormData = JSON.parse(JSON.stringify(infoRes.results));
      });
    },
    getOneCluesInfo() {
      (0, _clues.getOneCluesInfo)(this.lead_id).then(infoRes => {
        this.initForm = JSON.parse(JSON.stringify(infoRes.results));
        this.leadFormData = JSON.parse(JSON.stringify(infoRes.results));
      });
    },
    handleSave() {
      if (this.deal_id) {
        this.saveDeal();
      }
      if (this.lead_id) {
        this.saveLead();
      }
    },
    saveBusiness() {
      new Promise((resolve, reject) => {
        const child = this.$refs.busi_form;
        child.$refs['business-form'].validate(valid => {
          if (valid) {
            this.saveLoading = true;
            // 处理对象和数组，转换为Jason字符串
            const formData = this.handleBusiFormData();
            // updateForm.other_contacts = other_contactsStr
            (0, _business.editBusiness)(formData).then(res => {
              if (res.code === 200) {
                this.hasChange = false;
              }
              this.saveLoading = false;
              this.$router.push({
                path: _routeMap.ROUTES.conversation
              });
            })
            // eslint-disable-next-line handle-callback-err
            .catch(err => {
              this.saveLoading = false;
            });
            resolve();
          } else {
            throw new Error('校验失败');
            // eslint-disable-next-line no-unreachable
            reject(false);
          }
        });
      });
    },
    saveClues() {
      new Promise((resolve, reject) => {
        const child = this.$refs.clues_form;
        child.$refs['clues-form'].validate(valid => {
          if (valid) {
            this.saveLoading = true;
            // 处理对象和数组，转换为Jason字符串
            const formData = this.handleCluesFormData();
            // updateForm.other_contacts = other_contactsStr
            (0, _clues.editClues)(formData).then(res => {
              if (res.code === 200) {
                this.hasChange = false;
              }
              this.saveLoading = false;
              this.$router.push({
                path: _routeMap.ROUTES.conversation
              });
            })
            // eslint-disable-next-line handle-callback-err
            .catch(err => {
              this.saveLoading = false;
            });
            resolve();
          } else {
            throw new Error('校验失败');
            // eslint-disable-next-line no-unreachable
            reject(false);
          }
        });
      });
    },
    saveDeal() {
      this.$refs['deal-form'].updateFullDeal();
    },
    saveLead() {
      this.$refs['lead-form'].submit();
    },
    handleSubmitSucceed() {
      this.$router.push({
        path: _routeMap.ROUTES.conversation
      });
    },
    handleBusiFormData() {
      const updateForm = JSON.parse(JSON.stringify(this.dealFormData));
      const decisionStr = JSON.stringify(updateForm.decision_maker);
      const participantaArray = updateForm.other_contacts;
      updateForm.decision_maker = decisionStr;
      const filterArray = participantaArray.filter(item => {
        const valArray = Object.values(item);
        const isHasVal = valArray.some(n => n);
        return isHasVal;
      });
      filterArray.forEach((item, index) => {
        filterArray[index] = JSON.stringify(item);
      });
      updateForm.other_contacts = filterArray;
      return updateForm;
    },
    handleCluesFormData() {
      const form = JSON.parse(JSON.stringify(this.leadFormData));
      const {
        id,
        name,
        tel,
        email
      } = form.contact;
      if (!name && !tel && !email && id === 0) {
        form.contact = {};
      }
      form.contact = JSON.stringify(form.contact);
      return form;
    }
  }
};
exports.default = _default;