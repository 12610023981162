"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LeaderPlanHeader = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/LeaderPlanHeader"));
var _LeaderPlanHeaderFilter = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/LeaderPlanHeaderFilter"));
var _ParentTaskTable = _interopRequireDefault(require("./components/ParentTaskTable.vue"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _SimpleTabs = _interopRequireDefault(require("../leaderPlan/SimpleTabs.vue"));
var _CourseAnalysisTable = _interopRequireDefault(require("../leaderPlan/CourseAnalysisTable.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _useRouter = require("@/hooks/use-router");
var _vue = require("vue");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const route = (0, _useRouter.useRoute)();
    (0, _vue.onMounted)(() => {
      getTaskInfo();
      getParentTaskDetail();
    });

    // 任务头部信息
    const taskInfo = (0, _vue.ref)({});
    async function getTaskInfo() {
      const res = await (0, _coachingCenter.getCoachingParentPlanDetail)({
        id: route.query.id - 0
      });
      taskInfo.value = res.results.data;
    }

    // 任务详情列表
    const requestParams = (0, _vue.ref)({
      id: route.query.id - 0,
      page: 1,
      size: 10,
      order_field: '',
      order_type: '',
      is_complete: -1,
      score: [],
      tree_ids: [],
      user_ids: []
    });
    const tableTotal = (0, _vue.ref)(0);
    const taskTableData = (0, _vue.ref)([]);
    const taskTableHead = (0, _vue.ref)([]);
    const taskTableLoading = (0, _vue.ref)(false);
    async function getParentTaskDetail() {
      taskTableLoading.value = true;
      const params = {
        ...requestParams.value
      };
      const res = await (0, _coachingCenter.getCoachParentTaskDetail)(params).finally(() => {
        taskTableLoading.value = false;
      });
      taskTableData.value = res.results.data;
      taskTableHead.value = res.results.head;
      tableTotal.value = res.results.total_count;
      if (hasErrorSummary.value) {
        getErrorSummaryList();
      }
    }
    const regetParentTaskDetail = val => {
      requestParams.value = Object.assign(requestParams.value, val);
      requestParams.value.page = 1;
      getParentTaskDetail();
    };
    const onSizeChange = () => {
      requestParams.value.page = 1;
      getParentTaskDetail();
    };
    const onTableSortChange = val => {
      const params = {
        order_field: val.prop,
        order_type: val.order
      };
      regetParentTaskDetail(params);
    };
    const handleAllUrgeSuccess = () => {
      taskInfo.value.has_urged = 1;
    };
    const overviewTabs = [{
      name: 'completion',
      label: (0, _i18n.t)('coaching.completionStatus')
    }, {
      name: 'errorSummary',
      label: (0, _i18n.t)('coaching.错误汇总')
    }];
    const activeOverviewTab = (0, _vue.ref)('completion');
    const hasErrorSummary = (0, _vue.ref)(false);
    // watch路由参数,获取showErrorSummary的值
    (0, _vue.watchEffect)(() => {
      hasErrorSummary.value = !!route.query.showErrorSummary;
    });
    const courseAnalysisTableData = (0, _vue.ref)([]);
    const courseAnalysisTableLoading = (0, _vue.ref)(false);
    async function getErrorSummaryList(order_field, order_type) {
      courseAnalysisTableLoading.value = true;
      const res = await (0, _coachingCenter.getCourseAnalysisList)({
        plan_id: Number(requestParams.value.id),
        order_field: order_field || '',
        order_type: order_type || ''
      }).finally(() => {
        courseAnalysisTableLoading.value = false;
      });
      if (res.code === 20000) {
        courseAnalysisTableData.value = res.results.data;
      }
    }
    return {
      __sfc: true,
      route,
      taskInfo,
      getTaskInfo,
      requestParams,
      tableTotal,
      taskTableData,
      taskTableHead,
      taskTableLoading,
      getParentTaskDetail,
      regetParentTaskDetail,
      onSizeChange,
      onTableSortChange,
      handleAllUrgeSuccess,
      overviewTabs,
      activeOverviewTab,
      hasErrorSummary,
      courseAnalysisTableData,
      courseAnalysisTableLoading,
      getErrorSummaryList,
      LeaderPlanHeader: _LeaderPlanHeader.default,
      LeaderPlanHeaderFilter: _LeaderPlanHeaderFilter.default,
      ParentTaskTable: _ParentTaskTable.default,
      MgPagination: _MgPagination.default,
      SimpleTabs: _SimpleTabs.default,
      CourseAnalysisTable: _CourseAnalysisTable.default,
      t: _i18n.t
    };
  }
};
exports.default = _default;