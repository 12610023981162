"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    topHeight: {
      type: [Number, String],
      default: 56
    }
  },
  data() {
    return {
      weekDay: ['一', '二', '三', '四', '五', '六', '日'],
      monthDays: [],
      month: ''
    };
  },
  computed: {
    days() {
      return this.$moment(this.month, 'YYYY-MM').daysInMonth();
    },
    firstWeekDay() {
      return this.$moment(`${this.month}-01`).isoWeekday();
    },
    startDate() {
      return this.monthDays[0].date;
    },
    endDate() {
      return this.monthDays[this.layoutLine * 7 - 1].date;
    },
    layoutLine() {
      if (this.days === 28 && this.firstWeekDay == 1) {
        return 4;
      } else if (this.days === 31 && (this.firstWeekDay === 6 || this.firstWeekDay === 7)) {
        return 6;
      } else {
        return 5;
      }
    },
    totalBox() {
      return this.layoutLine * 7;
    },
    bodyHeight() {
      return `calc( 100vh - 48px - ${this.topHeight}px - 40px - ${this.layoutLine}px )`;
    },
    contentHeight() {
      return `calc( ${this.bodyHeight} / ${this.layoutLine} - 44px )`;
    }
  },
  watch: {
    month(val) {
      this.setMonthDays();
      const obj = {
        month: val,
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.$emit('monthChange', obj);
      sessionStorage.setItem('monthlyMonth', val);
    }
  },
  created() {
    const month = sessionStorage.getItem('monthlyMonth');
    if (month) {
      this.setMonth(month);
    } else {
      this.setNow();
    }
  },
  methods: {
    setNow() {
      this.month = this.$moment().format('YYYY-MM');
    },
    switchMonth(num) {
      this.month = this.$moment(this.month).add(num, 'M').format('YYYY-MM');
    },
    setMonth(month) {
      this.month = month;
    },
    setMonthDays() {
      this.monthDays = [];
      const firstDay = `${this.month}-01`;
      for (let i = 1; i < this.firstWeekDay; i++) {
        const moment = this.$moment(firstDay).subtract(this.firstWeekDay - i, 'days');
        const date = moment.format('YYYY-MM-DD');
        const day = moment.date();
        this.monthDays.push({
          date,
          day,
          type: 'previous'
        });
        // this.monthDays[i-1].date = date;
        // this.monthDays[i-1].type = 'previous';
      }

      for (let i = 0; i < this.days; i++) {
        const moment = this.$moment(firstDay).add(i, 'days');
        const date = moment.format('YYYY-MM-DD');
        const day = moment.date();
        this.monthDays.push({
          date,
          day,
          type: 'current'
        });
        // this.monthDays[this.firstWeekDay-1+i].date = date;
        // this.monthDays[this.firstWeekDay-1+i].type = 'current';
      }

      const surplus = this.totalBox - this.days - this.firstWeekDay + 1;
      for (let i = 0; i < surplus; i++) {
        const moment = this.$moment(firstDay).add(this.days + i, 'days');
        const date = moment.format('YYYY-MM-DD');
        const day = moment.date();
        this.monthDays.push({
          date,
          day,
          type: 'next'
        });
        // this.monthDays[this.firstWeekDay + this.days -1].date = date;
        // this.monthDays[this.firstWeekDay + this.days -1].type = 'next';
      }
    }
  }
};

exports.default = _default;