"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _useRouter = require("@/hooks/use-router");
var _videoDetail = require("@/api/videoDetail");
var _default = {
  __name: 'SendBoxHeader',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['cancel', 'clearProposals', 'input'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const route = (0, _useRouter.useRoute)();
    const commentStatus = (0, _vue.computed)(() => store.getters.commentStatus);
    const nameOptions = (0, _vue.ref)([]);
    const remoteTeacherName = async filter => {
      const params = {
        conversation_id: route.query.id,
        filter
      };
      const res = await (0, _videoDetail.getTeacherName)(params);
      if (res.code === 20000) {
        nameOptions.value = res.results.users;
      }
    };
    const cancelAskHelp = () => {
      emit('input', '');
      emit('cancel');
    };
    const cancelTeach = () => {
      emit('clearProposals');
      emit('cancel');
    };
    return {
      __sfc: true,
      props,
      emit,
      store,
      route,
      commentStatus,
      nameOptions,
      remoteTeacherName,
      cancelAskHelp,
      cancelTeach
    };
  }
};
exports.default = _default;