"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
var _dashboard = require("@/api/dashboard");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const TYPE_MAP = {
  call_back: {
    icon: 'outgoingcall',
    text: '待联系'
  },
  see_later: {
    icon: 'time',
    text: '稍后再看'
  },
  coach: {
    icon: 'info-1',
    text: '待辅导'
  },
  advise: {
    icon: 'hand-2',
    text: '申请辅导'
  }
};
var _default = {
  name: 'TodoItem',
  components: {
    MoreOperations: _MoreOperations.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      operateList: [{
        command: 'delete',
        label: '删除'
      }],
      isDone: false,
      isDisabled: false,
      todoTime: '',
      isTimeout: false,
      originTodoTime: '',
      originIsTimeout: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    markIcon() {
      return TYPE_MAP[this.item.type].icon;
    },
    isInProgress() {
      return this.item.status === 'created';
    },
    showBusinessAndAccount() {
      return this.item.deal_name || this.item.account_name;
    },
    showDatePicker() {
      return this.item.type === 'call_back' && (this.isInProgress || this.item.todo_time);
    },
    todoTimeContent() {
      return this.item.todo_time ? this.item.todo_time : '设置待办时间';
    },
    isReadonly() {
      var _this$item, _this$item$conversati;
      return this.item.type === 'call_back' && ((_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$conversati = _this$item.conversation) === null || _this$item$conversati === void 0 ? void 0 : _this$item$conversati.conversation_user_id) !== this.userId;
    }
  },
  // eslint-disable-next-line eqeqeq
  inject: ['getConvTime'],
  created() {
    this.getStatus();
    this.getTodoTimeInfo();
  },
  methods: {
    handleIgnore(_ref) {
      let {
        id
      } = _ref;
      this.$emit('handleIgnore', id);
    },
    handleIsDoneChange(isDone) {
      if (isDone) {
        setTimeout(() => {
          this.$emit('handleRead', this.item.id);
        }, 400);
      }
    },
    handleCommand(command) {
      if (command === 'delete') {
        this.handleDelete(this.item);
      }
    },
    handleDelete(_ref2) {
      let {
        id
      } = _ref2;
      this.$confirm('确定删除此待办？', '删除', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        this.$emit('delete', id);
      });
    },
    async submitTodoTime(time) {
      try {
        const res = await (0, _dashboard.setTodoTime)({
          id: this.item.id,
          todo_time: time
        });
        if (res.code === 20000) {
          this.$message.success('设置截止时间成功');
          this.$emit('refresh');
        }
      } catch (error) {
        this.todoTime = this.originTodoTime;
        this.isTimeout = this.originIsTimeout;
      }
    },
    handleTodoTimeChange(time) {
      this.isTimeout = this.judgeIsTimeOut(time);
    },
    getConvIcon(_ref3) {
      let {
        conversation: {
          conversation_type: type
        }
      } = _ref3;
      const ICON_MAP = {
        video: 'video-camera',
        audio: 'phone-outline',
        doc: 'im'
      };
      return `iconfont icon-${ICON_MAP[type]}`;
    },
    getType(_ref4) {
      let {
        type
      } = _ref4;
      return type === 'call_back' ? this.item.todo_name : TYPE_MAP[type].text;
    },
    getStatus() {
      const {
        status
      } = this.item;
      if (!this.isInProgress) {
        this.isDisabled = true;
        if (status === 'done') {
          this.isDone = true;
        }
      }
    },
    getTodoTimeInfo() {
      const {
        todo_time,
        is_timeout
      } = this.item;
      this.todoTime = todo_time;
      this.isTimeout = is_timeout;
      this.originTodoTime = todo_time;
      this.originIsTimeout = is_timeout;
    },
    getHighlightContent(_ref5) {
      let {
        sentence,
        light_position
      } = _ref5;
      const keyWords = light_position.map(_ref6 => {
        let {
          key_word
        } = _ref6;
        return key_word;
      }).sort((a, b) => b.length - a.length);
      return sentence.replace(new RegExp(`(${keyWords.join('|')})`, 'g'), '<span class="keyword-highlight">$1</span>');
    },
    goConv(_ref7) {
      let {
        is_access,
        conversation
      } = _ref7;
      if (!is_access) {
        this.$message.warning('无访问权限，请联系管理员');
        return;
      }
      this.$router.push({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: conversation.conversation_id
        }
      });
    },
    goConvSentence(item, todoItem) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${item.conversation.conversation_id}&order=${todoItem.order}&noback=true`);
    },
    goBusinessDetail(item) {
      window.open(`${location.origin}${_routeMap.ROUTES.dealDetail}?id=${item.deal_id}&isBack=true`);
    },
    goAccountDetail(item) {
      window.open(`${location.origin}${_routeMap.ROUTES.account}?id=${item.account_id}`);
    },
    judgeIsTimeOut(time) {
      return new Date(time).getTime() < Date.now();
    }
  }
};
exports.default = _default;