"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.chooseRoleDialogVisible,
      "before-close": _vm.handleCloseDialog,
      width: "400px",
      "custom-class": "choose-role-dialog",
      title: "选择适用角色"
    }
  }, [_c("div", {
    staticClass: "choose-role-content"
  }, [_c("div", {
    staticClass: "check-all"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.handleCheckAllChange
    },
    model: {
      value: _vm.checkAll,
      callback: function ($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("全选")]), _c("div", [_vm._v("已选 " + _vm._s(_vm.checkedRoles.length))])], 1), _c("div", {
    staticClass: "checkbox-group-content"
  }, [_c("el-checkbox-group", {
    on: {
      change: _vm.handleCheckedRoleChange
    },
    model: {
      value: _vm.checkedRoles,
      callback: function ($$v) {
        _vm.checkedRoles = $$v;
      },
      expression: "checkedRoles"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleCloseDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;