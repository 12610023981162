"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$data;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-list"
  }, [(_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.length ? _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: item.loading,
        expression: "item.loading"
      }],
      key: index,
      staticClass: "model-item",
      class: {
        "is-new": item.isNew
      }
    }, [_c(_setup.ModelItemHeader, {
      attrs: {
        "model-info": item
      },
      on: {
        buttonClick: function ($event) {
          return _setup.onButtonClick($event, item);
        }
      }
    }), item.last_version ? _c(_setup.ModelItemTable, {
      attrs: {
        data: [item.last_version],
        "model-type": _setup.modelTypeMap[item.model_type],
        "model-id": item.id
      },
      on: {
        refreshData: function ($event) {
          return _setup.onButtonClick("refreshItem", item);
        },
        retryTrain: function ($event) {
          return _setup.onButtonClick("retryTrainItem", item);
        },
        reTraining: function ($event) {
          return _setup.onButtonClick("reTraining", item);
        }
      }
    }) : _vm._e()], 1);
  }) : _c("div", {
    staticClass: "empty-text"
  }, [_vm._v("暂无数据")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;