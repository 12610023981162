"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardText = _interopRequireDefault(require("@/components/Charts/CardText"));
var _ReportDataChart = _interopRequireDefault(require("./layout/overViewElements/ReportDataChart"));
var _ConstMap = require("@/components/Message/components/ConstMap.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    CardText: _CardText.default,
    ReportDataChart: _ReportDataChart.default
  },
  props: {
    hasPrev: {
      type: Boolean,
      default: false
    },
    hasNext: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    detailInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    getDigitsData() {
      const list = this.detailInfo.data_views;
      const typeList = list.filter(item => item.type === 'Digit');
      return typeList;
    },
    getOtherData() {
      const list = this.detailInfo.data_views;
      const typeList = list.filter(item => item.type !== 'Digit');
      const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
      typeList.forEach(view => {
        view.data = view.data.map(_item => {
          return _item.map(val => {
            if (typeof val === 'string' && timeReg.test(val)) {
              return this.$moment(val).format(this.type === 1 ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
            }
            return val;
          });
        });
      });
      return typeList;
    },
    workspaceIconClass() {
      return `space-icon iconfont icon-${_ConstMap.ICONMAP['workspace']}`;
    }
  },
  methods: {
    goBack() {
      this.$emit('goBackList');
    },
    getDetailInfo(next) {
      this.$emit('getDetailInfo', next);
    }
  }
};
exports.default = _default;