"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _index = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentGenerationPromptForm',
  props: {
    labelTitle: {
      type: String,
      default: ''
    },
    valueTitle: {
      type: String,
      default: ''
    },
    desc: {
      type: Array,
      default: () => []
    },
    keyProps: {
      type: Object,
      default: () => ({
        maxlength: 10,
        placeholder: '请输入标题',
        inputType: 'text'
      })
    },
    valueProps: {
      type: Object,
      default: () => ({
        maxlength: 150,
        placeholder: '请输入描述内容'
      })
    },
    dragDisabled: {
      type: Boolean,
      default: false
    },
    addDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => ({
        key: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }],
        value: [{
          required: true,
          message: '描述内容不能为空',
          trigger: 'blur'
        }]
      })
    },
    atLeastOneItem: {
      type: Boolean,
      default: false
    },
    fieldLabel: {
      type: String,
      default: '字段'
    }
  },
  emits: 'add-field',
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const isShowRemoveButton = (0, _vue.computed)(() => {
      return (item, index) => {
        // 如果不要求至少有一项，则根据isPreset来判断;如果要求至少有一项，则只剩一项时不显示删除按钮
        return !props.atLeastOneItem && !item.isPreset || props.atLeastOneItem && props.desc.length > 1;
      };
    });
    const handleDragEnd = val => {
      emit('update:desc', val);
    };
    const addField = () => {
      emit('add-field');
    };
    const removeField = id => {
      emit('remove-field', id);
    };
    const formRef = (0, _vue.ref)([]);
    const validate = async () => {
      const res = await Promise.all(formRef.value.map(item => item.validate()));
      return res.every(item => item);
    };
    expose({
      validate
    });
    return {
      __sfc: true,
      props,
      emit,
      isShowRemoveButton,
      handleDragEnd,
      addField,
      removeField,
      formRef,
      validate,
      MgDraggable: _MgDraggable.default,
      MgvButton: _index.default
    };
  }
};
exports.default = _default;