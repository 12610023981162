"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "summary-template"
  }, [_c("header", {
    staticClass: "summary-template-header"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_vm._v("智能纪要")]), _c(_setup.SpaceButton), _c("div", {
    staticClass: "header-tip"
  }, [_vm._v("指令只对新增会话生效")])], 1), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      disabled: _setup.tableData.length >= 200,
      "tooltip-content": _setup.tableData.length >= 200 ? "指令数量上限200" : ""
    },
    on: {
      click: _setup.onCreateSummaryTemplate
    }
  }, [_vm._v("添加指令")])], 1), _c(_setup.TemplateTable, {
    staticClass: "summary-template-table",
    attrs: {
      data: _setup.tableData,
      "table-loading": _setup.tableLoading
    },
    on: {
      editTemplate: _setup.editTemplate,
      deleteTemplate: _setup.deleteTemplate,
      rowDragSort: _setup.rowDragSort
    }
  }), _c(_setup.CreateSummaryTemplateDrawer, {
    attrs: {
      visible: _setup.summaryTempalteDrawerVisible,
      "back-info": _setup.activeTemplate,
      type: _setup.submitType
    },
    on: {
      "update:visible": function ($event) {
        _setup.summaryTempalteDrawerVisible = $event;
      },
      submitSucceed: _setup.getSummaryTemplate
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;