"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SalesTaskTable',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableHeight: document.documentElement.clientHeight - 347,
      headerList: [{
        prop: 'name',
        label: (0, _i18n.t)('coaching.taskName'),
        fixed: 'left',
        width: 240,
        showOverflowTip: true
      }, {
        prop: 'course_type',
        label: (0, _i18n.t)('coaching.courseType'),
        width: 200
      }, {
        prop: 'end_time',
        label: (0, _i18n.t)('coaching.deadline'),
        sortable: 'custom'
      }, {
        prop: 'creator_info',
        label: (0, _i18n.t)('coaching.assigner'),
        width: 140
      }, {
        prop: 'start_time',
        label: (0, _i18n.t)('coaching.startTime'),
        sortable: 'custom'
      }, {
        prop: 'status',
        label: (0, _i18n.t)('coaching.status'),
        width: 140
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'userId', 'avatar'])
  },
  methods: {
    isCombinedTask(row) {
      var _row$children;
      return ((_row$children = row.children) === null || _row$children === void 0 ? void 0 : _row$children.length) > 1;
    },
    courseType(row) {
      const types = ['-', this.$t('coaching.scriptReading'), this.$t('coaching.scriptRecitation'), this.$t('coaching.scriptPractice'), this.$t('coaching.aICustomerHasVar', ['(Alpha)']), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer')];
      return types[row.course_type];
    },
    goPractice(row) {
      this.$emit('showTaskDetail', (row.children || [row])[0]);
    },
    plus8ToCurrentZone(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    handleSortChange(val) {
      this.$emit('tableSortChange', val);
    },
    goSalesPlanDetail(row) {
      let path;
      const currentData = (row.children || [row])[0];
      switch (Number(currentData.course_type)) {
        case 3:
          path = _routeMap.ROUTES.couseTaskDetailSmartTraining;
          break;
        case 4:
        case 5:
        case 6:
        case 7:
          path = _routeMap.ROUTES.aiCoachTaskDetail;
          break;
        default:
          path = _routeMap.ROUTES.coachingCenterLeaderDetail;
          break;
      }
      const taskId = currentData.id;
      const query = {
        taskId,
        type: currentData.course_type,
        score: currentData.pass_score
      };
      if ([1, 2, 3].includes(Number(currentData.course_type))) {
        query.userInfo = JSON.stringify({
          id: this.userId,
          name: this.name,
          avatar: this.avatar
        });
      }
      sessionStorage.setItem('task_practice_query', JSON.stringify(query));
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          taskId,
          from: 'coach-todo'
        }
      });
    },
    getStatus(row) {
      const map = {
        0: this.$t('coaching.inProgress'),
        1: this.$t('coaching.deadlinePassed'),
        2: this.$t('coaching.notStarted'),
        3: this.$t('coaching.completed')
      };
      return map[row.status];
    },
    getStatusClass(row) {
      const map = {
        0: 'status under-way',
        1: 'status has-as',
        2: 'status have-not-started',
        3: 'status have-all-done'
      };
      return map[row.status];
    }
  }
};
exports.default = _default;