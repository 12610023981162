"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeItemAnswers = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeItemAnswers.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeAside',
  components: {
    KnowledgeItemAnswers: _KnowledgeItemAnswers.default
  },
  props: {
    knowledge: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
exports.default = _default;