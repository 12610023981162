"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-sentence__type"
  }, [_c(_setup.HorizontalScroll, [_c("el-radio-group", {
    attrs: {
      value: _vm.exampleType
    },
    on: {
      input: _setup.handleChange
    }
  }, _vm._l(_setup.modelSentenceTypeList, function (item) {
    return _c("div", {
      key: item.value,
      class: ["model-sentence__type-item", {
        active: _vm.exampleType === item.value
      }],
      on: {
        click: function ($event) {
          return _setup.handleChange(item.value);
        }
      }
    }, [_c("div", {
      staticClass: "model-sentence__type-item-bg",
      style: item.imageUrl
    }), _c("div", {
      staticClass: "model-sentence__type-item-content"
    }, [_c("el-radio", {
      attrs: {
        label: item.value
      }
    }, [_vm._v(_vm._s(item.label))])], 1)]);
  }), 0)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;