"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-item"
  }, [_vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "content-top-part"
    }, [_vm._t("content", null, {
      item: item,
      index: index
    }), _vm.hasPopConfirm ? _c("el-popconfirm", {
      attrs: {
        "popper-class": "delete-popover",
        "confirm-button-text": _vm.$t("management.删除"),
        "cancel-button-text": _vm.$t("management.取消"),
        icon: "detele-icon el-icon-warning",
        title: _vm.$t("management.确认删除") + "？"
      },
      on: {
        onConfirm: function ($event) {
          return _vm.deleteItem(index);
        }
      }
    }, [_c("el-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.checkEdit ? index && item.editable : index,
        expression: "checkEdit ? index && item.editable : index"
      }],
      staticClass: "delete-btn",
      attrs: {
        slot: "reference",
        size: "mini"
      },
      slot: "reference"
    }, [_c("i", {
      staticClass: "el-icon-close"
    })])], 1) : index ? _c("el-button", {
      staticClass: "delete-btn",
      attrs: {
        slot: "reference",
        size: "mini"
      },
      on: {
        click: function ($event) {
          return _vm.deleteItem(index);
        }
      },
      slot: "reference"
    }, [_c("i", {
      staticClass: "el-icon-close"
    })]) : _vm._e()], 2);
  }), _c("el-button", {
    staticClass: "add-button",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.添加")) + _vm._s(_vm.title) + " ")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;