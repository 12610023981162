"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("article", {
    staticClass: "ranking-board"
  }, [_c("h1", {
    staticClass: "ranking-title"
  }, [_vm._v("排行榜")]), _c("section", {
    staticClass: "main-part"
  }, [_c("section", {
    staticClass: "file-part"
  }, [_c("ranking-files-list", {
    attrs: {
      list: _vm.snippetsMonthHots,
      "ranking-info": _vm.snippetsMonthHotsInfo
    }
  }), _c("ranking-files-list", {
    attrs: {
      list: _vm.snippetsMonthNewest,
      "ranking-info": _vm.snippetsMonthNewestInfo
    }
  }), _c("ranking-files-list", {
    attrs: {
      list: _vm.folderMonthHots,
      "ranking-info": _vm.folderMonthHotsInfo
    }
  }), _c("ranking-files-list", {
    attrs: {
      list: _vm.folderMonthNewest,
      "ranking-info": _vm.folderMonthNewestInfo
    }
  })], 1), _c("aside", {
    staticClass: "personal-part"
  }, [_c("ranking-persons", {
    attrs: {
      list: _vm.rankingPersonsList
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;