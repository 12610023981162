"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "knowledge-base-dropdown",
    attrs: {
      trigger: "click",
      placement: _vm.placement
    },
    on: {
      command: command => _vm.handleCommand(command),
      "visible-change": _vm.handleDropdownVisibleChange
    }
  }, [_c("div", {
    class: ["dropdown-link", {
      "dropdown-visible-link": _vm.visible
    }]
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.currentKnowledgeBase.name
    }
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.currentKnowledgeBase.name))])]), _c("i", {
    class: ["iconfont", _vm.icon]
  })], 1), _c("el-dropdown-menu", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "operate-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.knowledgeBaseList.length ? _vm._l(_vm.knowledgeBaseList, function (_ref) {
    let {
      name,
      id
    } = _ref;
    return _c("el-dropdown-item", {
      key: id,
      attrs: {
        command: id
      }
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v(_vm._s(name))]), _vm.currentKnowledgeBase.id === id ? _c("i", {
      staticClass: "iconfont icon-check"
    }) : _vm._e()]);
  }) : [_c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无知识库")])]], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;