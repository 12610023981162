"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select-custom-tag", {
    staticClass: "filter-input",
    attrs: {
      filterable: "",
      remote: "",
      multiple: "",
      "remote-method": _vm.getOptions,
      loading: _vm.loading,
      "popper-class": "conver-filter",
      "value-key": "id",
      placeholder: _vm.placeholder
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item
      }
    }, [_vm._t("optionIcon"), _c("span", [_vm._v(_vm._s(item.label))])], 2);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;