"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertParams2UTCplus8 = convertParams2UTCplus8;
exports.convertUTCplus82CurrentZone = convertUTCplus82CurrentZone;
exports.currentZoneToPlus8 = currentZoneToPlus8;
exports.datePickerConvertToZone = datePickerConvertToZone;
exports.datePickerParseToZone = datePickerParseToZone;
exports.datePickerWithTimeConvert2Zone = datePickerWithTimeConvert2Zone;
exports.datePickerWithTimeParse2Zone = datePickerWithTimeParse2Zone;
exports.plus8ToCurrentZone = plus8ToCurrentZone;
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 东八区转指定时区
function plus8ToCurrentZone(val) {
  const zone = _store.default.getters.timezone;
  const m = (0, _momentTimezone.default)(val).add(-8, 'hour').format('YYYY-MM-DD HH:mm:ss');
  const utc = _momentTimezone.default.utc(m);
  return (0, _momentTimezone.default)(utc).tz(zone);
}

// 指定时区转东八区
function currentZoneToPlus8(val) {
  const zone = _store.default.getters.timezone;
  return (0, _momentTimezone.default)(val).tz(zone, true).utcOffset(8);
}

// 日期选择器指定时区转东八区
function datePickerParseToZone(val) {
  const timeSecondReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  const timeMinuteReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  let start, end;
  if (val.every(item => timeSecondReg.test(item))) {
    start = val[0];
    end = val[1];
  }
  if (val.every(item => timeMinuteReg.test(item))) {
    start = val[0];
    end = val[1] + ':59';
  } else {
    const dates = val.map(item => (0, _momentTimezone.default)(item).format('YYYY-MM-DD'));
    start = dates[0] + ' 00:00:00';
    end = dates[1] + ' 23:59:59';
  }
  return [currentZoneToPlus8(start).format('YYYY-MM-DD HH:mm:ss'), currentZoneToPlus8(end).format('YYYY-MM-DD HH:mm:ss')];
}
// 处理前端传入的数据，将当前时区转换为东八区
/*
 * @params {Object} params 传入的参数
 * @params {Boolean} noSecond 是否显示秒
 * @params {Boolean} internationalizeDate 是否对日期格式化加时分秒
 */
function datePickerWithTimeParse2Zone(params, noSecond) {
  let internationalizeDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const dateReg = /^\d{4}-\d{2}-\d{2}$/;
  const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
  for (const key in params) {
    if (typeof params[key] === 'string' && (dateReg.test(params[key]) || timeReg.test(params[key]))) {
      if (dateReg.test(params[key]) && !internationalizeDate) {
        params[key] = currentZoneToPlus8(params[key]).format('YYYY-MM-DD');
      } else {
        params[key] = currentZoneToPlus8(params[key]).format(noSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss');
      }
    } else if (Array.isArray(params[key]) && params[key].length && params[key].every(d => dateReg.test(d))) {
      params[key] = params[key].map(t => currentZoneToPlus8(t).format(noSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss'));
    }
  }
}
// 处理后端返回的数据，将东八区转换为当前时区
function datePickerWithTimeConvert2Zone(params, noSecond) {
  const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
  for (const key in params) {
    if (typeof params[key] === 'string' && timeReg.test(params[key])) {
      params[key] = plus8ToCurrentZone(params[key]).format(noSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss');
    } else if (Array.isArray(params[key]) && params[key].length && params[key].every(d => timeReg.test(d))) {
      params[key] = params[key].map(t => plus8ToCurrentZone(t).format(noSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss'));
    }
  }
}
// 日期选择器东八区转指定时区
function datePickerConvertToZone(val, isTime) {
  const start = val[0];
  const end = val[1];
  return [plus8ToCurrentZone(start).format(isTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'), plus8ToCurrentZone(end).format(isTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')];
}
function isJSON(str) {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
}
// 自动查找参数中的日期，将当前时区转换为东八区
function convertParams2UTCplus8(params) {
  const dateReg = /\d{4}-\d{2}-\d{2}/;
  for (const key in params) {
    if (typeof params[key] === 'string' && isJSON(params[key]) && Array.isArray(JSON.parse(params[key])) && JSON.parse(params[key]).length && JSON.parse(params[key]).every(d => dateReg.test(d))) {
      params[key] = JSON.stringify(datePickerParseToZone(JSON.parse(params[key])));
    } else if (Array.isArray(params[key]) && params[key].length && params[key].every(d => dateReg.test(d))) {
      params[key] = datePickerParseToZone(params[key]);
    }
  }
  return params; // 函数写返回值是个好习惯！
}

function convertUTCplus82CurrentZone(params) {
  const dateReg = /\d{4}-\d{2}-\d{2}/;
  for (const key in params) {
    if (typeof params[key] === 'string' && isJSON(params[key]) && Array.isArray(JSON.parse(params[key])) && JSON.parse(params[key]).length && JSON.parse(params[key]).every(d => dateReg.test(d))) {
      params[key] = JSON.stringify(datePickerConvertToZone(JSON.parse(params[key])));
    } else if (Array.isArray(params[key]) && params[key].length && params[key].every(d => dateReg.test(d))) {
      params[key] = datePickerConvertToZone(params[key]);
    }
  }
}
/** 为什么要写这么多同样功能的方法啊，方法名还都这么像，太混乱了 */