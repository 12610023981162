"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-library-conv-card"
  }, [_c("div", {
    staticClass: "question-part"
  }, [_c("div", {
    staticClass: "questions-wrap"
  }, _vm._l(_vm.questionList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "question-item"
    }, [_vm._m(0, true), _c("div", {
      staticClass: "content"
    }, [_c("span", {
      staticClass: "asker"
    }, [_vm._v(_vm._s(_vm.getSpeakerType(item.entity_type)) + "：")]), _c("span", {
      staticClass: "words",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(item.order);
        }
      }
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0)]), _c("div", {
    staticClass: "answer-part"
  }, _vm._l(_vm.answerList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "answer-item"
    }, [_vm._m(1, true), _c("div", {
      staticClass: "content"
    }, [_c("span", {
      staticClass: "speaker-label"
    }, [_vm._v(_vm._s(_vm.getSpeakerType(item.entity_type)))]), _c("span", {
      staticClass: "words",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(item.order);
        }
      }
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0), _c("div", {
    staticClass: "other-info"
  }, [_c("span", {
    staticClass: "sales-man"
  }, [_vm._v("销售：" + _vm._s(_vm.convItem.sale_name))]), _c("span", {
    staticClass: "dot"
  }, [_vm._v("·")]), _c("span", {
    staticClass: "date"
  }, [_vm._v("时间：" + _vm._s(_vm.getConvTime(_vm.convItem.conversation_time)))])]), _c("div", {
    staticClass: "bottom"
  }, [_vm.showLikeInfo ? _c("like-info", {
    attrs: {
      info: _vm.convItem,
      type: "qa"
    }
  }) : _vm._e(), _vm.convItem.knowledge_names && _vm.convItem.knowledge_names.length ? _c("div", {
    staticClass: "history-added"
  }, [_c("div", {
    staticClass: "first-history"
  }, [_vm._v(" 已添加到【" + _vm._s(_vm.convItem.knowledge_names[0]) + "】 ")]), _vm.convItem.knowledge_names.length > 1 ? _c("el-popover", {
    attrs: {
      "popper-class": "knowledge-point-mining-engine-popover",
      placement: "bottom",
      "visible-arrow": false,
      width: "200",
      trigger: "hover"
    }
  }, [_c("ul", _vm._l(_vm.popoverKnowledgeNames, function (item, index) {
    return _c("li", {
      key: index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _c("span", {
    staticClass: "count-button",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("+" + _vm._s(_vm.convItem.knowledge_names.length - 1))])]) : _vm._e()], 1) : _vm._e(), _c("el-button", {
    class: {
      added: _vm.isAdded
    },
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("addConv");
      }
    }
  }, [_vm._v(_vm._s(_vm.isAdded ? "再添加" : "添加"))])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-type"
  }, [_c("i", {
    staticClass: "iconfont icon-ask"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-type"
  }, [_c("i", {
    staticClass: "iconfont icon-answer"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;