"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "space-label"
  }, [_c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.spaceInfo.icon_color
    }
  }), _c("span", {
    staticClass: "space-name"
  }, [_vm._v(_vm._s(_vm.spaceInfo.name))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;