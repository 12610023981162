"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _useVuex = require("@/hooks/use-vuex.js");
var _KeyValueFieldItems = _interopRequireDefault(require("./KeyValueFieldItems.vue"));
var _conversationList = require("@/api/conversationList");
var _vue = require("vue");
var _elementUi = require("element-ui");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateInstructionDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'create'
    },
    backInfo: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:visible', 'submitSucceed'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const updateVisible = () => emit('update:visible', false);
    const title = (0, _vue.computed)(() => {
      return props.type === 'create' ? '添加指令' : '编辑指令';
    });
    // 拿到空间信息
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const initForm = {
      name: '',
      content: [{
        field_name: '',
        field_content: ''
      }],
      tree_ids: []
    };
    const instructionForm = (0, _vue.ref)({
      ...initForm
    });
    const formContent = (0, _vue.ref)([{
      id: (0, _nanoid.nanoid)(),
      key: '',
      value: ''
    }]);
    (0, _vue.watchEffect)(() => {
      formContent.value = handleContent(instructionForm.value.content);
    });
    // 监听抽屉打开时，清空数据并清空校验
    (0, _vue.watch)(() => props.visible, val => {
      if (val && props.type === 'create') {
        instructionForm.value = {
          ...initForm
        };
        (0, _vue.nextTick)(() => {
          mgvDepartmentMultiSelectRef.value && mgvDepartmentMultiSelectRef.value.clear();
          formRef.value.clearValidate();
        });
      }
    }, {
      immediate: true
    });

    // 监听backInfo,将backInfo的值赋值给instructionForm
    (0, _vue.watch)(() => props.backInfo, val => {
      if (val) {
        Object.assign(instructionForm.value, val);
      }
    }, {
      deep: true
    });
    const validateTreeIds = (rule, value, callback) => {
      console.log('validateTreeIds', value, instructionForm.value.tree_ids);
      if (!value.length) {
        callback(new Error('请选择生效范围'));
      } else {
        callback();
      }
    };
    const rules = {
      name: [{
        required: true,
        message: '请填写指令名称',
        trigger: 'change'
      }],
      tree_ids: [{
        required: true,
        validator: validateTreeIds
      }]
    };
    function handleContent(contentArray) {
      return contentArray.map(item => {
        return {
          ...item,
          id: (0, _nanoid.nanoid)()
        };
      });
    }
    const addField = desc => {
      desc.push({
        id: (0, _nanoid.nanoid)(),
        key: '',
        value: ''
      });
    };
    const removeField = (id, desc) => {
      const index = desc.findIndex(item => item.id === id);
      desc.splice(index, 1);
    };
    const mgvDepartmentMultiSelectRef = (0, _vue.ref)(null);
    const handleDepartmentValueChange = val => {
      instructionForm.value.tree_ids = val;
      formRef.value.validateField('tree_ids');
    };
    const keyValueFieldItemsRef = (0, _vue.ref)(null);
    const loading = (0, _vue.ref)(false);
    const formRef = (0, _vue.ref)(null);
    const saveExtractInfoInstruction = async () => {
      formRef.value.validate(async valid => {
        const contentValidate = await keyValueFieldItemsRef.value.validate();
        if (valid && contentValidate) {
          loading.value = true;
          const params = {
            space_id: workspaceInfo.value.id,
            ...instructionForm.value,
            content: formContent.value.map(item => ({
              key: item.key,
              value: item.value,
              enum: item.enum
            }))
          };
          if (props.type === 'edit') {
            params.id = props.backInfo.id;
          }
          const apiRequst = props.type === 'create' ? _conversationList.createExtractInfoInstruction : _conversationList.updateExtractInfoInstruction;
          const res = await apiRequst(params).finally(() => loading.value = false);
          if (res.code === 20000) {
            _elementUi.Message.success(props.type === 'create' ? '添加成功' : '保存成功');
            emit('submitSucceed');
            updateVisible();
          }
        }
      });
    };
    return {
      __sfc: true,
      store,
      props,
      emit,
      updateVisible,
      title,
      workspaceInfo,
      initForm,
      instructionForm,
      formContent,
      validateTreeIds,
      rules,
      handleContent,
      addField,
      removeField,
      mgvDepartmentMultiSelectRef,
      handleDepartmentValueChange,
      keyValueFieldItemsRef,
      loading,
      formRef,
      saveExtractInfoInstruction,
      getOrgTree: _organization.getOrgTree,
      KeyValueFieldItems: _KeyValueFieldItems.default
    };
  }
};
exports.default = _default;