"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "field-table-wrapper"
  }, [_vm._m(0), _c("draggable", _vm._b({
    staticClass: "field-table-scroll",
    on: {
      start: function ($event) {
        _vm.dragging = true;
      },
      end: _vm.dragEnd
    },
    model: {
      value: _vm.localCustomFieldList,
      callback: function ($$v) {
        _vm.localCustomFieldList = $$v;
      },
      expression: "localCustomFieldList"
    }
  }, "draggable", _vm.draggableOptions, false), [_c("transition-group", {
    staticClass: "field-table table-body",
    class: {
      dragging: _vm.dragging
    },
    attrs: {
      tag: "table"
    }
  }, _vm._l(_vm.localCustomFieldList, function (item) {
    return _c("tr", {
      key: item.id
    }, [_c("td", {
      staticClass: "col-move"
    }, [_c("i", {
      staticClass: "iconfont icon-drag-dot"
    })]), _c("td", {
      staticClass: "col-name"
    }, [_vm._v(_vm._s(item.name))]), _c("td", {
      staticClass: "col-type"
    }, [_vm._v(_vm._s(_vm.fieldType[item.from_type]))]), _c("td", {
      staticClass: "col-creator"
    }, [_vm.getCustomFieldCreator(item).user_id ? [_c("mgv-avatar-name", {
      attrs: {
        src: _vm.getCustomFieldCreator(item).avatar,
        name: _vm.getCustomFieldCreator(item).name,
        size: 28
      }
    }), _c("span", {
      staticClass: "creator-name"
    }, [_vm._v(_vm._s(_vm.getCustomFieldCreator(item).name))])] : [_c("span", {
      staticClass: "creator-name"
    }, [_vm._v("系统")])]], 2), _c("td", {
      staticClass: "col-status"
    }, [_c("span", {
      staticClass: "col-status-show",
      on: {
        click: function ($event) {
          return _vm.updateField(item);
        }
      }
    }, [!item.status ? _c("i", {
      staticClass: "iconfont icon-view-close-2"
    }) : _c("i", {
      class: ["iconfont", "icon-view", {
        "show-disabled": !item.changeable
      }]
    })])]), _c("td", {
      staticClass: "col-operation"
    }, [item.has_edit ? _c("el-link", {
      attrs: {
        type: "primary",
        underline: false
      },
      on: {
        click: function ($event) {
          return _vm.$emit("editField", item);
        }
      }
    }, [_vm._v("编辑")]) : _vm._e(), _c("el-popover", {
      ref: "delete-popover" + item.id,
      refInFor: true,
      attrs: {
        placement: "bottom",
        trigger: "click",
        offset: -58
      }
    }, [_c("div", {
      staticClass: "delete-popover"
    }, [_c("p", [_vm._v("是否确认删除?")]), _c("div", [_c("el-button", {
      staticClass: "el-button-text--primary",
      attrs: {
        size: "mini"
      },
      on: {
        click: function ($event) {
          return _vm.closePopover(item.id);
        }
      }
    }, [_vm._v("取消")]), _c("el-button", {
      attrs: {
        size: "mini",
        type: "danger"
      },
      on: {
        click: function ($event) {
          return _vm.deleteCustomField(item.id);
        }
      }
    }, [_vm._v("删除")])], 1)]), item.has_delete ? _c("el-link", {
      staticClass: "delete",
      attrs: {
        slot: "reference",
        type: "danger",
        underline: false
      },
      slot: "reference"
    }, [_vm._v("删除")]) : _vm._e()], 1)], 1)]);
  }), 0)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    staticClass: "field-table table-header"
  }, [_c("tr", [_c("th", {
    staticClass: "col-move"
  }, [_vm._v("排序")]), _c("th", {
    staticClass: "col-name"
  }, [_vm._v("字段名称")]), _c("th", {
    staticClass: "col-type"
  }, [_vm._v("字段类型")]), _c("th", {
    staticClass: "col-creator"
  }, [_vm._v("创建人")]), _c("th", {
    staticClass: "col-status"
  }, [_vm._v("是否显示")]), _c("th", {
    staticClass: "col-operation"
  }, [_vm._v("操作")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;