"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dialogMixins = _interopRequireDefault(require("./dialogMixins.js"));
var _SetPublicScope = _interopRequireDefault(require("@/views/coaching/coach/course/components/SetPublicScope.vue"));
var _library = require("@/api/library");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SetAccessDialog',
  components: {
    SetPublicScope: _SetPublicScope.default
  },
  mixins: [_dialogMixins.default],
  data() {
    return {
      isInSetAccessDialog: true,
      submitLoading: false,
      isPublic: 1,
      backfillList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.getFolderPermission();
      }
    }
  },
  methods: {
    async getFolderPermission() {
      this.backfillList = [];
      const res = await (0, _library.getParentFolderPermission)({
        folder_id: 0,
        fid: this.currentNode.id
      });
      if (res.code === 200) {
        const {
          is_public,
          selection_list
        } = res.results;
        this.isPublic = is_public;
        if (!is_public) {
          this.backfillList = selection_list.map(item => {
            return {
              ...item,
              isDepartment: item.is_department,
              preNode: item.pre_node
            };
          });
        }
      }
    },
    closeDialog() {
      this.isPublic = 1;
      this.$emit('closeDialog');
    },
    async handleSubmit() {
      const {
        isPublic,
        assignee
      } = this.$refs['SetPublicScopeRef'];
      const params = {
        folder_id: this.currentNode.id,
        is_public: isPublic,
        space_id: this.workspaceInfo.id
      };
      if (!isPublic) {
        Object.assign(params, assignee);
      }
      this.submitLoading = true;
      const res = await (0, _library.editFolderAccess)(params).finally(() => {
        this.submitLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success('编辑成功');
        this.closeDialog();
      }
    }
  }
};
exports.default = _default;