"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    staticClass: "member-table",
    attrs: {
      data: _vm.tableData,
      height: "200"
    }
  }, _vm._l(_setup.tableHeads, function (_ref) {
    let {
      prop,
      label,
      width
    } = _ref;
    return _c("el-table-column", {
      key: prop,
      attrs: {
        prop: prop,
        label: label,
        width: width,
        fit: ""
      },
      scopedSlots: _vm._u([prop === "name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "member-info"
          }, [_c("mgv-avatar-name", {
            attrs: {
              size: 28,
              src: row.avatar,
              name: row.name
            }
          }), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.name
            }
          }, [_c("div", {
            staticClass: "member-name"
          }, [_vm._v(" " + _vm._s(row.name) + " ")])])], 1)];
        }
      } : prop === "allocate_percent" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_vm._v(" " + _vm._s(_setup.getPercent(row.allocate_percent)) + " ")];
        }
      } : prop === "real_percent" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_vm._v(" " + _vm._s(_setup.getPercent(row.real_percent)) + " ")];
        }
      } : null], null, true)
    }, [prop === "member_count" ? _c("template", {
      slot: "header"
    }, [_c("span", [_vm._v("质检成员数量 (" + _vm._s(_vm.totalMember) + ")")])]) : _vm._e()], 2);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;