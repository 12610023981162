"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModifyConvInfoHistoryValue = _interopRequireDefault(require("./ModifyConvInfoHistoryValue.vue"));
var _modifyConvInfoHistory = require("@/views/convDetail/hooks/modifyConvInfoHistory");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModifyConvInfoHistoryDrawer',
  props: {
    drawerVisible: Boolean,
    convId: Number
  },
  emits: ['update:drawerVisible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const closeDrawer = () => {
      emit('update:drawerVisible');
    };
    const {
      tableLoading,
      tableData,
      getModifyConvInfoHistoryApi
    } = (0, _modifyConvInfoHistory.useGetModifyConvInfoHistory)(props);
    return {
      __sfc: true,
      props,
      emit,
      closeDrawer,
      tableLoading,
      tableData,
      getModifyConvInfoHistoryApi,
      ModifyConvInfoHistoryValue: _ModifyConvInfoHistoryValue.default
    };
  }
};
exports.default = _default;