"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "effect-test-sentence-item"
  }, [_vm.type === "narrator" ? _c("div", {
    staticClass: "narration"
  }, [_c("pre", [_vm._v(_vm._s(_vm.sentence))])]) : _vm._e(), _vm.type !== "narrator" ? _c("div", {
    class: ["sentence-box", {
      "sale-sentence-box": _vm.type === "sales"
    }]
  }, [_vm.type === "customer" ? _c("mgv-avatar-name", {
    attrs: {
      src: _vm.avatarUrl || require("@/assets/coach/mai-mai-avatar.png")
    }
  }) : _vm._e(), _c("div", {
    class: ["sentence", {
      sale: _vm.type === "sales"
    }]
  }, [_c("p", [_vm._v(" " + _vm._s(_vm.sentence) + " ")])]), _vm.type === "sales" ? _c("mgv-avatar-name", {
    attrs: {
      src: _setup.store.getters.avatar,
      name: _setup.store.getters.name
    }
  }) : _vm._e()], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;