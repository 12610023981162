"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _default = {
  name: 'SpaceEventSelect',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultProps: {
        expandTrigger: 'hover',
        checkStrictly: false,
        children: 'children',
        label: 'name',
        value: 'id',
        multiple: true
      },
      keyEvents: [],
      localValue: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id'() {
      this.getKeyEvents();
    },
    value: {
      handler: function (val) {
        this.localValue = val;
      },
      immediate: true
    }
  },
  created() {
    this.getKeyEvents();
  },
  methods: {
    changeEvent(val) {
      this.$emit('change', {
        [this.item.field_name]: val
      });
    },
    async getKeyEvents() {
      const res = await (0, _knowledgeLibrary.getKeyEvents)({
        workspace_id: this.workspaceInfo.id,
        type: 'all'
      });
      if (res.code === 20000) {
        this.keyEvents = res.results.events;
      }
    }
  }
};
exports.default = _default;