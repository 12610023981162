"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clickoutside = _interopRequireDefault(require("element-ui/src/utils/clickoutside"));
var _ValueContainer = _interopRequireDefault(require("@/mgv-ui/packages/components/departmentSelectAndMemberSelect/ValueContainer"));
var _SearchContainer = _interopRequireDefault(require("@/mgv-ui/packages/components/departmentSelectAndMemberSelect/SearchContainer"));
var _DepartmentTree = _interopRequireDefault(require("./components/DepartmentTree"));
var _throttleDebounce = require("throttle-debounce");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MgvDepartmentMultiSelect',
  components: {
    ValueContainer: _ValueContainer.default,
    SearchContainer: _SearchContainer.default,
    DepartmentTree: _DepartmentTree.default
  },
  directives: {
    Clickoutside: _clickoutside.default
  },
  props: {
    showTagCount: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: '部门'
    },
    requestParams: {
      type: [Object, String],
      default: () => ({})
    },
    requestMethod: {
      require: true,
      type: Function
    },
    setCheckedRootNode: {
      type: Boolean,
      default: false
    },
    showSpaceName: {
      type: Boolean,
      default: false
    },
    showOnlyCurrent: {
      type: Boolean,
      default: false
    },
    onlyCurrent: {
      type: Boolean,
      default: false
    },
    backfillList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSearching: false,
      popoverVisible: false,
      selectionList: [],
      searchOptions: []
    };
  },
  watch: {
    backfillList: {
      immediate: true,
      handler(val) {
        // 如果有数据要回填则回填数据
        this.$nextTick(() => {
          this.$refs['department-tree'].backFillData(val);
        });
      }
    },
    selectionList() {
      this.setOptionsIsChecked();
    }
  },
  mounted() {
    // 设置popover最小宽度和输入框一致
    this.$refs['tree-select-options-container'].style.minWidth = this.$refs['value-container'].$el.offsetWidth + 'px';
  },
  methods: {
    hanldeClosePopover() {
      this.popoverVisible = false;
    },
    treeCheckedChange(val, isThrowData) {
      this.selectionList = val;
      isThrowData && this.throwData();
      if (this.showTagCount === -1) {
        this.$nextTick(() => {
          this.$refs['optionsPopover'].updatePopper();
        });
      }
    },
    throwData: (0, _throttleDebounce.debounce)(100, false, function () {
      this.$emit('change', this.selectionList.map(_ref => {
        let {
          id
        } = _ref;
        return id;
      }), this.selectionList);
    }),
    removeTag(nodeData) {
      // 取消树上选中节点
      this.$refs['department-tree'].handleTag(nodeData, false);
    },
    addTag(nodeData) {
      // 设置树上节点选中
      this.$refs['department-tree'].handleTag(nodeData, true);
    },
    clear() {
      this.selectionList.forEach(item => {
        this.removeTag(item);
      });
    },
    // 处理搜索部门的结果
    getDepartmentTreeData(filter) {
      this.searchOptions = [];
      if (!filter.length) return;
      const treeData = this.$refs['department-tree'].orgTreeData;
      this.searchTreeNode(treeData, filter);
      this.setOptionsIsChecked();
    },
    searchTreeNode(node, filter) {
      node.forEach(item => {
        if (item.name.includes(filter)) {
          this.searchOptions.push(item);
        }
        if (item.children.length) this.searchTreeNode(item.children, filter);
      });
    },
    setOptionsIsChecked() {
      this.searchOptions.forEach((item, index) => {
        this.$set(this.searchOptions[index], 'isChecked', this.selectionList.some(_ref2 => {
          let {
            id
          } = _ref2;
          return id === item.id;
        }));
      });
    },
    onOnlyCurrentInput(val) {
      this.$emit('update:onlyCurrent', val);
    }
  }
};
exports.default = _default;