"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("conversation-filter-item", {
    attrs: {
      label: "客户标签",
      "is-show-clear": _vm.isDeleteIconShow
    },
    on: {
      handleClear: _vm.clearAccountLabel
    }
  }, [_c("MultipleCascaderSelect", {
    staticClass: "multiple-cascader-select",
    attrs: {
      "is-account-label": "",
      filters: _vm.accountLabelFilter,
      "default-props": _vm.defaultProps,
      "cascader-options": _vm.item.options,
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleMultipleCascaderchange,
      addKeyEventCascader: function ($event) {
        return _vm.$emit("addKeyEventCascader");
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;