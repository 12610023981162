"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DealTargetInput',
  props: {
    targetInputOptions: {
      type: Object,
      default: () => ({})
    },
    disabledTargetList: {
      type: Array,
      default: () => []
    },
    isShowCloseBtn: {
      type: Boolean,
      default: false
    },
    targetName: {
      type: String,
      default: ''
    },
    newVal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editTitle: false,
      value: '',
      inputName: this.targetName,
      options: []
    };
  },
  watch: {
    newVal: {
      handler: function (val) {
        if (val.length) {
          this.value = val;
        }
      },
      immediate: true
    },
    value: {
      handler: function (val) {
        this.filterOptions(val);
      }
    },
    targetName: {
      handler: function (val) {
        this.inputName = val;
      }
    },
    inputName: {
      handler: function (val) {
        this.$emit('changeDealTargetTitle', val);
      }
    }
  },
  methods: {
    changeSelect(val) {
      this.$emit('excludeDealTarget', val);
    },
    changeTitle() {
      this.editTitle = !this.editTitle;
    },
    deleteDealTargetEvent() {
      this.$emit('deleteDealTargetEvent');
    },
    filterOptions() {
      this.$emit('assignDealTargetsProperty', {
        name: this.inputName,
        options: this.value
      });
    }
  }
};
exports.default = _default;