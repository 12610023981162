"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EventTracker = _interopRequireDefault(require("@/views/conversationPage/components/EventTracker.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealKeyEventFilter',
  components: {
    EventTracker: _EventTracker.default
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    keyEvents: {
      type: Array,
      default: () => []
    },
    popperClass: {
      type: String,
      default: 'business-filter-popover'
    }
  },
  computed: {
    chosenCount() {
      var _this$value$keyEventC;
      return (_this$value$keyEventC = this.value.keyEventCascaderList) === null || _this$value$keyEventC === void 0 ? void 0 : _this$value$keyEventC.filter(item => item.keyEventName.length).length;
    }
  },
  methods: {
    clearKeyEvent() {
      console.log('clear');
      this.$refs['eventTracker'].deleteKeyEvent();
    },
    addKeyEventCascader() {
      this.$refs['dealKeyEventPopoverRef'].updatePopper();
    },
    handleChange(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;