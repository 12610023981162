"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MyTaskFilterGroup = _interopRequireDefault(require("./MyTaskFilterGroup"));
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _compliance = require("@/api/compliance");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _MyTaskCardView = _interopRequireDefault(require("./MyTaskCardView.vue"));
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
var _SwitchViewButton = _interopRequireDefault(require("../../components/SwitchViewButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MyTaskContent',
  components: {
    MyTaskFilterGroup: _MyTaskFilterGroup.default,
    ComplianceBaseTable: _ComplianceBaseTable.default,
    NoData: _NoData.default,
    MyTaskCardView: _MyTaskCardView.default,
    ExportButton: _ExportButton.default,
    SwitchViewButton: _SwitchViewButton.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    total: {
      type: Number,
      default: 0
    },
    isTotal: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    tableData: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({
        prop: 'percent',
        order: 'descending'
      })
    },
    sortable: {
      type: Array,
      default: () => ['status', 'score_rule', 'template', 'create_at', 'percent', 'finish', 'wait', 'reject', 'total']
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    },
    tableHeader: {
      type: Array,
      default: () => [{
        label: '任务名称',
        prop: 'name',
        width: 190,
        fixed: 'left'
      }, {
        label: '状态',
        prop: 'task_status',
        width: 120
      }, {
        label: '进度',
        prop: 'percent',
        width: 260
      }, {
        label: '待质检',
        prop: 'wait',
        width: 100
      }, {
        label: '已质检',
        prop: 'finish',
        width: 100
      }, {
        label: '已驳回',
        prop: 'reject',
        width: 100
      }, {
        label: '会话总数',
        prop: 'total',
        width: 100
      }, {
        label: '质检评分规则',
        prop: 'score_rule',
        width: 200
      }, {
        label: '质检评价单',
        prop: 'template',
        width: 200
      }, {
        label: '分配人',
        prop: 'create_by',
        width: 120
      }, {
        label: '分配时间',
        prop: 'create_at',
        width: 160
      }]
    }
  },
  data() {
    return {
      pageSize: [10, 20, 30, 40],
      filtersObj: {
        task_name: '',
        create_time: [],
        percent: [],
        sort_by: [['create_at', 'descending']],
        page: 1,
        size: 20
      },
      viewType: 'card'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId']),
    noFilter() {
      const isSame = this.filtersObj.task_name === '' && JSON.stringify(this.filtersObj.percent) === '[]' && JSON.stringify(this.filtersObj.create_time) === '[]';
      if (!this.isTotal) {
        return isSame;
      }
      return this.filtersObj.status === 0 && isSame;
    }
  },
  watch: {
    filters: {
      handler: function (val, oldValue) {
        if (JSON.stringify(val) === JSON.stringify(oldValue)) return;
        this.filtersObj = val;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.viewType = sessionStorage.getItem('myTaskView') || 'card';
  },
  methods: {
    exportComplianceMyTaskApi: _compliance.exportComplianceMyTaskApi,
    async goComplianceWorkSpace(taskId) {
      const params = {
        user_ids: {
          tree_ids: [],
          user_ids: [this.userId]
        },
        workspace_id: this.workspaceInfo.id,
        sort_by: [['status', 'ascending']],
        task_id: taskId
      };
      const res = await (0, _compliance.getAllComplianceOfConversationIds)(params);
      if (res.code === 20000) {
        const conversationIds = res.results.list;
        params.conversationIds = conversationIds;
        const query = {
          id: conversationIds[0],
          task_id: taskId,
          sampling_type: this.$route.query.sampling_type
        };
        this.$router.push({
          name: 'ComplianceWorkSpace',
          query: {
            ...query
          },
          params
        });
      }
    },
    goTaskDetail(row) {
      this.$router.push({
        path: _routeMap.ROUTES.complianceMyTaskDetail,
        query: {
          id: row.id
        }
      });
    },
    selectView(command) {
      sessionStorage.setItem('myTaskView', command);
      this.viewType = command;
    },
    handleDistribute() {
      this.$emit('handleDistribute');
    },
    changeFilter(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.$emit('handleFiltersChange', this.filtersObj);
    },
    goCompliancePersonal(rowInfo) {
      this.$emit('goCompliancePersonal', rowInfo);
    },
    sizeChange(size) {
      this.$emit('sizeChange', size);
    },
    currentChange(page) {
      this.$emit('currentChange', page);
    }
  }
};
exports.default = _default;