"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$modelInfo$event;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: ["model-item-header", {
      "blue-bg": _setup.isRuleType
    }, {
      "orange-bg": _setup.isCombineType
    }]
  }, [_c("div", {
    staticClass: "header-row"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_vm._m(0), _c("div", {
    staticClass: "model-info"
  }, [_c("h2", {
    staticClass: "model-info__name row"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isNameEdit,
      expression: "!isNameEdit"
    }],
    staticClass: "text-container"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_setup.modelBaseInfo.name) + " ")]), _c("el-tooltip", {
    attrs: {
      content: "编辑",
      placement: "top"
    }
  }, [_c("el-button", {
    staticClass: "edit-button",
    on: {
      click: function ($event) {
        return _setup.startEdit("name");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit-outline"
  })])], 1)], 1), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isNameEdit,
      expression: "isNameEdit"
    }],
    ref: "nameInputRef",
    attrs: {
      maxlength: "20"
    },
    on: {
      blur: function ($event) {
        return _setup.onInputBlur("name");
      }
    },
    model: {
      value: _setup.modelBaseInfo.name,
      callback: function ($$v) {
        _vm.$set(_setup.modelBaseInfo, "name", $$v);
      },
      expression: "modelBaseInfo.name"
    }
  })], 1), _c("h2", {
    staticClass: "model-info__description row"
  }, [_c("div", {
    staticClass: "text-container"
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isDescriptionEdit,
      expression: "!isDescriptionEdit"
    }],
    staticClass: "text"
  }, [_vm._v(_vm._s(_setup.modelBaseInfo.description || "暂无描述"))]), _c("el-tooltip", {
    attrs: {
      content: "编辑",
      placement: "top"
    }
  }, [_c("el-button", {
    staticClass: "edit-button",
    on: {
      click: function ($event) {
        return _setup.startEdit("description");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit-outline"
  })])], 1)], 1), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isDescriptionEdit,
      expression: "isDescriptionEdit"
    }],
    ref: "descriptionInputRef",
    attrs: {
      type: "textarea",
      maxlength: "100"
    },
    on: {
      blur: function ($event) {
        return _setup.onInputBlur("description");
      }
    },
    model: {
      value: _setup.modelBaseInfo.description,
      callback: function ($$v) {
        _vm.$set(_setup.modelBaseInfo, "description", $$v);
      },
      expression: "modelBaseInfo.description"
    }
  })], 1)])]), _c("div", {
    staticClass: "model-operations"
  }, [_vm._l(_setup.buttons, function (item) {
    return [_c("el-button", {
      key: item.text,
      attrs: {
        "data-v-name": item["data-v-name"]
      },
      on: {
        click: item.onclick
      }
    }, [_c("i", {
      class: ["iconfont", `icon-${item.icon}`]
    }), _vm._v(" " + _vm._s(item.text))])];
  })], 2)]), _c("div", {
    staticClass: "info-detail-row"
  }, [_c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("模型ID")]), _c("span", {
    staticClass: "info-value-text"
  }, [_vm._v(_vm._s(_vm.modelInfo.id))])]), _c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("类型")]), _setup.isAIType ? _c("el-tag", {
    class: {
      "ai-tag": _setup.isAIType
    },
    attrs: {
      size: "small"
    }
  }, [_vm._v("AI模型")]) : _setup.isCombineType ? _c("el-tag", {
    class: {
      "combine-tag": _setup.isCombineType
    },
    attrs: {
      size: "small"
    }
  }, [_vm._v("组合模型")]) : _c("el-tag", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("规则模型")])], 1), _c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("模型用途")]), _vm.modelInfo.use_to === 1 ? _c("el-tag", {
    attrs: {
      size: "small",
      type: "info"
    }
  }, [_vm._v("关键事件")]) : _c("el-tag", {
    attrs: {
      size: "small",
      type: "info"
    }
  }, [_vm._v("客户标签")])], 1), _c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("发布状态")]), _c("span", {
    staticClass: "info-item-value"
  }, [_vm.modelInfo.status === 5 ? [_c("i", {
    staticClass: "iconfont icon-success"
  }), _c("span", {
    staticClass: "info-value-text"
  }, [_vm._v("已发布 (V" + _vm._s(_vm.modelInfo.stable_version) + ")")])] : [_c("i", {
    staticClass: "iconfont icon-error"
  }), _c("span", {
    staticClass: "info-value-text"
  }, [_vm._v("未发布")])]], 2)]), (_vm$modelInfo$event = _vm.modelInfo.event) !== null && _vm$modelInfo$event !== void 0 && _vm$modelInfo$event.event_name ? _c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("关键事件")]), _c("span", {
    staticClass: "info-item-value"
  }, [_c("i", {
    staticClass: "iconfont icon-key-event info-value-text"
  }), _c("span", {
    staticClass: "info-value-text"
  }, [_vm._v(_vm._s(_vm.modelInfo.event.event_name))]), _c("span", {
    staticClass: "dot info-value-text"
  }, [_vm._v("·")]), _c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.modelInfo.event.workspace.icon_color
    }
  }), _c("span", {
    staticClass: "info-value-text"
  }, [_vm._v(" " + _vm._s(_vm.modelInfo.event.workspace.name) + " ")])])]) : _vm._e(), _c("div", {
    staticClass: "info-item"
  }, [_c("label", {
    staticClass: "info-label"
  }, [_vm._v("归属人")]), _c("MgvAvatarName", {
    attrs: {
      size: 24,
      src: _vm.modelInfo.create_by.avatar,
      name: _vm.modelInfo.create_by.name
    }
  }), _c("span", {
    staticClass: "name info-value-text"
  }, [_vm._v(_vm._s(_vm.modelInfo.create_by.name))])], 1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-icon"
  }, [_c("i", {
    staticClass: "iconfont icon-box-square"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;