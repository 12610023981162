"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _DataTransferDrawer = _interopRequireDefault(require("@/components/DataTransferDrawer"));
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ResignedMemberTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    DataTransferDrawer: _DataTransferDrawer.default
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataTransferDrawerVisible: false,
      transferFromUserInfo: {},
      userCanTransferData: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['isTwoFactor']),
    tableHead() {
      const tableHead = [{
        field_name: 'name',
        label: this.$t('management.姓名')
      }, {
        field_name: 'tel',
        label: this.$t('management.登录账号')
      }, {
        field_name: 'department',
        label: this.$t('management.主部门')
      }, {
        field_name: 'workspace',
        label: this.$t('management.主空间')
      }, {
        field_name: 'operate',
        label: this.$t('management.操作'),
        fixed: 'right'
      }];
      if (this.isTwoFactor) {
        tableHead.splice(2, 0, {
          field_name: 'verify_email',
          label: this.$t('management.验证邮箱'),
          width: 180
        });
      }
      return tableHead;
    }
  },
  methods: {
    handleRefresh() {
      this.$emit('refresh');
    },
    debounceOpenDataTransferDrawer: (0, _commonFunc.debounce)(function (row) {
      this.openDataTransferDrawer(row);
    }),
    async openDataTransferDrawer(row) {
      const res = await (0, _organization.getUserCanTransferData)({
        user_id: row.user_id
      });
      if (res.code === 20000) {
        if (res.results.data.length) {
          this.userCanTransferData = res.results.data;
          this.transferFromUserInfo = row;
          this.dataTransferDrawerVisible = true;
        } else {
          this.$message({
            message: this.$t('management.此成员没有可转移的数据'),
            center: true,
            type: 'warning'
          });
        }
      }
    }
  }
};
exports.default = _default;