"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'DisableMemberDialog',
  props: {
    disableMemberDialogVisible: {
      type: Boolean,
      default: false
    },
    rowInfo: {
      type: Object,
      default: () => ({})
    },
    departmentMultiple: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      disableLoading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDisableMemberDialog');
    },
    submit() {
      const res = Object.values(this.rowInfo).length;
      const disableMembersData = {
        enable: false,
        user_ids: res ? [this.rowInfo.id] : this.departmentMultiple
      };
      this.enableAndDisableUser(disableMembersData);
    },
    async enableAndDisableUser(data) {
      this.disableLoading = true;
      const res = await (0, _organization.enableAndDisableUser)(data);
      if (res.code === 20000) {
        this.$emit('fetchDepartmentData');
        this.closeDialog();
      }
      // 自己禁用自己时直接关闭dialog并提示msg
      this.closeDialog();
      this.disableLoading = false;
    }
  }
};
exports.default = _default;