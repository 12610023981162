"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "batch-upload"
  }, _vm._l(_vm.dialogList, function (item) {
    return _c("batch-upload-dialog", {
      key: item,
      attrs: {
        type: item,
        visible: _vm.$data[`${item}Visible`]
      },
      on: {
        openDialog: function ($event) {
          return _vm.openDialog(item);
        },
        closeDialog: function ($event) {
          return _vm.closeDialog(item);
        }
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;