"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$currentKeyEvent;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: (_vm$currentKeyEvent = _vm.currentKeyEvent) !== null && _vm$currentKeyEvent !== void 0 && _vm$currentKeyEvent.id ? "编辑关键事件" : "新建关键事件",
      visible: _vm.visible,
      width: "600px",
      "before-close": _setup.handleClose,
      "append-to-body": ""
    }
  }, [_c("el-form", {
    ref: "createKeyEventRef",
    attrs: {
      model: _setup.form,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c(_setup.RuleEventFormItem, {
    staticClass: "rule-event-form-item",
    attrs: {
      name: _setup.form.name,
      description: _setup.form.desc
    },
    on: {
      "update:name": function ($event) {
        return _vm.$set(_setup.form, "name", $event);
      },
      "update:description": function ($event) {
        return _vm.$set(_setup.form, "desc", $event);
      }
    }
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.submitLoading,
      type: "primary"
    },
    on: {
      click: _setup.handleSubmit
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;