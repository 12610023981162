"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_vm.guideVisiable ? _c("div", {
    staticClass: "ti-shi"
  }, [_vm._m(0), _c("div", {
    staticClass: "button-style"
  }, [_c("el-button", {
    staticClass: "close-style",
    staticStyle: {
      color: "white"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.hanldeClose
    }
  }, [_vm._v("忽略")]), _c("el-button", {
    staticClass: "handle-enter",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleQuick.apply(null, arguments);
      }
    }
  }, [_vm._v("立即设定")])], 1)]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ti-shi-font"
  }, [_c("i", {
    staticClass: "iconfont icon-info1"
  }), _c("span", {
    staticStyle: {
      "margin-left": "5px",
      "font-size": "16px"
    }
  }, [_vm._v("设定说话人身份，获取完整分析结果")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;