"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isBase ? _c("ul", {
    staticClass: "audio-line base"
  }, _vm._l(_vm.audio, function (item) {
    return _c("li", {
      key: item.begin_percent,
      style: {
        left: item.begin_percent + "%",
        width: _vm.getWidth(item) + "%"
      }
    });
  }), 0) : _vm.isSingle ? _c("ul", {
    staticClass: "audio-line"
  }, [!_vm.items ? _c("li", {
    staticClass: "audio-loading"
  }) : _vm._l(_vm.items, function (item) {
    return _c("li", {
      key: item.begin_percent,
      style: {
        left: item.begin_percent + "%",
        width: _vm.getWidth(item) + "%",
        background: _vm.color
      }
    });
  }), _vm.maxSilenceDuration.duration && _vm.items ? _c("li", {
    style: {
      left: _vm.maxSilenceDuration.begin_percent + "%",
      width: _vm.getWidth(_vm.maxSilenceDuration) + "%",
      background: "#FFF6E5",
      borderRadius: 0
    }
  }) : _vm._e()], 2) : _vm.isMarker ? _c("ul", {
    staticClass: "audio-line point"
  }, _vm._l(_vm.pointObj.contents, function (item) {
    return _c("li", {
      key: item.begin_percent,
      style: {
        left: item.begin_percent + "%",
        background: _vm.pointObj.color
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.clickPoint(item);
        }
      }
    });
  }), 0) : _vm.isQa ? _c("ul", {
    staticClass: "audio-line qa"
  }, _vm._l(_vm.qaArray, function (item) {
    return _c("el-popover", {
      key: item.question.begin_percent,
      attrs: {
        placement: "top",
        width: "200",
        trigger: "hover",
        content: item.question.content,
        "popper-class": "qa-pop"
      }
    }, [_c("li", {
      style: {
        left: item.question.begin_percent + "%"
      },
      attrs: {
        slot: "reference"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.clickPoint(item.question);
        }
      },
      slot: "reference"
    })]);
  }), 1) : _vm.isShare ? _c("ul", {
    staticClass: "audio-line isScreen"
  }, [!_vm.items ? _c("li", {
    staticClass: "audio-loading"
  }) : _vm._l(_vm.items, function (item) {
    return _c("li", {
      key: item.color,
      style: {
        left: item.begin_percent + "%",
        width: _vm.getWidth(item) + "%",
        background: item.color
      }
    });
  })], 2) : _vm.isTopic ? _c("ul", {
    staticClass: "audio-line multiple"
  }, _vm._l(_vm.arrays, function (items) {
    return _c("div", {
      key: items.topic_color
    }, _vm._l(items.topic_range, function (item) {
      return _c("li", {
        key: item.start,
        class: {
          "grey-color": _vm.greyColor(items)
        },
        style: {
          left: item.begin_percent + "%",
          width: _vm.getWidthTopic(item) + "%",
          background: items.topic_color
        }
      });
    }), 0);
  }), 0) : _c("ul", {
    staticClass: "audio-line multiple"
  }, _vm._l(_vm.arrays, function (items) {
    return _c("div", {
      key: items.color
    }, _vm._l(items.detail, function (item) {
      return _c("li", {
        key: item.begin_percent,
        style: {
          left: item.begin_percent + "%",
          width: _vm.getWidth(item) + "%",
          background: items.color
        }
      });
    }), 0);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;