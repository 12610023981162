"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "hit-buttons"
  }, [_c("MgvButton", {
    class: {
      hit: _vm.status === 1
    },
    attrs: {
      size: "medium",
      color: "info",
      type: "plain"
    },
    on: {
      click: function ($event) {
        return _setup.onClick(1);
      }
    }
  }, [_vm._v("命中")]), _c("MgvButton", {
    class: {
      "un-hit": _vm.status === 2
    },
    attrs: {
      type: "plain",
      color: "info",
      size: "medium"
    },
    on: {
      click: function ($event) {
        return _setup.onClick(2);
      }
    }
  }, [_vm._v("未命中")]), _c("MgvButton", {
    class: {
      "no-hit": _vm.status === 3
    },
    attrs: {
      type: "plain",
      color: "info",
      size: "medium"
    },
    on: {
      click: function ($event) {
        return _setup.onClick(3);
      }
    }
  }, [_vm._v("不确定")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;