"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _moment = _interopRequireDefault(require("moment"));
var _i18n = require("@/i18n");
var _coaching = _interopRequireDefault(require("@/i18n/coaching"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ReminderSelect',
  props: {
    todoTime: {
      type: String,
      default: ''
    },
    defaultReminderTime: {
      type: String,
      default: ''
    },
    isLongerInterval: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reminderChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const reminderOptions = [{
      time: '',
      label: (0, _i18n.t)('coaching.不提醒'),
      longerShow: true
    }, {
      time: '5 minutes',
      label: (0, _i18n.t)('coaching.5 minutes'),
      longerShow: false
    }, {
      time: '15 minutes',
      label: (0, _i18n.t)('coaching.15 minutes'),
      longerShow: false
    }, {
      time: '30 minutes',
      label: (0, _i18n.t)('coaching.30 minutes'),
      longerShow: false
    }, {
      time: '1 hours',
      label: (0, _i18n.t)('coaching.1 hours'),
      longerShow: true
    }, {
      time: '2 hours',
      label: (0, _i18n.t)('coaching.2 hours'),
      longerShow: true
    }, {
      time: '4 hours',
      label: (0, _i18n.t)('coaching.4 hours'),
      longerShow: true
    }, {
      time: '1 days',
      label: (0, _i18n.t)('coaching.1 days'),
      longerShow: true
    }, {
      time: '3 days',
      label: (0, _i18n.t)('coaching.3 days'),
      longerShow: true
    }];
    const reminderRelativeTime = (0, _vue.ref)(props.defaultReminderTime);
    const getRelativeTimeValue = (0, _vue.computed)(() => {
      const timeValue = reminderOptions.find(item => item.label === reminderRelativeTime.value).time || '';
      return timeValue;
    });
    (0, _vue.watch)(() => props.todoTime, () => {
      reminderChange(reminderRelativeTime.value);
    });
    (0, _vue.watch)(() => props.defaultReminderTime, () => {
      reminderRelativeTime.value = props.defaultReminderTime;
    });
    function getAbsoluteTime(relativeLabel) {
      let time = 0;
      if (!props.todoTime || !relativeLabel) return time;
      const [value, unit] = reminderRelativeTime.value.split(' ');
      time = (0, _moment.default)(props.todoTime).subtract(Number(value), unit).unix();
      return time;
    }
    function reminderChange(relativeLabel) {
      const absoluteTimeAt = getAbsoluteTime(relativeLabel);
      const emitObj = {
        reminderAt: absoluteTimeAt,
        reminderLabel: relativeLabel
      };
      emit('reminderChange', emitObj);
    }
    return {
      __sfc: true,
      emit,
      props,
      reminderOptions,
      reminderRelativeTime,
      getRelativeTimeValue,
      getAbsoluteTime,
      reminderChange
    };
  }
};
exports.default = _default;