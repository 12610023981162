"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-radio-group", {
    staticClass: "mg-radio",
    on: {
      input: _vm.handleChange
    },
    model: {
      value: _vm.newValue[0],
      callback: function ($$v) {
        _vm.$set(_vm.newValue, 0, $$v);
      },
      expression: "newValue[0]"
    }
  }, _vm._l(_vm.options, function (item, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1) : _vm.newValue && _vm.newValue[0] ? _c("div", {
    staticClass: "replace-item"
  }, [_c("el-tag", {
    attrs: {
      type: "info",
      size: "small"
    }
  }, [_vm._v(_vm._s(_vm.noEditContent))])], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;