"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$dataSetDataList;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-set-annotate__operate"
  }, [_c("div", {
    staticClass: "conv-info"
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom-start",
      width: "400",
      trigger: "click",
      "append-to-body": false,
      "popper-class": "annotate-conversation-popover"
    },
    model: {
      value: _setup.convPopoverVisible,
      callback: function ($$v) {
        _setup.convPopoverVisible = $$v;
      },
      expression: "convPopoverVisible"
    }
  }, [_c(_setup.VirtualList, {
    staticStyle: {
      "overflow-y": "auto",
      height: "382px"
    },
    attrs: {
      "data-key": "id",
      "data-sources": _vm.dataSetDataList,
      "data-component": _setup.SampleItem,
      "extra-props": {
        activeDataSetData: _vm.activeDataSetData,
        handleChangeDataSetData: _setup.handleChangeDataSetData
      },
      "estimate-size": 40,
      keeps: 30
    }
  }), _c("div", {
    staticClass: "conv-basic-info",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    class: ["iconfont", {
      "icon-video-camera-solid-2": _vm.activeDataSetData.type === "video"
    }, {
      "icon-phone-outline-fill": _vm.activeDataSetData.type === "audio"
    }, {
      "icon-im-fill": _vm.activeDataSetData.type === "doc"
    }]
  }), _c("span", {
    staticClass: "conv-name"
  }, [_vm._v(_vm._s(_vm.activeDataSetData.name))]), _c("span", {
    staticClass: "conv-name"
  }, [_vm._v(_vm._s(_setup.activeDataSetDataIndex + 1) + "/" + _vm._s((_vm$dataSetDataList = _vm.dataSetDataList) === null || _vm$dataSetDataList === void 0 ? void 0 : _vm$dataSetDataList.length))]), _c("i", {
    staticClass: "iconfont icon-arrow-down"
  })])], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;