"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Feedback/index.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FeedbackButton',
  components: {
    FeedbackForm: _index.default
  },
  data() {
    return {
      feedbackDialogVisible: false
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    openFeedbackDialog() {
      this.feedbackDialogVisible = true;
    },
    closeDialog() {
      this.feedbackDialogVisible = false;
    },
    resetForm() {
      this.$refs.feedbackForm.resetForm();
    }
  }
};
exports.default = _default;