"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MemberViewFilterGroup = _interopRequireDefault(require("./MemberViewFilterGroup"));
var _ConvViewFilterGroup = _interopRequireDefault(require("./ConvViewFilterGroup"));
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberViewContent',
  components: {
    MemberViewFilterGroup: _MemberViewFilterGroup.default,
    ConvViewFilterGroup: _ConvViewFilterGroup.default,
    ComplianceBaseTable: _ComplianceBaseTable.default,
    NoData: _NoData.default,
    ExportButton: _ExportButton.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    viewName: {
      type: String,
      default: 'member'
    },
    scoreRuleList: {
      type: Array,
      default: () => []
    },
    evaluationItemList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    isTodayMission: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({
        prop: 'percent',
        order: 'descending'
      })
    },
    sortable: {
      type: Array,
      default: () => ['user_id', 'percent', 'finish', 'wait', 'reject', 'total']
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    },
    tableHeader: {
      type: Array,
      default: () => [{
        label: '质检员',
        prop: 'user_id',
        width: 163
      }, {
        label: '进度',
        prop: 'percent',
        width: 260
      }, {
        label: '已质检',
        prop: 'finish',
        width: 140
      }, {
        label: '待质检',
        prop: 'wait',
        width: 140
      }, {
        label: '已驳回',
        prop: 'reject',
        width: 140
      }, {
        label: '会话总数',
        prop: 'total',
        width: 100
      }]
    },
    isConvTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageSize: [10, 20, 30, 40],
      filtersObj: {},
      viewType: 'table',
      taskId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId'])
  },
  watch: {
    filters: {
      handler: function (val) {
        this.filtersObj = val;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.taskId = Number(this.$route.query.id);
  },
  methods: {
    sortChange(val) {
      this.$emit('sort-change', val);
    },
    exportComplianceTeamTaskDetailMemberApi: _compliance.exportComplianceTeamTaskDetailMemberApi,
    exportComplianceTeamConversationApi: _compliance.exportComplianceTeamConversationApi,
    goComplianceWorkSpace(rowInfo) {
      var _this$$route$query;
      const obj = (0, _commonFunc.deepClone)(this.filtersObj);
      delete obj.page;
      delete obj.size;
      const params = {
        user_ids: {
          tree_ids: [],
          user_ids: [this.userId]
        },
        task_id: (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.id,
        ...obj
      };
      const query = {
        id: rowInfo.conv_id || this.tableData[0].conv_id,
        sampling_id: rowInfo.account_sale_conv_id
      };
      params.status = params.status ? params.status : 0;
      this.$emit('goComplianceWorkSpace', {
        params,
        query
      });
    },
    selectView(command) {
      this.viewType = command;
    },
    handleDistribute() {
      this.$emit('handleDistribute');
    },
    changeFilter(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      if (this.isTodayMission) {
        this.filtersObj.create_time = this.getDate();
      }
      this.$emit('handleFiltersChange', this.filtersObj);
    },
    sizeChange(size) {
      this.$emit('sizeChange', size);
    },
    currentChange(page) {
      this.$emit('currentChange', page);
    }
  }
};
exports.default = _default;