"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DepartmentInfo',
  props: {
    currentDepartmentName: {
      type: String,
      default: ''
    },
    multipleSelect: {
      type: Array,
      default: () => []
    },
    memberCount: {
      type: Number,
      default: 0
    },
    exportApi: {
      type: Function,
      default: null
    },
    limitCount: {
      type: Number,
      default: 0
    },
    allMemberCounter: {
      type: Number,
      default: 0
    },
    departmentCharge: {
      type: Object,
      default: () => ({})
    },
    currentDepartmentMemberCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['setDepartmentCharge'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const setDepartmentCharge = () => {
      emit('setDepartmentCharge');
    };
    return {
      __sfc: true,
      props,
      emit,
      setDepartmentCharge,
      ExportButton: _ExportButton.default,
      t: _i18n.t
    };
  }
};
exports.default = _default;