"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-tooltip", {
    attrs: {
      content: "建模引导",
      disabled: !_setup.collapse
    }
  }, [_c("div", {
    directives: [{
      name: "right-bottom-drag",
      rawName: "v-right-bottom-drag"
    }],
    class: ["mega-tran-guide", {
      collapse: _setup.collapse
    }]
  }, [_c("div", {
    staticClass: "collapse-view",
    on: {
      click: _setup.handleCollapse
    }
  }, [_vm._v("💡")]), _c("div", {
    staticClass: "mega-tran-guide__header"
  }, [_c("div", {
    staticClass: "mega-tran-guide__title"
  }, [_c("span", [_vm._v("💡")]), _c("span", [_vm._v("建模引导")])]), _c("el-tooltip", {
    attrs: {
      content: "收起"
    }
  }, [_c("div", {
    staticClass: "mega-tran-guide-arrow",
    on: {
      click: _setup.handleCollapse
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-down"
  })])])], 1), _c("div", {
    staticClass: "mega-tran-guide__content"
  }, [_c("div", {
    staticClass: "tabs"
  }, _vm._l(_setup.tabList, function (tab) {
    return _c("div", {
      key: tab.id,
      staticClass: "tabs-item",
      class: {
        "is-active": _setup.activeTab === tab.id
      },
      on: {
        click: function ($event) {
          return _setup.handleTabChange(tab);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "tabs-content"
  }, [_c("el-collapse", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.activeTab === "AI" && _setup.apiPermission.includes(_setup.INTERFACE.createAiModel),
      expression: "\n              activeTab === 'AI' &&\n              apiPermission.includes(INTERFACE.createAiModel)\n            "
    }],
    attrs: {
      accordion: ""
    },
    on: {
      change: _setup.handleCollapseChange
    },
    model: {
      value: _setup.activeCollapse,
      callback: function ($$v) {
        _setup.activeCollapse = $$v;
      },
      expression: "activeCollapse"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "1.准备数据",
      name: "AI-1"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径：")]), _c("a", {
    on: {
      click: _setup.goDataSetPage
    }
  }, [_vm._v("数据集管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("新建数据集")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v("准备训练集和测试集")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v("训练集")]), _vm._v("用于模型的训练，就像练习题； ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v("测试集")]), _vm._v("用于模型效果的测试，就像考试题。 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 注意：考试题不能提前泄露，测试集不要用于训练模型哦！ ")])]), _c("el-collapse-item", {
    attrs: {
      title: "2.新建AI模型",
      name: "AI-2"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("新建模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v("选择AI模型新建，填写名称和描述")])]), _c("el-collapse-item", {
    attrs: {
      title: "3.训练AI模型",
      name: "AI-3"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径1：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("训练模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径2：")]), _c("a", {
    on: {
      click: _setup.goDataSetPage
    }
  }, [_vm._v("数据集管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“···”展开-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("训练模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" AI模型的训练一般需要等待几分钟，训练完成后可以继续测试或直接发布 ")])]), _c("el-collapse-item", {
    attrs: {
      title: "4.测试模型",
      name: "AI-4"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径1：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("测试模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径2：")]), _c("a", {
    on: {
      click: _setup.goDataSetPage
    }
  }, [_vm._v("数据集管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“···”展开-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("测试模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 常见的模型评估指标包括准确率、精确率、召回率、F1等。 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v("精确率Precision=正确预测为正/全部预测为正，")]), _vm._v(" 代表对正样本结果的预测准确程度，精确率越高，模型的预测结果越少出错。 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v("召回率Recall=正确预测为正/全部实际为正，")]), _vm._v(" 代表对正样本结果的遗漏程度，召回率越高，模型的预测结果越少漏掉正样本。 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v(" F1值=2 * （精确率 * 召回率） / （精确率 + 召回率），")]), _vm._v(" 代表对精确率和召回率之间的权衡。实际情况中，精确率和召回率经常互相制约，呈负相关关系，因此F1越高， 代表模型整体效果越好。 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_c("span", {
    staticClass: "weight"
  }, [_vm._v("模型效果不佳的解决措施")]), _c("br"), _vm._v(" 1.正样本太单一，不够多样，补充正样本"), _c("br"), _vm._v("2.训练集有标注错误，检查数据集标注"), _c("br"), _vm._v("3.联系我们，申请专家服务 ")])]), _c("el-collapse-item", {
    attrs: {
      title: "5.模型发布到关键事件",
      name: "AI-5"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径1：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("发布模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径2：")]), _c("a", {
    on: {
      click: _setup.goKeyEventManagementPage
    }
  }, [_vm._v("关键事件管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“···”展开-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("配置模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 模型是关键事件的运行机理，关键事件是模型应用于会话数据的载体 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 发布完成后，还可前往关键事件页面设置应用范围、分享权限。 ")])])], 1), _c("el-collapse", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.activeTab === "regular" && _setup.apiPermission.includes(_setup.INTERFACE.createRuleModel),
      expression: "\n              activeTab === 'regular' &&\n              apiPermission.includes(INTERFACE.createRuleModel)\n            "
    }],
    attrs: {
      accordion: ""
    },
    on: {
      change: _setup.handleCollapseChange
    },
    model: {
      value: _setup.activeCollapse,
      callback: function ($$v) {
        _setup.activeCollapse = $$v;
      },
      expression: "activeCollapse"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "1.新建规则模型",
      name: "regular-1"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("新建模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 选择规则模型新建，填写名称和描述，设置触发规则 ")])]), _c("el-collapse-item", {
    attrs: {
      title: "2.模型发布到关键事件",
      name: "regular-2"
    }
  }, [_c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径1：")]), _c("a", {
    on: {
      click: _setup.goModelManagementPage
    }
  }, [_vm._v("模型管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("发布模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs12"
  }, [_c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("操作路径2：")]), _c("a", {
    on: {
      click: _setup.goKeyEventManagementPage
    }
  }, [_vm._v("关键事件管理页面")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("-点击“···”展开-点击“")]), _c("span", {
    staticClass: "clr-gray-2 fs12"
  }, [_vm._v("配置模型")]), _c("span", {
    staticClass: "clr-gray-4 fs12"
  }, [_vm._v("”")])]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 模型是关键事件的运行机理，关键事件是模型应用于会话数据的载体 ")]), _c("p", {
    staticClass: "fs14 clr-gray-2"
  }, [_vm._v(" 发布完成后，还可前往关键事件页面设置应用范围、分享权限。 ")])])], 1)], 1)])])]), _c("MgvTour", {
    ref: "createDataSetGuideRef",
    attrs: {
      name: "showCreateDataSetGuide",
      steps: _setup.createDataSetStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  }), _c("MgvTour", {
    ref: "createModelGuideRef",
    attrs: {
      name: "showCreateAIModelGuide",
      steps: _setup.createAIModelStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  }), _c("MgvTour", {
    ref: "trainModelGuideRef",
    attrs: {
      name: "showTrainAIModelGuide",
      steps: _setup.trainAIModelStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  }), _c("MgvTour", {
    ref: "testModelGuideRef",
    attrs: {
      name: "showTestAIModelGuide",
      steps: _setup.testAIModelStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  }), _c("MgvTour", {
    ref: "publishModelGuideRef",
    attrs: {
      name: "showPublishAIModelGuide",
      steps: _setup.publishAIModelStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  }), _c("MgvTour", {
    ref: "configModelGuideRef",
    attrs: {
      name: "showConfigModelGuide",
      steps: _setup.configModelStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c("div")];
      },
      proxy: true
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;