"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authApp = require("@/api/authApp");
var _BackBtn = _interopRequireDefault(require("./BackBtn"));
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AppointForm',
  components: {
    BackBtn: _BackBtn.default,
    DynamicForm: _DynamicForm.default
  },
  props: {
    useType: {
      type: String,
      default: ''
    },
    tel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        channel: '腾讯会议应用市场',
        tel: this.tel,
        account_type: this.useType
      },
      btnLoading: false,
      industryOptions: ['企业服务', '互联网/IT', '教育', '金融', '房地产', '家装', '医美', '批发零售', '生活服务', '其他'],
      employeeCountOptions: ['10 人以下', '11 - 30 人', '31 - 100 人', '101 - 500 人', '500 人以上']
    };
  },
  computed: {
    desc() {
      // if(this.useType==='个人使用'){}
      const descs = [{
        id: 'name',
        label: '姓名',
        type: 'text',
        required: true
      }, {
        id: 'tel',
        label: '手机号',
        type: 'tel',
        required: true
      }, {
        id: 'position',
        label: '职务',
        type: 'text'
      }, {
        id: 'email',
        label: '工作邮箱',
        type: 'email'
      }, {
        id: 'company',
        label: '企业名称',
        type: 'text',
        required: true
      }, {
        id: 'industry',
        label: '所属行业',
        type: 'select',
        required: true,
        options: this.industryOptions
      }, {
        id: 'employee_count',
        label: '使用规模',
        type: 'select',
        required: true,
        options: this.employeeCountOptions
      }];
      return this.useType === '个人' ? descs.slice(0, 3) : descs;
    }
  },
  methods: {
    async submitForm() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.btnLoading = true;
        const res = await (0, _authApp.applyForTrial)(this.form);
        if (res.code === 200) {
          this.$emit('submitSucceed');
        }
        this.btnLoading = false;
      }
    }
  }
};
exports.default = _default;