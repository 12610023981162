"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _validate = require("@/utils/validate");
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _AllFilterItems = _interopRequireDefault(require("./AllFilterItems"));
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import DealKeyEventOrderFilter from '@/views/business/components/DealKeyEventOrderFilter.vue';
var _default = {
  name: 'HeaderFilters',
  components: {
    AllFilterItems: _AllFilterItems.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    BatchSearchInput: _BatchSearchInput.default
    // DealKeyEventOrderFilter
  },

  props: {
    customFilterObject: {
      type: Array,
      default: () => []
    },
    keyEvents: {
      type: Array,
      default: () => []
    },
    // keyEventsOrder: {
    //   type: Array,
    //   default: () => []
    // },
    filterConfigValue: {
      type: Object,
      default: () => ({})
    },
    kanbanId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      mgvCallBack: {
        onMore: this.onMore
      },
      departmentOptions: [],
      rules: {
        contact_tel: [{
          validator: _validate.telValidatorAllowNone,
          message: '请输入完整的手机号',
          trigger: 'change'
        }]
      },
      allFilters: {},
      filterParams: {
        trade_name: {
          value: [],
          is_batch: false
        },
        account_name: '',
        contact_tel: ''
      },
      keyEventObj: {
        keyEventCascaderList: [{
          keyEventName: [],
          inverse: false
        }],
        event_engine_relation: 'and'
      },
      keyEventObjOrder: {
        keyEventOrderCascaderList: [{
          keyEventName: [],
          inverse: false
        }, {
          keyEventName: [],
          inverse: false
        }]
      },
      clearBtnVisible: false,
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      filterNumber: 0,
      // 筛选数量
      sortNumber: 0,
      // 排序数量
      defaultFilterObj: {
        trade_name: {
          value: [],
          is_batch: false
        },
        account_name: '',
        contact_tel: ''
      },
      isNoNeedBack: false,
      isNoNeedSet: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'orgId']),
    originFilterParams() {
      return this.$options.data().filterParams;
    }
  },
  watch: {
    filterParams: {
      handler: function (val) {
        this.watchFilterParams(val);
      },
      deep: true
    },
    keyEventObj: {
      handler: function (val) {
        if (!this.isNoNeedSet) {
          this.$refs.allFilterItems.setKeyeventBackfillValue(val);
        } else {
          this.isNoNeedSet = false;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    const createPicker = document.querySelector('.create-date-picker');
    if (createPicker) {
      createPicker.removeEventListener('mouseup', this.stopPropagation);
    }
  },
  methods: {
    handleTelInput(val) {
      this.filterParams.contact_tel = val.trim();
      this.debounceCustomChange();
    },
    keyeventSelectChange(val) {
      this.isNoNeedSet = true;
      this.keyEventObj = val;
    },
    keyeventOrderSelectChange(val) {
      this.isNoNeedSet = true;
      this.keyEventObjOrder = val;
    },
    // 搜索客户/商机推荐项
    async getFuzzySearchList(filter, cb, type) {
      const res = await (0, _business.getFuzzySearchList)({
        filter,
        type,
        workspace_id: this.workspaceInfo.id
      });
      cb(res.results.map(value => {
        return {
          value
        };
      }));
    },
    handleClear(type) {
      if (type === 'trade_name') {
        this.filterParams[type] = {
          value: [],
          is_batch: false
        };
        return;
      }
      this.filterParams[type] = '';
    },
    watchFilterParams() {
      if (!this.isNoNeedBack) {
        this.defaultFilterObj = this.filterParams;
      } else {
        this.isNoNeedBack = false;
      }
    },
    isObjectKeyHasItem(obj) {
      let flag = false;
      for (const key in obj) {
        if (obj[key] && obj[key].length) {
          flag = true;
        }
      }
      return flag;
    },
    // 传递给后端的参数
    createViewData(filters) {
      const obj = {};
      let number = 0;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].params) {
          obj[filters[i].type] = filters[i].params;
          if (filters[i].type === 'user_ids') {
            if (filters[i].params && Array.isArray(filters[i].params)) {
              obj[filters[i].type] = this.handleUserValue(filters[i].params);
            }
          }
          if (filters[i].type === 'keyEventCascaderList') {
            const {
              event_engine_relation,
              keyEventCascaderList
            } = filters[i].params;
            if (keyEventCascaderList) {
              var _keyEventCascaderList, _keyEventCascaderList2;
              obj.keyEventCascaderList = keyEventCascaderList;
              obj.event_engine_relation = event_engine_relation;
              if (((_keyEventCascaderList = keyEventCascaderList[0]) === null || _keyEventCascaderList === void 0 ? void 0 : (_keyEventCascaderList2 = _keyEventCascaderList.keyEventName) === null || _keyEventCascaderList2 === void 0 ? void 0 : _keyEventCascaderList2.length) > 0) {
                number++;
              }
            }
          }
          if (filters[i].type === 'keyEventOrderCascaderList') {
            const {
              keyEventOrderCascaderList
            } = filters[i].params;
            if (keyEventOrderCascaderList) {
              var _keyEventOrderCascade, _keyEventOrderCascade2;
              obj.keyEventOrderCascaderList = keyEventOrderCascaderList.filter(e => e['keyEventLabel']);
              if (((_keyEventOrderCascade = keyEventOrderCascaderList[0]) === null || _keyEventOrderCascade === void 0 ? void 0 : (_keyEventOrderCascade2 = _keyEventOrderCascade.keyEventName) === null || _keyEventOrderCascade2 === void 0 ? void 0 : _keyEventOrderCascade2.length) > 0) {
                number++;
              }
            }
          }
          if (Array.isArray(filters[i].params) && filters[i].params.length > 0) {
            number++;
          } else if (filters[i].params instanceof Object && this.isObjectKeyHasItem(filters[i].params) && filters[i].type !== 'keyEventCascaderList' && filters[i].type !== 'keyEventOrderCascaderList') {
            number++;
          } else if (typeof filters[i].params !== 'object' && filters[i].params) {
            number++;
          }
        }
      }
      this.filterNumber = number;
      return obj;
    },
    handleUserValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    filterChange(filters) {
      this.allFilters = (0, _commonFunc.deepClone)(filters);
      const filterObj = this.createViewData(this.allFilters);
      const {
        trade_name,
        account_name,
        contact_tel
      } = filterObj;
      this.isNoNeedBack = true;
      this.filterParams = {
        ...this.filterParams,
        trade_name,
        account_name,
        contact_tel
      };
      this.$emit('filterChange', filterObj, this.filterNumber);
    },
    debounceCustomChange: (0, _commonFunc.debounce)(function (val) {
      this.customChange();
    }, 500),
    customChange() {
      // 需要保证customchange的时间在filter改变之后
      this.$nextTick(() => {
        this.$emit('custom-change');
      });
    }
  }
};
exports.default = _default;