"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useVModel;
var _vue = require("vue");
function useVModel(props, propName, emit) {
  return (0, _vue.computed)({
    get() {
      return new Proxy(props[propName], {
        get(target, key) {
          return Reflect.get(target, key);
        },
        set(target, key, newValue) {
          emit('update:' + propName, {
            ...target,
            [key]: newValue
          });
          return true;
        }
      });
    },
    set(value) {
      emit('update:' + propName, value);
    }
  });
}