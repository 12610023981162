"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "container"
  }, [_c("p", {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.publicScope")))]), _c("el-radio-group", {
    model: {
      value: _setup.currentIsPublic,
      callback: function ($$v) {
        _setup.currentIsPublic = $$v;
      },
      expression: "currentIsPublic"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.visibleToAll")))]), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.visibleToSpecified")))])], 1), _c("mgv-member-multi-select", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.currentIsPublic,
      expression: "!currentIsPublic"
    }],
    attrs: {
      "is-prevent-not-selected": false,
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "request-params": {
        type: JSON.stringify(["training_edit"])
      },
      "backfill-list": _vm.backfillList
    },
    on: {
      change: _setup.assigneeChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;