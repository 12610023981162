"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'MemberTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  emits: 'goCompliance',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;

    // 跳转至会话页面，回填待质检成员
    const goCompliance = row => {
      emit('goCompliance', row);
    };
    const tableHeads = [{
      prop: 'name',
      label: '成员'
    }, {
      prop: 'tree_path',
      label: '部门'
    }, {
      prop: 'operate',
      label: '操作'
    }];
    return {
      __sfc: true,
      props,
      emit,
      goCompliance,
      tableHeads
    };
  }
};
exports.default = _default;