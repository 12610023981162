"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "link-text",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("el-popover", {
    ref: "popover1",
    attrs: {
      placement: "top",
      trigger: "hover",
      content: "这是一段内容",
      "popper-class": "link-text-popper"
    },
    model: {
      value: _vm.popperShow,
      callback: function ($$v) {
        _vm.popperShow = $$v;
      },
      expression: "popperShow"
    }
  }, [_c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopySuccess,
      expression: "onCopySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.link,
      expression: "link",
      arg: "copy"
    }],
    attrs: {
      type: "text"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-copy-link"
  }), _c("span", [_vm._v("复制链接")])]), _c("el-link", {
    attrs: {
      slot: "reference",
      type: "primary",
      underline: false,
      href: _vm.link,
      target: "_blank"
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.title))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;