import { render, staticRenderFns } from "./KnowledgeConv.vue?vue&type=template&id=4fe9d330&scoped=true&"
import script from "./KnowledgeConv.vue?vue&type=script&lang=js&"
export * from "./KnowledgeConv.vue?vue&type=script&lang=js&"
import style0 from "./KnowledgeConv.vue?vue&type=style&index=0&id=4fe9d330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe9d330",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fe9d330')) {
      api.createRecord('4fe9d330', component.options)
    } else {
      api.reload('4fe9d330', component.options)
    }
    module.hot.accept("./KnowledgeConv.vue?vue&type=template&id=4fe9d330&scoped=true&", function () {
      api.rerender('4fe9d330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessIntelligence/knowledge/knowledgeOverview/components/KnowledgeConv.vue"
export default component.exports