"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "conversation-context"
  }, [_vm._l(_vm.renderInfo, function (item) {
    return [item.sentence ? _c("li", {
      key: item.type,
      class: item.type === "above" ? "is-above" : "is-current"
    }, [_c("span", {
      staticClass: "user-name"
    }, [item.type === "above" ? _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.name) + ": ")], 1), _c("span", {
      staticClass: "comment-content",
      domProps: {
        innerHTML: _vm._s(_vm.formatCommentContent(item.sentence))
      }
    })]) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;