"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    fullScreenContainer: {
      type: [HTMLDivElement, Array],
      default: null
    }
  },
  data() {
    return {
      isFullscreen: false
    };
  },
  mounted() {
    window.addEventListener('fullscreenchange', this.changeIsFullscreen);
  },
  beforeDestroy() {
    window.removeEventListener('fullscreenchange', this.changeIsFullscreen);
  },
  methods: {
    // 全屏查看
    handleFullScreen() {
      if (!this.fullScreenContainer) return;
      const fullScreenElement = Array.isArray(this.fullScreenContainer) ? this.fullScreenContainer[0] : this.fullScreenContainer;
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (fullScreenElement.requestFullscreen) {
          fullScreenElement.requestFullscreen();
        } else if (fullScreenElement.webkitRequestFullScreen) {
          fullScreenElement.webkitRequestFullScreen();
        } else if (fullScreenElement.mozRequestFullScreen) {
          fullScreenElement.mozRequestFullScreen();
        } else if (fullScreenElement.msRequestFullscreen) {
          // IE11
          fullScreenElement.msRequestFullscreen();
        }
      }
      this.$emit('toggleFullScreen', !this.isFullscreen);
    },
    changeIsFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    }
  }
};
exports.default = _default;