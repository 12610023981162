"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgAutoTooltip = _interopRequireDefault(require("@/components/MgAutoTooltip"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyEventFolderListView',
  props: {
    folderList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['onFolderClick'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onFolderClick = item => {
      emit('onFolderClick', item);
    };
    return {
      __sfc: true,
      props,
      emit,
      onFolderClick,
      MgAutoTooltip: _MgAutoTooltip.default
    };
  }
};
exports.default = _default;