"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberTable',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    memberList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    // 编辑操作
    editMember(memberList) {
      this.$emit('openOperateMemberDialog', memberList);
    },
    handleSelectionChange(membersList) {
      this.$emit('selectionChange', membersList);
    }
  }
};
exports.default = _default;