"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keyEventCard = _interopRequireDefault(require("@/components/BusinessTable/keyEventCard"));
var _EventTabTags = _interopRequireDefault(require("./EventTabTags.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _trade = require("@/api/trade");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventsTabContent',
  components: {
    EventTabsTags: _EventTabTags.default,
    KeyEventCard: _keyEventCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    activeTabName: {
      type: String,
      default: ''
    },
    currentRow: {
      type: Object,
      default: () => ({})
    },
    tradeId: {
      type: [String, Number],
      default: ''
    },
    currentFieldName: {
      type: String,
      default: ''
    },
    activeIds: {
      type: Array,
      default: () => []
    },
    isShowEventTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // NOTE 关键事件
      eventLoading: false,
      currentPage: 1,
      pageSize: 10,
      eventListLength: 0,
      eventTotal: 0,
      cardContentArr: [],
      convTotal: 0,
      convListLength: 0,
      tagsList: [],
      activeEventIds: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    dataType() {
      switch (this.activeTabName) {
        case 'events':
          return '关键事件';
        case 'next_step':
          return '下一步';
        case 'objection':
          return '客户异议';
        case 'account_label':
          return '客户标签';
        default:
          return '';
      }
    },
    noMore() {
      return this.activeTabName === 'account_label' ? !this.convListLength || this.convListLength >= this.convTotal : !this.eventListLength || this.eventListLength >= this.eventTotal;
    }
  },
  watch: {
    currentRow: {
      handler: function (val) {
        if (val.trade_id) {
          this.$nextTick(async () => {
            this.initKeyEventData();
            this.activeEventIds = [];
            await this.getKeyEventList({
              event_engine_type: this.activeTabName,
              trade_id: this.tradeId,
              page: this.currentPage,
              event_ids: this.activeEventIds
            });
            if (this.activeTabName === 'account_label') {
              this.scrollToActiveConv();
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    activeIds: {
      handler: async function (val) {
        if (val && val.length) {
          this.initKeyEventData();
          this.activeEventIds = val;
          await this.getKeyEventList({
            event_engine_type: this.activeTabName,
            trade_id: this.tradeId,
            page: this.currentPage,
            event_ids: this.activeEventIds
          });
          if (this.activeTabName === 'account_label') {
            this.scrollToActiveConv();
          }
        }
      },
      deep: true
    }
  },
  methods: {
    toggleSelectTag(isSelected, index) {
      this.$set(this.tagsList[index], 'is_selected', isSelected);
      this.activeEventIds = this.tagsList.filter(item => item.is_selected).map(c => c.id);
      this.initKeyEventData();
      this.getKeyEventList({
        event_engine_type: this.activeTabName,
        trade_id: this.tradeId,
        page: this.currentPage,
        event_ids: this.activeEventIds
      });
    },
    handleActiveDate(item) {
      if (this.activeTabName !== 'account_label' || !this.currentRow[this.currentFieldName]) {
        return;
      }
      return item.tag_id === this.currentRow[this.currentFieldName].data[0].event_id;
    },
    // 滚动到对应客户标签
    scrollToActiveConv() {
      if (this.currentFieldName && this.currentRow[this.currentFieldName].data && this.currentRow[this.currentFieldName].data[0].event_id && this.$refs['key-event-card']) {
        this.$nextTick(() => {
          let top = 0;
          this.cardContentArr.some((item, index) => {
            if (this.handleActiveDate(item)) {
              const timelineElement = this.$refs['key-event-card'][index];
              top = timelineElement ? timelineElement.$el.offsetTop - 20 : 0;
              return true;
            }
          });
          this.$emit('scrollToActiveConv', top);
        });
      }
    },
    async getKeyEventList(filters) {
      const {
        event_engine_type,
        trade_id,
        page,
        event_ids
      } = filters;
      if (event_engine_type === 'account_label') {
        await this.getAccountLabelData({
          trade_id,
          page,
          tag_ids: event_ids
        });
      } else {
        this.getKeyEventData(filters);
      }
    },
    // NOTE 初始化关键事件数据
    initKeyEventData() {
      this.currentPage = 1;
      this.cardContentArr = [];
      this.eventListLength = 0;
      this.convListLength = 0;
    },
    //  NOTE 请求关键事件数据
    async getKeyEventData(filters) {
      this.eventLoading = true;
      const res = await (0, _trade.getEventEngine)({
        ...filters,
        size: this.pageSize
      });
      if (res.code === 20000) {
        this.eventLoading = false;
        const {
          tag_infos,
          items,
          total_count
        } = res.results;
        items && this.cardContentArr.push(...items);
        this.tagsList = tag_infos;
        this.activeEventIds = this.tagsList.filter(item => item.is_selected).map(c => c.id);
        // 滚动加载
        this.eventTotal = total_count;
        this.eventListLength += items === null || items === void 0 ? void 0 : items.length;
        this.currentPage++;
      }
    },
    async getAccountLabelData(filters) {
      this.eventLoading = true;
      const params = {
        ...filters,
        size: this.pageSize,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _trade.getTradeAccountLabel)(params);
      if (res.code === 20000) {
        this.eventLoading = false;
        const {
          items,
          total_count,
          tag_infos
        } = res.results;
        items && this.cardContentArr.push(...items);
        this.tagsList = tag_infos;
        this.activeEventIds = this.tagsList.filter(item => item.is_selected).map(c => c.id);
        this.convTotal = total_count;
        this.convListLength += [...new Set(items.map(item => item.conversation_id))].length;
        this.currentPage++;
      }
    },
    // NOTE 滚动加载
    loadMore() {
      this.getKeyEventList({
        event_engine_type: this.activeTabName,
        trade_id: this.tradeId,
        page: this.currentPage,
        event_ids: this.activeEventIds
      });
    }
  }
};
exports.default = _default;