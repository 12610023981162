"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.isEdit ? "编辑看板" : "创建看板",
      visible: _vm.visible,
      "before-close": _vm.closeDrawer,
      size: "600px",
      "custom-class": "kanban-create",
      "wrapper-closable": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "kanban-config"
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.kanbanConfigLoading,
      expression: "kanbanConfigLoading"
    }],
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.kanbanConfig
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入看板名称"
    },
    model: {
      value: _vm.kanbanConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "name", $$v);
      },
      expression: "kanbanConfig.name"
    }
  })], 1), _c("div", {
    staticClass: "key-results-select"
  }, [_c("p", {
    staticClass: "key-results-title"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v("分析范围")]), _vm._v("符合以下条件的交易纳入分析范围 ")]), _c("el-radio-group", {
    on: {
      change: function ($event) {
        return _vm.handleKeyResultsTypeChange("analysis_scope");
      }
    },
    model: {
      value: _vm.kanbanConfig.analysis_scope.type,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.analysis_scope, "type", $$v);
      },
      expression: "kanbanConfig.analysis_scope.type"
    }
  }, _vm._l(_vm.keyResultsTypeOptions, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "key-results",
    class: {
      "custom-field": _vm.kanbanConfig.analysis_scope.type === "custom"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "analysis_scope.key"
    }
  }, [_vm.kanbanConfig.analysis_scope.type === "custom" ? _c("el-select", {
    attrs: {
      placeholder: "请选择自定义字段",
      clearable: ""
    },
    on: {
      change: function ($event) {
        return _vm.handleCustomFieldChange("analysis_scope");
      }
    },
    model: {
      value: _vm.kanbanConfig.analysis_scope.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.analysis_scope, "key", $$v);
      },
      expression: "kanbanConfig.analysis_scope.key"
    }
  }, _vm._l(_vm.customFieldOptions, function (item) {
    return _c("el-option", {
      key: item.field_name,
      attrs: {
        label: item.name,
        value: item.field_name
      }
    });
  }), 1) : _vm._e(), _vm.kanbanConfig.analysis_scope.type === "deal_stage" ? _c("el-select", {
    attrs: {
      placeholder: "请选择交易阶段",
      clearable: ""
    },
    on: {
      change: function ($event) {
        return _vm.handleDealStageFieldChange("analysis_scope");
      }
    },
    model: {
      value: _vm.kanbanConfig.analysis_scope.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.analysis_scope, "key", $$v);
      },
      expression: "kanbanConfig.analysis_scope.key"
    }
  }, _vm._l(_vm.dealStageOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.stage_name,
        value: item.id
      }
    });
  }), 1) : _vm._e(), _vm.kanbanConfig.analysis_scope.type === "events" ? _c("el-cascader", {
    staticClass: "events-cascadar",
    attrs: {
      "show-all-levels": false,
      options: _vm.eventTreeData,
      "collapse-tags": "",
      clearable: "",
      props: {
        multiple: true,
        expandTrigger: "hover",
        label: "name",
        value: "id",
        disabled: "is_disable",
        emitPath: false
      },
      placeholder: "请选择关键事件"
    },
    model: {
      value: _vm.kanbanConfig.analysis_scope.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.analysis_scope, "key", $$v);
      },
      expression: "kanbanConfig.analysis_scope.key"
    }
  }) : _vm._e()], 1), _vm.kanbanConfig.analysis_scope.type === "custom" && _vm.kanbanConfig.analysis_scope.key ? _c("el-form-item", {
    staticClass: "custom-value-input",
    attrs: {
      prop: "analysis_scope.value"
    }
  }, [_c("deal-custom-filter", {
    attrs: {
      item: _vm.customFilterItem("analysis_scope"),
      value: _vm.kanbanConfig.analysis_scope.value
    },
    on: {
      customFilterChange: function ($event) {
        return _vm.handleCustomFilterItemChange($event, "analysis_scope");
      }
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "key-results-select"
  }, [_c("p", {
    staticClass: "key-results-title"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _c("span", {
    staticClass: "name"
  }, [_vm._v("关键结果")]), _vm._v("达成关键结果视为转化，未达成视为流失 ")]), _c("el-radio-group", {
    on: {
      change: function ($event) {
        return _vm.handleKeyResultsTypeChange("results");
      }
    },
    model: {
      value: _vm.kanbanConfig.results.type,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.results, "type", $$v);
      },
      expression: "kanbanConfig.results.type"
    }
  }, _vm._l(_vm.keyResultsTypeOptions, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "key-results",
    class: {
      "custom-field": _vm.kanbanConfig.results.type === "custom"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "results.key"
    }
  }, [_vm.kanbanConfig.results.type === "custom" ? _c("el-select", {
    attrs: {
      placeholder: "请选择自定义字段"
    },
    on: {
      change: function ($event) {
        return _vm.handleCustomFieldChange("results");
      }
    },
    model: {
      value: _vm.kanbanConfig.results.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.results, "key", $$v);
      },
      expression: "kanbanConfig.results.key"
    }
  }, _vm._l(_vm.customFieldOptions, function (item) {
    return _c("el-option", {
      key: item.field_name,
      attrs: {
        label: item.name,
        value: item.field_name
      }
    });
  }), 1) : _vm._e(), _vm.kanbanConfig.results.type === "deal_stage" ? _c("el-select", {
    attrs: {
      placeholder: "请选择交易阶段"
    },
    on: {
      change: function ($event) {
        return _vm.handleDealStageFieldChange("results");
      }
    },
    model: {
      value: _vm.kanbanConfig.results.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.results, "key", $$v);
      },
      expression: "kanbanConfig.results.key"
    }
  }, _vm._l(_vm.dealStageOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.stage_name,
        value: item.id
      }
    });
  }), 1) : _vm._e(), _vm.kanbanConfig.results.type === "events" ? _c("el-cascader", {
    staticClass: "events-cascader",
    attrs: {
      "show-all-levels": false,
      options: _vm.eventTreeData,
      "collapse-tags": "",
      props: {
        multiple: true,
        expandTrigger: "hover",
        label: "name",
        value: "id",
        disabled: "is_disable",
        emitPath: false
      },
      placeholder: "请选择关键事件"
    },
    model: {
      value: _vm.kanbanConfig.results.key,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig.results, "key", $$v);
      },
      expression: "kanbanConfig.results.key"
    }
  }) : _vm._e()], 1), _vm.kanbanConfig.results.type === "custom" && _vm.kanbanConfig.results.key ? _c("el-form-item", {
    staticClass: "custom-value-input",
    attrs: {
      prop: "results.value"
    }
  }, [_c("deal-custom-filter", {
    attrs: {
      item: _vm.customFilterItem("results"),
      value: _vm.kanbanConfig.results.value
    },
    on: {
      customFilterChange: function ($event) {
        return _vm.handleCustomFilterItemChange($event, "results");
      }
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDrawer
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 保存 ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;