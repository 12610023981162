"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _clues = require("@/api/clues");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealDetailInfo',
  components: {
    DynamicForm: _DynamicForm.default
  },
  inject: ['init'],
  props: {
    clueObject: {
      type: Object,
      default: () => ({})
    },
    formDesc: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    clueId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      isClueEdit: false,
      saveLoading: false,
      everData: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions'])
  },
  methods: {
    handleCancelClick() {
      this.isClueEdit = false;
      this.$emit('cancel', this.everData);
    },
    async submitForm() {
      const submitData = {
        lead_id: this.clueId,
        lead: this.value
      };
      if (this.$refs['form'].validate()) {
        this.saveLoading = true;
        const res = await (0, _clues.postEditClue)(submitData).finally(() => {
          this.saveLoading = false;
        });
        if (res.code === 20000) {
          this.$message.success('保存成功！');
          this.isClueEdit = false;
          this.init();
        }
      }
    },
    enterEdit() {
      this.everData = {
        ...this.value
      };
      this.isClueEdit = true;
    },
    handleInput(val) {
      this.$emit('handleInput', val);
    }
  }
};
exports.default = _default;