"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible || _vm.getContentType() === "collect" ? _c("el-dropdown", {
    staticClass: "folder-dropdown",
    attrs: {
      placement: "bottom-end",
      trigger: "click"
    },
    on: {
      command: function ($event) {
        return _vm.$emit("command", $event);
      }
    }
  }, [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "operate-btn"
    }, [_c("i", {
      staticClass: "iconfont icon-more"
    })])];
  }), _c("el-dropdown-menu", {
    staticClass: "snippets-library-dropdown-menu-common",
    staticStyle: {
      "min-width": "182px"
    },
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.getContentType() === "collect" && !_vm.isTree ? _c("el-dropdown-item", {
    attrs: {
      command: "discollect"
    }
  }, [_vm._v("取消收藏")]) : _c("div", [_vm.interfacePermission.assignSnippetStudyTask ? _c("el-dropdown-item", {
    attrs: {
      command: "assignSnippetTask"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-user-assign"
  }), _vm._v("指派学习")]) : _vm._e(), _vm.interfacePermission.moveAllSnippetToFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "moveAllSnippetsToFolder"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-folder-opened"
  }), _vm._v("移动全部内容")]) : _vm._e(), _vm.interfacePermission.moveFolderToFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "moveFolderToFolder"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-folder-move"
  }), _vm._v("移动文件夹")]) : _vm._e(), _vm.interfacePermission.moveFolderToFolder ? _c("el-divider") : _vm._e(), _vm.interfacePermission.editPublicScope ? _c("el-dropdown-item", {
    attrs: {
      command: "editAccessUser"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-unlock"
  }), _vm._v("编辑公开范围")]) : _vm._e(), _vm.interfacePermission.renameFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "rename"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit-outline"
  }), _vm._v("重命名")]) : _vm._e(), _vm.interfacePermission.deleteFolder ? _c("el-dropdown-item", {
    attrs: {
      command: "deleteFolder"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-delete"
  }), _vm._v("删除")]) : _vm._e()], 1)], 1)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;