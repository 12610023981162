"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    pickerOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultOptions: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      }
    };
  },
  methods: {
    handleNativeInput(e) {
      if (this.isTime(e.target.value)) {
        this.handleChange(e.target.value);
        console.log('val', e.target.value);
      }
    },
    isTime(str) {
      const arr = str.split(':');
      const [h, s] = arr;
      console.log('streee ', h, s);
      return str.length === 5 && str[2] === ':' && h >= 0 && h < 24 && s >= 0 && s < 60;
    }
  }
};
exports.default = _default;