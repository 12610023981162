"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemCard = _interopRequireDefault(require("./FormItemCard"));
var _ScoreItemSet = _interopRequireDefault(require("@/views/tradeSet/tradeSetScore/components/ScoreItemSet"));
var _SavedFiltersContent = _interopRequireDefault(require("@/views/conversationPage/components/SavedFiltersContent.vue"));
var _index = _interopRequireDefault(require("@/components/ScopeOfOpen/index.vue"));
var _ScoreRuleDateFilter = _interopRequireDefault(require("@/components/ScoreRuleDateFilter"));
var _ScoreSummary = _interopRequireDefault(require("./ScoreSummary"));
var _conversationList = require("@/api/conversationList");
var _business = require("@/api/business");
var _conversationScoreRule = require("@/api/conversationScoreRule");
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RuleSettingForm',
  components: {
    FormItemCard: _FormItemCard.default,
    ScoreItemSet: _ScoreItemSet.default,
    SavedFiltersContent: _SavedFiltersContent.default,
    ScopeOfOpen: _index.default,
    ScoreRuleDateFilter: _ScoreRuleDateFilter.default,
    ScoreSummary: _ScoreSummary.default
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    noBaseInformation: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    filterNum: {
      type: Number,
      default: 0
    },
    // 会话列表筛选的数量
    count: {
      type: Number,
      default: 0
    },
    countLoading: {
      type: Boolean,
      default: false
    },
    currentScoreId: {
      type: [Number, String, Symbol],
      default: ''
    }
  },
  data() {
    return {
      filters: {},
      stageOptions: [],
      customFilterArray: [],
      isSaveValidate: false,
      backMemberObj: {},
      scopeError: false,
      // 后端返回允许添加的筛选项
      hasAllowOptionsArr: [],
      // 客户标签级联数据
      accountTagOptionsList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'orgId']),
    localFormData: {
      get() {
        const v = this.value;
        const dataConfig = v.data_scope.config;
        if (typeof dataConfig === 'string') {
          v.data_scope.config = JSON.parse(dataConfig);
        }
        return v;
      },
      set(val) {
        let v = (0, _commonFunc.deepClone)(this.value); // 利用深拷贝原理使得修改prop值不会报错,因为prop是单向数据流，2.0版本上不允许在组件内部直接修改
        v = val;
        this.$emit('input', v);
      }
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.$nextTick(() => {
          if (val) {
            this.getbackMemberObj();
          }
        });
      }
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getStageOptions();
        }
      },
      immediate: true
    }
  },
  created() {
    this.getConversationScoreOptions();
    this.getDealCustomFilter();
  },
  methods: {
    openConvListDrawer() {
      this.$emit('openConvListDrawer');
    },
    dateFilterChange(filter) {
      this.$emit('dateFilterChange', filter);
    },
    async getConversationScoreOptions() {
      const res = await (0, _conversationScoreRule.getConversationScoreOptions)();
      if (res.code === 20000) {
        this.hasAllowOptionsArr = res.results.list;
        const hasAccountLabel = this.hasAllowOptionsArr.find(item => item.item_type === 'account_label');
        if (hasAccountLabel) {
          this.getAccountTagOptions();
        }
      }
    },
    async getAccountTagOptions() {
      const res = await (0, _conversationScoreRule.getAccountTagOptions)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.accountTagOptionsList = res.results.tags;
        this.$store.commit('ruleSetting/SET_ACCOUNT_TAG_OPTIONS_LIST', this.accountTagOptionsList);
      }
    },
    getbackMemberObj() {
      const info = (0, _commonFunc.deepClone)(this.value);
      const members = Array.isArray(info.visible_range.visible_members) ? info.visible_range.visible_members : [];
      const obj = {
        isPublic: info.visible_range.is_public,
        'backfill-list': info.visible_range.is_public ? [] : members
      };
      this.backMemberObj = obj;
    },
    handleOpenSetOptionConvDrawer() {
      this.$emit('openFilterDrawer');
    },
    // 筛选范围发生变化
    handleScopeChange(visiableObj) {
      const {
        is_public
      } = visiableObj;
      this.localFormData.visible_range = visiableObj;
      if (is_public) {
        this.backMemberObj = {
          // 如果切换为对所有人可见充值回填值
          isPublic: true,
          'backfill-list': []
        };
      }
    },
    async getDealCustomFilter() {
      const params = {
        access_type: _routeMap.ROUTES.conversation
      };
      const res = await (0, _business.getDealCustomFilter)(params);
      this.customFilterArray = Object.values(res.results).flat();
    },
    getStageOptions() {
      (0, _conversationList.getStageOptions)({
        workspace_id: this.workspaceInfo.id,
        source: 'conversation'
      }).then(res => {
        this.stageOptions = res.results;
      });
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            this.isSaveValidate = true;
            const configValid = this.checkConfigItems();
            const scopeValid = !this.scopeError;
            resolve(configValid && scopeValid);
          } else {
            this.$message.warning('请输入规则名称');
            resolve(false);
          }
        });
      });
    },
    checkConfigItems() {
      var _this$localFormData$s;
      const configs = (_this$localFormData$s = this.localFormData.score_item_config) === null || _this$localFormData$s === void 0 ? void 0 : _this$localFormData$s.score_items;
      if (configs.length) {
        var _this$$refs$scoreItem2;
        const inputRes = configs.every(item => {
          var _this$$refs$scoreItem;
          return (_this$$refs$scoreItem = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem === void 0 ? void 0 : _this$$refs$scoreItem.validateRuleItem(item);
        });
        const scoreRes = (_this$$refs$scoreItem2 = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem2 === void 0 ? void 0 : _this$$refs$scoreItem2.validateRuleScore();
        if (!scoreRes) {
          this.$message.warning('各评分项分值总和不等于评分规则满分，请调整评分项分值或评分规则满分');
        }
        return inputRes && scoreRes;
      } else {
        this.$message.warning('请至少添加一个评分项');
        this.isSaveValidate = false;
      }
    },
    resetValidate() {
      var _this$$refs$scoreItem3;
      this.isSaveValidate = false;
      this.$refs['ruleForm'].clearValidate();
      (_this$$refs$scoreItem3 = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem3 === void 0 ? void 0 : _this$$refs$scoreItem3.resetScoreValid();
    },
    handleScopeError(val) {
      this.scopeError = val;
    }
  }
};
exports.default = _default;