"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "risk-card"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "risk-item"
    }, [_c("h1", [_c("i", {
      staticClass: "iconfont icon-risk"
    }), _c("span", {
      staticClass: "risk-title"
    }, [_vm._v(_vm._s(item.name))])]), _c("p", {
      staticClass: "risk-describe"
    }, [_vm._v(_vm._s(item.describe))])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;