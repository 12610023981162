"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'RuleTest',
  props: {
    testResult: {
      type: Object,
      default: () => ({
        keys: [],
        datas: []
      })
    }
  },
  data() {
    return {
      test_sentence: '',
      isTestRule: false
    };
  },
  methods: {
    handleTestRuleEvent() {
      this.$emit('handleTestRuleEvent', this.test_sentence);
      this.isTestRule = true;
    },
    handleHighLightContent(content, keywords) {
      for (let i = keywords.length - 1; i >= 0; i--) {
        const {
          begin_pos,
          end_pos
        } = keywords[i];
        const sentence = content.slice(begin_pos, end_pos);
        const before = content.slice(0, begin_pos);
        const after = content.slice(end_pos);
        content = before + `<span class='high-light'>${sentence}</span>` + after;
      }
      return content;
    }
  }
};
exports.default = _default;