"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "toggle-view"
  }, [_c("el-tooltip", {
    attrs: {
      content: "卡片视图"
    }
  }, [_c("i", {
    class: ["iconfont", "icon-view-card-2", {
      is_active: _vm.viewType === "card"
    }],
    on: {
      click: function ($event) {
        return _vm.selectView("card");
      }
    }
  })]), _c("el-tooltip", {
    attrs: {
      content: "表格视图"
    }
  }, [_c("i", {
    class: ["iconfont", "icon-view-list", {
      is_active: _vm.viewType === "table"
    }],
    on: {
      click: function ($event) {
        return _vm.selectView("table");
      }
    }
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;