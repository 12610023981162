"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
var _vuex = require("vuex");
var _videojs = _interopRequireDefault(require("videojs"));
var _errorLog = require("@/utils/error-log");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'VideoPlayer',
  components: {
    SvgIcon: _SvgIcon.default
  },
  inject: ['reload'],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    url: {
      type: String,
      required: ''
    },
    mediaType: {
      type: String,
      default: 'audio'
    },
    tabActiveName: {
      type: String,
      default: 'transtext'
    }
  },
  data() {
    return {
      options: {
        sources: [{
          src: '',
          type: 'application/x-mpegURL'
        }],
        playbackRates: [1, 1.25, 1.5, 2] // 倍速播放配置
      },

      canPlay: true,
      hasFirstPlayClick: false,
      videoErrorTimer: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['begintime', 'curtime', 'is_video_pause', 'player', 'buffTime', 'video', 'hasVideo'])
  },
  watch: {
    is_video_pause(val) {
      if (val) {
        this.pausePlay();
      }
    },
    begintime: {
      handler: function (value) {
        if (this.hasVideo && this.player.children_[0]) {
          this.player.children_[0].currentTime = value.value;
          this.player.children_[0].play();
        }
      },
      deep: true
    },
    url(value) {
      this.$store.commit('video/SET_VIDEO', {
        currentTime: 0,
        maxTime: 0,
        playing: false,
        muted: false,
        speed: 1,
        waiting: true,
        preload: 'auto'
      });
      this.$nextTick(() => {
        var _this$player;
        const sources = this.options.sources[0];
        sources.src = value;
        (_this$player = this.player) === null || _this$player === void 0 ? void 0 : _this$player.src(value);
      });
    },
    tabActiveName: {
      handler: function (val) {
        this.$nextTick(() => {
          if (this.mediaType !== 'video' || this.video.waiting) return;
          if (val === 'transtext' && !document.pictureInPictureElement) {
            var _this$player2;
            (_this$player2 = this.player) === null || _this$player2 === void 0 ? void 0 : _this$player2.requestPictureInPicture();
          } else if (val === 'video' && document.pictureInPictureElement) {
            document.exitPictureInPicture();
          }
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs['video'] && this.mediaType !== 'doc') {
        const player = (0, _videojs.default)(this.$refs['video'], this.options, function onPlayerReady() {});
        this.$store.commit('video/SET_PLAYER', player);
      }
      this.$refs['videoWrapper'].onkeydown = e => {
        const key = window.event.keyCode;
        this.keyup(key);
      };
    });
  },
  methods: {
    handleSliderInput(range) {
      this.$store.commit('video/SET_SNIPPET_SLIDER_RANGE', range);
    },
    enterPictureInPicture() {
      var _this$player3;
      // eslint-disable-next-line no-undef
      (_this$player3 = this.player) === null || _this$player3 === void 0 ? void 0 : _this$player3.requestPictureInPicture();
    },
    enterFullscreen() {
      var _this$player4, _this$player5, _this$$refs$video;
      // eslint-disable-next-line no-undef
      (_this$player4 = this.player) === null || _this$player4 === void 0 ? void 0 : _this$player4.requestFullscreen();
      // eslint-disable-next-line no-undef
      if ((_this$player5 = this.player) !== null && _this$player5 !== void 0 && _this$player5.isInPictureInPicture()) {
        var _this$player6;
        // eslint-disable-next-line no-undef
        (_this$player6 = this.player) === null || _this$player6 === void 0 ? void 0 : _this$player6.exitPictureInPicture();
      }
      (_this$$refs$video = this.$refs.video) === null || _this$$refs$video === void 0 ? void 0 : _this$$refs$video.focus();
      this.player.controls(true);
    },
    changeIsMuted() {
      var _this$player7;
      const isMuted = this.player.muted();
      if (isMuted) {
        this.voice = this.originVoice;
      } else {
        this.originVoice = this.voice;
        this.voice = 0;
      }
      // eslint-disable-next-line no-undef
      (_this$player7 = this.player) === null || _this$player7 === void 0 ? void 0 : _this$player7.muted(!isMuted);
    },
    handleVolumeSlider(val) {
      var _this$player8, _this$player9;
      // eslint-disable-next-line no-undef
      (_this$player8 = this.player) === null || _this$player8 === void 0 ? void 0 : _this$player8.volume(val / 100);
      // eslint-disable-next-line no-undef
      if (val && (_this$player9 = this.player) !== null && _this$player9 !== void 0 && _this$player9.muted()) {
        var _this$player10;
        // eslint-disable-next-line no-undef
        (_this$player10 = this.player) === null || _this$player10 === void 0 ? void 0 : _this$player10.muted(false);
      }
    },
    handleOpen() {
      if (this.$refs['popover0'].length > 0) {
        this.$refs['popover0'][0].popVisiable = true;
      }
    },
    keyup(key) {
      // 按左键
      // eslint-disable-next-line eqeqeq
      if (key == 37) {
        this.rewind();
      }
      // 按右键
      // eslint-disable-next-line eqeqeq
      if (key == 39) {
        this.forward();
      }
      // 按空格键
      // eslint-disable-next-line eqeqeq
      if (key == 32) {
        this.startPlayOrPause();
      }
    },
    rewind() {
      const newTime = this.player.children_[0].currentTime - 15;
      this.player.children_[0].currentTime = newTime > 0 ? newTime : 0;
      this.player.children_[0].play();
      this.$store.dispatch('video/get_cur_order', {
        current_time: newTime,
        time: new Date().getTime()
      });
    },
    forward() {
      const newTime = this.player.children_[0].currentTime + 15;
      this.player.children_[0].currentTime = newTime < this.video.maxTime ? newTime : this.video.maxTime;
      this.player.children_[0].play();
      this.$store.dispatch('video/get_cur_order', {
        current_time: newTime,
        time: new Date().getTime()
      });
    },
    // 播放跳转
    changeCurrentTime(index) {
      if (this.mediaType === 'doc') return;
      var curTime = index / 100 * this.video.maxTime;
      if (this.player.children_[0]) {
        // 如果是音视频
        this.player.children_[0].currentTime = curTime;
        this.player.children_[0].play();
      } else {
        // 如果是文字
        this.$store.dispatch('video/change_current', {
          current_time: curTime
        });
      }
      this.$store.dispatch('video/get_cur_order', {
        current_time: curTime,
        time: new Date().getTime()
      });
      this.$store.dispatch('video/change_begin', {
        begin_time: curTime,
        time: new Date().getTime()
      });
    },
    dropCurrentTime(time) {
      const video = this.player.children_[0];
      if (video) {
        video.currentTime = time;
        video.play();
      }
      this.$store.dispatch('video/get_cur_order', {
        current_time: time,
        time: new Date().getTime()
      });
    },
    inputFun(index) {
      this.slideInput = true;
      setTimeout(() => {
        this.slideInput = false;
      }, 500);
    },
    startPlayOrPause() {
      if (!this.hasFirstPlayClick) {
        this.$emit('firstPlay');
        this.hasFirstPlayClick = true;
      }
      return this.video.playing ? this.pausePlay() : this.startPlay();
    },
    // 开始播放
    startPlay() {
      // this.$refs['video'].play();
      this.player.children_[0].play();
    },
    // 暂停
    pausePlay() {
      var _this$player$children;
      (_this$player$children = this.player.children_[0]) === null || _this$player$children === void 0 ? void 0 : _this$player$children.pause();
    },
    // 当音频暂停
    onPause() {
      this.video.playing = false;
      this.$store.commit('video/SET_IS_VIDEO_PAUSE', true);
    },
    onError(error) {
      this.video.waiting = true; // 当发生错误, 就出现loading状态
      this.$emit('videoError', true);
      // 如果会话状态是分析完成，视频无法播放再上报日志。
      if (this.overviewInfo && this.overviewInfo.status === 'completed') {
        if (error.target.error.code !== 2) {
          // 如果code不为2，则出错时10s后尝试重新加载资源，避免阿里云鉴权链接延迟导致的加载错误
          if (!this.videoErrorTimer) {
            this.videoErrorTimer = setTimeout(() => {
              const sources = this.options.sources[0];
              sources.src = this.url;
              this.player.src(this.url);
            }, 10000);
          } else {
            // code为2是网络错误导致不需要报错误类型的日志,warning类型即可
            (0, _errorLog.postErrorLog)('video error:', 'error code:' + error.target.error.code + '| url:' + this.url, error.type);
          }
        } else {
          (0, _errorLog.postErrorLog)('video error:', 'error code:' + error.target.error.code + '| url:' + this.url, 'warning');
        }
      }
    },
    // 当音频开始等待
    onWaiting(res) {
      this.video.waiting = true;
      if (this.video.playing) {
        // 兼容safari直接点击进度条时一直加载，loading不消失的问题
        setTimeout(() => {
          this.video.waiting = false;
        }, 1);
      }
    },
    onPlaying() {
      this.video.loading = false;
      this.video.waiting = false;
    },
    // 当音频开始播放
    onPlay() {
      this.video.playing = true;
      this.video.loading = false;
      if (this.mediaType === 'video' && this.tabActiveName === 'transtext' && !document.pictureInPictureElement) {
        var _this$player11;
        (_this$player11 = this.player) === null || _this$player11 === void 0 ? void 0 : _this$player11.requestPictureInPicture();
      }
      this.$store.commit('video/SET_IS_VIDEO_PAUSE', false);
      this.$store.commit('video/SET_IS_USER_SCROLL', false);
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    onTimeupdate: (0, _commonFunc.throttle)(function (res) {
      if (!res.target) return;
      this.video.currentTime = res.target.currentTime;
      this.$store.dispatch('video/change_current', {
        current_time: res.target.currentTime
      });
      if (!this.slideInput) {
        const sliderTime = this.video.currentTime / this.video.maxTime * 100;
        this.$emit('changeSliderTime', sliderTime);
      }
    }, 1000),
    // 当加载语音流元数据完成后，会触发该事件的回调函数
    // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata(res) {
      this.video.waiting = false;
      this.$emit('videoError', false);
      this.video.maxTime = parseInt(res.target.duration);
      this.$store.commit('video/SET_VIDEO_MAX_TIME', this.video.maxTime);
    },
    // 当音频播放结束的时候
    onended(res) {},
    // 缓冲进度
    onProgress(res) {
      var _this$player$children2;
      const duration = parseInt(res.target.duration);
      const buffArray = (_this$player$children2 = this.player.children_) !== null && _this$player$children2 !== void 0 && _this$player$children2[0] ? this.player.children_[0].buffered : null;
      let bufferedLength = 0;
      if (duration > 0 && this.buffTime <= 100 && buffArray) {
        for (var i = 0; i < buffArray.length; i++) {
          // 寻找当前时间之后最近的点
          if (buffArray.start(buffArray.length - 1 - i) < res.target.currentTime) {
            bufferedLength = parseInt(buffArray.end(buffArray.length - 1 - i) / duration * 100);
            break;
          }
        }
      }
      const buffTime = bufferedLength > 100 ? 100 : bufferedLength;
      this.$store.commit('video/SET_BUFF_TIME', buffTime);
    }
  }
};
exports.default = _default;