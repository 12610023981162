"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-menu-content"
  }, [_c("header", {
    staticClass: "task-header"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isTaskDetail,
      expression: "isTaskDetail"
    }],
    staticClass: "go-back-btn",
    on: {
      click: _vm.goBackToTaskList
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  }), _c("span", [_vm._v("返回")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  })], 1), _c("span", {
    staticClass: "menu-name"
  }, [_vm._v(_vm._s(_vm.isTaskDetail ? "任务详情" : _vm.currentTaskTitle))])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isTaskDetail,
      expression: "!isTaskDetail"
    }],
    staticClass: "task-content"
  }, [_vm.currentMenu === "complete" ? _c("div", [_c("ul", {
    staticClass: "is-done-task-menu"
  }, _vm._l(_vm.isDoneTaskType, function (item) {
    return _c("li", {
      key: item.name,
      staticClass: "done-item",
      class: {
        "active-type": item.name === _vm.activeDoneTaskType
      },
      on: {
        click: function ($event) {
          return _vm.changeActiveDoneTaskType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _c("is-done-task-list", {
    staticClass: "is-done-task-list-container",
    attrs: {
      "active-type": _vm.activeDoneTaskType
    },
    on: {
      goTaskDetail: _vm.goTaskDetail
    }
  })], 1) : _vm.currentMenu.includes("manageView") ? _c("manage-view-list", {
    on: {
      goTaskDetail: _vm.goTaskDetail
    }
  }) : _c("div", [_c("create-task", {
    ref: "create-task",
    on: {
      creatNewTask: _vm.handleCreatNewTask
    }
  }), _c("task-list", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.taskListLoading,
      expression: "taskListLoading"
    }],
    attrs: {
      "task-type-list": _vm.taskTypeList,
      "current-menu": _vm.currentMenu
    },
    on: {
      goTaskDetail: _vm.goTaskDetail,
      operateTask: _vm.handleOperateTask
    }
  })], 1)], 1), _vm.isTaskDetail ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.taskDetailLoading,
      expression: "taskDetailLoading"
    }],
    staticClass: "task-detail-container"
  }, [Object.keys(_vm.currentTaskData).length ? _c("task-detail", {
    attrs: {
      "task-data": _vm.currentTaskData,
      "is-complete-task": _vm.currentMenu === "complete"
    },
    on: {
      updateDetailInfo: _vm.getCurrentTaskDetail
    }
  }) : _c("div", {
    staticClass: "no-detail"
  }, [_vm._v("暂无数据")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;