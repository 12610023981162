"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationCard = _interopRequireDefault(require("./ConversationCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _client = require("@/api/client");
var _contact = require("@/api/contact");
var _authApp = require("@/api/authApp");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetail',
  components: {
    ConversationCard: _ConversationCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    accountId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    // eslint-disable-next-line vue/require-prop-type-constructor
    contactId: String | Number,
    // eslint-disable-next-line vue/require-default-prop
    // eslint-disable-next-line vue/require-prop-type-constructor
    entityId: String | Number,
    // 腾讯会议中客户商机线索为同一接口
    // eslint-disable-next-line vue/require-default-prop
    // eslint-disable-next-line vue/require-prop-type-constructor
    entityType: String | Number,
    demoData: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      accountConversation: [],
      pageSize: 10,
      loading: false,
      total: 0,
      listLength: 0,
      currentPage: 1
    };
  },
  computed: {
    noMore() {
      return this.listLength && this.listLength === this.total;
    },
    disabled() {
      return this.loading || this.noMore || this.demoData;
    },
    getTimeString() {
      return function (time) {
        return this.$moment(time).format('YYYY-MM-DD HH:mm');
      };
    }
  },
  created() {
    if (this.demoData) {
      this.accountConversation = this.demoData.conversations;
      return;
    }
    this.getList();
  },
  methods: {
    load() {
      this.getList();
    },
    getIconType(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'audio') {
        return `iconfont icon-phone-outline2`;
        // eslint-disable-next-line eqeqeq
      } else if (type == 'video') {
        return `iconfont icon-video-camera`;
      } else if (type === 'doc') {
        return 'iconfont icon-im-fill';
      }
    },
    async getList() {
      this.loading = true;
      if (this.accountId) {
        await this.getClientConv();
      } else if (this.contactId) {
        await this.getContactConv();
      } else if (this.entityId) {
        await this.getConversationTimeline();
      }
      this.currentPage++;
      this.loading = false;
    },
    async getClientConv() {
      const res = await (0, _client.getClientDetailConversation)(this.accountId, this.currentPage, this.pageSize);
      res.results.account_conversation.forEach(item => {
        this.accountConversation.push(item);
      });
      this.total = res.results.counts_conversation;
      this.listLength += res.results.account_conversation.length;
    },
    async getContactConv() {
      const res = await (0, _contact.getContactConversation)(this.contactId, this.currentPage, this.pageSize);
      res.results.conversation.forEach(item => {
        this.accountConversation.push(item);
      });
      this.total = res.results.count;
      this.listLength += res.results.conversation.length;
    },
    async getConversationTimeline() {
      const res = await (0, _authApp.getConversationTimeline)(this.entityId, this.entityType, this.currentPage, this.pageSize);
      res.results.conversations.forEach(item => {
        this.accountConversation.push(item);
      });
      this.total = res.results.count;
      this.listLength += res.results.conversations.length;
    }
  }
};
exports.default = _default;