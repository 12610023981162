"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChangeBindDialog',
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    changeDialogVisible: {
      type: Boolean,
      default: false
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    isChangeBind: {
      type: Boolean,
      default: false
    },
    seatNumber: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bindUserId: 0,
      organizationTree: []
    };
  },
  watch: {
    changeDialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['header-filters'].selection = [];
        });
      }
    }
  },
  methods: {
    searchSeat(val) {
      this.bindUserId = val[0];
    },
    handleClose() {
      this.$emit('closeChangeBindDialog');
    },
    handleChangeBind() {
      if (!this.bindUserId) {
        this.$message({
          message: '请输入与该坐席绑定的成员账号！',
          center: true,
          type: 'warning'
        });
      } else {
        this.$emit('confirmChangeBind', this.bindUserId);
      }
    }
  }
};
exports.default = _default;