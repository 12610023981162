"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "table",
    staticClass: "sales-task-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight,
      "row-key": "id",
      "tree-props": {
        children: "children"
      },
      "default-expand-all": ""
    },
    on: {
      "sort-change": _vm.handleSortChange,
      "row-click": row => _vm.$refs.table.toggleRowExpansion(row)
    }
  }, [_vm._l(_vm.headerList, function (item) {
    return _c("el-table-column", {
      key: item.prop,
      attrs: {
        prop: item.prop,
        label: _vm.$t(item.label),
        sortable: item.sortable,
        "sort-orders": ["descending", "ascending"],
        fixed: item.fixed,
        "min-width": item.width ? item.width : 180,
        "show-overflow-tooltip": item.showOverflowTip
      },
      scopedSlots: _vm._u([item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "task-name"
          }, [_vm._v(" " + _vm._s(row.name) + " ")])];
        }
      } : item.prop === "course_type" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "course-type"
          }, [_vm._v(_vm._s(_vm.courseType(row)))])];
        }
      } : item.prop === "creator_info" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "executor-info"
          }, [_c("image-avatar", {
            staticClass: "upper-avatar",
            attrs: {
              name: row.creator_info.name,
              avatar: row.creator_info.avatar,
              shape: "circle"
            }
          }), _c("span", [_vm._v(_vm._s(row.creator_info.name))])], 1)];
        }
      } : item.prop === "start_time" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.start_time)) + " ")];
        }
      } : item.prop === "end_time" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.end_time)) + " ")];
        }
      } : item.prop === "status" ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_c("div", {
            class: _vm.getStatusClass(row)
          }, [_vm._v(_vm._s(_vm.getStatus(row)))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      width: 120,
      prop: "operate",
      fixed: "right",
      label: _vm.$t("coaching.operation"),
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("div", {
          staticClass: "operate"
        }, [!_vm.isCombinedTask(row) ? [row.status ? _c("span", {
          staticClass: "task-detail",
          on: {
            click: function ($event) {
              return _vm.goSalesPlanDetail(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("coaching.details")))]) : _c("el-button", {
          staticClass: "go-practice",
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.goPractice(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("coaching.goPractice")))])] : _vm._e()], 2)];
      }
    }])
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;