"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$metrics;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "left-practice-process-tips",
    class: {
      "is-collapse": _setup.isCollapse
    }
  }, [_c("div", {
    staticClass: "shuttle-button-header"
  }, [_c("el-tooltip", {
    attrs: {
      content: _setup.isCollapse ? "展开" : "收起",
      placement: "top"
    }
  }, [_c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      size: "small",
      square: "",
      icon: _setup.isCollapse ? "icon-menu-expansion" : "icon-menu-collapse"
    },
    on: {
      click: function ($event) {
        _setup.isCollapse = !_setup.isCollapse;
      }
    }
  })], 1)], 1), _c("div", {
    staticClass: "practice-process-content"
  }, [_vm.description ? _c("div", {
    staticClass: "process-tips-item"
  }, [_vm._m(0), _c("p", {
    staticClass: "process-tips-item__content"
  }, [_vm._v(_vm._s(_vm.description))])]) : _vm._e(), (_vm$metrics = _vm.metrics) !== null && _vm$metrics !== void 0 && _vm$metrics.length ? _c("div", {
    staticClass: "process-tips-item"
  }, [_vm._m(1), _c("div", {
    staticClass: "process-tips-item__content"
  }, _vm._l(_vm.metrics, function (dimension) {
    return _c("div", {
      key: dimension.dimension_id,
      staticClass: "dimention"
    }, _vm._l(dimension.metrics, function (metric) {
      return _c("div", {
        key: metric.metrics_id,
        staticClass: "metric"
      }, [_c("h2", {
        staticClass: "metric-title"
      }, [_vm._v(" " + _vm._s(metric.metrics_name) + "(权重" + _vm._s(metric.weight) + ") ")]), _vm._l(metric.gists, function (gist, index) {
        return _c("div", {
          key: index,
          staticClass: "gist"
        }, [_c("span", [_vm._v(_vm._s(gist.content))])]);
      })], 2);
    }), 0);
  }), 0)]) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "process-tips-item__title"
  }, [_c("i", {
    staticClass: "iconfont icon-tickets"
  }), _c("span", [_vm._v("练习说明")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "process-tips-item__title"
  }, [_c("i", {
    staticClass: "iconfont icon-score-a"
  }), _c("span", [_vm._v("评分标准")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;