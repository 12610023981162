"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    type: String
  },
  data() {
    return {
      newValue: []
    };
  },
  computed: {
    noEditContent() {
      return this.options.filter(item => this.newValue.includes(item.value)).map(item => (item === null || item === void 0 ? void 0 : item.label) || item);
    }
  },
  watch: {
    value: {
      handler: function (value) {
        if (Array.isArray(value)) {
          this.newValue = value;
        } else {
          this.newValue = value ? [value] : [];
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
exports.default = _default;