"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersTreeSelect = _interopRequireDefault(require("@/components/MgTreeSelect/MembersTreeSelect"));
var _user = require("@/api/user");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AddDeveloperDialog',
  components: {
    MembersTreeSelect: _MembersTreeSelect.default
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const developersCount = (rule, value, callback) => {
      if (value && !value.length) {
        callback(new Error('请选择成员!'));
      } else if (value.length > 20) {
        callback(new Error('最多只能选择20个成员！'));
      } else {
        callback();
      }
    };
    return {
      placeholder: '请选择成员',
      formData: {
        developer: []
      },
      rules: {
        developer: [{
          validator: developersCount,
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    getDepartmentAndMemberTree() {
      return _user.getDepartmentAndMemberTree;
    }
  },
  methods: {
    departmentAndMemberChange(_ref) {
      let {
        user_ids
      } = _ref;
      this.formData.developer = user_ids;
    },
    handleClose() {
      this.closeAddDeveloperDialog();
    },
    closeAddDeveloperDialog() {
      this.$emit('closeAddDeveloperDialog', false);
      this.$refs['members-tree-select'].clear();
      this.$refs.form.resetFields();
    },
    confirmAddDeveloper() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirmAddDeveloper', this.formData.developer);
          this.closeAddDeveloperDialog();
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;