"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    isTeamPath: {
      type: Boolean,
      default: false
    },
    keyEventNames: {
      type: Array,
      default: () => []
    },
    tableTotalStep: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      chart: null,
      options: {}
    };
  },
  computed: {
    // 根据最大步数拼接table表头
    tableHeads() {
      const tableHeads = [{
        label: this.isTeamPath ? '' : '销售名称',
        field_name: this.isTeamPath ? 'name' : 'user_name'
      }];
      for (let i = 0; i < this.tableTotalStep; i++) {
        tableHeads.push({
          label: `第${(0, _commonFunc.toChineseNumeral)(i + 1)}步`,
          field_name: 'path'
        });
      }
      if (this.isTeamPath) {
        tableHeads.push({
          field_name: 'percent'
        });
      }
      return tableHeads;
    }
  },
  methods: {
    // 获取关键事件背景颜色
    getEventNameBackground(name) {
      const eventBackgroundColors = ['#E6F2FF', '#EBFAF4', '#FFF6E6', '#ECEEFF', '#E6FDFF', '#FFFCE6', '#FCE9E9', '#ECFFE6', '#FEE7EF', '#FFEBE6', '#EEE9FB', '#E6FFFA', '#F9E9FB'];
      const keyEventIndex = this.keyEventNames.indexOf(name);
      const colorIndex = keyEventIndex - eventBackgroundColors.length * Math.floor(keyEventIndex / eventBackgroundColors.length);
      return eventBackgroundColors[colorIndex];
    },
    getKeyEventProportion(val) {
      if (val > 0.001 || val === 0) {
        return (val * 100).toFixed(2) + '%';
      } else if (val > 0.000001) {
        return (val * 100).toFixed(4) + '%';
      } else {
        return 0.0001 + '%';
      }
    }
  }
};
exports.default = _default;