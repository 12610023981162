"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _SaveTimeTip = _interopRequireDefault(require("@/components/NoteEditor/components/SaveTimeTip"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'VideoTemplates',
  components: {
    SaveTimeTip: _SaveTimeTip.default
  },
  props: {
    noteEmpty: Boolean,
    saveTime: String
  },
  data() {
    return {
      templatesList: [] // 笔记模板列表
    };
  },

  methods: {
    // 获取笔记模板列表
    async getNoteTemplateList() {
      const res = await (0, _videoDetail.getNoteTemplateList)();
      if (res.code === 200) {
        this.templatesList = res.results.templates;
      }
    },
    // 点击使用此模板按钮
    async insertTemplate(data) {
      // 模板内容出现在笔记上
      this.$emit('showNote', data.content);
    }
  }
};
exports.default = _default;