"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "options"
  }, [_c(_setup.MgDraggable, {
    on: {
      end: _setup.handleDragEnd
    },
    model: {
      value: _setup.localOptions,
      callback: function ($$v) {
        _setup.localOptions = $$v;
      },
      expression: "localOptions"
    }
  }, _vm._l(_setup.localOptions, function (option, index) {
    return _c("div", {
      key: option.id,
      staticClass: "option-row"
    }, [_c("i", {
      staticClass: "iconfont move icon-drag-dot"
    }), _c("div", {
      staticClass: "input-container"
    }, [_c("el-input", {
      class: {
        "has-error": _setup.isDuplicate(option.name, index)
      },
      attrs: {
        placeholder: _setup.t("coaching.请输入分类名称，最多20个字"),
        maxlength: "20"
      },
      on: {
        blur: function ($event) {
          return _setup.handleBlur(option);
        }
      },
      model: {
        value: option.name,
        callback: function ($$v) {
          _vm.$set(option, "name", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "option.name"
      }
    }), _c("div", {
      staticClass: "error-tip"
    }, [_vm._v(_vm._s(_setup.t("coaching.该名称已重复")))])], 1), _c("MgvButton", {
      attrs: {
        type: "text",
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          return _setup.removeOption(option, index);
        }
      }
    })], 1);
  }), 0)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;