"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _workspace = require("@/api/workspace");
var _default = {
  name: 'TransferConversationDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    workspaceItem: {
      type: Object,
      default: () => ({})
    },
    conversationCount: {
      type: [Number, String],
      default: 0
    },
    recycleConvCount: {
      type: Number,
      default: 0
    },
    isSingle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonLoading: false,
      rules: {
        workspaceId: [{
          required: true,
          message: '请选择空间',
          trigger: 'change'
        }]
      },
      formData: {
        workspaceId: ''
      },
      workspaceList: []
    };
  },
  computed: {
    text() {
      if (isNaN(this.conversationCount)) {
        return this.conversationCount;
      }
      return this.conversationCount + '条';
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getTransferWorkspace();
      } else {
        this.reset();
      }
    }
  },
  methods: {
    async getTransferWorkspace() {
      this.workspaceList = [];
      const res = await (0, _workspace.getTransferWorkspace)();
      if (res.code === 20000) {
        // 会话不可以转移到培训空间，培训空间的 type = 1
        this.workspaceList = res.results.list.filter(item => !item.type);
      }
    },
    goDepartmentAndMember() {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.departmentManagement
      });
      window.open(routeData.href, '_blank');
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.reset();
    },
    reset() {
      this.formData.workspaceId = '';
      this.$nextTick(() => {
        this.$refs['info-form'].clearValidate();
      });
    },
    submit() {
      this.$refs['info-form'].validate(valid => {
        if (valid) {
          this.buttonLoading = true;
          this.$emit('submit', this.formData.workspaceId, this.workspaceList.find(item => item.id === this.formData.workspaceId));
        }
      });
    },
    endButtonLoading() {
      this.buttonLoading = false;
    }
  }
};
exports.default = _default;