"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _message = require("@/api/message");
var _vuex = require("vuex");
var _StructuringTemplate = _interopRequireDefault(require("./components/layout/StructuringTemplate.vue"));
var _BriefReportDetail = _interopRequireDefault(require("./components/BriefReportDetail.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _FilterTab = _interopRequireDefault(require("@/components/FilterTab"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
notify_report_all = 1         # 简报类            大类（type）
notify_daily = 1              # 简报-日报         小类（sub_type）
notify_weekly = 2             # 简报-周报         小类
notify_monthly = 3            # 简报-月报         小类

notify_comment_all = 2        # 评论类            大类
notify_reply = 1              # 评论-回复         小类
notify_appoint = 2            # 评论-@我          小类
notify_comment_general = 3    # 评论-普通评论      小类

notify_coach_all = 3          # 辅导类            大类
notify_coach_apply = 1        # 辅导-申请辅导      小类
notify_coach_suggestions = 2  # 辅导-辅导建议      小类

notify_task_all = 4           # 任务类             大类
notify_task_perform = 1       # 任务-执行任务通知   小类
notify_task_completed = 2     # 任务-完成任务通知   小类

notify_todo_all = 5           # todo类             大类

notify_share_all = 6          # 分享类              大类
notify_share_conversation = 1 # 目前只有会话分享  1个小类
 */
var _default = {
  name: 'MessageList',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default,
    StructuringCardTemplate: _StructuringTemplate.default,
    BriefReportDetail: _BriefReportDetail.default,
    FilterTab: _FilterTab.default,
    NoData: _NoData.default
  },
  data() {
    return {
      msgList: [],
      hasMore: true,
      cursor: '',
      size: 10,
      loading: false,
      tabList: [{
        name: this.$t('coaching.all'),
        value: '',
        icon: 'bell',
        subTabList: [],
        count: 0
      }, {
        name: this.$t('coaching.notification'),
        value: 6,
        icon: 'menu-line',
        subTabList: [],
        count: 0
      }, {
        name: this.$t('coaching.review'),
        value: 2,
        count: 0,
        icon: 'chat-line-square',
        subTabList: [{
          name: '回复我',
          value: 1
        }, {
          name: '@我',
          value: 2
        }]
      }, {
        name: this.$t('coaching.tutor'),
        value: 3,
        icon: 'coaching',
        count: 0,
        subTabList: [{
          name: '申请辅导',
          value: 1
        }, {
          name: '辅导建议',
          value: 2
        }]
      }, {
        name: this.$t('coaching.bulletin'),
        value: 1,
        icon: 'document',
        count: 0,
        subTabList: [{
          name: '日报',
          value: 1
        }, {
          name: '周报',
          value: 2
        }, {
          name: '月报',
          value: 3
        }]
      }, {
        name: this.$t('coaching.task'),
        value: 4,
        count: 0,
        icon: 'task',
        subTabList: [{
          name: this.$t('coaching.enforcementNotice'),
          value: 1
        }, {
          name: this.$t('coaching.completionNotice'),
          value: 2
        }, {
          name: this.$t('coaching.expirationReminder'),
          value: 5
        }]
      }],
      activeTabItem: {
        name: this.$t('coaching.all'),
        value: '',
        icon: 'bell',
        subTabList: []
      },
      activeSubTabValue: '',
      currentView: 'list',
      // 当前视图，list和detail两种视图
      activeMessageId: '',
      // 跳转简报详情时需要的消息id
      detailType: '',
      activeMessageType: '',
      // 跳转简报详情时需要的消息大类
      activeMessageSubType: '',
      // 跳转简报详情时需要的消息子类型
      briefReportDetailInfo: null,
      detailLoading: false,
      detailHasPrev: false,
      detailHasNext: false,
      backTopHeight: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg'])
  },
  created() {
    this.getUnreadCount();
  },
  mounted() {
    this.$nextTick(() => {
      this.backTopHeight = this.$refs.contentDom.offsetHeight;
    });
  },
  methods: {
    tabClick(tab) {
      this.activeTabItem = tab;
      if (this.activeSubTabValue) {
        this.$refs.filterTab.init();
      } else {
        this.initData();
        this.getMessageCardData();
      }
      this.currentView = 'list';
    },
    subTabClick(value) {
      this.activeSubTabValue = value;
      this.initData();
      this.getMessageCardData();
    },
    getMessageCardData() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        cursor: this.cursor,
        size: this.size,
        message_type: this.activeTabItem.value,
        message_sub_type: this.activeSubTabValue
      };
      // 保存下请求数据时tab值，与请求后取值时tab值进行比较，避免在切换中渲染了上一次请求数据
      const beforeTabValue = this.activeTabItem.value.toString() + this.activeSubTabValue.toString();
      (0, _message.getMessageCardData)(params).then(res => {
        const data = res.results;
        data.card_list.forEach(item => {
          if (item.message_type === 4) {
            var _item$layout, _item$layout$card_hea, _item$layout$card_hea2;
            const ele = (_item$layout = item.layout) === null || _item$layout === void 0 ? void 0 : (_item$layout$card_hea = _item$layout.card_header) === null || _item$layout$card_hea === void 0 ? void 0 : (_item$layout$card_hea2 = _item$layout$card_hea.render_info) === null || _item$layout$card_hea2 === void 0 ? void 0 : _item$layout$card_hea2.left_elements[1];
            if (ele) {
              ele.value = this.$t(`coaching.${ele.value}`);
            }
          }
        });
        const afterTabValue = this.activeTabItem.value.toString() + this.activeSubTabValue.toString();
        if (beforeTabValue === afterTabValue) {
          this.msgList.push(...data.card_list);
          this.hasMore = data.has_more;
          this.cursor = data.cursor;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    initData() {
      this.msgList = [];
      this.loading = false;
      this.hasMore = true;
      this.cursor = '';
    },
    getReportDetailData(next) {
      const params = {
        cursor: this.activeMessageId,
        type: this.activeMessageType,
        sub_type: this.activeMessageSubType,
        next
      };
      this.detailLoading = true;
      (0, _message.getReportDetailData)(params).then(res => {
        const {
          id,
          message_sub_type,
          has_next,
          has_prev,
          timer_report_detail
        } = res.results;
        this.briefReportDetailInfo = timer_report_detail;
        this.activeMessageId = id;
        this.detailHasPrev = has_prev;
        this.detailHasNext = has_next;
        this.detailType = message_sub_type;
      }).finally(() => {
        this.detailLoading = false;
      });
    },
    goBriefReportDetail(messageId, messageType, messageSubType, next) {
      this.activeMessageId = messageId;
      this.activeMessageType = messageType;
      this.activeMessageSubType = messageSubType;
      this.currentView = 'detail';
      this.$refs.contentDom.scrollTop = 0;
      this.getReportDetailData(next);
    },
    goBackList() {
      this.briefReportDetailInfo = null;
      this.currentView = 'list';
    },
    getUnreadCount() {
      (0, _message.getUnreadCount)().then(res => {
        if (res.code === 20000) {
          const results = res.results;
          this.tabList.forEach(item => {
            if (item.value) {
              item.count = results[item.value];
            } else {
              item.count = results[0];
              this.$store.commit('user/SET_MESSAGE_UNREAD_COUNT', results);
            }
          });
        }
      });
    },
    async setReadMessage(data) {
      const res = await (0, _message.setReadMessage)(data);
      if (res.code === 20000) {
        this.initData();
        this.getUnreadCount();
        this.getMessageCardData();
        this.$message.success(this.$t('coaching.allAreSetToReadSuccessfully'));
      }
    },
    allRead() {
      const data = {
        message_type: this.activeTabItem.value || null
      };
      this.setReadMessage(data);
    }
  }
};
exports.default = _default;