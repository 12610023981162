"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyForTrial = applyForTrial;
exports.bindTencent = bindTencent;
exports.getAuthLinkOfWework = getAuthLinkOfWework;
exports.getAuthLinkTencent = getAuthLinkTencent;
exports.getBindName = getBindName;
exports.getBindStateOfWework = getBindStateOfWework;
exports.getConversationTimeline = getConversationTimeline;
exports.getIsBindTencent = getIsBindTencent;
exports.getJSConfig = getJSConfig;
exports.getMeetingInfo = getMeetingInfo;
exports.getTemplate = getTemplate;
exports.postAuthCodeAndGetBindInfo = postAuthCodeAndGetBindInfo;
exports.unbindTencent = unbindTencent;
exports.unbindWework = unbindWework;
var _request = require("@/utils/request");
// NOTE===================腾讯会议=====================
// 成员绑定授权信息
function bindTencent(data) {
  return (0, _request.request)({
    url: '/app/tencent_meeting/bind_access_token',
    method: 'post',
    data
  });
}
// 生成授权链接
function getAuthLinkTencent(data) {
  return (0, _request.request)({
    url: '/app/tencent_meeting/generate_auth_link',
    method: 'post',
    data
  });
}
// 成员绑定授权信息
function getIsBindTencent(params) {
  return (0, _request.webService)({
    url: '/app/tencent_meeting/is_bind',
    method: 'get',
    params
  });
}
// 取消绑定
function unbindTencent(data) {
  return (0, _request.request)({
    url: '/app/tencent_meeting/unbind',
    method: 'post',
    data
  });
}
function getJSConfig(auth_code) {
  return (0, _request.request)({
    url: '/app/tencent_meeting/get_js_config',
    method: 'get',
    params: {
      auth_code
    }
  });
}
function getMeetingInfo(meeting_id) {
  return (0, _request.request)({
    url: 'app/tencent_meeting/get_meeting_info',
    method: 'get',
    params: {
      meeting_id
    }
  });
}
function getConversationTimeline(entity_id, entity_type, page, size) {
  return (0, _request.request)({
    url: 'app/tencent_meeting/timeline',
    method: 'get',
    params: {
      entity_id,
      entity_type,
      page,
      size
    }
  });
}

// 获取模板内容
function getTemplate() {
  return (0, _request.request)({
    url: 'app/tencent_meeting/get_template',
    method: 'get'
  });
}

// 申请试用
function applyForTrial(data) {
  return (0, _request.request)({
    url: 'official/trial',
    method: 'post',
    data
  });
}

// 获取授权用户在腾讯会议的昵称和公司名
function getBindName() {
  return (0, _request.request)({
    url: 'app/tencent_meeting/get/extra_info',
    method: 'get'
  });
}

// NOTE===================企业微信=====================

// 获取绑定状态
function getBindStateOfWework() {
  return (0, _request.request)({
    url: '/app/we_work/get_wework_user_bing',
    method: 'get'
  });
}

// 企微登录url
function getAuthLinkOfWework(params) {
  return (0, _request.request)({
    url: '/app/we_work/generate_auth_link',
    method: 'get',
    params
  });
}

// 获取授权用户在企业微信的昵称和公司名
function postAuthCodeAndGetBindInfo(data) {
  return (0, _request.request)({
    url: '/app/we_work/get_wework_user_info',
    method: 'post',
    data
  });
}

// 取消绑定企业微信
function unbindWework(data) {
  return (0, _request.request)({
    url: '/app/we_work/relieve_wework_user_bing',
    method: 'post',
    data
  });
}