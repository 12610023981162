"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _xlsx = _interopRequireDefault(require("xlsx"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'StageTable',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLargeScreen: false,
      tableHeader: [{
        name: '交易阶段',
        prop: 'stage_name'
      }, {
        name: '总交易数',
        prop: 'total',
        tooltip: '进入过本阶段的所有交易数'
      }, {
        name: '交易金额',
        prop: 'amount',
        tooltip: '进入过本阶段的所有交易的金额之和'
      }, {
        name: '平均停留时长',
        prop: 'avg_stay_time',
        tooltip: '交易在本阶段停留的时长总和 / 进入过本阶段的交易总数'
      }, {
        name: '总转化率',
        prop: 'conversion_rat',
        tooltip: '本阶段的交易总数 / 第一阶段的交易总数 * 100%'
      }, {
        name: '流转交易数',
        prop: 'transfer_count',
        tooltip: '从当前阶段流转至下一阶段的所有交易数'
      }, {
        name: '流转率',
        prop: 'transfer_rate',
        tooltip: '流转交易数/交易总数'
      }, {
        name: '流失交易数',
        prop: 'loss_count',
        tooltip: '从当前阶段流转至输单或无效的所有交易数'
      }, {
        name: '流失率',
        prop: 'loss_rate',
        tooltip: '流失交易数 / 交易总数'
      }, {
        name: '停留交易数',
        prop: 'stay_count',
        tooltip: '目前停留在本阶段的所有交易数'
      }, {
        name: '平均客单价',
        prop: 'avg_price',
        tooltip: '赢单金额 / 赢单交易数'
      }, {
        name: '销售周期',
        prop: 'deal_period',
        tooltip: '赢单的交易从创建时间到赢单时间的平均耗时'
      }, {
        name: '赢单率',
        prop: 'win_rate',
        tooltip: '赢单交易数 / 第一阶段的交易总数 * 100%'
      }]
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.calcIsLargeScreen();
    window.onresize = () => {
      this.calcIsLargeScreen();
    };
  },
  methods: {
    calcIsLargeScreen() {
      const wrap = this.$refs.wrap;
      this.isLargeScreen = wrap && this.$refs.wrap.clientWidth > 1670;
    },
    getWidth(item) {
      return item.name === '交易金额' || item.name === '平均停留时长' ? 170 : 120;
    },
    exportXlsx() {
      const arr = this.data.map(item => Object.values(item));
      const header = this.tableHeader.map(item => item.name);
      arr.unshift(header);
      arr.unshift(['交易阶段分析']);
      const worksheet = _xlsx.default.utils.aoa_to_sheet(arr);
      const workbook = {
        SheetNames: ['stage'],
        Sheets: {
          stage: worksheet
        }
      };
      const wpts = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      };
      const workbookOut = _xlsx.default.write(workbook, wpts);
      const blob = new Blob([s2ab(workbookOut)], {
        type: 'application/octet-stream'
      });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      (0, _download.downloadBlob)(blob, `交易阶段分析.xlsx`);
    }
  }
};
exports.default = _default;