"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.getDialogueListLoading || _setup.getKeyScriptReviewLoading,
      expression: "getDialogueListLoading || getKeyScriptReviewLoading"
    }],
    staticClass: "container"
  }, [_c("el-tabs", {
    on: {
      "tab-click": function ($event) {
        return _setup.emit("scrollToTop");
      }
    },
    model: {
      value: _setup.activeName,
      callback: function ($$v) {
        _setup.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: _setup.t("coaching.comprehensiveEvaluation"),
      name: "overallReview",
      lazy: ""
    }
  }, [_c("div", {
    staticClass: "analysis-container"
  }, [_c("div", {
    staticClass: "comments"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-ai-assistant-fill"
  }), _c("span", [_vm._v(_vm._s(_setup.t("coaching.aIReview")) + "：")])]), _c("ul", {
    staticClass: "comments-list"
  }, [!_setup.commentsList.length ? _c("li", [_vm._v(" " + _vm._s(_setup.t("coaching.noCommentsAvailable")) + " ")]) : _vm._e(), _vm._l(_setup.commentsList, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item))])]);
  })], 2)]), _setup.abilityAnalysis.length ? _c("div", {
    staticClass: "comments"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-analysis-3-fill"
  }), _c("span", [_vm._v(_vm._s(_setup.t("coaching.abilityAnalysis")) + "：")])]), _c("ul", {
    staticClass: "comments-list"
  }, _vm._l(_setup.abilityAnalysis, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item || "暂无分析"))])]);
  }), 0)]) : _vm._e()])]), _c("el-tab-pane", {
    attrs: {
      label: _setup.t("coaching.keyScripts"),
      name: "keyScriptReview",
      lazy: ""
    }
  }, [_vm.courseType === 6 ? _c(_setup.KeyScriptReview, {
    attrs: {
      "scene-list": _setup.sceneList
    }
  }) : _c(_setup.KeyScriptReviewNoScene, {
    attrs: {
      "script-list": _setup.scriptList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: _setup.t("coaching.dialogueRecord"),
      name: "dialogueList",
      lazy: ""
    }
  }, [_c(_setup.DialogueList, {
    attrs: {
      "conversation-list": _setup.conversationList,
      "customer-avatar": _setup.customerAvatar,
      narration: _setup.narration,
      "task-type": 5
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;