"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TricksPractice = _interopRequireDefault(require("./TricksPractice"));
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PracticeKnowledgeDrawer',
  components: {
    TricksPractice: _TricksPractice.default
  },
  provide() {
    return {
      getPracticeDisabled: () => true
    };
  },
  props: {
    visible: Boolean,
    userId: {
      type: Number,
      default: 0
    },
    knowledgeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      knowledge: {},
      loading: false,
      showIntelligentReview: false
    };
  },
  watch: {
    knowledgeId: {
      handler(val) {
        this.getPracticeDetail(val);
      }
    }
  },
  created() {
    const taskConfig = JSON.parse(sessionStorage.getItem('task_practice_query') || '{}');
    // 话术朗读展示销售回答和智能评分
    this.showIntelligentReview = Number(taskConfig.type) === 2;
  },
  methods: {
    closeDrawer() {
      this.$emit('update:visible', false);
    },
    getKnowledge(direction) {
      var _this$knowledge;
      this.getPracticeDetail((_this$knowledge = this.knowledge) === null || _this$knowledge === void 0 ? void 0 : _this$knowledge[direction + '_id']);
    },
    async getPracticeDetail(id) {
      const params = {
        plan_knowledge_id: id,
        user_id: this.userId
      };
      this.loading = true;
      const res = await (0, _coachingCenter.getPracticeDetail)(params).finally(() => {
        this.loading = false;
      });
      this.knowledge = res.results;
    }
  }
};
exports.default = _default;