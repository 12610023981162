"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _useMessage = require("@/hooks/use-message");
var _default = {
  __name: 'SavePromptDialog',
  props: {
    visible: Boolean,
    prompt: String
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const formData = (0, _vue.ref)({
      name: ''
    });
    const rules = {
      name: [{
        required: true,
        message: '请输入模版名称',
        trigger: 'blur'
      }]
    };
    const createBtnLoading = (0, _vue.ref)(false);
    const formRef = (0, _vue.ref)(null);
    const createTemplate = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          createBtnLoading.value = true;
          const res = await (0, _rapidCreateModel.createPromptTemplate)({
            name: formData.value.name,
            content: props.prompt
          }).finally(() => createBtnLoading.value = false);
          if (res.code === 20000) {
            _useMessage.useMessage.success('保存成功');
            updateVisible();
          }
        }
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      formData,
      rules,
      createBtnLoading,
      formRef,
      createTemplate
    };
  }
};
exports.default = _default;