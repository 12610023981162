"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotationEvent = useAnnotationEvent;
exports.useCreateKeyEventFolder = useCreateKeyEventFolder;
exports.useDeleteEvent = useDeleteEvent;
exports.useEditEvent = useEditEvent;
exports.useMoveEvent = useMoveEvent;
exports.usePublishEvent = usePublishEvent;
exports.useSetKeyEventApplyPermission = useSetKeyEventApplyPermission;
exports.useSetModel = useSetModel;
var _vue = require("vue");
var _keyEventManagement = require("@/api/keyEventManagement");
function useMoveEvent(currentRow) {
  const moveEventVisible = (0, _vue.ref)(false);
  const onMoveEvent = row => {
    moveEventVisible.value = true;
    currentRow.value = row;
  };
  return {
    moveEventVisible,
    onMoveEvent
  };
}
function useSetModel(currentRow) {
  const setModelVisible = (0, _vue.ref)(false);
  const onSetModel = row => {
    setModelVisible.value = true;
    currentRow.value = row;
  };
  return {
    setModelVisible,
    onSetModel
  };
}
function useEditEvent(setModelInfo, goCreate) {
  const onEditEvent = row => {
    setModelInfoCommon(setModelInfo, row, {
      step: 1
    });
    goCreate();
  };
  return {
    onEditEvent
  };
}
function useAnnotationEvent(setModelInfo, goCreate) {
  const onAnnotationEvent = row => {
    setModelInfoCommon(setModelInfo, row, {
      step: 2
    });
    goCreate();
  };
  return {
    onAnnotationEvent
  };
}
function usePublishEvent(setModelInfo) {
  const publishEventVisible = (0, _vue.ref)(false);
  const onPublishEvent = row => {
    setModelInfoCommon(setModelInfo, row);
    publishEventVisible.value = true;
  };
  return {
    publishEventVisible,
    onPublishEvent
  };
}
function useDeleteEvent(workspaceId, getTableData) {
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const onDeleteEvent = row => {
    const msg = `<div class="first-part">您确定删除此自定义关键事件吗？</div>
      <div class="second-part">该关键事件相关的所有数据将被一起删除。</div>`;
    proxy.$confirm(msg, '删除', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '删除',
      confirmButtonClass: 'el-button--danger',
      cancelButtonText: '取消',
      customClass: 'key-event-delete key-event',
      type: 'info'
    }).then(async () => {
      const res = await (0, _keyEventManagement.deleteEvent)({
        event_id: row.id,
        workspace_id: workspaceId.value
      });
      if (res.code === 20000) {
        proxy.$message.success('删除成功');
        getTableData();
      }
    }).catch(() => {});
  };
  return {
    onDeleteEvent
  };
}
function useSetKeyEventApplyPermission() {
  const setKeyEventApplyPermissionVisible = (0, _vue.ref)(false);
  const eventIds = (0, _vue.ref)([]);
  const isBatch = (0, _vue.ref)(false);
  const isSystemEvent = (0, _vue.ref)(false);
  const hasSetModel = (0, _vue.ref)(false);
  const hasEnableKeyEvent = (0, _vue.ref)(false);
  const openSetKeyEventApplyPermissionDrawer = () => {
    setKeyEventApplyPermissionVisible.value = true;
  };
  const onSetKeyEventApplyPermission = row => {
    isBatch.value = false;
    eventIds.value = [row.id];
    isSystemEvent.value = row.event_type === 'system';
    hasSetModel.value = !!row.model_id;
    openSetKeyEventApplyPermissionDrawer();
    hasEnableKeyEvent.value = row.status === 2;
  };
  const batchSetKeyEventApplyPermission = batchEventsList => {
    eventIds.value = batchEventsList.map(item => item.id);
    hasSetModel.value = batchEventsList.every(item => item.model_id);
    hasEnableKeyEvent.value = batchEventsList.every(item => item.status === 2);
    isBatch.value = true;
    isSystemEvent.value = false;
    openSetKeyEventApplyPermissionDrawer();
  };
  return {
    isBatch,
    eventIds,
    isSystemEvent,
    hasSetModel,
    hasEnableKeyEvent,
    setKeyEventApplyPermissionVisible,
    onSetKeyEventApplyPermission,
    openSetKeyEventApplyPermissionDrawer,
    batchSetKeyEventApplyPermission
  };
}
function setModelInfoCommon(setModelInfo, row, extra) {
  const urlParams = getURLSearch();
  setModelInfo({
    id: row.id.slice(1) - 0,
    version: row.version,
    type: row.event_cate,
    isNew: row.new_custom,
    status: row.status,
    name: row.name,
    ...urlParams,
    ...extra
  });
}
function getURLSearch() {
  const params = new URLSearchParams(location.search);
  const folderId = params.get('id');
  const folderName = params.get('name');
  return {
    folderId,
    folderName
  };
}
function useCreateKeyEventFolder() {
  const createFolderPid = (0, _vue.ref)(0);
  const createKeyEventFolderDialogVisible = (0, _vue.ref)(false);
  const openCreateKeyEventFolderDialog = fid => {
    createFolderPid.value = fid;
    createKeyEventFolderDialogVisible.value = true;
  };
  return {
    createFolderPid,
    openCreateKeyEventFolderDialog,
    createKeyEventFolderDialogVisible
  };
}