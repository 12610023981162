"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      visible: _vm.visible,
      width: "400px",
      "close-on-click-modal": false,
      title: "新建文件夹"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c(_setup.CustomEventInput, {
    ref: "nameInputRef",
    staticClass: "key-event-name",
    model: {
      value: _setup.folderName,
      callback: function ($$v) {
        _setup.folderName = $$v;
      },
      expression: "folderName"
    }
  })], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  }, [_vm._v("取消")]), _c("MgvButton", {
    attrs: {
      loading: _setup.btnLoading
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("创建")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;