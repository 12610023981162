"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "distribution-content"
  }, [_c("detail-header", {
    attrs: {
      title: "新建任务",
      "back-button": ""
    }
  }), _c("div", {
    staticClass: "main-content"
  }, [_c("FormItemCard", {
    staticClass: "basic-information",
    attrs: {
      title: "基础信息"
    }
  }, [_c("el-form", {
    ref: "basicInformationForm",
    attrs: {
      model: _vm.basicInformationForm,
      "hide-required-asterisk": ""
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务名称",
      prop: "name",
      rules: [{
        required: true,
        message: "任务名称不能为空"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入内容",
      maxlength: 20
    },
    model: {
      value: _vm.basicInformationForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.basicInformationForm, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "basicInformationForm.name"
    }
  })], 1)], 1)], 1), _c("ComplianceItem", {
    ref: "ComplianceItem",
    staticClass: "compliance-item",
    attrs: {
      "form-data": _vm.localForm.complianceItem
    }
  }), _c("SampleScopeForm", {
    staticClass: "sample-scope-form",
    attrs: {
      unit: _vm.unit,
      "form-data": _vm.localForm.sampleScope,
      "compliance-item": _vm.localForm.complianceItem
    },
    on: {
      getSampleConvScopeCount: function ($event) {
        _vm.sampleConvScopeCount = $event;
      }
    }
  }), _c("SampleMethod", {
    ref: "sampleMethod",
    staticClass: "sample-method",
    attrs: {
      unit: _vm.unit,
      "sample-scope": _vm.localForm.sampleScope,
      "sample-method": _vm.localForm.sampleMethod,
      "sample-rule": _vm.sampleRule,
      "sample-conv-scope-count": _vm.sampleConvScopeCount
    },
    on: {
      sampleComplete: _vm.getConvInfo
    }
  }), _c("FormItemCard", {
    staticClass: "select-member",
    attrs: {
      title: "选择质检员"
    }
  }, [_c("members-tree-select", {
    attrs: {
      placeholder: "质检员",
      "request-function": _vm.getDataOrgMemberTree,
      "request-search-function": _vm.searchUserFuntion,
      "request-search-params": {
        module_type: "compliance"
      },
      "request-params": {
        type: JSON.stringify(["compliance"])
      },
      "search-params-no-space": "",
      "backfill-list": _vm.localForm.assignMembersList
    },
    on: {
      change: _vm.assigneeChange
    }
  })], 1)], 1), _c("div", {
    staticClass: "footer-content"
  }, [_c("el-button", {
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.distributionButtonDisabled,
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.distributeConv
    }
  }, [_vm._v("完成新建")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;