"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'HorizontalScroll',
  setup(__props) {
    const scrollContainer = (0, _vue.ref)(null);
    const scrollContent = (0, _vue.ref)(null);
    const showLeftArrow = (0, _vue.ref)(false);
    const showRightArrow = (0, _vue.ref)(true);
    const handleScroll = () => {
      const content = scrollContent.value;
      showLeftArrow.value = content.scrollLeft > 0;
      showRightArrow.value = content.scrollLeft < content.scrollWidth - content.clientWidth;
    };
    const scrollLeft = () => {
      const content = scrollContent.value;
      content.scrollTo({
        left: content.scrollLeft - content.clientWidth < 0 ? 0 : content.scrollLeft - content.clientWidth,
        behavior: 'smooth'
      });
    };
    const scrollRight = () => {
      const content = scrollContent.value;
      // 滚动到右侧
      content.scrollTo({
        left: content.scrollLeft + content.clientWidth - 72,
        behavior: 'smooth'
      });
    };
    (0, _vue.onMounted)(() => {
      const content = scrollContent.value;
      setTimeout(() => {
        handleScroll();
        content && content.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
      }, 1000);
    });
    (0, _vue.onUnmounted)(() => {
      const content = scrollContent.value;
      content && content.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    });
    return {
      __sfc: true,
      scrollContainer,
      scrollContent,
      showLeftArrow,
      showRightArrow,
      handleScroll,
      scrollLeft,
      scrollRight
    };
  }
};
exports.default = _default;