"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _default = {
  name: 'EditKanbanNameDialog',
  props: {
    title: {
      type: String,
      default: '复制'
    },
    kanbanName: {
      type: String,
      default: ''
    },
    handleFn: {
      type: Function,
      default: _businessIntelligence.universalCopyKanban
    },
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      config: {
        name: ''
      },
      handleLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.config.name = this.kanbanName;
      }
    }
  },
  methods: {
    handleClose() {
      this.$refs['form'].resetFields();
      this.$emit('update:visible', false);
    },
    submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.handleLoading = true;
          const res = await this.handleFn({
            ...this.params,
            ...this.config,
            workspace_id: this.workspaceInfo.id
          }).finally(() => {
            this.handleLoading = false;
          });
          if (res.code === 20000) {
            this.$message.success(`${this.title}成功`);
            // 此处返回复制后的看板信息，前端无刷新添加看板
            this.$emit('copyKanbanSuccess', res.results.kanban_id);
            this.handleClose();
          }
        }
      });
    }
  }
};
exports.default = _default;