"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'index',
  setup(__props) {
    return {
      __sfc: true
    };
  }
};
exports.default = _default;