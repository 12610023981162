"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clue-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "clueTable",
    attrs: {
      data: _vm.list,
      height: _vm.tableHeight,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "selection-change": _vm.handleSelectionChange,
      "row-click": _vm.handleRowClick,
      "sort-change": function ($event) {
        return _vm.handleSortChange($event, "clue");
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "75",
      selectable: _vm.selectable
    }
  }), _vm._l(_vm.headerList, function (item, index) {
    return _c("el-table-column", {
      key: index + item.field_name,
      ref: `clue-${item.field_name}`,
      refInFor: true,
      attrs: {
        label: item.name,
        prop: item.field_name,
        formatter: _vm.formatterCellValue,
        "show-overflow-tooltip": "",
        "min-width": "120px",
        width: _vm.setColumnWidth(item.field_name),
        sortable: _vm.customSortable(item.field_name),
        "sort-orders": ["descending", "ascending"]
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("span", {
            staticClass: "header-name"
          }, [_vm._v(_vm._s(item.name))])];
        },
        proxy: true
      }, item.field_type === "radio" || item.field_type === "checkbox" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_vm._v(" " + _vm._s(row[item.field_name] ? row[item.field_name].toString() : "-") + " ")];
        }
      } : item.field_name === "status" || item.field_name === "follow_up_by_name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [item.field_name === "status" ? [_c("el-tag", {
            attrs: {
              size: "small",
              type: row.status === "unconverted" ? "info" : "success"
            }
          }, [_vm._v(_vm._s(row.status === "unconverted" ? "未转换" : "已转换"))])] : _vm._e(), item.field_name === "follow_up_by_name" ? [_c("div", {
            staticClass: "follow-up-by"
          }, [_c("image-avatar", {
            attrs: {
              name: row.follow_up_by_name,
              avatar: row.follow_up_by_avatar
            }
          }), _c("span", [_vm._v(_vm._s(row.follow_up_by_name || "-"))])], 1)] : _vm._e()];
        }
      } : null], null, true)
    });
  }), _vm.operateColumnVisible ? _c("el-table-column", {
    attrs: {
      label: "操作",
      prop: "operate",
      width: _vm.operateColumnWidth,
      fixed: "right",
      "class-name": "operate-column"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === "unconverted" ? _c("div", {
          staticClass: "operate-column-cell"
        }, [_c("clue-operate-buttons", {
          attrs: {
            "operate-buttons": _vm.operateButtons,
            "row-info": scope.row,
            "active-id": _vm.activeClueId,
            "is-in-row": ""
          },
          on: {
            clickContactPersonItem: _vm.clickContactPersonItem,
            changeactiveClueId: _vm.changeactiveClueId
          }
        }), _c("el-tooltip", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/lead/lead/lead_to_account"],
            expression: "['/api/lead/lead/lead_to_account']"
          }],
          attrs: {
            content: "线索转客户",
            placement: "top",
            "open-delay": 400,
            "popper-class": "clue-to-custom-tooltip"
          }
        }, [_c("div", {
          staticClass: "btn"
        }, [_c("el-button", {
          directives: [{
            name: "preventReClick",
            rawName: "v-preventReClick"
          }],
          staticClass: "el-button-text--primary",
          attrs: {
            circle: "",
            disabled: !scope.row.has_edit
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.clueTranslateAccount(scope.row);
            }
          }
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "transform"
          }
        })], 1)], 1)]), _vm.dropdownVisible ? _c("el-dropdown", {
          staticClass: "btn",
          on: {
            command: function ($event) {
              return _vm.handleCommand(scope.row, $event);
            }
          }
        }, [_c("el-button", {
          staticClass: "el-button-text--primary",
          attrs: {
            circle: "",
            size: "small"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "more"
          }
        })], 1), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          directives: [{
            name: "api-permission",
            rawName: "v-api-permission",
            value: ["/api/lead/lead/edit_follow_up_by"],
            expression: "['/api/lead/lead/edit_follow_up_by']"
          }],
          attrs: {
            command: "changeFollowUpBy",
            disabled: !scope.row.has_edit
          }
        }, [_c("div", {
          staticClass: "drop-item"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": "change-follower"
          }
        }), _vm._v("变更跟进人 ")], 1)])], 1)], 1) : _vm._e()], 1) : _vm._e()];
      }
    }], null, false, 189445921)
  }) : _vm._e()], 2), _c("upload-media-dialog", {
    ref: "upload-media-dialog",
    attrs: {
      "page-type": _vm.pageType,
      "upload-media-dialog-visible": _vm.uploadMediaDialogVisible,
      "media-row-info": _vm.mediaRowInfo,
      "limit-count": 5,
      multiple: ""
    },
    on: {
      closeUploadMediaDialog: _vm.closeUploadMediaDialog
    }
  }), _vm.clueTranslateAccountDialogVisible ? _c("clue-translate-account-dialog", {
    attrs: {
      "clue-id": _vm.clueId,
      visible: _vm.clueTranslateAccountDialogVisible
    },
    on: {
      clueTranslateAccountSuccess: _vm.clueTranslateAccountSuccess,
      closeDialog: _vm.closeClueTranslateAccountDialog
    }
  }) : _vm._e(), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible
    },
    on: {
      closeDialog: function ($event) {
        _vm.meetingDialogVisible = false;
      }
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;