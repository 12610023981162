"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConstMap = require("@/components/Message/components/ConstMap.js");
var _default = {
  name: 'AvatarIcon',
  props: {
    renderInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    unread: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconValueMap: _ConstMap.ICONMAP
    };
  },
  methods: {
    getIconName(type) {
      return 'icon-' + _ConstMap.ICONMAP[type];
    }
  }
};
exports.default = _default;