"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "log-records-timeline"
  }, [_vm.isShow ? _c("mg-infinite-scroll", {
    staticClass: "content",
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, [_c("el-timeline", _vm._l(_vm.logRecords, function (item, index) {
    return _c("el-timeline-item", {
      key: index,
      attrs: {
        placement: "top",
        icon: _vm.getLogItemIcon(item.type),
        timestamp: _vm.formatLogItemTime(item.time)
      }
    }, [_c("div", {
      staticClass: "type-label"
    }, [_vm._v(_vm._s(_vm.getLogItemLabel(item.type)))]), _vm._t("default", null, {
      item: item
    })], 2);
  }), 1)], 1) : _c("figure", {
    staticClass: "empty-log"
  }, [_c("div", [_c("i", {
    staticClass: "iconfont icon-receiving-fill"
  })]), _c("p", [_vm._v("暂无操作日志")])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;