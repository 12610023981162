"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateInstructionDialog = _interopRequireDefault(require("./CreateInstructionDialog"));
var _InstructionTable = _interopRequireDefault(require("./InstructionTable"));
var _conversationList = require("@/api/conversationList");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const drawerOpened = (0, _vue.ref)(false);
    (0, _vue.watch)(() => props.visible, visible => {
      if (visible) {
        getExtractInfoInstructionListRequest();
      }
    });
    const closeDialog = () => {
      emit('update:visible', false);
      drawerOpened.value = false;
    };
    const tableData = (0, _vue.ref)([]);
    const tableLoading = (0, _vue.ref)(false);
    const getExtractInfoInstructionListRequest = async () => {
      tableLoading.value = true;
      const res = await (0, _conversationList.getExtractInfoInstructionList)({
        space_id: store.getters.workspaceInfo.id
      }).finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        tableData.value = res.results.items;
      }
    };
    const submitType = (0, _vue.ref)('create');
    const createInstructionDrawerVisible = (0, _vue.ref)(false);
    const addInstruction = () => {
      submitType.value = 'create';
      createInstructionDrawerVisible.value = true;
    };
    const activeInstruction = (0, _vue.ref)({});
    const editInstruction = row => {
      console.log(row);
      submitType.value = 'edit';
      activeInstruction.value = handleRowInfo(row);
      createInstructionDrawerVisible.value = true;
    };
    const handleRowInfo = row => {
      const {
        id,
        name,
        prompt,
        content,
        departments,
        space_id
      } = row;
      const tree_ids = departments.map(item => item.id);
      return {
        id,
        name,
        prompt,
        content,
        space_id,
        tree_ids
      };
    };
    const deleteInstruction = (row, index) => {
      // 删除前的再次确认
      _elementUi.MessageBox.confirm('是否确认删除该指令？', '删除指令', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        // 请求删除接口
        (0, _conversationList.deleteExtractInfoInstruction)({
          ids: [row.id]
        }).then(res => {
          if (res.code === 20000) {
            console.log('deleteInstruction', index);
            tableData.value.splice(index, 1);
            (0, _elementUi.Message)({
              message: '删除成功',
              type: 'success'
            });
          }
        });
      });
    };
    return {
      __sfc: true,
      props,
      store,
      emit,
      drawerOpened,
      closeDialog,
      tableData,
      tableLoading,
      getExtractInfoInstructionListRequest,
      submitType,
      createInstructionDrawerVisible,
      addInstruction,
      activeInstruction,
      editInstruction,
      handleRowInfo,
      deleteInstruction,
      CreateInstructionDialog: _CreateInstructionDialog.default,
      InstructionTable: _InstructionTable.default
    };
  }
};
exports.default = _default;