"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'KnowledgeQuestionsFilter',
  data() {
    return {
      isAdd: 0,
      status: 'all',
      statusOptions: [{
        label: '全部问题',
        value: 'all'
      }, {
        label: '排名上升的问题',
        value: 'up'
      }, {
        label: '排名下降的问题',
        value: 'down'
      }, {
        label: '本周新增的问题',
        value: 'new'
      }],
      isAddedOptions: [{
        label: '未添加',
        value: 0
      }, {
        label: '添加过',
        value: 1
      }, {
        label: this.$t('coaching.all'),
        value: -1
      }]
    };
  },
  computed: {
    statusLabel() {
      return this.statusOptions.find(item => item.value === this.status).label;
    }
  },
  methods: {
    emitFilter(type, val) {
      this.$emit('change', type, val);
    }
  }
};
exports.default = _default;