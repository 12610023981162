"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _DataSetSelectors = _interopRequireDefault(require("./DataSetSelectors.vue"));
var _modelManagement = require("@/api/modelManagement");
var _useTrainSuperParams = require("../hooks/use-train-super-params");
var _TrainSuperParamTable = _interopRequireDefault(require("@/components/TrainSuperParamTable"));
var _TrainAndTestDataSet = _interopRequireDefault(require("@/views/management/rapidModeling/components/TrainAndTestDataSet.vue"));
var _ModelPredictionResult = _interopRequireDefault(require("@/views/management/rapidModeling/components/ModelPredictionResult.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateAiModelDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'createSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => emit('update:visible', false);
    const onClose = () => {
      modelInfo.value = {};
      formRef.value.clearValidate();
      console.log('onBeforeClose', modelInfo.value);
    };
    const formRef = (0, _vue.ref)(null);
    const modelInfo = (0, _vue.ref)({});
    const modelInfoDesc = [{
      id: 'name',
      label: '模型名称',
      required: true,
      type: 'text',
      maxlength: 20
    }, {
      id: 'description',
      label: '模型描述',
      type: 'textarea',
      maxlength: 500
    }, {
      id: 'use_to',
      label: '模型用途',
      default: 1
    }];
    const {
      paramsChange,
      onAutoTuningParams,
      autoTuningParams,
      trainParam,
      trainParamMode,
      trainSuperParamTableRef
    } = (0, _useTrainSuperParams.useTrainSuperParams)();
    const {
      isAutoTest,
      trainAndTestDatasetRef,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType,
      getTrainAndTrailDatasetData
    } = (0, _useTrainSuperParams.useTrainAndTestDatasetParams)();

    // 大模型后处理hooks
    const {
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      getPromptContent
    } = (0, _useTrainSuperParams.usePredictionResult)();
    (0, _vue.watch)(() => isAutoTest.value, val => {
      if (!val) {
        autoTuningParams.value = false;
      }
    });
    (0, _vue.watch)(() => props.visible, newVal => {
      if (newVal) {
        autoTuningParams.value = false;
      }
    });
    const createModelAsync = async () => {
      const valid = formRef.value.validate();
      if (!valid) return;
      if (autoTuningParams.value) {
        trainParam.value = trainSuperParamTableRef.value.initParams();
      } else {
        trainParamMode.value = 0;
      }

      // 模型预测结果模块
      const promptContent = getPromptContent();
      if (!promptContent) return;

      // 模型训练和测试
      const trainAndTestDatasetParams = getTrainAndTrailDatasetData();
      if (!trainAndTestDatasetParams) {
        return;
      }
      const {
        train_datasets,
        trial_datasets,
        dataset_split_type
      } = trainAndTestDatasetParams;
      const params = {
        ...modelInfo.value,
        use_to: 1,
        model_type: 3,
        train_datasets,
        trial_datasets,
        dataset_split_type,
        auto_trial: isAutoTest.value,
        train_param: trainParam.value,
        train_param_mode: trainParamMode.value,
        postprocess_type: postProcessType.value,
        postprocess: {
          prompt_info: {
            prompt_content: isEnableLLM.value ? promptContent : ''
          }
        },
        postprocess_llm_enable: isEnableLLM.value
      };
      const res = await (0, _modelManagement.createAiModel)(params);
      if (res.code === 20000) {
        _elementUi.Message.success('创建成功');
        emit('createSuccess', res.results.id);
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      onClose,
      formRef,
      modelInfo,
      modelInfoDesc,
      paramsChange,
      onAutoTuningParams,
      autoTuningParams,
      trainParam,
      trainParamMode,
      trainSuperParamTableRef,
      isAutoTest,
      trainAndTestDatasetRef,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType,
      getTrainAndTrailDatasetData,
      postProcessType,
      isEnableLLM,
      isEnableLLMChange,
      predictionResultRef,
      getPromptContent,
      createModelAsync,
      DynamicForm: _DynamicForm.default,
      TrainSuperParamTable: _TrainSuperParamTable.default,
      TrainAndTestDataSet: _TrainAndTestDataSet.default,
      ModelPredictionResult: _ModelPredictionResult.default
    };
  }
};
exports.default = _default;