"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = _interopRequireDefault(require("@/assets/default.svg"));
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PersonalInfo',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  data() {
    const nameLengthValidator = (rule, value, callback) => {
      if (value.length > 32) {
        callback(new Error(this.$t('management.请输入1-32个字符')));
      }
      callback();
    };
    return {
      userInfo: {
        name: '',
        email: '',
        avatar: '',
        loginAccount: '',
        loginMethod: '',
        organization: {}
      },
      code: '',
      randStr: '',
      avatarUrl: '',
      defaultImg: _default2.default,
      userInfoRules: {
        name: [{
          required: true,
          message: this.$t('management.姓名不能为空'),
          trigger: 'blur'
        }, {
          validator: nameLengthValidator
        }],
        email: [{
          validator: _validate.emailValidatorAllowNone
        }]
      },
      loading: false,
      isSetPassword: true,
      updatePasswordDialogVisible: false
    };
  },
  computed: {
    buttonDisabled() {
      return false;
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    uploadAvatar: _user.uploadAvatar,
    handleAvatarSuccess(res) {
      if (!res) return;
      if (res.code === 20000) {
        this.userInfo.avatar = res.results.prefix + res.results.avatar_url;
      } else {
        this.$message.warning('上传失败，请重试！');
      }
    },
    async getInfo() {
      const res = await (0, _user.getDetailInfo)();
      const {
        name,
        login_account: loginAccount,
        login_method: loginMethod,
        email,
        avatar,
        organization
      } = res.results;
      this.userInfo = {
        name,
        loginAccount,
        loginMethod,
        email,
        avatar,
        organization
      };
    },
    submit() {
      this.$refs.userInfo.validate(async valid => {
        if (valid) {
          // 由于服务端返回的avatar是带压缩参数的，所以需要删除此参数，才是该头像的真实url
          let avatar = '';
          if (this.userInfo.avatar) {
            const url = new URL(this.userInfo.avatar);
            avatar = url.origin + url.pathname;
          }
          const fetchData = {
            name: this.userInfo.name,
            email: this.userInfo.email,
            avatar
          };
          try {
            this.loading = true;
            const res = await (0, _user.updateDetailInfo)(fetchData);
            if (res.code === 20000) {
              this.$message.success(this.$t('management.修改信息成功'));
              this.$store.dispatch('user/getInfo');
            }
          } finally {
            this.loading = false;
          }
        }
      });
    },
    beforeAvatarUpload(file) {
      const isImg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isSizeFit = file.size / 1024 < 200;
      if (!isSizeFit) {
        this.$message.warning(this.$t('management.上传头像图片大小不能超过200KB!'));
      }
      if (!isImg) {
        this.$message.warning(this.$t('management.上传头像图片格式暂不支持!'));
      }
      return isImg && isSizeFit;
    }
  }
};
exports.default = _default;