"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'KnowledgeFilter',
  props: {
    knowledgeList: {
      type: Array,
      default: () => []
    },
    collapseTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultProps: {
        expandTrigger: 'hover',
        multiple: true,
        children: 'children',
        label: 'name',
        value: 'id'
      }
    };
  },
  methods: {
    handleChange(val) {
      const knowledgeIds = val.map(item => {
        return item[item.length - 1];
      });
      this.$emit('knowledgeChange', knowledgeIds);
    }
  }
};
exports.default = _default;