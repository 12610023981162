"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "compliance-personal-table"
  }, [_c("ComplianceBaseTable", _vm._g(_vm._b({
    attrs: {
      "table-header": _vm.tableHeader,
      "table-data": _vm.tableData,
      "table-height": _vm.tableHeight,
      "score-rule-list": _vm.scoreRuleList,
      "evaluation-item-list": _vm.evaluationItemList,
      total: _vm.total,
      "is-conv-table": ""
    },
    on: {
      refresh: function ($event) {
        return _vm.$emit("changeFilter");
      }
    }
  }, "ComplianceBaseTable", _vm.$attrs, false), _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;