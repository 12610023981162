"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "新建联系人",
      "append-to-body": "",
      "before-close": _setup.handleClose,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "custom-class": "create-contact-dialog-wrapper",
      width: "600px",
      visible: _vm.visible
    }
  }, [_c(_setup.DynamicForm, {
    ref: "createContactFormRef",
    attrs: {
      desc: _setup.contactFormDesc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "customer_name-label",
      fn: function () {
        return [_vm._v("关联客户")];
      },
      proxy: true
    }, {
      key: "customer_name",
      fn: function () {
        return [_c("el-select", {
          attrs: {
            filterable: "",
            remote: "",
            placeholder: "请输入客户名称",
            "remote-method": _setup.searchAccountApi,
            clearable: ""
          },
          model: {
            value: _setup.contactFormData.customer_name,
            callback: function ($$v) {
              _vm.$set(_setup.contactFormData, "customer_name", $$v);
            },
            expression: "contactFormData.customer_name"
          }
        }, _vm._l(_setup.customerList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)];
      },
      proxy: true
    }]),
    model: {
      value: _setup.contactFormData,
      callback: function ($$v) {
        _setup.contactFormData = $$v;
      },
      expression: "contactFormData"
    }
  }), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _setup.handleCreateContact
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;