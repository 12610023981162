"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.handleClose,
      expression: "handleClose"
    }],
    staticClass: "el-select",
    class: [_vm.selectSize ? "el-select--" + _vm.selectSize : ""],
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.toggleMenu.apply(null, arguments);
      }
    }
  }, [_vm.multiple ? _c("div", {
    ref: "tags",
    staticClass: "el-select__tags",
    style: {
      "max-width": _vm.inputWidth - 32 + "px",
      width: "100%"
    }
  }, [_vm.collapseTags && _vm.selected.length ? _c("span", [_c("el-tag", {
    attrs: {
      closable: !_vm.selectDisabled,
      size: _vm.collapseTagSize,
      hit: _vm.selected[0].hitState,
      type: "info",
      "disable-transitions": ""
    },
    on: {
      close: function ($event) {
        return _vm.deleteTag($event, _vm.selected[0]);
      }
    }
  }, [_c("span", {
    staticClass: "el-select__tags-text"
  }, [_vm._v(_vm._s(_vm.selected[0].currentLabel))])]), _vm.selected.length > 1 ? _c("el-tag", {
    attrs: {
      closable: false,
      size: _vm.collapseTagSize,
      type: "info",
      "disable-transitions": ""
    }
  }, [_c("span", {
    staticClass: "el-select__tags-text"
  }, [_vm._v("+ " + _vm._s(_vm.selected.length - 1))])]) : _vm._e()], 1) : _vm._e(), !_vm.collapseTags ? _c("transition-group", {
    on: {
      "after-leave": _vm.resetInputHeight
    }
  }, _vm._l(_vm.selected, function (item, index) {
    return _c("div", {
      key: _vm.getValueKey(item)
    }, [_vm.customize !== "" && index !== 0 ? _c("span", {
      staticClass: "customize"
    }, [_vm._v(_vm._s(_vm.customize))]) : _vm._e(), _c("el-tag", {
      attrs: {
        closable: !_vm.selectDisabled,
        size: _vm.collapseTagSize,
        hit: item.hitState,
        type: "info",
        "disable-transitions": ""
      },
      on: {
        close: function ($event) {
          return _vm.deleteTag($event, item);
        }
      }
    }, [_c("span", {
      staticClass: "el-select__tags-text"
    }, [_vm._v(_vm._s(item.currentLabel))])])], 1);
  }), 0) : _vm._e(), _vm.filterable ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query,
      expression: "query"
    }],
    ref: "input",
    staticClass: "el-select__input",
    class: [_vm.selectSize ? `is-${_vm.selectSize}` : ""],
    style: {
      "flex-grow": "1",
      width: _vm.inputLength / (_vm.inputWidth - 32) + "%",
      "max-width": _vm.inputWidth - 42 + "px"
    },
    attrs: {
      type: "text",
      disabled: _vm.selectDisabled,
      autocomplete: _vm.autoComplete || _vm.autocomplete
    },
    domProps: {
      value: _vm.query
    },
    on: {
      focus: _vm.handleFocus,
      blur: _vm.handleInputBlur,
      keyup: _vm.managePlaceholder,
      keydown: [_vm.resetInputState, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        $event.preventDefault();
        return _vm.navigateOptions("next");
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        $event.preventDefault();
        return _vm.navigateOptions("prev");
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.selectOption.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        $event.stopPropagation();
        $event.preventDefault();
        _vm.visible = false;
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
        return _vm.deletePrevTag.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) return null;
        _vm.visible = false;
      }],
      compositionstart: _vm.handleComposition,
      compositionupdate: _vm.handleComposition,
      compositionend: _vm.handleComposition,
      input: [function ($event) {
        if ($event.target.composing) return;
        _vm.query = $event.target.value;
      }, _vm.debouncedQueryChange]
    }
  }) : _vm._e()], 1) : _vm._e(), _c("el-input", {
    ref: "reference",
    class: {
      "is-focus": _vm.visible
    },
    attrs: {
      id: _vm.id,
      type: "text",
      placeholder: _vm.currentPlaceholder,
      name: _vm.name,
      autocomplete: _vm.autoComplete || _vm.autocomplete,
      size: _vm.selectSize,
      disabled: _vm.selectDisabled,
      readonly: _vm.readonly,
      "validate-event": false,
      tabindex: _vm.multiple && _vm.filterable ? "-1" : null
    },
    on: {
      focus: _vm.handleFocus,
      blur: _vm.handleBlur
    },
    nativeOn: {
      keyup: function ($event) {
        return _vm.debouncedOnInputChange.apply(null, arguments);
      },
      keydown: [function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.navigateOptions("next");
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.navigateOptions("prev");
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.selectOption.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        $event.stopPropagation();
        $event.preventDefault();
        _vm.visible = false;
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) return null;
        _vm.visible = false;
      }],
      paste: function ($event) {
        return _vm.debouncedOnInputChange.apply(null, arguments);
      },
      mouseenter: function ($event) {
        _vm.inputHovering = true;
      },
      mouseleave: function ($event) {
        _vm.inputHovering = false;
      }
    },
    model: {
      value: _vm.selectedLabel,
      callback: function ($$v) {
        _vm.selectedLabel = $$v;
      },
      expression: "selectedLabel"
    }
  }, [_vm.$slots.prefix ? _c("template", {
    slot: "prefix"
  }, [_vm._t("prefix")], 2) : _vm._e(), _c("template", {
    slot: "suffix"
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showClose,
      expression: "!showClose"
    }],
    class: ["el-select__caret", "el-input__icon", "el-icon-" + _vm.iconClass]
  }), _vm.showClose ? _c("i", {
    staticClass: "el-select__caret el-input__icon el-icon-circle-close",
    on: {
      click: _vm.handleClearClick
    }
  }) : _vm._e()])], 2), _c("transition", {
    attrs: {
      name: "el-zoom-in-top"
    },
    on: {
      "after-leave": _vm.doDestroy
    }
  }, [_c("el-select-menu", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible && _vm.emptyText !== false,
      expression: "visible && emptyText !== false"
    }],
    ref: "popper",
    attrs: {
      "append-to-body": _vm.popperAppendToBody
    }
  }, [_vm.showNewOption ? _c("el-option", {
    attrs: {
      value: _vm.query,
      created: ""
    }
  }) : _vm._e(), _vm._t("default"), _vm.emptyText && (!_vm.allowCreate || _vm.loading || _vm.allowCreate && _vm.options.length === 0) ? [_vm.$slots.empty ? _vm._t("empty") : _c("p", {
    staticClass: "el-select-dropdown__empty"
  }, [_vm._v(" " + _vm._s(_vm.emptyText) + " ")])] : _vm._e()], 2)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;