"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GlobalUploadFileItem = _interopRequireDefault(require("./GlobalUploadFileItem.vue"));
var _elementUi = require("element-ui");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const globalUploadFileList = (0, _vue.computed)(() => {
      return store.getters.globalUploadFileList;
    });
    const collapse = (0, _vue.ref)(false);
    const collapseContent = () => {
      collapse.value = !collapse.value;
    };
    const closeGlobalUpload = () => {
      const isUploading = globalUploadFileList.value.some(item => ['waiting', 'uploading', 'processing'].includes(item.state));
      if (isUploading) {
        _elementUi.MessageBox.confirm('上传尚未完成，要取消所有正在进行的上传吗', '取消上传', {
          confirmButtonText: '取消上传',
          cancelButtonText: '继续上传',
          type: 'warning',
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          store.commit('upload/CLEAR_GLOBAL_UPLOAD_FILE_LIST');
        }).catch(() => {});
      } else {
        store.commit('upload/CLEAR_GLOBAL_UPLOAD_FILE_LIST');
      }
    };
    const progressViewState = (0, _vue.computed)(() => {
      const hasUploading = globalUploadFileList.value.some(item => ['waiting', 'uploading', 'processing'].includes(item.state));
      // 有上传中
      if (hasUploading) return 'uploading';

      // 完成
      // 全部成功
      const allSuccess = globalUploadFileList.value.every(item => ['success', 'cancel'].includes(item.state));
      if (allSuccess) return 'allSuccess';

      // 全部失败
      const allFail = globalUploadFileList.value.every(item => ['fail', 'cancel'].includes(item.state));
      if (allFail) return 'allFail';
      return 'complete';
    });
    const getFileCount = function () {
      var _globalUploadFileList;
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return (_globalUploadFileList = globalUploadFileList.value.filter(item => state.includes(item.state))) === null || _globalUploadFileList === void 0 ? void 0 : _globalUploadFileList.length;
    };
    const changeFileState = ($event, index) => {
      // 上传文件状态
      /**
       * enum uploadState = {
       *    waiting: '等待中',
       *    uploading: "上传中",
       *    processing: "处理中",
       *    success: "成功",
       *    fail: "失败",
       *    cancel: "取消"
       * }
       *  */

      store.commit('upload/SET_GLOBAL_UPLOAD_FILE_STATE', {
        index,
        val: {
          ...globalUploadFileList.value[index],
          ...$event
        }
      });
    };
    const changeGlobalUploadFileList = () => {
      var _globalUploadFileList2;
      let isUploadingFileCount = (_globalUploadFileList2 = globalUploadFileList.value.filter(item => item.state === 'uploading')) === null || _globalUploadFileList2 === void 0 ? void 0 : _globalUploadFileList2.length;
      for (let i = 0; i < globalUploadFileList.value.length; i++) {
        if (isUploadingFileCount >= 3) return;
        if (globalUploadFileList.value[i].state === 'waiting') {
          changeFileState({
            state: 'uploading'
          }, i);
          isUploadingFileCount += 1;
        }
      }
    };
    window.addEventListener('beforeunload', function (e) {
      const isUploading = globalUploadFileList.value.some(item => ['waiting', 'uploading', 'processing'].includes(item.state));
      if (isUploading) {
        (e || window.event).returnValue = '确定离开此页吗？';
      }
    });
    (0, _vue.watchEffect)(() => {
      changeGlobalUploadFileList();
    });
    return {
      __sfc: true,
      store,
      globalUploadFileList,
      collapse,
      collapseContent,
      closeGlobalUpload,
      progressViewState,
      getFileCount,
      changeFileState,
      changeGlobalUploadFileList,
      GlobalUploadFileItem: _GlobalUploadFileItem.default
    };
  }
};
exports.default = _default;