"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "manage-developer-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: "添加开发者",
      visible: _vm.isVisible,
      "before-close": _vm.handleClose
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.formData
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择成员",
      prop: "developer"
    }
  }, [_c("members-tree-select", {
    ref: "members-tree-select",
    attrs: {
      "request-function": _vm.getDepartmentAndMemberTree,
      "is-prevent-not-selected": false
    },
    on: {
      change: _vm.departmentAndMemberChange
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeAddDeveloperDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmAddDeveloper
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;