"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetailKeyEvent',
  components: {
    EventsList: _eventsList.default,
    KeyEventDetailList: _keyEventDetailList.default,
    NoData: _NoData.default
  },
  props: {
    convId: {
      type: [Number, String],
      default: 0
    },
    mediaType: {
      type: String,
      default: ''
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      eventEngineList: [],
      eventEngineCount: 0,
      loading: false,
      activeEventId: 0,
      id2EventMap: new Map(),
      eventDefaultIndex: 0,
      activeEventDetailData: [],
      eventTrackerTitle: '',
      deleteOperateLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    activeKeyEvent() {
      return this.id2EventMap.get(this.activeEventId) || {};
    }
  },
  watch: {
    convId() {
      this.activeEventId = 0;
      this.getEventEngine();
    }
  },
  created() {
    this.handleExternalKeyEvent();
  },
  mounted() {
    this.$bus.$on('addEventTrackerSuccess', async eventIds => {
      await this.getEventEngine();
      this.setTreeNodeHighLight(this.eventEngineList, eventIds);
    });
    this.$bus.$on('deleteKeyEventTrackerSuccess', async () => {
      this.getEventEngine();
      if (this.activeEventId) {
        await this.getEventTrackers();
      }
    });
  },
  methods: {
    setTreeNodeHighLight(node, eventIds) {
      node.forEach(item => {
        if (eventIds.includes(item.id)) {
          this.$set(item, 'highlight', true);
          item.highlight = true;
        }
        if (item.children && item.children.length) {
          this.setTreeNodeHighLight(item.children, eventIds);
        }
      });
    },
    async deleteKeyEventDetailTracker(event_engine_id) {
      this.deleteOperateLoading = true;
      const res = await (0, _videoDetail.deleteKeyEventDetailTracker)({
        conversation_id: Number(this.convId),
        event_engine_id
      }).finally(() => this.deleteOperateLoading = false);
      if (res.code === 20000) {
        this.$message.success('删除成功，评分重新计算中，请稍后刷新查看');
        this.$bus.$emit('conversationLeftDeleteKeyEventTrackerSuccess');
        await this.getEventEngine();
        await this.getEventTrackers();
        if (this.activeEventDetailData && !this.activeEventDetailData.length) {
          this.activeEventId = 0;
        }
      }
    },
    async handleDeleteKeyEventDetailTracker(tracker, index) {
      await this.deleteKeyEventDetailTracker(tracker.id);
    },
    async handleExternalKeyEvent() {
      const externalKeyEventId = this.$route.query.keyWordId;
      const externalKeyEventIndex = this.$route.query.itemIndex - 0;
      await this.getEventEngine();
      // 外部url跳转进来
      if (externalKeyEventId) {
        this.activeEventId = externalKeyEventId;
        await this.getEventTrackers();
        if (!this.activeEventDetailData.length) {
          this.activeEventId = 0;
        }
        this.eventDefaultIndex = externalKeyEventIndex;
      }
    },
    async getEventTrackers() {
      this.loading = true;
      const res = await (0, _videoDetail.getEventTrackers)({
        conversation_id: parseInt(this.convId),
        event_id: this.activeEventId
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.eventTrackerTitle = res.results.name + `(${res.results.count})`;
        this.activeEventDetailData = res.results.datas;
      }
    },
    async getEventEngine() {
      this.loading = true;
      const res = await (0, _videoDetail.getEventEngine)(this.convId, this.workspaceInfo.id).finally(() => this.loading = false);
      if (res.code === 200) {
        this.eventEngineList = res.results;
        this.eventEngineCount = res.total;
        this.setId2EventMap(this.eventEngineList);
      }
    },
    setId2EventMap(events) {
      for (const event of events) {
        if (event.children) {
          this.setId2EventMap(event.children);
        } else {
          const {
            id,
            ...ever
          } = event;
          this.id2EventMap.set(id, ever);
        }
      }
    },
    handleTagClick(val) {
      this.activeEventId = val;
      this.getEventTrackers();
    },
    handleBackUp() {
      this.activeEventId = 0;
      // 清空外部url跳转进来默认选中的关键事件
      this.eventDefaultIndex = 0;
    }
  }
};
exports.default = _default;