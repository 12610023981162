"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationPage = _interopRequireDefault(require("@/views/conversationPage"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FilterConvDrawer',
  components: {
    ConvListPage: _conversationPage.default
  },
  provide() {
    return {
      isBaseConvInDrawer: true,
      isInQualityTesting: true
    };
  },
  props: {
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    drawerVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '配置筛选条件'
    },
    tipContent: {
      type: String,
      default: ''
    },
    backFillFilters: {
      type: Object,
      default: () => ({})
    },
    noTip: {
      type: Boolean,
      default: false
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 100]
    }
  },
  data() {
    return {
      convFilters: {},
      convTotalCount: 0
    };
  },
  methods: {
    conversationListChange(conversationListArr) {
      this.$emit('conversationListChange', conversationListArr);
    },
    handleClose() {
      this.$emit('update:drawerVisible', false);
    },
    convFlowFiltersChange(filters) {
      this.convFilters = filters;
      this.$emit('convFilterChange', filters);
    },
    filterNumChange(val) {
      this.$emit('filterNumChange', val);
    },
    getConvTotalCount(val) {
      this.convTotalCount = val;
      this.$emit('convTotalCount', val);
    }
  }
};
exports.default = _default;