"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-drawer", {
    attrs: {
      title: "配置部门与空间关系",
      size: "500px",
      "append-to-body": "",
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDrawer,
      "custom-class": "set-space-department-mapping-drawer"
    }
  }, [_c("div", {
    staticClass: "operate-info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v(" 配置后，部门成员的主空间会发生改变，新产生的会话、交易会进入新的空间里，之前的会话、交易需要手动转移空间；通过开放接口进入的新成员，会自动进入部门对应的空间里 ")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _vm.departmentAndWorkspaceMappingList,
      "max-height": _vm.tableHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "空间",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("show-long-content", {
          attrs: {
            content: row.is_default ? `${row.name}（默认空间）` : row.name,
            len: 12
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "部门"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("show-long-content", {
          attrs: {
            content: _vm.formatDepartmentList(row.tree_list),
            len: 12
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("el-popover", {
          ref: "popover-" + row.id,
          attrs: {
            placement: "bottom-end",
            width: "300",
            title: "选择部门",
            "popper-class": "change-department-id-popper"
          }
        }, [_c("department-multiple-select", {
          ref: "org-tree" + row.id,
          staticClass: "department-select",
          attrs: {
            "no-default": "",
            "is-hide-strictly": true,
            "tree-data": _vm.treeData
          },
          on: {
            change: _vm.selectDepartment
          }
        }), _c("div", {
          staticClass: "popper-btn"
        }, [_c("el-button", {
          attrs: {
            type: "plain",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.closePopover(row.id);
            }
          }
        }, [_vm._v(" 取消 ")]), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.openConfirmDialog(row.id);
            }
          }
        }, [_vm._v(" 更新 ")])], 1), !row.is_default ? _c("el-button", {
          staticClass: "edit-btn",
          attrs: {
            slot: "reference",
            type: "text",
            disabled: !_vm.hasWorkspace
          },
          on: {
            click: function ($event) {
              return _vm.openPopover(row);
            }
          },
          slot: "reference"
        }, [_vm._v(" 编辑 ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;