"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'ChatTop',
  props: {
    foldable: {
      type: Boolean,
      default: false
    },
    isFold: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleFold: (0, _commonFunc.throttle)(function () {
      this.$emit('toggleFold');
    }, 200)
  }
};
exports.default = _default;