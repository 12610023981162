"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "complete-practice-button"
  }, [_c("MgvButton", {
    staticClass: "button left",
    on: {
      click: _setup.completePractice
    }
  }, [_vm._v(_vm._s(_setup.t("coaching.完成练习")))]), _c("el-dropdown", {
    attrs: {
      trigger: "click",
      placement: "top-end"
    },
    on: {
      command: _setup.onCommand
    }
  }, [_c("MgvButton", {
    staticClass: "button right",
    attrs: {
      icon: "icon-more",
      square: ""
    }
  }), _c("el-dropdown-menu", {
    staticClass: "complete-practice-button-popper",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "saveAndLeave"
    }
  }, [_c("span", [_vm._v(_vm._s(_setup.t("coaching.暂存离开")))]), _c("el-tooltip", {
    attrs: {
      content: _setup.t("coaching.任务截止前可继续本次练习"),
      placement: "right"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("el-dropdown-item", {
    attrs: {
      command: "giveUpPractice"
    }
  }, [_c("span", [_vm._v(_vm._s(_setup.t("coaching.放弃练习")))]), _c("el-tooltip", {
    attrs: {
      content: _setup.t("coaching.退出并清除本次练习记录"),
      placement: "right"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;