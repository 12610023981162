"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _num_formatter = require("@/utils/num_formatter");
var _default = {
  __name: 'ModifyConvInfoHistoryValue',
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    fieldValue: {
      type: [Object, Array, String, Number]
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      parseFormatNum: _num_formatter.parseFormatNum
    };
  }
};
exports.default = _default;