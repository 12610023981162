"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trade-root"
  }, [!_vm.onlyTradeList ? _c("div", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_c("div", {
    staticClass: "page-title"
  }, [_vm._v("交易")]), _c("workspace-badge")], 1)]) : _vm._e(), _c("div", {
    staticClass: "trade-main"
  }, [_c("header", {
    staticClass: "header-filter-container"
  }, [_c("div", {
    staticClass: "header-row"
  }, [!_vm.onlyTradeList ? _c("header-filters", {
    key: _vm.workspaceInfo.id,
    ref: "tradeFilter",
    attrs: {
      "custom-filter-object": _vm.customFilterObject,
      "key-events": _vm.keyEvents,
      "filter-config-value": _vm.localBackFillFilters
    },
    on: {
      filterChange: _vm.headerFiltersChange
    }
  }) : _vm._e(), _c("dynamic-popover", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.onlyTradeList,
      expression: "!onlyTradeList"
    }],
    attrs: {
      type: "business",
      "incoming-list": _vm.originHeaderList,
      "popper-class": "head-set-pop"
    },
    on: {
      getTableHead: _vm.getTableHead
    }
  }, [_c("el-button", {
    staticClass: "column-btn",
    attrs: {
      size: "small",
      icon: "iconfont icon-setting"
    }
  }, [_vm._v("列设置")])], 1)], 1)]), _c("trade-table", {
    ref: "tradeTable",
    attrs: {
      "header-list": _vm.headerList,
      list: _vm.list,
      table: _vm.table,
      loading: _vm.tableLoading,
      "double-call-permission": _vm.doubleCallPermission,
      "table-height": _vm.tableHeight
    },
    on: {
      sortChange: _vm.tableSortChange,
      getNextSituation: _vm.getTradeListFollow
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    staticClass: "conver-list-pagination",
    class: _vm.total >= 10000 ? "hide-last-page" : "",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.listParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.listParams.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  })], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;