"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CoachDetailCard = _interopRequireDefault(require("./CoachDetailCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _coach = require("@/api/coach");
var _NoDataHolder = _interopRequireDefault(require("@/components/NoDataHolder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachDetailList',
  components: {
    CoachDetailCard: _CoachDetailCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    NoDataHolder: _NoDataHolder.default
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    userId: Number,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myPage: 1,
      otherPage: 1,
      requestDisabled: false,
      isRequestOther: false,
      conversationCount: 0,
      // 向我申请的辅导所在的会话数
      otherUndoCount: 0,
      undoList: [],
      coachCount: 0,
      // 待我辅导的数量
      otherUndoList: [],
      loading: false,
      noMore: false
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.load();
      } else {
        this.resetData();
      }
    }
  },
  methods: {
    load() {
      this.isRequestOther ? this.getOtherUndoAdvise() : this.getUndoAdvise();
    },
    // 向我申请的辅导
    async getUndoAdvise() {
      this.loading = true;
      const params = {
        page: this.myPage++,
        size: 10,
        user_id: this.userId
      };
      const res = await (0, _coach.getUndoAdvise)(params);
      this.$emit('requestSucceed');
      const {
        count,
        coach_count,
        datas
      } = res.results;
      this.conversationCount = count;
      this.coachCount = coach_count;
      datas.forEach(item => {
        item.is_to_me = true;
        this.undoList.push(item);
      });
      if (this.undoList.length >= this.conversationCount || !this.conversationCount) {
        this.isRequestOther = true;
      }
      this.load();
    },
    // 向其他人申请的辅导
    async getOtherUndoAdvise() {
      const params = {
        page: this.otherPage++,
        size: 10,
        user_id: this.userId
      };
      const res = await (0, _coach.getOtherUndoAdvise)(params);
      const {
        count,
        datas
      } = res.results;
      this.otherUndoCount = count;
      this.$emit('getCount', this.coachCount + this.otherUndoCount);
      this.otherUndoList.push(...datas);
      if (this.otherUndoList.length >= this.otherUndoCount || !this.otherUndoCount) {
        this.noMore = true;
        this.loading = false;
        this.requestDisabled = true;
      }
    },
    resetData() {
      [this.myPage, this.otherPage] = [1, 1];
      [this.isRequestOther, this.requestDisabled, this.loading, this.noMore] = [false, false, false, false];
      [this.undoList, this.otherUndoList] = [[], []];
    }
  }
};
exports.default = _default;