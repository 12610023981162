"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hot-data"
  }, [_c("span", {
    staticClass: "item",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleLike.apply(null, arguments);
      }
    }
  }, [_c("i", {
    class: ["iconfont", _vm.localInfo.is_like ? "icon-great-3" : "icon-great-2"]
  }), _c("span", [_vm._v(_vm._s(_vm.localInfo.like_amount))])]), _c("span", {
    staticClass: "item",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleCollect.apply(null, arguments);
      }
    }
  }, [_c("i", {
    class: ["iconfont", _vm.localInfo.is_collect ? "icon-star-on-2" : "icon-star-off"]
  }), _c("span", [_vm._v(_vm._s(_vm.localInfo.collect_amount))])]), !_vm.noPlay ? _c("span", {
    staticClass: "item"
  }, [_c("i", {
    staticClass: "iconfont icon-video-play"
  }), _c("span", [_vm._v(_vm._s(_vm.localInfo.play_amount))])]) : _vm._e(), !_vm.isFolder && _vm.localInfo.has_study ? _c("StudyStatus", {
    attrs: {
      info: _vm.localInfo
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;