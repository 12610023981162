"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-popover", {
    attrs: {
      placement: _vm.placement,
      width: "300",
      "visible-arrow": false,
      "open-delay": 300,
      "popper-class": _vm.popperClass
    },
    on: {
      hide: _vm.handleHide
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "dynamic"
  }, [_vm.title ? _c("header", [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c("el-input", {
    staticClass: "dynamic-input",
    attrs: {
      "prefix-icon": "el-icon-search",
      placeholder: "搜索"
    },
    on: {
      input: _vm.change
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c("div", {
    staticClass: "checkbox-kuang"
  }, [_c("el-checkbox-group", {
    attrs: {
      min: _vm.minCheckCount,
      max: 100
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.checkList,
      callback: function ($$v) {
        _vm.checkList = $$v;
      },
      expression: "checkList"
    }
  }, [_c("mg-draggable", {
    on: {
      end: _vm.handleDragEnd
    },
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, [_vm._l(_vm.list, function (item, index) {
    return [item.show ? _c("div", {
      key: index,
      class: ["drag-wrap", {
        move: !item.is_fixed
      }, {
        draggable: !item.is_fixed,
        disabled: !!item.is_fixed
      }]
    }, [_c("div", {
      staticClass: "move-icon"
    }, [_c("i", {
      staticClass: "iconfont icon-drag-dot"
    })]), _c("el-checkbox", {
      staticClass: "check-items",
      attrs: {
        label: item,
        value: item,
        disabled: !!item.is_fixed
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm.type === "business" ? _c("el-tooltip", {
      attrs: {
        placement: "top",
        effect: "dark",
        content: "移到最前"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-back-top",
      on: {
        click: function ($event) {
          return _vm.moveToTop(index);
        }
      }
    })]) : _vm._e()], 1) : _vm._e()];
  })], 2)], 1)], 1)], 1), _c("div", {
    staticClass: "button-style"
  }, [_c("el-button", {
    attrs: {
      type: _vm.type === "business" ? "" : "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: _vm.saveTableHead
    }
  }, [_vm._v("确定")]), _vm.type === "business" ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmAndSaveTableHead
    }
  }, [_vm._v("确定并保存")]) : _vm._e()], 1), _c("el-popover", {
    attrs: {
      slot: "reference",
      placement: "bottom-start",
      width: "284",
      trigger: "manual",
      "popper-class": "score-rule-info-popover"
    },
    slot: "reference",
    model: {
      value: _vm.scoreRuleInfoVisible,
      callback: function ($$v) {
        _vm.scoreRuleInfoVisible = $$v;
      },
      expression: "scoreRuleInfoVisible"
    }
  }, [_c("span", {
    staticClass: "score-rule-check-info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v("请勾选“" + _vm._s(_vm.scoreRuleName) + "”评分规则列")]), _c("el-button", {
    attrs: {
      slot: "reference",
      type: "text"
    },
    slot: "reference"
  }, [_vm._t("default", function () {
    return [_vm._v(" 列设置 "), _c("i", {
      staticClass: "iconfont icon-arrow-down"
    })];
  })], 2)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;