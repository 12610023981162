"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CrossBarChart',
  mixins: [_chartMixins.default],
  data() {
    return {
      autoHeight: 0,
      options: {
        chartName: 'bar',
        dataset: {
          source: this.data
        },
        grid: {
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '业绩',
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: '14',
            padding: 10
          }
        },
        yAxis: {
          type: 'category',
          name: '姓名',
          nameTextStyle: {
            fontSize: '14',
            padding: 5
          }
        },
        series: {
          type: 'bar',
          barWidth: 14,
          label: {
            show: true,
            position: 'right',
            formatter: v => {
              var val = Number(v.data[v.data.length - 1]).toFixed(2);
              return val;
            }
          },
          encode: {
            x: '',
            y: ''
          },
          markLine: {
            data: [{
              type: 'average',
              name: '平均'
            }]
          }
        }
      }
    };
  },
  watch: {
    data: {
      handler(val) {
        this.options.dataset.source = val;
        this.init();
        this.$nextTick(() => {
          this.barChartResize();
        });
      },
      deep: true
    }
  },
  mounted() {
    this.barChartResize();
  },
  methods: {
    barChartResize() {
      this.autoHeight = this.data ? this.data.length * 20 + 150 : 300;
      const width = this.$refs['barChart'].offsetWidth;
      this.chart && this.chart.resize({
        height: this.autoHeight,
        width
      });
    }
  }
};
exports.default = _default;