"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _workspace = require("@/api/workspace");
var _conversationList = require("@/api/conversationList");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'WorkspaceTable',
  components: {
    MgOperateColumn: _MgOperateColumn.default
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    operateList(row) {
      return [{
        label: '编辑',
        command: 'edit',
        isShow: true
      }, {
        label: '删除',
        command: 'delete',
        icon: 'icon-delete',
        isShow: !row.is_default,
        class: 'delete'
      }];
    },
    handleCommand(row, command) {
      switch (command) {
        case 'edit':
          this.openDialog('edit', row);
          break;
        case 'delete':
          this.deleteWorkspace(row);
          break;
      }
    },
    openDialog(type, row) {
      this.$emit('openDialog', type, row);
    },
    async deleteWorkspace(row) {
      if (row.member_count) {
        this.$message({
          message: '该空间下有成员，不能删除',
          type: 'warning'
        });
        return;
      }
      const res = await (0, _workspace.getWorkspaceConvCount)(row.id);
      if (res.code === 20000) {
        const hasConversationData = res.results.conv_count || res.results.recycle_conv_count;
        this.$confirm(hasConversationData ? '该空间有会话数据，必须完成转移后再删除' : `确定删除${row.name}吗？`, '删除空间', {
          confirmButtonText: hasConversationData ? '转移会话' : '移除',
          cancelButtonText: '取消',
          confirmButtonClass: hasConversationData ? '' : 'confirm-right',
          type: 'warning'
        }).then(() => {
          if (hasConversationData) {
            this.transferConversation(row, res.results);
          } else {
            (0, _workspace.deleteWorkspace)({
              id: row.id
            }).then(res => {
              if (res.code === 20000) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                });
                this.$emit('deleteSuccess');
              }
            });
          }
        });
      }
    },
    async transferConversation(row, countObj) {
      const res = await (0, _conversationList.checkTransfer)();
      if (res.code === 20000) {
        this.$emit('transferConversation', row, countObj);
      }
    }
  }
};
exports.default = _default;