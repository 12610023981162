"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'PresetPathButton',
  props: {
    hasPresetPath: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 点击编辑业务路径按钮
    handlePresetBtnClick() {
      this.$emit('clickPresetPathBtn');
    },
    openPathExplain() {
      this.$alert('<p>业务路径是销售过程中各事件节点所构成的集合，可用于了解整体业务构成情况以及具体事件节点的构成和流转情况。</p><p>目前，业务路径将所有到达了指定结果（关键事件/商机阶段/自定义字段）的商机计入路径分析，其余商机不计入分析。</p><p>三种视图（桑基图、思维导图、表格）默认展示的路径为“无序路径”。</p><p>“无序路径”指的是由【触发指定结果的商机的所有事件】以不考虑事件发生先后顺序的方式展示的路径。（触发相同关键事件合并为同一路径，即路径间的区别只考虑内容不考虑顺序。）</p><p>“有序路径”指的是严格按照先后次序排列所构成的集合，即“有序路径”会还原“无序路径”所展示事件节点的顺序情况。您可以在表格视图中查看Top5路径相应的“有序路径”，同时，还可以在表格视图中查看“有序路径”所对应的商机以及子节点。</p>', '什么是业务路径？', {
        confirmButtonText: '知道了',
        customClass: 'business-path-explain',
        callback: () => {},
        dangerouslyUseHTMLString: true
      });
    }
  }
};
exports.default = _default;