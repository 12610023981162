"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "struct-template"
  }, [_vm.layout.avatar_element && !_vm.isEmptyObj(_vm.layout.avatar_element) ? _c("div", {
    staticClass: "left-element"
  }, [_c(_vm.layout.avatar_element.tag, {
    tag: "component",
    attrs: {
      "render-info": _vm.layout.avatar_element.render_info,
      unread: !_vm.templateInfo.config.is_read
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "right-card",
    class: {
      link: _vm.HasMainLink
    },
    on: {
      click: function ($event) {
        return _vm.cardBtnOperate(_vm.templateInfo.config.main_link);
      }
    }
  }, [_vm.layout.card_header && !_vm.isEmptyObj(_vm.layout.card_header) ? _c("div", {
    staticClass: "card-head",
    class: {
      "task-card-header": _vm.layout.card_overview.tag === "task-center-card",
      "no-border": !_vm.hasContent
    }
  }, [_c(_vm.layout.card_header.tag, {
    tag: "component",
    attrs: {
      "render-info": _vm.layout.card_header.render_info
    }
  })], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasContent,
      expression: "hasContent"
    }],
    staticClass: "card-content"
  }, [_vm.layout.card_overview && _vm.layout.card_overview.length ? _c("div", {
    staticClass: "content-item card-overview"
  }, [_vm._l(_vm.layout.card_overview, function (view, index) {
    return [_c("span", {
      key: index,
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c(view.tag, {
      key: index,
      tag: "component",
      attrs: {
        "render-info": view.render_info
      },
      on: {
        updateReadStatus: _vm.setReadMessage
      }
    })], 1)];
  })], 2) : _vm._e(), _vm.layout.card_source && _vm.layout.card_source.length ? _c("div", {
    staticClass: "content-item card-source"
  }, [_vm._l(_vm.layout.card_source, function (source, index) {
    return [_c(source.tag, {
      key: index,
      tag: "component",
      attrs: {
        "render-info": source.render_info,
        "operate-data": source.operate_data
      },
      on: {
        click: _vm.cardBtnOperate
      }
    })];
  })], 2) : _vm._e(), _vm.layout.operate_btns && _vm.layout.operate_btns.length ? _c("div", {
    staticClass: "content-item card-operate-btns"
  }, [_vm._l(_vm.layout.operate_btns, function (operateBtn, index) {
    return [_c(operateBtn.tag, {
      key: index,
      tag: "component",
      attrs: {
        "render-info": operateBtn.render_info,
        "operate-data": operateBtn.operate_data
      },
      on: {
        click: _vm.cardBtnOperate
      }
    })];
  })], 2) : _vm._e(), _vm.layout.card_space && _vm.layout.card_space.length ? _c("div", {
    staticClass: "content-item card-space"
  }, [_vm._l(_vm.layout.card_space, function (view, index) {
    return [_c(view.tag, {
      key: index,
      tag: "component",
      attrs: {
        "render-info": view.render_info
      },
      on: {
        updateReadStatus: _vm.setReadMessage
      }
    })];
  })], 2) : _vm._e()])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;