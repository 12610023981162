"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("main", {
    staticClass: "ai-coaching-main"
  }, [_c("article", {
    staticClass: "banner-container"
  }, [_c("section", {
    staticClass: "banner-content rise-module"
  }, [_c("div", {
    staticClass: "banner-content-left"
  }, [_c("h1", {
    staticClass: "banner-content-title"
  }, [_vm._v("智能培训 - 开启您的AI智能教练")]), _c("p", {
    staticClass: "banner-content-text"
  }, [_vm._v(" 基于“学 - 背 - 练 - 实战”四种循序渐进的课程，帮助员工快速学习标准话术，并在与AI客户实战对话过程中掌握应答技巧，加速新员工上岗、销售签单，助力企业在培训环节降本增效。 ")]), _c("button", {
    staticClass: "btn btn-medium try-btn",
    on: {
      click: _setup.showReservationForm
    }
  }, [_vm._v(" 申请试用 "), _c("img", {
    staticClass: "arrow",
    attrs: {
      src: require("@/assets/icons/right-arrow-white.svg")
    }
  })]), _c("p", {
    staticClass: "tip"
  }, [_vm._v(" 提交申请后 Megaview 工作人员将尽快与您联系并根据需求为您开通服务。 ")])]), _vm._m(0)])]), _c("article", {
    staticClass: "coaching-system-container rise-module"
  }, [_c("h1", {
    staticClass: "coaching-system-title"
  }, [_vm._v("Megaview赋能您的培训")]), _c("div", {
    staticClass: "coaching-system-desc"
  }, [_vm._v(" 新一代智能培训，结合AI模拟真实客户，快速复制销冠能力！ ")]), _c("div", {
    staticClass: "tabs pc"
  }, [_c("ul", {
    staticClass: "tabs-nav"
  }, _vm._l(_setup.tabsNavList, function (item, index) {
    return _c("li", {
      key: index,
      class: ["tabs-nav-item", {
        active: _setup.currentTabsNavIndex === index
      }],
      on: {
        click: function ($event) {
          _setup.currentTabsNavIndex = index;
        }
      }
    }, [_c("img", {
      attrs: {
        src: require(`@/assets/coach/guide/coaching-system-icon-${index + 1}${_setup.currentTabsNavIndex === index ? "-active" : ""}.png`),
        alt: ""
      }
    }), _c("span", {
      staticClass: "tab-nav-item__label"
    }, [_vm._v(_vm._s(item))])]);
  }), 0), _c("div", {
    staticClass: "tabs-content"
  }, [_c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 0
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 0
    }],
    attrs: {
      "animation-data": require("@/assets/json/ai-coaching.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(1)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 1
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 1
    }],
    attrs: {
      "animation-data": require("@/assets/json/coaching-speech.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(2)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 2
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 2
    }],
    attrs: {
      "animation-data": require("@/assets/json/self-training.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(3)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 3
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 3
    }],
    attrs: {
      "animation-data": require("@/assets/json/talent-inventory.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(4)], 1)])])]), _c("article", {
    staticClass: "own-advantage-container rise-module"
  }, [_c("h1", {
    staticClass: "own-advantage-title"
  }, [_vm._v("我们的优势")]), _c("section", {
    staticClass: "own-advantage-content rise-module pc"
  }, [_c("figure", {
    class: ["own-advantage-card", {
      "is-active": !_setup.ownAdvantageIndex
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 0;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("话术培训降本增效")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(5), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/coach/guide/own-advantage-1${!_setup.ownAdvantageIndex ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 1
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 1;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("挖掘优秀业务话术")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(6), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/coach/guide/own-advantage-2${_setup.ownAdvantageIndex === 1 ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 2
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 2;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("领先的技术实力")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(7), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/coach/guide/own-advantage-3${_setup.ownAdvantageIndex === 2 ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 3
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 3;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("全场景覆盖")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(8), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/coach/guide/own-advantage-4${_setup.ownAdvantageIndex === 3 ? "-active" : ""}.png`),
      alt: ""
    }
  })])])])]), _vm._m(9), _c("article", {
    staticClass: "oncall rise-module"
  }, [_c("div", {
    staticClass: "oncall-content"
  }, [_vm._m(10), _c("section", [_c("button", {
    staticClass: "btn btn-medium try-btn",
    on: {
      click: _setup.showReservationForm
    }
  }, [_vm._v(" 申请试用 "), _c("img", {
    staticClass: "arrow",
    attrs: {
      src: require("@/assets/icons/right-arrow-white.svg")
    }
  })])])])]), _c(_setup.GuidePageTrialDialog, {
    attrs: {
      "dialog-visible": _setup.guidePageTrialDialogVisible,
      source: "coaching"
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.guidePageTrialDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.guidePageTrialDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "banner-content-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/banner/ai-coaching-img.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("AI智能陪练")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 开盒即用，一键生成高智能AI客户模型，模拟真实场景进行实战演练。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("配置简单，只需导入语料便可自动生成AI客户")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("按需设置AI客户的性格特质，练习对象更多样")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("高智能真人语音自由对话，完美复刻真实客户")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("即时反馈对练效果，详细出具评分和改进建议")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("金牌话术沉淀")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 通过知识点挖掘引擎，快速提炼优秀话术，轻松获得一手培训素材。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("发掘金牌话术，复制金牌销售")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("缩短总结周期，快速建立话术库")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("沉淀学习素材，团队分享共同进步")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("员工自主训练")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 销售人员可选择不同的方式进行练习，并根据系统反馈进行针对性提升。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("多种对练模式，朗读、背诵、对练和AI客户自由选择")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("多样练习场景，文本、语音和模拟通话皆可支持")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("即时反馈薄弱点，促进高效自我提升")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("销售人才盘点")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 根据业务情况构建人员能力评估模型，发现深层隐藏问题，快速确定需培训对象。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("智能监测和分析会话过程，全面呈现团队能力分布")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("构建个人能力评估模型，有针对性地提升业务能力")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/guide/frame.png")
    }
  }), _c("div", [_vm._v("迅速识别员工优劣势，定向补足短板，降低培训成本")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("相比真人1V1效率倍增")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("培训成本降低32%")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("试岗时间缩短53%")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("构建全量话术挖掘模型，复制金牌销售")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("自动聚类客户高频问题")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("长尾优秀话术量增加20%")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("自研ASR、NLP等AI模型")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("PB级数据处理")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("数十项核心AI专利")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("顶级研发团队")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("支持大规模并发")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("全员工：新人上岗、老人提升")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("全需求：话术强化、SOP执行、知识点培训、新产品发布等")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("全类型：读、背、练皆可选；文本、语音均支持")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("全天候：App、PC端7*24小时在线")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("article", {
    staticClass: "feature-induction-container rise-module"
  }, [_c("h1", {
    staticClass: "feature-induction-title"
  }, [_vm._v("行业优秀实践")]), _c("p", {
    staticClass: "title-description"
  }, [_vm._v("助您构建企业数智化培训生态")]), _c("section", {
    staticClass: "feature-induction-content rise-module"
  }, [_c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "card-banner",
    attrs: {
      src: require("@/assets/coach/guide/card-banner-1.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v(" 在线教育集团高效销售新人培训实践 ")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 在线教育集团在销售团队迅速扩张时期，面临销售新人培训周期长、成本高的挑战。通过Megaview，构建教-学-练闭环，实现新人招聘、培训、管理三重创新，突破销售团队成长的瓶颈。 ")])]), _c("div", {
    staticClass: "content-bottom"
  }, [_c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("80%")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/top-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("行业新人培训效率提升")])]), _c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("20%")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/bottom-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("销售人员流失率降低")])])])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "card-banner",
    attrs: {
      src: require("@/assets/coach/guide/card-banner-2.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v(" 餐饮技术企业最佳培训素材搭建实践 ")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 餐饮技术平台进行业务扩张时，听录音收集问题和优秀话术的方法成本高且不能覆盖全部销售场景。使用Megaview知识点挖掘引擎，从真实会话中挖掘优秀话术，构建销售知识库，积累一手销售培训素材。 ")])]), _c("div", {
    staticClass: "content-bottom"
  }, [_c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("6x")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/top-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("工作效率提升")])]), _c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("40%")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/bottom-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("培训话术库成型周期缩短")])])])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "card-banner",
    attrs: {
      src: require("@/assets/coach/guide/card-banner-3.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("银行业务培训提效实践")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 银⾏在大规模“服转销”的过程中，将业务培训下放到⼀线管理者的培训方式，效率低、成本⾼。引入Megaview后，培训部门直接就可全⾯透视团队及个⼈能⼒状况，提供专业且更具针对性的集中培训。 ")])]), _c("div", {
    staticClass: "content-bottom"
  }, [_c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("53%")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/bottom-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("坐席上岗时间缩短")])]), _c("div", {
    staticClass: "data-desc-content"
  }, [_c("div", {
    staticClass: "data-desc-content-top"
  }, [_c("span", [_vm._v("65%")]), _c("img", {
    attrs: {
      src: require("@/assets/icons/bottom-arrow-black.png")
    }
  })]), _c("p", {
    staticClass: "data-desc-content-bottom"
  }, [_vm._v("成本至利润的转换周期缩短")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("aside", {
    staticClass: "h2 oncall-title"
  }, [_c("h1", {
    staticClass: "h2"
  }, [_vm._v("实战闭环培训，让每一位员工成为销售专家！")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;