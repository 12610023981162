"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.closeSelf
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.type === "get" ? _c("div", {
    staticClass: "info-item"
  }, [_c("label", [_vm._v("登录账号：")]), _c("span", [_vm._v(_vm._s(_vm.account))])]) : _vm._e(), _c("div", {
    staticClass: "info-item"
  }, [_c("label", [_vm._v(_vm._s(_vm.type === "get" ? "密码：" : "该成员当前密码为："))]), _c("span", [_vm._v(_vm._s(_vm.password))])]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.getCopycopyContent,
      expression: "getCopycopyContent",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    attrs: {
      type: "primary"
    }
  }, [_vm._v("复制并完成")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;