"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "admin-role-card"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("select-role", {
    ref: "selectRole",
    staticClass: "select-role",
    attrs: {
      "sub-title": "管理员角色",
      "tooltip-text": "管理员角色，是多个系统管理权限的集合，可将企业管理的各个方面分配给不同的管理员角色",
      "role-list": _vm.adminRoleList,
      "role-loading": _vm.roleLoading,
      "role-info": _vm.roleInfo
    },
    on: {
      getRoleList: _vm.getAdminRoleList,
      switchRightPage: _vm.switchRightPage,
      openRoleDrawer: _vm.openRoleDrawer
    }
  })], 1), _c("div", {
    staticClass: "mid-line"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  })], 1), _c("div", {
    staticClass: "right-content"
  }, [_c("div", {
    staticClass: "card-top"
  }, [_c("div", {
    staticClass: "card-title"
  }, [_c("h3", [_vm._v(_vm._s(_vm.roleInfo.name))]), _vm.roleInfo.name === "超级管理员" ? _c("span", {
    staticClass: "super-admin"
  }, [_vm._v("拥有系统管理的所有权限")]) : _vm._e()]), _vm.roleInfo.editable ? _c("el-button", {
    staticClass: "el-icon-edit",
    on: {
      click: function ($event) {
        return _vm.openRoleDrawer(_vm.roleInfo, "edit");
      }
    }
  }, [_vm._v("编辑角色")]) : _vm._e()], 1), _c("div", {
    staticClass: "filter-operate"
  }, [_c("div", {
    staticClass: "filter-part"
  }, [_c("el-input", {
    attrs: {
      placeholder: "搜索成员"
    },
    on: {
      input: _vm.searchMembers
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })]), _vm.hasDepartmentMember ? _c("department-select", {
    attrs: {
      placeholder: "管理范围",
      page: "noDefault",
      clearable: true,
      "request-method": _vm.getDepartmentsWithOutAuth
    },
    on: {
      change: _vm.setDepartmentLabel
    },
    model: {
      value: _vm.manageScope,
      callback: function ($$v) {
        _vm.manageScope = $$v;
      },
      expression: "manageScope"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "operate-btn"
  }, [_vm.roleInfo.addable ? _c("el-button", {
    attrs: {
      icon: "el-icon-plus",
      type: "primary"
    },
    on: {
      click: _vm.openAddMemberDialog
    }
  }, [_vm._v(_vm._s(_vm.isSuperAdmin ? "添加超级管理员" : "添加管理员"))]) : _vm._e(), _c("ExportButton", {
    attrs: {
      "btn-content": "导出管理员角色",
      content: ` 确定导出当前${_vm.total}个管理员的角色？`,
      "export-api": _vm.exportApi
    }
  })], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    }
  }, _vm._l(_vm.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.field_name,
      attrs: {
        prop: item.field_name,
        label: item.name,
        "show-overflow-tooltip": item.field_name !== "departments" && item.field_name !== "operate"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "custom-header"
          }, [item.field_name === "scope" ? _c("el-tooltip", {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "部门和成员页面管理范围",
              placement: "top"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-info-2"
          })]) : _vm._e(), _c("span", [_vm._v(_vm._s(item.name))])], 1)];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [item.field_name === "name" ? [_c("image-avatar", {
            attrs: {
              name: row.name,
              avatar: row.avatar,
              type: "'host-avatar'"
            }
          }), _c("span", [_vm._v(_vm._s(row.name))]), row.is_creator ? _c("span", {
            staticClass: "creator"
          }, [_vm._v("创建人")]) : _vm._e()] : _vm._e(), item.field_name === "departments" ? [_c("el-tooltip", {
            staticClass: "item",
            attrs: {
              enterable: false,
              "open-delay": 400,
              effect: "dark",
              placement: "top"
            }
          }, [_c("div", {
            ref: "content",
            refInFor: true,
            attrs: {
              slot: "content"
            },
            domProps: {
              innerHTML: _vm._s(_vm.getDepartmentNames(row.departments))
            },
            slot: "content"
          }), _c("div", {
            staticClass: "departments"
          }, _vm._l(row.departments, function (department, index) {
            return _c("span", {
              key: index
            }, [_vm._v(" " + _vm._s(department.super_tree_names[department.super_tree_names.length - 1]) + " "), department.is_main_tree ? _c("i", {
              staticClass: "main-department"
            }, [_vm._v("主部门")]) : _vm._e(), _vm._v(" " + _vm._s(index !== row.departments.length - 1 ? "；" : ""))]);
          }), 0)])] : _vm._e(), item.field_name === "role_names" ? [_c("span", {
            staticClass: "text-color"
          }, [_vm._v(_vm._s(_vm.getRoleNames(row.role_list)))])] : _vm._e(), item.field_name === "scope" ? [row.scope && row.scope.length ? _c("span", {
            staticClass: "text-color"
          }, [_vm._v(_vm._s(_vm.getScopeName(row.scope)))]) : _c("span", {
            staticClass: "unassigned"
          }, [_vm._v("未设置")])] : _vm._e(), item.field_name === "operate" ? [row.operate.change.is_show ? _c("el-button", {
            staticClass: "edit-button",
            attrs: {
              disabled: !row.operate.change.is_active
            },
            on: {
              click: function ($event) {
                return _vm.openDivertDialog(row);
              }
            }
          }, [_vm._v("转移")]) : _vm._e(), row.operate.scope.is_show ? _c("el-button", {
            staticClass: "edit-button",
            attrs: {
              disabled: !row.operate.scope.is_active
            },
            on: {
              click: function ($event) {
                return _vm.openOperateDialog("edit", row);
              }
            }
          }, [_vm._v("编辑")]) : _vm._e(), row.operate.delete.is_show ? _c("el-button", {
            staticClass: "remove-button",
            attrs: {
              disabled: !row.operate.delete.is_active
            },
            on: {
              click: function ($event) {
                return _vm.removeMember(row);
              }
            }
          }, [_vm._v("移除")]) : _vm._e()] : _vm._e()];
        }
      }], null, true)
    });
  }), 1), _c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-sizes": [10, 20, 30, 40],
      "current-page": _vm.pageParams.page,
      "page-size": _vm.pageParams.size,
      total: _vm.total
    },
    on: {
      "size-change": function ($event) {
        return _vm.handleListParamsChange($event, "size");
      },
      "current-change": function ($event) {
        return _vm.handleListParamsChange($event, "page");
      }
    }
  })], 1), _c("divert-member-dialog", {
    attrs: {
      visible: _vm.divertDialogVisible,
      "member-info": _vm.memberInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.divertDialogVisible = $event;
      },
      fetchMemberList: _vm.getAdminRoleMembers
    }
  }), _c("member-single-select-dialog", {
    ref: "addSuperAdmin",
    attrs: {
      visible: _vm.addMemberDialogVisible,
      title: "添加超级管理员"
    },
    on: {
      "update:visible": function ($event) {
        _vm.addMemberDialogVisible = $event;
      },
      submit: _vm.addAdmin
    }
  }), _c("add-admin-dialog", {
    attrs: {
      visible: _vm.addRoleMemberDialogVisible,
      "scope-list": _vm.scopeList,
      "manage-tree": _vm.manageTree,
      title: "添加管理员",
      "has-department-member": _vm.hasDepartmentMember,
      "role-info": _vm.roleInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.addRoleMemberDialogVisible = $event;
      },
      fetchMemberData: _vm.getAdminRoleMembers,
      openEditScopeDialog: function ($event) {
        return _vm.openEditScopeDialog({
          scopeList: $event.scopeList
        });
      }
    }
  }), _c("admin-role-drawer", {
    attrs: {
      "role-drawer-visible": _vm.adminRoleDrawerVisible,
      "role-list": _vm.adminRoleList,
      "operate-role-info": _vm.operateRoleInfo,
      "operate-role-type": _vm.operateRoleType
    },
    on: {
      "update:roleDrawerVisible": function ($event) {
        _vm.adminRoleDrawerVisible = $event;
      },
      "update:role-drawer-visible": function ($event) {
        _vm.adminRoleDrawerVisible = $event;
      },
      fetchRoleList: _vm.fetchRoleList
    }
  }), _c("transer-department-finder-dialog", {
    attrs: {
      visible: _vm.departmentsFinderDialogVisible,
      "default-checked": _vm.defaultChecked,
      "manage-tree": _vm.manageTree
    },
    on: {
      "update:visible": function ($event) {
        _vm.departmentsFinderDialogVisible = $event;
      },
      submit: _vm.submitManageScope
    }
  }), _c("operate-member-dialog", {
    attrs: {
      "dialog-visible": _vm.operateDialogVisible,
      "member-info": _vm.memberInfo,
      type: _vm.operateMemberType,
      page: "管理员",
      "role-list": _vm.allAdminRoleList,
      "manage-tree": _vm.manageTree
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.operateDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.operateDialogVisible = $event;
      },
      fetchMemberData: _vm.getAdminRoleMembers
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;