"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "chart-config",
    attrs: {
      title: "个人能力评分设置",
      "lock-scroll": false,
      visible: _vm.chartConfigVisible,
      "close-on-click-modal": false,
      width: "800px",
      "before-close": _vm.handleClose
    },
    on: {
      open: _vm.getConfig
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getConfigLoading,
      expression: "getConfigLoading"
    }],
    staticClass: "container"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("el-form", {
    ref: "configForm",
    attrs: {
      model: _vm.config,
      inline: true
    }
  }, [_c("div", {
    staticClass: "ability-container"
  }, [_c("p", {
    staticClass: "form-title"
  }, [_vm._v("能力设置")]), _c("p", {
    staticClass: "form-subtitle"
  }, [_c("span", [_vm._v("能力名称")]), _c("span", [_vm._v("能力指标")])]), _c("transition-group", {
    attrs: {
      name: "list"
    }
  }, _vm._l(_vm.config.ability, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "item"
    }, [_c("el-form-item", {
      attrs: {
        prop: `ability.${index}.name`,
        rules: [{
          trigger: "change",
          validator: (rule, value, callback) => _vm.checkLabel(rule, value, callback, "ability", "能力名称")
        }]
      }
    }, [_c("el-input", {
      attrs: {
        placeholder: "请输入能力名称",
        maxlength: "10"
      },
      on: {
        change: function ($event) {
          return _vm.setRadarOptions();
        }
      },
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "item.name"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        prop: `ability.${index}.value`,
        rules: [{
          required: true,
          message: "能力指标不能为空",
          trigger: "change"
        }]
      }
    }, [_c("event-select", {
      ref: "key-event-select",
      refInFor: true,
      attrs: {
        placeholder: "请选择能力指标",
        "is-multiple": "",
        "is-set-disable": "",
        "disabled-list": _vm.keyEventDisabledList
      },
      on: {
        changeEvent: function ($event) {
          return _vm.changeKeyEvent($event, index);
        }
      }
    })], 1), _vm.config.ability.length > 3 ? _c("el-button", {
      staticClass: "delete-btn hover-show-bg",
      attrs: {
        icon: "el-icon-circle-close"
      },
      on: {
        click: function ($event) {
          return _vm.removeConfigItem("ability", index);
        }
      }
    }) : _vm._e()], 1);
  }), 0), _vm.config.ability.length < 8 ? _c("el-button", {
    staticClass: "add-btn",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.addConfigItem("ability");
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "level-container"
  }, [_c("p", {
    staticClass: "form-title"
  }, [_vm._v("等级设置")]), _c("p", {
    staticClass: "form-subtitle"
  }, [_c("span", [_vm._v("等级名称")]), _c("span", [_vm._v("人数占比")])]), _c("transition-group", {
    attrs: {
      name: "list"
    }
  }, _vm._l(_vm.config.level, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "item"
    }, [_c("el-form-item", {
      attrs: {
        prop: `level.${index}.name`,
        rules: [{
          trigger: "change",
          validator: (rule, value, callback) => _vm.checkLabel(rule, value, callback, "level", "等级名称")
        }]
      }
    }, [_c("el-input", {
      attrs: {
        maxlength: "5",
        placeholder: "请输入等级名称"
      },
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "item.name"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        prop: String(`level.${index}.value`),
        rules: [{
          validator: _vm.checkLevelValue
        }]
      }
    }, [_c("el-input", {
      attrs: {
        maxlength: "2",
        minlength: "1",
        placeholder: "请输入人数占比",
        oninput: "value=value.replace(/[^\\d]/g, '');"
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, [_c("i", {
      attrs: {
        slot: "suffix"
      },
      slot: "suffix"
    }, [_vm._v("% ")])])], 1), _vm.config.level.length > 2 ? _c("el-button", {
      staticClass: "delete-btn hover-show-bg",
      attrs: {
        icon: "el-icon-circle-close"
      },
      on: {
        click: function ($event) {
          return _vm.removeConfigItem("level", index);
        }
      }
    }) : _vm._e()], 1);
  }), 0), _vm.config.level.length < 5 ? _c("el-button", {
    staticClass: "add-btn",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.addConfigItem("level");
      }
    }
  }) : _vm._e()], 1)])], 1), _c("div", {
    staticClass: "right"
  }, [_c("p", {
    staticClass: "form-title"
  }, [_vm._v("预览")]), _c("ChartTemplate", {
    attrs: {
      "chart-config": _vm.radarOption
    }
  })], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;