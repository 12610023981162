"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _TimeRange = _interopRequireDefault(require("@/components/TimeRange"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDate',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    TimeRange: _TimeRange.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    keyName: {
      type: String,
      default: 'duration'
    }
  },
  data() {
    return {
      timeOption: [{
        value: [0, 60],
        label: '1分钟内'
      }, {
        value: [60, 'unlimit'],
        label: '1分钟以上'
      }, {
        value: [300, 'unlimit'],
        label: '5分钟以上'
      }],
      isValid: true
    };
  },
  computed: {
    duration() {
      return this.filters[this.keyName];
    }
  },
  watch: {
    duration(val) {
      var _this$filters$this$ke;
      if (Array.isArray(val)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[this.keyName] = {
          unit: 'second',
          value: [val[0] * 60, val[1] === 'unlimit' ? 'unlimit' : val[1] * 60]
        };
      }
      if (!((_this$filters$this$ke = this.filters[this.keyName]) !== null && _this$filters$this$ke !== void 0 && _this$filters$this$ke.value[1])) this.clearTimeChoose();
    },
    deep: true
  },
  methods: {
    clearTimeChoose() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters[this.keyName].value = [0, 'unlimit'];
      this.$refs.timeRange.resetValid();
    },
    handleActiveTime(val) {
      return JSON.stringify(this.filters[this.keyName].value) === JSON.stringify(val);
    },
    selectTime(time) {
      this.$emit('selectTime', time);
      this.$refs.timeRange.resetValid();
    },
    validStatusChange(val) {
      this.isValid = val;
      this.$emit('validStatusChange', val);
    }
  }
};
exports.default = _default;