"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-center"
  }, [_c("navbar", {
    attrs: {
      title: _vm.$t("management.个人中心"),
      "back-button": ""
    }
  }), _c("el-tabs", {
    staticClass: "content-tabs",
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabArray, function (item) {
    return _c("el-tab-pane", {
      key: item.path,
      attrs: {
        label: _vm.$t(`management.${item.meta.subtitle}`),
        name: item.path
      }
    }, [_c("keep-alive", [_vm.activeTab === item.path ? _c("router-view", {
      key: item.path,
      attrs: {
        "personal-info": _vm.personalInfo
      }
    }) : _vm._e()], 1)], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;