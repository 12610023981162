"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icon-color"
  }, [_c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.localIconColor
    }
  }), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _vm._l(_vm.predefineColors, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "color-block",
      class: {
        "select-color": item === _vm.localIconColor
      },
      on: {
        click: function ($event) {
          return _vm.selectColor(item);
        }
      }
    }, [_c("div", {
      style: {
        background: item
      }
    })]);
  }), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("el-color-picker", {
    attrs: {
      "popper-class": "workspace-icon-color-picker",
      size: "mini"
    },
    model: {
      value: _vm.localIconColor,
      callback: function ($$v) {
        _vm.localIconColor = $$v;
      },
      expression: "localIconColor"
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;