"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "话术挖掘引擎",
      "custom-class": "discovery-engine",
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "dialog-title"
        }, [_vm._v("话术挖掘引擎")]), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "话术挖掘引擎能帮您从会话中找到优秀话术。我们根据已填写的客户问题，从会话中挖掘相关的回答。您可以筛选结果，找到优秀话术，将其添加到知识点中。",
            placement: "bottom"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })]), _c("space-button")];
      },
      proxy: true
    }])
  }, [_vm.visible ? _c("div", {
    staticClass: "main"
  }, [_c("tricks-library", {
    attrs: {
      "knowledge-id": _vm.knowledgeId,
      list: _vm.list
    },
    on: {
      listChange: _vm.handleListChange
    }
  }), _c("standard-tricks", {
    ref: "standardTricks",
    attrs: {
      "knowledge-id": _vm.knowledgeId,
      list: _vm.list
    }
  })], 1) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "mg-round-button",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    staticClass: "mg-round-button",
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;