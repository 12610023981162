"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "book-meeting"
  }, [_c("el-button", {
    staticClass: "meeting-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.bookMeetingDialogVisible = true;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "reservation-2"
    }
  }), _vm._v(" 预约会议")], 1), _c("book-meeting-dialog", {
    attrs: {
      visible: _vm.bookMeetingDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.bookMeetingDialogVisible = $event;
      },
      success: _vm.openInviteDialog
    }
  }), _c("invite-succeed-dialog", {
    attrs: {
      "invite-text": _vm.inviteText,
      visible: _vm.inviteSucceedDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.inviteSucceedDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;