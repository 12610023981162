"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "select",
    staticClass: "snippets-library-search",
    attrs: {
      "popper-class": "snippets-library-search-popper",
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.handleSearch,
      placeholder: "搜索"
    },
    on: {
      change: _vm.handleChange,
      "visible-change": _vm.handleVisibleChange
    },
    model: {
      value: _vm.selectedVal,
      callback: function ($$v) {
        _vm.selectedVal = $$v;
      },
      expression: "selectedVal"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-search"
  })]), _vm._l(_vm.options, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("option-template", {
      attrs: {
        item: item
      }
    })], 1);
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;