"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _coachingCenter = require("@/api/coachingCenter");
var _KnowledgeLibraryCatalog = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeLibraryCatalog.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CourseCategoryTreeSelect',
  components: {
    KnowledgeLibraryCatalog: _KnowledgeLibraryCatalog.default
  },
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('coaching.selectClassification');
      }
    }
  },
  data() {
    return {
      label: '',
      catalogData: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo'])
  },
  created() {
    this.getCourseCategoryData();
  },
  methods: {
    handleChange() {
      // 下拉暂无数据时
      if (!this.catalogData.length) this.label = '';
      return;
    },
    async getCourseCategoryData() {
      const res = await (0, _coachingCenter.getCourseCategoryData)();
      if (res.code === 20000) {
        this.catalogData = res.results.list;
        this.backfillData();
      }
    },
    handleNodeClick(val) {
      this.label = val.name;
      this.$emit('positionSelect', val);
      this.$refs.select.blur();
    },
    backfillData() {
      if (!Object.keys(this.currentNode).length) return;
      this.label = this.currentNode.name;
      this.$nextTick(() => {
        this.$refs.catalog.handleCurrentNodeChange(this.currentNode);
      });
      this.$emit('positionSelect', this.currentNode);
    }
  }
};
exports.default = _default;