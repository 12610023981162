"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _clues = require("@/api/clues");
var _business = require("@/api/business");
var _client = require("@/api/client");
var _errorLog = require("@/utils/error-log");
var _call = require("@/api/call");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'PhoneCall',
  directives: {
    stopdrag: {
      inserted: function (el, binding, vnode) {
        const element = el;
        element.onmousedown = function (e) {
          e.stopPropagation();
        };
      }
    }
  },
  props: {
    seatInited: {
      type: Boolean,
      default: false
    },
    // 坐席准备好了
    answerType: {
      type: String,
      default: 'sip'
    }
  },
  data() {
    return {
      mute: false,
      // 是否静音
      callHold: false,
      // 通话保持
      showTelDial: false,
      // 显示拨号盘
      conversationType: 'lead',
      // 会话类型， 线索和商机
      detailId: '',
      // 线索或者商机id
      isDetailActive: false,
      // 是否在当前线索或者商机页面
      contact_person: '',
      ringingTimer: null,
      ringingValue: '正在呼叫',
      status: 'INIT',
      statusValue: '00:00',
      phoneNumber: '',
      callPage: '/edb/html/webmain.html',
      timeToInit: false,
      conversation_id: null,
      recordSecond: 0,
      recordMinute: 0,
      recordHour: 0,
      timer: null,
      time: '',
      bodyHeight: null,
      showLeftArrow: false,
      showDownArrow: true,
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'],
      phone7MData: null,
      isError: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'callNoteVisible', 'callConverId', 'orgName', 'name']),
    getWrapperHeight() {
      if (this.bodyHeight && this.bodyHeight < 680) {
        return this.bodyHeight + 'px';
      } else {
        return '680px';
      }
    }
  },
  watch: {
    status(newVal, oldVal) {
      if (oldVal === 'RINGING') {
        clearInterval(this.ringingTimer);
      }
      if (this.status === 'RINGING') {
        this.$emit('isRinging');
        this.startRinging();
        this.isError = false;
      } else if (this.status === 'HANGUP') {
        clearInterval(this.timer);
        this.sendIframeMsg('', 'hangup', 'oneStopCallIframe');
        this.statusValue = '初始化中';
        this.initTimeValue();
        this.initCallOperation();
        this.$store.commit('call/SET_NOTE_VISIBLE', false);
        this.$store.commit('call/SET_IS_CLOSE_NOTE', true);
      } else if (this.status === 'INIT') {
        this.statusValue = '初始化中';
        this.isError = false;
      } else if (this.status === 'CALLING') {
        this.recordTime();
        this.$emit('isCalled');
        this.$store.commit('call/SET_NOTE_VISIBLE', true);
        this.$store.commit('call/SET_IS_CLOSE_NOTE', false);
      } else if (this.status === 'INCOMINGRING') {
        this.statusValue = '呼入响铃';
        this.time = '';
        this.$emit('isRinging');
        // if (this.phone7MData) {
        //   let data = {
        //     phone: this.phone7MData.CallNo,
        //     needCreateLead: true,
        //     callSheetId: this.phone7MData.CallsheetId,
        //   };
        //   this.init(data, true); //true表示是来电接听类型，不需要主动触发拨打
        //   this.$emit("isRinging");
        // }
        if (this.phone7MData) {
          this.contact_person = '陌生号码';
          this.phoneNumber = this.phone7MData.CallNo;
        }
        this.isError = false;
      } else if (this.status === 'INCOMINGCALLING') {
        //
        this.recordTime();
        this.$emit('isCalled');
        // this.$store.commit("call/SET_NOTE_VISIBLE", true);
        this.isError = false;
      } else if (this.status === 'NO_DEVICE') {
        this.statusValue = '无音频设备！';
        this.$message.warning('无音频设备！');
        this.isError = true;
        this.handleHangup();
      } else if (this.status === 'NO_PERMISSION') {
        this.statusValue = '浏览器麦克风权限未打开！';
        this.$message.warning('浏览器麦克风权限未打开！');
        this.isError = true;
        this.handleHangup();
      } else if (this.status === 'NETWORK ERROR') {
        this.statusValue = '无网络！';
        this.$message.warning('当前无网络');
        this.isError = true;
        this.handleHangup();
      }
      this.$emit('callStatusChange', newVal, this.isError);
    },
    $route: {
      handler: function (val) {
        const isPathActive = val.name === 'CluesDetail' && this.conversationType === 'lead' || val.name === 'BusinessDetail' && this.conversationType === 'deal';
        let isIdActive = false;
        if (val.query && val.query.id && val.query.id === this.detailId) {
          isIdActive = true;
        }
        if (isIdActive && isPathActive) {
          this.isDetailActive = true;
        } else {
          this.isDetailActive = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.closeCallDialog = this.closeCallDialog;
    window.frameInit = this.frameInit;
    window.phoneStatusChange = this.phoneStatusChange;
    window.startRecordTime = this.startRecordTime;
    window.clog = this.clog;
    this.bodyHeight = document.body.offsetHeight;
    const _this = this;
    window.addEventListener('online', () => {
      _this.clog('网络状态  已联网', 'info');
    });
    window.addEventListener('offline', function () {
      _this.$message.warning('您的网络已断开，请检查网络');
      _this.clog('网络状态  网络断开', 'info');
    });
  },
  methods: {
    // 所有外呼的主入口
    async init(val) {
      let isIncoming = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (val.needCreateLead) {
        // 根据返回的数据重新生成 val
        const resInfo = await this.checkNeedCreateLead(val);
        this.createMeetingByInfo(resInfo, isIncoming);
      } else {
        this.createMeetingByInfo(val);
      }
    },
    /**
     * @params Boolean isIncoming : 是否是来电
     */
    createMeetingByInfo(val) {
      let isIncoming = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.contact_person = val.contact_person;
      this.phoneNumber = val.phone;
      const _dateTime = this.$moment().format('YYYY-MM-DD') + ' ' + this.$moment().format('HH:mm');
      const _end = this.$moment(_dateTime).add(30, 'minutes');
      const _endTime = this.$moment(_end).format('YYYY-MM-DD') + ' ' + this.$moment(_end).format('HH:mm');
      const _meeting_name = `${this.orgName + this.name} 与 ${val.company ? val.company : ''}${val.contact_person ? val.contact_person : ' ' + val.phone + ' '}
      的通话`;
      const formData = {
        meeting_name: _meeting_name,
        begin_time: _dateTime,
        end_time: _endTime,
        contact_id: val.contact_id,
        host_salesman_id: this.userId,
        assistant_salesman_ids: [],
        spectator_salesman_ids: [],
        type: 'audio',
        call_sheet_id: val.callSheetId ? val.callSheetId : ''
      };
      if (val.type === 'clue') {
        this.conversationType = 'lead';
        this.detailId = val.id;
        formData.lead_id = val.id;
        // 线索创建会话
        (0, _clues.clueCreateMeeting)(formData).then(res => {
          if (res.code === 200) {
            const conversation_id = res.results.conversation_id;
            this.handleCreatdMeeting(val, conversation_id, isIncoming);
          }
        });
      } else if (val.type === 'deal') {
        this.conversationType = 'deal';
        this.detailId = val.id;
        formData.deal_id = val.id;
        // 商机创建会话
        (0, _business.businessCreateMeeting)(formData).then(res => {
          if (res.code === 200) {
            const conversation_id = res.results.conversation_id;
            this.handleCreatdMeeting(val, conversation_id, isIncoming);
          }
        });
      } else if (val.type === 'account') {
        this.conversationType = 'account';
        this.detailId = val.id;
        formData.account_id = val.id;
        // 客户创建会话
        (0, _client.accountCreateMeeting)(formData).then(res => {
          if (res.code === 200) {
            const conversation_id = res.results.conversation_id;
            this.handleCreatdMeeting(val, conversation_id, isIncoming);
          }
        });
      }
    },
    async checkNeedCreateLead(val) {
      try {
        const res = await (0, _call.getInfoByTel)(val.phone);
        if (res.code === 200) {
          const {
            id,
            contact_id,
            name,
            type
          } = res.results;
          if (id) {
            // 如果能获取到对应的信息，则返回创建会话需要的信息
            this.contact_person = name;
            this.phoneNumber = val.phone;
            const resObj = {
              phone: val.phone,
              contact_id,
              contact_person: name,
              id,
              type: type === 'deal' ? 'deal' : 'clue',
              callSheetId: val.callSheetId
            };
            this.$emit('checkIsNewLead', false);
            return resObj;
          } else {
            // 如果没有对应信息，先用手机号创建新线索，再返回创建线索后的信息
            this.$emit('checkIsNewLead', true);
            const leadInfo = await this.getObjByCreateClue(val);
            return leadInfo;
          }
        }
      } catch (error) {
        const leadInfo = await this.getObjByCreateClue(val);
        return leadInfo;
      }
    },
    async getObjByCreateClue(data) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          this.contact_person = '';
          this.phoneNumber = data.phone;
          const resInfo = await this.createClues(data.phone);
          if (resInfo) {
            const {
              lead_id
            } = resInfo;
            const resObj = {
              phone: data.phone,
              contact_id: 0,
              contact_person: '',
              id: lead_id,
              type: 'clue',
              callSheetId: data.callSheetId
            };
            resolve(resObj);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async createClues(tel) {
      const form = {
        // company_name: "",
        // lead_source: "",
        // industry: "",
        // employee_count: "",
        // contact: JSON.stringify({ id: 0, name: "", tel, email: "" }),
        tel
      };
      const res = await (0, _clues.createClues)(form);
      if (res.code === 200) {
        // return 返回的线索id和联系人id
        return res.results;
      } else {
        return false;
      }
    },
    handleCreatdMeeting(info, conversation_id) {
      let isIncoming = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.$store.commit('call/SET_CONVERSATION_ID', conversation_id);
      this.$store.commit('call/SET_CUSTOMER_NAME', info.company);
      if (this.conversationType === 'deal') {
        this.$store.commit('call/SET_DEAL_ID', this.detailId);
      } else {
        this.$store.commit('call/SET_LEAD_ID', this.detailId);
      }
      if (isIncoming) {
        // 如果是来电不用拨打电话
        return;
      }
      if (this.answerType === 'Local' && !isIncoming) {
        this.$message.info('当前是手机接听模式，请使用绑定的手机接听');
      }
      var timer2 = setInterval(() => {
        if (this.seatInited) {
          this.sendIframeMsg({
            tel: info.phone,
            conversation_id: conversation_id
          }, 'call', 'oneStopCallIframe');
          clearInterval(timer2);
        }
      }, 500);
    },
    handleNoteClick() {
      if (this.callNoteVisible) {
        this.$store.commit('call/SET_NOTE_VISIBLE', false);
        if (this.status === 'HANGUP') {
          this.closeCallDialog();
        }
      } else {
        this.$store.commit('call/SET_NOTE_VISIBLE', true);
      }
    },
    handleMuteClick() {
      this.sendIframeMsg('', 'mute', 'oneStopCallIframe');
      if (this.mute) {
        this.mute = false;
      } else {
        this.mute = true;
      }
    },
    handleHoldClick() {
      if (this.callHold) {
        this.callHold = false;
        this.sendIframeMsg('', 'unhold', 'oneStopCallIframe');
      } else {
        this.callHold = true;
        this.sendIframeMsg('', 'hold', 'oneStopCallIframe');
      }
    },
    handleTelDialClick() {
      this.showTelDial = !this.showTelDial;
    },
    handleDetailClick() {
      if (!this.detailId) {
        return;
      }
      if (this.conversationType === 'lead') {
        this.$router.push({
          path: _routeMap.ROUTES.leadDetail,
          query: {
            id: this.detailId
          }
        });
      } else {
        this.$router.push({
          path: _routeMap.ROUTES.dealDetail,
          query: {
            id: this.detailId
          }
        });
      }
    },
    handleHangup() {
      this.initTimeValue();
      clearInterval(this.timer);
      this.status = 'HANGUP';
      this.sendIframeMsg('', 'hangup', 'oneStopCallIframe');
    },
    handleAnswerCall() {
      this.sendIframeMsg('', 'answerCall', 'oneStopCallIframe');
    },
    closeCallDialog() {
      // this.timeToInit = false;
      // this.seatInited = false;
      // this.handleHangup();
      this.$bus.$emit('closePhoneCall');
    },
    sendIframeMsg(val, type, iframe_name) {
      window.frames[iframe_name].postMessage({
        type: type,
        params: val
      }, '*');
    },
    frameInit() {
      this.timeToInit = true;
    },
    phoneStatusChange(status, data) {
      this.status = status;
      if (data) {
        this.phone7MData = data;
      }
    },
    startRinging() {
      this.ringingTimer = setInterval(() => {
        if (this.status !== 'RINGING') {
          clearInterval(this.ringingTimer);
        }
        if (this.ringingValue.length < 7) {
          this.ringingValue += '.';
        } else {
          this.ringingValue = '正在呼叫';
        }
        this.statusValue = this.ringingValue;
      }, 1000);
      // this.$emit('openCallCard')
    },

    startRecordTime() {
      this.status = 'CALLING';
      // if (this.status === "RINGING") {
      //   this.status = "CALLING";
      // }
    },

    recordTime() {
      this.timer = setInterval(() => {
        this.recordSecond += 1;
        if (this.recordSecond === 60) {
          this.recordSecond = 0;
          this.recordMinute += 1;
        }
        if (this.recordMinute === 60) {
          this.recordMinute = 0;
          this.recordHour += 1;
        }
        let _h = '';
        let _m = '';
        let _s = '';
        if (this.recordHour != 0) {
          if (this.recordHour >= 10) {
            _h = this.recordHour.toString() + ':';
          } else {
            _h = '0' + this.recordHour.toString() + ':';
          }
        }
        if (this.recordMinute >= 10) {
          _m = this.recordMinute.toString() + ':';
        } else {
          _m = '0' + this.recordMinute.toString() + ':';
        }
        if (this.recordSecond >= 10) {
          _s = this.recordSecond.toString();
        } else {
          _s = '0' + this.recordSecond.toString();
        }
        this.$nextTick(() => {
          this.time = _h + _m + _s;
          this.statusValue = _h + _m + _s;
          this.$store.commit('call/SET_NOTE_TIME', this.time);
        });
      }, 1000);
    },
    initTimeValue() {
      this.recordSecond = 0;
      this.recordMinute = 0;
      this.recordHour = 0;
    },
    initCallOperation() {
      this.mute = false;
      this.callHold = false;
      this.showTelDial = false;
    },
    clog(e) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      (0, _errorLog.postErrorLog)('7m外呼:', e, type);
    }
  }
};
exports.default = _default;