"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "converItem" + _vm.item.id,
    staticClass: "list-card-item",
    on: {
      click: function ($event) {
        return _vm.goDetails(_vm.item.id);
      }
    }
  }, [_c("div", {
    staticClass: "item-left"
  }, [_vm.item.type === "audio" ? _c("div", {
    staticClass: "call-img"
  }, [_c("i", {
    staticClass: "iconfont icon-phone-outline"
  })]) : _vm._e(), _vm.item.type === "doc" ? _c("div", {
    staticClass: "call-img"
  }, [_c("i", {
    staticClass: "iconfont icon-im"
  })]) : _vm._e(), _vm.item.type === "video" && _vm.item.thumbnail ? _c("el-image", {
    staticClass: "video-img",
    attrs: {
      src: _vm.item.thumbnail,
      fit: "cover"
    }
  }, [_c("div", {
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_vm.item.type === "audio" ? _c("i", {
    staticClass: "iconfont icon-phone-outline"
  }) : _vm._e(), _vm.item.type === "doc" ? _c("i", {
    staticClass: "iconfont icon-im"
  }) : _vm._e(), _vm.item.type === "video" ? _c("i", {
    staticClass: "iconfont icon-video-camera"
  }) : _vm._e()])]) : _vm._e(), _vm.item.type !== "doc" ? _c("div", {
    staticClass: "duration-box",
    domProps: {
      innerHTML: _vm._s(_vm.getDuration(_vm.item.duration))
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "item-right"
  }, [_c("div", {
    staticClass: "item-right-top"
  }, [_c("div", {
    staticClass: "base-info"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("div", {
    staticClass: "item-infos"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话日期和时间",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-date i-light-color"
  }), _c("span", {
    staticClass: "date-light-color"
  }, [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.item[_vm.timeField]).format("YYYY-MM-DD HH:mm")))])])]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "客户",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.company_name,
      expression: "item.company_name"
    }],
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-office-building"
  }), _c("span", [_vm._v(_vm._s(_vm.item.company_name))])])]), this.activeName !== "my" ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "销售",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_vm.item.user_info ? _c("div", {
    staticClass: "info-content"
  }, [_vm.item.user_info.avatar || _vm.item.user_info.name ? _c("image-avatar", {
    attrs: {
      avatar: _vm.item.user_info.avatar,
      name: _vm.item.user_info.name
    }
  }) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.item.user_info.name))])], 1) : _vm._e()]) : _vm._e(), _vm.activeName !== "my" ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "评论数",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.comment_count,
      expression: "item.comment_count"
    }],
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-chat-line-square"
  }), _c("span", [_vm._v(_vm._s(_vm.item.comment_count))])])]) : _vm._e()], 1), _vm.activeName !== "my" ? _c("div", {
    staticClass: "item-other-info"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话时交易阶段",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.entity_type === "deal" && _vm.item.entity_type,
      expression: "item.entity_type === 'deal' && item.entity_type"
    }],
    staticClass: "info-tag"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-deal5"
  }), _c("span", [_vm._v(_vm._s(_vm.item.deal_stage))])])])], 1) : _vm._e()])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;