"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("mg-infinite-scroll", {
    staticClass: "comment-body",
    class: {
      "background-white": _vm.noComment
    },
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore,
      "nomore-text": _vm.noComment ? "" : "没有更多了"
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, [_vm._l(_vm.commentsList, function (item) {
    return [item.comments.length ? _c("el-card", {
      key: item.id,
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", {
      staticClass: "title-unit",
      on: {
        click: function ($event) {
          return _vm.goDetails(item.id, item.type);
        }
      }
    }, [_vm._v("来自" + _vm._s(_vm.judgeCommentType(item.type)) + "：" + _vm._s(item.name) + " ")])]), _vm._l(item.comments, function (comment) {
      return _c("div", {
        key: comment.comment_id,
        staticClass: "comment-unit"
      }, [_c("comment-unit", {
        attrs: {
          unit: comment,
          "is-only-display": false,
          "has-timestamp": item.type !== "business"
        }
      })], 1);
    })], 2) : _vm._e()];
  }), _vm.noComment ? _c("div", {
    staticClass: "no-comment"
  }, [_c("div", {
    staticClass: "no-comment-icon"
  }, [_c("i", {
    staticClass: "el-icon-chat-line-square"
  })]), _vm._v(" 暂无评论 ")]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;