"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PermissionsTree = _interopRequireDefault(require("@/components/PermissionsTree"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AdminRoleDrawer',
  components: {
    PermissionsTree: _PermissionsTree.default
  },
  props: {
    roleDrawerVisible: {
      type: Boolean,
      default: false
    },
    roleList: {
      type: Array,
      default: () => []
    },
    operateRoleInfo: {
      type: Object,
      default: () => ({})
    },
    operateRoleType: {
      type: String,
      default: ''
    }
  },
  data() {
    const repeatNameValidator = (rule, value, callback) => {
      if (this.operateRoleType !== 'edit') {
        if (this.roleList.some(item => item.name === value)) {
          callback(new Error('此名称与已有名称重复'));
        }
      } else {
        const roleList = this.roleList.filter(item => item.name !== this.operateRoleInfo.name);
        if (roleList.some(item => item.name === value)) {
          callback(new Error('此名称与已有名称重复'));
        }
      }
      callback();
    };
    return {
      pageChecked: [],
      adminRoleForm: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          validator: repeatNameValidator,
          trigger: 'change'
        }]
      },
      checkedList: [],
      permissions: [],
      buttonLoading: false,
      drawerLoading: false
    };
  },
  computed: {},
  watch: {
    roleDrawerVisible(val) {
      if (val) {
        this.checkedList = [];
        if (this.operateRoleInfo.name) {
          this.adminRoleForm.name = this.operateRoleInfo.name;
        } else {
          this.adminRoleForm.name = '';
        }
        this.getAdminRolePermissions();
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    getTitle() {
      const title = {
        add: '新建管理员角色',
        edit: '编辑管理员角色',
        copy: '复制管理员角色'
      };
      return title[this.operateRoleType];
    },
    recursionCheckbox(tree, checkedList) {
      tree.forEach(item => {
        if (item.is_chosen) {
          checkedList.push(item.id);
        }
        if (!item.is_valid) {
          this.$set(item, 'disabled', true);
        }
        if (item.children && item.children.length) {
          this.recursionCheckbox(item.children, checkedList);
        }
      });
    },
    handleDisableNode(tree, checkedList) {
      tree.forEach(item => {
        if (item.is_valid) {
          if (item.depend[0] === -1) {
            this.$set(item, 'disabled', false);
          } else {
            const res = item.depend.every(dependId => checkedList.includes(dependId));
            if (res) {
              this.$set(item, 'disabled', false);
            } else {
              if (checkedList.includes(item.id)) {
                this.$nextTick(() => {
                  var _currentNode$parent;
                  const currentNode = this.$refs['pageTree'].$refs['PermissionsTree'].getNode(item.id);
                  currentNode.checked = false;
                  const siblingNodeState = !!((_currentNode$parent = currentNode.parent) !== null && _currentNode$parent !== void 0 && _currentNode$parent.childNodes.filter(item => item.checked).length);
                  const parentIds = this.findParent(this.permissions, item.id);
                  if (siblingNodeState) {
                    const index = this.checkedList.findIndex(c => c === item.id);
                    this.checkedList.splice(index, 1);
                  } else {
                    this.checkedList = this.checkedList.filter(item1 => !parentIds.some(item2 => item1 === item2));
                  }
                });
              }
              this.$set(item, 'disabled', true);
            }
          }
        }
        if (item.children && item.children.length) {
          this.handleDisableNode(item.children, checkedList);
        }
      });
    },
    handleCheckChange(data, node) {
      this.$nextTick(() => {
        // 拿到当前节点
        const currentNode = this.$refs['pageTree'].$refs['PermissionsTree'].getNode(data);
        // 处理当前节点的父级节点
        this.handleParentChecked(currentNode);
        // 获取所有选中的值
        this.checkedList = this.$refs.pageTree.$refs.PermissionsTree.getCheckedKeys();
        // 通过选中的值禁用
        this.handleDisableNode(this.permissions, this.checkedList);
        // 设置选中的值
        this.$refs.pageTree.$refs.PermissionsTree.setCheckedKeys(this.checkedList);
      });
    },
    handleParentChecked(node) {
      if (node && node.parent) {
        var _node$parent;
        // 当前节点兄弟节点的状态
        const siblingNodeState = !!((_node$parent = node.parent) !== null && _node$parent !== void 0 && _node$parent.childNodes.filter(item => item.checked).length);
        node.parent.checked = siblingNodeState;
        this.handleParentChecked(node.parent);
      }
    },
    sortTree(tree) {
      tree.forEach(item => {
        if (item.children && item.children.length) {
          item.children.sort(this.compare('order'));
          this.sortTree(item.children);
        }
      });
    },
    compare(property) {
      return function (obj1, obj2) {
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2; // 升序
      };
    },

    async getAdminRolePermissions() {
      this.drawerLoading = true;
      try {
        const res = await (0, _permissions.getAdminRolePermissions)({
          role_id: this.operateRoleInfo.id
        });
        if (res.code === 200) {
          this.permissions = res.results.permission_tree;
          if (this.permissions.length) {
            // 给根节点排序
            this.permissions.sort(this.compare('order'));
            // 给每个根节点下的孩子节点排序
            this.sortTree(this.permissions);
            this.recursionCheckbox(this.permissions, this.checkedList);
            this.handleDisableNode(this.permissions, this.checkedList);
          }
        }
      } finally {
        this.drawerLoading = false;
      }
    },
    save() {
      this.$refs.adminRoleForm.validate(async valid => {
        if (valid) {
          const params = {
            permissions: this.checkedList,
            name: this.adminRoleForm.name
          };
          this.buttonLoading = true;
          if (this.operateRoleType === 'add' || this.operateRoleType === 'copy') {
            try {
              const res = await (0, _permissions.createAdminRole)(params);
              if (res.code === 20000) {
                this.handleClose();
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.$emit('fetchRoleList', res.results);
              }
            } finally {
              this.buttonLoading = false;
            }
          } else {
            const data = {
              role_id: this.operateRoleInfo.id,
              ...params
            };
            try {
              const res = await (0, _permissions.updateAdminRole)(data);
              if (res.code === 200) {
                this.handleClose();
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.$emit('fetchRoleList', this.operateRoleInfo);
              }
            } finally {
              this.buttonLoading = false;
            }
          }
        }
      });
    },
    handleClose() {
      this.$refs.adminRoleForm.resetFields();
      this.$emit('update:roleDrawerVisible', false);
    }
  }
};
exports.default = _default;