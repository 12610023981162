"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-title-link",
    class: _vm.taskTitleClass,
    on: {
      click: _vm.goToDetail
    }
  }, [_c("el-tooltip", {
    attrs: {
      content: "完成",
      placement: "top",
      "open-delay": 200,
      disabled: _vm.getCompleteDisable
    }
  }, [_vm.taskInfo.cur_user_status !== -1 ? _c("div", {
    staticClass: "complete-btn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleIsDoneChange();
      }
    }
  }, [_c("el-checkbox", {
    attrs: {
      value: _vm.getCheckValue,
      disabled: _vm.getCompleteDisable
    }
  })], 1) : _vm._e()]), _vm.taskInfo.entity_type ? _c("i", {
    class: _vm.contentIconClass
  }) : _vm._e(), _vm.taskInfo.title || _vm.taskInfo.content ? [_c("span", {
    domProps: {
      innerHTML: _vm._s(_vm.getTitleHtml(_vm.taskInfo.title || _vm.taskInfo.content))
    }
  })] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;