"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    ref: "PermissionsTree",
    attrs: {
      id: "PermissionsTree",
      data: _vm.permissions,
      "show-checkbox": "",
      "default-expand-all": "",
      "node-key": "id",
      "check-strictly": "",
      props: _vm.defaultProps,
      "expand-on-click-node": false,
      "default-checked-keys": _vm.checkedList
    },
    on: {
      check: _vm.handleCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node",
          class: {
            "is-permissions-tree-leaf": data.is_leaf,
            "is-permissions-tree-root": data.is_root
          }
        }, [_vm._v(" " + _vm._s(data.name) + " "), data.tooltip ? _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: data.tooltip,
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-2"
        })]) : _vm._e()], 1)];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;