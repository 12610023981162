"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _configDetailShow = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/configDetailShow"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamAnalysisTable',
  components: {
    SortCaret: _sortCaret.default,
    ImageAvatar: _ImageAvatar.default,
    ConfigDetailShow: _configDetailShow.default
  },
  props: {
    tableHeader: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    isShowCheckbox: {
      type: Boolean,
      default: false
    },
    dimension: {
      type: String,
      default: 'department'
    },
    hasHandlePermission: {
      type: Boolean,
      default: false
    },
    currenKanbanId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      teamAnalysisTableColumnWidthConfig: {},
      currPage: 1,
      pageSize: 20,
      total: 0,
      // 创建 A/B 测试列表
      checkList: []
    };
  },
  computed: {
    eachPageTableData() {
      const start = (this.currPage - 1) * this.pageSize;
      const end = this.currPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
    checkedHeaderCell() {
      return function (id) {
        const chenckedIdList = this.checkList.map(_ref => {
          let {
            id
          } = _ref;
          return id;
        });
        return chenckedIdList.includes(id);
      };
    }
  },
  watch: {
    tableData(val) {
      this.$nextTick(() => {
        this.$refs['team-analysis-table'].doLayout();
      });
    }
  },
  created() {
    this.teamAnalysisTableColumnWidthConfig = JSON.parse(localStorage.getItem('teamAnalysisTableColumnWidthConfig') || '{}');
  },
  methods: {
    async headerDragend(newWidth, oldWidth, column) {
      this.teamAnalysisTableColumnWidthConfig[column.property] = parseInt(newWidth);
      localStorage.setItem(`teamAnalysisTableColumnWidthConfig`, JSON.stringify(this.teamAnalysisTableColumnWidthConfig));
    },
    checkedCard(card_id) {
      if (!this.isShowCheckbox) return;
      const index = this.checkList.findIndex(item => item === card_id);
      if (index === -1) {
        this.checkList.push(card_id);
      } else {
        this.checkList.splice(index, 1);
      }
    },
    handleCheckColumn(item) {
      if (!this.isShowCheckbox) return;
      const {
        id,
        configs
      } = item;
      const index = this.checkList.findIndex(item => item.id === id);
      if (index === -1) {
        this.checkList.push({
          id,
          name: configs.name,
          configs,
          filter_config: {
            event_filters: {
              keyEventCascaderList: [{
                keyEventName: [],
                inverse: false
              }],
              event_engine_relation: 'and'
            },
            custom_filters: {}
          }
        });
      } else {
        this.checkList.splice(index, 1);
      }
      this.$emit('checkedChange', this.checkList);
    },
    goPersonalOverview(_ref2) {
      let {
        id,
        name
      } = _ref2;
      if (this.dimension === 'department') return;
      this.$router.push({
        path: _routeMap.ROUTES.biPersonalOverview,
        query: {
          id,
          name,
          kanban_id: this.currenKanbanId
        }
      });
    },
    colorCell(rowData) {
      if (rowData.value && Math.abs(rowData.ratio) / rowData.value > 0.2) {
        return rowData.ratio > 0 ? 'ascend-cell-detail' : 'descend-cell-detail';
      }
      return '';
    },
    cellClassName(_ref3) {
      let {
        rowIndex,
        columnIndex
      } = _ref3;
      if (rowIndex !== this.tableData.length - 1 && columnIndex === this.tableHeader.length + 1) {
        return 'no-border-column';
      }
    },
    // 排序
    handleSortChange(sort_by, sort_order) {
      if (this.isShowCheckbox || !sort_order) return;
      this.handleCurrentChange(1);
      this.columnSort(sort_by, sort_order === 'ascending' ? 1 : -1);
      this.sortInitOther(sort_by);
    },
    columnSort(index, orderType) {
      this.$emit('sortTableData', index, orderType);
    },
    sortInitOther(sort_by) {
      this.tableHeader.forEach((_, index) => {
        if (index !== sort_by) {
          const element = this.$refs['sort-caret' + index];
          if (element && element.length) {
            element[0].init();
          }
        }
      });
    },
    handleCommand(type, data) {
      if (this.isShowCheckbox) return;
      this.$emit('editColumn', type, data);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currPage = 1;
    },
    handleCurrentChange(val) {
      this.currPage = val;
    }
  }
};
exports.default = _default;