"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _user = require("@/api/user");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'ChooseTeamComp',
  data() {
    return {
      redirect: undefined,
      type: 'login',
      // 默认：登录
      orgName: '',
      // 组织名称
      code: '',
      showTeamChoice: false,
      invitations: null,
      organizations: null,
      upCreateVisible: false,
      orgForm: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '公司名不能为空',
          trigger: 'blur'
        }]
      },
      userRole: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['token', 'orgId', 'isDeveloper', 'login_account']),
    isCreator() {
      return this.userRole.indexOf('creator') !== -1;
    },
    isAdmin() {
      return this.userRole.indexOf('admin') !== -1;
    },
    hasTeamShow() {
      var _this$invitations, _this$organizations;
      return ((_this$invitations = this.invitations) === null || _this$invitations === void 0 ? void 0 : _this$invitations.length) > 0 || ((_this$organizations = this.organizations) === null || _this$organizations === void 0 ? void 0 : _this$organizations.length) > 0;
    },
    hasNoTeamShow() {
      var _this$invitations2, _this$organizations2;
      return ((_this$invitations2 = this.invitations) === null || _this$invitations2 === void 0 ? void 0 : _this$invitations2.length) === 0 && ((_this$organizations2 = this.organizations) === null || _this$organizations2 === void 0 ? void 0 : _this$organizations2.length) === 0;
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.type = route.query && route.query.type ? route.query.type : 'login';
        this.redirect = route.query && route.query.redirect;
        this.query = route.query && route.query.query;
        if (route.query.code) {
          this.code = route.query && route.query.code;
        }
      },
      immediate: true
    },
    // 根据token变化，若有token则请求团队列表接口并切换为选择团队
    token(val, oldval) {
      // this.tokenHandler();
    }
  },
  created() {
    this.tokenHandler();
  },
  methods: {
    async tokenHandler() {
      if (this.token) {
        (0, _user.getOrgList)({
          is_developer: this.isDeveloper
        }).then(async res => {
          const organizations = res.results.organizations;
          const invitations = res.results.invitations;
          this.organizations = organizations;
          this.invitations = invitations;
          // 如果只有一个团队且没有邀请团队,则直接进入系统
          if (invitations.length === 0 && organizations.length === 1) {
            this.goOrg(organizations[0].organization_id);
          } else {
            // 有多个团队或者有邀请团队则进入团队选择页
            let toPath = _routeMap.ROUTES.chooseTeam;
            if (this.$route.path.indexOf('xiaozhan') !== -1) {
              toPath = `/xiaozhan${_routeMap.ROUTES.chooseTeam}`;
            }
            this.$router.push({
              path: toPath,
              query: this.$route.query
            });
          }
          // 存本地判断是否用户只有一个团队,如果只有一个团队,导航栏不显示切换公司
          if (organizations.length < 2) {
            window.sessionStorage.setItem('isOnlyOneOrg', 'true');
          } else {
            window.sessionStorage.setItem('isOnlyOneOrg', '');
          }
        });
      } else {
        this.$router.push({
          path: _routeMap.ROUTES.login
        });
      }
    },
    getCheckCreated() {
      return new Promise((resolve, reject) => {
        (0, _user.getCheckCreated)().then(res => {
          if (res.code === 200) {
            resolve(res.results.is_trail_exist);
          } else {
            resolve(false);
          }
        }).catch(err => {
          reject(err);
        });
      });
    },
    goOrg(id, is_active) {
      const postData = {
        organization_id: id,
        is_developer: this.isDeveloper
      };
      (0, _organization.switchOrg)(postData).then(res => {
        if (res.code === 20000) {
          this.$store.commit('user/SET_ORG_ID', id);
          if (this.$route.query.redirect_uri) {
            location.href = this.$route.query.redirect_uri;
          } else {
            if (window.self !== window.top) {
              if (sessionStorage.getItem('embed') || this.$route.query.redirect) {
                this.$router.push({
                  path: sessionStorage.getItem('embed') || this.$route.query.redirect || _routeMap.ROUTES.dashboard
                });
              } else {
                this.$router.push('/conversationList');
              }
            } else {
              this.$router.push({
                path: this.$route.query.redirect || _routeMap.ROUTES.dashboard
              });
            }
          }
        }
      });
    },
    goToLogin() {
      this.$router.push({
        path: _routeMap.ROUTES.login
      });
    },
    closeCreate() {
      this.orgForm.name = '';
    },
    // 接受邀请
    acceptInvitation(organization_id) {
      const postData = {
        organization_id,
        is_developer: this.isDeveloper
      };
      (0, _organization.switchOrg)(postData).then(res => {
        if (res.code === 20000) {
          this.$store.commit('user/SET_ORG_ID', organization_id);
          // 如果加入邀请,若本身团队是0个时,则用户只有一个团队,如果本身团队是1个时,则变为它有多个团队
          if (this.organizations.length === 0) {
            window.sessionStorage.setItem('isOnlyOneOrg', 'true');
          } else if (this.organizations.length === 1) {
            window.sessionStorage.setItem('isOnlyOneOrg', '');
          }
          this.$router.push({
            path: _routeMap.ROUTES.dashboard
          });
        }
      });
    },
    isUser() {
      return this.userRole.length !== 0;
    }
  }
};
exports.default = _default;