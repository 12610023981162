"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateContactDialog = _interopRequireDefault(require("./CreateContactDialog"));
var _mediaUpload = require("@/api/mediaUpload");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _useSelectBottomAction = _interopRequireDefault(require("@/hooks/use-select-bottom-action"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConnectedContact',
  emits: ['contactChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const store = (0, _useVuex.useStore)();
    const connectedContactList = (0, _vue.ref)([]);
    const contact_id = (0, _vue.ref)('');
    const contactSelectInnerText = (0, _vue.ref)('');
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const getConnectedContactList = async val => {
      contactSelectInnerText.value = val;
      const res = await (0, _mediaUpload.searchContact)({
        workspace_id: workspaceId.value,
        filter: val
      });
      if (res.code === 20000) {
        connectedContactList.value = res.results.datas;
      }
    };
    const contactSelectRef = (0, _vue.ref)(null);
    const handleVisibleChange = v => {
      (0, _useSelectBottomAction.default)(v, {
        ref: contactSelectRef,
        label: '新建联系人',
        icon: 'iconfont icon-plus',
        click: openCreateContactDialog
      });
    };
    const createContactDialogVisible = (0, _vue.ref)(false);
    const openCreateContactDialog = () => {
      createContactDialogVisible.value = true;
    };
    const replaceContactContent = (sentence, keyword) => {
      const reg = new RegExp(keyword, 'gi');
      return sentence.replace(reg, `<span style="color:#4461ec">${keyword}</span>`);
    };
    const handleContactChange = () => {
      console.log('handleContactChange');
      contactSelectInnerText.value = '';
      emit('contactChange', contact_id.value);
    };
    const createContactSuccess = val => {
      connectedContactList.value.unshift(val);
      contact_id.value = val.id;
      emit('contactChange', contact_id.value);
    };
    return {
      __sfc: true,
      store,
      connectedContactList,
      contact_id,
      contactSelectInnerText,
      workspaceId,
      getConnectedContactList,
      contactSelectRef,
      handleVisibleChange,
      createContactDialogVisible,
      openCreateContactDialog,
      replaceContactContent,
      emit,
      handleContactChange,
      createContactSuccess,
      CreateContactDialog: _CreateContactDialog.default
    };
  }
};
exports.default = _default;