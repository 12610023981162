"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OperateDropdown = _interopRequireDefault(require("./OperateDropdown"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary.js");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vuex = require("vuex");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeBasePermissionDrawer',
  components: {
    OperateDropdown: _OperateDropdown.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentKnowledgeBase: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      publicScope: 0,
      memberList: [],
      loading: false,
      submitLoading: false,
      memberOptions: [],
      membersPermission: 0,
      memberBackfillList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    baseOperateList() {
      return [{
        value: 2,
        label: '可管理',
        icon: 'icon-setting',
        tips: '可管理/删除知识库'
      }, {
        value: 1,
        label: '可编辑',
        icon: ' icon-edit-outline',
        tips: '可编辑/删除目录及知识点'
      }, {
        value: 0,
        label: '可查看',
        icon: 'icon-view',
        tips: '只可查看知识库内容'
      }];
    },
    memberOperateList() {
      return function (item) {
        // 所有者权限：所有者的管理权限不能被更改
        // 只有所有者才能更改其他人(人：type = 0，部门type = 1)为所有者，更改后自己的权限变成可管理
        // 管理者是系统除外（id = 0），所有人都可对单人进行设为所有者操作
        const operate = (0, _cloneDeep.default)(this.baseOperateList);
        const owner = this.memberList.find(item => item.permission === 3);
        if (!owner.id || this.userId === owner.id && !item.type) {
          operate.push({
            value: 3,
            label: '设为所有者',
            icon: 'icon-change-follower'
          });
        }
        operate.push({
          value: -1,
          label: '移除',
          icon: 'icon-delete'
        });
        return operate;
      };
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.publicScope = this.currentKnowledgeBase.public_scope;
          this.getKnowledgeBasePermission();
        }
      }
    }
  },
  methods: {
    getUserList: _organization.getUserList,
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    // 统一修改添加成员权限类型
    changeMembersPermission(val) {
      this.membersPermission = val;
    },
    // 统一添加多个成员权限
    addPermission() {
      const {
        tree_ids,
        user_ids
      } = this.memberOptions;
      if (!(tree_ids !== null && tree_ids !== void 0 && tree_ids.length) && !(user_ids !== null && user_ids !== void 0 && user_ids.length)) return;
      let users = [];
      let trees = [];
      if (tree_ids.length) {
        trees = tree_ids.map(item => ({
          id: item,
          type: 1
        }));
      }
      if (user_ids.length) {
        users = user_ids.map(item => ({
          id: item,
          type: 0
        }));
      }
      const members = [...trees, ...users];
      const params = {
        members,
        base_id: this.currentKnowledgeBase.id,
        permission: this.membersPermission
      };
      this.editKnowledgeBasePermission(params, 'isAdd');
    },
    // 修改或删除单个人的权限
    editPermission(permission, item) {
      console.log(item, 'item', permission);
      // 删除权限
      if (permission === -1) {
        this.deleteKnowledgeBasePublicScope(item);
      } else {
        // 编辑权限
        const params = {
          members: [{
            id: item.id,
            type: item.type
          }],
          base_id: this.currentKnowledgeBase.id,
          permission
        };
        // permission 0:可查看 1:可编辑 2:可管理 3:设为所有者
        this.editKnowledgeBasePermission(params, permission);
      }
    },
    assigneeChange(val) {
      this.memberOptions = val;
    },
    async deleteKnowledgeBasePublicScope(_ref) {
      let {
        id,
        type
      } = _ref;
      const res = await (0, _knowledgeLibrary.deleteKnowledgeBasePublicScope)({
        base_id: this.currentKnowledgeBase.id,
        id: id,
        type: type
      });
      if (res.code === 20000) {
        this.$message.success('移除成功');
        this.memberList = this.memberList.filter(item => item.id !== id);
        // 删除自己的权限
        if (this.userId === id && !type) {
          this.handleClose();
          this.$emit('refresh');
        }
      }
    },
    async editKnowledgeBasePermission(params, operate) {
      const res = await (0, _knowledgeLibrary.editKnowledgeBasePermission)(params);
      if (res.code === 20000) {
        const message = operate === 'isAdd' ? '添加成功' : '修改成功';
        this.$message.success(message);
        // operate 0:可查看 1:可编辑 2:可管理 3:设为所有者
        // 添加操作和设为所有者操作需要重新请求权限列表
        if (operate === 'isAdd' || operate === 3) {
          this.memberOptions = [];
          this.memberBackfillList = [];
          this.getKnowledgeBasePermission();
        }
        // 如果修改自己的权限，改成 0:可查看 1:可编辑，关闭弹窗并刷新知识库列表
        if (operate < 2 && this.userId === params.members[0].id && !params.members[0].type) {
          this.handleClose();
          this.$emit('refresh');
        }
      }
    },
    async getKnowledgeBasePermission() {
      this.loading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeBasePermission)({
        id: this.currentKnowledgeBase.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.memberList = [res.results.create_by, ...res.results.other];
      }
    },
    // 修改公开范围
    async handleRadioChange(val) {
      const res = await (0, _knowledgeLibrary.editKnowledgeBasePublicScope)({
        base_id: this.currentKnowledgeBase.id,
        public_scope: val
      });
      if (res.code === 20000) {
        this.$message.success('修改成功');
        this.$emit('refresh');
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.memberOptions = [];
      this.memberBackfillList = [];
    }
  }
};
exports.default = _default;