"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DealRiskTabContent',
  props: {
    currentRow: {
      type: Object,
      default: () => []
    },
    customer: {
      type: String,
      default: ''
    }
  }
};
exports.default = _default;