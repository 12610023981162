"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'LeftPracticeProcessTips',
  props: {
    description: {
      type: String,
      default: ''
    },
    metrics: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const isCollapse = (0, _vue.ref)(false);
    return {
      __sfc: true,
      props,
      isCollapse
    };
  }
};
exports.default = _default;