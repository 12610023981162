"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'HitButtons',
  props: {
    status: {
      type: Number,
      default: 1
    }
  },
  emits: ['change', 'update:status'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onClick = status => {
      if (status === props.status) return;
      emit('update:status', status);
      emit('change', status);
    };
    return {
      __sfc: true,
      props,
      emit,
      onClick
    };
  }
};
exports.default = _default;