"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExportButton = _interopRequireDefault(require("@/views/management/components/ExportButton"));
var _SelectRole = _interopRequireDefault(require("@/components/SelectRole"));
var _DivertMemberDialog = _interopRequireDefault(require("./components/DivertMemberDialog"));
var _DepartmentSelect = _interopRequireDefault(require("@/components/DepartmentSelect"));
var _MemberSingleSelectDialog = _interopRequireDefault(require("@/components/MemberSingleSelectDialog"));
var _AdminRoleDrawer = _interopRequireDefault(require("./components/AdminRoleDrawer"));
var _AddAdminDialog = _interopRequireDefault(require("./components/AddAdminDialog"));
var _OperateMemberDialog = _interopRequireDefault(require("@/components/OperateMemberDialog"));
var _TranserDepartmentFinderDialog = _interopRequireDefault(require("@/components/TranserDepartmentFinderDialog"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _commonFunc = require("@/utils/commonFunc");
var _permissions = require("@/api/permissions");
var _export = require("@/api/export");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ExportButton: _ExportButton.default,
    SelectRole: _SelectRole.default,
    DivertMemberDialog: _DivertMemberDialog.default,
    DepartmentSelect: _DepartmentSelect.default,
    MemberSingleSelectDialog: _MemberSingleSelectDialog.default,
    AdminRoleDrawer: _AdminRoleDrawer.default,
    TranserDepartmentFinderDialog: _TranserDepartmentFinderDialog.default,
    AddAdminDialog: _AddAdminDialog.default,
    ImageAvatar: _ImageAvatar.default,
    OperateMemberDialog: _OperateMemberDialog.default
  },
  data() {
    return {
      tableLoading: false,
      filter: '',
      memberInfo: {},
      divertDialogVisible: false,
      manageScope: '',
      filterManageScope: '',
      addMemberDialogVisible: false,
      addRoleMemberDialogVisible: false,
      adminRoleDrawerVisible: false,
      departmentsFinderDialogVisible: false,
      roleInfo: {},
      operateRoleInfo: {},
      operateRoleType: '',
      roleLoading: false,
      hasDepartmentMember: false,
      isSuperAdmin: false,
      defaultChecked: [],
      pageParams: {
        page: 1,
        size: 10
      },
      tableHeader: [],
      tableData: [],
      adminRoleList: [],
      allAdminRoleList: [],
      total: 0,
      operateMemberType: '',
      scopeList: [],
      operateMemberInfo: {},
      manageTree: [],
      operateDialogVisible: false
    };
  },
  computed: {},
  watch: {
    roleInfo(val) {
      if (val) {
        if (val.name === '超级管理员') {
          this.isSuperAdmin = true;
        } else {
          this.isSuperAdmin = false;
        }
        this.filterManageScope = '';
        this.getTableHead();
      }
    }
  },
  created() {
    this.getAdminRoleList();
  },
  methods: {
    exportApi() {
      return (0, _export.exportManagerRole)({
        filter: this.filter,
        role_id: this.roleInfo.id
      });
    },
    getDepartmentsWithOutAuth: _permissions.getDepartmentsWithOutAuth,
    openOperateDialog(type, row) {
      this.operateDialogVisible = true;
      this.operateMemberType = type;
      this.memberInfo = {};
      if (!row) return;
      if (type === 'edit') {
        this.memberInfo = row;
      }
    },
    getRoleNames(roleList) {
      const roleNameList = roleList.map(item => item.name);
      return roleNameList.join('；');
    },
    async fetchRoleList(roleInfo) {
      await this.getAdminRoleList();
      if (roleInfo) {
        if (this.roleInfo.id === roleInfo.id) {
          this.getTableHead();
        } else {
          this.roleInfo = roleInfo;
          this.$refs.selectRole.scrollToCurrentRole();
        }
      }
    },
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    async addAdmin(id) {
      const res = await (0, _permissions.addAdminMembers)({
        user_ids: id,
        role_ids: [this.roleInfo.id]
      });
      if (res.code === 20000) {
        setTimeout(() => {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.getAdminRoleMembers();
          this.$refs.addSuperAdmin.handleClose();
        }, 1000);
      }
    },
    async submitManageScope(scope) {
      this.scopeList = scope;
    },
    searchMembers: (0, _commonFunc.debounce)(function () {
      this.pageParams.page = 1;
      this.getAdminRoleMembers();
    }),
    async getAdminRoleList(roleName) {
      this.roleLoading = true;
      let res;
      if (roleName) {
        res = await (0, _permissions.getAdminRoleList)({
          filter: roleName
        });
      } else {
        res = await (0, _permissions.getAdminRoleList)();
        this.allAdminRoleList = res.results.role_list;
      }
      this.roleLoading = false;
      if (res.code === 20000) {
        this.adminRoleList = res.results.role_list;
        if (!this.adminRoleList.length) return;
        if (JSON.stringify(this.roleInfo) === '{}') {
          this.roleInfo = this.adminRoleList[0];
        } else {
          const roleInfo = this.adminRoleList.find(item => item.id === this.roleInfo.id);
          if (roleInfo) {
            this.roleInfo = roleInfo;
          } else {
            this.roleInfo = this.adminRoleList[0];
          }
        }
      }
    },
    async getTableHead() {
      const res = await (0, _permissions.getAdminRoleTableHead)({
        role_id: this.roleInfo.id
      });
      if (res.code === 200) {
        this.tableHeader = res.results.title;
        this.getAdminRoleMembers();
        this.hasDepartmentMember = this.tableHeader.some(item => item.field_name === 'scope');
      }
    },
    async getAdminRoleMembers() {
      const data = {
        filter: this.filter,
        role_id: this.roleInfo.id,
        tree_id: this.filterManageScope,
        ...this.pageParams
      };
      this.tableLoading = true;
      const res = await (0, _permissions.getAdminMembers)(data);
      this.tableLoading = false;
      if (res.code === 200) {
        this.tableData = res.results.user_list;
        this.total = res.results.count;
        this.manageTree = res.results.manage_tree;
      }
    },
    openEditScopeDialog(scopeInfo) {
      this.departmentsFinderDialogVisible = true;
      this.defaultChecked = [];
      if (scopeInfo.row) {
        this.operateMemberInfo = scopeInfo.row;
        scopeInfo.row.scope.forEach(item => {
          this.defaultChecked.push(item);
        });
      } else if (scopeInfo.scopeList) {
        scopeInfo.scopeList.forEach(item => {
          this.defaultChecked.push(item);
        });
      } else {
        this.defaultChecked = this.manageTree;
      }
    },
    switchRightPage(roleInfo) {
      this.pageParams.page = 1;
      this.roleInfo = roleInfo;
    },
    openRoleDrawer(roleInfo, type) {
      this.operateRoleInfo = roleInfo;
      this.operateRoleType = type;
      this.adminRoleDrawerVisible = true;
    },
    removeMember(row) {
      this.$confirm(`确定将${row.name}从${this.roleInfo.name}中移除吗`, '移除管理员', {
        confirmButtonText: '移除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const data = {
          role_id: this.roleInfo.id,
          user_id: row.user_id
        };
        this.tableLoading = true;
        const res = await (0, _permissions.deleteAdminMembers)(data);
        this.tableLoading = false;
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '移除成功'
          });
          this.tableData = this.tableData.filter(item => item.user_id !== row.user_id);
        }
      });
    },
    openAddMemberDialog() {
      if (this.isSuperAdmin) {
        this.addMemberDialogVisible = true;
      } else {
        this.scopeList = [];
        this.addRoleMemberDialogVisible = true;
      }
    },
    setDepartmentLabel(val) {
      this.filterManageScope = val[0];
      this.pageParams.page = 1;
      this.getAdminRoleMembers();
    },
    openDivertDialog(row) {
      this.memberInfo = row;
      this.divertDialogVisible = true;
    },
    getScopeName(scope) {
      if (scope.some(item => !item.fid)) {
        return '全部';
      }
      const scopeNames = [];
      scope.forEach(item => {
        scopeNames.push(item.name);
      });
      return scopeNames.join('；');
    },
    handleListParamsChange(val, type) {
      this.pageParams[type] = val;
      this.getAdminRoleMembers();
    }
  }
};
exports.default = _default;