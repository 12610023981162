"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-correction-content-card"
  }, [_c("div", [_c("span", {
    staticClass: "score"
  }, [_c("span", {
    staticClass: "score-item"
  }, [_vm._v(_vm._s(_vm.value.score))]), _c("span", {
    staticClass: "desc"
  }, [_vm._v("分")])])]), _c("div", {
    staticClass: "content"
  }, _vm._l(_vm.value.content, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v("【" + _vm._s(item.entity_type === "customer_contact" ? "客户" : "销售") + "】")]), _c("span", {
      domProps: {
        innerHTML: _vm._s(_vm.handleHighLight(item))
      }
    })]);
  }), 0), _c("div", {
    staticClass: "divider"
  }), _c("div", {
    staticClass: "correct-score"
  }, [_c("div", {
    staticClass: "correct-score-content"
  }, [_c("div", {
    staticClass: "correct-title"
  }, [_vm._v("矫正得分")]), _c("div", {
    staticClass: "content"
  }, [_c("el-slider", {
    attrs: {
      min: 1,
      max: 5,
      step: 0.1,
      "show-input": "",
      "show-input-controls": false
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.score,
      callback: function ($$v) {
        _vm.score = $$v;
      },
      expression: "score"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("分")])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;