"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-analysis"
  }, [_c("el-radio-group", {
    staticClass: "toggle-data-type mg-radio-button-group",
    on: {
      change: _vm.toggleAnalysisType
    },
    model: {
      value: _vm.dimension,
      callback: function ($$v) {
        _vm.dimension = $$v;
      },
      expression: "dimension"
    }
  }, _vm._l(_vm.dimensionOptions, function (_ref) {
    let {
      label,
      type
    } = _ref;
    return _c("el-radio-button", {
      key: type,
      attrs: {
        label: type
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1), !_vm.getKanbanListLoading && !_vm.kanbanList.length ? _c("init-kanban-card-view", {
    attrs: {
      config: {
        title: "看板"
      }
    },
    on: {
      createKanban: _vm.createKanban
    }
  }) : _vm._e(), _vm.kanbanList.length ? [_c("BI-kanban", {
    ref: "bi-kanban",
    attrs: {
      "active-id": _vm.currentKanban.kanban_id,
      "extra-command-list": _vm.getExtraCommandlist(),
      "kanban-list": _vm.kanbanList
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }), _c("div", {
    staticClass: "kanban-container"
  }, [_c("div", {
    staticClass: "kanban-container-head"
  }, [_vm.tableData.length ? _c("div", {
    staticClass: "analysis-tips"
  }, [_vm._l(_vm.analysisTips, function (_ref2, index) {
    let {
      text,
      color
    } = _ref2;
    return _c("div", {
      key: index,
      staticClass: "tip-item"
    }, [color ? _c("span", {
      staticClass: "color-block",
      style: {
        backgroundColor: color
      }
    }) : _vm._e(), _c("span", [_vm._v(" " + _vm._s(text))])]);
  }), _c("a", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    attrs: {
      href: "https://www.megaview.com/help-center/doc?docx=DKtddMUOwoJbIHxxsKNc2rSxn3g&anchor=MMoGdqi6UoyEoExkTUBcssjFn6b",
      target: "_blank"
    }
  }, [_vm._v("了解更多")])], 2) : _vm._e(), !_vm.currentKanban.is_share && !_vm.isShowCheckbox ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.handleEditColumn("add");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("新增列")]) : _vm._e(), _vm.tableData.length ? [_vm.modulePermission.biExperiment ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowCheckbox,
      expression: "!isShowCheckbox"
    }],
    on: {
      click: function ($event) {
        _vm.isShowCheckbox = true;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-abtest"
  }), _vm._v("创建 A/B 测试")]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowCheckbox,
      expression: "isShowCheckbox"
    }]
  }, [_c("el-button", {
    on: {
      click: _vm.handleCancle
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.checkedList.length
    },
    on: {
      click: _vm.goABTest
    }
  }, [_vm._v("保存")])], 1)] : _vm._e(), _c("table-export", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableData.length && !_vm.isShowCheckbox,
      expression: "tableData.length && !isShowCheckbox"
    }],
    attrs: {
      "export-current-view-config": {
        label: "仅导出视图展示列",
        isThroughFrontEnd: true
      },
      "export-all-data-config": {
        label: "导出所有列",
        isThroughFrontEnd: true
      }
    },
    on: {
      exportCurrentView: function ($event) {
        return _vm.exportData(_vm.tableHeader, _vm.tableData);
      },
      exportAll: _vm.exportAllData
    }
  }), _vm.allTableHead.length && !_vm.currentKanban.is_share ? _c("dynamic-popover", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowCheckbox && !_vm.tableLoading,
      expression: "!isShowCheckbox && !tableLoading"
    }],
    ref: "dynamicPopover",
    attrs: {
      "popper-class": "head-set-pop",
      "incoming-list": _vm.allTableHead,
      "min-check-count": -1,
      "has-special-column": false,
      "reset-request-is-inner": false
    },
    on: {
      updateTableHead: _vm.updateTableHead
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      icon: "iconfont icon-setting"
    }
  }, [_vm._v("列设置")])], 1) : _vm._e()], 2), _c("TeamAnalysisTable", {
    ref: "team-analysis-table",
    attrs: {
      dimension: _vm.dimension,
      "table-loading": _vm.tableLoading,
      "table-data": _vm.tableData,
      "table-header": _vm.tableHeader,
      "is-show-checkbox": _vm.isShowCheckbox,
      "has-handle-permission": !_vm.currentKanban.is_share,
      "curren-kanban-id": _vm.currentKanban.kanban_id
    },
    on: {
      editColumn: _vm.handleEditColumn,
      sortTableData: _vm.sortTableData,
      checkedChange: val => _vm.checkedList = val
    }
  })], 1)] : _vm._e(), _c("EditColumnDialog", {
    attrs: {
      "is-edit": _vm.isEditColumn,
      "kanban-id": _vm.currentKanban.kanban_id,
      "edit-column-data": _vm.editColumnData,
      visible: _vm.editColumnDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.editColumnDialogVisible = $event;
      },
      refreshTeamAnalysisList: _vm.refreshTeamAnalysisList,
      closeDialog: _vm.closeDialog
    }
  }), _c("ShareConfig", {
    attrs: {
      visible: _vm.shareKanbanConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        kanban_id: _vm.currentKanban.kanban_id,
        type: 0
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareKanbanConfigVisible = $event;
      }
    }
  }), _c("EditKanbanNameDialog", {
    attrs: {
      visible: _vm.copyKanbanConfigVisible,
      title: _vm.editKanbanNameDialogTile,
      "kanban-name": _vm.backfillKanbanName,
      params: {
        id: _vm.currentKanban.kanban_id,
        type: 0
      },
      "handle-fn": _vm.getHandleKanabanNameFn
    },
    on: {
      "update:visible": function ($event) {
        _vm.copyKanbanConfigVisible = $event;
      },
      copyKanbanSuccess: _vm.getKanbanList
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;