"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "appoint-part"
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera-solid-2"
  }), _c("p", [_vm._v("保持和客户的沟通，快使用Megaview预约一个会议吧！")]), _c("book-meeting")], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;