"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _default = {
  name: 'InterposeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    interpose: {
      type: Array,
      default: () => []
    },
    currentExperiment: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        interpose: []
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          return !_this.$moment(time).isBetween(_this.currentExperiment.start_at, _this.currentExperiment.end_at, null, '[]');
        }
      };
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form.interpose = this.interpose.length ? (0, _commonFunc.deepClone)(this.interpose) : [{
          name: '',
          date: ''
        }];
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const params = {
            interpose_configs: this.form.interpose,
            id: this.currentExperiment.id,
            workspace_id: this.workspaceInfo.id
          };
          const res = await (0, _businessIntelligence.editInterpose)(params);
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '干预点设置成功',
              center: true
            });
            this.$emit('refresh');
            this.handleClose();
          }
        }
      });
    },
    addItem() {
      this.form.interpose.push({
        name: '',
        date: ''
      });
    },
    deleteItem(index) {
      this.form.interpose.splice(index, 1);
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.$refs['form'].resetFields();
    }
  }
};
exports.default = _default;