"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compliance = require("@/api/compliance");
var _default = {
  name: 'ConversationSelector',
  props: {
    conversationTitleInfo: {
      type: Object,
      default: () => ({})
    },
    convId: {
      type: [Number, String],
      default: ''
    },
    customerName: {
      type: String,
      default: ''
    },
    salesName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      totalCount: 0,
      currentIndex: 0,
      conversationList: [],
      pageConfig: {
        size: 9999,
        page: 1
      },
      loading: false,
      visible: false
    };
  },
  computed: {
    // isShowPreBtn() {
    //   return this.pageConfig.page > 1;
    // },
    // isShowNextBtn() {
    //   return (
    //     this.pageConfig.page < Math.ceil(this.totalCount / this.pageConfig.size)
    //   );
    // },
    statusConfig() {
      return [{
        icon: 'icon-time',
        class: 'waiting',
        text: '待质检'
      }, {
        icon: 'icon-circle-check',
        class: 'completed',
        text: '已质检'
      }, {
        icon: 'icon-circle-close',
        class: 'rejected',
        text: '已驳回'
      }];
    },
    isShow() {
      return this.conversationList.length > 1 && this.conversationList.length >= this.currentIndex;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.scrollActiveIntoView();
      }
    },
    convId(val) {
      this.getCurrentDataIndex(val);
    }
  },
  created() {
    // if (this.$route.query.page) {
    //   this.pageConfig.page = Number(this.$route.query.page);
    // }
  },
  methods: {
    changeId(id) {
      const task_id = this.$route.query.task_id;
      this.getCurrentDataIndex(id);
      this.$router.replace({
        name: 'ComplianceWorkSpace',
        query: {
          id,
          task_id,
          // page: this.pageConfig.page,
          sampling_type: this.$route.query.sampling_type,
          sampling_id: this.$route.query.sampling_id
        },
        params: this.$route.params
      });
      this.visible = false;
    },
    getDay(value) {
      return this.$moment(value).format('M-D');
    },
    getConvClass(type) {
      return {
        doc: 'icon-im',
        audio: 'icon-phone-outline',
        video: 'icon-video-camera'
      }[type];
    },
    async getAccountSaleConvList() {
      const params = {
        conversation_id: Number(this.$route.query.sampling_id),
        ...this.pageConfig
      };
      this.loading = true;
      const res = await (0, _compliance.getAccountSaleConvList)(params).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.totalCount = res.results.total;
        this.conversationList = res.results.datas;
        this.getCurrentDataIndex(this.convId);
        if (this.isShow) {
          this.visible = true;
          this.scrollActiveIntoView();
        }
        // if (!this.$route.query.page && this.conversationList.length > 1) {
        //   this.visible = true;
        // }
      }
    },

    scrollActiveIntoView() {
      this.$nextTick(() => {
        this.$refs[`item-${this.convId}`] && this.$refs[`item-${this.convId}`][0].scrollIntoView();
      });
    },
    // goPrePage() {
    //   this.pageConfig.page--;
    //   this.getAccountSaleConvList();
    // },
    // goNextPage() {
    //   this.pageConfig.page++;
    //   this.getAccountSaleConvList();
    // },
    getCurrentDataIndex(id) {
      if (!this.conversationList.length) return;
      this.currentIndex = this.conversationList.length - this.conversationList.findIndex(item => item.conv_id === Number(id));
      // const dataIndex = this.conversationList.findIndex(
      //   (item) => item.conv_id === Number(id)
      // );
      // console.log('=========', dataIndex);
      // if (dataIndex !== -1) {
      //   this.currentIndex =
      //     this.pageConfig.size * (this.pageConfig.page - 1) +
      //     this.conversationList.length -
      //     dataIndex;
      // }
    }
  }
};

exports.default = _default;