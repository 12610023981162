"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "sub-question-list"
  }, [_setup.subQuestionsList.length ? [_c("p", {
    staticClass: "description"
  }, [_vm._v(" 当前问题可按下列问题细分，点击右侧“拆分”按钮可将其拆分至左侧列表成为独立问题。 ")]), _vm._l(_setup.subQuestionsList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "sub-question-item",
      on: {
        click: function ($event) {
          return _setup.showDetail(item);
        }
      }
    }, [_c("div", {
      staticClass: "left"
    }, [_c("div", {
      staticClass: "question-name"
    }, [_c("span", [_vm._v(_vm._s(item.rank) + ".")]), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        placement: "top",
        effect: "dark",
        content: item.name
      }
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))])])], 1), _c("div", {
      staticClass: "question-info"
    }, [_c("span", [_vm._v("出现频率：" + _vm._s(item.deal_freq + "%"))]), _c("span", [_vm._v("问题占比：" + _vm._s(item.occ_percent + "%"))])])]), _c("div", {
      staticClass: "right"
    }, [_c("el-button", {
      attrs: {
        type: "text gray-bg"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.confirmSubdivideQuestion(item);
        }
      }
    }, [_vm._v("拆分")])], 1)]);
  })] : _c(_setup.NoData, {
    attrs: {
      tips: _setup.isNewSplitQuestion ? "该问题由细分问题拆分生成，请等待每周数据更新后再查看" : "该问题暂无细分问题，请等待每周数据更新后再查看"
    }
  }), _c(_setup.SubQuestionDetailDrawer, {
    attrs: {
      visible: _setup.subQuestionDetailVisible,
      "sub-question-item": _setup.subQuestionItem
    },
    on: {
      "update:visible": function ($event) {
        _setup.subQuestionDetailVisible = $event;
      }
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;