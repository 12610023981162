"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-admin-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("el-form", {
    ref: "memberIdForm",
    staticClass: "modify-form",
    attrs: {
      model: _vm.memberIdForm,
      "hide-required-asterisk": "",
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择成员",
      prop: "id"
    }
  }, [_c("members-finder", {
    ref: "memberFinder",
    staticClass: "select-member",
    attrs: {
      placeholder: "请选择成员",
      "has-self": false,
      "is-radio": true
    },
    on: {
      change: _vm.handleMemberChange
    }
  })], 1)], 1), _vm.hasDepartmentMember ? _c("div", {
    staticClass: "manage-scope"
  }, [_vm._v(" 部门和成员页面管理范围 "), _c("div", {
    staticClass: "scope-options"
  }, [_vm.currentScopeList.some(item => item.fid === 0) ? _c("span", [_vm._v("全部")]) : !_vm.currentScopeList.length ? _c("span", [_vm._v("未设置")]) : _vm._l(_vm.currentScopeList, function (scope) {
    return _c("span", {
      key: scope.id,
      staticClass: "manage-scope-item"
    }, [_vm._v(_vm._s(scope.name))]);
  }), _c("el-button", {
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.openEditScopeDialog
    }
  }, [_vm._v("编辑")])], 2)]) : _vm._e(), _c("div", {
    staticClass: "advanced-setting"
  }, [_c("span", {
    staticClass: "advanced-setting-title"
  }, [_vm._v("高级设置")]), _c("el-checkbox", {
    model: {
      value: _vm.isDistributable,
      callback: function ($$v) {
        _vm.isDistributable = $$v;
      },
      expression: "isDistributable"
    }
  }, [_vm._v("可以分配自己的权限给其他成员 "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      content: "勾选后，此管理员可以添加新管理员，但只能授予自己权限范围内的角色",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;