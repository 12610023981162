"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-cascader", {
    attrs: {
      value: _vm.cascaderIds,
      "show-all-levels": false,
      "collapse-tags": "",
      options: _setup.formatCascaderOptions(_setup.props.options),
      props: {
        expandTrigger: "hover",
        value: "id",
        label: "name",
        multiple: true
      },
      filterable: "",
      "popper-class": "widget-custom-cascader-popper"
    },
    on: {
      input: _setup.handleCascaderChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-cascader-node"
        }, [_c("span", [_vm._v(_vm._s(data.name))]), !data.children ? _c("span", {
          staticClass: "score"
        }, [data.score > 0 ? _c("span", [_vm._v("+")]) : _vm._e(), _c("span", [_vm._v(_vm._s(data.score))])]) : _vm._e()])];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;