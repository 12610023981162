"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timezone = require("@/utils/timezone");
var _HitButtons = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitButtons.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AnnotateResultsTable',
  props: {
    tableData: Array,
    startIndex: Number,
    dataSetStatistics: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['tableSortChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handleSortChange = _ref2 => {
      let {
        column,
        prop,
        order
      } = _ref2;
      emit('tableSortChange', prop, order);
    };
    const updateAnnotate = (status, row) => {
      console.log(status, row);
      emit('updateAnnotate', {
        status,
        dataset_annotation_ids: [row.id]
      });
    };
    const handleSelectionChange = selection => {
      emit('tableSelectionChange', selection);
    };
    const deleteAnnotation = row => {
      emit('deleteAnnotation', {
        annotationIds: [row.id]
      });
    };
    const onClickModelSentence = row => {
      emit('clickModelSentence', row);
    };
    return {
      __sfc: true,
      props,
      emit,
      handleSortChange,
      updateAnnotate,
      handleSelectionChange,
      deleteAnnotation,
      onClickModelSentence,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone,
      HitButtons: _HitButtons.default
    };
  }
};
exports.default = _default;