import { render, staticRenderFns } from "./KnowledgePointCard.vue?vue&type=template&id=1773a23c&scoped=true&"
import script from "./KnowledgePointCard.vue?vue&type=script&lang=js&"
export * from "./KnowledgePointCard.vue?vue&type=script&lang=js&"
import style0 from "./KnowledgePointCard.vue?vue&type=style&index=0&id=1773a23c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1773a23c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1773a23c')) {
      api.createRecord('1773a23c', component.options)
    } else {
      api.reload('1773a23c', component.options)
    }
    module.hot.accept("./KnowledgePointCard.vue?vue&type=template&id=1773a23c&scoped=true&", function () {
      api.rerender('1773a23c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessIntelligence/knowledge/knowledgeOverview/components/KnowledgePointCard.vue"
export default component.exports