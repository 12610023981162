"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'GradeProgress',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['medium', 'small', 'mini'].includes(value);
      }
    },
    percentage: {
      type: Number,
      default: 0
    },
    score: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getTypeClass() {
      var _classList$this$type;
      const classList = {
        0: {
          label: '',
          type: 'primary'
        },
        1: {
          label: '合格',
          type: 'success'
        },
        2: {
          label: '不合格',
          type: 'danger'
        }
      };
      return (_classList$this$type = classList[this.type]) === null || _classList$this$type === void 0 ? void 0 : _classList$this$type.type;
    }
  }
};
exports.default = _default;