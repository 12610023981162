"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    attrs: {
      disabled: !_vm.tooltipContent,
      effect: "dark",
      content: _vm.tooltipContent,
      placement: "top"
    }
  }, [_c("button", {
    staticClass: "mgv-button",
    class: _setup.btnClass,
    style: {
      width: _vm.width
    },
    attrs: {
      disabled: _setup.buttonDisabled,
      type: "button"
    },
    on: {
      click: _setup.handleClick
    }
  }, [_vm.loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "btn-loading"
  }) : _vm._e(), _vm.icon && !_vm.loading ? _c("i", {
    class: ["iconfont", _vm.icon]
  }) : _vm._e(), _vm.$slots.default ? _c("span", [_vm._t("default")], 2) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;