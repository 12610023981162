"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _export = require("@/api/export");
var _download = require("@/utils/download");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _default = {
  name: 'ExportRecords',
  data() {
    return {
      exportList: [],
      tableLoading: false,
      iconLoading: true,
      listParams: {
        page: 1,
        size: 10
      },
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg'])
  },
  watch: {
    webSocketMsg: {
      handler: function (newVal) {
        if (newVal.type === 'notify_export_task') {
          this.handleExportList(newVal.data);
        }
      },
      deep: true
    }
  },
  created() {
    this.getExportList();
  },
  methods: {
    downloadFile: (0, _commonFunc.debounce)(function (url) {
      (0, _download.downloadFile)(url);
    }, 500, true),
    async cancelExport(data) {
      const res = await (0, _export.cancelExport)({
        id: data.id
      });
      if (res.code === 20000) {
        this.exportList = this.exportList.map(item => {
          if (item.id === data.id) {
            item.status = 'cancel';
          }
          return item;
        });
      }
    },
    getStatusIcon(status) {
      const statusIcon = {
        finished: 'el-icon-circle-check',
        expired: 'el-icon-warning-outline',
        failed: 'el-icon-circle-close',
        cancel: 'el-icon-remove-outline'
      };
      return statusIcon[status];
    },
    getStatus(status) {
      return {
        generating: '导出中',
        finished: '导出完成',
        expired: '已过期',
        failed: '导出失败',
        cancel: '已取消'
      }[status];
    },
    handleExportList(data) {
      this.exportList = this.exportList.map(item => {
        if (item.id === data.id) {
          item.status = data.status;
          item.doc_url = data.doc_url;
        }
        return item;
      });
    },
    handleListParamsChange(val, type) {
      this.listParams[type] = val;
      this.getExportList();
    },
    async getExportList() {
      this.tableLoading = true;
      const res = await (0, _export.getExportList)(this.listParams);
      this.tableLoading = false;
      if (res.code === 200) {
        this.exportList = res.results.data;
        this.total = res.results.count;
      }
    }
  }
};
exports.default = _default;