"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", [_vm._v("主要按钮")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    on: {
      click: _vm.click
    }
  }, [_vm._v("默认主按钮")]), _c("MgvButton", {
    attrs: {
      disabled: ""
    },
    on: {
      click: _vm.click
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      loading: ""
    },
    on: {
      click: _vm.click
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "success"
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "success",
      disabled: ""
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "success",
      loading: ""
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "danger"
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "danger",
      disabled: ""
    }
  }, [_vm._v("主要按钮")]), _c("MgvButton", {
    attrs: {
      color: "danger",
      loading: ""
    }
  }, [_vm._v("主要按钮")])], 1), _c("div", [_vm._v("次要按钮")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain"
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      disabled: ""
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      loading: ""
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    }
  }, [_vm._v("次要按钮-info")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      disabled: ""
    }
  }, [_vm._v("次要按钮-info")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      loading: ""
    }
  }, [_vm._v("次要按钮-info")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "success",
      icon: "icon-plus"
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "success",
      icon: "icon-plus",
      disabled: ""
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "success",
      icon: "icon-plus",
      loading: ""
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "danger",
      icon: "icon-plus"
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "danger",
      icon: "icon-plus",
      disabled: ""
    }
  }, [_vm._v("次要按钮")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "danger",
      icon: "icon-plus",
      loading: ""
    }
  }, [_vm._v("次要按钮")])], 1), _c("div", [_vm._v("文字按钮")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text"
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      disabled: ""
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "info"
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "info",
      disabled: ""
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "success"
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "success",
      disabled: ""
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "danger"
    }
  }, [_vm._v("文字按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      color: "danger",
      disabled: ""
    }
  }, [_vm._v("文字按钮")])], 1), _c("div", [_vm._v("虚线按钮")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "dash"
    }
  }, [_vm._v("虚线按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "dash",
      disabled: ""
    }
  }, [_vm._v("虚线按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "dash",
      loading: ""
    }
  }, [_vm._v("虚线按钮")])], 1), _c("div", [_vm._v("不同尺寸")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus"
    }
  }, [_vm._v("按钮36")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "medium"
    }
  }, [_vm._v("按钮medium 32")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "small"
    }
  }, [_vm._v("按钮small 28")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "mini"
    }
  }, [_vm._v("按钮mini 24")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text"
    }
  }, [_vm._v("按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "medium"
    }
  }, [_vm._v("按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "small"
    }
  }, [_vm._v("按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "mini"
    }
  }, [_vm._v("按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "smini"
    }
  }, [_vm._v("按钮smini 20")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "smini",
      color: "info"
    }
  }, [_vm._v("按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "tiny"
    }
  }, [_vm._v("按钮tiny 16")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "tiny",
      color: "info"
    }
  }, [_vm._v("按钮")])], 1), _c("div", [_vm._v("只有图标")]), _c("div", {
    staticClass: "button"
  }, [_c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-more",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      size: "medium",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      size: "small",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      size: "mini",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      square: "",
      color: "info"
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      size: "medium",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      size: "small",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      size: "mini",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      size: "smini",
      square: ""
    }
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-more",
      type: "text",
      size: "tiny",
      square: ""
    },
    on: {
      click: _vm.click
    }
  })], 1)]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus"
    }
  }, [_c("span", [_vm._v("按钮")]), _c("i", {
    staticClass: "iconfont icon-arrow-down"
  })])], 1), _c("div", [_vm._v("宽度自适应按钮")]), _c("div", {
    staticClass: "button"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      width: "100%"
    }
  }, [_vm._v("宽度自适应按钮100%")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      width: "100%",
      size: "medium"
    }
  }, [_vm._v("宽度自适应按钮100%")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      width: "100px"
    }
  }, [_vm._v("宽度自适应按钮100px")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      width: "200px"
    }
  }, [_vm._v("宽度自适应按钮200px")]), _c("div", {
    staticStyle: {
      width: "300px"
    }
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      fitwidth: ""
    }
  }, [_vm._v("宽度自适应按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      fitwidth: "",
      type: "plain"
    },
    on: {
      click: _vm.click
    }
  }, [_vm._v("宽度自适应按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      fitwidth: "",
      type: "dash"
    },
    on: {
      click: _vm.click
    }
  }, [_vm._v("宽度自适应按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      fitwidth: "",
      type: "text"
    },
    on: {
      click: _vm.click
    }
  }, [_vm._v("宽度自适应按钮")])], 1)], 1), _c("div", [_vm._v("圆角按钮")]), _c("MgvButton", {
    attrs: {
      round: ""
    }
  }, [_vm._v("圆角按钮")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      circle: ""
    }
  }), _c("div", [_vm._v("按钮增加提示")]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "medium",
      circle: "",
      "tooltip-content": "按钮提示"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;