"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
var _default = {
  __name: 'ImportDataSetDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '补充导入数据'
    },
    datasetId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'expandTrainDataSetSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const modelInfo = (0, _vue.inject)('modelInfo');
    const confidence = (0, _vue.ref)(0.7);
    const annotation_status = (0, _vue.ref)(3);
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const changeConfidence = val => {
      if (val < 0.6) {
        _useMessage.useMessage.warning('可信度不能低于0.6');
      }
    };
    const btnLoading = (0, _vue.ref)(false);
    const submit = async () => {
      if (confidence.value < 0.6) {
        _useMessage.useMessage.warning('可信度不能低于0.6');
        return;
      }
      btnLoading.value = true;
      const res = await (0, _rapidCreateModel.expandTrainDatasetAi)({
        model_id: modelInfo.id,
        dataset_id: props.datasetId,
        confidence: [confidence.value, 1],
        annotation_status: annotation_status.value
      }).finally(() => btnLoading.value = false);
      if (res.code === 20000) {
        localStorage.setItem(`expandTrainData${modelInfo.id}`, confidence.value);
        emit('expandTrainDataSetSuccess');
        updateVisible();
      }
    };
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        confidence.value = localStorage.getItem(`expandTrainData${modelInfo.id}`) || 0.7;
      }
    });
    return {
      __sfc: true,
      props,
      modelInfo,
      confidence,
      annotation_status,
      emit,
      updateVisible,
      changeConfidence,
      btnLoading,
      submit
    };
  }
};
exports.default = _default;