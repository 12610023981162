"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "key-words-or-event"
  }, [_c("el-breadcrumb", {
    key: _vm.getSeparator,
    class: {
      "has-order": _vm.hasOrder
    },
    attrs: {
      separator: _vm.getSeparator
    }
  }, _vm._l(_vm.selectList, function (event, _index) {
    return _c("el-breadcrumb-item", {
      key: _index
    }, [_vm.type === "accountLabel" ? _c("span", _vm._l(event, function (labelId) {
      return _c("span", {
        key: labelId,
        staticClass: "event-tag account-label-tag",
        class: {
          "or-tag": _vm.relation === "or"
        }
      }, [_vm._v(_vm._s(_vm.getAccountLabelName(labelId)))]);
    }), 0) : event[_vm.keyValue] ? _c("span", {
      staticClass: "event-tag",
      class: {
        "or-tag": _vm.relation === "or"
      }
    }, [event.inverse ? _c("i", {
      staticClass: "not-include"
    }, [_vm._v(_vm._s(event.inverse ? "不包含" : ""))]) : _vm._e(), event.role !== "default" ? _c("i", {
      staticClass: "speaker"
    }, [_vm._v(_vm._s(_vm.getSpeaker(event.role)))]) : _vm._e(), _vm._v(_vm._s(event[_vm.keyValue]))]) : _vm._e()]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;