"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    attrs: {
      effect: "dark",
      disabled: !_vm.disabled,
      content: _vm.disabledTip,
      "popper-class": "schema-form-aside-tooltip",
      placement: "right"
    }
  }, [_c("div", {
    staticClass: "schema-form-generate-aside",
    class: _vm.disabled ? "aside-disabled" : ""
  }, _vm._l(_setup.fieldTypes(), function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "field-types"
    }, [_c("p", {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(item.text))]), _c(_setup.draggable, _vm._b({
      staticClass: "mg-draggable",
      attrs: {
        tag: "ul",
        list: item.list,
        move: _setup.handleMove,
        disabled: _vm.disabled
      },
      on: {
        end: _setup.handleMoveEnd,
        start: _setup.handleMoveStart
      }
    }, "draggable", {
      group: {
        name: "people",
        pull: "clone",
        put: false
      },
      sort: false,
      ghostClass: "ghost"
    }, false), _vm._l(item.list, function (field) {
      return _c("div", {
        key: field.id,
        staticClass: "generate-item",
        attrs: {
          "data-field-type": field.id
        },
        on: {
          click: function ($event) {
            return _setup.onItemClick(field.id);
          }
        }
      }, [_c("div", {
        staticClass: "none-draggable-item"
      }, [_c("i", {
        class: ["iconfont", `icon-${field.icon}`]
      }), _c("span", {
        staticClass: "text"
      }, [_vm._v(_vm._s(field.text))])]), _c("div", {
        staticClass: "ghost-draggable-item"
      }, [_vm._v("松开放到此处")])]);
    }), 0)], 1);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;