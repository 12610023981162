"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyEventCascader',
  props: {
    keyEventOptions: {
      type: Array,
      default: () => []
    },
    selectedList: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number
    }
  },
  setup(__props) {
    const props = __props;
    const localKeyEventOptions = (0, _vue.ref)([]);
    const filterKeyEvent = (node, keyword) => {
      if (!props.selectedList.includes(node.path[node.path.length - 1]) && node.text.includes(keyword)) {
        return true;
      }
      return false;
    };

    // 处理下拉框数据

    const addDisabled = (tree, disableList) => {
      tree.forEach((item, index) => {
        if (disableList.includes(item.id)) {
          item.is_disabled = true;
        } else {
          item.is_disabled = false;
        }
        if (item.children && item.children.length) {
          addDisabled(item.children, disableList);
        }
      });
    };
    (0, _vue.watchEffect)(() => {
      localKeyEventOptions.value = (0, _cloneDeep.default)(props.keyEventOptions);
      addDisabled(localKeyEventOptions.value, props.selectedList);
    });
    return {
      __sfc: true,
      props,
      localKeyEventOptions,
      filterKeyEvent,
      addDisabled
    };
  }
};
exports.default = _default;