"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NodeSideBar',
  data() {
    return {
      nodelist: [{
        label: 'coaching.narration',
        icon: 'narration',
        value: 'narrator'
      }, {
        label: 'coaching.customerSays',
        icon: 'robot',
        value: 'customer'
      }, {
        label: 'coaching.salespersonSays',
        icon: 'chat-dot-square',
        value: 'salesman'
      }]
    };
  }
};
exports.default = _default;