"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _setup.closeDialog,
      title: "配置模型",
      width: "600px"
    }
  }, [_c("div", {
    staticClass: "dialog-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h3", [_vm._v("配置模型")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      "popper-class": "set-model-dialog-tooltip"
    }
  }, [_c("div", {
    staticClass: "tooltip-content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" 更换为其他已训练模型，或前往"), _c("span", {
    staticClass: "model-management-page",
    on: {
      click: function ($event) {
        return _setup.goToModelManagementPage();
      }
    }
  }, [_vm._v("模型管理")]), _vm._v("页新建 ")]), _c("i", {
    staticClass: "tip-icon iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "info-item"
  }, [_c("span", {
    staticClass: "info-label"
  }, [_vm._v("关键事件")]), _c("span", {
    staticClass: "event-tag"
  }, [_vm._v(_vm._s(_vm.eventInfo.name))])]), _c("div", {
    staticClass: "info-item"
  }, [_c("span", {
    staticClass: "info-label"
  }, [_vm._v("当前模型")]), _vm.eventInfo.model_id ? _c("span", {
    staticClass: "model-info"
  }, [_c("div", {
    staticClass: "model-name"
  }, [_c("i", {
    staticClass: "iconfont icon-box-square"
  }), _c("span", {
    staticClass: "model-name-text"
  }, [_vm._v(_vm._s(_vm.eventInfo.model_name))]), _c("span", {
    staticClass: "model-name-text"
  }, [_vm._v("(V" + _vm._s(_vm.eventInfo.model_version) + ")")])])]) : _c("span", [_vm._v("无")])]), _c("div", {
    staticClass: "info-item"
  }, [_c("span", {
    staticClass: "info-label"
  }, [_vm._v("更换为")]), _vm.visible ? _c(_setup.ModelAndVersionSelect, {
    ref: "modelAndVersionSelectRef",
    on: {
      selectChange: _setup.selectChange
    }
  }) : _vm._e(), _c("el-button", {
    class: _setup.model_id ? "show-model-btn" : "",
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _setup.goToModelManagementPage(_setup.model_id);
      }
    }
  }, [_vm._v("模型详情"), _c("i", {
    staticClass: "el-icon--right iconfont icon-top-right"
  })])], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _setup.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("确定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;