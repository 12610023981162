"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'HistoryTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      historyTableHead: [{
        prop: 'operate_time',
        label: '操作时间',
        minWidth: 180
      }, {
        prop: 'operate_user',
        label: '操作人',
        minWidth: 100
      }, {
        prop: 'operate_type',
        label: '操作类型',
        minWidth: 70
      }, {
        prop: 'operate_version',
        label: '操作版本',
        minWidth: 70
      }, {
        prop: 'add_info',
        label: '补充信息',
        minWidth: 200
      }],
      listParams: {
        page: 1,
        size: 20
      },
      total: 0,
      historyTableHeight: 'calc(100vh - 172px)'
    };
  },
  methods: {
    handleSizeChange(size) {
      this.listParams.size = size;
      this.getModelResult();
    },
    handleCurrentChange(page) {
      this.listParams.page = page;
      this.getModelResult();
    }
  }
};
exports.default = _default;