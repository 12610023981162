"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _ComplianceStatusTag = _interopRequireDefault(require("./ComplianceStatusTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RemovedRecordDrawer',
  components: {
    NoData: _NoData.default,
    ComplianceStatusTag: _ComplianceStatusTag.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requestParams: {
        workspace_id: 0,
        task_id: 0,
        sort_by: [['compliance_remove_time', 'descending']],
        page: 1,
        size: 20
      },
      tableHeader: [{
        label: '会话名称',
        prop: 'conv_name',
        width: 260
      }, {
        label: '质检员',
        prop: 'compliance_user',
        width: 120
      }, {
        label: '质检状态',
        prop: 'compliance_status',
        width: 120
      }, {
        label: '移除时间',
        prop: 'compliance_remove_time',
        width: 200
      }, {
        label: '操作',
        prop: 'operate',
        width: 66
      }],
      headerCellStyle: {
        backgroundColor: '#F3F5F6',
        fontSize: '14px',
        color: '#576575'
      },
      cellStyle: {
        height: '72px',
        fontSize: '14px',
        color: '#2B323B'
      },
      tableLoading: false,
      sortable: ['compliance_remove_time'],
      tableData: [],
      total: 0,
      restoreCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.restoreCount = 0;
          this.requestParams.workspace_id = this.workspaceInfo.id;
          const taskId = this.$route.query.id;
          if (taskId) {
            this.requestParams.task_id = Number(taskId);
          } else {
            delete this.requestParams.task_id;
          }
          this.getRemoveList();
        }
      }
    }
  },
  methods: {
    async goCompliancePersonal(row) {
      const res = await (0, _compliance.getRemoveConvIds)(this.requestParams);
      if (res.code === 20000) {
        const conversationIds = res.results;
        const query = {
          id: row.conv_id,
          task_id: Number(this.$route.query.id) || row.task_id,
          sampling_type: this.$route.query.sampling_type
        };
        this.$router.push({
          name: 'ComplianceWorkSpace',
          query: {
            ...query
          },
          params: {
            conversationIds
          }
        });
      }
    },
    sortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.requestParams.sort_by = [[prop, order]];
      this.getRemoveList();
    },
    async ReStoreConv(row) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        conv_id: row.conv_id,
        is_remove: false
      };
      const res = await (0, _compliance.removeOrRestoreComplianceConv)(data);
      if (res.code === 20000) {
        this.restoreCount++;
        this.$message.success('恢复成功');
        this.getRemoveList();
      }
    },
    async getRemoveList() {
      this.tableLoading = true;
      const res = await (0, _compliance.getRemoveList)(this.requestParams).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        this.tableData = res.results.data;
        this.total = res.results.total;
      }
    },
    sizeChange(val) {
      this.requestParams.size = val;
      this.getRemoveList();
    },
    currentChange(val) {
      this.requestParams.page = val;
      this.getRemoveList();
    },
    handleClose() {
      this.$emit('update:visible', false);
      if (this.restoreCount) {
        this.$emit('refresh');
      }
    }
  }
};
exports.default = _default;