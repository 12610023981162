"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _i18n = require("@/i18n");
var _default = {
  __name: 'PointDeductionItemsDrawerContent',
  props: {
    userId: {
      type: Number,
      default: 0
    },
    planId: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const pointDeductionItems = (0, _vue.ref)([]);
    const loading = (0, _vue.ref)(false);
    const getSmartLostGists = async () => {
      const res = await (0, _coachingCenter.getSmartLostGistsAsync)({
        user_id: props.userId,
        plan_id: props.planId
      }).finally(() => {
        loading.value = false;
      });
      pointDeductionItems.value = res.results.data;
    };
    (0, _vue.onMounted)(getSmartLostGists);
    return {
      __sfc: true,
      props,
      pointDeductionItems,
      loading,
      getSmartLostGists,
      t: _i18n.t
    };
  }
};
exports.default = _default;