"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _dashboard = require("@/api/dashboard");
var _convList = _interopRequireDefault(require("./convList.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    convList: _convList.default
  },
  data() {
    return {
      tabArray: [{
        label: '我的会话',
        name: 'my'
      }, {
        label: '团队',
        name: 'team'
      }, {
        label: '公司',
        name: 'company'
      }],
      activeName: '',
      currentPage: 1,
      pageSize: 10,
      convList: [],
      loading: false,
      listLoading: false,
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['historyConversation', 'timezone']),
    nomore() {
      const safeCurrentPage = Math.ceil(Number(this.total) / Number(this.pageSize)) == 0 ? 1 : Math.ceil(Number(this.total) / Number(this.pageSize));
      return this.currentPage === safeCurrentPage && !this.loading;
    }
  },
  watch: {
    currentPage: {
      handler: function (val) {
        if (val > 1) {
          this.getConvList(this.activeName);
        }
      }
    }
  },
  created() {
    this.initTab();
  },
  methods: {
    initTab() {
      const session = sessionStorage.getItem('conversationTab');
      if (session) {
        this.activeName = session;
      } else if (this.historyConversation) {
        const history = this.historyConversation.split('/').pop();
        this.activeName = history;
      } else {
        this.activeName = 'my';
      }
      this.listLoading = true;
      this.getConvList(this.activeName);
    },
    handleClick(tab, event) {
      this.currentPage = 1;
      this.convList = [];
      this.listLoading = true;
      sessionStorage.setItem('conversationTab', this.activeName);
      this.getConvList(this.activeName);
    },
    loadMore() {
      if (!this.nomore) {
        this.currentPage++;
      }
    },
    getConvList(activeName) {
      this.loading = true;
      const url = `/home/conversation/${activeName}`;
      const params = {
        current_page: this.currentPage,
        page_size: this.pageSize,
        time_zone: this.timezone
      };
      (0, _dashboard.getConvList)(url, params).then(res => {
        if (res.code === 200) {
          if (this.currentPage == 1) {
            this.convList = res.results.data;
          } else {
            this.convList = this.contactArray(this.convList, res.results);
          }
          this.total = res.results.total;
        }
      }).finally(() => {
        this.loading = false;
        this.listLoading = false;
      });
    },
    contactArray(arr, newArr) {
      const len = arr.length;
      if (arr[len - 1].day == newArr.data[0].day) {
        arr[len - 1].data = arr[len - 1].data.concat(newArr.data[0].data);
        for (let i = 1; i < newArr.data.length; i++) {
          arr.push(newArr.data[i]);
        }
      } else {
        newArr.data.forEach(item => {
          arr.push(item);
        });
      }
      return arr;
    }
  }
};
exports.default = _default;