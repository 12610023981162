"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.item.children ? [_c("el-menu-item", {
    attrs: {
      index: String(_vm.item.id)
    }
  }, [_c("span", {
    staticClass: "children-name"
  }, [_vm._v(_vm._s(_vm.item.name))]), _vm.item.is_save ? _c("span", {
    staticClass: "is-saved"
  }, [_vm._v("已保存")]) : _vm._e()])] : _c("el-submenu", {
    attrs: {
      index: String(_vm.item.id)
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _vm._l(_vm.item.children, function (v) {
    return _c("submenu-item", {
      key: v.id,
      attrs: {
        item: v
      }
    });
  })], 2)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;