"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item",
    on: {
      click: function ($event) {
        return _vm.handleClick(_vm.item);
      }
    }
  }, [_c("div", {
    staticClass: "conv-title"
  }, [_c("i", {
    class: _vm.handleConvTitle(_vm.item)
  }), _vm._v(" " + _vm._s(_vm.item.entity_name) + " ")]), _c("div", {
    staticClass: "conv-detail-box"
  }, [_vm.item.params && _vm.item.params.entity_type === "deal" ? [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "商机阶段",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("mg-tag", {
    attrs: {
      center: "",
      size: "small",
      type: _vm.handleStatusMsg(_vm.item.deal_stage).type
    }
  }, [_vm.handleStatusMsg(_vm.item.deal_stage).iconClass ? _c("i", {
    class: _vm.handleStatusMsg(_vm.item.deal_stage).iconClass
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.item.cur_deal_stage) + " ")])], 1), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "跟进人",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_vm.item.entity_user ? _c("span", [_vm._v(_vm._s(_vm.item.entity_user.name))]) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "商机金额",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.parseFormatNum(_vm.item.amount, 2)) + "元")])]), _vm.item.risk_count > 0 ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "风险",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("span", {
    staticClass: "risk"
  }, [_c("i", {
    staticClass: "iconfont icon-risk"
  }), _c("span", [_vm._v(_vm._s(_vm.item.risk_count))])])]) : _vm._e(), _vm.item.objection_count > 0 ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "客户异议数",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("span", [_vm._v("客户异议：" + _vm._s(_vm.item.objection_count))])]) : _vm._e()] : [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话日期和时间",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "conv-detail-time"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _c("div", {
    staticClass: "conv-detail-time-date"
  }, [_vm._v(" " + _vm._s(_vm.getConvTime(_vm.item.entity_date)) + " ")])])]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "销售",
      placement: "top",
      "popper-class": "conv-list-tip",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "conv-detail-name"
  }, [_vm._v(" " + _vm._s(_vm.item.entity_user && _vm.item.entity_user.name) + " ")])])]], 2), _c("div", {
    staticClass: "comment-content"
  }, [_c("div", {
    class: ["comment-icon", [4].includes(_vm.item.content_type) ? "orange" : "", _vm.activeName === "inbox" ? "receive" : ""]
  }, [_c("i", {
    class: _vm.iconClass
  })]), _vm.activeName === "inbox" ? [_c("image-avatar", {
    staticClass: "avatar",
    attrs: {
      name: _vm.item.name,
      avatar: _vm.item.avatar
    }
  }), _c("div", {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.item.name))])] : _vm._e(), _c("div", {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.item.content) + " : "), _c("span", {
    staticClass: "text",
    domProps: {
      innerHTML: _vm._s(_vm.item.params ? _vm.formatCommentContent(_vm.item.params.comment_content) : "")
    }
  })])], 2), _c("div", {
    staticClass: "time"
  }, [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(_vm.item.create_at).format("YYYY-MM-DD HH:mm")) + " ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;