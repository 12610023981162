"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'AppCard',
  props: {
    appLogo: {
      type: String,
      default: ''
    },
    appName: {
      type: String,
      default: ''
    },
    appDescription: {
      type: String,
      default: ''
    },
    isBind: {
      type: Boolean,
      default: false
    },
    bindUserInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isShowUnbindBtn: false
    };
  },
  methods: {
    convertAppName() {
      switch (this.appName) {
        case 'wework':
          return '企业微信';
        case 'wemeet':
          return '腾讯会议';
        default:
          break;
      }
    },
    getAuthLink() {
      this.$emit('getAuthLink', this.appName);
    },
    goMergeAppDataPage() {
      this.$router.push({
        path: _routeMap.ROUTES.mergeAppData
      });
    },
    removeBinding() {
      const name = this.convertAppName();
      this.$confirm(`解除关联后，系统将不再自动拉取${name}文本数据`, '提 示', {
        confirmButtonText: '解除关联',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(async () => {
        await this.$emit('unbindApp', this.appName);
      }).catch(() => {});
    }
  }
};
exports.default = _default;