"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _tagBgColorArray = require("../tagBgColorArray.js");
var _CreateTaskForm = _interopRequireDefault(require("@/components/TaskCenterDrawer/components/CreateTaskForm.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountRecommendItem',
  components: {
    CreateTaskForm: _CreateTaskForm.default
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    eventList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeItemId: {
      type: Number,
      default: null
    }
  },
  computed: {
    createTaskdialogVisible() {
      return this.activeItemId === this.item.id;
    }
  },
  methods: {
    goEntityDetail(item) {
      const routeKey = item.account_name ? 'account' : 'tradeDetail';
      const idKey = item.account_name ? 'account_id' : 'trade_id';
      const path = _routeMap.ROUTES[routeKey];
      const id = item[idKey];
      this.openNewPage(path, {
        id,
        openDetailDrawer: true
      });
    },
    getConvIcon(_ref) {
      let {
        conversation: {
          conversation_type: type
        }
      } = _ref;
      const ICON_MAP = {
        video: 'video-camera',
        audio: 'phone-outline',
        doc: 'im'
      };
      return `iconfont icon-${ICON_MAP[type]}`;
    },
    getHighlightContent(_ref2) {
      let {
        sentence,
        light_position
      } = _ref2;
      const keyWords = light_position.map(_ref3 => {
        let {
          key_word
        } = _ref3;
        return key_word;
      }).sort((a, b) => b.length - a.length);
      return sentence.replace(new RegExp(`(${keyWords.join('|')})`, 'g'), '<span class="keyword-highlight">$1</span>');
    },
    goConvSentence(item, todoItem) {
      const path = _routeMap.ROUTES.conversationDetail;
      const query = {
        id: item.conversation.conversation_id,
        order: todoItem.order,
        noback: true
      };
      this.openNewPage(path, query);
    },
    goConv(_ref4) {
      let {
        is_access,
        conversation
      } = _ref4;
      if (!is_access) {
        this.$message.warning('无访问权限，请联系管理员');
        return;
      }
      const path = _routeMap.ROUTES.conversationDetail;
      const query = {
        id: conversation.conversation_id,
        noback: true
      };
      this.openNewPage(path, query);
    },
    getConvTime(time) {
      return this.$plus8ToCurrentZone(time).format('MM-DD HH:mm');
    },
    openNewPage(path, query) {
      const router = this.$router.resolve({
        path,
        query
      });
      window.open(router.href, '_blank');
    },
    getEventIndex(event_id) {
      const index = this.eventList.findIndex(item => item.id === event_id);
      return index;
    },
    getEventName(event_id) {
      var _this$eventList$index;
      const index = this.getEventIndex(event_id);
      const name = ((_this$eventList$index = this.eventList[index]) === null || _this$eventList$index === void 0 ? void 0 : _this$eventList$index.name) || this.item.todo_name;
      return name;
    },
    getEventTagColor(event_id) {
      const index = this.getEventIndex(event_id);
      const color = _tagBgColorArray.COLORARRAY[index];
      return color;
    },
    closeCreateTaskDialog() {
      this.$emit('changeActiveDialogId', null);
    },
    creatNewTask() {
      // eslint-disable-next-line vue/no-mutating-props
      this.item.task_status = 1;
      this.$message.success('新建成功');
      this.closeCreateTaskDialog();
    },
    openCreateTaskDialog(item) {
      this.$emit('changeActiveDialogId', item.id);
      const todoName = this.getEventName(item.event_id);
      const entityName = item.trade_name || item.account_name;
      const routeName = item.account_name ? 'account' : 'tradeDetail';
      const idKey = item.account_name ? 'account_id' : 'trade_id';
      // const path = ROUTES[routeKey];
      const id = item[idKey];
      this.defaultTextInfo = [{
        text: todoName + '-',
        router_name: '',
        router_params: {}
      }, {
        text: entityName,
        router_name: routeName,
        router_params: {
          id
        }
      }];
      const executorData = [{
        id: String(item.conversation.conversation_user_id),
        name: item.conversation.conversation_user_name
      }];
      const todoTime = item.todo_time;
      this.$nextTick(() => {
        this.$refs.createTaskForm.setDefaultValue('', executorData, todoTime);
      });
    }
  }
};
exports.default = _default;