"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MenuToggleBtn',
  inject: ['isEmbed'],
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentMenuElement: null
    };
  },
  mounted() {
    if (this.isEmbed) return;
    this.currentMenuElement = this.getMenuElement();
  },
  methods: {
    getMenuElement() {
      let ele = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;
      if (ele.$el.className.indexOf('app-main') === -1) {
        return ele.$refs['nav-menu'] || this.getMenuElement(ele.$parent);
      }
    },
    toggleMenu() {
      this.currentMenuElement.toggleMenu();
    },
    floatingMenuShow() {
      if (!this.isHiddenMenu) return;
      this.currentMenuElement.floatingMenuShow();
    }
  }
};
exports.default = _default;