"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("figure", {
    staticClass: "statistics-card",
    class: {
      active: _vm.active
    }
  }, [_c("div", [_c("label", [_vm._v(_vm._s(_vm.label))]), _c("figcaption", _vm._l(_vm.valueArr, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(_vm._s(item))]);
  }), 0)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;