"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "delete-confirmation-dialog",
    attrs: {
      title: "删除知识库",
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "480px"
    }
  }, [_c("div", {
    staticClass: "explain"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("span", [_vm._v("知识库删除后，所有内容将被删除，所有成员将不能再访问。请谨慎操作")])]), _c("div", {
    staticClass: "confirm-container"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("请在下方输入框中输入“确认删除”以确认操作")]), _c("el-input", {
    class: ["validate-error", {
      show: _vm.isValidate && !_vm.confirmVal.length
    }],
    attrs: {
      placeholder: "确认删除"
    },
    model: {
      value: _vm.confirmVal,
      callback: function ($$v) {
        _vm.confirmVal = $$v;
      },
      expression: "confirmVal"
    }
  }), _c("span", {
    staticClass: "validate-error-message"
  }, [_vm._v("不能为空")])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "danger",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.delete")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;