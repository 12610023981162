"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _clues = require("@/api/clues");
var _seiriNotes = _interopRequireDefault(require("@/views/afterCloseConv/seiriNotes"));
var _BusinessForm = _interopRequireDefault(require("@/components/BusinessForm"));
var _DealForm = _interopRequireDefault(require("@/components/DealForm"));
var _LeadForm = _interopRequireDefault(require("@/components/LeadForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    SeiriNotes: _seiriNotes.default,
    BusinessForm: _BusinessForm.default,
    DealForm: _DealForm.default,
    LeadForm: _LeadForm.default
  },
  data() {
    return {
      notes: null,
      dealFormData: {
        customer_name: '',
        deal_name: '',
        amount: '',
        status: '',
        deal_time: '',
        next_connect_time: '',
        decision_maker: {
          name: '',
          tel: '',
          email: ''
        },
        other_contacts: [{
          name: '',
          tel: '',
          email: ''
        }, {
          name: '',
          tel: '',
          email: ''
        }]
      },
      leadFormData: null,
      initForm: null,
      hasChange: false,
      saveLoading: false
    };
  },
  watch: {
    dealFormData: {
      deep: true,
      handler: function (newV, oldV) {
        const initStr = JSON.stringify(this.initForm);
        if (initStr === JSON.stringify(newV)) {
          this.hasChange = false;
        } else {
          this.hasChange = true;
        }
      }
    },
    deal_id: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getBusiChanceInfo();
        }
      }
    },
    lead_id: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getOneCluesInfo();
        }
      }
    }
  },
  methods: {
    getBusiChanceInfo() {
      (0, _business.getOneBusiness)(this.deal_id).then(infoRes => {
        this.initForm = JSON.parse(JSON.stringify(infoRes.results));
        this.dealFormData = JSON.parse(JSON.stringify(infoRes.results));
      });
    },
    getOneCluesInfo() {
      (0, _clues.getOneCluesInfo)(this.lead_id).then(infoRes => {
        this.initForm = JSON.parse(JSON.stringify(infoRes.results));
        this.leadFormData = JSON.parse(JSON.stringify(infoRes.results));
      });
    },
    handleSave() {
      if (this.deal_id) {
        this.$nextTick(() => {
          this.$refs['deal-form'].updateFullDeal();
        });
      }
      if (this.lead_id) {
        this.$nextTick(() => {
          this.$refs['lead-form'].submit();
        });
      }
    },
    handleSubmitSucceed() {
      this.$emit('closeEditForm');
    },
    saveBusiness() {
      new Promise((resolve, reject) => {
        const child = this.$refs.busi_form;
        child.$refs['business-form'].validate(valid => {
          if (valid) {
            this.saveLoading = true;
            // 处理对象和数组，转换为Jason字符串
            const formData = this.handleBusiFormData();
            (0, _business.editBusiness)(formData).then(res => {
              if (res.code === 200) {
                this.hasChange = false;
              }
              this.saveLoading = false;
              this.$emit('closeEditForm');
            }).catch(() => {
              this.saveLoading = false;
            });
            resolve();
          } else {
            throw new Error('校验失败');
          }
        });
      });
    },
    saveClues() {
      new Promise((resolve, reject) => {
        const child = this.$refs.clues_form;
        child.$refs['clues-form'].validate(valid => {
          if (valid) {
            this.saveLoading = true;
            // 处理对象和数组，转换为Jason字符串
            const formData = this.handleCluesFormData();
            // updateForm.other_contacts = other_contactsStr
            (0, _clues.editClues)(formData).then(res => {
              if (res.code === 200) {
                this.hasChange = false;
              }
              this.saveLoading = false;
              this.$emit('closeEditForm');
            }).catch(() => {
              this.saveLoading = false;
            });
            resolve();
          } else {
            throw new Error('校验失败');
          }
        });
      });
    },
    handleBusiFormData() {
      const updateForm = JSON.parse(JSON.stringify(this.dealFormData));
      const decisionStr = JSON.stringify(updateForm.decision_maker);
      const participantaArray = updateForm.other_contacts;
      updateForm.decision_maker = decisionStr;
      const filterArray = participantaArray.filter(item => {
        const valArray = Object.values(item);
        const isHasVal = valArray.some(n => n);
        return isHasVal;
      });
      filterArray.forEach((item, index) => {
        filterArray[index] = JSON.stringify(item);
      });
      updateForm.other_contacts = filterArray;
      return updateForm;
    },
    handleCluesFormData() {
      const form = JSON.parse(JSON.stringify(this.leadFormData));
      const {
        id,
        name,
        tel,
        email
      } = form.contact;
      if (!name && !tel && !email && id === 0) {
        form.contact = {};
      }
      form.contact = JSON.stringify(form.contact);
      return form;
    },
    inactivate(lead_id) {
      this.$confirm(`转为无效后您将无法看到，可联系管理员进行激活`, '是否将该线索转为无效？', {
        confirmButtonText: '确 定',
        confirmButtonClass: 'el-button--primary',
        cancelButtonText: '取 消'
      }).then(async () => {
        const res = await (0, _clues.invalidateLead)({
          lead_id
        });
        if (res.code === 200 && res.msg === 'Success') {
          this.$message.success('已将该线索转为无效');
          this.$emit('closeEditForm');
        }
      });
    }
  }
};
exports.default = _default;