"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permissions = require("@/api/permissions");
var _organization = require("@/api/organization");
var _DepartmentSingleSelector = _interopRequireDefault(require("@/components/MgTreeSelect/DepartmentSingleSelector"));
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'MoveDepartmentDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    moveDepartmentId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'moveDepartmentSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const newDepartmentPid = (0, _vue.ref)(0);
    const handleClose = () => {
      newDepartmentPid.value = 0;
      emit('update:visible', false);
    };
    const onDepartmentChange = (departmentIds, val) => {
      newDepartmentPid.value = departmentIds[0];
    };
    const moveDepartmentApi = async () => {
      const res = await (0, _organization.moveDepartment)({
        id: props.moveDepartmentId,
        pid: newDepartmentPid.value
      });
      if (res.code === 20000) {
        (0, _useMessage.useMessage)({
          type: 'success',
          message: (0, _i18n.t)('management.移动成功')
        });
        emit('moveDepartmentSuccess', newDepartmentPid.value);
        handleClose();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      newDepartmentPid,
      handleClose,
      onDepartmentChange,
      moveDepartmentApi,
      getDepartmentWithAuth: _permissions.getDepartmentWithAuth,
      DepartmentSingleSelector: _DepartmentSingleSelector.default
    };
  }
};
exports.default = _default;