"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _moment = _interopRequireDefault(require("moment"));
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    },
    activeName: {
      type: String,
      default: 'my'
    },
    timeField: {
      type: String,
      default: 'begin_time'
    }
  },
  data() {
    return {};
  },
  computed: {
    getDuration() {
      return function (seconds) {
        let {
          s
        } = (0, _timeFormatter.second2HMS)(seconds);
        const {
          m,
          h
        } = (0, _timeFormatter.second2HMS)(seconds);
        if (h) {
          return m ? `<span class="timer-number">${h}</span><span class="timer-text">小时</span><span class="timer-number"> ${m}</span><span class="timer-text">分</span>` : `<span class="timer-number">${h}</span><span class="timer-text">小时</span>`;
        } else if (m) {
          return s > 29 ? `<span class="timer-number">${m + 1}</span><span class="timer-text">分</span>` : `<span class="timer-number">${m}</span><span class="timer-text">分</span>`;
        } else if (s) {
          s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(0);
          return `<span class="timer-number"> ${s}</span><span class="timer-text">秒</span>`;
        } else {
          return `<span class="timer-number"> 0</span><span class="timer-text">秒</span>`;
        }
      };
    }
  },
  mounted() {},
  methods: {
    goDetails(id) {
      this.$router.push({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: id
        }
      });
    }
  }
};
exports.default = _default;