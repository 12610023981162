"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _AddFolderDropdown = _interopRequireDefault(require("./AddFolderDropdown.vue"));
var _FolderDropdown = _interopRequireDefault(require("@/components/FolderTree/FolderDropdown.vue"));
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    AddFolderDropdown: _AddFolderDropdown.default,
    FolderDropdown: _FolderDropdown.default
  },
  props: {
    folder: {
      type: Object,
      default: () => ({})
    },
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLike: false,
      isCollect: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    hasForderId() {
      return !!this.folder.id;
    }
  },
  watch: {
    folder(val) {
      this.isLike = val.is_like;
      this.isCollect = val.is_collect;
    }
  },
  methods: {
    async likeFolder() {
      const res = await (0, _library.likeFolder)(this.folder.id);
      if (res.code === 20000) {
        this.isLike = true;
      }
    },
    async dislikeFolder() {
      const res = await (0, _library.dislikeFolder)(this.folder.id);
      if (res.code === 20000) {
        this.isLike = false;
      }
    },
    async colletFolder() {
      const res = await (0, _library.colletFolder)(this.folder.id);
      if (res.code === 20000) {
        this.isCollect = true;
      }
    },
    async discollectFolder() {
      const res = await (0, _library.discollectFolder)(this.folder.id);
      if (res.code === 20000) {
        this.isCollect = false;
      }
    },
    uploadFile() {
      this.$emit('uploadFile');
    }
  }
};
exports.default = _default;