"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$data$dimension;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart-box"
  }, [_c("p", {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.data.dimension) + "划分的" + _vm._s(_vm.data.name))]), _c("p", {
    staticClass: "card-subtitle"
  }, [_vm._v(_vm._s((_vm$data$dimension = _vm.data.dimension) === null || _vm$data$dimension === void 0 ? void 0 : _vm$data$dimension.slice(1)))]), _c("chart-template", {
    attrs: {
      "chart-config": _vm.chartOption,
      "chart-loading": _vm.chartLoading
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;