"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: '',
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    defaultStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeStatus: ''
    };
  },
  watch: {
    defaultStatus: {
      handler: function (val) {
        if (val) {
          this.selectSortType(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    selectSortType(type) {
      // if (this.activeStatus === type) {
      //   this.activeStatus = "";
      // } else {
      //   this.activeStatus = type;
      // }
      this.activeStatus = type;
      this.$emit('sortCallBack', this.activeStatus);
    },
    handleClick() {
      if (!this.activeStatus) {
        this.activeStatus = 'descending';
        this.$emit('sortCallBack', 'descending');
      } else if (this.activeStatus === 'descending') {
        this.activeStatus = 'ascending';
        this.$emit('sortCallBack', 'ascending');
      } else if (this.activeStatus === 'ascending') {
        this.activeStatus = 'descending';
        this.$emit('sortCallBack', 'descending');
      }
    },
    init() {
      this.activeStatus = '';
    }
  }
};
exports.default = _default;