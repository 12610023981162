"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "team-analysis-table"
  }, [_c("el-table", {
    ref: "team-analysis-table",
    class: {
      department: _vm.dimension === "department"
    },
    attrs: {
      data: _vm.eachPageTableData,
      border: "",
      "show-overflow-tooltip": "",
      "cell-class-name": _vm.cellClassName,
      height: "calc(100% - 111px)"
    },
    on: {
      "header-dragend": _vm.headerDragend
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      width: "180",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header-cell"
        }, [_c("div", {
          staticClass: "header-title"
        }, [_vm._v(" " + _vm._s(_vm.dimension === "member" ? "姓名" : "部门") + " ")]), _c("div", {
          class: ["detail-count", "user-total"]
        }, [_vm._v(" 总计"), _c("span", {
          staticClass: "header-number"
        }, [_vm._v(_vm._s(_vm.tableData.length))]), _vm._v(" " + _vm._s(_vm.dimension === "member" ? "人" : "个") + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "user-info-cell",
          on: {
            click: function ($event) {
              return _vm.goPersonalOverview(row);
            }
          }
        }, [_vm.dimension === "member" ? _c("image-avatar", {
          staticClass: "upper-avatar",
          attrs: {
            name: row.name,
            avatar: row.avatar,
            shape: "circle"
          }
        }) : _vm._e(), _c("div", {
          staticClass: "user-info"
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: row.tree_name,
            placement: "top-start"
          }
        }, [_c("div", {
          class: {
            "deparment-name": _vm.dimension === "department"
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])])], 1)], 1)];
      }
    }])
  }), _vm._l(_vm.tableHeader, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        prop: item.config.name,
        "min-width": "180",
        width: _vm.teamAnalysisTableColumnWidthConfig[item.config.name]
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "header-cell",
            class: {
              "checked-header-cell": _vm.checkedHeaderCell(item.id),
              "hover-border-color-change": _vm.isShowCheckbox
            },
            on: {
              click: function ($event) {
                return _vm.handleCheckColumn(item);
              }
            }
          }, [_c("div", {
            staticClass: "column-title",
            class: {
              "column-title-flex": !_vm.isShowCheckbox
            }
          }, [_vm.isShowCheckbox ? _c("el-checkbox", {
            attrs: {
              value: _vm.checkedHeaderCell(item.id)
            },
            on: {
              change: function ($event) {
                return _vm.handleCheckColumn(item);
              }
            }
          }) : _vm._e(), _c("sort-caret", {
            ref: "sort-caret" + index,
            refInFor: true,
            class: ["sort-item", {
              "disabled-event": _vm.isShowCheckbox
            }],
            attrs: {
              label: _vm.tableHeader[index].configs.name
            },
            on: {
              sortCallBack: function ($event) {
                return _vm.handleSortChange(index, $event);
              }
            }
          }, [_c("ConfigDetailShow", {
            staticClass: "config-detail-show",
            attrs: {
              slot: "operate",
              placement: "bottom",
              "show-per-conv-option": "",
              "show-conv-key-event-option": "",
              "config-name": _vm.tableHeader[index].configs.name,
              configs: _vm.tableHeader[index].configs
            },
            slot: "operate"
          })], 1), _vm.hasHandlePermission ? _c("el-dropdown", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !_vm.isShowCheckbox,
              expression: "!isShowCheckbox"
            }],
            staticClass: "column-more",
            attrs: {
              trigger: "click",
              placement: "bottom-start"
            },
            on: {
              command: command => _vm.handleCommand(command, _vm.tableHeader[index])
            }
          }, [_c("el-button", {
            staticClass: "column-more-btn",
            attrs: {
              icon: "el-icon-more"
            }
          }), _c("el-dropdown-menu", {
            staticClass: "dropdown",
            attrs: {
              slot: "dropdown"
            },
            slot: "dropdown"
          }, [_c("el-dropdown-item", {
            attrs: {
              command: "edit"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-edit"
          }), _vm._v("编辑 ")]), _c("el-dropdown-item", {
            attrs: {
              command: "delete"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-delete"
          }), _vm._v("删除 ")])], 1)], 1) : _vm._e()], 1), item.value !== -1 ? _c("div", {
            staticClass: "detail-count"
          }, [_vm._v(" 总计 "), _c("span", {
            staticClass: "table-header team-analysis-table-format-data-unit",
            domProps: {
              innerHTML: _vm._s(item.show_value)
            }
          })]) : _vm._e(), _c("div", {
            staticClass: "detail-count"
          }, [item.value !== -1 ? _c("span", {
            staticClass: "label"
          }, [_vm._v("平均")]) : _vm._e(), _c("span", {
            staticClass: "team-analysis-table-format-data-unit",
            domProps: {
              innerHTML: _vm._s(item.show_avg)
            }
          })])])];
        },
        proxy: true
      }, item.field_name === "value" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            class: ["column-cell", _vm.colorCell(row.values[index])]
          }, [row.values[index].value !== "" ? [_c("div", {
            staticClass: "column-cell-detail"
          }, [_c("span", {
            staticClass: "table-body team-analysis-table-format-data-unit",
            domProps: {
              innerHTML: _vm._s(row.values[index].value !== -1 ? row.values[index].show_value : "-")
            }
          })]), row.values[index].value !== -1 ? _c("div", {
            class: ["column-cell-detail", "color-detail"]
          }, [row.values[index].ratio ? [_c("i", {
            class: [row.values[index].ratio > 0 ? "el-icon-caret-top" : "el-icon-caret-bottom"]
          }), _c("span", {
            staticClass: "table-body team-analysis-table-format-data-unit",
            domProps: {
              innerHTML: _vm._s(row.values[index].show_ratio)
            }
          })] : _c("span", {
            staticClass: "empty"
          }, [_vm._v("--")])], 2) : _vm._e()] : _c("div", {
            staticClass: "no-data"
          }, [_vm._v("--")])], 2)];
        }
      } : null], null, true)
    });
  })], 2), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-sizes": [10, 20, 30, 40],
      "current-page": _vm.currPage,
      "page-size": _vm.pageSize,
      total: _vm.tableData.length
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;