"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    attrs: {
      placeholder: "添加提醒"
    },
    on: {
      change: _setup.reminderChange
    },
    model: {
      value: _setup.reminderRelativeTime,
      callback: function ($$v) {
        _setup.reminderRelativeTime = $$v;
      },
      expression: "reminderRelativeTime"
    }
  }, _vm._l(_setup.reminderOptions, function (item) {
    return _c("el-option", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(_vm.isLongerInterval && !item.longerShow),
        expression: "!(isLongerInterval && !item.longerShow)"
      }],
      key: item.time,
      attrs: {
        label: item.label,
        value: item.time
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;