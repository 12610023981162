"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "my-task-detail"
  }, [_c("detail-header", {
    attrs: {
      title: _vm.taskName
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function () {
        return [_c("el-button", {
          staticClass: "go-back-box hover-show-bg",
          on: {
            click: _vm.goComplianceMyTask
          }
        }, [_c("svg-icon", {
          staticClass: "go-back",
          attrs: {
            "icon-class": "arrow-left"
          }
        })], 1), _c("i", {
          staticClass: "iconfont icon-compliance-tasks-fill"
        })];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_vm.interfacePermission.getRemovedRecord ? _c("el-button", {
          staticClass: "hover-show-bg",
          attrs: {
            icon: "iconfont icon-eliminated-already"
          },
          on: {
            click: _vm.openRemovedRecordDrawer
          }
        }, [_vm._v("已移除记录")]) : _vm._e()];
      },
      proxy: true
    }])
  }), _c("div", {
    staticClass: "main-content"
  }, [_c("MyTaskDetailContent", {
    ref: "MemberViewContent",
    attrs: {
      sortable: _vm.sortConfig.sortable,
      "default-sort": _vm.sortConfig.defaultSort,
      "table-data": _vm.tableDataList,
      filters: _vm.filtersObj,
      total: _vm.total,
      loading: _vm.loading,
      "score-rule-list": _vm.scoreRuleList,
      "evaluation-item-list": _vm.evaluationItemList
    },
    on: {
      goComplianceWorkSpace: _vm.goComplianceWorkSpace,
      handleFiltersChange: _vm.handleFiltersChange,
      sizeChange: _vm.sizeChange,
      currentChange: _vm.currentChange,
      sortChange: _vm.sortChange
    }
  })], 1), _c("removed-record-drawer", {
    attrs: {
      visible: _vm.removedRecordDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.removedRecordDrawerVisible = $event;
      },
      refresh: _vm.handleFiltersChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;