"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _main = _interopRequireDefault(require("@/mgv-ui/packages/button/src/main.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MgvButton: _main.default
  },
  data() {
    return {
      input: ''
    };
  },
  created() {
    // this.$message.warning('瑟瑟发抖');
    // this.$message.error('瑟瑟发抖');
    // this.$message.success('瑟瑟发抖');
  },
  methods: {
    click() {
      console.log('===');
    }
  }
};
exports.default = _default;