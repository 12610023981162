"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    staticClass: "metric-select",
    attrs: {
      value: "",
      "remote-method": _setup.getMetric,
      size: "small",
      filterable: "",
      clearable: "",
      remote: "",
      loading: _setup.loading,
      placeholder: "搜索",
      "popper-class": "ai-customer-metric-select-popper"
    },
    on: {
      change: function ($event) {
        return _setup.emit("change", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function () {
        return [_c("i", {
          staticClass: "iconfont icon-search"
        })];
      },
      proxy: true
    }])
  }, _vm._l(_setup.metricList, function (item) {
    return _c("el-option", {
      key: item.id,
      staticClass: "custom-option",
      attrs: {
        value: item
      }
    }, [_c("div", {
      staticClass: "metric-option"
    }, [_c("p", {
      staticClass: "metric",
      domProps: {
        innerHTML: _vm._s(_setup.handleItemText(item.name))
      }
    }), _c("p", {
      staticClass: "dimentsion"
    }, [_vm._v("维度：" + _vm._s(item.dimension_name))])])]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;