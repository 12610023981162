"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "scope-of-open"
  }, [_c("div", {
    staticClass: "choosep-is-public"
  }, [_vm.isEdit ? _c("el-radio-group", {
    on: {
      input: _vm.handleRadioChange
    },
    model: {
      value: _vm.localIsPublic,
      callback: function ($$v) {
        _vm.localIsPublic = $$v;
      },
      expression: "localIsPublic"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true
    }
  }, [_vm._v("对所有人可见")]), _c("el-radio", {
    attrs: {
      label: false
    }
  }, [_vm._v("对指定部门或成员可见")])], 1) : _c("div", {
    staticClass: "radio-disabled"
  }, [_vm._v(" " + _vm._s(_vm.localIsPublic ? "对所有人可见" : "对指定部门或成员可见") + " ")])], 1), !_vm.localIsPublic ? _c("div", {
    staticClass: "choose-members-select"
  }, [_c("members-tree-select", _vm._b({
    ref: "treeSelect",
    on: {
      change: _vm.handleMemberChange
    }
  }, "members-tree-select", _vm.membersTreeSelectBindProps, false)), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowError,
      expression: "isShowError"
    }],
    staticClass: "error-tip"
  }, [_vm._v("请选择部门或者成员")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;