"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkRecordStatus = checkRecordStatus;
exports.deleteNote = deleteNote;
exports.getBusiChanceInfo = getBusiChanceInfo;
exports.getCandConverInfo = getCandConverInfo;
exports.getCandConverInfoBycode = getCandConverInfoBycode;
exports.getCandInfo = getCandInfo;
exports.getCommentParticipant = getCommentParticipant;
exports.getCustomerToken = getCustomerToken;
exports.getInterConverInfo = getInterConverInfo;
exports.getInterConverInfoBycode = getInterConverInfoBycode;
exports.getNoteTopicPreset = getNoteTopicPreset;
exports.getPartiInfo = getPartiInfo;
exports.getSalesToken = getSalesToken;
exports.recordClose = recordClose;
exports.recordStart = recordStart;
exports.sendNote = sendNote;
exports.updateBusiChanceInfo = updateBusiChanceInfo;
exports.updateNote = updateNote;
var _request = require("@/utils/request");
// 轮询检查录制状态
function checkRecordStatus(cname) {
  return (0, _request.request)({
    url: '/record/video_record/transcribe/status',
    method: 'get',
    params: {
      cname
    }
  });
}
function recordStart(conversation_id) {
  return (0, _request.request)({
    url: '/transcribe/begin',
    method: 'post',
    data: {
      conversation_id
    }
  });
}
function recordClose(conversation_id) {
  return (0, _request.request)({
    url: '/record/video_record/transcribe/end',
    method: 'post',
    data: {
      conversation_id
    }
  });
}
function getInterConverInfo(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/info/interviewer',
    method: 'get',
    params: {
      conversation_id
    }
  });
}
function getPartiInfo(channel) {
  return (0, _request.request)({
    url: '/record/video_record/participants',
    method: 'get',
    params: {
      channel
    }
  });
}
function getInterConverInfoBycode(code) {
  return (0, _request.request)({
    url: '/record/video_record/interviewer/by_code',
    method: 'get',
    params: {
      code
    }
  });
}
function getCandConverInfo(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/info/candidate',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 获取销售方token
function getSalesToken(cname, uid) {
  return (0, _request.request)({
    url: '/record/video_record/token',
    method: 'get',
    params: {
      cname,
      uid
    }
  });
}

// 获取客户方token
function getCustomerToken(cname, uid, code) {
  return (0, _request.request)({
    url: '/record/video_record/cus_token',
    method: 'get',
    params: {
      cname,
      uid,
      code
    }
  });
}
function getCandConverInfoBycode(code) {
  return (0, _request.request)({
    url: '/record/video_record/candidate/by_code',
    method: 'get',
    params: {
      code
    }
  });
}

// 会话笔记获取候选人信息
function getCandInfo(conversation_id) {
  return (0, _request.request)({
    url: '/record/video_record/info',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 发送一条笔记
function sendNote(conversation_id, content) {
  return (0, _request.request)({
    url: '/conversation/detail/note/send',
    method: 'post',
    dataType: 'json',
    data: {
      conversation_id,
      content
    }
  });
}

// 获取话题预设列表
function getNoteTopicPreset() {
  return (0, _request.request)({
    url: '/conversation/detail/note/preset',
    method: 'get',
    params: {}
  });
}

/* 整理笔记 */
// 删除笔记
function deleteNote(conversation_id, note_id) {
  return (0, _request.request)({
    url: '/conversation/detail/note/delete',
    method: 'post',
    data: {
      conversation_id,
      note_id
    }
  });
}
// 修改笔记
function updateNote(conversation_id, note_id, content, mark_type) {
  return (0, _request.request)({
    url: '/conversation/detail/note/update',
    method: 'post',
    data: {
      conversation_id,
      note_id,
      content,
      mark_type
    }
  });
}
/* 商机表单 */
// 获取商机表单信息
function getBusiChanceInfo(deal_id) {
  return (0, _request.request)({
    url: '/deal/deal/get_one',
    method: 'get',
    params: {
      deal_id
    }
  });
}

// 跟新商机信息
function updateBusiChanceInfo(data) {
  return (0, _request.request)({
    url: '/deal/deal/update',
    method: 'post',
    data: data
  });
}

// 内部协作
function getCommentParticipant(filter, room_id) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/participant',
    method: 'get',
    params: {
      filter,
      room_id
    }
  });
}