"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tab-label-card"
  }, [_c("div", {
    staticClass: "tab-label-title"
  }, [_vm._v(_vm._s(_vm.labelTitle))]), _c("div", {
    staticClass: "tab-label-value",
    domProps: {
      innerHTML: _vm._s(_vm.labelValueHtml)
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.markValue === "vigilant" ? "推荐范围外" : "推荐范围内",
      placement: "top"
    }
  }, [false ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.markValue,
      expression: "markValue"
    }],
    staticClass: "mark-point",
    class: {
      "vigilant-point": _vm.markValue === "vigilant"
    }
  }) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;