"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TemplateTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['editTemplate', 'deleteTemplate'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const headers = [{
      prop: 'drag',
      label: '',
      minWidth: '30px'
    }, {
      prop: 'order',
      label: '序号',
      minWidth: '80px'
    }, {
      prop: 'name',
      label: '指令名称',
      minWidth: '300px'
    }, {
      prop: 'departments',
      label: '生效范围',
      minWidth: '330px'
    }, {
      prop: 'create_by',
      label: '创建人',
      minWidth: '120px'
    }, {
      prop: 'create_at',
      label: '创建时间',
      minWidth: '160px'
    }];
    const openEditTemplateDialog = row => {
      emit('editTemplate', row);
    };
    const deleteTemplate = (row, index) => {
      emit('deleteTemplate', row, index);
    };
    const tableRef = (0, _vue.ref)(null);
    (0, _vue.onMounted)(() => {
      initDragSort();
    });
    const initDragSort = () => {
      var _tableRef$value;
      const el = (_tableRef$value = tableRef.value) === null || _tableRef$value === void 0 ? void 0 : _tableRef$value.$el.querySelector('.el-table__body-wrapper > table > tbody');
      new _sortablejs.default(el, {
        animation: 150,
        // 动画
        handle: '.move',
        onEnd: evt => {
          emit('rowDragSort', evt);
        }
      });
    };
    return {
      __sfc: true,
      props,
      headers,
      emit,
      openEditTemplateDialog,
      deleteTemplate,
      tableRef,
      initDragSort,
      MgvButton: _index.default,
      VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default
    };
  }
};
exports.default = _default;