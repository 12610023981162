"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _default = {
  props: {
    results: {
      type: Object,
      default() {
        return {
          err_id: 0,
          fail_id: 0,
          abnormal_id: 0,
          total: 0,
          cover_count: 0,
          success_count: 0,
          fail_count: 0,
          abnormal_count: 0
        };
      }
    },
    status: {
      type: String,
      default: "success"
    },
    isMembers: {
      type: Boolean,
      default: false
    },
    tempalteRouter: String,
    uploadName: String
  },
  computed: {
    templateLink() {
      return this.isMembers ? "/organization/user/load" : "/batch";
    },
    baseLink() {
      return this.isMembers ? "/organization/user/download" : "/batch/error";
    },
    errorLink() {
      return `${this.baseLink}?err_id=${this.results.err_id}`;
    },
    failLink() {
      return `${this.baseLink}?fail_id=${this.results.fail_id}`;
    },
    abnormalLink() {
      return `${this.baseLink}?abnormal_id=${this.results.abnormal_id}`;
    },
    iconClass() {
      return this.status.replace(/finish/, "warning");
    }
  },
  methods: {
    downloadError(type, filename) {
      (0, _download.download)(this[`${type}Link`], filename);
    },
    downloadTemplate() {
      (0, _download.download)(this.tempalteRouter, `${this.uploadName}导入模板`);
    }
  }
};
exports.default = _default;