"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contrast-growth-or-decrease"
  }, [_c("div", {
    staticClass: "toggale-container"
  }, _vm._l(_vm.categoryList, function (_ref) {
    let {
      label,
      value
    } = _ref;
    return _c("span", {
      key: label,
      class: ["toggle-item", {
        active: _vm.activeCategory === value
      }],
      on: {
        click: function ($event) {
          return _vm.toggleTeam(value);
        }
      }
    }, [_vm._v(_vm._s(label))]);
  }), 0), _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("高于" + _vm._s(_vm.getActiveLabel) + "平均值")]), _c("ul", [_c("transition-group", {
    attrs: {
      name: "list"
    }
  }, _vm._l(_vm.getData(true), function (item) {
    return _c("li", {
      key: item
    }, [_c("i", {
      staticClass: "el-icon-upload2"
    }), _vm._v(_vm._s(item) + " ")]);
  }), 0)], 1)]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("低于" + _vm._s(_vm.getActiveLabel) + "平均值")]), _c("ul", [_c("transition-group", {
    attrs: {
      name: "list"
    }
  }, _vm._l(_vm.getData(false), function (item) {
    return _c("li", {
      key: item
    }, [_c("i", {
      staticClass: "el-icon-download"
    }), _vm._v(_vm._s(item) + " ")]);
  }), 0)], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;