"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MgSideBarMenu = _interopRequireDefault(require("./MgSideBarMenu"));
var _Logo = _interopRequireDefault(require("./Logo"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SideBarIndex',
  components: {
    Logo: _Logo.default,
    MgSideBarMenu: _MgSideBarMenu.default
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['sidebar']),
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};
exports.default = _default;