"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'SampleItem',
  props: {
    activeDataSetData: {
      type: Object,
      default: () => {}
    },
    source: {
      type: Object,
      default: () => {}
    },
    handleChangeDataSetData: {
      type: Function,
      default: null
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props
    };
  }
};
exports.default = _default;