"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'ConvFlowDropdown',
  inject: ['getContentType'],
  props: {
    circle: {
      type: Boolean,
      default: false
    },
    isTree: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission'])
  }
};
exports.default = _default;