"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BatchModifyUsers = BatchModifyUsers;
exports.ManageDeteRole = ManageDeteRole;
exports.addRole = addRole;
exports.cancelInvite = cancelInvite;
exports.changeRolePermission = changeRolePermission;
exports.createDepartment = createDepartment;
exports.createOrg = createOrg;
exports.deleteDepartment = deleteDepartment;
exports.deleteRole = deleteRole;
exports.deleteUser = deleteUser;
exports.departmentAddUser = departmentAddUser;
exports.editDepartment = editDepartment;
exports.enableAndDisableUser = enableAndDisableUser;
exports.getAiConversationSync = getAiConversationSync;
exports.getDefaultLogo = getDefaultLogo;
exports.getDepartmentTransferData = getDepartmentTransferData;
exports.getDepartmentUsers = getDepartmentUsers;
exports.getDeptRoleList = getDeptRoleList;
exports.getDesensitizationInfo = getDesensitizationInfo;
exports.getLoginCaptcha = getLoginCaptcha;
exports.getLoginLog = getLoginLog;
exports.getManageRoleList = getManageRoleList;
exports.getManageRolePermissionList = getManageRolePermissionList;
exports.getMemberInfo = getMemberInfo;
exports.getMembersFileInfo = getMembersFileInfo;
exports.getMembersFilter = getMembersFilter;
exports.getMembersList = getMembersList;
exports.getOperateLog = getOperateLog;
exports.getOrgInfo = getOrgInfo;
exports.getOrgRole = getOrgRole;
exports.getOrgTree = getOrgTree;
exports.getOrgTreeOptions = getOrgTreeOptions;
exports.getOrgUserList = getOrgUserList;
exports.getResignedMemberList = getResignedMemberList;
exports.getRoleFromDepartment = getRoleFromDepartment;
exports.getRoleList = getRoleList;
exports.getRolePermission = getRolePermission;
exports.getRolePermissionList = getRolePermissionList;
exports.getRoleUserList = getRoleUserList;
exports.getSeatList = getSeatList;
exports.getSeatTabsList = getSeatTabsList;
exports.getTeamList = getTeamList;
exports.getUploadLimitInfo = getUploadLimitInfo;
exports.getUserCanTransferData = getUserCanTransferData;
exports.getUserList = getUserList;
exports.getXiaozhanInfo = getXiaozhanInfo;
exports.handleAddRole = handleAddRole;
exports.handleDeteRole = handleDeteRole;
exports.handleManageAddRole = handleManageAddRole;
exports.handleManageRenameRole = handleManageRenameRole;
exports.membersScale = membersScale;
exports.moveDepartment = moveDepartment;
exports.moveUserFromOtherDepartment = moveUserFromOtherDepartment;
exports.orgInvite = orgInvite;
exports.refreshSeat = refreshSeat;
exports.removeRoleUser = removeRoleUser;
exports.removeUserFromDepartment = removeUserFromDepartment;
exports.resetUserPassword = resetUserPassword;
exports.saveUserInfo = saveUserInfo;
exports.seatOperation = seatOperation;
exports.setAiConversationSync = setAiConversationSync;
exports.setCharge = setCharge;
exports.setRoleChangeDesensitization = setRoleChangeDesensitization;
exports.setRoleDesensitization = setRoleDesensitization;
exports.setRolePermission = setRolePermission;
exports.setUploadLimit = setUploadLimit;
exports.setUserRole = setUserRole;
exports.switchOrg = switchOrg;
exports.transferData = transferData;
exports.transferDepartmentData = transferDepartmentData;
exports.updateOrg = updateOrg;
exports.updateOrgInfo = updateOrgInfo;
exports.updateRole = updateRole;
exports.uploadLogo = uploadLogo;
exports.uploadMembers = uploadMembers;
exports.usage = usage;
var _request = require("@/utils/request");
function createOrg(data) {
  return (0, _request.request)({
    url: '/organization/organization/create',
    method: 'post',
    data
  });
}
function updateOrg(organization_id, name) {
  return (0, _request.request)({
    url: '/organization/update',
    method: 'post',
    data: {
      organization_id,
      name
    }
  });
}
function switchOrg(data) {
  return (0, _request.webService)({
    url: '/profile/team/change',
    method: 'post',
    dataType: 'json',
    data
  });
}
function orgInvite(data) {
  return (0, _request.webService)({
    url: '/organization/invitation/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
function cancelInvite(invitation_id) {
  return (0, _request.request)({
    url: '/organization/invitation/cancel',
    method: 'post',
    data: {
      invitation_id: invitation_id
    }
  });
}

/* --------组织部门新街口分割线-----------*/

// 获取全部角色
function getOrgRole() {
  return (0, _request.request)({
    url: '/organization/organization/get_all_role_entities',
    method: 'get'
  });
}

// 获取组织架构列表
function getOrgTree(params) {
  return (0, _request.request)({
    url: '/organization/tree',
    method: 'get',
    params
  });
}

// 获取组织树列表
function getOrgTreeOptions(params) {
  return (0, _request.request)({
    url: '/organization/tree/children_tree',
    method: 'get',
    params
  });
}
// 获取部门成员列表
function getOrgUserList(params) {
  return (0, _request.request)({
    url: '/organization/organization/userlist',
    method: 'get',
    params
  });
}

// 添加子部门
function createDepartment(name, pid) {
  return (0, _request.webService)({
    url: '/organization/tree/create',
    method: 'post',
    dataType: 'json',
    data: {
      name,
      pid
    }
  });
}

// 修改部门名称
function editDepartment(name, id) {
  return (0, _request.request)({
    url: '/organization/tree/update',
    method: 'post',
    data: {
      name,
      id
    }
  });
}

// 删除部门
function deleteDepartment(id) {
  return (0, _request.request)({
    url: '/organization/tree/delete',
    method: 'post',
    data: {
      id
    }
  });
}

// 将成员移出部门
function removeUserFromDepartment(tree_id, user_ids) {
  return (0, _request.request)({
    url: '/organization/tree/remove_user',
    method: 'post',
    data: {
      tree_id,
      user_ids
    }
  });
}

// 角色树
function getRoleList() {
  return (0, _request.request)({
    url: '/organization/role/list',
    method: 'get'
  });
}

// 获取角色下成员列表
function getRoleUserList(params) {
  return (0, _request.request)({
    url: '/organization/role/user_list',
    method: 'get',
    params
  });
}

// 创建角色
function addRole(pid, name) {
  return (0, _request.request)({
    url: '/organization/role/add',
    method: 'post',
    data: {
      pid,
      name
    }
  });
}

// 修改角色名
function updateRole(role_id, name) {
  return (0, _request.request)({
    url: '/organization/role/update',
    method: 'post',
    data: {
      role_id,
      name
    }
  });
}

// 删除角色
function deleteRole(role_id) {
  return (0, _request.request)({
    url: '/organization/role/delete',
    method: 'post',
    data: {
      role_id
    }
  });
}

// 获取角色权限列表
function getRolePermissionList() {
  return (0, _request.request)({
    url: '/organization/role/permission/list',
    method: 'get'
  });
}

// 获取角色当前的权限
function getRolePermission(role_id) {
  return (0, _request.request)({
    url: '/organization/role/detail',
    method: 'get',
    params: {
      role_id
    }
  });
}

// 设置角色权限
function setRolePermission(role_id, permission) {
  return (0, _request.request)({
    url: '/organization/role/set',
    method: 'post',
    data: {
      role_id,
      permission
    }
  });
}

// 向部门添加成员
function departmentAddUser(data) {
  return (0, _request.request)({
    url: '/organization/tree/add_user',
    method: 'post',
    data
  });
}

// 设置用户角色
function setUserRole(role_ids, user_ids) {
  return (0, _request.request)({
    url: '/organization/role/add_user',
    method: 'post',
    data: {
      role_ids,
      user_ids
    }
  });
}

// 获取某一部门下的用户列表
function getDepartmentUsers(params) {
  return (0, _request.request)({
    url: '/organization/tree/user_list',
    method: 'get',
    params
  });
}

// 删除某一角色下的用户
function removeRoleUser(role_id, user_ids) {
  return (0, _request.request)({
    url: '/organization/role/remove_user',
    method: 'post',
    data: {
      role_id,
      user_ids
    }
  });
}

// 获取部门成员的详情
function getMemberInfo(user_id) {
  return (0, _request.webService)({
    url: '/organization/user/detail',
    method: 'get',
    params: {
      user_id
    }
  });
}

// 通过部门获取角色
function getRoleFromDepartment(ids) {
  return (0, _request.request)({
    url: '/organization/role/from_tree',
    method: 'post',
    data: {
      ids
    }
  });
}

// 保存成员信息
function saveUserInfo(data) {
  return (0, _request.request)({
    url: '/organization/user/set_detail',
    method: 'post',
    data
  });
}

// 删除用户/设置离职
function deleteUser(user_id, organization_id) {
  return (0, _request.request)({
    url: '/organization/user/delete',
    method: 'post',
    data: {
      user_id,
      organization_id
    }
  });
}

// 部门下未转移数据判断
function getDepartmentTransferData(params) {
  return (0, _request.webService)({
    url: '/organization/tree/trans_data/check',
    method: 'get',
    params
  });
}

// 创建部门数据转移任务
function transferDepartmentData(data) {
  return (0, _request.webService)({
    url: '/organization/tree/trans_data/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 未关单商机和未转移客户判断
function getUserCanTransferData(params) {
  return (0, _request.webService)({
    url: '/organization/user/trans_data/check',
    method: 'get',
    params
  });
}

// 未关单商机/未转移客户数据转移
function transferData(data) {
  return (0, _request.webService)({
    url: '/organization/user/trans_data/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取离职成员表
function getResignedMemberList(params) {
  return (0, _request.request)({
    url: '/organization/organization/userlist/resigned',
    method: 'get',
    params
  });
}

// 获取部门列表
function getTeamList() {
  return (0, _request.request)({
    url: '/organization/organization/my/teamlist',
    method: 'get'
  });
}

/**
 * 批量导入通讯录
 */
// 文件上传
function uploadMembers(data) {
  return (0, _request.request)({
    url: '/organization/user/upload',
    method: 'post',
    data
  });
}
// 获取文件上传后的数据
function getMembersFileInfo(params) {
  return (0, _request.request)({
    url: '/organization/user/upload',
    method: 'get',
    params
  });
}

// 部门模糊查询
function getMembersFilter(params) {
  return (0, _request.request)({
    url: '/organization/organization/search_user',
    method: 'get',
    params
  });
}

// 获取部门外的角色名单
function getMembersList(params) {
  return (0, _request.request)({
    url: '/organization/tree/get_user_in_other_department',
    method: 'get',
    params
  });
}

// 获取角色列表
function getManageRoleList() {
  return (0, _request.request)({
    url: '/organization/role/func/list',
    method: 'get'
  });
}

// 获取权限信息
function getManageRolePermissionList(role_id) {
  return (0, _request.request)({
    url: '/organization/role/manage/get_role_detail',
    method: 'get',
    params: {
      role_id
    }
  });
}

// 修改角色权限
function changeRolePermission(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/set_role',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 部门获取公司角色列表
function getDeptRoleList(params) {
  return (0, _request.request)({
    url: '/organization/role/manage/tree/get_org_role',
    method: 'get',
    params
  });
}

// 部门添加角色
function handleAddRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/tree/allot_role',
    method: 'post',
    data
  });
}
// 角色管理新建角色
function handleManageAddRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/create_role',
    method: 'post',
    data
  });
}
// 重命名角色
function handleManageRenameRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/rename_role',
    method: 'post',
    data
  });
}
// 角色树删除角色
function handleDeteRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/tree/remove_role',
    method: 'post',
    data
  });
}
// 公司删除角色
function ManageDeteRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/delete_role',
    method: 'post',
    data
  });
}
// 从其他部门移入角色
function moveUserFromOtherDepartment(data) {
  return (0, _request.request)({
    url: '/organization/tree/move_from',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 后台操作日志
function getOperateLog(params) {
  return (0, _request.request)({
    url: '/profile/operate_log',
    method: 'get',
    params
  });
}
// 登录日志
function getLoginLog(params) {
  return (0, _request.webService)({
    url: '/organization/user/use_record',
    method: 'get',
    params
  });
}

// 启用/禁用用户账号
function enableAndDisableUser(data) {
  return (0, _request.request)({
    url: '/organization/user/change',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 申请人数扩容
function membersScale(data) {
  return (0, _request.request)({
    url: '/organization/organization/dilatation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 计费
function usage() {
  return (0, _request.webService)({
    url: '/organization/organization/usage',
    methods: 'GET'
  });
}

// 获取团队手机号隐私保护信息
function getDesensitizationInfo() {
  return (0, _request.request)({
    url: '/organization/organization/get_desensitization_info',
    methods: 'GET'
  });
}

// 为角色设置手机号隐私保护
function setRoleDesensitization(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/set_desensitization',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 团队启用手机号隐私保护开关
function setRoleChangeDesensitization(data) {
  return (0, _request.request)({
    url: '/organization/organization/manage/change_desensitization',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取坐席管理公司列表
function getSeatTabsList() {
  return (0, _request.request)({
    url: '/outbound/app/overview',
    method: 'get'
  });
}

// 获取坐席列表
function getSeatList(params) {
  return (0, _request.request)({
    url: '/outbound/outbound/list',
    method: 'get',
    params
  });
}

// 坐席 绑定创建 OR 修改 OR 解除
function seatOperation(data) {
  return (0, _request.request)({
    url: '/outbound/outbound/binding_edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 坐席同步
function refreshSeat(data) {
  return (0, _request.request)({
    url: '/outbound/outbound/sync',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 批量设置用户部门与角色
function BatchModifyUsers(data) {
  return (0, _request.request)({
    url: '/organization/tree/batch_update_users_tree_role',
    method: 'post',
    data
  });
}

// 企业信息管理
// 获取企业信息
function getOrgInfo(params) {
  return (0, _request.webService)({
    url: '/organization/info/get',
    method: 'get',
    params
  });
}

// 小站定制logo和title接口
function getXiaozhanInfo(params) {
  return (0, _request.request)({
    url: '/organization/organization/info/before_login',
    method: 'get',
    params
  });
}

// 修改团队信息
function updateOrgInfo(data) {
  return (0, _request.webService)({
    url: '/organization/info/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 上传团队logo
function uploadLogo(data) {
  return (0, _request.request)({
    url: '/organization/organization/logo/upload',
    method: 'post',
    data
  });
}

// 获取默认logo
function getDefaultLogo(params) {
  return (0, _request.request)({
    url: '/organization/organization/logo/default',
    method: 'get',
    params
  });
}

// 设置部门负责人
function setCharge(data) {
  return (0, _request.request)({
    url: '/organization/tree/set_charge',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 搜索成员
function getUserList(params) {
  return (0, _request.webService)({
    url: '/organization/user/search_list',
    method: 'get',
    params
  });
}

// 获取部门开启/关闭Ai对练会话同步功能
function getAiConversationSync() {
  return (0, _request.webService)({
    url: '/organization/tree/practice_sync/get',
    method: 'get'
  });
}

// 设置部门开启/关闭Ai对练会话同步功能
function setAiConversationSync(data) {
  return (0, _request.webService)({
    url: '/organization/tree/practice_sync/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 重置成员密码【目前只有希音有这个功能】
function resetUserPassword(data) {
  return (0, _request.webService)({
    url: '/organization/user/reset_password',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取登录验证码（shein）
function getLoginCaptcha(params) {
  return (0, _request.webService)({
    url: '/organization/user/view_code',
    method: 'get',
    params
  });
}

// 移动部门
function moveDepartment(data) {
  return (0, _request.webService)({
    url: '/organization/tree/move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 保存会话上传额度设置
function setUploadLimit(data) {
  return (0, _request.webService)({
    url: '/organization/upload_duration/save',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取会话上传额度设置
function getUploadLimitInfo() {
  return (0, _request.webService)({
    url: '/organization/upload_duration/info',
    method: 'get'
  });
}