"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GradeRange = _interopRequireDefault(require("../../components/GradeRange.vue"));
var _ConversationFilterItem = _interopRequireDefault(require("../../components/ConversationFilterItem"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ComplianceScoreFilters',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    complianceScoreFiltersList: {
      type: Array,
      default: () => []
    },
    hideSavedFilters: {
      type: Boolean,
      default: false
    }
  },
  emits: ['scoreChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handlerScoreChange = function (index) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        type: '',
        score_range: []
      };
      emit('scoreChange', {
        index,
        data,
        type: 'compliance_score_filters'
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      handlerScoreChange,
      GradeRange: _GradeRange.default,
      ConversationFilterItem: _ConversationFilterItem.default
    };
  }
};
exports.default = _default;