"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _businessIntelligence = require("@/api/businessIntelligence");
var _default = {
  name: 'ShareConfig',
  props: {
    title: {
      type: String,
      default: '分享'
    },
    description: {
      type: String,
      default: '分享'
    },
    handleFn: {
      type: Function,
      default: _businessIntelligence.universalShareKanban
    },
    cancelHandleFn: {
      type: Function,
      default: _businessIntelligence.universalCancelShareKanban
    },
    getList: {
      type: Function,
      default: _businessIntelligence.universalGetshareKanbanUserList
    },
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    },
    clearable: {
      type: Boolean,
      default: false
    },
    successMsg: {
      type: String,
      default: ''
    },
    isShowSyncData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {
        tree_ids: [],
        user_ids: []
      },
      handleLoading: false,
      getListLoading: false,
      list: {
        user_list: [],
        tree_list: []
      },
      cancelAllLoading: false,
      cancelAllConfig: {
        loading: false,
        type: 1 // type：0，取消指派，1，全部取消
      },

      isSyncData: true
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    hasData() {
      return !this.getListLoading && (this.list.user_list.length || this.list.tree_list.length);
    },
    shareBtnDisabled() {
      const noSelectUser = !this.config.user_ids.length && !this.config.tree_ids.length;
      if (this.isShowSyncData) {
        return !this.hasData && noSelectUser;
      }
      return noSelectUser;
    }
  },
  watch: {
    visible(val) {
      val && this.getUserList();
    }
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    searchUserFuntion: _organization.getUserList,
    async getUserList() {
      this.getListLoading = true;
      const res = await this.getList({
        ...this.params,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.getListLoading = false;
      });
      if (res.code === 20000) {
        this.list = res.results;
        if (this.isShowSyncData) {
          this.isSyncData = res.results.sync_data_auth;
        }
      }
    },
    assigneeChange(val) {
      this.config = val;
    },
    handleClose() {
      this.$emit('handleClose', [...this.list.user_list, ...this.list.tree_list]);
      this.initMemberTreeSelect();
      this.$emit('update:visible', false);
    },
    initMemberTreeSelect() {
      this.list = {
        user_list: [],
        tree_list: []
      };
      this.$refs['member-tree-select'].clear();
      this.config = this.$options.data().config;
    },
    async submit() {
      this.handleLoading = true;
      if (this.isShowSyncData) {
        var _this$config$user_ids, _this$config$tree_ids;
        this.config.sync_data_auth = this.isSyncData;
        if (!((_this$config$user_ids = this.config.user_ids) !== null && _this$config$user_ids !== void 0 && _this$config$user_ids.length) && !((_this$config$tree_ids = this.config.tree_ids) !== null && _this$config$tree_ids !== void 0 && _this$config$tree_ids.length)) {
          const userIds = this.list.user_list.map(item => item.user_id);
          const treeIds = this.list.tree_list.map(item => item.tree_id);
          this.config = {
            ...this.config,
            user_ids: userIds,
            tree_ids: treeIds
          };
        }
      }
      const res = await this.handleFn({
        ...this.params,
        ...this.config,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.handleLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success(this.successMsg ? this.successMsg : `${this.title}成功`);
        this.initMemberTreeSelect();
        this.getUserList();
        this.$emit('refresh');
      }
    },
    cancel(val, type) {
      let extraTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.$confirm(`是否取消${extraTitle}${this.title}？`, `取消${extraTitle}${this.title}`, {
        confirmButtonText: `取消${this.title}`,
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        this.$set(val, 'loading', true);
        const data = {
          ...this.params,
          workspace_id: this.workspaceInfo.id
        };
        if (type === 'all') {
          data.type = 1;
        } else {
          type === 'user' ? data.user_id = val.user_id : data.tree_id = val.tree_id;
        }
        const res = await this.cancelHandleFn(data).finally(() => {
          this.$set(val, 'loading', false);
        });
        if (res.code === 20000) {
          this.$message.success(`取消${this.title}成功`);
          this.getUserList();
          this.$emit('refresh');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    }
  }
};
exports.default = _default;