"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "root"
  }, [_c("div", {
    staticClass: "kanban-root"
  }, [_c("mg-draggable", {
    ref: "kanbanWrap",
    staticClass: "kanban-wrap",
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.localList,
      callback: function ($$v) {
        _vm.localList = $$v;
      },
      expression: "localList"
    }
  }, [_vm._l(_vm.localList, function (item, index) {
    return [_c("div", {
      key: item.id,
      staticClass: "drag-container"
    }, [!item.need_fixed && !item.isNew && !item.isEditing ? _c("div", {
      class: ["kanban-item move", {
        active: _vm.activeId === item.id
      }],
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c("div", {
      staticClass: "left-info"
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: `${item.name}（${item.total}）`,
        placement: "top",
        disabled: item.name.length < 10
      }
    }, [_c("div", {
      staticClass: "kanban-title ellipsis"
    }, [_vm._v(" " + _vm._s(`${item.name}（${item.total || 0}）`) + " ")])]), _c("div", {
      staticClass: "kanban-amount ellipsis"
    }, [_vm._v(" ￥" + _vm._s(_vm.parseFormatNum(item.amount, 2)) + " ")])], 1), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "公开视图，空间所有人可查看、编辑和复制",
        placement: "top"
      }
    }, [_c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.type === 2,
        expression: "item.type === 2"
      }],
      staticClass: "team-view-icon iconfont icon-team-fill"
    })]), _c("span", {
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_vm.activeId === item.id ? _c("el-dropdown", {
      staticClass: "kanban-more",
      attrs: {
        trigger: "click",
        placement: "bottom-start"
      },
      on: {
        command: function ($event) {
          return _vm.handleCommand($event, item);
        }
      }
    }, [_c("el-button", {
      staticClass: "kanban-more-btn",
      attrs: {
        icon: "iconfont icon-more-v",
        size: "mini"
      }
    }), _c("el-dropdown-menu", {
      staticClass: "trade-view-opration",
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, [_c("el-dropdown-item", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.is_default,
        expression: "!item.is_default"
      }],
      attrs: {
        command: "edit"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-edit-outline"
    }), _vm._v("重命名")]), _c("el-dropdown-item", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.is_default,
        expression: "!item.is_default"
      }]
    }, [_c("el-popover", {
      ref: "changeViewTypePop",
      refInFor: true,
      attrs: {
        placement: "right-start",
        width: "300",
        trigger: "hover",
        "visible-arrow": false,
        "popper-class": "operate-view-type-pop"
      }
    }, [_c("div", {
      staticClass: "change-view-type",
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_c("i", {
      staticClass: "iconfont icon-setting"
    }), _c("span", [_vm._v("当前视图类型")]), _c("i", {
      staticClass: "iconfont icon-arrow-right"
    })]), _c("select-view-type", {
      on: {
        change: function ($event) {
          return _vm.changeViewType($event, item);
        }
      },
      model: {
        value: item.type,
        callback: function ($$v) {
          _vm.$set(item, "type", $$v);
        },
        expression: "item.type"
      }
    })], 1)], 1), _c("el-dropdown-item", {
      attrs: {
        command: "copy"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-copy-document"
    }), _vm._v("复制视图")]), _c("el-dropdown-item", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.is_default,
        expression: "!item.is_default"
      }],
      attrs: {
        divided: "",
        command: "delete"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-delete"
    }), _vm._v("删除视图")])], 1)], 1) : _vm._e()], 1), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSpilit(_vm.activeId, index),
        expression: "isShowSpilit(activeId, index)"
      }],
      staticClass: "spilit-line"
    })], 1) : _vm._e(), item.isNew || item.isEditing ? _c("el-input", {
      key: item.id,
      ref: "editViewInput",
      refInFor: true,
      class: ["kanban-item new-view", {
        active: _vm.activeId === item.id
      }, {
        error: _vm.errorTip
      }],
      attrs: {
        size: "small",
        maxlength: 20,
        placeholder: "请输入视图名称"
      },
      on: {
        blur: function ($event) {
          return _vm.handleSubmit(item);
        }
      },
      nativeOn: {
        keyup: function ($event) {
          if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.handleSubmit(item);
        }
      },
      model: {
        value: _vm.inputViewName,
        callback: function ($$v) {
          _vm.inputViewName = $$v;
        },
        expression: "inputViewName"
      }
    }) : _vm._e()], 1)];
  })], 2), _vm.isLeftArrowShow ? _c("div", {
    staticClass: "arrow-button arrow-left"
  }, [_c("div", {
    staticClass: "arrow-container"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(false);
      }
    }
  })]), _c("div", {
    staticClass: "arrow-border"
  })]) : _vm._e(), _vm.isRightArrowShow ? _c("div", {
    staticClass: "arrow-button arrow-right"
  }, [_c("div", {
    staticClass: "arrow-border"
  }), _c("div", {
    staticClass: "arrow-container"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-right",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(true);
      }
    }
  })])]) : _vm._e()], 1), _vm.isCreateButtonVisible ? _c("div", {
    staticClass: "create"
  }, [_c("el-popover", {
    ref: "selectViewTypePop",
    attrs: {
      placement: "bottom-start",
      width: "300",
      trigger: "click",
      "visible-arrow": false,
      offset: -20,
      "popper-class": "select-view-type-pop"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }), _c("select-view-type", {
    on: {
      change: _vm.handleCreateKanbanOpen
    },
    model: {
      value: _vm.newViewType,
      callback: function ($$v) {
        _vm.newViewType = $$v;
      },
      expression: "newViewType"
    }
  })], 1)], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;