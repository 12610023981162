"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _CustomAssignTable = _interopRequireDefault(require("./components/CustomAssignTable"));
var _UploadMemberFile = _interopRequireDefault(require("./components/UploadMemberFile"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _compliance = require("@/api/compliance");
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router.js");
var _vue = require("vue");
var _elementUi = require("element-ui");
var _useVuex = require("@/hooks/use-vuex");
var _debounce = _interopRequireDefault(require("lodash/debounce"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const router = (0, _useRouter.useRouter)();
    const goBack = () => {
      router.push({
        path: _routeMap.ROUTES.complianceAssign
      });
    };
    const formData = (0, _vue.ref)({
      compliance_user_ids: [],
      inspected_user_ids: [],
      allocate_type: 1
    });
    const selectType = (0, _vue.ref)('select');

    // 根据部门或用户获取用户信息
    // 选取部门时，需要请求接口获取部门内的成员信息
    const getUserInfoAsync = ids => {
      return new Promise((resolve, reject) => {
        (0, _compliance.getUserInfo)({
          user_ids: ids,
          workspace_id: workspaceId.value
        }).then(res => {
          if (res.code === 20000) {
            console.log('请求接口');
            resolve(res.results);
          } else {
            resolve([]);
          }
        }).catch(() => {
          reject([]);
        });
      });
    };
    const getMemberInfos = (ids, infos) => {
      if (ids.tree_ids.length) {
        // 请求接口
        return getUserInfoAsync(ids);
      } else {
        const localInfos = (0, _cloneDeep.default)(infos);
        localInfos.forEach(item => item.user_id = item.id.split('_').pop() - 0);
        return localInfos;
      }
    };

    // 质检员 变化
    const complianceUserList = (0, _vue.ref)([]);
    const complianceUserChange = (0, _debounce.default)(async (ids, infos) => {
      // 获取质检员的信息
      const members = await getMemberInfos(ids, infos);
      if (members.length > 100) {
        _elementUi.Message.warning('质检员最多可选100人');
      }
      complianceUserList.value = members.slice(0, 100);
      formData.value.compliance_user_ids = complianceUserList.value.map(_ref => {
        let {
          user_id
        } = _ref;
        return user_id;
      });
      complianceUserList.value.forEach(item => item.ratio = memberRatioMap.get(item.user_id));
      form.value.validateField('compliance_user_ids');
    }, 500);
    // 自定义分配 修改质检成员比例
    const memberRatioMap = new Map();
    const totalRatio = (0, _vue.ref)(0);
    const changeRatio = (_ref2, allRatio) => {
      let {
        user_id,
        ratio
      } = _ref2;
      memberRatioMap.set(user_id, ratio);
      totalRatio.value = allRatio;
      complianceUserList.value.find(item => {
        if (item.user_id === user_id) {
          item.ratio = ratio;
        }
      });
    };

    // 待质检成员 手动选择(select)和批量导入(import)
    const inspectedUserObj = (0, _vue.reactive)({
      select: [],
      import: []
    });
    // 待质检成员变化
    const inspectedUserChange = (0, _debounce.default)(async (ids, infos) => {
      var _form$value;
      inspectedUserObj[selectType.value] = await getMemberInfos(ids, infos);
      formData.value.inspected_user_ids = inspectedUserObj[selectType.value].map(_ref3 => {
        let {
          user_id
        } = _ref3;
        return user_id;
      });
      (_form$value = form.value) === null || _form$value === void 0 ? void 0 : _form$value.validateField('inspected_user_ids');
    }, 500);

    // 平均分配比例 (待质检成员/质检员)
    const averageRatio = (0, _vue.computed)(() => {
      var _inspectedUserObj$sel;
      return Math.floor(((_inspectedUserObj$sel = inspectedUserObj[selectType.value]) === null || _inspectedUserObj$sel === void 0 ? void 0 : _inspectedUserObj$sel.length) / complianceUserList.value.length) || 1;
    });

    // 质检员人数大于待质检人数时，展示没有分配到人的质检员人数
    const remainder = (0, _vue.computed)(() => {
      var _inspectedUserObj$sel2;
      return complianceUserList.value.length - ((_inspectedUserObj$sel2 = inspectedUserObj[selectType.value]) === null || _inspectedUserObj$sel2 === void 0 ? void 0 : _inspectedUserObj$sel2.length) * averageRatio.value;
    });

    // 待质检成员和质检员全部选择完 才能选分配策略
    const disabled = (0, _vue.computed)(() => {
      var _inspectedUserObj$sel3;
      return !((_inspectedUserObj$sel3 = inspectedUserObj[selectType.value]) !== null && _inspectedUserObj$sel3 !== void 0 && _inspectedUserObj$sel3.length) || !complianceUserList.value.length;
    });

    // 自定义分配比例，最少为 1 / 待质检人数
    const lowestRatio = (0, _vue.computed)(() => {
      var _inspectedUserObj$sel4;
      return Math.ceil(100 / ((_inspectedUserObj$sel4 = inspectedUserObj[selectType.value]) === null || _inspectedUserObj$sel4 === void 0 ? void 0 : _inspectedUserObj$sel4.length));
    });
    const rules = (0, _vue.computed)(() => {
      const compliance_user_ids = [{
        type: 'array',
        required: true,
        message: '请选择质检员',
        trigger: 'change'
      }];
      const inspected_user_ids = [{
        type: 'array',
        required: true,
        message: selectType.value === 'select' ? '请选择待质检成员' : '请上传文件',
        trigger: 'change'
      }];
      return {
        compliance_user_ids,
        inspected_user_ids
      };
    });
    const btnLoading = (0, _vue.ref)(false);
    const form = (0, _vue.ref)(null);
    const confirm = () => {
      console.log(formData.value, '===formData');
      form.value.validate(async valid => {
        if (valid) {
          var config = [];
          if (formData.value.allocate_type === 2) {
            // 自定义分配校验
            if (totalRatio.value !== 100) {
              _elementUi.Message.warning('合计值需为100%，请调整质检成员分配比例');
              return;
            }
            if (complianceUserList.value.some(item => !item.ratio)) {
              _elementUi.Message.warning('自定义分配比例不能为空');
              return;
            }
            config = complianceUserList.value.map(_ref4 => {
              let {
                user_id,
                ratio
              } = _ref4;
              return {
                user_id,
                percent: ratio / 100
              };
            });
          }
          btnLoading.value = true;
          const res = await (0, _compliance.createComplianceAssign)({
            workspace_id: workspaceId.value,
            ...formData.value,
            config
          }).finally(() => {
            btnLoading.value = false;
          });
          if (res.code === 20000) {
            _elementUi.Message.success('创建成功');
            goBack();
          }
        }
      });
    };
    const changeSelectType = async val => {
      var _form$value2;
      formData.value.inspected_user_ids = inspectedUserObj[selectType.value].map(_ref5 => {
        let {
          id
        } = _ref5;
        return id;
      });
      await (0, _vue.nextTick)();
      (_form$value2 = form.value) === null || _form$value2 === void 0 ? void 0 : _form$value2.clearValidate();
    };
    const changeUploadFile = async success_count => {
      var userInfo = [];
      if (success_count) {
        userInfo = await getUserInfoAsync({
          tree_ids: [],
          user_ids: []
        });
      }
      console.log(userInfo, 'userInfo===');
      inspectedUserObj['import'] = userInfo;
      formData.value.inspected_user_ids = userInfo.map(_ref6 => {
        let {
          user_id
        } = _ref6;
        return user_id;
      });
      console.log(formData.value.inspected_user_ids, 'formData.value.inspected_user_ids');
      form.value.validateField('inspected_user_ids');
    };
    return {
      __sfc: true,
      store,
      workspaceId,
      router,
      goBack,
      formData,
      selectType,
      getUserInfoAsync,
      getMemberInfos,
      complianceUserList,
      complianceUserChange,
      memberRatioMap,
      totalRatio,
      changeRatio,
      inspectedUserObj,
      inspectedUserChange,
      averageRatio,
      remainder,
      disabled,
      lowestRatio,
      rules,
      btnLoading,
      form,
      confirm,
      changeSelectType,
      changeUploadFile,
      DetailHeader: _DetailHeader.default,
      FormItemCard: _FormItemCard.default,
      CustomAssignTable: _CustomAssignTable.default,
      UploadMemberFile: _UploadMemberFile.default,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList
    };
  }
};
exports.default = _default;