"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportSopAnalysisConversationOverviewTableData = exportSopAnalysisConversationOverviewTableData;
exports.exportSopAnalysisConversationTableData = exportSopAnalysisConversationTableData;
exports.exportSopAnalysisTradeOverviewTableData = exportSopAnalysisTradeOverviewTableData;
exports.exportSopAnalysisTradeTableData = exportSopAnalysisTradeTableData;
exports.getSopAnalysisConversationData = getSopAnalysisConversationData;
exports.getSopAnalysisConversationLineChartData = getSopAnalysisConversationLineChartData;
exports.getSopAnalysisConversationTableData = getSopAnalysisConversationTableData;
exports.getSopAnalysisFiltersConfig = getSopAnalysisFiltersConfig;
exports.getSopAnalysisTradeData = getSopAnalysisTradeData;
exports.getSopAnalysisTradeLineChartData = getSopAnalysisTradeLineChartData;
exports.getSopAnalysisTradeTableData = getSopAnalysisTradeTableData;
var _request = require("@/utils/request");
// 获取会话评分概览卡片筛选器配置
function getSopAnalysisFiltersConfig(params) {
  return (0, _request.webService)({
    url: '/sop/score/rule/visible_list',
    method: 'get',
    params
  });
}

// 获取会话评分概览卡片数据
function getSopAnalysisConversationData(data) {
  return (0, _request.webService)({
    url: '/sop/conv/score/analysis/view',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取交易评分概览卡片数据
function getSopAnalysisTradeData(data) {
  return (0, _request.webService)({
    url: '/sop/trade/score/analysis/view',
    method: 'post',
    dataType: 'json',
    data
  });
}

// getBarChartData, getLineChartData
// 获取会话评分分析柱状图数据: getSopAnalysisConversationData 接口复用

// 获取会话评分分析折线图数据
function getSopAnalysisConversationLineChartData(data) {
  return (0, _request.webService)({
    url: '/sop/conv/score/analysis/trend',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取交易评分分析折线图数据
function getSopAnalysisTradeLineChartData(data) {
  return (0, _request.webService)({
    url: '/sop/trade/score/analysis/trend',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取会话表格数据
function getSopAnalysisConversationTableData(data) {
  return (0, _request.webService)({
    url: '/sop/conv/score/analysis/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取交易表格数据
function getSopAnalysisTradeTableData(data) {
  return (0, _request.webService)({
    url: '/sop/trade/score/analysis/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出
// 会话评分分析导出
function exportSopAnalysisConversationTableData(data) {
  return (0, _request.webService)({
    url: '/sop/conv/score/analysics/export',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 交易评分分析导出
function exportSopAnalysisTradeTableData(data) {
  return (0, _request.webService)({
    url: '/sop/trade/score/analysics/export',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 会话评分分析概览导出
function exportSopAnalysisConversationOverviewTableData(data) {
  return (0, _request.webService)({
    url: '/sop/conv/score/analysics/view/export',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 交易评分分析概览导出
function exportSopAnalysisTradeOverviewTableData(data) {
  return (0, _request.webService)({
    url: '/sop/trade/score/analysics/view/export',
    method: 'post',
    dataType: 'json',
    data
  });
}