"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "key-event-management__header"
  }, [_c("div", {
    staticClass: "key-event-management__left"
  }, [_c("h1", {
    staticClass: "key-event-management__title"
  }, [_vm._v("关键事件")]), _c("el-tooltip", {
    staticClass: "icon-info"
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" 关键事件，能够将会话中的一些重要信息自动提取为事件标签， "), _c("a", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticStyle: {
      color: "#81d6eb"
    },
    attrs: {
      href: "https://www.megaview.com/help-center/doc?docx=XfJ2dqdaXoHZduxPVuTcKPHXnzf&edition_id=eIyYpq&anchor=doxcn9X7OVhDVNUgFdFmYl3Htjc",
      target: "_blank"
    }
  }, [_vm._v("了解更多")])]), _c("i", {
    staticClass: "iconfont icon-info-2"
  })]), _c("SpaceButton"), _vm.taskProcessList.length ? _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }) : _vm._e(), _vm.taskProcessList.length ? _c("div", {
    staticClass: "process-contain"
  }, [_c("div", {
    attrs: {
      id: "process"
    },
    on: {
      mouseover: function ($event) {
        return _vm.rollStop();
      },
      mouseout: function ($event) {
        return _vm.rollStart(30);
      }
    }
  }, _vm._l(_vm.isScroll ? 2 : 1, function (index) {
    return _c("div", {
      key: index,
      attrs: {
        id: "task" + index
      }
    }, [_vm._l(_vm.taskProcessList, function (item, _index) {
      return [!item.is_delete ? _c("div", {
        key: item.workspace_id + _index,
        staticClass: "task-process-item"
      }, [_c("div", {
        staticClass: "task-process-item-header"
      }, [_c("div", {
        staticClass: "content"
      }, [_c("i", {
        staticClass: "iconfont icon-workspace-3-fill",
        style: {
          color: item.workspace_icon_color,
          "margin-right": "4px"
        }
      }), _c("span", {
        staticClass: "workspace-name"
      }, [_vm._v(_vm._s(item.workspace_name))]), _vm.isInProcess(item.task_status) ? _c("i", {
        directives: [{
          name: "loading",
          rawName: "v-loading",
          value: _vm.isInProcess(item.task_status),
          expression: "isInProcess(item.task_status)"
        }]
      }) : _vm._e(), _c("span", {
        staticClass: "status",
        class: _vm.isInProcess(item.task_status) ? "left-space" : "complete"
      }, [_vm._v(" " + _vm._s(_vm.isInProcess(item.task_status) ? `${item.task_percent}%` : "已完成") + " ")])]), !_vm.isInProcess(item.task_status) && !item.is_delete ? _c("i", {
        staticClass: "iconfont icon-close",
        on: {
          click: function ($event) {
            return _vm.deleteProgressBar(item);
          }
        }
      }) : _vm._e()]), _c("div", {
        staticClass: "hover-content"
      }, [_c("p", {
        staticClass: "all-event"
      }, _vm._l(item.events, function (event) {
        return _c("span", {
          key: event.event_id,
          staticClass: "event-item"
        }, [_vm._v(_vm._s(event.event_name))]);
      }), 0), _c("p", {
        staticClass: "result"
      }, [_vm._v(" 已完成 "), _c("span", {
        staticClass: "number"
      }, [_vm._v(_vm._s(`${item.count}/${item.total}`))]), _vm._v(" 条会话 ")]), item.total !== item.actual_count ? _c("p", {
        staticClass: "result"
      }, [_vm._v(" 处理前被转移或删除的会话："), _c("span", {
        staticClass: "number"
      }, [_vm._v(_vm._s(item.total - item.actual_count))]), _vm._v("条 ")]) : _vm._e()])]) : _vm._e()];
    })], 2);
  }), 0)]) : _vm._e()], 1), _c("div", [_c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-setting-config",
      size: "medium"
    },
    on: {
      click: _vm.goKeyEventMutualExclusion
    }
  }, [_vm._v(" 互斥策略 ")])], 1), _c("div", {
    staticClass: "use-guide"
  }, [_c("MgvButton", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    attrs: {
      type: "text",
      color: "primary",
      icon: "icon-question3",
      size: "tiny"
    },
    on: {
      click: _vm.goHelpCenter
    }
  }, [_vm._v(" 使用指南 ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;