"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _default = {
  __name: 'AnnotateOperate',
  props: {
    dataSetDataList: {
      type: Array,
      default: () => []
    },
    activeDataSetData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['changeDataSetDataOperate'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const handleChangeDataSetDataOperate = operate => {
      emit('changeDataSetDataOperate', {
        operate,
        activeDataSetDataIndex: activeDataSetDataIndex.value
      });
    };
    const annotateAutoNext = (0, _vue.ref)(true);
    const initAnnotateAutoNext = () => {
      const res = localStorage.getItem(`${store.getters.userId}-annotate-auto-next`);
      if (res === 'false') {
        annotateAutoNext.value = false;
      } else {
        annotateAutoNext.value = true;
        localStorage.setItem(`${store.getters.userId}-annotate-auto-next`, annotateAutoNext.value);
      }
    };
    const handleAutoToNext = () => {
      localStorage.setItem(`${store.getters.userId}-annotate-auto-next`, annotateAutoNext.value);
    };
    const activeDataSetDataIndex = (0, _vue.computed)(() => {
      var _props$dataSetDataLis;
      return (_props$dataSetDataLis = props.dataSetDataList) === null || _props$dataSetDataLis === void 0 ? void 0 : _props$dataSetDataLis.findIndex(item => {
        var _props$activeDataSetD;
        return item.id === ((_props$activeDataSetD = props.activeDataSetData) === null || _props$activeDataSetD === void 0 ? void 0 : _props$activeDataSetD.id);
      });
    });
    (0, _vue.onMounted)(() => {
      initAnnotateAutoNext();
    });
    return {
      __sfc: true,
      props,
      store,
      emit,
      handleChangeDataSetDataOperate,
      annotateAutoNext,
      initAnnotateAutoNext,
      handleAutoToNext,
      activeDataSetDataIndex
    };
  }
};
exports.default = _default;