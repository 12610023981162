"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.knownFlag ? _c("div", [_c("div", {
    staticClass: "ponit-box"
  }, [_c("div", {
    staticClass: "text-box"
  }, [_c("i", {
    staticClass: "el-icon-s-opportunity"
  }), _c("span", [_vm._v("点击姓名查看「个人分析」")]), _c("el-button", {
    staticClass: "enter-button",
    on: {
      click: _vm.handleEnter
    }
  }, [_vm._v("知道了")])], 1)])]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;