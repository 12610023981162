"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generator-form-item"
  }, [_setup.fieldType === "input" ? [_c("el-input", {
    staticClass: "no-event",
    attrs: {
      readonly: "",
      value: "",
      placeholder: "请输入"
    }
  })] : _setup.fieldType === "textarea" ? [_c("el-input", {
    staticClass: "no-event",
    attrs: {
      readonly: "",
      type: "textarea",
      value: "",
      placeholder: "请输入"
    }
  })] : _setup.fieldType === "number" ? [_c("el-input", {
    staticClass: "no-event",
    attrs: {
      readonly: "",
      value: "",
      placeholder: "请输入"
    }
  })] : _setup.fieldType === "radio" ? [_c("el-radio-group", {
    class: {
      "no-event": _vm.isEditStatus
    },
    attrs: {
      value: _setup.props.optionIds[0]
    },
    on: {
      input: _setup.handleRadioChange
    }
  }, [_c(_setup.MgDraggable, {
    attrs: {
      value: _setup.props.options,
      disabled: _vm.isEditStatus
    },
    on: {
      input: _setup.onOptionDrag
    }
  }, _vm._l(_setup.props.options, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "option-item"
    }, [!_vm.isEditStatus ? _c("el-button", {
      staticClass: "move-button"
    }, [_c("i", {
      staticClass: "iconfont move icon-drag-dot"
    })]) : _vm._e(), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "设为默认选中项",
        enterable: false,
        disabled: _vm.optionIds.includes(item.id),
        placement: "top"
      }
    }, [_c("el-radio", {
      attrs: {
        label: item.id
      }
    })], 1), _c("el-input", {
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    }), !_vm.isEditStatus && _setup.props.options.length > 1 ? _c("el-button", {
      staticClass: "delete-button",
      on: {
        click: function ($event) {
          return _setup.deleteOption(item.id);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()], 1);
  }), 0)], 1)] : _setup.fieldType === "checkbox" ? [_c("el-checkbox-group", {
    class: {
      "no-event": _vm.isEditStatus
    },
    attrs: {
      value: _setup.props.optionIds
    },
    on: {
      input: _setup.handleCheckboxChange
    }
  }, [_c(_setup.MgDraggable, {
    attrs: {
      value: _setup.props.options,
      disabled: _vm.isEditStatus
    },
    on: {
      input: _setup.onOptionDrag
    }
  }, _vm._l(_setup.props.options, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "option-item"
    }, [!_vm.isEditStatus ? _c("el-button", {
      staticClass: "move-button"
    }, [_c("i", {
      staticClass: "iconfont move icon-drag-dot"
    })]) : _vm._e(), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "设为默认选中项",
        enterable: false,
        disabled: _vm.optionIds.includes(item.id),
        placement: "top"
      }
    }, [_c("el-checkbox", {
      attrs: {
        label: item.id
      }
    })], 1), _c("el-input", {
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    }), !_vm.isEditStatus && _setup.props.options.length > 1 ? _c("el-button", {
      staticClass: "delete-button",
      on: {
        click: function ($event) {
          return _setup.deleteOption(item.id);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()], 1);
  }), 0)], 1)] : _setup.fieldType === "date" ? [_c("el-date-picker", {
    staticClass: "no-event",
    attrs: {
      value: "",
      placeholder: "请选择",
      "value-format": "yyyy-MM-dd",
      readonly: "",
      format: "yyyy-MM-dd"
    }
  })] : _setup.fieldType === "datetime" ? [_c("el-date-picker", {
    staticClass: "no-event",
    attrs: {
      value: "",
      type: "datetime",
      placeholder: "请选择",
      readonly: "",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      format: "yyyy-MM-dd HH:mm:ss"
    }
  })] : _setup.fieldType === "cascader" ? [!_vm.isEditStatus ? _c(_setup.GeneratorCascader, {
    attrs: {
      options: _setup.props.options
    },
    on: {
      dragEnd: function ($event) {
        return _setup.emit("onOptionDrag", $event);
      }
    }
  }) : _c(_setup.WidgetCascader, {
    attrs: {
      options: _setup.props.options
    }
  })] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;