"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "message-center-button"
  }, [_c("el-badge", {
    staticClass: "message-btn",
    attrs: {
      value: _vm.messageUnreadCount,
      max: 99,
      hidden: !Boolean(_vm.messageUnreadCount)
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "消息",
      "popper-class": "sidebar-bottom-button-tooltip",
      placement: "bottom",
      "hide-after": 1000
    }
  }, [_c("el-button", {
    staticClass: "message-icon hover-show-bg",
    attrs: {
      icon: "el-icon-bell",
      circle: ""
    },
    on: {
      click: _vm.openMessageDrawer
    }
  })], 1)], 1), _c("el-drawer", {
    attrs: {
      title: _vm.$t("coaching.messageCenter"),
      visible: _vm.messageDrawer,
      direction: "rtl",
      "before-close": _vm.closeDrawer,
      "custom-class": "sidebar-message-center-drawer",
      size: "800px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.messageDrawer = $event;
      }
    }
  }, [_c("message-center", {
    key: _vm.messageKey
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;