"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _workspace = require("@/api/workspace");
const initialWorkspaceInfo = {};
const storeWorkspaceInfo = sessionStorage.getItem('workspaceInfo');
if (storeWorkspaceInfo) {
  Object.assign(initialWorkspaceInfo, JSON.parse(storeWorkspaceInfo));
}
const state = {
  workspaceInfo: initialWorkspaceInfo,
  isWorkspaceButtonDisabled: false,
  workspaceList: []
};
const mutations = {
  CHANGE_WORKSPACE: (state, val) => {
    state.workspaceInfo = {
      id: val.id,
      name: val.name,
      description: val.description,
      icon_color: val.icon_color || '#2B323B',
      type: val.type || 0
    };
  },
  CHANGE_WORKSPACE_BUTTON_DISABLE: (state, val) => {
    state.isWorkspaceButtonDisabled = val;
  }
};
const actions = {
  changeWorkspace(_ref, data) {
    let {
      commit
    } = _ref;
    commit('CHANGE_WORKSPACE', data);
  },
  getWorkspaceList(_ref2, spaceId) {
    let {
      state,
      dispatch
    } = _ref2;
    (0, _workspace.getSwitchWorkspaceList)().then(res => {
      if (res.code === 20000) {
        state.workspaceList = res.results.workspaces;
        dispatch('saveWorkspace', spaceId);
      }
    });
  },
  async saveWorkspace(_ref3, spaceId) {
    let {
      state,
      dispatch
    } = _ref3;
    let workspaceInfo;
    const workspace = state.workspaceList.find(i => i.id === spaceId);
    if (workspace) {
      workspaceInfo = workspace;
    } else if (!sessionStorage.getItem('workspaceInfo')) {
      const res = await (0, _workspace.getLatestWorkspaceInfo)();
      workspaceInfo = res.results;
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
    } else {
      const localSpaceId = JSON.parse(sessionStorage.getItem('workspaceInfo')).id;
      workspaceInfo = state.workspaceList.find(i => i.id === localSpaceId) || state.workspaceList[0];
    }
    dispatch('changeWorkspace', workspaceInfo);
    const res = await (0, _workspace.judgeWorkspaceExist)(workspaceInfo.id);
    if (!res.results.exists) {
      workspaceInfo = state.workspaceList[0];
      (0, _workspace.switchWorkspace)({
        id: workspaceInfo.id
      });
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
      dispatch('changeWorkspace', workspaceInfo);
    }
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;