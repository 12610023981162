"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$activeDataSetD, _setup$activeDataSetD2, _setup$activeDataSetD3, _setup$activeDataSetD4, _setup$activeDataSetD5;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.dataSetLoading,
      expression: "dataSetLoading"
    }],
    staticClass: "data-set-annotate"
  }, [_c(_setup.AnnotateHeader, {
    attrs: {
      "data-set-data-list": _setup.dataSetDataList,
      "data-set-name": _setup.dataSetName
    },
    on: {
      checkAnnotateResults: _setup.checkAnnotateResults
    }
  }), _c("div", {
    staticClass: "data-set-annotate__content"
  }, [_c("div", {
    staticClass: "data-set-annotate__content-left"
  }, [_c(_setup.AnnotateAllSample, {
    attrs: {
      "data-set-data-list": _setup.dataSetDataList,
      "active-data-set-data": _setup.activeDataSetData
    },
    on: {
      changeDataSetData: _setup.changeDataSetData
    }
  }), _c(_setup.AnnotateSearch, {
    key: ((_setup$activeDataSetD = _setup.activeDataSetData) === null || _setup$activeDataSetD === void 0 ? void 0 : _setup$activeDataSetD.id) + "annotateSearch",
    attrs: {
      "data-set-data-info": _setup.dataSetDataInfo
    }
  }), _c(_setup.AnnotateText, {
    key: ((_setup$activeDataSetD2 = _setup.activeDataSetData) === null || _setup$activeDataSetD2 === void 0 ? void 0 : _setup$activeDataSetD2.id) + "annotateText",
    ref: "annotateTextRef",
    attrs: {
      "active-data-set-data": _setup.activeDataSetData,
      "sentence-of-event-tag-map": _setup.sentenceOfEventTagMap,
      "data-set-label-list": _setup.dataSetLabelList
    },
    on: {
      deleteAnnotate: _setup.deleteAnnotateApi,
      createAnnotate: _setup.createAnnotateApi
    }
  }), _c(_setup.VideoPlayer, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "video-wrapper",
    attrs: {
      url: _setup.dataSetDataInfo.media_url,
      "media-type": ((_setup$activeDataSetD3 = _setup.activeDataSetData) === null || _setup$activeDataSetD3 === void 0 ? void 0 : _setup$activeDataSetD3.type) === "video" ? "audio" : (_setup$activeDataSetD4 = _setup.activeDataSetData) === null || _setup$activeDataSetD4 === void 0 ? void 0 : _setup$activeDataSetD4.type
    },
    on: {
      changeSliderTime: _setup.changeSliderTime
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.activeDataSetData.type !== "doc",
      expression: "activeDataSetData.type !== 'doc'"
    }],
    class: {
      "conv-control-content-wrapper": ((_setup$activeDataSetD5 = _setup.activeDataSetData) === null || _setup$activeDataSetD5 === void 0 ? void 0 : _setup$activeDataSetD5.type) !== "doc"
    }
  }, [_c(_setup.ConvControlContent, {
    staticClass: "video-wrapper",
    attrs: {
      url: _setup.dataSetDataInfo.media_url,
      "has-video": _setup.dataSetDataInfo.media_url ? true : false,
      "local-slider-time": _setup.localSliderTime
    }
  })], 1)], 1), _c("div", {
    staticClass: "data-set-annotate__content-right"
  }, [_c(_setup.AnnotateLabel, {
    key: _setup.activeDataSetData.id,
    attrs: {
      "data-set-label-list": _setup.dataSetLabelList,
      "event-label-of-sentence-map": _setup.eventLabelOfSentenceMap,
      "active-data-set-data": _setup.activeDataSetData,
      "active-label-ids-arr": _setup.activeLabelIdsArr
    },
    on: {
      createAnnotate: _setup.createAnnotateApi,
      renameAnnotateLabel: _setup.renameAnnotateLabel,
      changeActiveLabelArr: _setup.changeActiveLabelArr,
      deleteAnnotate: _setup.deleteAnnotateApi,
      refreshDataSetLabel: _setup.refreshDataSetLabel,
      getSentenceOfEvent: _setup.getSentenceOfEventApi
    }
  }), _c(_setup.AnnotateOperate, {
    key: _setup.activeDataSetData.id + "annotateOperate",
    attrs: {
      "data-set-data-list": _setup.dataSetDataList,
      "active-data-set-data": _setup.activeDataSetData
    },
    on: {
      changeDataSetDataOperate: _setup.changeDataSetDataOperate
    }
  })], 1)]), _setup.checkAnnotateResultDrawerVisible ? _c(_setup.AnnotateResultsDrawer, {
    attrs: {
      "drawer-visible": _setup.checkAnnotateResultDrawerVisible,
      "data-set-id": _setup.dataSetId,
      "data-set-name": _setup.dataSetName
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.checkAnnotateResultDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.checkAnnotateResultDrawerVisible = $event;
      }
    }
  }) : _vm._e(), _c("MgvTour", {
    ref: "annotateTourRef",
    attrs: {
      name: "isShowAnnotateGuide",
      steps: _setup.annotateGuideSteps
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;