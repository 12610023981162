"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _moment = _interopRequireDefault(require("moment"));
var _vue = _interopRequireDefault(require("vue"));
var _timeFormatter = require("@/utils/time-formatter");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const activityInitArray = [{
  name: 'count',
  markPoint: '',
  labelTitle: '总通话数',
  labelType: '次',
  labelValueHtml: '<span class="bold-text">20</span>',
  desDetail: '接通的通话数'
}, {
  name: 'sum_duration',
  markPoint: '',
  labelTitle: '总通话时长',
  labelType: 'time',
  labelValueHtml: '<span class="bold-text">20</span>',
  desDetail: '所有接通的会话时间长度'
}, {
  name: 'avg_duration',
  markPoint: '',
  labelTitle: '平均通话时长',
  labelType: 'time',
  labelValueHtml: '<span class="bold-text">28</span><span>分钟</span><span class="bold-text"> 34</span><span>秒</span>',
  avgStr: '',
  desDetail: '平均每通电话的时间长度，基于有效通话次数，排除没有接通的电话'
}];
const dimensionInitArray = [{
  name: 'percent_speak',
  markPoint: '',
  labelTitle: '销售说话占比',
  labelType: 'percent',
  labelValueHtml: '<span class="bold-text">28</span><span>分钟</span><span class="bold-text"> 34</span><span>秒</span>',
  avgStr: ''
}, {
  name: 'max_sales',
  markPoint: '',
  labelTitle: '平均最长销售独白',
  labelType: 'time',
  labelValueHtml: '<span class="bold-text">20</span>'
}, {
  name: 'max_customer',
  markPoint: '',
  labelTitle: '平均最长客户叙述',
  labelType: 'time',
  labelValueHtml: '<span class="bold-text">20</span>'
}, {
  name: 'discuss',
  markPoint: '',
  labelTitle: '深入讨论次数',
  labelType: '次',
  labelValueHtml: '<span class="bold-text">20</span>'
},
// {
//   name: "patience",
//   markPoint: "",
//   labelTitle: "耐心",
//   labelType: "patience",
//   labelValueHtml: '<span class="bold-text">20</span>',
//   desDetail: "平均每次客户发言结束后多久销售发言",
// },
{
  name: 'question',
  markPoint: '',
  labelTitle: '提问频率',
  labelType: '次',
  labelValueHtml: '<span class="bold-text">20</span>'
}];
function handleTabLabelArray(initArray, apiObj, tab) {
  if (apiObj) {
    const array = initArray;
    array.forEach(item => {
      item.avg = apiObj[item.name].avg;
      item.tab = apiObj[item.name].tab;
      item.xAxis = getTableHorizontalArray(apiObj[item.name].table, item.labelType);
      item.table = handleTableData(apiObj[item.name].table, item.labelType, item.xAxis);
      getLabelHtmlbyItem(item);
      getAvgStrbyItem(item);
      if (tab === 'dimension') {
        item.reference = getReferenceValue(apiObj[item.name].min, apiObj[item.name].max, item.xAxis);
        item.markPoint = apiObj[item.name].mark;
        item.referenceLabel = apiObj[item.name].suggestion_label;
        item.desAllCount = apiObj.all_count;
        item.desCount = apiObj.count;
        item.desDetail = apiObj.labels[item.name].explanation;
      } else if (tab === 'activity') {
        item.desDetail = apiObj.labels[item.name].explanation;
      }
    });
    return array;
  } else {
    return [];
  }
}
function handleTableData(array, type, xAxis) {
  const maxValue = getMaxValue(xAxis);
  array.forEach(item => {
    item.valueLabel = getTableLabel(item.value, type);
    item.percentage = (item.value / maxValue).toFixed(2) * 100;
  });
  return array;
}
function getTableLabel(value, type) {
  if (type === 'time') {
    return getTableTimeStr(value);
  } else if (type === 'percent') {
    return `${parseFloat(value.toFixed(2))}%`;
  } else if (type === 'patience') {
    return `${parseFloat(value.toFixed(2))}s`;
  } else {
    return `${parseFloat(value.toFixed(2)) + type}`;
  }
}
function getTableTimeStr(seconds) {
  const {
    s,
    m,
    h
  } = (0, _timeFormatter.second2HMS)(seconds);
  if (h) {
    return m ? `${h}h ${m}m` : `${h}h`;
  } else if (m) {
    return s ? `${m}m ${s}s` : `${m}m`;
  } else if (s) {
    return `${s}s`;
  } else {
    return `0s`;
  }
}
function getMaxValue(table) {
  const values = [];
  table.forEach(item => {
    values.push(item.value);
    if (item.table) {
      item.table.forEach(i => {
        values.push(i.value);
      });
    }
  });
  let max;
  if (values.length === 1) {
    max = values[0];
  } else if (values.length > 1) {
    max = Math.max(...values);
  }
  return max;
}

// function getMinValue(table) {
//   let values = [];
//   table.forEach((item) => {
//     values.push(item.value);
//   });
//   let min;
//   if (values.length === 1) {
//     min = values[0];
//   } else if (values.length > 1) {
//     min = Math.min(...values);
//   }
//   return min;
// }

function getTableHorizontalArray(table, type) {
  let maxValue = getMaxValue(table);
  // let minValue = getMinValue(table)
  if (type === 'time') {
    let ceilNumber;
    if (maxValue > 3600) {
      ceilNumber = Math.ceil(maxValue / 3600);
      maxValue = ceilNumber * 3600;
    } else if (maxValue > 60) {
      ceilNumber = Math.ceil(maxValue / 60);
      maxValue = ceilNumber * 60;
    } else {
      ceilNumber = Math.ceil(maxValue / 60);
      maxValue = ceilNumber * 60;
    }
  }
  if (maxValue && type !== 'percent') {
    const avgValue = Math.ceil(maxValue / 5);
    const emptyArray = [];
    for (let i = 0; i < 6; i++) {
      emptyArray.push({
        label: getTableLabel(avgValue * i, type),
        value: avgValue * i
      });
    }
    return emptyArray;
  } else {
    if (type === 'time') {
      return [{
        label: '0m',
        value: 0
      }, {
        label: '1m',
        value: 60
      }, {
        label: '2m',
        value: 120
      }, {
        label: '3m',
        value: 180
      }, {
        label: '4m',
        value: 240
      }, {
        label: '5m',
        value: 300
      }];
    } else if (type == 'patience') {
      return [{
        label: '0s',
        value: 0
      }, {
        label: '1s',
        value: 60
      }, {
        label: '2s',
        value: 120
      }, {
        label: '3s',
        value: 180
      }, {
        label: '4s',
        value: 240
      }, {
        label: '5s',
        value: 300
      }];
    } else if (type === 'percent') {
      return [{
        label: '0%',
        value: 0
      }, {
        label: '20%',
        value: 20
      }, {
        label: '40%',
        value: 40
      }, {
        label: '60%',
        value: 60
      }, {
        label: '80%',
        value: 80
      }, {
        label: '100%',
        value: 100
      }];
    } else {
      return [{
        label: '0' + type,
        value: 0
      }, {
        label: '1' + type,
        value: 1
      }, {
        label: '2' + type,
        value: 2
      }, {
        label: '3' + type,
        value: 3
      }, {
        label: '4' + type,
        value: 4
      }, {
        label: '5' + type,
        value: 5
      }];
    }
  }
}
function getReferenceValue(min, max, xAxis) {
  let maxVal = max;
  let minVal = min;
  if (max == 'max') {
    maxVal = 'max';
    minVal = min;
  }
  if (min == 'min') {
    minVal = 0;
  }
  return [minVal, maxVal];
}
function getLabelHtmlbyItem(item) {
  if (item.tab === '--') {
    item.labelValueHtml = `<span class="no-text">${item.tab}</span>`;
  } else if (item.labelType === 'time') {
    item.labelValueHtml = getTimeHtml(item.tab);
  } else if (item.labelType === 'percent') {
    item.labelValueHtml = `<span class="bold-text">${item.tab.toFixed(2)}</span><span>%</span>`;
  } else if (item.labelType === 'patience') {
    item.labelValueHtml = `<span class="bold-text">${item.tab.toFixed(2)}</span><span>秒</span>`;
  } else {
    item.labelValueHtml = `<span class="bold-text">${parseFloat(item.tab.toFixed(2))}</span>`;
  }
}
function getAvgStrbyItem(item) {
  if (item.avg === '--') {
    item.avgStr = '--';
  } else if (item.labelType === 'time') {
    item.avgStr = getTimeStr(item.avg);
  } else if (item.labelType === 'percent') {
    item.avgStr = `${item.avg.toFixed(2)}%`;
  } else if (item.labelType === 'patience') {
    item.avgStr = `${parseFloat(item.avg.toFixed(2))}s`;
  } else {
    item.avgStr = `${parseFloat(item.avg.toFixed(2))}次`;
  }
}
function getTimeStr(seconds) {
  let {
    s
  } = (0, _timeFormatter.second2HMS)(seconds);
  const {
    m,
    h
  } = (0, _timeFormatter.second2HMS)(seconds);
  if (h) {
    return m ? `${h}小时${m}分` : `${h}小时`;
  } else if (m) {
    return s ? `${m}分${s}秒` : `${m}分`;
  } else if (s) {
    s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(2);
    return `${s}秒`;
  } else {
    return `0秒`;
  }
}
function getTimeHtml(seconds) {
  let {
    s
  } = (0, _timeFormatter.second2HMS)(seconds);
  const {
    m,
    h
  } = (0, _timeFormatter.second2HMS)(seconds);
  if (h) {
    return m ? `<span class="bold-text">${h}</span><span>小时</span><span class="bold-text"> ${m}</span><span>分</span>` : `<span class="bold-text">${h}</span><span>小时</span>`;
  } else if (m) {
    return s ? `<span class="bold-text">${m}</span><span>分</span><span class="bold-text"> ${s}</span><span>秒</span>` : `<span class="bold-text">${m}</span><span>分</span>`;
  } else if (s) {
    s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(2);
    return `<span class="bold-text"> ${s}</span><span>秒</span>`;
  } else {
    return `<span class="bold-text"> 0</span><span>秒</span>`;
  }
}
function handleKeyeventObj(res) {
  res.avgStr = `${res.avg.toFixed(2)}%`;
  res.xAxis = [{
    label: '0%',
    value: 0
  }, {
    label: '20%',
    value: 20
  }, {
    label: '40%',
    value: 40
  }, {
    label: '60%',
    value: 60
  }, {
    label: '80%',
    value: 80
  }, {
    label: '100%',
    value: 100
  }];
  res.table = handleTableData(res.table, 'percent', res.xAxis);
  res.table.forEach(item => {
    if (item.table) {
      item.avgStr = `${item.avg.toFixed(2)}%`;
      item.xAxis = res.xAxis;
      item.table = handleTableData(item.table, 'percent', res.xAxis);
    }
  });
  return res;
}
function handleTopicObj(res) {
  res.avgStr = getTableTimeStr(res.avg);
  res.xAxis = getTableHorizontalArray(res.table, 'time');
  res.table = handleTableData(res.table, 'time', res.xAxis);
  res.table.forEach(item => {
    item.avgStr = getTableTimeStr(item.avg);
    item.xAxis = res.xAxis;
    item.table = handleTableData(item.table, 'time', res.xAxis);
  });
  return res;
}
function handleFiltersParams(filtersObj, rootState) {
  var _rootState$workspace, _rootState$user;
  const requestParams = (0, _commonFunc.deepClone)(filtersObj);
  (0, _timezone.convertParams2UTCplus8)(requestParams.custom_filters);
  const {
    tree_ids,
    user_ids
  } = requestParams.tree_user_ids;
  delete requestParams.tree_user_ids;
  return {
    ...requestParams,
    tree_ids,
    user_ids,
    workspace_id: rootState === null || rootState === void 0 ? void 0 : (_rootState$workspace = rootState.workspace) === null || _rootState$workspace === void 0 ? void 0 : _rootState$workspace.workspaceInfo.id,
    time_zone: rootState === null || rootState === void 0 ? void 0 : (_rootState$user = rootState.user) === null || _rootState$user === void 0 ? void 0 : _rootState$user.time_zone
  };
}
const state = {
  filtersObj: null,
  activityObj: null,
  dimensionObj: null,
  keyeventObj: null,
  topicObj: null,
  topicTime: null,
  statementArr: null,
  businessAnalysis: null,
  biPersonalFilterTime: ''
};
const mutations = {
  SET_FILTERS_OBJ: (state, Obj) => {
    state.filtersObj = Obj;
  },
  GET_ACTIVITY_OBJ: (state, Obj) => {
    state.activityObj = Obj;
  },
  GET_DIMENSION_OBJ: (state, Obj) => {
    state.dimensionObj = Obj;
  },
  GET_KEYEVENT_OBJ: (state, Obj) => {
    state.keyeventObj = Obj;
  },
  GET_TOPIC_OBJ: (state, Obj) => {
    state.topicObj = Obj;
  },
  GET_TOPIC_TIME: (state, Obj) => {
    state.topicTime = Obj;
  },
  SET_STATEMENT_OBJ: (state, obj) => {
    state.statementArr = obj;
  },
  SET_STATEMENT_SUBDATA: (state, _ref) => {
    let {
      data,
      index
    } = _ref;
    _vue.default.set(state.statementArr, index, {
      ...state.statementArr[index],
      subData: data
    });
  },
  handleKeyeventObj(state, obj) {
    return handleKeyeventObj(obj);
  },
  handleTopicObj(state, obj) {
    return handleTopicObj(obj);
  },
  SET_ACTIVITY_OBJ: state => state.activityObj = null,
  SET_DIMENSION_OBJ: state => state.dimensionObj = null,
  SET_KEYEVENT_OBJ: state => state.keyeventObj = null,
  SET_BI_PERSONAL_TIME_FILTER: (state, val) => {
    state.biPersonalFilterTime = val;
  }
};
const actions = {
  async get_activity_obj(_ref2) {
    let {
      commit,
      state,
      rootState
    } = _ref2;
    try {
      const res = await (0, _businessIntelligence.getConversationBiInfo)(handleFiltersParams(state.filtersObj, rootState));
      const data = handleTabLabelArray(activityInitArray, res.results, 'activity');
      commit('GET_ACTIVITY_OBJ', data);
    } catch (error) {
      commit('GET_ACTIVITY_OBJ', {});
    }
  },
  async get_dimension_obj(_ref3) {
    let {
      commit,
      state,
      rootState
    } = _ref3;
    try {
      const res = await (0, _businessIntelligence.getConversatioDimensionBiInfo)(handleFiltersParams(state.filtersObj, rootState));
      const data = handleTabLabelArray(dimensionInitArray, res.results, 'dimension');
      commit('GET_DIMENSION_OBJ', data);
    } catch (error) {
      commit('GET_DIMENSION_OBJ', {});
    }
  },
  async get_keyevent_obj(_ref4) {
    let {
      commit,
      state,
      rootState
    } = _ref4;
    try {
      const res = await (0, _businessIntelligence.getKeyeventBiInfo)(handleFiltersParams(state.filtersObj, rootState));
      const data = handleKeyeventObj(res.results);
      commit('GET_KEYEVENT_OBJ', data);
    } catch (error) {
      commit('GET_KEYEVENT_OBJ', {});
    }
  },
  async get_topic_obj(_ref5) {
    let {
      commit,
      state
    } = _ref5;
    try {
      const res = await (0, _businessIntelligence.getTopicDuration)(state.filtersObj);
      const data = handleTopicObj(res.results);
      // 临时代码，topic没有id，前端手动加上id，用来标记高亮
      data.table.forEach(item => {
        item.id = item.key;
      });
      commit('GET_TOPIC_OBJ', data);
    } catch (error) {
      commit('GET_TOPIC_OBJ', {});
    }
    try {
      const res = await (0, _businessIntelligence.getTopicTime)(state.filtersObj);
      commit('GET_TOPIC_TIME', res.results);
    } catch (error) {
      commit('GET_TOPIC_TIME', {});
    }
  },
  async get_statement_obj(_ref6) {
    let {
      commit,
      state,
      rootState
    } = _ref6;
    const res = await (0, _businessIntelligence.getAnalyticalStatement)(handleFiltersParams(state.filtersObj, rootState));
    commit('SET_STATEMENT_OBJ', res.results);
  },
  async get_statement_sub_data(_ref7, _ref8) {
    let {
      commit,
      state,
      rootState
    } = _ref7;
    let {
      id,
      index
    } = _ref8;
    const res = await (0, _businessIntelligence.getStatementSubData)({
      ...handleFiltersParams(state.filtersObj, rootState),
      id
    });
    if (!res.results.data) {
      res.results.data = [];
    }
    commit('SET_STATEMENT_SUBDATA', {
      data: res.results.data,
      index
    });
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;