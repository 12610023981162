"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomFieldRightContent = _interopRequireDefault(require("./components/CustomFieldRightContent"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    CustomFieldRightContent: _CustomFieldRightContent.default
  },
  data() {
    return {
      current_menu: 'conversation'
    };
  },
  computed: {
    title() {
      return function () {
        switch (this.current_menu) {
          case 'conversation':
            return '会话';
          case 'lead':
            return '线索';
          case 'account':
            return '客户';
          case 'deal':
            return '商机';
          case 'contact':
            return '联系人';
        }
      };
    }
  },
  created() {
    const menu = this.$route.params.menu;
    if (menu) {
      this.current_menu = menu;
    }
  },
  methods: {
    handleSelect(key) {
      this.current_menu = key;
      if (this.$refs.tab) this.$refs.tab.activeTab = 'field';
    }
  }
};
exports.default = _default;