"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TeamAnalysisTable = _interopRequireDefault(require("./components/TeamAnalysisTable"));
var _EditColumnDialog = _interopRequireDefault(require("./components/EditColumnDialog"));
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _editKanbanNameDialog = _interopRequireDefault(require("@/views/businessIntelligence/components/editKanbanNameDialog"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _TableExport = _interopRequireDefault(require("@/components/TableExport.vue"));
var _formatDataUnit = require("@/components/Charts/formatDataUnit");
var _routeMap = require("@/router/routeMap");
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamAnalysis',
  components: {
    TeamAnalysisTable: _TeamAnalysisTable.default,
    EditColumnDialog: _EditColumnDialog.default,
    BIKanban: _BIKanban.default,
    ShareConfig: _shareConfig.default,
    EditKanbanNameDialog: _editKanbanNameDialog.default,
    InitKanbanCardView: _InitKanbanCardView.default,
    DynamicPopover: _DynamicPopover.default,
    TableExport: _TableExport.default
  },
  mixins: [_ExportMixins.default, _cancelTokenMixin.default],
  data() {
    return {
      tableLoading: false,
      teamAnalysisFilters: {},
      editColumnDialogVisible: false,
      isEditColumn: false,
      editColumnData: {
        configs: {
          name: '',
          analysis_target: 'conversation',
          analysis_target_key: '',
          analysis_target_vals: '',
          conversation_type: [],
          conversation_scene_type: [],
          conversation_duration: {
            unit: 'second',
            value: [0, 'unlimit']
          },
          space_conv_total_duration: {
            // 交易会话总时长
            unit: 'second',
            value: ['unlimit', 'unlimit']
          },
          draw_style: 'count',
          strategy: {
            results_type: '',
            key: '',
            vals: ''
          }
        }
      },
      tableHeader: [],
      tableData: [],
      isShowCheckbox: false,
      // 创建A/B测试选中的列
      checkedList: [],
      dimension: 'department',
      dimensionOptions: Object.freeze([{
        type: 'department',
        label: '部门'
      }, {
        type: 'member',
        label: '成员'
      }]),
      getKanbanListLoading: true,
      currentKanban: {},
      kanbanList: [],
      getKanbanConfigLoading: false,
      getChartDataLoading: false,
      operateType: 'create',
      shareKanbanConfigVisible: false,
      copyKanbanConfigVisible: false,
      analysisTips: Object.freeze([{
        text: '环比上升20%及以上',
        color: '#D6F5E8'
      }, {
        text: '环比下降20%及以上',
        color: '#F9D2D4'
      }]),
      allTableHead: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'userId', 'workspaceInfo', 'modulePermission', 'timezone']),
    getHandleKanabanNameFn() {
      return {
        create: this.createTeamAnalysisKanban,
        edit: this.editTeamAnalysisKanban,
        copy: this.universalCopyKanban
      }[this.operateType];
    },
    editKanbanNameDialogTile() {
      return this.operateType === 'create' ? '新建' : this.operateType === 'edit' ? '编辑' : '复制';
    },
    backfillKanbanName() {
      let kanbanName = '';
      switch (this.operateType) {
        case 'edit':
          kanbanName = this.currentKanban.name;
          break;
        case 'copy':
          kanbanName = this.currentKanban.name + '_副本';
          break;
      }
      return kanbanName;
    },
    queryParams() {
      return {
        ...this.teamAnalysisFilters,
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id,
        view_type: this.dimension === 'member' ? 2 : 1,
        // view_type: 1 -> 部门, 2 -> 成员
        time_zone: this.timezone,
        is_all: false
      };
    }
  },
  watch: {
    biFiltersObj: {
      handler(val) {
        this.handleCancle();
        console.log('val', val);
        const {
          date,
          tree_user_ids,
          tree_ids,
          custom_filters,
          analysis_basis
        } = val;
        if (this.dimension === 'member') {
          this.teamAnalysisFilters = {
            date: JSON.parse(date),
            tree_ids: tree_user_ids.tree_ids,
            user_ids: tree_user_ids.user_ids,
            basic_source: analysis_basis === 'conversation' ? 1 : 2,
            custom_filters
          };
        } else {
          this.teamAnalysisFilters = {
            date: JSON.parse(date),
            tree_ids,
            basic_source: analysis_basis === 'conversation' ? 1 : 2,
            custom_filters
          };
        }
        if (this.currentKanban.kanban_id) this.getTeamAnalysisList();
      }
    },
    'workspaceInfo.id': {
      handler() {
        this.currentKanban = {};
        this.getKanbanList();
      }
    }
  },
  created() {
    if (this.$route.query.type) {
      this.dimension = this.$route.query.type;
    }
    if (!this.workspaceInfo.id) {
      return;
    }
    this.getKanbanList();
  },
  methods: {
    refreshTeamAnalysisList() {
      this.getTeamAnalysisList();
      !this.isEditColumn && this.getTeamAnalysisTableHead();
    },
    initTableConfig() {
      this.getTeamAnalysisList();
      // 获取当前看板所有列
      this.getTeamAnalysisTableHead();
    },
    async updateTableHead(head) {
      head.forEach((item, index) => {
        item.order = index + 1;
      });
      this.$refs.dynamicPopover.saveLoading = true;
      const res = await (0, _businessIntelligence.updateTeamAnalysisTableHead)({
        kanban_id: this.currentKanban.kanban_id,
        head
      }).finally(() => {
        this.$refs.dynamicPopover.saveLoading = false;
        this.$refs.dynamicPopover.visible = false;
      });
      if (res.code === 20000) {
        this.initTableConfig();
      }
    },
    createTeamAnalysisKanban: _businessIntelligence.createTeamAnalysisKanban,
    editTeamAnalysisKanban: _businessIntelligence.editTeamAnalysisKanban,
    universalCopyKanban: _businessIntelligence.universalCopyKanban,
    getExtraCommandlist() {
      return [{
        type: 'copy',
        label: '复制',
        order: 2,
        icon: 'icon-copy-document'
      }, {
        type: 'share',
        label: '分享',
        order: 3,
        icon: 'icon-share-line'
      }];
    },
    // 看板操作
    async getKanbanList() {
      if (!this.workspaceInfo.id) {
        return;
      }
      this.getKanbanListLoading = true;
      const res = await (0, _businessIntelligence.getTeamAnalysisKanbanList)({
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.getKanbanListLoading = false;
      });
      if (res.code === 20000) {
        if (!res.results.data.length) {
          this.kanbanList = [];
          return;
        }
        this.kanbanList = res.results.data.map(item => {
          item.hiddenHandle = [];
          if (item.is_share) {
            item.hiddenHandle.push('edit', 'delete', 'share');
            item.kanban_remark = `来自${item.share_user_name}的分享`;
          }
          return item;
        });

        // 第一次进来默认选中第一个看板
        if (!this.currentKanban.kanban_id) {
          this.currentKanban = this.kanbanList[0];
        } else if (this.operateType === 'create') {
          // 新建选中最后一个看板
          this.currentKanban = this.kanbanList[this.kanbanList.length - 1];
        }
        this.initTableConfig();
      }
    },
    async getTeamAnalysisTableHead() {
      const res = await (0, _businessIntelligence.getTeamAnalysisTableHead)({
        id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.allTableHead = res.results;
      }
    },
    createKanban() {
      this.copyKanbanConfigVisible = true;
      this.operateType = 'create';
      this.isEdit = false;
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.handleCancle();
      this.currentKanban = activeKanban;
      this.initTableConfig();
    },
    handleKanban(type, data) {
      this.operateType = type;
      this[`${type}Kanban`](data);
    },
    async copyKanban() {
      this.copyKanbanConfigVisible = true;
    },
    async shareKanban() {
      this.isEdit = true;
      this.shareKanbanConfigVisible = true;
    },
    editKanban() {
      this.copyKanbanConfigVisible = true;
    },
    async deleteKanban(_ref) {
      let {
        kanban_id,
        name
      } = _ref;
      this.$confirm(`看板删除后，被分享人员的看板也会同步删除，是否删除？`, '删除看板', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteTeamAnalysisKanban)({
          id: kanban_id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== kanban_id);
          if (this.kanbanList.length) {
            this.toggleKanban(this.kanbanList[0]);
          }
        }
      });
    },
    initTable() {
      this.tableHeader = [];
      this.tableData = [];
    },
    async exportAllData() {
      const res = await (0, _businessIntelligence.getTeamAnalysisList)({
        ...this.queryParams,
        is_all: true
      });
      if (res.code === 20000) {
        if (res.results.header.length) {
          const tableHead = this.formatTableHeader(res.results.header);
          const tableData = this.formatTableData(res.results.table, tableHead);
          this.exportData(tableHead, tableData);
        }
      }
    },
    exportData(tableHead, tableData) {
      if (this.isShowCheckbox) return;
      const head = tableHead.map(item => item.configs.name);
      head.unshift(this.dimension === 'department' ? '部门' : '姓名', this.dimension === 'department' ? '父部门信息' : '主部门信息');
      const data = tableData.map(_ref2 => {
        let {
          name,
          tree_name,
          values
        } = _ref2;
        return [name, tree_name, ...values.map(_ref3 => {
          let {
            value,
            show_value
          } = _ref3;
          if (value === '') {
            return '--';
          } else {
            return this.replaceTag(show_value);
          }
        })];
      });
      // 汇总数据
      data.unshift(['总计', '-', ...tableHead.map(item => this.replaceTag(item.show_value))], ['平均', '-', ...tableHead.map(item => this.replaceTag(item.show_avg))]);
      const {
        startDate,
        endDate
      } = this.getBiFilter();
      const viewType = this.dimensionOptions.find(_ref4 => {
        let {
          type
        } = _ref4;
        return type === this.dimension;
      }).label;
      const fileName = `团队概览（${viewType}） ${startDate}至${endDate}.csv`;
      (0, _download.downloadBlob)(this.handleFileToBuf([head, ...data]), fileName);
    },
    toggleAnalysisType() {
      this.handleCancle();
      this.initTable();
      this.$router.push({
        path: _routeMap.ROUTES.biTeamAnalysis,
        query: {
          type: this.dimension
        }
      });
    },
    goABTest() {
      this.$router.push({
        name: 'AbTesting',
        params: {
          monitor_configs: this.checkedList,
          source: 3
        }
      });
    },
    handleCancle() {
      this.isShowCheckbox = false;
      this.checkedList = [];
      if (this.$refs['team-analysis-table']) {
        this.$refs['team-analysis-table'].checkList = [];
      }
    },
    sortTableData(index, orderType) {
      this.tableData.sort((a, b) => orderType * (a.values[index].value - b.values[index].value));
    },
    // 删除/编辑/新增 列
    async handleEditColumn(type, data) {
      switch (type) {
        case 'delete':
          // 删除列,发接口，重新渲染table
          this.deleteTeamAnalysisColumn({
            id: data.id
          });
          break;
        case 'edit':
          this.isEditColumn = true;
          this.editColumnDialogVisible = true;
          this.editColumnData = (0, _commonFunc.deepClone)(data);
          break;
        case 'add':
          if (this.tableHeader.length < 20) {
            this.editColumnDialogVisible = true;
            this.isEditColumn = false;
            this.editColumnData = this.$options.data().editColumnData;
          } else {
            this.$message({
              message: '最多可添加20列',
              type: 'warning',
              center: true
            });
          }
          break;
        default:
          break;
      }
    },
    // 删除团队概览列
    async deleteTeamAnalysisColumn(id) {
      this.$confirm(`是否删除当前字段？`, '删除字段', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteTeamAnalysisColumn)({
          ...id,
          kanban_id: this.currentKanban.kanban_id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.initTableConfig();
        }
      });
    },
    // 获取团队概览表
    async getTeamAnalysisList() {
      if (!this.teamAnalysisFilters.tree_ids || !this.workspaceInfo.id) return;
      this.initTable();
      this.$refs['team-analysis-table'] && this.$refs['team-analysis-table'].sortInitOther('');
      this.tableLoading = true;
      // 取消上一次请求
      this.axiosCancelRequest();
      // 使用新的cancelToken
      this.newCancelToken();
      const res = await (0, _businessIntelligence.getTeamAnalysisList)(this.queryParams, {
        cancelToken: this.axiosCancelToken
      }).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        if (res.results.header.length) {
          res.results.header.forEach(item => {
            if (!item.config.conversation_type) {
              item.config.conversation_type = [];
            }
          });
          this.tableHeader = this.formatTableHeader(res.results.header);
          this.tableData = this.formatTableData(res.results.table, this.tableHeader);
        } else {
          this.tableHeader = [];
          this.tableData = [];
        }
      }
    },
    closeDialog() {
      this.editColumnDialogVisible = false;
      this.editColumnData = this.$options.data().editColumnData;
    },
    formatTableHeader(data) {
      data.forEach(item => {
        // const configs = JSON.parse(item.config);
        const configs = item.config;
        configs.draw_style = configs.dimension || 'count';
        item.field_name = 'value';
        item.configs = configs;
        item.show_value = configs.dimension === 'percent' || configs.analysis_target_key === 'business_trans' || configs.analysis_target_key === 'question' || item.value === -1 ? '--' : (0, _formatDataUnit.formatDataUnit)(configs, item.value);
        item.show_avg = (0, _formatDataUnit.formatDataUnit)(configs, Math.abs(item.avg));
      });
      return data;
    },
    formatTableData(data, tableHead) {
      data.forEach(item => {
        item.values.forEach((itemValue, index) => {
          const configs = tableHead[index].configs;
          itemValue.show_ratio = (0, _formatDataUnit.formatDataUnit)(configs, Math.abs(itemValue.ratio));
          itemValue.show_value = (0, _formatDataUnit.formatDataUnit)(configs, itemValue.value);
        });
      });
      return data;
    },
    replaceTag(data) {
      return data.replace(/<[^>]+>/g, '');
    }
  }
};
exports.default = _default;