"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.type === "change" ? _vm.$t("management.修改密码") : _vm.$t("management.设置密码"),
      visible: _vm.dialogVisible,
      width: "400px",
      "before-close": _vm.handleClose
    }
  }, [_c("article", {
    staticClass: "update-password"
  }, [_c("el-form", {
    ref: "passwordForm",
    attrs: {
      rules: _vm.rules,
      "hide-required-asterisk": "",
      autocomplete: "off",
      model: _vm.form
    }
  }, [_vm.isTwoFactor ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.验证邮箱"),
      prop: "verifyEmail"
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.verifyEmail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "verifyEmail", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.verifyEmail"
    }
  })], 1) : [_vm.type === "change" && !_vm.isMessageVerification ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.当前账户密码"),
      prop: "originPassword"
    }
  }, [_vm.ENV_TITLE !== "image" ? _c("el-link", {
    staticClass: "message-verification-link",
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        return _vm.switchVerificationMethod(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.切换为验证码验证")))]) : _vm._e(), _c("password", {
    attrs: {
      placeholder: _vm.$t("management.请输入当前账户密码"),
      tabindex: "1"
    },
    model: {
      value: _vm.form.originPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "originPassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.originPassword"
    }
  })], 1) : _vm._e(), _vm.tel && (_vm.type === "set" || _vm.isMessageVerification) ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.登录手机号"),
      prop: "tel"
    }
  }, [_vm.type === "change" ? _c("el-link", {
    staticClass: "message-verification-link",
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        return _vm.switchVerificationMethod(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.切换为密码验证")))]) : _vm._e(), _c("el-input", {
    ref: "tel",
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.tel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tel", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.tel"
    }
  })], 1) : _vm._e(), !_vm.tel && (_vm.type === "set" || _vm.isMessageVerification) ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.登录邮箱"),
      prop: "email"
    }
  }, [_vm.type === "change" ? _c("el-link", {
    staticClass: "message-verification-link",
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        return _vm.switchVerificationMethod(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("management.切换为密码验证")))]) : _vm._e(), _c("el-input", {
    ref: "email",
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1) : _vm._e()], _vm.isTwoFactor || _vm.type === "set" || _vm.isMessageVerification ? _c("el-form-item", {
    staticClass: "send-code",
    attrs: {
      prop: "code"
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "tel",
      tabindex: "-1"
    }
  }), _c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "password",
      tabindex: "-1"
    }
  }), _c("VerificationCodeInput", {
    ref: "codeInput",
    staticClass: "verification-code-input",
    attrs: {
      "code-type": "reset_password",
      "sending-destination": _vm.sendingDestination,
      tel: _vm.form.tel,
      "no-slider": _vm.isTwoFactor,
      email: _vm.isTwoFactor ? _vm.form.verifyEmail : _vm.form.email
    },
    on: {
      sendCode: _vm.sendCode
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.code"
    }
  }), _c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "tel",
      tabindex: "-1"
    }
  }), _c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "password",
      tabindex: "-1"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.设置新密码"),
      prop: "newPassword"
    }
  }, [_c("password-input", {
    ref: "passwordInput",
    staticClass: "password-input",
    attrs: {
      placeholder: _vm.$t("management.请输入新密码")
    },
    model: {
      value: _vm.form.newPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "newPassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.newPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.确认新密码"),
      prop: "repassword"
    }
  }, [_c("password", {
    attrs: {
      placeholder: _vm.$t("management.请再次输入新密码")
    },
    model: {
      value: _vm.form.repassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "repassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.repassword"
    }
  })], 1), _c("el-form-item", {
    staticClass: "form-item-submit"
  }, [_c("el-button", {
    staticClass: "mg-button",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("management.取消")))]), _c("el-button", {
    staticClass: "mg-button",
    attrs: {
      type: "primary",
      disabled: _vm.buttonDisabled,
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.type === "change" ? _vm.$t("management.保存更改") : _vm.$t("management.保存")))])], 1)], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;