"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _client = require("@/api/client");
var _businessIntelligence = require("@/api/businessIntelligence");
var _business = require("@/api/business");
var _dealFilter = _interopRequireDefault(require("@/views/business/components/dealFilter"));
var _vuex = require("vuex");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessTransKanbanCreate',
  components: {
    DealCustomFilter: _dealFilter.default
  },
  props: {
    visible: Boolean,
    isEdit: Boolean,
    kanbanConfigLoading: Boolean,
    kanbanId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      submitLoading: false,
      customFieldOptions: [],
      dealStageOptions: [],
      eventTreeData: [],
      keyResultsTypeOptions: [{
        label: 'custom',
        name: '自定义字段'
      }, {
        label: 'deal_stage',
        name: '交易阶段'
      }, {
        label: 'events',
        name: '关键事件'
      }],
      kanbanConfig: {
        name: '',
        analysis_scope: {
          type: 'custom',
          key: '',
          value: '',
          name: ''
        },
        results: {
          type: 'custom',
          key: '',
          value: ''
        }
      },
      rules: {
        name: [{
          required: true,
          message: '请输入看板名称',
          trigger: 'change'
        }, {
          max: 15,
          message: '长度不能超过15个字符',
          trigger: 'change'
        }],
        'analysis_scope.value': [{
          required: true,
          message: '内容不能为空',
          trigger: 'change'
        }],
        'results.key': [{
          required: true,
          message: '请选择内容',
          trigger: 'change'
        }],
        'results.value': [{
          required: true,
          message: '内容不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getEvents();
      },
      immediate: true
    }
  },
  created() {
    this.getCustomFieldOptions();
    this.getDealStageOptions();
  },
  methods: {
    customFilterItem(type) {
      return this.customFieldOptions.find(_ref => {
        let {
          field_name
        } = _ref;
        return field_name === this.kanbanConfig[type].key;
      }) || {};
    },
    DealStageFilterItem(type) {
      return this.dealStageOptions.find(_ref2 => {
        let {
          id
        } = _ref2;
        return id === this.kanbanConfig[type].key;
      }) || {};
    },
    async submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.submitLoading = true;
          const params = this.getSubmitParams();
          const res = this.isEdit ? await (0, _businessIntelligence.editKanban)(params).finally(() => {
            this.submitLoading = false;
          }) : await (0, _businessIntelligence.createKanban)(params).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 200) {
            this.$message({
              message: this.isEdit ? '编辑成功' : '新建成功',
              type: 'success',
              center: true
            });
          }
          this.$emit('handleKanbanSuccess');
          this.closeDrawer();
        }
      });
    },
    async getEvents() {
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.eventTreeData = res.results.events;
      }
    },
    async getCustomFieldOptions() {
      const res = await (0, _business.getDealCustomFilter)({
        access_type: '/bi/bi/transandloss',
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.customFieldOptions = res.results;
      }
    },
    async getDealStageOptions() {
      const res = await (0, _client.getBusinessStatusOptions)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.dealStageOptions = res.results;
      }
    },
    getSubmitParams() {
      const {
        name,
        analysis_scope,
        results
      } = this.kanbanConfig;
      const params = {
        name,
        kanban_info: {
          analysis_scope,
          results
        },
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      (0, _timezone.convertParams2UTCplus8)(params.kanban_info.analysis_scope);
      (0, _timezone.convertParams2UTCplus8)(params.kanban_info.results);
      if (this.isEdit) {
        params.kanban_id = this.kanbanId;
      }
      return params;
    },
    handleKeyResultsTypeChange(field) {
      this.kanbanConfig[field].key = '';
      this.kanbanConfig[field].value = '';
      this.kanbanConfig[field].name = '';
    },
    handleCustomFilterItemChange(val, key) {
      this.kanbanConfig[key].value = Object.values(val)[0];
    },
    handleCustomFieldChange(key) {
      this.kanbanConfig[key].value = '';
      this.kanbanConfig[key].name = this.customFilterItem(key).name || '';
    },
    handleDealStageFieldChange(key) {
      this.kanbanConfig[key].name = this.DealStageFilterItem(key).stage_name || '';
    },
    closeDrawer() {
      this.kanbanConfig = this.$options.data().kanbanConfig;
      this.$refs['form'].resetFields();
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;