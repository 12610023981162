"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$loaded;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "file-item"
  }, [_c("i", {
    staticClass: "iconfont icon-tickets-fill"
  }), _c("div", {
    staticClass: "file-info"
  }, [_c("p", [_vm._v(_vm._s(_vm.file.name))]), _c("p", {
    staticClass: "upload-progress"
  }, [_vm.file.state === "uploading" ? [_vm._v(_vm._s(_setup.computerSizeToStr(_setup.loaded[_setup.loaded.length - 1])) + " / " + _vm._s(_setup.computerSizeToStr(_vm.file.size)))] : _vm._e(), _vm.file.state === "success" ? [_vm._v(_vm._s(_setup.computerSizeToStr(_vm.file.size)) + " / " + _vm._s(_setup.computerSizeToStr(_vm.file.size)))] : _vm._e(), _vm.file.state === "uploading" ? _c("span", {
    staticClass: "separator"
  }, [_vm._v("·")]) : _vm._e(), _vm.file.state === "uploading" ? _c("span", [_vm._v(" " + _vm._s(`${_setup.computerSizeToStr(_setup.uploadSpeed)}/s`))]) : _vm._e()], 2)]), _c("div", {
    staticClass: "upload-state"
  }, [_vm.file.state === "uploading" ? _c("span", [_vm._v(" " + _vm._s(Math.round(((_setup$loaded = _setup.loaded) === null || _setup$loaded === void 0 ? void 0 : _setup$loaded[_setup.loaded.length - 1]) / _vm.file.size * 100)) + "%")]) : _vm.file.state === "success" ? _c("i", {
    staticClass: "iconfont icon-success"
  }) : [_c("span", [_vm._v(_vm._s(_vm.file.errorMsg))]), _c("i", {
    staticClass: "iconfont icon-error"
  })], _c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-close",
      square: "",
      size: "medium"
    },
    on: {
      click: _setup.deleteFile
    }
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;