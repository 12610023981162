"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("draggable", _vm._b({
    staticClass: "mg-draggable",
    attrs: {
      value: _vm.value
    },
    on: {
      start: _vm.start,
      end: _vm.end,
      change: _vm.handleChange,
      input: _vm.dragInput
    }
  }, "draggable", _vm.getDragableOptions, false), [_c("transition-group", {
    attrs: {
      tag: "section"
    }
  }, [_vm._t("default")], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;