"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentTree = _interopRequireDefault(require("@/components/DepartmentTree"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentSelector',
  components: {
    DepartmentTree: _DepartmentTree.default
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    popperHeight: {
      type: String,
      default: 'auto'
    },
    orgTree: {
      type: Array,
      default: () => []
    },
    disabledArr: {
      type: Array,
      default: () => []
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    departmentName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localDepartmentName: ''
    };
  },
  methods: {
    handleChange(data) {
      var _this$$refs$select;
      this.localDepartmentName = data.name;
      this.$emit('input', data.id);
      this.$emit('change', data);
      (_this$$refs$select = this.$refs.select) === null || _this$$refs$select === void 0 ? void 0 : _this$$refs$select.blur();
    }
  }
};
exports.default = _default;