"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "department-and-member-select-tree",
    attrs: {
      placeholder: _vm.placeholder,
      "popper-class": "department-and-member-select-tree"
    },
    model: {
      value: _vm.labels,
      callback: function ($$v) {
        _vm.labels = $$v;
      },
      expression: "labels"
    }
  }, [_c("el-option", {
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: 0,
      disabled: ""
    }
  }, [_c("div", {
    staticClass: "drop-wrapper"
  }, [_c("lazy-tree", _vm._b({
    ref: "lazyTree",
    attrs: {
      "is-show-main-space-member": Number(_vm.isShowMainSpaceMember),
      "request-function": _vm.requestFunction,
      "backfill-checked-ids": _vm.backfillParams.ids
    },
    on: {
      change: _vm.handleChange,
      clearChecked: _vm.clearChecked
    }
  }, "lazy-tree", _vm.$attrs, false)), _c("div", {
    staticClass: "check-box"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.isShowMainSpaceMember,
      callback: function ($$v) {
        _vm.isShowMainSpaceMember = $$v;
      },
      expression: "isShowMainSpaceMember"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.onlyMainSpaceAreDisplayed")))])], 1)], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;