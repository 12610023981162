"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _KeyEventDataScope = _interopRequireDefault(require("@/components/KeyEventDataScope"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CascaderOrderSelect',
  components: {
    MgDraggable: _MgDraggable.default,
    KeyEventDataScope: _KeyEventDataScope.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    defaultProps: {
      type: Object,
      default: () => ({})
    },
    cascaderOptions: {
      type: Array,
      default: () => []
    },
    isAccountLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cascaderIdx: 0,
      infoText: '',
      infoTextStatus: '',
      // 0不展示，1展示
      draggableData: [],
      cascaderWidth: 130
    };
  },
  watch: {
    cascaderOptions: {
      handler: function () {
        this.cascaderIdx++;
      },
      deep: true
    },
    'filters.keyEventOrderCascaderList': {
      handler: function (val) {
        this.draggableData = val;
        this.setInfoText(false);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initMinWidth(val, index) {
      if (val) {
        this.cascaderWidth = 130;
        this.$nextTick(() => {
          this.$refs['key-event-order-cascader'][index].popperElm.style.minWidth = this.cascaderWidth + 'px';
          this.$refs['key-event-order-cascader'][index].updatePopper();
        });
      }
    },
    expandChange(item, index) {
      this.$nextTick(() => {
        const clientWidth = this.$refs['key-event-order-cascader'][index].popperElm.clientWidth;
        if (this.cascaderWidth < clientWidth) {
          this.cascaderWidth = clientWidth;
        }
        this.$nextTick(() => {
          this.$refs['key-event-order-cascader'][index].popperElm.style.minWidth = this.cascaderWidth + 'px';
          this.$refs['key-event-order-cascader'][index].updatePopper();
        });
      });
    },
    cascaderSelectChange(item, index) {
      setTimeout(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters.keyEventOrderCascaderList[index].keyEventLabel = this.$refs['key-event-order-cascader'][index].$refs['input'].value;
        this.setInfoText();
        this.$emit('change', this.filters);
      });
    },
    // 添加关键是事件跟踪器
    addKeyEventCascader() {
      const cascaderItem = {
        keyEventName: [],
        inverse: false
      };
      // eslint-disable-next-line vue/no-mutating-props
      if (this.filters.keyEventOrderCascaderList.length >= 15) {
        this.$message({
          message: '最多添加15条',
          type: 'warning'
        });
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters.keyEventOrderCascaderList.push(cascaderItem);
        this.setInfoText();
        this.$emit('addKeyEventCascader');
      }
    },
    // 删除当前的关键事件跟踪器
    deleteCurrentKeyEventCascader(index) {
      const {
        keyEventName
      } = this.filters.keyEventOrderCascaderList[index];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList.splice(index, 1);
      if (keyEventName.length) {
        this.setInfoText();
        this.$emit('change', this.filters);
      }
    },
    handleDragEnd() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList = this.draggableData;
      this.$emit('change', this.filters);
    },
    handleCheckBoxChange() {
      this.$emit('change', this.filters);
      this.setInfoText();
    },
    setInfoText() {
      let isTriggerUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const result = [];
      const filterList = this.filters.keyEventOrderCascaderList.filter(e => e['keyEventLabel']);
      if (filterList.length === 1) {
        this.infoTextStatus = 1;
      } else {
        this.infoTextStatus = 0;
        filterList.forEach(item => {
          result.push(`${item.inverse ? '不含' : ''}${item.keyEventLabel}`);
        });
        this.infoText = `${result.join(' → ')}`;
      }
      isTriggerUpdate && this.upDateSelectChange();
    },
    upDateSelectChange() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList.push({});
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventOrderCascaderList.pop();
    }
  }
};
exports.default = _default;