"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisHeaderButton = _interopRequireDefault(require("../../components/AnalysisHeaderButton"));
var _statementItem = _interopRequireDefault(require("../../components/statementItem"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AnalyticalStatement',
  components: {
    AnalysisHeaderButton: _AnalysisHeaderButton.default,
    statementItem: _statementItem.default
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biStatementArr'])
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.indexOf('/bi/') === -1) {
      sessionStorage.setItem('biFilters', '');
      sessionStorage.setItem('biDepartmentOptions', '');
    }
    next();
    this.$store.commit('bi/SET_STATEMENT_OBJ');
  }
};
exports.default = _default;