"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-collapse", {
    staticClass: "learn-content-collapse",
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("mg-draggable", {
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.localCheckedKnowledgeScript,
      callback: function ($$v) {
        _vm.localCheckedKnowledgeScript = $$v;
      },
      expression: "localCheckedKnowledgeScript"
    }
  }, _vm._l(_vm.localCheckedKnowledgeScript, function (item) {
    return _c("el-collapse-item", {
      key: item.knowledge_id,
      attrs: {
        name: item.knowledge_id
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("div", {
      staticClass: "left"
    }, [_c("i", {
      class: ["arrow", "el-icon-caret-right", _vm.activeNames.includes(item.knowledge_id) ? "expand" : "collapse"]
    }), _c("i", {
      staticClass: "iconfont icon-reading"
    }), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        placement: "top",
        effect: "dark",
        content: item.knowledge_name
      }
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.knowledge_name))])])], 1), _c("el-button", {
      staticClass: "hover-show-bg",
      attrs: {
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.deleteKnowledge(item);
        }
      }
    }), _c("i", {
      staticClass: "iconfont move icon-drag-dot-horizontal"
    })], 1), _c("LearnCard", {
      attrs: {
        "card-item": item
      }
    })], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;