"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-center-button"
  }, [_c("el-badge", {
    staticClass: "task-btn",
    attrs: {
      value: _vm.overtimeTaskNumber,
      max: 99,
      hidden: !Boolean(_vm.overtimeTaskNumber)
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "任务",
      "popper-class": "sidebar-bottom-button-tooltip",
      placement: "bottom",
      "hide-after": 1000
    }
  }, [_c("el-button", {
    staticClass: "message-icon hover-show-bg",
    attrs: {
      icon: "iconfont icon-task",
      circle: ""
    },
    on: {
      click: _vm.openTaskCenterDrawer
    }
  })], 1)], 1), _c("task-center-drawer", {
    attrs: {
      visible: _vm.taskCenterDrawerVisible,
      "current-task-detail-data": _vm.currentTaskDetailData
    },
    on: {
      "update:visible": function ($event) {
        _vm.taskCenterDrawerVisible = $event;
      },
      closeTaskDrawer: _vm.handelCloseTaskDrawer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;