import { render, staticRenderFns } from "./itemDetail.vue?vue&type=template&id=2cc610e2&scoped=true&"
import script from "./itemDetail.vue?vue&type=script&lang=js&"
export * from "./itemDetail.vue?vue&type=script&lang=js&"
import style0 from "./itemDetail.vue?vue&type=style&index=0&id=2cc610e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc610e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2cc610e2')) {
      api.createRecord('2cc610e2', component.options)
    } else {
      api.reload('2cc610e2', component.options)
    }
    module.hot.accept("./itemDetail.vue?vue&type=template&id=2cc610e2&scoped=true&", function () {
      api.rerender('2cc610e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BusinessDetail/itemDetail.vue"
export default component.exports