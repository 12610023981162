"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _KnowledgeList = _interopRequireDefault(require("./KnowledgeList.vue"));
var _vue = require("vue");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DialogueScenarioList',
  props: {
    backfillSceneList: {
      type: Array,
      default: () => []
    },
    saved: {
      type: Boolean,
      default: true
    }
  },
  emits: ['openAddScriptDialog', 'update:saved'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const activeNames = (0, _vue.ref)([]);
    function openAddScriptDialog(item) {
      emit('openAddScriptDialog', item);
      updateSaved();
      if (!activeNames.value.includes(item.id)) {
        activeNames.value.push(item.id);
      }
    }
    function updateSaved() {
      emit('update:saved', false);
    }
    const addId = (0, _vue.ref)(0);
    const sceneList = (0, _vue.ref)([{
      id: ++addId.value,
      name: '产品介绍-示例场景',
      knowledges: []
    }, {
      id: ++addId.value,
      name: '价格异议-示例场景',
      knowledges: []
    }]);
    (0, _vue.watch)(() => props.backfillSceneList, val => {
      sceneList.value = val;
    });
    const editSceneId = (0, _vue.ref)('');
    let oldName = '';
    function handleSceneNameChange(val) {
      if (!val.name.length) {
        val.name = oldName;
      }
      editSceneId.value = '';
      updateSaved();
    }
    const coll = (0, _vue.ref)();
    let collClick;
    function titleInputFocus() {
      collClick = coll.value.setActiveNames;
      coll.value.setActiveNames = () => {};
    }
    function titleInputBlur(val) {
      coll.value.setActiveNames = collClick;
      handleSceneNameChange(val);
    }
    const editInputRef = (0, _vue.ref)();
    function editSceneName(item) {
      oldName = item.name;
      editSceneId.value = item.id;
      (0, _vue.nextTick)(() => {
        editInputRef.value[0].select();
      });
    }
    function addScene() {
      const scene = {
        id: ++addId.value,
        name: '对话场景' + (sceneList.value.length + 1),
        knowledges: []
      };
      sceneList.value.push(scene);
      editSceneName(scene);
      updateSaved();
    }
    function deleteScene(index) {
      sceneList.value.splice(index, 1);
      updateSaved();
    }
    expose({
      sceneList
    });
    return {
      __sfc: true,
      emit,
      props,
      activeNames,
      openAddScriptDialog,
      updateSaved,
      addId,
      sceneList,
      editSceneId,
      oldName,
      handleSceneNameChange,
      coll,
      collClick,
      titleInputFocus,
      titleInputBlur,
      editInputRef,
      editSceneName,
      addScene,
      deleteScene,
      MgDraggable: _MgDraggable.default,
      KnowledgeList: _KnowledgeList.default
    };
  }
};
exports.default = _default;