"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tag", {
    class: ["mgv-tag", _vm.type ? "mgv-tag--" + _vm.type : "", _vm.color ? "mgv-tag--" + _vm.color : "", _vm.size ? "mgv-tag--size-" + _vm.size : "", {
      "is-click": _vm.clickable,
      "is-round": _vm.round,
      "is-disabled": _vm.disabled
    }],
    on: {
      click: _setup.handleClick
    }
  }, [_vm.icon ? _c("i", {
    class: [_vm.icon, "tag-icon"]
  }) : _vm._e(), _vm.$slots.default ? _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      content: _vm.$slots.default[0].text,
      tabindex: -1
    }
  }, [_c("span", {
    staticClass: "tag-text"
  }, [_vm._t("default")], 2)]) : _vm._e(), _vm.closable ? _c("i", {
    class: [`iconfont icon-close${_vm.size > 24 ? "" : "-small"}`, "close-icon"],
    on: {
      click: _setup.handleClose
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;