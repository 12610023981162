"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'EventSelect',
  props: {
    indicators: {
      type: Object,
      default: () => [{}]
    },
    IsMultiple: {
      type: Boolean
    },
    IsMultipleFatherRadio: {
      type: Boolean
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultProps: {
        expandTrigger: 'hover',
        children: 'children',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      prevVal: []
    };
  },
  watch: {
    'indicators.metrics_id': {
      // watch触发时机比change事件晚，故可以在change事件使用上次选项值之前获取到上次选项值
      handler(newVal) {
        if (this.IsMultipleFatherRadio && newVal) {
          this.prevVal = newVal;
        }
      },
      immediate: true
    }
  },
  created() {
    this.$set(this.defaultProps, 'multiple', this.IsMultiple);
  },
  methods: {
    visibleChange(val) {
      if (!val) return;
      this.setDisabled(this.options);
    },
    setDisabled(options) {
      options.forEach(item => {
        this.$set(item, 'disabled', this.disabled.includes(item.id));
        if (item.children) {
          this.setDisabled(item.children);
        }
      });
    },
    handleChange(value) {
      if (this.IsMultipleFatherRadio) {
        // 1.获取所有选中项
        let checkedNodes = this.$refs['custom-cascader'].getCheckedNodes();
        // 2.过滤上次已选项
        checkedNodes = checkedNodes.filter(item => !this.prevVal.some(prevItem => prevItem.includes(item.value)));
        // 3.判断当前已选项中是否存在父节点
        const checkedParent = checkedNodes.some(item => item.children && item.children.length);
        if (checkedParent) {
          // 4.存在：清空上次选中
          value = value.filter(item => !this.prevVal.includes(item));
        } else {
          // 5.不存在 && 当前选中项和之前的存在不一样的label开头的（即属于不同父级），用当前选项覆盖之前选中项
          if (checkedNodes.length && !this.prevVal.some(item => item.includes(checkedNodes[0].value.split('__')[0]))) {
            value = [checkedNodes[0].value];
          }
        }
      }
      this.$set(this.indicators, 'metrics_id', value);
      this.$emit('change');
    }
  }
};
exports.default = _default;