"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAutoRefresh = useAutoRefresh;
var _vue = require("vue");
function useAutoRefresh(fn) {
  let timer = null;
  const start = () => {
    fn();
    timer = setInterval(fn, 3000);
  };
  const stop = () => {
    clearInterval(timer);
  };
  (0, _vue.onBeforeUnmount)(() => {
    stop();
  });
  return {
    startAutoRefresh: start,
    stopAutoRefresh: stop
  };
}