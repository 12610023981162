"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-context-box"
  }, [_c("div", {
    staticClass: "rule-serial-number"
  }, [_vm._v(_vm._s(_vm.ruleGroupIndex + 1) + "B")]), _c("div", {
    staticClass: "rule-box-content"
  }, [_c("div", {
    staticClass: "rule-speak-item"
  }, [_c("div", {
    staticClass: "label-text"
  }, [_vm._v("发言角色")]), _c("div", {
    staticClass: "speak-role-row"
  }, [_c("el-select", {
    staticClass: "select-width",
    model: {
      value: _vm.contextItem.apply_to,
      callback: function ($$v) {
        _vm.$set(_vm.contextItem, "apply_to", $$v);
      },
      expression: "contextItem.apply_to"
    }
  }, _vm._l(_vm.speakingRoleOption, function (role) {
    return _c("el-option", {
      key: role.value,
      attrs: {
        label: role.label,
        value: role.value
      }
    });
  }), 1), _c("div", {
    staticClass: "delete-context-rule",
    on: {
      click: _vm.handleDeleteContextRule
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })])], 1)]), _c("div", {
    staticClass: "rule-basic-include"
  }, [_vm._l(_vm.contextItem.include, function (includeItem, includeIndex) {
    return _c("div", {
      key: includeIndex,
      class: ["rule-include-item", {
        "no-first-rule-include-item": includeIndex !== 0
      }]
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: includeIndex === 0,
        expression: "includeIndex === 0"
      }],
      staticClass: "label-text"
    }, [_vm._v("包含")]), _c("el-tooltip", {
      attrs: {
        content: _vm.getItemTypeTooltip(includeItem.type),
        placement: "top",
        "open-delay": 500,
        enterable: false
      }
    }, [_c("el-select", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: includeIndex === 0,
        expression: "includeIndex === 0"
      }],
      staticClass: "select-width",
      on: {
        change: function ($event) {
          return _vm.handleIncludeTypeChange(_vm.contextItem.include, includeItem);
        }
      },
      model: {
        value: includeItem.type,
        callback: function ($$v) {
          _vm.$set(includeItem, "type", $$v);
        },
        expression: "includeItem.type"
      }
    }, _vm._l(_vm.typeOptions, function (ruleType) {
      return _c("el-option", {
        key: ruleType.value,
        attrs: {
          label: ruleType.label,
          value: ruleType.value
        }
      });
    }), 1)], 1), _c("el-form-item", {
      attrs: {
        prop: "tracker_groups." + _vm.ruleGroupIndex + ".context." + _vm.contextIndex + ".include." + includeIndex + ".value",
        rules: {
          required: true,
          validator: _vm.validateArrIsEmpty,
          trigger: "change"
        }
      }
    }, [includeItem.type === "keywords" ? _c("div", {
      staticClass: "include-content"
    }, [_c("mgv-select", {
      staticClass: "select-input",
      attrs: {
        "popper-class": "select-input-dropdown",
        "popper-append-to-body": false,
        visible: false,
        multiple: "",
        filterable: "",
        "allow-create": "",
        "default-first-option": "",
        placeholder: "可输入多个关键词，使用回车分隔，词之间为“或”关系"
      },
      model: {
        value: includeItem.value,
        callback: function ($$v) {
          _vm.$set(includeItem, "value", $$v);
        },
        expression: "includeItem.value"
      }
    }), _vm.contextItem.include && _vm.contextItem.include.length > 1 ? _c("div", {
      staticClass: "delete-include-btn",
      on: {
        click: function ($event) {
          return _vm.handleDeleteIncludeItem(_vm.contextItem.include, includeIndex);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-circle-close"
    })]) : _vm._e()], 1) : _vm._e(), includeItem.type === "regex" ? _c("el-input", {
      attrs: {
        type: "textarea",
        placeholder: "请填写正则表达式"
      },
      model: {
        value: includeItem.value[0],
        callback: function ($$v) {
          _vm.$set(includeItem.value, 0, $$v);
        },
        expression: "includeItem.value[0]"
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.contextItem.include && _vm.contextItem.include.length && _vm.contextItem.include[0].type === "keywords" ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "add-rule-include-item-btn",
    attrs: {
      type: "text",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.contextItem.include.push({
          type: "keywords",
          value: []
        });
      }
    }
  }, [_vm._v("且")]) : _vm._e()], 2), _c("div", {
    staticClass: "rule-basic-include"
  }, [_vm._l(_vm.contextItem.exclude, function (excludeItem, excludeIndex) {
    return _c("div", {
      key: "exclude" + excludeIndex,
      class: ["rule-include-item", {
        "no-first-rule-include-item": excludeIndex !== 0
      }]
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: excludeIndex === 0,
        expression: "excludeIndex === 0"
      }],
      staticClass: "label-text"
    }, [_vm._v("不包含")]), _c("el-tooltip", {
      attrs: {
        content: _vm.getItemTypeTooltip(excludeItem.type),
        placement: "top",
        "open-delay": 500,
        enterable: false
      }
    }, [_c("el-select", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: excludeIndex === 0,
        expression: "excludeIndex === 0"
      }],
      staticClass: "select-width",
      on: {
        change: function ($event) {
          return _vm.handleIncludeTypeChange(_vm.contextItem.exclude, excludeItem);
        }
      },
      model: {
        value: excludeItem.type,
        callback: function ($$v) {
          _vm.$set(excludeItem, "type", $$v);
        },
        expression: "excludeItem.type"
      }
    }, _vm._l(_vm.typeOptions, function (ruleType) {
      return _c("el-option", {
        key: ruleType.value,
        attrs: {
          label: ruleType.label,
          value: ruleType.value
        }
      });
    }), 1)], 1), _c("el-form-item", [excludeItem.type === "keywords" ? _c("div", {
      staticClass: "include-content"
    }, [_c("mgv-select", {
      staticClass: "select-input",
      attrs: {
        "popper-class": "select-input-dropdown",
        "popper-append-to-body": false,
        multiple: "",
        filterable: "",
        "allow-create": "",
        "default-first-option": "",
        placeholder: "可输入多个关键词，使用回车分隔，词之间为“或”关系"
      },
      model: {
        value: excludeItem.value,
        callback: function ($$v) {
          _vm.$set(excludeItem, "value", $$v);
        },
        expression: "excludeItem.value"
      }
    }), _vm.contextItem.exclude && _vm.contextItem.exclude.length > 1 ? _c("div", {
      staticClass: "delete-include-btn",
      on: {
        click: function ($event) {
          return _vm.handleDeleteIncludeItem(_vm.contextItem.exclude, excludeIndex);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-circle-close"
    })]) : _vm._e()], 1) : _vm._e(), excludeItem.type === "regex" ? _c("el-input", {
      attrs: {
        type: "textarea",
        placeholder: "请填写正则表达式"
      },
      model: {
        value: excludeItem.value[0],
        callback: function ($$v) {
          _vm.$set(excludeItem.value, 0, $$v);
        },
        expression: "excludeItem.value[0]"
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.contextItem.exclude && _vm.contextItem.exclude.length && _vm.contextItem.exclude[0].type === "keywords" ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "add-rule-include-item-btn",
    attrs: {
      type: "text",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.contextItem.exclude.push({
          type: "keywords",
          value: []
        });
      }
    }
  }, [_vm._v("且")]) : _vm._e()], 2), _c("div", {
    staticClass: "rule-check-scope"
  }, [_vm._m(0), _c("div", {
    staticClass: "trigger-rule-content"
  }, [_vm._v(" 规则 "), _c("span", {
    staticClass: "rule-serial-number"
  }, [_vm._v(_vm._s(_vm.ruleGroupIndex + 1) + "A")]), _vm._v(" 触发句子的 ")]), _c("el-select", {
    staticClass: "select-width",
    model: {
      value: _vm.contextItem.context_type,
      callback: function ($$v) {
        _vm.$set(_vm.contextItem, "context_type", $$v);
      },
      expression: "contextItem.context_type"
    }
  }, _vm._l(_vm.contextTypeOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        value: item.value,
        label: item.label
      }
    });
  }), 1), _c("el-form-item", {
    attrs: {
      prop: "tracker_groups." + _vm.ruleGroupIndex + ".context." + _vm.contextIndex + ".context_span",
      rules: {
        required: true,
        message: "请输入句子限制",
        trigger: "change"
      }
    }
  }, [_c("el-input-number", {
    staticClass: "select-width",
    attrs: {
      min: 1,
      max: 100,
      controls: false
    },
    model: {
      value: _vm.contextItem.context_span,
      callback: function ($$v) {
        _vm.$set(_vm.contextItem, "context_span", $$v);
      },
      expression: "contextItem.context_span"
    }
  }), _c("span", [_vm._v("句内")])], 1)], 1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label-text"
  }, [_c("span", [_vm._v("*")]), _vm._v("检测范围")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;