"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationFilter = _interopRequireDefault(require("@/views/coaching/aiCourseEdit/components/conversationFilter.vue"));
var _conversationItem = _interopRequireDefault(require("@/views/coaching/aiCourseEdit/components/conversationItem.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _coachingCenter = require("@/api/coachingCenter");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationListDialog',
  components: {
    ConversationFilter: _conversationFilter.default,
    ConversationItem: _conversationItem.default,
    NoData: _NoData.default
  },
  data() {
    return {
      searchVal: '',
      loading: false,
      params: {
        page: 0,
        size: 10
      },
      conversationList: [],
      hasMore: true,
      conversationTotal: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    dialogVisible(val) {
      val && this.initFilters();
    },
    'workspaceInfo.id': function () {
      this.initFilters();
    }
  },
  methods: {
    checkedConversation(val) {
      if (val.duration > 60 * 60) {
        this.$message({
          message: '仅支持添加60min以内的会话',
          type: 'warning'
        });
        return;
      }
      this.$emit('checkedConversation', val);
    },
    initFilters() {
      var _this$$refs$conversat;
      this.conversationList = [];
      // 通过可选链阻止掉首次进入和watch-deep同时触发请求问题
      (_this$$refs$conversat = this.$refs['conversation-filter']) === null || _this$$refs$conversat === void 0 ? void 0 : _this$$refs$conversat.init();
    },
    filterChange(val) {
      this.params = {
        ...this.params,
        ...val
      };
      this.params.host_ids = this.params.host_ids.map(item => String(item));
      this.initGetData();
    },
    initGetData() {
      this.hasMore = true;
      this.params.page = 0;
      this.conversationList = [];
      this.getConversationList();
    },
    async getConversationList() {
      if (!this.params.account_ids || !this.hasMore) return;
      this.params.page++;
      this.loading = true;
      const date = (0, _commonFunc.deepClone)(this.params.date);
      if (this.params.date.range.length) {
        date.range = (0, _timezone.datePickerParseToZone)(date.range);
      }
      const res = await (0, _coachingCenter.getConversationList)({
        ...this.params,
        date,
        conv_name: this.searchVal,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          total_count,
          list
        } = res.results;
        this.conversationList = this.conversationList.concat(list || []);
        this.hasMore = total_count > this.conversationList.length;
      }
    }
  }
};
exports.default = _default;