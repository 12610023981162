"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
var _default = {
  name: "moveAccountSeaDialog",
  props: {
    moveAccountSeaDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelPosition: "top",
      formData: {
        id: [],
        refund_reason: "",
        add_reason: ""
      },
      formDataRules: {
        refund_reason: [{
          required: true,
          message: "请选择退回原因",
          trigger: "change"
        }]
      },
      reasonOption: ["电话不存在", "没有购买需求", "已经购买其他产品", "价格超过预算", "其他"]
    };
  },
  watch: {
    moveAccountSeaDialogVisible(val) {
      if (val) {
        this.formData.add_reason = "";
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit("closeDialog");
      this.resetForm();
    },
    async postMoveAccountSea(data) {
      const res = await (0, _client.postMoveAccountSea)(data);
      if (res.code === 200) {
        this.$message.success("移入公海成功");
        setTimeout(() => {
          this.$emit("freshClientTable");
          this.handleClose();
        }, 500);
      }
    },
    resetForm() {
      this.$refs["moveAccountSeaForm"].resetFields();
    },
    submitMoveAccountSeaForm() {
      this.$refs["moveAccountSeaForm"].validate(valid => {
        if (valid) {
          this.postMoveAccountSea(this.formData);
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;