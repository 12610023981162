"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "department-and-member-tree"
  }, [_c("el-input", {
    staticClass: "member-filter",
    attrs: {
      placeholder: "搜索"
    },
    on: {
      input: _vm.getMember
    },
    model: {
      value: _vm.memberFilter,
      callback: function ($$v) {
        _vm.memberFilter = $$v;
      },
      expression: "memberFilter"
    }
  }), _c("div", {
    staticClass: "member-container"
  }, [_vm.memberFilter ? _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.searchLoading,
      expression: "searchLoading"
    }],
    staticClass: "filter-member-list"
  }, _vm._l(_vm.memberOptions, function (item) {
    return _c("li", {
      key: item.id,
      staticClass: "member-item",
      class: {
        "member-selected": _vm.filterMemberIsSelected(item)
      },
      on: {
        click: function ($event) {
          return _vm.handleMemberFilterChange(item);
        }
      }
    }, [_c("div", [_vm._v(_vm._s(item.name))]), _vm.filterMemberIsSelected(item) ? _c("i", {
      staticClass: "el-icon-check"
    }) : _vm._e()]);
  }), 0) : _vm._e(), _c("lazy-tree", _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.memberFilter,
      expression: "!memberFilter"
    }],
    ref: "tree",
    attrs: {
      "is-task-center": "",
      "is-checked-root": _vm.isCheckedRoot,
      "request-function": _vm.requestFunction,
      params: _vm.params,
      "backfill-checked-ids": _vm.backfillIds
    },
    on: {
      filterChange: _vm.handleChange
    }
  }, "lazy-tree", _vm.$attrs, false))], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;