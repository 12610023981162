"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-library"
  }, [_c("p", {
    staticClass: "bold-title"
  }, [_vm._v("销售回答")]), _c("tricks-library-filter", {
    attrs: {
      layout: _vm.layout,
      "is-filter-added-status": ""
    },
    on: {
      filtersChange: _vm.handleFiltersChange
    }
  }), _c("div", {
    staticClass: "date-sort"
  }, [_c("sort-caret", {
    attrs: {
      label: "时间"
    },
    on: {
      sortCallBack: _vm.sortDate
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    class: ["conv-list", {
      "no-content": !_vm.loading && !_vm.convList.length
    }]
  }, [_c("mg-infinite-scroll", {
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, _vm._l(_vm.convList, function (item, index) {
    return _c("tricks-library-conv-card", {
      key: index,
      attrs: {
        "conv-item": item,
        "origin-added-list": _vm.originAddedList,
        "added-list": _vm.addedList
      },
      on: {
        addConv: function ($event) {
          return _vm.handleAddConv(index);
        }
      }
    });
  }), 1), !_vm.loading && !_vm.convList.length ? _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-data",
      tips: "这里没有话术"
    }
  }) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;