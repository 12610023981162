"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "select-viewer-type"
  }, _vm._l(_vm.typeOptions, function (item) {
    return _c("li", {
      key: item.value,
      staticClass: "viewer-type-item",
      on: {
        click: function ($event) {
          return _vm.selectActiveType(item.value);
        }
      }
    }, [_c("i", {
      class: ["iconfont", item.icon]
    }), _c("div", {
      staticClass: "right-info"
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "describe"
    }, [_vm._v(_vm._s(item.describe))])]), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.value && _vm.value === item.value,
        expression: "value && value === item.value"
      }],
      staticClass: "iconfont icon-check"
    })]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;