"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _SaveTimeTip = _interopRequireDefault(require("@/components/NoteEditor/components/SaveTimeTip"));
var _NoteTemplate = _interopRequireDefault(require("@/components/NoteTemplate"));
var _CommentsArea = _interopRequireDefault(require("@/components/Detail/CommentsArea"));
var _CorrectKeyEventHistory = _interopRequireDefault(require("@/views/convDetail/components/CorrectKeyEventHistory"));
var _CorrectTextContainer = _interopRequireDefault(require("@/views/convDetail/CorrectTextContainer"));
var _ComplianceDetail = _interopRequireDefault(require("@/views/compliance/workspace/components/ComplianceDetail"));
var _ConversationInfoContainer = _interopRequireDefault(require("@/views/convDetail/components/ConversationInfoContainer"));
var _AICoachingContent = _interopRequireDefault(require("@/views/convDetail/components/AICoachingContent"));
var _ConversationInfoTitle = _interopRequireDefault(require("@/views/convDetail/components/ConversationInfoTitle"));
var _CorrectFlipRoleHistory = _interopRequireDefault(require("@/views/convDetail/components/CorrectFlipRoleHistory.vue"));
var _SummaryResultDisplay = _interopRequireDefault(require("@/views/convDetail/components/SummaryResultDisplay"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentDrawer',
  components: {
    CorrectFlipRoleHistory: _CorrectFlipRoleHistory.default,
    CommentsArea: _CommentsArea.default,
    NoteEditor: _NoteEditor.default,
    SaveTimeTip: _SaveTimeTip.default,
    NoteTemplate: _NoteTemplate.default,
    CorrectKeyEventHistory: _CorrectKeyEventHistory.default,
    CorrectTextContainer: _CorrectTextContainer.default,
    ComplianceDetail: _ComplianceDetail.default,
    ConversationInfoContainer: _ConversationInfoContainer.default,
    AICoachingContent: _AICoachingContent.default,
    ConversationInfoTitle: _ConversationInfoTitle.default,
    SummaryResultDisplay: _SummaryResultDisplay.default
  },
  inject: ['getAISummaryDetail', 'getExtractInfoDetail'],
  props: {
    rightBarActive: {
      type: String,
      default: ''
    },
    convId: {
      type: [Number, String],
      default: ''
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    mediaType: {
      type: String,
      default: 'audio'
    },
    conversationDetailInfo: {
      type: Object,
      default: () => {}
    },
    onlyEvaluation: {
      type: Boolean,
      default: false
    },
    complianceStatus: {
      type: Number,
      default: 0
    },
    departmentManagerInfo: {
      type: Object,
      default: () => ({})
    },
    commentType: {
      type: String,
      default: 'conversation'
    }
  },
  data() {
    return {
      noteSavedTime: '',
      noteData: {},
      noteIsEmpty: false
    };
  },
  methods: {
    openModifyConvInfoHistoryDrawer() {
      this.$emit('openModifyConvInfoHistoryDrawer');
    },
    getRightDialogTitle() {
      switch (this.rightBarActive) {
        case 'comments':
          return '评论';
        case 'notes':
          return '私人笔记';
        case 'correctKeyEventHistory':
          return '关键事件修正历史';
        case 'correctFlipRoleHistory':
          return '翻转角色修正历史';
        case 'correctTextContainer':
          return '转录文字修正历史';
        case 'compliance':
          return '任务信息';
        case 'convInfo':
          return '会话信息';
        case 'evaluation':
          return '评价单';
        case 'AICoaching':
          return '智能辅导';
        case 'AISummary':
          return '智能纪要';
        case 'extractInfo':
          return '信息提取';
      }
    },
    manualExecution() {
      this.$emit('manualExecution');
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    handleNoteChange(isEmpty) {
      this.noteIsEmpty = isEmpty;
    },
    handleNoteSaved(time) {
      this.noteSavedTime = time;
    },
    handleSaveNewTemplate(data) {
      this.noteData = data;
    },
    handleShowNote(data) {
      this.$refs['note-editor'].destroy();
      this.$refs['note-editor'].originData = data;
      this.$refs['note-editor'].init();
      // 保存一下
      this.$refs['note-editor'].onChange();
    },
    getUnTeachList() {
      this.$emit('getUnTeachList');
    },
    applyAICoachingSuccess() {
      this.$emit('applyAICoachingSuccess');
    }
  }
};
exports.default = _default;