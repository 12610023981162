"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
var _MemberInfoTag = _interopRequireDefault(require("./MemberInfoTag"));
var _taskCenter = require("@/api/taskCenter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const GOENTITYTEXT = {
  coaching: (0, _i18n.t)('coaching.goToTraining')
};
const ICONMAP = {
  coaching: 'training'
};
var _default = {
  name: 'TaskCard',
  components: {
    VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default,
    MoreOperations: _MoreOperations.default
  },
  props: {
    cardItem: {
      type: Object,
      default: () => ({})
    },
    currentMenu: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      MemberInfoTag: _MemberInfoTag.default,
      operateList: [{
        command: 'delete',
        label: '删除'
      }],
      ICONMAP: ICONMAP,
      allTaskUsersList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId']),
    getCompleteDisable() {
      // 选择框禁止点击的逻辑判断：任务状态是已完成或者已截止。如果任务状态是0，则要看是否在已完成tab下，tab下代表个人已完成。另外如果是主体创建的任务他是不可以在任务中心点击完成的，也是被禁用的。团队视图下禁止操作
      return [1, 2].includes(this.cardItem.task_info.status) || this.currentMenu === 'complete' || this.cardItem.task_info.complete_disable || this.currentMenu === 'manageView';
    },
    getIsDone() {
      // 是否要显示删除线： 只要是在已完成的都有删除线或者团队视图下完成/已截止
      return this.currentMenu === 'complete' || this.currentMenu === 'manageView' && [1, 2].includes(this.cardItem.task_info.status);
    },
    getCheckValue() {
      // 是否打钩：在已完成tab下且没有已截止的情况下打钩或者团队视图下完成
      return this.currentMenu === 'complete' && !this.cardItem.task_info.has_close || this.currentMenu === 'manageView' && this.cardItem.task_info.status === 1;
    }
  },
  methods: {
    async getAllTaskUsers(startLoading, endLoading) {
      startLoading();
      const res = await (0, _taskCenter.getAllTaskUsers)({
        task_id: this.cardItem.task_info.task_id
      }).finally(() => {
        endLoading();
      });
      if (res.code === 20000) {
        this.allTaskUsersList = res.results.list;
      }
    },
    goTaskDetail() {
      this.$emit('goTaskDetail', this.cardItem);
    },
    handleIsDoneChange() {
      if (this.getCompleteDisable) {
        this.$emit('goTaskDetail', this.cardItem);
        return;
      }
      const task_id = this.cardItem.task_info.task_id;
      // 弹框提醒：我分配的任务 || 我的任务中（我分配的多人任务且其他人中有未完成的）
      const isMultTaskUsers = this.currentMenu === 'mytask' && this.cardItem.create_user.id === this.userId && this.cardItem.task_users.length > 1 && this.cardItem.task_users.filter(item => item.id !== this.userId).some(_ref => {
        let {
          status
        } = _ref;
        return !status;
      });
      if (isMultTaskUsers || this.currentMenu === 'mydistribute') {
        this.$confirm('还有执行者未完成这个任务，是否完成此任务?', '完成任务', {
          confirmButtonText: '确认完成',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: async (action, ctx, close) => {
            if (action !== 'confirm') {
              close();
              return;
            }
            ctx.confirmButtonLoading = true;
            try {
              setTimeout(async () => {
                await this.completeTask(task_id);
              }, 400);
              close();
            } finally {
              ctx.confirmButtonLoading = false;
            }
          }
        }).finally(() => {});
      } else {
        setTimeout(async () => {
          await this.completeTask(task_id);
        }, 400);
      }
    },
    async completeTask(task_id) {
      const params = {
        task_id,
        status: 1
      };
      const res = await (0, _taskCenter.completeTask)(params);
      if (res.code === 20000) {
        this.$message({
          message: '已完成',
          type: 'success',
          center: true
        });
        this.$emit('operateTask', task_id);
      }
    },
    getcontentIconClass(type) {
      return [`iconfont icon-${ICONMAP[type]}`, type];
    },
    getGoEntityText(type) {
      return GOENTITYTEXT[type];
    },
    goToEntityDetail(info) {
      if (!info.router_name) return;
      const path = _routeMap.ROUTES[info.router_name] ? _routeMap.ROUTES[info.router_name] : '/404';
      const query = info.router_params;
      const routeData = this.$router.resolve({
        path,
        query
      });
      window.open(routeData.href, '_blank');
    },
    handleCommand(item, command) {
      if (command === 'delete') {
        this.handleDelete(item);
      }
    },
    handleDelete(_ref2) {
      let {
        task_id
      } = _ref2;
      this.$confirm('确定删除该任务？', '删除', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        customClass: 'delete-todo-item',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        // 删除接口
        this.deleteTask(task_id);
      });
    },
    async deleteTask(task_id) {
      const res = await (0, _taskCenter.deleteTask)({
        id: task_id
      });
      if (res.code === 20000) {
        this.$message({
          message: '已删除',
          type: 'success',
          center: true
        });
        this.$emit('operateTask', task_id);
      }
    },
    getTitleHtml(titleInfo) {
      let html = '';
      titleInfo.forEach(item => {
        if (item.router_name) {
          const router = this.$router.resolve({
            path: _routeMap.ROUTES[item.router_name],
            query: Object.assign(item.router_params, {
              openDetailDrawer: true
            })
          });
          html += `<a href="${router.href}" target="_blank" contenteditable="false"
           onClick="event.stopPropagation();">${item.text}</a>&nbsp;`;
        } else {
          html += item.text;
        }
      });
      return html;
    }
  }
};
exports.default = _default;