"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-header"
  }, [_c("div", {
    staticClass: "header-name"
  }, [!_vm.isTitle ? _c("span", {
    staticClass: "form-item-order"
  }, [_vm._v(_vm._s(_setup.props.index < 10 ? "0" + _setup.props.index : _setup.props.index))]) : _vm._e(), _setup.props.required ? _c("span", {
    staticClass: "required-dot"
  }, [_vm._v("*")]) : _vm._e(), _c("span", {
    staticClass: "header-name-text"
  }, [_vm._v(_vm._s(_setup.props.name))])]), _c("p", {
    staticClass: "header-description"
  }, [_vm._v(_vm._s(_setup.props.description))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;