"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "deal-tabs-drawer",
    attrs: {
      id: "deal-tabs-drawer",
      visible: _vm.visible,
      modal: false,
      size: "600px",
      stretch: "",
      "before-close": _vm.closeDrawer,
      "wrapper-closable": false,
      "destroy-on-close": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("header", {
    staticClass: "drawer-header",
    on: {
      click: _vm.handleTitleClick
    }
  }, [_vm._v(" " + _vm._s(!_vm.currentRow ? "" : _vm.currentRow.deal_name) + " ")])]), _c("div", {
    staticClass: "member-info"
  }, [_c("div", [_c("div", {
    staticClass: "member-info-name"
  }, [_vm._v("客户")]), _c("div", {
    staticClass: "customer-name",
    class: {
      "has-customer-permission": _vm.hasCustomerPermission
    },
    on: {
      click: function ($event) {
        return _vm.goCustomerPage(_vm.currentRow.account_id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentRow.customer_name) + " ")])]), _vm.currentRow.follow_up_by ? _c("div", {
    staticClass: "follow-up-info"
  }, [_c("div", {
    staticClass: "member-info-name"
  }, [_vm._v("跟进人")]), _c("div", {
    staticClass: "follow-up-by"
  }, [_c("image-avatar", {
    attrs: {
      name: _vm.imageAvatar
    }
  }), _c("span", [_vm._v(_vm._s(_vm.currentRow.follow_up_by.name))])], 1)]) : _vm._e()]), _c("el-tabs", {
    staticClass: "deal-tabs",
    attrs: {
      value: _vm.activeTabName
    },
    on: {
      "tab-click": _vm.toggleTab
    }
  }, _vm._l(_vm.tabsHeaders, function (_ref) {
    let {
      name,
      field_name
    } = _ref;
    return _c("el-tab-pane", {
      key: field_name,
      attrs: {
        label: name,
        name: field_name
      }
    }, [field_name === _vm.activeTabName ? _c(`deal-${_vm.getComponentsName(field_name)}-tab-content`, {
      tag: "component",
      attrs: {
        "active-tab-name": _vm.activeTabName,
        "current-row": _vm.currentRow,
        "deal-id": _vm.dealId,
        "active-conv-date": _vm.activeConvDate,
        "current-field-name": _vm.currentFieldName,
        "no-event-click": ""
      },
      on: {
        scrollToActiveConv: _vm.scrollToActiveConv,
        loadMore: _vm.loadMore
      }
    }) : _vm._e()], 1);
  }), 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;