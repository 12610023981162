"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GradeProgress = _interopRequireDefault(require("./GradeProgress"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'GradeProgressPopover',
  components: {
    GradeProgress: _GradeProgress.default
  },
  props: {
    convId: {
      type: Number,
      default: 0
    },
    scoreRule: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    percentage() {
      return parseInt(this.scoreRule.score / (this.scoreRule.total_score || 100) * 100);
    }
  },
  methods: {
    goScoreDetail() {
      this.$emit('goScoreDetail');
    },
    getHitIcon(mode, score) {
      return `iconfont ${score ? mode === 'increase' ? 'icon-check' : 'icon-close' : 'icon-circle-dash'}`;
    },
    getTotalScore(score) {
      return JSON.parse(score).score;
    },
    getScoreTooltip(score, score_type) {
      const score_ext = JSON.parse(score);
      let text = '';
      score_ext.items.forEach(item => {
        const prefixText = score_type === 2 ? `第${item.gte}-${item.lte}次，每次` : `任意${item.hits}项`;
        text += `${prefixText}${score_ext.mode === 'increase' ? '加' : '减'}${item.score}分；`;
      });
      text += `最多${score_ext.mode === 'increase' ? '加' : '减'}${score_ext.score}分`;
      return text;
    }
  }
};
exports.default = _default;