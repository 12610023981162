"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "precision-detail-table"
  }, [_c("el-table", {
    attrs: {
      data: _vm.localTableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header"
        }, [_vm._v("关键事件")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "event"
        }, [_c("div", [_c("i", {
          class: ["iconfont", {
            "icon-key-event": row.type === 1
          }, {
            "icon-price-tag": row.type === 2
          }]
        })]), _c("div", {
          staticClass: "event-name",
          on: {
            click: function ($event) {
              return _vm.openEventAnnotationDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "精确率"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header precision-header"
        }, [_vm._v("精确率")])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.selectedVersions, function (item, _index) {
    return _c("el-table-column", {
      key: "precision" + item + _index,
      attrs: {
        prop: "precision"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            class: ["header", {
              "left-border": _index === 0
            }, {
              "right-border": _index === _vm.selectedVersions.length - 1
            }]
          }, [_vm._v(" 版本" + _vm._s(item) + " ")])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            class: ["column-cell", {
              "left-border": _index === 0
            }, {
              "right-border": _index === _vm.selectedVersions.length - 1
            }, {
              min: row.minPrecision.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "precision"
              }) !== -1
            }, {
              max: row.maxPrecision.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "precision"
              }) !== -1
            }]
          }, [_vm._v(" " + _vm._s(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "precision"
          }) === -1 ? "-" : `${Math.round(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "precision"
          }) * 10000) / 100}%`) + " ")])];
        }
      }], null, true)
    });
  }), 1), _c("el-table-column", {
    attrs: {
      label: "召回率"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header"
        }, [_vm._v("召回率")])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.selectedVersions, function (item) {
    return _c("el-table-column", {
      key: "recall" + item,
      attrs: {
        prop: "recall"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            class: ["header"]
          }, [_vm._v("版本" + _vm._s(item))])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            class: ["column-cell", {
              min: row.minRecall.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "recall"
              }) !== -1
            }, {
              max: row.maxRecall.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "recall"
              }) !== -1
            }]
          }, [_vm._v(" " + _vm._s(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "recall"
          }) === -1 ? "-" : `${Math.round(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "recall"
          }) * 10000) / 100}%`) + " ")])];
        }
      }], null, true)
    });
  }), 1), _c("el-table-column", {
    attrs: {
      label: "F1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "header"
        }, [_vm._v("f1")])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.selectedVersions, function (item, index) {
    return _c("el-table-column", {
      key: "f1" + item + index,
      attrs: {
        prop: "f1"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            class: ["header", {
              "left-border": index === 0
            }, {
              "right-border": index === _vm.selectedVersions.length - 1
            }]
          }, [_vm._v(" 版本" + _vm._s(item) + " ")])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("div", {
            class: ["column-cell", {
              "left-border": index === 0
            }, {
              "right-border": index === _vm.selectedVersions.length - 1
            }, {
              min: row.minF1.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "f1"
              }) !== -1
            }, {
              max: row.maxF1.includes(item) && _vm.getRowValue({
                rowInfo: row,
                version: item,
                attr: "f1"
              }) !== -1
            }]
          }, [_vm._v(" " + _vm._s(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "f1"
          }) === -1 ? "-" : `${Math.round(_vm.getRowValue({
            rowInfo: row,
            version: item,
            attr: "f1"
          }) * 10000) / 100}%`) + " ")])];
        }
      }], null, true)
    });
  }), 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;