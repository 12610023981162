"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "MgvProgressLoading",
  props: {
    duration: {
      type: Number,
      default: 150
    },
    text: String,
    color: {
      type: String,
      default: "#4461EC"
    }
  },
  data() {
    return {
      percentage: 5,
      timer: null
    };
  },
  computed: {},
  mounted() {
    this.setTimer();
  },
  methods: {
    setTimer() {
      this.timer = setInterval(() => {
        if (this.percentage >= 0 && this.percentage < 98) {
          this.percentage = this.percentage + 1;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    }
  }
};
exports.default = _default;