"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.saveTime ? _c("div", {
    staticClass: "save-time-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-circle-check"
  }), _c("span", [_vm._v("最近保存" + _vm._s(_vm.saveTime))])]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;