"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "intelligent-extract-dialog",
    attrs: {
      visible: _vm.visible,
      width: "800px",
      "before-close": _setup.handleClose,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.showKnowledgePoint,
      expression: "!showKnowledgePoint"
    }],
    staticClass: "upload-step"
  }, [_c("header", [_c("i", {
    staticClass: "iconfont icon-magic-1"
  }), _c("span", [_vm._v("智能提取知识点")]), _c("MgvButton", {
    attrs: {
      type: "text",
      color: "info",
      icon: "icon-close",
      square: "",
      size: "small"
    },
    on: {
      click: _setup.handleClose
    }
  })], 1), _c(_setup.IntelligentExtractUploadMedia, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.showKnowledgePoint,
      expression: "!showKnowledgePoint"
    }],
    ref: "intelligentExtractUploadMediaRef",
    on: {
      changeFile: _setup.changeFile
    }
  }), _c("div", {
    staticClass: "footer"
  }, [_c("MgvButton", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.showKnowledgePoint,
      expression: "!showKnowledgePoint"
    }],
    attrs: {
      icon: "icon-magic-1",
      disabled: _setup.extractKnowledgePointBtnDisabled
    },
    on: {
      click: _setup.onExtractKnowledgePoint
    }
  }, [_vm._v("提取知识点")])], 1)], 1), _setup.showKnowledgePoint ? _c(_setup.KnowledgePointExtract, {
    ref: "knowledgePointExtractRef",
    attrs: {
      "catalog-id": _vm.catalogId
    },
    on: {
      goBack: _setup.goBack,
      close: _setup.handleClose,
      refreshKnowledgeList: _setup.refreshKnowledgeList,
      reextract: _setup.onExtractKnowledgePoint
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;