"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _elementUi = require("element-ui");
var _vue = require("vue");
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'BindTelOrEmailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // operate 分为 bind or modify
    operate: {
      type: String,
      default: ''
    },
    // type 分为 tel or email
    type: {
      type: String,
      default: ''
    },
    tel: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  },
  emits: ['update:visible', 'updateEmailAccountSuccess', 'updateTelAccountSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;

    // 绑定手机号流程：1. 验证当前已登录邮箱 -> 2. 验证手机号
    // 绑定邮箱流程：1. 验证当前已登录手机号 -> 2. 验证邮箱
    // 换绑手机号流程：1. 验证当前已登录手机号 -> 2. 验证新手机号
    // 换绑邮箱流程：1. 验证当前已登录邮箱 -> 2. 验证新邮箱

    const handleClose = () => {
      emit('update:visible', false);
    };
    const isFirstStep = (0, _vue.ref)(true);
    const codeInputRef = (0, _vue.ref)(null);
    const codeFormRef = (0, _vue.ref)(null);
    const codeForm = (0, _vue.reactive)({
      code: '',
      tel: '',
      email: ''
    });
    const codeRules = {
      tel: [{
        required: true,
        validator: _validate.telValidator,
        trigger: 'change'
      }],
      email: [{
        required: true,
        validator: _validate.emailValidator,
        trigger: 'change'
      }],
      code: [{
        required: true,
        validator: _validate.codeValidator,
        trigger: 'blur'
      }]
    };
    const dialogTitle = (0, _vue.computed)(() => {
      return () => {
        switch (props.operate) {
          case 'bind':
            if (props.type === 'email') {
              if (isFirstStep.value) return (0, _i18n.t)('management.验证当前已登录手机号');
              return (0, _i18n.t)('management.绑定新邮箱');
            } else if (props.type === 'tel') {
              if (isFirstStep.value) return (0, _i18n.t)('management.验证当前已登录邮箱');
              return (0, _i18n.t)('management.绑定新手机号');
            }
            break;
          case 'modify':
            if (props.type === 'email') {
              if (isFirstStep.value) return (0, _i18n.t)('management.验证当前已登录邮箱');
              return (0, _i18n.t)('management.绑定新邮箱');
            } else if (props.type === 'tel') {
              if (isFirstStep.value) return (0, _i18n.t)('management.验证当前已登录手机号');
              return (0, _i18n.t)('management.绑定新手机号');
            }
            break;
        }
      };
    });

    // 给谁发送验证码 tel or email
    const sendingDestination = (0, _vue.computed)(() => {
      return () => {
        switch (props.operate) {
          case 'bind':
            if (isFirstStep.value) {
              if (props.type === 'email') return 'tel';
              return 'email';
            } else {
              return props.type;
            }
          case 'modify':
            return props.type;
        }
      };
    });
    const codeType = (0, _vue.computed)(() => {
      return () => {
        switch (props.operate) {
          case 'bind':
            if (props.type === 'email') {
              if (isFirstStep.value) return 'before_reset_tel';
              return 'reset';
            }
            if (props.type === 'tel') {
              if (isFirstStep.value) return 'before_reset';
              return 'reset_tel';
            }
            break;
          case 'modify':
            if (props.type === 'tel') {
              if (isFirstStep.value) return 'before_reset_tel';
              return 'reset_tel';
            }
            if (props.type === 'email') {
              if (isFirstStep.value) return 'before_reset';
              return 'reset';
            }
            break;
        }
      };
    });
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        codeForm.tel = props.tel;
        codeForm.email = props.email;
      }
    }, {
      immediate: true
    });
    const initCodeForm = () => {
      codeForm.tel = '';
      codeForm.email = '';
      codeForm.code = '';
    };
    const validFormField = prop => {
      let res;
      codeFormRef.value.validateField(prop, valid => {
        res = valid;
      });
      return res;
    };
    const sendCode = () => {
      const emailValid = validFormField('email');
      const telValid = validFormField('tel');
      const obj = {
        email: emailValid,
        tel: telValid
      };
      if (isFirstStep.value) {
        var _codeInputRef$value;
        codeInputRef === null || codeInputRef === void 0 ? void 0 : (_codeInputRef$value = codeInputRef.value) === null || _codeInputRef$value === void 0 ? void 0 : _codeInputRef$value.showTencentVerify();
      } else {
        if (!obj[props.type]) {
          var _codeInputRef$value2;
          codeInputRef === null || codeInputRef === void 0 ? void 0 : (_codeInputRef$value2 = codeInputRef.value) === null || _codeInputRef$value2 === void 0 ? void 0 : _codeInputRef$value2.showTencentVerify();
        }
      }
    };
    const checkEmailCode = async () => {
      const res = await (0, _user.checkEmailCaptcha)({
        code: codeForm.code,
        email: codeForm.email
      });
      if (res.code === 20000) {
        var _codeInputRef$value3;
        isFirstStep.value = false;
        // 重置发送验证码
        codeInputRef === null || codeInputRef === void 0 ? void 0 : (_codeInputRef$value3 = codeInputRef.value) === null || _codeInputRef$value3 === void 0 ? void 0 : _codeInputRef$value3.reset();
        initCodeForm();
      }
    };
    const bindEmail = async () => {
      const res = await (0, _user.updateLoginEmailAccount)({
        email: codeForm.email,
        code: codeForm.code
      });
      console.log(res, 'res');
      if (res.code === 20000) {
        if (props.operate === 'bind') {
          _elementUi.Message.success((0, _i18n.t)('management.绑定成功'));
        } else {
          _elementUi.Message.success((0, _i18n.t)('management.修改成功'));
        }
        console.log('走这儿');
        emit('updateEmailAccountSuccess');
        handleClose();
      }
    };
    const checkTelCode = async () => {
      const res = await (0, _user.checkOldTel)({
        code: codeForm.code,
        tel: codeForm.tel
      });
      if (res.code === 20000) {
        var _codeInputRef$value4;
        isFirstStep.value = false;
        // 重置发送验证码
        codeInputRef === null || codeInputRef === void 0 ? void 0 : (_codeInputRef$value4 = codeInputRef.value) === null || _codeInputRef$value4 === void 0 ? void 0 : _codeInputRef$value4.reset();
        initCodeForm();
      }
    };
    const bindTel = async () => {
      const res = await (0, _user.updateTelInfo)({
        code: codeForm.code,
        tel: codeForm.tel
      });
      if (res.code === 20000) {
        emit('updateTelAccountSuccess');
        if (props.operate === 'bind') {
          _elementUi.Message.success((0, _i18n.t)('management.绑定成功'));
        } else {
          _elementUi.Message.success((0, _i18n.t)('management.修改成功'));
        }
        handleClose();
      }
    };
    const handleCodeTelModify = () => {
      const codeValid = validFormField('code');
      const emailValid = validFormField('email');
      const telValid = validFormField('tel');
      switch (props.operate) {
        case 'bind':
          if (props.type === 'tel') {
            if (isFirstStep.value) {
              if (!codeValid) checkEmailCode();
            } else {
              if (!codeValid && !telValid) bindTel();
            }
          } else if (props.type === 'email') {
            if (isFirstStep.value) {
              if (!codeValid) checkTelCode();
            } else {
              if (!codeValid && !emailValid) bindEmail();
            }
          }
          break;
        case 'modify':
          if (props.type === 'tel') {
            if (isFirstStep.value) {
              if (!codeValid) checkTelCode();
            } else {
              if (!codeValid && !telValid) bindTel();
            }
          } else if (props.type === 'email') {
            if (isFirstStep.value) {
              if (!codeValid) checkEmailCode();
            } else {
              if (!codeValid && !emailValid) bindEmail();
            }
          }
          break;
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      handleClose,
      isFirstStep,
      codeInputRef,
      codeFormRef,
      codeForm,
      codeRules,
      dialogTitle,
      sendingDestination,
      codeType,
      initCodeForm,
      validFormField,
      sendCode,
      checkEmailCode,
      bindEmail,
      checkTelCode,
      bindTel,
      handleCodeTelModify,
      VerificationCodeInput: _VerificationCodeInput.default,
      t: _i18n.t
    };
  }
};
exports.default = _default;