"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "evalute-content"
  }, [_c("h1", {
    staticClass: "title",
    class: {
      "is-hidden-menu": _vm.isHiddenMenu
    }
  }, [_vm._v(" 质检评价单  (" + _vm._s(_vm.total) + "/" + _vm._s(_vm.maxCount) + ") ")]), _c("evaluation-content", {
    staticClass: "main-content",
    attrs: {
      "table-data": _vm.tableData,
      total: _vm.total,
      "max-count": _vm.maxCount,
      loading: _vm.tableLoading,
      "no-filter": _vm.noFilter
    },
    on: {
      filterChange: _vm.filterChange,
      handleEvaluationStatus: _vm.handleEvaluationStatus,
      refresh: _vm.getEvaluationTemplateList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;