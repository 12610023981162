"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _KnowledgeCatalogPositionSelect = _interopRequireDefault(require("./KnowledgeCatalogPositionSelect.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeMoveDialog',
  components: {
    KnowledgeCatalogPositionSelect: _KnowledgeCatalogPositionSelect.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentNode: {
      type: Object,
      default: () => ({})
    },
    knowledgeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      submitLoading: false,
      position: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible(val) {
      this.$nextTick(() => {
        val && this.$refs['knowledge-catalog-position-select'].getCatalogData();
      });
    }
  },
  methods: {
    async submit() {
      if (!this.position || this.position === this.currentNode.id) {
        this.handleClose();
        this.clearData();
        return;
      }
      const res = await (0, _knowledgeLibrary.moveKnowledge)({
        from_id: this.currentNode.id,
        to_id: this.position,
        knowledge_id: this.knowledgeId,
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.successfullyMoved'));
        this.$emit('moveSuccess', this.knowledgeId);
        this.handleClose();
        this.clearData();
      }
    },
    handlePositionSelect(val) {
      this.position = val;
    },
    handleClose() {
      this.clearData();
      this.$emit('update:visible', false);
    },
    clearData() {
      this.position = '';
    }
  }
};
exports.default = _default;