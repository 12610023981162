"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'PersonalKnowledgeTable',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 285,
      lastSort: {
        prop: 'score_my',
        order: 'descending'
      }
    };
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 285;
    },
    sortChange(_ref) {
      let {
        column,
        prop,
        order
      } = _ref;
      if (prop !== this.lastSort.prop) {
        this.$refs[this.lastSort.prop].columnConfig.order = null;
      }
      if (!order) {
        column.order = this.lastSort.order;
      } else {
        this.lastSort = {
          prop,
          order
        };
        this.$emit('tableSortChange', {
          prop,
          order
        });
      }
    }
  }
};
exports.default = _default;