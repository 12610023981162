"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CourseCard = _interopRequireDefault(require("@/views/coaching/coach/course/components/CourseCard.vue"));
var _CreateTaskDrawer = _interopRequireDefault(require("@/views/coaching/coach/course/components/CreateTaskDrawer.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _SmartTrainingDialog = _interopRequireDefault(require("./components/SmartTrainingDialog"));
var _FiltersGroup = _interopRequireDefault(require("./components/FiltersGroup"));
var _CourseCatalog = _interopRequireDefault(require("./components/CourseCatalog"));
var _MoveCourseDialog = _interopRequireDefault(require("./components/MoveCourseDialog"));
var _EditNameDialog = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/EditNameDialog.vue"));
var _SetPublicScopeDialog = _interopRequireDefault(require("./components/SetPublicScopeDialog.vue"));
var _SetPublicScope = _interopRequireDefault(require("./components/SetPublicScope.vue"));
var _TaskRecordDrawerContent = _interopRequireDefault(require("./components/TaskRecordDrawerContent.vue"));
var _CreateAiCustomerDialog = _interopRequireDefault(require("@/views/coaching/AiCustomerGenerator/CreateAiCustomerDialog.vue"));
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
var _coachingCenter = require("@/api/coachingCenter");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const courseTypes = [{
  type: '1',
  label: 'coaching.scriptReading',
  icon: 'icon-reading-fill',
  color: '#0078FF'
}, {
  type: '2',
  label: 'coaching.scriptRecitation',
  icon: 'icon-recite-fill',
  color: '#7852E0'
}, {
  type: '3',
  label: 'coaching.scriptPractice',
  icon: 'icon-robot-fill',
  color: '#FF5F33'
}, {
  type: '6',
  label: 'coaching.aICustomer',
  icon: 'icon-ai-user',
  color: '#00B7B8'
}];
const editCourseRoutes = [_routeMap.ROUTES.coachCourseEdit, _routeMap.ROUTES.smartTrainingEdit, _routeMap.ROUTES.aiCoachCourseEdit, _routeMap.ROUTES.aiSopCoachCourseEdit, _routeMap.ROUTES.aiSopCoachCourse, _routeMap.ROUTES.aiCustomerGenerator];
var _default = {
  name: 'Course',
  components: {
    CourseCard: _CourseCard.default,
    CreateTaskDrawer: _CreateTaskDrawer.default,
    NoData: _NoData.default,
    MenuToggleBtn: _MenuToggleBtn.default,
    SmartTrainingDialog: _SmartTrainingDialog.default,
    FiltersGroup: _FiltersGroup.default,
    CourseCatalog: _CourseCatalog.default,
    MoveCourseDialog: _MoveCourseDialog.default,
    EditNameDialog: _EditNameDialog.default,
    SetPublicScopeDialog: _SetPublicScopeDialog.default,
    SetPublicScope: _SetPublicScope.default,
    TaskRecordDrawerContent: _TaskRecordDrawerContent.default,
    CreateAiCustomerDialog: _CreateAiCustomerDialog.default
  },
  beforeRouteLeave(to, from, next) {
    if (editCourseRoutes.includes(to.path)) {
      const isEdit = to.query.id;
      const params = isEdit ? {
        currentCategory: this.currentCategory,
        ...this.listParams
      } : {
        currentCategory: this.currentCategory
      };
      if (!isEdit) {
        this.removeCache('coachingCourseListParamsMemberList');
      }
      this.updateParamsCache(params);
    } else {
      this.removeCache('coachingCourseListParams');
      this.removeCache('coachingCourseListParamsMemberList');
    }
    next();
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fid: false,
      catalogData: [],
      catalogLoading: false,
      currentCategory: {},
      courseList: [],
      total: 0,
      searchFilter: '',
      listParams: {
        filter: '',
        page: 1,
        size: 20,
        is_only_show_current: false
      },
      createTaskDrawerVisible: false,
      loading: false,
      currentCourseInfo: {},
      smartTrainingDialogVisible: false,
      moveCourseDialogVisible: false,
      activeCourseInfo: {},
      editNameDialogVisible: false,
      editCatalogType: '',
      editNameDialogLabel: '',
      editPublicScopeDialogVisible: false,
      isPublic: 1,
      backfillList: [],
      taskRecordDrawerVisible: false,
      createAiCustomerDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    courseTypes() {
      courseTypes.filter(item => ['4', '5', '6'].includes(item.type)).forEach(item => {
        if (item.type === '4') {
          item.isHidden = !this.api_permissions.includes('/webapi/training/course/ai/customer/create');
        } else {
          item.isHidden = !this.api_permissions.includes('/webapi/training/course/scene_ai/customer/create');
        }
      });
      return courseTypes.filter(item => !item.isHidden);
    }
  },
  created() {
    this.initIsOnlyShowCurrent();
    this.getCourseCategoryData();
  },
  methods: {
    initIsOnlyShowCurrent() {
      const _isShow = localStorage.getItem('isOnlyShowCurrentCategoryCourse');
      this.listParams.is_only_show_current = _isShow ? JSON.parse(_isShow) : false;
    },
    searchFilterChange(val) {
      this.listParams.filter = val;
      this.currentCategory = val.length ? {} : this.catalogData[0];
      this.$refs['filters-group'].backfillFilters([], {});
    },
    filterChange(val) {
      Object.assign(this.listParams, val);
      this.getCourseList();
    },
    // 首次新建课程成功后展示引导
    handleCreateTask() {
      this.$emit('showMyTour');
    },
    refresh() {
      this.listParams.page = 1;
      this.getCourseList();
    },
    handleCommand(type) {
      // 跳转编辑课程页面
      let path;
      switch (Number(type)) {
        case 3:
          this.smartTrainingDialogVisible = true;
          break;
        case 4:
          path = _routeMap.ROUTES.aiCoachCourseEdit;
          break;
        case 5:
          path = _routeMap.ROUTES.aiSopCoachCourseEdit;
          break;
        case 6:
          // path = ROUTES.aiSopCoachCourse;
          this.createAiCustomerDialogVisible = true;
          return;
        default:
          path = _routeMap.ROUTES.coachCourseEdit;
          break;
      }
      this.$router.push({
        path,
        query: {
          type,
          categoryId: this.currentCategory.id
        }
      });
    },
    async getCourseList() {
      if (typeof this.currentCategory.id === 'undefined' && !this.listParams.filter) {
        return;
      }
      if (this.catalogLoading) return;
      this.loading = true;
      const res = await (0, _coachingCenter.getCourseList)({
        category_id: this.currentCategory.id,
        ...this.listParams,
        type: Number(this.listParams.type)
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          count,
          course_list
        } = res.results;
        this.total = count;
        this.courseList = course_list;
      }
    },
    handelCreateTask(val) {
      this.currentCourseInfo = val;
      this.createTaskDrawerVisible = true;
    },
    // 分类操作
    handleNodeClick(currenNode) {
      this.clearSearch();
      this.currentCategory = currenNode;
      // 更新右侧课程列表
      this.sizeChange(this.listParams.size);
      this.updateParamsCache({
        currentCategory: currenNode
      });
    },
    clearSearch() {
      this.listParams.filter = '';
      this.searchFilter = '';
    },
    getCourseCategoryData: (0, _commonFunc.debounce)(async function () {
      this.catalogLoading = true;
      const res = await (0, _coachingCenter.getCourseCategoryData)().finally(() => {
        this.catalogLoading = false;
      });
      if (res.code === 20000) {
        this.catalogData = res.results.list;
        if (this.catalogData.length) {
          const {
            currentCategory,
            page,
            size,
            filter
          } = this.getParamsCache();
          this.currentCategory = currentCategory || this.catalogData[0];
          this.listParams = {
            ...this.listParams,
            page: page || 1,
            size: size || 20,
            filter: filter || ''
          };
          this.total = page * size;
          this.$nextTick(() => {
            this.pageChange(page);
          });
        }
      }
    }, 50),
    handleCreateCatalog(val) {
      this.fid = val ? val.id : 0;
      this.editNameDialogVisible = true;
      this.editCatalogType = 'createCatalog';
      this.editNameDialogLabel = val === null || val === void 0 ? void 0 : val.name;
    },
    handleRenameCatalog(val) {
      this.editNameDialogVisible = true;
      this.editCatalogType = 'renameCatalog';
      this.editNameDialogLabel = val === null || val === void 0 ? void 0 : val.name;
    },
    handleEditPublicScope() {
      this.editPublicScopeDialogVisible = true;
      // 请求公开范围配置
      this.getCourseCategoryPublicScope();
    },
    async getCourseCategoryPublicScope() {
      this.backfillList = [];
      const res = await (0, _coachingCenter.getCourseCategoryPublicScope)({
        category_id: this.currentCategory.id
      });
      if (res.code === 20000) {
        const {
          public_scope,
          selection_list
        } = res.results;
        this.isPublic = public_scope;
        this.backfillList = selection_list.map(item => {
          return {
            ...item,
            isDepartment: item.is_department,
            preNode: item.pre_node
          };
        });
      }
    },
    async handleSubmitEditPublicScope(val, callback) {
      const params = {
        category_id: this.currentCategory.id
      };
      this.formatPublicScopePrams(params, val);
      await (0, _coachingCenter.editCourseCategoryPublicScope)(params);
      callback();
    },
    handleDeleteCatalog() {
      this.$confirm(this.$t('coaching.deleteCategoryWarning'), this.$t('coaching.note'), {
        confirmButtonText: this.$t('coaching.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('coaching.cancel')
      }).then(async () => {
        const res = await (0, _coachingCenter.deleteCourseCategory)({
          id: this.currentCategory.id
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('coaching.deletedSuccessfully'));
          this.removeCache('coachingCourseListParams');
          this.getCourseCategoryData();
        }
      });
    },
    async handlerNameSuccess(name) {
      const isCreate = this.editCatalogType === 'createCatalog';
      const requestFunc = isCreate ? _coachingCenter.createCourseCategory : _coachingCenter.updateCourseCategory;
      const params = {
        name
      };
      if (isCreate) {
        params.fid = this.fid;
        this.formatPublicScopePrams(params, this.$refs['SetPublicScopeRef']);
      } else {
        params.id = this.currentCategory.id;
      }
      const res = await requestFunc(params);
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.savedSuccessfully'));
        this.editNameDialogVisible = false;
        this.refreshTree({
          currentCategory: isCreate ? res.results : {
            ...this.currentCategory,
            name
          }
        });
      }
    },
    formatPublicScopePrams(params, val) {
      const {
        isPublic,
        assignee
      } = val;
      params.public_scope = isPublic;
      if (!isPublic) {
        Object.assign(params, assignee);
      }
    },
    moveCourse(val) {
      this.moveCourseDialogVisible = true;
      this.activeCourseInfo = val;
    },
    refreshTree() {
      let val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentCategory;
      this.updateParamsCache({
        currentCategory: val
      });
      this.currentCategory.id = null;
      this.getCourseCategoryData();
    },
    sizeChange(val) {
      this.listParams.page = 1;
      this.listParams.size = val;
      this.getCourseList();
    },
    pageChange() {
      let val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.listParams.page = val;
      this.getCourseList();
    },
    getParamsCache() {
      return JSON.parse(sessionStorage.getItem('coachingCourseListParams') || '{}');
    },
    updateParamsCache(data) {
      sessionStorage.setItem('coachingCourseListParams', JSON.stringify(data));
    },
    removeCache(val) {
      sessionStorage.removeItem(val);
    },
    openTaskRecordDrawer(courseInfo) {
      this.taskRecordDrawerVisible = true;
      this.activeCourseInfo = courseInfo;
    },
    onIsOnlyCurrentChange(val) {
      localStorage.setItem('isOnlyShowCurrentCategoryCourse', val);
      this.refresh();
    }
  }
};
exports.default = _default;