"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _WorkspaceSelector = _interopRequireDefault(require("@/components/Filters/WorkspaceSelector/WorkspaceSelector"));
var _SentenceType = _interopRequireDefault(require("./components/SentenceType.vue"));
var _ModelSentenceItem = _interopRequireDefault(require("./components/ModelSentenceItem.vue"));
var _IntelligentExtension = _interopRequireDefault(require("./components/IntelligentExtension.vue"));
var _modelSentence = require("./hooks/modelSentence");
var _useVuex = require("@/hooks/use-vuex");
var _rapidCreateModel = require("@/api/rapidCreateModel");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  emits: ['changeModifyModelSentence'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const modelInfo = (0, _vue.inject)('modelInfo');
    const setModelInfo = (0, _vue.inject)('setModelInfo');
    const store = (0, _useVuex.useStore)();
    const {
      exampleType
    } = (0, _modelSentence.useExampleSentence)();
    const {
      modelSentenceForm,
      modelSentenceFormRef,
      checkLeastOneOfHasValue,
      addModelSentenceItem,
      deleteModelSentenceItem,
      initModelSentenceForm
    } = (0, _modelSentence.useModelSentenceForm)(exampleType);
    const {
      gptLoading,
      intelligentSentenceRef,
      exampleSentenceList,
      handleIntelligentSentence,
      deleteExampleSentence,
      intelligentSentenceDrawerVisible,
      initIntelligentExtension
    } = (0, _modelSentence.useIntelligentExtension)(exampleType);
    const entityTypeLabelObj = (0, _vue.ref)({
      1: {
        host_salesman: '销售说'
      },
      2: {
        customer_contact: '客户说'
      },
      3: {
        host_salesman: '销售说',
        customer_contact: '客户答'
      },
      4: {
        host_salesman: '销售答',
        customer_contact: '客户说'
      },
      7: {}
    });
    const changeModelSentenceItem = () => {
      sessionStorage.setItem(`quick-model-${modelInfo.id}-example-type-${exampleType.value}`, JSON.stringify(modelSentenceForm.value.sentences));
    };
    (0, _vue.watch)(() => exampleType.value, (val, oldVal) => {
      if (oldVal) {
        setModelInfo({
          sentences: {
            [oldVal]: modelSentenceForm.value.sentences
          }
        });
        sessionStorage.setItem(`quick-model-${modelInfo.id}-example-type-${oldVal}`, JSON.stringify(modelSentenceForm.value.sentences));
      }
      if (val) {
        initModelSentenceForm();
        initIntelligentExtension();
        if (modelInfo.sentences[val]) {
          modelSentenceForm.value.sentences = modelInfo.sentences[val];
          return;
        }
        const sentences = sessionStorage.getItem(`quick-model-${modelInfo.id}-example-type-${val}`);
        if (sentences) {
          modelSentenceForm.value.sentences = JSON.parse(sentences);
        }
      }
    });
    const triggerAIExpandSentence = (0, _vue.ref)({});
    // 智能扩充按钮触发事件
    const handleAIExpand = async val => {
      var _triggerAIExpandSente, _triggerAIExpandSente2;
      triggerAIExpandSentence.value = val;
      intelligentSentenceDrawerVisible.value = true;
      await (0, _vue.nextTick)();
      const salesContent = (_triggerAIExpandSente = triggerAIExpandSentence.value.find(item => item.entity_type === 'host_salesman')) === null || _triggerAIExpandSente === void 0 ? void 0 : _triggerAIExpandSente.content;
      const contactContent = (_triggerAIExpandSente2 = triggerAIExpandSentence.value.find(item => item.entity_type === 'customer_contact')) === null || _triggerAIExpandSente2 === void 0 ? void 0 : _triggerAIExpandSente2.content;
      if ([1, 2, 7].includes(exampleType.value)) {
        intelligentSentenceRef.value.setLocalIntelligentSentence(`“${triggerAIExpandSentence.value[0].content}”；请根据前面的句子，生成15句左右语义相近，但所用词汇、句式顺序等更多样的句子。句子风格口语化并简洁`);
      } else if ([3, 5].includes(exampleType.value)) {
        intelligentSentenceRef.value.setLocalIntelligentSentence(`“销售：${salesContent}” ，“客户：${contactContent}”；请根据前面的句子，生成15组左右语义相近，但所用词汇、句式顺序等更多样的对话。对话风格口语化并简洁`);
      } else if ([4, 6].includes(exampleType.value)) {
        intelligentSentenceRef.value.setLocalIntelligentSentence(`“客户：${contactContent}”，“销售：${salesContent}” ，；请根据前面的句子，生成15组左右语义相近，但所用词汇、句式顺序等更多样的对话。对话风格口语化并简洁`);
      }
      handleIntelligentSentence();
    };

    // 全部添加至例句
    const addExtendedSentenceToExample = () => {
      exampleSentenceList.value.forEach(item => {
        modelSentenceForm.value.sentences.push(item);
      });
      intelligentSentenceDrawerVisible.value = false;
      exampleSentenceList.value = [];
    };
    const getModelSentenceData = () => {
      return {
        modelSentenceFormData: modelSentenceForm.value,
        exampleType: exampleType.value
      };
    };
    const modelSentenceFormValidate = async () => {
      return await modelSentenceFormRef.value.validate();
    };
    expose({
      getModelSentenceData,
      modelSentenceFormValidate
    });
    const loading = (0, _vue.ref)(false);
    const getModelSentenceInfo = async () => {
      loading.value = true;
      const res = await (0, _rapidCreateModel.getRapidModelSentence)({
        model_id: modelInfo.id
      }).finally(() => loading.value = false);
      if (res.code === 20000) {
        const {
          examples,
          workspace_ids,
          example_type
        } = res.results;
        exampleType.value = example_type || 1;
        if (examples.length) {
          modelSentenceForm.value.sentences = examples;
          setModelInfo({
            example_type,
            sentences: {
              [example_type]: examples
            }
          });
        }
        emit('changeModifyModelSentence', examples.length);
        if (workspace_ids && workspace_ids.length) {
          modelSentenceForm.value.workspace_ids = workspace_ids;
        } else {
          console.log(store.getters);
          modelSentenceForm.value.workspace_ids = [store.getters.workspaceInfo.id];
        }
      }
    };
    (0, _vue.onMounted)(() => {
      getModelSentenceInfo();
    });
    return {
      __sfc: true,
      modelInfo,
      setModelInfo,
      store,
      exampleType,
      modelSentenceForm,
      modelSentenceFormRef,
      checkLeastOneOfHasValue,
      addModelSentenceItem,
      deleteModelSentenceItem,
      initModelSentenceForm,
      gptLoading,
      intelligentSentenceRef,
      exampleSentenceList,
      handleIntelligentSentence,
      deleteExampleSentence,
      intelligentSentenceDrawerVisible,
      initIntelligentExtension,
      entityTypeLabelObj,
      changeModelSentenceItem,
      triggerAIExpandSentence,
      handleAIExpand,
      addExtendedSentenceToExample,
      getModelSentenceData,
      modelSentenceFormValidate,
      emit,
      loading,
      getModelSentenceInfo,
      WorkspaceSelector: _WorkspaceSelector.default,
      SentenceType: _SentenceType.default,
      ModelSentenceItem: _ModelSentenceItem.default,
      IntelligentExtension: _IntelligentExtension.default
    };
  }
};
exports.default = _default;