"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permissions = require("@/api/permissions");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'SelectRole',
  components: {},
  props: {
    subTitle: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    roleList: {
      type: Array,
      default: () => []
    },
    roleLoading: {
      type: Boolean,
      default: false
    },
    roleInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      roleName: '',
      currentRoleId: ''
    };
  },
  computed: {},
  watch: {
    roleInfo: {
      handler: function (val) {
        if (val) {
          this.currentRoleId = val.id;
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    searchRole: (0, _commonFunc.debounce)(function () {
      this.getRoleList();
    }),
    scrollToCurrentRole() {
      this.$nextTick(() => {
        const item = document.getElementsByClassName('is-active-role').item(0);
        const wrapper = document.getElementsByClassName('role-list').item(0);
        wrapper.scrollTo(0, item.offsetTop);
      });
    },
    getRoleList() {
      this.$emit('getRoleList', this.roleName);
    },
    openRoleDrawer(roleInfo, type) {
      this.$emit('openRoleDrawer', roleInfo, type);
    },
    switchRightPage(roleInfo) {
      this.currentRoleId = roleInfo.id;
      this.$emit('switchRightPage', roleInfo);
    },
    handleCommand(command, item) {
      switch (command) {
        case 'copy':
          this.openRoleDrawer(item, 'copy');
          break;
        case 'default':
          this.openDefaultDialog(item);
          break;
        case 'delete':
          this.openDeleteDialog(item);
          break;
      }
    },
    openDefaultDialog(item) {
      this.$confirm(`确定将${item.name}设为成员的默认业务角色吗？修改后，不会移除原有的默认业务角色`, '设为默认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _permissions.setDefaultRole)({
          role_id: item.id
        });
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '设置成功'
          });
          this.roleName = '';
          this.getRoleList();
        }
      });
    },
    openDeleteDialog(item) {
      this.$confirm(`确定删除${item.name}吗？删除后，此角色下的${this.subTitle === '业务角色' ? '成员' : '管理员'}将不再拥有对应权限`, '删除角色', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        let res;
        if (this.subTitle === '业务角色') {
          res = await (0, _permissions.deleteFuncRole)({
            role_id: item.id
          });
        } else {
          res = await (0, _permissions.deleteAdminRole)({
            role_id: item.id
          });
        }
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.roleName = '';
          this.getRoleList();
        }
      });
    }
  }
};
exports.default = _default;