"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _DepartmentMultiSelect = _interopRequireDefault(require("./DepartmentMultiSelect"));
var _permissions = require("@/api/permissions");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DataPermissionCard',
  components: {
    MembersFinder: _MembersFinder.default,
    DepartmentMultiSelect: _DepartmentMultiSelect.default
  },
  props: {
    profileType: {
      type: String,
      default: ''
    },
    permissionProfile: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      typeMap: {
        conversation: {
          title: '会话',
          label: '会话',
          res_type: 1
        },
        deal: {
          title: '商机',
          label: '商机',
          res_type: 2
        },
        lead: {
          title: '线索',
          label: '线索',
          res_type: 3
        },
        account: {
          title: '客户',
          label: '客户',
          res_type: 4
        },
        assist: {
          title: '任务和助手',
          label: '任务和助手',
          res_type: 5
        },
        training: {
          title: '培训',
          label: '培训任务',
          res_type: 6
        },
        trade: {
          title: '交易',
          label: '交易',
          res_type: 7
        },
        compliance: {
          title: '质检',
          label: '质检',
          res_type: 8
        }
      },
      operateOptions: [{
        value: 1,
        label: '查看'
      }, {
        value: 2,
        label: '编辑'
      }
      // {
      //   value: 4,
      //   label: '删除'
      // }
      ],

      initScopeOptions: [{
        value: 1,
        label: '仅自己'
      }, {
        value: 2,
        label: '下级部门成员'
      },
      // {
      //   value: 3,
      //   label: '所属部门成员'
      // },
      {
        value: 4,
        label: '所属及下级部门成员'
      }, {
        value: 5,
        label: '所有成员'
      }],
      localPermisson: {},
      isValidate: false,
      validateIsOkArray: []
    };
  },
  computed: {
    getCanEdit() {
      var _this$permissionProfi;
      const editItem = (_this$permissionProfi = this.permissionProfile.basic_rules) === null || _this$permissionProfi === void 0 ? void 0 : _this$permissionProfi.find(item => item.operate_type === 2);
      return editItem;
    },
    getOperateLabel() {
      return function (type) {
        return this.operateOptions.find(item => item.value === type).label;
      };
    },
    getIncompleteRules() {
      return function (data, index) {
        var _targetObj$tree_infos, _targetObj$user_infos, _resourceObj$tree_inf, _resourceObj$user_inf;
        const targetObj = data.auth_target;
        const resourceObj = data.resource;
        if (((targetObj === null || targetObj === void 0 ? void 0 : (_targetObj$tree_infos = targetObj.tree_infos) === null || _targetObj$tree_infos === void 0 ? void 0 : _targetObj$tree_infos.length) > 0 || (targetObj === null || targetObj === void 0 ? void 0 : (_targetObj$user_infos = targetObj.user_infos) === null || _targetObj$user_infos === void 0 ? void 0 : _targetObj$user_infos.length) > 0) && ((resourceObj === null || resourceObj === void 0 ? void 0 : (_resourceObj$tree_inf = resourceObj.tree_infos) === null || _resourceObj$tree_inf === void 0 ? void 0 : _resourceObj$tree_inf.length) > 0 || (resourceObj === null || resourceObj === void 0 ? void 0 : (_resourceObj$user_inf = resourceObj.user_infos) === null || _resourceObj$user_inf === void 0 ? void 0 : _resourceObj$user_inf.length) > 0)) {
          this.validateIsOkArray[index] = true;
          return false;
        } else {
          this.validateIsOkArray[index] = false;
          return true;
        }
      };
    },
    getScopeOptions() {
      return function (type) {
        var _this$localPermisson$;
        if (((_this$localPermisson$ = this.localPermisson.basic_rules) === null || _this$localPermisson$ === void 0 ? void 0 : _this$localPermisson$.length) > 1 && type === 2) {
          const index = this.localPermisson.basic_rules.findIndex(item => {
            return item.operate_type === 1;
          });
          const readAuthScope = this.localPermisson.basic_rules[index].auth_scope;
          const scopeOptions = (0, _commonFunc.deepClone)(this.initScopeOptions);
          scopeOptions.forEach(item => {
            if (item.value > readAuthScope) {
              item.disabled = true;
            }
            if (readAuthScope === 3 && item.value === 2) {
              // 单独处理可查看值为3的情况
              item.disabled = true;
            }
          });
          return scopeOptions;
        } else {
          return this.initScopeOptions;
        }
      };
    }
  },
  watch: {
    permissionProfile: {
      handler: function (val) {
        this.handleData();
      },
      deep: true,
      immediate: true
    },
    localPermisson: {
      handler: function (val) {
        this.$emit('dataChange', val);
      },
      deep: true
    }
  },
  methods: {
    getDepartmentsWithOutAuth: _permissions.getDepartmentsWithOutAuth,
    submitManageScope(scope, customizeRules) {
      customizeRules.resource.tree_infos = scope.map(item => item.id);
    },
    handleData() {
      this.localPermisson = JSON.parse(JSON.stringify(this.permissionProfile));
      this.handleMemberFinderBackfill('auth_target', 'authTargetMembersFinder');
      this.handleMemberFinderBackfill('resource', 'departmentMultipleSelect');
    },
    handleMemberFinderBackfill(attribute, refName) {
      this.$nextTick(() => {
        var _this$$refs$refName;
        (_this$$refs$refName = this.$refs[refName]) === null || _this$$refs$refName === void 0 ? void 0 : _this$$refs$refName.forEach((item, index) => {
          const treeInfos = this.localPermisson.supplement_rules[index][attribute].tree_infos || [];
          if (attribute === 'auth_target') {
            const treeIds = treeInfos === null || treeInfos === void 0 ? void 0 : treeInfos.map(item => 'tree_id_' + item.id);
            const userInfos = this.localPermisson.supplement_rules[index][attribute].user_infos || [];
            const userIds = userInfos === null || userInfos === void 0 ? void 0 : userInfos.map(item => item.id);
            item.selection = [...treeIds, ...userIds];
            const treesInfo = treeInfos === null || treeInfos === void 0 ? void 0 : treeInfos.map(item => {
              item.id = 'tree_id_' + item.id;
              return item;
            });
            item.memberOptions = [...treesInfo, ...userInfos];
          } else {
            item.selectedDepartmentInfo = treeInfos;
            this.localPermisson.supplement_rules[index][attribute].tree_infos = treeInfos === null || treeInfos === void 0 ? void 0 : treeInfos.map(item => item.id);
          }
        });
      });
    },
    handleMemberChange(val, customizeRules) {
      const userId_Multiple = [];
      const deptId_Multiple = [];
      val.forEach(item => {
        const id = String(item).split('_').pop() - 0;
        if (String(item).indexOf('tree_id') === -1) {
          userId_Multiple.push(id);
        } else {
          deptId_Multiple.push(id);
        }
      });
      customizeRules.auth_target.user_infos = userId_Multiple;
      customizeRules.auth_target.tree_infos = deptId_Multiple;
    },
    removeRule(customizeRules, index) {
      this.localPermisson.supplement_rules = this.localPermisson.supplement_rules.filter(item => item.id !== customizeRules.id);
      this.validateIsOkArray.splice(index, 1);
    },
    addCustomizeRules() {
      this.localPermisson.supplement_rules.push({
        id: new Date().getTime(),
        auth_target: {
          // 主体
          tree_infos: [],
          user_infos: []
        },
        resource: {
          // 客体
          tree_infos: [],
          user_infos: []
        },
        operate_type: 1,
        res_type: this.typeMap[this.profileType].res_type
      });
    },
    judgeValidateIsOk() {
      const validateArray = this.validateIsOkArray;
      const newArray = [...new Set(validateArray)];
      const res = newArray.length === 1 && newArray[0] || newArray.length === 0;
      this.isValidate = !res;
      return res;
    },
    closeValidate() {
      this.isValidate = false;
      this.validateIsOkArray = [];
    }
  }
};
exports.default = _default;