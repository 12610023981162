"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CopyInvitation',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgName: {
      type: String,
      default: ''
    }
  },
  computed: {
    inviteText() {
      const _url = window.location.protocol + '//' + window.location.host + '/login';
      return '我和“' + this.orgName + '”的小伙伴都在等你，已将你加入团队，用这个链接开启会话吧！' + _url;
    }
  },
  methods: {
    onCopy() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
    },
    closeSelf() {
      this.$emit('closeCopyDialog');
    }
  }
};
exports.default = _default;