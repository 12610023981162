"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "rank-persons-list"
  }, [_vm._m(0), _vm.list.length > 0 ? _c("div", {
    staticClass: "list-header-title"
  }, [_vm._v("引用次数")]) : _vm._e(), _vm.list.length > 0 ? _c("ul", {
    staticClass: "persons-ranking"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("li", {
      key: index
    }, [_c("span", {
      staticClass: "order",
      class: index < 3 ? "hot" : ""
    }, [_vm._v(_vm._s(index + 1))]), _c("image-avatar", {
      staticClass: "avatar",
      attrs: {
        avatar: item.avatar,
        name: item.name
      }
    }), _c("span", {
      staticClass: "name",
      class: item.id ? "" : "no-person"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "times"
    }, [_vm._v(_vm._s(item.count))])], 1);
  }), 0) : _vm._e(), !_vm.list || _vm.list.length === 0 ? _c("no-content", {
    attrs: {
      title: "暂未出炉"
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "list-header"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/personsRanking.png"),
      alt: "personsRanking"
    }
  }), _c("span", {
    staticClass: "title-name"
  }, [_vm._v("上月名人榜")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;