"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "searchList",
    staticClass: "search-results"
  }, [_c("div", {
    staticClass: "results-wrapper"
  }, [_vm.isShowSearchList && _vm.resultList && _vm.resultList.length > 0 ? _c("ul", {
    staticClass: "result"
  }, _vm._l(_vm.resultList, function (item) {
    return _c("li", {
      key: item.id,
      staticClass: "result-item",
      class: {
        "active-item": _vm.activeOrder === item.order
      },
      on: {
        click: function ($event) {
          return _vm.goKeyDetail(item.order, item);
        }
      }
    }, [_c("image-avatar", {
      attrs: {
        avatar: item.avatar,
        name: item.name,
        type: item.entity_type
      }
    }), _c("div", {
      staticClass: "item-right"
    }, [_c("div", {
      staticClass: "item-top"
    }, [_c("div", {
      staticClass: "item-name"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "begin-time"
    }, [_vm._v(" " + _vm._s(_vm.conversationType === "doc" ? _vm.$plus8ToCurrentZone(item.begin_time * 1000).format("YYYY-MM-DD HH:mm") : _vm.realFormatSecond(item.begin_time)) + " ")])]), _c("div", {
      staticClass: "item-content",
      domProps: {
        innerHTML: _vm._s(item.content)
      }
    })])], 1);
  }), 0) : _vm._e(), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (!_vm.resultList || _vm.resultList.length === 0) && _vm.isShowSearchList,
      expression: "(!resultList || resultList.length === 0) && isShowSearchList"
    }],
    staticClass: "tip"
  }, [_vm._v(" 暂无相关数据 ")])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;