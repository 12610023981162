"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'FolderDropdown',
  inject: ['getContentType'],
  props: {
    isCollect: Boolean,
    isTree: Boolean
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    visible() {
      const {
        moveAllSnippetToFolder,
        moveFolderToFolder,
        editPublicScope,
        renameFolder,
        deleteFolder
      } = this.interfacePermission;
      return [moveAllSnippetToFolder, moveFolderToFolder, editPublicScope, renameFolder, deleteFolder].includes(true);
    }
  },
  methods: {
    // 判断一个数组是否是另一个数组的子数组
    isContained(a, b) {
      if (!(a instanceof Array) || !(b instanceof Array)) return false;
      if (a.length < b.length) return false;
      var aStr = a.toString();
      for (var i = 0, len = b.length; i < len; i++) {
        if (aStr.indexOf(b[i]) === -1) return false;
      }
      return true;
    }
  }
};
exports.default = _default;