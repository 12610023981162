"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _organization = require("@/api/organization");
var _permissions = require("@/api/permissions");
var _validate = require("@/utils/validate");
var _DepartmentSelector = _interopRequireDefault(require("@/components/DepartmentSelector"));
var _AddItem = _interopRequireDefault(require("@/components/AddItem"));
var _DataTransferDrawer = _interopRequireDefault(require("@/components/DataTransferDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserInformationDrawer',
  components: {
    DepartmentSelect: _DepartmentSelector.default,
    DataTransferDrawer: _DataTransferDrawer.default,
    AddItem: _AddItem.default
  },
  props: {
    userInformationDrawerVisible: {
      type: Boolean,
      default: false
    },
    editUserInfo: {
      type: Object,
      default: () => ({})
    },
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const nameLengthValidator = (rule, value, callback) => {
      if (value.length > 32) {
        callback(new Error(this.$t('management.请输入1-32个字符')));
      }
      callback();
    };
    const checkDept = (rule, value, callback) => {
      const params = JSON.parse(rule.field);
      if (!params[0]) {
        return callback(new Error(this.$t('management.请选择部门')));
      }
      callback();
    };
    const checkWorkspace = (rule, value, callback) => {
      const params = JSON.parse(rule.field);
      if (!params[0]) {
        return callback(new Error(this.$t('management.请选择空间')));
      }
      callback();
    };
    return {
      roles: [],
      userInfo: {
        user_id: '',
        name: '',
        email: '',
        main_department: '',
        login_method: '',
        login_account: '',
        tel_editable: false,
        login_account_editable: false,
        main_workspace_id: '',
        login_email: '',
        login_tel: '',
        verify_email: ''
      },
      orgTree: [],
      disabledArr: [],
      defaultExpandedKeys: [],
      departmentAndRole: [],
      userWorkspaceList: [],
      submitLoading: false,
      rules: {
        // login_account: [
        //   { required: false, message: '请输入正确的手机号', trigger: 'change' },
        //   { validator: telValidatorAllowNone }
        // ],
        name: [{
          required: true,
          message: this.$t('management.姓名不能为空'),
          trigger: 'blur'
        }, {
          validator: nameLengthValidator
        }],
        email: [{
          type: 'email',
          message: this.$t('management.请输入正确的邮箱地址'),
          trigger: 'change'
        }],
        verify_email: [{
          type: 'email',
          message: this.$t('management.请输入正确的邮箱地址'),
          trigger: 'change'
        }]
      },
      checkDept: {
        required: true,
        validator: checkDept,
        trigger: 'change'
      },
      checkWorkspace: {
        required: true,
        validator: checkWorkspace,
        trigger: 'change'
      },
      dataTransferDrawerVisible: false,
      userInfoDrawerLoading: false,
      userCanTransferData: [],
      localWorkspaceList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'hasWorkspace', 'isTwoFactor'])
  },
  watch: {
    departmentAndRole: {
      handler() {
        this.disabledArr = this.departmentAndRole.map(item => item.department);
      },
      deep: true
    },
    userWorkspaceList: {
      handler() {
        const disabledArr = this.userWorkspaceList.map(item => item.workspace);
        this.localWorkspaceList.forEach(item => {
          item.disabled = disabledArr.includes(item.id);
        });
      },
      deep: true
    },
    userInformationDrawerVisible(val) {
      if (val) {
        this.localWorkspaceList = this.workspaceList;
        this.$nextTick(() => {
          this.userInfo.user_id = this.userId;
          this.getOrgTree();
        });
      } else {
        this.departmentAndRole = [];
        this.userWorkspaceList = [];
      }
    }
  },
  methods: {
    handleDataTransferRefresh() {
      this.handleUserInfomationDrawerClose();
      this.$emit('refresh');
    },
    async getOrgTree() {
      try {
        this.userInfoDrawerLoading = true;
        const res = await (0, _permissions.getDepartmentWithAuth)();
        if (res.code === 200) {
          this.orgTree = res.results.filter(item => item.node_type !== 'unassigned');
          this.defaultExpandedKeys = [this.orgTree[0].id];
          this.fetchData();
        }
      } finally {
        this.userInfoDrawerLoading = false;
      }
    },
    async fetchData() {
      const {
        id
      } = this.editUserInfo;
      const res = await (0, _organization.getMemberInfo)(id);
      if (res.code === 20000) {
        const {
          name,
          email,
          main_department,
          roles,
          login_account,
          login_method,
          login_account_editable,
          main_workspace_id,
          login_email,
          login_tel,
          verify_email
        } = res.results;
        this.roles = roles;
        this.userInfo = {
          user_id: id,
          name,
          email,
          login_email,
          login_tel,
          main_department,
          login_account,
          login_method,
          login_account_editable,
          main_workspace_id,
          verify_email
        };
        this.login_account = login_account;
        this.handleDepartmentList(res.results.departments, main_department);
        this.handleWorkspaceList(res.results.workspaces, main_workspace_id);
      }
    },
    handleDepartmentList(departments, main_department) {
      this.departmentAndRole = [];
      departments.forEach((item, i) => {
        const current = {
          department: item.id,
          departmentName: item.name,
          editable: item.editable,
          id: i
        };
        if (item.id === main_department) {
          this.departmentAndRole.unshift(current);
        } else {
          this.departmentAndRole.push(current);
        }
      });
      if (!this.departmentAndRole.length) {
        this.departmentAndRole.push({
          department: '',
          editable: true,
          id: 0
        });
      }
    },
    handleWorkspaceList(workspaces, main_workspace_id) {
      this.userWorkspaceList = [];
      if (this.hasWorkspace && workspaces) {
        workspaces.forEach((item, index) => {
          const currentWorkspace = {
            workspace: item.id,
            id: index
          };
          if (item.id === main_workspace_id) {
            this.userWorkspaceList.unshift(currentWorkspace);
          } else {
            this.userWorkspaceList.push(currentWorkspace);
          }
        });
      }
      if (!this.userWorkspaceList.length) {
        this.userWorkspaceList.push({
          workspace: '',
          id: 0
        });
      }
    },
    addDeptAndRole() {
      this.departmentAndRole.push({
        department: '',
        editable: true,
        id: new Date().getTime()
      });
    },
    addWorkspaceItem() {
      this.userWorkspaceList.push({
        workspace: '',
        id: new Date().getTime()
      });
    },
    confirmDeleteDeptAndRole(index) {
      this.departmentAndRole.splice(index, 1);
    },
    confirmDeleteWorkspace(index) {
      this.userWorkspaceList.splice(index, 1);
    },
    formateData() {
      this.userInfo.main_department = this.departmentAndRole[0].department;
      this.userInfo.main_workspace_id = this.userWorkspaceList[0].workspace;
      this.userInfo.departments = this.disabledArr;
      this.userInfo.workspace_ids = this.userWorkspaceList.map(item => item.workspace);
    },
    save() {
      this.$refs['info-form'].validate(valid => {
        if (valid) {
          this.saveUserInfo();
        }
      });
    },
    saveUserInfo() {
      this.formateData();
      this.submitLoading = true;
      (0, _organization.saveUserInfo)(this.userInfo).then(() => {
        this.submitLoading = false;
        this.$message({
          message: this.$t('management.保存成功'),
          type: 'success'
        });
        this.handleUserInfomationDrawerClose();
        // 编辑成员数据成功之后刷新页面
        this.$emit('refresh');
      }).catch(() => {
        this.submitLoading = false;
      });
    },
    async setResign() {
      const res = await (0, _organization.getUserCanTransferData)({
        user_id: this.userInfo.user_id
      });
      if (res.code === 20000) {
        this.userCanTransferData = res.results.data;
        if (this.userCanTransferData.length) {
          this.dataTransferDrawerVisible = true;
        } else {
          // 直接离职
          this.operateQuit(this.userInfo);
        }
      }
    },
    operateQuit(row) {
      this.$confirm(this.$t('management.确定要将{0}设置为离职吗？', [row.name]), this.$t('management.离职成员'), {
        customClass: 'operate-user-quit-confirm',
        confirmButtonText: this.$t('management.确定'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('management.取消'),
        type: 'warning',
        beforeClose: async (action, ctx, close) => {
          if (action !== 'confirm') {
            close();
            return;
          }
          ctx.confirmButtonLoading = true;
          (0, _organization.deleteUser)(row.user_id, this.orgId).then(res => {
            if (res.code === 200) {
              this.$message({
                message: this.$t('management.离职成功'),
                center: true,
                type: 'success'
              });
              close();
              // 成功之后刷新页面
              this.handleDataTransferRefresh();
            }
          }).finally(() => {
            ctx.confirmButtonLoading = false;
          });
        }
      }).finally(() => {});
    },
    handleUserInfomationDrawerClose() {
      this.$emit('update:userInformationDrawerVisible', false);
      this.$refs['info-form'].resetFields();
      this.$refs['info-form'].clearValidate();
    }
  }
};
exports.default = _default;