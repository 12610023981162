"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotationContext = useAnnotationContext;
var _dataSet = require("@/api/dataSet");
var _routeMap = require("@/router/routeMap");
var _vue = require("vue");
function useAnnotationContext(_ref) {
  let {
    checkList,
    datasetId
  } = _ref;
  const context = (0, _vue.ref)({
    conversation_name: '',
    confidence: 0,
    sentence: [],
    status: 0
  });
  const sentenceTextRef = (0, _vue.ref)(null);
  const contentLoading = (0, _vue.ref)(false);
  const scrollToSimilarSentence = () => {
    const similarSentenceIndex = context.value.sentence.findIndex(item => item.is_similar_sentence);
    if (similarSentenceIndex === -1) return;
    const activeSentence = sentenceTextRef.value[similarSentenceIndex];
    activeSentence.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };
  const getAnnotationContextApi = async data => {
    contentLoading.value = false;
    const res = await (0, _dataSet.getAnnotationContext)(data).finally(() => contentLoading.value = false);
    if (res.code === 20000) {
      context.value = res.results;
      await (0, _vue.nextTick)();
      // 默认选中
      context.value.sentence.map((item, index) => {
        if (item.is_similar_sentence) {
          checkList.value.push(index);
        }
      });
      scrollToSimilarSentence();
    }
  };
  const getAnnotatePath = () => {
    const basePath = _routeMap.ROUTES.annotate;
    const {
      dataset_data_id
    } = context.value;
    return `${basePath}?id=${datasetId}&datasetDataId=${dataset_data_id}`;
  };
  return {
    sentenceTextRef,
    contentLoading,
    context,
    getAnnotatePath,
    getAnnotationContextApi
  };
}