"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  name: 'ConverEchartsBar',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '112px'
    },
    chartData: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      chart: null,
      option: {},
      timer: null
    };
  },
  computed: {
    // 有数据的年份数组
    yearArray() {
      const firstItemsByYear = {};
      this.chartData.dateArray.forEach(item => {
        const year = item.split('-')[0];
        if (!firstItemsByYear[year]) {
          firstItemsByYear[year] = item;
        }
      });
      return Object.values(firstItemsByYear);
    }
  },
  // 数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
  watch: {
    // 观察option的变化
    chartData: {
      handler(newVal, oldVal) {
        if (newVal && newVal.numberArray.length > 0) {
          this.initChart();
        } else {
          if (!this.chart) {
            return;
          }
          this.chart.dispose();
          this.chart = null;
        }
      },
      deep: true // 对象内部属性的监听，关键。
    },

    height: {
      handler(newVal, oldVal) {
        this.initChart();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
    window.addEventListener('resize', () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.chart) {
          const dom = document.getElementById('conversation-echarts-bar');
          if (dom) {
            this.chart.resize();
          }
        }
      }, 300);
    }, false);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getWeekRangeDate(value, format) {
      return this.$moment(value).startOf('week').format(format) + '-' + this.$moment(value).endOf('week').format(format);
    },
    initChart() {
      this.$nextTick(() => {
        const dom = document.getElementById('conversation-echarts-bar');
        if (dom) {
          this.chart = echarts.init(dom, null, {
            renderer: 'svg'
          });
          if (this.chartData) {
            this.chart.hideLoading();
            this.getOption();
            this.chart.getDom().style.height = this.height;
            this.chart.setOption(this.option);
          } else {
            this.chart.showLoading({
              text: '加载中',
              color: '#4461EC',
              textColor: 'rgba(102, 111, 141, 1)',
              maskColor: 'rgba(255, 255, 255, 0.8)',
              lineWidth: 2,
              zlevel: 0
            });
          }
        }
      });
    },
    getOption() {
      const option = {
        tooltip: {
          trigger: 'axis',
          appendToBody: true,
          axisPointer: {
            type: 'shadow',
            label: {
              formatter: params => {
                return this.getWeekRangeDate(params.value, 'YYYY年MM月DD日');
              }
            }
          },
          renderMode: 'html',
          className: 'conversation-tooltip'
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     mark: { show: true },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        //   right: 5,
        //   top: -5
        // },
        xAxis: {
          type: 'category',
          axisLine: {
            show: false,
            color: 'rgba(102, 111, 141, 1)'
          },
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLabel: {
            color: 'rgba(102, 111, 141, 1)',
            formatter: value => {
              return this.getWeekRangeDate(value, 'MM/DD');
            }
          },
          data: this.chartData.dateArray
        },
        yAxis: {
          type: 'value',
          splitNumber: 2,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLabel: {
            color: 'rgba(102, 111, 141, 1)'
          },
          splitLine: {
            lineStyle: {
              color: '#EBEEF5'
            }
          }
        },
        color: ['#4461EC'],
        grid: [{
          left: 5,
          right: 5,
          top: 16,
          bottom: 5,
          containLabel: true
        }],
        series: [{
          type: 'bar',
          barWidth: '20%',
          // 数据
          data: this.chartData.numberArray,
          markLine: {
            label: {
              formatter: val => {
                return val.value.split('-')[0];
              },
              backgroundColor: '#fff',
              padding: [1, 8]
            },
            symbol: ['none', 'none'],
            lineStyle: {
              color: '#E2E5E9',
              width: 1,
              type: 'dashed'
            },
            data: this.yearArray.map(value => {
              return {
                xAxis: value
              };
            }),
            animation: false
          }
        }]
      };
      this.option = option;
    }
  }
};
exports.default = _default;