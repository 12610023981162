"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'RulesConfig',
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      configs: {
        ruleType: 0,
        rules: []
      },
      currentLanguage: ''
    };
  },
  watch: {
    config: {
      handler: function (val) {
        this.configs.ruleType = val.ruleType;
        this.configs.rules = (0, _commonFunc.deepClone)(val.rules);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.currentLanguage = localStorage.getItem('lang') || 'zh';
  },
  methods: {
    keywordInput(index) {
      const inputRef = this.$refs['keywordSelect' + index][0];
      inputRef.query.split('').reduce((acc, curVal, curIndex) => {
        const len = acc + (curVal.charCodeAt() > 255 ? 1 : 0.2);
        if (Math.ceil(len) > 100) {
          inputRef.query = inputRef.query.slice(0, curIndex);
          return undefined;
        }
        return len;
      }, 0);
    },
    addKeyword(index) {
      const keyword = this.$refs['keywordSelect' + index][0].query;
      if (keyword && !this.configs.rules[index].keyword.includes(keyword) && this.configs.rules[index].keyword.length < 5) {
        this.configs.rules[index].keyword.push(keyword);
        this.updateItemRule();
      }
    },
    getRulesOptions() {
      return [{
        label: 'coaching.ruleHit',
        value: 0
      }, {
        label: 'coaching.ruleMiss',
        value: 1
      }];
    },
    deleteRule(id) {
      // 触发画布的删除事件
      this.$emit('deleteRule', id);
    },
    addRule() {
      // 触发画布的增加事件
      this.$emit('addRule');
    },
    toggleRuleType() {
      this.$emit('toggleRuleType', this.configs.ruleType);
    },
    updateItemRule() {
      this.updateRule({
        rules: this.configs.rules
      });
    },
    updateRule(data) {
      this.$emit('updateRule', data);
    }
  }
};
exports.default = _default;