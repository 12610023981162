"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _EmptyHelpTip = _interopRequireDefault(require("@/components/EmptyHelpTip.vue"));
var _CatalogPart = _interopRequireDefault(require("./components/CatalogPart.vue"));
var _KnowledgePart = _interopRequireDefault(require("./components/KnowledgePart.vue"));
var _KnowledgeMoveDialog = _interopRequireDefault(require("./components/KnowledgeMoveDialog.vue"));
var _EditNameDialog = _interopRequireDefault(require("./components/EditNameDialog.vue"));
var _CreateKnowledgeDrawer = _interopRequireDefault(require("./components/CreateKnowledgeDrawer.vue"));
var _RecordingAudioDrawer = _interopRequireDefault(require("./components/RecordingAudioDrawer.vue"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeLibraryDetail',
  components: {
    CatalogPart: _CatalogPart.default,
    KnowledgePart: _KnowledgePart.default,
    EmptyHelpTip: _EmptyHelpTip.default,
    KnowledgeMoveDialog: _KnowledgeMoveDialog.default,
    EditNameDialog: _EditNameDialog.default,
    RecordingAudioDrawer: _RecordingAudioDrawer.default,
    CreateKnowledgeDrawer: _CreateKnowledgeDrawer.default,
    NoData: _index.default
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      catalogData: [],
      currentNode: {},
      // 当前目录(targetNode为目录时，等于targetNode；targetNode为知识点时，等于该知识点的父级目录)
      targetNode: null,
      // 当前目录或者当前知识点（移动/点击/新建的目标节点）
      targetKnowledge: {},
      // 滚动到指定的知识点位置
      catalogLoading: false,
      routeCatalogId: null,
      tipInfo: {
        mainIntroduction: {
          title: '什么是知识库？',
          content: '知识库是收录销售话术的地方。您可以通过知识点/话术挖掘引擎，从会话中快捷挖掘销售话术；也可以用知识库话术搭建课程，指派销售学习话术。'
        },
        description: [],
        helpUrl: 'https://www.megaview.com/help-center/doc?docx=XgSIde74noecqJxNbrjcc8Usnbb&anchor=TCIadI4yioge2exQZ1pcomasnpf'
      },
      moveDialogVisible: false,
      editNameDialogVisible: false,
      editNameDialogLabel: '',
      createKnowledgeDrawerVisible: false,
      knowledgeDrawerIsEdit: false,
      currentKnowledgeInfo: {},
      getKnowledgeInfoLoading: false,
      recordingAudioDrawerVisible: false,
      isCreateRootCatalog: false,
      editCatalogType: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo']),
    knowledgePermission() {
      var _this$knowledgeBaseIn;
      return (_this$knowledgeBaseIn = this.knowledgeBaseInfo) === null || _this$knowledgeBaseIn === void 0 ? void 0 : _this$knowledgeBaseIn.permission;
    }
  },
  watch: {
    'knowledgeBaseInfo.id': {
      handler: function (val) {
        if (!val) return;
        this.catalogLoading = true;
        if (this.getRouteQuery('knowledgeBaseId')) {
          this.getCatalogData();
        } else {
          this.getRouteCatalogId();
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.$parent.$refs['nav-menu'].toggleMenu();
    },
    floatingMenuShow() {
      this.$parent.$parent.$refs['nav-menu'].floatingMenuShow();
    },
    floatingMenuHidden() {
      this.$parent.$parent.$refs['nav-menu'].floatingMenuHidden();
    },
    handleKnowledgeSuccess(_ref) {
      let {
        id
      } = _ref;
      this.targetNode = {
        id,
        is_know: true
      };
      this.getCatalogData();
      this.$nextTick(() => {
        this.$refs['knowledge-part'].getKnowledgeList();
        this.targetKnowledge = {
          id: id
        };
      });
    },
    async recordingAudio(val) {
      await this.getKnowledgeInfo(val.id).then(() => {
        this.recordingAudioDrawerVisible = true;
      });
    },
    createKnowledge(data) {
      this.targetNode = data;
      this.knowledgeDrawerIsEdit = false;
      this.createKnowledgeDrawerVisible = true;
    },
    async editKnowledge(_ref2) {
      let {
        id
      } = _ref2;
      await this.getKnowledgeInfo(id).then(() => {
        this.knowledgeDrawerIsEdit = true;
        this.createKnowledgeDrawerVisible = true;
        this.targetNode = this.currentKnowledgeInfo.catalog;
        this.backfillDrawerData();
      });
    },
    async getKnowledgeInfo(id) {
      this.getKnowledgeInfoLoading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeInfo)({
        id: id || this.currentKnowledgeInfo.id
      }).finally(() => {
        this.getKnowledgeInfoLoading = false;
      });
      if (res.code === 20000) {
        this.currentKnowledgeInfo = res.results.data;
      }
    },
    createRootCatalog() {
      this.isCreateRootCatalog = true;
      this.editNameDialogVisible = true;
      this.editCatalogType = 'createCatalog';
    },
    initEditDialogData(data, type) {
      this.targetNode = data;
      this.editNameDialogVisible = true;
      this.isCreateRootCatalog = false;
      // 新建文件夹 type: createCatalog 重命名文件夹 type:updateCatalog
      this.editCatalogType = type;
      this.editNameDialogLabel = data === null || data === void 0 ? void 0 : data.name;
    },
    async handlerNameSuccess(name) {
      var _this$targetNode;
      const requestFunc = this.editCatalogType === 'createCatalog' ? _knowledgeLibrary.createCatalog : _knowledgeLibrary.updateCatalog;
      const res = await requestFunc({
        base_id: this.knowledgeBaseInfo.id,
        fid: this.isCreateRootCatalog ? 0 : this.targetNode.id,
        id: (_this$targetNode = this.targetNode) === null || _this$targetNode === void 0 ? void 0 : _this$targetNode.id,
        name
      });
      if (res.code === 20000) {
        this.$message.success('保存成功');
        this.editNameDialogVisible = false;
        // 新建文件或者新建知识点之后，定位到新建的位置
        if (this.editCatalogType === 'createCatalog') {
          this.targetNode = {
            id: res.results.id,
            name
          };
        }
        this.getCatalogData();
      }
    },
    getCatalogData: (0, _commonFunc.debounce)(async function () {
      // 请求的loading在操作树节点导致树数据刷新的时候不展示,故需要将loading=true放到外面
      const res = await (0, _knowledgeLibrary.getCatalogData)({
        id: this.knowledgeBaseInfo.id,
        is_know: true
      }).finally(() => {
        this.catalogLoading = false;
      });
      if (res.code === 20000) {
        this.catalogData = res.results.datas;
        if (!this.catalogData.length) {
          return;
        }
        const target = this.getCurrentNode();
        this.currentNode = {
          ...target
        };
        this.toggleKnowledgeBaseAndpositioning(this.getRouteQuery('id'));
        this.toggleKnowledgeCatalog();
      }
    }),
    toggleKnowledgeCatalog() {
      this.$nextTick(() => {
        if (!this.$refs.catalogPart) return;
        if (this.targetNode && (0, _commonFunc.getTreeNodeById)(this.catalogData, this.targetNode.id)) {
          this.$refs.catalogPart.$refs.catalog.handleCurrentNodeChange(this.targetNode);
        } else {
          // 切换知识库
          this.$refs.catalogPart.$refs.catalog.handleCurrentNodeChange(this.currentNode);
        }
      });
    },
    async getRouteCatalogId() {
      const id = this.getRouteQuery('id');
      let knowledgeBaseId = this.getRouteQuery('knowledgeBaseId');
      if (!id) return;
      // 请求接口获取知识库id
      const res = await (0, _knowledgeLibrary.getFidById)({
        id
      });
      if (res.code === 20000) {
        this.routeCatalogId = this.toggleKnowledgeBaseAndpositioning(id);
        knowledgeBaseId = res.results.base_id;
      }
      if (!knowledgeBaseId) {
        this.$message.warning('该知识库不存在');
        setTimeout(() => {
          this.reloadPage(knowledgeBaseId);
        }, 1500);
      } else {
        this.reloadPage(knowledgeBaseId);
      }
    },
    reloadPage(knowledgeBaseId) {
      this.$router.replace({
        path: `${this.$route.fullPath}&knowledgeBaseId=${knowledgeBaseId}`
      });
      location.reload();
    },
    toggleKnowledgeBaseAndpositioning(id) {
      id = Number(id);
      // 从当前知识库数据中找到该知识点的父节点id
      const traversalCatalogTree = node => {
        node.forEach(item => {
          var _item$children;
          if ((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
            if (item.children.some(item => item.id === id)) {
              // 高亮父节点
              this.currentNode = item;
              // 滚动到指定知识点
              this.targetKnowledge = {
                id
              };
            } else {
              traversalCatalogTree(item.children);
            }
          }
        });
      };
      traversalCatalogTree(this.catalogData);
    },
    getCurrentNode() {
      const routeJumpTarget = this.getNodeByRoute();
      if (this.targetNode && (0, _commonFunc.getTreeNodeById)(this.catalogData, this.targetNode.id)) {
        if (this.targetNode.is_know) {
          // 知识点，返回它的父级目录
          this.toggleKnowledgeBaseAndpositioning(this.targetNode.id);
          return this.currentNode;
        } else {
          return this.targetNode;
        }
      } else if (routeJumpTarget) {
        return routeJumpTarget;
      } else {
        return this.catalogData[0];
      }
    },
    handleNodeClick(data, parentNodeData) {
      if (data.is_know) {
        // 滚动到指定的知识点位置
        this.targetKnowledge = data;
        data = parentNodeData;
      } else {
        this.targetKnowledge = {};
      }
      this.currentNode = data;
      this.targetNode = data;
    },
    handleMoveKnowledge(data, parentNodeData) {
      this.targetKnowledge = data;
      this.currentNode = parentNodeData;
      this.moveDialogVisible = true;
    },
    async moveSuccess(knowledgeId) {
      // knowledgeId 是被移动的知识点id
      this.targetNode = (0, _commonFunc.getTreeNodeById)(this.catalogData, knowledgeId);
      await this.getCatalogData();
      this.$refs.catalogPart.$refs.catalog.loading = false;
      // 知识点拖动后需要更新右侧知识点列表
      this.targetNode.is_know && this.$nextTick(() => {
        this.$refs['knowledge-part'].getKnowledgeList();
      });
    },
    handleSearch(val) {
      const target = (0, _commonFunc.getTreeNodeById)(this.catalogData, val.fid);
      this.currentNode = {
        ...target
      };
      this.targetNode = target;
      this.targetKnowledge = val;
      this.$refs.catalogPart.$refs.catalog.handleCurrentNodeChange(val);
    },
    getNodeByRoute() {
      if (!this.routeCatalogId) return false;
      const node = (0, _commonFunc.getTreeNodeById)(this.catalogData, this.routeCatalogId);
      if (!node) {
        this.$message.warning('该目录不存在');
        return false;
      }
      return node;
    },
    backfillDrawerData() {
      var _this$$refs$knowledge;
      const form = JSON.parse(JSON.stringify(this.currentKnowledgeInfo));
      form.position = '';
      const keys = ['question_list', 'skill_list', 'scene_reply_list'];
      keys.forEach(key => {
        if (!form[key]) {
          form[key] = [];
        }
      });
      if (form.scene_reply_list.length) {
        form.scene_reply_list.forEach(item => {
          if (!item.reply_list) {
            item.reply_list = [{
              reply_content: '',
              reply_id: 0
            }];
          }
        });
      }
      (_this$$refs$knowledge = this.$refs.knowledgeDrawer) === null || _this$$refs$knowledge === void 0 ? void 0 : _this$$refs$knowledge.backfillConfig(form);
    },
    getRouteQuery(key) {
      return this.$route.query[key];
    }
  }
};
exports.default = _default;