"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-score-correction-aside"
  }, [_c("ScoreTips"), _c("ScoreCorrectionKnowledgeList", {
    attrs: {
      "all-knowledge-list": _vm.allKnowledgeList
    },
    on: {
      acitveTabsChange: _vm.activeTabsChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;