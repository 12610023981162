"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mediaUpload = require("@/api/mediaUpload");
var _default = {
  props: ['isDrag'],
  // 是否启用多拽样式
  data() {
    return {
      fileList: [],
      cancel: '',
      axiosCancel: [],
      arr: [],
      dataArr: []
    };
  },
  methods: {
    // 上传
    uploadNewMedia(item) {
      const file = item.file;
      const data = new FormData();
      const params = {
        begin_time: file.lastModified
      };
      const cb = {
        onUploadProgress: ProgressEvent => {
          // processEvent.loaded 已上传文件大小
          // processEvent.total 需要上传文件的总大小
          console.log(ProgressEvent, 'ProgressEvent');
          const progressPercent = ProgressEvent.loaded / ProgressEvent.total * 100;
          item.onProgress({
            percent: Math.round(progressPercent)
          });
        }
      };
      data.append('file', file);
      (0, _mediaUpload.uploadMedia)(data, params, cb, this).then(res => {
        if (res.status === 200) {
          item.onSuccess(res.data);
        }
      }).catch(err => {
        if (err.message !== '终止请求') {
          item.onError(err);
        }
      });
    },
    onProgress(event, file, fileList) {
      this.fileList = fileList;
      this.$emit('select', this.fileList);
    },
    onSuccess(e, file, fileList) {
      this.fileList = fileList;
      this.fileList.forEach(item => {
        if (item.status === 'success') {
          item.text = item.response.msg;
        }
      });
      this.$emit('select', this.fileList);
    },
    onError(e, file, fileList) {
      this.fileList.forEach(item => {
        if (item.uid === file.uid) {
          item.text = '上传失败';
        }
      });
      this.$emit('select', this.fileList);
    },
    beforeFileUpload(file) {
      const isVideo = /\/(?:mp3|wav|m4a|aiff|acc|ogg|wma|amr|mp4|m4v|avi|wmv|mpeg|3gp|flv)$/.test(file.type);
      const sizeLimit = this.validateSize(file);
      const webStatus = navigator.onLine;
      if (!isVideo) {
        this.$message.error('上传文件格式不符合,请查看上传要求');
      }
      if (!sizeLimit.isSizeValid) {
        this.$message.error(sizeLimit.message);
      }
      if (!webStatus) {
        this.$message.warning('网络异常');
      }
      return isVideo && sizeLimit.isSizeValid && webStatus;
    },
    // 单个音频文件最大500MB,单个视频文件最大4GB
    validateSize(file) {
      var _file$type;
      if (file.name.split('.').pop() === 'amr' || ((_file$type = file.type) === null || _file$type === void 0 ? void 0 : _file$type.split('/')[0]) === 'audio') {
        return {
          isSizeValid: file.size / Math.pow(1024, 2) < 500,
          message: '文件大小不能超过500MB!'
        };
      }
      return {
        isSizeValid: file.size / Math.pow(1024, 3) < 4,
        message: '文件大小不能超过4G!'
      };
    },
    handleRemove(index) {
      if (this.axiosCancel[index].cancel) {
        this.axiosCancel[index].cancel('终止请求');
        this.axiosCancel.splice(index, 1);
      }
    },
    handleAllRemove(len) {
      for (let i = 0; i < len; i++) {
        if (this.axiosCancel[i].cancel) {
          this.axiosCancel[i].cancel('终止请求');
        }
      }
      this.axiosCancel = [];
    },
    onExeed(files, fileList) {
      this.$message({
        type: 'error',
        message: '单次上传文件数量不可超过100个'
      });
    }
  }
};
exports.default = _default;