"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "two-factor-login"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.step === "second",
      expression: "step === 'second'"
    }],
    staticClass: "left-back",
    on: {
      click: function ($event) {
        _setup.step = "first";
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  }), _c("span", [_vm._v("返回")])]), _c("div", {
    staticClass: "center-title"
  }, [_vm._v(_vm._s(_setup.getLoginTitle()))])]), _setup.step === "first" ? _c("div", {
    staticClass: "first-step"
  }, [_c("el-form", {
    key: _setup.step,
    ref: "firstStepFormRef",
    staticClass: "step-form",
    attrs: {
      model: _setup.firstStepForm,
      rules: _setup.formRules,
      "hide-required-asterisk": "",
      "validate-on-rule-change": false
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "account"
    }
  }, [_c("el-input", {
    ref: "account",
    attrs: {
      placeholder: `请输入登录${_setup.ACCOUNT_TYPE_MAP[_setup.accountType].label}`,
      type: "tel",
      tabindex: "1",
      name: "username",
      autocomplete: "on",
      maxlength: _setup.accountType === "tel" ? 11 : null
    },
    model: {
      value: _setup.firstStepForm.account,
      callback: function ($$v) {
        _vm.$set(_setup.firstStepForm, "account", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "firstStepForm.account"
    }
  })], 1), _setup.validateType === "password" ? _c("el-form-item", {
    key: _setup.validateType,
    attrs: {
      prop: "verify_code"
    }
  }, [_c(_setup.Password, {
    ref: "password",
    attrs: {
      placeholder: "请输入密码",
      tabindex: "2"
    },
    on: {
      enter: _setup.handleFirstStepSubmit
    },
    model: {
      value: _setup.firstStepForm.verify_code,
      callback: function ($$v) {
        _vm.$set(_setup.firstStepForm, "verify_code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "firstStepForm.verify_code"
    }
  })], 1) : _vm._e(), _setup.validateType === "code" ? _c("el-form-item", {
    key: _setup.validateType,
    staticClass: "send-code",
    attrs: {
      prop: "verify_code"
    }
  }, [_c(_setup.verificationCodeInput, {
    key: _setup.step,
    ref: "firstCodeInputRef",
    attrs: {
      "code-type": "login",
      tel: _setup.firstStepForm.account,
      email: _setup.firstStepForm.account,
      "sending-destination": _setup.accountType,
      "max-length": "6",
      "no-slider": "",
      "is-direct-send": ""
    },
    model: {
      value: _setup.firstStepForm.verify_code,
      callback: function ($$v) {
        _vm.$set(_setup.firstStepForm, "verify_code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "firstStepForm.verify_code"
    }
  })], 1) : _vm._e(), _c("el-button", {
    staticClass: "handle-btn",
    attrs: {
      loading: _setup.firstSubmitLoading,
      type: "primary",
      "native-type": "submit",
      disabled: !_setup.readChecked
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _setup.handleFirstStepSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v("下一步")])], 1), _c("div", {
    staticClass: "read-protocol"
  }, [_c("div", {
    staticClass: "read-protocol-text"
  }, [_c("el-checkbox", {
    model: {
      value: _setup.readChecked,
      callback: function ($$v) {
        _setup.readChecked = $$v;
      },
      expression: "readChecked"
    }
  }), _c("span", {
    staticClass: "read-text"
  }, [_vm._v("我已阅读并同意")]), _c("a", {
    staticClass: "protocol-text",
    attrs: {
      href: _setup.serviceAgreementUrl,
      target: "_blank"
    }
  }, [_vm._v("  服务协议 ")]), _c("span", {
    staticClass: "read-text"
  }, [_vm._v("和")]), _c("a", {
    staticClass: "protocol-text",
    attrs: {
      href: _setup.privacyPolicyUrl,
      target: "_blank"
    }
  }, [_vm._v(" 隐私政策  ")])], 1)])], 1) : _vm._e(), _setup.step === "second" ? _c("div", {
    staticClass: "second-step"
  }, [_c("div", {
    staticClass: "validate-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("p", {
    staticClass: "tip-content"
  }, [_vm._v(" 为了你的账号安全，需完成身份认证，请输入发送至 " + _vm._s(_setup.secondVerify.email || _setup.secondVerify.tel) + " 的 4 位验证码，有效期10分钟。 ")])]), _c("el-form", {
    ref: "secondStepFormRef",
    staticClass: "step-form",
    attrs: {
      model: _setup.secondStepForm,
      rules: _setup.formRules,
      "hide-required-asterisk": "",
      "validate-on-rule-change": false
    }
  }, [_c("el-form-item", {
    key: _setup.step,
    staticClass: "send-code",
    attrs: {
      prop: "second_verify_code"
    }
  }, [_c(_setup.verificationCodeInput, {
    key: _setup.step,
    ref: "secondCodeInputRef",
    attrs: {
      "code-type": "factor",
      tel: _setup.secondVerify.tel,
      email: _setup.secondVerify.email,
      "sending-destination": _setup.validateToolType,
      "max-length": "6",
      "no-slider": "",
      "is-direct-send": ""
    },
    model: {
      value: _setup.secondStepForm.second_verify_code,
      callback: function ($$v) {
        _vm.$set(_setup.secondStepForm, "second_verify_code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "secondStepForm.second_verify_code"
    }
  })], 1), _c("el-button", {
    staticClass: "handle-btn",
    attrs: {
      loading: _setup.secondSubmitLoading,
      type: "primary",
      "native-type": "submit"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _setup.handleSecondStepSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v("登录")])], 1)], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;