"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "scale-line"
  }, [_c("div", {
    staticClass: "scale-line-scroll",
    style: {
      transform: `translateX(${_vm.scrollTrans}px)`,
      transition: ".6s"
    }
  }, _vm._l(_vm.dateList, function (item) {
    return _c("div", {
      key: item.date,
      staticClass: "date-item"
    }, [_c("div", {
      staticClass: "scale",
      class: {
        bold: item.isBold,
        today: item.date === _vm.today
      }
    }), item.date === _vm.today ? _c("div", {
      staticClass: "text today"
    }, [_vm._v("今天")]) : _vm._e(), item.isBold && _vm.isVisibleDate(item.date) ? _c("div", {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.date).format("MM-DD")) + " ")]) : _vm._e()]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;