"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _user = require("@/api/user");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserOperationCard',
  props: {
    name: {
      type: String,
      default: ''
    },
    organization: {
      type: String,
      default: ''
    },
    userType: {
      type: String,
      default: ''
    },
    trialExpireDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      languageList: [],
      currentLanguage: 'zh',
      languagePopoverVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['avatar', 'routesTree', 'loginTel', 'email', 'interfacePermission']),
    managementPagePermission() {
      return this.routesTree.filter(item => item.path === _routeMap.ROUTES.set).length;
    }
  },
  created() {
    if (this.interfacePermission.getLanguageList) {
      this.getLanguageList();
      this.currentLanguage = localStorage.getItem('lang') || 'zh';
    } else {
      this.currentLanguage = 'zh';
    }
    localStorage.setItem('lang', this.currentLanguage);
    _jsCookie.default.set('lang', this.currentLanguage);
  },
  methods: {
    async getLanguageList() {
      const res = await (0, _user.getLanguageList)();
      if (res.code === 20000) {
        this.languageList = res.results;
      }
    },
    handleClick(type, isProtect) {
      this.$emit('operation', type, isProtect);
    },
    selectLanguage(item) {
      localStorage.setItem('lang', item.code);
      _jsCookie.default.set('lang', item.code);
      location.reload();
    }
  }
};
exports.default = _default;