"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layoutMixins = _interopRequireDefault(require("./components/layoutMixins.js"));
var _PersonBarChart = _interopRequireDefault(require("./components/PersonBarChart.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationActivity',
  components: {
    PersonBarChart: _PersonBarChart.default
  },
  mixins: [_layoutMixins.default],
  data() {
    return {
      convParams: {
        sort_order: 'descending'
      },
      chartDesc: {
        sum_count: {
          title: '总通话数',
          subTitle: '',
          cardValue: 0,
          value: []
        },
        sum_duration: {
          title: '总通话时长',
          subTitle: '',
          cardValue: 0,
          value: []
        },
        avg_duration: {
          title: '平均通话时长',
          subTitle: '',
          cardValue: 0,
          value: []
        }
      },
      active: 'sum_count',
      xAxisList: []
    };
  },
  methods: {
    handleResults() {
      this.xAxisList = this.chartData.dates.length ? this.chartData.dates.map(item => item.date) : [];
      for (const key in this.chartDesc) {
        this.chartDesc[key].cardValue = this.chartData[key] || 0;
        this.chartDesc[key].subTitle = this.chartData.labels[key].explanation;
        this.chartDesc[key].value = this.chartData.dates.length ? this.chartData.dates.map(item => item[`day_${key}`]) : [];
      }
    }
  }
};
exports.default = _default;