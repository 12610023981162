"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.config = void 0;
var _settings = require("@/settings");
// eslint-disable-next-line prefer-const

const CONFIG = {
  development: {
    wsHost: 'ws://' + window.location.host,
    website: 'https://alpha.mgvai.cn',
    passCaptchaAppId: '193120264',
    codeCaptchaAppId: '198762301',
    targets: ['https://jirui.dev.mgvai.cn']
  },
  test: {
    wsHost: 'wss://' + 'jirui.test.mgvai.cn',
    website: 'https://alpha.mgvai.cn',
    passCaptchaAppId: '199995378',
    codeCaptchaAppId: '195444746',
    targets: ['https://jirui.test2.mgvai.cn', 'https://jirui.test.mgvai.cn'],
    googleClientId: '767822651765-m2cq9s97ggg40vjv9hjlr7h84j3isud9.apps.googleusercontent.com'
  },
  production: {
    wsHost: 'wss://' + window.location.host,
    website: 'https://www.megaview.com',
    passCaptchaAppId: '196606049',
    codeCaptchaAppId: '196363797',
    targets: ['https://app.megaview.com', 'https://dudutalk.app.megaview.com', 'https://prepublish2.megaview.com', 'https://prepublish.megaview.com'],
    googleClientId: '767822651765-hahemqdpb20eqv7a3gsp7jt771dfj8in.apps.googleusercontent.com'
  },
  image: {
    wsHost: 'wss://' + window.location.host,
    website: 'https://www.megaview.com',
    passCaptchaAppId: '196606049',
    codeCaptchaAppId: '196363797',
    targets: ['https://app.megaview.com', 'https://dudutalk.app.megaview.com', 'https://prepublish2.megaview.com', 'https://prepublish.megaview.com'],
    googleClientId: '767822651765-hahemqdpb20eqv7a3gsp7jt771dfj8in.apps.googleusercontent.com'
    // wsHost: 'jirui.test.mgvai.cn',
    // website: 'https://alpha.mgvai.cn',
    // passCaptchaAppId: '199995378',
    // codeCaptchaAppId: '2035547465',
    // targets: ['https://jirui.test2.mgvai.cn', 'https://jirui.test.mgvai.cn'],
    // googleClientId:
    //   '767822651765-m2cq9s97ggg40vjv9hjlr7h84j3isud9.apps.googleusercontent.com'
  }
};

let environment = process.env.VUE_APP_TITLE;
if (location.hostname === 'localhost' && environment !== 'image') {
  for (const key in CONFIG) {
    if (CONFIG[key].targets.includes(_settings.apiTarget)) {
      environment = key;
      break;
    }
    environment = 'development';
  }
}
const {
  wsHost,
  website,
  passCaptchaAppId,
  codeCaptchaAppId,
  googleClientId
} = CONFIG[environment];

// 用户协议和隐私政策链接
let privacyPolicyUrl = 'https://www.megaview.com/service-agreement.html';
let serviceAgreementUrl = 'https://www.megaview.com/privacy-policy.html';
const brand = location.host.split('.').slice(-2)[0];
/**
 * @var isMegaview 由于生产环境中，部分团队的域名不是megaview.com（比如上海用歆），且他们要求去除所有Megaview相关的元素，所以需要判断是否是Megaview
 */
const isMegaview = ['megaview', 'mgvai'].includes(brand) || environment !== 'production';
if (!isMegaview) {
  privacyPolicyUrl = `/policy/${brand}/privacy-policy.html`;
  serviceAgreementUrl = `/policy/${brand}/service-agreement.html`;
}
const devWsUrl = 'ws://jirui.test.mgvai.cn';
const config = {
  // 测试代码====================
  // wsUrl: 'ws://jirui.test.mgvai.cn',
  // wsUrl: 'ws://' + '192.168.110.64:2027',
  wsUrl: window.location.hostname === 'localhost' ? devWsUrl : wsHost,
  website,
  passCaptchaAppId,
  codeCaptchaAppId,
  googleClientId,
  privacyPolicyUrl,
  serviceAgreementUrl,
  isMegaview
};
exports.config = config;