"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "新建交易",
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog,
      "custom-class": "business-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "business-title"
  }, [_vm._v("交易信息")]), _c("dynamic-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "createTradeForm",
    attrs: {
      desc: _vm.formDesc,
      "is-edit": _vm.isEdit,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "customer_name",
      fn: function () {
        return [_c("el-select", {
          staticClass: "account-name",
          attrs: {
            remote: "",
            "remote-method": _vm.getAccount,
            clearable: "",
            filterable: "",
            "value-key": "id",
            placeholder: "请选择客户"
          },
          on: {
            change: _vm.customerChange
          },
          model: {
            value: _vm.formData["customer_name"],
            callback: function ($$v) {
              _vm.$set(_vm.formData, "customer_name", $$v);
            },
            expression: "formData['customer_name']"
          }
        }, _vm._l(_vm.accountList, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: {
              label: item.name,
              value: item
            }
          });
        }), 1), _c("el-button", {
          staticClass: "add-account",
          attrs: {
            icon: "iconfont icon-plus"
          },
          on: {
            click: _vm.openAccountTable
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isContactCheckBoxVisible,
      expression: "isContactCheckBoxVisible"
    }]
  }, [_c("div", {
    staticClass: "associate-contact"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.isAssociateContact,
      callback: function ($$v) {
        _vm.isAssociateContact = $$v;
      },
      expression: "isAssociateContact"
    }
  }, [_vm._v("同时关联联系人")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAssociateContact,
      expression: "isAssociateContact"
    }]
  }, [_c("AssociateContactForm", {
    ref: "associateContactForm",
    attrs: {
      "contact-list": _vm.contactList
    }
  })], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;