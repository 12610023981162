"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ScoreSummary',
  components: {},
  props: {
    count: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'conv'
    }
  },
  data() {
    return {};
  },
  methods: {
    countClick() {
      if (this.loading) return;
      this.$emit('countClick');
    }
  }
};
exports.default = _default;