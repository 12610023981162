"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentSingleSelector = _interopRequireDefault(require("@/components/MgTreeSelect/DepartmentSingleSelector"));
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _vuex = require("vuex");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TransferList',
  components: {
    MembersFinder: _MembersFinder.default,
    DepartmentSingleSelector: _DepartmentSingleSelector.default
  },
  props: {
    showTableData: {
      type: Array,
      default: () => []
    },
    isDepartment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      departmentInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['avatar', 'userId', 'name', 'workspaceInfo'])
  },
  methods: {
    getOrgTree: _organization.getOrgTree,
    getPermissionType(type) {
      if (type.includes('trade')) {
        return 'trade';
      }
      if (type.includes('conversation')) {
        return 'conversation';
      }
    },
    handleRowClick(row) {
      if (row.name) {
        this.$set(row, 'type', '');
      }
    },
    handleMemberChange(value, row) {
      var _userInfo$2;
      const [userId, userInfo] = value;
      let avatar;
      if (userInfo.length) {
        var _userInfo$;
        avatar = (_userInfo$ = userInfo[0]) === null || _userInfo$ === void 0 ? void 0 : _userInfo$.avatar;
      } else {
        avatar = this.avatar;
      }
      this.$set(row, 'id', userId);
      this.$set(row, 'name', ((_userInfo$2 = userInfo[0]) === null || _userInfo$2 === void 0 ? void 0 : _userInfo$2.name) || this.name);
      this.$set(row, 'avatar', avatar);
      this.$set(row, 'type', '');
    },
    setDepartmentLabel(value, row) {
      var _departmentInfo$;
      const [departmentId, departmentInfo] = value;
      this.departmentInfo = departmentInfo[0];
      this.$set(row, 'id', departmentId[0]);
      this.$set(row, 'name', (_departmentInfo$ = departmentInfo[0]) === null || _departmentInfo$ === void 0 ? void 0 : _departmentInfo$.name);
      this.$set(row, 'type', '');
    },
    // 点击修改资源指定人按钮
    changeSourseReceiver(row) {
      this.$set(row, 'type', 'edit');
    }
  }
};
exports.default = _default;