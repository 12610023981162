"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "option-template",
    on: {
      click: _vm.handleCheck
    }
  }, [_c("p", {
    staticClass: "name"
  }, [_vm.isMultiple ? _c("span", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleCheck.apply(null, arguments);
      }
    }
  }, [_c("el-checkbox", {
    attrs: {
      value: _vm.checked
    }
  })], 1) : _vm._e(), _c("i", {
    class: ["iconfont", _vm.item.icon || "icon-reading"]
  }), _c("span", {
    staticClass: "search-name-highlight overflow-ellipsis",
    domProps: {
      innerHTML: _vm._s(_vm.getSearchText())
    }
  })]), _c("div", {
    staticClass: "main-content"
  }, [_vm.item.highlight_content ? _c("p", {
    staticClass: "search-content-highlight overflow-ellipsis",
    domProps: {
      innerHTML: _vm._s(_vm.item.highlight_content)
    }
  }) : _vm._e(), _c("p", {
    staticClass: "path overflow-ellipsis"
  }, [_vm._v(_vm._s(_vm.path))])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;