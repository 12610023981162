"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      fit: "",
      "highlight-current-row": ""
    }
  }, [_vm._l(_vm.tableHeads, function (item, index) {
    return [_c("el-table-column", {
      key: index,
      attrs: {
        "show-overflow-tooltip": "",
        label: item.label,
        prop: item.field_name,
        fixed: !index,
        "min-width": "100px"
      },
      scopedSlots: _vm._u([item.field_name === "user_name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("image-avatar", {
            staticClass: "upper-avatar",
            attrs: {
              name: row.user_name,
              avatar: row.avatar,
              shape: "circle"
            }
          }), _c("div", {
            staticClass: "user-name"
          }, [_vm._v(_vm._s(row.user_name))])];
        }
      } : item.field_name === "path" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row.path[index - 1] ? _c("div", {
            staticClass: "table-text"
          }, [_c("div", {
            staticClass: "event-name",
            style: {
              background: _vm.getEventNameBackground(row.path[index - 1].name)
            }
          }, [_vm._v(" " + _vm._s(row.path[index - 1].name) + " ")])]) : _vm._e()];
        }
      } : null], null, true)
    })];
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;