"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.visible,
      size: "90%",
      title: "修正训练集",
      "close-on-click-modal": false,
      "custom-class": "fix-train-dataset-drawer",
      "append-to-body": ""
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.contentLoading,
      expression: "contentLoading"
    }],
    staticClass: "main-container"
  }, [!_setup.hasData && !_setup.contentLoading ? _c("div", {
    staticClass: "no-data-wrapper"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v("没有可修正的数据")])]) : _vm._e(), _setup.hasData && !_setup.contentLoading ? _c("div", {
    staticClass: "train-data-content-wrapper"
  }, [_c("MgvInfoAlert", {
    staticClass: "alert",
    attrs: {
      "alert-key": "fix-train-dataset-drawer"
    }
  }, [_vm._v("根据模型预测错误的句子，我们发现了训练集里可能有问题的标注，请检查并在“命中/未命中”列点击进行修正。")]), _c("div", {
    staticClass: "model-prediction-sentence"
  }, [_c("div", {
    staticClass: "model-prediction-sentence__title"
  }, [_vm._v("模型预测错误句")]), _vm._l(_vm.resultsRowInfo.sentence, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "model-prediction-sentence__content"
    }, [_c("span", {
      class: ["entity-type", {
        "customer-contact": item.entity_type === "customer_contact"
      }, {
        "host-salesman": item.entity_type === "host_salesman"
      }]
    }, [_vm._v(_vm._s(item.entity_type === "host_salesman" ? "销售：" : "客户："))]), _c("span", [_vm._v(_vm._s(item.content))])]);
  })], 2), _c("div", {
    staticClass: "dataset-tabs-container"
  }, [_c("el-tabs", {
    staticClass: "dataset-tabs",
    model: {
      value: _setup.activeDatasetTab,
      callback: function ($$v) {
        _setup.activeDatasetTab = $$v;
      },
      expression: "activeDatasetTab"
    }
  }, [_vm._l(_setup.annotationList, function (dataset) {
    return [_c("el-tab-pane", {
      key: dataset.dataset_id,
      attrs: {
        name: String(dataset.dataset_id)
      }
    }, [_c("div", {
      staticClass: "tab-label-container",
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_c("i", {
      staticClass: "iconfont icon-dataset-fill"
    }), _c("span", {
      staticClass: "dataset-name"
    }, [_vm._v(_vm._s(dataset.dataset_name))]), _c("span", {
      staticClass: "count"
    }, [_vm._v("（" + _vm._s(dataset.annotation_cnt) + "）")])]), _c(_setup.FixTrainDatasetTable, {
      ref: "fixTrainDatasetTableRef",
      refInFor: true,
      attrs: {
        "table-data": dataset.annotations,
        "header-summary-data": dataset,
        loading: _setup.contentLoading
      },
      on: {
        tableSelectionChange: _setup.tableSelectionChange,
        updateAnnotationStatus: (rowArr, status) => _setup.updateAnnotationStatus(dataset, rowArr, status),
        deleteAnnotation: _setup.deleteAnnotation
      }
    })], 1)];
  })], 2), _c("div", {
    staticClass: "operate-btn"
  }, [_vm._l(_setup.dataStatusButtons, function (button) {
    return _c("MgvButton", {
      key: button.status,
      attrs: {
        type: "plain",
        color: _setup.tableSelectionList.length ? "primary" : "info",
        size: "medium",
        disabled: !_setup.tableSelectionList.length
      },
      on: {
        click: function ($event) {
          _setup.updateAnnotationStatus(_setup.annotationList.find(item => item.dataset_id == _setup.activeDatasetTab), _setup.tableSelectionList, button.status);
        }
      }
    }, [_vm._v(_vm._s("批量" + button.text))]);
  }), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: _setup.tableSelectionList.length ? "danger" : "info",
      size: "medium",
      disabled: !_setup.tableSelectionList.length
    },
    on: {
      click: function ($event) {
        _setup.deleteAnnotation(_setup.tableSelectionList.map(item => item.id), true);
      }
    }
  }, [_vm._v("批量删除")]), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      size: "medium"
    },
    on: {
      click: _setup.refresh
    }
  }, [_vm._v("刷新")])], 2)], 1)], 1) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;