"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "TelDial",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"],
      tel: ""
    };
  },
  computed: {},
  mounted() {
    let _this = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 8) {
        _this.deleteLastStr();
      }
    };
  },
  methods: {
    closeTelDial() {
      this.$emit("closeTelDial", this.tel);
      this.tel = "";
    },
    inputNumber(val) {
      this.tel = this.tel.toString() + val.toString();
    },
    deleteLastStr() {
      if (this.tel.length > 0) {
        this.tel = this.tel.substring(0, this.tel.length - 1);
      }
    },
    callTel() {
      this.$emit('callTel', this.tel);
    }
  }
};
exports.default = _default;