"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      visible: _vm.visible,
      width: "600px",
      "close-on-click-modal": false,
      title: _vm.title
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "similarity"
  }, [_c("span", [_vm.title === "提示" ? _c("span", [_vm._v("当前数据量过少，是否")]) : _vm._e(), _c("span", [_vm._v("继续寻找可信度为")])]), _c("el-input-number", {
    attrs: {
      controls: false,
      min: 0,
      max: 1,
      precision: 2
    },
    on: {
      change: _setup.changeConfidence
    },
    model: {
      value: _setup.confidence,
      callback: function ($$v) {
        _setup.confidence = $$v;
      },
      expression: "confidence"
    }
  }), _c("span", [_vm._v("以上的文本句")])], 1), _c("div", {
    staticClass: "annotation-status"
  }, [_c("div", {
    staticClass: "annotation-status-desc"
  }, [_vm._v("文本句自动标注为")]), _c("div", {
    staticClass: "annotation-status-content"
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("命中")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("未命中")]), _c("el-radio", {
    attrs: {
      label: 3
    },
    model: {
      value: _setup.annotation_status,
      callback: function ($$v) {
        _setup.annotation_status = $$v;
      },
      expression: "annotation_status"
    }
  }, [_vm._v("不确定")])], 1)])]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  }, [_vm._v("暂不导入")]), _c("MgvButton", {
    attrs: {
      loading: _setup.btnLoading,
      disabled: !_setup.confidence
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("确定导入")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;