"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "progress-loading"
  }, [_c("el-progress", _vm._b({
    attrs: {
      percentage: _vm.percentage,
      color: _vm.color
    }
  }, "el-progress", _vm.$attrs, false)), _c("span", [_vm._v(_vm._s(_vm.text))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;