"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    ref: "extractInfoSettingDrawer",
    attrs: {
      "custom-class": "extract-info-setting-drawer",
      visible: _vm.visible,
      size: "90%",
      "before-close": _setup.closeDialog,
      "append-to-body": ""
    },
    on: {
      opened: function ($event) {
        _setup.drawerOpened = true;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticClass: "drawer-header"
  }, [_c("span", {
    staticClass: "header-title"
  }, [_vm._v("配置信息提取指令 "), _c("el-tooltip", {
    attrs: {
      manual: !_setup.drawerOpened,
      content: "指令会从会话中提取问题答案，并从简返回结果；若配置了答案描述，会按答案描述中举例的选项返回结果。",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1 header-tip"
  })])], 1)])]), _c("div", {
    staticClass: "content-container"
  }, [_c("div", {
    staticClass: "content-top"
  }, [_c("mgv-button", {
    attrs: {
      icon: "icon-plus",
      disabled: _setup.tableData.length >= 100,
      "tooltip-content": _setup.tableData.length >= 100 ? "指令数量上限100" : ""
    },
    on: {
      click: _setup.addInstruction
    }
  }, [_vm._v("添加指令")])], 1), _c(_setup.InstructionTable, {
    staticClass: "summary-template-table",
    attrs: {
      data: _setup.tableData,
      "table-loading": _setup.tableLoading
    },
    on: {
      editInstruction: _setup.editInstruction,
      deleteInstruction: _setup.deleteInstruction
    }
  })], 1), _c(_setup.CreateInstructionDialog, {
    attrs: {
      visible: _setup.createInstructionDrawerVisible,
      "back-info": _setup.activeInstruction,
      type: _setup.submitType
    },
    on: {
      "update:visible": function ($event) {
        _setup.createInstructionDrawerVisible = $event;
      },
      submitSucceed: _setup.getExtractInfoInstructionListRequest
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;