import { render, staticRenderFns } from "./CourseCatalog.vue?vue&type=template&id=d5d39f12&scoped=true&"
import script from "./CourseCatalog.vue?vue&type=script&lang=js&"
export * from "./CourseCatalog.vue?vue&type=script&lang=js&"
import style0 from "./CourseCatalog.vue?vue&type=style&index=0&id=d5d39f12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d39f12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5d39f12')) {
      api.createRecord('d5d39f12', component.options)
    } else {
      api.reload('d5d39f12', component.options)
    }
    module.hot.accept("./CourseCatalog.vue?vue&type=template&id=d5d39f12&scoped=true&", function () {
      api.rerender('d5d39f12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coach/course/components/CourseCatalog.vue"
export default component.exports