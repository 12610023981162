"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      title: "添加到指令集",
      width: "600px",
      "append-to-body": ""
    },
    on: {
      "update:visible": _setup.closeDialog
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _setup.form,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "指令名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入指令名称",
      maxlength: 30
    },
    model: {
      value: _setup.form.name,
      callback: function ($$v) {
        _vm.$set(_setup.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "指令内容",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入指令内容，上限600字，例如：\n1.平均通话时长是多少？\n2.有几次会话中客户提到了竞品，占比多少？\n3.客户常见的顾虑是什么？",
      maxlength: "600",
      type: "textarea",
      resize: "none",
      autosize: {
        minRows: 1,
        maxRows: 20
      },
      "show-word-limit": ""
    },
    model: {
      value: _setup.form.content,
      callback: function ($$v) {
        _vm.$set(_setup.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "生效范围",
      prop: "departments"
    }
  }, [_c("MgvDepartmentMultiSelect", {
    ref: "mgvDepartmentMultiSelectRef",
    attrs: {
      "request-method": _setup.getOrgTree,
      "show-tag-count": 10,
      placeholder: "请选择部门",
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleDepartmentValueChange
    }
  })], 1)], 1), _c("footer", {
    staticClass: "footer-btns"
  }, [_c("el-button", {
    on: {
      click: _setup.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.saveLoading
    },
    on: {
      click: _setup.saveForm
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;