"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _modelInfo = require("../hooks/modelInfo");
var _index = require("@/utils/index.js");
var _modelManagement = require("@/api/modelManagement.js");
var _TestDetailDrawer = _interopRequireDefault(require("./TestDetailDrawer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TestRecordsDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentVersion: {
      type: Number,
      default: 0
    },
    modelId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'reTraining'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;

    // 记录列表
    const recordsList = (0, _vue.ref)([]);
    // 记录表头
    const recordsHeader = (0, _vue.ref)([{
      prop: 'create_at',
      label: '测试时间',
      minWidth: 200
    }, {
      prop: 'precision',
      label: '精确率',
      minWidth: 100
    }, {
      prop: 'recall',
      label: '召回率',
      minWidth: 100
    }, {
      prop: 'f1',
      label: 'F1',
      minWidth: 100
    }, {
      prop: 'model_version',
      label: '版本',
      minWidth: 80
    }, {
      prop: 'dataset',
      label: '测试集',
      minWidth: 200
    }, {
      prop: 'dataset_label',
      label: '关键事件',
      minWidth: 200
    }, {
      prop: 'create_user',
      label: '测试人',
      minWidth: 150
    }, {
      prop: 'operate',
      label: '操作',
      minWidth: 150
    }]);
    // 获取测试记录
    const tableLoading = (0, _vue.ref)(false);
    async function getRecordsListApi() {
      tableLoading.value = true;
      const res = await (0, _modelManagement.getModelTestRecordList)({
        model_id: props.modelId,
        model_version: activeVersion.value
      }).finally(() => tableLoading.value = false);
      if (res.code === 20000) {
        recordsList.value = res.results.data;
      }
    }
    // 获取所有版本

    const {
      getModelVersionListApi,
      allVersionsList
    } = (0, _modelInfo.useGetModelAllVersions)({
      modelId: props.modelId,
      currentPage: 1,
      pageSize: 9999
    });
    const activeVersion = (0, _vue.ref)('');
    (0, _vue.watch)(() => props.currentVersion, val => {
      getModelVersionListApi();
      activeVersion.value = val;
    }, {
      immediate: true
    });
    (0, _vue.watchEffect)(() => {
      getRecordsListApi();
    });
    // 抽屉关闭

    const updateVisible = () => emit('update:visible', false);

    // 打开测试详情抽屉
    const activeRow = (0, _vue.ref)(null);
    const detailDrawerVisible = (0, _vue.ref)(false);
    const openTestDetail = row => {
      activeRow.value = row;
      detailDrawerVisible.value = true;
    };

    // 跳转到数据集
    const {
      goDataSet
    } = (0, _modelInfo.useGoDataSet)();
    return {
      __sfc: true,
      props,
      recordsList,
      recordsHeader,
      tableLoading,
      getRecordsListApi,
      getModelVersionListApi,
      allVersionsList,
      activeVersion,
      emit,
      updateVisible,
      activeRow,
      detailDrawerVisible,
      openTestDetail,
      goDataSet,
      handlePercentage: _index.handlePercentage,
      TestDetailDrawer: _TestDetailDrawer.default
    };
  }
};
exports.default = _default;