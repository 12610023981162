"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BarChart',
  mixins: [_chartMixins.default],
  // 接受父组件传递进来的颜色
  props: {
    color: String,
    title: {
      type: String,
      default: ''
    },
    chartLoading: Boolean
  },
  data() {
    return {
      options: {
        title: {
          text: '',
          left: 'center',
          textStyle: {
            fontSize: 16
          }
        },
        avoidLabelOverlap: true,
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            rotate: 45,
            width: 70,
            overflow: 'truncate'
          }
        },
        yAxis: {
          type: 'value',
          name: '出现次数',
          minInterval: 1
        },
        series: [{
          data: [],
          type: 'bar',
          itemStyle: {
            normal: {
              color: () => this.color
            }
          }
        }],
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
      }
    };
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        const [xAxis, data] = [[], []];
        val.forEach(_ref => {
          let {
            name,
            value
          } = _ref;
          xAxis.push(name);
          data.push(value);
        });
        this.options.xAxis.data = xAxis;
        const x = xAxis.length;
        this.options.xAxis.axisLabel.width = x > 4 ? 70 : 170 - 25 * x;
        this.options.series[0].data = data;
        this.options.xAxis.axisLabel.rotate = xAxis.length < 5 ? 0 : 45;
        this.options.title.text = this.title + '详情';
        this.options.xAxis.show = !!data.length;
        this.options.yAxis.show = !!data.length;
        this.init();
      },
      deep: true
    }
  }
};
exports.default = _default;