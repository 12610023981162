"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "task-executor-info"
  }, [_vm._l(_vm.list.slice(0, _vm.exposedCount), function (user) {
    return _c("div", {
      key: user.id,
      staticClass: "executor-avatar"
    }, [_vm._t("exposed-avatar-prefix", null, {
      exposedItem: user
    }), _vm._t("exposed-avatar", function () {
      return [_c("el-tooltip", {
        attrs: {
          effect: "dark",
          content: user.name,
          placement: "top"
        }
      }, [_c("mgv-avatar-name", {
        staticClass: "member-avatar",
        attrs: {
          name: user.name,
          src: user.avatar,
          size: _vm.exposedAvatarSize
        }
      })], 1)];
    }, {
      exposedItem: user
    }), _vm._t("exposed-avatar-suffix", null, {
      exposedItem: user
    })], 2);
  }), _c("el-popover", {
    attrs: {
      placement: "bottom",
      width: "200",
      trigger: "hover",
      "open-delay": 100,
      "popper-class": "virtual-more-user-list-in-popover",
      "append-to-body": _vm.appendToBody
    },
    on: {
      show: _setup.showMoreUserList
    }
  }, [_c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_setup.collapasCount > 0 ? _c("div", {
    staticClass: "count"
  }, [_vm._v("+" + _vm._s(_setup.collapasCount))]) : _vm._e()]), _c(_setup.VirtualList, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    class: ["virtual-list-container", {
      loading: _setup.loading
    }],
    attrs: {
      "data-key": _vm.virtualListDataKey,
      "data-sources": _vm.list.slice(_vm.exposedCount),
      "data-component": {},
      keeps: 20
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        let {
          item
        } = _ref;
        return [_vm._t("virtual-list-item", function () {
          return [_c("div", {
            staticClass: "virtaul-list-item"
          }, [_vm._t("virtual-list-item-prefix", null, {
            virtualListItem: item
          }), _c("div", {
            staticClass: "avatar-name"
          }, [_c("mgv-avatar-name", {
            attrs: {
              name: item.name,
              src: item.avatar,
              size: _vm.virtualListItemAvatarSize
            }
          }), _c("span", [_vm._v(_vm._s(item.name))])], 1), _vm._t("virtual-list-item-suffix", null, {
            virtualListItem: item
          })], 2)];
        }, {
          virtualListItem: item
        })];
      }
    }], null, true)
  })], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;