"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskDetailCard = _interopRequireDefault(require("./components/TaskDetailCard"));
var _TaskTableFilter = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskTableFilter"));
var _SalesTaskTable = _interopRequireDefault(require("@/views/coaching/coach/coachTask/sales/SalesTaskTable"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _coachingCenter = require("@/api/coachingCenter");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _taskUtils = require("../task-utils.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachingCenterSales',
  components: {
    MenuToggleBtn: _MenuToggleBtn.default,
    TaskTableFilter: _TaskTableFilter.default,
    SalesTaskTable: _SalesTaskTable.default,
    NoData: _NoData.default,
    TaskDetailCard: _TaskDetailCard.default
  },
  inject: ['isEmbed'],
  beforeRouteLeave(to, from, next) {
    if (to.path.includes(_routeMap.ROUTES.coaching)) {
      sessionStorage.setItem('coachingTaskListParamsByMyView', JSON.stringify(this.requestParams));
    }
    next();
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: true
    },
    refreshSign: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      requestParams: {
        name: '',
        creator_id: 0,
        space_id: '',
        status: -1,
        page: 1,
        size: 10,
        order_field: '',
        order_type: ''
      },
      tableData: [],
      tableLoading: false,
      totalCount: 0,
      taskDetailVisible: false,
      currentTaskInfo: {},
      currentUserTaskStatus: -1,
      cachePageNumber: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId'])
  },
  watch: {
    refreshSign() {
      this.initData();
      this.getSalesTaskList();
    }
  },
  created() {
    const {
      origin,
      id
    } = this.$route.query;
    // if (origin === 'message') {
    //   this.showTaskDetail({ id });
    // }
    const {
      page,
      size
    } = JSON.parse(sessionStorage.getItem('coachingTaskListParamsByMyView') || '{}');
    this.cachePageNumber = page;
    this.requestParams.size = size || 10;
    this.requestParams.page = page || 1;
  },
  methods: {
    goBack() {
      this.taskDetailVisible = false;
    },
    async showTaskDetail(val) {
      const res = await (0, _coachingCenter.getCoachingTaskInfo)({
        id: val.id
      });
      if (res.code === 20000) {
        this.currentTaskInfo = res.results.data;
        this.currentTaskInfo.taskId = val.id;
        this.taskDetailVisible = true;
      }
      if (![4, 5].includes(Number(this.currentTaskInfo.course_type))) {
        this.getTaskScore({
          id: val.id,
          user_id: this.userId
        });
      }
    },
    tableSortChange(val) {
      this.requestParams.order_field = val.prop;
      this.requestParams.order_type = val.order;
      this.initData();
      this.getSalesTaskList();
    },
    async getSalesTaskList() {
      try {
        this.tableLoading = true;
        this.formatParams();
        const res = await (0, _coachingCenter.getSalesTaskList)(this.formatParams());
        if (res.code === 20000) {
          this.tableData = (0, _taskUtils.formatCombineTaskList)(res.results.data);
          this.totalCount = res.results.total_count;
        }
      } finally {
        this.tableLoading = false;
      }
    },
    async getTaskScore(data) {
      const res = await (0, _coachingCenter.getTaskScore)(data);
      if (res.code === 20000) {
        this.currentUserTaskStatus = res.results.data.status;
      }
    },
    initData() {
      this.requestParams.page = this.cachePageNumber || 1;
      this.cachePageNumber = 0;
    },
    tableSizeChange(val) {
      this.requestParams.size = val;
      this.initData();
      this.getSalesTaskList();
    },
    tableCurrentPageChange(val) {
      this.requestParams.page = val;
      this.getSalesTaskList();
    },
    filterChange(val) {
      this.requestParams = {
        ...this.requestParams,
        ...val
      };
      this.initData();
      this.getSalesTaskList();
    },
    formatParams() {
      // 后端 0 是有意义的，所以判断空串
      const params = {
        ...this.requestParams
      };
      if (params.status === '') {
        params.status = -1;
      }
      if (params.space_id === '') {
        params.space_id = 0;
      }
      if (params.course_type === '') {
        params.course_type = 0;
      }
      if (params.creator_id === '') {
        params.creator_id = 0;
      }
      // delete params.creator_id;
      return params;
    }
  }
};
exports.default = _default;