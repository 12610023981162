"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeItem = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeItem.vue"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KnowledgeDetailDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    knowledgeId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const knowledge = (0, _vue.ref)({});
    const loading = (0, _vue.ref)(false);
    (0, _vue.watch)(() => props.visible, val => {
      console.log(val);
      if (val) {
        loading.value = true;
        (0, _knowledgeLibrary.getKnowledgeInfo)({
          id: props.knowledgeId
        }).then(res => {
          if (res.code === 20000) {
            knowledge.value = res.results.data;
          } else {
            knowledge.value = null;
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      knowledge,
      loading,
      KnowledgeItem: _KnowledgeItem.default,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;