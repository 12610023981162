"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataSetSelectors = _interopRequireDefault(require("./DataSetSelectors.vue"));
var _ModelAndVersionSelect = _interopRequireDefault(require("./ModelAndVersionSelect.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
var _modelManagement = require("@/api/modelManagement.js");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TestModelDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataSetId: {
      type: Number,
      default: 0
    },
    version: {
      type: Number,
      default: 0
    },
    modelId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'submitSucceed'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => emit('update:visible', false);
    const modelDetail = (0, _vue.ref)({});
    const testDataSet = (0, _vue.ref)([]);
    const trainDataSet = (0, _vue.ref)([]);
    const testDataFill = (0, _vue.ref)([]);
    const localModelId = (0, _vue.ref)('');
    const localModelVersion = (0, _vue.ref)('');
    (0, _vue.watchEffect)(async () => {
      if (props.modelId && props.visible) {
        const params = {
          id: props.modelId,
          version: props.version
        };
        const res = await (0, _modelManagement.getModelDetail)(params);
        modelDetail.value = res.results;
        testDataSet.value = res.results.trial_datasets;
        trainDataSet.value = res.results.train_datasets;
        testDataFill.value = (0, _cloneDeep.default)(res.results.trial_datasets);
      }
    });
    (0, _vue.watch)([() => props.dataSetId, () => props.visible], () => {
      if (props.dataSetId && props.visible) {
        testDataSet.value = [{
          dataset_id: props.dataSetId,
          dataset_label_id: ''
        }];
        testDataFill.value = [{
          dataset_id: props.dataSetId,
          dataset_label_id: ''
        }];
      }
    }, {
      immediate: true
    });
    const ModelAndVersionSelectChange = val => {
      localModelId.value = val.model_id;
      localModelVersion.value = val.model_version;
    };
    const startTest = async () => {
      const testSet = testDataSet.value;
      if (!testSet.length) {
        _elementUi.Message.warning('请选择测试集');
        return;
      }
      const params = {
        model_id: localModelId.value || props.modelId,
        model_version: localModelVersion.value || props.version,
        dataset_id: testSet[0].dataset_id,
        dataset_label_id: testSet[0].dataset_label_id
      };
      const res = await (0, _modelManagement.createTestTask)(params);
      if (res.code === 20000) {
        _elementUi.Message.success('开始测试');
        emit('submitSucceed', props.modelId);
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      modelDetail,
      testDataSet,
      trainDataSet,
      testDataFill,
      localModelId,
      localModelVersion,
      ModelAndVersionSelectChange,
      startTest,
      DataSetSelectors: _DataSetSelectors.default,
      ModelAndVersionSelect: _ModelAndVersionSelect.default
    };
  }
};
exports.default = _default;