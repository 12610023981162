"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer create-summary-template-drawer",
    attrs: {
      visible: _vm.visible,
      title: _setup.title,
      size: "800px"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _setup.templateForm,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "form-item-title margin-bottom-20"
  }, [_vm._v("基础信息")]), _c("el-form-item", {
    attrs: {
      label: "指令名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入名称，上限20字",
      maxlength: "20"
    },
    model: {
      value: _setup.templateForm.name,
      callback: function ($$v) {
        _vm.$set(_setup.templateForm, "name", $$v);
      },
      expression: "templateForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "生效范围",
      prop: "tree_ids"
    }
  }, [_c("MgvDepartmentMultiSelect", {
    ref: "mgvDepartmentMultiSelectRef",
    attrs: {
      "request-method": _setup.getOrgTree,
      "show-tag-count": 10,
      placeholder: "请选择部门",
      "backfill-list": _setup.templateForm.tree_ids,
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleDepartmentValueChange
    }
  })], 1), _c("div", {
    staticClass: "form-item-title"
  }, [_c("span", {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("指令内容 ")]), _c("div", {
    staticClass: "form-item-sub-title"
  }, [_vm._v(" AI会按指令内容要求，自动为每通新会话填写纪要 ")]), _c(_setup.FieldItems, {
    ref: "fieldItemsRef",
    attrs: {
      "need-validate": "",
      "get-key-place-holder": "请输入要求标题，上限20字，如：商机关键信息",
      "get-value-place-holder": () => "请输入要求内容，上限600字，如：\n1.客户是否咨询过竞品\n2.客户的预算\n3.客户的顾虑",
      "key-props": {
        maxlength: 20
      },
      "value-props": {
        maxlength: 600,
        rows: 4
      },
      "max-items-length": 10,
      "item-label": "要求"
    },
    model: {
      value: _setup.formContent,
      callback: function ($$v) {
        _setup.formContent = $$v;
      },
      expression: "formContent"
    }
  })], 1)], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.saveSummaryTemplate
    }
  }, [_vm._v(_vm._s(_vm.type === "create" ? "创建" : "保存"))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;