"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentFilters = _interopRequireDefault(require("./components/DepartmentFilters"));
var _vuex = require("vuex");
var _export = require("@/api/export");
var _DepartmentHeader = _interopRequireDefault(require("./components/DepartmentHeader.vue"));
var _DepartmentInfo = _interopRequireDefault(require("./components/DepartmentInfo.vue"));
var _OrgInvitation = _interopRequireDefault(require("@/components/OrgInvitation"));
var _CopyInvitation = _interopRequireDefault(require("@/components/CopyInvitation"));
var _Inactivated = _interopRequireDefault(require("@/components/Inactivated"));
var _DepartmentMembers = _interopRequireDefault(require("@/components/Department/DepartmentMembers"));
var _membersFinderDialog = _interopRequireDefault(require("../components/membersFinderDialog"));
var _MemberInfo = _interopRequireDefault(require("../components/MemberInfo"));
var _MemberSingleSelectDialog = _interopRequireDefault(require("@/components/MemberSingleSelectDialog"));
var _BatchModify = _interopRequireDefault(require("../components/BatchModify"));
var _UserScaleDialog = _interopRequireDefault(require("../components/UserScaleDialog"));
var _resignedMember = _interopRequireDefault(require("@/views/resignedMember"));
var _AddNewMember = _interopRequireDefault(require("../components/AddNewMember"));
var _DataTransferDrawer = _interopRequireDefault(require("@/components/DataTransferDrawer"));
var _MoveDepartmentDialog = _interopRequireDefault(require("./components/MoveDepartmentDialog"));
var _organization = require("@/api/organization");
var _routeMap = require("@/router/routeMap");
var _workspace = require("@/api/workspace");
var _permissions = require("@/api/permissions");
var _lodash = require("lodash");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentMember',
  components: {
    DepartmentFilters: _DepartmentFilters.default,
    DepartmentHeader: _DepartmentHeader.default,
    DepartmentInfo: _DepartmentInfo.default,
    OrgInvitation: _OrgInvitation.default,
    CopyInvitation: _CopyInvitation.default,
    Inactivated: _Inactivated.default,
    BatchModify: _BatchModify.default,
    DepartmentMembers: _DepartmentMembers.default,
    membersFinderDialog: _membersFinderDialog.default,
    UserScaleDialog: _UserScaleDialog.default,
    MemberSingleSelectDialog: _MemberSingleSelectDialog.default,
    ResignedMember: _resignedMember.default,
    AddNewMember: _AddNewMember.default,
    MemberInfo: _MemberInfo.default,
    DataTransferDrawer: _DataTransferDrawer.default,
    MoveDepartmentDialog: _MoveDepartmentDialog.default
  },
  data() {
    return {
      // 筛选项
      filters: {
        filter: '',
        workspace_id: 0,
        user_type: '',
        is_active: '',
        is_enable: ''
      },
      // 当前正在操作的id
      operateDepartmentId: 0,
      moveDepartmentDialogVisible: false,
      dataTransferDrawerVisible: false,
      transferDepartmentTitle: '',
      isDeleteDepartment: false,
      canTransferData: [],
      currentDepartmentInfo: {},
      showDepartmentAndMember: true,
      multipleSelect: [],
      // 勾选批量修改用户的ids
      userMultipleArray: [],
      // 勾选批量修改用户的ids和name
      listLoading: true,
      userList: [],
      memberCount: 0,
      limitCount: -1,
      allMemberCounter: 0,
      inviteVisible: false,
      addMemberVisible: false,
      showType: '',
      account: '',
      params: {
        current_page: 1,
        size: 10
      },
      resultsTotal: 0,
      batchModifyVisible: false,
      copyVisible: false,
      inactivatedVisible: false,
      statusCount: 0,
      openDelay: 500,
      orgTree: [],
      orgTreeLoading: true,
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      currNode: null,
      currData: null,
      showAddDepartment: false,
      departmentName: '',
      // 编辑和添加部门时绑定的数据
      // root: {node: null, data: null},
      currName: '',
      batchUploadProps: {
        dialogVisible: false,
        isMembers: true
      },
      isSearch: '',
      deptId: '',
      dialogVisible: false,
      memberInfoVisible: false,
      userPassword: '',
      departmentExpanded: [],
      hasChildren: true,
      membersFinderVisible: false,
      userScaleDialogVisible: false,
      workspaceList: [],
      isViolation: false,
      isDirectMember: false,
      isSwitchDisabled: false,
      departmentCharge: {
        name: '',
        id: 0
      },
      departmentChargeDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'orgName']),
    getDay() {
      return function (value) {
        return this.$moment(value).format('YYYY-MM-DD');
      };
    },
    ENV_TITLE() {
      return process.env.VUE_APP_TITLE;
    }
  },
  watch: {
    orgTree(val, oldVal) {
      if (val && oldVal.length === 0) {
        // 初始默认展开第一层
        this.departmentExpanded.push(Number(val[0].id));
      }
      if (this.currNode === null) {
        if (this.orgTree) {
          // 第一次进入页面时初始化 currNode等
          this.currName = this.orgTree.name;
          this.$nextTick(() => {
            var _this$orgTree$, _this$$refs$departmen, _this$orgTree$2, _this$orgTree$3;
            this.currNode = this.$refs['department-tree'].getNode((_this$orgTree$ = this.orgTree[0]) === null || _this$orgTree$ === void 0 ? void 0 : _this$orgTree$.id);
            this.currData = (_this$$refs$departmen = this.$refs['department-tree'].getNode((_this$orgTree$2 = this.orgTree[0]) === null || _this$orgTree$2 === void 0 ? void 0 : _this$orgTree$2.id)) === null || _this$$refs$departmen === void 0 ? void 0 : _this$$refs$departmen.data;
            this.$refs['department-tree'].setCurrentKey((_this$orgTree$3 = this.orgTree[0]) === null || _this$orgTree$3 === void 0 ? void 0 : _this$orgTree$3.id);
          });
        }
      } else {
        // 切换tab时保证保留原来的node点击状态
        this.$nextTick(() => {
          this.$refs['department-tree'].setCurrentKey(this.currNode.data.id);
        });
      }
    }
  },
  created() {
    this.getAllWorkspace();
    if (localStorage.isDirectMember !== undefined) {
      this.isDirectMember = JSON.parse(localStorage.isDirectMember);
    }
    this.fetchDepartmentData();
  },
  methods: {
    // 筛选项发生变化
    handleFilterChange(val) {
      this.filters = val;
      this.params.current_page = 1;
      this.getUserList();
    },
    // 移动部门成功后的回调
    onMoveDepartmentSuccess(val) {
      this.departmentExpanded.push(Number(val));
      this.fetchDepartmentData();
    },
    // 移动部门
    moveDepartment(node, data) {
      this.moveDepartmentDialogVisible = true;
      this.operateDepartmentId = data.id;
    },
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    },
    exportDepartment(node, data) {
      _elementUi.MessageBox.confirm(`<i style="color:#4461EC;margin-right:6px" class="iconfont icon-info-2"></i>${this.$t('management.确定导出部门信息吗？')}`, this.$t('management.导出部门'), {
        confirmButtonText: this.$t('management.确定'),
        cancelButtonText: this.$t('management.取消'),
        dangerouslyUseHTMLString: true
      }).then(async () => {
        const res = await (0, _export.exportDepartment)({
          tree_id: data.id
        });
        if (res.code === 20000) {
          if (_elementUi.Notification) {
            _elementUi.Notification.closeAll();
          }
          const h = this.$createElement;
          (0, _elementUi.Notification)({
            title: this.$t('management.导出文件'),
            message: h('div', {
              class: 'notify-content'
            }, [h('span', this.$t('management.文件正在准备中，您可以在个人中心')), h('a', {
              class: 'jump-link',
              on: {
                click: this.goExportRecords
              }
            }, this.$t('management.导出记录')), h('span', this.$t('management.中查看。'))]),
            duration: 5000,
            customClass: 'conversation-list-export-file-notify'
          });
        }
      }).catch(() => {});
    },
    exportMembersApi() {
      var _this$orgTree$4;
      return (0, _export.exportMembers)({
        tree_id: this.currData ? this.currData.id : (_this$orgTree$4 = this.orgTree[0]) === null || _this$orgTree$4 === void 0 ? void 0 : _this$orgTree$4.id,
        filter: this.filters.filter,
        is_active: this.filters.is_active === '' ? null : this.filters.is_active,
        is_enable: this.filters.is_enable === '' ? null : this.filters.is_enable,
        only_direct: this.isDirectMember,
        user_type: this.filters.user_type,
        workspace_id: this.filters.workspace_id || 0,
        user_ids: this.multipleSelect
      });
    },
    openMemberInfoDialog(info) {
      this.memberInfoVisible = true;
      this.userPassword = info.password;
      this.showType = info.type;
      this.account = info.account || '';
    },
    async getAllWorkspace() {
      const res = await (0, _workspace.getAllWorkspace)();
      if (res.code === 20000) {
        this.workspaceList = res.results.list;
      }
    },
    goDepartmentAndMember() {
      this.showDepartmentAndMember = true;
    },
    goResignedMemberPage() {
      this.showDepartmentAndMember = false;
    },
    fetchUserInfoList(val) {
      localStorage.isDirectMember = val;
      this.params.current_page = 1;
      this.isSwitchDisabled = true;
      this.getUserList();
    },
    // 成员多选ids
    handleMultipleSelect(ids, userArray) {
      this.multipleSelect = ids;
      this.userMultipleArray = userArray;
    },
    // 编辑成员数据成功之后刷新页面
    freshCurrentList() {
      this.getUserList();
    },
    handleNameInput(val) {
      if (this.departmentName.length > val.length) {
        this.isViolation = false;
      }
      this.departmentName = val.replace(/[,\/]/g, '');
    },
    nameKeypress($event) {
      if (!$event.key.replace(/[,\/]/g, '')) {
        this.isViolation = true;
      } else {
        this.isViolation = false;
      }
    },
    openAddDialog() {
      this.dialogVisible = true;
    },
    handleChange() {
      this.dialogVisible = false;
    },
    remoteMethod() {
      this.params.current_page = 1;
      this.getUserList();
    },
    // 获取列表用户数据
    getUserList: (0, _lodash.throttle)(async function () {
      var _this$orgTree, _this$orgTree$5;
      if (!((_this$orgTree = this.orgTree) !== null && _this$orgTree !== void 0 && _this$orgTree.length)) return;
      const requestParams = {
        tree_id: this.currData ? this.currData.id : (_this$orgTree$5 = this.orgTree[0]) === null || _this$orgTree$5 === void 0 ? void 0 : _this$orgTree$5.id,
        current_page: this.params.current_page,
        size: this.params.size,
        only_direct: this.isDirectMember,
        ...this.filters
      };
      this.listLoading = true;
      const res = await (0, _organization.getOrgUserList)(requestParams).finally(() => {
        this.listLoading = false;
        this.isSwitchDisabled = false;
      });
      if (res.code === 200) {
        const {
          users,
          count,
          charge_name,
          charge_id,
          license_used,
          license_upper_limit
        } = res.results;
        this.userList = users;
        this.memberCount = count;
        this.resultsTotal = count;
        this.departmentCharge = {
          id: charge_id,
          name: charge_name
        };
        if (count) {
          this.allMemberCounter = license_used;
          this.limitCount = license_upper_limit;
        }
      }
    }, 500),
    handleSizeChange(val) {
      this.params.size = val;
      this.params.current_page = 1;
      this.getUserList();
    },
    handleCurrentChange(currentPage) {
      if (this.params.size * currentPage > 10000) {
        this.params.current_page = 10000 / this.params.size;
      } else {
        this.params.current_page = currentPage;
      }
      this.getUserList();
    },
    fetchDepartmentData() {
      // 组织tab 获取数据
      this.listLoading = true;
      this.orgTreeLoading = true;
      (0, _permissions.getDepartmentWithAuth)().then(res => {
        // 获取组织树
        if (res.code === 200) {
          this.orgTree = res.results;
          if (!this.currName) {
            this.$nextTick(() => {
              var _this$orgTree$6, _this$orgTree$7, _this$orgTree$8;
              this.currName = (_this$orgTree$6 = this.orgTree[0]) === null || _this$orgTree$6 === void 0 ? void 0 : _this$orgTree$6.name;
              this.isSearch = (_this$orgTree$7 = this.orgTree[0]) === null || _this$orgTree$7 === void 0 ? void 0 : _this$orgTree$7.node_type;
              this.deptId = (_this$orgTree$8 = this.orgTree[0]) === null || _this$orgTree$8 === void 0 ? void 0 : _this$orgTree$8.id;
            });
          }
        }
        this.orgTreeLoading = false;
        this.getUserList();
      });
      this.listLoading = false;
    },
    // 对邀请人员数做判断
    invitePerson() {
      if (this.limitCount === -1 || this.allMemberCounter < this.limitCount) {
        this.inviteVisible = true;
      } else {
        this.openScaleConfirm();
      }
    },
    addPerson() {
      if (this.limitCount === -1 || this.allMemberCounter < this.limitCount) {
        this.addMemberVisible = true;
      } else {
        this.openScaleConfirm();
      }
    },
    openScaleConfirm() {
      this.$confirm(this.$t('management.员工数已达上限'), this.$t('management.提示'), {
        confirmButtonText: this.$t('management.申请扩容'),
        cancelButtonText: this.$t('management.取消'),
        type: 'warning'
      }).then(() => {
        this.openUserScaleDialog();
      });
    },
    closeUserScaleDialog() {
      this.userScaleDialogVisible = false;
    },
    openUserScaleDialog() {
      this.userScaleDialogVisible = true;
    },
    openBatchModifyInfo() {
      this.batchModifyVisible = true;
    },
    openInactivated() {
      this.inactivatedVisible = true;
    },
    openBatchUpload() {
      if (this.limitCount === -1 || this.allMemberCounter < this.limitCount) {
        this.$bus.$emit('openBatchUploadDialog', 'employee');
      } else {
        this.openScaleConfirm();
      }
    },
    closeCopy() {
      this.copyVisible = false;
    },
    closeInactivated() {
      this.inactivatedVisible = false;
    },
    handleDepartmentNodeClick(data, node) {
      // 点击部门树的节点时触发
      this.$refs['departmentFiltersRef'].initFilters();
      this.filters = this.$options.data().filters;
      if (this.currNode !== node) {
        this.$refs['department-members'].clearDepartmentMulti();
        this.currName = data.name;
        this.isSearch = data.node_type;
        this.deptId = data.id;
        this.currNode = node;
        this.currData = data;
        this.params.current_page = 1;
        this.params.size = 10;
        this.getUserList();
      } else {
        this.getUserList();
      }
    },
    editDepartmentName(node, data) {
      // 编辑部门名称
      this.departmentName = data.name;
      this.$set(data, 'editType', 'edit');
      this.$nextTick(() => {
        this.$refs['department-input'].focus();
      });
    },
    setDepartmentCharge() {
      this.departmentChargeDialogVisible = true;
    },
    submitDepartmentEdit(node, data) {
      // 提交部门修改（失焦或enter后触发）
      const _editType = data.editType;
      if (_editType === 'edit') {
        // 编辑节点
        if (data.name === this.departmentName.replace(/[\(\)@]/g, '') || this.departmentName === '') {
          // 未修改或者为空则不作任何操作
        } else {
          // 修改部门名称
          (0, _organization.editDepartment)(this.departmentName, data.id).then(res => {
            if (res.code === 200) {
              this.$set(data, 'name', this.departmentName);
              if (this.currData.id === data.id) {
                // 如果修改的是当前选中部门名字，需要更新currName
                this.currName = this.departmentName;
              }
            }
          }).catch(() => {
            this.$set(data, 'editType', null);
          });
        }
      } else if (_editType === 'add') {
        // 新增节点
        if (this.departmentName === '') {
          // 未设置名字则删除这个节点
          this.remove(node, data);
        } else {
          (0, _organization.createDepartment)(this.departmentName, node.parent.data.id).then(res => {
            if (res.code === 20000) {
              const _id = res.results;
              this.$set(data, 'name', this.departmentName);
              this.$set(data, 'id', _id);
              this.fetchDepartmentData();
              this.currNode = node;
              this.currData = node.data;
              this.deptId = node.data.id;
              this.currName = node.data.name;
            } else {
              this.remove(node, data);
            }
          }).catch(() => {
            this.remove(node, data);
          });
        }
      }
      this.$set(data, 'editType', null);
      this.isViolation = false;
    },
    focusMethod() {
      this.$nextTick(() => {
        this.$refs['department-input'].focus();
      });
    },
    appendDepartment(node) {
      if (node === null) {
        node = this.$refs['department-tree'].root.childNodes[0];
      }

      // 确保是当前树的节点
      node = this.$refs['department-tree'].getNode(node.data.id);
      this.departmentName = '';
      const newChild = {
        name: '',
        editType: 'add'
      };
      if (!node.data.children) {
        this.$set(node.data, 'children', []);
      }
      node.data.children.push(newChild);
      this.departmentExpanded.push(node.data.id);
      setTimeout(() => {
        if (!this.$refs['department-input']) {
          node = this.$refs['department-tree'].root.childNodes[0];
          node.data.children.push(newChild);
        } else {
          this.$refs['department-input'].focus();
        }
      }, 50);
      setTimeout(() => {
        this.$refs['department-input'].focus();
      }, 50);
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d === data);
      if (index !== -1) {
        children.splice(index, 1);
      }
    },
    async transferDepartmentData(node, data) {
      this.currentDepartmentInfo = data;
      // 判断是否有可转移的数据
      const res = await (0, _organization.getDepartmentTransferData)({
        tree_id: data.id
      });
      if (res.code === 20000) {
        if (res.results.has_task) {
          this.$message.warning(this.$t('management.此部门数据已在转移中'));
        } else {
          this.canTransferData = res.results.data;
          // 数据转移操作
          if (this.canTransferData.length) {
            this.isDeleteDepartment = false;
            this.transferDepartmentTitle = this.$t('management.转移部门数据');
            this.dataTransferDrawerVisible = true;
          } else {
            this.$message({
              message: this.$t('management.此部门没有可转移的数据'),
              center: true,
              type: 'warning'
            });
          }
        }
      }
    },
    handleDepartmentData(node, data) {
      this.getMemberCount(data).then(async res => {
        if (res || node.childNodes.length) {
          this.$message({
            message: this.$t('management.该部门下已有成员或部门，不能被删除。'),
            type: 'warning',
            center: true
          });
        } else {
          this.currentDepartmentInfo = data;
          // 判断是否有可转移的数据
          const res = await (0, _organization.getDepartmentTransferData)({
            tree_id: data.id
          });
          if (res.code === 20000) {
            this.canTransferData = res.results.data;
            // 数据转移操作
            if (this.canTransferData.length) {
              if (res.results.has_task) {
                this.$message.warning(this.$t('management.此部门数据正在转移中，无法删除'));
              } else {
                this.transferDeletingDepartmentData();
              }
            } else {
              this.removeDepartment(data);
            }
          }
        }
      });
    },
    transferDeletingDepartmentData() {
      const noPermission = this.canTransferData.some(item => !item.can_edit);
      if (noPermission) {
        const noPermissionTypeArr = [...new Set(this.canTransferData.filter(item => !item.can_edit).map(item => {
          if (item.type.includes('trade')) {
            return this.$t('management.交易');
          }
          if (item.type.includes('conversation')) {
            return this.$t('management.会话');
          }
        }))];
        this.$confirm(`${this.$t('management.删除部门前需转移部门数据，你没有此部门的')}${noPermissionTypeArr.join('、')}${this.$t('management.编辑权限，请先前往')}<a style="color: #4461EC" href="${_routeMap.ROUTES.dataPermission}" target="_blank">${this.$t('management.数据权限')}</a>${this.$t('management.页面配置')}`, this.$t('management.无编辑权限'), {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
          cancelButtonText: this.$t('management.我知道了'),
          type: 'warning'
        }).catch(() => {});
      } else {
        this.isDeleteDepartment = true;
        this.transferDepartmentTitle = this.$t('management.删除前转移部门数据');
        this.dataTransferDrawerVisible = true;
      }
    },
    removeDepartment(data) {
      this.$confirm(this.$t('management.删除部门'), {
        title: this.$t('management.删除部门'),
        message: this.$t('management.是否删除该部门？'),
        confirmButtonText: this.$t('management.删除'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('management.取消'),
        type: 'warning'
      }).then(async () => {
        this.currentDepartmentInfo = data;
        // 判断是否有可转移的数据
        const res = await (0, _organization.getDepartmentTransferData)({
          tree_id: data.id
        });
        if (res.code === 20000) {
          this.canTransferData = res.results.data;
          // 数据转移操作
          if (this.canTransferData.length) {
            this.transferDeletingDepartmentData();
          } else {
            const _is = this.currData.id === data.id;
            (0, _organization.deleteDepartment)(data.id).then(res => {
              if (res.code === 200) {
                if (_is || this.isChild(data, this.currData.id)) {
                  // 当前选中节点为删除的子节点
                  this.currName = '';
                  this.currNode = null;
                  this.currData = null;
                }
                this.$message({
                  message: this.$t('management.删除成功'),
                  type: 'success'
                });
                this.fetchDepartmentData();
              }
            });
          }
        }
      });
    },
    getMemberCount(data) {
      return new Promise(resolve => {
        // 判断选中部门与被删除部门是否一致
        if (data.id !== this.deptId || this.isDirectMember) {
          const askParams = {
            tree_id: data.id,
            current_page: 1,
            size: 10,
            only_direct: false
          };
          (0, _organization.getOrgUserList)(askParams).then(res => {
            if (res.code === 200) {
              resolve(res.results.count);
            }
          });
        } else {
          resolve(this.memberCount);
        }
      });
    },
    closeInviteDialog() {
      this.inviteVisible = false;
    },
    closeAddMemberDialog() {
      this.addMemberVisible = false;
    },
    isChild(data, id) {
      const ids = [];
      this.getTreeChildrenIds(data, ids);
      return ids.indexOf(id) !== -1;
    },
    getTreeChildrenIds(data, ids) {
      data.children.forEach(item => {
        ids.push(item.id);
        this.getTreeChildrenIds(item, ids);
      });
    },
    getUserId(userId) {
      // 获取设置部门负责人时的id
      (0, _organization.setCharge)({
        tree_id: this.deptId,
        user_id: userId
      }).then(res => {
        const {
          user_id,
          user_name
        } = res.results;
        this.departmentCharge = {
          id: user_id,
          name: user_name
        };
        this.$refs.setChargeDom.handleClose();
      }).catch(() => {
        this.$refs.setChargeDom.submitLoading = false;
      });
    }
  }
};
exports.default = _default;