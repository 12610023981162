"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "org-switch-list"
  }, [_vm._l(_vm.invitations, function (item) {
    return [_c("li", {
      key: item.organization_id,
      class: ["invite-org", "org-item", {
        "is-inactive-item": !item.is_active
      }],
      on: {
        click: function ($event) {
          return _vm.selectOrg(item.organization_id, "invit");
        }
      }
    }, [_c("div", {
      staticClass: "org-name",
      class: {
        "cur-org-name": item.organization_id === _vm.activeOrgId
      }
    }, [_c("span", {
      staticClass: "name-text"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "cur-org-mark invite-color"
    }, [_vm._v("(新的公司邀请)")])]), item.is_active ? _c("div", [_c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.organization_id === _vm.activeOrgId,
        expression: "item.organization_id === activeOrgId"
      }],
      staticClass: "el-icon-success"
    }), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.organization_id !== _vm.activeOrgId,
        expression: "item.organization_id !== activeOrgId"
      }],
      staticClass: "circle"
    })]) : _c("div", [_vm._m(0, true)])])];
  }), _vm._l(_vm.organizations, function (item) {
    return [_c("li", {
      key: item.organization_id,
      on: {
        click: function ($event) {
          return _vm.selectOrg(item.organization_id, "org");
        }
      }
    }, [_c("div", {
      class: ["org-item", {
        "is-inactive-item": !item.is_active
      }, {
        "cur-item": item.organization_id === _vm.activeOrgId
      }]
    }, [_c("div", {
      staticClass: "org-name",
      class: {
        "cur-org-name": item.organization_id === _vm.activeOrgId
      }
    }, [_c("span", {
      staticClass: "name-text"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.organization_id === _vm.orgId,
        expression: "item.organization_id === orgId"
      }],
      staticClass: "cur-org-mark"
    }, [_vm._v("(当前公司)")])]), item.is_active ? _c("div", [_c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.organization_id === _vm.activeOrgId,
        expression: "item.organization_id === activeOrgId"
      }],
      staticClass: "el-icon-success"
    }), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.organization_id !== _vm.activeOrgId,
        expression: "item.organization_id !== activeOrgId"
      }],
      staticClass: "circle"
    })]) : _c("div", [_vm._m(1, true)])])])];
  })], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "is-inactive"
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated"
  }), _vm._v(" 已禁用 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "is-inactive"
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated"
  }), _vm._v(" 已禁用 ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;