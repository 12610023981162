"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      title: _vm.isEdit ? "编辑问题" : "新建问题",
      "before-close": _vm.handleClose,
      width: "600px",
      "append-to-body": "",
      "custom-class": "create-question-dialog"
    }
  }, [_vm.createAndMove ? _c("div", {
    staticClass: "answer-container"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("销售回答")]), _c("answer-item", {
    staticClass: "answer-item",
    attrs: {
      "conv-item": _vm.currentQaItem,
      "is-show-desc": false,
      "is-show-operate": false
    }
  })], 1) : _vm._e(), _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.questionInfo,
      rules: _vm.rules,
      "label-width": "100px",
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("问题名称"), _c("span", [_vm._v("*")])]), _c("el-form-item", {
    attrs: {
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入问题名称",
      clearable: "",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.questionInfo.name,
      callback: function ($$v) {
        _vm.$set(_vm.questionInfo, "name", $$v);
      },
      expression: "questionInfo.name"
    }
  })], 1), _c("div", {
    staticClass: "question-tip"
  }, [_c("span", [_vm._v("问答归类依据")]), _c("el-tooltip", {
    attrs: {
      placement: "bottom"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" 您可以提供一些关键词或短句，以便我们更好的对新的销售问答进行归类（与关键词/短句意思相近的销售问答将归类于此问题），已有的销售问答不会重新归类。"), _c("br"), _c("br"), _vm._v("关键词/短句尽量简洁唯一，不要与其他问题的重复。"), _c("br"), _c("br"), _vm._v("示例："), _c("br"), _vm._v("问题名称：能不能分期？"), _c("br"), _vm._v("问答归类依据："), _c("br"), _vm._v("关键词：分期"), _c("br"), _vm._v("短 句1：如何办理分期"), _c("br"), _vm._v("短 句2：可以分期支付吗 ")]), _c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "label"
  }, [_vm._v("关键词")]), _vm._l(_vm.questionInfo.keyword, function (word, index) {
    return _c("el-form-item", {
      key: `keyword.${index}`,
      staticClass: "keyword-item"
    }, [_c("el-input", {
      attrs: {
        placeholder: "请输入关键词",
        clearable: "",
        maxlength: "20",
        "show-word-limit": ""
      },
      model: {
        value: _vm.questionInfo.keyword[index],
        callback: function ($$v) {
          _vm.$set(_vm.questionInfo.keyword, index, $$v);
        },
        expression: "questionInfo.keyword[index]"
      }
    }), !index ? _c("el-button", {
      attrs: {
        icon: "iconfont icon-plus"
      },
      on: {
        click: function ($event) {
          return _vm.addWordOrSentence("keyword");
        }
      }
    }) : _c("el-button", {
      attrs: {
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          return _vm.removeWordOrSentence("keyword", index);
        }
      }
    })], 1);
  }), _c("div", {
    staticClass: "label"
  }, [_vm._v("短句")]), _vm._l(_vm.questionInfo.sentence, function (sentence, index) {
    return _c("el-form-item", {
      key: `sentence.${index}`,
      staticClass: "keyword-item"
    }, [_c("el-input", {
      attrs: {
        placeholder: "请输入句子",
        clearable: "",
        maxlength: "20",
        "show-word-limit": ""
      },
      model: {
        value: _vm.questionInfo.sentence[index],
        callback: function ($$v) {
          _vm.$set(_vm.questionInfo.sentence, index, $$v);
        },
        expression: "questionInfo.sentence[index]"
      }
    }), !index ? _c("el-button", {
      attrs: {
        icon: "iconfont icon-plus"
      },
      on: {
        click: function ($event) {
          return _vm.addWordOrSentence("sentence");
        }
      }
    }) : _c("el-button", {
      attrs: {
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          return _vm.removeWordOrSentence("sentence", index);
        }
      }
    })], 1);
  })], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.createAndMove ? "新建问题并将该回答移入" : "保存"))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;