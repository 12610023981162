"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TransferUserInfo = _interopRequireDefault(require("./components/TransferUserInfo"));
var _TransferList = _interopRequireDefault(require("./components/TransferList"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DataTransferDrawer',
  components: {
    TransferUserInfo: _TransferUserInfo.default,
    TransferList: _TransferList.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    transferFromUserInfo: {
      type: Object,
      default: () => ({})
    },
    canTransferData: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    transferType: {
      type: String,
      default: 'user'
    },
    isDeleteDepartment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transferTypeList: [],
      checkedTransferType: [],
      // 选择的要转移数据类型
      tableData: [],
      showTableData: [],
      confirmButtonLoading: false,
      finalTransferData: [] // 最终转移的数据
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['avatar', 'userId', 'name', 'orgId']),
    confirmButtonDisabled() {
      return this.transferType !== 'resign' && !this.checkedTransferType.length;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.getUserCanTransferData();
        });
      } else {
        this.showTableData = [];
      }
    },
    checkedTransferType: {
      handler(val) {
        this.showTableData = this.tableData.filter(item => val.includes(item.dataType));
      },
      deep: true
    }
  },
  methods: {
    getPermissionType(type) {
      if (type.includes('trade')) {
        return this.$t('management.交易');
      }
      if (type.includes('conversation')) {
        return this.$t('management.会话');
      }
    },
    goDataPermissionPage() {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.dataPermission
      });
      window.open(routeData.href, '_blank');
    },
    handleRefresh() {
      this.handleDataTranferDrawerClose();
      this.$emit('refresh');
    },
    getUserCanTransferData() {
      this.checkedTransferType = this.transferType === 'resign' || this.isDeleteDepartment ? this.canTransferData.filter(item => item.can_edit).map(item => item.type) : [];
      this.tableData = [];
      // 根据可转移数据确定 table 列表数据
      this.canTransferData.forEach(item => {
        this.tableData.push({
          resourcesName: item.name,
          dataType: item.type,
          name: '',
          id: '',
          type: 'edit',
          avatar: ''
        });
      });
    },
    async handleClickConfirm() {
      // 确定转移的数据
      this.finalTransferData = this.showTableData.filter(item => item.type !== 'edit');
      // 离职
      if (this.transferType === 'resign') {
        this.handleConfirmResign();
      } else {
        // 数据转移
        this.handleDataTransferConfirm();
      }
    },
    // 操作离职
    async handleConfirmResign() {
      // 有未转移的数据(离职提醒)
      const hasUntransferData = this.finalTransferData.length !== this.canTransferData.length;
      if (hasUntransferData) {
        this.unTransferDataConfirm();
      } else {
        // 数据转移 && 离职
        await this.transferData();
        await this.userResign();
        this.handleRefresh();
      }
    },
    // 操作离职时有未转移数据提醒
    unTransferDataConfirm() {
      this.$confirm(`<p>${this.$t('management.还有未转移的数据，确定要将{0}设置为离职吗？', [this.transferFromUserInfo.name])}</p><p class="un-transfer-data">${this.$t('management.未转移数据将保留，所有者仍展示离职成员')}</p>`, this.$t('management.离职成员'), {
        customClass: 'operate-user-quit-confirm',
        confirmButtonText: this.$t('management.确定'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('management.取消'),
        type: 'warning',
        dangerouslyUseHTMLString: true,
        beforeClose: async (action, ctx, close) => {
          if (action !== 'confirm') {
            close();
            return;
          }
          ctx.confirmButtonLoading = true;
          try {
            if (this.finalTransferData.length) {
              // 数据转移确认
              await this.transferData();
            }
            await this.userResign();
            close();
            this.handleRefresh();
          } finally {
            ctx.confirmButtonLoading = false;
          }
        }
      }).finally(() => {});
    },
    handleDataTransferConfirm() {
      // 校验勾选的转移数据中，有未指定资源接收方
      const noReceiver = this.showTableData.some(item => item.type === 'edit');
      if (noReceiver) {
        this.$message({
          message: `${this.$t('management.请选择数据接收')}${this.transferType === 'department' ? this.$t('management.部门') : this.$t('management.人')}`,
          center: true,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$t('management.确定要转移数据吗？'), this.$t('management.转移数据'), {
          customClass: 'operate-user-quit-confirm',
          confirmButtonText: this.$t('management.转移'),
          cancelButtonText: this.$t('management.取消'),
          type: 'warning',
          beforeClose: async (action, ctx, close) => {
            if (action !== 'confirm') {
              close();
              return;
            }
            ctx.confirmButtonLoading = true;
            try {
              await this.transferData();
              close();
              this.handleRefresh();
            } finally {
              ctx.confirmButtonLoading = false;
            }
          }
        }).finally(() => {});
      }
    },
    async transferData() {
      const params = {
        tasks: []
      };
      switch (this.transferType) {
        case 'user':
        case 'resign':
          params.source_user_id = this.transferFromUserInfo.id ? this.transferFromUserInfo.id : this.transferFromUserInfo.user_id;
          this.finalTransferData.forEach(item => {
            params.tasks.push({
              target_user_id: item.id[0],
              type: item.dataType
            });
          });
          break;
        case 'department':
          params.source_tree_id = this.transferFromUserInfo.id;
          this.finalTransferData.forEach(item => {
            params.tasks.push({
              target_tree_id: item.id,
              type: item.dataType
            });
          });
          break;
      }
      const method = this.transferType === 'department' ? _organization.transferDepartmentData : _organization.transferData;
      const res = await method(params);
      if ((res.code === 200 || res.code === 20000) && this.transferType !== 'resign') {
        this.$message({
          message: this.$t('management.转移已开始，请耐心等候，完成时会再次消息通知您'),
          center: true,
          type: 'success'
        });
      }
    },
    async userResign() {
      const res = await (0, _organization.deleteUser)(this.transferFromUserInfo.id, this.orgId);
      if (res.code === 200) {
        this.$message({
          message: this.$t('management.离职成功'),
          center: true,
          type: 'success'
        });
      }
    },
    handleDataTranferDrawerClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;