"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MoreFilters = _interopRequireDefault(require("@/views/business/MoreFilters"));
var _ConversationFilterItem = _interopRequireDefault(require("@/views/conversationPage/components/ConversationFilterItem.vue"));
var _routeMap = require("@/router/routeMap");
var _dealFilter = _interopRequireDefault(require("@/views/business/components/dealFilter"));
var _business = require("@/api/business");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealCustomFieldFilter',
  components: {
    MoreFilters: _MoreFilters.default,
    DealCustomFilter: _dealFilter.default,
    ConversationFilterItem: _ConversationFilterItem.default
  },
  props: {
    customFilters: {
      type: Object,
      default: () => ({})
    },
    isShowLabel: {
      type: Boolean,
      default: false
    },
    isShowClear: {
      type: Boolean,
      default: false
    },
    defaultMoreFilter: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      customFilterObject: {},
      filterParams: {},
      customFilterPromise: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    filterList() {
      return Object.values(this.customFilterObject).flat().filter(item => item === null || item === void 0 ? void 0 : item.show);
    },
    showCustomFilterClear() {
      return customFilter => {
        return !!(this.isShowClear && customFilter && customFilter.length);
      };
    }
  },
  watch: {
    filterList(newVal, oldVal) {
      const decreaseFields = this.findDecreaseFields(newVal, oldVal);
      const isChange = decreaseFields.some(item => this.filterParams[item]);
      decreaseFields.map(item => {
        delete this.filterParams[item];
      });
      if (isChange) {
        this.$emit('customFilterChange', this.filterParams);
      }
    },
    customFilters: {
      handler(val) {
        var _this$customFilterPro;
        this.filterParams = val;
        (_this$customFilterPro = this.customFilterPromise) === null || _this$customFilterPro === void 0 ? void 0 : _this$customFilterPro.then(() => {
          // 会话页选择已保存的筛选器时，设置商机自定义筛选器的显示
          const customFilterArray = Object.values(this.customFilterObject)[0];
          for (const key in val) {
            customFilterArray.find(item => {
              if (item.field_name === key) {
                item.show = 1;
              }
            });
          }
          this.customFilterObject = {
            交易自定义字段: customFilterArray
          };
        });
      },
      deep: true,
      immediate: true
    },
    workspaceInfo: {
      handler: function () {
        this.getDealCustomFilter();
      },
      immediate: true
    }
  },
  methods: {
    handelFilterItemClear(val) {
      this.$emit('clearCustomFilterItem', val);
    },
    async saveFilters(val) {
      // 培训高频问题添加默认筛选字段
      this.$emit('showDefaultFilter', val);
      const defaultFilterField = this.defaultMoreFilter.map(_ref => {
        let {
          field_name
        } = _ref;
        return field_name;
      });
      // 培训高频问题添加默认筛选字段
      const data = {
        customize_field: val.filter(item => !defaultFilterField.includes(item)),
        access_type: this.$route.path
      };
      if ([_routeMap.ROUTES.biTeamAnalysis, _routeMap.ROUTES.biSOPAnalysis, _routeMap.ROUTES.biSOPFunnel].includes(this.$route.path)) {
        data.workspace_id = this.workspaceInfo.id;
      }
      const res = await (0, _business.postDealCustomFilter)(data);
      if (res.code === 200) {
        this.getDealCustomFilter();
      }
    },
    customFilterChange(val) {
      this.filterParams = {
        ...this.filterParams,
        ...val
      };
      this.$emit('customFilterChange', this.filterParams, val);
    },
    getDealCustomFilter() {
      this.customFilterPromise = new Promise(resolve => {
        const params = {
          access_type: this.$route.path
        };
        if ([_routeMap.ROUTES.biTeamAnalysis, _routeMap.ROUTES.biSOPAnalysis, _routeMap.ROUTES.biSOPFunnel].includes(this.$route.path)) {
          params.workspace_id = this.workspaceInfo.id;
        }
        (0, _business.getDealCustomFilter)(params).then(res => {
          this.customFilterObject = Array.isArray(res.results) ? {
            交易自定义字段: res.results
          } : res.results;
          if (this.defaultMoreFilter.length) {
            this.customFilterObject = {
              默认字段: this.defaultMoreFilter,
              商机自定义字段: res.results
            };
          }
          resolve();
        });
      });
    },
    findDecreaseFields(newVal, oldVal) {
      const [before, after] = [oldVal ? oldVal.map(item => item.field_name) : [], newVal ? newVal.map(item => item.field_name) : []];
      return before.filter(item => !after.includes(item));
    }
  }
};
exports.default = _default;