"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "scatter-chart"
  }, [_vm.chartConfig.circle ? _c("fullScreenBtn", {
    attrs: {
      "full-screen-container": _vm.chartElement
    }
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chartLoading,
      expression: "chartLoading"
    }],
    staticClass: "scatter-chart"
  }, [_c("div", {
    ref: "chart",
    staticClass: "chart",
    class: {
      "set-max-width": _vm.chartConfig.circle
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.data.length && !_vm.chartLoading,
      expression: "!data.length && !chartLoading"
    }],
    staticClass: "no-data-placeholder"
  }, [_vm._v(" 该项目没有数据 ")])]), _vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;