"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FiltersGroup = _interopRequireDefault(require("./ScoreAnalysis/components/FiltersGroup.vue"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Sop',
  components: {
    FiltersGroup: _FiltersGroup.default,
    MenuToggleBtn: _MenuToggleBtn.default,
    SpaceButton: _SpaceButton.default
  },
  data() {
    return {
      isHiddenMenu: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree', 'workspaceInfo']),
    getTabTitle() {
      return this.$route.meta.subtitle;
    },
    biMenuList() {
      var _deepClone, _deepClone$find;
      const newBiMenuList = (_deepClone = (0, _commonFunc.deepClone)(this.routesTree)) === null || _deepClone === void 0 ? void 0 : (_deepClone$find = _deepClone.find(item => item.path === '/sop')) === null || _deepClone$find === void 0 ? void 0 : _deepClone$find.children;
      if (!newBiMenuList) {
        return [];
      }
      this.sortMenu(newBiMenuList);
      return newBiMenuList;
    },
    defaultOpeneds() {
      const openedMenu = [];
      const traverseMenuList = node => {
        node.forEach(_ref => {
          let {
            link,
            children
          } = _ref;
          openedMenu.push(link);
          if (children) {
            traverseMenuList(children);
          }
        });
      };
      traverseMenuList(this.biMenuList);
      return openedMenu;
    }
  },
  methods: {
    updateIsHiddenMenu(val) {
      this.isHiddenMenu = val;
    },
    toggleMenu() {
      this.$refs['nav-menu'].toggleMenu();
    },
    floatingMenuShow() {
      this.$refs['nav-menu'].floatingMenuShow();
    },
    floatingMenuHidden() {
      this.$refs['nav-menu'].floatingMenuHidden();
    },
    sortMenu(list) {
      list.sort((a, b) => a.meta.order - b.meta.order);
      list.forEach(_ref2 => {
        let {
          children
        } = _ref2;
        if (children) {
          this.sortMenu(children);
        }
      });
    }
  }
};
exports.default = _default;