"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceScoreFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceScoreFilter"));
var _ComplianceStatusFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceStatusFilter"));
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvViewFilterGroup',
  components: {
    ComplianceScoreFilter: _ComplianceScoreFilter.default,
    ComplianceStatusFilter: _ComplianceStatusFilter.default
  },
  props: {
    scoreRuleList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    timeSelectorDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterObj: {
        conversation_name: '',
        conversation_time: [],
        status: 0,
        score: [],
        sort_by: [['status', 'descending']],
        page: 1,
        size: 20
      }
    };
  },
  watch: {
    filters: {
      handler: function (val) {
        this.filterObj = (0, _cloneDeep.default)(val);
      },
      deep: true
    }
  },
  methods: {
    getUserList: _organization.getUserList,
    changeFilter() {
      this.$emit('changeFilter', this.filterObj);
    },
    changeStatusFilter(val) {
      this.filterObj.status = val;
      this.changeFilter();
    },
    changeScoreFilter(val) {
      this.filterObj.score = val;
      this.changeFilter();
    },
    changeConvName: (0, _commonFunc.debounce)(function () {
      this.$emit('changeFilter', this.filterObj);
    }, 300),
    changeConvTime(val) {
      this.filterObj.conversation_time = val || [];
      this.$emit('changeFilter', this.filterObj);
    }
  }
};
exports.default = _default;