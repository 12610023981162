"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getField = getField;
exports.useActiveFormItem = useActiveFormItem;
var _formConfig = require("../form-config");
var _vue = require("vue");
function getField(id) {
  return _formConfig.fieldTypeMap.find(item => item.id === id);
}
function useActiveFormItem(formRef) {
  const activeFormItem = (0, _vue.ref)(null);
  const addActiveFormItem = item => {
    activeFormItem.value = item;
  };
  const removeActiveFormItem = () => {
    activeFormItem.value = null;
  };
  const onClickChangeInput = e => {
    if (!formRef.value.contains(e.target)) {
      activeFormItem.value = null;
    }
  };
  document.addEventListener('click', onClickChangeInput);
  (0, _vue.onBeforeUnmount)(() => {
    document.removeEventListener('click', onClickChangeInput);
  });
  return {
    activeFormItem,
    addActiveFormItem,
    removeActiveFormItem
  };
}