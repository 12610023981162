"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _contact = require("@/api/contact");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DynamicForm: _DynamicForm.default,
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {},
      desc: [{
        id: 'id',
        label: '姓名',
        required: true,
        halfRow: true
      }, {
        id: 'tel',
        type: 'tel',
        label: '手机号',
        halfRow: true
      }],
      contactList: [],
      loading: false,
      query: '',
      hasContactForm: true
    };
  },
  watch: {
    value: {
      handler(val) {
        this.form = val;
      },
      immediate: true
    },
    form: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    }
  },
  methods: {
    async searchContact(query) {
      this.query = query;
      if (!query) return;
      this.loading = true;
      const res = await (0, _contact.searchContact)({
        filter: query
      }).finally(() => {
        this.loading = false;
      });
      this.contactList = res.results.data;
    },
    async createContact() {
      const res = await (0, _contact.updateContact)({
        name: this.query
      });
      this.$refs['name-form-item'].blur();
      this.contactList.unshift(res.results);
      this.form = {
        id: res.results.id,
        name: this.query,
        tel: ''
      };
      this.query = '';
    },
    validate() {
      return !this.hasContactForm || this.$refs.form.validate();
    },
    handleSelectChange(id) {
      const selected = this.contactList.find(item => item.id === id);
      this.form = {
        ...selected
      };
      this.query = '';
    },
    handleCheckboxChange(val) {
      if (!val) {
        this.form = {
          id: '',
          tel: ''
        };
      } else {
        var _this$$refs$form;
        (_this$$refs$form = this.$refs.form) === null || _this$$refs$form === void 0 ? void 0 : _this$$refs$form.clearValidate();
      }
    },
    async handleTelBlur() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const index = this.contactList.findIndex(item => item.id === this.form.id);
        const contact = {
          ...this.contactList[index],
          ...this.form
        };
        await (0, _contact.updateContact)(contact);
        this.$set(this.contactList, index, contact);
      }
    }
  }
};
exports.default = _default;