"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _CoachActivities = _interopRequireDefault(require("./CoachActivities"));
var _datePopover = _interopRequireDefault(require("@/components/BusinessTable/datePopover"));
var _TableHeadTips = _interopRequireDefault(require("./TableHeadTips.vue"));
var _TextTag = _interopRequireDefault(require("./TextTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    CoachActivities: _CoachActivities.default,
    DatePopover: _datePopover.default,
    TableHeadTips: _TableHeadTips.default,
    TextTag: _TextTag.default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 200,
      datePopoverVisible: false,
      popoverProps: {},
      popoverDate: '',
      clickableColumns: [1, 2, 3]
    };
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 200;
    },
    handleMousemove(e) {
      const date = e.target.dataset.date || e.target.parentElement.dataset.date;
      if (date) {
        this.datePopoverVisible = true;
        this.popoverDate = this.$plus8ToCurrentZone(date).format('YYYY-MM-DD');
        this.popoverProps = {
          left: e.x - 40 + 'px',
          top: e.y - 40 + 'px'
        };
      } else {
        this.datePopoverVisible = false;
      }
    },
    handleCellClick(row, column, cell, event) {
      const clickableColumns = ['coach_record', 'todo_coach_count'];
      // 点击不同列会去往不同的tab
      const {
        property
      } = column;
      if (!clickableColumns.includes(property)) return;
      const {
        user,
        coach_record: {
          count: coachedCount
        },
        undo_coach_to_me_count,
        undo_coach_to_other_count
      } = row;
      if (property === 'coach_record' && coachedCount) {
        this.$emit('openDrawer', {
          user,
          date: '',
          tab: 'coachRecord'
        });
      }
      if (property === 'todo_coach_count') {
        if (!undo_coach_to_me_count && !undo_coach_to_other_count) {
          return;
        }
        this.$emit('openDrawer', {
          user,
          date: '',
          tab: 'todoCoach'
        });
      }
    },
    handleTimelineTagClick() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      const [[event, date], user] = args;
      event.stopPropagation();
      this.$emit('openDrawer', {
        user,
        date,
        tab: 'coachRecord'
      });
    },
    goCoach(row) {
      const params = {
        user: row.user,
        date: '',
        tab: ''
      };
      params.tab = !row.undo_coach_to_me_count && !row.undo_coach_to_other_count ? 'conversations' : 'todoCoach';
      this.$emit('openDrawer', params);
    },
    getLastCoachTime(time) {
      const lastCoachTime = this.$plus8ToCurrentZone(time).format('YYYY-MM-DD');
      if (lastCoachTime === 'Invalid date') return '-';
      return lastCoachTime === this.$moment().format('YYYY-MM-DD') ? '今天' : lastCoachTime;
    }
  }
};
exports.default = _default;