"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list.length ? _c("section", {
    staticClass: "snippets-list"
  }, [_c("header", {
    staticClass: "snippet-header"
  }, [_c("i", {
    staticClass: "iconfont icon-film"
  }), _vm._v(" " + _vm._s(_vm.videoTotal) + "个剪辑片段 ")]), _c("ul", {
    ref: "listWrapper",
    staticClass: "list-container"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("li", {
      key: index,
      ref: "snippetItem" + item.id,
      refInFor: true
    }, [_c("snippet-list-card", {
      attrs: {
        readonly: _vm.readonly,
        "show-checkbox": _vm.showCheckbox,
        snippet: item
      },
      on: {
        operation: function ($event) {
          return _vm.$emit("operation", $event);
        },
        snippetCheckedChange: _vm.snippetCheckedChange
      }
    })], 1);
  }), 0)]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;