"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conv-flow-info"
  }, [_c("div", {
    staticClass: "form"
  }, [_c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.formDesc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "folder_id",
      fn: function () {
        return [_c("choose-folder-select", {
          attrs: {
            disabled: _vm.createSubfolder
          },
          model: {
            value: _vm.folderPosition,
            callback: function ($$v) {
              _vm.folderPosition = $$v;
            },
            expression: "folderPosition"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c("SetPublicScope", {
    ref: "SetPublicScopeRef",
    attrs: {
      "is-public": _vm.isPublic,
      "backfill-list": _vm.backfillList
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;