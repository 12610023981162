"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.renderInfo.icon_type || _vm.renderInfo.text,
      expression: "renderInfo.icon_type || renderInfo.text"
    }],
    staticClass: "source-tag",
    class: _vm.getDynamicTagClass,
    on: {
      click: _vm.sourceClick
    }
  }, [_c("i", {
    class: ["iconfont", _vm.getIconName(_vm.renderInfo.icon_type)]
  }), _c("span", {
    staticClass: "source-text"
  }, [_vm._v(_vm._s(_vm.renderInfo.text))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;