import { render, staticRenderFns } from "./TaskMenuContent.vue?vue&type=template&id=1cb2e900&scoped=true&"
import script from "./TaskMenuContent.vue?vue&type=script&lang=js&"
export * from "./TaskMenuContent.vue?vue&type=script&lang=js&"
import style0 from "./TaskMenuContent.vue?vue&type=style&index=0&id=1cb2e900&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb2e900",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cb2e900')) {
      api.createRecord('1cb2e900', component.options)
    } else {
      api.reload('1cb2e900', component.options)
    }
    module.hot.accept("./TaskMenuContent.vue?vue&type=template&id=1cb2e900&scoped=true&", function () {
      api.rerender('1cb2e900', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TaskCenterDrawer/components/TaskMenuContent.vue"
export default component.exports