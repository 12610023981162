"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversation-item-container"
  }, [_c("i", {
    class: ["medium-icon", "iconfont", _vm.mediumIcon]
  }), _c("div", {
    staticClass: "center-container"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("div", {
    staticClass: "info"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: "会话时长",
      enterable: false
    }
  }, [_c("span", {
    staticClass: "duration",
    domProps: {
      innerHTML: _vm._s(_vm.getDuration(_vm.item.duration))
    }
  })]), _c("span", {
    staticClass: "line"
  }), _vm.salesInfo && (_vm.salesInfo.name || _vm.salesInfo.avatar) ? [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: "销售",
      enterable: false
    }
  }, [_vm.salesInfo ? _c("div", {
    staticClass: "info-item"
  }, [_c("mgv-avatar-name", {
    attrs: {
      name: _vm.salesInfo.name,
      src: _vm.salesInfo.avatar,
      size: 20
    }
  }), _c("span", [_vm._v(_vm._s(_vm.salesInfo.name))])], 1) : _vm._e()]), _c("span", {
    staticClass: "line"
  })] : _vm._e(), _vm.item.company_name ? [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: "公司名称",
      enterable: false
    }
  }, [_c("div", {
    staticClass: "info-item"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-office-building"
  }), _c("span", [_vm._v(_vm._s(_vm.item.company_name))])])]), _c("span", {
    staticClass: "line"
  })] : _vm._e(), _vm.item.deal_name ? [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: "交易名称",
      enterable: false
    }
  }, [_c("div", {
    staticClass: "info-item"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-deal4"
  }), _c("span", [_vm._v(_vm._s(_vm.item.deal_name))])])]), _c("span", {
    staticClass: "line"
  })] : _vm._e(), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      content: "会话时间",
      enterable: false
    }
  }, [_c("div", {
    staticClass: "info-item"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-date"
  }), _c("span", [_vm._v(_vm._s(_vm.getDate(_vm.item.date)))])])])], 2)]), _vm._t("suffix", function () {
    return [_vm.isShowDeleteButton ? _c("el-button", {
      attrs: {
        type: "text gray-bg",
        icon: "el-icon-delete"
      },
      on: {
        click: function ($event) {
          return _vm.$emit("delete");
        }
      }
    }) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;