"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _commonFunc = require("@/utils/commonFunc");
var _CatalogSelect = _interopRequireDefault(require("./CatalogSelect.vue"));
var _KnowledgeTricksSelect = _interopRequireDefault(require("./KnowledgeTricksSelect.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachingTasksContent',
  components: {
    CatalogSelect: _CatalogSelect.default,
    KnowledgeTricksSelect: _KnowledgeTricksSelect.default
  },
  props: {
    hasKnowledgesSearch: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    selectedKnowledges: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentNode: {},
      catalogData: [],
      targetKnowledge: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo'])
  },
  watch: {
    'knowledgeBaseInfo.id': {
      // 切换知识库
      handler: function (val) {
        if (val) {
          this.getCatalogData(val);
        }
      }
    }
  },
  methods: {
    async getCatalogData(id) {
      const res = await (0, _knowledgeLibrary.getCatalogData)({
        id
      });
      if (res.code === 20000) {
        this.catalogData = res.results.datas;
        if (!this.catalogData.length) return;
        this.currentNode = this.catalogData[0];
      }
    },
    handleNodeClick(node) {
      this.currentNode = node;
    },
    clearData() {
      this.targetKnowledge = {};
      this.$refs.tricksSelect.clearCheckStatus();
    },
    handleSearch(knowledge) {
      const target = (0, _commonFunc.getTreeNodeById)(this.catalogData, knowledge.fid);
      this.currentNode = {
        ...target
      };
      this.targetKnowledge = knowledge;
    }
  }
};
exports.default = _default;