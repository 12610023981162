"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    attrs: {
      "popper-append-to-body": false
    },
    on: {
      change: _setup.toggleHistory
    },
    model: {
      value: _setup.currentHistoryId,
      callback: function ($$v) {
        _setup.currentHistoryId = $$v;
      },
      expression: "currentHistoryId"
    }
  }, _vm._l(_setup.filterUndoneList, function (_ref) {
    let {
      conv_id,
      create_time,
      name,
      score,
      practice_duration,
      isMaxScore
    } = _ref;
    return _c("el-option", {
      key: conv_id,
      attrs: {
        label: `${name}（${score >= 0 ? score + _setup.t("coaching.points") : _setup.t("coaching.notCompleted")}）`,
        value: conv_id
      }
    }, [_c("span", {
      staticClass: "option-item-left"
    }, [_c("div", {
      staticClass: "option-item-name"
    }, [_vm._v(" " + _vm._s(name)), _c("span", [_vm._v("（" + _vm._s(score >= 0 ? `${score}${_setup.t("coaching.points")}` : `${_setup.t("coaching.notCompleted")}`) + "）")])]), _c("div", {
      staticClass: "option-item-bottom"
    }, [_c("span", {
      staticClass: "option-item-time"
    }, [_vm._v(_vm._s(create_time))]), practice_duration ? _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }) : _vm._e(), practice_duration ? _c("span", {
      staticClass: "option-item-duration"
    }, [_vm._v(_vm._s(practice_duration))]) : _vm._e()], 1)]), isMaxScore ? _c("span", {
      staticClass: "option-item-right"
    }, [_vm._v(_vm._s(_setup.t("coaching.theHighestScore")))]) : _vm._e()]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;