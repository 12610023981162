"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _telDial = _interopRequireDefault(require("./telDial"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SeatCard',
  components: {
    TelDial: _telDial.default
  },
  props: {
    seatStatus: {
      type: String,
      default: ''
    },
    answerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      statusItems: [{
        name: '空闲',
        status: 'free',
        icon: 'icon-service',
        color: '#00B899',
        background: '#EBF9F7',
        isHidden: false
      }, {
        name: '小休',
        status: 'break',
        icon: 'icon-hot-water',
        color: '#F4AF00',
        background: '#FDF9EC',
        isHidden: true
      }, {
        name: '忙碌',
        status: 'busy',
        icon: 'icon-remove-outline',
        color: '#F43F7C',
        background: '#FEF0F3',
        isHidden: true
      }, {
        name: '离线',
        status: 'offLine',
        icon: 'icon-service',
        color: '#8E95B0',
        background: '#F6F6F9',
        isHidden: false
      }],
      inputTel: null,
      countTimer: null,
      countText: '00:00:00',
      showTelDial: false
    };
  },
  computed: {
    getAnswerTypeIcon() {
      if (this.answerType === 'Local') {
        return 'icon-mobile';
      } else {
        return 'icon-monitor';
      }
    },
    getAnswerTypeName() {
      if (this.answerType === 'Local') {
        return '手机接听';
      } else {
        return '在线接听';
      }
    }
  },
  watch: {
    seatStatus(val) {
      if (val === 'offLine') {
        this.inputTel = null;
      }
    }
  },
  methods: {
    changeStatus(status) {
      if (status !== 'offLine') {
        this.$emit('changeStatus', status);
      } else {
        this.$emit('changeOffline');
      }
    },
    initStatusDuration() {
      if (this.countTimer) clearInterval(this.countTimer);
      this.countText = '00:00:00';
      const initTime = this.$moment();
      // 定时器
      this.countTimer = setInterval(() => {
        const nowTime = this.$moment();
        const diffTime = this.$moment(nowTime).diff(initTime);
        const day = Math.floor(this.$moment.duration(diffTime).asDays());
        const hours = this.$moment.duration(diffTime).hours();
        const minutes = this.$moment.duration(diffTime).minutes();
        const seconds = this.$moment.duration(diffTime).seconds();
        if (day) {
          this.countText = day + ':' + this.prefixInteger(hours, 2) + ':' + this.prefixInteger(minutes, 2) + ':' + this.prefixInteger(seconds, 2);
        } else {
          this.countText = this.prefixInteger(hours, 2) + ':' + this.prefixInteger(minutes, 2) + ':' + this.prefixInteger(seconds, 2);
        }
      }, 1000);
    },
    // 补零
    prefixInteger(num, length) {
      return (Array(length).join('0') + num).slice(-length);
    },
    openSeatDialog() {
      this.$emit('openSeatSetDialog');
    },
    openTelDial() {
      this.showTelDial = true;
    },
    closeTelDial(value) {
      this.showTelDial = false;
      if (/^1[3456789]\d{9}$/.test(value) !== false) {
        this.inputTel = value;
      }
    },
    callTel(value) {
      const validate = this.telValidator(value);
      if (validate) {
        // 调用全局拨打电话的方法
        this.$emit('makeCall', value);
      }
    },
    telValidator(value) {
      if (value === '') {
        this.$message.warning('手机号不能为空');
        return false;
      } else {
        if (/^1[3456789]\d{9}$/.test(value) == false) {
          this.$message.warning('请输入正确的手机号');
          return false;
        } else {
          return true;
        }
      }
    }
  }
};
exports.default = _default;