"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "iframe-coversation-list"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dealCode && _vm.isNone === "false",
      expression: "dealCode && isNone === 'false'"
    }],
    staticClass: "list-wrapper"
  }, _vm._l(_vm.list, function (item) {
    return _c("card-item", {
      key: item.id,
      attrs: {
        item: item,
        "is-iframe": ""
      },
      on: {
        openEventKeyDrawer: _vm.openEventKeyDrawer
      }
    });
  }), 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isNone === "true" || !_vm.dealCode,
      expression: "isNone === 'true' || !dealCode"
    }],
    staticClass: "no-cont"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("@/assets/logo/megaview-logo.svg"),
      alt: "Megaview"
    }
  }), _c("img", {
    staticClass: "empty-pic",
    attrs: {
      src: require("@/assets/noContent/home-empty.png"),
      alt: "暂无会话"
    }
  }), _c("div", {
    staticClass: "tip"
  }, [_vm._v(" " + _vm._s(_vm.hasNoPermission ? "无法查看页面信息" : "暂无会话") + " ")]), _c("div", {
    staticClass: "info-box"
  }, [_vm._m(0), _c("div", {
    staticClass: "info-text"
  }, [_c("ul", {
    staticClass: "item-list"
  }, [_c("li", {
    staticClass: "item-text"
  }, [_vm._v("您与该线索/客户未产生过相关会话")]), _c("li", {
    staticClass: "item-text"
  }, [_vm._v(" 您的账号未与外呼坐席账号绑定，可在Megaview管理后台-坐席管理中操作绑定 "), _c("span", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "guide-anchor",
    on: {
      click: _vm.goGuideDocs
    }
  }, [_vm._v("查看操作指南")])])]), _c("div", {
    staticClass: "contact-text"
  }, [_vm._v(" 若非以上原因，仍无法查看会话，可联系Megaview工作人员 ")])])]), _c("div", {
    staticClass: "login-info"
  }, [_vm._v(" 想查看全部线索/客户的会话记录？ "), _c("span", {
    staticClass: "login-anchor",
    on: {
      click: _vm.goConversationPage
    }
  }, [_vm._v("点此进入Megaview")])])]), _c("el-pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasNoPermission,
      expression: "!hasNoPermission"
    }],
    staticClass: "pagination iframe-conver-list-pagination",
    class: _vm.resultsTotal >= 10000 ? "hide-last-page" : "",
    attrs: {
      "current-page": _vm.pageParams.current_page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.pageParams.size,
      layout: "total, prev, pager, next, sizes",
      total: _vm.resultsTotal
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  }), _c("event-key-drawer", _vm._b({
    on: {
      goBack: () => _vm.eventDrawerProps.isKeyEventDetail = false,
      handleTagClick: () => _vm.eventDrawerProps.isKeyEventDetail = true,
      closeDrawer: function ($event) {
        _vm.eventDrawerProps.eventKeyDrawerVisible = false;
      }
    }
  }, "event-key-drawer", _vm.eventDrawerProps, false))], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "info-sub"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("span", [_vm._v("出现以下任一情况时，会没有会话： ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;