"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _client = require("@/api/client");
var _business = require("@/api/business");
var _clues = require("@/api/clues");
var _doubleCall = require("@/api/doubleCall");
var _default = {
  data() {
    return {
      doubleCallData: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'orgName', 'name'])
  },
  methods: {
    doubleCallOut(contact, row, type) {
      switch (type) {
        case 'account':
          this.doubleCallData = {
            id: row.id,
            type: 'account',
            company: row.name,
            contact_id: contact.id,
            contact_person: contact.name,
            phone: contact.tel
          };
          break;
        case 'deal':
          this.doubleCallData = {
            id: row.deal_id,
            type: 'deal',
            company: row.customer_name,
            contact_id: contact.id,
            contact_person: contact.name,
            phone: contact.tel
          };
          break;
        case 'lead':
          this.doubleCallData = {
            id: row.id,
            type: 'lead',
            company: row.company,
            contact_id: contact.id,
            contact_person: contact.name,
            phone: contact.tel
          };
          break;
        default:
          break;
      }
      this.createMeetingInfo(this.doubleCallData);
    },
    /**
     *
     * @param {*} val 所用到的对象
     * @param {*} isInComing 是否是来电
     */
    createMeetingInfo(val) {
      this.contact_person = val.contact_person;
      this.phoneNumber = val.phone;
      const _dateTime = `${this.$moment().format('YYYY-MM-DD')} ${this.$moment().format('HH:mm')}`;
      const _end = this.$moment(_dateTime).add(30, 'minutes');
      const _endTime = `${this.$moment(_end).format('YYYY-MM-DD')} ${this.$moment(_end).format('HH:mm')}`;
      const _meeting_name = `${this.orgName + this.name} 与 ${val.company ? val.company : ''}${val.contact_person ? val.contact_person : ' ' + val.phone + ' '}的通话`;
      const formData = {
        meeting_name: _meeting_name,
        begin_time: _dateTime,
        end_time: _endTime,
        contact_id: val.contact_id,
        host_salesman_id: this.userId,
        assistant_salesman_ids: [],
        spectator_salesman_ids: [],
        type: 'audio',
        call_sheet_id: val.callSheetId ? val.callSheetId : ''
      };
      switch (val.type) {
        case 'account':
          formData.account_id = val.id;
          // 客户创建会话
          this.accountCreateMeeting(formData);
          break;
        case 'deal':
          formData.deal_id = val.id;
          // 商机创建会话
          this.businessCreateMeeting(formData);
          break;
        case 'lead':
          formData.lead_id = val.id;
          this.clueCreateMeeting(formData);
          break;
        default:
          break;
      }
    },
    async accountCreateMeeting(val) {
      const res = await (0, _client.accountCreateMeeting)(val);
      if (res.code === 200) {
        const conversation_id = res.results.conversation_id;
        const tel = res.results.candidate_tel;
        this.postDoubleCall(conversation_id, tel);
      }
    },
    async businessCreateMeeting(data) {
      const res = await (0, _business.businessCreateMeeting)(data);
      if (res.code === 200) {
        const conversation_id = res.results.conversation_id;
        const tel = res.results.candidate_tel;
        this.postDoubleCall(conversation_id, tel);
      }
    },
    async clueCreateMeeting(data) {
      const res = await (0, _clues.clueCreateMeeting)(data);
      if (res.code === 200) {
        const conversation_id = res.results.conversation_id;
        const tel = res.results.candidate_tel;
        this.postDoubleCall(conversation_id, tel);
      }
    },
    /**
     * 双呼的接口
     * @param {*} data
     */
    async postDoubleCall(conversation_id, tel) {
      const res = await (0, _doubleCall.postDoubleCall)(conversation_id, tel);
      if (res.code === 200) {
        if (res.results.code === 200) {
          this.$message.success('双呼拨打成功');
        } else if (res.results.code === 20108) {
          this.$message.warning('被叫拨打过于频繁，请明天再打');
        }
      }
    }
  }
};
exports.default = _default;