"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailNavbar = _interopRequireDefault(require("@/layout/components/DetailNavbar"));
var _KnowledgeFilter = _interopRequireDefault(require("@/views/businessIntelligencePersonal/knowledge/components/KnowledgeFilter"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _KnowleageConvDrawer = _interopRequireDefault(require("./components/KnowleageConvDrawer"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _commonFunc = require("@/utils/commonFunc");
var _DepartmentAndMemberSelectTree = _interopRequireDefault(require("@/components/DepartmentAndMemberSelectTree"));
var _timezone = require("@/utils/timezone");
var _businessIntelligence = require("@/api/businessIntelligence");
var _download = require("@/utils/download");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamDataAnalysis',
  components: {
    Navbar: _DetailNavbar.default,
    DepartmentAndMemberSelectTree: _DepartmentAndMemberSelectTree.default,
    KnowledgeFilter: _KnowledgeFilter.default,
    ImageAvatar: _ImageAvatar.default,
    KnowleageConvDrawer: _KnowleageConvDrawer.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default
  },
  mixins: [_ExportMixins.default],
  data() {
    const self = this;
    return {
      tableLoading: false,
      datePickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            self.setDatePickerOptions(picker, 7);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            self.setDatePickerOptions(picker, 30);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            self.setDatePickerOptions(picker, 90);
          }
        }],
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      date: '',
      teamDataParams: {
        start_time: '',
        end_time: '',
        knowledge_ids: [],
        page_num: 1,
        page_size: 10,
        sort_by: '',
        sort_order: 'descending',
        tree_ids: [],
        user_ids: [],
        custom_filters: {}
      },
      lastSort: {
        prop: '',
        order: 'descending'
      },
      allKnowledgeList: [],
      tableHeader: [],
      tableData: [],
      total: 0,
      hasRightShadow: true,
      knowledgeConvDrawerVisible: false,
      activeKnowledge: {},
      filterType: 'all',
      fiterTypeList: [{
        label: '会话',
        value: 'all'
      }, {
        label: '商机',
        value: 'deal'
      }],
      showTooltip: false
    };
  },
  watch: {
    tableData(val) {
      this.$nextTick(() => {
        this.$refs['team-data-table'].doLayout();
      });
    }
  },
  created() {
    this.formatTime();
    this.getAllKnowledge();
    this.getTeamDataHeader();
  },
  mounted() {
    this.judgeRightShadow();
  },
  methods: {
    async exportTable() {
      var _data$results$data$;
      const params = this.timeParse2UTCplus8();
      params.page_size = 1000;
      const data = await (0, _businessIntelligence.getTeamDataList)(params);
      const res = data.results.data.map(item => [item.host_name, ...item.scores.map(c => c.score_my)]);
      const head = ['成员'];
      (_data$results$data$ = data.results.data[0]) === null || _data$results$data$ === void 0 ? void 0 : _data$results$data$.scores.map(item => head.push(item.name));
      res.unshift(head);
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `知识点团队数据_${this.teamDataParams.start_time}-${this.teamDataParams.end_time}.csv`);
    },
    judgeRightShadow() {
      const dom = this.$refs['team-data-table'].bodyWrapper;
      if (dom) {
        dom.addEventListener('scroll', () => {
          const originLength = dom.scrollWidth;
          const scrollLength = dom.scrollLeft + dom.clientWidth;
          if (originLength - scrollLength < 10) {
            this.hasRightShadow = false;
          } else {
            this.hasRightShadow = true;
          }
        });
      }
    },
    formatTime() {
      if (!this.date) {
        const start = this.$moment(new Date()).subtract(90, 'days');
        const end = this.$moment(new Date());
        this.date = [start, end];
        this.$nextTick(() => {
          this.date = [start, end];
        });
        this.teamDataParams.start_time = this.$moment(this.date[0]).format('YYYY-MM-DD');
        this.teamDataParams.end_time = this.$moment(this.date[1]).format('YYYY-MM-DD');
      }
    },
    setDatePickerOptions(picker, count) {
      const start = this.$moment(new Date()).subtract(count, 'days');
      const end = this.$moment(new Date());
      picker.$emit('pick', [start, end]);
    },
    datePickerChange(date) {
      if (!date) {
        this.formatTime();
      } else {
        this.teamDataParams.start_time = this.$moment(date[0]).format('YYYY-MM-DD');
        this.teamDataParams.end_time = this.$moment(date[1]).format('YYYY-MM-DD');
      }
      this.teamDataParams.page_num = 1;
      this.getTeamDataList();
    },
    // 部门成员选择
    departmentAndMemberChange(tree_user_ids) {
      const {
        tree_ids,
        user_ids
      } = tree_user_ids;
      this.teamDataParams.tree_ids = tree_ids;
      this.teamDataParams.user_ids = user_ids;
      this.teamDataParams.page_num = 1;
      this.getTeamDataList();
    },
    async getAllKnowledge() {
      const res = await (0, _businessIntelligence.getAllKnowledge)();
      if (res.code === 200) {
        this.allKnowledgeList = res.results;
      }
    },
    async getTeamDataHeader(knowledgeIds) {
      this.tableLoading = true;
      const res = await (0, _businessIntelligence.getTeamDataHeader)({
        knowledge_ids: knowledgeIds || []
      });
      if (res.code === 200) {
        this.tableHeader = res.results;
      }
    },
    async getTeamDataList() {
      this.tableLoading = true;
      const teamDataParams = this.timeParse2UTCplus8();
      const res = await (0, _businessIntelligence.getTeamDataList)(teamDataParams);
      this.tableLoading = false;
      if (res.code === 200) {
        this.tableData = res.results.data;
        this.total = res.results.count;
      }
    },
    timeParse2UTCplus8() {
      const teamDataParams = (0, _commonFunc.deepClone)(this.teamDataParams);
      teamDataParams.end_time = teamDataParams.end_time + ' 23:59:59';
      (0, _timezone.datePickerWithTimeParse2Zone)(teamDataParams);
      return teamDataParams;
    },
    async knowledgeChange(knowledgeIds) {
      this.teamDataParams.knowledge_ids = knowledgeIds;
      this.teamDataParams.page_num = 1;
      if (knowledgeIds.indexOf(Number(this.teamDataParams.sort_by)) === -1) {
        this.teamDataParams.sort_by = '';
      }
      await this.getTeamDataHeader(knowledgeIds);
      await this.getTeamDataList();
      if (this.$refs['team-data-table'].bodyWrapper.classList.contains('is-scrolling-none')) {
        this.hasRightShadow = false;
      } else {
        this.hasRightShadow = true;
      }
    },
    sortChange(_ref) {
      let {
        column,
        prop,
        order
      } = _ref;
      if (prop !== this.lastSort.prop) {
        const column = this.tableHeader.find(item => item.id.toString() === this.lastSort.prop);
        if (column) {
          this.$nextTick(() => {
            this.$refs[`column_${this.lastSort.prop}`][0].columnConfig.order = null;
          });
        }
      }
      this.$nextTick(() => {
        if (!order) {
          column.order = this.lastSort.order;
        } else {
          this.lastSort = {
            prop,
            order
          };
          this.teamDataParams.sort_by = Number(prop);
          this.teamDataParams.sort_order = order;
          this.getTeamDataList();
        }
      });
    },
    getPersonalScore(id, row) {
      var _row$scores$find;
      return (_row$scores$find = row.scores.find(item => item.id === id)) === null || _row$scores$find === void 0 ? void 0 : _row$scores$find.score_my;
    },
    goMyKnowledge(host_id, host_name) {
      this.$router.push({
        path: _routeMap.ROUTES.biPersonalKnowledge,
        query: {
          id: host_id,
          name: host_name
        }
      });
    },
    currentChange(val) {
      this.teamDataParams.page_num = val;
      this.getTeamDataList();
    },
    sizeChange(val) {
      this.teamDataParams.page_size = val;
      this.teamDataParams.page_num = 1;
      this.getTeamDataList();
    },
    closeKnowledgeConvDrawer() {
      this.knowledgeConvDrawerVisible = false;
    },
    openKnowledgeConvDrawer(id, row, item) {
      if (id === 0) return;
      const hasScore = this.getPersonalScore(id, row);
      if (hasScore) {
        this.activeKnowledge = {
          user_id: row.host_id,
          knowledge_id: id
        };
        this.knowledgeConvDrawerVisible = true;
      }
    },
    customFilterChange: (0, _commonFunc.debounce)(function (val) {
      this.teamDataParams.custom_filters = val;
      this.getTeamDataList();
    }),
    handleFilterTypeChange() {
      setTimeout(() => {
        this.showTooltip = true;
        setTimeout(() => {
          this.showTooltip = false;
        }, 2000);
      }, 1000);
    }
  }
};
exports.default = _default;