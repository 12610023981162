"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _elementUi = require("element-ui");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TransfereeDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    conversationName: {
      type: String,
      default: ''
    },
    salesName: {
      type: String,
      default: ''
    },
    conversationId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:dialogVisible', 'submitSucceed'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const handleClose = () => {
      emit('update:dialogVisible', false);
    };
    const transfer_department = (0, _vue.ref)(true);
    const transferUser = (0, _vue.ref)({});
    const handleMemberChange = (_, users) => {
      const [user] = users;
      transferUser.value = user;
    };
    const submitLoading = (0, _vue.ref)(false);
    const submit = async () => {
      submitLoading.value = true;
      const res = await (0, _videoDetail.changeConversationHost)({
        conversation_id: props.conversationId,
        host_id: transferUser.value.id,
        other_change: transfer_department.value
      }).finally(() => {
        submitLoading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success('转移归属人成功');
        emit('submitSucceed', transferUser);
        handleClose();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      store,
      workspaceInfo,
      handleClose,
      transfer_department,
      transferUser,
      handleMemberChange,
      submitLoading,
      submit,
      MembersFinder: _MembersFinder.default
    };
  }
};
exports.default = _default;