"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NoSupport',
  props: {
    entityType: {
      type: Number,
      default: 0
    }
  },
  computed: {
    tipTitle() {
      if (this.entityType === 2) {
        return '“文字过长，暂不支持使用”';
      } else {
        return '“会话过长，暂不支持使用”';
      }
    },
    tipContent() {
      if (this.entityType === 2) {
        return '当前为麦麦beta限免版，分析文字超出5w字，暂不支持使用';
      } else {
        return '当前为麦麦beta限免版，该会话字数超过2w字，暂不支持使用，换个较短的会话试试吧~';
      }
    }
  }
};
exports.default = _default;