"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.$t("coaching.expand"),
      placement: "right",
      enterable: false
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHiddenMenu,
      expression: "isHiddenMenu"
    }],
    staticClass: "iconfont icon-menu-expansion menu-expansion-btn",
    on: {
      click: _vm.toggleMenu,
      "!mouseenter": function ($event) {
        return _vm.floatingMenuShow.apply(null, arguments);
      }
    }
  })]), _vm._t("preIcon"), _vm.title.length ? _c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("suffix")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;