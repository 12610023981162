"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-template"
  }, [_c("div", {
    staticClass: "top",
    class: _vm.type
  }, [_c("span", {
    staticClass: "title-container"
  }, [_c("i", {
    class: [`iconfont icon-${_vm.titleInfo[_vm.type].icon}`]
  }), _c("span", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.customTitle || _vm.titleInfo[_vm.type].name))]), _vm._t("suffix")], 2), _vm._t("button")], 2), _vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;