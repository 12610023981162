"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "practice-audio-recorder"
  }, [_c("div", {
    staticClass: "trick-content"
  }, [_vm.reply.is_closed_exam ? _c("p", {
    class: _vm.contentClass
  }, [_c("span", {
    staticClass: "visible-answer"
  }, [_vm._v(" " + _vm._s(_vm.reply.reply_content.slice(0, 10)))]), _c("span", {
    staticClass: "mosaic"
  }, [_vm._v(" " + _vm._s(_vm.reply.reply_content))])]) : _c("p", {
    class: _vm.contentClass
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.reply.reply_content))])]), _vm.courseType === 2 ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRecording,
      expression: "isRecording"
    }],
    staticClass: "frequency-histogram-view-box",
    attrs: {
      id: _vm.frequencyHistogramViewBoxId
    }
  }) : _vm._e()]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isRecording,
      expression: "!isRecording"
    }],
    staticClass: "operate-btns"
  }, [_c("PlayVoice", {
    staticClass: "play-voice",
    attrs: {
      disabled: !_vm.reply.reply_record_standard_url || _vm.reply.is_closed_exam,
      "tooltip-content": _vm.reply.is_closed_exam ? "闭卷考核中，不可播放示例录音" : "",
      "is-play": _vm.currentTemplateVoiceId === _vm.reply.reply_id
    },
    on: {
      play: function ($event) {
        return _vm.play(_vm.reply.reply_id, _vm.reply.reply_record_standard_url, _vm.reply.duration);
      },
      pause: _vm.pause
    }
  }), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isScoring,
      expression: "isScoring"
    }],
    staticClass: "recorder-btn",
    attrs: {
      type: "primary",
      icon: "iconfont icon-microphone-fill",
      circle: "",
      disabled: _vm.isScoring
    },
    on: {
      click: _vm.startRecord
    }
  }), _c("div", {
    class: ["pracrice-score", _vm.scoreClass]
  }, [_vm._v(" " + _vm._s(_vm.score >= 0 ? _vm.score : "-") + " ")]), _vm.reply.reply_record ? _c("el-popover", {
    attrs: {
      placement: "top",
      width: "240",
      trigger: "hover",
      "popper-class": "course-score-history-list"
    }
  }, [_c("el-button", {
    staticClass: "iconfont icon-drag-line hover-show-bg",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }), _c("div", [_c("p", {
    staticClass: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.finalScore")) + " "), _c("el-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("span", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v("若练习次数要求为1，最终分=表现最好一次练习的得分；"), _c("br"), _vm._v("若练习次数要求为N，最终分=表现最好N次练习的平均分；")]), _c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _vm.reply.reply_record.score ? _c("span", [_c("span", {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.reply.reply_record.score))]), _vm._v("分")]) : _vm._e()]), !_vm.reply.reply_record.score ? _c("p", [_vm._v("练习未完成，暂无得分")]) : _vm._e(), _c("ul", _vm._l(_vm.reply.reply_record.list, function (item) {
    return _c("li", {
      key: item.id,
      class: item.is_qualified ? "green" : "red"
    }, [item.is_qualified ? _c("i", {
      staticClass: "iconfont icon-success-green"
    }) : _c("i", {
      staticClass: "iconfont icon-error-red"
    }), _c("span", [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.score))])]);
  }), 0)])], 1) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRecording,
      expression: "isRecording"
    }],
    staticClass: "recording-container"
  }, [_c("div", {
    staticClass: "recording-btn",
    on: {
      click: _vm.endRecord
    }
  }, [_c("div", {
    staticClass: "btn"
  })]), _c("div", {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.duration))])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.content || _vm.comment,
      expression: "content || comment"
    }],
    staticClass: "comment-container"
  }, [!_vm.isScoring && _vm.score > -1 ? [_c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.salesResponse")))]), _c("p", [_vm._v(_vm._s(_vm.content || _vm.$t("coaching.noContent")))]), _vm.courseType === 2 ? [_c("p", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("coaching.intelligentReview")) + "：")]), _c("p", {
    domProps: {
      innerHTML: _vm._s(_vm.comment || _vm.$t("coaching.noCommentsAvailable"))
    }
  })] : _vm._e()] : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;