"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban.vue"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _customerBaseProfileConfig = _interopRequireDefault(require("@/views/businessIntelligence/CustomerBaseProfile/components/customerBaseProfileConfig"));
var _chartConfig = _interopRequireDefault(require("@/views/businessIntelligence/CustomerBaseProfile/components/chartConfig"));
var _dataOverview = _interopRequireDefault(require("@/views/businessIntelligence/CustomerBaseProfile/components/dataOverview"));
var _cardChart = _interopRequireDefault(require("@/views/businessIntelligence/CustomerBaseProfile/components/cardChart"));
var _ChartContainer = _interopRequireDefault(require("@/views/businessIntelligence/components/ChartContainer"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _editKanbanNameDialog = _interopRequireDefault(require("@/views/businessIntelligence/components/editKanbanNameDialog"));
var _conversationList = require("@/api/conversationList");
var _business = require("@/api/business");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const chartBaseConfig = {
  grid: {
    containLabel: true,
    top: '25%',
    bottom: '10%'
  },
  tooltip: {},
  legend: {
    type: 'scroll',
    top: '2%'
  }
};
const barChartConfig = {
  xAxis: {},
  yAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      show: true
    }
  }
};
const dataZoom = {
  type: 'slider',
  show: true,
  zoomLock: true,
  yAxisIndex: 0,
  width: 0,
  moveHandleSize: 10,
  height: '90%',
  top: '5%',
  right: '7%',
  startValue: 0,
  endValue: 8,
  filterMode: 'none'
};
var _default = {
  name: 'CustomerBaseProfile',
  components: {
    BIKanban: _BIKanban.default,
    InitKanbanCardView: _InitKanbanCardView.default,
    DataOverview: _dataOverview.default,
    CustomerBaseProfileConfig: _customerBaseProfileConfig.default,
    ChartConfig: _chartConfig.default,
    ChartContainer: _ChartContainer.default,
    CardChart: _cardChart.default,
    ShareConfig: _shareConfig.default,
    EditKanbanNameDialog: _editKanbanNameDialog.default
  },
  data() {
    return {
      requestParams: {},
      // 看板参数
      drawerVisible: false,
      getKanbanConfigLoading: false,
      operateType: 'create',
      currentKanban: {},
      kanbanList: [],
      getKanbanListLoading: true,
      // 图表参数
      isEditChart: false,
      chartConfigVisible: false,
      chartConfig: {},
      overViewData: {},
      getChartListLoading: false,
      chartList: [],
      chartId: null,
      // 配置数据源
      eventOptions: [],
      chartConfigLoading: false,
      hasAccountLabel: false,
      metricsTypeList: [{
        label: '关键事件',
        type: 'event'
      }],
      customFiled: [],
      chartBusinessIndicatorList: [],
      filterParams: {},
      // 客户标签级联数据
      accountLabelOptions: [],
      shareKanbanConfigVisible: false,
      copyKanbanConfigVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo']),
    isLoadingChart() {
      // 获取筛选器数据 -> 获取看板列表 -> 获取图表列表
      return !this.biFiltersObj || this.getKanbanListLoading || this.getChartListLoading;
    },
    getChartTitle() {
      return (field_type, indicator) => {
        let label;
        switch (field_type) {
          case 'event':
            label = '关键事件';
            break;
          case 'account_label':
            label = '客户标签';
            break;
          case 'deal_indicator':
            label = '交易指标：' + indicator;
            break;
        }
        return label;
      };
    }
  },
  watch: {
    biFiltersObj() {
      this.initRequestParams();
      this.getCustomerBaseProfileChartList();
    },
    'workspaceInfo.id': {
      handler: function () {
        this.initRequestParams();
        this.getSearchList();
        this.getEventOptions();
        this.currentKanban = {};
        this.getKanbanList();
      },
      immediate: true
    }
  },
  methods: {
    changeKanbanStatus(val) {
      this.kanbanList.find(item => item.kanban_id === this.currentKanban.kanban_id).is_sharing = !!val.length;
    },
    getExtraCommandlist() {
      return [
      // { type: 'detail', label: '详情', order: 2, icon: 'icon-document' },
      {
        type: 'copy',
        label: '复制',
        order: 3,
        icon: 'icon-copy-document'
      }, {
        type: 'share',
        label: '分享',
        order: 4,
        icon: 'icon-share-line'
      }];
    },
    // 切换全屏
    toggleFullScreen(isFullScreen, cardIndex) {
      const currentCard = this.chartList[cardIndex];
      if (currentCard.chartConfig.chart_type === 'bar') {
        if (isFullScreen) {
          currentCard.chartConfig.dataZoomOld = (0, _commonFunc.deepClone)(currentCard.chartConfig.dataZoom);
          delete currentCard.chartConfig.dataZoom;
        } else {
          currentCard.chartConfig.dataZoom = (0, _commonFunc.deepClone)(currentCard.chartConfig.dataZoomOld);
        }
      }
    },
    async getEventOptions() {
      // 看板配置和图表配置共用关键事件数据
      if (!this.workspaceInfo.id) return;
      const resOfAllKeyEvent = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      });
      if (resOfAllKeyEvent.code === 20000) {
        this.eventOptions = resOfAllKeyEvent.results.events;
      }
    },
    initRequestParams() {
      if (this.biFiltersObj && this.workspaceInfo.id) {
        this.requestParams = {
          ...this.biFiltersObj,
          ...this.biFiltersObj.tree_user_ids,
          workspace_id: this.workspaceInfo.id
        };
      }
    },
    // 获取交易自定义筛选项
    async getSearchList() {
      if (!this.workspaceInfo.id) return;
      this.metricsTypeList = this.$options.data().metricsTypeList;
      const resOfDealCustomFilter = await (0, _business.getDealCustomFilter)({
        access_type: '/old_seach_list',
        workspace_id: this.workspaceInfo.id
      });
      if (resOfDealCustomFilter.code === 200) {
        var _resOfDealCustomFilte, _this$chartBusinessIn, _this$accountLabelOpt;
        this.customFiled = resOfDealCustomFilter.results['自定义字段'];
        // 图表交易指标
        this.chartBusinessIndicatorList = this.customFiled.filter(_ref => {
          let {
            type
          } = _ref;
          return type !== 'text' && type !== 'date' && type !== 'integer';
        });
        // 客户标签
        this.accountLabelOptions = (_resOfDealCustomFilte = resOfDealCustomFilter.results['AI智能分析字段'].find(_ref2 => {
          let {
            field_name
          } = _ref2;
          return field_name === 'account_label';
        })) === null || _resOfDealCustomFilte === void 0 ? void 0 : _resOfDealCustomFilte.options;
        if ((_this$chartBusinessIn = this.chartBusinessIndicatorList) !== null && _this$chartBusinessIn !== void 0 && _this$chartBusinessIn.length) {
          this.metricsTypeList.push({
            label: '交易指标',
            type: 'deal_indicator'
          });
        }
        if ((_this$accountLabelOpt = this.accountLabelOptions) !== null && _this$accountLabelOpt !== void 0 && _this$accountLabelOpt.length) {
          this.hasAccountLabel = true;
          this.metricsTypeList.push({
            label: '客户标签',
            type: 'account_label'
          });
        }
      }
    },
    // 图表操作
    dragEnd(val) {
      this.chartList = val;
    },
    moveCustomerBaseProfileChart: _businessIntelligence.moveCustomerBaseProfileChart,
    addChart() {
      this.chartConfigVisible = true;
      this.isEditChart = false;
    },
    async handleCommand(type, data) {
      const params = {
        chart_id: data.chart_id,
        workspace_id: this.workspaceInfo.id
      };
      if (type === 'edit') {
        this.isEditChart = true;
        const res = await (0, _businessIntelligence.getCustomerBaseProfileChartConfig)(params);
        res.results.chart_config.chart_name = data.chart_name;
        this.$refs['chart-config'].chartConfig = res.results.chart_config;
        this.chartId = res.results.chart_id;
        this.chartConfigVisible = true;
      } else {
        this.$confirm(`确认删除此图表吗？`, '删除图表', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(async () => {
          const res = await (0, _businessIntelligence.deleteCustomerBaseProfileChartConfig)(params);
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.chartList = this.chartList.filter(_ref3 => {
              let {
                chart_id
              } = _ref3;
              return chart_id !== data.chart_id;
            });
          }
        });
      }
    },
    handleChartSuccess() {
      if (this.isEditChart) {
        const currentChartIndex = this.chartList.findIndex(_ref4 => {
          let {
            chart_id
          } = _ref4;
          return chart_id === this.chartId;
        });
        this.getChartDataById(this.chartList[currentChartIndex], currentChartIndex);
      } else {
        this.getCustomerBaseProfileChartList();
      }
    },
    // 看板操作
    moveCustomerBaseProfileKanban: _businessIntelligence.moveCustomerBaseProfileKanban,
    async getKanbanList(type) {
      this.kanbanList = [];
      if (!this.workspaceInfo.id) return;
      this.getKanbanListLoading = true;
      const res = await (0, _businessIntelligence.getCustomerBaseProfileKanbanList)({
        workspace_id: this.workspaceInfo.id
      });
      this.getKanbanListLoading = false;
      if (res.code === 200) {
        if (!res.results.data.length) {
          this.kanbanList = [];
          return;
        }
        this.kanbanList = res.results.data.map(item => {
          item.hiddenHandle = [];
          if (item.is_share) {
            item.hiddenHandle.push('edit', 'delete', 'share');
            item.kanban_remark = `来自${item.share_user_name}的分享`;
          }
          return item;
        });
        if (type) {
          // 如果是对看板的新增或者编辑，需要在获取看板列表后刷新指定看板
          if (['create', 'copy'].includes(this.operateType)) {
            // 新增看板默认选中新增的看板
            this.currentKanban = this.kanbanList[this.kanbanList.length - 1];
          } else if (this.operateType === 'edit') {
            this.currentKanban = this.kanbanList.find(_ref5 => {
              let {
                kanban_id
              } = _ref5;
              return kanban_id === this.currentKanban.kanban_id;
            });
          }
          if (!this.currentKanban) return;
          this.getCustomerBaseProfileChartList();
        } else {
          // 如果是第一次进入，不直接触发请求第一个看板，而是通过watch bifilterobj的变化来触发，防止在bi各个模块间切换导致拿到其他bi模块的筛选项发起请求
          this.currentKanban = this.kanbanList[0];
        }
      }
    },
    createKanban() {
      this.operateType = 'create';
      this.drawerVisible = true;
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.currentKanban = activeKanban;
      this.getCustomerBaseProfileChartList();
    },
    handleKanban(type, data) {
      this.operateType = type;
      this[`${type}Kanban`](data);
    },
    editKanban(_ref6) {
      let {
        kanban_id
      } = _ref6;
      this.drawerVisible = true;
      this.getKanbanConfigDetail(kanban_id);
    },
    async getKanbanConfigDetail(kanban_id) {
      this.getKanbanConfigLoading = true;
      const res = await (0, _businessIntelligence.getCustomerBaseProfileKanbanDetail)({
        kanban_id,
        workspace_id: this.workspaceInfo.id
      });
      this.getKanbanConfigLoading = false;
      if (res.code === 200) {
        this.$refs['customer-base-profile-config'].kanbanConfig = this.formateAccountLabelData(res.results);
      }
    },
    async deleteKanban(_ref7) {
      let {
        kanban_id,
        name
      } = _ref7;
      this.$confirm(`看板删除后，被分享人员的看板也会同步删除，是否删除？`, '删除看板', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteCustomerBaseProfileKanban)({
          kanban_id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== kanban_id);
          if (this.kanbanList.length) {
            this.toggleKanban(this.kanbanList[0]);
          }
        }
      });
    },
    async detailKanban(_ref8) {
      let {
        kanban_id
      } = _ref8;
      this.drawerVisible = true;
      this.getKanbanConfigDetail(kanban_id);
    },
    async copyKanban() {
      this.copyKanbanConfigVisible = true;
    },
    async shareKanban() {
      this.shareKanbanConfigVisible = true;
    },
    handleKanbanSuccess(name, description) {
      if (this.operateType === 'edit') {
        this.currentKanban.name = name;
        this.currentKanban.description = description;
        this.getCustomerBaseProfileChartList();
      } else {
        this.getKanbanList('createOrEditKanban');
      }
    },
    formateAccountLabelData(data) {
      // 如果当前用户没有客户标签，则对配置数据中的客户标签进行过滤
      if (!this.hasAccountLabel) {
        data.filter_config = data.filter_config.filter(_ref9 => {
          let {
            type
          } = _ref9;
          return type !== 'account_label';
        });
      }
      data.filter_config.forEach(item => {
        const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
        if (Array.isArray(item.value) && item.value.length && item.value.every(c => timeReg.test(c))) {
          item.value = (0, _timezone.datePickerConvertToZone)(item.value);
        }
      });
      return data;
    },
    async getCustomerBaseProfileChartList() {
      if (!this.currentKanban.kanban_id || !this.biFiltersObj) return;
      this.getChartListLoading = true;
      const res = await (0, _businessIntelligence.getCustomerBaseProfileChartList)({
        kanban_id: this.currentKanban.kanban_id,
        ...this.requestParams
      });
      this.getChartListLoading = false;
      if (res.code === 200) {
        const {
          all_count,
          count,
          data,
          filter_params
        } = res.results;
        this.chartList = data;
        this.overViewData = {
          all_count,
          count
        };
        this.filterParams = filter_params;
        this.chartList.forEach(async (item, index) => {
          await this.getChartDataById(item, index);
        });
      }
    },
    async getChartDataById(item, index) {
      this.$set(this.chartList[index], 'loading', true);
      const res = await (0, _businessIntelligence.getCustomerBaseProfileChartData)({
        chart_id: item.chart_id,
        ...this.requestParams
      });
      if (res.code === 200) {
        item.loading = false;
        const {
          indicator,
          field_type,
          data: originData,
          chart_name
        } = res.results;
        item = {
          ...item,
          indicator,
          field_type,
          originData,
          chart_name: chart_name || this.getChartTitle(field_type, indicator)
        };
        item.chartConfig = this.setOptions(res.results);
        this.$set(this.chartList, index, item);
      }
    },
    setOptions(_ref10) {
      let {
        data,
        chart_type
      } = _ref10;
      const chartOptions = (0, _commonFunc.deepClone)(chartBaseConfig);
      chartOptions.chart_type = chart_type;
      let series;
      if (chart_type === 'pie' || chart_type === 'doughnut') {
        series = {
          data: data.filter(_ref11 => {
            let {
              value
            } = _ref11;
            return Boolean(value);
          }),
          type: 'pie',
          label: {
            formatter: _ref12 => {
              let {
                name,
                percent
              } = _ref12;
              return `${name}(${percent}%)`;
            }
          }
        };
        chartOptions.tooltip = {
          formatter: _ref13 => {
            let {
              marker,
              name,
              percent,
              value
            } = _ref13;
            return `${marker}${name}&nbsp;&nbsp;&nbsp;&nbsp;${value}(${percent}%)`;
          }
        };
        if (chart_type === 'doughnut') {
          series.radius = ['40%', '60%'];
        } else {
          series.radius = '60%';
        }
      }
      if (chart_type === 'bar') {
        chartOptions.grid.top = '5%';
        series = {
          data: [],
          type: 'bar',
          barWidth: '25',
          label: {
            show: true,
            position: 'right',
            formatter: _ref14 => {
              let {
                value
              } = _ref14;
              return value + '个';
            }
          }
        };
        chartOptions.dataZoom = (0, _commonFunc.deepClone)(dataZoom);
        Object.assign(chartOptions, (0, _commonFunc.deepClone)(barChartConfig));
        data.forEach(_ref15 => {
          let {
            name,
            value
          } = _ref15;
          chartOptions.yAxis.data.push(name);
          series.data.push(value);
        });
        const dataLength = series.data.length;
        if (dataLength > 7) {
          chartOptions.dataZoom.startValue = dataLength;
          chartOptions.dataZoom.endValue = dataLength - 8;
        } else {
          chartOptions.dataZoom.show = false;
        }
        chartOptions.yAxis.show = !!dataLength;
      }
      chartOptions.series = series;
      return chartOptions;
    }
  }
};
exports.default = _default;