"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-input", {
    ref: "input",
    attrs: {
      maxlength: _vm.localMaxlength,
      disabled: _vm.disabled,
      placeholder: `请输入${_vm.label}`
    },
    on: {
      input: _vm.handleChange
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "newValue"
    }
  }) : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, [_vm._v(_vm._s(_vm.newValue))]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;