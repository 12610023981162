"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MgSideBarMenu',
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree']),
    activeMenu() {
      if (this.$route.path === '/dashboard') {
        return '/';
      } else {
        return `/${this.$route.path.split('/')[1]}`;
      }
    },
    variables() {
      return _variablesModule.default;
    },
    sideBarMenu() {
      return this.routesTree.filter(item => {
        var _item$meta;
        return item === null || item === void 0 ? void 0 : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.order;
      }).sort((a, b) => a.meta.order - b.meta.order);
    }
  },
  methods: {
    i18nTitle(title) {
      switch (title) {
        case '首页':
          return this.$t('coaching.首页');
        case '培训':
          return this.$t('coaching.培训');
        default:
          return title;
      }
    },
    dynamicFontStyle(text) {
      const len = text.length;
      let fontSize = 12;
      if (len > 8) {
        fontSize = 12 - (len - 4) * 0.5;
      }
      if (fontSize < 8) {
        fontSize = 8;
      }
      return {
        fontSize: `${fontSize}px`
      };
    }
  }
};
exports.default = _default;