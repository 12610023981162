"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _message = require("@/utils/message");
var _vue = require("vue");
var _useTribute = require("../hooks/use-tribute.js");
var _default = {
  __name: 'PromptInput',
  props: {
    prompt: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Object,
      default: () => ({
        empty: '',
        title: '',
        content: ''
      })
    },
    scripts: {
      type: Array,
      default: () => []
    },
    addScript: {
      type: Function,
      default: () => {}
    },
    pasteScript: {
      type: Function,
      default: () => {}
    },
    removeScript: {
      type: Function,
      default: () => {}
    },
    recoverScript: {
      type: Function,
      default: () => {}
    },
    activeScriptId: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:activeScriptId', 'update:prompt', 'openAddScriptDialog'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const inputRef = (0, _vue.ref)(null);
    const inputStatus = (0, _vue.ref)('');
    const TAG_CLASS = 'prompt-editor-active-script-tag';
    (0, _vue.watch)(() => props.fillValue, val => {
      inputRef.value.innerHTML = val;
    });
    (0, _vue.watch)(() => props.activeScriptId, val => {
      activateTag(val);
    });
    const onInput = e => {
      emit('update:prompt', {
        html: inputRef.value.innerHTML,
        text: inputRef.value.innerText,
        tags: inputRef.value.querySelectorAll(`.${TAG_CLASS}`)
      });
    };
    (0, _vue.onMounted)(() => {
      observerElement();
      inputRef.value.addEventListener('paste', e => {
        // const text = e.clipboardData.getData('text/plain');
        // const sel = window.getSelection();
        // const range = sel.getRangeAt(0);
        // const rangeTextLen = getValidTextLength(range.toString());

        // const len =
        //   getValidTextLength(text) +
        //   getValidTextLength(inputRef.value.innerText) -
        //   rangeTextLen;
        // if (len >= 400) {
        //   e.preventDefault();
        // }
        // if (len > 400) {
        //   message.warning('每部分提示词最多输入400字');
        //   return;
        // }
        const html = e.clipboardData.getData('text/html');
        const container = document.createElement('div');
        container.innerHTML = html;
        const span = Array.from(container.childNodes).filter(item => {
          var _item$classList;
          return (_item$classList = item.classList) === null || _item$classList === void 0 ? void 0 : _item$classList.contains(TAG_CLASS);
        });
        if ((span === null || span === void 0 ? void 0 : span.length) === 1) {
          const sel = window.getSelection();
          const range = sel.getRangeAt(0);
          rangePosTemp.value = range;
          const id = appendScriptTag();
          props.pasteScript(id, span[0].dataset.scriptId - 0);
          e.preventDefault();
          return;
        }
      });
      inputRef.value.addEventListener('copy', handleCopyAndCut);
      // 剪切事件
      inputRef.value.addEventListener('cut', handleCopyAndCut);
    });
    function handleCopyAndCut(e) {
      const sel = window.getSelection();
      const range = sel.getRangeAt(0);
      const cloneContents = range.cloneContents();
      if (cloneContents.childNodes.length === 1 && cloneContents.childNodes[0].classList.contains(TAG_CLASS)) {
        if (e.type === 'cut') {
          const span = inputRef.value.querySelector(`.${TAG_CLASS}[data-script-id="${cloneContents.childNodes[0].dataset.scriptId}"]`);
          inputRef.value.removeChild(span);
        }
        _message.message.success(e.type === 'cut' ? '剪切成功' : '复制成功');
        e.preventDefault();
        e.clipboardData.setData('text/html', cloneContents.childNodes[0].outerHTML);
        return;
      }
    }
    (0, _useTribute.useTribute)({
      inputRef,
      onSelectMenu: onSelectMenuItem,
      tagClassName: TAG_CLASS + ' active'
    });
    const rangePosTemp = (0, _vue.ref)(null);
    async function onSelectMenuItem(item) {
      const tag = document.querySelector(`.${TAG_CLASS}.active`);
      if (props.scripts.length >= 10) {
        _message.message.warning('最多添加10条关键问题');
        inputRef.value.removeChild(tag);
        return;
      }
      if (item === 'type') {
        tag.classList.remove('active');
        const id = props.addScript({
          el: tag
        });
        tag.dataset.scriptId = id;
        tag.innerText = ' 请在右侧设置关键问题 ';
        tag.classList.add('is-empty');
        emit('update:activeScriptId', tag.dataset.scriptId - 0);
        formatScriptTag(tag);
        await (0, _vue.nextTick)();
        const input = document.querySelector(`.script-item[data-script-id="${id}"] textarea`);
        input === null || input === void 0 ? void 0 : input.focus();
        activateTag(tag.dataset.scriptId);
      } else if (item === 'knowledge') {
        inputRef.value.removeChild(tag);
        const sel = window.getSelection();
        rangePosTemp.value = sel.getRangeAt(0);
        emit('openAddScriptDialog');
      }
    }
    function appendScriptTag() {
      var _rangePosTemp$value;
      const tag = document.createElement('span');
      tag.contentEditable = false;
      tag.classList.add(TAG_CLASS);
      tag.classList.add('is-empty');
      tag.innerText = ' 请在右侧设置关键问题 ';
      const id = props.addScript({
        el: tag
      });
      tag.dataset.scriptId = id;
      const cloneRange = (_rangePosTemp$value = rangePosTemp.value) === null || _rangePosTemp$value === void 0 ? void 0 : _rangePosTemp$value.cloneRange();
      const textNode = document.createTextNode(' ');
      cloneRange.insertNode(textNode);
      cloneRange.insertNode(tag);
      cloneRange.setStartAfter(textNode);
      cloneRange.collapse(true);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(cloneRange);
      rangePosTemp.value = sel.getRangeAt(0);
      formatScriptTag(tag);
      return id;
    }
    function formatScriptTag(tag) {
      tag.addEventListener('click', e => {
        e.stopPropagation();
        emit('update:activeScriptId', tag.dataset.scriptId - 0);
        activateTag(tag.dataset.scriptId);
        const range = document.createRange();
        range.selectNode(tag);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      });
    }
    function activateTag(id) {
      const tags = document.querySelectorAll(`.${TAG_CLASS}`);
      tags.forEach(item => {
        item.classList.remove('is-active');
      });
      const tag = document.querySelector(`.${TAG_CLASS}[data-script-id="${id}"]`);
      tag === null || tag === void 0 ? void 0 : tag.classList.add('is-active');
    }
    function observerElement() {
      const observer = new MutationObserver(function (mutationList) {
        mutationList.forEach(mutation => {
          if (mutation.type === 'childList') {
            const {
              addedNodes,
              removedNodes
            } = mutation;
            [...removedNodes].forEach(node => {
              var _node$dataset, _node$dataset2;
              if (node !== null && node !== void 0 && (_node$dataset = node.dataset) !== null && _node$dataset !== void 0 && _node$dataset.scriptId) {
                props.removeScript(node.dataset.scriptId - 0);
              }
              if (node !== null && node !== void 0 && (_node$dataset2 = node.dataset) !== null && _node$dataset2 !== void 0 && _node$dataset2.ispromptpre) {
                const span = document.createElement('span');
                span.innerText = node.innerText;
                span.dataset.ispromptpre = true;
                span.contentEditable = false;
                const firstChild = inputRef.value.firstChild;
                if (firstChild) {
                  var _firstChild$dataset;
                  if (!((_firstChild$dataset = firstChild.dataset) !== null && _firstChild$dataset !== void 0 && _firstChild$dataset.ispromptpre)) {
                    inputRef.value.insertBefore(span, firstChild);
                  }
                } else {
                  inputRef.value.appendChild(span);
                }
                const sel = window.getSelection();
                const range = sel === null || sel === void 0 ? void 0 : sel.getRangeAt(0);
                if (range && range.startContainer === range.endContainer && range.startContainer === inputRef.value && range.startOffset === range.endOffset && range.startOffset === 0) {
                  range.selectNode(span);
                  range === null || range === void 0 ? void 0 : range.setStartAfter(span);
                }
              }
            });
            [...addedNodes].forEach(node => {
              var _node$dataset3, _node$nextSibling, _node$nextSibling$dat;
              if (node !== null && node !== void 0 && (_node$dataset3 = node.dataset) !== null && _node$dataset3 !== void 0 && _node$dataset3.scriptId) {
                props.recoverScript(node.dataset.scriptId - 0);
              }
              if ((_node$nextSibling = node.nextSibling) !== null && _node$nextSibling !== void 0 && (_node$nextSibling$dat = _node$nextSibling.dataset) !== null && _node$nextSibling$dat !== void 0 && _node$nextSibling$dat.ispromptpre) {
                inputRef.value.removeChild(node);
              }
            });
            onInput();
          }
        });
      });
      observer.observe(inputRef.value, {
        childList: true,
        subtree: true
      });
    }
    const getPrompt = () => {
      const html = inputRef.value.innerHTML;
      console.log(html);
    };
    const setPrompt = html => {
      inputRef.value.innerHTML = html;
    };
    expose({
      getPrompt,
      setPrompt,
      appendScriptTag,
      formatScriptTag
    });
    return {
      __sfc: true,
      props,
      emit,
      inputRef,
      inputStatus,
      TAG_CLASS,
      onInput,
      handleCopyAndCut,
      rangePosTemp,
      onSelectMenuItem,
      appendScriptTag,
      formatScriptTag,
      activateTag,
      observerElement,
      getPrompt,
      setPrompt
    };
  }
};
exports.default = _default;