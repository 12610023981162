"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$activeDataSetData;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: ["data-set-data-item", {
      active: ((_vm$activeDataSetData = _vm.activeDataSetData) === null || _vm$activeDataSetData === void 0 ? void 0 : _vm$activeDataSetData.id) === _vm.source.id
    }],
    on: {
      click: function ($event) {
        return _vm.handleChangeDataSetData(_vm.source);
      }
    }
  }, [_c("i", {
    class: ["iconfont", {
      "icon-video-camera": _vm.source.type === "video"
    }, {
      "icon-phone-outline": _vm.source.type === "audio"
    }, {
      "icon-im": _vm.source.type === "doc"
    }]
  }), _c("span", {
    staticClass: "conv-name"
  }, [_vm._v(_vm._s(_vm.source.name))]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.source.is_annotated,
      expression: "source.is_annotated"
    }],
    staticClass: "person-annotated"
  }, [_vm._v("人工已标注")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;