"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "control-experiment"
  }, [_c("div", {
    staticClass: "experiment-list"
  }, [_c("el-button", {
    staticClass: "create-btn",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.creatExperiment
    }
  }, [_vm._v(" A/B 测试 ")]), _c("HeaderFilter", {
    staticClass: "header-filter",
    on: {
      filterChange: _vm.handleFilterChange
    }
  }), _c("experiment-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "experiment-table",
    attrs: {
      "table-data": _vm.tableData
    },
    on: {
      editExperiment: _vm.handleEditExperiment,
      copyExperiment: _vm.handleCopyExperiment,
      shareExperiment: _vm.handleShareExperiment,
      goExperimentDetail: _vm.goExperimentDetail,
      refresh: _vm.refresh
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-sizes": [10, 20, 30, 40],
      "current-page": _vm.listParams.page,
      "page-size": _vm.listParams.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1), _c("ExperimentDrawer", {
    attrs: {
      visible: _vm.experimentDrawerVisible,
      "edit-info": _vm.editInfo,
      "is-edit": _vm.isEdit,
      "is-copy": _vm.isCopy
    },
    on: {
      "update:visible": function ($event) {
        _vm.experimentDrawerVisible = $event;
      },
      refresh: _vm.refresh
    }
  }), _c("shareConfig", {
    attrs: {
      visible: _vm.shareConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        id: _vm.currentExperimentId
      },
      "handle-fn": _vm.shareExperiment,
      "cancel-handle-fn": _vm.cancelShareExperiment,
      "get-list": _vm.getShareExperimentList
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareConfigVisible = $event;
      }
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;