"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.biDimensionObj ? false : true,
      expression: "biDimensionObj ? false : true"
    }],
    staticClass: "bi-dimension",
    attrs: {
      "element-loading-text": "数据加载中"
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.biDimensionObj, function (item, index) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        name: item.name
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c("tab-label", {
            attrs: {
              "label-title": item.labelTitle,
              "label-value-html": item.labelValueHtml,
              "mark-value": item.markPoint
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c("tab-content", {
      attrs: {
        "key-name": item.name,
        "des-title": item.labelTitle,
        "des-detail": item.desDetail,
        "des-all-count": item.desAllCount,
        "des-count": item.desCount,
        "content-arrays": item.table,
        "horizontal-array": item.xAxis,
        "avg-obj": {
          value: item.avg,
          label: item.avgStr
        },
        "chart-type": item.labelType,
        "second-label": _vm.labels[index]
      },
      on: {
        changeContentArrays: function ($event) {
          return _vm.changeItemTable(_vm.biDimensionObj[index]);
        },
        changeSortType: _vm.changeSortType
      }
    })], 1);
  }), 1), _vm.biDimensionObj && JSON.stringify(_vm.biDimensionObj) == "{}" ? _c("div", {
    staticClass: "no-dimension"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;