"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trade = require("@/api/trade");
var _client = require("@/api/client");
var _videoDetail = require("@/api/videoDetail");
var _speakTimeCard = _interopRequireDefault(require("./speakTimeCard"));
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SpeakTimeTabContent',
  components: {
    SpeakTimeCard: _speakTimeCard.default,
    KeyEventDetailList: _keyEventDetailList.default,
    EventsList: _eventsList.default
  },
  props: {
    currentRow: {
      type: Object,
      default: () => ({})
    },
    // YYYY-MM-DD
    activeConvDate: {
      type: String,
      default: ''
    },
    accountId: {
      type: [String, Number],
      default: ''
    },
    conversationIds: {
      type: Array,
      default: () => []
    },
    hasAccountLabelPermission: {
      type: Boolean,
      default: false
    },
    clickCard: {
      type: Boolean,
      default: false
    },
    noEventClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      speakTimeLoading: false,
      speakTimeList: [],
      eventListShow: false,
      // 跟进动态-关键事件对应细节相关变量
      conversationId: '',
      conversationDate: '',
      activeEventData: [],
      mediaType: '',
      participants: [],
      eventListLoading: false,
      isKeyEventDetail: false,
      // 跟进动-关键事件列表相关变量
      eventEngine: [],
      total: 0,
      eventName: '',
      eventTotal: 0,
      isEventsList: false // 展示关键事件列表页
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'busiActiveConver', 'busiActiveDate']),
    drawerTitle() {
      const drawerTitle = this.eventName ? `${this.eventName} (${this.eventTotal})` : '';
      return this.isKeyEventDetail ? drawerTitle : `关键事件 (${this.total})`;
    },
    filterSpeakTimeList() {
      if (this.conversationIds.length) {
        return this.speakTimeList.filter(item => this.conversationIds.includes(item.id));
      }
      return this.speakTimeList;
    }
  },
  watch: {
    currentRow: {
      handler: function (val) {
        if (val.trade_id) {
          // 切换table行时，跟进动态展示列表
          this.eventListShow = false;
          this.$nextTick(async () => {
            await this.getTradeSpeakTime(val.trade_id);
            this.scrollToActiveConv();
          });
        }
      },
      immediate: true,
      deep: true
    },
    accountId: {
      handler: async function (val) {
        if (val) {
          await this.getConversationList(parseInt(val));
          this.scrollToActiveConv();
        }
      },
      immediate: true
    },
    activeConvDate: {
      handler: async function (val) {
        if (val && this.$refs['el-timeline-item']) {
          this.scrollToActiveConv();
        }
      },
      immediate: true
    },
    busiActiveConver(val) {
      if (val && this.$refs['el-timeline-item']) {
        if (!this.filterSpeakTimeList.some(item => item.id === val)) {
          this.$emit('cancelFilter');
        }
        this.scrollToActiveConv();
      }
    },
    filterSpeakTimeList(val) {
      if (this.conversationIds.length && val.length) {
        this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$moment(val[0].date).format('YYYY-MM-DD'));
        this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', val[0].id);
      }
    }
  },
  methods: {
    // 点击商机跟进动态会话气泡，对应会话背景高亮
    handleActiveDate(item) {
      if (this.activeConvDate) {
        return this.$moment(item.date).format('YYYY-MM-DD HH:mm') === this.activeConvDate;
      } else if (this.busiActiveConver) {
        return item.id === this.busiActiveConver;
      }
    },
    // 滚动到跟进动态对应会话
    scrollToActiveConv() {
      if ((this.activeConvDate || this.busiActiveConver) && this.$refs['el-timeline-item']) {
        this.$nextTick(() => {
          let top = 0;
          this.filterSpeakTimeList.some((item, index) => {
            if (this.handleActiveDate(item)) {
              const timelineElement = this.$refs['el-timeline-item'][index];
              top = timelineElement ? timelineElement.$el.offsetTop - 20 : 0;
              return true;
            }
          });
          this.$emit('scrollToActiveConv', top);
        });
      }
    },
    handleItemDate(item) {
      const today = this.$moment().format('YYYY-MM-DD');
      const yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
      const date = this.$plus8ToCurrentZone(item.date).format('YYYY-MM-DD');
      const time = this.$plus8ToCurrentZone(item.date).format('HH:mm');
      if (date === today) {
        item.dateText = `今天 ${time}`;
      } else if (date === yesterday) {
        item.dateText = `昨天 ${time}`;
      } else {
        item.dateText = this.$plus8ToCurrentZone(item.date).format('YYYY-MM-DD HH:mm');
      }
      return item.dateText;
    },
    goBack() {
      if (this.isKeyEventDetail && this.isEventsList) {
        // 从关键事件列表进入关键事件详情，返回关键事件列表页
        this.isKeyEventDetail = false;
      } else {
        // 直接从关键事件进入关键事件详情，返回跟踪动态列表
        this.eventListShow = false;
        this.eventEngine = [];
        this.total = 0;
        this.isEventsList = false;
      }
    },
    keyEventToDetail(index) {
      const url = `${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.conversationId}&keyWordId=${this.eventId}&itemIndex=${index}&noback=true`;
      window.open(url);
    },
    goConvCard() {
      this.eventListShow = false;
      this.eventEngine = [];
      this.total = 0;
      this.isEventsList = false;
    },
    async getTradeSpeakTime(trade_id) {
      try {
        this.speakTimeList = [];
        this.speakTimeLoading = true;
        const res = await (0, _trade.getTradeSpeakTime)({
          trade_id,
          size: 9999,
          page: 1,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 20000) {
          this.speakTimeList = res.results.items;
        }
      } finally {
        this.speakTimeLoading = false;
      }
    },
    async getConversationList(account_id, trade_id) {
      try {
        this.speakTimeList = [];
        this.speakTimeLoading = true;
        const data = {
          account_id: account_id,
          size: 9999,
          current_page: 1
        };
        if (trade_id) {
          data.trade_id = trade_id;
        }
        const res = await (0, _client.getAccountConversation)(data);
        if (res.code === 20000) {
          this.speakTimeList = res.results.conversations;
          if (this.speakTimeList.length) {
            if (this.busiActiveConver) {
              this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.busiActiveDate);
              this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', this.busiActiveConver);
            } else {
              this.$store.commit('business/SET_BUSI_ACTIVE_DATE', this.$plus8ToCurrentZone(this.speakTimeList[0].date).format('YYYY-MM-DD'));
              this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', this.speakTimeList[0].id);
            }
            this.$emit('initCalendar');
          }
        }
      } finally {
        this.speakTimeLoading = false;
      }
    },
    // 关键事件列表内点击关键事件
    handleTagClick(eventId) {
      this.isKeyEventDetail = true;
      this.$emit('clickTag', this.conversationId, this.conversationDate);
      this.getEventTrackers(eventId, this.conversationId);
    },
    //  跟进动态-某一个会话对应关键事件列表
    async openEventsList(id, date) {
      try {
        this.isEventsList = true;
        this.conversationId = id;
        this.conversationDate = date;
        this.eventListShow = true;
        this.isKeyEventDetail = false;
        this.eventListLoading = true;
        const res = await (0, _videoDetail.getEventEngine)(id, this.accountId ? undefined : this.workspaceInfo.id);
        if (res.code === 200) {
          this.eventEngine = res.results;
          this.total = res.total;
        }
      } finally {
        this.eventListLoading = false;
      }
    },
    clickConvCard(item) {
      this.conversationId = item.id;
      this.$emit('clickConvCard', this.conversationId, item.date);
    },
    // 跟进动态-会话关键事件对应会话细节
    openKeyEventDetailList(event_id, item) {
      this.isKeyEventDetail = true;
      this.conversationId = item.id;
      this.eventId = event_id;
      const {
        conversation_type,
        participants
      } = item;
      this.participants = participants;
      this.eventListShow = true;
      this.mediaType = conversation_type;
      this.$emit('clickTag', this.conversationId, item.date);
      this.getEventTrackers(event_id, this.conversationId);
    },
    async getEventTrackers(event_id, conversation_id) {
      try {
        this.activeEventData = [];
        this.eventName = '';
        this.eventListLoading = true;
        const data = {
          conversation_id,
          event_id: event_id || this.eventId
        };
        const res = await (0, _videoDetail.getEventTrackers)(data);
        if (res.code === 20000) {
          this.activeEventData = res.results.datas;
          this.eventName = res.results.name;
          this.eventTotal = res.results.count;
        }
      } finally {
        this.eventListLoading = false;
      }
    },
    openCustomerLabelDetailList(tag, item) {
      this.isKeyEventDetail = true;
      this.conversationId = item.id;
      const {
        conversation_type,
        participants
      } = item;
      this.participants = participants;
      this.eventListShow = true;
      this.mediaType = conversation_type;
      this.$emit('clickTag', this.conversationId, item.date);
      this.getCustomerLabelTag(tag, this.conversationId);
    },
    async getCustomerLabelTag(tag, conversation_id) {
      try {
        this.activeEventData = [];
        this.eventName = '';
        this.eventListLoading = true;
        const data = {
          account_id: this.accountId,
          conversation_id,
          tag_id: tag.id,
          tag_value: tag.value
        };
        const res = await (0, _client.getCustomerLabelTag)(data);
        if (res.code === 20000) {
          this.activeEventData = res.results.datas;
          this.eventName = tag.value || res.results.name;
          this.eventTotal = res.results.count;
        }
      } finally {
        this.eventListLoading = false;
      }
    }
  }
};
exports.default = _default;