"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "leader-task-container"
  }, [_c("TaskTableFilter", {
    attrs: {
      type: "leader"
    },
    on: {
      filterChange: _vm.filterChange
    }
  }), _c("leader-task-table", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.totalCount || _vm.tableLoading,
      expression: "totalCount || tableLoading"
    }],
    attrs: {
      "table-data": _vm.tableData,
      "table-loading": _vm.tableLoading
    },
    on: {
      tableSortChange: _vm.tableSortChange,
      delete: _vm.handleDelete,
      editTask: function ($event) {
        return _vm.$emit("editTask", $event);
      },
      copyTask: function ($event) {
        return _vm.$emit("copyTask", $event);
      }
    }
  }), _c("NoData", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.totalCount && !_vm.tableLoading,
      expression: "!totalCount && !tableLoading"
    }],
    attrs: {
      icon: "icon-a-Property1no-todo",
      tips: _vm.$t("coaching.noTrainingTasksYet")
    }
  }), _c("footer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.totalCount,
      expression: "totalCount"
    }],
    staticClass: "footer"
  }, [_c("el-pagination", {
    class: _vm.totalCount >= 10000 ? "hide-last-page" : "",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.requestParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.requestParams.size,
      total: _vm.totalCount
    },
    on: {
      "size-change": _vm.tableSizeChange,
      "current-change": _vm.tableCurrentPageChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;