"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _composables = require("vue-router/composables");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const route = (0, _composables.useRoute)();
    const router = (0, _composables.useRouter)();
    const dataAnalysisChildrenRoutes = (0, _vue.computed)(() => {
      const res = (0, _commonFunc.recursionGet)({
        recurArr: store.getters.routesTree,
        targetValue: 'CoachDataAnalysis',
        targetAttr: 'name',
        getAttr: 'children'
      });
      if (res && res.length > 0) {
        return res.sort((a, b) => a.meta.order - b.meta.order);
      }
      return [];
    });
    const activeName = (0, _vue.ref)(route.name);
    (0, _vue.watch)(() => route.name, val => {
      activeName.value = val;
    });
    const onRouteChange = val => {
      activeName.value = route.name;
    };
    const routeTitle = route.meta.title;
    const handleClick = val => {
      router.replace({
        name: val.name
      });
    };
    return {
      __sfc: true,
      props,
      store,
      route,
      router,
      dataAnalysisChildrenRoutes,
      activeName,
      onRouteChange,
      routeTitle,
      handleClick,
      MenuToggleBtn: _MenuToggleBtn.default
    };
  }
};
exports.default = _default;