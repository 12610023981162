"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    attrs: {
      data: _vm.tableData,
      "header-cell-style": _vm.headerCellStyle,
      "cell-style": _vm.cellStyle,
      "default-sort": {
        prop: "create_at",
        order: "descending"
      },
      "row-class-name": _vm.tableRowClassName,
      height: "calc(100vh - 210px)"
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, _vm._l(_vm.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.label,
      attrs: {
        "show-overflow-tooltip": "",
        label: item.label,
        prop: item.prop,
        "min-width": item.width,
        fixed: item.fixed,
        sortable: _vm.sortable.includes(item.prop) ? "custom" : false,
        "sort-orders": ["ascending", "descending"]
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [item.prop === "name" ? [_c("div", {
            staticClass: "evaluation-name"
          }, [_c("i", {
            staticClass: "iconfont icon-compliance-evaluation-form-fill"
          }), _c("el-link", {
            attrs: {
              type: "primary",
              underline: false
            },
            on: {
              click: function ($event) {
                return _vm.$emit("previewEvaluation", row);
              }
            }
          }, [_vm._v(_vm._s(row[item.prop]))]), row.is_default ? _c("span", {
            staticClass: "default-tag"
          }, [_vm._v("默认")]) : _vm._e()], 1)] : item.prop === "task_count" ? [_c("div", {
            staticClass: "task-count"
          }, [_c("i", {
            staticClass: "iconfont icon-compliance-tasks"
          }), _c("span", [_vm._v(_vm._s(row.task_count))])])] : item.prop === "conversation_count" ? [_c("div", {
            staticClass: "conversation-count"
          }, [_c("i", {
            staticClass: "iconfont icon-video-camera"
          }), _c("span", [_vm._v(_vm._s(row.conversation_count))])])] : item.prop === "user_info" ? [_c("div", [row.user_info.name ? _c("span", {
            staticClass: "user-info"
          }, [_c("MgvAvatarName", {
            attrs: {
              name: row.user_info.name,
              src: row.user_info.avatar,
              size: 28
            }
          }), _vm._v(" " + _vm._s(row.user_info.name) + " ")], 1) : _c("span", [_vm._v("-")])])] : item.prop === "create_at" ? [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm")) + " ")] : item.prop === "status" ? [_c("el-switch", {
            attrs: {
              value: row.status,
              "active-color": "#4461EC",
              "inactive-color": "#E2E5E9",
              "active-value": 1,
              "inactive-value": 2,
              width: 34
            },
            on: {
              change: function ($event) {
                return _vm.$emit("handleEvaluationStatus", row);
              }
            }
          })] : item.prop === "operate" ? [_c("div", {
            staticClass: "operate-buttons"
          }, [_c("mg-operate-column", {
            attrs: {
              list: _vm.getOperateList(row)
            },
            on: {
              command: function ($event) {
                return _vm.handleCommand($event, row);
              }
            }
          })], 1)] : [_vm._v(" " + _vm._s(row[item.prop]) + " ")]];
        }
      }], null, true)
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;