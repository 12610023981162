"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _client = require("@/api/client");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ContactPersonCard',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    formDesc: {
      type: Array,
      default: () => []
    },
    isShow: {
      type: Boolean,
      default: false
    },
    inDeal: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: [Boolean, Number],
      default: 0
    }
  },
  data() {
    return {
      isEdit: false,
      formData: {},
      everData: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions'])
  },
  methods: {
    handleKeyChange(key, value) {
      this.$emit('keyChange', {
        key,
        value
      });
    },
    cancel() {
      this.$refs.form.clearValidate();
      this.$emit('cancel', this.everData);
      this.isEdit = false;
    },
    enterEdit() {
      this.everData = {
        ...this.value
      };
      this.isEdit = true;
    },
    async submit() {
      const {
        account_id,
        id: contact_id,
        ...ever
      } = this.value;
      const params = {
        contact: ever,
        account_id,
        contact_id
      };
      if (this.$refs['form'].validate()) {
        const res = await (0, _client.postCreateContact)(params);
        if (res.code == 200) {
          this.$message.success('保存成功');
          this.isEdit = false;
        }
      }
    },
    handleCommand(command) {
      this.$emit(command, this.value);
    }
  }
};
exports.default = _default;