"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coaching-center"
  }, [_c("div", {
    staticClass: "upper-part"
  }, [_c("menu-toggle-btn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: _vm.$t("coaching.taskManagement")
    }
  }), _vm.interfacePermission.createCoachingTask ? _c("el-button", {
    staticClass: "create-task-btn",
    attrs: {
      icon: "el-icon-plus",
      type: "primary",
      size: "medium"
    },
    on: {
      click: _vm.createTask
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.newTask")))]) : _vm._e(), _vm.interfacePermission.saveTaskClassify ? _c("TaskClassifyManagementBtn") : _vm._e(), _vm.interfacePermission.setAiConversationSync ? _c("AiConversationSync") : _vm._e()], 1), _c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.coachingCenterChildrenRoutes, function (item) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.meta.subtitle,
        name: item.name
      }
    });
  }), 1), _c("main", {
    staticClass: "coaching-center-container"
  }, [_c("router-view", {
    attrs: {
      "refresh-sign": _vm.refreshSign
    },
    on: {
      editTask: _vm.editTask,
      copyTask: _vm.copyTask
    }
  })], 1), _c("CreateTaskDrawer", {
    attrs: {
      visible: _vm.tasksDrawerVisible,
      "task-id": _vm.taskId,
      "is-edit": _vm.isEdit,
      "task-status": _vm.taskStatus
    },
    on: {
      "update:visible": function ($event) {
        _vm.tasksDrawerVisible = $event;
      },
      refresh: _vm.handleRefresh
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;