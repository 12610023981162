"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearchContent = _interopRequireDefault(require("./KnowledgeSearchContent"));
var _KnowledgeMultipleTree = _interopRequireDefault(require("./KnowledgeMultipleTree"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CourseScript',
  components: {
    KnowledgeSearchContent: _KnowledgeSearchContent.default,
    KnowledgeMultipleTree: _KnowledgeMultipleTree.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default
  },
  props: {
    scriptVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      loading: false
    };
  },
  computed: {
    searchNodataTips() {
      return `未搜索到${this.searchValue}`;
    }
  },
  methods: {
    scrollToScriptEnd() {
      this.$emit('scrollToScriptEnd');
    },
    closeScript() {
      this.$emit('closeScript');
    }
  }
};
exports.default = _default;