"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compliance = require("@/api/compliance");
var _routeMap = require("@/router/routeMap");
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EvaluationTable',
  components: {
    MgOperateColumn: _MgOperateColumn.default
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeader: [{
        label: '评价单名称',
        prop: 'name',
        width: 200,
        fixed: 'left'
      }, {
        label: '任务数',
        prop: 'task_count',
        width: 160
      }, {
        label: '会话数',
        prop: 'conversation_count',
        width: 160
      }, {
        label: '创建人',
        prop: 'user_info',
        width: 160
      }, {
        label: '创建时间',
        prop: 'create_at',
        width: 160
      }, {
        label: '启用状态',
        prop: 'status',
        width: 120,
        fixed: 'right'
      }, {
        label: '操作',
        prop: 'operate',
        width: 170,
        fixed: 'right'
      }],
      sortable: ['task_count', 'conversation_count', 'create_at'],
      headerCellStyle: {
        backgroundColor: '#F3F5F6',
        padding: '8px auto',
        color: '#576575'
      },
      cellStyle: {
        height: '72px',
        color: '#2B323B'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  methods: {
    getOperateList(row) {
      return [{
        label: '编辑',
        command: 'edit',
        isShow: true
      }, {
        label: '复制',
        command: 'copy',
        isShow: true
      }, {
        label: row.is_default ? '取消默认' : '设为默认',
        command: 'handleDefault',
        icon: row.is_default ? 'icon-remove-outline' : 'icon-circle-check',
        isShow: true
      }, {
        label: '删除',
        command: 'delete',
        icon: 'icon-delete',
        class: 'delete',
        isShow: true
      }];
    },
    handleCommand(command, row) {
      switch (command) {
        case 'edit':
          this.editEvaluationTemplate(row);
          break;
        case 'copy':
          this.copyEvaluationTemplate(row);
          break;
        case 'handleDefault':
          this.handleDefault(row);
          break;
        case 'delete':
          this.deleteEvaluationTemplate(row);
          break;
        default:
          break;
      }
    },
    copyEvaluationTemplate(row) {
      this.$emit('copy', row);
    },
    async handleDefault(row) {
      const data = {
        template_id: row.id,
        workspace_id: this.workspaceInfo.id,
        is_default: !row.is_default
      };
      const res = await (0, _compliance.handleDefaultEvaluation)(data);
      if (res.code === 20000) {
        this.$message.success(!row.is_default ? '设置成功' : '取消成功');
        row.is_default = !row.is_default;
        if (row.is_default) {
          this.tableData.forEach(item => {
            if (item.id !== row.id) {
              item.is_default = false;
            }
          });
        }
      }
    },
    tableRowClassName(_ref) {
      let {
        row
      } = _ref;
      if (row.highlight) {
        return 'high-light';
      }
    },
    getEvaluationStatus(status) {
      const statusList = {
        1: '已启用',
        2: '已禁用'
      };
      return statusList[status];
    },
    sortChange(_ref2) {
      let {
        prop,
        order
      } = _ref2;
      this.$emit('sortChange', prop, order);
    },
    editEvaluationTemplate(row) {
      this.$router.push({
        path: _routeMap.ROUTES.complianceEditEvaluation,
        query: {
          type: 'edit',
          id: row.id
        }
      });
    },
    async deleteEvaluationTemplate(row) {
      this.$confirm(`${row.task_count ? '删除评价单后任务关联的评价单数据不会被删除，此操作无法恢复，请确认' : '删除后不可恢复，是否确定删除？'}`, '删除评价单', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _compliance.deleteEvaluationTemplate)({
          template_id: row.id
        });
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.$emit('refresh');
        }
      });
    }
  }
};
exports.default = _default;