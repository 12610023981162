import { render, staticRenderFns } from "./AsideTop.vue?vue&type=template&id=4254468e&scoped=true&"
import script from "./AsideTop.vue?vue&type=script&lang=js&"
export * from "./AsideTop.vue?vue&type=script&lang=js&"
import style0 from "./AsideTop.vue?vue&type=style&index=0&id=4254468e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4254468e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4254468e')) {
      api.createRecord('4254468e', component.options)
    } else {
      api.reload('4254468e', component.options)
    }
    module.hot.accept("./AsideTop.vue?vue&type=template&id=4254468e&scoped=true&", function () {
      api.rerender('4254468e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/knowledge/snippetsLibrary/components/AsideTop.vue"
export default component.exports