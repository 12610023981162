"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MegaTranGuide = _interopRequireDefault(require("../components/MegaTranGuide.vue"));
var _KeyEventManagementHeader = _interopRequireDefault(require("./components/KeyEventManagementHeader.vue"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _EventTree = _interopRequireDefault(require("./components/EventTree.vue"));
var _EventTableFilter = _interopRequireDefault(require("./components/EventTableFilter.vue"));
var _EventTable = _interopRequireDefault(require("./components/EventTable.vue"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _conversationList = require("@/api/conversationList");
var _keyEventManagement = require("@/api/keyEventManagement");
var _useModelInfo = require("../keyEventCreateRuleModel/hooks/use-model-info");
var _MoveEventDialog = _interopRequireDefault(require("./components/MoveEventDialog.vue"));
var _BatchOperate = _interopRequireDefault(require("./components/BatchOperate.vue"));
var _SetKeyEventApplyPermission = _interopRequireDefault(require("./components/SetKeyEventApplyPermission.vue"));
var _SetModelDialog = _interopRequireDefault(require("./components/SetModelDialog.vue"));
var _CreateKeyEventDialog = _interopRequireDefault(require("./components/CreateKeyEventDialog.vue"));
var _CreateKeyEventFolderDialog = _interopRequireDefault(require("./components/CreateKeyEventFolderDialog.vue"));
var _KeyEventFolderListView = _interopRequireDefault(require("./components/KeyEventFolderListView.vue"));
var _tableOperations = require("./hooks/table-operations");
var _elementUi = require("element-ui");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** 关键事件树 */
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const router = (0, _useRouter.useRouter)();
    const route = (0, _useRouter.useRoute)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const Initial_Event = {
      id: '',
      name: '全部关键事件'
    };
    const urlEvent = route.query;
    const currentEvent = (0, _vue.ref)(urlEvent.id ? urlEvent : Initial_Event);
    (0, _vue.watch)(currentEvent, val => {
      history.replaceState(null, '', `?id=${val.id}&name=${val.name}`);
    });
    const eventTree = (0, _vue.ref)([]);
    const eventTreeLoading = (0, _vue.ref)(false);
    const {
      modelInfo,
      setModelInfo,
      resetModelInfo
    } = (0, _useModelInfo.useModelInfo)();
    const eventTreeRef = (0, _vue.ref)(null);
    const createFolder = () => {
      eventTreeRef.value.createFolder();
    };
    const onCreateFolderSuccess = async curEvent => {
      await getAllKeyEventList();
      currentEvent.value = curEvent;
      refreshTableData();
    };

    // 初始化
    (0, _vue.watch)(workspaceId, async val => {
      if (!val) return;
      initEventAndTable(currentEvent.value);
    }, {
      immediate: true
    });
    async function initEventAndTable() {
      let curEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Initial_Event;
      await getAllKeyEventList();
      currentEvent.value = curEvent;
      await (0, _vue.nextTick)();
      eventTreeRef.value.setCurrentKey(curEvent.id);
      getTableData();
      getEventFoldersApi();
    }
    async function getAllKeyEventList() {
      eventTreeLoading.value = true;
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: workspaceId.value,
        keep_empty: true,
        new_flag: true,
        show_disable: true
      }).finally(() => {
        eventTreeLoading.value = false;
      });
      eventTree.value = res.results.events;
    }

    // 刷新顶部
    const keyEventManagementHeaderRef = (0, _vue.ref)(null);
    const refreshTaskProgress = () => {
      var _keyEventManagementHe;
      keyEventManagementHeaderRef === null || keyEventManagementHeaderRef === void 0 ? void 0 : (_keyEventManagementHe = keyEventManagementHeaderRef.value) === null || _keyEventManagementHe === void 0 ? void 0 : _keyEventManagementHe.getTaskProgress();
    };

    // 筛选项
    const originTableData = (0, _vue.ref)([]);
    // 将原始的表单数据根据model_id为0进行筛选
    const unsetModelCount = (0, _vue.computed)(() => originTableData.value.filter(item => !item.model_id && item.event_type !== 'system').length); // 未设置模型数0;
    const unsetModelTagClosable = (0, _vue.ref)(false); // 是否可关闭
    const handleTagClick = () => {
      unsetModelTagClosable.value = !unsetModelTagClosable.value;
    };
    (0, _vue.watch)(unsetModelTagClosable, val => {
      if (val) {
        tableData.value = originTableData.value.filter(item => !item.model_id && item.event_type !== 'system');
      } else {
        tableData.value = originTableData.value;
      }
    });

    /** 关键事件表格 */
    const tableFilterParams = (0, _vue.ref)({
      name: '',
      status: ''
    });
    const tableLoading = (0, _vue.ref)(false);
    const handleTableFilterChange = params => {
      tableFilterParams.value = params;
      getTableData();
    };
    const tableData = (0, _vue.ref)([]);
    async function getAllTableData() {
      currentEvent.value = Initial_Event;
      eventTreeRef.value.setCurrentKey();
      getTableData();
      getEventFoldersApi();
    }
    async function getTableData() {
      tableLoading.value = true;
      const params = {
        workspace_id: workspaceId.value,
        folder_id: currentEvent.value.id,
        is_one_level_child: !!currentEvent.value.id,
        ...tableFilterParams.value
      };
      const res = await (0, _keyEventManagement.getKeyEventList)(params).finally(() => {
        tableLoading.value = false;
      });
      originTableData.value = (0, _cloneDeep.default)(res.results.events);
      tableData.value = res.results.events;
    }
    const refreshTableData = () => {
      getTableData();
      eventTreeRef.value.scrollToCurrNode();
    };

    // 获取文件夹
    const eventFolderList = (0, _vue.ref)([]);
    const getEventFoldersApi = async () => {
      if (!currentEvent.value.id) {
        eventFolderList.value = [];
        return;
      }
      const res = await (0, _keyEventManagement.getEventFolders)({
        workspace_id: workspaceId.value,
        folder_id: currentEvent.value.id
      });
      if (res.code === 20000) {
        eventFolderList.value = res.results.folders;
      }
    };
    const eventFiltersRef = (0, _vue.ref)(null);
    const handleTreeNodeClick = () => {
      eventFiltersRef.value.clearFilters();
      tableFilterParams.value.name = '';
      tableFilterParams.value.filter = '';
      getEventFoldersApi();
      getTableData();
    };
    const handleFolderClick = val => {
      currentEvent.value = val;
      getEventFoldersApi();
      getTableData();
    };

    /** 表格操作 */
    const currentRow = (0, _vue.ref)({});
    const batchEventsList = (0, _vue.ref)([]);
    const handleSelectionChange = tableSelectionList => {
      batchEventsList.value = tableSelectionList;
    };
    // 配置模型
    const {
      setModelVisible,
      onSetModel
    } = (0, _tableOperations.useSetModel)(currentRow);
    // 移动
    const {
      moveEventVisible,
      onMoveEvent
    } = (0, _tableOperations.useMoveEvent)(currentRow);
    // 删除
    const {
      onDeleteEvent
    } = (0, _tableOperations.useDeleteEvent)(workspaceId, getTableData);
    // 设置应用范围
    const {
      eventIds,
      isBatch,
      hasEnableKeyEvent,
      isSystemEvent,
      hasSetModel,
      setKeyEventApplyPermissionVisible,
      onSetKeyEventApplyPermission,
      batchSetKeyEventApplyPermission
    } = (0, _tableOperations.useSetKeyEventApplyPermission)();

    // 创建文件夹

    const {
      createKeyEventFolderDialogVisible,
      openCreateKeyEventFolderDialog,
      createFolderPid
    } = (0, _tableOperations.useCreateKeyEventFolder)(currentEvent);

    /** 跳转 */
    const createRuleModelEvent = data => {
      resetModelInfo();
      createKeyEventDialogVisible.value = true;
      currentRow.value = {
        id: data.id
      };
    };
    const createEventPid = (0, _vue.ref)(0);
    const onCreateKeyEvent = data => {
      resetModelInfo();
      currentRow.value = {};
      createKeyEventDialogVisible.value = true;
      createEventPid.value = data.id;
    };
    const createKeyEventDialogVisible = (0, _vue.ref)(false);
    function openCreateKeyEventDialog() {
      createKeyEventDialogVisible.value = true;
      currentRow.value = {};
      createEventPid.value = 0;
    }
    function editEvent(row) {
      createKeyEventDialogVisible.value = true;
      currentRow.value = row;
    }
    const handleCommand = command => {
      switch (command) {
        case 'createFolder':
          openCreateKeyEventFolderDialog(currentEvent.value.id);
          break;
        case 'createKeyEvent':
          openCreateKeyEventDialog();
          break;
      }
    };
    const submitCreateKeyEventLoading = (0, _vue.ref)(false);
    async function submitCreateKeyEvent(type, form) {
      let data = {};
      let requestApi = null;
      if (type === 'create') {
        data = {
          ...form,
          workspace_id: workspaceId.value,
          pid: createEventPid.value || currentEvent.value.id || ''
        };
        requestApi = _keyEventManagement.createKeyEvent;
      } else if (type === 'edit') {
        data = {
          ...form,
          workspace_id: workspaceId.value,
          event_id: currentRow.value.id
        };
        requestApi = _keyEventManagement.updateKeyEvent;
      }
      submitCreateKeyEventLoading.value = true;
      const res = await requestApi(data).finally(() => {
        submitCreateKeyEventLoading.value = false;
      });
      if (res.code === 20000) {
        (0, _elementUi.Message)({
          message: `${type === 'edit' ? '编辑' : '新建'}关键事件成功`,
          type: 'success'
        });
        createKeyEventDialogVisible.value = false;
        if (type === 'create') {
          await getAllKeyEventList();
          currentEvent.value = {
            id: 'c' + res.results.id,
            name: form.name,
            is_leaf: 1
          };
        }
        refreshTableData();
      }
    }
    return {
      __sfc: true,
      store,
      router,
      route,
      workspaceId,
      Initial_Event,
      urlEvent,
      currentEvent,
      eventTree,
      eventTreeLoading,
      modelInfo,
      setModelInfo,
      resetModelInfo,
      eventTreeRef,
      createFolder,
      onCreateFolderSuccess,
      initEventAndTable,
      getAllKeyEventList,
      keyEventManagementHeaderRef,
      refreshTaskProgress,
      originTableData,
      unsetModelCount,
      unsetModelTagClosable,
      handleTagClick,
      tableFilterParams,
      tableLoading,
      handleTableFilterChange,
      tableData,
      getAllTableData,
      getTableData,
      refreshTableData,
      eventFolderList,
      getEventFoldersApi,
      eventFiltersRef,
      handleTreeNodeClick,
      handleFolderClick,
      currentRow,
      batchEventsList,
      handleSelectionChange,
      setModelVisible,
      onSetModel,
      moveEventVisible,
      onMoveEvent,
      onDeleteEvent,
      eventIds,
      isBatch,
      hasEnableKeyEvent,
      isSystemEvent,
      hasSetModel,
      setKeyEventApplyPermissionVisible,
      onSetKeyEventApplyPermission,
      batchSetKeyEventApplyPermission,
      createKeyEventFolderDialogVisible,
      openCreateKeyEventFolderDialog,
      createFolderPid,
      createRuleModelEvent,
      createEventPid,
      onCreateKeyEvent,
      createKeyEventDialogVisible,
      openCreateKeyEventDialog,
      editEvent,
      handleCommand,
      submitCreateKeyEventLoading,
      submitCreateKeyEvent,
      MegaTranGuide: _MegaTranGuide.default,
      KeyEventManagementHeader: _KeyEventManagementHeader.default,
      EventTree: _EventTree.default,
      EventTableFilter: _EventTableFilter.default,
      EventTable: _EventTable.default,
      MoveEventDialog: _MoveEventDialog.default,
      SetKeyEventApplyPermission: _SetKeyEventApplyPermission.default,
      SetModelDialog: _SetModelDialog.default,
      CreateKeyEventDialog: _CreateKeyEventDialog.default,
      CreateKeyEventFolderDialog: _CreateKeyEventFolderDialog.default,
      KeyEventFolderListView: _KeyEventFolderListView.default
    };
  }
};
exports.default = _default;