"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _BusinessPathMixin = _interopRequireDefault(require("./BusinessPathMixin"));
var _DealListDrawer = _interopRequireDefault(require("./DealListDrawer.vue"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamTable',
  components: {
    DealListDrawer: _DealListDrawer.default
  },
  mixins: [_BusinessPathMixin.default],
  props: {
    nodePathParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dealDrawerVisible: false,
      popoverContent: '',
      popoverLoading: false,
      realNodePath: ''
    };
  },
  methods: {
    async loadChilden(tree, treeNode, resolve) {
      const params = {
        node_path: tree.node_path,
        ...this.nodePathParams
      };
      // 获取真实的有顺序的业务路径
      const res = await (0, _businessIntelligence.getOrderedPath)(params);
      if (res.code === 200) {
        const childrenData = res.results.data.splice(0, 5);
        resolve(childrenData);
      }
    },
    // 打开商机列表抽屉
    openDealDrawer(path) {
      this.dealDrawerVisible = true;
      this.realNodePath = path;
    },
    closeDealDrawer() {
      this.dealDrawerVisible = false;
    },
    handleMouseenter(row, index) {
      if (!row.percent) return;
      this.popoverLoading = true;
      this.popoverContent = [];
      this.getPopoverContent(row, index);
    },
    // 有序路径下钻展示子节点占比
    getPopoverContent: (0, _commonFunc.debounce)(async function (row, index) {
      const rel_node_path = row.rel_node_path;
      const node_id = row.path[index].node_id;
      const params = {
        ...this.nodePathParams,
        rel_node_path,
        node_id
      };
      try {
        const res = await (0, _businessIntelligence.getRelPathChildPercent)(params);
        if (res.code === 200) {
          this.popoverContent = res.results.data;
        }
      } finally {
        this.popoverLoading = false;
      }
    }, 600)
  }
};
exports.default = _default;