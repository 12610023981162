"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KeyEventDataScope = _interopRequireDefault(require("@/components/KeyEventDataScope"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MultipleCascaderSelect',
  components: {
    KeyEventDataScope: _KeyEventDataScope.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    defaultProps: {
      type: Object,
      default: () => ({})
    },
    cascaderOptions: {
      type: Array,
      default: () => []
    },
    isAccountLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logicBtnList: [{
        type: 'and',
        label: '且'
      }, {
        type: 'or',
        label: '或'
      }],
      cascaderIdx: 0,
      cascaderWidth: 130
    };
  },
  computed: {
    cascaderplaceholder() {
      return this.isAccountLabel ? '请选择客户标签' : '请选择关键事件';
    },
    listLength() {
      var _this$filters$keyEven;
      return (_this$filters$keyEven = this.filters.keyEventCascaderList) === null || _this$filters$keyEven === void 0 ? void 0 : _this$filters$keyEven.length;
    }
  },
  watch: {
    listLength(val) {
      // eslint-disable-next-line vue/no-mutating-props
      if (val === 1) this.filters.event_engine_relation = 'and';
    },
    cascaderOptions: {
      handler: function () {
        this.cascaderIdx++;
      },
      deep: true
    },
    disabled: {
      handler: function (val) {
        if (val) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters.event_engine_relation = 'or';
          this.$emit('change', this.filters);
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    initMinWidth(val, index) {
      if (val) {
        this.cascaderWidth = 130;
        this.$nextTick(() => {
          this.$refs['key-event-cascader'][index].popperElm.style.minWidth = this.cascaderWidth + 'px';
          this.$refs['key-event-cascader'][index].updatePopper();
        });
      }
    },
    expandChange(item, index) {
      this.$nextTick(() => {
        const clientWidth = this.$refs['key-event-cascader'][index].popperElm.clientWidth;
        if (this.cascaderWidth < clientWidth) {
          this.cascaderWidth = clientWidth;
        }
        this.$nextTick(() => {
          this.$refs['key-event-cascader'][index].popperElm.style.minWidth = this.cascaderWidth + 'px';
          this.$refs['key-event-cascader'][index].updatePopper();
        });
      });
    },
    cascaderSelectChange(index) {
      setTimeout(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.$set(this.filters.keyEventCascaderList[index], 'keyEventLabel', this.$refs['key-event-cascader'][index].$refs['input'].value);
        this.$emit('change', this.filters);
      }, 0);
    },
    handleCheckboxChange(item) {
      var _item$keyEventName;
      if ((_item$keyEventName = item.keyEventName) !== null && _item$keyEventName !== void 0 && _item$keyEventName.length) this.$emit('change', this.filters);
    },
    // 添加关键是事件跟踪器
    addKeyEventCascader() {
      const cascaderItem = this.isAccountLabel ? {
        keyEventName: []
      } : {
        keyEventName: [],
        inverse: false
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventCascaderList.push(cascaderItem);
      this.$emit('addKeyEventCascader');
    },
    // 删除当前的关键事件跟踪器
    deleteCurrentKeyEventCascader(index) {
      const {
        keyEventName
      } = this.filters.keyEventCascaderList[index];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventCascaderList.splice(index, 1);
      if (keyEventName.length) this.$emit('change', this.filters);
    },
    handleActiveLogicBtn(val) {
      return this.filters.event_engine_relation === val;
    },
    selectLogicBtn(val) {
      if (this.disabled && val === 'and') return;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.event_engine_relation = val;
      this.$emit('change', this.filters);
    }
  }
};
exports.default = _default;