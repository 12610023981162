"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversition-member-info"
  }, _vm._l(_vm.memberInfo.participants, function (_ref, index) {
    let {
      avatar,
      name
    } = _ref;
    return _c("div", {
      key: index,
      staticClass: "member-info-item"
    }, [index === 0 || index === 1 ? [_c("image-avatar", {
      attrs: {
        name: name,
        avatar: avatar
      }
    }), _c("div", {
      staticClass: "right-member-info"
    }, [_c("div", {
      staticClass: "member-info-name"
    }, [_vm._v(_vm._s(name))]), _c("div", {
      staticClass: "other-member-info"
    }, [!index ? _c("span", [_vm._v(_vm._s("销售代表") + " / " + _vm._s(_vm.orgName))]) : _c("span", [_vm._v(_vm._s("联系人") + " / " + _vm._s(_vm.memberInfo.company_name || "未知公司"))])])])] : _vm._e()], 2);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;