"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "filter-tab"
  }, _vm._l(_vm.mergedList, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "tab-item",
      class: {
        "tab-actve-item": index === _vm.activeIndex
      },
      on: {
        click: function ($event) {
          return _vm.tabClick(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;