"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TimeRange = _interopRequireDefault(require("@/components/TimeRange"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    timeRangeCallback: {
      type: Array,
      default: () => []
    },
    isCalendarFilter: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const options = [{
      value: [1, -1],
      label: '1秒以上'
    }, {
      value: [0, 60],
      label: '1分钟内'
    }, {
      value: [60, -1],
      label: '1分钟以上'
    }, {
      value: [300, -1],
      label: '5分钟以上'
    }];
    const currentSelect = (0, _vue.ref)('');
    const customTimeRange = (0, _vue.ref)([]);
    const handleDateChange = val => {
      // 清空自定义时间范围
      customTimeRange.value = [];
      if (val === '') {
        emit('change', props.isCalendarFilter ? [] : {
          space_conv_total_duration: []
        });
        return;
      }
      const duration = options.find(item => item.label === val).value;
      emit('change', props.isCalendarFilter ? duration : {
        space_conv_total_duration: duration
      });
    };

    // 选择自定义时间范围
    const timeRangeSelect = (0, _vue.ref)(null);
    const customTimeChange = time => {
      console.log(time, 'customTimeChange');
      timeRangeSelect.value.blur();
      emit('change', props.isCalendarFilter ? time : {
        space_conv_total_duration: time
      });
    };

    // 自定义时间范围 label
    const timeLabelChange = label => {
      currentSelect.value = label;
    };

    // 时间范围回填
    const {
      timeRangeCallback
    } = (0, _vue.toRefs)(props);
    (0, _vue.watch)(timeRangeCallback, val => {
      if (!val || !val.length) {
        currentSelect.value = '';
        customTimeRange.value = [];
        return;
      }
      const option = options.find(item => JSON.stringify(item.value) === JSON.stringify(val));
      if (option) {
        currentSelect.value = option.label;
      } else {
        customTimeRange.value = val;
      }
    }, {
      immediate: true
    });
    const handleVisibleChange = val => {
      if (val && props.isCalendarFilter) document.body.click();
    };
    return {
      __sfc: true,
      props,
      emit,
      options,
      currentSelect,
      customTimeRange,
      handleDateChange,
      timeRangeSelect,
      customTimeChange,
      timeLabelChange,
      timeRangeCallback,
      handleVisibleChange,
      TimeRange: _TimeRange.default
    };
  }
};
exports.default = _default;