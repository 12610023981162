"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-event"
  }, [_vm._l(_vm.keyEvent.slice(0, _vm.showNumber), function (item, index) {
    return _c("el-tooltip", {
      key: index,
      attrs: {
        content: item.name,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(_setup.getName(item)) + " ")])]);
  }), _vm.keyEvent.length > _vm.showNumber ? _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      width: "200",
      trigger: "hover",
      "visible-arrow": false,
      "popper-class": "show-more-key-event-popover"
    }
  }, [_c("el-button", {
    staticClass: "more-btn",
    attrs: {
      slot: "reference",
      type: "text"
    },
    slot: "reference"
  }, [_vm._v("+" + _vm._s(_vm.keyEvent.length - _vm.showNumber))]), _vm._l(_vm.keyEvent.slice(_vm.showNumber), function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;