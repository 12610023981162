"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'PromptExample',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    example: {
      type: Object,
      default: () => {}
    }
  },
  emits: 'setExamplePrompt',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const actives = (0, _vue.ref)([]);
    const exampleFormat = (0, _vue.computed)(() => {
      const html = Object.values(props.example).join('\n');
      const em = document.createElement('em');
      em.innerHTML = html;
      return em.innerText;
    });
    const onClick = () => {
      emit('setExamplePrompt');
    };
    return {
      __sfc: true,
      props,
      actives,
      exampleFormat,
      emit,
      onClick
    };
  }
};
exports.default = _default;