"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _default = {
  __name: 'BackToTaskDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancel', 'abandon', 'stash', 'update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = v => {
      emit('update:visible', v);
    };
    const cancel = () => {
      updateVisible(false);
    };
    const abandon = () => {
      emit('abandon');
    };
    const stash = () => {
      emit('stash');
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      cancel,
      abandon,
      stash,
      t: _i18n.t
    };
  }
};
exports.default = _default;