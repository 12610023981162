"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchTransferConversation = batchTransferConversation;
exports.createWorkspace = createWorkspace;
exports.deleteWorkspace = deleteWorkspace;
exports.getAllWorkspace = getAllWorkspace;
exports.getDepartmentAndWorkspaceMappingList = getDepartmentAndWorkspaceMappingList;
exports.getLatestWorkspaceInfo = getLatestWorkspaceInfo;
exports.getSwitchWorkspaceList = getSwitchWorkspaceList;
exports.getTransferWorkspace = getTransferWorkspace;
exports.getWorkspaceConvCount = getWorkspaceConvCount;
exports.getWorkspaceList = getWorkspaceList;
exports.judgeWorkspaceExist = judgeWorkspaceExist;
exports.setDepartmentAndWorkspaceMapping = setDepartmentAndWorkspaceMapping;
exports.switchWorkspace = switchWorkspace;
exports.updateWorkspace = updateWorkspace;
var _request = require("@/utils/request");
// 更新空间
function updateWorkspace(data) {
  return (0, _request.webService)({
    url: '/organization/workspace/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新建空间
function createWorkspace(data) {
  return (0, _request.webService)({
    url: '/organization/workspace/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取空间列表
function getWorkspaceList(params) {
  return (0, _request.webService)({
    url: '/organization/workspace/list',
    method: 'get',
    params
  });
}

// 删除空间
function deleteWorkspace(data) {
  return (0, _request.webService)({
    url: '/organization/workspace/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取切换空间列表
function getSwitchWorkspaceList() {
  return (0, _request.webService)({
    url: '/organization/workspace/change_list',
    method: 'get'
  });
}

// 切换空间
function switchWorkspace(data) {
  return (0, _request.webService)({
    url: '/organization/workspace/change',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取最近一次切换的空间信息
function getLatestWorkspaceInfo() {
  return (0, _request.webService)({
    url: '/organization/workspace/latest',
    method: 'get'
  });
}

// 判断空间是否存在
function judgeWorkspaceExist(id) {
  return (0, _request.webService)({
    url: '/organization/workspace/exists',
    method: 'get',
    params: {
      id
    }
  });
}

// 获得该空间下的会话数量
function getWorkspaceConvCount(id) {
  return (0, _request.webService)({
    url: '/organization/workspace/conv/count',
    method: 'get',
    params: {
      id
    }
  });
}

// 删除空间前批量转移空间所有会话
function batchTransferConversation(data) {
  return (0, _request.request)({
    url: '/organization/workspace/conv/transfer/batch',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获得团队下所有空间
function getAllWorkspace() {
  return (0, _request.webService)({
    url: '/organization/workspace/all',
    method: 'get'
  });
}

// 获得可转移空间列表
function getTransferWorkspace() {
  return (0, _request.webService)({
    url: '/conversation/workspace/list',
    method: 'get'
  });
}

// 获取部门、空间映射list
function getDepartmentAndWorkspaceMappingList() {
  return (0, _request.webService)({
    url: '/organization/workspace/tree_list',
    method: 'get'
  });
}

// 配置空间与部门关系
function setDepartmentAndWorkspaceMapping(data) {
  return (0, _request.webService)({
    url: '/organization/workspace/set_trees',
    method: 'post',
    dataType: 'json',
    data
  });
}