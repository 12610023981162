"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "course-info",
    class: {
      "full-screen": !_vm.scriptVisible
    }
  }, [_c("div", {
    ref: "courseForm",
    staticClass: "course-form"
  }, [_c("el-form", {
    ref: "form",
    staticClass: "task-form",
    attrs: {
      model: _vm.courseInfoConfig,
      "label-position": "top",
      "hide-required-asterisk": ""
    }
  }, [_c("div", {
    staticClass: "info-card"
  }, [_c("div", {
    staticClass: "card-title"
  }, [_vm._v("基础信息")]), _c("el-form-item", {
    attrs: {
      prop: "name"
    }
  }, [_c("div", {
    staticClass: "course-name content-title"
  }, [_vm._v("课程名称")]), _c("el-input", {
    class: {
      "error-input": !_vm.courseInfoConfig.name && _vm.isCheckingKnowledgeScript
    },
    attrs: {
      placeholder: "最多输入50字",
      maxlength: "50"
    },
    model: {
      value: _vm.courseInfoConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.courseInfoConfig, "name", $$v);
      },
      expression: "courseInfoConfig.name"
    }
  }), !_vm.courseInfoConfig.name && _vm.isCheckingKnowledgeScript ? _c("div", {
    staticClass: "content-tip"
  }, [_vm._v(" 请填写课程名称 ")]) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "info-card"
  }, [_c("div", {
    staticClass: "card-title content-title"
  }, [_vm._v("课程内容")]), _c("LearnContent", {
    ref: "learnContent"
  }), _c("el-button", {
    staticClass: "add-button",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addScript
    }
  }, [_vm._v(" 添加知识点话术 ")]), !_vm.checkedKnowledgeScript.length && _vm.isCheckingKnowledgeScript ? _c("div", {
    staticClass: "content-tip"
  }, [_vm._v(" 请添加话术 ")]) : _vm._e()], 1), _c("div", {
    ref: "courseRule",
    staticClass: "info-card"
  }, [_c("div", {
    staticClass: "card-title"
  }, [_vm._v("课程规则")]), _c("el-form-item", {
    attrs: {
      label: `${_vm.practiceRule}要求`
    }
  }, [_c("div", {
    staticClass: "read-rule"
  }, [_c("span", [_vm._v("每段话术需" + _vm._s(_vm.practiceRule))]), _c("span", {
    staticClass: "input-container"
  }, [_c("el-input-number", {
    attrs: {
      min: 1,
      max: 100,
      controls: false
    },
    model: {
      value: _vm.courseInfoConfig.practice_rule.repeat_count,
      callback: function ($$v) {
        _vm.$set(_vm.courseInfoConfig.practice_rule, "repeat_count", $$v);
      },
      expression: "courseInfoConfig.practice_rule.repeat_count"
    }
  }), _c("i", [_vm._v("次")])], 1), _c("span", [_vm._v("且每次评分不低于")]), _c("span", {
    staticClass: "input-container"
  }, [_c("el-input-number", {
    attrs: {
      min: 0,
      max: 100,
      controls: false
    },
    model: {
      value: _vm.courseInfoConfig.practice_rule.score,
      callback: function ($$v) {
        _vm.$set(_vm.courseInfoConfig.practice_rule, "score", $$v);
      },
      expression: "courseInfoConfig.practice_rule.score"
    }
  }), _c("i", [_vm._v("分")])], 1), _c("span", [_vm._v("（满分：100）")])])]), _vm.type == 2 ? _c("div", {
    staticClass: "close-book-exam-container"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("闭卷考核")]), _c("p", {
    staticClass: "description"
  }, [_vm._v(" 开启后，销售练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文 ")]), _c("el-switch", {
    model: {
      value: _vm.courseInfoConfig.practice_rule.is_closed_exam,
      callback: function ($$v) {
        _vm.$set(_vm.courseInfoConfig.practice_rule, "is_closed_exam", $$v);
      },
      expression: "courseInfoConfig.practice_rule.is_closed_exam"
    }
  })], 1) : _vm._e()], 1)])], 1), _c("div", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "operate-btns"
  }, [_c("el-button", {
    staticClass: "edit-btn",
    on: {
      click: _vm.cancelEdit
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.confirmLoading
    },
    on: {
      click: _vm.saveCourse
    }
  }, [_vm._v("保存")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;