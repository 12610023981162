"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageTextTips = _interopRequireDefault(require("@/components/ImageTextTips.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SeatTable',
  components: {
    ImageTextTips: _ImageTextTips.default
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isChangeBind: false,
      bindVisible: false,
      changeBindVisible: false
    };
  },
  methods: {
    // 高亮
    tableRowClassName(_ref) {
      let {
        row
      } = _ref;
      if (row.highlight === true) {
        return 'highlight-row';
      }
      return '';
    },
    // 解绑或者绑定
    handleBindClick(row) {
      this.$emit('bindOrUnbind', row);
    },
    // 修改绑定关系
    handleChangeBindClick(row) {
      this.$emit('changeBind', row);
    }
  }
};
exports.default = _default;