"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BookMeeting = _interopRequireDefault(require("@/components/BookMeeting"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import MeetingButton from '@/components/MeetingButton';
// import MeetingDialog from '@/components/MeetingDialog';
var _default = {
  name: 'AppointPart',
  components: {
    // MeetingButton,
    // MeetingDialog,
    BookMeeting: _BookMeeting.default
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    appointMeeting(type) {
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;