"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = _interopRequireDefault(require("@/assets/default.svg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ImageAvatar',
  props: {
    name: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    shape: {
      type: String,
      default: 'circle',
      validator: val => ['circle', 'square'].includes(val)
    },
    sizeValue: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      // url: require("../../assets/default.svg"),
      defaultPhoto: _default2.default
    };
  },
  computed: {
    handleAvatar() {
      return function (name) {
        name = name.trim();
        if (name === '' && this.type === 'customer_contact') name = '客户';
        if (this.isChiStr(name)) {
          return name.substring(0, 1);
        } else {
          return name.substring(0, 1);
        }
      };
    },
    isChiStr() {
      return function (str) {
        const pattern = new RegExp('[\u4E00-\u9FA5]+');
        if (pattern.test(str)) {
          return true;
        } else {
          return false;
        }
      };
    }
  },
  methods: {}
};
exports.default = _default;