"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "hint-content-ai-customer-process"
  }, [_c("el-collapse", {
    model: {
      value: _setup.activeNames,
      callback: function ($$v) {
        _setup.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.hints, function (item) {
    return _c("el-collapse-item", {
      key: item.id,
      attrs: {
        name: item.id
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c("MgvButton", {
            staticClass: "custom-icon-carat-right",
            attrs: {
              size: "tiny",
              type: "text",
              color: "info transparent",
              icon: "icon-caret-right"
            }
          }), _c("h1", {
            staticClass: "title-text"
          }, [_vm._v(_vm._s(item.content))])];
        },
        proxy: true
      }], null, true)
    }, [_c("div", {
      staticClass: "content-text"
    }, _vm._l(item.replies, function (reply, index) {
      return _c("p", {
        key: index
      }, [item.replies.length > 1 ? _c("span", [_vm._v("(" + _vm._s(index + 1) + ") ")]) : _vm._e(), _vm._v(_vm._s(reply) + " ")]);
    }), 0)]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;