"use strict";

require("babel-polyfill");
var _es6Promise = _interopRequireDefault(require("es6-promise"));
var _vue = _interopRequireDefault(require("vue"));
var _errorLog = _interopRequireWildcard(require("@/utils/error-log"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireWildcard(require("element-ui"));
var _index = _interopRequireDefault(require("@/mgv-ui/packages/index"));
require("@/styles/element-variables.scss");
require("element-ui/lib/theme-chalk/icon.css");
var _i18n = require("@/i18n");
require("@/styles/index.scss");
require("@/styles/mobile-reset.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
require("@/iconfont/iconfont.css");
require("@/iconfont/iconfont.js");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _Cascader = _interopRequireDefault(require("@/components/EnhanceElementComponents/Cascader.vue"));
var _Popover = _interopRequireDefault(require("@/components/EnhanceElementComponents/Popover.vue"));
var _Image = _interopRequireDefault(require("@/components/EnhanceElementComponents/Image.vue"));
var _moment = _interopRequireDefault(require("moment"));
var _timezone = require("@/utils/timezone");
var _debounce = _interopRequireDefault(require("lodash/debounce"));
require("./utils/custom_directive");
require("./utils/btn-permission");
require("./utils/prevent-re-click");
require("./utils/overrideWindowOpenMethod");
var _message = require("./utils/message");
var _config = require("@/utils/config");
var _dialog = _interopRequireDefault(require("@/components/Local/dialog"));
var _tree = _interopRequireDefault(require("@/components/Local/tree"));
var _select = _interopRequireDefault(require("@/components/Local/select"));
var _mgvSelect = _interopRequireDefault(require("@/components/Local/mgvSelect"));
var _rsa = _interopRequireDefault(require("@/utils/rsa.js"));
require("@/utils/error-handlers");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
require('es6-promise').polyfill();
_es6Promise.default.polyfill();

// 收集报错

_vue.default.use(_errorLog.default);
window.onerror = _errorLog.globalErrorHandler;

// A modern alternative to CSS resets

// global css

// icon
// permission control
// iconfont

// 复制到粘贴板插件

// VueClipboard.config.autoSetContainer = true
_vue.default.use(_vueClipboard.default);

// 修复element组件bug

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const fixElTableErr = table => {
  const oldResizeListener = table.methods.resizeListener;
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};
fixElTableErr(_elementUi.Table);

// moment模块
// 导入模块

_moment.default.locale('zh-cn'); // 设置语言 或 moment.lang('zh-cn');
_vue.default.prototype.$moment = _moment.default; // 赋值使用
_vue.default.prototype.$plus8ToCurrentZone = _timezone.plus8ToCurrentZone; // 东八区转指定时区
_vue.default.prototype.$currentZoneToPlus8 = _timezone.currentZoneToPlus8;

// 防抖

/**
 * @param {Function} func 需要防抖的函数
 * @param {Number} wait 防抖的时间
 * @param {Object} options 配置项
 * @param {Boolean} options.leading 是否在第一次触发时立即执行
 * @param {Boolean} options.trailing 是否在最后一次触发时执行
 * @param {Boolean} options.maxWait 最大等待时间
 */
_vue.default.prototype.$debounce = _debounce.default;

// 全局引用自定义指令

// 全局引用判断是否有按钮的权限

// 重写全局window.open方法

// 常用的config

_vue.default.prototype.$config = _config.config;
_vue.default.use(_elementUi.default, {
  i18n: (k, v) => _i18n.i18n.t(k, v)
});
_vue.default.use(_index.default);
_vue.default.use(require('vue-shortkey'), {
  prevent: ['.el-input__inner']
});
_vue.default.component('ElCascader', _Cascader.default);
_vue.default.component('ElPopover', _Popover.default);
_vue.default.component('ElImage', _Image.default);
_vue.default.prototype.$message = _message.message;

// 重写element dialog 解决模态框非正常关闭问题
// 重写tree
// 增加select中tag可以加icon

_vue.default.use(_dialog.default);
_vue.default.use(_tree.default);
_vue.default.use(_select.default);
_vue.default.use(_mgvSelect.default);

// NOTE  使用window.log保留部分特定log信息
// 其余log用terser-webpack-plugin删除
window.log = console.log;

// RSA加密

_vue.default.prototype.Rsa = _rsa.default;

// 重写全局resizeObserver事件

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  beforeCreate() {
    _vue.default.prototype.$bus = this;
  },
  router: _router.default,
  store: _store.default,
  i18n: _i18n.i18n,
  render: h => h(_App.default)
});