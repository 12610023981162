"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _default = {
  __name: 'ScoreRangeSelect',
  props: {
    maxLimit: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change', 'input'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const options = [{
      value: [0, 60],
      label: (0, _i18n.t)('coaching.below60Points')
    }, {
      value: [60, props.maxLimit],
      label: (0, _i18n.t)('coaching.above60Points')
    }, {
      value: [0, 0],
      label: '0'
    }];
    const currentSelect = (0, _vue.ref)('');
    const customChange = () => {
      const val = currentSelect.value;
      if (!val) {
        emit('change', []);
        return;
      }
      emit('change', Array.isArray(val) ? val : val.split('-').map(item => parseFloat(item)));
    };
    const selectChange = () => {
      scoreRange.value = [];
      customChange();
    };
    const scoreRange = (0, _vue.ref)([0, 100]);
    const scoreRangeStr = (0, _vue.computed)(() => {
      return scoreRange.value.join('-');
    });
    function scoreRangeChange(val, index) {
      const score = parseFloat(val) > props.maxLimit ? props.maxLimit : parseFloat(val || index * 100);
      scoreRange.value.splice(index, 1, score);
      if (!scoreRange.value[0]) {
        scoreRange.value[0] = 0;
      }
      if (!scoreRange.value[1] && scoreRange.value[1] !== 0) {
        scoreRange.value[1] = props.maxLimit;
      }
      console.log(scoreRange.value.toString(), 'scoreRange.value.toString()');
      currentSelect.value = (0, _vue.unref)(scoreRangeStr);
      customChange();
    }
    (0, _vue.watch)(() => props.value, val => {
      if (options.some(item => item.value[0] === val[0] && item.value[1] === val[1])) {
        currentSelect.value = val;
      } else {
        scoreRange.value = val;
        currentSelect.value = (0, _vue.unref)(scoreRangeStr);
      }
    });
    return {
      __sfc: true,
      emit,
      props,
      options,
      currentSelect,
      customChange,
      selectChange,
      scoreRange,
      scoreRangeStr,
      scoreRangeChange,
      t: _i18n.t
    };
  }
};
exports.default = _default;