"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "root"
  }, [_c("el-popover", _vm._b({
    attrs: {
      placement: _vm.placement,
      width: "300",
      "popper-class": "filter-popover"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, "el-popover", _vm.$attrs, false), [_c("div", {
    staticClass: "filter-list"
  }, [_c("el-checkbox-group", {
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.checkList,
      callback: function ($$v) {
        _vm.checkList = $$v;
      },
      expression: "checkList"
    }
  }, _vm._l(_vm.customFilterObject, function (value, key, index) {
    return _c("div", {
      key: key + index
    }, [_c("div", {
      staticClass: "checkbox-group-title"
    }, [_vm._v(" " + _vm._s(key) + " ")]), _c("section", {
      staticClass: "deal-check-group"
    }, _vm._l(value, function (item, idx) {
      return _c("el-checkbox", {
        key: item.field_name + idx,
        staticClass: "checkbox",
        attrs: {
          value: item.field_name,
          label: item.field_name
        }
      }, [_vm._v(_vm._s(item.name))]);
    }), 1), index !== Object.keys(_vm.customFilterObject).length - 1 ? _c("el-divider") : _vm._e()], 1);
  }), 0)], 1), _c("el-button", {
    class: ["more-button", "mg-button"],
    attrs: {
      slot: "reference",
      plain: ""
    },
    on: {
      click: _vm.handleClick
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "iconfont icon-filter"
  }), _c("span", [_vm._v("更多筛选")])])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;