"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("main", {
    staticClass: "ai-compliance-main"
  }, [_c("article", {
    staticClass: "banner-container"
  }, [_c("section", {
    staticClass: "banner-content rise-module"
  }, [_c("div", {
    staticClass: "banner-content-left"
  }, [_c("h1", {
    staticClass: "banner-content-title"
  }, [_vm._v("智能质检")]), _c("p", {
    staticClass: "banner-content-text"
  }, [_vm._v(" 基于ASR语音识别，NLP语义分析、情感模型、数据挖掘等技术，自动对海量会话进行检测。全面覆盖客服、销售、催收等业务场景，降低企业合规、管理的风险。 ")]), _c("button", {
    staticClass: "btn btn-medium try-btn",
    on: {
      click: _setup.showReservationForm
    }
  }, [_vm._v(" 申请试用 "), _c("img", {
    staticClass: "arrow",
    attrs: {
      src: require("@/assets/icons/right-arrow-white.svg")
    }
  })]), _c("p", {
    staticClass: "tip"
  }, [_vm._v(" 提交申请后 Megaview 工作人员将尽快与您联系并根据需求为您开通服务。 ")])]), _vm._m(0)])]), _c("article", {
    staticClass: "compliance-system-container rise-module"
  }, [_c("h1", {
    staticClass: "compliance-system-title"
  }, [_vm._v("便捷高效的质检系统")]), _c("div", {
    staticClass: "compliance-system-desc"
  }, [_vm._v("助力企业合规运营，提升管理效率")]), _c("div", {
    staticClass: "tabs pc"
  }, [_c("ul", {
    staticClass: "tabs-nav"
  }, _vm._l(_setup.tabsNavList, function (item, index) {
    return _c("li", {
      key: index,
      class: ["tabs-nav-item", {
        active: _setup.currentTabsNavIndex === index
      }],
      on: {
        click: function ($event) {
          _setup.currentTabsNavIndex = index;
        }
      }
    }, [_c("img", {
      attrs: {
        src: require(`@/assets/compliance/compliance-system-icon-${index + 1}${_setup.currentTabsNavIndex === index ? "-active" : ""}.png`),
        alt: ""
      }
    }), _c("span", {
      staticClass: "tab-nav-item__label"
    }, [_vm._v(_vm._s(item))])]);
  }), 0), _c("div", {
    staticClass: "tabs-content"
  }, [_c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 0
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 0
    }],
    attrs: {
      "animation-data": require("@/assets/json/compliance.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(1)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 1
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 1
    }],
    attrs: {
      "animation-data": require("@/assets/json/sop-compliance.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(2)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 2
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 2
    }],
    attrs: {
      "animation-data": require("@/assets/json/correlation-compliance.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(3)], 1), _c("div", {
    class: ["tabs-content-item", {
      active: _setup.currentTabsNavIndex === 3
    }]
  }, [_c(_setup.LottieAnimation, {
    class: ["svg-card", {
      "card-active": _setup.currentTabsNavIndex === 3
    }],
    attrs: {
      "animation-data": require("@/assets/json/compliance-analysis.json"),
      loop: true,
      "auto-play": true
    }
  }), _vm._m(4)], 1)])])]), _c("article", {
    staticClass: "own-advantage-container rise-module"
  }, [_c("h1", {
    staticClass: "own-advantage-title"
  }, [_vm._v("我们的优势")]), _c("section", {
    staticClass: "own-advantage-content rise-module pc"
  }, [_c("figure", {
    class: ["own-advantage-card", {
      "is-active": !_setup.ownAdvantageIndex
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 0;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("领先的技术实力")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(5), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/compliance/own-advantage-1${!_setup.ownAdvantageIndex ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 1
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 1;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("丰富的质检类型")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(6), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/compliance/own-advantage-2${_setup.ownAdvantageIndex === 1 ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 2
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 2;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("全面的质检覆盖")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(7), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/compliance/own-advantage-3${_setup.ownAdvantageIndex === 2 ? "-active" : ""}.png`),
      alt: ""
    }
  })])]), _c("figure", {
    class: ["own-advantage-card", {
      "is-active": _setup.ownAdvantageIndex === 3
    }],
    on: {
      mouseenter: function ($event) {
        _setup.ownAdvantageIndex = 3;
      }
    }
  }, [_c("div", {
    staticClass: "own-advantage-card-title"
  }, [_vm._v("丰富的应用实践")]), _c("div", {
    staticClass: "bottom-dash"
  }), _vm._m(8), _c("div", {
    staticClass: "own-advantage-card-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/compliance/own-advantage-4${_setup.ownAdvantageIndex === 3 ? "-active" : ""}.png`),
      alt: ""
    }
  })])])])]), _vm._m(9), _c("article", {
    staticClass: "oncall rise-module"
  }, [_c("div", {
    staticClass: "oncall-content"
  }, [_vm._m(10), _c("section", [_c("button", {
    staticClass: "btn btn-medium try-btn",
    on: {
      click: _setup.showReservationForm
    }
  }, [_vm._v(" 申请试用 "), _c("img", {
    staticClass: "arrow",
    attrs: {
      src: require("@/assets/icons/right-arrow-white.svg")
    }
  })])])])]), _c(_setup.GuidePageTrialDialog, {
    attrs: {
      "dialog-visible": _setup.guidePageTrialDialogVisible,
      source: "compliance"
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.guidePageTrialDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.guidePageTrialDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "banner-content-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/banner/ai-compliance-img.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("合规质检")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 合规质检是指对会话内容进行检测，确保员工在沟通过程中的合规性，降低企业经营风险。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 话术检测：检测员工是否按照标准礼貌用语、专业词汇及标准沟通技巧等进行服务 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 敏感词检测：检测沟通中是否存在辱骂、涉政、暴力、歧视等词语 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 专项检测：语速检测、关键事件检测、正负向情绪检测、必讲漏讲检测等 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("SOP流程质检")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" SOP流程质检是指对沟通过程进行检查和分析，确保符合公司标准流程和规范，提高工作效率和服务质量。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 流程规范检测：判断员工是否按照公司规定的流程执行，是否有遗漏项等 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 流程卡点检测：检测员工在流程执行中的薄弱环节，及时进行纠正和改进 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 流程效率检测：检测各流程执行次数、时间等，以评估流程的优化空间 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("关联质检")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 关联质检是指按不同维度将多通会话关联在一起，按组进行质检，全面评估服务质量。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v("客户关联质检：将同一客户的多通会话关联在一起进行检测")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 交易关联质检：以交易维度对该交易关联的会话进行整体检测 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 同客同销质检：将客户、销售相同的多通会话关联在一起进行检测 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "content-detail"
  }, [_c("h2", {
    staticClass: "content-detail-title"
  }, [_vm._v("质检分析")]), _c("div", {
    staticClass: "content-detail-desc"
  }, [_vm._v(" 通过对质检数据进行挖掘、统计和分析，发现企业服务中的问题，赋能销售、培训等业务。 ")]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 灵活的分析方法：统计分析、图表分析、对比分析、聚类分析等 ")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v("丰富的分析维度：合规分析、违规分析、业务卡点分析等")])]), _c("div", {
    staticClass: "content-detail-item"
  }, [_c("div", [_vm._v("✦")]), _c("div", [_vm._v(" 多样化的展示图表：柱状图、折线图、饼图、条形图、散点图等 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("自研ASR、NLP等AI模型")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("PB级数据处理")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("数十项核心AI专利")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("顶级研发团队")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("支持大规模并发")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("关键词质检")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("语义质检")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("情感质检")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("语速质检")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("话术质检")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("专项质检")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("语音检测")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("文本检测")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("视频检测")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("关联检测")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("综合检测")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "own-advantage-card-contents"
  }, [_c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("金融行业")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("教育培训")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("企业服务")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("保险证券")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("汽车销售")])]), _c("div", {
    staticClass: "own-advantage-card-content"
  }, [_c("span", {
    staticClass: "point"
  }), _c("span", [_vm._v("房地产")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("article", {
    staticClass: "feature-induction-container rise-module"
  }, [_c("h1", {
    staticClass: "feature-induction-title"
  }, [_vm._v(" 海量应用场景，全面覆盖企业质检需求 ")]), _c("section", {
    staticClass: "feature-induction-content rise-module"
  }, [_c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/销售.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("销售质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对电销员的行业话术规范、营销关键点、销售话术覆盖的完整度和准确性等内容检测，防止违规操作，提升销售效益。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/客户.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("客服质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对在线客服及电话客服的行业话术规范和合规性、服务态度、业务流程及标准等进行检测，发现客服问题提升服务质量。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/催收.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("催收质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对电话催收人员的话术规范及合规性、催收业务流程和投诉相关内容进行检测，优化催收话术和流程，提高催收效率。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/回访.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("回访质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对客户回访的话术规范、服务态度、问题解决能力等进行检测，提高客户满意度和忠诚度，发现并解决回访中存在的问题。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/营销.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("营销质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对营销人员的话术规范、销售技巧、客户需求分析等进行检测，提高销售效率和客户转化率，发现并解决营销中存在的问题。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])]), _c("figure", {
    staticClass: "content-item"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/compliance/路径.png")
    }
  }), _c("figcaption", {
    staticClass: "content-item-desc"
  }, [_c("h4", {
    staticClass: "content-item-desc-title"
  }, [_vm._v("线路质检")]), _c("p", {
    staticClass: "content-detail"
  }, [_vm._v(" 对线路上进行的语音话务是否符合规范进行检测，主要用于电诈识别、用语规范、业务匹配，可以有效降低业务风险。 ")]), _c("span", {
    staticClass: "bottom-dash"
  })])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("aside", {
    staticClass: "h2 oncall-title"
  }, [_c("h1", {
    staticClass: "h2"
  }, [_vm._v("提升质检效率，赋能销售团队!")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;