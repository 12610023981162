"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.drawerVisible,
      size: "600px",
      "before-close": () => _vm.$emit("update:drawerVisible", false)
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", [_c("span", [_vm._v("已忽略问题 (" + _vm._s(_vm.total) + ")")]), _c("el-tooltip", {
          attrs: {
            tabindex: -1,
            effect: "dark",
            placement: "bottom",
            content: "已忽略的问题将不会出现在问题列表中，也不参与出现频率和问题占比的计算。"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })])], 1)];
      },
      proxy: true
    }])
  }, [!_vm.ignoreQuestionListLoading && !_vm.ignoreQuestionList.length ? _c("NoData", {
    attrs: {
      tips: "暂无数据"
    }
  }) : _c("mg-infinite-scroll", {
    attrs: {
      loading: _vm.ignoreQuestionListLoading,
      nomore: _vm.ignoreQuestionList.length >= _vm.total,
      distance: 200,
      "nomore-text": "没有更多内容"
    },
    on: {
      loadMore: _vm.getIgnoreQuestionList
    }
  }, _vm._l(_vm.ignoreQuestionList, function (item, index) {
    return _c("knowledge-question", {
      key: index,
      attrs: {
        question: item,
        "is-ignore": true,
        "deal-all-count": _vm.dealAllCount
      }
    }, [_c("i", {
      staticClass: "iconfont icon-refresh-left",
      attrs: {
        slot: "hover-handler-button"
      },
      on: {
        click: function ($event) {
          return _vm.cancelIgnoreQuestion(item);
        }
      },
      slot: "hover-handler-button"
    }, [_vm._v(" 取消忽略")])]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;