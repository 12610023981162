"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-coaching-content"
  }, [_c("transition", {
    attrs: {
      name: "el-fade-in"
    }
  }, [_vm.isShowApply && !_vm.isNetworkError && !_vm.isTooLong && !_vm.requestLoading ? [_c("div", {
    staticClass: "apply-container"
  }, [_c("div", {
    staticClass: "background"
  }), _c("div", {
    staticClass: "apply-main-content"
  }, [_c("div", {
    staticClass: "iconfont icon-ai-assistant-fill"
  }), _c("div", {
    staticClass: "apply-tip"
  }, [_vm._v(" Hi～我是你的智能辅导老师，我可以对你的会话进行分析并提供指导建议。每个会话仅可辅导一次哦！ ")]), _c("div", {
    staticClass: "content-tip"
  }, [_vm._v(" 你可以补充一些会话背景和当前遇到的问题，以便为你提供更具针对性的辅导。 ")]), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6,
      maxlength: 200,
      autosize: {
        minRows: 6,
        maxRows: 6
      },
      "show-word-limit": "",
      placeholder: "示例1：帮我总结一下这通会话的优点和缺点以及应该如何改进。\n示例2：这是我与客户的第5次通话，一直未能签约，有没有什么比较好的促单技巧。"
    },
    model: {
      value: _vm.applyContent,
      callback: function ($$v) {
        _vm.applyContent = $$v;
      },
      expression: "applyContent"
    }
  }), _c("el-button", {
    staticClass: "apply-btn",
    attrs: {
      type: "primary",
      size: "mini",
      loading: _vm.requestLoading
    },
    on: {
      click: _vm.applyAICoaching
    }
  }, [_vm._v("申请辅导")])], 1)])] : _vm.requestLoading || _vm.AICoachingContent ? [_c("div", {
    ref: "contentContainer",
    staticClass: "content-container",
    class: _vm.conversationDetailInfo.isSeeker ? "has-bottom-tip" : ""
  }, [_c("div", {
    staticClass: "content-title"
  }, [_c("i", {
    staticClass: "iconfont icon-ai-assistant-fill"
  }), _c("span", {
    staticClass: "content-title-text"
  }, [_vm._v("智能辅导老师")])]), _vm.requestLoading ? _c("div", {
    staticClass: "loading-container"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", {
    staticClass: "dot dot-2"
  }), _c("div", {
    staticClass: "dot dot-3"
  })]) : _vm._e(), !_vm.requestLoading && _vm.AICoachingContent ? _c("PrintText", {
    attrs: {
      text: _vm.AICoachingContent,
      "no-print": _vm.isNoPrint,
      "is-stream-text": _vm.isStreamText,
      "stream-text-status": _vm.streamTextStatus
    }
  }) : _vm._e(), _vm.conversationDetailInfo.isSeeker ? _c("div", {
    staticClass: "seeker-bottom-tip"
  }, [_c("el-input", {
    attrs: {
      placeholder: "每个会话仅可辅导一次",
      disabled: ""
    }
  })], 1) : _vm._e()], 1)] : (_vm.isNetworkError || _vm.isTooLong || _vm.isUncoach) && !_vm.requestLoading ? [_vm.isNetworkError ? _c("NoData", {
    attrs: {
      icon: "icon-a-Property1no-wifi"
    }
  }, [_vm.isNetworkError ? _c("div", {
    staticClass: "error-tip",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_vm._v(" 网络错误，请"), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.applyAICoaching
    }
  }, [_vm._v("刷新重试")])], 1) : _vm._e()]) : _vm._e(), _vm.isTooLong ? _c("NoData", {
    attrs: {
      icon: "icon-a-Property1not-supported",
      tips: "当前会话时间过长，暂不支持辅导，换个时间短一些的会话试试吧~"
    }
  }) : _vm._e(), _vm.isUncoach ? _c("NoData", {
    attrs: {
      tips: "当前会话尚未进行辅导，只有会话归属人可以申请智能辅导哦！"
    }
  }) : _vm._e()] : _vm._e()], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;