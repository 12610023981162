"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PermissionsTree = _interopRequireDefault(require("@/components/PermissionsTree"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FuncRoleDrawer',
  components: {
    PermissionsTree: _PermissionsTree.default
  },
  props: {
    funcRoleDrawerVisible: {
      type: Boolean,
      default: false
    },
    roleInfo: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    roleList: {
      type: Array,
      default: () => []
    },
    roleIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const repeatNameValidator = (rule, value, callback) => {
      if (this.type !== 'edit') {
        if (this.roleList.some(item => item.name === value)) {
          callback(new Error('此名称与已有名称重复'));
        }
      } else {
        const roleList = this.roleList.filter(item => item.name !== this.roleInfo.name);
        if (roleList.some(item => item.name === value)) {
          callback(new Error('此名称与已有名称重复'));
        }
      }
      callback();
    };
    return {
      funcRoleForm: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('coaching.roleNamePlaceholder'),
          trigger: 'blur'
        }, {
          validator: repeatNameValidator,
          trigger: 'change'
        }]
      },
      pageCheckedList: [],
      funcCheckedList: [],
      pagePermissions: [],
      funcPermissions: [],
      buttonLoading: false,
      drawerLoading: false
    };
  },
  computed: {
    dialogTitle() {
      const title = {
        add: '新建业务角色',
        edit: '业务角色权限编辑',
        copy: '复制业务角色',
        preview: '权限预览'
      };
      return title[this.type];
    }
  },
  watch: {
    funcRoleDrawerVisible(val) {
      if (val) {
        this.pageCheckedList = [];
        this.funcCheckedList = [];
        this.getFuncRolePermissions();
        if (this.roleInfo && this.roleInfo.name) {
          this.funcRoleForm.name = this.roleInfo.name;
        } else {
          this.funcRoleForm.name = '';
        }
      }
    },
    pageCheckedList(val) {
      this.$nextTick(() => {
        this.handleFuncTreeNodeDisabled(this.funcPermissions, val);
        this.handleFuncSelfDisabled(this.funcPermissions);
        this.cancelParentCheck(this.funcPermissions);
        if (this.type === 'preview') {
          this.$nextTick(() => {
            this.disableAll(this.pagePermissions);
            this.disableAll(this.funcPermissions);
          });
        }
      });
    }
  },
  methods: {
    handlePageCheckChange(data, node) {
      this.$nextTick(() => {
        // 拿到当前节点
        const currentNode = this.$refs['pageTree'].$refs['PermissionsTree'].getNode(data);
        // 处理当前节点的父级节点
        this.handleParentChecked(currentNode);
        // 处理当前节点的孩子节点
        this.handleChildrenChecked(currentNode, currentNode.checked);
        // 获取所有选中的值
        this.pageCheckedList = this.$refs.pageTree.$refs.PermissionsTree.getCheckedKeys();
        // 通过选中的值禁用
        this.handleDisableNode(this.pagePermissions, this.pageCheckedList);
        // 设置选中的值
        this.$refs.pageTree.$refs.PermissionsTree.setCheckedKeys(this.pageCheckedList);
      });
    },
    handleFuncCheckChange(data) {
      this.$nextTick(() => {
        const currentNode = this.$refs['funcTree'].$refs['PermissionsTree'].getNode(data);
        // 处理当前节点的父级节点
        this.handleParentChecked(currentNode);
        // 处理当前节点的孩子节点
        this.handleChildrenChecked(currentNode, currentNode.checked);
        // 获取所有选中的值
        this.funcCheckedList = this.$refs.funcTree.$refs.PermissionsTree.getCheckedKeys();
      });
    },
    cancelParentCheck(tree) {
      tree.forEach(item => {
        if (item.children && item.children.length) {
          this.cancelParentCheck(item.children);
          this.$nextTick(() => {
            const isChildrenAllUncheck = item.children.every(children => !this.funcCheckedList.includes(children.id));
            if (isChildrenAllUncheck) {
              this.funcCheckedList.filter(ele => ele !== item.id);
              this.$refs.funcTree.$refs.PermissionsTree.setCheckedKeys(this.funcCheckedList);
            }
          });
        }
      });
    },
    handleDisableNode(tree, checkedList) {
      tree.forEach(item => {
        if (item.depend[0] === -1) {
          this.$set(item, 'disabled', false);
        } else {
          const res = item.depend.every(dependId => checkedList.includes(dependId));
          if (res) {
            this.$set(item, 'disabled', false);
          } else {
            if (checkedList.includes(item.id)) {
              this.$nextTick(() => {
                var _currentNode$parent;
                const refName = tree === this.pagePermissions ? 'pageTree' : 'funcTree';
                const currentNode = this.$refs[refName].$refs['PermissionsTree'].getNode(item.id);
                currentNode.checked = false;
                const siblingNodeState = !!((_currentNode$parent = currentNode.parent) !== null && _currentNode$parent !== void 0 && _currentNode$parent.childNodes.filter(item => item.checked).length);
                const parentIds = this.findParent(checkedList, item.id);
                if (siblingNodeState) {
                  checkedList = checkedList.filter(c => c !== item.id);
                } else {
                  checkedList = checkedList.filter(item1 => !parentIds.some(item2 => item1 === item2));
                }
                this.$refs[refName].$refs.PermissionsTree.setCheckedKeys(checkedList);
              });
            }
            this.$set(item, 'disabled', true);
          }
        }
        if (item.children && item.children.length) {
          this.handleDisableNode(item.children, checkedList);
        }
      });
    },
    handleParentChecked(node) {
      if (node && node.parent) {
        var _node$parent;
        // 当前节点兄弟节点的状态
        const siblingNodeState = !!((_node$parent = node.parent) !== null && _node$parent !== void 0 && _node$parent.childNodes.filter(item => item.checked).length);
        node.parent.checked = siblingNodeState;
        this.handleParentChecked(node.parent);
      }
    },
    handleChildrenChecked(node, state) {
      if (node.childNodes && node.childNodes.length) {
        for (const item of node.childNodes) {
          if (!item.disabled) {
            item.checked = state;
          }
          if (item.childNodes.length) {
            this.handleChildrenChecked(item, state);
          }
        }
      }
    },
    handleFuncTreeNodeDisabled(tree, checkedList) {
      // 和页面权限的关联
      tree.forEach(item => {
        if (item.depend_page[0] === -1) {
          this.$set(item, 'disabled', false);
        } else {
          const res = item.depend_page.every(dependId => checkedList.includes(dependId));
          if (res) {
            this.$set(item, 'disabled', false);
          } else {
            this.$set(item, 'disabled', true);
            this.funcCheckedList = this.funcCheckedList.filter(ele => ele !== item.id);
            this.$refs.funcTree.$refs.PermissionsTree.setCheckedKeys(this.funcCheckedList);
          }
        }
        if (item.children && item.children.length) {
          this.handleFuncTreeNodeDisabled(item.children, checkedList);
        }
      });
    },
    handleFuncSelfDisabled(tree) {
      tree.forEach(item => {
        if (item.children && item.children.length) {
          this.handleFuncSelfDisabled(item.children);
          this.$nextTick(() => {
            const childAllDisabled = item.children.every(children => children.disabled);
            if (childAllDisabled) {
              this.$set(item, 'disabled', true);
              this.funcCheckedList = this.funcCheckedList.filter(ele => ele !== item.id);
              this.$refs.funcTree.$refs.PermissionsTree.setCheckedKeys(this.funcCheckedList);
            }
          });
        }
      });
    },
    recursionCheckbox(tree, checkedList) {
      tree.forEach(item => {
        if (item.is_chosen) {
          checkedList.push(item.id);
        }
        if (item.children && item.children.length) {
          this.recursionCheckbox(item.children, checkedList);
        }
      });
    },
    findParent(tree, id) {
      const allParents = [];
      if (!tree.length) return;
      const findEle = (data, id) => {
        if (!id) return;
        data.forEach(item => {
          if (item.id == id) {
            allParents.unshift(item.id);
            findEle(tree, item.fid);
          } else {
            if (item.children) {
              findEle(item.children, id);
            }
          }
        });
      };
      findEle(tree, id);
      return allParents;
    },
    sortTree(tree) {
      tree.forEach(item => {
        if (item.children && item.children.length) {
          item.children.sort(this.compare('order'));
          this.sortTree(item.children);
        }
      });
    },
    compare(property) {
      return function (obj1, obj2) {
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2; // 升序
      };
    },

    async getFuncRolePermissions() {
      this.drawerLoading = true;
      try {
        var _this$roleInfo, _this$roleInfo2;
        const params = {
          edit: [(_this$roleInfo = this.roleInfo) === null || _this$roleInfo === void 0 ? void 0 : _this$roleInfo.id],
          copy: [(_this$roleInfo2 = this.roleInfo) === null || _this$roleInfo2 === void 0 ? void 0 : _this$roleInfo2.id],
          preview: this.roleIds
        };
        const res = await (0, _permissions.getFuncRolePermissions)({
          role_ids: JSON.stringify(params[this.type])
        });
        if (res.code === 200) {
          this.pagePermissions = res.results.page_list;
          this.funcPermissions = res.results.perm_list;
          this.initPermissionTree(this.pagePermissions, this.pageCheckedList);
          this.handleDisableNode(this.pagePermissions, this.pageCheckedList);
          this.initPermissionTree(this.funcPermissions, this.funcCheckedList);
          this.handleFuncTreeNodeDisabled(this.funcPermissions, this.pageCheckedList);
          this.handleFuncSelfDisabled(this.funcPermissions);
          this.cancelParentCheck(this.funcPermissions);
        }
      } finally {
        this.drawerLoading = false;
      }
    },
    initPermissionTree(permissions, checkedList) {
      if (permissions.length) {
        // 给根节点排序
        permissions.sort(this.compare('order'));
        // 给每个根节点下的孩子节点排序
        this.sortTree(permissions);
        this.recursionCheckbox(permissions, checkedList);
      }
    },
    disableAll(tree) {
      tree.forEach(item => {
        this.$set(item, 'disabled', true);
        if (item.children && item.children.length) {
          this.disableAll(item.children);
        }
      });
    },
    async save() {
      this.$refs.funcRoleForm.validate(async valid => {
        if (valid) {
          this.buttonLoading = true;
          if (this.type !== 'edit') {
            try {
              const res = await (0, _permissions.addFuncRole)({
                name: this.funcRoleForm.name,
                permissions: [...this.pageCheckedList, ...this.funcCheckedList]
              });
              if (res.code === 20000) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.$emit('fetchRoleList', res.results);
              }
            } finally {
              this.buttonLoading = false;
            }
          } else {
            try {
              const res = await (0, _permissions.editFuncRole)({
                role_id: this.roleInfo.id,
                name: this.funcRoleForm.name,
                permissions: [...this.pageCheckedList, ...this.funcCheckedList]
              });
              if (res.code === 200) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.$emit('fetchRoleList', this.roleInfo);
              }
            } finally {
              this.buttonLoading = false;
            }
          }
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.$emit('handleClose');
      if (this.type !== 'preview') {
        this.$refs.funcRoleForm.resetFields();
      }
    }
  }
};
exports.default = _default;