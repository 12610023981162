"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _TaskTable = _interopRequireDefault(require("./TaskTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _library = require("@/api/library");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SnippetStudyTask',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const taskTypes = [{
      label: '待学习',
      type: 0
    }, {
      label: (0, _i18n.t)('coaching.completed'),
      type: 1
    }];
    const listParams = (0, _vue.ref)({
      page: 1,
      size: 10,
      type: 0
    });
    const count = (0, _vue.ref)(0);
    const sizeChange = val => {
      listParams.value.page = 1;
      listParams.value.size = val;
      getStudyList();
    };
    const pageChange = val => {
      listParams.value.page = val;
      getStudyList();
    };

    // 切换学习的类型
    const changeType = type => {
      if (listParams.value.type === type) return;
      listParams.value.type = type;
      tableData.value = [];
      listParams.value.page = 1;
      getStudyList();
    };
    const tableData = (0, _vue.ref)([]);
    const getTableHeads = () => {
      const column = !listParams.value.type ? {
        prop: 'operate',
        label: '操作',
        width: 120
      } : {
        prop: 'study_time',
        label: '已学时长',
        width: 220
      };
      return [{
        prop: 'name',
        label: '学习内容'
      }, {
        prop: 'assigned_user.name',
        label: (0, _i18n.t)('coaching.assigner'),
        width: 220
      }, column];
    };

    // 获取学习列表
    const loading = (0, _vue.ref)(false);
    const getStudyList = async () => {
      loading.value = true;
      const res = await (0, _library.getLibraryStudyList)({
        ...listParams.value,
        workspace_id: store.getters.workspaceInfo.id
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        tableData.value = res.results.data;
        count.value = res.results.total_count;
      }
    };
    getStudyList();
    return {
      __sfc: true,
      store,
      taskTypes,
      listParams,
      count,
      sizeChange,
      pageChange,
      changeType,
      tableData,
      getTableHeads,
      loading,
      getStudyList,
      TaskTable: _TaskTable.default,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;