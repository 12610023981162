"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-basic-box"
  }, [_c("div", {
    staticClass: "rule-serial-number"
  }, [_vm._v(_vm._s(_vm.ruleGroupIndex + 1) + "A")]), _c("div", {
    staticClass: "rule-box-content"
  }, [_c("div", {
    staticClass: "rule-speak-item"
  }, [_c("div", {
    staticClass: "label-text"
  }, [_vm._v("发言角色")]), _c("div", {
    staticClass: "speak-role-row"
  }, [_c("el-select", {
    staticClass: "select-width",
    model: {
      value: _vm.ruleItem.apply_to,
      callback: function ($$v) {
        _vm.$set(_vm.ruleItem, "apply_to", $$v);
      },
      expression: "ruleItem.apply_to"
    }
  }, _vm._l(_vm.speakingRoleOption, function (role) {
    return _c("el-option", {
      key: role.value,
      attrs: {
        label: role.label,
        value: role.value
      }
    });
  }), 1), _vm.ruleGroup && _vm.ruleGroup.length > 1 ? _c("div", {
    staticClass: "delete-context-rule",
    on: {
      click: _vm.handleDeleteRuleGroupItem
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })]) : _vm._e()], 1)]), _c("div", {
    staticClass: "rule-basic-include"
  }, [_vm._l(_vm.ruleItem.include, function (includeItem, includeIndex) {
    return _c("div", {
      key: includeIndex,
      class: ["rule-include-item", {
        "no-first-rule-include-item": includeIndex !== 0
      }]
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: includeIndex === 0,
        expression: "includeIndex === 0"
      }],
      staticClass: "label-text"
    }, [_vm._v("包含")]), _c("el-tooltip", {
      attrs: {
        content: _vm.getItemTypeTooltip(includeItem.type),
        placement: "top",
        "open-delay": 500,
        enterable: false
      }
    }, [_c("el-select", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: includeIndex === 0,
        expression: "includeIndex === 0"
      }],
      staticClass: "select-width",
      on: {
        change: function ($event) {
          return _vm.handleIncludeTypeChange(_vm.ruleItem.include, includeItem);
        }
      },
      model: {
        value: includeItem.type,
        callback: function ($$v) {
          _vm.$set(includeItem, "type", $$v);
        },
        expression: "includeItem.type"
      }
    }, _vm._l(_vm.typeOptions, function (ruleType) {
      return _c("el-option", {
        key: ruleType.value,
        attrs: {
          label: ruleType.label,
          value: ruleType.value
        }
      });
    }), 1)], 1), _c("el-form-item", {
      attrs: {
        prop: "tracker_groups." + _vm.ruleGroupIndex + ".rule." + _vm.ruleIndex + ".include." + includeIndex + ".value",
        rules: {
          required: true,
          validator: _vm.validateArrIsEmpty,
          trigger: "change"
        }
      }
    }, [includeItem.type === "keywords" ? _c("div", {
      staticClass: "include-content"
    }, [_c("mgv-select", {
      staticClass: "select-input",
      attrs: {
        "popper-class": "select-input-dropdown",
        "popper-append-to-body": false,
        visible: false,
        multiple: "",
        filterable: "",
        "allow-create": "",
        "default-first-option": "",
        placeholder: "可输入多个关键词，使用回车分隔，词之间为“或”关系"
      },
      model: {
        value: includeItem.value,
        callback: function ($$v) {
          _vm.$set(includeItem, "value", $$v);
        },
        expression: "includeItem.value"
      }
    }), _vm.ruleItem.include && _vm.ruleItem.include.length > 1 ? _c("div", {
      staticClass: "delete-include-btn",
      on: {
        click: function ($event) {
          return _vm.handleDeleteIncludeItem(_vm.ruleItem.include, includeIndex);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-circle-close"
    })]) : _vm._e()], 1) : _vm._e(), includeItem.type === "regex" ? _c("el-input", {
      attrs: {
        type: "textarea",
        placeholder: "请填写正则表达式"
      },
      model: {
        value: includeItem.value[0],
        callback: function ($$v) {
          _vm.$set(includeItem.value, 0, $$v);
        },
        expression: "includeItem.value[0]"
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.ruleItem.include && _vm.ruleItem.include.length && _vm.ruleItem.include[0].type === "keywords" ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "add-rule-include-item-btn",
    attrs: {
      type: "text",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.ruleItem.include.push({
          type: "keywords",
          value: []
        });
      }
    }
  }, [_vm._v("且")]) : _vm._e()], 2), _c("div", {
    staticClass: "rule-basic-include"
  }, [_vm._l(_vm.ruleItem.exclude, function (excludeItem, excludeIndex) {
    return _c("div", {
      key: "exclude" + excludeIndex,
      class: ["rule-include-item", {
        "no-first-rule-include-item": excludeIndex !== 0
      }]
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: excludeIndex === 0,
        expression: "excludeIndex === 0"
      }],
      staticClass: "label-text"
    }, [_vm._v("不包含")]), _c("el-tooltip", {
      attrs: {
        content: _vm.getItemTypeTooltip(excludeItem.type),
        placement: "top",
        "open-delay": 500,
        enterable: false
      }
    }, [_c("el-select", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: excludeIndex === 0,
        expression: "excludeIndex === 0"
      }],
      staticClass: "select-width",
      on: {
        change: function ($event) {
          return _vm.handleIncludeTypeChange(_vm.ruleItem.exclude, excludeItem);
        }
      },
      model: {
        value: excludeItem.type,
        callback: function ($$v) {
          _vm.$set(excludeItem, "type", $$v);
        },
        expression: "excludeItem.type"
      }
    }, _vm._l(_vm.typeOptions, function (ruleType) {
      return _c("el-option", {
        key: ruleType.value,
        attrs: {
          label: ruleType.label,
          value: ruleType.value
        }
      });
    }), 1)], 1), _c("el-form-item", [excludeItem.type === "keywords" ? _c("div", {
      staticClass: "include-content"
    }, [_c("mgv-select", {
      staticClass: "select-input",
      attrs: {
        "popper-class": "select-input-dropdown",
        "popper-append-to-body": false,
        visible: false,
        multiple: "",
        filterable: "",
        "allow-create": "",
        "default-first-option": "",
        placeholder: "可输入多个关键词，使用回车分隔，词之间为“或”关系"
      },
      model: {
        value: excludeItem.value,
        callback: function ($$v) {
          _vm.$set(excludeItem, "value", $$v);
        },
        expression: "excludeItem.value"
      }
    }), _vm.ruleItem.exclude && _vm.ruleItem.exclude.length > 1 ? _c("div", {
      staticClass: "delete-include-btn",
      on: {
        click: function ($event) {
          return _vm.handleDeleteIncludeItem(_vm.ruleItem.exclude, excludeIndex);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-circle-close"
    })]) : _vm._e()], 1) : _vm._e(), excludeItem.type === "regex" ? _c("el-input", {
      attrs: {
        type: "textarea",
        placeholder: "请填写正则表达式"
      },
      model: {
        value: excludeItem.value[0],
        callback: function ($$v) {
          _vm.$set(excludeItem.value, 0, $$v);
        },
        expression: "excludeItem.value[0]"
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.ruleItem.exclude && _vm.ruleItem.exclude.length && _vm.ruleItem.exclude[0].type === "keywords" ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isReadonly,
      expression: "!isReadonly"
    }],
    staticClass: "add-rule-include-item-btn",
    attrs: {
      type: "text",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.ruleItem.exclude.push({
          type: "keywords",
          value: []
        });
      }
    }
  }, [_vm._v("且")]) : _vm._e()], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;