"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "report-overview"
  }, [_c("header", {
    staticClass: "header"
  }, [_vm._v("报告概览")]), _c("div", {
    staticClass: "overview"
  }, _vm._l(_vm.tableData, function (_ref, index) {
    let {
      name,
      user_total
    } = _ref;
    return _c("div", {
      key: index,
      staticClass: "group"
    }, [_c("i", {
      staticClass: "el-icon-user-solid",
      class: {
        "test-group": !index
      }
    }), _c("div", {
      staticClass: "info"
    }, [_c("p", {
      staticClass: "user-lable"
    }, [_vm._v(_vm._s(name) + "人数")]), _c("p", {
      staticClass: "total"
    }, [_vm._v(_vm._s(user_total))])])]);
  }), 0), _c("div", {
    staticClass: "report-table"
  }, [_c("el-table", {
    attrs: {
      data: _vm.tableData
    }
  }, _vm._l(_vm.tableHead, function (_ref2, index) {
    let {
      label,
      field_name
    } = _ref2;
    return _c("el-table-column", {
      key: index,
      attrs: {
        label: label,
        prop: field_name,
        resizable: false,
        "min-width": "160",
        "show-overflow-tooltip": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: label,
              placement: "top"
            }
          }, [_c("div", {
            staticClass: "table-header"
          }, [_vm._v(_vm._s(label))])])];
        },
        proxy: true
      }, field_name === "data" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "content"
          }, [_c("p", [_vm._v(" 合计: " + _vm._s(row.data[index - 1].sum === -1 ? "--" : row.data[index - 1].sum) + " ")]), _c("p", [_vm._v("平均: " + _vm._s(row.data[index - 1].avg))])])];
        }
      } : null], null, true)
    });
  }), 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;