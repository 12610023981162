"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DetailOperation',
  props: {
    operateButton: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    operate(action) {
      this.$emit('detailOperations', action);
    }
  }
};
exports.default = _default;