"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mgv-department-multi-select"
  }, [_c("el-popover", {
    ref: "optionsPopover",
    attrs: {
      placement: "bottom-start",
      "popper-class": _vm.popoverVisible ? "tree-select-popover collapase popover-show" : "tree-select-popover collapase",
      "visible-arrow": false,
      trigger: "click"
    },
    on: {
      "after-enter": function ($event) {
        return _vm.$refs["optionsPopover"].updatePopper();
      }
    },
    model: {
      value: _vm.popoverVisible,
      callback: function ($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c("ValueContainer", {
    ref: "value-container",
    attrs: {
      slot: "reference",
      placeholder: _vm.placeholder,
      "is-department": "",
      "is-single": "",
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.selectionList
    },
    on: {
      removeItem: _vm.removeItem,
      clear: _vm.clear
    },
    nativeOn: {
      click: function ($event) {
        $event.stopPropagation();
      }
    },
    slot: "reference"
  }), _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.hanldeClosePopover,
      expression: "hanldeClosePopover"
    }],
    ref: "tree-select-options-container",
    staticClass: "tree-select-options-container"
  }, [_c("SearchContainer", {
    attrs: {
      "is-department": "",
      "is-searching": _vm.isSearching,
      "popover-visible": _vm.popoverVisible,
      "selection-list": _vm.selectionList,
      "search-options": _vm.searchOptions
    },
    on: {
      "update:isSearching": function ($event) {
        _vm.isSearching = $event;
      },
      "update:is-searching": function ($event) {
        _vm.isSearching = $event;
      },
      removeItem: _vm.removeItem,
      chekedItem: _vm.chekedItem,
      filterChange: _vm.getDepartmentTreeData
    }
  }), _c("DepartmentTree", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSearching,
      expression: "!isSearching"
    }],
    ref: "department-tree",
    attrs: {
      "popover-visible": _vm.popoverVisible,
      "request-params": _vm.requestParams,
      "request-method": _vm.requestMethod,
      "selection-list": _vm.selectionList,
      "set-checked-root-node": _vm.setCheckedRootNode,
      "show-space-name": _vm.showSpaceName,
      "no-cache": _vm.noCache
    },
    on: {
      checkedChange: _vm.treeCheckedChange
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;