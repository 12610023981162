"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KeyEventMutualExclusionHeader = _interopRequireDefault(require("./components/KeyEventMutualExclusionHeader.vue"));
var _KeyEventMutualExclusionTable = _interopRequireDefault(require("./components/KeyEventMutualExclusionTable.vue"));
var _CreateMutualExclusionRule = _interopRequireDefault(require("./components/CreateMutualExclusionRule.vue"));
var _vue = require("vue");
var _keyEventMutualExclusion = require("./hooks/keyEventMutualExclusion.js");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const {
      getMutualExclusionListApi,
      mutexRuleList,
      tableLoading
    } = (0, _keyEventMutualExclusion.useGetMutualExclusionList)(store);
    const operateMutexRuleId = (0, _vue.ref)(0);
    const {
      createMutualExclusionRuleDrawerVisible,
      openMutualExclusionDrawer,
      usedIds
    } = (0, _keyEventMutualExclusion.useCreateMutualExclusion)(store, operateMutexRuleId);
    const mutexDrawerRef = (0, _vue.ref)(null);
    const editMutexRule = async id => {
      openMutualExclusionDrawer(id);
      mutexDrawerRef.value.getKeyEventMutexDetailApi(id);
    };
    const {
      deleteMutexRuleAsync
    } = (0, _keyEventMutualExclusion.useDeleteMutualExclusion)(store, getMutualExclusionListApi);
    (0, _vue.onMounted)(() => {
      getMutualExclusionListApi();
    });
    const mutexTableRef = (0, _vue.ref)(null);
    (0, _vue.watch)(() => createMutualExclusionRuleDrawerVisible.value, val => {
      if (!val) {
        mutexTableRef.value.clearActiveId();
      }
    });
    (0, _vue.watch)(() => store.getters.workspaceInfo.id, val => {
      if (val) {
        getMutualExclusionListApi();
      }
    });
    return {
      __sfc: true,
      store,
      getMutualExclusionListApi,
      mutexRuleList,
      tableLoading,
      operateMutexRuleId,
      createMutualExclusionRuleDrawerVisible,
      openMutualExclusionDrawer,
      usedIds,
      mutexDrawerRef,
      editMutexRule,
      deleteMutexRuleAsync,
      mutexTableRef,
      KeyEventMutualExclusionHeader: _KeyEventMutualExclusionHeader.default,
      KeyEventMutualExclusionTable: _KeyEventMutualExclusionTable.default,
      CreateMutualExclusionRule: _CreateMutualExclusionRule.default
    };
  }
};
exports.default = _default;