"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _LogTable = _interopRequireDefault(require("./components/LogTable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OperationLog',
  components: {
    LogTable: _LogTable.default
  },
  data() {
    return {
      dateRange: [this.$moment().subtract(6, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      total: 0,
      page: 1,
      size: 10,
      list: [],
      sortColumn: 'operate_time',
      sortOrder: 'descending',
      pickerOptions: {
        disabledDate: this.getDisabledDate
      },
      loading: false
    };
  },
  computed: {
    listParams() {
      return {
        page: this.page,
        size: this.size,
        date: JSON.stringify(this.dateRange),
        sort_column: this.sortColumn,
        sort_order: this.sortOrder
      };
    }
  },
  watch: {
    listParams: {
      handler() {
        this.getOperateLog();
      },
      deep: true
    }
  },
  created() {
    this.getOperateLog();
  },
  methods: {
    async getOperateLog() {
      this.loading = true;
      const res = await (0, _organization.getOperateLog)(this.listParams);
      this.loading = false;
      this.list = res.results.datas;
      this.total = res.results.count;
    },
    handleDateRange(val) {
      this.dateRange = val || [this.$moment().subtract(6, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
    },
    handleCurrentChange(page) {
      this.page = page;
    },
    handleSizeChange(size) {
      this.size = size;
      this.page = 1;
    },
    getDisabledDate(date) {
      return !this.$moment(date).isBetween(this.$moment().subtract(90, 'days'));
    },
    handleSortChange(prop, order) {
      this.sortColumn = prop;
      this.sortOrder = order;
    }
  }
};
exports.default = _default;