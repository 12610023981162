"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _conversationList = require("@/api/conversationList");
var _SavedFilters = _interopRequireDefault(require("./components/SavedFilters"));
var _FiltersForm = _interopRequireDefault(require("./components/FiltersForm"));
var _FiltersManage = _interopRequireDefault(require("./components/FiltersManage"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _business = require("@/api/business");
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationFilter',
  components: {
    SavedFilters: _SavedFilters.default,
    FiltersManage: _FiltersManage.default,
    FiltersForm: _FiltersForm.default
  },
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    }
  },
  props: {
    backFillFilters: {
      type: Object,
      default: () => ({})
    },
    // 会话/质检评分规则抽屉中隐藏已保存的筛选项及质检评分筛选项
    hideSavedFilters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      selectValue: '',
      isSelectValue: false,
      filterNumber: 0,
      filters: {
        host: [],
        companyName: [],
        dealName: [],
        textFilterList: [{
          pattern: '',
          role: 'default',
          inverse: false
        }],
        filter: [],
        filter_relation: 'and',
        // 存放关键事件跟踪器级联的数组
        keyEventCascaderList: [{
          inverse: false,
          keyEventName: []
        }],
        event_engine_relation: 'and',
        event_engine: [],
        // 关键事件顺序筛选器
        keyEventOrderCascaderList: [{
          inverse: false,
          keyEventName: []
        }, {
          inverse: false,
          keyEventName: []
        }],
        event_engine_order: [],
        date: {
          range: [],
          tag: ''
        },
        entity_type: '',
        conversation_deal_status: '',
        conversation_type: '',
        conversation_scene_type: '',
        duration: {
          unit: 'second',
          value: [0, 'unlimit']
        },
        max_silence_duration: [0, 'unlimit'],
        hang_up: 0,
        // 0无 1销售 2客户
        host_ids: [],
        hosts_includes_deleted_members: false,
        organization_tree_ids: [],
        account_ids: [],
        deal_name: {
          value: [],
          is_batch: false
        },
        departmentLabel: '',
        custom_filters: {},
        // 商机自定义字段参数
        sale_sentence_count: [],
        custom_sentence_count: [],
        sentence_count: [],
        // 会话自定义字段
        conv_custom_filters: {},
        conversation_id: {
          value: [],
          is_batch: false
        },
        // 会话ids
        conv_times: {
          is_custom: false,
          value: [-1, -1]
        },
        // 会话维度字段
        max_sales: [],
        // 最大销售说话时长
        max_customer: [],
        // 最大客户说话时长
        percent_speak: [],
        // 销售说话占比
        discuss: [],
        // 深入讨论次数
        question: [],
        // 销售提问次数
        customer_question: [],
        // 客户提问次数
        sales_speed: [],
        // 销售说话语速
        customer_speed: [],
        // 客户说话语速
        compliance_score_filters: [],
        // 质检评分规则
        conv_score_filters: [] // 会话评分规则
      },

      filterDepartmentLabel: '',
      stageOptions: [],
      customFilterObject: {},
      customFilterPromise: null,
      // 会话列表页面会话自定义筛选项
      conversationListCustomFilter: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['main_department_id', 'workspaceInfo'])
  },
  watch: {
    selectValue(val) {
      if (this.$refs['savedFilters']) {
        this.$refs['savedFilters'].selectValue = val;
      }
    },
    filters: {
      handler: function (val) {
        clearTimeout(this.timer);
        this.filterNumber = this.getFilterNumber(val);
        this.$emit('filterNumChange', this.filterNumber);
        this.timer = setTimeout(() => {
          // 是否清空已选择筛选器名
          this.clearSelectValue(val);
        }, 500);
      },
      deep: true
    },
    $route: {
      handler: function (to, from) {
        if (to.params.searchText) {
          this.initSearchText();
        } else if (to.params.path.length) {
          this.initSearchEvent();
        }
      },
      deep: true
    },
    backFillFilters: {
      handler(filter) {
        if (Object.keys(filter).length) {
          this.$nextTick(() => {
            this.filterChange({
              filter
            });
          });
        }
      },
      immediate: true
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getStageOptions();
          this.getConversationListFilter();
        }
      },
      immediate: true
    }
  },
  created() {
    this.getDealCustomFilter();
  },
  mounted() {
    // 全局搜索携参跳转至会话页
    this.initSearchText();
    this.initSearchEvent();
    this.initSearchHost();
    // 会话智能流查看全部
    this.$nextTick(() => {
      const storageFilter = JSON.parse(sessionStorage.getItem('conversationFilter'));
      if (storageFilter) {
        this.filterChange({
          filter: storageFilter
        });
      }
    });
    this.$emit('showMytour');
    // 我的成员质检回填
    const {
      compliance_id,
      name
    } = this.$route.query;
    if (compliance_id) {
      this.filterChangeFunction().handleMemberBackFillData({
        host_ids: [Number(compliance_id)],
        host: [{
          id: Number(compliance_id),
          name
        }]
      });
    }
  },
  methods: {
    // 获取会话列表页面筛选项
    async getConversationListFilter() {
      const res = await (0, _conversationList.getConversationListFilter)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.conversationListCustomFilter = res.results.filter_list;
      }
    },
    async getDealCustomFilter() {
      this.customFilterPromise = new Promise(resolve => {
        const params = {
          access_type: _routeMap.ROUTES.conversation,
          workspace_id: this.workspaceInfo.id
        };
        (0, _business.getDealCustomFilter)(params).then(res => {
          this.customFilterObject = res.results;
          resolve();
        });
      });
    },
    // 获取filters原始数据
    getOriginData() {
      return this.$options.data().filters;
    },
    // 格式化要保存的筛选器数据或者要发起列表筛选请求的数据
    formatData() {
      var _this$$refs$filtersFo, _this$$refs$filtersFo2, _this$$refs$filtersFo3, _this$$refs$filtersFo4, _this$$refs$filtersFo5, _this$$refs$filtersFo6;
      let isSavedFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const params = (0, _commonFunc.deepClone)(this.filters);
      const {
        textFilterList,
        keyEventCascaderList,
        keyEventOrderCascaderList
      } = params;
      // 每次要触发过滤器请求时格式化数据
      // 修改会话时长参数
      // eslint-disable-next-line
      if (params.duration.value[1] == 0) params.duration.value[1] = 'unlimit';

      // 过滤空的文本搜索和关键事件
      const changedText = textFilterList.filter(item => item.pattern.length);
      params.textFilterList = changedText.length ? changedText : [{
        pattern: '',
        role: 'default',
        inverse: false
      }];
      params.filter = [];
      this.filters.textFilterList.forEach((item, index) => {
        if (item.pattern.length) {
          params.filter.push({
            pattern: item.pattern,
            role: item.role,
            inverse: item.inverse
          });
        }
      });
      const changedEvents = keyEventCascaderList.filter(item => item.keyEventName.length);
      params.keyEventCascaderList = changedEvents.length ? changedEvents : [{
        inverse: false,
        keyEventName: []
      }];

      // 格式化关键事件追踪器数据
      const keyEventNode =
      // eslint-disable-next-line no-undef
      (_this$$refs$filtersFo = this.$refs['filtersForm']) === null || _this$$refs$filtersFo === void 0 ? void 0 : (_this$$refs$filtersFo2 = _this$$refs$filtersFo.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo2 === void 0 ? void 0 : (_this$$refs$filtersFo3 = _this$$refs$filtersFo2.$refs['eventTracker']) === null || _this$$refs$filtersFo3 === void 0 ? void 0 : _this$$refs$filtersFo3.$refs['MultipleCascaderSelect'].$refs['key-event-cascader'];
      params.event_engine = [];
      this.filters.keyEventCascaderList.forEach((item, index) => {
        var _keyEventNode$index$g;
        const checkedNode = keyEventNode && ((_keyEventNode$index$g = keyEventNode[index].getCheckedNodes()) === null || _keyEventNode$index$g === void 0 ? void 0 : _keyEventNode$index$g[0]);
        if (item.keyEventName.length && checkedNode) {
          const keyEventName = checkedNode.data.name;
          const obj = {
            id: item.keyEventName[item.keyEventName.length - 1],
            ids: item.keyEventName,
            inverse: item.inverse,
            keyEventName
          };
          params.event_engine.push(obj);
        }
      });
      const changedEventsOrder = keyEventOrderCascaderList.filter(item => item.keyEventName.length);
      params.keyEventOrderCascaderList = changedEventsOrder.length ? changedEventsOrder : [{
        inverse: false,
        keyEventName: []
      }, {
        inverse: false,
        keyEventName: []
      }];
      const keyEventOrderNode =
      // eslint-disable-next-line no-undef
      (_this$$refs$filtersFo4 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo4 === void 0 ? void 0 : (_this$$refs$filtersFo5 = _this$$refs$filtersFo4.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo5 === void 0 ? void 0 : (_this$$refs$filtersFo6 = _this$$refs$filtersFo5.$refs['eventOrderTracker']) === null || _this$$refs$filtersFo6 === void 0 ? void 0 : _this$$refs$filtersFo6.$refs['orderSelect'].$refs['key-event-order-cascader'];
      params.event_engine_order = [];
      this.filters.keyEventOrderCascaderList.forEach((item, index) => {
        var _keyEventOrderNode$in;
        const checkedNode = keyEventOrderNode && ((_keyEventOrderNode$in = keyEventOrderNode[index].getCheckedNodes()) === null || _keyEventOrderNode$in === void 0 ? void 0 : _keyEventOrderNode$in[0]);
        if (item.keyEventName.length && checkedNode) {
          const keyEventName = checkedNode.data.name;
          const obj = {
            id: item.keyEventName[item.keyEventName.length - 1],
            ids: item.keyEventName,
            inverse: item.inverse,
            keyEventName
          };
          params.event_engine_order.push(obj);
        }
      });
      this.$emit('filterChange', params);
      if (isSavedFilter) {
        var _this$$refs$filtersFo7, _this$$refs$filtersFo8;
        // 因为每次筛选器改变都会触发格式化数据，所以要保存数据前仅需要处理回填数据即可
        // 如果是要保存筛选器则给数据中加入host数据，存储当前选中的销售信息，方便数据回填
        const isExistsSelectMembers =
        // eslint-disable-next-line no-undef
        (_this$$refs$filtersFo7 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo7 === void 0 ? void 0 : (_this$$refs$filtersFo8 = _this$$refs$filtersFo7.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo8 === void 0 ? void 0 : _this$$refs$filtersFo8.$refs['memberFinderRef'];
        if (isExistsSelectMembers) {
          const hostArr = params.host.concat(isExistsSelectMembers.selectionObj);
          const hostIdsArr = [];
          params.host = [];
          hostArr.forEach(item => {
            if (params.host_ids.includes(item.id) && !hostIdsArr.includes(item.id)) {
              params.host.push(item);
              hostIdsArr.push(item.id);
            }
          });
        }
        if (Object.prototype.hasOwnProperty.call(params, 'event_invalid')) {
          delete params.event_invalid;
        }
      }
      this.$emit('convFlowFiltersChange', params);
      return params;
    },
    deliverFilters() {
      let isSavedFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const params = this.formatData(isSavedFilter);
      // 调用筛选管理器的保存方法并将数据返回给筛选管理器
      this.$refs['filtersManage'].postSaveFilter(params);
    },
    filterChangeFunction() {
      const obj = {};
      obj.clearConversationScore = () => {
        this.$refs['filtersForm'] && this.$refs['filtersForm'].clearConversationScore();
      };
      obj.clearComplianceScore = () => {
        this.$refs['filtersForm'] && this.$refs['filtersForm'].clearComplianceScore();
      };
      // 处理会话评分的回填
      obj.handleConversationScoreChange = filter => {
        if (filter.conv_score_filters) {
          const conversationScoreFiltersList = this.$refs['filtersForm'] && this.$refs['filtersForm'].conversationScoreFiltersList;
          filter.conv_score_filters.map(item => {
            const findIndex = conversationScoreFiltersList.findIndex(cur => cur.id === item.id);
            this.$refs['filtersForm'] && this.$refs['filtersForm'].handleScoreChange({
              index: findIndex,
              data: findIndex !== -1 && {
                type: item.type,
                score_range: item.score_range
              },
              type: 'conv_score_filters'
            });
          });
        }
      };
      // 处理质检评分的回填
      obj.handleComplianceScoreChange = filter => {
        if (filter.compliance_score_filters) {
          const complianceScoreFiltersList = this.$refs['filtersForm'] && this.$refs['filtersForm'].complianceScoreFiltersList;
          filter.compliance_score_filters.map(item => {
            const findIndex = complianceScoreFiltersList.findIndex(cur => cur.id === item.id);
            this.$refs['filtersForm'] && this.$refs['filtersForm'].handleScoreChange({
              index: findIndex,
              data: findIndex !== -1 && {
                type: item.type,
                score_range: item.score_range
              },
              type: 'compliance_score_filters'
            });
          });
        }
      };

      // 处理部门多选回填
      obj.handleDepartmentBackFillData = filter => {
        if (filter.organization_tree_ids) {
          var _this$$refs$filtersFo9, _this$$refs$filtersFo10, _this$$refs$filtersFo11;
          const departmentDom = (_this$$refs$filtersFo9 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo9 === void 0 ? void 0 : (_this$$refs$filtersFo10 = _this$$refs$filtersFo9.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo10 === void 0 ? void 0 : (_this$$refs$filtersFo11 = _this$$refs$filtersFo10.$refs['mgvDepartmentMultiSelectRef']) === null || _this$$refs$filtersFo11 === void 0 ? void 0 : _this$$refs$filtersFo11.$refs['department-tree'];
          departmentDom && departmentDom.backFillData(filter.organization_tree_ids);
        }
      };

      // 处理成员多选回填
      obj.handleMemberBackFillData = filter => {
        if (filter.host) {
          var _this$$refs$filtersFo12, _this$$refs$filtersFo13;
          const node = (_this$$refs$filtersFo12 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo12 === void 0 ? void 0 : (_this$$refs$filtersFo13 = _this$$refs$filtersFo12.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo13 === void 0 ? void 0 : _this$$refs$filtersFo13.$refs['memberFinderRef'];
          if (node) {
            node.selection = [];
            node.memberOptions = filter.host;
            this.$nextTick(() => {
              node.selection = filter.host_ids;
              node.isCheckedDeleted = filter.hosts_includes_deleted_members;
              node.handleCheckDeleted(node.isCheckedDeleted);
            });
          }
        }
      };
      return obj;
    },
    // 筛选器管理组件的选中值发生变化时触发
    filterChange(_ref) {
      let {
        filter
      } = _ref;
      // 清楚会话评分和质检评分
      this.filterChangeFunction().clearComplianceScore();
      this.filterChangeFunction().clearConversationScore();
      this.filterChangeFunction().handleConversationScoreChange(filter);
      this.filterChangeFunction().handleComplianceScoreChange(filter);
      filter = (0, _commonFunc.deepClone)(filter);
      const originFilters = this.getOriginData();
      this.$emit('filterChange', (0, _commonFunc.deepClone)(filter));
      // 标识当前有选中筛选器
      this.isSelectValue = true;
      // 选中值的时候，保存按钮不能点击
      if (this.$refs['filtersManage']) {
        this.$refs['filtersManage'].saveFilterDisable = true;
      }
      // 清空所有选项
      this.filters = originFilters;
      // 处理特殊值回填
      // 部门多选回填;
      this.filterChangeFunction().handleDepartmentBackFillData(filter);
      // 销售：数据回填下拉选框树
      // eslint-disable-next-line no-undef
      this.filterChangeFunction().handleMemberBackFillData(filter);

      // 事件跟踪器数据格式化
      if (!filter.keyEventCascaderList) {
        var _filter, _filter$event_engine;
        if ((_filter = filter) !== null && _filter !== void 0 && (_filter$event_engine = _filter.event_engine) !== null && _filter$event_engine !== void 0 && _filter$event_engine.length) {
          filter.keyEventCascaderList = JSON.parse(JSON.stringify(filter.event_engine));
          filter.event_engine.forEach((item, index) => {
            filter.keyEventCascaderList[index].keyEventName = item.ids;
          });
        } else {
          filter.keyEventCascaderList = originFilters.keyEventCascaderList;
        }
      }
      if (!filter.keyEventOrderCascaderList) {
        var _filter2, _filter2$event_engine;
        if ((_filter2 = filter) !== null && _filter2 !== void 0 && (_filter2$event_engine = _filter2.event_engine_order) !== null && _filter2$event_engine !== void 0 && _filter2$event_engine.length) {
          filter.keyEventOrderCascaderList = JSON.parse(JSON.stringify(filter.event_engine_order));
          filter.event_engine_order.forEach((item, index) => {
            filter.keyEventOrderCascaderList[index].keyEventName = item.ids;
          });
        } else {
          filter.keyEventOrderCascaderList = originFilters.keyEventOrderCascaderList;
        }
      }
      Object.assign(this.filters, (0, _commonFunc.deepClone)(filter));
      // 如果有本地存储的筛选器，回填完删除
      const storageFilter = sessionStorage.getItem('conversationFilter');
      if (storageFilter) {
        sessionStorage.removeItem('conversationFilter');
      }
    },
    // 是否清空已选择的筛选器名
    clearSelectValue(val) {
      if (this.isSelectValue) {
        var _this$$refs$savedFilt;
        if (JSON.stringify(val) !== JSON.stringify((_this$$refs$savedFilt = this.$refs['savedFilters']) === null || _this$$refs$savedFilt === void 0 ? void 0 : _this$$refs$savedFilt.selectValue)) {
          this.isSelectValue = false;
          if (this.$refs['filtersManage']) {
            this.$refs['filtersManage'].saveFilterDisable = true;
          }
        }
      } else {
        // 改变筛选器后触发会话筛选
        this.formatData(this.isBaseConvInDrawer);
        if (this.$refs['savedFilters']) {
          this.$refs['savedFilters'].selectValue = '';
        }
        if (this.$refs['filtersManage']) {
          this.$refs['filtersManage'].saveFilterDisable = false;
        }
      }
    },
    // 清空筛选器
    clearAll() {
      var _this$$refs$filtersFo14, _this$$refs$filtersFo15, _this$$refs$filtersFo16, _this$$refs$filtersFo17, _this$$refs$filtersFo18, _this$$refs$filtersFo19;
      this.filters = this.getOriginData();
      if (this.$refs['savedFilters']) {
        this.$refs['savedFilters'].selectValue = '';
      }
      // 部门多选清空
      const departmentDom = (_this$$refs$filtersFo14 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo14 === void 0 ? void 0 : (_this$$refs$filtersFo15 = _this$$refs$filtersFo14.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo15 === void 0 ? void 0 : _this$$refs$filtersFo15.$refs['mgvDepartmentMultiSelectRef'];
      departmentDom && (departmentDom.selectionList = []);
      // 成员多选清空
      // eslint-disable-next-line no-undef
      const node = (_this$$refs$filtersFo16 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo16 === void 0 ? void 0 : (_this$$refs$filtersFo17 = _this$$refs$filtersFo16.$refs['conversationBaseFiltersRef']) === null || _this$$refs$filtersFo17 === void 0 ? void 0 : _this$$refs$filtersFo17.$refs['memberFinderRef'];
      if (node) {
        node.selection = [];
      }
      // 清空会话评分
      (_this$$refs$filtersFo18 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo18 === void 0 ? void 0 : _this$$refs$filtersFo18.clearConversationScore();
      // 清空质检评分
      (_this$$refs$filtersFo19 = this.$refs['filtersForm']) === null || _this$$refs$filtersFo19 === void 0 ? void 0 : _this$$refs$filtersFo19.clearComplianceScore();
    },
    // 获取会话时商机阶段
    getStageOptions() {
      (0, _conversationList.getStageOptions)({
        workspace_id: this.workspaceInfo.id,
        source: 'conversation'
      }).then(res => {
        this.stageOptions = res.results;
      });
    },
    // 比对修改的项数
    getFilterNumber(filters) {
      const compareList = ['organization_tree_ids', 'host_ids', 'account_ids', 'deal_name', 'textFilterList', 'keyEventCascaderList', 'keyEventOrderCascaderList', 'date', 'conversation_deal_status', 'conversation_type', 'duration', 'max_silence_duration', 'hang_up', 'custom_filters', 'conv_custom_filters', 'sale_sentence_count', 'custom_sentence_count', 'sentence_count', 'compliance_score_filters', 'conv_score_filters', 'conv_times', 'max_sales', 'max_customer', 'percent_speak', 'discuss', 'question', 'customer_question', 'sales_speed', 'customer_speed'];
      const originFilters = this.getOriginData();
      let number = 0;
      compareList.forEach(key => {
        const curr = filters[key];
        if (typeof curr === 'object') {
          if (!(0, _isEqual.default)(curr, originFilters[key])) {
            switch (key) {
              case 'keyEventCascaderList':
              case 'keyEventOrderCascaderList':
                {
                  const changedEvent = curr.find(item => item.keyEventName.length);
                  changedEvent && number++;
                  break;
                }
              case 'textFilterList':
                {
                  const changedText = curr.find(item => item.pattern.length);
                  changedText && number++;
                  break;
                }
              case 'custom_filters':
              case 'conv_custom_filters':
                {
                  // 商机自定义字段
                  Object.values(curr).forEach(item => {
                    const type = Object.prototype.toString.call(item);
                    switch (type) {
                      case '[object Object]':
                        Object.values(item).length && number++;
                        break;
                      case '[object Boolean]':
                        item && number++;
                        break;
                      default:
                        item && item.length && number++;
                        break;
                    }
                  });
                  break;
                }
              case 'compliance_score_filters':
              case 'conv_score_filters':
                number += curr.filter(item => item.type).length;
                break;
              case 'conv_times':
                curr.value.length && number++;
                break;
              default:
                number++;
                break;
            }
          }
        } else {
          if (curr !== originFilters[key]) {
            number++;
          }
        }
      });
      return number;
    },
    // 全局搜索携参跳转至会话页，含搜索文本的跳转
    initSearchText() {
      const searchText = this.$route.params.searchText;
      if (searchText) {
        this.clearAll();
        this.filters.textFilterList = [{
          pattern: searchText,
          role: 'default',
          inverse: false
        }];
        this.filters.filter = [{
          pattern: searchText,
          role: 'default',
          inverse: false
        }];
        delete this.$route.params.searchText;
      }
    },
    // 全局搜索携参跳转至会话页，含关键事件的跳转
    initSearchEvent() {
      const searchEvent = this.$route.params.path;
      if (searchEvent && searchEvent.length) {
        this.clearAll();
        this.filters.keyEventCascaderList = [{
          keyEventName: searchEvent,
          inverse: false
        }];
        this.filters.event_engine = [{
          id: searchEvent[searchEvent.length - 1],
          ids: searchEvent,
          inverse: false
        }];
        delete this.$route.params.path;
      }
    },
    initSearchHost() {
      if (!this.$route.query.id || !this.$route.query.name) return;
      const {
        id,
        name
      } = this.$route.query;
      const exFilters = {
        host: [{
          id: id - 0,
          name
        }],
        host_ids: [id - 0]
      };
      const filter = {
        ...this.filters,
        ...exFilters
      };
      this.filterChange({
        filter
      });
    }
  }
};
exports.default = _default;