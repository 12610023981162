"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trans-and-loss"
  }, [!_vm.kanbanListLoading && !_vm.kanbanList.length && !_vm.rankingListVisible ? _c("init-kanban-card-view", {
    attrs: {
      config: {
        title: "看板",
        tooltipTitle: "业务转化卡点分析看板",
        tooltip: ["业务转化卡点分析能够对关键事件的转化率、流失率进行分析。用户可以看到已选的关键事件的转化、流失情况，进而可以对转化率低的关键事件进行分析，也可以下钻到相应人员进行追踪或培训。"]
      }
    },
    on: {
      createKanban: _vm.createKanban
    }
  }, [_c("img", {
    staticClass: "right",
    attrs: {
      slot: "right-img",
      src: require("@/assets/BI/pie-doughnut.svg")
    },
    slot: "right-img"
  })]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.kanbanList.length && !_vm.rankingListVisible,
      expression: "kanbanList.length && !rankingListVisible"
    }]
  }, [_c("BI-kanban", {
    ref: "kanban",
    attrs: {
      "active-id": _vm.currentKanban.kanban_id,
      "extra-command-list": _vm.getExtraCommandlist(),
      "kanban-list": _vm.kanbanList
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }), _c("div", {
    staticClass: "main-content"
  }, [_vm.showInvalidDataTip ? _c("NoData", {
    attrs: {
      tips: "因指标被删除，看板失效"
    }
  }) : _c("div", [_c("div", {
    staticClass: "chart-header"
  }, [_c("div", {
    staticClass: "chart-title"
  }, [_c("el-tooltip", {
    attrs: {
      disabled: !_vm.chartFootnote.event || _vm.chartFootnote.event.length <= 3,
      content: _vm.chartFootnote.event && _vm.chartFootnote.event.join("、"),
      placement: "top"
    }
  }, [_c("h1", [_vm._v(" 成员"), _vm.formatEventLabel ? _c("span", [_vm._v("【" + _vm._s(_vm.formatEventLabel) + "】")]) : _vm._e(), _vm._v("卡点分析 ")])]), _c("h2", [_vm._v(" 共" + _vm._s(_vm.chartData.count) + "名成员，其中 " + _vm._s(_vm.chartData.prob_percent) + " 的成员有卡点 ")])], 1), _c("div", {
    staticClass: "ranking-list-entry",
    on: {
      click: _vm.showRankingList
    }
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "转化率排行榜展示在该看板选择的关键结果下全部关键事件的转化流失情况"
    }
  }, [_c("span", {
    staticClass: "btn"
  }, [_c("i", {
    staticClass: "iconfont icon-ranking"
  }), _vm._v("转化率排行榜 ")])])], 1)]), _c("business-trans-pie-chart", {
    ref: "chart",
    attrs: {
      data: _vm.chartData,
      loading: _vm.chartLoading
    },
    on: {
      chartSelectChange: _vm.handleChartSelectChange
    }
  }), _c("business-trans-chart-footnote", {
    ref: "note",
    attrs: {
      data: _vm.chartFootnote
    },
    on: {
      submit: _vm.editFunnelFieldName
    }
  }), _c("business-trans-table", {
    ref: "table",
    attrs: {
      "kanban-id": _vm.currentKanban.kanban_id,
      "pie-selection": _vm.pieSelection
    },
    on: {
      fetchDataSucceed: _vm.updateChartData,
      submit: _vm.editFunnelFieldName
    }
  })], 1)], 1)], 1), _vm.rankingListVisible ? _c("business-trans-ranking-list", {
    attrs: {
      "kanban-id": _vm.currentKanban.kanban_id,
      "kanban-type": _vm.chartFootnote.type
    },
    on: {
      goBack: function ($event) {
        _vm.rankingListVisible = false;
      }
    }
  }) : _vm._e(), _c("business-trans-kanban-create", {
    ref: "drawer",
    attrs: {
      visible: _vm.drawerVisible,
      "is-edit": _vm.isEdit,
      "kanban-id": _vm.currentKanban.kanban_id,
      "kanban-config-loading": _vm.kanbanConfigLoading
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      },
      handleKanbanSuccess: function ($event) {
        return _vm.getKanbanList(true);
      }
    }
  }), _c("ShareConfig", {
    attrs: {
      visible: _vm.shareKanbanConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        kanban_id: _vm.currentKanban.kanban_id,
        type: 3
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareKanbanConfigVisible = $event;
      },
      handleClose: _vm.changeKanbanStatus
    }
  }), _c("EditKanbanNameDialog", {
    attrs: {
      visible: _vm.copyKanbanConfigVisible,
      "kanban-name": `${_vm.currentKanban.name}_副本`,
      params: {
        id: _vm.currentKanban.kanban_id,
        type: 3
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.copyKanbanConfigVisible = $event;
      },
      copyKanbanSuccess: _vm.getKanbanList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;