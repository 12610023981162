"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-select", {
    ref: "select",
    staticClass: "remote-select",
    attrs: {
      filterable: "",
      placeholder: _vm.placeholder,
      remote: "",
      clearable: _vm.clearable,
      autocomplete: "off",
      "remote-method": _vm.searchFilter,
      "no-data-text": "无此筛选器",
      "value-key": "id"
    },
    on: {
      focus: _vm.handleFocus,
      change: _vm.filterChange,
      "visible-change": _vm.handleVisibleChange
    },
    model: {
      value: _vm.selectValue,
      callback: function ($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filterList.length || _vm.loading,
      expression: "filterList.length || loading"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "saved-filter-container"
  }, _vm._l(_vm.filterList, function (item) {
    return _c("div", {
      key: item.id
    }, [_c("el-popover", {
      attrs: {
        disabled: item.disabled,
        trigger: "hover",
        placement: "right",
        "popper-class": "saved-filter-popover"
      }
    }, [_c("div", {
      staticClass: "filter-box"
    }, [_c("p", {
      staticClass: "filter-name"
    }, [_vm._v(_vm._s(item.name))]), _c("saved-filters-content", {
      attrs: {
        filter: item.filter,
        "stage-options": _vm.stageOptions,
        "custom-filter-array": _vm.customFilterArray,
        "conversation-list-custom-filter": _vm.conversationListCustomFilter
      }
    })], 1), _c("el-option", {
      key: item.id,
      attrs: {
        slot: "reference",
        label: item.name,
        value: item,
        disabled: item.disabled
      },
      slot: "reference"
    })], 1)], 1);
  }), 0), _c("el-option", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.filterList.length && !_vm.loading,
      expression: "!filterList.length && !loading"
    }],
    staticClass: "empty-filter",
    attrs: {
      value: "0",
      disabled: ""
    }
  }, [_c("span", [_vm._v("暂无常用筛选")])])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowInvalidTips,
      expression: "isShowInvalidTips"
    }],
    staticClass: "invalid-filter"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("span", [_vm._v("部分筛选器已失效")])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;