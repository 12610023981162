"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    class: [`mg-tag`, `mg-tag--${_vm.type}`, `mg-tag--${_vm.size}`, {
      center: _vm.center
    }, {
      circle: _vm.circle
    }, {
      clickable: _vm.clickable
    }],
    on: {
      click: _vm.handleClick
    }
  }, [_vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;