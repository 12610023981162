"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTricksToKnowledge = addTricksToKnowledge;
exports.appendReply = appendReply;
exports.changeKnowledgeTreeOrder = changeKnowledgeTreeOrder;
exports.createCatalog = createCatalog;
exports.createKnowledge = createKnowledge;
exports.createKnowledgeBase = createKnowledgeBase;
exports.createScene = createScene;
exports.deleteCatalog = deleteCatalog;
exports.deleteKnowledge = deleteKnowledge;
exports.deleteKnowledgeBase = deleteKnowledgeBase;
exports.deleteKnowledgeBasePublicScope = deleteKnowledgeBasePublicScope;
exports.editIgnoreStatus = editIgnoreStatus;
exports.editKnowledgeBase = editKnowledgeBase;
exports.editKnowledgeBasePermission = editKnowledgeBasePermission;
exports.editKnowledgeBasePublicScope = editKnowledgeBasePublicScope;
exports.getCatalogData = getCatalogData;
exports.getConvList = getConvList;
exports.getFidById = getFidById;
exports.getIgnoreKnowledgeQuestionList = getIgnoreKnowledgeQuestionList;
exports.getKeyEvents = getKeyEvents;
exports.getKnowledgeBaseList = getKnowledgeBaseList;
exports.getKnowledgeBasePermission = getKnowledgeBasePermission;
exports.getKnowledgeInfo = getKnowledgeInfo;
exports.getKnowledgeInfoById = getKnowledgeInfoById;
exports.getKnowledgeList = getKnowledgeList;
exports.getKnowledgeQaList = getKnowledgeQaList;
exports.getKnowledgeQuestionList = getKnowledgeQuestionList;
exports.getSceneList = getSceneList;
exports.getTopKnowledgeBaseList = getTopKnowledgeBaseList;
exports.likeOrDislike = likeOrDislike;
exports.moveKnowledge = moveKnowledge;
exports.moveKnowledgeTreePotion = moveKnowledgeTreePotion;
exports.updateCatalog = updateCatalog;
exports.updateKnowledge = updateKnowledge;
exports.updateKnowledgeBaseTopStatus = updateKnowledgeBaseTopStatus;
exports.updateTricksOrder = updateTricksOrder;
exports.uploadKnowledgeFile = uploadKnowledgeFile;
var _request = require("@/utils/request");
// 获取知识库目录
function getCatalogData(params) {
  return (0, _request.webService)({
    url: '/training/catalog/get',
    method: 'get',
    params
  });
}

// 创建知识库目录
function createCatalog(data) {
  return (0, _request.webService)({
    url: '/training/catalog/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新知识库目录
function updateCatalog(data) {
  return (0, _request.webService)({
    url: '/training/catalog/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除知识库目录
function deleteCatalog(data) {
  return (0, _request.webService)({
    url: '/training/catalog/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取知识点列表
function getKnowledgeList(params) {
  return (0, _request.webService)({
    url: '/training/knowledge/list',
    method: 'get',
    params
  });
}

// 获取单个知识点
function getKnowledgeInfo(params) {
  return (0, _request.webService)({
    url: '/training/knowledge/get',
    method: 'get',
    params
  });
}
// 创建知识点
function createKnowledge(data) {
  return (0, _request.webService)({
    url: '/training/knowledge/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 更新知识点
function updateKnowledge(data) {
  return (0, _request.webService)({
    url: '/training/knowledge/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除知识点
function deleteKnowledge(data) {
  return (0, _request.webService)({
    url: '/training/knowledge/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移动知识点
function moveKnowledge(data) {
  return (0, _request.webService)({
    url: '/training/knowledge/move',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 场景选择列表
function getSceneList(params) {
  return (0, _request.webService)({
    url: '/training/scene/list',
    method: 'get',
    params
  });
}

// 销售回答会话列表
function getConvList(data) {
  return (0, _request.webService)({
    url: '/training/dig_reply_engine',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建话术
function addTricksToKnowledge(data) {
  return (0, _request.webService)({
    url: '/training/reply/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 通过知识点id获取目录id
function getFidById(params) {
  return (0, _request.webService)({
    url: '/training/knowledge/fid',
    method: 'get',
    params
  });
}

// 通过知识点id获取知识库信息
function getKnowledgeInfoById(params) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/detail',
    method: 'get',
    params
  });
}

// 获取知识点挖掘引擎问题列表
function getKnowledgeQuestionList(params) {
  return (0, _request.webService)({
    url: '/training/freq_question/list',
    method: 'get',
    params
  });
}

// 获取知识点挖掘引擎销售回答问答列表
function getKnowledgeQaList(data) {
  return (0, _request.webService)({
    url: '/training/freq_question/detail/list',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 获取关键事件
function getKeyEvents(params) {
  return (0, _request.webService)({
    url: '/conversation/all_events',
    method: 'get',
    params
  });
}

// 高频问题和qa点赞点踩
function likeOrDislike(data) {
  return (0, _request.webService)({
    url: '/training/freq_question/attitude/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改知识点引擎忽略状态
function editIgnoreStatus(data) {
  return (0, _request.webService)({
    url: '/training/freq_question/omit/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取已忽略知识点挖掘引擎问题列表
function getIgnoreKnowledgeQuestionList(params) {
  return (0, _request.webService)({
    url: '/training/freq_question/omit/list',
    method: 'get',
    params
  });
}

// 创建场景
function createScene(data) {
  return (0, _request.webService)({
    url: '/training/scene/create',
    method: 'post',
    data
  });
}

// 话术换顺序
function updateTricksOrder(data) {
  return (0, _request.webService)({
    url: '/training/reply/order/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 添加话术到已有知识点
function appendReply(data) {
  return (0, _request.webService)({
    url: '/training/knowledge/scene/append',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取知识库列表
function getKnowledgeBaseList() {
  return (0, _request.webService)({
    url: '/training/knowledge_base/list',
    method: 'get'
  });
}
// 知识库置顶
function updateKnowledgeBaseTopStatus(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/top',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 删除知识库
function deleteKnowledgeBase(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 创建知识库
function createKnowledgeBase(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 重命名知识库
function editKnowledgeBase(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取知识库权限成员列表
function getKnowledgeBasePermission(params) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/permission/list',
    method: 'get',
    params
  });
}

// 编辑知识库成员权限
function editKnowledgeBasePermission(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/permission/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑知识库公开范围
function editKnowledgeBasePublicScope(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/scope/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除知识库权限
function deleteKnowledgeBasePublicScope(data) {
  return (0, _request.webService)({
    url: '/training/knowledge_base/permission/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识库置顶列表
function getTopKnowledgeBaseList() {
  return (0, _request.webService)({
    url: '/training/knowledge_base/top/list',
    method: 'get'
  });
}

// 知识点目录排序
function changeKnowledgeTreeOrder(data) {
  return (0, _request.webService)({
    url: '/training/catalog/order',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 知识点目录迁移
function moveKnowledgeTreePotion(data) {
  return (0, _request.webService)({
    url: '/training/catalog/migrate',
    method: 'post',
    dataType: 'json',
    data
  });
}

// AI提取知识点上传文件接口
function uploadKnowledgeFile(data, config) {
  return (0, _request.webService)({
    url: '/training/knowledge/abstract/upload',
    method: 'post',
    timeout: 0,
    data,
    ...config
  });
}