"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TextJoint = _interopRequireDefault(require("../TextJoint.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CardHeader',
  components: {
    TextJoint: _TextJoint.default
  },
  props: {
    renderInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  methods: {}
};
exports.default = _default;