"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-conversation"
  }, [_c("el-button", {
    staticClass: "mg-button",
    attrs: {
      icon: "el-icon-upload2",
      type: "primary",
      "data-v-step": "upload-conversation-btn"
    },
    on: {
      click: function ($event) {
        _setup.uploadConversationDialogVisible = true;
      }
    }
  }, [_vm._v(" 上传会话")]), _setup.uploadConversationDialogVisible ? _c(_setup.UploadConversationDialog, {
    attrs: {
      "dialog-visible": _setup.uploadConversationDialogVisible
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.uploadConversationDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.uploadConversationDialogVisible = $event;
      }
    }
  }) : _vm._e(), _c("MgvTour", {
    ref: "uploadConversationTourRef",
    attrs: {
      name: "isShowUploadConversationGuide",
      steps: _setup.uploadConversationGuideSteps
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("video", {
          staticClass: "batch-media-tour-video",
          attrs: {
            autoplay: "",
            loop: "",
            muted: ""
          },
          domProps: {
            muted: true
          }
        }, [_c("source", {
          attrs: {
            src: require("@/assets/batch-upload-media.mp4"),
            type: "video/mp4"
          }
        })]), _c("div", {
          staticClass: "tour-text"
        }, [_vm._v("会话可以批量上传啦！")])];
      },
      proxy: true
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;