"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
const state = {
  contactDynamicFormDescList: null // 获取联系人动态表单
};

const mutations = {
  SET_CONTACT_DYNAMIC_FORM_DESC_LIST: (state, val) => {
    state.contactDynamicFormDescList = val;
  }
};
const actions = {
  async getContactDynamicForm(_ref) {
    let {
      commit,
      state
    } = _ref;
    const res = await (0, _client.getContactDynamicForm)({
      type: "contact",
      has_account: 1
    });
    commit('SET_CONTACT_DYNAMIC_FORM_DESC_LIST', res.results);
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;