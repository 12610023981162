"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dragableOptions: {
        handle: '.move',
        // 必须加这个class，控制触发拖拽的区域，整行、拖拽按钮均可
        animation: 200,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'dragItem',
        forceFallback: true,
        scrollSensitivity: '80',
        touchStartThreshold: '10',
        fallbackTolerance: '5'
      }
    };
  },
  computed: {
    getDragableOptions() {
      return {
        ...this.dragableOptions,
        ...this.$attrs
      };
    }
  },
  methods: {
    dragInput(newList) {
      this.$emit('input', newList);
    },
    start(evt) {
      this.$emit('start', evt);
    },
    end(evt) {
      this.$emit('end', evt);
    },
    handleChange(evt) {
      this.$emit('change', evt);
    }
  }
};
exports.default = _default;