"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.data.length ? _c("el-button", {
    staticClass: "export-table-button",
    on: {
      click: _vm.exportXlsx
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]) : _vm._e(), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "dealStage",
      label: "交易阶段"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticClass: "stage-tag"
        }, [_vm._v(" " + _vm._s(scope.row.dealStage) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "dealNumber",
      label: "交易数"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "dealAmount",
      label: "交易金额"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "winRate",
      label: "预测赢单率"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "predictAmount",
      label: "预测金额"
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;