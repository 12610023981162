"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "library",
    staticClass: "question-tricks-library"
  }, [_c("div", {
    staticClass: "title-part"
  }, [_c("span", [_vm._v("销售回答")]), _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: _vm.closeLibrary
    }
  })]), _c("div", {
    staticClass: "main-content"
  }, [_c("tricks-library-filter", {
    attrs: {
      layout: _vm.layout
    },
    on: {
      filtersChange: _vm.handleFiltersChange
    }
  }), _c("div", {
    staticClass: "date-sort"
  }, [_c("sort-caret", {
    attrs: {
      label: "时间"
    },
    on: {
      sortCallBack: _vm.sortDate
    }
  }), _vm.showAddBtn ? _c("el-button", {
    staticClass: "batch-add-btn mg-round-button",
    attrs: {
      slot: "suffix",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addMultipleQas
    },
    slot: "suffix"
  }, [_vm._v("批量添加")]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conv-list"
  }, [_c("mg-infinite-scroll", {
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore,
      distance: 200,
      "nomore-text": "没有更多内容"
    },
    on: {
      loadMore: _vm.getQaList
    }
  }, _vm._l(_vm.qaList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "card-container"
    }, [_c("el-checkbox", {
      on: {
        change: function ($event) {
          return _vm.handleCheckCard($event, item.id);
        }
      },
      model: {
        value: item.checked,
        callback: function ($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }), _c("tricks-library-conv-card", {
      attrs: {
        "conv-item": item,
        "added-list": _vm.addedList
      },
      on: {
        addConv: function ($event) {
          return _vm.addSingleQa(index);
        }
      }
    })], 1);
  }), 0)], 1)], 1), _c("create-knowledge-drawer", {
    ref: "drawer",
    attrs: {
      visible: _vm.drawerVisible,
      title: "添加到知识库",
      "is-hidden-type": "",
      "extra-submit-params": {
        q_id: _vm.currentQuestion.id,
        qa_ids: _vm.qaIds
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      },
      createSuccess: _vm.handleCreateKnowledgeSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;