"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SpaceLabel = _interopRequireDefault(require("./SpaceLabel.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskInfo',
  components: {
    SpaceLabel: _SpaceLabel.default
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getReminderLabel() {
      const {
        alert_label
      } = this.taskInfo;
      const i18nLabel = this.$t(`coaching.${alert_label || '不提醒'}`);
      return i18nLabel.includes('coaching.') ? alert_label : i18nLabel;
    }
  },
  methods: {
    plus8ToCurrentZone(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    }
  }
};
exports.default = _default;