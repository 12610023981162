"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-detail-info"
  }, [!_vm.isStageReadonly ? _c("div", {
    staticClass: "header"
  }, [!_vm.isDealEdit && _vm.business.has_edit ? _c("div", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/deal/deal/update"],
      expression: "['/api/deal/deal/update']"
    }],
    staticClass: "edit-client",
    on: {
      click: _vm.enterEdit
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "edit-outline"
    }
  }), _c("span", [_vm._v("编辑")])], 1) : _vm._e(), _vm.isDealEdit ? _c("div", {
    staticClass: "client-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.handleCancelClick
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")])], 1) : _vm._e()]) : _vm._e(), _c("dynamic-form", {
    ref: "form",
    attrs: {
      "is-edit": _vm.isDealEdit,
      desc: _vm.formDesc,
      value: _vm.value
    },
    on: {
      input: _vm.handleInput
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;