"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "knowledge-library-card-view"
  }, _vm._l(_vm.cardList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "card-item",
      on: {
        click: function ($event) {
          return _setup.emit("goDetail", item);
        }
      }
    }, [_c("div", {
      staticClass: "card-header"
    }, [_c("div", {
      staticClass: "header-left-content"
    }, [_c("div", {
      staticClass: "title-content"
    }, [_c("i", {
      staticClass: "iconfont icon-knowledge-base"
    }), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        content: item.name,
        placement: "top"
      }
    }, [_c("h3", [_vm._v(_vm._s(item.name))])]), _c("el-tag", {
      attrs: {
        size: "mini",
        type: item.public_scope ? "" : "success"
      }
    }, [_vm._v(_vm._s(item.public_scope ? "公开" : "成员可见"))])], 1), _c("el-tooltip", {
      directives: [{
        name: "mg-tooltip-auto-show",
        rawName: "v-mg-tooltip-auto-show"
      }],
      attrs: {
        content: item.profile,
        placement: "top"
      }
    }, [_c("p", {
      staticClass: "profile"
    }, [_vm._v(_vm._s(item.profile || "-"))])])], 1), _c(_setup.MoreOptionDropdown, {
      staticClass: "header-right-dropdown",
      attrs: {
        "option-list": _setup.getDropdownConfigs(item)
      },
      on: {
        command: function ($event) {
          return _setup.emit("handleCommand", item, $event);
        }
      }
    })], 1), _c("img", {
      staticClass: "knowledge-library-img",
      attrs: {
        src: require("@/assets/coach/konwledgeLibraryCard.png")
      }
    })]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;