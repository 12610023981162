"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-detail"
  }, [_c("detail-header", {
    attrs: {
      title: _vm.dealObject.deal_name,
      "back-button": _vm.backButton
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function () {
        return [_vm.dealObject.workspace ? _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "所属空间",
            placement: "top"
          }
        }, [_c("div", {
          staticClass: "deal-workspace"
        }, [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: _vm.dealObject.workspace.icon_color,
            "margin-right": "4px"
          }
        }), _c("span", [_vm._v(_vm._s(_vm.dealObject.workspace.name))])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header_left_bottom",
      fn: function () {
        return [_c("div", {
          staticClass: "top-center"
        }, [_vm.isStagePermission && !_vm.isStageReadonly ? _c("deal-stage-line", {
          attrs: {
            stage: _vm.stageValue,
            "can-click": _vm.isStagePermission
          },
          on: {
            submit: _vm.changeStage
          }
        }) : _c("progress-tags", {
          attrs: {
            "progress-obj": _vm.progressObj
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_c("div", {
          staticClass: "right-btn"
        }, [_c("deal-operate-button", {
          staticClass: "operate-btn",
          attrs: {
            "main-part": "",
            "operate-buttons": _vm.slotOperateButtons,
            "row-info": _vm.business,
            "active-id": _vm.dealId
          },
          on: {
            clickContactPersonItem: _vm.clickContactPersonItem
          }
        }), _c("el-button", {
          staticClass: "mg-button info-btn",
          attrs: {
            disabled: !Object.keys(_vm.business).length,
            plain: ""
          },
          on: {
            click: _vm.openDealDrawer
          }
        }, [_vm._v("查看详情")])], 1)];
      },
      proxy: true
    }])
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "main-part"
  }, [_c("div", {
    staticClass: "calendar-directory",
    class: {
      "calender-fold": _vm.isCalenderFold
    }
  }, [_c("busi-calender-directoty", {
    attrs: {
      "is-calender-fold": _vm.isCalenderFold,
      "deal-object": _vm.dealObject,
      "expand-persons": ""
    },
    on: {
      "update:isCalenderFold": function ($event) {
        _vm.isCalenderFold = $event;
      },
      "update:is-calender-fold": function ($event) {
        _vm.isCalenderFold = $event;
      }
    }
  })], 1), _c("div", {
    staticClass: "flex-container",
    class: {
      "fold-height": _vm.isCalenderFold
    }
  }, [_c("div", {
    staticClass: "timeline-container"
  }, [_vm.timelineArray && _vm.timelineArray.length > 0 ? [_c("div", {
    staticClass: "left-list"
  }, [_c("items-flow", {
    attrs: {
      "timeline-array": _vm.timelineArray,
      "customer-name": _vm.dealObject.customer_name
    }
  })], 1), _c("div", {
    staticClass: "center-detail"
  }, [_c("item-detail", {
    attrs: {
      "customer-name": _vm.dealObject.customer_name,
      "detail-obj": _vm.getActiveDetail
    }
  })], 1)] : _vm._e(), !_vm.timelineArray || _vm.timelineArray && _vm.timelineArray.length === 0 ? _c("div", {
    staticClass: "no-content"
  }, [_c("p", {
    staticClass: "no-text"
  }, [_vm._v("暂无会话")])]) : _vm._e()], 2), _c("div", {
    ref: "communicate",
    staticClass: "right-communicate"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      enterable: false,
      content: "评论",
      placement: "left"
    }
  }, [_c("div", {
    staticClass: "chat-wrapper",
    class: {
      "active-bar-item": _vm.rightBarActive === true
    },
    on: {
      click: function ($event) {
        _vm.rightBarActive = !_vm.rightBarActive;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-chat-line-square"
  })])]), _c("div", {
    ref: "rightDialog",
    staticClass: "right-dialog-wrapper",
    class: {
      "right-dialog-visible": _vm.rightBarActive
    }
  }, [_c("div", {
    staticClass: "drawer-title"
  }, [_c("span", {
    staticClass: "drawer-title-name"
  }, [_vm._v(_vm._s(_vm.getRightDialogTitle))]), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: function ($event) {
        _vm.rightBarActive = false;
      }
    }
  })]), _c("div", {
    staticClass: "drawer-content-comments"
  }, [_c("comments-area", {
    ref: "comments",
    attrs: {
      "comment-type": "business"
    }
  })], 1)])], 1)])]), _c("business-allot-dialog", _vm._b({
    on: {
      closeDialog: function ($event) {
        _vm.allotDialogProps.dialogVisible = false;
      },
      renew: _vm.getDealDetail
    }
  }, "business-allot-dialog", _vm.allotDialogProps, false)), _c("deal-detail-drawer", {
    attrs: {
      visible: _vm.dealDrawerVisible,
      "deal-id": _vm.dealId,
      "form-desc": _vm.formDesc,
      business: _vm.business
    },
    on: {
      closeDrawer: function ($event) {
        _vm.dealDrawerVisible = false;
      }
    }
  }), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible,
      type: "deal"
    },
    on: {
      closeDialog: _vm.closeMeetingDialog
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;