"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _AssociateContactForm = _interopRequireDefault(require("@/views/businessDetail/components/AssociateContactForm"));
var _client = require("@/api/client");
var _business = require("@/api/business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateBusinessDialog',
  components: {
    DynamicForm: _DynamicForm.default,
    AssociateContactForm: _AssociateContactForm.default
  },
  props: {
    businessDialogVisible: {
      type: Boolean,
      default: false
    },
    businessStatusOptions: {
      type: Array,
      default: () => []
    },
    formDesc: {
      type: Array,
      default: () => []
    },
    clientDetailDrawerVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      formData: {},
      isEdit: true,
      loading: true,
      account_id: '',
      customer_name: '',
      saveLoading: false,
      isAssociateContact: false,
      contactList: [],
      dealId: ''
    };
  },
  watch: {
    businessDialogVisible(val) {
      if (val) {
        window.addEventListener('keydown', this.handleEnter);
        this.isAssociateContact = true;
      } else {
        window.removeEventListener('keydown', this.handleEnter);
        this.isAssociateContact = false;
      }
    },
    formDesc(val) {
      const initObj = {};
      val.forEach(item => {
        initObj[item.id] = item.default;
      });
      this.formData = initObj;
    },
    account_id(val) {
      this.isAssociateContact = true;
      this.getAllContactPersonList(this.account_id);
      this.initAssociateContactForm();
    },
    isAssociateContact() {
      this.initAssociateContactForm();
    }
  },
  methods: {
    initAssociateContactForm() {
      this.$nextTick(() => {
        this.$refs['associateContactForm'].formData.contact_id = '';
        this.$refs['associateContactForm'].formData.is_main = '';
      });
    },
    async getAllContactPersonList(data) {
      const res = await (0, _client.getAllContactPersonList)(data);
      if (res.code === 200) {
        this.contactList = [];
        for (let i = 0; i < res.results.length; i++) {
          const obj = {
            label: res.results[i].name,
            value: res.results[i].id
          };
          this.contactList.push(obj);
        }
      }
    },
    async associateContact(data) {
      this.saveLoading = true;
      const res = await (0, _business.associateContact)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.$message.success('关联联系人成功');
        this.closeDialog();
      }
    },
    handleEnter(e) {
      if (e.keyCode == 13) {
        this.submitForm();
      }
    },
    resetForm() {
      this.$refs.dynamicForm.resetFields();
    },
    async submitForm() {
      if (!this.$refs.dynamicForm.validate()) {
        return;
      }
      if (this.isAssociateContact && !this.$refs['associateContactForm'].$refs['dynamicForm'].validate()) {
        return;
      }
      Object.prototype.hasOwnProperty.call(this.formData, 'customer_name') && delete this.formData.customer_name;
      await this.postCreateDeal({
        deal: this.formData,
        account_id: this.account_id
      });
      if (this.isAssociateContact) {
        this.$nextTick(() => {
          const contactData = {
            deal_id: this.dealId,
            contact_id: this.$refs['associateContactForm'].formData.contact_id,
            is_main: this.$refs['associateContactForm'].formData.is_main || 0
          };
          this.associateContact(contactData);
        });
      }
    },
    async postCreateDeal(data) {
      this.saveLoading = true;
      const res = await (0, _client.postCreateDeal)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.dealId = res.results.deal_id;
        if (!this.isAssociateContact) {
          this.closeDialog();
        }
        this.$message.success('新建成功');
        if (this.clientDetailDrawerVisible && this.businessDialogVisible) {
          this.$emit('freshCustomerDetail', this.account_id);
        }
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.resetForm();
    }
  }
};
exports.default = _default;