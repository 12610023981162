"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CommentBox = _interopRequireDefault(require("./CommentBox"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    CommentBox: _CommentBox.default
  },
  inject: ['getActiveCommentId'],
  props: {
    unit: {
      type: Object,
      default: () => ({})
    },
    activeId: {
      type: Number,
      default: null
    },
    isOnlyDisplay: {
      type: Boolean,
      default: true
    },
    entityType: {
      type: String,
      default: 'conversation'
    },
    hasTimestamp: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      actived: false,
      UnitUnfold: 0
    };
  },
  computed: {
    buttonInfo() {
      return this.actived ? {
        text: '收起回复',
        icon: 'el-icon-arrow-up'
      } : {
        text: '展开所有回复',
        icon: 'el-icon-arrow-down'
      };
    },
    activeCommentId() {
      return this.getActiveCommentId();
    },
    isActiveCommentIdInUnit() {
      let res = false;
      // eslint-disable-next-line no-prototype-builtins
      if (this.unit.hasOwnProperty('comment_id')) {
        if (this.activeId === this.unit.comment_id) {
          res = true;
        } else {
          if (this.unit.replies.length > 0) {
            if (this.unit.replies.find(this.checkCommentId)) {
              res = true;
            }
          }
        }
      }
      return res;
    }
  },
  watch: {
    // unit: {
    //   handler: function (val) {
    //     this.$nextTick(() => {
    //       if (val.replies.length >= 2) {
    //         const firstBoxDomStr = 'commentBox' + val.replies[0].comment_id;
    //         const secondBoxDomStr = 'commentBox' + val.replies[1].comment_id;
    //         this.UnitfoldHeight =
    //           this.$refs[firstBoxDomStr][0].$el.offsetHeight +
    //           6 +
    //           this.$refs[secondBoxDomStr][0].$el.offsetHeight +
    //           6;
    //       } else if (val.replies.length === 1) {
    //         const firstBoxDomStr = 'commentBox' + val.replies[0].comment_id;
    //         this.UnitfoldHeight =
    //           this.$refs[firstBoxDomStr][0].$el.offsetHeight + 6;
    //       } else {
    //         this.UnitfoldHeight = 0;
    //       }
    //       this.setReplyBoxHeight();
    //     });
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // actived(val) {
    //   const replyBox = this.$refs['reply-container'];
    //   if (replyBox) {
    //     if (val) {
    //       replyBox.style.height = replyBox.scrollHeight + 'px';
    //     } else {
    //       replyBox.style.height = this.UnitfoldHeight + 'px';
    //     }
    //   }
    // },
    // UnitUn(val) {
    //   const replyBox = this.$refs["reply-container"];
    //   if (replyBox && !this.actived) {
    //     //在回复收起的情况下才给高度赋值
    //     replyBox.style.height = val + "px";
    //   }
    // },
    // isActiveCommentIdInUnit: {
    //   handler: function (val) {
    //     if (val) {
    //       this.actived = true;
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    replyComment(comment) {
      this.$emit('replyComment', comment);
    },
    checkCommentId(item) {
      return this.activeCommentId === item.comment_id;
    },
    // setReplyBoxHeight() {
    //   const replyBox = this.$refs['reply-container'];
    //   if (replyBox) {
    //     const childRealHeight = [...replyBox.children].reduce(
    //       (total, item) => total + item.offsetHeight + 6,
    //       0
    //     );
    //     replyBox.style.height = this.actived
    //       ? `${childRealHeight}px`
    //       : `${this.UnitfoldHeight}px`;
    //   }
    // },
    replyChangeEdit(val) {
      this.actived = val;
      // const replyBox = this.$refs['reply-container'];
      // if()
      // if (replyBox) {
      //   if (val) {
      //     this.actived = true;
      //     replyBox.style.height = replyBox.scrollHeight + 'px';
      //   } else {
      //     this.actived = false;
      //     replyBox.style.height = this.UnitfoldHeight + 'px';
      //   }
      // }
    }
  }
};

exports.default = _default;