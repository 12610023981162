"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.folderTree,
      expression: "!folderTree"
    }],
    ref: "folder-tree",
    staticClass: "folder-tree",
    attrs: {
      "icon-class": "iconfont icon-caret-right",
      props: _vm.treeProps,
      data: _vm.virtualTree,
      "node-key": "id",
      "default-expanded-keys": _vm.expandedKeys,
      "highlight-current": "",
      "check-on-click-node": "",
      "expand-on-click-node": false
    },
    on: {
      "!node-click": function ($event) {
        return _vm.handleClick.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data,
          node
        } = _ref;
        return [data.type ? _c("div", {
          staticClass: "folder-item"
        }, [_c("i", {
          staticClass: "iconfont icon-filter"
        }), _c("div", {
          staticClass: "folder-name"
        }, [_vm._v(" " + _vm._s(data.name) + " ")]), _c("span", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("conv-flow-dropdown", {
          attrs: {
            "is-tree": ""
          },
          on: {
            command: function ($event) {
              return _vm.handleConvFlowCommand($event, data);
            }
          }
        })], 1)]) : _c("div", {
          staticClass: "folder-item"
        }, [_c("el-tooltip", {
          attrs: {
            content: "部分成员可见",
            placement: "top",
            disabled: data.is_public
          }
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.getIcon(data, node)
        })]), _c("div", {
          staticClass: "folder-name"
        }, [_vm._v(" " + _vm._s(data.name) + " ")]), _c("div", {
          staticClass: "operate-container",
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm.createable ? _c("add-folder-dropdown", {
          on: {
            create: function ($event) {
              return _vm.$emit("create", $event, data);
            }
          }
        }) : _vm._e(), _vm.editable ? _c("folder-dropdown", {
          attrs: {
            "is-tree": ""
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand($event, data);
            }
          }
        }) : _vm._e()], 1)], 1)];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;