"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateQuestionDialog = _interopRequireDefault(require("./CreateQuestionDialog"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MoveFreqQuestionDialog',
  components: {
    CreateQuestionDialog: _CreateQuestionDialog.default,
    NoData: _NoData.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 当前高频问题
    currentQuestion: {
      type: Object,
      default: () => ({})
    },
    // 当前销售回答问答对
    currentQaItem: {
      type: Object,
      default: () => ({})
    },
    hasFooterOperate: {
      type: Boolean,
      default: false
    },
    operateName: {
      type: String,
      default: '移动'
    }
  },
  data() {
    return {
      filterValue: '',
      loading: false,
      hasMore: false,
      questionList: [],
      qaFilters: {
        page: 1,
        size: 10,
        filter: ''
      },
      createQuestionDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.qaFilters = this.$options.data().qaFilters;
          this.questionList = [];
          this.getAllFreqQuestionList();
        }
      }
    }
  },
  methods: {
    loadMore() {
      if (this.qaFilters.page === 1) return;
      this.getAllFreqQuestionList();
    },
    createNewQuestion() {
      this.createQuestionDialogVisible = true;
    },
    debounceSearchQuestionList: (0, _commonFunc.debounce)(function () {
      this.qaFilters.page = 1;
      this.questionList = [];
      this.getAllFreqQuestionList();
    }),
    // 新建问题并将该回答移入
    async createAndMoveFreqQuestion() {
      this.handleClose();
      this.$emit('refreshQuestionAndQa');
    },
    async moveFreqQuestion(q_id) {
      if (q_id === this.currentQuestion.id) return;
      this.$emit('moveFreqQuestion', q_id);
    },
    async getAllFreqQuestionList() {
      this.loading = true;
      const res = await (0, _businessIntelligence.getFAQQuestionList)({
        ...this.qaFilters,
        status: 'all',
        include_omit: true,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.questionList = this.questionList.concat(res.results.data);
        this.hasMore = res.results.count > this.questionList.length;
        this.qaFilters.page++;
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;