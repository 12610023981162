"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upcoming-meetings"
  }, [_c("h2", [_vm._v(" 即将开始的会议 "), _c("span", {
    class: ["meeting-count", {
      "is-active": _vm.list.length
    }]
  }, [_vm._v(_vm._s(_vm.list.length))])]), _vm.list.length || _vm.loading ? _c("div", _vm._l(_vm.list, function (item) {
    return _c("meeting-item", {
      key: item.id,
      attrs: {
        name: item.name,
        date: item.begin_time,
        "meeting-url": item.meeting_url
      }
    });
  }), 1) : _c("appoint-part")], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;