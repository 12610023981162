"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersTreeSelect = _interopRequireDefault(require("@/components/MgTreeSelect/MembersTreeSelect"));
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _SampleScopeForm = _interopRequireDefault(require("./components/SampleScopeForm"));
var _SampleMethod = _interopRequireDefault(require("./components/SampleMethod"));
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _ComplianceItem = _interopRequireDefault(require("./components/ComplianceItem"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DistributionIndex',
  components: {
    DetailHeader: _DetailHeader.default,
    SampleScopeForm: _SampleScopeForm.default,
    SampleMethod: _SampleMethod.default,
    FormItemCard: _FormItemCard.default,
    MembersTreeSelect: _MembersTreeSelect.default,
    ComplianceItem: _ComplianceItem.default
  },
  data() {
    return {
      localForm: {
        sampleScope: {
          is_all_data: false,
          account_sale: false,
          rule_id: '',
          data_scope: {},
          date: {
            range: [],
            tag: ''
          }
        },
        sampleMethod: {
          method: 'random_n',
          sampleData: {
            random_n: '',
            random_percent: '',
            per_sale: ''
          }
        },
        assignMembers: {
          tree_ids: [],
          user_ids: []
        },
        assignMembersList: [],
        complianceItem: {
          checkedItem: [],
          compliance_score_rule_ids: [],
          evaluation_template_id: ''
        }
      },
      basicInformationForm: {
        name: ''
      },
      sampleConvScopeCount: 0,
      sampleMethodConvCount: 0,
      btnLoading: false,
      idsInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'orgId', 'userId']),
    unit() {
      return !this.localForm.sampleScope.is_all_data && this.localForm.sampleScope.account_sale ? '组' : '条';
    },
    sampleRule() {
      const hasScoreRule = this.localForm.complianceItem.checkedItem.includes('rule') && this.localForm.complianceItem.compliance_score_rule_ids;
      const compliance_score_rule_ids = hasScoreRule ? {
        compliance_score_rule_ids: this.localForm.complianceItem.compliance_score_rule_ids
      } : {};
      if (this.localForm.sampleScope.is_all_data) {
        return {
          ...compliance_score_rule_ids
        };
      } else {
        const date = this.localForm.sampleScope.date.range && this.localForm.sampleScope.date.range.length ? {
          date: this.localForm.sampleScope.date
        } : {};
        return {
          ...this.localForm.sampleScope.data_scope,
          ...date,
          ...compliance_score_rule_ids
        };
      }
    },
    distributionButtonDisabled() {
      return !((this.localForm.assignMembers.tree_ids.length || this.localForm.assignMembers.user_ids.length) && this.sampleMethodConvCount);
    }
  },
  watch: {
    'localForm.sampleScope.is_all_data': {
      handler: function () {
        this.localForm.sampleMethod.sampleData = {
          random_n: '',
          random_percent: '',
          per_sale: ''
        };
        this.$refs.sampleMethod.clearConvList();
      }
    }
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    searchUserFuntion: _organization.getUserList,
    getConvInfo(count, idsInfo) {
      this.sampleMethodConvCount = count;
      this.idsInfo = idsInfo;
    },
    assigneeChange(val, selectionObj) {
      this.localForm.assignMembers = val;
      this.localForm.assignMembersList = selectionObj;
    },
    async assignCompliance(data) {
      this.btnLoading = true;
      try {
        const res = await (0, _compliance.assignCompliance)(data);
        if (res.code === 20000) {
          this.$message.success('新建成功');
          this.$router.push({
            path: _routeMap.ROUTES.complianceTeamTask
          });
          sessionStorage.setItem('newTaskId', res.results.task_id);
        }
      } finally {
        this.btnLoading = false;
      }
    },
    distributeConv() {
      if (!this.localForm.complianceItem.checkedItem.length) {
        this.$message.warning('请至少选中一项质检项目');
        return;
      }
      const valid1 = this.$refs.basicInformationForm.validate();
      const valid2 = this.$refs.ComplianceItem.$refs.complianceItem.validate();
      const valid3 = this.$refs.sampleMethod.$refs.ruleForm.validate();
      Promise.all([valid1, valid2, valid3]).then(() => {
        const {
          is_all_data,
          rule_id,
          date
        } = this.localForm.sampleScope;
        const {
          method,
          sampleData
        } = this.localForm.sampleMethod;
        const {
          checkedItem,
          compliance_score_rule_ids,
          evaluation_template_id
        } = this.localForm.complianceItem;
        const data = {
          assign_members: this.localForm.assignMembers,
          workspace_id: this.workspaceInfo.id,
          account_sale: this.localForm.sampleScope.is_all_data ? false : this.localForm.sampleScope.account_sale,
          ...this.idsInfo,
          name: this.basicInformationForm.name,
          evaluation_template_id: checkedItem.includes('evaluation') ? evaluation_template_id : 0,
          sampling_params: {
            sampling_count_params: {
              is_all: is_all_data,
              sample_rule_id: rule_id || 0,
              date,
              compliance_score_rule_ids: checkedItem.includes('rule') ? compliance_score_rule_ids : []
            },
            sampling_method: {
              method,
              number: Number(sampleData[method]),
              total: this.sampleConvScopeCount
            }
          }
        };
        this.assignCompliance(data);
      }).catch(() => {});
    },
    goBack() {
      this.$router.push({
        path: _routeMap.ROUTES.complianceTeamTask
      });
    }
  }
};
exports.default = _default;