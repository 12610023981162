"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "新建客户",
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog,
      "custom-class": "create-client",
      "append-to-body": "",
      width: "700px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm("createClientForm");
      }
    }
  }, [_c("dynamic-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getFormLoading,
      expression: "getFormLoading"
    }],
    ref: "dynamicForm",
    attrs: {
      desc: _vm.formDesc,
      "is-edit": "",
      "label-position": "top"
    },
    model: {
      value: _vm.createClientForm,
      callback: function ($$v) {
        _vm.createClientForm = $$v;
      },
      expression: "createClientForm"
    }
  }), _c("div", {
    staticClass: "create-contact-checkbox"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.createContact,
      callback: function ($$v) {
        _vm.createContact = $$v;
      },
      expression: "createContact"
    }
  }, [_vm._v("同时创建联系人")])], 1), _vm.createContact ? _c("dynamic-form", {
    ref: "contactDynamicForm",
    attrs: {
      "label-position": "top",
      desc: _vm.contactFormDesc
    },
    model: {
      value: _vm.contactFormData,
      callback: function ($$v) {
        _vm.contactFormData = $$v;
      },
      expression: "contactFormData"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;