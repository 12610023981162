"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$data, _vm$data$;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    staticClass: "valid-trail-data-table",
    attrs: {
      data: _vm.data,
      height: _vm.tableHeight
    },
    on: {
      "selection-change": _setup.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "序号",
      width: "50px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          $index
        } = _ref;
        return [_vm._v(" " + _vm._s(_vm.startIndex + $index) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "sentence",
      label: "模型预测句",
      "min-width": "350"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "sentence-container"
        }, _vm._l(row.sentence, function (item, index) {
          return _c("div", {
            key: index
          }, [_c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: item.content
            }
          }, [_c("div", {
            staticClass: "model-sentence",
            on: {
              click: function ($event) {
                return _setup.onClickModelSentence(row);
              }
            }
          }, [item.entity_type === "host_salesman" ? _c("span", {
            staticClass: "sale-role"
          }, [_vm._v(" 销售：")]) : _c("span", {
            staticClass: "customer-role"
          }, [_vm._v(" 客户：")]), _c("span", [_vm._v(_vm._s(item.content))])])])], 1);
        }), 0)];
      }
    }])
  }), (_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$ = _vm$data[0]) !== null && _vm$data$ !== void 0 && _vm$data$.model_predict_res ? _c("el-table-column", {
    attrs: {
      label: "模型原始结果",
      width: "180px"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180px",
      label: `命中${_vm.datasetStats.model_predict_hit_count}个 未命中${_vm.datasetStats.model_predict_miss_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c(_setup.HitTag, {
          attrs: {
            status: row.model_predict_res
          }
        })];
      }
    }], null, false, 2232663406)
  })], 1) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "模型预测结果"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180px",
      label: `命中${_vm.datasetStats.predict_hit_count}个 未命中${_vm.datasetStats.predict_miss_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c(_setup.HitTag, {
          attrs: {
            status: row.predict_res
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "是否正确"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [row.rule_result === 1 ? _c("MgvTag", {
          attrs: {
            color: "success",
            icon: "iconfont icon-circle-check"
          }
        }, [_vm._v("正确")]) : _c("MgvTag", {
          attrs: {
            color: "error",
            icon: "iconfont icon-circle-close"
          }
        }, [_vm._v("错误")])];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "测试集标注结果",
      fixed: "right",
      width: "350px"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "350px",
      label: `命中${_vm.datasetStats.annotate_hit_count}个 未命中${_vm.datasetStats.annotate_miss_count}个 不确定${_vm.datasetStats.annotate_uncertain_count}个 未标注${_vm.datasetStats.un_annotate_count}个`
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c(_setup.HitButtons, {
          attrs: {
            status: row.status
          },
          on: {
            change: function ($event) {
              return _setup.annotateMark($event, row);
            }
          }
        }), _c("el-tooltip", {
          attrs: {
            content: "清空标注结果",
            placement: "top"
          }
        }, [_c("MgvButton", {
          staticClass: "delete-annotate-btn",
          attrs: {
            icon: "icon-clear",
            square: "",
            color: "info",
            type: "text",
            size: "medium"
          },
          on: {
            click: function ($event) {
              return _setup.clearAnnotationResult(row);
            }
          }
        })], 1), row.status !== row.predict_res ? _c("MgvButton", {
          staticClass: "fix-train-data-btn",
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function ($event) {
              return _setup.handleFixTrainData(row);
            }
          }
        }, [_vm._v("修正训练集")]) : _vm._e()];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;