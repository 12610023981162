"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetailAccountTag',
  components: {
    EventsList: _eventsList.default,
    KeyEventDetailList: _keyEventDetailList.default,
    NoData: _NoData.default
  },
  props: {
    convId: {
      type: [Number, String],
      default: 0
    },
    mediaType: {
      type: String,
      default: ''
    },
    participantsInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      accountTagList: [],
      activeAccountTagId: 0,
      activeAccountTagName: '',
      accountTagCount: 0,
      id2AccountTagMap: new Map(),
      activeAccountTagData: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    activeAccountTag() {
      return this.id2AccountTagMap.get(this.activeAccountTagId + this.activeAccountTagName) || {};
    }
  },
  watch: {
    convId(val) {
      this.activeAccountTagId = 0;
      this.getAccountTag();
    }
  },
  async created() {
    await this.getAccountTag();
  },
  methods: {
    async getAccountTag() {
      this.loading = true;
      const res = await (0, _videoDetail.getAccountTag)({
        conversation_id: parseInt(this.convId),
        workspace_id: this.workspaceInfo.id
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.accountTagList = res.results.tags;
        this.accountTagCount = res.results.total;
        this.setId2AccountTagMap(this.accountTagList);
      }
    },
    setId2AccountTagMap(accountTags) {
      for (const accountTag of accountTags) {
        if (accountTag.children) {
          this.setId2AccountTagMap(accountTag.children);
        } else {
          const {
            id,
            ...ever
          } = accountTag;
          this.id2AccountTagMap.set(id + ever.name, ever);
        }
      }
    },
    async getAccountTagDetail(tagName) {
      this.loading = true;
      const res = await (0, _videoDetail.getAccountTagDetail)({
        conversation_id: parseInt(this.convId),
        tag_id: this.activeAccountTagId,
        tag_value: tagName
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.activeAccountTagData = res.results.datas;
      }
    },
    handleTagClick(id, val) {
      this.activeAccountTagId = id;
      this.activeAccountTagName = val.name;
      this.getAccountTagDetail(val.name);
    },
    handleBackUp() {
      this.activeAccountTagId = 0;
    }
  }
};
exports.default = _default;