"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useEvaluateForm = require("../hooks/use-evaluate-form");
var _vue = require("vue");
var _default = {
  __name: 'FormItemTypeTag',
  props: {
    fieldTypeId: {
      type: Number,
      default: 1
    },
    isEditStatus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:fieldTypeId'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const optionFieldIds = [3, 4];
    const field = (0, _vue.computed)(() => (0, _useEvaluateForm.getField)(props.fieldTypeId));
    const isOptionField = (0, _vue.computed)(() => optionFieldIds.includes(props.fieldTypeId));
    const handleCommand = id => {
      emit('update:fieldTypeId', id);
    };
    return {
      __sfc: true,
      props,
      optionFieldIds,
      field,
      isOptionField,
      emit,
      handleCommand,
      getField: _useEvaluateForm.getField
    };
  }
};
exports.default = _default;