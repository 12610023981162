"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login",
    class: {
      fadeout: !_vm.wrapperShow,
      "fade-in": _vm.wrapperShow
    },
    on: {
      mouseup: _vm.handleSSOError
    }
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode,
      expression: "currentMode"
    }],
    staticClass: "left-back",
    on: {
      click: _vm.goFirstStep
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  }), _c("span", [_vm._v("返回")])]), _c("div", {
    staticClass: "center-title"
  }, [_vm._v(_vm._s(_vm.getLoginTitle()))])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "equalInsurance" || _vm.needResetPassword,
      expression: "currentMode === 'equalInsurance' || needResetPassword"
    }],
    staticClass: "equal-insurance-info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm.needResetPassword ? _c("span", [_vm._v("为了您的账号安全，建议您定期更换密码")]) : _c("span", [_vm._v("为保障您的账号安全，请使用"), _c("b", [_vm._v("密码")]), _vm._v("及"), _c("b", [_vm._v("验证码")]), _vm._v("登录")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.needResetPassword,
      expression: "needResetPassword"
    }],
    staticClass: "reset-password-buttons"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goReset
    }
  }, [_vm._v("重置密码")]), _c("el-button", {
    on: {
      click: _vm.skip
    }
  }, [_vm._v("跳过")])], 1), _c("el-tabs", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.needResetPassword && !_vm.currentMode,
      expression: "!needResetPassword && !currentMode"
    }],
    on: {
      "tab-click": _vm.handleChangeLoginMethod
    },
    model: {
      value: _vm.loginMethod,
      callback: function ($$v) {
        _vm.loginMethod = $$v;
      },
      expression: "loginMethod"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "手机号",
      name: "tel"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "邮箱",
      name: "email"
    }
  })], 1), _c("el-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.needResetPassword && !_vm.currentMode,
      expression: "!needResetPassword && !currentMode"
    }],
    ref: "telForm",
    staticClass: "login-form form",
    attrs: {
      model: _vm.telForm,
      rules: _vm.loginRules,
      "hide-required-asterisk": "",
      "validate-on-rule-change": false
    }
  }, [_vm.loginMethod === "tel" ? _c("el-form-item", {
    attrs: {
      prop: "tel"
    }
  }, [_c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: "请输入手机号",
      type: "tel",
      tabindex: "1",
      name: "username",
      autocomplete: "on",
      maxlength: "11"
    },
    on: {
      focus: _vm.handleFocus
    },
    model: {
      value: _vm.telForm.tel,
      callback: function ($$v) {
        _vm.$set(_vm.telForm, "tel", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "telForm.tel"
    }
  })], 1) : _vm._e(), _vm.loginMethod === "email" ? _c("el-form-item", {
    attrs: {
      prop: "email"
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      placeholder: "请输入邮箱",
      type: "email",
      tabindex: "1",
      name: "username",
      autocomplete: "on"
    },
    on: {
      focus: _vm.handleFocus
    },
    model: {
      value: _vm.telForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.telForm, "email", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "telForm.email"
    }
  })], 1) : _vm._e(), _c("el-button", {
    staticClass: "handle-btn",
    attrs: {
      id: "tencentCaptchaPassword",
      loading: _vm.passLoading,
      type: "primary",
      "native-type": "submit",
      disabled: !_vm.readChecked,
      "data-appid": "2018197489",
      "data-cbfn": "tencentVerifyCallback"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.humanMachineCheck.apply(null, arguments);
      }
    }
  }, [_vm._v("下一步")])], 1), _c("el-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.needResetPassword,
      expression: "!needResetPassword"
    }],
    ref: "loginForm",
    staticClass: "login-form form",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      "hide-required-asterisk": "",
      "validate-on-rule-change": false
    }
  }, [_c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "password" || _vm.currentMode === "equalInsurance",
      expression: "currentMode === 'password' || currentMode === 'equalInsurance'"
    }],
    attrs: {
      prop: "password"
    }
  }, [_c("password", {
    ref: "password",
    attrs: {
      placeholder: "请输入密码",
      tabindex: "2"
    },
    on: {
      enter: _vm.postPasswordLoginRequest
    },
    model: {
      value: _vm.loginForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "loginForm.password"
    }
  })], 1), _vm.currentMode === "equalInsurance" ? _c("el-form-item", {
    staticClass: "send-code",
    attrs: {
      prop: "code"
    }
  }, [_c("verification-code-input", {
    ref: "codeInput",
    attrs: {
      "code-type": "login",
      tel: _vm.loginForm.tel,
      email: _vm.loginForm.email,
      "sending-destination": _vm.loginMethod,
      "max-length": "6",
      "no-slider": "",
      "is-direct-send": ""
    },
    model: {
      value: _vm.loginForm.code,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "loginForm.code"
    }
  })], 1) : _vm._e(), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "password" || _vm.currentMode === "equalInsurance",
      expression: "currentMode === 'password' || currentMode === 'equalInsurance'"
    }],
    staticClass: "handle-btn",
    attrs: {
      loading: _vm.passLoading,
      type: "primary",
      "native-type": "submit",
      disabled: !_vm.loginForm.password
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.postPasswordLoginRequest.apply(null, arguments);
      }
    }
  }, [_vm._v("登录")])], 1), _c("el-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "code" && !_vm.needResetPassword,
      expression: "currentMode === 'code' && !needResetPassword"
    }],
    ref: "codeForm",
    staticClass: "code-form form",
    attrs: {
      model: _vm.codeForm,
      rules: _vm.codeRules,
      autocomplete: "off",
      "label-position": "top",
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      prop: "tel"
    }
  }, [_c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: "请输入手机号",
      type: "hidden",
      tabindex: "1",
      name: "tel",
      autocomplete: "on",
      oninput: "value=value.replace(/[^\\d]/g,''); if(value.length>11)value=value.slice(0,11)"
    },
    model: {
      value: _vm.codeForm.tel,
      callback: function ($$v) {
        _vm.$set(_vm.codeForm, "tel", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "codeForm.tel"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "code"
    }
  }, [_c("verification-code-input", {
    ref: "codeInput",
    attrs: {
      "code-type": "login",
      tel: _vm.codeForm.tel,
      email: _vm.codeForm.email,
      "sending-destination": _vm.loginMethod,
      "is-button-outside": true,
      "max-length": "6",
      "no-slider": "",
      "is-direct-send": ""
    },
    model: {
      value: _vm.codeForm.code,
      callback: function ($$v) {
        _vm.$set(_vm.codeForm, "code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "codeForm.code"
    }
  })], 1), _c("el-button", {
    staticClass: "handle-btn",
    attrs: {
      loading: _vm.codeLoading,
      type: "primary",
      "native-type": "submit",
      disabled: !_vm.codeForm.code
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleCodeLogin.apply(null, arguments);
      }
    }
  }, [_vm._v("登录")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.google && _vm.currentMode === "" && !_vm.needResetPassword,
      expression: "google && currentMode === '' && !needResetPassword"
    }]
  }, [_c("div", {
    staticClass: "divider"
  }, [_vm._v("更多方式")]), _c("div", {
    attrs: {
      id: "google-login-btn",
      standard: ""
    }
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.needResetPassword,
      expression: "!needResetPassword"
    }],
    staticClass: "foot-tip"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.currentMode,
      expression: "!currentMode"
    }],
    staticClass: "register-tip"
  }, [_c("div", {
    staticClass: "read-protocol"
  }, [_c("div", {
    staticClass: "read-protocol-text"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.readChecked,
      callback: function ($$v) {
        _vm.readChecked = $$v;
      },
      expression: "readChecked"
    }
  }), _c("span", {
    staticClass: "read-text"
  }, [_vm._v("我已阅读并同意")]), _c("a", {
    staticClass: "protocol-text",
    attrs: {
      href: _vm.$config.serviceAgreementUrl,
      target: "_blank"
    }
  }, [_vm._v("  服务协议 ")]), _c("span", {
    staticClass: "read-text"
  }, [_vm._v("和")]), _c("a", {
    staticClass: "protocol-text",
    attrs: {
      href: _vm.$config.privacyPolicyUrl,
      target: "_blank"
    }
  }, [_vm._v(" 隐私政策  ")])], 1)])]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "password",
      expression: "currentMode === 'password'"
    }],
    staticClass: "code-tip",
    attrs: {
      id: "tencentCaptchaCode",
      type: "text"
    },
    on: {
      click: _vm.openCodeMode
    }
  }, [_vm._v("输入验证码")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "password" || _vm.currentMode === "equalInsurance",
      expression: "currentMode === 'password' || currentMode === 'equalInsurance'"
    }],
    staticClass: "forget-tip",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.goReset
    }
  }, [_vm._v("忘记密码")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentMode === "code",
      expression: "currentMode === 'code'"
    }],
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.openPasswordMode
    }
  }, [_vm._v("密码登录")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;