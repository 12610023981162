"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleHighLight = handleHighLight;
function mapToArr(map) {
  const list = [];
  for (const key of map) {
    const begin = key[0];
    const end = key[1];
    list.push({
      begin,
      end
    });
  }
  return list;
}
function mergeArr(arr) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i].end === arr[j].begin) {
        arr[i].end = arr[j].end;
        arr.splice(j--, 1);
      }
    }
  }
  return arr;
}
function handleHighLight(contents) {
  let {
    content
  } = contents;
  const {
    keywords
  } = contents;
  const highlightMap = new Map();
  const contentArr = content.split('');
  const punctuationArr = ['，', '。', '？'];
  // 通过关键词知道这个关键词对应的句子的 begin 和 end,以 begin 为 key,end 为 value,如果出现相同的 key,取 value 较大的那个
  for (let i = 0; i < keywords.length; i++) {
    const {
      begin_pos,
      end_pos
    } = keywords[i];
    let begin = 0;
    let end = 0;
    for (let j = begin_pos; j >= 0; j--) {
      if (punctuationArr.includes(contentArr[j])) {
        begin = j;
        break;
      } else {
        begin = 0;
      }
    }
    for (let j = end_pos; j < contentArr.length; j++) {
      if (punctuationArr.includes(contentArr[j])) {
        end = j;
        break;
      } else {
        end = contentArr.length - 1;
      }
    }
    if (highlightMap.has(begin)) {
      highlightMap.get(begin) > end ? highlightMap : highlightMap.set(begin, end);
    } else {
      highlightMap.set(begin, end);
    }
  }
  // 将 Map 转换为 Array
  let highlightArr = mapToArr(highlightMap);
  // 对转换后的数组按照 begin 正序排序
  highlightArr.sort((a, b) => a.begin - b.begin);
  // 将转换后的数组进行合并 比如 [{0,2},{2,4}] ——> [{0,4}]
  highlightArr = mergeArr(highlightArr);
  // 对转换后的数组按照 begin 倒序排序
  highlightArr.sort((a, b) => b.begin - a.begin);
  // 遍历转换后的数组，对句子相应的位置进行替换，添加样式
  for (let i = 0; i < highlightArr.length; i++) {
    const {
      begin,
      end
    } = highlightArr[i];
    // 判断 begin 是否是一句话的开头，如果是的话就从 0 开始，如果不是的话，需要 +1，去掉前面的标点符号
    // end + 1 是选中后面的标点符号，如果不想，可以不加
    const sentence = content.slice(begin === 0 ? begin : begin + 1, end + 1);
    content = content.replace(sentence, `<span class='high-light'>${sentence}</span>`);
  }

  // for (let i = 0; i < keywords.length; i++) {
  //   const keyword = keywords[i];
  //   if (content.includes(keyword.word)) {
  //     content = content.replace(
  //       keyword.word,
  //       `<span class="underline">${keyword.word}</span>`
  //     );
  //   }
  // }
  return content;
}