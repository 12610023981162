"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-info"
  }, [_c("el-form", {
    ref: "userInfo",
    staticClass: "account-form",
    attrs: {
      "label-width": "140px",
      model: _vm.userInfo,
      rules: _vm.userInfoRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("management.姓名"),
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "32",
      placeholder: "请输入姓名"
    },
    model: {
      value: _vm.userInfo.name,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "userInfo.name"
    }
  })], 1), _c("el-form-item", {
    staticClass: "form-item-upload",
    attrs: {
      label: _vm.$t("management.头像")
    }
  }, [_c("el-upload", {
    staticClass: "avatar-upload",
    attrs: {
      action: "#",
      "show-file-list": false,
      "http-request": _vm.uploadAvatar,
      accept: "image/jpeg, image/jpg, image/png",
      "on-success": _vm.handleAvatarSuccess,
      "before-upload": _vm.beforeAvatarUpload
    }
  }, [_c("image-avatar", {
    attrs: {
      avatar: _vm.userInfo.avatar,
      name: _vm.userInfo.name
    }
  }), _c("div", {
    staticClass: "upload-text-box"
  }, [_c("el-link", {
    attrs: {
      size: "small",
      type: "primary",
      underline: false
    }
  }, [_vm._v(_vm._s(_vm.$t("management.上传")) + " ")]), _c("p", {
    staticClass: "el-upload-tip"
  }, [_vm._v(" " + _vm._s(_vm.$t("management.支持jpg、jpeg、png格式小于200KB")) + " ")])], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.工作邮箱"),
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("management.请输入邮箱")
    },
    model: {
      value: _vm.userInfo.email,
      callback: function ($$v) {
        _vm.$set(_vm.userInfo, "email", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "userInfo.email"
    }
  }), _c("span", {
    staticClass: "email-info"
  }, [_vm._v(_vm._s(_vm.$t("management.工作邮箱仅为个人信息项，不是登录账号")))])], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("management.主部门")
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.userInfo.organization.main_tree_name))])]), _c("el-form-item", {
    staticClass: "form-item-submit"
  }, [_c("el-button", {
    staticClass: "mg-button",
    attrs: {
      loading: _vm.loading,
      type: "primary",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.保存修改")) + " ")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;