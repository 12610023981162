"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("dynamic-form", {
    ref: "dynamicForm",
    attrs: {
      desc: _vm.formDesc,
      "label-position": "top"
    },
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;