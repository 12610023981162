"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-new-member"
  }, [_c("el-dialog", {
    attrs: {
      title: "添加新成员",
      visible: _vm.visible,
      "before-close": _vm.closeSelf
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addPerson.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("p", [_vm._v(" 登录账号是用户登录的唯一登录方式，初始密码会在添加成功之后自动生成，建议首次登录后前往个人中心 - 登录账号修改初始密码。 ")])]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "tel-input-content"
  }, [_c("el-form", {
    ref: "addPersonForm",
    staticClass: "invite-form",
    attrs: {
      model: _vm.addPersonForm,
      rules: _vm.rules
    }
  }, [_c("div", {
    staticClass: "invite-form-line"
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      rules: _vm.rules.name
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" 姓名 "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-input", {
    ref: "name",
    attrs: {
      type: "name",
      placeholder: "请输入姓名"
    },
    model: {
      value: _vm.addPersonForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.addPersonForm, "name", $$v);
      },
      expression: "addPersonForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "login_account",
      rules: _vm.rules.login_account
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" 登录账号 "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-input", {
    ref: "login_account",
    attrs: {
      maxlength: "20",
      placeholder: "请输入登录账号"
    },
    model: {
      value: _vm.addPersonForm.login_account,
      callback: function ($$v) {
        _vm.$set(_vm.addPersonForm, "login_account", $$v);
      },
      expression: "addPersonForm.login_account"
    }
  })], 1)], 1), _c("div", {
    staticClass: "invite-form-line"
  }, [_c("el-form-item", {
    attrs: {
      prop: "departmentName",
      rules: _vm.rules.departmentName
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" 主部门 "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-select", {
    ref: "tree-select",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "popper-class": "tree-select",
      loading: _vm.departmentLoading
    },
    model: {
      value: _vm.addPersonForm.departmentName,
      callback: function ($$v) {
        _vm.$set(_vm.addPersonForm, "departmentName", $$v);
      },
      expression: "addPersonForm.departmentName"
    }
  }, [_c("el-option", {
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: _vm.departments,
      disabled: _vm.orgTree.length == 0
    }
  }, [_c("el-tree", {
    ref: "department-tree",
    staticClass: "department-radio-tree",
    staticStyle: {
      width: "100%",
      "pointer-events": "auto"
    },
    attrs: {
      data: _vm.getTree,
      "node-key": "id",
      "highlight-current": "",
      "expand-on-click-node": false,
      "check-strictly": "",
      props: _vm.defaultProps,
      "default-expanded-keys": _vm.defaultExpandedKeys
    },
    on: {
      "current-change": _vm.handleCurrentChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return _c("div", {
          staticClass: "custom-tree-node"
        }, [data.node_type === "root" ? _c("svg-icon", {
          attrs: {
            "icon-class": "office-building"
          }
        }) : _c("svg-icon", {
          attrs: {
            "icon-class": "department"
          }
        }), [_vm._v(" " + _vm._s(data.name) + " ")]], 2);
      }
    }])
  })], 1)], 1)], 1), _vm.hasWorkspace ? _c("el-form-item", {
    attrs: {
      prop: "workspaceId",
      rules: _vm.rules.workspaceId
    }
  }, [_c("label", {
    staticClass: "step-label"
  }, [_vm._v(" 主空间 "), _c("span", {
    staticClass: "required-sign"
  }, [_vm._v("*")])]), _c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "popper-class": "workspace-selector",
      placeholder: "请选择主空间"
    },
    model: {
      value: _vm.addPersonForm.workspaceId,
      callback: function ($$v) {
        _vm.$set(_vm.addPersonForm, "workspaceId", $$v);
      },
      expression: "addPersonForm.workspaceId"
    }
  }, _vm._l(_vm.workspaceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color,
        "margin-right": "4px"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1) : _vm._e()], 1)])], 1)]), _c("div", {
    staticClass: "dialog-footer",
    staticStyle: {
      "margin-top": "22px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeSelf
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.inviteBtnLoading
    },
    on: {
      click: _vm.addPerson
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;