"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentSelector = _interopRequireDefault(require("@/components/DepartmentSelector"));
var _organization = require("@/api/organization");
var _permissions = require("@/api/permissions");
var _vuex = require("vuex");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _AddItem = _interopRequireDefault(require("@/components/AddItem"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BatchModify',
  components: {
    MembersFinder: _MembersFinder.default,
    DepartmentSelect: _DepartmentSelector.default,
    AddItem: _AddItem.default
  },
  props: {
    userIds: {
      type: Array,
      default: () => []
    },
    userMultipleArray: {
      type: Array,
      default: () => []
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    inviteText: {
      type: String,
      default: ''
    },
    orgId: {
      type: Number,
      default: null
    },
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const checkDept = (rule, value, callback) => {
      const params = JSON.parse(rule.field);
      if (!params[0]) {
        return callback(new Error(this.$t('management.请选择要修改的部门')));
      }
      callback();
    };
    const checkWorkspace = (rule, value, callback) => {
      const params = JSON.parse(rule.field);
      if (!params[0]) {
        return callback(new Error(this.$t('management.请选择要修改的空间')));
      }
      callback();
    };
    return {
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      batchModifyForm: {
        ids: [],
        // 需要迁移的所有ids
        batchUserIds: [],
        // 需要迁移的用户组
        batchDeptIds: [],
        // 需要迁移的部门组
        departments: '' // 迁往的部门ID
      },

      rules: {
        ids: [{
          required: true,
          message: this.$t('management.请选择成员'),
          trigger: 'change'
        }],
        departments: [{
          required: true,
          message: this.$t('management.请选择部门'),
          trigger: 'change'
        }]
      },
      checkDept: {
        required: true,
        validator: checkDept,
        trigger: 'change'
      },
      checkWorkspace: {
        required: true,
        validator: checkWorkspace,
        trigger: 'change'
      },
      disabledArr: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultExpandedKeys: [],
      orgTree: [],
      departmentLoading: false,
      modifyBtnLoading: false,
      chooseDepartment: false,
      chooseWorkspace: false,
      departmentList: [],
      workspaceInfoGroup: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['hasWorkspace']),
    getTree() {
      return this.orgTree.filter(item => item.node_type !== 'unassigned');
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.batchModifyForm.ids = this.userIds;
          this.batchModifyForm.batchUserIds = this.userIds;
          this.$refs['membersfinder'].selection = this.userIds;
          this.$refs['membersfinder'].memberOptions = this.userMultipleArray;
        });
        this.localWorkspaceList = this.workspaceList;
        this.departmentList.push({
          department: '',
          id: 0
        });
        this.workspaceInfoGroup.push({
          workspace: '',
          id: 0
        });
        this.fetchDepartmentData();
      }
    },
    departmentList: {
      handler(val) {
        this.disabledArr = val.map(item => item.department);
      },
      deep: true
    },
    workspaceInfoGroup: {
      handler(val) {
        const disabledArr = val.map(item => item.workspace);
        this.localWorkspaceList.forEach(item => {
          if (disabledArr.includes(item.id)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      },
      deep: true
    }
  },
  methods: {
    clearData() {
      this.departmentList = [];
      this.workspaceInfoGroup = [];
      this.chooseDepartment = false;
      this.chooseWorkspace = false;
    },
    addDepartment() {
      this.departmentList.push({
        department: '',
        id: new Date().getTime()
      });
    },
    addWorkspaceItem() {
      this.workspaceInfoGroup.push({
        workspace: '',
        id: new Date().getTime()
      });
    },
    deleteDepartment(index) {
      this.departmentList.splice(index, 1);
    },
    deleteWorkspace(index) {
      this.workspaceInfoGroup.splice(index, 1);
    },
    confirmBatchModify() {
      // 校验 成员/部门/角色 必须全选择
      this.$refs['batchModifyForm'].validate(async valid => {
        if (valid) {
          if (this.chooseDepartment || this.chooseWorkspace) {
            this.modifyBtnLoading = true;
            const _data = {
              user_ids: this.batchModifyForm.batchUserIds,
              tree_ids: this.batchModifyForm.batchDeptIds
            };
            if (this.chooseDepartment) {
              _data.main_target_tree_id = this.departmentList[0].department;
              _data.target_tree_ids = this.disabledArr;
            }
            if (this.chooseWorkspace) {
              _data.main_workspace_id = this.workspaceInfoGroup[0].workspace;
              _data.workspace_ids = this.workspaceInfoGroup.map(item => item.workspace);
            }
            // 发送接口
            try {
              const res = await (0, _organization.BatchModifyUsers)(_data);
              if (res.code === 200) {
                this.handleClose();
                this.$message({
                  message: this.$t('management.修改成功'),
                  center: true,
                  type: 'success'
                });
                this.$emit('refresh');
              }
            } finally {
              this.modifyBtnLoading = false;
            }
          } else {
            this.$message({
              message: this.$t('management.请选择要修改的成员信息'),
              type: 'warning'
            });
          }
        }
      });
    },
    // 成员选择
    handleMemberChange(val) {
      this.batchModifyForm.ids = val;
      const userId_Multiple = [];
      const deptId_Multiple = [];
      val.forEach(item => {
        const id = String(item).split('_').pop() - 0;
        if (String(item).indexOf('tree_id') === -1) {
          userId_Multiple.push(id);
        } else {
          deptId_Multiple.push(id);
        }
      });
      this.batchModifyForm.batchUserIds = userId_Multiple;
      this.batchModifyForm.batchDeptIds = deptId_Multiple;
    },
    async fetchDepartmentData() {
      this.departmentLoading = true;
      const res = await (0, _permissions.getDepartmentWithAuth)();
      if (res.code === 200) {
        var _this$orgTree$;
        this.orgTree = res.results;
        this.defaultExpandedKeys = [(_this$orgTree$ = this.orgTree[0]) === null || _this$orgTree$ === void 0 ? void 0 : _this$orgTree$.id];
      }
      this.departmentLoading = false;
    },
    // 选择部门
    handleCurrentChange(data) {
      var _this$$refs$treeSele;
      this.batchModifyForm.departments = data.id;
      (_this$$refs$treeSele = this.$refs['tree-select']) === null || _this$$refs$treeSele === void 0 ? void 0 : _this$$refs$treeSele.blur();
    },
    handleClose() {
      this.reset();
      this.$emit('update:dialogVisible', false);
    },
    reset() {
      this.$refs['batchModifyForm'].resetFields();
      // this.$refs['depatment-select'].localDepartmentName = '';
    }
  }
};

exports.default = _default;