"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountLogin = accountLogin;
exports.addMewMember = addMewMember;
exports.getAccountInfo = getAccountInfo;
exports.reqGet = reqGet;
exports.resetPassword = resetPassword;
exports.updateAccountPassWord = updateAccountPassWord;
var _request = require("@/utils/request");
// 私有化部署

// 账号登录
function accountLogin(data) {
  return (0, _request.webService)({
    url: '/reglogin/account/login',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 验证码获取验证图片  以及token
function reqGet() {
  return (0, _request.webService)({
    url: '/reglogin/account/login/block',
    method: 'get'
  });
}

// 添加新成员
function addMewMember(data) {
  return (0, _request.webService)({
    url: '/organization/user/account/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 重置成员密码
function resetPassword(data) {
  return (0, _request.webService)({
    url: '/organization/user/account/password/reset',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取个人信息
function getAccountInfo() {
  return (0, _request.webService)({
    url: '/profile/account/user_info/get',
    method: 'get'
  });
}

/** 获取个人信息页的修改密码**/
function updateAccountPassWord(data) {
  return (0, _request.webService)({
    url: '/profile/account/password/update',
    method: 'post',
    dataType: 'json',
    data
  });
}