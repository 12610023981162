"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _EvaluatePreviewDialog = _interopRequireDefault(require("../../evalute/components/EvaluatePreviewDialog"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceItem',
  components: {
    FormItemCard: _FormItemCard.default,
    EvaluatePreviewDialog: _EvaluatePreviewDialog.default
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        checkedItem: [],
        compliance_score_rule_ids: [],
        evaluation_template_id: ''
      })
    }
  },
  data() {
    return {
      scoreRuleList: [],
      evaluationTemplateList: [],
      rules: {
        compliance_score_rule_ids: {
          required: true,
          message: '请选择质检评分规则',
          trigger: 'change'
        },
        evaluation_template_id: {
          required: true,
          message: '请选择质检评价单',
          trigger: 'change'
        }
      },
      schema: {},
      previewDialogVisible: false,
      previewLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'webRoutes'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getSamplingScoreRuleList();
          this.getEvaluationTemplateList();
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    async previewEvaluation(id) {
      this.previewDialogVisible = true;
      this.previewLoading = true;
      const res = await (0, _compliance.getEvaluationTemplate)({
        template_id: id
      }).finally(() => {
        this.previewLoading = false;
      });
      if (res.code === 20000) {
        this.schema = res.results;
      }
    },
    async getSamplingScoreRuleList() {
      const res = await (0, _compliance.getSamplingScoreRuleList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.scoreRuleList = res.results.rules;
      }
    },
    async getEvaluationTemplateList() {
      const params = {
        type: 1,
        name: '',
        status: 1,
        workspace_id: this.workspaceInfo.id,
        page: 1,
        size: 50
      };
      const res = await (0, _compliance.getEvaluationTemplateList)(params);
      if (res.code === 20000) {
        this.evaluationTemplateList = res.results.template_list;
        const defaultTemplate = this.evaluationTemplateList.find(item => item.is_default);
        if (!defaultTemplate) return;
        // eslint-disable-next-line vue/no-mutating-props
        this.formData.evaluation_template_id = defaultTemplate.id;
      }
    },
    goComplianceRules() {
      if (this.webRoutes.find(item => item.name === 'ComplianceRules')) {
        this.$router.push({
          path: _routeMap.ROUTES.complianceScoreSetting
        });
      } else {
        this.$message.warning('当前用户没有质检评分规则管理权限');
      }
    },
    goComplianceEvaluation() {
      if (this.webRoutes.find(item => item.name === 'ComplianceEvalute')) {
        this.$router.push({
          path: _routeMap.ROUTES.complianceEvaluation
        });
      } else {
        this.$message.warning('当前用户没有质检评价单管理权限');
      }
    }
  }
};
exports.default = _default;