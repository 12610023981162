"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceKanban = _interopRequireDefault(require("@/views/compliance/components/ComplianceKanban"));
var _MyTaskContent = _interopRequireDefault(require("./components/MyTaskContent"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamTask',
  components: {
    ComplianceKanban: _ComplianceKanban.default,
    MyTaskContent: _MyTaskContent.default
  },
  mixins: [_complianceTableMixin.default],
  data() {
    return {
      scheduleFilter: null,
      temArr: [],
      filtersObj: {
        workspace_id: 0,
        task_name: '',
        create_time: [],
        percent: [],
        create_by: {
          tree_ids: [],
          user_ids: []
        },
        status: 0,
        sort_by: [['create_at', 'descending']],
        page: 1,
        size: 20
      },
      activeTabIndex: 0,
      tabList: [],
      tableDataList: [],
      total: 0,
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          const complianceMyTask = localStorage.getItem('ComplianceMyTask');
          const tabName = (complianceMyTask === '今日任务' ? '全部任务' : complianceMyTask) || '全部任务';
          this.$router.replace({
            query: {
              tab: tabName
            }
          });
          await this.getMyTaskTabCount(val);
          this.handleTabClick(tabName);
          this.initParams(val);
        }
      },
      immediate: true
    },
    activeTabIndex: {
      handler: function () {
        if (this.workspaceInfo.id) {
          this.filtersObj.page = 1;
          this.initParams(this.workspaceInfo.id);
        }
      }
    }
  },
  methods: {
    async getMyTaskTabCount(val) {
      const res = await (0, _compliance.getMyTaskTabCount)({
        workspace_id: val
      });
      if (res.code === 20000) {
        this.tabList = res.results;
      }
    },
    initParams(workspaceId) {
      var _this$tabList$this$ac;
      this.filtersObj.status = (_this$tabList$this$ac = this.tabList[this.activeTabIndex]) === null || _this$tabList$this$ac === void 0 ? void 0 : _this$tabList$this$ac.value;
      this.filtersObj.workspace_id = workspaceId;
      this.handleFiltersChange(this.filtersObj);
    },
    handleTabClick(tabName) {
      localStorage.setItem('ComplianceMyTask', tabName);
      this.activeTabIndex = this.tabList.findIndex(item => item.label === tabName);
    },
    handleFiltersChange(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.getComplianceTeamTask(this.filtersObj);
    },
    sortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.filtersObj.sort_by = [[prop, order]];
      this.filtersObj.page = 1;
      this.getComplianceTeamTask(this.filtersObj);
    },
    async getComplianceTeamTask(filters) {
      const localFilters = (0, _commonFunc.deepClone)(filters);
      (0, _timezone.convertParams2UTCplus8)(localFilters);
      this.loading = true;
      try {
        const res = await (0, _compliance.getMyComplianceTaskList)(localFilters);
        if (res.code === 20000) {
          const {
            datas,
            total
          } = res.results;
          this.tableDataList = datas;
          this.total = total;
        }
      } finally {
        this.loading = false;
      }
    },
    goCompliancePersonal(rowInfo) {
      sessionStorage.setItem('compliancePersonal', JSON.stringify(rowInfo));
      window.open(`${window.location.origin}/compliance/personal`, '_blank');
    },
    sizeChange(size) {
      this.filtersObj.size = size;
      this.filtersObj.page = 1;
      this.getComplianceTeamTask(this.filtersObj);
    },
    currentChange(page) {
      this.filtersObj.page = page;
      this.getComplianceTeamTask(this.filtersObj);
    }
  }
};
exports.default = _default;