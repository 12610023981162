"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _ScoreRuleContent = _interopRequireDefault(require("./ScoreRuleContent.vue"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationDetailComplianceScore',
  components: {
    ScoreRuleContent: _ScoreRuleContent.default,
    NoData: _index.default,
    KeyEventDetailList: _keyEventDetailList.default
  },
  props: {
    convId: {
      type: [Number, String],
      default: 0
    },
    mediaType: {
      type: String,
      default: ''
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 请求数据的会话id，用于每次activated时判断是否要更新数据
      requestConvId: 0,
      loading: false,
      scoreList: [],
      // eventTracker 数据
      activeEventDetailData: [],
      eventTrackerTitle: '',
      deleteOperateLoading: false,
      activeEventId: 0,
      activeAccountTagData: [],
      accountTagTitle: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    formatScoreList() {
      return this.scoreList.filter(item => item.item.length);
    }
  },
  watch: {
    convId(val) {
      this.initData();
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.initData();
        }
      },
      immediate: true
    }
  },
  methods: {
    refreshScore() {
      this.initData();
    },
    async getAccountTagDetail(val) {
      this.loading = true;
      const res = await (0, _videoDetail.getAccountTagDetail)({
        conversation_id: parseInt(this.convId),
        tag_id: val.id,
        tag_value: val.name
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.accountTagTitle = res.results.name + `(${res.results.count})`;
        this.activeAccountTagData = res.results.datas;
      }
    },
    async deleteKeyEventDetailTracker(event_engine_id) {
      this.deleteOperateLoading = true;
      const res = await (0, _videoDetail.deleteKeyEventDetailTracker)({
        conversation_id: Number(this.convId),
        event_engine_id
      }).finally(() => this.deleteOperateLoading = false);
      if (res.code === 20000) {
        this.$message.success('删除成功，评分重新计算中，请稍后刷新查看');
        this.$bus.$emit('conversationLeftDeleteKeyEventTrackerSuccess');
        await this.getEventTrackers();
      }
    },
    async handleDeleteKeyEventDetailTracker(tracker, index) {
      await this.deleteKeyEventDetailTracker(tracker.id);
    },
    triggerKeyEvent(val) {
      this.activeEventId = val.id;
      this.getEventTrackers();
    },
    triggerAccountTag(val) {
      this.getAccountTagDetail(val);
    },
    handleBackUp() {
      this.activeEventDetailData = [];
      this.activeAccountTagData = [];
    },
    async getEventTrackers() {
      this.loading = true;
      const res = await (0, _videoDetail.getEventTrackers)({
        conversation_id: parseInt(this.convId),
        event_id: this.activeEventId
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.eventTrackerTitle = res.results.name + `(${res.results.count})`;
        this.activeEventDetailData = res.results.datas;
      }
    },
    toggleAllExpandAndCollapse(index) {
      this.$set(this.scoreList[index], 'isExpandAll', !this.scoreList[index].isExpandAll);
      this.scoreList[index].activeNames = this.scoreList[index].isExpandAll ? this.scoreList[index].item.map(item => item.id) : [];
    },
    toggleItemExpandAndCollapse(index) {
      this.$set(this.scoreList[index], 'isExpandAll', this.scoreList[index].activeNames.length === this.scoreList[index].item.length);
    },
    async initData() {
      var _this$$route, _this$$route$query;
      this.requestConvId = this.convId;
      this.loading = true;
      const data = {
        workspace_id: this.workspaceInfo.id,
        id: this.convId
      };
      const taskId = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query = _this$$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.task_id;
      if (taskId) {
        data.task_id = Number(taskId);
      }
      const res = await (0, _compliance.getComplianceScore)(data).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.scoreList = res.results.score_result;
        this.scoreList.forEach((item, index) => {
          this.$set(this.scoreList[index], 'activeNames', []);
        });
      }
    }
  }
};
exports.default = _default;