"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "intelligent-extension-wrapper",
    class: {
      active: _vm.intelligentSentenceDrawerVisible
    }
  }, [_c("div", {
    staticClass: "intelligent-extension"
  }, [_c("div", {
    staticClass: "intelligent-extension__header"
  }, [_c("div", {
    staticClass: "intelligent-extension__title"
  }, [_vm._v("智能扩充")]), _c("div", {
    staticClass: "intelligent-extension__close",
    on: {
      click: _setup.closeIntelligentSentenceDrawer
    }
  }, [_c("i", {
    staticClass: "iconfont icon-close"
  })])]), _c("div", {
    staticClass: "intelligent-extension__content"
  }, [_c("div", {
    staticClass: "intelligent-extension__content-input"
  }, [_c("div", {
    staticClass: "intelligent-extension__content-tips"
  }, [_c("span", [_vm._v("提示")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "通过增加“行业”、“说话背景”等信息丰富提示，获得更符合事件含义的句子"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "editable-div-wrapper"
  }, [_c("div", {
    ref: "editableDivRef",
    staticClass: "editable-div",
    attrs: {
      contenteditable: "true"
    },
    on: {
      input: _setup.divInput
    }
  }), _c("div", {
    staticClass: "intelligent-btn-container"
  }, [_c("el-button", {
    staticClass: "intelligent-btn",
    attrs: {
      loading: _vm.gptLoading,
      disabled: !_setup.localIntelligentSentence
    },
    on: {
      click: _setup.handleIntelligentSentence
    }
  }, [_vm._v("扩充")])], 1)])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.gptLoading,
      expression: "gptLoading"
    }],
    staticClass: "intelligent-extension__content-sentence"
  }, [_c("div", {
    staticClass: "intelligent-extension__content-operate"
  }, [_c("span", [_vm._v("扩充句")]), _c("el-button", {
    attrs: {
      type: "primary",
      plain: "",
      disabled: !_vm.exampleSentenceList.length
    },
    on: {
      click: _setup.addExtendedSentenceToExample
    }
  }, [_vm._v("全部添加至例句")])], 1), _c("div", {
    staticClass: "intelligent-extension__example"
  }, [_vm._l(_vm.exampleSentenceList, function (sentence, index) {
    return [_c("div", {
      key: index,
      staticClass: "example-sentence-item"
    }, [_c("div", {
      staticClass: "example-sentence-item-content"
    }, _vm._l(sentence, function (item, _index) {
      return _c("div", {
        key: _index,
        staticClass: "sentence-content"
      }, [_c("span", {
        staticClass: "entity-type"
      }, [_vm._v(" " + _vm._s(_setup.entityTypeObj[item["entity_type"]]) + " ")]), _c("span", {
        staticClass: "content"
      }, [_vm._v(_vm._s(item.content))])]);
    }), 0), _c("i", {
      staticClass: "iconfont icon-close",
      on: {
        click: function ($event) {
          return _setup.deleteExampleSentence(index);
        }
      }
    })])];
  }), !_vm.exampleSentenceList.length ? _c("div", {
    staticClass: "no-intelligent-extension__example"
  }, [_vm._v(" 请扩充例句 ")]) : _vm._e()], 2)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;