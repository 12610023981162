"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "container",
    staticClass: "knowledge-discovery"
  }, [_c("div", {
    staticClass: "container-head"
  }, [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: "知识点挖掘引擎"
    }
  }, [_c("div", {
    staticClass: "pre-container",
    attrs: {
      slot: "preIcon"
    },
    slot: "preIcon"
  }, [_c("div", {
    staticClass: "back",
    on: {
      click: _vm.goBack
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("img", {
    staticClass: "pre-icon",
    attrs: {
      src: _vm.colorfulRadar
    }
  })]), _c("space-button", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })], 1), _c("el-button", {
    staticClass: "ignore-button",
    on: {
      click: function ($event) {
        _vm.drawerVisible = true;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated-already"
  }), _vm._v("已忽略问题")]), _c("guide-dialog", {
    attrs: {
      visible: _vm.guideDialogVisible
    },
    on: {
      handleClick: _vm.handleKnown
    }
  })], 1), _c("div", {
    staticClass: "container-body"
  }, [_c("knowledge-questions-content", {
    ref: "questionContent",
    on: {
      questionClick: _vm.handleQuestionClick,
      ignoreQuestion: _vm.ignoreQuestion
    }
  }), _c("question-tricks-library", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowQuestionTricksLibrary,
      expression: "isShowQuestionTricksLibrary"
    }],
    staticClass: "question-tricks-library",
    attrs: {
      "current-question": _vm.currentQuestion
    },
    on: {
      refreshQuestionList: _vm.refreshQuestionList,
      closeLibrary: _vm.closeLibrary
    }
  })], 1), _c("IgnoreQuestionDrawer", {
    attrs: {
      "drawer-visible": _vm.drawerVisible
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.drawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;