"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "nav-bar"
  }, [_vm.isShowGlobalSearch ? _c("global-search") : _vm._e(), _c("div", {
    staticClass: "right-fix-container"
  }, [_c("message-center-button"), _c("task-center-button"), _vm.$config.isMegaview ? _c("assistant-button") : _vm._e(), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("feedback-button"), _c("el-tooltip", {
    staticClass: "tooltip",
    attrs: {
      slot: "suffix",
      effect: "dark",
      placement: "bottom",
      enterable: true,
      content: "帮助中心",
      "hide-after": 1000
    },
    slot: "suffix"
  }, [!_vm.isTwoFactor ? _c("el-button", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "help-center-btn hover-show-bg",
    attrs: {
      icon: "iconfont icon-question3",
      circle: ""
    },
    on: {
      click: _vm.goHelpCenter
    }
  }) : _vm._e()], 1), _c("user-avatar")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;