"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DealFilterMultiSelect',
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String]
    }
  },
  data() {
    return {
      localValue: []
    };
  },
  computed: {
    tipContent() {
      const res = [];
      if (this.localValue && this.localValue.length) {
        this.localValue.map(value => {
          this.item.options.map(_item => {
            if ((_item.value || _item) === value) {
              res.push(_item.label || _item);
            }
          });
        });
      }
      return res.join(',');
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.localValue = val;
      },
      immediate: true
    }
  },
  methods: {
    tooltipIsDisabled(val) {
      return !(val !== null && val !== void 0 && val.length);
    },
    handleChange(val) {
      this.$emit('change', {
        [this.item.field_name]: val
      });
    }
  }
};
exports.default = _default;