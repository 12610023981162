"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["training-test-drawer", {
      show: _vm.drawerVisible
    }]
  }, [_c("header", [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.dialogueTest")))]), _c("i", {
    staticClass: "iconfont icon-refresh-left",
    on: {
      click: _vm.restartTest
    }
  }), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: function ($event) {
        return _vm.$emit("update:drawerVisible", false);
      }
    }
  })]), _c("article", {
    ref: "conversation-container"
  }, [_c("div", {
    staticClass: "conversational-transcription"
  }, [_vm.practiceStatus === "connecting" ? _c("p", {
    staticClass: "connecting"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.connecting"))), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.practiceStatus !== "connecting",
      expression: "practiceStatus !== 'connecting'"
    }],
    ref: "sentence-container",
    staticClass: "sentence-container"
  }, [_c("div", {
    staticClass: "start-sentence"
  }, [_c("span"), _vm._v(" " + _vm._s(_vm.$t("coaching.start")) + " "), _c("span")]), _vm._l(_vm.sentenceList, function (item, index) {
    return [item.type === "narrator" ? _c("DialogueItemNarration", {
      key: index,
      attrs: {
        imgs: item.images,
        "image-append-to-body": true
      }
    }, [_vm._v(" " + _vm._s(item.content) + " ")]) : _c("SentenceItem", {
      key: item.child_id || index,
      attrs: {
        "image-width": 256,
        "task-type": 3,
        "image-append-to-body": true,
        "practice-rule": _vm.practiceRule,
        sentence: item
      }
    })];
  }), _vm.conversationComplate && _vm.scoreComplate ? [_c("div", {
    staticClass: "end-sentence"
  }, [_c("span"), _vm._v(" " + _vm._s(_vm.$t("coaching.conversationEnds")) + " "), _c("span")]), _c("div", {
    staticClass: "score-container"
  }, [_c("span", {
    staticClass: "score"
  }, [_vm._v(_vm._s(_vm.averageScore))]), _c("span", {
    staticClass: "unit"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.points")))])])] : _vm._e()], 2)])]), _vm.practiceStatus !== "connecting" ? _c("footer", [!_vm.conversationComplate ? [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.standardReplies.length,
      expression: "standardReplies.length"
    }],
    staticClass: "quick-send-container"
  }, _vm._l(_vm.standardReplies, function (reply, index) {
    return _c("div", {
      key: index,
      staticClass: "send-item",
      on: {
        click: function ($event) {
          return _vm.uploadSmartTrainingTest(reply);
        }
      }
    }, [_c("MgAutoTooltip", {
      attrs: {
        enterable: false,
        content: reply
      }
    })], 1);
  }), 0), _c("div", {
    staticClass: "chart-input-container"
  }, [_c("ChatInput", {
    ref: "chatInput",
    attrs: {
      disabled: _vm.uploadLoading,
      maxlength: 1000,
      placeholder: _vm.$t("coaching.replyPlaceholder")
    },
    on: {
      change: _vm.uploadSmartTrainingTest
    }
  })], 1)] : _c("el-button", {
    staticClass: "restart-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.restartTest
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.retest")))])], 2) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;