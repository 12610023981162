"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _validate = require("@/utils/validate");
var _applicationIntegration = require("@/api/applicationIntegration");
var _default = {
  __name: 'index',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    }
  },
  emits: ['update:dialogVisible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const isComplete = (0, _vue.ref)(false);
    const sourceCode = {
      compliance: '7Y9pW2',
      coaching: '3k1VqG'
    };
    const rules = {
      name: [{
        required: true,
        message: '姓名不能为空',
        trigger: 'blur'
      }],
      tel: [{
        required: true,
        validator: _validate.telValidator,
        trigger: 'blur'
      }],
      company: [{
        required: true,
        message: '企业名称不能为空',
        trigger: 'blur'
      }]
    };
    const reservationForm = (0, _vue.ref)({
      name: '',
      tel: '',
      position: '',
      email: '',
      company: '',
      industry: '',
      employee_count: '',
      channel: '',
      source: '',
      entrance_url: window.location.origin || ''
    });
    const formRef = (0, _vue.ref)(null);
    const handleClose = () => {
      emit('update:dialogVisible', false);
      formRef.value && formRef.value.resetFields();
    };
    const submitForm = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          reservationForm.value.source = sourceCode[props.source];
          const res = await (0, _applicationIntegration.officialTrial)(reservationForm.value);
          if (res.code === 200) {
            isComplete.value = true;
            formRef.value.resetFields();
          }
        }
      });
    };
    return {
      __sfc: true,
      props,
      isComplete,
      sourceCode,
      emit,
      rules,
      reservationForm,
      formRef,
      handleClose,
      submitForm
    };
  }
};
exports.default = _default;