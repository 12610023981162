"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomFieldTypeGroup = _interopRequireDefault(require("./CustomFieldTypeGroup"));
var _CustomFieldForm = _interopRequireDefault(require("./CustomFieldForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomFieldDialog',
  components: {
    CustomFieldTypeGroup: _CustomFieldTypeGroup.default,
    CustomFieldForm: _CustomFieldForm.default
  },
  props: {
    visible: Boolean,
    isEdit: Boolean,
    editForm: Object,
    type: String,
    customFieldList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formType: 'text'
    };
  },
  methods: {
    closeDialog() {
      this.$nextTick(() => {
        this.formType = 'text';
        this.$refs.form.reset();
        this.$refs.form.$refs.form.clearValidate();
        this.$emit('closeDialog', false);
      });
    },
    optionChange(type) {
      this.formType = type;
    },
    changeType(type) {
      this.formType = type;
    }
  }
};
exports.default = _default;