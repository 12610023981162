"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    staticClass: "export-table-button",
    attrs: {
      icon: "iconfont icon-export",
      disabled: _vm.disabled
    },
    on: {
      click: function ($event) {
        return _vm.$emit("exportTable");
      }
    }
  }, [_vm._v("导出表格")]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;