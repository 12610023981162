"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  data() {
    return {
      defaultSortColumn: 'update_at',
      // 客户公海table的优先取组件内的lastSort
      lastSort: {
        prop: 'update_at',
        order: 'descending'
      }
    };
  },
  watch: {
    headerList(val, oldVal) {
      const {
        prop,
        order
      } = this.lastSort;
      const lastSortColumn = val.find(item => item.field_name === prop);
      const defaultColumn = val.find(item => item.field_name === `${this.defaultSortColumn}`);
      if (lastSortColumn) {
        this.$nextTick(() => {
          this.$refs[`${this.pageType}-${prop}`][0].columnConfig.order = order;
        });
      } else {
        this.handleSortChange({
          prop: `${this.defaultSortColumn}`,
          order: 'descending'
        }, this.pageType);
        if (defaultColumn) {
          this.$nextTick(() => {
            this.$refs[`${this.pageType}-${this.defaultSortColumn}`][0].columnConfig.order = 'descending';
          });
        }
      }
    }
  },
  methods: {
    customSortable(item) {
      if (this.sortColumnList.some(column => column.field_name === item)) {
        return 'custom';
      }
      return false;
    },
    setColumnWidth(colName) {
      switch (colName) {
        case 'email':
          return '200px';
        case 'create_at':
        case 'update_at':
        case 'add_pool_time':
          return '150px';
        default:
          break;
      }
    },
    handleSortChange(_ref, currentPage) {
      let {
        column,
        prop,
        order
      } = _ref;
      if (prop !== this.lastSort.prop) {
        const targetColumn = this.headerList.find(item => item.field_name === this.lastSort.prop);
        if (targetColumn) {
          this.$nextTick(() => {
            this.$refs[`${currentPage}-${this.lastSort.prop}`][0].columnConfig.order = null;
          });
        }
      }
      this.$nextTick(() => {
        if (!order) {
          column.order = this.lastSort.order;
        } else {
          this.lastSort = {
            prop,
            order
          };
          this.$emit('handleSortOrder', prop, order, currentPage);
        }
      });
    }
  }
};
exports.default = _default;