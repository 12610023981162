"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "salesman-profile"
  }, [_vm.isShowKanban && _vm.kanbanList.length ? _c("BI-kanban", {
    ref: "bi-kanban",
    class: {
      "has-permisstion": _vm.hasHandleKanbanPermission
    },
    attrs: {
      "active-id": _vm.currentKanban.kanban_id,
      "kanban-list": _vm.kanbanList,
      "has-permisstion": _vm.hasHandleKanbanPermission
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }) : _vm._e(), _vm.isShowKanban && !_vm.isLoading && !_vm.kanbanList.length ? _c("init-kanban-card-view", {
    attrs: {
      "has-permisstion": _vm.hasHandleKanbanPermission,
      config: {
        title: "销售画像看板",
        tooltip: ["配置成员的过程分：根据关键事件，沟通分指标配置。", "配置成员的结果分：根据关键事件，商机字段指标配置。", "创建成功后，系统将生成销售画像九宫格；管理员可以随时编辑销售画像配置，生成新的画像。"]
      }
    },
    on: {
      createKanban: _vm.createKanban
    }
  }, [_c("img", {
    staticClass: "right",
    attrs: {
      slot: "right-img",
      src: require("@/assets/BI/scatterChart.jpg")
    },
    slot: "right-img"
  })]) : _c("dynamic-tab-chart", {
    ref: "dynamicTab",
    staticClass: "scroll-box",
    attrs: {
      "is-loading": _vm.isLoading,
      "page-type": _vm.pageType,
      "page-configs": _vm.pageConfigs,
      "current-tab": _vm.currentTab,
      "chart-data": _vm.chartData
    },
    on: {
      editConfig: function ($event) {
        return _vm.handleKanban("edit", _vm.currentKanban);
      }
    }
  }), _c("sales-portrait-config", {
    ref: "SalesPortraitConfig",
    attrs: {
      "is-edit": _vm.isEdit,
      "drawer-visible": _vm.drawerVisible,
      "get-portrait-config-loading": _vm.getPortraitConfigLoading
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.drawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.drawerVisible = $event;
      },
      previewPortrait: _vm.previewPortrait,
      portraitSuccess: _vm.portraitSuccess
    }
  }), _c("preview-portrait", {
    attrs: {
      loading: _vm.previewLoading,
      "dialog-visible": _vm.dialogVisible,
      "portrait-config": _vm.portraitConfig
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.dialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;