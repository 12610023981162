"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-display-cascader",
    class: {
      "is-child-level": _vm.level
    }
  }, _vm._l(_setup.props.option, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "cascader-row"
    }, [_setup.isShow(item.id) ? [item.children && item.children.length ? _c("div", {
      staticClass: "title-row"
    }, [_vm._m(0, true), _c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))])]) : _c("div", {
      staticClass: "score-row"
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "score"
    }, [item.score > 0 ? _c("span", [_vm._v("+")]) : _vm._e(), _c("span", [_vm._v(_vm._s(item.score))])])]), item.children ? _c("FormDisplayCascader", {
      attrs: {
        option: item.children,
        ids: _vm.ids,
        level: _vm.level + 1
      }
    }) : _vm._e()] : _vm._e()], 2);
  }), 0);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("span", {
    staticClass: "dot-box"
  }, [_c("i", {
    staticClass: "dot"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;