"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearchContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeSearchContent"));
var _KnowledgeMultipleTree = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeMultipleTree"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _LearnContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/LearnContent.vue"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScriptSelectionDialog',
  components: {
    NoData: _NoData.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default,
    KnowledgeMultipleTree: _KnowledgeMultipleTree.default,
    KnowledgeSearchContent: _KnowledgeSearchContent.default,
    LearnContent: _LearnContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      oldCheckedKnowledgeScript: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['searchInput'].focus();
        });
        this.oldCheckedKnowledgeScript = (0, _commonFunc.deepClone)(this.checkedKnowledgeScript);
      }
    }
  },
  methods: {
    submit() {
      if (!this.checkedKnowledgeScript.length) {
        this.$message(this.$t('coaching.addScriptTip'));
        return;
      }
      this.$emit('change', this.$refs.learnContent.localCheckedKnowledgeScript);
      this.handleClose();
    },
    cancelAdd() {
      this.$emit('change', this.oldCheckedKnowledgeScript);
      this.handleClose();
    },
    scrollToScriptEnd() {
      this.$nextTick(() => {
        this.$refs['script-container'].scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;