"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ImageAvatar: _ImageAvatar.default,
    NoteEditor: _NoteEditor.default,
    eventsList: _eventsList.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isSilent: Boolean
  },
  data() {
    return {
      activeName: ['note', 'keyevent']
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'has_video_permission']),
    getDuration() {
      return function (duration) {
        const {
          h,
          m,
          s
        } = (0, _timeFormatter.second2HMS)(duration);
        if (h) {
          if (m) {
            return h + 'h ' + m + 'm';
          } else {
            return h + 'h';
          }
        } else {
          if (m) {
            return m + 'm';
          } else {
            return s + 's';
          }
        }
      };
    },
    getIsShowButton() {
      return function (dateItem) {
        return dateItem.status === 'attend' || dateItem.status === 'recorded' || dateItem.status === 'gotfiles' || dateItem.status !== 'expire' && dateItem.type === 'video' && this.has_video_permission;
      };
    },
    getHoverText() {
      return function (item) {
        if (item.status === 'attend' || item.status === 'recorded' || item.status === 'gotfiles') {
          return '会话详情';
        }
        if ((item.status === 'plan' || item.status === 'recording') && item.type === 'video') {
          return '进入会议';
        }
      };
    },
    getHoverIcon() {
      return function (item) {
        switch (item.type) {
          case 'audio':
            return 'iconfont icon-phone-outline';
          case 'doc':
            return 'iconfont icon-im';
          default:
            return 'iconfont icon-video-camera';
        }
      };
    }
  },
  methods: {
    getIconType(type, status) {
      if (type === 'audio') {
        return 'phone';
      } else if (type === 'doc') {
        return 'im-fill';
      } else {
        if (status === 'expire') {
          return 'video-camera';
        } else {
          return 'video-camera-solid-2';
        }
      }
    },
    goTargetPage(item) {
      if (item.status === 'attend' || item.status === 'recorded' || item.status === 'gotfiles') {
        this.$router.push({
          path: _routeMap.ROUTES.conversationDetail,
          query: {
            id: item.id
          }
        });
      } else if (item.status === 'plan' || item.status === 'recording') {
        if (item.type === 'video') {
          if (!this.has_video_permission) {
            this.$message.error('暂无访问视频会议权限');
            return;
          }
          if (item.meeting_type === 'tencent_meeting') {
            window.open(item.meeting_url, '_blank');
            return;
          }
          const interUrl = `${window.location.protocol}//${window.location.host}/vri?c=${item.code}`;
          window.open(interUrl, '_blank');
        }
      }
    },
    onTagClick(eventId) {
      if (this.isSilent) return;
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&keyWordId=${eventId}`);
    }
  }
};
exports.default = _default;