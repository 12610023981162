import { render, staticRenderFns } from "./TransferUserInfo.vue?vue&type=template&id=a1920278&scoped=true&"
import script from "./TransferUserInfo.vue?vue&type=script&lang=js&"
export * from "./TransferUserInfo.vue?vue&type=script&lang=js&"
import style0 from "./TransferUserInfo.vue?vue&type=style&index=0&id=a1920278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1920278",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a1920278')) {
      api.createRecord('a1920278', component.options)
    } else {
      api.reload('a1920278', component.options)
    }
    module.hot.accept("./TransferUserInfo.vue?vue&type=template&id=a1920278&scoped=true&", function () {
      api.rerender('a1920278', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DataTransferDrawer/components/TransferUserInfo.vue"
export default component.exports