"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "mgv-radio-tags"
  }, _vm._l(_vm.list, function (item) {
    return _c("MgvTag", {
      key: item.value,
      class: item.value === _vm.value ? "is-checked" : "",
      attrs: {
        size: _setup.radioSize,
        icon: item.icon,
        disabled: item.disabled,
        round: _vm.round
      },
      on: {
        click: function ($event) {
          return _setup.handleClick(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label) + " ")]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;