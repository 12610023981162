import { render, staticRenderFns } from "./AiCustomerEffectTest.vue?vue&type=template&id=083d4c33&scoped=true&"
import script from "./AiCustomerEffectTest.vue?vue&type=script&setup=true&lang=js&"
export * from "./AiCustomerEffectTest.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AiCustomerEffectTest.vue?vue&type=style&index=0&id=083d4c33&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083d4c33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('083d4c33')) {
      api.createRecord('083d4c33', component.options)
    } else {
      api.reload('083d4c33', component.options)
    }
    module.hot.accept("./AiCustomerEffectTest.vue?vue&type=template&id=083d4c33&scoped=true&", function () {
      api.rerender('083d4c33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/AiCustomerGenerator/components/AiCustomerEffectTest.vue"
export default component.exports