"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "practice-process-tips-switch"
  }, [_c("span", {
    staticClass: "title-text"
  }, [_vm._v(_vm._s(_vm.title))]), _c("el-switch", {
    attrs: {
      width: 34,
      value: _vm.value
    },
    on: {
      input: _setup.onInput
    }
  }), _c("p", {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.description))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;