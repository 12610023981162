"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "drag",
      rawName: "v-drag"
    }],
    staticClass: "global-upload-file"
  }, [_c("div", {
    staticClass: "global-upload-file__header"
  }, [_c("div", {
    staticClass: "global-upload-file__header-content"
  }, [_setup.progressViewState === "uploading" ? [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: true,
      expression: "true"
    }],
    staticClass: "loading"
  }), _c("div", {
    staticClass: "title-text"
  }, [_vm._v("上传中...")]), _c("div", {
    staticClass: "title-text"
  }, [_vm._v(" " + _vm._s(_setup.getFileCount(["success"])) + " / " + _vm._s(_setup.getFileCount(["success", "fail", "processing", "waiting", "uploading"])) + " ")])] : _setup.progressViewState === "allSuccess" ? [_c("i", {
    staticClass: "iconfont icon-success"
  }), _c("div", {
    staticClass: "title-text"
  }, [_vm._v("会话上传完成")]), _c("div", {
    staticClass: "title-text"
  }, [_vm._v(" " + _vm._s(_setup.getFileCount(["success"])) + " / " + _vm._s(_setup.getFileCount(["success"])) + " ")])] : _setup.progressViewState === "allFail" ? [_c("i", {
    staticClass: "iconfont icon-fail"
  }), _c("div", {
    staticClass: "title-text"
  }, [_vm._v("会话上传失败")]), _c("div", {
    staticClass: "title-text"
  }, [_vm._v(" 0 / " + _vm._s(_setup.getFileCount(["fail"])) + " ")]), _c("div", {
    staticClass: "fail-count"
  }, [_c("i", {
    staticClass: "iconfont icon-warning"
  }), _c("span", [_vm._v(_vm._s(_setup.getFileCount(["fail"])) + "个失败")])])] : [_c("i", {
    staticClass: "iconfont icon-fail"
  }), _c("div", {
    staticClass: "title-text"
  }, [_vm._v("会话上传完成")]), _c("div", {
    staticClass: "title-text"
  }, [_vm._v(" " + _vm._s(_setup.getFileCount(["success"])) + " / " + _vm._s(_setup.getFileCount(["fail", "success"])) + " ")]), _c("div", {
    staticClass: "fail-count"
  }, [_c("i", {
    staticClass: "iconfont icon-warning"
  }), _c("span", [_vm._v(_vm._s(_setup.getFileCount(["fail"])) + "个失败")])])]], 2), _c("div", {
    staticClass: "header-operate"
  }, [_c("i", {
    class: ["iconfont", {
      "icon-arrow-down": !_setup.collapse
    }, {
      "icon-arrow-up": _setup.collapse
    }],
    on: {
      click: _setup.collapseContent
    }
  }), _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: _setup.closeGlobalUpload
    }
  })])]), _c("div", {
    staticClass: "global-upload-file__content",
    class: {
      "global-upload-file__content-height": _setup.collapse
    }
  }, _vm._l(_setup.globalUploadFileList, function (file, index) {
    return _c(_setup.GlobalUploadFileItem, {
      key: file === null || file === void 0 ? void 0 : file.uid,
      attrs: {
        file: file
      },
      on: {
        changeFileState: function ($event) {
          return _setup.changeFileState($event, index);
        }
      }
    });
  }), 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;