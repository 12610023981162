"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _default = {
  __name: 'CompletePracticeButton',
  emits: ['completePractice', 'saveAndLeave', 'giveUpPractice'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const completePractice = () => {
      emit('completePractice');
    };
    const onCommand = command => {
      console.log(command, 'commad');
      emit(command);
    };
    return {
      __sfc: true,
      emit,
      completePractice,
      onCommand,
      t: _i18n.t
    };
  }
};
exports.default = _default;