"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-correction-content"
  }, _vm._l(_vm.scoreCorrectionContentList, function (item) {
    return _c("ScoreCorrectionContentCard", {
      key: item.template_id,
      attrs: {
        value: item
      },
      on: {
        scoreChange: _vm.handleScoreChange
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;