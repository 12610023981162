"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'HeaderFilter',
  components: {},
  props: {},
  data() {
    return {
      source: [],
      state: [],
      sourceOptions: [],
      stateOptions: [{
        id: 2,
        name: '进行中'
      }, {
        id: 3,
        name: '已完成'
      }, {
        id: 1,
        name: '未开始'
      }],
      userIds: [],
      userList: [],
      remoteLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getMonitorSource();
      },
      immediate: true
    }
  },
  methods: {
    async getMonitorSource() {
      const res = await (0, _businessIntelligence.getMonitorSource)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.sourceOptions = res.results.source;
      }
    },
    handleFilterChange() {
      this.$emit('filterChange', this.source, this.state, this.userIds);
    },
    remoteUserList: (0, _commonFunc.debounce)(async function (filter) {
      const params = {
        current_page: 1,
        size: 10,
        filter
      };
      this.remoteLoading = true;
      const res = await (0, _organization.getOrgUserList)(params).finally(() => {
        this.remoteLoading = false;
      });
      if (res.code === 200) {
        this.userList = res.results.users;
      }
    })
  }
};
exports.default = _default;