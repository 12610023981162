"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStore = void 0;
var _vue = require("vue");
// 访问vuex
const useStore = () => {
  const vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
exports.useStore = useStore;