"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "point-deduction-items-drawer-content"
  }, _vm._l(_setup.pointDeductionItems, function (item, index) {
    var _item$comment, _item$comment2, _item$comment3, _item$comment4;
    return _c("div", {
      key: index,
      staticClass: "point-deduction-item"
    }, [_c("figure", {
      staticClass: "q-a"
    }, [_c("div", {
      staticClass: "sentence-container"
    }, [_c("MgvAvatarName", {
      attrs: {
        src: item.avatar,
        name: item.name,
        size: 24
      }
    }), _c("div", {
      staticClass: "sentence"
    }, [_c("p", {
      staticClass: "name-row"
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("span", [_vm._v(_vm._s(item.date))])]), _c("p", {
      staticClass: "sentence-row"
    }, [_vm._v(" " + _vm._s(item.reply) + " ")])])], 1)]), _c("div", {
      staticClass: "item standard-speech"
    }, [_c("h1", {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(_setup.t("coaching.standardScript")) + "：")]), _c("p", {
      staticClass: "speech-content"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])]), _c("div", {
      staticClass: "item main-points"
    }, [_c("h1", {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(_setup.t("coaching.matchingPoints")) + "：")]), (_item$comment = item.comment) !== null && _item$comment !== void 0 && _item$comment.hit_points.length ? _c("p", {
      staticClass: "speech-content"
    }, _vm._l((_item$comment2 = item.comment) === null || _item$comment2 === void 0 ? void 0 : _item$comment2.hit_points, function (tag, index) {
      return _c("MgvTag", {
        key: index,
        attrs: {
          size: 24,
          "max-width": "535px"
        }
      }, [_vm._v(_vm._s(tag))]);
    }), 1) : _c("p", {
      staticClass: "speech-content"
    }, [_vm._v(_vm._s(_setup.t("coaching.onlyNone")))])]), _c("div", {
      staticClass: "item main-points"
    }, [_c("h1", {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(_setup.t("coaching.missingPoints")) + "：")]), (_item$comment3 = item.comment) !== null && _item$comment3 !== void 0 && _item$comment3.missed_points.length ? _c("p", {
      staticClass: "speech-content"
    }, _vm._l((_item$comment4 = item.comment) === null || _item$comment4 === void 0 ? void 0 : _item$comment4.missed_points, function (tag, index) {
      return _c("MgvTag", {
        key: index,
        attrs: {
          size: 24,
          "max-width": "535px"
        }
      }, [_vm._v(_vm._s(tag))]);
    }), 1) : _c("p", {
      staticClass: "speech-content"
    }, [_vm._v(_vm._s(_setup.t("coaching.onlyNone")))])])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;