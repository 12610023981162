"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _export = require("@/api/export");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'TableExport',
  props: {
    exportCurrentViewConfig: {
      type: Object,
      default: () => ({
        label: '仅导出视图展示字段',
        isThroughFrontEnd: false
      })
    },
    exportAllDataConfig: {
      type: Object,
      default: () => ({
        label: '导出所有字段',
        isThroughFrontEnd: false
      })
    },
    requestMethod: {
      type: Function,
      default: _export.exportTradeList
    },
    requestParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportType: 'view',
      loading: false
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async exportTable() {
      if (this.exportCurrentViewConfig.isThroughFrontEnd && this.exportType === 'view') {
        this.$emit('exportCurrentView');
        this.handleClose();
        return;
      }
      if (this.exportAllDataConfig.isThroughFrontEnd && this.exportType === 'all') {
        this.$emit('exportAll');
        this.handleClose();
        return;
      }
      const requestParams = (0, _commonFunc.deepClone)(this.requestParams);
      // 如果导出的视图是全部视图，清空head
      if (this.exportType === 'all' && requestParams.hasOwnProperty.call('head')) {
        requestParams.head = [];
      }
      const data = {
        column: this.exportType,
        ...requestParams
      };
      try {
        this.loading = true;
        const res = await this.requestMethod(data);
        if (res.code === 20000) {
          this.handleClose();
          if (this.$notify) {
            this.$notify.closeAll();
          }
          const h = this.$createElement;
          this.$notify({
            title: '导出文件',
            message: h('div', {
              class: 'notify-content'
            }, [h('span', '文件正在准备中，您可以在个人中心 - '), h('a', {
              class: 'jump-link',
              on: {
                click: this.goExportRecords
              }
            }, '导出记录'), h('span', '中查看。')]),
            duration: 5000,
            customClass: 'conversation-list-export-file-notify'
          });
        }
      } finally {
        this.loading = false;
      }
    },
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    }
  }
};
exports.default = _default;