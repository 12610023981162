"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FilterTab = _interopRequireDefault(require("@/components/FilterTab"));
var _feedback = require("@/api/feedback.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FeedbackForm',
  components: {
    FilterTab: _FilterTab.default
  },
  data() {
    return {
      feedbackData: {
        type: 1,
        description: '',
        images: [],
        contact: ''
      },
      typeList: [{
        name: '产品建议',
        value: 1
      }, {
        name: '问题反馈',
        value: 2
      }],
      submitLoading: false,
      submitDisable: false,
      previewImgUrl: '',
      previewDialogVisible: false,
      submitSuccess: false,
      isProgress: false
    };
  },
  computed: {
    textareaPlaceholder() {
      return this.feedbackData.type === 1 ? '您在使用什么功能时，希望我们做出哪些改进？' : '您在哪个界面，操作什么功能时，遇到了什么问题？';
    }
  },
  methods: {
    uploadFeedbackImages: _feedback.uploadFeedbackImages,
    typeTabClick(tab) {
      this.feedbackData.type = tab;
    },
    handlePictureCardPreview(file) {
      this.previewImgUrl = file.url;
      this.previewDialogVisible = true;
    },
    handleImagesChange(file, fileList) {
      this.uploadingImages = fileList;
      this.feedbackData.images = fileList.map(item => {
        var _item$response, _item$response$result;
        return (_item$response = item.response) === null || _item$response === void 0 ? void 0 : (_item$response$result = _item$response.results) === null || _item$response$result === void 0 ? void 0 : _item$response$result.url;
      });
    },
    beforeImageUpload(file) {
      const isImg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isSizeFit = file.size / 1024 / 1024 < 10;
      if (!isSizeFit) {
        this.$message.warning('上传图片大小不能超过10M');
      }
      if (!isImg) {
        this.$message.warning('上传图片格式暂不支持');
      }
      return isImg && isSizeFit;
    },
    onExceed() {
      this.$message.warning(`最多只能上传4个文件`);
    },
    initCloseDialog() {
      this.$emit('closeDialog');
      this.isResetClose = true;
    },
    resetForm() {
      if (this.isResetClose) {
        this.$refs.upload.clearFiles();
        this.$refs['feedbackForm'].resetFields();
        this.feedbackData = this.$options.data().feedbackData;
        this.$refs.filterTab.init();
        this.submitSuccess = false;
        this.isResetClose = false;
      }
    },
    feedbackSubmit() {
      var _this$uploadingImages;
      // 判断如果有正在上传的文件提示用户
      const hasUploading = (_this$uploadingImages = this.uploadingImages) === null || _this$uploadingImages === void 0 ? void 0 : _this$uploadingImages.find(item => item.status === 'ready');
      if (hasUploading) {
        this.$message.warning('有正在上传的图片，请上传完再提交');
        return;
      }
      this.$refs.feedbackForm.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true;
            const res = await (0, _feedback.createFeedback)(this.feedbackData);
            if (res.code === 20000) {
              this.submitSuccess = true;
            }
          } finally {
            this.submitLoading = false;
          }
        }
      });
    }
  }
};
exports.default = _default;