"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filterSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/filterSelect"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _FunnelChart = _interopRequireDefault(require("@/components/Charts/FunnelChart"));
var _FunnelTable = _interopRequireDefault(require("./components/FunnelTable"));
var _predictSettingDialog = _interopRequireDefault(require("./components/predictSettingDialog"));
var _num_formatter = require("@/utils/num_formatter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: '',
  components: {
    FunnelChart: _FunnelChart.default,
    FunnelTable: _FunnelTable.default,
    predictSettingDialog: _predictSettingDialog.default,
    BiFilter: _filterSelect.default
  },
  data() {
    return {
      funnelData: [],
      funnelMax: null,
      tableData: [],
      totalDealCount: 0,
      predictAmount: 0,
      changeNum: 0,
      isPredictDialogVisible: false,
      loading: false,
      closeTime: {
        type: 'create_time',
        options: {
          field_name: 'create_time',
          name: '预计成交日期',
          options: [['本月', '本季度', '今年'], ['上个月', '上个季度', '去年'], ['下个月', '下个季度', '明年']],
          type: 'date'
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes', 'timezone']),
    customFieldPermission() {
      return this.webRoutes.find(item => item.name === 'CustomField');
    }
  },
  methods: {
    async getFunnelData(val) {
      const params = {
        tree_id: val.tree_id,
        deal_range: val.create_time || [],
        user_ids: JSON.stringify(val.user_ids),
        time_zone: this.timezone
      };
      this.loading = true;
      const res = await (0, _businessIntelligence.getFunnelPrediction)(params);
      if (res.code === 200) {
        this.funnelData = res.results.map(item => {
          return {
            name: item.name,
            value: item.info.total,
            amount: item.info.amount
          };
        });
        // 漏斗不显示总计
        this.funnelData.pop();
        // 计算漏斗max值，100可以换为其它任意正整数
        const totalList = res.results.map(item => item.info.total);
        const max = Math.max(...totalList);
        const nonzero = 100;
        this.funnelMax = max || nonzero;
        this.tableData = res.results.map(item => {
          const isTotal = item.name === '合计';
          if (isTotal) {
            this.totalDealCount = item.info.total;
            this.predictAmount = item.info.predicted_amount;
          }
          return {
            dealStage: item.name,
            dealNumber: item.info.total,
            dealAmount: (0, _num_formatter.parseFormatNum)(item.info.amount),
            winRate: isTotal ? '-' : `${item.info.win_rate * 100}%`,
            predictAmount: (0, _num_formatter.parseFormatNum)(item.info.predicted_amount)
          };
        });
      }
      this.loading = false;
    },
    handlePredictSettingClick() {
      this.$router.push({
        path: _routeMap.ROUTES.customField,
        params: {
          tab: 'deal',
          menu: 'deal'
        }
      });
      // this.$refs.predictDialogRef.openDialog();
    },

    handlePredictChange() {
      this.getFunnelData();
    }
  }
};
exports.default = _default;