"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _useRouter = require("@/hooks/use-router");
var _SelectedLabel = _interopRequireDefault(require("./SelectedLabel.vue"));
var _SelectKnowledgesDrawer = _interopRequireDefault(require("@/views/convDetail/components/SelectKnowledgesDrawer"));
var _SelectSnippetsDrawer = _interopRequireDefault(require("@/views/convDetail/components/SelectSnippetsDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'InsightClipSelector',
  props: {},
  emits: ['cancel', 'clearProposals'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const route = (0, _useRouter.useRoute)();
    const knowledgeBaseInfo = (0, _vue.computed)(() => store.getters.knowledgeBaseInfo);
    const modulePermission = (0, _vue.computed)(() => store.getters.modulePermission);
    const selectedKnowledges = (0, _vue.ref)([]);
    const selectedSnippets = (0, _vue.ref)([]);
    const convId = route.query.id || '';
    const knowledgesDrawerVisible = (0, _vue.ref)(false);
    const snippetsDrawerVisible = (0, _vue.ref)(false);
    const setSelectedKnowledges = val => {
      selectedKnowledges.value = val;
    };
    const setSelectedSnippets = val => {
      selectedSnippets.value = val;
    };
    const getAddedKnowledges = () => {
      if (!selectedKnowledges.value.length) return [];
      return selectedKnowledges.value.map(item => {
        const {
          fid,
          name,
          id
        } = item;
        return {
          type: 'knowledge',
          name,
          info: {
            router_name: 'knowledgeLibraryDetail',
            router_params: {
              id,
              fid,
              base_id: knowledgeBaseInfo.value.id
            }
          }
        };
      });
    };
    const getAddedSnippets = () => {
      if (!selectedSnippets.value.length) return [];
      return selectedSnippets.value.map(item => {
        const {
          fid,
          name,
          id
        } = item;
        return {
          type: 'snippet',
          name,
          info: {
            router_name: 'libraryDetail',
            router_params: {
              id,
              fid
            }
          }
        };
      });
    };
    const deleteKnowledge = index => {
      selectedKnowledges.value.splice(index, 1);
    };
    const deleteSnippet = index => {
      selectedSnippets.value.splice(index, 1);
    };
    const addKnowledges = () => {
      knowledgesDrawerVisible.value = true;
    };
    const addSnippets = () => {
      snippetsDrawerVisible.value = true;
    };
    const clearProposals = () => {
      selectedKnowledges.value = [];
      selectedSnippets.value = [];
    };
    const fillProposals = proposals => {
      const knowledgeProposals = proposals.filter(item => item.type === 'knowledge');
      selectedKnowledges.value = knowledgeProposals.map(item => {
        return {
          id: item.info.router_params.id,
          fid: item.info.router_params.fid,
          name: item.name
        };
      });
      const snippetProposals = proposals.filter(item => item.type === 'snippet');
      snippetProposals.value = snippetProposals.map(item => {
        return {
          id: item.info.router_params.id,
          fid: item.info.router_params.fid,
          name: item.name
        };
      });
      setSelectedKnowledges(knowledgeProposals);
      setSelectedSnippets(snippetProposals);
    };
    expose({
      clearProposals,
      getAddedKnowledges,
      getAddedSnippets,
      fillProposals
    });
    return {
      __sfc: true,
      props,
      emit,
      store,
      route,
      knowledgeBaseInfo,
      modulePermission,
      selectedKnowledges,
      selectedSnippets,
      convId,
      knowledgesDrawerVisible,
      snippetsDrawerVisible,
      setSelectedKnowledges,
      setSelectedSnippets,
      getAddedKnowledges,
      getAddedSnippets,
      deleteKnowledge,
      deleteSnippet,
      addKnowledges,
      addSnippets,
      clearProposals,
      fillProposals,
      SelectedLabel: _SelectedLabel.default,
      SelectKnowledgesDrawer: _SelectKnowledgesDrawer.default,
      SelectSnippetsDrawer: _SelectSnippetsDrawer.default
    };
  }
};
exports.default = _default;