"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "video-templates"
  }, [_c("save-time-tip", {
    attrs: {
      "save-time": _vm.saveTime
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.noteEmpty,
      expression: "noteEmpty"
    }],
    staticClass: "insert-template"
  }, [_c("el-dropdown", {
    attrs: {
      trigger: "click"
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link",
    on: {
      click: _vm.getNoteTemplateList
    }
  }, [_vm._v(" 插入模板 ")]), _c("el-dropdown-menu", {
    staticClass: "template-lists",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.templatesList, function (item) {
    return _c("el-dropdown-item", {
      key: item.id,
      nativeOn: {
        click: function ($event) {
          return _vm.insertTemplate(item);
        }
      }
    }, [_vm._v(_vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;