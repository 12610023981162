"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'SimpleTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  emits: ['input'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    return {
      __sfc: true,
      emit
    };
  }
};
exports.default = _default;