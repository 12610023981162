"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-aside"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_c("i", {
    staticClass: "iconfont icon-s-opportunity"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.answeringSkills")))])]), _vm.knowledge.skill_list && _vm.knowledge.skill_list.length ? _c("knowledge-item-answers", {
    attrs: {
      list: _vm.knowledge.skill_list
    }
  }) : _c("p", {
    staticClass: "no-data"
  }, [_vm._v(_vm._s(_vm.$t("coaching.noAnsweringSkills")))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;