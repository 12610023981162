"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeConvDrawer',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    teamDataParams: {
      type: Object,
      default: () => ({})
    },
    activeKnowledge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      knowledgeConvParams: {
        knowledge_ids: [],
        page_size: 10,
        page_num: 1,
        start_time: '',
        end_time: '',
        host_id: ''
      },
      loading: false,
      convList: [],
      total: 0,
      convListLength: 0
    };
  },
  computed: {
    noMore() {
      return this.convListLength && this.convListLength === this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },
  watch: {
    activeKnowledge: {
      handler: function (val) {
        this.initData();
        this.knowledgeConvParams.knowledge_ids = [val.knowledge_id];
        this.knowledgeConvParams.host_id = val.user_id;
        this.getPersonalKnowledgeConv();
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.knowledgeConvParams.page_num = 1;
      this.convList = [];
      this.convListLength = 0;
      this.loading = false;
    },
    secondToTime: _timeFormatter.secondToTime,
    handleClose() {
      this.$emit('closeKnowledgeConvDrawer');
    },
    goConversationDetail(id) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&noback=true`);
    },
    // 获取右侧会话列表
    async getPersonalKnowledgeConv() {
      const {
        start_time,
        end_time
      } = this.teamDataParams;
      this.knowledgeConvParams.start_time = start_time;
      this.knowledgeConvParams.end_time = end_time;
      this.loading = true;
      const res = await (0, _businessIntelligence.personalKnowledgeConv)({
        ...this.knowledgeConvParams
      });
      if (res.code === 200) {
        res.results.data.forEach(item => {
          this.convList.push(item);
        });
        this.total = res.results.count;
        this.convListLength += res.results.data.length;
        this.knowledgeConvParams.page_num++;
      }
      this.loading = false;
    },
    load() {
      this.getPersonalKnowledgeConv();
    }
  }
};
exports.default = _default;