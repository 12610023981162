"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateClientsDialog = _interopRequireDefault(require("./CreateClientsDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AccountSeaHeaderFilters',
  components: {
    CreateClientsDialog: _CreateClientsDialog.default
  },
  props: {
    accountSeaVal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      createAccountSeaDialogVisible: false,
      searchValue: ''
    };
  },
  computed: {
    filterParams() {
      return {
        search_val: this.searchValue
      };
    }
  },
  watch: {
    filterParams: {
      handler() {
        this.$emit('change', this.filterParams);
      },
      deep: true
    }
  },
  methods: {
    handleAccountSeaClick() {
      this.createAccountSeaDialogVisible = true;
    },
    freshAccountSeaList(val) {
      this.$emit('freshAccountSeaList', val);
    },
    handleBulkAction(action) {
      this.$emit('bulkAction', action);
    }
  }
};
exports.default = _default;