"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ClientContactPersonList = _interopRequireDefault(require("@/views/clients/components/ClientContactPersonList.vue"));
var _AssociateContactDialog = _interopRequireDefault(require("@/views/businessDetail/components/AssociateContactDialog.vue"));
var _business = require("@/api/business.js");
var _client = require("@/api/client");
var _formatContactFormDescMixins = _interopRequireDefault(require("@/views/clients/formatContactFormDescMixins"));
var _MeetingButton = _interopRequireDefault(require("@/components/MeetingButton"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealOperateButton',
  components: {
    ContactPersonList: _ClientContactPersonList.default,
    AssociateContactDialog: _AssociateContactDialog.default,
    MeetingButton: _MeetingButton.default
  },
  mixins: [_formatContactFormDescMixins.default],
  props: {
    rowInfo: {
      type: Object,
      default: () => ({})
    },
    operateButtons: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: [Number, String],
      default: ''
    },
    isInRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dealId: null,
      contactList: [],
      // 商机联系人
      clientContactList: [],
      // 新建关联联系人的时候的客户联系人列表
      contactFormDesc: [],
      currentOperateType: '',
      contactPersonDialogVisible: false,
      associateContactDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    classObject() {
      return function (row, item) {
        return {
          'el-button-text--primary row-icon': item.isCircle,
          'hover-show-bg': !item.isCircle,
          'no-list': !row.contact_count && item.isCircle
        };
      };
    }
  },
  mounted() {
    // 如果有联系人列表出现时，点其他地方隐藏popover
    document.addEventListener('click', this.clickOtherHiddenPopover);
  },
  destroyed() {
    document.removeEventListener('click', this.clickOtherHiddenPopover);
  },
  methods: {
    getIsShowPopover(row, item) {
      var _this$contactList;
      return item.isShowList && this.activeId === row.deal_id && !!((_this$contactList = this.contactList) !== null && _this$contactList !== void 0 && _this$contactList.length) && this.currentOperateType === item.operateType;
    },
    createMeeting(meetingType) {
      this.$emit('createMeeting', meetingType);
    },
    async clickOperateBtn(row, item, meetingType) {
      var _this$contactList2;
      this.dealId = row.deal_id;
      this.currentOperateType = item.operateType;
      await this.getContactListByDealId();
      this.$emit('changeActiveDealId', row.deal_id, row.account_id);
      if (!item.isShowList && (_this$contactList2 = this.contactList) !== null && _this$contactList2 !== void 0 && _this$contactList2.length) {
        // 如果不展示列表直接用第一个联系人信息传入
        this.clickContactPersonItem(this.contactList[0], row, item.operateType, meetingType);
      }
    },
    async getContactListByDealId() {
      if (!this.dealId) return;
      this.contactList = [];
      const res = await (0, _business.getDealContacts)({
        deal_id: this.dealId
      });
      if (res.code === 200) {
        this.contactList = [...res.results.main, ...res.results.other];
      }
      if (!this.contactList.length) {
        // 如果没有联系人需要打开新建联系人
        this.$message.warning('暂无联系人，请先新建或关联联系人');
        if (this.api_permissions.includes('/api/deal/contact/deal_add_contact')) {
          setTimeout(() => {
            if (!this.isInRow) {
              this.openContactPersonDialog(this.rowInfo);
            } else {
              this.$emit('openContactPersonDialog', this.rowInfo);
            }
            this.dealId = null;
          }, 1000);
        }
      }
    },
    clickContactPersonItem(contactItem, row, type, meetingType) {
      this.$emit('clickContactPersonItem', contactItem, row, type, this.contactList, meetingType);
      this.dealId = null;
      this.hiddenPopover();
    },
    hiddenPopover() {
      this.currentOperateType = '';
    },
    async openContactPersonDialog(row) {
      const res = await (0, _client.getAllContactPersonList)(row.account_id);
      if (res.code === 200) {
        this.clientContactList = [];
        for (let i = 0; i < res.results.length; i++) {
          const obj = {
            label: res.results[i].name,
            value: res.results[i].id
          };
          this.clientContactList.push(obj);
        }
      }
      this.associateContactDialogVisible = true;
      this.$nextTick(() => {
        this.$refs['associateContactDialog'].$refs['associateContactForm'].clearValidate();
      });
    },
    clickOtherHiddenPopover(e) {
      if (e.target.className !== 'contact-list-comp') {
        this.hiddenPopover();
      }
    }
  }
};
exports.default = _default;