"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", {
    staticClass: "move-knowledge-scene"
  }, [_vm.order ? _c("el-tooltip", {
    attrs: {
      content: "上移",
      placement: "bottom"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-top",
    on: {
      click: _vm.moveUp
    }
  })]) : _vm._e(), _vm.order !== _vm.list.length - 1 ? _c("el-tooltip", {
    attrs: {
      content: "下移",
      placement: "bottom"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-bottom",
    on: {
      click: _vm.moveDown
    }
  })]) : _vm._e()], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;