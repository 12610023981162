"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _default = {
  name: 'ForewarnConfig',
  props: {
    forewarnConfigVisible: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      getForewarnKanbanLoading: false,
      submitForewarnKanbanLoading: false,
      config: [{
        type: 'quota',
        action: 'up',
        value: 0,
        is_enable: false
      }, {
        type: 'ring_ratio',
        action: 'down',
        value: 0,
        is_enable: false
      }, {
        type: 'between',
        from: 0,
        to: 0,
        is_enable: false
      }],
      configItemLabel: Object.freeze([['该数据指标', '时，触发预警！'], ['该数据指标环比', '时，触发预警！'], ['该数据指标介于', '与', '之间时 ，触发预警！']]),
      options: Object.freeze([[{
        label: '高于',
        value: 'up'
      }, {
        label: '低于',
        value: 'down'
      }], [{
        label: '上升',
        value: 'up'
      }, {
        label: '下降',
        value: 'down'
      }]])
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    forewarnConfigVisible(val) {
      if (val) {
        this.getForewarnConfig();
      }
    }
  },
  methods: {
    changeConfigValue(item, val) {
      let key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'value';
      item[key] = Number(val.replace(/[^\d]/g, ''));
    },
    handleClose() {
      this.$emit('update:forewarnConfigVisible', false);
      this.config = this.$options.data().config;
    },
    async forewarnKanban() {
      this.submitForewarnKanbanLoading = true;
      const res = await (0, _businessIntelligence.editForewarnConfig)({
        card_id: this.cardId,
        config: this.config,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.submitForewarnKanbanLoading = false;
      });
      if (res.code === 20000) {
        this.$message.success('保存成功');
        this.$emit('openForewarn', this.cardId, this.config.some(_ref => {
          let {
            is_enable
          } = _ref;
          return Boolean(is_enable);
        }));
        this.handleClose();
      }
    },
    async getForewarnConfig() {
      this.getForewarnKanbanLoading = true;
      const res = await (0, _businessIntelligence.getForewarnConfig)({
        card_id: this.cardId,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.getForewarnKanbanLoading = false;
      });
      if (res.code === 20000) {
        this.config = res.results.config;
      }
    }
  }
};
exports.default = _default;