"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "multi-score-set-content"
  }, [_vm._l(_vm.scoreExtItems, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "score-set-item"
    }, [_c("div", {
      staticClass: "left-content"
    }, [_vm._v(" 第 "), _c("div", {
      staticClass: "count-input-content"
    }, [_c("el-input", {
      attrs: {
        disabled: !!index,
        oninput: "value=value.replace(/^0|[^\\d]/g,'');"
      },
      model: {
        value: item.gte,
        callback: function ($$v) {
          _vm.$set(item, "gte", $$v);
        },
        expression: "item.gte"
      }
    }), _c("span", [_vm._v("-")]), _c("el-input", {
      staticClass: "right-input",
      attrs: {
        oninput: "value=value.replace(/^0|[^\\d]/g,'');"
      },
      on: {
        input: function ($event) {
          return _vm.$emit("handleScore", item, index);
        }
      },
      model: {
        value: item.lte,
        callback: function ($$v) {
          _vm.$set(item, "lte", $$v);
        },
        expression: "item.lte"
      }
    }), _vm.isValidError(item) ? _c("span", {
      staticClass: "err-tip"
    }, [_vm._v("后项不能小于前项")]) : _vm._e()], 1), _vm._v(" 次，每次"), _c("span", {
      staticClass: "model-tag",
      class: _vm.scoreMode
    }, [_vm._v(_vm._s(_vm.scoreMode === "decrease" ? "减" : "加"))]), _c("el-input", {
      staticClass: "score-input-item",
      attrs: {
        oninput: "value=value.replace(/^0|[^\\d]/g,'');"
      },
      model: {
        value: item.score,
        callback: function ($$v) {
          _vm.$set(item, "score", $$v);
        },
        expression: "item.score"
      }
    }, [_c("span", {
      staticClass: "unit",
      attrs: {
        slot: "suffix"
      },
      slot: "suffix"
    }, [_vm._v("分")])])], 1), index ? _c("i", {
      staticClass: "iconfont icon-close",
      on: {
        click: function ($event) {
          return _vm.$emit("deleteScoreRules", index);
        }
      }
    }) : _vm._e()]);
  }), _c("el-button", {
    staticClass: "add-score-button",
    attrs: {
      plain: "",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("addScoreRule");
      }
    }
  }, [_vm._v("计分规则")]), _c("div", {
    staticClass: "result-text-content"
  }, [_c("div", {
    staticClass: "description"
  }, [_vm.scoreExtItems[0].gte > 1 ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.scoreExtItems[0].gte))]), _vm._v("次以下、 ")]) : _vm._e(), _vm.scoreExtItems[_vm.scoreExtItems.length - 1].lte ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.scoreExtItems[_vm.scoreExtItems.length - 1].lte))]), _vm._v("次以上 不加分 ")]) : _vm._e()]), _c("div", {
    staticClass: "total-score"
  }, [_vm._v(" 最多" + _vm._s(_vm.scoreMode === "decrease" ? "减" : "加")), _c("span", [_vm._v(_vm._s(_vm.totalScore))]), _vm._v("分 ")])])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;