"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WorkspaceTable = _interopRequireDefault(require("./components/WorkspaceTable"));
var _OperateWorkspaceDialog = _interopRequireDefault(require("./components/OperateWorkspaceDialog"));
var _TransferConversationDialog = _interopRequireDefault(require("@/components/TransferConversationDialog"));
var _DepartmentAndWorkspaceMappingDrawer = _interopRequireDefault(require("@/views/workspace/components/DepartmentAndWorkspaceMappingDrawer"));
var _SpaceDescriptionPopover = _interopRequireDefault(require("@/components/SpaceDescriptionPopover"));
var _workspace = require("@/api/workspace");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Workspace',
  components: {
    WorkspaceTable: _WorkspaceTable.default,
    OperateWorkspaceDialog: _OperateWorkspaceDialog.default,
    TransferConversationDialog: _TransferConversationDialog.default,
    DepartmentAndWorkspaceMappingDrawer: _DepartmentAndWorkspaceMappingDrawer.default,
    SpaceDescriptionPopover: _SpaceDescriptionPopover.default
  },
  data() {
    return {
      dialogVisible: false,
      transferConversationDialogVisible: false,
      departmentAndWorkspaceMappingVisible: false,
      tableLoading: false,
      operateType: '',
      currentWorkspaceInfo: {},
      loading: false,
      tableData: [],
      hasCreatePermission: true,
      maxCount: 0,
      totalCount: 0,
      convCount: 0,
      recycleConvCount: 0,
      departmentAndWorkspaceMappingList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['hasWorkspace', 'interfacePermission'])
  },
  created() {
    this.getWorkspaceList();
    this.getDepartmentAndWorkspaceMappingList();
  },
  methods: {
    openTransferConversationDialog(row, countObj) {
      this.transferConversationDialogVisible = true;
      this.currentWorkspaceInfo = row;
      this.convCount = countObj.conv_count;
      this.recycleConvCount = countObj.recycle_conv_count;
    },
    goHelpCenter() {
      window.open('https://www.megaview.com/help-center/doc?docx=Ik3odwYwEo2NmpxVQQIcZcLvnbh&anchor=AAEOdgiEMoAW0kxGOcWcLAFvnsd', '_blank');
    },
    openDialog(type, row) {
      if (type === 'add') {
        if (!this.hasWorkspace) {
          this.$message({
            message: '您的版本暂不支持此功能，请联系销售升级',
            type: 'warning'
          });
          return;
        }
        if (!(this.hasCreatePermission && this.totalCount < this.maxCount)) {
          this.$message({
            message: '空间数量超过上限，请联系销售升级',
            type: 'warning'
          });
          return;
        }
      }
      this.dialogVisible = true;
      this.operateType = type;
      this.currentWorkspaceInfo = row || {};
    },
    getWorkspaceList() {
      this.loading = true;
      (0, _workspace.getWorkspaceList)({
        page: 1,
        size: 20
      }).then(res => {
        if (res.code === 20000) {
          this.tableData = res.results.workspaces;
          this.hasCreatePermission = res.results.can_create;
          this.maxCount = res.results.max_count;
          this.totalCount = res.results.total_count;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    async getDepartmentAndWorkspaceMappingList() {
      const res = await (0, _workspace.getDepartmentAndWorkspaceMappingList)();
      if (res.code === 20000) {
        this.departmentAndWorkspaceMappingList = res.results.workspace_list;
      }
    },
    async batchTransferConversation(targetWorkspaceId) {
      const data = {
        origin_workspace_id: this.currentWorkspaceInfo.id,
        target_workspace_id: targetWorkspaceId
      };
      try {
        const res = await (0, _workspace.batchTransferConversation)(data);
        if (res.code === 200 || res.code === 20000) {
          this.$refs.transferConversationDialog.closeDialog();
          this.$message({
            message: '转移已开始，请耐心等候，完成时消息通知',
            type: 'success'
          });
        }
      } finally {
        this.$refs.transferConversationDialog.endButtonLoading();
      }
    },
    openDepartmentAndWorkspaceMappingDialog() {
      this.departmentAndWorkspaceMappingVisible = true;
    },
    closeDepartmentAndWorkspaceMappingDialog() {
      this.departmentAndWorkspaceMappingVisible = false;
    },
    confirmToChangeDepartmentAndSpace(mappingInfo) {
      this.setDepartmentAndWorkspaceMapping(mappingInfo);
    },
    async setDepartmentAndWorkspaceMapping(mappingInfo) {
      if (!mappingInfo.departmentIds.length || !mappingInfo.workspaceId) {
        this.$message({
          type: 'warning',
          message: '部门不能为空！'
        });
        return;
      }
      const params = {
        tree_ids: mappingInfo.departmentIds,
        workspace_id: mappingInfo.workspaceId
      };
      const res = await (0, _workspace.setDepartmentAndWorkspaceMapping)(params);
      this.tableLoading = true;
      if (res.code === 20000) {
        try {
          const res = await (0, _workspace.getDepartmentAndWorkspaceMappingList)();
          if (res.code === 20000) {
            this.departmentAndWorkspaceMappingList = res.results.workspace_list;
            this.$message({
              type: 'success',
              message: '部门与空间关系更新成功！'
            });
          }
        } finally {
          this.tableLoading = false;
        }
      } else {
        this.$message({
          type: 'warning',
          message: '部门与空间关系更新失败！'
        });
      }
    }
  }
};
exports.default = _default;