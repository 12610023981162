"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.has_video_permission ? _c("div", {
    staticClass: "meeting-button",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [!_vm.has_tencent_meeting ? _c("el-button", {
    class: _vm.customClass,
    on: {
      click: function ($event) {
        return _vm.$emit("click");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "reservation-2"
    }
  }), _vm._t("default")], 2) : _c("el-dropdown", [_c("el-button", {
    class: _vm.customClass,
    on: {
      click: _vm.handleClick
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "reservation-2"
    }
  }), _vm._t("default")], 2), _c("el-dropdown-menu", {
    staticClass: "meeting-options-popper",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.has_tencent_meeting ? _c("el-dropdown-item", [_c("div", {
    staticClass: "option-row",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleTencentMeeting
    }
  }, [_c("img", {
    staticClass: "icon-meeting",
    attrs: {
      src: _vm.tencent_meeting
    }
  }), _vm._v(" 腾讯会议 ")])]) : _vm._e()], 1)], 1)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;