"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetModifyConvInfoHistory = useGetModifyConvInfoHistory;
var _vue = require("vue");
var _videoDetail = require("@/api/videoDetail");
function useGetModifyConvInfoHistory(props) {
  const tableData = (0, _vue.ref)([]);
  const tableLoading = (0, _vue.ref)(false);
  const getModifyConvInfoHistoryApi = async () => {
    tableLoading.value = true;
    const res = await (0, _videoDetail.getModifyConvInfoHistory)({
      conversation_id: props.convId
    }).finally(() => {
      tableLoading.value = false;
    });
    if (res.code === 20000) {
      tableData.value = res.results.datas;
    }
  };
  (0, _vue.watch)(() => props.drawerVisible, val => {
    if (val) {
      getModifyConvInfoHistoryApi();
    }
  });
  return {
    getModifyConvInfoHistoryApi,
    tableData,
    tableLoading
  };
}