"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.columnDataArr.length ? _c("div", {
    staticClass: "event-tag",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_vm.columnDataArr[0] ? _c("el-tag", {
    staticClass: "event-label"
  }, [_vm._v(" " + _vm._s(_vm.handleTagName(_vm.columnDataArr[0][`${_vm.nameKey}`])) + " ")]) : _vm._e(), _vm.columnDataArr.length > 1 ? _c("el-tag", {
    staticClass: "event-append",
    attrs: {
      effect: "dark"
    }
  }, [_vm._v(" " + _vm._s(`+${_vm.columnDataArr.length - 1}`) + " ")]) : _vm._e()], 1) : _c("span", [_vm._v("-")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;