"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "rightDialog",
    staticClass: "right-dialog-wrapper",
    class: {
      "bar-active": _vm.rightBarActive
    }
  }, [_c("div", {
    staticClass: "drawer-title"
  }, [_c("div", {
    staticClass: "drawer-title-left",
    class: {
      "notes-title-left": _vm.rightBarActive === "notes"
    }
  }, [_c("span", {
    staticClass: "drawer-title-name"
  }, [_vm._v(_vm._s(_vm.getRightDialogTitle()))]), _vm.noteSavedTime && _vm.rightBarActive === "notes" ? _c("save-time-tip", {
    attrs: {
      "save-time": _vm.noteSavedTime
    }
  }) : _vm._e(), _vm.rightBarActive === "convInfo" ? _c("ConversationInfoTitle", {
    on: {
      openModifyConvInfoHistoryDrawer: _vm.openModifyConvInfoHistoryDrawer
    }
  }) : _vm._e()], 1), _vm.rightBarActive === "notes" ? _c("note-template", {
    attrs: {
      "note-data": _vm.noteData,
      "note-empty": _vm.noteIsEmpty
    },
    on: {
      showNote: _vm.handleShowNote
    }
  }) : _vm._e(), ["AISummary", "extractInfo"].includes(_vm.rightBarActive) ? _c("mgv-button", {
    attrs: {
      icon: "icon-refresh-right",
      type: "text",
      size: "small",
      color: "info",
      square: "",
      "tooltip-content": "再次运行指令"
    },
    on: {
      click: _vm.manualExecution
    }
  }) : _vm._e(), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: _vm.closeDrawer
    }
  })], 1), _c("div", {
    staticClass: "drawer-content-comments"
  }, [_vm.rightBarActive === "comments" ? _c("comments-area", {
    key: _vm.convId,
    ref: "comments",
    attrs: {
      "comment-type": _vm.commentType,
      "department-manager-info": _vm.departmentManagerInfo
    },
    on: {
      getUnTeachList: _vm.getUnTeachList,
      getCommentsCount: function ($event) {
        return _vm.$emit("getCommentsCount", $event);
      }
    }
  }) : _vm.rightBarActive === "notes" ? _c("note-editor", {
    ref: "note-editor",
    attrs: {
      "conversation-id": _vm.convId
    },
    on: {
      saveSucceed: _vm.handleNoteSaved,
      change: _vm.handleNoteChange,
      ready: _vm.handleNoteChange,
      saveNewTemplate: _vm.handleSaveNewTemplate
    }
  }) : _vm.rightBarActive === "correctKeyEventHistory" ? _c("CorrectKeyEventHistory", {
    key: _vm.convId,
    attrs: {
      "conversation-id": _vm.convId,
      "media-type": _vm.mediaType,
      "participants-info": _vm.participantsInfo
    }
  }) : _vm.rightBarActive === "correctFlipRoleHistory" ? _c("CorrectFlipRoleHistory", {
    key: _vm.convId,
    attrs: {
      "conversation-id": Number(_vm.convId),
      "media-type": _vm.mediaType
    }
  }) : _vm.rightBarActive === "correctTextContainer" ? _c("CorrectTextContainer", {
    ref: "correctTextContainer",
    on: {
      selectTransText: function ($event) {
        return _vm.$emit("selectTransText");
      }
    }
  }) : _vm.rightBarActive === "compliance" || _vm.rightBarActive === "evaluation" ? _c("ComplianceDetail", {
    key: _vm.convId,
    attrs: {
      "right-bar-active": _vm.rightBarActive,
      "conversation-id": _vm.convId,
      "media-type": _vm.mediaType,
      "participants-info": _vm.participantsInfo,
      "only-evaluation": _vm.onlyEvaluation,
      "compliance-status": _vm.complianceStatus
    },
    on: {
      changeComplianceStatus: function ($event) {
        return _vm.$emit("update:complianceStatus", $event);
      },
      changeRightBar: function ($event) {
        return _vm.$emit("changeRightBar", $event);
      }
    }
  }) : _vm.rightBarActive === "convInfo" ? _c("ConversationInfoContainer", _vm._g({
    key: _vm.convId,
    attrs: {
      "conversation-detail-info": _vm.conversationDetailInfo,
      "participants-info": _vm.participantsInfo
    }
  }, _vm.$listeners)) : _vm.rightBarActive === "AICoaching" ? _c("AICoachingContent", {
    attrs: {
      "conversation-detail-info": _vm.conversationDetailInfo
    },
    on: {
      applyAICoachingSuccess: _vm.applyAICoachingSuccess
    }
  }) : ["extractInfo", "AISummary"].includes(_vm.rightBarActive) ? _c("SummaryResultDisplay", {
    attrs: {
      info: _vm.rightBarActive === "AISummary" ? _vm.getAISummaryDetail() : _vm.getExtractInfoDetail(),
      "right-bar-active": _vm.rightBarActive
    },
    on: {
      manualExecution: _vm.manualExecution
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;