"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkDealStages = checkDealStages;
exports.checkFieldOptionDelete = checkFieldOptionDelete;
exports.deleteCustomField = deleteCustomField;
exports.editCustomField = editCustomField;
exports.getCustomFieldList = getCustomFieldList;
exports.getDealStages = getDealStages;
exports.moveCustomFieldPosition = moveCustomFieldPosition;
exports.updateDealStage = updateDealStage;
var _request = require("@/utils/request");
/** 与自定义字段相关的接口
 * @config dataType:'json'；表示使用json传递参数
 */

// 获取商机阶段
function getDealStages(params) {
  return (0, _request.request)({
    url: '/field/field/deal/stage',
    method: 'get',
    params
  });
}

// 编辑商机阶段
function updateDealStage(data) {
  return (0, _request.request)({
    url: '/field/field/deal/stage',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 检查自定义商机阶段可删除
function checkDealStages(params) {
  return (0, _request.request)({
    url: '/field/field/deal/stage_check',
    method: 'get',
    params
  });
}

// 字段删除选项检测
function checkFieldOptionDelete(data) {
  return (0, _request.webService)({
    url: '/field/option/delete_check',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取字段列表
function getCustomFieldList(params) {
  return (0, _request.request)({
    url: '/field/field/list',
    method: 'get',
    params
  });
}

// 删除字段
function deleteCustomField(data) {
  return (0, _request.request)({
    url: '/field/field/delete',
    method: 'post',
    data
  });
}

// 新建或编辑字段
function editCustomField(data) {
  return (0, _request.request)({
    url: '/field/field/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移动字段位置
function moveCustomFieldPosition(data) {
  return (0, _request.request)({
    url: '/field/field/move',
    method: 'post',
    data
  });
}