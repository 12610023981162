"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "multi-score-set-content"
  }, [_vm._l(_vm.scoreExtItems, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "score-set-item"
    }, [_c("div", {
      staticClass: "left-content"
    }, [_vm._v(" 任意 "), _c("div", {
      staticClass: "count-input-content"
    }, [_c("el-input", {
      attrs: {
        oninput: "value=value.replace(/^0|[^\\d]/g,'');"
      },
      model: {
        value: item.hits,
        callback: function ($$v) {
          _vm.$set(item, "hits", $$v);
        },
        expression: "item.hits"
      }
    }, [_c("span", {
      staticClass: "unit",
      attrs: {
        slot: "suffix"
      },
      slot: "suffix"
    }, [_vm._v("项")])]), _c("div", {
      staticClass: "err-tip"
    }, [_vm.isCountValidError(item) ? _c("span", [_vm._v("不能大于已选条件数")]) : _vm._e(), _vm.isRepeatValidError(item) ? _c("span", [_vm._v("项数不能重复")]) : _vm._e()])], 1), _c("span", {
      staticClass: "model-tag",
      class: _vm.scoreMode
    }, [_vm._v(_vm._s(_vm.scoreMode === "decrease" ? "减" : "加"))]), _c("el-input", {
      staticClass: "score-input-item",
      attrs: {
        oninput: "value=value.replace(/^0|[^\\d]/g,'');"
      },
      model: {
        value: item.score,
        callback: function ($$v) {
          _vm.$set(item, "score", $$v);
        },
        expression: "item.score"
      }
    }, [_c("span", {
      staticClass: "unit",
      attrs: {
        slot: "suffix"
      },
      slot: "suffix"
    }, [_vm._v("分")])])], 1), index ? _c("i", {
      staticClass: "iconfont icon-close",
      on: {
        click: function ($event) {
          return _vm.$emit("deleteScoreRules", index);
        }
      }
    }) : _vm._e()]);
  }), _c("div", {
    staticClass: "footer-content"
  }, [_c("el-button", {
    staticClass: "add-score-button",
    attrs: {
      plain: "",
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("addScoreRule");
      }
    }
  }, [_vm._v("计分规则")]), _c("div", {
    staticClass: "total-score"
  }, [_vm._v(" 最多" + _vm._s(_vm.scoreMode === "decrease" ? "减" : "加")), _c("span", [_vm._v(_vm._s(_vm.totalScore))]), _vm._v("分 ")])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;