"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepartmentSelect = _interopRequireDefault(require("@/components/DepartmentSelect"));
var _CreateDate = _interopRequireDefault(require("@/components/Filters/components/CreateDate"));
var _EventSelect = _interopRequireDefault(require("./components/EventSelect"));
var _FilterBasisSelect = _interopRequireDefault(require("./components/FilterBasisSelect"));
var _DealCustomFieldFilter = _interopRequireDefault(require("@/components/DealCustomFieldFilter"));
var _index = _interopRequireDefault(require("@/components/AmountRange/index"));
var _ScoreSelect = _interopRequireDefault(require("@/components/Filters/components/ScoreSelect"));
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _index2 = _interopRequireDefault(require("@/components/MembersFinder/index"));
var _timezone = require("@/utils/timezone");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _client = require("@/api/client");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _index3 = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BiFilter',
  components: {
    DepartmentSelect: _DepartmentSelect.default,
    CreateDate: _CreateDate.default,
    EventSelect: _EventSelect.default,
    DealCustomFieldFilter: _DealCustomFieldFilter.default,
    FilterBasisSelect: _FilterBasisSelect.default,
    AmountRangeSelect: _index.default,
    ScoreSelect: _ScoreSelect.default,
    MembersFinder: _index2.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    MgvButton: _index3.default
  },
  props: {
    layout: {
      type: Array,
      default: () => []
    },
    hasDealCustomFieldFilter: {
      type: Boolean,
      default: false
    },
    params: {
      type: [Object, String],
      default: () => ({})
    },
    defaultMoreFilter: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filters: {
        departmentOrMembersType: 'tree_ids',
        tree_id: [],
        tree_ids: [],
        user_ids: [],
        tree_user_ids: {},
        labels: '',
        month: '',
        date: '',
        day: 30,
        type: 'all',
        create_time: '',
        info_source: '',
        event_id: '',
        exclude_event_list: [],
        analysis_basis: 'conversation',
        entity_type: 'all',
        conversation_counts: [],
        node_counts: [1, 13],
        // 业务路径关键事件节点数
        event_tracker: {
          keyEventCascaderList: [{
            keyEventName: [],
            inverse: false
          }],
          event_engine_relation: 'and'
        },
        trade_status: []
      },
      tree_id_label: '',
      departmentAndMemberData: [],
      // 部门和成员多选数据
      tree_ids_label: '',
      userObjArr: [],
      isShowDepartmentOrMembersSelect: false,
      departmentOrMembersOptions: [{
        label: '按部门筛选',
        value: 'tree_ids'
      }, {
        label: '按成员筛选',
        value: 'tree_user_ids'
      }],
      conversationTypeOptions: [{
        label: '电话',
        value: 'audio',
        icon: 'phone-outline'
      }, {
        label: '在线会议',
        value: 'video',
        icon: 'video-camera'
      }, {
        label: '文字',
        value: 'doc',
        icon: 'im'
      }],
      conversationOptions: [{
        label: '所有会话',
        value: 'all'
      }, {
        label: '商机的会话',
        value: 'deal'
      }],
      conversationSceneOptions: [{
        label: '单聊',
        value: 'single'
      }, {
        label: '群聊',
        value: 'multiple'
      }],
      datePickerOptions: {},
      daySelectOption: [7, 14, 30, 60, 90],
      createTimeOptions: {
        field_name: 'create_time',
        name: '创建时间',
        options: [['本月', '本季度', '今年'], ['上个月', '上个季度', '去年'], ['下个月', '下个季度', '明年']],
        show: 1,
        type: 'date'
      },
      customFieldParams: {},
      conversationCount: {
        name: '会话次数'
      },
      scoreRange: [1, 13],
      defaultRange: [4, 13],
      scoreSuggestion: '建议：筛选节点数大于等于4的路径，节点数小于4的路径可能包含数据缺失的情况导致分析不准确。',
      conversationType: ['audio', 'video', 'doc'],
      showSaveFilterBar: false,
      dealStageOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'biPersonalFilterTime', 'workspaceInfo']),
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          return _this.isEarlyThan(time);
        }
      };
    },
    // 整理配置项
    filtersConfig() {
      const newLayout = this.layout.map(item => {
        if (item === 'select_department_member_type' || item.type === 'select_department_member_type') {
          this.isShowDepartmentOrMembersSelect = true;
        }
        return typeof item === 'string' ? {
          type: item
        } : item;
      });
      if (this.isShowDepartmentOrMembersSelect) {
        var _this$layout$find;
        newLayout.unshift({
          type: this.filters.departmentOrMembersType,
          params: (_this$layout$find = this.layout.find(_ref => {
            let {
              type
            } = _ref;
            return type === 'select_department_member_type';
          })) === null || _this$layout$find === void 0 ? void 0 : _this$layout$find.params
        });
      }
      return newLayout;
    },
    // 业务分析
    isFilterEventId() {
      return this.$route.path !== _routeMap.ROUTES.biBusinessAnalysis;
    },
    // 是否是会话维度相关页面
    isDimensionPage() {
      return [_routeMap.ROUTES.biDimension, _routeMap.ROUTES.biPersonalDimension].includes(this.$route.path);
    },
    needLocalStorageFiltersPage() {
      // 需要本地存储筛选项的页面
      return [_routeMap.ROUTES.biSOPFunnel, _routeMap.ROUTES.biTeamAnalysis].includes(this.$route.path);
    },
    analysisBasis() {
      return this.filtersConfig.find(item => item.type === 'analysis_basis');
    },
    showCustomFieldFilter() {
      var _this$analysisBasis;
      const showCustomFieldType = ['analysis_basis', 'entity_type'];
      const CustomFilterContronller = this.filtersConfig.find(item => showCustomFieldType.includes(item.type));
      const isSelectDeal = CustomFilterContronller ? this.filters[CustomFilterContronller.type] === 'deal' : true;
      return this.hasDealCustomFieldFilter && isSelectDeal || ((_this$analysisBasis = this.analysisBasis) === null || _this$analysisBasis === void 0 ? void 0 : _this$analysisBasis.hasDealCustomFieldFilter);
    },
    hasBasisSelect() {
      return this.layout.find(item => item.type === 'entity_type');
    },
    hasExportCsv() {
      return this.layout.find(item => item.type === 'exportCsv');
    },
    getStorageCustomFilters() {
      let storageCustomFilters = {};
      if (this.needLocalStorageFiltersPage) {
        const storageKey = `org${this.orgId}-page_${this.$route.name}-bi-custom-filter`;
        const storageFilters = localStorage.getItem(storageKey) && JSON.parse(localStorage.getItem(storageKey));
        storageCustomFilters = storageFilters || {};
      }
      return storageCustomFilters;
    }
  },
  watch: {
    $route() {
      this.filtersChange();
    },
    'filters.event_tracker': {
      handler: function (val) {
        this.filtersChange();
      },
      deep: true
    },
    'workspaceInfo.id': {
      // 切换空间清空已选择的关键事件，防止旧关键事件到新的空间发起请求
      handler: function () {
        this.filters.event_list = [];
        this.filters.exclude_event_list = [];
        this.filtersChange();
      }
    }
  },
  created() {
    this.filters.analysis_basis = localStorage.getItem('biFilterAnalysisBasis') || 'conversation';
    const self = this;
    this.datePickerOptions = {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: [{
        text: '最近一周',
        onClick(picker) {
          self.setDatePickerOptions(picker, 7);
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          self.setDatePickerOptions(picker, 30);
        }
      }, {
        text: '最近三个月',
        onClick(picker) {
          self.setDatePickerOptions(picker, 90);
        }
      }]
    };
    this.getDealStageOptions();
  },
  mounted() {
    this.filters.date = this.biPersonalFilterTime;
    let conversationType = JSON.parse(sessionStorage.getItem('bi_activity_conversation_type') || '[]');
    const conversationSceneType = JSON.parse(sessionStorage.getItem('bi_activity_conversation_scene_type') || '[]');
    const hasDoc = sessionStorage.getItem('bi_activity_conversation_type_has_doc');
    // 如果跳到会话维度页面把doc去掉
    if (this.isDimensionPage) {
      conversationType = conversationType.filter(type => type !== 'doc');
    }
    // 如果跳之前会话活动有doc，去到会话维度后再回活动需要把doc加回来
    if ([_routeMap.ROUTES.biActivity, _routeMap.ROUTES.biPersonalActivity].includes(this.$route.path) && hasDoc === 'true' && !conversationType.includes('doc')) {
      conversationType.push('doc');
    }
    this.conversationType = conversationType;
    this.filters.conversation_scene_type = conversationSceneType;
    // 回填筛选项
    // this.backFillFilters();
    if (this.needLocalStorageFiltersPage) {
      this.customFieldParams = this.getStorageCustomFilters;
    }

    // 只有部门选择器会动态根据根节点选中根节点，在首次进入时主动触发filtersChange事件，所以当没有部门节点时要手动触发
    // 如果成员选择器放开回填数据且有默认保存数据时也应该加入成员选择器
    const canAutoTriggerChange = ['tree_id'];
    this.filtersConfig.some(item => {
      return canAutoTriggerChange.includes(item.type);
    }) || this.filtersChange();
  },
  methods: {
    getOrgTree: _organization.getOrgTree,
    getUserList: _organization.getUserList,
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    setDatePickerOptions(picker, count) {
      const start = this.$moment().subtract(count, 'days').format('YYYY-MM-DD');
      const end = this.$moment().format('YYYY-MM-DD');
      picker.$emit('pick', [start, end]);
    },
    isEarlyThan(time) {
      const _time = parseInt(this.$moment(time).format('YYYYMMDD'));
      const now = parseInt(this.$moment().format('YYYYMMDD'));
      return _time > now;
    },
    // 选择部门-单选
    departmentLabelChange(labels) {
      this.tree_id_label = labels;
      this.filtersChange();
    },
    // 选择部门-多选
    departmentMultipleChange(ids, val) {
      this.filters.tree_ids = ids;
      this.tree_ids_label = val.map(_ref2 => {
        let {
          name
        } = _ref2;
        return name;
      });
      this.filtersChange();
    },
    // 创建时间
    createTimeChange(_ref3) {
      let {
        create_time
      } = _ref3;
      this.filters.create_time = create_time;
      this.filtersChange();
    },
    // 会话筛选条件(商机/会话)切换
    handleFilterBasisChange(value, key) {
      this.setFiltersValue(value, key);
      setTimeout(() => {
        this.$emit('dataPickerTooltipChange');
      }, 1000);
    },
    // 部门与成员多选
    departmentAndMemberChange(tree_user_ids, data) {
      this.departmentAndMemberData = data;
      this.setFiltersValue(tree_user_ids, 'tree_user_ids');
    },
    // 选择关键事件, 选择销售, 部门成员混选
    setFiltersValue(value, key) {
      this.$set(this.filters, key, value);
      this.filtersChange();
    },
    // 交易阶段变化处理
    tradeStatusChange(val) {
      this.setFiltersValue(val, 'trade_status');
      // 需要缓存用户上次选过的交易阶段
      localStorage.setItem(`org${this.orgId}-bi-filter-trade-status`, JSON.stringify(val));
    },
    analysisBasisChange(val) {
      localStorage.setItem('biFilterAnalysisBasis', val);
      this.filtersChange();
    },
    handleDateRangeChange() {
      this.filtersChange();
    },
    // 筛选项变化
    filtersChange() {
      this.formatTime();

      // 获取配置项作为要传递出去参数的key
      const filtersConfig = this.filtersConfig.map(item => item.type);

      // 对要传递出去的参数进行过滤
      const params = this.formatParams(filtersConfig);
      // 校验必填参数成功后触发filtersChange
      this.checkRequireParams(params) && this.debounceFiltersChange(params);

      // 记忆筛选项
      this.saveFilters();
    },
    debounceFiltersChange: (0, _commonFunc.debounce)(function (params) {
      const cloneParams = (0, _cloneDeep.default)(params);
      cloneParams.conversation_type = this.conversationType;
      (0, _timezone.convertParams2UTCplus8)(cloneParams);
      this.$emit('filtersChange', cloneParams);
    }),
    formatTime() {
      const date_filter = this.filtersConfig.find(item => item.type === 'date');
      // 时间筛选器不需要默认时间
      if (date_filter !== null && date_filter !== void 0 && date_filter.noDefaultDate && !this.filters.date) {
        this.filters.date = '';
        return;
      }
      // 时间筛选器必须非空
      if (!this.filters.date) {
        const start = this.$moment().subtract(30, 'days').format('YYYY-MM-DD');
        const end = this.$moment().format('YYYY-MM-DD');
        this.filters.date = [start, end];
        this.$nextTick(() => {
          this.filters.date = [start, end];
        });
      }
      if (!this.filters.month) {
        if (this.biPersonalFilterTime) {
          this.filters.month = this.biPersonalFilterTime;
        } else {
          this.filters.month = this.$moment().format('YYYY-MM');
        }
      }
    },
    // 对要传递出去的参数进行过滤
    formatParams(filtersConfig) {
      const newParams = {};
      filtersConfig.map(key => {
        newParams[key] = this.filters[key];
      });
      if (newParams.tree_id) {
        newParams.tree_id = newParams.tree_id[0];
      }
      if (this.isShowDepartmentOrMembersSelect) {
        newParams.data_dimension = this.filters.departmentOrMembersType;
      }
      if (newParams.date) {
        newParams.date = JSON.stringify([this.$moment(newParams.date[0]).format('YYYY-MM-DD'), this.$moment(newParams.date[1]).format('YYYY-MM-DD')]);
      }
      if (this.showCustomFieldFilter) {
        newParams.custom_filters = this.customFieldParams;
      }
      if (this.$route.path === _routeMap.ROUTES.biBusinessAnalysis) {
        const order = ['销售一组', '销售二组', '销售三组'];
        const tree_ids_label = this.tree_ids_label && this.tree_ids_label.length ? this.tree_ids_label.slice().sort((a, b) => {
          return order.indexOf(a) - order.indexOf(b);
        }) : ['default'];
        newParams.demo_tree_name = tree_ids_label.join('_');
      }
      return newParams;
    },
    // 校验必填参数
    checkRequireParams(params) {
      // 获取必填项后续进行值校验(需要校验必填是防止需要请求参数再默认选中的项因为接口返回数据时机不同导致的参数不同步问题)
      // 后续添加异步默认选中的筛选项请加入必填校验
      const requireParams = this.filtersConfig.filter(item => item.type === 'tree_user_ids' && (item.isPreventNotSelected || item.isPreventNotSelected === undefined) || ['tree_id', 'event_id'].includes(item.type));
      return requireParams.every(item => {
        const current = params[item.type];
        return current && (current.constructor === Object ? Object.keys(current).length : String(current));
      });
    },
    // 记忆筛选项
    saveFilters() {
      // 筛选项数据存储
      sessionStorage.setItem('biFilters', JSON.stringify(this.filters));
      // 如果是需要localstorage存储的筛选项页面
      // 部门单选数据存储
      sessionStorage.setItem('tree_id_label', this.tree_id_label);
      // 部门与成员多选数据
      sessionStorage.setItem('department_and_member_data', JSON.stringify(this.departmentAndMemberData));
      // 会话活动或者维度页面会话类型数据存储
      if ([_routeMap.ROUTES.biActivity, _routeMap.ROUTES.biDimension, _routeMap.ROUTES.biPersonalActivity, _routeMap.ROUTES.biPersonalDimension].includes(this.$route.path)) {
        sessionStorage.setItem('bi_activity_conversation_type', JSON.stringify(this.conversationType));
      }
      // 记录活动页面时是否本来有没有doc
      if ([_routeMap.ROUTES.biActivity, _routeMap.ROUTES.biPersonalActivity].includes(this.$route.path)) {
        sessionStorage.setItem('bi_activity_conversation_type_has_doc', this.conversationType.includes('doc'));
        sessionStorage.setItem('bi_activity_conversation_scene_type', JSON.stringify(this.filters.conversation_scene_type));
      }
    },
    // 回填筛选项
    backFillFilters() {
      const sessionFilters = sessionStorage.getItem('biFilters');
      if (sessionFilters) {
        this.filters = JSON.parse(sessionFilters);
        // 部门单选回填
        this.tree_id_label = sessionStorage.getItem('tree_id_label');
        // 部门多选回填
        this.tree_ids_label = sessionStorage.getItem('tree_ids_label');
        const departmentMultipleSelect = this.$refs['departmentMultipleSelect'];
        if (this.tree_ids_label && departmentMultipleSelect && departmentMultipleSelect.length) {
          this.$nextTick(() => {
            departmentMultipleSelect[0].defaultChecked = this.filters.tree_ids;
            departmentMultipleSelect[0].departmentiLabels = this.tree_ids_label;
          });
        }
        // 回填人员选项
        this.userObjArr = JSON.parse(sessionStorage.getItem('biUserObj'));
        const memberFinderNode = this.$refs['membersFinder'] && this.$refs['membersFinder'][0];
        if (memberFinderNode) {
          memberFinderNode.selection = this.filters.user_ids;
          memberFinderNode.memberOptions = this.userObjArr;
        }
        this.filtersChange();
      }
    },
    customFilterChange(params) {
      this.customFieldParams = params;
      this.filtersChange();
      if (this.needLocalStorageFiltersPage) {
        this.showSaveFilterBar = true;
      }
    },
    saveToLoacalStorage() {
      const storageKey = `org${this.orgId}-page_${this.$route.name}-bi-custom-filter`;
      localStorage.setItem(storageKey, JSON.stringify(this.customFieldParams));
      this.$message.success('保存成功');
      this.showSaveFilterBar = false;
    },
    showDefaultFilter(val) {
      this.$emit('showDefaultFilter', val);
    },
    exportCsv() {
      this.$bus.$emit('exportCsv');
    },
    async getDealStageOptions() {
      const res = await (0, _client.getBusinessStatusOptions)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.dealStageOptions = res.results;
        this.filters.trade_status = JSON.parse(localStorage.getItem(`org${this.orgId}-bi-filter-trade-status`)) || [];
      }
    }
  }
};
exports.default = _default;