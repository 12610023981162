"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _EffectTestSentenceItem = _interopRequireDefault(require("./EffectTestSentenceItem.vue"));
var _ChatInput = _interopRequireDefault(require("@/components/ChatUnit/components/ChatInput.vue"));
var _useEffectTestWebsocket = require("../hooks/use-effect-test-websocket");
var _useVuex = require("@/hooks/use-vuex");
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AiCustomerEffectTest',
  props: {
    promptConfig: {
      type: Object,
      default: () => ({})
    },
    startWatch: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const sentenceList = (0, _vue.ref)([]);
    const conversationContainerRef = (0, _vue.ref)(null);
    const addSentence = async sentence => {
      sentenceList.value.push(sentence);
      await (0, _vue.nextTick)();
      conversationContainerRef.value.scrollTo({
        top: conversationContainerRef.value.scrollHeight,
        behavior: 'smooth'
      });
    };
    const store = (0, _useVuex.useStore)();
    const {
      ws,
      initWebsocket
    } = (0, _useEffectTestWebsocket.useEffectTestWebsocket)({
      store,
      addSentence
    });
    const isShowStartTest = (0, _vue.ref)(true);
    const onChatInputChange = content => {
      if (!content.trim().length) return;
      const data = {
        content: content.trim(),
        type: 'sales',
        uuid: (0, _nanoid.nanoid)()
      };
      ws.value.sendMessage({
        type: 'plan_message',
        data
      });
      addSentence(data);
    };
    const isShowAskRestartModel = (0, _vue.ref)(false);
    (0, _vue.watch)([() => props.promptConfig.prompt, () => props.promptConfig.prologue.role], () => {
      if (props.startWatch && !isShowStartTest.value) {
        isShowAskRestartModel.value = true;
      }
    });
    const tempConfigId = (0, _vue.ref)('');
    const restart = async () => {
      const res = await (0, _coachingCenter.tempStoreAiCustomerConfig)(props.promptConfig);
      tempConfigId.value = res.results.tmp_config_id;
      initWebsocket({
        tmp_config_id: tempConfigId.value
      });
      isShowAskRestartModel.value = false;
      isShowStartTest.value = false;
      sentenceList.value = [];
    };
    return {
      __sfc: true,
      props,
      sentenceList,
      conversationContainerRef,
      addSentence,
      store,
      ws,
      initWebsocket,
      isShowStartTest,
      onChatInputChange,
      isShowAskRestartModel,
      tempConfigId,
      restart,
      EffectTestSentenceItem: _EffectTestSentenceItem.default,
      ChatInput: _ChatInput.default
    };
  }
};
exports.default = _default;