"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'OperateDropdown',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    // 0:可查看 1:可编辑 2:可管理 3:设为所有者
    permission: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentPermission: 0,
      visible: false
    };
  },
  computed: {
    permissionLabel() {
      return this.list.find(item => item.value === this.currentPermission).label;
    }
  },
  watch: {
    permission: {
      handler: function (val) {
        this.currentPermission = val;
      },
      immediate: true
    }
  },
  methods: {
    handleDropdownVisibleChange(val) {
      this.visible = val;
    },
    handleCommand(val) {
      if (this.currentPermission === val) return;
      this.currentPermission = val;
      this.$emit('change', val);
    }
  }
};
exports.default = _default;