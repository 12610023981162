"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("conversation-filter-item", {
    attrs: {
      label: "关键事件跟踪器",
      "popover-content": "*",
      "is-show-clear": _vm.isDeleteIconShow
    },
    on: {
      handleClear: _vm.deleteKeyEvent
    }
  }, [_c("div", {
    attrs: {
      slot: "popoverHtml"
    },
    slot: "popoverHtml"
  }, [_c("p", [_vm._v("关键事件跟踪器包括一系列系统内置的关键事件；")]), _c("p", [_vm._v("您可以根据关键事件，对销售语音的内容进行搜索。")]), _c("p", [_vm._v("*系统将自动为您把语音转录成可搜索的文字")])]), _c("MultipleCascaderSelect", {
    ref: "MultipleCascaderSelect",
    attrs: {
      filters: _vm.filters,
      "default-props": _vm.defaultProps,
      "cascader-options": _vm.keyEventOptions,
      disabled: _vm.disabled
    },
    on: {
      change: _vm.customChange,
      addKeyEventCascader: function ($event) {
        return _vm.$emit("addKeyEventCascader");
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;