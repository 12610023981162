"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'TaskCenterExportButton',
  props: {
    size: {
      type: String,
      default: 'default'
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  },
  methods: {
    exportData() {
      this.$emit('exportData', this.handleNotify);
    },
    handleNotify() {
      if (this.$notify) {
        this.$notify.closeAll();
      }
      const h = this.$createElement;
      this.$notify({
        title: '导出文件',
        message: h('div', {
          class: 'notify-content'
        }, [h('span', '文件正在准备中，您可以在个人中心 - '), h('a', {
          class: 'jump-link',
          on: {
            click: this.goExportRecords
          }
        }, '导出记录'), h('span', '中查看。')]),
        duration: 5000,
        customClass: 'conversation-list-export-file-notify'
      });
    },
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    }
  }
};
exports.default = _default;