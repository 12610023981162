"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trans-text"
  }, [_c("div", {
    ref: "contWrapper",
    staticClass: "content-wrapper"
  }, [_c("dialog-text", {
    key: "transtext",
    ref: "dialogText",
    attrs: {
      "is-snippet-status": _vm.isSnippetStatus,
      words: _vm.words,
      origin: _vm.origin,
      "active-round-id": _vm.activeRoundId,
      "upload-type": _vm.uploadType,
      "has-role": _vm.hasRole,
      "media-type": _vm.mediaType,
      "can-edit-key-event": _vm.canEditKeyEvent,
      "conv-in-work-space-id": _vm.convInWorkSpaceId,
      "right-bar-active": _vm.rightBarActive
    },
    on: {
      changeKeyWordBack: _vm.changeKeyWordBack,
      changeEventBack: _vm.changeEventBack,
      correctTextSuccess: function ($event) {
        return _vm.$emit("correctTextSuccess");
      },
      handleComment: function ($event) {
        return _vm.$emit("handleComment");
      }
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;