"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAdminMembers = addAdminMembers;
exports.addFuncMembers = addFuncMembers;
exports.addFuncRole = addFuncRole;
exports.createAdminRole = createAdminRole;
exports.deleteAdminMembers = deleteAdminMembers;
exports.deleteAdminRole = deleteAdminRole;
exports.deleteFuncMembers = deleteFuncMembers;
exports.deleteFuncRole = deleteFuncRole;
exports.deleteMemberRole = deleteMemberRole;
exports.editFuncRole = editFuncRole;
exports.editMemberManageScope = editMemberManageScope;
exports.editMemberRole = editMemberRole;
exports.getAdminMembers = getAdminMembers;
exports.getAdminRoleList = getAdminRoleList;
exports.getAdminRoleMembers = getAdminRoleMembers;
exports.getAdminRolePermissions = getAdminRolePermissions;
exports.getAdminRoleTableHead = getAdminRoleTableHead;
exports.getAdminRolesPermissionList = getAdminRolesPermissionList;
exports.getBusinessRoles = getBusinessRoles;
exports.getDataPermisson = getDataPermisson;
exports.getDepartmentWithAuth = getDepartmentWithAuth;
exports.getDepartmentsWithOutAuth = getDepartmentsWithOutAuth;
exports.getFuncRolePermissions = getFuncRolePermissions;
exports.getMembersList = getMembersList;
exports.saveDataPermisson = saveDataPermisson;
exports.setDefaultRole = setDefaultRole;
exports.transferCreator = transferCreator;
exports.updateAdminRole = updateAdminRole;
exports.updateMembersRole = updateMembersRole;
var _request = require("@/utils/request");
// 获取部门树
function getDepartmentWithAuth(params) {
  return (0, _request.request)({
    url: '/organization/tree/below_auth',
    method: 'get',
    params
  });
}

// 获取部门 ———— 无权限限制
function getDepartmentsWithOutAuth() {
  return (0, _request.request)({
    url: '/organization/tree/without_auth',
    method: 'get'
  });
}

/**
 * 系统管理权限
 */
// 角色名称搜索
function getAdminRoleList(params) {
  return (0, _request.webService)({
    url: '/organization/role/manage/list',
    method: 'get',
    params
  });
}

// 角色的新建
function createAdminRole(data) {
  return (0, _request.webService)({
    url: '/organization/role/manage/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 角色的编辑
function updateAdminRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 角色的删除
function deleteAdminRole(data) {
  return (0, _request.request)({
    url: '/organization/role/manage/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取管理员角色表头
function getAdminRoleTableHead(params) {
  return (0, _request.request)({
    url: '/organization/user/manage/head',
    method: 'get',
    params
  });
}

// 获取某角色下所有成员信息
function getAdminRoleMembers(params) {
  return (0, _request.request)({
    url: '/organization/user/manage/under_role_list',
    method: 'get',
    params
  });
}

// 为角色添加成员
function addAdminMembers(data) {
  return (0, _request.webService)({
    url: '/organization/role/manage/user/batch_add',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 为角色删除成员
function deleteAdminMembers(data) {
  return (0, _request.request)({
    url: '/organization/user/manage/under_role_delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取系统管理角色权限树
function getAdminRolePermissions(params) {
  return (0, _request.request)({
    url: '/organization/role/manage/permissions',
    method: 'get',
    params
  });
}

// 编辑用户部门管理范围
function editMemberManageScope(data) {
  return (0, _request.request)({
    url: '/organization/user/manage/under_role_update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取所有成员信息
function getAdminMembers(params) {
  return (0, _request.request)({
    url: '/organization/user/manage/list',
    method: 'get',
    params
  });
}

// 编辑成员角色信息
function editMemberRole(data) {
  return (0, _request.request)({
    url: '/organization/user/manage/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 转移创建人
function transferCreator(data) {
  return (0, _request.request)({
    url: '/organization/user/manage/change_owner',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移除用户的所有管理员角色
function deleteMemberRole(data) {
  return (0, _request.request)({
    url: '/organization/user/manage/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取某些管理员角色的并集权限列表
function getAdminRolesPermissionList(role_ids) {
  return (0, _request.request)({
    url: '/organization/user/manage/perm_ids_with_tree_ids',
    method: 'get',
    params: {
      role_ids
    }
  });
}

/**
 * 业务功能权限
 */

// .获取业务功能角色权限树
function getFuncRolePermissions(params) {
  return (0, _request.request)({
    url: '/organization/role/func/tree',
    method: 'get',
    params
  });
}

// 搜索业务角色
function getBusinessRoles(params) {
  return (0, _request.webService)({
    url: '/organization/role/func/list',
    method: 'get',
    params
  });
}

// 获取成员列表
function getMembersList(data) {
  return (0, _request.request)({
    url: '/organization/user/func/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 设置组织默认角色
function setDefaultRole(data) {
  return (0, _request.request)({
    url: '/organization/role/func/endefault',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除业务角色
function deleteFuncRole(data) {
  return (0, _request.request)({
    url: '/organization/role/func/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 移除成员
function deleteFuncMembers(data) {
  return (0, _request.request)({
    url: '/organization/user/func/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 批量添加成员
function addFuncMembers(data) {
  return (0, _request.webService)({
    url: '/organization/role/func/user/batch_add',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 新建业务角色
function addFuncRole(data) {
  return (0, _request.webService)({
    url: '/organization/role/func/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 批量修改业务角色、编辑成员角色
function updateMembersRole(data) {
  return (0, _request.request)({
    url: '/organization/user/func/batch_update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑业务角色
function editFuncRole(data) {
  return (0, _request.request)({
    url: '/organization/role/func/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

/**
 *  数据权限相关接口
 */
// 获取数据权限信息
function getDataPermisson() {
  return (0, _request.webService)({
    url: '/organization/access_rule/detail',
    method: 'get'
  });
}

// 编辑业务角色
function saveDataPermisson(data) {
  return (0, _request.webService)({
    url: '/organization/access_rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}