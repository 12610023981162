"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lottieWebVue = _interopRequireDefault(require("lottie-web-vue"));
var _GuidePageTrialDialog = _interopRequireDefault(require("@/components/GuidePageTrialDialog"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const tabsNavList = ['AI智能陪练', '金牌话术沉淀', '员工自主训练', '销售人才盘点'];
    const currentTabsNavIndex = (0, _vue.ref)(0);
    const ownAdvantageIndex = (0, _vue.ref)(0);
    const guidePageTrialDialogVisible = (0, _vue.ref)(false);
    const showReservationForm = () => {
      guidePageTrialDialogVisible.value = true;
    };
    return {
      __sfc: true,
      tabsNavList,
      currentTabsNavIndex,
      ownAdvantageIndex,
      guidePageTrialDialogVisible,
      showReservationForm,
      LottieAnimation: _lottieWebVue.default,
      GuidePageTrialDialog: _GuidePageTrialDialog.default
    };
  }
};
exports.default = _default;