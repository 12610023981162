"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _clues = require("@/api/clues");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  props: {
    type: String,
    leadId: Number | String,
    visible: {
      type: Boolean,
      default: false
    },
    isAfterConv: Boolean
  },
  components: {
    DynamicForm: _DynamicForm.default
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          switch (this.type) {
            case "create":
              this.isEdit = true;
              break;
            default:
              this.isEdit = false;
              this.fetchData();
          }
          this.$nextTick(() => {
            this.clearValidate();
          });
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      formDesc: [],
      formData: {},
      oldFormData: {},
      isEdit: true,
      loading: false
    };
  },
  created() {
    this.getDynamicForm();
    if (this.isAfterConv) {
      this.fetchData();
    }
  },
  mounted() {},
  methods: {
    enterEdit() {
      this.oldFormData = {
        ...this.formData
      };
      this.isEdit = true;
    },
    submit() {
      this.$refs.form.$refs.form.validate(valid => {
        if (valid) {
          this.type === "edit" ? this.editLead() : this.createLead();
        }
      });
    },
    async createLead() {
      const res = await (0, _clues.createClues)(this.formData);
      if (res.code === 200) {
        this.$message.success("新建成功");
        this.initForm();
        this.$emit("submitSucceed", res.results, this.formData);
      }
    },
    async fetchData() {
      // this.loading = true
      const res = await (0, _clues.getLeadFormInfo)({
        lead_id: this.leadId
      });
      this.formData = res.results;
      // this.loading = false
    },

    async editLead() {
      const res = await (0, _clues.editClues)({
        lead_id: this.leadId,
        ...this.formData
      });
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.$emit("submitSucceed");
      }
    },
    cancel() {
      this.formData = {
        ...this.oldFormData
      };
      this.isEdit = false;
      this.clearValidate();
      if (this.type === "create") {
        this.$emit("cancel");
      }
    },
    clearValidate() {
      this.$refs.form.clearValidate();
    },
    initForm() {
      const {
        formDesc,
        ...others
      } = this.$options.data();
      Object.assign(this.$data, others);
    },
    async getDynamicForm() {
      const res = await (0, _clues.getDynamicForm)();
      if (res.code === 200) {
        const desc = [];
        for (const item of res.results) {
          const {
            id,
            name: label,
            from_type: type,
            is_require: required,
            field_type,
            is_unique: unique,
            options,
            default_value,
            tips
          } = item;
          desc.push({
            id: String(id),
            label,
            type,
            required: required ? true : false,
            unique,
            options,
            preset: field_type ? true : false,
            action: "",
            tips,
            default: default_value
          });
        }
        this.formDesc = desc;
      }
    }
  }
};
exports.default = _default;