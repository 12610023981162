"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _TimeRange = _interopRequireDefault(require("@/components/TimeRange"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDate',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    TimeRange: _TimeRange.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timeOption: [{
        value: [0, 20],
        label: '20秒内'
      }, {
        value: [20, 'unlimit'],
        label: '20秒以上'
      }],
      invalid: false
    };
  },
  computed: {
    duration() {
      return this.filters.max_silence_duration;
    }
  },
  watch: {
    duration(val) {
      if (!this.filters.max_silence_duration[1]) this.clearTimeChoose();
    },
    deep: true
  },
  methods: {
    clearTimeChoose() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.max_silence_duration = [0, 'unlimit'];
      this.invalid = false;
      this.$refs.timeRange.isValid = true;
    },
    handleActiveTime(val) {
      return JSON.stringify(this.filters.max_silence_duration) === JSON.stringify(val);
    },
    selectTime(time) {
      this.$emit('selectTime', time);
      this.invalid = false;
      this.$refs.timeRange.isValid = true;
    }
  }
};
exports.default = _default;