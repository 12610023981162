"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "customer"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("h1", [_vm._v(_vm._s(_vm.customerName))])]), _c("el-tabs", {
    model: {
      value: _vm.customerTab,
      callback: function ($$v) {
        _vm.customerTab = $$v;
      },
      expression: "customerTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "客户跟进",
      name: "customerFollowUp"
    }
  }, [_vm.fromType ? _c("customer-follow-up-deal", {
    attrs: {
      "account-id": _vm.accountId
    },
    on: {
      getCustomerName: _vm.getCustomerName
    }
  }) : _c("customer-follow-up", {
    attrs: {
      "account-id": _vm.accountId
    },
    on: {
      getCustomerName: _vm.getCustomerName
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "客户信息",
      name: "customerInfo"
    }
  }, [_c("information-card", {
    attrs: {
      "account-id": _vm.accountId
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "联系人",
      name: "contactPerson"
    }
  }, [_c("contact-person", {
    attrs: {
      "account-id": _vm.accountId
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;