"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-operation"
  }, [_vm._l(_vm.btnList, function (item) {
    return _c("el-tooltip", {
      directives: [{
        name: "delTabIndex",
        rawName: "v-delTabIndex"
      }],
      key: item.icon,
      attrs: {
        content: item.tip,
        placement: "top"
      }
    }, [_c("i", {
      class: [`iconfont icon-${item.icon}`],
      on: {
        click: function ($event) {
          return _vm.handleOperation(item.command);
        }
      }
    })]);
  }), _c("more-operations", {
    staticClass: "operate-btn",
    attrs: {
      list: _vm.dropdownList,
      "menu-class": "knowledge-operation-dropdown-menu"
    },
    on: {
      command: _vm.handleOperation
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;