"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportAndDownloadTable = exportAndDownloadTable;
exports.sheet2blob = sheet2blob;
var _xlsx = _interopRequireDefault(require("xlsx"));
var _download = require("./download");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 把二维数组转为XLSX blob
function sheet2blob(data, isMultiple) {
  // 创建空book
  const workbook = _xlsx.default.utils.book_new();
  // 将sheet加入book
  // 将非数组类型的data转化为数组统一处理多sheet类型
  let sheetQueue;
  if (isMultiple) {
    sheetQueue = data.map(_ref => {
      let {
        sheetName,
        data
      } = _ref;
      return {
        sheetName,
        sheetData: _xlsx.default.utils.aoa_to_sheet(data)
      };
    });
  } else {
    sheetQueue = [{
      sheetData: _xlsx.default.utils.aoa_to_sheet(data)
    }];
  }
  sheetQueue.forEach(_ref2 => {
    let {
      sheetName,
      sheetData
    } = _ref2;
    _xlsx.default.utils.book_append_sheet(workbook, sheetData, sheetName);
  });
  const wpts = {
    bookType: 'xlsx',
    // csv格式无法支持多sheet
    bookSST: true,
    type: 'binary'
  };
  const workbookOut = _xlsx.default.write(workbook, wpts);
  return new Blob([s2ab(workbookOut)], {
    type: 'application/octet-stream'
  });
  function s2ab(s) {
    let sLength = s.length;
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    while (sLength--) {
      view[sLength] = s.charCodeAt(sLength) & 0xff;
    }
    return buf;
  }
}
function exportAndDownloadTable(data, filename, isMultiple) {
  const blob = sheet2blob(data, isMultiple);
  (0, _download.downloadBlob)(blob, filename);
}