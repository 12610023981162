"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tree-select-value-container"
  }, [_c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.selectionList.length,
      expression: "!selectionList.length"
    }],
    staticClass: "placeholder"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.pleaseSelectHasVar", [_vm.placeholder])) + " ")]), _vm.isSingle && _vm.selectionList[0] ? _c("span", {
    staticClass: "tree-single-select"
  }, [_vm.isDepartment || _vm.selectionList[0].isDepartment ? _c("mgv-avatar-name", {
    attrs: {
      icon: `iconfont icon-${_vm.selectionList[0].fid ? "department" : "office-building"}`,
      size: 20
    }
  }) : _c("mgv-avatar-name", {
    attrs: {
      name: _vm.selectionList[0].name,
      src: _vm.selectionList[0].avatar,
      size: 20
    }
  }), _c("span", [_vm._v(_vm._s(_vm.selectionList[0].name))])], 1) : _c("ul", {
    staticClass: "tree-select-value-item"
  }, [_vm._l(_vm.selectionList.slice(0, _vm.showTagCount), function (item) {
    return _c("li", {
      key: item.id
    }, [_vm.isDepartment || item.isDepartment ? _c("mgv-avatar-name", {
      attrs: {
        icon: `iconfont icon-${_vm.selectionList[0].fid ? "department" : "office-building"}`,
        size: 20
      }
    }) : _c("mgv-avatar-name", {
      attrs: {
        name: item.name,
        src: item.avatar,
        size: 20
      }
    }), _c("span", [_vm._v(_vm._s(item.name))]), _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.$emit("removeItem", item);
        }
      }
    })], 1);
  }), _vm.selectionList.length > _vm.showTagCount ? _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      "popper-class": "tree-select-popover",
      "visible-arrow": false,
      width: "200",
      trigger: "hover"
    }
  }, [_c("li", {
    staticClass: "collapse-tags",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v(" +" + _vm._s(_vm.selectionList.length - _vm.showTagCount) + " ")]), _c("ValueList", {
    attrs: {
      "value-list": _vm.selectionList.slice(_vm.showTagCount)
    },
    scopedSlots: _vm._u([{
      key: "prefix-icon",
      fn: function (_ref) {
        let {
          nodeData
        } = _ref;
        return [_vm.isDepartment || nodeData.isDepartment ? _c("i", {
          staticClass: "iconfont icon-department"
        }) : _vm._e()];
      }
    }, {
      key: "suffix-icon",
      fn: function (_ref2) {
        let {
          nodeData
        } = _ref2;
        return [_c("i", {
          staticClass: "el-icon-close",
          on: {
            click: function ($event) {
              return _vm.$emit("removeItem", nodeData);
            }
          }
        })];
      }
    }], null, false, 3417529884)
  })], 1) : _vm._e()], 2), _c("span", {
    class: ["tree-select-icon-container", {
      "has-value": _vm.selectionList.length
    }]
  }, [_vm.selectionList.length ? _c("i", {
    staticClass: "el-icon-error",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("clear");
      }
    }
  }) : _vm._e(), _c("i", {
    class: ["el-icon-arrow-down", {
      rotation: _vm.popoverVisible
    }]
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;