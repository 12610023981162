"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'FilterBasisSelect',
  data() {
    return {
      filterBasis: 'all',
      filterBasisOptions: [{
        label: '会话',
        value: 'all'
      }, {
        label: '交易',
        value: 'deal'
      }]
    };
  },
  methods: {
    handleBasisfiltersChange() {
      this.$emit('change', this.filterBasis);
    }
  }
};
exports.default = _default;