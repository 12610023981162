"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SummaryResultDisplay',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    rightBarActive: {
      type: String,
      default: ''
    }
  },
  emits: ['manualExecution'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const hasResult = (0, _vue.computed)(() => {
      let data = null;
      if (props.rightBarActive === 'AISummary') {
        data = props.info.data;
      } else {
        var _props$info, _props$info$data;
        data = (_props$info = props.info) === null || _props$info === void 0 ? void 0 : (_props$info$data = _props$info.data) === null || _props$info$data === void 0 ? void 0 : _props$info$data.length;
      }
      return data;
    });
    const manualExecution = () => {
      emit('manualExecution');
    };
    return {
      __sfc: true,
      props,
      emit,
      hasResult,
      manualExecution,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;