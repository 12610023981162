"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _clues = require("@/api/clues");
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _cluesCalendarDirectory = _interopRequireDefault(require("@/components/CluesDetail/cluesCalendarDirectory"));
var _ClueDetailDrawer = _interopRequireDefault(require("./components/ClueDetailDrawer"));
var _CluesAllotDialog = _interopRequireDefault(require("@/components/CluesAllotDialog"));
var _itemsFlow = _interopRequireDefault(require("@/components/BusinessDetail/itemsFlow"));
var _itemDetail = _interopRequireDefault(require("@/components/BusinessDetail/itemDetail"));
var _ClueOperateButtons = _interopRequireDefault(require("@/views/clues/components/ClueOperateButtons"));
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClueDetail',
  components: {
    DetailHeader: _DetailHeader.default,
    ClueCalenderDirectoty: _cluesCalendarDirectory.default,
    ClueDetailDrawer: _ClueDetailDrawer.default,
    CluesAllotDialog: _CluesAllotDialog.default,
    ItemsFlow: _itemsFlow.default,
    ItemDetail: _itemDetail.default,
    ClueOperateButtons: _ClueOperateButtons.default,
    MeetingDialog: _MeetingDialog.default
  },
  provide() {
    return {
      init: this.init
    };
  },
  data() {
    return {
      clueId: '',
      clueObject: {},
      detailLoading: false,
      isCalenderFold: false,
      clueDetailDrawerVisible: false,
      clueDynamicForm: [],
      formDesc: [],
      allotDialogVisible: false,
      allotDialogProps: {},
      conversationList: [],
      activeClueId: null,
      meetingDialogVisible: false,
      meetingDialogTitle: '安排在线会议',
      meetingProps: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'busiActiveDate', 'busiActiveConver', 'userId', 'has_outbound', 'has_video_permission', 'webRoutes']),
    customerName() {
      var _this$clueObject, _this$clueObject$acco;
      // eslint-disable-next-line no-undef
      return (_this$clueObject = this.clueObject) === null || _this$clueObject === void 0 ? void 0 : (_this$clueObject$acco = _this$clueObject.account) === null || _this$clueObject$acco === void 0 ? void 0 : _this$clueObject$acco.name;
    },
    timelineArray() {
      if (this.conversationList) {
        // 深拷贝出对象，避免公用对象间的混乱
        const obj = JSON.parse(JSON.stringify(this.conversationList));
        return obj.timeline;
      } else {
        return [];
      }
    },
    infoObject() {
      var _this$clueObject2, _this$clueObject2$fol;
      return {
        industry: this.clueObject.industry,
        employee_count: this.clueObject.employee_count,
        name: this.clueObject.name,
        // eslint-disable-next-line no-undef
        followerUpByName: (_this$clueObject2 = this.clueObject) === null || _this$clueObject2 === void 0 ? void 0 : (_this$clueObject2$fol = _this$clueObject2.follow_up_by) === null || _this$clueObject2$fol === void 0 ? void 0 : _this$clueObject2$fol.name,
        timeline: this.conversationList.timeline
      };
    },
    getActiveDetail() {
      const timelineArray = this.conversationList.timeline;
      if (timelineArray) {
        let itemObj = {};
        timelineArray.forEach(item => {
          if (item.date === this.busiActiveDate) {
            item.infos.forEach(itemInfo => {
              if (itemInfo.id === this.busiActiveConver) {
                itemObj = itemInfo;
              }
            });
          }
        });
        return itemObj;
      } else {
        return {};
      }
    },
    operateButtons() {
      return [{
        operateType: 'createMeeting',
        icon: 'reservation-2',
        text: '预约会议',
        isShow: this.has_video_permission
      }, {
        operateType: 'callout',
        icon: 'phone-outline',
        text: '呼叫',
        isShow: this.has_outbound
      }];
    }
  },
  watch: {},
  created() {
    this.clueId = this.$route.query.id;
    this.getClueDynamicForm();
    this.init();
  },
  mounted() {},
  methods: {
    goAccountDetail() {
      var _this$clueObject3, _this$clueObject3$acc;
      // eslint-disable-next-line no-undef
      const id = (_this$clueObject3 = this.clueObject) === null || _this$clueObject3 === void 0 ? void 0 : (_this$clueObject3$acc = _this$clueObject3.account) === null || _this$clueObject3$acc === void 0 ? void 0 : _this$clueObject3$acc.id;
      if (this.webRoutes.find(item => item.name === 'ClientIndex')) {
        this.$router.push({
          path: _routeMap.ROUTES.account,
          query: {
            id,
            tab: 'account'
          }
        });
      } else {
        this.$message.warning('无此客户查看权限');
      }
    },
    init() {
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', '');
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', null);
      this.getClueDetail();
      this.getClueConversation();
    },
    refreshDetail() {
      this.init();
    },
    setDefaultActiveVal(timelineArray) {
      timelineArray.forEach((item, index) => {
        if (index === 0) {
          this.$store.commit('business/SET_BUSI_ACTIVE_DATE', item.date);
          this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', item.infos[0].id);
        }
      });
    },
    closeDetailDrawer() {
      this.clueDetailDrawerVisible = false;
    },
    openDetailDrawer() {
      this.clueDetailDrawerVisible = true;
      this.formDesc = this.formatFormDesc(this.clueDynamicForm);
    },
    handleMoreCommand(command) {
      switch (command) {
        case 'edit':
          this.openDetailDrawer();
          break;
      }
    },
    // 变更跟进人
    allocateToAnyone(ids, type) {
      this.allotDialogProps = {
        type,
        leadIds: ids
      };
      this.allotDialogVisible = true;
    },
    // 获取线索会话
    async getClueConversation() {
      const res = await (0, _clues.getClueConversation)({
        lead_id: this.$route.query.id
      });
      if (res.code === 20000) {
        this.conversationList = res.results;
        this.setDefaultActiveVal(res.results.timeline);
      }
    },
    async getClueDetail() {
      this.detailLoading = true;
      const res = await (0, _clues.getClueDetail)({
        lead_id: this.$route.query.id
      });
      if (res.code === 20000) {
        this.clueObject = res.results.detail_lead;
      }
      this.detailLoading = false;
    },
    async getClueDynamicForm() {
      const res = await (0, _clues.getClueDynamicForm)();
      if (res.code === 20000) {
        this.clueDynamicForm = res.results;
      }
    },
    formatFormDesc(formDesc) {
      const desc = [];
      for (const item of formDesc) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          default: item.default_value,
          tips: item.tips
        };
        desc.push(obj);
      }
      return desc;
    },
    changeactiveClueId(id) {
      this.activeClueId = id;
    },
    callOut(item, row) {
      if (!this.has_outbound) {
        this.$message.warning('暂无坐席');
        return;
      }
      const data = {
        id: row.id,
        type: 'clue',
        company: row.name,
        contact_id: '',
        contact_person: item.name,
        phone: item.tel
      };
      this.$bus.$emit('openPhoneCall', data);
    },
    createMeeting(contactItem, clientInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      // eslint-disable-next-line no-unused-vars
      const {
        id,
        name
      } = clientInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName,
        tel: contactTel
      } = contactItem;
      this.meetingProps = {
        type: 'clue',
        cluesId: id,
        customerName: '',
        contactOptions,
        contactId,
        contactName,
        contactTel,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    clickContactPersonItem(contactItem, rowInfo, type, contactList, meetingType) {
      switch (type) {
        case 'callout':
          this.callOut(contactItem, rowInfo);
          break;
        case 'createMeeting':
          this.createMeeting(contactItem, rowInfo, contactList, meetingType);
          break;
        default:
          break;
      }
    }
  }
};
exports.default = _default;