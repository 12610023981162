"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer create-mutual-exclusion-drawer",
    attrs: {
      visible: _vm.visible,
      title: _vm.strategyId ? "编辑" : "创建互斥策略",
      size: "600px",
      "wrapper-closable": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "main-content"
  }, [_c(_setup.MutualExclusionBasicInfoForm, {
    ref: "mutexBasicInfoFormRef",
    attrs: {
      "mutual-exclusion-basic-info-form": _setup.mutualExclusionBasicInfoForm
    }
  }), _c(_setup.MutualExclusionRuleForm, {
    ref: "mutexRuleFormValidRef",
    attrs: {
      "mutual-exclusion-rule-form": _setup.mutualExclusionRuleForm,
      "used-ids": _vm.strategyId ? _setup.extraUsedIds : _vm.usedIds
    },
    on: {
      addMutexEventItem: _setup.addMutexEventItem,
      deleteMutexEventItem: _setup.deleteMutexEventItem
    }
  })], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.submitLoading
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v(_vm._s(_vm.strategyId ? "保存" : "创建策略"))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;