"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatInput = _interopRequireDefault(require("./ChatInput.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChatIntro',
  components: {
    ChatInput: _ChatInput.default
  },
  props: {
    guideList: {
      type: Array,
      default: () => []
    },
    helloText: {
      type: String,
      default: 'Hi！✨ 我是AI助手麦麦，你可以问我：'
    }
  },
  methods: {
    emitMsg(msg) {
      this.$emit('change', msg);
    }
  }
};
exports.default = _default;