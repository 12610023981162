"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _MetricCreateDialog = _interopRequireDefault(require("./MetricCreateDialog.vue"));
var _elementUi = require("element-ui");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'MetricsFilterTable',
  props: {
    dimensionId: {
      type: Number,
      default: 0
    }
  },
  emits: 'deleteSuccess',
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const updateBy = (0, _vue.ref)({
      user_ids: [],
      tree_ids: []
    });
    const createBy = (0, _vue.ref)({
      user_ids: [],
      tree_ids: []
    });
    const requestParams = (0, _vue.computed)(() => {
      return {
        dimension_id: props.dimensionId,
        update_by: updateBy.value,
        create_by: createBy.value
      };
    });
    const updateByChange = val => {
      updateBy.value = val;
      fetchData();
    };
    const createByChange = val => {
      createBy.value = val;
      fetchData();
    };
    const tableData = (0, _vue.ref)([]);
    const fetchData = async () => {
      const res = await (0, _coachingCenter.scoreMetricsList)(requestParams.value);
      tableData.value = res.results.items;
    };
    const activeMetric = (0, _vue.ref)(null);
    const metricCreateDialogVisible = (0, _vue.ref)(false);
    const editMetric = metric => {
      activeMetric.value = (0, _cloneDeep.default)(metric);
      metricCreateDialogVisible.value = true;
    };
    const deleteMetric = metric => {
      _elementUi.MessageBox.confirm(`确认删除指标 ${metric.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _coachingCenter.scoreMetricsDelete)({
          metrics_id: metric.id
        });
        if (res.code === 20000) {
          tableData.value = tableData.value.filter(item => item.id !== metric.id);
          emit('deleteSuccess', metric);
          _elementUi.Message.success('删除成功');
        }
      }).catch(() => {});
    };
    expose({
      fetchData
    });
    return {
      __sfc: true,
      props,
      updateBy,
      createBy,
      requestParams,
      updateByChange,
      createByChange,
      tableData,
      fetchData,
      activeMetric,
      metricCreateDialogVisible,
      editMetric,
      emit,
      deleteMetric,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      MetricCreateDialog: _MetricCreateDialog.default
    };
  }
};
exports.default = _default;