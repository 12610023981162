"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatInput = _interopRequireDefault(require("@/components/ChatUnit/components/ChatInput.vue"));
var _vuex = require("vuex");
var _nanoid = require("nanoid");
var _privateWebsocket = _interopRequireDefault(require("@/utils/privateWebsocket"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TrainingTestDrawer',
  components: {
    ChatInput: _ChatInput.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    courseId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentConverId: '',
      sentenceList: [],
      practiceStatus: 'generating',
      generatingTipIndex: 0,
      conversationComplate: false,
      currentPlayingVoiceId: 0,
      salesTalkUuid: 0,
      standardReply: '',
      uploadLoading: false,
      averageScore: 0,
      unlock: true,
      currentReply: '',
      nodeHighLightTimer: null,
      backfillInputVal: '',
      nextSaleNodeId: '',
      websocket: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'avatar', 'name', 'webSocketMsg', 'orgName'])
  },
  watch: {
    drawerVisible: {
      handler(val) {
        if (val && this.courseId) {
          this.refresh();
        }
      },
      immediate: true
    },
    webSocketMsg: {
      handler: function (newVal) {
        if (newVal) {
          if (newVal.type === 6 && newVal.sub_type === 9) {
            // 模型生成成功
            this.initWebsocket();
          }
          switch (newVal.type) {
            case 'plan_success':
              // 连接成功
              this.practiceStatus = 'started';
              break;
            case 'plan_model_empty':
              // 对练会话模型不存在
              this.handleCourseDelete(this.$t('coaching.sessionModelDoesNotExist'));
              break;
            case 'plan_task_not_exist':
              // 对练任务不存在
              this.handleCourseDelete(this.$t('coaching.taskHasBeenDeleted'));
              break;
            case 'plan_chat_create_success':
              // 会话创建成功
              // 存储当前会话id
              this.currentConverId = newVal.data.content;
              this.websocket && this.websocket.handlerPath({
                course_id: this.courseId,
                conv_id: this.currentConverId
              });
              break;
            case 'plan_chat_create_failed':
              // 会话创建失败
              this.$message.error(this.$t('coaching.sessionCreationFailed'));
              this.closeWebsocket();
              break;
            case 'plan_chat_restore_success':
              // 会话恢复成功
              this.handleChatRestoreSuccess();
              break;
            case 'plan_chat_restore_failed':
              // 会话恢复失败
              this.$message.error(this.$t('coaching.sessionRecoveryFailed'));
              this.closeWebsocket();
              break;
            case 'plan_message':
              // 客户话术消息
              this.uploadLoading = false;
              this.updateTalk(newVal.data);
              break;
            case 'plan_course_delete':
              // 课程被删除
              this.handleCourseDelete('该课程已被删除，无法继续编辑');
              break;
            case 'plan_close':
              // 后端关闭连接消息
              this.closeWebsocket();
              this.$message(this.$t('coaching.sessionEnded'));
              break;
            case 'plan_error':
              // 后端报错
              this.closeWebsocket();
              break;
            default:
              break;
          }
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.closeWebsocket();
  },
  methods: {
    handleCourseDelete(tips) {
      this.currentConverId = '';
      this.closeWebsocket();
      this.$alert(tips, this.$t('coaching.prompt'), {
        confirmButtonText: this.$t('coaching.confirm'),
        callback: () => {
          this.$router.replace({
            path: _routeMap.ROUTES.course
          });
        }
      });
    },
    initWebsocket() {
      this.closeWebsocket();
      this.websocket = new _privateWebsocket.default('/webapi/training/plan/ai/customer/begin');
      this.websocket.init({
        course_id: this.courseId,
        conv_id: this.currentConverId
      });
    },
    closeWebsocket() {
      if (this.websocket) {
        this.websocket.close();
        // this.websocket = null;
      }
    },

    echo(content) {
      if (!content.trim().length) return;
      const data = {
        content: content.trim(),
        type: 'sales',
        uuid: (0, _nanoid.nanoid)()
      };
      if (this.websocket) {
        this.websocket.sendMessage({
          type: 'plan_message',
          data
        });
        this.updateTalk(data);
        setTimeout(() => {
          this.uploadLoading = true;
        }, 100);
      }
    },
    async refresh() {
      this.websocket && this.websocket.close();
      this.currentConverId = '';
      this.sentenceList = [];
      // 可以开始连接ws了
      console.log('555555555555');
      this.initWebsocket();
    },
    restartTest() {
      this.currentConverId = '';
      this.conversationComplate = false;
      this.sentenceList = [];
      this.practiceStatus = 'generating';
      this.backfillInputVal = '';
      this.initWebsocket();
    },
    handleChatRestoreSuccess() {
      this.$message.success(this.$t('coaching.sessionRecoverySuccessful'));
      if (this.isFinished) {
        this.websocket.sendMessage({
          type: 'plan_finished'
        });
      } else {
        if (this.sentenceList.length) {
          const lastSentence = this.sentenceList.concat().reverse().find(item => ['customer', 'sales'].includes(item.type));
          if ((lastSentence === null || lastSentence === void 0 ? void 0 : lastSentence.type) === 'sales') {
            // 通过ws发送消息
            this.websocket.sendMessage({
              type: 'plan_reconnect_message',
              data: lastSentence
            });
          }
        }
      }
    },
    updateTalk(val) {
      this.sentenceList = this.sentenceList.concat(val);
      this.scrollToBottom();
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs['conversation-container'];
        container.scrollTo({
          top: this.getElementHeight(container),
          behavior: 'smooth'
        });
      });
    },
    getElementHeight(ele) {
      return ele === null || ele === void 0 ? void 0 : ele.scrollHeight;
    }
  }
};
exports.default = _default;