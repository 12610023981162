import { render, staticRenderFns } from "./DepartmentSingleSelector.vue?vue&type=template&id=57e48894&"
import script from "./DepartmentSingleSelector.vue?vue&type=script&lang=js&"
export * from "./DepartmentSingleSelector.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentSingleSelector.vue?vue&type=style&index=0&id=57e48894&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57e48894')) {
      api.createRecord('57e48894', component.options)
    } else {
      api.reload('57e48894', component.options)
    }
    module.hot.accept("./DepartmentSingleSelector.vue?vue&type=template&id=57e48894&", function () {
      api.rerender('57e48894', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MgTreeSelect/DepartmentSingleSelector.vue"
export default component.exports