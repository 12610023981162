import { render, staticRenderFns } from "./UploadConversationDialog.vue?vue&type=template&id=127b8974&scoped=true&"
import script from "./UploadConversationDialog.vue?vue&type=script&setup=true&lang=js&"
export * from "./UploadConversationDialog.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./UploadConversationDialog.vue?vue&type=style&index=0&id=127b8974&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127b8974",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('127b8974')) {
      api.createRecord('127b8974', component.options)
    } else {
      api.reload('127b8974', component.options)
    }
    module.hot.accept("./UploadConversationDialog.vue?vue&type=template&id=127b8974&scoped=true&", function () {
      api.rerender('127b8974', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/conversationPage/uploadConversation/UploadConversationDialog.vue"
export default component.exports