"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("header", {
    staticClass: "prompt-input-header"
  }, [_c("h2", {
    staticClass: "prompt-input-header__title"
  }, [_vm._v("编写提示词")]), _c("div", {
    staticClass: "right-btns"
  }, [_c("MgvButton", {
    staticClass: "smart-arrange-btn",
    attrs: {
      type: "text",
      size: "medium",
      square: "",
      color: "info",
      icon: "icon-magic-1 colorful"
    },
    on: {
      click: _setup.clickIntelligentGeneration
    }
  }, [_vm._v("智能编排 ")]), _c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _setup.onResetCommand
    }
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-history",
      type: "text",
      size: "medium",
      square: "",
      color: "info"
    }
  }), _c("el-dropdown-menu", {
    staticClass: "reset-prompt-dropdown-menu",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("h1", {
    staticClass: "prompt-menu-tip"
  }, [_vm._v("提示词重置")]), _c("el-dropdown-item", {
    staticClass: "reset-prompt-dropdown-item",
    attrs: {
      command: "recover"
    }
  }, [_vm._v("还原为最近发布状态")]), _c("el-dropdown-item", {
    staticClass: "reset-prompt-dropdown-item",
    attrs: {
      command: "system"
    }
  }, [_vm._v("重置为系统预设内容")])], 1)], 1), _c("el-popover", {
    attrs: {
      trigger: "hover",
      placement: "bottom-end",
      "visible-arrow": false
    }
  }, [_c("div", {
    staticClass: "menu-container"
  }, [_c("div", {
    staticClass: "prompt-menu-tip"
  }, [_vm._v("提示词查看")]), _vm._l(_vm.promptTextBlocks, function (item) {
    return _c("div", {
      key: item.key,
      staticClass: "menu-item"
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("el-switch", {
      attrs: {
        disabled: item.isSwitchDisabled,
        width: 26
      },
      model: {
        value: item.isShow,
        callback: function ($$v) {
          _vm.$set(item, "isShow", $$v);
        },
        expression: "item.isShow"
      }
    })], 1);
  })], 2), _c("MgvButton", {
    attrs: {
      slot: "reference",
      icon: "icon-setting-config",
      type: "text",
      size: "medium",
      square: "",
      color: "info"
    },
    slot: "reference"
  })], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;