"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TodoFilter',
  data() {
    return {
      todoTypeOptions: [{
        label: '待辅导',
        value: 'coach'
      }, {
        label: '申请辅导',
        value: 'advise'
      }, {
        label: '稍后再看',
        value: 'see_later'
      }],
      todoStatusOptions: [{
        label: '进行中',
        value: 'created'
      }, {
        label: '已完成',
        value: 'done'
      }, {
        label: '已忽略',
        value: 'ignored'
      }],
      todoType: 'coach',
      todoStatus: 'created',
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      ownerName: '我的',
      ownerValue: null,
      ownerPopVisible: false
    };
  },
  methods: {
    handleTodoTypeChange(type) {
      if (this.todoType === type) return;
      this.todoType = type;
      this.$emit('typeChange', this.todoType);
    },
    handleTodoStatusChange(status) {
      this.$emit('statusChange', status);
    },
    handleOwnersChange(ids) {
      this.ownerValue = ids;
      this.ownerName = ids.length ? '已选：' + ids.length : '我的';
      this.$emit('ownerChange', this.ownerValue);
    },
    hidePopover() {
      var _this$$refs$selectMem, _this$$refs$selectMem2, _this$$refs$selectMem3;
      this.ownerPopVisible = false;
      (_this$$refs$selectMem = this.$refs.selectMembers) === null || _this$$refs$selectMem === void 0 ? void 0 : (_this$$refs$selectMem2 = _this$$refs$selectMem.$refs) === null || _this$$refs$selectMem2 === void 0 ? void 0 : (_this$$refs$selectMem3 = _this$$refs$selectMem2.selectInput) === null || _this$$refs$selectMem3 === void 0 ? void 0 : _this$$refs$selectMem3.blur(); // 处理因为pop嵌套导致的外层隐藏，内层pop未消失的问题
    },

    statusSelectPopVisibleChange(val) {
      if (val && this.ownerPopVisible) {
        this.$refs.ownerPop.doClose();
      }
    }
  }
};
exports.default = _default;