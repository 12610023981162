"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$activeDataSetData;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "search-text"
  }, [_c("el-input", {
    staticClass: "no-shortkey-element",
    attrs: {
      id: "conv-search-input",
      "prefix-icon": "el-icon-search",
      placeholder: "搜索会话内容",
      clearable: ""
    },
    on: {
      input: function ($event) {
        return _setup.getWordsSearchApi();
      },
      focus: function ($event) {
        _setup.isShowResult = true;
      },
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _setup.getWordsSearchApi();
      }
    },
    model: {
      value: _setup.inputWords,
      callback: function ($$v) {
        _setup.inputWords = $$v;
      },
      expression: "inputWords"
    }
  }), _c(_setup.SearchResult, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.inputWords && _setup.isShowResult,
      expression: "inputWords && isShowResult"
    }],
    ref: "searchResult",
    staticClass: "search-result-content",
    attrs: {
      id: "conv-search-result",
      "conversation-type": (_vm$activeDataSetData = _vm.activeDataSetData) === null || _vm$activeDataSetData === void 0 ? void 0 : _vm$activeDataSetData.type,
      words: _setup.inputWords,
      "result-list": _setup.searchResultList
    },
    on: {
      goKeyDetail: _setup.setCurOrder
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;