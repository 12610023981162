"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'FirstStep',
  props: {
    editParams: {
      type: Object,
      default: () => ({})
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        start_at: '',
        end_at: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入监测名称',
          trigger: 'blur'
        }],
        start_at: [{
          required: true,
          message: '请选择时间',
          trigger: 'blur'
        }],
        end_at: [{
          required: true,
          message: '请选择时间',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    pickerOptions() {
      return function (contrast_time, isEearly) {
        const _this = this;
        return {
          disabledDate(time) {
            return _this.isEarlyThan(time, contrast_time, isEearly);
          }
        };
      };
    }
  },
  watch: {
    editParams: {
      handler(val) {
        if (Object.keys(val).length) {
          const {
            name,
            description,
            start_at,
            end_at
          } = val;
          this.form = {
            name,
            description,
            start_at,
            end_at
          };
        } else {
          this.form = this.$options.data().form;
        }
        this.clearValidate();
      },
      deep: true
    }
  },
  methods: {
    changeStartDateTime() {
      if (!this.form.end_at) {
        const currentDateTime = this.$moment();
        const startDateTime = this.$moment(this.form.start_at);
        const isEarlyNow = startDateTime.isSameOrBefore(currentDateTime);
        this.form.end_at = (isEarlyNow ? currentDateTime : startDateTime).add(7, 'days').format('YYYY-MM-DD HH:mm');
      }
    },
    isEarlyThan(time, contrast_time, isEearly) {
      const _time = this.$moment(time);
      const now = this.$moment();
      const last_year = this.$moment().subtract(1, 'years');
      if (!contrast_time) {
        // isEearly 代表开始时间,开始时间不能早于一年前，结束时间不能早于今天
        return isEearly ? this.$moment(_time).isSameOrBefore(last_year) : this.$moment(_time).isSameOrBefore(now);
      }
      // 开始时间在一年前与结束时间之间，结束时间不早于开始时间与今天
      return isEearly ? !this.$moment(_time).isBetween(last_year, contrast_time) : this.$moment(_time).isSameOrBefore(contrast_time) || this.$moment(_time).isSameOrBefore(now);
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('change', this.form);
        }
      });
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    }
  }
};
exports.default = _default;