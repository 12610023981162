"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _conversationList = require("@/api/conversationList");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BookMeetingDialog',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      form: {},
      nowTime: this.$moment(),
      submitLoading: false
    };
  },
  computed: {
    desc() {
      const _this = this;
      return [{
        id: 'meeting_name',
        label: '会议名称',
        type: 'text',
        required: true
      }, {
        id: 'beginDate',
        label: '开始时间',
        placeholder: '请选择开始日期',
        type: 'date',
        halfRow: true,
        rule: [{
          required: true,
          message: '请选择开始日期',
          trigger: 'change'
        }],
        pickerOptions: {
          disabledDate(date) {
            return _this.$moment().isAfter(date, 'day');
          }
        },
        default: this.nowTime.format('YYYY-MM-DD')
      }, {
        id: 'beginTime',
        type: 'time-select',
        label: '开始时间',
        halfRow: true,
        pickerOptions: {
          minTime: this.minBeginTime
        },
        default: this.nowTime.format('HH:mm'),
        rule: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'change'
        }, {
          validator: this.beginTimeValidator,
          trigger: 'change'
        }]
      }, {
        id: 'endDate',
        label: '结束时间',
        placeholder: '请选择结束日期',
        type: 'date',
        halfRow: true,
        rule: [{
          required: true,
          message: '请选择结束日期',
          trigger: 'change'
        }],
        pickerOptions: {
          disabledDate(date) {
            return _this.$moment(_this.form.beginDate).isAfter(date, 'day');
          }
        },
        default: this.nowTime.format('YYYY-MM-DD')
      }, {
        id: 'endTime',
        type: 'time-select',
        label: 'none',
        placeholder: '请选择结束时间',
        halfRow: true,
        pickerOptions: {
          minTime: this.minEndTime
        },
        rule: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'change'
        }, {
          validator: this.endTimeValidator,
          trigger: 'change'
        }]
      }];
    },
    beginDateTime() {
      return this.form.beginDate + ' ' + this.form.beginTime;
    },
    endDateTime() {
      return this.form.endDate + ' ' + this.form.endTime;
    },
    minBeginTime() {
      const isToday = this.$moment().isSame(this.form.beginDate, 'day');
      return isToday ? this.$moment().format('HH:mm') : '';
    },
    minEndTime() {
      const isToday = this.$moment(this.form.endDate).isSame(this.form.beginDate, 'day');
      return isToday ? this.form.beginTime : '';
    }
  },
  watch: {
    beginDateTime(val) {
      const isTime = this.$moment(val).isValid();
      const time = this.$moment(val).add(30, 'minute');
      this.form.endDate = isTime ? time.format('YYYY-MM-DD') : '';
      this.form.endTime = isTime ? time.format('HH:mm') : '';
      this.validateTimeField();
    },
    endDateTime() {
      this.validateTimeField();
    },
    visible(val) {
      if (val) {
        // 时间吸附至半小时
        this.nowTime = this.$moment(new Date(parseInt(new Date().getTime() / 1800000) + 1) * 1800000);
        // 打开时聚焦首项input
        this.$nextTick(() => {
          this.$refs.form.$refs.meeting_name[0].$refs.input.focus();
        });
      }
    }
  },
  methods: {
    closeDialog() {
      this.form = {};
      this.$emit('update:visible', false);
    },
    validateTimeField() {
      var _this$$refs$form;
      // 每次时间变化都会校验所有时间字段，以及时更新validate状态
      (_this$$refs$form = this.$refs.form) === null || _this$$refs$form === void 0 ? void 0 : _this$$refs$form.validateField(['beginDate', 'beginTime', 'endDate', 'endTime']);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        const data = {
          meeting_name: this.form.meeting_name,
          meeting_type: 'tencent_meeting',
          begin_time: this.beginDateTime,
          end_time: this.endDateTime
        };
        const res = await (0, _conversationList.bookMeeting)(data).finally(() => this.submitLoading = false);
        if (res.code === 200) {
          this.$emit('success', res.results);
        }
        this.closeDialog();
      }
    },
    setNow() {
      const now = this.$moment();
      this.form = {
        ...this.form,
        beginDate: now.format('YYYY-MM-DD'),
        beginTime: now.format('HH:mm')
      };
    },
    beginTimeValidator(rule, value, callback) {
      if (this.$moment(this.beginDateTime).isBefore(this.$moment(), 'minute')) {
        callback(new Error('开始时间不可小于当前时间'));
      } else {
        callback();
      }
    },
    endTimeValidator(rule, value, callback) {
      const begin = this.$moment(this.beginDateTime);
      const end = this.$moment(this.endDateTime);
      if (end.isBefore(begin, 'minute')) {
        callback(new Error('结束时间不可小于开始时间'));
      } else {
        callback();
      }
    }
  }
};
exports.default = _default;