"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModelInfo = useModelInfo;
var _vue = require("vue");
function useModelInfo() {
  const INITIAL = {
    id: 0,
    step: 1,
    name: '',
    description: '',
    isContinue: false,
    version: 0,
    sentences: {},
    trainStatus: 0,
    example_type: 0
  };
  const modelInfo = (0, _vue.reactive)({
    ...INITIAL
  });
  const setModelInfo = info => {
    Object.assign(modelInfo, info);
  };
  const resetModelInfo = () => {
    Object.assign(modelInfo, {
      ...INITIAL
    });
  };
  const rapidModelInfo = (0, _vue.ref)({
    name: '',
    description: ''
  });
  (0, _vue.provide)('modelInfo', modelInfo);
  (0, _vue.provide)('setModelInfo', setModelInfo);
  (0, _vue.watchEffect)(() => {
    rapidModelInfo.value = {
      name: modelInfo.name,
      description: modelInfo.description
    };
  });
  return {
    rapidModelInfo,
    modelInfo,
    setModelInfo,
    resetModelInfo
  };
}