"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "60%",
      "custom-class": "search-script-dialog"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "label"
        }, [_vm._v(_vm._s(_vm.$t("coaching.searchKnowledgeBaseScript")))]), _vm.visible ? _c("KnowledgeBaseDropdown", {
          attrs: {
            "has-all-knowledge-base": ""
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("knowledge-search", {
    ref: "knowledge-search",
    attrs: {
      "popover-disabled": ""
    },
    on: {
      search: _vm.searchKnowledge
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.knowledgeLoading,
      expression: "knowledgeLoading"
    }],
    staticClass: "knowledge-info"
  }, [_vm.currentKnowledgeInfo.name ? [_c("span", {
    staticClass: "name"
  }, [_c("i", {
    staticClass: "iconfont icon-reading"
  }), _c("span", [_vm._v(_vm._s(_vm.currentKnowledgeInfo.name))])]), _c("div", {
    staticClass: "learn-card"
  }, _vm._l(_vm.currentKnowledgeInfo.scene_reply_list, function (item) {
    return _c("div", {
      key: item.scene_id,
      staticClass: "sentence-container"
    }, [item.scene_content ? _c("tricks-scene", {
      attrs: {
        "display-only": "",
        value: item.scene_content
      }
    }) : _vm._e(), item.reply_list && item.reply_list.length ? _c("div", {
      staticClass: "tricks"
    }, _vm._l(item.reply_list, function (reply) {
      return _c("el-checkbox", {
        key: reply.reply_id,
        staticClass: "trick-item",
        attrs: {
          value: _vm.isChecked(reply.reply_id)
        },
        on: {
          change: function ($event) {
            return _vm.knowledgeCheckedChange(reply, $event);
          }
        }
      }, [_vm._v(" " + _vm._s(reply.reply_content) + " ")]);
    }), 1) : _vm._e()], 1);
  }), 0)] : _c("div", {
    staticClass: "no-data"
  }, [_vm._v(_vm._s(_vm.$t("coaching.searchTips")))])], 2)], 1), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "right-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.checkedScript", [_vm.checkedKnowledgeScript.length])) + " ")]), _c("div", {
    ref: "script-container",
    staticClass: "script-container"
  }, _vm._l(_vm.checkedKnowledgeScript, function (item) {
    return _c("div", {
      key: item.reply_id,
      staticClass: "script-item"
    }, [_c("el-input", {
      attrs: {
        type: "textarea",
        autosize: "",
        resize: "none"
      },
      model: {
        value: item.reply_content,
        callback: function ($$v) {
          _vm.$set(item, "reply_content", $$v);
        },
        expression: "item.reply_content"
      }
    }), _c("el-button", {
      staticClass: "hover-show-bg",
      attrs: {
        icon: "el-icon-close"
      },
      on: {
        click: function ($event) {
          return _vm.deleteKnowledgeScript(item);
        }
      }
    })], 1);
  }), 0)])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.add")))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;