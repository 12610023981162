"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMBED_ROUTES = exports.EMBED_PAGES = void 0;
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
const EMBED_ROUTES = {};
exports.EMBED_ROUTES = EMBED_ROUTES;
const EMBED_PAGES = {
  EmbedContainer: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "EmbedContainer" */'@/views/embed/index.vue'))),
  EmbedCoachTodo: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTodo" */
  '@/views/coaching/coach/coachTask/sales/index.vue'))),
  EmbedCoachTaskPracticeSmartTraining: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskPractice" */
  '@/views/coaching/coachTaskPractice/PracticeSmartTraining.vue'))),
  EmbedCoachTaskPractice: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskPractice" */
  '@/views/coaching/coachTaskPractice/index.vue'))),
  EmbedCoachTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetail" */
  '@/views/coaching/coach/coachTask/leaderDetail/index.vue'))),
  EmbedCoachTaskDetailSmartTraining: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetailPersonal" */
  '@/views/coaching/coach/coachTask/salesPlan/SmartTrainingDetail'))),
  EmbedAiCoachTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiCoachTaskDetail" */
  '@/views/coaching/coach/coachTask/salesPlan/AiCoachTaskDetail')))
};
exports.EMBED_PAGES = EMBED_PAGES;