"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const state = {
  typeOptions: {},
  accountTagOptionsList: []
};
const mutations = {
  SET_TYPE_OPTIONS: (state, val) => {
    state.typeOptions = val;
  },
  SET_ACCOUNT_TAG_OPTIONS_LIST: (state, val) => {
    state.accountTagOptionsList = val;
  }
};
var _default = {
  namespaced: true,
  state,
  mutations
};
exports.default = _default;