"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ClientContactPersonList = _interopRequireDefault(require("@/views/clients/components/ClientContactPersonList.vue"));
var _ContactPersonDialog = _interopRequireDefault(require("@/views/clients/components/ContactPersonDialog"));
var _client = require("@/api/client.js");
var _MeetingButton = _interopRequireDefault(require("@/components/MeetingButton"));
var _vuex = require("vuex");
var _formatContactFormDescMixins = _interopRequireDefault(require("@/views/clients/formatContactFormDescMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    ContactPersonList: _ClientContactPersonList.default,
    ContactPersonDialog: _ContactPersonDialog.default,
    MeetingButton: _MeetingButton.default
  },
  mixins: [_formatContactFormDescMixins.default],
  props: {
    rowInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    operateButtons: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ''
    },
    isInRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accountId: null,
      contactList: [],
      contactFormDesc: [],
      currentOperateType: '',
      contactPersonDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['contactDynamicFormDescList', 'api_permissions']),
    classObject() {
      return function (row, item) {
        return {
          'el-button-text--primary row-icon': item.isCircle,
          'mg-button': !item.isCircle,
          'no-list': !row.contact_count
        };
      };
    }
  },
  mounted() {
    // 如果有联系人列表出现时，点其他地方隐藏popover
    document.addEventListener('click', this.clickOtherHiddenPopover);
  },
  destroyed() {
    document.removeEventListener('click', this.clickOtherHiddenPopover);
  },
  methods: {
    getIsShowPopover(row, item) {
      var _this$contactList;
      return item.isShowList && this.activeId === row.id && !!((_this$contactList = this.contactList) !== null && _this$contactList !== void 0 && _this$contactList.length) && this.currentOperateType === item.operateType;
    },
    async clickOperateBtn(row, item, meetingType) {
      var _this$contactList2;
      this.accountId = row.id;
      this.currentOperateType = item.operateType;
      await this.getContactListByAccountId();
      this.$emit('changeActiveClientId', row.id);
      if (!item.isShowList && (_this$contactList2 = this.contactList) !== null && _this$contactList2 !== void 0 && _this$contactList2.length) {
        // 如果不展示列表直接用第一个联系人信息传入
        this.clickContactPersonItem(this.contactList[0], row, item.operateType, meetingType);
      }
    },
    async getContactListByAccountId() {
      if (!this.accountId) return;
      this.contactList = [];
      const res = await (0, _client.getAllContactPersonList)(this.accountId);
      if (res.code === 200) {
        this.contactList = res.results;
      }
      if (!this.contactList.length) {
        // 如果没有联系人需要打开新建联系人
        this.$message.warning('暂无联系人，请先新建联系人');
        if (this.api_permissions.includes('/api/account/account/contact')) {
          setTimeout(() => {
            if (!this.isInRow) {
              this.openContactPersonDialog(this.rowInfo);
            } else {
              this.$emit('openContactPersonDialog', this.rowInfo);
            }
            this.accountId = null;
          }, 1000);
        }
      }
    },
    clickContactPersonItem(contactItem, row, type, meetingType) {
      this.$emit('clickContactPersonItem', contactItem, row, type, this.contactList, meetingType);
      this.accountId = null;
      this.hiddenPopover();
    },
    async openContactPersonDialog(row) {
      if (!this.contactDynamicFormDescList) {
        await this.$store.dispatch('account/getContactDynamicForm');
      }
      this.contactPersonDialogVisible = true;
      this.$nextTick(() => {
        this.$refs['createContactPersonDialog'].clearValidate();
      });
      this.contactFormDesc = this.descContactFormat(this.contactDynamicFormDescList, true, row);
    },
    hiddenPopover() {
      this.currentOperateType = '';
    },
    clickOtherHiddenPopover(e) {
      if (e.target.className !== 'contact-list-comp') {
        this.hiddenPopover();
      }
    }
  }
};
exports.default = _default;