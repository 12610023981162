"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartContainer = _interopRequireDefault(require("@/views/businessIntelligence/components/ChartContainer"));
var _PieChart = _interopRequireDefault(require("@/components/Charts/PieChart"));
var _ScatterTable = _interopRequireDefault(require("./ScatterTable"));
var _CrossBarChart = _interopRequireDefault(require("@/components/Charts/CrossBarChart"));
var _ScatterDiagramChart = _interopRequireDefault(require("@/components/Charts/ScatterDiagramChart"));
var _SuggestionBox = _interopRequireDefault(require("@/views/businessIntelligence/components/SuggestionBox"));
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DynamicChart',
  components: {
    ChartContainer: _ChartContainer.default,
    ScatterTable: _ScatterTable.default,
    CrossBarChart: _CrossBarChart.default,
    PieChart: _PieChart.default,
    ScatterDiagramChart: _ScatterDiagramChart.default,
    SuggestionBox: _SuggestionBox.default
  },
  mixins: [_ExportMixins.default],
  inject: ['getBiFilters'],
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleExportCsv(item) {
      switch (item.chartType) {
        // 饼图
        case 'pie':
          this.handlePieExportCsv(item);
          break;
        // 柱状图
        case 'bar':
          this.handleBarExportCsv(item);
          break;
        default:
          break;
      }
    },
    handlePieExportCsv(item) {
      var _this$$route, _this$$route$meta;
      const total = item.chartData.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0);
      const res = item.chartData.map(item => [item.name, item.value, (total ? item.value / total : item.value).toLocaleString('zh', {
        style: 'percent',
        maximumFractionDigits: 2
      })]);
      res.unshift([item.chartConfig.title, '出现次数', '占比']); // 添加表头
      res.push(['合计', total, total ? '100%' : '0%']);
      const {
        date
      } = this.getBiFilters();
      const dateArr = JSON.parse(date);
      const startDate = this.$moment(dateArr[0]).format('YYYY-MM-DD');
      const endDate = this.$moment(dateArr[1]).format('YYYY-MM-DD');
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${(_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$meta = _this$$route.meta) === null || _this$$route$meta === void 0 ? void 0 : _this$$route$meta.subtitle}_${item.chartConfig.title}_${startDate}至${endDate}.csv`);
    },
    handleBarExportCsv(item) {
      var _chartConfig$xAxis, _chartConfig$yAxis, _this$$route2, _this$$route2$meta;
      const {
        chartConfig,
        chartData
      } = item;
      const xName = (_chartConfig$xAxis = chartConfig.xAxis) === null || _chartConfig$xAxis === void 0 ? void 0 : _chartConfig$xAxis.name;
      const yName = (_chartConfig$yAxis = chartConfig.yAxis) === null || _chartConfig$yAxis === void 0 ? void 0 : _chartConfig$yAxis.name;
      const xIndex = chartData[0].indexOf(xName);
      const yIndex = chartData[0].indexOf(yName);
      const res = chartData.map(item => [item[yIndex], typeof item[xIndex] === 'number' ? item[xIndex].toFixed(2) : item[xIndex]]);
      const head = res.shift();
      res.unshift([head[0], item.chartConfig.title]);
      const {
        date
      } = this.getBiFilters();
      const dateArr = JSON.parse(date);
      const startDate = this.$moment(dateArr[0]).format('YYYY-MM-DD');
      const endDate = this.$moment(dateArr[1]).format('YYYY-MM-DD');
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${(_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : (_this$$route2$meta = _this$$route2.meta) === null || _this$$route2$meta === void 0 ? void 0 : _this$$route2$meta.subtitle}_${item.chartConfig.title}_${startDate}至${endDate}.csv`);
    }
  }
};
exports.default = _default;