"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModelDetailDrawer = _interopRequireDefault(require("./components/ModelDetailDrawer"));
var _TrainModelDialog = _interopRequireDefault(require("./components/TrainModelDialog.vue"));
var _ModelManagementFilters = _interopRequireDefault(require("./components/ModelManagementFilters.vue"));
var _ModelList = _interopRequireDefault(require("./components/ModelList.vue"));
var _CreateModelButton = _interopRequireDefault(require("./components/CreateModelButton"));
var _CreateAiModelDrawer = _interopRequireDefault(require("./components/CreateAiModelDrawer.vue"));
var _CreateRuleModelDrawer = _interopRequireDefault(require("./components/CreateRuleModelDrawer.vue"));
var _PublishModelContent = _interopRequireDefault(require("./components/PublishModelContent.vue"));
var _useCancelPublish = require("./hooks/use-cancel-publish");
var _modelInfo = require("../modelAllVersions/hooks/modelInfo.js");
var _useModelTableOperate = require("./hooks/use-model-table-operate");
var _SwitchVersionDialog = _interopRequireDefault(require("./components/SwitchVersionDialog.vue"));
var _EditRuleModelDrawer = _interopRequireDefault(require("./components/EditRuleModelDrawer.vue"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _MegaTranGuide = _interopRequireDefault(require("../components/MegaTranGuide.vue"));
var _CombineModelDrawer = _interopRequireDefault(require("./components/combineModel/CombineModelDrawer.vue"));
var _TrainDetailDrawer = _interopRequireDefault(require("@/views/management/modelAllVersions/components/TrainDetailDrawer.vue"));
var _modelManagement = require("@/api/modelManagement");
var _usePagination = require("./hooks/use-pagination");
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const router = (0, _useRouter.useRouter)();
    const modelList = (0, _vue.ref)([]);
    const modelListRef = (0, _vue.ref)(null);
    const listLoading = (0, _vue.ref)(false);
    async function getModelListAsync() {
      let newId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      listLoading.value = true;
      const filters = filtersRef.value.getFilters();
      const params = {
        ...filters,
        include_last_version: true,
        page: currentPage.value,
        size: pageSize.value
      };
      const res = await (0, _modelManagement.getModelList)(params).finally(() => {
        listLoading.value = false;
      });
      if (newId) {
        const newItem = res.results.models.find(item => item.id === newId);
        if (newItem) {
          newItem.isNew = true;
          await (0, _vue.nextTick)();
          console.log(modelListRef.value);
          modelListRef.value.$el.scrollTop = 0;
        }
      }
      modelList.value = res.results.models.map(item => {
        item.loading = false;
        return item;
      });
      total.value = res.results.count;
    }
    async function getModelItemAsync(id) {
      const index = modelList.value.findIndex(item => item.id === id);
      modelList.value[index].loading = true;
      const res = await (0, _modelManagement.getModelItem)({
        id
      });
      res.results.loading = false;
      modelList.value.splice(index, 1, res.results);
    }

    // 重试训练
    const {
      retryTrainAsync
    } = (0, _modelInfo.useRetryTrain)(getModelItemAsync);
    const retryTrain = model => {
      const {
        id,
        last_version
      } = model;
      retryTrainAsync(id, last_version.version);
    };
    (0, _vue.onMounted)(() => {
      getModelListAsync();
    });
    const {
      currentPage,
      pageSize,
      total,
      onSizeChange
    } = (0, _usePagination.usePagination)(getModelListAsync);
    const filtersRef = (0, _vue.ref)(null);
    const {
      cancelPublish
    } = (0, _useCancelPublish.useCancelPublish)();
    const {
      deleteModelAsync,
      trainDetailVisible,
      checkVersionModelInfo
    } = (0, _useModelTableOperate.useDeleteModel)(getModelListAsync);
    const combineModelDrawerRef = (0, _vue.ref)(null);
    const editCombineModelInfo = (0, _vue.ref)({});
    async function editCombineModel(modelInfo) {
      editCombineModelInfo.value = modelInfo;
      combineModelDrawerVisible.value = true;
      // 获取组合模型中的信息
      await (0, _vue.nextTick)();
      combineModelDrawerRef.value.getCombineModelDetailAsync();
    }
    const modelTableButtonClick = async (type, model) => {
      currentModelInfo.value = model;
      switch (type) {
        case 'delete':
          await deleteModelAsync({
            id: model.id,
            name: model.name
          });
          break;
        case 'training':
          trainingModelDialogVisible.value = true;
          break;
        case 'reTraining':
          trainingModelDialogVisible.value = true;
          TrainModelIsAutoTrial.value = true;
          break;
        case 'publish':
          publishDrawerVisible.value = true;
          break;
        case 'cancelPublish':
          cancelPublish(currentModelInfo.value, () => {
            getModelItemAsync(currentModelInfo.value.id);
          });
          break;
        case 'detail':
          detailDrawerVisible.value = true;
          break;
        case 'version':
          SwitchVersionDialogVisible.value = true;
          break;
        case 'editModel':
          // model_type 为 4 为组合模型
          if (model.model_type === 1) {
            editRuleModelDrawerVisible.value = true;
          } else if (model.model_type === 4) {
            var _model$last_version;
            editCombineModel({
              id: model.id,
              version: (_model$last_version = model.last_version) === null || _model$last_version === void 0 ? void 0 : _model$last_version.version,
              name: model.name
            });
          } else {
            if (model.model_cate === 2) {
              const routeData = router.resolve({
                path: _routeMap.ROUTES.RapidModeling,
                query: {
                  modelId: model.id
                }
              });
              window.open(routeData.href, '_blank');
            }
          }
          break;
        case 'refreshItem':
          getModelItemAsync(model.id);
          break;
        case 'retryTrainItem':
          retryTrain(model);
          break;
        default:
          break;
      }
    };
    const trainingModelDialogVisible = (0, _vue.ref)(false);
    const TrainModelIsAutoTrial = (0, _vue.ref)(false);
    const createDrawerVisible = (0, _vue.ref)(false);
    const combineModelDrawerVisible = (0, _vue.ref)(false);
    const onCreateButtonClick = async type => {
      const map = {
        AI: 3,
        rule: 1,
        combine: 4
      };
      let canCreate = false;
      const res = await (0, _modelManagement.validateModelCanCreate)({
        model_type: map[type]
      });
      if (res.code === 20000) {
        canCreate = res.results.can_create;
      }
      if (canCreate) {
        switch (type) {
          case 'AI':
            createDrawerVisible.value = true;
            break;
          case 'rule':
            createRuleDrawerVisible.value = true;
            break;
          case 'combine':
            combineModelDrawerVisible.value = true;
            editCombineModelInfo.value = {};
        }
      }
    };
    const createRuleDrawerVisible = (0, _vue.ref)(false);
    const publishDrawerVisible = (0, _vue.ref)(false);
    const SwitchVersionDialogVisible = (0, _vue.ref)(false);
    const editRuleModelDrawerVisible = (0, _vue.ref)(false);
    const currentModelInfo = (0, _vue.ref)({
      name: '模型A'
    });
    const detailDrawerVisible = (0, _vue.ref)(false);
    const openDetailDrawer = () => {
      detailDrawerVisible.value = true;
    };
    return {
      __sfc: true,
      router,
      modelList,
      modelListRef,
      listLoading,
      getModelListAsync,
      getModelItemAsync,
      retryTrainAsync,
      retryTrain,
      currentPage,
      pageSize,
      total,
      onSizeChange,
      filtersRef,
      cancelPublish,
      deleteModelAsync,
      trainDetailVisible,
      checkVersionModelInfo,
      combineModelDrawerRef,
      editCombineModelInfo,
      editCombineModel,
      modelTableButtonClick,
      trainingModelDialogVisible,
      TrainModelIsAutoTrial,
      createDrawerVisible,
      combineModelDrawerVisible,
      onCreateButtonClick,
      createRuleDrawerVisible,
      publishDrawerVisible,
      SwitchVersionDialogVisible,
      editRuleModelDrawerVisible,
      currentModelInfo,
      detailDrawerVisible,
      openDetailDrawer,
      ModelDetailDrawer: _ModelDetailDrawer.default,
      TrainModelDialog: _TrainModelDialog.default,
      ModelManagementFilters: _ModelManagementFilters.default,
      ModelList: _ModelList.default,
      CreateModelButton: _CreateModelButton.default,
      CreateAiModelDrawer: _CreateAiModelDrawer.default,
      CreateRuleModelDrawer: _CreateRuleModelDrawer.default,
      PublishModelContent: _PublishModelContent.default,
      SwitchVersionDialog: _SwitchVersionDialog.default,
      EditRuleModelDrawer: _EditRuleModelDrawer.default,
      MgPagination: _MgPagination.default,
      MegaTranGuide: _MegaTranGuide.default,
      CombineModelDrawer: _CombineModelDrawer.default,
      TrainDetailDrawer: _TrainDetailDrawer.default
    };
  }
};
exports.default = _default;