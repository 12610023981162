"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _routeMap = require("@/router/routeMap");
var _TextTag = _interopRequireDefault(require("./TextTag.vue"));
var _DialogTag = _interopRequireDefault(require("./DialogTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachDetailCard',
  components: {
    TextTag: _TextTag.default,
    DialogTag: _DialogTag.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isTodo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      markBtnLoading: false,
      isCoached: false
    };
  },
  methods: {
    writeSuggest() {
      const commentId = this.item.coach_info[0].advise_id;
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&commentId=${commentId}`);
    },
    goDetail() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}`);
    },
    async markCoached() {
      this.markBtnLoading = true;
      const res = await (0, _videoDetail.changeFinishTeach)({
        conversation_id: this.item.id
      });
      if (res.code === 200) {
        this.isCoached = true;
      }
      this.markBtnLoading = false;
    }
  }
};
exports.default = _default;