"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "bind-dialog",
    attrs: {
      visible: _vm.changeDialogVisible,
      "before-close": _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "filter-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticClass: "seat-operation"
  }, [_vm._v(" " + _vm._s(_vm.isChangeBind ? "修改绑定关系" : "绑定") + " ")]), _c("div", {
    staticClass: "seat-id"
  }, [_vm._v("坐席号 " + _vm._s(_vm.seatNumber))])]), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("div", {
    staticClass: "filter-select"
  }, [_vm._v("选择与该坐席绑定的成员账号：")]), _c("members-finder", {
    ref: "header-filters",
    attrs: {
      "popper-class": "agent-management-bind-dialog-members-finder",
      placeholder: "搜索成员",
      "is-radio": true,
      "get-member-api-type": "manage"
    },
    on: {
      change: _vm.searchSeat
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isChangeBind,
      expression: "isChangeBind"
    }],
    staticClass: "change-tip"
  }, [_vm._v(" 修改后，原账号将不再同步该坐席对应的会话 ")])], 1)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "cancel-btn",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    staticClass: "save-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleChangeBind
    }
  }, [_vm._v("确 定")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;