"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "客户邀请",
      visible: _vm.visible,
      width: "480px",
      "before-close": _vm.closeDialog
    }
  }, [_c("div", [_c("div", {
    staticClass: "success-info"
  }, [_c("i", {
    staticClass: "iconfont icon-success"
  }), _vm.modulePermission.calendar ? _c("p", [_vm._v("已添加至「会议日程」列表")]) : _c("p", [_vm._v("预约会议成功")])]), _c("textarea", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localInviteText,
      expression: "localInviteText"
    }],
    domProps: {
      value: _vm.localInviteText
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.localInviteText = $event.target.value;
      }
    }
  })]), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.localInviteText,
      expression: "localInviteText",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopySuccess,
      expression: "onCopySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onCopyError,
      expression: "onCopyError",
      arg: "error"
    }],
    attrs: {
      plain: ""
    }
  }, [_vm._v("复制全部")]), _vm.showButton ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goCalendar
    }
  }, [_vm._v("查看会议日程")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;