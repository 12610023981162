"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "预测赢单率设置",
      visible: _vm.dialogVisible,
      width: "400px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-row"
  }, [_c("div", {
    staticClass: "input-left bold"
  }, [_vm._v("商机阶段")]), _c("div", {
    staticClass: "input-right bold"
  }, [_vm._v("预测赢单率（%）")])]), _vm._l(_vm.stages, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "input-row"
    }, [!item.is_fixed ? [_c("div", {
      staticClass: "input-left"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "input-right"
    }, [_c("el-input-number", {
      attrs: {
        min: 0,
        max: 100,
        step: 10,
        "step-strictly": ""
      },
      model: {
        value: _vm.stages[index].rate,
        callback: function ($$v) {
          _vm.$set(_vm.stages[index], "rate", $$v);
        },
        expression: "stages[index].rate"
      }
    })], 1)] : [_c("div", {
      staticClass: "input-left"
    }, [_vm._v("赢单")]), _c("div", {
      staticClass: "input-right align-center"
    }, [_vm._v(_vm._s(item.rate))])]], 2);
  }), _c("span", {
    staticClass: "tips"
  }, [_vm._v("提示：此设置将应用于所有人。")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v("确 定")])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;