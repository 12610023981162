"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HitButtons = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitButtons.vue"));
var _HitTag = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitTag.vue"));
var _routeMap = require("@/router/routeMap");
var _ConversationLink = _interopRequireDefault(require("./ConversationLink.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ValidTrainDataTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isTrail: {
      type: Boolean,
      default: false
    },
    startIndex: {
      type: Number,
      default: 0
    },
    datasetStats: {
      type: Object,
      default: () => {}
    },
    tableHeight: {
      type: [Number, String],
      default: 400
    },
    datasetId: {
      type: Number,
      default: 0
    }
  },
  emits: ['clickModelSentence', 'annotateMark', 'selection-change', 'fixTrainData'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const conversationDetailPath = _routeMap.ROUTES.conversationDetail;
    const modelInfo = (0, _vue.inject)('modelInfo');
    const onClickModelSentence = item => {
      emit('clickModelSentence', item);
    };
    const handleSelectionChange = val => {
      emit('selection-change', val);
    };
    const annotateMark = async (status, row) => {
      emit('annotateMark', [props.isTrail ? row.praf_task_detail_id : row.id], status);
    };
    const handleFixTrainData = row => {
      emit('fixTrainData', row);
    };
    const deleteAnnotation = row => {
      emit('deleteAnnotation', [row.id]);
    };
    const getAnnotatePath = row => {
      const {
        dataset_data_id
      } = row;
      const basePath = _routeMap.ROUTES.annotate;
      return `${basePath}?id=${props.datasetId}&datasetDataId=${dataset_data_id}`;
    };
    return {
      __sfc: true,
      props,
      conversationDetailPath,
      modelInfo,
      emit,
      onClickModelSentence,
      handleSelectionChange,
      annotateMark,
      handleFixTrainData,
      deleteAnnotation,
      getAnnotatePath,
      HitButtons: _HitButtons.default
    };
  }
};
exports.default = _default;