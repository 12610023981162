"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$cardItem$create_b;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "compliance-task-card",
    class: {
      "high-light": _vm.cardItem.highLight,
      "in-progress": _vm.cardItem.status === 1,
      completed: _vm.cardItem.status === 2,
      cancelled: _vm.cardItem.status === 3
    },
    on: {
      click: _vm.handleClickTaskCard
    }
  }, [_c("div", {
    staticClass: "compliance-task-card-container"
  }, [_c("div", {
    staticClass: "task-card-top"
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.cardItem.name
    }
  }, [_c("div", {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.cardItem.name))])]), !_vm.noOperateButtons ? _c("div", {
    staticClass: "operate-buttons"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "取消任务"
    }
  }, [_vm.cardItem.status === 1 && _vm.cardItem.source === "compliance" && _vm.interfacePermission.cancelTask ? _c("i", {
    staticClass: "iconfont icon-circle-close",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("cancelComplianceTask", _vm.cardItem.id);
      }
    }
  }) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "删除"
    }
  }, [_vm.interfacePermission.deleteTask ? _c("i", {
    staticClass: "iconfont icon-delete",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("deleteComplianceTask", _vm.cardItem.id);
      }
    }
  }) : _vm._e()])], 1) : _vm._e(), _vm.startComplianceButton && _vm.cardItem.status === 1 ? _c("el-button", {
    staticClass: "start-compliance-button",
    attrs: {
      type: "primary",
      icon: "iconfont icon-protect-line"
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("goComplianceWorkSpace", _vm.cardItem.id);
      }
    }
  }, [_vm._v("开始质检")]) : _vm._e()], 1), _c("div", {
    staticClass: "distribute-info"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "分配人"
    }
  }, [_vm.cardItem.create_by ? _c("span", {
    staticClass: "create-by"
  }, [_vm._v(_vm._s((_vm$cardItem$create_b = _vm.cardItem.create_by) === null || _vm$cardItem$create_b === void 0 ? void 0 : _vm$cardItem$create_b.name))]) : _vm._e()]), _c("div", {
    staticClass: "card-time"
  }, [_vm._v(" 分配于 "), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "分配时间"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.cardItem.create_at).format("YYYY-MM-DD HH:mm")))])])], 1)], 1), _c("div", {
    staticClass: "card-data"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("div", {
    staticClass: "status"
  }, [_vm._v(" " + _vm._s(_vm.getTaskStatus(_vm.cardItem.status)) + " ")]), _c("div", {
    staticClass: "compliance-progress"
  }, [_vm._v(" " + _vm._s((_vm.cardItem.finish / _vm.cardItem.total * 100).toFixed(2)) + " % ")])]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "dark",
      content: "已质检/会话总数"
    }
  }, [_c("div", {
    staticClass: "task-data"
  }, [_vm._v(" " + _vm._s(_vm.cardItem.finish) + "/" + _vm._s(_vm.cardItem.total) + " ")])])], 1), _c("div", {
    staticClass: "progress-bar-outer"
  }, [_c("div", {
    class: ["progress-bar-inner"],
    style: {
      width: _vm.cardItem.finish / _vm.cardItem.total * 100 + "%"
    }
  })]), _vm.cardItem.user_infos && _vm.cardItem.user_infos.length ? _c("collapse-avatar", {
    attrs: {
      list: _vm.cardItem.user_infos
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;