"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "meeting-item"
  }, [_c("div", {
    staticClass: "meeting-info"
  }, [_c("p", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))]), _c("p", {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.date))])]), _c("el-button", {
    staticClass: "enter-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterMeeting
    }
  }, [_vm._v("进入会议")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;