"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "manage-developer"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("span", {
    staticClass: "back-btn",
    on: {
      click: _vm.goBack
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _vm._v(" 开发者管理 ")]), _c("el-button", {
    staticClass: "header-btn",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.openAddDeveloperDialog
    }
  }, [_c("i", {
    staticClass: "iconfont icon-user-add"
  }), _vm._v("添加开发者 ")])], 1), _vm.$config.isMegaview ? _c("div", {
    staticClass: "sub-info"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1 sub-info-icon"
  }), _vm._v(" 开发者是企业的开发人员，可以登录Megaview开放平台，调用开放接口，进行个性化需求开发 ")]) : _vm._e(), _c("manage-developer-table", {
    attrs: {
      "table-loading": _vm.tableLoading,
      "developer-list": _vm.developerList
    },
    on: {
      deleteDeveloper: _vm.deleteDeveloper
    }
  }), _c("add-developer-dialog", {
    attrs: {
      "is-visible": _vm.isVisible
    },
    on: {
      closeAddDeveloperDialog: _vm.closeAddDeveloperDialog,
      confirmAddDeveloper: _vm.confirmAddDeveloper
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      "current-page": _vm.paramsObj.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.paramsObj.size,
      total: _vm.total,
      layout: "total, prev, pager, next, sizes"
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;