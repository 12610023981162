import { render, staticRenderFns } from "./SuggestionSelect.vue?vue&type=template&id=db91a4f6&"
import script from "./SuggestionSelect.vue?vue&type=script&lang=js&"
export * from "./SuggestionSelect.vue?vue&type=script&lang=js&"
import style0 from "./SuggestionSelect.vue?vue&type=style&index=0&id=db91a4f6&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db91a4f6')) {
      api.createRecord('db91a4f6', component.options)
    } else {
      api.reload('db91a4f6', component.options)
    }
    module.hot.accept("./SuggestionSelect.vue?vue&type=template&id=db91a4f6&", function () {
      api.rerender('db91a4f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Filters/components/SuggestionSelect.vue"
export default component.exports