"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-menu", {
    staticClass: "bi-menu",
    attrs: {
      "default-active": _vm.$route.path,
      "default-openeds": _vm.defaultOpeneds,
      router: ""
    }
  }, [_vm._l(_vm.menuList, function (_ref) {
    let {
      name,
      path,
      link,
      meta: {
        subtitle,
        icon
      },
      children
    } = _ref;
    return [children ? _c("el-submenu", {
      key: path + "-" + subtitle,
      attrs: {
        index: link,
        "data-v-name": name || path
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("i", {
      class: ["iconfont", icon]
    }), _c("span", {
      staticClass: "menu-title"
    }, [_vm._v(_vm._s(subtitle))])]), _c("MenuContainer", {
      attrs: {
        "menu-list": children,
        "default-openeds": _vm.defaultOpeneds
      }
    })], 2) : _c("el-menu-item", {
      key: path,
      class: {
        "is-active": _vm.$route.path.includes(link)
      },
      attrs: {
        index: link,
        "data-v-name": name || path
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("i", {
      class: ["iconfont", icon]
    }), _c("span", {
      staticClass: "menu-title"
    }, [_vm._v(_vm._s(subtitle))])])], 2)];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;