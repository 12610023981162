"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MgOperateColumn',
  components: {
    MoreOperations: _MoreOperations.default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    // 操作列默认展示的按钮数量，超出则合并为更多操作
    showOperateCount: {
      type: [Number, String],
      default: 3
    },
    trigger: {
      type: String,
      default: 'click'
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allShowOperateList() {
      // 过滤没有权限而不展示的按钮
      return this.list.filter(item => item.isShow);
    },
    // 默认展示的按钮个数
    defaultButtonCount() {
      return this.allShowOperateList.length > this.showOperateCount ? this.showOperateCount - 1 : this.showOperateCount;
    },
    // 是否展示更多按钮
    showMoreOperate() {
      return this.allShowOperateList.length > this.showOperateCount ? this.showOperateCount - 1 : 0;
    }
  },
  methods: {
    handleClick(command) {
      if (!command) return;
      this.$emit('command', command);
    }
  }
};
exports.default = _default;