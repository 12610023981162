"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  methods: {
    // 联系人单格式化
    descContactFormat(formDesc, isHalfRow, accountInfo) {
      const desc = [];
      for (const item of formDesc) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: isHalfRow,
          tips: item.tips
        };
        if (item.name === '客户名称') {
          obj.default = accountInfo.name;
        }
        desc.push(obj);
      }
      return desc;
    }
  }
};
exports.default = _default;