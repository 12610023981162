"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "recommended-answer"
  }, [_c("el-collapse", {
    model: {
      value: _setup.activeNames,
      callback: function ($$v) {
        _setup.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    staticClass: "hit-collapse-item",
    attrs: {
      name: "knowledge"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("header", {
          staticClass: "title-row"
        }, [_c("span", {
          staticClass: "title-text"
        }, [_vm._v("推荐回答")])])];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "answer-item"
    }, [_c("span", {
      staticClass: "list-item"
    }, [_vm._v("✦")]), _c("p", {
      staticClass: "answer-text"
    }, [_vm._v(_vm._s(item))])]);
  }), _c("div", {
    staticClass: "btn-container"
  }, [_c("MgvButton", {
    attrs: {
      type: "text",
      size: "tiny"
    },
    on: {
      click: _setup.openKnowLedgeDetail
    }
  }, [_vm._v("知识点详情")])], 1)], 2)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;