"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "process-module"
  }, [_c("div", {
    staticClass: "module-title"
  }, [_c("span", [_vm._v("自动填写字段")]), _c("mgv-button", {
    attrs: {
      size: "medium",
      icon: "iconfont icon-plus",
      disabled: _setup.ruleList.length >= 30 || _setup.activeRuleKey,
      "tooltip-content": _setup.ruleList.length >= 30 ? "最多添加30个规则" : _setup.activeRuleKey ? "请先完成正在编辑的规则" : ""
    },
    on: {
      click: _setup.addRule
    }
  }, [_vm._v("添加规则")])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.listLoading,
      expression: "listLoading"
    }],
    ref: "ruleListRef",
    staticClass: "rule-list"
  }, _vm._l(_setup.ruleList, function (item, index) {
    return _c(_setup.RuleItemCard, {
      key: item.id,
      attrs: {
        "submit-loading": _setup.activeSubmitRuleKey === item.id,
        title: `规则${_setup.ruleList.length - index}`,
        "directly-editable": typeof item.id === "string",
        "edit-disabled": _setup.activeRuleKey && item.id !== _setup.activeRuleKey
      },
      on: {
        edit: function ($event) {
          return _setup.editRule(item);
        },
        delete: function ($event) {
          return _setup.deleteRule(item, index);
        },
        cancel: function ($event) {
          return _setup.cancelEdit(item, index);
        },
        submit: function ($event) {
          return _setup.submitEdit(item, $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "card-content",
        fn: function (_ref) {
          let {
            isEditing
          } = _ref;
          return [_c(_setup.RuleCardContent, {
            key: item.id,
            ref: _setup.setRuleCardContentRef(item.id),
            refInFor: true,
            attrs: {
              "process-type": _vm.processType,
              "is-editing": isEditing,
              "card-info": item
            }
          })];
        }
      }], null, true)
    });
  }), 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;