"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "create-task-form"
  }, [_c("div", {
    staticClass: "main-part"
  }, [!_vm.textOnly ? _c("div", {
    ref: "editor",
    staticClass: "div-editor",
    attrs: {
      contenteditable: "true"
    },
    domProps: {
      innerHTML: _vm._s(_vm.textHtml)
    },
    on: {
      input: _vm.inputChange
    }
  }) : _c("el-input", {
    ref: "task-content",
    staticClass: "task-content",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      maxlength: "400",
      placeholder: " 例如：明天12:00完成学习任务"
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }), _c("div", {
    staticClass: "executor-container"
  }, [_c("i", {
    staticClass: "iconfont icon-user-add"
  }), _c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _c("div", {
    staticClass: "executor-content"
  }, [_c("executor-info-list", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.executorData.length,
      expression: "executorData.length"
    }],
    attrs: {
      "executor-data": _vm.executorData
    },
    on: {
      deleteExecutor: _vm.handleDeleteExecutor
    }
  }), _c("el-popover", {
    ref: "executorPopover",
    attrs: {
      placement: "bottom-start",
      width: "300",
      trigger: "click",
      "popper-class": "add-task-executort-popover"
    }
  }, [_c("el-button", {
    staticClass: "add-executor-btn",
    class: {
      "add-more-btn": _vm.executorData.length
    },
    attrs: {
      slot: "reference",
      type: "text"
    },
    on: {
      click: _vm.addExecutor
    },
    slot: "reference"
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.executorData.length,
      expression: "executorData.length"
    }],
    staticClass: "el-icon-plus"
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.executorData.length,
      expression: "!executorData.length"
    }]
  }, [_vm._v("添加执行人")])]), _c("department-and-member-filter-tree", {
    ref: "departmentAndMemberTree",
    attrs: {
      params: _vm.departmentAndMemberTreeParams,
      "checked-nodes-data": _vm.executorData,
      "backfill-ids": _vm.backfillIds
    },
    on: {
      change: _vm.handleDepartmentAndMemberChange,
      memberFilterChange: _vm.handleMemberFilterChange
    }
  })], 1)], 1)]), _c("div", {
    staticClass: "date-container"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _c("div", {
    staticClass: "date-content",
    class: {
      "has-todo-time": _vm.todoTime
    }
  }, [_c("el-date-picker", {
    staticClass: "todo-time-picker",
    attrs: {
      format: "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm",
      type: "datetime",
      "popper-class": "dashboard-todo-item-time-select",
      "default-time": "18:00:00",
      clearable: false,
      editable: false,
      placeholder: "设置截止时间"
    },
    model: {
      value: _vm.todoTime,
      callback: function ($$v) {
        _vm.todoTime = $$v;
      },
      expression: "todoTime"
    }
  }), _vm.todoTime ? _c("el-button", {
    staticClass: "delete-btn",
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.deleteTodoTime
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]) : _vm._e()], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.todoTime,
      expression: "todoTime"
    }],
    staticClass: "alert-container"
  }, [_c("i", {
    staticClass: "iconfont icon-alarm-clock"
  }), _c("ReminderSelect", {
    attrs: {
      "default-reminder-time": _vm.defaultReminderTime,
      "todo-time": _vm.todoTime
    },
    on: {
      reminderChange: _vm.handelReminderChange
    }
  })], 1)], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleCancle
    }
  }, [_vm._v("取消")]), _c("el-button", {
    class: {
      "confirm-disabled-btn": _vm.confirmButtonDisabled
    },
    attrs: {
      type: "primary",
      disabled: _vm.confirmButtonDisabled,
      loading: _vm.createBtnLoading
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v("创建")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;