"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _tencent_meeting = _interopRequireDefault(require("@/assets/tencent_meeting.svg"));
var _authApp = require("@/api/authApp");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MeetingButton',
  props: {
    customClass: {
      type: [String, Object],
      default: ''
    }
  },
  // eslint-disable-next-line no-dupe-keys
  data() {
    return {
      tencent_meeting: _tencent_meeting.default
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_tencent_meeting', 'has_video_permission'])
  },
  methods: {
    handleClick() {},
    async handleTencentMeeting() {
      const res = await (0, _authApp.getIsBindTencent)();
      if (res.results) {
        this.$emit('click', 'tencent_meeting');
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `未授权应用，请前往<a style="color:#4461ec" href="${_routeMap.ROUTES.application}">个人中心-应用</a>关联账户`
        });
      }
    }
  }
};
exports.default = _default;