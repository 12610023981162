"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return Object.keys(_vm.userInfo).length ? _c("div", {
    staticClass: "container"
  }, [_c("image-avatar", {
    attrs: {
      name: _vm.userInfo.name,
      avatar: _vm.userInfo.avatar
    }
  }), _c("div", {
    staticClass: "right"
  }, [_c("h1", [_vm._v(_vm._s(_vm.userInfo.name))]), _c("span", [_c("svg-icon", {
    attrs: {
      "icon-class": "department"
    }
  }), _vm._v(_vm._s(_vm.getMainDepartmentInfo.name))], 1)])], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;