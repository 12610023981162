"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "evaluate-form-display"
  }, [_c("div", {
    staticClass: "evaluation-name-content"
  }, [_c("h2", [_vm._v(_vm._s(_vm.evaluationRecord.template_name))]), _c("p", [_vm._v(_vm._s(_vm.evaluationRecord.template_description))])]), _c("div", {
    staticClass: "evaluation-form-content"
  }, _vm._l(_vm.evaluationRecord.field_values, function (item) {
    return _c("div", {
      key: item.field_order,
      staticClass: "evaluation-form-item"
    }, [_c("div", {
      staticClass: "form-item-header"
    }, [_c("span", {
      staticClass: "serial-number"
    }, [_vm._v(_vm._s(String(item.field_order).padStart(2, "0")))]), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        content: item.field_propt,
        placement: "top",
        disabled: !item.field_propt
      }
    }, [_c("label", {
      class: {
        "has-description": item.field_propt
      }
    }, [_vm._v(_vm._s(item.field_name))])])], 1), _c("div", {
      staticClass: "form-item-content"
    }, [item.field_type === 3 || item.field_type === 4 ? _c("div", [item.option_ids.length ? _c("div", {
      staticClass: "radio-or-checkbox"
    }, _vm._l(_vm.getSelectedOptions(item), function (option) {
      return _c("span", {
        key: option.id
      }, [_vm._v(_vm._s(option.name))]);
    }), 0) : _c("span", [_vm._v("-")])]) : item.field_type === 7 ? _c("div", [_vm._v(" " + _vm._s(item.field_value ? _vm.$plus8ToCurrentZone(item.field_value).format("YYYY-MM-DD HH:mm:ss") : "-") + " ")]) : item.field_type === 8 ? _c("div", [item.cascader_ids && item.cascader_ids.length ? _c("FormDisplayCascader", {
      attrs: {
        option: item.options,
        ids: item.cascader_ids
      }
    }) : _c("div", [_vm._v("-")])], 1) : _c("div", [_vm._v(_vm._s(item.field_value || "-"))])])]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;