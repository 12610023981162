"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModelVersionSelect = _interopRequireDefault(require("@/views/management/modelManagement/components/ModelVersionSelect.vue"));
var _ValidTrainDataTable = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/ValidTrainDataTable.vue"));
var _FixTrainDatasetDialog = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/FixTrainDatasetDialog.vue"));
var _TrailDataDetailDialog = _interopRequireDefault(require("./TrailDataDetailDialog.vue"));
var _TrailResultsHeader = _interopRequireDefault(require("./TrailResultsHeader.vue"));
var _TrailResultTable = _interopRequireDefault(require("./TrailResultTable.vue"));
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _useAutoRefresh = require("@/views/management/rapidModeling/hooks/use-auto-refresh.js");
var _fixTrainDataset = require("@/views/management/rapidModeling/hooks/fixTrainDataset.js");
var _useDeleteTrailAnnotation = require("@/views/management/rapidModeling/hooks/use-delete-trail-annotation.js");
var _num_formatter = require("@/utils/num_formatter.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrailResultsDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'updateAnnotationStatus', 'reCreateTrianTask'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const modelInfo = (0, _vue.inject)('modelInfo');
    const setModelInfo = (0, _vue.inject)('setModelInfo');
    const goStep = (0, _vue.inject)('goStep');
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const tableHeight = (0, _vue.computed)(() => {
      if (localStorage.getItem('trail-dataset-drawer-info-alert-hide')) {
        return 'calc(100vh - 185px)';
      }
      return 'calc(100vh - 240px)';
    });
    const testProgress = (0, _vue.ref)(0);
    const modelVersion = (0, _vue.ref)(modelInfo.version);
    const resultsTableData = (0, _vue.ref)([]);
    const datasetId = (0, _vue.ref)(0);
    const datasetStats = (0, _vue.ref)({
      annotate_hit_count: 0,
      annotate_miss_count: 0,
      annotate_uncertain_count: 0,
      model_predict_hit_count: 0,
      model_predict_miss_count: 0,
      predict_hit_count: 0,
      predict_miss_count: 0,
      un_annotate_count: 0
    });
    const total = (0, _vue.ref)(0);
    const filterTotal = (0, _vue.ref)(0);
    const pageSize = (0, _vue.ref)(100);
    const handleSizeChange = () => {
      currentPage.value = 1;
      getModelTestResultTable();
    };
    const currentPage = (0, _vue.ref)(1);
    const tableSelection = (0, _vue.ref)([]);
    const onSelectionChange = val => {
      tableSelection.value = val.map(item => item.praf_task_detail_id);
    };
    const contentQuery = (0, _vue.ref)('');
    const prafTaskId = (0, _vue.ref)(0);
    const {
      startAutoRefresh,
      stopAutoRefresh
    } = (0, _useAutoRefresh.useAutoRefresh)(getModelTestResultTable);
    const filters = (0, _vue.ref)({
      predict_res: 0,
      status: 0,
      rule_result: 0,
      content: ''
    });
    // 筛选项变化的时候
    const filterChange = filter => {
      filters.value = filter;
      getModelTestResultTable();
      getModelPredictAnnotationIdsApi();
    };
    async function getModelTestResultTable() {
      if (!modelInfo.id) {
        stopAutoRefresh();
        return;
      }
      const params = {
        model_id: modelInfo.id,
        model_version: modelVersion.value || 1,
        page: currentPage.value,
        size: pageSize.value,
        ...filters.value
      };
      const res = await (0, _rapidCreateModel.getModelTestResultDetail)(params);
      testProgress.value = res.results.progress;
      total.value = res.results.total;
      filterTotal.value = res.results.filter_total;
      resultsTableData.value = res.results.data;
      datasetId.value = res.results.dataset_id;
      prafTaskId.value = res.results.praf_task_id;
      datasetStats.value = {
        un_annotate_count: res.results.un_annotate_count,
        predict_hit_count: res.results.predict_hit_count,
        predict_miss_count: res.results.predict_miss_count,
        annotate_hit_count: res.results.annotate_hit_count,
        annotate_miss_count: res.results.annotate_miss_count,
        annotate_uncertain_count: res.results.annotate_uncertain_count,
        model_predict_hit_count: res.results.model_predict_hit_count,
        model_predict_miss_count: res.results.model_predict_miss_count
      };
      if (testProgress.value === 1) {
        stopAutoRefresh();
        requestModelTestPrecision();
        // 请求验证集所有ids
        getModelPredictAnnotationIdsApi();
      }
    }
    async function updateTrainDataStatus(ids, status) {
      const params = {
        praf_task_id: prafTaskId.value,
        praf_task_detail_ids: ids,
        status
      };
      const res = await (0, _rapidCreateModel.batchUpdateTestData)(params);
      if (res.code === 20000) {
        getModelTestResultTable();
      }
    }
    const dataStatusButtons = [{
      status: 1,
      text: '命中'
    }, {
      status: 2,
      text: '未命中'
    }, {
      status: 3,
      text: '不确定'
    }];
    const resultsValue = (0, _vue.ref)({
      precision: 0,
      recall: 0,
      f1: 0
    });
    const requestModelTestPrecision = async () => {
      const res = await (0, _rapidCreateModel.getModelTestPrecision)({
        model_id: modelInfo.id,
        model_version: modelVersion.value
      });
      resultsValue.value = res.results;
    };
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        startAutoRefresh();
      } else {
        stopAutoRefresh();
      }
    }, {
      immediate: true
    });
    const reValidData = () => {
      setModelInfo({
        isContinue: true
      });
      goStep(3);
    };
    const reCreateTrianTask = () => {
      emit('reCreateTrianTask');
    };
    const trailDataIds = (0, _vue.ref)([]);
    const getModelPredictAnnotationIdsApi = async () => {
      const res = await (0, _rapidCreateModel.getModelPredictAnnotationIds)({
        praf_task_id: prafTaskId.value,
        ...filters.value
      });
      if (res.code === 20000) {
        trailDataIds.value = res.results.annotation_ids;
      }
    };
    const dialogVisible = (0, _vue.ref)(false);
    const currentTrailDataDetailId = (0, _vue.ref)(0);
    const openTrailDataDetailDialog = row => {
      dialogVisible.value = true;
      currentTrailDataDetailId.value = row.praf_task_detail_id;
    };

    // 删除测试集标注
    const {
      deleteTrailAnnotationApi
    } = (0, _useDeleteTrailAnnotation.useDeleteTrailAnnotation)({
      refreshTable: getModelTestResultTable
    });
    const clearAnnotationResult = praf_task_detail_ids => {
      deleteTrailAnnotationApi({
        praf_task_id: prafTaskId.value,
        praf_task_detail_ids: praf_task_detail_ids
      });
    };

    // 修正数据集
    const {
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      currentResultsRowInfo
    } = (0, _fixTrainDataset.useFixTrainDataset)(modelInfo);
    return {
      __sfc: true,
      props,
      emit,
      modelInfo,
      setModelInfo,
      goStep,
      updateVisible,
      tableHeight,
      testProgress,
      modelVersion,
      resultsTableData,
      datasetId,
      datasetStats,
      total,
      filterTotal,
      pageSize,
      handleSizeChange,
      currentPage,
      tableSelection,
      onSelectionChange,
      contentQuery,
      prafTaskId,
      startAutoRefresh,
      stopAutoRefresh,
      filters,
      filterChange,
      getModelTestResultTable,
      updateTrainDataStatus,
      dataStatusButtons,
      resultsValue,
      requestModelTestPrecision,
      reValidData,
      reCreateTrianTask,
      trailDataIds,
      getModelPredictAnnotationIdsApi,
      dialogVisible,
      currentTrailDataDetailId,
      openTrailDataDetailDialog,
      deleteTrailAnnotationApi,
      clearAnnotationResult,
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      currentResultsRowInfo,
      ModelVersionSelect: _ModelVersionSelect.default,
      FixTrainDatasetDialog: _FixTrainDatasetDialog.default,
      TrailDataDetailDialog: _TrailDataDetailDialog.default,
      TrailResultsHeader: _TrailResultsHeader.default,
      TrailResultTable: _TrailResultTable.default,
      MgPagination: _MgPagination.default,
      num2percent: _num_formatter.num2percent
    };
  }
};
exports.default = _default;