import { render, staticRenderFns } from "./SourceTag.vue?vue&type=template&id=c872edea&scoped=true&"
import script from "./SourceTag.vue?vue&type=script&lang=js&"
export * from "./SourceTag.vue?vue&type=script&lang=js&"
import style0 from "./SourceTag.vue?vue&type=style&index=0&id=c872edea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c872edea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c872edea')) {
      api.createRecord('c872edea', component.options)
    } else {
      api.reload('c872edea', component.options)
    }
    module.hot.accept("./SourceTag.vue?vue&type=template&id=c872edea&scoped=true&", function () {
      api.rerender('c872edea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Message/components/layout/SourceTag.vue"
export default component.exports