"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "batch-upload-dialog",
    attrs: {
      title: "批量导入" + _vm.uploadName,
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      width: "700px",
      top: "5vh"
    }
  }, [_c("el-steps", {
    staticClass: "batch-upload-steps",
    attrs: {
      active: _vm.active,
      "finish-status": "success"
    }
  }, [_c("el-step", {
    attrs: {
      title: "上传文件"
    }
  }), _c("el-step", {
    class: [`result-${_vm.status}`],
    attrs: {
      title: "导入数据"
    }
  }), _c("el-step", {
    attrs: {
      title: _vm.status === "error" ? "导入失败" : "导入完成",
      status: _vm.status,
      icon: _vm.lastStepIcon
    }
  })], 1), _vm.active === 0 ? _c("choose-file", {
    attrs: {
      "tempalte-router": _vm.tempalteRouter,
      alias: _vm.alias,
      "rule-text": _vm.ruleText,
      type: _vm.type,
      "upload-name": _vm.uploadName
    },
    on: {
      click: function ($event) {
        _vm.active++;
      },
      hasFile: function ($event) {
        _vm.hasFile = true;
      },
      success: _vm.uploadStart,
      cancel: _vm.closeDialog,
      start: function ($event) {
        _vm.active++;
      }
    }
  }) : _vm.active === 1 ? _c("upload-progress", {
    attrs: {
      percentage: _vm.percentage,
      "is-members": _vm.type === "employee"
    },
    on: {
      click: function ($event) {
        _vm.active--;
      },
      minimize: _vm.closeDialog
    }
  }) : _c("upload-result", {
    attrs: {
      "is-members": _vm.type === "employee",
      status: _vm.status,
      "upload-name": _vm.uploadName,
      "tempalte-router": _vm.tempalteRouter,
      results: _vm.uploadResults
    },
    on: {
      complete: _vm.closeDialog
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;