"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Password = _interopRequireDefault(require("@/components/Password"));
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _user = require("@/api/user");
var _config = require("@/utils/config");
var _validate = require("@/utils/validate");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _rsa = _interopRequireDefault(require("@/utils/rsa.js"));
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'twoFactorLogin',
  setup(__props) {
    const ACCOUNT_TYPE_MAP = {
      account: {
        label: '账号',
        validateRule: _validate.loginAccountValidator
      },
      tel: {
        label: '手机',
        validateRule: _validate.internationalTelValidator
      },
      email: {
        label: '邮箱',
        validateRule: _validate.emailValidator
      }
    };
    const store = (0, _useVuex.useStore)();
    const router = (0, _useRouter.useRouter)();
    const step = (0, _vue.ref)('first'); // 'first' 第一步 'second' 第二步

    const readChecked = (0, _vue.ref)(true);

    // 第一步的账户类型
    const accountType = (0, _vue.ref)('account'); // 账户类型  账户：account, 手机号：tel，邮箱：email
    // 第一步校验类型
    const validateType = (0, _vue.ref)('password'); // 验证类型  密码：password, 验证码：code
    // 第二步校验工具类型
    const validateToolType = (0, _vue.ref)('email'); // 二次验证工具类型  邮箱：email，手机号：tel

    const getLoginTitle = () => {
      if (step.value === 'second') {
        return `输入${ACCOUNT_TYPE_MAP[validateToolType.value].label}验证码`;
      } else {
        return '登录';
      }
    };
    const formRules = (0, _vue.ref)({
      account: [{
        required: true,
        trigger: 'blur',
        validator: ACCOUNT_TYPE_MAP[accountType.value].validateRule
      }],
      verify_code: [{
        required: true,
        message: `${validateType.value === 'code' ? '请输入验证码' : '请输入密码'}`,
        trigger: 'blur'
      }],
      second_verify_code: [{
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      }]
    });
    // 第一步验证
    const firstStepForm = (0, _vue.ref)({
      account: '',
      verify_code: ''
    });
    const firstStepFormRef = (0, _vue.ref)(null);
    const firstSubmitLoading = (0, _vue.ref)(false);
    const handleFirstStepSubmit = () => {
      firstStepFormRef.value.validate(async valid => {
        if (valid) {
          firstSubmitLoading.value = true;
          let verifyCode = firstStepForm.value.verify_code;
          if (validateType.value === 'password') {
            const {
              data: rsaPass
            } = await _rsa.default.getRsaCode(verifyCode);
            verifyCode = rsaPass;
          }
          const data = {
            method: accountType.value,
            account: firstStepForm.value.account,
            verify_type: validateType.value,
            verify_code: verifyCode
          };
          const res = await (0, _user.firstStepLogin)(data).finally(() => {
            firstSubmitLoading.value = false;
          });
          if (res.code === 20000) {
            const {
              is_two_factor,
              method,
              account,
              id
            } = res.results;
            if (is_two_factor) {
              validateToolType.value = method;
              secondVerify.value[method] = account;
              store.commit('user/SET_ID', id);
              step.value = 'second';
              postSendCode(); // 自动发送验证码
            }
          }
        }
      });
    };
    // 第二步验证
    const secondStepForm = (0, _vue.ref)({
      second_verify_code: ''
    });
    const secondVerify = (0, _vue.ref)({
      email: '',
      tel: ''
    });
    const secondSubmitLoading = (0, _vue.ref)(false);
    const secondStepFormRef = (0, _vue.ref)(null);
    const secondCodeInputRef = (0, _vue.ref)(null);
    const postSendCode = () => {
      (0, _vue.nextTick)(() => {
        secondCodeInputRef.value.postSendCode();
      });
    };
    const handleSecondStepSubmit = () => {
      secondStepFormRef.value.validate(async valid => {
        if (valid) {
          secondSubmitLoading.value = true;
          const data = {
            method: validateToolType.value,
            account: secondVerify.value[validateToolType.value],
            verify_type: 'code',
            verify_code: secondStepForm.value.second_verify_code
          };
          const res = await (0, _user.secondStepLogin)(data).finally(() => {
            secondSubmitLoading.value = false;
          });
          if (res.code === 20000) {
            // 第二次请求成功后的处理
            const {
              id,
              session
            } = res.results;
            store.commit('user/SET_TOKEN', session);
            store.commit('user/SET_ID', id);
            router.push({
              path: _routeMap.ROUTES.chooseTeam
            });
          }
        }
      });
    };
    return {
      __sfc: true,
      ACCOUNT_TYPE_MAP,
      store,
      router,
      step,
      readChecked,
      accountType,
      validateType,
      validateToolType,
      getLoginTitle,
      formRules,
      firstStepForm,
      firstStepFormRef,
      firstSubmitLoading,
      handleFirstStepSubmit,
      secondStepForm,
      secondVerify,
      secondSubmitLoading,
      secondStepFormRef,
      secondCodeInputRef,
      postSendCode,
      handleSecondStepSubmit,
      Password: _Password.default,
      verificationCodeInput: _VerificationCodeInput.default,
      serviceAgreementUrl: _config.serviceAgreementUrl,
      privacyPolicyUrl: _config.privacyPolicyUrl
    };
  }
};
exports.default = _default;