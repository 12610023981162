"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ENTITY_TITLE = exports.ENTITY_DETAIL = void 0;
const ENTITY_DETAIL = {
  deal: [{
    key: 'deal_name',
    label: '商机'
  }, {
    key: 'amount',
    label: '金额'
  }, {
    key: 'deal_stage',
    label: '阶段'
  }, {
    key: 'plan_to_deal_at',
    label: '预计成交日期'
  }, {
    key: 'risk',
    label: '风险'
  }],
  account: [{
    key: 'level',
    label: '客户级别'
  }, {
    key: 'industry',
    label: '行业'
  }, {
    key: 'scale',
    label: '企业规模'
  }, {
    key: 'region',
    label: '区域'
  }],
  lead: [{
    key: 'position',
    label: '职位'
  }, {
    key: 'company',
    label: '公司名称'
  }, {
    key: 'industry',
    label: '行业'
  }, {
    key: 'scale',
    label: '企业规模'
  }, {
    key: 'region',
    label: '区域'
  }]
};
exports.ENTITY_DETAIL = ENTITY_DETAIL;
const ENTITY_TITLE = {
  deal: {
    icon: 'office-building',
    key: 'account_name'
  },
  account: {
    icon: 'office-building',
    key: 'name'
  },
  lead: {
    icon: 'leads',
    key: 'name'
  }
};
exports.ENTITY_TITLE = ENTITY_TITLE;