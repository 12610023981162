"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "复制数据集",
      visible: _vm.visible,
      width: "600px",
      "before-close": _setup.handleClose,
      "append-to-body": "",
      "close-on-click-modal": false
    }
  }, [_c("el-form", {
    ref: "copyDataSetFormRef",
    attrs: {
      model: _setup.form,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "数据集名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入",
      maxlength: "20",
      type: "text",
      "show-word-limit": ""
    },
    model: {
      value: _setup.form.name,
      callback: function ($$v) {
        _vm.$set(_setup.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文件夹",
      prop: "folder_id"
    }
  }, [_c(_setup.FolderSelect, {
    attrs: {
      "external-folder-id": _vm.folderId
    },
    on: {
      folderChange: _setup.handleFolderChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "标注结果",
      prop: "annotation_type"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _setup.form.is_copy_annotate_results,
      callback: function ($$v) {
        _vm.$set(_setup.form, "is_copy_annotate_results", $$v);
      },
      expression: "form.is_copy_annotate_results"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true
    }
  }, [_vm._v("复制标注")]), _c("el-radio", {
    attrs: {
      label: false
    }
  }, [_vm._v("不复制标注，清空")])], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _setup.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.handleSubmit
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;