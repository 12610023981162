"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "select-role"
  }, [_c("div", {
    staticClass: "content-top"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.subTitle))]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      content: _vm.tooltipText,
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })])], 1), _c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick",
      value: 200,
      expression: "200"
    }],
    attrs: {
      icon: "el-icon-plus",
      type: "text"
    },
    on: {
      click: function ($event) {
        return _vm.openRoleDrawer({
          id: 0
        }, "add");
      }
    }
  }, [_vm._v("新建")])], 1), _c("div", {
    staticClass: "search-role-input"
  }, [_c("el-input", {
    attrs: {
      placeholder: "搜索角色名称"
    },
    on: {
      input: _vm.searchRole
    },
    model: {
      value: _vm.roleName,
      callback: function ($$v) {
        _vm.roleName = $$v;
      },
      expression: "roleName"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.roleLoading,
      expression: "roleLoading"
    }],
    staticClass: "role-list"
  }, _vm._l(_vm.roleList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "role-card",
      class: {
        "is-active-role": _vm.currentRoleId === item.id
      },
      on: {
        click: function ($event) {
          return _vm.switchRightPage(item);
        }
      }
    }, [_c("div", {
      staticClass: "role-title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.is_default,
        expression: "item.is_default"
      }],
      staticClass: "default-tag"
    }, [_vm._v("默认")]), _c("div", {
      staticClass: "operate-button"
    }, [_vm.subTitle === "业务角色" && item.id || item.editable ? _c("el-button", {
      attrs: {
        icon: "el-icon-edit"
      },
      on: {
        click: function ($event) {
          return _vm.openRoleDrawer(item, "edit");
        }
      }
    }) : _vm._e(), _vm.subTitle === "业务角色" && item.id || item.copyable || item.deletable ? _c("el-dropdown", {
      attrs: {
        placement: "bottom-start"
      },
      on: {
        command: function ($event) {
          return _vm.handleCommand($event, item);
        }
      }
    }, [_c("span", {
      staticClass: "el-dropdown-link"
    }, [_c("i", {
      staticClass: "el-icon-more"
    })]), _c("el-dropdown-menu", {
      staticClass: "func-role-operate-dropdown",
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, [_vm.subTitle === "业务角色" || item.copyable ? _c("el-dropdown-item", {
      attrs: {
        command: "copy"
      }
    }, [_vm._v("复制")]) : _vm._e(), !item.is_default && _vm.subTitle === "业务角色" ? _c("el-dropdown-item", {
      attrs: {
        command: "default"
      }
    }, [_vm._v("设为默认")]) : _vm._e(), _vm.subTitle === "业务角色" || item.deletable ? _c("el-dropdown-item", {
      attrs: {
        command: "delete"
      }
    }, [_vm._v("删除")]) : _vm._e()], 1)], 1) : _vm._e()], 1)]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;