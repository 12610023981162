"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
var _TableExport = _interopRequireDefault(require("@/components/TableExport.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessTransTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    TableExport: _TableExport.default
  },
  mixins: [_ExportMixins.default],
  props: {
    kanbanId: {
      type: Number,
      default: 0
    },
    eventInfo: {
      type: Object,
      default: () => ({})
    },
    pieSelection: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      tableHead: [],
      tableData: [],
      originTableData: [],
      total: 0,
      eventIndex: 0,
      page: 1,
      size: 20,
      lastSort: {
        prop: '',
        order: 'descending'
      },
      editFieldKey: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone']),
    tooltips() {
      const companyConversionRate = this.tableHead[3].children[0].name;
      const personalConversionRate = this.tableHead[1].children[0].name;
      return `如何判断某事件是否为卡点? 
      以全量数据计算某事件的${companyConversionRate}为基准线，
      若某事件成员${personalConversionRate}>=${companyConversionRate}，
      则不是卡点；若某事件成员${personalConversionRate}<${companyConversionRate}，则是卡点`;
    },
    eachPageData() {
      const start = (this.page - 1) * this.size;
      const end = this.page * this.size;
      return this.tableData.slice(start, end);
    },
    queryParams() {
      const {
        custom_filters,
        date,
        tree_user_ids: {
          tree_ids,
          user_ids
        }
      } = this.biFiltersObj;
      return {
        custom_filters,
        date,
        tree_ids,
        user_ids,
        kanban_id: this.kanbanId,
        event_index: this.eventIndex,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
    },
    clickableColumn() {
      return prop => ['trans_other_count', 'trans_next_count', 'loss_count'].includes(prop);
    },
    noRightBorderColumn() {
      return prop => ['trans_other_rate', 'trans_next_rate', 'loss_rate'].includes(prop);
    },
    clickableClass() {
      return prop => this.clickableColumn(prop) ? 'clickable' : '';
    },
    noRightBorderClass() {
      return prop => this.noRightBorderColumn(prop) ? 'no-right-border' : '';
    },
    isStuckClass() {
      return prop => prop === 'is_prob' ? 'is-stuck' : '';
    }
  },
  watch: {
    pieSelection(val) {
      this.handlePieSelectionChange(val);
    }
  },
  methods: {
    exportBusinessAndLossdata: _businessIntelligence.exportBusinessAndLossdata,
    editField(key) {
      this.editFieldKey = key;
      this.$nextTick(() => {
        this.$refs[key][0].select();
      });
    },
    handleEditFieldName(item) {
      this.editFieldKey = '';
      this.$emit('submit', {
        [item.filed_key]: item.name
      });
      if (!item.name) {
        item.name = item.origin_filed_name;
      }
    },
    async getTableData() {
      this.loading = true;
      const customFilters = (0, _commonFunc.deepClone)(this.queryParams);
      (0, _timezone.convertParams2UTCplus8)(customFilters.custom_filters);
      customFilters.time_zone = this.timezone;
      const res = await (0, _businessIntelligence.getMemberData)(customFilters).finally(() => {
        this.loading = false;
      });
      if (res.code === 200) {
        this.tableHead = [];
        this.$nextTick(() => {
          const {
            heads,
            rows,
            count,
            ...surplus
          } = res.results;
          this.tableHead = heads;
          this.tableData = rows;
          this.originTableData = JSON.parse(JSON.stringify(rows));
          this.total = count;
          this.$emit('fetchDataSucceed', {
            count,
            ...surplus
          });
        });
      }
    },
    getExportTableHeads() {
      const [primaryHead, secondaryHead] = [[], []];
      this.tableHead.forEach(item => {
        if (item.children) {
          item.children.forEach(child => {
            primaryHead.push(item.name);
            secondaryHead.push(child.name);
          });
        } else {
          primaryHead.push(item.name);
          secondaryHead.push('');
        }
      });
      return [primaryHead, secondaryHead];
    },
    getExportTableData() {
      return this.tableData.map(_ref => {
        let {
          user: {
            name
          },
          trans_other_rate,
          trans_other_count,
          loss_rate,
          loss_count,
          is_prob
        } = _ref;
        return [name, trans_other_rate, trans_other_count, loss_rate, loss_count, is_prob ? '是' : '否'];
      });
    },
    handleSortChange(_ref2) {
      let {
        column,
        prop,
        order
      } = _ref2;
      if (!order) return;
      order === 'ascending' ? this.tableData.sort((a, b) => parseFloat(a[prop]) - parseFloat(b[prop])) : this.tableData.sort((a, b) => parseFloat(b[prop]) - parseFloat(a[prop]));
    },
    handleCellClick: (0, _commonFunc.debounce)(async function (row, column, cell, event) {
      const {
        property
      } = column;
      if (!['trans_other_count', 'loss_count'].includes(property) || !row[property]) {
        return;
      }
      const {
        user: {
          id: user_id
        }
      } = row;
      const type = property === 'trans_other_count' ? 'trans' : 'loss';
      const params = {
        ...this.queryParams,
        user_id,
        type
      };
      const res = await (0, _businessIntelligence.getMemberDealIds)(params);
      if (res.code === 200) {
        const otherPageCarryingFilter = {
          tradeIds: res.results.data,
          label: '业务转化卡点分析筛选条件'
        };
        sessionStorage.setItem('otherPageCarryingFilter', JSON.stringify(otherPageCarryingFilter));
        window.open(`${location.origin}${_routeMap.ROUTES.trade}`);
        sessionStorage.removeItem('otherPageCarryingFilter');
      }
    }, 300),
    handleSizeChange(size) {
      this.size = size;
    },
    handleCurrentChange(page) {
      this.page = page;
    },
    handlePieSelectionChange(val) {
      this.page = 1;
      this.clearSortStatus();
      if (!val) {
        this.tableData = JSON.parse(JSON.stringify(this.originTableData));
        this.total = this.tableData.length;
      } else {
        const {
          isStuck
        } = val;
        this.tableData = this.originTableData.filter(_ref3 => {
          let {
            is_prob
          } = _ref3;
          return is_prob === isStuck;
        });
        this.total = this.tableData.length;
      }
    },
    exportData() {
      const heads = this.getExportTableHeads();
      const data = this.getExportTableData();
      const {
        activeTabName,
        startDate,
        endDate
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${startDate}至${endDate}.csv`;
      (0, _download.downloadBlob)(this.handleFileToBuf([...heads, ...data]), fileName);
    },
    clearSortStatus() {
      this.$refs['table'] && this.$refs['table'].clearSort();
    }
  }
};
exports.default = _default;