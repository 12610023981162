"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useVModel = _interopRequireDefault(require("@/hooks/useVModel"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    rapidModelInfo: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:rapidModelInfo'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const modelTypesArr = [{
      name: 'AI模型',
      id: 1
    }];
    const modelUsesArr = [{
      name: '关键事件',
      id: 1
    }];
    const modelInfoForm = (0, _useVModel.default)(props, 'rapidModelInfo', emit);
    const rules = {
      name: [{
        required: true,
        message: '请输入模型名称',
        trigger: 'change'
      }]
    };
    const rapidModelFormRef = (0, _vue.ref)(null);
    const rapidModelFormValidate = async () => {
      return await rapidModelFormRef.value.validate();
    };
    expose({
      rapidModelFormValidate
    });
    return {
      __sfc: true,
      props,
      modelTypesArr,
      modelUsesArr,
      emit,
      modelInfoForm,
      rules,
      rapidModelFormRef,
      rapidModelFormValidate
    };
  }
};
exports.default = _default;