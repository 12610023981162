"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AddAdminDialog',
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    scopeList: {
      type: Array,
      default: () => []
    },
    hasDepartmentMember: {
      type: Boolean,
      default: false
    },
    roleInfo: {
      type: Object,
      default: () => ({})
    },
    manageTree: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      memberIdForm: {
        id: []
      },
      rules: {
        id: [{
          required: true,
          message: '请选择成员',
          trigger: 'change'
        }]
      },
      isDistributable: false,
      currentScopeList: [],
      buttonLoading: false
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.currentScopeList = this.manageTree;
      }
    },
    scopeList(val) {
      if (val.length) {
        this.currentScopeList = val;
      }
    }
  },
  mounted() {},
  methods: {
    handleMemberChange(val) {
      if (val[0]) {
        this.memberIdForm.id = val;
      } else {
        this.memberIdForm.id = [];
      }
    },
    openEditScopeDialog() {
      this.$emit('openEditScopeDialog', {
        type: 'add',
        scopeList: this.currentScopeList
      });
    },
    submitFormData() {
      this.$refs['memberIdForm'].validate(async valid => {
        if (valid) {
          let data = {};
          if (this.hasDepartmentMember) {
            const scopeIds = [];
            if (this.currentScopeList.length) {
              this.currentScopeList.forEach(item => {
                scopeIds.push(item.id);
              });
            }
            data = {
              user_ids: this.memberIdForm.id,
              tree_ids: scopeIds,
              is_distributable: this.isDistributable,
              role_ids: [this.roleInfo.id]
            };
          } else {
            data = {
              user_ids: this.memberIdForm.id,
              is_distributable: this.isDistributable,
              role_ids: [this.roleInfo.id]
            };
          }
          this.buttonLoading = true;
          try {
            const res = await (0, _permissions.addAdminMembers)(data);
            if (res.code === 20000) {
              setTimeout(() => {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.buttonLoading = false;
                this.$emit('fetchMemberData');
                this.handleClose();
              }, 1000);
            } else {
              this.buttonLoading = false;
            }
          } catch {
            this.buttonLoading = false;
          }
        }
      });
    },
    handleClose() {
      this.reset();
      this.$emit('update:visible', false);
    },
    reset() {
      this.isDistributable = false;
      this.$refs['memberFinder'].selection = [];
      this.$nextTick(() => {
        this.$refs['memberIdForm'].resetFields();
      });
    }
  }
};
exports.default = _default;