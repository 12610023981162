"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAnnotateKeyEventTracker = addAnnotateKeyEventTracker;
exports.annotateConversationComplete = annotateConversationComplete;
exports.cancelEdit = cancelEdit;
exports.changeKeyEventPublicScope = changeKeyEventPublicScope;
exports.copyEventToSpace = copyEventToSpace;
exports.createFolder = createFolder;
exports.createRuleTrainTask = createRuleTrainTask;
exports.deleteAnnotateKeyEventTracker = deleteAnnotateKeyEventTracker;
exports.deleteFolder = deleteFolder;
exports.deleteKeyEventPermission = deleteKeyEventPermission;
exports.disableKeyEvent = disableKeyEvent;
exports.editKeyEventPermission = editKeyEventPermission;
exports.enableKeyEvent = enableKeyEvent;
exports.getAnnotateKeyEventList = getAnnotateKeyEventList;
exports.getAnnotateKeyEventTracker = getAnnotateKeyEventTracker;
exports.getDisplayEventInfo = getDisplayEventInfo;
exports.getDisplayFolderList = getDisplayFolderList;
exports.getDisplayTableData = getDisplayTableData;
exports.getEventInfo = getEventInfo;
exports.getEventTrackerOfSentence = getEventTrackerOfSentence;
exports.getEventTrainTask = getEventTrainTask;
exports.getFolderList = getFolderList;
exports.getKeyEventPermission = getKeyEventPermission;
exports.getMarkConvInfos = getMarkConvInfos;
exports.getOrgEventCount = getOrgEventCount;
exports.getScopeOfKeyEvent = getScopeOfKeyEvent;
exports.getTableData = getTableData;
exports.getTaskProgress = getTaskProgress;
exports.isEditing = isEditing;
exports.moveEvent = moveEvent;
exports.renameFolder = renameFolder;
exports.saveEdit = saveEdit;
exports.setScopeOfKeyEvent = setScopeOfKeyEvent;
exports.startEdit = startEdit;
exports.transferOwnerOfKeyEvent = transferOwnerOfKeyEvent;
exports.updateEventInfo = updateEventInfo;
var _request = require("@/utils/request");
// 非编辑状态
// 获取文件夹列表
function getDisplayFolderList(params) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/root_customize_node',
    method: 'get',
    params
  });
}
// 获取事件列表
function getDisplayTableData(params) {
  return (0, _request.webService)({
    url: '/event/customize_events',
    method: 'get',
    params
  });
}
// 获取事件信息
function getDisplayEventInfo(params) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/tracker',
    method: 'get',
    params
  });
}
// 判断是否是编辑状态
function isEditing() {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/status',
    method: 'get'
  });
}

// 编辑状态
// 进入编辑状态
function startEdit() {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/start',
    method: 'post'
  });
}

// 取消修改
function cancelEdit() {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/cancel',
    method: 'post'
  });
}

// 保存
function saveEdit(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/save',
    method: 'post',
    data
  });
}

// 获取事件文件夹列表
function getFolderList(params) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/get/event_folder_list',
    method: 'get',
    params
  });
}

// 获取事件文件夹下事件列表
function getTableData(params) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/get/event_list',
    method: 'get',
    params
  });
}

// 新建事件/事件文件夹
function createFolder(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/create/event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 重命名事件文件夹
function renameFolder(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/rename/event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除事件/事件文件夹
function deleteFolder(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/delete/event',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取事件信息
function getEventInfo(params) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/get/event',
    method: 'get',
    params
  });
}

// 修改事件信息
function updateEventInfo(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/update/event',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 移动事件
function moveEvent(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/move/event',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 获取任务完成进度
function getTaskProgress() {
  return (0, _request.request)({
    url: '/customize_event/customize_event/task_progress',
    method: 'get'
  });
}

// 复制自定义关键事件
function copyEventToSpace(data) {
  return (0, _request.request)({
    url: '/customize_event/customize_event/edit/cp/event',
    dataType: 'json',
    method: 'post',
    data
  });
}

// 查询用户所在组织的自定义关键事件数量
function getOrgEventCount() {
  return (0, _request.webService)({
    url: '/workspace/customize_event/stat',
    method: 'get'
  });
}

// ------------------------------------------------------------------ new key event
// 获取标注详情页会话统计信息
function getMarkConvInfos(params) {
  return (0, _request.webService)({
    url: '/mp_event/mark_infos',
    method: 'get',
    params
  });
}

// 会话完成标注
function annotateConversationComplete(data) {
  return (0, _request.webService)({
    url: '/mp_event/finish_annotate',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 标注页面获取关键事件列表
function getAnnotateKeyEventList(params) {
  return (0, _request.webService)({
    url: '/mp_event/event_overview',
    method: 'get',
    params
  });
}

// 标注页面获取关键事件详情
function getAnnotateKeyEventTracker(params) {
  return (0, _request.webService)({
    url: '/mp_event/event_engines',
    method: 'get',
    params
  });
}

// 标注页面取消命中事件
function deleteAnnotateKeyEventTracker(data) {
  return (0, _request.webService)({
    url: '/mp_event/delete_event_engine',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 标注页面获取句子命中关键事件
function getEventTrackerOfSentence(params) {
  return (0, _request.webService)({
    url: '/mp_event/event_engine/order_events',
    method: 'get',
    params
  });
}

// 添加命中事件
function addAnnotateKeyEventTracker(data) {
  return (0, _request.webService)({
    url: '/mp_event/add_event_engine',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getEventTrainTask(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_train_tasks',
    method: 'get',
    params
  });
}
function createRuleTrainTask(data) {
  return (0, _request.webService)({
    url: '/mp_event/create_training_task',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 设置关键事件范围
function setScopeOfKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/set_scope',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取关键事件范围
function getScopeOfKeyEvent(params) {
  return (0, _request.webService)({
    url: '/mp_event/get_scope',
    method: 'get',
    params
  });
}
function changeKeyEventPublicScope(data) {
  return (0, _request.webService)({
    url: '/mp_event/permission/change_public_scope',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getKeyEventPermission(params) {
  return (0, _request.webService)({
    url: '/mp_event/permission/list',
    method: 'get',
    params
  });
}
function editKeyEventPermission(data) {
  return (0, _request.webService)({
    url: '/mp_event/permission/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}
function transferOwnerOfKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/permission/transfer_owner',
    method: 'post',
    dataType: 'json',
    data
  });
}
function deleteKeyEventPermission(data) {
  return (0, _request.webService)({
    url: '/mp_event/permission/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 启用事件
function enableKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/enable',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 禁用事件
function disableKeyEvent(data) {
  return (0, _request.webService)({
    url: '/mp_event/disable',
    method: 'post',
    dataType: 'json',
    data
  });
}