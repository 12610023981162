"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MessageOperate = _interopRequireDefault(require("./MessageOperate.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChatMessage',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MessageOperate: _MessageOperate.default
  },
  props: {
    // message.status: loading（请求中/回答中）;done（已回答完成）;cancel（取消回答）
    message: {
      type: Object,
      default: () => ({})
    },
    questionImport: {
      type: Boolean,
      default: false
    },
    importTooltip: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'avatar'])
  },
  methods: {
    handleImportClick(info) {
      this.$emit('importClick', info);
    }
  }
};
exports.default = _default;