"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _IntelligentGenerationUserInput = _interopRequireDefault(require("./IntelligentGenerationUserInput"));
var _ConversationSelector = _interopRequireDefault(require("./ConversationSelector.vue"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _coachingCenter = require("@/api/coachingCenter");
var _IntelligentGenerationForm = _interopRequireDefault(require("./IntelligentGenerationForm"));
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentGenerationDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: 'update:visible',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const curStep = (0, _vue.ref)('input'); // ['input', 'selectConversation', 'generating', 'generateFailed', 'form']
    const description = (0, _vue.ref)('');
    const conversationChecked = (0, _vue.ref)({});
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        curStep.value = 'input';
        description.value = '';
        conversationChecked.value = {};
      }
    });
    const checkConversation = val => {
      conversationChecked.value = val;
      curStep.value = 'input';
    };
    const formDesc = (0, _vue.ref)({
      customer_info: [],
      progress: []
    });
    const submitUserInput = async () => {
      curStep.value = 'generating';
      const params = {
        conversation_id: conversationChecked.value.id,
        prompt: description.value
      };
      (0, _coachingCenter.getAiWriteForm)(params).then(res => {
        curStep.value = 'form';
        formDesc.value = res.results;
      }).catch(() => {
        curStep.value = 'generateFailed';
      });
    };
    const replaceNewLine = str => {
      return str.replace(/[\n\r\t]+/gm, '\n- ');
    };
    const generatePrompt = async prompt => {
      const customer_info = prompt.customer_info.reduce((prev, cur) => {
        if (cur.isPreset) {
          return prev + `- ${replaceNewLine(cur.value)}\n`;
        } else {
          return prev + `- ${cur.key}：${replaceNewLine(cur.value)}\n`;
        }
      }, '## 定义角色和基本信息\n');
      if (customer_info.length > 400) {
        _elementUi.Message.warning('客户信息提示词不能超过400字');
        return;
      }
      const progress = prompt.progress.reduce((prev, cur, index) => {
        return prev + `${index + 1}. ${cur.key}\n- ${replaceNewLine(cur.value)}\n`;
      }, '## 你需要严格遵守对话流程\n');
      if (progress.length > 400) {
        _elementUi.Message.warning('对话流程提示词不能超过400字');
        return;
      }
      emit('generated', {
        customer_info,
        progress
      });
      emit('update:visible', false);
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      curStep,
      description,
      conversationChecked,
      checkConversation,
      formDesc,
      submitUserInput,
      replaceNewLine,
      generatePrompt,
      IntelligentGenerationUserInput: _IntelligentGenerationUserInput.default,
      ConversationSelector: _ConversationSelector.default,
      SpaceButton: _SpaceButton.default,
      IntelligentGenerationForm: _IntelligentGenerationForm.default
    };
  }
};
exports.default = _default;