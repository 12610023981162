"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "annotate-label"
  }, [_c("div", {
    staticClass: "annotate-label__header"
  }, [_vm._v("标签")]), _c("div", {
    staticClass: "annotate-label__content"
  }, [_c("div", {
    staticClass: "annotate-label__content-operate"
  }, [_c("el-button", {
    attrs: {
      plain: "",
      icon: "iconfont icon-plus",
      "data-v-step": "addNewAnnotateLabel"
    },
    on: {
      click: _setup.onCreateAnnotateLabel
    }
  }, [_vm._v("标签")]), _c("span", [_vm._v("快捷键")])], 1), _c("div", {
    staticClass: "annotate-label__content-container"
  }, [_c("el-collapse", {
    on: {
      change: _setup.handleChange
    },
    model: {
      value: _setup.activeNames,
      callback: function ($$v) {
        _setup.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.dataSetLabelList, function (item, index) {
    var _vm$activeDataSetData;
    return _c("div", {
      key: item.id,
      staticClass: "annotate-container"
    }, [_c("el-collapse-item", {
      directives: [{
        name: "shortkey",
        rawName: "v-shortkey",
        value: [`${index + 1}`],
        expression: "[`${index + 1}`]"
      }],
      key: item.id,
      attrs: {
        name: item.id,
        "data-v-step": "annotateLabel"
      },
      nativeOn: {
        shortkey: function ($event) {
          return _setup.createAnnotate(item);
        }
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("div", {
      staticClass: "el-collapse-item__title"
    }, [_c("i", {
      staticClass: "iconfont icon-caret-right"
    }), _c("div", {
      staticClass: "annotate-label-name",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.createAnnotate(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + "（" + _vm._s(item.positive_count) + "） ")])]), _c("div", {
      staticClass: "el-collapse-item__header-operate"
    }, [_c("el-dropdown", {
      attrs: {
        trigger: "click"
      },
      on: {
        command: function ($event) {
          return _setup.onCommand($event, item, index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-more-v",
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }), _c("el-dropdown-menu", {
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, [_c("el-dropdown-item", {
      attrs: {
        icon: "iconfont icon-edit",
        command: "rename"
      }
    }, [_vm._v("重命名")]), _c("el-popconfirm", {
      attrs: {
        "confirm-button-text": "删除",
        "cancel-button-text": "取消",
        icon: "iconfont icon-warning",
        title: "确定永久删除吗?",
        "popper-class": "annotate-label-delete-popconfirm"
      },
      on: {
        onConfirm: function ($event) {
          return _setup.deleteDataSetLabelApi(item);
        }
      }
    }, [_c("el-dropdown-item", {
      attrs: {
        slot: "reference",
        icon: "iconfont icon-delete",
        command: "delete"
      },
      slot: "reference"
    }, [_vm._v("删除")])], 1)], 1)], 1)], 1)]), _c(_setup.KeyEventDetailList, {
      attrs: {
        "can-edit-key-event": "",
        "media-type": (_vm$activeDataSetData = _vm.activeDataSetData) === null || _vm$activeDataSetData === void 0 ? void 0 : _vm$activeDataSetData.type,
        list: _vm.eventLabelOfSentenceMap[Number(item.id)]
      },
      on: {
        deleteKeyEventDetailTracker: _setup.deleteAnnotate
      }
    })], 2), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.id === _setup.isRenameId,
        expression: "item.id === isRenameId"
      }],
      staticClass: "edit-annotate-label"
    }, [_c("el-input", {
      ref: _setup.setRenameInputRefs,
      refInFor: true,
      on: {
        blur: function ($event) {
          return _setup.onRenameAnnotateLabel(item);
        }
      },
      nativeOn: {
        keyup: function ($event) {
          if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    })], 1), _c("div", {
      staticClass: "shortcut-container"
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index < 9,
        expression: "index < 9"
      }],
      staticClass: "shortcut"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])])], 1);
  }), 0), _setup.isShowCreateAnnotateInput ? _c("div", {
    staticClass: "new-annotate-label"
  }, [_c("el-input", {
    ref: "newAnnotateLabelInputRef",
    attrs: {
      maxlength: "10",
      placeholder: "请输入标签名称"
    },
    on: {
      blur: _setup.onBlurNewAnnotateLabel
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return $event.target.blur();
      }
    },
    model: {
      value: _setup.newAnnotateLabelName,
      callback: function ($$v) {
        _setup.newAnnotateLabelName = $$v;
      },
      expression: "newAnnotateLabelName"
    }
  })], 1) : _vm._e()], 1)]), _c("MgvTour", {
    ref: "annotateLabelTourRef",
    attrs: {
      name: "isShowAnnotateLabelGuide",
      steps: _setup.annotateLabelGuideSteps
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;