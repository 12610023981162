"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _videoDetail = require("@/api/videoDetail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CorrectKeyEventHistory',
  components: {
    KeyEventDetailList: _keyEventDetailList.default
  },
  props: {
    conversationId: {
      type: [Number, String],
      default: 0
    },
    participantsInfo: {
      type: Array,
      default: () => []
    },
    mediaType: {
      type: String,
      default: 'audio'
    }
  },
  data() {
    return {
      loading: false,
      requestParams: {
        conversation_id: 0,
        page: 1,
        size: 10
      },
      correctKeyEventHistoryList: [],
      hasMore: false
    };
  },
  methods: {
    goKeyEventDrawer(item) {
      if (item.operate === 'add') {
        this.$bus.$emit('goKeyEventDrawer', {
          eventId: item.event.id
        });
      }
    },
    getIcon(val) {
      switch (val) {
        case 'add':
          return 'iconfont icon-plus';
        case 'delete':
          return 'iconfont icon-delete';
      }
    },
    async getCorrectKeyEventHistory() {
      this.requestParams.conversation_id = this.conversationId;
      this.loading = true;
      const res = await (0, _videoDetail.getCorrectKeyEventHistory)(this.requestParams).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.correctKeyEventHistoryList = [...this.correctKeyEventHistoryList, ...res.results.histories];
        this.hasMore = this.requestParams.size === res.results.histories.length;
        this.requestParams.page++;
      }
    }
  }
};
exports.default = _default;