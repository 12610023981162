"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-item-answers"
  }, [_c("ul", _vm._l(_vm.list, function (item, index) {
    return _c("li", {
      key: index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;