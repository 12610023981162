"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "preset-path-button"
  }, [_c("el-button", {
    staticClass: "path-explain-btn",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.openPathExplain
    }
  }, [_vm._v("什么是业务路径？")]), _c("el-button", {
    staticClass: "edit-path-btn",
    class: {
      "set-path-btn": !_vm.hasPresetPath
    },
    attrs: {
      icon: _vm.hasPresetPath ? "el-icon-edit" : "el-icon-plus",
      plain: ""
    },
    on: {
      click: _vm.handlePresetBtnClick
    }
  }, [_vm._v(_vm._s(_vm.hasPresetPath ? "编辑预设业务路径" : "预设业务路径"))])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;