"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePlayAudio = usePlayAudio;
var _vue = require("vue");
function usePlayAudio() {
  const currentPlayAudioId = (0, _vue.ref)(null);
  const audioPlayProgree = (0, _vue.ref)(0);
  const audioPlayer = new Audio();
  const currentPlayTime = (0, _vue.ref)('');
  let timer;
  const init = () => {
    timer && clearInterval(timer);
    currentPlayTime.value = '';
    audioPlayProgree.value = 0;
    currentPlayAudioId.value = null;
  };
  const getAudioDurantion = audioCurrentPlayTime => {
    const minutes = Math.floor(audioCurrentPlayTime / 60);
    const remainingSeconds = Math.floor(audioCurrentPlayTime % 60);
    const filterZero = (num, unit) => num ? `${num >= 10 ? num : '0' + num}${unit}` : '';
    return `${filterZero(minutes, '′')}${filterZero(remainingSeconds, '″')}`;
  };
  const playAudio = (id, src) => {
    init();
    currentPlayAudioId.value = id;
    audioPlayer.src = src;
    let audioCurrentPlayTime = 0;
    audioPlayer.oncanplaythrough = () => {
      const totalStep = (audioPlayer.duration || 0) * 10;
      audioPlayer.play();
      let currentStep = 0;
      timer = setInterval(() => {
        if (totalStep > currentStep) {
          currentStep++;
          audioPlayProgree.value = currentStep / totalStep * 100;
          audioCurrentPlayTime += 0.1;
          currentPlayTime.value = getAudioDurantion(audioCurrentPlayTime);
        }
      }, 100);
    };
    audioPlayer.onended = e => {
      init();
    };
  };
  const pauseAudio = () => {
    audioPlayer.pause();
    init();
  };
  return {
    currentPlayAudioId,
    playAudio,
    pauseAudio,
    currentPlayTime,
    audioPlayProgree
  };
}