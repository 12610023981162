"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GradeProgress = _interopRequireDefault(require("@/components/ConversationPageComp/GradeProgress.vue"));
var _ruleTypeOptionsMixin = require("@/mixin/ruleTypeOptionsMixin");
var _timeFormatter = require("@/utils/time-formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScoreDetail',
  components: {
    GradeProgress: _GradeProgress.default
  },
  props: {
    scoreList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getTotalScore(score) {
      return JSON.parse(score).score;
    },
    getScoreTooltip(score, score_type) {
      const score_ext = JSON.parse(score);
      let text = '';
      score_ext.items.forEach(item => {
        const prefixText = score_type === 2 ? `第${item.gte}-${item.lte}次，每次` : `任意${item.hits}项`;
        text += `${prefixText}${score_ext.mode === 'increase' ? '加' : '减'}${item.score}分；`;
      });
      text += `最多${score_ext.mode === 'increase' ? '加' : '减'}${score_ext.score}分`;
      return text;
    },
    toggleAllExpandAndCollapse(index) {
      this.$emit('toggleAllExpandAndCollapse', index);
    },
    toggleItemExpandAndCollapse(index) {
      this.$emit('toggleItemExpandAndCollapse', index);
    },
    getHitIcon(mode, isHit) {
      return `iconfont ${isHit ? mode === 'increase' ? 'icon-check' : 'icon-close' : 'icon-circle-dash'}`;
    },
    getTypeLabel(type) {
      var _ruleTypeOptions$type;
      return (_ruleTypeOptions$type = _ruleTypeOptionsMixin.ruleTypeOptions[type]) === null || _ruleTypeOptions$type === void 0 ? void 0 : _ruleTypeOptions$type.label;
    },
    fromatValue(data, type) {
      var _ruleTypeOptions$type4;
      if (!Array.isArray(data)) data = [data];
      if (type === 'hang_up') {
        var _ruleTypeOptions$type2, _ruleTypeOptions$type3;
        return (_ruleTypeOptions$type2 = _ruleTypeOptionsMixin.ruleTypeOptions[type]) === null || _ruleTypeOptions$type2 === void 0 ? void 0 : (_ruleTypeOptions$type3 = _ruleTypeOptions$type2.mappings[data]) === null || _ruleTypeOptions$type3 === void 0 ? void 0 : _ruleTypeOptions$type3.label;
      }
      const unit = (_ruleTypeOptions$type4 = _ruleTypeOptionsMixin.ruleTypeOptions[type]) === null || _ruleTypeOptions$type4 === void 0 ? void 0 : _ruleTypeOptions$type4.unit;
      data = data.map(item => {
        return item === -1 ? item : unit === 'ms' ? (0, _timeFormatter.secondToCNTimeArr)(item).join('') : item + unit;
      });
      const [min, max] = data;
      if (min === -1 || max === -1) {
        return (min === -1 ? ['小于等于', max] : ['大于等于', min]).join('');
      } else {
        return data.join(' - ');
      }
    }
  }
};
exports.default = _default;