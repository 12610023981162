"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _ExportTableButton = _interopRequireDefault(require("@/views/sop/ScoreAnalysis/components/ExportTableButton.vue"));
var _MemberAssignTable = _interopRequireDefault(require("./components/MemberAssignTable"));
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router.js");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _useTableOperate = require("../hooks/use-table-operate");
var _useVuex = require("@/hooks/use-vuex");
var _compliance = require("@/api/compliance");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const createComplianceMemberAssign = (0, _vue.computed)(() => store.getters.interfacePermission.createComplianceMemberAssign);
    const router = (0, _useRouter.useRouter)();
    const createMemberAssign = () => {
      router.push({
        path: _routeMap.ROUTES.complianceCreateMemberAssign
      });
    };
    const params = (0, _vue.ref)({
      page: 1,
      size: 10,
      user_ids: {}
    });
    const {
      sizeChange,
      pageChange,
      memberChange,
      getTableList,
      tableInfo,
      loading,
      workspaceId
    } = (0, _useTableOperate.useTableOperate)(_compliance.getComplianceAssignMember, params);
    getTableList();
    const exportTableRef = (0, _vue.ref)(null);
    const exportTableData = () => {
      exportTableRef.value.exportTable({
        workspace_id: workspaceId.value,
        user_ids: params.value.user_ids
      });
    };
    return {
      __sfc: true,
      store,
      createComplianceMemberAssign,
      router,
      props,
      createMemberAssign,
      params,
      sizeChange,
      pageChange,
      memberChange,
      getTableList,
      tableInfo,
      loading,
      workspaceId,
      exportTableRef,
      exportTableData,
      NoData: _NoData.default,
      ExportTableButton: _ExportTableButton.default,
      MemberAssignTable: _MemberAssignTable.default,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      exportComplianceAssignMember: _compliance.exportComplianceAssignMember
    };
  }
};
exports.default = _default;