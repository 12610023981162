"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hit-tag"
  }, [_c("span", {
    staticClass: "dot",
    class: [{
      hit: _vm.status === 1
    }, {
      "un-hit": _vm.status === 2
    }, {
      "no-hit": _vm.status === 3
    }]
  }), _c("span", [_vm._v(_vm._s(_vm.statusObj[_vm.status]))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;