"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _formatDataUnit = require("@/components/Charts/formatDataUnit.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ReportDataChart',
  components: {
    ChartTemplate: _ChartTemplate.default
  },
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    // 卡片配置
    setOptions(_ref, _ref2) {
      let {
        type,
        configs
      } = _ref;
      let {
        data
      } = _ref2;
      if (!data || !data.length) return;
      let xAxis, yAxis, grid;
      const tooltip = {
        confine: true,
        formatter: _ref3 => {
          let {
            marker,
            name,
            data
          } = _ref3;
          return formatUnit(`<span style='line-height: 20px;'>${marker}</span> ${name}`, type === 'Line' ? data[1] : data[0], configs);
        }
      };
      const AxisLableFormatter = {
        type: 'value',
        minInterval: 1,
        axisLabel: {
          formatter: val => {
            return formatUnit('', val, configs).replace(/<[^>]+>/g, '');
          }
        }
      };
      if (type === 'Line') {
        grid = {
          top: '5%',
          left: '10%',
          right: '5%',
          bottom: '10%'
        };
        xAxis = {
          type: 'category'
        };
        yAxis = AxisLableFormatter;
      }
      if (type === 'Bar') {
        grid = {
          top: '5%',
          left: '20%',
          right: '15%',
          bottom: '10%'
        };
        xAxis = AxisLableFormatter;
        yAxis = {
          type: 'category'
        };
      }
      const option = {
        grid,
        color: '#3393FF',
        tooltip,
        xAxis,
        yAxis,
        dataset: {
          source: data
        }
      };
      option.series = {
        type: type.toLowerCase(),
        encode: {
          x: 0,
          y: 1
        },
        itemStyle: {
          normal: {
            // 这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
            barBorderRadius: [0, 5, 5, 0]
          }
        }
      };
      return option;
    }
  }
};
exports.default = _default;
function formatUnit(label, data, unitConfig) {
  return `${label}<span style="margin-left: 30px; font-weight: bold;">${(0, _formatDataUnit.formatDataUnit)(unitConfig, data)}</span>`;
}