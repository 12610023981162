"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mg-money-input"
  }, [_vm.isEdit ? [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isActived,
      expression: "!isActived"
    }],
    staticClass: "el-input__inner simulate-box",
    on: {
      click: _vm.handleSimulateBoxClick
    }
  }, [_vm.newValue ? _c("span", {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.formatNewValue) + " ")]) : _c("span", {
    staticClass: "placeholder"
  }, [_vm._v(" " + _vm._s(`请输入${_vm.label}`) + " ")])]), _c("el-input", _vm._g({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isActived,
      expression: "isActived"
    }],
    ref: "input",
    attrs: {
      maxlength: _vm.maxlengthLocal,
      disabled: _vm.disabled,
      placeholder: `请输入${_vm.label}`
    },
    on: {
      input: _vm.handleChange,
      blur: _vm.handleBlur
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleKeypress.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "newValue"
    }
  }, _vm.$listeners))] : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, [_vm._v(_vm._s(_vm.formatNewValue))]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;