"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PracticeProcessTipsSwitch = _interopRequireDefault(require("./PracticeProcessTipsSwitch.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyScriptSet',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    activeScriptId: {
      type: Number,
      default: 0
    },
    addScriptReply: {
      type: Function,
      default: () => {}
    }
  },
  emits: 'update:activeScriptId',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onContentInput = (val, el) => {
      el.innerText = val ? ` “${val}” ` : ' 请在右侧设置关键问题 ';
      if (val) {
        el.classList.remove('is-empty');
      } else {
        el.classList.add('is-empty');
      }
    };
    const addReply = item => {
      props.addScriptReply(item.id);
      focusScript(item.id);
    };
    const deleteReply = (item, index) => {
      item.replies.splice(index, 1);
      focusScript(item.id);
    };
    const focusScript = id => {
      emit('update:activeScriptId', id);
    };
    const promptStruct = (0, _vue.inject)('promptStruct');
    return {
      __sfc: true,
      props,
      emit,
      onContentInput,
      addReply,
      deleteReply,
      focusScript,
      promptStruct,
      PracticeProcessTipsSwitch: _PracticeProcessTipsSwitch.default
    };
  }
};
exports.default = _default;