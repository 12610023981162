"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TestDetailFilters',
  props: {
    testTaskInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isHit: '',
      isAnnotated: '',
      isRight: ''
    };
  },
  methods: {
    handleChange() {
      const filters = {
        is_hit: this.isHit || 0,
        is_annotated: this.isAnnotated || 0,
        is_right: this.isRight || 0
      };
      this.$emit('filterChange', filters);
    }
  }
};
exports.default = _default;