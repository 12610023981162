"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSwitchAnnotateData = useSwitchAnnotateData;
var _elementUi = require("element-ui");
var _dataSet = require("@/api/dataSet");
var _vue = require("vue");
function useSwitchAnnotateData(_ref) {
  let {
    store,
    dataSetId,
    activeDataSetData,
    dataSetDataInfo,
    dataSetDataList,
    getEventListOfSentenceApi,
    getDataSetLabelsApi,
    getDataSetDataInfoApi,
    initCurrentSampleAnnotateInfo
  } = _ref;
  let timer = null;
  const changeDataSetData = async item => {
    store.dispatch('video/set_cur_order', {
      order: 0,
      time: new Date().getTime()
    });
    activeDataSetData.value = item;
    initCurrentSampleAnnotateInfo();
    getDataSetLabelsApi();
    getEventListOfSentenceApi();
    await getDataSetDataInfoApi();
  };
  const completeAnnotateApi = async () => {
    const res = await (0, _dataSet.completeAnnotate)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id
    });
    return res;
  };
  const changeDataSetDataOperate = async _ref2 => {
    let {
      operate,
      activeDataSetDataIndex
    } = _ref2;
    switch (operate) {
      case 'next':
        if (activeDataSetDataIndex === dataSetDataList.value.length - 1) {
          _elementUi.Message.warning('已经是最后一条样本');
          return;
        }
        changeDataSetData(dataSetDataList.value[activeDataSetDataIndex + 1]);
        break;
      case 'prev':
        if (activeDataSetDataIndex === 0) {
          _elementUi.Message.warning('当前为第一条样本');
          return;
        }
        changeDataSetData(dataSetDataList.value[activeDataSetDataIndex - 1]);
        break;
      case 'completedAndNext':
        {
          const res = await completeAnnotateApi();
          if (res.code === 20000) {
            _elementUi.Message.success('标注完成');
            activeDataSetData.value.is_annotated = true;
            if (activeDataSetDataIndex === dataSetDataList.value.length - 1) {
              return;
            }
            const autoNext = localStorage.getItem(`${store.getters.userId}-annotate-auto-next`);
            timer && clearTimeout(timer);
            if (autoNext === 'true') {
              timer = setTimeout(() => {
                changeDataSetData(dataSetDataList.value[activeDataSetDataIndex + 1]);
              }, 300);
            }
          }
        }
        break;
    }
  };
  (0, _vue.onBeforeUnmount)(() => {
    clearTimeout(timer);
    timer = null;
  });
  return {
    changeDataSetData,
    changeDataSetDataOperate
  };
}