"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CustomFieldHeader',
  props: {
    title: {
      type: String,
      default: '线索字段'
    },
    desc: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    noCount: {
      type: Boolean,
      default: false
    }
  }
};
exports.default = _default;