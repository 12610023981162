"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CustomFieldTypeGroup',
  props: {
    value: String,
    isEdit: Boolean,
    type: String
  },
  computed: {
    options() {
      switch (this.type) {
        case 'account':
          return {
            text: '文本',
            radio: '选项列表 ',
            number: '数字',
            money: '金额',
            date: '日期和时间',
            link: '超链接',
            tel: '手机号',
            email: '邮箱'
          };
        default:
          return {
            text: '文本',
            radio: '选项列表 ',
            number: '数字',
            money: '金额',
            date: '日期和时间',
            tel: '手机号',
            email: '邮箱'
            // file:'附件',
          };
      }
    }
  },

  methods: {
    handleSelect(key) {
      if (this.isEdit) {
        this.$message.warning('不能修改类型！');
      } else {
        this.$emit('input', key);
      }
    }
  }
};
exports.default = _default;