"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _TrainDetailDrawer = _interopRequireDefault(require("../../modelAllVersions/components/TrainDetailDrawer.vue"));
var _TestRecordsDrawer = _interopRequireDefault(require("../../modelAllVersions/components/TestRecordsDrawer.vue"));
var _TestDetailDrawer = _interopRequireDefault(require("../../modelAllVersions/components/TestDetailDrawer.vue"));
var _TestModelDialog = _interopRequireDefault(require("./TestModelDialog.vue"));
var _index = require("@/utils/index.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModelItemTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    modelId: {
      type: Number,
      default: 0
    },
    modelType: {
      type: String,
      default: 'rule'
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refreshData', 'retryTrain', 'reTraining'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const headers = (0, _vue.computed)(() => {
      return [{
        prop: 'version',
        label: '最新版本',
        minWidth: '150px'
      }, {
        prop: 'train_status',
        label: props.modelType === 'ai' ? '训练状态' : '',
        minWidth: '180px'
      }, {
        prop: 'trial_status',
        label: '最新测试结果',
        minWidth: '220px'
      }, {
        prop: 'create_by',
        label: '更新人',
        minWidth: '150px'
      }, {
        prop: 'update_at',
        label: '更新时间',
        minWidth: '150px'
      }];
    });
    const refreshData = () => {
      emit('refreshData');
    };
    const retryTrain = row => {
      emit('retryTrain', row);
    };
    const activeVersion = (0, _vue.ref)(0);
    // 打开训练详情抽屉
    const trainDetailVisible = (0, _vue.ref)(false);
    const openTrainDetailDrawer = row => {
      activeVersion.value = row.version;
      trainDetailVisible.value = true;
    };
    // 打开测试记录抽屉
    const testRecordsVisible = (0, _vue.ref)(false);
    const openTestRecordsDrawer = row => {
      activeVersion.value = row.version;
      testRecordsVisible.value = true;
    };

    // 打开测试详情抽屉
    const testDetailVisible = (0, _vue.ref)(false);
    const activePrafTaskId = (0, _vue.ref)(0);
    const openTestDetailDrawer = row => {
      activeVersion.value = row.version;
      activePrafTaskId.value = row.trial_status.praf_task_id;
      testDetailVisible.value = true;
    };

    // 测试模型Dialog
    const TestModelVisible = (0, _vue.ref)(false);
    const openTestModelDialog = row => {
      activeVersion.value = row.version;
      TestModelVisible.value = true;
    };
    return {
      __sfc: true,
      props,
      emit,
      headers,
      refreshData,
      retryTrain,
      activeVersion,
      trainDetailVisible,
      openTrainDetailDrawer,
      testRecordsVisible,
      openTestRecordsDrawer,
      testDetailVisible,
      activePrafTaskId,
      openTestDetailDrawer,
      TestModelVisible,
      openTestModelDialog,
      TrainDetailDrawer: _TrainDetailDrawer.default,
      TestRecordsDrawer: _TestRecordsDrawer.default,
      TestDetailDrawer: _TestDetailDrawer.default,
      TestModelDialog: _TestModelDialog.default,
      handlePercentage: _index.handlePercentage
    };
  }
};
exports.default = _default;