"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LeaderTableOperate',
  components: {
    MgOperateColumn: _MgOperateColumn.default
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    operateList() {
      const {
        is_edit_access,
        is_delete_access,
        children,
        pid
      } = this.row;
      const list = [{
        command: 'goDetail',
        label: this.$t(children ? 'coaching.overview' : 'coaching.details'),
        isShow: true
      }, {
        command: is_edit_access ? 'edit' : '',
        label: this.$t('coaching.edit'),
        isShow: is_edit_access
      }, {
        command: 'copyTask',
        label: this.$t('coaching.copy'),
        icon: 'icon-copy-document',
        isShow: this.interfacePermission.createCoachingTask
      }, {
        command: 'delete',
        label: this.$t('coaching.delete'),
        icon: 'icon-delete',
        isShow: is_delete_access,
        class: 'delete'
      }];
      // 父任务操作列(把子任务提取出来的)有所有按钮
      if (pid && !children) return list;
      // 子任务操作列只有详情按钮，父任务没有详情按钮
      return children ? list.slice(0) : list.slice(0, 1);
    }
  },
  methods: {
    handleCommand(command) {
      if (!command) return;
      if (command === 'goDetail') {
        this.$emit('goDetail');
      }
      this.$emit('command', command);
    }
  }
};
exports.default = _default;