"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LlmPostProcessing = _interopRequireDefault(require("./LlmPostProcessing"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModelPredictionResult',
  props: {
    postProcessType: {
      type: Number,
      default: 1
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:postProcessType'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const postProcessTypeList = [{
      label: '不处理',
      value: 1
    }, {
      label: '只保留销售发言',
      value: 2
    }, {
      label: '只保留客户发言',
      value: 3
    }];
    const postProcessTypeChange = val => {
      emit('update:postProcessType', val);
    };
    const LlmPostProcessingRef = (0, _vue.ref)(null);
    const getPromptContent = () => {
      return LlmPostProcessingRef.value.getPromptContent();
    };
    expose({
      getPromptContent
    });
    return {
      __sfc: true,
      props,
      postProcessTypeList,
      emit,
      postProcessTypeChange,
      LlmPostProcessingRef,
      getPromptContent,
      LlmPostProcessing: _LlmPostProcessing.default
    };
  }
};
exports.default = _default;