"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "angle-tag",
    class: _vm.canClick ? "can-click" : ""
  }, [_vm.type === "first" ? _c("div", {
    staticClass: "first-tag father-dom",
    style: {
      background: _vm.colorObj.backgroudColor,
      borderColor: _vm.colorObj.borderColor,
      color: _vm.colorObj.fontColor
    }
  }, [_vm._t("default", function () {
    return [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.label,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "tag-label"
    }, [_vm._v(_vm._s(_vm.label))])])];
  }), _c("div", {
    staticClass: "absolute-after",
    style: {
      background: _vm.colorObj.backgroudColor,
      borderColor: _vm.colorObj.borderColor
    }
  })], 2) : _vm.type === "last" ? _c("div", {
    staticClass: "last-tag father-dom",
    style: {
      background: _vm.colorObj.backgroudColor,
      borderColor: _vm.colorObj.borderColor,
      color: _vm.colorObj.fontColor
    }
  }, [_c("div", {
    staticClass: "absolute-before",
    style: {
      borderColor: _vm.colorObj.borderColor
    }
  }), _vm._t("default", function () {
    return [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.label,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "tag-label"
    }, [_vm._v(_vm._s(_vm.label))])])];
  })], 2) : _c("div", {
    staticClass: "center-tag father-dom",
    style: {
      background: _vm.colorObj.backgroudColor,
      borderColor: _vm.colorObj.borderColor,
      color: _vm.colorObj.fontColor
    }
  }, [_c("div", {
    staticClass: "absolute-before",
    style: {
      borderColor: _vm.colorObj.borderColor
    }
  }), _vm._t("default", function () {
    return [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.label,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "tag-label"
    }, [_vm._v(_vm._s(_vm.label))])])];
  }), _c("div", {
    staticClass: "absolute-after",
    style: {
      background: _vm.colorObj.backgroudColor,
      borderColor: _vm.colorObj.borderColor
    }
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;