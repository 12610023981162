"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _search = require("@/api/search");
var _default = {
  name: '',
  components: {},
  data() {
    return {
      options: [],
      value: '',
      list: [],
      loading: false,
      query: ''
    };
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.query = query;
        const res = await (0, _search.globalSearch)({
          phrase: query
        });
        if (res.code === 200) {
          const list = [];
          Object.keys(res.results).forEach(key => {
            const options = res.results[key].map(item => {
              item.type = item.type || key;
              return item;
            });
            if (options.length !== 0) {
              list.push({
                options
              });
            }
          });
          const footerOptions = [{
            value: -1,
            label: `在会话文本中搜索${query}`,
            type: 'text',
            highlight_name: `在会话文本中搜索<em>${query}</em>`
          }];
          list.push({
            options: footerOptions
          });
          this.options = list;
        }
        this.loading = false;
      } else {
        this.options = [];
      }
    },
    getIconClass(type) {
      let iconString = '';
      switch (type) {
        case 'deal':
          iconString = 'icon-deal4';
          break;
        case 'audio':
          iconString = 'icon-phone-outline';
          break;
        case 'video':
          iconString = 'icon-video-camera';
          break;
        case 'text':
          iconString = 'icon-document';
          break;
        case 'event':
          iconString = 'icon-key-event';
          break;
        default:
          iconString = 'icon-document';
      }
      return iconString;
    },
    handleOptionClick(_ref) {
      let {
        type,
        id,
        path
      } = _ref;
      if (type === 'deal') {
        this.goBusinessDetail(id);
      } else if (type === 'text') {
        this.goConversation({
          searchText: this.query
        });
      } else if (type === 'event') {
        this.goConversation({
          path
        });
      } else {
        this.goConversationDetails(id);
      }
      this.value = '';
      this.options = [];
    },
    goConversation(params) {
      if (this.$route.name !== 'ConversationIndex') {
        this.$router.push({
          name: 'ConversationIndex',
          params
        });
      } else {
        // 参考vue-element-admin，通过重定向原地刷新页面
        this.$router.replace({
          path: _routeMap.ROUTES.conversation,
          query: {
            redirect: 'true'
          }
        });
        this.$router.replace({
          name: 'ConversationIndex',
          params
        });
      }
    },
    goConversationDetails(id) {
      this.$router.push({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id
        }
      });
    },
    goBusinessDetail(id) {
      this.$router.push({
        path: _routeMap.ROUTES.tradeDetail,
        query: {
          id
        }
      });
    }
  }
};
exports.default = _default;