"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bubble-line"
  }, [_c("div", {
    staticClass: "bubble-line-scroll",
    style: {
      transform: `translateX(${_vm.scrollTrans}px)`,
      transition: ".6s"
    }
  }, [_vm._l(_vm.speech, function (item, index) {
    return [_c("speech-bubble", _vm._b({
      key: index,
      class: {
        "selected-bubble": item.date == _vm.activeConvDate && _vm.selected
      },
      attrs: {
        translate: _vm.distance(_vm.$plus8ToCurrentZone(item.date).format("YYYY-MM-DD")),
        type: item.type,
        "dataset-date": _vm.$plus8ToCurrentZone(item.date).format("YYYY-MM-DD HH:mm")
      },
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.bubbleClick(item.date);
        }
      }
    }, "speech-bubble", _vm.judgeSize(item), false))];
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;