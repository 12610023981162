"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "conv-container"
  }, [_c("div", {
    staticClass: "page-subtitle"
  }, [_vm._v("会话")]), _c("conv-tab")], 1), _c("div", {
    staticClass: "comment-container"
  }, [_c("div", {
    staticClass: "page-subtitle"
  }, [_vm._v("活动")]), _c("comment-tab")], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;