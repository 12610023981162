"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useImportAnnotation = useImportAnnotation;
var _vue = require("vue");
var _useMessage = require("@/hooks/use-message");
var _elementUi = require("element-ui");
var _useVuex = require("@/hooks/use-vuex");
function useImportAnnotation(_ref) {
  let {
    refreshDataFun = null
  } = _ref;
  const store = (0, _useVuex.useStore)();
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const conversationDrawerVisible = (0, _vue.ref)(false);
  const conversationList = (0, _vue.ref)([]);
  const conversationListChange = val => {
    conversationList.value = val;
  };
  const closeConversationDrawer = () => {
    conversationDrawerVisible.value = false;
  };
  const openConversationDrawer = () => {
    conversationDrawerVisible.value = true;
  };
  const manualImportDataSetDialogVisible = (0, _vue.ref)(false);
  const openManualImportDialog = () => {
    var _conversationList$val;
    if (!conversationList.value.length || ((_conversationList$val = conversationList.value[0]) === null || _conversationList$val === void 0 ? void 0 : _conversationList$val.sentences.length) === 0) {
      _useMessage.useMessage.warning('请搜索会话文本或关键事件');
      return;
    } else {
      manualImportDataSetDialogVisible.value = true;
    }
  };
  const showLoadingDataTips = (0, _vue.ref)(false);
  const expandTrainDataSetSuccess = () => {
    showLoadingDataTips.value = true;
    if (conversationDrawerVisible.value) {
      conversationDrawerVisible.value = false;
    }
  };
  const successMessage = (0, _vue.ref)(null);
  const expandDataSetSuccessCb = () => {
    refreshDataFun && refreshDataFun();
    successMessage.value && successMessage.value.close();
  };
  (0, _vue.watch)(() => store.getters.webSocketMsg, val => {
    console.log(val, 'val');
    if (val.sub_type === 13) {
      showLoadingDataTips.value = false;
      const h = proxy.$createElement;
      successMessage.value = (0, _elementUi.Message)({
        message: h('p', null, [h('span', null, '导入成功，'), h('span', {
          class: 'refresh-text',
          on: {
            click: expandDataSetSuccessCb
          }
        }, '刷新查看')]),
        showClose: true,
        duration: 0,
        type: 'success'
      });
    }
  });
  return {
    conversationList,
    conversationDrawerVisible,
    manualImportDataSetDialogVisible,
    openConversationDrawer,
    closeConversationDrawer,
    openManualImportDialog,
    conversationListChange,
    expandTrainDataSetSuccess,
    showLoadingDataTips
  };
}