"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "folder-select"
  }, [_c("el-select", {
    ref: "folderSelectRef",
    attrs: {
      remote: "",
      "remote-method": _setup.getFolderListApi,
      filterable: "",
      clearable: "",
      "popper-class": "folder-select-popper",
      placeholder: "请搜索文件夹"
    },
    on: {
      change: _setup.handleFolderChange,
      "visible-change": _setup.handleVisibleChange
    },
    model: {
      value: _setup.folder_id,
      callback: function ($$v) {
        _setup.folder_id = $$v;
      },
      expression: "folder_id"
    }
  }, _vm._l(_setup.folderList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_c("div", {
      staticClass: "folder-option-item",
      class: {
        selected: item.id === _setup.folder_id
      }
    }, [_c("i", {
      staticClass: "iconfont icon-folder-fill"
    }), _c("span", [_vm._v(_vm._s(item.name))])])]);
  }), 1), _setup.createFolderDialogVisible ? _c(_setup.CreateFolderDialog, {
    attrs: {
      visible: _setup.createFolderDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createFolderDialogVisible = $event;
      },
      createDataSetFolderSuccess: _setup.createDataSetFolderSuccess
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;