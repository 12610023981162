"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog create-instruction-dialog",
    attrs: {
      visible: _vm.visible,
      title: _setup.title,
      "modal-append-to-body": false,
      width: "1000px",
      "custom-class": "create-extract-info-instruction-dialog"
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _setup.instructionForm,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "指令名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入名称，上限20字",
      maxlength: "20"
    },
    model: {
      value: _setup.instructionForm.name,
      callback: function ($$v) {
        _vm.$set(_setup.instructionForm, "name", $$v);
      },
      expression: "instructionForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "生效范围",
      prop: "tree_ids"
    }
  }, [_c("MgvDepartmentMultiSelect", {
    ref: "mgvDepartmentMultiSelectRef",
    attrs: {
      "request-method": _setup.getOrgTree,
      "show-tag-count": 10,
      placeholder: "请选择部门",
      "backfill-list": _setup.instructionForm.tree_ids,
      "request-params": {
        type: JSON.stringify(["conversation"]),
        workspace_id: _setup.workspaceInfo.id
      }
    },
    on: {
      change: _setup.handleDepartmentValueChange
    }
  })], 1), _c("div", {
    staticClass: "form-item-label"
  }, [_c("span", {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("指令内容"), _c("el-tooltip", {
    attrs: {
      disabled: !_vm.visible,
      content: "答案描述和枚举值选填，填写后返回结果会更精准。如客户自述年龄32岁，问题名称为“客户的年龄是多少”，答案描述为“年龄范围”，枚举值为“30岁以上，30岁以下”，AI会返回“30以上”的结果",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c(_setup.KeyValueFieldItems, {
    ref: "keyValueFieldItemsRef",
    staticClass: "key-value-field-items",
    attrs: {
      desc: _setup.formContent,
      "add-disabled": _setup.formContent.length >= 20,
      "field-label": "问题",
      "at-least-one-item": ""
    },
    on: {
      "update:desc": function ($event) {
        _setup.formContent = $event;
      },
      "add-field": function ($event) {
        return _setup.addField(_setup.formContent);
      },
      "remove-field": function ($event) {
        return _setup.removeField($event, _setup.formContent);
      }
    }
  })], 1)], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.loading
    },
    on: {
      click: _setup.saveExtractInfoInstruction
    }
  }, [_vm._v(_vm._s(_vm.type === "create" ? "创建" : "保存"))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;