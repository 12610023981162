"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PicDisplay = _interopRequireDefault(require("@/components/CommentInput/components/PicDisplay.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DialogueItemNarration',
  props: {
    imgs: {
      type: Array,
      default: () => []
    },
    imageAppendToBody: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      PicDisplay: _PicDisplay.default
    };
  }
};
exports.default = _default;