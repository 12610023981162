"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router");
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyEventMutualExclusionHeader',
  emits: ['createMutualExclusionRule'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const router = (0, _useRouter.useRouter)();
    const goBack = () => {
      // 返回上一页
      router.push({
        path: _routeMap.ROUTES.keyEventManagement
      });
    };
    const createMutualExclusion = () => {
      emit('createMutualExclusionRule');
    };
    return {
      __sfc: true,
      router,
      goBack,
      emit,
      createMutualExclusion,
      SpaceButton: _SpaceButton.default
    };
  }
};
exports.default = _default;