"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-conv-wrapper"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 会话"), _c("span", {
    staticClass: "count"
  }, [_vm._v("共" + _vm._s(_vm.total) + "个")])]), _c("div", {
    staticClass: "filter"
  }, [_c("el-tooltip", {
    staticClass: "date-tooltip",
    attrs: {
      effect: "dark",
      content: "会话时间",
      placement: "top"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      align: "right",
      "unlink-panels": "",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "picker-options": _vm.datePickerOptions
    },
    on: {
      change: _vm.filtersChange
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("score-select", {
    attrs: {
      placeholder: "会话得分",
      range: _vm.scoreRange,
      maxlength: "1"
    },
    on: {
      scoreChange: _vm.handleScoreChange
    }
  })], 1), _c("knowledge-text-filter", {
    on: {
      textFilterChange: _vm.handleTextFilterChange
    }
  }), _c("deal-custom-field-filter", {
    on: {
      customFilterChange: _vm.customFilterChange
    }
  }), _c("div", {
    staticClass: "sort-row"
  }, [_c("sort-caret", {
    ref: "score_sort",
    attrs: {
      label: "得分"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("score", $event);
      }
    }
  }), _c("sort-caret", {
    ref: "time_sort",
    attrs: {
      label: "时间"
    },
    on: {
      sortCallBack: function ($event) {
        return _vm.sortData("time", $event);
      }
    }
  })], 1), _vm.knowledgeConvList && _vm.knowledgeConvList.length ? _c("mg-infinite-scroll", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-conv-content",
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.knowledgeConvList, function (item, index) {
    return _c("knowledge-conv-card", {
      key: index,
      attrs: {
        "conv-item": item,
        "active-knowledge": _vm.activeKnowledge,
        "is-expand": _vm.isExpand
      }
    });
  }), 1) : _c("div", {
    staticClass: "no-knowledge-conv-content"
  }, [_vm._v("暂无数据")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;