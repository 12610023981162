import { render, staticRenderFns } from "./MgDate.vue?vue&type=template&id=64ba0c6c&scoped=true&"
import script from "./MgDate.vue?vue&type=script&lang=js&"
export * from "./MgDate.vue?vue&type=script&lang=js&"
import style0 from "./MgDate.vue?vue&type=style&index=0&id=64ba0c6c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ba0c6c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64ba0c6c')) {
      api.createRecord('64ba0c6c', component.options)
    } else {
      api.reload('64ba0c6c', component.options)
    }
    module.hot.accept("./MgDate.vue?vue&type=template&id=64ba0c6c&scoped=true&", function () {
      api.rerender('64ba0c6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mgv-ui/packages/dynamic-form/src/components/MgDate.vue"
export default component.exports