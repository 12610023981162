"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timezone = require("@/utils/timezone");
var _vue = require("vue");
var _videoDetail = require("@/api/videoDetail");
var _SingleSentence = _interopRequireDefault(require("./SingleSentence.vue"));
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CorrectFlipRoleHistory',
  props: {
    conversationId: {
      type: Number,
      default: 0
    },
    mediaType: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const hasMore = (0, _vue.ref)(false);
    const loading = (0, _vue.ref)(false);
    const requestParams = (0, _vue.ref)({
      conversation_id: 0,
      page: 1,
      size: 10
    });
    const correctFlipRoleHistoryList = (0, _vue.ref)([]);
    const locationSentence = item => {
      console.log(item, 'item');
      store.dispatch('video/set_cur_order', {
        order: item.order,
        time: new Date().getTime(),
        beginTime: item.begin_time
      }); // 跳转到指定order；
    };

    const getCorrectFlipRoleHistoryApi = async () => {
      requestParams.value.conversation_id = props.conversationId;
      loading.value = true;
      const res = await (0, _videoDetail.getCorrectFlipRoleHistory)(requestParams.value).finally(() => loading.value = false);
      if (res.code === 20000) {
        correctFlipRoleHistoryList.value = [...correctFlipRoleHistoryList.value, ...res.results.data];
        hasMore.value = requestParams.value.size === res.results.data.length;
        requestParams.value.page++;
      }
    };
    return {
      __sfc: true,
      props,
      store,
      hasMore,
      loading,
      requestParams,
      correctFlipRoleHistoryList,
      locationSentence,
      getCorrectFlipRoleHistoryApi,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone,
      SingleSentence: _SingleSentence.default
    };
  }
};
exports.default = _default;