"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "mgv-tabs__nav-scroll"
  }, _vm._l(_vm.tabsList, function (item) {
    return _c("div", {
      key: item.id,
      class: ["mgv-tabs__item", {
        "mgv-tabs__item-active": _vm.activeTabs.id === item.id
      }],
      on: {
        click: function ($event) {
          return _setup.tabClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;