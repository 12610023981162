"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _MembersTreeSelect = _interopRequireDefault(require("@/components/MgTreeSelect/MembersTreeSelect"));
var _dealFilter = _interopRequireDefault(require("./dealFilter"));
var _DealkeyEventFilter = _interopRequireDefault(require("./DealkeyEventFilter"));
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const defaultFilter = [{
  field_name: 'user_ids',
  name: '成员',
  is_show_kanban: false
}, {
  field_name: 'department_tree_ids',
  name: '部门',
  is_show_kanban: true
}, {
  field_name: 'deal_name',
  name: '商机名称',
  is_show_kanban: true
}, {
  field_name: 'account_name',
  name: '客户名称',
  is_show_kanban: true
}, {
  field_name: 'contact_tel',
  name: '联系人手机号',
  is_show_kanban: true
}, {
  field_name: 'keyEventCascaderList',
  name: '关键事件',
  is_show_kanban: true
}];
var _default = {
  name: 'AllFilterItems',
  components: {
    MembersTreeSelect: _MembersTreeSelect.default,
    DealCustomFilter: _dealFilter.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    BatchSearchInput: _BatchSearchInput.default
  },
  props: {
    customFilterObject: {
      type: Object,
      default: () => ({})
    },
    kanban: {
      type: Object,
      default: null
    },
    defaultFilterObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      filters: [],
      // 部门
      isPreventMemberClear: false,
      // 关键事件
      keyEvent: {
        keyEventCascaderList: [{
          keyEventName: [],
          inverse: false
        }],
        event_engine_relation: 'and'
      },
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      userBackfillData: [],
      departmentBackfillData: [],
      backFillData: null // 回填数据
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    // 处理select的下拉选项
    handleOptions() {
      const options = JSON.parse(JSON.stringify({
        默认字段: defaultFilter,
        ...this.customFilterObject
      }));
      for (const option in options) {
        for (let i = 0; i < options[option].length; i++) {
          options[option][i].is_show_kanban = true;
          this.filters.map(item => {
            if (item.type === options[option][i].field_name) {
              options[option][i].is_show_kanban = false;
            }
          });
        }
      }
      return options;
    },
    isAddButtonVisible() {
      const allFilters = Object.values(this.handleOptions).flat();
      return this.filters.length < allFilters.length;
    },
    isDeleteButtonVisible() {
      return this.filters.length > 0;
    },
    filterValues() {
      return (0, _commonFunc.deepClone)(this.filters.map(_ref => {
        let {
          params
        } = _ref;
        return params;
      }).filter(item => item));
    }
  },
  watch: {
    'kanban.id': {
      handler: function () {
        this.initEditKanban(this.kanban.filter_config, true);
      }
    },
    customFilterObject: {
      handler() {
        this.initEditKanban(this.kanban.filter_config, true);
      },
      immediate: true
    },
    keyEvent: {
      handler: function (val) {
        if (val) {
          var _val$keyEventCascader;
          var keyEventCount = (_val$keyEventCascader = val.keyEventCascaderList) === null || _val$keyEventCascader === void 0 ? void 0 : _val$keyEventCascader.filter(item => item.keyEventName.length).length;
          if (keyEventCount) {
            const typeItem = this.filters.find(item => item.type === 'keyEventCascaderList');
            if (typeItem) {
              typeItem.params = val;
            } else {
              this.handleAddSelect('keyEventCascaderList');
              this.handleChangeSelect(this.filters.length - 1, 'keyEventCascaderList');
              this.setFilterParams('keyEventCascaderList', val);
            }
          } else {
            this.filters.find(item => {
              if (item.type === 'keyEventCascaderList') {
                item.params = null;
              }
            });
          }
          this.$emit('keyeventSelectChange', val);
        }
      },
      deep: true
    },
    filterValues: {
      handler: function (val, oldval) {
        if (val && JSON.stringify(val) !== JSON.stringify(oldval)) {
          this.$nextTick(() => {
            this.$emit('filterChange', this.filters);
          });
        }
      },
      deep: true,
      immediate: true
    },
    defaultFilterObj: {
      handler: function (val) {
        if (val) {
          const default_filter = ['deal_name', 'account_name', 'contact_tel'];
          default_filter.forEach(item => {
            const filter = val[item];
            this.handleValueBackfill(item, filter, val);
            this.$nextTick(() => {
              if (!filter) {
                this.handleDeleteSelect(item);
              }
            });
          });
        }
      },
      deep: true
    }
  },
  methods: {
    getOrgTree: _organization.getOrgTree,
    // 搜索客户/商机推荐项
    async getFuzzySearchList(filter, cb, type) {
      const res = await (0, _business.getFuzzySearchList)({
        filter,
        type,
        workspace_id: this.workspaceInfo.id
      });
      cb(res.results.map(value => {
        return {
          value
        };
      }));
    },
    handleClear(type) {
      this.$refs[type].activated = true;
    },
    matchCustomFilterItem(type) {
      const allFilter = Object.values(this.handleOptions).flat();
      return allFilter.find(item => item.field_name === type);
    },
    customFilterChange(val) {
      const key = Object.keys(val)[0];
      const value = Object.values(val)[0];
      this.setFilterParams(key, value);
    },
    isFilterValueEmpty(filter, type, filterObj) {
      let keyeventEmpty = false;
      if (type === 'keyEventCascaderList' && filter) {
        keyeventEmpty = filter.length === 1 && !filter[0].keyEventName.length;
      }
      return !filter || Array.isArray(filter) && !filter.length || keyeventEmpty;
    },
    initEditKanban(filterObj) {
      let needReset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.reset();
      // 对看板名字赋值
      const allFilters = Object.values(this.handleOptions).flat();
      if (!filterObj) return;
      allFilters.forEach((item, index) => {
        const filter = filterObj[item.field_name];
        this.$emit('departmentSelectChange', []); // 先清空部门
        this.$emit('keyeventSelectChange', this.$options.data().keyEvent); // 清空关键事件
        if (this.isFilterValueEmpty(filter, item.field_name, filterObj)) return;
        this.handleValueBackfill(item.field_name, filter, filterObj);
      });
    },
    handleValueBackfill(type, filter, filterObj) {
      this.handleAddSelect(type);
      this.handleChangeSelect(this.filters.length - 1, type);
      // 对部门进行单独处理
      if (type === 'department_tree_ids') {
        // this.isPreventMemberClear = true;
        this.$nextTick(() => {
          filter = Array.isArray(filter) ? filter : [filter];
          this.departmentBackfillData = filter;
        });
      }
      // 对成员进行单独处理
      if (type === 'user_ids' && Array.isArray(filter)) {
        var _filter;
        // 回填成员数据
        (_filter = filter) === null || _filter === void 0 ? void 0 : _filter.forEach(item => {
          const {
            is_department,
            parent_department
          } = item;
          item.isDepartment = is_department;
          item.preNode = parent_department;
        });
        this.userBackfillData = (0, _commonFunc.deepClone)(filter);
      }
      // 对关键事件进行单独处理
      if (type === 'keyEventCascaderList') {
        this.keyEvent.keyEventCascaderList = filter;
        this.keyEvent.event_engine_relation = filterObj.event_engine_relation;
      }
      this.setFilterParams(type, filter);
    },
    // 校验规则清空
    reset() {
      this.filters = [];
      this.departmentBackfillData = [];
      this.userBackfillData = [];
      this.keyEvent = this.$options.data().keyEvent;
    },
    async createKanban(data) {
      const res = await (0, _business.createDealFilterConfig)(data);
      if (res.code === 200) {
        this.$emit('createKanban');
      }
    },
    async updateKanban(data) {
      const res = await (0, _business.updateDealFilterConfig)(data);
      if (res.code === 200) {
        this.$emit('updateKanban');
      }
    },
    handleChangeSelect(index, type) {
      const typeItem = this.filters.find(item => item.type === type);
      if (typeItem) return;
      // 设置filters的type和label
      this.filters[index].type = type;
      const allFilters = Object.values(this.handleOptions).flat();
      const filter = allFilters.find(item => item.field_name === type);
      this.filters[index].label = filter.name;
      this.filters[index].params = null;
    },
    // 添加select的方法
    handleAddSelect(type) {
      const typeItem = this.filters.find(item => item.type === type);
      if (typeItem) return;
      const id = Math.random();
      this.isNoNeedEmit = true;
      this.filters.push({
        id,
        type: '',
        label: '',
        params: null,
        typeError: false,
        valueError: false
      });
    },
    handleDeleteSelect(type) {
      const index = this.filters.findIndex(filter => filter.type === type);
      if (index !== -1) {
        this.filters.splice(index, 1);
      }
      if (type === 'keyEventCascaderList') {
        this.keyEvent = this.$options.data().keyEvent;
      }
      if (type === 'department_tree_ids') {
        this.departmentBackfillData = [];
      }
      if (type === 'user_ids') {
        this.userBackfillData = [];
      }
    },
    setFilterParams(type, value) {
      const filterIndex = this.filters.findIndex(filter => filter.type === type);
      if (filterIndex !== -1) {
        this.filters[filterIndex].params = value;
      }
    },
    handleUserValueChange(value, array) {
      this.setFilterParams('user_ids', value);
    },
    handleDepartmentValueChange(type, value) {
      this.setFilterParams(type, value);
    },
    setDepartmentBackfillValue(val) {
      this.isNoNeedEmit = true;
      if (this.isFilterValueEmpty(val, 'department_tree_ids')) {
        this.handleDeleteSelect('department_tree_ids');
      } else {
        this.handleValueBackfill('department_tree_ids', val);
      }
    },
    setKeyeventBackfillValue(val) {
      this.isNoNeedEmit = true;
      if (this.isFilterValueEmpty(val.keyEventCascaderList, 'keyEventCascaderList', val)) {
        this.handleDeleteSelect('keyEventCascaderList');
      } else {
        this.handleValueBackfill('keyEventCascaderList', val.keyEventCascaderList, val);
      }
    }
  }
};
exports.default = _default;