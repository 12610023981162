"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateContactDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'createContactSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const prop = __props;
    const {
      proxy
    } = (0, _vue.getCurrentInstance)();
    const store = (0, _useVuex.useStore)();
    const handleClose = () => {
      emit('update:visible', false);
    };
    const contactFormDesc = (0, _vue.ref)([]);
    const contactFormData = (0, _vue.ref)({
      customer_name: ''
    });
    const customerList = (0, _vue.ref)([]);

    // 联系人表格格式化
    const descContactFormat = formDesc => {
      const desc = [];
      for (const item of formDesc) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          tips: item.tips
        };
        desc.push(obj);
      }
      return desc;
    };
    const getContactDynamicFormApi = async () => {
      const res = await (0, _client.getContactDynamicForm)({
        type: 'contact',
        has_account: 1
      });
      if (res.code === 200) {
        contactFormDesc.value = descContactFormat(res.results);
      }
    };
    (0, _vue.onMounted)(() => {
      getContactDynamicFormApi();
    });
    const searchAccountApi = async val => {
      const res = await (0, _client.getClientList)({
        search_val: val,
        page_at: 1,
        page_size: 30,
        only_my_accounts: 0,
        in_deal_list: 1
      });
      if (res.code === 200) {
        customerList.value = res.results.items;
      }
    };
    const createContactFormRef = (0, _vue.ref)(null);
    const handleCreateContact = async () => {
      if (createContactFormRef.value.validate()) {
        const res = await (0, _client.postCreateContact)({
          contact: contactFormData.value,
          account_id: contactFormData.value.customer_name
        });
        if (res.code === 200) {
          proxy.$message.success('创建联系人成功');
          emit('createContactSuccess', res.results);
          handleClose();
        }
      }
    };
    return {
      __sfc: true,
      proxy,
      prop,
      store,
      emit,
      handleClose,
      contactFormDesc,
      contactFormData,
      customerList,
      descContactFormat,
      getContactDynamicFormApi,
      searchAccountApi,
      createContactFormRef,
      handleCreateContact,
      DynamicForm: _DynamicForm.default
    };
  }
};
exports.default = _default;