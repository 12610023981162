import { render, staticRenderFns } from "./GroupImportButton.vue?vue&type=template&id=266e13cf&scoped=true&"
import script from "./GroupImportButton.vue?vue&type=script&setup=true&lang=js&"
export * from "./GroupImportButton.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./GroupImportButton.vue?vue&type=style&index=0&id=266e13cf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266e13cf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('266e13cf')) {
      api.createRecord('266e13cf', component.options)
    } else {
      api.reload('266e13cf', component.options)
    }
    module.hot.accept("./GroupImportButton.vue?vue&type=template&id=266e13cf&scoped=true&", function () {
      api.rerender('266e13cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coach/course/components/GroupImportButton.vue"
export default component.exports