"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "open-number-desensitization"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("el-switch", {
    attrs: {
      value: _vm.openNumberDesensitization,
      "active-color": "#4461EC",
      "inactive-color": "#C4CBD4 "
    },
    on: {
      change: _vm.handleChange
    }
  }), _c("span", [_vm._v(_vm._s(_vm.openNumberDesensitization ? "已开启手机号隐私保护" : "未开启手机号隐私保护") + " ")])], 1), _vm._m(0)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "content"
  }, [_c("p", [_vm._v(" 1. 手机号隐私保护开启后，查看手机号字段类型内容时（线索手机号，客户联系人手机号，交易联系人手机号），号码将为脱敏显示（例如：188****8888）。 ")]), _c("p", [_vm._v(" 2. 若该员工是线索/客户/交易的负责人，则可查看手机号字段类型的全量号码。 ")]), _c("p", [_vm._v("3. 手机号隐私保护开启后，将会应用于以下选中的用户角色。")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;