"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _knowledgeLibrary = require("@/api/knowledgeLibrary.js");
var _default = {
  name: 'DeleteConfirmationDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentKnowledgeBase: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      confirmVal: '',
      isValidate: false,
      loading: false
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
      this.isValidate = false;
      this.confirmVal = '';
    },
    async submit() {
      this.isValidate = true;
      if (this.confirmVal.trim() === '确认删除') {
        this.loading = true;
        const res = await (0, _knowledgeLibrary.deleteKnowledgeBase)({
          id: this.currentKnowledgeBase.id
        }).finally(() => {
          this.loading = false;
        });
        if (res.code === 20000) {
          this.$message.success('删除成功');
          this.handleClose();
          this.$emit('delete');
        }
      }
    }
  }
};
exports.default = _default;