"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timeFormatter = require("@/utils/time-formatter");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _index = require("@/utils/index.js");
var _default = {
  __name: 'UploadFileList',
  props: {
    fileList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['addFile', 'deleteFile'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const modifyMediaNameTourRef = (0, _vue.ref)(null);
    const modifyMediaNameStep = [{
      title: ' ',
      content: '会话名称改为点击文件修改啦！',
      target: '[data-v-step="upload-file-list-0"]' // 模块-功能-step-id
    }];

    (0, _vue.watch)(() => props.fileList, async val => {
      if (val && val.length) {
        await (0, _vue.nextTick)();
        modifyMediaNameTourRef.value.start();
      }
    }, {
      deep: true
    });
    const AUDIO_TYPE = ['audio/mpeg', 'audio/wav', 'audio/x-m4a', 'audio/aiff', 'audio/vnd.dlna.adts', 'audio/ogg', 'audio/x-ms-wma', 'audio/flac', 'audio/3gpp'];
    const addFile = () => {
      emit('addFile');
    };
    const formatDuration = () => {
      console.log('formatDuration', store.getters);
      if (store.getters.isUseOrgMaxDuration) {
        if (store.getters.orgMaxDuration === -1) return '无上限';
        return (0, _index.handleMinutes)(store.getters.orgMaxDuration - store.getters.orgUsedDuration);
      } else {
        return (0, _index.handleMinutes)(store.getters.maxDuration - store.getters.usedDuration);
      }
    };
    const inputRefs = [];
    const setInputRefs = el => {
      if (el) {
        inputRefs.push(el);
      }
    };
    // 编辑文件名字
    const editFileName = async (file, index) => {
      var _inputRefs$index;
      file.isEditFileName = true;
      await (0, _vue.nextTick)();
      (_inputRefs$index = inputRefs[index]) === null || _inputRefs$index === void 0 ? void 0 : _inputRefs$index.focus();
    };

    // 获取所有文件总时长
    const getFilesDuration = () => {
      let allFireDuration = 0;
      props.fileList.forEach(item => {
        allFireDuration += item.duration;
      });
      return allFireDuration;
    };

    // 禁用添加文件按钮
    const addFileBtnDisabled = () => {
      let residueDuration = 0;
      let res = false;
      if (store.getters.orgMaxDuration === -1) {
        res = false;
      } else {
        if (store.getters.isUseOrgMaxDuration) {
          residueDuration = (store.getters.orgMaxDuration - store.getters.orgUsedDuration) * 60;
        } else {
          residueDuration = (store.getters.maxDuration - store.getters.usedDuration) * 60;
        }
        res = residueDuration < getFilesDuration();
      }
      emit('canUpload', res);
      return res;
    };

    // 删除文件
    const handleDeleteFile = (file, index) => {
      inputRefs.splice(index, 1);
      emit('deleteFile', index);
    };
    (0, _vue.watchEffect)(() => {
      getFilesDuration();
      addFileBtnDisabled();
    });
    return {
      __sfc: true,
      store,
      props,
      modifyMediaNameTourRef,
      modifyMediaNameStep,
      AUDIO_TYPE,
      emit,
      addFile,
      formatDuration,
      inputRefs,
      setInputRefs,
      editFileName,
      getFilesDuration,
      addFileBtnDisabled,
      handleDeleteFile,
      secondToCNTimeOfFixedTwo: _timeFormatter.secondToCNTimeOfFixedTwo
    };
  }
};
exports.default = _default;