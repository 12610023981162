"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _default = {
  __name: 'DataSetFilter',
  emits: ['handleFilterChange', 'importData', 'createDataSet'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const IMPORT_STATUS_OPTION = [{
      id: 1,
      label: '未导入'
    }, {
      id: 2,
      label: '导入中'
    }, {
      id: 3,
      label: '已完成'
    }, {
      id: 4,
      label: '导入失败'
    }];
    const ANNOTATION_TYPE_OPTION = [{
      id: 1,
      label: '关键事件'
    }, {
      id: 2,
      label: '客户标签'
    }];
    const ANNOTATION_STATUS_OPTION = [{
      id: 1,
      label: '未标注'
    }, {
      id: 2,
      label: '标注中'
    }, {
      id: 3,
      label: '已完成'
    }];
    const filters = (0, _vue.ref)({
      dataset_name_or_id: '',
      dataset_folder_name: '',
      import_status: '',
      annotation_type: '',
      annotator: {},
      creator: {},
      annotation_status: '',
      create_at: [],
      update_at: []
    });
    const handleFilterChange = () => {
      emit('handleFilterChange', filters.value);
    };
    const handleTaggerChange = ids => {
      filters.value.annotator = ids;
      handleFilterChange();
    };
    const handleCreatorChange = ids => {
      filters.value.creator = ids;
      handleFilterChange();
    };
    const createDataSet = () => {
      emit('createDataSet');
    };
    const route = (0, _useRouter.useRoute)();
    (0, _vue.onMounted)(() => {
      if (route.query.dataset_id) {
        filters.value.dataset_name_or_id = route.query.dataset_id;
        handleFilterChange();
      }
    });
    return {
      __sfc: true,
      IMPORT_STATUS_OPTION,
      ANNOTATION_TYPE_OPTION,
      ANNOTATION_STATUS_OPTION,
      filters,
      emit,
      handleFilterChange,
      handleTaggerChange,
      handleCreatorChange,
      createDataSet,
      route,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList
    };
  }
};
exports.default = _default;