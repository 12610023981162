"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-input"
  }, [_c("el-input", {
    ref: "input",
    class: _vm.isInputExpand ? "expand" : "not-expand",
    attrs: {
      autosize: {
        minRows: 1,
        maxRows: 3
      },
      type: "textarea",
      placeholder: _vm.placeholder,
      maxlength: _vm.maxlength
    },
    on: {
      input: _vm.listenInput
    },
    nativeOn: {
      keydown: function ($event) {
        return _vm.handleKeyDown.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v;
      },
      expression: "inputVal"
    }
  }), _c("div", {
    staticClass: "send-btn",
    on: {
      click: _vm.send
    }
  }, [_c("i", {
    class: ["iconfont icon-sent-fill", _vm.hasContent && !_vm.disabled ? "active" : ""]
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;