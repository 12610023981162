"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "select",
    staticClass: "choose-folder-select",
    attrs: {
      value: _vm.value.name,
      disabled: _vm.disabled,
      "popper-class": "folder-select-poover",
      placeholder: "请选择文件夹"
    }
  }, [_c("el-option", {
    staticClass: "folder-select-option",
    attrs: {
      value: _vm.value.name
    }
  }, [_c("folder-tree", _vm._b({
    ref: "folder-tree",
    attrs: {
      "has-root": !_vm.isSnippet,
      "current-node": _vm.value,
      "filter-conv-flow": ""
    },
    on: {
      nodeClick: _vm.handleNodeClick
    }
  }, "folder-tree", _vm.$attrs, false))], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;