"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-task-detail"
  }, [_c("detail-header", {
    attrs: {
      title: _vm.taskName,
      "back-button": ""
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function () {
        return [_c("i", {
          staticClass: "iconfont icon-compliance-tasks-fill"
        })];
      },
      proxy: true
    }, {
      key: "suffix",
      fn: function () {
        return [_c("div", {
          staticClass: "switch-view-button"
        }, [_c("el-button", {
          class: {
            "is-active": _vm.viewName === "member"
          },
          attrs: {
            icon: "iconfont icon-user-dbl"
          },
          on: {
            click: function ($event) {
              return _vm.switchView("member");
            }
          }
        }, [_vm._v("成员视图")]), _c("el-button", {
          class: {
            "is-active": _vm.viewName === "conversation"
          },
          attrs: {
            icon: "iconfont icon-video-camera"
          },
          on: {
            click: function ($event) {
              return _vm.switchView("conversation");
            }
          }
        }, [_vm._v("会话视图")])], 1)];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_vm.viewName === "conversation" && _vm.interfacePermission.getRemovedRecord ? _c("el-button", {
          staticClass: "hover-show-bg",
          attrs: {
            icon: "iconfont icon-eliminated-already"
          },
          on: {
            click: _vm.openRemovedRecordDrawer
          }
        }, [_vm._v("已移除记录")]) : _vm._e()];
      },
      proxy: true
    }])
  }), _c("div", {
    staticClass: "main-content"
  }, [_c("TeamTaskDetailContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.viewName === "member",
      expression: "viewName === 'member'"
    }],
    ref: "MemberViewContent",
    attrs: {
      "table-data": _vm.tableDataList[0],
      filters: _vm.filtersObj[0],
      total: _vm.total[0],
      loading: _vm.loading,
      "view-name": _vm.viewName
    },
    on: {
      goCompliancePersonal: _vm.goCompliancePersonal,
      handleFiltersChange: _vm.handleFiltersChange,
      sizeChange: _vm.sizeChange,
      currentChange: _vm.currentChange,
      "sort-change": _vm.sortChange
    }
  }), _c("TeamTaskDetailContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.viewName === "conversation",
      expression: "viewName === 'conversation'"
    }],
    ref: "ConvViewContent",
    staticClass: "conv-view-content",
    attrs: {
      "table-data": _vm.tableDataList[1],
      filters: _vm.filtersObj[1],
      total: _vm.total[1],
      loading: _vm.loading,
      "view-name": _vm.viewName,
      "default-sort": _vm.conversationViewSortConfig.defaultSort,
      sortable: _vm.conversationViewSortConfig.sortable,
      "score-rule-list": _vm.scoreRuleList,
      "evaluation-item-list": _vm.evaluationItemList,
      "table-header": _vm.convTableHeader,
      "is-conv-table": ""
    },
    on: {
      goComplianceWorkSpace: _vm.goComplianceWorkSpace,
      handleFiltersChange: _vm.handleFiltersChange,
      sizeChange: _vm.sizeChange,
      currentChange: _vm.currentChange,
      "sort-change": _vm.sortChange
    }
  })], 1), _c("removed-record-drawer", {
    attrs: {
      visible: _vm.removedRecordDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.removedRecordDrawerVisible = $event;
      },
      refresh: _vm.handleFiltersChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;