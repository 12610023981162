"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'SentenceImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    imageList: {
      type: Array,
      default: () => []
    },
    imageAppendToBody: {
      type: Boolean,
      default: false
    },
    imageIndex: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: 200
    }
  },
  setup(__props) {
    const props = __props;
    (0, _vue.useCssVars)((_vm, _setup) => ({
      "48d4241e-maxpx": _setup.maxpx
    }));
    const max = (0, _vue.ref)(200);
    const min = (0, _vue.computed)(() => max.value / 2);
    const maxpx = (0, _vue.computed)(() => `${max.value}px`);
    const imgRef = (0, _vue.ref)(null);
    const imgType = (0, _vue.ref)('');
    const imageLoaded = e => {
      console.log(e, imgRef.value, 'imageLoaded');
      if (e.target.width > e.target.height) {
        imgType.value = 'landscape';
      } else {
        imgType.value = 'portrait';
      }
    };
    return {
      __sfc: true,
      props,
      max,
      min,
      maxpx,
      imgRef,
      imgType,
      imageLoaded
    };
  }
};
exports.default = _default;