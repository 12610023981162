"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "event-table"
  }, [_c("el-table", {
    ref: "keyEventTableRef",
    attrs: {
      data: _vm.tableData
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55",
      selectable: _vm.selectable
    }
  }), _vm._l(_vm.tableHead, function (item) {
    return _c("el-table-column", {
      key: item.prop,
      attrs: {
        label: item.label,
        prop: item.prop,
        width: item.width || "",
        fit: "",
        "show-overflow-tooltip": !["data_scope", "model_id"].includes(item.prop)
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          var _vm$eventIds;
          return [item.prop === "name" ? _c("div", [_vm.eventIds && _vm.eventIds.length ? _c("div", {
            staticClass: "event-name-header"
          }, [_vm._v(" 已选"), _c("span", {
            staticClass: "count"
          }, [_vm._v(_vm._s((_vm$eventIds = _vm.eventIds) === null || _vm$eventIds === void 0 ? void 0 : _vm$eventIds.length))]), _vm._v("个关键事件 ")]) : _c("div", [_vm._v("关键事件")])]) : item.prop === "status" ? _c("div", {
            staticClass: "status-header"
          }, [_c("span", [_vm._v(_vm._s(item.label))]), _c("el-tooltip", {
            staticClass: "item",
            attrs: {
              content: "启用后，关键事件才开始应用到会话",
              placement: "top"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-info-1"
          })])], 1) : _c("div", [_vm._v(" " + _vm._s(item.label) + " ")])];
        },
        proxy: true
      }, item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "event-name"
          }, [_c("span", [_vm._v(_vm._s(row.name))]), row.event_cate === 2 ? _c("span", {
            staticClass: "ai-tag"
          }, [_vm._v("AI")]) : _vm._e()])];
        }
      } : item.prop === "model_id" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row.model_id ? [_c("div", {
            staticClass: "model-info"
          }, [_c("i", {
            staticClass: "iconfont icon-box-square"
          }), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              effect: "dark",
              placement: "top",
              content: row.model_name
            }
          }, [_c("span", {
            staticClass: "model-name"
          }, [_vm._v(_vm._s(row.model_name))])])], 1), _c("div", {
            staticClass: "version-tag"
          }, [_vm._v("V" + _vm._s(row.model_version))])] : row.event_type === "system" ? _c("div", [_vm._v("系统模型")]) : _c("div", [_vm._v("无模型")])];
        }
      } : item.prop === "status" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_vm.modulePermission.ModelManagement ? [_c("el-tooltip", {
            attrs: {
              disabled: !(row.event_type !== "system" && !row.model_id),
              effect: "dark",
              placement: "top",
              content: "配置模型后可启用"
            }
          }, [_c("div", {
            staticClass: "status-container"
          }, [_c("span", {
            staticClass: "event-status-label"
          }, [_vm._v(_vm._s(_vm.getStatus(row.status)))]), _c("el-switch", {
            attrs: {
              value: row.status,
              "active-color": "#4461EC",
              "inactive-color": "#E2E5E9",
              "active-value": 2,
              "inactive-value": 1,
              width: 34,
              disabled: !row.model_id
            },
            on: {
              change: function ($event) {
                return _vm.handleSwitchEventStatus(row);
              }
            }
          })], 1)])] : [_c("span", {
            staticClass: "event-status-cell",
            class: {
              "is-published": row.status === 2
            }
          }, [_vm._v(_vm._s(_vm.getStatus(row.status)))])]];
        }
      } : item.prop === "data_scope" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("KeyEventDataScope", {
            attrs: {
              scope: row.data_scope
            }
          })];
        }
      } : item.prop === "folder_path" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c("div", {
            staticClass: "folder-path-cell"
          }, [row.folder_path ? _c("i", {
            staticClass: "iconfont icon-folder"
          }) : _vm._e(), _c("span", {
            staticClass: "folder-path"
          }, [_vm._v(_vm._s(row.folder_path))])])];
        }
      } : item.prop === "update_by" ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_c("MgvAvatarName", {
            attrs: {
              name: row.update_by,
              size: 28,
              src: row.update_avatar
            }
          }), _c("span", {
            staticClass: "update-by-name"
          }, [_vm._v(_vm._s(row.update_by))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "230",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("div", {
          staticClass: "operate-cell"
        }, [_c("div", {
          staticClass: "operate-panel"
        }, [_c("MgOperateColumn", {
          attrs: {
            list: _vm.getOuterOperateList(row)
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand($event, row);
            }
          }
        })], 1)])];
      }
    }])
  })], 2), _c("ShareKeyEventDialog", {
    attrs: {
      visible: _vm.shareKeyEventDialogVisible,
      "event-id": _vm.currRow.id,
      "event-name": _vm.currRow.name
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareKeyEventDialogVisible = $event;
      },
      refreshTableData: _vm.refreshTableData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;