"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-speak-time-tab-content"
  }, [!_vm.eventListShow ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.speakTimeLoading,
      expression: "speakTimeLoading"
    }],
    staticClass: "deal-speak-time"
  }, [_vm.filterSpeakTimeList.length ? _c("div", [_c("el-timeline", {
    staticClass: "speak-time-timeline"
  }, _vm._l(_vm.filterSpeakTimeList, function (item, index) {
    return _c("el-timeline-item", {
      key: index,
      ref: "el-timeline-item",
      refInFor: true,
      attrs: {
        placement: "top",
        timestamp: _vm.handleItemDate(item)
      }
    }, [_c("span", {
      staticClass: "timeline-item-dot",
      attrs: {
        slot: "dot"
      },
      slot: "dot"
    }, [_vm._v(_vm._s(item.order || _vm.filterSpeakTimeList.length - index))]), _c("speak-time-card", {
      class: {
        "active-speak-time-card": _vm.handleActiveDate(item)
      },
      attrs: {
        item: item,
        "click-card": _vm.clickCard,
        "has-account-label-permission": _vm.hasAccountLabelPermission
      },
      on: {
        openCustomerLabelDetailList: _vm.openCustomerLabelDetailList,
        openKeyEventDetailList: _vm.openKeyEventDetailList,
        openEventsList: _vm.openEventsList,
        clickConvCard: _vm.clickConvCard
      }
    })], 1);
  }), 1)], 1) : _c("div", {
    staticClass: "no-speak-time"
  }, [_vm._v("暂无跟进动态")])]) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.eventListLoading,
      expression: "eventListLoading"
    }],
    staticClass: "event-key-drawer-content"
  }, [_c("div", {
    staticClass: "key-event-drawer-title"
  }, [_c("div", {
    staticClass: "go-back-btn",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  }), _c("span", [_vm._v("返回")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  })], 1), _c("span", {
    staticClass: "key-name"
  }, [_vm._v(_vm._s(_vm.drawerTitle))])]), !_vm.isKeyEventDetail ? _c("events-list", {
    attrs: {
      "event-engine": _vm.eventEngine
    },
    on: {
      tagClick: _vm.handleTagClick
    }
  }) : _c("key-event-detail-list", {
    ref: "keyEventDetailList",
    attrs: {
      "media-type": _vm.mediaType,
      list: _vm.activeEventData,
      "no-event-click": _vm.noEventClick,
      participants: _vm.participants
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;