"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _TranserDepartmentFinderDialog = _interopRequireDefault(require("@/components/TranserDepartmentFinderDialog"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OperateMemberDialog',
  components: {
    MembersFinder: _MembersFinder.default,
    ImageAvatar: _ImageAvatar.default,
    TranserDepartmentFinderDialog: _TranserDepartmentFinderDialog.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    memberInfo: [Object, Array],
    page: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    memberMultiArray: {
      type: Array,
      default: () => []
    },
    roleList: {
      type: Array,
      default: () => []
    },
    manageTree: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const scopeValidator = (rule, value, callback) => {
      if (!this.scopeList.length) {
        callback(new Error('请选择管理范围'));
      }
      callback();
    };
    return {
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      operateMemberForm: {
        user_ids: [],
        role_ids: [],
        batchUserIds: [],
        batchDeptIds: []
      },
      isDistributable: false,
      rules: {
        user_ids: [{
          required: true,
          message: '请选择成员',
          trigger: 'change'
        }],
        role_ids: [{
          required: true,
          message: '请选择管理员角色',
          trigger: 'change'
        }],
        manage_scope: [{
          validator: scopeValidator,
          trigger: 'change'
        }]
      },
      buttonLoading: false,
      adminRadio: 'superAdmin',
      permissionList: [],
      scopeList: [],
      hasManageScope: false,
      permissionLoading: false,
      superAdminId: '',
      departmentsFinderDialogVisible: false
    };
  },
  computed: {
    getDialogTitle() {
      const dialogTitle = {
        add: '添加',
        edit: '编辑',
        batchEdit: '批量修改业务角色'
      };
      if (this.page === '管理员') {
        return dialogTitle[this.type] + this.page;
      }
      return dialogTitle[this.type] + '成员';
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) return;
      if (JSON.stringify(this.memberInfo) != '{}') {
        this.operateMemberForm.role_ids = this.memberInfo.role_list.map(item => item.id);
        this.isDistributable = this.memberInfo.is_distributable;
      }
      switch (this.type) {
        case 'add':
          this.adminRadio = 'superAdmin';
          this.scopeList = this.manageTree;
          break;
        case 'edit':
          this.adminRadio = this.memberInfo.is_super_manager ? 'superAdmin' : 'admin';
          if (this.adminRadio === 'admin') {
            this.operateMemberForm.role_ids = this.operateMemberForm.role_ids.filter(item => item !== this.superAdminId);
            this.getAdminRolesPermissionList();
          }
          this.scopeList = this.memberInfo.scope;
          break;
        case 'batchEdit':
          this.$nextTick(() => {
            const res = this.memberMultiArray.map(item => {
              return {
                id: item.user_id,
                name: item.name
              };
            });
            this.operateMemberForm.user_ids = this.memberInfo;
            this.operateMemberForm.batchUserIds = this.memberInfo;
            this.$refs['memberFinder'].selection = this.memberInfo;
            this.$refs['memberFinder'].memberOptions = res;
          });
          break;
      }
    },
    adminRadio(val) {
      if (val === 'admin') {
        this.operateMemberForm.role_ids = this.operateMemberForm.role_ids.filter(item => item !== this.superAdminId);
        this.getAdminRolesPermissionList();
      }
    },
    roleList(val) {
      const superAdminInfo = val.find(item => item.is_super_role);
      if (superAdminInfo) {
        this.superAdminId = superAdminInfo.id;
      }
    }
  },
  methods: {
    openEditScopeDialog() {
      this.departmentsFinderDialogVisible = true;
    },
    submitManageScope(scope) {
      this.scopeList = scope;
      this.$refs['operateMemberForm'].validate('manage_scope');
    },
    async getAdminRolesPermissionList() {
      this.permissionLoading = true;
      try {
        const res = await (0, _permissions.getAdminRolesPermissionList)(JSON.stringify(this.operateMemberForm.role_ids));
        if (res.code === 200) {
          this.permissionList = res.results.permissions;
          this.hasManageScope = res.results.has_auth_scope;
        }
      } finally {
        this.permissionLoading = false;
      }
    },
    toCNTime(time) {
      const timeArr = time.split('-');
      return timeArr[0] + '年' + timeArr[1] + '月' + timeArr[2] + '日';
    },
    getDepartmentNames(departments) {
      return departments.map(item => item.super_tree_names.join('/')).join('<br/>');
    },
    handleMemberChange(val) {
      this.operateMemberForm.user_ids = val;
      if (this.page === '管理员') return;
      const userId_Multiple = [];
      const deptId_Multiple = [];
      val.forEach(item => {
        const id = String(item).split('_').pop() - 0;
        if (String(item).indexOf('tree_id') === -1) {
          userId_Multiple.push(id);
        } else {
          deptId_Multiple.push(id);
        }
      });
      this.operateMemberForm.batchUserIds = userId_Multiple;
      this.operateMemberForm.batchDeptIds = deptId_Multiple;
    },
    submitFormData() {
      this.$refs['operateMemberForm'].validate(async valid => {
        if (valid) {
          if (this.page === '管理员') {
            let data;
            if (this.type === 'add') {
              data = {
                user_ids: this.operateMemberForm.user_ids,
                role_ids: this.adminRadio === 'admin' ? this.operateMemberForm.role_ids : [this.superAdminId],
                is_distributable: this.isDistributable
              };
              if (this.adminRadio === 'admin' && this.hasManageScope) {
                data.tree_ids = this.scopeList.map(item => item.id);
              }
              this.buttonLoading = true;
              try {
                const res = await (0, _permissions.addAdminMembers)(data);
                if (res.code === 20000) {
                  setTimeout(() => {
                    this.$message({
                      message: '添加成功',
                      type: 'success'
                    });
                    this.buttonLoading = false;
                    this.$emit('fetchMemberData');
                    this.closeDialog();
                  }, 1000);
                } else {
                  this.buttonLoading = false;
                }
              } catch {
                this.buttonLoading = false;
              }
            } else {
              data = {
                user_id: this.memberInfo.user_id,
                role_ids: this.adminRadio === 'admin' ? this.operateMemberForm.role_ids : [this.superAdminId],
                is_distributable: this.isDistributable
              };
              if (this.adminRadio === 'admin' && this.hasManageScope) {
                data.tree_ids = this.scopeList.map(item => item.id);
              }
              const res = await (0, _permissions.editMemberRole)(data);
              if (res.code === 200) {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
              }
              this.$emit('fetchMemberData');
              this.closeDialog();
            }
          }
        }
      });
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.reset();
    },
    reset() {
      if (this.type !== 'edit') {
        this.$refs['memberFinder'].selection = [];
      }
      this.isDistributable = false;
      this.$refs['operateMemberForm'].resetFields();
      this.$nextTick(() => {
        this.$refs['operateMemberForm'].clearValidate();
      });
      this.operateMemberForm.role_ids = [];
      this.permissionList = [];
      this.hasManageScope = false;
    }
  }
};
exports.default = _default;