"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
var _default = {
  __name: 'DepartmentHeader',
  emits: ['addPerson', 'invitePerson', 'openBatchModifyInfo'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const ENV_TITLE = (0, _vue.computed)(() => {
      return process.env.VUE_APP_TITLE;
    });
    const router = (0, _useRouter.useRouter)();
    const addPerson = () => {
      emit('addPerson');
    };
    const invitePerson = () => {
      emit('invitePerson');
    };
    const openBatchModifyInfo = () => {
      emit('openBatchModifyInfo');
    };
    const goResignedMemberPage = () => {
      emit('goResignedMemberPage');
    };
    const importMembers = () => {
      router.push({
        path: _routeMap.ROUTES.ImportMembers
      });
    };
    const importDepartment = () => {
      router.push({
        path: _routeMap.ROUTES.ImportDepartment
      });
    };
    return {
      __sfc: true,
      ENV_TITLE,
      router,
      emit,
      addPerson,
      invitePerson,
      openBatchModifyInfo,
      goResignedMemberPage,
      importMembers,
      importDepartment,
      t: _i18n.t
    };
  }
};
exports.default = _default;