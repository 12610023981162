"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String]
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: '',
      min: '',
      max: ''
    };
  },
  computed: {
    range() {
      if (this.min === '' && this.max === '') {
        return [];
      }
      return [this.min === '' ? -1 : Number(this.min), this.max === '' ? -1 : Number(this.max)];
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (!val || !val.length) {
          this.localValue = '';
          this.min = '';
          this.max = '';
        } else {
          if (Array.isArray(val)) {
            const min = val[0];
            const max = val[1];
            if (min !== -1 && max !== -1) {
              this.min = min;
              this.max = max;
              this.localValue = `${min} - ${max}`;
            } else if (min !== -1 && max === -1) {
              this.min = min;
              if (min === 0) {
                this.localValue = '';
              } else {
                this.localValue = `大于等于 ${min}`;
              }
            } else if (max !== -1 && min === -1) {
              this.max = max;
              if (max === 0) {
                this.localValue = `等于 ${max}`;
              } else {
                this.localValue = `小于等于 ${max}`;
              }
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClear() {
      this.min = '';
      this.max = '';
      this.handleChange();
    },
    handleClearItem(type) {
      this[type] = '';
      this.handleChange();
    },
    handleChange() {
      this.formatText();
      if (this.item.field_name) {
        this.$emit('change', {
          [this.item.field_name]: this.range
        });
      } else {
        this.$emit('amountChange', this.range);
      }
    },
    handleBlur(type) {
      const reg = this.item.type === 'float' ? /^(0|([1-9]\d*))(\.\d+)?$/ : /^[\d]+$/g;
      if (!reg.test(this[type])) {
        this[type] = '';
      }
      if (this.item.type === 'float' && reg.test(this[type])) {
        this[type] = parseFloat(this[type]).toFixed(parseInt(this.item.precisions));
      }
      if (this.min !== '' && this.max !== '' && this.min > this.max) {
        const temp = this.min;
        this.min = this.max;
        this.max = temp;
      }
      this.handleChange();
    },
    formatText() {
      const min = Number(this.min);
      const max = Number(this.max);
      if (min && max) {
        this.localValue = `${min} - ${max}`;
      } else if (min && !max) {
        this.localValue = `大于等于 ${min}`;
      } else if (!min && max) {
        this.localValue = `小于等于 ${max}`;
      } else {
        this.localValue = '';
      }
    }
  }
};
exports.default = _default;