"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DynamicPopover',
  components: {
    MgDraggable: _MgDraggable.default
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    fetchTableHeadMethod: {
      type: Function,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null
    },
    resetHeadDataMethod: {
      type: Function,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null
    },
    incomingList: {
      // 商机列表页不需要在该组件内请求数据，是直接传进来的
      type: Array,
      default: () => []
    },
    scoreRuleId: {
      type: [String, Number],
      default: ''
    },
    scoreRuleName: {
      type: String,
      default: ''
    },
    popperClass: {
      type: String,
      default: ''
    },
    addCreateAt: {
      type: Boolean,
      default: false
    },
    hasSpecialColumn: {
      type: Boolean,
      default: true
    },
    minCheckCount: {
      type: Number,
      default: 2
    },
    resetRequestIsInner: {
      type: Boolean,
      default: true
    },
    params: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      rawList: [],
      checkList: [],
      list: [],
      search: '',
      saveLoading: false,
      scoreRuleInfoVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    incomingList: {
      handler: function (val, oldval) {
        if (!val) return;
        const list = this.hasSpecialColumn ? this.setClassName(val) : val;
        this.initTableHeaderList(list);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getTableHead();
    const clickHandler = e => {
      if (!e.target.closest('.score-rule-info-popover')) {
        this.scoreRuleInfoVisible = false;
      }
    };
    document.addEventListener('mousedown', clickHandler);
    // 卸载全局click事件
    this.$once('hook:beforeDestroy', () => document.removeEventListener('mousedown', clickHandler, true));
  },
  methods: {
    listSortByFixed() {
      this.list.sort((a, b) => b.is_fixed - a.is_fixed);
    },
    // 拖拽结束后处理
    handleDragEnd() {
      this.listSortByFixed();
    },
    // 移到最前面
    moveToTop(currentIndex) {
      this.list.unshift(this.list.splice(currentIndex, 1)[0]);
      this.listSortByFixed();
      this.list = this.list.map((item, index) => {
        item.order = index + 1;
        return item;
      });
    },
    async confirmAndSaveTableHead() {
      this.handleTableHead();
      this.visible = false;
      this.$emit('confirmAndSaveTableHead', this.list);
    },
    // 获取表头列表
    async getTableHead() {
      if (this.type && this.fetchTableHeadMethod) {
        const res = await this.fetchTableHeadMethod({
          ...this.params,
          workspace_id: this.workspaceInfo.id
        });
        if (res && (res.code === 200 || res.code === 20000)) {
          if (this.addCreateAt) {
            res.results.splice(1, 0, {
              field_name: 'create_at',
              field_type: 'datetime',
              is_activate: 1,
              is_fixed: 1,
              is_show: 1,
              name: '创建时间',
              order: 1
            });
          }
          const list = Array.isArray(res.results) ? this.setClassName(res.results) : res.results.heads;
          this.initTableHeaderList(list);
        }
      }
    },
    initTableHeaderList(initList) {
      this.list = (0, _commonFunc.deepClone)(initList.map(item => {
        // 用来控制搜索时是否显示
        item.show = true;
        return item;
      }));
      // 如果is_fixed为1，按照现有顺序将其放置列表最前面
      this.listSortByFixed();
      this.rawList = this.list;
      if (this.list.length && this.scoreRuleId) {
        const scoreRule = this.list.find(item => item.field_id === Number(this.scoreRuleId));
        if (scoreRule) {
          if (scoreRule.is_activate) {
            this.$emit('highlightColumn', Number(this.scoreRuleId));
          } else {
            this.scoreRuleInfoVisible = true;
          }
        } else {
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            duration: 0,
            showClose: true,
            message: `<span>无${this.scoreRuleName}查看权限，请调整规则可见范围，回到<a style="color:#4461ec" href="${_routeMap.ROUTES.tradeScoreSetting}">交易评分规则</a></span>`
          });
        }
      }
      this.checkList = this.list.filter(item => item.is_activate);
      this.$emit('getTableHead', this.type, this.checkList);
    },
    change(val) {
      if (val) {
        this.list.forEach(item => {
          const reg = new RegExp(val);
          if (item.name.match(reg)) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
      } else {
        this.list.forEach(item => {
          item.show = true;
        });
      }
    },
    handleChange(val) {
      if (val.length === this.minCheckCount) {
        this.$message({
          type: 'warning',
          message: `最少选择 ${this.minCheckCount} 项`
        });
      }
    },
    // 自定义表头上传
    async saveTableHead() {
      this.handleTableHead();
      const config = (0, _commonFunc.deepClone)(this.list);
      config.forEach(item => {
        delete item.show;
        delete item.className;
        delete item.fixed;
        delete item.special;
        delete item.sortable;
        delete item.width;
      });
      // 列设置更新再外面发请求
      if (!this.resetRequestIsInner) {
        this.$emit('updateTableHead', config, this.list);
        this.visible = false;
        return;
      }
      if (this.resetHeadDataMethod) {
        this.saveLoading = true;
        try {
          // 质检导出列表的列设置
          const params = Object.keys(this.params).length ? {
            ...this.params,
            workspace_id: this.workspaceInfo.id,
            config
          } : config;
          const res = await this.resetHeadDataMethod(params);
          if (res.code === 200 || res.code === 20000) {
            this.$message.success('设置成功');
            this.getTableHead();
            this.visible = false;
          }
        } finally {
          this.saveLoading = false;
        }
      } else {
        // 商机页逻辑（暂时保留）
        this.initTableHeaderList(this.list);
        this.visible = false;
      }
    },
    handleTableHead() {
      this.list.forEach(item => {
        if (this.checkList.indexOf(item) === -1) {
          item.is_activate = 0;
        } else {
          item.is_activate = 1;
        }
        // eslint-disable-next-line eqeqeq
        if (['account_name', 'customer_name'].includes(item.field_name)) {
          item.is_activate = 1;
        }
      });
    },
    // 重置
    handleReset() {
      this.list = this.rawList;
      this.checkList = this.list.filter(item => {
        return item.is_activate === 1;
      });
    },
    handleHide() {
      this.handleReset();
    },
    // 处理表头信息
    setClassName(val) {
      val.forEach(item => {
        // 判断其宽度和自定义类
        const columnWidth185 = ['objection', 'next_step', 'events', 'account_label', '企微添加记录', '下发网销记录', '订单记录'];
        if (item.field_name === 'speak_time') {
          item.className = 'follow-column';
          item.width = 500;
        } else if (item.field_name === 'follow_up_by' || item.field_name === 'own_by') {
          item.className = 'right-column';
          item.width = 150;
        } else if (item.field_name === 'risk') {
          item.width = 80;
        } else if (columnWidth185.includes(item.field_name) || columnWidth185.includes(item.name)) {
          item.width = 185;
        } else {
          item.width = 150;
        }
        // 判断是否fixed
        item.fixed = ['deal_name', 'trade_name', 'account_name', 'customer_name'].includes(item.field_name);
        // 是否是特殊列
        const specialColumnLists = ['speak_time', 'follow_up_by', 'own_by', 'objection', 'next_step', 'events', 'account_label', 'risk', 'deal_name', 'next_connect_time', 'deal_stage', 'create_at', 'amount', 'checkbox', 'comment', 'customer_score', 'account_name', 'customer_name', 'trade_name'];
        const specialColumnTypeList = ['table', 'checkbox', 'radio', 'select', 'datetime', 'date', 'money', 'number', 'score_rule', 'link'];
        if (specialColumnLists.includes(item.field_name) || specialColumnTypeList.includes(item.field_type) || item.event_type === 2) {
          item.special = true;
        }
        const tipHeaderLists = ['speak_time', 'next_step', 'objection', 'comment', 'space_conv_total', 'space_conv_total_duration'];
        if (tipHeaderLists.includes(item.field_name) || item.field_type === 'score_rule') {
          item.customHeader = true;
        }
      });
      return val;
    }
  }
};
exports.default = _default;