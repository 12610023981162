"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _select = _interopRequireDefault(require("./src/select"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* istanbul ignore next */
_select.default.install = function (Vue) {
  Vue.component(_select.default.name, _select.default);
};
var _default = _select.default;
exports.default = _default;