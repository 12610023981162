"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _serviceQR = _interopRequireDefault(require("@/assets/serviceQR.jpg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AfterSumitCard',
  data() {
    return {
      QRImg: _serviceQR.default
    };
  },
  methods: {
    backTo() {
      this.$emit('backTo');
    }
  }
};
exports.default = _default;