"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
// import LikeOperate from './LikeOperate';
var _default = {
  name: 'AnswerItem',
  // components: { LikeOperate },
  props: {
    convItem: {
      type: Object,
      default: () => ({})
    },
    isShowDesc: {
      type: Boolean,
      default: true
    },
    isShowOperate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    questionList() {
      var _this$convItem$replys;
      return (_this$convItem$replys = this.convItem.replys) === null || _this$convItem$replys === void 0 ? void 0 : _this$convItem$replys.filter(i => i.type === 'question');
    },
    answerList() {
      var _this$convItem$replys2;
      return (_this$convItem$replys2 = this.convItem.replys) === null || _this$convItem$replys2 === void 0 ? void 0 : _this$convItem$replys2.filter(i => i.type === 'answer');
    }
  },
  methods: {
    getConvTime(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    goConvSentence(order) {
      if (this.isShowOperate) {
        window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.convItem.conversation_id}&order=${order}&noback=true`);
      }
    },
    getSpeakerType(type) {
      const map = {
        customer_contact: '客户',
        host_salesman: '销售'
      };
      return map[type];
    }
  }
};
exports.default = _default;