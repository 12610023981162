"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _index = _interopRequireDefault(require("@/components/ShowLongContent/index"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MergeAppDataDialog',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ShowLongContent: _index.default
  },
  props: {
    mergeDialogVisible: {
      type: Boolean,
      default: false
    },
    recommendList: {
      type: Array,
      default: () => []
    },
    recommendTitle: {
      type: String,
      default: ''
    },
    externalUserId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      form: {
        external_user_id: '',
        outbound_contact_id: '',
        outbound_contact_name: '',
        confirm_contact_name: 'outbound'
      },
      filter: '',
      selectedId: ''
    };
  },
  watch: {
    externalUserId: {
      handler: function (val) {
        this.form.external_user_id = val.toString();
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('filterDealContactList', val);
    },
    closeMergeDialog() {
      this.$emit('closeMergeDialog');
      this.form = this.$options.data().form;
      this.filter = this.$options.data().filter;
      this.selectedId = this.$options.data().selectedId;
    },
    submitForm() {
      this.$refs.mergeForm.validate(valid => {
        if (valid && this.form.outbound_contact_id) {
          this.$emit('submitForm', (0, _commonFunc.deepClone)(this.form));
          this.closeMergeDialog();
        } else {
          this.$message({
            type: 'warning',
            message: '请选择要合并的客户'
          });
          return false;
        }
      });
    },
    handleClose() {
      this.closeMergeDialog();
    },
    selectUserToMerge(userInfo) {
      this.selectedId = this.selectedId === userInfo.contact_id ? '' : userInfo.contact_id;
      this.form.outbound_contact_id = Number(this.selectedId);
      this.form.outbound_contact_name = userInfo.name;
    }
  }
};
exports.default = _default;