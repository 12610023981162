"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _TimeRangeSelect = _interopRequireDefault(require("@/components/TimeRangeSelect"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _vue = require("vue");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TeamScheduleFilter',
  props: {
    callBackFilters: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const {
      callBackFilters
    } = (0, _vue.toRefs)(props);
    const localFilters = (0, _vue.ref)({
      tree_ids: [],
      user_ids: [],
      conversation_type: '',
      conversation_scene_type: '',
      duration: {
        unit: 'second',
        value: []
      },
      keyEventCascaderList: [{
        keyEventName: [],
        inverse: false
      }],
      event_engine_relation: 'and'
    });
    const filterValues = (0, _vue.computed)(() => {
      const validFilters = (0, _cloneDeep.default)(localFilters.value);
      validFilters.keyEventCascaderList = validFilters.keyEventCascaderList.filter(item => item.keyEventName.length);
      return validFilters;
    });
    (0, _vue.watch)(filterValues, (val, oldVal) => {
      if (!(0, _isEqual.default)(val, oldVal)) {
        emit('change', localFilters);
      }
    }, {
      deep: true
    });

    // 部门成员筛选器变化
    const memberChange = val => {
      const {
        tree_ids,
        user_ids
      } = val;
      localFilters.value.tree_ids = tree_ids;
      localFilters.value.user_ids = user_ids;
    };

    // 关键事件筛选器
    const keyEventObj = (0, _vue.computed)(() => {
      return {
        keyEventCascaderList: localFilters.value.keyEventCascaderList,
        event_engine_relation: localFilters.value.event_engine_relation
      };
    });
    const keyEventChange = val => {
      if (Array.isArray(val) && !val.length) {
        localFilters.value.event_engine_relation = 'and';
        localFilters.value.keyEventCascaderList = [{
          keyEventName: [],
          inverse: false
        }];
      } else {
        const {
          event_engine_relation,
          keyEventCascaderList
        } = val;
        localFilters.value.event_engine_relation = event_engine_relation;
        localFilters.value.keyEventCascaderList = keyEventCascaderList;
      }
    };

    // 会话时长
    const changeTimeRang = val => {
      localFilters.value.duration.value = val;
    };
    // 会话类别
    const conversationTypeOptions = [{
      value: 'audio',
      label: '电话'
    }, {
      value: 'video',
      label: '在线会议'
    }, {
      value: 'doc',
      label: '文字'
    }];

    // 会话场景
    const conversationSceneTypeOptions = [{
      value: 'single',
      label: '单聊'
    }, {
      value: 'multiple',
      label: '群聊'
    }];

    // 回填
    const numberList = (0, _vue.ref)([]);
    (0, _vue.watch)(callBackFilters, val => {
      console.log(val, 'callBackFilters====');
      const {
        user_ids,
        duration,
        conversation_type,
        conversation_scene_type,
        keyEventCascaderList,
        event_engine_relation
      } = val;
      numberList.value = user_ids;
      localFilters.value = {
        duration,
        conversation_type,
        conversation_scene_type,
        keyEventCascaderList,
        event_engine_relation,
        ...handleUserMembersValue(user_ids)
      };
    });
    const handleUserMembersValue = initArray => {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    };
    const handleVisibleChange = val => {
      if (val) document.body.click();
    };
    const conversationTypeChange = val => {
      localFilters.value.conversation_scene_type = '';
    };
    return {
      __sfc: true,
      props,
      callBackFilters,
      localFilters,
      filterValues,
      emit,
      memberChange,
      keyEventObj,
      keyEventChange,
      changeTimeRang,
      conversationTypeOptions,
      conversationSceneTypeOptions,
      numberList,
      handleUserMembersValue,
      handleVisibleChange,
      conversationTypeChange,
      DealKeyEventFilter: _DealkeyEventFilter.default,
      TimeRangeSelect: _TimeRangeSelect.default,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList
    };
  }
};
exports.default = _default;