"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "coach-parent-task-detail",
    class: {
      "has-tab": _setup.hasErrorSummary
    }
  }, [_c(_setup.LeaderPlanHeader, {
    attrs: {
      "task-info": _setup.taskInfo
    }
  }), _setup.hasErrorSummary ? _c(_setup.SimpleTabs, {
    attrs: {
      tabs: _setup.overviewTabs
    },
    model: {
      value: _setup.activeOverviewTab,
      callback: function ($$v) {
        _setup.activeOverviewTab = $$v;
      },
      expression: "activeOverviewTab"
    }
  }) : _vm._e(), _setup.activeOverviewTab === "completion" ? _c("div", {
    staticClass: "main-content"
  }, [_c(_setup.LeaderPlanHeaderFilter, {
    attrs: {
      "task-info": _setup.taskInfo,
      "has-member-select": "",
      "show-export-button": false,
      "score-max-limit": 9999,
      "score-range-placeholder": _setup.t("coaching.cumulativeScoreRange")
    },
    on: {
      filterChange: _setup.regetParentTaskDetail,
      selectMember: _setup.regetParentTaskDetail,
      allUrgeSuccess: _setup.handleAllUrgeSuccess
    }
  }), _c(_setup.ParentTaskTable, {
    attrs: {
      data: _setup.taskTableData,
      head: _setup.taskTableHead,
      "table-loading": _setup.taskTableLoading
    },
    on: {
      "sort-change": _setup.onTableSortChange
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      "current-page": _setup.requestParams.page,
      "page-size": _setup.requestParams.size,
      total: _setup.tableTotal
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_setup.requestParams, "page", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_setup.requestParams, "page", $event);
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_setup.requestParams, "size", $event);
      },
      "update:page-size": function ($event) {
        return _vm.$set(_setup.requestParams, "size", $event);
      },
      sizeChange: _setup.onSizeChange,
      currentChange: _setup.getParentTaskDetail
    }
  })], 1) : _c("div", {
    staticClass: "main-content error-summary-container"
  }, [_c("MgvInfoAlert", {
    attrs: {
      closable: false
    }
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.暂只支持剧本对练课程类型的错误汇总")) + " ")]), _c(_setup.CourseAnalysisTable, {
    attrs: {
      "table-data": _setup.courseAnalysisTableData,
      "table-loading": _setup.courseAnalysisTableLoading,
      "show-course-name": ""
    },
    on: {
      sortChange: _setup.getErrorSummaryList
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;