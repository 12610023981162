"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@/api/client");
var _CreateDealDialog = _interopRequireDefault(require("./CreateDealDialog"));
var _ChooseAccountDialog = _interopRequireDefault(require("./ChooseAccountDialog"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateDeal',
  components: {
    createDealDialog: _CreateDealDialog.default,
    ChooseAccountDialog: _ChooseAccountDialog.default
  },
  data() {
    return {
      createDealDialogVisible: false,
      chooseAccountDialogVisible: false,
      dealForm: {},
      formDesc: [],
      dynamicForm: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['contactDynamicFormDescList', 'businessStatusOptions'])
  },
  created() {
    this.$store.dispatch('account/getContactDynamicForm');
  },
  mounted() {
    this.getBusinessStatusOptions();
  },
  methods: {
    createDealFormChange(val) {
      this.dealForm = val || {};
    },
    fetchAccountSuccess(val) {
      this.chooseAccountDialogVisible = false;
      this.$refs['createDealDialog'].customerChange({
        name: val.name,
        id: val.id
      });
    },
    chooseAccountSuccess(val) {
      if (val) {
        this.$refs['createDealDialog'].customerChange(val);
      }
    },
    openChooseAccountDialog() {
      this.chooseAccountDialogVisible = true;
    },
    closeCreateDealDialog() {
      this.createDealDialogVisible = false;
      this.formDesc = [];
    },
    closeChooseAccountDialog() {
      this.chooseAccountDialogVisible = false;
    },
    openCreateDealDialog() {
      this.createDealDialogVisible = true;
      this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)));
    },
    // 获取商机阶段getBusinessStatusOptions
    async getBusinessStatusOptions() {
      await this.$store.dispatch('business/get_business_status_options', 'deal');
      // 获取动态表单
      if (this.$refs['createDealDialog']) {
        this.$refs['createDealDialog'].loading = true;
      }
      // 参数：in_account: 1表示在客户模块，0表示在商机模块
      const dynamicForm = await (0, _client.getDynamicForm)({
        in_account: 0
      });
      if (dynamicForm.code === 200) {
        this.dynamicForm = dynamicForm.results;
        if (this.$refs['createDealDialog']) {
          this.$refs['createDealDialog'].loading = false;
        }
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)));
      }
    },
    // 表单描述格式化
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'select' && item.name === '商机阶段') {
          obj.options = this.businessStatusOptions;
        }
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
        }
        desc.push(obj);
      }
      return desc;
    }
  }
};
exports.default = _default;