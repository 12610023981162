"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "AngleTag",
  props: {
    colorObj: {
      type: Object,
      default: function () {
        return {
          backgroudColor: "#fff",
          borderColor: "#EBEEF5",
          fontColor: "#8E95B0"
        };
      }
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      //类型 第一个、中间、最后一个
      type: String,
      default: "center"
    },
    canClick: {
      type: [Number, Boolean],
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
exports.default = _default;