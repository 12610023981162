"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'PresetPathForm',
  props: {
    keyEventsForm: {
      type: Object,
      default: () => ({})
    },
    keyEventOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      keyEventsRule: [{
        required: true,
        message: '请选择关键事件',
        trigger: 'change'
      }],
      // 级联数据
      defaultProps: {
        expandTrigger: 'hover',
        checkStrictly: true,
        children: 'children',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      localKeyEventOptions: []
    };
  },
  watch: {
    keyEventsForm: {
      handler: function (val) {
        let disabledKeyEventArr = [];
        disabledKeyEventArr = val.pathKeyEventsList.map(item => item.value);
        this.setKeyEventDisabled(this.keyEventOptions, disabledKeyEventArr);
        this.localKeyEventOptions = (0, _commonFunc.deepClone)(this.keyEventOptions);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleCascaderChange(_, refName) {
      this.$refs[refName][0].dropDownVisible = false;
    },
    setKeyEventDisabled(keyEvent, ids) {
      keyEvent.forEach(item => {
        item.disabled = ids.includes(item.id);
        if (Object.prototype.hasOwnProperty.call(item, 'children')) {
          this.setKeyEventDisabled(item.children, ids);
        }
      });
    },
    deletePathKeyEvents(index) {
      this.$emit('deletePathKeyEvents', index);
    },
    clearValidate() {
      this.$refs['keyEventsForm'].clearValidate();
    }
  }
};
exports.default = _default;