"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: `${_vm.isEdit ? "编辑" : "新建"}智能会话流`,
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      size: "1000px",
      "custom-class": "create-conv-flow-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("conv-flow-info", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.activeStep,
      expression: "!activeStep"
    }],
    ref: "convFlowInfo",
    attrs: {
      visible: _vm.visible,
      "is-edit": _vm.isEdit,
      "current-node": _vm.currentNode,
      "edit-node": _vm.editNode,
      "create-subfolder": _vm.createSubfolder
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep,
      expression: "activeStep"
    }],
    staticClass: "conv-list-page"
  }, [_c("div", {
    staticClass: "top-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v(" 请在下方选择该智能会话流的筛选项，确认后点击完成。 ")]), _c("conv-list-page", {
    attrs: {
      "back-fill-filters": _vm.backFillFilters,
      "create-conv-flow-visible": _vm.visible
    },
    on: {
      convFlowFiltersChange: _vm.convFlowFiltersChange,
      filterNumChange: _vm.filterNumChange
    }
  })], 1)], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    staticClass: "mg-button mg-round-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep === 0,
      expression: "activeStep === 0"
    }],
    staticClass: "mg-button mg-round-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goNextStep
    }
  }, [_vm._v("下一步")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep === 1,
      expression: "activeStep === 1"
    }],
    staticClass: "mg-button mg-round-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.goPrevStep
    }
  }, [_vm._v("上一步")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep === 1,
      expression: "activeStep === 1"
    }],
    staticClass: "mg-button mg-round-button",
    class: [!_vm.filterNum ? "submit-disabled" : ""],
    attrs: {
      disabled: !_vm.filterNum,
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("完成")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;