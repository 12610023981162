"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EventOrderTracker = _interopRequireDefault(require("@/views/conversationPage/components/EventOrderTracker.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealKeyEventOrderFilter',
  components: {
    EventOrderTracker: _EventOrderTracker.default
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    keyEvents: {
      type: Array,
      default: () => []
    },
    popperClass: {
      type: String,
      default: 'business-filter-popover'
    }
  },
  computed: {
    orderText() {
      return this.setOrderText(this.value);
    }
  },
  methods: {
    addKeyEventOrderCascader() {
      this.$refs['dealKeyEventOrderPopoverRef'].updatePopper();
    },
    setOrderText(param) {
      if (param) {
        const result = [];
        const filterList = param.keyEventOrderCascaderList.filter(e => e['keyEventLabel']);
        filterList.forEach(item => {
          result.push(`${item.inverse ? '不含' : ''}${item.keyEventLabel}`);
        });
        return result.join(' → ');
      }
    },
    handleChange(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;