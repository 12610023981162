"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "contact-form-card"
  }, [_c("div", [_c("header", {
    staticClass: "header"
  }, [_c("div", [!_vm.isEdit && _vm.isShow ? _c("div", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/account/account/contact"],
      expression: "['/api/account/account/contact']"
    }]
  }, [_c("el-button", {
    attrs: {
      plain: "",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.enterEdit
    }
  })], 1) : _vm._e(), _vm.isEdit ? _c("div", [_c("el-button", {
    attrs: {
      size: "small",
      plain: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick"
    }],
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1) : _vm._e()])]), _vm.inDeal ? _c("header", {
    staticClass: "deal-contact"
  }, [_c("div", {
    staticClass: "contact-operation"
  }, [_vm.isMain ? _c("div", {
    staticClass: "is-decision"
  }, [_vm._v("关键决策人")]) : _vm._e()]), _c("el-dropdown", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/api/deal/contact/deal_update_contact"],
      expression: "['/api/deal/contact/deal_update_contact']"
    }],
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      plain: ""
    }
  }, [_c("i", {
    staticClass: "iconfont icon-more"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [!_vm.isMain ? _c("el-dropdown-item", {
    attrs: {
      command: "setDecisionMarker"
    }
  }, [_vm._v("设为关键决策人")]) : _c("el-dropdown-item", {
    attrs: {
      command: "cancelSetDecisionMarker"
    }
  }, [_vm._v("取消关键决策人")])], 1)], 1)], 1) : _vm._e()]), _c("div", {
    staticClass: "content"
  }, [_c("dynamic-form", {
    ref: "form",
    staticClass: "contact-form",
    attrs: {
      desc: _vm.formDesc,
      value: _vm.value,
      "is-edit": _vm.isEdit
    },
    on: {
      keyChange: _vm.handleKeyChange
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;