"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "smart-traning-edit"
  }, [_c("header", [_c("div", {
    staticClass: "left-part"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _setup.goBack
    }
  }), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_setup.t(_setup.isEdit ? "coaching.editCourse" : "coaching.newCourse")) + "-" + _vm._s(_setup.t("coaching.aICustomer")))]), _c("el-tooltip", {
    attrs: {
      content: "AI客户会跟据您选择的知识库话术，与销售进行对话，发起提问并评分。"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.updateCourseNameLoading,
      expression: "updateCourseNameLoading"
    }],
    staticClass: "update-name-loading"
  })], 1), _c("div", {
    staticClass: "center"
  }), _c("div", {
    staticClass: "right"
  }, [_c("el-link", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    attrs: {
      type: "primary",
      underline: false,
      target: "_blank",
      href: "https://www.megaview.com/help-center/doc?docx=XgSIde74noecqJxNbrjcc8Usnbb&edition_id=hOw95E&anchor=doxcnNNuU0llUAKlFJ5gppmhShc"
    }
  }, [_vm._v("帮助教程")])], 1)]), _c("article", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "info-container"
  }, [_c("div", {
    staticClass: "scroll-container"
  }, [_c("div", {
    staticClass: "card-conatainer"
  }, [_vm._m(0), _c("el-input", {
    staticClass: "edit-title-input",
    attrs: {
      placeholder: "请输入课程名称",
      maxlength: "50"
    },
    on: {
      change: _setup.modifyTitle
    },
    model: {
      value: _setup.title,
      callback: function ($$v) {
        _setup.title = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "title"
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.titleErrorMessage,
      expression: "titleErrorMessage"
    }],
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_setup.titleErrorMessage) + " ")])], 1), _c("div", {
    staticClass: "card-conatainer"
  }, [_vm._m(1), _c("p", {
    staticClass: "description"
  }, [_vm._v(" 建立不同的对话场景，并添加客户问题，AI客户将提出问题考核学员 ")]), _c(_setup.DialogueScenarioList, {
    ref: "dialogueScenarioListRef",
    attrs: {
      saved: _setup.saved,
      "backfill-scene-list": _setup.backfillSceneList
    },
    on: {
      "update:saved": function ($event) {
        _setup.saved = $event;
      },
      openAddScriptDialog: _setup.openAddScriptDialog
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.sceneErrorMessage.length,
      expression: "sceneErrorMessage.length"
    }],
    ref: "sceneErrorMessageRef",
    staticClass: "scene-error-message"
  }, [_vm._v(" " + _vm._s(_setup.sceneErrorMessage) + " ")]), _c(_setup.PracticeProcessTipsSwitch, {
    attrs: {
      title: "对练话术提示",
      description: "开启后，学员可在练习过程中查看话术提示"
    },
    model: {
      value: _setup.courseConfig.is_knowledges_display,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "is_knowledges_display", $$v);
      },
      expression: "courseConfig.is_knowledges_display"
    }
  })], 1), _c(_setup.CustomerPersona, {
    ref: "CustomerPersonaRef",
    staticClass: "card-conatainer",
    attrs: {
      saved: _setup.saved,
      "backfill-customer-persona-list": _setup.backfillCustomerPersonaList
    },
    on: {
      "update:saved": function ($event) {
        _setup.saved = $event;
      }
    }
  }), _c("div", {
    staticClass: "card-conatainer"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("个性化设置")]), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 头像 "), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "头像将在对练页或课程封面展示"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c(_setup.ttsAvatarNameSelect, {
    on: {
      "immediate-change": _setup.ttsAvatarNameChange
    },
    model: {
      value: _setup.courseConfig.avatar_type,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "avatar_type", $$v);
      },
      expression: "courseConfig.avatar_type"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.courseConfig.practice_mode,
      expression: "!courseConfig.practice_mode"
    }]
  }, [_c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 语言表现增强"), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "合适的语言有助于AI客户的发音和学员录音识别"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _vm._l(_setup.languageMap, function (_ref, key) {
    let {
      label
    } = _ref;
    return _c("el-radio", {
      key: key,
      attrs: {
        label: key
      },
      on: {
        change: _setup.toggleLanguage
      },
      model: {
        value: _setup.courseConfig.language,
        callback: function ($$v) {
          _vm.$set(_setup.courseConfig, "language", $$v);
        },
        expression: "courseConfig.language"
      }
    }, [_vm._v(_vm._s(label))]);
  })], 2)], 1), _c("div", {
    staticClass: "card-conatainer"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("客户知识设置")]), _c("p", {
    staticClass: "description"
  }, [_vm._v(" 关联某个客户的若干会话，谈及会话相似内容时，AI客户将依据会话内容发言 ")]), _vm._l(_setup.conversationList, function (item, index) {
    return _c(_setup.ConversationItem, {
      key: item.id,
      attrs: {
        item: item
      },
      on: {
        delete: function ($event) {
          return _setup.deleteConversation(index);
        }
      }
    });
  }), _c("el-button", {
    staticClass: "add-conversation-button",
    attrs: {
      icon: "el-icon-plus",
      size: "medium "
    },
    on: {
      click: function ($event) {
        _setup.conversationDialog = true;
      }
    }
  }, [_vm._v("添加会话")])], 2), _c("div", {
    staticClass: "card-conatainer"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("其他设置")]), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 发言输入方式 "), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "设置学员的发言输入方式"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _vm._l(["语音模式", "文字模式"], function (label, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: index
      },
      model: {
        value: _setup.courseConfig.practice_mode,
        callback: function ($$v) {
          _vm.$set(_setup.courseConfig, "practice_mode", $$v);
        },
        expression: "courseConfig.practice_mode"
      }
    }, [_vm._v(_vm._s(label))]);
  }), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 练习说明 "), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "在对练页面向学员介绍客户信息、沟通目标"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("el-input", {
    staticClass: "practice-na",
    attrs: {
      type: "textarea",
      rows: 3,
      maxlength: "100",
      resize: "none",
      placeholder: "在对练页面向学员介绍客户信息、沟通目标"
    },
    on: {
      change: function ($event) {
        _setup.saved = false;
      }
    },
    model: {
      value: _setup.courseConfig.scene,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "scene", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "courseConfig.scene"
    }
  }), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 谁先发言 "), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: "设置对话开始时的发言方和发言内容"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("el-radio", {
    attrs: {
      label: 2
    },
    on: {
      change: _setup.toggleSpeakerParty
    },
    model: {
      value: _setup.courseConfig.speaker_party,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "speaker_party", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "courseConfig.speaker_party"
    }
  }, [_vm._v("AI客户先说")]), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      maxlength: "300",
      resize: "none",
      placeholder: "请输入内容"
    },
    on: {
      change: function ($event) {
        _setup.saved = false;
      }
    },
    model: {
      value: _setup.courseConfig.content,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "content", $$v);
      },
      expression: "courseConfig.content"
    }
  })], 2)], 1)]), _c("footer", [_c("div", {
    staticClass: "footer-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.submitLoading
    },
    on: {
      click: _setup.saveCourse
    }
  }, [_vm._v(_vm._s(_setup.courseId ? "提交模型修改" : "生成客户模型"))]), _c("el-button", {
    attrs: {
      disabled: !_setup.courseId || _setup.submitLoading
    },
    on: {
      click: _setup.goBack
    }
  }, [_vm._v(_vm._s(_setup.t("coaching.returnToList")))])], 1)])]), _c(_setup.TrainingTestDrawer, {
    ref: "trainingTestDrawerRef",
    attrs: {
      "drawer-visible": _setup.trainingTestDrawerVisible,
      "avatar-url": _setup.ttsAvatarUrl,
      "sale-default-script": false,
      "course-id": _setup.courseId
    }
  })], 1), _c(_setup.AddScriptDialog, {
    attrs: {
      visible: _setup.addScriptDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.addScriptDialogVisible = $event;
      },
      change: _setup.changeKnowledgeList
    }
  }), _c(_setup.ConversationListDialog, {
    attrs: {
      "dialog-visible": _setup.conversationDialog
    },
    on: {
      "update:dialogVisible": function ($event) {
        _setup.conversationDialog = $event;
      },
      "update:dialog-visible": function ($event) {
        _setup.conversationDialog = $event;
      },
      checkedConversation: _setup.checkedConversation
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "title"
  }, [_vm._v(" 课程名称 "), _c("span", {
    staticClass: "require-icon"
  }, [_vm._v("*")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "title"
  }, [_vm._v(" 对话场景设置"), _c("span", {
    staticClass: "require-icon"
  }, [_vm._v("*")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;