"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventKeyDrawer',
  components: {
    EventsList: _eventsList.default,
    KeyEventDetailList: _keyEventDetailList.default
  },
  props: {
    eventKeyDrawerVisible: {
      type: Boolean,
      default: false
    },
    convId: {
      type: [Number, String],
      required: true
    },
    mediaType: {
      type: String,
      default: ''
    },
    participants: {
      type: Array,
      default: () => []
    },
    isKeyEventDetail: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: [Number, String],
      default: ''
    },
    noEventClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      eventEngine: [],
      activeEventData: [],
      total: 0,
      eventName: '',
      eventTotal: 0,
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    drawerTitle() {
      const drawerTitle = this.eventName ? `${this.eventName} (${this.eventTotal})` : '';
      return this.isKeyEventDetail ? drawerTitle : `关键事件 (${this.total})`;
    }
  },
  watch: {
    eventKeyDrawerVisible(val) {
      if (val) {
        if (this.isKeyEventDetail) {
          this.getEventTrackers();
        } else {
          this.getEventEngine();
        }
      } else {
        this.eventEngine = [];
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    goBack() {
      this.$emit('goBack');
      this.getEventEngine();
    },
    handleTagClick(eventId) {
      this.$emit('handleTagClick');
      this.getEventTrackers(eventId);
    },
    async getEventTrackers(eventId) {
      this.activeEventData = [];
      this.eventName = '';
      this.loading = true;
      const data = {
        conversation_id: this.convId,
        event_id: eventId || this.eventId
      };
      const res = await (0, _videoDetail.getEventTrackers)(data);
      this.loading = false;
      if (res.code === 20000) {
        this.activeEventData = res.results.datas;
        this.eventName = res.results.name;
        this.eventTotal = res.results.count;
      }
    },
    keyEventToDetail(index) {
      const url = `${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.convId}&keyWordId=${this.eventId}&itemIndex=${index}&noback=true`;
      window.open(url);
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    async getEventEngine() {
      const res = await (0, _videoDetail.getEventEngine)(this.convId, this.workspaceInfo.id);
      if (res.code === 200) {
        this.eventEngine = res.results;
        this.total = res.total;
      }
    }
  }
};
exports.default = _default;