"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "event-tree"
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      "icon-class": "iconfont icon-caret-right",
      "node-key": "id",
      "highlight-current": "",
      data: _vm.data,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "expand-on-click-node": false,
      "auto-expand-parent": false
    },
    on: {
      "node-click": _vm.handleNodeClick,
      "node-expand": (data, node) => _vm.updateExpandedNodeKey(node, true),
      "node-collapse": (data, node) => _vm.updateExpandedNodeKey(node, false)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data,
          node
        } = _ref;
        return [!data.showInput ? _c("div", {
          staticClass: "node-content",
          attrs: {
            id: data.id
          }
        }, [_c("div", {
          staticClass: "left-content"
        }, [!data.is_leaf ? _c("i", {
          class: ["iconfont", "folder", node.expanded ? "icon-folder-opened-fill" : "icon-folder-fill"]
        }) : _c("i", {
          staticClass: "iconfont icon-key-event"
        }), _c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(data.name))])]), _c("div", {
          staticClass: "right-operation"
        }, [!data.is_leaf && data.event_type === "custom" && !_vm.isSilence ? _c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand($event, data, node);
            }
          }
        }, [_c("el-button", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-plus"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            command: "createKeyEvent",
            icon: "iconfont icon-key-event"
          }
        }, [_vm._v("新建关键事件")]), _c("el-dropdown-item", {
          attrs: {
            command: "createFolder",
            icon: "iconfont icon-folder-add"
          }
        }, [_vm._v("新建文件夹")])], 1)], 1) : _vm._e(), !data.is_leaf && data.event_type === "custom" && !_vm.isSilence ? _c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand($event, data, node);
            }
          }
        }, [_c("el-button", {
          on: {
            click: function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c("i", {
          staticClass: "iconfont icon-more"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            command: "rename",
            icon: "iconfont icon-edit"
          }
        }, [_vm._v("重命名")]), _c("el-dropdown-item", {
          staticClass: "delete",
          attrs: {
            command: "delete",
            icon: "iconfont icon-delete"
          }
        }, [_vm._v("删除")])], 1)], 1) : _vm._e()], 1)]) : _c("CustomEventInput", {
          ref: "rename" + data.folder_id,
          attrs: {
            maxlength: "32"
          },
          on: {
            blur: function ($event) {
              return _vm.handleRenameInputBlur(data, arguments);
            }
          },
          nativeOn: {
            keypress: function ($event) {
              if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return $event.target.blur();
            }
          },
          model: {
            value: data.name,
            callback: function ($$v) {
              _vm.$set(data, "name", $$v);
            },
            expression: "data.name"
          }
        })];
      }
    }])
  }), _vm.showInput ? _c("CustomEventInput", {
    ref: "nameInput",
    staticClass: "key-event-name",
    on: {
      blur: _vm.submitCreate
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return $event.target.blur();
      }
    },
    model: {
      value: _vm.folderName,
      callback: function ($$v) {
        _vm.folderName = $$v;
      },
      expression: "folderName"
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;