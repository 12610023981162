"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tricks-practice"
  }, _vm._l(_vm.localList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tricks-card"
    }, [item.scene_content ? _c("div", {
      staticClass: "scene-name"
    }, [_c("tricks-scene", {
      attrs: {
        "display-only": "",
        value: item.scene_content
      }
    })], 1) : _vm._e(), item.reply_list && item.reply_list.length ? _c("div", {
      staticClass: "tricks"
    }, _vm._l(item.reply_list, function (reply, replyIndex) {
      return _c("div", {
        key: replyIndex,
        ref: "trickItem",
        refInFor: true,
        staticClass: "trick-item",
        class: {
          "incomplete-trick": reply.reply_id === _vm.incompleteId
        },
        attrs: {
          id: reply.reply_id
        }
      }, [_c("div", {
        staticClass: "trick-progress"
      }, [_c("div", [_vm._v("( " + _vm._s(reply.order) + " )")]), _vm.practiceCount > 1 ? [reply.repeat_count === _vm.practiceCount ? _c("div", {
        staticClass: "completed"
      }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.completed")))]), _c("i", {
        staticClass: "iconfont icon-success-green"
      })]) : _c("div", [_vm._v(" 还需" + _vm._s(_vm.courseType === 1 ? "朗读" : "背诵") + " " + _vm._s(_vm.practiceCount - reply.repeat_count) + " 次 ")]), _c("el-progress", {
        attrs: {
          percentage: reply.repeat_count / _vm.practiceCount * 100,
          "show-text": false
        }
      })] : [reply.reply_score >= _vm.lowestScore ? _c("div", {
        staticClass: "completed"
      }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.completed")))]), _c("i", {
        staticClass: "iconfont icon-success-green"
      })]) : _c("div", [_vm._v("进度")]), _c("el-progress", {
        attrs: {
          percentage: reply.reply_score >= _vm.lowestScore ? 100 : 0,
          "show-text": false
        }
      })], _c("span", {
        class: ["great-tip", {
          active: _vm.isComplete(reply)
        }, {
          "fail-tip": reply.reply_score < _vm.lowestScore
        }]
      }, [_vm._v(_vm._s(reply.reply_score >= _vm.lowestScore ? "太棒了！" : "得分较低，请再次练习"))])], 2), _vm.disabled ? [_c("p", {
        staticClass: "trick-content"
      }, [_c("span", [_vm._v(" " + _vm._s(reply.reply_content))])]), _c("div", {
        staticClass: "score-box"
      }, [reply.reply_score < 0 ? _c("div", {
        staticClass: "no-score"
      }, [_vm._v(" " + _vm._s(_vm.$t("coaching.noScoreYet")) + " ")]) : [_c("div", {
        staticClass: "score"
      }, [_vm._v(_vm._s(reply.reply_score) + "分")]), _c("audio", {
        attrs: {
          src: reply.reply_record_url,
          controls: ""
        }
      })]], 2)] : _c("PracticeAudioRecorder", {
        attrs: {
          reply: reply,
          "course-type": _vm.courseType
        },
        on: {
          recording: function ($event) {
            return _vm.handleRecording(reply.reply_id);
          },
          stopRecord: _vm.stopRecord,
          finished: _vm.handleFinished
        }
      })], 2);
    }), 0) : _vm._e()]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;