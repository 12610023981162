"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("./knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _KnowledgeLibraryCatalog = _interopRequireDefault(require("./KnowledgeLibraryCatalog.vue"));
var _KnowledgeSearch = _interopRequireDefault(require("./KnowledgeSearch.vue"));
var _EditKnowledgeBaseConfig = _interopRequireDefault(require("./knowledgeLibrary/EditKnowledgeBaseConfig"));
var _KnowledgeBasePermissionDrawer = _interopRequireDefault(require("./knowledgeLibrary/KnowledgeBasePermissionDrawer"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CatalogPart',
  components: {
    MenuToggleBtn: _MenuToggleBtn.default,
    KnowledgeLibraryCatalog: _KnowledgeLibraryCatalog.default,
    KnowledgeSearch: _KnowledgeSearch.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default,
    KnowledgeBasePermissionDrawer: _KnowledgeBasePermissionDrawer.default,
    EditKnowledgeBaseConfig: _EditKnowledgeBaseConfig.default
  },
  props: {
    catalogData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentNode: {
      type: Object,
      default: () => ({})
    },
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      publicScope: 0,
      knowledgeBaseList: [],
      currentKnowledgeBase: {},
      knowledgeBaseConfigVisible: false,
      knowledgeBasePermissionVisible: false,
      knowledgeBaseKey: 1
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission', 'knowledgeBaseInfo']),
    permission() {
      var _this$knowledgeBaseIn;
      return (this === null || this === void 0 ? void 0 : (_this$knowledgeBaseIn = this.knowledgeBaseInfo) === null || _this$knowledgeBaseIn === void 0 ? void 0 : _this$knowledgeBaseIn.permission) || 0;
    },
    hasCatalog() {
      return this.catalogData.length;
    },
    showKnowledgeDiscovery() {
      return this.interfacePermission.knowledgeDiscovery;
    },
    publicScopeLabel() {
      return this.publicScope === 1 ? '公开' : '成员可见';
    }
  },
  watch: {
    knowledgeBaseInfo: {
      // 切换知识库
      handler: function (val) {
        if (val) {
          this.currentKnowledgeBase = val;
          this.publicScope = val.public_scope;
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        path: _routeMap.ROUTES.knowledgeLibrary
      });
    },
    renameKnowledgeBase(_ref) {
      let {
        name
      } = _ref;
      this.currentKnowledgeBase.name = name;
    },
    // 修改知识库公开范围及权限
    changeKnowledgeBasePermission() {
      // 重新刷新页面
      this.knowledgeBaseKey += 1;
    },
    handleCommand(type) {
      switch (type) {
        case 'rename':
          this.handleKnowledgeBaseConfig();
          break;
        case 'permission':
          this.knowledgeBasePermissionVisible = true;
          break;
      }
    },
    handleKnowledgeBaseConfig() {
      this.knowledgeBaseConfigVisible = true;
      this.$refs['edit-knowledge-base-config'].knowledgeBaseConfig = (0, _commonFunc.deepClone)(this.currentKnowledgeBase);
    },
    getDropdownConfigs() {
      return [{
        type: 'rename',
        label: '重命名',
        icon: 'icon-edit'
      }, {
        type: 'permission',
        label: '权限',
        icon: 'icon-unlock'
      }];
    },
    goSessionDig() {
      this.$router.push({
        path: _routeMap.ROUTES.coachingKnowledgeSessionDig,
        query: {
          knowledgeBaseId: this.knowledgeBaseInfo.id
        }
      });
    },
    createCatalog() {
      this.$emit('createCatalog');
    },
    searchKnowledge(val) {
      this.$emit('search', val);
    }
  }
};
exports.default = _default;