"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    visible: Boolean,
    type: String,
    isSnippet: Boolean,
    currentNode: {
      type: Object,
      default: () => ({})
    },
    currentSnippet: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    closeDialog() {
      // this.$refs.form && this.$refs.form.clearValidate();
      this.$emit('closeDialog');
    }
  }
};
exports.default = _default;