"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
const prevParamsMap = new Map();
var _default = {
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone'])
  },
  data() {
    return {
      isLoading: true,
      pageType: '',
      pageConfigs: [],
      currentTab: '',
      chartType: '',
      chartConfig: {},
      chartData: [],
      tableData: [],
      tableColumn: [],
      tableMap: [],
      mapping_column: '',
      tableVisibles: [],
      // 确保筛选项数据准备完成
      isReady: false,
      currentKanban: {}
    };
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getBiViewConfig();
      },
      immediate: true
    },
    biFiltersObj(val) {
      // 当筛选器里面有部门选择器时，部门选择器默认选中触发filtes变化比bi_view_config早时，ids=[]不会发起请求，
      // 此时设置isReady标识值标识筛选器已是最新，待bi_view_config请求到结果时再发起请求
      this.isReady = true;
      if (val.tree_user_ids) {
        this.isReady = false;
      }
      // 在这里发送请求
      if (this.pageType === 'tabsPage' && !this.currentTab) return;
      if (this.currentKanban.bi_chart_id) {
        this.getChartData([this.currentKanban.bi_chart_id]);
      }
      const ids = this.getCurrentChartIds(this.currentTab);
      this.getChartData(ids);
    },
    // watch当前选中的tab,点击哪个tab请求哪个tab的数据
    currentTab() {
      // 在这里发送请求
      const ids = this.getCurrentChartIds(this.currentTab);
      if (this.isReady && ids.length) this.getChartData(ids);
    },
    currentKanban(val) {
      if (this.isReady && val) {
        this.getChartData([val.bi_chart_id]);
      }
    }
  },
  methods: {
    async getBiViewConfig() {
      const res = await (0, _businessIntelligence.getBiViewConfig)({
        web_route: this.$route.fullPath,
        workspace_id: this.workspaceInfo.id
      });
      this.isLoading = false;
      if (res.code === 200) {
        const {
          charts,
          tabs,
          kanban
        } = res.results;
        if (kanban && this.$route.path === _routeMap.ROUTES.biTalentReview) {
          this.getKanbanData(kanban);
        } else {
          // charts和tabs不共存: 当有charts时不渲染tab, 当有tabs时渲染tab
          if (charts.length) {
            this.getChartsData(charts);
          }
          if (tabs.length) {
            this.getTabsData(tabs);
          }
        }
      }
    },
    getKanbanData(kanban) {
      // 当存在看板时就不渲染其他类型的页面了
      this.isShowKanban = true;
      this.kanbanList = kanban;
      if (kanban.length) {
        this.currentKanban = this.kanbanList[0];
      }
    },
    getChartsData(charts) {
      this.pageType = 'singlePage';
      this.pageConfigs = charts;
      // 判断筛选器是否已是最新的筛选器，防止发请求在filters更新之前而导致的参数错误
      if (this.isReady) {
        const ids = this.getCurrentChartIds(this.currentTab);
        this.getChartData(ids);
      }
    },
    getTabsData(tabs) {
      var _this$pageConfigs$;
      this.pageType = 'tabsPage';
      this.pageConfigs = tabs;
      this.currentTab = (_this$pageConfigs$ = this.pageConfigs[0]) === null || _this$pageConfigs$ === void 0 ? void 0 : _this$pageConfigs$.tab_name;
      this.$nextTick(() => {
        const dynamicTab = this.$refs['dynamicTab'];
        if (dynamicTab) {
          dynamicTab.currentSelectTab = this.currentTab;
        }
      });
    },
    isObjSame(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    async getChartData(ids) {
      // 这里循环请求
      ids.forEach(async (item, index) => {
        var _chartItem$chartConfi, _chartItem$chartConfi2;
        const params = this.getParams();
        /** @varriable prevParamsMap 避免前面请求覆盖当前请求 */
        prevParamsMap.set(item, (0, _commonFunc.deepClone)(params));
        this.isLoading = true;
        (0, _timezone.convertParams2UTCplus8)(params.custom_filters);
        const res = await (0, _businessIntelligence.getChartById)({
          ...params,
          chart_id: item,
          time_zone: this.timezone
        });
        if (!this.isObjSame(prevParamsMap.get(item), params)) return;
        if (res.code !== 200) {
          if (res.code === 42707) {
            this.getBiViewConfig();
          }
          return;
        }
        const {
          type: chartType,
          configs: chartConfig
        } = res.results.charts;
        const tableData = res.results.table;
        const {
          heads,
          rows,
          is_sampled,
          total_count
        } = tableData;
        this.chartType = chartType;
        const chartItem = {
          chartType: this.chartType
        };
        chartItem.chartConfig = chartConfig;

        // 用于提示是否全量数据
        chartItem.chartConfig.is_sampled = is_sampled;
        chartItem.chartConfig.total_count = total_count;

        // 图表数据
        this.chartType === 'bar' && this.barChartData(chartItem, chartConfig, heads, rows);
        this.chartType === 'pie' && this.pieChartData(chartItem, heads, rows);
        this.chartType === 'scatter' && this.scatterTableData(tableData, chartItem, rows, heads);

        // 对mapping映射数据进行格式化
        if ((_chartItem$chartConfi = chartItem.chartConfig) !== null && _chartItem$chartConfi !== void 0 && (_chartItem$chartConfi2 = _chartItem$chartConfi.mapping_config) !== null && _chartItem$chartConfi2 !== void 0 && _chartItem$chartConfi2.map_list) {
          const mappingConfig = chartItem.chartConfig.mapping_config;
          mappingConfig.mapping = this.formateMapping(mappingConfig.map_list);
        }
        // 所有请求的结果按照对象数组的方式存储
        this.$set(this.chartData, index, chartItem);
        this.isLoading = false;
      });
    },
    sortBar(rows, sortIndex) {
      rows.sort((a, b) => a[sortIndex] - b[sortIndex]);
    },
    barChartData(chartItem, chartConfig, heads, rows) {
      const key = chartConfig.xAxis.type === 'value' ? chartConfig.xAxis.name : chartConfig.yAxis.name;
      this.sortBar(rows, heads.indexOf(key));
      chartItem.chartData = [heads, ...rows];
    },
    pieChartData(chartItem, heads, rows) {
      const arr = heads.map((item, index) => {
        return {
          name: item,
          value: rows[0][index]
        };
      });
      chartItem.chartData = arr;
    },
    scatterTableData(tableData, chartItem, rows, heads) {
      chartItem.tableConfigs = {
        ...tableData
      };
      chartItem.chartData = [heads, ...rows];
      if (!chartItem.tableConfigs.column_config.length) return;
      chartItem.tableConfigs.column_config.forEach((_ref, index) => {
        let {
          type,
          mapping
        } = _ref;
        if (type === 'mapping') {
          chartItem.tableConfigs.column_config[index].mapping = this.formateMapping(mapping);
        }
      });
    },
    formateMapping(mapList) {
      const mappingObj = {};
      mapList.forEach(item => {
        mappingObj[item.column_value] = item;
      });
      return mappingObj;
    },
    getCurrentChartIds(currentTab) {
      const ids = [];
      this.pageConfigs.forEach(item => {
        if (this.pageType === 'tabsPage') {
          if (item.tab_name === currentTab) {
            item.charts.forEach(item => {
              ids.push(item.bi_chart_id);
            });
          }
        } else {
          ids.push(item.bi_chart_id);
        }
      });
      return ids;
    },
    getParams() {
      if (this.biFiltersObj.tree_user_ids) {
        return {
          ...this.biFiltersObj,
          ...this.biFiltersObj.tree_user_ids
        };
      }
      return {
        ...this.biFiltersObj,
        workspace_id: this.workspaceInfo.id
      };
    }
  }
};
exports.default = _default;