"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "assist-button"
  }, [_c("el-badge", {
    staticClass: "assist-btn",
    attrs: {
      value: _vm.assistUnreadCount,
      max: 99,
      hidden: !Boolean(_vm.assistUnreadCount)
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "助手",
      "popper-class": "sidebar-bottom-button-tooltip",
      placement: "bottom",
      "hide-after": 1000
    }
  }, [_c("div", {
    staticClass: "img-button-box"
  }, [_c("img", {
    staticClass: "assist-img",
    attrs: {
      src: require("@/assets/assist/assistant_btn.png"),
      alt: "助手按钮"
    },
    on: {
      click: _vm.openAssistantDrawer
    }
  })])])], 1), _c("el-drawer", {
    attrs: {
      visible: _vm.assistantDrawerVisible,
      direction: "rtl",
      "before-close": _vm.closeDrawer,
      "custom-class": "sidebar-assist-drawer",
      size: "600px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.assistantDrawerVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "title-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("lottie-animation", {
    staticClass: "animation-img",
    attrs: {
      "animation-data": require("@/assets/assist/data.json"),
      loop: true,
      "auto-play": true
    }
  }), _c("h5", [_vm._v("助手麦麦")])], 1), _c("assist-recommend", {
    key: _vm.assistKey
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;