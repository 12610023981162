"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "manage-view-list"
  }, [_c("manage-view-list-filter", {
    on: {
      filterChange: _vm.filterChange
    }
  }), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.taskList.length && _vm.taskListLoading,
      expression: "!taskList.length && taskListLoading"
    }],
    staticClass: "task-list-container"
  }, [!_vm.taskList.length ? _c("div", {
    staticClass: "no-task-list"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-fill"
  }), _c("p", [_vm._v(_vm._s(_vm.$t("coaching.noTaskCurrently")))])]) : _c("div", {
    staticClass: "task-list"
  }, [_c("mg-infinite-scroll", {
    attrs: {
      nomore: _vm.noMore,
      disabled: _vm.disabled,
      loading: _vm.taskListLoading && !!_vm.taskList.length
    },
    on: {
      loadMore: _vm.getManageViewTaskList
    }
  }, _vm._l(_vm.taskList, function (card, i) {
    return _c("task-card", {
      key: i,
      attrs: {
        "card-item": card,
        "current-menu": "manageView"
      },
      on: {
        goTaskDetail: _vm.goTaskDetail,
        operateTask: function ($event) {
          return _vm.handleOperateTask(_vm.item, $event);
        }
      }
    });
  }), 1)], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;