"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "aside-top"
  }, [_vm._l(_vm.operationList, function (_ref) {
    let {
      type,
      label,
      icon
    } = _ref;
    return _c("el-button", {
      key: type,
      staticClass: "mg-button text-button el-button-text",
      class: {
        active: _vm.value === type
      },
      on: {
        click: function ($event) {
          return _vm.changeType(type);
        }
      }
    }, [_c("i", {
      class: `iconfont ${icon}`
    }), _vm._v(_vm._s(label) + " "), type === "task" && _vm.taskCount ? _c("span", {
      staticClass: "task-count"
    }, [_vm._v(" " + _vm._s(_vm.taskCount > 99 ? "99+" : _vm.taskCount))]) : _vm._e()]);
  }), _c("el-divider"), _c("div", {
    staticClass: "operate-part"
  }, [_vm._m(0), _c("add-folder-dropdown", {
    on: {
      create: function ($event) {
        return _vm.$emit($event);
      }
    }
  })], 1), _c("snippets-library-search", {
    on: {
      search: function ($event) {
        return _vm.$emit("search", $event);
      }
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_c("i", {
    staticClass: "iconfont icon-catalogue"
  }), _vm._v("目录")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;