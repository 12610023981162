"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _compliance = require("@/api/compliance");
var _EvaluationTable = _interopRequireDefault(require("./EvaluationTable.vue"));
var _EvaluatePreviewDialog = _interopRequireDefault(require("./EvaluatePreviewDialog"));
var _EvaluationFilterGroup = _interopRequireDefault(require("./EvaluationFilterGroup.vue"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EvaluationTableContent',
  components: {
    NoData: _NoData.default,
    EvaluationTable: _EvaluationTable.default,
    EvaluatePreviewDialog: _EvaluatePreviewDialog.default,
    EvaluationFilterGroup: _EvaluationFilterGroup.default
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    noFilter: {
      type: Boolean,
      default: true
    },
    maxCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      filtersObj: {
        page: 1,
        size: 20,
        sort_by: ['create_at', 'descending']
      },
      previewDialogVisible: false,
      schema: {},
      previewLoading: false
    };
  },
  methods: {
    previewEvaluation(row) {
      this.getEvaluationTemplate(row);
      this.previewDialogVisible = true;
    },
    async getEvaluationTemplate(row) {
      this.previewLoading = true;
      const res = await (0, _compliance.getEvaluationTemplate)({
        template_id: row.id
      }).finally(() => {
        this.previewLoading = false;
      });
      if (res.code === 20000) {
        const compare = p => {
          return function (m, n) {
            var a = m[p];
            var b = n[p];
            return a - b;
          };
        };
        const sortField = fields => {
          const localField = (0, _cloneDeep.default)(fields);
          localField.sort(compare('field_order'));
          return localField;
        };
        sortField(res.results.fields);
        this.schema = res.results;
      }
    },
    createEvaluation() {
      if (this.maxCount <= this.total) {
        this.$message.warning('评价单数量已达上限');
        return;
      }
      this.$router.push({
        path: _routeMap.ROUTES.complianceEditEvaluation,
        query: {
          type: 'create'
        }
      });
    },
    copyEvaluationTemplate(row) {
      if (this.maxCount <= this.total) {
        this.$message.warning('评价单数量已达上限');
        return;
      }
      this.$router.push({
        path: _routeMap.ROUTES.complianceEditEvaluation,
        query: {
          type: 'copy',
          id: row.id
        }
      });
    },
    sizeChange(size) {
      this.filtersObj.size = size;
      this.filterChange();
    },
    currentChange(page) {
      this.filtersObj.page = page;
      this.filterChange();
    },
    sortChange(prop, order) {
      this.filtersObj.sort_by = [prop, order];
      this.filterChange();
    },
    filterChange(filter) {
      Object.assign(this.filtersObj, filter);
      this.$emit('filterChange', this.filtersObj);
    }
  }
};
exports.default = _default;