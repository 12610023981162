"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "admin-role-drawer"
  }, [_c("el-drawer", {
    attrs: {
      title: _vm.getTitle(),
      visible: _vm.roleDrawerVisible,
      "before-close": _vm.handleClose,
      "wrapper-closable": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.roleDrawerVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.drawerLoading,
      expression: "drawerLoading"
    }],
    staticClass: "add-role"
  }, [_c("div", {
    staticClass: "name-input item"
  }, [_c("el-form", {
    ref: "adminRoleForm",
    staticClass: "role-form",
    attrs: {
      model: _vm.adminRoleForm,
      rules: _vm.rules
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name"
    }
  }, [_c("span", {
    staticClass: "sub-title"
  }, [_vm._v("名称")]), _c("el-input", {
    attrs: {
      type: "text",
      placeholder: "请输入角色名称",
      maxlength: "40",
      "show-word-limit": ""
    },
    on: {
      input: function ($event) {
        _vm.adminRoleForm.name = $event.replace(/\s+/g, "");
      }
    },
    model: {
      value: _vm.adminRoleForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.adminRoleForm, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "adminRoleForm.name"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "permissions-profile"
  }, [_c("span", {
    staticClass: "sub-title"
  }, [_vm._v("权限配置")]), _c("permissions-tree", {
    ref: "pageTree",
    attrs: {
      permissions: _vm.permissions,
      "checked-list": _vm.checkedList,
      "func-role-drawer-visible": _vm.roleDrawerVisible
    },
    on: {
      handleCheckChange: _vm.handleCheckChange
    }
  })], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      type: "primary",
      disabled: !_vm.checkedList.length
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;