"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FieldEditForm = _interopRequireDefault(require("@/components/DynamicForm/FieldEditForm.vue"));
var _TransfereeDialog = _interopRequireDefault(require("@/views/convDetail/components/TransfereeDialog.vue"));
var _TransferConversationDialog = _interopRequireDefault(require("@/components/TransferConversationDialog"));
var _videoDetail = require("@/api/videoDetail");
var _elementUi = require("element-ui");
var _vue = require("vue");
var _validate = require("@/utils/validate");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConversationInfoContainer',
  props: {
    conversationDetailInfo: {
      type: Object,
      default: () => ({})
    }
  },
  emits: 'update:conversationDetailInfo',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const canChangeHost = (0, _vue.computed)(() => props.conversationDetailInfo.can_edit && props.conversationDetailInfo.can_change_host);
    const formDesc = (0, _vue.computed)(() => {
      const {
        name,
        date,
        participants,
        tree_info,
        workspace,
        custom_fields
      } = props.conversationDetailInfo;
      const hostSalesman = participants.find(item => item.is_host); // 会话归属人
      const desc1 = [{
        id: 'name',
        label: '会话名称',
        type: 'text',
        default: name,
        required: true
      }, {
        id: 'date',
        label: '会话时间',
        type: 'datetime',
        default: date,
        required: true,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        rule: [{
          required: true,
          message: '请选择会话时间',
          trigger: 'change'
        }, {
          validator: _validate.validateTimeBeforeNow,
          trigger: 'change'
        }]
      }, {
        id: 'host_salesman',
        label: '归属人',
        type: '',
        default: hostSalesman
      }, {
        id: 'tree_info',
        label: '归属部门',
        type: 'department',
        default: tree_info,
        options: []
      }, {
        id: 'workspace',
        label: '所属空间',
        type: 'workspace',
        default: workspace,
        options: []
      }];
      const customFieldsDesc = custom_fields.map(item => {
        const {
          field_name,
          name,
          from_type,
          value,
          is_require,
          options,
          ...etc
        } = item;
        return {
          id: field_name,
          label: name,
          type: from_type,
          default: value,
          required: !!is_require,
          options,
          ...etc
        };
      });
      return [...desc1, ...customFieldsDesc];
    });
    const formModel = {};
    const onFieldChangeSucceed = async (field, value) => {
      const isCustomField = getIsCustomField(field);
      const res = await (0, _videoDetail.editConvInfo)({
        conversation_id: props.conversationDetailInfo.id,
        field_name: field,
        value,
        is_custom_field: isCustomField
      });
      if (res.code === 20000) {
        updateConversationDetailInfo(field, value);
      }
    };
    function updateConversationDetailInfo(field, value) {
      const isCustomField = getIsCustomField(field);
      _elementUi.Message.success('保存成功');
      emit('update:conversationDetailInfo', field, value, isCustomField);
    }
    function getIsCustomField(field) {
      return !['name', 'date', 'host_salesman', 'tree_info', 'workspace'].includes(field);
    }
    const transfereeDialogVisible = (0, _vue.ref)(false);
    const openTransfereeDialog = () => {
      if (canChangeHost.value) {
        transfereeDialogVisible.value = true;
      }
    };
    const onTransferSubmit = user => {
      updateConversationDetailInfo('host_salesman', user);
    };
    const transferConversationDialogVisible = (0, _vue.ref)(false);
    const transferConversationDialog = (0, _vue.ref)();
    const transferConversation = async (targetWorkspaceId, workspaceInfo) => {
      const data = {
        conversation_id: props.conversationDetailInfo.id,
        workspace_id: targetWorkspaceId
      };
      try {
        const res = await (0, _videoDetail.convDetailTransferWorkspace)(data);
        if (res.code === 200) {
          transferConversationDialogVisible.value = false;
          (0, _elementUi.Message)({
            message: '转移成功',
            type: 'success'
          });
          updateConversationDetailInfo('workspace', workspaceInfo);
        }
      } finally {
        transferConversationDialog.value.endButtonLoading();
      }
    };
    const openTransferWorkspace = () => {
      if (props.conversationDetailInfo.can_edit) {
        transferConversationDialogVisible.value = true;
      }
    };
    return {
      __sfc: true,
      props,
      canChangeHost,
      emit,
      formDesc,
      formModel,
      onFieldChangeSucceed,
      updateConversationDetailInfo,
      getIsCustomField,
      transfereeDialogVisible,
      openTransfereeDialog,
      onTransferSubmit,
      transferConversationDialogVisible,
      transferConversationDialog,
      transferConversation,
      openTransferWorkspace,
      FieldEditForm: _FieldEditForm.default,
      TransfereeDialog: _TransfereeDialog.default,
      TransferConversationDialog: _TransferConversationDialog.default
    };
  }
};
exports.default = _default;