"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      visible: _vm.visible,
      size: "90%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "header-line"
        }, [_c("h1", {
          staticClass: "title-text"
        }, [_vm._v("效果预览")]), _c("div", [_c("span", {
          staticClass: "label"
        }, [_vm._v("模型版本")]), _c(_setup.ModelVersionSelect, {
          staticClass: "version-select",
          attrs: {
            "model-id": _setup.modelInfo.id,
            "show-status": false
          },
          on: {
            onChange: _setup.handleSizeChange
          },
          model: {
            value: _setup.modelVersion,
            callback: function ($$v) {
              _setup.modelVersion = $$v;
            },
            expression: "modelVersion"
          }
        })], 1), _setup.testProgress === 1 ? [_c("el-divider", {
          attrs: {
            direction: "vertical"
          }
        }), _c("div", [_c("span", {
          staticClass: "target-label"
        }, [_vm._v("精：")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_setup.num2percent(_setup.resultsValue.precision)))]), _c("span", {
          staticClass: "target-label"
        }, [_vm._v("召：")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_setup.num2percent(_setup.resultsValue.recall)))]), _c("span", {
          staticClass: "target-label"
        }, [_vm._v("F1：")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_setup.num2percent(_setup.resultsValue.f1)))])]), _c("el-divider", {
          attrs: {
            direction: "vertical"
          }
        }), _c("div", {
          staticClass: "operate"
        }, [_c("span", [_vm._v("如果效果不佳，可以")]), _c("el-link", {
          attrs: {
            type: "primary",
            underline: false
          },
          on: {
            click: _setup.reValidData
          }
        }, [_vm._v("重新校验数据")]), _c("span", [_vm._v("或")]), _c("el-link", {
          attrs: {
            type: "primary",
            underline: false
          },
          on: {
            click: _setup.reCreateTrianTask
          }
        }, [_vm._v("重新训练")])], 1)] : _vm._e()], 2)];
      },
      proxy: true
    }])
  }, [_setup.testProgress !== 1 ? _c("div", {
    staticClass: "model-trail-progress"
  }, [_c("h1", {
    staticClass: "progress-title"
  }, [_vm._v("模型预测中")]), _c("div", {
    staticClass: "row"
  }, [_c("el-progress", {
    attrs: {
      percentage: Math.round(_setup.testProgress * 100)
    }
  })], 1)]) : _c("div", {
    staticClass: "content-container"
  }, [_setup.total ? [_setup.total ? _c("MgvInfoAlert", {
    staticClass: "info-alert",
    attrs: {
      "alert-key": "trail-dataset-drawer"
    }
  }, [_vm._v("模型预测错的文本句，如果是测试集标注有问题，可以直接点击单元格修正，如果是训练集标注有问题，可以“修正训练集”。")]) : _vm._e(), _c("div", {
    staticClass: "result-table"
  }, [_c(_setup.TrailResultsHeader, {
    attrs: {
      "table-selection": _setup.tableSelection
    },
    on: {
      updateTrainDataStatus: _setup.updateTrainDataStatus,
      clearAnnotationResult: _setup.clearAnnotationResult,
      filterChange: _setup.filterChange
    }
  }), _c(_setup.TrailResultTable, {
    attrs: {
      data: _setup.resultsTableData,
      "dataset-id": _setup.datasetId,
      "table-height": _setup.tableHeight,
      "start-index": _setup.pageSize * (_setup.currentPage - 1) + 1,
      "dataset-stats": _setup.datasetStats
    },
    on: {
      "selection-change": _setup.onSelectionChange,
      annotateMark: _setup.updateTrainDataStatus,
      clearAnnotationResult: _setup.clearAnnotationResult,
      fixTrainData: _setup.openFixTrainDatasetDialog,
      clickModelSentence: _setup.openTrailDataDetailDialog
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      total: _setup.filterTotal,
      "page-size": _setup.pageSize,
      "page-sizes": [100, 200, 400],
      "current-page": _setup.currentPage
    },
    on: {
      "update:pageSize": function ($event) {
        _setup.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _setup.pageSize = $event;
      },
      "update:currentPage": function ($event) {
        _setup.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _setup.currentPage = $event;
      },
      "size-change": _setup.handleSizeChange,
      "current-change": _setup.getModelTestResultTable
    }
  })], 1), _c(_setup.FixTrainDatasetDialog, {
    attrs: {
      visible: _setup.fixTrainDatasetDialogVisible,
      "results-row-info": _setup.currentResultsRowInfo
    },
    on: {
      "update:visible": function ($event) {
        _setup.fixTrainDatasetDialogVisible = $event;
      }
    }
  }), _c(_setup.TrailDataDetailDialog, {
    attrs: {
      id: _setup.currentTrailDataDetailId,
      "praf-task-id": _setup.prafTaskId,
      "dataset-id": _setup.datasetId,
      "can-annotate": false,
      visible: _setup.dialogVisible,
      ids: _setup.trailDataIds
    },
    on: {
      "update:visible": function ($event) {
        _setup.dialogVisible = $event;
      },
      clearAnnotationResult: _setup.clearAnnotationResult,
      updateTableListAnnotationStatus: _setup.getModelTestResultTable
    }
  })] : _c("div", {
    staticClass: "no-sentence-box"
  }, [_c("i", {
    staticClass: "iconfont icon-warning"
  }), _c("h1", {
    staticClass: "no-sentence-box-title"
  }, [_vm._v("没有找到命中")]), _c("p", [_vm._v("可能是会话数量较少或事件比较稀疏")]), _c("p", {
    staticClass: "row"
  }, [_c("span", [_vm._v("您可以")]), _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: _setup.reCreateTrianTask
    }
  }, [_vm._v("重新预测")]), _c("span", [_vm._v("或")]), _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: _setup.reValidData
    }
  }, [_vm._v("重新校验训练数据")]), _c("span", [_vm._v("训练模型")])], 1)])], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;