"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "infinite-scroll-disabled": _vm.scrollDisabled || _vm.disabled,
      "infinite-scroll-distance": _vm.distance,
      "infinite-scroll-immediate": _vm.immediate,
      "infinite-scroll-delay": _vm.delay
    }
  }, [_vm._t("default"), _vm.loading ? _c("p", {
    staticClass: "loading-text"
  }, [_vm._v("加载中...")]) : _vm._e(), !_vm.hasMore && !_vm.loading ? _c("p", {
    staticClass: "loading-text"
  }, [_vm._v(_vm._s(_vm.nomoreText))]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;