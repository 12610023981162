"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _fetchRequest = _interopRequireDefault(require("@/utils/fetch-request"));
var _videoDetail = require("@/api/videoDetail");
var _commonFunc = require("@/utils/commonFunc");
var _ChatTop = _interopRequireDefault(require("./components/ChatTop.vue"));
var _ChatIntro = _interopRequireDefault(require("./components/ChatIntro.vue"));
var _ChatMessage = _interopRequireDefault(require("./components/ChatMessage.vue"));
var _ChatGuide = _interopRequireDefault(require("./components/ChatGuide.vue"));
var _ChatShortcuts = _interopRequireDefault(require("./components/ChatShortcuts.vue"));
var _ChatAgreement = _interopRequireDefault(require("./components/ChatAgreement.vue"));
var _NotSupport = _interopRequireDefault(require("./components/NotSupport.vue"));
var _ChatInput = _interopRequireDefault(require("./components/ChatInput.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChatUnit',
  components: {
    ChatTop: _ChatTop.default,
    ChatIntro: _ChatIntro.default,
    ChatMessage: _ChatMessage.default,
    ChatGuide: _ChatGuide.default,
    ChatShortcuts: _ChatShortcuts.default,
    ChatAgreement: _ChatAgreement.default,
    NotSupport: _NotSupport.default,
    ChatInput: _ChatInput.default
  },
  props: {
    promptConfig: {
      type: Object,
      default: () => ({
        guideList: [],
        shortcutsList: [],
        guideTips: ''
      })
    },
    requestConfig: {
      type: Object,
      default: () => ({
        getQuestionParams: {},
        getHistoryParams: {}
      })
    },
    checkIsSupport: {
      type: Boolean,
      default: false
    },
    messageOperateList: {
      type: Array,
      default: () => []
    },
    isShowChatInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msgList: [],
      loading: false,
      showStopReply: false,
      showAskAgain: false,
      isAnswering: false,
      cursor: 0,
      isNoMore: false,
      hasHistory: false,
      isSupport: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['isConfirmChatAgreement'])
  },
  watch: {
    'requestConfig.getHistoryParams': {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  async created() {
    if (!this.isConfirmChatAgreement) return;
    this.getData();
  },
  methods: {
    async getData() {
      if (this.checkIsSupport) {
        await this.checkConvSize();
      }
      if (this.checkIsSupport && !this.isSupport) return;
      this.getChatHistory();
    },
    async getChatHistory() {
      let isPull = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      // isPull为true: 下拉刷新时的请求数据；isPull为false: 进入页面时的请求数据
      this.loading = true;
      const pageSize = 20;
      const {
        getHistoryParams
      } = this.requestConfig;
      const res = await (0, _videoDetail.getChatHistory)({
        cursor: this.cursor,
        size: pageSize,
        ...getHistoryParams
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          cursor,
          data
        } = res.results;
        if (!data.length) {
          this.isNoMore = true;
          return;
        }
        this.cursor = cursor;
        const list = data.map(i => ({
          ...i,
          status: 'done'
        }));
        this.msgList.unshift(...list);
        if (isPull) {
          this.scrollToMessageByOrder(list.length);
        } else {
          this.hasHistory = !!list.length;
          if (this.msgList.length < pageSize) {
            this.isNoMore = true;
          }
          this.scrollToBottom();
        }
      }
    },
    getReverseList() {
      return [...this.msgList].reverse();
    },
    handleListScroll: (0, _commonFunc.throttle)(function (e) {
      const {
        scrollTop
      } = e.target;
      if (!scrollTop && !this.isNoMore) {
        this.getChatHistory(true);
      }
    }, 300),
    createQA: (0, _commonFunc.throttle)(function (msg) {
      if (this.isAnswering) {
        this.$message.warning('一个问题回答完成后，可发送下一个问题');
        return;
      }
      this.sendMsg(msg);
      this.appendReply();
      this.scrollToBottom();
    }, 300),
    sendMsg(msg) {
      this.msgList.push({
        type: 'question',
        msg
      });
    },
    appendReply() {
      const isOnline = window.navigator.onLine;
      const msg = isOnline ? '' : '不好意思网络中断了';
      const status = isOnline ? 'loading' : 'done';
      this.msgList.push({
        type: 'answer',
        msg,
        status
      });
      if (!isOnline) {
        this.showAskAgain = true;
        return;
      }
      this.showStopReply = true;
      this.showAskAgain = false;
      this.getReplyContent();
    },
    async getReplyContent() {
      var _this$getReverseList$;
      const reply = this.getReverseList().find(i => i.type === 'answer');
      const question = (_this$getReverseList$ = this.getReverseList().find(i => i.type === 'question')) === null || _this$getReverseList$ === void 0 ? void 0 : _this$getReverseList$.msg;
      this.isAnswering = true;
      const {
        getQuestionParams
      } = this.requestConfig;
      const res = await (0, _fetchRequest.default)('/webapi/ai/qa/ask', {
        method: 'POST',
        body: JSON.stringify({
          question,
          ...getQuestionParams
        })
      });

      // eslint-disable-next-line no-undef
      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
      console.log('res reader', res, reply.status !== 'cancel');
      while (reply.status !== 'cancel') {
        const {
          done,
          value
        } = await reader.read();
        if (done) {
          reply.status = 'done';
          this.showStopReply = false;
          this.showAskAgain = true;
          this.isAnswering = false;
          this.scrollToBottom();
          break;
        }
        reply.msg += value;
        (0, _commonFunc.debounce)(this.scrollToBottom(), 1000);
      }
    },
    stopReply() {
      const reply = this.getReverseList().find(i => i.type === 'answer');
      if (reply.status === 'loading' && !reply.msg) {
        reply.msg = '回答中止';
      }
      reply.status = 'cancel';
      this.isAnswering = false;
      this.showStopReply = false;
      this.showAskAgain = true;
    },
    askAgain() {
      var _this$getReverseList$2;
      const msg = (_this$getReverseList$2 = this.getReverseList().find(i => i.type === 'question')) === null || _this$getReverseList$2 === void 0 ? void 0 : _this$getReverseList$2.msg;
      this.createQA(msg);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const listRef = this.$refs.list;
        if (!listRef) return;
        const {
          clientHeight,
          scrollHeight
        } = listRef;
        if (scrollHeight === clientHeight) return; // 内容没超出时不需滚动到底部
        listRef.scrollTo({
          top: scrollHeight
        });
      });
    },
    scrollToMessageByOrder(order) {
      this.$nextTick(() => {
        const messageRef = this.$refs[`message_item_${order}`];
        if (!messageRef) return;
        const {
          offsetTop
        } = messageRef[0].$el;
        this.$refs.list.scrollTo({
          top: offsetTop - 40
        });
      });
    },
    handleConfirm() {
      this.getData();
    },
    async checkConvSize() {
      const {
        checkIsSupportParams
      } = this.requestConfig;
      this.loading = true;
      const res = await (0, _videoDetail.checkConvSize)(checkIsSupportParams).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.isSupport = res.results.is_passed;
      }
    }
  }
};
exports.default = _default;