"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "appoint-form"
  }, [_c("back-btn", {
    on: {
      backTo: function ($event) {
        return _vm.$emit("backTo");
      }
    }
  }), _c("div", {
    staticClass: "form-container"
  }, [_c("h1", [_vm._v("预约注册")]), _c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.desc,
      "label-position": "top"
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c("el-button", {
    staticClass: "submit-btn",
    attrs: {
      loading: _vm.btnLoading,
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;