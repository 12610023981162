"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-item-tricks"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "scene-item"
    }, [item.scene_content ? _c("div", {
      staticClass: "tricks-scene-container"
    }, [_c("tricks-scene", {
      attrs: {
        "display-only": "",
        value: item.scene_content
      }
    })], 1) : _vm._e(), item.reply_list && item.reply_list.length ? _c("div", {
      staticClass: "trick-list"
    }, [_c("mg-draggable", {
      on: {
        end: function ($event) {
          return _vm.handleDragEnd(item);
        }
      },
      model: {
        value: item.reply_list,
        callback: function ($$v) {
          _vm.$set(item, "reply_list", $$v);
        },
        expression: "item.reply_list"
      }
    }, _vm._l(item.reply_list, function (reply, replyIndex) {
      return _c("trick-item", _vm._g(_vm._b({
        key: replyIndex,
        attrs: {
          reply: reply
        }
      }, "trick-item", _vm.$attrs, false), _vm.$listeners), [_c("div", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.showDragIcon(item.reply_list),
          expression: "showDragIcon(item.reply_list)"
        }],
        staticClass: "drag-container"
      }, [_c("i", {
        staticClass: "iconfont move icon-drag-dot"
      })]), reply.reply_record_standard_url ? _c("PlayVoice", {
        attrs: {
          slot: "voice-play-icon",
          "is-play": _vm.currentTemplateVoiceId === reply.reply_id
        },
        on: {
          play: function ($event) {
            return _vm.play(reply.reply_id, reply.reply_record_standard_url, reply.duration);
          },
          pause: _vm.pause
        },
        slot: "voice-play-icon"
      }) : _vm._e()], 1);
    }), 1)], 1) : _c("div", {
      staticClass: "no-trick"
    }, [_vm._v("请添加话术")])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;