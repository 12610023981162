"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("figure", {
    ref: "commentBox",
    staticClass: "comment-box",
    class: {
      "is-reply": _vm.isReply,
      "is-new": _vm.activeCommentId === _vm.comment.comment_id,
      "gradual-highlight": _vm.comment.highlight
    },
    attrs: {
      id: "comment-box" + _vm.comment.comment_id
    }
  }, [!_vm.comment.advise ? _c("image-avatar", {
    staticClass: "el-avatar",
    attrs: {
      avatar: _vm.comment.sender_avatar,
      name: _vm.comment.sender_name
    }
  }) : _c("div", {
    staticClass: "help-avatar"
  }, [_c("image-avatar", {
    staticClass: "el-avatar",
    attrs: {
      avatar: _vm.comment.sender_avatar,
      name: _vm.comment.sender_name
    }
  }), _vm.comment.advise === "seeker" ? _c("i", {
    staticClass: "iconfont icon-hand-2"
  }) : _vm._e(), _vm.comment.advise === "advisor" ? _c("i", {
    staticClass: "iconfont icon-suggest"
  }) : _vm._e()], 1), _c("figcaption", {
    staticClass: "content",
    on: {
      click: function ($event) {
        return _vm.dropVideo(_vm.comment.comment_timestamp, false);
      }
    }
  }, [_c("div", {
    staticClass: "name-row"
  }, [_c("p", {
    staticClass: "name-part"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.comment.sender_name))]), _vm.comment.advise && !_vm.isReply ? [_c("span", {
    staticClass: "reply"
  }, [_vm._v(_vm._s(_vm.comment.advise === "seeker" ? "向" : "给"))]), _c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.comment.receiver))]), _c("span", {
    staticClass: "reply"
  }, [_vm._v(_vm._s(_vm.comment.advise === "seeker" ? "申请辅导" : "留下辅导建议"))])] : _vm._e(), _vm.isReply ? [_c("span", {
    staticClass: "reply"
  }, [_vm._v(" 回复 ")]), _c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.comment.recipient_name))])] : _c("svg-icon", {
    attrs: {
      "icon-class": _vm.openIcon
    }
  }), _vm.comment.is_edited ? _c("span", {
    staticClass: "is-edited-tag"
  }, [_vm._v("（已编辑）")]) : _vm._e()], 2)]), !_vm.isEdit ? [_c("p", {
    staticClass: "sentence"
  }, [_vm.hasTimestamp ? _c("span", {
    staticClass: "link time",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.dropVideo(_vm.comment.comment_timestamp, true);
      }
    }
  }, [_vm._v(_vm._s(_vm.formatTime))]) : _vm._e(), _c("span", {
    staticClass: "comment-content",
    domProps: {
      innerHTML: _vm._s(_vm.formatCommentContent(_vm.comment.content))
    }
  })]), _vm.comment.img ? _c("PicDisplay", {
    attrs: {
      "pic-list": _vm.comment.img,
      readonly: ""
    }
  }) : _vm._e()] : _c("EditCommentWidget", {
    ref: "editCommentWidgetRef",
    attrs: {
      comment: _vm.comment,
      "entity-id": _vm.$route.query.id - 0,
      "is-reply": _vm.isReply,
      "entity-type": _vm.entityType
    },
    on: {
      cancel: function ($event) {
        _vm.isEdit = false;
      },
      submitSuccess: _vm.onCommentEditSuccess
    }
  }), _vm.comment.proposals ? _c("div", {
    staticClass: "proposals"
  }, _vm._l(_vm.comment.proposals, function (item, idx) {
    return _c("selected-label", {
      key: idx,
      attrs: {
        item: item
      }
    });
  }), 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEdit,
      expression: "!isEdit"
    }],
    staticClass: "comment-bottom"
  }, [_c("div", [_vm.isOnlyDisplay ? _c("span", {
    staticClass: "reply-btn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.replyComment.apply(null, arguments);
      }
    }
  }, [_vm._v(" 回复 ")]) : _vm._e(), _c("span", {
    attrs: {
      "click.stop": "",
      "data-v-name": `comment-${_vm.comment.sender_id === _vm.userId && _vm.isOnlyDisplay ? "isOwnComment" : ""}`
    }
  }, [_vm.comment.sender_id === _vm.userId && _vm.isOnlyDisplay ? _c("el-dropdown", {
    attrs: {
      trigger: "click",
      placement: "bottom-start"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("i", {
    staticClass: "el-icon-more"
  }), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.entityType !== "business" ? _c("el-dropdown-item", {
    attrs: {
      command: "edit"
    }
  }, [_vm._v(" 编辑 ")]) : _vm._e(), _c("el-dropdown-item", {
    staticClass: "delete-item",
    attrs: {
      command: "delete"
    }
  }, [_vm._v(" 删除 ")])], 1)], 1) : _vm._e()], 1)]), _c("span", {
    staticClass: "time-part"
  }, [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.comment.date).format("YYYY-MM-DD HH:mm:ss")))])])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;