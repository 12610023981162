"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "transer-department-finder"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.treeLoading,
      expression: "treeLoading"
    }],
    staticClass: "part-left"
  }, [_c("el-tree", {
    ref: "departmentTree",
    attrs: {
      data: _vm.orgTree,
      "node-key": "id",
      "show-checkbox": "",
      props: _vm.defaultProps,
      "check-on-click-node": "",
      "check-strictly": true,
      "default-expanded-keys": _vm.defaultExpanded,
      "default-checked-keys": _vm.checkedIdList
    },
    on: {
      check: _vm.handleCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node",
          class: {
            "manage-scope-root-department": !data.fid
          }
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": data.path === "001" ? "office-building" : "department"
          }
        }), _vm._v(" " + _vm._s(data.name) + " ")], 1)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "part-right"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" 已选： " + _vm._s(_vm.selection.length) + "个部门 "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selection.length,
      expression: "selection.length"
    }],
    staticClass: "clear-department",
    on: {
      click: _vm.clearDepartment
    }
  }, [_vm._v(" 清空 ")])]), _c("div", {
    staticClass: "department-container"
  }, _vm._l(_vm.selection, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "user-box"
    }, [_c("div", {
      staticClass: "department"
    }, [_c("div", {
      staticClass: "department-name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.super_fid_names && item.super_fid_names.slice(0, -1).length ? _c("div", {
      staticClass: "super-fid-names"
    }, [_vm._v(" " + _vm._s(item.super_fid_names.slice(0, -1).join("/")) + " ")]) : _vm._e()]), _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          return _vm.removeDepartment(item.id);
        }
      }
    })]);
  }), 0)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;