"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const CancelToken = _axios.default.CancelToken;
const cancelTokenMixin = {
  data() {
    return {
      axiosCancelToken: null,
      // cancelToken 实例
      axiosCancelRequest: null // cancel 方法
    };
  },

  created() {
    this.newCancelToken();
  },
  beforeDestroy() {
    // 离开页面前清空所有请求
    this.axiosCancelRequest();
  },
  methods: {
    // 创建新CancelToken
    newCancelToken() {
      this.axiosCancelToken = new CancelToken(c => {
        this.axiosCancelRequest = c;
      });
    }
  }
};
var _default = cancelTokenMixin;
exports.default = _default;