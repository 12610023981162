"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
let chart;
var _default = {
  name: 'BusinessTransPieChart',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loading: Boolean
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 40,
          left: '20%',
          orient: 'vertical'
        },
        label: {
          formatter: '{b}\n{d}%'
        },
        series: {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderWidth: 2
          },
          selectedMode: true,
          select: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0,0,0,0.5)'
            }
          },
          data: []
        }
      },
      selectedDataIndex: 0
    };
  },
  watch: {
    data: {
      handler() {
        this.drawChart();
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.chartResize);
    window.addEventListener('beforeunload', this.clearChart);
  },
  beforeDestroy() {
    this.clearChart();
    window.removeEventListener('resize', this.chartResize);
    window.removeEventListener('beforeunload', this.clearChart);
  },
  methods: {
    initChart() {
      if (!this.$refs['chart']) return;
      chart = echarts.init(this.$refs['chart'], null);
      chart.on('selectchanged', this.handleChartSelectChanged);
      chart.getZr().on('click', this.handleChartClick);
      const {
        not_prob_count,
        prob_count
      } = this.data;
      const data = [{
        name: '不是卡点',
        value: not_prob_count,
        itemStyle: {
          color: '#5abf86'
        }
      }, {
        name: '是卡点',
        value: prob_count,
        itemStyle: {
          color: '#df503a'
        }
      }];
      this.options.series.data = data;
      chart.setOption(this.options);
    },
    handleChartSelectChanged(data) {
      let selected = null;
      if (data.selected.length) {
        const index = data.selected[0].dataIndex[0];
        this.selectedDataIndex = index;
        selected = {
          isStuck: !!data.selected[0].dataIndex[0]
        };
      }
      this.$emit('chartSelectChange', selected);
    },
    handleChartClick(params) {
      if (!params.target) {
        chart && chart.dispatchAction({
          type: 'unselect',
          dataIndex: this.selectedDataIndex
        });
        // this.$emit('chartSelectChange', null);
      }
    },

    drawChart() {
      this.clearChart();
      this.$nextTick(() => {
        this.initChart();
      });
    },
    chartResize() {
      chart && chart.resize();
    },
    clearChart() {
      chart && chart.dispose();
    }
  }
};
exports.default = _default;