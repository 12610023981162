"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tree-select-search-container"
  }, [_c("el-input", {
    ref: "input-container",
    staticClass: "search-input",
    attrs: {
      placeholder: _vm.$t("coaching.search"),
      "prefix-icon": "el-icon-search"
    },
    on: {
      input: _vm.remote
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchValue.length,
      expression: "searchValue.length"
    }],
    staticClass: "el-icon-error",
    attrs: {
      slot: "suffix"
    },
    on: {
      click: function ($event) {
        _vm.searchValue = "";
      }
    },
    slot: "suffix"
  })]), _c("ValueList", _vm._g({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isSearching,
      expression: "isSearching"
    }],
    attrs: {
      "search-value": _vm.searchValue,
      "value-list": _vm.searchOptions,
      loading: _vm.remoteLoading
    },
    scopedSlots: _vm._u([{
      key: "prefix-icon",
      fn: function (_ref) {
        let {
          nodeData
        } = _ref;
        return [_vm.isDepartment || nodeData.isDepartment ? _c("i", {
          staticClass: "iconfont icon-department"
        }) : _vm._e()];
      }
    }, {
      key: "suffix-icon",
      fn: function (_ref2) {
        let {
          nodeData
        } = _ref2;
        return [nodeData.isChecked ? _c("i", {
          staticClass: "el-icon-check"
        }) : _vm._e()];
      }
    }])
  }, _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;