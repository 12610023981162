"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "speak-time-card",
    class: {
      "customer-speak-time-card": _vm.clickCard
    },
    on: {
      click: _vm.selectConv
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.getTooltipText(_vm.item.conversation_type),
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "left-icons"
  }, _vm._l(2, function (index) {
    return _c("i", {
      key: index,
      staticClass: "icon-wrapper"
    }, [_c("svg-icon", {
      staticClass: "conv-icon",
      attrs: {
        "icon-class": _vm.getIconType(_vm.item.conversation_type)
      }
    })], 1);
  }), 0)]), _c("div", {
    staticClass: "right-content"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm.item.origin_type ? _c("img", {
    staticClass: "title-logo",
    attrs: {
      src: _vm.titleLogoSrc,
      alt: "logo"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "title-text",
    on: {
      click: _vm.goConvDetail
    }
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.duration,
      expression: "item.duration"
    }],
    class: ["conv-duration", "text-tag"]
  }, [_vm._v(" " + _vm._s(_vm.getDuration(_vm.item.duration)) + " ")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.entity_status,
      expression: "item.entity_status"
    }],
    class: ["deal-stage", "text-tag"]
  }, [_c("svg-icon", {
    staticClass: "deal-stage-icon",
    attrs: {
      "icon-class": "deal5"
    }
  }), _vm._v(_vm._s(_vm.item.entity_status))], 1)]), _c("member-info", {
    attrs: {
      "member-info": _vm.item
    }
  }), _c("div", {
    staticClass: "key-events"
  }, [_c("div", {
    staticClass: "key-events-title",
    on: {
      click: function ($event) {
        return _vm.openEventsList(_vm.item);
      }
    }
  }, [_c("span", {
    class: {
      "key-events-title-color": _vm.item.event_engine_count
    }
  }, [_vm._v("关键事件")]), _c("span", {
    staticClass: "event-number",
    class: {
      "number-color": _vm.item.event_engine_count
    }
  }, [_vm._v(_vm._s(_vm.item.event_engine_count))])]), _vm.item.event_engines_complete && _vm.item.event_engines.length ? _c("div", {
    staticClass: "event-container"
  }, [_vm._l(_vm.item.event_engines, function (event, index) {
    return _c("div", {
      key: index,
      staticClass: "key-event-list"
    }, [_c("el-button", {
      staticClass: "event-button",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openKeyEventDetailList(event.id, _vm.item);
        }
      }
    }, [_vm._v(" " + _vm._s(event.type) + " "), _c("span", {
      staticClass: "divide-line"
    }, [_vm._v("|")]), _vm._v(" " + _vm._s(event.count) + " ")])], 1);
  }), _c("div", {
    staticClass: "key-event-list",
    on: {
      click: function ($event) {
        return _vm.openEventsList(_vm.item);
      }
    }
  }, [_vm.item.event_more_count ? _c("el-button", {
    staticClass: "event-button"
  }, [_vm._v("+" + _vm._s(_vm.item.event_more_count))]) : _vm._e()], 1)], 2) : _c("div", {
    staticClass: "no-key-event"
  }, [_vm._v("暂无关键事件")])]), _vm.hasAccountLabelPermission ? _c("div", {
    staticClass: "customer-label"
  }, [_c("div", {
    staticClass: "key-events-title"
  }, [_c("span", [_vm._v("客户标签")]), _c("span", {
    staticClass: "event-number"
  }, [_vm._v(_vm._s(_vm.item.tag_count))])]), _vm.item.tag_count ? _c("div", {
    staticClass: "event-container"
  }, _vm._l(_vm.item.tags, function (tag, index) {
    return _c("div", {
      key: index,
      staticClass: "key-event-list"
    }, [_c("el-button", {
      staticClass: "event-button",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openCustomerLabelDetailList(tag, _vm.item);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.value || tag.name) + " "), _c("span", {
      staticClass: "divide-line"
    }, [_vm._v("|")]), _vm._v(" " + _vm._s(tag.count) + " ")])], 1);
  }), 0) : _c("div", {
    staticClass: "no-key-event"
  }, [_vm._v("暂无客户标签")])]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;