"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-header"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_vm.backButton && _vm.hasHistory ? _c("el-button", {
    staticClass: "goback-box hover-show-bg",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "header-left-content"
  }, [_vm._t("title_log"), _c("div", {
    staticClass: "header-left-top"
  }, [_c("div", {
    staticClass: "title-container"
  }, [_vm._t("prefix"), _vm.title ? _c("span", {
    staticClass: "page-subtitle"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("title"), _vm._t("suffix")], 2), _c("div", {
    staticClass: "deader-left-bottom"
  }, [_vm._t("header_left_bottom")], 2)])], 2)], 1), _c("div", {
    staticClass: "deader-right"
  }, [_vm._t("header_right")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;