"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceKanban = _interopRequireDefault(require("@/views/compliance/components/ComplianceKanban"));
var _ComplianceTeamConversationContent = _interopRequireDefault(require("@/views/compliance/teamConversation/components/ComplianceTeamConversationContent"));
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _compliance = require("@/api/compliance");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _RemovedRecordDrawer = _interopRequireDefault(require("../components/RemovedRecordDrawer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceTeamConversation',
  components: {
    ComplianceKanban: _ComplianceKanban.default,
    ComplianceTeamConversationContent: _ComplianceTeamConversationContent.default,
    RemovedRecordDrawer: _RemovedRecordDrawer.default
  },
  mixins: [_complianceTableMixin.default],
  data() {
    return {
      loading: false,
      filters: {
        workspace_id: 0,
        create_time: [],
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        status: 0,
        task_name: '',
        conversation_name: '',
        page: 1,
        size: 20,
        sort_by: [['create_at', 'descending']]
      },
      activeTabIndex: 0,
      tabList: [],
      tableDataList: [],
      total: 0,
      removedRecordDrawerVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          const complianceTeamConversation = localStorage.getItem('ComplianceTeamConversation');
          const tabName = (complianceTeamConversation === '今日会话' ? '全部会话' : complianceTeamConversation) || '全部会话';
          this.$router.replace({
            query: {
              tab: tabName
            }
          });
          await this.getTeamConversationTabCount(val);
          this.handleTabClick(tabName);
        }
      },
      immediate: true
    }
  },
  methods: {
    openRemovedRecordDrawer() {
      this.removedRecordDrawerVisible = true;
    },
    async getTeamConversationTabCount(val) {
      const res = await (0, _compliance.getTeamConversationTabCount)({
        workspace_id: val
      });
      if (res.code === 20000) {
        this.tabList = res.results;
      }
    },
    handleTabClick(tabName) {
      var _this$tabList$this$ac;
      localStorage.setItem('ComplianceTeamConversation', tabName);
      this.filters = this.$options.data().filters;
      this.filters.workspace_id = this.workspaceInfo.id;
      this.activeTabIndex = this.tabList.findIndex(item => item.label === tabName);
      this.filters.status = (_this$tabList$this$ac = this.tabList[this.activeTabIndex]) === null || _this$tabList$this$ac === void 0 ? void 0 : _this$tabList$this$ac.value;
      if (this.filters.workspace_id) {
        this.getComplianceConversation(this.filters);
      }
    },
    handleFiltersChange(filtersObj) {
      Object.assign(this.filters, filtersObj);
      this.getComplianceConversation(this.filters);
    },
    async getComplianceConversation(filters) {
      const requestFilters = (0, _commonFunc.deepClone)(filters);
      (0, _timezone.convertParams2UTCplus8)(requestFilters);
      this.loading = true;
      try {
        const res = await (0, _compliance.getComplianceTeamConversationApi)(requestFilters);
        if (res.code === 20000) {
          const {
            datas,
            total
          } = res.results;
          this.tableDataList = datas;
          this.total = total;
        }
      } finally {
        this.loading = false;
      }
    },
    handleDistribute() {
      this.$router.push({
        path: '/compliance/distribution'
      });
    },
    goComplianceWorkSpace(rowInfo) {
      const obj = (0, _commonFunc.deepClone)(this.filters);
      (0, _timezone.convertParams2UTCplus8)(obj);
      delete obj.page;
      delete obj.size;
      const params = {
        ...obj
      };
      const query = {
        id: rowInfo.conv_id || this.tableData[0].conv_id,
        task_id: rowInfo.task_id,
        sampling_type: !!rowInfo.account_sale_conv_id,
        sampling_id: rowInfo.account_sale_conv_id
      };
      params.status = params.status ? params.status : 0;
      this.$router.push({
        name: 'ComplianceWorkSpace',
        query,
        params
      });
    }
  }
};
exports.default = _default;