"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/mgv-ui/packages/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ValueList',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    },
    valueList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeItem(_ref) {
      let {
        disabled,
        isChecked,
        name,
        id,
        preNode
      } = _ref;
      if (disabled) return;
      this.$emit(isChecked ? 'removeItem' : 'chekedItem', {
        name,
        id,
        preNode
      });
    }
  }
};
exports.default = _default;