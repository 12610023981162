"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _NoteEditor = _interopRequireDefault(require("@/components/NoteEditor"));
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ItemDetail',
  components: {
    ImageAvatar: _ImageAvatar.default,
    eventsList: _eventsList.default,
    NoteEditor: _NoteEditor.default
  },
  props: {
    customerName: {
      type: String,
      default: ''
    },
    detailObj: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      eventEngine: [],
      eventCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName', 'busiActiveConver', 'workspaceInfo']),
    getDuration() {
      return function (duration) {
        const {
          h,
          m,
          s
        } = (0, _timeFormatter.second2HMS)(duration);
        if (h) {
          if (m) {
            return h + 'h ' + m + 'm';
          } else {
            return h + 'h';
          }
        } else {
          if (m) {
            return m + 'm';
          } else {
            return s + 's';
          }
        }
      };
    },
    getTimeText() {
      return function (time) {
        return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
      };
    },
    getBtnIcon() {
      switch (this.detailObj.type) {
        case 'audio':
          return 'iconfont icon-phone-outline';
        case 'doc':
          return 'iconfont icon-im';
        default:
          return 'iconfont icon-video-camera';
      }
    }
  },
  watch: {
    busiActiveConver() {
      this.getEventEngine();
    }
  },
  created() {
    this.getEventEngine();
  },
  methods: {
    handleTagClick(keyWordId) {
      this.$router.push({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: this.detailObj.id,
          keyWordId
        }
      });
    },
    goConvDetail(isOpenText) {
      if (isOpenText) {
        this.$router.push({
          path: _routeMap.ROUTES.conversationDetail,
          query: {
            id: this.detailObj.id,
            tab: 'transtext'
          }
        });
      } else {
        this.$router.push({
          path: _routeMap.ROUTES.conversationDetail,
          query: {
            id: this.detailObj.id
          }
        });
      }
    },
    getEventEngine() {
      if (this.busiActiveConver) {
        (0, _videoDetail.getEventEngine)(this.busiActiveConver, this.workspaceInfo.id).then(res => {
          this.eventEngine = res.results;
          this.eventCount = res.total;
        });
      } else {
        this.eventEngine = [];
      }
    }
  }
};
exports.default = _default;