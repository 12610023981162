"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EvaluateFormGenerateAside = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormGenerateAside"));
var _EvaluateFormGenerator = _interopRequireDefault(require("@/components/EvaluateForm/EvaluateFormGenerator"));
var _EvaluatePreviewDialog = _interopRequireDefault(require("./components/EvaluatePreviewDialog"));
var _compliance = require("@/api/compliance");
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router.js");
var _routeMap = require("@/router/routeMap");
var _nanoid = require("nanoid");
var _index = _interopRequireDefault(require("@/store/index"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _orderBy = _interopRequireDefault(require("lodash/orderBy"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'editEvalute',
  setup(__props) {
    const schema = (0, _vue.ref)({
      name: '未命名评价单',
      description: '',
      fields: [{
        id: (0, _nanoid.nanoid)(),
        name: '单行文本',
        prompt: '',
        field_order: 1,
        field_type: 1,
        required: 0,
        field_value: '',
        option_ids: []
      }]
    });
    const {
      proxy
    } = (0, _vue.getCurrentInstance)();
    const route = (0, _useRouter.useRoute)();
    const router = (0, _useRouter.useRouter)();
    // 是新建评价单还是编辑评价单
    const evaluationStatus = route.query.type;
    const editInfoText = evaluationStatus === 'edit' ? '编辑评价单不会影响已分配质检任务中的评价项，如需修改已分配质检任务中的评价项，请取消或删除质检任务后重新分配。' : '评价单可在创建质检任务时关联，质检员在操作质检时进行填写';
    const title = evaluationStatus === 'edit' ? '编辑评价单' : '新建评价单';
    const loading = (0, _vue.ref)(false);
    if (evaluationStatus !== 'create') {
      loading.value = true;
      (0, _compliance.getEvaluationTemplate)({
        template_id: route.query.id
      }).then(res => {
        const {
          fields
        } = res.results;
        res.results.fields = (0, _orderBy.default)(fields, 'field_order', 'asc');
        schema.value = res.results;
        schema.value.fields = handleFieldsData(res.results.fields);
        if (evaluationStatus === 'copy') {
          schema.value.name = schema.value.name.substring(0, 17) + ' 副本';
        }
      }).finally(() => {
        loading.value = false;
      });
    }
    const handleFieldsData = fields => {
      const modifiedArray = fields.map(item => {
        const OPTIONS_IDS = [3, 4];
        const CASCADER_IDS = [8];
        if (OPTIONS_IDS.includes(item.field_type)) {
          const modifiedOptionIds = [];
          const modifiedOptions = item.options.map(option => {
            const newId = (0, _nanoid.nanoid)();
            if (item.option_ids.includes(option.id)) {
              modifiedOptionIds.push(newId);
            }
            return {
              ...option,
              id: newId
            };
          });
          return {
            ...item,
            option_ids: modifiedOptionIds,
            options: modifiedOptions
          };
        } else if (CASCADER_IDS.includes(item.field_type)) {
          const newOptions = handleCascaderOptions(item.options);
          return {
            ...item,
            options: newOptions
          };
        }
        return item;
      });
      return modifiedArray;
    };
    const handleCascaderOptions = array => {
      const modifiedArray = array.map(item => {
        const newId = (0, _nanoid.nanoid)();
        if (item.children && item.children.length > 0) {
          const modifiedChildren = handleCascaderOptions(item.children);
          return {
            ...item,
            id: newId,
            children: modifiedChildren
          };
        } else {
          return {
            ...item,
            children: [],
            id: newId,
            score: item.score || ''
          };
        }
      });
      return modifiedArray;
    };
    const generator = (0, _vue.ref)(null);
    const addFormItem = id => {
      generator.value.addFormItem(id);
    };
    const goBack = () => {
      router.push({
        path: _routeMap.ROUTES.complianceEvaluation
      });
    };
    const schemaAfterTreatment = schema => {
      schema.fields.forEach(field => {
        if (field.field_type === 8) {
          const deleteIsActive = options => {
            options.forEach(option => {
              delete option.is_active;
              option.score = option.score - 0 || 0;
              if (option.children && option.children.length) {
                deleteIsActive(option.children);
              }
            });
          };
          deleteIsActive(field.options);
        }
      });
      return schema;
    };
    const changeOptionId = options => {
      if (!options) return;
      for (const item of options) {
        item.id = (0, _nanoid.nanoid)();
        if (item.children && item.children.length) {
          changeOptionId(item.children);
        }
      }
    };
    const saveEvaluateForm = async () => {
      const _schema = generator.value.getSchema();
      let schema = schemaAfterTreatment(_schema);
      schema.workspace_id = _index.default.state.workspace.workspaceInfo.id;
      if (evaluationStatus === 'copy') {
        schema.fields = schema.fields.map(item => {
          delete item.decimal_point;
          return {
            ...item
          };
        });
        const {
          description,
          fields,
          name,
          workspace_id
        } = schema;
        schema = {
          description,
          fields,
          name,
          workspace_id
        };
      }
      const res = await (0, _compliance.createEvaluationTemplate)(schema);
      if (res.code === 20000) {
        proxy.$root.$message.success('保存成功');
        proxy.$root.$router.push({
          path: _routeMap.ROUTES.complianceEvaluation,
          query: {
            id: res.results.template_id
          }
        });
      }
    };
    const updateEvaluationForm = async () => {
      let schema = generator.value.getSchema();
      schema = schemaAfterTreatment(schema);
      const fields = schema.fields.map(item => {
        delete item.decimal_point;
        return {
          ...item
        };
      });
      const data = {
        template_id: schema.id,
        status: 0,
        name: schema.name,
        description: schema.description,
        version: schema.version,
        fields: fields
      };
      const res = await (0, _compliance.updateEvaluationTemplate)(data);
      if (res.code === 20000) {
        proxy.$root.$message.success('编辑成功');
        proxy.$root.$router.push({
          path: _routeMap.ROUTES.complianceEvaluation,
          query: {
            id: schema.id
          }
        });
      }
    };
    const handleSaveEvaluateForm = () => {
      if (evaluationStatus !== 'edit') {
        saveEvaluateForm();
      } else {
        updateEvaluationForm();
      }
    };
    const previewSchema = (0, _vue.ref)({});
    const previewDialogVisible = (0, _vue.ref)(false);
    const openPreviewDialog = () => {
      previewSchema.value = (0, _cloneDeep.default)(schema.value);
      previewDialogVisible.value = true;
    };
    return {
      __sfc: true,
      schema,
      proxy,
      route,
      router,
      evaluationStatus,
      editInfoText,
      title,
      loading,
      handleFieldsData,
      handleCascaderOptions,
      generator,
      addFormItem,
      goBack,
      schemaAfterTreatment,
      changeOptionId,
      saveEvaluateForm,
      updateEvaluationForm,
      handleSaveEvaluateForm,
      previewSchema,
      previewDialogVisible,
      openPreviewDialog,
      EvaluateFormGenerateAside: _EvaluateFormGenerateAside.default,
      EvaluateFormGenerator: _EvaluateFormGenerator.default,
      EvaluatePreviewDialog: _EvaluatePreviewDialog.default
    };
  }
};
exports.default = _default;