"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "tree-select-value-list-container"
  }, [_vm._l(_vm.valueList, function (_ref, index) {
    let {
      name,
      id,
      preNode,
      avatar,
      isChecked
    } = _ref;
    return _c("li", {
      key: id,
      staticClass: "tree-select-value-list-item",
      on: {
        click: function ($event) {
          return _vm.$emit(isChecked ? "removeItem" : "chekedItem", {
            name,
            id,
            preNode
          });
        }
      }
    }, [_vm._t("prefix-icon", function () {
      return [_c("ImageAvatar", {
        attrs: {
          name: name,
          avatar: avatar
        }
      })];
    }, {
      nodeData: _vm.valueList[index]
    }), _c("span", [_c("span", _vm._l(name, function (item, index) {
      return _c("em", {
        key: index,
        class: {
          highlight: _vm.searchValue.includes(item)
        }
      }, [_vm._v(_vm._s(item))]);
    }), 0), preNode ? _c("span", [_vm._v(_vm._s(preNode.name))]) : _vm._e()]), _vm._t("suffix-icon", null, {
      nodeData: _vm.valueList[index]
    })], 2);
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.valueList.length && !_vm.loading,
      expression: "!valueList.length && !loading"
    }],
    staticClass: "empty"
  }, [_vm._v("暂无数据")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;