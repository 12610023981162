"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _IntelligentExtractUploadMedia = _interopRequireDefault(require("./IntelligentExtractUploadMedia"));
var _KnowledgePointExtract = _interopRequireDefault(require("./KnowledgePointExtract"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentTractDialog',
  props: {
    catalogId: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const fileList = (0, _vue.ref)([]);
    const changeFile = files => {
      fileList.value = files;
    };
    const extractKnowledgePointBtnDisabled = (0, _vue.computed)(() => {
      const hasUploading = fileList.value.some(item => item.state === 'uploading');
      const hasLeastOneSuccess = fileList.value.some(item => item.state === 'success');
      return !(fileList.value.length && !hasUploading && hasLeastOneSuccess);
    });
    const handleClose = async () => {
      emit('update:visible', false);
      await (0, _vue.nextTick)();
      intelligentExtractUploadMediaRef.value.clearUploadFileList();
    };
    const knowledgePointExtractRef = (0, _vue.ref)(null);
    const showKnowledgePoint = (0, _vue.ref)(false);
    const onExtractKnowledgePoint = async () => {
      console.log('extractKnowledgePoint');
      showKnowledgePoint.value = true;
      // 发接口
      (0, _vue.nextTick)(() => {
        knowledgePointExtractRef.value.getAiExtractKnowledgeList(fileList.value.filter(item => item.state === 'success'));
      });
    };
    const intelligentExtractUploadMediaRef = (0, _vue.ref)(null);
    const goBack = async () => {
      showKnowledgePoint.value = false;
      await (0, _vue.nextTick)();
      intelligentExtractUploadMediaRef.value.clearUploadFileList();
    };
    const refreshKnowledgeList = () => {
      emit('refreshKnowledgeList');
      handleClose();
    };
    (0, _vue.watch)(() => props.visible, () => {
      showKnowledgePoint.value = false;
    });
    // 提取中  / 提取到结果 / 全部都提取成功

    return {
      __sfc: true,
      props,
      fileList,
      changeFile,
      extractKnowledgePointBtnDisabled,
      emit,
      handleClose,
      knowledgePointExtractRef,
      showKnowledgePoint,
      onExtractKnowledgePoint,
      intelligentExtractUploadMediaRef,
      goBack,
      refreshKnowledgeList,
      IntelligentExtractUploadMedia: _IntelligentExtractUploadMedia.default,
      KnowledgePointExtract: _KnowledgePointExtract.default
    };
  }
};
exports.default = _default;