"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "button-group"
  }, [_vm._l(_setup.statusOptions, function (button) {
    return _c("el-tooltip", {
      key: button.value,
      attrs: {
        content: _vm.tooltipContent,
        placement: "top",
        disabled: !!_vm.tableSelectionList.length
      }
    }, [_c("MgvButton", {
      attrs: {
        type: "plain",
        color: "primary",
        size: "medium",
        disabled: !_vm.tableSelectionList.length
      },
      on: {
        click: function ($event) {
          return _setup.updateAnnotate(button.value);
        }
      }
    }, [_vm._v(_vm._s("批量" + button.label))])], 1);
  }), _c("MgvButton", {
    attrs: {
      type: "plain",
      color: "danger",
      size: "medium",
      disabled: !_vm.tableSelectionList.length
    },
    on: {
      click: _setup.deleteAnnotation
    }
  }, [_vm._v("批量删除")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;