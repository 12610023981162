"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DivertDialogVisible',
  components: {
    verificationCodeInput: _VerificationCodeInput.default,
    MembersFinder: _MembersFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    memberInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const isSelfValidator = (rule, value, callback) => {
      if (this.memberInfo.user_id === value) {
        callback(new Error('不可转移给自己'));
      }
      callback();
    };
    return {
      title: '验证创建人身份',
      checkOldTelCode: '',
      isFirstStep: true,
      codeForm: {
        tel: '',
        code: '',
        userId: ''
      },
      codeRules: {
        tel: [{
          required: true,
          validator: _validate.telValidator,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          validator: _validate.codeValidator,
          trigger: 'change'
        }],
        userId: [{
          required: true,
          message: '请选择成员',
          trigger: 'blur'
        }, {
          validator: isSelfValidator,
          trigger: 'change'
        }]
      },
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      }
    };
  },
  watch: {
    visible(val) {
      if (!val) return;
      this.isFirstStep = true;
      this.codeForm.tel = this.memberInfo.tel;
    }
  },
  methods: {
    // 验证码修改手机号
    handleCodeTelModify() {
      this.$refs.codeForm.validate(async valid => {
        if (valid) {
          if (this.isFirstStep) {
            const formData = {
              tel: this.codeForm.tel,
              code: this.codeForm.code
            };
            const res = await (0, _user.checkOldTel)(formData);
            if (res.code === 20000) {
              this.checkOldTelCode = res.results;
              this.isFirstStep = false;
              this.title = '转移创建人身份';
              this.$refs.codeInput.reset();
              this.codeForm.tel = '';
              this.codeForm.code = '';
              this.clearValidate();
            }
          } else {
            const res = await (0, _permissions.transferCreator)({
              user_id: this.codeForm.userId
            });
            if (res.code === 200) {
              this.$message({
                message: '转移成功',
                type: 'success'
              });
              this.$emit('fetchMemberList');
            }
            this.handleClose();
          }
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      this.$refs.codeForm.validateField('tel', value => {
        if (!value) {
          this.$refs.codeInput.showTencentVerify();
        }
      });
    },
    handleMemberChange(val) {
      this.codeForm.userId = val[0];
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.codeForm = {
        tel: '',
        code: ''
      };
      this.clearValidate();
    },
    clearValidate() {
      this.$refs['codeForm'].selection = [];
      this.$refs['codeForm'].resetFields();
      this.$nextTick(() => {
        this.$refs['codeForm'].clearValidate();
      });
    }
  }
};
exports.default = _default;