"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'MemberAssignTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    totalMember: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const tableHeads = [{
      prop: 'name',
      label: '质检员'
    }, {
      prop: 'allocate_percent',
      label: '质检成员设置分配比例'
    }, {
      prop: 'real_percent',
      label: '质检成员实际分配比例'
    }, {
      prop: 'member_count',
      label: '质检成员数量 (100人)'
    }];
    const getPercent = ratio => {
      return ratio.toLocaleString('zh', {
        style: 'percent',
        maximumFractionDigits: 2
      });
    };
    return {
      __sfc: true,
      props,
      tableHeads,
      getPercent
    };
  }
};
exports.default = _default;