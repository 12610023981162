"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExecutorInfoList = _interopRequireDefault(require("./ExecutorInfoList"));
var _TaskTitleLink = _interopRequireDefault(require("./TaskTitleLink.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskDetail',
  components: {
    ExecutorInfoList: _ExecutorInfoList.default,
    TaskTitleLink: _TaskTitleLink.default
  },
  props: {
    taskData: {
      type: Object,
      default: () => ({})
    },
    isCompleteTask: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getReminderLabel() {
      const {
        alert_label
      } = this.taskData.task_info;
      const i18nLabel = this.$t(`coaching.${alert_label || '不提醒'}`);
      return i18nLabel.includes('coaching.') ? alert_label : i18nLabel;
    }
  },
  methods: {
    updateExecutorStatus(id) {
      this.$emit('updateDetailInfo', id);
    }
  }
};
exports.default = _default;