"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _nanoid = require("nanoid");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const {
  orgId,
  userId
} = _store.default.getters;
const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Org-Id': orgId,
  'X-User-Id': userId
};
function fetchRequest(url, config) {
  defaultHeaders['X-Trace-Id'] = (0, _nanoid.nanoid)();
  return fetch(url, {
    headers: defaultHeaders,
    ...config
  });
}
var _default = fetchRequest;
exports.default = _default;