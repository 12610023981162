"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.userScaleDialogVisible,
      "before-close": _vm.handleCloseDialog,
      title: "申请扩容",
      width: "476px"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          staticClass: "mg-button",
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("提交申请")])];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form
    }
  }, [_c("div", [_vm._v("账号：" + _vm._s(_vm.orgName))]), _c("div", {
    staticClass: "limit-invite"
  }, [_c("el-form-item", {
    attrs: {
      prop: "scale",
      label: "申请使用人数上限",
      "label-position": "left",
      "label-width": "125px",
      rules: [{
        required: true,
        message: "申请人数不能为空"
      }, {
        type: "number",
        message: "申请人数必须为数字值"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "11"
    },
    model: {
      value: _vm.form.scale,
      callback: function ($$v) {
        _vm.$set(_vm.form, "scale", _vm._n($$v));
      },
      expression: "form.scale"
    }
  })], 1)], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;