"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "",
  mixins: [_chartMixins.default],
  components: {},
  props: {
    data: {
      type: Array,
      default: []
    },
    yAxis: {
      type: Array,
      default: []
    },
    transferRate: {
      type: Array,
      default: []
    },
    lossRate: {
      type: Array,
      default: []
    },
    maxChartValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            const getLine = num => {
              if (!params[num]) return "";
              const dot = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params[num].color};"></span>`;
              const text = `${params[num].seriesName}: ${params[num].value}`;
              return `<div>${dot}${text}</div>`;
            };
            const title = `<strong>${params[0].name}</strong>`;
            const transfer = getLine(0);
            const loss = getLine(1);
            const stay = getLine(2);
            let string;
            if (params[0].name === "赢单") {
              string = title + stay;
            } else {
              string = title + transfer + loss + stay;
            }
            return string;
          }
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}个"
          }
        },
        yAxis: {
          type: "category",
          data: []
        },
        color: ["#91CC75", "#EE6666", "#FAC858"],
        series: [{
          name: "流转至下一阶段",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: params => {
              // 计算label是否显示
              const tooSmall = params.value / this.maxChartValue < 0.1;
              const showLabel = params.value && !tooSmall;
              // 计算label内容
              const rate = this.transferRate[params.dataIndex];
              const string = `${params.value}/${rate}`;
              return showLabel ? string : "";
            }
          },
          emphasis: {
            focus: "series"
          },
          data: []
        }, {
          name: "流失（转为输单/无效）",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: params => {
              // 计算label是否显示
              const tooSmall = params.value / this.maxChartValue < 0.1;
              const showLabel = params.value && !tooSmall;
              // 计算label内容
              const rate = this.lossRate[params.dataIndex];
              const string = `${params.value}/${rate}`;
              return showLabel ? string : "";
            }
          },
          emphasis: {
            focus: "series"
          },
          data: []
        }, {
          name: "停留在本阶段",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: params => {
              // 计算label是否显示
              const tooSmall = params.value / this.maxChartValue < 0.05;
              const showLabel = params.value && !tooSmall;
              // 计算label内容
              const string = params.value || "";
              return showLabel ? string : "";
            }
          },
          emphasis: {
            focus: "series"
          },
          data: []
        }]
      }
    };
  },
  computed: {},
  watch: {
    data: {
      handler(val) {
        this.options.series[0].data = val[0];
        this.options.series[1].data = val[1];
        this.options.series[2].data = val[2];
        this.options.yAxis.data = this.yAxis;
        this.init();
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    // this.init();
  },
  methods: {}
};
exports.default = _default;