"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _vuex = require("vuex");
var _mediaUpload = require("@/api/mediaUpload");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  inject: ['reload'],
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: ['index', 'tranText', 'id', 'audioList', 'activeRoundId', 'bindName', 'uploadType', 'peopleType', 'hasRole', 'entityId', 'channel', 'sumbitVisible'],
  data() {
    return {
      testArr123: [],
      name: '',
      name2: '',
      type1: '',
      type2: '',
      speakName: '',
      speakName2: '',
      testValue: '',
      popVisiable: false,
      popToken: false,
      indentityDialogVisible: false,
      iconToken: false,
      peopleIndex: '',
      saleList: [],
      custList: [],
      companyName: '',
      custFlag: false,
      saleFlag: false,
      saleLoading: false,
      custLoading: false,
      nameChangeItem: '',
      options: [{
        name: '销售代表',
        token: false,
        type: 'host_contact',
        id: '销售代表'
      }, {
        name: '客户',
        token: false,
        type: 'customer_contact',
        id: '客户'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['guideVisiable'])
  },
  watch: {
    popVisiable(val) {
      this.iconToken = val !== false;
      if (val && this.activeRoundId && this.uploadType) {
        this.getSalemanList();
      }
      if (val && this.activeRoundId && this.uploadType) {
        this.getCustList();
      }
      if (val) {
        this.$store.dispatch('video/set_guide_visiable', false);
      }
    },
    activeRoundId(val) {},
    sumbitVisible(val) {
      if (!val) this.speakName = '';
    }
  },
  created() {},
  mounted() {
    if (this.index % 2 === 0) {
      this.peopleIndex = 0;
    } else {
      this.peopleIndex = 1;
    }
  },
  methods: {
    handleSale() {
      this.saleFlag = true;
      this.custFlag = false;
    },
    handleCust() {
      this.saleFlag = false;
      this.custFlag = true;
    },
    mouseO() {
      this.iconVisiable = true;
    },
    mouseOver() {
      this.iconToken = true;
    },
    mouseLeave() {
      this.iconToken = false;
    },
    handleEnter(index, id, item) {
      this.testValue = id;
      this.popVisiable = false;
      if (this.peopleIndex === 0) {
        this.name = index === 0 ? '销售代表' : '客户';
        this.name2 = index === 0 ? '客户' : '销售代表';
      } else {
        this.name2 = index === 0 ? '销售代表' : '客户';
        this.name = index === 0 ? '客户' : '销售代表';
      }
      this.indentityDialogVisible = true;
    },
    handleEnterName(index, id, item) {
      this.testValue = id;
      let arr = [];
      arr = JSON.parse(JSON.stringify(this.audioList));
      if (this.peopleIndex === 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].entityType == item.entity_type) {
            item.participantId = arr[i].participantId;
            arr[i] = item;
            this.nameChangeItem = item;
            this.speakName = item.name;
          }
        }
      } else if (this.peopleIndex === 1) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].entityType == item.entity_type) {
            item.participantId = arr[i].participantId;
            arr[i] = item;
            this.nameChangeItem = item;
            this.speakName = item.name;
          }
        }
      }
      this.$emit('select', this.nameChangeItem);
      this.popVisiable = false;
    },
    handleTypeName(index, id, item) {
      this.testValue = id;
      const newItem = JSON.parse(JSON.stringify(item));
      if (this.peopleIndex === 0) {
        newItem.participantId = this.audioList[0].participantId;
        this.speakName = item.name;
      } else if (this.peopleIndex === 1) {
        newItem.participantId = this.audioList[1].participantId;
        this.speakName = item.name;
      }
      this.$emit('select', newItem);
      this.popVisiable = false;
    },
    handleUp() {
      const type1 = this.name === '销售代表' ? 'host_salesman' : 'customer_contact';
      const type2 = this.name2 === '销售代表' ? 'host_salesman' : 'customer_contact';
      const data = {
        speaker1_type: type1,
        speaker2_type: type2,
        speaker1_id: this.audioList[0].participantId,
        speaker2_id: this.audioList[1].participantId,
        conversation_id: this.activeRoundId
      };
      (0, _mediaUpload.createRole)(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '设定成功'
          });
          this.indentityDialogVisible = false;
          this.reload();
        }
      });
    },
    getSalemanList() {
      this.saleLoading = true;
      const data = {
        conversation_id: this.activeRoundId
      };
      (0, _mediaUpload.bindSaleman)(data).then(res => {
        this.companyName = res.results.company;
        this.saleList = res.results.users;
        this.saleLoading = false;
      });
    },
    getCustList() {
      this.custLoading = true;
      const data = {
        conversation_id: this.activeRoundId
      };
      (0, _mediaUpload.bindCust)(data).then(res => {
        this.custList = res.results;
        this.custLoading = false;
      });
    }
  }
};
exports.default = _default;