"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _CreateSummaryTemplateDrawer = _interopRequireDefault(require("./components/CreateSummaryTemplateDrawer"));
var _TemplateTable = _interopRequireDefault(require("./components/TemplateTable.vue"));
var _summaryTemplate = require("@/api/summaryTemplate");
var _elementUi = require("element-ui");
var _useVuex = require("@/hooks/use-vuex.js");
var _vue = require("vue");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const workspaceInfo = (0, _vue.computed)(() => store.getters.workspaceInfo);
    const submitType = (0, _vue.ref)('create');
    const summaryTempalteDrawerVisible = (0, _vue.ref)(false);
    const onCreateSummaryTemplate = () => {
      submitType.value = 'create';
      summaryTempalteDrawerVisible.value = true;
    };
    const tableData = (0, _vue.ref)([]);
    const tableLoading = (0, _vue.ref)(false);
    const getSummaryTemplate = () => {
      tableLoading.value = true;
      (0, _summaryTemplate.getSummaryTemplateList)({
        space_id: workspaceInfo.value.id
      }).then(res => {
        if (res.code === 20000) {
          tableData.value = res.results.items;
        }
      }).finally(() => {
        tableLoading.value = false;
      });
    };
    // 在组件挂载完成后获取数据
    (0, _vue.onMounted)(() => {
      getSummaryTemplate();
    });

    // 空间信息变化后需要请求数据
    (0, _vue.watch)(() => workspaceInfo.value, (val, oldVal) => {
      if (val.id !== oldVal.id) {
        getSummaryTemplate();
      }
    }, {
      deep: true
    });
    const activeTemplate = (0, _vue.ref)({});
    const editTemplate = row => {
      console.log(row);
      submitType.value = 'edit';
      activeTemplate.value = handleRowInfo(row);
      summaryTempalteDrawerVisible.value = true;
    };
    const handleRowInfo = row => {
      const {
        id,
        name,
        prompt,
        content,
        departments,
        space_id
      } = row;
      const tree_ids = departments.map(item => item.id);
      return {
        id,
        name,
        prompt,
        content,
        space_id,
        tree_ids
      };
    };
    const deleteTemplate = (row, index) => {
      // 删除前的再次确认
      _elementUi.MessageBox.confirm('是否确认删除该指令？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirm-right',
        type: 'warning'
      }).then(() => {
        // 请求删除接口
        (0, _summaryTemplate.deleteSummaryTemplate)({
          ids: [row.id]
        }).then(res => {
          if (res.code === 20000) {
            console.log('deleteTemplate', index);
            tableData.value.splice(index, 1);
            (0, _elementUi.Message)({
              message: '删除成功',
              type: 'success'
            });
          }
        });
      });
    };
    const rowDragSort = evt => {
      const copyData = (0, _cloneDeep.default)(tableData.value);
      const curRow = copyData.splice(evt.oldIndex, 1)[0];
      copyData.splice(evt.newIndex, 0, curRow);
      const newOrderArray = copyData.map((item, index) => {
        return {
          id: item.id,
          serial_number: copyData.length - index
        };
      });
      (0, _summaryTemplate.updateSummaryTemplateOrder)({
        space_id: workspaceInfo.value.id,
        items: newOrderArray
      }).then(res => {
        if (res.code === 20000) {
          tableData.value = copyData;
        }
      });
    };
    return {
      __sfc: true,
      store,
      workspaceInfo,
      submitType,
      summaryTempalteDrawerVisible,
      onCreateSummaryTemplate,
      tableData,
      tableLoading,
      getSummaryTemplate,
      activeTemplate,
      editTemplate,
      handleRowInfo,
      deleteTemplate,
      rowDragSort,
      SpaceButton: _SpaceButton.default,
      CreateSummaryTemplateDrawer: _CreateSummaryTemplateDrawer.default,
      TemplateTable: _TemplateTable.default
    };
  }
};
exports.default = _default;