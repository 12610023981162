"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CollapseAvatar = _interopRequireDefault(require("@/components/CollapseAvatar"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceTaskCard',
  components: {
    CollapseAvatar: _CollapseAvatar.default
  },
  props: {
    cardItem: {
      type: Object,
      default: () => ({})
    },
    noOperateButtons: {
      type: Boolean,
      default: false
    },
    startComplianceButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission'])
  },
  methods: {
    handleClickTaskCard() {
      this.$emit('clickTaskCard', this.cardItem.id, this.cardItem.sampling_type);
    },
    getTaskStatus(status) {
      return ['进行中', '已完成', '已取消'][status - 1];
    }
  }
};
exports.default = _default;