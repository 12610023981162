"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _TaskResultDetailDrawer = _interopRequireDefault(require("../components/TaskResultDetail/TaskResultDetailDrawer"));
var _routeMap = require("@/router/routeMap");
var _PointDeductionItemsDrawerContent = _interopRequireDefault(require("./PointDeductionItemsDrawerContent.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LeaderPlanTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    TaskResultDetailDrawer: _TaskResultDetailDrawer.default,
    PointDeductionItemsDrawerContent: _PointDeductionItemsDrawerContent.default
  },
  props: {
    tableHead: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    taskInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 170,
      currentTaskInfo: {},
      TaskResultDetailDrawerVisible: false,
      pointDeductionItemDrawerVisible: false,
      currentRow: {
        user: {}
      }
    };
  },
  computed: {
    tableHeadTooltip() {
      let tips = '';
      if ([1, 2].includes(this.taskInfo.course_type)) {
        tips = this.$t('coaching.averageScoreOfAllQuestions');
      }
      if ([5, 6].includes(this.taskInfo.course_type)) {
        tips = this.$t('coaching.finalScoreOfAICustomerCourse');
      }
      return tips;
    }
  },
  watch: {
    tableHead() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    tableData() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    }
  },
  methods: {
    handleSortChange(val) {
      this.$emit('tableSortChange', val);
    },
    goPersonDetail(row) {
      if (!row.is_access) return;
      this.currentTaskInfo = {
        courseType: this.taskInfo.course_type,
        executorInfo: row.user
      };
      this.TaskResultDetailDrawerVisible = true;
    },
    getStatus(row) {
      if (this.taskInfo.status === 2 || !row.is_access) {
        return `<span class='cannot-view'>--</span>`;
      }
      if (row.is_complete) {
        return `<span class='status-complete'>${this.$t('coaching.completed')}</span>`;
      } else {
        return `<span class='status-no-complete'>${this.$t('coaching.notCompleted')}</span>`;
      }
    },
    openPointDeductionItemDrawer(row) {
      this.currentRow = row;
      this.pointDeductionItemDrawerVisible = true;
    }
  }
};
exports.default = _default;