"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _EvaluateForm = _interopRequireDefault(require("./EvaluateForm.vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceDetail',
  components: {
    EvaluateForm: _EvaluateForm.default
  },
  props: {
    conversationId: {
      type: [String, Number],
      default: 0
    },
    rightBarActive: {
      type: String,
      default: 'compliance'
    },
    onlyEvaluation: {
      type: Boolean,
      default: false
    },
    complianceStatus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 质检详情信息
      complianceDetailInfo: {},
      currentConversationIdx: 0,
      allComplianceConversationIds: [],
      completeComplianceLoading: false,
      complianceDetailLoading: false,
      complianceAutoMoveOnToTheNext: false,
      evaluationId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission', 'userId', 'name', 'avatar', 'isShowStageIIComplianceWorkSpaceGuide']),
    taskDetailInfo() {
      var _this$complianceDetai, _this$complianceDetai2;
      return (_this$complianceDetai = (_this$complianceDetai2 = this.complianceDetailInfo) === null || _this$complianceDetai2 === void 0 ? void 0 : _this$complianceDetai2.task_info) !== null && _this$complianceDetai !== void 0 ? _this$complianceDetai : {};
    },
    taskCreatorInfo() {
      var _this$complianceDetai3, _this$complianceDetai4, _this$complianceDetai5;
      return (_this$complianceDetai3 = (_this$complianceDetai4 = this.complianceDetailInfo) === null || _this$complianceDetai4 === void 0 ? void 0 : (_this$complianceDetai5 = _this$complianceDetai4.task_info) === null || _this$complianceDetai5 === void 0 ? void 0 : _this$complianceDetai5.create_by) !== null && _this$complianceDetai3 !== void 0 ? _this$complianceDetai3 : {};
    },
    isShowComplianceWorkSpaceGuide() {
      return localStorage.getItem('isShowStageIIComplianceWorkSpaceGuide') === 'true' || this.isShowStageIIComplianceWorkSpaceGuide;
    },
    localStatus() {
      return this.onlyEvaluation ? this.complianceStatus : this.complianceDetailInfo.status;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          await this.getAllCompliance();
          if (!this.onlyEvaluation) {
            this.getComplianceDetail();
          }
          this.getSessionStorageData();
        }
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      // 会话详情页是打开的新标签页
      if (this.$route.name === 'ConversationDetail') {
        this.$router.push({
          path: _routeMap.ROUTES.conversation
        });
      } else {
        this.$router.go(-1);
      }
    },
    getStatus(status) {
      return ['进行中', '已完成', '已取消'][status - 1];
    },
    getOperateRecord(code) {
      return ['完成质检', '驳回质检', '取消任务'][code - 1];
    },
    getSessionStorageData() {
      this.allComplianceConversationIds = JSON.parse(sessionStorage.getItem('allComplianceOfConversationIds'));
      this.currentConversationIdx = Number(sessionStorage.getItem('currentConversationInComplianceIdx'));
      this.complianceAutoMoveOnToTheNext = localStorage.getItem(`${this.userId}ComplianceAutoMoveOnToTheNext`) === 'true';
    },
    // 自动进入下一个
    handleAutoToNext() {
      // this.complianceAutoMoveOnToTheNext = !this.complianceAutoMoveOnToTheNext;
      // 存在 localStorage 中,添加上 userId 为key
      localStorage.setItem(`${this.userId}ComplianceAutoMoveOnToTheNext`, this.complianceAutoMoveOnToTheNext);
    },
    handleClickProgress(type) {
      var _this$$route, _this$$route$query, _this$$route2, _this$$route2$query;
      switch (type) {
        case 'prev':
          sessionStorage.setItem('currentConversationInComplianceIdx', this.currentConversationIdx - 1);
          this.$router.replace({
            path: this.onlyEvaluation ? _routeMap.ROUTES.conversationDetail : _routeMap.ROUTES.complianceWorkspace,
            query: {
              ...this.$route.query,
              id: this.allComplianceConversationIds[this.currentConversationIdx - 1],
              task_id: (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query = _this$$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.task_id
            }
          });
          return;
        case 'next':
          sessionStorage.setItem('currentConversationInComplianceIdx', this.currentConversationIdx + 1);
          this.$router.replace({
            path: this.onlyEvaluation ? _routeMap.ROUTES.conversationDetail : _routeMap.ROUTES.complianceWorkspace,
            query: {
              ...this.$route.query,
              id: this.allComplianceConversationIds[this.currentConversationIdx + 1],
              task_id: (_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : (_this$$route2$query = _this$$route2.query) === null || _this$$route2$query === void 0 ? void 0 : _this$$route2$query.task_id
            }
          });
      }
    },
    async getAllCompliance() {
      const params = this.$route.params;
      const conversationIds = localStorage.getItem('conversationIds');
      if (this.$route.path === _routeMap.ROUTES.complianceWorkspace && JSON.stringify(params) === '{}') {
        return;
      }
      if (this.$route.path === _routeMap.ROUTES.conversationDetail && !conversationIds) {
        return;
      }
      if (params.conversationIds) {
        this.storeConversations(params.conversationIds);
      } else if (conversationIds && this.$route.path === _routeMap.ROUTES.conversationDetail) {
        this.storeConversations(JSON.parse(conversationIds));
        localStorage.removeItem('conversationIds');
      } else {
        const res = await (0, _compliance.getAllComplianceOfConversationIds)({
          workspace_id: this.workspaceInfo.id,
          ...params
        });
        if (res.code === 20000) {
          this.storeConversations(res.results.list);
        }
      }
    },
    storeConversations(conversationIds) {
      var _this$$route$query2;
      const currentConversationId = (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.id;
      const currentConversationIdx = conversationIds.findIndex(id => id == currentConversationId);
      // 存储当前会话的idx
      sessionStorage.setItem('currentConversationInComplianceIdx', currentConversationIdx);
      // 存储所有位于工作台的所有ids
      sessionStorage.setItem('allComplianceOfConversationIds', JSON.stringify(conversationIds));
    },
    handleCompleteCompliance() {
      if (this.taskDetailInfo.template_name || this.onlyEvaluation) {
        const valid = this.$refs.evaluateForm.$refs.evaluateFormWidget.validateForm();
        if (!valid) {
          this.$message.warning('请填写评价单');
          return;
        }
        const formData = this.$refs.evaluateForm.$refs.evaluateFormWidget.getFormData();
        const noRemindAgain = _jsCookie.default.get('noRemindAgain');
        const hasValue = formData.some(item => item.field_value || item.option_ids && item.option_ids.length || item.cascader_ids && item.cascader_ids.length);
        if (!hasValue && !noRemindAgain) {
          this.$confirm('<div>还未填写任何评价项，请确认</div><div class="no-remind-content"><input type="checkbox" id="no-remind-checkbox">今天不再提示</div>', '提示', {
            dangerouslyUseHTMLString: true,
            customClass: 'compliance-workspace-reminder-not-filled',
            confirmButtonText: '完成质检',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const checked = document.getElementById('no-remind-checkbox').checked;
            if (checked) {
              _jsCookie.default.set('noRemindAgain', true, {
                expires: 1
              });
            }
            this.completeCompliance(formData);
          });
        } else {
          this.completeCompliance(formData);
        }
      } else {
        this.completeCompliance();
      }
    },
    // 完成质检
    async completeCompliance(formData) {
      this.completeComplianceLoading = true;
      // 产品要求 300 ms延时
      setTimeout(() => {
        const {
          evaluationVersion,
          evaluationRecord
        } = this.$refs.evaluateForm;
        const data = {
          id: Number(this.conversationId),
          workspace_id: this.workspaceInfo.id,
          status: 2
        };
        if (formData) {
          data.template_id = this.evaluationId;
          data.evaluation_record = formData;
          data.version = evaluationVersion;
        }
        const currentQC = this.complianceDetailInfo.user_id ? this.complianceDetailInfo : evaluationRecord === null || evaluationRecord === void 0 ? void 0 : evaluationRecord.user_info;
        const currentQCId = (currentQC === null || currentQC === void 0 ? void 0 : currentQC.user_id) || (currentQC === null || currentQC === void 0 ? void 0 : currentQC.id);
        if (currentQCId && this.userId !== currentQCId) {
          this.$confirm(`<p style="font-weight: bold;font-size: 18px;margin-bottom: 19px;margin-top: -20px;">更新质检员</p>
            <i class="iconfont icon-info-2" style="color: #4461EC;margin-right: 6px;font-size: 14px;"></i>
            <span style="color: #2B323B;line-height: 28px;">该会话当前质检员为${this.formatConfirmHtml(currentQC)}，是否更新为${this.formatConfirmHtml({
            user_name: this.name,
            avatar: this.avatar
          })}？更新之后，该会话将转移至新质检员的任务中。</span>`, {
            dangerouslyUseHTMLString: true,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            confirmButtonText: '更新',
            cancelButtonText: '不更新'
          }).then(() => {
            data.is_transfer = true;
          }).catch(() => {
            data.is_transfer = false;
          }).finally(() => {
            this.updateComplianceDetailRequest(data);
          });
        } else {
          data.is_transfer = false;
          this.updateComplianceDetailRequest(data);
        }
      }, 300);
    },
    async updateComplianceDetailRequest(data) {
      const res = await this.updateComplianceDetail(data).finally(() => {
        this.completeComplianceLoading = false;
      });
      if (res.code === 20000) {
        if (this.onlyEvaluation) {
          this.$emit('changeComplianceStatus', 2);
        } else {
          this.complianceDetailInfo.status = 2;
          this.getComplianceDetail();
        }
        if (this.complianceAutoMoveOnToTheNext && this.allComplianceConversationIds.length - 1 != this.currentConversationIdx) {
          this.handleClickProgress('next');
        } else {
          this.$message.success('质检完成');
        }
      }
    },
    formatConfirmHtml() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return `${data.avatar ? `<img src="${data.avatar}" style="width: 28px;height: 28px;border-radius: 50%;vertical-align: bottom;margin-left: 6px;margin-right: 4px">` : ''}<span style="font-weight: bold;">${data.user_name}</span>`;
    },
    // 驳回质检
    async handleRejectCompliance() {
      this.completeComplianceLoading = true;
      const method = this.onlyEvaluation ? _compliance.rejectConvDetailCompliance : _compliance.rejectCompliance;
      const res = await method({
        id: Number(this.conversationId),
        workspace_id: this.workspaceInfo.id,
        status: 3
      }).finally(() => this.completeComplianceLoading = false);
      if (res.code === 20000) {
        if (this.onlyEvaluation) {
          // eslint-disable-next-line vue/no-mutating-props
          this.$emit('changeComplianceStatus', 3);
        } else {
          this.complianceDetailInfo.status = 3;
          this.getComplianceDetail();
        }
        this.$message.success('驳回成功');
      }
    },
    // 获取质检详情
    async getComplianceDetail() {
      this.complianceDetailLoading = true;
      const res = await (0, _compliance.getComplianceDetail)({
        id: Number(this.conversationId),
        workspace_id: this.workspaceInfo.id
      }).finally(() => this.complianceDetailLoading = false);
      if (res.code === 20000) {
        this.complianceDetailInfo = res.results;
        if (!this.complianceDetailInfo.task_info.name) {
          this.$message.warning('任务已删除');
          this.$router.replace({
            path: _routeMap.ROUTES.complianceMyTask
          });
        }
        if (this.complianceDetailInfo.task_info.template_name) {
          this.$emit('changeRightBar', 'evaluation');
        }
      }
    },
    // 更新质检状态及备注
    updateComplianceDetail(data) {
      return this.onlyEvaluation ? (0, _compliance.updateConvDetailComplianceDetail)(data) : (0, _compliance.updateComplianceDetail)(data);
    }
  }
};
exports.default = _default;