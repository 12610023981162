"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeConvCardContent = _interopRequireDefault(require("./KnowledgeConvCardContent"));
var _timeFormatter = require("@/utils/time-formatter");
var _GiveLikeOrUnlike = _interopRequireDefault(require("./GiveLikeOrUnlike"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeConvCard',
  components: {
    KnowledgeConvCardContent: _KnowledgeConvCardContent.default,
    GiveLikeOrUnlike: _GiveLikeOrUnlike.default
  },
  props: {
    convItem: {
      type: Object,
      default: () => ({})
    },
    activeKnowledge: {
      type: Object,
      default: () => ({})
    },
    isExpand: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isExpand: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs['cardContent'].isExpand = val;
        });
      },
      immediate: true
    }
  },
  methods: {
    getConversationIcon(type) {
      switch (type) {
        case 'doc':
          return 'im';
        case 'audio':
          return 'phone-outline';
        case 'video':
          return 'video-camera';
      }
    },
    secondToTime: _timeFormatter.secondToTime,
    goConvDetail() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.convItem.conversation_id}&noback=true`);
    }
  }
};
exports.default = _default;