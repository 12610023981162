"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _app = _interopRequireDefault(require("./modules/app"));
var _video = _interopRequireDefault(require("./modules/video"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _user = _interopRequireDefault(require("./modules/user"));
var _business = _interopRequireDefault(require("./modules/business"));
var _bi = _interopRequireDefault(require("./modules/bi"));
var _call = _interopRequireDefault(require("./modules/call"));
var _library = _interopRequireDefault(require("./modules/library"));
var _account = _interopRequireDefault(require("./modules/account"));
var _upload = _interopRequireDefault(require("./modules/upload"));
var _workspace = _interopRequireDefault(require("./modules/workspace"));
var _training = _interopRequireDefault(require("./modules/training"));
var _compliance = _interopRequireDefault(require("./modules/compliance"));
var _sop = _interopRequireDefault(require("./modules/sop"));
var _ruleSetting = _interopRequireDefault(require("./modules/ruleSetting"));
var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
_vue.default.use(_vuex.default);
const store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    settings: _settings.default,
    user: _user.default,
    video: _video.default,
    business: _business.default,
    bi: _bi.default,
    call: _call.default,
    library: _library.default,
    account: _account.default,
    upload: _upload.default,
    workspace: _workspace.default,
    training: _training.default,
    compliance: _compliance.default,
    sop: _sop.default,
    ruleSetting: _ruleSetting.default
  },
  plugins: [(0, _vuexPersistedstate.default)({
    storage: window.sessionStorage,
    reducer(val) {
      return {
        // 只储存state中的library
        library: val.library
      };
    }
  })],
  getters: _getters.default
});
var _default = store;
exports.default = _default;