"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      visible: _vm.visible,
      title: "评分指标管理",
      size: "90%",
      "append-to-body": true
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "drawer-content"
  }, [_c("aside", {
    staticClass: "dimension-aside"
  }, [_c(_setup.DimensionList, {
    ref: "dimensionRef",
    on: {
      "node-click": _setup.onDimensionNodeClick
    }
  })], 1), _c("div", {
    staticClass: "metrics-content"
  }, [_c("div", {
    staticClass: "metric-header"
  }, [_c("div", {
    staticClass: "metric-header-left"
  }, [_c("div", {
    staticClass: "folder-icon-container"
  }, [_c("i", {
    staticClass: "iconfont icon-folder-fill"
  })]), _c("div", {
    staticClass: "dimension-info"
  }, [_c("p", [_vm._v(_vm._s(_setup.activeDimension.name))]), _c("p", [_vm._v("系统预设")])])]), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      size: "medium"
    },
    on: {
      click: _setup.openCreateMetricDialog
    }
  }, [_vm._v("新增评分指标")])], 1), _c("div", {
    staticClass: "metric-table-container"
  }, [_c(_setup.MetricsFilterTable, {
    ref: "metricTableRef",
    attrs: {
      "dimension-id": _setup.activeDimension.id
    },
    on: {
      deleteSuccess: _setup.onMetricDeleteSuccess
    }
  })], 1)])]), _c(_setup.MetricCreateDialog, {
    attrs: {
      visible: _setup.metricCreateDialogVisible,
      "dimension-id": _setup.activeDimension.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.metricCreateDialogVisible = $event;
      },
      createSuccess: _setup.onCreateMetricSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;