"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$taskDetailInfo$sc, _vm$taskDetailInfo$op;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.complianceDetailLoading,
      expression: "complianceDetailLoading"
    }],
    staticClass: "compliance-detail"
  }, [_vm.rightBarActive === "compliance" ? _c("div", {
    staticClass: "compliance-detail-container"
  }, [_c("div", {
    staticClass: "task-info"
  }, [_c("div", {
    staticClass: "task-info-content"
  }, [_c("span", {
    staticClass: "desc"
  }, [_vm._v("任务名称")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.taskDetailInfo.name))])]), _c("div", {
    staticClass: "task-info-content"
  }, [_c("span", {
    staticClass: "desc"
  }, [_vm._v("质检项目")]), _c("span", {
    staticClass: "content compliance-items"
  }, [(_vm$taskDetailInfo$sc = _vm.taskDetailInfo.score_names) !== null && _vm$taskDetailInfo$sc !== void 0 && _vm$taskDetailInfo$sc.length ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      content: "质检评分规则",
      placement: "top"
    }
  }, [_c("span", [_c("i", {
    staticClass: "iconfont icon-scoring-compliance"
  }), _vm._v(_vm._s(_vm.taskDetailInfo.score_names.join(",")))])]) : _vm._e(), _vm.taskDetailInfo.template_name ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      content: "质检评价单",
      placement: "top"
    }
  }, [_c("span", [_c("i", {
    staticClass: "iconfont icon-compliance-evaluation-form"
  }), _vm._v(_vm._s(_vm.taskDetailInfo.template_name))])]) : _vm._e()], 1)]), _c("div", {
    staticClass: "task-info-content"
  }, [_c("span", {
    staticClass: "desc"
  }, [_vm._v("分配人")]), _c("span", {
    staticClass: "content"
  }, [_c("mgv-avatar-name", {
    staticClass: "mgv-avatar",
    attrs: {
      src: _vm.taskCreatorInfo.avatar,
      size: 24,
      name: _vm.taskCreatorInfo.name
    }
  }), _c("span", {
    staticClass: "creator-name"
  }, [_vm._v(_vm._s(_vm.taskCreatorInfo.name))])], 1)]), _c("div", {
    staticClass: "task-info-content"
  }, [_c("span", {
    staticClass: "desc"
  }, [_vm._v("分配时间")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.taskDetailInfo.create_at).format("YYYY-MM-DD HH:mm")))])]), _c("div", {
    staticClass: "task-status-content"
  }, [_c("span", {
    staticClass: "desc"
  }, [_vm._v("任务状态")]), _c("span", {
    staticClass: "task-status",
    class: {
      "in-progress": _vm.taskDetailInfo.status === 1,
      completed: _vm.taskDetailInfo.status === 2,
      cancelled: _vm.taskDetailInfo.status === 3
    }
  }, [_vm._v(_vm._s(_vm.getStatus(_vm.taskDetailInfo.status)))])])]), _c("el-divider"), _c("div", {
    staticClass: "compliance-personal"
  }, [_c("div", {
    staticClass: "personal-info"
  }, [_c("div", {
    staticClass: "personal-name"
  }, [_vm._v("质检员")]), _c("mgv-avatar-name", {
    attrs: {
      src: _vm.complianceDetailInfo.avatar,
      name: _vm.complianceDetailInfo.user_name,
      size: 24
    }
  }), _c("div", {
    staticClass: "compliance-personal-name"
  }, [_vm._v(" " + _vm._s(_vm.complianceDetailInfo.user_name) + " ")])], 1), _c("div", {
    staticClass: "operate-record"
  }, [_c("div", {
    staticClass: "operate-record-title"
  }, [_vm._v("操作记录")]), (_vm$taskDetailInfo$op = _vm.taskDetailInfo.op_record) !== null && _vm$taskDetailInfo$op !== void 0 && _vm$taskDetailInfo$op.length ? _c("el-timeline", _vm._l(_vm.taskDetailInfo.op_record, function (activity, index) {
    return _c("el-timeline-item", {
      key: index,
      attrs: {
        timestamp: `${activity.name} ${_vm.$plus8ToCurrentZone(activity.create_at).format("YYYY-MM-DD HH:mm:ss")}`,
        placement: "top",
        color: "#4461EC"
      }
    }, [_vm._v(" " + _vm._s(_vm.getOperateRecord(activity.op_code)) + " ")]);
  }), 1) : _c("span", [_vm._v("-")])], 1)])], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightBarActive === "evaluation",
      expression: "rightBarActive === 'evaluation'"
    }],
    staticClass: "compliance-evaluation-container"
  }, [_c("evaluate-form", {
    ref: "evaluateForm",
    attrs: {
      "compliance-status": _vm.localStatus,
      "only-evaluation": _vm.onlyEvaluation
    },
    on: {
      getEvaluationId: function ($event) {
        _vm.evaluationId = $event;
      }
    }
  })], 1), _c("div", {
    staticClass: "compliance-submit"
  }, [_c("div", {
    staticClass: "compliance-submit-content"
  }, [_c("transition", {
    attrs: {
      name: "fade-out"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localStatus === 1 || _vm.localStatus === 0,
      expression: "localStatus === 1 || localStatus === 0"
    }],
    staticClass: "compliance-status no-complete",
    class: {
      cancelled: !_vm.onlyEvaluation && _vm.taskDetailInfo.status === 3
    }
  }, [_c("i", {
    staticClass: "iconfont icon-time-fill"
  }), _c("span", [_vm._v("待质检")])])]), _c("transition", {
    attrs: {
      name: "fade-in"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localStatus === 2,
      expression: "localStatus === 2"
    }],
    staticClass: "compliance-status complete",
    class: {
      cancelled: !_vm.onlyEvaluation && _vm.taskDetailInfo.status === 3
    }
  }, [_c("i", {
    staticClass: "iconfont icon-success"
  }), _c("span", [_vm._v("已质检")])])]), _c("transition", {
    attrs: {
      name: "fade-in"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localStatus === 3,
      expression: "localStatus === 3"
    }],
    staticClass: "compliance-status rejected",
    class: {
      cancelled: !_vm.onlyEvaluation && _vm.taskDetailInfo.status === 3
    }
  }, [_c("i", {
    staticClass: "iconfont icon-circle-close"
  }), _c("span", [_vm._v("已驳回")])])]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localStatus !== 2 && (_vm.onlyEvaluation || _vm.taskDetailInfo.status !== 3) && (_vm.onlyEvaluation && _vm.interfacePermission.completeConvDetailCompliance || !_vm.onlyEvaluation && _vm.interfacePermission.completeCompliance),
      expression: "\n          localStatus !== 2 &&\n          (onlyEvaluation || taskDetailInfo.status !== 3) &&\n          ((onlyEvaluation &&\n            interfacePermission.completeConvDetailCompliance) ||\n            (!onlyEvaluation && interfacePermission.completeCompliance))\n        "
    }],
    staticClass: "complete-compliance-btn",
    attrs: {
      icon: "iconfont icon-circle-check",
      loading: _vm.completeComplianceLoading
    },
    on: {
      click: _vm.handleCompleteCompliance
    }
  }, [_vm._v("完成质检")]), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localStatus === 2 && (_vm.onlyEvaluation && _vm.interfacePermission.rejectConvDetailCompliance || !_vm.onlyEvaluation && _vm.interfacePermission.rejectCompliance) && (_vm.onlyEvaluation || _vm.taskDetailInfo.status !== 3),
      expression: "\n          localStatus === 2 &&\n          ((onlyEvaluation &&\n            interfacePermission.rejectConvDetailCompliance) ||\n            (!onlyEvaluation && interfacePermission.rejectCompliance)) &&\n          (onlyEvaluation || taskDetailInfo.status !== 3)\n        "
    }],
    staticClass: "reject-compliance-btn",
    attrs: {
      loading: _vm.completeComplianceLoading,
      icon: "iconfont icon-circle-close"
    },
    on: {
      click: _vm.handleRejectCompliance
    }
  }, [_vm._v("驳回")])], 1), _c("div", {
    staticClass: "compliance-progress-buttons"
  }, [_c("div", {
    staticClass: "compliance-shortcut",
    attrs: {
      "data-v-step": "autoComplianceRemarkToNext"
    }
  }, [_c("el-checkbox", {
    on: {
      change: _vm.handleAutoToNext
    },
    model: {
      value: _vm.complianceAutoMoveOnToTheNext,
      callback: function ($$v) {
        _vm.complianceAutoMoveOnToTheNext = $$v;
      },
      expression: "complianceAutoMoveOnToTheNext"
    }
  }), _c("div", [_vm._v("自动进入下一个")])], 1), _c("div", {
    staticClass: "compliance-progress"
  }, [_c("el-button", {
    class: ["prev-btn", {
      "disabled-prev-btn": _vm.currentConversationIdx < 1
    }],
    attrs: {
      plain: "",
      disabled: _vm.currentConversationIdx < 1,
      icon: "iconfont icon-back"
    },
    on: {
      click: function ($event) {
        return _vm.handleClickProgress("prev");
      }
    }
  }, [_vm._v("上一个")]), _vm.allComplianceConversationIds && _vm.allComplianceConversationIds.length - 1 != _vm.currentConversationIdx ? _c("el-button", {
    staticClass: "next-btn",
    attrs: {
      plain: ""
    },
    on: {
      click: function ($event) {
        return _vm.handleClickProgress("next");
      }
    }
  }, [_vm._v("下一个"), _c("i", {
    staticClass: "iconfont icon-right"
  })]) : _vm._e(), !_vm.allComplianceConversationIds || _vm.allComplianceConversationIds.length - 1 == _vm.currentConversationIdx ? _c("el-button", {
    staticClass: "next-btn",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("退出")]) : _vm._e()], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;