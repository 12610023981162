"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-explain"
  }, [_c("div", {
    staticClass: "item bubble-explain"
  }, [_c("div", {
    staticClass: "blue bubble"
  }), _c("div", {
    staticClass: "red bubble"
  }), _c("el-popover", {
    attrs: {
      placement: "right",
      trigger: "hover",
      "popper-class": "business-explain"
    }
  }, [_c("explain-card"), _c("template", {
    slot: "reference"
  }, [_c("i", {
    staticClass: "icon-doubt"
  }, [_c("svg-icon", {
    attrs: {
      iconClass: "question2"
    }
  })], 1)])], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;