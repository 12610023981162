"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationBaseFilters = _interopRequireDefault(require("../conversationFiltersForm/components/ConversationBaseFilters"));
var _ConversationDimensionFilters = _interopRequireDefault(require("../conversationFiltersForm/components/ConversationDimensionFilters"));
var _TradeCustomFilters = _interopRequireDefault(require("../conversationFiltersForm/components/TradeCustomFilters"));
var _CustomerFilters = _interopRequireDefault(require("../conversationFiltersForm/components/CustomerFilters"));
var _ConversationScoreFilters = _interopRequireDefault(require("../conversationFiltersForm/components/ConversationScoreFilters"));
var _ComplianceScoreFilters = _interopRequireDefault(require("../conversationFiltersForm/components/ComplianceScoreFilters"));
var _ConversationCustomFilters = _interopRequireDefault(require("../conversationFiltersForm/components/ConversationCustomFilters"));
var _MoreFilters = _interopRequireDefault(require("@/views/business/MoreFilters"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _business = require("@/api/business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FiltersForm',
  components: {
    ConversationBaseFilters: _ConversationBaseFilters.default,
    ConversationDimensionFilters: _ConversationDimensionFilters.default,
    TradeCustomFilters: _TradeCustomFilters.default,
    CustomerFilters: _CustomerFilters.default,
    ConversationScoreFilters: _ConversationScoreFilters.default,
    ComplianceScoreFilters: _ComplianceScoreFilters.default,
    ConversationCustomFilters: _ConversationCustomFilters.default,
    MoreFilters: _MoreFilters.default
  },
  inject: {
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    }
  },
  props: {
    stageOptions: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    customFilterObject: {
      type: Object,
      default: () => ({})
    },
    customFilterPromise: {
      type: Promise,
      default: null
    },
    // 会话/质检评分规则抽屉中隐藏已保存的筛选项及质检评分筛选项
    hideSavedFilters: {
      type: Boolean,
      default: false
    },
    conversationListCustomFilter: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterParams: {},
      activeNames: ['conversation', 'trade', 'conversationDimension', 'account', 'conversationScore', 'complianceScore'],
      // 会话评分筛选项
      conversationScoreFiltersList: [],
      // 质检评分筛选项
      complianceScoreFiltersList: [],
      // 会话自定义筛选项
      conversationCustomFiltersList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['modulePermission', 'workspaceInfo']),
    // 交易自定义筛选器
    dealFilterList() {
      var _this$customFilterObj;
      if (!this.customFilterObject['交易']) return [];
      return (_this$customFilterObj = this.customFilterObject['交易']) === null || _this$customFilterObj === void 0 ? void 0 : _this$customFilterObj.filter(item => item === null || item === void 0 ? void 0 : item.show);
    },
    // 客户自定义筛选器
    accountFilterList() {
      var _this$customFilterObj2;
      if (!this.customFilterObject['客户']) return [];
      return (_this$customFilterObj2 = this.customFilterObject['客户']) === null || _this$customFilterObj2 === void 0 ? void 0 : _this$customFilterObj2.filter(item => item === null || item === void 0 ? void 0 : item.show);
    }
  },
  watch: {
    'filters.custom_filters': {
      handler(val) {
        this.filterParams = val;
        this.customFilterPromise.then(() => {
          // 会话页选择已保存的筛选器时，设置交易自定义筛选器的显示
          Object.values(this.customFilterObject).forEach(filters => {
            for (const key in val) {
              filters.find(item => {
                if (item.field_name === key) {
                  item.show = 1;
                }
              });
            }
          });
        });
      },
      deep: true
    },
    conversationListCustomFilter: {
      handler(val) {
        if (val && val.length) {
          var _val$find, _val$find2, _val$find3, _val$find3$filters;
          // 会话评分筛选项
          (this.conversationScoreFiltersList = ((_val$find = val.find(filter => filter.name === '会话评分')) === null || _val$find === void 0 ? void 0 : _val$find.filters) || []).map(item => ({
            ...item,
            type: '',
            score_range: []
          }));
          // 质检评分筛选项
          (this.complianceScoreFiltersList = ((_val$find2 = val.find(filter => filter.name === '质检评分')) === null || _val$find2 === void 0 ? void 0 : _val$find2.filters) || []).map(item => ({
            ...item,
            type: '',
            score_range: []
          }));

          // 会话自定义筛选器
          this.conversationCustomFiltersList = (_val$find3 = val.find(filter => filter.name === '会话基本信息')) === null || _val$find3 === void 0 ? void 0 : (_val$find3$filters = _val$find3.filters) === null || _val$find3$filters === void 0 ? void 0 : _val$find3$filters.map(item => {
            return {
              ...item,
              source: 'conversation'
            };
          });
        }
      },
      deep: true
    },
    dealFilterList(newVal, oldVal) {
      this.deleteDecreaseFields(newVal, oldVal);
    },
    accountFilterList(newVal, oldVal) {
      this.deleteDecreaseFields(newVal, oldVal);
    }
  },
  methods: {
    // 清空会话评分筛选项
    clearConversationScore() {
      this.conversationScoreFiltersList.forEach(item => {
        item.type = '';
        item.score_range = [];
      });
      this.triggerScoreFilterChange({
        type: 'conv_score_filters',
        scoreFilterList: this.conversationScoreFiltersList
      });
    },
    // 清空质检评分筛选项
    clearComplianceScore() {
      this.complianceScoreFiltersList.forEach(item => {
        item.type = '';
        item.score_range = [];
      });
      this.triggerScoreFilterChange({
        type: 'compliance_score_filters',
        scoreFilterList: this.complianceScoreFiltersList
      });
    },
    handleScoreChange(_ref) {
      let {
        index,
        data,
        type
      } = _ref;
      switch (type) {
        case 'conv_score_filters':
          this.$set(this.conversationScoreFiltersList, index, {
            ...this.conversationScoreFiltersList[index],
            ...data
          });
          this.triggerScoreFilterChange({
            type,
            scoreFilterList: this.conversationScoreFiltersList
          });
          break;
        case 'compliance_score_filters':
          this.$set(this.complianceScoreFiltersList, index, {
            ...this.complianceScoreFiltersList[index],
            ...data
          });
          this.triggerScoreFilterChange({
            type,
            scoreFilterList: this.complianceScoreFiltersList
          });
          break;
        default:
          break;
      }
    },
    triggerScoreFilterChange(_ref2) {
      let {
        type,
        scoreFilterList
      } = _ref2;
      this.$set(this.filters, type, scoreFilterList.filter(item => item.type));
    },
    // 取消更多筛选勾选，对应的筛选项传参也要删除
    deleteDecreaseFields(newVal, oldVal) {
      const decreaseFields = this.findDecreaseFields(newVal, oldVal);
      const isChange = decreaseFields.some(item => this.filterParams[item]);
      decreaseFields.map(item => {
        delete this.filterParams[item];
      });
      if (isChange) {
        this.customFilterChange(this.filterParams);
      }
    },
    findDecreaseFields(newVal, oldVal) {
      const [before, after] = [oldVal ? oldVal.map(item => item.field_name) : [], newVal ? newVal.map(item => item.field_name) : []];
      return before.filter(item => !after.includes(item));
    },
    async saveFilters(val) {
      const data = {
        customize_field: val,
        access_type: _routeMap.ROUTES.conversation,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _business.postDealCustomFilter)(data);
      if (res.code === 200) {
        this.$emit('getDealCustomFilter');
      }
    },
    // 交易自定义字段的筛选
    customFilterChange(params, val) {
      // 深拷贝一下，不然watch不到this.filters的变化
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.custom_filters = JSON.parse(JSON.stringify(params));
      // this.filters.custom_filters = params;
    }
  }
};

exports.default = _default;