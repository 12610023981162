"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      visible: _vm.uploadFlag,
      width: "700px",
      "close-on-click-modal": false,
      "before-close": _vm.handleClose,
      center: true,
      "modal-append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.uploadFlag = $event;
      }
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticClass: "title-style"
  }, [_c("div", {
    staticClass: "title-name"
  }, [_vm._v("上传音视频")]), _c("div", {
    staticClass: "title-support-name"
  }, [_vm._v("上传要求")]), _c("el-tooltip", {
    staticClass: "format-support",
    attrs: {
      effect: "dark",
      placement: "top"
    }
  }, [_c("span", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" 格式支持："), _c("br"), _vm._v(" mp3，wav，m4a，aiff，acc，ogg，wma，amr，mp4，m4v，avi，wmv，mpeg，3gp，flv。"), _c("br"), _vm._v(" 单次最多上传100个文件，单个文件 最大 4 GB，单个文件最长 3 个小时 ")]), _c("span", {
    staticClass: "iocn-style"
  }, [_c("span", {
    staticClass: "el-icon-info"
  })])])], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fileMediaList.length,
      expression: "fileMediaList.length"
    }]
  }, [_c("div", {
    staticClass: "file-table"
  }, _vm._l(_vm.fileMediaList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "file-table-items-background"
    }, [_c("div", {
      staticClass: "file-table-items",
      on: {
        mouseenter: function ($event) {
          return _vm.mouseEnter($event);
        },
        mouseleave: function ($event) {
          return _vm.mouseLeave($event);
        }
      }
    }, [_c("div", {
      staticClass: "upload-detail"
    }, [_c("div", {
      staticClass: "upload-name"
    }, [_c("i", {
      staticClass: "el-icon-document"
    }), _c("span", [_vm._v(_vm._s(item.name))])]), _c("div", {
      staticClass: "env-del-btn-span",
      on: {
        click: function ($event) {
          return _vm.removeFile(item, index);
        }
      }
    }), _c("div", {
      staticClass: "file-table-items-percentage"
    }, [item.percentage !== 100 && item.percentage !== 0 && item.status !== "fail" ? _c("span", [_vm._v(_vm._s(item.percentage) + "%")]) : _vm._e(), item.percentage === 100 && !item.text ? _c("span", [_c("i", {
      staticClass: "el-icon-loading"
    })]) : _vm._e(), item.percentage === 0 && item.text !== "" ? _c("span", [_vm._v(_vm._s("等待上传"))]) : _vm._e(), item.text === "Success" ? _c("i", {
      staticClass: "el-icon-circle-check"
    }) : _vm._e(), item.text !== "Success" && item.text ? _c("span", [_c("span", {
      staticClass: "risk-text"
    }, [_vm._v(_vm._s(item.text))]), _c("i", {
      staticClass: "el-icon-circle-close"
    })]) : _vm._e()])]), item.percentage != 100 ? _c("div", [_c("el-progress", {
      attrs: {
        percentage: item.percentage = item.percentage ? item.percentage : 0,
        "stroke-width": 2,
        color: "#4461EC",
        "text-inside": true
      }
    })], 1) : _vm._e()])]);
  }), 0)]), _c("div", {
    class: {
      "upload-style": !_vm.fileMediaList.length
    },
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.fileMediaList.length ? _c("div", {
    staticStyle: {
      "margin-top": "9px"
    }
  }, [_vm._v(" 共" + _vm._s(_vm.fileMediaList.length) + "条音视频 ")]) : _vm._e(), _c("upload-media", {
    ref: "upload",
    attrs: {
      "is-drag": !_vm.fileMediaList.length ? true : false
    },
    on: {
      select: _vm.uploadFileList
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.formData
    }
  }, [_c("div", {
    staticClass: "videoLang"
  }, [_vm._v("音频语言")]), _c("el-form-item", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "baseline",
      "margin-top": "-15px"
    }
  }, [_c("div", {
    staticClass: "proLang"
  }, [_vm._v("专业领域")]), _c("div", {
    staticClass: "pro-style"
  }, [_vm._v("选择专业领域，可提升相对识别效果")])]), _c("el-form-item", [_c("el-radio-group", {
    staticStyle: {
      display: "flex"
    },
    model: {
      value: _vm.radio,
      callback: function ($$v) {
        _vm.radio = $$v;
      },
      expression: "radio"
    }
  }, _vm._l(_vm.radioList, function (item) {
    return _c("el-radio", {
      key: item.key,
      attrs: {
        label: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1)], 1), _c("div", {
    staticClass: "button-style"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), !_vm.finalFlag && this.fileMediaList.length !== 0 ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("提交分析")]) : _vm._e(), _vm.finalFlag ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleMini
    }
  }, [_vm._v("最小化")]) : _vm._e()], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;