"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateKnowledgeDrawer = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/CreateKnowledgeDrawer.vue"));
var _TaskCenterExportButton = _interopRequireDefault(require("@/components/TaskCenterExportButton"));
var _CreateDate = _interopRequireDefault(require("@/components/Filters/components/CreateDate"));
var _AnswerItem = _interopRequireDefault(require("./AnswerItem.vue"));
var _MoveFreqQuestionDialog = _interopRequireDefault(require("./MoveFreqQuestionDialog"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AnswerList',
  components: {
    CreateKnowledgeDrawer: _CreateKnowledgeDrawer.default,
    CreateDate: _CreateDate.default,
    AnswerItem: _AnswerItem.default,
    NoData: _index.default,
    TaskCenterExportButton: _TaskCenterExportButton.default,
    MoveFreqQuestionDialog: _MoveFreqQuestionDialog.default
  },
  props: {
    currentQuestion: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      hasMore: false,
      page: 1,
      convList: [],
      answerFilters: {
        conv_date: [],
        tree_ids: [],
        user_ids: []
      },
      drawerVisible: false,
      qaIds: [],
      moveFreqQuestionDialogVisible: false,
      currentQaItem: {} // 当前销售回答问答对
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    requestParams() {
      const {
        create_at,
        begin_time,
        user_ids,
        tree_ids,
        custom_filters
      } = this.params;
      return {
        q_id: this.currentQuestion.id,
        space_id: this.workspaceInfo.id,
        date: [],
        events: [],
        is_add: -1,
        answer_filters: this.answerFilters,
        time_zone: this.timezone,
        time_sort: '',
        create_at,
        begin_time,
        user_ids,
        tree_ids,
        custom_filters
      };
    }
  },
  watch: {
    'currentQuestion.id': {
      handler: function () {
        this.init();
      }
    },
    params() {
      this.init();
    }
  },
  methods: {
    // 新建问题并移动，需要刷新问题列表和回答列表
    refreshQuestionAndQa() {
      this.init();
      this.$emit('refreshCurrentPageQuestions');
    },
    moveToFreqQuestion(item) {
      this.currentQaItem = item;
      this.moveFreqQuestionDialogVisible = true;
    },
    addSingleQa(item) {
      const checkedList = [{
        ...item,
        checked: true
      }];
      this.backfillDrawerData(checkedList);
    },
    backfillDrawerData(checkedList) {
      const config = this.getKnowledgeConfig(checkedList);
      this.qaIds = checkedList.map(_ref => {
        let {
          qa_id
        } = _ref;
        return qa_id;
      });
      this.drawerVisible = true;
      this.$refs.drawer.backfillConfig(config);
    },
    getKnowledgeConfig(list) {
      const qaList = list.map(i => i.replys).flat();
      const questions = qaList.filter(i => i.type === 'question').map(i => i.content);
      const replys = list.map(i => i.replys.filter(i => i.type === 'answer').reduce((a, b) => a + ' ' + b.content, '')).map(i => ({
        reply_id: 0,
        reply_content: i
      }));
      const replyList = [{
        scene_id: 0,
        scene_content: '',
        reply_list: replys
      }];
      return {
        name: '',
        description: '',
        position: 0,
        skill_list: [''],
        question_list: questions,
        scene_reply_list: replyList
      };
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    async exportData(callback) {
      const res = await (0, _businessIntelligence.exportFAQAnswerList)(this.requestParams);
      if (res.code === 20000) {
        callback();
      }
    },
    memberChange(val) {
      Object.assign(this.answerFilters, val);
      this.init();
    },
    dateChange(val) {
      this.answerFilters.conv_date = val.begin_time;
      this.init();
    },
    createTimeOptions(name, field_name) {
      return {
        field_name,
        name,
        options: [['本周', '本月', '上周', '上月']],
        type: 'date'
      };
    },
    async handleMoveFreqQuestion(q_id) {
      const res = await (0, _businessIntelligence.moveFreqQuestion)({
        q_id,
        // 问题 id
        qa_id: this.currentQaItem.qa_id,
        // 问答对 id
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('移动成功');
        this.moveFreqQuestionDialogVisible = false;
        this.init();
      }
    },
    init() {
      this.convList = [];
      this.page = 1;
      this.getConvList();
    },
    async getConvList() {
      this.loading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeQaList)({
        ...this.requestParams,
        page: this.page,
        size: 10
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          has_more,
          cases
        } = res.results;
        if (cases.length) {
          this.convList.push(...cases);
        }
        this.hasMore = has_more;
        this.page++;
      }
    }
  }
};
exports.default = _default;