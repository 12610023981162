"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _noteAndEditForm = _interopRequireDefault(require("./noteAndEditForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "AfterCloseConv",
  props: {
    conv_id: {
      type: [String, Number],
      default: ""
    },
    lead_id: {
      type: [String, Number],
      default: ""
    },
    deal_id: {
      type: [String, Number],
      default: ""
    },
    isNewLead: {
      type: Boolean,
      default: false
    }
  },
  mixins: [_noteAndEditForm.default],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
exports.default = _default;