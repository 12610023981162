"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationPage = _interopRequireDefault(require("@/views/conversationPage"));
var _SetNameDialog = _interopRequireDefault(require("./SetNameDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SetOptionConvDrawer',
  components: {
    ConvListPage: _conversationPage.default,
    SetNameDialog: _SetNameDialog.default
  },
  provide() {
    return {
      isBaseConvInDrawer: true,
      isInQualityTesting: true
    };
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '配置筛选条件'
    },
    tipContent: {
      type: String,
      default: ''
    },
    backFillFilters: {
      type: Object,
      default: () => ({})
    },
    noTip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      convFilters: {},
      filterNum: 0,
      setNameDialogVisible: false
    };
  },
  methods: {
    handleNext() {
      this.setNameDialogVisible = true;
    },
    handleSave(name) {
      this.$emit('saveOptionConvFilters', this.convFilters, name);
      this.handleClose();
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.setNameDialog.resetFields();
      });
      this.$emit('update:drawerVisible', false);
    },
    convFlowFiltersChange(filters) {
      this.convFilters = filters;
    },
    filterNumChange(val) {
      this.filterNum = val;
    }
  }
};
exports.default = _default;