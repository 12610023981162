"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UploadMediaDialog = _interopRequireDefault(require("@/components/UploadMediaDialog"));
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _vuex = require("vuex");
var _ClueOperateButtons = _interopRequireDefault(require("./ClueOperateButtons.vue"));
var _ClueTranslateAccountDialog = _interopRequireDefault(require("@/views/clueDetail/components/ClueTranslateAccountDialog"));
var _doubleCallMixins = _interopRequireDefault(require("@/views/clients/doubleCallMixins"));
var _clueAndClientSortMixin = _interopRequireDefault(require("@/mixin/clueAndClientSortMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClueTable',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ClueOperateButtons: _ClueOperateButtons.default,
    MeetingDialog: _MeetingDialog.default,
    UploadMediaDialog: _UploadMediaDialog.default,
    clueTranslateAccountDialog: _ClueTranslateAccountDialog.default
  },
  mixins: [_doubleCallMixins.default, _clueAndClientSortMixin.default],
  props: {
    headerList: {
      type: Array,
      default() {
        return null;
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    doubleCallPermission: {
      type: Number,
      default: 0
    },
    sortColumnList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pageType: 'clue',
      uploadMediaDialogVisible: false,
      rowInfo: {},
      activeClueId: null,
      tableHeight: document.body.clientHeight - 126,
      clueList: [],
      meetingDialogVisible: false,
      meetingDialogTitle: '安排在线会议',
      meetingProps: {},
      clueTranslateAccountDialogVisible: false,
      clueId: '',
      mediaRowInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'has_video_permission', 'api_permissions']),
    dropdownVisible() {
      return this.api_permissions.includes('/api/lead/lead/edit_follow_up_by');
    },
    operateColumnVisible() {
      const arr = ['/api/lead/lead/edit_follow_up_by', '/api/lead/lead/lead_to_account'];
      const res = arr.every(element => this.api_permissions.includes(element));
      return res || this.has_outbound || this.has_video_permission;
    },
    operateColumnWidth() {
      const followerPermission = this.api_permissions.includes('/api/lead/lead/edit_follow_up_by');
      const leadTranslateButtonPermission = this.api_permissions.includes('/api/lead/lead/lead_to_account');
      // eslint-disable-next-line eqeqeq
      const arr = this.operateButtons.filter(item => item.isShow == true);
      if (followerPermission && leadTranslateButtonPermission) {
        return ((arr === null || arr === void 0 ? void 0 : arr.length) + 2) * 60;
      } else {
        return ((arr === null || arr === void 0 ? void 0 : arr.length) + 1) * 60;
      }
    },
    operateButtons() {
      if (this.doubleCallPermission) {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'doubleCall',
          text: '双呼',
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isCircle: true,
          isShow: this.has_video_permission
        }];
      } else {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isCircle: true,
          isShow: this.has_video_permission
        }];
      }
    }
  },
  watch: {
    list() {
      this.$nextTick(() => {
        this.$refs['clueTable'].doLayout();
      });
    }
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    selectable(row, index) {
      return row.status === 'unconverted' && row.has_edit;
    },
    tableRowClassName(_ref) {
      let {
        row,
        rowIndex
      } = _ref;
      if (row.highlight === true && rowIndex === 0) {
        return 'highlight-row';
      }
      return '';
    },
    handleRowClick(row) {
      this.$emit('rowClick', row.id);
    },
    handleSelectionChange(val) {
      this.$emit('clueSelectionChange', val);
    },
    changeFollowUpBy(ids) {
      this.$emit('changeFollowUpBy', ids, 'clueChangeFollower');
    },
    freshCurrentList() {
      this.$emit('freshCurrentList');
    },
    formatterCellValue(row, column, cellValue, index) {
      return cellValue || '-';
    },
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 262;
    },
    clueTranslateAccount(row) {
      this.clueId = row.id;
      this.openClueTranslateAccountDialog();
    },
    openClueTranslateAccountDialog() {
      this.clueTranslateAccountDialogVisible = true;
    },
    closeClueTranslateAccountDialog() {
      this.clueTranslateAccountDialogVisible = false;
    },
    clueTranslateAccountSuccess() {
      this.$emit('clueTranslateAccountSuccess');
    },
    handleCommand(row, command) {
      switch (command) {
        case 'changeFollowUpBy':
          // eslint-disable-next-line no-case-declarations
          const followerIds = [row.id];
          this.changeFollowUpBy(followerIds);
          break;
      }
    },
    changeactiveClueId(id) {
      this.activeClueId = id;
    },
    callOut(item, row) {
      if (!this.has_outbound) {
        this.$message.warning('暂无坐席');
        return;
      }
      const data = {
        id: row.id,
        type: 'clue',
        company: row.company,
        contact_id: '',
        contact_person: item.name,
        phone: item.tel
      };
      this.$bus.$emit('openPhoneCall', data);
    },
    createMeeting(contactItem, clientInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      // eslint-disable-next-line no-unused-vars
      const {
        id,
        name
      } = clientInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName,
        tel: contactTel
      } = contactItem;
      this.meetingProps = {
        type: 'clue',
        cluesId: id,
        customerName: '',
        contactOptions,
        contactId,
        contactName,
        contactTel,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    clickContactPersonItem(contactItem, rowInfo, type, contactList, meetingType) {
      switch (type) {
        case 'upload':
          this.openUploadMediaDialog(contactItem, rowInfo, contactList);
          break;
        case 'callout':
          this.callOut(contactItem, rowInfo);
          break;
        case 'createMeeting':
          this.createMeeting(contactItem, rowInfo, contactList, meetingType);
          break;
        case 'doubleCall':
          this.doubleCallOut(contactItem, rowInfo, 'lead');
          break;
        default:
          break;
      }
    },
    // 打开上传dialog
    openUploadMediaDialog(contactItem, rowInfo, contactList) {
      const {
        id,
        name
      } = contactItem;
      const currentContactInfo = {
        id,
        name
      };
      const contactInfo = {
        currentContactInfo,
        contactList
      };
      const clientInfo = {
        id: rowInfo.id,
        name: rowInfo.name
      };
      this.mediaRowInfo = {
        contactInfo,
        clientInfo,
        rowInfo
      };
      this.uploadMediaDialogVisible = true;
    },
    // 关闭上传Dialog
    closeUploadMediaDialog() {
      this.$refs['upload-media-dialog'].mediaFileList = [];
      this.uploadMediaDialogVisible = false;
    }
  }
};
exports.default = _default;