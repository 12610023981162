"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TricksScene = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksScene.vue"));
var _PracticeAudioRecorder = _interopRequireDefault(require("./PracticeAudioRecorder.vue"));
var _PlayVoiceMixin = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoiceMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskPracticeCard',
  components: {
    TricksScene: _TricksScene.default,
    PracticeAudioRecorder: _PracticeAudioRecorder.default
  },
  mixins: [_PlayVoiceMixin.default],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    courseType: {
      type: [Number, String],
      default: ''
    },
    practiceCount: {
      type: Number,
      default: 1
    },
    lowestScore: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      localList: [],
      incompleteId: '',
      isReciting: false,
      showScoringAnimationReplyId: 0
    };
  },
  watch: {
    list(val) {
      this.localList = this.formatLocalList(val);
    }
  },
  methods: {
    isComplete(reply) {
      return !(this.practiceCount > 1 && reply.repeat_count === this.practiceCount || this.practiceCount === 1 && reply.reply_score >= this.lowestScore) && this.showScoringAnimationReplyId === reply.reply_id;
    },
    judgeIncompletePractice() {
      // 点击下一步时滚动到未完成的练习题处并标红
      const trickItems = [];
      this.localList.forEach(item => {
        if (item.reply_list) {
          trickItems.push(...item.reply_list);
        }
      });
      if (!trickItems.length) return -1;
      const incompleteItem = trickItems.find(c => !(c.repeat_count === this.practiceCount));
      if (incompleteItem) {
        this.incompleteId = incompleteItem.reply_id;
      }
      return trickItems.findIndex(c => (c === null || c === void 0 ? void 0 : c.reply_id) === this.incompleteId);
    },
    formatLocalList(list) {
      let order = 1;
      const resList = JSON.parse(JSON.stringify(list));
      resList.forEach(trick => {
        var _trick$reply_list;
        (_trick$reply_list = trick.reply_list) === null || _trick$reply_list === void 0 ? void 0 : _trick$reply_list.forEach(i => {
          i.order = order++;
        });
      });
      return resList;
    },
    handleRecording(val) {
      this.pause();
      // 录制未完成的练习时，取消提示的红色背景
      if (val === this.incompleteId) this.incompleteId = '';
      // 背诵模式开始录音展示音频动画
      this.courseType === 2 && (this.isReciting = true);
    },
    // 停止录音
    stopRecord() {
      // 背诵模式停止录音取消音频动画
      this.isReciting = false;
      this.$emit('stopRecord', true);
    },
    // 录音结束并上传完评分(录音事件长的时候异步评分is_async为true，此时也算答完题)
    handleFinished(score, is_async, reply_id, repeat_count, reply_record) {
      this.localList.forEach(item => {
        item.reply_list.forEach(c => {
          if (c.reply_id === reply_id) {
            c.reply_score = score;
            c.repeat_count = repeat_count;
            c.reply_record = reply_record;
            // (录音事件长的时候异步评分is_async为true，此时也算答完题)
            c.is_async = is_async;
            // 得分大于规则分数，显示太棒了动画
            if (!is_async) {
              this.showScoringAnimationReplyId = reply_id;
              setTimeout(() => {
                this.showScoringAnimationReplyId = null;
              }, 1000);
            }
          }
        });
      });
      // 答完当前题后需要重新计算答题卡的平均分
      this.$emit('scoreFinished');
    }
  }
};
exports.default = _default;