"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _ContactFormCard = _interopRequireDefault(require("./ContactFormCard"));
var _vuex = require("vuex");
var _business = require("@/api/business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DynamicForm: _DynamicForm.default,
    ContactFormCard: _ContactFormCard.default
  },
  props: {
    type: {
      type: String,
      validator: val => ['create', 'edit'].includes(val),
      default: ''
    },
    dealId: {
      type: [Number, String],
      default: ''
    },
    leadId: {
      type: [Number, String],
      default: ''
    },
    visible: Boolean,
    isAfterConv: Boolean // 是否是会话后置页
  },

  data() {
    return {
      formDesc: [],
      formData: {},
      isEdit: true,
      decisionMakerIsEdit: true,
      loading: false,
      stage: 1,
      // 商机阶段
      stageKey: 0,
      // 商机阶段在desc中的key
      contactsTabs: 'saveLoading',
      contactFormDesc: [{
        id: 'name',
        type: 'text',
        label: '姓名',
        required: true
      }, {
        id: 'tel',
        type: 'tel',
        label: '手机号',
        preset: true
      }, {
        id: 'email',
        type: 'email',
        label: '邮箱',
        preset: true
      }, {
        id: 'position',
        type: 'text',
        label: '职位'
      }],
      decisionMaker: {},
      everDecisionMaker: {},
      others: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (this.type === 'create' && this.leadId) {
            this.getLeadTransDealFormInfo();
          }
          this.clearValidate();
        }
      },
      immediate: true
    }
  },
  provide() {
    return {
      descFormat: this.descFormat,
      isEmptyForm: this.isEmptyForm
    };
  },
  created() {
    this.init();
    this.getDynamicForm();
    this.$store.dispatch('business/get_business_status_options');
    if (this.type === 'edit') {
      this.isEdit = false;
      this.decisionMakerIsEdit = false;
      this.fetchData();
    }
    if (this.isAfterConv) {
      this.isEdit = true;
      this.decisionMakerIsEdit = true;
    }
  },
  methods: {
    init() {
      switch (this.type) {
        case 'create':
          this.isEdit = true;
          this.decisionMakerEnterEdit = true;
          break;
        default:
          this.isEdit = false;
          this.decisionMakerIsEdit = false;
          break;
      }
      this.contactsTabs = 'decisionMaker';
    },
    // 商机点保存
    async saveDeal() {
      const res = await (0, _business.editBusiness)({
        deal_id: this.dealId,
        workspace_id: this.workspaceInfo.id,
        ...this.formData
      });
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('修改成功');
        this.isEdit = false;
      }
    },
    async saveDecisionMaker() {
      const res = await (0, _business.updateDealContacts)(this.decisionMaker);
      if (res.code === 200 && res.msg === 'Success') {
        this.$message.success('修改成功');
        this.decisionMakerIsEdit = false;
      }
    },
    decisionMakerEnterEdit() {
      this.everDecisionMaker = {
        ...this.decisionMaker
      };
      this.decisionMakerIsEdit = true;
    },
    handleContactFormCommand(command, index) {
      switch (command) {
        case 'delete':
          this.deleteOtherContact(index);
          break;
        case 'setMain':
          this.setMain(index);
          break;
      }
    },
    // 判断一个表单是否为空
    isEmptyForm(form) {
      const {
        name,
        tel,
        position,
        email
      } = form;
      return !name && !tel && !position && !email;
      // return !Object.values(form).some((item) => item);
    },

    validateOtherForm(index) {
      const {
        name,
        tel,
        email
      } = this.others[index];
      const contact = tel || email;
      return name && contact;
    },
    formValidate() {
      const flags = [];
      flags.push(this.$refs.form1.validate());
      flags.push(this.$refs.form2.validate());
      return !flags.includes(false);
    },
    submit() {
      if (this.formValidate()) {
        this.createDeal();
      }
    },
    // 只有会话后置页才需要
    async updateFullDeal() {
      if (this.formValidate()) {
        const data = {
          deal: {
            ...this.formData,
            deal_id: this.dealId
          },
          main: this.decisionMaker,
          other: this.getClearOthers()
        };
        const res = await (0, _business.updateFullDeal)(data);
        if (res.code === 200) {
          this.$emit('submitSucceed');
        }
      }
    },
    // 除formDesc外全部回归初始值
    initForm() {
      const {
        ...others
      } = this.$options.data();
      Object.assign(this.$data, others);
      this.clearValidate();
    },
    async createDeal() {
      this.formData[this.stageKey] = this.stage;
      const res = await (0, _business.createBusiness)({
        deal: this.formData,
        main: this.decisionMaker,
        other: this.getClearOthers(),
        lead_id: this.leadId
      });
      if (res.code === 200) {
        this.$message.success(this.leadId ? '转换成功' : '新建成功');
        this.initForm();
        this.$emit('submitSucceed', res.results.deal_id, this.formData);
      }
    },
    // 获取所有数据
    fetchData() {
      this.getDealFormInfo();
      this.getDealContacts();
    },
    async getDealFormInfo() {
      const res = await (0, _business.getDealFormInfo)({
        deal_id: this.$props.dealId,
        workspace_id: this.workspaceInfo.id
      });
      this.formData = res.results;
      setTimeout(() => {
        this.stage = res.results[this.stageKey];
      }, 0);
    },
    async getDealContacts() {
      const res = await (0, _business.getDealContacts)({
        deal_id: this.$props.dealId
      });
      const {
        main,
        other
      } = res.results;
      this.decisionMaker = main;
      this.others = other;
      if (!this.others.length) {
        this.contactsTabs = 'decisionMaker';
      }
    },
    async getLeadTransDealFormInfo() {
      const res = await (0, _business.getLeadTransDealFormInfo)({
        lead_id: this.leadId
      });
      const {
        deal,
        contact
      } = res.results;
      this.formData = deal;
      this.decisionMaker = contact;
      this.clearValidate();
    },
    // 商机表单点击取消
    dealFormCancel() {
      this.isEdit = false;
      this.$refs.form1.clearValidate();
    },
    // 决策人点击取消
    decisionMakerFormCancel() {
      this.decisionMaker = {
        ...this.everDecisionMaker
      };
      this.decisionMakerIsEdit = false;
      this.$refs.form2.clearValidate();
    },
    // 清除所有表单的校验
    clearValidate() {
      this.$nextTick(() => {
        this.$refs.form1 && this.$refs.form1.clearValidate();
        this.$refs.form2 && this.$refs.form2.clearValidate();
      });
    },
    // 获取表单配置
    async getDynamicForm() {
      const res = await (0, _business.getDynamicForm)();
      if (res.code === 200) {
        const desc = this.descFormat(res.results);
        this.formDesc = desc;
      }
    },
    // 修改商机阶段
    async changeStage(val) {
      if (this.$props.type === 'edit') {
        const res = await (0, _business.changeDealStage)({
          deal_id: this.$props.dealId,
          stage: val
        });
        if (res.code === 200 && res.msg === 'Success') {
          this.$message.success('修改成功');
          this.stage = val;
        }
      } else {
        this.stage = val;
      }
    },
    // 表单描述格式化
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const {
          id,
          name: label,
          from_type: type,
          is_require: required,
          field_type,
          is_unique: unique,
          tips
        } = item;
        if (label === '商机阶段') {
          this.stageKey = item.id;
        } else {
          desc.push({
            id: String(id),
            label,
            type,
            required: !!required,
            unique,
            preset: !!field_type,
            action: '',
            tips
          });
        }
      }
      return desc;
    },
    // 添加一个新的表单并且设置为编辑模式
    addContact(isBtn) {
      const extraField = {
        entity_id: this.dealId,
        entity_type: 'deal',
        is_main: 0
      };
      const other = {
        name: '',
        email: '',
        tel: '',
        position: ''
      };
      this.others.push({
        ...other,
        ...(this.type === 'create' ? {} : extraField)
      });
      this.$nextTick(() => {
        this.$refs[`otherForm${this.others.length - 1}`][0].isEdit = true;
        if (isBtn) {
          this.contactsTabs = 'others';
        }
      });
    }
  }
};
exports.default = _default;