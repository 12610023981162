"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban"));
var _BusinessTransPieChart = _interopRequireDefault(require("./components/BusinessTransPieChart"));
var _BusinessTransTable = _interopRequireDefault(require("./components/BusinessTransTable.vue"));
var _BusinessTransRankingList = _interopRequireDefault(require("./components/BusinessTransRankingList.vue"));
var _BusinessTransKanbanCreate = _interopRequireDefault(require("./components/BusinessTransKanbanCreate.vue"));
var _BusinessTransChartFootnote = _interopRequireDefault(require("./components/BusinessTransChartFootnote.vue"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _editKanbanNameDialog = _interopRequireDefault(require("@/views/businessIntelligence/components/editKanbanNameDialog"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _timezone = require("@/utils/timezone");
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TransAndLoss',
  components: {
    InitKanbanCardView: _InitKanbanCardView.default,
    BIKanban: _BIKanban.default,
    BusinessTransPieChart: _BusinessTransPieChart.default,
    BusinessTransTable: _BusinessTransTable.default,
    BusinessTransRankingList: _BusinessTransRankingList.default,
    BusinessTransKanbanCreate: _BusinessTransKanbanCreate.default,
    BusinessTransChartFootnote: _BusinessTransChartFootnote.default,
    NoData: _NoData.default,
    ShareConfig: _shareConfig.default,
    EditKanbanNameDialog: _editKanbanNameDialog.default
  },
  data() {
    return {
      kanbanList: [],
      currentKanban: {},
      drawerVisible: false,
      chartData: {},
      chartFootnote: {},
      isEdit: false,
      kanbanListLoading: true,
      kanbanConfigLoading: false,
      chartLoading: false,
      rankingListVisible: false,
      pieSelection: null,
      showInvalidDataTip: true,
      operateType: 'create',
      shareKanbanConfigVisible: false,
      copyKanbanConfigVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone']),
    formatEventLabel() {
      if (!this.chartFootnote.event) return '';
      const endStr = this.chartFootnote.event.length > 3 ? '等' : '';
      return this.chartFootnote.event.slice(0, 3).join('、') + endStr;
    }
  },
  watch: {
    biFiltersObj() {
      if (this.kanbanList.length) {
        this.getData();
      } else {
        this.getKanbanList();
      }
    },
    'workspaceInfo.id': {
      handler: function () {
        this.kanbanList = [];
      }
    },
    rankingListVisible(newVal, oldVal) {
      if (oldVal && !newVal && this.kanbanList.length) {
        this.$refs['chart'].drawChart();
      }
    }
  },
  methods: {
    async editFunnelFieldName(data, callback, isUpdate) {
      const res = await (0, _businessIntelligence.editFunnelFieldName)({
        workspace_id: this.workspaceInfo.id,
        kanban_id: this.currentKanban.kanban_id,
        field_map: data
      }).finally(() => {
        callback && callback();
      });
      if (res.code === 20000) {
        this.$message.success('修改成功');
        if (isUpdate) {
          for (const key in this.chartFootnote) {
            if (Object.keys(data).includes(this.chartFootnote[key]['field_key'])) {
              this.chartFootnote[key].name = data[this.chartFootnote[key]['field_key']] || this.chartFootnote[key].origin_name;
            }
          }
        }
      }
    },
    changeKanbanStatus(val) {
      this.kanbanList.find(item => item.kanban_id === this.currentKanban.kanban_id).is_sharing = !!val.length;
    },
    getExtraCommandlist() {
      return [{
        type: 'copy',
        label: '复制',
        order: 2,
        icon: 'icon-copy-document'
      }, {
        type: 'share',
        label: '分享',
        order: 3,
        icon: 'icon-share-line'
      }];
    },
    async getKanbanList() {
      if (!this.workspaceInfo.id || !this.biFiltersObj) return;
      this.kanbanListLoading = true;
      const res = await (0, _businessIntelligence.getKanbanList)({
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.kanbanListLoading = false;
      });
      if (res.code === 200) {
        this.kanbanList = res.results.kanban_list.map(item => {
          item.hiddenHandle = [];
          if (item.is_share) {
            item.hiddenHandle.push('edit', 'delete', 'share');
            item.kanban_remark = `来自${item.share_user_name}的分享`;
          }
          return item;
        });
      }
      if (this.kanbanList.length) {
        if (!this.currentKanban.kanban_id) {
          this.currentKanban = this.kanbanList[0];
        } else if (!this.isEdit) {
          this.currentKanban = this.kanbanList[this.kanbanList.length - 1];
        }
        this.getData();
      }
    },
    getData() {
      if (!this.kanbanList.length) return;
      this.getChartData();
      this.$nextTick(() => {
        this.$refs['table'].getTableData();
        this.$refs['table'].clearSortStatus();
      });
    },
    async getChartData() {
      if (!this.workspaceInfo.id || !this.biFiltersObj) return;
      this.showInvalidDataTip = false;
      this.chartLoading = true;
      const {
        custom_filters,
        date,
        tree_user_ids: {
          tree_ids,
          user_ids
        }
      } = this.biFiltersObj;
      const customFilters = (0, _commonFunc.deepClone)(custom_filters);
      (0, _timezone.convertParams2UTCplus8)(customFilters);
      const params = {
        custom_filters: customFilters,
        date,
        tree_ids,
        user_ids,
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      const res = await (0, _businessIntelligence.getFunnelChartData)(params).finally(() => {
        this.chartLoading = false;
      });
      if (res.code === 200) {
        const {
          rows,
          total,
          type
        } = res.results;
        const {
          event
        } = rows[0];
        this.chartFootnote = {
          event,
          type,
          ...total
        };
      }
      if (res.code === 43102) {
        this.showInvalidDataTip = true;
      }
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.currentKanban = activeKanban;
      this.getData();
    },
    createKanban() {
      this.isEdit = false;
      this.drawerVisible = true;
    },
    handleKanban(type, kanban) {
      kanban.workspace_id = this.workspaceInfo.id;
      this.operateType = type;
      this[`${type}Kanban`](kanban);
    },
    async copyKanban() {
      this.copyKanbanConfigVisible = true;
    },
    async shareKanban() {
      this.isEdit = true;
      this.shareKanbanConfigVisible = true;
    },
    async editKanban(_ref) {
      let {
        kanban_id,
        workspace_id
      } = _ref;
      this.isEdit = true;
      this.drawerVisible = true;
      this.kanbanConfigLoading = true;
      const res = await (0, _businessIntelligence.getKanbanConfig)({
        kanban_id,
        workspace_id
      }).finally(() => {
        this.kanbanConfigLoading = false;
      });
      res.code === 200 && this.backFillDrawerData(res.results);
    },
    backFillDrawerData(data) {
      const {
        name,
        kanban_info: {
          events,
          analysis_scope,
          results
        }
      } = data;
      const timeReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/;
      if (Array.isArray(results === null || results === void 0 ? void 0 : results.value) && results !== null && results !== void 0 && results.value.length && results !== null && results !== void 0 && results.value.every(c => timeReg.test(c))) {
        results.value = (0, _timezone.datePickerConvertToZone)(results.value);
      }
      let scope = {};
      if (events) {
        scope = {
          key: events,
          type: 'events',
          value: '',
          name: ''
        };
      } else {
        scope = analysis_scope;
      }
      this.$refs['drawer'].kanbanConfig = {
        name,
        analysis_scope: scope || this.$refs['drawer'].kanbanConfig.analysis_scope,
        results: results || this.$refs['drawer'].kanbanConfig.results
      };
    },
    deleteKanban(_ref2) {
      let {
        kanban_id,
        workspace_id,
        name
      } = _ref2;
      this.$confirm(`看板删除后，被分享人员的看板也会同步删除，是否删除？`, '删除看板', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteKanban)({
          kanban_id,
          workspace_id
        });
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== kanban_id);
          if (this.kanbanList.length) {
            this.toggleKanban(this.kanbanList[0]);
          }
        }
      });
    },
    showRankingList() {
      this.rankingListVisible = true;
    },
    updateChartData(chartData) {
      this.chartData = chartData;
    },
    handleChartSelectChange(params) {
      this.pieSelection = params;
    }
  }
};
exports.default = _default;