"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _TrickItem = _interopRequireDefault(require("./TrickItem.vue"));
var _TricksScene = _interopRequireDefault(require("./TricksScene.vue"));
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _PlayVoice = _interopRequireDefault(require("./PlayVoice.vue"));
var _PlayVoiceMixin = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/PlayVoiceMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeItemTricks',
  components: {
    TrickItem: _TrickItem.default,
    TricksScene: _TricksScene.default,
    MgDraggable: _MgDraggable.default,
    PlayVoice: _PlayVoice.default
  },
  mixins: [_PlayVoiceMixin.default],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    showDragIcon() {
      return replyList => {
        return !this.disabled && this.interfacePermission.operateKnowledge && replyList.length > 1;
      };
    }
  },
  methods: {
    async handleDragEnd(_ref) {
      let {
        scene_id,
        reply_list
      } = _ref;
      const params = {
        scene_id,
        reply_ids: reply_list.map(i => i.reply_id)
      };
      await (0, _knowledgeLibrary.updateTricksOrder)(params);
    }
  }
};
exports.default = _default;