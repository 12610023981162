"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'ScoreAnalysisCard',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    filtersConfig: {
      type: Object,
      default: () => ({})
    },
    filterDate: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      filters: {
        score_rule_id: 0,
        view_type: 1,
        column: 'score_percent',
        page: 1,
        size: 8
      },
      loading: false,
      dataListConfig: {},
      singleStep: [{
        content: '标题可切换评分规则 / 指标项 / 部门或成员 快速查看',
        target: '[data-v-step="sop-overview"]',
        placement: 'bottom'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone', 'workspaceInfo']),
    scoreRuleOptions() {
      var _this$filtersConfig;
      return ((_this$filtersConfig = this.filtersConfig) === null || _this$filtersConfig === void 0 ? void 0 : _this$filtersConfig.socre_rules) || [];
    },
    dimensionOptions() {
      var _this$scoreRuleOption;
      return ((_this$scoreRuleOption = this.scoreRuleOptions.find(item => item.id === this.filters.score_rule_id)) === null || _this$scoreRuleOption === void 0 ? void 0 : _this$scoreRuleOption.column) || [];
    },
    checkedScoreRuleLable() {
      var _this$scoreRuleOption2;
      return ((_this$scoreRuleOption2 = this.scoreRuleOptions.find(item => item.id === this.filters.score_rule_id)) === null || _this$scoreRuleOption2 === void 0 ? void 0 : _this$scoreRuleOption2.name) || '请选择';
    },
    checkedDimensionLable() {
      var _this$dimensionOption;
      return ((_this$dimensionOption = this.dimensionOptions.find(item => item.key === this.filters.column)) === null || _this$dimensionOption === void 0 ? void 0 : _this$dimensionOption.name) || '请选择';
    },
    configListLength() {
      var _this$dataListConfig, _this$dataListConfig$;
      return (_this$dataListConfig = this.dataListConfig) === null || _this$dataListConfig === void 0 ? void 0 : (_this$dataListConfig$ = _this$dataListConfig.list) === null || _this$dataListConfig$ === void 0 ? void 0 : _this$dataListConfig$.length;
    }
  },
  watch: {
    filtersConfig: {
      handler: function () {
        this.initFilters();
      },
      immediate: true
    }
  },
  mounted() {
    !this.index && this.startTour();
  },
  methods: {
    startTour() {
      this.$nextTick(() => {
        if (localStorage.getItem('isShowSopOverviewGuide') !== 'true') {
          var _this$$refs$sopOvervi;
          (_this$$refs$sopOvervi = this.$refs['sopOverview']) === null || _this$$refs$sopOvervi === void 0 ? void 0 : _this$$refs$sopOvervi.start();
        }
      });
    },
    handleSopGuide(tour) {
      localStorage.setItem('isShowSopOverviewGuide', true);
      tour.stop();
    },
    initFilters() {
      var _this$scoreRuleOption3;
      this.filters.score_rule_id = (_this$scoreRuleOption3 = this.scoreRuleOptions[0]) === null || _this$scoreRuleOption3 === void 0 ? void 0 : _this$scoreRuleOption3.id;
      this.getData();
    },
    scoreRuleChange() {
      if (!this.dimensionOptions.find(item => item.key === this.filters.column)) {
        this.filters.column = 'score_percent';
      }
      this.getData();
    },
    async getData() {
      if (!(this.filters.score_rule_id && this.filters.column)) return;
      this.filters = {
        ...this.filters,
        time_zone: this.timezone,
        workspace_id: this.workspaceInfo.id,
        date: this.filterDate
      };
      this.loading = true;
      const res = await this.config.getDataMethod(this.filters).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.dataListConfig = res.results;
      }
    },
    goAnalysisPage(path) {
      this.$router.push({
        path,
        query: {
          scoreRuleId: this.filters.score_rule_id,
          column: this.filters.column,
          viewType: this.filters.view_type
        }
      });
    }
  }
};
exports.default = _default;