"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_vm.showLogo ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse,
      active: _vm.activeMenu === "/conversation"
    }
  }) : _vm._e(), _c("el-scrollbar", {
    ref: "scroll-menu-bar",
    attrs: {
      "wrap-class": "scrollbar-wrapper",
      "data-v-name": "scroll-menu-bar"
    }
  }, [_c("MgSideBarMenu")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;