"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModelAndVersionSelect = _interopRequireDefault(require("../ModelAndVersionSelect.vue"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vue = require("vue");
var _combineModel = require("./hooks/combineModel");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CombineRuleGroup',
  props: {
    ruleGroupIndex: {
      type: Number,
      default: 0
    },
    ruleGroup: {
      type: Object,
      default: () => ({})
    },
    ruleGroupLength: {
      type: Number,
      default: 0
    },
    submitCount: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['deleteRuleGroup'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const {
      extractModelIds
    } = (0, _combineModel.useCombineModelConfig)();
    const showRuleGroupError = (0, _vue.ref)(false);
    const validRuleGroupRes = () => {
      const modelArr = extractModelIds(props.ruleGroup);
      const notIdOrVersionArr = modelArr.filter(item => !item.id || !item.version);
      showRuleGroupError.value = notIdOrVersionArr.length > 0;
    };
    (0, _vue.watchEffect)(() => {
      validRuleGroupRes();
    });
    const spanTypeOption = (0, _vue.ref)([{
      value: 1,
      label: '任意'
    }, {
      value: 3,
      label: '当前'
    }, {
      value: 2,
      label: '前'
    }, {
      value: 4,
      label: '后'
    }]);
    const actionOption = (0, _vue.ref)([{
      value: 1,
      label: '命中'
    }, {
      value: 2,
      label: '未命中'
    }]);
    const modelAndVersionSelectChange = (_ref2, item) => {
      let {
        model_id,
        model_version
      } = _ref2;
      item.model_id = model_id;
      item.model_version = model_version;
    };
    const deleteRow = ruleItemIndex => {
      // eslint-disable-next-line vue/no-mutating-props
      props.ruleGroup.configs.splice(ruleItemIndex, 1);
    };
    const deleteRuleGroup = () => {
      emit('deleteRuleGroup');
    };
    const addRow = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.ruleGroup.configs.push({
        type: 'and',
        configs: [{
          model_id: '',
          model_version: '',
          span_type: 1,
          span: 1,
          action: 1
        }]
      });
    };
    return {
      __sfc: true,
      props,
      extractModelIds,
      showRuleGroupError,
      validRuleGroupRes,
      spanTypeOption,
      actionOption,
      modelAndVersionSelectChange,
      emit,
      deleteRow,
      deleteRuleGroup,
      addRow,
      ModelAndVersionSelect: _ModelAndVersionSelect.default
    };
  }
};
exports.default = _default;