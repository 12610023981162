"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "snippet-button-wrap"
  }, [_c("el-button", {
    attrs: {
      type: _vm.cancelType
    },
    on: {
      click: _vm.handleCancelSnippet
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: _vm.type
    },
    on: {
      click: _vm.handleSaveSnippet
    }
  }, [_vm._v("保存")])], 1), _c("snippet-video-dialog", {
    attrs: {
      "show-space-select": "",
      "snippet-video-dialog-visible": _vm.snippetVideoDialogVisible,
      "conversation-id": _vm.conversationId,
      "media-type": _vm.mediaType
    },
    on: {
      closeDialog: _vm.handleCloseSnippetVideoDialog,
      snippetWordSuccess: _vm.snippetWordSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;