"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _TaskInfo = _interopRequireDefault(require("../components/TaskInfo.vue"));
var _PlanAverageScore = _interopRequireDefault(require("./PlanAverageScore.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LeaderPlanHeader',
  components: {
    TaskInfo: _TaskInfo.default,
    PlanAverageScore: _PlanAverageScore.default
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    sourceUrlConfig() {
      return {
        teamView: _routeMap.ROUTES.coachingCenterTeamView,
        leader: _routeMap.ROUTES.coachingCenterLeader,
        'coach-todo': _routeMap.ROUTES.coachingCenterSales
      };
    }
  },
  methods: {
    handleBack() {
      const {
        source
      } = this.$route.query;
      this.$router.push({
        path: this.sourceUrlConfig[source] || _routeMap.ROUTES.coachingCenterLeader
      });
    }
  }
};
exports.default = _default;