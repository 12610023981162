"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'BusinessPathViewBtn',
  props: {
    chartRadio: {
      type: String,
      default: '桑基图'
    }
  },
  data() {
    return {
      viewConfig: [{
        name: '桑基图',
        img: require('@/assets/BI/chart-sankey.svg'),
        content: '桑基图展示事件的流向及占比'
      }, {
        name: '思维导图',
        img: require('@/assets/BI/chart-tree.svg'),
        content: '思维导图展示到达结尾事件的路径'
      }, {
        name: '表格',
        img: require('@/assets/BI/chart-table.svg'),
        content: '表格展示团队、个人数据Top路径并可以展开查看Top路径详情'
      }]
    };
  }
};
exports.default = _default;