"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _LikeInfo = _interopRequireDefault(require("./LikeInfo.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TricksLibraryConvCard',
  components: {
    LikeInfo: _LikeInfo.default
  },
  props: {
    convItem: {
      type: Object,
      default: () => ({})
    },
    originAddedList: {
      type: Array,
      default: () => []
    },
    addedList: {
      type: Array,
      default: () => []
    },
    showLikeInfo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAdded() {
      return [...this.originAddedList, ...this.addedList].some(item => item.id === this.convItem.id);
    },
    questionList() {
      var _this$convItem$replys;
      return (_this$convItem$replys = this.convItem.replys) === null || _this$convItem$replys === void 0 ? void 0 : _this$convItem$replys.filter(i => i.type === 'question');
    },
    answerList() {
      var _this$convItem$replys2;
      return (_this$convItem$replys2 = this.convItem.replys) === null || _this$convItem$replys2 === void 0 ? void 0 : _this$convItem$replys2.filter(i => i.type === 'answer');
    },
    popoverKnowledgeNames() {
      return this.convItem.knowledge_names.slice(1, this.convItem.knowledge_names.length);
    }
  },
  methods: {
    getConvTime(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    goConvSentence(order) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.convItem.conversation_id}&order=${order}&noback=true`);
    },
    getSpeakerType(type) {
      const map = {
        customer_contact: '客户',
        host_salesman: '销售'
      };
      return map[type];
    }
  }
};
exports.default = _default;