"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "guide-page-trial-dialog",
    attrs: {
      visible: _vm.dialogVisible,
      width: "400px",
      "close-on-click-modal": false,
      "before-close": _setup.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      closed: function ($event) {
        _setup.isComplete = false;
      }
    }
  }, [!_setup.isComplete ? _c("div", {
    staticClass: "reservation-form"
  }, [_c("h1", {
    staticClass: "h3"
  }, [_vm._v("申请试用")]), _c("p", [_vm._v("提交申请后 Megaview 工作人员将尽快与您联系并根据需求为您开通服务。")]), _c("el-form", {
    ref: "formRef",
    attrs: {
      rules: _setup.rules,
      model: _setup.reservationForm,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入姓名",
      minlength: "1",
      maxlength: "100"
    },
    model: {
      value: _setup.reservationForm.name,
      callback: function ($$v) {
        _vm.$set(_setup.reservationForm, "name", $$v);
      },
      expression: "reservationForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "手机号",
      prop: "tel"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入手机号",
      maxlength: "11"
    },
    model: {
      value: _setup.reservationForm.tel,
      callback: function ($$v) {
        _vm.$set(_setup.reservationForm, "tel", $$v);
      },
      expression: "reservationForm.tel"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "企业名称",
      prop: "company"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入企业名称",
      minlength: "1",
      maxlength: "100"
    },
    model: {
      value: _setup.reservationForm.company,
      callback: function ($$v) {
        _vm.$set(_setup.reservationForm, "company", $$v);
      },
      expression: "reservationForm.company"
    }
  })], 1)], 1)], 1) : _vm._e(), !_setup.isComplete ? _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("button", {
    staticClass: "btn",
    on: {
      click: _setup.submitForm
    }
  }, [_vm._v("立即提交")])]) : _vm._e(), _setup.isComplete ? _c("div", {
    staticClass: "complete-form"
  }, [_c("aside", {
    staticClass: "title"
  }, [_c("svg", {
    staticClass: "img",
    attrs: {
      width: "56",
      height: "56",
      viewBox: "0 0 56 56",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      d: "M0 28C0 12.536 12.536 0 28 0V0C43.464 0 56 12.536 56 28V28C56 43.464 43.464 56 28 56V56C12.536 56 0 43.464 0 28V28Z",
      fill: "#E4FFF5"
    }
  }), _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M40.8499 20.7501C41.7611 21.6613 41.7611 23.1387 40.8499 24.0499L31.0831 33.8167C28.3494 36.5504 23.9172 36.5504 21.1836 33.8167L17.0167 29.6499C16.1055 28.7387 16.1055 27.2613 17.0167 26.3501C17.928 25.4388 19.4053 25.4388 20.3166 26.3501L24.4834 30.5169C25.3946 31.4281 26.872 31.4281 27.7832 30.5169L37.5501 20.7501C38.4613 19.8388 39.9387 19.8388 40.8499 20.7501Z",
      fill: "#19D994"
    }
  })]), _c("p", [_vm._v("您的信息已提交，我们会尽快与您取得联络")])]), _c("section", {
    staticClass: "content"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/qrcode-2.png"),
      alt: "官方客服微信二维码"
    }
  }), _c("p", [_vm._v("您也可以扫码加工作人员微信联系我们")])])]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;