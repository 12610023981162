"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "modal-append-to-body": false,
      "before-close": _vm.closeDialog
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_vm.operateType === "edit" ? _c("div", {
    staticClass: "member-info"
  }, [_c("div", {
    staticClass: "member-card"
  }, [_c("image-avatar", {
    attrs: {
      avatar: _vm.memberInfo.avatar,
      name: _vm.memberInfo.name,
      size: "little-large"
    }
  }), _c("div", {
    staticClass: "avatar-text"
  }, [_c("span", [_vm._v(_vm._s(_vm.memberInfo.name))]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      enterable: false,
      effect: "dark",
      placement: "top"
    }
  }, [_c("div", {
    ref: "content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.getDepartmentNames(_vm.memberInfo.departments))
    },
    slot: "content"
  }), _c("div", {
    staticClass: "departments"
  }, _vm._l(_vm.memberInfo.departments, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(" " + _vm._s(item.super_tree_names[item.super_tree_names.length - 1]) + " "), item.is_main_tree ? _c("i", {
      staticClass: "main-department"
    }, [_vm._v("主部门")]) : _vm._e(), _vm._v(" " + _vm._s(index !== _vm.memberInfo.departments.length - 1 ? "；" : ""))]);
  }), 0)])], 1)], 1)]) : _vm._e(), _c("el-form", {
    ref: "operateMemberForm",
    attrs: {
      model: _vm.operateMemberForm,
      "hide-required-asterisk": true,
      rules: _vm.rules
    }
  }, [_vm.operateType !== "edit" ? _c("el-form-item", {
    attrs: {
      label: "选择成员",
      prop: "user_ids"
    }
  }, [_c("members-finder", {
    ref: "memberFinder",
    staticClass: "member-finder",
    attrs: {
      placeholder: "请选择成员",
      "tree-config": _vm.membersFinderTreeConfig,
      "has-self": false
    },
    on: {
      change: _vm.handleMemberChange
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: _vm.operateType !== "batchEdit" ? `业务角色` : `修改后的角色`,
      prop: "role_ids"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.operateMemberForm.role_ids,
      callback: function ($$v) {
        _vm.$set(_vm.operateMemberForm, "role_ids", $$v);
      },
      expression: "operateMemberForm.role_ids"
    }
  }, _vm._l(_vm.newRoleList, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id,
        name: "funcRole"
      }
    }, [_vm._v(_vm._s(item.name) + " "), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.is_default,
        expression: "item.is_default"
      }],
      staticClass: "default-tag"
    }, [_vm._v(" 默认 ")])]);
  }), 1)], 1)], 1), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.operateMemberForm.role_ids.length,
      expression: "operateMemberForm.role_ids.length"
    }],
    staticClass: "permission-preview",
    on: {
      click: _vm.openPermissionDrawer
    }
  }, [_vm._v("权限预览")]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确定")])], 1), _c("func-role-drawer", {
    attrs: {
      "func-role-drawer-visible": _vm.funcRoleDrawerVisible,
      type: "preview",
      "role-ids": _vm.operateMemberForm.role_ids
    },
    on: {
      handleClose: _vm.closeFuncRoleDrawer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;