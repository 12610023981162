"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'ChooseRoleDialog',
  props: {
    chooseRoleDialogVisible: {
      type: Boolean,
      default: false
    },
    hasDesensitizationRoleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkAll: false,
      checkedRoles: [],
      roleList: [],
      roleIdList: [],
      loading: false
    };
  },
  watch: {
    chooseRoleDialogVisible(val) {
      if (val) {
        this.checkedRoles = [];
        this.hasDesensitizationRoleList.map(item => this.checkedRoles.push(item.id));
        this.checkAll = this.checkedRoles.length === this.roleList.length;
      }
    }
  },
  created() {
    this.getManageRoleList();
  },
  methods: {
    async setRoleDesensitization(data) {
      this.loading = true;
      const res = await (0, _organization.setRoleDesensitization)(data);
      if (res.code === 20000) {
        this.$message.success('修改角色成功');
        this.handleCloseDialog();
        this.$emit('fetchRoleDesensitizationSuccess');
      }
      this.loading = false;
    },
    async getManageRoleList() {
      const res = await (0, _organization.getManageRoleList)();
      if (res.code === 200) {
        this.roleList = res.results.role_list.filter(item => item.id);
      }
    },
    handleCloseDialog() {
      this.$emit('closeChooseRoleDialog');
    },
    handleCheckAllChange(val) {
      this.roleIdList = [];
      this.roleList.map(item => {
        this.roleIdList.push(item.id);
      });
      this.checkedRoles = val ? this.roleIdList : [];
    },
    handleCheckedRoleChange(val) {
      const checkedCount = val.length;
      this.checkAll = checkedCount === this.roleList.length;
    },
    submit() {
      this.setRoleDesensitization({
        role_ids: this.checkedRoles
      });
    }
  }
};
exports.default = _default;