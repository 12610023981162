"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-conv-card"
  }, [_c("div", {
    staticClass: "card-top"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "该会话的知识点得分",
      placement: "top"
    }
  }, [_c("span", {
    staticClass: "score"
  }, [_c("span", {
    staticClass: "score-item"
  }, [_vm._v(_vm._s(_vm.convItem.score))]), _c("span", {
    staticClass: "desc"
  }, [_vm._v("分")])])]), _c("div", {
    staticClass: "info"
  }, [_c("div", {
    staticClass: "info-top"
  }, [_c("p", {
    staticClass: "title"
  }, [_c("svg-icon", {
    staticClass: "conversation-tag",
    attrs: {
      "icon-class": _vm.getConversationIcon(_vm.convItem.conversation_type)
    }
  }), _c("span", {
    staticClass: "title-content",
    on: {
      click: _vm.goConvDetail
    }
  }, [_vm._v(_vm._s(_vm.convItem.conversation_name))])], 1), _c("give-like-or-unlike", {
    attrs: {
      "conv-item": _vm.convItem,
      "active-knowledge": _vm.activeKnowledge
    }
  })], 1), _c("div", {
    staticClass: "info-desc"
  }, [_c("span", {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.secondToTime(_vm.convItem.duration)))]), _c("span", {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.convItem.begin_time.slice(0, 10)))]), _c("span", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.convItem.begin_time.slice(11)))]), _c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.convItem.host_name))])])])], 1), _c("div", {
    staticClass: "card-content"
  }, [_c("knowledge-conv-card-content", {
    ref: "cardContent",
    attrs: {
      "conv-item": _vm.convItem,
      "content-list": _vm.convItem.content
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;