"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _PicDisplay = _interopRequireDefault(require("./PicDisplay.vue"));
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'PicUploader',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const imgList = (0, _vue.ref)([]);
    const imgUrls = (0, _vue.computed)(() => {
      return imgList.value.map(item => item.url);
    });
    const disabled = (0, _vue.computed)(() => {
      return imgList.value.length > 2 || props.disabled;
    });
    const deletePic = index => {
      imgList.value.splice(index, 1);
    };
    const uploadRequest = _ref2 => {
      let {
        file
      } = _ref2;
      return (0, _videoDetail.uploadCommentImg)({
        file,
        type: 'comment'
      });
    };
    const handleUploadSuccess = res => {
      const img = {
        ...res.results
      };
      if (imgList.value.length > 2) return;
      imgList.value.push(img);
    };
    const beforeUpload = file => {
      if (imgList.value.length > 2) {
        _elementUi.Message.warning('最多上传3张图片');
        return false;
      }
      const isSizeFit = file.size / 1024 / 1024 < 5;
      const isImg = /image\/(jpeg|jpg|png|gif)/.test(file.type);
      if (!isSizeFit) {
        _elementUi.Message.warning('图片大小不能超过5M');
      }
      if (!isImg) {
        _elementUi.Message.warning('请上传图片格式的文件');
      }
      return isSizeFit && isImg;
    };
    const getImgs = () => {
      return imgList.value.map(item => item.path);
    };
    const clearImgs = () => {
      imgList.value = [];
    };
    const upload = async file => {
      if (!beforeUpload(file)) return;
      const res = await uploadRequest({
        file
      });
      handleUploadSuccess(res);
    };
    const setImgs = imgs => {
      imgList.value = imgs.map(item => {
        const url = new URL(item);
        return {
          url: item,
          path: url.pathname
        };
      });
    };
    expose({
      getImgs,
      clearImgs,
      upload,
      setImgs
    });
    return {
      __sfc: true,
      props,
      imgList,
      imgUrls,
      disabled,
      deletePic,
      uploadRequest,
      handleUploadSuccess,
      beforeUpload,
      getImgs,
      clearImgs,
      upload,
      setImgs,
      PicDisplay: _PicDisplay.default
    };
  }
};
exports.default = _default;