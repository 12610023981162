"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "compliance-base-table"
  }, [_c("el-table", _vm._g(_vm._b({
    attrs: {
      data: _vm.tableData,
      "header-cell-style": _vm.headerCellStyle,
      "cell-style": _vm.cellStyle,
      "default-sort": _vm.defaultSort,
      height: _vm.tableHeight,
      "row-class-name": _vm.tableRowClassName
    }
  }, "el-table", _vm.$attrs, false), _vm.$listeners), [_vm._l(_vm.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.label,
      attrs: {
        "show-overflow-tooltip": true,
        label: item.label,
        prop: item.prop,
        "min-width": item.width,
        fixed: item.fixed,
        sortable: _vm.sortable.includes(item.prop) ? "custom" : false,
        "sort-orders": ["ascending", "descending"]
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [item.prop === "name" ? [_c("div", {
            staticClass: "task-name",
            on: {
              click: function ($event) {
                return _vm.$emit("goTaskDetail", row);
              }
            }
          }, [_c("i", {
            staticClass: "iconfont icon-compliance-tasks-fill"
          }), _c("el-link", {
            attrs: {
              type: "primary",
              underline: false
            }
          }, [_vm._v(_vm._s(row.name))])], 1)] : item.prop === "conv_name" ? [_c("div", {
            staticClass: "conv-name",
            on: {
              click: function ($event) {
                return _vm.$emit("goComplianceWorkSpace", row);
              }
            }
          }, [_c("span", {
            staticClass: "phone-icon"
          }, [_c("i", {
            class: ["iconfont", _vm.controlIcon(row.media_type)]
          })]), _c("span", {
            staticClass: "conv-text"
          }, [_vm._v(_vm._s(row.conv_name))])])] : item.prop === "task_status" ? [_c("span", {
            class: ["compliance-status", {
              "in-progress": row.status === 1,
              completed: row.status === 2,
              cancelled: row.status === 3
            }]
          }, [_vm._v(" " + _vm._s(_vm.getTaskStatus(row.status)) + " ")])] : item.prop === "status" ? [_c("compliance-status-tag", {
            attrs: {
              status: row.status
            }
          })] : item.prop === "score_rule" ? [row.score_rule.length ? _c("span", {
            staticClass: "score-rule"
          }, [_c("i", {
            staticClass: "iconfont icon-scoring-compliance"
          }), _vm._v(" " + _vm._s(row.score_rule.join(",")) + " ")]) : _c("span", [_vm._v("-")])] : item.prop === "template" ? [row.template ? _c("span", {
            staticClass: "template-content"
          }, [_c("i", {
            staticClass: "iconfont icon-compliance-evaluation-form"
          }), _vm._v(" " + _vm._s(row.template) + " ")]) : _c("span", [_vm._v("-")])] : item.prop === "user_id" ? [_c("span", {
            staticClass: "name",
            on: {
              click: function ($event) {
                return _vm.$emit("goCompliancePersonal", row);
              }
            }
          }, [_c("MgvAvatarName", {
            attrs: {
              name: row.user_info.name,
              src: row.user_info.avatar,
              size: 28
            }
          }), _vm._v(" " + _vm._s(row.user_info.name) + " ")], 1)] : item.prop === "create_by" ? [_c("div", [row.create_by ? _c("span", {
            staticClass: "create-by"
          }, [_c("MgvAvatarName", {
            attrs: {
              name: row.create_by.name,
              src: row.create_by.avatar,
              size: 28
            }
          }), _vm._v(" " + _vm._s(row.create_by.name) + " ")], 1) : _c("span", [_vm._v("-")])])] : item.prop === "user_infos" ? [_c("div", {
            staticClass: "user-infos"
          }, [_c("CollapseAvatar", {
            attrs: {
              list: row.user_infos
            }
          }), _c("span", {
            ref: "total-user",
            refInFor: true,
            staticClass: "total-user"
          }, [_vm._v("(共"), _c("b", [_vm._v(_vm._s(row.user_infos.length))]), _vm._v("人)")])], 1)] : item.prop === "percent" ? [_c("div", {
            staticClass: "percent"
          }, [_c("span", {
            staticClass: "percent-text"
          }, [_vm._v(_vm._s(`${row.percent}%`))]), _c("el-progress", {
            class: {
              completed: row.status === 2 || row.percent === 100,
              rejected: row.status === 3
            },
            attrs: {
              percentage: row.percent,
              "show-text": false,
              "stroke-width": 8
            }
          })], 1)] : item.prop === "create_at" ? [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm")) + " ")] : item.prop === "task_name" ? [row.task_name ? _c("div", {
            staticClass: "compliance-task-name"
          }, [_c("i", {
            staticClass: "iconfont icon-compliance-tasks"
          }), _c("span", [_vm._v(_vm._s(row.task_name))])]) : _c("span", [_vm._v("-")])] : item.prop === "finish" ? [_c("div", {
            staticClass: "task-results"
          }, [_c("i", {
            staticClass: "iconfont icon-circle-check"
          }), _vm._v(" " + _vm._s(row.finish) + " ")])] : item.prop === "wait" ? [_c("div", {
            staticClass: "task-results"
          }, [_c("i", {
            staticClass: "iconfont icon-time"
          }), _vm._v(" " + _vm._s(row.wait) + " ")])] : item.prop === "reject" ? [_c("div", {
            staticClass: "task-results"
          }, [_c("i", {
            staticClass: "iconfont icon-circle-close"
          }), _vm._v(" " + _vm._s(row.reject) + " ")])] : item.prop === "total" ? [_c("div", {
            staticClass: "task-results"
          }, [_c("i", {
            staticClass: "iconfont icon-video-camera"
          }), _vm._v(" " + _vm._s(row.total) + " ")])] : item.prop === "remark" ? [_c("span", {
            staticClass: "remark"
          }, [_vm._v(_vm._s(row.remark))])] : item.prop === "operate" ? [_c("div", {
            staticClass: "operate-buttons"
          }, [row.status === 1 && row.source === "compliance" && _vm.interfacePermission.cancelTask ? _c("el-link", {
            attrs: {
              type: "primary",
              underline: false
            },
            on: {
              click: function ($event) {
                return _vm.$emit("cancelComplianceTask", row.id);
              }
            }
          }, [_vm._v("取消任务")]) : _vm._e(), _vm.interfacePermission.deleteTask ? _c("el-link", {
            attrs: {
              type: "danger",
              underline: false
            },
            on: {
              click: function ($event) {
                return _vm.$emit("deleteComplianceTask", row.id);
              }
            }
          }, [_vm._v("删除")]) : _vm._e()], 1)] : [_vm._v(" " + _vm._s(row[item.prop]) + " ")]];
        }
      }], null, true)
    });
  }), _vm._l(_vm.scoreRuleHeader, function (item, index) {
    return _c("el-table-column", {
      key: item.label,
      attrs: {
        "show-overflow-tooltip": true,
        label: item.label,
        prop: item.prop,
        "min-width": 200,
        sortable: "custom",
        "sort-orders": ["ascending", "descending"]
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row.score && row.score[index] && item.prop === "compliance_score_" + row.score[index].id ? [row.score[index] && row.score[index].item.length ? _c("GradeProgressPopover", {
            staticClass: "grade-score",
            attrs: {
              "score-rule": row.score[index]
            },
            on: {
              goScoreDetail: function ($event) {
                return _vm.$emit("goComplianceWorkSpace", row);
              }
            }
          }) : _c("span", [_vm._v("无评分")])] : _vm._e()];
        }
      }], null, true)
    });
  }), _vm._l(_vm.evaluationItemHeader, function (item, index) {
    return _c("el-table-column", {
      key: item.label,
      attrs: {
        "show-overflow-tooltip": true,
        label: String(item.order).padStart(2, "0") + ` ${item.label}`,
        prop: item.prop,
        "min-width": 180,
        "class-name": "table-column"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("el-tooltip", {
            attrs: {
              disabled: !_vm.isShowTooltip,
              content: _vm.handleLabel(item),
              placement: "top"
            }
          }, [_c("span", {
            staticClass: "header-name",
            on: {
              mouseenter: function ($event) {
                return _vm.onMouseOver($event);
              }
            }
          }, [_vm._v(_vm._s(_vm.handleLabel(item)))])])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref3) {
          var _vm$handleEvaluationR, _vm$handleEvaluationR2;
          let {
            row
          } = _ref3;
          return [_c("div", [_vm.handleEvaluationRecord(row.evaluation_record)[index] && _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type !== 3 && _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type !== 4 && _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type !== 8 && _vm.handleEvaluationRecord(row.evaluation_record)[index].field_value ? _c("span", [_vm._v(_vm._s(_vm.handleEvaluationRecord(row.evaluation_record)[index].field_value))]) : _vm.handleEvaluationRecord(row.evaluation_record)[index] && (_vm.handleEvaluationRecord(row.evaluation_record)[index].field_type === 3 || _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type === 4 || _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type === 8) && (_vm$handleEvaluationR = _vm.handleEvaluationRecord(row.evaluation_record)[index]) !== null && _vm$handleEvaluationR !== void 0 && (_vm$handleEvaluationR2 = _vm$handleEvaluationR.options) !== null && _vm$handleEvaluationR2 !== void 0 && _vm$handleEvaluationR2.length ? _c("span", [_vm._v(_vm._s(_vm.formatText(_vm.handleEvaluationRecord(row.evaluation_record)[index].options, _vm.handleEvaluationRecord(row.evaluation_record)[index].field_type)))]) : _c("span", [_vm._v("-")])])];
        }
      }], null, true)
    });
  }), _vm.isConvTable && _vm.interfacePermission.removeComplianceConv ? _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "68"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("div", {
          staticClass: "operate-buttons"
        }, [_c("el-link", {
          attrs: {
            type: "primary",
            underline: false
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(row);
            }
          }
        }, [_vm._v("移除")])], 1)];
      }
    }], null, false, 760398221)
  }) : _vm._e()], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;