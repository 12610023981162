"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "practice-knowledge",
    class: {
      "is-embed-fullscreen": _vm.isEmbed
    }
  }, [_vm.isFinished ? [_vm.taskConfig.type === 3 ? _c("PracticeFinish", {
    attrs: {
      score: _vm.overallScore
    },
    on: {
      goMyTask: _vm.goMyTask,
      goCoachDetail: _vm.goCoachDetail
    }
  }) : _c("PracticeFinishAiCustomer", {
    attrs: {
      "is-finished": _vm.isFinished,
      "scene-list": _vm.sceneList,
      "script-list": _vm.scriptList,
      "customer-avatar": _vm.taskConfig.avatar_url,
      "course-type": _vm.taskConfig.type,
      "conv-id": _vm.currentConverId
    }
  })] : _c("header", [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.back")) + " ")]), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])], 1), _c("article", [_c("div", {
    staticClass: "practice-container"
  }, [_vm.isShowLeftPracticeProcessTips ? _c("LeftPracticeProcessTips", {
    attrs: {
      description: _vm.practiceDescription,
      metrics: _vm.practiceScoreMetrics
    }
  }) : _vm._e(), _c("div", {
    staticClass: "conversational-transcription"
  }, [_c("div", {
    staticClass: "recognizer",
    class: _vm.recognizerStatusChange(_vm.recognizerStatus)
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRecognizerConnecting,
      expression: "isRecognizerConnecting"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.isRecognizerConnecting,
      expression: "isRecognizerConnecting"
    }],
    staticClass: "recognizer-connecting",
    attrs: {
      "element-loading-text": `${_vm.$t("coaching.preparing")}…`,
      "element-loading-spinner": "el-icon-loading"
    }
  }), _c("div", {
    class: {
      "visiblity-hidden": _vm.isRecognizerConnecting
    }
  }, [_c("div", {
    ref: "recognizer-text",
    staticClass: "recognizer-text"
  }, [!_vm.recognizerText ? _c("span", {
    staticClass: "recognizer-text-default"
  }, [_vm._v(_vm._s(_vm.recognizerTextDefault))]) : _vm._e(), _c("div", {
    staticClass: "recognizer-text-content"
  }, [_vm._v(_vm._s(_vm.recognizerText))])]), _vm._m(0)])]), _c("div", {
    class: ["practice-container-header", {
      show: _vm.practiceStatus !== "connecting"
    }]
  }, [_c("span", {
    staticClass: "subtitle"
  }, [_vm._v(_vm._s(_vm.$t("coaching.conversationContent")))])]), _vm.practiceStatus === "connecting" ? _c("p", {
    staticClass: "connecting"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.connecting"))), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _vm._e(), _c("div", {
    ref: "sentence-container",
    class: ["sentence-container", {
      show: _vm.practiceStatus !== "connecting"
    }]
  }, [_c("div", {
    staticClass: "start-sentence"
  }, [_c("span"), _vm._v(" " + _vm._s(_vm.$t("coaching.start")) + " "), _c("span")]), _c("transition-group", {
    attrs: {
      name: "list",
      tag: "p"
    }
  }, [_vm._l(_vm.sentenceList, function (item, index) {
    return [item.type === "narrator" ? _c("DialogueItemNarration", {
      key: item.child_id || index,
      attrs: {
        imgs: item.images
      }
    }, [_vm._v(_vm._s(item.content))]) : item.type === "end" ? _c("div", {
      key: index,
      staticClass: "end-tip"
    }, [_c("span", [_vm._v(_vm._s(item.content))]), _c("el-button", {
      attrs: {
        type: "danger",
        size: "mini"
      },
      on: {
        click: _vm.endConversation
      }
    }, [_vm._v(_vm._s(_vm.$t("coaching.endConversation")))])], 1) : _c("SentenceItem", {
      key: index,
      attrs: {
        "task-type": _vm.taskConfig.type,
        "customer-avatar": _vm.taskConfig.avatar_url,
        "practice-rule": _vm.practiceRule,
        sentence: item,
        "current-playing-audio-id": _vm.currentPlayingAudioId
      },
      on: {
        playAudio: function ($event) {
          return _vm.playAudio(item);
        }
      }
    })];
  })], 2)], 1), _c("div", {
    class: ["practice-container-footer", {
      show: _vm.practiceStatus !== "connecting"
    }, {
      "text-mode-practice-footer": _vm.practiceRule.practice_mode
    }, {
      "is-aicustomer": _vm.isAiCustomer
    }],
    attrs: {
      "data-v-step": "practiceChatInput"
    }
  }, [_vm.conversationComplate ? _c("el-tooltip", {
    attrs: {
      disabled: !_vm.conversationComplateDisabled,
      content: _vm.$t("coaching.ratingInProgress")
    }
  }, [_c("el-button", {
    staticClass: "complate-conversation-btn",
    attrs: {
      disabled: _vm.conversationComplateDisabled,
      type: "primary"
    },
    on: {
      click: _vm.complateConversation
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.完成练习")))])], 1) : [_vm.practiceRule.practice_mode === 0 ? [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ["not-started", "ended"].includes(_vm.practiceStatus),
      expression: "['not-started', 'ended'].includes(practiceStatus)"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.customerSayLoading,
      expression: "customerSayLoading"
    }],
    class: ["recording-btn", "iconfont", "icon-microphone-fill", {
      disabled: _vm.recordDisabled
    }],
    attrs: {
      "data-v-step": "trainingRecord"
    },
    on: {
      click: _vm.startRecording
    }
  })]), _c("transition", {
    attrs: {
      name: "slide-fade"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.practiceStatus === "started",
      expression: "practiceStatus === 'started'"
    }],
    staticClass: "pause-recording-container"
  }, [_c("span", {
    staticClass: "pause-btn",
    on: {
      click: _vm.endRecording
    }
  }, [_c("span"), _c("span")]), _c("span", {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.duration))]), _c("i", {
    staticClass: "close-pause-recording iconfont icon-close",
    on: {
      click: _vm.closeRecording
    }
  })])])] : _vm.practiceRule.practice_mode === 1 ? [_c("ChatInput", {
    attrs: {
      placeholder: _vm.$t("coaching.sendAndWrapPlaceholder"),
      disabled: _vm.customerSayLoading,
      maxlength: 1000
    },
    on: {
      change: _vm.uploadRecord
    }
  })] : _vm._e()], _vm.isAiCustomer ? _c("CompletePracticeButton", {
    on: {
      completePractice: _vm.onCompletePractice,
      saveAndLeave: _vm.onSaveAndLeave,
      giveUpPractice: _vm.giveUpPractice
    }
  }) : _vm._e()], 2)]), _c("div", {
    staticClass: "phonetic-practice"
  }, [_c("div", {
    staticClass: "practice-container-header"
  }, [_c("div", {
    staticClass: "avatar"
  }, [_c("mgv-avatar-name", {
    attrs: {
      src: _vm.isAiCustomer ? _vm.taskConfig.avatar_url : require("@/assets/coach/mai-mai-avatar.png"),
      size: 24
    }
  }), _c("mgv-avatar-name", {
    attrs: {
      src: _vm.avatar,
      name: _vm.name,
      size: 24
    }
  })], 1), _c("div", {
    staticClass: "recording"
  }, [_vm.practiceStatus !== "connecting" ? _c("p", [!_vm.conversationComplate ? _c("span") : _vm._e(), _vm._v(_vm._s(_vm.practiceDuration) + " ")]) : _vm._e(), _vm.conversationComplate && _vm.practiceStatus !== "connecting" ? _c("p", [_vm._v(" " + _vm._s(_vm.$t("coaching.sessionEnded")) + " ")]) : _vm._e()])]), _c("div", {
    staticClass: "phonetic-content-container",
    class: {
      "has-hints": _vm.isShowPracticeProcessScriptHint
    }
  }, [_c("div", {
    class: ["maimai-avatar-container", {
      small: _vm.practiceStatus !== "connecting"
    }]
  }, [_c("mgv-avatar-name", {
    attrs: {
      src: _vm.isAiCustomer ? _vm.taskConfig.avatar_url : require("@/assets/coach/mai-mai-avatar.png")
    }
  })], 1), _vm.isShowPracticeProcessScriptHint ? _c("PracticeProcessScriptHint", [_vm.taskConfig.type === 3 ? _c("HintContentIntelligentPracticeProcess", {
    attrs: {
      hints: _vm.standardReply
    }
  }) : _c("HintContentAiCustomerProcess", {
    attrs: {
      hints: _vm.aiCustomerProcessHints
    }
  })], 1) : _vm._e()], 1)])], 1)]), _vm.tourName ? _c("MgvTour", {
    ref: "smartTrainingRecordGuide",
    staticClass: "smart-training-record-tour",
    attrs: {
      name: _vm.tourName,
      steps: _vm.tourSteps
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div", {
          staticClass: "content-container"
        }, [_c("img", {
          attrs: {
            src: _vm.tourContent.img
          }
        }), _c("div", [_vm._v(_vm._s(_vm.tourContent.text))])])];
      },
      proxy: true
    }], null, false, 1806069054)
  }) : _vm._e(), _c("BackToTaskDialog", {
    attrs: {
      visible: _vm.backToTaskDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.backToTaskDialogVisible = $event;
      },
      abandon: _vm.giveUpPractice,
      stash: _vm.onSaveAndLeave
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "recognizer-audio-track"
  }, [_c("div", {
    attrs: {
      id: "wave-view"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;