"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _listCardDetail = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCardDetail"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _GradeProgressPopover = _interopRequireDefault(require("../GradeProgressPopover.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConverListCard',
  components: {
    CardDetail: _listCardDetail.default,
    ImageAvatar: _ImageAvatar.default,
    GradeProgressPopover: _GradeProgressPopover.default
  },
  inject: {
    scrollList: {
      from: 'scrollList',
      default: () => () => {}
    },
    isBaseConvInDrawer: {
      from: 'isBaseConvInDrawer',
      default: false
    },
    isInQualityTesting: {
      from: 'isInQualityTesting',
      default: false
    },
    getHasSummary: {
      default: () => function () {
        return false;
      }
    },
    getHasExtractInfo: {
      default: () => function () {
        return false;
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    },
    conversationScoreHead: {
      type: Array,
      default: () => []
    },
    complianceScoreHead: {
      type: Array,
      default: () => []
    },
    isIframe: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      eventLoadingText: '生成中'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['modulePermission', 'webRoutes', 'interfacePermission']),
    getHandledItem() {
      return this.handleInfo(this.item);
    },
    getDate() {
      return function (value) {
        return this.$plus8ToCurrentZone(value.slice(0, 19)).format('yyyy-MM-DD HH:mm');
      };
    },
    getStage() {
      return function (value) {
        switch (value) {
          case 'init':
            return '客户验证';
          case 'demand_confirmed':
            return '需求分析';
          case 'price_given':
            return '方案/报价';
          case 'to_verify':
            return '谈判审核';
          case 'win':
            return '赢单';
          case 'loss':
            return '输单';
          case 'invalidate':
            return '无效';
          default:
            return '';
        }
      };
    },
    getDuration() {
      return function (seconds) {
        let {
          s
        } = (0, _timeFormatter.second2HMS)(seconds);
        const {
          m,
          h
        } = (0, _timeFormatter.second2HMS)(seconds);
        if (h) {
          return m ? `<span class="timer-number">${h}</span><span class="timer-text">小时</span><span class="timer-number"> ${m}</span><span class="timer-text">分</span>` : `<span class="timer-number">${h}</span><span class="timer-text">小时</span>`;
        } else if (m) {
          return s > 29 ? `<span class="timer-number">${m + 1}</span><span class="timer-text">分</span>` : `<span class="timer-number">${m}</span><span class="timer-text">分</span>`;
        } else if (s) {
          s = this.$moment.duration(seconds * 1000).asSeconds().toFixed(0);
          return `<span class="timer-number"> ${s}</span><span class="timer-text">秒</span>`;
        } else {
          return `<span class="timer-number"> 0</span><span class="timer-text">秒</span>`;
        }
      };
    }
  },
  mounted() {
    this.getEventLoadingText();
  },
  methods: {
    // 跳转会话详情页面会话评分
    goConversationScoreDetail(module) {
      switch (module) {
        case 'conversation_score':
          // 会话sop
          window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&tab=score`);
          break;
        case 'compliance_score':
          // 质检评分
          window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&tab=complianceScore`);
          break;
        default:
          break;
      }
    },
    // 跳转到智能纪要
    goSummary() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&tab=AISummary`);
    },
    // 跳转到信息提取
    goExtractInfo() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&tab=extractInfo`);
    },
    getComplianceStatus(status) {
      return ['待质检', '已质检', '已驳回'][status - 1];
    },
    getComplianceStatusIcon(status) {
      return ['time', 'circle-check', 'circle-close'][status - 1];
    },
    getMediumIcon(type) {
      return {
        doc: 'icon-im-fill',
        audio: 'icon-phone-outline-fill',
        video: 'icon-video-camera-solid-2'
      }[type];
    },
    openEventKeyDrawer(id, conversationType, participants, isKeyEventDetail, eventId) {
      if (this.isBaseConvInDrawer) return;
      const params = {
        id,
        conversationType,
        participants,
        isKeyEventDetail,
        eventId
      };
      this.$emit('openEventKeyDrawer', params);
    },
    goDetails(id) {
      const isDingTalk = navigator.userAgent.indexOf('DingTalk') !== -1;
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${id}&noback=true`);
      localStorage.setItem('conversationIds', JSON.stringify(this.list.map(item => item.id)));
    },
    goDetailsKeyWord(eventId, keywordId) {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${eventId}&keyWordId=${keywordId}&noback=true`);
    },
    uifoldDetail(id) {
      this.$nextTick(() => {
        const name = 'converItem' + id;
        if (this.$refs[name]) {
          const item = this.$refs[name];
          const height = item.offsetTop;
          this.scrollList(height);
        }
      });
    },
    handleIdentify(participants) {
      const obj = {
        host_salesman: [],
        assistant_salesman: [],
        spectator_salesman: [],
        customer_contact: []
      };
      for (const item of participants) {
        if (item.entity_type) {
          obj[item.entity_type].push(item);
        }
      }
      return obj;
    },
    getEventLoadingText() {
      setInterval(() => {
        if (this.eventLoadingText.length < 6) {
          this.eventLoadingText += '.';
        } else {
          this.eventLoadingText = '生成中';
        }
      }, 1000);
    },
    handleInfo(info) {
      var _newInfo$participants;
      const newInfo = (0, _commonFunc.deepClone)(info);
      const arr = [];
      newInfo === null || newInfo === void 0 ? void 0 : (_newInfo$participants = newInfo.participants) === null || _newInfo$participants === void 0 ? void 0 : _newInfo$participants.map(item => {
        if (item.is_host) {
          newInfo.host = item;
        }
      });
      return newInfo;
    }
  }
};
exports.default = _default;