"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "intelligent-generation-step-one"
  }, [_c("div", {
    staticClass: "intelligent-generation__content"
  }, [_c("div", {
    staticClass: "content-input"
  }, [_c("p", {
    staticClass: "row"
  }, [_vm._v(" 请提供简短的一句话描述/要求，如：创建一个考虑购买智能培训产品的客户/这个客户开始阶段发言很少 ")]), _c("el-input", {
    staticClass: "row",
    attrs: {
      value: _vm.description,
      type: "textarea",
      resize: "none",
      autosize: {
        minRows: 1,
        maxRows: 10
      },
      maxlength: "300",
      "show-word-limit": "",
      placeholder: "请提供要创建的AI客户的简短描述/要求"
    },
    on: {
      input: _setup.handleDescriptionInput
    }
  }), _c("div", {
    staticClass: "row add-conversation"
  }, [_setup.hasConversation ? _c(_setup.ConversationItem, {
    attrs: {
      item: _vm.conversation
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function () {
        return [_c("MgvButton", {
          staticClass: "item-button",
          attrs: {
            type: "text",
            color: "info",
            icon: "icon-team-switch-2",
            square: ""
          },
          on: {
            click: _setup.addConversation
          }
        }), _c("MgvButton", {
          staticClass: "item-button",
          attrs: {
            type: "text",
            color: "info",
            icon: "icon-delete",
            square: ""
          },
          on: {
            click: _setup.deleteConversation
          }
        })];
      },
      proxy: true
    }], null, false, 776238066)
  }) : _setup.modulePermission.conversation ? [_c("MgvButton", {
    attrs: {
      color: "info",
      icon: "icon-video-camera",
      type: "plain"
    },
    on: {
      click: _setup.addConversation
    }
  }, [_vm._v("添加会话素材")]), _c("el-tooltip", {
    attrs: {
      content: "选择 1 个会话作为智能编排的参考素材",
      placement: "right"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])] : _vm._e()], 2)], 1)]), _c("footer", {
    staticClass: "intelligent-generation__footer"
  }, [_c("MgvButton", {
    attrs: {
      disabled: !_vm.description
    },
    on: {
      click: _setup.submit
    }
  }, [_vm._v("开始编排")]), _setup.isCreate ? _c("MgvButton", {
    staticClass: "right-button",
    attrs: {
      type: "text",
      color: "info"
    },
    on: {
      click: _setup.close
    }
  }, [_vm._v("跳过，自行编写提示词")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;