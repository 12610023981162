"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _parivatizationDeployment = require("@/api/parivatizationDeployment");
var _permissions = require("@/api/permissions");
var _vuex = require("vuex");
var _default = {
  name: 'OrgInvitation',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    inviteText: {
      type: String,
      default: ''
    },
    orgId: {
      type: Number,
      default: null
    },
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const nameValidator = (rule, value, callback) => {
      if (value.match(/[\,\/]/g)) {
        callback(new Error('特殊字符不支持,/'));
      }
      callback();
    };
    return {
      addPersonForm: {
        name: '',
        login_account: '',
        departmentName: '',
        workspaceId: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'change'
        }, {
          validator: nameValidator
        }],
        login_account: [{
          required: true,
          validator: _validate.loginAccountValidator,
          trigger: 'change'
        }],
        departmentName: [{
          required: true,
          message: '请选择主部门',
          trigger: 'change'
        }],
        workspaceId: [{
          required: true,
          message: '请选择主空间',
          trigger: 'change'
        }]
      },
      currIndex: null,
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultExpandedKeys: [],
      orgTree: [],
      departments: [],
      departmentLoading: false,
      inviteBtnLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['hasWorkspace']),
    getTree() {
      return this.orgTree.filter(item => item.node_type !== 'unassigned');
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.fetchDepartmentData();
      }
    }
  },
  methods: {
    closeSelf() {
      this.reset();
      this.$emit('closeInviteDialog');
    },
    addPerson() {
      this.$refs['addPersonForm'].validate(async valid => {
        if (valid) {
          const _data = {
            name: this.addPersonForm.name,
            account: this.addPersonForm.login_account,
            main_depart_id: this.departments[0] || 0,
            main_workspace_id: this.addPersonForm.workspaceId
          };
          this.inviteBtnLoading = true;
          try {
            const res = await (0, _parivatizationDeployment.addMewMember)(_data);
            if (res.code === 20000) {
              const password = res.results.password;
              this.$message({
                message: '添加成功！',
                type: 'success'
              });
              this.getMemberInfo(password);
            }
          } finally {
            this.inviteBtnLoading = false;
          }
        }
      });
    },
    getMemberInfo(password) {
      this.$emit('openCopy', {
        password,
        type: 'get',
        account: this.addPersonForm.login_account
      });
      this.closeSelf();
      this.$bus.$emit('freshUserList');
      this.fetchDepartmentData();
    },
    async fetchDepartmentData() {
      this.departmentLoading = true;
      const res = await (0, _permissions.getDepartmentWithAuth)();
      if (res.code === 200) {
        var _this$orgTree$;
        this.orgTree = res.results;
        this.defaultExpandedKeys = [(_this$orgTree$ = this.orgTree[0]) === null || _this$orgTree$ === void 0 ? void 0 : _this$orgTree$.id];
      }
      this.departmentLoading = false;
    },
    handleCurrentChange(data) {
      var _this$$refs$treeSele;
      this.addPersonForm.workspaceId = data.space_info.id;
      this.departments = [data.id];
      this.addPersonForm.departmentName = data.name;
      (_this$$refs$treeSele = this.$refs['tree-select']) === null || _this$$refs$treeSele === void 0 ? void 0 : _this$$refs$treeSele.blur();
    },
    reset() {
      this.addPersonForm = {
        name: '',
        login_account: '',
        departmentName: '',
        workspaceId: ''
      };
      this.departments = [];
      this.$nextTick(() => {
        this.$refs['addPersonForm'].clearValidate();
      });
    }
  }
};
exports.default = _default;