"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("article", {
    ref: "snippetsLibrary",
    staticClass: "snippets-library"
  }, [_c("aside", {
    ref: "aside",
    staticClass: "aside"
  }, [_c("div", [_c("MenuToggleBtn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: "剪辑库"
    }
  }, [_c("space-button", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })], 1), _c("aside-top", {
    on: {
      createFolder: _vm.openCreateDialog,
      createConvFlow: _vm.createConvFlow,
      search: _vm.handleSearch
    },
    model: {
      value: _vm.contentType,
      callback: function ($$v) {
        _vm.contentType = $$v;
      },
      expression: "contentType"
    }
  })], 1), _c("folder-tree", {
    ref: "folder-tree",
    attrs: {
      editable: "",
      createable: "",
      "current-node": _vm.currentNode
    },
    on: {
      create: _vm.handleCreate,
      nodeClick: _vm.handleNodeClick,
      folderOperation: _vm.folderOperation,
      convFlowOperation: _vm.convFlowOperation,
      nodeChange: _vm.handleTreeNodeChange,
      fetchSucceed: _vm.treeFetchSucceed
    }
  })], 1), _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.contentLoading,
      expression: "contentLoading"
    }],
    staticClass: "content"
  }, [_vm.contentType === "ranking" ? [_c("ranking-board", {
    ref: "rankBoard"
  })] : _vm._e(), _vm.contentType === "task" ? _c("SnippetStudyTask") : _vm.currentNode && Object.keys(_vm.currentNode).length ? [!_vm.currentNode.type ? [_vm.contentType === "collect" ? _c("h1", {
    staticClass: "title-collect"
  }, [_vm._v(" 我的收藏 ")]) : _c("folder-header", {
    attrs: {
      folder: _vm.curFolder,
      breadcrumbs: _vm.breadcrumbs
    },
    on: {
      command: _vm.folderOperation,
      create: _vm.handleCreate,
      breadClick: _vm.handleNodeClick,
      uploadFile: _vm.openUploadDialog
    }
  }), _vm.contentVisible ? _c("mg-infinite-scroll", {
    ref: "scroll-container",
    staticClass: "scroll-container",
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.getFlowVideoList
    }
  }, [_c("sub-folders", {
    attrs: {
      list: _vm.subFolders,
      "fold-total": _vm.foldTotal,
      "conv-flow-list": _vm.convFlowSubfolders,
      "conv-flow-total": _vm.convFlowTotal
    },
    on: {
      folderClick: _vm.handleFolderCardClick,
      command: _vm.folderOperation,
      create: _vm.handleCreate,
      moreSubfolders: _vm.moreSubfolders,
      convFlowOperation: _vm.convFlowOperation,
      moreConvFlow: _vm.getConvFlowSubfolders
    }
  }), _c("media-list", {
    attrs: {
      list: _vm.mediaList,
      total: _vm.mediaListTotal
    },
    on: {
      operation: _vm.handleSnippetOperation,
      moreMedia: _vm.getMediaList
    }
  }), _c("snippets-list", {
    attrs: {
      list: _vm.snippetsList,
      "video-total": _vm.videoTotal
    },
    on: {
      operation: _vm.handleSnippetOperation
    }
  })], 1) : _vm.requestFinished ? [_vm.contentType !== "collect" ? [_c("empty-folder"), false ? _c("recommend", {
    directives: [{
      name: "route-permission",
      rawName: "v-route-permission",
      value: ["ConversationIndex"],
      expression: "['ConversationIndex']"
    }]
  }) : _vm._e()] : [_c("empty-collect-folder", {
    on: {
      skipRanking: function ($event) {
        _vm.contentType = "ranking";
      }
    }
  })]] : _vm._e()] : [_c("conv-flow-detail", {
    attrs: {
      id: _vm.currentNode.id,
      "current-node": _vm.currentNode,
      breadcrumbs: _vm.breadcrumbs
    },
    on: {
      breadClick: _vm.handleNodeClick,
      convFlowOperation: _vm.convFlowOperation,
      uploadFile: _vm.openUploadDialog
    }
  })]] : _c("no-data-holder")], 2), _c("create-folder-dialog", {
    attrs: {
      visible: _vm.createFolderVisible,
      "create-subfolder": _vm.createSubfolder,
      "current-node": _vm.folderParent
    },
    on: {
      closeDialog: function ($event) {
        _vm.createFolderVisible = false;
      },
      submitSuccess: _vm.handleCreateSuccess
    }
  }), _c("move-folder-dialog", _vm._b({
    on: {
      closeDialog: function ($event) {
        _vm.moveFolderProps.visible = false;
      },
      submitSucceed: _vm.handleDialogSucceed,
      mediaSubmitSucceed: _vm.moveMedia
    }
  }, "move-folder-dialog", _vm.moveFolderProps, false)), _c("rename-dialog", _vm._b({
    on: {
      closeDialog: function ($event) {
        _vm.renameProps.visible = false;
      },
      submitSucceed: _vm.handleDialogSucceed,
      mediaSubmitSucceed: _vm.editMedia
    }
  }, "rename-dialog", _vm.renameProps, false)), _c("set-access-dialog", _vm._b({
    on: {
      closeDialog: function ($event) {
        _vm.accessProps.visible = false;
      }
    }
  }, "set-access-dialog", _vm.accessProps, false)), _c("create-conv-flow-drawer", {
    attrs: {
      "edit-node": _vm.editConvFlowNode,
      "current-node": _vm.folderParent,
      visible: _vm.createConvFlowVisible,
      "create-subfolder": _vm.createSubfolder,
      "is-edit": _vm.isEditConvFlow
    },
    on: {
      "update:visible": function ($event) {
        _vm.createConvFlowVisible = $event;
      },
      success: _vm.handleConvFlowSuccess
    }
  }), _c("upload-library-file-dialog", {
    attrs: {
      visible: _vm.uploadFileVisible,
      "current-node": _vm.currentNode
    },
    on: {
      "update:visible": function ($event) {
        _vm.uploadFileVisible = $event;
      },
      uploadSuccess: _vm.openFolder
    }
  }), _c("shareConfig", {
    attrs: {
      visible: _vm.assignConfigVisible,
      clearable: "",
      title: "指派",
      description: "",
      params: _vm.assignParams,
      "handle-fn": _vm.assignLibraryStudy,
      "cancel-handle-fn": _vm.cancelLibraryStudy,
      "get-list": _vm.getLibraryStudyAssignList,
      "success-msg": _vm.successMsg
    },
    on: {
      "update:visible": function ($event) {
        _vm.assignConfigVisible = $event;
      },
      refresh: _vm.refresh
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;