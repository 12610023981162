"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.treeLoading,
      expression: "treeLoading"
    }],
    staticClass: "konwledge-multiple-tree"
  }, [_vm.treeData.length ? [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.treeData,
      "show-checkbox": "",
      "node-key": "id"
    },
    on: {
      check: _vm.handleCheck
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return _c("span", {
          staticClass: "konewledge-tree-node"
        }, [data.is_know ? _c("i", {
          staticClass: "iconfont icon-reading"
        }) : _vm._e(), _c("span", {
          staticClass: "knowledge-info"
        }, [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            placement: "top",
            effect: "dark",
            content: data.name
          }
        }, [_c("span", {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(data.name) + " ")])]), _c("span", {
          staticClass: "knowledge-count"
        }, [_vm._v(_vm._s(data.count))])], 1)]);
      }
    }], null, false, 2309685205)
  })] : _vm._e(), !_vm.treeData.length && !_vm.treeLoading ? _vm._t("no-data", function () {
    return [_c("NoData", {
      attrs: {
        tips: _vm.noDataTip
      }
    }, [_vm.hasKnowledgePermission ? _c("el-button", {
      attrs: {
        slot: "button",
        type: "primary"
      },
      on: {
        click: _vm.goKnowledgeLibrary
      },
      slot: "button"
    }, [_vm._v(" " + _vm._s(_vm.$t("coaching.goToCreate"))), _c("i", {
      staticClass: "iconfont icon-right"
    })]) : _vm._e()], 1)];
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;