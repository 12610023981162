"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "personalized-setting"
  }, [_c(_setup.SettingSubtitle, {
    attrs: {
      title: "头像",
      "sub-title": "头像将在对练页或课程封面展示"
    }
  }), _c(_setup.ttsAvatarNameSelect, {
    on: {
      "immediate-change": _setup.onAvatarChange
    },
    model: {
      value: _setup.promptStruct.avatar_type,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "avatar_type", $$v);
      },
      expression: "promptStruct.avatar_type"
    }
  }), _c(_setup.SettingSubtitle, {
    attrs: {
      title: "语言表现增强",
      "sub-title": "合适的语言有助于AI客户的发音和学员录音识别"
    }
  }), _c("div", {
    staticClass: "radio-container"
  }, [_c("el-radio", {
    staticClass: "mg-common-radio",
    attrs: {
      label: "zh"
    },
    model: {
      value: _setup.promptStruct.language,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "language", $$v);
      },
      expression: "promptStruct.language"
    }
  }, [_vm._v("中文")]), _c("el-radio", {
    staticClass: "mg-common-radio",
    attrs: {
      label: "en"
    },
    model: {
      value: _setup.promptStruct.language,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "language", $$v);
      },
      expression: "promptStruct.language"
    }
  }, [_vm._v("英文")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;