"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _ContactForm = _interopRequireDefault(require("@/components/ContactForm"));
var _conversationList = require("@/api/conversationList");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BaseMediaForm',
  components: {
    DynamicForm: _DynamicForm.default,
    ContactForm: _ContactForm.default
  },
  props: {
    file: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {},
      contact: {
        id: '',
        tel: ''
      },
      submitLoading: false
    };
  },
  computed: {
    desc() {
      return [{
        id: 'meeting_name',
        type: 'text',
        label: '会议名称',
        required: true
      }, {
        id: 'conversationDate',
        label: '会话日期',
        type: 'date',
        required: true,
        halfRow: true,
        default: this.$moment().format('YYYY-MM-DD')
      }, {
        id: 'conversationTime',
        label: '时间',
        hideLabel: true,
        type: 'time-picker',
        placeholder: '请选择时间',
        pickerOptions: this.pickerOptions,
        required: true,
        halfRow: true,
        default: this.$moment().format('HH:mm:ss')
      }];
    },
    hasfile() {
      var _this$file, _this$file$;
      return (_this$file = this.file) === null || _this$file === void 0 ? void 0 : (_this$file$ = _this$file[0]) === null || _this$file$ === void 0 ? void 0 : _this$file$.video_id;
    },
    pickerOptions() {
      const selectableRange = this.form.conversationDate === this.$moment().format('YYYY-MM-DD') ? '00:00:00 - ' + this.$moment().format('HH:mm:ss') : '00:00:00 - 23:59:59';
      const range = {
        selectableRange
      };
      return range;
    }
  },
  watch: {
    file: {
      handler(val) {
        var _val$;
        this.form = {
          ...this.form,
          meeting_name: (_val$ = val[0]) === null || _val$ === void 0 ? void 0 : _val$.name
        };
      },
      deep: true
    }
  },
  methods: {
    async submit() {
      const isValid = ['dynamic-form', 'contact-form'].map(ref => this.$refs[ref].validate()).every(item => item);
      if (!isValid) return;
      this.submitLoading = true;
      const data = {
        meeting_name: this.form.meeting_name,
        begin_time: `${this.form.conversationDate} ${this.form.conversationTime}`,
        video_id: this.file[0].video_id,
        contact_id: this.contact.id
      };
      await (0, _conversationList.uploadConversation)(data).finally(() => this.submitLoading = false);
      this.$emit('submitSucceed');
    }
  }
};
exports.default = _default;