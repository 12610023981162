"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HitButtons = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitButtons.vue"));
var _HitTag = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ModelResult/HitTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrailResultTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    startIndex: {
      type: Number,
      default: 0
    },
    datasetStats: {
      type: Object,
      default: () => {}
    },
    tableHeight: {
      type: [Number, String],
      default: 400
    },
    datasetId: {
      type: Number,
      default: 0
    }
  },
  emits: ['clickModelSentence', 'annotateMark', 'selection-change', 'fixTrainData'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onClickModelSentence = item => {
      emit('clickModelSentence', item);
    };
    const handleSelectionChange = val => {
      emit('selection-change', val);
    };
    const annotateMark = async (status, row) => {
      emit('annotateMark', [row.praf_task_detail_id], status);
    };
    const clearAnnotationResult = row => {
      emit('clearAnnotationResult', [row.praf_task_detail_id]);
    };
    const handleFixTrainData = row => {
      emit('fixTrainData', row);
    };
    return {
      __sfc: true,
      props,
      emit,
      onClickModelSentence,
      handleSelectionChange,
      annotateMark,
      clearAnnotationResult,
      handleFixTrainData,
      HitButtons: _HitButtons.default,
      HitTag: _HitTag.default
    };
  }
};
exports.default = _default;