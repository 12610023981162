"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-checkbox", {
    staticClass: "single-checkbox",
    attrs: {
      label: _vm.item.name,
      border: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;