"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "operate-dropdown",
    attrs: {
      trigger: "click",
      placement: "bottom-end"
    },
    on: {
      command: command => _vm.handleCommand(command),
      "visible-change": _vm.handleDropdownVisibleChange
    }
  }, [_c("div", {
    class: ["dropdown-link", {
      "dropdown-visible-link": _vm.visible
    }]
  }, [_vm._v(" " + _vm._s(_vm.permissionLabel)), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    staticClass: "permission-operate-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.list, function (_ref, index) {
    let {
      icon,
      label,
      value,
      tips
    } = _ref;
    return _c("el-dropdown-item", {
      key: index,
      class: {
        "delete-operate": value === -1
      },
      attrs: {
        command: value
      }
    }, [value === -1 ? _c("el-divider") : _vm._e(), _c("div", {
      staticClass: "dropdown-item-container"
    }, [_c("i", {
      class: ["iconfont", icon, "permission"]
    }), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v(_vm._s(label))]), tips ? _c("div", {
      staticClass: "tips"
    }, [_vm._v(_vm._s(tips))]) : _vm._e()]), _vm.currentPermission === value ? _c("i", {
      staticClass: "iconfont icon-check"
    }) : _vm._e()])], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;