"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeleteModel = useDeleteModel;
var _elementUi = require("element-ui");
var _vue = require("vue");
var _modelManagement = require("@/api/modelManagement");
var _useMessage = require("@/hooks/use-message");
function useDeleteModel(getModelListAsync) {
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const getModelBindInfoAsync = async model => {
    const res = await (0, _modelManagement.getModelBindInfo)({
      id: model.id
    });
    if (res.code === 20000) {
      return res.results;
    }
  };
  const trainDetailVisible = (0, _vue.ref)(false);
  const checkVersionModelInfo = (0, _vue.ref)({});
  const deleteModelAsync = async model => {
    const {
      models
    } = await getModelBindInfoAsync(model);
    if (!models.length) {
      _elementUi.MessageBox.confirm(`确定删除模型${model.name}的所有版本吗？</br> 如果模型已发布到关键事件，模型删除后，关键事件无模型将被置为禁用状态`, '删除模型', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'el-button--danger'
      }).then(async () => {
        const res = await (0, _modelManagement.deleteModel)({
          model_id: model.id
        });
        if (res.code === 20000) {
          _useMessage.useMessage.success('删除模型成功');
          getModelListAsync();
        }
      }).catch(e => {
        console.log(e);
      });
    } else {
      const h = proxy.$createElement;
      const modelClickCb = _ref => {
        let {
          id,
          version
        } = _ref;
        trainDetailVisible.value = true;
        checkVersionModelInfo.value = {
          id,
          version
        };
      };
      const elements = models.map((_ref2, index) => {
        let {
          name,
          id,
          versions
        } = _ref2;
        return h('span', {
          key: index,
          on: {
            click: () => modelClickCb({
              id,
              version: versions[0].version_num
            })
          }
        }, [h('span', {
          style: {
            color: '#4461EC',
            cursor: 'pointer'
          }
        }, name), h('span', null, `(ID:${id})`), h('span', null, `${models.length > 1 && index !== models.length - 1 ? '；' : ''}`)]);
      });
      const message = h('div', null, [h('span', '组合模型'), h('span', elements), h('span', '正在使用此模型，请从组合模型中移除后重试')]);
      _elementUi.MessageBox.confirm(message, `无法删除模型${model.name}`, {
        type: 'warning',
        confirmButtonText: '确定',
        showCancelButton: false
      });
    }
  };
  return {
    deleteModelAsync,
    checkVersionModelInfo,
    trainDetailVisible
  };
}