"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-select", {
    attrs: {
      clearable: "",
      placeholder: `请输入${_vm.label}`
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.value || item,
      attrs: {
        label: item.label || item,
        value: item.value || item,
        disabled: item.disabled || false
      }
    });
  }), 1) : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, [_c("el-tag", {
    attrs: {
      type: "info",
      size: "small"
    }
  }, [_vm._v(_vm._s(_vm.newValue))])], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;