"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "seiri-notes"
  }, [_vm.notes && _vm.notes.length > 0 ? _c("ul", {
    ref: "content"
  }, _vm._l(_vm.notes, function (note, index) {
    return _c("li", {
      key: note.id,
      staticClass: "one-note"
    }, [_c("el-popconfirm", {
      attrs: {
        placement: "top",
        width: "180",
        "popper-class": "delete-note-pop",
        "confirm-button-text": "删除",
        "cancel-button-text": "取消",
        title: "是否删除这条笔记？",
        "hide-icon": ""
      },
      on: {
        onConfirm: function ($event) {
          return _vm.deleteNote(note.id);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete delete-note-icon",
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    })]), _c("div", {
      staticClass: "time"
    }, [_vm._v(" " + _vm._s(note.point_time.toString().indexOf(":") === -1 ? _vm.secondToTime(note.point_time) : note.point_time) + " ")]), _c("div", {
      ref: "contItem" + index,
      refInFor: true,
      staticClass: "content",
      class: {
        "can-edit": note.mark_type === "none",
        editing: _vm.activeId === note.id
      },
      attrs: {
        contenteditable: note.mark_type === "none"
      },
      domProps: {
        innerHTML: _vm._s(_vm.getHeightLight(note))
      },
      on: {
        click: function ($event) {
          return _vm.editContent(note, index);
        },
        blur: function ($event) {
          return _vm.contentChange(note, $event, index);
        }
      }
    })], 1);
  }), 0) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.notes.length < 1 || !_vm.notes,
      expression: "notes.length < 1 || !notes"
    }],
    staticClass: "nomark-tip"
  }, [_vm._v("暂无标记")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;