"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useApplyPermissionChange = useApplyPermissionChange;
exports.useEditDepartmentDialog = useEditDepartmentDialog;
exports.useGetConvCount = useGetConvCount;
exports.useGetKeyEventApplyPermission = useGetKeyEventApplyPermission;
exports.useOpenConvDrawer = useOpenConvDrawer;
var _vue = require("vue");
var _conversationList = require("@/api/conversationList");
var _organization = require("@/api/organization");
var _keyEvent = require("@/api/keyEvent");
var _commonFunc = require("@/utils/commonFunc");
var _axios = _interopRequireWildcard(require("axios"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
function useGetKeyEventApplyPermission(props, store) {
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const scope = (0, _vue.reactive)({
    date_new: {
      type: 'after',
      range: [proxy.$moment().format('YYYY-MM-DD HH:mm:ss')]
    },
    conversationType: 'unlimit',
    orgType: 'unlimit',
    conversation_types: [],
    organization_tree_ids: [],
    selectedDepartment: []
  });
  const eventName = (0, _vue.ref)('');
  const getScopeOfKeyEventApi = async () => {
    if (props.isBatch) return;
    const [event_id] = props.eventIds;
    const res = await (0, _keyEvent.getScopeOfKeyEvent)({
      event_id,
      workspace_id: store.getters.workspaceInfo.id
    });
    if (res.code === 20000) {
      var _data_scope$conversat, _data_scope$organizat;
      const {
        data_scope,
        name
      } = res.results;
      eventName.value = name;
      if ((_data_scope$conversat = data_scope.conversation_types) !== null && _data_scope$conversat !== void 0 && _data_scope$conversat.length) {
        scope.conversationType = 'limit';
        scope.conversation_types = data_scope.conversation_types;
      }
      if (data_scope !== null && data_scope !== void 0 && (_data_scope$organizat = data_scope.organization_tree_ids) !== null && _data_scope$organizat !== void 0 && _data_scope$organizat.length) {
        scope.orgType = 'limit';
        scope.organization_tree_ids = data_scope.organization_tree_ids;
        scope.selectedDepartment = data_scope.organization_tree_infos;
      }
      scope.date_new = data_scope.date_new;
    }
  };
  return {
    scope,
    eventName,
    getScopeOfKeyEventApi
  };
}

// 打开会话列表页面抽屉
function useOpenConvDrawer(scope, store) {
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const convListDrawerVisible = (0, _vue.ref)(false);
  const listLoading = (0, _vue.ref)(false);
  const conversationList = (0, _vue.ref)([]);
  const conversationTotal = (0, _vue.ref)(0);
  const getConvList = async () => {
    var _params$date_new, _params$date_new$rang;
    const params = (0, _commonFunc.deepClone)(scope);
    params.workspace_id = store.getters.workspaceInfo.id;
    params.from_score_rule = false;
    delete params.selectedDepartment;
    delete params.orgType;
    delete params.conversationType;
    if ((_params$date_new = params.date_new) !== null && _params$date_new !== void 0 && (_params$date_new$rang = _params$date_new.range) !== null && _params$date_new$rang !== void 0 && _params$date_new$rang.length) {
      params.date_new.range = params.date_new.range.map(item => proxy.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
    }
    params.time_zone = store.getters.timezone;
    listLoading.value = true;
    const res = await (0, _conversationList.getConversationList)(params).finally(() => {
      listLoading.value = false;
    });
    if (res.code === 200) {
      conversationList.value = res.results.items;
      conversationTotal.value = res.results.total_count;
    }
  };
  const openConvDrawer = () => {
    convListDrawerVisible.value = true;
    getConvList();
  };
  return {
    convListDrawerVisible,
    conversationList,
    conversationTotal,
    listLoading,
    openConvDrawer
  };
}

// 获取会话数量
function useGetConvCount(scope, store) {
  const convCount = (0, _vue.ref)(0);
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  let cancelRequest = null;
  const getConvCountApi = async () => {
    const {
      date_new,
      conversation_types,
      organization_tree_ids
    } = (0, _commonFunc.deepClone)(scope);
    if (!date_new.range.length) {
      date_new.range[0] = proxy.$moment().format('YYYY-MM-DD HH:mm:ss');
    }
    date_new.range = date_new.range.map(item => proxy.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
    if (cancelRequest) cancelRequest();
    const config = {
      cancelToken: new _axios.CancelToken(cancel => {
        cancelRequest = cancel;
      })
    };
    const res = await (0, _conversationList.getConversationListCount)({
      date_new,
      conversation_types,
      organization_tree_ids,
      workspace_id: store.getters.workspaceInfo.id,
      time_zone: store.getters.timezone
    }, config);
    if (res.code === 200) {
      convCount.value = res.results.count;
    }
  };
  return {
    convCount,
    getConvCountApi
  };
}

// 编辑部门弹窗操作
function useEditDepartmentDialog(scope) {
  const editDepartmentDialogVisible = (0, _vue.ref)(false);
  const openEditDepartmentDialog = () => {
    editDepartmentDialogVisible.value = true;
  };
  const getConversationOrgList = () => {
    const params = {
      type: JSON.stringify(['conversation'])
    };
    return (0, _organization.getOrgTree)(params);
  };
  const handleChangeDepartment = selection => {
    scope.selectedDepartment = selection;
    scope.organization_tree_ids = selection.map(item => item.id);
  };
  return {
    editDepartmentDialogVisible,
    openEditDepartmentDialog,
    getConversationOrgList,
    handleChangeDepartment
  };
}

// 每一项发生变化
function useApplyPermissionChange(scope) {
  const {
    proxy
  } = (0, _vue.getCurrentInstance)();
  const conversationTypeList = [{
    type: 'audio',
    label: '电话',
    icon: 'icon-phone-outline'
  }, {
    type: 'video',
    label: '在线会议',
    icon: 'icon-video-camera'
  }, {
    type: 'doc',
    label: '文字',
    icon: 'icon-im'
  }];
  const handleConvTypeChange = val => {
    if (val === 'unlimit') {
      scope.conversation_types = [];
    }
  };
  const handleOrgTypeChange = val => {
    if (val === 'unlimit') {
      scope.organization_tree_ids = [];
      scope.selectedDepartment = [];
    }
  };
  const selectConversationType = type => {
    if (scope.conversation_types.includes(type)) {
      const res = scope.conversation_types.filter(item => item !== type);
      scope.conversation_types = res;
    } else {
      scope.conversation_types.push(type);
    }
  };
  const dateFilterChange = val => {
    if (!val.range.length) {
      val.range[0] = proxy.$moment().format('YYYY-MM-DD HH:mm:ss');
    }
    val.range = val.range.map(item => proxy.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
    scope.date_new = val;
  };
  return {
    conversationTypeList,
    handleConvTypeChange,
    handleOrgTypeChange,
    selectConversationType,
    dateFilterChange
  };
}