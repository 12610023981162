"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "preview-portrait"
  }, [_c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      title: "预览",
      visible: _vm.dialogVisible,
      width: "66%",
      "modal-append-to-body": false,
      "close-on-click-modal": false,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [!_vm.loading && _vm.dialogVisible ? _c("scatter-diagram-chart", {
    attrs: {
      data: _vm.chartData,
      "chart-config": _vm.portraitConfig.charts.configs,
      "table-configs": _vm.tableConfig
    }
  }) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;