"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/AnalysisConfig"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EditColumnDialog',
  components: {
    AnalysisConfig: _AnalysisConfig.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    kanbanId: {
      type: Number,
      default: 0
    },
    editColumnData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      formConfig: {
        analysisTargetLabel: '指标类型',
        analysisTargetKeyLabel: '指标项'
      },
      durationValid: true // 会话时长校验是否通过，
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'workspaceInfo'])
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.isEdit) {
          const {
            analysis_target_key,
            analysis_target_vals
          } = this.editColumnData.configs;
          // SOP监测项里的关键事件和业务转化卡点选择关键事件级联,数据格式转换
          if (['key_event', 'conv_event', 'business_trans'].includes(analysis_target_key)) {
            // eslint-disable-next-line vue/no-mutating-props
            this.editColumnData.configs.analysis_target_vals = analysis_target_vals.map(_ref => {
              let {
                keyEventName
              } = _ref;
              return keyEventName;
            });
          }
        } else {
          this.handleClearValidate();
        }
      }
    }
  },
  methods: {
    handleClearValidate() {
      this.$nextTick(() => {
        this.$refs['editColumnForm'].clearValidate();
      });
    },
    submit() {
      this.$refs['editColumnForm'].validate(async valid => {
        if (valid) {
          if (['count', 'deal_count'].includes(this.editColumnData.configs.analysis_target_key) && !this.durationValid) {
            return;
          } // 在总通话数下，会话时长不是必填，但是如果填了有问题需要报出来，禁止提交
          // eslint-disable-next-line vue/no-mutating-props
          this.editColumnData.configs.dimension = this.editColumnData.configs.draw_style;
          const params = {
            user_id: this.userId,
            ...this.editColumnData.configs,
            workspace_id: this.workspaceInfo.id
          };
          // SOP监测项里的关键事件和业务转化卡点选择关键事件级联,数据格式转换
          const {
            analysis_target_key,
            analysis_target_vals
          } = this.editColumnData.configs;
          if (['key_event', 'conv_event', 'business_trans'].includes(analysis_target_key)) {
            params.analysis_target_vals = analysis_target_vals.map(item => {
              return {
                keyEventName: item,
                inverse: false
              };
            });
          }
          if (this.isEdit) {
            params.id = this.editColumnData.id;
          }
          params.kanban_id = this.kanbanId;
          // 字段名称不填,则取指标名称
          if (!params.name) {
            params.name = this.$refs['analysis-config'].$refs['analysis-target-key'].selected.label;
          }
          const requestFn = this.isEdit ? _businessIntelligence.editTeamAnalysisColumn : _businessIntelligence.addTeamAnalysisColumn;
          try {
            this.submitLoading = true;
            const res = await requestFn(params);
            if (res.code === 20000) {
              this.$message({
                message: this.isEdit ? '编辑成功' : '新建成功',
                type: 'success',
                center: true
              });
              this.$emit('refreshTeamAnalysisList');
              this.handleClose();
            }
          } finally {
            this.submitLoading = false;
          }
        }
      });
    },
    handleClose() {
      this.$emit('closeDialog');
    },
    handleDurationValidStatusChange(val) {
      this.durationValid = val;
    }
  }
};
exports.default = _default;