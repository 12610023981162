"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ContactPerson = _interopRequireDefault(require("./components/ContactPerson"));
var _CustomerFollowUp = _interopRequireDefault(require("./components/CustomerFollowUp"));
var _CustomerFollowUpDeal = _interopRequireDefault(require("./components/CustomerFollowUpDeal"));
var _InformationCard = _interopRequireDefault(require("./components/InformationCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Customer',
  components: {
    CustomerFollowUp: _CustomerFollowUp.default,
    InformationCard: _InformationCard.default,
    ContactPerson: _ContactPerson.default,
    CustomerFollowUpDeal: _CustomerFollowUpDeal.default
  },
  data() {
    return {
      customerTab: 'customerFollowUp',
      accountId: '',
      customerName: '',
      fromType: ''
    };
  },
  created() {
    this.accountId = this.$route.query.id;
    // 暂时兼容商机页与交易页跳转客户页面
    const from_type = this.$route.query.from;
    if (from_type) {
      this.fromType = from_type;
    }
  },
  methods: {
    getCustomerName(customerName) {
      this.customerName = customerName;
    }
  }
};
exports.default = _default;