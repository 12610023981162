"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-cascader"
  }, [_c("el-cascader", {
    ref: "custom-cascader",
    attrs: {
      options: _vm.options,
      props: _vm.defaultProps,
      "popper-class": "bi-custom-cascader-popper",
      "show-all-levels": false,
      "collapse-tags": "",
      clearable: ""
    },
    on: {
      "visible-change": _vm.visibleChange,
      change: _vm.handleChange
    },
    model: {
      value: _vm.indicators.metrics_id,
      callback: function ($$v) {
        _vm.$set(_vm.indicators, "metrics_id", $$v);
      },
      expression: "indicators.metrics_id"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;