"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "task-center-card"
  }, [_c("task-title-link", {
    attrs: {
      "task-info": _vm.getRenderInfoObj
    },
    on: {
      updateReadStatus: function ($event) {
        return _vm.$emit("updateReadStatus");
      }
    }
  }), _vm.getRenderInfoObj.date ? _c("div", {
    staticClass: "task-date"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _c("span", [_vm._v(_vm._s(_vm.plus8ToCurrentZone(_vm.getRenderInfoObj.date)))])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;