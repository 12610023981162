"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _speechBubble = _interopRequireDefault(require("./speechBubble"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BubbleLine',
  components: {
    speechBubble: _speechBubble.default
  },
  props: {
    speech: {
      type: [Array, Object],
      default() {
        return [];
      }
    },
    text: String,
    flipNum: {
      type: Number,
      default: 0
    },
    activeConvDate: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollTrans: 0
    };
  },
  watch: {
    flipNum(val) {
      this.scrollTrans = val * 17 * 14;
    }
  },
  methods: {
    judgeSize(_ref) {
      let {
        customer,
        salesman
      } = _ref;
      const total = (customer + salesman) / 60;
      const size = ['small', 'small', 'small', 'medium', 'large', 'large'];
      let [blueIndex, redIndex] = [3, 3];
      const [blueRatio, redRatio] = [customer / total, salesman / total];
      if (total >= 5) {
        blueIndex = redIndex = 4;
      } else if (total < 1) {
        blueIndex = redIndex = 2;
      }
      if (customer > salesman) {
        blueIndex--;
        if (redRatio < 0.33) {
          blueIndex--;
        }
      } else {
        redIndex--;
        if (blueRatio < 0.33) {
          redIndex--;
        }
      }
      const obj = {
        blueSize: size[blueIndex],
        redSize: size[redIndex]
      };
      return obj;
    },
    distance(date) {
      const today = this.$moment().format('YYYY-MM-DD');
      const distance = this.$moment(today).diff(date, 'days');
      return distance * 17;
    },
    bubbleClick(date) {
      this.$emit('bubbleClick', date);
    }
  }
};
exports.default = _default;