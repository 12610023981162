"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "edit-column-dialog",
    attrs: {
      title: _vm.isEdit ? "编辑列字段" : "新建列字段",
      visible: _vm.visible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "editColumnForm",
    attrs: {
      model: _vm.editColumnData,
      "label-position": "top",
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("字段名称")]), _c("el-input", {
    attrs: {
      maxlength: "10",
      placeholder: "请输入字段名称"
    },
    model: {
      value: _vm.editColumnData.configs.name,
      callback: function ($$v) {
        _vm.$set(_vm.editColumnData.configs, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "editColumnData.configs.name"
    }
  })], 1), _c("analysis-config", {
    ref: "analysis-config",
    attrs: {
      "show-conv-key-event-option": "",
      "show-per-conv-option": "",
      "show-conv-duration": "",
      configs: _vm.editColumnData.configs,
      "form-config": _vm.formConfig
    },
    on: {
      clearValidate: _vm.handleClearValidate,
      durationValidStatusChange: _vm.handleDurationValidStatusChange
    }
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;