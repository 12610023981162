"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    ref: "scrollContainer",
    staticClass: "scroll-container"
  }, [_c("div", {
    ref: "scrollContent",
    staticClass: "scroll-content"
  }, [_vm._t("default")], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.showLeftArrow,
      expression: "showLeftArrow"
    }],
    staticClass: "scroll-arrow left",
    on: {
      click: _setup.scrollLeft
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.showRightArrow,
      expression: "showRightArrow"
    }],
    staticClass: "scroll-arrow right",
    on: {
      click: _setup.scrollRight
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-right"
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;