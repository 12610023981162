"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = require("@/mixin/mixin");
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'KeyEventCard',
  mixins: [_mixin.handleTextHighLightMixin],
  props: {
    cardContentText: {
      type: Object,
      default: () => ({})
    },
    activeTabName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      eventName: '',
      labelName: '',
      eventTime: '',
      eventSentences: '',
      conversationId: '',
      eventId: '',
      participants: [],
      tagValue: ''
    };
  },
  created() {
    this.setCardData();
  },
  methods: {
    setCardData() {
      // 根据里层的 entity_id 从 participants中获取 name（里层的name有可能为空）
      const {
        participants,
        tag_name,
        event_name,
        begin_time,
        sentences,
        conversation_id,
        event_id,
        tag_value
      } = this.cardContentText;
      this.participants = participants;
      this.eventName = event_name;
      this.eventTime = begin_time;
      this.eventSentences = sentences;
      this.conversationId = conversation_id;
      this.eventId = event_id;
      this.labelName = tag_name;
      this.tagValue = tag_value || '';
    },
    goToKeyEventDetial(sentenceIndex) {
      const baseUrl = `${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.conversationId}&`;
      const url = this.activeTabName === 'account_label' ? baseUrl + `order=${this.eventSentences[sentenceIndex].data[0].order}&noback=true` : baseUrl + `keyWordId=${this.eventId}&itemIndex=${sentenceIndex}&noback=true`;
      window.open(url);
    },
    getEntityName(entityId, entityType) {
      for (const item of this.participants) {
        if (entityType == item.entity_type && entityId == item.entity_id) {
          return item.name;
        }
      }
      return '未知姓名';
    },
    secondToTime: _timeFormatter.secondToTime
  }
};
exports.default = _default;