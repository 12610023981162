"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'EvaluationHeader',
  data() {
    return {
      filterObj: {
        name: '',
        status: ''
      },
      options: [{
        value: 1,
        label: '已启用'
      }, {
        value: 2,
        label: '已禁用'
      }]
    };
  },
  mounted() {},
  methods: {
    changeEvaluationName: (0, _commonFunc.debounce)(function () {
      this.changeFilter();
    }, 300),
    changeEvaluationStatus() {
      this.changeFilter();
    },
    changeFilter() {
      this.$emit('filterChange', this.filterObj);
    }
  }
};
exports.default = _default;