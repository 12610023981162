"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-set-selector"
  }, [_vm._l(_setup.dataSetsList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "selector-item row"
    }, [_c("el-select", {
      attrs: {
        placeholder: "选择数据集（不含未标注数据集）",
        "popper-class": "data-set-select-popper",
        filterable: "",
        disabled: _vm.disabled,
        clearable: "",
        remote: "",
        "remote-method": _setup.getDataSetsAsync
      },
      on: {
        change: function ($event) {
          return _setup.onDatasetChange(item);
        }
      },
      model: {
        value: item.dataset_id,
        callback: function ($$v) {
          _vm.$set(item, "dataset_id", $$v);
        },
        expression: "item.dataset_id"
      }
    }, [_vm._l(_setup.dataSetOptions, function (option) {
      return _c("el-option", {
        key: option.id,
        attrs: {
          value: option.id,
          label: option.name,
          disabled: _setup.isDataSetDisabled(option.id)
        }
      }, [_c("div", {
        staticClass: "custom-option"
      }, [_c("div", {
        staticClass: "dataset-info-container"
      }, [_c("i", {
        staticClass: "iconfont icon-dataset-fill"
      }), _c("div", {
        staticClass: "dataset-info"
      }, [_c("h3", {
        staticClass: "dataset-name"
      }, [_vm._v(_vm._s(option.name))]), _c("p", {
        staticClass: "dataset-id"
      }, [_vm._v("ID:" + _vm._s(option.id))])])]), _c("el-tooltip", {
        attrs: {
          content: "标注状态",
          placement: "top",
          enterable: false
        }
      }, [_c("div", {
        staticClass: "dataset-annotation"
      }, [_c("span", {
        staticClass: "dot",
        class: {
          "is-full": option.annotated_data_count === option.total_data_count
        }
      }), _c("span", {
        staticClass: "count"
      }, [_vm._v(_vm._s(option.annotated_data_count) + "/" + _vm._s(option.total_data_count))])])])], 1)]);
    }), _setup.dataSetOptions.length && !_setup.hasSelectOption(item.dataset_id) ? _c("el-option", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: false,
        expression: "false"
      }],
      key: item.dataset_id,
      attrs: {
        value: item.dataset_id,
        label: item.dataset_name
      }
    }) : _vm._e()], 2), _c(_setup.DataSetLabelSelect, {
      ref: "dataSetLabelSelectRef",
      refInFor: true,
      attrs: {
        "dataset-id": item.dataset_id,
        disabled: _vm.disabled
      },
      on: {
        change: function ($event) {
          return _setup.onDataSetLabelChange($event, item);
        }
      },
      model: {
        value: item.dataset_label_id,
        callback: function ($$v) {
          _vm.$set(item, "dataset_label_id", $$v);
        },
        expression: "item.dataset_label_id"
      }
    }), !_vm.single ? _c("el-button", {
      staticClass: "close-button",
      attrs: {
        disabled: _vm.disabled
      },
      on: {
        click: function ($event) {
          return _setup.deleteDataset(index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()], 1);
  }), _c("div", {
    staticClass: "add-row row",
    class: {
      "is-single": _vm.single
    }
  }, [!_vm.single ? _c("el-button", {
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      click: _setup.addDataSet
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus"
  }), _vm._v("数据集")]) : _vm._e(), _c("span", [_c("span", {
    staticClass: "tip"
  }, [_vm._v(" 建议 " + _vm._s(_vm.tipCount) + " 个正样本")]), _c("span", [_vm._v("已选择 " + _vm._s(_setup.totalPositiveCount) + " 个")])])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;