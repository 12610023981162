"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _SnippetVideoButton = _interopRequireDefault(require("../snippetWord/SnippetVideoButton"));
var _routeMap = require("@/router/routeMap");
var _TransferConversationDialog = _interopRequireDefault(require("@/components/TransferConversationDialog"));
var _ShareConv = _interopRequireDefault(require("@/components/ShareConv"));
var _FollowupDynamicSelector = _interopRequireDefault(require("./FollowupDynamicSelector"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _ChatEntry = _interopRequireDefault(require("@/components/ChatEntry"));
var _chat_tip = _interopRequireDefault(require("@/assets/AIHelper/chat_tip.mp4"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDetailHeader',
  components: {
    DetailHeader: _DetailHeader.default,
    SnippetVideoButton: _SnippetVideoButton.default,
    TransferConversationDialog: _TransferConversationDialog.default,
    ShareConv: _ShareConv.default,
    FollowupDynamicSelector: _FollowupDynamicSelector.default,
    ImageAvatar: _ImageAvatar.default,
    ChatEntry: _ChatEntry.default
  },
  inject: ['getAISummaryDetail', 'getExtractInfoDetail'],
  props: {
    originType: {
      type: String,
      default: ''
    },
    workspace: {
      type: Object,
      default: () => ({})
    },
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    isVideoError: {
      type: Boolean,
      default: false
    },
    convId: {
      type: [Number, String],
      default: ''
    },
    mediaType: {
      type: String,
      default: ''
    },
    detailResult: {
      type: Object,
      default: () => ({})
    },
    commentsCount: {
      type: Number,
      default: 0
    },
    hasEvaluation: {
      type: Boolean,
      default: false
    },
    rightBarActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shareFormVisible: false,
      // 会话分享弹窗是否可见
      hasCoaches: false,
      isSeeker: false,
      transferConversationDialogVisible: false,
      unTeachTotal: 0,
      defaultStatus: false,
      chatTipVideoSrc: _chat_tip.default
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['conversationTitleInfo', 'orgId', 'audio', 'hasWorkspace', 'webRoutes', 'interfacePermission', 'hasVideo', 'currentOperateName', 'workspaceInfo']),
    backButton() {
      var _this$$route, _this$$route$query;
      // eslint-disable-next-line no-undef
      return !((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.noback);
    },
    convdate() {
      const _date = this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('YYYY-MM-DD');
      return _date;
    },
    convtime() {
      const _time = this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('HH:mm');
      return _time;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    convDuration() {
      if (this.conversationTitleInfo.convDuration) {
        const seconds = this.conversationTitleInfo.convDuration;
        const {
          s,
          m,
          h
        } = (0, _timeFormatter.second2HMS)(seconds);
        if (h) {
          return m ? `${h}小时${m}分` : `${h}小时`;
        } else if (m) {
          return s ? `${m}分${s}秒` : `${m}分`;
        } else if (s) {
          return `${s}秒`;
        } else {
          return `0秒`;
        }
      }
    },
    getDealName() {
      var _this$detailResult;
      if (((_this$detailResult = this.detailResult) === null || _this$detailResult === void 0 ? void 0 : _this$detailResult.type) === 'trade') {
        return this.detailResult.type_info.name;
      }
      return '';
    },
    getCustomerName() {
      var _this$detailResult2;
      if (JSON.stringify(this.detailResult) === '{}') return;
      const {
        name,
        customer_name
      } = this.detailResult.type_info;
      return {
        trade: customer_name,
        account: name
      }[(_this$detailResult2 = this.detailResult) === null || _this$detailResult2 === void 0 ? void 0 : _this$detailResult2.type] || '';
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    },
    getSalesman() {
      if (JSON.stringify(this.detailResult) === '{}') return;
      return this.detailResult.participants.find(item => !!item.is_host);
    }
  },
  mounted() {
    this.getIsHasCoach();
    this.getUnTeachList();
    this.defaultOpenRightDrawer();
  },
  methods: {
    defaultOpenRightDrawer() {
      const tab = this.$route.query.tab;
      if (!tab) return;
      this.$nextTick(() => {
        switch (tab) {
          case 'AISummary':
            this.showAISummary();
            break;
          case 'extractInfo':
            this.showExtractInfo();
            break;
          default:
            break;
        }
      });
    },
    deleteConv() {
      var _this$detailResult3, _this$detailResult3$h, _this$detailResult4;
      const recyclePath = `${window.location.origin}${_routeMap.ROUTES.convRecycleBin}`;
      const msg = `确定删除${(_this$detailResult3 = this.detailResult) === null || _this$detailResult3 === void 0 ? void 0 : (_this$detailResult3$h = _this$detailResult3.host_salesman) === null || _this$detailResult3$h === void 0 ? void 0 : _this$detailResult3$h.name}的会话“${(_this$detailResult4 = this.detailResult) === null || _this$detailResult4 === void 0 ? void 0 : _this$detailResult4.name}”吗？<br>删除后的数据，将进入<a target="_blank" class="link" href="${recyclePath}">回收站</a>，30天后将永久删除`;
      this.$confirm(msg, '删除会话', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'el-button--danger'
      }).then(async () => {
        const res = await (0, _videoDetail.deleteConversation)({
          conversation_id: this.convId - 0
        });
        if (res.code === 20000) {
          this.$message({
            customClass: 'el-message__show',
            type: 'success',
            message: '删除成功'
          });
          this.$router.push({
            path: _routeMap.ROUTES.conversation
          });
        }
      }).catch(() => {});
    },
    onCopy() {
      this.$message({
        message: '已复制到剪贴板',
        type: 'success',
        center: true
      });
    },
    openCommentDrawer() {
      this.$emit('openRightDrawer', 'comments');
    },
    goTradePage() {
      var _this$detailResult5;
      if (((_this$detailResult5 = this.detailResult) === null || _this$detailResult5 === void 0 ? void 0 : _this$detailResult5.type) !== 'trade') return;
      const {
        is_access,
        id
      } = this.detailResult.type_info;
      if (is_access) {
        const routeData = this.$router.resolve({
          path: _routeMap.ROUTES.tradeDetail,
          query: {
            id,
            isBack: true
          }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$message.warning('无交易详情查看权限');
      }
    },
    goCustomerPage() {
      var _this$detailResult6;
      if (!this.hasCustomerPermission) return;
      const {
        is_access,
        id,
        account_is_access,
        account_id
      } = this.detailResult.type_info;
      switch ((_this$detailResult6 = this.detailResult) === null || _this$detailResult6 === void 0 ? void 0 : _this$detailResult6.type) {
        case 'trade':
          if (account_is_access) {
            const routeData = this.$router.resolve({
              path: _routeMap.ROUTES.customer,
              query: {
                id: account_id
              }
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message.warning('无客户详情查看权限');
          }
          break;
        case 'account':
          if (is_access) {
            const routeData = this.$router.resolve({
              path: _routeMap.ROUTES.customer,
              query: {
                id
              }
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message.warning('无客户详情查看权限');
          }
          break;
        default:
          return;
      }
    },
    changeConversation(id, firstIndex) {
      this.$emit('changeId', id, firstIndex);
    },
    getMediumIcon(type) {
      return {
        doc: 'icon-im-fill',
        audio: 'icon-phone-outline-fill',
        video: 'icon-video-camera-solid-2'
      }[type];
    },
    handleCommand(command) {
      switch (command) {
        case 'transferWorkspace':
          this.openTransferConversationDialog();
          break;
        case 'seeLater':
          this.addToSeeLater();
          break;
        case 'openNotes':
          this.openNotes();
          break;
        case 'deleteConv':
          this.deleteConv();
          break;
      }
    },
    openNotes() {
      this.$emit('openRightDrawer', 'notes');
    },
    openTransferConversationDialog() {
      this.transferConversationDialogVisible = true;
    },
    addToSeeLater() {
      (0, _videoDetail.createSeeLater)(this.convId).then(res => {
        if (res.code === 20000) {
          this.$message.success('已添加至稍后再看');
        }
      });
    },
    async transferConversation(targetWorkspaceId) {
      const data = {
        conversation_id: parseInt(this.convId),
        workspace_id: targetWorkspaceId
      };
      try {
        const res = await (0, _videoDetail.convDetailTransferWorkspace)(data);
        if (res.code === 200) {
          this.$refs.transferConversationDialog.closeDialog();
          this.$message({
            message: '转移成功',
            type: 'success'
          });
          this.$emit('transferConversationSuccess');
        }
      } finally {
        var _this$$refs$transferC;
        (_this$$refs$transferC = this.$refs.transferConversationDialog) === null || _this$$refs$transferC === void 0 ? void 0 : _this$$refs$transferC.endButtonLoading();
      }
    },
    addVideoToSnippet() {
      if (this.currentOperateName === 'hitKeyEvent') {
        return;
      }
      this.$emit('addVideoToSnippet');
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', 'snippet');
    },
    onCancelSnippet() {
      this.$emit('onCancelSnippet');
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', '');
    },
    askForAdvice() {
      this.$emit('askForAdvice');
    },
    viewAICoaching() {
      this.$emit('viewAICoaching', this.isSeeker);
    },
    openEvaluation() {
      this.$emit('openRightDrawer', 'evaluation');
    },
    showConvInfo() {
      this.$emit('openRightDrawer', 'convInfo');
    },
    showAISummary() {
      this.$emit('openRightDrawer', 'AISummary');
    },
    showExtractInfo() {
      this.$emit('openRightDrawer', 'extractInfo');
    },
    teachAdvise() {
      this.$emit('teachAdvise');
    },
    getIsHasCoach() {
      (0, _videoDetail.getIsHasCoachButton)({
        conversation_id: this.convId
      }).then(res => {
        if (res.code === 20000) {
          this.hasCoaches = res.results.has_coach;
          this.isSeeker = res.results.is_seeker;
        }
      });
    },
    changeUnTeach() {
      (0, _videoDetail.changeUnTeach)({
        conversation_id: this.convId
      }).then(res => {
        if (res.code === 20000) {
          this.getUnTeachList();
        }
      });
    },
    changeFinishTeach() {
      (0, _videoDetail.changeFinishTeach)({
        conversation_id: this.convId
      }).then(res => {
        if (res.code === 200) {
          this.getUnTeachList();
        }
      });
    },
    getUnTeachList() {
      (0, _videoDetail.getUnTeachList)({
        conversation_id: this.convId
      }).then(res => {
        if (res.code === 20000) {
          this.unTeachTotal = res.results.count;
          this.defaultStatus = res.results.default_status;
        }
      });
    }
  }
};
exports.default = _default;