"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("el-dialog", {
    staticClass: "assign-user-dialog",
    attrs: {
      title: "编辑公开范围",
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      width: "482px",
      "append-to-body": false,
      "modal-append-to-body": false
    }
  }, [_c("SetPublicScope", {
    ref: "SetPublicScopeRef",
    attrs: {
      "is-public": _vm.isPublic,
      "backfill-list": _vm.backfillList
    }
  }), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("确定")])], 1)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;