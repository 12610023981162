"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKeyEventTrackerOfSentence = addKeyEventTrackerOfSentence;
exports.addNoteTemplate = addNoteTemplate;
exports.applyAICoaching = applyAICoaching;
exports.changeConversationHost = changeConversationHost;
exports.changeFinishTeach = changeFinishTeach;
exports.changeUnTeach = changeUnTeach;
exports.checkConvSize = checkConvSize;
exports.checkDetailRole = checkDetailRole;
exports.commentConversation = commentConversation;
exports.convDetailTransferWorkspace = convDetailTransferWorkspace;
exports.createCutVideo = createCutVideo;
exports.createSeeLater = createSeeLater;
exports.delOneSharedUser = delOneSharedUser;
exports.deleteComment = deleteComment;
exports.deleteConversation = deleteConversation;
exports.deleteKeyEventDetailTracker = deleteKeyEventDetailTracker;
exports.deleteNoteTemplate = deleteNoteTemplate;
exports.editConvInfo = editConvInfo;
exports.editConversationComment = editConversationComment;
exports.eliminateCand = eliminateCand;
exports.flipRole = flipRole;
exports.getAICoachingDetail = getAICoachingDetail;
exports.getAISummary = getAISummary;
exports.getAccountTag = getAccountTag;
exports.getAccountTagDetail = getAccountTagDetail;
exports.getAllEvaluate = getAllEvaluate;
exports.getAllMember = getAllMember;
exports.getAllNote = getAllNote;
exports.getAudioList = getAudioList;
exports.getAuthorityM3U8Url = getAuthorityM3U8Url;
exports.getChatHistory = getChatHistory;
exports.getCommentList = getCommentList;
exports.getConvAtableUsers = getConvAtableUsers;
exports.getConvComments = getConvComments;
exports.getConvFilterVisiableUsers = getConvFilterVisiableUsers;
exports.getConvVisiableUsers = getConvVisiableUsers;
exports.getConversationAnalySisData = getConversationAnalySisData;
exports.getConversationAnalySisSentences = getConversationAnalySisSentences;
exports.getConversationDetailInfo = getConversationDetailInfo;
exports.getCorrectFlipRoleHistory = getCorrectFlipRoleHistory;
exports.getCorrectKeyEventHistory = getCorrectKeyEventHistory;
exports.getCustomAiQuestionList = getCustomAiQuestionList;
exports.getEventEngine = getEventEngine;
exports.getEventTrackers = getEventTrackers;
exports.getInfoExtractResult = getInfoExtractResult;
exports.getInterGroupList = getInterGroupList;
exports.getInterviewer = getInterviewer;
exports.getIsHasCoachButton = getIsHasCoachButton;
exports.getKeyEventOfSentenceHit = getKeyEventOfSentenceHit;
exports.getKeyWords = getKeyWords;
exports.getModifyConvInfoHistory = getModifyConvInfoHistory;
exports.getMyNote = getMyNote;
exports.getMyPublicPlaylist = getMyPublicPlaylist;
exports.getNoteTemplateById = getNoteTemplateById;
exports.getNoteTemplateList = getNoteTemplateList;
exports.getQuestion = getQuestion;
exports.getRecommendKnowledges = getRecommendKnowledges;
exports.getReportAbstract = getReportAbstract;
exports.getReportPositon = getReportPositon;
exports.getReportQa = getReportQa;
exports.getSearchSnippet = getSearchSnippet;
exports.getShareNames = getShareNames;
exports.getSharedList = getSharedList;
exports.getTeacherName = getTeacherName;
exports.getUnTeachList = getUnTeachList;
exports.getUsedSnippets = getUsedSnippets;
exports.getWordsSearch = getWordsSearch;
exports.manualRunAISummary = manualRunAISummary;
exports.manualRunInfoExtract = manualRunInfoExtract;
exports.modifyNoteTemplate = modifyNoteTemplate;
exports.modifyNoteTemplateName = modifyNoteTemplateName;
exports.offerCand = offerCand;
exports.searchKnowledges = searchKnowledges;
exports.sendComment = sendComment;
exports.sendEvaluate = sendEvaluate;
exports.shareInterview = shareInterview;
exports.shareToAll = shareToAll;
exports.uploadCommentImg = uploadCommentImg;
var _request = require("@/utils/request");
function checkDetailRole(conversation_id, workspace_id) {
  return (0, _request.webService)({
    url: '/conversation/detail/check',
    method: 'get',
    params: {
      conversation_id,
      workspace_id
    }
  });
}

// 详情页转移空间
function convDetailTransferWorkspace(data) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/workspace/transfer',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取带授权失效的 video url
function getAuthorityM3U8Url(conversation_id, video_id, workspace_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/video/authority',
    method: 'get',
    params: {
      conversation_id,
      video_id,
      workspace_id
    }
  });
}
function getAudioList(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/sentences',
    method: 'get',
    params
  });
}
function getWordsSearch(conversation_id, content, workspace_id) {
  return (0, _request.webService)({
    url: '/conversation/detail/sentences/search',
    method: 'get',
    params: {
      conversation_id,
      content,
      workspace_id
    }
  });
}
function getKeyWords(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/analysis',
    method: 'get',
    params: {
      conversation_id
    }
  });
}
function getQuestion(conversation_id, workspace_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/qas',
    method: 'get',
    params: {
      conversation_id,
      workspace_id
    }
  });
}

// 发送评价
function sendEvaluate(conversation_id, content) {
  let is_pass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return (0, _request.request)({
    url: '/conversation/detail/detail/evaluate/send',
    method: 'post',
    data: {
      conversation_id,
      content,
      is_pass
    }
  });
}

// 获取所有评价
function getAllEvaluate(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/evaluate/all',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 获取会话组内的轮次信息
function getInterGroupList(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/list_by_group',
    method: 'get',
    params
  });
}

// 获取所有的note
function getAllNote(conversation_id, workspace_id) {
  let is_normal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let is_great = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let is_doubt = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  let has_topic = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  let is_text = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  return (0, _request.request)({
    url: '/conversation/detail/detail/notes',
    method: 'get',
    params: {
      conversation_id,
      workspace_id,
      is_normal,
      is_great,
      is_doubt,
      has_topic,
      is_text
    }
  });
}

// 获取我的note
function getMyNote(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/notes/my',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 获取笔记模板列表
function getNoteTemplateList() {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/get_list',
    method: 'get'
  });
}

// 添加笔记模板
function addNoteTemplate(name, content) {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/add',
    method: 'post',
    dataType: 'json',
    data: {
      name,
      content
    }
  });
}

// 修改笔记模板
function modifyNoteTemplate(id, content) {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/modify',
    method: 'post',
    dataType: 'json',
    data: {
      id,
      content
    }
  });
}

// 删除笔记模板
function deleteNoteTemplate(id) {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/delete',
    method: 'post',
    data: {
      id
    }
  });
}

// 根据笔记模板id获取笔记模板信息
function getNoteTemplateById(id) {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/get_by_id',
    method: 'get',
    params: {
      id
    }
  });
}

// 修改笔记模板名称
function modifyNoteTemplateName(id, name) {
  return (0, _request.request)({
    url: '/conversation/detail/note/template/modify_name',
    method: 'post',
    data: {
      id,
      name
    }
  });
}

// 获取事件引擎
function getEventEngine(conversation_id, workspace_id) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/event_engine',
    method: 'get',
    params: {
      conversation_id,
      workspace_id
    }
  });
}

// 获取关键事件详情
function getEventTrackers(data) {
  return (0, _request.webService)({
    url: '/conversation/conv/get_trackers',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取评论列表
function getCommentList(room_id) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/get',
    method: 'get',
    params: {
      room_id
    }
  });
}

// 发送评论
function sendComment(entity_type, entity_id, content) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/send',
    method: 'post',
    data: {
      entity_type,
      entity_id,
      content
    }
  });
}

// 删除评论
function deleteComment(entity_id, comment_id) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/delete',
    method: 'post',
    data: {
      entity_id,
      comment_id
    }
  });
}

// 录用候选人
function offerCand(groups) {
  return (0, _request.request)({
    url: '/deal/pass',
    method: 'post',
    data: {
      groups
    }
  });
}

// 淘汰候选人
function eliminateCand(groups) {
  return (0, _request.request)({
    url: '/deal/refuse',
    method: 'post',
    data: {
      groups
    }
  });
}

// 获取可指派的销售代表列表
function getInterviewer(filter) {
  return (0, _request.request)({
    url: '/organization/user/get_interviewer',
    method: 'get',
    params: {
      filter
    }
  });
}

// 获取可分享的成员列表
function getShareNames(filter) {
  return (0, _request.request)({
    url: '/organization/user/get_share',
    method: 'get',
    params: {
      filter
    }
  });
}

// 获取所有成员列表
function getAllMember(filter) {
  return (0, _request.request)({
    url: '/profile/list',
    method: 'get',
    params: {
      filter
    }
  });
}

// 分享视频
function shareInterview(conversation_id, user_ids) {
  return (0, _request.webService)({
    url: '/conversation/share',
    method: 'post',
    isForm: 'false',
    data: {
      conversation_id,
      user_ids
    }
  });
}

// 导出报告 智能摘要

function getReportAbstract(conversation_id) {
  return (0, _request.request)({
    url: '/conversation_detail/abstract',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 导出报告 职位需求

function getReportPositon(conversation_id) {
  return (0, _request.request)({
    url: '/conversation_detail/position',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 导出报告 问答

function getReportQa(conversation_id) {
  return (0, _request.request)({
    url: '/conversation_detail/candidate',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

/** *剪辑***/

// 创建剪辑
function createCutVideo(conversation_id, name, playlist_ids, begin_time, end_time) {
  return (0, _request.request)({
    url: '/snippet/create',
    method: 'post',
    data: {
      conversation_id,
      name,
      playlist_ids,
      begin_time,
      end_time
    }
  });
}

// 获取我公开的播放列表
function getMyPublicPlaylist() {
  return (0, _request.request)({
    url: '/playlist/mypublic',
    method: 'get',
    params: {}
  });
}

// 获取我分享的成员列表
function getSharedList(conversation_id) {
  return (0, _request.webService)({
    url: '/conversation/shared/list',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 删除某一个已经分享过的成员
function delOneSharedUser(conversation_id, delete_user_id) {
  return (0, _request.webService)({
    url: '/conversation/shared/delete',
    method: 'post',
    data: {
      conversation_id,
      delete_user_id
    }
  });
}

// 修改公开状态
function shareToAll(conversation_id, is_public) {
  return (0, _request.request)({
    url: '/conversation/conversation/share_to_all',
    method: 'post',
    data: {
      conversation_id,
      is_public
    }
  });
}

/** 评论 */

// 评论接口
function commentConversation(data) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/sender',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑评论
function editConversationComment(data) {
  return (0, _request.request)({
    url: '/conversation/detail/comment/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取评论
function getConvComments(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/comment/list',
    method: 'get',
    params
  });
}

// 图片上传
function uploadCommentImg(data) {
  return (0, _request.webService)({
    url: '/image/upload',
    method: 'post',
    data
  });
}

// 获取可@人员
function getConvAtableUsers(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/visiable_list',
    method: 'get',
    params
  });
}

// 获取可见成员
function getConvVisiableUsers(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/views',
    method: 'get',
    params
  });
}

// 搜索可见成员
function getConvFilterVisiableUsers(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/views',
    method: 'get',
    params
  });
}

// 添加到稍后再看
function createSeeLater(conversation_id) {
  return (0, _request.webService)({
    url: '/conversation/detail/see_later/create',
    method: 'post',
    data: {
      conversation_id
    }
  });
}

// 获取请求帮助的人
function getTeacherName(params) {
  return (0, _request.webService)({
    url: '/training/coach/get_user_list',
    method: 'get',
    params
  });
}

// 辅导相关按钮显示
function getIsHasCoachButton(params) {
  return (0, _request.webService)({
    url: '/training/coach/has_coach_button',
    method: 'get',
    params
  });
}

// 获取待辅导数量
function getUnTeachList(params) {
  return (0, _request.webService)({
    url: '/training/coach/undo_list',
    method: 'get',
    params
  });
}

// 标为待辅导
function changeUnTeach(data) {
  return (0, _request.webService)({
    url: '/training/coach/add_todo_coach',
    method: 'post',
    data
  });
}

// 标为已辅导
function changeFinishTeach(data) {
  return (0, _request.request)({
    url: '/coach/coach/clear',
    method: 'post',
    data
  });
}

// 培训 选择知识点和剪辑片段
// 搜索知识点
function searchKnowledges(params) {
  return (0, _request.webService)({
    url: '/training/knowledge/search',
    method: 'get',
    params
  });
}

// 获取最近选择和推荐的知识点
function getRecommendKnowledges(params) {
  return (0, _request.webService)({
    url: '/training/knowledge/used_recommend/list',
    method: 'get',
    params
  });
}

// 搜索剪辑片段
function getSearchSnippet(params) {
  return (0, _request.webService)({
    url: '/training/snippet/search',
    method: 'get',
    params
  });
}

// 获取最近选择剪辑片段
function getUsedSnippets(params) {
  return (0, _request.webService)({
    url: '/training/snippet/used',
    method: 'get',
    params
  });
}

// 获取会话详情页客户标签信息
function getAccountTag(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/account_tags',
    method: 'get',
    params
  });
}

// 获取客户标签详情
function getAccountTagDetail(params) {
  return (0, _request.webService)({
    url: '/conversation/tag/detail',
    method: 'get',
    params
  });
}

// 获取关键事件修正历史
function getCorrectKeyEventHistory(params) {
  return (0, _request.webService)({
    url: '/conversation/event_engine/edit_history',
    method: 'get',
    params
  });
}

// 添加关键事件命中点
function addKeyEventTrackerOfSentence(data) {
  return (0, _request.webService)({
    url: '/conversation/event_engine/add',
    method: 'post',
    dataType: 'json',
    data
  });
}
function deleteKeyEventDetailTracker(data) {
  return (0, _request.webService)({
    url: '/conversation/event_engine/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// chat-gpt 获取历史数据
function getChatHistory(params) {
  return (0, _request.webService)({
    url: '/ai/qa/list',
    method: 'get',
    params
  });
}

// 会话文本长度校验
function checkConvSize(params) {
  return (0, _request.webService)({
    url: '/ai/qa/check',
    method: 'get',
    params
  });
}

// 会话详情页面-获取会话维度分析数据
function getConversationAnalySisData(params) {
  return (0, _request.webService)({
    url: '/conversation/analysis',
    method: 'get',
    params
  });
}

// 会话详情页-获取会话维度对应句子
function getConversationAnalySisSentences(params) {
  return (0, _request.webService)({
    url: '/conversation/analysis/get_trackers',
    method: 'get',
    params
  });
}

// 会话详情页面-获取会话详情信息
function getConversationDetailInfo(params) {
  return (0, _request.webService)({
    url: '/conversation/detail',
    method: 'get',
    params
  });
}

// 智能辅导-申请
function applyAICoaching(data) {
  return (0, _request.webService)({
    url: '/conversation/detail/evaluate/ask',
    method: 'post',
    dataType: 'json',
    data,
    timeout: 120000
  });
}

// 智能辅导-获取详情
function getAICoachingDetail(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/evaluate/detail',
    method: 'get',
    params
  });
}

// 获取会话详情页面句子对应关键事件
function getKeyEventOfSentenceHit(params) {
  return (0, _request.webService)({
    url: '/conversation/event_engine/order_events',
    method: 'get',
    params
  });
}

// 修改会话 info 历史
function getModifyConvInfoHistory(params) {
  return (0, _request.webService)({
    url: '/conversation/edit_history',
    method: 'get',
    params
  });
}

// 删除会话
function deleteConversation(data) {
  return (0, _request.webService)({
    url: '/conversation/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话信息编辑
function editConvInfo(data) {
  return (0, _request.webService)({
    url: '/conversation/field/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 修改会话主销售
function changeConversationHost(data) {
  return (0, _request.webService)({
    url: '/conversation/change_host',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 翻转角色
function flipRole(data) {
  return (0, _request.webService)({
    url: '/conversation/detail/role_flip',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取翻转历史
function getCorrectFlipRoleHistory(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/role_flip/list',
    method: 'get',
    params
  });
}

// 获取团队自定义ai助手问题列表
function getCustomAiQuestionList(params) {
  return (0, _request.webService)({
    url: '/ai/qa/question_list',
    method: 'get',
    params
  });
}

// 智能纪要
function getAISummary(params) {
  return (0, _request.webService)({
    url: '/conversation/summary',
    method: 'get',
    params
  });
}

// 信息提取结果
function getInfoExtractResult(params) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set_result',
    method: 'get',
    params
  });
}

// 智能纪要手动运行
function manualRunAISummary(data) {
  return (0, _request.webService)({
    url: '/conversation/summary/run',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 信息提取手动运行
function manualRunInfoExtract(data) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set/run',
    method: 'post',
    dataType: 'json',
    data
  });
}