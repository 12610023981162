"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notFoundRoutes = exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
_vue.default.use(_vueRouter.default);
const constantRoutes = [{
  path: '/element-test',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ElementTest.vue')))
}, {
  path: '/login',
  // 项目登录界面
  name: 'Login',
  component: () => {
    if (process.env.VUE_APP_TITLE === 'image') {
      return Promise.resolve().then(() => _interopRequireWildcard(require('@/views/parivatizationDeploymentLogin/index')));
    }
    return Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/index')));
  },
  meta: {
    subtitle: process.env.VUE_APP_TITLE === 'image' ? '登录' : '登录/注册'
  }
}, {
  path: '/xiaozhan/login',
  // 小站项目登录界面
  name: 'XiaozhanLogin',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/index')))
}, {
  path: '/login/:app',
  // 单点登录界面
  name: 'SingleLogin',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/index'))),
  meta: {
    is_iframe: true // 是否是内嵌相关页面
  }
}, {
  path: '/resetPassword',
  // 重置密码
  name: 'ResetPassword',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resetPassword'))),
  meta: {
    subtitle: '重置密码'
  }
}, {
  path: '/xiaozhan/resetPassword',
  // 小站重置密码
  name: 'XiaozhanResetPassword',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resetPassword')))
}, {
  path: '/chooseTeam',
  // 选择团队
  name: 'ChooseTeam',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/chooseTeam'))),
  meta: {
    subtitle: '选择企业'
  }
}, {
  path: '/xiaozhan/chooseTeam',
  // 小站选择团队
  name: 'XiaozhanChooseTeam',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/chooseTeam')))
}, {
  path: '/conversationList',
  // 嵌入的简单版会话列表页
  name: 'ConversationList',
  meta: {
    is_iframe: true // 是否是内嵌相关页面
  },

  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/iframeCoversationList')))
}, {
  path: '/meeting-pc',
  name: 'MeetPc',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/meetingHelper')))
}, {
  path: '/meeting-mobile',
  name: 'MeetMobile',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/meetingHelper')))
}, {
  path: '/no-permission',
  name: 'NoPermission',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/noPermission')))
}, {
  path: '/my-own-training',
  name: 'MyOwnTraining',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/coaching/smartTrainingEdit/index.vue')))
}];
exports.constantRoutes = constantRoutes;
if (process.env.VUE_APP_TITLE !== 'production') {
  constantRoutes.push({
    path: '/vue3-example',
    name: 'Vue3Example',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/Vue3Example.vue')))
  });
}
const notFoundRoutes = [{
  path: '/404',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/404'))),
  meta: {
    subtitle: '404'
  }
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404'
}];
exports.notFoundRoutes = notFoundRoutes;
const asyncRoutes = [];
exports.asyncRoutes = asyncRoutes;
const createRouter = () => new _vueRouter.default({
  mode: 'history',
  // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();

// 解决路由重复点击报错问题
const VueRouterPush = _vueRouter.default.prototype.push;
_vueRouter.default.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};
// 解决路由重复点击报错问题
const VueRouterReplace = _vueRouter.default.prototype.replace;
_vueRouter.default.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err);
};

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  router.options.routes = []; // 重置时需要清空该数组，否则影响permission
}

var _default = router;
exports.default = _default;