"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _i18n = require("@/i18n");
var _default = {
  __name: 'CheckCaptchaDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rowInfo: {
      type: Object,
      default: () => ({})
    },
    loginCaptchaObj: {
      type: Object,
      default: () => ({
        code: '',
        expire_at: ''
      })
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handleClose = () => {
      emit('update:visible', false);
    };
    const copyCaptcha = () => {
      _elementUi.Message.success((0, _i18n.t)('management.复制成功'));
      handleClose();
    };
    return {
      __sfc: true,
      props,
      emit,
      handleClose,
      copyCaptcha,
      t: _i18n.t
    };
  }
};
exports.default = _default;