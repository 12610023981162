"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COACHING_ROUTES = exports.COACHING_PAGES = void 0;
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
const COACHING_ROUTES = {
  // 新培训路由
  coaching: '/coaching',
  // 培训
  course: '/coaching/coach/course',
  // 课程管理
  coach: '/coaching/coach',
  // 统一培训
  tutor: '/coaching/tutor',
  // 针对辅导
  knowledge: '/coaching/knowledge',
  // 知识沉淀
  coachCourseEdit: '/coaching/coach-course-edit',
  // 培训 - 课程搭建 - 课程新建和编辑
  aiCoachCourseEdit: '/coaching/ai-coach-course-edit',
  // 培训 - 课程搭建 - AI客户(Alpha)课程新建和编辑
  aiSopCoachCourseEdit: '/coaching/ai-sop-coach-course-edit',
  // 培训 - 课程搭建 - AI客户(Beta)课程新建和编辑
  // AI客户(Beta)课程加强版，上线后可删除 aiSopCoachCourseEdit
  aiSopCoachCourse: '/coaching/ai-sop-coach-course',
  // 培训 - 课程搭建 - AI客户(Beta)课程新建和编辑
  aiCoachTaskDetail: '/coaching/ai-coach-task-detail',
  // AI客户(Alpha)课程详情
  aiCustomerGenerator: '/coaching/ai-customer-generator',
  // AI客户v1.0搭建
  knowledgeLibrary: '/coaching/knowledge/script/library',
  // 知识库 - 知识库首页
  knowledgeLibraryDetail: '/coaching/knowledge/script/library-detail',
  // 知识库 - 知识库详情页
  coachingKnowledgeSessionDig: '/coaching/knowledge/script/session-dig',
  // 知识库 - 知识点挖掘引擎
  snippetsLibrary: '/coaching/knowledge/clip',
  // 剪辑库
  libraryDetail: '/coaching/clip-detail',
  // 剪辑详情
  coachingCenterTeamView: '/coaching/coach/coach-task/team-view',
  // 任务管理-团队视图
  coachingCenterLeader: '/coaching/coach/coach-task/leader-view',
  // 任务管理-我分配的
  coachingCenterLeaderPlan: '/coaching/coach-task-detail-team',
  // 任务管理-任务详情页面（团队视图，我分配的执行人列表）
  coachingCenterLeaderDetail: '/coaching/coach-task-detail',
  // 任务管理-任务详情页面（团队视图，我分配的问题列表）- 个人任务问题列表
  coachingCenterSalesPlan: '/coaching/coach-task-detail-personal',
  // 任务管理 / 分配给我的问题详情页
  coachingCenterSales: '/coaching/coach/coach-todo',
  // 我的任务列表
  coachParentTaskDetail: '/coaching/coach-parent-task-detail',
  // 父任务详情
  practiceKnowledge: '/coaching/coach-task-practice',
  // 我的任务 / 我的问题详情页
  smartTrainingEdit: '/coaching/smart-training-edit',
  // 智能对练编辑
  practiceSmartTraining: '/coaching/coach-task-practice-smart-training',
  // 智能对练练习
  // 智能对练任务详情页面
  couseTaskDetailSmartTraining: '/coaching/coach-task-detail-smart-training',
  coachDataAnalysisTaskDetail: '/coaching/coach/coach-data-analysis/task-detail'
};
exports.COACHING_ROUTES = COACHING_ROUTES;
const COACHING_PAGES = {
  // 培训模块路由：
  Coaching: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Coaching" */'@/layout'))),
  // 统一培训
  CoachingCoach: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachingCoach" */'@/views/coaching/index'))),
  // 统一培训 / 课程搭建
  Course: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "Course" */
  '@/views/coaching/coach/course/index'))),
  // 统一培训 / 任务管理
  CoachTask: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTask" */
  '@/views/coaching/coach/coachTask/index'))),
  // 统一培训 / 任务管理-团队视图
  CoachTaskTeamView: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskTeamView" */
  '@/views/coaching/coach/coachTask/teamView/index'))),
  // 统一培训 / 任务管理-我分配的
  CoachTaskLeaderView: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskLeaderView" */
  '@/views/coaching/coach/coachTask/leader/index.vue'))),
  // 任务详情(执行人列表)
  CoachTaskDetailTeam: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetailTeam" */
  '@/views/coaching/coach/coachTask/leaderPlan/index.vue'))),
  // 父任务详情
  CoachParentTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachParentTaskDetail" */'@/views/coaching/coach/coachTask/coachParentTaskDetail'))),
  // 任务详情
  CoachTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetail" */
  '@/views/coaching/coach/coachTask/leaderDetail/index.vue'))),
  // 统一培训 / 我的任务
  CoachTodo: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTodo" */
  '@/views/coaching/coach/coachTask/sales/index.vue'))),
  // 个人任务详情(我的任务)
  CoachTaskDetailPersonal: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetailPersonal" */
  '@/views/coaching/coach/coachTask/salesPlan/index'))),
  // 个人任务详情(智能对练)
  CoachTaskDetailSmartTraining: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskDetailPersonal" */
  '@/views/coaching/coach/coachTask/salesPlan/SmartTrainingDetail'))),
  // 个人任务练习（查看练习)
  CoachTaskPractice: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskPractice" */
  '@/views/coaching/coachTaskPractice/index.vue'))),
  // 个人任务练习（智能对练）
  CoachTaskPracticeSmartTraining: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachTaskPractice" */
  '@/views/coaching/coachTaskPractice/PracticeSmartTraining.vue'))),
  // 统一培训 / 新建编辑课程
  CoachCourseEdit: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachCourseEdit" */
  '@/views/coaching/courseEdit/index'))),
  // 统一培训 / 新建编辑AI客户(Alpha)课程
  AiCoachCourseEdit: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiCoachCourseEdit" */
  '@/views/coaching/aiCourseEdit/index'))),
  // 统一培训 / 新建编辑AI客户(Beta)课程
  AiSopCoachCourseEdit: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiSopCoachCourseEdit" */
  '@/views/coaching/aiSopCourseEdit/index'))),
  AiSopCoachCourse: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiSopCoachCourse" */
  '@/views/coaching/aiSopCourseEdit/aiSopCoachCourse.vue'))),
  AiCustomerGenerator: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiCustomerGenerator" */
  '@/views/coaching/AiCustomerGenerator/index'))),
  // 统一培训 / AI客户(Alpha)课程详情
  AiCoachTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "AiCoachTaskDetail" */
  '@/views/coaching/coach/coachTask/salesPlan/AiCoachTaskDetail'))),
  // 新建智能对练
  SmartTrainingEdit: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "SmartTraningEdit" */
  '@/views/coaching/smartTrainingEdit/index'))),
  // 统一培训/数据分析
  CoachDataAnalysis: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachDataAnalysis" */'@/views/coaching/coach/coachDataAnalysis/index'))),
  // 统一培训/数据分析/学员概览
  CoachDataAnalysisTraineeOverview: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachDataAnalysisTraineeOverview" */'@/views/coaching/coach/coachDataAnalysis/traineeOverview/index'))),
  // 统一培训/数据分析/任务详情
  CoachDataAnalysisTaskDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachDataAnalysisTaskDetail" */'@/views/coaching/coach/coachDataAnalysis/taskDetail/index'))),
  // 针对辅导
  CoachingTutor: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachingTutor" */'@/views/coaching/index'))),
  // 针对辅导 / 辅导管理
  TutorList: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "TutorList" */
  '@/views/coaching/tutor/tutorList/index'))),
  // 知识沉淀
  CoachingKnowledge: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "CoachingKnowledge" */
  '@/views/coaching/index'))),
  // 知识沉淀 / 知识库
  KnowledgeScript: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeScript" */
  '@/views/coaching/knowledge/knowledgeLibrary/index'))),
  // 知识沉淀 / 知识库 / 知识库
  KnowledgeScriptLibrary: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeScript" */
  '@/views/coaching/knowledge/knowledgeLibrary/knowledgeLibrary'))),
  // 知识沉淀 / 知识库 / 知识库详情
  KnowledgeScriptLibraryDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeScript" */
  '@/views/coaching/knowledge/knowledgeLibrary/knowledgeLibraryDetail'))),
  // 知识沉淀 / 知识库 / 知识点挖掘引擎
  KnowledgeSessionDig: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeScript" */
  '@/views/coaching/knowledge/knowledgePointMiningEngine/index'))),
  // 知识沉淀 / 剪辑库
  KnowledgeClip: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "KnowledgeClip" */
  '@/views/coaching/knowledge/snippetsLibrary/index'))),
  // 知识沉淀 / 剪辑库 详情
  ClipDetail: () => Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName: "ClipDetail" */'@/views/snippetsDetail')))
};
exports.COACHING_PAGES = COACHING_PAGES;