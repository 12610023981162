"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    fetchLogMethod: {
      type: Function,
      default: () => {}
    },
    pageSize: {
      type: Number,
      default: 10
    },
    logItemProperties: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      logRecords: [],
      total: 0,
      listLength: 0,
      currentPage: 1,
      loading: false
    };
  },
  computed: {
    isShow() {
      return this.logRecords && this.logRecords.length > 0;
    },
    noMore() {
      return this.listLength && this.listLength === this.total;
    },
    formatLogItemTime() {
      return function (time) {
        return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
      };
    }
  },
  created() {
    this.initData();
    this.showLogRecords(this.id, this.pageSize, this.fetchLogMethod);
  },
  methods: {
    loadMore() {
      this.showLogRecords(this.id, this.pageSize, this.fetchLogMethod);
    },
    getLogItemIcon(type) {
      if (type) {
        return this.logItemProperties[type].icon;
      }
    },
    getLogItemLabel(type) {
      if (type) {
        return this.logItemProperties[type].label;
      }
    },
    initData() {
      this.currentPage = 1;
      this.logRecords = [];
      this.listLength = 0;
    },
    async showLogRecords(id, pageSize, callback) {
      this.loading = true;
      const res = await callback(id, this.currentPage, pageSize);
      if (res.code === 200) {
        res.results.data.forEach(item => this.logRecords.push(item));
        this.total = res.results.total;
        this.listLength += res.results.data.length;
        this.currentPage++;
        this.loading = false;
      }
    }
  }
};
exports.default = _default;