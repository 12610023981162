"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _x = require("@antv/x6");
var _x6VueShape = require("@antv/x6-vue-shape");
var _StartNode = _interopRequireDefault(require("../graphNode/StartNode.vue"));
var _RoleNode = _interopRequireDefault(require("../graphNode/RoleNode.vue"));
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 自定义组件注册插件

const portConfig = {
  attrs: {
    circle: {
      r: 6,
      magnet: true,
      // 是否可连接
      stroke: _variablesModule.default.pageActiveText,
      strokeWidth: 5
    }
  },
  position: {
    name: 'absolute'
  }
};
const roleNode = {
  component: _RoleNode.default,
  type: 'node',
  width: 280,
  height: 136,
  ports: {
    groups: {
      branch: portConfig,
      rule: portConfig
    }
  }
};
const roleData = {
  isActive: false,
  branches: []
};
const register = function () {
  (0, _x6VueShape.register)({
    shape: 'narrator',
    ...roleNode,
    data: {
      role: 'narrator',
      roleName: (0, _i18n.t)('coaching.narration'),
      ...roleData
    }
  });
  (0, _x6VueShape.register)({
    shape: 'customer',
    ...roleNode,
    data: {
      role: 'customer',
      roleName: (0, _i18n.t)('coaching.customerSays'),
      ...roleData
    }
  });
  (0, _x6VueShape.register)({
    shape: 'salesman',
    ...roleNode,
    data: {
      role: 'salesman',
      roleName: (0, _i18n.t)('coaching.salespersonSays'),
      ...roleData,
      ruleType: 0,
      rules: [],
      scoreRuleType: 1,
      scoreRules: []
    }
  });
  (0, _x6VueShape.register)({
    shape: 'start',
    type: 'node',
    component: _StartNode.default,
    ports: {
      groups: {
        start: {
          attrs: {
            circle: {
              r: 6,
              magnet: true,
              // 是否可连接
              stroke: '#fff',
              fill: _variablesModule.default.pageActiveText,
              strokeWidth: 5
            }
          },
          position: {
            name: 'absolute',
            args: {
              x: 110,
              y: 20
            }
          }
        }
      },
      items: [{
        group: 'start',
        id: 'start'
      }]
    },
    data: {
      branches: [{
        id: 'start',
        text: (0, _i18n.t)('coaching.start')
      }]
    }
  });
  _x.Graph.registerEdge('custom-edge', {
    inherit: 'edge',
    zIndex: 0,
    type: 'edge',
    attrs: {
      line: {
        stroke: _variablesModule.default.graphEdge,
        strokeWidth: 2,
        targetMarker: 'block'
      },
      zIndex: -1
    }
  });
};
var _default = register;
exports.default = _default;