"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "leader-plan-header"
  }, [_c("div", {
    staticClass: "plan-info"
  }, [_c("div", {
    staticClass: "back",
    on: {
      click: _vm.handleBack
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("task-info", {
    attrs: {
      "task-info": _vm.taskInfo
    }
  })], 1), _vm.taskInfo.average_score !== -1 ? _c("PlanAverageScore", {
    attrs: {
      score: _vm.taskInfo.average_score
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;