"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _library = require("@/api/library");
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _ChooseFolderSelect = _interopRequireDefault(require("./ChooseFolderSelect"));
var _SetPublicScope = _interopRequireDefault(require("@/views/coaching/coach/course/components/SetPublicScope.vue"));
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvFlowInfo',
  components: {
    DynamicForm: _DynamicForm.default,
    ChooseFolderSelect: _ChooseFolderSelect.default,
    SetPublicScope: _SetPublicScope.default
  },
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    editNode: {
      type: Object,
      default: () => ({})
    },
    createSubfolder: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPublic: 1,
      form: {
        name: '',
        folder_id: ''
      },
      folderPosition: {},
      backfillList: [],
      checkableList: [],
      getOrgMemberTree: _user.getOrgMemberTree,
      formDesc: [{
        id: 'name',
        type: 'text',
        label: '名称',
        required: true,
        rule: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }, {
          max: 65,
          message: '长度不能超过65个字符',
          trigger: 'change'
        }]
      }, {
        id: 'folder_id',
        type: 'text',
        label: '位置',
        required: true
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['folderTree'])
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.setFolderPosition(this.currentNode);
          if (this.isEdit) {
            this.backFillData();
          }
        } else {
          this.clearData();
        }
      },
      immediate: true
    },
    folderPosition: {
      handler(val) {
        if (this.currentNode) {
          // eslint-disable-next-line vue/no-mutating-props
          this.currentNode.fid = val.id;
        }
        this.form.folder_id = val.id;
        this.isPublic = 1;
      },
      immediate: true
    },
    currentNode(val) {
      this.setFolderPosition(val);
    }
  },
  methods: {
    getConvFlowParams() {
      const {
        isPublic,
        assignee
      } = this.$refs['SetPublicScopeRef'];
      const params = {
        ...this.form,
        is_public: isPublic
      };
      if (!isPublic) {
        Object.assign(params, assignee);
      }
      return params;
    },
    async getCurrentFolderPermission() {
      const res = await (0, _library.getParentFolderPermission)({
        folder_id: 0,
        fid: this.editNode.id
      });
      if (res.code === 200) {
        const {
          is_public,
          selection_list
        } = res.results;
        this.isPublic = is_public;
        if (!is_public) {
          this.backfillList = this.formatBackfillList(selection_list);
        }
      }
    },
    setFolderPosition(val) {
      const root = {
        id: 0,
        name: '剪辑库'
      };
      if (this.createSubfolder) {
        this.folderPosition = val && val.id ? {
          ...val
        } : root;
      } else if (this.isEdit) {
        const father = (0, _commonFunc.getTreeNodeById)(this.folderTree, this.editNode.fid);
        this.folderPosition = father || root;
      } else {
        this.folderPosition = root;
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    clearData() {
      var _this$$refs$form;
      this.isPublic = 1;
      this.backfillList = [];
      this.checkableList = [];
      this.form = this.$options.data().form;
      (_this$$refs$form = this.$refs.form) === null || _this$$refs$form === void 0 ? void 0 : _this$$refs$form.resetFields();
    },
    async backFillData() {
      this.form.name = this.editNode.name;
      this.getCurrentFolderPermission();
    },
    formatBackfillList(list) {
      return list.map(i => {
        return {
          ...i,
          isDepartment: i.is_department,
          preNode: i.pre_node
        };
      });
    }
  }
};
exports.default = _default;