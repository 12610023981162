"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "form-item-card"
  }, [_c("div", {
    staticClass: "card-title"
  }, [_c("div", {
    staticClass: "title-line"
  }), _c("span", {
    staticClass: "title-name"
  }, [_vm._v(_vm._s(_vm.title))]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      enterable: true,
      content: _vm.tooltip
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tooltip,
      expression: "tooltip"
    }],
    staticClass: "iconfont icon-info-1"
  })]), _vm.explain ? _c("span", {
    staticClass: "explain"
  }, [_vm._v("（" + _vm._s(_vm.explain) + "）")]) : _vm._e()], 1), _c("div", {
    staticClass: "card-main"
  }, [_vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;