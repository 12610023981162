"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _TodoItem = _interopRequireDefault(require("./TodoItem.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TodoList',
  components: {
    TodoItem: _TodoItem.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    NoData: _NoData.default
  },
  props: {
    commentList: {
      type: Array,
      default: () => []
    },
    activeName: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    listLoading: {
      type: Boolean,
      default: false
    },
    nomore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg']),
    disabled() {
      return this.loading || this.nomore;
    }
  },
  methods: {
    load() {
      this.$emit('loadMore');
    },
    handleRead(id) {
      this.$emit('handleRead', id);
    },
    handleIgnore(id) {
      this.$emit('handleIgnore', id);
    },
    handleDelete(id) {
      this.$emit('delete', id);
    },
    handleRefresh() {
      this.$emit('refresh');
    }
  }
};
exports.default = _default;