"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.reply.score ? _c("el-popover", {
    attrs: {
      placement: "top",
      width: "240",
      trigger: "hover",
      "popper-class": "course-score-history-list"
    }
  }, [_c("el-button", {
    staticClass: "iconfont icon-drag-line hover-show-bg",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }), _c("div", [_c("p", {
    staticClass: "title"
  }, [_c("span", [_vm._v(_vm._s(_setup.t("coaching.finalScore")) + " "), _c("el-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("span", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(_vm._s(_setup.t("coaching.scoreDesc")))]), _c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _vm.reply.score ? _c("span", [_c("span", {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.reply.score))]), _vm._v(_vm._s(_setup.t("coaching.points")))]) : _vm._e()]), !_vm.reply.score ? _c("p", [_vm._v(" " + _vm._s(_setup.t("coaching,theExerciseHasNotBeenCompletedAndThereIsNoScore")) + " ")]) : _vm._e(), _c("ul", _vm._l(_vm.reply.list, function (item) {
    return _c("li", {
      key: item.id,
      class: item.is_qualified ? "green" : "red"
    }, [item.is_qualified ? _c("i", {
      staticClass: "iconfont icon-success-green"
    }) : _c("i", {
      staticClass: "iconfont icon-error-red"
    }), _c("span", [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.score))])]);
  }), 0)])], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;