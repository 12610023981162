"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemTypeTag = _interopRequireDefault(require("@/components/EvaluateForm/components/FormItemTypeTag"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'GeneratorFormItemOperation',
  props: {
    required: {
      type: Number,
      default: 0
    },
    isEditStatus: {
      type: Boolean,
      default: false
    },
    fieldTypeId: {
      type: Number,
      default: 1
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:required', 'delete-item', 'update:fieldTypeId'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onCheckBoxChange = val => {
      emit('update:required', val);
    };
    const onDeleteClick = () => {
      emit('delete-item');
    };
    const onFieldTypeIdChange = val => {
      emit('update:fieldTypeId', val);
    };
    return {
      __sfc: true,
      props,
      emit,
      onCheckBoxChange,
      onDeleteClick,
      onFieldTypeIdChange,
      FormItemTypeTag: _FormItemTypeTag.default
    };
  }
};
exports.default = _default;