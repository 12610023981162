"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    attrs: {
      size: "1000px",
      visible: _vm.visible,
      "before-close": _setup.handleClose
    }
  }, [_vm.currentTaskInfo.executorInfo ? _c("header", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("mgv-avatar-name", {
    attrs: {
      name: _vm.currentTaskInfo.executorInfo.name,
      src: _vm.currentTaskInfo.executorInfo.avatar,
      size: 28
    }
  }), _c("span", [_vm._v(_vm._s(_vm.currentTaskInfo.executorInfo.name))])], 1) : _vm._e(), _c("article", {
    ref: "drawerContainer"
  }, [[3, 6, 7].includes(_vm.currentTaskInfo.courseType) ? _c(_setup.ToggleTaskHistorySelect, {
    attrs: {
      "user-id": _vm.currentTaskInfo.executorInfo.id,
      "task-id": _vm.taskId
    },
    on: {
      toggleHistory: _setup.toggleHistory
    }
  }) : _vm._e(), _c("div", {
    class: ["overall-container"]
  }, [_c("div", {
    staticClass: "card"
  }, [_c("p", {
    staticClass: "card-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.getCourseScore")))]), _vm.currentTaskInfo.courseType !== 7 ? _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: _vm.$t("coaching.taskScoreDescription")
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })]) : _vm._e()], 1), _c("p", {
    staticClass: "card-score"
  }, [_c("span", [_vm._v(_vm._s(_setup.taskInfo.score >= 0 ? _setup.taskInfo.score : "--"))]), _setup.taskInfo.score >= 0 ? _c("span", [_vm._v(_vm._s(_setup.t("coaching.points")))]) : _vm._e()])]), _vm.currentTaskInfo.courseType < 3 ? _c("div", {
    staticClass: "card"
  }, [_c("p", [_vm._v(_vm._s(_setup.t("coaching.progressCompletion")))]), _c("div", {
    staticClass: "card-progress"
  }, [_c("el-progress", {
    attrs: {
      percentage: _setup.taskInfo.complate * 100 / _setup.taskInfo.total,
      "show-text": false,
      color: "#4461EC"
    }
  }), _c("p", {
    staticClass: "progress-remark"
  }, [_vm._v(" " + _vm._s(_setup.taskInfo.complate) + "/" + _vm._s(_setup.taskInfo.total) + " ")])], 1)]) : _vm._e()]), _vm.visible ? _c(_setup.componentName, {
    tag: "component",
    attrs: {
      "user-info": _vm.currentTaskInfo.executorInfo,
      "course-type": _vm.currentTaskInfo.courseType,
      "current-history-id": _setup.currentHistoryId,
      "task-id": _vm.taskId
    },
    on: {
      updateTaskInfo: _setup.updateTaskInfo,
      scrollToTop: _setup.scrollToTop
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;