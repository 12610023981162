"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotate = useAnnotate;
exports.useAnnotateTour = useAnnotateTour;
exports.useCheckAnnotateResults = useCheckAnnotateResults;
exports.useConvInfo = useConvInfo;
exports.useDataSet = useDataSet;
exports.useDataSetDataInfo = useDataSetDataInfo;
var _useRouter = require("@/hooks/use-router");
var _dataSet = require("@/api/dataSet");
var _vue = require("vue");
var _elementUi = require("element-ui");
// 获取dataSetId
function useDataSet() {
  var _route$query;
  const route = (0, _useRouter.useRoute)();
  const dataSetId = (0, _vue.ref)(Number(route === null || route === void 0 ? void 0 : (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.id));
  const dataSetName = (0, _vue.ref)('');
  (0, _vue.provide)('dataSetId', dataSetId);
  const dataSetDataList = (0, _vue.ref)([]);
  const dataSetLoading = (0, _vue.ref)(false);
  const getDataSetInfoApi = async () => {
    const res = await (0, _dataSet.getDataSetInfo)({
      dataset_id: dataSetId.value
    });
    if (res.code === 20000) {
      dataSetName.value = res.results.name;
    }
  };
  const getDataSetDataListApi = async () => {
    dataSetLoading.value = true;
    const res = await (0, _dataSet.getDataSetDataList)({
      dataset_id: dataSetId.value
    }).finally(() => dataSetLoading.value = false);
    if (res.code === 20000) {
      dataSetDataList.value = res.results.dataset_data;
    }
  };
  return {
    dataSetId,
    dataSetName,
    getDataSetInfoApi,
    getDataSetDataListApi,
    dataSetDataList,
    dataSetLoading
  };
}

// 获取当前选中的数据集数据信息
function useDataSetDataInfo(store, dataSetId, activeDataSetData) {
  const dataSetDataInfo = (0, _vue.ref)({});
  const setIsShowAvatar = function () {
    let sentence = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    if (!sentence.length) return;
    sentence[0].isShowAvatar = true;
    for (let i = 1; i < sentence.length; i++) {
      sentence[i].isShowAvatar = sentence[i].entity_id !== sentence[i - 1].entity_id;
    }
  };
  const getDataSetDataInfoApi = async () => {
    const res = await (0, _dataSet.getDataSetDataInfo)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id
    });
    if (res.code === 20000) {
      dataSetDataInfo.value = res.results;
      setIsShowAvatar(res.results.sentences);
      store.commit('video/SET_AUDIO_ALL', res.results.sentences);
      if (dataSetDataInfo.value.media_url) {
        store.commit('video/SET_HAS_VIDEO', true);
      }
    }
  };
  const dataSetLabelList = (0, _vue.ref)([]);
  const getDataSetLabelsApi = async () => {
    const res = await (0, _dataSet.getDataSetLabels)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id
    });
    if (res.code === 20000) {
      dataSetLabelList.value = res.results.labels;
      dataSetLabelList.value.forEach(item => item.isEdit = false);
    }
  };
  return {
    dataSetLabelList,
    getDataSetLabelsApi,
    dataSetDataInfo,
    getDataSetDataInfoApi
  };
}
function useConvInfo() {
  const localSliderTime = (0, _vue.ref)(0);
  const changeSliderTime = val => {
    localSliderTime.value = val;
  };
  return {
    localSliderTime,
    changeSliderTime
  };
}

// 标注相关
function useAnnotate(_ref) {
  let {
    store,
    dataSetId,
    activeDataSetData,
    dataSetDataInfo,
    getDataSetLabelsApi
  } = _ref;
  const sentenceOfEventTagMap = (0, _vue.ref)({});
  const annotateTextRef = (0, _vue.ref)(null);
  const eventLabelOfSentenceMap = (0, _vue.ref)({});
  const activeLabelIdsArr = (0, _vue.ref)([]);

  // 获取句子维度的关键事件
  const getEventListOfSentenceApi = async () => {
    const res = await (0, _dataSet.getEventListOfSentence)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id
    });
    if (res.code === 20000) {
      sentenceOfEventTagMap.value = res.results.order_to_annotations;
    }
  };
  const changeActiveLabelArr = async activeLabelArr => {
    await (0, _vue.nextTick)();
    activeLabelIdsArr.value = activeLabelArr ? [...activeLabelArr] : [];
  };
  // 获取标签维度对用的句子
  const getSentenceOfEventApi = async labelId => {
    const res = await (0, _dataSet.getSentenceOfEvent)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id,
      label_id: labelId,
      status: [1]
    });
    if (res.code === 20000) {
      eventLabelOfSentenceMap.value[labelId] = res.results.annotations;
      activeLabelIdsArr.value = [...activeLabelIdsArr.value];
    }
  };

  // 清空存储命中句子和activeLabelIdsArr
  const initCurrentSampleAnnotateInfo = () => {
    sentenceOfEventTagMap.value = {};
    activeLabelIdsArr.value = [];
    eventLabelOfSentenceMap.value = {};
  };

  // localStorage中存储当前选中的标签
  const storageSelectedAnnotateLabel = labelArr => {
    const userId = store.getters.userId;
    const orgId = store.getters.orgId;
    localStorage.setItem(`${orgId}-${userId}-annotate-selected-label`, JSON.stringify(labelArr));
  };

  // 创建标注
  const createAnnotateApi = async val => {
    const selectedSentenceOrder = annotateTextRef.value.getSelectedSentenceOrderArr();
    if (!(selectedSentenceOrder && selectedSentenceOrder.length)) {
      _elementUi.Message.warning('添加命中请至少选择一句话');
      return;
    }
    const sentences = [];
    [...new Set(selectedSentenceOrder)].map(item => {
      dataSetDataInfo.value.sentences.map(sentence => {
        if (item === sentence.order) {
          sentences.push(sentence);
        }
      });
    });
    const res = await (0, _dataSet.createAnnotate)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id,
      label_ids: val,
      sentences
    });
    if (res.code === 20000) {
      storageSelectedAnnotateLabel(val);
      _elementUi.Message.success('添加成功');
      annotateTextRef.value.handleCancelAddKeyEventTracker();
      annotateTextRef.value.clearSentenceOrderArr();
      getEventListOfSentenceApi();
      getDataSetLabelsApi();
      val.map(item => {
        getSentenceOfEventApi(item);
      });
    }
  };

  // 删除标注
  const deleteAnnotateApi = async val => {
    const res = await (0, _dataSet.deleteAnnotate)({
      dataset_id: dataSetId.value,
      dataset_data_id: activeDataSetData.value.id,
      label_id: val.label_id,
      annotation_ids: [val.annotation_id || val.id]
    });
    if (res.code === 20000) {
      getEventListOfSentenceApi();
      getDataSetLabelsApi();
      getSentenceOfEventApi(val.label_id);
      _elementUi.Message.success('删除成功');
    }
  };
  return {
    annotateTextRef,
    activeLabelIdsArr,
    eventLabelOfSentenceMap,
    sentenceOfEventTagMap,
    getEventListOfSentenceApi,
    getSentenceOfEventApi,
    changeActiveLabelArr,
    initCurrentSampleAnnotateInfo,
    createAnnotateApi,
    deleteAnnotateApi
  };
}
function useCheckAnnotateResults() {
  const checkAnnotateResultDrawerVisible = (0, _vue.ref)(false);
  const checkAnnotateResults = () => {
    checkAnnotateResultDrawerVisible.value = (0, _vue.ref)(true);
  };
  return {
    checkAnnotateResultDrawerVisible,
    checkAnnotateResults
  };
}
function useAnnotateTour() {
  const annotateTourRef = (0, _vue.ref)(null);
  const annotateGuideSteps = [{
    title: '新建要标注的事件',
    content: '可以用关键事件名称直接命名，比如“不规范话术”等',
    target: '[data-v-step="addNewAnnotateLabel"]',
    placement: 'left'
  }, {
    title: '勾选文本句按“1”标为命中',
    content: '试试用快捷键标注，效率翻倍！',
    target: '[data-v-step="annotateTextCheckBox"]',
    placement: 'bottom'
  }, {
    title: '完成当前数据的标注',
    content: '点此按钮或按“Shift+S”也能完成标注。正样本以外的会被视为负样本，因此标注时可以细心一点，不要遗漏噢！',
    target: '[data-v-step="annotateCompleteBtn"]',
    placement: 'bottom'
  }, {
    title: '在这里查看标注进度',
    content: '进度百分比=已标注条数/总条数',
    target: '[data-v-step="annotateProgress"]',
    placement: 'bottom'
  }];
  const annotateLabelTourRef = (0, _vue.ref)();
  const annotateLabelGuideSteps = [{
    title: '“1”代表正样本数，点击箭头可展开查看具体文本',
    content: '标为命中的文本=数据集的正样本，推荐训练集的正样本数能达到300个',
    target: '[data-v-step="annotateLabel"]',
    placement: 'bottom'
  }];
  return {
    annotateTourRef,
    annotateGuideSteps,
    annotateLabelTourRef,
    annotateLabelGuideSteps
  };
}