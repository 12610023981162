"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _vuex = require("vuex");
var _ConversationFilterItem = _interopRequireDefault(require("./ConversationFilterItem"));
var _MultipleCascaderSelect = _interopRequireDefault(require("@/components/MultipleCascaderSelect"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventTracker',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    MultipleCascaderSelect: _MultipleCascaderSelect.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    keyEvents: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onlySelectLeaf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 级联数据
      keyEventOptions: [],
      filtersOrigin: {
        keyEventCascaderList: [{
          inverse: false,
          keyEventName: []
        }],
        event_engine_relation: 'and'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    isDeleteIconShow() {
      return !(0, _isEqual.default)(this.filters.keyEventCascaderList, this.filtersOrigin.keyEventCascaderList);
    },
    defaultProps() {
      return {
        expandTrigger: 'hover',
        checkStrictly: !this.onlySelectLeaf,
        children: 'children',
        label: 'name',
        value: 'id'
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.getAllKeyEvent();
      },
      immediate: true
    }
  },
  methods: {
    getAllKeyEvent() {
      // 获取级联数据
      (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      }).then(res => {
        this.keyEventOptions = res.results.events;
        if (this.keyEvents.length) {
          this.getOtherPageKeyEvents();
        }
      });
    },
    getOtherPageKeyEvents() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.event_engine_relation = 'or';
      const keyEventIdList = this.getKeyEventDefaultIds([], [], this.keyEventOptions)[0];
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventCascaderList = [];
      this.keyEvents.forEach(item => {
        keyEventIdList.forEach(ele => {
          if (ele.slice(-1) == item) {
            // eslint-disable-next-line vue/no-mutating-props
            this.filters.keyEventCascaderList.push({
              keyEventName: ele,
              inverse: false
            });
          }
        });
      });
    },
    // 递归获取关键事件id数组
    getKeyEventDefaultIds(list, prevArray, source) {
      return source.map(item => {
        const array = [...prevArray, item.id];
        item.children ? this.getKeyEventDefaultIds(list, array, item.children) : list.push(array);
        return list;
      });
    },
    // 清空关键事件选择器级联数据
    deleteKeyEvent() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.keyEventCascaderList = JSON.parse(JSON.stringify(this.filtersOrigin.keyEventCascaderList));
      this.customChange([]);
    },
    customChange(val) {
      this.$emit('change', val);
    }
  }
};
exports.default = _default;