"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-item-set",
    class: {
      "is-validating": _vm.isSaveValidate
    }
  }, [_c("div", {
    staticClass: "select-model"
  }, [_vm.isEdit || _vm.localScoreObj.scoring_mode === "increase" ? _c("el-radio", {
    attrs: {
      label: "increase"
    },
    model: {
      value: _vm.localScoreObj.scoring_mode,
      callback: function ($$v) {
        _vm.$set(_vm.localScoreObj, "scoring_mode", $$v);
      },
      expression: "localScoreObj.scoring_mode"
    }
  }, [_c("div", {
    staticClass: "model-info"
  }, [_c("div", {
    staticClass: "model-name"
  }, [_vm._v("加分模式")]), _c("div", {
    staticClass: "model-detail"
  }, [_vm._v(" (适用于检查销售流程推进、客户信息挖掘等场景) ")])])]) : _vm._e(), _vm.isEdit || _vm.localScoreObj.scoring_mode === "decrease" ? _c("el-radio", {
    attrs: {
      label: "decrease"
    },
    model: {
      value: _vm.localScoreObj.scoring_mode,
      callback: function ($$v) {
        _vm.$set(_vm.localScoreObj, "scoring_mode", $$v);
      },
      expression: "localScoreObj.scoring_mode"
    }
  }, [_c("div", {
    staticClass: "model-info"
  }, [_c("div", {
    staticClass: "model-name"
  }, [_vm._v("减分模式")]), _c("div", {
    staticClass: "model-detail"
  }, [_vm._v("(适用于监测违禁话术、不良诱导等场景)")])])]) : _vm._e()], 1), _c("div", {
    staticClass: "rule-items-container"
  }, [_c("el-row", {
    staticClass: "rule-header"
  }, [_c("el-col", {
    attrs: {
      span: 1
    }
  }, [_c("div", {
    staticClass: "grid-content"
  })]), _c("el-col", {
    attrs: {
      span: 3
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm._v("评分项名称")])]), _c("el-col", {
    attrs: {
      span: 3
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm._v("类型")])]), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm._v("条件")])]), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm._v("计分规则")])]), _c("el-col", {
    attrs: {
      span: 2
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm._v("操作")])])], 1), _vm.localScoreObj.score_items.length ? _c("mg-draggable", {
    model: {
      value: _vm.localScoreObj.score_items,
      callback: function ($$v) {
        _vm.$set(_vm.localScoreObj, "score_items", $$v);
      },
      expression: "localScoreObj.score_items"
    }
  }, _vm._l(_vm.localScoreObj.score_items, function (item, index) {
    var _vm$typeOptions$item$;
    return _c("el-row", {
      key: item.id,
      staticClass: "rule-item",
      class: {
        active: item.id === _vm.currentScoreId
      },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("editScore", item.id);
        }
      }
    }, [_c("el-col", {
      attrs: {
        span: 1
      }
    }, [_c("i", {
      staticClass: "iconfont move icon-drag-dot"
    })]), _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_c("div", {
      staticClass: "score-name"
    }, [_vm._v(_vm._s(item.name))]), !item.name && _vm.isSaveValidate ? _c("span", {
      staticClass: "error-tip"
    }, [_vm._v("名称不能为空")]) : _vm._e()]), _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_c("div", [_vm._v(" " + _vm._s(["按是否命中", "按累计命中", "按命中项数"][item.score_type - 1]) + " ")])]), _c("el-col", {
      attrs: {
        span: 11
      }
    }, [_c("div", {
      staticClass: "score-condition-content"
    }, [_c("div", {
      staticClass: "score-condition-top"
    }, [_vm._v(" 当命中 "), _c("span", {
      staticClass: "score-condition"
    }, [_vm._v(_vm._s((_vm$typeOptions$item$ = _vm.typeOptions[item.type]) === null || _vm$typeOptions$item$ === void 0 ? void 0 : _vm$typeOptions$item$.label))])]), _c("div", {
      staticClass: "score-condition-bottom"
    }, [item.type === "key_words" && item.config.textFilterList ? _c("key-event-display", {
      attrs: {
        relation: item.config.filter_relation,
        "select-list": item.config.textFilterList,
        "no-separator": item.score_type === 3,
        "key-value": "pattern"
      }
    }) : _vm._e(), item.type === "event_engine" && item.config.keyEventCascaderList ? _c("key-event-display", {
      attrs: {
        relation: item.config.event_engine_relation,
        "select-list": item.config.keyEventCascaderList,
        "no-separator": item.score_type === 3,
        "key-value": "keyEventLabel"
      }
    }) : _vm._e(), item.type === "event_engine_order" && item.config.keyEventOrderCascaderList && item.config.keyEventOrderCascaderList[0].keyEventName.length ? _c("key-event-display", {
      attrs: {
        "has-order": "",
        "select-list": item.config.keyEventOrderCascaderList,
        "key-value": "keyEventLabel"
      }
    }) : _vm._e(), item.type === "account_label" && item.config.value ? _c("key-event-display", {
      attrs: {
        relation: item.config.relation,
        "account-label-array": _vm.accountLabelArray,
        "select-list": item.config.value,
        type: "accountLabel"
      }
    }) : _vm._e(), (item.type === "duration" || item.type === "max_sales" || item.type === "max_customer") && item.config.length ? _c("div", {
      staticClass: "display-tag"
    }, [_vm._v(" " + _vm._s(_vm.getConvDuration(item.config)) + " ")]) : _vm._e(), _vm.configUnitList.includes(_vm.typeOptions[item.type] && _vm.typeOptions[item.type].unit) && item.config.length ? _c("div", {
      staticClass: "display-tag"
    }, [_vm._v(" " + _vm._s(_vm.formatData(item.config, _vm.typeOptions[item.type].unit)) + " ")]) : _vm._e(), item.type === "hang_up" ? _c("div", {
      staticClass: "display-tag"
    }, [_vm._v(" " + _vm._s(["客户", "销售"][item.config - 1]) + " ")]) : _vm._e()], 1)]), !_vm.isConditionValid(item) && _vm.isSaveValidate ? _c("span", {
      staticClass: "error-tip"
    }, [_vm._v("条件不完整")]) : _vm._e()]), _c("el-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "scoring-type"
    }, [item.score_type === 1 && item.score ? _c("div", [_vm._v(" " + _vm._s((_vm.localScoreObj.scoring_mode === "decrease" ? "减" : "加") + item.score) + "分 ")]) : _vm._e(), item.score_type === 2 ? _c("div", [_vm._l(_vm.getScoreExtList(item.score_ext), function (score, _index) {
      return _c("p", {
        key: _index,
        staticClass: "score-rule-item"
      }, [_vm._v(" " + _vm._s(`第${score.gte}-${score.lte}次，每次${_vm.localScoreObj.scoring_mode === "decrease" ? "减" : "加"}${score.score}分`) + " ")]);
    }), _c("p", [_vm._v(" " + _vm._s(`最多${_vm.localScoreObj.scoring_mode === "decrease" ? "减" : "加"}${_vm.getTotalScore(item.score_ext, item.score_type)}分`) + " ")])], 2) : _vm._e(), item.score_type === 3 ? _c("div", [_vm._l(_vm.getScoreExtList(item.score_ext), function (score, _index) {
      return _c("p", {
        key: _index,
        staticClass: "score-rule-item"
      }, [_vm._v(" " + _vm._s(`任意${score.hits}项${_vm.localScoreObj.scoring_mode === "decrease" ? "减" : "加"}${score.score}分`) + " ")]);
    }), _c("p", [_vm._v(" " + _vm._s(`最多${_vm.localScoreObj.scoring_mode === "decrease" ? "减" : "加"}${_vm.getTotalScore(item.score_ext, item.score_type)}分`) + " ")])], 2) : _vm._e()]), !_vm.isScoreValid(item) && _vm.isSaveValidate ? _c("span", {
      staticClass: "error-tip"
    }, [_vm._v("计分规则错误")]) : _vm._e()]), _c("el-col", {
      attrs: {
        span: 2
      }
    }, [_c("div", {
      staticClass: "operate-buttons"
    }, [_c("i", {
      staticClass: "iconfont icon-edit",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.$emit("editScore", item.id);
        }
      }
    }), _c("el-popconfirm", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isEdit,
        expression: "isEdit"
      }],
      attrs: {
        "popper-class": "delete-popover",
        "confirm-button-text": "删除",
        "cancel-button-text": "取消",
        icon: "delete-icon el-icon-warning",
        title: "确认删除？"
      },
      on: {
        onConfirm: function ($event) {
          return _vm.deleteRuleItem(index, item.id);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-delete",
      attrs: {
        slot: "reference"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
        }
      },
      slot: "reference"
    })])], 1)])], 1);
  }), 1) : _c("div", {
    staticClass: "empty-content",
    on: {
      click: _vm.addNewRule
    }
  }, [_c("span", [_c("i", {
    staticClass: "iconfont icon-plus"
  }), _vm._v("评分项")])]), _c("el-row", {
    staticClass: "rule-footer",
    attrs: {
      type: "flex"
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.isEdit && _vm.localScoreObj.score_items.length < _vm.maxCount ? _c("el-button", {
    staticClass: "add-item-btn",
    attrs: {
      icon: "iconfont icon-plus",
      plain: ""
    },
    on: {
      click: _vm.addNewRule
    }
  }, [_vm._v("评分项 (" + _vm._s(_vm.localScoreObj.score_items.length) + "/" + _vm._s(_vm.maxCount) + ")")]) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("div", {
    staticClass: "full-score-content"
  }, [_c("span", {
    staticClass: "full-score"
  }, [_c("span", [_vm._v("满分")]), _c("span", {
    staticClass: "score-content"
  }, [_c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditFullMark,
      expression: "isEditFullMark"
    }],
    ref: "fullMarkInputRef",
    attrs: {
      value: _vm.localScoreObj.full_score
    },
    on: {
      input: _vm.handleFullMarkLimitInput,
      blur: _vm.handleFullMarkBlur
    }
  }, [_c("span", {
    staticClass: "unit",
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("分")])]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEditFullMark,
      expression: "!isEditFullMark"
    }],
    staticClass: "bold-number"
  }, [_vm._v(_vm._s(_vm.localScoreObj.full_score))])], 1), _vm.isEdit ? _c("el-tooltip", {
    attrs: {
      content: "修改满分",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-edit",
    on: {
      click: _vm.handleChangeFullMark
    }
  })]) : _vm._e()], 1), !_vm.isEditFullMark ? _c("span", {
    staticClass: "full-error-tip"
  }, [_vm._v(_vm._s(_vm.scoreValidateTip))]) : _vm._e()])])], 1)], 1), _vm.isEdit || _vm.localScoreObj.qualified.is_on ? _c("div", {
    staticClass: "set-standard"
  }, [_vm.isEdit ? _c("el-checkbox", {
    model: {
      value: _vm.localScoreObj.qualified.is_on,
      callback: function ($$v) {
        _vm.$set(_vm.localScoreObj.qualified, "is_on", $$v);
      },
      expression: "localScoreObj.qualified.is_on"
    }
  }, [_vm._v("设置合格标准")]) : _c("p", [_vm._v("合格标准")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localScoreObj.qualified.is_on,
      expression: "localScoreObj.qualified.is_on"
    }],
    staticClass: "stantard-content"
  }, [_c("span", [_vm._v("当得分大于等于")]), _c("el-input", {
    attrs: {
      value: _vm.localScoreObj.qualified.qualify_standard,
      size: "mini"
    },
    on: {
      input: _vm.handleQualifiedScoreInput,
      blur: _vm.handleQualifiedScoreBlur
    }
  }), _c("span", [_vm._v("标记为合格，否则标记为不合格")])], 1), _c("el-slider", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localScoreObj.qualified.is_on,
      expression: "localScoreObj.qualified.is_on"
    }],
    attrs: {
      "show-stops": "",
      value: parseInt(_vm.localScoreObj.qualified.qualify_standard / _vm.localScoreObj.full_score * 100),
      disabled: "",
      step: 5,
      marks: _vm.marks
    }
  })], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;