"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'ConversationContext',
  components: {},
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    formatCommentContent: _commonFunc.formatCommentContent
  }
};
exports.default = _default;