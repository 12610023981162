"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "init-card-preview"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("img", {
    attrs: {
      src: _vm.config.imgSrc
    }
  }), _c("ul", _vm._l(_vm.config.tooltip, function (item) {
    return _c("li", {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;