"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeOperation',
  components: {
    MoreOperations: _MoreOperations.default
  },
  data() {
    return {
      btnList: [{
        icon: 'microphone-plus',
        command: 'recording-audio',
        tip: '添加示例录音',
        permission: 'operateKnowledge'
      }, {
        icon: 'edit',
        command: 'edit',
        tip: '编辑',
        permission: 'operateKnowledge'
      }],
      dropdownList: [{
        command: 'move',
        label: '移动',
        icon: 'icon-folder-move'
      }, {
        command: 'delete',
        label: '删除',
        icon: 'icon-delete'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission'])
  },
  methods: {
    handleOperation(val) {
      this.$emit('operate', val);
    }
  }
};
exports.default = _default;