"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [!_vm.onlyRead ? _c("div", {
    staticClass: "train-super-param-content"
  }, [_vm._l(_setup.paramsList, function (item) {
    return [item.isShow ? _c("div", {
      key: item.key,
      staticClass: "train-super-param-item"
    }, [_c("div", {
      staticClass: "top-title"
    }, [_c("label", [_vm._v(_vm._s(item.parameterName))]), _c("span", [_vm._v(_vm._s(item.key))]), _c("el-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      domProps: {
        innerHTML: _vm._s(item.tips)
      },
      slot: "content"
    }), _c("i", {
      staticClass: "iconfont icon-info-1"
    })])], 1), _c("p", {
      staticClass: "explain"
    }, [_vm._v(_vm._s(item.explain))]), _c("div", {
      staticClass: "parameters-content"
    }, [["int", "float"].includes(item.valueType) ? _c("div", {
      staticClass: "number-type-content"
    }, [_c("el-slider", {
      attrs: {
        step: item.step,
        min: item.valueRange[0],
        max: item.valueRange[1]
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }), _c("el-input-number", {
      attrs: {
        precision: item.precision,
        step: item.step,
        "controls-position": "right",
        min: item.valueRange[0],
        max: item.valueRange[1]
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.key === "data_type" ? _c("el-select", {
      staticClass: "params-selector",
      attrs: {
        placeholder: "请选择",
        size: "small"
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, _vm._l(_setup.dataTypeOption, function (option) {
      return _c("el-option", {
        key: option.value,
        attrs: {
          label: option.label,
          value: option.value
        }
      });
    }), 1) : _vm._e()], 1)]) : _vm._e()];
  })], 2) : _c("div", {
    staticClass: "train-super-param-display"
  }, [_vm._l(_setup.paramsList, function (item) {
    return [item.isShow ? _c("div", {
      key: item.key,
      staticClass: "train-super-param-item"
    }, [_c("div", {
      staticClass: "top-title"
    }, [_c("label", [_vm._v(_vm._s(item.parameterName))]), _c("span", [_vm._v(_vm._s(item.key))]), _c("el-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      domProps: {
        innerHTML: _vm._s(item.tips)
      },
      slot: "content"
    }), _c("i", {
      staticClass: "iconfont icon-info-1"
    })])], 1), item.key === "data_type" ? _c("span", {
      staticClass: "params-value"
    }, [_vm._v(_vm._s(_setup.getDataType(item.value)))]) : _c("span", {
      staticClass: "params-value"
    }, [_vm._v(_vm._s(item.value))])]) : _vm._e()];
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;