"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _EvaluationContent = _interopRequireDefault(require("./components/EvaluationContent.vue"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EvaluteIndex',
  components: {
    EvaluationContent: _EvaluationContent.default
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filtersObj: {
        type: 1,
        name: '',
        status: '',
        page: 1,
        size: 20,
        sort_by: ['create_at', 'descending'],
        workspace_id: 0
      },
      tableData: [],
      total: 0,
      maxCount: 0,
      tableLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    noFilter() {
      return this.filtersObj.name === '' && this.filtersObj.status === -1;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          const templateId = this.$route.query.id;
          this.filtersObj.workspace_id = val;
          await this.getEvaluationTemplateList();
          if (templateId) {
            this.$router.replace({
              path: _routeMap.ROUTES.complianceEvaluation
            });
            this.tableData.forEach(item => {
              if (item.id === Number(templateId)) {
                this.$set(item, 'highlight', true);
              }
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    filterChange(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.getEvaluationTemplateList();
    },
    async getEvaluationTemplateList() {
      if (this.filtersObj.status === '') {
        this.filtersObj.status = -1;
      }
      this.tableLoading = true;
      const res = await (0, _compliance.getEvaluationTemplateList)(this.filtersObj).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        const {
          template_list,
          count,
          max_count
        } = res.results;
        this.tableData = template_list;
        this.total = count;
        this.maxCount = max_count;
      }
    },
    handleEvaluationStatus(row) {
      if (row.status === 1) {
        this.$confirm(`禁用后该评价单不可在创建质检任务时关联，确定禁用？`, '禁用评价单', {
          confirmButtonText: '禁用',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.updateEvaluationTemplate(row);
        });
      } else {
        this.updateEvaluationTemplate(row);
      }
    },
    async updateEvaluationTemplate(row) {
      const status = row.status === 1 ? 2 : 1;
      const res = await (0, _compliance.updateEvaluationTemplate)({
        template_id: row.id,
        status,
        version: row.version
      });
      if (res.code === 20000) {
        this.$message({
          type: 'success',
          message: `${row.status === 1 ? '禁用成功' : '启用成功'}`
        });
        this.tableData.forEach(item => {
          if (item.id === row.id) {
            item.status = status;
          }
        });
      }
    }
  }
};
exports.default = _default;