"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ItemTemplate',
  props: {
    type: {
      type: String,
      // questions:客户常见问法 answers:回答技巧 tricks:标准话术
      default: 'questions'
    },
    customTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      titleInfo: {
        questions: {
          name: '客户常见问法',
          icon: 'question2'
        },
        answers: {
          name: '回答技巧',
          icon: 's-opportunity'
        },
        tricks: {
          name: '标准话术',
          icon: 'excellent-talk-fill'
        }
      }
    };
  }
};
exports.default = _default;