"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'FilterTab',
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isShowAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  computed: {
    mergedList() {
      let array = this.list || [];
      if (this.isShowAll) {
        array = [{
          value: 0,
          name: this.$t('coaching.all')
        }, ...this.list];
      }
      return array;
    }
  },
  watch: {
    activeIndex: {
      handler: function (index) {
        var _this$mergedList$inde, _this$mergedList$inde2;
        const value = ((_this$mergedList$inde = this.mergedList[index]) === null || _this$mergedList$inde === void 0 ? void 0 : _this$mergedList$inde.value) || ((_this$mergedList$inde2 = this.mergedList[index]) === null || _this$mergedList$inde2 === void 0 ? void 0 : _this$mergedList$inde2.id) || 0;
        const obj = this.mergedList[index] || {};
        this.$emit('tabChange', value, obj);
      },
      deep: true
    }
  },
  methods: {
    tabClick(index) {
      this.activeIndex = index;
    },
    init() {
      this.activeIndex = 0;
    }
  }
};
exports.default = _default;