"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "drawer-warp"
  }, [_c("el-dialog", {
    staticClass: "share-dialog",
    attrs: {
      visible: _vm.visible,
      title: "分享",
      "before-close": _vm.cancelShare,
      "append-to-body": "",
      modal: _vm.location === "detail" ? false : "",
      "custom-class": _vm.location === "detail" ? "share-conv-dialog" : "",
      "destroy-on-close": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.shareEnter.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "share-all"
  }, [_c("span", {
    staticClass: "text-main share-text"
  }, [_vm._v("全公司公开")]), _c("el-switch", {
    attrs: {
      "active-color": "#4461EC",
      "inactive-color": "#8E95B0"
    },
    on: {
      change: _vm.switchChange
    },
    model: {
      value: _vm.shareAll,
      callback: function ($$v) {
        _vm.shareAll = $$v;
      },
      expression: "shareAll"
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shareAll,
      expression: "!shareAll"
    }],
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", {
    staticClass: "select-wrapper"
  }, [_c("members-finder", {
    ref: "selectMembers",
    attrs: {
      placeholder: "请选择成员",
      "has-self": false
    },
    on: {
      change: _vm.handleSelectChange
    }
  }), _c("el-button", {
    attrs: {
      loading: _vm.submitLoading,
      type: "primary"
    },
    on: {
      click: _vm.shareEnter
    }
  }, [_vm._v("分享")])], 1), _c("div", {
    staticClass: "shared_users"
  }, _vm._l(_vm.sharedList, function (user, index) {
    return _c("div", {
      key: index,
      staticClass: "one-user member"
    }, [_c("div", {
      staticClass: "avatar-wrapper"
    }, [_c("image-avatar", {
      staticClass: "user-avatar",
      attrs: {
        avatar: user.avatar,
        name: user.name
      }
    }), _c("span", {
      staticClass: "user-name"
    }, [_vm._v(_vm._s(user.name))]), _c("span", {
      staticClass: "user-tel"
    }, [_vm._v(_vm._s(user.tel))])], 1), _c("span", {
      staticClass: "user-role"
    }, [_vm._v("成员")]), _c("el-button", {
      staticClass: "btn-delete",
      attrs: {
        size: "small"
      },
      on: {
        click: function ($event) {
          return _vm.delOneSharedUser(user);
        }
      }
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": "delete"
      }
    })], 1)], 1);
  }), 0)])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;