"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("dynamic-form", {
    staticClass: "information-card",
    attrs: {
      "is-edit": false,
      desc: _vm.formDesc,
      "label-position": "left"
    },
    model: {
      value: _vm.localFormData,
      callback: function ($$v) {
        _vm.localFormData = $$v;
      },
      expression: "localFormData"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;