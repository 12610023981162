"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modelManagement = require("@/api/modelManagement");
var _vue = require("vue");
var _default = {
  __name: 'ModelVersionSelect',
  props: {
    modelId: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Number, String],
      default: ''
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    strictVersionDisable: {
      type: Boolean,
      default: false
    },
    defaultSelectedPublishedVersion: {
      type: Boolean,
      default: false
    },
    selectLastVersion: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'onChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onInput = val => emit('input', val);
    const onChange = val => {
      emit('onChange', val);
    };
    const options = (0, _vue.ref)([]);
    (0, _vue.watchEffect)(() => {
      if (props.modelId) {
        (0, _modelManagement.getModelVersionList)({
          model_id: props.modelId,
          page: 1,
          size: 9999
        }).then(res => {
          options.value = res.results.versions;
          // 需要默认选中上线的版本
          if (!props.value && props.defaultSelectedPublishedVersion) {
            var _res$results$versions;
            onInput((_res$results$versions = res.results.versions.find(item => item.is_online)) === null || _res$results$versions === void 0 ? void 0 : _res$results$versions.version);
          }

          // 需要选中最新的版本
          if (!props.value && props.selectLastVersion) {
            var _res$results$versions2;
            onInput((_res$results$versions2 = res.results.versions[res.results.versions.length - 1]) === null || _res$results$versions2 === void 0 ? void 0 : _res$results$versions2.version);
          }
        });
      }
    });
    const getVersionStatus = val => {
      switch (val) {
        case 2:
          return {
            text: '训练完成',
            color: '#33CC8C'
          };
        case 1:
          return {
            text: '训练中',
            color: '#4461EC'
          };
        case 0:
          return {
            text: '等待训练',
            color: '#FFA100'
          };
        default:
          return {};
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      onInput,
      onChange,
      options,
      getVersionStatus
    };
  }
};
exports.default = _default;