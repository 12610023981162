"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _index = _interopRequireDefault(require("@/mgv-ui/packages/mgv-tag/index"));
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'MissPointDetailDrawerContent',
  props: {
    cardInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(__props) {
    const props = __props;
    (0, _vue.useCssVars)((_vm, _setup) => ({
      "64a761a4-expandedHeight": _setup.expandedHeight,
      "64a761a4-initHeight": _setup.initHeight
    }));
    const detailInfo = (0, _vue.ref)({});
    const loading = (0, _vue.ref)(false);
    async function getDetailInfo() {
      loading.value = true;
      const res = await (0, _coachingCenter.getCourseAnalysisMissPointsDetail)({
        card_id: props.cardInfo.card_id,
        plan_id: props.cardInfo.plan_id
      }).finally(() => {
        loading.value = false;
      });
      detailInfo.value = res.results;
    }
    (0, _vue.watchEffect)(() => {
      getDetailInfo();
    });
    const isExpanded = (0, _vue.ref)(false);
    const initHeight = (0, _vue.ref)('auto');
    const expandedHeight = (0, _vue.ref)('0px');
    (0, _vue.onBeforeMount)(() => {
      isTextLongerThanMaxLines(detailInfo.value.standard_content, 3);
    });
    function isTextLongerThanMaxLines(text, maxLines) {
      const lineHeight = 1.4; // Assume line height is 1.4 times the font size
      const fontSize = 14; // Assume font size is 16px
      const maxHeight = maxLines * lineHeight * fontSize;
      const width = 560;
      const dummyElement = document.createElement('p');
      dummyElement.innerHTML = text;
      dummyElement.style.width = width + 'px';
      dummyElement.style.lineHeight = lineHeight;
      dummyElement.style.maxHeight = maxHeight + 'px';
      dummyElement.style.overflow = 'hidden';
      dummyElement.style.textOverflow = 'ellipsis';
      dummyElement.style.webkitLineClamp = maxLines;
      dummyElement.style.webkitBoxOrient = 'vertical';
      dummyElement.style.display = '-webkit-box';
      document.body.appendChild(dummyElement);
      const isLonger = dummyElement.scrollHeight > maxHeight;
      if (isLonger) {
        initHeight.value = 83 + 'px';
        expandedHeight.value = dummyElement.scrollHeight + 24 + 'px';
      } else {
        initHeight.value = dummyElement.offsetHeight + 'px';
      }
      document.body.removeChild(dummyElement);
      return isLonger;
    }
    return {
      __sfc: true,
      props,
      detailInfo,
      loading,
      getDetailInfo,
      isExpanded,
      initHeight,
      expandedHeight,
      isTextLongerThanMaxLines,
      t: _i18n.t,
      ImageAvatar: _ImageAvatar.default,
      MgvTag: _index.default
    };
  }
};
exports.default = _default;