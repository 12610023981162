"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.displayStatus ? _c("section", {
    staticClass: "meeting-helper"
  }, [_vm.isOnTrial ? _c("div", {
    staticClass: "preview-banner banner-header"
  }, [_vm._v(" 使用 Megaview 您将收获如下体验 ")]) : _vm._e(), _c("div", {
    staticClass: "entity-info-container"
  }, [_c("h1", {
    staticClass: "title-row"
  }, [_c("i", {
    class: ["iconfont", `icon-${_vm.entityTitle.icon}`]
  }), _c("span", [_vm._v(_vm._s(_vm.entityTitle.name))])]), _c("table", _vm._l(_vm.entityDetail, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "item-label"
    }, [_vm._v(_vm._s(item.label))]), _c("td", {
      staticClass: "item-value"
    }, [item.key === "risk" ? _c("el-popover", {
      attrs: {
        placement: "right",
        width: "180",
        trigger: "hover"
      }
    }, [_c("risk-card", {
      attrs: {
        list: item.value
      }
    }), _c("risk-popover", {
      attrs: {
        slot: "reference",
        list: item.value
      },
      slot: "reference"
    })], 1) : item.key === "deal_stage" && item.value ? _c("mg-tag", {
      attrs: {
        size: "small"
      }
    }, [_vm._v(_vm._s(item.value))]) : [_vm._v(" " + _vm._s(item.value || "-") + " ")]], 2)]);
  }), 0)]), _c("el-tabs", {
    ref: "tabs",
    class: {
      "header-fixed-top": _vm.isTabScroll2Top,
      "is-on-trial": _vm.isOnTrial
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "跟进活动",
      name: "timeline"
    }
  }, [_vm.entityId ? _c("conversation-detail", {
    attrs: {
      "demo-data": _vm.demoTimeline,
      "entity-id": _vm.entityId,
      "entity-type": _vm.entityType
    }
  }) : _vm._e()], 1), !_vm.isOnTrial ? _c("el-tab-pane", {
    attrs: {
      label: "私人笔记",
      name: "note"
    }
  }, [_vm.conversationId && !_vm.isOnTrial ? _c("video-templates", {
    attrs: {
      "save-time": _vm.noteSavedTime,
      "note-empty": _vm.noteIsEmpty
    },
    on: {
      showNote: _vm.handleShowNote
    }
  }) : _vm._e(), _vm.conversationId || _vm.isOnTrial ? _c("note-editor", {
    ref: "note-editor",
    class: ["reality-note", {
      "is-not-ontrial": !_vm.isOnTrial
    }],
    attrs: {
      "is-demo": _vm.isOnTrial,
      "conversation-id": _vm.conversationId
    },
    on: {
      saveSucceed: _vm.handleNoteSaved,
      change: _vm.handleNoteChange,
      ready: _vm.handleNoteChange
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _vm.isOnTrial ? _c("div", {
    staticClass: "preview-banner banner-footer"
  }, [_c("el-form", {
    ref: "preForm",
    staticClass: "tel-form",
    attrs: {
      model: _vm.preForm,
      rules: _vm.preFormRules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "tel"
    }
  }, [_c("el-input", {
    staticClass: "appoint-input",
    attrs: {
      maxlength: "11",
      placeholder: "请填写手机号"
    },
    model: {
      value: _vm.preForm.tel,
      callback: function ($$v) {
        _vm.$set(_vm.preForm, "tel", $$v);
      },
      expression: "preForm.tel"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append"
    },
    on: {
      click: _vm.openService
    },
    slot: "append"
  }, [_vm._v("开启销售智能")])], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm.displayStatus === "preForm" ? _c("pre-appoint", {
    on: {
      input: _vm.handlePreInput,
      backTo: function ($event) {
        _vm.displayStatus = "";
      }
    }
  }) : _vm.displayStatus === "form" ? _c("appoint-form", {
    attrs: {
      "use-type": _vm.preForm.account_type,
      tel: _vm.preForm.tel
    },
    on: {
      submitSucceed: function ($event) {
        _vm.displayStatus = "afterSubmit";
      },
      backTo: function ($event) {
        _vm.displayStatus = "preForm";
      }
    }
  }) : _vm.displayStatus === "afterSubmit" ? _c("after-submit-card", {
    on: {
      backTo: function ($event) {
        _vm.displayStatus = "";
      }
    }
  }) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;