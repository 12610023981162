"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "set-name-dialog",
    attrs: {
      title: "抽样范围名称",
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "400px",
      "modal-append-to-body": false
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name"
    }
  }, [_c("el-input", {
    ref: "nameInput",
    attrs: {
      placeholder: "请输入抽样范围名称",
      maxlength: "20",
      clearable: ""
    },
    model: {
      value: _vm.ruleForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "name", $$v);
      },
      expression: "ruleForm.name"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;