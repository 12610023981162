"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCreateMutualExclusion = useCreateMutualExclusion;
exports.useDeleteMutualExclusion = useDeleteMutualExclusion;
exports.useGetMutualExclusionList = useGetMutualExclusionList;
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
var _keyEventMutex = require("@/api/keyEventMutex");
var _elementUi = require("element-ui");
function useCreateMutualExclusion(store, operateMutexRuleId) {
  const usedIds = (0, _vue.ref)([]);
  const getUsedKeyEventIdsApi = async () => {
    const res = await (0, _keyEventMutex.getUsedKeyEventIds)({
      workspace_id: store.getters.workspaceInfo.id
    });
    if (res.code === 20000) {
      usedIds.value = res.results.list;
    }
  };
  const createMutualExclusionRuleDrawerVisible = (0, _vue.ref)(false);
  const openMutualExclusionDrawer = id => {
    operateMutexRuleId.value = id;
    getUsedKeyEventIdsApi();
    createMutualExclusionRuleDrawerVisible.value = true;
  };
  return {
    createMutualExclusionRuleDrawerVisible,
    openMutualExclusionDrawer,
    usedIds
  };
}
function useDeleteMutualExclusion(store, getMutualExclusionListApi) {
  const deleteMutexRuleAsync = id => {
    _elementUi.MessageBox.confirm(`是否删除本条互斥策略？`, '删除', {
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      type: 'warning',
      confirmButtonClass: 'el-button--danger'
    }).then(async () => {
      const res = await (0, _keyEventMutex.deleteKeyEventMutex)({
        id,
        workspace_id: store.getters.workspaceInfo.id
      });
      if (res.code === 20000) {
        _useMessage.useMessage.success('删除成功');
        getMutualExclusionListApi();
      }
    }).catch(() => {});
  };
  return {
    deleteMutexRuleAsync
  };
}
function useGetMutualExclusionList(store) {
  const mutexRuleList = (0, _vue.ref)([]);
  const tableLoading = (0, _vue.ref)(false);
  const getMutualExclusionListApi = async () => {
    tableLoading.value = true;
    const res = await (0, _keyEventMutex.getKeyEventMutexList)({
      workspace_id: store.getters.workspaceInfo.id
    }).finally(() => tableLoading.value = false);
    if (res.code === 20000) {
      mutexRuleList.value = res.results.list;
    }
  };
  return {
    getMutualExclusionListApi,
    mutexRuleList,
    tableLoading
  };
}