"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationItem = _interopRequireDefault(require("@/views/coaching/aiCourseEdit/components/conversationItem.vue"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentGenerationUserInput',
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    },
    description: {
      type: String,
      default: ''
    }
  },
  emits: 'submit',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const route = (0, _useRouter.useRoute)();
    const isCreate = route.query.isCreate;
    const store = (0, _useVuex.useStore)();
    const modulePermission = (0, _vue.computed)(() => store.getters.modulePermission);
    const handleDescriptionInput = val => {
      emit('update:description', val);
    };
    const submit = () => {
      emit('submit');
    };
    const addConversation = () => {
      emit('add-conversation');
    };
    const hasConversation = (0, _vue.computed)(() => {
      return !(0, _isEmpty.default)(props.conversation);
    });
    const close = () => {
      emit('close');
    };
    const deleteConversation = () => {
      emit('delete-conversation');
    };
    return {
      __sfc: true,
      props,
      route,
      isCreate,
      store,
      modulePermission,
      emit,
      handleDescriptionInput,
      submit,
      addConversation,
      hasConversation,
      close,
      deleteConversation,
      ConversationItem: _conversationItem.default
    };
  }
};
exports.default = _default;