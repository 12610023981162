"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    ref: "accountLabelPopoverRef",
    attrs: {
      placement: "bottom",
      "popper-class": "account-label-cascader-select-popover",
      trigger: "click"
    }
  }, [_c("multiple-cascader-tracker", {
    attrs: {
      item: _vm.item,
      value: _vm.value,
      "other-page-account-label": _vm.otherPageAccountLabel
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event);
      },
      addKeyEventCascader: _vm.addKeyEventCascader
    }
  }), _c("el-button", {
    staticClass: "button-pretend-input",
    class: {
      "button-has-content": _vm.chosenCount
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.chosenCount ? "客户标签" : "请选择客户标签")), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.chosenCount,
      expression: "chosenCount"
    }],
    staticClass: "chosen-count"
  }, [_vm._v(_vm._s(_vm.chosenCount))])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;