"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationScoreRule = require("@/api/conversationScoreRule.js");
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
var _routeMap = require("@/router/routeMap");
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _ScoreRuleCard = _interopRequireDefault(require("./components/ScoreRuleCard.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConversationScoreRule',
  components: {
    SpaceButton: _SpaceButton.default,
    NoData: _NoData.default,
    ScoreRuleCard: _ScoreRuleCard.default
  },
  data() {
    return {
      helpUrl: 'https://www.megaview.com/help-center/doc?docx=N90ydsVayoQ7RtxQRVbc9Rr6nUe&anchor=RUyidMEyCoyyeGxctX0cLI39ngc',
      ROUTES: _routeMap.ROUTES,
      workspace_id: 0,
      maxCount: 10,
      canCreate: false,
      activeItemInfo: {},
      dialogVisible: false,
      isCheckedOldData: false,
      oldDataDateValue: 1,
      oldDataDateOptions: [{
        value: 1,
        label: '近7天'
      }, {
        value: 2,
        label: '近30天'
      }, {
        value: 3,
        label: '近3个月'
      }, {
        value: 4,
        label: '所有'
      }],
      activeOperate: 'on',
      operateConfirmObj: {
        on: {
          confirmTitle: '启用评分规则',
          content: '启用后，评分范围内的新会话数据将按此规则评分',
          confirmButtonName: '启用',
          confirmButtonClass: 'primary'
        },
        off: {
          confirmTitle: '禁用评分规则',
          content: '禁用后，新会话数据将不再使用此规则评分，筛选条件和历史评分数据也将隐藏',
          confirmButtonName: '禁用',
          confirmButtonClass: 'primary'
        },
        delete: {
          confirmTitle: '删除评分规则',
          content: '删除后，此规则的所有历史评分数据也将随之删除',
          confirmButtonName: '删除',
          confirmButtonClass: 'error'
        }
      },
      confirmBtnLoading: false,
      scoreList: [],
      saveBtnLoading: false,
      saveAndEnableBtnLoading: false,
      currentEditFormModel: 'create',
      formLoading: false,
      scoreListLoading: false,
      conversationCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        this.workspace_id = val;
        const ruleId = this.$route.query.id;
        if (val) {
          await this.getScoreRuleList();
          if (ruleId) {
            this.highlightRule(Number(ruleId));
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    goBackConversation() {
      this.$router.push({
        path: _routeMap.ROUTES.conversation
      });
    },
    goConvPage(id, name) {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.conversation,
        query: {
          score_rule_id: id,
          score_rule_name: name
        }
      });
      window.open(routeData.href, '_blank');
    },
    highlightRule(id) {
      this.scoreList.forEach(item => {
        if (item.id === id) {
          this.$set(item, 'highlight', true);
          this.$router.replace({
            path: _routeMap.ROUTES.conversationScoreSetting
          });
        }
      });
    },
    async getScoreRuleList() {
      this.scoreListLoading = true;
      const res = await (0, _conversationScoreRule.getScoreRuleList)({
        workspace_id: this.workspace_id
      }).finally(() => {
        this.scoreListLoading = false;
      });
      if (res.code === 20000) {
        const {
          max_count,
          can_create,
          rules
        } = res.results;
        this.maxCount = max_count;
        this.canCreate = can_create;
        this.scoreList = rules;
      }
    },
    async changeScoreRuleStatus(info) {
      return new Promise((resolve, reject) => {
        this.confirmBtnLoading = true;
        const date_range = this.isCheckedOldData ? this.oldDataDateValue : 0;
        const data = {
          workspace_id: this.workspace_id,
          rule_id: info.id,
          is_on: !info.is_on,
          date_range
        };
        (0, _conversationScoreRule.changeScoreRuleStatus)(data).then(res => {
          if (res.code === 200) {
            this.$message.success('保存成功');
            info.is_on = !info.is_on;
            this.closeDialog();
            if (info.is_on) {
              const message = this.conversationCount ? '启用成功，会话正在评分中，完成时会再次消息通知您' : '启用成功';
              this.$message.success(message);
            }
          }
          resolve(res);
        }).finally(() => {
          this.confirmBtnLoading = false;
        });
      });
    },
    deleteScoreRule(info) {
      this.confirmBtnLoading = true;
      const data = {
        workspace_id: this.workspace_id,
        rule_id: info.id
      };
      (0, _conversationScoreRule.deleteScoreRule)(data).then(res => {
        if (res.code === 20000) {
          const index = this.scoreList.findIndex(item => item.id === info.id);
          this.scoreList.splice(index, 1);
          this.closeDialog();
        }
      }).finally(() => {
        this.confirmBtnLoading = false;
      });
    },
    openDialog(key, info) {
      this.activeOperate = key;
      this.activeItemInfo = info;
      this.isCheckedOldData = this.$options.data().isCheckedOldData;
      this.oldDataDateValue = this.$options.data().oldDataDateValue;
      if (this.activeOperate === 'on') {
        this.getConversationCount(info.id);
      }
      this.dialogVisible = true;
    },
    async getConversationCount(id) {
      const params = {
        workspace_id: this.workspaceInfo.id,
        rule_id: id
      };
      const res = await (0, _conversationScoreRule.getConversationCount)(params);
      if (res.code === 200) {
        this.conversationCount = res.results.count;
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    dialogSubmit() {
      if (this.activeOperate === 'delete') {
        this.deleteScoreRule(this.activeItemInfo);
      } else {
        if (this.isSaveAndEnable && this.activeOperate === 'on') {
          this.saveRuleRequest(true);
        } else {
          this.changeScoreRuleStatus(this.activeItemInfo);
        }
      }
    },
    goScoreRuleEdit() {
      this.$router.push({
        path: _routeMap.ROUTES.conversationCreateScoreRule,
        query: {
          type: 'create'
        }
      });
    },
    saveRuleRequest() {
      let needEnable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const api = this.currentEditFormModel === 'edit' ? _conversationScoreRule.updateScoreRule : _conversationScoreRule.createScoreRule;
      const data = this.handlePostFormData();
      if (needEnable) {
        this.saveAndEnableBtnLoading = true;
      } else {
        this.saveBtnLoading = true;
      }
      api(data).then(async res => {
        if (res.code === 20000) {
          if (!needEnable) {
            // 仅保存
            this.saveBtnLoading = false;
            this.$message.success('保存成功');
            this.getScoreRuleList();
          } else {
            // 保存并启用

            const id = res.results.id || this.formData.id;
            const info = {
              id,
              is_on: false,
              name: this.formData.name
            };
            const changeRes = await this.changeScoreRuleStatus(info);
            if (changeRes.code === 200) {
              this.isSaveAndEnable = false;
              this.saveAndEnableBtnLoading = false;
              this.getScoreRuleList();
            }
          }
        }
      }).finally(() => {
        this.saveAndEnableBtnLoading = false;
        this.saveBtnLoading = false;
      });
    },
    stringifyData(data) {
      var _data$score_item_conf, _data$score_item_conf2, _data$score_item_conf3, _data$score_item_conf4, _data$score_item_conf5, _data$score_item_conf6;
      // 传递给后端时区
      data.data_scope.time_zone = this.timezone;
      // 每一评分项分数转化为 Number
      (_data$score_item_conf = data.score_item_config) === null || _data$score_item_conf === void 0 ? void 0 : (_data$score_item_conf2 = _data$score_item_conf.score_items) === null || _data$score_item_conf2 === void 0 ? void 0 : _data$score_item_conf2.forEach(item => {
        item.score = Number(item.score);
      });
      // 总分转化为 Number
      data.score_item_config.full_score = Number(data.score_item_config.full_score);
      // 设置合格分数转化为 Number
      if ((_data$score_item_conf3 = data.score_item_config) !== null && _data$score_item_conf3 !== void 0 && (_data$score_item_conf4 = _data$score_item_conf3.qualified) !== null && _data$score_item_conf4 !== void 0 && _data$score_item_conf4.qualify_standard) {
        data.score_item_config.qualified.qualify_standard = Number(data.score_item_config.qualified.qualify_standard);
      }
      (_data$score_item_conf5 = data.score_item_config) === null || _data$score_item_conf5 === void 0 ? void 0 : (_data$score_item_conf6 = _data$score_item_conf5.score_items) === null || _data$score_item_conf6 === void 0 ? void 0 : _data$score_item_conf6.forEach(item => {
        item.config = JSON.stringify(item.config);
      });
      (0, _timezone.convertParams2UTCplus8)(data.data_scope.config.date);
      data.data_scope.config = JSON.stringify(data.data_scope.config);
    },
    handlePostFormData() {
      const data = (0, _commonFunc.deepClone)(this.formData);
      this.stringifyData(data);
      if (this.currentEditFormModel === 'edit') {
        data.score_item_config.has_changed = this.checkScoreObjChange(data.score_item_config);
        const members = data.visible_range.visible_members;
        if (Array.isArray(members)) {
          data.visible_range.visible_members = this.handleUserMembersValue(members);
        }
      }
      data.workspace_id = this.workspace_id;
      return data;
    },
    async editScore(id) {
      const data = {
        workspace_id: this.workspaceInfo.id,
        rule_id: id
      };
      try {
        const res = await (0, _conversationScoreRule.startEditScoreRule)(data);
        if (res.code === 20000) {
          this.$router.push({
            path: _routeMap.ROUTES.conversationCreateScoreRule,
            query: {
              id,
              type: 'edit'
            }
          });
        }
      } catch {
        return false;
      }
    },
    copyRule(id) {
      if (this.canCreate || this.scoreList.length < this.maxCount) {
        this.$router.push({
          path: _routeMap.ROUTES.conversationCreateScoreRule,
          query: {
            id,
            type: 'copy'
          }
        });
      } else {
        this.$message.warning('评分规则数量已达上限');
      }
    },
    checkScoreObjChange(editedData) {
      return !(0, _isEqual.default)(editedData, this.initScoreRuleObj);
    },
    handleUserMembersValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    }
  }
};
exports.default = _default;