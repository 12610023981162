"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "474px",
      title: _vm.isSnippet ? "编辑" : "重命名",
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      "custom-class": "move-folder-dialog"
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "footer"
        }, [_c("el-button", {
          staticClass: "mg-button",
          attrs: {
            plain: ""
          },
          on: {
            click: _vm.closeDialog
          }
        }, [_vm._v("取消")]), _c("el-button", {
          staticClass: "mg-button",
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("确定")])], 1)];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c("div", {
          staticClass: "label-name"
        }, [_vm._v(" " + _vm._s(_vm.isSnippet ? "剪辑" : "") + "标题 "), _c("span", {
          staticClass: "dot"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }, [_c("el-input", {
    staticClass: "mg-input",
    attrs: {
      placeholder: "请输入名称",
      type: "textarea",
      "show-word-limit": "",
      maxlength: _vm.isSnippet ? 150 : 20,
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      resize: "none"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm.isSnippet ? _c("el-form-item", {
    attrs: {
      prop: "description"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c("div", {
          staticClass: "label-name"
        }, [_vm._v("描述")])];
      },
      proxy: true
    }], null, false, 2209414178)
  }, [_c("el-input", {
    staticClass: "mg-input1",
    attrs: {
      type: "textarea",
      placeholder: "请输入描述，200字以内",
      maxlength: "200",
      "show-word-limit": "",
      rows: 5,
      resize: "none"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;