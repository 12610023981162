"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ConversationLink = _interopRequireDefault(require("@/views/management/rapidModeling/components/ValidTrainData/components/ConversationLink.vue"));
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TestDetailDataDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 0
    },
    datasetId: {
      type: Number,
      default: 0
    },
    prafTaskId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'updateAnnotationStatus', 'clearAnnotationResult'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const checkList = (0, _vue.ref)([]);
    const modelInfo = (0, _vue.inject)('modelInfo');
    const context = (0, _vue.ref)({
      conversation_name: '',
      confidence: 0,
      sentence: [],
      status: 0
    });
    const currentIndex = (0, _vue.ref)(0);
    const currentId = (0, _vue.computed)(() => {
      var _props$ids;
      return (_props$ids = props.ids) === null || _props$ids === void 0 ? void 0 : _props$ids[currentIndex.value];
    });
    const hasNext = (0, _vue.computed)(() => {
      var _props$ids2;
      return currentIndex.value < ((_props$ids2 = props.ids) === null || _props$ids2 === void 0 ? void 0 : _props$ids2.length) - 1;
    });
    const hasPre = (0, _vue.computed)(() => {
      return currentIndex.value > 0;
    });
    const next = () => {
      currentIndex.value++;
    };
    const pre = () => {
      currentIndex.value--;
    };
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        currentIndex.value = props.ids.findIndex(id => id === props.id);
      }
    }, {
      immediate: true
    });
    const getAnnotatePath = () => {
      const basePath = _routeMap.ROUTES.annotate;
      const {
        dataset_data_id
      } = context.value;
      return `${basePath}?id=${props.datasetId}&datasetDataId=${dataset_data_id}`;
    };
    const clearAnnotationResult = () => {
      emit('clearAnnotationResult', [currentId.value]);
      if (hasNext.value) {
        next();
      }
    };
    const trainDataDetailRef = (0, _vue.ref)(null);
    const sentenceTextRef = (0, _vue.ref)(null);
    const contentLoading = (0, _vue.ref)(false);
    (0, _vue.watchEffect)(() => {
      if (currentId.value && props.visible) {
        contentLoading.value = true;
        checkList.value = [];
        (0, _rapidCreateModel.getTrailAnnotationContext)({
          annotation_id: currentId.value,
          praf_task_id: props.prafTaskId
        }).then(async res => {
          context.value = res.results.data;
          await (0, _vue.nextTick)();
          // 默认选中
          context.value.sentence.map((item, index) => {
            if (item.is_similar_sentence) {
              checkList.value.push(index);
            }
          });
          scrollToSimilarSentence();
        }).finally(() => {
          contentLoading.value = false;
        });
      }
    });
    function scrollToSimilarSentence() {
      const similarSentenceIndex = context.value.sentence.findIndex(item => item.is_similar_sentence);
      if (similarSentenceIndex === -1) return;
      const activeSentence = sentenceTextRef.value[similarSentenceIndex];
      activeSentence.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
    const btnLoading = (0, _vue.ref)(false);
    const updateTrailDataStatus = async status => {
      btnLoading.value = true;
      const params = {
        status,
        praf_task_detail_ids: [currentId.value],
        praf_task_id: props.prafTaskId
      };
      const res = await (0, _rapidCreateModel.batchUpdateTestData)(params).finally(() => {
        btnLoading.value = false;
      });
      if (res.code === 20000) {
        context.value.status = status;
        emit('updateTableListAnnotationStatus', {
          id: currentId.value,
          status
        });
        if (hasNext.value) {
          next();
        }
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      checkList,
      modelInfo,
      context,
      currentIndex,
      currentId,
      hasNext,
      hasPre,
      next,
      pre,
      getAnnotatePath,
      clearAnnotationResult,
      trainDataDetailRef,
      sentenceTextRef,
      contentLoading,
      scrollToSimilarSentence,
      btnLoading,
      updateTrailDataStatus,
      ConversationLink: _ConversationLink.default
    };
  }
};
exports.default = _default;