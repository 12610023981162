import { render, staticRenderFns } from "./CreateRuleModelDrawer.vue?vue&type=template&id=15d8a124&scoped=true&"
import script from "./CreateRuleModelDrawer.vue?vue&type=script&setup=true&lang=js&"
export * from "./CreateRuleModelDrawer.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CreateRuleModelDrawer.vue?vue&type=style&index=0&id=15d8a124&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d8a124",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15d8a124')) {
      api.createRecord('15d8a124', component.options)
    } else {
      api.reload('15d8a124', component.options)
    }
    module.hot.accept("./CreateRuleModelDrawer.vue?vue&type=template&id=15d8a124&scoped=true&", function () {
      api.rerender('15d8a124', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/management/modelManagement/components/CreateRuleModelDrawer.vue"
export default component.exports