"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDeveloper = addDeveloper;
exports.deleteDeveloper = deleteDeveloper;
exports.getAppConfigInfo = getAppConfigInfo;
exports.getAppList = getAppList;
exports.getDeveloperList = getDeveloperList;
exports.getPrivateAppList = getPrivateAppList;
exports.getPublicKey = getPublicKey;
exports.installApp = installApp;
exports.officialTrial = officialTrial;
exports.updateAppConfigInfo = updateAppConfigInfo;
var _request = require("@/utils/request");
// 安装应用
function installApp(params) {
  return (0, _request.request)({
    url: '/profile/app/wework/install',
    method: 'post',
    params
  });
}

// 生成公钥
function getPublicKey(params) {
  return (0, _request.request)({
    url: '/profile/app/wework/session_archive/get_public_key',
    method: 'post',
    params
  });
}

// 获取应用集成列表
function getAppList() {
  return (0, _request.webService)({
    url: '/app_center/app_integration/list',
    method: 'get'
  });
}

// 获取自建应用列表
function getPrivateAppList() {
  return (0, _request.webService)({
    url: '/app_center/app/list',
    method: 'get'
  });
}

// 获取应用配置信息
function getAppConfigInfo(params) {
  return (0, _request.webService)({
    url: '/app_center/app/get',
    method: 'get',
    params
  });
}

// 更新应用信息
function updateAppConfigInfo(data) {
  return (0, _request.request)({
    url: '/app_center/save',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 开发者管理
function getDeveloperList(params) {
  return (0, _request.webService)({
    url: '/app/developer/list',
    method: 'get',
    params
  });
}
function deleteDeveloper(data) {
  return (0, _request.webService)({
    url: '/app/developer/remove',
    method: 'post',
    dataType: 'json',
    data
  });
}
function addDeveloper(data) {
  return (0, _request.webService)({
    url: '/app/developer/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 申请试用
function officialTrial(data) {
  return (0, _request.request)({
    url: '/official/trial',
    method: 'post',
    data
  });
}