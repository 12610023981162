"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timeFormatter = require("@/utils/time-formatter");
var _KeyEvent = _interopRequireDefault(require("./components/KeyEvent.vue"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DailyPreviewCard',
  components: {
    KeyEvent: _KeyEvent.default
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    keyEventConfig: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allowNumber: 0
    };
  },
  computed: {
    // 联系人为手机号展示后四位
    getContactName() {
      return function (name) {
        if (/^(1\d{10})|^\d{3}\*{4}\d{4}$/.test(name)) {
          return name.slice(-4);
        }
        return name;
      };
    },
    // 会话的关键事件中需要外显的关键事件
    convShowKeyEvent() {
      if (!this.detail.events.length) return [];
      const needShowKeyEvent = [];
      this.keyEventConfig.forEach(item => {
        this.detail.events.forEach(subItem => {
          if ((0, _isEqual.default)(subItem.id, item)) {
            needShowKeyEvent.push(subItem);
          }
        });
      });
      return needShowKeyEvent;
    },
    showNumber() {
      return this.convShowKeyEvent.length > this.allowNumber ? this.allowNumber - 1 : this.allowNumber;
    },
    showAllName() {
      return function (name) {
        // 没有需要外显的关键事件&会话的联系人不是电话号码时，展示全部的联系人名称
        return !/^(1\d{10})|^\d{3}\*{4}\d{4}$/.test(name) && !this.convShowKeyEvent.length;
      };
    }
  },
  watch: {
    keyEventConfig: {
      handler: function () {
        this.cardResize();
      }
    },
    detail: {
      handler: function (val) {
        this.cardResize();
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.cardResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.cardResize);
  },
  methods: {
    cardResize() {
      this.$nextTick(() => {
        const dailyCard = this.$refs[`daily-preview-card-${this.detail.conversation_id}`];
        const info = this.$refs[`detail-info-${this.detail.conversation_id}`];
        // 默认展示的按钮个数
        if (dailyCard && info) {
          this.allowNumber = Math.floor((dailyCard.offsetWidth - info.offsetWidth - 12) / 28);
        }
      });
    },
    getDuration(second) {
      return (0, _timeFormatter.secondToENTime)(second);
    },
    getIcon(type) {
      switch (type) {
        case 'audio':
          return 'icon-phone-outline2';
        case 'video':
          return 'icon-video-camera';
        case 'doc':
          return 'icon-im';
        default:
          return '';
      }
    }
  }
};
exports.default = _default;