"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog,
      width: _vm.isBase ? "400px" : "700px",
      "custom-class": "business-dialog",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.title)), _vm.meetingType === "tencent_meeting" ? _c("img", {
    staticClass: "icon-tencent-meeting",
    attrs: {
      src: _vm.tencent_meeting
    }
  }) : _vm._e()]), _c("meeting-form", {
    ref: "meeting-form",
    attrs: {
      form: _vm.form,
      "contact-options": _vm.formContactOptions,
      type: _vm.type,
      "meeting-type": _vm.meetingType,
      "conversation-id": _vm.conversationId
    },
    on: {
      "update:form": function ($event) {
        _vm.form = $event;
      },
      changeSubmitStatus: _vm.changeSubmitStatus,
      change: _vm.changeMembersList
    }
  }), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", {
    staticClass: "business-dialog-footer-wrapper"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.canSubmit,
      expression: "!canSubmit"
    }],
    staticClass: "submit-tips"
  }, [_vm._v(" 主持人无法同时主持多场会议 ")]), _c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "business-submit-btn",
    attrs: {
      type: "primary",
      loading: _vm.submitBtnLoading,
      disabled: !_vm.canSubmit
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(_vm._s(_vm.submitBtnLoading ? "预约中" : "确认预约"))])], 1)])], 1), _c("copy-url", _vm._b({
    attrs: {
      visible: _vm.copyUrlVisible
    },
    on: {
      closeUrlDialog: _vm.closeUrlDialog
    }
  }, "copy-url", _vm.copyUrlProps, false)), _c("invite-succeed-dialog", {
    attrs: {
      "invite-text": _vm.inviteText,
      visible: _vm.inviteSucceedDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.inviteSucceedDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;