"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _workspace = require("@/api/workspace");
var _IconColorSelect = _interopRequireDefault(require("./IconColorSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OperateWorkspaceDialog',
  components: {
    IconColorSelect: _IconColorSelect.default
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    workspaceItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const repeatNameValidator = (rule, value, callback) => {
      if (this.type !== 'edit') {
        if (this.tableData.some(item => item.name.replace(/\s*/g, '') === value.replace(/\s*/g, ''))) {
          callback(new Error('此名称与已有名称重复'));
        }
      } else {
        const tableData = this.tableData.filter(item => item.name !== this.workspaceItem.name);
        if (tableData.some(item => item.name.replace(/\s*/g, '') === value.replace(/\s*/g, ''))) {
          callback(new Error('此名称与已有名称重复'));
        }
      }
      callback();
    };
    return {
      buttonLoading: false,
      operateWorkspaceForm: {
        name: '',
        icon_color: '',
        description: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入空间名称',
          trigger: 'blur'
        }, {
          validator: repeatNameValidator,
          trigger: 'change'
        }],
        icon_color: [{
          required: true,
          message: '请选择图标颜色',
          trigger: 'change'
        }]
      },
      predefineColors: ['#F43F7C', '#33CC8C', '#3393FF', '#FFA100', '#CCB700', '#9A7DE8', '#FF5F33', '#00BBCC'],
      departmentsFinderDialogVisible: false,
      scopeDialogTitle: '',
      currentDepSelectInfo: {}
    };
  },
  computed: {
    getDialogTitle() {
      return `${this.type === 'add' ? '新建' : '编辑'}空间`;
    }
  },
  watch: {
    dialogVisible: {
      handler: function (val) {
        if (!val) return;
        if (JSON.stringify(this.workspaceItem) !== '{}') {
          const {
            name,
            icon_color,
            description
          } = this.workspaceItem;
          this.operateWorkspaceForm = {
            name,
            icon_color,
            description
          };
        }
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    selectColor(val) {
      this.operateWorkspaceForm.icon_color = val;
    },
    submitFormData() {
      this.$refs.operateWorkspaceForm.validate(async valid => {
        if (valid) {
          this.buttonLoading = true;
          const {
            name,
            description,
            icon_color
          } = this.operateWorkspaceForm;
          const data = {
            id: this.type === 'add' ? 0 : this.workspaceItem.id,
            name,
            description,
            icon_color
          };
          const spaceMethod = this.type === 'add' ? _workspace.createWorkspace : _workspace.updateWorkspace;
          spaceMethod(data).then(res => {
            if (res.code === 20000) {
              this.$message({
                message: this.type === 'add' ? '添加成功' : '编辑成功',
                type: 'success'
              });
              this.$emit('updateSuccess');
              sessionStorage.removeItem('workspaceInfo');
              this.$store.dispatch('workspace/getWorkspaceList');
              this.closeDialog();
            }
          }).finally(() => {
            this.buttonLoading = false;
          });
        }
      });
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.reset();
    },
    reset() {
      this.operateWorkspaceForm = {
        name: '',
        icon_color: '',
        description: ''
      };
      this.$refs.operateWorkspaceForm.resetFields();
      this.$nextTick(() => {
        this.$refs.operateWorkspaceForm.clearValidate();
      });
    }
  }
};
exports.default = _default;