import { render, staticRenderFns } from "./ValidTrainDataHeader.vue?vue&type=template&id=228cb6b0&scoped=true&"
import script from "./ValidTrainDataHeader.vue?vue&type=script&setup=true&lang=js&"
export * from "./ValidTrainDataHeader.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ValidTrainDataHeader.vue?vue&type=style&index=0&id=228cb6b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228cb6b0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('228cb6b0')) {
      api.createRecord('228cb6b0', component.options)
    } else {
      api.reload('228cb6b0', component.options)
    }
    module.hot.accept("./ValidTrainDataHeader.vue?vue&type=template&id=228cb6b0&scoped=true&", function () {
      api.rerender('228cb6b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/management/rapidModeling/components/ValidTrainData/components/ValidTrainDataHeader.vue"
export default component.exports