"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$currentModelIn, _setup$currentModelIn2, _setup$currentModelIn3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-management"
  }, [_c("header", {
    staticClass: "model-header"
  }, [_c("h1", {
    staticClass: "model-management__title"
  }, [_vm._v("模型管理")]), _c("div", {
    staticClass: "filter-row"
  }, [_c(_setup.ModelManagementFilters, {
    ref: "filtersRef",
    on: {
      customChange: _setup.onSizeChange
    }
  }), _c(_setup.CreateModelButton, {
    on: {
      itemClick: _setup.onCreateButtonClick
    }
  })], 1)]), _c(_setup.ModelList, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.listLoading,
      expression: "listLoading"
    }],
    ref: "modelListRef",
    attrs: {
      data: _setup.modelList
    },
    on: {
      buttonClick: _setup.modelTableButtonClick
    }
  }), _c(_setup.MgPagination, {
    attrs: {
      "current-page": _setup.currentPage,
      "page-size": _setup.pageSize,
      total: _setup.total
    },
    on: {
      "update:currentPage": function ($event) {
        _setup.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _setup.currentPage = $event;
      },
      "update:pageSize": function ($event) {
        _setup.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _setup.pageSize = $event;
      },
      sizeChange: _setup.onSizeChange,
      currentChange: _setup.getModelListAsync
    }
  }), _setup.detailDrawerVisible ? _c(_setup.ModelDetailDrawer, {
    attrs: {
      title: _setup.currentModelInfo.name,
      "drawer-visible": _setup.detailDrawerVisible
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.detailDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.detailDrawerVisible = $event;
      }
    }
  }) : _vm._e(), _setup.createDrawerVisible ? _c(_setup.CreateAiModelDrawer, {
    attrs: {
      visible: _setup.createDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createDrawerVisible = $event;
      },
      createSuccess: _setup.getModelListAsync
    }
  }) : _vm._e(), _setup.createRuleDrawerVisible ? _c(_setup.CreateRuleModelDrawer, {
    attrs: {
      visible: _setup.createRuleDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createRuleDrawerVisible = $event;
      },
      submitSucceed: _setup.getModelListAsync
    }
  }) : _vm._e(), _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      visible: _setup.publishDrawerVisible,
      title: `模型发布 - ${_setup.currentModelInfo.name}`,
      "wrapper-closable": false,
      size: "600px"
    },
    on: {
      "update:visible": function ($event) {
        _setup.publishDrawerVisible = $event;
      }
    }
  }, [_setup.publishDrawerVisible ? _c(_setup.PublishModelContent, {
    attrs: {
      id: _setup.currentModelInfo.id,
      version: (_setup$currentModelIn = _setup.currentModelInfo.last_version) === null || _setup$currentModelIn === void 0 ? void 0 : _setup$currentModelIn.version,
      "is-ai-model": _setup.currentModelInfo.model_type !== 1
    },
    on: {
      publishSuccess: _setup.getModelListAsync,
      "update:visible": function ($event) {
        _setup.publishDrawerVisible = false;
      }
    }
  }) : _vm._e()], 1), _c(_setup.TrainModelDialog, {
    attrs: {
      visible: _setup.trainingModelDialogVisible,
      "model-id": _setup.currentModelInfo.id,
      "model-name": _setup.currentModelInfo.name,
      version: (_setup$currentModelIn2 = _setup.currentModelInfo.last_version) === null || _setup$currentModelIn2 === void 0 ? void 0 : _setup$currentModelIn2.version,
      "is-auto-trial": _setup.TrainModelIsAutoTrial
    },
    on: {
      "update:visible": function ($event) {
        _setup.trainingModelDialogVisible = $event;
      },
      "update:isAutoTrial": function ($event) {
        _setup.TrainModelIsAutoTrial = $event;
      },
      "update:is-auto-trial": function ($event) {
        _setup.TrainModelIsAutoTrial = $event;
      },
      submitSuccess: _setup.getModelItemAsync
    }
  }), _setup.SwitchVersionDialogVisible ? _c(_setup.SwitchVersionDialog, {
    attrs: {
      visible: _setup.SwitchVersionDialogVisible,
      "model-id": _setup.currentModelInfo.id,
      "model-name": _setup.currentModelInfo.name,
      "is-ai-model": _setup.currentModelInfo.model_type !== 1,
      "cur-version": _setup.currentModelInfo.stable_version
    },
    on: {
      "update:visible": function ($event) {
        _setup.SwitchVersionDialogVisible = $event;
      },
      submitSuccess: _setup.getModelItemAsync
    }
  }) : _vm._e(), _c(_setup.EditRuleModelDrawer, {
    attrs: {
      visible: _setup.editRuleModelDrawerVisible,
      "model-id": _setup.currentModelInfo.id,
      version: (_setup$currentModelIn3 = _setup.currentModelInfo.last_version) === null || _setup$currentModelIn3 === void 0 ? void 0 : _setup$currentModelIn3.version
    },
    on: {
      "update:visible": function ($event) {
        _setup.editRuleModelDrawerVisible = $event;
      },
      submitSucceed: _setup.getModelItemAsync
    }
  }), _setup.combineModelDrawerVisible ? _c(_setup.CombineModelDrawer, {
    ref: "combineModelDrawerRef",
    attrs: {
      visible: _setup.combineModelDrawerVisible,
      "model-info": _setup.editCombineModelInfo
    },
    on: {
      "update:visible": function ($event) {
        _setup.combineModelDrawerVisible = $event;
      },
      createSuccess: _setup.getModelListAsync
    }
  }) : _vm._e(), _setup.trainDetailVisible ? _c(_setup.TrainDetailDrawer, {
    attrs: {
      visible: _setup.trainDetailVisible,
      "current-version": _setup.checkVersionModelInfo.version,
      "model-id": _setup.checkVersionModelInfo.id,
      "model-type": "combine"
    },
    on: {
      "update:visible": function ($event) {
        _setup.trainDetailVisible = $event;
      }
    }
  }) : _vm._e(), _c(_setup.MegaTranGuide)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;