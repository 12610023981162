"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bi-kanban"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLeftArrowShow,
      expression: "isLeftArrowShow"
    }],
    staticClass: "arrow-button arrow-left",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(false);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("MgDraggable", {
    ref: "kanbanWrap",
    staticClass: "kanban-wrap",
    attrs: {
      disabled: !_vm.isDraggable
    },
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.localKanbanList,
      callback: function ($$v) {
        _vm.localKanbanList = $$v;
      },
      expression: "localKanbanList"
    }
  }, [_vm._l(_vm.localKanbanList, function (item) {
    return [_c("div", {
      key: item.kanban_id,
      ref: `kanban-item-${item.kanban_id}`,
      refInFor: true,
      class: ["kanban-item move", {
        active: _vm.activeId === item.kanban_id
      }, {
        "kanban-type": item.kanban_remark
      }],
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c("div", [_c("div", {
      staticClass: "kanban-name"
    }, [_vm._v(_vm._s(item.name))]), item.kanban_remark && _vm.showKanbanRemark ? _c("div", {
      staticClass: "kanban-subtitle"
    }, [_vm._v(" " + _vm._s(item.kanban_remark) + " ")]) : _vm._e(), item.is_sharing && _vm.showKanbanRemark ? _c("div", {
      staticClass: "kanban-subtitle"
    }, [_vm._v(" 已开启分享 ")]) : _vm._e()]), _vm.hasPermisstion && _vm.filterCommandList(item.hiddenHandle).length ? _c("el-dropdown", {
      staticClass: "kanban-more",
      attrs: {
        trigger: "click",
        placement: "bottom-start"
      },
      on: {
        command: command => _vm.handleCommand(command, item)
      }
    }, [_c("el-button", {
      staticClass: "kanban-more-btn",
      attrs: {
        icon: "iconfont icon-more-v"
      }
    }), _c("el-dropdown-menu", {
      staticClass: "dropdown",
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, _vm._l(_vm.filterCommandList(item.hiddenHandle), function (_ref) {
      let {
        type,
        label,
        icon
      } = _ref;
      return _c("el-dropdown-item", {
        key: type,
        class: {
          "delete-button": type === "delete"
        },
        attrs: {
          command: type
        }
      }, [_c("i", {
        class: ["iconfont", icon]
      }), _vm._v(" " + _vm._s(label) + " ")]);
    }), 1)], 1) : _vm._e()], 1)];
  })], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRightArrowShow,
      expression: "isRightArrowShow"
    }],
    staticClass: "arrow-button arrow-right",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(true);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-right"
  })]), _vm.isCreateKanbanVisible && _vm.hasPermisstion ? _c("el-button", {
    staticClass: "create-kanban",
    on: {
      click: _vm.createKanban
    }
  }, [_c("el-tooltip", {
    attrs: {
      content: "新建看板",
      placement: "bottom"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;