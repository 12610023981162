"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _vue = require("vue");
var _usePromptDataStruct = require("./hooks/use-prompt-data-struct");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _useVuex = require("@/hooks/use-vuex");
var _constantPrompt = _interopRequireDefault(require("./hooks/constant-prompt"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateAiCustomerDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const store = (0, _useVuex.useStore)();
    const modulePermission = (0, _vue.computed)(() => store.getters.modulePermission);
    const updateVisible = visible => emit('update:visible', visible);
    (0, _vue.watch)(() => props.visible, () => {
      var _formRef$value;
      promptStruct.value.name = '';
      formData.name = '';
      formData.type = 'qa';
      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.clearValidate();
    });
    const {
      formatPromptText,
      setPromptTextBlocks
    } = (0, _usePromptDataStruct.usePromptTextBlocks)();
    const {
      formatScripts,
      setScripts
    } = (0, _usePromptDataStruct.useKeyScripts)();
    const {
      promptStruct,
      createAiCustomerFn
    } = (0, _usePromptDataStruct.usePromptDataStruct)({
      scripts: formatScripts,
      prompt: formatPromptText
    });
    const {
      DEFAULT_PROMPT,
      DEFAULT_SCRIPTS
    } = _constantPrompt.default;
    (0, _vue.onMounted)(() => {
      setPromptTextBlocks(DEFAULT_PROMPT, DEFAULT_SCRIPTS);
      setScripts(DEFAULT_SCRIPTS);
    });
    const formRef = (0, _vue.ref)(null);
    const formData = (0, _vue.reactive)({
      name: '',
      type: 'qa'
    });
    const rules = {
      name: [{
        required: true,
        message: '请输入课程名称',
        trigger: 'change'
      }, {
        max: 50,
        message: '课程名称最多50个字符',
        trigger: 'change'
      }],
      type: [{
        required: true,
        message: '请选择课程类型',
        trigger: 'change'
      }]
    };
    const createBtnLoading = (0, _vue.ref)(false);
    const createAiCustomer = async () => {
      const valid = await formRef.value.validate();
      if (!valid) return;
      if (formData.type === 'qa') {
        router.push({
          path: _routeMap.ROUTES.aiSopCoachCourse,
          query: {
            type: 6,
            categoryId: props.categoryId,
            name: formData.name
          }
        });
        return;
      }
      promptStruct.value.name = formData.name;
      createBtnLoading.value = true;
      const res = await createAiCustomerFn({
        category_id: props.categoryId
      }).finally(() => {
        createBtnLoading.value = false;
      });
      if (res.results.course_id) {
        router.push({
          path: _routeMap.ROUTES.aiCustomerGenerator,
          query: {
            id: res.results.course_id,
            isCreate: true
          }
        });
        _elementUi.Message.success('创建成功');
      }
    };
    return {
      __sfc: true,
      router,
      props,
      store,
      modulePermission,
      emit,
      updateVisible,
      formatPromptText,
      setPromptTextBlocks,
      formatScripts,
      setScripts,
      promptStruct,
      createAiCustomerFn,
      DEFAULT_PROMPT,
      DEFAULT_SCRIPTS,
      formRef,
      formData,
      rules,
      createBtnLoading,
      createAiCustomer
    };
  }
};
exports.default = _default;