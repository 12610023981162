"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'CourseCard',
  components: {},
  props: {
    courseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      courseCoverList: [require('@/assets/coach/read-aloud.jpg'), require('@/assets/coach/recite.jpg'), require('@/assets/coach/smart-training.jpg'), require('@/assets/coach/ai-smart-training.jpg'), require('@/assets/coach/ai-smart-training.jpg'), require('@/assets/coach/ai-smart-training.jpg'), require('@/assets/coach/ai-smart-training.jpg')]
    };
  },
  computed: {
    courseType() {
      return ['placeholder',
      // 只是用来占位，不会展示
      this.$t('coaching.scriptReading'), this.$t('coaching.scriptRecitation'), this.$t('coaching.scriptPractice'), this.$t('coaching.aICustomerHasVar', ['(Alpha)']), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer')][this.courseInfo.type];
    },
    courseClass() {
      return ['course-type', 'recite-course', 'smart-training', 'ai-smart-training', 'ai-smart-training', 'ai-smart-training', 'ai-smart-training'][this.courseInfo.type - 1];
    },
    tooltipContent() {
      const contentMap = {
        1: this.$t('coaching.scoringPointsAreBeingGenerated'),
        2: this.$t('coaching.unconfiguredScoringRules')
      };
      return contentMap[this.courseInfo.gist_status];
    }
  },
  methods: {
    editCourse() {
      let course = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.courseInfo;
      // 跳转编辑课程页面
      let path;
      switch (course.type) {
        case 3:
          path = _routeMap.ROUTES.smartTrainingEdit;
          break;
        case 4:
          path = _routeMap.ROUTES.aiCoachCourseEdit;
          break;
        case 5:
          path = _routeMap.ROUTES.aiSopCoachCourseEdit;
          break;
        case 6:
          path = _routeMap.ROUTES.aiSopCoachCourse;
          break;
        case 7:
          path = _routeMap.ROUTES.aiCustomerGenerator;
          break;
        default:
          path = _routeMap.ROUTES.coachCourseEdit;
          break;
      }
      this.$router.push({
        path,
        query: {
          id: course.id,
          type: course.type
        }
      });
    },
    async copyCourse() {
      const res = await (0, _coachingCenter.copyCourse)({
        id: this.courseInfo.id
      });
      if (res.code === 20000) {
        const {
          course_id: id,
          course_type: type
        } = res.results;
        this.editCourse({
          id,
          type
        });
      }
    },
    handleCommand(type) {
      switch (type) {
        case 'edit':
          this.editCourse();
          break;
        case 'copy':
          this.copyCourse();
          break;
        case 'move':
          this.$emit('moveCourse', this.courseInfo);
          break;
        case 'taskRecord':
          this.$emit('openTaskRecordDrawer', this.courseInfo);
          break;
        case 'delete':
          this.deleteCourse();
          break;
        default:
          break;
      }
    },
    deleteCourse() {
      this.$confirm(this.$t('coaching.tipsForDeletingCourses'), this.$t('coaching.deleteCourse'), {
        confirmButtonText: this.$t('coaching.delete'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('coaching.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await (0, _coachingCenter.deleteCourse)({
          id: this.courseInfo.id
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('coaching.deletedSuccessfully'));
          this.$emit('refresh');
        }
      }).catch(() => {});
    },
    createTask() {
      this.$emit('createTask', this.courseInfo);
    }
  }
};
exports.default = _default;