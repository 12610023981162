// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/AIHelper/chat_intro_bg.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-26f51a92]:export {\n  menuText: #6c7e93;\n  menuActiveText: #4461ec;\n  subMenuActiveText: #666f8d;\n  menuBg: #e8eaee;\n  menuHover: rgba(68, 97, 236, 0.1);\n  subMenuBg: #fff;\n  subMenuHover: #eee;\n  sideBarWidth: 60px;\n  headerHeight: 40px;\n  titleHeight: 67px;\n  pageMainText: #37446f;\n  pageCommonText: #666f8d;\n  pageActiveText: #4461ec;\n  title: 18px;\n  subtitle: 16px;\n  body: 14px;\n  describe: 12px;\n  active: #4461ec;\n  inactive: #e2e5e9;\n  graphBg: rgba(68, 97, 236, 0.1019607843);\n  graphEdge: #c4cbd4;\n}\n.not-support[data-v-26f51a92] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 58px;\n  height: calc(100% - 48px);\n  animation: fade-in-26f51a92 0.3s ease-in;\n  background-position: center;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.not-support img[data-v-26f51a92] {\n  display: block;\n  margin: 0 auto;\n  width: 120px;\n}\n.not-support h3[data-v-26f51a92] {\n  margin: 16px 0;\n  font-weight: 600;\n  line-height: 20px;\n  text-align: center;\n  color: #2b323b;\n}\n.not-support p[data-v-26f51a92] {\n  line-height: 20px;\n  color: #576575;\n}\n@keyframes fade-in-26f51a92 {\n0% {\n    opacity: 0;\n}\n100% {\n    opacity: 1;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
