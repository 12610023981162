"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CommonRenameDialog',
  props: {
    visible: Boolean,
    inputInfo: {
      type: Object,
      default: () => ({
        title: '重命名',
        label: '名称',
        errorText: '请输入名称',
        placeholder: '请输入名称'
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    inputObject() {
      const obj = {};
      const copyForm = JSON.parse(JSON.stringify(this.form));
      for (const key in copyForm) {
        obj[key] = this.form[key];
      }
      return obj;
    }
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.rules.name[0].message = this.inputInfo.errorText;
          this.form.name = this.inputInfo.name;
          this.$nextTick(() => {
            this.$refs.form.clearValidate();
            this.$refs['nameInput'].select();
          });
        }
      }
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$emit('submitForm', this.form);
        }
      });
    },
    closeDialog() {
      this.$refs.form && this.$refs.form.resetFields();
      this.form.name = '';
      this.$emit('closeDialog');
    }
  }
};
exports.default = _default;