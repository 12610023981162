"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _listCardItem = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/listCardItem"));
var _iframe = require("@/api/iframe");
var _eventKeyDrawer = _interopRequireDefault(require("@/views/conversationPage/eventKeyDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'IframeConversationList',
  components: {
    CardItem: _listCardItem.default,
    EventKeyDrawer: _eventKeyDrawer.default
  },
  data() {
    return {
      dealCode: '',
      list: [],
      listLoading: false,
      resultsTotal: 0,
      pageParams: {
        current_page: 1,
        size: 10
      },
      isNone: '',
      // defaut表示还没请求, true表示请求完没数据, false有数据
      hasNoPermission: false,
      // 不同团队不能查看商机下会话
      eventDrawerProps: {
        convId: 0
      }
    };
  },
  computed: {},
  watch: {
    pageParams: {
      handler: function () {
        this.getList();
      },
      deep: true
    },
    dealCode() {
      this.getList();
    }
  },
  created() {
    this.dealCode = this.$route.query.deal_code;
  },
  mounted() {},
  methods: {
    openEventKeyDrawer(params) {
      const nameMap = {};
      params.participants.forEach(item => {
        nameMap[item.entity_id] = item.name;
      });
      this.eventDrawerProps = {
        eventKeyDrawerVisible: true,
        convId: params.id,
        mediaType: params.conversationType,
        nameMap,
        isKeyEventDetail: params.isKeyEventDetail,
        eventId: params.eventId,
        noEventClick: true
      };
    },
    async getList() {
      this.listLoading = true;
      const codeObj = {
        code: this.dealCode
      };
      const data = Object.assign(this.pageParams, codeObj);
      const res = await (0, _iframe.getIframeCoversationList)(data);
      if (res.code === 200 || res.code === 20000) {
        const results = res.results;
        this.list = results.items;
        this.handleList(this.list);
        this.resultsTotal = results.total_count;
        if (!this.list.length) {
          this.isNone = 'true';
        } else {
          this.isNone = 'false';
        }
      } else {
        this.hasNoPermission = true;
        this.isNone = 'true';
      }
      this.listLoading = false;
    },
    handleList(list) {
      for (let i = 0; i < list.length; i++) {
        const arr = [];
        list[i].participants.map(item => {
          if (item.entity_type === 'host_salesman' || item.entity_type === '') {
            list[i].host = item;
          } else if (item.entity_type === 'assistant_salesman' || item.entity_type === 'spectator_salesman') {
            arr.push(item);
            list[i].others = arr;
          }
        });
      }
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.pageParams.current_page = 1;
    },
    handleCurrentChange(currentPage) {
      if (this.pageParams.size * currentPage > 10000) {
        this.pageParams.current_page = 10000 / this.pageParams.size;
      } else {
        this.pageParams.current_page = currentPage;
      }
    },
    goGuideDocs() {
      window.open(`https://www.megaview.com/help-center/doc?docx=Ik3odwYwEo2NmpxVQQIcZcLvnbh&anchor=OEKIdAaKWoGUW2xshlvcXRCVnTd`);
    },
    goConversationPage() {
      window.open(`${window.location.origin}/conversation`);
    }
  }
};
exports.default = _default;