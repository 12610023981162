"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dropdown", {
    staticClass: "create-model-button",
    attrs: {
      trigger: "click"
    }
  }, [_c("el-button", {
    staticClass: "create-button",
    attrs: {
      type: "primary",
      "data-v-name": "create-model-btn"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus"
  }), _vm._v("模型 ")]), _c("el-dropdown-menu", {
    staticClass: "create-model-button-dropdown-menu",
    attrs: {
      slot: "dropdown",
      "hide-on-click": ""
    },
    slot: "dropdown"
  }, _vm._l(_setup.models, function (item) {
    return _c("el-dropdown-item", {
      key: item.type
    }, [_c("div", {
      staticClass: "create-model-dropdown"
    }, [_c("figure", {
      class: ["model-type-item", {
        "is-ai": item.type === "AI"
      }, {
        "is-combine": item.type === "combine"
      }],
      on: {
        click: function ($event) {
          return _setup.onClick(item.type);
        }
      }
    }, [_c("div", {
      staticClass: "model-icon"
    }, [_c("i", {
      staticClass: "iconfont icon-box-square"
    })]), _c("figcaption", {
      staticClass: "model-info"
    }, [_c("h2", {
      staticClass: "model-title"
    }, [_vm._v(_vm._s(item.title))]), _c("p", {
      staticClass: "describe"
    }, [_vm._v(_vm._s(item.describe))]), _c("p", {
      staticClass: "tip"
    }, [_vm._v(_vm._s(item.tip))])])])])]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;