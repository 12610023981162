"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer test-records-detail-drawer",
    attrs: {
      visible: _vm.visible,
      title: "测试详情",
      size: "90vw",
      "before-close": _setup.updateVisible,
      "append-to-body": "",
      border: "",
      modal: false
    }
  }, [_c("div", {
    staticClass: "drawer-title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h3", [_vm._v("测试详情")]), _c("div", {
    staticClass: "dataset-name-container",
    on: {
      click: function ($event) {
        return _setup.goDataSet(_setup.testTaskInfo.dataset_id);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-dataset-fill"
  }), _c("span", {
    staticClass: "dataset-name"
  }, [_vm._v(_vm._s(_setup.testTaskInfo.dataset))])]), _c("div", {
    staticClass: "label-tag"
  }, [_c("span", {
    staticClass: "label-name"
  }, [_vm._v(_vm._s(_setup.testTaskInfo.dataset_label))])]), _c("div", {
    staticClass: "results-display"
  }, [_c("span", {
    staticClass: "res-label"
  }, [_vm._v("精：")]), _c("span", {
    staticClass: "res-value"
  }, [_vm._v(_vm._s(_setup.handlePercentage(_setup.testTaskInfo.precision)))]), _c("span", {
    staticClass: "res-label"
  }, [_vm._v("召：")]), _c("span", {
    staticClass: "res-value"
  }, [_vm._v(_vm._s(_setup.handlePercentage(_setup.testTaskInfo.recall)))]), _c("span", {
    staticClass: "res-label"
  }, [_vm._v("F1：")]), _c("span", {
    staticClass: "res-value"
  }, [_vm._v(_vm._s(_setup.handlePercentage(_setup.testTaskInfo.f1)))])])]), _c("MgvInfoAlert", {
    attrs: {
      "alert-key": "test-detail-drawer"
    }
  }, [_vm._v("模型预测错的文本句，如果是测试集标注有问题，可以直接点击单元格修正，如果是训练集标注有问题，可以“修正训练集”")]), _c(_setup.TestDetailFilters, {
    attrs: {
      "test-task-info": _setup.testTaskInfo
    },
    on: {
      filterChange: _setup.filterChange,
      reTraining: _setup.onReTraining
    }
  }), _c(_setup.EventResultTable, {
    attrs: {
      "has-operate": false,
      loading: _setup.tableLoading,
      "table-data": _setup.tableData,
      "header-target-counts": _setup.headerTargetCounts,
      "dataset-id": _setup.testTaskInfo.dataset_id,
      "dataset-name": _setup.testTaskInfo.dataset_label,
      "table-height": "calc(100vh - 56px - 40px - 36px - 16px -32px)"
    },
    on: {
      changeTestAnnotation: _setup.handleChangeTestAnnotation,
      fixTrainData: _setup.openFixTrainDatasetDialog,
      clickModelSentence: _setup.openTestDataDetailDialog
    }
  }), _c("el-pagination", {
    attrs: {
      "current-page": _setup.listParams.page,
      "page-sizes": [20, 50, 100, 500],
      "page-size": _setup.listParams.size,
      layout: "total, prev, pager, next, sizes ",
      total: _setup.total
    },
    on: {
      "size-change": _setup.handleSizeChange,
      "current-change": _setup.handleCurrentChange
    }
  }), _c(_setup.FixTrainDatasetDialog, {
    attrs: {
      visible: _setup.fixTrainDatasetDialogVisible,
      "results-row-info": _setup.currentResultsRowInfo
    },
    on: {
      "update:visible": function ($event) {
        _setup.fixTrainDatasetDialogVisible = $event;
      }
    }
  }), _c(_setup.TestDetailDataDialog, {
    attrs: {
      id: _setup.currentTestDataDetailId,
      visible: _setup.testDetailDataDialogVisible,
      ids: _setup.testDetailDataIds,
      "praf-task-id": _vm.prafTaskId,
      "dataset-id": _setup.testTaskInfo.dataset_id
    },
    on: {
      "update:visible": function ($event) {
        _setup.testDetailDataDialogVisible = $event;
      },
      updateTableListAnnotationStatus: _setup.getTestDetailList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;