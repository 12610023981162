"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCombineModelBasicInfoFrom = useCombineModelBasicInfoFrom;
exports.useCombineModelConfig = useCombineModelConfig;
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
function useCombineModelBasicInfoFrom() {
  const basicInfoFormRef = (0, _vue.ref)(null);
  const basicModelInfo = (0, _vue.ref)({});
  const basicModelInfoDesc = [{
    id: 'name',
    label: '模型名称',
    required: true,
    type: 'text',
    maxlength: 20
  }, {
    id: 'description',
    label: '模型描述',
    type: 'textarea',
    maxlength: 500
  }];
  const basicModelFormValidate = async () => {
    return await basicInfoFormRef.value.validate();
  };
  return {
    basicInfoFormRef,
    basicModelInfo,
    basicModelInfoDesc,
    basicModelFormValidate
  };
}
function useCombineModelConfig() {
  const combineConfig = (0, _vue.ref)({
    type: 'or',
    configs: [{
      type: 'and',
      configs: [{
        type: 'and',
        configs: [{
          model_id: '',
          model_version: '',
          // 模型版本
          action: 1,
          // 动作，1为命中，2为不命中
          span: 0,
          // "前、后、任意、句子数量"
          span_type: 0 // 1:任意 2:当前句 3:前 4:后
        }]
      }]
    }]
  });

  const addRuleGroup = () => {
    combineConfig.value.configs.push({
      type: 'and',
      configs: [{
        type: 'and',
        configs: [{
          model_id: '',
          model_version: '',
          // 模型版本
          action: 1,
          // 动作，1为命中，2为不命中
          span: 0,
          // "前、后、任意、句子数量"
          span_type: 0 // 1:任意 2:当前句 3:前 4:后
        }]
      }]
    });
  };

  function extractModelIds(config) {
    let modelArr = [];
    if (!config.type) {
      modelArr.push({
        id: config.model_id,
        version: config.model_version
      });
    }
    if (config.configs && Array.isArray(config.configs)) {
      for (const nestedConfig of config.configs) {
        modelArr = modelArr.concat(extractModelIds(nestedConfig));
      }
    }
    return modelArr;
  }
  const hasLeastTwoAtomModel = (0, _vue.computed)(() => {
    // 检验 combineConfig 所有的 modelId 是否超过两个
    const modelArr = extractModelIds(combineConfig.value);
    // 至少选择两个原子模型
    const modelIdAndVersionArr = modelArr.filter(item => item.id && item.version);
    return modelIdAndVersionArr.length < 2;
  });
  const configsValidate = () => {
    // 检验 combineConfig 所有的 modelId 是否超过两个
    const modelArr = extractModelIds(combineConfig.value);
    // 没有选择模型
    const modelNotIdArr = modelArr.filter(item => !item.id);
    if (modelNotIdArr.length) {
      _useMessage.useMessage.warning(`有${modelNotIdArr.length}个没有选择模型`);
      return false;
    }
    // 没有选择版本
    const modelIdNotVersionArr = modelArr.filter(item => item.id && !item.version);
    if (modelIdNotVersionArr.length) {
      _useMessage.useMessage.warning(`有${modelIdNotVersionArr.length}个模型没有选择版本`);
      return false;
    }
    // 至少选择两个原子模型
    const modelIdAndVersionArr = modelArr.filter(item => item.id && item.version);
    if (modelIdAndVersionArr.length < 2) {
      _useMessage.useMessage.warning(`至少选择两个原子模型`);
      return false;
    }
    return true;
  };
  return {
    combineConfig,
    addRuleGroup,
    configsValidate,
    extractModelIds,
    hasLeastTwoAtomModel
  };
}