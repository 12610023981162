"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CustomerReachTable',
  props: {
    tableHeads: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currPage: 1,
      pageSize: 20,
      total: 0
    };
  },
  computed: {
    eachPageTableData() {
      const start = (this.currPage - 1) * this.pageSize;
      const end = this.currPage * this.pageSize;
      return this.tableData.slice(start, end);
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currPage = 1;
    },
    handleCurrentChange(val) {
      this.currPage = val;
    },
    handleSortChange(_ref) {
      let {
        order,
        prop
      } = _ref;
      if (!order) return;
      this.columnSort(prop, order === 'ascending' ? 1 : -1);
    },
    columnSort(index, orderType) {
      // eslint-disable-next-line vue/no-mutating-props
      this.tableData.sort((a, b) => orderType * (a[index].toString().replace('%', '') - b[index].toString().replace('%', '')));
    }
  }
};
exports.default = _default;