"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'CustomFieldFilter',
  props: {
    currentCustomFieldType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      customFieldFilter: {
        name: ''
      }
    };
  },
  watch: {
    currentCustomFieldType: {
      handler: function (type) {
        this.customFieldFilter = this.$options.data().customFieldFilter;
      }
    }
  },
  methods: {
    handleFieldNameChange: (0, _commonFunc.debounce)(function () {
      this.$emit('filterChange', this.customFieldFilter);
    })
  }
};
exports.default = _default;