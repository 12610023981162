"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'Audioline',
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    maxSilenceDuration: {
      type: Object,
      default: () => ({})
    },
    arrays: {
      type: Array,
      default: function () {
        return [{
          title: '自我介绍',
          color: 'red',
          detail: [{
            begin_percent: 2,
            end_percent: 7
          }, {
            begin_percent: 12,
            end_percent: 40
          }]
        }, {
          title: '未来规划',
          color: 'blue',
          detail: [{
            begin_percent: 4,
            end_percent: 9
          }, {
            begin_percent: 15,
            end_percent: 60
          }]
        }, {
          title: '优缺点',
          color: 'green',
          detail: [{
            begin_percent: 6,
            end_percent: 12
          }, {
            begin_percent: 30,
            end_percent: 50
          }]
        }];
      }
    },
    radius: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#4461EC'
    },
    isSingle: {
      // 是否是单色
      type: Boolean,
      default: false
    },
    isBase: {
      // 是否是底部全部显示条
      type: Boolean,
      default: false
    },
    pointObj: {
      // 关键词标记的对象
      type: Object,
      default: function () {
        return {};
      }
    },
    isMarker: {
      // 是否是关键词的标记
      type: Boolean,
      default: false
    },
    isShare: {
      // 屏幕共享标记
      type: Boolean,
      default: false
    },
    isQa: {
      // 问答的标记
      type: Boolean,
      default: false
    },
    isTopic: {
      // 标题
      type: Boolean,
      default: false
    },
    qaArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    candColor: {
      type: String,
      default: '#7186EF'
    },
    interColor: {
      type: String,
      default: '#EE779F'
    },
    topicName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curconvid', 'audio']),
    getWidth() {
      return function (item) {
        return (item.end_percent - item.begin_percent).toFixed(2);
      };
    },
    getWidthTopic() {
      return function (item) {
        return (item.end_percent - item.begin_percent).toFixed(2);
      };
    },
    greyColor() {
      return function (items) {
        if (this.topicName) {
          return this.topicName !== items.topic_name;
        }
      };
    }
  },
  methods: {
    clickPoint(item) {
      this.$store.dispatch('video/get_cur_order', {
        current_time: item.begin_time,
        time: new Date().getTime()
      });
      this.$store.dispatch('video/change_begin', {
        begin_time: item.begin_time,
        time: new Date().getTime()
      });
    },
    getShareScreenColor(item) {
      if (item.entity_type === 'candidate') {
        return this.candColor;
      } else {
        return this.interColor;
      }
    }
  }
};
exports.default = _default;