"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'DealFilterCheckbox',
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String, Boolean]
    }
  },
  data() {
    return {
      checked: false
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.checked = val;
      },
      immediate: true
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('change', {
        [this.item.field_name]: val
      });
    }
  }
};
exports.default = _default;