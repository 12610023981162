"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'FiltersGroup',
  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      }
    },
    courseTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filtesParams: {
        // view_type: 'list',
        type: '',
        tree_ids: [],
        user_ids: [],
        date: []
      },
      datePickerOptions: {},
      memberBackfillList: []
    };
  },
  created() {
    const self = this;
    this.datePickerOptions = {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: [{
        text: this.$t('coaching.lastWeek'),
        onClick(picker) {
          self.setDatePickerOptions(picker, 7);
        }
      }, {
        text: this.$t('coaching.lastMonth'),
        onClick(picker) {
          self.setDatePickerOptions(picker, 30);
        }
      }, {
        text: this.$t('coaching.lastThreeMonths'),
        onClick(picker) {
          self.setDatePickerOptions(picker, 90);
        }
      }]
    };
    // 缓存回填
    this.backfillFilters(JSON.parse(sessionStorage.getItem('coachingCourseListParamsMemberList') || '[]'), JSON.parse(sessionStorage.getItem('coachingCourseListParams') || '{}'));
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    setDatePickerOptions(picker, count) {
      const start = this.$moment().subtract(count, 'days').format('YYYY-MM-DD');
      const end = this.$moment().format('YYYY-MM-DD');
      picker.$emit('pick', [start, end]);
    },
    toggleViewType(val) {
      localStorage.setItem('courseListViewType', val);
      this.filterChange({
        type: val
      });
    },
    memberChange(val, memberList) {
      this.filtesParams = {
        ...this.filtesParams,
        ...val
      };
      sessionStorage.setItem('coachingCourseListParamsMemberList', JSON.stringify(memberList));
      this.filterChange();
    },
    async filterChange() {
      this.$emit('filterChange', this.filtesParams);
    },
    getViewTypesConfig() {
      return [{
        type: 'list',
        tip: '列表视图',
        icon: 'icon-view-card'
      }, {
        type: 'chart',
        tip: '图表视图',
        icon: 'icon-pie-chart'
      }];
    },
    backfillFilters() {
      let memberBackfillList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let coachingCourseListParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.$options.data().filtesParams;
      let isTriggerRequest = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      this.memberBackfillList = memberBackfillList;
      for (const key in this.filtesParams) {
        if (typeof coachingCourseListParams[key] !== 'undefined') {
          this.filtesParams[key] = coachingCourseListParams[key];
        }
      }
      isTriggerRequest && this.filterChange();
    }
  }
};
exports.default = _default;