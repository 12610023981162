"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'BatchOperate',
  props: {
    batchEventIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['batchSetKeyEventApplyPermission'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const batchSetKeyEventApplyPermission = () => {
      emit('batchSetKeyEventApplyPermission');
    };
    return {
      __sfc: true,
      props,
      emit,
      batchSetKeyEventApplyPermission
    };
  }
};
exports.default = _default;