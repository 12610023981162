"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "verification-code-input",
    class: _vm.isButtonOutside ? "set-input-width" : ""
  }, [_c("el-input", {
    ref: "code",
    attrs: {
      placeholder: _vm.$t("management.请输入验证码"),
      type: "text",
      tabindex: "2",
      minlength: "4",
      maxlength: _vm.maxLength,
      value: _vm.value
    },
    on: {
      input: _vm.handleInput
    }
  }, [!_vm.isButtonOutside ? _c("el-button", {
    style: {
      color: _vm.textColor
    },
    attrs: {
      slot: "suffix",
      id: "tencentCaptchaCode",
      type: "text",
      disabled: _vm.isSendIng && _vm.count !== 0
    },
    on: {
      click: _vm.sendCode
    },
    slot: "suffix"
  }, [_vm._v(_vm._s(_vm.codeText))]) : _vm._e()], 1), _vm.isButtonOutside ? _c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick"
    }],
    style: {
      color: _vm.textColor,
      borderColor: _vm.textColor
    },
    attrs: {
      id: "tencentCaptchaRegisterCode",
      type: "text",
      disabled: _vm.isSendIng && _vm.count !== 0
    },
    on: {
      click: _vm.sendCode
    }
  }, [_vm._v(_vm._s(_vm.codeText))]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;