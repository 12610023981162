"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "bi-team-business-table"
  }, [_vm.tableData.length ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "highlight-current-row": "",
      "row-key": "id",
      fit: "",
      lazy: "",
      "max-height": "600",
      load: _vm.loadChilden,
      "tree-props": {
        children: "children",
        hasChildren: "hasChildren"
      }
    }
  }, [_vm._l(_vm.tableHeads, function (item, index) {
    return [_c("el-table-column", {
      key: index,
      attrs: {
        label: item.label,
        prop: item.field_name,
        fixed: !index,
        "min-width": "130px"
      },
      scopedSlots: _vm._u([item.field_name === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "team-top-name",
            class: {
              "team-top1-name": row.name == "top1",
              "team-preset-name": row.name == "预设",
              "ordered-path-name": row.percent
            }
          }, [_vm._v(" " + _vm._s(row.name) + " ")])];
        }
      } : item.field_name === "path" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [row.path[index - 1] ? _c("div", {
            staticClass: "table-text"
          }, [_c("el-popover", {
            attrs: {
              placement: "bottom",
              trigger: "hover",
              "append-to-body": false,
              "popper-class": "real-path-key-event-popover",
              disabled: !row.percent
            }
          }, [_c("div", {
            directives: [{
              name: "loading",
              rawName: "v-loading",
              value: _vm.popoverLoading,
              expression: "popoverLoading"
            }],
            staticClass: "key-event-container"
          }, [_vm.popoverContent.length ? _c("div", _vm._l(_vm.popoverContent, function (event, eventIndex) {
            return _c("div", {
              key: eventIndex,
              staticClass: "key-event"
            }, [_c("el-tooltip", {
              attrs: {
                effect: "dark",
                content: event.name,
                placement: "top",
                disabled: event.name.length < 5
              }
            }, [_c("div", {
              staticClass: "key-event-name"
            }, [_vm._v(_vm._s(event.name))])]), _c("div", [_vm._v(_vm._s(_vm.getKeyEventProportion(event.percent)))])], 1);
          }), 0) : _c("div", {
            staticClass: "key-event"
          }, [_vm._v("该节点无子节点数据")])]), _c("el-tooltip", {
            attrs: {
              slot: "reference",
              effect: "dark",
              content: row.path[index - 1].name,
              placement: "top",
              disabled: row.path[index - 1].name.length < 6
            },
            slot: "reference"
          }, [_c("div", {
            staticClass: "event-name",
            class: {
              "event-cell": row.percent
            },
            style: {
              background: _vm.getEventNameBackground(row.path[index - 1].name)
            },
            on: {
              mouseenter: function ($event) {
                return _vm.handleMouseenter(row, index - 1);
              }
            }
          }, [_vm._v(" " + _vm._s(row.path[index - 1].name) + " ")])])], 1), !row.percent ? _c("div", {
            staticClass: "event-proportion"
          }, [_vm._v(" " + _vm._s(_vm.getKeyEventProportion(row.path[index - 1].value)) + " ")]) : _vm._e()], 1) : _vm._e()];
        }
      } : item.field_name === "percent" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row.percent ? _c("div", {
            staticClass: "path-percent"
          }, [_c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: "路径占比",
              placement: "top"
            }
          }, [_c("div", [_vm._v(_vm._s(_vm.getKeyEventProportion(row.percent)))])]), _c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: "所在商机",
              placement: "top"
            }
          }, [_c("el-button", {
            staticClass: "business-btn",
            attrs: {
              icon: "iconfont icon-deal4"
            },
            on: {
              click: function ($event) {
                return _vm.openDealDrawer(row.rel_node_path);
              }
            }
          })], 1)], 1) : _vm._e()];
        }
      } : null], null, true)
    })];
  })], 2) : _vm._e(), _c("dealList-drawer", {
    attrs: {
      "node-path-params": _vm.nodePathParams,
      "real-node-path": _vm.realNodePath,
      "deal-drawer-visible": _vm.dealDrawerVisible
    },
    on: {
      closeDealDrawer: _vm.closeDealDrawer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;