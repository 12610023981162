"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  webSocketMsg: state => state.app.webSocketMsg,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userId: state => state.user.id,
  name: state => state.user.name,
  email: state => state.user.email,
  loginTel: state => state.user.loginTel,
  login_account: state => state.user.login_account,
  login_method: state => state.user.login_method,
  messageUnreadCount: state => state.user.message_unread_count,
  assistUnreadCount: state => state.user.assist_unread_count,
  pageSize: state => state.user.page_size,
  hasVideo: state => state.video.has_video,
  begintime: state => state.video.begin_time,
  curtime: state => state.video.current_time,
  defaultItem: state => state.video.defaultItem,
  order_end_time: state => state.video.order_end_time,
  curorder: state => state.video.cur_order,
  curMediaType: state => state.video.cur_media_type,
  audio: state => state.video.audio_all,
  maxSilenceDuration: state => state.video.max_silence_duration,
  hangUp: state => state.video.hang_up,
  isEsInsertComplete: state => state.video.isEsInsertComplete,
  transTextLoading: state => state.video.transTextLoading,
  curconvid: state => state.video.cur_conv_id,
  is_user_scroll: state => state.video.is_user_scroll,
  dialogTextScrollable: state => state.video.dialogTextScrollable,
  is_video_pause: state => state.video.is_video_pause,
  pointObj: state => state.video.point_obj,
  tabName: state => state.video.tab_name,
  player: state => state.video.player,
  buffTime: state => state.video.buffTime,
  video: state => state.video.video,
  orgId: state => state.user.org_id,
  orgName: state => state.user.org_name,
  orgs: state => state.user.orgs,
  org_display_info: state => state.user.org_display_info,
  // 团队的logo,icon,title信息
  org_config: state => state.user.org_config,
  // 团队的配置信息
  roleId: state => state.user.role_id,
  api_permissions: state => state.user.api_permissions,
  has_outbound: state => state.user.has_outbound,
  has_video_permission: state => state.user.has_video_permission,
  historyConversation: state => state.user.history_conversation,
  historyActivity: state => state.user.history_activity,
  need_refresh_router: state => state.user.need_refresh_router,
  main_department_id: state => state.user.main_department_id,
  businessStatusOptions: state => state.business.businessStatusOptions,
  cluesStatusOptions: state => state.business.cluesStatusOptions,
  membersOptions: state => state.business.membersOptions,
  busiActiveDate: state => state.business.busiActiveDate,
  busiActiveConver: state => state.business.busiActiveConver,
  biActivityObj: state => state.bi.activityObj,
  biKeyeventObj: state => state.bi.keyeventObj,
  biTopicObj: state => state.bi.topicObj,
  biDimensionObj: state => state.bi.dimensionObj,
  biTopicTime: state => state.bi.topicTime,
  biStatementArr: state => state.bi.statementArr,
  biFiltersObj: state => state.bi.filtersObj,
  userType: state => state.user.user_type,
  trialExpireDate: state => state.user.trial_expire_date,
  hasWorkspace: state => state.user.has_workspace,
  isTwoFactor: state => state.user.is_two_factor,
  webRoutes: state => state.user.webRoutes,
  routesTree: state => state.user.routesTree,
  guideVisiable: state => state.video.guideVisiable,
  appFlag: state => state.app.appFlag,
  top: state => state.video.top,
  keyEventName: state => state.video.keyEventName,
  finishLeft: state => state.video.finishLeft,
  finishTopLeft: state => state.video.finishTopLeft,
  commentStatus: state => state.video.commentStatus,
  selectedKnowledgeIds: state => state.video.selectedKnowledgeIds,
  id2KnowledgeMap: state => state.video.id2KnowledgeMap,
  isConfirmChatAgreement: state => state.user.isConfirmChatAgreement,
  // 是否选择语句
  isChoose: state => state.video.isChoose,
  // 评论选中句子
  selectedSentenceOfComment: state => state.video.selectedSentenceOfComment,
  // 点击评论按钮的次数
  clickCommentBtnTimes: state => state.video.clickCommentBtnTimes,
  // 获取会话的名称
  conversationTitleInfo: state => state.video.conversationTitleInfo,
  // 剪辑或者是命中关键事件
  currentOperateName: state => state.video.currentOperateName,
  // 会话详情页面是否显示关键事件tag
  isShowEventTag: state => state.video.isShowEventTag,
  // 外呼
  callNoteVisible: state => state.call.noteVisible,
  callConverId: state => state.call.conversationId,
  callCustomerName: state => state.call.customerName,
  callNoteTime: state => state.call.noteTime,
  callLeadId: state => state.call.leadId,
  callDealId: state => state.call.dealId,
  callIsCloseNote: state => state.call.isCloseNote,
  /**
   * 剪辑库
   */
  clipConversationId: state => state.library.clipConversationId,
  clipVideoUrl: state => state.library.clipVideoUrl,
  audioInfoList: state => state.library.audioInfoList,
  shareInfoObj: state => state.library.shareInfoObj,
  folderTree: state => state.library.folderTree,
  snippetVideoTime: state => state.video.snippetVideoTime,
  sliderTimeRange: state => state.video.sliderTimeRange,
  snippetCheckList: state => state.video.snippetCheckList,
  saveSnippetVideoTime: state => state.video.saveSnippetVideoTime,
  // 客户
  contactDynamicFormDescList: state => state.account.contactDynamicFormDescList,
  // 上传
  maxDuration: state => state.upload.maxDuration,
  usedDuration: state => state.upload.usedDuration,
  orgMaxDuration: state => state.upload.orgMaxDuration,
  orgUsedDuration: state => state.upload.orgUsedDuration,
  isUseOrgMaxDuration: state => state.upload.isUseOrgMaxDuration,
  uploadStatus: state => state.upload.uploadStatus,
  uploadHostSalesmanInfo: state => state.upload.uploadHostSalesmanInfo,
  globalUploadFileList: state => state.upload.globalUploadFileList,
  has_tencent_meeting: state => state.user.has_tencent_meeting,
  has_wework: state => state.user.has_wework,
  // 关键事件 编辑时间是否截止
  isEditTimeEnd: state => state.settings.isEditTimeEnd,
  // 个人bi时间筛选
  biPersonalFilterTime: state => state.bi.biPersonalFilterTime,
  // 页面权限
  modulePermission: state => state.user.modulePermission,
  // 接口权限
  interfacePermission: state => state.user.interfacePermission,
  // 空间信息
  workspaceInfo: state => state.workspace.workspaceInfo,
  workspaceList: state => state.workspace.workspaceList,
  // 左边栏空间按钮是否禁用
  isWorkspaceButtonDisabled: state => state.workspace.isWorkspaceButtonDisabled,
  /**
   * 培训
   */
  knowledgeDeleted: state => state.training.knowledgeDeleted,
  coachingTaskSpaceId: state => state.training.coachingTaskSpaceId,
  // 时区
  timezone: state => state.user.time_zone,
  isDeveloper: state => state.user.is_developer,
  // 创建课程的已选知识点话术
  checkedKnowledgeScript: state => state.training.checkedKnowledgeScript,
  // 知识库信息
  knowledgeBaseInfo: state => state.training.knowledgeBaseInfo,
  /**
   * 质检
   */
  isShowStageIIComplianceWorkSpaceGuide: state => state.compliance.isShowStageIIComplianceWorkSpaceGuide,
  /**
   * sop
   */
  sopDateFilter: state => state.sop.dateRange,
  /**
   * 规则设置
   */
  typeOptions: state => state.ruleSetting.typeOptions,
  accountTagOptionsList: state => state.ruleSetting.accountTagOptionsList
};
var _default = getters;
exports.default = _default;