"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "smart-training-detail-container",
    class: {
      "is-full-screen": _vm.isEmbed
    }
  }, [_c("personal-plan-header", {
    attrs: {
      type: "smart-training",
      "task-info": _vm.taskInfo,
      "task-score-info": _vm.taskScoreInfo
    },
    on: {
      toggleHistory: _vm.toggleHistory
    }
  }), _c("div", {
    staticClass: "filter"
  }, [_vm.isShowRestartButton ? [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goSmartTrainingPractice
    }
  }, [_vm._v(_vm._s(_vm.isComplete ? _vm.$t("coaching.开始第x次练习", [_vm.totalCount + 1]) : _vm.$t("coaching.继续第x次练习", [_vm.totalCount])))])] : _vm._e()], 2), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "personal-plan-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "row-style": {
        height: "72px"
      }
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "content",
      label: _vm.$t("coaching.standardScript"),
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("div", {
          staticClass: "content"
        }, [_vm._v(_vm._s(row.content))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "reply",
      label: _vm.$t("coaching.salesResponse"),
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "content"
        }, [_vm._v(_vm._s(row.reply))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "score",
      label: _vm.$t("coaching.practiceScore"),
      sortable: "",
      width: "240"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-popover", {
          attrs: {
            trigger: "hover",
            disabled: scope.row.total_score < 0 || scope.row.score < 0
          }
        }, [_c("div", {
          staticClass: "popover-container"
        }, [_c("p", {
          staticClass: "popover-title"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v(_vm._s(_vm.$t("coaching.practiceScore")))]), _c("span", {
          staticClass: "score"
        }, [_c("span", {
          class: _vm.getScoreClass(scope.row)
        }, [_vm._v(_vm._s(scope.row.score))]), _c("span", {
          staticClass: "total-score"
        }, [_vm._v("/" + _vm._s(scope.row.total_score))])])]), scope.row.comment ? _c("p", {
          staticClass: "subtitle"
        }, [_c("i", {
          staticClass: "iconfont icon-ai-assistant-fill"
        }), _vm._v(" " + _vm._s(_vm.$t("coaching.comment")) + "： ")]) : _vm._e(), _c("p", {
          staticClass: "description",
          domProps: {
            innerHTML: _vm._s(scope.row.comment)
          }
        })]), _c("span", {
          staticClass: "score",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [scope.row.score < 0 ? [_vm._v(_vm._s(_vm.$t("coaching.noScoring")))] : [_c("span", {
          class: _vm.getScoreClass(scope.row)
        }, [_vm._v(_vm._s(scope.row.score))]), _vm._v(" " + _vm._s(_vm.$t("coaching.points")) + " ")]], 2)])];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;