"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _StudyStatus = _interopRequireDefault(require("./StudyStatus"));
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    StudyStatus: _StudyStatus.default
  },
  props: {
    noPlay: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    },
    type: {
      // folder:文件夹 snippet:剪辑
      type: String,
      default: 'folder'
    }
  },
  data() {
    return {
      localInfo: {}
    };
  },
  computed: {
    isFolder() {
      return this.type === 'folder';
    }
  },
  watch: {
    info: {
      handler: function (val) {
        this.localInfo = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleLike() {
      this.isFolder ? this.likeOrDislikeFolder() : this.likeOrDislikeSnippet();
    },
    handleCollect() {
      this.isFolder ? this.colleOrDiscollecttFolder() : this.colletOrDiscollectSnippet();
    },
    async likeOrDislikeFolder() {
      const {
        id,
        is_like,
        like_amount
      } = this.localInfo;
      const res = is_like ? await (0, _library.dislikeFolder)(id) : await (0, _library.likeFolder)(id);
      if (res.code === 20000) {
        this.updateLikeData(is_like, like_amount);
      }
    },
    async colleOrDiscollecttFolder() {
      const {
        id,
        is_collect,
        collect_amount
      } = this.localInfo;
      const res = is_collect ? await (0, _library.discollectFolder)(id) : await (0, _library.colletFolder)(id);
      if (res.code === 20000) {
        this.updateCollectData(is_collect, collect_amount);
      }
    },
    async likeOrDislikeSnippet() {
      const {
        id,
        is_like,
        like_amount
      } = this.localInfo;
      const res = is_like ? await (0, _library.dislikeSnippet)(id) : await (0, _library.likeSnippet)(id);
      if (res.code === 20000) {
        this.updateLikeData(is_like, like_amount);
      }
    },
    async colletOrDiscollectSnippet() {
      const {
        id,
        is_collect,
        collect_amount
      } = this.localInfo;
      const res = is_collect ? await (0, _library.discollectSnippet)(id) : await (0, _library.colletSnippet)(id);
      if (res.code === 20000) {
        this.updateCollectData(is_collect, collect_amount);
      }
    },
    updateLikeData(isLike, likeAmount) {
      this.localInfo.is_like = !isLike;
      this.localInfo.like_amount = isLike ? likeAmount - 1 : likeAmount + 1;
    },
    updateCollectData(isCollect, collectAmount) {
      this.localInfo.is_collect = !isCollect;
      this.localInfo.collect_amount = isCollect ? collectAmount - 1 : collectAmount + 1;
    }
  }
};
exports.default = _default;