"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "personal-plan-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight,
      "row-style": {
        height: "72px"
      }
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_vm._l(_vm.tableHeads, function (item) {
    return _c("el-table-column", {
      key: item.prop,
      attrs: {
        prop: item.prop,
        label: item.label,
        sortable: item.sortable,
        "sort-orders": ["descending", "ascending"],
        "min-width": 200,
        width: item.width,
        fixed: item.fixed
      },
      scopedSlots: _vm._u([item.prop === "average_score" ? {
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "custom-table-head"
          }, [_c("div", {
            staticClass: "head-content"
          }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: "题目得分等于该题目下所有回答的平均分",
              placement: "bottom"
            }
          }, [_c("i", {
            staticClass: "iconfont icon-info-1"
          })])], 1)])];
        },
        proxy: true
      } : null, item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "knowledge-name"
          }, [_vm._v(_vm._s(row.name))])];
        }
      } : item.prop === "count" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.getStatus(row))
            }
          })];
        }
      } : item.prop === "average_score" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [row.average_score >= 0 ? _c("span", [_vm._v(_vm._s(row.average_score) + "分")]) : _c("span", {
            staticClass: "no-average-score"
          }, [_vm._v(_vm._s(_vm.$t("coaching.noScoreYet")))])];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("coaching.operation"),
      prop: "operate",
      fixed: "right",
      width: 200,
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("span", {
          staticClass: "operate-content",
          on: {
            click: function ($event) {
              return _vm.goPracticeDetail(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("coaching.details")))])];
      }
    }])
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;