"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "conversation-detail-dimension"
  }, [_c("conversation-dimension", {
    attrs: {
      info: _vm.dimensionInfo,
      "api-process": _vm.apiProcess,
      "media-type": _vm.mediaType,
      "participants-info": _vm.participantsInfo
    },
    on: {
      goBiperson: _vm.goBiPerson
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;