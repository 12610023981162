"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "connected-contact"
  }, [_c("el-select", {
    ref: "contactSelectRef",
    attrs: {
      remote: "",
      "remote-method": _setup.getConnectedContactList,
      filterable: "",
      clearable: "",
      "popper-class": "connected-contact-select",
      placeholder: "请搜索联系人姓名或手机号"
    },
    on: {
      change: _setup.handleContactChange,
      "visible-change": _setup.handleVisibleChange
    },
    model: {
      value: _setup.contact_id,
      callback: function ($$v) {
        _setup.contact_id = $$v;
      },
      expression: "contact_id"
    }
  }, _vm._l(_setup.connectedContactList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_c("div", {
      staticClass: "contact-option-item",
      class: {
        selected: _setup.contact_id === item.id
      }
    }, [_c("mgv-avatar-name", {
      attrs: {
        src: item.avatar,
        name: item.name,
        size: 32,
        "avatar-type": "customer"
      }
    }), _c("div", {
      staticClass: "contact-content"
    }, [_c("div", {
      staticClass: "contact-name",
      domProps: {
        innerHTML: _vm._s(_setup.replaceContactContent(item.name, _setup.contactSelectInnerText))
      }
    }), _c("div", {
      staticClass: "contact-tel",
      domProps: {
        innerHTML: _vm._s(_setup.replaceContactContent(item.tel, _setup.contactSelectInnerText))
      }
    })])], 1)]);
  }), 1), _c(_setup.CreateContactDialog, {
    attrs: {
      visible: _setup.createContactDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.createContactDialogVisible = $event;
      },
      createContactSuccess: _setup.createContactSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;