"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "speech-bubble",
    style: {
      transform: `translateX(-${_vm.translateX}px)`,
      transition: ".7s ease-in-out"
    },
    attrs: {
      "data-date": _vm.datasetDate
    }
  }, [_c("div", {
    staticClass: "blue bubble",
    class: [`blue-size-${_vm.blueSize}`, `size-${_vm.blueSize}`],
    attrs: {
      "data-date": _vm.datasetDate
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": _vm.iconClass,
      "data-date": _vm.datasetDate
    }
  })], 1), _c("div", {
    staticClass: "red bubble",
    class: [`red-size-${_vm.redSize}`, `size-${_vm.redSize}`, {
      "margin-large": _vm.marginLarge
    }, {
      "margin-medium": _vm.marginMedium
    }, {
      "margin-small": _vm.marginSmall
    }],
    attrs: {
      "data-date": _vm.datasetDate
    }
  }, [_c("svg-icon", {
    ref: "svg-icon",
    attrs: {
      "icon-class": _vm.iconClass,
      "data-date": _vm.datasetDate
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;