"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("el-dropdown", _vm._b({
    ref: "moreOperationsRef",
    attrs: {
      trigger: _vm.trigger
    },
    on: {
      command: _vm.handleCommand,
      "visible-change": _vm.visibleChange
    }
  }, "el-dropdown", _vm.$attrs, false), [_vm._t("default", function () {
    return [_vm.stopBubbling ? _c("el-button", {
      attrs: {
        id: "more-btn",
        icon: _vm.icon,
        type: "text"
      },
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation();
        }
      }
    }) : _c("el-button", {
      attrs: {
        icon: _vm.icon,
        type: "text"
      }
    })];
  }), _c("el-dropdown-menu", {
    class: _vm.menuClass,
    staticStyle: {
      "min-width": "128px"
    },
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: item.tips,
        disabled: !item.tips,
        placement: "top"
      }
    }, [_c("el-dropdown-item", {
      class: item.class || "",
      attrs: {
        command: item.command,
        "data-v-name": item["data-v-name"]
      }
    }, [item.icon ? _c("i", {
      class: `iconfont ${item.icon}`
    }) : _vm._e(), _vm._v(_vm._s(item.label) + " "), item.tipInfo && _vm.visible ? _c("el-tooltip", {
      attrs: {
        placement: "top",
        content: item.tipInfo.content
      }
    }, [_c("i", {
      class: `iconfont ${item.tipInfo.icon} tip`
    })]) : _vm._e()], 1)], 1)], 1);
  }), 0)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;