"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dealRiskTabContent = _interopRequireDefault(require("./dealRiskTabContent"));
var _dealSpeakTimeTabContent = _interopRequireDefault(require("./dealSpeakTimeTabContent"));
var _dealCommentTabContent = _interopRequireDefault(require("./dealCommentTabContent"));
var _dealEventsTabContent = _interopRequireDefault(require("./dealEventsTabContent"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealTabsDrawer',
  components: {
    DealRiskTabContent: _dealRiskTabContent.default,
    DealEventsTabContent: _dealEventsTabContent.default,
    DealCommentTabContent: _dealCommentTabContent.default,
    DealNextStepTabContent: _dealEventsTabContent.default,
    DealObjectionTabContent: _dealEventsTabContent.default,
    DealSpeakTimeTabContent: _dealSpeakTimeTabContent.default,
    DealAccountLabelTabContent: _dealEventsTabContent.default,
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    headerList: {
      type: Array,
      default: () => []
    },
    activeTabName: {
      type: String,
      default: ''
    },
    currentRow: {
      type: Object,
      default: () => ({})
    },
    activeConvDate: {
      type: String,
      default: ''
    },
    dealId: {
      type: [String, Number],
      default: ''
    },
    currentFieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webRoutes']),
    tabsHeaders() {
      const headers = ['risk', 'speak_time', 'events', 'next_step', 'objection', 'comment', 'account_label'];
      const headerList = this.headerList.map(item => {
        if (headers.includes(item.field_name)) return item;
      }).filter(item => item !== undefined && Object.keys(item).length);
      if (this.headerList.some(item => item.event_type === 2) && !this.headerList.some(item => item.field_name === 'account_label')) {
        headerList.push({
          field_name: 'account_label',
          name: '客户标签'
        });
      }
      return headerList;
    },
    imageAvatar() {
      return this.currentRow.follow_up_by.avatar || this.currentRow.follow_up_by.name;
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    }
  },
  methods: {
    goCustomerPage(account_id) {
      if (this.hasCustomerPermission) {
        const routeData = this.$router.resolve({
          path: _routeMap.ROUTES.customer,
          query: {
            id: account_id,
            from: 'deal'
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    getComponentsName(field_name) {
      const reg = new RegExp('_', 'g');
      return field_name.replace(reg, '-');
    },
    //  点击跟进动态气泡tab滚动到对应会话
    scrollToActiveConv(top) {
      const tabs_content = document.getElementsByClassName('el-tabs__content')[0];
      setTimeout(() => {
        tabs_content.scrollTo({
          top,
          behavior: 'smooth'
        });
      }, 500);
    },
    handleTitleClick() {
      window.open(`${location.origin}${_routeMap.ROUTES.dealDetail}?id=${this.currentRow.deal_id}&noback=true`);
    },
    toggleTab(tab, event) {
      this.$emit('update:activeTabName', tab.name);
    },
    closeDrawer() {
      this.$emit('update:activeTabName', '');
      this.$emit('update:activeConvDate', '');
      this.$emit('update:visible', false);
    },
    loadMore() {
      this.$emit('loadMore');
    }
  }
};
exports.default = _default;