"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CollapseTab',
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    title: {
      type: String,
      default: ''
    },
    tabArray: {
      type: Array,
      default: () => {
        return []; // 对象属性需要有label和value
      }
    }
  },

  data() {
    return {
      fold: false,
      activeValue: null
    };
  },
  watch: {
    value(item) {
      this.activeValue = item === null || item === void 0 ? void 0 : item.value;
    }
  },
  methods: {
    tabClick(item) {
      this.$emit('input', item);
    }
  }
};
exports.default = _default;