"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.drawerVisible ? _c("div", {
    class: ["training-test-drawer", {
      show: _vm.drawerVisible
    }]
  }, [_c("header", [_c("span", [_vm._v("效果测试")]), _c("el-tooltip", {
    attrs: {
      content: "重新开始",
      placement: "top"
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.practiceStatus !== "generating",
      expression: "practiceStatus !== 'generating'"
    }],
    staticClass: "iconfont icon-refresh-left",
    on: {
      click: _vm.restartTest
    }
  })])], 1), _c("article", {
    ref: "conversation-container"
  }, [_c("div", {
    staticClass: "conversational-transcription"
  }, [_vm.practiceStatus === "generating" ? _c("div", {
    staticClass: "generating"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/coach/generating.gif")
    }
  }), _vm._v(" " + _vm._s(["努力生成中", "模型生成中，请1~3分钟后"][_vm.generatingTipIndex % 2]) + " "), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("刷新查看")])], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.practiceStatus !== "generating",
      expression: "practiceStatus !== 'generating'"
    }],
    ref: "sentence-container",
    staticClass: "sentence-container"
  }, [_vm._m(0), _vm._l(_vm.sentenceList, function (_ref, index) {
    let {
      content,
      type
    } = _ref;
    return [type === "narrator" ? _c("div", {
      key: index,
      staticClass: "narration"
    }, [_c("pre", [_vm._v(_vm._s(content))])]) : _vm._e(), type !== "narrator" ? _c("div", {
      key: index,
      class: ["sentence-box", {
        "sale-sentence-box": type === "sales"
      }]
    }, [type === "customer" ? _c("mgv-avatar-name", {
      attrs: {
        src: _vm.avatarUrl || require("@/assets/coach/mai-mai-avatar.png")
      }
    }) : _vm._e(), _c("div", {
      class: ["sentence", {
        sale: type === "sales"
      }]
    }, [_c("p", [_vm._v(" " + _vm._s(content) + " ")])]), type === "sales" ? _c("mgv-avatar-name", {
      attrs: {
        src: _vm.avatar,
        name: _vm.name
      }
    }) : _vm._e()], 1) : _vm._e()];
  }), _vm.conversationComplate ? [_vm._m(1)] : _vm._e()], 2)])]), _vm.practiceStatus !== "generating" ? _c("footer", [!_vm.conversationComplate ? [_c("div", {
    staticClass: "chart-input-container"
  }, [_c("ChatInput", {
    ref: "chatInput",
    attrs: {
      disabled: _vm.uploadLoading,
      "backfill-input-val": _vm.backfillInputVal,
      maxlength: 1000
    },
    on: {
      change: _vm.echo
    }
  })], 1)] : _vm._e()], 2) : _vm._e()]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "start-sentence"
  }, [_c("span"), _vm._v(" 开始 "), _c("span")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "end-sentence"
  }, [_c("span"), _vm._v(" 会话结束 "), _c("span")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;