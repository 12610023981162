"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "folder-header"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.folder.type === 1 ? "智能会话流" : "文件夹",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "folder-type",
    class: _vm.folder.type === 1 ? "smart" : "folder"
  }, [_c("i", {
    class: ["iconfont", _vm.folder.type === 1 ? "icon-filter" : "icon-folder-fill"]
  })])]), _c("div", [_vm.breadcrumbs.length ? _c("el-breadcrumb", {
    attrs: {
      "separator-class": "el-icon-arrow-right"
    }
  }, _vm._l(_vm.breadcrumbs, function (item) {
    return _c("el-breadcrumb-item", {
      key: item.id,
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("breadClick", item);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1) : _vm._e(), _c("div", {
    staticClass: "plain-row"
  }, [_c("span", [_vm._v("创建人："), _c("span", [_vm._v(_vm._s(_vm.folder.create_by))])]), _c("span", {
    staticClass: "update-date"
  }, [_vm._v("更新日期："), _c("span", [_vm._v(_vm._s(_vm.$plus8ToCurrentZone(_vm.folder.update_at).format("YYYY-MM-DD")))])])])], 1)], 1), !_vm.readonly ? _c("div", {
    staticClass: "button-part"
  }, [!_vm.isLike ? _c("el-tooltip", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasForderId,
      expression: "hasForderId"
    }],
    attrs: {
      effect: "dark",
      content: "点赞",
      placement: "bottom"
    }
  }, [_c("div", {
    staticClass: "operate-item",
    on: {
      click: _vm.likeFolder
    }
  }, [_c("i", {
    staticClass: "iconfont icon-great-2"
  })])]) : _c("el-tooltip", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasForderId,
      expression: "hasForderId"
    }],
    attrs: {
      effect: "dark",
      content: "已点赞",
      placement: "bottom"
    }
  }, [_c("div", {
    staticClass: "operate-item",
    on: {
      click: _vm.dislikeFolder
    }
  }, [_c("i", {
    staticClass: "iconfont icon-great-3"
  })])]), !_vm.isCollect ? _c("el-tooltip", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasForderId,
      expression: "hasForderId"
    }],
    attrs: {
      effect: "dark",
      content: "收藏",
      placement: "bottom"
    }
  }, [_c("div", {
    staticClass: "operate-item",
    on: {
      click: _vm.colletFolder
    }
  }, [_c("i", {
    staticClass: "iconfont icon-star-off"
  })])]) : _c("el-tooltip", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasForderId,
      expression: "hasForderId"
    }],
    attrs: {
      effect: "dark",
      content: "已收藏",
      placement: "bottom"
    }
  }, [_c("div", {
    staticClass: "operate-item",
    on: {
      click: _vm.discollectFolder
    }
  }, [_c("i", {
    staticClass: "iconfont icon-star-on-2"
  })])]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "上传音视频",
      placement: "bottom"
    }
  }, [_vm.interfacePermission.uploadMedia && !_vm.folder.type ? _c("div", {
    staticClass: "operate-item",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.uploadFile
    }
  }, [_c("i", {
    staticClass: "iconfont icon-upload1"
  })]) : _vm._e()]), _vm._t("more-operate", function () {
    return [_c("add-folder-dropdown", {
      on: {
        create: function ($event) {
          return _vm.$emit("create", $event);
        }
      }
    }), _c("folder-dropdown", {
      attrs: {
        "is-collect": false
      },
      on: {
        command: function ($event) {
          return _vm.$emit("command", $event, _vm.folder);
        }
      }
    })];
  })], 2) : _vm._e()]), _c("el-divider")], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;