"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "vue3-example"
  }, [_c("h1", {
    staticClass: "example-title"
  }, [_vm._v("MGV 组件示例")]), _vm._m(0), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvSearchBar"
    }
  }, [_c("h1", [_vm._v("搜索")]), _c("MgvSearchBar", {
    attrs: {
      root: "#vue3-example"
    }
  })], 1), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvTag"
    }
  }, [_c("h1", [_vm._v("标签")]), _vm._v(" 默认标签： "), _c("MgvTag", [_vm._v("默认标签")]), _c("br"), _c("br"), _vm._v(" 图标标签： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag"
    }
  }, [_vm._v("图标标签")]), _c("br"), _c("br"), _vm._v(" 可删除标签： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: ""
    },
    on: {
      close: _setup.handleClose
    }
  }, [_vm._v("删除标签")]), _c("br"), _c("br"), _vm._v(" 可选择标签： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      clickable: ""
    },
    on: {
      click: _setup.handleClick
    }
  }, [_vm._v("选择标签")]), _c("br"), _c("br"), _vm._v(" 标签颜色： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: ""
    }
  }, [_vm._v("default")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      type: "success"
    }
  }, [_vm._v("success")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      type: "processing"
    }
  }, [_vm._v("processing")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      type: "error"
    }
  }, [_vm._v("error")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      type: "warning"
    }
  }, [_vm._v("warning")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      color: "orange"
    }
  }, [_vm._v("orange")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      color: "purple"
    }
  }, [_vm._v("purple")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      color: "pink"
    }
  }, [_vm._v("pink")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      color: "cyan"
    }
  }, [_vm._v("cyan")]), _c("br"), _c("br"), _vm._v(" 标签大小： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 20
    }
  }, [_vm._v("标签大小20")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: ""
    }
  }, [_vm._v("标签大小24")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 28
    }
  }, [_vm._v("标签大小28")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 32
    }
  }, [_vm._v("标签大小32")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 36
    }
  }, [_vm._v("标签大小36")]), _c("br"), _c("br"), _vm._v(" 圆角标签： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 20,
      round: ""
    }
  }, [_vm._v("圆角标签")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      round: ""
    }
  }, [_vm._v("圆角标签")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 28,
      round: ""
    }
  }, [_vm._v("圆角标签")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 32,
      round: ""
    }
  }, [_vm._v("圆角标签")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      size: 36,
      round: ""
    }
  }, [_vm._v("圆角标签")]), _c("br"), _c("br"), _vm._v(" 标签宽度： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      round: ""
    }
  }, [_vm._v("默认最大宽度为110px，我先测试一下写长一点")]), _vm._v("  "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      "max-width": "300px",
      closable: "",
      round: ""
    }
  }, [_vm._v("自设宽度为300px，我先测试一下写长一点,再写长一点点点")]), _c("br"), _c("br"), _vm._v(" 禁用标签： "), _c("MgvTag", {
    attrs: {
      icon: "iconfont icon-price-tag",
      closable: "",
      round: "",
      clickable: "",
      disabled: ""
    }
  }, [_vm._v("禁用标签")]), _c("br"), _c("br")], 1), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvLink"
    }
  }, [_c("h1", [_vm._v("链接")]), _vm._v(" 默认链接： "), _c("MgvLink", [_vm._v("文字链接")]), _vm._v("   "), _c("MgvLink", {
    attrs: {
      icon: "iconfont icon-reading"
    }
  }, [_vm._v("图标文字链接")]), _c("br"), _c("br"), _vm._v(" 禁用链接： "), _c("MgvLink", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v("文字链接")]), _vm._v("   "), _c("MgvLink", {
    attrs: {
      icon: "iconfont icon-reading",
      disabled: ""
    }
  }, [_vm._v("图标文字链接")])], 1), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvCatalogTree"
    }
  }, [_c("h1", [_vm._v("目录树")]), _c("div", {
    staticClass: "mgv-catalog-tree-wrapper"
  }, [_c("MgvCatalogTree", {
    ref: "mgvCatalogTreeRef",
    attrs: {
      "tree-data": _setup.treeData,
      "show-count": "",
      "show-operate-btns": "",
      "icon-config-method": _setup.getCatalogIconConfig,
      "operate-btns-options-method": _setup.getCatalogTreeOperationConfigs
    },
    on: {
      handleOperateCommand: _setup.handleOperateCommand,
      inputBlur: _setup.handleRename
    }
  })], 1)]), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvRadioTags"
    }
  }, [_c("h1", [_vm._v("自定义单选框组")]), _c("MgvRadioTags", {
    attrs: {
      list: _setup.customRadioList,
      size: "large"
    },
    model: {
      value: _setup.customRadioValue,
      callback: function ($$v) {
        _setup.customRadioValue = $$v;
      },
      expression: "customRadioValue"
    }
  }), _c("MgvRadioTags", {
    attrs: {
      list: _setup.customRadioList,
      round: ""
    },
    model: {
      value: _setup.customRadioValue2,
      callback: function ($$v) {
        _setup.customRadioValue2 = $$v;
      },
      expression: "customRadioValue2"
    }
  }), _c("MgvRadioTags", {
    attrs: {
      list: _setup.customRadioList,
      size: "small",
      required: false
    },
    model: {
      value: _setup.customRadioValue3,
      callback: function ($$v) {
        _setup.customRadioValue3 = $$v;
      },
      expression: "customRadioValue3"
    }
  })], 1), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvDatePicker"
    }
  }, [_c("h1", [_vm._v("日期选择器")]), _c("MgvDatePicker", {
    on: {
      change: _setup.datePickerChange
    },
    model: {
      value: _setup.dateValue,
      callback: function ($$v) {
        _setup.dateValue = $$v;
      },
      expression: "dateValue"
    }
  }), _c("br"), _c("br"), _c("MgvDatePicker", {
    attrs: {
      size: "small",
      shortcuts: ["近7天", "近30天", "近3个月"]
    },
    model: {
      value: _setup.dateValue2,
      callback: function ($$v) {
        _setup.dateValue2 = $$v;
      },
      expression: "dateValue2"
    }
  }), _c("br"), _c("br"), _c("MgvDatePicker", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _setup.dateValue3,
      callback: function ($$v) {
        _setup.dateValue3 = $$v;
      },
      expression: "dateValue3"
    }
  }), _c("br"), _c("br"), _c("MgvDatePicker", {
    attrs: {
      size: "micro"
    },
    model: {
      value: _setup.dateValue4,
      callback: function ($$v) {
        _setup.dateValue4 = $$v;
      },
      expression: "dateValue4"
    }
  })], 1), _c("div", {
    staticClass: "mgv-content",
    attrs: {
      id: "MgvInfoAlert"
    }
  }, [_c("h1", [_vm._v("可删除的信息提示")]), _c("MgvInfoAlert", {
    attrs: {
      "alert-key": "example"
    }
  }, [_vm._v(" 模型预测错的文本句，如果是测试集标注有问题，可以直接点击单元格修正，如果是训练集标注有问题，可以“修正训练集” ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "nav-display"
  }, [_c("a", {
    attrs: {
      href: "#MgvSearchBar"
    }
  }, [_vm._v("搜索")]), _c("a", {
    attrs: {
      href: "#MgvTag"
    }
  }, [_vm._v("标签")]), _c("a", {
    attrs: {
      href: "#MgvLink"
    }
  }, [_vm._v("链接")]), _c("a", {
    attrs: {
      href: "#MgvCatalogTree"
    }
  }, [_vm._v("目录树")]), _c("a", {
    attrs: {
      href: "#MgvRadioTags"
    }
  }, [_vm._v("自定义单选框组")]), _c("a", {
    attrs: {
      href: "#MgvDatePicker"
    }
  }, [_vm._v("日期选择器")]), _c("a", {
    attrs: {
      href: "#MgvInfoAlert"
    }
  }, [_vm._v("可删除的信息提示")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;