"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoRoom = require("@/api/videoRoom");
var _videoDetail = require("@/api/videoDetail");
var _nanoid = require("nanoid");
var _i18nConfig = _interopRequireDefault(require("./i18nConfig"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  name: 'NoteEditor',
  props: {
    conversationId: {
      type: [Number, String],
      default: ''
    },
    readOnly: Boolean,
    note: {
      type: Object,
      default: () => null
    },
    isTemplate: Boolean,
    isDemo: Boolean
  },
  data() {
    return {
      editor: null,
      editorId: (0, _nanoid.nanoid)(),
      originData: {},
      timer: null,
      savedTime: '',
      blocksCount: '',
      blocksChange: {}
    };
  },
  computed: {
    saveIconClass() {
      return this.saveLoding ? 'el-icon-loading' : 'el-icon-check';
    }
  },
  watch: {
    async conversationId() {
      this.destroy();
      await this.getMyNote();
      this.init();
    },
    blocksChange: {
      handler(val) {
        this.judgeEmpty(val);
      },
      deep: true
    }
  },
  created() {},
  async mounted() {
    if (this.note) this.originData = this.note;
    await this.getMyNote();
    this.init();
  },
  methods: {
    async init() {
      const {
        default: Editorjs
      } = await Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName:"editorjs" */'@editorjs/editorjs')));
      const {
        default: Header
      } = await Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName:"editorjs" */'@editorjs/header')));
      const {
        default: List
      } = await Promise.resolve().then(() => _interopRequireWildcard(require( /* webpackChunkName:"editorjs" */'@editorjs/list')));
      this.editor = new Editorjs({
        holder: this.editorId,
        tools: {
          header: {
            class: Header,
            config: {
              levels: [3],
              defaultLevel: 3
            }
          },
          list: List
        },
        readOnly: this.readOnly,
        data: this.originData,
        placeholder: this.readOnly ? '暂无笔记' : '请在这里输入您的想法···',
        onChange: this.onChange,
        onReady: this.onReady,
        i18n: _i18nConfig.default
      });
    },
    async getMyNote() {
      if (!this.conversationId || this.isDemo) return;
      const res = await (0, _videoDetail.getMyNote)(this.conversationId);
      this.originData = res.results.content;
    },
    destroy() {
      var _this$editor;
      (_this$editor = this.editor) === null || _this$editor === void 0 ? void 0 : _this$editor.destroy();
    },
    async save() {
      if (this.isDemo || this.readOnly) return;
      this.saveLoding = true;
      const content = await this.editor.save();
      if (this.isTemplate) {
        this.$emit('saveTemplate', content);
      } else {
        this.$emit('saveNewTemplate', content);
        const res = await (0, _videoRoom.sendNote)(this.conversationId, content);
        if (res.code === 200) {
          this.savedTime = this.$moment().format('HH:mm:ss');
          this.$emit('saveSucceed', this.savedTime);
        }
      }
    },
    onChange(api, e) {
      if (this.isDemo) return;
      if (this.isTemplate) {
        this.$emit('templateChange', e);
      } else {
        this.blocksCount = api === null || api === void 0 ? void 0 : api.blocks.getBlocksCount();
        this.blocksChange = e;
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.save();
        }, 2000);
      }
    },
    async judgeEmpty(data) {
      if (this.blocksCount === 0 || this.blocksCount === undefined) {
        this.$emit('change', true);
      } else if (this.blocksCount === 1 || (data === null || data === void 0 ? void 0 : data.isEmpty) === true) {
        const content = !this.readOnly && (await this.editor.save());
        const noteIsEmpty = !(content !== null && content !== void 0 && content.blocks.length);
        this.$emit('change', noteIsEmpty);
      } else {
        this.$emit('change', false);
      }
    },
    onReady() {
      // 用来笔记模板列表切换时，防止错乱
      this.$emit('templateEditorReady');
      if (this.conversationId) {
        if (JSON.stringify(this.originData) === '{}') {
          this.$emit('ready', true);
        } else {
          var _this$originData;
          const noteIsEmpty = !((_this$originData = this.originData) !== null && _this$originData !== void 0 && _this$originData.blocks.length);
          this.$emit('ready', noteIsEmpty);
          this.$emit('saveNewTemplate', this.originData);
        }
      }
    }
  }
};
exports.default = _default;