"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "chart-config",
    attrs: {
      title: _vm.isEditCard ? "编辑卡片" : "添加卡片",
      visible: _vm.chartConfigVisible,
      "close-on-click-modal": false,
      width: "1080px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.chartConfigVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-form", {
    ref: "chartConfigForm",
    attrs: {
      model: _vm.chartConfig
    }
  }, [_c("div", {
    staticClass: "left"
  }, [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("卡片类型")]), _c("div", {
    staticClass: "chart-type-box"
  }, [_vm._l(_vm.chartTypeList, function (_ref) {
    let {
      type,
      label,
      imgSrc
    } = _ref;
    return [_c("div", {
      key: type,
      staticClass: "chart-type-item",
      class: {
        active: _vm.chartConfig.type === type
      },
      on: {
        click: function ($event) {
          return _vm.changeChartType(type);
        }
      }
    }, [_c("img", {
      attrs: {
        src: imgSrc
      }
    }), _c("span", [_vm._v(_vm._s(label))])])];
  })], 2), _vm.chartConfig.type !== "Digit" ? [_c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("分析维度")]), _vm.chartConfig.type !== "Digit" ? _c("el-form-item", {
    attrs: {
      prop: "configs.dimension",
      rules: [{
        required: true,
        message: "请选择分析维度",
        trigger: "change"
      }]
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择分析维度"
    },
    on: {
      change: _vm.resetPreview
    },
    model: {
      value: _vm.chartConfig.configs.dimension,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.configs, "dimension", $$v);
      },
      expression: "chartConfig.configs.dimension"
    }
  }, _vm._l(_vm.dimensionOptions, function (_ref2) {
    let {
      label,
      value
    } = _ref2;
    return _c("el-option", {
      key: value,
      attrs: {
        label: label,
        value: value
      }
    });
  }), 1)], 1) : _vm._e()] : _vm._e(), _c("analysis-config", {
    attrs: {
      "show-conv-key-event-option": "",
      configs: _vm.chartConfig.configs
    },
    on: {
      clearValidate: _vm.clearValidate,
      resetPreview: _vm.resetPreview
    }
  }), _c("p", {
    staticClass: "form-item-label"
  }, [_vm._v("筛选条件")]), _c("el-form-item", {
    staticClass: "filter-date-box",
    attrs: {
      prop: "filter_configs.date.value"
    }
  }, [_c("el-select", {
    on: {
      change: _vm.resetPreview
    },
    model: {
      value: _vm.chartConfig.filter_configs.date.type,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.filter_configs.date, "type", $$v);
      },
      expression: "chartConfig.filter_configs.date.type"
    }
  }, _vm._l(_vm.dateType, function (_ref3) {
    let {
      label,
      value
    } = _ref3;
    return _c("el-option", {
      key: value,
      attrs: {
        label: label,
        value: value
      }
    });
  }), 1), _c("div", {
    staticClass: "date-input-box"
  }, [_c("el-radio", {
    attrs: {
      label: false
    },
    model: {
      value: _vm.chartConfig.filter_configs.date.is_current,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.filter_configs.date, "is_current", $$v);
      },
      expression: "chartConfig.filter_configs.date.is_current"
    }
  }, [_vm._v("过去")]), _c("el-input", {
    attrs: {
      maxlength: "3"
    },
    on: {
      change: _vm.changeFilterDate
    },
    model: {
      value: _vm.chartConfig.filter_configs.date.value,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.filter_configs.date, "value", $$v);
      },
      expression: "chartConfig.filter_configs.date.value"
    }
  }, [_c("i", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(_vm._s(_vm.getDateTip))])])], 1), _c("el-radio", {
    attrs: {
      label: true
    },
    model: {
      value: _vm.chartConfig.filter_configs.date.is_current,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.filter_configs.date, "is_current", $$v);
      },
      expression: "chartConfig.filter_configs.date.is_current"
    }
  }, [_vm._v(_vm._s(_vm.getCurrentDateTip))])], 1), _c("deal-key-event-filter", {
    staticClass: "deal-key-event-filter",
    model: {
      value: _vm.chartConfig.filter_configs.event_filters,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig.filter_configs, "event_filters", $$v);
      },
      expression: "chartConfig.filter_configs.event_filters"
    }
  }), _c("deal-custom-field-filter", {
    attrs: {
      "custom-filters": _vm.chartConfig.filter_configs.custom_filters
    },
    on: {
      customFilterChange: _vm.customFilterChange
    }
  })], 2), _c("div", {
    staticClass: "right"
  }, [_c("el-form-item", {
    staticClass: "edit-card-name-input",
    attrs: {
      prop: "name",
      rules: [{
        required: true,
        message: "请输入图表名称",
        trigger: "change"
      }]
    }
  }, [_c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditCardName,
      expression: "isEditCardName"
    }],
    ref: "edit-card-name-input",
    attrs: {
      placeholder: "请填写卡片名称",
      maxlength: "20"
    },
    on: {
      blur: function ($event) {
        _vm.isEditCardName = false;
      }
    },
    model: {
      value: _vm.chartConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig, "name", $$v);
      },
      expression: "chartConfig.name"
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEditCardName,
      expression: "!isEditCardName"
    }]
  }, [_c("span", [_vm._v(_vm._s(_vm.chartConfig.name))]), _c("i", {
    staticClass: "el-icon-edit",
    attrs: {
      slot: "append"
    },
    on: {
      click: function ($event) {
        return _vm.editChartName("edit-card-name-input");
      }
    },
    slot: "append"
  })])], 1), _c("el-input", {
    staticClass: "edit-card-description",
    attrs: {
      type: "textarea",
      placeholder: "添加描述",
      maxlength: "100"
    },
    model: {
      value: _vm.chartConfig.description,
      callback: function ($$v) {
        _vm.$set(_vm.chartConfig, "description", $$v);
      },
      expression: "chartConfig.description"
    }
  }), _c("previewChart", {
    attrs: {
      "init-card-preview-config": _vm.getInitCardPreviewConfig,
      "preview-chart-data": _vm.previewChartData
    },
    on: {
      updatePreview: _vm.updatePreview
    }
  })], 1)])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;