"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "meeting-form",
    staticClass: "business-form",
    attrs: {
      model: _vm.form,
      "hide-required-asterisk": "",
      rules: _vm.rules
    }
  }, [!_vm.isBase ? [_vm.type === "clue" ? _c("el-form-item", {
    attrs: {
      prop: "customer_name"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("线索联系人")]), _c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.contact_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_name", $$v);
      },
      expression: "form.contact_name"
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      prop: "customer_name"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("客户名称")]), _c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.customer_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customer_name", $$v);
      },
      expression: "form.customer_name"
    }
  })], 1)] : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "meeting_name"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("会议名称")]), _c("el-input", {
    attrs: {
      placeholder: "请输入会议名称"
    },
    model: {
      value: _vm.form.meeting_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "meeting_name", $$v);
      },
      expression: "form.meeting_name"
    }
  })], 1), _vm.type !== "clue" && !_vm.isBase ? _c("el-form-item", {
    attrs: {
      prop: "contact"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("客户联系人")]), _c("el-select", {
    model: {
      value: _vm.form.contact_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_id", $$v);
      },
      expression: "form.contact_id"
    }
  }, _vm._l(_vm.contactOptions, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "date-time"
  }, [_c("el-form-item", {
    attrs: {
      prop: "begin_date"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("开始时间")]), _c("el-date-picker", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      type: "date",
      placeholder: "选择日期",
      "picker-options": _vm.pickerOptions,
      rules: _vm.rules.time
    },
    model: {
      value: _vm.form.begin_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "begin_date", $$v);
      },
      expression: "form.begin_date"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "begin_time"
    }
  }, [_c("div", {
    staticClass: "set-now-wrapper"
  }, [_c("el-button", {
    staticClass: "set-now",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.setNow
    }
  }, [_vm._v("设为此刻")])], 1), _c("time-picker", {
    attrs: {
      "min-time": _vm.nowTime,
      "begin-time": _vm.form.begin_time,
      "begin-date": _vm.form.begin_date,
      type: "begin"
    },
    on: {
      selected: val => _vm.form.begin_time = val,
      flag: _vm.timeFlag
    }
  })], 1)], 1), !_vm.isBase ? _c("el-form-item", {
    attrs: {
      prop: "host_salesman_id"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("销售")]), _c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.searchGetMemberList,
      loading: _vm.selectLoading,
      disabled: _vm.meetingType === "tencent_meeting"
    },
    model: {
      value: _vm.form.host_salesman_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "host_salesman_id", $$v);
      },
      expression: "form.host_salesman_id"
    }
  }, _vm._l(_vm.memberOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "date-time",
    staticStyle: {
      position: "relative"
    }
  }, [_vm.confilct_conversations_as_host.length !== 0 || _vm.confilct_conversations_not_as_host.length !== 0 ? _c("div", {
    staticClass: "conflict"
  }, [_c("div", {
    staticClass: "conflict-text"
  }, [_vm._v(_vm._s(_vm.isBase ? "您" : "销售") + "有冲突日程")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      "popper-class": "event-tooltip"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.conflictContent)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      "font-size": "16px",
      color: "#c7ccdc"
    }
  })])], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "end_date"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("结束时间")]), _c("el-date-picker", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      type: "date",
      placeholder: "选择日期",
      "picker-options": _vm.pickerOptions
    },
    model: {
      value: _vm.form.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "end_date", $$v);
      },
      expression: "form.end_date"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "end_time"
    }
  }, [_c("label", {
    staticClass: "label",
    staticStyle: {
      height: "22px"
    }
  }), _c("time-picker", {
    attrs: {
      "min-time": _vm.sameDay ? _vm.form.begin_time : null,
      type: "end",
      "begin-time": _vm.form.begin_time,
      "end-time": _vm.form.end_time,
      "end-date": _vm.form.end_date,
      "begin-date": _vm.form.begin_date
    },
    on: {
      selected: _vm.select,
      flag: _vm.timeFlag
    }
  })], 1)], 1), _vm.meetingType !== "tencent_meeting" && !_vm.isBase ? _c("el-form-item", {
    attrs: {
      prop: "assistant_salesman_ids"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("邀请部门同事"), _c("span", {
    staticClass: "label-info"
  }, [_vm._v("（最多可选6人）")])]), _c("members-finder", _vm._g({
    ref: "select-members",
    attrs: {
      "has-self": "",
      "multiple-limit": _vm.limitNumber
    },
    model: {
      value: _vm.form.assistant_salesman_ids,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assistant_salesman_ids", $$v);
      },
      expression: "form.assistant_salesman_ids"
    }
  }, _vm.$listeners))], 1) : _vm._e(), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      prop: "spectator_salesman_ids"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("关注者")]), _c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      remote: "",
      loading: _vm.selectLoading,
      "remote-method": _vm.searchGetMemberList
    },
    model: {
      value: _vm.form.spectator_salesman_ids,
      callback: function ($$v) {
        _vm.$set(_vm.form, "spectator_salesman_ids", $$v);
      },
      expression: "form.spectator_salesman_ids"
    }
  }, _vm._l(_vm.memberOptionsNew.length > 0 ? _vm.memberOptionsNew : _vm.form.spectatorInfos, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;