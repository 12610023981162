"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "mark-line"
  }, _vm._l(_vm.items, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "mark-item",
      style: {
        left: item.begin_percent + "%"
      }
    }, [_c("div", {
      staticClass: "round-wrapper",
      style: {
        background: item.entity_type === "customer_contact" ? "#f43f7c" : "#4461EC"
      }
    }), _c("div", {
      staticClass: "bar",
      style: {
        background: item.entity_type === "customer_contact" ? "#f43f7c" : "#4461EC"
      }
    })]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;