"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _vue = require("vue");
var _default = {
  __name: 'ConversationLink',
  props: {
    id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    path: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const conversationDetailPath = _routeMap.ROUTES.conversationDetail;
    const conversationIcon = (0, _vue.computed)(() => {
      return {
        doc: 'icon-im',
        audio: 'icon-phone-outline',
        video: 'icon-video-camera'
      }[props.type] || 'icon-phone-outline';
    });
    const hrefPath = (0, _vue.computed)(() => {
      return props.path || `${conversationDetailPath}?id=${props.id}&noback=true`;
    });
    return {
      __sfc: true,
      props,
      conversationDetailPath,
      conversationIcon,
      hrefPath
    };
  }
};
exports.default = _default;