"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.convFlowList.length || _vm.list.length ? _c("div", {
    staticClass: "sub-folders"
  }, [_vm.convFlowList.length ? _c("div", {
    staticClass: "conv-flow-cards"
  }, [_c("div", {
    staticClass: "number"
  }, [_c("i", {
    staticClass: "iconfont icon-filter"
  }), _vm._v(" " + _vm._s(_vm.convFlowTotal) + "个智能会话流 ")]), _c("div", {
    staticClass: "conv-flow-list"
  }, _vm._l(_vm.convFlowList, function (item) {
    return _c("conv-flow-card", {
      key: item.id,
      attrs: {
        folder: item
      },
      on: {
        command: function ($event) {
          return _vm.$emit("convFlowOperation", $event, item);
        }
      },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("folderClick", item);
        }
      }
    });
  }), 1), _vm.convFlowList.length !== _vm.convFlowTotal ? _c("div", {
    staticClass: "load-more",
    on: {
      click: function ($event) {
        return _vm.$emit("moreConvFlow");
      }
    }
  }, [_vm._v(" 加载更多 ")]) : _vm._e()]) : _vm._e(), _vm.list.length ? [_c("div", {
    staticClass: "number"
  }, [_c("i", {
    staticClass: "iconfont icon-folder"
  }), _vm._v(" " + _vm._s(_vm.foldTotal) + "个文件夹 ")]), _c("div", {
    staticClass: "card-list"
  }, _vm._l(_vm.list, function (item) {
    return _c("folder-card", {
      key: item.id,
      ref: "foldCard",
      refInFor: true,
      attrs: {
        folder: item
      },
      on: {
        command: function ($event) {
          return _vm.$emit("command", $event, item);
        },
        create: function ($event) {
          return _vm.$emit("create", $event, item);
        }
      },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("folderClick", item);
        }
      }
    });
  }), 1), _vm.list.length !== _vm.foldTotal ? _c("div", {
    staticClass: "load-more",
    on: {
      click: function ($event) {
        return _vm.$emit("moreSubfolders");
      }
    }
  }, [_vm._v(" 加载更多 ")]) : _vm._e()] : _vm._e()], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;