"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }]
  }, [_vm.convList.length ? _c("mg-infinite-scroll", {
    staticClass: "conv-list",
    attrs: {
      "nomore-text": "仅显示近一年的会话",
      loading: _vm.loading,
      nomore: _vm.nomore
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.convList, function (items, index) {
    return _c("div", {
      key: index,
      staticClass: "list-item"
    }, [_c("div", {
      staticClass: "list-item-title"
    }, [_c("div", {
      staticClass: "list-item-time"
    }, [_vm._v(_vm._s(items.day))]), _c("div", {
      staticClass: "list-item-total"
    }, [_vm._v(" " + _vm._s(items.total)), _c("span", {
      staticClass: "total-unit"
    }, [_vm._v("个会话")])])]), _c("div", {
      staticClass: "list-item-box"
    }, _vm._l(items.data, function (item, index) {
      return _c("div", {
        key: index + "c"
      }, [_c("conv-item", {
        attrs: {
          item: item,
          "active-name": _vm.activeName
        }
      })], 1);
    }), 0)]);
  }), 0) : _vm._e(), !_vm.convList.length && !_vm.listLoading ? _c("NoData", {
    staticClass: "no-conv-cont",
    attrs: {
      tips: "仅显示近一年的会话"
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;