"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardTextMainData = _interopRequireDefault(require("./CardTextMainData.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CardText',
  components: {
    CardTextMainData: _CardTextMainData.default
  },
  props: {
    cardConfig: {
      type: Object,
      default: () => ({})
    },
    unitConfig: {
      type: Object,
      default: () => ({})
    }
  }
};
exports.default = _default;