"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g({
    staticClass: "space-select",
    attrs: {
      "popper-class": "coaching-center-space-select-popper",
      placeholder: "空间",
      value: _vm.value,
      clearable: _vm.clearable
    }
  }, _vm.$listeners), [_vm.selectedItem ? _c("i", {
    staticClass: "iconfont icon-workspace-3-fill",
    style: {
      color: _vm.selectedItem.icon_color
    },
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  }) : _vm._e(), _vm._l(_vm.workspaceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;