"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "knowledge-conv-card-content"
  }, _vm._l(_vm.firstContent, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "content-item"
    }, [_vm.convItem.conversation_type !== "doc" ? _c("span", {
      staticClass: "begin-time"
    }, [_vm._v(_vm._s(_vm.secondToTime(item.begin_time)))]) : _c("span", {
      staticClass: "begin-time-stamp"
    }, [_vm._v(_vm._s(_vm.timeStampToDate(item.begin_time)))]), _c("div", {
      staticClass: "content",
      on: {
        click: function ($event) {
          return _vm.goConvDetail(item);
        }
      }
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v("【" + _vm._s(item.name) + "】")]), _c("span", {
      domProps: {
        innerHTML: _vm._s(_vm.getHightlightContent(item))
      }
    })])]);
  }), 0), _vm.isExpand ? _c("el-divider") : _vm._e(), _c("collapse-transition", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isExpand,
      expression: "isExpand"
    }],
    staticClass: "allSelect"
  }, [_vm._t("default", function () {
    return [_c("div", {
      staticClass: "allSelect"
    }, _vm._l(_vm.openContent, function (segment, index) {
      return _c("div", {
        key: index + "openContent",
        staticClass: "knowledge-conv-card-content"
      }, [_vm._l(segment, function (item, index) {
        return _c("div", {
          key: index,
          staticClass: "content-item"
        }, [_vm.convItem.conversation_type !== "doc" ? _c("span", {
          staticClass: "begin-time"
        }, [_vm._v(_vm._s(_vm.secondToTime(item.begin_time)))]) : _c("span", {
          staticClass: "begin-time-stamp"
        }, [_vm._v(_vm._s(_vm.timeStampToDate(item.begin_time)))]), _c("div", {
          staticClass: "content",
          on: {
            click: function ($event) {
              return _vm.goConvDetail(item);
            }
          }
        }, [_c("span", {
          staticClass: "name"
        }, [_vm._v("【" + _vm._s(item.name) + "】")]), _c("span", {
          domProps: {
            innerHTML: _vm._s(_vm.getHightlightContent(item))
          }
        })])]);
      }), index !== _vm.openContent.length - 1 ? _c("el-divider") : _vm._e()], 2);
    }), 0)];
  })], 2)]), _vm.contentList.length > 1 ? _c("div", {
    staticClass: "open",
    on: {
      click: function ($event) {
        _vm.isExpand = !_vm.isExpand;
      }
    }
  }, [_vm._v(" " + _vm._s(!_vm.isExpand ? `查看全部 ${_vm.contentList.length} 次` : "收起") + " "), _c("span", [!_vm.isExpand ? _c("i", {
    staticClass: "iconfont icon-arrow-down"
  }) : _c("i", {
    staticClass: "iconfont icon-arrow-up"
  })])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;