"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._l(_vm.chartData, function (item, index) {
    return [item ? _c("chartContainer", {
      key: index,
      staticClass: "chart-container",
      attrs: {
        title: item.chartConfig.title || "",
        "sub-text": item.chartConfig.description || "",
        total: item.chartConfig.total_count || "",
        "is-sampled": item.chartConfig.is_sampled
      }
    }, [item.chartType !== "scatter" ? _c("el-button", {
      staticClass: "export-chart-to-csv",
      on: {
        click: function ($event) {
          return _vm.handleExportCsv(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-export"
    }), _vm._v("导出表格")]) : _vm._e(), item.chartType === "scatter" ? _c("el-button", {
      staticClass: "bi-kanban-edit-btn",
      attrs: {
        icon: "el-icon-edit"
      },
      on: {
        click: function ($event) {
          return _vm.$emit("editConfig");
        }
      }
    }, [_vm._v("编辑")]) : _vm._e(), item.chartType === "scatter" ? _c("scatter-diagram-chart", {
      attrs: {
        data: item.chartData,
        "chart-config": item.chartConfig,
        "table-configs": item.tableConfigs
      }
    }) : _vm._e(), item.chartType === "bar" ? _c("cross-bar-chart", {
      attrs: {
        data: item.chartData,
        "chart-config": item.chartConfig
      }
    }) : _vm._e(), item.chartType === "pie" ? _c("pie-chart", {
      attrs: {
        data: item.chartData
      }
    }) : _vm._e(), item.chartConfig.idea ? _c("suggestion-box", {
      attrs: {
        text: item.chartConfig.idea
      }
    }) : _vm._e(), item.chartType === "scatter" ? _c("scatter-table", {
      attrs: {
        "table-data": item.tableConfigs,
        "legend-column": item.chartConfig.mapping_config.map_column
      }
    }) : _vm._e()], 1) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;