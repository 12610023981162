"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MyTaskDetailFilterGroup = _interopRequireDefault(require("./MyTaskDetailFilterGroup"));
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _compliance = require("@/api/compliance");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MyTaskDetailContent',
  components: {
    MyTaskDetailFilterGroup: _MyTaskDetailFilterGroup.default,
    ComplianceBaseTable: _ComplianceBaseTable.default,
    NoData: _NoData.default,
    ExportButton: _ExportButton.default
  },
  mixins: [_complianceTableMixin.default],
  props: {
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    scoreRuleList: {
      type: Array,
      default: () => []
    },
    evaluationItemList: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    },
    tableHeader: {
      type: Array,
      default: () => [{
        label: '会话名称',
        prop: 'conv_name',
        width: 260
      }, {
        label: '会话时间',
        prop: 'conversation_time',
        width: 160
      }, {
        label: '质检状态',
        prop: 'status',
        width: 160
      }]
    }
  },
  data() {
    return {
      pageSize: [10, 20, 30, 40],
      filtersObj: {},
      viewType: 'table',
      taskId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId']),
    formatComplianceCount() {
      return this.total > 999 ? '999+' : this.total;
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.filtersObj = val;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.taskId = Number(this.$route.query.id);
  },
  methods: {
    exportComplianceMyConversationApi: _compliance.exportComplianceMyConversationApi,
    goComplianceWorkSpace(rowInfo) {
      const obj = (0, _commonFunc.deepClone)(this.filtersObj);
      delete obj.page;
      delete obj.size;
      const params = {
        user_ids: {
          tree_ids: [],
          user_ids: [this.userId]
        },
        ...obj
      };
      const query = {
        id: rowInfo.conv_id || this.tableData[0].conv_id,
        task_id: params.task_id,
        sampling_type: this.$route.query.sampling_type || rowInfo.account_sale_conv_id,
        sampling_id: rowInfo.account_sale_conv_id
      };
      this.$emit('goComplianceWorkSpace', {
        params,
        query
      });
    },
    selectView(command) {
      this.viewType = command;
    },
    sortChange(val) {
      this.$emit('sortChange', val);
    },
    handleDistribute() {
      this.$emit('handleDistribute');
    },
    changeFilter(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      if (this.isTodayMission) {
        this.filtersObj.create_time = this.getDate();
      }
      this.$emit('handleFiltersChange', this.filtersObj);
    },
    goCompliancePersonal(rowInfo) {
      this.$emit('goCompliancePersonal', rowInfo);
    },
    sizeChange(size) {
      this.$emit('sizeChange', size);
    },
    currentChange(page) {
      this.$emit('currentChange', page);
    }
  }
};
exports.default = _default;