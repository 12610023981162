"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _vue = require("vue");
var _ToggleTaskHistorySelect = _interopRequireDefault(require("./ToggleTaskHistorySelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  __name: 'TaskResultDetailDrawer',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    currentTaskInfo: {
      type: Object,
      default: () => ({})
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const componentName = (0, _vue.computed)(() => {
      let component;
      switch (props.currentTaskInfo.courseType) {
        case 3:
          component = () => Promise.resolve().then(() => _interopRequireWildcard(require('./SmartTrainingCourseTask.vue')));
          break;
        case 5:
        case 6:
        case 7:
          component = () => Promise.resolve().then(() => _interopRequireWildcard(require('./AiCustomerCourseTask.vue')));
          break;
        default:
          component = () => Promise.resolve().then(() => _interopRequireWildcard(require('./ReadingAndRecitingCourseTask.vue')));
          break;
      }
      return component;
    });
    const handleClose = () => {
      emit('update:visible', false);
    };
    const taskInfo = (0, _vue.ref)({
      score: 0,
      total: 1,
      complate: 0
    });
    const updateTaskInfo = info => {
      Object.assign(taskInfo.value, info);
    };
    const currentHistoryId = (0, _vue.ref)('');
    const toggleHistory = id => {
      currentHistoryId.value = id;
    };
    const drawerContainer = (0, _vue.ref)(null);
    const scrollToTop = () => drawerContainer.value.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return {
      __sfc: true,
      props,
      emit,
      componentName,
      handleClose,
      taskInfo,
      updateTaskInfo,
      currentHistoryId,
      toggleHistory,
      drawerContainer,
      scrollToTop,
      t: _i18n.t,
      ToggleTaskHistorySelect: _ToggleTaskHistorySelect.default
    };
  }
};
exports.default = _default;