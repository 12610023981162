"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CoachDetailCard = _interopRequireDefault(require("./CoachDetailCard"));
var _NoDataHolder = _interopRequireDefault(require("@/components/NoDataHolder"));
var _coach = require("@/api/coach");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachRecordList',
  components: {
    CoachDetailCard: _CoachDetailCard.default,
    NoDataHolder: _NoDataHolder.default
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    userId: Number,
    visible: {
      type: Boolean,
      default: false
    },
    coachDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      recordList: [],
      totalCount: 0
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getRecordList();
        } else {
          this.resetData();
        }
      },
      immediate: true
    }
  },
  methods: {
    async getRecordList() {
      const params = {
        user_id: this.userId,
        coach_date: this.coachDate
      };
      const res = await (0, _coach.getCoachRecord)(params);
      this.$emit('requestSucceed');
      if (res.code === 20000) {
        const {
          count,
          datas
        } = res.results;
        this.totalCount = count;
        this.$emit('getCount', this.totalCount);
        this.recordList = this.formatData(datas);
        this.scrollToHighlightItem();
      }
    },
    formatData(val) {
      return val.map(item => {
        const {
          content,
          date
        } = item;
        const coach_info = [{
          content,
          date
        }];
        return {
          coach_info,
          ...item
        };
      });
    },
    resetData() {
      this.recordList = [];
    },
    scrollToHighlightItem() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs['highlight'] && this.$refs['highlight'][0]) {
            this.$refs['highlight'][0].$el.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 200);
      });
    }
  }
};
exports.default = _default;