"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CourseInfo = _interopRequireDefault(require("./components/CourseInfo"));
var _CourseScript = _interopRequireDefault(require("./components/CourseScript"));
var _CourseTips = _interopRequireDefault(require("./components/CourseTips"));
var _store = _interopRequireDefault(require("@/store"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CourseEdit',
  components: {
    CourseInfo: _CourseInfo.default,
    CourseScript: _CourseScript.default,
    CourseTips: _CourseTips.default
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('training/SET_KNOWLEDGE_SCRIPT', []);
    next();
  },
  data() {
    return {
      scriptVisible: false,
      checkedKnowledgeScripts: [],
      loading: false,
      type: 0,
      imgSrc: require('@/assets/coach/courseCreate.png')
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript']),
    title() {
      const courseType = [this.$t('coaching.all'), this.$t('coaching.scriptReading'), this.$t('coaching.scriptRecitation')];
      const operate = this.$route.query.id ? '编辑' : '新建';
      return `${operate}课程-${courseType[this.type]}`;
    },
    courseDescription() {
      const rule = this.type === 1 ? '朗读' : '背诵';
      return `您可以在课程中添加话术，并将课程通过任务派发给销售，要求其${rule}话术`;
    }
  },
  created() {
    this.type = Number(this.$route.query.type);
  },
  methods: {
    addScript() {
      setTimeout(() => {
        this.$refs.courseScript.$refs.searchInput.focus();
      }, 300);
    },
    scrollToScriptEnd() {
      this.$refs['course-info'].scrollToCourseInfoEnd();
    },
    goBack() {
      var _this$checkedKnowledg;
      if ((_this$checkedKnowledg = this.checkedKnowledgeScript) !== null && _this$checkedKnowledg !== void 0 && _this$checkedKnowledg.length || this.$refs['course-info'].courseInfoConfig.name) {
        this.$confirm('更改的内容不会保存，是否退出', this.$t('coaching.prompt'), {
          confirmButtonText: '退出',
          cancelButtonText: this.$t('coaching.cancel')
        }).then(() => {
          this.linkTo();
        });
      } else {
        this.linkTo();
      }
    },
    linkTo() {
      this.$router.go(-1);
      _store.default.commit('training/SET_KNOWLEDGE_SCRIPT', []);
    }
  }
};
exports.default = _default;