"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.isEdit ? _c("div", [_vm.value && _vm.value.length ? _c("el-collapse", {
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: _vm.label,
      name: "Alan"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.value,
      border: ""
    }
  }, _vm._l(_vm.table, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        prop: item.key,
        label: item.value,
        resizable: false,
        width: "299"
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1) : _c("div", {
    attrs: {
      id: "replace-item"
    }
  }, [_vm._v("不支持编辑")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;