"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$currentDataSet, _setup$currentDataSet2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "data-set"
  }, [_c("header", {
    staticClass: "data-set__header"
  }, [_vm._v("数据集管理")]), _c(_setup.DataSetFilter, {
    on: {
      handleFilterChange: _setup.filterChange,
      importData: _setup.openImportDataDialog,
      createDataSet: _setup.createDataSet
    }
  }), _c(_setup.DataSetTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableResults.loading,
      expression: "tableResults.loading"
    }],
    attrs: {
      "table-data": _setup.tableResults.tableData
    },
    on: {
      importData: _setup.openImportDataDialog,
      copyDataSet: _setup.openCopyDataSetDialog,
      deleteDataSet: _setup.onDeleteDataSet,
      editDataSet: _setup.editDataSet,
      goAnnotate: _setup.goAnnotatePage,
      sortChange: _setup.sortChange,
      trainingModel: _setup.trainingModel,
      testModel: _setup.testModel,
      clickLabel: _setup.openAnnotateResultsDrawer,
      refreshCurrentPage: _setup.getDataSetListApi
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _setup.paginationParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _setup.paginationParams.size,
      total: _setup.tableResults.total
    },
    on: {
      "size-change": _setup.sizeChange,
      "current-change": _setup.currentChange
    }
  })], 1), _setup.importDataDialogVisible ? _c(_setup.ImportDataDialog, {
    attrs: {
      visible: _setup.importDataDialogVisible,
      "data-set-id": _setup.currentDataSet.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.importDataDialogVisible = $event;
      },
      importSuccess: _setup.refreshTable
    }
  }) : _vm._e(), _setup.copyDataSetDialogVisible ? _c(_setup.CopyDataSetDialog, {
    attrs: {
      visible: _setup.copyDataSetDialogVisible,
      "data-set-id": _setup.currentDataSet.id,
      "data-set-name": _setup.currentDataSet.name,
      "folder-id": (_setup$currentDataSet = _setup.currentDataSet) === null || _setup$currentDataSet === void 0 ? void 0 : (_setup$currentDataSet2 = _setup$currentDataSet.folder) === null || _setup$currentDataSet2 === void 0 ? void 0 : _setup$currentDataSet2.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.copyDataSetDialogVisible = $event;
      },
      refreshTable: _setup.refreshTable
    }
  }) : _vm._e(), _setup.createDataSetDialogVisible ? _c("div", [_c(_setup.CreateDataSetDialog, {
    attrs: {
      visible: _setup.createDataSetDialogVisible,
      "current-data-set": _setup.currentDataSet
    },
    on: {
      "update:visible": function ($event) {
        _setup.createDataSetDialogVisible = $event;
      },
      importData: _setup.openImportDataDialog,
      refreshTable: _setup.refreshTable
    }
  })], 1) : _vm._e(), _c(_setup.TrainModelDialog, {
    attrs: {
      visible: _setup.trainModelDialogVisible,
      "data-set-id": _setup.currentDataSet.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.trainModelDialogVisible = $event;
      },
      submitSuccess: _setup.getDataSetListApi
    }
  }), _c(_setup.TestModelDialog, {
    attrs: {
      visible: _setup.testModelDialogVisible,
      "data-set-id": _setup.currentDataSet.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.testModelDialogVisible = $event;
      }
    }
  }), _setup.annotateResultsDrawerVisible ? _c(_setup.AnnotateResultsDrawer, {
    attrs: {
      "drawer-visible": _setup.annotateResultsDrawerVisible,
      "data-set-id": _setup.currentDataSet.id,
      "data-set-name": _setup.currentDataSet.name,
      "external-label": _setup.activeTabs
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.annotateResultsDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.annotateResultsDrawerVisible = $event;
      }
    }
  }) : _vm._e(), _c(_setup.MegaTranGuide)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;