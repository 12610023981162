"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "smart-traning-edit"
  }, [_c("header", [_c("div", {
    staticClass: "left-part"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    attrs: {
      icon: "iconfont icon-arrow-left"
    },
    on: {
      click: _setup.goBack
    }
  }), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t(_setup.isEdit ? "coaching.editCourse" : "coaching.newCourse")) + "-" + _vm._s(_vm.$t("coaching.aICustomer")))]), _c("el-tooltip", {
    attrs: {
      content: "AI客户会跟据您选择的知识库话术，与销售进行对话，发起提问并评分。"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.updateCourseNameLoading,
      expression: "updateCourseNameLoading"
    }],
    staticClass: "update-name-loading"
  })], 1), _c("div", {
    staticClass: "center"
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_setup.isEditTitle,
      expression: "!isEditTitle"
    }],
    staticClass: "title"
  }, [_vm._v(_vm._s(_setup.title) + " "), _c("el-button", {
    staticClass: "hover-show-bg-btn",
    attrs: {
      icon: "iconfont icon-edit-outline"
    },
    on: {
      click: _setup.editTitle
    }
  })], 1), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.isEditTitle,
      expression: "isEditTitle"
    }],
    ref: "titleInputRef",
    attrs: {
      placeholder: "请输入课程名称",
      maxlength: "50"
    },
    on: {
      change: _setup.modifyTitle
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _setup.modifyTitle.apply(null, arguments);
      }
    },
    model: {
      value: _setup.title,
      callback: function ($$v) {
        _setup.title = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "title"
    }
  })], 1)]), _c("article", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "info-container"
  }, [_c("div", {
    staticClass: "scroll-container"
  }, [_c("div", {
    staticClass: "card-conatainer"
  }, [_c("p", {
    staticClass: "description"
  }, [_vm._v("使用说明")]), _c("div", {
    staticClass: "description-container"
  }, [_vm._l(["添加对话场景", "为对话场景添加话术", "生成模型，可进行对话测试及创建任务"], function (item, index) {
    return [_c("div", {
      key: index,
      staticClass: "description-card-item"
    }, [_c("span", [_vm._v(_vm._s(item))]), _c("span", [_vm._v("step" + _vm._s(index + 1))])]), index !== 2 ? _c("img", {
      key: index,
      attrs: {
        src: require("@/assets/coach/arrow.png"),
        alt: "arrow"
      }
    }) : _vm._e()];
  })], 2)]), _c("div", {
    staticClass: "card-conatainer"
  }, [_vm._m(0), _c(_setup.DialogueScenarioList, {
    ref: "dialogueScenarioListRef",
    attrs: {
      "backfill-scene-list": _setup.backfillSceneList
    },
    on: {
      openAddScriptDialog: _setup.openAddScriptDialog
    }
  }), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.sceneErrorMessage.length,
      expression: "sceneErrorMessage.length"
    }],
    ref: "sceneErrorMessageRef",
    staticClass: "scene-error-message"
  }, [_vm._v(" " + _vm._s(_setup.sceneErrorMessage) + " ")])], 1), _c("div", {
    staticClass: "card-conatainer"
  }, [[_c("p", {
    staticClass: "title"
  }, [_vm._v("对话设置")]), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v("开场旁白说明")]), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      maxlength: "100",
      resize: "none",
      placeholder: "在对练页面向学员介绍客户信息、沟通目标"
    },
    model: {
      value: _setup.courseConfig.scene,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "scene", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "courseConfig.scene"
    }
  })], [_c("p", {
    staticClass: "sub-title"
  }, [_vm._v("开场内容预设")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    on: {
      change: _setup.toggleSpeakerParty
    },
    model: {
      value: _setup.courseConfig.speaker_party,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "speaker_party", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "courseConfig.speaker_party"
    }
  }, [_vm._v("AI客户先说")]), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      maxlength: "300",
      resize: "none",
      placeholder: "请输入内容"
    },
    model: {
      value: _setup.courseConfig.content,
      callback: function ($$v) {
        _vm.$set(_setup.courseConfig, "content", $$v);
      },
      expression: "courseConfig.content"
    }
  })]], 2), _c(_setup.CustomerPersona, {
    ref: "CustomerPersonaRef",
    staticClass: "card-conatainer",
    attrs: {
      saved: _setup.saved,
      "backfill-customer-persona-list": _setup.backfillCustomerPersonaList
    },
    on: {
      "update:saved": function ($event) {
        _setup.saved = $event;
      }
    }
  })], 1)]), _c("footer", [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.submitLoading
    },
    on: {
      click: _setup.saveCourse
    }
  }, [_vm._v("生成客户模型")])], 1)]), _c(_setup.TrainingTestDrawer, {
    ref: "trainingTestDrawerRef",
    attrs: {
      "drawer-visible": _setup.trainingTestDrawerVisible,
      "course-id": _setup.courseId
    }
  })], 1), _c(_setup.AddScriptDialog, {
    attrs: {
      visible: _setup.addScriptDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.addScriptDialogVisible = $event;
      },
      change: _setup.changeKnowledgeList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "title"
  }, [_vm._v("对话场景"), _c("span", {
    staticClass: "require-icon"
  }, [_vm._v("*")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;