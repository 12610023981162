"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _index = _interopRequireDefault(require("./components/NavBar/index"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Layout',
  components: {
    Sidebar: _components.Sidebar,
    AppMain: _components.AppMain,
    AppNavbar: _index.default
  },
  mixins: [_ResizeHandler.default],
  data() {
    return {
      hasNav: false,
      navTitle: ''
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        this.hasNav = Boolean(to.meta.has_navbar);
        this.navTitle = to.meta.title;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClickOutside() {
      // this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    }
  }
};
exports.default = _default;