"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HitTag = _interopRequireDefault(require("./HitTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'HitTagDropdown',
  props: {
    status: {
      type: Number,
      default: 1
    }
  },
  emits: ['change', 'update:status'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onCommand = command => {
      if (command === props.status) return;
      emit('update:status', command);
      emit('change', command);
    };
    return {
      __sfc: true,
      props,
      emit,
      onCommand,
      HitTag: _HitTag.default
    };
  }
};
exports.default = _default;