"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "videoWrapper",
    staticClass: "di video-wrap"
  }, [_vm.mediaType !== "doc" && _vm.hasVideo ? _c("div", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isControlsShow,
      expression: "isControlsShow"
    }],
    staticClass: "video-control-container"
  }, [_c("div", {
    staticClass: "control-strip"
  }, [_c("span", {
    staticClass: "time start-time"
  }, [_vm._v(_vm._s(_vm._f("formatSecond")(_vm.video.currentTime)))]), _vm.isSnippetStatus ? _c("div", {
    staticClass: "clip-group-wrapper"
  }, [_c("el-slider", {
    staticClass: "base-slider clip-slider-control",
    attrs: {
      "format-tooltip": _vm.formatProcessToolTip,
      step: 0.01
    },
    on: {
      "!change": function ($event) {
        return _vm.changeCurrentTime.apply(null, arguments);
      },
      input: _vm.inputFun
    },
    model: {
      value: _vm.sliderTime,
      callback: function ($$v) {
        _vm.sliderTime = $$v;
      },
      expression: "sliderTime"
    }
  }), _c("el-slider", {
    staticClass: "base-slider clip-slider",
    attrs: {
      value: _vm.sliderTimeRange,
      range: "",
      "format-tooltip": _vm.formatProcessToolTip,
      step: 0.01
    },
    on: {
      "!change": function ($event) {
        return _vm.snippetVideoSliderChange.apply(null, arguments);
      },
      input: _vm.handleSliderInput,
      click: () => false
    }
  })], 1) : _c("div", {
    staticClass: "base-control"
  }, [_c("div", {
    staticClass: "set-height"
  }), _c("el-progress", {
    staticClass: "progress",
    attrs: {
      percentage: _vm.buffTime,
      "show-text": false
    }
  }), _c("el-slider", {
    staticClass: "base-slider",
    attrs: {
      "format-tooltip": _vm.formatProcessToolTip,
      step: 0.01
    },
    on: {
      change: _vm.changeCurrentTime,
      input: _vm.inputFun
    },
    model: {
      value: _vm.sliderTime,
      callback: function ($$v) {
        _vm.sliderTime = $$v;
      },
      expression: "sliderTime"
    }
  })], 1), _c("span", {
    staticClass: "time end-time"
  }, [_vm._v(_vm._s(_vm._f("formatSecond")(_vm.video.maxTime)))])]), _c("div", {
    staticClass: "control-main"
  }, [_c("div", {
    staticClass: "player-buttons"
  }, [_c("el-popover", {
    staticClass: "backrate",
    attrs: {
      "popper-class": "backrate-popper",
      placement: "top",
      width: "80",
      trigger: "hover",
      "visible-arrow": false
    },
    model: {
      value: _vm.backrateVisible,
      callback: function ($$v) {
        _vm.backrateVisible = $$v;
      },
      expression: "backrateVisible"
    }
  }, [_vm._l(_vm.backrates, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "option-standard backrate-option",
      class: _vm.currBackRate === item ? ["curr-backrate", "option-standard-selected"] : "",
      on: {
        click: function ($event) {
          return _vm.changeBackrate(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + "X ")]);
  }), _c("el-button", {
    staticClass: "control-btn",
    attrs: {
      slot: "reference",
      type: "text"
    },
    slot: "reference"
  }, [_vm._v(" " + _vm._s(_vm.currBackRate === 1 ? "倍速" : `${_vm.currBackRate}X`) + " ")])], 2), _c("el-button", {
    staticClass: "refresh-left",
    attrs: {
      icon: "iconfont icon-refresh-left-151"
    },
    on: {
      click: _vm.rewind
    }
  }), _c("div", {
    staticClass: "play-button"
  }, [_vm.video.playing && _vm.canPlay ? _c("div", {
    staticClass: "play-btn-center",
    on: {
      click: _vm.startPlayOrPause
    }
  }, [_c("i", {
    staticClass: "iconfont icon-video-pause-soild"
  })]) : !_vm.video.playing && _vm.canPlay ? _c("div", {
    staticClass: "play-btn-center",
    on: {
      click: _vm.startPlayOrPause
    }
  }, [_c("i", {
    staticClass: "iconfont icon-video-play-soild"
  })]) : !_vm.canPlay ? _c("div", {
    staticClass: "play-btn-center"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "warning-outline"
    }
  })], 1) : _vm._e()]), _c("el-button", {
    staticClass: "refresh-right",
    attrs: {
      icon: "iconfont icon-refresh-right-151"
    },
    on: {
      click: _vm.forward
    }
  }), _c("el-popover", {
    attrs: {
      "popper-class": "voice-popper video-controls-popper",
      placement: "top",
      width: "55",
      title: _vm.voice + "%",
      trigger: "hover",
      "visible-arrow": false
    }
  }, [_c("el-slider", {
    attrs: {
      "show-tooltip": false,
      vertical: "",
      height: "106px"
    },
    on: {
      input: _vm.handleVolumeSlider
    },
    model: {
      value: _vm.voice,
      callback: function ($$v) {
        _vm.voice = $$v;
      },
      expression: "voice"
    }
  }), _c("el-button", {
    staticClass: "control-btn",
    attrs: {
      slot: "reference",
      type: "text"
    },
    on: {
      click: _vm.changeIsMuted
    },
    slot: "reference"
  }, [_c("i", {
    class: ["iconfont", `icon-${_vm.voice ? "volume" : "volume-off"}`]
  })])], 1)], 1)]), _c("div", {
    staticClass: "show-audio-line-button",
    on: {
      click: _vm.switchAudioLine
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.isShow ? "收起" : "展开"))]), _c("i", {
    class: _vm.isShow ? "el-icon-caret-bottom" : "el-icon-caret-right"
  })])])]) : _vm._e(), _c("div", {
    ref: "lineWrap",
    staticClass: "line-wrapper",
    class: {
      "has-hang-up-content": _vm.hangUp !== 0
    },
    style: {
      height: _vm.getLineWrapperHeight,
      "padding-top": _vm.isShow ? "16px" : 0
    }
  }, [_c("el-slider", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.audio,
      expression: "audio"
    }],
    class: ["analyze-control", {
      "prevent-click": _vm.mediaType === "doc"
    }],
    style: {
      height: 50 * (_vm.getPresentLength + (_vm.localShareScreen.length > 0 ? 1 : 0)) + "px"
    },
    attrs: {
      "show-tooltip": false,
      step: 0.01
    },
    on: {
      "!change": function ($event) {
        return _vm.changeCurrentTime.apply(null, arguments);
      }
    },
    model: {
      value: _vm.sliderTime,
      callback: function ($$v) {
        _vm.sliderTime = $$v;
      },
      expression: "sliderTime"
    }
  }), _c("div", {
    staticClass: "mark-content"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.audio && _vm.mediaType !== "doc",
      expression: "audio && mediaType !== 'doc'"
    }],
    staticClass: "mark",
    class: {
      "no-no-speak-time": !_vm.maxSilenceDuration.duration
    },
    style: {
      left: _vm.sliderTime - 1 > 0 ? _vm.sliderTime - 1 + "%" : _vm.sliderTime + "%",
      height: 48 * (_vm.getPresentLength + (_vm.localShareScreen.length > 0 ? 1 : 0)) + "px"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.maxSilenceDuration.duration && _vm.mediaType !== "doc",
      expression: "maxSilenceDuration.duration && mediaType !== 'doc'"
    }],
    staticClass: "silence-mark",
    style: {
      left: _vm.maxSilenceDuration.begin_percent + "%"
    }
  }, [_c("span", {
    staticClass: "silence-tag",
    class: {
      "text-left": _vm.maxSilenceDuration.begin_percent > 80
    }
  }, [_vm._v(_vm._s(Math.round(_vm.maxSilenceDuration.duration)) + "秒内无人发言")])])]), _vm._l(_vm.audioList, function (item, index) {
    return _c("div", {
      key: item.participantId,
      staticClass: "audio-line-item",
      class: {
        "no-no-speak-time": !_vm.maxSilenceDuration.duration
      }
    }, [_c("div", {
      staticClass: "audio-top"
    }, [_c("div", {
      staticClass: "audio-title"
    }, [_vm.origin !== "upload" || _vm.origin == "upload" && _vm.hasRole && item.id !== 0 || _vm.origin == "upload" && _vm.hasRole && _vm.peopleFlag ? _c("span", {
      staticClass: "role-info"
    }, [_c("span", {
      staticClass: "role-type"
    }, [_vm._v(_vm._s(_vm.getTypeStr(item.type, index)))]), _c("div", {
      staticClass: "role-avatar"
    }, [_c("image-avatar", {
      attrs: {
        avatar: _vm.handleAvatar(item.id, item.type),
        name: item.name,
        type: item.entityType
      }
    }), _c("span", {
      staticClass: "role-name"
    }, [_vm._v(_vm._s(item.name ? item.name : "未知姓名"))])], 1)]) : _c("span", {
      staticStyle: {
        display: "flex"
      }
    }, [_vm.origin == "upload" && _vm.hasRole ? _c("span", [_vm._v(_vm._s(_vm.getTypeStr(item.entityType)) + ":")]) : _vm._e(), _c("identity-model", {
      ref: "popover" + index,
      refInFor: true,
      attrs: {
        id: item.participantId,
        index: index,
        "bind-name": item.name,
        "audio-list": _vm.audioList,
        "active-round-id": _vm.activeRoundId,
        "upload-type": _vm.uploadType,
        "people-type": item.entityType,
        "has-role": _vm.hasRole,
        entity_id: item.entity_id,
        "sumbit-visible": _vm.sumbitVisible
      },
      on: {
        select: _vm.handleSelect
      }
    })], 1)]), _c("div", {
      staticClass: "audio-wrap",
      class: {
        "has-hang-up-content": _vm.hangUp !== 0
      }
    }, [_c("audio-line", {
      attrs: {
        items: item.detail,
        color: item.color,
        "max-silence-duration": item.maxSilenceDuration,
        "is-single": ""
      }
    })], 1), _c("div", {
      staticClass: "audio-line-right"
    }, [_vm.hangUp !== 0 ? _c("div", {
      staticClass: "hang-up-content"
    }, [_vm.isHangUpPerson(item.type) ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "挂断方",
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-phone-hangup-2"
    })]) : _vm._e()], 1) : _vm._e(), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.audio,
        expression: "audio"
      }],
      staticClass: "audio-percent"
    }, [_vm._v(_vm._s(item.percent + "%"))])])])]);
  }), _c("novice-guide"), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localShareScreen && _vm.localShareScreen.length > 0,
      expression: "localShareScreen && localShareScreen.length > 0"
    }],
    staticClass: "audio-line-item"
  }, [_c("div", {
    staticClass: "audio-top"
  }, [_c("div", {
    staticClass: "audio-title"
  }, [_vm._v("屏幕共享:")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sharePercent,
      expression: "sharePercent"
    }],
    staticClass: "audio-percent"
  }, [_vm._v(_vm._s(_vm.sharePercent + "%"))])]), _c("div", {
    staticClass: "audio-wrap"
  }, [_c("audio-line", {
    attrs: {
      items: _vm.getScreenInfo,
      "is-share": ""
    }
  })], 1)])], 2), _c("sumbit-dialog", {
    attrs: {
      "modify-arr": _vm.modifyArr,
      "active-round-id": _vm.activeRoundId,
      "audio-list": _vm.audioList,
      "sumbit-visible": _vm.sumbitVisible
    },
    on: {
      changeSumbitVisible: _vm.changeSumbitVisible
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;