"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-tooltip", {
    attrs: {
      content: _setup.tooltipContent,
      placement: "top"
    }
  }, [_c("el-upload", {
    attrs: {
      action: "#",
      "show-file-list": false,
      "http-request": _setup.uploadRequest,
      accept: "image/jpeg, image/jpg, image/png, image/gif",
      "on-success": _setup.handleUploadSuccess,
      disabled: _vm.disabled,
      "before-upload": _setup.beforeUpload
    }
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-picture-outline",
      square: "",
      size: "small",
      disabled: _vm.disabled,
      color: "info transparent",
      type: "text"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;