"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filterSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/filterSelect"));
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _workspace = require("@/api/workspace");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BI',
  components: {
    BiFilter: _filterSelect.default,
    MenuToggleBtn: _MenuToggleBtn.default,
    SpaceButton: _SpaceButton.default
  },
  provide() {
    return {
      getBiFilters: this.getBiFilters
    };
  },
  data() {
    return {
      biFilters: {},
      showDataPickerTooltip: false,
      isHiddenMenu: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'routesTree', 'workspaceInfo']),
    biMenuList() {
      var _deepClone, _deepClone$find;
      const newBiMenuList = (_deepClone = (0, _commonFunc.deepClone)(this.routesTree)) === null || _deepClone === void 0 ? void 0 : (_deepClone$find = _deepClone.find(item => item.path === '/bi')) === null || _deepClone$find === void 0 ? void 0 : _deepClone$find.children.filter(item => item.meta.order);
      if (!newBiMenuList) {
        return [];
      }
      // 单独处理总览模块层级关系
      const BiOverview = newBiMenuList.find(_ref => {
        let {
          name
        } = _ref;
        return name === 'BiOverview';
      });
      if (BiOverview) {
        delete BiOverview.children;
      }
      this.sortMenu(newBiMenuList);
      return newBiMenuList;
    },
    defaultOpeneds() {
      const openedMenu = [];
      const traverseMenuList = node => {
        node.forEach(_ref2 => {
          let {
            link,
            children
          } = _ref2;
          openedMenu.push(link);
          if (children) {
            traverseMenuList(children);
          }
        });
      };
      traverseMenuList(this.biMenuList);
      return openedMenu;
    },
    hasDealCustomFieldFilter() {
      return [
      // 会话分析
      _routeMap.ROUTES.biActivity,
      // 会话活动
      _routeMap.ROUTES.biDimension,
      // 会话维度
      _routeMap.ROUTES.biKeyEvent,
      // 关键事件排行
      _routeMap.ROUTES.biStatement,
      // 关键事件分布
      _routeMap.ROUTES.biBusinessAnalysis,
      // 执行力对比
      _routeMap.ROUTES.biSOPAnalysis,
      // 客户触达情况
      _routeMap.ROUTES.biSOPSalesPath,
      // 业务路径
      _routeMap.ROUTES.biSOPFunnel,
      // 业务转化卡点分析
      // 客户分析
      _routeMap.ROUTES.biIntentAnalysis,
      // 客户评级
      // 团队全景
      _routeMap.ROUTES.biTeamAnalysis // 团队概览
      ].includes(this.$route.path);
    },
    getTabTitle() {
      return this.$route.meta.subtitle;
    },
    layout() {
      let layout = [];
      switch (this.$route.path) {
        case _routeMap.ROUTES.biActivity:
          // 会话活动
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["conversation"]'
            }
          }, {
            type: 'date',
            tooltip: this.biFilters.entity_type === 'deal' ? '交易创建时间' : '会话时间',
            showTooltip: this.showDataPickerTooltip
          }, {
            type: 'conversation_type'
          }, {
            type: 'conversation_scene_type'
          }, {
            type: 'entity_type'
          }, {
            type: 'exportCsv'
          }];
          break;
        case _routeMap.ROUTES.biDimension:
          // 会话维度
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["conversation"]'
            }
          }, {
            type: 'date',
            tooltip: this.biFilters.entity_type === 'deal' ? '交易创建时间' : '会话时间',
            showTooltip: this.showDataPickerTooltip
          }, {
            type: 'conversation_type'
          }, {
            type: 'entity_type'
          }, {
            type: 'exportCsv'
          }];
          break;
        case _routeMap.ROUTES.biStatement:
          // 关键事件分布（分析报表）
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["conversation"]'
            }
          }, {
            type: 'date',
            tooltip: this.biFilters.entity_type === 'deal' ? '交易创建时间' : '会话时间',
            showTooltip: this.showDataPickerTooltip
          }, {
            type: 'entity_type'
          }];
          break;
        case _routeMap.ROUTES.biKeyEvent:
          // 关键事件排行(关键事件)
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true
          }, {
            type: 'date',
            tooltip: this.biFilters.analysis_basis === 'conversation' ? '会话时间' : '交易创建时间'
          }, {
            type: 'analysis_basis',
            customClass: 'flex-end-radio-group'
          }];
          break;
        case _routeMap.ROUTES.biBusinessAnalysis:
          // 执行力对比(原业务分析)
          layout = [{
            type: 'tree_ids',
            strictly: true,
            isStricklyDisabled: true,
            params: {
              type: '["conversation"]'
            }
          }, {
            type: 'date',
            tooltip: '会话时间'
          }, {
            type: 'event_id',
            isStrictly: true
          }];
          break;
        case _routeMap.ROUTES.biTeamAnalysis:
          // 团队全景-团队概览
          layout = [{
            type: this.$route.query.type === 'member' ? 'tree_user_ids' : 'tree_ids',
            isSetCheckedRoot: this.$route.query.type === 'member',
            params: {
              type: '["conversation", "trade"]'
            }
          }, {
            type: 'date',
            tooltip: this.biFilters.analysis_basis === 'deal' ? '交易创建时间' : '会话时间'
          }, {
            type: 'analysis_basis',
            hasDealCustomFieldFilter: true,
            contentList: [`按会话分析是指分析的基础是会话。当分析指标中有交易时，通过筛选出的会话所关联的交易进行分析。例如：可以筛选出最近三个月的会话以及该会话所关联的交易，对上述筛选结果进行相关指标的统计、展示。`, `按交易分析是指分析的基础是交易。当分析指标中有会话时，通过筛选出的交易所关联的会话进行分析。例如：可以筛选出最近三个月的交易以及该交易下的所有会话，对上述筛选结果进行相关指标的统计、展示。`]
          }];
          break;
        case _routeMap.ROUTES.biSOPAnalysis:
          // 客户触达情况
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["trade"]'
            }
          }, {
            type: 'date',
            tooltip: this.biFilters.analysis_basis === 'conversation' ? '会话时间' : '交易创建时间'
          }, {
            type: 'event_list',
            placeholder: '分析指标',
            isMultiple: true,
            tooltip: '分析指标'
          }, {
            type: 'exclude_event_list',
            label: this.biFilters.analysis_basis === 'conversation' ? '排除会话' : '排除交易',
            tooltip: this.biFilters.analysis_basis === 'conversation' ? '排除会话' : '排除交易'
          }, {
            type: 'analysis_basis',
            hasDealCustomFieldFilter: true
          }];
          break;
        case _routeMap.ROUTES.biSOPComparison:
          // 客户触达对比
          layout = [{
            type: 'select_department_member_type',
            params: {
              type: '["trade"]'
            }
          }, {
            type: 'date',
            tooltip: '交易创建时间'
          }];
          break;
        case _routeMap.ROUTES.biSOPSalesPath:
          // 业务路径
          layout = [{
            type: 'tree_ids',
            params: {
              type: '["trade"]'
            },
            setCheckedRootNode: true
          }, {
            type: 'date',
            tooltip: '交易创建时间'
          }, {
            type: 'conversation_counts',
            tooltip: '会话次数'
          }, {
            type: 'node_counts',
            tooltip: '节点数'
          }];
          break;
        case _routeMap.ROUTES.biSOPFunnel:
          // 业务转化卡点分析
          layout = [{
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["conversation"]'
            }
          }, {
            type: 'date',
            tooltip: '交易创建时间'
          }];
          break;
        case _routeMap.ROUTES.biTalentReview:
          // 销售人才盘点（改名，原销售画像)
          layout = [{
            type: 'tree_ids',
            params: {
              type: '["trade", "conversation"]'
            }
          }, {
            type: 'date',
            tooltip: '会话时间'
          }];
          break;
        case _routeMap.ROUTES.biIntentAnalysis:
          // 客户评级(客户画像)
          layout.push({
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["trade", "conversation"]'
            }
          }, {
            type: 'date',
            tooltip: '交易创建时间'
          });
          break;
        case _routeMap.ROUTES.biPersona:
          // 客户群画像
          layout.push({
            type: 'tree_user_ids',
            isSetCheckedRoot: true,
            params: {
              type: '["trade"]'
            }
          }, {
            type: 'date',
            tooltip: '交易创建时间'
          }, {
            type: 'trade_status',
            tooltip: '商机阶段'
          });
          break;
        default:
          break;
      }
      return layout;
    },
    tabArray() {
      const secondMenu = this.$route.path.split('/').slice(0, -1).join('/');
      const res = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: secondMenu,
        targetAttr: 'link',
        getAttr: 'children'
      });
      if (res && res.length) {
        return res.sort((a, b) => a.meta.order - b.meta.order);
      }
      return [];
    },
    biTab: {
      get() {
        return this.$route.path.split('/').pop();
      },
      set() {}
    }
  },
  watch: {
    workspaceInfo() {
      this.getBiDatas();
    }
  },
  async created() {
    const urlParamsWorkspaceId = this.$route.query.workspaceId;
    let workspaceItem;
    if (urlParamsWorkspaceId) {
      try {
        const res = await (0, _workspace.switchWorkspace)({
          id: Number(urlParamsWorkspaceId)
        });
        workspaceItem = res.results;
        this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
      } catch (error) {
        const isExist = await (0, _workspace.judgeWorkspaceExist)(Number(urlParamsWorkspaceId));
        if (!isExist.results.exists) {
          workspaceItem = this.workspaceList[0];
          this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
        }
      }
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
    }
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('biFilter');
    next();
  },
  methods: {
    updateIsHiddenMenu(val) {
      this.isHiddenMenu = val;
    },
    toggleMenu() {
      this.$refs['nav-menu'].toggleMenu();
    },
    floatingMenuShow() {
      this.$refs['nav-menu'].floatingMenuShow();
    },
    floatingMenuHidden() {
      this.$refs['nav-menu'].floatingMenuHidden();
    },
    sortMenu(list) {
      list.sort((a, b) => a.meta.order - b.meta.order);
      list.forEach(_ref3 => {
        let {
          children
        } = _ref3;
        if (children) {
          this.sortMenu(children);
        }
      });
    },
    recursionGet: _commonFunc.recursionGet,
    dataPickerTooltipChange() {
      clearTimeout(this.timer);
      this.showDataPickerTooltip = true;
      // 会话筛选条件(商机/会话)切换时展示对应tooltip且延迟两秒再消失
      this.timer = setTimeout(() => {
        this.showDataPickerTooltip = false;
      }, 2000);
    },
    getBiFilters() {
      return this.biFilters;
    },
    handleTabClick(tab) {
      const secondMenu = this.$route.path.split('/').slice(0, -1).join('/');
      this.$router.replace({
        path: `${secondMenu}/${tab.name}`
      });
    },
    filtersChange(filters) {
      // 临时方案  把筛选项存起来
      if (filters.date) {
        const date = (0, _timezone.datePickerConvertToZone)(JSON.parse(filters.date), true);
        this.$store.commit('bi/SET_BI_PERSONAL_TIME_FILTER', [date[0], date[1]]);
      }
      this.biFilters = filters;
      // 会话活动
      // this.$store.commit('bi/SET_ACTIVITY_OBJ');
      // // 关键事件
      // this.$store.commit('bi/SET_KEYEVENT_OBJ');
      // this.$store.commit('bi/SET_STATEMENT_OBJ');
      // 通用
      this.$store.commit('bi/SET_FILTERS_OBJ', filters);
      this.getBiDatas();
    },
    getBiDatas() {
      if (this.biTab === 'activity') {
        this.$store.dispatch('bi/get_activity_obj');
      } else if (this.biTab === 'metrics') {
        this.$store.dispatch('bi/get_dimension_obj');
      } else if (this.biTab === 'bar-chart') {
        this.$store.dispatch('bi/get_keyevent_obj');
      } else if (this.biTab === 'pie-chart') {
        this.$store.dispatch('bi/get_statement_obj');
      }
    }
  }
};
exports.default = _default;