"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _pointDialog = _interopRequireDefault(require("@/components/BusinessIntelligence/pointDialog.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BiChart',
  components: {
    SortCaret: _sortCaret.default,
    ImageAvatar: _ImageAvatar.default,
    pointDialog: _pointDialog.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    keyName: {
      type: String,
      default: ''
    },
    secondLabel: {
      type: String,
      default: '占比'
    },
    infoArray: {
      type: Array,
      default: () => []
    },
    averageValue: {
      type: Object,
      default: () => ({})
    },
    horizontalArray: {
      type: Array,
      default: () => []
    },
    canSelect: {
      type: Boolean,
      default: false
    },
    smallMargin: {
      type: Boolean,
      default: true
    },
    popover: {
      type: Boolean,
      default: false
    },
    popoverFormatter: {
      type: Function,
      default: () => {}
    },
    // 首次进入判断
    contentType: {
      type: String,
      default: ''
    },
    isLabelSilent: {
      type: Boolean,
      default: false
    },
    clickDisabled: {
      type: Boolean,
      default: false
    },
    isShowAverageVerticalLine: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      maxValue: 0,
      activeKey: null,
      eventType: 0,
      isMaxLarger: false,
      chartDataArr: [],
      currentPage: 1,
      pageSize: 30,
      nomore: false,
      sortType: 'descending'
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biKeyeventObj']),
    getChartHeight() {
      return `${36 * this.chartDataArr.length}px`;
    },
    getAverageHeight() {
      return `${36 * this.chartDataArr.length + 36}px`;
    },
    getAverageRight() {
      const AVERAGE_VALUE = this.averageValue.value;
      const MAX_VALUE = this.horizontalArray[this.horizontalArray.length - 1].value;
      const percent = ((MAX_VALUE - AVERAGE_VALUE) / MAX_VALUE).toFixed(2);
      if (Number(percent)) {
        return 'calc((100% - 177px) * ' + percent + ' + 9px)';
      } else {
        return '9px';
      }
    }
  },
  watch: {
    infoArray: {
      handler: function (val) {
        const arr = this.sortByType(val);
        this.chartDataArr = arr.slice(0, this.currentPage * this.pageSize);
        this.setRightChartContentTop();
      }
    }
  },
  mounted() {
    this.setSortDescending();
  },
  methods: {
    setRightChartContentTop() {
      const childItem = document.querySelector('.child-item');
      if (childItem) {
        childItem.scrollTop = 0;
      }
    },
    sortByType(arrData) {
      const tempArr = (0, _commonFunc.deepClone)(arrData);
      const sortFlag = this.sortType === 'ascending' ? 1 : -1;
      const resArr = tempArr.sort((a, b) => (a.value - b.value) * sortFlag);
      return resArr;
    },
    handleSortClick(type) {
      this.sortType = type;
      this.chartDataArr = this.sortByType(this.infoArray).slice(0, this.currentPage * this.pageSize);
    },
    setSortDescending() {
      this.$nextTick(() => {
        if (this.$refs[this.keyName + 'Sort']) {
          this.$refs[this.keyName + 'Sort'].activeStatus = 'descending';
          this.handleSortClick('descending');
        }
      });
    },
    loadMore() {
      if (this.chartDataArr.length < this.infoArray.length) {
        this.currentPage++;
        const arr = this.sortByType(this.infoArray);
        this.chartDataArr = arr.slice(0, this.currentPage * this.pageSize);
      } else {
        this.nomore = true;
      }
    },
    isTooltipDisabled(_ref) {
      let {
        name,
        key
      } = _ref;
      const str = name || key;
      const strArr = str.split('');
      const point = strArr.reduce((count, item) => count += /[\u4e00-\u9fa5]/.test(item) ? 2 : 1, 0);
      if (point === 12 && strArr.every(item => !/[\u4e00-\u9fa5]/.test(item))) {
        return false;
      } else {
        return point < 13;
      }
    },
    selectActiveKey(item) {
      this.activeKey = item.id;
      this.activeName = item.key;
      this.eventType = item.event_type;
      this.$emit('activeKeyChange', this.activeKey, this.activeName, this.eventType);
      //  存储关键事件选择状态
      sessionStorage.setItem('biKeyEventSelectedState', JSON.stringify({
        id: this.activeKey,
        key: this.activeName,
        event_type: this.eventType
      }));
    },
    goBIPerson(_ref2) {
      var _this$$route;
      let {
        id,
        name
      } = _ref2;
      const curRouterPath = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : _this$$route.path.split('/');
      const path = `${_routeMap.ROUTES.biPersonal}/${curRouterPath[curRouterPath.length - 1]}`;
      if (id && name) {
        this.$router.push({
          path: path,
          query: {
            id,
            name
          }
        });
      }
    }
  }
};
exports.default = _default;