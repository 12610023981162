"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UploadFileItem = _interopRequireDefault(require("./UploadFileItem"));
var _vue = require("vue");
var _elementUi = require("element-ui");
var _download = require("@/utils/download");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'IntelligentExtractUploadMedia',
  emits: 'changeFile',
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const ACCEPT_FILE_TYPE = '.doc,.docx,.DOC,.DOCX,.pdf,.PDF,.txt,.TXT,.pptx,.PPTX,.xml,.XML,.text,.TEXT,';
    const uploadFileList = (0, _vue.ref)([]);
    const downloadTemplate = () => {
      (0, _download.downloadByUrl)('https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/knowledge_smart_extract_template.docx');
    };
    const onChange = (_file, fileList) => {
      const file = {
        ..._file,
        state: 'prepare'
      };
      if (file.status !== 'ready') return;
      if (uploadFileList.value.length >= 5) {
        _elementUi.Message.warning('最多选择5个文件');
        return;
      }
      if (file.size / Math.pow(1024, 2) > 5) {
        _elementUi.Message.warning('文件大小不能超过5MB');
        return;
      }
      uploadFileList.value.push(file);
      file.state = 'uploading';
    };
    const deleteFile = file => {
      uploadFileList.value = uploadFileList.value.filter(item => item.uid !== file.uid);
    };
    const changeFileState = ($event, index) => {
      // 上传文件状态
      /**
       * enum uploadState = {
       *    uploading: "上传中",
       *    success: "成功",
       *    fail: "失败",
       * }
       *  */
      uploadFileList.value.splice(index, 1, {
        ...uploadFileList.value[index],
        ...$event
      });
    };
    (0, _vue.watchEffect)(() => {
      emit('changeFile', uploadFileList.value);
    });
    const clearUploadFileList = () => {
      uploadFileList.value = [];
    };
    expose({
      clearUploadFileList
    });
    return {
      __sfc: true,
      ACCEPT_FILE_TYPE,
      uploadFileList,
      downloadTemplate,
      onChange,
      deleteFile,
      changeFileState,
      emit,
      clearUploadFileList,
      UploadFileItem: _UploadFileItem.default
    };
  }
};
exports.default = _default;