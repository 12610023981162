"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UploadConversationDialog = _interopRequireDefault(require("./UploadConversationDialog.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const uploadConversationDialogVisible = (0, _vue.ref)(false);
    const uploadConversationTourRef = (0, _vue.ref)(null);
    const uploadConversationGuideSteps = [{
      title: ' ',
      content: '会话可以批量上传啦！',
      target: '[data-v-step="upload-conversation-btn"]' // 模块-功能-step-id
    }];

    (0, _vue.onMounted)(async () => {
      await (0, _vue.nextTick)();
      uploadConversationTourRef.value.start();
    });
    return {
      __sfc: true,
      uploadConversationDialogVisible,
      uploadConversationTourRef,
      uploadConversationGuideSteps,
      UploadConversationDialog: _UploadConversationDialog.default
    };
  }
};
exports.default = _default;