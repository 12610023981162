"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "member-assign"
  }, [_c("header", {
    class: {
      "is-hidden-menu": _vm.isHiddenMenu
    }
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v("质检成员分配")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      "popper-class": "compliance-member-assign-tooltip"
    }
  }, [_c("div", {
    staticClass: "content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(" 1. 质检团队可根据质检任务分配质检成员"), _c("br"), _vm._v("2. 分配成员之后，质检员可以按照各自成员名单快速质检"), _c("br"), _vm._v("3. 分配质检成员不会影响质检员的质检会话权限，质检员仍然可以质检权限范围内的所有会话 ")]), _c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "header-filter"
  }, [_c("MgvMemberMultiSelect", {
    staticClass: "member-filter",
    attrs: {
      placeholder: "质检员",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "request-search-params": {
        module_type: "compliance"
      },
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["compliance"])
      }
    },
    on: {
      change: _setup.memberChange
    }
  }), _c("div", {
    staticClass: "operate-container"
  }, [_setup.createComplianceMemberAssign ? _c("el-button", {
    staticClass: "create-assign-btn",
    attrs: {
      icon: "el-icon-plus",
      type: "primary"
    },
    on: {
      click: _setup.createMemberAssign
    }
  }, [_vm._v("新建分配")]) : _vm._e(), _c(_setup.ExportTableButton, {
    ref: "exportTableRef",
    attrs: {
      disabled: !_setup.tableInfo.total,
      "export-method": _setup.exportComplianceAssignMember
    },
    on: {
      exportTable: _setup.exportTableData
    }
  })], 1)], 1), _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }]
  }, [_setup.tableInfo.data && _setup.tableInfo.data.length || _setup.loading ? [_c(_setup.MemberAssignTable, {
    attrs: {
      "table-data": _setup.tableInfo.data,
      "total-member": _setup.tableInfo.total_member
    }
  }), _c("footer", [_setup.tableInfo.allocate_time ? _c("div", {
    staticClass: "allocate-info"
  }, [_c("span", [_vm._v("分配时间")]), _c("span", [_vm._v(_vm._s(_setup.tableInfo.allocate_time))]), _c("span", [_vm._v("分配人员")]), _c("span", [_vm._v(_vm._s(_setup.tableInfo.allocate_user.name))])]) : _vm._e(), _c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _setup.params.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _setup.params.size,
      total: _setup.tableInfo.total
    },
    on: {
      "size-change": _setup.sizeChange,
      "current-change": _setup.pageChange
    }
  })], 1)] : _c(_setup.NoData)], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;