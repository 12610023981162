"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _TeamTaskDetailContent = _interopRequireDefault(require("./components/TeamTaskDetailContent"));
var _compliance = require("@/api/compliance");
var _vuex = require("vuex");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _RemovedRecordDrawer = _interopRequireDefault(require("../components/RemovedRecordDrawer.vue"));
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskDetail',
  components: {
    DetailHeader: _DetailHeader.default,
    TeamTaskDetailContent: _TeamTaskDetailContent.default,
    RemovedRecordDrawer: _RemovedRecordDrawer.default
  },
  mixins: [_complianceTableMixin.default],
  data() {
    return {
      taskName: '',
      taskId: 0,
      scoreRuleList: [],
      evaluationItemList: [],
      filtersObj: [{
        workspace_id: 0,
        task_id: 0,
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        percent: [],
        sort_by: [['percent', 'descending']],
        page: 1,
        size: 20
      }, {
        workspace_id: 0,
        task_id: 0,
        conversation_name: '',
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        status: 0,
        score: [],
        sort_by: [['user_id', 'descending']],
        page: 1,
        size: 20
      }],
      samplingType: true,
      convTableHeader: [{
        label: '会话名称',
        prop: 'conv_name',
        width: 260
      }, {
        label: '会话时间',
        prop: 'conversation_time',
        width: 160
      }, {
        label: '质检员',
        prop: 'user_id',
        width: 200
      }, {
        label: '质检状态',
        prop: 'status',
        width: 160
      }],
      viewName: 'member',
      activeTabIndex: 0,
      tableDataList: [[], []],
      total: [],
      loading: false,
      removedRecordDrawerVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission']),
    conversationViewSortConfig() {
      const sortable = ['user_id', 'conversation_time', 'status', 'score'];
      if (this.samplingType) {
        // sortable.unshift('conv_name');
      }
      return {
        sortable,
        defaultSort: {
          // prop: this.samplingType ? 'conv_name' : 'user_id',
          prop: this.samplingType ? '' : 'user_id',
          order: 'descending'
        }
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        const viewName = localStorage.getItem('ComplianceTeamTaskDetail') || 'member';
        this.samplingType = this.$route.query.sampling_type;
        this.handleActiveTabIndex(viewName);
        this.initSamplingSortBy();
        if (val) {
          this.filtersObj.forEach(item => {
            item.workspace_id = val;
          });
          this.taskId = Number(this.$route.query.id);
          this.filtersObj.forEach(item => {
            item.task_id = this.taskId;
          });
          this.getScoreRuleList(val);
          this.getEvaluationItemList(val);
          this.initParams(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    openRemovedRecordDrawer() {
      this.removedRecordDrawerVisible = true;
    },
    goCompliancePersonal(row) {
      const routeData = this.$router.resolve({
        path: _routeMap.ROUTES.compliancePersonalConversation,
        query: {
          id: this.taskId,
          user_id: row.user_info.user_id,
          sampling_type: this.$route.query.sampling_type
        }
      });
      window.open(routeData.href, '_blank');
    },
    async getScoreRuleList() {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getScoreRuleList)(data);
      if (res.code === 20000) {
        this.scoreRuleList = res.results.score_rule_filters;
      }
    },
    async getEvaluationItemList() {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getEvaluationItemList)(data);
      if (res.code === 20000) {
        this.evaluationItemList = res.results.evaluation_items;
      }
    },
    initParams(workspaceId) {
      this.filtersObj[this.activeTabIndex].workspace_id = workspaceId;
      this.handleFiltersChange(this.filtersObj[this.activeTabIndex]);
    },
    initSamplingSortBy() {
      if (this.viewName === 'conversation' && this.samplingType) {
        // const { prop, order } = this.conversationViewSortConfig.defaultSort;
        // this.filtersObj[1].sort_by = [[prop, order]];
        this.filtersObj[1].sort_by = [];
      }
    },
    switchView(viewName) {
      this.initSamplingSortBy();
      this.handleActiveTabIndex(viewName);
      this.filtersObj[this.activeTabIndex].page = 1;
      this.initParams(this.workspaceInfo.id);
    },
    handleActiveTabIndex(viewName) {
      localStorage.setItem('ComplianceTeamTaskDetail', viewName);
      this.viewName = viewName;
      this.activeTabIndex = ['member', 'conversation'].findIndex(item => item === viewName);
    },
    goComplianceWorkSpace(_ref) {
      let {
        params,
        query
      } = _ref;
      this.$router.push({
        name: 'ComplianceWorkSpace',
        query: {
          ...query,
          task_id: params.task_id,
          sampling_type: this.$route.query.sampling_type
        },
        params
      });
    },
    handleFiltersChange(filters) {
      this.filtersObj[this.activeTabIndex] = Object.assign(this.filtersObj[this.activeTabIndex], filters);
      this.getComplianceTeamTask(this.filtersObj[this.activeTabIndex]);
    },
    sortChange(_ref2) {
      let {
        prop,
        order
      } = _ref2;
      this.filtersObj[this.activeTabIndex].sort_by = [[prop, order]];
      this.filtersObj[this.activeTabIndex].page = 1;
      this.getComplianceTeamTask(this.filtersObj[this.activeTabIndex]);
    },
    async getComplianceTeamTask(filters) {
      const conversationFilters = (0, _commonFunc.deepClone)(filters);
      if (this.viewName === 'conversation') {
        conversationFilters.status = conversationFilters.status || 0;
        (0, _timezone.convertParams2UTCplus8)(conversationFilters);
      }
      this.loading = true;
      const method = this.viewName === 'member' ? _compliance.getTeamMemberTaskList : _compliance.getTeamConvTaskList;
      try {
        const res = await method(conversationFilters);
        if (res.code === 20000) {
          const {
            datas,
            total,
            task_name
          } = res.results;
          this.$set(this.tableDataList, this.activeTabIndex, datas);
          this.$set(this.total, this.activeTabIndex, total);
          this.taskName = task_name;
        }
      } finally {
        this.loading = false;
      }
    },
    sizeChange(size) {
      this.filtersObj[this.activeTabIndex].size = size;
      this.filtersObj[this.activeTabIndex].page = 1;
      this.getComplianceTeamTask(this.filtersObj[this.activeTabIndex]);
    },
    currentChange(page) {
      this.filtersObj[this.activeTabIndex].page = page;
      this.getComplianceTeamTask(this.filtersObj[this.activeTabIndex]);
    }
  }
};
exports.default = _default;