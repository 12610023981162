"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data.type ? _c("div", {
    staticClass: "business-trans-chart-footnote"
  }, [_vm.editVisisble ? _c("div", {
    staticClass: "edit-container"
  }, [_vm._l(_vm.formKeys, function (key) {
    return _c("el-input", {
      key: key,
      attrs: {
        placeholder: _vm.data[key].origin_name,
        maxlength: 20,
        clearable: ""
      },
      model: {
        value: _vm.form[key].name,
        callback: function ($$v) {
          _vm.$set(_vm.form[key], "name", $$v);
        },
        expression: "form[key].name"
      }
    });
  }), _c("el-button", {
    on: {
      click: function ($event) {
        _vm.editVisisble = false;
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.editLoading
    },
    on: {
      click: _vm.handlerSubmit
    }
  }, [_vm._v("确定")])], 2) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editVisisble,
      expression: "!editVisisble"
    }],
    staticClass: "detail-container"
  }, [_c("div", {
    staticClass: "des"
  }, [_vm._v(_vm._s(_vm.data.title.name))]), _c("div", {
    staticClass: "tips"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      "popper-class": "business-trans-chart-footnote-tooltip"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, _vm._l(_vm.tooltipList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "content-row"
    }, [_c("div", {
      staticClass: "index"
    }, [_vm._v(".")]), _c("span", [_vm._v(_vm._s(item))])]);
  }), 0), _c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c("div", {
    staticClass: "total"
  }, [_c("p", {
    staticClass: "content"
  }, [_c("span", {
    staticClass: "large-text"
  }, [_vm._v(_vm._s(_vm.data.total.value))]), _c("span", [_vm._v(_vm._s(_vm.data.total.name))])])]), _c("div", {
    staticClass: "trans"
  }, [_c("p", {
    staticClass: "content"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "large-text"
  }, [_vm._v(_vm._s(_vm.data.trans_other_rate.value))]), _c("span", [_vm._v(_vm._s(_vm.data.trans_other_rate.name))])]), _c("span", {
    staticClass: "large-text"
  }, [_vm._v(_vm._s(_vm.data.trans_other_count.value))]), _c("span", [_vm._v(_vm._s(_vm.data.trans_other_count.name))])])]), _c("div", {
    staticClass: "loss"
  }, [_c("p", {
    staticClass: "content"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "large-text"
  }, [_vm._v(_vm._s(_vm.data.loss_rate.value))]), _c("span", [_vm._v(_vm._s(_vm.data.loss_rate.name))])]), _c("span", {
    staticClass: "large-text"
  }, [_vm._v(_vm._s(_vm.data.loss_count.value))]), _c("span", [_vm._v(_vm._s(_vm.data.loss_count.name))])])]), _c("el-button", {
    staticClass: "el-button-text--info",
    attrs: {
      size: "smini",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.editField
    }
  })], 1)]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;