"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "el-image"
  }, [_vm.loading ? _vm._t("placeholder", function () {
    return [_c("div", {
      staticClass: "el-image__placeholder"
    })];
  }) : _vm.error ? _vm._t("error", function () {
    return [_c("div", {
      staticClass: "el-image__error"
    }, [_vm._v(_vm._s(_vm.t("el.image.error")))])];
  }) : _c("img", _vm._g(_vm._b({
    staticClass: "el-image__inner",
    class: {
      "el-image__inner--center": _vm.alignCenter,
      "el-image__preview": _vm.preview
    },
    style: _vm.imageStyle,
    attrs: {
      src: _vm.src
    },
    on: {
      click: _vm.clickHandler
    }
  }, "img", _vm.$attrs, false), _vm.$listeners)), _vm.preview ? [_vm.showViewer ? _c("image-viewer", {
    ref: "imageViewer",
    attrs: {
      "z-index": _vm.zIndex,
      "initial-index": _vm.imageIndex,
      "on-close": _vm.closeViewer,
      "url-list": _vm.previewSrcList
    }
  }) : _vm._e()] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;