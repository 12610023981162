"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formatDataUnit = require("./formatDataUnit.js");
var _default = {
  name: 'CardTextMainData',
  props: {
    cardConfig: {
      type: Object,
      default: () => ({})
    },
    unitConfig: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    formatUnit() {
      return (0, _formatDataUnit.formatDataUnit)(this.unitConfig, this.cardConfig.data[0]);
    }
  }
};
exports.default = _default;