"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-data-analysis"
  }, [_c("navbar", {
    attrs: {
      title: "团队数据分析",
      "back-button": true
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "content-top"
  }, [_c("div", {
    staticClass: "filter-select"
  }, [_c("div", {
    staticClass: "department-multiple-select"
  }, [_c("department-and-member-select-tree", {
    attrs: {
      "is-checked-root": "",
      params: {
        type: JSON.stringify(["conversation"])
      }
    },
    on: {
      change: _vm.departmentAndMemberChange
    }
  })], 1), _c("div", {
    staticClass: "date-box"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.filterType === "all" ? "会话创建时间" : "交易创建时间",
      placement: "top",
      value: _vm.showTooltip
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      align: "right",
      "unlink-panels": "",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "picker-options": _vm.datePickerOptions
    },
    on: {
      change: _vm.datePickerChange
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1), _c("div", {
    staticClass: "knowledge-filter"
  }, [_c("knowledge-filter", {
    attrs: {
      "knowledge-list": _vm.allKnowledgeList
    },
    on: {
      knowledgeChange: _vm.knowledgeChange
    }
  })], 1), _c("div", {
    staticClass: "filter-type"
  }, [_c("span", [_vm._v("筛选会话条件：")]), _c("el-select", {
    attrs: {
      "popper-class": "filter-basis-select",
      "popper-append-to-body": false
    },
    on: {
      change: _vm.handleFilterTypeChange
    },
    model: {
      value: _vm.filterType,
      callback: function ($$v) {
        _vm.filterType = $$v;
      },
      expression: "filterType"
    }
  }, _vm._l(_vm.fiterTypeList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)])]), _c("deal-custom-field-filter", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filterType === "deal",
      expression: "filterType === 'deal'"
    }],
    on: {
      customFilterChange: _vm.customFilterChange
    }
  }), _c("div", {
    staticClass: "export-table"
  }, [_c("span", {
    on: {
      click: _vm.exportTable
    }
  }, [_vm._v("导出表格")])]), _c("div", {
    ref: "table",
    staticClass: "team-data-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "team-data-table",
    class: [_vm.hasRightShadow ? "right-shadow" : ""],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: "",
      "header-cell-style": {
        "border-right": "solid 1px #E2E5E9"
      }
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      "show-overflow-tooltip": "",
      fixed: "",
      prop: "host_id",
      label: "成员",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [[_c("div", {
          staticClass: "member",
          on: {
            click: function ($event) {
              return _vm.goMyKnowledge(row.host_id, row.host_name);
            }
          }
        }, [_c("image-avatar", {
          attrs: {
            avatar: row.host_avatar,
            name: row.host_name
          }
        }), _c("a", [_vm._v(_vm._s(row.host_name))])], 1)]];
      }
    }])
  }), _vm._l(_vm.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.id,
      ref: "column_" + item.id,
      refInFor: true,
      attrs: {
        prop: item.id.toString(),
        label: item.name,
        "class-name": item.id ? "" : "average-column",
        sortable: "custom",
        "sort-orders": ["ascending", "descending"],
        width: "200"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c("div", {
            staticClass: "custom-header"
          }, [_c("span", [_vm._v(_vm._s(item.name))]), item.type_name ? _c("div", {
            staticClass: "type-name"
          }, [_vm._v(" " + _vm._s(item.type_name) + " ")]) : _vm._e()])];
        },
        proxy: true
      }, {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            class: [item.id ? "custom-score-cell" : "", {
              "custom-score-cell-cursor": item.id && _vm.getPersonalScore(item.id, row)
            }],
            on: {
              click: function ($event) {
                return _vm.openKnowledgeConvDrawer(item.id, row);
              }
            }
          }, [_c("span", {
            class: {
              "no-score": !_vm.getPersonalScore(item.id, row)
            }
          }, [_vm._v(" " + _vm._s(_vm.getPersonalScore(item.id, row) || "暂无得分") + " ")]), item.id && _vm.getPersonalScore(item.id, row) ? _c("span", {
            staticClass: "score-tooltip"
          }, [_c("i", {
            staticClass: "iconfont icon-playlist"
          })]) : _vm._e()])];
        }
      }], null, true)
    });
  })], 2)], 1)], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    staticClass: "conver-list-pagination",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.teamDataParams.page_num,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.teamDataParams.page_size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  })], 1), _c("KnowleageConvDrawer", {
    attrs: {
      visible: _vm.knowledgeConvDrawerVisible,
      "team-data-params": _vm.teamDataParams,
      "active-knowledge": _vm.activeKnowledge
    },
    on: {
      closeKnowledgeConvDrawer: _vm.closeKnowledgeConvDrawer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;