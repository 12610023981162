"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'InfoEditCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    },
    directlyEditable: {
      // 直接进入编辑状态，无取消操作
      type: Boolean,
      default: false
    }
  },
  emits: ['submit', 'cancel'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const isEditing = (0, _vue.ref)(false);
    (0, _vue.watchEffect)(() => {
      if (props.directlyEditable) {
        isEditing.value = true;
      }
    });
    const submit = async () => {
      const res = await emit('submit', () => {
        isEditing.value = false;
      });
    };
    const cancel = () => {
      emit('cancel');
      isEditing.value = false;
    };
    return {
      __sfc: true,
      isEditing,
      props,
      emit,
      submit,
      cancel
    };
  }
};
exports.default = _default;