"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScoreRuleCard',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MgOperateColumn: _MgOperateColumn.default
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    },
    linkText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      enable: false
    };
  },
  computed: {
    variables() {
      return _variablesModule.default;
    },
    getOperateList() {
      return [{
        label: '编辑',
        command: 'edit',
        icon: 'icon-edit',
        isShow: true
      }, {
        label: '复制',
        command: 'copy',
        icon: 'icon-copy-document',
        isShow: true
      }, {
        label: '删除',
        command: 'delete',
        icon: 'icon-delete',
        class: 'delete',
        isShow: true
      }];
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'edit':
          this.editScore();
          break;
        case 'copy':
          this.copyRule();
          break;
        case 'delete':
          this.deleteRule();
          break;
        default:
          break;
      }
    },
    getTimeText(time) {
      return this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm');
    },
    changeStatus(newStatus, info) {
      info.is_on = !newStatus;
      this.$emit('operateClick', info.is_on ? 'off' : 'on', info);
    },
    deleteRule() {
      this.$emit('operateClick', 'delete', this.info);
    },
    editScore() {
      this.$emit('editScore', this.info.id);
    },
    copyRule() {
      this.$emit('copyRule', this.info.id);
    }
  }
};
exports.default = _default;