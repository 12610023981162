"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ContactPersonCard = _interopRequireDefault(require("@/views/clients/components/ContactPersonCard"));
var _business = require("@/api/business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "ContactPersonDetail",
  components: {
    ContactPersonCard: _ContactPersonCard.default
  },
  props: {
    dealId: {
      type: String | Number
    },
    formDesc: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      contactPersonList: [],
      total: 0
    };
  },
  created() {
    this.getDealContactList();
  },
  methods: {
    async getDealContactList() {
      const res = await (0, _business.getDealContactList)({
        deal_id: this.dealId
      });
      this.contactPersonList = [...res.results.main, ...res.results.other];
      this.total = res.results.total;
    },
    setDecisionMarker(val) {
      let updateData = {
        contact_id: val.id,
        deal_id: this.dealId,
        is_main: 1
      };
      this.updateDealContactDecision(updateData);
    },
    cancelSetDecisionMarker(val) {
      let updateData = {
        contact_id: val.id,
        deal_id: this.dealId,
        is_main: 0
      };
      this.updateDealContactDecision(updateData);
    },
    async updateDealContactDecision(data) {
      const res = await (0, _business.updateDealContactDecision)(data);
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.getDealContactList();
      }
    }
  }
};
exports.default = _default;