"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _elementUi = require("element-ui");
var _FilterConvDrawer = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/ruleEventTest/filterConversation/FilterConvDrawer.vue"));
var _dataSet = require("@/api/dataSet");
var _keyEventManagement = require("@/api/keyEventManagement");
var _timezone = require("@/utils/timezone");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ImportDataDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataSetId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'refreshTable'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    (0, _vue.provide)('batchSearchDialogAppendToBody', true);
    const form = (0, _vue.ref)({
      dataOrigin: 'system',
      annotation_type: 1
    });
    const importDataFormRef = (0, _vue.ref)(null);
    const handleClose = () => {
      emit('update:visible', false);
    };
    const filterConvDrawerVisiable = (0, _vue.ref)(false);
    const convFilters = (0, _vue.ref)({});
    const convCount = (0, _vue.ref)(0);
    const openFilterConvDrawer = () => {
      filterConvDrawerVisiable.value = true;
    };
    const closeFilterConvDrawer = () => {
      filterConvDrawerVisiable.value = false;
    };
    const filterConvDrawerRef = (0, _vue.ref)(null);
    const selectConversation = async () => {
      convCount.value = filterConvDrawerRef.value.convTotalCount;
      if (convCount.value && convCount.value > 10000) {
        _elementUi.Message.warning('当前会话数量超过10000，请缩小会话范围');
        return;
      }
      convFilters.value = filterConvDrawerRef.value.convFilters;
      filterConvDrawerVisiable.value = false;
    };
    const getConvIdsApi = async () => {
      var _convFilters$value, _convFilters$value$da, _convFilters$value$da2;
      if ((_convFilters$value = convFilters.value) !== null && _convFilters$value !== void 0 && (_convFilters$value$da = _convFilters$value.date) !== null && _convFilters$value$da !== void 0 && (_convFilters$value$da2 = _convFilters$value$da.range) !== null && _convFilters$value$da2 !== void 0 && _convFilters$value$da2.length) {
        convFilters.value.date.range = (0, _timezone.datePickerParseToZone)(convFilters.value.date.range);
      }
      const res = await (0, _keyEventManagement.getConvIds)({
        ...convFilters.value,
        workspace_id: store.getters.workspaceInfo.id
      });
      if (res.code === 200) {
        return res.results.ids;
      }
    };
    const loading = (0, _vue.ref)(false);
    const handleImportData = async () => {
      if (!convCount.value) return;
      loading.value = true;
      const convIds = await getConvIdsApi();
      const res = await (0, _dataSet.importData)({
        dataset_id: props.dataSetId,
        conversation_ids: convIds
      }).finally(() => loading.value = false);
      if (res.code === 20000) {
        _elementUi.Message.success('导入成功');
        emit('importSuccess');
        handleClose();
      }
    };
    const initImportDataDialog = () => {
      if (props.visible) {
        convFilters.value = {};
        convCount.value = 0;
      }
    };
    (0, _vue.watchEffect)(() => {
      initImportDataDialog();
    });
    return {
      __sfc: true,
      props,
      store,
      form,
      importDataFormRef,
      emit,
      handleClose,
      filterConvDrawerVisiable,
      convFilters,
      convCount,
      openFilterConvDrawer,
      closeFilterConvDrawer,
      filterConvDrawerRef,
      selectConversation,
      getConvIdsApi,
      loading,
      handleImportData,
      initImportDataDialog,
      FilterConvDrawer: _FilterConvDrawer.default
    };
  }
};
exports.default = _default;