"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatRouterTree = formatRouterTree;
exports.generateRouter = generateRouter;
var _routeMap = require("@/router/routeMap");
/**
 * 生成树形结构
 * @param data
 * @returns {*}
 */
function formatRouterTree(data) {
  const parents = data.filter(p => p.pid === 0);
  const children = data.filter(c => c.pid !== 0);
  dataToTree(parents, children);
  function dataToTree(parents, children) {
    parents.map(p => {
      children.map((c, i) => {
        if (c.pid === p.id) {
          const _c = JSON.parse(JSON.stringify(children));
          _c.splice(i, 1);
          dataToTree([c], _c);
          if (p.children) {
            p.children.push(c);
          } else {
            p.children = [c];
          }
        }
      });
    });
  }
  return parents;
}

/**
 * 生成路由
 * @param routersTree
 * @returns {*}
 */
function generateRouter(routersTree) {
  const newRouters = routersTree.map(r => {
    const route = {
      path: r.path,
      name: r.name,
      component: _routeMap.PAGES[r.name],
      redirect: (() => {
        if (r.children && r.children.length) {
          if (r.children.find(item => item.path === '')) {
            return r.children.find(item => item.path === '').link;
          } else {
            const hasOrderChildren = r.children.filter(item => item.meta.order);
            hasOrderChildren.sort((a, b) => a.meta.order - b.meta.order);
            if (hasOrderChildren.length) {
              return hasOrderChildren[0].link;
            }
            return r.children[0].link;
          }
        }
      })(),
      meta: r.meta
    };
    if (r.children) {
      route.children = generateRouter(r.children);
    }
    return route;
  });
  return newRouters;
}