"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail.js");
var _conversationList = require("@/api/conversationList");
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _sumbitDialog = _interopRequireDefault(require("@/components/Video/sumbitDialog"));
var _mgvProgressLoading = _interopRequireDefault(require("@/components/mgvProgressLoading"));
var _SnippetCheckBox = _interopRequireDefault(require("./SnippetCheckBox"));
var _CorrectTextDialog = _interopRequireDefault(require("./CorrectTextDialog"));
var _AddKeyEventTrackerPopover = _interopRequireDefault(require("./AddKeyEventTrackerPopover.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DialogText',
  components: {
    SvgIcon: _SvgIcon.default,
    ImageAvatar: _ImageAvatar.default,
    SumbitDialog: _sumbitDialog.default,
    MgvProgressLoading: _mgvProgressLoading.default,
    SnippetCheckBox: _SnippetCheckBox.default,
    CorrectTextDialog: _CorrectTextDialog.default,
    AddKeyEventTrackerPopover: _AddKeyEventTrackerPopover.default,
    NoData: _NoData.default
  },
  inject: {
    flipRoleApi: {
      default: null
    },
    getConversationDetail: {
      default: null
    }
  },
  props: {
    convInWorkSpaceId: {
      type: [Number, String],
      default: ''
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    },
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    isPure: {
      type: Boolean,
      default: false
    },
    words: {
      type: String,
      default: ''
    },
    highlightItem: {
      type: Object,
      default() {
        return null;
      }
    },
    activeRoundId: {
      type: [Number, String],
      default: null
    },
    origin: {
      type: String,
      default: 'native'
    },
    uploadType: {
      type: String,
      default: ''
    },
    hasRole: {
      type: Boolean,
      default: true
    },
    mediaType: {
      type: String,
      default: 'audio'
    },
    rightBarActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ROUTES: _routeMap.ROUTES,
      curResultOrder: 0,
      audioList: [],
      interrupt: false,
      scrollTop: 0,
      // 记录当前的滚动距离
      modifyArr: [],
      sumbitVisible: false,
      peopleFlag: false,
      audioPlayer: new Audio(),
      isPlay: false,
      timer: null,
      correctTextDialogVisible: false,
      correctTextItem: {},
      // 修改的当前句子对象,
      showAddKeyEventTrackerPopover: false,
      sliceStartIndex: 0,
      sliceEndIndex: 30,
      // 命中关键事件选中的句子
      hitKeyEventSelectedSentence: [],
      // 所有句子命中的关键事件
      sentenceOfEventTagMap: [],
      // 删除关键事件loading
      deleteKeyEventTackerLoading: false,
      selectKeyEventTagKey: '',
      addEventTrackerLoading: false,
      eventOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['currentOperateName', 'curtime', 'curconvid', 'curorder', 'audio', 'is_user_scroll', 'dialogTextScrollable', 'is_video_pause', 'selectedSentenceOfComment', 'snippetCheckList', 'interfacePermission', 'isEsInsertComplete', 'hasVideo', 'isShowEventTag']),
    _sliceStartIndex() {
      return this.sliceStartIndex < 0 ? 0 : this.sliceStartIndex;
    },
    sliceSentence() {
      var _this$audio;
      return (_this$audio = this.audio) === null || _this$audio === void 0 ? void 0 : _this$audio.slice(this._sliceStartIndex, this.sliceEndIndex);
    },
    operateItemPermission() {
      return !this.isPure && (this.mediaType !== 'doc' && this.hasVideo || this.mediaType !== 'doc' && this.hasCorrectTextPermission || this.canEditKeyEvent && this.hasCorrectTextPermission);
    },
    isSales() {
      return function (item) {
        if (item.entity_type && item.entity_type !== 'customer_contact' && item.entity_type !== '' || item.channel === 0 && item.entity_type === '' && item.entity_type) {
          return true;
        }
      };
    },
    hasCorrectTextPermission() {
      const permissionKeyMap = {
        [_routeMap.ROUTES.complianceWorkspace]: 'correctConvText',
        [_routeMap.ROUTES.conversationDetail]: 'correctConvText',
        [_routeMap.ROUTES.libraryDetail]: 'correctSnippetText'
      };
      const key = permissionKeyMap[this.$route.path];
      return this.interfacePermission[key];
    },
    isShowName() {
      return function (item) {
        if (this.origin !== 'upload' && this.origin && this.origin !== '' || this.origin === 'upload' && this.hasRole && item.entity_id !== 0 || this.origin === 'upload' && this.hasRole && this.peopleFlag) {
          return true;
        }
      };
    },
    isDocGroup() {
      // 是不是文字群聊
      return this.getConversationDetail().is_group;
    }
  },
  watch: {
    isShowEventTag: {
      handler: function (val) {
        if (val && !this.sentenceOfEventTagMap.length) {
          if (this.$route.path === _routeMap.ROUTES.libraryDetail) return;
          this.getKeyEventOfSentenceHit();
        }
      }
    },
    audio: {
      handler: function initScrollHeight() {
        if (this.startSliceIndex === 0) return;
        this.$nextTick(() => {
          this.$refs.contWrapper.scroll({
            top: 80
          });
        });
      }
    },
    isSnippetStatus(val) {
      if (!val) {
        this.$store.commit('video/SET_SNIPPET_CHECK_LIST', []);
      }
    },
    convInWorkSpaceId: {
      handler(val) {
        if (val) {
          this.getAllKeyEvent();
        }
      },
      immediate: true
    },
    uploadType: {
      handler: function (value) {
        if (!value) {
          this.peopleFlag = true;
        } else {
          this.peopleFlag = false;
        }
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    },
    modifyArr(val) {
      if (!Array.isArray(this.audioList)) return;
      const flag = this.audioList.every(item => {
        return item.id;
      });
      if (val.length === 2 && flag) {
        this.sumbitVisible = true;
      }
    },
    curorder: {
      handler: function (value) {
        this.updateSliceSentence(value.value);
        if (this.dialogTextScrollable) {
          this.$nextTick(() => {
            const name = 'dialogItem' + value.value;
            if (this.audio && this.audio.length > 0) {
              if (this.$refs[name]) {
                const list = this.$refs[name][0];
                if (list) {
                  var _this$$refs$contWrapp;
                  const height = list.offsetTop;
                  (_this$$refs$contWrapp = this.$refs['contWrapper']) === null || _this$$refs$contWrapp === void 0 ? void 0 : _this$$refs$contWrapp.scroll({
                    top: height - 80,
                    // 向上移动的距离，如果有fixed布局， 直接减去对应距离即可
                    behavior: this.is_video_pause ? 'instant' : 'smooth'
                  });
                }
              }
            }
          });
        }
        this.$store.commit('video/SET_DIALOG_TEXT_SCROLLABLE', true);
      },
      deep: true,
      immediate: true
    },
    curconvid: {
      handler: function (value) {
        if (this.isShowEventTag) {
          this.getKeyEventOfSentenceHit();
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('audioList', val => {
      this.audioList = val;
    });
    this.$bus.$on('conversationLeftDeleteKeyEventTrackerSuccess', () => {
      if (this.isShowEventTag) {
        this.getKeyEventOfSentenceHit();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off('audioList');
  },
  methods: {
    flipRole(item) {
      this.flipRoleApi({
        conversation_id: Number(this.curconvid),
        is_flip_all: false,
        flip_orders: [item.order]
      });
    },
    async getAllKeyEvent() {
      const res = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.convInWorkSpaceId
      });
      if (res.code === 20000) {
        this.eventOptions = res.results.events;
      }
    },
    handleSubmitAddEventTracker(selectEventId) {
      this.addKeyEventTrackerOfSentence(selectEventId);
    },
    async addKeyEventTrackerOfSentence(selectEventId) {
      if (this.addEventTrackerLoading) return;
      this.addEventTrackerLoading = true;
      const localSelectEventIds = (0, _commonFunc.deepClone)(selectEventId);
      const event_ids = localSelectEventIds.map(item => item.pop());
      const res = await (0, _videoDetail.addKeyEventTrackerOfSentence)({
        conversation_id: Number(this.curconvid),
        event_ids,
        sentence_orders: this.hitKeyEventSelectedSentence
      }).finally(() => this.addEventTrackerLoading = false);
      if (res.code === 20000) {
        this.$message.success('添加成功，评分重新计算中，请稍后刷新查看');
        if (this.isShowEventTag) {
          this.getKeyEventOfSentenceHit();
        }
        this.$bus.$emit('addEventTrackerSuccess', event_ids);
        this.handleCancelAddKeyEventTracker();
      }
    },
    handleKeyEventTagSelected(keyEventTag, sentence) {
      if (this.selectKeyEventTagKey === String(keyEventTag.event_engine_id) + sentence.order) {
        this.selectKeyEventTagKey = '';
      } else {
        this.selectKeyEventTagKey = String(keyEventTag.event_engine_id) + sentence.order;
      }
    },
    async deleteHitKeyEventTracker(keyEventTag, sentence) {
      this.deleteKeyEventTackerLoading = true;
      const res = await (0, _videoDetail.deleteKeyEventDetailTracker)({
        conversation_id: Number(this.curconvid),
        event_engine_id: keyEventTag.event_engine_id
      }).finally(() => this.deleteKeyEventTackerLoading = false);
      if (res.code === 20000) {
        this.$message.success('删除成功，评分重新计算中，请稍后刷新查看');
        this.$bus.$emit('deleteKeyEventTrackerSuccess');
        keyEventTag.orders.forEach(item => {
          this.sentenceOfEventTagMap[item] = this.sentenceOfEventTagMap[item].filter(_item => _item.event_engine_id !== keyEventTag.event_engine_id);
        });
      }
    },
    async getKeyEventOfSentenceHit() {
      const res = await (0, _videoDetail.getKeyEventOfSentenceHit)({
        conversation_id: Number(this.curconvid)
      });
      if (res.code === 20000) {
        this.sentenceOfEventTagMap = res.results.sentence_events;
      }
    },
    handleClickContent(item) {
      switch (this.$route.path) {
        case _routeMap.ROUTES.conversationDetail:
          if (this.rightBarActive === 'comments') {
            this.$store.commit('video/SET_SELECTED_SENTENCE_OF_COMMENT', item);
          }
          break;
        case _routeMap.ROUTES.libraryDetail:
          this.$store.commit('video/SET_SELECTED_SENTENCE_OF_COMMENT', item);
          break;
      }
    },
    handleSelectedCommentSentence(item) {
      this.$emit('handleComment');
      this.$store.commit('video/SET_SELECTED_SENTENCE_OF_COMMENT', item);
      this.$store.commit('video/SET_CLICK_COMMENT_BTN_TIMES');
    },
    // 命中关键事件
    addKeyEventTracker($event, item, index) {
      console.log($event, 'events');
      if (this.currentOperateName === 'snippet') {
        return;
      }
      this.hitKeyEventSelectedSentence = [item.order];
      this.$store.commit('video/SET_SNIPPET_CHECK_LIST', this.hitKeyEventSelectedSentence);
      this.showAddKeyEventTrackerPopover = true;
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', 'hitKeyEvent');
      this.setAddKeyEventTrackerPopoverOffset(item.order);
    },
    setAddKeyEventTrackerPopoverOffset(order) {
      this.$nextTick(() => {
        var _this$$refs;
        const ele = this.$refs['addKeyEventTrackerPopover'];
        if (ele.$el.dataset.hasUserDrag === '1') {
          return;
        }
        const sentenceEle = (_this$$refs = this.$refs['contWrap' + order]) === null || _this$$refs === void 0 ? void 0 : _this$$refs[0];
        const {
          left,
          top,
          height,
          width,
          right
        } = sentenceEle.getBoundingClientRect();
        const contentEle = sentenceEle.querySelector('.content');
        const {
          width: contentWidth
        } = contentEle.getBoundingClientRect();
        if (sentenceEle.className.split(' ').includes('reverse')) {
          const offsetY = top + 36;
          ele.$el.style.top = offsetY + 'px';
          if (right - contentWidth + 400 > window.innerWidth) {
            ele.$el.style.left = window.innerWidth - 400 + 'px';
          } else {
            ele.$el.style.left = right - contentWidth - 132 - 10 + 'px';
          }
        } else {
          const offsetY = top + 36;
          ele.$el.style.top = offsetY + 'px';
          ele.$el.style.left = left + width - 132 + 'px';
        }
      });
    },
    handleCancelAddKeyEventTracker() {
      this.showAddKeyEventTrackerPopover = false;
      this.hitKeyEventSelectedSentence = [];
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', '');
      this.$nextTick(() => {
        const ele = this.$refs['addKeyEventTrackerPopover'];
        console.log(ele);
        ele.$el.dataset.hasUserDrag = '0';
        ele.selectEventId = 0;
      });
    },
    // 修改转录文字成功，修改修正的句子
    correctTextSuccess(text) {
      this.correctTextItem.content = text;
      this.$emit('correctTextSuccess');
    },
    handleCheckBoxInput(val) {
      this.$store.commit('video/SET_SNIPPET_CHECK_LIST', val);
    },
    handleCheckBoxChange(val) {
      switch (this.currentOperateName) {
        case 'snippet':
          if (val.length > 2) {
            const lastSelectedValue = val.pop();
            this.$store.commit('video/SET_SNIPPET_CHECK_LIST', [lastSelectedValue]);
          }
          break;
        case 'hitKeyEvent':
          this.hitKeyEventSelectedSentence = val;
          if (val[val.length - 1] !== undefined) {
            this.setAddKeyEventTrackerPopoverOffset(val[val.length - 1]);
          }
          break;
        default:
          break;
      }
    },
    handleClick(val) {
      if (val.msg_type === 'voice') {
        this.audioPlayer.src = val.src;
        if (this.isPlay) {
          this.audioPlayer.pause();
          if (this.isPlay === val.order + 1) {
            this.isPlay = false;
            return;
          }
        }
        this.audioPlayer.play();
        this.isPlay = val.order + 1;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.isPlay = false;
        }, val.duration * 1000);
      }
      this.chooseItem = val;
      this.$store.dispatch('video/set_cur_order', {
        order: val.order,
        time: 'click',
        beginTime: val.begin_time
      });
      // this.$store.dispatch('video/set_choose', true);
      this.$emit('changeEventBack', 'keySingle');
      this.$emit('changeKeyWordBack', 'single');
    },
    changeSumbitVisible() {
      this.sumbitVisible = false;
    },
    handleSelect(val) {
      this.modifyArr = val;
    },
    handleMouseScroll(e) {
      const isDialogWrapper = e.target.className === 'dialog-wrapper';
      if (isDialogWrapper) {
        // 先判断鼠标点击元素是否是class为"dialog-wrapper"的滚动元素
        const wrapperWidth = e.target.clientWidth;
        const mouseOffsetX = e.offsetX;
        const isInScrollBar = wrapperWidth - mouseOffsetX <= 14; // mac的滚动条宽度是14

        if (isInScrollBar) {
          // 以上主要是判断用户是否点击的是滚动条区域
          this.handleMouseWheel();
        }
      }
    },
    handleMouseWheel() {
      this.$store.commit('video/SET_IS_USER_SCROLL', true);
    },
    handleScroll(e) {
      var _this$$refs$contWrapp2;
      this.loadMoreSentence(e);
      this.scrollTop = (_this$$refs$contWrapp2 = this.$refs.contWrapper) === null || _this$$refs$contWrapp2 === void 0 ? void 0 : _this$$refs$contWrapp2.scrollTop;
    },
    updateSliceSentence(order) {
      if (order < this.sliceStartIndex || order > this.sliceEndIndex) {
        this.sliceStartIndex = order - 20;
        this.sliceEndIndex = order + 20;
      }
    },
    loadMoreSentence(e) {
      // 上拉加载
      if (!this.sliceSentence || !this.sliceSentence.length) return;
      if (e.target.scrollTop === 0) {
        const order = this.sliceSentence[0].order;
        this.sliceStartIndex -= 15;
        // 保证当前句子视觉位置不变
        this.$nextTick(() => {
          const curSentence = this.$refs['dialogItem' + order][0];
          this.$refs.contWrapper.scroll({
            top: curSentence.offsetTop
          });
        });
        // 下拉加载
      } else if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 150) {
        this.sliceEndIndex += 15;
      }
    },
    goVideo(item) {
      this.showIntSearch = true;
      this.$store.dispatch('video/change_begin', {
        begin_time: item.begin_time,
        time: new Date().getTime()
      });
      this.$store.dispatch('video/set_cur_order', {
        order: item.order,
        time: new Date().getTime()
      });
    },
    goKeyDetail(order, item) {
      if (item) {
        if (order === this.curResultOrder) {
          this.$store.dispatch('video/set_cur_order', {
            order: order,
            time: new Date().getTime(),
            beginTime: item.begin_time
          });
        } else {
          this.$store.dispatch('video/set_cur_order', {
            order: order,
            time: new Date().getTime(),
            beginTime: item.begin_time
          });
          this.curResultOrder = order;
        }
      }
    },
    backPlaying() {
      this.$store.commit('video/SET_IS_USER_SCROLL', false);
      const time = new Date().getTime();
      this.$store.dispatch('video/get_cur_order', {
        current_time: this.curtime,
        time: time
      });
    },
    openCorrectTextDialog(item) {
      this.$store.commit('video/SET_IS_VIDEO_PAUSE', true);
      this.correctTextItem = item;
      this.correctTextDialogVisible = true;
    },
    closeCorrectTextDialog() {
      this.correctTextDialogVisible = false;
    }
  }
};
exports.default = _default;