"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "intelligent-generation-form"
  }, [_c("div", {
    staticClass: "intelligent-generation__content"
  }, [_c("p", {
    staticClass: "title-tips"
  }, [_vm._v("以下是为您编排的提示信息")]), _c(_setup.IntelligentGenerationPromptForm, {
    ref: "customerInfoFormRef",
    attrs: {
      "label-title": "客户信息标题",
      "value-title": "客户信息描述",
      desc: _setup.customerInfoFormDesc
    },
    on: {
      "update:desc": function ($event) {
        _setup.customerInfoFormDesc = $event;
      },
      "add-field": function ($event) {
        return _setup.addField(_setup.customerInfoFormDesc);
      },
      "remove-field": function ($event) {
        return _setup.removeField($event, _setup.customerInfoFormDesc);
      }
    }
  }), _c(_setup.IntelligentGenerationPromptForm, {
    ref: "progressFormRef",
    attrs: {
      "label-title": "对话流程标题",
      "value-title": "对话流程描述",
      desc: _setup.progressFormDesc
    },
    on: {
      "update:desc": function ($event) {
        _setup.progressFormDesc = $event;
      },
      "add-field": function ($event) {
        return _setup.addField(_setup.progressFormDesc);
      },
      "remove-field": function ($event) {
        return _setup.removeField($event, _setup.progressFormDesc);
      }
    }
  }), _vm._m(0)], 1), _c("footer", {
    staticClass: "intelligent-generation__footer"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info"
    },
    on: {
      click: _setup.cancel
    }
  }, [_vm._v("返回")]), _c("MgvButton", {
    on: {
      click: _setup.generatePrompt
    }
  }, [_vm._v("确认使用")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "content-tip"
  }, [_vm._v(" 如需设置AI客户"), _c("span", [_vm._v("关键问题 & 标准话术")]), _vm._v("参与评分，请在下一步生成的“对话流程提示词”中编辑 ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;