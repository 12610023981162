"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog save-prompt-dialog",
    attrs: {
      visible: _vm.visible,
      title: "存为模版",
      width: "600px",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("MgvButton", {
          attrs: {
            type: "plain",
            color: "info"
          },
          on: {
            click: function ($event) {
              return _setup.updateVisible(false);
            }
          }
        }, [_vm._v("取消")]), _c("MgvButton", {
          attrs: {
            loading: _setup.createBtnLoading,
            disabled: !_setup.formData.name
          },
          on: {
            click: _setup.createTemplate
          }
        }, [_vm._v("保存")])];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      "label-position": "top",
      model: _setup.formData,
      rules: _setup.rules
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
        return _setup.createTemplate.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "模版名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入模版名称",
      maxlength: "20",
      type: "text",
      "show-word-limit": ""
    },
    model: {
      value: _setup.formData.name,
      callback: function ($$v) {
        _vm.$set(_setup.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;