"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomEventInput = _interopRequireDefault(require("./CustomEventInput.vue"));
var _keyEventManagement = require("@/api/keyEventManagement");
var _useMessage = require("@/hooks/use-message");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateKeyEventFolderDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    createFolderPid: {
      type: String,
      default: ''
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const folderName = (0, _vue.ref)('');
    const btnLoading = (0, _vue.ref)(false);
    const nameInputRef = (0, _vue.ref)(null);
    const submit = async () => {
      btnLoading.value = true;
      const res = await (0, _keyEventManagement.createKeyEventFolder)({
        workspace_id: store.getters.workspaceInfo.id,
        name: nameInputRef.value.getName(),
        pid: props.createFolderPid || '0'
      }).finally(() => btnLoading.value = false);
      if (res.code === 20000) {
        _useMessage.useMessage.success('创建成功');
        emit('createFolderSuccess', {
          name: folderName.value,
          id: res.results.folder_id
        });
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      store,
      emit,
      updateVisible,
      folderName,
      btnLoading,
      nameInputRef,
      submit,
      CustomEventInput: _CustomEventInput.default
    };
  }
};
exports.default = _default;