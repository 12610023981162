"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "generator-form-item-operation"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("el-button", {
    staticClass: "drag icon-button"
  }, [_c("i", {
    staticClass: "iconfont move icon-drag-dot"
  })]), !_vm.isEditStatus ? _c(_setup.FormItemTypeTag, {
    staticClass: "form-item-type-tag",
    attrs: {
      "field-type-id": _vm.fieldTypeId,
      "is-edit-status": _vm.isEditStatus
    },
    on: {
      "update:fieldTypeId": _setup.onFieldTypeIdChange
    }
  }) : _vm._e()], 1), !_vm.isEditStatus ? _c("div", {
    staticClass: "right-part"
  }, [_c("el-switch", {
    staticClass: "requird-switch",
    attrs: {
      value: _vm.required,
      width: 26,
      "active-value": 1,
      "inactive-value": 0
    },
    on: {
      input: _setup.onCheckBoxChange
    }
  }), _c("span", {
    staticClass: "required-text"
  }, [_vm._v("必填")]), _c("div", {
    staticClass: "divider"
  }), _c("el-button", {
    staticClass: "delete-button icon-button",
    on: {
      click: _setup.onDeleteClick
    }
  }, [_c("i", {
    staticClass: "iconfont icon-delete"
  })])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;