"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "conversation-detail-left-content",
    class: {
      "hide-menu": _vm.isHideMenu
    }
  }, [_c("div", {
    staticClass: "conversation-detail-left-container"
  }, [_c("div", {
    staticClass: "menu-container"
  }, [_c("div", {
    staticClass: "header",
    attrs: {
      id: "conv-detail-left-menu-header"
    }
  }, [_c("div", {
    ref: "menuTabs",
    staticClass: "menu-tabs",
    attrs: {
      id: "menu-tabs"
    }
  }, _vm._l(_vm.menuList, function (menu) {
    return _c("div", {
      key: menu.name,
      staticClass: "menu-tab",
      class: {
        active: _vm.activeTab === menu.name
      },
      attrs: {
        name: menu.name,
        "data-v-step": menu.name === "complianceScore" ? "complianceScoreTab" : ""
      },
      on: {
        click: function ($event) {
          return _vm.switchTab(menu);
        }
      }
    }, [_c("span", [_c("i", {
      class: ["iconfont", _vm.activeTab == menu.name ? `icon-${menu.activeIcon}` : `icon-${menu.icon}`]
    }), _c("span", {
      staticClass: "menu-name"
    }, [_vm._v(_vm._s(menu.label))])])]);
  }), 0), _vm.isLeftArrowShow ? _c("div", {
    staticClass: "arrow-button arrow-left"
  }, [_c("div", {
    staticClass: "arrow-container"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(false);
      }
    }
  })]), _c("div", {
    staticClass: "arrow-border"
  })]) : _vm._e(), _vm.isRightArrowShow ? _c("div", {
    staticClass: "arrow-button arrow-right"
  }, [_c("div", {
    staticClass: "arrow-border"
  }), _c("div", {
    staticClass: "arrow-container"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-right",
    on: {
      click: function ($event) {
        return _vm.handleArrowClick(true);
      }
    }
  })])]) : _vm._e()]), _c("keep-alive", [_c(_vm.handleComponent(), {
    tag: "component",
    staticClass: "component-content",
    attrs: {
      "conv-id": _vm.convId,
      "can-edit-key-event": _vm.canEditKeyEvent,
      "api-process": _vm.apiProcess,
      "participants-info": _vm.participantsInfo,
      "external-key-event-id": _vm.externalKeyEventId,
      "external-account-tag": _vm.externalAccountTag,
      "media-type": _vm.mediaType
    },
    on: {
      triggerSearch: function ($event) {
        return _vm.$emit("triggerSearch", $event);
      }
    }
  })], 1), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "收起",
      placement: "top"
    }
  }, [_c("div", {
    staticClass: "pack-up-btn",
    on: {
      click: _vm.handleExpandOrPackUp
    }
  }, [_c("i", {
    staticClass: "iconfont icon-menu-collapse"
  })])])], 1)]), _vm.isHideMenu ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      slot: "label",
      effect: "dark",
      content: "展开",
      placement: "top"
    },
    slot: "label"
  }, [_c("div", {
    staticClass: "expand-btn",
    on: {
      click: _vm.handleExpandOrPackUp
    }
  }, [_c("i", {
    staticClass: "iconfont icon-menu-expansion"
  })])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;