"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MoreOperations',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    stopBubbling: {
      type: Boolean,
      default: true
    },
    trigger: {
      type: String,
      default: 'click'
    },
    icon: {
      type: String,
      default: 'el-icon-more'
    },
    menuClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    handleCommand(command) {
      if (!command) return;
      this.$emit('command', command);
    },
    visibleChange(val) {
      this.visible = val;
    }
  }
};
exports.default = _default;