"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-card", {
    staticClass: "deal-card"
  }, [_c("div", {
    on: {
      click: function ($event) {
        return _vm.goDealDetail(_vm.dealCardContent.deal_id);
      }
    }
  }, [_c("header", {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.dealCardContent.deal_name) + " ")]), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "card-avatar-name"
  }, [_c("image-avatar", {
    staticClass: "upper-avatar",
    attrs: {
      name: _vm.dealCardContent.user_name,
      avatar: _vm.dealCardContent.user_avatar,
      shape: "circle"
    }
  }), _c("div", {
    staticClass: "follower-name"
  }, [_vm._v(_vm._s(_vm.dealCardContent.user_name))])], 1), _c("div", {
    staticClass: "create-time"
  }, [_vm._v(" 商机创建时间：" + _vm._s(_vm.dealCardContent.create_at) + " ")])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;