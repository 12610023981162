"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _timeFormatter = require("@/utils/time-formatter");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    chartConfig: {
      type: [Object, Array],
      default: () => {}
    },
    chartLoading: Boolean,
    tableConfigs: {
      type: Object,
      default: () => ({})
    },
    // 可配置的渲染模式：解决拖拽功能下：canvas渲染模式的图表复制dom后无法重新渲染
    rendererType: {
      type: String,
      default: 'canvas'
    }
  },
  data() {
    return {
      chart: null,
      options: {}
    };
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart, null, {
      renderer: this.rendererType
    });
    this.init();
    window.addEventListener('resize', this.chartResize);
  },
  updated() {
    this.chart.resize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartResize);
  },
  methods: {
    init() {
      // 如果有图表配置参数则定义配置
      if (JSON.stringify(this.chartConfig) !== '{}') {
        // 给散点图和柱状图设置自定义配置项
        if (this.options.chartName && (this.options.chartName === 'scatter' || this.options.chartName === 'bar')) {
          // 坐标轴配置
          this.options.series.encode = {
            x: this.chartConfig.xAxis.name,
            y: this.chartConfig.yAxis.name
          };
          ['name', 'max', 'min'].forEach(item => {
            this.options.xAxis[item] = this.chartConfig.xAxis[item];
            this.options.yAxis[item] = this.chartConfig.yAxis[item];
          });
          // 散点图特殊配置
          this.chart.clear();
          this.options.chartName === 'scatter' && this.scatterInit();
        }
      }
      this.chart.setOption(this.options);
    },
    chartResize() {
      this.init();
      this.chart.resize();
    },
    getYAxisLabel(val) {
      switch (this.unit) {
        case 'time':
          return (0, _timeFormatter.secondToCNTime)(val);
        case 'count':
          return `${parseFloat(val.toFixed(2))}次`;
        case 'countPerTenMin':
          return `${parseFloat(val.toFixed(2))}次/10分钟`;
        case 'money':
          return `${val}元`;
        case 'percent':
          return `${Math.round(val * 10000) / 100}%`;
        case 'score':
          return `${val.toLocaleString('zh', {
            maximumFractionDigits: 1
          })}分`;
      }
    }
  }
};
exports.default = _default;