"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _user = require("@/api/user");
var _crypto = _interopRequireDefault(require("crypto"));
var _VerificationCodeInput = _interopRequireDefault(require("@/components/VerificationCodeInput"));
var _PasswordInput = _interopRequireDefault(require("@/components/PasswordInput"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Register',
  components: {
    passwordInput: _PasswordInput.default,
    VerificationCodeInput: _VerificationCodeInput.default
  },
  data() {
    const repasswordValidator = (rule, value, callback) => {
      if (value === '' && this.registerForm.password) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        tel: '',
        email: '',
        code: '',
        password: '',
        rePassword: ''
      },
      registerRules: {
        tel: [{
          required: true,
          validator: _validate.internationalTelValidator,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: _validate.emailValidator,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          validator: _validate.codeValidator,
          trigger: 'change'
        }],
        password: '',
        rePassword: [{
          required: true,
          trigger: 'change',
          validator: repasswordValidator
        }]
      },
      resetLoading: false,
      loginMethod: ''
    };
  },
  created() {
    this.loginMethod = localStorage.getItem('loginMethod');
  },
  methods: {
    validFormField(prop) {
      let res;
      this.$refs.registerForm.validateField(prop, valid => {
        res = valid;
      });
      return res;
    },
    sendCode() {
      const validObj = {
        tel: this.validFormField('tel'),
        email: this.validFormField('email')
      };
      if (!validObj[this.loginMethod]) {
        this.$refs.codeInput.showTencentVerify();
      }
    },
    submitForm() {
      this.handleReset();
    },
    handleReset() {
      this.$refs.registerForm.validate(async valid => {
        const passValid = this.$refs.passwordInput.valid;
        if (valid && passValid) {
          this.resetLoading = true;
          const {
            tel,
            code,
            password,
            email
          } = this.registerForm;
          const {
            pub_key,
            data: rsaPass
          } = await this.Rsa.getRsaCode(password);
          const resetForm = {
            tel,
            code,
            email,
            method: this.loginMethod,
            new_password: rsaPass,
            pub_key
          };
          const res = await (0, _user.resetPassword)(resetForm).finally(() => this.resetLoading = false);
          if (res.code === 20000) {
            this.$message.success('重置密码成功，请重新登录');
            this.$router.push({
              path: _routeMap.ROUTES.login
            });
          }
        }
      });
    },
    goLogin() {
      this.$router.push({
        path: _routeMap.ROUTES.login
      });
    },
    md5Password(password) {
      var md5 = _crypto.default.createHash('md5');
      md5.update(password); // 需要加密的密码
      var md5Password = md5.digest('hex'); // password 加密完的密码
      return md5Password;
    }
  }
};
exports.default = _default;