"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      "visible-arrow": false,
      placement: "bottom-start",
      width: "400",
      trigger: "click",
      "popper-class": "list-popover"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "conversation-info"
  }, [_c("i", {
    staticClass: "iconfont icon-user"
  }), _c("span", [_vm._v(_vm._s(_vm.salesName))]), _c("i", {
    staticClass: "iconfont icon-team-switch-2"
  }), _c("i", {
    staticClass: "iconfont icon-office-building"
  }), _c("span", [_vm._v(_vm._s(_vm.customerName))])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "list"
  }, _vm._l(_vm.conversationList, function (item) {
    return _c("div", {
      key: item.conv_id,
      ref: `item-${item.conv_id}`,
      refInFor: true,
      staticClass: "item",
      class: {
        "active-item": item.conv_id === Number(_vm.convId)
      },
      on: {
        click: function ($event) {
          return _vm.changeId(item.conv_id);
        }
      }
    }, [_c("span", {
      staticClass: "conv-time"
    }, [_vm._v(_vm._s(_vm.getDay(item.create_at)))]), _vm._v(" · "), _c("i", {
      class: "iconfont " + _vm.getConvClass(item.media_type)
    }), _c("span", {
      staticClass: "conv-name"
    }, [_vm._v(_vm._s(item.conv_name))]), _vm.convId == item.conv_id ? _c("span", {
      staticClass: "status active"
    }, [_vm._v(" 当前质检 ")]) : _vm.statusConfig[item.status - 1] ? _c("span", {
      class: ["status", _vm.statusConfig[item.status - 1].class]
    }, [_c("i", {
      class: ["iconfont", _vm.statusConfig[item.status - 1].icon]
    }), _vm._v(" " + _vm._s(_vm.statusConfig[item.status - 1].text) + " ")]) : _vm._e()]);
  }), 0)]), _c("div", {
    staticClass: "conv-title",
    attrs: {
      slot: "reference",
      id: "conv-detail-header-title"
    },
    slot: "reference"
  }, [_c("h1", [_vm._v(_vm._s(_vm.conversationTitleInfo.convName))]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShow,
      expression: "isShow"
    }]
  }, [_c("span", {
    staticClass: "icons"
  }, [_c("i", {
    staticClass: "iconfont icon-user"
  }), _c("i", {
    staticClass: "iconfont icon-team-switch-2"
  }), _c("i", {
    staticClass: "iconfont icon-office-building"
  })]), _vm.totalCount ? _c("span", [_vm._v(_vm._s(_vm.currentIndex) + " / " + _vm._s(_vm.totalCount))]) : _vm._e(), _c("i", {
    staticClass: "el-icon-arrow-down"
  })])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;