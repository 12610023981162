"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceWorkSpaceHeader = _interopRequireDefault(require("./components/ComplianceWorkSpaceHeader"));
var _ConversationDetailLeftContent = _interopRequireDefault(require("@/views/convDetail/components/ConversationDetailLeftContent"));
var _ConvPlayContent = _interopRequireDefault(require("@/components/ConvPlayContent"));
var _ConvControlContent = _interopRequireDefault(require("@/components/Video/ConvControlContent"));
var _CommentDrawer = _interopRequireDefault(require("@/components/CommentDrawer"));
var _videoDetail = require("@/api/videoDetail");
var _workspace = require("@/api/workspace");
var _routeMap = require("@/router/routeMap");
var _index = require("@/utils/index");
var _vuex = require("vuex");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvDetail',
  components: {
    ComplianceWorkSpaceHeader: _ComplianceWorkSpaceHeader.default,
    ConversationDetailLeftContent: _ConversationDetailLeftContent.default,
    ConvPlayContent: _ConvPlayContent.default,
    ConvControlContent: _ConvControlContent.default,
    CommentDrawer: _CommentDrawer.default
  },
  inject: ['reload'],
  provide() {
    return {
      getActiveCommentId: this.getActiveCommentId,
      getConvSpaceId: this.getConvSpaceId,
      flipRoleApi: this.flipRoleApi,
      getConversationDetail: this.getConversationDetail
    };
  },
  data() {
    return {
      localSliderTime: 0,
      hasDetailInfo: false,
      rightBarActive: 'compliance',
      videoUrl: '',
      // 视频的url
      partiInfo: [],
      // 参与者信息
      sharePercent: null,
      // 共享屏幕的占比
      shareScreen: [],
      // 共享屏幕的音频
      tabActiveName: 'transtext',
      // tab激活栏
      convId: '',
      // conversation_id
      searchWords: '',
      // 搜索词
      overviewInfo: {},
      // 概览基本信息
      apiProcess: {
        is_es_insert_complete: false,
        // 音频信息
        is_qa_complete: false,
        // 问答
        is_event_engine_complete: false,
        // 关键事件
        is_keyword_complete: false,
        // 关键词
        is_qa_keyword_complete: false,
        // 报告关键词
        is_abstract_complete: false // 报告摘要
      },

      // 返回的其他接口的请求进度
      resultList: [],
      origin: '',
      // 会话类型
      uploadType: '',
      // 是否绑定商机或线索
      mediaType: '',
      // 会话类型
      hasRole: '',
      // 是否选定角色
      isVideoError: false,
      // 判断视频资源是否有问题
      activeCommentId: null,
      // 消息转过来的打开评论的页面传递的commentId
      originType: '',
      // 文字剪辑的标识
      isSnippetStatus: false,
      workspace: {},
      commentsCount: 0,
      excludeMenu: [],
      // 排除部分没有权限的模块
      canEditKeyEvent: false,
      chatDrawerVisible: false,
      videoDuration: 0,
      hasEvaluation: false,
      departmentManagerInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curtime', 'audio', 'isEsInsertComplete', 'curorder', 'is_user_scroll', 'webSocketMsg', 'isChoose', 'is_video_pause', 'workspaceInfo', 'timezone', 'isShowStageIIComplianceWorkSpaceGuide']),
    isRightContentExpand() {
      return this.rightBarActive || this.chatDrawerVisible;
    }
  },
  watch: {
    rightBarActive(val) {
      if (!val) {
        this.activeCommentId = null;
      }
    },
    hasEvaluation() {
      this.rightBarActive = 'evaluation';
    },
    curtime(val) {
      if (!this.is_user_scroll && !this.is_video_pause // 修复关键事件跳转时跳转到错误句子的问题
      ) {
        this.$store.dispatch('video/get_cur_order', {
          current_time: val,
          time: new Date().getTime()
        });
      }
    },
    convId: {
      handler: function (val) {
        this.changeConvId(val);
        this.commentsCount = 0;
        this.searchWords = '';
        this.isSnippetStatus = false;
        this.$store.commit('video/SET_TRANS_TEXT_LOADING', true);
        if (this.$refs['mainContent']) {
          this.$refs['mainContent'].inputWords = '';
        }
      },
      immediate: true
    },
    partiInfo: {
      handler: function (value) {
        if (Array.isArray(value)) {
          this.handleSearchResultAvater();
        }
      },
      deep: true
    },
    webSocketMsg: {
      handler: function (newVal) {
        var _newVal$extra_info, _newVal$extra_info2;
        if (newVal.type === 'conversation_completed' &&
        // eslint-disable-next-line eqeqeq
        newVal.data.conversation_id == this.convId) {
          (0, _index.msgDispatcher)(newVal, 'conversation_completed', this.detailInfoFun);
        }
        // 如果是评论相关的消息，是本会话的需要刷新评论数据
        if (newVal.type === 2 &&
        // eslint-disable-next-line eqeqeq
        ((_newVal$extra_info = newVal.extra_info) === null || _newVal$extra_info === void 0 ? void 0 : _newVal$extra_info.entity_type) === 'conversation' && ((_newVal$extra_info2 = newVal.extra_info) === null || _newVal$extra_info2 === void 0 ? void 0 : _newVal$extra_info2.entity_id) === Number(this.convId)) {
          if (this.rightBarActive) {
            if (this.$refs.rightDrawer.$refs.comments) {
              this.$refs.rightDrawer.$refs.comments.getComments();
            }
          }
        }
      },
      deep: true
    },
    audio(val) {
      if (val && val.length) {
        this.handleRouterOrder();
      }
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.handleBeforeEnterConvDetailPage(to, from, next);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('video/set_conversation_info', {
      convName: null,
      convTime: null,
      convDuration: null
    });
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    // this.handleBeforeEnterConvDetailPage(to, from, next);
    // const isNoBackButton = from.query.noback; // 判断原页面是否是新页面打开
    // // eslint-disable-next-line no-prototype-builtins
    // const isTimeLineChange = to.query.hasOwnProperty('index'); // 判断是否是商机集合处切换页面
    // const splitToUrl = to.fullPath.split('&');
    const conv_id = to.query.id ? to.query.id : '';
    // if (!/^[0-9]*$/.test(conv_id)) conv_id = 0;
    this.convId = conv_id.toString();
    // if (splitToUrl.length > 2 && !isTimeLineChange) {
    //   if (isNoBackButton) {
    //     const newUrl = splitToUrl[0] + '&noback=true';
    //     this.$router.replace(newUrl);
    //   } else {
    //     const newUrl = splitToUrl[0];
    //     this.$router.replace(newUrl);
    //   }
    // }
    next();
  },
  created() {
    let conv_id = this.$route.query.id ? this.$route.query.id : '';
    if (!/^[0-9]*$/.test(conv_id)) conv_id = 0;
    this.convId = conv_id.toString();
    this.getComments();
    this.$store.dispatch('video/set_comment_status', 'default');
  },
  mounted() {
    this.shareOptions = [];
    // document.addEventListener('click', this.clickEvent, true);
    // 如果页面上有评论id, 则打开右侧的评论
    const commentId = this.$route.query.commentId ? this.$route.query.commentId : '';
    if (commentId) {
      this.rightBarActive = 'comments';
      this.activeCommentId = Number(commentId);
    }
  },
  destroyed() {
    // document.removeEventListener('click', this.clickEvent, true);
  },
  methods: {
    flipRoleApi(_ref) {
      let {
        conversation_id,
        is_flip_all = true,
        flip_orders = []
      } = _ref;
      _elementUi.MessageBox.confirm('翻转角色后，发言归属的角色将对调，会话重新分析可能会花费一些时间，是否继续？', '翻转角色', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await (0, _videoDetail.flipRole)({
          conversation_id,
          is_flip_all,
          flip_orders
        });
        if (res.code === 20000) {
          this.changeSentenceRole(flip_orders);
        }
      }).catch(() => {});
    },
    changeSentenceRole(flip_orders) {
      console.log(flip_orders, 'flip_orders');
      if (!this.audio && !this.audio.length) return;
      const nameMap = {};
      this.partiInfo.map(item => {
        nameMap[item.entity_type] = {
          entity_id: item.entity_id,
          name: item.name,
          entity_type: item.entity_type,
          avatar: item.avatar
        };
      });
      if (flip_orders.length) {
        flip_orders.map(order => {
          if (this.audio[order].entity_type === 'host_salesman') {
            this.audio[order] = Object.assign(this.audio[order], nameMap['customer_contact']);
          } else {
            this.audio[order] = Object.assign(this.audio[order], nameMap['host_salesman']);
          }
        });
      } else {
        this.audio.map(item => {
          if (item.entity_type === 'host_salesman') {
            item = Object.assign(item, nameMap['customer_contact']);
          } else {
            item = Object.assign(item, nameMap['host_salesman']);
          }
        });
      }
    },
    handleComment() {
      this.rightBarActive = 'comments';
    },
    changeRightBar(val) {
      this.hasEvaluation = true;
    },
    // 剪辑
    addVideoToSnippet() {
      this.rightBarActive = '';
      this.tabActiveName = 'transtext';
      this.isSnippetStatus = true;
      this.$store.commit('video/SET_DIALOG_TEXT_SCROLLABLE', false);
      this.$store.commit('video/SET_SNIPPET_SLIDER_RANGE', [0, 100]);
      // 点击添加至剪辑库的时候 默认选中全部
      this.$refs['convControlContent'].snippetVideoSliderChange([0, 100]);
    },
    onCancelSnippet() {
      this.isSnippetStatus = false;
    },
    correctTextSuccess() {
      if (this.rightBarActive === 'correctTextContainer') {
        var _this$$refs$rightDraw, _this$$refs$rightDraw2;
        (_this$$refs$rightDraw = this.$refs.rightDrawer) === null || _this$$refs$rightDraw === void 0 ? void 0 : (_this$$refs$rightDraw2 = _this$$refs$rightDraw.$refs.correctTextContainer) === null || _this$$refs$rightDraw2 === void 0 ? void 0 : _this$$refs$rightDraw2.getCorrectTextHistory(true);
      }
    },
    async handleBeforeEnterConvDetailPage(to, from, next) {
      // if (JSON.stringify(to.params) === '{}' && from.name) {
      //   sessionStorage.removeItem('allComplianceOfConversationIds');
      //   sessionStorage.removeItem('currentConversationInComplianceIdx');
      // }

      let workspaceInfo = JSON.parse(sessionStorage.getItem('workspaceInfo'));
      if (!workspaceInfo) {
        (0, _workspace.getLatestWorkspaceInfo)().then(async ans => {
          if (ans.code === 20000) {
            workspaceInfo = ans.results;
            sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
            const res = await (0, _videoDetail.checkDetailRole)(to.query.id, workspaceInfo.id);
            if (res.code === 20000) {
              next();
            }
          }
        });
      } else {
        const res = await (0, _videoDetail.checkDetailRole)(to.query.id, workspaceInfo.id);
        if (res.code === 20000) {
          next();
        }
      }
    },
    async getComments() {
      if (!/^[0-9]*$/.test(this.convId)) return;
      const res = await (0, _videoDetail.getConvComments)({
        conversation_id: this.convId
      });
      this.commentsCount = res.results.total;
    },
    selectTransText() {
      this.tabActiveName = 'transtext';
    },
    changeSliderTime(sliderTime) {
      this.localSliderTime = sliderTime;
    },
    closeDrawer() {
      this.rightBarActive = '';
    },
    switchTab(type) {
      this.tabActiveName = type;
    },
    // clickEvent(e) {
    //   setTimeout(() => {
    //     if (this.isChoose) {
    //       if (
    //         this.$refs.rightDrawer &&
    //         !this.$refs.rightDrawer.$el.contains(e.target)
    //       ) {
    //         if (
    //           this.$refs.mainContent.$refs.transtext.$refs.dialogText.$refs[
    //             `dialogItem${this.curorder.value}`
    //           ] &&
    //           !this.$refs.mainContent.$refs.transtext.$refs.dialogText.$refs[
    //             `dialogItem${this.curorder.value}`
    //           ][0]?.contains(e.target) &&
    //           this.$refs.mainContent.$refs.transtext.$refs.dialogText
    //         ) {
    //           if (e.target.closest('#atwho-ul')?.id === 'atwho-ul') {
    //             this.$store.dispatch('video/set_choose', true);
    //           } else {
    //             this.$store.dispatch('video/set_choose', false);
    //           }
    //         } else {
    //           this.$store.dispatch('video/set_choose', true);
    //         }
    //       }
    //     }
    //   }, 100);
    // },
    goTransText(words) {
      this.searchWords = words;
      this.$refs['mainContent'].inputWords = words;
      this.getSearchResult();
    },
    changeConversation(id, firstIndex) {
      this.convId = id;
      this.$router.replace({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: id,
          index: firstIndex,
          noback: this.$route.query.noback
        }
      });
    },
    changeConvId(convId) {
      const convIdStr = convId.toString();
      if (!convIdStr) {
        return false;
      } else {
        this.$store.dispatch('video/set_cur_conv_id', {
          cur_conv_id: convIdStr
        }).then(() => {
          this.detailInfoFun();
        }).catch(err => {
          throw new Error(err);
        });
      }
    },
    openTextTab() {
      this.tabActiveName = 'transtext';
    },
    goKeyDetail(order, item) {
      this.openTextTab();
      this.$refs.mainContent.$refs.transtext.goKeyDetail(order, item);
    },
    getSearchResult() {
      (0, _videoDetail.getWordsSearch)(this.convId, this.searchWords, this.workspaceInfo.id).then(res => {
        this.resultList = res.results.sentences;
        this.handleSearchResultAvater();
        this.$refs.mainContent.showResults();
      }).finally(() => {});
    },
    handleSearchResultAvater() {
      if (this.resultList && this.resultList.length) {
        this.resultList.forEach(resItem => {
          if (this.partiInfo && this.partiInfo.length) {
            this.partiInfo.forEach(partItem => {
              if (partItem.entity_id === resItem.entity_id && partItem.entity_type === resItem.entity_type) {
                resItem.name = partItem.name;
                resItem.avatar = partItem.avatar;
              }
            });
          }
        });
      }
    },
    detailInfoFun() {
      const params = {
        conversation_id: this.convId,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      (0, _videoDetail.getConversationDetailInfo)(params).then(async res => {
        if (res.code === 20000) {
          this.hasDetailInfo = true;
        } else if (res.code === 40704) {
          return;
        }
        const results = res.results;
        const {
          id,
          task_id,
          sampling_type
        } = this.$route.query;
        const query = {
          id,
          task_id,
          sampling_type
        };
        if (results.account_sale_conv_id) {
          query.sampling_id = results.account_sale_conv_id;
        }
        this.$router.replace({
          name: 'ComplianceWorkSpace',
          query
        });
        if (Number(sampling_type)) {
          this.$refs.convDetailHeader.$refs.ConversationSelector.getAccountSaleConvList();
        }
        this.overviewInfo = results;
        this.resumeUrl = results.resume;
        this.videoUrl = await this.getVideoUrl(results.video.url, results.video.id);
        this.videoDuration = results.video.duration;
        this.partiInfo = results.participants;
        this.origin = results.origin;
        this.uploadType = results.type;
        this.mediaType = results.media_type;
        this.hasRole = results.has_role;
        this.apiProcess = results.process;
        this.originType = results.origin_type;
        this.workspace = results.workspace;
        this.excludeMenu = results.exclude_labels;
        this.canEditKeyEvent = results.can_edit;
        this.$store.commit('video/SET_HAS_VIDEO', results.has_video);
        this.$store.commit('video/SET_CUR_MEDIA_TYPE', this.mediaType);
        this.departmentManagerInfo = {
          id: results.tree_info.charge_id,
          name: results.tree_info.charge_name,
          avatar: results.tree_info.charge_avatar,
          tel: results.tree_info.charge_tel,
          is_department_manager: true
        };
        this.sharePercent = typeof results.share_percent === 'number' ? results.share_percent.toString() : '';
        this.shareScreen = results.share_screen;
        if (!this.hasRole) {
          this.$store.dispatch('video/set_guide_visiable', true);
        } else {
          this.$store.dispatch('video/set_guide_visiable', false);
        }
        this.$store.dispatch('video/set_conversation_info', {
          convName: results.name,
          convTime: results.date,
          convDuration: results.video.duration
        });
        // 将详情信息存到本地供分析报告使用
        const detailStr = JSON.stringify(res.results);
        window.sessionStorage.setItem('conv_detail_' + this.convId, detailStr);
        this.$store.commit('video/SET_TRANS_TEXT_LOADING', false);
        // 需要等待的请求接口
        this.$store.dispatch('video/set_audio_all', {
          cur_conv_id: this.convId,
          type: 'conversation',
          has_workspace: false,
          has_entity_id: this.partiInfo[0].entity_id
        }).then(() => {
          if (!(this.audio.length || this.isEsInsertComplete)) {
            this.$store.commit('video/SET_AUDIO_ALL', null);
          }
        });
      });
    },
    async getVideoUrl(url, video_id) {
      if (url.indexOf('.m3u8') !== -1) {
        const res = await (0, _videoDetail.getAuthorityM3U8Url)(this.convId, video_id, this.workspaceInfo.id);
        if (res.code === 200) {
          return res.results.file_name;
        }
      } else {
        return url;
      }
    },
    handleRouterOrder() {
      // 如果页面上有搜索关键词，则打开搜索
      const keyWords = this.$route.query.keywords ? this.$route.query.keywords : '';
      const routerActiveOrder = this.$route.query.order || '';
      if (keyWords) {
        this.goTransText(keyWords);
      }
      if (routerActiveOrder !== '') {
        if (this.$refs.mainContent.$refs.searchResult) {
          this.$refs.mainContent.$refs.searchResult.goKeyDetail(Number(routerActiveOrder));
        }
      }
      if (keyWords && routerActiveOrder !== '') {
        this.tabActiveName = 'transtext';
        this.$store.dispatch('video/set_cur_order', {
          order: Number(routerActiveOrder),
          time: new Date().getTime()
        });
      }
    },
    selectActiveBar(name) {
      if (this.rightBarActive === name) {
        this.rightBarActive = '';
      } else {
        this.chatDrawerVisible = false;
        this.rightBarActive = name;
      }
    },
    changeChatDrawerVisible() {
      if (!this.chatDrawerVisible) {
        this.rightBarActive = '';
      }
      this.chatDrawerVisible = !this.chatDrawerVisible;
    },
    getActiveCommentId() {
      return this.activeCommentId;
    },
    getConvSpaceId() {
      return this.workspace.id;
    },
    getConversationDetail() {
      return this.overviewInfo;
    }
  }
};
exports.default = _default;