"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImportHeader = _interopRequireDefault(require("./components/ImportHeader"));
var _ImportFile = _interopRequireDefault(require("./components/ImportFile"));
var _ImportResults = _interopRequireDefault(require("./components/ImportResults"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _managementImport = require("@/api/managementImport");
var _lodash = require("lodash");
var _useVuex = require("@/hooks/use-vuex");
var _i18n = require("@/i18n");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const route = (0, _useRouter.useRoute)();
    const router = (0, _useRouter.useRouter)();
    const store = (0, _useVuex.useStore)();
    const importObj = {
      [_routeMap.ROUTES.ImportMembers]: {
        title: (0, _i18n.t)('management.导入成员'),
        downloadTemplateAddress: store.getters.isTwoFactor ? 'https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/%E6%88%90%E5%91%98%E5%AF%BC%E5%85%A5-%E8%B4%A6%E5%8F%B7%E6%A8%A1%E6%9D%BF.xlsx' : 'https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/%E6%88%90%E5%91%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        uploadRequestFunc: _managementImport.importMembers
      },
      [_routeMap.ROUTES.ImportDepartment]: {
        title: (0, _i18n.t)('management.导入部门'),
        downloadTemplateAddress: 'https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        uploadRequestFunc: _managementImport.importDepartments
      },
      [_routeMap.ROUTES.ImportBusinessRole]: {
        title: (0, _i18n.t)('management.导入成员角色'),
        downloadTemplateAddress: 'https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/%E4%B8%9A%E5%8A%A1%E8%A7%92%E8%89%B2%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        uploadRequestFunc: _managementImport.importBusinessRole
      },
      [_routeMap.ROUTES.ImportManagerRole]: {
        title: (0, _i18n.t)('management.导入管理员角色'),
        downloadTemplateAddress: 'https://megaview.oss-cn-zhangjiakou.aliyuncs.com/template/%E7%AE%A1%E7%90%86%E5%91%98%E8%A7%92%E8%89%B2%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        uploadRequestFunc: _managementImport.importManagerRole
      }
    };
    const importResult = (0, _vue.ref)({});
    const importFileRef = (0, _vue.ref)(null);
    const retryImport = () => {
      importResult.value = {};
      importFileRef.value && importFileRef.value.initImportFile();
    };
    const importComplete = importResults => {
      importResult.value = importResults;
    };
    const goBack = () => {
      router.go(-1);
    };
    return {
      __sfc: true,
      route,
      router,
      store,
      importObj,
      importResult,
      importFileRef,
      retryImport,
      importComplete,
      goBack,
      ImportHeader: _ImportHeader.default,
      ImportFile: _ImportFile.default,
      ImportResults: _ImportResults.default,
      isEmpty: _lodash.isEmpty,
      t: _i18n.t
    };
  }
};
exports.default = _default;