"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mgvProgressLoading = _interopRequireDefault(require("@/components/mgvProgressLoading"));
var _keyEventDetailList = _interopRequireDefault(require("@/components/Detail/KeyEvent/keyEventDetailList"));
var _videoDetail = require("@/api/videoDetail");
var _vuex = require("vuex");
var _KnowledgeDetailDrawer = _interopRequireDefault(require("./KnowledgeDetailDrawer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MgvProgressLoading: _mgvProgressLoading.default,
    KeyEventDetailList: _keyEventDetailList.default,
    KnowledgeDetailDrawer: _KnowledgeDetailDrawer.default
  },
  props: {
    info: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | null,
      default: () => []
    },
    apiProcess: {
      type: Object,
      default: function () {
        return null;
      }
    },
    mediaType: {
      type: String,
      default: ''
    },
    participantsInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      biComplete: '',
      eventEngineComplete: '',
      activeLabel: '',
      activeDimensionDetailData: [],
      count: 0,
      loading: false,
      knowledgeDetailDrawerVisible: false,
      activeKnowledgeId: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['modulePermission', 'workspaceInfo']),
    goBiPersonPermission() {
      return this.modulePermission.biDimension;
    }
  },
  watch: {
    apiProcess: {
      handler: function (value) {
        if (value.is_bi_complete) {
          this.biComplete = true;
        } else {
          this.biComplete = false;
        }
        if (value.is_event_engine_complete) {
          this.eventEngineComplete = true;
        } else {
          this.eventEngineComplete = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    goDetail(item) {
      if (!item.clickable) return;
      this.activeLabel = item.label;
      this.getConversationAnalySisSentences(item.type);
    },
    async getConversationAnalySisSentences(type) {
      this.loading = true;
      const params = {
        workspace_id: this.workspaceInfo.id,
        conversation_id: Number(this.$route.query.id),
        type
      };
      const res = await (0, _videoDetail.getConversationAnalySisSentences)(params);
      if (res.code === 20000) {
        this.loading = false;
        this.count = res.results.count;
        this.activeDimensionDetailData = res.results.datas;
      }
    },
    openKnowLedgeDetail(id) {
      this.activeKnowledgeId = id;
      this.knowledgeDetailDrawerVisible = true;
    }
  }
};
exports.default = _default;