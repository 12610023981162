"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskDetailCard',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  inject: ['isEmbed', 'getEmbedPath'],
  props: {
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    currentUserTaskStatus: {
      type: Number,
      default: -1
    }
  },
  computed: {
    smartOrAi() {
      return [3, 4, 5, 6, 7].includes(this.taskDetail.course_type);
    },
    percentage() {
      var _this$taskDetail;
      const complete = (_this$taskDetail = this.taskDetail) === null || _this$taskDetail === void 0 ? void 0 : _this$taskDetail.knowledges.filter(item => item.is_complete);
      return complete.length / this.taskDetail.knowledges.length * 100;
    },
    taskProgress() {
      var _this$taskDetail2;
      const complete = (_this$taskDetail2 = this.taskDetail) === null || _this$taskDetail2 === void 0 ? void 0 : _this$taskDetail2.knowledges.filter(item => item.is_complete);
      return `${complete.length} / ${this.taskDetail.knowledges.length}`;
    },
    taskCourseType() {
      const courseType = ['placeholder',
      // 只是用来占位，不会展示
      this.$t('coaching.scriptReading'), this.$t('coaching.recitationAssessment'), this.$t('coaching.scriptPractice'), this.$t('coaching.aICustomerHasVar', ['(Alpha)']), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer'), this.$t('coaching.aICustomer')];
      return courseType[this.taskDetail.course_type];
    },
    practiceRule() {
      const rule = this.taskDetail.course_type === 1 ? this.$t('coaching.readAloud') : this.$t('coaching.recite');
      const isMultiple = this.taskDetail.practice_rule.repeat_count > 1;
      if (isMultiple) {
        return this.$t('coaching.EachScriptNeedsRead', [rule, rule, this.taskDetail.practice_rule.repeat_count, this.taskDetail.practice_rule.score]);
      } else {
        return this.$t('coaching.scoreNotLessThanPoints', [rule, rule, this.taskDetail.practice_rule.score]);
      }
    },
    smartTrainingButtonTitle() {
      if (this.taskDetail.conv_id) {
        return this.$t('coaching.continuePractice');
      }
      if (this.currentUserTaskStatus === 3 && this.$moment().isBefore(this.taskDetail.end_time, 'minute')) {
        return this.$t('coaching.practiceAgain');
      }
      if (this.currentUserTaskStatus === 0) {
        return this.$t('coaching.startTraining');
      }
      return this.$t('coaching.taskHasEnded');
    }
  },
  methods: {
    goCoachingDetail() {
      var _incompleteInfo;
      // 未完成的知识点
      if (this.taskDetail.status === 1) {
        this.$message.error(this.$t('coaching.taskHasEndedAndCannotBePracticed'));
        return;
      }
      let incompleteInfo = this.taskDetail.knowledges.find(item => !item.is_complete);
      console.log('incompleteInfo', incompleteInfo);
      if (!incompleteInfo) {
        incompleteInfo = this.taskDetail.knowledges[0];
      }
      let path;
      switch (this.taskDetail.course_type) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          path = _routeMap.ROUTES.practiceSmartTraining;
          break;
        default:
          path = _routeMap.ROUTES.practiceKnowledge;
          break;
      }
      const taskId = this.taskDetail.taskId;
      sessionStorage.setItem('task_practice_query', JSON.stringify({
        type: this.taskDetail.course_type,
        knowledgeId: (_incompleteInfo = incompleteInfo) === null || _incompleteInfo === void 0 ? void 0 : _incompleteInfo.id,
        taskId,
        score: this.taskDetail.practice_rule.score,
        planName: this.taskDetail.name,
        language: this.taskDetail.language,
        practice_mode: this.taskDetail.practice_mode,
        avatar_url: this.taskDetail.avatar_url,
        restart: this.currentUserTaskStatus === 3 && this.$moment().isBefore(this.taskDetail.end_time, 'minute')
      }));
      this.$router.push({
        path: this.isEmbed ? this.getEmbedPath(path) : path,
        query: {
          taskId,
          conv_id: this.taskDetail.conv_id
        }
      });
    },
    goBack() {
      this.$emit('goBack');
    }
  }
};
exports.default = _default;