"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _AssociateContactForm = _interopRequireDefault(require("./AssociateContactForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AssociateContactDialog',
  components: {
    AssociateContactForm: _AssociateContactForm.default
  },
  props: {
    associateContactDialogVisible: {
      type: Boolean,
      default: false
    },
    contactList: {
      type: Array,
      default: () => []
    },
    dealId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      saveLoading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
      this.$nextTick(() => {
        this.$refs['associateContactForm'].resetForm();
      });
    },
    submitForm() {
      if (this.$refs['associateContactForm'].$refs['dynamicForm'].validate()) {
        const data = {
          deal_id: this.dealId,
          contact_id: this.$refs['associateContactForm'].formData.contact_id,
          is_main: this.$refs['associateContactForm'].formData.is_main || 0
        };
        this.associateContact(data);
      }
    },
    async associateContact(data) {
      this.saveLoading = true;
      const res = await (0, _business.associateContact)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.$message.success('关联联系人成功');
        this.closeDialog();
      }
    }
  }
};
exports.default = _default;