"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tradeScoreRule = require("@/api/tradeScoreRule");
var _vuex = require("vuex");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _ScoreRuleContent = _interopRequireDefault(require("@/views/convDetail/components/ScoreRuleContent"));
var _EventsTabContent = _interopRequireDefault(require("./EventsTabContent.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScoreRuleTabContent',
  components: {
    ScoreRuleContent: _ScoreRuleContent.default,
    NoData: _NoData.default,
    EventsTabContent: _EventsTabContent.default
  },
  props: {
    tradeId: {
      type: Number,
      default: 0
    },
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      scoreList: [],
      activeIds: [],
      activeTabName: '',
      titleName: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    formatScoreList() {
      return this.scoreList.filter(item => item.item.length);
    }
  },
  watch: {
    tradeId: {
      handler: function () {
        this.initData();
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    refreshScore() {
      this.initData();
    },
    handleBackUp() {
      this.activeIds = [];
    },
    triggerKeyEvent(val) {
      this.titleName = val.name;
      this.activeIds = [val.id];
      this.activeTabName = 'events';
    },
    triggerAccountTag(val) {
      this.titleName = val.name;
      this.activeIds = [val.id];
      this.activeTabName = 'account_label';
    },
    toggleAllExpandAndCollapse(index) {
      this.$set(this.scoreList[index], 'isExpandAll', !this.scoreList[index].isExpandAll);
      this.scoreList[index].activeNames = this.scoreList[index].isExpandAll ? this.scoreList[index].item.map(item => item.id) : [];
    },
    toggleItemExpandAndCollapse(index) {
      this.$set(this.scoreList[index], 'isExpandAll', this.scoreList[index].activeNames.length === this.scoreList[index].item.length);
    },
    async initData() {
      this.loading = true;
      const res = await (0, _tradeScoreRule.getTradeScoreRuleDetail)({
        workspace_id: this.workspaceInfo.id,
        id: this.tradeId
      }).finally(() => this.loading = false);
      if (res.code === 20000) {
        this.scoreList = res.results.score_result;
        this.scoreList.forEach((item, index) => {
          this.$set(this.scoreList[index], 'activeNames', []);
        });
      }
    }
  }
};
exports.default = _default;