"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-form-content"
  }, [_c("el-form", {
    ref: "scoreForm",
    attrs: {
      model: _vm.scoreItem,
      "label-position": "top",
      rules: _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "名称"
    }
  }, [_c("el-input", {
    staticClass: "name-input",
    class: {
      "error-tip": !_vm.scoreItem.name
    },
    attrs: {
      placeholder: "评分项名称",
      maxlength: 10
    },
    model: {
      value: _vm.scoreItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.scoreItem, "name", $$v);
      },
      expression: "scoreItem.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "score_type",
      label: "类型"
    }
  }, [_c("el-select", {
    staticClass: "score-type-select",
    on: {
      change: _vm.handleScoreType
    },
    model: {
      value: _vm.scoreItem.score_type,
      callback: function ($$v) {
        _vm.$set(_vm.scoreItem, "score_type", $$v);
      },
      expression: "scoreItem.score_type"
    }
  }, _vm._l(_vm.scoreTypeList, function (option) {
    return _c("el-option", {
      key: option.value,
      attrs: {
        label: option.label,
        value: option.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "当命中"
    }
  }, [_c("div", {
    staticClass: "score-rule-content"
  }, [_c("div", {
    staticClass: "score-condition-content"
  }, [_c("el-select", {
    staticClass: "type-select",
    on: {
      change: function ($event) {
        return _vm.changeRuleType($event, _vm.scoreItem);
      }
    },
    model: {
      value: _vm.scoreItem.type,
      callback: function ($$v) {
        _vm.$set(_vm.scoreItem, "type", $$v);
      },
      expression: "scoreItem.type"
    }
  }, _vm._l(Object.values(_vm.typeOptions), function (option) {
    return _c("el-option", {
      key: option.value,
      attrs: {
        label: option.label,
        value: option.value,
        disabled: _vm.scoreItem.score_type !== 1 && option.value !== "key_words" && option.value !== "event_engine"
      }
    });
  }), 1), _vm.scoreItem.type === "key_words" && _vm.scoreItem.config.textFilterList ? _c("text-search", {
    ref: "textSearchInput",
    staticClass: "key-words-content",
    class: {
      "hide-left-part": _vm.scoreItem.score_type === 3
    },
    attrs: {
      disabled: _vm.scoreItem.score_type !== 1,
      filters: _vm.scoreItem.config
    }
  }) : _vm._e(), _vm.scoreItem.type === "event_engine" && _vm.scoreItem.config.keyEventCascaderList ? _c("event-tracker", {
    staticClass: "event-engine-content",
    class: {
      "hide-left-part": _vm.scoreItem.score_type === 3
    },
    attrs: {
      disabled: _vm.scoreItem.score_type !== 1,
      "only-select-leaf": _vm.scoreItem.score_type === 3,
      filters: _vm.scoreItem.config
    }
  }) : _vm._e(), _vm.scoreItem.type === "event_engine_order" && _vm.scoreItem.config.keyEventOrderCascaderList ? _c("event-order-tracker", {
    staticClass: "event-engine-order-content",
    attrs: {
      filters: _vm.scoreItem.config
    }
  }) : _vm.scoreItem.type === "account_label" ? _c("multiple-cascader-tracker", {
    staticClass: "account-label-content",
    attrs: {
      item: {
        options: _vm.accountTagOptionsList,
        field_name: "account_label"
      },
      value: _vm.scoreItem.config
    },
    on: {
      change: function ($event) {
        return _vm.handleAccountTagChange($event);
      }
    }
  }) : _vm._e(), ["ms", "秒"].includes(_vm.typeOptions[_vm.scoreItem.type] && _vm.typeOptions[_vm.scoreItem.type].unit) ? _c("time-range", {
    class: {
      "error-tip": !_vm.scoreItem.config.length
    },
    attrs: {
      "time-range": _vm.scoreItem.config,
      "only-second": _vm.typeOptions[_vm.scoreItem.type].unit === "秒"
    },
    on: {
      "update:timeRange": function ($event) {
        return _vm.$set(_vm.scoreItem, "config", $event);
      },
      "update:time-range": function ($event) {
        return _vm.$set(_vm.scoreItem, "config", $event);
      },
      timeChange: val => _vm.scoreItem.config = val
    }
  }) : _vm._e(), _vm.configUnitList.includes(_vm.typeOptions[_vm.scoreItem.type] && _vm.typeOptions[_vm.scoreItem.type].unit) ? _c("amount-range-input", {
    class: {
      "error-tip": !_vm.scoreItem.config.length
    },
    attrs: {
      value: _vm.scoreItem.config,
      unit: _vm.typeOptions[_vm.scoreItem.type].unit
    },
    on: {
      amountChange: val => _vm.scoreItem.config = val
    }
  }) : _vm._e(), _vm.scoreItem.type === "hang_up" ? _c("el-select", {
    staticClass: "hang-up-select",
    attrs: {
      placeholder: "请选择挂断方"
    },
    model: {
      value: _vm.scoreItem.config,
      callback: function ($$v) {
        _vm.$set(_vm.scoreItem, "config", $$v);
      },
      expression: "scoreItem.config"
    }
  }, _vm._l(_vm.roleOptions, function (role) {
    return _c("el-option", {
      key: role.value,
      attrs: {
        label: role.label,
        value: role.value
      }
    });
  }), 1) : _vm._e()], 1), _c("div", {
    staticClass: "text-then"
  }, [_vm._v("则")]), _c("div", {
    staticClass: "score-condition-content"
  }, [_vm.scoreItem.score_type === 1 ? _c("div", {
    staticClass: "one-score-set-content"
  }, [_c("span", {
    staticClass: "model-tag",
    class: _vm.scoreMode
  }, [_vm._v(_vm._s(_vm.scoreMode === "decrease" ? "减" : "加"))]), _c("el-input", {
    staticClass: "score-input",
    attrs: {
      size: "medium"
    },
    on: {
      input: function ($event) {
        return _vm.handleScoreInput($event, _vm.scoreItem);
      }
    },
    model: {
      value: _vm.scoreItem.score,
      callback: function ($$v) {
        _vm.$set(_vm.scoreItem, "score", $$v);
      },
      expression: "scoreItem.score"
    }
  }, [_c("span", {
    staticClass: "unit",
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("分")])])], 1) : _vm._e(), _vm.scoreItem.score_type === 2 ? _c("cumulative-score-rule", {
    attrs: {
      "score-item": _vm.scoreItem,
      "score-mode": _vm.scoreMode,
      "score-ext-items": _vm.scoreExtItems,
      "total-score": _vm.getTotalScore
    },
    on: {
      handleScore: _vm.handleScore,
      deleteScoreRules: _vm.deleteScoreRules,
      addScoreRule: _vm.addScoreRule
    }
  }) : _vm._e(), _vm.scoreItem.score_type === 3 ? _c("hit-count-score-rule", {
    attrs: {
      "score-item": _vm.scoreItem,
      "score-mode": _vm.scoreMode,
      "score-ext-items": _vm.scoreExtItems,
      "total-score": _vm.getTotalScore
    },
    on: {
      deleteScoreRules: _vm.deleteScoreRules,
      addScoreRule: _vm.addScoreRule
    }
  }) : _vm._e()], 1)])])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;