"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tribute = _interopRequireDefault(require("./tribute"));
var _vue = require("vue");
var _PicUploader = _interopRequireDefault(require("./components/PicUploader.vue"));
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _videoDetail = require("@/api/videoDetail");
var _business = require("@/api/business");
var _library = require("@/api/library");
var _useRouter = require("@/hooks/use-router.js");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  props: {
    placeholder: {
      type: String,
      default: '请输入评论'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    entityType: {
      type: String,
      default: 'conversation'
    },
    departmentManagerInfo: {
      type: Object,
      default: () => ({})
    },
    hasMention: {
      type: Boolean,
      default: false
    }
  },
  emits: ['focus', 'update:hasMention', 'pressEnter'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const handleFocus = e => {
      emit('focus', e);
    };
    const handleInput = e => {
      if (!e.target.innerHTML) {
        emit('update:hasMention', false);
      }
      emit('input', e.target.innerHTML);
    };
    let tribute = null;
    const input__inner = (0, _vue.ref)();
    const picRef = (0, _vue.ref)();
    const visibleList = (0, _vue.ref)([]);
    const visibleListOriginal = (0, _vue.ref)([]);
    (0, _vue.onMounted)(async () => {
      await initVisibleList();
      initTribute();
      focus();
      tribute.attach(input__inner.value);
      input__inner.value.addEventListener('paste', imagePasteListener);
    });
    (0, _vue.watchEffect)(async () => {
      if (props.disabled) {
        await (0, _vue.nextTick)();
        input__inner.value.focus();
      } else {
        await (0, _vue.nextTick)();
        input__inner.value.blur();
      }
    });
    const getComment = () => {
      const node = input__inner.value.cloneNode(true);
      const ats = node.querySelectorAll('span.mention');
      const mentionIds = Array.from(ats).map(at => {
        const id = at.dataset.id - 0;
        const is_department = at.dataset.department === 'undefined' ? 0 : 1;
        return {
          id,
          is_department
        };
      });
      Array.from(ats).forEach(at => {
        at.innerText = `[[at]]${at.innerText}[[/at]]`;
      });
      const rows = node.querySelectorAll('br,.comment-input__inner > div,.comment-input__inner > p');
      Array.from(rows).forEach(row => {
        const n = document.createTextNode('\n');
        const parent = row.parentNode;
        parent.insertBefore(n, row);
      });
      const imgs = picRef.value.getImgs();
      const content = removeLastN(node.innerText);
      // 递归去掉content末尾的\n
      function removeLastN(str) {
        if (str[str.length - 1] === '\n') {
          return removeLastN(str.slice(0, str.length - 1));
        } else {
          return str;
        }
      }
      return {
        mentionIds,
        imgs,
        content
      };
    };
    const onKeyPress = e => {
      if (e.keyCode === 13 && !e.shiftKey && !e.metaKey && !e.altKey && !e.ctrlKey) {
        e.preventDefault();
        emit('pressEnter');
      }
    };
    const clearComment = () => {
      input__inner.value.innerHTML = '';
      picRef.value.clearImgs();
    };
    function initTribute() {
      tribute = new _tribute.default({
        values: (0, _debounce.default)(function (text, cb) {
          getVisibleList(text, cb);
        }, 300),
        searchOpts: {
          skip: true
        },
        requireLeadingSpace: false,
        // 是否需要在@之前输入空格才能触发
        selectTemplate: function (item) {
          return `<span class="mention" data-id=${item.original.id} data-department=${item.original.is_department} contenteditable='false'> @${item.original.name}</span>`;
        },
        menuItemTemplate: function (item) {
          return getOptionTemplate(item.original);
        },
        onSelectMenu(item) {
          insert2local(item.original);
          emit('update:hasMention', true);
        }
      });
    }
    const route = (0, _useRouter.useRoute)();
    async function initVisibleList() {
      await getVisibleList('', null, true);
      visibleListOriginal.value = visibleList.value;
      if (props.departmentManagerInfo.id) {
        const list = visibleListOriginal.value.filter(item => {
          return item.id !== props.departmentManagerInfo.id;
        });
        visibleListOriginal.value = [props.departmentManagerInfo, ...list];
      }
    }
    const count = (0, _vue.ref)(0);
    async function getVisibleList(fuzzy, cb) {
      let force = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      count.value++;
      const _count = count.value;
      if (!force && fuzzy === '') {
        insertLocalItem2VisibleListOriginal();
        cb && cb(visibleListOriginal.value);
        return;
      }
      const REQEST_MAP = {
        conversation: {
          func: _videoDetail.getConvAtableUsers,
          params: {
            conversation_id: route.query.id
          }
        },
        business: {
          func: _business.getBusiAtableUsers,
          params: {
            deal_id: route.query.id
          }
        },
        snippet: {
          func: _library.getSnipAtableUsers,
          params: {
            snippet_id: route.query.id
          }
        }
      };
      const res = await REQEST_MAP[props.entityType].func({
        ...REQEST_MAP[props.entityType].params,
        fuzzy
      });
      if (_count !== count.value) return;
      visibleList.value = res.results.visiable_list;
      cb && cb(visibleList.value);
    }
    function getOptionTemplate(item) {
      const {
        id,
        name,
        is_department,
        avatar,
        employee_count,
        is_department_manager
      } = item;
      if (is_department) {
        return `<div class="mention-item">
      <div class="mention-item__avatar is-department">
        <i class="iconfont icon-department"></i>
      </div>
      <div class="mention-item__info">
        <span class="name">${name}(${employee_count})</span>
      </div>`;
      } else if (avatar) {
        return `<div class="mention-item">
      <div class="mention-item__avatar">
        <img class="avatar__inner" src="${avatar}" alt="">
      </div>
      <div class="mention-item__info">
        <span class="mention-item__name">${name}</span>
        ${is_department_manager ? '<span class="mention-item__tip">部门负责人</span>' : ''}
      </div>`;
      } else {
        return `<div class="mention-item">
      <div class="mention-item__avatar is-name-text">
        <span class="name__inner">${name.slice(0, 1)}</span>
      </div>
      <div class="mention-item__info">
        <span class="mention-item__name">${name}</span>
        ${is_department_manager ? '<span class="mention-item__tip">部门负责人</span>' : ''}
      </div>`;
      }
    }
    function imagePasteListener(e) {
      const clipboardData = e.clipboardData;
      const items = clipboardData.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const file = items[i].getAsFile();
            picRef.value.upload(file);
            e.preventDefault();
          }
        }
      }
    }
    function insert2local(item) {
      const commentMentions = localStorage.getItem('comment__Mentions');
      if (commentMentions) {
        const commentMentionsArr = JSON.parse(commentMentions);
        const index = commentMentionsArr.findIndex(mention => mention.id === item.id);
        if (index !== -1) {
          commentMentionsArr.splice(index, 1);
        }
        commentMentionsArr.unshift(item);
        if (commentMentionsArr.length > 5) {
          commentMentionsArr.pop();
        }
        localStorage.setItem('comment__Mentions', JSON.stringify(commentMentionsArr));
      } else {
        localStorage.setItem('comment__Mentions', JSON.stringify([item]));
      }
    }
    function insertLocalItem2VisibleListOriginal() {
      const commentMentions = localStorage.getItem('comment__Mentions');
      if (commentMentions) {
        const commentMentionsArr = JSON.parse(commentMentions);
        // 删除visibleListOriginal中和commentMentionsArr重复的项
        visibleListOriginal.value = visibleListOriginal.value.filter(item => {
          return !commentMentionsArr.some(mention => mention.id === item.id && mention.is_department === item.is_department);
        });
        visibleListOriginal.value = commentMentionsArr.concat(visibleListOriginal.value);
      }
    }
    function focus() {
      input__inner.value.focus();
    }
    const setComment = _ref2 => {
      let {
        mention_ids,
        img,
        content
      } = _ref2;
      console.log('mention_ids--content', content);
      input__inner.value.innerHTML = (0, _commonFunc.formatCommentContentWithMentionId)(content, mention_ids);
      img && picRef.value.setImgs(img);
      if (mention_ids.length > 0) {
        emit('update:hasMention', true);
      }
    };
    expose({
      getComment,
      clearComment,
      focus,
      setComment
    });
    return {
      __sfc: true,
      props,
      emit,
      handleFocus,
      handleInput,
      tribute,
      input__inner,
      picRef,
      visibleList,
      visibleListOriginal,
      getComment,
      onKeyPress,
      clearComment,
      initTribute,
      route,
      initVisibleList,
      count,
      getVisibleList,
      getOptionTemplate,
      imagePasteListener,
      insert2local,
      insertLocalItem2VisibleListOriginal,
      focus,
      setComment,
      PicUploader: _PicUploader.default
    };
  }
};
exports.default = _default;