"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DragInputs = _interopRequireDefault(require("./DragInputs"));
var _num_formatter = require("@/utils/num_formatter");
var _validate = require("@/utils/validate.js");
var _commonFunc = require("@/utils/commonFunc");
var _field = require("@/api/field");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomFieldForm',
  components: {
    DragInputs: _DragInputs.default
  },
  props: {
    formType: {
      type: String,
      default: ''
    },
    editForm: Object,
    type: {
      type: String,
      default: 'lead'
    },
    isEdit: Boolean,
    visible: Boolean,
    customFieldList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        from_type: '',
        name: '',
        tips: '',
        default_value: '',
        is_require: 0,
        is_unique: 0,
        is_hidden: 0,
        options: [{
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }, {
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }, {
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }],
        precisions: 0,
        is_thousand_separate: 1,
        is_batch: 0
      },
      oldName: null,
      precisionsList: [{
        value: 0,
        label: '整数'
      }, {
        value: 1,
        label: '1位'
      }, {
        value: 2,
        label: '2位'
      }, {
        value: 3,
        label: '3位'
      }, {
        value: 4,
        label: '4位'
      }],
      submitLoading: false
    };
  },
  computed: {
    amountFormatExample() {
      const number = 2345.6789;
      return `￥${(0, _num_formatter.parseFormatNum)(number, this.form.precisions, !this.form.is_thousand_separate)}`;
    },
    desc() {
      switch (this.formType) {
        case 'text':
          return {
            radios: [{
              label: '单行文本',
              value: 'text'
            }, {
              label: '多行文本',
              value: 'textarea'
            }]
          };
        case 'radio':
          return {
            radios: [{
              label: '单选',
              value: 'radio'
            }, {
              label: '多选',
              value: 'checkbox'
            }, {
              label: '下拉框单选',
              value: 'select'
            }],
            options: true
          };
        case 'date':
          return {
            radios: [{
              label: '日期',
              value: 'date'
            }, {
              label: '日期和时间',
              value: 'datetime'
            }]
          };
        case 'tel':
        case 'email':
        case 'file':
        case 'money':
        case 'number':
        default:
          return {};
      }
    },
    rules() {
      const checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('字段名称不能为空'));
        } else {
          if (this.isEdit && value === this.oldName) {
            callback();
          } else {
            if (this.customFieldList.find(item => item.name === value)) {
              return callback(new Error('字段名称重复'));
            } else {
              callback();
            }
          }
        }
      };
      const checkOptions = (rule, value, callback) => {
        if (!(this.formType === 'select' || this.formType === 'radio' || this.formType === 'checkbox')) {
          callback();
        }
        if (value.filter(item => item.value).length === 0) {
          return callback(new Error('选项不能为空'));
        } else {
          callback();
        }
      };
      return {
        name: [{
          validator: checkName,
          trigger: 'change'
        }],
        options: [{
          required: true,
          validator: checkOptions,
          message: '选项不能为空',
          trigger: 'change'
        }],
        default_value: this.defaultValValidator
      };
    },
    systemPresetShow() {
      if (this.form.field_type) {
        if (this.formType === 'tel' || this.formType === 'email') {
          return true;
        }
      }
      return false;
    },
    defaultValValidator() {
      switch (this.form.from_type) {
        case 'tel':
          return [{
            validator: _validate.telValidatorAllowNone,
            trigger: 'change'
          }];
        case 'email':
          return [{
            validator: _validate.emailValidatorAllowNone,
            trigger: 'change'
          }];
        case 'number':
          return [{
            validator: _validate.numberValidator,
            trigger: 'change'
          }];
        default:
          return null;
      }
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs['form'].clearValidate();
            this.$refs['name-input'].focus();
            // eslint-disable-next-line no-prototype-builtins
            if (this.editForm.hasOwnProperty('id')) {
              this.form = {
                ...this.form,
                ...this.editForm
              };
              this.oldName = this.form.name;
              if (!this.form.options.length) {
                this.form.options = this.$options.data().form.options;
              }
              this.$emit('changeType', this.typeTrans(this.editForm.from_type));
              setTimeout(() => {
                this.form.from_type = this.editForm.from_type;
                this.form.default_value = this.editForm.default_value;
              }, 0);
            }
          });
        } else {
          if (!this.form.options.length) {
            this.form.options = this.$options.data().form.options;
          }
        }
      },
      immediate: true
    },
    formType: {
      handler(val) {
        this.form.from_type = val;
        if (!this.isEdit) {
          if (val === 'number') {
            this.form.precisions = 0;
          }
          if (val === 'money') {
            this.form.precisions = 2;
          }
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      },
      immediate: true
    },
    'form.from_type'(val, oldVal) {
      switch (val) {
        case 'checkbox':
          if (Array.isArray(this.form.default_value)) return;
          this.form.default_value = this.form.default_value ? [this.form.default_value] : [];
          break;
        case 'radio':
        case 'select':
          if (Array.isArray(this.form.default_value)) {
            this.form.default_value = this.form.default_value[0];
          }
          break;
        default:
          this.form.default_value = '';
      }
    }
  },
  methods: {
    handleOptions(options) {
      if (!options) return;
      let localOptions = (0, _commonFunc.deepClone)(options);
      localOptions = localOptions.filter(item => item.value);
      const keyArr = localOptions.map(item => item.key);
      let maxKey = Math.max.apply(null, keyArr);
      // 更新order，key
      localOptions.map((item, idx) => {
        if (item.key === 0) {
          item.key = ++maxKey;
        }
        item.order = idx + 1;
      });
      return localOptions;
    },
    async submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.submitLoading = true;
          const dataForm = {
            type: this.type,
            ...this.form
          };
          dataForm.default_value = dataForm.default_value ? dataForm.default_value.toString() : '';
          // 处理options
          dataForm.options = this.handleOptions(dataForm.options);
          const res = await (0, _field.editCustomField)(dataForm).finally(() => this.submitLoading = false);
          if (res.code === 200) {
            this.$message.success(this.isEdit ? '修改成功' : '新建成功');
            this.$emit('success');
            this.cancel();
          }
        }
      });
    },
    reset() {
      this.form = {
        from_type: 'text',
        name: '',
        tips: '',
        default_value: '',
        is_require: 0,
        is_unique: 0,
        is_hidden: 0,
        options: [{
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }, {
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }, {
          value: '',
          is_delete: 0,
          key: 0,
          order: 0
        }],
        precisions: 0,
        is_thousand_separate: 1,
        is_batch: 0
      };
      this.$refs['form'].clearValidate();
    },
    cancel() {
      this.$emit('cancel');
    },
    typeTrans(type) {
      switch (type) {
        case 'textarea':
          return 'text';
        case 'checkbox':
        case 'select':
          return 'radio';
        case 'floatnumber':
        case 'percent':
          return 'number';
        case 'datetime':
          return 'date';
        default:
          return type;
      }
    },
    async handleSender(type, index, item) {
      if (type === 'delete') {
        var _res, _res$results;
        let needCheck = true;
        if (!Object.hasOwn(this.form, 'id')) {
          needCheck = false;
        }
        if (!item.key) {
          needCheck = false;
          this.$set(this.form.options[index], 'value', '');
        }
        let res = null;
        if (needCheck) {
          res = await (0, _field.checkFieldOptionDelete)({
            type: this.type,
            field_id: this.form.id,
            key: item.key
          });
        }
        this.$confirm((_res = res) !== null && _res !== void 0 && (_res$results = _res.results) !== null && _res$results !== void 0 && _res$results.has_entity ? `有数据正在使用此选项，删除后将清空，确定删除吗？` : `确定删除吗`, '删除', {
          confirmButtonText: '删除',
          confirmButtonClass: 'confirm-right',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$set(this.form.options[index], 'is_delete', 1);
        }).catch(() => {});
      }
    }
  }
};
exports.default = _default;