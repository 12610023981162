"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["tree-select-value-container", {
      "show-all-tag": _vm.showTagCount === -1
    }]
  }, [_c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.selectionList.length,
      expression: "!selectionList.length"
    }],
    staticClass: "placeholder"
  }, [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]), _c("ul", {
    staticClass: "tree-select-value-item"
  }, [_vm._l(_vm.showTagCount === -1 ? _vm.selectionList : _vm.selectionList.slice(0, _vm.showTagCount), function (item) {
    return _c("li", {
      key: item.id
    }, [_c("span", [_vm._t("value-list-item-prefix-icon", function () {
      return [_c("ImageAvatar", {
        attrs: {
          name: item.name,
          avatar: item.avatar
        }
      })];
    }, {
      nodeData: item
    })], 2), _c("span", {
      staticClass: "value-list-item-label"
    }, [_vm._v(_vm._s(item.name))]), _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.$emit("removeItem", item);
        }
      }
    })]);
  }), _vm.showTagCount > 0 && _vm.selectionList.length > _vm.showTagCount ? _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      "popper-class": "tree-select-popover",
      "visible-arrow": false,
      width: "200",
      trigger: "hover"
    }
  }, [_c("li", {
    staticClass: "collapse-tags",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v(" +" + _vm._s(_vm.selectionList.length - _vm.showTagCount) + " ")]), _c("ValueList", {
    attrs: {
      "value-list": _vm.selectionList.slice(_vm.showTagCount)
    },
    scopedSlots: _vm._u([{
      key: "value-list-item-prefix-icon",
      fn: function (_ref) {
        let {
          nodeData
        } = _ref;
        return [_vm._t("value-list-item-prefix-icon", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "value-list-item-suffix-description",
      fn: function (_ref2) {
        let {
          nodeData
        } = _ref2;
        return [_vm._t("value-list-item-suffix-description", null, {
          nodeData: nodeData
        })];
      }
    }, {
      key: "suffix-icon",
      fn: function (_ref3) {
        let {
          nodeData
        } = _ref3;
        return [_c("i", {
          staticClass: "el-icon-close",
          on: {
            click: function ($event) {
              return _vm.$emit("removeItem", nodeData);
            }
          }
        })];
      }
    }], null, true)
  })], 1) : _vm._e()], 2), _c("span", {
    class: ["tree-select-icon-container", {
      "has-value": _vm.selectionList.length
    }]
  }, [_vm.selectionList.length ? _c("i", {
    staticClass: "el-icon-error",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("clear");
      }
    }
  }) : _vm._e(), _c("i", {
    class: ["el-icon-arrow-down", {
      rotation: _vm.popoverVisible
    }]
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;