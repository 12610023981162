"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _CoachTable = _interopRequireDefault(require("./components/CoachTable"));
var _TableFilters = _interopRequireDefault(require("./components/TableFilters"));
var _coach = require("@/api/coach");
var _CoachDetailDrawer = _interopRequireDefault(require("./components/CoachDetailDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TutorList',
  components: {
    CoachTable: _CoachTable.default,
    TableFilters: _TableFilters.default,
    CoachDetailDrawer: _CoachDetailDrawer.default,
    MenuToggleBtn: _MenuToggleBtn.default
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      coachList: [],
      tableLoading: false,
      total: 0,
      listParams: {
        size: 10,
        page: 1
      },
      drawerVisible: false,
      activeUser: {},
      coachDate: ''
    };
  },
  created() {
    this.tableLoading = true;
  },
  methods: {
    async getCoachOverviewList() {
      this.tableLoading = true;
      const res = await (0, _coach.getCoachOverviewList)(this.listParams);
      this.tableLoading = false;
      const {
        count,
        data
      } = res.results;
      this.total = count;
      this.coachList = data;
    },
    closeDrawer() {
      this.drawerVisible = false;
    },
    handleFilterChange(filter) {
      this.listParams = {
        ...this.listParams,
        ...filter
      };
      this.getCoachOverviewList();
    },
    sizeChange(val) {
      this.listParams.size = val;
      this.getCoachOverviewList();
    },
    currentChange(val) {
      this.listParams.page = val;
      this.getCoachOverviewList();
    },
    openDrawer(_ref) {
      let {
        user,
        date,
        tab
      } = _ref;
      this.activeUser = user;
      this.coachDate = date || '';
      this.$refs['drawer'].activeTab = tab;
      this.drawerVisible = true;
    }
  }
};
exports.default = _default;