"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExportMixins = _interopRequireDefault(require("../../ExportMixins"));
var _vuex = require("vuex");
var _biChart = _interopRequireDefault(require("@/components/BusinessIntelligence/biChart"));
var _sortMixin = _interopRequireDefault(require("../../sortMixin"));
var _download = require("@/utils/download");
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const {
  mapMutations
} = (0, _vuex.createNamespacedHelpers)('bi');
var _default = {
  name: 'KeyEvent',
  components: {
    BiChart: _biChart.default
  },
  mixins: [_sortMixin.default, _ExportMixins.default],
  data() {
    return {
      activeKey: null,
      activeName: '',
      eventType: 0,
      biInfoArray: [],
      personInfoArray: [],
      biSortType: 'descending',
      personSortType: 'descending',
      activeKeyeventObj: null,
      isLoading: false,
      clickDisabled: false,
      biKeyEventSelectedState: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biKeyeventObj', 'biFiltersObj', 'workspaceInfo', 'timezone']),
    analysisBasis() {
      var _this$biFiltersObj;
      return ((_this$biFiltersObj = this.biFiltersObj) === null || _this$biFiltersObj === void 0 ? void 0 : _this$biFiltersObj.analysis_basis) === 'deal' ? '交易' : '会话';
    },
    tableDataLength() {
      var _this$biKeyeventObj, _this$biKeyeventObj$t;
      return (_this$biKeyeventObj = this.biKeyeventObj) === null || _this$biKeyeventObj === void 0 ? void 0 : (_this$biKeyeventObj$t = _this$biKeyeventObj.table) === null || _this$biKeyeventObj$t === void 0 ? void 0 : _this$biKeyeventObj$t.length;
    }
  },
  watch: {
    analysisBasis: {
      handler: function () {
        var _this$$refs$eventBich;
        this.biKeyEventSelectedState = JSON.parse(sessionStorage.getItem('biKeyEventSelectedState'));
        this.biKeyEventSelectedState && ((_this$$refs$eventBich = this.$refs.eventBichart) === null || _this$$refs$eventBich === void 0 ? void 0 : _this$$refs$eventBich.selectActiveKey(this.biKeyEventSelectedState));
      }
    },
    biKeyeventObj: {
      handler: function () {
        // eslint-disable-next-line eqeqeq
        if (this.tableDataLength) {
          if (!this.biKeyEventSelectedState) {
            this.activeKey = this.getFirstKey();
          }
          this.$nextTick(() => {
            this.$refs.eventBichart.activeKey = this.activeKey;
          });
          const biInfoArray = JSON.parse(JSON.stringify(this.biKeyeventObj.table));
          this.biInfoArray = this.handleSortArray(biInfoArray, this.biSortType);
          // NOTE 关键事件有选中状态的时候，不在这里触发请求，而是在activeKeyChange中请求
          !this.biKeyEventSelectedState && this.getPersonInfo();
        } else {
          this.activeKeyeventObj = null;
          this.activeName = '';
        }
      },
      deep: true
    },
    activeKey(val) {
      this.personSortType = 'descending';
      if (this.$refs['personBichart'] && this.$refs['personBichart'].$refs['personBichartSort']) {
        this.$refs['personBichart'].$refs['personBichartSort'].activeStatus = 'descending';
      }
    },
    biSortType: {
      handler: function (val) {
        if (this.biKeyeventObj && this.biKeyeventObj.table) {
          const biInfoArray = JSON.parse(JSON.stringify(this.biKeyeventObj.table));
          this.biInfoArray = this.handleSortArray(biInfoArray, val);
        }
      }
    },
    personSortType: {
      handler: function (val) {
        if (this.activeKeyeventObj && this.activeKeyeventObj.table) {
          const personInfoArray = JSON.parse(JSON.stringify(this.activeKeyeventObj.table));
          this.personInfoArray = this.handleSortArray(personInfoArray, val);
        }
      }
    },
    activeKeyeventObj(val) {
      if (val) {
        const personInfoArray = JSON.parse(JSON.stringify(val.table));
        this.personInfoArray = this.handleSortArray(personInfoArray, val);
      }
    },
    biFiltersObj(val) {
      this.biSortType = 'descending';
      this.personSortType = 'descending';
      if (this.$refs['eventBichart'] && this.$refs['eventBichart'].$refs['eventBichartSort']) {
        this.$refs['eventBichart'].$refs['eventBichartSort'].activeStatus = 'descending';
      }
      if (this.$refs['personBichart'] && this.$refs['personBichart'].$refs['personBichartSort']) {
        this.$refs['personBichart'].$refs['personBichartSort'].activeStatus = 'descending';
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.indexOf('/bi/') === -1) {
      sessionStorage.setItem('biFilters', '');
      sessionStorage.setItem('biDepartmentOptions', '');
    }
    sessionStorage.removeItem('biKeyEventSelectedState');
    next();
    // 把 数据置为空 加载loading
    this.$store.commit('bi/SET_KEYEVENT_OBJ');
  },
  methods: {
    ...mapMutations(['handleKeyeventObj']),
    async getPersonInfo() {
      this.isLoading = true;
      this.clickDisabled = true;
      const params = {
        event_id: this.activeKey,
        event_name: this.activeName,
        event_type: this.eventType,
        ...this.biFiltersObj,
        ...this.biFiltersObj.tree_user_ids,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      const res = await (0, _businessIntelligence.getKeyeventBiPersonInfo)(params);
      this.handleKeyeventObj(res.results);
      this.activeKeyeventObj = res.results;
      this.isLoading = false;
      this.clickDisabled = false;
      this.$refs['personBichart'] && (this.$refs['personBichart'].$data.currentPage = 1);
      this.$refs['personBichart'] && (this.$refs['personBichart'].$data.nomore = false);
    },
    exportKeyEventCsv() {
      var _this$biKeyeventObj2;
      const res = this.biInfoArray.map(_ref => {
        let {
          key,
          valueLabel,
          count
        } = _ref;
        return [key, valueLabel, count];
      });
      res.unshift(['关键事件', '占比', '数量']); // 添加表头
      res.push(['平均', (_this$biKeyeventObj2 = this.biKeyeventObj) === null || _this$biKeyeventObj2 === void 0 ? void 0 : _this$biKeyeventObj2.avgStr]); // 添加平均值
      const {
        activeTabName,
        departmentName,
        startDate,
        endDate,
        conversationFrom
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${departmentName}_${startDate}至${endDate}_${conversationFrom}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    exportEveryKeyEventCsv() {
      var _this$activeKeyeventO;
      const res = this.personInfoArray.map(_ref2 => {
        let {
          name,
          valueLabel,
          count
        } = _ref2;
        return [name, valueLabel, count];
      });
      res.unshift(['成员', `${this.activeName}占比`, `${this.activeName}数量`]); // 添加表头
      res.push(['平均', (_this$activeKeyeventO = this.activeKeyeventObj) === null || _this$activeKeyeventO === void 0 ? void 0 : _this$activeKeyeventO.avgStr]); // 添加平均值
      const {
        activeTabName,
        departmentName,
        startDate,
        endDate,
        conversationFrom
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${this.activeName}_${departmentName}_${startDate}至${endDate}_${conversationFrom}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    getFirstKey() {
      const array = this.biKeyeventObj && this.biKeyeventObj.table;
      if (array && array.length > 0) {
        this.activeName = array[0].key;
        this.eventType = array[0].event_type;
        return array[0].id;
      } else {
        return null;
      }
    },
    activeKeyChange(id, name, type) {
      if (id) {
        this.activeKey = id;
      }
      if (name) {
        this.activeName = name;
      }
      if (type === 0 || type === 1) {
        this.eventType = type;
      }
      this.getPersonInfo();
    },
    sortTypeChange(type, key) {
      if (key === 'eventBichart') {
        this.biSortType = type;
      } else {
        this.personSortType = type;
      }
    }
  }
};
exports.default = _default;