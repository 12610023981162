"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataPermissionCard = _interopRequireDefault(require("./components/DataPermissionCard"));
var _permissions = require("@/api/permissions.js");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DataPermisionIndex',
  components: {
    DataPermissionCard: _DataPermissionCard.default
  },
  data() {
    return {
      isEdit: false,
      editPermissionObj: {},
      saveLoading: false,
      contentLoading: false,
      typeDataProfile: {
        conversation: {},
        trade: {},
        lead: {},
        account: {},
        deal: {},
        assist: {},
        training: {},
        compliance: {}
      }
    };
  },
  created() {
    this.getDataPermission();
  },
  methods: {
    getDataPermission() {
      this.contentLoading = true;
      (0, _permissions.getDataPermisson)().then(res => {
        const results = res.results;
        for (const key in this.typeDataProfile) {
          if (results[key]) {
            this.typeDataProfile[key] = results[key];
          } else {
            delete this.typeDataProfile[key];
          }
        }
      }).finally(() => {
        this.contentLoading = false;
      });
    },
    cancelEdit() {
      this.isEdit = false;
      for (const key in this.typeDataProfile) {
        this.$refs[key + 'DataPermissionComp'][0].closeValidate();
        this.typeDataProfile[key] = {};
      }
      this.getDataPermission();
    },
    permissionChange(val, type) {
      this.editPermissionObj[type] = val;
    },
    handleValidate() {
      const validateObj = {};
      for (const key in this.typeDataProfile) {
        var _this$$refs$;
        validateObj[key] = (_this$$refs$ = this.$refs[key + 'DataPermissionComp'][0]) === null || _this$$refs$ === void 0 ? void 0 : _this$$refs$.judgeValidateIsOk();
      }
      const validateValues = Object.values(validateObj);
      return !validateValues.includes(false);
    },
    save() {
      // 先校验补充规则是否有空选项
      const validateRes = this.handleValidate();
      if (validateRes) {
        const data = this.getEditedPermission();
        this.saveDataPermissonApi(data);
      }
    },
    saveDataPermissonApi(data) {
      this.saveLoading = true;
      (0, _permissions.saveDataPermisson)(data).then(() => {
        this.cancelEdit();
        this.$message.success('保存成功！');
      }).finally(() => {
        this.saveLoading = false;
      });
    },
    handleEditPermissionData(data) {
      const {
        basic_rules,
        supplement_rules
      } = data;
      const new_supplement_rules = [];
      supplement_rules.forEach(item => {
        const {
          auth_target,
          resource,
          operate_type,
          res_type
        } = item;
        const new_item = {
          auth_target: {
            tree_ids: auth_target.tree_infos,
            user_ids: auth_target.user_infos
          },
          resource: {
            tree_ids: resource.tree_infos,
            user_ids: resource.user_infos
          },
          operate_type,
          res_type
        };
        new_supplement_rules.push(new_item);
      });
      return {
        basic_rules,
        supplement_rules: new_supplement_rules
      };
    },
    getEditedPermission() {
      const basicRules = [];
      const supplementRules = [];
      for (const key in this.editPermissionObj) {
        const data = this.handleEditPermissionData(this.editPermissionObj[key]);
        basicRules.push(...data.basic_rules);
        supplementRules.push(...data.supplement_rules);
      }
      const obj = {
        basic_rules: basicRules,
        supplement_rules: supplementRules
      };
      return obj;
    },
    isValuableObj(val) {
      return (0, _commonFunc.isValuableObj)(val);
    }
  }
};
exports.default = _default;