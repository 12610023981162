"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "merge-table",
    attrs: {
      data: _vm.tableData,
      height: _vm.tableHeight
    }
  }, _vm._l(_vm.tableHeader, function (item, index) {
    return _c("el-table-column", {
      key: index + item.label,
      attrs: {
        label: item.label,
        prop: item.field_name
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function (_ref) {
          let {
            column
          } = _ref;
          return [item.field_name === "merge" ? _c("el-tooltip", {
            attrs: {
              "popper-class": "merge-data-popper",
              effect: "dark",
              placement: "top"
            }
          }, [_c("div", {
            attrs: {
              slot: "content"
            },
            slot: "content"
          }, [_vm._v(" 已合并状态表示联系人已合并到已有的Megaview外呼联系人中；"), _c("br"), _vm._v("未合并状态表示联系人未与任何外呼联系人进行合并。 ")]), _c("i", {
            staticClass: "iconfont icon-question2"
          })]) : _vm._e(), item.field_name === "operate" ? _c("el-tooltip", {
            attrs: {
              "popper-class": "merge-data-popper",
              effect: "dark",
              placement: "top"
            }
          }, [_c("div", {
            attrs: {
              slot: "content"
            },
            slot: "content"
          }, [_vm._v(" 1.合并联系人后可以在当天24点之前撤销合并，超过当天24点则无法撤销合并。"), _c("br"), _vm._v("2.合并联系人操作后约5分钟生效。"), _c("br"), _vm._v("3.撤销合并联系人操作后约5分钟生效。 ")]), _c("i", {
            staticClass: "iconfont icon-question2"
          })]) : _vm._e(), _vm._v(" " + _vm._s(column.label) + " ")];
        }
      }, item.field_name === "name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("img", {
            staticClass: "avatar",
            attrs: {
              src: row.avatar || _vm.defaultAvatar
            }
          }), _c("span", [_vm._v(_vm._s(row.name))])];
        }
      } : item.field_name === "type" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "contact"
          }, [_c("img", {
            staticClass: "contact-img",
            attrs: {
              src: row.type === "wechat" ? _vm.wechatLogo : _vm.weworkLogo
            }
          }), _c("span", [_vm._v(" " + _vm._s(row.type === "wechat" ? "微信联系人" : "企业微信联系人") + " ")])])];
        }
      } : item.field_name === "merge" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("div", {
            class: ["merge-state", {
              "is-merge": row.merge_by
            }]
          }, [_c("i", {
            staticClass: "iconfont",
            class: _vm.setStatusClassName(row.merge_by)
          }), _vm._v(" " + _vm._s(row.merge_by ? "已合并" : "未合并") + " ")])];
        }
      } : item.field_name === "operate" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [!row.merge_by ? _c("div", {
            staticClass: "operation"
          }, [_c("div", {
            staticClass: "operate-btn",
            on: {
              click: function ($event) {
                return _vm.openMergeDialog(row);
              }
            }
          }, [_vm._v(" 合并联系人 ")])]) : row.revert_enable ? _c("div", {
            staticClass: "operation cancel-mergence"
          }, [_c("div", {
            staticClass: "operate-btn",
            on: {
              click: function ($event) {
                return _vm.openRevertMergenceDialog(row);
              }
            }
          }, [_vm._v(" 撤销合并 ")])]) : _vm._e()];
        }
      } : null], null, true)
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;