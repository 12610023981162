"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "like-info",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("div", {
    staticClass: "operate like"
  }, [_c("div", {
    staticClass: "icon-container",
    on: {
      click: function ($event) {
        return _vm.handleLikeOrDislike("like");
      }
    }
  }, [_c("i", {
    class: ["iconfont", _vm.localInfo.is_like ? "icon-great-3" : "icon-great-2"]
  })]), _c("span", [_vm._v(_vm._s(_vm.localInfo.like_count))])]), _c("div", {
    staticClass: "operate dislike"
  }, [_c("div", {
    staticClass: "icon-container",
    on: {
      click: function ($event) {
        return _vm.handleLikeOrDislike("dislike");
      }
    }
  }, [_c("i", {
    class: ["iconfont", _vm.localInfo.is_dislike ? "icon-great-3" : "icon-great-2"]
  })])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;