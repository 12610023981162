"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ai-customer-generator"
  }, [_c(_setup.AiCustomerGeneratorHeader, {
    attrs: {
      "update-course": _setup.updateAiCustomerFn,
      "ai-customer-id": _setup.aiCustomerId,
      "prompt-config": _setup.promptConfig,
      "start-watch": _setup.fetchDataComplete
    }
  }), _c("div", {
    staticClass: "ai-customer-generator__content"
  }, [_c("div", {
    staticClass: "prompt-setting"
  }, [_c("h1", {
    staticClass: "prompt-setting-title"
  }, [_vm._v("配置AI客户课程")]), _c("div", {
    staticClass: "prompt-setting__content"
  }, [_c("div", {
    staticClass: "prompt-mian-input-container"
  }, [_c(_setup.PromptInputHeader, {
    attrs: {
      "prompt-text-blocks": _setup.promptTextBlocks
    },
    on: {
      resetPrompt: _setup.resetPromptTextBlocks,
      clickIntelligentGeneration: _setup.openIntelligentGenerationDialog
    }
  }), _c("div", {
    staticClass: "prompt-mian-input__content"
  }, _vm._l(_setup.promptTextBlocks, function (block, index) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: block.isShow,
        expression: "block.isShow"
      }],
      key: index,
      staticClass: "prompt-input-block-container"
    }, [index ? _c("el-divider", {
      staticClass: "prompt-block-divider"
    }) : _vm._e(), _c("p", {
      staticClass: "prompt-input-block-name"
    }, [_vm._v(" " + _vm._s(block.name) + _vm._s(block.tip ? `（${block.tip}）` : "") + " ")]), _c(_setup.PromptInput, {
      ref: el => {
        _setup.bindPromptRef(el, block.key);
      },
      refInFor: true,
      attrs: {
        placeholder: block.placeholder,
        scripts: _setup.keyScripts,
        "add-script": _setup.addScript,
        "remove-script": _setup.removeScript,
        "recover-script": _setup.recoverScript,
        "paste-script": _setup.pasteScript,
        "active-script-id": _setup.activeScriptId,
        disabled: block.disabled
      },
      on: {
        "update:activeScriptId": function ($event) {
          _setup.activeScriptId = $event;
        },
        "update:active-script-id": function ($event) {
          _setup.activeScriptId = $event;
        },
        "update:prompt": function ($event) {
          return _setup.updatePromptTextContent($event, block);
        },
        openAddScriptDialog: function ($event) {
          return _setup.openAddScriptDialog(block.key);
        }
      }
    })], 1);
  }), 0), _c("div", {
    staticClass: "word-count"
  }, [_c("span", {
    staticClass: "count-text",
    class: {
      "is-invalid": _setup.totalWordCount > 1000
    }
  }, [_vm._v(_vm._s(_setup.totalWordCount) + " / 1000")]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      content: `含其他提示词 ${_setup.promptTextBlocks[2].wordCount} 个，如需查看请打开其他提示词开关`
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1), _c(_setup.PromptExample, {
    attrs: {
      example: _setup.EXAMPLE_PROMPT.label
    },
    on: {
      setExamplePrompt: function ($event) {
        return _setup.setPromptTextBlocksAndScripts(_setup.EXAMPLE_PROMPT.value, _setup.EXAMPLE_SCRIPTS);
      }
    }
  })], 1), _c("div", {
    staticClass: "prompt-settings"
  }, [_c("el-collapse", {
    staticClass: "prompt-collapse",
    model: {
      value: _setup.activeSettings,
      callback: function ($$v) {
        _setup.activeSettings = $$v;
      },
      expression: "activeSettings"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "personalizedSetting"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "setting-title"
        }, [_vm._v("个性化设置")]), _c("span", {
          staticClass: "setting-desciption"
        }, [_vm._v("设置语言")])];
      },
      proxy: true
    }])
  }, [_c(_setup.PersonalizedSetting)], 1), _c("el-collapse-item", {
    attrs: {
      name: "keyScriptSetting"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "setting-title"
        }, [_vm._v("关键问题设置")])];
      },
      proxy: true
    }])
  }, [_c(_setup.KeyScriptSet, {
    attrs: {
      "add-script-reply": _setup.addScriptReply,
      "active-script-id": _setup.activeScriptId
    },
    on: {
      "update:activeScriptId": function ($event) {
        _setup.activeScriptId = $event;
      },
      "update:active-script-id": function ($event) {
        _setup.activeScriptId = $event;
      }
    },
    model: {
      value: _setup.keyScripts,
      callback: function ($$v) {
        _setup.keyScripts = $$v;
      },
      expression: "keyScripts"
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      name: "scoreSetting"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "setting-title"
        }, [_vm._v("评分设置")]), _c("span", {
          staticClass: "setting-desciption"
        }, [_vm._v("设置对练后用于评分的指标和权重")])];
      },
      proxy: true
    }])
  }, [_c(_setup.ScoreSet, {
    on: {
      "add-metric": _setup.openMetricDialog,
      "delete-metric": _setup.removeScoreMetric
    },
    model: {
      value: _setup.scoreMetrics,
      callback: function ($$v) {
        _setup.scoreMetrics = $$v;
      },
      expression: "scoreMetrics"
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "其他设置",
      name: "otherSetting"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", {
          staticClass: "setting-title"
        }, [_vm._v("其他设置")]), _c("span", {
          staticClass: "setting-desciption"
        }, [_vm._v("设置语音输入 / 背景介绍 / 谁先发言")])];
      },
      proxy: true
    }])
  }, [_c(_setup.OtherSetting)], 1)], 1)], 1)])]), _c(_setup.AiCustomerEffectTest, {
    attrs: {
      "prompt-config": _setup.promptConfig,
      "start-watch": _setup.fetchDataComplete
    }
  })], 1), _c(_setup.AddScriptFromKnowledgeDialog, {
    attrs: {
      visible: _setup.addScriptDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.addScriptDialogVisible = $event;
      },
      change: _setup.onSelectKnowledge
    }
  }), _c(_setup.IntelligentGenerationDialog, {
    attrs: {
      visible: _setup.intelligentGenerationDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.intelligentGenerationDialogVisible = $event;
      },
      generated: function ($event) {
        return _setup.setPromptTextBlocksAndScripts($event, []);
      }
    }
  }), _c(_setup.SelectMetricsDialog, {
    attrs: {
      visible: _setup.selectMetricsDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.selectMetricsDialogVisible = $event;
      }
    },
    model: {
      value: _setup.scoreMetrics,
      callback: function ($$v) {
        _setup.scoreMetrics = $$v;
      },
      expression: "scoreMetrics"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;