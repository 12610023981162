"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "bottom",
      width: "300",
      trigger: "click",
      "popper-class": "tip-description-popover",
      transition: "fade-in-linear",
      "close-delay": 200
    },
    on: {
      show: function ($event) {
        _vm.showGif = true;
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_vm.showGif ? _vm._t("default") : _vm._e(), _c("p", [_vm._v(_vm._s(_vm.description))]), _vm.learnMore ? _c("a", {
    directives: [{
      name: "help-center",
      rawName: "v-help-center"
    }],
    staticClass: "know-more",
    attrs: {
      href: _vm.helpUrl,
      target: "_blank"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-question3"
  }), _c("span", [_vm._v("了解更多")])]) : _vm._e()], 2), _c("i", {
    staticClass: "iconfont icon-info-1",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;