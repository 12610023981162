"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.codeValidator = void 0;
exports.emailValidator = emailValidator;
exports.emailValidatorAllowNone = emailValidatorAllowNone;
exports.internationalTelValidator = internationalTelValidator;
exports.isExternal = isExternal;
exports.numberValidatorRequired = exports.numberValidator = exports.nameLengthValidator = void 0;
exports.telValidator = telValidator;
exports.telValidatorAllowNone = telValidatorAllowNone;
exports.validUsername = validUsername;
exports.validateChangePassword = validateChangePassword;
exports.validatePassword = validatePassword;
exports.validatePasswordOnlyHas = validatePasswordOnlyHas;
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

// 校验密码
function validatePassword(rule, value, callback) {
  if (value === '') {
    callback(new Error('密码不能为空'));
  } else {
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\u0021-\u002f]{6,16}$/.test(value) == false) {
      callback(new Error('请输入6-16位字符，包含字母和数字'));
    } else {
      callback();
    }
  }
}

// 校验修改密码时，并不需要判断是否为空
function validateChangePassword(rule, value, callback) {
  if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\u0021-\u002f]{6,16}$/.test(value) == false) {
    callback(new Error('请输入6-16位字符，包含字母和数字'));
  } else {
    callback();
  }
}

// 仅校验密码不为空
function validatePasswordOnlyHas(rule, value, callback) {
  if (value === '') {
    callback(new Error('密码不能为空'));
  } else {
    callback();
  }
}

// 校验手机号
function telValidator(rule, value, callback) {
  if (value === '') {
    callback(new Error('手机号不能为空'));
  } else {
    if (/^1[3456789]\d{9}$/.test(value) == false) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  }
}

// 只校验数字的手机号，兼容国外手机号
function internationalTelValidator(rule, value, callback) {
  if (value === '') {
    callback(new Error('手机号不能为空'));
  } else {
    if (/^[0-9]*$/.test(value) == false) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  }
}

// 校验手机号可空
function telValidatorAllowNone(rule, value, callback) {
  if (value === '' || value === undefined) {
    callback();
  } else {
    if (/^1[3456789]\d{9}$/.test(value) == false) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  }
}
// 校验邮箱
function emailValidator(rule, value, callback) {
  if (value === '' || value === undefined) {
    callback(new Error('邮箱不能为空'));
  } else {
    if (/^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(value) == false) {
      callback(new Error('请输入正确的邮箱'));
    } else {
      callback();
    }
  }
}
// 校验邮箱可空
function emailValidatorAllowNone(rule, value, callback) {
  if (value === '' || value === undefined) {
    callback();
  } else {
    if (/^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(value) == false) {
      callback(new Error('请输入正确的邮箱'));
    } else {
      callback();
    }
  }
}
// 验证码校验
const codeValidator = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('验证码不能为空'));
  } else {
    // callback()
    if (/^[0-9]*$/.test(value) == false) {
      callback(new Error('验证码格式不正确'));
    } else {
      callback();
    }
  }
};

// 只能是数字的校验
exports.codeValidator = codeValidator;
const numberValidator = (rule, value, callback) => {
  if (value) {
    if (/^[0-9]*$/.test(value) == false) {
      callback(new Error('请填写数字'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

// 数字必填
exports.numberValidator = numberValidator;
const numberValidatorRequired = (rule, value, callback) => {
  if (value) {
    if (/^[0-9]*$/.test(value) == false) {
      callback(new Error('请填写数字'));
    } else {
      callback();
    }
  } else {
    callback(new Error('请填写数字'));
  }
};
exports.numberValidatorRequired = numberValidatorRequired;
const nameLengthValidator = (rule, value, callback) => {
  if (value.length > 20) {
    callback(new Error('请输入1-20个字符'));
  }
  callback();
};
exports.nameLengthValidator = nameLengthValidator;