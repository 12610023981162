"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const boxplotOption = function () {
  let sourceData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let department = arguments.length > 1 ? arguments[1] : undefined;
  let yAxisName = arguments.length > 2 ? arguments[2] : undefined;
  // 百分比格式化
  const formatRateToPercent = num => {
    return num.toLocaleString('zh', {
      style: 'percent',
      maximumFractionDigits: 2
    });
  };
  const option = {
    dataset: [{
      source: sourceData
    }, {
      transform: {
        type: 'boxplot',
        config: {
          itemNameFormatter: value => {
            const {
              value: key
            } = value;
            return department[key];
          }
        }
      }
    }, {
      fromDatasetIndex: 1,
      fromTransformResult: 1,
      print: true
    }],
    grid: {
      containLabel: true,
      top: '5%',
      left: 50,
      right: 20,
      bottom: sourceData.length > 3 ? '10%' : '5%'
    },
    xAxis: {
      type: 'category',
      axisLine: {
        show: false
      },
      axisLabel: {
        overflow: 'truncate',
        width: sourceData.length > 3 ? 90 : 100,
        rotate: sourceData.length > 3 ? 25 : 0
      },
      axisTick: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      name: `${yAxisName}事件命中率`,
      nameLocation: 'center',
      nameTextStyle: {
        padding: [0, 0, 30, 0]
      },
      axisLabel: {
        formatter: value => {
          return `${value.toLocaleString('zh', {
            style: 'percent'
          })}`;
        }
      }
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      }
    },
    color: ['#5470C6', '#91CC75'],
    series: [{
      name: 'boxplot',
      type: 'boxplot',
      datasetIndex: 1,
      animationDuration: 800,
      itemStyle: {
        color: '#b8c5f2'
      },
      tooltip: {
        trigger: 'item',
        appendToBody: true,
        axisPointer: {
          type: 'shadow'
        },
        borderColor: '#fff',
        formatter: params => {
          return `${params.name} </br>
            最大值：${formatRateToPercent(params.value[5])} </br>
            上四分位值：${formatRateToPercent(params.value[4])} </br>
            中位值：${formatRateToPercent(params.value[3])} </br>
            下四分位值：${formatRateToPercent(params.value[2])} </br>
            最小值：${formatRateToPercent(params.value[1])} </br>`;
        }
      }
    }, {
      name: '离群值',
      type: 'scatter',
      datasetIndex: 2,
      label: {
        show: true,
        position: [15, 0],
        formatter: params => {
          return `${formatRateToPercent(params.value[1])}`;
        }
      },
      tooltip: {
        trigger: 'item',
        borderColor: '#fff',
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          return `离群值：${formatRateToPercent(params.data[1])}`;
        }
      }
    }]
  };
  return option;
};
var _default = boxplotOption;
exports.default = _default;