"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
let chart = null;
var _default = {
  name: 'CustomerReachChart',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    chartLoading: Boolean,
    chartNames: {
      type: Array,
      default: () => []
    },
    chartLegend: {
      type: Array,
      default: () => []
    },
    hasBusinessInfo: {
      type: Boolean,
      default: false
    },
    businessInfo: {
      type: Array,
      default: () => []
    },
    businessDesc: {
      type: Array,
      default: () => []
    },
    xAxisMax: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'axis',
          confine: true,
          appendToBody: true,
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            const getLine = index => {
              if (!params[index] || !params[index].value) return '';
              const dot = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params[index].color};"></span><span style="margin-right:10px">${params[index].seriesName}</span>`;
              const text = `<span style="float:right">${params[index].value}</span>`;
              return `<div>${dot}${text}</div>`;
            };
            const sum = params.reduce((a, b) => a + b.value, 0).toFixed(2);
            const name = `<strong>${params[0].name}</strong>`;
            const totalScore = `<div>总分<span style="float:right">${sum}</span></div>`;
            const dividingLine = '<div style="height:1px;background:#EAEDF0;margin:16px 0"></div>';
            const businessList = this.businessInfo[this.businessInfo.length - 1 - params[0].dataIndex];
            const businessHtml = this.businessDesc.reduce((html, val, index) => {
              html += `<div style="display:flex;justify-content:space-between"><span style="margin-right:5px">${val}</span><span>${businessList[index]}</span></div>`;
              return html;
            }, '');
            let res = name + totalScore;
            params.forEach((_, index) => {
              res += getLine(index);
            });
            if (this.hasBusinessInfo) {
              res += dividingLine + businessHtml;
            }
            return res;
          }
        },
        legend: {
          type: 'scroll',
          width: '90%'
        },
        // 此项有用，勿删
        grid: {
          left: '3%',
          right: '10%',
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [],
        dataZoom: [{
          type: 'slider',
          show: true,
          zoomLock: true,
          yAxisIndex: 0,
          width: 0,
          moveHandleSize: 10,
          height: '90%',
          top: '5%',
          right: '3%',
          start: 100
        }]
      }
    };
  },
  watch: {
    data: {
      handler(val) {
        // 计算每个柱子总数
        const seriesData = val.map((item, index) => ({
          name: this.chartLegend[index].name,
          color: this.chartLegend[index].color,
          type: 'bar',
          stack: 'total',
          data: item,
          barWidth: 15
        }));
        this.options.yAxis.data = this.chartNames;
        this.options.xAxis.max = this.xAxisMax;
        this.options.series = seriesData;
        if (this.data.length) {
          this.options.dataZoom[0].end = this.data[0].length < 18 ? 0 : 100 - 18 / this.data[0].length * 100;
        }
        this.clearChart();
        this.$nextTick(() => {
          this.initChart();
        });
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.chartResize);
    window.addEventListener('beforeunload', this.clearChart);
  },
  beforeDestroy() {
    this.clearChart();
    window.removeEventListener('resize', this.chartResize);
    window.removeEventListener('beforeunload', this.clearChart);
  },
  methods: {
    initChart() {
      if (!this.$refs.chart) return;
      chart = echarts.init(this.$refs.chart, null, {
        renderer: 'svg'
      });
      chart.setOption(this.options);
    },
    chartResize() {
      chart.resize();
    },
    clearChart() {
      chart && chart.dispose();
    }
  }
};
exports.default = _default;