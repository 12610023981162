"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-time-select", _vm._b({
    attrs: {
      "picker-options": {
        ..._vm.defaultOptions,
        ..._vm.pickerOptions
      },
      placeholder: _vm.placeholder ? _vm.placeholder : `请选择${_vm.label}`
    },
    on: {
      change: _vm.handleChange
    },
    nativeOn: {
      input: function ($event) {
        return _vm.handleNativeInput.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  }, "el-time-select", _vm.$attrs, false));
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;