"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "customer-reach"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("通过堆叠图，来表示团队中每个销售的客户触达得分情况")]), _c("div", {
    staticClass: "stacking-chart"
  }, [_c("p", {
    staticClass: "chart-title text-center"
  }, [_vm._v(" 各销售 “"), _c("span", [_vm._v(_vm._s(_vm.chartTitle))]), _vm._v("” 触达情况 ")]), _c("customer-reach-chart", {
    attrs: {
      "chart-loading": _vm.chartLoading,
      data: _vm.chartData,
      "chart-names": _vm.chartNames,
      "chart-legend": _vm.chartLegend,
      "has-business-info": _vm.hasBusinessInfo,
      "business-info": _vm.businessInfo,
      "business-desc": _vm.businessDesc,
      "x-axis-max": _vm.xAxisMax
    }
  }), _c("p", {
    staticClass: "depart-name text-center"
  }, [_vm._v(" " + _vm._s(_vm.departmentName) + " ")]), _c("suggestion-box", {
    staticClass: "suggestion",
    attrs: {
      text: "客户触达情况，是指销售在客户跟进过程中，按照事件维度衡量销售对客户的触达情况。\n      可结合销售的多事件触达情况对相应能力进行监测、培训、考核和优化。例如：对于某事件或某类事件触达分数过低成员，\n      关注其在会话过程中相应的话术是否缺乏或话术能力是否需要提升，从而给予相应培训。"
    }
  })], 1), _c("el-button", {
    staticClass: "export-btn",
    on: {
      click: _vm.exportTableCsv
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]), _c("customer-reach-table", {
    attrs: {
      "table-data": _vm.tableData,
      "table-heads": _vm.tableHeads,
      loading: _vm.tableLoading
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;