"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemHeader = _interopRequireDefault(require("./components/FormItemHeader.vue"));
var _WidgetFormItem = _interopRequireDefault(require("@/components/EvaluateForm/components/WidgetFormItem"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'EvaluateFormWidget',
  props: {
    schema: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const schema = (0, _vue.ref)(props.schema);
    const initModel = () => {
      var _schema$value$fields;
      const model = {};
      (_schema$value$fields = schema.value.fields) === null || _schema$value$fields === void 0 ? void 0 : _schema$value$fields.map(field => {
        model[field.id] = field.field_value || '';
        const OPTIONS_IDS = [3, 4];
        const CASCADER_IDS = [8];
        if (OPTIONS_IDS.includes(field.field_type)) {
          model[field.id] = field.option_ids;
        } else if (CASCADER_IDS.includes(field.field_type)) {
          model[field.id] = field.cascader_ids || [];
        }
      });
      return (0, _vue.reactive)(model);
    };
    let model = initModel();
    (0, _vue.watch)(() => props.schema, () => {
      schema.value = props.schema;
      model = initModel();
    });
    const getFormData = () => {
      return schema.value.fields.map(field => {
        const OPTIONS_IDS = [3, 4];
        const CASCADER_IDS = [8];
        if (OPTIONS_IDS.includes(field.field_type)) {
          return {
            field_id: field.id,
            field_value: null,
            option_ids: model[field.id],
            cascader_ids: null
          };
        } else if (CASCADER_IDS.includes(field.field_type)) {
          return {
            field_id: field.id,
            field_value: null,
            option_ids: null,
            cascader_ids: model[field.id]
          };
        } else {
          return {
            field_id: field.id,
            field_value: model[field.id],
            option_ids: null,
            cascader_ids: null
          };
        }
      });
    };
    const rules = (0, _vue.computed)(() => {
      const rules = {};
      if (!schema.value.fields) return;
      schema.value.fields.map(field => {
        rules[field.id] = [{
          required: !!field.required,
          message: '该字段为必填项',
          trigger: 'change'
        }];
      });
      return rules;
    });
    const formRef = (0, _vue.ref)(null);
    const validateForm = () => {
      let valid = false;
      formRef.value.validate(v => {
        valid = v;
      });
      return valid;
    };
    const handleOptionFieldChange = (value, formItem) => {
      formItem.option_ids = value;
      model[formItem.id] = value;
    };
    const handleCascaderFieldChange = (value, formItem) => {
      formItem.cascader_ids = value;
      model[formItem.id] = value;
    };
    expose({
      getFormData,
      validateForm
    });
    return {
      __sfc: true,
      props,
      schema,
      initModel,
      model,
      getFormData,
      rules,
      formRef,
      validateForm,
      handleOptionFieldChange,
      handleCascaderFieldChange,
      FormItemHeader: _FormItemHeader.default,
      WidgetFormItem: _WidgetFormItem.default
    };
  }
};
exports.default = _default;