"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _setup$modelSentenceF, _setup$modelSentenceF2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "model-sentence"
  }, [_c("div", {
    staticClass: "model-sentence-left"
  }, [_c("div", {
    staticClass: "model-sentence-left__content"
  }, [_c("div", {
    staticClass: "model-sentence__content-container"
  }, [_c("div", {
    staticClass: "model-sentence__content"
  }, [_vm._m(0), _c("div", {
    staticClass: "workspace"
  }, [_c("div", {
    staticClass: "workspace-desc"
  }, [_vm._v("训练数据所属空间")]), _c(_setup.WorkspaceSelector, {
    attrs: {
      multiple: "",
      placeholder: "请选择空间"
    },
    model: {
      value: _setup.modelSentenceForm.workspace_ids,
      callback: function ($$v) {
        _vm.$set(_setup.modelSentenceForm, "workspace_ids", $$v);
      },
      expression: "modelSentenceForm.workspace_ids"
    }
  })], 1), _c("div", [_c("div", {
    staticClass: "model-sentence__content-title"
  }, [_vm._v(" 例句 (" + _vm._s(((_setup$modelSentenceF = _setup.modelSentenceForm.sentences) === null || _setup$modelSentenceF === void 0 ? void 0 : _setup$modelSentenceF.length) || 1) + ") ")]), _c("div", {
    staticClass: "model-sentence__content-desc"
  }, [_vm._v(" 填写例句时，词汇、句式等表述方式请尽量多样化，否则模型预测可能过于单一” ")])]), _c(_setup.SentenceType, {
    attrs: {
      "example-type": _setup.exampleType
    },
    on: {
      "update:exampleType": function ($event) {
        _setup.exampleType = $event;
      },
      "update:example-type": function ($event) {
        _setup.exampleType = $event;
      }
    }
  }), _c("div", {
    staticClass: "model-sentence__content-sentences"
  }, [_c("el-form", {
    ref: "modelSentenceFormRef",
    attrs: {
      model: _setup.modelSentenceForm
    }
  }, _vm._l(_setup.modelSentenceForm.sentences, function (modelSentence, i) {
    return _c("el-form-item", {
      key: i
    }, [_c(_setup.ModelSentenceItem, {
      attrs: {
        "example-type": _setup.exampleType,
        "entity-type-label-obj": _setup.entityTypeLabelObj,
        "model-sentence": modelSentence
      },
      on: {
        changeModelSentenceItem: _setup.changeModelSentenceItem,
        deleteModelSentenceItem: function ($event) {
          return _setup.deleteModelSentenceItem(i);
        },
        handleAIExpand: _setup.handleAIExpand
      }
    })], 1);
  }), 1), ((_setup$modelSentenceF2 = _setup.modelSentenceForm.sentences) === null || _setup$modelSentenceF2 === void 0 ? void 0 : _setup$modelSentenceF2.length) < 100 ? _c("el-button", {
    staticClass: "add-sentence-btn",
    attrs: {
      icon: "iconfont icon-plus"
    },
    on: {
      click: function ($event) {
        return _setup.addModelSentenceItem();
      }
    }
  }, [_vm._v("例句")]) : _vm._e()], 1)], 1)])])]), _c(_setup.IntelligentExtension, {
    ref: "intelligentSentenceRef",
    attrs: {
      "entity-type-label-obj": _setup.entityTypeLabelObj,
      "intelligent-sentence-drawer-visible": _setup.intelligentSentenceDrawerVisible,
      "example-type": _setup.exampleType,
      "gpt-loading": _setup.gptLoading,
      "example-sentence-list": _setup.exampleSentenceList
    },
    on: {
      "update:intelligentSentenceDrawerVisible": function ($event) {
        _setup.intelligentSentenceDrawerVisible = $event;
      },
      "update:intelligent-sentence-drawer-visible": function ($event) {
        _setup.intelligentSentenceDrawerVisible = $event;
      },
      addExtendedSentenceToExample: _setup.addExtendedSentenceToExample,
      handleIntelligentSentence: _setup.handleIntelligentSentence,
      deleteExampleSentence: _setup.deleteExampleSentence
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "model-sentence__content-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _c("span", [_vm._v("请尽可能多的提供符合事件含义的句子，我们会根据例句，在会话中寻找语义相似的句子，用于训练模型")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;