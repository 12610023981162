"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timezone = require("@/utils/timezone");
var _timeFormatter = require("@/utils/time-formatter");
var _default = {
  __name: 'SingleSentence',
  props: {
    sentence: {
      type: Object,
      default: () => ({
        begin_time: 100.54,
        entity_type: 'host_salesman',
        name: '张三',
        content: '大大扩大空间的集卡活动回家哈数据肯定会撒娇肯定会尽快回到家卡上打进卡上的接口'
      })
    },
    mediaType: {
      type: String,
      default: 'audio'
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone,
      secondToTime: _timeFormatter.secondToTime
    };
  }
};
exports.default = _default;