"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MetricsManegeDrawer = _interopRequireDefault(require("./MetricsManegeDrawer.vue"));
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _PracticeProcessTipsSwitch = _interopRequireDefault(require("./PracticeProcessTipsSwitch.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ScoreSet',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['add-metric', 'delete-metric', 'input'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const metricsManegeDrawerVisible = (0, _vue.ref)(false);
    const promptStruct = (0, _vue.inject)('promptStruct');
    const addMetric = () => {
      emit('add-metric');
    };
    const deleteMetric = id => {
      emit('delete-metric', id);
    };
    const onInput = value => {
      emit('input', value);
    };
    return {
      __sfc: true,
      props,
      metricsManegeDrawerVisible,
      promptStruct,
      emit,
      addMetric,
      deleteMetric,
      onInput,
      MetricsManegeDrawer: _MetricsManegeDrawer.default,
      MgDraggable: _MgDraggable.default,
      PracticeProcessTipsSwitch: _PracticeProcessTipsSwitch.default
    };
  }
};
exports.default = _default;