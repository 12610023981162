"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "container"
  }, [_c("MgvMultiLevelMenu", {
    ref: "nav-menu",
    attrs: {
      "menu-title": _vm.$t("coaching.train"),
      "menu-list": _vm.menuList,
      "default-openeds": _vm.defaultOpeneds
    },
    on: {
      updateIsHiddenMenu: _vm.updateIsHiddenMenu
    }
  }), _c("div", {
    staticClass: "right-part",
    on: {
      mouseover: _vm.floatingMenuHidden
    }
  }, [_c("router-view", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu
    },
    on: {
      showMyTour: _vm.showMyTour
    }
  })], 1)], 1), _c("MgvTour", {
    ref: "taskManagementGuide",
    attrs: {
      name: "isShowTaskManagementGuide",
      steps: _vm.singleStep
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;