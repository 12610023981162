"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$batchEventIds;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "batch-operate"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 已选"), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s((_vm$batchEventIds = _vm.batchEventIds) === null || _vm$batchEventIds === void 0 ? void 0 : _vm$batchEventIds.length))]), _vm._v("项 ")]), _c("div", {
    attrs: {
      "operate-btns": ""
    }
  }, [_c("el-button", {
    attrs: {
      icon: "iconfont icon-filter"
    },
    on: {
      click: _setup.batchSetKeyEventApplyPermission
    }
  }, [_vm._v("批量设置应用范围")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;