"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timeFormatter = require("@/utils/time-formatter");
var _vue = require("vue");
var _default = {
  __name: 'StudyStatus',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const activeName = (0, _vue.ref)('waiting_study');
    const tabsHeaders = [{
      name: 'waiting_study',
      label: '待学习'
    }, {
      name: 'complete_study',
      label: '已学习'
    }];
    return {
      __sfc: true,
      props,
      activeName,
      tabsHeaders,
      secondToCNTime: _timeFormatter.secondToCNTime
    };
  }
};
exports.default = _default;