"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: _vm.title,
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "80%",
      "custom-class": "create-conv-flow-drawer",
      "wrapper-closable": false,
      "modal-append-to-body": _vm.modalAppendToBody,
      "append-to-body": _vm.appendToBody
    }
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("div", {
    staticClass: "conv-list-page"
  }, [!_vm.noTip ? _c("div", {
    staticClass: "top-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v(" " + _vm._s(_vm.tipContent) + " ")]) : _vm._e(), _c("conv-list-page", {
    class: {
      "no-tip": _vm.noTip
    },
    attrs: {
      "back-fill-filters": _vm.backFillFilters,
      "page-sizes": _vm.pageSizes,
      "has-extra-page": true
    },
    on: {
      convFlowFiltersChange: _vm.convFlowFiltersChange,
      conversationListChange: _vm.conversationListChange,
      filterNumChange: _vm.filterNumChange,
      convTotalCount: _vm.getConvTotalCount
    }
  })], 1)]), _c("footer", {
    staticClass: "footer-btn"
  }, [_vm._t("footer-operate")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;