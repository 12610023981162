"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KeyEventCascader = _interopRequireDefault(require("./KeyEventCascader.vue"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _WorkspaceSelector = _interopRequireDefault(require("@/components/Filters/WorkspaceSelector/WorkspaceSelector"));
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModelManagementFilters',
  emits: ['customChange'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const filters = (0, _vue.ref)({
      model_name: '',
      model_type: '',
      online_type: '',
      event_id: '',
      workspace_id: '',
      create_by: {}
    });
    const route = (0, _useRouter.useRoute)();
    (0, _vue.watch)(() => route.query, query => {
      if (query.model_id) {
        filters.value.model_name = query.model_id;
      }
    }, {
      immediate: true
    });
    const customChange = () => {
      emit('customChange', filters.value);
    };
    const debounceChange = (0, _debounce.default)(customChange, 300);
    const getFilters = () => {
      const handleFilter = {
        model_name: filters.value.model_name,
        model_type: filters.value.model_type || 0,
        online_type: filters.value.online_type || 0,
        workspace_id: filters.value.workspace_id || 0,
        // event_id: filters.value.event_id || 0,
        create_by: filters.value.create_by
      };
      return handleFilter;
    };
    const handleMemberChange = val => {
      filters.value.create_by = val;
      customChange();
    };
    expose({
      getFilters
    });
    return {
      __sfc: true,
      filters,
      route,
      emit,
      customChange,
      debounceChange,
      getFilters,
      handleMemberChange,
      KeyEventCascader: _KeyEventCascader.default,
      getDataOrgMemberTree: _user.getDataOrgMemberTree,
      getUserList: _organization.getUserList,
      WorkspaceSelector: _WorkspaceSelector.default
    };
  }
};
exports.default = _default;