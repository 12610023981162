"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contact-person-detail"
  }, [_c("header", {
    staticClass: "contact-header"
  }, [_c("span", [_vm._v("商机联系人")]), _c("span", [_vm._v("（" + _vm._s(_vm.total) + "）")])]), _c("div", {
    staticClass: "contact-content"
  }, _vm._l(_vm.contactPersonList, function (item, index) {
    return _c("contact-person-card", {
      key: index,
      attrs: {
        value: item,
        formDesc: _vm.formDesc,
        inDeal: true,
        isMain: item.is_main
      },
      on: {
        setDecisionMarker: _vm.setDecisionMarker,
        cancelSetDecisionMarker: _vm.cancelSetDecisionMarker
      }
    });
  }), 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;