"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "member-table"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.memberList
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "40"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "成员"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("image-avatar", {
          attrs: {
            name: row.name,
            avatar: row.avatar,
            type: "'host-avatar'"
          }
        }), _c("span", [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "departments",
      label: "部门"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            enterable: false,
            "open-delay": 400,
            effect: "dark",
            placement: "top"
          }
        }, [_c("div", {
          ref: "content",
          attrs: {
            slot: "content"
          },
          domProps: {
            innerHTML: _vm._s(_vm.getDepartmentNames(row.departments))
          },
          slot: "content"
        }), _c("div", {
          staticClass: "departments"
        }, _vm._l(row.departments, function (item, index) {
          return _c("span", {
            key: index,
            staticClass: "departments"
          }, [_vm._v(" " + _vm._s(item.super_tree_names[item.super_tree_names.length - 1]) + " "), item.is_main_tree ? _c("i", {
            staticClass: "main-department"
          }, [_vm._v("主部门")]) : _vm._e(), _vm._v(" " + _vm._s(index !== row.departments.length - 1 ? "；" : ""))]);
        }), 0)])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      "show-overflow-tooltip": "",
      prop: "role_names",
      label: "业务角色"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [row.role_list.length ? _c("span", {
          staticClass: "func-role"
        }, [_vm._v(_vm._s(row.role_list.map(item => item.name).join("；")))]) : _c("span", {
          staticClass: "unassigned"
        }, [_vm._v("未分配")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "operate",
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("el-button", {
          staticClass: "edit-button",
          on: {
            click: function ($event) {
              return _vm.editMember({
                type: "edit",
                membersList: row
              });
            }
          }
        }, [_vm._v("编辑")])];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;