"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RowEditTable = _interopRequireDefault(require("./RowEditTable.vue"));
var _calendar = require("@/api/calendar");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _nanoid = require("nanoid");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'InstructionSetDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    (0, _vue.watch)(() => props.visible, visible => {
      if (visible) {
        getInstructionSetListRequest();
        tableIsEditing.value = false;
      }
    });
    const closeDialog = () => {
      emit('update:visible', false);
    };
    const tableData = (0, _vue.ref)([]);
    const tableLoading = (0, _vue.ref)(false);
    const getInstructionSetListRequest = async () => {
      tableLoading.value = true;
      const res = await (0, _calendar.getInstructionSetList)({
        space_id: store.getters.workspaceInfo.id
      }).finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        tableData.value = res.results.items;
      }
    };
    const rowEditTableRef = (0, _vue.ref)(null);
    const addInstruction = () => {
      const row = {
        id: (0, _nanoid.nanoid)(),
        name: '',
        content: '',
        departments: [],
        user_info: {
          name: store.getters.name,
          avatar: store.getters.avatar
        }
      };
      tableData.value.unshift(row);
      rowEditTableRef.value.addRow(row);
    };
    const createInstructionRequest = async (row, cb) => {
      const res = await (0, _calendar.createInstruction)({
        space_id: store.getters.workspaceInfo.id,
        content: row.content,
        tree_ids: row.departments,
        name: row.name
      }).finally(() => {
        cb(false);
      });
      if (res.code === 20000) {
        getInstructionSetListRequest();
        _elementUi.Message.success('添加成功');
        cb(true);
      }
    };
    const saveNewRow = (row, cb) => {
      createInstructionRequest(row, cb);
    };
    const saveEditRowRequest = async (row, cb) => {
      const res = await (0, _calendar.updateInstruction)({
        id: row.id,
        space_id: store.getters.workspaceInfo.id,
        content: row.content,
        tree_ids: row.departments,
        name: row.name
      }).finally(() => {
        cb(false);
      });
      if (res.code === 20000) {
        getInstructionSetListRequest();
        _elementUi.Message.success('保存成功');
        cb(true);
      }
    };
    const saveEditRow = (row, cb) => {
      saveEditRowRequest(row, cb);
    };
    const deleteRowRequest = async (row, index, cb) => {
      const res = await (0, _calendar.deleteInstruction)({
        ids: [row.id]
      });
      if (res.code === 20000) {
        tableData.value.splice(index, 1);
        _elementUi.Message.success('删除成功');
        cb();
      }
    };
    const deleteRow = (row, index, cb) => {
      if (row.isAdd) {
        tableData.value.splice(index, 1);
        cb();
      } else {
        deleteRowRequest(row, index, cb);
      }
    };
    const tableIsEditing = (0, _vue.ref)(false);
    const editStatusChange = status => {
      tableIsEditing.value = status;
    };
    const getAddBtnTip = () => {
      if (tableIsEditing.value) {
        return '请先完成正在编辑的指令';
      } else if (tableData.value.length >= 200) {
        return '团队指令数量达到200上限';
      } else {
        return '';
      }
    };
    return {
      __sfc: true,
      props,
      store,
      emit,
      closeDialog,
      tableData,
      tableLoading,
      getInstructionSetListRequest,
      rowEditTableRef,
      addInstruction,
      createInstructionRequest,
      saveNewRow,
      saveEditRowRequest,
      saveEditRow,
      deleteRowRequest,
      deleteRow,
      tableIsEditing,
      editStatusChange,
      getAddBtnTip,
      RowEditTable: _RowEditTable.default
    };
  }
};
exports.default = _default;