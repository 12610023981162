"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _fullScreenBtn = _interopRequireDefault(require("@/components/FullScreen/fullScreenBtn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChartFunctionBtns',
  components: {
    fullScreenBtn: _fullScreenBtn.default
  },
  props: {
    chartRadio: {
      type: String,
      default: ''
    },
    totalTopPath: {
      type: [String, Number],
      default: 0
    },
    sankeyPathTotalStep: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      pathRadio: ''
    };
  },
  watch: {
    chartRadio: {
      handler(val) {
        this.$nextTick(() => {
          if (val === '思维导图') {
            this.pathRadio = 'top1';
          } else {
            this.pathRadio = '';
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    // 桑基图复原
    restoreSankeyView() {
      this.pathRadio = '';
      this.$emit('restore');
    },
    // 思维导图切换/复原
    topPathChange() {
      this.$emit('change', this.pathRadio);
    }
  }
};
exports.default = _default;