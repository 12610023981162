"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "videoWrapper",
    staticClass: "di video-wrap"
  }, [_vm.mediaType !== "doc" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.video.waiting,
      expression: "video.waiting"
    }],
    ref: "videoBox",
    staticClass: "video-box"
  }, [_c("video", {
    ref: "video",
    staticClass: "video-js vjs-default-skin",
    attrs: {
      id: "video",
      src: _vm.url,
      oncontextmenu: "return false;",
      preload: _vm.video.preload
    },
    on: {
      play: _vm.onPlay,
      playing: _vm.onPlaying,
      error: _vm.onError,
      waiting: _vm.onWaiting,
      progress: _vm.onProgress,
      pause: _vm.onPause,
      timeupdate: _vm.onTimeupdate,
      loadedmetadata: _vm.onLoadedmetadata,
      ended: _vm.onended,
      click: _vm.startPlayOrPause
    }
  }), !_vm.video.playing && _vm.canPlay && !_vm.video.waiting ? _c("div", {
    staticClass: "play-btn",
    on: {
      click: _vm.startPlayOrPause
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "video-play1"
    }
  })], 1) : !_vm.canPlay ? _c("div", {
    staticClass: "play-btn"
  }, [_c("svg", {
    staticClass: "icon svg-icon",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "#icon-warning-outline"
    }
  })])]) : _vm._e(), _c("div", {
    staticClass: "control-buttons"
  }, [_vm.mediaType === "video" ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      content: "画中画"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-picture-in-picture",
    on: {
      click: _vm.enterPictureInPicture
    }
  })]) : _vm._e(), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top",
      content: "全屏"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-full-screen",
    on: {
      click: _vm.enterFullscreen
    }
  })])], 1)]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;