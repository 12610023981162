"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contact-list-wrapper"
  }, _vm._l(_vm.contactList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "contact-list",
      on: {
        click: function ($event) {
          return _vm.clickContactPersonItem(item);
        }
      }
    }, [_c("span", {
      staticClass: "info-name"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowTel,
        expression: "isShowTel"
      }],
      staticClass: "info-tel"
    }, [_vm._v(_vm._s(item.tel))])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;