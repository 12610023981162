"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _library = require("@/api/library");
var _ChooseFolderSelect = _interopRequireDefault(require("./ChooseFolderSelect"));
var _SpaceSelect = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/SpaceSelect.vue"));
var _SetPublicScope = _interopRequireDefault(require("@/views/coaching/coach/course/components/SetPublicScope.vue"));
var _dialogMixins = _interopRequireDefault(require("./dialogMixins"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DynamicForm: _DynamicForm.default,
    ChooseFolderSelect: _ChooseFolderSelect.default,
    SpaceSelect: _SpaceSelect.default,
    SetPublicScope: _SetPublicScope.default
  },
  mixins: [_dialogMixins.default],
  inject: {
    getConvSpaceId: {
      from: 'getConvSpaceId',
      default: () => () => {}
    }
  },
  props: {
    createSubfolder: Boolean,
    showSpaceSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        folder_id: ''
      },
      btnLoading: false,
      folderPosition: {},
      isPublic: 1
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    formDesc() {
      const name = {
        id: 'name',
        type: 'text',
        label: '名称',
        required: true
      };
      const position = {
        id: 'folder_id',
        type: 'text',
        label: '位置',
        required: true
      };
      const space = {
        id: 'space_id',
        type: 'text',
        label: '空间',
        required: true
      };
      return this.showSpaceSelect ? [name, space, position] : [name, position];
    }
  },
  watch: {
    currentNode(val) {
      this.setFolderPosition(val);
    },
    folderPosition(val) {
      if (this.currentNode) {
        this.currentNode.fid = val.id;
      }
      this.form.folder_id = val.id;
    },
    createSubfolder() {
      this.setFolderPosition(this.currentNode);
    },
    // isPublic: {
    //   handler(val) {
    //     if (val) {
    //       this.checkScopeValid();
    //     }
    //   },
    //   immediate: true
    // },
    visible(val) {
      val && this.setSpaceId();
    }
  },
  methods: {
    setFolderPosition(val) {
      this.form.name = '';
      if (this.createSubfolder) {
        this.folderPosition = val && val.id ? {
          ...val
        } : {
          id: 0,
          name: '剪辑库'
        };
      } else {
        this.folderPosition = {
          id: 0,
          name: '剪辑库'
        };
      }
    },
    submit() {
      // isPublic ：0-对指定部门和成员可见  1-对所有人可见
      const {
        isPublic,
        assignee
      } = this.$refs['SetPublicScopeRef'];
      // this.checkScopeValid();
      this.form.folder_id = this.folderPosition.id;
      const form = {
        ...this.form,
        is_public: isPublic
      };
      if (!isPublic) {
        Object.assign(form, assignee);
      }
      console.log(form, 'form===');

      // 解决校验[位置不能为空]问题
      this.$nextTick(async () => {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.btnLoading = true;
          const res = await (0, _library.createFolder)(form).finally(() => {
            this.btnLoading = false;
          });
          if (res.code === 20000) {
            this.$message.success('新建成功');
            this.$emit('submitSuccess', {
              id: res.results.folder_id,
              name: this.form.name,
              is_leaf: true,
              spaceId: this.form.space_id
            });
            this.closeDialog();
          }
        }
      });
    },
    // checkScopeValid() {
    //   this.form.scope = this.isPublic ? true : this.ids.length || '';
    // },
    setSpaceId() {
      this.form.space_id = this.getConvSpaceId() || this.workspaceInfo.id;
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.form.name = '';
      this.form.folder_id = '';
      // 不清空space_id 清空则会引发请求folderTree，造成数据覆盖
      this.$refs.form.resetFields();
      this.setFolderPosition(this.currentNode);
    },
    handleSpaceChange() {
      this.folderPosition = {};
      this.form.folder_id = '';
    }
  }
};
exports.default = _default;