"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: "撤销合并",
      visible: _vm.revertDialogVisible,
      "before-close": _vm.handleClose
    }
  }, [_c("i", {
    staticClass: "el-icon-warning"
  }), _c("span", [_vm._v(" 确认撤销 " + _vm._s(_vm.revertMergenceInfo.name) + " 与 " + _vm._s(_vm.revertMergenceInfo.merged_contact_name) + " 的合并？ ")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeCancelMergenceDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.confirmToCancelMergence(_vm.revertMergenceInfo);
      }
    }
  }, [_vm._v("确定 ")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;