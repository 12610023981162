"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "task-detail-filters"
  }, [_c("div", {
    staticClass: "left-filter-container"
  }, [_c("MgvMemberMultiSelect", {
    staticClass: "member-select filter-item",
    attrs: {
      placeholder: "请选择学员",
      "request-function": _setup.getDataOrgMemberTree,
      "request-search-function": _setup.getUserList,
      "is-prevent-not-selected": false,
      "request-params": {
        type: JSON.stringify(["training_read"])
      },
      "backfill-list": _setup.backfillList
    },
    on: {
      change: _setup.onMemberChange
    }
  }), _c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: "搜索任务",
      clearable: ""
    },
    on: {
      change: _setup.customChange
    },
    model: {
      value: _setup.filters.plan_name,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "plan_name", $$v);
      },
      expression: "filters.plan_name"
    }
  }), _c("div", {
    staticClass: "date-time-select"
  }, [_c("el-select", {
    on: {
      change: _setup.onDatePickerChange
    },
    model: {
      value: _setup.dateType,
      callback: function ($$v) {
        _setup.dateType = $$v;
      },
      expression: "dateType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "任务开始时间",
      value: "task_start_time"
    }
  }), _c("el-option", {
    attrs: {
      label: "任务练习时间",
      value: "task_practice_time"
    }
  })], 1), _c("MgvDatePicker", _vm._b({
    attrs: {
      clearable: "",
      placeholder: "请选择日期",
      shortcuts: ["今天", "昨天", "近7天", "近30天", "近3个月"]
    },
    on: {
      change: _setup.onDatePickerChange
    },
    model: {
      value: _setup.dateTime,
      callback: function ($$v) {
        _setup.dateTime = $$v;
      },
      expression: "dateTime"
    }
  }, "MgvDatePicker", _vm.$attrs, false))], 1), _c("el-popover", {
    attrs: {
      width: "300",
      trigger: "click",
      placement: "bottom-start",
      "visible-arrow": false
    }
  }, [_c("div", {
    staticClass: "popover-content"
  }, [_c("h1", {
    staticClass: "popover-content__title"
  }, [_vm._v("更多筛选")]), _c("div", {
    staticClass: "filter-item"
  }, [_c("p", {
    staticClass: "filter-item__label"
  }, [_vm._v("任务状态")]), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: "任务状态",
      clearable: ""
    },
    on: {
      change: _setup.customChange
    },
    model: {
      value: _setup.filters.is_complete,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "is_complete", $$v);
      },
      expression: "filters.is_complete"
    }
  }, _vm._l(_setup.statusOptions, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        value: status.value,
        label: status.label
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("p", {
    staticClass: "filter-item__label"
  }, [_vm._v("任务分类")]), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: "任务分类",
      clearable: ""
    },
    on: {
      change: _setup.customChange
    },
    model: {
      value: _setup.filters.category,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "category", $$v);
      },
      expression: "filters.category"
    }
  }, _vm._l(_setup.taskTypeOptions, function (taskType) {
    return _c("el-option", {
      key: taskType.id,
      attrs: {
        value: taskType.id,
        label: taskType.name
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("p", {
    staticClass: "filter-item__label"
  }, [_vm._v("得分区间")]), _c(_setup.ScoreRangeSelect, {
    staticClass: "score-range-select",
    attrs: {
      "max-limit": 999,
      placeholder: "任务得分范围"
    },
    on: {
      change: _setup.onScoreRangeChange
    },
    model: {
      value: _setup.filters.score,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "score", $$v);
      },
      expression: "filters.score"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("p", {
    staticClass: "filter-item__label"
  }, [_vm._v("课程名称")]), _c("el-input", {
    attrs: {
      placeholder: "搜索课程",
      clearable: ""
    },
    on: {
      change: _setup.customChange
    },
    model: {
      value: _setup.filters.course_name,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "course_name", $$v);
      },
      expression: "filters.course_name"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("p", {
    staticClass: "filter-item__label"
  }, [_vm._v("课程类型")]), _c("el-select", {
    attrs: {
      placeholder: "课程类型",
      clearable: ""
    },
    on: {
      change: _setup.customChange
    },
    model: {
      value: _setup.filters.course_type,
      callback: function ($$v) {
        _vm.$set(_setup.filters, "course_type", $$v);
      },
      expression: "filters.course_type"
    }
  }, _vm._l(_setup.courseTypeOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        value: item.value,
        label: item.label
      }
    });
  }), 1)], 1)]), _c("MgvButton", {
    staticClass: "more-filter-btn",
    class: {
      "is-active": !!_setup.moreFilterCount
    },
    attrs: {
      slot: "reference",
      icon: "icon-filter",
      type: "plain",
      color: "info"
    },
    slot: "reference"
  }, [_vm._v("更多筛选"), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.moreFilterCount,
      expression: "moreFilterCount"
    }],
    staticClass: "more-filter-count"
  }, [_vm._v(_vm._s(_setup.moreFilterCount))])])], 1)], 1), _c("div", {
    staticClass: "right-filter-container"
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-export"
    },
    on: {
      click: _setup.exportTable
    }
  }, [_vm._v("导出表格")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;