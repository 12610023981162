"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssistAccountRecommendList = getAssistAccountRecommendList;
exports.getAssistDataPermisison = getAssistDataPermisison;
exports.getAssistTodoEventList = getAssistTodoEventList;
exports.getAssistUnreadCount = getAssistUnreadCount;
var _request = require("@/utils/request");
function getAssistAccountRecommendList(data) {
  return (0, _request.webService)({
    url: 'assist/todo/list',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getAssistTodoEventList(params) {
  return (0, _request.webService)({
    url: 'event/todo/list',
    method: 'get',
    params
  });
}
function getAssistUnreadCount() {
  return (0, _request.webService)({
    url: 'assist/unread_count',
    method: 'get'
  });
}
function getAssistDataPermisison() {
  return (0, _request.webService)({
    url: 'assist/perm/is_self',
    method: 'get'
  });
}