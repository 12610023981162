"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _TaskCard = _interopRequireDefault(require("./TaskCard"));
var _taskCenter = require("@/api/taskCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'IsDoneTaskList',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default,
    TaskCard: _TaskCard.default
  },
  props: {
    activeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      taskListLength: 0,
      taskListParams: {
        page: 1,
        size: 10
      },
      taskListLoading: false,
      taskDataMap: {},
      noMoreTask: false
    };
  },
  computed: {
    noMore() {
      return this.taskListLength && this.noMoreTask;
    },
    disabled() {
      return this.taskListLoading || this.noMore;
    }
  },
  watch: {
    activeType: {
      handler(val) {
        // 获取已完成任务列表（我的任务/我分配的）
        this.initListData();
        this.getTaskListByType();
      },
      immediate: true
    }
  },
  methods: {
    initListData() {
      this.taskDataMap = {};
      this.taskListLength = 0;
      this.taskListParams.page = 1;
    },
    // 获取商机列表
    async getTaskListByType() {
      try {
        this.taskListLoading = true;
        const requestParams = {
          task_type: this.activeType === 'myTask' ? 1 : 0,
          ...this.taskListParams
        };
        const res = await (0, _taskCenter.getCompletedTaskList)(requestParams);
        if (res.code === 20000 && res.results.data) {
          this.noMoreTask = res.results.data.length < 10;
          this.taskListLength += res.results.data.length;
          this.taskListParams.page++;
          this.handleTaskData(res.results.data);
        }
      } finally {
        this.taskListLoading = false;
      }
    },
    handleTaskData(taskData) {
      for (let i = 0; i < taskData.length; i++) {
        const item = taskData[i];
        if (this.taskDataMap[item.tag]) {
          this.taskDataMap[item.tag].push(item);
        } else {
          this.taskDataMap[item.tag] = [item];
        }
      }
      this.$forceUpdate();
    },
    loadMore() {
      this.getTaskListByType();
    },
    goTaskDetail(data) {
      this.$emit('goTaskDetail', data.task_info.task_id);
    },
    // 删除任务
    handleDeleteTask(key, task_id) {
      this.taskDataMap[key] = this.taskDataMap[key].filter(item => item.task_info.task_id !== task_id);
      this.$forceUpdate(); // 因为this.taskDataMap的属性是动态添加的，不能响应，所以需要加强制更新。
    }
  }
};

exports.default = _default;