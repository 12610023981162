"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/** 传入的props数据结构
 * tipInfo: {
        mainIntroduction: {
          title: '',
          content: ''
        },
        description: [
          {
            title: '',
            content: ['', '']
          }
        ],
        helpUrl: ''
      }
 */
var _default = {
  name: 'EmptyHelpTip',
  components: {},
  props: {
    tipInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
exports.default = _default;