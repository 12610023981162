"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-popover", {
    ref: "popover",
    attrs: {
      placement: "bottom",
      trigger: "click",
      width: "400px",
      "visible-arrow": false
    },
    model: {
      value: _vm.popVisiable,
      callback: function ($$v) {
        _vm.popVisiable = $$v;
      },
      expression: "popVisiable"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_c("el-scrollbar", [!_vm.uploadType ? _c("div", {
    staticClass: "pop-card"
  }, [_c("span", {
    staticClass: "title-style"
  }, [_vm._v("选择身份")]), _c("div", _vm._l(_vm.options, function (item, index) {
    return _c("div", {
      key: item.value,
      staticClass: "item-back"
    }, [_c("div", {
      staticClass: "item-style",
      on: {
        mousedown: function ($event) {
          return _vm.handleEnter(index, item.name);
        }
      }
    }, [_c("div", {
      staticClass: "item-name"
    }, [_c("image-avatar", {
      attrs: {
        name: item.name,
        avatar: item.avatar,
        type: item.type
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(_vm._s(item.name))])], 1), item.id === _vm.testValue ? _c("div", {
      staticClass: "el-icon-check"
    }) : _vm._e()])]);
  }), 0)]) : _vm._e(), _vm.uploadType && _vm.hasRole ? _c("div", {
    staticClass: "pop-card"
  }, [_vm.peopleType === "host_salesman" ? _c("div", [_c("span", {
    staticClass: "title-style"
  }, [_vm._v(_vm._s(_vm.companyName))]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.saleLoading,
      expression: "saleLoading"
    }]
  }, _vm._l(_vm.saleList, function (item, index) {
    return _c("div", {
      key: item.value,
      staticClass: "item-back"
    }, [_c("div", {
      staticClass: "item-style",
      on: {
        mousedown: function ($event) {
          return _vm.handleEnterName(index, item.id, item);
        }
      }
    }, [_c("div", {
      staticClass: "item-name"
    }, [_c("image-avatar", {
      attrs: {
        name: item.name,
        avatar: item.avatar,
        type: item.entity_type
      }
    }), _c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "baseline",
        width: "88px",
        "justify-content": "space-between"
      }
    }, [_c("div", {
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v(_vm._s(item.name))]), item.is_follower ? _c("div", {
      staticStyle: {
        "font-size": "12px",
        color: "#c7ccdc"
      }
    }, [_vm._v(" " + _vm._s("跟进人") + " ")]) : _vm._e()])], 1), item.id === _vm.testValue ? _c("div", {
      staticClass: "el-icon-check"
    }) : _vm._e()])]);
  }), 0)]) : _vm._e(), _vm.peopleType === "customer_contact" ? _c("div", [_c("span", {
    staticClass: "title-style"
  }, [_vm._v("客户")]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.custLoading,
      expression: "custLoading"
    }],
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, _vm._l(_vm.custList, function (item, index) {
    return _c("div", {
      key: item.value,
      staticClass: "item-back"
    }, [_c("div", {
      staticClass: "item-style",
      on: {
        mousedown: function ($event) {
          return _vm.handleEnterName(index, item.id, item);
        }
      }
    }, [_c("div", {
      staticClass: "item-name"
    }, [_c("image-avatar", {
      attrs: {
        name: item.name,
        avatar: item.avatar,
        type: item.entity_type
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(_vm._s(item.name))])], 1), item.id === _vm.testValue ? _c("div", {
      staticClass: "el-icon-check"
    }) : _vm._e()])]);
  }), 0)]) : _vm._e()]) : _vm._e(), _vm.uploadType && !_vm.hasRole ? _c("div", {
    staticClass: "pop-card1"
  }, [_c("div", {
    staticClass: "select-button"
  }, [_c("el-button", {
    staticClass: "sale-button",
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: _vm.handleSale
    }
  }, [_vm._v("销售")]), _c("el-button", {
    staticClass: "cust-button",
    attrs: {
      type: "danger",
      plain: ""
    },
    on: {
      click: _vm.handleCust
    }
  }, [_vm._v("客户")])], 1), _c("el-scrollbar", [_vm.custFlag ? _c("div", {
    staticStyle: {
      height: "136；margin-left:10px"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.custLoading,
      expression: "custLoading"
    }]
  }, _vm._l(_vm.custList, function (item, index) {
    return _c("div", {
      key: item.value,
      staticClass: "item-back"
    }, [_c("div", {
      staticClass: "item-style",
      on: {
        mousedown: function ($event) {
          return _vm.handleTypeName(index, item.id, item);
        }
      }
    }, [_c("div", {
      staticClass: "item-name"
    }, [_c("image-avatar", {
      attrs: {
        name: item.name,
        avatar: item.avatar,
        type: item.entity_type
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(_vm._s(item.name))])], 1), item.id === _vm.testValue ? _c("div", {
      staticClass: "el-icon-check"
    }) : _vm._e()])]);
  }), 0)]) : _vm._e(), _vm.saleFlag ? _c("div", {
    staticStyle: {
      height: "136px",
      "margin-left": "10px"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.saleLoading,
      expression: "saleLoading"
    }]
  }, _vm._l(_vm.saleList, function (item, index) {
    return _c("div", {
      key: item.value,
      staticClass: "item-back"
    }, [_c("div", {
      staticClass: "item-style",
      on: {
        mousedown: function ($event) {
          return _vm.handleTypeName(index, item.id, item);
        }
      }
    }, [_c("div", {
      staticClass: "item-name"
    }, [_c("image-avatar", {
      attrs: {
        name: item.name,
        avatar: item.avatar,
        type: item.entity_type
      }
    }), _c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "baseline",
        width: "88px",
        "justify-content": "space-between"
      }
    }, [_c("div", {
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v(_vm._s(item.name))]), item.is_follower ? _c("div", {
      staticStyle: {
        "font-size": "12px",
        color: "#c7ccdc"
      }
    }, [_vm._v(" " + _vm._s("跟进人") + " ")]) : _vm._e()])], 1), item.id === _vm.testValue ? _c("div", {
      staticClass: "el-icon-check"
    }) : _vm._e()])]);
  }), 0)]) : _vm._e()])], 1) : _vm._e()])], 1), !_vm.popVisiable && !_vm.guideVisiable && _vm.tranText !== "tranText" ? _c("div", {
    staticClass: "speak-man",
    attrs: {
      slot: "reference"
    },
    on: {
      click: _vm.mouseO,
      mouseover: _vm.mouseOver,
      mouseleave: _vm.mouseLeave
    },
    slot: "reference"
  }, [!_vm.speakName ? _c("span", [_vm._v(_vm._s(_vm.bindName))]) : _c("span", [_vm._v(_vm._s(_vm.speakName))]), _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.iconToken,
      expression: "iconToken"
    }],
    staticClass: "el-icon-edit",
    staticStyle: {
      "margin-left": "3px"
    }
  })]) : _vm._e(), (_vm.popVisiable || _vm.guideVisiable) && _vm.tranText !== "tranText" ? _c("div", {
    staticClass: "speak-man1",
    attrs: {
      slot: "reference"
    },
    on: {
      click: _vm.mouseO
    },
    slot: "reference"
  }, [!_vm.speakName ? _c("span", [_vm._v(_vm._s(_vm.bindName))]) : _c("span", [_vm._v(_vm._s(_vm.speakName))]), _c("i", {
    staticClass: "el-icon-edit"
  })]) : _vm._e(), (!_vm.popVisiable || !_vm.guideVisiable) && _vm.tranText == "tranText" ? _c("div", {
    staticClass: "speak-man-tran-left",
    class: {
      "speak-man-tran-right": _vm.peopleIndex + 1 == 2
    },
    attrs: {
      slot: "reference"
    },
    on: {
      click: _vm.mouseO,
      mouseover: _vm.mouseOver,
      mouseleave: _vm.mouseLeave
    },
    slot: "reference"
  }, [_c("span", [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.iconToken && _vm.channel == 0,
      expression: "iconToken && channel == 0"
    }],
    staticClass: "el-icon-edit"
  }), !_vm.speakName ? _c("span", [_vm._v(_vm._s(_vm.bindName))]) : _c("span", [_vm._v(_vm._s(_vm.speakName))]), _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.iconToken && _vm.channel == 1,
      expression: "iconToken && channel == 1"
    }],
    staticClass: "el-icon-edit"
  })])]) : _vm._e()]), _c("el-dialog", {
    attrs: {
      visible: _vm.indentityDialogVisible,
      width: "482px",
      height: "187px",
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.indentityDialogVisible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v("提示")]), _c("div", {
    staticClass: "indentity-body"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("设定身份")]), !_vm.uploadType ? _c("div", {
    staticStyle: {
      "margin-left": "20px",
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticClass: "indentity-man-style"
  }, [_vm._v("说话人1")]), _c("i", {
    staticClass: "el-icon-right",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _c("span", {
    class: _vm.name === "销售代表" ? "host-style" : "customer-style"
  }, [_vm._v(_vm._s(_vm.name))])]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticClass: "indentity-man-style"
  }, [_vm._v("说话人2")]), _c("i", {
    staticClass: "el-icon-right",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _c("span", {
    class: _vm.name === "客户" ? "host-style" : "customer-style"
  }, [_vm._v(_vm._s(_vm.name2))])])]) : _vm._e(), _c("div", {
    staticClass: "button-style"
  }, [_c("el-button", {
    staticClass: "cancel-style",
    on: {
      click: function ($event) {
        _vm.indentityDialogVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "enter-style",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleUp
    }
  }, [_vm._v("确定并重新分析")])], 1)])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;