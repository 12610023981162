"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SavedFilters = _interopRequireDefault(require("@/views/conversationPage/components/SavedFilters.vue"));
var _TeamScheduleFilter = _interopRequireDefault(require("./components/TeamScheduleFilter"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _KeyEventConfig = _interopRequireDefault(require("./components/KeyEventConfig"));
var _DailyCalendar = _interopRequireDefault(require("@/components/MgCalendar/DailyCalendar"));
var _MonthlyCalendar = _interopRequireDefault(require("@/components/MgCalendar/MonthlyCalendar"));
var _filterManageDrawer = _interopRequireDefault(require("@/views/conversationPage/filterManageDrawer.vue"));
var _RenameDialog = _interopRequireDefault(require("@/components/CommonComponents/RenameDialog.vue"));
var _InstructionSetDrawer = _interopRequireDefault(require("./components/InstructionSetDrawer.vue"));
var _InstructionResIconPopover = _interopRequireDefault(require("./components/InstructionResIconPopover.vue"));
var _AIAssistantChatDrawer = _interopRequireDefault(require("@/views/businessIntelligence/FAQ/components/AIAssistantChatDrawer.vue"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _calendar = require("@/api/calendar");
var _MgTag = _interopRequireDefault(require("@/components/MgTag"));
var _CopyUrl = _interopRequireDefault(require("@/components/CopyUrl"));
var _vuex = require("vuex");
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _dailyPreviewCard = _interopRequireDefault(require("@/components/Calendar/dailyPreviewCard"));
var _dailyDetailCard = _interopRequireDefault(require("@/components/Calendar/dailyDetailCard"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _variablesModule = _interopRequireDefault(require("@/styles/variables.module.scss"));
var _InviteSucceedDialog = _interopRequireDefault(require("@/components/InviteSucceedDialog"));
var _CreateInstructionDialog = _interopRequireDefault(require("./components/CreateInstructionDialog.vue"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _videoDetail = require("@/api/videoDetail");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MgCalendar',
  components: {
    ImageAvatar: _ImageAvatar.default,
    dayCalendar: _DailyCalendar.default,
    monthCalendar: _MonthlyCalendar.default,
    mgTag: _MgTag.default,
    CopyUrl: _CopyUrl.default,
    MeetingDialog: _MeetingDialog.default,
    dailyPreviewCard: _dailyPreviewCard.default,
    dailyDetailCard: _dailyDetailCard.default,
    InviteSucceedDialog: _InviteSucceedDialog.default,
    TeamScheduleFilter: _TeamScheduleFilter.default,
    KeyEventConfig: _KeyEventConfig.default,
    SpaceButton: _SpaceButton.default,
    SavedFilters: _SavedFilters.default,
    FilterManageDrawer: _filterManageDrawer.default,
    CommonRenameDialog: _RenameDialog.default,
    InstructionSetDrawer: _InstructionSetDrawer.default,
    InstructionResIconPopover: _InstructionResIconPopover.default,
    AIAssistantChatDrawer: _AIAssistantChatDrawer.default,
    CreateInstructionDialog: _CreateInstructionDialog.default
  },
  data() {
    return {
      visibleCalendar: 'day',
      teamSchedule: {},
      myBtnActive: true,
      titleDate: '',
      titleMonth: '',
      startDate: '',
      endDate: '',
      monthlyInfos: null,
      loading: false,
      meetingProps: {
        dialogVisible: false,
        title: '修改在线会议'
      },
      copyUrlProps: {
        visible: false
      },
      dailyDetailProps: {},
      activeDailyItem: null,
      dailyDetailOperationParams: {},
      // 详情卡片需要的参数集合
      cardDetailPopover: false,
      detailCardLoading: false,
      changeArrowDirection: false,
      membersOptions: [],
      teamPageVariable: {
        teamPageSize: 9,
        teamTotalUserNumber: 0,
        // 团队的总人数
        teamCurrentUserNumber: 0,
        // 团队当前已经加载的人数
        teamUserCurrentPage: 1 // 团队当前请求页数
      },

      inviteText: '',
      inviteSucceedDialogVisible: false,
      eventEngineList: [],
      memberFilter: {
        tree_ids: [],
        user_ids: []
      },
      calendarDetail: {},
      // 日程详情
      keyEventConfig: [],
      // 设置的需要外显的关键事件
      disabled: false,
      disabledKey: 'keyEventConfigPopover',
      saveFilterDialogVisible: false,
      filterManageVisible: false,
      inputInfo: {
        title: '保存为常用筛选',
        tips: '将当前设定条件保存为一个组合，保存成功后在顶端的“常用筛选”中选择可立即执行筛选',
        label: '常用筛选名称',
        errorText: '请输入常用筛选名称',
        placeholder: '请输入常用筛选名称',
        name: ''
      },
      callBackFilters: {},
      saveButtonDisabled: false,
      // 保存按钮的禁用状态
      isChooseSavedFilter: false,
      filters: {
        tree_ids: [],
        user_ids: [],
        conversation_type: '',
        duration: {
          unit: 'second',
          value: []
        },
        keyEventCascaderList: [{
          keyEventName: [],
          inverse: false
        }],
        event_engine_relation: 'and'
      },
      saveLoading: false,
      instructionSetVisible: false,
      chatDrawerVisible: false,
      chatPromptConfig: {
        guideList: ['平均通话时长是多少？', '销售在哪些方面表现不佳？', '客户常见的顾虑有哪些？']
      },
      activeUserInfo: null,
      createInstructionDialogVisible: false,
      instructionInfo: {
        content: ''
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'orgName', 'workspaceInfo', 'timezone', 'interfacePermission']),
    CSSVariables() {
      return _variablesModule.default;
    },
    needTeamLoadMore() {
      return this.teamPageVariable.teamCurrentUserNumber < this.teamPageVariable.teamTotalUserNumber;
    },
    saveDisabled() {
      return this.saveButtonDisabled || (0, _isEqual.default)(this.$options.data().filters, this.filters);
    },
    chatRequestConfig() {
      const infoParams = {
        space_id: this.workspaceInfo.id,
        user_id: this.activeUserInfo.id,
        schedule_date: this.titleDate
      };
      return {
        getQuestionParams: {
          entity_type: 2,
          params: {
            ...infoParams
          }
        },
        getHistoryParams: {
          entity_type: 2,
          ...infoParams
        },
        checkIsSupportParams: {
          ...infoParams,
          entity_type: 2
        }
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function () {
        this.filters = this.$options.data().filters;
        this.renewData();
        this.getKeyEventConfig();
        if (this.chatDrawerVisible) {
          this.closeChatDrawer();
        }
      }
    },
    titleDate() {
      // 切换日期的时候，如果ai助手是打开的，关闭ai助手
      if (this.chatDrawerVisible) {
        this.closeChatDrawer();
      }
    }
  },
  created() {
    const path = this.$route.path;
    if (path === _routeMap.ROUTES.calendarTeam) {
      this.visibleCalendar = 'day';
    } else {
      this.visibleCalendar = 'month';
    }
    this.$store.dispatch('business/get_member_options');
    this.titleDate = this.$moment().format('YYYY-MM-DD');
    this.titleMonth = this.$moment().format('YYYY-MM-DD');
    this.disabled = JSON.parse(localStorage.getItem(this.disabledKey));
    this.getKeyEventConfig();
  },
  beforeDestroy() {
    this.$bus.$off('setMembersList');
  },
  methods: {
    getScheduleFilter: _calendar.getScheduleFilter,
    deleteScheduleFilter: _calendar.deleteScheduleFilter,
    updateScheduleFilter: _calendar.updateScheduleFilter,
    getInstructionResult: _calendar.getInstructionResult,
    runInstruction: _calendar.runInstruction,
    savedFilterChange(val) {
      this.callBackFilters = val ? val.filter : this.$options.data().filters;
      // 当前选择了已保存的筛选器,保存按钮改为禁用状态
      this.isChooseSavedFilter = true;
      this.saveButtonDisabled = true;
    },
    debounceTeamScheduleFilterChange: (0, _commonFunc.debounce)(function (val) {
      this.filters = val;
      this.teamScheduleInit();
      this.getTeamSchedule();
      // 筛选条件变化后,保存按钮改为激活状态
      this.$nextTick(() => {
        if (this.isChooseSavedFilter) {
          this.isChooseSavedFilter = false;
        } else {
          this.saveButtonDisabled = false;
          this.$refs['savedFilters'].selectValue = '';
        }
      });
    }),
    async createFilter(_ref) {
      let {
        name
      } = _ref;
      this.saveLoading = true;
      const res = await (0, _calendar.createScheduleFilter)({
        workspace_id: this.workspaceInfo.id,
        name,
        ...this.filters
      }).finally(() => {
        this.saveLoading = false;
      });
      if (res.code === 20000) {
        this.saveFilterDialogVisible = false;
        this.$refs['savedFilters'].selectValue = name;
        this.saveButtonDisabled = true;
        this.$message.success('保存成功');
      }
    },
    saveAsFilter() {
      this.saveFilterDialogVisible = true;
    },
    openFilterManage() {
      this.filterManageVisible = true;
    },
    handleKeyEventVisible(visible) {
      const disabled = JSON.parse(localStorage.getItem(this.disabledKey));
      if (disabled) return;
      this.disabled = visible;
    },
    hideTips() {
      this.disabled = true;
      localStorage.setItem(this.disabledKey, true);
    },
    // 获取关键事件外显设置
    async getKeyEventConfig() {
      const res = await (0, _calendar.getKeyEventConfig)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.keyEventConfig = res.results.events;
      }
    },
    // 关键事件显示变化
    keyEventShowChange(val) {
      this.keyEventConfig = val;
      // 发接口
      this.debounceUpdateKeyEvent();
    },
    debounceUpdateKeyEvent: (0, _commonFunc.debounce)(async function () {
      const params = {
        events: this.keyEventConfig,
        workspace_id: this.workspaceInfo.id
      };
      const res = await (0, _calendar.updateKeyEventConfig)(params);
      if (res.code === 20000) {
        this.$message.success('设置成功');
      }
    }),
    // 获取会话的关键事件
    async getEventEngine() {
      const res = await (0, _videoDetail.getEventEngine)(this.activeDailyItem.conversation_id, this.workspaceInfo.id);
      if (res.code === 200) {
        this.eventEngineList = res.results;
      }
    },
    getConversationIcon(type) {
      switch (type) {
        case 'audio':
          return 'phone-outline2';
        case 'video':
          return 'video-camera';
        case 'doc':
          return 'im';
        default:
          return 'phone-outline2';
      }
    },
    // 部门成员筛选器变化
    memberChange(val) {
      this.teamPageVariable.teamUserCurrentPage = 1;
      this.memberFilter = val;
      this.teamScheduleInit();
      this.getTeamSchedule();
    },
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    getItemClass(status) {
      if (status === 'plan' || status === 'created') {
        return 'icon-color';
      }
      if (status === 'recording') {
        return 'background-color icon-color recording';
      }
      if (status === 'attend' || status === 'completed' || status === 'recorded') {
        return 'background-color icon-color';
      }
      if (status === 'expire') {
        return 'border-color';
      }
    },
    switchCalender(type) {
      this.visibleCalendar = type;
      if (type === 'day') {
        this.$router.push({
          path: _routeMap.ROUTES.calendarTeam
        });
      } else {
        this.$router.push({
          path: _routeMap.ROUTES.calendarMy
        });
      }
      // sessionStorage.setItem('calendarType', type);
    },

    getDay() {
      this.$refs['day-calendar'].date = this.$moment(this.titleDate).format('YYYY-MM-DD');
    },
    getMonth() {
      this.$refs['month-calendar'].month = this.$moment(this.titleMonth).format('YYYY-MM');
    },
    changeSuffix() {
      this.changeArrowDirection = true;
    },
    restoreSuffix() {
      this.changeArrowDirection = false;
    },
    switchDate(num) {
      if (this.visibleCalendar === 'day') {
        this.$refs['day-calendar'].switchDate(num);
      } else {
        this.$refs['month-calendar'].switchMonth(num);
      }
    },
    switchToday() {
      if (this.visibleCalendar === 'day') {
        this.$refs['day-calendar'].setNow();
      } else {
        this.$refs['month-calendar'].setNow();
      }
    },
    monthChange(val) {
      this.titleMonth = val.month;
      this.startDate = val.startDate;
      this.endDate = val.endDate;
      this.getMySchedule();
    },
    dateChange(val) {
      this.titleDate = val;
      this.teamScheduleInit();
      this.getTeamSchedule();
    },
    async getMySchedule() {
      try {
        this.loading = true;
        const res = await (0, _calendar.getMySchedule)({
          date_start: this.startDate,
          date_end: this.endDate,
          time_zone: this.timezone,
          workspace_id: this.workspaceInfo.id
        });
        this.monthlyInfos = res.results.detail_infos;
      } finally {
        this.loading = false;
      }
    },
    getTeamPageSize() {
      // 获取滚动区域的宽度 = 窗口的宽度 - 左侧导航栏的宽度 - 左侧时间栏的宽度
      const scrollContainerWidth = window.innerWidth - parseInt(this.CSSVariables.sideBarWidth) - 48;
      const maxItem = Math.ceil(scrollContainerWidth / 300);
      if (maxItem > 6) {
        // 只有当最大展示个数大于6个之后再重新计算pagesize
        this.teamPageVariable.teamPageSize = Math.ceil(maxItem * 1.5);
      }
    },
    teamScheduleInit() {
      this.teamPageVariable = this.$options.data().teamPageVariable;
      this.membersOptions = [];
      this.teamSchedule = {};
      this.$refs['day-calendar'].distance = 0; // 重置子组件的滚动
    },

    async getTeamSchedule() {
      try {
        this.loading = true;
        this.getTeamPageSize();
        const params = {
          date: this.titleDate,
          size: this.teamPageVariable.teamPageSize,
          page: this.teamPageVariable.teamUserCurrentPage,
          tree_ids: this.memberFilter.tree_ids,
          user_ids: this.memberFilter.user_ids,
          time_zone: this.timezone,
          workspace_id: this.workspaceInfo.id,
          ...this.filters
        };
        const res = await (0, _calendar.getTeamSchedule)(params);
        const {
          data,
          total
        } = res.results;
        this.getMembersOptions(data);
        this.teamSchedule = {
          ...this.teamSchedule,
          ...data
        };
        this.teamPageVariable.teamTotalUserNumber = total;
        this.teamPageVariable.teamCurrentUserNumber += Object.keys(data).length;
        this.teamPageVariable.teamUserCurrentPage++;
        this.$nextTick(() => {
          var _this$$refs$dayCalen;
          return (_this$$refs$dayCalen = this.$refs['day-calendar']) === null || _this$$refs$dayCalen === void 0 ? void 0 : _this$$refs$dayCalen.setScrollTop();
        });
      } finally {
        this.loading = false;
      }
    },
    getMembersOptions(obj) {
      Object.values(obj).map(item => this.membersOptions.push(item.user_info));
      if (this.membersOptions.length < 3) {
        // 当不够三个人时，需要补全到三列
        const diff = 3 - this.membersOptions.length;
        for (let i = 0; i < diff; i++) {
          this.membersOptions.push({
            id: 0,
            name: '',
            avatar: ''
          });
        }
      }
    },
    renewData() {
      if (this.visibleCalendar === 'month') {
        this.getMySchedule();
      } else {
        this.teamScheduleInit();
        this.getTeamSchedule();
      }
    },
    meetingCount(column) {
      if (Object.prototype.hasOwnProperty.call(this.teamSchedule, column.id) && !(0, _isEmpty.default)(this.teamSchedule[column.id].detail_infos)) {
        const infos = Object.values(this.teamSchedule[column.id].detail_infos)[0];
        const total = (infos === null || infos === void 0 ? void 0 : infos.length) || 0;
        // 根据conversation_type判断不同类型的会议数
        const audio = infos.filter(info => info.conversation_type === 'audio').length;
        const video = infos.filter(info => info.conversation_type === 'video').length;
        const doc = infos.filter(info => info.conversation_type === 'doc').length;
        return {
          total,
          audio,
          video,
          doc
        };
      } else {
        return 0;
      }
    },
    handleDailyContent(hour, column) {
      const data = this.teamSchedule;
      const infos = [];
      for (const key in data) {
        // eslint-disable-next-line eqeqeq
        if (column.id == key && !(0, _isEmpty.default)(data[key].detail_infos)) {
          for (const info of Object.values(data[key].detail_infos)[0]) {
            if (this.$moment(info.conversation_date).hour() === hour.getHour) {
              infos.push(info);
            }
          }
        }
      }
      return infos;
    },
    handleContent(date) {
      if (!this.monthlyInfos) return {};
      for (const key in this.monthlyInfos) {
        if (key === date) {
          return this.monthlyInfos[key].filter(item => {
            // if (item.details !== null) {
            //   return item.details.type !== "lead";
            // }
            return item;
          });
        }
      }
    },
    async openDailyItemDetail(item, ref) {
      try {
        this.detailCardLoading = true;
        this.activeDailyItem = item;
        this.dailyDetailProps = {};
        await this.getEventEngine();
        await this.getDailyItemDetail(item, ref);
      } finally {
        this.detailCardLoading = false;
      }
    },
    async getDailyItemDetail(item, ref) {
      this.detailCardLoading = true;
      try {
        const res = await (0, _calendar.getScheduleDetail)({
          conversation_id: item.conversation_id
        });
        this.calendarDetail = {
          ...res.results
        };
        this.$nextTick(() => {
          var _this$$refs$ref;
          (_this$$refs$ref = this.$refs[ref]) === null || _this$$refs$ref === void 0 ? void 0 : _this$$refs$ref[0].updatePopper(); // 重新计算Popover位置的
        });
      } finally {
        this.detailCardLoading = false;
      }
    },
    openInstructionSetDrawer() {
      this.instructionSetVisible = true;
    },
    clickFixedElement() {
      document.body.click();
    },
    openChatDrawer(column) {
      const date = this.$moment(this.titleDate).format('M月D日');
      this.chatPromptConfig.helloText = `嗨~我是AI助手麦麦，针对${column.name}在${date}的${column.conv_summary_count ? column.conv_summary_count + '份' : ''}会话纪要内容，你可以问我:`;
      this.chatDrawerVisible = true;
      this.activeUserInfo = column;
    },
    closeChatDrawer() {
      this.chatDrawerVisible = false;
      this.activeUserInfo = null;
    },
    chatImport(info) {
      console.log('chatImport', info);
      this.createInstructionDialogVisible = true;
      this.instructionInfo = {
        content: info.msg
      };
    }
  }
};
exports.default = _default;