"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScoreTips = _interopRequireDefault(require("./ScoreTips"));
var _ScoreCorrectionKnowledgeList = _interopRequireDefault(require("./ScoreCorrectionKnowledgeList"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeScoreCorrectionAside',
  components: {
    ScoreTips: _ScoreTips.default,
    ScoreCorrectionKnowledgeList: _ScoreCorrectionKnowledgeList.default
  },
  props: {
    allKnowledgeList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    activeTabsChange(key) {
      this.$emit('activeTabsChange', key);
    }
  }
};
exports.default = _default;