"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _angleTag = _interopRequireDefault(require("./angleTag"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: "ProgressTags",
  components: {
    AngleTag: _angleTag.default
  },
  props: {
    progressObj: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      winDialogVisible: false,
      isSetPreDate: true,
      isOverflow: false
    };
  },
  watch: {
    "progressObj.progressArray.length": {
      handler() {
        this.$nextTick(() => {
          this.isOverflow = this.$el.offsetWidth > 900 ? true : false;
        });
      },
      immediate: true
    }
  },
  computed: {
    getTagType() {
      return function (index) {
        if (index === 0) {
          return "first";
        } else if (index === this.progressObj.progressArray.length - 1) {
          return "last";
        } else {
          return "center";
        }
      };
    },
    getTagColorObj() {
      return function (item, index) {
        let hasClosedStatus = ["win", "loss", "invalidate"];
        if (!hasClosedStatus.includes(this.progressObj.type)) {
          //如果是在进行中
          if (item.date) {
            //如果有日期设定，没有日期是默认的颜色
            if (item.name === this.progressObj.status) {
              //如果是当前阶段
              return {
                backgroudColor: "#00B8B0",
                borderColor: "transparent",
                fontColor: "#fff"
              };
            } else {
              return {
                backgroudColor: "#EBF9F8",
                borderColor: "#EBF9F8",
                fontColor: "#00B8B0"
              };
            }
          } else {
            return {
              backgroudColor: "#fff",
              borderColor: "#EBEEF5",
              fontColor: "#8E95B0"
            };
          }
        } else if (this.progressObj.type === "win") {
          if (index === this.progressObj.progressArray.length - 1) {
            //最后一个
            return {
              backgroudColor: "#00B8B0",
              borderColor: "transparent",
              fontColor: "#fff"
            };
          } else {
            return {
              backgroudColor: "#EBF9F8",
              borderColor: "#EBF9F8",
              fontColor: "#00B8B0"
            };
          }
        } else if (this.progressObj.type === "loss") {
          if (index === this.progressObj.progressArray.length - 1) {
            //最后一个
            return {
              backgroudColor: "#8E95B0",
              borderColor: "transparent",
              fontColor: "#fff"
            };
          } else {
            return {
              backgroudColor: "#F6F6F9",
              borderColor: "#F6F6F9",
              fontColor: "#8E95B0"
            };
          }
        } else if (this.progressObj.type === "invalidate") {
          if (index === this.progressObj.progressArray.length - 1) {
            //最后一个
            return {
              backgroudColor: "#C7CCDC",
              borderColor: "transparent",
              fontColor: "#fff"
            };
          } else {
            return {
              backgroudColor: "#F6F6F9",
              borderColor: "#F6F6F9",
              fontColor: "#C7CCDC"
            };
          }
        } else {
          if (index === this.progressObj.progressArray.length - 1) {
            //最后一个
            return {
              backgroudColor: "#8E95B0",
              borderColor: "transparent",
              fontColor: "#fff"
            };
          } else {
            return {
              backgroudColor: "#F6F6F9",
              borderColor: "#F6F6F9",
              fontColor: "#8E95B0"
            };
          }
        }
      };
    }
  },
  methods: {}
};
exports.default = _default;