"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  name: 'CourseCatalog',
  props: {
    courseTypes: {
      type: Array,
      default: () => []
    },
    catalogData: {
      type: Array,
      default: () => []
    },
    currentNodeId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      defaultExpandedKeys: []
    };
  },
  computed: {
    getFolderDropdownConfigs() {
      return [{
        icon: 'el-icon-plus',
        options: [[{
          type: 'createCatalog',
          label: this.$t('coaching.newHasVar', [this.$t('coaching.category')]),
          icon: 'icon-folder-add'
        }], this.courseTypes.map(item => {
          return {
            ...item,
            label: `${this.$t('coaching.newHasVar', [this.$t(item.label, ['(Alpha)'])])}`
          };
        })]
      }, {
        icon: 'el-icon-more',
        options: [[{
          type: 'renameCatalog',
          label: this.$t('coaching.rename'),
          icon: 'icon-edit-outline'
        }, {
          type: 'editPublicScope',
          label: this.$t('coaching.editPublicScope'),
          icon: 'icon-unlock'
        }, {
          type: 'deleteCatalog',
          label: this.$t('coaching.delete'),
          icon: 'icon-delete'
        }]]
      }];
    }
  },
  watch: {
    currentNodeId(val) {
      val && this.handleNodeExpand(val);
      this.$nextTick(() => {
        this.handleCurrentNodeChange(val);
      });
    }
  },
  created() {
    this.defaultExpandedKeys = JSON.parse(sessionStorage.getItem('courseCatalogExpended') || '[]');
  },
  beforeDestroy() {
    sessionStorage.setItem('courseCatalogExpended', JSON.stringify(this.defaultExpandedKeys));
  },
  methods: {
    getIcon(data, node) {
      if (data.is_public) {
        return node.expanded ? 'icon-folder-opened-fill' : 'icon-folder-fill';
      } else {
        return node.expanded ? 'icon-folder-opened-lock-fill' : 'icon-folder-lock-fill';
      }
    },
    // 拖拽排序
    async handleDrop(draggingNode, dropNode, dropType) {
      var _nodeList;
      const dropFid = dropType === 'inner' ? dropNode.data.id : dropNode.parent.data.id || 0;
      const nodeList = dropType === 'inner' ? dropNode.data.children : dropNode.parent.level ? dropNode.parent.data.children : dropNode.parent.data;
      const currentIndex = nodeList.findIndex(item => item.id === draggingNode.data.id);
      await (0, _coachingCenter.dragCourseCategory)({
        sid: draggingNode.data.id,
        tid: dropFid,
        before_tid: (_nodeList = nodeList[currentIndex + 1]) === null || _nodeList === void 0 ? void 0 : _nodeList.id
      }).finally(() => {
        this.$emit('refreshTree');
      });
      if (dropType === 'inner') this.handleNodeExpand(dropFid);
    },
    allowDrop(draggingNode, dropNode, dropType) {
      return dropNode.data.id;
    },
    allowDrag(draggingNode) {
      return draggingNode.data.id;
    },
    updateExpandedNodeKey(node, isExpanded) {
      if (node) {
        node.expanded = isExpanded;
      }
      this.defaultExpandedKeys = [];
      Object.values(this.$refs['tree'].store.nodesMap).forEach(item => {
        if (item.expanded) {
          this.defaultExpandedKeys.push(item.data.id);
        }
      });
    },
    handleNodeExpand(id) {
      this.openAllParent(id);
      this.updateExpandedNodeKey();
    },
    handleCommand(data, type) {
      if (['createCatalog', 'renameCatalog', 'deleteCatalog', 'editPublicScope'].includes(type)) {
        this.$emit(type, data);
      } else {
        this.$emit('createCourse', type, data.id);
      }
    },
    openAllParent(id) {
      const node = this.$refs.tree.getNode(id);
      recursionNode(node);
      function recursionNode(node) {
        if (node) {
          node.expanded = true;
          if (node.parent) {
            node.parent.expanded = true;
            recursionNode(node.parent);
          }
        }
      }
    },
    handleCurrentNodeChange(id) {
      if (id) {
        this.openAllParent(id);
        const node = this.$refs.tree.getNode(id);
        if (!node) {
          this.handleNodeClick(this.catalogData[0]);
        }
      }
      this.$refs.tree.setCurrentKey(id);
    },
    handleNodeClick(data, node) {
      this.$emit('nodeClick', data);
      if (node) {
        node.expanded = true;
      }
    }
  }
};
exports.default = _default;