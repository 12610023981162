"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", {
    staticClass: "guide-dialog"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "guide-title"
  }, [_vm._t("title")], 2), _c("div", {
    staticClass: "content"
  }, [_vm._t("content", function () {
    return [_c("span", [_vm._v("已忽略的问题在这里进行管理")])];
  })], 2), _c("div", {
    staticClass: "operate"
  }, [_vm._t("operate", function () {
    return [_c("el-button", {
      staticClass: "known-button",
      attrs: {
        slot: "operate"
      },
      on: {
        click: function ($event) {
          return _vm.$emit("handleClick");
        }
      },
      slot: "operate"
    }, [_vm._v("我知道了 ")])];
  })], 2)])]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;