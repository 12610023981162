"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tooltip", {
    attrs: {
      "popper-class": "full-screen-tooltip",
      effect: "dark",
      content: _vm.isFullscreen ? "退出全屏" : "全屏",
      placement: "top"
    }
  }, [_c("el-button", {
    staticClass: "full-screen-btn",
    attrs: {
      icon: _vm.isFullscreen ? "iconfont icon-restore-screen" : "el-icon-full-screen"
    },
    on: {
      click: function ($event) {
        return _vm.handleFullScreen();
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;