"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preventReClick = exports.debounce = void 0;
var _vue = _interopRequireDefault(require("vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const preventReClick = _vue.default.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  }
});
exports.preventReClick = preventReClick;
const debounce = _vue.default.directive('debounce', {
  inserted: function (el, binding) {
    let [fn, event = "click", time = 300] = binding.value;
    let timer;
    let count = 0;
    el.addEventListener(event, () => {
      if (!count) {
        fn();
        count++;
      } else {
        timer && clearTimeout(timer);
        timer = setTimeout(() => fn(), time);
      }
    });
  }
});
exports.debounce = debounce;