"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ContrastGrowthOrDecrease',
  props: {
    configs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeCategory: 'department',
      categoryList: [{
        label: '本部门',
        value: 'department'
      }, {
        label: '本公司',
        value: 'organization'
      }]
    };
  },
  computed: {
    getActiveLabel() {
      return this.categoryList.find(_ref => {
        let {
          value
        } = _ref;
        return value === this.activeCategory;
      }).label;
    },
    getData() {
      return type => {
        return this.configs.filter(item => item[`is_higher_${this.activeCategory}`] === type).map(_ref2 => {
          let {
            name
          } = _ref2;
          return name;
        });
      };
    }
  },
  methods: {
    toggleTeam(type) {
      this.activeCategory = type;
    }
  }
};
exports.default = _default;