"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    class: {
      "hidden-dialog": _vm.createQuestionDialogVisible
    },
    attrs: {
      visible: _vm.visible,
      title: `${_vm.operateName}至其他问题`,
      "before-close": _vm.handleClose,
      width: "600px",
      "custom-class": "move-freq-question-dialog"
    }
  }, [_c("header", [_c("el-input", {
    attrs: {
      clearable: "",
      "prefix-icon": "iconfont icon-search",
      placeholder: "搜索问题"
    },
    on: {
      input: _vm.debounceSearchQuestionList
    },
    model: {
      value: _vm.qaFilters.filter,
      callback: function ($$v) {
        _vm.$set(_vm.qaFilters, "filter", $$v);
      },
      expression: "qaFilters.filter"
    }
  })], 1), _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading && _vm.qaFilters.page === 1,
      expression: "loading && qaFilters.page === 1"
    }]
  }, [_c("mgv-infinite-scroll", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.questionList.length,
      expression: "questionList.length"
    }],
    attrs: {
      loading: _vm.loading,
      "has-more": _vm.hasMore
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, _vm._l(_vm.questionList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "question-item"
    }, [_c("div", {
      class: ["order", item.rank < 100 ? `order-${item.rank}` : "no-background-color"]
    }, [_vm._v(" " + _vm._s(item.rank) + " ")]), _c("span", {
      staticClass: "content"
    }, [_vm._v(_vm._s(item.name))]), item.id === _vm.currentQuestion.id ? _c("el-tag", {
      attrs: {
        type: "info"
      }
    }, [_vm._v("当前问题")]) : _vm._e(), item.is_omit ? _c("el-tag", {
      attrs: {
        type: "info"
      }
    }, [_vm._v("已忽略")]) : _vm._e(), _c("el-button", {
      class: ["move-btn", {
        "disabled-btn": item.id === _vm.currentQuestion.id
      }],
      attrs: {
        disabled: item.id === _vm.currentQuestion.id,
        size: "medium"
      },
      on: {
        click: function ($event) {
          return _vm.moveFreqQuestion(item.id);
        }
      }
    }, [_vm._v(_vm._s(_vm.operateName))])], 1);
  }), 0), _vm.qaFilters.filter && !_vm.questionList.length && !_vm.loading ? _c("NoData", {
    attrs: {
      size: "120",
      icon: "icon-a-Property1not-found"
    }
  }, [_c("div", {
    staticClass: "search-container",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_c("span", [_vm._v("未搜索到“")]), _c("span", {
    staticClass: "search-value"
  }, [_vm._v(_vm._s(_vm.qaFilters.filter))]), _c("span", [_vm._v("”相关结果")])])]) : _vm._e()], 1), _vm.hasFooterOperate ? _vm._t("footer", function () {
    return [_c("el-button", {
      staticClass: "add-btn",
      attrs: {
        slot: "footer"
      },
      on: {
        click: _vm.createNewQuestion
      },
      slot: "footer"
    }, [_vm._v("移动至新问题")]), _c("CreateQuestionDialog", {
      ref: "createQuestionDialog",
      attrs: {
        "create-and-move": "",
        visible: _vm.createQuestionDialogVisible,
        "current-qa-item": _vm.currentQaItem
      },
      on: {
        "update:visible": function ($event) {
          _vm.createQuestionDialogVisible = $event;
        },
        createAndMoveFreqQuestion: _vm.createAndMoveFreqQuestion
      }
    })];
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;