"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MeetingItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    meetingUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    enterMeeting() {
      open(this.meetingUrl, '_blank');
    }
  }
};
exports.default = _default;