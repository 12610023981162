"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _showFiltersDetail = _interopRequireDefault(require("./showFiltersDetail"));
var _CardText = _interopRequireDefault(require("@/components/Charts/CardText"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _fullScreenBtn = _interopRequireDefault(require("@/components/FullScreen/fullScreenBtn"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _business = require("@/api/business");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CardContainer',
  components: {
    ShowFiltersDetail: _showFiltersDetail.default,
    CardText: _CardText.default,
    ChartTemplate: _ChartTemplate.default,
    MgDraggable: _MgDraggable.default,
    fullScreenBtn: _fullScreenBtn.default
  },
  props: {
    cardList: {
      type: Array,
      default: () => []
    },
    isSubscribe: {
      type: Boolean,
      default: false
    },
    isAssign: {
      type: Boolean,
      default: false
    },
    isShowCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCardList: [],
      customFilterArray: [],
      checkList: [],
      dimensionOptions: Object.freeze([{
        label: '小时',
        value: 'hour'
      }, {
        label: '日',
        value: 'day' // 默认值
      }, {
        label: '周',
        value: 'week'
      }, {
        label: '月',
        value: 'month'
      }])
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    getSelectValue() {
      return function (index) {
        var _this$dimensionOption;
        return (_this$dimensionOption = this.dimensionOptions.find(_ref => {
          let {
            value
          } = _ref;
          return value === this.cardList[index].configs.dimension;
        })) === null || _this$dimensionOption === void 0 ? void 0 : _this$dimensionOption.label;
      };
    },
    isCheckedCardItem() {
      return function (card_id) {
        return this.checkList.includes(card_id);
      };
    }
  },
  watch: {
    cardList: {
      handler: function (val) {
        this.localCardList = val;
      },
      immediate: true
    }
  },
  created() {
    this.getDealCustomFilter();
  },
  methods: {
    checkedCard(card_id) {
      if (!this.isShowCheckbox) return;
      const index = this.checkList.findIndex(item => item === card_id);
      if (index === -1) {
        this.checkList.push(card_id);
      } else {
        this.checkList.splice(index, 1);
      }
      this.$emit('checkedChange', this.checkList);
    },
    async dragEnd(_ref2) {
      let {
        newIndex
      } = _ref2;
      await (0, _businessIntelligence.moveBiDataOverviewCard)({
        card_id: this.localCardList[newIndex].card_id,
        workspace_id: this.workspaceInfo.id,
        order: newIndex + 1
      });
      this.$emit('dragEnd', this.localCardList);
    },
    // 获取自定义筛选器的信息==给筛选器回显使用
    async getDealCustomFilter() {
      const res = await (0, _business.getDealCustomFilter)({
        access_type: this.$route.path,
        workspace_id: this.workspaceInfo.id
      });
      this.customFilterArray = res.results;
    }
  }
};
exports.default = _default;