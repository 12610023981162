"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'RevertMergenceDialog',
  props: {
    revertDialogVisible: {
      type: Boolean,
      default: false
    },
    revertMergenceInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    confirmToCancelMergence(revertMergenceInfo) {
      this.$emit('confirmToCancelMergence', revertMergenceInfo.external_user_id);
    },
    closeCancelMergenceDialog() {
      this.$emit('closeCancelMergenceDialog');
    },
    handleClose() {
      this.closeCancelMergenceDialog();
    }
  }
};
exports.default = _default;