"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _speechBubble = _interopRequireDefault(require("@/components/BusinessTable/speechBubble"));
var _explainCard = _interopRequireDefault(require("./explainCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    speechBubble: _speechBubble.default,
    explainCard: _explainCard.default
  },
  props: {
    // total: {
    //   type: [Number, String],
    //   default: 0,
    // },
    // amount: {
    //   type: [Number, String],
    //   default: 0,
    // },
  }
};
exports.default = _default;