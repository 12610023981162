"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BarChart',
  components: {
    ChartTemplate: _ChartTemplate.default
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    chartLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chartOption() {
      var _this$data$list;
      return {
        grid: {
          containLabel: true,
          top: 0,
          bottom: 20,
          left: 0,
          right: 20
        },
        color: '#3393FF',
        tooltip: {
          confine: true,
          appendToBody: true,
          formatter: _ref => {
            let {
              value: [name, val]
            } = _ref;
            return `<p style="color: #2B323B;font-weight: 600;">${name}<br>${val}${this.data.type === 'percent' ? '%' : ''}</p>`;
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#576575',
            lineHeight: 16,
            formatter: `{value}${this.data.type === 'percent' ? '%' : ''}`
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#E2E5E9'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#576575',
            formatter: value => value.length > 10 ? value.slice(0, 10) + '…' : value
          },
          axisLine: {
            lineStyle: {
              color: '#E2E5E9'
            }
          }
        },
        dataset: {
          sourceHeader: false,
          source: ((_this$data$list = this.data.list) === null || _this$data$list === void 0 ? void 0 : _this$data$list.map(_ref2 => {
            let {
              name,
              value
            } = _ref2;
            return [name, value];
          }).reverse()) || []
        },
        series: {
          type: 'bar',
          barMinWidth: 20,
          encode: {
            x: 1,
            y: 0
          }
        }
      };
    }
  }
};
exports.default = _default;