"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: `符合筛选条件的交易${_vm.count}条${_vm.count > _vm.dataPermissionCount ? "，数据权限范围内的交易" + _vm.dataPermissionCount + "条" : ""}`,
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "80%",
      "custom-class": "create-trade-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "main-content",
    class: {
      "only-trade-list": _vm.onlyTradeList
    }
  }, [_c("div", {
    staticClass: "trade-page"
  }, [!_vm.onlyTradeList ? _c("div", {
    staticClass: "top-tips"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  }), _vm._v(" " + _vm._s(_vm.tipContent) + " ")]) : _vm._e(), _vm.drawerVisible ? _c("TradeDrawer", {
    attrs: {
      "table-height-offset": 120,
      "back-fill-filters": _vm.backFillFilters,
      "only-trade-list": _vm.onlyTradeList,
      "from-score-rule": _vm.fromScoreRule
    },
    on: {
      tradeFiltersChange: _vm.tradeFiltersChange,
      getCount: function ($event) {
        _vm.dataPermissionCount = $event;
      }
    }
  }) : _vm._e()], 1)]), !_vm.onlyTradeList ? _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    staticClass: "mg-button mg-round-button",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    class: ["mg-button", "mg-round-button", {
      "submit-disabled": !_vm.filterNum
    }],
    attrs: {
      disabled: !_vm.filterNum,
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("保存")])], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;