"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ComplianceKanban',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String,
      default: ''
    },
    tabList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeName: ''
    };
  },
  watch: {
    activeName: {
      handler: function (val) {
        this.$router.replace({
          query: {
            tab: val
          }
        });
      }
    }
  },
  created() {
    this.activeName = this.$route.query.tab || this.activeTab;
  },
  methods: {
    handleTabClick(_ref) {
      let {
        name
      } = _ref;
      this.$emit('handleTabClick', name);
    }
  }
};
exports.default = _default;