"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "key-event-folder-list-view"
  }, _vm._l(_vm.folderList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "key-event-folder",
      on: {
        click: function ($event) {
          return _setup.onFolderClick(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-folder-fill"
    }), _c(_setup.MgAutoTooltip, {
      attrs: {
        content: item.name
      }
    })], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;