"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "customer-field-history-value"
  }, [["email", "tel", "text", "textarea"].includes(_vm.row.field_type) ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue || "-") + " ")]) : ["select", "checkbox", "radio"].includes(_vm.row.field_type) ? _c("div", {
    staticClass: "multi-select"
  }, [_vm.fieldValue && _vm.fieldValue.length ? _vm._l(_vm.fieldValue, function (item, index) {
    return _c("span", {
      key: item + index,
      staticClass: "multi-select-item"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }) : _c("div", [_vm._v("-")])], 2) : ["time", "datetime"].includes(_vm.row.field_type) ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue ? _vm.$moment(_vm.fieldValue).format("YYYY-MM-DD HH:mm:ss") : "-") + " ")]) : _vm.row.field_type === "date" ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue ? _vm.$moment(_vm.fieldValue).format("YYYY-MM-DD") : "-") + " ")]) : _vm.row.field_type === "money" ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue ? `¥${new Intl.NumberFormat().format(_vm.fieldValue - 0)}` : "-") + " ")]) : _vm.row.field_type === "floatnumber" ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue ? _setup.parseFormatNum(_vm.fieldValue - 0, 2) : "-") + " ")]) : _vm.row.field_type === "integer" ? _c("div", [_vm._v(" " + _vm._s(_vm.fieldValue ? _setup.parseFormatNum(_vm.fieldValue - 0) : "-") + " ")]) : _vm._e(), _vm._t(_vm.row.field_type)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;