"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timeFormatter = require("@/utils/time-formatter");
var _default = {
  name: 'SeiriNotes',
  props: {
    notes: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      activeId: null
    };
  },
  computed: {
    deleteTipVisible: {
      get() {
        return function (id) {
          return this.activeId === id;
        };
      },
      set(val) {
        return val;
      }
    }
  },
  watch: {},
  methods: {
    secondToTime(second) {
      return (0, _timeFormatter.secondToTime)(second);
    },
    getHeightLight(item) {
      const _type = item.mark_type;
      const pattern = /(#[a-zA-Z0-9\u4e00-\u9fa5]+)/g;
      if (_type === 'none') {
        // 用户自己输入的note
        let _content = item.content;
        _content = _content.replace(pattern, '<span style="font-weight: 600;color: #4461EC">$1</span>');
        return _content;
      } else if (_type === 'normal') {
        return `<i class="iconfont icon-mark3"></i>标记一下`;
      } else if (_type === 'doubt') {
        return `<i class="iconfont icon-doubt2"></i>这里有点疑问`;
      } else if (_type === 'great') {
        return `<i class="iconfont icon-great-2"></i>这个回答不错哦`;
      }
    },
    deleteNote(id) {
      this.$emit('deleteNote', id);
    },
    closePop() {
      this.activeId = null;
    },
    changeActiveId(id) {
      this.activeId = id;
    },
    editContent(note, index) {
      if (note.mark_type === 'none') {
        this.changeEditStatus(note, index, true);
        this.changeActiveId(note.id);
        // this.$refs["contItem"+index].focus()
      } else {
        return false;
      }
    },
    changeEditStatus(note, index, status) {
      note.canEdit = status;
      const myArray = this.notes;
      myArray[index] = note;
      this.$emit('update:notes', myArray);
    },
    contentChange(item, e, index) {
      this.activeId = null;
      const text = e.target.innerText;
      const parmas = {
        note_id: item.id,
        content: text,
        mark_type: item.mark_type
      };
      this.$emit('updateNote', parmas);
    }
  }
};
exports.default = _default;