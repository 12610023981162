"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "annotate-text"
  }, [_c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.audio && _setup.audio.length > 0,
      expression: "audio && audio.length > 0"
    }],
    ref: "contentWrapperRef",
    staticClass: "annotate-sentence-wrapper",
    on: {
      mousedown: function ($event) {
        return _setup.handleMouseScroll($event, "annotate-sentence-wrapper");
      },
      mousewheel: _setup.handleMouseWheel,
      scroll: _setup.handleScroll
    }
  }, _vm._l(_setup.sliceSentence, function (item) {
    return _c("li", {
      key: item.order,
      class: {
        "is-sales": _setup.isSales(item),
        "is-show-avatar": item.isShowAvatar,
        "selected-sentence-of-hit-key-event": _setup.selectedSentenceOrderArr.includes(item.order)
      },
      attrs: {
        id: "sentence-item" + item.order
      }
    }, [_c("div", {
      staticClass: "content-box"
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.isShowAvatar,
        expression: "item.isShowAvatar"
      }],
      staticClass: "label"
    }, [_c("div", {
      class: ["name", {
        reverse: _setup.isSales(item)
      }]
    }, [_c(_setup.ImageAvatar, {
      attrs: {
        avatar: item.avatar,
        name: item.entity_type === "host_salesman" ? "销售" : "客户",
        type: item.entity_type
      }
    }), _c("span", {
      staticClass: "name-text"
    }, [_vm._v(_vm._s(item.entity_type === "host_salesman" ? "销售" : "客户"))]), _vm.activeDataSetData.type == "doc" ? _c("span", {
      staticClass: "text-time"
    }, [_vm._v(_vm._s(_vm.$moment(item.begin_time * 1000).format("YYYY-MM-DD HH:mm")))]) : _vm._e()], 1)]), _c("div", {
      staticClass: "content-row"
    }, [_c("el-checkbox-group", {
      on: {
        input: _setup.onSentenceOrderChange
      },
      model: {
        value: _setup.selectedSentenceOrderArr,
        callback: function ($$v) {
          _setup.selectedSentenceOrderArr = $$v;
        },
        expression: "selectedSentenceOrderArr"
      }
    }, [_c("el-checkbox", {
      staticClass: "hit-key-event-check-box",
      attrs: {
        label: item.order
      }
    })], 1), _c("div", {
      ref: _setup.setContWrapRefs,
      refInFor: true,
      class: ["cont-wrap", {
        reverse: _setup.isSales(item)
      }]
    }, [_c("div", {
      class: ["content", {
        "is-audio": item.msg_type
      }, {
        active: item.order === _setup.curorder.value && _setup.curorder.time !== "click"
      }],
      attrs: {
        "data-v-step": "annotateTextCheckBox",
        "data-sign": item.order
      }
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.sentenceOfEventTagMap[item.order] && _vm.sentenceOfEventTagMap[item.order].length,
        expression: "\n                  sentenceOfEventTagMap[item.order] &&\n                  sentenceOfEventTagMap[item.order].length\n                "
      }],
      staticClass: "key-event-tag-list"
    }, _vm._l(_vm.sentenceOfEventTagMap[item.order], function (keyEventTag) {
      var _vm$dataSetLabelList$;
      return _c("div", {
        key: keyEventTag.annotation_id,
        staticClass: "key-event-tag-item"
      }, [_c("span", [_vm._v(_vm._s((_vm$dataSetLabelList$ = _vm.dataSetLabelList.find(item => item.id === keyEventTag.label_id)) === null || _vm$dataSetLabelList$ === void 0 ? void 0 : _vm$dataSetLabelList$.name))]), _c("el-popconfirm", {
        attrs: {
          "confirm-button-text": "删除",
          "cancel-button-text": "取消",
          icon: "iconfont icon-warning",
          title: "确定删除此关键事件命中文本吗?",
          "popper-class": "key-event-detail-list-popper"
        },
        on: {
          onConfirm: function ($event) {
            return _setup.deleteHitKeyEventTracker(keyEventTag);
          }
        }
      }, [_c("i", {
        staticClass: "iconfont icon-close-small",
        attrs: {
          slot: "reference"
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
          }
        },
        slot: "reference"
      })])], 1);
    }), 0), _c("div", {
      staticClass: "content-sentence"
    }, [item.msg_type === "voice" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_c("div", {
      staticClass: "voice"
    }, [item.src ? _c("div", {
      staticClass: "voice-box"
    }, [_c("span", [_vm._v(_vm._s(Math.ceil(item.duration)) + '"')]), _c("svg-icon", {
      attrs: {
        "icon-class": _vm.isPlay === item.order + 1 ? "voice-animation" : "voice"
      }
    })], 1) : _vm._e(), _c("p", {
      class: {
        "voice-text": item.src
      }
    }, [_vm._v(" " + _vm._s(item.content) + " ")])])]) : item.msg_type === "file" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" [发来一个文件：" + _vm._s(item.content) + "] ")]) : item.msg_type === "location" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" [发来一个位置：" + _vm._s(item.content) + "] ")]) : item.msg_type === "link" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [item.src ? _c("el-link", {
      attrs: {
        type: "primary",
        underline: false,
        href: item.src,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.content))]) : _c("span", [_vm._v(_vm._s(item.content))])], 1) : _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" " + _vm._s(item.content) + " ")])])]), item.msg_type === "reference" ? _c("div", {
      staticClass: "content reference-container"
    }, [_c("div", {
      staticClass: "reference"
    }, [_vm._v(" " + _vm._s(item.reference_content) + " ")])]) : _vm._e(), _c("div", {
      staticClass: "operate-item"
    }, [_vm.activeDataSetData.type !== "doc" ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "播放"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.locateSentence(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-video-play"
    })])]) : _vm._e(), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "命中关键事件"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      attrs: {
        id: "addKeyEventTrackerBtn"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.addKeyEventTracker(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-key-event"
    })])])], 1)])], 1)])]);
  }), 0), _c(_setup.AddKeyEventTrackerPopover, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.showAddKeyEventTrackerPopover,
      expression: "showAddKeyEventTrackerPopover"
    }, {
      name: "drag",
      rawName: "v-drag"
    }],
    ref: "addKeyEventTrackerPopoverRef",
    class: ["add-key-event-tracker-popover", {
      show: _setup.showAddKeyEventTrackerPopover
    }],
    attrs: {
      id: "addKeyEventTrackerPopover",
      "data-has-user-drag": 0,
      "event-options": _vm.dataSetLabelList,
      "show-add-key-event-tracker-popover": _setup.showAddKeyEventTrackerPopover
    },
    on: {
      handleCancelAddKeyEventTracker: _setup.handleCancelAddKeyEventTracker,
      handleSubmitAddEventTracker: _setup.createAnnotate
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;