"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "knowledge-library-catalog"
  }, [_c("MgvCatalogTree", {
    ref: "mgvCatalogTree",
    attrs: {
      "tree-data": _vm.catalogData,
      "show-count": _vm.showKnowledgeCount,
      "icon-config-method": _vm.isShowFolderIcon ? _vm.getCatalogIconConfig : null,
      draggable: _vm.draggable && _vm.knowledgePermission,
      "allow-drop": _vm.allowDrop,
      "operate-btns-options-method": _vm.getCatalogTreeOperationConfigs
    },
    on: {
      nodeClick: _vm.handleNodeClick,
      "node-drag-start": _vm.handleNodeDragStart,
      "node-drop": _vm.handleDrop,
      handleOperateCommand: _vm.handleCommand
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;