"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex.js");
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'InstructionResIconPopover',
  props: {
    requestMethod: {
      type: Function,
      required: true
    },
    runRequestMethod: {
      // 用户主动运行指令请求
      type: Function,
      required: true
    },
    requestParams: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const instructionRes = (0, _vue.ref)('');
    const exceptionStatus = (0, _vue.ref)('none');
    const instructionInfoMap = [{
      label: '创建人',
      value: '',
      key: 'create_by_user_info'
    }, {
      label: '创建时间',
      value: '',
      key: 'create_at'
    }, {
      label: '指令内容',
      value: '',
      key: 'content'
    }];
    const instructionInfoList = (0, _vue.ref)([]);
    const tipTextMap = {
      no_result: '当天的指令结果在次日凌晨生成，历史会话不会自动运行',
      no_summary_content: '暂无可分析的纪要内容',
      running: '指令结果生成中，请稍后几分钟再查看'
    };
    const loading = (0, _vue.ref)(false);
    const getInstructionRes = () => {
      loading.value = true;
      props.requestMethod(props.requestParams).then(res => {
        if (res.code === 20000) {
          const {
            result,
            exception_status,
            copilot_instruction_sets
          } = res.results;
          instructionRes.value = result;
          exceptionStatus.value = exception_status;
          instructionInfoList.value = copilot_instruction_sets;
        }
      }).finally(() => {
        loading.value = false;
      });
    };
    const handleInstructionInfo = info => {
      const infoList = instructionInfoMap.map(item => {
        if (item.key === 'create_by_user_info') {
          return {
            ...item,
            value: info[item.key].name
          };
        } else {
          return {
            ...item,
            value: info[item.key]
          };
        }
      });
      return infoList || [];
    };
    const nowRunInstruction = async () => {
      const res = await props.runRequestMethod(props.requestParams);
      if (res.code === 20000) {
        exceptionStatus.value = 'running';
      }
    };

    // 监听store的webSocketMsg的值，如有变化需要做处理
    (0, _vue.watch)(() => store.getters.webSocketMsg, val => {
      if (val.type === 'notify_schedule_instruction_set_manual_run') {
        if ((0, _isEqual.default)(val.data, props.requestParams)) {
          // 比较webSocketMsg的data和requestParams是否相等再出发
          getInstructionRes();
        }
      }
    }, {
      deep: true
    });

    // onMounted(() => {
    //   getInstructionRes();
    // });

    return {
      __sfc: true,
      props,
      store,
      instructionRes,
      exceptionStatus,
      instructionInfoMap,
      instructionInfoList,
      tipTextMap,
      loading,
      getInstructionRes,
      handleInstructionInfo,
      nowRunInstruction,
      MgvButton: _index.default
    };
  }
};
exports.default = _default;