"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ConversationFilterItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    popoverContent: {
      type: String,
      default: ''
    },
    isShowClear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClear() {
      this.$emit('handleClear');
    }
  }
};
exports.default = _default;