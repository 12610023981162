"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.completeTask = completeTask;
exports.creatNewTask = creatNewTask;
exports.deleteTask = deleteTask;
exports.getAllTaskUsers = getAllTaskUsers;
exports.getCompletedTaskList = getCompletedTaskList;
exports.getCurrentTaskDetail = getCurrentTaskDetail;
exports.getManageViewTaskList = getManageViewTaskList;
exports.getOvertimeTaskNumber = getOvertimeTaskNumber;
exports.getTodoTaskCount = getTodoTaskCount;
exports.getTodoTaskList = getTodoTaskList;
var _request = require("@/utils/request");
// 查询任务个数
function getTodoTaskCount(params) {
  return (0, _request.webService)({
    url: '/task_center/task/count',
    method: 'get',
    params
  });
}

// 分配给我/我创建的任务列表（未完成的）
function getTodoTaskList(params) {
  return (0, _request.webService)({
    url: '/task_center/task/mine',
    method: 'get',
    params
  });
}

// 查询任务所有执行人列表
function getAllTaskUsers(params) {
  return (0, _request.webService)({
    url: '/task_center/task/users',
    method: 'get',
    params
  });
}

// 已完成的任务列表
function getCompletedTaskList(params) {
  return (0, _request.webService)({
    url: '/task_center/task/completed',
    method: 'get',
    params
  });
}

// 创建任务
function creatNewTask(data) {
  return (0, _request.webService)({
    url: '/task_center/task/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 任务详情
function getCurrentTaskDetail(params) {
  return (0, _request.webService)({
    url: '/task_center/task/detail',
    method: 'get',
    params
  });
}

// 完成任务
function completeTask(data) {
  return (0, _request.webService)({
    url: '/task_center/task/complete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 逾期的任务
function getOvertimeTaskNumber() {
  return (0, _request.webService)({
    url: '/task_center/task/overtime_number',
    method: 'get'
  });
}
function deleteTask(data) {
  return (0, _request.webService)({
    url: '/task_center/task/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取团队视图任务列表
function getManageViewTaskList(data) {
  return (0, _request.webService)({
    url: '/task_center/task/view',
    method: 'post',
    dataType: 'json',
    data
  });
}