"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataSet = require("@/api/dataSet");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _DataSetLabelSelect = _interopRequireDefault(require("./DataSetLabelSelect.vue"));
var _vue = require("vue");
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DataSetSelectors',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    fillValue: {
      type: Array,
      default: () => []
    },
    datasetId: {
      type: String,
      default: ''
    },
    single: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tipCount: {
      type: [String, Number],
      default: '300'
    },
    disabledDataSets: {
      type: Array,
      default: () => []
    },
    disabeldDataSetLabels: {
      type: Array,
      default: () => []
    }
  },
  emits: ['input'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const onInput = () => {
      emit('input', getValue());
      // calcTotalPositiveCount();
    };

    const onDataSetLabelChange = (val, item) => {
      item.label_positive_count = (val === null || val === void 0 ? void 0 : val.positive_count) || 0;
      onInput();
    };
    const ITEM = {
      dataset_id: '',
      dataset_label_id: '',
      label_positive_count: 0
    };
    const getInitialItem = () => {
      return (0, _cloneDeep.default)(ITEM);
    };
    const dataSetsList = (0, _vue.ref)([getInitialItem()]);
    (0, _vue.watchEffect)(async () => {
      if (!(0, _isEmpty.default)(props.fillValue)) {
        dataSetsList.value = props.fillValue.map(item => {
          return {
            dataset_id: item.dataset_id,
            dataset_name: item.dataset_name,
            dataset_label_id: item.dataset_label_id,
            label_positive_count: item.label_positive_count
          };
        });
      } else {
        dataSetsList.value = [getInitialItem()];
      }
    });
    const hasSelectOption = id => {
      return !!dataSetOptions.value.find(item => item.id === id);
    };
    const addDataSet = () => {
      dataSetsList.value.push(getInitialItem());
    };
    const deleteDataset = async index => {
      dataSetsList.value.splice(index, 1);
      await (0, _vue.nextTick)();
      onInput();
    };
    const isDataSetDisabled = id => {
      return dataSetsList.value.some(item => id === item.dataset_id) || props.disabledDataSets.some(item => id === item.dataset_id);
    };
    const dataSetOptions = (0, _vue.ref)([]);
    const getDataSetsAsync = async val => {
      const res = await (0, _dataSet.getDataSetList)({
        dataset_name_or_id: val || '',
        page: 1,
        size: 20
      });
      dataSetOptions.value = res.results.items.filter(item => item.annotated_data_count > 0);
    };
    getDataSetsAsync();
    const onDatasetChange = item => {
      item.dataset_label_id = '';
      item.label_positive_count = 0;
      onInput();
    };
    const effectiveValue = (0, _vue.computed)(() => {
      return dataSetsList.value.filter(item => item.dataset_id && item.dataset_label_id);
    });
    const totalPositiveCount = (0, _vue.computed)(() => {
      return effectiveValue.value.reduce((total, item) => {
        return total + item.label_positive_count;
      }, 0);
    });
    const dataSetLabelSelectRef = (0, _vue.ref)([]);

    // function calcTotalPositiveCount() {
    //   totalPositiveCount.value = dataSetLabelSelectRef.value.reduce(
    //     (total, item) => {
    //       return total + item.getPositiveCount();
    //     },
    //     0
    //   );
    // }
    const getValue = () => {
      return effectiveValue.value.map(item => {
        return {
          dataset_id: item.dataset_id,
          dataset_label_id: item.dataset_label_id
        };
      });
    };
    expose({
      getValue
    });
    return {
      __sfc: true,
      props,
      emit,
      onInput,
      onDataSetLabelChange,
      ITEM,
      getInitialItem,
      dataSetsList,
      hasSelectOption,
      addDataSet,
      deleteDataset,
      isDataSetDisabled,
      dataSetOptions,
      getDataSetsAsync,
      onDatasetChange,
      effectiveValue,
      totalPositiveCount,
      dataSetLabelSelectRef,
      getValue,
      DataSetLabelSelect: _DataSetLabelSelect.default
    };
  }
};
exports.default = _default;