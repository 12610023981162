"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _keyEventManagement = require("@/api/keyEventManagement");
var _EventTree = _interopRequireDefault(require("./EventTree.vue"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MoveEventDialog',
  components: {
    EventTree: _EventTree.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => ({})
    },
    eventTree: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      folderId: '',
      label: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'workspaceInfo']),
    hideLeafTree() {
      // 递归删除eventTree的叶子节点

      if (this.eventTree && this.eventTree.length) {
        let _eventTree = (0, _cloneDeep.default)(this.eventTree);
        _eventTree = _eventTree.filter(item => item.event_type !== 'system');
        this.deleteLeafNodes(_eventTree);
        return _eventTree;
      }
      return this.eventTree;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false);
    },
    handleNodeClick(data, node) {
      this.label = data.name;
      this.folderId = data.id;
      this.$refs.select.blur();
    },
    async submit() {
      if (this.event.id === this.folderId) {
        this.closeDialog();
        return;
      }
      this.loading = true;
      const res = await (0, _keyEventManagement.moveKeyEvent)({
        workspace_id: this.workspaceInfo.id,
        event_ids: [this.event.id],
        target_folder_id: this.folderId
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.$message.success('移动成功');
        this.$emit('moveSuccess', {
          id: this.folderId,
          name: this.label
        });
        this.closeDialog();
      }
    },
    deleteLeafNodes(tree) {
      if (!tree) return;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.is_leaf) {
          tree.splice(i, 1);
          i--;
        } else {
          this.deleteLeafNodes(node.children);
        }
      }
    }
  }
};
exports.default = _default;