"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bi-filter-basis-select"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top",
      "popper-class": "filter-basis-select-tooltip"
    }
  }, [_c("div", {
    staticClass: "tooltip-content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticClass: "filter-content"
  }, [_c("div", {
    staticClass: "filter-content-index"
  }, [_vm._v("·")]), _c("div", [_vm._v(" 筛选条件为"), _c("span", {
    staticClass: "important-content"
  }, [_vm._v("会话")]), _vm._v("时，可以通过会话时间直接筛选出对应会话。"), _c("br")])]), _c("div", {
    staticClass: "filter-content"
  }, [_c("div", {
    staticClass: "filter-content-index"
  }, [_vm._v("·")]), _c("div", [_vm._v(" 筛选条件为"), _c("span", {
    staticClass: "important-content"
  }, [_vm._v("交易")]), _vm._v("时，可以通过交易创建时间以及其他交易自定义筛选条件筛选出符合条件的"), _c("span", {
    staticClass: "important-content"
  }, [_vm._v("交易下的会话")]), _vm._v("，例如：可以筛选出交易创建时间在上三个月的、地区为北京的交易下的所有会话，对上述筛选结果进行相关指标的统计、展示。 ")])])]), _c("i", {
    staticClass: "iconfont icon-question2"
  })]), _c("div", {
    staticClass: "bi-filter-basis-title"
  }, [_vm._v("筛选会话条件：")]), _c("el-select", {
    attrs: {
      "popper-class": "filter-basis-select",
      placeholder: "请选择会话类型",
      "popper-append-to-body": false
    },
    on: {
      change: _vm.handleBasisfiltersChange
    },
    model: {
      value: _vm.filterBasis,
      callback: function ($$v) {
        _vm.filterBasis = $$v;
      },
      expression: "filterBasis"
    }
  }, _vm._l(_vm.filterBasisOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;