"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabLabel = _interopRequireDefault(require("@/components/BusinessIntelligence/tabLabel"));
var _tabContent = _interopRequireDefault(require("@/components/BusinessIntelligence/tabContent"));
var _vuex = require("vuex");
var _sortMixin = _interopRequireDefault(require("../../sortMixin"));
var _timeFormatter = require("@/utils/time-formatter");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _download = require("@/utils/download");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'Activity',
  components: {
    TabLabel: _tabLabel.default,
    TabContent: _tabContent.default
  },
  mixins: [_sortMixin.default, _ExportMixins.default],
  data() {
    return {
      activeTab: 'count',
      sortType: 'ascending',
      labels: ['时长', '次数', '时长']
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biActivityObj'])
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.indexOf('/bi/') === -1) {
      sessionStorage.setItem('biFilters', '');
      sessionStorage.setItem('biDepartmentOptions', '');
    }
    next();
    this.$store.commit('bi/SET_ACTIVITY_OBJ');
  },
  mounted() {
    this.$bus.$on('exportCsv', this.exportCsv);
  },
  beforeDestroy() {
    this.$bus.$off('exportCsv');
  },
  methods: {
    exportCsv() {
      if (JSON.stringify(this.biActivityObj) == '{}') return;
      const memberMap = new Map();
      this.biActivityObj.map(item => {
        item.table.forEach(c => {
          const {
            id,
            name,
            valueLabel
          } = c;
          if (memberMap.has(id)) {
            memberMap.get(id).push(valueLabel);
          } else {
            memberMap.set(id, [name, valueLabel]);
          }
        });
      });
      const res = [['成员', ...this.biActivityObj.map(_ref => {
        let {
          labelTitle
        } = _ref;
        return labelTitle;
      })], ...memberMap.values(), ['平均', ...this.biActivityObj.map(_ref2 => {
        let {
          avgStr
        } = _ref2;
        return avgStr;
      })], ['总计', '--', `${this.biActivityObj[1].tab}次`, (0, _timeFormatter.secondToCNTimeOfFixedTwo)(this.biActivityObj[2].tab)]];
      const {
        activeTabName,
        startDate,
        endDate,
        conversationType
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${startDate}至${endDate}的${conversationType}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    handleChangeContentArrays(item) {
      const newTable = this.getDeepCloneArr(item.table);
      item.table = this.handleSortArray(newTable, this.sortType);
    },
    handleChangeSortType(val) {
      this.sortType = val;
    }
  }
};
exports.default = _default;