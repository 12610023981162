"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _export = require("@/api/export");
var _doubleCall = require("@/api/doubleCall");
var _headerFilters = _interopRequireDefault(require("./headerFilters"));
var _CreateDeal = _interopRequireDefault(require("./components/CreateDeal"));
var _Kanban = _interopRequireDefault(require("./Kanban"));
var _BusinessTable = _interopRequireDefault(require("@/components/BusinessTable"));
var _TableExport = _interopRequireDefault(require("@/components/TableExport.vue"));
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _OtherPageCarryingFilter = _interopRequireDefault(require("./components/OtherPageCarryingFilter"));
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessIndex',
  components: {
    BusinessTable: _BusinessTable.default,
    headerFilters: _headerFilters.default,
    createDeal: _CreateDeal.default,
    Kanban: _Kanban.default,
    SpaceButton: _SpaceButton.default,
    OtherPageCarryingFilter: _OtherPageCarryingFilter.default,
    DynamicPopover: _DynamicPopover.default,
    TableExport: _TableExport.default,
    MgPagination: _MgPagination.default
  },
  mixins: [_cancelTokenMixin.default],
  data() {
    return {
      headerList: [],
      kanbanList: [],
      activeKanban: {},
      list: null,
      table: null,
      deal_ids: null,
      listParams: {
        // 表格的分页、筛选、排序
        customize_params: {},
        filter_params: {},
        current_page: 1,
        size: 10,
        workspace_id: 0,
        sort_column: '',
        sort_order: ''
      },
      total: 0,
      fresh: [],
      tableLoading: false,
      doubleCallPermission: 0,
      customFilterObject: {},
      currentDealIds: [],
      otherPageCarryingFilterLabel: '',
      otherPageCarryingParams: [],
      keyEvents: [],
      tableHeight: document.body.clientHeight - 156,
      // 可排序的列
      sortColumnList: [],
      currentListParams: {},
      originDealAmount: [] // 商机原始金额
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'webRoutes', 'workspaceInfo', 'timezone']),
    hasDealSetPermission() {
      return this.webRoutes.find(item => item.name === 'DealSet');
    },
    exportRequestParams() {
      return {
        body: this.currentListParams,
        filter_id: this.activeKanban.id,
        module: 'deal_list',
        head_field: this.headerList.map(item => item.field_name)
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', true);
        this.activeKanban = {};
        this.getDealCustomFilter();
        this.initData();
      }
    }
  },
  async created() {
    const globalPageSize = localStorage.getItem('globalPageSize');
    if (globalPageSize) {
      const parsedPageSize = JSON.parse(globalPageSize);
      const routePageSize = parsedPageSize[this.$route.name] || 10;
      this.listParams.size = routePageSize;
    }
    this.getOtherPageCarryingFilter();
    this.initData();
    await this.getDealCustomFilter();
  },
  mounted() {
    window.addEventListener('resize', this.setTableHeight);
    if (this.has_outbound) {
      // 有外呼才可以有双呼
      this.getDoubleCallPermission();
    }
  },
  methods: {
    // 确定并保存列设置
    confirmAndSaveTableHead(list) {
      this.headerList = list.filter(item => item.is_activate);
      this.handleSave();
    },
    exportBusinessList: _export.exportBusinessList,
    // 保存
    handelSaveCommand(command) {
      if (command === 'isSaveAsNew') {
        this.handleSave(true);
      }
    },
    // 保存全部筛选器
    async handleSave() {
      let isSaveAsNew = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const {
        id,
        ...other
      } = this.activeKanban;
      const localFilterData = (0, _commonFunc.deepClone)(this.listParams.filter_params);
      (0, _timezone.convertParams2UTCplus8)(localFilterData);
      const data = {
        filter_id: id,
        ...other,
        filter_config: localFilterData,
        head_conf: this.headerList
      };
      if (isSaveAsNew) {
        var _this$$refs$kanban;
        data.name = '';
        (_this$$refs$kanban = this.$refs.kanban) === null || _this$$refs$kanban === void 0 ? void 0 : _this$$refs$kanban.handleCopyView(data);
      } else {
        const res = await (0, _business.editDealView)(data);
        if (res.code === 20000) {
          this.$message.success('已将当前筛选项、排序、列设置保存到视图中');
          this.initData();
        }
      }
    },
    createNewKanban() {
      this.initData('create').then(() => {
        var _this$$refs$kanban2;
        (_this$$refs$kanban2 = this.$refs.kanban) === null || _this$$refs$kanban2 === void 0 ? void 0 : _this$$refs$kanban2.ScrollToEnd();
      });
    },
    // 切换看板重新请求看板视图数据
    handleKanbanChange(item) {
      if (item.id === this.activeKanban.id) return;
      this.activeKanban = item;
      // this.initData();
      this.tableLoading = true;
      this.handleViewChange();
    },
    getTableHead(type, list) {
      this.headerList = list;
      if (!this.tableLoading) {
        this.setScaleLine();
      }
    },
    async initData(type) {
      var _this$workspaceInfo;
      this.tableLoading = true;
      if (!((_this$workspaceInfo = this.workspaceInfo) !== null && _this$workspaceInfo !== void 0 && _this$workspaceInfo.id)) return;
      const res = await (0, _business.getDealFilterConfig)({
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      });
      if (res.code === 20000) {
        const list = res.results.view_list;
        list.forEach(kanban => {
          const filterConfig = (0, _commonFunc.deepClone)(kanban.filter_config);
          (0, _timezone.convertUTCplus82CurrentZone)(filterConfig);
          kanban.filter_config = filterConfig;
        });
        this.kanbanList = list;
      }
      if (type === 'create') {
        this.activeKanban = this.kanbanList[this.kanbanList.length - 1];
      }
      if (!this.activeKanban.id) {
        this.activeKanban = this.kanbanList[0];
      } else {
        this.activeKanban = this.kanbanList.find(item => {
          var _this$activeKanban;
          return item.id === ((_this$activeKanban = this.activeKanban) === null || _this$activeKanban === void 0 ? void 0 : _this$activeKanban.id);
        });
      }
      await this.handleViewChange();
      await this.getDealAmount();
    },
    async handleViewChange() {
      this.setDealAmount();
      const config = JSON.parse(JSON.stringify(this.activeKanban.filter_config));
      // 处理user_ids格式，对象数组转id数组
      if (config && config.user_ids) {
        config.user_ids = this.handleUserValue(config.user_ids);
      }
      this.listParams.filter_params = config;
      this.listParams.current_page = 1;
      await this.getActiveViewSortInfo(this.activeKanban.id);
      await this.getActiveViewHeadList(this.activeKanban.id);
      this.$store.commit('workspace/CHANGE_WORKSPACE_BUTTON_DISABLE', false);
    },
    handleUserValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    async getActiveViewSortInfo(viewId) {
      const res = await (0, _business.getViewSortInfo)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 20000) {
        this.sortColumnList = res.results.sort_field;
        const sortList = res.results.sort_field.filter(item => !!item.value);
        if (sortList.length) {
          var _this$$refs$businessT;
          (_this$$refs$businessT = this.$refs['businessTable']) === null || _this$$refs$businessT === void 0 ? void 0 : _this$$refs$businessT.sortChange({
            prop: sortList[0].field_name,
            order: sortList[0].value
          });
        }
      }
    },
    async getActiveViewHeadList(viewId) {
      const res = await (0, _business.getViewHeadList)({
        workspace_id: this.workspaceInfo.id,
        filter_id: viewId
      });
      if (res.code === 200) {
        this.$set(this.activeKanban, 'head_conf', res.results);
        this.handleAddColumnSortAttr();
      }
    },
    /**
     * 给列添加排序
     */
    handleAddColumnSortAttr() {
      this.sortColumnList.forEach(column => {
        this.activeKanban['head_conf'].forEach(head => {
          if (column.field_name === head.field_name) {
            head.sortable = 'custom';
          }
        });
      });
    },
    // 获取自定义的筛选项
    async getDealCustomFilter() {
      const res = await (0, _business.getDealCustomFilter)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 200) {
        this.customFilterObject = res.results;
        this.$nextTick(() => {
          this.setTableHeight();
        });
      }
    },
    handleKanBanCustomObject(customObject) {
      for (const item in customObject) {
        for (let i = 0; i < customObject[item].length; i++) {
          customObject[item][i].is_show_kanban = true;
        }
      }
      return customObject;
    },
    async getDoubleCallPermission() {
      const res = await (0, _doubleCall.getDoubleCallPermission)();
      if (res.code === 200) {
        this.doubleCallPermission = res.results.double_call;
      }
    },
    debounceGetList: (0, _commonFunc.debounce)(function (val) {
      this.getList(val);
    }, 500, true),
    async getList(isFilterChange) {
      this.axiosCancelRequest();
      this.newCancelToken();
      this.tableLoading = true;
      this.currentListParams = (0, _commonFunc.deepClone)(this.listParams);
      this.currentListParams.workspace_id = this.workspaceInfo.id;
      this.currentListParams.time_zone = this.timezone;
      this.currentDealIds && (this.currentListParams.filter_params.deal_ids = this.currentDealIds);
      (0, _timezone.convertParams2UTCplus8)(this.currentListParams.filter_params);
      try {
        const res = await (0, _business.getBusinessList)(this.currentListParams, {
          cancelToken: this.axiosCancelToken
        });
        this.tableLoading = false;
        if (res.code === 200) {
          const {
            data,
            table,
            total,
            total_amount
          } = res.results;
          [this.list, this.table, this.fresh, this.total] = [data, table, data, total];
          if (isFilterChange) {
            this.$set(this.kanbanList.find(item => {
              var _this$activeKanban2;
              return item.id === ((_this$activeKanban2 = this.activeKanban) === null || _this$activeKanban2 === void 0 ? void 0 : _this$activeKanban2.id);
            }), 'amount', total_amount);
            this.$set(this.kanbanList.find(item => {
              var _this$activeKanban3;
              return item.id === ((_this$activeKanban3 = this.activeKanban) === null || _this$activeKanban3 === void 0 ? void 0 : _this$activeKanban3.id);
            }), 'total', total);
          }
          this.deal_ids = this.list.map(item => item.deal_id);
          this.earliestTime = res.results.earliest_time;
          this.setScaleLine();
        }
      } finally {
        this.tableLoading = false;
      }
    },
    // 设置表格跟进动态时间轴刻度线 scaleLine
    setScaleLine() {
      // 把渲染负载放到下一次事件循环中
      setTimeout(() => {
        this.$nextTick(() => {
          const table = this.$refs['businessTable'];
          if (table) {
            table.flipNum = 0;
            table.flipNumLoaded = 0;
            const scaleLine = table.$refs['scaleLine'];
            if (scaleLine) {
              scaleLine.forEach(element => {
                element.setDateList(this.earliestTime);
              });
            }
          }
        });
      }, 10);
    },
    // 跟进动态请求
    async getBusinessSituation() {
      var _this$$refs$businessT2;
      if (JSON.stringify(this.listParams.filter_params) === '{}') return;
      const scaleLine = (_this$$refs$businessT2 = this.$refs['businessTable']) === null || _this$$refs$businessT2 === void 0 ? void 0 : _this$$refs$businessT2.$refs['scaleLine'];
      if (scaleLine) {
        const params = {
          date: scaleLine[0].dateRange,
          deal_ids: this.deal_ids
        };
        const situationRes = await (0, _business.getBusinessSituation)(params);
        if (situationRes.code === 200) {
          this.list.forEach((item, index) => {
            item.speak_time.unshift(...situationRes.results[index].conversation_speak_times);
          });
        }
      }
    },
    currentChange(val) {
      this.listParams.current_page = val;
      this.getList();
    },
    sizeChange(val) {
      this.listParams.size = val;
      this.listParams.current_page = 1;
      this.getList();
    },
    goBusinessDetail(id, date) {
      window.open(`${location.origin}${_routeMap.ROUTES.dealDetail}?id=${id}${date ? '&date=' + date : ''}&noback=true`);
    },
    tableSortChange(prop, order) {
      if (!prop) return;
      this.listParams.sort_column = order === 'default' ? '' : prop;
      this.listParams.sort_order = order;
      if (order !== 'default') {
        this.activeKanban.sort_conf = [{
          field_name: prop,
          value: order,
          order: 1
        }];
      }
      this.debounceGetList();
    },
    headerFiltersChange(params) {
      if (params.deal_name && !params.deal_name.value.length) {
        params.deal_name = undefined;
      }
      if (JSON.stringify(this.listParams.filter_params) === JSON.stringify(params)) {
        return;
      }
      // 修复deal/list重复请求问题
      if (!this.tableLoading) {
        this.listParams.current_page = 1;
        this.listParams.filter_params = params;
        this.debounceGetList(true);
      }
    },
    handleObjectionClick(businessId, conversationId) {
      this.$router.push({
        path: _routeMap.ROUTES.dealDetail,
        query: {
          id: businessId,
          convId: conversationId
        }
      });
    },
    handleSettingClick() {
      this.$router.push({
        path: _routeMap.ROUTES.dealSet
      });
    },
    async handleDeleteKanban(id) {
      const res = await (0, _business.deleteDealView)({
        filter_id: id,
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('删除成功');
        if (!this.kanbanList.length) return;
        this.activeKanban = {};
        this.kanbanList = this.kanbanList.filter(item => {
          return item.id !== id;
        });
        this.initData();
      }
    },
    getOtherPageCarryingFilter() {
      // 获取其他页面跳转商机页携带的商机id和label
      const otherPageCarryingFilter = JSON.parse(sessionStorage.getItem('otherPageCarryingFilter'));
      if (otherPageCarryingFilter) {
        this.currentDealIds = otherPageCarryingFilter.dealIds;
        this.otherPageCarryingFilterLabel = otherPageCarryingFilter.label;
      }
      sessionStorage.removeItem('otherPageCarryingFilter');
    },
    setTableHeight() {
      const header = document.querySelector('.header-filter-container');
      this.tableHeight = document.body.clientHeight - (header ? 156 + header.clientHeight : 156);
    },
    handleClearOtherPageFilter() {
      this.currentDealIds = [];
      this.listParams.current_page = 1;
      this.getList();
    },
    setDealAmount() {
      let amountViewList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.originDealAmount;
      for (const item of this.kanbanList) {
        const view = amountViewList.find(v => v.id === item.id);
        if (view) {
          item.amount = view.amount;
          item.total = view.total;
        }
      }
    },
    async getDealAmount() {
      const params = {
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone
      };
      const res = await (0, _business.getDealAmount)(params);
      const viewList = res.results.view_list;
      this.originDealAmount = viewList;
      // 给视图添加商机金额和数量
      this.setDealAmount(viewList);
    }
  }
};
exports.default = _default;