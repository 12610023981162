"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SpaceLabel',
  props: {
    spaceInfo: {
      type: Object,
      default: () => ({})
    }
  }
};
exports.default = _default;