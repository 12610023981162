"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _commonFunc = require("@/utils/commonFunc");
var _DirectedGraph = _interopRequireDefault(require("./components/DirectedGraph"));
var _EditTemplate = _interopRequireDefault(require("./components/EditTemplate"));
var _ShortcutKeysDrawer = _interopRequireDefault(require("./components/ShortcutKeysDrawer"));
var _TrainingTestDrawer = _interopRequireDefault(require("./components/TrainingTestDrawer"));
var _SettingDrawer = _interopRequireDefault(require("./components/SettingDrawer"));
var _ImportDrawer = _interopRequireDefault(require("./components/ImportDrawer"));
var _ConfirmDialog = _interopRequireDefault(require("./components/ConfirmDialog"));
var _AiGeneratingCourseDrawer = _interopRequireDefault(require("./components/AiGeneratingCourseDrawer"));
var _CourseTips = _interopRequireDefault(require("@/views/coaching/courseEdit/components/CourseTips.vue"));
var _vuex = require("vuex");
var _MgAutoTooltip = _interopRequireDefault(require("@/components/MgAutoTooltip"));
var _coachingCenter = require("@/api/coachingCenter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SmartTraningEdit',
  components: {
    DirectedGraph: _DirectedGraph.default,
    ShortcutKeysDrawer: _ShortcutKeysDrawer.default,
    EditTemplate: _EditTemplate.default,
    CourseTips: _CourseTips.default,
    TrainingTestDrawer: _TrainingTestDrawer.default,
    SettingDrawer: _SettingDrawer.default,
    ImportDrawer: _ImportDrawer.default,
    AiGeneratingCourseDrawer: _AiGeneratingCourseDrawer.default,
    ConfirmDialog: _ConfirmDialog.default,
    MgAutoTooltip: _MgAutoTooltip.default
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('training/SET_KNOWLEDGE_BASE_INFO', {});
    this.$store.commit('training/SET_KNOWLEDGE_SCRIPT', []);
    next();
  },
  data() {
    return {
      isEdit: false,
      isEditTitle: false,
      title: '',
      originTitle: '',
      templateDialogVisible: false,
      shortcutKeysDrawerVisible: false,
      roleType: 'narration',
      submitLoading: false,
      graphId: 0,
      // 新建或使用模板第一次调用创建接口，将创建接口返回的id赋值，后面的操作都用此id调用更新接口；
      trainingTestDrawerVisible: false,
      settingDrawerVisible: false,
      nodeCard: '',
      testContent: '',
      // 用于话术练习的卡片信息
      practiceRule: {
        access_note: true,
        language: 'zh',
        practice_mode: 0,
        // 0 语音模式   1文字模式
        summary_score_type: 1,
        // 1 每句得分的平均值   2 每句得分的和
        score: 60,
        sentence_pass_rate: 60
      },
      importDrawerVisible: false,
      operations: [{
        icon: 'iconfont icon-import',
        tip: 'coaching.importData',
        operate: 'importDrawerVisible'
      }, {
        icon: 'iconfont icon-setting',
        tip: 'coaching.courseSettings',
        operate: 'settingDrawerVisible'
      }, {
        icon: 'iconfont icon-keyboard',
        tip: 'coaching.shortcutKeys',
        operate: 'shortcutKeysDrawerVisible'
      }],
      aiGeneratingCourseDrawerVisible: false,
      confirmDialogVisible: false,
      noScoreNodeCards: [],
      autoCompleteLoading: false,
      getScoreRuleTimer: null,
      messageBox: null,
      importLoading: null,
      myCallbacks: {
        onNextStep: () => this.tourCardDisplay(arguments),
        onPreviousStep: () => this.tourCardDisplay(arguments)
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission']),
    courseDescription() {
      return this.$t('coaching.smartTrainingUseTip');
    },
    steps() {
      // 5 步卡片引导
      const nodeCardSteps = [{
        target: '[data-v-step="nodeSidebar"]',
        placement: 'right',
        content: this.$t('coaching.scriptPracticeTour1')
      }, {
        placement: 'bottom',
        target: '[data-v-step="roleCard"]',
        content: this.$t('coaching.scriptPracticeTour2')
      }, {
        target: '[data-v-step="roleCardPort"]',
        placement: 'right',
        content: this.$t('coaching.scriptPracticeTour3')
      }, {
        target: '[data-v-step="smartTrainingTest"]',
        content: this.$t('coaching.scriptPracticeTour4')
      }, {
        target: '[data-v-step="saveTemplateBtn"]',
        content: this.$t('coaching.scriptPracticeTour5')
      }];
      const currentLanguage = localStorage.getItem('lang') || 'zh';
      nodeCardSteps.forEach((item, index) => {
        item.src = require(`@/assets/coach/smartTrainingCreateGuide/step${index + 1}/${currentLanguage}.jpg`);
      });
      return nodeCardSteps;
    },
    videoSrc() {
      const currentLanguage = localStorage.getItem('lang') || 'zh';
      return require(`@/assets/coach/smartTrainingCreateGuide/${currentLanguage}.mp4`);
    }
  },
  mounted() {
    const clickHandler = e => {
      // NOTE <<<点击空白处关闭eventDrawer
      if (!e.target.closest('#training-test-drawer') && !e.target.closest('#shortcut-keys-drawer') && !e.target.closest('#setting-drawer') && !e.target.closest('#import-drawer') && !e.target.closest('.smart-training-language-list-select-dropdown') && !e.target.closest('.el-image-viewer__wrapper')) {
        ['trainingTestDrawerVisible', 'settingDrawerVisible', 'shortcutKeysDrawerVisible', 'importDrawerVisible'].forEach(item => this[item] = false);
      }

      // 点击空白处关闭eventDrawer>>>
    };

    document.addEventListener('mousedown', clickHandler);
    // 卸载全局click事件
    this.$once('hook:beforeDestroy', () => document.removeEventListener('mousedown', clickHandler, true));
  },
  created() {
    const id = this.$route.query.id;
    if (id) {
      this.graphId = Number(id);
      this.getSmartTrainingCourseDetail(this.graphId);
      this.getSmartTrainingSet(this.graphId);
    }
    if (this.graphId) {
      this.isEdit = true;
    }
  },
  beforeDestroy() {
    this.clearScoreRuleInterval();
  },
  methods: {
    tourCardDisplay(node) {
      var _node, _document$getElements;
      node = (_node = node) === null || _node === void 0 ? void 0 : _node[0];
      const tourEle = (_document$getElements = document.getElementsByClassName('v-tour')) === null || _document$getElements === void 0 ? void 0 : _document$getElements[0];
      if (node && tourEle) {
        var _node2, _node2$nodeCard;
        tourEle.style.display = 'none';
        this.highLightRoleNode((_node2 = node) === null || _node2 === void 0 ? void 0 : (_node2$nodeCard = _node2.nodeCard) === null || _node2$nodeCard === void 0 ? void 0 : _node2$nodeCard.id);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          tourEle.style.display = 'block';
        }, 500);
      }
    },
    async importData(val) {
      // 解析数据
      val.unshift([0, this.$t('coaching.start'), this.$t('coaching.start')]);
      val.forEach((item, index) => {
        item.push({
          x: 570 + index * (index === 1 ? 300 : 353),
          y: !index ? 110 : 60
        });
      });
      const content = this.$refs['directed-graph'].constructdirectedGraphCardData(val);
      // 上传数据
      console.log('content', content);
      const res = await (0, _coachingCenter.editSmartTrainingCourse)({
        id: this.graphId,
        name: this.title,
        content: JSON.stringify(content)
      }).catch(() => {
        this.$refs['import-drawer'].handleFail();
      });
      if (res.code === 20000) {
        this.$refs['import-drawer'].handleSuccess();
        this.$refs['directed-graph'].renderGraph({
          cells: content
        });
      }
    },
    async getSmartTrainingSet(id) {
      const res = await (0, _coachingCenter.getSmartTrainingSet)({
        id
      });
      if (res.code === 20000) {
        this.practiceRule = res.results;
        if (this.interfacePermission.getLanguageList) {
          if (!this.practiceRule.language) {
            this.$set(this.practiceRule, 'language', localStorage.getItem('lang'));
          }
        } else {
          this.$set(this.practiceRule, 'language', 'zh');
        }
      }
    },
    highLightRoleNode(data) {
      // this.$refs['directed-graph'].focusOnNode(nodeId);
      // 连接多个销售说卡片时，高亮卡片连线，否则高亮卡片
      this.$refs['directed-graph'].focusOnNodeOrEdge(data);
    },
    openDrawer(drawer) {
      this.$refs['directed-graph'].talkConfigDrawerVisible = false;
      this[drawer] = true;
    },
    skipGuide(tour) {
      tour.stop();
    },
    startMytour() {
      if (localStorage.getItem('isShowSmartTrainingGuide') !== 'true') {
        if (this.nodeCard) {
          var _this$$refs$smartTrai;
          this.$refs['directed-graph'].focusOnNode(this.nodeCard.id);
          this.$refs['directed-graph'].clearAllhighLight();
          (_this$$refs$smartTrai = this.$refs['smartTrainingGuide']) === null || _this$$refs$smartTrai === void 0 ? void 0 : _this$$refs$smartTrai.start();
        }
      }
    },
    getNoScoreNodeCards() {
      const nodeCards = this.$refs['directed-graph'].graph.toJSON().cells;
      // 需要自动补齐的卡片：销售说卡片 && 有销售话术 && 评分规则是按要点评分 && 没有评分要点
      this.noScoreNodeCards = nodeCards.filter(item => this.noScoreNodeCondition(item));
      if (!this.noScoreNodeCards.length) {
        this.clearNodeLoading();
      }
    },
    clearNodeLoading() {
      this.$refs['directed-graph'].graph.getNodes().forEach(item => {
        item.setData({
          loading: false
        });
      });
    },
    noScoreNodeCondition(item) {
      if (!item.data) return false;
      const {
        role,
        scoreRuleType,
        scoreRules,
        branches
      } = item.data;
      return role === 'salesman' && branches[0].text && scoreRuleType === 1 && !scoreRules.filter(item => item.text).length;
    },
    async goBack() {
      if (this.title.length) {
        const res = await this.modifyTitle();
        if (res.code === 20000) {
          this.goBackCourse();
        }
      } else {
        this.goBackCourse();
      }
    },
    goBackCourse() {
      this.$router.push({
        path: _routeMap.ROUTES.course
      });
    },
    async confirmGoBack() {
      this.getNoScoreNodeCards();
      if (this.autoCompleteLoading || !this.noScoreNodeCards.length) {
        this.goBack();
      } else {
        this.confirmDialogVisible = true;
      }
    },
    async startAutoCreateScoreRule() {
      var _this$$refs$directed;
      let showMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.getNoScoreNodeCards();
      if (!this.noScoreNodeCards.length) {
        this.confirmDialogVisible = false;
        showMessage && this.$message({
          type: '',
          customClass: 'no-auto-complete-score-rule-message',
          dangerouslyUseHTMLString: true,
          message: `<div class="content"><i class="iconfont icon-info-2"></i><span>${this.$t('coaching.noNeedCompleteCard')} </span></div>`
        });
        return;
      }
      this.autoCompleteLoading = true;
      await (0, _coachingCenter.editSmartTrainingCourse)({
        id: this.graphId,
        name: this.title,
        content: JSON.stringify((_this$$refs$directed = this.$refs['directed-graph']) === null || _this$$refs$directed === void 0 ? void 0 : _this$$refs$directed.graph.toJSON().cells)
      }).then(async () => {
        const res = await (0, _coachingCenter.createAiScoreRule)({
          course_id: this.graphId
        }).finally(() => {
          this.autoCompleteLoading = false;
        });
        if (res.code === 20000) {
          this.confirmDialogVisible = false;
          this.$refs['directed-graph'].graph.getNodes().forEach(item => {
            if (this.noScoreNodeCondition(item.store.data)) {
              item.setData({
                loading: true
              });
            }
          });
          this.createMessageBox();
          this.getAiScoreRule();
        }
      }).catch(() => {
        this.autoCompleteLoading = false;
      });
    },
    createMessageBox() {
      // TODO 如果发现已经存在auto-complete-score-rule-message的dom,则把该dom删除
      const existingMessageBox = document.querySelector('.auto-complete-score-rule-message');
      if (existingMessageBox) {
        existingMessageBox.remove();
      }
      this.messageBox = this.$message({
        duration: 0,
        type: '',
        customClass: 'auto-complete-score-rule-message',
        dangerouslyUseHTMLString: true,
        message: `<div><span class="loading-icon"></span><span class="score-rule-message-text">${this.$t('coaching.ruleIsGenerating')}  0/${this.noScoreNodeCards.length}</span></div>`
      });
      this.importLoading = _elementUi.Loading.service({
        target: '.loading-icon',
        customClass: 'import-loading-icon'
      });
    },
    // 获取自动补全要点的结果
    getAiScoreRule() {
      this.autoCompleteLoading = true;
      // 轮询导入结果接口
      this.getScoreRuleTimer = setInterval(async () => {
        const res = await (0, _coachingCenter.getAiScoreRule)({
          course_id: this.graphId
        }).catch(() => {
          this.clearScoreRuleInterval();
        });
        if (res.code === 20000) {
          const {
            failed_count,
            finished_count,
            finished_list,
            total_count
          } = res.results;
          if (total_count) {
            if (!document.querySelector('.score-rule-message-text')) {
              this.createMessageBox();
            }
            document.querySelector('.score-rule-message-text').innerHTML = `${this.$t('coaching.ruleIsGenerating')}  ${finished_count}/${this.noScoreNodeCards.length}`;
            (finished_list === null || finished_list === void 0 ? void 0 : finished_list.length) && this.autoCompleteNodeScoreRule(finished_list);
            if (failed_count + finished_count === total_count) {
              const hasEmptyGistList = finished_list === null || finished_list === void 0 ? void 0 : finished_list.find(item => item.gist_list.length === 0);
              if (hasEmptyGistList) {
                this.$message({
                  type: 'warning',
                  message: this.$t('coaching.someKeyPointExtractionFailed')
                });
              } else {
                this.$message({
                  type: 'success',
                  message: this.$t('coaching.generatedSuccessfully')
                });
              }
              this.clearScoreRuleInterval();
            }
          } else {
            this.clearScoreRuleInterval();
            this.clearNodeLoading();
          }
        }
      }, 2000);
    },
    autoCompleteNodeScoreRule(finished_list) {
      this.$nextTick(() => {
        const directedGraph = this.$refs['directed-graph'];
        finished_list.forEach(card => {
          directedGraph.graph.getNodes().forEach(item => {
            // 自动补齐不覆盖有评分要点的销售说卡片
            if (card.id === item.id && this.noScoreNodeCondition(item.store.data)) {
              var _directedGraph$curren;
              item.setData({
                loading: false,
                scoreRules: card.gist_list
              });
              // 更新当前打开的销售说卡片数据
              if (((_directedGraph$curren = directedGraph.currentNodeData) === null || _directedGraph$curren === void 0 ? void 0 : _directedGraph$curren.id) === card.id) {
                directedGraph.currentNodeData.scoreRules = card.gist_list;
                directedGraph.currentNodeData.loading = false;
              }
            }
          });
        });
      });
    },
    clearScoreRuleInterval() {
      this.autoCompleteLoading = false;
      this.messageBox && this.messageBox.close();
      this.getScoreRuleTimer && clearInterval(this.getScoreRuleTimer);
    },
    closeConfirmDialog() {
      this.confirmDialogVisible = false;
    },
    editTitle() {
      this.isEditTitle = true;
      this.originTitle = this.title;
      this.$nextTick(() => {
        this.$refs['title-input'].focus();
      });
    },
    async modifyTitle() {
      this.isEditTitle = false;
      if (!this.title.length) {
        this.title = this.originTitle;
      }
      const res = await (0, _coachingCenter.editAiCustomerSopCourseName)({
        course_id: this.graphId,
        name: this.title
      });
      if (res.code !== 20000) {
        this.title = this.originTitle;
      } else {
        this.originTitle = this.title;
      }
      return res;
    },
    async getSmartTrainingCourseDetail(id) {
      // 获取画布配置
      const res = await (0, _coachingCenter.getSmartTrainingCourseDetail)({
        id
      });
      if (res.code === 20000) {
        const {
          name,
          content
        } = res.results;
        this.title = name;
        this.originTitle = name;
        this.testContent = content;
        if (!content) return;
        this.$nextTick(() => {
          var _JSON$parse;
          this.$refs['directed-graph'].renderGraph({
            cells: JSON.parse(content)
          });
          if (localStorage.getItem('isShowSmartTrainingGuide') === 'true') {
            this.$refs['directed-graph'].centerContent();
          }
          // 当前课程要点正在生成中，进入页面需要轮询生成结果接口
          this.getNoScoreNodeCards();
          this.noScoreNodeCards.length && this.getAiScoreRule();
          // nodeCard 用来区分没有卡片时不同形式的引导
          this.nodeCard = (_JSON$parse = JSON.parse(content)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.find(item => item.type === 'node' && item.shape !== 'start');
          setTimeout(() => {
            this.startMytour();
          }, 500);
        });
      }
    },
    submitGraph: (0, _commonFunc.debounce)(async function (data) {
      if (this.trainingTestDrawerVisible) return;
      this.testContent = data;
      this.submitLoading = true;
      const parseData = JSON.parse(data);
      const _data = parseData.map(item => {
        var _item$data;
        if (item !== null && item !== void 0 && (_item$data = item.data) !== null && _item$data !== void 0 && _item$data.branches) {
          for (const branch of item.data.branches) {
            if (branch.images) {
              branch.images = branch.images.map(image => {
                const url = new URL(image);
                return url.pathname;
              });
            }
          }
        }
        return item;
      });
      await (0, _coachingCenter.editSmartTrainingCourse)({
        id: this.graphId,
        name: this.title,
        content: JSON.stringify(_data)
      }).finally(() => {
        this.submitLoading = false;
      });
    })
  }
};
exports.default = _default;