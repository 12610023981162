"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnswerItem = _interopRequireDefault(require("./AnswerItem.vue"));
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateQuestionDialog',
  components: {
    AnswerItem: _AnswerItem.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 当前销售回答问答对
    currentQaItem: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    // 编辑的问题信息
    editQuestion: {
      type: Object,
      default: () => ({})
    },
    // 新建问题并将该回答移入
    createAndMove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const nameValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入问题名称'));
      } else if (value === '其他') {
        callback(new Error('问题名称被系统占用，请修改后重试'));
      } else {
        callback();
      }
    };
    return {
      questionInfo: {
        name: '',
        keyword: [''],
        sentence: ['']
      },
      rules: {
        name: [{
          validator: nameValidate,
          trigger: 'change'
        }]
      },
      loading: false,
      submitLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // 编辑问题
          if (this.isEdit) {
            this.getQuestionDetail();
          }
          this.$nextTick(() => {
            this.$refs['ruleForm'].clearValidate();
          });
        }
      }
    }
  },
  methods: {
    async getQuestionDetail() {
      this.loading = true;
      const res = await (0, _businessIntelligence.getFreqQuestionDetail)({
        id: this.editQuestion.id,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        let {
          keyword,
          sentence
        } = res.results;
        !keyword.length && (keyword = ['']);
        !sentence.length && (sentence = ['']);
        this.questionInfo = {
          name: res.results.name,
          keyword,
          sentence
        };
      }
    },
    submit() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          this.submitLoading = true;
          const {
            name,
            keyword,
            sentence
          } = this.questionInfo;
          const params = {
            name,
            workspace_id: this.workspaceInfo.id,
            keyword: keyword.filter(item => item.length),
            sentence: sentence.filter(item => item.length)
          };
          if (this.isEdit) {
            params.q_id = this.editQuestion.id;
          } else {
            params.qa_id = this.currentQaItem.qa_id;
          }
          const requestFunc = this.isEdit ? _businessIntelligence.editFreqQuestion : _businessIntelligence.createFreqQuestion;
          const res = await requestFunc(params).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 20000) {
            if (this.createAndMove) {
              // 新建问题并移动，需要调用移动接口&关闭弹窗后刷新问题列表
              this.moveFreqQuestion(res.results.id);
            } else {
              this.$message.success(this.isEdit ? '编辑成功' : '创建成功');
              this.handleClose();
              this.$emit('refreshCurrentPageQuestions');
            }
          }
        }
      });
    },
    async moveFreqQuestion(q_id) {
      const res = await (0, _businessIntelligence.moveFreqQuestion)({
        qa_id: this.currentQaItem.qa_id,
        // 问答对 id
        q_id,
        // 问题 id
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.$message.success('移动成功');
        this.handleClose();
        this.$emit('createAndMoveFreqQuestion');
      }
    },
    removeWordOrSentence(keyName, index) {
      this.questionInfo[keyName].splice(index, 1);
    },
    addWordOrSentence(keyName) {
      this.questionInfo[keyName].push('');
    },
    handleClose() {
      this.questionInfo = this.$options.data().questionInfo;
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;