"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SnippetCheckBox',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    label: [String, Number],
    isShowAvatar: Boolean,
    checkList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isEndOrder() {
      return Math.max(...this.checkList) === this.label;
    },
    isInRange() {
      let [begin, end] = this.checkList;
      if (end < begin) {
        [begin, end] = [end, begin];
      }
      return this.label <= end && this.label >= begin;
    },
    isBeginOrder() {
      return Math.min(...this.checkList) === this.label;
    }
  }
};
exports.default = _default;