"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _num_formatter = require("@/utils/num_formatter");
var _timeFormatter = require("@/utils/time-formatter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'ConvTable',
  inject: {
    isInQualityTesting: {
      from: 'isInQualityTesting',
      default: false
    },
    getHasSummary: {
      from: 'getHasSummary',
      default: () => function () {
        return false;
      }
    },
    getHasExtractInfo: {
      from: 'getHasExtractInfo',
      default: () => function () {
        return false;
      }
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          date: '',
          filter: '',
          current_page: 1,
          size: 10
        };
      }
    },
    list: {
      type: Array,
      default: () => {
        return null;
      }
    },
    listRefresh: {
      type: Array,
      default: () => {
        return null;
      }
    },
    conversationListTableHead: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      total: 0,
      shareVisibleId: null,
      shareFormVisible: false,
      screenHeight: document.body.clientHeight,
      popoverOpen: false,
      popoverLoading: false,
      showPopoverText: false,
      popoverText: '',
      tooltipDelay: 400,
      openDelay: 400
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg', 'modulePermission']),
    getDate() {
      return function (value) {
        return this.$moment(value).format('YYYY-MM-DD HH:mm');
      };
    },
    complianceScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 2);
    },
    conversationScoreHead() {
      return this.conversationListTableHead.filter(item => item.field_type === 'score_rule').filter(item => item.module === 1);
    },
    conversationCustomFilterHead() {
      return this.conversationListTableHead.filter(item => item.field_type !== 'score_rule');
    },
    textColor() {
      return function (status) {
        if (status === 'completed') {
          return '#4461EC';
        } else if (status === 'expire') {
          return '#C7CCDC';
        } else {
          return '#666F8D';
        }
      };
    },
    getTipText() {
      return function (status) {
        if (status === 'completed') {
          return '';
        } else if (status === 'expire') {
          return '未出席';
        } else {
          return '生成中';
        }
      };
    },
    tableHeight() {
      const wh = this.screenHeight;
      const th = wh - 248;
      return th;
    },
    getEntityStatus() {
      return function (item) {
        if (item.entity_type === 'lead') {
          return '线索';
        } else if (!item.entity_status) {
          return '-';
        } else {
          return item.entity_status;
        }
      };
    }
  },
  watch: {
    params: {
      handler: function (val, oldVal) {
        if (val.date === null) {
          // eslint-disable-next-line vue/no-mutating-props
          this.params.date = '';
        }
      },
      deep: true
    },
    listRefresh: {
      handler: function (val) {
        this.$nextTick(() => {
          const itemName = document.getElementsByClassName('itemName');
          if (itemName) {
            for (let i = 0; i < itemName.length; i++) {
              this.$set(this.list[i], 'nameWidth', itemName[i].offsetWidth);
              this.$set(this.list[i], 'fatherNameWidth', itemName[i].parentElement.offsetWidth);
            }
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.reappearPage();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenHeight = document.body.clientHeight;
      })();
    };
  },
  methods: {
    formatMoney(_ref) {
      let {
        data,
        precisions,
        is_thousand_separate
      } = _ref;
      if (data !== '') {
        return `￥${(0, _num_formatter.parseFormatNum)(data, precisions, !is_thousand_separate)}`;
      } else {
        return '-';
      }
    },
    formatNumber(_ref2) {
      let {
        data,
        precisions
      } = _ref2;
      if (data !== '') {
        return (0, _num_formatter.parseFormatNum)(data, precisions, true);
      } else {
        return '-';
      }
    },
    formatDate(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : '-';
    },
    formatDateTime(time) {
      return time ? this.$plus8ToCurrentZone(time).format('YYYY-MM-DD HH:mm') : '-';
    },
    getConversationValue(value) {
      return value !== '' ? value : '-';
    },
    getMediumIcon(type) {
      return {
        doc: 'icon-im-fill',
        audio: 'icon-phone-outline-fill',
        video: 'icon-video-camera-solid-2'
      }[type];
    },
    getClassName(hangUp) {
      switch (hangUp) {
        case 1:
          return 'custom-tag';
        case 2:
          return 'sales-tag';
      }
    },
    getHangUpPerson(hangUp) {
      switch (hangUp) {
        case 0:
          return '-';
        case 1:
          return '客户';
        case 2:
          return '销售';
      }
    },
    rowStyle(row) {
      if (row.row.status === 'expire') {
        return 'row-expire';
      } else {
        return 'row-normal';
      }
    },
    goDetails(row) {
      if (row.status !== 'expire') {
        window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${row.id}&noback=true`);
        if (this.type === 'my') {
          sessionStorage.setItem('my_browseInfo', JSON.stringify(this.params));
        } else if (this.type === 'all') {
          sessionStorage.setItem('all_browseInfo', JSON.stringify(this.params));
        }
      } else {
        return false;
      }
    },
    goKeyevent(row) {
      if (row.status === 'expire') {
        return;
      }
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${row.id}&tab=keyevent&noback=true`);
    },
    secondToTime: _timeFormatter.secondToTime,
    reappearPage() {
      const isDetail = sessionStorage.getItem('isDetail');
      if (isDetail === 'true') {
        let browseInfo = '';
        if (this.type === 'my') {
          browseInfo = JSON.parse(sessionStorage.getItem('my_browseInfo'));
        } else if (this.type === 'all') {
          browseInfo = JSON.parse(sessionStorage.getItem('all_browseInfo'));
        }
        if (browseInfo) {
          // eslint-disable-next-line vue/no-mutating-props
          this.params = browseInfo;
        }
      }
    },
    sortChange(_ref3) {
      let {
        prop,
        order
      } = _ref3;
      this.$emit('sortChange', prop, order);
    }
  }
};
exports.default = _default;