"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ICONMAP = void 0;
const ICONMAP = {
  knowledge: 'training',
  snippet: 'film',
  date: 'date',
  video: 'video-camera',
  audio: 'phone-outline',
  doc: 'im',
  deal: 'deal4',
  account: 'office-building',
  report: 'document',
  next: 'right',
  comment: 'chat-line-square',
  coaching: 'coaching',
  task: 'task',
  share: 'share-line',
  other: 'menu-line',
  workspace: 'workspace-3-fill',
  bi: 'analysis-3'
};
exports.ICONMAP = ICONMAP;