"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.sumbitVisible,
      width: "482px",
      height: "187px",
      "append-to-body": true,
      "before-close": _vm.handleClose,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.sumbitVisible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", [_vm._v("提示")])]), _c("div", {
    staticClass: "indentity-body"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("设定身份")]), _c("div", {
    staticStyle: {
      "margin-left": "20px",
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px",
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticClass: "indentity-man-style"
  }, [_vm._v(_vm._s(_vm.oldName))]), _c("i", {
    staticClass: "el-icon-right",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _c("span", {
    class: _vm.newType === "customer_contact" ? "customer-style" : "host-style"
  }, [_vm._v(_vm._s(_vm.newType === "customer_contact" ? "客户" : "销售"))]), _c("image-avatar", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      name: _vm.newName,
      avatar: _vm.newAvatar,
      type: _vm.newType
    }
  }), _c("span", [_vm._v(" " + _vm._s(_vm.newName))])], 1), _c("div", {
    staticStyle: {
      "margin-top": "10px",
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticClass: "indentity-man-style"
  }, [_vm._v(_vm._s(_vm.oldName2))]), _c("i", {
    staticClass: "el-icon-right",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _c("span", {
    class: _vm.newType2 === "host_salesman" ? "host-style" : "customer-style"
  }, [_vm._v(_vm._s(_vm.newType2 === "host_salesman" ? "销售" : "客户"))]), _c("image-avatar", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      name: _vm.newName2,
      avatar: _vm.newAvatar2,
      type: _vm.newType2
    }
  }), _c("span", [_vm._v(_vm._s(_vm.newName2))])], 1)]), _c("div", {
    staticClass: "button-style"
  }, [_c("el-button", {
    staticClass: "cancel-style",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "enter-style",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleUp
    }
  }, [_vm._v("确定并重新分析")])], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;