"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "custom-field-title"
  }, [_vm._v(_vm._s(_vm.title()))]), _c("div", {
    staticClass: "horizontal-structure custom-field"
  }, [_c("div", {
    staticClass: "left-content"
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.current_menu,
      "collapse-transition": false
    },
    on: {
      select: _vm.handleSelect
    }
  }, [_c("el-menu-item", {
    staticClass: "overview-menu-item",
    attrs: {
      index: "conversation"
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("会话字段")])]), _c("el-menu-item", {
    staticClass: "overview-menu-item",
    attrs: {
      index: "lead"
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("线索字段")])]), _c("el-menu-item", {
    staticClass: "overview-menu-item",
    attrs: {
      index: "account"
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("客户字段")])]), _c("el-menu-item", {
    staticClass: "overview-menu-item",
    attrs: {
      index: "deal"
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("商机字段")])]), _c("el-menu-item", {
    staticClass: "overview-menu-item",
    attrs: {
      index: "contact"
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("联系人字段")])])], 1)], 1), _c("div", {
    staticClass: "right-content"
  }, [_c("CustomFieldRightContent", {
    ref: "tab",
    attrs: {
      "current-menu": _vm.current_menu
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;