"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'LableRequireTooltipRemark',
  props: {
    // 参数格式及含义
    // config: {
    //   label: '标题',
    //   // 必填星号提示
    //   require: true,
    //   tooltip: [
    //     {
    //       title: '提示标题1',
    //       content: '提示内容1'
    //     },
    //     {
    //       title: '提示标题2',
    //       content: '提示内容3'
    //     }
    //   ],
    //   remark: '标题后括号内的备注'
    // }
    config: {
      type: Object,
      default: () => ({})
    }
  }
};
exports.default = _default;