"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TimeRange',
  props: {
    timeRange: {
      type: Array,
      default: () => []
    },
    onlySecond: {
      type: Boolean,
      default: false
    },
    maxValue: {
      type: [String, Number],
      default: -1
    },
    isLeftUnlimit: {
      // 默认模式是左侧>, 右侧是<=。如果isLeftUnlimit=true, 说明是左侧>= 右侧<。所以为true时，左侧可以输入0，并且左侧默认为"unlimit"
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minMinute: '',
      minSecond: '',
      maxMinute: '',
      maxSecond: '',
      isValid: true,
      isChoose: false,
      unlimitArray: ['unlimit', -1]
    };
  },
  watch: {
    timeRange: {
      handler: function (val) {
        this.minMinute = this.unlimitArray.includes(val[0]) ? '' : parseInt(val[0] / 60) || '';
        this.minSecond = this.unlimitArray.includes(val[0]) ? '' : this.isLeftUnlimit && !this.minMinute ? val[0] % 60 : val[0] % 60 || '';
        this.maxMinute = this.unlimitArray.includes(val[1]) ? '' : parseInt(val[1] / 60) || '';
        this.maxSecond = this.unlimitArray.includes(val[1]) ? '' : this.isLeftUnlimit && !this.maxMinute ? val[1] % 60 : val[1] % 60 || '';
        val.length && this.getTimeLabel();
      },
      deep: true,
      immediate: true
    },
    isValid(val) {
      this.$emit('validStatusChange', val);
    }
  },
  methods: {
    handleBlur() {
      if (this.isChoose) return;
      if (this.minSecond >= 60 || this.maxSecond >= 60) {
        this.isValid = false;
        return;
      } else {
        this.isValid = true;
      }
      let min = parseInt(this.minMinute || 0) * 60 + parseInt(this.minSecond || 0);
      let max = parseInt(this.maxMinute || 0) * 60 + parseInt(this.maxSecond || 0);
      if (min && max && min > max) {
        [min, max] = [max, min];
      }
      if (this.isLeftUnlimit) {
        if (this.minMinute === '' && this.minSecond === '') {
          min = 'unlimit';
        }
        if (this.maxMinute === '' && this.maxSecond === '') {
          max = 'unlimit';
        }
      }
      this.handleChange(min, this.isLeftUnlimit ? max : max || this.maxValue);
      this.getTimeLabel();
    },
    handleChange(min, max) {
      this.$emit('timeChange', [min, max]);
    },
    getTimeLabel() {
      // 会话时长label
      let timeLabel;
      if (this.minMinute === this.maxMinute && this.minSecond === this.maxSecond) {
        timeLabel = this.formatTime(this.minMinute, this.minSecond);
      } else if (!this.minMinute && !this.minSecond) {
        timeLabel = this.formatTime(this.maxMinute, this.maxSecond) + '以内';
      } else if (!this.maxMinute && !this.maxSecond) {
        timeLabel = this.formatTime(this.minMinute, this.minSecond) + '以上';
      } else {
        timeLabel = this.formatTime(this.minMinute, this.minSecond) + ' - ' + this.formatTime(this.maxMinute, this.maxSecond);
      }
      this.$emit('timeLabelChange', timeLabel);
    },
    formatTime(min, sec) {
      const minute = min ? min + '分钟' : '';
      const second = sec ? sec + '秒' : '';
      return minute + second;
    },
    resetValid() {
      this.isValid = true;
    }
  }
};
exports.default = _default;