"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "role-node",
    class: {
      "is-active": _vm.isActive
    },
    attrs: {
      "data-v-step": "roleCard"
    }
  }, [_c("div", {
    staticClass: "node-header"
  }, [_c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.roleName
    }
  }, [_c("span", {
    staticClass: "role-name"
  }, [_vm._v(_vm._s(_vm.roleName))])]), _vm.role === "salesman" ? [_vm.loading ? _c("div", {
    staticClass: "loading-text"
  }, [_vm._v(" " + _vm._s(_vm.ruleIsGenerating)), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _c("span", {
    class: ["score-status", {
      "not-scored": _vm.scoreRuleType === 2,
      "has-score": !_vm.getScoreStatus
    }]
  }, [_vm._v(_vm._s(_vm.getScoreStatus))])] : _vm._e(), _c("el-button", {
    staticClass: "delete-btn",
    attrs: {
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.deleteNodeMethod
    }
  })], 2), _c("div", {
    staticClass: "node-content"
  }, [_vm._l(_vm.branches, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "branch-container",
      class: {
        "has-pic": item.images && item.images.length
      },
      attrs: {
        "data-v-step": !index && "roleCardPort"
      }
    }, [_c("div", {
      staticClass: "text-container"
    }, [_c("span", [_vm._v(_vm._s(item.text || _vm.defaultText))])]), item.images && item.images.length ? _c("i", {
      staticClass: "iconfont icon-picture"
    }) : _vm._e()]);
  }), _vm.role === "salesman" && _vm.ruleType ? _vm._l(_vm.rulesDescription, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "branch-container"
    }, [_c("span", [_vm._v(_vm._s(item))])]);
  }) : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;