"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-date-picker", _vm._b({
    attrs: {
      type: "date",
      placeholder: _vm.placeholder ? _vm.placeholder : `请选择${_vm.label}`,
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.onDateChange
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  }, "el-date-picker", _vm.$attrs, false)) : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, [_vm._v(_vm._s(_vm.newValue))]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;