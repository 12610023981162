"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _ModelVersionSelect = _interopRequireDefault(require("./ModelVersionSelect.vue"));
var _modelManagement = require("@/api/modelManagement");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SwitchVersionDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: Number,
      default: 0
    },
    modelName: {
      type: String,
      default: ''
    },
    curVersion: {
      type: [String, Number],
      default: ''
    },
    isAiModel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submitSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const version = (0, _vue.ref)('');
    const btnLoading = (0, _vue.ref)(false);
    const changeVersion = () => {
      btnLoading.value = true;
      (0, _modelManagement.changeModelVersion)({
        id: props.modelId,
        version: version.value
      }).then(res => {
        if (res.code === 20000) {
          _elementUi.Message.success('切换成功');
          emit('submitSuccess', props.modelId);
          updateVisible(false);
        }
      }).finally(() => {
        btnLoading.value = false;
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      version,
      btnLoading,
      changeVersion,
      ModelVersionSelect: _ModelVersionSelect.default
    };
  }
};
exports.default = _default;