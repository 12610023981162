"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "analysis-business-table"
  }, [_c("el-button", {
    staticClass: "export-analysis-business-table",
    on: {
      click: _vm.exportAnalysisBusinessTable
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]), _c("el-table", {
    attrs: {
      data: _vm.tableData,
      "default-sort": {
        prop: "rate",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "user_info.name",
      label: "销售姓名"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "include_event_conversation",
      label: `含${_vm.eventName}会话数`,
      sortable: "",
      "sort-orders": ["ascending", "descending"]
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "all_conversation",
      label: "总会话数",
      sortable: "",
      "sort-orders": ["ascending", "descending"]
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "rate",
      label: `${_vm.eventName}事件命中率`,
      sortable: "",
      "sort-orders": ["ascending", "descending"],
      formatter: _vm.formatRateToPercent
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;