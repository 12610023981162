"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "hint-content-intelligent-practice-process"
  }, [_vm._l(_vm.hints, function (item, index) {
    return [_c("div", {
      key: index
    }, [_vm.hints.length > 1 ? _c("h1", {
      staticClass: "hit-title"
    }, [_vm._v(" " + _vm._s(`提示${index + 1}`) + " ")]) : _vm._e(), _c("p", {
      staticClass: "hit-content"
    }, [_vm._v(_vm._s(item))])])];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;