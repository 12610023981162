"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _BusinessPathChart = _interopRequireDefault(require("./components/BusinessPathChart.vue"));
var _BusinessPathSankeyChart = _interopRequireDefault(require("./components/BusinessPathSankeyChart.vue"));
var _BusinessPathViewBtn = _interopRequireDefault(require("./components/BusinessPathViewBtn.vue"));
var _PresetPathButton = _interopRequireDefault(require("./components/PresetPathButton.vue"));
var _ChartFunctionBtns = _interopRequireDefault(require("./components/ChartFunctionBtns.vue"));
var _BusinessPathTable = _interopRequireDefault(require("./components/BusinessPathTable.vue"));
var _PresetPathDialog = _interopRequireDefault(require("./components/PresetPathDialog.vue"));
var _conversationList = require("@/api/conversationList");
var _businessIntelligence = require("@/api/businessIntelligence");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessPath',
  components: {
    BusinessPathViewBtn: _BusinessPathViewBtn.default,
    ChartFunctionBtns: _ChartFunctionBtns.default,
    PresetPathButton: _PresetPathButton.default,
    BusinessPathChart: _BusinessPathChart.default,
    BusinessPathSankeyChart: _BusinessPathSankeyChart.default,
    BusinessPathTable: _BusinessPathTable.default,
    PresetPathDialog: _PresetPathDialog.default
  },
  data() {
    return {
      nodePathParams: {},
      chartData: {},
      chartRadio: '桑基图',
      totalTopPath: 0,
      // top路径的总个数
      presetPathData: [],
      // 设置预设路径数据
      presetPathChartData: {},
      // 预设路径思维导图数据
      treeNodeIds: [],
      // 获取思维导图路径的id参数
      treeChartTops: [],
      // 思维导图top5数据
      sankeyChartTops: {},
      // 桑基图top5数据
      sankeyTopPath: [],
      // 桑基图top路径数据
      sankeyPathTotalStep: 0,
      // 桑基图最长路径的总步数
      teamTopPathData: [],
      // 团队的TOP路径和预设的数据
      hasChartView: false,
      hasPresetPath: false,
      teamTableLoading: false,
      actualPathLoading: true,
      // 实际业务路径思维导图loading
      presetPathChartLoading: false,
      // 预设业务路径思维导图loading
      keyEventOptions: [],
      keyEventNames: [],
      viewLoading: false,
      presetPathDialogVisible: false,
      savePresetLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone'])
  },
  watch: {
    biFiltersObj: {
      async handler(val) {
        this.viewLoading = true;
        const {
          date,
          tree_ids
        } = val;
        if (!tree_ids.length) return;
        const time = JSON.parse(date);
        const [start_date, end_date] = time;
        this.nodePathParams = {
          start_date,
          end_date,
          tree_ids,
          ...this.biFiltersObj
        };
        // 获取团队top5路径
        await this.getTop5BusinessPath();
        if (this.treeChartTops.length) {
          // 获取预设路径
          this.fetchPresetPathData();
          // 视图切换
          this.getViewData();
        } else {
          // 业务路径没有数据时展示暂无数据
          this.actualPathLoading = false;
        }
      },
      deep: true
    },
    'workspaceInfo.id': {
      handler: function () {
        this.getEventOptions();
      },
      immediate: true
    }
  },
  methods: {
    getEventOptions() {
      (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      }).then(res => {
        this.keyEventOptions = res.results.events;
        this.getEventName(this.keyEventOptions);
      });
    },
    handleChartViewChange(view) {
      this.chartRadio = view;
      this.getViewData();
    },
    // 思维导图/桑基图/列表视图切换/筛选条件变化
    async getViewData() {
      this.viewLoading = true;
      try {
        switch (this.chartRadio) {
          case '桑基图':
            await this.fetchBusinessPathData();
            // 避免视图切换后,首次点击不高亮
            this.sankeyTopPath = [];
            // 筛选条件变化后，默认不选top路径
            this.$refs['chart-function-btns'].pathRadio = '';
            break;
          case '思维导图':
            this.getTreeNodeIds(0);
            await this.fetchBusinessPathData();
            break;
          case '表格':
            // 获取个人top1路径
            this.$refs['business-path-table'] && (await this.$refs['business-path-table'].getUsersTop1Path('page'));
            break;
        }
      } finally {
        this.viewLoading = false;
      }
    },
    // 获取思维导图或者桑基图数据
    async fetchBusinessPathData() {
      let res;
      this.actualPathLoading = true;
      if (this.chartRadio === '思维导图') {
        const params = {
          ...this.nodePathParams,
          node_path: [],
          top_path: this.treeNodeIds
        };
        // 获取思维导图
        res = await (0, _businessIntelligence.getBusinessPathChart)(params);
      } else {
        res = await (0, _businessIntelligence.getBusinessSankeyChart)(this.nodePathParams);
      }
      if (res.code === 200 && res.msg === 'Success') {
        this.chartData = res.results;
      }
      this.actualPathLoading = false;
      if (res.results.total_step) {
        this.sankeyPathTotalStep = res.results.total_step;
      }
    },
    // 获取预设路径思维导图数据
    async fetchPresetPathData() {
      this.presetPathChartLoading = true;
      const res = await (0, _businessIntelligence.getBusinessPresetPathChart)(this.nodePathParams);
      if (res.code === 200) {
        this.presetPathChartData = res.results.data;
        this.hasPresetPath = res.results.is_existent;
        // 用来编辑预设路径的数据
        const presetPathData = res.results.preset_path;
        this.presetPathData = [];
        // 用来编辑预设路径的数据
        if (presetPathData.length) {
          presetPathData.forEach((item, index) => {
            this.presetPathData.push({
              value: 'b' + item.node_id
            });
          });
        }
      } else {
        this.hasPresetPath = false;
      }
      this.presetPathChartLoading = false;
    },
    // 获取团队top5路径和用来编辑预设路径的数据
    async getTop5BusinessPath() {
      this.teamTableLoading = true;
      this.teamTopPathData = [];
      const customFilters = (0, _commonFunc.deepClone)(this.nodePathParams.custom_filters);
      (0, _timezone.convertParams2UTCplus8)(customFilters);
      this.nodePathParams.custom_filters = customFilters;
      this.nodePathParams.time_zone = this.timezone;
      const res = await (0, _businessIntelligence.getTop5BusinessPath)(this.nodePathParams);
      if (res.code === 200) {
        // 思维导图top5数据
        this.treeChartTops = res.results.top5_path;
        if (this.treeChartTops.length) {
          // 桑基图top5数据
          this.sankeyChartTops = res.results.top5_sankey;
          // 团队top路径的总条数
          this.totalTopPath = this.treeChartTops.length;
          this.hasChartView = true;
        } else {
          this.viewLoading = false;
          this.hasChartView = false;
          this.totalTopPath = 0;
        }
        // 展示在团队路径中的预设路径数据
        const presetPathData = res.results.preset_path;
        // 团队分析TOP路径数据(top1-5和预设路径)
        if (presetPathData.length) {
          this.teamTopPathData = [...this.treeChartTops, ...presetPathData];
        } else {
          this.teamTopPathData = this.treeChartTops;
        }
      }
      this.teamTableLoading = false;
    },
    // top路径切换
    topPathChange(val) {
      const pathIndex = val.charAt(val.length - 1) - 1;
      if (this.chartRadio === '桑基图') {
        this.sankeyTopPath = this.sankeyChartTops[pathIndex].links;
      } else {
        // 思维导图top5路径需要根据 treeNodeIds 重新请求
        // topn的路径数据
        if (this.treeChartTops.length) {
          this.getTreeNodeIds(pathIndex);
          this.fetchBusinessPathData();
        }
      }
    },
    // 获取思维导图top路径的节点id treeNodeIds
    getTreeNodeIds(pathIndex) {
      this.treeNodeIds = [];
      const topData = this.treeChartTops[pathIndex].path;
      topData.forEach(item => {
        this.treeNodeIds.push(item.node_id);
      });
    },
    // 桑基图复原
    restoreSankeyView() {
      this.sankeyTopPath = [];
    },
    // 获取关键事件名字数组(用于设置颜色)
    getEventName(list) {
      list.forEach(item => {
        this.keyEventNames.push(item.name);
        if (Object.prototype.hasOwnProperty.call(item, 'children')) {
          this.getEventName(item.children);
        }
      });
    },
    // 预设路径相关代码
    openSetBusinessPathDialog() {
      this.presetPathDialogVisible = true;
    },
    // 编辑预设业务路径
    async createPresetPath(val) {
      // 删除 id 的第一个字符 b
      const preset_path = val.map(item => Number(item.value.substr(1)));
      const params = {
        preset_path
      };
      this.savePresetLoading = true;
      await this.modifyPresetPath(params);
      this.savePresetLoading = false;
      this.closePresetPathDialog();
      this.hasPresetPath = true;
      // 重新获取预设路径思维导图
      this.fetchPresetPathData();
      // 获取团队top5路径和用来编辑预设路径的数据
      this.getTop5BusinessPath();
    },
    // 删除预设业务路径
    async deletePresetPath() {
      const params = {
        preset_path: []
      };
      // 发接口
      await this.modifyPresetPath(params);
      this.closePresetPathDialog();
      // 删除预设路径思维导图
      this.hasPresetPath = false;
      // 删除展示团队数据的最后一项，即预设路径
      this.teamTopPathData.pop();
    },
    // 编辑或删除预设路径
    async modifyPresetPath(params) {
      const res = await (0, _businessIntelligence.modifyPresetPath)(params);
      if (res.code === 200) {
        this.$message({
          message: (params.preset_path.length ? '编辑' : '删除') + '预设路径成功',
          center: true,
          type: 'success'
        });
      }
    },
    closePresetPathDialog() {
      this.presetPathDialogVisible = false;
    }
  }
};
exports.default = _default;