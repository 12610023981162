"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _mediaUpload = require("@/api/mediaUpload");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  inject: ["reload"],
  props: ["sumbitVisible", "modifyArr", "activeRoundId", "audioList"],
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  data() {
    return {
      newName: "",
      newName2: "",
      oldName: "",
      oldName2: "",
      newType: "",
      newType2: "",
      newAvatar: "",
      newAvatar2: ""
    };
  },
  watch: {
    modifyArr(val) {
      if (val.length === 2) {
        this.oldName = this.audioList[0].name;
        this.oldName2 = this.audioList[1].name;
        this.newName = val[0].name;
        this.newName2 = val[1].name;
        this.newAvatar = val[0].avatar;
        this.newAvatar2 = val[1].avatar;
        this.newType = val[0].entityType || val[0].entity_type;
        this.newType2 = val[1].entity_type || val[1].entityType;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    handleCancel() {
      this.$emit("changeSumbitVisible");
    },
    handleUp() {
      let data = {
        conversation_id: this.activeRoundId,
        speaker1_id: this.modifyArr[0].participantId,
        speaker1_type: this.newType,
        speaker2_id: this.modifyArr[1].participantId,
        speaker2_type: this.newType2,
        entity_id1: this.modifyArr[0].id,
        entity_type1: this.newType,
        participant_id1: this.modifyArr[0].participantId,
        participant_name1: this.newName,
        entity_id2: this.modifyArr[1].id,
        entity_type2: this.newType2,
        participant_id2: this.modifyArr[1].participantId,
        participant_name2: this.newName2
      };
      if (this.newType == this.newType2) {
        this.$message({
          type: "warning",
          message: "不可选择重复角色"
        });
        return;
      }
      (0, _mediaUpload.createRole)(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "设定成功"
          }), this.handleClose();
          this.reload();
        }
      });
    },
    handleClose() {
      this.$emit("changeSumbitVisible");
    }
  }
};
exports.default = _default;