"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "init-kanban-card-view"
  }, [_vm.config.tooltip ? _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("p", [_vm._v(_vm._s(_vm.config.tooltipTitle))]), _c("ul", [_vm._l(_vm.config.tooltip, function (item) {
    return _c("li", {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _vm._t("default")], 2)]), _vm._t("right-img")], 2) : _vm._e(), _c("div", {
    staticClass: "create-box"
  }, [_c("NoData", {
    attrs: {
      icon: _vm.config.icon || "icon-a-Property1no-monitoring-01",
      tips: `暂无${_vm.config.title}`
    }
  }, [_vm.hasPermisstion ? _c("el-button", {
    attrs: {
      slot: "button",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("createKanban");
      }
    },
    slot: "button"
  }, [_vm._v(" 新建" + _vm._s(_vm.config.title) + " ")]) : _vm._e()], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;