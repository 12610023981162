"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PersonalFilterGroup = _interopRequireDefault(require("./components/PersonalFilterGroup"));
var _CompliancePersonalTable = _interopRequireDefault(require("./components/CompliancePersonalTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _commonFunc = require("@/utils/commonFunc");
var _ExportButton = _interopRequireDefault(require("../components/ExportButton"));
var _RemovedRecordDrawer = _interopRequireDefault(require("../components/RemovedRecordDrawer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CompliancePersonal',
  components: {
    PersonalFilterGroup: _PersonalFilterGroup.default,
    CompliancePersonalTable: _CompliancePersonalTable.default,
    NoData: _NoData.default,
    DetailHeader: _DetailHeader.default,
    ExportButton: _ExportButton.default,
    RemovedRecordDrawer: _RemovedRecordDrawer.default
  },
  data() {
    return {
      taskId: 0,
      taskName: '',
      scoreRuleList: [],
      evaluationItemList: [],
      userInfo: {
        name: '',
        avatar: ''
      },
      filtersObj: {
        workspace_id: 0,
        task_id: 0,
        conversation_name: '',
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        status: '',
        score: [],
        sort_by: [],
        page: 1,
        size: 20
      },
      tableData: [],
      total: 0,
      loading: false,
      removedRecordDrawerVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'interfacePermission']),
    sortConfig() {
      const sortable = ['score'];
      const samplingType = this.$route.query.sampling_type;
      if (samplingType) {
        // sortable.unshift('conv_name');
      }
      return {
        sortable,
        defaultSort: {
          // prop: samplingType ? 'conv_name' : 'score',
          prop: samplingType ? '' : 'score',
          order: 'descending'
        }
      };
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.taskId = Number(this.$route.query.id);
          this.filtersObj.task_id = this.taskId;
          this.filtersObj.user_ids.user_ids = [Number(this.$route.query.user_id)];
          this.filtersObj.workspace_id = val;
          this.getScoreRuleList();
          this.getEvaluationItemList(val);
          if (!this.filtersObj.sort_by.length) {
            // const { prop, order } = this.sortConfig.defaultSort;
            // this.filtersObj.sort_by = [[prop, order]];
            this.filtersObj.sort_by = [];
          }
          this.getComplianceConversationPersonal(this.filtersObj);
        }
      },
      immediate: true
    }
  },
  methods: {
    exportComplianceConversationPersonalApi: _compliance.exportComplianceConversationPersonalApi,
    openRemovedRecordDrawer() {
      this.removedRecordDrawerVisible = true;
    },
    async getScoreRuleList() {
      this.filtersObj.task_id = Number(this.$route.query.id);
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getScoreRuleList)(data);
      if (res.code === 20000) {
        this.scoreRuleList = res.results.score_rule_filters;
      }
    },
    async getEvaluationItemList() {
      const data = {
        workspace_id: this.workspaceInfo.id,
        task_id: this.taskId
      };
      const res = await (0, _compliance.getEvaluationItemList)(data);
      if (res.code === 20000) {
        this.evaluationItemList = res.results.evaluation_items;
      }
    },
    changeFilter(filters) {
      this.filtersObj = Object.assign(this.filtersObj, filters);
      this.getComplianceConversationPersonal(this.filtersObj);
    },
    sortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.filtersObj.sort_by = [[prop, order]];
      this.getComplianceConversationPersonal(this.filtersObj);
    },
    sizeChange(size) {
      this.filtersObj.size = size;
      this.filtersObj.page = 1;
      this.getComplianceConversationPersonal(this.filtersObj);
    },
    currentChange(page) {
      this.filtersObj.page = page;
      this.getComplianceConversationPersonal(this.filtersObj);
    },
    async getComplianceConversationPersonal(filters) {
      const data = (0, _commonFunc.deepClone)(filters);
      data.status = data.status || 0;
      this.loading = true;
      try {
        const res = await (0, _compliance.getTeamConvTaskList)(data);
        const {
          datas,
          total,
          task_name
        } = res.results;
        if (res.code === 20000) {
          this.tableData = datas;
          this.total = total;
          this.taskName = task_name;
          this.userInfo = this.tableData[0].user_info;
        }
      } finally {
        this.loading = false;
      }
    },
    goComplianceWorkSpace(rowInfo) {
      var _this$$route$query;
      const obj = (0, _commonFunc.deepClone)(this.filtersObj);
      delete obj.page;
      delete obj.size;
      const params = {
        user_ids: {
          tree_ids: [],
          user_ids: [rowInfo.user_info.id]
        },
        ...obj,
        sampling_type: this.$route.query.sampling_type
      };
      const query = {
        id: rowInfo.conv_id,
        task_id: (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.id,
        sampling_type: this.$route.query.sampling_type,
        sampling_id: rowInfo.account_sale_conv_id
      };
      params.status = params.status ? params.status : 0;
      this.$router.push({
        name: 'ComplianceWorkSpace',
        query,
        params
      });
    }
  }
};
exports.default = _default;