"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("el-drawer", {
    attrs: {
      visible: _vm.visible,
      size: "800px",
      "before-close": _vm.closeDrawer
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.detailsOfKnowledgePointExercises"))), _c("el-tooltip", {
          attrs: {
            content: "销售练习多次时，本页记录只展示最后一次练习的录音和得分"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })])], 1)];
      },
      proxy: true
    }], null, false, 2222480392)
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main"
  }, [_c("div", {
    staticClass: "question-info"
  }, [_c("div", {
    staticClass: "process"
  }, [_c("span", {
    staticClass: "bold-text"
  }, [_vm._v(" " + _vm._s(_vm.knowledge.order) + " ")]), _c("span", {
    staticClass: "bias"
  }, [_vm._v("/")]), _c("span", [_vm._v(_vm._s(_vm.knowledge.total_count))])]), _c("span", {
    staticClass: "bold-text knowledge-name"
  }, [_vm._v(_vm._s(_vm.knowledge.name))])]), _c("tricks-practice", {
    attrs: {
      list: _vm.knowledge.scene_reply_list,
      "show-intelligent-review": _vm.showIntelligentReview
    }
  })], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      plain: "",
      disabled: !_vm.knowledge.last_id
    },
    on: {
      click: function ($event) {
        return _vm.getKnowledge("last");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-back"
  }), _vm._v("上一题")]), _c("span", {
    staticClass: "page-text"
  }, [_vm._v(_vm._s(_vm.knowledge.order) + "/" + _vm._s(_vm.knowledge.total_count))]), _c("el-button", {
    staticClass: "next-button",
    attrs: {
      type: "primary",
      plain: "",
      disabled: !_vm.knowledge.next_id
    },
    on: {
      click: function ($event) {
        return _vm.getKnowledge("next");
      }
    }
  }, [_vm._v(" 下一题"), _c("i", {
    staticClass: "iconfont icon-right"
  })])], 1)]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;