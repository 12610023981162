"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/mgv-ui/packages/components/ImageAvatar"));
var _ValueList = _interopRequireDefault(require("./ValueList"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ValueContainer',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ValueList: _ValueList.default
  },
  props: {
    popoverVisible: {
      type: Boolean,
      defalut: false
    },
    showTagCount: {
      type: Number,
      default: 1
    },
    isDepartment: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('coaching.pleaseSelectDepartment');
      }
    },
    selectionList: {
      type: Array,
      default: () => []
    }
  }
};
exports.default = _default;