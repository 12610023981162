"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "field-type-group",
    class: {
      "is-edit": _vm.isEdit
    }
  }, [_c("h4", [_vm._v("字段类型")]), _vm._l(_vm.options, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "option",
      class: {
        selected: _vm.value === key
      },
      on: {
        click: function ($event) {
          return _vm.handleSelect(key);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " "), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.value === key,
        expression: "value === key"
      }],
      staticClass: "iconfont icon-check"
    })]);
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;