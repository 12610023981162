"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-joint"
  }, [_vm._l(_vm.renderInfo, function (item, index) {
    return [item.value ? _c("span", {
      key: index,
      class: _vm.getClassObject(item),
      style: _vm.getStyleObject(item),
      domProps: {
        innerHTML: _vm._s(_vm.getHtml(item))
      },
      on: {
        click: function ($event) {
          return _vm.linkTo(item);
        }
      }
    }) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;