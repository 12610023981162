"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _download = require("@/utils/download");
var _TransText = _interopRequireDefault(require("@/components/Detail/TransText"));
var _Video = _interopRequireDefault(require("@/components/Video"));
var _SearchResult = _interopRequireDefault(require("@/components/Detail/SearchResult"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConvPlayContent',
  components: {
    TransText: _TransText.default,
    VideoPlayer: _Video.default,
    SearchResult: _SearchResult.default
  },
  inject: ['flipRoleApi'],
  props: {
    conversationDetailInfo: {
      type: Object,
      default: () => ({})
    },
    convInWorkSpaceId: {
      type: [Number, String],
      default: ''
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    },
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    tabActiveName: {
      type: String,
      default: 'transtext'
    },
    videoUrl: {
      type: String,
      default: ''
    },
    mediaType: {
      type: String,
      default: ''
    },
    searchWords: {
      type: String,
      default: ''
    },
    resultList: {
      type: Array,
      default: () => []
    },
    origin: {
      type: String,
      default: ''
    },
    convId: {
      type: [String, Number],
      default: ''
    },
    uploadType: {
      type: String,
      default: ''
    },
    hasRole: {
      type: Boolean,
      default: true
    },
    partiInfo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rightBarActive: {
      type: String,
      default: ''
    },
    // 隐藏下载/转录文字/关键事件修正历史功能（剪辑库详情）
    hideFixOperates: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputWords: '',
      // 外部的搜索词
      isShowResult: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['player', 'hasVideo', 'workspaceInfo', 'transTextLoading', 'isShowEventTag', 'userId', 'interfacePermission']),
    hasCorrectTextPermission() {
      const permissionKeyMap = {
        [_routeMap.ROUTES.complianceWorkspace]: 'correctConvText',
        [_routeMap.ROUTES.conversationDetail]: 'correctConvText',
        [_routeMap.ROUTES.libraryDetail]: 'correctSnippetText'
      };
      const key = permissionKeyMap[this.$route.path];
      return this.interfacePermission[key];
    }
  },
  mounted() {
    this.getIsShowEventTag();
    const clickHandler = e => {
      if (!e.target.closest('#conv-search-input') && !e.target.closest('#conv-search-result')) {
        this.isShowResult = false;
      }
    };
    document.addEventListener('mousedown', clickHandler);
    // 卸载全局click事件
    this.$once('hook:beforeDestroy', () => document.removeEventListener('mousedown', clickHandler, true));
  },
  methods: {
    handleShowEventTag() {
      this.$store.commit('video/SET_IS_SHOW_EVENT_TAG', !this.isShowEventTag);
      localStorage.setItem(`${this.userId}IsShowEventTag`, this.isShowEventTag);
    },
    getIsShowEventTag() {
      const isShowEventTag = localStorage.getItem(`${this.userId}IsShowEventTag`);
      if (isShowEventTag === 'true') {
        this.$store.commit('video/SET_IS_SHOW_EVENT_TAG', true);
      } else {
        this.$store.commit('video/SET_IS_SHOW_EVENT_TAG', false);
      }
    },
    async handleCommand(command) {
      switch (command) {
        case 'correctKeyEventHistory':
          this.$emit('openRightDrawer', 'correctKeyEventHistory');
          break;
        case 'correctTextHistory':
          this.$emit('openRightDrawer', 'correctTextContainer');
          break;
        case 'correctFlipRoleHistory':
          this.$emit('openRightDrawer', 'correctFlipRoleHistory');
          break;
        case 'exportTransText':
          await (0, _download.download)('/conversation/detail/detail/export_sentences', '', {
            conversation_id: this.convId,
            workspace_id: this.workspaceInfo.id
          });
          break;
        case 'downloadVideo':
          await (0, _download.downloadByUrl)(this.videoUrl, this.conversationDetailInfo.name);
          break;
      }
    },
    // 翻转角色
    flipRole() {
      this.flipRoleApi({
        conversation_id: Number(this.convId)
      });
    },
    switchTab(type) {
      this.$emit('switchTab', type);
    },
    videoError(status) {
      this.$emit('videoError', status);
    },
    changeSliderTime(sliderTime) {
      this.$emit('changeSliderTime', sliderTime);
    },
    goKeyDetail(order, item) {
      this.$emit('goKeyDetail', order, item);
      if (!this.player.children_[0].paused) {
        var _this$player$children;
        (_this$player$children = this.player.children_[0]) === null || _this$player$children === void 0 ? void 0 : _this$player$children.pause();
      }
    },
    goTransText(words) {
      this.isShowResult = true;
      this.inputWords = words;
      this.$emit('goTransText', words);
    },
    showResults() {
      this.isShowResult = true;
    }
  }
};
exports.default = _default;