"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      "custom-class": "snippets-library-create-folder-dialog",
      title: "新建文件夹",
      width: "400px",
      "append-to-body": ""
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.formDesc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([_vm.showSpaceSelect ? {
      key: "space_id",
      fn: function () {
        return [_c("space-select", {
          on: {
            change: _vm.handleSpaceChange
          },
          model: {
            value: _vm.form.space_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "space_id", $$v);
            },
            expression: "form.space_id"
          }
        })];
      },
      proxy: true
    } : null, {
      key: "folder_id",
      fn: function () {
        return [_c("choose-folder-select", {
          key: _vm.form.space_id,
          attrs: {
            disabled: _vm.createSubfolder,
            "space-id": _vm.form.space_id
          },
          model: {
            value: _vm.folderPosition,
            callback: function ($$v) {
              _vm.folderPosition = $$v;
            },
            expression: "folderPosition"
          }
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c("SetPublicScope", {
    ref: "SetPublicScopeRef"
  }), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    staticClass: "mg-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-button",
    attrs: {
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;