"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit ? _c("el-checkbox-group", {
    staticClass: "mg-checkbox",
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  }, _vm._l(_vm.options, function (item, index) {
    return _c("el-checkbox", {
      key: index,
      attrs: {
        label: item
      }
    });
  }), 1) : _vm.newValue ? _c("div", {
    staticClass: "replace-item"
  }, _vm._l(_vm.newValue, function (item, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        type: "info",
        size: "small"
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;