"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _collapseTransition = _interopRequireDefault(require("element-ui/src/transitions/collapse-transition"));
var _checkbox = _interopRequireDefault(require("element-ui/packages/checkbox"));
var _emitter = _interopRequireDefault(require("element-ui/src/mixins/emitter"));
var _node = _interopRequireDefault(require("./mixin/node"));
var _util = require("./model/util");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ElTreeNode',
  componentName: 'ElTreeNode',
  components: {
    ElCollapseTransition: _collapseTransition.default,
    ElCheckbox: _checkbox.default,
    NodeContent: {
      props: {
        node: {
          required: true
        }
      },
      render(h) {
        const parent = this.$parent;
        const tree = parent.tree;
        const node = this.node;
        const {
          data,
          store
        } = node;
        return parent.renderContent ? parent.renderContent.call(parent._renderProxy, h, {
          _self: tree.$vnode.context,
          node,
          data,
          store
        }) : tree.$scopedSlots.default ? tree.$scopedSlots.default({
          node,
          data
        }) : h("span", {
          "class": 'el-tree-node__label'
        }, [node.label]);
      }
    }
  },
  mixins: [_emitter.default, _node.default],
  props: {
    node: {
      default() {
        return {};
      }
    },
    props: {},
    renderContent: Function,
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tree: null,
      expanded: false,
      childNodeRendered: false,
      oldChecked: null,
      oldIndeterminate: null
    };
  },
  watch: {
    'node.indeterminate'(val) {
      this.handleSelectChange(this.node.checked, val);
    },
    'node.checked'(val) {
      this.handleSelectChange(val, this.node.indeterminate);
    },
    'node.expanded'(val) {
      this.$nextTick(() => {
        this.expanded = val;
      });
      if (val) {
        this.childNodeRendered = true;
      }
    }
  },
  created() {
    const parent = this.$parent;
    this.creator(parent, 'node');
  },
  methods: {
    getNodeKey(node) {
      return (0, _util.getNodeKey)(this.tree.nodeKey, node.data);
    },
    handleDragStart(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-start', event, this);
    },
    handleDragOver(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-over', event, this);
      event.preventDefault();
    },
    handleDrop(event) {
      event.preventDefault();
    },
    handleDragEnd(event) {
      if (!this.tree.draggable) return;
      this.tree.$emit('tree-node-drag-end', event, this);
    }
  }
};
exports.default = _default;