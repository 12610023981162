"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _uploadMedia = _interopRequireDefault(require("./uploadMedia"));
var _mediaUpload = require("@/api/mediaUpload");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    uploadMedia: _uploadMedia.default
  },
  props: ['uploadFlag'],
  data() {
    return {
      formData: {},
      closeToken: false,
      fileMediaList: [],
      radio: '',
      radioList: [{
        name: '教育',
        key: '1'
      }, {
        name: '医疗',
        key: '2'
      }, {
        name: '法律',
        key: '3'
      }, {
        name: '媒体',
        key: '4'
      }, {
        name: '智能家居',
        key: '5'
      }, {
        name: '新零售',
        key: '6'
      }],
      // 专业领域
      options: [{
        value: '选项1',
        label: '普通话'
      }],
      // 音频语言
      value: '选项1',
      finalFlag: false,
      loading: true,
      importLoading: '',
      failArr: [],
      sucArr: [],
      mediaData: [],
      firstUp: [],
      secondUp: []
    };
  },
  watch: {
    fileMediaList(val) {
      // this.submitButtonAndSmallWin(val);
    }
  },
  created() {},
  mounted() {
    window.addEventListener('beforeunload', e => {
      this.beforeunloadHandler(e);
    });
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => {
      this.beforeunloadHandler(e);
    });
  },
  methods: {
    submitButtonAndSmallWin(val) {
      if (val) {
        this.finalFlag = val.some(item => {
          return item.status == 'uploading' || item.text !== 'Success';
        });
        val.every(item => {
          return item.status == 'uploading';
        });
        if (val.every(item => {
          return item.status !== 'uploading' && item.status !== 'ready';
        })) {
          for (let i = 0; i < val.length; i++) {
            const item = val[i];
            if (item.text != 'Success' && item.text && this.failArr.indexOf(item) == -1) {
              this.failArr.push(item);
            } else if (item.text == 'Success' && item.text && this.sucArr.indexOf(item) == -1) {
              this.sucArr.push(item);
            }
          }
        }
        let upFailFlag, upSuccessFlag, upAllSuccessFlag, upAllFailFlag;
        if (val.length !== 0) {
          if (this.failArr.length > 0 && this.failArr.length !== val.length) {
            upFailFlag = true;
          }
          if (this.sucArr.length > 0 && this.sucArr.length !== val.length) {
            upSuccessFlag = true;
          }
          if (this.sucArr.length === val.length && val.length !== 0) {
            upAllSuccessFlag = true;
          }
          if (this.failArr.length === val.length && val.length !== 0) {
            upAllFailFlag = true;
          }
        }
        if (upFailFlag) {
          this.$message({
            showClose: true,
            message: '您有文件未成功上传',
            type: 'error'
          });
        }
        if (upFailFlag && this.uploadLoadNote && upSuccessFlag) {
          this.uploadLoadNote.close();
          this.uploadSuccessNote = this.$notify({
            type: 'warning',
            message: '上传完成',
            dangerouslyUseHTMLString: true,
            customClass: 'importing-notify',
            showClose: false,
            duration: 0,
            onClick: this.otherStaNotice,
            position: 'bottom-right'
          });
        }
        if (upAllFailFlag && this.uploadLoadNote) {
          this.uploadLoadNote.close();
          this.uploadSuccessNote = this.$notify({
            type: 'error',
            message: '上传失败',
            dangerouslyUseHTMLString: true,
            customClass: 'importing-notify',
            showClose: false,
            duration: 0,
            onClick: this.otherStaNotice,
            position: 'bottom-right'
          });
        }
        if (upAllSuccessFlag && this.uploadLoadNote) {
          this.uploadLoadNote.close();
          this.uploadSuccessNote = this.$notify({
            type: 'success',
            message: '上传成功',
            dangerouslyUseHTMLString: true,
            customClass: 'importing-notify',
            showClose: false,
            duration: 0,
            onClick: this.otherStaNotice,
            position: 'bottom-right'
          });
        }
      }
    },
    uploadFileList(val) {
      this.fileMediaList = val;
      this.submitButtonAndSmallWin(this.fileMediaList);
      this.$forceUpdate();
    },
    removeFile(item, index) {
      this.fileMediaList.splice(index, 1);
      this.finalFlag = this.fileMediaList.some(item => {
        return item.status == 'uploading' || item.text !== 'Success';
      });
      this.$refs['upload'].handleRemove(index);
    },
    mouseEnter(event) {
      event.target.parentElement.querySelector('.file-table-items-percentage').style.cssText += 'display:none';
      event.target.parentElement.querySelector('.env-del-btn-span').style.cssText += 'display:block';
    },
    mouseLeave(event) {
      event.target.parentElement.querySelector('.file-table-items-percentage').style.cssText += 'display:block';
      event.target.parentElement.querySelector('.env-del-btn-span').style.cssText += 'display:none';
    },
    // flag状态
    handleClose(done) {
      if (this.fileMediaList && this.fileMediaList.length > 0) {
        this.$confirm('确认取消上传？', '提示', {
          confirmButtonText: '取消上传',
          cancelButtonText: '返回',
          type: 'warning',
          confirmButtonClass: 'confirmClass',
          cancelButtonClass: 'cancelClass'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '已取消上传!'
          });
          this.$refs['upload'].handleAllRemove(this.fileMediaList.length);
          this.fileMediaList = [];
          this.$refs.upload.fileList = [];
          this.changeUploadDialogFlag();
        }).catch(() => {});
      } else {
        this.changeUploadDialogFlag();
      }
    },
    changeUploadDialogFlag() {
      this.$emit('changeUploadDialogFlag');
    },
    handleMini() {
      this.changeUploadDialogFlag();
      this.uploadLoadNote = this.$notify({
        message: '<div class="loading-icon"></div> 上传中',
        customClass: 'importing-notify',
        dangerouslyUseHTMLString: true,
        showClose: false,
        duration: 0,
        onClick: this.loadingStaNotice,
        position: 'bottom-right'
      });
      this.importLoading = _elementUi.Loading.service({
        target: '.loading-icon',
        customClass: 'import-loading-icon'
      });
    },
    loadingStaNotice() {
      this.$emit('openUploadDialog');
      this.uploadLoadNote.close();
    },
    otherStaNotice() {
      this.$emit('openUploadDialog');
      this.uploadSuccessNote.close();
    },
    handleSubmit() {
      const arr = [];
      for (let i = 0; i < this.fileMediaList.length; i++) {
        if (this.fileMediaList[i].response.results) {
          arr.push(this.fileMediaList[i].response.results);
        }
      }
      const data = {
        data: JSON.stringify(arr)
      };
      (0, _mediaUpload.submitAnalysis)(data).then(res => {
        if (res.code === 200) {
          this.fileMediaList = [];
          this.$message({
            type: 'success',
            message: '提交成功'
          });
          this.$refs.upload.fileList = [];
          this.$bus.$emit('fetchConData');
          this.$emit('changeUploadDialogFlag');
        }
      });
    },
    beforeunloadHandler(e) {
      if (this.finalFlag) {
        e = e || window.event;
        if (e) {
          e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存';
        }
        return '您是否确认离开此页面-您输入的数据可能不会被保存';
      }
    }
  }
};
exports.default = _default;