"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    ref: "tree",
    attrs: {
      "icon-class": "iconfont icon-caret-right",
      "node-key": "id",
      "highlight-current": "",
      data: _vm.catalogData,
      "expand-on-click-node": false,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      draggable: "",
      "allow-drop": _vm.allowDrop,
      "allow-drag": _vm.allowDrag,
      "auto-expand-parent": false
    },
    on: {
      "node-click": _vm.handleNodeClick,
      "node-expand": (data, node) => _vm.updateExpandedNodeKey(node, true),
      "node-collapse": (data, node) => _vm.updateExpandedNodeKey(node, false),
      "node-drop": _vm.handleDrop
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data,
          node
        } = _ref;
        return [_c("div", {
          staticClass: "catalog-item"
        }, [_c("el-tooltip", {
          attrs: {
            content: "部分成员可见",
            placement: "top",
            disabled: data.is_public
          }
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.getIcon(data, node)
        })]), _c("div", {
          staticClass: "node-content"
        }, [_c("span", {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(data.id ? data.name : _vm.$t("coaching.defaultClassification")) + " ")]), data.id ? _c("div", {
          staticClass: "operation-btns"
        }, _vm._l(_vm.getFolderDropdownConfigs, function (itemDropdown) {
          return _c("el-dropdown", {
            key: itemDropdown.icon,
            attrs: {
              trigger: "click"
            },
            on: {
              command: function ($event) {
                return _vm.handleCommand(data, $event);
              }
            }
          }, [_c("el-button", [_c("i", {
            class: itemDropdown.icon
          })]), _c("el-dropdown-menu", {
            staticClass: "operate-dropdown",
            attrs: {
              slot: "dropdown"
            },
            slot: "dropdown"
          }, _vm._l(itemDropdown.options, function (optionsGroupItem, index) {
            return _c("div", {
              key: index,
              staticClass: "option-group-item"
            }, [_vm._l(optionsGroupItem, function (item) {
              return [_c("el-dropdown-item", {
                key: item.type,
                attrs: {
                  disabled: node.level === 5 && item.type === "createCatalog",
                  command: item.type
                }
              }, [_c("i", {
                class: ["iconfont", item.icon],
                style: {
                  color: item.color
                }
              }), _vm._v(_vm._s(item.label)), item.type === "6" ? _c("span", {
                staticClass: "beta-tag"
              }, [_vm._v("Beta")]) : _vm._e()])];
            })], 2);
          }), 0)], 1);
        }), 1) : _vm._e()])], 1)];
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;