"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keyEventCard = _interopRequireDefault(require("@/components/BusinessTable/keyEventCard"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _business = require("@/api/business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DealEventsTabContent',
  components: {
    KeyEventCard: _keyEventCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    activeTabName: {
      type: String,
      default: ''
    },
    currentRow: {
      type: Object,
      default: () => ({})
    },
    dealId: {
      type: [String, Number],
      default: ''
    },
    currentFieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // NOTE 关键事件
      eventLoading: false,
      currentPage: 1,
      pageSize: 10,
      eventListLength: 0,
      eventTotal: 0,
      cardContentArr: [],
      convTotal: 0,
      convListLength: 0
    };
  },
  computed: {
    dataType() {
      switch (this.activeTabName) {
        case 'events':
          return '关键事件';
        case 'next_step':
          return '下一步';
        case 'objection':
          return '客户异议';
        case 'account_label':
          return '客户标签';
        default:
          return '';
      }
    },
    noMore() {
      return this.activeTabName === 'account_label' ? !this.convListLength || this.convListLength === this.convTotal : !this.eventListLength || this.eventListLength === this.eventTotal;
    }
  },
  watch: {
    currentRow: {
      handler: function (val) {
        if (val.deal_id) {
          this.$nextTick(async () => {
            await this.getKeyEventList(this.activeTabName, val.deal_id);
            if (this.activeTabName === 'account_label') {
              this.scrollToActiveConv();
            }
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleActiveDate(item) {
      if (this.activeTabName !== 'account_label' || !this.currentRow[this.currentFieldName]) {
        return;
      }
      return item.tag_id === this.currentRow[this.currentFieldName].data[0].event_id;
    },
    // 滚动到对应客户标签
    scrollToActiveConv() {
      if (this.currentFieldName && this.currentRow[this.currentFieldName].data && this.currentRow[this.currentFieldName].data[0].event_id && this.$refs['key-event-card']) {
        this.$nextTick(() => {
          let top = 0;
          this.cardContentArr.some((item, index) => {
            if (this.handleActiveDate(item)) {
              const timelineElement = this.$refs['key-event-card'][index];
              top = timelineElement ? timelineElement.$el.offsetTop - 20 : 0;
              return true;
            }
          });
          this.$emit('scrollToActiveConv', top);
        });
      }
    },
    async getKeyEventList(eventType, dealId) {
      this.initKeyEventData();
      if (eventType === 'account_label') {
        await this.getAccountLabelData(dealId, this.currentPage);
      } else {
        this.getKeyEventData(eventType, dealId, this.currentPage);
      }
    },
    // NOTE 初始化关键事件数据
    initKeyEventData() {
      this.currentPage = 1;
      this.cardContentArr = [];
      this.eventListLength = 0;
      this.convListLength = 0;
    },
    //  NOTE 请求关键事件数据
    async getKeyEventData(eventType, dealId, currentPage) {
      this.eventLoading = true;
      const params = {
        event_engine_type: eventType,
        deal_id: dealId,
        page_at: currentPage,
        page_size: this.pageSize
      };
      const res = await (0, _business.getDealKeyEvent)(params);
      if (res.code === 200) {
        this.eventLoading = false;
        const {
          items,
          total_count
        } = res.results;
        items && this.cardContentArr.push(...items);
        // 滚动加载
        this.eventTotal = total_count;
        this.eventListLength += items === null || items === void 0 ? void 0 : items.length;
        this.currentPage++;
      }
    },
    async getAccountLabelData(dealId, currentPage) {
      this.eventLoading = true;
      const params = {
        deal_id: dealId,
        page_at: currentPage,
        page_size: this.pageSize
      };
      const res = await (0, _business.getAccountLabel)(params);
      if (res.code === 200) {
        this.eventLoading = false;
        const {
          items,
          total_count
        } = res.results;
        items && this.cardContentArr.push(...items);
        this.convTotal = total_count;
        this.convListLength += [...new Set(items.map(item => item.conversation_id))].length;
        this.currentPage++;
      }
    },
    // NOTE 滚动加载
    loadMore() {
      if (this.activeTabName === 'account_label') {
        this.getAccountLabelData(this.dealId, this.currentPage);
      } else {
        this.getKeyEventData(this.activeTabName, this.dealId, this.currentPage);
      }
    }
  }
};
exports.default = _default;