"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'index',
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    isFinish: {
      type: Boolean,
      default: false
    },
    current: {
      type: Number,
      default: 1
    },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props
    };
  }
};
exports.default = _default;