"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "member-lazy-tree-container"
  }, [_c("el-tree", {
    ref: "department-member-tree",
    attrs: {
      lazy: "",
      load: _vm.loadTreeData,
      "node-key": "id",
      "show-checkbox": "",
      props: _vm.defaultProps,
      "check-on-click-node": "",
      "default-expanded-keys": _vm.defaultExpandedKeys
    },
    on: {
      check: _vm.checkNode
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node"
        }, [_vm.isDepartment(data.id) ? _c("i", {
          staticClass: "iconfont",
          class: data.is_root ? "icon-office-building" : "icon-department"
        }) : _c("image-avatar", {
          attrs: {
            size: "small",
            avatar: data.avatar,
            name: data.name
          }
        }), _vm._v(" " + _vm._s(data.name) + " ")], 1)];
      }
    }])
  }), _vm.isShowMainSpaceMember ? _c("el-checkbox", {
    staticClass: "lazy-tree-triggle",
    model: {
      value: _vm.isCheckedSpace,
      callback: function ($$v) {
        _vm.isCheckedSpace = $$v;
      },
      expression: "isCheckedSpace"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.onlyMainSpaceAreDisplayed")))]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;