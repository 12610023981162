"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary.js");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'KnowledgeBaseDropdown',
  props: {
    placement: {
      type: String,
      default: 'bottom-start'
    },
    hasAllKnowledgeBase: {
      type: Boolean,
      default: false
    },
    // 添加至知识库时依赖于权限（可编辑及以上） permission 0:可查看 1:可编辑 2:可管理
    dependPermission: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'icon-caret-bottom'
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      knowledgeBaseList: [],
      currentKnowledgeBase: {
        name: ''
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo', 'interfacePermission'])
  },
  async created() {
    await this.getKnowledgeBaseList();
    this.knowledgeBaseList.length && this.getCurrentKnowledgeBase();
  },
  methods: {
    getCurrentKnowledgeBase() {
      const knowledgeBaseId = Number(this.$route.query['knowledgeBaseId']);
      if (knowledgeBaseId) {
        // 某一个知识库详情页
        const knowledgeBase = this.knowledgeBaseList.find(item => item.id === knowledgeBaseId);
        // 目标知识库被删除，跳转至知识库首页
        if (!knowledgeBase) {
          this.$message.warning('目标知识点已被删除，为您跳转至知识库');
          this.$router.push({
            path: _routeMap.ROUTES.knowledgeLibrary
          });
          return;
        }
        this.currentKnowledgeBase = knowledgeBase;
        this.setKnowledgeBaseInfo();
      } else {
        var _this$knowledgeBaseIn2;
        const knowledgeBaseExist = this.knowledgeBaseList.some(item => {
          var _this$knowledgeBaseIn;
          return item.id === ((_this$knowledgeBaseIn = this.knowledgeBaseInfo) === null || _this$knowledgeBaseIn === void 0 ? void 0 : _this$knowledgeBaseIn.id);
        });
        // 知识库不存在 或者 vuex中不存已选知识库 或者 有全部知识库，下拉菜单选择知识库列表的第一个
        if (!knowledgeBaseExist || !((_this$knowledgeBaseIn2 = this.knowledgeBaseInfo) !== null && _this$knowledgeBaseIn2 !== void 0 && _this$knowledgeBaseIn2.id) || this.hasAllKnowledgeBase) {
          this.currentKnowledgeBase = this.knowledgeBaseList[0];
          // 全部知识库只存在会话搜索页面，不存入 vueX
          this.setKnowledgeBaseInfo();
        } else {
          this.currentKnowledgeBase = this.knowledgeBaseInfo;
        }
      }
    },
    async getKnowledgeBaseList() {
      if (!this.interfacePermission.knowledgeBaseList) return;
      this.loading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeBaseList)().finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        this.knowledgeBaseList = res.results.data;
        if (this.dependPermission) {
          //  添加至知识库时依赖于权限（可编辑及以上） permission 0:可查看 1:可编辑 2:可管理
          this.knowledgeBaseList = res.results.data.filter(item => item.permission);
        }
        if (this.hasAllKnowledgeBase) {
          this.knowledgeBaseList.unshift({
            name: '全部知识库',
            id: -1
          });
        }
      }
    },
    handleDropdownVisibleChange(val) {
      this.visible = val;
      val && this.getKnowledgeBaseList();
    },
    handleCommand(val) {
      if (this.currentKnowledgeBase.id === val) return;
      this.currentKnowledgeBase = this.knowledgeBaseList.find(item => item.id === val);
      this.setKnowledgeBaseInfo();
    },
    setKnowledgeBaseInfo() {
      this.$store.commit('training/SET_KNOWLEDGE_BASE_INFO', this.currentKnowledgeBase);
    }
  }
};
exports.default = _default;