"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'RapidModelingFooter',
  setup(__props) {
    const modelInfo = (0, _vue.inject)('modelInfo');
    const setModelInfo = (0, _vue.inject)('setModelInfo');

    // const props = defineProps({
    //   modelInfo: {
    //     type: Object,
    //     default: () => {}
    //   }
    // });

    const BUTTONS = [{
      id: 1,
      label: '取消建模',
      type: 'default',
      onclick: () => {
        goStep(1);
      }
    }, {
      id: 2,
      label: '上一步',
      type: 'default',
      onclick: minusStep
    }, {
      id: 3,
      label: '下一步',
      type: 'primary',
      onclick: () => {
        addStep();
      }
    }, {
      id: 4,
      label: '获取训练数据',
      type: 'primary',
      onclick: () => {
        addStep();
      }
    }, {
      id: 5,
      label: '修改例句',
      type: 'default',
      onclick: () => {
        console.log('修改例句');
      }
    }, {
      id: 6,
      label: '不校验, 直接训练模型',
      type: 'primary',
      onclick: () => {
        addStep();
      }
    }, {
      id: 7,
      label: '校验完成，训练模型',
      type: 'primary',
      onclick: () => {
        console.log('校验完成，训练模型');
      }
    }, {
      id: 8,
      label: '仅发布',
      type: 'default',
      onclick: () => {
        console.log('仅发布');
      }
    }, {
      id: 9,
      label: '发布并启用',
      type: 'primary',
      onclick: () => {
        console.log('发布并启用');
      }
    }];
    const buttons = (0, _vue.computed)(() => {
      let ids = [];
      switch (modelInfo.step) {
        case 1:
          ids = [1, 3];
          break;
        case 2:
          ids = [1, 2, 4];
          break;
        case 3:
          ids = [1, 5, 6, 7];
          break;
        case 4:
          ids = [1, 8, 9];
      }
      return ids.map(id => BUTTONS.find(button => button.id === id));
    });
    function addStep() {
      setModelInfo({
        step: modelInfo.step + 1
      });
    }
    function minusStep() {
      setModelInfo({
        step: modelInfo.step - 1
      });
    }
    function goStep(step) {
      setModelInfo({
        step
      });
    }
    return {
      __sfc: true,
      modelInfo,
      setModelInfo,
      BUTTONS,
      buttons,
      addStep,
      minusStep,
      goStep
    };
  }
};
exports.default = _default;