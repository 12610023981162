"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "base-media-form"
  }, [_c("dynamic-form", {
    ref: "dynamic-form",
    attrs: {
      desc: _vm.desc,
      "label-position": "top"
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c("contact-form", {
    ref: "contact-form",
    model: {
      value: _vm.contact,
      callback: function ($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }), _c("div", {
    staticClass: "button-row"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.hasfile,
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("上传")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;