"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'KeyEventDisplay',
  props: {
    relation: {
      type: String,
      default: 'and'
    },
    selectList: {
      type: Array,
      default: () => []
    },
    keyValue: {
      type: String,
      default: ''
    },
    hasOrder: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    accountLabelArray: {
      type: Array,
      default: () => []
    },
    noSeparator: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getSeparator() {
      if (this.noSeparator) {
        return '';
      }
      if (!this.hasOrder) {
        return this.relation === 'and' ? '且' : '或';
      } else {
        return '→';
      }
    }
  },
  methods: {
    getSpeaker(role) {
      return {
        default: '全部',
        host_salesman: '销售说的',
        customer_contact: '客户说的'
      }[role];
    },
    getAccountLabelName(id) {
      var _this$accountLabelArr;
      return this.accountLabelArray.length && ((_this$accountLabelArr = this.accountLabelArray.find(item => item.id === id)) === null || _this$accountLabelArr === void 0 ? void 0 : _this$accountLabelArr.name);
    }
  }
};
exports.default = _default;