import { render, staticRenderFns } from "./ResignedMemberTable.vue?vue&type=template&id=ca2b0470&scoped=true&"
import script from "./ResignedMemberTable.vue?vue&type=script&lang=js&"
export * from "./ResignedMemberTable.vue?vue&type=script&lang=js&"
import style0 from "./ResignedMemberTable.vue?vue&type=style&index=0&id=ca2b0470&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca2b0470",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ca2b0470')) {
      api.createRecord('ca2b0470', component.options)
    } else {
      api.reload('ca2b0470', component.options)
    }
    module.hot.accept("./ResignedMemberTable.vue?vue&type=template&id=ca2b0470&scoped=true&", function () {
      api.rerender('ca2b0470', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/resignedMember/components/ResignedMemberTable.vue"
export default component.exports