"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _updatePassword = _interopRequireDefault(require("@/views/updatePassword"));
var _BindTelOrEmailDialog = _interopRequireDefault(require("./components/BindTelOrEmailDialog.vue"));
var _useVuex = require("@/hooks/use-vuex");
var _accountInfo = require("./hooks/accountInfo.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'accountInfo',
  setup(__props) {
    const store = (0, _useVuex.useStore)();
    const isTwoFactor = (0, _vue.computed)(() => store.getters.isTwoFactor);

    // 绑定手机号 or 邮箱
    const {
      bindTelOrEmailDialogVisible,
      who,
      operate,
      openBindTelOrEmailDialog
    } = (0, _accountInfo.useBindTelOrEmailDialog)();

    // 绑定 or 修改密码
    const {
      updatePasswordDialogVisible,
      openUpdatePasswordDialog,
      closeUpdatePasswordDialog
    } = (0, _accountInfo.useUpdatePasswordDialog)();

    // 检查是否设置密码
    const {
      isSetPassword,
      checkIsSetPasswordApi
    } = (0, _accountInfo.useCheckSetPassword)();

    // 获取页面基本信息
    const {
      ENV_TITLE,
      accountInfo,
      getAccountInfoApi
    } = (0, _accountInfo.useGetAccountInfo)();
    checkIsSetPasswordApi();
    getAccountInfoApi();
    const getLatestData = () => {
      getAccountInfoApi();
      store.dispatch('user/getInfo');
    };
    const updatePasswordType = (0, _vue.computed)(() => isTwoFactor.value ? 'change' : isSetPassword.value ? 'change' : 'set');
    return {
      __sfc: true,
      store,
      isTwoFactor,
      bindTelOrEmailDialogVisible,
      who,
      operate,
      openBindTelOrEmailDialog,
      updatePasswordDialogVisible,
      openUpdatePasswordDialog,
      closeUpdatePasswordDialog,
      isSetPassword,
      checkIsSetPasswordApi,
      ENV_TITLE,
      accountInfo,
      getAccountInfoApi,
      getLatestData,
      updatePasswordType,
      UpdatePasswordDialog: _updatePassword.default,
      BindTelOrEmailDialog: _BindTelOrEmailDialog.default
    };
  }
};
exports.default = _default;