"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CumulativeScoreRule',
  props: {
    scoreItem: {
      type: Object,
      default: () => ({})
    },
    scoreMode: {
      type: String,
      default: 'increase'
    },
    scoreExtItems: {
      type: Array,
      default: () => []
    },
    totalScore: {
      type: Number,
      default: 0
    }
  },
  methods: {
    isValidError(item) {
      if (!(item.gte && item.lte)) return false;
      if (item.gte > item.lte) {
        return true;
      } else {
        return false;
      }
    }
  }
};
exports.default = _default;