"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-upload", {
    ref: "uploadMedia123",
    staticClass: "upload-demo",
    attrs: {
      action: "#",
      drag: _vm.isDrag,
      "http-request": _vm.uploadNewMedia,
      multiple: "",
      limit: 100,
      "show-file-list": false,
      "file-list": _vm.fileList,
      "before-upload": _vm.beforeFileUpload,
      "on-progress": _vm.onProgress,
      "on-success": _vm.onSuccess,
      "on-error": _vm.onError,
      "on-exceed": _vm.onExeed
    }
  }, [_vm.isDrag ? _c("i", {
    staticClass: "el-icon-upload"
  }) : _vm._e(), _vm.isDrag ? _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" 将文件拖到此处，或"), _c("em", [_vm._v("点击上传")])]) : _vm._e(), !_vm.isDrag ? _c("el-button", {
    attrs: {
      size: "small",
      type: "text"
    }
  }, [_vm._v("继续添加")]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;