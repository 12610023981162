"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _PromptTemplateLibraryFilters = _interopRequireDefault(require("./PromptTemplateLibraryFilters.vue"));
var _useMessage = require("@/hooks/use-message");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'PromptTemplateDialog',
  props: {
    visible: Boolean
  },
  emits: ['update:visible', 'usePromptTemplate'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const usePromptTemplate = prompt => {
      emit('usePromptTemplate', prompt.content);
      updateVisible();
    };
    const params = (0, _vue.ref)({
      name: '',
      content: '',
      create_by: {},
      page: 0,
      size: 10
    });
    const loading = (0, _vue.ref)(false);
    const hasMore = (0, _vue.ref)(true);
    const promptsList = (0, _vue.ref)([]);
    const initGetData = () => {
      hasMore.value = true;
      params.value.page = 0;
      promptsList.value = [];
      getPromptTemplateListApi();
    };
    const filterChange = val => {
      params.value = {
        ...params.value,
        ...val
      };
      initGetData();
    };
    const onCopy = () => {
      _useMessage.useMessage.success('复制成功');
    };
    async function getPromptTemplateListApi() {
      if (!hasMore.value) return;
      params.value.page++;
      loading.value = true;
      const res = await (0, _rapidCreateModel.getPromptTemplateList)(params.value).finally(() => loading.value = false);
      if (res.code === 20000) {
        const {
          total,
          prompts
        } = res.results;
        promptsList.value = promptsList.value.concat(prompts || []);
        hasMore.value = total > promptsList.value.length;
      }
    }
    const deletePromptTemplateApi = async prompt => {
      const res = await (0, _rapidCreateModel.deletePromptTemplate)({
        id: prompt.id
      });
      if (res.code === 20000) {
        _useMessage.useMessage.success('删除成功');
        promptsList.value = promptsList.value.filter(item => item.id !== prompt.id);
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      usePromptTemplate,
      params,
      loading,
      hasMore,
      promptsList,
      initGetData,
      filterChange,
      onCopy,
      getPromptTemplateListApi,
      deletePromptTemplateApi,
      PromptTemplateLibraryFilters: _PromptTemplateLibraryFilters.default
    };
  }
};
exports.default = _default;