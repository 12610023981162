"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trade-set"
  }, [_c("div", {
    staticClass: "trade-set-left"
  }, [_c("div", {
    staticClass: "page-title"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: _vm.handleBackTrade
    }
  }), _c("span", [_vm._v("交易设置")])]), _c("el-menu", {
    attrs: {
      router: "",
      "default-active": _vm.$route.path
    }
  }, _vm._l(_vm.menuList, function (_ref) {
    let {
      path,
      link,
      meta: {
        subtitle,
        icon
      }
    } = _ref;
    return _c("el-menu-item", {
      key: path,
      class: {
        "is-active": _vm.$route.path.includes(link)
      },
      attrs: {
        index: link
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("i", {
      class: ["iconfont", `icon-${icon}`]
    }), _c("span", {
      staticClass: "menu-title"
    }, [_vm._v(_vm._s(subtitle))])])], 2);
  }), 1)], 1), _c("router-view")], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;