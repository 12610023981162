"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'ConversationInfoTitle',
  emits: ['openModifyConvInfoHistoryDrawer'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const openModifyConvInfoHistoryDrawer = () => {
      emit('openModifyConvInfoHistoryDrawer');
    };
    return {
      __sfc: true,
      emit,
      openModifyConvInfoHistoryDrawer
    };
  }
};
exports.default = _default;