"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", [_c("div", {
    staticClass: "desc-wrapper"
  }, [_vm.desc ? _c("span", {
    staticClass: "desc"
  }, [_vm._v(_vm._s(_vm.desc))]) : _vm._e(), !_vm.noCount ? _c("div", {
    staticClass: "plain-row"
  }, [_vm._v(" " + _vm._s(_vm.count) + "个字段，按展示顺序排列 ")]) : _vm._e(), _vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;