"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mediaUpload = require("@/api/mediaUpload");
var _elementUi = require("element-ui");
var _download = require("@/utils/download");
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _i18n = require("@/i18n");
var _default = {
  __name: 'ImportFile',
  props: {
    downloadTemplateAddress: {
      type: String,
      default: ''
    },
    uploadRequestFunc: {
      type: Function
    }
  },
  emits: ['importComplete'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const router = (0, _useRouter.useRouter)();
    const route = (0, _useRouter.useRoute)();
    const status = (0, _vue.ref)('');
    const currentStatusContent = (0, _vue.computed)(() => {
      const options = {
        importing: {
          icon: 'iconfont icon-refresh',
          text: `${(0, _i18n.t)('management.正在导入')}...`,
          tip: (0, _i18n.t)('management.请不要刷新或离开，同时保持网络畅通')
        },
        'upload-fail': {
          icon: 'iconfont icon-error-red',
          text: (0, _i18n.t)('management.上传失败'),
          tip: (0, _i18n.t)('management.上传失败，请重新上传'),
          reload: (0, _i18n.t)('management.重新上传')
        }
      };
      return options[status.value];
    });
    const downloadTemplateExcel = () => {
      (0, _download.downloadByUrl)(props.downloadTemplateAddress);
    };
    const fileInfo = (0, _vue.ref)({});
    const beforeFileUpload = file => {
      if (file.status !== 'ready') return;
      if (/\.(?:xlsx|xls)$/.test(file.name)) {
        fileInfo.value = file;
      } else {
        _elementUi.Message.warning((0, _i18n.t)('management.请选择xlsx或xls格式的文件'));
      }
    };
    let uploadRequestController = null;
    const uploadRateLabel = (0, _vue.ref)('');
    const isCompleteUpload = (0, _vue.ref)(true);
    const loading = (0, _vue.ref)(false);
    const uploadFile = async () => {
      if (!fileInfo.value.raw) return;
      status.value = 'importing';
      loading.value = true;
      uploadRequestController = new AbortController();
      const signal = uploadRequestController.signal;
      try {
        isCompleteUpload.value = false;
        const uploadFuncApi = props.uploadRequestFunc;
        console.log(fileInfo.value);
        const res = await uploadFuncApi({
          file: fileInfo.value.raw
        }, {
          timeout: 0,
          signal
        });
        if (res.code === 20000) {
          uploadRateLabel.value = `${(0, _i18n.t)('management.解析中')}...`;
          getImportResults(res.results.task_id);
        }
        isCompleteUpload.value = true;
      } catch (error) {
        isCompleteUpload.value = true;
        if (!uploadRequestController.signal.aborted) {
          handleUploadFail(error.message);
        }
      }
    };

    // 轮询导入结果接口
    let getImportResultsTimer = null;
    const importResult = (0, _vue.ref)({});
    const getImportResults = task_id => {
      getImportResultsTimer = setInterval(async () => {
        const importRes = await (0, _mediaUpload.getBatchUpload)({
          task_id
        });
        if (importRes.code === 200) {
          const {
            fail_count,
            success_count,
            total,
            failed_datas
          } = importRes.results;
          importResult.value = {
            total,
            success_count,
            fail_count,
            failed_datas
          };
          if (total && fail_count + success_count === total) {
            loading.value = false;
            emit('importComplete', importResult.value);
            clearInterval(getImportResultsTimer);
          }
        }
      }, 2000);
    };
    const handleUploadFail = msg => {
      status.value = 'upload-fail';
      uploadRateLabel.value = msg || (0, _i18n.t)('management.上传失败');
    };
    const abortUpload = callback => {
      _elementUi.MessageBox.confirm((0, _i18n.t)('management.上传尚未完成。要取消正在进行的上传吗？'), (0, _i18n.t)('management.取消上传'), {
        confirmButtonText: (0, _i18n.t)('management.取消上传'),
        cancelButtonText: (0, _i18n.t)('management.继续上传'),
        type: 'warning'
      }).then(() => {
        if (!isCompleteUpload.value) {
          uploadRequestController.abort();
          (0, _elementUi.Message)({
            type: 'success',
            message: (0, _i18n.t)('management.已取消上传')
          });
        }
        initImportFile();
        callback && callback();
      });
    };
    function initImportFile() {
      status.value = '';
      fileInfo.value = {};
      uploadRateLabel.value = '';
      getImportResultsTimer && clearInterval(getImportResultsTimer);
    }
    const goBack = () => {
      initImportFile();
      router.go(-1);
    };
    expose({
      initImportFile
    });
    return {
      __sfc: true,
      props,
      emit,
      router,
      route,
      status,
      currentStatusContent,
      downloadTemplateExcel,
      fileInfo,
      beforeFileUpload,
      uploadRequestController,
      uploadRateLabel,
      isCompleteUpload,
      loading,
      uploadFile,
      getImportResultsTimer,
      importResult,
      getImportResults,
      handleUploadFail,
      abortUpload,
      initImportFile,
      goBack,
      t: _i18n.t
    };
  }
};
exports.default = _default;