"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _timeFormatter = require("@/utils/time-formatter");
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  mixins: [_chartMixins.default],
  props: {
    isCount: Boolean
  },
  methods: {
    render() {
      this.chart = echarts.init(this.$refs.lineChart);
      const _this = this;
      const option = {
        title: {
          text: this.title,
          textStyle: {
            color: '#29304A',
            fontSize: 16,
            height: 21,
            lineHeight: 21,
            fontWeight: 600
          },
          itemGap: 10,
          subtext: this.subTitle,
          subtextStyle: {
            color: '#8E95B0',
            fontSize: 13,
            height: 18,
            lineheight: 18,
            fontWeight: 'normal'
          }
        },
        grid: {
          containLabel: true,
          left: 20,
          right: 20,
          top: 80,
          height: 220
        },
        xAxis: {
          type: 'category',
          show: !!this.chartValue.length,
          boundaryGap: false,
          // 让 X轴从0开始
          data: this.xAxisData,
          // name: this.$moment(this.month).format('MMM'),
          nameLocation: 'center',
          nameGap: 33,
          nameTextStyle: {
            color: '#29304A',
            lineheight: 17,
            fontSize: 12
          },
          axisLabel: {
            // X轴刻度标签
            show: true,
            interval: 'auto',
            formatter: '{value}'
          },
          axisTick: {
            // X轴刻度
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: true,
          gridIndex: 0,
          nameTextStyle: {
            color: '#8E95B0',
            fontSize: 12
          },
          minInterval: 1,
          splitNumber: 5,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: val => _this.getYAxisLabel(val)
          }
        },
        series: [{
          data: this.chartValue,
          type: 'line',
          // showSymbol: false,
          symbol: 'emptyCircle',
          // 标记的图形
          lineStyle: {
            // 线的属性
            color: '#3393FF'
          },
          symbolSize: [8, 8],
          // 标记的大小
          itemStyle: {
            // 折线拐点标志的样式
            color: '#3393FF'
          },
          label: {
            show: false,
            position: 'top',
            formatter: params => {
              return _this.getYAxisLabel(params.value);
            }
          }
        }],
        tooltip: {
          trigger: 'axis',
          // 触发类型
          alwaysShowContent: false,
          borderRadius: 8,
          // 边框圆角
          hideDelay: 50,
          // 隐藏延时
          appendToBody: true,
          formatter: function (params) {
            // 自定义数据和样式
            const date = params[0].name;
            const value = params[0].value;
            const res = `<div class="echart-tooltip-bi-personal">
              <p class="echart-tooltip-header">${date}</p>
              <p class="echart-tooltip-body">${_this.title}</p>
              <p class="echart-tooltip-footer">
                ${_this.getToolTipHtml(value)}
              </p>
            </div>`;
            return res;
          }
        }
      };
      this.chart.setOption(option);
    },
    getToolTipHtml(value) {
      switch (this.dataType) {
        case 'time':
          return this.getTimeHtml(value);
        case 'percent':
          return `<span>${value}</span><span>%</span>`;
        default:
          return `<span>${value}</span><span>次</span>`;
      }
    },
    getTimeHtml(value) {
      return (0, _timeFormatter.secondToCNTimeArr)(value).reduce((str, el) => str + `<span>${el}</span>`, '');
    },
    getYAxisLabel(val) {
      switch (this.dataType) {
        case 'time':
          return (0, _timeFormatter.secondToCNTime)(val);
        case 'percent':
          return `${val}%`;
        default:
          return `${val}次`;
      }
    }
  }
};
exports.default = _default;