"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-container-box"
  }, [_c("div", {
    staticClass: "card-container-body"
  }, [_c("MgDraggable", {
    attrs: {
      disabled: _vm.isAssign
    },
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.localCardList,
      callback: function ($$v) {
        _vm.localCardList = $$v;
      },
      expression: "localCardList"
    }
  }, _vm._l(_vm.localCardList, function (_ref, index) {
    let {
      card_id,
      name,
      type,
      description,
      cardConfig,
      configs,
      filter_configs,
      enable_warning,
      loading
    } = _ref;
    return _c("div", {
      key: card_id,
      ref: `card-${index}`,
      refInFor: true,
      staticClass: "card-item",
      class: {
        "checked-card-item": _vm.isCheckedCardItem(card_id),
        "hover-border-color-change": _vm.isShowCheckbox
      },
      on: {
        click: function ($event) {
          return _vm.checkedCard(card_id);
        }
      }
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowCheckbox,
        expression: "isShowCheckbox"
      }],
      staticClass: "card-item-title-checkbox"
    }, [_c("el-checkbox", {
      attrs: {
        value: _vm.isCheckedCardItem(card_id)
      },
      on: {
        change: function ($event) {
          return _vm.checkedCard(card_id);
        }
      }
    }), _c("span", [_vm._v(_vm._s(name))])], 1), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isShowCheckbox,
        expression: "!isShowCheckbox"
      }]
    }, [_c("div", {
      staticClass: "card-item-title"
    }, [_c("span", [!_vm.isAssign ? _c("i", {
      staticClass: "iconfont icon-drag-dot move"
    }) : _vm._e(), _c("span", [_vm._v(_vm._s(name))])]), _c("div", [type !== "Digit" && ["hour", "day", "week", "month"].includes(configs.dimension) ? _c("el-select", {
      staticClass: "dimension-select",
      attrs: {
        value: configs.dimension,
        "popper-class": "bi-overview-dimension-select-options"
      },
      on: {
        change: $event => _vm.$emit("dimensionChange", index, $event)
      }
    }, [_c("template", {
      slot: "prefix"
    }, [_vm._v(_vm._s(_vm.getSelectValue(index)))]), _vm._l(_vm.dimensionOptions, function (_ref2) {
      let {
        label,
        value
      } = _ref2;
      return _c("el-option", {
        key: value,
        attrs: {
          label: label,
          value: value
        }
      });
    })], 2) : _vm._e(), type === "Digit" && !_vm.isAssign ? _c("el-tooltip", {
      attrs: {
        content: "预警设置",
        placement: "top"
      }
    }, [_c("i", {
      class: ["alarm-btn", "iconfont", _vm.isSubscribe && enable_warning ? "icon-alarm-fill" : "icon-alarm"],
      on: {
        click: function ($event) {
          return _vm.$emit("handleForewarn", card_id);
        }
      }
    })]) : _vm._e(), _c("fullScreenBtn", {
      attrs: {
        "full-screen-container": _vm.$refs[`card-${index}`]
      },
      on: {
        toggleFullScreen: isFullScreen => _vm.$emit("toggleFullScreen", isFullScreen, index)
      }
    }), !_vm.isAssign ? _c("el-dropdown", {
      attrs: {
        trigger: "click",
        placement: "bottom-start"
      },
      on: {
        command: command => _vm.$emit("handleCommand", command, card_id)
      }
    }, [_c("el-button", {
      attrs: {
        icon: "el-icon-more"
      }
    }), _c("el-dropdown-menu", {
      staticClass: "dropdown",
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, [_c("el-dropdown-item", {
      attrs: {
        command: "edit"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-edit"
    }), _vm._v(" 编辑 ")]), _c("el-dropdown-item", {
      attrs: {
        command: "delete"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-delete"
    }), _vm._v(" 删除 ")])], 1)], 1) : _vm._e()], 1)]), _c("ShowFiltersDetail", {
      attrs: {
        "custom-filter-array": _vm.customFilterArray,
        "filters-detail": filter_configs
      }
    }), _c("p", {
      staticClass: "card-item-description"
    }, [_vm._v(_vm._s(description))])], 1), _c("div", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: loading,
        expression: "loading"
      }]
    }, [type === "Digit" ? _c("CardText", {
      attrs: {
        "card-config": cardConfig,
        "unit-config": configs
      }
    }) : _c("chart-template", {
      attrs: {
        "chart-config": cardConfig,
        "renderer-type": "svg"
      }
    })], 1)]);
  }), 0)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;