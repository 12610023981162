"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AddFolderDropdown = _interopRequireDefault(require("./AddFolderDropdown.vue"));
var _SnippetsLibrarySearch = _interopRequireDefault(require("./SnippetsLibrarySearch.vue"));
var _library = require("@/api/library");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    AddFolderDropdown: _AddFolderDropdown.default,
    SnippetsLibrarySearch: _SnippetsLibrarySearch.default
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: String
  },
  data() {
    return {
      operationList: [{
        label: '我的收藏',
        type: 'collect',
        icon: 'icon-star-off'
      }, {
        label: '我的学习',
        type: 'task',
        icon: 'icon-reading-2'
      }, {
        label: '排行榜',
        type: 'ranking',
        icon: 'icon-ranking'
      }],
      taskCount: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['webSocketMsg', 'workspaceInfo'])
  },
  watch: {
    webSocketMsg: {
      handler: function (newVal) {
        // 我的剪辑库学习任务
        if (newVal.type === 6 && newVal.sub_type === 12) {
          this.getLibraryStudyCount();
        }
      },
      deep: true
    }
  },
  created() {
    this.getLibraryStudyCount();
  },
  methods: {
    changeType(type) {
      this.$emit('input', type);
    },
    // 获取我的学习-待学习的个数
    async getLibraryStudyCount() {
      const res = await (0, _library.getLibraryStudyCount)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.taskCount = res.results.count;
      }
    }
  }
};
exports.default = _default;