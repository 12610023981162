"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "brief-report-detail"
  }, [_c("header", {
    staticClass: "detail-header"
  }, [_c("div", {
    staticClass: "left-element"
  }, [_c("el-button", {
    staticClass: "no-border",
    attrs: {
      icon: "el-icon-arrow-left",
      size: "mini",
      plain: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v(" 返回 ")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", {
    staticClass: "report-title"
  }, [_vm._v(_vm._s(_vm.detailInfo.title))])], 1), _c("div", {
    staticClass: "right-element"
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-back",
      size: "mini",
      plain: "",
      disabled: !_vm.hasPrev
    },
    on: {
      click: function ($event) {
        return _vm.getDetailInfo(-1);
      }
    }
  }, [_vm._v("上一封")]), _c("el-button", {
    staticClass: "next-page-btn",
    attrs: {
      size: "mini",
      plain: "",
      disabled: !_vm.hasNext
    },
    on: {
      click: function ($event) {
        return _vm.getDetailInfo(1);
      }
    }
  }, [_vm._v("下一封"), _c("i", {
    staticClass: "el-icon-right el-icon--right"
  })])], 1)]), _c("div", {
    staticClass: "detail-content"
  }, [_c("div", {
    staticClass: "data-range"
  }, [_c("span", {
    staticClass: "data-range-label"
  }, [_vm._v("数据来源：")]), _vm.detailInfo.space.space_name ? _c("span", {
    staticClass: "space"
  }, [_c("i", {
    class: _vm.workspaceIconClass,
    style: `color:${_vm.detailInfo.space.icon_color}`
  }), _c("span", {
    staticClass: "space-name"
  }, [_vm._v(_vm._s(_vm.detailInfo.space.space_name))]), _c("span", {
    staticClass: "space-divide"
  }, [_vm._v("/")])]) : _vm._e(), _c("span", {
    staticClass: "data-range-value"
  }, [_vm._v(_vm._s(_vm.detailInfo.data_range))])]), _vm.detailInfo.data_warning && _vm.detailInfo.data_warning.length ? _c("div", {
    staticClass: "data-warning"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailInfo.data_warning.length,
      expression: "detailInfo.data_warning.length"
    }],
    staticClass: "warning-total"
  }, [_vm._v(" 预警总数 （" + _vm._s(_vm.detailInfo.data_warning.length) + "） ")]), _c("div", {
    staticClass: "warning-item-container"
  }, _vm._l(_vm.detailInfo.data_warning, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "warning-item"
    }, [_c("i", {
      staticClass: "warning-icon iconfont icon-alarm-fill"
    }), _c("div", {
      staticClass: "warning-title"
    }, [_c("span", {
      staticClass: "warning-content"
    }, [_vm._v("【 " + _vm._s(item.time_range) + " 】")]), _c("span", {
      staticClass: "warning-content target-name"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "warning-content"
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0)]) : _vm._e(), _c("div", {
    staticClass: "data-table-container"
  }, _vm._l(_vm.getDigitsData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail-card-text"
    }, [_c("div", {
      staticClass: "chart-title"
    }, [_vm._v(_vm._s(item.name))]), _c("card-text", {
      attrs: {
        "card-config": item,
        "unit-config": item.configs
      }
    })], 1);
  }), 0), _c("report-data-chart", {
    attrs: {
      "render-info": _vm.getOtherData
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;