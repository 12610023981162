"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MeetingForm = _interopRequireDefault(require("@/components/MeetingForm"));
var _tencent_meeting = _interopRequireDefault(require("@/assets/tencent_meeting.svg"));
var _CopyUrl = _interopRequireDefault(require("@/components/CopyUrl"));
var _clues = require("@/api/clues");
var _business = require("@/api/business");
var _client = require("@/api/client");
var _vuex = require("vuex");
var _InviteSucceedDialog = _interopRequireDefault(require("@/components/InviteSucceedDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MeetingDialog',
  components: {
    MeetingForm: _MeetingForm.default,
    CopyUrl: _CopyUrl.default,
    InviteSucceedDialog: _InviteSucceedDialog.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '安排在线会议'
    },
    businessId: {
      type: Number,
      default: 0
    },
    accountId: {
      type: Number,
      default: 0
    },
    cluesId: {
      type: Number,
      default: 0
    },
    customerName: {
      type: String,
      default: ''
    },
    // 预约、编辑
    contactOptions: {
      type: Array,
      default: () => null
    },
    // 预约、编辑
    contactId: {
      type: Number,
      default: 0
    },
    // 预约、编辑
    conversationId: {
      type: Number,
      default: 0
    },
    // 编辑
    type: {
      type: String,
      default: 'deal'
    },
    // 发邮件需要
    contactName: {
      type: String,
      default: ''
    },
    contactTel: {
      type: String,
      default: null
    },
    contactEmail: {
      type: String,
      default: ''
    },
    spectatorInfos: {
      type: Array,
      default: () => []
    },
    assistantInfos: {
      type: Array,
      default: () => []
    },
    hostId: {
      type: Number,
      default: null
    },
    hostName: {
      type: String,
      default: ''
    },
    beginTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    meetingType: {
      type: String,
      default: ''
    },
    isBase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        account_id: '',
        deal_id: '',
        lead_id: '',
        customer_name: '',
        meeting_name: '',
        contact_id: 0,
        begin_time: '',
        begin_date: '',
        end_time: '',
        end_date: '',
        time: '',
        host_salesman_id: 1,
        assistant_salesman_ids: [],
        spectator_salesman_ids: []
      },
      copyUrlVisible: false,
      copyUrlProps: {},
      inviteText: '',
      submitBtnLoading: false,
      requestContactOptions: [],
      canSubmit: true,
      tencent_meeting: _tencent_meeting.default,
      inviteSucceedDialogVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'name', 'orgName'
    // 'inter_tel'
    ]),

    formContactOptions() {
      if (this.$props.title === '安排在线会议') {
        return this.contactOptions;
      } else {
        return this.requestContactOptions;
      }
    },
    meetingName() {
      // 线索会议名称
      let _c = '';
      if (!this.customerName) {
        if (!this.contactName) {
          _c += this.contactTel ? this.contactTel : this.contactEmail;
        } else {
          _c += this.contactName;
        }
      } else {
        _c += this.customerName;
        if (this.contactName) {
          _c = _c + ' ' + this.contactName;
        }
      }
      return this.orgName + ' 与 ' + _c + ' 的在线视频会议';
    },
    clueContact() {
      // 线索联系人
      let _c = '';
      _c += this.contactName ? this.contactName : '未知姓名';
      _c += this.contactTel ? '（' + this.contactTel + '）' : '（' + this.contactEmail + '）';
      return _c;
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.setInitForm();
        const timer = setInterval(() => {
          if (this.$refs['meeting-form']) {
            this.$refs['meeting-form'].checkDateConfliction();
            clearInterval(timer);
          }
        }, 300);
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    submitForm() {
      const form = this.$refs['meeting-form'].$refs['meeting-form'];
      form.validate(valid => {
        if (valid) {
          switch (this.$props.title) {
            case '安排在线会议':
              this.createMeeting();
              break;
            case '修改在线会议':
              this.editMeeting();
              break;
          }
        }
      });
    },
    resetForm() {
      this.$refs['meeting-form'].$refs['meeting-form'].resetFields();
    },
    setInitForm() {
      switch (this.$props.title) {
        case '安排在线会议':
          this.setInitFormCreat();
          break;
        case '修改在线会议':
          this.setInitFormEdit();
          break;
      }
    },
    getRecentDateTime() {
      // 获取离当前时间最近的半整点
      const now = this.$moment();
      const remainder = 30 - now.minute() % 30;
      const time = this.$moment(now).add(remainder, 'minutes');
      return time;
    },
    setInitFormCreat() {
      const {
        contactId,
        contactOptions
      } = this.$props;
      this.form.customer_name = this.customerName ? this.customerName : '未知公司';
      this.form.contact_name = this.clueContact;
      this.form.contact_id = contactId;
      this.form.contactOptions = contactOptions;
      this.form.meeting_name = this.meetingName;
      this.form.host_salesman_id = this.userId;
      const _recent_date_time = this.getRecentDateTime();
      this.form.begin_date = _recent_date_time.format('YYYY-MM-DD');
      this.form.begin_time = _recent_date_time.format('HH:mm');
      const _end_time = this.$moment(_recent_date_time).add(30, 'minutes');
      this.form.end_date = _end_time.format('YYYY-MM-DD');
      this.form.end_time = _end_time.format('HH:mm');
      this.$bus.$emit('setMembersList', [], []);
    },
    getIds(arr) {
      const res = [];
      for (const item of arr) {
        res.push(item.id);
      }
      return res;
    },
    async setInitFormEdit() {
      const spectator_salesman_ids = this.getIds(this.spectatorInfos);
      this.form = {
        conversation_id: this.$props.conversationId,
        customer_name: this.customerName,
        contact_name: this.clueContact,
        contact_id: this.contactId,
        host_salesman_id: this.hostId,
        host_salesman_name: this.hostName,
        meeting_name: this.meetingName,
        spectator_salesman_ids,
        begin_date: this.$moment(this.beginTime).format('YYYY-MM-DD'),
        begin_time: this.$moment(this.beginTime).format('HH:mm'),
        end_date: this.$moment(this.endTime).format('YYYY-MM-DD'),
        end_time: this.$moment(this.endTime).format('HH:mm'),
        assistantInfos: this.$props.assistantInfos,
        spectatorInfos: this.$props.spectatorInfos
      };
      this.requestContactOptions = this.contactOptions;
    },
    // 处理表单之后再发请求
    handleForm() {
      const _form = {
        customer_name: this.form.customer_name === '未知公司' ? '' : this.form.customer_name,
        meeting_name: this.form.meeting_name,
        contact_id: this.form.contact_id,
        host_salesman_id: this.form.host_salesman_id,
        assistant_salesman_ids: this.form.assistant_salesman_ids || [],
        spectator_salesman_ids: this.form.spectator_salesman_ids || [],
        begin_time: this.$currentZoneToPlus8(this.form.begin_date + ' ' + this.form.begin_time).format('YYYY-MM-DD HH:mm'),
        end_time: this.$currentZoneToPlus8(this.form.end_date + ' ' + this.form.end_time).format('YYYY-MM-DD HH:mm'),
        meeting_type: this.meetingType
      };
      return _form;
    },
    async createMeeting() {
      this.submitBtnLoading = true;
      const form = this.handleForm();
      const {
        businessId,
        cluesId,
        accountId
      } = this.$props;
      let createMeeting = null;
      if (businessId) {
        form.deal_id = businessId;
        createMeeting = _business.businessCreateMeeting;
      } else if (cluesId) {
        form.lead_id = cluesId;
        createMeeting = _clues.clueCreateMeeting;
      } else {
        form.account_id = accountId;
        createMeeting = _client.accountCreateMeeting;
      }
      try {
        const res = await createMeeting(form);
        this.handleCopyUrlParams(res.results, form);
        if (res.code === 200) {
          this.submitBtnLoading = false;
          this.$emit('submitSucceed');
          this.closeDialog();
        }
      } catch (error) {
        this.submitBtnLoading = false;
      }
    },
    handleCopyUrlParams(params, form) {
      if (params.meeting_type === 'tencent_meeting') {
        const {
          meeting_code,
          meeting_name,
          meeting_url,
          interviewer_name,
          meeting_time_duration
        } = params;
        this.inviteSucceedDialogVisible = true;
        this.inviteText = `
${interviewer_name} 邀请您参加腾讯会议
会议主题：${meeting_name}
会议时间：${meeting_time_duration}

点击链接入会，或添加至会议列表：
${meeting_url}

#腾讯会议：${meeting_code}
`;
        return;
      }
      this.copyUrlVisible = true;
      // 如遇问题，请联系${params.interviewer_name} ${params.interviewer_tel}`
      const _inviteText = this.contactUrl(params.candidate_code);
      this.copyUrlProps = {
        conversationType: this.$props.type,
        candUrl: this.contactUrl(params.candidate_code, 'customer'),
        inteUrl: this.contactUrl(params.interviewer_code, 'sale'),
        candQr: params.candidate_qrcode,
        inteQr: params.interviewer_qrcode,
        conversationId: params.conversation_id,
        inviteText: _inviteText
      };
    },
    selectedLinkName() {
      let name = '';
      if (this.form.contact_id && this.contactOptions) {
        const itemObj = this.contactOptions.find(item => {
          return item.id === this.form.contact_id;
        });
        name = itemObj ? itemObj.name : '';
      }
      return name;
    },
    contactUrl(code, type) {
      return `${window.location.protocol}//${window.location.host}/${type === 'sale' ? 'vri' : 'vrc'}?c=${code}`;
    },
    async editMeeting() {
      this.submitBtnLoading = true;
      const form = this.handleForm();
      form.conversation_id = this.$props.conversationId;
      try {
        const res = await (0, _business.businessModifyMeeting)(form);
        this.handleCopyUrlParams(res.results, form);
        await res;
        this.submitBtnLoading = false;
        this.$emit('submitSucceed');
        this.closeDialog();
      } catch (error) {
        this.submitBtnLoading = false;
      }
    },
    closeUrlDialog() {
      this.copyUrlVisible = false;
    },
    changeSubmitStatus(canSubmit) {
      this.canSubmit = canSubmit;
    },
    changeMembersList(membersList) {
      this.form.assistant_salesman_ids = membersList;
    }
  }
};
exports.default = _default;