"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "assist-recommend"
  }, [_vm._m(0), _c("div", {
    staticClass: "recommend-part"
  }, [_c("account-recommend")], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "banner"
  }, [_c("div", {
    staticClass: "text-box"
  }, [_c("h5", {
    staticClass: "text-title"
  }, [_vm._v("Hi～我是AI助手麦麦")]), _c("p", {
    staticClass: "text-content"
  }, [_vm._v("很高兴为您服务～")])]), _c("img", {
    staticClass: "background-line",
    attrs: {
      src: require("@/assets/assist/assistant_banner_line.png"),
      alt: "背景图片"
    }
  }), _c("img", {
    staticClass: "img-maimai",
    attrs: {
      src: require("@/assets/assist/assistant_maimai.png"),
      alt: "背景图片"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;