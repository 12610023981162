"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chartMixins = _interopRequireDefault(require("./chartMixins"));
var _fullScreenBtn = _interopRequireDefault(require("@/components/FullScreen/fullScreenBtn"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const basicSeriesObj = {
  type: 'scatter',
  hoverAnimation: false,
  symbolSize: 15,
  itemStyle: {
    normal: {
      borderColor: '#fff'
    }
  }
};
var _default = {
  name: 'ScatterDiagramChart',
  components: {
    fullScreenBtn: _fullScreenBtn.default
  },
  mixins: [_chartMixins.default],
  data() {
    return {
      options: {
        chartName: 'scatter',
        // 表格位置
        backgroundColor: '#fff',
        grid: [{
          left: '15%',
          top: '15%',
          width: '70%',
          height: '70%'
        }],
        // 坐标轴配置
        xAxis: {
          name: '',
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: '14',
            padding: 10
          },
          axisLine: {
            symbol: ['none', 'arrow'],
            symbolOffset: [0, 15]
          }
        },
        yAxis: {
          name: '',
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: '14',
            padding: 25
          },
          axisLine: {
            symbol: ['none', 'arrow'],
            symbolOffset: [0, 15]
          }
        },
        dataset: {
          source: this.data
        },
        legend: {
          type: 'scroll'
        },
        color: ['#3393FF'],
        // 图表数据渲染配置
        series: [],
        // 数据提示配置
        tooltip: {
          showDelay: 0
        }
      },
      chartElement: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId']),
    legendSeries() {
      return this.chartConfig.area ? this.options.series.slice(0, -1) : this.options.series;
    },
    legendData() {
      return this.legendSeries.map(_ref => {
        let {
          name
        } = _ref;
        return name;
      });
    },
    dotColor() {
      return this.legendSeries.map(_ref2 => {
        let {
          dotColor
        } = _ref2;
        return dotColor;
      });
    }
  },
  watch: {
    data: {
      handler(val) {
        this.options.xAxis.show = !!val.length;
        this.options.yAxis.show = !!val.length;
        this.options.dataset.source = val;
        this.init();
      },
      deep: true
    }
  },
  mounted() {
    this.chartElement = this.$el;
    this.chart.on('click', this.handleScatterClick);
  },
  methods: {
    scatterInit() {
      this.options.series = [];
      this.options.tooltip.formatter = this.setTooltip;
      const encode = {
        x: this.data[0].indexOf(this.chartConfig.xAxis.name),
        y: this.data[0].indexOf(this.chartConfig.yAxis.name)
      };
      const areaSeries = {
        ...basicSeriesObj,
        markArea: {
          silent: true,
          data: this.chartConfig.area
        }
      };
      if (this.chartConfig.mapping_config.map_list) {
        this.options.series = this.chartConfig.mapping_config.map_list.map(_ref3 => {
          let {
            name,
            dotColor,
            column_value
          } = _ref3;
          return {
            ...basicSeriesObj,
            name,
            data: this.data.filter(rowItem => rowItem[this.chartConfig.mapping_config.map_column] === column_value),
            encode,
            dotColor,
            // 只针对 244 团队，修改客户评级的散点图大小
            symbolSize: this.orgId === 244 && this.$route.path === _routeMap.ROUTES.biIntentAnalysis ? 10 : 15
          };
        });
        // 在所有点都取消显示时，专门用来显示九宫格的
        areaSeries.data = [];
        if (this.chartConfig.area) {
          this.options.series.push(areaSeries);
        }
        this.options.legend.data = this.legendData;
        this.options.color = this.dotColor;
      } else {
        areaSeries.encode = encode;
        if (this.chartConfig.circle) {
          delete areaSeries.markArea;
        }
        this.options.series.push(areaSeries);
      }
      if (this.chartConfig.circle) {
        this.options.xAxis.splitLine = this.options.yAxis.splitLine = {
          show: false
        };
        this.options.series.push(...this.setArcArea());
      }
    },
    setArcArea() {
      const coordinatesList = this.chartConfig.circle.map(item => item.intercept);
      return this.chartConfig.circle.map((_ref4, index) => {
        var _this$data;
        let {
          intercept,
          color,
          backgroundColor,
          name: formatter,
          label,
          smooth
        } = _ref4;
        const [x, y] = intercept;
        let data = [];
        if (smooth) {
          data = [[-x, 0], [0, y], [x, 0], [0, -y]];
        } else {
          data = [[0, y], [x, y], [x, 0]];
        }
        return {
          type: 'line',
          z: 0,
          smooth: 0.55,
          silent: true,
          symbolSize: 0,
          areaStyle: {
            color: backgroundColor
          },
          endLabel: {
            // 无数据的时候不显示
            show: ((_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.length) > 1,
            offset: this.getLabelOffset(intercept, index, coordinatesList),
            color: label.color,
            formatter
          },
          color,
          data
        };
      });
    },
    getLabelOffset(current, index, coordinatesList) {
      let labeLintercept;
      if (index < coordinatesList.length - 1) {
        labeLintercept = coordinatesList[index + 1][0] + (current[0] - coordinatesList[index + 1][0]) / 2;
      } else {
        labeLintercept = current[0] / 2;
      }
      const endLabelOffsetConstant = coordinatesList.length > 3 ? 0.75 : 0.8;
      labeLintercept *= Math.sin(45 * Math.PI / 180);
      let chartHeight = document.body.clientHeight * endLabelOffsetConstant;
      if (!this.isFullscreen) {
        chartHeight *= endLabelOffsetConstant;
      }
      if (index === 0) {
        return [-(current[0] - labeLintercept) * chartHeight, -labeLintercept * chartHeight];
      } else {
        return [(labeLintercept - 0.01) * chartHeight, -(current[0] + labeLintercept) * chartHeight];
      }
    },
    handleScatterClick(params) {
      const linkObj = this.tableConfigs.column_config.find(item => item.type === 'link');
      const {
        target_column
      } = linkObj;
      const paramsObj = {};
      for (const key in target_column) {
        paramsObj[key] = params.value[target_column[key]];
      }
      const href = `${location.origin}${linkObj.link}?${new URLSearchParams(paramsObj)}`;
      window.open(href, '_blank');
    },
    setTooltip(params) {
      const dimensionNames = this.data[0];
      return this.tableConfigs.column_config.reduce((str, item, index) => {
        const currentValue = params.value[index];
        const mapping = this.tableConfigs.column_config[index].mapping;
        let itemValue;
        // eslint-disable-next-line eqeqeq
        if (mapping) {
          var _mapping$currentValue;
          itemValue = (_mapping$currentValue = mapping[currentValue]) === null || _mapping$currentValue === void 0 ? void 0 : _mapping$currentValue.name;
        } else {
          if (typeof currentValue === 'number') {
            itemValue = parseFloat(parseFloat(currentValue).toFixed(4));
          } else {
            itemValue = currentValue;
          }
        }
        return item.visiable ? `${str}${dimensionNames[index]}：${itemValue}<br />` : str;
      }, '');
    }
  }
};
exports.default = _default;