"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TradeDrawer = _interopRequireDefault(require("./TradeDrawer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SetOptionTradeDrawer',
  components: {
    TradeDrawer: _TradeDrawer.default
  },
  provide() {
    return {
      isInQualityTesting: () => this.isInQualityTesting
    };
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    tipContent: {
      type: String,
      default: ''
    },
    backFillFilters: {
      type: Object,
      default: () => ({})
    },
    onlyTradeList: {
      type: Boolean,
      default: false
    },
    fromScoreRule: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tradeFilters: {},
      filterNum: 0,
      dataPermissionCount: 0
    };
  },
  watch: {
    drawerVisible: {
      handler: function (val) {
        this.filterNum = Object.keys(this.backFillFilters).length;
      }
    }
  },
  methods: {
    handleSave() {
      this.$emit('saveOptionTradeFilters', this.tradeFilters);
      this.handleClose();
    },
    handleClose() {
      this.$emit('update:drawerVisible', false);
    },
    tradeFiltersChange(filters, filterNumber) {
      this.tradeFilters = filters;
      this.filterNum = filterNumber;
    }
  }
};
exports.default = _default;