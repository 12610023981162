"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ShowLongContent',
  props: {
    content: {
      type: String,
      default: ''
    },
    len: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: 'top'
    }
  }
};
exports.default = _default;