"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    options: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      newValue: []
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.newValue = value || [];
      },
      immediate: true
    }
  },
  methods: {
    clickRadio(item) {
      if (item === this.newValue[0]) {
        this.$emit('input', []);
      } else {
        this.$emit('input', [item]);
      }
    }
  }
};
exports.default = _default;