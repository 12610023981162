"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "practice-knowledge"
  }, [_vm.isFinished ? _c("PracticeFinish", {
    attrs: {
      score: _vm.averageScore
    },
    on: {
      goMyTask: _vm.goMyTask,
      goCoachDetail: _vm.goCoachDetail
    }
  }) : _vm._e(), _c("header-title", {
    attrs: {
      knowledge: _vm.knowledge
    },
    on: {
      showAnswerSheet: _vm.showAnswerSheet
    }
  }), _c("div", {
    staticClass: "main"
  }, [_c("knowledge-aside", {
    attrs: {
      knowledge: _vm.knowledge,
      loading: _vm.loading
    }
  }), _c("knowledge-content", {
    attrs: {
      knowledge: _vm.knowledge,
      loading: _vm.loading
    },
    on: {
      "click-jump": _vm.clickJump,
      finish: _vm.handleFinish,
      scoreFinished: _vm.scoreFinished
    }
  }), _c("AnswerSheet", {
    ref: "answerSheet",
    staticClass: "answer-sheet",
    class: {
      "hide-answer-sheet": !_vm.answerSheetVisible
    },
    attrs: {
      visible: _vm.answerSheetVisible,
      knowledge: _vm.knowledge
    },
    on: {
      "update:visible": function ($event) {
        _vm.answerSheetVisible = $event;
      },
      changeKnowledge: _vm.getKnowledge
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;