"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$tabList$;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "my-task"
  }, [_c("ComplianceKanban", {
    staticClass: "compliance-kanban",
    attrs: {
      "tab-list": _vm.tabList,
      "active-tab": (_vm$tabList$ = _vm.tabList[0]) === null || _vm$tabList$ === void 0 ? void 0 : _vm$tabList$.label,
      loading: _vm.loading,
      "show-num": _vm.total[_vm.activeTabIndex]
    },
    on: {
      handleTabClick: _vm.handleTabClick
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function (_ref) {
        var _vm$tabList$_vm$activ;
        let {
          content
        } = _ref;
        return [_vm.tabList.length && content.label === ((_vm$tabList$_vm$activ = _vm.tabList[_vm.activeTabIndex]) === null || _vm$tabList$_vm$activ === void 0 ? void 0 : _vm$tabList$_vm$activ.label) ? [_c("MyTaskContent", {
          ref: "MyTaskContent",
          attrs: {
            "is-total": !_vm.activeTabIndex,
            "table-data": _vm.tableDataList,
            filters: _vm.filtersObj,
            total: _vm.total
          },
          on: {
            handleFiltersChange: _vm.handleFiltersChange,
            goCompliancePersonal: _vm.goCompliancePersonal,
            sizeChange: _vm.sizeChange,
            currentChange: _vm.currentChange,
            "sort-change": _vm.sortChange
          }
        })] : _vm._e()];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;