"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FixTrainDatasetTable = _interopRequireDefault(require("./FixTrainDatasetTable.vue"));
var _vue = require("vue");
var _fixTrainDataset = require("@/views/management/rapidModeling/hooks/fixTrainDataset.js");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'FixTrainDatasetDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    resultsRowInfo: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const dataStatusButtons = [{
      status: 1,
      text: '命中'
    }, {
      status: 2,
      text: '未命中'
    }, {
      status: 3,
      text: '不确定'
    }];
    const activeDatasetTab = (0, _vue.computed)(() => {
      var _annotationList$value;
      return String(((_annotationList$value = annotationList.value[0]) === null || _annotationList$value === void 0 ? void 0 : _annotationList$value.dataset_id) || '');
    });
    const modelInfo = (0, _vue.inject)('modelInfo');
    const hasData = (0, _vue.computed)(() => {
      return annotationList.value.length > 0;
    });
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const refresh = () => {
      getTrainDatasetAnnotationApi(props.resultsRowInfo);
    };

    // watch visible为true时请求数据
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        refresh();
      }
    });
    const tableSelectionList = (0, _vue.ref)([]);
    const tableSelectionChange = val => {
      tableSelectionList.value = val;
    };
    const {
      fixTrainDatasetTableRef,
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      contentLoading,
      annotationList,
      getTrainDatasetAnnotationApi,
      updateAnnotationStatus
    } = (0, _fixTrainDataset.useFixTrainDataset)(modelInfo);
    const {
      deleteAnnotationApi
    } = (0, _fixTrainDataset.useDeleteAnnotation)({
      successCallBack: refresh
    });
    const deleteAnnotation = function (annotationIds) {
      let isBatch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (isBatch) {
        _elementUi.MessageBox.confirm(`确定删除${annotationIds.length}条数据的人工标注结果吗？`, '批量删除', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger'
        }).then(() => {
          deleteAnnotationApi({
            annotation_ids: annotationIds
          });
        }).catch(() => {});
      } else {
        deleteAnnotationApi({
          annotation_ids: annotationIds
        });
      }
    };
    return {
      __sfc: true,
      props,
      dataStatusButtons,
      activeDatasetTab,
      modelInfo,
      hasData,
      emit,
      updateVisible,
      refresh,
      tableSelectionList,
      tableSelectionChange,
      fixTrainDatasetTableRef,
      fixTrainDatasetDialogVisible,
      openFixTrainDatasetDialog,
      contentLoading,
      annotationList,
      getTrainDatasetAnnotationApi,
      updateAnnotationStatus,
      deleteAnnotationApi,
      deleteAnnotation,
      FixTrainDatasetTable: _FixTrainDatasetTable.default
    };
  }
};
exports.default = _default;