"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bi-chart"
  }, [_c("mg-infinite-scroll", {
    attrs: {
      nomore: _vm.nomore,
      "nomore-text": _vm.infoArray.length === 0 ? "" : "没有更多了"
    },
    on: {
      loadMore: _vm.loadMore
    }
  }, [_vm.infoArray && _vm.infoArray.length > 0 ? _c("div", {
    staticClass: "base-box"
  }, [_c("div", {
    staticClass: "base-box-left"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.smallMargin,
      expression: "smallMargin"
    }],
    staticClass: "header-titles"
  }, [_vm._t("title-name", function () {
    return [_c("span", {
      staticClass: "header-title-name"
    }, [_vm._v("名称")])];
  }), _c("sort-caret", {
    ref: _vm.keyName + "Sort",
    staticClass: "header-title-percent",
    attrs: {
      label: _vm.secondLabel,
      "default-status": "descending"
    },
    on: {
      sortCallBack: _vm.handleSortClick
    }
  })], 2)]), _c("div", {
    staticClass: "base-box-right"
  }, _vm._l(_vm.horizontalArray, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "horizontal-base-item",
      class: {
        "small-margin": _vm.smallMargin
      }
    }, [_c("div", {
      staticClass: "vertical-line",
      style: {
        height: _vm.getChartHeight
      }
    }), _c("div", {
      staticClass: "horizontal-label"
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0)]) : _vm._e(), _vm.infoArray && _vm.infoArray.length > 0 ? _c("div", {
    staticClass: "upper-box"
  }, [_vm.contentType === "avg_duration" ? _c("point-dialog") : _vm._e(), _vm._l(_vm.chartDataArr, function (item) {
    return [_c("el-popover", {
      key: item.id,
      attrs: {
        placement: "top",
        trigger: "hover",
        disabled: !_vm.popover,
        "open-delay": 400,
        width: "260"
      }
    }, [_c("div", {
      domProps: {
        innerHTML: _vm._s(_vm.popoverFormatter(item))
      }
    }), _c("div", {
      staticClass: "upper-item",
      class: {
        selected: _vm.canSelect && item.id === _vm.activeKey,
        "can-select": _vm.canSelect,
        "click-disabled": _vm.clickDisabled
      },
      attrs: {
        slot: "reference"
      },
      on: {
        click: function ($event) {
          return _vm.selectActiveKey(item);
        }
      },
      slot: "reference"
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "推荐范围外",
        placement: "top",
        disabled: !item.mark
      }
    }, [_c("span", {
      staticClass: "upper-item-mark",
      class: {
        vigilant: item.mark && item.mark === "vigilant"
      }
    }, [_c("i")])]), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: item.name || item.key,
        placement: "top-start",
        "popper-class": "bi-name-tooltip",
        disabled: _vm.isTooltipDisabled(item)
      }
    }, [_c("div", {
      staticClass: "upper-name-box"
    }, [_c("image-avatar", {
      staticClass: "upper-avatar",
      class: {
        "upper-event": !item.name
      },
      attrs: {
        name: item.name,
        avatar: item.avatar
      },
      nativeOn: {
        click: function ($event) {
          return _vm.goBIPerson(item);
        }
      }
    }), _c("span", {
      staticClass: "upper-item-name",
      class: {
        clickable: item.name && item.id,
        "is-label-silent": _vm.isLabelSilent
      },
      on: {
        click: function ($event) {
          return _vm.goBIPerson(item);
        }
      }
    }, [_vm._v(_vm._s(item.name || item.key) + " ")])], 1)]), _c("span", {
      staticClass: "upper-item-value"
    }, [_vm._v(_vm._s(item.valueLabel))]), !isNaN(parseInt(item.percentage)) ? _c("el-progress", {
      attrs: {
        percentage: item.percentage,
        color: "#A0B5FF",
        "stroke-width": 10,
        "show-text": false
      }
    }) : _vm._e()], 1)])];
  }), _vm.averageValue && _vm.isShowAverageVerticalLine ? _c("div", {
    staticClass: "average-value-wrapper",
    style: {
      right: _vm.getAverageRight
    }
  }, [_c("div", {
    staticClass: "average-label"
  }, [_vm._v(" 平均 "), _c("span", [_vm._v(_vm._s(_vm.averageValue.label))])]), _c("div", {
    staticClass: "average-vertical-line",
    style: {
      height: _vm.getAverageHeight
    }
  })]) : _vm._e()], 2) : _vm._e()]), !_vm.infoArray || _vm.infoArray && _vm.infoArray.length < 1 ? _c("div", {
    staticClass: "no-data"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;