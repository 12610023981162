"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _useRouter = require("@/hooks/use-router.js");
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _TaskScoreHistoryList = _interopRequireDefault(require("./TaskScoreHistoryList.vue"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _TricksScene = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksScene.vue"));
var _useAudioPlay = require("./hooks/use-audio-play.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ReadingAndRecitingCourseTask',
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  emits: [],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const route = (0, _useRouter.useRoute)();
    const stautsList = [(0, _i18n.t)('coaching.all'), (0, _i18n.t)('coaching.completed'), (0, _i18n.t)('coaching.notCompleted')];
    const currentActive = (0, _vue.ref)(0);
    const taskDetail = (0, _vue.ref)({
      score: 0,
      complete_count: 0,
      total: 0
    });
    const knowledgeList = (0, _vue.ref)([]);
    const loading = (0, _vue.ref)(false);
    const {
      currentPlayAudioId,
      playAudio,
      pauseAudio,
      currentPlayTime,
      audioPlayProgree
    } = (0, _useAudioPlay.usePlayAudio)();
    const setReplyOrder = data => {
      var _data$knowledge_list;
      (_data$knowledge_list = data.knowledge_list) === null || _data$knowledge_list === void 0 ? void 0 : _data$knowledge_list.forEach(item => {
        let order = 1;
        item.scene_reply_list.forEach(sceneItem => {
          sceneItem.reply_list.forEach(reply => {
            reply.order = order++;
          });
        });
      });
    };
    let currentPage = 1;
    const getSmartTrainingListData = async () => {
      if (loading.value) return;
      loading.value = true;
      const res = await (0, _coachingCenter.getReadingAndRecitingCourseTaskDetail)({
        plan_id: props.taskId,
        user_id: props.userInfo.id,
        status: currentActive.value,
        page: currentPage++,
        size: 5
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        setReplyOrder(res.results);
        const {
          score,
          complete_count,
          total,
          knowledge_list
        } = res.results;
        taskDetail.value = {
          score,
          complete_count,
          total
        };
        knowledgeList.value.push(...(knowledge_list || []));
        emit('updateTaskInfo', {
          score,
          total: total,
          complate: complete_count
        });
      }
    };
    const toggleStatus = val => {
      currentActive.value = val;
      currentPage = 1;
      knowledgeList.value = [];
      getSmartTrainingListData();
    };
    const notCompletedCount = (0, _vue.computed)(() => taskDetail.value.total - taskDetail.value.complete_count);
    return {
      __sfc: true,
      props,
      emit,
      route,
      stautsList,
      currentActive,
      taskDetail,
      knowledgeList,
      loading,
      currentPlayAudioId,
      playAudio,
      pauseAudio,
      currentPlayTime,
      audioPlayProgree,
      setReplyOrder,
      currentPage,
      getSmartTrainingListData,
      toggleStatus,
      notCompletedCount,
      t: _i18n.t,
      TaskScoreHistoryList: _TaskScoreHistoryList.default,
      NoData: _NoData.default,
      TricksScene: _TricksScene.default
    };
  }
};
exports.default = _default;