"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "overview",
    staticClass: "overview"
  }, [_c("conversation-detail-info", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.conversationAssociationInfoPermission,
      expression: "conversationAssociationInfoPermission"
    }],
    staticClass: "conversation-detail-info",
    attrs: {
      "detail-result": _vm.detailResult
    }
  }), _c("div", {
    ref: "#anchor-2",
    staticClass: "analy-overview inter-info",
    style: {
      marginTop: !_vm.infoArray || _vm.infoArray.length < 1 ? "0px" : "24px"
    }
  }, [_vm.detailResult.media_type !== "doc" ? _c("div", {
    staticClass: "item-title"
  }, [_c("div", {
    staticClass: "title-text"
  }, [_vm._v("会话维度")]), _c("div", {
    staticClass: "detail-link",
    class: {
      "report-loading": !_vm.biComplete
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "analy-content info-content"
  }, [_vm._l(_vm.dimensionInfo, function (item) {
    return [_vm.detailResult.media_type !== "doc" ? _c("div", {
      key: item.label,
      staticClass: "info-item",
      class: {
        "report-loading": !_vm.biComplete
      }
    }, [_c("div", {
      staticClass: "info-item-title analy-item-title"
    }, [_vm._v(_vm._s(item.label))]), !_vm.biComplete ? _c("div", {
      staticClass: "analy-item-value"
    }) : _c("div", {
      staticClass: "info-item-content",
      domProps: {
        innerHTML: _vm._s(item.value)
      }
    })]) : _vm._e()];
  }), !_vm.noEvents ? _c("div", {
    staticClass: "split-line"
  }) : _vm._e(), !_vm.noEvents ? _c("div", {
    staticClass: "key-event analy-item",
    class: {
      "report-loading": !_vm.eventEngineComplete && !_vm.analyOverView.keyevent_count
    }
  }, [_c("div", {
    staticClass: "item-title"
  }, [_c("div", {
    staticClass: "title-text"
  }, [_vm._v("关键事件")]), _c("div", {
    staticClass: "detail-link",
    class: {
      "report-loading": !_vm.eventEngineComplete && !_vm.analyOverView.keyevent_count
    }
  })]), _vm.eventEngineComplete || _vm.analyOverView.keyevent_count ? _c("div", {
    staticClass: "analy-item-value analy-item-link",
    class: {
      "no-value": !_vm.analyOverView.keyevent_count
    },
    on: {
      click: function ($event) {
        return _vm.$emit("openKeyEvent");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.analyOverView.keyevent_count) + " ")]) : _vm._e()]) : _vm._e(), _vm.eventEngine && _vm.eventEngine.length !== 0 ? _c("div", {
    staticClass: "key-event-detail"
  }, [_c("events-list", {
    attrs: {
      "event-engine": _vm.eventEngine,
      "tag-size": "large"
    },
    on: {
      tagClick: function ($event) {
        return _vm.$emit("eventClick", $event);
      }
    }
  })], 1) : !_vm.noEvents ? _c("div", {
    staticClass: "event-empty"
  }, [_vm._m(0)]) : _vm._e()], 2)])], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-content"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-empty"
  }), _c("span", {
    staticClass: "empty-text"
  }, [_vm._v("暂无关键事件")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;