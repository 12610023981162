"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coaching-tasks-content"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_vm.visible ? _c("catalog-select", _vm._b({
    attrs: {
      "catalog-data": _vm.catalogData,
      "current-node": _vm.currentNode,
      "has-knowledges-search": _vm.hasKnowledgesSearch
    },
    on: {
      nodeClick: _vm.handleNodeClick,
      search: _vm.handleSearch
    }
  }, "catalog-select", _vm.$attrs, false)) : _vm._e()], 1), _c("div", {
    staticClass: "right-part"
  }, [_c("knowledge-tricks-select", _vm._b({
    ref: "tricksSelect",
    attrs: {
      "selected-knowledges": _vm.selectedKnowledges,
      "current-node": _vm.currentNode,
      "target-knowledge": _vm.targetKnowledge
    },
    on: {
      knowledgesChange: function ($event) {
        return _vm.$emit("knowledgesChange", $event);
      },
      setSelectedKnowledges: function ($event) {
        return _vm.$emit("setSelectedKnowledges", $event);
      }
    }
  }, "knowledge-tricks-select", _vm.$attrs, false))], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;