"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _TaskTableFilter = _interopRequireDefault(require("../components/TaskTableFilter.vue"));
var _TeamViewTable = _interopRequireDefault(require("./TeamViewTable.vue"));
var _taskUtils = require("../task-utils.js");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamView',
  components: {
    TaskTableFilter: _TaskTableFilter.default,
    TeamViewTable: _TeamViewTable.default,
    NoData: _NoData.default
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.includes(_routeMap.ROUTES.coaching)) {
      sessionStorage.setItem('coachingTaskListParamsByTeamView', JSON.stringify(this.params));
    }
    next();
  },
  props: {
    refreshSign: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      params: {
        name: '',
        creator_id: 0,
        space_id: '',
        status: -1,
        user_ids: [],
        tree_ids: [],
        page: 1,
        size: 10,
        order_field: '',
        order_type: ''
      },
      tableData: [],
      tableLoading: false,
      count: 0,
      cachePageNumber: 0
    };
  },
  watch: {
    refreshSign() {
      this.initData();
      this.getTableData();
    }
  },
  created() {
    const {
      page,
      size
    } = JSON.parse(sessionStorage.getItem('coachingTaskListParamsByTeamView') || '{}');
    this.cachePageNumber = page;
    this.params.size = size || 10;
    this.params.page = page || 1;
  },
  methods: {
    async getTableData() {
      let deleteId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      // 后端数据更新有延迟，暂定前端过滤需删除的数据项（多请求一条数据->过滤需删除的数据）
      this.formatParams();
      this.tableLoading = true;
      const params = this.formatParams();
      if (deleteId) {
        params.size = params.size + 1;
      }
      const res = await (0, _coachingCenter.getTeamViewList)(params).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        const {
          data: _data,
          total_count
        } = res.results;
        const data = (0, _taskUtils.formatCombineTaskList)(_data);
        this.tableData = deleteId ? data.filter(i => i.id !== deleteId) : data;
        this.count = deleteId ? total_count - 1 : total_count;
      }
    },
    filterChange(val) {
      this.params = {
        ...this.params,
        ...val
      };
      this.initData();
      this.getTableData();
    },
    initData() {
      this.params.page = this.cachePageNumber || 1;
      this.cachePageNumber = 0;
    },
    sizeChange(val) {
      this.params.size = val;
      this.initData();
      this.getTableData();
    },
    pageChange(val) {
      this.params.page = val;
      this.getTableData();
    },
    tableSortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.params.order_field = prop;
      this.params.order_type = order;
      this.initData();
      this.getTableData();
    },
    handleDelete(id) {
      this.initData();
      this.getTableData(id);
    },
    formatParams() {
      const params = {
        ...this.params
      };
      if (params.status === '') {
        params.status = -1;
      }
      if (params.category_id === '') {
        params.category_id = -1;
      }
      params.space_id = params.space_id || 0;
      params.course_type = params.course_type || 0;
      params.creator_id = params.creator_id || 0;
      return params;
    }
  }
};
exports.default = _default;