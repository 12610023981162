"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permissions = require("@/api/permissions");
var _MemberHeaderFilter = _interopRequireDefault(require("./components/MemberHeaderFilter"));
var _MemberTable = _interopRequireDefault(require("./components/MemberTable"));
var _OperateMemberDialog = _interopRequireDefault(require("./components/OperateMemberDialog"));
var _export = require("@/api/export");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MemberHeaderFilter: _MemberHeaderFilter.default,
    MemberTable: _MemberTable.default,
    OperateMemberDialog: _OperateMemberDialog.default
  },
  data() {
    return {
      roleList: [],
      tableData: [],
      listParams: {
        filter: '',
        tree_ids: [],
        only_direct: false,
        role_id: '',
        page: 1,
        size: 10
      },
      total: 0,
      operateMemberDialogVisible: false,
      memberInfo: {},
      multiMembersList: [],
      operateType: '',
      memberTableLoading: false,
      unassignedCount: 0
    };
  },
  computed: {},
  created() {
    this.getBusinessRoles();
    this.getMembersList();
  },
  methods: {
    // 导出业务角色
    exportApi() {
      const {
        filter,
        tree_ids,
        only_direct,
        role_id
      } = this.listParams;
      console.log(role_id, 'role_id');
      return (0, _export.exportBusinessRole)({
        filter,
        tree_ids,
        only_direct,
        role_id: role_id === '' ? null : role_id
      });
    },
    // 搜索业务角色
    async getBusinessRoles(filter) {
      const res = await (0, _permissions.getBusinessRoles)(filter);
      if (res.code === 20000) {
        this.roleList = res.results.role_list;
      }
    },
    // 获取成员列表
    async getMembersList() {
      try {
        this.memberTableLoading = true;
        if (this.listParams.role_id === '') {
          delete this.listParams.role_id;
        }
        const res = await (0, _permissions.getMembersList)(this.listParams);
        if (res.code === 200) {
          const {
            count,
            unassigned_count,
            user_list
          } = res.results;
          this.tableData = user_list;
          this.total = count;
          this.unassignedCount = unassigned_count;
        }
      } finally {
        this.memberTableLoading = false;
      }
    },
    initData() {
      this.listParams.page = 1;
      this.tableData = [];
      this.total = 0;
    },
    // 筛选项变化
    filterChange(val) {
      this.listParams = {
        ...this.listParams,
        ...val
      };
      this.initData();
      this.getMembersList();
    },
    handleSizeChange(val) {
      this.listParams.size = val;
      this.initData();
      this.getMembersList();
    },
    handlePageChange(val) {
      this.listParams.page = val;
      this.getMembersList();
    },
    openOperateMemberDialog(val) {
      this.operateMemberDialogVisible = true;
      this.operateType = val.type;
      this.memberInfo = val.membersList ? val.membersList : {};
    },
    handleSelectionChange(val) {
      this.multiMembersList = val;
    },
    refreshTable() {
      this.initData();
      this.getMembersList();
    }
  }
};
exports.default = _default;