"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog,
      width: "474px",
      "custom-class": "allot-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticClass: "title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("p", {
    staticClass: "plain-title"
  }, [_vm._v("指定负责人")]), _c("el-cascader", {
    ref: "cascadar",
    attrs: {
      placeholder: "搜索跟进人",
      options: _vm.options,
      filterable: "",
      props: {
        expandTrigger: "hover"
      }
    },
    model: {
      value: _vm.val,
      callback: function ($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  }), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;