"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clues = require("@/api/clues");
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateClueDialog',
  components: {
    DynamicForm: _DynamicForm.default
  },
  props: {
    createClueDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clueDynamicFormDesc: null,
      formDesc: [],
      createClueForm: {},
      getFormloading: false,
      saveLoading: false
    };
  },
  watch: {
    createClueDialogVisible(val) {
      if (!val) {
        this.formDesc = [];
      }
    }
  },
  created() {
    this.getClueDynamicForm();
  },
  methods: {
    // 获取新建线索表单
    async getClueDynamicForm() {
      this.getFormloading = true;
      const res = await (0, _clues.getClueDynamicForm)();
      if (res.code === 20000) {
        this.clueDynamicFormDesc = res.results;
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.clueDynamicFormDesc)));
      }
      this.getFormloading = false;
    },
    descFormat(results) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          tips: item.tips
        };
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    submitForm() {
      if (this.$refs['dynamicForm'].validate()) {
        this.postCreateClue({
          lead: this.createClueForm
        });
      }
    },
    // 新建线索的请求
    async postCreateClue(data) {
      this.saveLoading = true;
      const res = await (0, _clues.postCreateClue)(data);
      if (res.code === 20000) {
        this.$message.success('新建成功');
        this.$emit('createClueSuccess', res.results);
        this.closeDialog();
      }
      this.saveLoading = false;
    }
  }
};
exports.default = _default;