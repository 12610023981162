"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MemberCard = _interopRequireDefault(require("./MemberCard"));
var _FuncRoleCard = _interopRequireDefault(require("./FuncRoleCard"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MemberCard: _MemberCard.default,
    FuncRoleCard: _FuncRoleCard.default
  },
  props: {},
  data() {
    return {
      activeName: 'first'
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(tab, event) {},
    goImportPage() {
      this.$router.push({
        path: _routeMap.ROUTES.ImportBusinessRole
      });
    }
  }
};
exports.default = _default;