"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vue = require("vue");
var _SentenceItem = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/SentenceItem.vue"));
var _DialogueItemNarration = _interopRequireDefault(require("../components/DialogueItemNarration.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'DialogueList',
  props: {
    conversationList: {
      type: Array,
      default: () => []
    },
    customerAvatar: {
      type: String,
      default: ''
    },
    narration: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const sentenceList = (0, _vue.computed)(() => {
      return props.conversationList.map(item => {
        const {
          avatar,
          comment,
          content,
          date,
          name,
          reply,
          score,
          speaker_party,
          total_score,
          images,
          // 新增字段
          pass_score
        } = item;
        return {
          score_rule_type: 1,
          comment,
          content: reply,
          standard_reply: content,
          type: speaker_party === 'narrator' ? 'narrator' : speaker_party === 'customer_contact' ? 'customer' : 'sales',
          score,
          pass_score,
          total_score,
          avatar,
          name,
          images,
          date
        };
      });
    });
    return {
      __sfc: true,
      props,
      sentenceList,
      t: _i18n.t,
      NoData: _NoData.default,
      SentenceItem: _SentenceItem.default,
      DialogueItemNarration: _DialogueItemNarration.default
    };
  }
};
exports.default = _default;