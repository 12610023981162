"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomFieldFilter = _interopRequireDefault(require("./CustomFieldFilter.vue"));
var _CustomFieldHeader = _interopRequireDefault(require("./CustomFieldHeader"));
var _CustomFieldTable = _interopRequireDefault(require("./CustomFieldTable"));
var _CustomFieldDialog = _interopRequireDefault(require("./CustomFieldDialog"));
var _BusinessCustomStatus = _interopRequireDefault(require("./BusinessCustomStatus"));
var _field = require("@/api/field");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomFieldRightContent',
  components: {
    CustomFieldFilter: _CustomFieldFilter.default,
    CustomFieldHeader: _CustomFieldHeader.default,
    CustomFieldTable: _CustomFieldTable.default,
    CustomFieldDialog: _CustomFieldDialog.default,
    BusinessCustomStatus: _BusinessCustomStatus.default
  },
  props: {
    currentMenu: {
      type: String,
      default: 'conversation'
    }
  },
  data() {
    return {
      activeTab: 'field',
      isStageEditing: false,
      // 是否进入编辑模式
      isDataChanged: false,
      // 数据是否发生变化
      saveStageLoading: false,
      // 提交商机阶段变化时的loading
      stageData: {},
      // 商机阶段的整体数据
      errorTip: '',
      // 校验提示
      createDialogVisible: false,
      editForm: {},
      isEdit: false,
      tableLoading: false,
      customFieldList: []
    };
  },
  watch: {
    currentMenu: {
      handler(val) {
        if (val) {
          this.getCustomFieldList({
            type: val
          });
        }
      },
      immediate: true
    }
  },
  created() {
    const tab = this.$route.params.tab;
    if (tab) {
      this.activeTab = tab;
    }
  },
  methods: {
    filterChange(customFieldFilter) {
      this.getCustomFieldList({
        type: this.currentMenu,
        ...customFieldFilter
      });
    },
    freshCustomFieldList() {
      this.getCustomFieldList({
        type: this.currentMenu
      });
    },
    async getCustomFieldList(params) {
      this.tableLoading = true;
      const res = await (0, _field.getCustomFieldList)(params).finally(() => this.tableLoading = false);
      if (res.code === 200) {
        this.customFieldList = res.results;
      }
    },
    openCreateDialog() {
      if (this.customFieldList && this.customFieldList.length >= 100) {
        this.$message({
          message: '字段数量超过上限',
          type: 'warning'
        });
        return;
      }
      this.editForm = {};
      this.createDialogVisible = true;
    },
    editField(data) {
      this.createDialogVisible = true;
      this.isEdit = true;
      this.editForm = data;
    },
    closeDialog() {
      this.createDialogVisible = false;
      this.isEdit = false;
    },
    handleSuccess() {
      this.closeDialog();
      this.freshCustomFieldList();
    },
    switchEdit() {
      this.isStageEditing = true;
    },
    dataHasChange(isChanged, val) {
      this.isDataChanged = isChanged;
      this.stageData = val;
      this.errorTip = '';
    },
    cancelSaveStageChange() {
      this.$refs.businessCustomStatus.recoveryData(); // 未保存时恢复原有数据
      this.isStageEditing = false;
    },
    saveStageChange() {
      this.handleValidate();
      if (this.errorTip) return;
      this.$confirm('保存后，新商机阶段设置会在所有商机中生效', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        // 确定提交保存
        this.$refs.businessCustomStatus.updateDealStage();
      });
    },
    handleValidate() {
      const data = this.stageData;
      for (const key in data) {
        if (key === 'in_process') {
          for (const item of data[key]) {
            if (!item.name) {
              this.errorTip = '阶段名称不能为空';
              return;
            }
            if (isNaN(item.win_rate)) {
              this.errorTip = '赢率不能为空';
              return;
            }
          }
        } else {
          if (!data[key].name) {
            this.errorTip = '阶段名称不能为空';
            return;
          }
          if (isNaN(data[key].win_rate)) {
            this.errorTip = '赢率不能为空';
            return;
          }
        }
      }
    }
  }
};
exports.default = _default;