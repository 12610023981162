"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'ChatInput',
  props: {
    backfillInputVal: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('coaching.replyPlaceholder');
      }
    },
    maxlength: {
      type: Number,
      default: 10000
    }
  },
  data() {
    return {
      inputVal: '',
      isInputExpand: false
    };
  },
  computed: {
    hasContent() {
      return this.inputVal.trim();
    }
  },
  watch: {
    backfillInputVal(val) {
      this.inputVal = val;
      this.$refs.input.focus();
    },
    inputVal(val) {
      if (!val) {
        this.isInputExpand = false;
      }
    }
  },
  methods: {
    handleKeyDown(e) {
      const {
        ctrlKey,
        altKey,
        keyCode
      } = e;
      if ((ctrlKey || altKey) && (keyCode === 13 || keyCode === 10)) {
        this.lineFeed(e);
        return;
      }
      if (keyCode === 13) {
        e.preventDefault();
        this.send();
      }
    },
    send() {
      if (!this.hasContent || this.disabled) {
        if (this.hasContent && this.disabled) {
          this.$message.warning(this.$t('coaching.sendNextWarning'));
        }
        return;
      }
      this.$emit('change', this.inputVal.replace(/(\s*$)/g, ''));
      this.inputVal = '';
    },
    lineFeed(e) {
      const {
        selectionStart: start
      } = e.target;
      this.inputVal = this.inputVal.slice(0, start) + '\n' + this.inputVal.slice(start);
      this.$nextTick(() => {
        const pos = start + 1;
        e.target.setSelectionRange(pos, pos);
        this.listenInput();
      });
    },
    listenInput: (0, _commonFunc.throttle)(function () {
      var _this$$refs$input;
      const input = (_this$$refs$input = this.$refs.input) === null || _this$$refs$input === void 0 ? void 0 : _this$$refs$input.$el;
      const textArea = input.querySelector('textarea');
      const {
        clientHeight,
        scrollHeight
      } = textArea;
      if (clientHeight > 42) {
        this.isInputExpand = true;
        textArea.scrollTo({
          top: scrollHeight + 200
        });
      }
    }, 100)
  }
};
exports.default = _default;