"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filterSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/filterSelect"));
var _StageChart = _interopRequireDefault(require("@/components/Charts/StageChart"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _num_formatter = require("@/utils/num_formatter");
var _vuex = require("vuex");
var _StageTable = _interopRequireDefault(require("./components/StageTable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: '',
  components: {
    StageChart: _StageChart.default,
    StageTable: _StageTable.default,
    BiFilter: _filterSelect.default
  },
  data() {
    return {
      tableData: [],
      chartData: [],
      maxChartValue: 0,
      yAxis: [],
      transferRate: [],
      lossRate: [],
      rightList: [],
      // 部门
      departmentValue: [],
      changeNum: 0,
      // 成员
      memberValue: [],
      // 创建时间
      createTime: {},
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone'])
  },
  methods: {
    async getStageData(val) {
      const params = {
        tree_id: val.tree_id,
        create_time: val.create_time,
        user_ids: JSON.stringify(val.user_ids),
        time_zone: this.timezone
      };
      this.loading = true;
      const res = await (0, _businessIntelligence.getStageAnalysis)(params);
      if (res.code === 200) {
        // 排序
        const data = res.results.sort((a, b) => {
          return a.order - b.order;
        });
        // 计算表格数据
        this.tableData = this.formatTableData(data);
        // 计算图表数据
        const transferData = data.map(item => item.info.transfer_count || 0).reverse();
        const lossData = data.map(item => item.info.loss_count || 0).reverse();
        const stayData = data.map(item => item.info.stay_count || 0).reverse();
        this.chartData = [transferData, lossData, stayData];
        this.maxChartValue = Math.max(...transferData, ...lossData, ...stayData);
        this.yAxis = data.map(item => item.stage_name).reverse();
        this.transferRate = data.map(item => {
          const rate = item.info.transfer_rate || 0;
          return `${(rate * 100).toFixed(0)}%`;
        }).reverse();
        this.lossRate = data.map(item => {
          const rate = item.info.loss_rate || 0;
          return `${(rate * 100).toFixed(0)}%`;
        }).reverse();
        // 计算图表右侧数据
        this.rightList = data.map(item => {
          return {
            totalRate: this.percentFormatter(item.info.conversion_rat),
            stayTime: this.timeFormatter(item.info.avg_stay_time)
          };
        });
      }
      this.loading = false;
    },
    // 部门
    handleDepartmentChange(val) {
      this.departmentValue = val;
      if (!this.changeNum++) return;
      if (this.$refs['selectMembers']) {
        this.$refs['selectMembers'].selection = [];
      }
      this.memberValue = [];
    },
    // 成员
    handleMemberChange(value) {
      this.memberValue = value;
    },
    // 创建时间
    handleCreateTimeChange(value) {
      this.createTime = value;
    },
    formatTableData(data) {
      return data.map(stage => {
        return {
          stage_name: this.defaultFormatter(stage.stage_name),
          total: this.defaultFormatter(stage.info.total),
          amount: this.amountFormatter(stage.info.amount),
          avg_stay_time: this.timeFormatter(stage.info.avg_stay_time),
          conversion_rat: this.percentFormatter(stage.info.conversion_rat),
          transfer_count: this.defaultFormatter(stage.info.transfer_count),
          transfer_rate: this.percentFormatter(stage.info.transfer_rate),
          loss_count: this.defaultFormatter(stage.info.loss_count),
          loss_rate: this.percentFormatter(stage.info.loss_rate),
          stay_count: this.defaultFormatter(stage.info.stay_count),
          avg_price: this.amountFormatter(stage.info.avg_price),
          deal_period: this.timeFormatter(stage.info.deal_period),
          win_rate: this.percentFormatter(stage.info.win_rate)
        };
      });
    },
    percentFormatter(cellValue) {
      if (cellValue === null || cellValue === undefined) return '-';
      return `${(cellValue * 100).toFixed(2)}%`;
    },
    amountFormatter(cellValue) {
      if (cellValue === null || cellValue === undefined) return '-';
      return `${(0, _num_formatter.number_to_amount)(cellValue)} 元`;
    },
    timeFormatter(cellValue) {
      if (cellValue === null || cellValue === undefined) return '-';
      return `${Math.floor(cellValue)} 天`;
    },
    defaultFormatter(cellValue) {
      if (cellValue === null || cellValue === undefined) return '-';
      return cellValue;
    }
  }
};
exports.default = _default;