"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SetNameDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ruleForm: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入抽样范围名称',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
      this.$refs['ruleForm'].clearValidate();
    },
    submit() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$emit('save', this.ruleForm.name);
          this.handleClose();
        }
      });
    },
    resetFields() {
      this.$nextTick(() => {
        var _this$$refs$ruleForm;
        (_this$$refs$ruleForm = this.$refs['ruleForm']) === null || _this$$refs$ruleForm === void 0 ? void 0 : _this$$refs$ruleForm.resetFields();
      });
    }
  }
};
exports.default = _default;