"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DragInputs',
  components: {
    MgDraggable: _MgDraggable.default
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isItemObj: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: '阶段'
    },
    maxCount: {
      type: [Number, String],
      default: 12
    }
  },
  data() {
    return {
      localList: this.$props.value,
      oldVal: ''
    };
  },
  computed: {
    placeholder() {
      return `请输入${this.labelText}名称`;
    },
    // localList 中没有删除的数量
    localListValidItemLength() {
      var _this$localList$filte;
      return this.localList && this.localList.length && ((_this$localList$filte = this.localList.filter(item => !item.is_delete)) === null || _this$localList$filte === void 0 ? void 0 : _this$localList$filte.length);
    }
  },
  watch: {
    value: {
      handler(val) {
        this.localList = val;
      }
    },
    localList: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    }
  },
  methods: {
    handleFocus(val) {
      this.oldVal = val;
    },
    handleBlur(val) {
      if (val !== this.oldVal) {
        this.$emit('sender');
      }
    },
    addItem() {
      if (this.isItemObj) {
        this.localList.push({
          id: 0,
          name: '',
          win_rate: 0
        });
      } else {
        this.localList.push({
          key: '',
          value: '',
          is_delete: 0,
          order: 0
        });
      }
      setTimeout(() => {
        this.$emit('sender', 'add');
      }, 0);
    },
    async deleteItem(index, item) {
      this.$emit('sender', 'delete', index, item);
    }
  }
};
exports.default = _default;