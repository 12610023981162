"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formMixin = _interopRequireDefault(require("./formMixin.js"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_formMixin.default],
  props: {
    options: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      newValue: []
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.newValue = value || [];
      },
      immediate: true
    }
  },
  methods: {
    noEditContent(item) {
      if (item) {
        var _this$options$find;
        return (_this$options$find = this.options.find(option => option.value === item)) === null || _this$options$find === void 0 ? void 0 : _this$options$find.label;
      }
    }
  }
};
exports.default = _default;