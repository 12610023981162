"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "annotate-detail"
  }, [_setup.showLoadingDataTips ? _c(_setup.LoadingDataMessage, {
    staticClass: "loading-data"
  }, [_vm._v("数据加载中")]) : _vm._e(), _c("el-drawer", {
    staticClass: "mg-common-drawer data-set-detail-drawer",
    attrs: {
      visible: _vm.drawerVisible,
      "before-close": _setup.handleClose,
      size: "90%"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "data-set-detail-drawer__header"
        }, [_c("div", {
          staticClass: "custom-title"
        }, [_vm._v(_vm._s(_vm.dataSetName))]), _c("div", [_vm._v(" ID: " + _vm._s(_vm.dataSetId) + " ")]), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "复制ID",
            placement: "top"
          }
        }, [_c("i", {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: JSON.stringify(_vm.dataSetId),
            expression: "JSON.stringify(dataSetId)",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _setup.onCopy,
            expression: "onCopy",
            arg: "success"
          }],
          staticClass: "iconfont icon-copy-document"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c(_setup.HorizontalScroll, [_c(_setup.AnnotateResultsTabs, {
    attrs: {
      "tabs-list": _setup.allLabelsList,
      "active-tabs": _setup.activeTabs
    },
    on: {
      "update:activeTabs": function ($event) {
        _setup.activeTabs = $event;
      },
      "update:active-tabs": function ($event) {
        _setup.activeTabs = $event;
      },
      activeTabsChange: _setup.getAllData
    }
  })], 1), _c(_setup.AnnotateResultsFilters, {
    attrs: {
      "annotators-list": _setup.annotatorsList,
      "table-selection-list": _setup.tableSelectionList
    },
    on: {
      filterChange: _setup.filterChange,
      exportAnnotationResults: _setup.exportAnnotationResults,
      deleteAnnotation: _setup.deleteAnnotation,
      updateAnnotate: _setup.batchUpdateDatasetAnnotationApi,
      openConversationDrawer: _setup.openConversationDrawer
    }
  }), _c(_setup.AnnotateResultsTable, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableResults.loading,
      expression: "tableResults.loading"
    }],
    attrs: {
      "data-set-statistics": _setup.dataSetStatistics,
      "start-index": _setup.paginationParams.size * (_setup.paginationParams.page - 1) + 1,
      "table-data": _setup.tableResults.tableData
    },
    on: {
      tableSortChange: _setup.tableSortChange,
      updateAnnotate: _setup.batchUpdateDatasetAnnotationApi,
      tableSelectionChange: _setup.tableSelectionChange,
      deleteAnnotation: _setup.deleteAnnotation,
      clickModelSentence: _setup.openAnnotationResultsDialog
    }
  }), _c("footer", {
    staticClass: "footer"
  }, [_c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _setup.paginationParams.page,
      "page-sizes": [100, 200, 400],
      "page-size": _setup.paginationParams.size,
      total: _setup.tableResults.total
    },
    on: {
      "size-change": _setup.sizeChange,
      "current-change": _setup.currentChange
    }
  })], 1)], 1), _setup.conversationDrawerVisible ? _c(_setup.FilterConvDrawer, {
    ref: "filterConvDrawerRef",
    attrs: {
      title: "手动筛选会话",
      "drawer-visible": _setup.conversationDrawerVisible,
      "modal-append-to-body": false,
      "no-tip": "",
      "page-sizes": [200]
    },
    on: {
      "update:drawerVisible": function ($event) {
        _setup.conversationDrawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _setup.conversationDrawerVisible = $event;
      },
      conversationListChange: _setup.conversationListChange
    },
    scopedSlots: _vm._u([{
      key: "footer-operate",
      fn: function () {
        return [_c("el-button", {
          staticClass: "mg-button mg-round-button",
          on: {
            click: _setup.closeConversationDrawer
          }
        }, [_vm._v("取消")]), _c("el-button", {
          class: ["mg-button", "mg-round-button"],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _setup.openManualImportDialog.apply(null, arguments);
            }
          }
        }, [_vm._v("导入")])];
      },
      proxy: true
    }], null, false, 2387503612)
  }) : _vm._e(), _c(_setup.ManualImportDataSetDialog, {
    attrs: {
      visible: _setup.manualImportDataSetDialogVisible,
      "dataset-id": _vm.dataSetId,
      "conversation-list": _setup.conversationList,
      "dataset-label-id": _setup.activeTabs.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.manualImportDataSetDialogVisible = $event;
      },
      expandTrainDataSetSuccess: _setup.expandTrainDataSetSuccess
    }
  }), _setup.annotationResultsDialogVisible ? _c(_setup.AnnotateResultsDialog, {
    attrs: {
      id: _setup.currentAnnotationResultId,
      visible: _setup.annotationResultsDialogVisible,
      "dataset-id": _vm.dataSetId,
      ids: _setup.allAnnotatedIds,
      "get-all-data": _setup.getAllData
    },
    on: {
      "update:visible": function ($event) {
        _setup.annotationResultsDialogVisible = $event;
      },
      updateTableListAnnotationStatus: _setup.updateTableListAnnotationStatus
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;