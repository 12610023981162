"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "group-operation"
  }, [_c(_setup.GroupSaveButton, {
    attrs: {
      "members-ids": _vm.membersIds,
      "is-exceeded-limit": _setup.tableData.length >= 30
    }
  }), _c(_setup.GroupImportButton, {
    attrs: {
      "table-data": _setup.tableData,
      "table-loading": _setup.tableLoading
    },
    on: {
      updateTableData: _setup.getTableList,
      getImportMemberList: function ($event) {
        return _setup.emit("getImportMemberList", $event);
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;