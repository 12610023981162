"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coach-activities"
  }, [_c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.$t("coaching.times", [_vm.record.count])))]), _c("div", {
    staticClass: "right-container"
  }, [_c("div", {
    staticClass: "record-row"
  }), _c("div", {
    staticClass: "absolute-row"
  }, [_c("div", {
    staticClass: "icons"
  }, _vm._l(_vm.record.list, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "icon-container",
      style: {
        marginLeft: `${item.offset * 100}%`
      },
      attrs: {
        "data-date": key
      }
    }, [_c("dialog-tag", {
      attrs: {
        "data-date": key,
        text: item.count,
        type: item.is_light ? "me-coach" : "other-coach"
      },
      nativeOn: {
        click: function ($event) {
          return _vm.handleTagClick($event, key);
        }
      }
    })], 1);
  }), 0), _c("div", {
    staticClass: "date-row"
  }, [_c("span", [_vm._v(_vm._s(_vm.plus8ToCurrentZone(_vm.record.start_date)))]), _c("span", [_vm._v(_vm._s(_vm.plus8ToCurrentZone(_vm.record.end_date)))])])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;