"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timezone = require("@/utils/timezone");
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyEventMutualExclusionTable',
  props: {
    mutualExclusionKeyEventList: {
      type: Array,
      default: () => []
    }
  },
  emits: 'editMutexRule',
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const configEnum = {
      1: '触发时间最早',
      2: '触发时间最晚',
      3: '优先级最高'
    };
    const activeId = (0, _vue.ref)(0);
    const clearActiveId = () => {
      activeId.value = 0;
    };
    expose({
      clearActiveId
    });
    const handleCommand = (command, row) => {
      switch (command) {
        case 'edit':
          emit('editMutexRule', row.id);
          activeId.value = row.id;
          break;
        case 'delete':
          emit('deleteMutexRule', row.id);
          break;
        default:
          break;
      }
    };
    const getOperateList = () => {
      return [{
        label: '编辑',
        command: 'edit',
        isShow: true
      }, {
        label: '删除',
        command: 'delete',
        class: 'delete',
        isShow: true
      }];
    };
    return {
      __sfc: true,
      props,
      configEnum,
      activeId,
      clearActiveId,
      emit,
      handleCommand,
      getOperateList,
      plus8ToCurrentZone: _timezone.plus8ToCurrentZone,
      MgDraggable: _MgDraggable.default,
      MgOperateColumn: _MgOperateColumn.default
    };
  }
};
exports.default = _default;