"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "chart",
    staticClass: "trend-analysis-container"
  }, [_c("div", {
    staticClass: "chart-head"
  }, [_c("span", {
    staticClass: "chart-title"
  }, [_vm._v("问题出现频率 - "), _c("span", [_vm._v("【" + _vm._s(_vm.config.name) + "】")])]), _c("el-select", {
    staticClass: "dimension-select",
    attrs: {
      "popper-class": "bi-faq-dimension-select-options"
    },
    on: {
      change: _vm.getChartData
    },
    model: {
      value: _vm.dimension,
      callback: function ($$v) {
        _vm.dimension = $$v;
      },
      expression: "dimension"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_vm._v(_vm._s(_vm.getSelectValue))]), _vm._l(_vm.getDimensionOptions(), function (_ref) {
    let {
      label,
      value
    } = _ref;
    return _c("el-option", {
      key: value,
      attrs: {
        label: label,
        value: value
      }
    });
  })], 2), _c("el-tooltip", {
    attrs: {
      content: "导出数据",
      placement: "top"
    }
  }, [_c("TaskCenterExportButton", {
    staticClass: "export-button",
    on: {
      exportData: _vm.exportData
    }
  })], 1), _c("full-screen-btn", {
    attrs: {
      "full-screen-container": _vm.chartContainer
    }
  })], 1), _c("div", {
    staticClass: "chart-body"
  }, [_c("chart-template", {
    attrs: {
      "chart-loading": _vm.getChartDataLoading,
      "chart-config": _vm.chartConfig
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;