"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  __name: 'MetricSelector',
  emits: ['change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const metricList = (0, _vue.ref)([]);
    const loading = (0, _vue.ref)(false);
    const curMetric = (0, _vue.ref)({});
    const curQuery = (0, _vue.ref)('');
    const getMetric = async query => {
      curQuery.value = query;
      if (!query) {
        return;
      }
      const params = {
        name: query
      };
      loading.value = true;
      const res = await (0, _coachingCenter.scoreMetricsList)(params).finally(() => {
        loading.value = false;
      });
      metricList.value = res.results.items;
    };
    const handleItemText = text => {
      const reg = new RegExp(curQuery.value, 'g');
      return text.replace(reg, `<span style="color: #4461ec">${curQuery.value}</span>`);
    };
    return {
      __sfc: true,
      metricList,
      loading,
      curMetric,
      curQuery,
      getMetric,
      emit,
      handleItemText
    };
  }
};
exports.default = _default;