"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MessageOperate',
  props: {
    msg: {
      type: String,
      default: ''
    },
    messageOperateList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visibleScopeList: [{
        icon: 'icon-office-building',
        lable: '对公司所有人公开',
        value: 'all'
      }, {
        icon: 'icon-user-dbl',
        lable: '对指定人可见',
        value: 'some'
      }, {
        icon: 'icon-lock',
        lable: '仅自己可见',
        value: 'self'
      }],
      isInDropdown: false,
      popoverVisible: false
    };
  },
  computed: {
    isBtnVisible() {
      return this.isInDropdown || this.popoverVisible;
    }
  },
  watch: {
    popoverVisible(val) {
      if (!val) {
        if (!this.isInDropdown) {
          this.setDropdownVisible(false);
        }
      }
    }
  },
  methods: {
    onCopy() {
      this.$message.success('复制成功');
    },
    handeClick(val) {
      this.$emit('commentScopeChange', val);
      this.popoverVisible = false;
    },
    handleDropdownVisibleChange(val) {
      if (!val && this.popoverVisible) {
        this.setDropdownVisible(true);
      }
    },
    handleEnterDropdown() {
      this.isInDropdown = true;
    },
    handleLeaveDropdown() {
      this.isInDropdown = false;
    },
    setDropdownVisible(val) {
      this.$refs.dropdown.visible = val;
    }
  }
};
exports.default = _default;