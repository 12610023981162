"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _AnswerItem = _interopRequireDefault(require("./AnswerItem.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _useVuex = require("@/hooks/use-vuex");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SubQuestionDetailDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    subQuestionItem: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const workspaceId = (0, _vue.computed)(() => store.getters.workspaceInfo.id);
    const answerList = (0, _vue.ref)([]);
    const page = (0, _vue.ref)(1);
    const loading = (0, _vue.ref)(false);
    const hasMore = (0, _vue.ref)(false);
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        init();
      }
    });
    const init = () => {
      answerList.value = [];
      page.value = 1;
      getAnswerList();
    };
    const getAnswerList = async () => {
      loading.value = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeQaList)({
        page: page.value,
        size: 10,
        q_id: props.subQuestionItem.id,
        space_id: workspaceId.value,
        is_sub_freq: true
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        const {
          has_more,
          cases
        } = res.results;
        if (cases.length) {
          answerList.value = answerList.value.concat(cases);
        }
        hasMore.value = has_more;
        page.value++;
      }
    };
    return {
      __sfc: true,
      store,
      workspaceId,
      props,
      answerList,
      page,
      loading,
      hasMore,
      init,
      getAnswerList,
      NoData: _index.default,
      AnswerItem: _AnswerItem.default,
      MgInfiniteScroll: _MgInfiniteScroll.default
    };
  }
};
exports.default = _default;