"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ContactPersonCard = _interopRequireDefault(require("./ContactPersonCard"));
var _client = require("@/api/client");
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ContactPersonDetail',
  components: {
    ContactPersonCard: _ContactPersonCard.default,
    MgInfiniteScroll: _MgInfiniteScroll.default
  },
  props: {
    accountId: {
      type: [String, Number],
      default: ''
    },
    formDesc: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean
    },
    isShow: {
      type: Boolean
    }
  },
  data() {
    return {
      contactPersonList: [],
      pageSize: 10,
      loading: false,
      total: 0,
      listLength: 0,
      currentPage: 1
    };
  },
  computed: {
    noMore() {
      return this.listLength && this.listLength === this.total;
    }
  },
  created() {
    this.getContactPersonList();
  },
  mounted() {
    this.$bus.$on('fetchContactSuccess', () => {
      this.initData();
      this.getContactPersonList();
    });
  },
  beforeDestroy() {
    this.$bus.$off('fetchContactSuccess');
  },
  methods: {
    initData() {
      this.contactPersonList = [];
      this.currentPage = 1;
      this.listLength = 0;
      this.loading = false;
    },
    load() {
      this.getContactPersonList();
    },
    getContactPersonList() {
      this.loading = true;
      (0, _client.getContactPersonList)(this.accountId, this.currentPage, this.pageSize).then(res => {
        if (res.code === 20000) {
          res.results.contacts.forEach(item => {
            this.contactPersonList.push(item);
          });
          this.total = res.results.total_count;
          this.listLength += res.results.contacts.length;
          this.currentPage++;
          this.loading = false;
        }
      });
    },
    handleCancel(value, index) {
      this.$set(this.contactPersonList, index, {
        ...value
      });
    },
    handleKeyChange(_ref, index) {
      let {
        key,
        value
      } = _ref;
      this.$set(this.contactPersonList[index], key, value);
    }
  }
};
exports.default = _default;