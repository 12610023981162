"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'KnowledgeTextFilter',
  data() {
    return {
      filterParams: {
        pattern: '',
        inverse: false,
        role: 'default'
      }
    };
  },
  watch: {
    filterParams: {
      handler(val) {
        this.handleParamsChange(val);
      },
      deep: true
    }
  },
  methods: {
    handleParamsChange: (0, _commonFunc.debounce)(function (val) {
      this.$emit('textFilterChange', val);
    })
  }
};
exports.default = _default;