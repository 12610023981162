"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SpaceButton = _interopRequireDefault(require("@/components/SpaceButton"));
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _TricksLibrary = _interopRequireDefault(require("./TricksLibrary.vue"));
var _StandardTricks = _interopRequireDefault(require("./StandardTricks.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DiscoveryEngine',
  components: {
    TricksLibrary: _TricksLibrary.default,
    StandardTricks: _StandardTricks.default,
    SpaceButton: _SpaceButton.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    knowledgeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      submitLoading: false,
      list: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible(val) {
      if (!val) {
        this.list = [];
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    handleListChange(val) {
      this.list = val;
    },
    submit() {
      const standardTricks = this.$refs.standardTricks;
      standardTricks.$refs.form.validate(async valid => {
        if (valid) {
          const scene_id = standardTricks.formData.scene;
          const reply_list = standardTricks.formData.list.map(_ref => {
            let {
              value
            } = _ref;
            return {
              reply_content: value,
              reply_id: 0
            };
          }).filter(item => item.reply_content);
          if (!reply_list.length) {
            this.$message.warning('请添加标准话术');
            return;
          }
          this.submitLoading = true;
          const res = await (0, _knowledgeLibrary.addTricksToKnowledge)({
            scene_id,
            reply_list,
            space_id: this.workspaceInfo.id,
            knowledge_id: this.knowledgeId,
            conversation_ids: this.list.map(i => i.conversation_id)
          }).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 20000) {
            this.handleClose();
            this.$emit('addTricks');
          }
        }
      });
    }
  }
};
exports.default = _default;