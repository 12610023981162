"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "400px",
      title: _vm.inputInfo.title,
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      "custom-class": "common-reset-dialog",
      "append-to-body": false,
      "modal-append-to-body": false
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "footer"
        }, [_c("el-button", {
          attrs: {
            plain: ""
          },
          on: {
            click: _vm.closeDialog
          }
        }, [_vm._v("取消")]), _c("el-button", {
          attrs: {
            type: "primary",
            loading: _vm.loading
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("确定")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.inputInfo.tips ? _c("div", {
    staticClass: "tips-container"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _c("p", [_vm._v(" " + _vm._s(_vm.inputInfo.tips) + " ")])]) : _vm._e(), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c("div", {
          staticClass: "label-name"
        }, [_vm._v(" " + _vm._s(_vm.inputInfo.label) + " "), _c("span", {
          staticClass: "dot"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }, [_c("el-input", {
    ref: "nameInput",
    attrs: {
      maxlength: "20",
      placeholder: _vm.inputInfo.placeholder
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;