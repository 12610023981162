"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dynamicTabChart = _interopRequireDefault(require("./components/dynamicTabChart.vue"));
var _chartTabMixin = _interopRequireDefault(require("./chartTabMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomerProfile',
  components: {
    DynamicTabChart: _dynamicTabChart.default
  },
  mixins: [_chartTabMixin.default]
};
exports.default = _default;