"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ConversationFilterItem", {
    staticClass: "conversation-filter-item",
    attrs: {
      label: "第几次会话",
      "popover-content": "发生在单次交易中的第几次会话。",
      "is-show-clear": !(_vm.filters.conv_times.value[0] == -1 && _vm.filters.conv_times.value[1] == -1)
    },
    on: {
      handleClear: _vm.handleClearConvTimes
    }
  }, [_c("div", {
    staticClass: "conv-times-filter-btn"
  }, _vm._l(_vm.btnList, function (item) {
    return _c("el-button", {
      key: item.label,
      class: ["choose-btn", {
        active: (!_vm.filters.conv_times.is_custom && JSON.stringify(_vm.filters.conv_times.value)) == JSON.stringify(item.value) || _vm.filters.conv_times.is_custom && _vm.filters.conv_times.is_custom === item.isCustom
      }],
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("div", {
    staticClass: "conv-times-amount"
  }, [_vm.isCustomConvTimes ? _c("ConvTimesAmount", {
    attrs: {
      value: _vm.filters.conv_times.value,
      unit: "次"
    },
    on: {
      amountChange: _vm.handleTimesChange
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;