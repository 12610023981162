"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _BIKanban = _interopRequireDefault(require("@/views/businessIntelligence/components/BIKanban"));
var _InitKanbanCardView = _interopRequireDefault(require("@/views/businessIntelligence/components/InitKanbanCardView"));
var _reachDrawer = _interopRequireDefault(require("@/views/businessIntelligence/SOP/components/BusinessReach/components/reachDrawer"));
var _ChartContainer = _interopRequireDefault(require("@/views/businessIntelligence/components/ChartContainer"));
var _ChartTemplate = _interopRequireDefault(require("@/components/Charts/ChartTemplate"));
var _businessChartTable = _interopRequireDefault(require("@/views/businessIntelligence/SOP/components/BusinessReach/components/businessChartTable"));
var _shareConfig = _interopRequireDefault(require("@/views/businessIntelligence/components/shareConfig"));
var _editKanbanNameDialog = _interopRequireDefault(require("@/views/businessIntelligence/components/editKanbanNameDialog"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// kanbanTarget 区分关键事件看板和交易指标看板的返回结果，用于echarts的数据展示

let kanbanTarget = '';
function eventKanbanTooltip(params) {
  return params.value[params.seriesIndex + 2];
}
function targetKanbanTooltip(params) {
  const baseNumber = params.value[params.seriesIndex * 2 + 2];
  const percentNumber = Number(params.value[params.seriesIndex * 2 + 3]).toLocaleString('zh', {
    style: 'percent',
    maximumFractionDigits: 2
  });
  return `${baseNumber} / ${percentNumber}`;
}
const chartBaseConfig = {
  grid: [{
    containLabel: true,
    left: 20,
    right: 20,
    top: '10%',
    width: '80%',
    height: '80%'
  }],
  legend: {
    type: 'scroll'
  },
  xAxis: {},
  yAxis: {
    type: 'category',
    axisLabel: {
      formatter(value) {
        const v = value.split('-');
        v.pop();
        return v.join('');
      }
    }
  },
  tooltip: {
    appendToBody: true,
    formatter: params => {
      const v = params.name.split('-');
      v.pop();
      const name = v.join('');
      return `
      <div class="customer-reach-contrast-toolip">
        <strong>${params.seriesName}</strong>
        <div class="row">
          <div class="row-left">
            <span class="dot" style="background:${params.color}"></span>
            <span>${name}</span>
          </div>
          <div class="row-right">${kanbanTarget === 'deal_target' ? targetKanbanTooltip(params) : eventKanbanTooltip(params)}</div>
        </div>
      </div>`;
    }
  },
  dataZoom: [{
    type: 'slider',
    show: true,
    zoomLock: true,
    yAxisIndex: 0,
    width: 0,
    moveHandleSize: 10,
    height: '90%',
    top: '5%',
    right: '3%',
    startValue: 0,
    endValue: 8
  }]
};
const seriesBaseConfig = {
  type: 'bar',
  barGap: 0,
  barMaxWidth: 25,
  barMinWidth: 10,
  label: {
    show: true,
    position: 'right'
  }
};
var _default = {
  name: 'BusinessReach',
  components: {
    BIKanban: _BIKanban.default,
    InitKanbanCardView: _InitKanbanCardView.default,
    ReachDrawer: _reachDrawer.default,
    ChartContainer: _ChartContainer.default,
    ChartTemplate: _ChartTemplate.default,
    BusinessChartTable: _businessChartTable.default,
    ShareConfig: _shareConfig.default,
    EditKanbanNameDialog: _editKanbanNameDialog.default
  },
  data() {
    return {
      getKanbanListLoading: true,
      currentKanban: {},
      kanbanList: [],
      drawerVisible: false,
      getKanbanConfigLoading: false,
      getChartDataLoading: false,
      isEdit: false,
      chartConfig: {},
      tableConfig: {},
      rawData: {},
      responseKanbanInfo: {},
      operateType: 'create',
      shareKanbanConfigVisible: false,
      copyKanbanConfigVisible: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'api_permissions', 'workspaceInfo'])
  },
  watch: {
    biFiltersObj() {
      if (this.currentKanban.kanban_id) {
        this.getData();
      } else {
        this.getKanbanList();
      }
    },
    'workspaceInfo.id': {
      handler: function () {
        this.currentKanban = {};
      }
    }
  },
  created() {
    this.getKanbanList();
  },
  methods: {
    changeKanbanStatus(val) {
      this.kanbanList.find(item => item.kanban_id === this.currentKanban.kanban_id).is_sharing = !!val.length;
    },
    getExtraCommandlist() {
      return [{
        type: 'copy',
        label: '复制',
        order: 2,
        icon: 'icon-copy-document'
      }, {
        type: 'share',
        label: '分享',
        order: 3,
        icon: 'icon-share-line'
      }];
    },
    async getKanbanList() {
      if (!this.workspaceInfo.id) return;
      this.getKanbanListLoading = true;
      const res = await (0, _businessIntelligence.getBusinessReachKanbanList)({
        workspace_id: this.workspaceInfo.id
      });
      this.getKanbanListLoading = false;
      if (res.code === 200) {
        this.kanbanList = res.results.map(item => {
          item.hiddenHandle = [];
          if (item.is_share) {
            item.hiddenHandle.push('edit', 'delete', 'share');
            item.kanban_remark = `来自${item.share_user_name}的分享`;
          }
          return item;
        });
        if (res.results.length) {
          // 第一次进来默认选中第一个看板
          if (!this.currentKanban.kanban_id) {
            this.currentKanban = this.kanbanList[0];
          } else if (!this.isEdit) {
            // 新增看板默认选中新增的看板
            this.currentKanban = this.kanbanList[this.kanbanList.length - 1];
          }
          this.getData();
        }
      }
    },
    createKanban() {
      this.drawerVisible = true;
      this.isEdit = false;
    },
    toggleKanban(activeKanban) {
      if (this.currentKanban.kanban_id === activeKanban.kanban_id) {
        return;
      }
      this.currentKanban = activeKanban;
      this.getData();
    },
    handleKanban(type, data) {
      this.operateType = type;
      this[`${type}Kanban`](data);
    },
    async copyKanban() {
      this.copyKanbanConfigVisible = true;
    },
    async shareKanban() {
      this.isEdit = true;
      this.shareKanbanConfigVisible = true;
    },
    editKanban(_ref) {
      let {
        kanban_id
      } = _ref;
      this.drawerVisible = true;
      this.isEdit = true;
      this.getKanbanConfigDetail(kanban_id);
    },
    async getKanbanConfigDetail(kanban_id) {
      this.getKanbanConfigLoading = true;
      const res = await (0, _businessIntelligence.getBusinessReachKanbanDetail)({
        kanban_id,
        workspace_id: this.workspaceInfo.id
      });
      this.getKanbanConfigLoading = false;
      if (res.code === 200) {
        // 关键事件指标没有deal_field字段，需要特殊处理
        if (!res.results.kanban_info.deal_field) {
          res.results.kanban_info.deal_field = {
            id: '',
            name: '',
            key: Symbol()
          };
          res.results.kanban_info.deal_targets = [{
            key: Symbol(),
            name: '指标1',
            options: []
          }, {
            key: Symbol(),
            name: '指标2',
            options: []
          }];
          res.results.kanban_info.deal_events = [{
            key: Symbol(),
            event_id: ''
          }, {
            key: Symbol(),
            event_id: ''
          }];
        } else {
          res.results.kanban_info.events = [{
            key: Symbol(),
            event_id: ''
          }, {
            key: Symbol(),
            event_id: ''
          }];
        }
        this.$refs.reachDrawer.kanbanConfig = res.results;
        // 需要关键事件回填
        this.$nextTick(() => {
          var _res$results$kanban_i;
          if (!this.$refs.reachDrawer.$refs.eventSelect) {
            return;
          }
          this.$refs.reachDrawer.$refs.eventSelect.defalutEvent = (_res$results$kanban_i = res.results.kanban_info) === null || _res$results$kanban_i === void 0 ? void 0 : _res$results$kanban_i.full_events;
        });
      }
    },
    async deleteKanban(_ref2) {
      let {
        kanban_id,
        name
      } = _ref2;
      this.$confirm(`看板删除后，被分享人员的看板也会同步删除，是否删除？`, '删除看板', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'confirm-right'
      }).then(async () => {
        const res = await (0, _businessIntelligence.deleteBusinessReachKanban)({
          kanban_id,
          workspace_id: this.workspaceInfo.id
        });
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.kanbanList = this.kanbanList.filter(item => item.kanban_id !== kanban_id);
          if (this.kanbanList.length) {
            this.toggleKanban(this.kanbanList[0]);
          }
        }
      });
    },
    async getData() {
      if (!this.currentKanban.kanban_id || !this.biFiltersObj) return;
      if (!Object.prototype.hasOwnProperty.call(this.biFiltersObj, 'data_dimension')) {
        return;
      }
      let tree_ids, user_ids;
      if (this.biFiltersObj.data_dimension === 'tree_user_ids') {
        ({
          tree_ids,
          user_ids
        } = this.biFiltersObj.tree_user_ids);
      } else {
        tree_ids = this.biFiltersObj.tree_ids;
      }
      this.getChartDataLoading = true;
      const res = await (0, _businessIntelligence.getBusinessReachChartData)({
        kanban_id: this.currentKanban.kanban_id,
        workspace_id: this.workspaceInfo.id,
        ...this.biFiltersObj,
        tree_ids,
        user_ids
      });
      this.getChartDataLoading = false;
      if (res.code === 200) {
        const {
          heads,
          rows,
          legend,
          data_dimension,
          kanban_target
        } = res.results;
        this.rawData = res.results;
        kanbanTarget = kanban_target;
        this.tableConfig = {
          heads,
          rows,
          data_dimension
        };
        // 在此深拷贝row是因为rows在table中有用到
        const chartData = JSON.parse(JSON.stringify(rows));
        chartData.forEach((item, index) => item[1] = item[1] + '-' + index);
        // 因为echarts的datazoom默认从下往上渲染数据（滚动条也是），需求是将datazoom滚动条改为从上往下，故需要将数组反转
        this.setOptions((0, _commonFunc.deepClone)(heads), chartData.reverse(), legend);
      }
    },
    setOptions(heads, rows, legend) {
      legend = legend.filter(item => !item.name.includes('占比'));
      const colorList = [];
      chartBaseConfig.dataZoom[0].startValue = rows.length;
      chartBaseConfig.dataZoom[0].endValue = rows.length - 8 + parseInt(heads.length / 2);
      const series = legend.map(_ref3 => {
        let {
          name,
          color
        } = _ref3;
        colorList.push(color);
        const eventConfig = {
          ...seriesBaseConfig,
          name,
          // 图例数据来源

          encode: {
            x: name,
            y: heads[1]
          }
        };
        const targetConfig = {
          ...seriesBaseConfig,
          name,
          // 图例数据来源
          stack: 'reach',
          encode: {
            x: name,
            y: heads[1]
          },
          // 鼠标hover时高亮同一维度数据
          emphasis: {
            focus: 'series'
          },
          // 柱体中间文字
          label: {
            show: true,
            formatter: function (params) {
              const baseNumber = params.value[params.seriesIndex * 2 + 2];
              return baseNumber || '';
            }
          }
        };

        // 不同类型看板返回不同的echart配置
        if (kanbanTarget === 'event') {
          return eventConfig;
        }
        return targetConfig;
      });
      this.chartConfig = {
        color: colorList,
        ...chartBaseConfig,
        dataset: {
          source: [heads, ...rows]
        },
        series
      };
    },
    handleSortChange(params) {
      const {
        prop,
        order
      } = params;
      const index = this.rawData.heads.findIndex(item => item === prop);
      this.rawData.rows.sort((a, b) => {
        return order === 'ascending' ? a[index] - b[index] : b[index] - a[index];
      });
      const {
        heads,
        rows,
        legend,
        data_dimension,
        kanban_target
      } = this.rawData;
      kanbanTarget = kanban_target;
      this.tableConfig = {
        heads,
        rows,
        data_dimension
      };
      // 在此深拷贝row是因为rows在table中有用到
      const chartData = JSON.parse(JSON.stringify(rows));
      chartData.forEach((item, index) => item[1] = item[1] + '-' + index);
      // 因为echarts的datazoom默认从下往上渲染数据（滚动条也是），需求是将datazoom滚动条改为从上往下，故需要将数组反转
      this.setOptions((0, _commonFunc.deepClone)(heads), chartData.reverse(), legend);
    }
  }
};
exports.default = _default;