"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
var _timezone = require("@/utils/timezone");
var _DynamicPopover = _interopRequireDefault(require("@/components/DynamicPopover"));
var _compliance = require("@/api/compliance");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SalesApiExportButton',
  components: {
    DynamicPopover: _DynamicPopover.default
  },
  props: {
    exportApi: {
      type: Function,
      default: () => () => ({})
    },
    filtersObj: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    configurable: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getComplianceHeadConfig: _compliance.getComplianceHeadConfig,
    updateComplianceHeadConfig: _compliance.updateComplianceHeadConfig,
    async exportTable() {
      if (this.disabled) return;
      const filtersObj = (0, _commonFunc.deepClone)(this.filtersObj);
      (0, _timezone.convertParams2UTCplus8)(filtersObj);
      const data = {
        body: filtersObj,
        module: 'compliance_list',
        column: this.params.type
      };
      const res = await this.exportApi(data);
      if (res.code === 20000) {
        if (this.$notify) {
          this.$notify.closeAll();
        }
        const h = this.$createElement;
        this.$notify({
          title: '导出文件',
          message: h('div', {
            class: 'notify-content'
          }, [h('span', '文件正在准备中，您可以在个人中心 - '), h('a', {
            class: 'jump-link',
            on: {
              click: this.goExportRecords
            }
          }, '导出记录'), h('span', '中查看。')]),
          duration: 5000,
          customClass: 'conversation-list-export-file-notify'
        });
      }
    },
    goExportRecords() {
      this.$router.push({
        path: _routeMap.ROUTES.exportRecords
      });
    }
  }
};
exports.default = _default;