"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearch = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeSearch.vue"));
var _TricksScene = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksScene.vue"));
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SearchScriptDialog',
  components: {
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default,
    KnowledgeSearch: _KnowledgeSearch.default,
    TricksScene: _TricksScene.default
  },
  // 临时方案，全部知识库id为-1，需要清空
  beforeRouteLeave(to, from, next) {
    // 把 数据置为空
    this.$store.commit('training/SET_KNOWLEDGE_BASE_INFO', {});
    next();
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentScript: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentKnowledgeInfo: {},
      checkedKnowledgeScript: [],
      knowledgeLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['knowledgeBaseInfo']),
    isChecked() {
      return function (id) {
        return this.checkedKnowledgeScript.map(_ref => {
          let {
            reply_id
          } = _ref;
          return reply_id;
        }).includes(id);
      };
    }
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          setTimeout(() => {
            this.$refs['knowledge-search'].$refs['select'].focus();
          }, 500);
        }
      }
    },
    'knowledgeBaseInfo.id': {
      // 切换知识库
      handler: function (val) {
        if (val) {
          this.currentKnowledgeInfo = {};
        }
      }
    }
  },
  methods: {
    submit() {
      if (!this.checkedKnowledgeScript.length) {
        this.$message(this.$t('coaching.addScriptTip'));
        return;
      }
      let script = this.currentScript;
      this.checkedKnowledgeScript.forEach(item => script += item.reply_content);
      if (script.length > 1000) {
        this.$message.warning(this.$t('coaching.scriptLengthLimit'));
      } else {
        this.$emit('addScript', script);
        // 确定后清空数据
        this.currentKnowledgeInfo = {};
        this.checkedKnowledgeScript = [];
        this.$refs['knowledge-search'].clearData();
        this.handleClose();
        this.$message.success(this.$t('coaching.addSuccess'));
      }
    },
    deleteKnowledgeScript(val) {
      this.checkedKnowledgeScript = this.checkedKnowledgeScript.filter(item => item.reply_id !== val.reply_id);
    },
    knowledgeCheckedChange(val, checked) {
      if (checked) {
        this.checkedKnowledgeScript.push((0, _cloneDeep.default)(val));
        this.scrollToScriptEnd();
      } else {
        this.checkedKnowledgeScript = this.checkedKnowledgeScript.filter(item => item.reply_id !== val.reply_id);
      }
    },
    async searchKnowledge(_ref2) {
      let {
        id
      } = _ref2;
      this.knowledgeLoading = true;
      const res = await (0, _knowledgeLibrary.getKnowledgeInfo)({
        id
      }).finally(() => {
        this.knowledgeLoading = false;
      });
      if (res.code === 20000) {
        this.currentKnowledgeInfo = res.results.data;
      }
    },
    scrollToScriptEnd() {
      this.$nextTick(() => {
        this.$refs['script-container'].scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;