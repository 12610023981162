"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-recommend-item"
  }, [_c("div", {
    staticClass: "item-box"
  }, [_c("div", {
    staticClass: "item-header"
  }, [_c("div", {
    staticClass: "header-left"
  }, [_c("div", {
    staticClass: "type-tag",
    style: {
      background: _vm.getEventTagColor(_vm.item.event_id)
    }
  }, [_vm._v(" " + _vm._s(_vm.getEventName(_vm.item.event_id)) + " ")]), _c("el-tooltip", {
    attrs: {
      content: _vm.item.account_name ? "客户" : "交易",
      "popper-class": "conv-list-tip",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.account_name || _vm.item.trade_name ? _c("div", {
    staticClass: "entity-name",
    on: {
      click: function ($event) {
        return _vm.goEntityDetail(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont",
    class: _vm.item.account_name ? "icon-office-building" : "icon-deal4"
  }), _vm._v(_vm._s(_vm.item.account_name || _vm.item.trade_name) + " ")]) : _vm._e()])], 1), _c("div", {
    staticClass: "header-right"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.item.task_status,
      expression: "!item.task_status"
    }],
    staticClass: "add-task",
    on: {
      click: function ($event) {
        return _vm.openCreateTaskDialog(_vm.item);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-circle-plus-outline"
  }), _vm._v(" 添加任务 ")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.task_status,
      expression: "item.task_status"
    }],
    staticClass: "has-added"
  }, [_c("i", {
    staticClass: "iconfont icon-circle-check"
  }), _vm._v(" 已添加 ")]), _vm.createTaskdialogVisible ? _c("el-dialog", {
    ref: "createTaskDialog",
    attrs: {
      width: "520px",
      title: "添加任务",
      visible: _vm.createTaskdialogVisible,
      "before-close": _vm.closeCreateTaskDialog,
      "append-to-body": false,
      modal: false,
      "custom-class": "create-task-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.createTaskdialogVisible = $event;
      }
    }
  }, [_c("create-task-form", {
    ref: "createTaskForm",
    attrs: {
      "task-type": 3,
      "text-only": false,
      "default-text-info": _vm.defaultTextInfo,
      "entity-info": {
        entity_type: "todo",
        entity_id: _vm.item.id
      }
    },
    on: {
      cancelCreate: _vm.closeCreateTaskDialog,
      creatNewTask: _vm.creatNewTask
    }
  })], 1) : _vm._e()], 1)]), _c("div", {
    staticClass: "conv-content"
  }, [_c("div", {
    staticClass: "conv-info"
  }, [_c("div", {
    staticClass: "conv-name",
    on: {
      click: function ($event) {
        return _vm.goConv(_vm.item);
      }
    }
  }, [_c("i", {
    class: _vm.getConvIcon(_vm.item)
  }), _c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.conversation.name))])]), _c("div", {
    staticClass: "conv-right-info"
  }, [_c("el-tooltip", {
    attrs: {
      content: "会话日期和时间",
      placement: "top",
      trigger: "click",
      "open-delay": 200
    }
  }, [_c("div", {
    staticClass: "conv-date"
  }, [_vm._v(" " + _vm._s(_vm.getConvTime(_vm.item.conversation.conversation_date)) + " ")])]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("el-tooltip", {
    attrs: {
      content: "销售",
      placement: "top",
      "open-delay": 200
    }
  }, [_vm.item.conversation.conversation_user_name ? _c("div", {
    staticClass: "salesman"
  }, [_vm._v(" " + _vm._s(_vm.item.conversation.conversation_user_name) + " ")]) : _vm._e()])], 1)]), _c("div", {
    staticClass: "dialog-info"
  }, _vm._l(_vm.item.detail, function (todoItem, index) {
    return _c("div", {
      key: index,
      staticClass: "sentence",
      on: {
        click: function ($event) {
          return _vm.goConvSentence(_vm.item, todoItem);
        }
      }
    }, [_c("span", {
      staticClass: "speak-time",
      class: todoItem.user_type === "customer_contact" ? "customer-speak" : ""
    }, [_c("i", {
      staticClass: "iconfont icon-mark"
    }), _vm._v(_vm._s(todoItem.speak_time))]), _c("span", {
      staticClass: "speaker"
    }, [_vm._v(" " + _vm._s(todoItem.user_name) + "：")]), _c("span", {
      staticClass: "text",
      domProps: {
        innerHTML: _vm._s(_vm.getHighlightContent(todoItem))
      }
    })]);
  }), 0)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;