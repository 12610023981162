"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "workspace"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "title-content"
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v("空间")]), _c("space-description-popover")], 1), _c("div", [_c("el-button", {
    staticClass: "create-space-btn",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.openDialog("add");
      }
    }
  }, [_vm._v(" 新建 ")]), _c("el-button", {
    staticClass: "set-space-mapping-btn",
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.openDepartmentAndWorkspaceMappingDialog
    }
  }, [_vm._v(" 配置部门与空间关系 ")])], 1)]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_c("workspace-table", {
    attrs: {
      "table-data": _vm.tableData
    },
    on: {
      openDialog: _vm.openDialog,
      transferConversation: _vm.openTransferConversationDialog,
      deleteSuccess: _vm.getWorkspaceList
    }
  })], 1), _c("operate-workspace-dialog", {
    attrs: {
      "dialog-visible": _vm.dialogVisible,
      type: _vm.operateType,
      "table-data": _vm.tableData,
      "workspace-item": _vm.currentWorkspaceInfo
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.dialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      updateSuccess: _vm.getWorkspaceList
    }
  }), _c("transfer-conversation-dialog", {
    ref: "transferConversationDialog",
    attrs: {
      "dialog-visible": _vm.transferConversationDialogVisible,
      "workspace-item": _vm.currentWorkspaceInfo,
      "conversation-count": _vm.convCount,
      "recycle-conv-count": _vm.recycleConvCount
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.transferConversationDialogVisible = $event;
      },
      submit: _vm.batchTransferConversation
    }
  }), _c("department-and-workspace-mapping-drawer", {
    attrs: {
      "table-loading": _vm.tableLoading,
      "dialog-visible": _vm.departmentAndWorkspaceMappingVisible,
      "department-and-workspace-mapping-list": _vm.departmentAndWorkspaceMappingList
    },
    on: {
      closeDepartmentAndWorkspaceMappingDialog: _vm.closeDepartmentAndWorkspaceMappingDialog,
      confirmToChangeDepartmentAndSpace: _vm.confirmToChangeDepartmentAndSpace
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;