"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnnotate = useAnnotate;
exports.useCopyDataSet = useCopyDataSet;
exports.useCreateOrEditDataSet = useCreateOrEditDataSet;
exports.useDeleteDataSet = useDeleteDataSet;
exports.useImportData = useImportData;
exports.useTestModel = useTestModel;
exports.useTrainingModel = useTrainingModel;
var _vue = require("vue");
var _elementUi = require("element-ui");
var _dataSet = require("@/api/dataSet");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
function useImportData(currentDataSet) {
  const importDataDialogVisible = (0, _vue.ref)(false);
  const openImportDataDialog = val => {
    importDataDialogVisible.value = true;
    currentDataSet.value = val;
  };
  return {
    importDataDialogVisible,
    openImportDataDialog
  };
}
function useCopyDataSet(currentDataSet) {
  const copyDataSetDialogVisible = (0, _vue.ref)(false);
  const openCopyDataSetDialog = val => {
    copyDataSetDialogVisible.value = true;
    currentDataSet.value = val;
  };
  return {
    copyDataSetDialogVisible,
    openCopyDataSetDialog
  };
}
function useDeleteDataSet(refreshTable) {
  const onDeleteDataSet = val => {
    _elementUi.MessageBox.confirm(`确定要删除数据集${val.name}吗？`, '删除', {
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      type: 'warning',
      confirmButtonClass: 'el-button--danger'
    }).then(async () => {
      const res = await (0, _dataSet.deleteDataSet)({
        dataset_id: val.id
      });
      if (res.code === 20000) {
        _elementUi.Message.success('删除成功');
        refreshTable();
      }
    }).catch(() => {});
  };
  return {
    onDeleteDataSet
  };
}
function useCreateOrEditDataSet(currentDataSet) {
  const createDataSetDialogVisible = (0, _vue.ref)(false);
  const editDataSet = val => {
    createDataSetDialogVisible.value = true;
    currentDataSet.value = val;
  };
  const createDataSet = () => {
    createDataSetDialogVisible.value = true;
    currentDataSet.value = {};
  };
  return {
    createDataSetDialogVisible,
    editDataSet,
    createDataSet
  };
}
function useAnnotate() {
  const router = (0, _useRouter.useRouter)();
  const goAnnotatePage = query => {
    router.push({
      path: _routeMap.ROUTES.annotate,
      query: query
    });
  };
  return {
    goAnnotatePage
  };
}
function useTrainingModel(currentDataSet) {
  const trainModelDialogVisible = (0, _vue.ref)(false);
  const trainingModel = row => {
    currentDataSet.value = row;
    trainModelDialogVisible.value = true;
  };
  return {
    trainModelDialogVisible,
    trainingModel
  };
}
function useTestModel(currentDataSet) {
  const testModelDialogVisible = (0, _vue.ref)(false);
  const testModel = row => {
    testModelDialogVisible.value = true;
    currentDataSet.value = row;
  };
  return {
    testModelDialogVisible,
    testModel
  };
}