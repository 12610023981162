"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.num2percent = num2percent;
exports.number_to_amount = number_to_amount;
exports.parseFormatNum = parseFormatNum;
exports.str_to_amount = str_to_amount;
function number_to_amount(n) {
  var b = parseInt(n).toString();
  var len = b.length;
  if (len <= 3) {
    return b;
  }
  var r = len % 3;
  return r > 0 ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',') : b.slice(r, len).match(/\d{3}/g).join(',');
}
function str_to_amount(n) {
  var b = n.split('.')[0];
  var t = n.split('.')[1];
  var len = b.length;
  if (len <= 3) {
    return b + '.' + t;
  }
  var r = len % 3;
  const e = r > 0 ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',') : b.slice(r, len).match(/\d{3}/g).join(',');
  return e + '.' + t;
}

// 带小数点
function parseFormatNum(number, n, noThousandsSeparate) {
  number = parseFloat(number);
  if (!number) {
    number = 0.0;
  }
  if (n != 0) {
    n = n > 0 && n <= 20 ? n : 2;
  }
  number = parseFloat((number + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  if (noThousandsSeparate) return number;
  var sub_val = number.split('.')[0].split('').reverse();
  var sub_xs = number.split('.')[1];
  var show_html = '';
  for (let i = 0; i < sub_val.length; i++) {
    show_html += sub_val[i] + ((i + 1) % 3 == 0 && i + 1 != sub_val.length ? ',' : '');
  }
  if (n == 0) {
    return show_html.split('').reverse().join('');
  } else {
    return show_html.split('').reverse().join('') + '.' + sub_xs;
  }
}

// 把数字保留两位小数
function num2percent(num) {
  let n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return Math.round(num * Math.pow(10, n + 2)) / Math.pow(10, n) + '%';
}