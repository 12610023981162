"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "select",
    attrs: {
      placeholder: _vm.placeholder,
      "popper-class": "knowledge-catalog-select-poover",
      "popper-append-to-body": false
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.label,
      callback: function ($$v) {
        _vm.label = $$v;
      },
      expression: "label"
    }
  }, [_c("el-option", {
    attrs: {
      value: "0"
    }
  }, [_c("knowledge-library-catalog", {
    ref: "catalog",
    attrs: {
      draggable: false,
      "is-show-folder-icon": false,
      "catalog-data": _vm.catalogData,
      "current-node": _vm.currentNode,
      "show-operation-btns": false
    },
    on: {
      nodeClick: _vm.handleNodeClick
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;