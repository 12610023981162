"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CommentInput = _interopRequireDefault(require("@/components/CommentInput"));
var _videoDetail = require("@/api/videoDetail");
var _business = require("@/api/business");
var _library = require("@/api/library");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _SendBoxHeader = _interopRequireDefault(require("./SendBoxHeader.vue"));
var _InsightClipSelector = _interopRequireDefault(require("./InsightClipSelector.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SendBox',
  components: {
    CommentInput: _CommentInput.default,
    SendBoxHeader: _SendBoxHeader.default,
    InsightClipSelector: _InsightClipSelector.default
  },
  props: {
    isUnfolded: {
      type: Boolean,
      default: true
    },
    isReply: {
      type: Boolean,
      default: false
    },
    recipientName: {
      type: String,
      default: ''
    },
    replyCommentId: {
      type: Number,
      default: 0
    },
    commentType: {
      type: String,
      default: 'conversation'
    },
    departmentManagerInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      members: [],
      originMembers: [],
      commentVisiblePermission: false,
      hasMention: false,
      sendBtnLoading: false,
      teacherId: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curMediaType', 'isChoose', 'commentStatus', 'userId', 'workspaceInfo', 'selectedSentenceOfComment']),
    commentInputDisabled() {
      return this.commentStatus === 'default' && !this.isReply && (this.commentType === 'conversation' || this.commentType === 'snippet') && (0, _isEmpty.default)(this.selectedSentenceOfComment);
    },
    placeholder() {
      if (this.isReply) {
        return `回复 ${this.recipientName}`;
      }
      if (this.commentStatus === 'teach') {
        return '写下你的辅导建议...（Enter 发布，Shift + Enter 换行）';
      }
      if (this.commentStatus === 'help') {
        return '写下你的问题...（Enter 发布，Shift + Enter 换行）';
      }
      if ((0, _isEmpty.default)(this.selectedSentenceOfComment)) {
        return '如需评论，请先选中一条发言';
      }
      return this.isUnfolded ? '写下你的评论...（Enter 发布，Shift + Enter 换行）可以 @ 同事' : '写下你的评论...';
    },
    currentTimeLabel() {
      if (this.curMediaType === 'doc') {
        if ((0, _isEmpty.default)(this.selectedSentenceOfComment)) {
          return '00:00';
        }
        return this.$moment(this.selectedSentenceOfComment.begin_time * 1000).format('YYYY-MM-DD HH:mm');
      }
      return this.realFormatSecond(this.selectedSentenceOfComment.begin_time);
    },
    sendDisabled() {
      if (this.commentStatus === 'help') {
        return !this.teacherId;
      }
      return false;
    },
    showCommentPermissionCheckbox() {
      return this.commentStatus === 'default' && !this.isReply && this.hasMention;
    }
  },
  watch: {
    isUnfolded(val) {
      this.$nextTick(() => {
        if (this.$refs.commentInput && val) {
          this.$refs.commentInput.focus();
        }
      });
    },
    selectedSentenceOfComment: {
      handler() {
        var _this$$refs$commentIn;
        (_this$$refs$commentIn = this.$refs.commentInput) === null || _this$$refs$commentIn === void 0 ? void 0 : _this$$refs$commentIn.focus();
      },
      immediate: true
    },
    commentStatus(val) {
      if (val === 'help') {
        setTimeout(() => {
          var _this$$refs$SendBoxHe;
          (_this$$refs$SendBoxHe = this.$refs.SendBoxHeader.$refs['teacher-select']) === null || _this$$refs$SendBoxHe === void 0 ? void 0 : _this$$refs$SendBoxHe.focus();
        }, 0);
        this.clearText();
      }
      if (val === 'teach') {
        this.$refs.commentInput.focus();
      }
    }
  },
  created() {
    // this.getAtableUsers();
    this.getCommentPermissionCheckBoxValue();
  },
  beforeDestroy() {
    this.$store.commit('video/SET_SELECTED_SENTENCE_OF_COMMENT', {});
  },
  methods: {
    getCommentPermissionCheckBoxValue() {
      const res = localStorage.getItem(`${this.userId}commentPermissionCheckBox`);
      if (res === 'true') {
        this.commentVisiblePermission = true;
      }
    },
    handleCommentPermissionChange(val) {
      localStorage.setItem(`${this.userId}commentPermissionCheckBox`, val);
    },
    isEmpty: _isEmpty.default,
    clearText() {
      if (this.$refs['commentInput']) {
        this.$refs['commentInput'].clearComment();
      }
    },
    cancelHelpOrTeach() {
      this.clearText();
      this.$store.dispatch('video/set_comment_status', 'default');
      this.$emit('update:isReply', false);
    },
    async getAtableUsers() {
      let res;
      switch (this.commentType) {
        case 'conversation':
          res = await (0, _videoDetail.getConvAtableUsers)({
            conversation_id: this.$route.query.id
          });
          this.originMembers = res.results.visiable_list;
          this.members = this.handleAddTopFiveToMembers(this.originMembers);
          break;
        case 'business':
          res = await (0, _business.getBusiAtableUsers)({
            deal_id: this.$route.query.id
          });
          this.originMembers = res.results.visiable_list;
          this.members = this.handleAddTopFiveToMembers(this.originMembers);
          break;
        case 'snippet':
          res = await (0, _library.getSnipAtableUsers)({
            snippet_id: this.$route.query.id
          });
          this.originMembers = res.results.users;
          this.members = this.handleAddTopFiveToMembers(this.originMembers);
          break;
      }
    },
    handleAddTopFiveToMembers(members) {
      const localMembers = (0, _commonFunc.deepClone)(members);
      const topFiveMembersStr = localStorage.getItem('userAt');
      const includeTopFiveMembers = [];
      if (topFiveMembersStr) {
        const topFiveMembersArr = JSON.parse(topFiveMembersStr);
        topFiveMembersArr.map(member => {
          localMembers.map((item, i) => {
            if (member.id === item.id && member.is_department === item.is_department) {
              includeTopFiveMembers.push(item);
              localMembers.splice(i--, 1);
            }
          });
        });
      }
      if (this.departmentManagerInfo.id) {
        const res = [...includeTopFiveMembers, ...localMembers].filter(item => item.id != this.departmentManagerInfo.id);
        return [this.departmentManagerInfo].concat(res);
      }
      return [...includeTopFiveMembers, ...localMembers];
    },
    textFocus() {
      this.$emit('update:isUnfolded', true);
    },
    async sendComment() {
      const {
        mentionIds: mention_ids,
        imgs,
        content
      } = this.$refs.commentInput.getComment();
      if (!content && imgs.length === 0) {
        this.$message.info('不能发送空评论');
        return;
      }
      if (content.length > 1000) {
        this.$message.info('评论内容不能超过1000个字符, 当前字符数为' + content.length);
        return;
      }
      // return
      let advisor = '';
      if (this.commentStatus === 'help') {
        advisor = this.teacherId;
      } else if (this.commentStatus === 'teach') {
        advisor = this.userId;
      } else {
        advisor = null;
      }
      const params = {
        entity_id: this.$route.query.id,
        comment_id: this.isReply ? this.replyCommentId : 0,
        content,
        mention_ids,
        imgs,
        comment_timestamp: (0, _isEmpty.default)(this.selectedSentenceOfComment) ? 0 : this.selectedSentenceOfComment.begin_time,
        visiable_users: (() => {
          if (this.commentStatus === 'help') {
            return [this.teacherId];
          } else {
            return [];
          }
        })(),
        open_scope: (() => {
          return this.showCommentPermissionCheckbox && this.commentVisiblePermission ? 'some' : 'all';
        })(),
        advisor: advisor,
        workspace_id: this.workspaceInfo.id
      };
      if (this.commentStatus === 'teach') {
        const addedKnowledges = this.$refs.insightClipSelector.getAddedKnowledges();
        const addedSnippets = this.$refs.insightClipSelector.getAddedSnippets();
        const proposals = [...addedKnowledges, ...addedSnippets];
        if (proposals.length) {
          params.proposals = proposals;
        }
      }
      const funcMap = {
        conversation: _videoDetail.commentConversation,
        business: _business.commentBusiness,
        snippet: _library.commentSnippet
      };
      this.sendBtnLoading = true;
      try {
        const res = await funcMap[this.commentType](params);
        if (res.code === 200) {
          this.$refs.commentInput.clearComment();
          this.$emit('success');
          this.getCommentPermissionCheckBoxValue();
          this.members = this.handleAddTopFiveToMembers(this.originMembers);
          this.commentStatus === 'teach' && this.clearProposals();
        }
      } finally {
        this.sendBtnLoading = false;
      }
    },
    realFormatSecond(second) {
      var secondType = typeof second;
      if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);
        var hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        var minute = Math.floor(second / 60);
        second = second - minute * 60;
        if (hours) {
          return ('0' + hours).slice(-2) + ':' + ('0' + minute).slice(-2) + ':' + ('0' + second).slice(-2);
        } else {
          return ('0' + minute).slice(-2) + ':' + ('0' + second).slice(-2);
        }
      } else {
        return '00:00';
      }
    },
    clearProposals() {
      this.$refs.insightClipSelector.clearProposals();
    }
  }
};
exports.default = _default;