"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "score-rule-container"
  }, [!_vm.formatScoreList.length && !_vm.loading ? _c("NoData", {
    attrs: {
      tips: "暂无交易评分"
    }
  }) : [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeIds.length,
      expression: "activeIds.length"
    }],
    staticClass: "key-event-detail"
  }, [_c("div", {
    staticClass: "key-event-detail-header"
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left",
    on: {
      click: _vm.handleBackUp
    }
  }), _c("span", [_vm._v(_vm._s(_vm.titleName))])]), _c("EventsTabContent", {
    attrs: {
      "active-ids": _vm.activeIds,
      "is-show-event-tags": false,
      "active-tab-name": _vm.activeTabName,
      "trade-id": _vm.tradeId
    }
  })], 1), _c("score-rule-content", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.activeIds.length,
      expression: "!activeIds.length"
    }],
    attrs: {
      "score-list": _vm.scoreList
    },
    on: {
      toggleAllExpandAndCollapse: _vm.toggleAllExpandAndCollapse,
      toggleItemExpandAndCollapse: _vm.toggleItemExpandAndCollapse,
      triggerKeyEvent: _vm.triggerKeyEvent,
      triggerAccountTag: _vm.triggerAccountTag,
      refreshScore: _vm.refreshScore
    }
  })]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;