"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ScoreRuleDateFilter',
  components: {},
  props: {
    dateFilter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hasDateLimit: true,
      options: [{
        value: 'after',
        label: '晚于'
      }, {
        value: 'before',
        label: '早于'
      }, {
        value: 'range',
        label: '范围'
      }],
      time: '',
      filter: {
        type: 'after',
        range: []
      }
    };
  },
  computed: {
    pickerOptions() {
      const self = this;
      if (this.filter.type === 'after') {
        return {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              self.setDatePickerOptions(picker, 1);
            }
          }, {
            text: '7天前',
            onClick(picker) {
              self.setDatePickerOptions(picker, 7);
            }
          }, {
            text: '30天前',
            onClick(picker) {
              self.setDatePickerOptions(picker, 30);
            }
          }, {
            text: '3个月前',
            onClick(picker) {
              self.setDatePickerOptions(picker, 90);
            }
          }]
        };
      }
      return {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            self.setDatePickerOptions(picker, -1);
          }
        }, {
          text: '7天后',
          onClick(picker) {
            self.setDatePickerOptions(picker, -7);
          }
        }, {
          text: '30天后',
          onClick(picker) {
            self.setDatePickerOptions(picker, -30);
          }
        }, {
          text: '3个月后',
          onClick(picker) {
            self.setDatePickerOptions(picker, -90);
          }
        }]
      };
    }
  },
  watch: {
    dateFilter: {
      handler: function (val) {
        const {
          range,
          type
        } = val;
        if (type === 'unlimit') {
          this.hasDateLimit = false;
        } else {
          this.filter.type = type;
        }
        this.time = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        if (type === 'range') {
          this.filter.range = range;
        } else if (range !== null && range !== void 0 && range.length) {
          this.time = range[0];
          this.filter.range = [this.time];
        }
      },
      immediate: true
    }
  },
  methods: {
    setDatePickerOptions(picker, count) {
      const date = this.$moment().subtract(count, 'days').format('YYYY-MM-DD HH:mm');
      picker.$emit('pick', date);
    },
    handleRadioChange(val) {
      if (!val) {
        this.$emit('change', {
          type: 'unlimit',
          range: []
        });
      } else {
        // 默认选择时间筛选类型为 晚于
        if (!this.filter.type) {
          this.filter.type = 'after';
          this.filter.range = [this.time];
        }
        this.$emit('change', this.filter);
      }
    },
    handleTypeChange() {
      if (this.filter.type === 'range' && this.filter.range.length < 2) {
        this.filter.range = [this.$moment().format('YYYY-MM-DD') + ' 00:00:00', this.$moment().format('YYYY-MM-DD') + ' 23:59:59'];
      } else {
        this.filter.range = [this.time];
      }
      this.filter.range.length && this.$emit('change', this.filter);
    },
    dateChange(val) {
      this.filter.range = [val];
      this.$emit('change', this.filter);
    },
    dateRangeChange(val) {
      if (!val) {
        this.filter.range = [this.$moment().format('YYYY-MM-DD') + ' 00:00:00', this.$moment().format('YYYY-MM-DD') + ' 23:59:59'];
      }
      val && this.$emit('change', this.filter);
    }
  }
};
exports.default = _default;