"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _RankingPersonsList = _interopRequireDefault(require("./RankingPersonsList"));
var _RankingFilesList = _interopRequireDefault(require("./RankingFilesList"));
var _library = require("@/api/library");
var _photoRanking = _interopRequireDefault(require("@/assets/photoRanking.png"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    RankingPersons: _RankingPersonsList.default,
    RankingFilesList: _RankingFilesList.default
  },
  data() {
    return {
      rankingPersonsList: [],
      defaultPhoto: _photoRanking.default,
      snippetsMonthNewest: [],
      snippetsMonthHots: [],
      folderMonthNewest: [],
      folderMonthHots: [],
      snippetsMonthNewestInfo: {
        icon: 'icon-time-fill',
        color: '#F4AF00',
        title: '上月最新剪辑',
        cardType: 'snippet'
      },
      snippetsMonthHotsInfo: {
        icon: 'icon-hot',
        color: '#F43F7C',
        title: '上月最热剪辑',
        cardType: 'snippet'
      },
      folderMonthNewestInfo: {
        icon: 'icon-time-fill',
        color: '#F4AF00',
        title: '上月最新文件夹',
        cardType: 'folder'
      },
      folderMonthHotsInfo: {
        icon: 'icon-hot',
        color: '#F43F7C',
        title: '上月最热文件夹',
        cardType: 'folder'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getTopPerformers();
    this.getSnippetsRankingList();
    this.getFoldersRankingList();
  },
  methods: {
    getSnippetsRankingList() {
      (0, _library.getSnippetsRankingList)({
        space_id: this.workspaceInfo.id
      }).then(res => {
        if (res.code === 20000) {
          const results = res.results;
          this.snippetsMonthNewest = results.month_newest;
          this.snippetsMonthHots = results.month_hots;
        }
      });
    },
    getFoldersRankingList() {
      (0, _library.getFoldersRankingList)({
        space_id: this.workspaceInfo.id
      }).then(res => {
        if (res.code === 20000) {
          const results = res.results;
          this.folderMonthNewest = results.month_newest;
          this.folderMonthHots = results.month_hots;
        }
      });
    },
    getTopPerformers() {
      (0, _library.getTopPerformers)({
        space_id: this.workspaceInfo.id
      }).then(res => {
        if (res.code === 20000) {
          const results = res.results;
          this.handlePerformersRes(results);
        }
      });
    },
    handlePerformersRes(res) {
      const list = [...res.honey];
      // eslint-disable-next-line no-unmodified-loop-condition
      while (list && list.length > 0 && list.length < 8) {
        list.push({
          avatar: _photoRanking.default,
          count: '',
          id: null,
          name: '虚位以待'
        });
      }
      this.rankingPersonsList = list;
    }
  }
};
exports.default = _default;