"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", [_c("li", {
    staticClass: "transfer-data-list-title"
  }, [_c("div", {
    staticClass: "resources-name"
  }, [_vm._v(" " + _vm._s(_vm.isDepartment ? _vm.$t("management.数据") : _vm.$t("management.资源")) + " ")]), _c("div", {
    staticClass: "receiver-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.isDepartment ? _vm.$t("management.接收部门") : _vm.$t("management.接收人")))])])]), _vm._l(_vm.showTableData, function (row) {
    return _c("li", {
      key: row.dataType,
      class: ["transfer-data-list-title", "resources-item"],
      on: {
        click: function ($event) {
          return _vm.handleRowClick(row);
        }
      }
    }, [_c("div", {
      staticClass: "resources-name"
    }, [_vm._v(_vm._s(row.resourcesName))]), _c("div", {
      staticClass: "receiver-title"
    }, [row.type === "edit" ? _c("div", [_vm.isDepartment ? _c("department-single-selector", {
      ref: "department-single-selector",
      refInFor: true,
      attrs: {
        placeholder: _vm.$t("management.部门"),
        "request-method": _vm.getOrgTree,
        "request-params": {
          type: JSON.stringify([_vm.getPermissionType(row.dataType)]),
          workspace_id: _vm.workspaceInfo.id
        },
        "no-cache": ""
      },
      on: {
        change: function ($event) {
          return _vm.setDepartmentLabel(arguments, row);
        }
      }
    }) : _c("members-finder", {
      ref: "members-finder",
      refInFor: true,
      attrs: {
        "popper-class": "data-transfer-drawer-members-finder",
        placeholder: _vm.$t("management.搜索成员"),
        "is-radio": true
      },
      on: {
        change: function ($event) {
          return _vm.handleMemberChange(arguments, row);
        }
      }
    })], 1) : _c("div", {
      staticClass: "sourse-receiver"
    }, [_c("div", {
      staticClass: "receiver-name"
    }, [_vm.isDepartment ? _c("mgv-avatar-name", {
      staticClass: "department-icon",
      attrs: {
        icon: `iconfont icon-${_vm.departmentInfo.fid ? "department" : "office-building"}`,
        size: 28
      }
    }) : _c("mgv-avatar-name", {
      staticClass: "upper-avatar",
      attrs: {
        name: row.name,
        src: row.avatar,
        size: 28
      }
    }), _c("span", {
      staticClass: "receiver-name-text"
    }, [_vm._v(_vm._s(row.name))])], 1), _c("el-button", {
      staticClass: "operate",
      attrs: {
        type: "text"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.changeSourseReceiver(row);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("management.修改")))])], 1)])]);
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;