"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useEvaluateForm = require("../hooks/use-evaluate-form");
var _WidgetCascader = _interopRequireDefault(require("./WidgetCascader.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'WidgetFormItem',
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    optionIds: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    cascaderIds: {
      type: Array,
      default: () => []
    },
    fieldType: {
      type: Number,
      default: 1
    }
  },
  emits: ['input', 'optionFieldChange', 'cascaderFieldChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const fieldType = (0, _vue.computed)(() => (0, _useEvaluateForm.getField)(props.fieldType).type);
    const onInput = val => {
      emit('input', val);
    };
    const handleRadioChange = val => {
      emit('optionFieldChange', [val]);
      props.options.forEach(item => item.is_checked = item.id === val ? 1 : 0);
    };
    const handleCheckboxChange = val => {
      emit('optionFieldChange', val);
      props.options.forEach(item => item.is_checked = val.includes(item.id) ? 1 : 0);
    };
    const handleCascaderFieldChange = val => {
      console.log('???');
      emit('cascaderFieldChange', val);
    };
    return {
      __sfc: true,
      props,
      fieldType,
      emit,
      onInput,
      handleRadioChange,
      handleCheckboxChange,
      handleCascaderFieldChange,
      WidgetCascader: _WidgetCascader.default
    };
  }
};
exports.default = _default;