"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _CustomerReachChart = _interopRequireDefault(require("../../components/CustomerReachChart.vue"));
var _CustomerReachTable = _interopRequireDefault(require("../../components/CustomerReachTable.vue"));
var _SuggestionBox = _interopRequireDefault(require("../../components/SuggestionBox.vue"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _timezone = require("@/utils/timezone");
var _commonFunc = require("@/utils/commonFunc");
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CustomerReach',
  components: {
    CustomerReachChart: _CustomerReachChart.default,
    CustomerReachTable: _CustomerReachTable.default,
    SuggestionBox: _SuggestionBox.default
  },
  mixins: [_ExportMixins.default],
  data() {
    return {
      departmentName: '',
      chartTitle: '',
      chartLoading: false,
      chartData: [],
      chartNames: [],
      chartLegend: [],
      tableHeads: [],
      tableData: [],
      tableLoading: false,
      xAxisMax: 1,
      hasBusinessInfo: false,
      businessInfo: [],
      businessDesc: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['biFiltersObj', 'workspaceInfo', 'timezone'])
  },
  watch: {
    biFiltersObj: {
      handler(val, oldVal) {
        if (!val.tree_user_ids) return;
        this.getData(val);
        this.setDepartmentName();
        if (JSON.stringify(val.event_list) !== JSON.stringify((oldVal === null || oldVal === void 0 ? void 0 : oldVal.event_list) || {})) {
          this.setChartTitle();
        }
      },
      deep: true
    }
  },
  methods: {
    exportTableCsv() {
      const data = [this.tableHeads, ...this.tableData];
      const {
        activeTabName,
        departmentName,
        startDate,
        endDate
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${departmentName}_${startDate}至${endDate}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(data), `${fileName}.csv`);
    },
    initData() {
      this.chartNames = [];
      this.tableHeads = [];
      this.tableData = [];
    },
    async getData(val) {
      const {
        date,
        event_list,
        tree_user_ids,
        exclude_event_list,
        custom_filters,
        analysis_basis
      } = val;
      const {
        tree_ids,
        user_ids
      } = tree_user_ids;
      // 兼容：空间无关键事件发请求导致报错
      if (!event_list || !event_list.length) {
        this.initData();
        return;
      }
      this.chartLoading = true;
      this.tableLoading = true;
      const customFilters = (0, _commonFunc.deepClone)(custom_filters);
      (0, _timezone.convertParams2UTCplus8)(customFilters);
      const res = await (0, _businessIntelligence.getCustomerReachData)({
        tree_ids,
        user_ids,
        date,
        event_list,
        exclude_event_list,
        custom_filters: customFilters,
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone,
        analysis_basis
      }).finally(() => {
        this.chartLoading = false;
        this.tableLoading = false;
      });
      if (res.code === 200) {
        if ((0, _isEmpty.default)(res.results)) {
          this.initData();
          return;
        }
        const {
          heads,
          legend,
          names,
          rows
        } = res.results;
        this.hasBusinessInfo = heads[heads.length - 1] === '触达率'; // 临时方案：只给小站教育上新增的三个字段，其余团队不上。hasBusinessInfo为true表示有这三个字段
        this.hasBusinessInfo ? heads[heads.length - 7] = '总分' : heads[heads.length - 1] = '总分';
        this.tableHeads = heads;
        // 触达率加单位 %
        this.hasBusinessInfo && rows.forEach(item => {
          item[item.length - 1] += '%';
        });
        this.tableData = rows;
        if (this.hasBusinessInfo) {
          this.businessInfo = rows.map(item => item.slice(item.length - 6));
          this.businessDesc = heads.slice(heads.length - 6);
        }
        this.chartData = this.getChartData(rows);
        this.chartNames = names.reverse();
        this.chartLegend = legend;
        if (names.length && rows.length) {
          this.xAxisMax = this.hasBusinessInfo ? Number(rows[0][rows[0].length - 7].toFixed(2)) : Number(rows[0][rows[0].length - 1].toFixed(2));
        }
      }
    },
    setDepartmentName() {
      this.departmentName = sessionStorage.getItem('tree_id_label');
    },
    setChartTitle() {
      this.chartTitle = sessionStorage.getItem('bi-boxplot-event-label');
    },
    getChartData(arr) {
      const res = this.getTransposedArr(arr);
      const end = this.hasBusinessInfo ? res.length - 7 : res.length - 1;
      return res.slice(1, end).map(item => item.reverse());
    },
    getTransposedArr(arr) {
      if (!arr.length) return [];
      return arr[0].map((_, index) => {
        return arr.map(row => row[index]);
      });
    }
  }
};
exports.default = _default;