"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: ['minTime',
  // 最小时间   用于判断开始时间的选择范围
  'endTime',
  // 结束时间   用于反显
  'type',
  // 时间类型
  'endDate',
  // 结束日期    判断结束时间的选择范围
  'beginDate',
  // 开始日期  判断开始和结束时间的选择范围
  'beginTime' // 开始时间   用于反显和判断结束时间的选择范围
  ],

  data() {
    return {
      flag: true,
      finFlag: false,
      value: '',
      options: [{
        value: '00:00',
        disabled: false
      }, {
        value: '00:30',
        disabled: false
      }, {
        value: '01:00',
        disabled: false
      }, {
        value: '01:30',
        disabled: false
      }, {
        value: '02:00',
        disabled: false
      }, {
        value: '02:30',
        disabled: false
      }, {
        value: '03:00',
        disabled: false
      }, {
        value: '03:30',
        disabled: false
      }, {
        value: '04:00',
        disabled: false
      }, {
        value: '04:30',
        disabled: false
      }, {
        value: '05:00',
        disabled: false
      }, {
        value: '05:30',
        disabled: false
      }, {
        value: '06:00',
        disabled: false
      }, {
        value: '06:30',
        disabled: false
      }, {
        value: '07:00',
        disabled: false
      }, {
        value: '07:30',
        disabled: false
      }, {
        value: '08:00',
        disabled: false
      }, {
        value: '08:30',
        disabled: false
      }, {
        value: '09:00',
        disabled: false
      }, {
        value: '09:30',
        disabled: false
      }, {
        value: '10:00',
        disabled: false
      }, {
        value: '10:30',
        disabled: false
      }, {
        value: '11:00',
        disabled: false
      }, {
        value: '11:30',
        disabled: false
      }, {
        value: '12:00',
        disabled: false
      }, {
        value: '12:30',
        disabled: false
      }, {
        value: '13:00',
        disabled: false
      }, {
        value: '13:30',
        disabled: false
      }, {
        value: '14:00',
        disabled: false
      }, {
        value: '14:30',
        disabled: false
      }, {
        value: '15:00',
        disabled: false
      }, {
        value: '15:30',
        disabled: false
      }, {
        value: '16:00',
        disabled: false
      }, {
        value: '16:30',
        disabled: false
      }, {
        value: '17:00',
        disabled: false
      }, {
        value: '17:30',
        disabled: false
      }, {
        value: '18:00',
        disabled: false
      }, {
        value: '18:30',
        disabled: false
      }, {
        value: '19:00',
        disabled: false
      }, {
        value: '19:30',
        disabled: false
      }, {
        value: '20:00',
        disabled: false
      }, {
        value: '20:30',
        disabled: false
      }, {
        value: '21:00',
        disabled: false
      }, {
        value: '21:30',
        disabled: false
      }, {
        value: '22:00',
        disabled: false
      }, {
        value: '22:30',
        disabled: false
      }, {
        value: '23:00',
        disabled: false
      }, {
        value: '23:30',
        disabled: false
      }]
    };
  },
  watch: {
    // 反显和选择此刻使用
    beginTime: {
      handler: function (newValue, oldValue) {
        if (this.type === 'begin') this.value = newValue;
        if (this.type === 'finish') this.value = newValue;
      },
      deep: true,
      immediate: true
    },
    endTime: {
      handler: function (newValue, oldValue) {
        if (this.type === 'end') this.value = newValue;
      },
      deep: true,
      immediate: true
    },
    type: {
      handler: function (newValue, oldValue) {
        if (newValue === 'finish') {
          this.finFlag = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    timeFocus() {
      if (this.type === 'end') {
        // 如果类型为结束时间
        this.flag = false;
        this.options.forEach(item => {
          const _begin = this.beginDate + ' ' + this.beginTime;
          const _end = this.endDate + ' ' + item.value;
          if (!this.$moment(_begin).isBefore(_end)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      } else if (this.type === 'begin') {
        // 如果类型为开始时间
        const todayDate = this.$moment().format('YYYY-MM-DD');
        this.flag = true;
        this.$emit('flag', this.flag);
        this.options.forEach(item => {
          const _begin = todayDate + ' ' + this.minTime;
          const _end = this.beginDate + ' ' + item.value;
          if (!this.$moment(_begin).isBefore(_end) && this.$moment(todayDate).isSame(this.beginDate)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      } else if (this.type === 'finish') {
        // this.options.forEach((item) => {
        //   let _begin = this.minTime;
        //   let _end = this.beginDate + " " + item.value;
        //   if (!this.$moment(_begin).isBefore(_end)) {
        //     item.disabled = true;
        //   } else {
        //     item.disabled = false;
        //   }
        // });
      }
    },
    // 校验是否为时间格式
    timeCheck(num) {
      if (num[2] !== ':' && num[2] !== '：') {
        return true;
      } else {
        return false;
      }
    },
    // 校验满足时间格式下，是否都为数字
    timeCheck2(num) {
      const timeArr = num.split(':');
      const re = /^[0-9]+.?[0-9]*/;
      if (!re.test(num[0]) && !re.test(num[1]) && !re.test(num[3]) && !re.test(num[4])) {
        return true;
      } else if (!(Number(timeArr[0]) <= 23 && Number(timeArr[0]) >= 0 && Number(timeArr[1]) <= 59 && Number(timeArr[0]) >= 0)) {
        return true;
      } else {
        return false;
      }
    },
    changeTime(val) {
      // 将val值中的冒号统一格式化为英文冒号
      this.value = val.replace('：', ':');
      // 分割value值，生成['xx','xx']
      const arr = this.value.split(':');
      // 格式化value数据,将其变为xx:xx形式
      if (arr[0] < 10 && arr[0].length === 1 && arr[1] < 6 && arr[1].length === 1) {
        arr[0] = '0' + arr[0];
        arr[1] = arr[1] + '0';
        this.value = arr.join(':');
      } else if (arr[0] > 10 && arr[1] < 6 && arr[1].length === 1) {
        arr[1] = arr[1] + '0';
        this.value = arr.join(':');
      } else if (arr[0] < 10 && arr[0].length === 1 && arr[1] >= 6 && arr[1].length === 1) {
        arr[0] = '0' + arr[0];
        arr[1] = '0' + arr[1];
        this.value = arr.join(':');
      } else if (arr[0] > 10 && arr[1] >= 6 && arr[1].length === 1) {
        arr[1] = '0' + arr[1];
        this.value = arr.join(':');
      } else {
        if (arr[0] < 10 && arr[0].length === 1) arr[0] = '0' + arr[0];
        this.value = arr.join(':');
      }
      // 校验格式化后数据是否符合时间规范
      if (this.timeCheck2(this.value) || this.timeCheck(this.value)) {
        val = '';
        this.value = '';
      }
      this.$emit('selected', this.value);
    }
  }
};
exports.default = _default;