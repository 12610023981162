"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      "before-close": _setup.updateVisible,
      visible: _vm.visible,
      title: _vm.modelInfo.id ? `配置模型 - ${_vm.modelInfo.name}` : "创建组合模型",
      size: "900px"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.loading,
      expression: "loading"
    }],
    staticClass: "main-content"
  }, [!_vm.modelInfo.id ? _c("div", {
    staticClass: "model-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础信息")]), _c(_setup.DynamicForm, {
    ref: "basicInfoFormRef",
    attrs: {
      desc: _setup.basicModelInfoDesc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "use_to",
      fn: function () {
        return [_c("el-tag", {
          staticClass: "type-tag",
          attrs: {
            type: "info"
          }
        }, [_vm._v("关键事件")])];
      },
      proxy: true
    }], null, false, 3030504294),
    model: {
      value: _setup.basicModelInfo,
      callback: function ($$v) {
        _setup.basicModelInfo = $$v;
      },
      expression: "basicModelInfo"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "model-rule"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("触发规则"), _c("span", {
    staticClass: "validate-label"
  }, [_vm._v("*")])]), _setup.submitCount && _setup.hasLeastTwoAtomModel ? _c("div", {
    staticClass: "error"
  }, [_vm._v(" 至少两条原子规则 ")]) : _vm._e(), _c("div", {
    staticClass: "model-rule-content"
  }, [_c("div", {
    staticClass: "model-rule-content-container"
  }, [_setup.combineConfig.configs.length > 1 ? _c("div", {
    staticClass: "model-rule-content-container-left"
  }, [_c("div", {
    staticClass: "model-rule-content-container-left-text"
  }, [_vm._v("或")])]) : _vm._e(), _c("div", {
    staticClass: "model-rule-content-container-right"
  }, [_vm._l(_setup.combineConfig.configs, function (ruleGroup, index) {
    return _c(_setup.CombineRuleGroup, {
      key: index,
      attrs: {
        "rule-group": ruleGroup,
        "submit-count": _setup.submitCount,
        "rule-group-index": index,
        "rule-group-length": _setup.combineConfig.configs.length
      },
      on: {
        deleteRuleGroup: function ($event) {
          return _setup.deleteRuleGroup(index);
        }
      }
    });
  }), _c("div", {
    staticClass: "model-rule-content-container-right-operate"
  }, [_c("el-tooltip", {
    attrs: {
      content: "最多设置10个规则组",
      disabled: _setup.combineConfig.configs.length < 10
    }
  }, [_c("MgvButton", {
    attrs: {
      type: "plain",
      color: "info",
      icon: "icon-plus",
      disabled: _setup.combineConfig.configs.length >= 10
    },
    on: {
      click: _setup.addRuleGroup
    }
  }, [_vm._v("或（" + _vm._s(_setup.combineConfig.configs.length) + "/10）")])], 1)], 1)], 2)])])])]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _setup.updateVisible
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.submitBtnLoading
    },
    on: {
      click: _setup.submitCombineModelAsync
    }
  }, [_vm._v(_vm._s(_vm.modelInfo.id ? "保存" : "创建完成"))])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;