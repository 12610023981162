"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBindTelOrEmailDialog = useBindTelOrEmailDialog;
exports.useCheckSetPassword = useCheckSetPassword;
exports.useGetAccountInfo = useGetAccountInfo;
exports.useUpdatePasswordDialog = useUpdatePasswordDialog;
var _user = require("@/api/user");
var _parivatizationDeployment = require("@/api/parivatizationDeployment");
var _vue = require("vue");
function useBindTelOrEmailDialog() {
  const bindTelOrEmailDialogVisible = (0, _vue.ref)(false);
  const who = (0, _vue.ref)('');
  const operate = (0, _vue.ref)('');
  const openBindTelOrEmailDialog = (modifyWho, operateName) => {
    bindTelOrEmailDialogVisible.value = true;
    who.value = modifyWho;
    operate.value = operateName;
  };
  return {
    openBindTelOrEmailDialog,
    bindTelOrEmailDialogVisible,
    who,
    operate
  };
}
function useUpdatePasswordDialog() {
  const updatePasswordDialogVisible = (0, _vue.ref)(false);
  const openUpdatePasswordDialog = () => {
    updatePasswordDialogVisible.value = true;
  };
  const closeUpdatePasswordDialog = () => {
    updatePasswordDialogVisible.value = false;
  };
  return {
    updatePasswordDialogVisible,
    openUpdatePasswordDialog,
    closeUpdatePasswordDialog
  };
}
function useCheckSetPassword() {
  const isSetPassword = (0, _vue.ref)(false);
  const checkIsSetPasswordApi = async () => {
    const res = await (0, _user.checkIsSetPassword)();
    if (res.code === 20000) {
      isSetPassword.value = res.results.is_set;
    }
  };
  return {
    isSetPassword,
    checkIsSetPasswordApi
  };
}
function useGetAccountInfo() {
  const accountInfo = (0, _vue.ref)({});
  const ENV_TITLE = (0, _vue.computed)(() => {
    return process.env.VUE_APP_TITLE;
  });
  const getAccountInfoApi = async () => {
    const method = ENV_TITLE.value === 'image' ? _parivatizationDeployment.getAccountInfo : _user.getDetailInfo;
    const res = await method();
    if (res.code === 20000) {
      accountInfo.value = res.results;
    }
  };
  return {
    accountInfo,
    getAccountInfoApi,
    ENV_TITLE
  };
}