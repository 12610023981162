"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _MenuToggleBtn = _interopRequireDefault(require("@/components/MgNavMenu/components/MenuToggleBtn"));
var _CreateTaskDrawer = _interopRequireDefault(require("@/views/coaching/coach/course/components/CreateTaskDrawer.vue"));
var _AiConversationSync = _interopRequireDefault(require("./components/AiConversationSync"));
var _TaskClassifyManagementBtn = _interopRequireDefault(require("./components/TaskClassifyManagementBtn.vue"));
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachTask',
  components: {
    MenuToggleBtn: _MenuToggleBtn.default,
    CreateTaskDrawer: _CreateTaskDrawer.default,
    AiConversationSync: _AiConversationSync.default,
    TaskClassifyManagementBtn: _TaskClassifyManagementBtn.default
  },
  props: {
    isHiddenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tasksDrawerVisible: false,
      isEdit: false,
      taskId: null,
      taskStatus: null,
      taskInfo: {},
      refreshSign: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree', 'interfacePermission', 'knowledgeDeleted', 'workspaceList']),
    coachingCenterChildrenRoutes() {
      const res = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: 'CoachTask',
        targetAttr: 'name',
        getAttr: 'children'
      });
      if (res && res.length) {
        return res.sort((a, b) => a.meta.order - b.meta.order);
      }
      return [];
    },
    activeName: {
      get() {
        return this.$route.name;
      },
      set() {}
    }
  },
  watch: {
    knowledgeDeleted() {
      this.$message.warning(this.$t('coaching.deletedTask'));
    }
  },
  methods: {
    recursionGet: _commonFunc.recursionGet,
    handleClick(val) {
      this.$router.replace({
        name: val.name
      });
    },
    createTask() {
      this.isEdit = false;
      this.taskId = '';
      this.taskStatus = '';
      this.tasksDrawerVisible = true;
    },
    async editTask(_ref) {
      let {
        pid,
        status
      } = _ref;
      this.isEdit = true;
      this.taskId = pid;
      this.tasksDrawerVisible = true;
      this.taskStatus = status;
    },
    async copyTask(_ref2) {
      let {
        pid
      } = _ref2;
      this.isEdit = false;
      this.taskId = pid;
      this.tasksDrawerVisible = true;
    },
    handleRefresh() {
      this.refreshSign = Date.now();
    }
  }
};
exports.default = _default;