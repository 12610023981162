"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearchContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeSearchContent"));
var _KnowledgeMultipleTree = _interopRequireDefault(require("@/views/coaching/courseEdit/components/KnowledgeMultipleTree"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _LearnContent = _interopRequireDefault(require("@/views/coaching/courseEdit/components/LearnContent.vue"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AddScriptDialog',
  components: {
    NoData: _NoData.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default,
    KnowledgeMultipleTree: _KnowledgeMultipleTree.default,
    KnowledgeSearchContent: _KnowledgeSearchContent.default,
    LearnContent: _LearnContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      oldCheckedKnowledgeScript: [],
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['searchInput'].focus();
        });
        this.oldCheckedKnowledgeScript = (0, _commonFunc.deepClone)(this.checkedKnowledgeScript || []);
      }
    }
  },
  methods: {
    async submit() {
      var _this$checkedKnowledg;
      if (!((_this$checkedKnowledg = this.checkedKnowledgeScript) !== null && _this$checkedKnowledg !== void 0 && _this$checkedKnowledg.length)) {
        this.$message(this.$t('coaching.addScriptPlaceholder'));
        return;
      }
      this.loading = true;
      const data = this.$refs.learnContent.localCheckedKnowledgeScript;
      const res = await (0, _coachingCenter.checkKnowledgeLength)({
        knowledge_ids: data.map(item => item.knowledge_id)
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        if (res.results.is_to_much) {
          this.$message.warning(res.results.msg);
        } else {
          this.$emit('change', data);
          this.handleClose();
        }
      }
    },
    cancelAdd() {
      this.$emit('change', this.oldCheckedKnowledgeScript);
      this.handleClose();
    },
    scrollToScriptEnd() {
      this.$nextTick(() => {
        this.$refs['script-container'].scrollTo({
          top: 100000,
          behavior: 'smooth'
        });
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;