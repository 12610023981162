"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScoreRangeSelect = _interopRequireDefault(require("./ScoreRangeSelect.vue"));
var _AllUrgeButton = _interopRequireDefault(require("../components/AllUrgeButton.vue"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LeaderPlanHeaderFilter',
  components: {
    ScoreRangeSelect: _ScoreRangeSelect.default,
    AllUrgeButton: _AllUrgeButton.default
  },
  props: {
    taskScoreInfo: {
      type: Object,
      default: () => ({})
    },
    scoreMaxLimit: {
      type: Number,
      default: 100
    },
    scoreRangePlaceholder: {
      type: String,
      default: ''
    },
    hasMemberSelect: {
      type: Boolean,
      default: false
    },
    showExportButton: {
      type: Boolean,
      default: true
    },
    taskInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filter: {
        is_complete: '',
        score: []
      },
      isCompleteOptions: [{
        label: this.$t('coaching.notCompleted'),
        value: 0
      }, {
        label: this.$t('coaching.completed'),
        value: 1
      }]
    };
  },
  computed: {
    showGoPractice() {
      return this.taskScoreInfo.status === 3 && !['leader', 'teamView'].includes(this.$route.query.source);
    }
  },
  watch: {
    filter: {
      handler: function (val) {
        const filter = {
          ...val
        };
        if (filter.is_complete === '') {
          filter.is_complete = -1;
        }
        this.$emit('filterChange', filter);
      },
      deep: true
    }
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    handleAllUrgeSuccess() {
      this.$emit('allUrgeSuccess');
    }
  }
};
exports.default = _default;