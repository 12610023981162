"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _main = _interopRequireDefault(require("./src/main.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
_main.default.install = Vue => {
  Vue.component(_main.default.name, _main.default);
};
var _default = _main.default;
exports.default = _default;