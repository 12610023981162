"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _vue = require("vue");
var _default = {
  __name: 'MetricsTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: Number,
      default: 0
    },
    selectable: {
      type: Boolean,
      default: true
    },
    selection: {
      type: Array,
      default: () => []
    }
  },
  emits: ['selection-change'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const tableRef = (0, _vue.ref)(null);
    (0, _vue.watch)(() => props.data, async val => {
      await (0, _vue.nextTick)();
      props.selection.forEach(item => {
        const row = val.find(i => i.metrics_id === item.metrics_id);
        if (row) {
          tableRef.value.toggleRowSelection(row, true);
          row.weight = item.weight;
        }
      });
    });
    const rowClassName = row => {
      return 'row-class-name' + row.metrics_id;
    };
    const onSelectionChange = val => {
      emit('selection-change', val);
    };
    const onCellClick = (row, column, cell, event) => {
      console.log('cell', row, column, cell, event);
    };
    const handleSelectable = row => {
      if (props.selectable || props.selection.map(i => i.metrics_id).includes(row.metrics_id)) {
        return true;
      }
      return false;
    };
    const onRowClick = row => {
      if (!props.selectable && !props.selection.map(i => i.metrics_id).includes(row.metrics_id)) {
        (0, _elementUi.Message)({
          message: '最多添加8个指标',
          type: 'warning'
        });
        return;
      }
      tableRef.value.toggleRowSelection(row);
    };
    const highlightRow = metric => {
      const row = props.data.find(item => item.metrics_id === metric.id);
      tableRef.value.setCurrentRow(row);
    };
    expose({
      highlightRow
    });
    return {
      __sfc: true,
      props,
      emit,
      tableRef,
      rowClassName,
      onSelectionChange,
      onCellClick,
      handleSelectable,
      onRowClick,
      highlightRow
    };
  }
};
exports.default = _default;