"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "experiment-table"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      fit: "",
      height: "calc(100vh - 215px)"
    }
  }, [_vm._l(_vm.headerList, function (_ref) {
    let {
      prop,
      field_name,
      minWidth,
      fixed
    } = _ref;
    return _c("el-table-column", {
      key: prop,
      attrs: {
        prop: prop,
        label: field_name,
        "min-width": minWidth,
        fixed: fixed || false,
        "show-overflow-tooltip": "",
        "class-name": prop === "operate" ? "operate-table-head" : ""
      },
      scopedSlots: _vm._u([prop === "name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("span", {
            staticClass: "experiment-name",
            class: {
              "active-name": row.status !== 1
            },
            on: {
              click: function ($event) {
                return _vm.handleCommand("goExperimentDetail", row);
              }
            }
          }, [_vm._v(_vm._s(row.name))])];
        }
      } : prop === "creator_info" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("span", {
            staticClass: "creator_info"
          }, [_c("image-avatar", {
            attrs: {
              avatar: row.creator_info.avatar,
              name: row.creator_info.name
            }
          }), _vm._v(_vm._s(row.creator_info.name))], 1)];
        }
      } : prop === "start_at" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.start_at)) + " ")];
        }
      } : prop === "end_at" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_vm._v(" " + _vm._s(_vm.plus8ToCurrentZone(row.end_at)) + " ")];
        }
      } : prop === "status" ? {
        key: "default",
        fn: function (_ref6) {
          let {
            row
          } = _ref6;
          return [_c("div", {
            staticClass: "common-status",
            class: {
              "doing-status": row.status === 2,
              "completed-status": row.status === 3
            }
          }, [_c("i", {
            class: row.status === 3 ? "el-icon-circle-check" : "el-icon-time"
          }), _vm._v(" " + _vm._s(_vm.judgeTestState(row.status)) + " ")])];
        }
      } : prop === "operate" ? {
        key: "default",
        fn: function (_ref7) {
          let {
            row
          } = _ref7;
          return [_c("mg-operate-column", {
            attrs: {
              list: _vm.getOperateList(row)
            },
            on: {
              command: function ($event) {
                return _vm.handleCommand($event, row);
              }
            }
          })];
        }
      } : null], null, true)
    });
  }), _c("div", {
    staticClass: "no-data",
    attrs: {
      slot: "empty"
    },
    slot: "empty"
  }, [_c("i", {
    staticClass: "iconfont icon-receiving-empty"
  }), _c("div", [_vm._v("暂无数据")])])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;