"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'Inactivated',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      listLoading: false
    };
  },
  computed: {
    inviteText() {
      const _url = window.location.protocol + '//' + window.location.host + '/login';
      return '我和“' + this.orgName + '”的小伙伴都在等你，已将你加入团队，用这个链接开启会话吧！' + _url;
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val === true) {
          this.listLoading = true;
          (0, _organization.statusList)().then(res => {
            this.list = res.results;
            this.listLoading = false;
          });
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    onCopy() {
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    closeSelf() {
      this.$emit('closeInactivated');
    },
    getDate(value) {
      return this.$moment(value).format('YYYY-MM-DD HH:mm');
    },
    withdraw(row) {
      (0, _organization.cancelInvite)(row.id).then(response => {
        if (response.code === 200) {
          this.$bus.$emit('freshUserList');
          this.$message({
            message: '取消成功',
            type: 'success'
          });
          this.listLoading = true;
          (0, _organization.statusList)().then(res => {
            this.list = res.results;
            this.listLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;