"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "correct-text-container"
  }, [_vm.correctTextHistoryList.length ? _c("mg-infinite-scroll", {
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.correctTextHistoryList, function (item, index) {
    return _c("div", {
      key: item.order,
      staticClass: "correct-text-card",
      class: {
        "correct-text-card-active": _vm.activeCardIndex === index
      },
      on: {
        click: function ($event) {
          return _vm.clickCard(item, index);
        }
      }
    }, [_c("span", {
      staticClass: "mark"
    }, [_c("i", {
      staticClass: "iconfont icon-mark-line"
    })]), _c("section", {
      staticClass: "origin-text"
    }, [_c("div", {
      staticClass: "origin-text-top"
    }, [_c("image-avatar", {
      attrs: {
        name: item.origin_user.name,
        avatar: item.origin_user.avatar
      }
    }), _c("span", {
      staticClass: "speak-name"
    }, [_vm._v(_vm._s(item.origin_user.name))]), _c("span", {
      staticClass: "origin-text-desc"
    }, [_vm._v("系统转录")])], 1), _c("span", {
      staticClass: "sentence"
    }, [_vm._v(" " + _vm._s(item.origin_content) + " ")])]), _c("div", {
      staticClass: "update-times"
    }, [_vm._v("已修改 " + _vm._s(item.correction.length) + " 次")]), _vm._l(item.correction, function (update, index) {
      return _c("div", {
        key: index,
        staticClass: "update-container"
      }, [_c("div", {
        staticClass: "update-item",
        class: {
          "no-bottom-item": index === item.correction.length - 1
        }
      }, [_c("div", {
        staticClass: "update-top"
      }, [_c("i", {
        staticClass: "iconfont icon-edit-outline"
      }), _c("el-tooltip", {
        staticClass: "item",
        attrs: {
          effect: "dark",
          content: `修改人： ${update.create_by}`,
          placement: "top"
        }
      }, [_c("span", {
        staticClass: "update-name"
      }, [_vm._v(_vm._s(update.create_by))])]), _c("span", {
        staticClass: "update-date"
      }, [_vm._v(_vm._s(_vm.$moment(update.create_at).format("YYYY-MM-DD HH:mm")) + " ")]), index === item.correction.length - 1 ? _c("span", {
        staticClass: "update-desc"
      }, [_vm._v("最新")]) : _vm._e()], 1), _c("span", {
        staticClass: "sentence"
      }, [_vm._v(_vm._s(update.content))])])]);
    })], 2);
  }), 0) : _c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无数据")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;