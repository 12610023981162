"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  methods: {
    handleSortArray(array, sortType) {
      if (array) {
        let newArray = this.getDeepCloneArr(array);
        if (sortType === "ascending") {
          newArray.sort(this.orderSort(1));
        } else if (sortType === "descending") {
          newArray.sort(this.orderSort(-1));
        }
        return newArray;
      } else {
        return array;
      }
    },
    orderSort(i) {
      return function (a, b) {
        let value1 = a["value"];
        let value2 = b["value"];
        return (value1 - value2) * i;
      };
    },
    getDeepCloneArr(arr) {
      return JSON.parse(JSON.stringify(arr));
    }
  }
};
exports.default = _default;