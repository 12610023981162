"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _ContactPersonCard = _interopRequireDefault(require("@/views/clients/components/ContactPersonCard.vue"));
var _formatContactFormDescMixins = _interopRequireDefault(require("@/views/clients/formatContactFormDescMixins"));
var _client = require("@/api/client");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ContactPerson',
  components: {
    MgInfiniteScroll: _MgInfiniteScroll.default,
    ContactPersonCard: _ContactPersonCard.default
  },
  mixins: [_formatContactFormDescMixins.default],
  props: {
    accountId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      contactPersonList: [],
      pageSize: 10,
      loading: false,
      total: 0,
      listLength: 0,
      currentPage: 1
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['contactDynamicFormDescList']),
    noMore() {
      return this.listLength && this.listLength === this.total;
    },
    contactDynamicFormNoAccountList() {
      if (this.contactDynamicFormDescList) {
        return this.contactDynamicFormDescList.filter(item => {
          return item.id !== 'customer_name';
        });
      } else {
        return [];
      }
    }
  },
  created() {
    this.$store.dispatch('account/getContactDynamicForm');
    this.getContactPersonList();
  },
  methods: {
    initData() {
      this.contactPersonList = [];
      this.currentPage = 1;
      this.listLength = 0;
      this.loading = false;
    },
    load() {
      this.getContactPersonList();
    },
    getContactPersonList() {
      this.loading = true;
      (0, _client.getContactPerson)(this.accountId, this.currentPage, this.pageSize).then(res => {
        if (res.code === 20000) {
          res.results.contacts.forEach(item => {
            this.contactPersonList.push(item);
          });
          this.total = res.results.total_count;
          this.listLength += res.results.contacts.length;
          this.currentPage++;
          this.loading = false;
        }
      });
    }
  }
};
exports.default = _default;