"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'RuleEventFormItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    nameProp: {
      type: String,
      default: 'name'
    },
    description: {
      type: String,
      default: ''
    },
    formItemlabel: {
      type: String,
      default: '关键事件'
    }
  }
};
exports.default = _default;