"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("@/views/conversationPage/components/ConversationFilterItem"));
var _GradeRange = _interopRequireDefault(require("@/views/conversationPage/components/GradeRange.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceScoreFilter',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    GradeRange: _GradeRange.default
  },
  props: {
    scoreConfigList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localScoreConfigList: []
    };
  },
  computed: {
    chosenCount() {
      return this.localScoreConfigList.filter(item => item.type).length;
    }
  },
  watch: {
    scoreConfigList: {
      handler: function (val) {
        if (val) {
          this.localScoreConfigList = val.map(item => ({
            ...item,
            type: '',
            score_range: []
          }));
        }
      }
    }
  },
  methods: {
    handlerScoreChange(index) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        type: '',
        score_range: []
      };
      this.$set(this.localScoreConfigList, index, {
        ...this.localScoreConfigList[index],
        ...data
      });
      this.$emit('complianceScoreChange', this.localScoreConfigList.filter(item => item.type).map(c => ({
        id: c.id,
        type: c.type,
        score_range: c.score_range
      })));
    }
  }
};
exports.default = _default;