"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModelItemHeader = _interopRequireDefault(require("./ModelItemHeader"));
var _ModelItemTable = _interopRequireDefault(require("./ModelItemTable"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ModelList',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  emits: ['buttonClick'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const modelTypeMap = {
      1: 'rule',
      2: 'ai',
      3: 'ai',
      4: 'combine'
    };
    const onButtonClick = (val, item) => {
      emit('buttonClick', val, item);
    };
    return {
      __sfc: true,
      emit,
      modelTypeMap,
      onButtonClick,
      ModelItemHeader: _ModelItemHeader.default,
      ModelItemTable: _ModelItemTable.default
    };
  }
};
exports.default = _default;