"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'KeyEventColumnContent',
  props: {
    columnDataArr: {
      type: Array,
      default: () => []
    },
    nameKey: {
      type: String,
      default: 'event_name'
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('openKeyEventDrawer');
    },
    handleTagName(name) {
      if (this.type === 'date') {
        return this.$moment(name).format('YYYY-MM-DD');
      }
      return name;
    }
  }
};
exports.default = _default;