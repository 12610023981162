"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _dataSet = require("@/api/dataSet");
var _default = {
  __name: 'DataSetLabelSelect',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    datasetId: {
      type: [Number, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'change', 'update:positiveCount'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const onInput = val => {
      emit('input', val);
    };
    const positiveCount = (0, _vue.ref)(0);
    const onChange = val => {
      emit('change', keyEventList.value.find(item => item.id === val));
    };
    const keyEventList = (0, _vue.ref)([]);
    (0, _vue.watchEffect)(async () => {
      if (props.datasetId) {
        const res = await (0, _dataSet.getDataSetLabels)({
          dataset_id: props.datasetId
        });
        keyEventList.value = res.results.labels;
        if (props.value) {
          onChange(props.value);
        }
      }
    });
    const setPositiveCount = val => {
      var _keyEventList$value$f;
      positiveCount.value = ((_keyEventList$value$f = keyEventList.value.find(item => item.id === val)) === null || _keyEventList$value$f === void 0 ? void 0 : _keyEventList$value$f.positive_count) || 0;
    };
    const getPositiveCount = () => {
      return positiveCount.value;
    };
    expose({
      getPositiveCount
    });
    return {
      __sfc: true,
      props,
      emit,
      onInput,
      positiveCount,
      onChange,
      keyEventList,
      setPositiveCount,
      getPositiveCount
    };
  }
};
exports.default = _default;