"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _CreateDate = _interopRequireDefault(require("@/components/Filters/components/CreateDate"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ManageViewListFilter',
  components: {
    MembersFinder: _MembersFinder.default,
    CreateDate: _CreateDate.default
  },
  inject: ['getActiveManageViewTab'],
  data() {
    return {
      status: 0,
      statusOptions: [{
        label: '所有状态',
        value: 0,
        hiddenType: []
      }, {
        label: '已逾期',
        value: 4,
        hiddenType: ['培训任务']
      }, {
        label: '进行中',
        value: 1,
        hiddenType: []
      }, {
        label: '已完成',
        value: 2,
        hiddenType: []
      }, {
        label: '已截止',
        value: 3,
        hiddenType: ['普通任务', '助手任务']
      }],
      executors: [],
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      deadline: [],
      deadlineOptions: {
        field_name: 'task_deadline',
        name: '截止时间',
        options: [['今天', '本周', '本月']]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    taskTypeValue() {
      var _this$getActiveManage;
      return (_this$getActiveManage = this.getActiveManageViewTab()) === null || _this$getActiveManage === void 0 ? void 0 : _this$getActiveManage.label;
    },
    filterObj() {
      return {
        task_status: this.status,
        executors: this.executors,
        deadline_ats: this.deadline
      };
    }
  },
  watch: {
    taskTypeValue() {
      this.init();
    },
    filterObj: {
      handler: function (val) {
        this.$emit('filterChange', val);
      },
      deep: true
    }
  },
  methods: {
    handleStatusChange(val) {
      this.status = val;
    },
    handleExecutorChange(val) {
      this.executors = val;
    },
    deadlineChange(val) {
      let deadline = val['task_deadline'];
      if (typeof deadline === 'string') {
        deadline = deadline ? [deadline] : [];
      }
      this.deadline = deadline;
    },
    init() {
      this.status = 0;
      if (this.$refs.selectMembers) this.$refs.selectMembers.selection = [];
      this.deadline = [];
    }
  }
};
exports.default = _default;