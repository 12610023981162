"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.download = download;
exports.downloadBlob = downloadBlob;
exports.downloadByUrl = downloadByUrl;
exports.downloadFile = downloadFile;
exports.downloadFileRename = downloadFileRename;
exports.downloadVideo = downloadVideo;
exports.downloadWebApi = downloadWebApi;
exports.getBlob = getBlob;
exports.handleBlob = handleBlob;
exports.saveAs = saveAs;
var _request = require("@/utils/request");
var _streamsaver = _interopRequireDefault(require("streamsaver"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// 下载通用接口
function download(url, filename, surplus) {
  return (0, _request.request)({
    url,
    method: 'get',
    responseType: 'blob',
    params: {
      filename,
      ...surplus
    }
  });
}
function downloadWebApi(url, filename, surplus) {
  return (0, _request.webService)({
    url,
    method: 'get',
    responseType: 'blob',
    params: {
      filename,
      ...surplus
    }
  });
}
function downloadFile(url) {
  window.location.href = url;
}
function downloadFileRename(url, filename) {
  const currentFilename = `${filename}.csv`;
  getBlob(url, blob => {
    saveAs(blob, currentFilename);
  });
}
function getBlob(url, cb) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
}
function handleBlob(response) {
  var _response$headers$con;
  let filename = '';
  const _filename = (_response$headers$con = response.headers['content-disposition'].match(/filename=(.*)/)[1]) === null || _response$headers$con === void 0 ? void 0 : _response$headers$con.split(';')[0];
  const expand = '.' + _filename.split('.').pop();
  const customizeName = response.config.params.filename;
  filename = customizeName ? customizeName + expand : _filename;

  // 将二进制流转为blob
  // const blob = new Blob([response.data], { type: 'application/octet-stream' })
  const blob = response.data;
  downloadBlob(blob, filename);
}

// 已请求回文件的下载方法
function downloadBlob(blob, filename) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
    window.navigator.msSaveBlob(blob, decodeURI(filename));
  } else {
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob);
    downloadByUrl(blobURL, filename);
  }
}
function downloadByUrl(blobURL, filename) {
  // 创建a标签，用于跳转至下载链接
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', decodeURI(filename));
  // 兼容：某些浏览器不支持HTML5的download属性
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  // 挂载a标签
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  // 释放blob URL地址
  window.URL.revokeObjectURL(blobURL);
}

// 解决下载视频时浏览器不显示进度的问题
function downloadVideo(url, filename, callback) {
  fetch(url, {
    method: 'get',
    cache: 'no-cache'
  }).then(res => {
    const fileStream = _streamsaver.default.createWriteStream(filename, {
      size: res.headers.get('content-length')
    });
    const readableStream = res.body;
    if (window.WritableStream && readableStream.pipeTo) {
      return readableStream.pipeTo(fileStream).then(() => {
        callback && callback();
      });
    }
    window.writer = fileStream.getWriter();
    const reader = res.body.getReader();
    const pump = () => reader.read().then(res => {
      if (res.done) {
        callback && callback();
        return window.writer.close();
      }
      return window.writer.write(res.value).then(pump);
    });
    pump();
  });
}