"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NumberScopeInput = _interopRequireDefault(require("@/components/Filters/components/NumberScopeInput.vue"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ValidTrainDataHeader',
  props: {
    trainDataSelection: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change', 'updateTrainDataStatus'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const content = (0, _vue.ref)('');
    const confidence = (0, _vue.ref)([]);
    const status = (0, _vue.ref)('');
    const getFilters = () => {
      return {
        content: content.value,
        confidence: confidence.value,
        status: status.value || -1
      };
    };
    const statusOptions = [{
      value: 1,
      label: '命中'
    }, {
      value: 2,
      label: '未命中'
    }, {
      value: 3,
      label: '不确定'
    }];
    const updateTrainDataStatus = (trainDataSelection, status) => {
      emit('updateTrainDataStatus', trainDataSelection, status);
    };
    const deleteAnnotation = (annotationIds, isBatch) => {
      emit('deleteAnnotation', annotationIds, isBatch);
    };
    const onChange = () => {
      emit('change', getFilters());
    };
    expose({
      getFilters
    });
    return {
      __sfc: true,
      props,
      content,
      confidence,
      status,
      getFilters,
      statusOptions,
      emit,
      updateTrainDataStatus,
      deleteAnnotation,
      onChange,
      NumberScopeInput: _NumberScopeInput.default
    };
  }
};
exports.default = _default;