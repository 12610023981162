"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _library = require("@/api/library");
var _ConvFlowInfo = _interopRequireDefault(require("./ConvFlowInfo.vue"));
var _conversationPage = _interopRequireDefault(require("@/views/conversationPage"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateConvFlowDrawer',
  components: {
    ConvFlowInfo: _ConvFlowInfo.default,
    ConvListPage: _conversationPage.default
  },
  provide() {
    return {
      isBaseConvInDrawer: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    currentNode: {
      type: Object,
      default: () => ({})
    },
    editNode: {
      type: Object,
      default: () => ({})
    },
    createSubfolder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      convFilters: {},
      convFlowParams: {},
      backFillFilters: {},
      filterNum: 0,
      activeStep: 0,
      submitLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    visible(val) {
      if (val && this.isEdit) {
        this.getConvFlowRule();
      }
    }
  },
  methods: {
    async submit() {
      const filters = JSON.stringify({
        ...this.convFilters,
        space_id: this.workspaceInfo.id
      });
      const params = {
        filters,
        space_id: this.workspaceInfo.id,
        ...this.convFlowParams
      };
      // if (!params.is_public) {
      //   params.is_main_space = this.$refs['convFlowInfo'].getIsShowMainSpace();
      // }
      if (this.isEdit) {
        const {
          id,
          fid
        } = this.editNode;
        params.folder_id = id;
        params.fid = fid;
        const res = await (0, _library.editCovnFlow)(params).finally(() => {
          this.submitLoading = false;
        });
        if (res.code === 20000) {
          this.$message.success('编辑成功');
          this.handleClose();
          this.$emit('success', this.editNode);
        }
      } else {
        const res = await (0, _library.createCovnFlow)(params).finally(() => {
          this.submitLoading = false;
        });
        if (res.code === 20000) {
          this.$message.success('新建成功');
          this.handleClose();
          this.$emit('success', {
            id: res.results.folder_id,
            name: this.convFlowParams.name,
            is_leaf: true,
            type: 1
          });
        }
      }
    },
    async getConvFlowRule() {
      const res = await (0, _library.getConvFlowRule)({
        folder_id: this.editNode.id
      });
      if (res.code === 200) {
        this.backFillFilters = res.results.data.filter;
      }
    },
    filterNumChange(val) {
      this.filterNum = val;
    },
    goNextStep() {
      const valid = this.$refs.convFlowInfo.validateForm();
      if (!valid) return;
      this.convFlowParams = this.$refs.convFlowInfo.getConvFlowParams();
      console.log(this.convFlowParams, 'this.convFlowParams====');
      this.activeStep = 1;
    },
    goPrevStep() {
      this.activeStep = 0;
    },
    handleClose() {
      this.activeStep = 0;
      this.$emit('update:visible', false);
    },
    convFlowFiltersChange(filters) {
      this.convFilters = filters;
    }
  }
};
exports.default = _default;