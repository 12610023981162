"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-file-list"
  }, [_c("div", {
    staticClass: "upload-file-list__header"
  }, [_c("div", {
    staticClass: "upload-file-list__header-content"
  }, [_c("div", {
    staticClass: "upload-file-dosage"
  }, [_c("p", [_vm._v(" 文件时长 "), _c("span", {
    class: {
      "over-dosage": _setup.addFileBtnDisabled()
    }
  }, [_vm._v(_vm._s(_setup.secondToCNTimeOfFixedTwo(_setup.getFilesDuration())))])]), _c("p", [_vm._v(" 剩余可用量"), _setup.store.getters.uploadHostSalesmanInfo && _setup.store.getters.uploadHostSalesmanInfo.id ? _c("span", [_vm._v(_vm._s(_setup.formatDuration()))]) : _vm._e()])]), _c("el-button", {
    attrs: {
      type: "text",
      disabled: _setup.addFileBtnDisabled()
    },
    on: {
      click: _setup.addFile
    }
  }, [_vm._v("添加")])], 1), _setup.addFileBtnDisabled() ? _c("div", {
    staticClass: "upload-file-dosage-over-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-warning"
  }), _c("span", [_vm._v("时长超出剩余可用量，无法上传")])]) : _vm._e()]), _c("ul", {
    staticClass: "upload-file-list__content"
  }, _vm._l(_vm.fileList, function (file, index) {
    return _c("li", {
      key: file.uid,
      staticClass: "upload-file-list__item"
    }, [_c("div", {
      staticClass: "item-type"
    }, [_c("i", {
      class: ["iconfont", _setup.AUDIO_TYPE.includes(file.raw.type) ? "icon-phone-outline-fill" : "icon-video-camera-solid-2"]
    })]), _c("div", {
      staticClass: "item-content"
    }, [_c("div", {
      staticClass: "item-content-top"
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !file.isEditFileName,
        expression: "!file.isEditFileName"
      }],
      staticClass: "file-name"
    }, [_c("span", [_vm._v(_vm._s(file.meeting_name))]), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        content: "重命名",
        effect: "dark",
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "iconfont icon-edit",
      attrs: {
        "data-v-step": `upload-file-list-${index}`
      },
      on: {
        click: function ($event) {
          return _setup.editFileName(file, index);
        }
      }
    })])], 1), _c("el-input", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: file.isEditFileName,
        expression: "file.isEditFileName"
      }],
      ref: _setup.setInputRefs,
      refInFor: true,
      on: {
        blur: function ($event) {
          file.isEditFileName = false;
        }
      },
      model: {
        value: file.meeting_name,
        callback: function ($$v) {
          _vm.$set(file, "meeting_name", $$v);
        },
        expression: "file.meeting_name"
      }
    })], 1)]), _c("div", {
      staticClass: "close-btn",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.handleDeleteFile(file, index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })])]);
  }), 0), _c("MgvTour", {
    ref: "modifyMediaNameTourRef",
    attrs: {
      name: "isShowModifyMediaNameGuide",
      steps: _setup.modifyMediaNameStep
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("video", {
          staticClass: "batch-media-tour-video",
          attrs: {
            autoplay: "",
            loop: "",
            muted: ""
          },
          domProps: {
            muted: true
          }
        }, [_c("source", {
          attrs: {
            src: require("@/assets/modify-media-name.mp4"),
            type: "video/mp4"
          }
        })]), _c("div", {
          staticClass: "tour-text"
        }, [_vm._v("会话名称改为点击文件修改啦！")])];
      },
      proxy: true
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;