"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "score-set"
  }, [_c("div", {
    staticClass: "score-set-table"
  }, [_c("div", {
    staticClass: "score-set-table__header row"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("span", {
    staticClass: "column-header-text"
  }, [_vm._v("评分指标")]), _c("MgvButton", {
    staticClass: "management-button",
    attrs: {
      type: "text",
      size: "tiny"
    },
    on: {
      click: function ($event) {
        _setup.metricsManegeDrawerVisible = true;
      }
    }
  }, [_vm._v("管理")])], 1), _vm._m(0), _c("div", {
    staticClass: "column"
  }, [_c("span", {
    staticClass: "column-header-text"
  }, [_vm._v("分值权重(0-100) ")]), _c("el-tooltip", {
    attrs: {
      content: "课程满分100分，将按设置的分值权重进行加权计算",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1)]), _c(_setup.MgDraggable, {
    attrs: {
      value: _vm.value
    },
    on: {
      input: _setup.onInput
    }
  }, _vm._l(_vm.value, function (item) {
    return _c("div", {
      key: item.metrics_id,
      staticClass: "row"
    }, [_c("div", {
      staticClass: "column"
    }, [_c("MgvButton", {
      staticClass: "move",
      attrs: {
        type: "text",
        size: "mini",
        color: "info transparent",
        square: "",
        icon: "icon-drag-dot"
      }
    }), _c("span", [_vm._v(_vm._s(item.metrics_name))])], 1), _c("div", {
      staticClass: "column"
    }, [_vm._v(_vm._s(item.dimension_name))]), _c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "weight-input-container"
    }, [_c("el-input-number", {
      attrs: {
        placeholder: "权重值",
        controls: false,
        min: 0,
        step: 1,
        "step-strictly": "",
        clearable: "",
        max: 100,
        size: "small"
      },
      model: {
        value: item.weight,
        callback: function ($$v) {
          _vm.$set(item, "weight", $$v);
        },
        expression: "item.weight"
      }
    }), _c("span", {
      staticClass: "unit"
    }, [_vm._v("分")])], 1), _c("MgvButton", {
      staticClass: "delete-button",
      attrs: {
        type: "text",
        icon: "icon-close",
        color: "info transparent",
        square: "",
        size: "small"
      },
      on: {
        click: function ($event) {
          return _setup.deleteMetric(item.metrics_id);
        }
      }
    })], 1)]);
  }), 0), _c("div", {
    staticClass: "add-row"
  }, [_c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "small"
    },
    on: {
      click: _setup.addMetric
    }
  }, [_vm._v("评分指标")])], 1), _c(_setup.PracticeProcessTipsSwitch, {
    attrs: {
      title: "评分标准提示",
      description: "开启后，学员可在练习过程中查看评分标准提示"
    },
    model: {
      value: _setup.promptStruct.is_evaluates_display,
      callback: function ($$v) {
        _vm.$set(_setup.promptStruct, "is_evaluates_display", $$v);
      },
      expression: "promptStruct.is_evaluates_display"
    }
  })], 1), _c(_setup.MetricsManegeDrawer, {
    attrs: {
      visible: _setup.metricsManegeDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _setup.metricsManegeDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "column"
  }, [_c("span", {
    staticClass: "column-header-text"
  }, [_vm._v("归属维度")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;