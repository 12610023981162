"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessAnalysisTable',
  mixins: [_ExportMixins.default],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    eventName: {
      type: String,
      default: ''
    }
  },
  methods: {
    formatRateToPercent(row) {
      return row.rate.toLocaleString('zh', {
        style: 'percent',
        minimumSignificantDigits: 3
      });
    },
    exportAnalysisBusinessTable() {
      const res = this.tableData.map(item => [item.user_info.name, item.include_event_conversation, item.all_conversation, this.formatRateToPercent(item)]);
      res.unshift(['销售姓名', `含${this.eventName}会话数`, '总会话数', `${this.eventName}事件命中率`]);
      const {
        startDate,
        endDate
      } = this.getBiFilter();
      const fileName = `${this.eventName}_${startDate}至${endDate}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    }
  }
};
exports.default = _default;