"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _library = require("@/api/library");
var _routeMap = require("@/router/routeMap");
var _default = {
  name: 'CorrectTextDialog',
  props: {
    correctTextDialogVisible: {
      type: Boolean,
      default: false
    },
    correctTextItem: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        text: ''
      },
      rules: {
        text: [{
          required: true,
          message: '内容不能为空',
          trigger: 'change'
        }]
      },
      loading: false
    };
  },
  computed: {
    textSource() {
      // 判断是哪个页面的转录文字（质检工作台or会话详情页or剪辑详情页）
      const map = {
        [_routeMap.ROUTES.complianceWorkspace]: 'conversation',
        [_routeMap.ROUTES.conversationDetail]: 'conversation',
        [_routeMap.ROUTES.libraryDetail]: 'snippet'
      };
      return map[this.$route.path] || 'conversation';
    }
  },
  watch: {
    correctTextDialogVisible(val) {
      if (val) {
        this.form.text = this.correctTextItem.content;
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeCorrectTextDialog');
    },
    async submitCorrectText() {
      this.loading = true;
      const params = this.getParams();
      const fn = this.textSource === 'conversation' ? _conversationList.correctConvText : _library.correctSnippetText;
      const res = await fn(params).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        if (this.textSource === 'conversation') {
          this.$message.success('修改成功，评分重新计算中，请稍后刷新查看');
        } else {
          this.$message.success('修改成功');
        }
        this.closeDialog();
        this.$emit('correctTextSuccess', params.modified_content);
      }
    },
    saveText() {
      this.$refs['correct-text-form'].validate(valid => {
        if (valid) {
          this.submitCorrectText();
        }
      });
    },
    getParams() {
      const params = {
        order: this.correctTextItem.order,
        modified_content: this.form.text
      };
      const id = this.id || Number(this.$route.query.id);
      if (this.textSource === 'conversation') {
        params.conversation_id = id;
      } else {
        params.snippet_id = id;
      }
      return params;
    }
  }
};
exports.default = _default;