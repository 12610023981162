"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardTextMainData = _interopRequireDefault(require("@/components/Charts/CardTextMainData.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DetailTable',
  components: {
    CardTextMainData: _CardTextMainData.default
  },
  props: {
    lineData: {
      type: Array,
      default: () => []
    },
    timeColumn: {
      type: Array,
      default: () => []
    },
    interpose: {
      type: Array,
      default: () => []
    },
    unitConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currPage: 1,
      pageSize: 20,
      total: 0
    };
  },
  computed: {
    tableHead() {
      return this.lineData.map(_ref => {
        let {
          name
        } = _ref;
        return name;
      });
    },
    tableData() {
      if (!this.lineData.length) {
        return [];
      }
      const arr = [];
      this.timeColumn.forEach(item => {
        arr.push({
          time: item
        });
      });
      // 列数据改为行数据
      for (const {
        data,
        name
      } of this.lineData) {
        data.forEach((item, index) => {
          arr[index][name] = item;
        });
      }
      return arr;
    },
    eachPageTableData() {
      const start = (this.currPage - 1) * this.pageSize;
      const end = this.currPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
    showInterpose() {
      return function (time) {
        return this.interpose.map(_ref2 => {
          let {
            date
          } = _ref2;
          return date;
        }).includes(time);
      };
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currPage = 1;
    },
    handleCurrentChange(val) {
      this.currPage = val;
    }
  }
};
exports.default = _default;