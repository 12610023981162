"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _DialogueList = _interopRequireDefault(require("@/views/coaching/coach/coachTask/salesPlan/DialogueList.vue"));
var _KeyScriptReview = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/KeyScriptReview.vue"));
var _KeyScriptReviewNoScene = _interopRequireDefault(require("@/views/coaching/coachTaskPractice/components/KeyScriptReviewNoScene.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _useRouter = require("@/hooks/use-router.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'AiCustomerCourseTask',
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    currentHistoryId: {
      type: String,
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    },
    courseType: {
      type: Number,
      default: 0
    }
  },
  emits: ['updateTaskInfo', 'scrollToTop'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const route = (0, _useRouter.useRoute)();
    const activeName = (0, _vue.ref)('overallReview');
    const commentsList = (0, _vue.ref)([]);
    const conversationList = (0, _vue.ref)([]);
    const narration = (0, _vue.ref)('');
    const sceneList = (0, _vue.ref)([]);
    const scriptList = (0, _vue.ref)([]);
    const abilityAnalysis = (0, _vue.ref)([]);
    const customerAvatar = (0, _vue.ref)('');

    // 获取总评和对话记录
    const getDialogueListLoading = (0, _vue.ref)(false);
    const getSmartTrainingListData = async data => {
      getDialogueListLoading.value = true;
      const res = await (0, _coachingCenter.getSmartTrainingList)(data).finally(() => {
        getDialogueListLoading.value = false;
      });
      if (res.code === 20000) {
        const {
          data,
          score,
          comment,
          ability_analysis,
          scene,
          avatar_url
        } = res.results;
        commentsList.value = comment;
        conversationList.value = data;
        narration.value = scene;
        abilityAnalysis.value = ability_analysis;
        customerAvatar.value = avatar_url;
        emit('updateTaskInfo', {
          score
        });
      }
    };
    const getKeyScriptReviewLoading = (0, _vue.ref)(false);
    const getAiCustomerKeyScriptReviewData = async data => {
      getKeyScriptReviewLoading.value = true;
      if (props.courseType === 6) {
        const res = await (0, _coachingCenter.getAiCustomerKeyScriptReview)(data).finally(() => {
          getKeyScriptReviewLoading.value = false;
        });
        if (res.code === 20000) {
          sceneList.value = res.results.scene;
        }
      } else {
        const res = await (0, _coachingCenter.getAiCustomerPracticeScriptsResult)(data).finally(() => {
          getKeyScriptReviewLoading.value = false;
        });
        if (res.code === 20000) {
          scriptList.value = res.results.keypoints;
        }
      }
    };
    const toggleHistory = () => {
      if (props.taskId) {
        const params = {
          plan_id: props.taskId,
          user_id: props.userInfo.id
        };
        if (props.currentHistoryId) {
          params.conv_id = props.currentHistoryId;
        }
        getSmartTrainingListData(params);
        getAiCustomerKeyScriptReviewData(params);
      }
    };
    (0, _vue.onMounted)(() => {
      toggleHistory();
    });
    (0, _vue.watch)(() => props.currentHistoryId, () => {
      toggleHistory();
    });
    return {
      __sfc: true,
      props,
      emit,
      route,
      activeName,
      commentsList,
      conversationList,
      narration,
      sceneList,
      scriptList,
      abilityAnalysis,
      customerAvatar,
      getDialogueListLoading,
      getSmartTrainingListData,
      getKeyScriptReviewLoading,
      getAiCustomerKeyScriptReviewData,
      toggleHistory,
      t: _i18n.t,
      DialogueList: _DialogueList.default,
      KeyScriptReview: _KeyScriptReview.default,
      KeyScriptReviewNoScene: _KeyScriptReviewNoScene.default
    };
  }
};
exports.default = _default;