"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "company-info-management"
  }, [_c("header", [_vm._v("企业权益管理")]), _c("div", {
    staticClass: "company-info-management-main"
  }, [_c("InfoEditCard", {
    attrs: {
      title: "企业信息",
      "submit-loading": _vm.logoLoading
    },
    on: {
      cancel: _vm.resetLogoForm,
      submit: _vm.submitLogoInfo
    },
    scopedSlots: _vm._u([{
      key: "card-content",
      fn: function (_ref) {
        let {
          isEditing
        } = _ref;
        return [_c("div", {
          staticClass: "form-container"
        }, [_c("el-form", {
          ref: "form",
          attrs: {
            "label-position": "top",
            "label-width": "80px",
            model: _vm.formData,
            rules: _vm.rules
          }
        }, [_c("el-form-item", {
          attrs: {
            label: "logo设置"
          }
        }, [_c("div", {
          staticClass: "logo-settings"
        }, [_c("div", {
          staticClass: "left-part"
        }, [_c("image-avatar", {
          attrs: {
            avatar: _vm.formData.logoUrl || "",
            name: _vm.formData.organizationName,
            shape: "square"
          }
        })], 1), _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: isEditing,
            expression: "isEditing"
          }],
          staticClass: "right-part"
        }, [_c("div", {
          staticClass: "upload"
        }, [_c("el-upload", {
          attrs: {
            action: "#",
            "show-file-list": false,
            "http-request": _vm.uploadLogo,
            accept: "image/jpeg, image/jpg, image/png, image/svg",
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.beforeAvatarUpload
          }
        }, [_c("el-button", {
          attrs: {
            size: "small"
          }
        }, [_vm._v("上传")])], 1), _c("div", {
          staticClass: "restore",
          on: {
            click: _vm.restoreDefaultLogo
          }
        }, [_vm._v(" 还原默认logo ")])], 1), _c("div", {
          staticClass: "tips"
        }, [_vm._v(" 支持jpg、jpeg、png、svg格式，小于200KB，建议长宽相等、透明背景。 ")])])])]), _c("el-form-item", {
          attrs: {
            label: "企业名称设置",
            prop: "organizationName"
          }
        }, [_c("el-input", {
          attrs: {
            disabled: !isEditing
          },
          model: {
            value: _vm.formData.organizationName,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "organizationName", $$v);
            },
            expression: "formData.organizationName"
          }
        })], 1)], 1)], 1)];
      }
    }])
  }), _c("InfoEditCard", {
    attrs: {
      title: "会话上传额度",
      "submit-loading": _vm.uploadLimitLoading
    },
    on: {
      cancel: _vm.resetUsageInfo,
      submit: _vm.submitUploadLimit
    },
    scopedSlots: _vm._u([{
      key: "card-content",
      fn: function (_ref2) {
        let {
          isEditing
        } = _ref2;
        return [_c("div", {
          staticClass: "usage-info-card"
        }, [_c("div", {
          staticClass: "usage-label"
        }, [_vm._v("企业本月总用量：")]), _c("div", {
          staticClass: "usage-value"
        }, [_vm._v(" " + _vm._s(_vm.handleMinutes(_vm.usageInfo.org_used_duration)) + " / " + _vm._s(_vm.usageInfo.org_max_duration === -1 ? "无上限" : _vm.handleMinutes(_vm.usageInfo.org_max_duration)) + " "), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.usageInfo.org_max_duration !== -1,
            expression: "usageInfo.org_max_duration !== -1"
          }]
        }, [_vm._v("(已用" + _vm._s(_vm.getUsedPercentage) + "%)")])]), _vm.usageInfo.org_max_duration !== -1 ? _c("el-progress", {
          attrs: {
            percentage: _vm.usageInfo.org_used_duration / _vm.usageInfo.org_max_duration * 100,
            "show-text": false,
            "stroke-width": 10
          }
        }) : _vm._e()], 1), _c("div", {
          staticClass: "member-limit"
        }, [_c("div", {
          staticClass: "limit-label"
        }, [_vm._v("成员每月上传限制：")]), _c("el-input-number", {
          attrs: {
            value: _vm.usageInfo.user_max_duration / 60,
            disabled: !isEditing,
            controls: false,
            size: "small",
            step: 1,
            min: 1,
            precision: 0
          },
          on: {
            change: _vm.memberLimitChange
          }
        }), _c("div", {
          staticClass: "unit"
        }, [_vm._v("小时")])], 1), _c("div", {
          staticClass: "member-exceed-limit"
        }, [_c("el-checkbox", {
          attrs: {
            disabled: !isEditing
          },
          model: {
            value: _vm.usageInfo.is_use_org_max_duration,
            callback: function ($$v) {
              _vm.$set(_vm.usageInfo, "is_use_org_max_duration", $$v);
            },
            expression: "usageInfo.is_use_org_max_duration"
          }
        }, [_vm._v("允许个别成员超上限使用（企业总量用完后所有人无法再上传会话）")]), _c("mgv-member-multi-select", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.usageInfo.is_use_org_max_duration,
            expression: "usageInfo.is_use_org_max_duration"
          }],
          ref: "member-tree-select",
          attrs: {
            "is-prevent-not-selected": false,
            "request-function": _vm.getOrgMemberTree,
            "request-search-function": _vm.getUserList,
            "request-params": {
              size: 9999
            },
            "backfill-list": _vm.usageInfo.user_infos,
            "show-tag-count": 20,
            "read-only": !isEditing
          },
          on: {
            change: _vm.membersChange
          }
        })], 1)];
      }
    }])
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;