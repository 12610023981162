"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "course-container"
  }, [_c("div", {
    staticClass: "left-category-menu"
  }, [_c("menu-toggle-btn", {
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      title: _vm.$t("coaching.courseManagement")
    }
  }), _c("div", {
    staticClass: "catalog-top"
  }, [_c("span", {
    staticClass: "sub-title"
  }, [_c("i", {
    staticClass: "iconfont icon-catalogue"
  }), _vm._v(_vm._s(_vm.$t("coaching.courseClassification")))]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.$t("coaching.addClassification"),
      placement: "bottom"
    }
  }, [_c("span", {
    staticClass: "add",
    on: {
      click: function ($event) {
        return _vm.handleCreateCatalog(false);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus"
  })])])], 1), _c("el-input", {
    staticClass: "course-search",
    attrs: {
      placeholder: _vm.$t("coaching.searchByCourseName"),
      clearable: "",
      "prefix-icon": "iconfont icon-search"
    },
    on: {
      change: _vm.searchFilterChange
    },
    model: {
      value: _vm.searchFilter,
      callback: function ($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  }), _c("CourseCatalog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.catalogLoading,
      expression: "catalogLoading"
    }],
    attrs: {
      "catalog-data": _vm.catalogData,
      "current-node-id": _vm.currentCategory.id,
      "course-types": _vm.courseTypes
    },
    on: {
      refreshTree: _vm.refreshTree,
      nodeClick: _vm.handleNodeClick,
      createCourse: _vm.handleCommand,
      createCatalog: _vm.handleCreateCatalog,
      renameCatalog: _vm.handleRenameCatalog,
      deleteCatalog: _vm.handleDeleteCatalog,
      editPublicScope: _vm.handleEditPublicScope
    }
  })], 1), _c("div", {
    staticClass: "right-course-container"
  }, [_c("header", [_c("div", {
    class: ["detail", {
      "visibility-hidden": !_vm.currentCategory.name
    }]
  }, [_c("span", {
    staticClass: "info"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.currentCategory.name))]), _c("span", {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.creator")) + "：" + _vm._s(_vm.currentCategory.creater || "-") + " ")])])]), _c("el-dropdown", {
    staticClass: "creat-course-btn",
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.newCourse"))), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.courseTypes, function (_ref) {
    let {
      type,
      icon,
      label,
      color
    } = _ref;
    return _c("el-dropdown-item", {
      key: type,
      attrs: {
        command: type
      }
    }, [_c("i", {
      class: ["iconfont", icon],
      style: {
        color: color
      }
    }), _vm._v(" " + _vm._s(_vm.$t("coaching.newHasVar", [_vm.$t(label, ["(Alpha)"])])) + " "), type === "6" ? _c("span", {
      staticClass: "beta-tag"
    }, [_vm._v("Beta")]) : _vm._e()]);
  }), 1)], 1)], 1), _c("article", [_c("div", {
    staticClass: "filters-container"
  }, [_c("FiltersGroup", {
    ref: "filters-group",
    attrs: {
      filter: _vm.listParams,
      "course-types": _vm.courseTypes
    },
    on: {
      filterChange: function ($event) {
        return _vm.filterChange({
          ...$event,
          page: 1
        });
      }
    }
  })], 1), _vm.courseList.length ? _c("div", {
    staticClass: "courser-list-container"
  }, _vm._l(_vm.courseList, function (course, index) {
    return _c("CourseCard", {
      key: course.id,
      attrs: {
        "course-info": course,
        "data-v-step": !index ? "firstCourseCard" : course.id
      },
      on: {
        moveCourse: _vm.moveCourse,
        createTask: _vm.handelCreateTask,
        refresh: _vm.refresh,
        openTaskRecordDrawer: _vm.openTaskRecordDrawer
      }
    });
  }), 1) : _vm._e(), !_vm.courseList.length && !_vm.loading ? _c("NoData", {
    attrs: {
      tips: _vm.$t("coaching.noCourses"),
      icon: _vm.listParams.filter ? "icon-a-Property1not-found" : "icon-a-Property1no-data"
    }
  }, [_vm.listParams.filter ? _c("div", {
    staticClass: "search-container",
    attrs: {
      slot: "tips"
    },
    slot: "tips"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("coaching.noResults", [_vm.listParams.filter])))])]) : _vm._e()]) : _vm._e()], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.onIsOnlyCurrentChange
    },
    model: {
      value: _vm.listParams.is_only_show_current,
      callback: function ($$v) {
        _vm.$set(_vm.listParams, "is_only_show_current", $$v);
      },
      expression: "listParams.is_only_show_current"
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.不显示子分类的课程")))]), _c("el-pagination", {
    staticClass: "mg-pagination small",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "current-page": _vm.listParams.page,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _vm.listParams.size,
      total: _vm.total
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.pageChange
    }
  })], 1)]), _c("CreateTaskDrawer", {
    attrs: {
      "has-link": "",
      "current-course-info": _vm.currentCourseInfo,
      visible: _vm.createTaskDrawerVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.createTaskDrawerVisible = $event;
      },
      refresh: _vm.handleCreateTask
    }
  }), _c("SmartTrainingDialog", {
    attrs: {
      "dialog-visible": _vm.smartTrainingDialogVisible
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.smartTrainingDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.smartTrainingDialogVisible = $event;
      }
    }
  }), _c("MoveCourseDialog", {
    attrs: {
      visible: _vm.moveCourseDialogVisible,
      "course-info": _vm.activeCourseInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveCourseDialogVisible = $event;
      },
      moveSuccess: _vm.refreshTree
    }
  }), _c("EditNameDialog", {
    attrs: {
      visible: _vm.editNameDialogVisible,
      "form-type": _vm.$t("coaching.category"),
      "edit-catalog-type": _vm.editCatalogType,
      label: _vm.editNameDialogLabel
    },
    on: {
      "update:visible": function ($event) {
        _vm.editNameDialogVisible = $event;
      },
      submit: _vm.handlerNameSuccess
    }
  }, [_vm.editCatalogType !== "renameCatalog" ? _c("SetPublicScope", {
    ref: "SetPublicScopeRef"
  }) : _vm._e()], 1), _c("SetPublicScopeDialog", {
    attrs: {
      "is-public": _vm.isPublic,
      "backfill-list": _vm.backfillList,
      visible: _vm.editPublicScopeDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.editPublicScopeDialogVisible = $event;
      },
      closeDialog: function ($event) {
        _vm.editPublicScopeDialogVisible = false;
      },
      submitEditPublicScope: _vm.handleSubmitEditPublicScope
    }
  }), _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      visible: _vm.taskRecordDrawerVisible,
      title: `${_vm.$t("coaching.taskRecord")}-${_vm.activeCourseInfo.name}`,
      size: "1000px",
      "destroy-on-close": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.taskRecordDrawerVisible = $event;
      }
    }
  }, [_vm.taskRecordDrawerVisible ? _c("TaskRecordDrawerContent", {
    attrs: {
      "course-info": _vm.activeCourseInfo
    }
  }) : _vm._e()], 1), _c("CreateAiCustomerDialog", {
    attrs: {
      visible: _vm.createAiCustomerDialogVisible,
      "category-id": _vm.currentCategory.id
    },
    on: {
      "update:visible": function ($event) {
        _vm.createAiCustomerDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;