"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("article", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.biStatementArr,
      expression: "!biStatementArr"
    }],
    staticClass: "analytical-statement"
  }, [_c("analysis-header-button"), _vm._l(_vm.biStatementArr, function (item, index) {
    return _c("statement-item", {
      key: index,
      attrs: {
        index: index,
        item: item
      }
    });
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;