"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _useRouter = require("@/hooks/use-router");
var _routeMap = require("@/router/routeMap");
var _ModelAndVersionSelect = _interopRequireDefault(require("../../modelManagement/components/ModelAndVersionSelect.vue"));
var _modelManagement = require("@/api/modelManagement");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SetModelDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  emits: ['update:visible', 'submitSuccess'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const loading = (0, _vue.ref)(false);
    const model_version = (0, _vue.ref)('');
    const model_id = (0, _vue.ref)('');
    const router = (0, _useRouter.useRouter)();
    const goToModelManagementPage = modelId => {
      // 新标签页打开模型管理页
      const page = router.resolve({
        path: _routeMap.ROUTES.ModelManagement,
        query: {
          model_id: modelId
        }
      });
      window.open(page.href, '_blank');
    };
    const modelAndVersionSelectRef = (0, _vue.ref)(null);
    const closeDialog = () => {
      model_id.value = '';
      model_version.value = '';
      modelAndVersionSelectRef.value.initData();
      emit('update:visible', false);
    };
    const submit = async () => {
      loading.value = true;
      const res = await (0, _modelManagement.eventAssociateModel)({
        event_id: props.eventInfo.id,
        model_id: model_id.value,
        model_version: model_version.value
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        _elementUi.Message.success('配置成功');
        emit('submitSuccess');
        closeDialog();
      }
    };
    const selectChange = val => {
      console.log('model_id, model_version', val);
      model_id.value = val.model_id;
      model_version.value = val.model_version;
    };
    return {
      __sfc: true,
      props,
      loading,
      model_version,
      model_id,
      router,
      goToModelManagementPage,
      modelAndVersionSelectRef,
      emit,
      closeDialog,
      submit,
      selectChange,
      ModelAndVersionSelect: _ModelAndVersionSelect.default
    };
  }
};
exports.default = _default;