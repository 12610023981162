"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog front-dialog",
    attrs: {
      title: `${_vm.metric ? "编辑" : "新增"}评分指标`,
      visible: _vm.visible,
      "append-to-body": true,
      width: "600px"
    },
    on: {
      "update:visible": _setup.updateVisible
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("MgvButton", {
          staticClass: "cancel",
          attrs: {
            type: "plain",
            color: "info"
          },
          on: {
            click: function ($event) {
              return _setup.updateVisible(false);
            }
          }
        }, [_vm._v("取消")]), _c("MgvButton", {
          staticClass: "submit-button",
          on: {
            click: _setup.submit
          }
        }, [_vm._v("保存指标")])];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "formRef",
    staticClass: "dialog-content",
    attrs: {
      model: _setup.form,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "label-row"
  }, [_c("span", {
    staticClass: "label-text"
  }, [_vm._v("指标名称")])]), _c("el-form-item", {
    attrs: {
      prop: "name",
      rules: {
        required: true,
        message: "指标名称不能为空",
        trigger: "change"
      }
    }
  }, [_c("div", [_c("el-input", {
    attrs: {
      placeholder: "请输入指标名称",
      maxlength: "10"
    },
    model: {
      value: _setup.form.name,
      callback: function ($$v) {
        _vm.$set(_setup.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)]), _c("div", {
    staticClass: "label-row"
  }, [_c("span", {
    staticClass: "label-text"
  }, [_vm._v("归属维度")])]), _c("el-form-item", {
    attrs: {
      prop: "dimension_id",
      rules: {
        required: true,
        message: "归属维度不能为空",
        trigger: "change"
      }
    }
  }, [_c("el-select", {
    staticClass: "dimension-select",
    model: {
      value: _setup.form.dimension_id,
      callback: function ($$v) {
        _vm.$set(_setup.form, "dimension_id", $$v);
      },
      expression: "form.dimension_id"
    }
  }, _vm._l(_setup.dimensionList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "label-row"
  }, [_c("span", {
    staticClass: "label-text"
  }, [_vm._v("指标要点")]), _c("span", {
    staticClass: "label-text-info"
  }, [_vm._v("单个指标满分100分，按要点命中数量平均计分")]), _c("p", {
    staticClass: "label-row-info"
  }, [_vm._v("要点描述需要有明确的“是/否”判断，如：")]), _c("p", {
    staticClass: "label-row-info"
  }, [_vm._v("a.开场白是否明确交待了公司职位和致电原因")]), _c("p", {
    staticClass: "label-row-info"
  }, [_vm._v("b.结束语是否向客户表示感谢")])]), _vm._l(_setup.form.gists, function (gist, index) {
    return _c("el-form-item", {
      key: index,
      attrs: {
        prop: "gists." + index + ".content",
        rules: {
          required: true,
          message: "要点描述不能为空",
          trigger: "change"
        }
      }
    }, [_c("div", {
      staticClass: "form-item-content"
    }, [_c("div", {
      staticClass: "row"
    }, [_c("el-input", {
      attrs: {
        placeholder: "请输入要点描述，包含“是/否”判断",
        maxlength: "50"
      },
      model: {
        value: gist.content,
        callback: function ($$v) {
          _vm.$set(gist, "content", $$v);
        },
        expression: "gist.content"
      }
    }), _c("MgvButton", {
      staticClass: "delete-button",
      attrs: {
        icon: "icon-delete",
        type: "plain",
        square: "",
        color: "info",
        disabled: _setup.form.gists.length <= 1
      },
      on: {
        click: function ($event) {
          return _setup.deleteGist(index);
        }
      }
    })], 1)])]);
  }), _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      disabled: _setup.form.gists.length >= 3,
      type: "text"
    },
    on: {
      click: _setup.addGist
    }
  }, [_vm._v("添加要点（" + _vm._s(_setup.form.gists.length) + "/3）")])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;