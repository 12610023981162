"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _RuleSettingForm = _interopRequireDefault(require("@/views/conversationScoreRule/components/RuleSettingForm.vue"));
var _RightDrawer = _interopRequireDefault(require("@/views/conversationScoreRule/components/RightDrawer.vue"));
var _ConvListDrawer = _interopRequireDefault(require("@/views/compliance/components/ConvListDrawer.vue"));
var _ScoreRuleEnableDialog = _interopRequireDefault(require("@/components/ScoreRuleEnableDialog.vue"));
var _compliance = require("@/api/compliance");
var _conversationList = require("@/api/conversationList");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
var _cancelTokenMixin = _interopRequireDefault(require("@/mixin/cancelTokenMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateScoreRule',
  components: {
    RuleSettingForm: _RuleSettingForm.default,
    DetailHeader: _DetailHeader.default,
    RightDrawer: _RightDrawer.default,
    ConvListDrawer: _ConvListDrawer.default,
    ScoreRuleEnableDialog: _ScoreRuleEnableDialog.default
  },
  mixins: [_cancelTokenMixin.default],
  data() {
    return {
      title: '新建评分规则',
      formLoading: false,
      saveBtnLoading: false,
      saveAndEnableBtnLoading: false,
      confirmBtnLoading: false,
      dialogVisible: false,
      formData: {
        name: '',
        description: '',
        data_scope: {
          config: JSON.stringify({})
        },
        score_item_config: {
          scoring_mode: 'increase',
          score_items: [],
          qualified: {
            is_on: false,
            qualify_standard: 60
          },
          full_score: 100
        },
        visible_range: {
          is_public: true,
          visible_members: {}
        }
      },
      currentEditFormModel: 'create',
      targetFormData: {},
      // 作为打开编辑或者创建窗口时被对比的原对象，来确定在关闭页面时是否提示未保存
      initScoreRuleObj: {},
      convFilterCount: 100,
      filterNum: 0,
      convListDrawerVisible: false,
      list: [],
      total: 0,
      conversationCount: 0,
      countLoading: false,
      listLoading: false,
      rightActiveName: '',
      currentScoreId: '',
      currentScoreItem: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.currentEditFormModel = this.$route.query.type;
          if (this.currentEditFormModel !== 'create') {
            if (this.currentEditFormModel === 'edit') {
              this.title = '编辑评分规则';
              document.title = '编辑评分规则';
            }
            const ruleId = Number(this.$route.query.id);
            this.getComplianceScoreInfo(ruleId);
          } else {
            this.getConversationListCount();
          }
        }
      },
      immediate: true
    },
    rightActiveName(val) {
      if (!val || val !== 'editScore') {
        this.currentScoreId = '';
      }
    }
  },
  methods: {
    editScore(id) {
      this.selectRightActive('editScore', id);
      this.currentScoreId = id;
      this.currentScoreItem = this.formData.score_item_config.score_items.find(item => item.id === id);
    },
    deleteScoreRule(id) {
      if (this.currentScoreId === id) {
        this.rightActiveName = '';
      }
    },
    selectRightActive(name, id) {
      if (this.rightActiveName === name) {
        if (name === 'editScore' && this.currentScoreId !== id) {
          this.rightActiveName = name;
        } else {
          this.rightActiveName = '';
        }
      } else {
        this.rightActiveName = name;
      }
    },
    // 会话时间筛选变化
    dateFilterChange(_ref) {
      let {
        type,
        range
      } = _ref;
      if (!this.formData.data_scope.config.date_new) {
        this.formData.data_scope.config.date_new = {};
      }
      this.formData.data_scope.config.date_new.range = range;
      this.formData.data_scope.config.date_new.type = type;
      this.getConversationListCount();
    },
    // 获得会话列表筛选的数量
    getConversationListCount: (0, _commonFunc.debounce)(async function () {
      var _params$date_new, _params$date_new$rang;
      if (!this.workspaceInfo.id) return;
      this.countLoading = true;
      if (typeof this.formData.data_scope.config === 'string') {
        this.formData.data_scope.config = JSON.parse(this.formData.data_scope.config);
      }
      if (!this.formData.data_scope.config.date_new) {
        this.$set(this.formData.data_scope.config, 'date_new', {
          type: 'after',
          range: [this.$moment().format('YYYY-MM-DD HH:mm:ss')]
        });
      }
      const params = {
        ...this.formData.data_scope.config,
        workspace_id: this.workspaceInfo.id,
        from_score_rule: true,
        is_score_rule_data_range: true
      };
      if ((_params$date_new = params.date_new) !== null && _params$date_new !== void 0 && (_params$date_new$rang = _params$date_new.range) !== null && _params$date_new$rang !== void 0 && _params$date_new$rang.length) {
        params.date_new.range = params.date_new.range.map(item => this.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
      }
      params.time_zone = this.timezone;
      const res = await (0, _conversationList.getConversationListCount)(params).finally(() => {
        this.countLoading = false;
      });
      if (res.code === 200) {
        this.conversationCount = res.results.count;
      }
    }, 500),
    openConvListDrawer() {
      this.convListDrawerVisible = true;
      this.getConvList();
    },
    getConvList: (0, _commonFunc.debounce)(async function (val) {
      var _params$date_new2;
      const params = (0, _commonFunc.deepClone)(this.formData.data_scope.config);
      params.workspace_id = this.workspaceInfo.id;
      params.from_score_rule = true;
      if (val) {
        const {
          current_page,
          size,
          sort_by,
          sort_order
        } = val;
        params.sort_by = sort_by;
        params.current_page = current_page;
        params.size = size;
        params.sort_order = sort_order;
      }
      if ((_params$date_new2 = params.date_new) !== null && _params$date_new2 !== void 0 && _params$date_new2.range.length) {
        params.date_new.range = params.date_new.range.map(item => this.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
      }
      (0, _timezone.convertParams2UTCplus8)(params.custom_filters);
      params.time_zone = this.timezone;
      this.listLoading = true;
      // 取消上一次请求
      this.axiosCancelRequest();
      // 使用新的cancelToken
      this.newCancelToken();
      const res = await (0, _conversationList.getConversationList)(params, {
        cancelToken: this.axiosCancelToken
      }).finally(() => {
        this.listLoading = false;
      });
      if (res.code === 200) {
        this.list = res.results.items;
        this.handleList(this.list);
        this.total = res.results.total_count;
      }
    }, 300),
    // 处理数据的方法
    handleList(list) {
      for (let i = 0; i < list.length; i++) {
        const arr = [];
        list[i].participants.map(item => {
          if (item.entity_type === 'host_salesman' || item.entity_type === '') {
            list[i].host = item;
          } else if (item.entity_type === 'assistant_salesman' || item.entity_type === 'spectator_salesman') {
            arr.push(item);
            list[i].others = arr;
          }
        });
      }
    },
    // 配置筛选条件变化
    filterNumChange(val) {
      this.filterNum = val;
    },
    // 配置筛选条件变化
    convFlowFiltersChange(val) {
      this.formData.data_scope.config = {
        ...this.formData.data_scope.config,
        ...val
      };
      this.getConversationListCount();
    },
    openFilterDrawer() {
      this.selectRightActive('conversationFilter');
    },
    isFormDataChange() {
      const editedData = (0, _commonFunc.deepClone)(this.formData);
      this.stringifyData(editedData);
      delete editedData.data_scope.time_zone;
      if (JSON.stringify(this.targetFormData) !== '{}') {
        delete this.targetFormData.data_scope.time_zone;
      }
      return !(0, _isEqual.default)(editedData, this.targetFormData);
    },
    cancelEdit() {
      if (this.isFormDataChange()) {
        this.$confirm('当前修改内容未保存，确认退出编辑吗？', '修改未保存', {
          confirmButtonText: '退出编辑',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cancelEditScoreRule();
        }).catch(() => {});
      } else {
        this.cancelEditScoreRule();
      }
    },
    async cancelEditScoreRule(id) {
      if (this.currentEditFormModel === 'edit') {
        const data = {
          workspace_id: this.workspaceInfo.id,
          rule_id: this.formData.id
        };
        const res = await (0, _compliance.cancelEditScoreRule)(data);
        if (res.code === 20000) {
          this.$router.push({
            path: _routeMap.ROUTES.complianceScoreSetting,
            query: {
              id
            }
          });
        }
      } else {
        this.$router.push({
          path: _routeMap.ROUTES.complianceScoreSetting,
          query: {
            id
          }
        });
      }
    },
    async saveAndEnableRule() {
      const validate = await this.$refs.ruleSettingForm.formValidate();
      if (validate) {
        this.isSaveAndEnable = true;
        const info = {
          id: this.formData.id,
          name: this.formData.name,
          is_on: false
        };
        this.openDialog('on', info);
      }
    },
    openDialog(key, info) {
      this.activeOperate = key;
      this.activeItemInfo = info;
      this.isCheckedOldData = this.$options.data().isCheckedOldData;
      this.oldDataDateValue = this.$options.data().oldDataDateValue;
      this.dialogVisible = true;
    },
    dialogSubmit() {
      if (this.isSaveAndEnable) {
        this.saveRuleRequest(true);
      }
    },
    getComplianceScoreInfo(id) {
      this.formLoading = true;
      (0, _compliance.getComplianceScoreInfo)({
        workspace_id: this.workspaceInfo.id,
        rule_id: id
      }).then(res => {
        this.formData = res.results;
        if (this.currentEditFormModel === 'copy') {
          this.formData.name = this.formData.name.substring(0, 17) + ' 副本';
        }
        this.targetFormData = (0, _commonFunc.deepClone)(this.formData);
        this.initScoreRuleObj = (0, _commonFunc.deepClone)(res.results.score_item_config);
      }).finally(() => {
        this.formLoading = false;
      });
    },
    checkScoreObjChange(editedData) {
      return !(0, _isEqual.default)(editedData, this.initScoreRuleObj);
    },
    async saveRule() {
      const validate = await this.$refs.ruleSettingForm.formValidate();
      if (validate) {
        this.saveRuleRequest(false);
      }
    },
    saveRuleRequest() {
      let needEnable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.confirmBtnLoading = true;
      const api = this.currentEditFormModel === 'edit' ? _compliance.updateComplianceScoreRule : _compliance.createComplianceScoreRule;
      const data = this.handlePostFormData();
      if (needEnable) {
        this.saveAndEnableBtnLoading = true;
      } else {
        this.saveBtnLoading = true;
      }
      api(data).then(async res => {
        // 编辑锁失效，有效时长为10分钟
        if (res.code === 43206) {
          this.$message({
            type: 'warning',
            message: '编辑失效，请重新获取编辑锁'
          });
          this.$router.push({
            path: _routeMap.ROUTES.complianceScoreSetting
          });
        }
        if (res.code === 20000) {
          const id = res.results.id || this.formData.id;
          if (!needEnable) {
            // 仅保存
            this.saveBtnLoading = false;
            this.$message.success('保存成功');
            this.cancelEditScoreRule(id);
          } else {
            // 保存并启用

            const id = res.results.id || this.formData.id;
            const info = {
              id,
              is_on: false,
              name: this.formData.name
            };
            const changeRes = await this.changeComplianceScoreRuleStatus(info);
            if (changeRes.code === 200) {
              this.isSaveAndEnable = false;
              this.saveAndEnableBtnLoading = false;
              this.confirmBtnLoading = false;
              this.cancelEditScoreRule(id);
            }
          }
        }
      }).finally(() => {
        this.saveAndEnableBtnLoading = false;
        this.saveBtnLoading = false;
        this.confirmBtnLoading = false;
      });
    },
    async changeComplianceScoreRuleStatus(info) {
      return new Promise((resolve, reject) => {
        this.confirmBtnLoading = true;
        const data = {
          workspace_id: this.workspaceInfo.id,
          rule_id: info.id,
          is_on: !info.is_on,
          date_range: 0
        };
        (0, _compliance.changeComplianceScoreRuleStatus)(data).then(res => {
          if (res.code === 200) {
            this.$message.success('保存成功');
            info.is_on = !info.is_on;
            this.dialogVisible = false;
            if (info.is_on) {
              const message = this.conversationCount ? '启用成功，会话正在评分中，完成时会再次消息通知您' : '启用成功';
              this.$message.success(message);
            }
          }
          resolve(res);
        }).finally(() => {
          this.confirmBtnLoading = false;
        });
      });
    },
    handlePostFormData() {
      const data = (0, _commonFunc.deepClone)(this.formData);
      this.stringifyData(data);
      if (this.currentEditFormModel !== 'create') {
        data.score_item_config.has_changed = this.checkScoreObjChange(data.score_item_config);
        const members = data.visible_range.visible_members;
        if (Array.isArray(members)) {
          data.visible_range.visible_members = this.handleUserMembersValue(members);
        }
        if (this.currentEditFormModel === 'copy') {
          delete data.id;
          data.score_item_config.score_items.forEach(item => {
            delete item.id;
          });
        }
      }
      data.score_item_config.score_items.forEach(item => {
        if (item.score_type === 1) {
          item.score_ext = '';
        } else {
          item.score = JSON.parse(item.score_ext).score;
        }
      });
      data.workspace_id = this.workspaceInfo.id;
      return data;
    },
    handleUserMembersValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    stringifyData(data) {
      var _data$score_item_conf, _data$score_item_conf2, _data$score_item_conf3, _data$score_item_conf4, _data$score_item_conf5, _data$score_item_conf6;
      // 传递给后端时区
      data.data_scope.time_zone = this.timezone;
      // 每一评分项分数转化为 Number
      (_data$score_item_conf = data.score_item_config) === null || _data$score_item_conf === void 0 ? void 0 : (_data$score_item_conf2 = _data$score_item_conf.score_items) === null || _data$score_item_conf2 === void 0 ? void 0 : _data$score_item_conf2.forEach(item => {
        item.score = Number(item.score);
      });
      // 总分转化为 Number
      data.score_item_config.full_score = Number(data.score_item_config.full_score);
      // 设置合格分数转化为 Number
      if ((_data$score_item_conf3 = data.score_item_config) !== null && _data$score_item_conf3 !== void 0 && (_data$score_item_conf4 = _data$score_item_conf3.qualified) !== null && _data$score_item_conf4 !== void 0 && _data$score_item_conf4.qualify_standard) {
        data.score_item_config.qualified.qualify_standard = Number(data.score_item_config.qualified.qualify_standard);
      }
      (_data$score_item_conf5 = data.score_item_config) === null || _data$score_item_conf5 === void 0 ? void 0 : (_data$score_item_conf6 = _data$score_item_conf5.score_items) === null || _data$score_item_conf6 === void 0 ? void 0 : _data$score_item_conf6.forEach(item => {
        item.config = JSON.stringify(item.config);
      });
      (0, _timezone.convertParams2UTCplus8)(data.data_scope.config.date);
      data.data_scope.config = JSON.stringify(data.data_scope.config);
    }
  }
};
exports.default = _default;