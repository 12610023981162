"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "converCard",
    staticClass: "conver-list-card"
  }, _vm._l(_vm.list, function (item) {
    return _c("card-item", _vm._b({
      key: item.id,
      attrs: {
        item: item,
        list: _vm.list
      },
      on: {
        openEventKeyDrawer: _vm.openEventKeyDrawer
      }
    }, "card-item", _vm.$attrs, false));
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;