"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-top"
  }, [_c("div", [_vm.foldable ? _c("i", {
    class: ["iconfont", "clickable", _vm.isFold ? "icon-caret-right" : "icon-caret-bottom"],
    on: {
      click: _vm.toggleFold
    }
  }) : _vm._e(), _c("i", {
    staticClass: "iconfont icon-magic-1 colorful"
  }), _c("span", {
    staticClass: "name"
  }, [_vm._v("AI助手")])]), _c("i", {
    staticClass: "el-icon-close clickable",
    on: {
      click: function ($event) {
        return _vm.$emit("close");
      }
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;