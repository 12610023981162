"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dialogue-scenar-list-container"
  }, [_c("el-collapse", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.sceneList.length,
      expression: "sceneList.length"
    }],
    ref: "coll",
    model: {
      value: _setup.activeNames,
      callback: function ($$v) {
        _setup.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c(_setup.MgDraggable, {
    model: {
      value: _setup.sceneList,
      callback: function ($$v) {
        _setup.sceneList = $$v;
      },
      expression: "sceneList"
    }
  }, _vm._l(_setup.sceneList, function (item, index) {
    return _c("el-collapse-item", {
      key: item.id,
      attrs: {
        name: item.id
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("div", {
      staticClass: "left"
    }, [_c("i", {
      staticClass: "iconfont move icon-drag-dot-horizontal"
    }), _c("i", {
      staticClass: "arrow el-icon-caret-right"
    }), _c("div", {
      staticClass: "scene-name"
    }, [_setup.editSceneId !== item.id ? _c("span", [_vm._v(_vm._s(item.name) + "(" + _vm._s(item.knowledges.length) + ")")]) : _c("el-input", {
      ref: "editInputRef",
      refInFor: true,
      attrs: {
        maxlength: 20
      },
      on: {
        focus: _setup.titleInputFocus,
        blur: function ($event) {
          return _setup.titleInputBlur(item);
        }
      },
      nativeOn: {
        keyup: function ($event) {
          if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          $event.stopPropagation();
          return _setup.handleSceneNameChange(item);
        }
      },
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    })], 1)]), _c("div", {
      class: {
        "visibility-hidden": _setup.editSceneId === item.id
      }
    }, [_c("el-button", {
      staticClass: "gray-text-button hover-show-bg",
      attrs: {
        icon: "el-icon-plus",
        type: "text"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.openAddScriptDialog(item);
        }
      }
    }, [_vm._v("添加客户问题")]), _c("el-button", {
      staticClass: "hover-show-bg",
      attrs: {
        icon: "iconfont icon-edit"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.editSceneName(item);
        }
      }
    }), _c("el-button", {
      staticClass: "hover-show-bg",
      attrs: {
        icon: "iconfont icon-delete"
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _setup.deleteScene(index);
        }
      }
    })], 1)]), _c(_setup.KnowledgeList, {
      attrs: {
        "knowledge-script-list": item.knowledges
      }
    })], 2);
  }), 1)], 1), _c("el-button", {
    staticClass: "add-script-button",
    attrs: {
      icon: "el-icon-plus",
      size: "medium",
      disabled: _setup.sceneList.length >= 30
    },
    on: {
      click: _setup.addScene
    }
  }, [_vm._v("添加对话场景")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;