"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Page404',
  data() {
    return {
      homeUrl: window.location.origin
    };
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.cancel, false);
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.cancel, false);
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    goHome() {
      window.open(this.homeUrl, '_self');
    }
  }
};
exports.default = _default;