module.exports = {
  title: '销售能力的智能延伸',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @description 本地连接的远程服务器地址
   */
  apiTarget: 'https://jirui.test.mgvai.cn'
  // webApiTarget: 'https://jirui.test.mgvai.cn'
  // apiTarget: 'https://jirui.dev.mgvai.cn',
  // apiTarget: 'https://jirui.dev.mgvai.cn',
  // apiTarget: 'https://jirui.test2.mgvai.cn',
  // webApiTarget: 'https://jirui.test2.mgvai.cn'
  // apiTarget: 'http://jirui.yangchen.mgvai.cn',
  // webApiTarget: 'http://jirui.yangchen.mgvai.cn'
  // webApiTarget: 'http://192.168.1.154:9300',
  // apiTarget: 'https://jirui.dev.mgvai.cn',
  // webApiTarget: 'http://192.168.1.206:5223'
  // apiTarget: 'http://192.168.1.206:5285',
  // webApiTarget: 'http://192.168.1.206:5992'
  // apiTarget: 'http://192.168.1.206:6670'
  // webApiTarget: 'http://192.168.1.154:2041'
  // webApiTarget: 'http://192.168.1.154:9501'
  // webApiTarget: 'http://192.168.1.167:8888'
  // webApiTarget: 'http://192.168.110.80:9999'
  // apiTarget: 'https://prepublish.megaview.com',
  // webApiTarget: 'https://prepublish.megaview.com'
  // apiTarget: 'https://app.megaview.com',
  // webApiTarget: 'https://app.megaview.com'
  // apiTarget: 'https://jirui.dev.mgvai.cn',
  // webApiTarget: 'http://192.168.110.168:14023'
  // apiTarget: 'http://shein.megaview.com',
  // webApiTarget: 'http://shein.megaview.com'
};