"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountCreateMeeting = accountCreateMeeting;
exports.accountSeaAssignsFollowUpBy = accountSeaAssignsFollowUpBy;
exports.accountSeaReceiveFollowUpBy = accountSeaReceiveFollowUpBy;
exports.editClientFollowUpBy = editClientFollowUpBy;
exports.getAccount = getAccount;
exports.getAccountBubble = getAccountBubble;
exports.getAccountConversation = getAccountConversation;
exports.getAccountSeaDetail = getAccountSeaDetail;
exports.getAccountSeaList = getAccountSeaList;
exports.getAllContactPersonList = getAllContactPersonList;
exports.getBusinessStatusOptions = getBusinessStatusOptions;
exports.getClientDetail = getClientDetail;
exports.getClientDetailConversation = getClientDetailConversation;
exports.getClientDynamicForm = getClientDynamicForm;
exports.getClientInfo = getClientInfo;
exports.getClientList = getClientList;
exports.getClientLogRecords = getClientLogRecords;
exports.getClientSeaTableHead = getClientSeaTableHead;
exports.getClientTableHead = getClientTableHead;
exports.getContactDynamicForm = getContactDynamicForm;
exports.getContactPerson = getContactPerson;
exports.getContactPersonList = getContactPersonList;
exports.getConvDetail = getConvDetail;
exports.getConvSentences = getConvSentences;
exports.getCustomerLabelList = getCustomerLabelList;
exports.getCustomerLabelTag = getCustomerLabelTag;
exports.getCustomerObjectionList = getCustomerObjectionList;
exports.getDealList = getDealList;
exports.getDynamicForm = getDynamicForm;
exports.getSortColumn = getSortColumn;
exports.postCreateAccountSea = postCreateAccountSea;
exports.postCreateAccountSeaContact = postCreateAccountSeaContact;
exports.postCreateClient = postCreateClient;
exports.postCreateContact = postCreateContact;
exports.postCreateDeal = postCreateDeal;
exports.postMoveAccountSea = postMoveAccountSea;
exports.saveClientPoolTableHead = saveClientPoolTableHead;
exports.saveClientTableHead = saveClientTableHead;
exports.updataClientDetail = updataClientDetail;
var _request = require("@/utils/request");
// ---------------------------------------------客户详情页面--------------------------------------------------
// 获取气泡图
function getAccountBubble(params) {
  return (0, _request.webService)({
    url: '/account/detail/bubble',
    method: 'get',
    params
  });
}

// 获取客户跟进
function getAccountConversation(params) {
  return (0, _request.webService)({
    url: '/account/detail/conversation',
    method: 'get',
    params
  });
}

// 获取客户商机列表
function getDealList(account_id) {
  return (0, _request.webService)({
    url: '/account/detail/deal',
    method: 'get',
    params: {
      account_id
    }
  });
}

// 获取客户页面客户标签
function getCustomerLabelList(params) {
  return (0, _request.webService)({
    url: '/account/detail/label',
    method: 'get',
    params
  });
}

// 获取客户页面客户异议标签
function getCustomerObjectionList(params) {
  return (0, _request.webService)({
    url: '/account/detail/objection',
    method: 'get',
    params
  });
}

// 会话中客户标签命中详细信息
function getCustomerLabelTag(params) {
  return (0, _request.webService)({
    url: '/account/tag/detail',
    method: 'get',
    params
  });
}

// 获取会话转录文字
function getConvSentences(conversation_id) {
  return (0, _request.webService)({
    url: '/conversation/detail/sentences',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 获取会话概览
function getConvDetail(params) {
  return (0, _request.webService)({
    url: '/account/conversation/detail',
    method: 'get',
    params
  });
}

// 获取客户信息
function getClientInfo(id) {
  return (0, _request.request)({
    url: '/account/account/detail_info',
    method: 'get',
    params: {
      id
    }
  });
}

// 获取客户联系人列表(分页)
function getContactPerson(account_id, page, size) {
  return (0, _request.webService)({
    url: '/account/contact/list',
    method: 'get',
    params: {
      account_id,
      page,
      size
    }
  });
}

// -----------------------------------------------------------------------------------------------------

// 获取排序信息
function getSortColumn(params) {
  return (0, _request.webService)({
    url: '/account/list/sort_conf',
    method: 'get',
    params
  });
}

// 获取新建商机动态表单
function getDynamicForm(params) {
  return (0, _request.request)({
    url: '/deal/deal/form',
    method: 'get',
    params
  });
}
// 获取客户的动态表单
function getClientDynamicForm(params) {
  return (0, _request.request)({
    url: '/account/account/form',
    method: 'get',
    params
  });
}
// 获取客户的列表头
function getClientTableHead(params) {
  return (0, _request.request)({
    url: '/account/account/head',
    method: 'get',
    params
  });
}
// 获取客户大公海的列表头
function getClientSeaTableHead(params) {
  return (0, _request.request)({
    url: '/account/account_pool/head',
    method: 'get',
    params
  });
}
// 客户下新建客户
function postCreateClient(data) {
  return (0, _request.request)({
    url: '/account/account/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 客户公海下新建客户
function postCreateAccountSea(data) {
  return (0, _request.request)({
    url: '/account/account/account_pool_create',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取客户列表
function getClientList(params) {
  return (0, _request.request)({
    url: '/account/account/list',
    method: 'get',
    params
  });
}
// 获取客户详情
function getClientDetail(id) {
  return (0, _request.request)({
    url: '/account/account/detail',
    method: 'get',
    params: {
      id
    }
  });
}
// 获取公海详情
function getAccountSeaDetail(id) {
  return (0, _request.request)({
    url: '/account/account/account_pool_detail',
    method: 'get',
    params: {
      id
    }
  });
}
// 获取客户详情会话相关
function getClientDetailConversation(id, page, size) {
  return (0, _request.request)({
    url: '/account/account/detail_conversation',
    method: 'get',
    params: {
      id,
      page,
      size
    }
  });
}

// 获取客户操作日志
function getClientLogRecords(id, page, size) {
  return (0, _request.request)({
    url: '/account/account_pool/handle_log',
    method: 'get',
    params: {
      id,
      page,
      size
    }
  });
}

// 修改客户详情
function updataClientDetail(data) {
  return (0, _request.request)({
    url: '/account/account/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 客户外呼
function accountCreateMeeting(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_in_account',
    method: 'post',
    data
  });
}

// 客户公海列表
function getAccountSeaList(params) {
  return (0, _request.request)({
    url: '/account/account_pool/list',
    method: 'get',
    params
  });
}

// 客户公海更改跟进人
function editClientFollowUpBy(data) {
  return (0, _request.request)({
    url: 'account/account_pool/edit_follow_up_by',
    method: 'post',
    data
  });
}

// 客户移入公海
function postMoveAccountSea(data) {
  return (0, _request.request)({
    url: '/account/account/move_to_pool',
    method: 'post',
    data
  });
}

// 模糊搜索客户
function getAccount(params) {
  return (0, _request.request)({
    url: '/account/account/filter',
    method: 'get',
    params
  });
}

// ------------------------------------------------------------------------------------------------------------------------------------
// 获取商机阶段
function getBusinessStatusOptions(params) {
  return (0, _request.request)({
    url: '/deal/deal/get_status_options',
    method: 'get',
    params
  });
}

// 新建商机
function postCreateDeal(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account/create_deal ',
    method: 'post',
    data
  });
}

// =========================================================    联系人   ==============================================
// 获取联系人的动态表单
function getContactDynamicForm(params) {
  return (0, _request.request)({
    url: '/field/field/form',
    method: 'get',
    params
  });
}

// 新建客户联系人
function postCreateContact(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account/contact',
    method: 'post',
    data
  });
}

// 新建公海下客户联系人
function postCreateAccountSeaContact(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account/account_pool_contact',
    method: 'post',
    data
  });
}

// 获取联系人的列表(分页)
function getContactPersonList(account_id, page, size) {
  return (0, _request.webService)({
    url: '/account/contact/list',
    method: 'get',
    params: {
      account_id,
      page,
      size
    }
  });
}

// 获取所有联系人
function getAllContactPersonList(account_id) {
  return (0, _request.request)({
    url: '/account/account/contact_list_no_page',
    method: 'get',
    params: {
      account_id
    }
  });
}

// 保存客户自定义表头设置
function saveClientTableHead(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account/head/on',
    method: 'post',
    data
  });
}

// 保存客户公海自定义表头设置
function saveClientPoolTableHead(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account_pool/head/on',
    method: 'post',
    data
  });
}

// 客户公海领取
function accountSeaReceiveFollowUpBy(data) {
  return (0, _request.request)({
    url: '/account/account_pool/receive_follow_up_by ',
    method: 'post',
    data
  });
}

// 客户公海分配
function accountSeaAssignsFollowUpBy(data) {
  return (0, _request.request)({
    url: '/account/account_pool/assigns_follow_up_by',
    method: 'post',
    data
  });
}