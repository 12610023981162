"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      visible: _vm.snippetVideoDialogVisible,
      "before-close": _vm.handleCloseDialog,
      title: _vm.title,
      width: "474px",
      "append-to-body": ""
    }
  }, [_vm._t("prefix"), _c("el-form", {
    ref: "createSnippet",
    attrs: {
      model: _vm.snippetForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "title"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("剪辑标题"), _c("span", {
    staticClass: "require-dot"
  }, [_vm._v(" *")])]), _c("el-input", {
    staticClass: "mg-input",
    attrs: {
      placeholder: "请输入剪辑标题",
      type: "textarea",
      "show-word-limit": "",
      maxlength: 150,
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      resize: "none"
    },
    model: {
      value: _vm.snippetForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.snippetForm, "title", $$v);
      },
      expression: "snippetForm.title"
    }
  })], 1), _vm.showSpaceSelect ? _c("el-form-item", {
    attrs: {
      prop: "space"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("空间"), _c("span", {
    staticClass: "require-dot"
  }, [_vm._v(" *")])]), _c("space-select", {
    on: {
      change: _vm.handleSpaceChange
    },
    model: {
      value: _vm.snippetForm.space_id,
      callback: function ($$v) {
        _vm.$set(_vm.snippetForm, "space_id", $$v);
      },
      expression: "snippetForm.space_id"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "folder_id"
    }
  }, [_c("div", {
    staticClass: "folder-line"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("选择文件夹"), _c("span", {
    staticClass: "require-dot"
  }, [_vm._v(" *")])]), _c("div", {
    directives: [{
      name: "api-permission",
      rawName: "v-api-permission",
      value: ["/webapi/training/library/folder/standard/create"],
      expression: "[\n              '/webapi/training/library/folder/standard/create'\n            ]"
    }],
    staticClass: "create-folder",
    on: {
      click: function ($event) {
        _vm.createFolderVisible = true;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-folder-add"
  }), _c("span", [_vm._v("新建文件夹")])])]), _c("choose-folder-select", {
    key: _vm.snippetForm.space_id,
    ref: "choose-folder-select",
    attrs: {
      value: _vm.snippetFolder,
      "space-id": _vm.snippetForm.space_id,
      "is-snippet": ""
    },
    on: {
      input: _vm.handleFolderSelect
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "description"
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("描述")]), _c("el-input", {
    staticClass: "mg-input",
    attrs: {
      type: "textarea",
      placeholder: "请输入描述，200字以内",
      rows: 5,
      "show-word-limit": "",
      maxlength: 200,
      resize: "none"
    },
    model: {
      value: _vm.snippetForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.snippetForm, "description", $$v);
      },
      expression: "snippetForm.description"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleCloseDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.createSnippet
    }
  }, [_vm._v("确 定")])], 1)], 2), _c("create-folder-dialog", {
    staticClass: "snippets-library-create-folder-dialog-container",
    class: ["snippets-library-create-folder-dialog-container", _vm.getClassByRoute()],
    attrs: {
      "show-space-select": _vm.showSpaceSelect,
      visible: _vm.createFolderVisible
    },
    on: {
      closeDialog: _vm.closeCreateFolderDialog,
      submitSuccess: _vm.handleCreateSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;