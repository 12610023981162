"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChooseFolderSelect = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/ChooseFolderSelect.vue"));
var _CreateFolderDialog = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/CreateFolderDialog.vue"));
var _SpaceSelect = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/SpaceSelect.vue"));
var _library = require("@/api/library");
var _workspace = require("@/api/workspace");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetVideoDialog',
  components: {
    ChooseFolderSelect: _ChooseFolderSelect.default,
    CreateFolderDialog: _CreateFolderDialog.default,
    SpaceSelect: _SpaceSelect.default
  },
  inject: {
    getConvSpaceId: {
      from: 'getConvSpaceId',
      default: () => () => {}
    }
  },
  props: {
    title: {
      type: String,
      default: '添加至剪辑库'
    },
    snippetVideoDialogVisible: {
      type: Boolean,
      default: false
    },
    conversationId: {
      type: [Number, String],
      default: ''
    },
    mediaType: {
      type: String,
      default: ''
    },
    snippetType: {
      type: String,
      default: 'some'
    },
    showSpaceSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      snippetForm: {
        title: '',
        space_id: '',
        folder_id: '',
        description: ''
      },
      rules: {
        title: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }],
        folder_id: [{
          required: true,
          message: '请选择文件夹',
          trigger: 'change'
        }]
      },
      // 新建文件夹
      createFolderVisible: false,
      snippetFolder: {},
      submitLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['saveSnippetVideoTime', 'snippetCheckList', 'workspaceInfo'])
  },
  watch: {
    snippetVideoDialogVisible(val) {
      if (val) {
        this.snippetForm.space_id = this.getConvSpaceId();
      }
    }
  },
  methods: {
    handleFolderSelect(val) {
      this.snippetFolder = val;
      this.snippetForm.folder_id = val.id;
    },
    handleCloseDialog() {
      this.$emit('closeDialog');
    },
    createSnippet() {
      this.$refs['createSnippet'].validate(async valid => {
        if (valid) {
          const params = this.getSubmitParams();
          this.submitLoading = true;
          const res = await (0, _library.createSnippet)(params).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 20000) {
            if (this.snippetType === 'all') {
              this.$message.success('添加至文件夹成功');
            } else {
              await this.switchSpace();
              this.$message.success({
                dangerouslyUseHTMLString: true,
                message: `添加剪辑成功，可在&nbsp;<a href="${_routeMap.ROUTES.snippetsLibrary}?folder_id=${params.folder_id}"><strong style="text-decoration:underline">剪辑库</strong></a>&nbsp;查看详情`
              });
            }
            this.$store.commit('video/SET_CURRENT_OPERATE_NAME', '');
            this.$emit('snippetWordSuccess');
            this.handleCloseDialog();
            this.$refs['createSnippet'].resetFields();
            this.snippetFolder = {};
          }
        }
      });
    },
    handleCreateSuccess(data) {
      this.snippetForm.space_id = data.spaceId;
      setTimeout(() => {
        this.snippetFolder = data;
        this.snippetForm.folder_id = data.id;
      }, 10);
    },
    closeCreateFolderDialog() {
      this.createFolderVisible = false;
      // create-folder-dialog里可能切换空间修改了folderTree的数据（folderTree数据是全局修改的），故关闭时重新请求get_tree
      this.$store.dispatch('library/get_tree', this.snippetForm.space_id);
    },
    async switchSpace() {
      const spaceId = this.snippetForm.space_id;
      if (spaceId === this.workspaceInfo.id) return;
      let workspaceItem;
      try {
        const res = await (0, _workspace.switchWorkspace)({
          id: spaceId
        });
        workspaceItem = res.results;
        this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
      } catch (error) {
        const isExist = await (0, _workspace.judgeWorkspaceExist)(spaceId);
        if (!isExist.results.exists) {
          workspaceItem = this.workspaceList[0];
          this.$store.dispatch('workspace/changeWorkspace', workspaceItem);
        }
      }
      sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceItem));
    },
    getSubmitParams() {
      let start = 0;
      let end = 0;
      if (this.snippetType !== 'all') {
        const {
          beginTime,
          endTime
        } = this.saveSnippetVideoTime;
        if (this.mediaType !== 'doc') {
          start = beginTime.toFixed(2);
          end = endTime.toFixed(2);
        }
      }
      return {
        type: this.snippetType,
        folder_id: this.snippetForm.folder_id,
        conversation_id: Number(this.conversationId),
        name: this.snippetForm.title,
        start: parseFloat(start),
        end: parseFloat(end),
        order: this.snippetCheckList.sort((a, b) => a - b),
        description: this.snippetForm.description,
        space_id: this.snippetForm.space_id
      };
    },
    getClassByRoute() {
      const map = {
        [_routeMap.ROUTES.snippetsLibrary]: 'snippet-library',
        [_routeMap.ROUTES.conversationDetail]: 'conv-detail'
      };
      return map[this.$route.path];
    },
    handleSpaceChange() {
      this.snippetFolder = {};
      this.snippetForm.folder_id = '';
    }
  }
};
exports.default = _default;