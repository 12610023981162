"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _memberInfo = _interopRequireDefault(require("./memberInfo"));
var _timeFormatter = require("@/utils/time-formatter");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SpeakTimeCard',
  components: {
    MemberInfo: _memberInfo.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hasAccountLabelPermission: {
      type: Boolean,
      default: false
    },
    clickCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    titleLogoSrc() {
      return this.item.origin_type === 'tencent_meeting' ? require('@/assets/tencent_meeting.svg') : require('@/assets/wework.svg');
    },
    getDuration() {
      return function (duration) {
        const {
          h,
          m,
          s
        } = (0, _timeFormatter.second2HMS)(duration);
        if (h) {
          return m ? `${h}h${m}m` : `${h}h`;
        } else {
          return m ? `${m}m` : `${s}s`;
        }
      };
    }
  },
  methods: {
    selectConv() {
      this.$emit('clickConvCard', this.item);
    },
    // 点击会话标题进入会话详情页
    goConvDetail() {
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${this.item.id}&noback=true`);
    },
    // 跟进动态-会话对应关键事件列表
    openEventsList(item) {
      if (!item.event_engine_count) return;
      this.$emit('openEventsList', item.id, item.date);
    },
    // 跟进动态-会话关键事件对应会话细节
    openKeyEventDetailList(event_id, item) {
      this.$emit('openKeyEventDetailList', event_id, item);
    },
    openCustomerLabelDetailList(tag, item) {
      this.$emit('openCustomerLabelDetailList', tag, item);
    },
    getTooltipText(type) {
      if (type === 'audio') {
        return '电话';
      } else if (type === 'doc') {
        return '文字';
      } else {
        return '在线会议';
      }
    },
    getIconType(type) {
      if (type === 'audio') {
        return 'phone';
      } else if (type === 'doc') {
        return 'im-fill';
      } else {
        return 'video-camera-solid-2';
      }
    }
  }
};
exports.default = _default;