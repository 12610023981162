"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberInfo',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    memberInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgName'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
exports.default = _default;