"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TeamConversationFilterGroup = _interopRequireDefault(require("@/views/compliance/teamConversation/components/TeamConversationFilterGroup"));
var _ComplianceBaseTable = _interopRequireDefault(require("@/views/compliance/components/ComplianceBaseTable"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
var _compliance = require("@/api/compliance");
var _complianceTableMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceTableMixin"));
var _complianceMethodMixin = _interopRequireDefault(require("@/views/compliance/mixins/complianceMethodMixin"));
var _ExportButton = _interopRequireDefault(require("../../components/ExportButton"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceTeamConversationContent',
  components: {
    TeamConversationFilterGroup: _TeamConversationFilterGroup.default,
    ComplianceBaseTable: _ComplianceBaseTable.default,
    NoData: _NoData.default,
    ExportButton: _ExportButton.default
  },
  mixins: [_complianceTableMixin.default, _complianceMethodMixin.default],
  props: {
    tableHeader: {
      type: Array,
      default: () => [{
        label: '会话名称',
        prop: 'conv_name',
        width: 260,
        fixed: 'left'
      }, {
        label: '会话时间',
        prop: 'conversation_time',
        width: 160
      }, {
        label: '质检员',
        prop: 'user_id',
        width: 140
      }, {
        label: '质检状态',
        prop: 'status',
        width: 120
      }, {
        label: '任务名称',
        prop: 'task_name',
        width: 180
      }, {
        label: '分配时间',
        prop: 'create_at',
        width: 160
      }, {
        label: '质检评分规则',
        prop: 'score_rule',
        width: 200
      }, {
        label: '质检评价单',
        prop: 'template',
        width: 200
      }]
    },
    tableHeight: {
      type: String,
      default: 'calc(100vh - 206px)'
    },
    sortable: {
      type: Array,
      default: () => ['status', 'conversation_time', 'create_at', 'task_name', 'score_rule', 'template']
    },
    defaultSort: {
      type: Object,
      default: () => ({
        prop: 'create_at',
        order: 'descending'
      })
    },
    isTotal: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filtersObj: {
        create_time: [],
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        status: 0,
        task_name: '',
        conversation_name: '',
        page: 1,
        size: 20,
        sort_by: [['create_at', 'descending']],
        workspace_id: 0,
        taskId: 0
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId']),
    noFilter() {
      const isSame = this.filtersObj.task_name === '' && this.filtersObj.conversation_name === '' && JSON.stringify(this.filtersObj.user_ids) === '{"tree_ids":[],"user_ids":[]}' && JSON.stringify(this.filtersObj.create_time) === '[]';
      if (!this.isTotal) {
        return isSame;
      }
      return this.filtersObj.status === 0 && isSame;
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.filtersObj.workspace_id = val;
        }
      },
      immediate: true
    }
  },
  created() {
    this.taskId = Number(this.$route.query.id) || '';
  },
  methods: {
    exportComplianceTeamConversationApi: _compliance.exportComplianceTeamConversationApi
  }
};
exports.default = _default;