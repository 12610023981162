"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dialogMixins = _interopRequireDefault(require("./dialogMixins.js"));
var _library = require("@/api/library");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  mixins: [_dialogMixins.default],
  data() {
    return {
      form: {
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.isSnippet) {
          const {
            name,
            description
          } = this.currentSnippet;
          this.form = {
            name,
            description
          };
        } else {
          this.form.name = this.currentNode.name;
        }
      }
    }
  },
  methods: {
    renameFolder: _library.renameFolder,
    editSnippet: _library.editSnippet,
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = this.isSnippet ? {
            ...this.form,
            snippet_id: this.currentSnippet.id
          } : {
            ...this.form,
            folder_id: this.currentNode.id
          };
          const res = await this[this.type](params);
          if ([200, 20000].includes(res.code)) {
            var _this$currentSnippet;
            this.closeDialog();
            if ((_this$currentSnippet = this.currentSnippet) !== null && _this$currentSnippet !== void 0 && _this$currentSnippet.isMedia) {
              this.$emit('mediaSubmitSucceed', res.results);
            } else {
              this.$emit('submitSucceed', this.isSnippet, 'rename', res.results);
            }
            this.$message.success('修改成功');
          }
        }
      });
    }
  }
};
exports.default = _default;