"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "experiment-detail"
  }, [_c("header", {
    staticClass: "detail-header"
  }, [_c("el-button", {
    staticClass: "goback-box hover-show-bg",
    on: {
      click: _vm.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  })], 1), _c("div", {
    staticClass: "detail"
  }, [_c("p", {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.experimentData.name) + " "), _c("span", {
    staticClass: "common-status",
    class: {
      "doing-status": _vm.experimentData.status === 2,
      "completed-status": _vm.experimentData.status === 3
    }
  }, [_c("i", {
    class: _vm.experimentData.status === 3 ? "el-icon-circle-check" : "el-icon-time"
  }), _vm._v(" " + _vm._s(_vm.judgeTestState(_vm.experimentData.status)) + " ")])]), _c("p", {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.experimentData.description) + " ")]), _c("div", {
    staticClass: "info"
  }, [_c("span", [_vm._v("监测来源: " + _vm._s(_vm.judgeTestSource(_vm.experimentData.source)))]), _c("span", {
    staticClass: "divider"
  }, [_vm._v("|")]), _c("span", [_vm._v("开始时间: " + _vm._s(_vm.plus8ToCurrentZone(_vm.experimentData.start_at)))]), _c("span", {
    staticClass: "divider"
  }, [_vm._v("|")]), _c("span", [_vm._v("结束时间: " + _vm._s(_vm.plus8ToCurrentZone(_vm.experimentData.end_at)))])])]), !_vm.experimentData.is_share_display && !_vm.isFromCoaching ? _c("el-button", {
    staticClass: "share-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleShareExperiment
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "share-line"
    }
  }), _vm._v("分享 ")], 1) : _vm._e()], 1), _c("div", {
    staticClass: "detail-container"
  }, [_vm.experimentData.status === 3 ? _c("report-overview", {
    attrs: {
      id: _vm.monitorParams.id
    }
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chartLoading,
      expression: "chartLoading"
    }],
    staticClass: "chart-container"
  }, [_c("p", {
    staticClass: "name"
  }, [_vm._v("指标趋势图")]), _c("detail-filter", {
    ref: "detail-filter",
    attrs: {
      id: _vm.monitorParams.id,
      "export-params": _vm.monitorParams,
      status: _vm.experimentData.status,
      "monitor-groups": _vm.monitorGroups,
      "monitor-targets": _vm.monitorTargets,
      "member-options": _vm.memberOptions
    },
    on: {
      setInterpose: _vm.setInterpose,
      filterChange: _vm.filterChange,
      groupChange: _vm.handleGroupChange,
      memberChange: _vm.handleMemberChange
    }
  }), _c("chart-template", {
    staticClass: "chart-template",
    attrs: {
      "chart-config": _vm.lineOptions,
      "chart-loading": _vm.chartLoading
    }
  }), _c("detail-table", {
    attrs: {
      "line-data": _vm.lineData,
      "time-column": _vm.getxAxisByDateInterval(),
      interpose: _vm.getInterposeByDateInterval(),
      "current-monitor-target": _vm.monitorParams.monitor_config,
      "unit-config": _vm.getUnitConfig
    }
  })], 1)], 1), _c("interpose-dialog", {
    attrs: {
      visible: _vm.interposeDialogVisible,
      interpose: _vm.interpose,
      "current-experiment": _vm.experimentData
    },
    on: {
      "update:visible": function ($event) {
        _vm.interposeDialogVisible = $event;
      },
      refresh: _vm.refresh
    }
  }), _c("shareConfig", {
    attrs: {
      visible: _vm.shareConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        id: _vm.monitorParams.id
      },
      "handle-fn": _vm.shareExperiment,
      "cancel-handle-fn": _vm.cancelShareExperiment,
      "get-list": _vm.getShareExperimentList
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareConfigVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;