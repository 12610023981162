"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ContactPersonCard = _interopRequireDefault(require("@/views/clients/components/ContactPersonCard"));
var _business = require("@/api/business");
var _trade = require("@/api/trade");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ContactPersonDetail',
  components: {
    ContactPersonCard: _ContactPersonCard.default
  },
  props: {
    dealId: {
      type: [Number, String],
      default: ''
    },
    formDesc: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      contactPersonList: [],
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    dealId: {
      handler(val) {
        if (!val) return;
        this.getDealContactList();
      },
      immediate: true
    }
  },
  methods: {
    async getDealContactList() {
      const res = await (0, _trade.getContactByTradeId)({
        trade_id: this.dealId,
        workspace_id: this.workspaceInfo.id
      });
      this.contactPersonList = [...res.results.main, ...res.results.other];
      this.total = res.results.total;
    },
    setDecisionMarker(val) {
      const updateData = {
        contact_id: val.id,
        deal_id: this.dealId,
        is_main: 1
      };
      this.updateDealContactDecision(updateData);
    },
    cancelSetDecisionMarker(val) {
      const updateData = {
        contact_id: val.id,
        deal_id: this.dealId,
        is_main: 0
      };
      this.updateDealContactDecision(updateData);
    },
    async updateDealContactDecision(data) {
      const res = await (0, _business.updateDealContactDecision)(data);
      if (res.code === 200) {
        this.$message.success('修改成功');
        this.getDealContactList();
      }
    }
  }
};
exports.default = _default;