"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.completeAnnotate = completeAnnotate;
exports.copyDataSet = copyDataSet;
exports.createAnnotate = createAnnotate;
exports.createDataSet = createDataSet;
exports.createDataSetFolder = createDataSetFolder;
exports.createDataSetLabel = createDataSetLabel;
exports.deleteAnnotate = deleteAnnotate;
exports.deleteDataSet = deleteDataSet;
exports.deleteDataSetLabel = deleteDataSetLabel;
exports.editDataSet = editDataSet;
exports.exportAnnotationResults = exportAnnotationResults;
exports.getAnnotatedIds = getAnnotatedIds;
exports.getAnnotationContext = getAnnotationContext;
exports.getAnnotatorsList = getAnnotatorsList;
exports.getDataSetDataInfo = getDataSetDataInfo;
exports.getDataSetDataList = getDataSetDataList;
exports.getDataSetInfo = getDataSetInfo;
exports.getDataSetLabels = getDataSetLabels;
exports.getDataSetList = getDataSetList;
exports.getEventListOfSentence = getEventListOfSentence;
exports.getFolderList = getFolderList;
exports.getSentenceOfEvent = getSentenceOfEvent;
exports.importData = importData;
exports.renameDataSetLabel = renameDataSetLabel;
exports.updateAnnotation = updateAnnotation;
var _request = require("@/utils/request");
// 获取数据集列表
function getDataSetList(data) {
  return (0, _request.webService)({
    url: '/event/dataset/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取数据集下文件夹
function getFolderList(params) {
  return (0, _request.webService)({
    url: '/event/dataset_folder/list',
    method: 'get',
    params
  });
}

// 创建数据集文件夹
function createDataSetFolder(data) {
  return (0, _request.webService)({
    url: '/event/dataset_folder/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 创建数据集
function createDataSet(data) {
  return (0, _request.webService)({
    url: '/event/dataset/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑数据集
function editDataSet(data) {
  return (0, _request.webService)({
    url: '/event/dataset/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 复制数据集
function copyDataSet(data) {
  return (0, _request.webService)({
    url: '/event/dataset/copy',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除数据集
function deleteDataSet(data) {
  return (0, _request.webService)({
    url: '/event/dataset/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导入数据
function importData(data) {
  return (0, _request.webService)({
    url: '/event/dataset/import',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取数据集标签
function getDataSetLabels(params) {
  return (0, _request.webService)({
    url: '/event/dataset_label/list',
    method: 'get',
    params
  });
}

// 获取数据集下所有会话数据
function getDataSetDataList(params) {
  return (0, _request.webService)({
    url: '/event/dataset_data/list',
    method: 'get',
    params
  });
}

// 获取数据集样本内容
function getDataSetDataInfo(params) {
  return (0, _request.webService)({
    url: '/event/dataset_data/get',
    method: 'get',
    params
  });
}

// 创建标注
function createAnnotate(data) {
  return (0, _request.webService)({
    url: '/event/annotation/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除标注
function deleteAnnotate(data) {
  return (0, _request.webService)({
    url: '/event/annotation/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 完成标注
function completeAnnotate(data) {
  return (0, _request.webService)({
    url: '/event/dataset_data/complete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 句子维度的关键事件列表
function getEventListOfSentence(params) {
  return (0, _request.webService)({
    url: '/event/annotation/all',
    method: 'get',
    params
  });
}

// 创建数据集label
function createDataSetLabel(data) {
  return (0, _request.webService)({
    url: '/event/dataset_label/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除数据集label
function deleteDataSetLabel(data) {
  return (0, _request.webService)({
    url: '/event/dataset_label/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 重命名数据集label
function renameDataSetLabel(data) {
  return (0, _request.webService)({
    url: '/event/dataset_label/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取标签下命中的句子
function getSentenceOfEvent(data) {
  return (0, _request.webService)({
    url: '/event/annotation/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取数据集基本信息
function getDataSetInfo(params) {
  return (0, _request.webService)({
    url: '/event/dataset/info',
    method: 'get',
    params
  });
}

// 获取当前数据集下所有标注人
function getAnnotatorsList(params) {
  return (0, _request.webService)({
    url: '/event/annotator/list',
    method: 'get',
    params
  });
}

// 获取当前数据集下所有标注的Ids
function getAnnotatedIds(data) {
  return (0, _request.webService)({
    url: '/event/annotation/ids',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 导出标注结果
function exportAnnotationResults(data) {
  return (0, _request.webService)({
    url: '/event/annotation/export',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取标注上下文
function getAnnotationContext(_ref) {
  let {
    annotation_id
  } = _ref;
  return (0, _request.webService)({
    url: '/event/annotation/context',
    method: 'get',
    params: {
      annotation_id
    }
  });
}

// 标注弹窗中更新标注
function updateAnnotation(data) {
  return (0, _request.webService)({
    url: '/event/annotation/update_one',
    method: 'post',
    dataType: 'json',
    data
  });
}