"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "message"
  }, [_c("ul", {
    staticClass: "left-tab"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("li", {
      key: item.value,
      staticClass: "tab-item",
      class: {
        "actve-tab": item.value === _vm.activeTabItem.value
      },
      on: {
        click: function ($event) {
          return _vm.tabClick(item, index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont",
      class: "icon-" + item.icon
    }), _c("span", {
      staticClass: "tab-title"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.count,
        expression: "item.count"
      }],
      staticClass: "badge"
    }, [_vm._v(" " + _vm._s(item.count > 99 ? "99+" : item.count) + " ")])]);
  }), 0), _c("div", {
    ref: "contentDom",
    staticClass: "right-content"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentView === "list",
      expression: "currentView === 'list'"
    }],
    staticClass: "list-container"
  }, [_c("div", {
    staticClass: "list-header"
  }, [_c("div", {
    staticClass: "content-tittle"
  }, [_vm._v(_vm._s(_vm.activeTabItem.name))]), _c("el-button", {
    staticClass: "all-read",
    attrs: {
      icon: "iconfont icon-finished"
    },
    on: {
      click: _vm.allRead
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.allRead")))])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTabItem.subTabList.length,
      expression: "activeTabItem.subTabList.length"
    }],
    staticClass: "content-classify-container"
  }, [_c("filter-tab", {
    ref: "filterTab",
    attrs: {
      list: _vm.activeTabItem.subTabList
    },
    on: {
      tabChange: _vm.subTabClick
    }
  })], 1), _c("ul", {
    staticClass: "card-list-container"
  }, [_c("NoData", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !_vm.msgList.length,
      expression: "!loading && !msgList.length"
    }],
    attrs: {
      size: "120"
    }
  }), _c("mg-infinite-scroll", {
    staticClass: "infinite-scroll-style",
    attrs: {
      disabled: _vm.currentView !== "list" || !_vm.hasMore,
      loading: _vm.loading,
      nomore: !_vm.hasMore && _vm.msgList.length
    },
    on: {
      loadMore: _vm.getMessageCardData
    }
  }, _vm._l(_vm.msgList, function (card) {
    return _c("li", {
      key: card.card_id,
      staticClass: "card-item"
    }, [_c("structuring-card-template", {
      attrs: {
        "template-info": card
      },
      on: {
        goBriefReportDetail: (id, type, sub_type) => _vm.goBriefReportDetail(id, type, sub_type, 0),
        updateUnreadCount: _vm.getUnreadCount
      }
    })], 1);
  }), 0)], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentView === "detail",
      expression: "currentView === 'detail'"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "detail-container"
  }, [_vm.briefReportDetailInfo ? _c("brief-report-detail", {
    attrs: {
      "detail-info": _vm.briefReportDetailInfo,
      type: _vm.detailType,
      "has-prev": _vm.detailHasPrev,
      "has-next": _vm.detailHasNext
    },
    on: {
      goBackList: _vm.goBackList,
      getDetailInfo: _vm.getReportDetailData
    }
  }) : _vm._e()], 1)]), _c("el-backtop", {
    attrs: {
      target: ".right-content",
      bottom: 112,
      "visibility-height": _vm.backTopHeight
    }
  }, [_c("div", {
    staticClass: "back-top"
  }, [_c("i", {
    staticClass: "iconfont icon-back-top"
  })])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;