"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LearnCard = _interopRequireDefault(require("@/views/coaching/courseEdit/components/LearnCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeList',
  components: {
    LearnCard: _LearnCard.default
  },
  props: {
    knowledgeScriptList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeNames: [],
      localKnowledgeScriptList: []
    };
  },
  watch: {
    knowledgeScriptList: {
      handler: function (val) {
        this.localKnowledgeScriptList = val;
      },
      immediate: true
    }
  },
  methods: {
    deleteKnowledge(item, index) {
      this.localKnowledgeScriptList.splice(index, 1);
      this.$store.commit('training/SET_KNOWLEDGE_SCRIPT_DELETE', item.knowledge_id);
    }
  }
};
exports.default = _default;