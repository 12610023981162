"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "selected-label",
    on: {
      click: _vm.goDetail
    }
  }, [_c("i", {
    class: `iconfont ${_vm.icon}`
  }), _c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.item.name))]), _vm.showClose ? _c("i", {
    staticClass: "iconfont icon-close",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("delete", _vm.index);
      }
    }
  }) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;