"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "department-members"
  }, [_vm.departmentMultiple.length || _vm.requestComplete && !_vm.userList.length ? _c("div", {
    staticClass: "member-btn"
  }, [_vm.departmentMultiple.length ? _c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.enableMembers
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.启用")) + " ")]) : _vm._e(), _vm.departmentMultiple.length ? _c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.disableMembers
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.禁用")) + " ")]) : _vm._e(), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.requestComplete && !_vm.userList.length,
      expression: "requestComplete && !userList.length"
    }],
    on: {
      click: _vm.openAddDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("management.从其他部门移入成员")) + " ")])], 1) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    ref: "department-user",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      fit: "",
      data: _vm.userList,
      height: _vm.tableHeight
    },
    on: {
      "row-click": _vm.goUserInformation,
      "selection-change": _vm.DepartmentSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.姓名"),
      prop: "name",
      "min-width": "110",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            placement: "top",
            effect: "dark",
            content: row.name
          }
        }, [_c("div", {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(row.name || "-") + " ")])])];
      }
    }])
  }), !_vm.isTwoFactor ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.登录账号"),
      "min-width": "230",
      prop: "tel"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "login-account"
        }, [row.login_tel ? _c("div", [_c("i", {
          staticClass: "iconfont icon-mobile-phone"
        }), _vm._v(_vm._s(row.login_tel) + " ")]) : _vm._e(), row.login_email ? _c("div", [_c("i", {
          staticClass: "iconfont icon-message"
        }), _vm._v(_vm._s(row.login_email) + " ")]) : _vm._e()])];
      }
    }], null, false, 908245773)
  }) : [_c("el-table-column", {
    attrs: {
      label: _vm.$t("management.登录账号"),
      "min-width": "230",
      prop: "login_account"
    }
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.验证邮箱"),
      "min-width": "230",
      prop: "verify_email"
    }
  })], _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.部门"),
      prop: "department",
      "class-name": "self-cell-wrap",
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("div", {
          staticClass: "depart-wrap cell-wrap"
        }, [!row.department ? _c("p", {
          staticClass: "unassigned"
        }, [_vm._v(" " + _vm._s(_vm.$t("management.未分配")) + " ")]) : _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            enterable: false,
            "open-delay": 400,
            effect: "dark",
            placement: "top"
          }
        }, [_c("div", {
          ref: "content",
          attrs: {
            slot: "content"
          },
          domProps: {
            innerHTML: _vm._s(_vm.getDepartmentNames(row.department))
          },
          slot: "content"
        }), _c("div", {
          staticClass: "departments"
        }, _vm._l(row.department, function (item) {
          return _c("p", {
            key: item.id,
            staticClass: "table-text"
          }, [_vm._v(" " + _vm._s(item.name)), _c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: item.is_main,
              expression: "item.is_main"
            }],
            staticClass: "main-depart"
          }, [_vm._v("（" + _vm._s(_vm.$t("management.主部门")) + "）")])]);
        }), 0)])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.空间"),
      prop: "workspace",
      "class-name": "self-cell-wrap",
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("div", {
          staticClass: "cell-wrap"
        }, [!row.workspaces.length ? _c("p", {
          staticClass: "unassigned"
        }, [_vm._v(" " + _vm._s(_vm.$t("management.未分配")) + " ")]) : _vm._e(), _c("div", {
          staticClass: "workspace"
        }, _vm._l(row.workspaces, function (item) {
          return _c("p", {
            key: item.id,
            staticClass: "table-text"
          }, [_c("i", {
            staticClass: "iconfont icon-workspace-3-fill",
            style: {
              color: item.icon_color,
              "margin-right": "4px"
            }
          }), _vm._v(" " + _vm._s(item.name) + " "), _c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: item.is_main,
              expression: "item.is_main"
            }],
            staticClass: "main-depart"
          }, [_vm._v("（" + _vm._s(_vm.$t("management.主空间")) + "）")])]);
        }), 0)])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.是否登录"),
      prop: "is_enable",
      "min-width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_c("div", {
          staticClass: "is-public",
          class: "is-" + _vm.setStatusClass("isEnable", row.is_enable)
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.judgeStatusClassName("isEnable", row.is_enable)
        }), _vm._v(" " + _vm._s(_vm.judgeActiveStatus("isEnable", row.is_enable)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.状态"),
      prop: "is_active",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("div", {
          staticClass: "is-public",
          class: "is-" + _vm.setStatusClass("isActive", row.is_active)
        }, [_c("i", {
          staticClass: "iconfont",
          class: _vm.judgeStatusClassName("isActive", row.is_active)
        }), _vm._v(" " + _vm._s(_vm.judgeActiveStatus("isActive", row.is_active)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.账号类型"),
      prop: "user_type",
      "min-width": "130",
      formatter: _vm.formatCellValue
    }
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.到期时间"),
      prop: "trial_expire_date",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("span", [_vm._v(_vm._s(row.trial_expire_date || "-"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("management.操作"),
      fixed: "right",
      "min-width": "260",
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        let {
          row
        } = _ref8;
        return [_c("mg-operate-column", {
          attrs: {
            list: _vm.getOperateList(row)
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand($event, row);
            }
          }
        })];
      }
    }])
  })], 2), _c("disable-member-dialog", {
    attrs: {
      "disable-member-dialog-visible": _vm.disableMemberDialogVisible,
      "department-multiple": _vm.departmentMultiple,
      "row-info": _vm.rowInfo
    },
    on: {
      closeDisableMemberDialog: _vm.closeDisableMemberDialog,
      fetchDepartmentData: _vm.fetchDepartmentData
    }
  }), _c("user-information-drawer", {
    attrs: {
      "edit-user-info": _vm.editUserInfo,
      "user-information-drawer-visible": _vm.userInformationDrawerVisible,
      "workspace-list": _vm.workspaceList
    },
    on: {
      "update:userInformationDrawerVisible": function ($event) {
        _vm.userInformationDrawerVisible = $event;
      },
      "update:user-information-drawer-visible": function ($event) {
        _vm.userInformationDrawerVisible = $event;
      },
      refresh: _vm.refresh
    }
  }), _c("data-transfer-drawer", {
    attrs: {
      visible: _vm.dataTransferDrawerVisible,
      "can-transfer-data": _vm.userCanTransferData,
      "transfer-type": _vm.isResign ? "resign" : "user",
      title: _vm.isResign ? _vm.$t("management.离职") : _vm.$t("management.转移成员数据"),
      "transfer-from-user-info": _vm.editUserInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.dataTransferDrawerVisible = $event;
      },
      refresh: _vm.handleDataTransferRefresh
    }
  }), _c("CheckCaptchaDialog", {
    attrs: {
      visible: _vm.checkCaptchaDialogVisible,
      "row-info": _vm.rowInfo,
      "login-captcha-obj": _vm.loginCaptchaObj
    },
    on: {
      "update:visible": function ($event) {
        _vm.checkCaptchaDialogVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;