"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormItemCard = _interopRequireDefault(require("@/views/conversationScoreRule/components/FormItemCard"));
var _ScoreItemSet = _interopRequireDefault(require("./ScoreItemSet"));
var _ScoreRuleDateFilter = _interopRequireDefault(require("@/components/ScoreRuleDateFilter"));
var _index = _interopRequireDefault(require("@/components/ScopeOfOpen/index.vue"));
var _AllFilterItems = _interopRequireDefault(require("@/views/trade/components/AllFilterItems"));
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _tradeScoreRule = require("@/api/tradeScoreRule");
var _trade = require("@/api/trade");
var _timezone = require("@/utils/timezone");
var _ScoreSummary = _interopRequireDefault(require("@/views/conversationScoreRule/components/ScoreSummary"));
var _SetOptionTradeDrawer = _interopRequireDefault(require("./SetOptionTradeDrawer"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RuleSettingForm',
  components: {
    FormItemCard: _FormItemCard.default,
    ScoreItemSet: _ScoreItemSet.default,
    ScopeOfOpen: _index.default,
    SetOptionTradeDrawer: _SetOptionTradeDrawer.default,
    ScoreRuleDateFilter: _ScoreRuleDateFilter.default,
    ScoreSummary: _ScoreSummary.default,
    AllFilterItems: _AllFilterItems.default
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    currentScoreId: {
      type: [Number, String, Symbol],
      default: ''
    }
  },
  data() {
    return {
      setOptionTradeDrawerVisible: false,
      isSaveValidate: false,
      dataScopeBackFill: {},
      backMemberObj: {},
      scopeError: false,
      // 后端返回允许添加的筛选项
      hasAllowOptionsArr: [],
      // 客户标签级联数据
      accountTagOptionsList: [],
      customFilterObject: [],
      filterObj: {},
      filterNumber: 0,
      // 筛选数量
      tradeListCount: 0,
      filterParams: {},
      countLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone', 'orgId']),
    localFormData: {
      get() {
        const v = this.value;
        const dataConfig = v.data_scope.config;
        if (typeof dataConfig === 'string') {
          v.data_scope.config = JSON.parse(dataConfig);
        }
        return v;
      },
      set(val) {
        let v = (0, _commonFunc.deepClone)(this.value); // 利用深拷贝原理使得修改prop值不会报错,因为prop是单向数据流，2.0版本上不允许在组件内部直接修改
        v = val;
        this.$emit('input', v);
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    localBackFillFilters() {
      if (JSON.stringify(this.dataScopeBackFill) !== '{}') {
        const localBackFillFilters = JSON.parse(JSON.stringify(this.dataScopeBackFill));
        (0, _timezone.convertUTCplus82CurrentZone)(localBackFillFilters);
        return localBackFillFilters;
      }
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          this.getTradeScoreOptions();
          this.getTradeFilterList();
        }
      },
      immediate: true
    },
    localFormData: {
      handler: function (val) {
        this.dataScopeBackFill = this.localFormData.data_scope.config;
        this.filterObj = this.dataScopeBackFill;
        if (this.filterParams.create_time_new) {
          this.filterParams = {
            create_time_new: this.filterParams.create_time_new,
            ...this.filterObj
          };
        } else {
          this.filterParams = this.filterObj;
        }
        this.getTradeListCount(this.filterParams);
      },
      immediate: true
    }
  },
  methods: {
    getTradeListCount: (0, _commonFunc.debounce)(async function (filter) {
      var _data$filter_params$c;
      if (Array.isArray(filter === null || filter === void 0 ? void 0 : filter.user_ids)) return;
      this.countLoading = true;
      const data = {
        filter_params: filter,
        time_zone: this.timezone,
        workspace_id: this.workspaceInfo.id,
        from_score_rule: true
      };
      if (!data.filter_params.create_time_new) {
        this.$set(data.filter_params, 'create_time_new', {
          type: 'after',
          range: [this.$moment().format('YYYY-MM-DD HH:mm:ss')]
        });
      }
      if ((_data$filter_params$c = data.filter_params.create_time_new) !== null && _data$filter_params$c !== void 0 && _data$filter_params$c.range.length) {
        data.filter_params.create_time_new.range = data.filter_params.create_time_new.range.map(item => this.$currentZoneToPlus8(item).format('YYYY-MM-DD HH:mm:ss'));
      }
      const res = await (0, _tradeScoreRule.getTradeListCount)(data).finally(() => {
        this.countLoading = false;
      });
      if (res.code === 20000) {
        this.tradeListCount = res.results.count;
        this.$emit('getTradeCount', this.tradeListCount);
      }
    }, 500),
    filterChange: (0, _commonFunc.debounce)(function (filters) {
      const allFilters = (0, _commonFunc.deepClone)(filters);
      this.filterObj = this.createViewData(allFilters);
      if (this.filterParams.create_time_new) {
        this.filterParams = {
          create_time_new: this.filterParams.create_time_new,
          ...this.filterObj
        };
      } else {
        this.filterParams = this.filterObj;
      }
      this.$emit('filterChange', this.filterParams);
      this.getTradeListCount(this.filterParams);
    }, 500),
    dateFilterChange(_ref) {
      let {
        range,
        type
      } = _ref;
      this.filterParams.create_time_new = {
        range,
        type
      };
      this.$emit('filterChange', this.filterParams);
      this.getTradeListCount(this.filterParams);
    },
    // 传递给后端的参数
    createViewData(filters) {
      const obj = {};
      let number = 0;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].params) {
          obj[filters[i].type] = filters[i].params;
          if (filters[i].type === 'user_ids') {
            if (filters[i].params && Array.isArray(filters[i].params)) {
              obj[filters[i].type] = this.handleUserValue(filters[i].params);
            }
          }
          if (filters[i].type === 'keyEventCascaderList') {
            const {
              event_engine_relation,
              keyEventCascaderList
            } = filters[i].params;
            if (keyEventCascaderList) {
              var _keyEventCascaderList, _keyEventCascaderList2;
              obj.keyEventCascaderList = keyEventCascaderList;
              obj.event_engine_relation = event_engine_relation;
              if (((_keyEventCascaderList = keyEventCascaderList[0]) === null || _keyEventCascaderList === void 0 ? void 0 : (_keyEventCascaderList2 = _keyEventCascaderList.keyEventName) === null || _keyEventCascaderList2 === void 0 ? void 0 : _keyEventCascaderList2.length) > 0) {
                number++;
              }
            }
          }
          if (Array.isArray(filters[i].params) && filters[i].params.length > 0) {
            number++;
          } else if (filters[i].params instanceof Object && this.isObjectKeyHasItem(filters[i].params) && filters[i].type !== 'keyEventCascaderList') {
            number++;
          } else if (typeof filters[i].params !== 'object' && filters[i].params) {
            number++;
          }
        }
      }
      this.filterNumber = number;
      return obj;
    },
    handleUserValue(initArray) {
      const depList = [];
      const userList = [];
      initArray.forEach(item => {
        if (item.is_department) {
          depList.push(Number(item.id.replace('tree_id_', '')));
        } else {
          userList.push(item.user_id);
        }
      });
      return {
        tree_ids: depList,
        user_ids: userList
      };
    },
    isObjectKeyHasItem(obj) {
      let flag = false;
      for (const key in obj) {
        if (obj[key] && obj[key].length) {
          flag = true;
        }
      }
      return flag;
    },
    // 获取自定义的筛选项
    async getTradeFilterList() {
      const res = await (0, _trade.getTradeFilterList)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        // 交易评分规则的更多筛选不显示交易创建时间和交易评分筛选器
        this.customFilterObject = res.results.filter_list.map(item => {
          if (item.name === '交易') {
            item.filters = item.filters.filter(f => f.field_name !== 'create_time' && f.type !== 'score_rule');
          }
          return item;
        });
      }
    },
    openTradePage() {
      this.setOptionTradeDrawerVisible = true;
    },
    async getTradeScoreOptions() {
      const res = await (0, _tradeScoreRule.getTradeScoreOptions)();
      if (res.code === 20000) {
        this.hasAllowOptionsArr = res.results.list;
        const hasAccountLabel = this.hasAllowOptionsArr.find(item => item.item_type === 'account_label');
        if (hasAccountLabel) {
          this.getAccountTagOptions();
        }
      }
    },
    async getAccountTagOptions() {
      const res = await (0, _tradeScoreRule.getAccountTagOptions)({
        workspace_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.accountTagOptionsList = res.results.tags;
        this.$store.commit('ruleSetting/SET_ACCOUNT_TAG_OPTIONS_LIST', this.accountTagOptionsList);
      }
    },
    getbackMemberObj() {
      const info = (0, _commonFunc.deepClone)(this.value);
      const members = Array.isArray(info.visible_range.visible_members) ? info.visible_range.visible_members : [];
      const obj = {
        isPublic: info.visible_range.is_public,
        'backfill-list': info.visible_range.is_public ? [] : members,
        'request-params': {
          type: JSON.stringify(['trade'])
        }
      };
      this.backMemberObj = obj;
    },
    handleOpenSetOptionTradeDrawer() {
      this.setOptionTradeDrawerVisible = true;
    },
    // 保存筛选范围方法
    saveOptionTradeFilters(filters) {
      delete filters.score_rules; // 过滤掉质检评分项的筛选项
      this.localFormData.data_scope.config = filters;
    },
    // 筛选范围发生变化
    handleScopeChange(visiableObj) {
      const {
        is_public
      } = visiableObj;
      this.localFormData.visible_range = visiableObj;
      if (is_public) {
        this.backMemberObj = {
          // 如果切换为对所有人可见充值回填值
          isPublic: true,
          'backfill-list': []
        };
      }
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            this.isSaveValidate = true;
            const configValid = this.checkConfigItems();
            const scopeValid = !this.scopeError;
            resolve(configValid && scopeValid);
          } else {
            this.$message.warning('请输入规则名称');
            resolve(false);
          }
        });
      });
    },
    checkConfigItems() {
      var _this$localFormData$s;
      const configs = (_this$localFormData$s = this.localFormData.score_item_config) === null || _this$localFormData$s === void 0 ? void 0 : _this$localFormData$s.score_items;
      if (configs.length) {
        var _this$$refs$scoreItem2;
        const inputRes = configs.every(item => {
          var _this$$refs$scoreItem;
          return (_this$$refs$scoreItem = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem === void 0 ? void 0 : _this$$refs$scoreItem.validateRuleItem(item);
        });
        const scoreRes = (_this$$refs$scoreItem2 = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem2 === void 0 ? void 0 : _this$$refs$scoreItem2.validateRuleScore();
        if (!scoreRes) {
          this.$message.warning('各评分项分值总和不等于评分规则满分，请调整评分项分值或评分规则满分');
        }
        return inputRes && scoreRes;
      } else {
        this.$message.warning('请至少添加一个评分项');
        this.isSaveValidate = false;
      }
    },
    resetValidate() {
      var _this$$refs$scoreItem3;
      this.isSaveValidate = false;
      this.$refs['ruleForm'].clearValidate();
      (_this$$refs$scoreItem3 = this.$refs.scoreItemSet) === null || _this$$refs$scoreItem3 === void 0 ? void 0 : _this$$refs$scoreItem3.resetScoreValid();
    },
    handleScopeError(val) {
      this.scopeError = val;
    }
  }
};
exports.default = _default;