"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("conversation-filter-item", {
    attrs: {
      label: "会话时间",
      "is-show-clear": !!_vm.filters.date && !!_vm.filters.date.range.length
    },
    on: {
      handleClear: _vm.clearDateChoose
    }
  }, [_c("div", {
    staticClass: "tag-group"
  }, _vm._l(_vm.convDateList, function (item) {
    return _c("span", {
      key: item.label,
      staticClass: "tag-group-item",
      class: {
        "active-item-tag": _vm.handleActiveDate(item.tag)
      },
      on: {
        click: function ($event) {
          return _vm.selectDate(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _c("el-tooltip", {
    staticClass: "date-tooltip",
    attrs: {
      effect: "dark",
      content: "会话时间",
      disabled: _vm.noTooltip,
      placement: "top"
    }
  }, [_c("mgv-date-time-picker", _vm._b({
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.filters.date.range,
      callback: function ($$v) {
        _vm.$set(_vm.filters.date, "range", $$v);
      },
      expression: "filters.date.range"
    }
  }, "mgv-date-time-picker", _vm.$attrs, false))], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;