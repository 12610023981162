"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorLog = require("./error-log");
class Tracking {
  constructor() {
    this._start = null;
  }
  start() {
    this._start = new Date().getTime();
  }
  end() {
    let lcpLimit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10000;
    if (!this._start) return;
    const page_path = location.pathname;
    setTimeout(() => {
      const end = new Date().getTime();
      const LCP = end - this._start;
      this._start = null;
      window.collectEvent('page_main_content_load_end', {
        page_path,
        LCP
      });
      if (LCP > lcpLimit) {
        (0, _errorLog.postErrorLog)('LCP超限', `页面: ${page_path}, LCP: ${LCP}`, 'warning');
      }
    }, 0);
  }
}
var _default = new Tracking();
exports.default = _default;