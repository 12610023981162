"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SavePromptDialog = _interopRequireDefault(require("./SavePromptDialog.vue"));
var _PromptTemplateDialog = _interopRequireDefault(require("./PromptTemplateDialog.vue"));
var _PromptExampleDialog = _interopRequireDefault(require("./PromptExampleDialog.vue"));
var _vue = require("vue");
var _useMessage = require("@/hooks/use-message");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'LlmPostProcessing',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    isEnableLLM: {
      type: Number,
      default: 0
    },
    promptContent: {
      type: String,
      default: ''
    }
  },
  emits: ['isEnableLLMChange'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const isEnableLLMChange = val => {
      emit('isEnableLLMChange', val);
    };
    const prompt = (0, _vue.ref)('');
    const promptInputRef = (0, _vue.ref)(null);
    const hasTextVariable = (0, _vue.computed)(() => {
      return prompt.value.includes('{text}');
    });
    (0, _vue.watchEffect)(() => {
      prompt.value = props.promptContent;
    });
    const insertText = async insertTxt => {
      // 获取el-input中的input元素
      const elInput = promptInputRef.value.$el.firstElementChild;
      // 获取el-input的值
      const txt = elInput.value;
      // 获取选区开始位置
      const startPos = elInput.selectionStart;
      // 获取选区结束位置
      const endPos = elInput.selectionEnd;
      if (startPos === undefined || endPos === undefined) return;
      // 将文本插入光标位置
      prompt.value = txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
      // 将光标移至文本末尾
      await (0, _vue.nextTick)();
      elInput.focus();
      elInput.selectionStart = startPos + insertTxt.length;
      elInput.selectionEnd = startPos + insertTxt.length;
    };
    const savePromptDialogVisible = (0, _vue.ref)(false);
    const savePromptAsTemplate = () => {
      savePromptDialogVisible.value = true;
    };
    const promptListDialogVisible = (0, _vue.ref)(false);
    const openPromptListDialog = () => {
      promptListDialogVisible.value = true;
    };
    const usePromptTemplate = val => {
      prompt.value = val;
      promptInputRef.value.$el.firstElementChild.focus();
    };
    const promptExampleDialogVisible = (0, _vue.ref)(false);
    const openPromptExampleDialog = () => {
      promptExampleDialogVisible.value = true;
    };
    const validPrompt = () => {
      const res = prompt.value.includes('{text}');
      if (!res) {
        _useMessage.useMessage.warning('没有插入变量');
      }
      console.log(prompt.value, 'prompt.value');
      console.log(res, 'res');
      return res;
    };
    const count = (0, _vue.ref)(0);
    const getPromptContent = () => {
      console.log(count.value++, 'count');
      if (props.isEnableLLM) {
        if (validPrompt()) {
          return prompt.value || false;
        } else {
          return false;
        }
      }
      return true;
    };
    expose({
      getPromptContent
    });
    return {
      __sfc: true,
      props,
      emit,
      isEnableLLMChange,
      prompt,
      promptInputRef,
      hasTextVariable,
      insertText,
      savePromptDialogVisible,
      savePromptAsTemplate,
      promptListDialogVisible,
      openPromptListDialog,
      usePromptTemplate,
      promptExampleDialogVisible,
      openPromptExampleDialog,
      validPrompt,
      count,
      getPromptContent,
      SavePromptDialog: _SavePromptDialog.default,
      PromptTemplateDialog: _PromptTemplateDialog.default,
      PromptExampleDialog: _PromptExampleDialog.default
    };
  }
};
exports.default = _default;