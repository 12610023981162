"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-filters"
  }, [_c("department-select", {
    attrs: {
      params: {
        type: JSON.stringify(["conversation"])
      }
    },
    model: {
      value: _vm.departmentId,
      callback: function ($$v) {
        _vm.departmentId = $$v;
      },
      expression: "departmentId"
    }
  }), _c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "请输入成员姓名",
      remote: "",
      "remote-method": _vm.getUserOptions,
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.userId,
      callback: function ($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  }, _vm._l(_vm.userOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;