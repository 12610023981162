"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.associateContact = associateContact;
exports.businessCreateMeeting = businessCreateMeeting;
exports.businessModifyMeeting = businessModifyMeeting;
exports.changeDealStage = changeDealStage;
exports.changeDealViewType = changeDealViewType;
exports.checkDateConfliction = checkDateConfliction;
exports.commentBusiness = commentBusiness;
exports.createBusiness = createBusiness;
exports.createDealFilterConfig = createDealFilterConfig;
exports.createDealView = createDealView;
exports.deleteDeal = deleteDeal;
exports.deleteDealContacts = deleteDealContacts;
exports.deleteDealView = deleteDealView;
exports.editBusiness = editBusiness;
exports.editDealView = editDealView;
exports.getAccountLabel = getAccountLabel;
exports.getAllComments = getAllComments;
exports.getAllocateFilterOptions = getAllocateFilterOptions;
exports.getAssociteContactList = getAssociteContactList;
exports.getAvailableAllocatorList = getAvailableAllocatorList;
exports.getBusiAtableUsers = getBusiAtableUsers;
exports.getBusiComments = getBusiComments;
exports.getBusiFilterVisiableUsers = getBusiFilterVisiableUsers;
exports.getBusiVisiableUsers = getBusiVisiableUsers;
exports.getBusinessList = getBusinessList;
exports.getBusinessSituation = getBusinessSituation;
exports.getBusinessStatusOptions = getBusinessStatusOptions;
exports.getConversationHostCode = getConversationHostCode;
exports.getDealAmount = getDealAmount;
exports.getDealCheck = getDealCheck;
exports.getDealContactList = getDealContactList;
exports.getDealContacts = getDealContacts;
exports.getDealCustomFilter = getDealCustomFilter;
exports.getDealDetail = getDealDetail;
exports.getDealFilterConfig = getDealFilterConfig;
exports.getDealFilterOptions = getDealFilterOptions;
exports.getDealFormInfo = getDealFormInfo;
exports.getDealKeyEvent = getDealKeyEvent;
exports.getDealLogRecords = getDealLogRecords;
exports.getDealRiskConfig = getDealRiskConfig;
exports.getDealTimeRangeFilterOptions = getDealTimeRangeFilterOptions;
exports.getDepartmentFilterOptions = getDepartmentFilterOptions;
exports.getDynamicForm = getDynamicForm;
exports.getFuzzySearchList = getFuzzySearchList;
exports.getLeadTransDealFormInfo = getLeadTransDealFormInfo;
exports.getOneBusiness = getOneBusiness;
exports.getStatusFilterOptions = getStatusFilterOptions;
exports.getViewHeadList = getViewHeadList;
exports.getViewSortInfo = getViewSortInfo;
exports.moveViewPosition = moveViewPosition;
exports.postCreateDeal = postCreateDeal;
exports.postDealCustomFilter = postDealCustomFilter;
exports.postViewVisited = postViewVisited;
exports.saveDealFilterOptions = saveDealFilterOptions;
exports.sendEmail = sendEmail;
exports.setDealRiskEnable = setDealRiskEnable;
exports.transFollowerNormal = transFollowerNormal;
exports.transFollowerSuper = transFollowerSuper;
exports.updateDealContactDecision = updateDealContactDecision;
exports.updateDealContacts = updateDealContacts;
exports.updateDealFilterConfig = updateDealFilterConfig;
exports.updateDealRiskConfig = updateDealRiskConfig;
exports.updateFullDeal = updateFullDeal;
var _request = require("@/utils/request");
// 获取商机列表
function getBusinessList(data, config) {
  return (0, _request.request)({
    url: '/deal/deal/list',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

// 获取商机列表跟进动态模块数据
function getBusinessSituation(data) {
  return (0, _request.request)({
    url: '/deal/deal/conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取模糊搜索关键词列表
function getFuzzySearchList(params) {
  return (0, _request.request)({
    url: '/deal/deal/search',
    method: 'get',
    params
  });
}

// 新建商机
function createBusiness(data) {
  return (0, _request.request)({
    url: '/deal/deal/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑商机
function editBusiness(data) {
  return (0, _request.request)({
    url: '/deal/deal/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取单个商机
function getOneBusiness(deal_id, workspace_id) {
  return (0, _request.request)({
    url: '/deal/deal/get_one',
    method: 'get',
    params: {
      deal_id,
      workspace_id
    }
  });
}

// 获取商机状态列表
function getBusinessStatusOptions(params) {
  return (0, _request.request)({
    url: '/deal/deal/get_status_options',
    method: 'get',
    params
  });
}
// 商机预约会议
function businessCreateMeeting(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_in_deal',
    method: 'post',
    data
  });
}
// 修改会议

function businessModifyMeeting(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/modify_in_deal',
    method: 'post',
    data
  });
}

// 发邮件
function sendEmail(conversation_id, email_body) {
  return (0, _request.request)({
    url: '/conversation/conversation/interview_send_mail',
    method: 'post',
    data: {
      conversation_id,
      email_body
    }
  });
}

/**
 *
 * 商机详情页
 */
// 获取商机详情
function getDealDetail(params) {
  return (0, _request.request)({
    url: '/deal/detail/info',
    method: 'get',
    params
  });
}

// 切换阶段
// export function changeDealStage(deal_id, stage, set_current_time=true) {
//   return request({
//     url: '/deal/detail/change_stage',
//     method: 'post',
//     data: {
//       deal_id,
//       stage,
//       set_current_time
//     }
//   })
// }
function changeDealStage(data) {
  return (0, _request.request)({
    url: '/deal/detail/change_stage',
    method: 'post',
    data
  });
}

// 判断是否有冲突日程
function checkDateConfliction(host_user_id, conversation_begin_time, conversation_end_time, conversation_id) {
  return (0, _request.request)({
    url: '/schedule/schedule/check_date_confliction',
    method: 'get',
    params: {
      host_user_id,
      conversation_begin_time,
      conversation_end_time,
      conversation_id
    }
  });
}

// 普通用户-移交商机
function transFollowerNormal(data) {
  return (0, _request.request)({
    url: '/deal/deal/transfer_follower/from_me_to_another',
    method: 'post',
    data
  });
}
// 管理员-移交商机
function transFollowerSuper(data) {
  return (0, _request.request)({
    url: '/deal/deal/transfer_follower/from_one_to_another',
    method: 'post',
    data
  });
}
// 获取初始选项状态（未使用）
// export function getVisibleFilters(){
//   return request({
//     url:'/deal/deal/get_visible_filters',
//     method:'get'
//   })
// }
// 获取可选部门选项
function getDepartmentFilterOptions() {
  return (0, _request.request)({
    url: '/deal/deal/get_department_filter_options',
    method: 'get'
  });
}
// 获取部门下的联系人选项
function getAllocateFilterOptions(params) {
  return (0, _request.request)({
    url: '/organization/organization/userlist',
    method: 'get',
    params
  });
}
// 获取成交时间选项
function getDealTimeRangeFilterOptions() {
  return (0, _request.request)({
    url: '/deal/deal/get_deal_time_range_filter_options',
    method: 'get'
  });
}
// 获取状态选项
function getStatusFilterOptions() {
  return (0, _request.request)({
    url: '/deal/deal/get_status_filter_options',
    method: 'get'
  });
}
// 获取“可选择的分配人”列表
function getAvailableAllocatorList() {
  return (0, _request.request)({
    url: '/deal/deal/get_available_allocator_list',
    method: 'get'
  });
}
function deleteDeal(data) {
  return (0, _request.request)({
    url: '/deal/deal/soft_delete',
    method: 'post',
    data
  });
}
// 列表请求code
function getConversationHostCode(params) {
  return (0, _request.request)({
    url: '/deal/deal/get_conversation_host_code',
    method: 'get',
    params
  });
}
// 获取动态表单配置
function getDynamicForm() {
  return (0, _request.request)({
    url: '/deal/deal/form',
    method: 'get'
  });
}
// 获取商机表单预填信息
function getDealFormInfo(params) {
  return (0, _request.request)({
    url: '/deal/deal/field/info',
    method: 'get',
    params
  });
}
// 获取商机决策人和联系人
function getDealContacts(params) {
  return (0, _request.request)({
    url: '/deal/deal/contact',
    method: 'get',
    params
  });
}
// 更新商机决策人或联系人的信息
function updateDealContacts(data) {
  return (0, _request.request)({
    url: '/deal/deal/contact',
    method: 'post',
    data
  });
}
// 删除联系人
function deleteDealContacts(data) {
  return (0, _request.request)({
    url: '/deal/deal/contact/delete',
    method: 'post',
    data
  });
}
// 线索转商机时预填信息获取
function getLeadTransDealFormInfo(params) {
  return (0, _request.request)({
    url: '/deal/deal/base/info',
    method: 'get',
    params
  });
}
// 更新全量商机
function updateFullDeal(data) {
  return (0, _request.request)({
    url: '/deal/deal/update/one',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取视图相关的排序信息
function getViewSortInfo(params) {
  return (0, _request.webService)({
    url: '/deal/filter_view/sort_conf',
    method: 'get',
    params
  });
}

// 获取商机列表头
function getViewHeadList(params) {
  return (0, _request.request)({
    url: '/deal/deal/head',
    method: 'get',
    params
  });
}

// 商机视图浏览上报
function postViewVisited(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/action',
    method: 'post',
    dataType: 'json',
    data
  });
}

/** 评论 */

// 评论接口
function commentBusiness(data) {
  return (0, _request.request)({
    url: '/deal/detail/comment/sender',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取评论
function getBusiComments(params) {
  return (0, _request.request)({
    url: '/deal/detail/comment/all',
    method: 'get',
    params
  });
}
// 获取所有评论
function getAllComments(params) {
  return (0, _request.request)({
    url: '/deal/deal/comments',
    method: 'get',
    params
  });
}
// 获取可@人员
function getBusiAtableUsers(params) {
  return (0, _request.webService)({
    url: '/deal/detail/visiable_list',
    method: 'get',
    params
  });
}
// 获取可见成员
function getBusiVisiableUsers(params) {
  return (0, _request.webService)({
    url: '/deal/detail/views',
    method: 'get',
    params
  });
}
// 搜索可见成员
function getBusiFilterVisiableUsers(params) {
  return (0, _request.request)({
    url: '/deal/detail/comment/views/search',
    method: 'get',
    params
  });
}

/** 自定义商机风险 */

// 获取自定义商机风险配置列表
function getDealRiskConfig() {
  return (0, _request.request)({
    url: '/deal/deal_risk/list',
    method: 'get'
  });
}

// 修改自定义商机风险配置
function updateDealRiskConfig(data) {
  return (0, _request.request)({
    url: '/deal/deal_risk/update_config',
    method: 'post',
    data
  });
}

// 设置启用/禁用
function setDealRiskEnable(data) {
  return (0, _request.request)({
    url: '/deal/deal_risk/set_enable_status',
    method: 'post',
    data
  });
}

// 获取商机筛选项
function getDealFilterOptions() {
  return (0, _request.request)({
    url: '/deal/deal/get_filter_options',
    method: 'get'
  });
}

// 修改商机筛选项
function saveDealFilterOptions(data) {
  return (0, _request.request)({
    url: '/deal/deal/save_filter_options',
    method: 'post',
    data,
    dataType: 'json'
  });
}

/** 视图（原看板） */

function getDealFilterConfig(params) {
  return (0, _request.webService)({
    url: '/deal/filter_view/list',
    method: 'get',
    params
  });
}
function createDealFilterConfig(data) {
  return (0, _request.request)({
    url: '/deal/deal_filter_config/create',
    method: 'post',
    data
  });
}
function updateDealFilterConfig(data) {
  return (0, _request.request)({
    url: '/deal/deal_filter_config/update',
    method: 'post',
    data
  });
}
// 获取商机金额和数量
function getDealAmount(params) {
  return (0, _request.webService)({
    url: '/deal/filter_view/list_stat',
    method: 'get',
    params
  });
}
// 新建视图
function createDealView(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/create',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 更改视图
function editDealView(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/update',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 更改视图类型
function changeDealViewType(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/change_type',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 删除视图
function deleteDealView(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/delete',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 移动看板位置
function moveViewPosition(data) {
  return (0, _request.webService)({
    url: '/deal/filter_view/move',
    method: 'post',
    data,
    dataType: 'json'
  });
}

// 获取商机自定义筛选项
function getDealCustomFilter(params) {
  return (0, _request.request)({
    url: '/deal/deal/search_list',
    method: 'get',
    params
  });
}

// 设置商机自定义筛选项
function postDealCustomFilter(data) {
  return (0, _request.request)({
    url: '/deal/deal/search_list',
    method: 'post',
    dataType: 'json',
    data
  });
}
// ===================================== 商机下联系人 ==============================================
// 商机下关联联系人
function associateContact(data) {
  return (0, _request.request)({
    url: '/deal/contact/deal_add_contact',
    method: 'post',
    data
  });
}

// 商机下联系人列表
function getDealContactList(params) {
  return (0, _request.request)({
    url: '/deal/deal/customize_contact',
    method: 'get',
    params
  });
}

// 更改商机决策人
function updateDealContactDecision(data) {
  return (0, _request.request)({
    url: '/deal/contact/deal_update_contact',
    method: 'post',
    data
  });
}
// 商机下可以关联联系人的列表
function getAssociteContactList(params) {
  return (0, _request.request)({
    url: '/account/account/deal_contact_list',
    method: 'get',
    params
  });
}

// 获取商机操作日志
function getDealLogRecords(id, page, size) {
  return (0, _request.request)({
    url: 'account/account_pool/deal_handle_log',
    method: 'get',
    params: {
      id,
      page,
      size
    }
  });
}

// 商机页新建商机
function postCreateDeal(data) {
  return (0, _request.request)({
    dataType: 'json',
    url: '/account/account/deal_create_deal',
    method: 'post',
    data
  });
}

// 商机列表关键事件
function getDealKeyEvent(params) {
  return (0, _request.request)({
    url: '/deal/deal/event_engine',
    method: 'get',
    params
  });
}

// 客户标签
function getAccountLabel(params) {
  return (0, _request.request)({
    url: '/deal/deal/account_label',
    method: 'get',
    params
  });
}

// 检查是否可以查看商机详情
function getDealCheck(deal_id, workspace_id) {
  return (0, _request.request)({
    url: '/deal/detail/check',
    method: 'get',
    params: {
      deal_id,
      workspace_id
    }
  });
}