"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
const complianceMethodMixin = {
  methods: {
    emitFilterChange(obj) {
      this.$emit('handleFiltersChange', obj);
    },
    changeFilter(filters) {
      this.emitFilterChange(Object.assign(this.filtersObj, filters));
    },
    sortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.filtersObj.sort_by = [[prop, order]];
      this.emitFilterChange(this.filtersObj);
    },
    sizeChange(size) {
      this.filtersObj.page = 1;
      this.filtersObj.size = size;
      this.emitFilterChange(this.filtersObj);
    },
    currentChange(page) {
      this.filtersObj.page = page;
      this.emitFilterChange(this.filtersObj);
    },
    goComplianceWorkSpace(rowInfo) {
      const obj = (0, _commonFunc.deepClone)(this.filtersObj);
      delete obj.page;
      delete obj.size;
      const params = {
        user_ids: {
          tree_ids: [],
          user_ids: [this.userId]
        },
        ...obj
      };
      const query = {
        id: rowInfo.conv_id || this.tableData[0].conv_id
      };
      this.$emit('goComplianceWorkSpace', {
        params,
        query
      });
    },
    exportComplianceData() {
      this.$emit('exportComplianceData');
    },
    handleDistribute() {
      this.$emit('handleDistribute');
    }
  }
};
var _default = complianceMethodMixin;
exports.default = _default;