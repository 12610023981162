"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["department-tree-container", {
      "selected-not-expandable": _vm.selectedNotExpandable
    }]
  }, [_c("mgv-virtual-scroll-tree", {
    ref: "department-tree",
    attrs: {
      data: _vm.orgTreeData,
      "node-key": "id",
      "show-checkbox": "",
      "check-strictly": _vm.selectedNotExpandable,
      "check-on-click-node": "",
      height: "250px",
      "empty-text": "",
      "expand-on-click-node": false,
      props: _vm.defaultProps,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "default-checked-keys": _vm.defaultChecked
    },
    on: {
      check: _vm.handleCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_vm._t("tree-custom-node", function () {
          return [_c("div", {
            class: `custom-node-${data.id}`
          }, [_c("i", {
            staticClass: "iconfont",
            class: data.path === "001" ? "icon-office-building" : "icon-department"
          }), _vm._v(" " + _vm._s(data.name) + " ")])];
        }, {
          nodeData: data
        })];
      }
    }], null, true)
  }), !_vm.orgTreeData.length ? _vm._t("tree-empty", function () {
    return [_c("span", [_vm._v("暂无数据")])];
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;