"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationFilter = _interopRequireDefault(require("@/views/conversationPage/conversationFilter.vue"));
var _WorkspaceBadge = _interopRequireDefault(require("@/components/WorkspaceBadge"));
var _ScoreItemConfig = _interopRequireDefault(require("@/views/tradeSet/tradeSetScore/components/ScoreItemConfig.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'RightDrawer',
  components: {
    ConversationFilter: _conversationFilter.default,
    WorkspaceBadge: _WorkspaceBadge.default,
    ScoreItemConfig: _ScoreItemConfig.default
  },
  provide() {
    return {
      isBaseConvInDrawer: true,
      isInQualityTesting: true
    };
  },
  props: {
    targetFormData: {
      type: Object,
      default: () => ({})
    },
    rightActiveName: {
      type: String,
      default: ''
    },
    scoreItem: {
      type: Object,
      default: () => ({})
    },
    scoreMode: {
      type: String,
      default: 'increase'
    }
  },
  data() {
    return {
      backFillFilters: {}
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    getTitle() {
      switch (this.rightActiveName) {
        case 'conversationFilter':
          return '配置筛选条件';
        case 'editScore':
          return '配置评分项';
      }
    }
  },
  watch: {
    targetFormData: {
      handler(val) {
        if (val.data_scope) {
          this.backFillFilters = JSON.parse(this.targetFormData.data_scope.config);
        }
      }
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer');
    }
  }
};
exports.default = _default;