"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      "custom-class": "knowledge-move-dialog",
      title: "移动到",
      width: "400px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("knowledge-catalog-position-select", {
    ref: "knowledge-catalog-position-select",
    attrs: {
      "current-node": _vm.currentNode
    },
    on: {
      positionSelect: _vm.handlePositionSelect
    }
  }), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    staticClass: "mg-roung-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-roung-button",
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;