"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "deal-operate-button"
  }, [_vm._l(_vm.operateButtons, function (item, index) {
    return [item.operateType === "createMeeting" && item.isShow ? _c("meeting-button", {
      key: index,
      attrs: {
        "custom-class": _vm.classObject(_vm.rowInfo, item)
      },
      on: {
        click: function ($event) {
          return _vm.clickOperateBtn(_vm.rowInfo, item.operateType, $event);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.text))])]) : _c("el-popover", {
      key: index,
      attrs: {
        placement: "bottom",
        width: "250",
        trigger: "manual",
        value: _vm.getIsShowPopover(_vm.rowInfo, item.operateType),
        "popper-class": "drop-call-pop"
      }
    }, [_vm.getIsShowPopover(_vm.rowInfo, item.operateType) ? _c("contact-person-list", {
      ref: "contactListcallout",
      refInFor: true,
      staticClass: "contact-list-comp",
      attrs: {
        "is-show-tel": ["callout", "doubleCall"].includes(item.operateType),
        "contact-list": _vm.contactList
      },
      on: {
        clickContactPersonItem: function ($event) {
          return _vm.clickContactPersonItem($event, _vm.rowInfo, item.operateType);
        }
      }
    }) : _vm._e(), _c("template", {
      slot: "reference"
    }, [_vm._t("clickBtn", function () {
      return [item.isShow ? _c("el-button", {
        directives: [{
          name: "preventReClick",
          rawName: "v-preventReClick"
        }],
        class: _vm.classObject(_vm.rowInfo, item),
        attrs: {
          slot: "reference",
          circle: item.isCircle,
          size: item.isCircle ? "small" : ""
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
            return _vm.clickOperateBtn(_vm.rowInfo, item.operateType);
          }
        },
        slot: "reference"
      }, [item.icon ? _c("svg-icon", {
        staticClass: "btn-icon",
        attrs: {
          "icon-class": item.icon
        }
      }) : _vm._e(), item.text ? _c("span", [_vm._v(_vm._s(item.text))]) : _vm._e()], 1) : _vm._e()];
    })], 2)], 2)];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;