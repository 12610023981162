"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "uploadMediaForm",
    staticClass: "upload-media-form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "80px",
      "label-position": "top"
    }
  }, [["clue", "business", "trade", "client"].includes(_vm.pageType) ? _c("el-form-item", {
    attrs: {
      label: _vm.setLabel(_vm.pageType),
      prop: _vm.setIdToString(_vm.pageType)
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      value: _vm.form.customer_name
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "会话归属人",
      prop: "host_salesman_id"
    }
  }, [_c("members-finder", {
    ref: "membersFinderRef",
    attrs: {
      "popper-class": "data-transfer-drawer-members-finder",
      placeholder: "搜索成员",
      "is-radio": true,
      "get-member-api-type": "data",
      "has-self": false,
      params: {
        type: JSON.stringify(["conversation"]),
        workspace_id: _vm.workspaceInfo.id
      }
    },
    on: {
      change: _vm.handleMemberChange
    }
  })], 1), _c("div", {
    staticClass: "date-flex"
  }, [_c("el-form-item", {
    staticClass: "conversation_date",
    attrs: {
      label: "会话时间",
      prop: "conversation_date"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "picker-options": _vm.datePickerOptions,
      type: "date",
      placeholder: "选择日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.conversation_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "conversation_date", $$v);
      },
      expression: "form.conversation_date"
    }
  })], 1), _c("el-form-item", {
    staticClass: "conversation_time",
    attrs: {
      prop: "conversation_time"
    }
  }, [_c("el-time-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "picker-options": _vm.timePickerOptions,
      "value-format": "HH:mm:ss",
      format: "HH:mm",
      placeholder: "选择时间"
    },
    model: {
      value: _vm.form.conversation_time,
      callback: function ($$v) {
        _vm.$set(_vm.form, "conversation_time", $$v);
      },
      expression: "form.conversation_time"
    }
  })], 1)], 1), ["conversation"].includes(_vm.pageType) ? _c("el-form-item", {
    attrs: {
      label: "关联联系人",
      prop: "independent_contact_id"
    }
  }, [_c("ConnectedContact", {
    on: {
      contactChange: _vm.handleIndependentContactChange
    }
  })], 1) : _vm._e(), ["conversation"].includes(_vm.pageType) ? _c("el-form-item", {
    attrs: {
      label: "关联交易",
      prop: "independent_trade_id"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "请先关联联系人",
      placement: "top",
      disabled: !!_vm.form.independent_contact_id
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "搜索交易名称",
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.searchTrade,
      disabled: !_vm.form.independent_contact_id
    },
    on: {
      focus: _vm.searchTrade
    },
    model: {
      value: _vm.form.independent_trade_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "independent_trade_id", $$v);
      },
      expression: "form.independent_trade_id"
    }
  }, _vm._l(_vm.independentTradeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1) : _vm._e(), ["business", "client", "trade"].includes(_vm.pageType) ? _c("el-form-item", {
    attrs: {
      label: "联系人",
      prop: "contact_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择联系人"
    },
    model: {
      value: _vm.form.contact_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_id", $$v);
      },
      expression: "form.contact_id"
    }
  }, _vm._l(_vm.contactList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e()], 1), _c("DynamicForm", {
    ref: "conversationDynamicFieldsRef",
    attrs: {
      desc: _vm.conversationDynamicFieldsDesc,
      "label-position": "top"
    },
    model: {
      value: _vm.conversationFieldForm,
      callback: function ($$v) {
        _vm.conversationFieldForm = $$v;
      },
      expression: "conversationFieldForm"
    }
  }), _c("span", {
    staticClass: "form-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading,
      disabled: !_vm.file.length || !_vm.uploadBtnDisabled
    },
    on: {
      click: function ($event) {
        return _vm.submitMediaForm("uploadMediaForm");
      }
    }
  }, [_vm._v("上 传 ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;