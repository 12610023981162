"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-content noRankSnippet"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/noContent/home-empty.png"),
      alt: "暂无内容"
    }
  }), _c("div", {
    staticClass: "tip"
  }, [_vm._v(_vm._s(_vm.title))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;