"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FirstStep = _interopRequireDefault(require("./FirstStep"));
var _SecondStep = _interopRequireDefault(require("./SecondStep"));
var _ThirdStep = _interopRequireDefault(require("./ThirdStep"));
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ExperimentDrawer',
  components: {
    FirstStep: _FirstStep.default,
    SecondStep: _SecondStep.default,
    ThirdStep: _ThirdStep.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isCopy: {
      type: Boolean,
      default: false
    },
    editInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      componentNames: ['FirstStep', 'SecondStep', 'ThirdStep'],
      activeStep: 0,
      submitLoading: false,
      params: {},
      editParams: {},
      loading: false,
      targetDescription: '',
      rangeDescription: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    drawerTitle() {
      return this.isEdit ? this.isCopy ? '复制' : '编辑' : '新建';
    },
    editDisabled() {
      // 编辑进行中的实验(status === 2)，只有名称、描述、结束时间可以修改
      return this.isEdit && this.editInfo.status === 2 && !this.isCopy;
    },
    isStepByStep() {
      // 三步走：复制 || 未开始的编辑 || 新建
      return this.isCopy || this.editInfo.status === 1 && this.isEdit || !this.isEdit && !this.isCopy;
    }
  },
  watch: {
    visible(val) {
      if (!val) return;
      this.params.source = this.params.source || 1;
      if (this.isEdit) {
        this.activeStep = 0;
        this.rangeDescription = '';
        this.targetDescription = '';
        this.getExperimentConfig();
      }
    }
  },
  created() {
    // 总览/团队概览创建 A/B 测试
    const {
      monitor_configs,
      source_info,
      source
    } = this.$route.params;
    if (monitor_configs) {
      monitor_configs.forEach(item => {
        item.configs.dimension = item.configs.draw_style;
      });
      setTimeout(() => {
        this.$emit('update:visible', true);
        this.targetDescription = '已填入所选指标';
        this.rangeDescription = source_info ? '已填入所选范围' : '';
        this.$nextTick(() => {
          // 创建 A/B 测试的来源 (1:A/B测试，2:总览，3: 团队概览)
          this.params.source = source || 1;
          // 总览页创建A/B测试，监测范围的实验组数据回填
          this.editParams = {
            monitor_configs,
            date_interval: 'day'
          };
          // 总览页创建A/B测试，监测范围的实验组数据回填
          if (source_info) {
            this.editParams.range_configs = {
              source_info
            };
          }
        });
      }, 500);
    }
  },
  methods: {
    async getExperimentConfig() {
      this.loading = true;
      const res = await (0, _businessIntelligence.getExperimentConfig)({
        id: this.editInfo.id,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        res.results.monitor_configs.forEach(item => {
          if (!item.configs.conversation_type) {
            item.configs.conversation_type = [];
          }
        });
        this.editParams = res.results;
        this.editParams.monitor_configs.map(item => {
          item.configs.draw_style = item.configs.dimension;
        });

        // 进行中的测试
        if (this.isCopy) {
          this.editParams.name = this.editParams.name + '的副本';
          this.editParams.start_at = '';
          this.editParams.end_at = '';
        }
      }
    },
    async handleStepchange(data) {
      // 三步走：复制 || 未开始的编辑
      if (!this.isStepByStep) {
        this.params = {
          ...this.editParams
        };
      }
      this.params = {
        ...this.params,
        ...data,
        workspace_id: this.workspaceInfo.id
      };
      if (this.activeStep === 2 || !this.isStepByStep) {
        this.submitLoading = true;
        const requestFunc = this.isCopy ? _businessIntelligence.createExperiment : this.isEdit ? _businessIntelligence.editExperiment : _businessIntelligence.createExperiment;
        if (this.isEdit && !this.isCopy) this.params.id = this.editInfo.id;
        this.params.workspace_id = this.workspaceInfo.id;
        (0, _timezone.datePickerWithTimeParse2Zone)(this.params, true);
        const res = await requestFunc(this.params).finally(() => {
          this.submitLoading = false;
        });
        if (res.code === 20000) {
          this.submitLoading = false;
          this.handleClose();
          this.$message({
            message: this.drawerTitle + '成功',
            type: 'success',
            center: true
          });
          this.$emit('refresh');
          this.activeStep === 0;
        }
      } else {
        this.activeStep++;
      }
    },
    nextStep() {
      this.$refs[this.componentNames[this.activeStep]][0].submit();
    },
    preStep() {
      if (!this.activeStep) return;
      this.activeStep--;
    },
    beforeClose() {
      // 从总览页进入A/B测试，进入详情后返回列表页，清空monitor_configs
      this.$route.params.monitor_configs = '';
      if (this.isEdit) {
        this.handleClose();
      } else {
        // 创建时点击空白地方收起但是不刷新已配置选项
        this.$emit('update:visible', false);
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.$route.params.monitor_configs = '';
      this.rangeDescription = '';
      this.targetDescription = '';
      this.editParams = {};
      // 不能删
      this.activeStep = 0;
    }
  }
};
exports.default = _default;