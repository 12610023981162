"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'MemberWithDepartmentInput',
  data() {
    return {
      userName: '',
      userOptions: [],
      loading: false
    };
  },
  methods: {
    async getOrgUserList(query) {
      if (!query) return;
      const res = await (0, _organization.getOrgUserList)({
        filter: query
      });
      this.userOptions = res.results.users;
    },
    handleChange(item) {
      this.$emit('select', item);
    }
  }
};
exports.default = _default;