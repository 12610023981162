"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceStatusFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceStatusFilter"));
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamConversationFilterGroup',
  components: {
    ComplianceStatusFilter: _ComplianceStatusFilter.default
  },
  data() {
    return {
      filtersGroupObj: {
        user_ids: {
          tree_ids: [],
          user_ids: []
        },
        conversation_time: [],
        status: 0,
        task_name: '',
        conversation_name: '',
        create_time: []
      }
    };
  },
  methods: {
    getUserList: _organization.getUserList,
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    changeStatusFilter(status) {
      this.filtersGroupObj.status = status;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    changeScoreFilter(score) {
      this.filtersGroupObj.score = score;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    departmentMultipleChange(ids) {
      this.filtersGroupObj.user_ids = ids;
      this.filtersGroupObj.page = 1;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    changeTimeFilter(time) {
      this.filtersGroupObj.create_time = time;
      this.$emit('changeFilter', this.filtersGroupObj);
    },
    changeTaskName: (0, _commonFunc.debounce)(function () {
      this.$emit('changeFilter', this.filtersGroupObj);
    }, 300),
    changeConvName: (0, _commonFunc.debounce)(function () {
      this.$emit('changeFilter', this.filtersGroupObj);
    }, 300),
    changeConversationTime(val) {
      this.filtersGroupObj.conversation_time = val || [];
      this.$emit('changeFilter', this.filtersGroupObj);
    }
  }
};
exports.default = _default;