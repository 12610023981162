"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-collapse", {
    staticClass: "practice-process-script-hint",
    model: {
      value: _setup.activeNames,
      callback: function ($$v) {
        _setup.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    staticClass: "hit-collapse-item",
    attrs: {
      name: "hit"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("header", {
          staticClass: "title-row"
        }, [_c("h1", {
          staticClass: "title-container"
        }, [_c("i", {
          staticClass: "iconfont icon-lamp"
        }), _c("span", {
          staticClass: "text"
        }, [_vm._v(_vm._s(_setup.t("coaching.scriptPrompt")))])])])];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;