"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/i18n/index.js");
class CourseType {
  constructor() {
    this._labelMap = new Map();
    this._labelMap.set(1, (0, _index.t)('coaching.scriptReading')).set(2, (0, _index.t)('coaching.scriptRecitation')).set(3, (0, _index.t)('coaching.scriptPractice')).set(6, (0, _index.t)('coaching.aICustomer')).set(7, (0, _index.t)('coaching.aICustomer'));
  }
  getLabel(type) {
    return this._labelMap.get(type);
  }
  getOptions() {
    const options = [];
    for (const [key, value] of this._labelMap) {
      if (key === 7) continue;
      options.push({
        value: key,
        label: value
      });
    }
    return options;
  }
}
var _default = new CourseType();
exports.default = _default;