"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _i18n = require("@/i18n");
var _default = {
  __name: 'PracticeProcessScriptHint',
  setup(__props) {
    const activeNames = (0, _vue.ref)(['hit']);
    return {
      __sfc: true,
      activeNames,
      t: _i18n.t
    };
  }
};
exports.default = _default;