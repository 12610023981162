"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCancelPublish = useCancelPublish;
var _modelManagement = require("@/api/modelManagement");
var _elementUi = require("element-ui");
function useCancelPublish() {
  const cancelPublish = async (_ref, cb) => {
    let {
      id,
      name
    } = _ref;
    _elementUi.MessageBox.confirm('模型取消发布后，关联的关键事件将恢复为禁用状态，是否确认取消发布？', `取消发布 - ${name}`, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await (0, _modelManagement.cancelPublishModel)({
        id
      });
      _elementUi.Message.success('已取消发布');
      cb();
    });
  };
  return {
    cancelPublish
  };
}