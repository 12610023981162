"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _routeMap = require("@/router/routeMap");
var _useRouter = require("@/hooks/use-router.js");
var _vue = require("vue");
var _organization = require("@/api/organization");
var _elementUi = require("element-ui");
var _default = {
  __name: 'AiConversationSync',
  setup(__props) {
    const router = (0, _useRouter.useRouter)();
    const goDepartmentAndMember = () => {
      const routeData = router.resolve({
        path: _routeMap.ROUTES.departmentManagement
      });
      window.open(routeData.href, '_blank');
    };
    const loading = (0, _vue.ref)(false);
    const syncConfig = (0, _vue.ref)(false);
    const getAiConversationConfig = async () => {
      loading.value = true;
      const res = await (0, _organization.getAiConversationSync)().finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        syncConfig.value = res.results.is_on;
      }
    };
    getAiConversationConfig();

    // 设置是否同步到会话
    const changeConfig = isOn => {
      if (isOn) {
        setAiConversationConfig(isOn);
      } else {
        _elementUi.MessageBox.confirm('关闭后，成员的练习录音不再自动上传至会话-培训空间', '关闭提示', {
          confirmButtonText: (0, _i18n.t)('coaching.confirm'),
          cancelButtonText: (0, _i18n.t)('coaching.cancel'),
          type: 'warning',
          confirmButtonClass: 'confirm-right'
        }).then(() => setAiConversationConfig(isOn)).catch(() => {});
      }
    };
    const setAiConversationConfig = async isOn => {
      loading.value = true;
      const res = await (0, _organization.setAiConversationSync)({
        is_on: isOn
      }).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        syncConfig.value = isOn;
        const message = isOn ? '已开启，练习录音将自动同步到会话' : '已关闭，练习录音将不再同步到会话';
        _elementUi.Message.success(message);
      }
    };
    return {
      __sfc: true,
      router,
      goDepartmentAndMember,
      loading,
      syncConfig,
      getAiConversationConfig,
      changeConfig,
      setAiConversationConfig
    };
  }
};
exports.default = _default;