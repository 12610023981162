"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", {
    ref: "department-select",
    attrs: {
      multiple: _vm.isShowTags,
      "collapse-tags": _vm.collapseTags,
      disabled: _vm.disabledSelect,
      placeholder: "请选择部门"
    },
    on: {
      "remove-tag": _vm.handleRemoveTag
    },
    model: {
      value: _vm.departmentLabels,
      callback: function ($$v) {
        _vm.departmentLabels = $$v;
      },
      expression: "departmentLabels"
    }
  }, [_c("el-option", {
    staticStyle: {
      padding: "0",
      height: "auto"
    },
    attrs: {
      value: 0,
      disabled: ""
    }
  }, [_c("div", {
    staticClass: "drop-wrapper"
  }, [_c("el-tree", {
    ref: "department-tree",
    attrs: {
      data: _vm.orgTree,
      "node-key": "id",
      "show-checkbox": "",
      props: _vm.defaultProps,
      "default-expand-all": _vm.defaultExpandAll,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "check-on-click-node": "",
      "default-checked-keys": _vm.defaultChecked,
      "check-strictly": _vm.switchStrictly
    },
    on: {
      check: _vm.handleCheckChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return [_c("div", {
          staticClass: "custom-node"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": data.path === "001" ? "office-building" : "department"
          }
        }), _vm._v(" " + _vm._s(_vm.getTreeNodeLabel(data)) + " ")], 1)];
      }
    }])
  }), !_vm.isHideStrictly ? _c("div", {
    staticClass: "switch-strictly-box"
  }, [_c("el-switch", {
    attrs: {
      disabled: _vm.isStricklyDisabled
    },
    on: {
      change: _vm.changeStrictly
    },
    model: {
      value: _vm.switchStrictly,
      callback: function ($$v) {
        _vm.switchStrictly = $$v;
      },
      expression: "switchStrictly"
    }
  }), _c("span", [_vm._v("仅选择部门直属成员数据")])], 1) : _vm._e()], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;