"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mg-link"
  }, [_vm.isEdit ? _c("div", {
    staticClass: "link-inner"
  }, [_c("el-input", _vm._b({
    attrs: {
      placeholder: _vm.placeholder ? _vm.placeholder : `请输入链接`
    },
    on: {
      input: _vm.handleInput,
      change: _vm.handleMainChange
    },
    model: {
      value: _vm.newValue.title,
      callback: function ($$v) {
        _vm.$set(_vm.newValue, "title", $$v);
      },
      expression: "newValue.title"
    }
  }, "el-input", _vm.$attrs, false)), _c("el-popover", {
    ref: "popover1",
    attrs: {
      slot: "suffix",
      placement: "bottom-end",
      width: "375",
      trigger: "click",
      "close-delay": 0,
      "popper-class": "mg-form-item-link-popper"
    },
    on: {
      show: _vm.onPopperShow
    },
    slot: "suffix",
    model: {
      value: _vm.isPoppershow,
      callback: function ($$v) {
        _vm.isPoppershow = $$v;
      },
      expression: "isPoppershow"
    }
  }, [_c("div", {
    staticClass: "link-widget"
  }, [_c("el-row", [_c("h1", [_vm._v("文本")]), _c("el-input", {
    attrs: {
      placeholder: "请输入文本"
    },
    on: {
      input: _vm.handleInput
    },
    model: {
      value: _vm.popperValue.title,
      callback: function ($$v) {
        _vm.$set(_vm.popperValue, "title", $$v);
      },
      expression: "popperValue.title"
    }
  })], 1), _c("el-row", [_c("h1", [_vm._v("超链接")]), _c("el-input", {
    attrs: {
      placeholder: "请输入链接"
    },
    on: {
      input: _vm.handleInput
    },
    model: {
      value: _vm.popperValue.link,
      callback: function ($$v) {
        _vm.$set(_vm.popperValue, "link", $$v);
      },
      expression: "popperValue.link"
    }
  })], 1), _c("el-row", {
    staticClass: "button-row"
  }, [_c("el-button", {
    on: {
      click: _vm.cancelEdit
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmEdit
    }
  }, [_vm._v("确定")])], 1)], 1), _c("div", {
    staticClass: "suffix-container",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("el-button", {
    staticClass: "link-suffix"
  }, [_c("i", {
    staticClass: "iconfont icon-link-2"
  })])], 1)])], 1) : _vm.newValue.link ? _c("div", {
    staticClass: "replace-item"
  }, [_c("LinkText", _vm._b({}, "LinkText", _vm.newValue, false))], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;