"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "risk-body"
  }, [_vm.currentRow.risk.length ? _vm._l(_vm.currentRow.risk, function (_ref, index) {
    let {
      name,
      describe,
      suggest
    } = _ref;
    return _c("div", {
      key: index,
      staticClass: "risk-item"
    }, [_vm._m(0, true), _c("div", {
      staticClass: "risk-right"
    }, [_c("div", {
      staticClass: "risk-name"
    }, [_vm._v(_vm._s(name))]), _c("div", {
      staticClass: "risk-condition"
    }, [_vm._v(_vm._s(describe))]), _c("div", {
      staticClass: "risk-advice"
    }, [_c("i", {
      staticClass: "iconfont icon-info-1"
    }), _vm._v(" " + _vm._s(suggest) + " ")])])]);
  }) : _c("div", {
    staticClass: "no-data"
  }, [_vm._v("暂无风险")])], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "risk-left"
  }, [_c("i", {
    staticClass: "iconfont icon-risk"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;