"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "play-voice",
    class: {
      "play-disabled": _vm.disabled
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: _vm.tooltipContent || _vm.playVoiceContent,
      placement: "bottom"
    }
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isPlay,
      expression: "!isPlay"
    }],
    class: ["iconfont", {
      "icon-volume": true
    }],
    on: {
      click: _vm.play
    }
  })]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "结束",
      placement: "bottom"
    }
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isPlay,
      expression: "isPlay"
    }],
    staticClass: "paly-voice-gif",
    attrs: {
      src: require("@/assets/palyVoice.gif")
    },
    on: {
      click: function ($event) {
        return _vm.$emit("pause");
      }
    }
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;