"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-library"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("catalog-part", {
    ref: "catalogPart",
    attrs: {
      "is-hidden-menu": _vm.isHiddenMenu,
      "catalog-data": _vm.catalogData,
      loading: _vm.catalogLoading,
      "current-node": _vm.currentNode
    },
    on: {
      mouseover: _vm.floatingMenuHidden,
      toggleMenu: _vm.toggleMenu,
      floatingMenuShow: _vm.floatingMenuShow,
      createKnowledge: _vm.createKnowledge,
      editKnowledge: _vm.editKnowledge,
      createRootCatalog: _vm.createRootCatalog,
      createCatalog: function ($event) {
        return _vm.initEditDialogData($event, "createCatalog");
      },
      renameCatalog: function ($event) {
        return _vm.initEditDialogData($event, "updateCatalog");
      },
      nodeClick: _vm.handleNodeClick,
      search: _vm.handleSearch,
      moveKnowledge: _vm.handleMoveKnowledge,
      refreshCatalogData: _vm.getCatalogData,
      moveSuccess: _vm.moveSuccess
    }
  })], 1), _vm.catalogData.length ? _c("div", {
    staticClass: "knowledge-content",
    on: {
      mouseover: _vm.floatingMenuHidden
    }
  }, [_c("knowledge-part", {
    ref: "knowledge-part",
    attrs: {
      "current-node": _vm.currentNode,
      "target-knowledge": _vm.targetKnowledge
    },
    on: {
      refresh: _vm.getCatalogData,
      moveKnowledge: _vm.moveSuccess,
      createKnowledge: _vm.createKnowledge,
      createCatalog: function ($event) {
        return _vm.initEditDialogData($event, "createCatalog");
      },
      editKnowledge: _vm.editKnowledge,
      recordingAudio: _vm.recordingAudio
    }
  })], 1) : _vm._e(), !_vm.catalogLoading && !_vm.catalogData.length ? _c("div", {
    staticClass: "empty-container"
  }, [_vm.knowledgePermission ? _c("empty-help-tip", {
    attrs: {
      "tip-info": _vm.tipInfo
    }
  }, [_c("el-button", {
    attrs: {
      slot: "operate-btn",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.createRootCatalog
    },
    slot: "operate-btn"
  }, [_vm._v(" 创建目录 ")])], 1) : _c("no-data", {
    attrs: {
      icon: "icon-a-Property1no-knowledge",
      tips: "知识库还在建设中..."
    }
  })], 1) : _vm._e(), _c("knowledge-move-dialog", {
    attrs: {
      visible: _vm.moveDialogVisible,
      "current-node": _vm.currentNode,
      "knowledge-id": _vm.targetKnowledge.id
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveDialogVisible = $event;
      },
      moveSuccess: _vm.moveSuccess
    }
  }), _c("EditNameDialog", {
    attrs: {
      visible: _vm.editNameDialogVisible,
      "edit-catalog-type": _vm.editCatalogType,
      label: _vm.editNameDialogLabel
    },
    on: {
      "update:visible": function ($event) {
        _vm.editNameDialogVisible = $event;
      },
      submit: _vm.handlerNameSuccess
    }
  }), _c("create-knowledge-drawer", {
    ref: "knowledgeDrawer",
    attrs: {
      visible: _vm.createKnowledgeDrawerVisible,
      "is-edit": _vm.knowledgeDrawerIsEdit,
      "knowledge-position": _vm.targetNode
    },
    on: {
      "update:visible": function ($event) {
        _vm.createKnowledgeDrawerVisible = $event;
      },
      createSuccess: _vm.handleKnowledgeSuccess
    }
  }), _c("RecordingAudioDrawer", {
    attrs: {
      visible: _vm.recordingAudioDrawerVisible,
      loading: _vm.getKnowledgeInfoLoading,
      "current-knowledge": _vm.currentKnowledgeInfo
    },
    on: {
      "update:visible": function ($event) {
        _vm.recordingAudioDrawerVisible = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;