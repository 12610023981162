"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'ModelSentenceItem',
  props: {
    modelSentence: {
      type: Array,
      default: () => []
    },
    exampleType: {
      type: Number,
      default: 1
    },
    entityTypeLabelObj: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['handleAIExpand', 'deleteModelSentenceItem', 'changeModelSentenceItem'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const sentenceDisabled = (0, _vue.computed)(() => {
      return props.modelSentence.every(item => item.content);
    });
    const entityTypeObj = (0, _vue.computed)(() => {
      var _props$entityTypeLabe;
      return ((_props$entityTypeLabe = props.entityTypeLabelObj) === null || _props$entityTypeLabe === void 0 ? void 0 : _props$entityTypeLabe[props.exampleType]) || {};
    });
    const changeModelSentenceItem = () => {
      emit('changeModelSentenceItem');
    };
    const handleAIExpand = () => {
      if (!sentenceDisabled.value) return;
      emit('handleAIExpand', props.modelSentence);
    };
    const deleteModelSentenceItem = () => {
      emit('deleteModelSentenceItem');
    };
    return {
      __sfc: true,
      props,
      sentenceDisabled,
      entityTypeObj,
      emit,
      changeModelSentenceItem,
      handleAIExpand,
      deleteModelSentenceItem
    };
  }
};
exports.default = _default;