"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("section", {
    staticClass: "conversation-list"
  }, _vm._l(_vm.convList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "conv-container"
    }, [_c("conv-item", {
      attrs: {
        item: item,
        "time-field": "date"
      }
    }), _c("el-button", {
      attrs: {
        plain: "",
        type: "primary",
        icon: "iconfont icon-coaching"
      },
      on: {
        click: function ($event) {
          return _vm.goDetail(item.id);
        }
      }
    }, [_vm._v("去辅导")])], 1);
  }), 0), _vm.convList.length ? _c("p", {
    staticClass: "number-limit"
  }, [_vm._v("最多显示 10 条")]) : _c("no-data-holder", {
    attrs: {
      text: "暂无最近的会话"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;