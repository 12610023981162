"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _eventsList = _interopRequireDefault(require("@/components/Detail/KeyEvent/eventsList"));
var _MgTag = _interopRequireDefault(require("@/components/MgTag"));
var _iconProcess = _interopRequireDefault(require("@/components/Calendar/iconProcess"));
var _routeMap = require("@/router/routeMap");
var _timeFormatter = require("@/utils/time-formatter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DailyDetailCard',
  components: {
    MgTag: _MgTag.default,
    iconProcess: _iconProcess.default,
    EventsList: _eventsList.default
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    eventEngineList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    statusText() {
      switch (this.detail.conversation_status) {
        case 'plan':
          return '未开始';
        case 'created':
          return '未开始';
        case 'recording':
          return '进行中';
        case 'expire':
          if (this.detail.conversation_type === 'video') {
            return '缺席';
          } else {
            return '未接通';
          }
        case 'attend':
        case 'recorded':
        case 'gotfiles':
        case 'completed':
          return '已结束';
        default:
          return '';
      }
    }
  },
  methods: {
    goTradeDetail() {
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.tradeDetail,
        query: {
          id: this.detail.trade_id
        }
      });
      window.open(router.href, '_blank');
    },
    openMeetingDetail() {
      const router = this.$router.resolve({
        path: _routeMap.ROUTES.conversationDetail,
        query: {
          id: this.detail.conversation_id
        }
      });
      window.open(router.href, '_blank');
    },
    getDuration(second) {
      return (0, _timeFormatter.secondToENTime)(second);
    },
    getIcon(type) {
      switch (type) {
        case 'audio':
          return 'phone';
        case 'video':
          return 'video-camera-solid-2';
        case 'doc':
          return 'im-fill';
        default:
          return 'phone';
      }
    }
  }
};
exports.default = _default;