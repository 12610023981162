"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "register"
  }, [_c("div", {
    staticClass: "reg-title"
  }, [_vm._v("重置密码")]), _c("el-form", {
    ref: "registerForm",
    staticClass: "register-form form",
    attrs: {
      model: _vm.registerForm,
      rules: _vm.registerRules,
      autocomplete: "off",
      "label-position": "top",
      "hide-required-asterisk": ""
    }
  }, [_vm.loginMethod === "tel" ? _c("el-form-item", {
    attrs: {
      prop: "tel",
      label: ""
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "tel",
      tabindex: "-1"
    }
  }), _c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: "请输入手机号",
      name: "tel",
      type: "tel",
      tabindex: "2",
      size: "large",
      oninput: "value=value.replace(/[^\\d]/g,''); if(value.length>11)value=value.slice(0,11)"
    },
    model: {
      value: _vm.registerForm.tel,
      callback: function ($$v) {
        _vm.$set(_vm.registerForm, "tel", $$v);
      },
      expression: "registerForm.tel"
    }
  })], 1) : _vm._e(), _vm.loginMethod === "email" ? _c("el-form-item", {
    attrs: {
      prop: "email",
      label: ""
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "tel",
      tabindex: "-1"
    }
  }), _c("el-input", {
    ref: "tel",
    attrs: {
      placeholder: "请输入邮箱号",
      size: "large"
    },
    model: {
      value: _vm.registerForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.registerForm, "email", $$v);
      },
      expression: "registerForm.email"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    staticClass: "send-code",
    attrs: {
      prop: "code",
      label: ""
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      tabindex: "-1"
    }
  }), _c("VerificationCodeInput", {
    ref: "codeInput",
    attrs: {
      "code-type": "reset_password",
      tel: _vm.registerForm.tel,
      email: _vm.registerForm.email,
      "sending-destination": _vm.loginMethod,
      "is-button-outside": true,
      "max-length": "6"
    },
    on: {
      sendCode: _vm.sendCode
    },
    model: {
      value: _vm.registerForm.code,
      callback: function ($$v) {
        _vm.$set(_vm.registerForm, "code", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "registerForm.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "password",
      label: ""
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "text",
      tabindex: "-1"
    }
  }), _c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "password",
      tabindex: "-1"
    }
  }), _c("password-input", {
    ref: "passwordInput",
    attrs: {
      placeholder: "请输入密码",
      tabindex: "4"
    },
    model: {
      value: _vm.registerForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.registerForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "registerForm.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "rePassword",
      label: ""
    }
  }, [_c("el-input", {
    staticStyle: {
      position: "fixed",
      bottom: "-9999px"
    },
    attrs: {
      type: "password",
      tabindex: "-1"
    }
  }), _c("el-input", {
    ref: "rePassword",
    attrs: {
      type: "text",
      placeholder: "请确认密码",
      name: "password",
      tabindex: "5",
      "show-password": ""
    },
    model: {
      value: _vm.registerForm.rePassword,
      callback: function ($$v) {
        _vm.$set(_vm.registerForm, "rePassword", $$v);
      },
      expression: "registerForm.rePassword"
    }
  })], 1), _c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick"
    }],
    staticClass: "handle-btn",
    attrs: {
      loading: _vm.resetLoading,
      type: "primary",
      "native-type": "submit"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_vm._v("重置")])], 1), _c("div", {
    staticClass: "tips"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.goLogin
    }
  }, [_vm._v("登录")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;