"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      size: "800px",
      visible: _vm.visible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("div", {
          staticClass: "drawer-title"
        }, [_c("span", [_vm._v("已移除记录 (" + _vm._s(_vm.total) + ")")]), _c("el-tooltip", {
          attrs: {
            tabindex: -1,
            content: "移除会话不会删除质检记录，已移除的会话不会在会话视图中显示，导出表格时不会导出已移除的会话。"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_vm.tableData && _vm.tableData.length ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    staticClass: "remove-conv-table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "header-cell-style": _vm.headerCellStyle,
      "cell-style": _vm.cellStyle,
      "default-sort": {
        prop: "compliance_remove_time",
        order: "descending"
      }
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, _vm._l(_vm.tableHeader, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        label: item.label,
        prop: item.prop,
        width: item.width,
        "show-overflow-tooltip": "",
        sortable: _vm.sortable.includes(item.prop) ? "custom" : false,
        "sort-orders": ["ascending", "descending"]
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [item.prop === "conv_name" ? [_c("div", {
            staticClass: "conv-name",
            on: {
              click: function ($event) {
                return _vm.goCompliancePersonal(row);
              }
            }
          }, [_c("i", {
            staticClass: "iconfont icon-phone-outline-fill"
          }), _c("el-link", {
            attrs: {
              type: "primary",
              underline: false
            }
          }, [_vm._v(_vm._s(row.conv_name))])], 1)] : item.prop === "compliance_user" ? [_c("div", {
            staticClass: "compliance-user"
          }, [_c("mgv-avatar-name", {
            attrs: {
              src: row.compliance_user.avatar,
              name: row.compliance_user.name,
              size: 28
            }
          }), _c("span", [_vm._v(_vm._s(row.compliance_user.name))])], 1)] : item.prop === "compliance_status" ? [_c("compliance-status-tag", {
            attrs: {
              status: row.compliance_status
            }
          })] : item.prop === "compliance_remove_time" ? [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.compliance_remove_time).format("YYYY-MM-DD HH:mm:ss")) + " ")] : item.prop === "operate" ? [_c("div", {
            staticClass: "operate-buttons"
          }, [_c("el-link", {
            attrs: {
              type: "primary",
              underline: false
            },
            on: {
              click: function ($event) {
                return _vm.ReStoreConv(row);
              }
            }
          }, [_vm._v("恢复")])], 1)] : [_vm._v(" " + _vm._s(row[item.prop]) + " ")]];
        }
      }], null, true)
    });
  }), 1) : _c("NoData", {
    staticClass: "no-data"
  }), _vm.tableData && _vm.tableData.length && _vm.total > _vm.requestParams.size ? _c("el-pagination", {
    staticClass: "pagination",
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-sizes": [10, 20, 30, 40],
      total: _vm.total,
      "current-page": _vm.requestParams.page,
      "page-size": _vm.requestParams.size
    },
    on: {
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;