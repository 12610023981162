"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _UploadFileList = _interopRequireDefault(require("./UploadFileList.vue"));
var _uploadMedia = require("./hooks/uploadMedia");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'UploadMedia',
  emits: ['canUpload'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const ACCEPT_FILE_TYPE = '.mp3, .wav, .m4a, .aiff, .aac, .ogg, .wma, .mp4, .m4v, .flac, .avi, .rmvb, .mkv, .asf, .wmv, .3gp, .flv, .mov, .amr';
    const fileList = (0, _vue.ref)([]);
    const uploadRef = (0, _vue.ref)();
    const addFile = () => {
      uploadRef.value.$refs['upload-inner'].handleClick();
    };
    const deleteFile = index => {
      fileList.value.splice(index, 1);
    };
    const {
      onChange
    } = (0, _uploadMedia.useBeforeUpload)(fileList);
    // useComputedFileDuration(fileList);

    const canUpload = val => {
      emit('canUpload', val);
    };
    expose({
      fileList
    });
    return {
      __sfc: true,
      ACCEPT_FILE_TYPE,
      fileList,
      uploadRef,
      addFile,
      deleteFile,
      onChange,
      emit,
      canUpload,
      UploadFileList: _UploadFileList.default
    };
  }
};
exports.default = _default;