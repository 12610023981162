"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoDetail = require("@/api/videoDetail");
var _vue = require("vue");
var _i18n = require("@/i18n");
var _elementUi = require("element-ui");
var _default = {
  __name: 'PicUploadButton',
  props: {
    picList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'comment'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:picList'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const tooltipContent = (0, _vue.computed)(() => {
      return props.disabled ? (0, _i18n.t)('coaching.最多上传x张图片', [3]) : (0, _i18n.t)('coaching.上传图片');
    });
    const emitChange = picList => {
      emit('update:picList', picList);
      emit('change', picList);
    };
    const beforeUpload = file => {
      if (props.picList.length > 2) {
        _elementUi.Message.warning((0, _i18n.t)('coaching.最多上传x张图片', [3]));
        return false;
      }
      const isSizeFit = file.size / 1024 / 1024 < 5;
      const isImg = /image\/(jpeg|jpg|png|gif)/.test(file.type);
      if (!isSizeFit) {
        _elementUi.Message.warning((0, _i18n.t)('coaching.图片大小不能超过x', ['5M']));
      }
      if (!isImg) {
        _elementUi.Message.warning((0, _i18n.t)('coaching.请上传图片格式的文件'));
      }
      return isSizeFit && isImg;
    };
    const uploadRequest = _ref2 => {
      let {
        file
      } = _ref2;
      return (0, _videoDetail.uploadCommentImg)({
        file,
        type: props.type
      });
    };
    const handleUploadSuccess = res => {
      const img = {
        ...res.results
      };
      if (props.picList.length > 2) return;
      const picList = [...props.picList, img.url];
      emitChange(picList);
    };
    const upload = async file => {
      if (!beforeUpload(file)) return;
      const res = await uploadRequest({
        file
      });
      handleUploadSuccess(res);
    };
    expose({
      upload
    });
    return {
      __sfc: true,
      props,
      tooltipContent,
      emit,
      emitChange,
      beforeUpload,
      uploadRequest,
      handleUploadSuccess,
      upload
    };
  }
};
exports.default = _default;