"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "mg-common-dialog",
    attrs: {
      visible: _vm.visible,
      title: `切换版本 - ${_vm.modelName}`,
      width: "400px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": _setup.updateVisible
    }
  }, [_c("div", {
    staticClass: "row"
  }, [_c("span", {
    staticClass: "row-label"
  }, [_vm._v("当前发布版本")]), _c("span", [_vm._v("V" + _vm._s(_vm.curVersion))])]), _c("div", {
    staticClass: "row"
  }, [_c("span", {
    staticClass: "row-label"
  }, [_vm._v("切换发布版本")]), _c(_setup.ModelVersionSelect, {
    attrs: {
      "model-id": _vm.modelId,
      "strict-version-disable": _vm.isAiModel,
      "select-last-version": "",
      "show-status": _vm.isAiModel
    },
    model: {
      value: _setup.version,
      callback: function ($$v) {
        _setup.version = $$v;
      },
      expression: "version"
    }
  })], 1), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        return _setup.updateVisible(false);
      }
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _setup.btnLoading
    },
    on: {
      click: _setup.changeVersion
    }
  }, [_vm._v("确认切换")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;