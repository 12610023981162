"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _vue = require("vue");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'KeyScriptReview',
  props: {
    sceneList: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const activeSceneName = (0, _vue.ref)([]);
    const activeKnowledgeName = (0, _vue.ref)([]);
    (0, _vue.watch)(() => props.sceneList, val => {
      if (val.length) {
        val.forEach(item => {
          var _item$conv_comment;
          !activeSceneName.value.includes(item.id) && activeSceneName.value.push(item.id);
          (_item$conv_comment = item.conv_comment) === null || _item$conv_comment === void 0 ? void 0 : _item$conv_comment.forEach((i, index) => {
            // 未触发的话术默认收起
            if (!i.not_triggered && !activeKnowledgeName.value.includes(`${item.id}-${index}`)) {
              activeKnowledgeName.value.push(`${item.id}-${index}`);
            }
          });
        });
      }
    }, {
      immediate: true,
      deep: true
    });
    return {
      __sfc: true,
      props,
      activeSceneName,
      activeKnowledgeName,
      t: _i18n.t,
      NoData: _NoData.default
    };
  }
};
exports.default = _default;