"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-select"
  }, [_c("el-select", {
    ref: "select",
    attrs: {
      "popper-class": "knowledge-library-knowledge-search-popper",
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.filterChange,
      placeholder: "搜索"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.currKnowledge,
      callback: function ($$v) {
        _vm.currKnowledge = $$v;
      },
      expression: "currKnowledge"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-search"
  })]), _c("mg-infinite-scroll", {
    attrs: {
      nomore: _vm.noMore,
      loading: _vm.loading,
      disabled: _vm.disabled
    },
    on: {
      loadMore: _vm.getSearchKnowledges
    }
  }, _vm._l(_vm.knowledgeOptions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("el-popover", {
      attrs: {
        "open-delay": 500,
        "popper-class": "knowledge-search-popover",
        placement: "right-start",
        trigger: "hover",
        disabled: _vm.popoverDisabled
      }
    }, [_c("knowledge-item", {
      attrs: {
        knowledge: item.detail,
        disabled: ""
      }
    }), _c("option-template", {
      attrs: {
        slot: "reference",
        item: item
      },
      slot: "reference"
    })], 1)], 1);
  }), 1)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;