"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-item"
  }, [_c("div", {
    staticClass: "head-container"
  }, [_c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "name-container"
  }, [_vm.showCheckbox ? _c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.$emit("knowledgeCheckedChange", $event, _vm.knowledge.id);
      }
    },
    model: {
      value: _vm.knowledge.checked,
      callback: function ($$v) {
        _vm.$set(_vm.knowledge, "checked", $$v);
      },
      expression: "knowledge.checked"
    }
  }) : _vm._e(), _c("el-tooltip", {
    directives: [{
      name: "mg-tooltip-auto-show",
      rawName: "v-mg-tooltip-auto-show"
    }],
    attrs: {
      placement: "top",
      effect: "dark",
      content: _vm.knowledge.name
    }
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.knowledge.name) + " ")])])], 1), !_vm.disabled ? [_vm.interfacePermission.discoveryTricks ? _c("el-button", {
    staticClass: "discovery-engine-btn",
    attrs: {
      size: "medium",
      type: "text gray-bg"
    },
    on: {
      click: _vm.openDiscoveryEngine
    }
  }, [_c("i", {
    staticClass: "iconfont icon-radar"
  }), _vm._v("话术挖掘引擎 ")]) : _vm._e()] : _vm._e(), !_vm.disabled ? _c("knowledge-operation", {
    on: {
      operate: _vm.operateKnowledge
    }
  }) : _vm._e()], 2)]), _vm.hasContent ? _c("div", {
    staticClass: "content"
  }, [_vm.knowledge.skill_list && _vm.knowledge.skill_list.filter(item => item).length ? _c("item-template", {
    attrs: {
      type: "answers"
    }
  }, [_c("knowledge-item-answers", {
    attrs: {
      list: _vm.knowledge.skill_list
    }
  })], 1) : _vm._e(), _vm.knowledge.scene_reply_list && _vm.knowledge.scene_reply_list.length ? _c("item-template", {
    attrs: {
      type: "tricks"
    },
    scopedSlots: _vm._u([!_vm.disabled ? {
      key: "suffix",
      fn: function () {
        return [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "标准话术指销售应该如何回答客户的提问",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "iconfont icon-info-1"
        })])];
      },
      proxy: true
    } : null], null, true)
  }, [_c("knowledge-item-tricks", {
    attrs: {
      list: _vm.knowledge.scene_reply_list,
      "show-checkbox": _vm.showCheckbox,
      disabled: _vm.disabled
    },
    on: {
      trickItemCheckedChange: function ($event) {
        return _vm.$emit("trickItemCheckedChange", _vm.knowledge.id);
      }
    }
  })], 1) : _vm._e()], 1) : _c("div", {
    staticClass: "no-content"
  }, [_vm._v("请添加知识点内容")]), _c("knowledge-move-dialog", {
    attrs: {
      visible: _vm.moveDialogVisible,
      "current-node": _vm.knowledge.catalog,
      "knowledge-id": _vm.knowledge.id
    },
    on: {
      "update:visible": function ($event) {
        _vm.moveDialogVisible = $event;
      },
      moveSuccess: _vm.handleMoveSuccess
    }
  }), _c("discovery-engine", {
    attrs: {
      visible: _vm.discoveryDialogVisible,
      "knowledge-id": _vm.knowledge.id
    },
    on: {
      "update:visible": function ($event) {
        _vm.discoveryDialogVisible = $event;
      },
      addTricks: _vm.handleAddTricks
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;