"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _seatCard = _interopRequireDefault(require("@/components/OneStopCall/seatCard"));
var _seatSetForm = _interopRequireDefault(require("@/components/OneStopCall/seatSetForm"));
var _index = _interopRequireDefault(require("@/components/OneStopCall/PhoneCall/index"));
var _afterCloseConv = _interopRequireDefault(require("@/components/OneStopCall/afterCloseConv/afterCloseConv"));
var _errorLog = require("@/utils/error-log");
var _call = require("@/api/call");
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'OneStopCall',
  components: {
    SeatCard: _seatCard.default,
    SeatSetForm: _seatSetForm.default,
    PhoneCall: _index.default,
    EditForm: _afterCloseConv.default
  },
  inject: ['reload'],
  props: {},
  data() {
    return {
      seatStatus: 'offLine',
      // 离线 offLine,空闲 free,小休 break，忙碌 busy
      agentStatus: '0',
      // 转换为七陌的坐席状态标识
      seatStatusColor: '#8E95B0',
      seatStatusIcon: 'icon-service',
      seatTipText: '登入坐席',
      answerType: 'sip',
      // 在线接听 sip, 手机接听 Local
      statusItems: [{
        name: '空闲',
        status: 'free',
        icon: 'icon-service',
        color: '#00B899',
        background: '#EBF9F7'
      }, {
        name: '小休',
        status: 'break',
        icon: 'icon-hot-water',
        color: '#F4AF00',
        background: '#FDF9EC'
      }, {
        name: '忙碌',
        status: 'busy',
        icon: 'icon-remove-outline',
        color: '#F43F7C',
        background: '#FEF0F3'
      }, {
        name: '离线',
        status: 'offLine',
        icon: 'icon-service',
        color: '#8E95B0',
        background: '#F6F6F9'
      }],
      openSeatCard: false,
      // 控制坐席卡片的显隐
      seatFormVisible: false,
      dialogType: 'set',
      isMove: false,
      moveTimer: null,
      // 判断是否拖动坐席元素的定时器
      callPage: '/edb/html/webmain.html',
      proxyUrl: 'https://pbx-sh-tx01.7moor.com',
      loginNoMoreAsk: false,
      logoutNoMoreAsk: false,
      seatForm: null,
      isLoginOK: false,
      showCallCard: false,
      // 是否显示外呼时的面板
      callDomFinish: false,
      // 初始化好了可以登录坐席了
      isSeatInited: false,
      // 坐席好了可以打电话了
      callStatus: '',
      // 电话状态
      callTipText: '',
      // 电话时提示词
      isRinging: false,
      // 是否开始响铃了
      isCalled: false,
      // 是否已接通电话
      seatHasDestroyed: false,
      // 坐席是否已被销毁
      editFormVisible: false,
      // 电话结束后的弹框
      isNewLead: false // 是否是新线索
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'orgName', 'callNoteVisible', 'callConverId', 'callLeadId', 'callDealId', 'has_outbound']),
    seatDialogTitle() {
      if (this.dialogType === 'login') {
        return '登入座席';
      } else if (this.dialogType === 'logout') {
        return '是否退出座席？';
      } else {
        return '座席设置';
      }
    },
    editDialogTitle() {
      if (this.callLeadId) {
        return '编辑线索';
      } else {
        return '编辑商机';
      }
    }
  },
  watch: {
    seatStatus(val, oldVal) {
      switch (val) {
        case 'offLine':
          this.seatStatusColor = '#8E95B0';
          this.seatStatusIcon = 'icon-service';
          this.seatTipText = '登入坐席';
          break;
        case 'free':
          this.agentStatus = '0';
          this.seatStatusColor = '#00B899';
          this.seatStatusIcon = 'icon-service';
          this.seatTipText = ' 空闲 ';
          this.sendIframeMsg({
            isBusy: false,
            busyType: '0'
          }, 'seatStatusChange', 'oneStopCallIframe');
          break;
        case 'break':
          this.agentStatus = '2';
          this.seatStatusColor = '#F4AF00';
          this.seatStatusIcon = 'icon-hot-water';
          this.seatTipText = ' 小休 ';
          this.sendIframeMsg({
            isBusy: true,
            busyType: '2'
          }, 'seatStatusChange', 'oneStopCallIframe');
          break;
        case 'busy':
          this.agentStatus = '1';
          this.seatStatusColor = '#F43F7C';
          this.seatStatusIcon = 'icon-remove-outline';
          this.seatTipText = ' 忙碌 ';
          this.sendIframeMsg({
            isBusy: true,
            busyType: '1'
          }, 'seatStatusChange', 'oneStopCallIframe');
          break;
        default:
          this.seatStatusColor = '#8E95B0';
          this.seatStatusIcon = 'icon-service';
          this.seatTipText = '登入坐席';
          break;
      }
      this.$nextTick(() => {
        if (val !== 'offLine') {
          this.$refs.seatCard.initStatusDuration();
        }
      });
    },
    seatFormVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.seatForm.getCallSeats();
          this.$refs.seatForm.getBindTels();
        });
      }
    },
    openSeatCard(val) {
      // 如果关闭坐席卡片时，清空输入手机号
      if (!val) {
        this.$refs.seatCard.inputTel = '';
      }
    },
    showCallCard(val) {
      // 如果弹出电话面板，则关闭坐席卡片
      if (val) {
        this.openSeatCard = false;
      }
    }
  },
  mounted() {
    window.seatInited = this.seatInited;
    window.frameFinishInit = this.frameFinishInit;
    window.sendPostCommandProxy = this.sendPostCommandProxy;
    window.clog = this.clog;
    window.loginSeat = this.loginSeat;
    window.seatOffLine = this.seatOffLine;
    this.$bus.$on('openPhoneCall', this.openPhoneCall);
    this.$bus.$on('closePhoneCall', this.closePhoneCall);
    window.addEventListener('beforeunload', this.beforeunloadHandler);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeunloadHandler);
    this.$bus.$off('openPhoneCall');
    this.$bus.$off('closePhoneCall');
  },
  methods: {
    isCallError() {
      if (this.callStatus === 'NO_DEVICE' || this.callStatus === 'NO_PERMISSION' || this.callStatus === 'NETWORK ERROR"') {
        this.showCallCard = false;
        this.isRinging = false;
        return true;
      } else {
        return false;
      }
    },
    getSeatSettings() {
      (0, _call.getSeatSettings)().then(res => {
        const {
          seat_id,
          default_tel,
          seat_login_type,
          seat_status,
          is_last_answer,
          is_login_default,
          is_logout_default
        } = res.results;
        this.seatForm = {
          seatNumber: seat_id,
          onlineStatus: seat_status,
          answerType: seat_login_type,
          bindTel: default_tel,
          offlinePhoneAnswer: is_last_answer,
          askNoMore: is_login_default,
          offlineBindTel: default_tel
        };
        this.loginNoMoreAsk = is_login_default;
        this.logoutNoMoreAsk = is_logout_default;
        this.answerType = seat_login_type;
        this.handleAfterGetSetting();
      });
    },
    sendIframeMsg(val, type, iframe_name) {
      window.frames[iframe_name].postMessage({
        type: type,
        params: val
      }, '*');
    },
    clog(e) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      (0, _errorLog.postErrorLog)('7m外呼:', e, type);
    },
    changeStatus(status) {
      this.seatStatus = status;
    },
    changeOffline() {
      this.dialogType = 'logout';
      this.seatFormVisible = true;
    },
    setMoveVal() {
      this.isMove = false;
      this.moveTimer = setTimeout(this.setDragTrue, 300);
    },
    setDragTrue() {
      this.isMove = true;
    },
    clearMoveTimer() {
      if (!this.isMove) {
        // 先把moveTimer清除，不然300毫秒后设置ismove true
        clearTimeout(this.moveTimer);
      }
    },
    handleClickSeatBtn() {
      if (this.isMove) {
        return false;
      }
      if (!this.isRinging || this.isCallError) {
        if (this.seatStatus === 'offLine') {
          // 如果是要登录坐席
          this.getSeatSettings();
        } else {
          this.openSeatCard = !this.openSeatCard;
        }
      } else {
        if (this.openSeatCard) {
          this.openSeatCard = false;
        }
        this.showCallCard = !this.showCallCard;
      }
    },
    handleAfterGetSetting() {
      if (this.loginNoMoreAsk) {
        // 如果登录不再询问直接调登录方法，
        this.formLogin(this.seatForm);
      } else {
        // 如果没有不再询问, 显示弹登录弹框
        this.dialogType = 'login';
        this.seatFormVisible = true;
      }
      this.openSeatCard = false;
    },
    openSeatSetDialog() {
      this.dialogType = 'set';
      this.seatFormVisible = true;
    },
    getProxyUrl(val) {
      this.proxyUrl = val;
    },
    async sendPostCommandProxy(data) {
      const res = await this.getSendPostComandResult(data);
      return res.results.data;
    },
    getSendPostComandResult(data) {
      return new Promise((resolve, reject) => {
        (0, _call.sendPostCommand)(data).then(res => {
          resolve(res);
        }).catch(() => {
          reject();
        });
      });
    },
    loginSeat() {
      const data = {
        seat_id: this.seatForm.seatNumber,
        is_login_default: this.seatForm.askNoMore,
        seat_status: this.seatForm.onlineStatus,
        seat_login_type: this.seatForm.answerType,
        default_tel: this.seatForm.bindTel
      };
      return new Promise((resolve, reject) => {
        this.isLoginOK = false;
        (0, _call.postLogin)(data).then(res => {
          this.seatStatus = this.seatForm.onlineStatus;
          this.isLoginOK = true;
          this.$message.success('坐席登录成功');
          resolve(res.results.data);
        }).catch(error => {
          if (this.loginNoMoreAsk) {
            this.dialogType = 'login';
            this.seatFormVisible = true;
          }
          reject(error);
        });
      });
    },
    formLogin(form) {
      this.seatForm = form;
      const data = {
        conversation_id: '',
        proxy: this.proxyUrl,
        seat_id: form.seatNumber,
        loginType: form.answerType,
        agentStatus: this.agentStatus,
        is_login_default: form.askNoMore,
        tel: form.bindTel
      };
      this.seatHasDestroyed = false;
      this.answerType = form.answerType;
      var timer1 = setInterval(() => {
        if (this.callDomFinish) {
          this.sendIframeMsg(data, 'login', 'oneStopCallIframe');
          clearInterval(timer1);
        }
      }, 500);
    },
    formLogout(form) {
      this.seatForm = form;
      const data = {
        seat_id: form.seatNumber,
        default_tel: form.bindTel,
        is_last_answer: form.offlinePhoneAnswer,
        is_logout_default: form.askNoMore
      };
      return new Promise((resolve, reject) => {
        (0, _call.postLogout)(data).then(() => {
          this.seatFormVisible = false;
          this.seatStatus = 'offLine';
          this.seatLogout();
          this.seatHasDestroyed = true;
          this.$refs.seatCard.initStatusDuration();
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    seatOffLine(msg) {
      this.$message.warning(msg);
      this.seatFormVisible = false;
      this.seatStatus = 'offLine';
      this.seatLogout();
      this.seatHasDestroyed = true;
    },
    async formSetUpdata(form) {
      this.formLogout(form).then(() => {
        this.formLogin(form);
      }).catch(err => {
        throw new Error(err);
      });
    },
    seatLogout() {
      setTimeout(() => {
        this.openSeatCard = false;
      }, 200);
    },
    // 外呼时面板相关方法
    callStatusChange(val, isError) {
      this.callStatus = val;
      if (this.callStatus === 'HANGUP') {
        this.isRinging = false;
        this.isCalled = false;
        this.showCallCard = false;
        // 挂断后的逻辑先别删除
        setTimeout(() => {
          if (this.callConverId && !isError) {
            // this.$store.commit("call/SET_NOTE_VISIBLE", false);
            // this.editFormVisible = true;
          }
        }, 500);
      } else if (this.callStatus === 'RINGING') {
        this.isRinging = true;
        this.callTipText = '呼叫中';
        this.showCallCard = true;
      } else if (this.callStatus === 'CALLING') {
        this.isRinging = true; // 兼容手机接听时，接通电话后的笔记出现时大按钮的状态同步
        this.isCalled = true;
        this.callTipText = '通话中';
        this.showCallCard = true;
      } else if (this.callStatus === 'INCOMINGCALLING') {
        this.isCalled = true;
        this.callTipText = '通话中';
        this.showCallCard = true;
      } else if (this.callStatus === 'INCOMINGRING') {
        this.callTipText = '有来电';
        this.showCallCard = true;
      } else {
        this.showCallCard = false;
      }
    },
    async openPhoneCall(val, type) {
      if (this.showCallCard) {
        this.$message.warning('当前有会话正在通话中');
        return;
      }
      if (this.seatStatus === 'offLine') {
        this.$message.warning('还未登录坐席');
        return;
      }
      if (type === 'multiple') {
        // this.showCallCard = true;
        this.$nextTick(() => {
          this.$refs['phone'].init(val);
        });
      } else {
        this.$confirm(`是否呼叫${val.contact_person ? val.contact_person : val.phone}？`, '提示', {
          confirmButtonText: '呼 叫',
          cancelButtonText: '取 消'
        }).then(() => {
          // this.showCallCard = true;
          this.$nextTick(() => {
            this.$refs['phone'].init(val);
          });
        }).catch(() => {});
      }
    },
    makeCall(tel) {
      const data = {
        phone: tel,
        needCreateLead: true
      };
      this.$refs['phone'].init(data);
    },
    closePhoneCall() {
      this.showCallCard = false;
    },
    frameFinishInit() {
      this.callDomFinish = true;
    },
    seatInited() {
      this.isSeatInited = true;
    },
    closeEditForm() {
      this.editFormVisible = false;
      this.reload();
    },
    beforeunloadHandler(e) {
      if (this.has_outbound && this.seatStatus !== 'offLine') {
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '坐席还未退出，您确定关闭页面吗？';
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return '坐席还未退出，您确定关闭页面吗？';
      }
    },
    checkIsNewLead(val) {
      this.isNewLead = val;
    }
  }
};
exports.default = _default;