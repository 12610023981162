"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-shortcuts",
    class: {
      "no-shortcuts": !_vm.shortcutsList.length
    }
  }, [_vm._l(_vm.shortcutsList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item",
      on: {
        click: function ($event) {
          return _vm.handeClick(item.value);
        }
      }
    }, [item.icon ? _c("i", {
      class: ["iconfont", item.icon],
      style: {
        color: item.color
      }
    }) : _vm._e(), _c("span", [_vm._v(_vm._s(item.label))])]);
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showStopReply,
      expression: "showStopReply"
    }],
    staticClass: "operate-btn",
    on: {
      click: function ($event) {
        return _vm.$emit("stopReply");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-call-zoom"
  }), _vm._v("停止回答 ")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAskAgain,
      expression: "showAskAgain"
    }],
    staticClass: "operate-btn",
    on: {
      click: function ($event) {
        return _vm.$emit("askAgain");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-refresh-right"
  }), _vm._v("重问一遍 ")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;