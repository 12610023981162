import { render, staticRenderFns } from "./ParentTaskTable.vue?vue&type=template&id=1004b806&scoped=true&"
import script from "./ParentTaskTable.vue?vue&type=script&setup=true&lang=js&"
export * from "./ParentTaskTable.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ParentTaskTable.vue?vue&type=style&index=0&id=1004b806&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1004b806",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1004b806')) {
      api.createRecord('1004b806', component.options)
    } else {
      api.reload('1004b806', component.options)
    }
    module.hot.accept("./ParentTaskTable.vue?vue&type=template&id=1004b806&scoped=true&", function () {
      api.rerender('1004b806', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coaching/coach/coachTask/coachParentTaskDetail/components/ParentTaskTable.vue"
export default component.exports