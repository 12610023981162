"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ComplianceScheduleFilter = _interopRequireDefault(require("@/views/compliance/components/ComplianceScheduleFilter"));
var _organization = require("@/api/organization");
var _commonFunc = require("@/utils/commonFunc");
var _user = require("@/api/user");
var _TaskStatusFilter = _interopRequireDefault(require("../../components/TaskStatusFilter.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TeamTaskFilterGroup',
  components: {
    ComplianceScheduleFilter: _ComplianceScheduleFilter.default,
    TaskStatusFilter: _TaskStatusFilter.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    isShowStatusSelector: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterObj: {
        task_name: '',
        create_time: [],
        percent: [],
        create_by: {
          tree_ids: [],
          user_ids: []
        },
        status: '',
        sort_by: [['create_at', 'descending']],
        page: 1,
        size: 20
      }
    };
  },
  watch: {
    filters: {
      handler: function (val, oldValue) {
        if (JSON.stringify(val) === JSON.stringify(oldValue)) return;
        this.filterObj = val;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    changeTaskName: (0, _commonFunc.debounce)(function () {
      this.changeFilter();
    }, 300),
    changeFilter() {
      this.$emit('changeFilter', this.filterObj);
    },
    changeScheduleFilter(val) {
      this.filterObj.percent = val;
      this.changeFilter();
    },
    distributorChange(ids) {
      this.filterObj.create_by = ids;
      this.changeFilter();
    },
    changeTaskStatusFilter(val) {
      this.filterObj.status = val;
      this.changeFilter();
    }
  }
};
exports.default = _default;