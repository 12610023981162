"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MgInput = _interopRequireDefault(require("./components/MgInput"));
var _MgRadio = _interopRequireDefault(require("./components/MgRadio"));
var _MgTextarea = _interopRequireDefault(require("./components/MgTextarea"));
var _MgFile = _interopRequireDefault(require("./components/MgFile"));
var _MgDate = _interopRequireDefault(require("./components/MgDate"));
var _MgDatetime = _interopRequireDefault(require("./components/MgDatetime"));
var _MgCheckbox = _interopRequireDefault(require("./components/MgCheckbox"));
var _MgSelect = _interopRequireDefault(require("./components/MgSelect.vue"));
var _MgTable = _interopRequireDefault(require("./components/MgTable.vue"));
var _MgTimeSelect = _interopRequireDefault(require("./components/MgTimeSelect"));
var _MgTimePicker = _interopRequireDefault(require("./components/MgTimePicker"));
var _MgLink = _interopRequireDefault(require("./components/MgLink"));
var _ImageTextTips = _interopRequireDefault(require("@/components/ImageTextTips.vue"));
var _validate = require("@/utils/validate");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    MgInput: _MgInput.default,
    MgRadio: _MgRadio.default,
    MgTextarea: _MgTextarea.default,
    MgFile: _MgFile.default,
    MgDate: _MgDate.default,
    MgDatetime: _MgDatetime.default,
    MgTimePicker: _MgTimePicker.default,
    MgCheckbox: _MgCheckbox.default,
    MgSelect: _MgSelect.default,
    MgTable: _MgTable.default,
    MgTimeSelect: _MgTimeSelect.default,
    MgLink: _MgLink.default,
    ImageTextTips: _ImageTextTips.default
  },
  props: {
    desc: {
      type: [Object, Array],
      require: true,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    labelPosition: {
      type: String,
      default: 'left'
    },
    labelWidth: {
      type: String,
      default: '120px'
    },
    isShowImageTips: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        ...this.value
      },
      // 避免引用
      rules: null,
      telKey: '',
      emailKey: ''
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.formData = {
          ...val
        };
      },
      deep: true
    },
    desc: {
      handler: function (val, oldVal) {
        if (val.length && JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.setRules();
        }
      },
      immediate: true
    },
    isEdit(val) {
      if (!val) {
        this.clearValidate();
      }
    }
  },
  methods: {
    isShow(item) {
      let flag = false;
      if (this.isEdit) flag = true;
      if (item) {
        if (Array.isArray(item)) {
          if (item.length) flag = true;
        } else {
          flag = true;
        }
      }
      return flag;
    },
    validate() {
      let flag = false;
      this.$refs.form.validate(valid => flag = valid);
      return flag;
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    clearValidate() {
      this.$refs.form.clearValidate();
    },
    validateField() {
      this.$refs.form.validateField(...arguments);
    },
    getComponentName(type) {
      return `mg-${type}`;
    },
    handleChange(key, value) {
      const checkboxTypeItem = this.desc.find(item => item.id == key).type == 'checkbox';
      if (checkboxTypeItem && value.length) {
        this.$refs.form.clearValidate();
      }
      this.$set(this.formData, key, value);
      this.$emit('keyChange', key, value);
      this.$emit('input', this.formData);
    },
    setRules() {
      if (this.rules) return;
      const obj = {};
      this.telKey = this.emailKey = '';
      for (const item of this.desc) {
        // 创建初始值
        const oldData = this.formData[item.id];
        if (oldData === undefined) {
          this.formData[item.id] = item.default || '';
        }
        const {
          type,
          preset
        } = item;
        // 如果是系统预设，才会重设rule
        if (type === 'tel' && preset) this.telKey = item.id;
        if (type === 'email' && preset) this.emailKey = item.id;
        obj[item.id] = this.handleRule(item);
      }
      if (this.telKey && this.emailKey) {
        obj[this.telKey] = [{
          validator: this.leadTelValidator,
          trigger: 'change'
        }];
        obj[this.emailKey] = [{
          validator: this.leadEmailValidator,
          trigger: 'change'
        }];
      }
      this.rules = obj;
      this.$emit('input', this.formData);
    },
    handleRule(_ref) {
      let {
        type,
        required,
        preset,
        label,
        rule,
        precisions
      } = _ref;
      if (rule) return rule;
      switch (type) {
        case 'tel':
          return [{
            validator: required || preset ? _validate.telValidator : _validate.telValidatorAllowNone,
            trigger: 'change'
          }];
        case 'email':
          return [{
            validator: required || preset ? _validate.emailValidator : _validate.emailValidatorAllowNone,
            trigger: 'change'
          }];
        case 'number':
        case 'floatnumber':
          return [{
            validator: required ? _validate.floatNumberValidator : _validate.floatNumberValidatorAllowNone,
            precisions: precisions,
            trigger: 'change'
          }];
        case 'select':
          return required ? [{
            validator: this.selectValidator,
            message: `${label}不能为空`,
            trigger: 'change'
          }] : null;
        case 'link':
          return required ? [{
            validator: _validate.linkValidator,
            trigger: ['blur', 'change']
          }] : [{
            validator: _validate.linkValidatorAllowNone,
            trigger: ['blur', 'change']
          }];
        default:
          return required ? [{
            required,
            message: `${label}不能为空`,
            trigger: 'change'
          }] : null;
      }
    },
    handleComp(type) {
      switch (type) {
        case 'date':
        case 'datetime':
        case 'textarea':
        case 'file':
        case 'select':
        case 'radio':
        case 'checkbox':
        case 'table':
        case 'time-select':
        case 'time-picker':
        case 'link':
          return `mg-${type}`;
        default:
          return 'mg-input';
      }
    },
    selectValidator(rule, value, callback) {
      if (!value) {
        callback(new Error());
      }
      if (Array.isArray(value)) {
        if (!value[0]) {
          callback(new Error());
        } else {
          callback();
        }
      }
    },
    leadTelValidator(rule, value, callback) {
      if (value === '' || value === undefined) {
        // 如果手机号为空，则需要判断邮箱是否为空
        if (this.formData[this.emailKey] === '' || this.formData[this.emailKey] === undefined) {
          callback(new Error('手机号和邮箱至少填一项'));
        } else {
          callback();
        }
      } else {
        if (/^1[3456789]\d{9}$/.test(value) == false) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      }
    },
    leadEmailValidator(rule, value, callback) {
      if (value === '' || value === undefined) {
        // 如果邮箱为空，则需要判断手机号是否为空
        if (this.formData[this.telKey] === '' || this.formData[this.telKey] === undefined) {
          callback(new Error('手机号和邮箱至少填一项'));
        } else {
          callback();
        }
      } else {
        const regEmail = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/;
        if (regEmail.test(value) == false) {
          callback(new Error('请输入正确的邮箱'));
        } else {
          callback();
        }
      }
    }
  }
};
exports.default = _default;