"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RuleEventFormItem = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/RuleEventFormItem.vue"));
var _TriggerRulesFormItem = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/TriggerRulesFormItem"));
var _RuleTest = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/RuleTest.vue"));
var _keyEventManagement = require("@/api/keyEventManagement");
var _modelManagement = require("@/api/modelManagement");
var _vue = require("vue");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CreateRuleModelDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submitSucceed'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => emit('update:visible', false);
    const ruleEventForm = (0, _vue.ref)({
      name: '',
      description: '',
      tracker_groups: [{
        key: '1',
        rule: [{
          apply_to: 3,
          include: [{
            type: 'keywords',
            value: []
          }],
          exclude: [{
            type: 'keywords',
            value: []
          }]
        }],
        context: []
      }]
    });
    const rules = {
      name: [{
        required: true,
        message: '请输入模型名称',
        trigger: 'change'
      }]
    };
    const ruleCount = (0, _vue.computed)(() => {
      return ruleEventForm.value.tracker_groups.reduce((prev, current) => {
        const ruleLength = current.rule && current.rule.length;
        const contextLength = current.context && current.context.length;
        return prev + ruleLength + contextLength;
      }, 0);
    });

    // 测试结果
    const testResult = (0, _vue.ref)({});
    const handleTestRuleEvent = async sentence => {
      ruleEventForm.value.tracker_groups.map((item, index) => item.key = String(index + 1));
      const res = await (0, _keyEventManagement.testRuleEvent)({
        test_sentences: sentence,
        tracker_groups: ruleEventForm.value.tracker_groups
      });
      if (res.code === 20000) {
        testResult.value = res.results;
      }
    };
    const loading = (0, _vue.ref)(false);
    const createModelAsync = async () => {
      loading.value = true;
      const params = {
        use_to: 1,
        model_type: 1,
        ...ruleEventForm.value
      };
      const res = await (0, _modelManagement.createModel)(params).finally(() => loading.value = false);
      if (res.code === 20000) {
        _elementUi.Message.success('创建成功');
        emit('submitSucceed', res.results.id);
        updateVisible();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      ruleEventForm,
      rules,
      ruleCount,
      testResult,
      handleTestRuleEvent,
      loading,
      createModelAsync,
      RuleEventFormItem: _RuleEventFormItem.default,
      TriggerRulesFormItem: _TriggerRulesFormItem.default,
      RuleTest: _RuleTest.default
    };
  }
};
exports.default = _default;