"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-detail"
  }, [_c("div", {
    staticClass: "item-top-wrapper unfold-height"
  }, [_vm.textArray ? _vm._l(_vm.textArray, function (item, index) {
    return _c("div", {
      key: index
    }, [item.items.length ? _c("div", {
      staticClass: "text-item-wrapper"
    }, [_c("div", {
      staticClass: "text-tag-wrapper",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.switchEventFold("text", item, index, item.keyword);
        }
      }
    }, [_c("span", {
      staticClass: "text-tag"
    }, [_c("span", {
      staticClass: "text-label"
    }, [_vm._v("文本：")]), _c("span", {
      staticClass: "text-name"
    }, [_vm._v(_vm._s(item.keyword))]), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.items.length > 3,
        expression: "item.items.length > 3"
      }],
      staticClass: "iconfont icon-caret-right",
      class: {
        "trans-rote": item.isTextUnfold
      }
    })])]), _c("div", {
      staticClass: "spread-line"
    }), _c("div", {
      staticClass: "text-content-wrapper"
    }, [_vm._l(item.items, function (detailItem, detailIndex) {
      return [_c("el-collapse-transition", {
        key: "text" + detailItem.order
      }, [_c("div", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.isShowAllContent(item.isTextUnfold, detailIndex, "text"),
          expression: "\n                    isShowAllContent(item.isTextUnfold, detailIndex, 'text')\n                  "
        }],
        staticClass: "detail-item text-item",
        on: {
          click: function ($event) {
            $event.stopPropagation();
            return _vm.goDetail(_vm.conversationId, detailItem.order, item.keyword);
          }
        }
      }, [_c("div", {
        staticClass: "detail-time",
        class: {
          "detail-time-stamp": _vm.conversationType === "doc",
          "customer-detail-time": detailItem.entity_type === "customer_contact"
        }
      }, [_vm._v(" " + _vm._s(_vm.timeFormat(_vm.conversationType, detailItem.begin_time)) + " ")]), _c("div", {
        staticClass: "detail-content"
      }, [_c("span", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.handleName(detailItem),
          expression: "handleName(detailItem)"
        }],
        staticClass: "user-name trans-text"
      }, [_vm._v("【" + _vm._s(_vm.handleName(detailItem)) + "】")]), _c("span", {
        staticClass: "trans-text",
        domProps: {
          innerHTML: _vm._s(detailItem.content)
        }
      })])])])];
    })], 2)]) : _vm._e()]);
  }) : _vm._e(), _vm.leafArray ? [_vm._l(_vm.leafArray, function (detailItem, detailIndex) {
    return [_c("div", {
      key: "leaf" + detailIndex,
      staticClass: "detail-item event-leaf-item"
    }, [_c("div", {
      staticClass: "event-button-wrapper",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.switchEventFold("event_leaf", detailItem, detailIndex);
        }
      }
    }, [_c("div", {
      staticClass: "event-button"
    }, [_c("span", {
      staticClass: "event-name"
    }, [_vm._v(_vm._s(detailItem.name))]), _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }), _c("span", {
      staticClass: "event-number"
    }, [_vm._v(_vm._s(detailItem.total))]), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: detailItem.data.length > 3,
        expression: "detailItem.data.length > 3"
      }],
      staticClass: "iconfont icon-caret-right",
      class: {
        "trans-rote": detailItem.isTextUnfold
      }
    })], 1)]), _c("div", {
      staticClass: "spread-line"
    }), _c("div", {
      staticClass: "event-content-wrapper"
    }, [_vm._l(detailItem.data, function (dataList, dataIndex) {
      return _vm._l(dataList, function (dataItem, index) {
        return _c("el-collapse-transition", {
          key: "leaf" + index
        }, [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isShowAllContent(detailItem.isTextUnfold, dataIndex, "event_leaf"),
            expression: "\n                    isShowAllContent(\n                      detailItem.isTextUnfold,\n                      dataIndex,\n                      'event_leaf'\n                    )\n                  "
          }],
          staticClass: "event-content-item",
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.goDetailKeyEvent(_vm.conversationId, detailItem.id, dataIndex, detailItem.style_type);
            }
          }
        }, [_c("div", {
          staticClass: "detail-time",
          class: {
            "detail-time-stamp": _vm.conversationType === "doc",
            "customer-detail-time": dataItem.entity_type === "customer_contact"
          }
        }, [_vm._v(" " + _vm._s(_vm.timeFormat(_vm.conversationType, dataItem.begin_time)) + " ")]), _c("div", {
          staticClass: "detail-content"
        }, [_c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.handleName(dataItem),
            expression: "handleName(dataItem)"
          }],
          staticClass: "user-name trans-text"
        }, [_vm._v("【" + _vm._s(_vm.handleName(dataItem)) + "】")]), _c("span", {
          staticClass: "trans-text"
        }, [_vm._l(_vm.handleContent(dataItem, detailItem.style_type), function (obj, index) {
          return [!obj.isKeyword ? _c("span", {
            key: index,
            attrs: {
              "data-sign": dataItem.order
            }
          }, [_vm._v(_vm._s(obj.word))]) : _c("span", {
            key: index,
            staticClass: "highlight"
          }, [_c("span", {
            domProps: {
              innerHTML: _vm._s(obj.word)
            }
          })])];
        })], 2)])])]);
      });
    })], 2)])];
  })] : _vm._e(), _vm.noneleafArray ? [_vm._l(_vm.noneleafArray, function (detailItem, detailIndex) {
    return [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isUnfold && detailIndex < 3 || _vm.isUnfold,
        expression: "(!isUnfold && detailIndex < 3) || isUnfold"
      }],
      key: "none_leaf" + detailItem.id,
      staticClass: "detail-item event-leaf-item non-leaf"
    }, [_c("div", {
      staticClass: "event-button-wrapper",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.switchEventFold("event_nonleaf", detailItem, detailIndex);
        }
      }
    }, [_c("div", {
      staticClass: "event-button"
    }, [_c("span", {
      staticClass: "event-name"
    }, [_vm._v(_vm._s(detailItem.name))]), _c("el-divider", {
      attrs: {
        direction: "vertical"
      }
    }), _c("span", {
      staticClass: "event-number"
    }, [_vm._v(_vm._s(detailItem.total))]), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: detailItem.data.length > 1,
        expression: "detailItem.data.length > 1"
      }],
      staticClass: "iconfont icon-caret-right",
      class: {
        "trans-rote": detailItem.isTextUnfold
      }
    })], 1)]), _c("div", {
      staticClass: "spread-line"
    }), _c("div", {
      staticClass: "event-content-wrapper"
    }, [_vm._l(detailItem.data, function (dataList, dataIndex) {
      return _vm._l(dataList, function (dataItem, index) {
        return _c("el-collapse-transition", {
          key: "none_leaf" + index
        }, [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isShowAllContent(detailItem.isTextUnfold, dataIndex, "event_nonleaf"),
            expression: "\n                    isShowAllContent(\n                      detailItem.isTextUnfold,\n                      dataIndex,\n                      'event_nonleaf'\n                    )\n                  "
          }],
          staticClass: "event-content-item",
          on: {
            click: function ($event) {
              $event.stopPropagation();
              return _vm.goDetailKeyEvent(_vm.conversationId, detailItem.id, dataIndex);
            }
          }
        }, [_c("div", {
          staticClass: "detail-time",
          class: {
            "detail-time-stamp": _vm.conversationType === "doc",
            "customer-detail-time": dataItem.entity_type === "customer_contact"
          }
        }, [_vm._v(" " + _vm._s(_vm.timeFormat(_vm.conversationType, dataItem.begin_time)) + " ")]), _c("div", {
          staticClass: "detail-content"
        }, [_c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.handleName(dataItem),
            expression: "handleName(dataItem)"
          }],
          staticClass: "user-name trans-text"
        }, [_vm._v("【" + _vm._s(_vm.handleName(dataItem)) + "】")]), _c("span", {
          staticClass: "trans-text"
        }, [_vm._l(_vm.handleContent(dataItem, detailItem.style_type), function (obj, index) {
          return [!obj.isKeyword ? _c("span", {
            key: index,
            attrs: {
              "data-sign": dataItem.order
            }
          }, [_vm._v(_vm._s(obj.word))]) : _c("span", {
            key: index,
            staticClass: "highlight"
          }, [_c("span", {
            domProps: {
              innerHTML: _vm._s(obj.word)
            }
          })])];
        })], 2)])])]);
      });
    })], 2)])];
  })] : _vm._e()], 2), _c("div", {
    staticClass: "detail-bottom",
    style: {
      "border-top": _vm.isUnfold ? "1px solid #E4E7ED" : "none"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.markLineList.length > _vm.maxShowNumber || _vm.isbothShowUnfold,
      expression: "markLineList.length > maxShowNumber || isbothShowUnfold"
    }],
    staticClass: "fold-and-unfold"
  }, [_vm.isUnfold ? _c("div", {
    staticClass: "fold",
    on: {
      click: function ($event) {
        return _vm.foldOrUnfoldItems("fold");
      }
    }
  }, [_vm._v(" 收起"), _c("i", {
    staticClass: "el-icon-arrow-up"
  })]) : _c("div", {
    staticClass: "unfold",
    on: {
      click: function ($event) {
        return _vm.foldOrUnfoldItems("unfold");
      }
    }
  }, [_vm._v(" 查看所有" + _vm._s(_vm.markLineList.length) + "条"), _c("i", {
    staticClass: "el-icon-arrow-down"
  })])]), _c("div", {
    staticClass: "audio-percent-wrapper"
  }, [_c("mark-line", {
    attrs: {
      items: _vm.markLineList
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;