"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
var _DetailNavbar = _interopRequireDefault(require("@/layout/components/DetailNavbar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserInfo',
  components: {
    Navbar: _DetailNavbar.default
  },
  data() {
    return {
      personalInfo: {
        avatar: '',
        email: '',
        loginAccount: '',
        name: ''
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['routesTree', 'avatar', 'name', 'email', 'login_account']),
    activeTab: {
      get() {
        return this.$route.path.split('/').pop();
      },
      set() {}
    },
    tabArray() {
      var _this$recursionGet;
      return (_this$recursionGet = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: 'UserInfoIndex',
        targetAttr: 'name',
        getAttr: 'children'
      })) === null || _this$recursionGet === void 0 ? void 0 : _this$recursionGet.sort((a, b) => a.meta.order - b.meta.order).filter(item => this.$config.isMegaview ? item : item.name !== 'AuthApp');
    }
  },
  created() {
    this.personalInfo.avatar = this.avatar;
    this.personalInfo.name = this.name;
    this.personalInfo.email = this.email;
    this.personalInfo.loginAccount = this.login_account;
  },
  methods: {
    recursionGet: _commonFunc.recursionGet,
    handleClick(_ref) {
      let {
        name
      } = _ref;
      this.$router.replace({
        path: `${_routeMap.ROUTES.personalCenter}/${name}`
      });
    }
    // handlePersonalInfo(personalInfo) {
    //   this.personalInfo = personalInfo;
    // }
  }
};

exports.default = _default;