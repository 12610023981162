"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("login-layout", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          isTwoFactor
        } = _ref;
        return [isTwoFactor ? _c("two-factor-login") : _c("login-comp")];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;