"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'OptionTemplate',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isMultiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)(['checkedKnowledgeScript']),
    path() {
      const list = this.item.path;
      if (!list) return '';
      return list.length > 1 ? list.join(' > ') : list[0];
    },
    checked() {
      return this.checkedKnowledgeScript.map(_ref => {
        let {
          knowledge_id
        } = _ref;
        return knowledge_id;
      }).includes(this.item.id);
    }
  },
  methods: {
    getSearchText() {
      const {
        highlight_name,
        name
      } = this.item;
      return highlight_name || name;
    },
    handleCheck() {
      this.$emit('checkKnowledge', this.item, this.checked);
    }
  }
};
exports.default = _default;