"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _coachingCenter = require("@/api/coachingCenter");
var _default = {
  name: 'TaskTableFilter',
  props: {
    type: {
      type: String,
      default: 'sales'
    }
  },
  data() {
    return {
      filters: {
        name: '',
        creator_id: '',
        space_id: '',
        status: '',
        course_type: '',
        user_ids: [],
        tree_ids: [],
        category_id: ''
      },
      memberOptions: [],
      searchLoading: false,
      memberBackfillList: [],
      taskCategoryOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['api_permissions']),
    statusOptions() {
      const commonOptions = [{
        value: 0,
        label: this.$t('coaching.inProgress')
      }, {
        value: 3,
        label: this.$t('coaching.completed')
      }, {
        value: 1,
        label: this.$t('coaching.deadlinePassed')
      }];
      const dynamicOption = {
        value: 2,
        label: this.$t('coaching.notStarted')
      };
      return ['teamView', 'leader'].includes(this.type) ? [dynamicOption, ...commonOptions] : commonOptions;
    },
    courseTypeOptions() {
      return [{
        value: 1,
        label: this.$t('coaching.scriptReading')
      }, {
        value: 2,
        label: this.$t('coaching.scriptRecitation')
      }, {
        value: 3,
        label: this.$t('coaching.scriptPractice')
      }, {
        value: 4,
        label: this.$t('coaching.aICustomerHasVar', ['(Alpha)']),
        isHidden: !this.api_permissions.includes('/webapi/training/course/ai/customer/create')
      },
      // {
      //   value: 5,
      //   label: this.$t('coaching.aICustomer'),
      //   isHidden: !this.api_permissions.includes(
      //     '/webapi/training/course/scene_ai/customer/create'
      //   )
      // },
      {
        value: 6,
        label: this.$t('coaching.aICustomer'),
        isHidden: !this.api_permissions.includes('/webapi/training/course/scene_ai/customer/create')
      }].filter(item => !item.isHidden);
    }
  },
  watch: {
    filters: {
      handler: (0, _commonFunc.debounce)(function (val) {
        this.$emit('filterChange', val);
      }, 200),
      deep: true
    }
  },
  created() {
    this.getTaskCategoryList();
    const cacheKey = {
      [_routeMap.ROUTES.coachingCenterLeader]: 'ByLeaderView',
      [_routeMap.ROUTES.coachingCenterTeamView]: 'ByTeamView',
      [_routeMap.ROUTES.coachingCenterSales]: 'ByMyView'
    }[this.$route.path];
    const coachingTaskListParams = JSON.parse(sessionStorage.getItem(`coachingTaskListParams${cacheKey}`) || '{}');
    const taskName = this.$route.query.name;
    if (taskName) {
      coachingTaskListParams.name = taskName;
    }
    if (!Object.keys(coachingTaskListParams).length) {
      this.$emit('filterChange', this.filters);
      return;
    }
    for (const key in this.filters) {
      if (typeof coachingTaskListParams[key] !== 'undefined') {
        this.filters[key] = coachingTaskListParams[key];
      }
    }
    if (this.filters.user_ids.length || this.filters.tree_ids.length) {
      this.memberBackfillList = JSON.parse(sessionStorage.getItem('coachingTaskListParamsMemberList') || '[]');
    }
    this.memberOptions = JSON.parse(sessionStorage.getItem('distributorList') || '[]');
  },
  methods: {
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getUserList: _organization.getUserList,
    distributorChange() {
      sessionStorage.setItem('distributorList', JSON.stringify(this.memberOptions));
    },
    getMember: (0, _commonFunc.debounce)(async function (filter) {
      try {
        this.searchLoading = true;
        const res = await (0, _conversationList.getHostPersonOrTeam)({
          filter
        });
        if (res.code === 200) {
          this.memberOptions = res.results.users;
        }
      } finally {
        this.searchLoading = false;
      }
    }),
    selectMember(val, valObjs) {
      this.filters.user_ids = val.user_ids;
      this.filters.tree_ids = val.tree_ids;
      sessionStorage.setItem('coachingTaskListParamsMemberList', JSON.stringify(valObjs));
    },
    async getTaskCategoryList() {
      const res = await (0, _coachingCenter.getTaskCategoryList)();
      if (res.code === 20000) {
        this.taskCategoryOptions = res.results.data;
        this.taskCategoryOptions.push({
          id: 0,
          name: this.$t('coaching.无分类')
        });
      }
    }
  }
};
exports.default = _default;