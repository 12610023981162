"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "operate-column"
  }, [_vm.allShowOperateList.length ? [_vm._l(_vm.allShowOperateList.slice(0, _vm.defaultButtonCount), function (item) {
    return [_vm._t(item.command, function () {
      return [_c("el-link", {
        key: item.command,
        class: ["operate-item", item.class],
        attrs: {
          underline: false,
          "data-v-name": item["data-v-name"],
          icon: item.icon && _vm.showIcon ? `iconfont ${item.icon}` : ""
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
            return _vm.handleClick(item.command);
          }
        }
      }, [_vm._v(_vm._s(item.label))])];
    })];
  }), _vm.showMoreOperate ? _c("more-operations", _vm._b({
    staticClass: "more-btn",
    attrs: {
      "menu-class": "more-operation-menu",
      trigger: _vm.trigger,
      list: _vm.allShowOperateList.slice(_vm.showMoreOperate)
    },
    on: {
      command: _vm.handleClick
    }
  }, "more-operations", _vm.$attrs, false)) : _vm._e()] : [_vm._v("-")]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;