"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/i18n/index.js");
class TaskStatus {
  constructor() {
    this._labelMap = new Map();
    this._labelMap.set(0, {
      label: (0, _index.t)('coaching.inProgress'),
      type: 'processing'
    }).set(1, {
      label: (0, _index.t)('coaching.deadlinePassed'),
      type: 'warning'
    }).set(2, {
      label: (0, _index.t)('coaching.notStarted'),
      type: ''
    }).set(3, {
      label: (0, _index.t)('coaching.completed'),
      type: 'success'
    });
  }
  getLabel(type) {
    return this._labelMap.get(type).label;
  }
  getType(type) {
    return this._labelMap.get(type).type;
  }
  getOptions() {
    const options = [];
    for (const [key, value] of this._labelMap) {
      options.push({
        value: key,
        label: value.label
      });
    }
    return options;
  }
}
var _default = new TaskStatus();
exports.default = _default;