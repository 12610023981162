"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisFilters = _interopRequireDefault(require("./components/AnalysisFilters.vue"));
var _DataAnalysisTable = _interopRequireDefault(require("./components/DataAnalysisTable.vue"));
var _MgPagination = _interopRequireDefault(require("@/components/MgPagination"));
var _coachingCenter = require("@/api/coachingCenter");
var _exportTable = require("@/utils/export-table");
var _vue = require("vue");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _composables = require("vue-router/composables");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'index',
  setup(__props) {
    const total = (0, _vue.ref)(0);
    const listParams = (0, _vue.ref)({
      current_page: 1,
      size: 10
    });
    const sizeChange = size => {
      listParams.value.size = size;
    };
    const currentChange = page => {
      listParams.value.current_page = page;
    };
    const tableHeader = (0, _vue.ref)({});
    const tableData = (0, _vue.ref)([]);
    const mainColumn = (0, _vue.ref)({});
    const tableDataDisplay = (0, _vue.computed)(() => {
      const start = (listParams.value.current_page - 1) * listParams.value.size;
      const end = start + listParams.value.size;
      return tableData.value.slice(start, end);
    });
    const filter = (0, _vue.ref)({});
    const loading = (0, _vue.ref)(false);
    const fetchData = async () => {
      loading.value = true;
      const params = (0, _timezone.convertParams2UTCplus8)(filter.value);
      const res = await (0, _coachingCenter.getTraineeOverview)(params).finally(() => {
        loading.value = false;
      });
      if (res.code === 20000) {
        mainColumn.value = filter.value.dimension === 'trainee' ? {
          key: 'trainee',
          label: '学员'
        } : {
          key: 'department',
          label: '部门'
        };
        const results = res.results;
        tableHeader.value = results.headers;
        tableData.value = results.table;
        total.value = res.results.table.length;
        sortTableData();
      }
    };
    const formatResults = _ref => {
      let {
        headers,
        table
      } = _ref;
      for (const key in headers) {
        headers[key].total = headers[key].total === -1 ? '-' : headers[key].total;
        headers[key].average = headers[key].average === -1 ? '-' : headers[key].average;
        if (key === 'complete_rate') {
          headers[key].average = parse2percent(headers[key].average);
        }
      }
      for (const item of table) {
        for (const key in item) {
          if (key === 'complete_rate' && item[key] !== -1) {
            item[key] = parse2percent(item[key]);
          }
          if (item[key] === -1) {
            item[key] = '-';
          }
        }
      }
      return {
        headers,
        table
      };
    };
    const parse2percent = num => {
      return `${Math.round(num * 10000) / 100}%`;
    };
    const onAnalysisFilterChange = _filter => {
      filter.value = _filter;
      listParams.value.current_page = 1;
      fetchData();
    };
    const subColumns = (0, _vue.ref)([{
      key: 'plan_count',
      label: '任务数'
    }, {
      key: 'complete_count',
      label: '完成数'
    }, {
      key: 'complete_rate',
      label: '完成率',
      isRatio: true,
      isDisableClick: true
    }, {
      key: 'avg_score',
      label: '平均分',
      isDisableClick: true
    }, {
      key: 'highest_score',
      label: '最高分'
    }, {
      key: 'lowest_score',
      label: '最低分'
    }]);
    const currentSort = (0, _vue.ref)({
      prop: 'plan_count',
      order: 'descending'
    });
    const sortChange = (prop, order) => {
      currentSort.value = {
        prop,
        order
      };
      currentChange(1);
      sortTableData();
    };
    const sortTableData = () => {
      const {
        prop,
        order
      } = currentSort.value;
      tableData.value.sort((a, b) => {
        if (order === 'ascending') {
          return a[prop] - b[prop];
        } else {
          return b[prop] - a[prop];
        }
      });
    };
    const tableRef = (0, _vue.ref)(null);
    const exportTable = () => {
      const column1and2 = [{
        key: mainColumn.value.key + '.name',
        label: mainColumn.value.label
      }, {
        key: mainColumn.value.key + '.tree',
        label: '所属部门'
      }];

      // 按部门导出增加一列
      if (mainColumn.value.key === 'department') {
        column1and2.push({
          key: 'department.user_count',
          label: '练习人数'
        });
      }
      const columns = [...column1and2, ...subColumns.value];
      const results = {
        headers: (0, _cloneDeep.default)(tableHeader.value),
        table: (0, _cloneDeep.default)(tableData.value)
      };
      const {
        headers,
        table
      } = formatResults(results);
      const data = table.map(item => {
        const bread = [];
        columns.forEach(column => {
          const keyArr = column.key.split('.');
          if (keyArr.length > 1) {
            bread.push(item[keyArr[0]][keyArr[1]]);
            return;
          }
          bread.push(item[column.key]);
        });
        return bread;
      });
      const head = columns.map(column => column.label);
      console.log(subColumns.value, 'subColumns.value');
      const headerCount = ['total', 'average'].map(key => {
        return [key === 'total' ? '总计' : '平均', '', ...subColumns.value.map(column => {
          const value = headers[column.key][key];
          return value === -1 ? '-' : value;
        })];
      });

      // 如果按部门导出，需要在headerCount中第三列增加department.total_user_count
      if (mainColumn.value.key === 'department') {
        headerCount[0].splice(2, 0, headers.department.total_user_count || 0);
        const averageUserCount = headers.department.total_user_count / tableData.value.filter(item => item.plan_count !== -1).length;
        headerCount[1].splice(2, 0, headers.department.total_user_count ? averageUserCount > 0 ? Math.floor(averageUserCount) : averageUserCount.toFixed(1) : 0);
      }
      const _data = [head, ...headerCount, ...data];
      (0, _exportTable.exportAndDownloadTable)(_data, `学员概览-按${mainColumn.value.label}-${filter.value.plan_start_time.map(item => (0, _moment.default)(item).format('yyyyMMDD')).join('_')}.csv`);
    };
    const router = (0, _composables.useRouter)();
    const cellClick = (column, row) => {
      let fillArr;
      if (mainColumn.value.key === 'trainee') {
        fillArr = [{
          id: row.trainee.id + '',
          name: row.trainee.name,
          avatar: row.trainee.avatar,
          isDepartment: false
        }];
      } else {
        fillArr = [{
          id: 'tree_id_' + row.department.id,
          name: row.department.name,
          isDepartment: true
        }];
      }
      const transFilter = {
        user_start_time: filter.value.user_start_time,
        plan_start_time: filter.value.plan_start_time
      };
      switch (column.key) {
        case 'complete_count':
          transFilter.is_complete = 1;
          break;
        case 'highest_score':
        case 'lowest_score':
        case 'avg_score':
          transFilter.score = [row[column.key], row[column.key]];
      }
      sessionStorage.setItem('coachDataAnalysisTaskDetailFilter', JSON.stringify({
        transFilter,
        fillArr
      }));
      router.push(_routeMap.ROUTES.coachDataAnalysisTaskDetail);
    };
    return {
      __sfc: true,
      total,
      listParams,
      sizeChange,
      currentChange,
      tableHeader,
      tableData,
      mainColumn,
      tableDataDisplay,
      filter,
      loading,
      fetchData,
      formatResults,
      parse2percent,
      onAnalysisFilterChange,
      subColumns,
      currentSort,
      sortChange,
      sortTableData,
      tableRef,
      exportTable,
      router,
      cellClick,
      AnalysisFilters: _AnalysisFilters.default,
      DataAnalysisTable: _DataAnalysisTable.default,
      MgPagination: _MgPagination.default
    };
  }
};
exports.default = _default;