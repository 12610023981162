"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomCascader = _interopRequireDefault(require("@/views/businessIntelligence/components/portraitConfigComponents/CustomCascader"));
var _LableRequireTooltipRemark = _interopRequireDefault(require("@/views/businessIntelligence/components/portraitConfigComponents/LableRequireTooltipRemark"));
var _conversationList = require("@/api/conversationList");
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    CustomCascader: _CustomCascader.default,
    LableRequireTooltipRemark: _LableRequireTooltipRemark.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean
    },
    getPortraitConfigLoading: {
      type: Boolean
    }
  },
  data() {
    const checkAxisConfigName = (rule, value, callback) => {
      if (!rule.field) {
        return callback(new Error(`请输入轴名称`));
      }
      callback();
    };
    const checkIndicatorsItem = (rule, value, callback) => {
      const params = JSON.parse(rule.field);
      const currentCator = this.kanbanConfig.axis_config[params[0]].config_items[params[1]];
      if (!currentCator.metrics_type) {
        return callback(new Error(`请选择指标类型`));
      }
      if (!currentCator.metrics_id || Array.isArray(currentCator.metrics_id) && !currentCator.metrics_id.length) {
        return callback(new Error(`请选择指标值`));
      }
      callback();
    };
    return {
      submitLoading: false,
      activeTab: 0,
      kanbanConfig: {
        config_name: '',
        axis_config: [{
          title: 'X轴',
          name: '过程分',
          remark: '推荐配置10个指标',
          config_items: [{
            metrics_type: '',
            metrics_id: '',
            weight: 50,
            id: Symbol(),
            aggregate_type: '',
            score_type: ''
          }]
        }, {
          title: 'Y轴',
          name: '结果分',
          config_items: [{
            metrics_type: '',
            metrics_id: '',
            weight: 50,
            id: Symbol(),
            aggregate_type: '',
            score_type: ''
          }]
        }]
      },
      indicatorsType: [{
        name: '关键事件',
        type: 'keyEventOptions'
      }, {
        name: '交易指标',
        type: 'businessOptions'
      }, {
        name: '会话指标',
        type: 'conversation'
      }],
      indicatorsValueOptions: {
        keyEventOptions: [],
        businessOptions: [],
        conversation: []
      },
      dataTypeConfig: {
        aggregateTypeOptions: {
          key: 'aggregate_type',
          label: '统计维度',
          require: true,
          tooltip: [{
            title: '统计维度：',
            content: '同一交易下会存在多通会话，基于考量目标我们可以选择对应的统计维度。选择后，数据统计时将会以所选维度进行数据汇总。'
          }, {
            title: '交易维度：',
            content: '汇总某些数据在每一个交易上的表现情况。 例如：A销售有300个交易共1000通会话，其中100个交易触达了与"异议"相关的事件。'
          }, {
            title: '会话维度：',
            content: '汇总某些数据在每一通会话上的表现情况。例如：A销售有300个交易共1000通会话，其中400个会话触达了与"异议"相关的事件。'
          }, {
            title: '提示：',
            content: '部分数据因其特殊性，只存在单一的考量维度，例如交易指标固定只有交易维度。'
          }],
          remark: ''
        },
        scoreTypeOptions: {
          key: 'score_type',
          label: '计算方式',
          require: true,
          tooltip: [{
            title: '计算方式：',
            content: '基于不同的分析方向，我们可以选择对应的计算方式。'
          }, {
            title: '日均总量：',
            content: '计算某些数据在所选时间范围内的日均情况，相对偏向量的分析。例如：A销售在一周内一共300条数据，其中有70条符合要求则日均总量为10。'
          }, {
            title: '平均量：',
            content: '计算某些数据在所选时间范围内对应该数据的总体的占比，相对偏向质的分析。例如：B销售在一周内一共100条数据，其中有40条符合要求则平均量为40%（计算时将转换成0.4）'
          }, {
            title: '提示：',
            content: '部分数据因其特殊性，只存在单一的计算方式，例如通话时长等。'
          }],
          remark: ''
        },
        keyEventOptions: {
          aggregateTypeOptions: [{
            type: '0',
            name: '交易维度',
            disabled: false
          }, {
            type: '1',
            name: '会话维度',
            disabled: false
          }],
          scoreTypeOptions: [{
            type: '0',
            name: '日均总量',
            disabled: false
          }, {
            type: '1',
            name: '平均量',
            disabled: false
          }]
        },
        businessOptions: {
          aggregateTypeOptions: [{
            type: '0',
            name: '交易维度',
            disabled: false
          }, {
            type: '1',
            name: '会话维度',
            disabled: true
          }],
          scoreTypeOptions: [{
            type: '0',
            name: '日均总量',
            disabled: false
          }, {
            type: '1',
            name: '平均量',
            disabled: false
          }]
        },
        conversationDuration: {
          aggregateTypeOptions: [{
            type: '0',
            name: '交易维度',
            disabled: true
          }, {
            type: '1',
            name: '会话维度',
            disabled: false
          }],
          scoreTypeOptions: [{
            type: '0',
            name: '日均总量',
            disabled: false
          }, {
            type: '1',
            name: '平均量',
            disabled: false
          }]
        },
        conversationCount: {
          aggregateTypeOptions: [{
            type: '0',
            name: '交易维度',
            disabled: true
          }, {
            type: '1',
            name: '会话维度',
            disabled: false
          }],
          scoreTypeOptions: [{
            type: '0',
            name: '日均总量',
            disabled: false
          }, {
            type: '1',
            name: '平均量',
            disabled: true
          }]
        }
      },
      tooltip: [{
        title: 'X 轴是如何计算的？',
        content: '销售成员的X轴可以按照各项指标（关键事件得分、会话指标得分、交易指标得分）的加权求和分数。X轴的配置可以由公司管理员根据本公司的业务特点配置。例如：一个医美行业公司，一个销售成员的X轴表示了销售的基础能力，由“客户异议处理”，“邀约上门”事件得分加权得到。'
      }, {
        title: 'Y 轴是如何计算的？',
        content: '销售成员的Y轴可以按照各项指标（关键事件得分、会话指标得分、交易指标得分）的加权求和分数。Y轴的配置可以由公司管理员根据本公司的业务特点配置。例如：一个医美行业公司，一个销售成员的Y轴表示了销售的业绩结果能力，由交易指标“赢单数”得分得到。'
      }],
      rules: {
        config_name: [{
          required: true,
          message: '请输入看板名称',
          trigger: 'blur'
        }]
      },
      checkAxisConfigName: {
        required: true,
        validator: checkAxisConfigName,
        trigger: 'blur'
      },
      checkIndicatorsItem: {
        required: true,
        validator: checkIndicatorsItem,
        trigger: 'blur'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'workspaceInfo'])
  },
  watch: {
    drawerVisible() {
      this.activeTab = 0;
    },
    'workspaceInfo.id': {
      handler: function () {
        this.axisConfig = this.$options.data().axisConfig;
        this.getOptions();
      },
      immediate: true
    }
  },
  methods: {
    getDateTypeConfig(indicatorsItem) {
      let key = indicatorsItem.metrics_type;
      if (key === 'conversation') {
        key = indicatorsItem.metrics_id;
      }
      return key ? this.dataTypeConfig[key] : {};
    },
    scrollForm: (0, _commonFunc.debounce)(function (e) {
      const scrollTop = e.target.scrollTop;
      const xAxisHeight = this.$refs[`tab-container-0`][0].scrollHeight;
      this.activeTab = scrollTop < xAxisHeight ? 0 : 1;
    }, 200),
    changeTab(val) {
      this.activeTab = val;
      const scrollForm = this.$refs['scroll-form'];
      let top = 0;
      if (val) {
        const itemAxios = this.$refs[`tab-container-${val}`][0];
        top = scrollForm.scrollHeight - itemAxios.scrollHeight - 20;
      }
      scrollForm.scrollTo({
        top,
        behavior: 'smooth'
      });
    },
    setRedioDefaultChecked(indicatorsItem, type) {
      const options = this.getDateTypeConfig(indicatorsItem)[type];
      return options ? options.find(item => !item.disabled).type : '';
    },
    metricsTypeChange(indicatorsItem) {
      indicatorsItem.metrics_id = '';
      this.metricsValueChange(indicatorsItem);
    },
    metricsValueChange(indicatorsItem) {
      indicatorsItem.aggregate_type = this.setRedioDefaultChecked(indicatorsItem, 'aggregateTypeOptions');
      indicatorsItem.score_type = this.setRedioDefaultChecked(indicatorsItem, 'scoreTypeOptions');
    },
    handleClose() {
      this.clearForm();
      this.changeRules('blur');
      this.$emit('update:drawerVisible', false);
    },
    clearForm() {
      this.kanbanConfig = this.$options.data().kanbanConfig;
      this.$refs['form'].clearValidate();
    },
    changeRules(type) {
      this.checkAxisConfigName.trigger = type;
      this.checkIndicatorsItem.trigger = type;
    },
    submit() {
      this.changeRules('change');
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm('保存后，销售画像将根据本次计算指标配置生成', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(async () => {
            const requestFn = this.isEdit ? _businessIntelligence.editPortraitConfig : _businessIntelligence.createPortraitConfig;
            this.submitLoading = true;
            const res = await requestFn({
              ...this.kanbanConfig,
              workspace_id: this.workspaceInfo.id
            }).finally(() => {
              this.submitLoading = false;
            });
            res.code === 200 && this.$message.success(this.isEdit ? '编辑成功' : '新建成功');
            const emitParams = {
              name: this.kanbanConfig.config_name,
              kanban_id: this.kanbanConfig.kanban_id
            };
            if (!this.isEdit) {
              emitParams.kanban_id = res.results.origin_config_id;
              emitParams.bi_chart_id = res.results.chart_id;
            }
            this.$emit('portraitSuccess', emitParams);
            this.$emit('update:drawerVisible', false);
            this.clearForm();
          });
        } else {
          return false;
        }
      });
    },
    previewPortrait() {
      this.changeRules('change');
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.$emit('previewPortrait', this.kanbanConfig);
        } else {
          return false;
        }
      });
    },
    addIndicator(index) {
      this.kanbanConfig.axis_config[index].config_items.push({
        metrics_type: '',
        metrics_id: '',
        weight: 50,
        id: Symbol(),
        score_type: '',
        aggregate_type: ''
      });
    },
    deleteIndicator(index, indicatorsIndex) {
      this.kanbanConfig.axis_config[index].config_items.splice(indicatorsIndex, 1);
    },
    async getOptions() {
      this.$set(this.indicatorsValueOptions, 'conversation', [{
        id: 'conversationDuration',
        name: '通话时长'
      }, {
        id: 'conversationCount',
        name: '通话次数'
      }]);
      const keyEventRes = await (0, _conversationList.getAllKeyEvent)({
        workspace_id: this.workspaceInfo.id
      });
      if (keyEventRes.code === 20000) {
        this.$set(this.indicatorsValueOptions, 'keyEventOptions', keyEventRes.results.events);
      }
      const businessRes = await (0, _businessIntelligence.getDealOptions)();
      if (businessRes.code === 200) {
        this.$set(this.indicatorsValueOptions, 'businessOptions', businessRes.results);
      }
    }
  }
};
exports.default = _default;