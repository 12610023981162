"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePredictionResult = usePredictionResult;
exports.useTrainAndTestDatasetParams = useTrainAndTestDatasetParams;
exports.useTrainSuperParams = useTrainSuperParams;
var _vue = require("vue");
function useTrainSuperParams() {
  const autoTuningParams = (0, _vue.ref)(false);
  const trainParamMode = (0, _vue.ref)('');
  const trainSuperParamTableRef = (0, _vue.ref)(null);
  const originTrainParams = {
    resample_num: 2,
    negative_selected_resample_num: 1,
    negative_surround_ratio: 0.5,
    positive_negative_ratio: 4,
    epoch: 8
  };
  const trainParamsFillValue = (0, _vue.ref)({});
  const trainParam = (0, _vue.ref)(originTrainParams);
  const onAutoTuningParams = val => {
    trainParamMode.value = val ? 1 : '';
  };
  const initDrawer = () => {
    trainSuperParamTableRef.value.initParams();
    autoTuningParams.value = false;
  };
  const paramsChange = val => {
    trainParam.value = val;
  };
  return {
    trainParamsFillValue,
    paramsChange,
    initDrawer,
    trainSuperParamTableRef,
    trainParam,
    autoTuningParams,
    trainParamMode,
    onAutoTuningParams
  };
}
function useTrainAndTestDatasetParams() {
  const trainDatasetList = (0, _vue.ref)([]);
  const trialDatasetList = (0, _vue.ref)([]);
  const datasetSplitType = (0, _vue.ref)(1);
  const trainAndTestDatasetRef = (0, _vue.ref)(null);
  const isAutoTest = (0, _vue.ref)(false);
  const getTrainAndTrailDatasetData = () => {
    return trainAndTestDatasetRef.value.getTrainAndTestData();
  };
  return {
    isAutoTest,
    trainAndTestDatasetRef,
    trainDatasetList,
    trialDatasetList,
    datasetSplitType,
    getTrainAndTrailDatasetData
  };
}
function usePredictionResult() {
  const postProcessType = (0, _vue.ref)(1);
  const isEnableLLM = (0, _vue.ref)(0);
  const promptContent = (0, _vue.ref)('');
  const isEnableLLMChange = val => {
    isEnableLLM.value = val;
  };
  const predictionResultRef = (0, _vue.ref)(null);
  const getPromptContent = () => {
    return predictionResultRef.value.getPromptContent();
  };
  return {
    postProcessType,
    isEnableLLM,
    isEnableLLMChange,
    predictionResultRef,
    promptContent,
    getPromptContent
  };
}