"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FolderTree = _interopRequireDefault(require("@/components/FolderTree"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    FolderTree: _FolderTree.default
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isSnippet: Boolean,
    disabled: Boolean
  },
  methods: {
    handleNodeClick(data) {
      this.$refs.select.blur();
      this.$emit('input', data);
    },
    updateTree() {
      this.$nextTick(() => {
        this.$refs['folder-tree'].fetchData();
      });
    }
  }
};
exports.default = _default;