"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hightlight = require("@/utils/hightlight");
var _default = {
  name: 'ScoreCorrectionContentCard',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      score: 1
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.score = Number(val.score);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleHighLight: _hightlight.handleHighLight,
    handleChange(val) {
      this.score = Number(val.toFixed(1));
      this.$emit('scoreChange', {
        [this.value.template_id]: this.score
      });
    }
  }
};
exports.default = _default;