"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DimensionList = _interopRequireDefault(require("./DimensionList.vue"));
var _MetricsTable = _interopRequireDefault(require("./MetricsTable.vue"));
var _coachingCenter = require("@/api/coachingCenter");
var _MetricCreateDialog = _interopRequireDefault(require("./MetricCreateDialog.vue"));
var _vue = require("vue");
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SelectMetricsDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const dimensionListRef = (0, _vue.ref)(null);
    let selectionMap = new Map();
    (0, _vue.watch)(() => props.visible, async val => {
      if (val) {
        await (0, _vue.nextTick)();
        dimensionListRef.value.initData();
        selectionMap = new Map();
        props.value.forEach(item => {
          if (selectionMap.has(item.dimension_id)) {
            selectionMap.get(item.dimension_id).push(item);
          } else {
            selectionMap.set(item.dimension_id, [item]);
          }
        });
        // localSelection.value = cloneDeep(props.value);
      }
    }, {
      immediate: true
    });
    const activeDimension = (0, _vue.ref)({});
    const activeSelection = (0, _vue.computed)(() => {
      return selectionMap.get(activeDimension.value.id) || [];
    });
    const metricsTableRef = (0, _vue.ref)(null);
    const dimensionNodeClick = async (item, metric) => {
      activeDimension.value = item;
      if (!selectionMap.has(item.id)) {
        selectionMap.set(item.id, []);
      }
      await getScoreMetricsList();
      if (metric) {
        metricsTableRef.value.highlightRow(metric);
      }
    };
    const metricList = (0, _vue.ref)([]);
    const getScoreMetricsList = async () => {
      const params = {
        dimension_id: activeDimension.value.id
      };
      const res = await (0, _coachingCenter.scoreMetricsList)(params);
      metricList.value = res.results.items.map(item => {
        return {
          metrics_id: item.id,
          dimension_id: item.dimension_id,
          dimension_name: item.dimension_name,
          metrics_name: item.name,
          gists: item.gists || [],
          weight: undefined
        };
      });
    };
    const metricCreateDialogVisible = (0, _vue.ref)(false);
    const localSelection = (0, _vue.ref)([]);
    const selectedList = (0, _vue.ref)([]);
    const selectable = (0, _vue.ref)(true);
    const onSelectionChange = val => {
      selectionMap.set(activeDimension.value.id, val);
      // selectedList.value = selectionMap.values().flat();
      // console.log(selectionMap.values(), 'selectionMap.values().flat()');
      // 把map的value合并成一个数组
      selectedList.value = Array.from(selectionMap.values()).flat();
      if (selectedList.value.length >= 8) {
        selectable.value = false;
      } else {
        selectable.value = true;
      }
    };
    const confirmValue = () => {
      console.log(selectedList.value);
      if (selectedList.value.length > 8) {
        (0, _elementUi.Message)({
          message: '最多选择8个指标',
          type: 'warning'
        });
        return;
      }
      // selectionMap.forEach((value, key) => {
      //   localSelection.value = localSelection.value.filter(
      //     (item) => item.dimension_id !== key
      //   );
      //   localSelection.value.push(...value);
      // });
      // const formatValue = localSelection.value.map((item) => {
      //   return {
      //     metrics_id: item.id,
      //     dimension_id: item.dimension_id,
      //     dimension_name: item.dimension_name,
      //     metrics_name: item.name,
      //     gists: item.gists || [],
      //     weight: item.weight || 0
      //   };
      // });
      emit('input', (0, _cloneDeep.default)(selectedList.value));
      emit('update:visible', false);
    };
    return {
      __sfc: true,
      props,
      emit,
      dimensionListRef,
      selectionMap,
      activeDimension,
      activeSelection,
      metricsTableRef,
      dimensionNodeClick,
      metricList,
      getScoreMetricsList,
      metricCreateDialogVisible,
      localSelection,
      selectedList,
      selectable,
      onSelectionChange,
      confirmValue,
      DimensionList: _DimensionList.default,
      MetricsTable: _MetricsTable.default,
      MetricCreateDialog: _MetricCreateDialog.default
    };
  }
};
exports.default = _default;