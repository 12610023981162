"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _ItemTemplate = _interopRequireDefault(require("./ItemTemplate.vue"));
var _TrickItem = _interopRequireDefault(require("./TrickItem.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'StandardTricks',
  components: {
    ItemTemplate: _ItemTemplate.default,
    TrickItem: _TrickItem.default
  },
  props: {
    knowledgeId: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sceneList: [],
      sceneName: '',
      isEditingScene: false,
      formData: {
        scene: null,
        // 初始值设为null,区别于为0的情况
        list: []
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    list(val) {
      this.formData.list = JSON.parse(JSON.stringify(val));
    },
    'workspaceInfo.id': {
      handler: async function (val) {
        if (val) {
          var _this$sceneList$;
          await this.getSceneList();
          this.formData.scene = (_this$sceneList$ = this.sceneList[0]) === null || _this$sceneList$ === void 0 ? void 0 : _this$sceneList$.scene_id;
        }
      },
      immediate: true
    }
  },
  methods: {
    async getSceneList() {
      const res = await (0, _knowledgeLibrary.getSceneList)({
        knowledge_id: this.knowledgeId,
        space_id: this.workspaceInfo.id
      });
      if (res.code === 20000) {
        this.sceneList = res.results.datas;
      }
    },
    handleTextChange(value, index) {
      // 解决表单验证监听不到value变化后的问题
      this.$set(this.formData.list, index, {
        ...this.formData.list,
        value
      });
    },
    deleteItem(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.list.splice(index, 1); // 这里直接操作了props里的list
    },

    editScene() {
      this.isEditingScene = true;
      this.$nextTick(() => {
        this.$refs.sceneInput.focus();
      });
    },
    cancelEditScene() {
      this.isEditingScene = false;
      this.sceneName = '';
    },
    async createScene() {
      const res = await (0, _knowledgeLibrary.createScene)({
        knowledge_id: this.knowledgeId,
        name: this.sceneName
      });
      if (res.code === 20000) {
        this.isEditingScene = false;
        const {
          id
        } = res.results;
        this.sceneList.push({
          content: this.sceneName,
          scene_id: id
        });
        this.formData.scene = id;
        this.sceneName = '';
      }
    },
    handleVisibleChange(val) {
      if (!val && this.isEditingScene) {
        this.cancelEditScene();
      }
    }
  }
};
exports.default = _default;