"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _BusinessPathMixin = _interopRequireDefault(require("./BusinessPathMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UserTable',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  mixins: [_BusinessPathMixin.default]
};
exports.default = _default;