"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "date-popover",
    style: {
      position: "fixed",
      top: _vm.top,
      left: _vm.left,
      visibility: `${_vm.visible ? "visible" : "hidden"}`
    }
  }, [_vm._t("default", function () {
    return [_vm._v("2020-12-20")];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;