"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _loginTable = _interopRequireDefault(require("./components/loginTable.vue"));
var _DepartmentSelect = _interopRequireDefault(require("@/components/DepartmentSelect"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LoginLog',
  components: {
    loginTable: _loginTable.default,
    DepartmentSelect: _DepartmentSelect.default
  },
  data() {
    return {
      departmentValue: [1],
      member: '',
      dateRange: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      status: -1,
      statusOptions: [{
        value: -1,
        label: '全部状态'
      }, {
        value: 1,
        label: '使用'
      }, {
        value: 0,
        label: '未使用'
      }],
      list: [],
      total: 0,
      page: 1,
      size: 10,
      pickerOptions: {
        disabledDate: this.getDisabledDate
      },
      loading: false,
      timer: null
    };
  },
  computed: {
    listParams() {
      return {
        name: this.member,
        start_day: this.dateRange[0],
        end_day: this.dateRange[1],
        tree_id: this.departmentValue[0],
        status: this.status,
        page: this.page,
        size: this.size
      };
    }
  },
  watch: {
    listParams: {
      handler() {
        this.getLoginLog();
      },
      deep: true
    }
  },
  methods: {
    getLoginLog() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(this.fetchData, 500);
    },
    async fetchData() {
      try {
        this.loading = true;
        const res = await (0, _organization.getLoginLog)(this.listParams);
        if (res.code === 20000) {
          this.total = res.results.count;
          this.list = res.results.datas;
        }
      } finally {
        this.loading = false;
      }
    },
    handleDateRange(val) {
      this.page = 1;
      this.dateRange = val || [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
    },
    handleCurrentChange(page) {
      this.page = page;
    },
    handleSizeChange(size) {
      this.size = size;
      this.page = 1;
    },
    getDisabledDate(date) {
      return !this.$moment(date).isBetween(this.$moment().subtract(45, 'days'));
    },
    handleInput(val) {
      this.page = 1;
      this.member = val;
    },
    handleSelectChange(val) {
      this.page = 1;
      this.status = val;
    },
    handleDepartmentChange(val) {
      this.page = 1;
      this.departmentValue = val;
    }
  }
};
exports.default = _default;