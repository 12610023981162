"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'WidgetCascader',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    cascaderIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['cascaderFieldChange'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const formatCascaderOptions = options => {
      // 递归给children长度为空的项删除children
      options.forEach(item => {
        if (item.children && item.children.length === 0) {
          delete item.children;
        } else {
          return formatCascaderOptions(item.children || []);
        }
      });
      return options;
    };
    const handleCascaderChange = val => {
      emit('cascaderFieldChange', val);
    };
    return {
      __sfc: true,
      props,
      emit,
      formatCascaderOptions,
      handleCascaderChange
    };
  }
};
exports.default = _default;