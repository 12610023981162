"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "tree",
    staticClass: "department-tree",
    attrs: {
      data: _vm.orgTree,
      "node-key": "id",
      "highlight-current": "",
      "expand-on-click-node": false,
      "show-checkbox": "",
      props: _vm.defaultProps,
      "default-expanded-keys": _vm.defaultExpandedKeys
    },
    on: {
      "current-change": _vm.handleCurrentChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          data
        } = _ref;
        return _c("div", {
          staticClass: "custom-tree-node"
        }, [_c("svg-icon", {
          attrs: {
            "icon-class": data.node_type === "root" ? "office-building" : "department"
          }
        }), [_vm._v(" " + _vm._s(data.name) + " ")]], 2);
      }
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;