"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dialogue-item-narration"
  }, [_c("div", {
    staticClass: "content-contaner"
  }, [_c("p", {
    staticClass: "narration-text"
  }, [_vm._t("default")], 2), _vm.imgs && _vm.imgs.length ? _c(_setup.PicDisplay, {
    attrs: {
      "pic-list": _vm.imgs,
      "append-to-body": _vm.imageAppendToBody,
      readonly: ""
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;