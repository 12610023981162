"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HotData = _interopRequireDefault(require("./HotData"));
var _commonFunc = require("@/utils/commonFunc");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SnippetListCard',
  components: {
    HotData: _HotData.default
  },
  props: {
    snippet: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
    };
  },
  computed: {
    convOrigin() {
      var _this$snippet$origin_;
      return (_this$snippet$origin_ = this.snippet.origin_user) === null || _this$snippet$origin_ === void 0 ? void 0 : _this$snippet$origin_.name;
    }
  },
  methods: {
    getDuration: _commonFunc.getDuration,
    goSnippetDetail() {
      this.$router.push({
        path: _routeMap.ROUTES.libraryDetail,
        query: {
          id: this.snippet.id
        }
      });
    }
  }
};
exports.default = _default;