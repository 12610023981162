"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _AssociateContactForm = _interopRequireDefault(require("@/views/businessDetail/components/AssociateContactForm"));
var _client = require("@/api/client");
var _business = require("@/api/business");
var _routeMap = require("@/router/routeMap");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateDealDialog',
  components: {
    DynamicForm: _DynamicForm.default,
    AssociateContactForm: _AssociateContactForm.default
  },
  props: {
    createDealDialogVisible: {
      type: Boolean,
      default: false
    },
    formDesc: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: {},
      isEdit: true,
      loading: true,
      deal_id: '',
      accountList: [],
      saveLoading: false,
      // 判断关联联系人表单是否显示
      isAssociateContact: false,
      contactList: [],
      // 判断复选框是否显示
      isContactCheckBoxVisible: false
    };
  },
  watch: {
    'formData.account_id': {
      handler: function (val) {
        if (val) {
          this.isContactCheckBoxVisible = true;
          this.isAssociateContact = true;
          this.initAssociateContactForm();
          this.getAllContactPersonList(val);
        } else {
          this.isAssociateContact = false;
          this.isContactCheckBoxVisible = false;
        }
      }
    },
    isAssociateContact(val) {
      this.initAssociateContactForm();
    }
  },
  methods: {
    initAssociateContactForm() {
      this.$nextTick(() => {
        this.$refs['associateContactForm'].formData.contact_id = '';
        this.$refs['associateContactForm'].formData.is_main = '';
      });
    },
    customerChange(val) {
      this.$emit('createDealFormChange', val);
      this.$set(this.formData, 'customer_name', val.name);
      this.$set(this.formData, 'account_id', val.id);
    },
    async submitForm() {
      let flag = false;
      if (this.$refs['createDealForm'].validate()) {
        flag = true;
      }
      if (this.isAssociateContact && !this.$refs['associateContactForm'].$refs['dynamicForm'].validate()) {
        flag = false;
      }
      const params = JSON.parse(JSON.stringify(this.formData));
      // eslint-disable-next-line no-prototype-builtins
      params.hasOwnProperty('customer_name') && delete params.customer_name;
      // eslint-disable-next-line no-prototype-builtins
      params.hasOwnProperty('account_id') && delete params.account_id;
      if (flag) {
        await this.postCreateDeal({
          deal: params,
          account_id: this.formData.account_id
        });
        if (this.isAssociateContact) {
          this.$nextTick(() => {
            const contactData = {
              deal_id: this.deal_id,
              contact_id: this.$refs['associateContactForm'].formData.contact_id,
              is_main: this.$refs['associateContactForm'].formData.is_main || 0
            };
            this.associateContact(contactData);
          });
        }
      }
    },
    async getAccount(filter) {
      const res = await (0, _client.getAccount)({
        filter
      });
      if (res.code === 200) {
        this.accountList = res.results.account;
      }
    },
    async postCreateDeal(data) {
      this.saveLoading = true;
      (0, _timezone.datePickerWithTimeParse2Zone)(data.deal);
      const res = await (0, _business.postCreateDeal)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.deal_id = res.results.deal_id;
        if (!this.isAssociateContact) {
          this.closeDialog();
        }
        this.$message.success('新建商机成功');
        this.$router.push({
          path: _routeMap.ROUTES.dealDetail,
          query: {
            id: this.deal_id
          }
        });
      }
    },
    async getAllContactPersonList(data) {
      const res = await (0, _client.getAllContactPersonList)(data);
      if (res.code === 200) {
        this.contactList = [];
        for (let i = 0; i < res.results.length; i++) {
          const obj = {
            label: res.results[i].name,
            value: res.results[i].id
          };
          this.contactList.push(obj);
        }
      }
    },
    async associateContact(data) {
      this.saveLoading = true;
      const res = await (0, _business.associateContact)(data);
      this.saveLoading = false;
      if (res.code === 200) {
        this.$message.success('关联联系人成功');
        this.closeDialog();
      }
    },
    openAccountTable() {
      this.$emit('openChooseAccountDialog', this.formData.account_id);
    },
    closeDialog() {
      this.$emit('closeCreateDealDialog');
      this.formData = {};
    }
  }
};
exports.default = _default;