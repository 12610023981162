"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
var _default = {
  name: 'BusinessForm',
  props: {
    form: {
      type: Object,
      default() {
        return {
          customer_name: '',
          deal_name: '',
          amount: '',
          status: '',
          deal_time: '',
          decision_maker: {
            id: 0,
            name: '',
            tel: '',
            email: ''
          },
          other_contacts: [{
            id: 0,
            name: '',
            tel: '',
            email: ''
          }]
        };
      }
    }
  },
  data() {
    let decisionMakerTelValidator = (rule, value, callback) => {
      if (value === '') {
        // 如果手机号为空，则需要判断邮箱是否为空
        if (this.form.decision_maker.email === '') {
          // 如果邮箱也为空，则提醒用户
          this.$refs['decision-maker-email'].validateMessage = '手机号和邮箱至少填一项';
          this.$refs['decision-maker-email'].validateState = 'error';
          callback(new Error('手机号和邮箱至少填一项'));
        } else {
          callback();
        }
      } else {
        // 如果手机号不为空，则正常校验
        if (/^1[3456789]\d{9}$/.test(value) == false) {
          callback(new Error('请输入正确的手机号'));
        } else {
          this.$refs['decision-maker-email'].clearValidate();
          callback();
        }
      }
    };
    let decisionMakerEmailValidator = (rule, value, callback) => {
      if (value === '') {
        // 如果邮箱为空，则需要判断手机号是否为空
        if (this.form.decision_maker.tel === '') {
          // 如果手机号也为空，则提醒用户
          this.$refs['decision-maker-tel'].validateMessage = '手机号和邮箱至少填一项';
          this.$refs['decision-maker-tel'].validateState = 'error';
          callback(new Error('手机号和邮箱至少填一项'));
        } else {
          callback();
        }
      } else {
        // 如果邮箱不为空，则正常校验
        let regEmail = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/;
        if (regEmail.test(value) == false) {
          callback(new Error('请输入正确的邮箱'));
        } else {
          this.$refs['decision-maker-tel'].clearValidate();
          callback();
        }
      }
    };
    return {
      rules: {
        deal_name: [{
          required: true,
          message: '商机名称不能为空',
          trigger: 'change'
        }],
        customer_name: [{
          required: true,
          message: '客户名称不能为空',
          trigger: 'change'
        }],
        amount: [{
          validator: _validate.numberValidator,
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '商机阶段不能为空',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '决策人姓名不能为空',
          trigger: 'change'
        }],
        tel: [{
          validator: _validate.telValidatorAllowNone,
          trigger: 'blur'
        }],
        email: [{
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: 'blur'
        }],
        decision_maker_tel: [{
          validator: decisionMakerTelValidator,
          trigger: 'blur'
        }],
        decision_maker_email: [{
          validator: decisionMakerEmailValidator,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['businessStatusOptions']),
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          return _this.isEarlyThan(time);
        }
      };
    }
  },
  created() {
    this.$store.dispatch('business/get_business_status_options', 'deal');
  },
  methods: {
    appendother_contacts() {
      this.$props.form.other_contacts.push({
        id: 0,
        name: '',
        tel: '',
        email: ''
      });
    },
    handleValidate() {
      const {
        other_contacts
      } = this.form;
      this.$emit('validate', this.form);
    },
    isEarlyThan(time) {
      const _time = parseInt(this.$moment(time).format('YYYYMMDD'));
      const now = parseInt(this.$moment().format('YYYYMMDD'));
      return _time < now;
    },
    deleteContacts(item, index) {
      let array = this.form.other_contacts;
      array.splice(index, 1);
    },
    changeToDecision(item, index) {
      let array = this.form.other_contacts;
      let saveDecisionInfo = JSON.parse(JSON.stringify(this.form.decision_maker));
      this.form.decision_maker = item;
      array[index] = saveDecisionInfo;
    }
  }
};
exports.default = _default;