"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "el-select-dropdown__item",
    class: {
      selected: _vm.itemSelected,
      "is-disabled": _vm.disabled || _vm.groupDisabled || _vm.limitReached,
      hover: _vm.hover
    },
    on: {
      mouseenter: _vm.hoverItem,
      click: function ($event) {
        $event.stopPropagation();
        return _vm.selectOptionClick.apply(null, arguments);
      }
    }
  }, [_vm._t("default", function () {
    return [_c("span", [_vm._v(_vm._s(_vm.currentLabel))])];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;