"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "note-dialog",
    attrs: {
      title: "笔记模板",
      visible: _vm.visible,
      "before-close": _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "note-template"
  }, [_c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick",
      value: 200,
      expression: "200"
    }],
    staticClass: "add-template",
    attrs: {
      icon: "el-icon-plus",
      type: "text"
    },
    on: {
      click: _vm.addTemplate
    }
  }, [_vm._v("新建模板")]), _c("div", {
    staticClass: "name-list"
  }, [_vm._l(_vm.templatesList, function (item) {
    return _c("div", {
      key: item.id,
      ref: "name-input",
      refInFor: true,
      staticClass: "name-input",
      class: {
        disabled: _vm.disabled
      }
    }, [item.type === "edit" ? [_c("el-input", {
      ref: "edit",
      refInFor: true,
      attrs: {
        placeholder: "请输入模板名称"
      },
      on: {
        blur: function ($event) {
          return _vm.submitNameEdit(item);
        }
      },
      nativeOn: {
        keypress: function ($event) {
          if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          $event.stopPropagation();
          return _vm.submitNameEdit(item);
        }
      },
      model: {
        value: _vm.templateName,
        callback: function ($$v) {
          _vm.templateName = $$v;
        },
        expression: "templateName"
      }
    })] : [_c("div", {
      staticClass: "no-edit",
      class: {
        selected: item.id === _vm.currentId
      }
    }, [_c("span", {
      staticClass: "edit-name",
      on: {
        click: function ($event) {
          return _vm.switchTemplateNote(item);
        }
      }
    }, [_vm._v(_vm._s(item.name))]), _c("span", [_c("i", {
      staticClass: "el-icon-edit edit-icon",
      on: {
        click: function ($event) {
          return _vm.editTemplateName(item);
        }
      }
    })])])]], 2);
  }), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputShow,
      expression: "inputShow"
    }],
    ref: "add-template",
    attrs: {
      placeholder: "请输入模板名称",
      autofocus: ""
    },
    on: {
      blur: _vm.addNewTemplate
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.stopPropagation();
        return _vm.addNewTemplate.apply(null, arguments);
      }
    },
    model: {
      value: _vm.addTemplateName,
      callback: function ($$v) {
        _vm.addTemplateName = $$v;
      },
      expression: "addTemplateName"
    }
  })], 2)], 1), _c("div", {
    staticClass: "template-editor"
  }, [_c("div", {
    staticClass: "note-title"
  }, [_vm._v(" " + _vm._s(_vm.currentTitle) + " ")]), _c("note-editor", {
    ref: "edit-inner",
    attrs: {
      "is-template": "",
      note: _vm.noteTemplateData
    },
    on: {
      templateEditorReady: _vm.handleEditorReady,
      templateChange: _vm.templateIsChange,
      saveTemplate: _vm.saveTemplateChange
    }
  })], 1)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "dialog-btn cancel-btn",
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    staticClass: "dialog-btn use-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.useThisTemplate
    }
  }, [_vm._v("使用此模板")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;