"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MoreOperations = _interopRequireDefault(require("@/components/MoreOperations"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'QuestionItem',
  components: {
    MoreOperations: _MoreOperations.default
  },
  props: {
    isIgnore: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => ({})
    },
    currentQuestion: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId']),
    dropdownList() {
      const list = [{
        command: 'edit',
        show: true,
        label: '编辑',
        icon: 'icon-edit-outline'
      }, {
        command: 'subdivide',
        label: '查看细分问题',
        icon: 'icon-view-list',
        tipInfo: {
          icon: 'icon-info-1',
          content: '当前问题包含若干细分问题，可根据需要将细分问题拆分出来成为独立的问题'
        },
        show: [222, 269, 1].includes(this.orgId)
      }, {
        command: 'merge',
        show: true,
        label: '并入至其他问题',
        icon: 'icon-sum',
        tipInfo: {
          icon: 'icon-info-1',
          content: '可将当前问题并入其他已有问题'
        }
      }, {
        command: 'ignore',
        show: true,
        label: '忽略',
        icon: 'icon-eliminated',
        tipInfo: {
          icon: 'icon-info-1',
          content: '若不需要关注当前问题，可将当前问题忽略，忽略的问题将移入“已忽略问题”中，不参与出现频率和问题占比的计算'
        }
      }, {
        command: 'delete',
        show: true,
        label: '删除',
        icon: 'icon-delete',
        class: 'danger-delete'
        // tipInfo: {
        //   icon: 'icon-info-1',
        //   content:
        //     '若当前问题聚类不准 ，可将当前问题删除，删除后将对问题中的问答重新进行归类'
        // }
      }];

      return list.filter(item => item.show);
    },
    dealFreqTooltip() {
      return `${this.question.all_count}笔交易中有${this.question.deal_count}笔提到`;
    },
    statusConfig() {
      return [{
        type: 'up',
        icon: 'icon-top',
        tip: '排名较上周有上升'
      }, {
        type: 'down',
        icon: 'icon-bottom',
        tip: '排名较上周有下降'
      }].find(item => item.type === this.question.status);
    }
  },
  methods: {
    handleClick() {
      this.$emit('questionClick', this.question);
    }
  }
};
exports.default = _default;