"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convApply = convApply;
exports.convApplyResult = convApplyResult;
exports.getMessageCardData = getMessageCardData;
exports.getMsgList = getMsgList;
exports.getReportDetailData = getReportDetailData;
exports.getUnreadCount = getUnreadCount;
exports.setReadMessage = setReadMessage;
var _request = require("@/utils/request");
// 获取消息列表
function getMsgList(current_page, page_size) {
  return (0, _request.request)({
    url: '/notification/list',
    method: 'get',
    params: {
      current_page,
      page_size
    }
  });
}

// 申请开通权限
function convApply(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/apply',
    method: 'post',
    data: {
      conversation_id
    }
  });
}

// 反馈申请开通权限
function convApplyResult(conversation_id, share_user_id, result) {
  return (0, _request.request)({
    url: '/conversation/applyresult',
    method: 'post',
    data: {
      conversation_id,
      share_user_id,
      result
    }
  });
}

/**
 * 消息卡片结构化
 */

function getMessageCardData(params) {
  return (0, _request.webService)({
    url: '/notification_center/message/list',
    method: 'get',
    params
  });
}

// 日报详情
function getReportDetailData(params) {
  return (0, _request.webService)({
    url: 'notification_center/message/detail',
    method: 'get',
    params
  });
}

// 获取消息中心未读个数
function getUnreadCount() {
  return (0, _request.webService)({
    url: 'notification_center/message/unread_count',
    method: 'get'
  });
}

// 已读操作
function setReadMessage(data) {
  return (0, _request.webService)({
    url: 'notification_center/message/set_read',
    method: 'post',
    dataType: 'json',
    data
  });
}