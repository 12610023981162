"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "tree-select-value-list-container"
  }, [_vm._l(_vm.valueList, function (item, index) {
    return _c("li", {
      key: item.id,
      staticClass: "tree-select-value-list-item",
      class: {
        "select-disabled": item.disabled
      },
      on: {
        click: function ($event) {
          return _vm.changeItem(item);
        }
      }
    }, [_vm._t("value-list-item-prefix-icon", function () {
      return [_c("ImageAvatar", {
        attrs: {
          name: item.name,
          avatar: item.avatar
        }
      })];
    }, {
      nodeData: _vm.valueList[index]
    }), _c("span", [_c("span", {
      staticClass: "course-name"
    }, _vm._l(item.name, function (char, charIndex) {
      return _c("em", {
        key: charIndex,
        class: {
          highlight: _vm.searchValue.includes(char)
        }
      }, [_vm._v(_vm._s(char))]);
    }), 0), _vm._t("value-list-item-suffix-description", function () {
      return [item.preNode ? _c("span", [_vm._v(_vm._s(item.preNode.name))]) : _vm._e()];
    }, {
      nodeData: _vm.valueList[index]
    })], 2), _vm._t("suffix-icon", null, {
      nodeData: _vm.valueList[index]
    })], 2);
  }), !_vm.valueList.length && !_vm.loading ? _vm._t("search-list-empty", function () {
    return [_c("span", {
      staticClass: "empty"
    }, [_vm._v("暂无数据")])];
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;