"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScoreCorrectionContent = _interopRequireDefault(require("./ScoreCorrectionContent"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeScoreCorrectionRightPart',
  components: {
    ScoreCorrectionContent: _ScoreCorrectionContent.default
  },
  props: {
    activeTabName: {
      type: String,
      default: ''
    },
    activeTabKnowledgeId: {
      type: [Number, String],
      default: ''
    },
    scoreCorrectionContentList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      correctScoreParamsMap: {}
    };
  },
  computed: {
    saveButtonState() {
      return JSON.stringify(this.correctScoreParamsMap) !== '{}';
    }
  },
  watch: {
    activeTabKnowledgeId() {
      this.correctScoreParamsMap = {};
    }
  },
  methods: {
    async postCorrectTemplateScore(data) {
      const res = await (0, _businessIntelligence.postCorrectTemplateScore)(data);
      if (res.code === 200) {
        this.$message.success('保存成功');
        this.correctScoreParamsMap = {};
        this.$emit('correctScoreSuccess');
      }
    },
    handleScoreChange(val) {
      this.correctScoreParamsMap = {
        ...this.correctScoreParamsMap,
        ...val
      };
    },
    noChangeSave: (0, _commonFunc.debounce)(function () {
      this.$message.warning('没有修改数据，无法保存');
    }, 1000),
    scoreParamsMapToArr() {
      const res = [];
      for (const item in this.correctScoreParamsMap) {
        res.push({
          id: Number(item),
          score: this.correctScoreParamsMap[item]
        });
      }
      return res;
    },
    saveCorrectScore() {
      this.$confirm('是否保存已矫正的分数？系统将在 0:00 点统一对已保存数据进行重跑实现全局应用。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const templates = this.scoreParamsMapToArr();
        this.postCorrectTemplateScore({
          knowledge_id: this.activeTabKnowledgeId,
          templates
        });
      });
    }
  }
};
exports.default = _default;