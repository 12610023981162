"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dialog-text"
  }, [_c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.audio && _vm.audio.length > 0,
      expression: "audio && audio.length > 0"
    }],
    ref: "contWrapper",
    staticClass: "dialog-wrapper",
    on: {
      mousedown: _vm.handleMouseScroll,
      mousewheel: _vm.handleMouseWheel,
      scroll: _vm.handleScroll
    }
  }, [_c("el-checkbox-group", {
    attrs: {
      value: _vm.snippetCheckList
    },
    on: {
      change: _vm.handleCheckBoxChange,
      input: _vm.handleCheckBoxInput
    }
  }, _vm._l(_vm.sliceSentence, function (item) {
    return _c("li", {
      key: item.order,
      ref: "dialogItem" + item.order,
      refInFor: true,
      class: {
        "is-sales": _vm.isSales(item),
        "is-show-avatar": item.isShowAvatar,
        "selected-sentence-of-hit-key-event": _vm.hitKeyEventSelectedSentence.includes(item.order)
      }
    }, [_vm.isSnippetStatus ? _c("snippet-check-box", {
      key: item.order,
      attrs: {
        label: item.order,
        "check-list": _vm.snippetCheckList,
        "is-show-avatar": item.isShowAvatar
      }
    }) : _vm._e(), _c("div", {
      staticClass: "content-box"
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.isShowAvatar,
        expression: "item.isShowAvatar"
      }],
      staticClass: "label",
      class: {
        reverse: _vm.isSales(item)
      }
    }, [_c("div", {
      staticClass: "name",
      class: {
        reverse: _vm.isSales(item)
      }
    }, [_c("div", {
      class: {
        reverse: _vm.isSales(item)
      },
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("image-avatar", {
      attrs: {
        avatar: item.avatar,
        name: item.name,
        type: item.entity_type
      }
    }), _vm.isShowName(item) ? _c("span", {
      staticClass: "name-text"
    }, [_vm._v(_vm._s(item.name || "未知姓名"))]) : _vm._e(), _vm.mediaType == "doc" ? _c("span", {
      staticClass: "text-time"
    }, [_vm._v(_vm._s(_vm.$moment(item.begin_time * 1000).format("YYYY-MM-DD HH:mm")))]) : _vm._e()], 1)])]), _c("div", {
      staticClass: "content-row"
    }, [_c("el-checkbox", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showAddKeyEventTrackerPopover,
        expression: "showAddKeyEventTrackerPopover"
      }],
      class: ["hit-key-event-check-box", {
        show: _vm.showAddKeyEventTrackerPopover
      }],
      attrs: {
        label: item.order
      }
    }), _c("div", {
      ref: "contWrap" + item.order,
      refInFor: true,
      class: ["cont-wrap", {
        reverse: _vm.isSales(item)
      }]
    }, [_c("div", {
      staticClass: "content",
      class: {
        active: item.order === _vm.curorder.value && _vm.curorder.time !== "click",
        chosen: _vm.selectedSentenceOfComment === item,
        "is-audio": item.msg_type
      },
      attrs: {
        "data-sign": item.order,
        "data-v-step": `conversation-detail-content-${item.order}`
      },
      on: {
        click: function ($event) {
          return _vm.handleClickContent(item);
        }
      }
    }, [_vm.sentenceOfEventTagMap[item.order] && _vm.sentenceOfEventTagMap[item.order].length && _vm.isShowEventTag ? _c("div", {
      staticClass: "key-event-tag-list"
    }, _vm._l(_vm.sentenceOfEventTagMap[item.order], function (keyEventTag) {
      return _c("div", {
        key: keyEventTag.event_engine_id,
        class: ["key-event-tag-item", {
          "selected-key-event-tag-item": _vm.selectKeyEventTagKey === String(keyEventTag.event_engine_id) + item.order
        }],
        on: {
          click: function ($event) {
            return _vm.handleKeyEventTagSelected(keyEventTag, item);
          }
        }
      }, [_c("span", [_vm._v(_vm._s(keyEventTag.event_name))]), _c("el-popconfirm", {
        attrs: {
          "confirm-button-text": "删除",
          "cancel-button-text": "取消",
          icon: "iconfont icon-warning",
          title: "确定删除此关键事件命中文本吗?",
          "popper-class": "key-event-detail-list-popper"
        },
        on: {
          onConfirm: function ($event) {
            return _vm.deleteHitKeyEventTracker(keyEventTag, item);
          }
        }
      }, [_c("i", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.canEditKeyEvent && _vm.hasCorrectTextPermission && _vm.selectKeyEventTagKey === String(keyEventTag.event_engine_id) + item.order,
          expression: "\n                          canEditKeyEvent &&\n                          hasCorrectTextPermission &&\n                          selectKeyEventTagKey ===\n                            String(keyEventTag.event_engine_id) + item.order\n                        "
        }],
        staticClass: "iconfont icon-close-small",
        attrs: {
          slot: "reference"
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
          }
        },
        slot: "reference"
      })])], 1);
    }), 0) : _vm._e(), _c("div", {
      staticClass: "content-sentence"
    }, [item.msg_type === "voice" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_c("div", {
      staticClass: "voice"
    }, [item.src ? _c("div", {
      staticClass: "voice-box"
    }, [_c("span", [_vm._v(_vm._s(Math.ceil(item.duration)) + '"')]), _c("svg-icon", {
      attrs: {
        "icon-class": _vm.isPlay === item.order + 1 ? "voice-animation" : "voice"
      }
    })], 1) : _vm._e(), _c("p", {
      class: {
        "voice-text": item.src
      }
    }, [_vm._v(" " + _vm._s(item.content) + " ")])])]) : item.msg_type === "file" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" [发来一个文件：" + _vm._s(item.content) + "] ")]) : item.msg_type === "location" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" [发来一个位置：" + _vm._s(item.content) + "] ")]) : item.msg_type === "link" ? _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [item.src ? _c("el-link", {
      attrs: {
        type: "primary",
        underline: false,
        href: item.src,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.content) + " ")]) : _c("span", [_vm._v(_vm._s(item.content))])], 1) : _c("div", {
      attrs: {
        "data-sign": item.order
      }
    }, [_vm._v(" " + _vm._s(item.content) + " ")])])]), item.msg_type === "reference" ? _c("div", {
      staticClass: "content reference-container"
    }, [_c("div", {
      staticClass: "reference"
    }, [_vm._v(" " + _vm._s(item.reference_content) + " ")])]) : _vm._e(), !_vm.isPure ? _c("div", {
      staticClass: "operate-item"
    }, [_vm.mediaType !== "doc" && _vm.hasVideo ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "播放",
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      on: {
        click: function ($event) {
          return _vm.goVideo(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-video-play"
    })])]) : _vm._e(), _vm.hasCorrectTextPermission && (_vm.mediaType !== "doc" || _vm.$route.path === _vm.ROUTES.libraryDetail) ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "修改文字",
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      on: {
        click: function ($event) {
          return _vm.openCorrectTextDialog(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-edit-outline"
    })])]) : _vm._e(), _vm.canEditKeyEvent && _vm.hasCorrectTextPermission ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "命中关键事件",
        placement: "top"
      }
    }, [_c("div", {
      class: ["hover-play", {
        "add-key-event-btn-disabled": _vm.currentOperateName === "snippet"
      }],
      attrs: {
        id: "addKeyEventTrackerBtn"
      },
      on: {
        click: function ($event) {
          return _vm.addKeyEventTracker($event, item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-key-event"
    })])]) : _vm._e(), _vm.hasCorrectTextPermission && _vm.$route.path !== _vm.ROUTES.libraryDetail && !_vm.isDocGroup ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "翻转角色",
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      on: {
        click: function ($event) {
          return _vm.flipRole(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-switch-roles"
    })])]) : _vm._e(), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "评论",
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "hover-play",
      on: {
        click: function ($event) {
          return _vm.handleSelectedCommentSentence(item);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-chat-line-square"
    })])])], 1) : _vm._e()])], 1)])], 1);
  }), 0), _c("AddKeyEventTrackerPopover", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAddKeyEventTrackerPopover,
      expression: "showAddKeyEventTrackerPopover"
    }, {
      name: "drag",
      rawName: "v-drag"
    }],
    ref: "addKeyEventTrackerPopover",
    class: ["add-key-event-tracker-popover", {
      show: _vm.showAddKeyEventTrackerPopover
    }],
    attrs: {
      id: "addKeyEventTrackerPopover",
      "data-has-user-drag": 0,
      "event-options": _vm.eventOptions,
      "show-add-key-event-tracker-popover": _vm.showAddKeyEventTrackerPopover
    },
    on: {
      handleCancelAddKeyEventTracker: _vm.handleCancelAddKeyEventTracker,
      handleSubmitAddEventTracker: _vm.handleSubmitAddEventTracker
    }
  }), !_vm.isPure ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_user_scroll && _vm.mediaType !== "doc" && _vm.hasVideo,
      expression: "is_user_scroll && mediaType !== 'doc' && hasVideo"
    }],
    staticClass: "back-playing-content"
  }, [_c("div", {
    staticClass: "back-playing",
    on: {
      click: _vm.backPlaying
    }
  }, [_c("svg-icon", {
    staticClass: "backplay",
    attrs: {
      "icon-class": "backplay"
    }
  }), _c("span", [_vm._v("回到当前播放点")])], 1)]) : _vm._e()], 1), !(_vm.audio || _vm.isEsInsertComplete) ? _c("div", {
    staticClass: "is-loading"
  }, [_c("mgv-progress-loading", {
    attrs: {
      text: "正在努力生成中...",
      "stroke-width": 18
    }
  })], 1) : _vm._e(), _vm.audio && _vm.audio.length === 0 ? _c("NoData", {
    attrs: {
      tips: "暂无转录文字",
      size: "120"
    }
  }) : _vm._e(), _c("sumbit-dialog", {
    attrs: {
      "modify-arr": _vm.modifyArr,
      "active-round-id": _vm.activeRoundId,
      "audio-list": _vm.audioList,
      "sumbit-visible": _vm.sumbitVisible
    },
    on: {
      changeSumbitVisible: _vm.changeSumbitVisible
    }
  }), _c("correct-text-dialog", {
    attrs: {
      "correct-text-dialog-visible": _vm.correctTextDialogVisible,
      "correct-text-item": _vm.correctTextItem
    },
    on: {
      closeCorrectTextDialog: _vm.closeCorrectTextDialog,
      correctTextSuccess: _vm.correctTextSuccess
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;