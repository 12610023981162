"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "standard-tricks"
  }, [_c("item-template", {
    attrs: {
      "custom-title": "已选话术",
      type: "tricks"
    }
  }, [_vm.list.length ? _c("span", {
    staticClass: "trick-count",
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("(" + _vm._s(_vm.list.length) + ")")]) : _vm._e(), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.formData
    }
  }, [_c("div", {
    staticClass: "scene-select"
  }, [_c("p", {
    staticClass: "tip"
  }, [_vm._v("选择要保存的场景分类")]), _c("el-form-item", {
    attrs: {
      prop: "scene",
      rules: [{
        required: true,
        message: "请选择场景分类",
        trigger: "change"
      }]
    }
  }, [_c("el-select", {
    attrs: {
      "popper-class": "standard-tricks-popper",
      placeholder: "请选择场景"
    },
    on: {
      "visible-change": _vm.handleVisibleChange
    },
    model: {
      value: _vm.formData.scene,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "scene", $$v);
      },
      expression: "formData.scene"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-box-square"
  })]), _vm._l(_vm.sceneList, function (item) {
    return _c("el-option", {
      key: item.scene_id,
      attrs: {
        label: item.content,
        value: item.scene_id
      }
    });
  }), _c("div", {
    staticClass: "create-scene"
  }, [_c("div", {
    staticClass: "left-part"
  }, [_c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditingScene,
      expression: "isEditingScene"
    }],
    ref: "sceneInput",
    attrs: {
      placeholder: "最多输入200字",
      maxlength: "200"
    },
    model: {
      value: _vm.sceneName,
      callback: function ($$v) {
        _vm.sceneName = $$v;
      },
      expression: "sceneName"
    }
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEditingScene,
      expression: "!isEditingScene"
    }],
    staticClass: "holder",
    on: {
      click: _vm.editScene
    }
  }, [_c("i", {
    staticClass: "iconfont icon-plus"
  }), _vm._v("添加场景")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditingScene,
      expression: "isEditingScene"
    }],
    staticClass: "operate-part"
  }, [_c("el-button", {
    staticClass: "mg-round-button",
    on: {
      click: _vm.cancelEditScene
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-round-button",
    attrs: {
      disabled: !_vm.sceneName,
      type: "primary"
    },
    on: {
      click: _vm.createScene
    }
  }, [_vm._v("添加")])], 1)])], 2)], 1)], 1), _c("div", {
    staticClass: "tricks-container"
  }, _vm._l(_vm.formData.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "trick-item"
    }, [_c("el-form-item", {
      attrs: {
        prop: `list.${index}.value`,
        rules: [{
          required: true,
          message: "请填写内容",
          trigger: "change"
        }, {
          max: 1000,
          message: "长度不能超过1000个字符",
          trigger: "change"
        }]
      }
    }, [_c("trick-item", {
      attrs: {
        "is-edit": "",
        "conv-info": item
      },
      on: {
        textChange: function ($event) {
          return _vm.handleTextChange($event, index);
        }
      }
    })], 1), _c("div", {
      staticClass: "operate-btn",
      on: {
        click: function ($event) {
          return _vm.deleteItem(index);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-close"
    })])], 1);
  }), 0)])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;