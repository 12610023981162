"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "collapse-tab"
  }, [_c("div", {
    staticClass: "collapse-title",
    on: {
      click: function ($event) {
        _vm.fold = !_vm.fold;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-caret-bottom",
    class: _vm.fold ? "fold" : ""
  }), _c("span", [_vm._v(_vm._s(_vm.title))])]), _c("ul", {
    staticClass: "tab-container",
    class: _vm.fold ? "tabs-fold" : ""
  }, _vm._l(_vm.tabArray, function (item, index) {
    return _c("li", {
      key: index,
      class: ["tab-item", item.value === _vm.activeValue ? "active" : ""],
      on: {
        click: function ($event) {
          return _vm.tabClick(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;