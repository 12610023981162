"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_vm.contactPersonList && _vm.contactPersonList.length ? _c("mg-infinite-scroll", {
    staticClass: "contact-content",
    attrs: {
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.contactPersonList, function (item, index) {
    return _c("contact-person-card", {
      key: index,
      staticClass: "information-card",
      attrs: {
        value: item,
        "form-desc": _vm.descContactFormat(_vm.contactDynamicFormNoAccountList, false),
        "is-show": false
      }
    });
  }), 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;