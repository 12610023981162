"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BackBtn = _interopRequireDefault(require("./BackBtn"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PreAppoint',
  components: {
    BackBtn: _BackBtn.default
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  }
};
exports.default = _default;