"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'TreeContainer',
  props: {
    popoverVisible: {
      type: Boolean,
      default: false
    },
    requestParams: {
      type: [Object, String],
      default: () => ({})
    },
    requestMethod: {
      require: true,
      default: () => ({}),
      type: Function
    },
    setCheckedRootNode: {
      type: Boolean,
      default: false
    },
    selectedNotExpandable: {
      type: Boolean,
      default: false
    }
  },
  // 给vue实例上添加hasCache，cacheResult属性用于组件多次使用时缓存请求数据
  privateData: {
    hasCache: false,
    cacheResult: null
  },
  data() {
    return {
      departmentTreeNode: null,
      orgTreeData: [],
      defaultProps: {
        // 树形控件配置选项
        children: 'children',
        label: 'name'
      },
      defaultExpandedKeys: [],
      defaultChecked: []
    };
  },
  watch: {
    popoverVisible(val) {
      val && this.updateVirualListOffset();
    }
  },
  created() {
    if (this.$options.privateData.hasCache) {
      this.handleRequestResults(this.$options.privateData.cacheResult);
    } else {
      this.getOrgTreeOptions();
    }
  },
  mounted() {
    this.departmentTreeNode = this.$refs['department-tree'];
  },
  beforeDestroy() {
    this.$options.privateData.hasCache = false;
  },
  methods: {
    updateVirualListOffset() {
      var _this$departmentTreeN;
      const virualListElement = (_this$departmentTreeN = this.departmentTreeNode) === null || _this$departmentTreeN === void 0 ? void 0 : _this$departmentTreeN.$children[0];
      virualListElement && virualListElement.scrollToOffset(virualListElement.range.padFront);
    },
    // 获取部门树数据并设置默认选中根节点
    async getOrgTreeOptions() {
      this.$options.privateData.hasCache = true;
      this.$options.privateData.cacheResult = this.requestMethod(this.requestParams);
      await this.handleRequestResults(this.$options.privateData.cacheResult);
    },
    handleRequestResults(results) {
      results.then(res => {
        if ([200, 20000].includes(res.code)) {
          this.orgTreeData = Array.isArray(res.results) ? res.results : res.results.list;
          this.addPreNodeLable();
          if (this.orgTreeData.length) {
            if (this.setCheckedRootNode) {
              this.handlerCheckedRootNode(this.orgTreeData[0]);
            } else {
              this.defaultExpandedKeys = [this.orgTreeData[0].id];
            }
          }
        }
      });
    },
    addPreNodeLable() {
      let node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.orgTreeData;
      let preNode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        name: '',
        id: 0
      };
      node.forEach(item => {
        var _item$children;
        item.preNode = preNode;
        if ((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
          this.addPreNodeLable(item.children, {
            name: item.name,
            id: item.id
          });
        }
      });
    },
    handlerCheckedRootNode(node) {
      this.defaultChecked = [node.id];
      this.handleCheckChange(node);
    },
    handleCheckChange(currentData, isThrowData) {
      if (!currentData) return;
      if (this.selectedNotExpandable) {
        this.handleCheckedNodeExpanded(currentData, isThrowData);
      } else {
        this.getCheckedNodeData();
      }
    },
    emitData(data, isThrowData) {
      this.$emit('checkedChange', data, isThrowData);
    },
    getCheckedNodeData() {
      const checkedNodes = [];
      this.recursionTreeNode(child => {
        if (child.checked) {
          checkedNodes.push(child.data);
        }
      });
      this.$nextTick(() => {
        this.emitData(checkedNodes, true);
      });
    },
    // 递归树
    recursionTreeNode(callback) {
      // 获取树的配置属性信息
      const store = this.departmentTreeNode.store;
      const traverseTreeNode = node => {
        // 获取根节点，从根节点开始递归，将全选的节点加入到选中项数组中
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          callback(child);
          // 判断节点的子树中是否有被选中的节点
          if (child.indeterminate) {
            traverseTreeNode(child);
          }
        });
      };
      traverseTreeNode(store);
    },
    // 选中项不可展开
    handleCheckedNodeExpanded(data) {
      let isThrowData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.$nextTick(() => {
        var _node$data$children2;
        const node = this.departmentTreeNode.getNode(data);
        if (node.checked) {
          var _node$data$children;
          node.expanded = false;
          if ((_node$data$children = node.data.children) !== null && _node$data$children !== void 0 && _node$data$children.length) {
            this.unCheckChildNodes(node);
            this.handleExpandedTooltip(data.id, 'add');
          }
        } else if ((_node$data$children2 = node.data.children) !== null && _node$data$children2 !== void 0 && _node$data$children2.length) {
          this.handleExpandedTooltip(data.id, 'remove');
        }
        this.$nextTick(() => {
          this.emitData(this.departmentTreeNode.getCheckedNodes(), isThrowData);
        });
      });
    },
    // 取消选中所有子节点
    unCheckChildNodes(currentNode) {
      if (currentNode.childNodes.length) {
        currentNode.childNodes.forEach(item => {
          var _item$data$children;
          item.checked = false;
          item.isLeaf = !((_item$data$children = item.data.children) !== null && _item$data$children !== void 0 && _item$data$children.length);
          this.handleExpandedTooltip(item.id, 'remove');
          if (item.childNodes.length) {
            this.unCheckChildNodes(item);
          }
        });
      }
    },
    handleExpandedTooltip(id, fnName) {
      this.$nextTick(() => {
        const currentDom = document.getElementsByClassName(`custom-node-${id}`);
        if (currentDom.length) {
          const expandDom = currentDom[0].parentNode.getElementsByClassName('el-tree-node__expand-icon')[0];
          expandDom.classList[fnName]('close');
        }
      });
    },
    handleTag(currentNode, isChecked) {
      this.departmentTreeNode.setChecked(currentNode, isChecked, true);
      // 如果选中项不可展开则删除后可展开
      this.handleCheckChange(currentNode);
    },
    backFillData(ids) {
      if (!ids.length) {
        this.departmentTreeNode.setCheckedKeys([]);
        this.emitData([]);
        return;
      }
      this.departmentTreeNode.setCheckedKeys(ids);
      this.$options.privateData.cacheResult.then(() => {
        this.$nextTick(() => {
          this.departmentTreeNode.getCheckedNodes().forEach(item => {
            this.handleCheckChange(item, false);
          });
        });
      });
    }
  }
};
exports.default = _default;