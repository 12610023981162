"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'PromptExampleDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'usePromptTemplate'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = () => {
      emit('update:visible', false);
    };
    const usePromptTemplate = () => {
      emit('usePromptTemplate', `你是一名销售，与客户的对话文本如下："""{text}"""， #这里替换为：数据集标签（或事件）描述定义、和期望大语言模型如何判断。\n最终结果以JSON格式返回，示例格式如下:\n{ "关键事件": "", "关键事件对话上下文": [""] }`);
      updateVisible();
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      usePromptTemplate
    };
  }
};
exports.default = _default;