"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _qiyeweixin = _interopRequireDefault(require("@/assets/qiyeweixin.jpg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DetailInfo',
  components: {
    ImageAvatar: _ImageAvatar.default
  },
  props: {
    info: {
      // 传递进来的数据
      // info :{ name:"",subLabel:""，subValue:"",avatarType:"customer_contact",type:'account | deal | clues' }
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      weChatImg: _qiyeweixin.default
    };
  }
};
exports.default = _default;