"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'HeaderTitle',
  props: {
    knowledge: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    showAnswerSheet() {
      this.$emit('showAnswerSheet');
    },
    goCoachTodo() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;