"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.globalErrorHandler = globalErrorHandler;
exports.postErrorLog = postErrorLog;
exports.postMeetingInfoLog = postMeetingInfoLog;
exports.setMeetingInfoLog = setMeetingInfoLog;
var _log = require("@/api/log");
var _es6Promise = require("es6-promise");
var _moment = _interopRequireDefault(require("moment"));
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import {errorLog} from "@/api/log"
var _default = {
  install(Vue) {
    Vue.config.errorHandler = (error, vm, mes) => {
      const componentName = formatComponentName(vm, false);
      const trace = vm ? generateComponentTrace(vm) : '';
      let infoStr = '';
      infoStr = '|componentName:' + componentName + '|trace:' + trace + '|vueInfo:' + mes + '|error:' + error.stack || error;
      console.error(error);
      postErrorLog('vue components on error', infoStr);
      // 错误上报到收集报错的平台
    };
  }
}; // 获取组件名
exports.default = _default;
const formatComponentName = (vm, includeFile) => {
  const ROOT_COMPONENT_NAME = '<Root>';
  const ANONYMOUS_COMPONENT_NAME = '<Anonymous>';
  const classifyRE = /(?:^|[-_])(\w)/g;
  const classify = str => str.replace(classifyRE, c => c.toUpperCase()).replace(/[-_]/g, '');
  if (!vm) {
    return ANONYMOUS_COMPONENT_NAME;
  }
  if (vm.$root === vm) {
    return ROOT_COMPONENT_NAME;
  }
  const options = vm.$options;
  let name = options.name || options._componentTag;
  const file = options.__file;
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/);
    if (match) {
      name = match[1];
    }
  }
  return (name ? `<${classify(name)}>` : ANONYMOUS_COMPONENT_NAME) + (file && includeFile !== false ? ` at ${file}` : ``);
};
function generateComponentTrace(vm) {
  var _vm, _vm2, _vm3;
  if (((_vm = vm) !== null && _vm !== void 0 && _vm._isVue || (_vm2 = vm) !== null && _vm2 !== void 0 && _vm2.__isVue) && (_vm3 = vm) !== null && _vm3 !== void 0 && _vm3.$parent) {
    const tree = [];
    let currentRecursiveSequence = 0;
    while (vm) {
      if (tree.length > 0) {
        const last = tree[tree.length - 1];
        if (last.constructor === vm.constructor) {
          currentRecursiveSequence += 1;
          vm = vm.$parent; // eslint-disable-line no-param-reassign
          continue;
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence];
          currentRecursiveSequence = 0;
        }
      }
      tree.push(vm);
      vm = vm.$parent; // eslint-disable-line no-param-reassign
    }

    const formattedTree = tree.map((vm, i) => `${(i === 0 ? '---> ' : repeat(' ', 5 + i * 2)) + (Array.isArray(vm) ? `${formatComponentName(vm[0])}... (${vm[1]} recursive calls)` : formatComponentName(vm))}`).join('\n');
    return `\n\nfound in\n\n${formattedTree}`;
  }
  return `\n\n(found in ${formatComponentName(vm)})`;
}
function repeat(str, n) {
  let res = '';
  while (n) {
    if (n % 2 === 1) {
      res += str;
    }
    if (n > 1) {
      str += str;
    }
    n >>= 1;
  }
  return res;
}

/**
 *
 * @param {*} title  表示错误标题
 * @param {*} error  错误信息
 * @param {*} logType 日志类型  error, warning, info
 *  nowTime 时间
 *  url  当前url
 *  brower 当前浏览器
 *  errorStr 错误字符串
 *
 */

// 发送请求
function postErrorLog() {
  let title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  let logType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'error';
  if (location.hostname === 'localhost') return;
  const nowTime = (0, _moment.default)().format('llll');
  const errorStr = JSON.stringify(error);
  const url = window.location.href;
  const browser = navigator.userAgent.toLowerCase();
  const userInfo = 'userId:' + _store.default.getters.userId + '| userName:' + _store.default.getters.name + '| orgId:' + _store.default.getters.orgId + '|' + 'orgName:' + _store.default.getters.orgName + '|';
  const str = nowTime + '--title:' + title + '--type:' + logType + '--url:' + url + ' --userInfo:' + userInfo + ' --browser:' + browser + 'content:' + errorStr;
  console.log('postErrorLog', title, error, logType);
  (0, _log.errorLog)(logType, str);
}
function globalErrorHandler(message, url, lineNo, columnNo, error) {
  const infoStr = `错误原因：${message}\n错误URL: ${url}\n错误行号: ${lineNo}\n错误详情: ${error === null || error === void 0 ? void 0 : error.stack}`;
  // 一些可忽略的报错，不上报
  const ignorableError = ["Uncaught SyntaxError: Unexpected token '<'", 'unhandledrejection', 'ResizeObserver loop', 'cancel', 'Network Error'];
  const containsIgnorableError = ignorableError.some(ignorableError => message.includes(ignorableError));
  const logType = containsIgnorableError ? 'warning' : 'error';
  window.log('globalErrorHandler', infoStr, logType);
  postErrorLog('js global monitoring on error', infoStr, logType);
}

// 存本地视频过程的日志
function setMeetingInfoLog() {
  let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const nowTime = (0, _moment.default)().format('llll');
  const contentStr = JSON.stringify(content);
  const url = window.location.href;
  const browser = navigator.userAgent.toLowerCase();
  const str = nowTime + ': --type:' + type + '--content:' + contentStr + '--url:' + url + ' --browser:' + browser;
  const logArray = sessionStorage.getItem('postMeetinglog') ? JSON.parse(sessionStorage.getItem('postMeetinglog')) : [];
  logArray.push(str);
  sessionStorage.setItem('postMeetinglog', JSON.stringify(logArray));
}

// 上报视频过程的日志
function postMeetingInfoLog(logStrs) {
  return new _es6Promise.Promise((resolve, reject) => {
    (0, _log.errorLog)('info', logStrs).then(() => {
      resolve();
    }).catch(() => {
      resolve();
    });
  });
}