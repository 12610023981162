"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _videoDetail = require("@/api/videoDetail");
var _default2 = _interopRequireDefault(require("@/assets/default.svg"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ShareConv',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MembersFinder: _MembersFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    convId: {
      type: String || Number,
      default: null
    },
    inTable: {
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shareValue: [],
      // 分享input绑定
      shareOptions: [],
      // 处理后的检索结果
      shareList: [],
      // 初步检索结果
      loading: false,
      shareInitList: [],
      users: [],
      sharedList: [],
      shareAll: false,
      defaultPhoto: _default2.default,
      submitLoading: false
    };
  },
  watch: {
    visible(val) {
      if (val == true) {
        // 弹窗弹出时，禁用页面滚动
        this.getSharedList();
        document.body.style.overflowY = 'hidden';
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['curconvid', 'avatar'])
  },
  methods: {
    handleSelectChange(data) {
      this.shareValue = data;
    },
    // 取消分享视频
    cancelShare() {
      if (this.inTable) {
        this.$emit('closeShareDialog');
      } else {
        this.$emit('update:visible', false);
      }
      this.shareValue = '';
      this.shareList = [];
      this.shareOptions = [];
      this.shareInitList = [];
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          // 调用检索列表信息接口
          (0, _videoDetail.getShareNames)(query).then(res => {
            this.shareList = res.results;
            res.results.forEach(item => {
              this.shareInitList.push(item);
            });
            this.shareOptions = this.shareList.map(item => {
              this.$set(item, 'option', item.name + ' ' + item.tel);
              return item;
            });
            this.loading = false;
          }).catch(res => {
            this.options = [];
          });
        }, 200);
      } else {
        this.list = [];
        this.options = [];
      }
    },
    focusMethod() {
      this.loading = true;
      const query = '';
      (0, _videoDetail.getShareNames)(query).then(res => {
        this.shareList = res.results;
        res.results.forEach(item => {
          this.shareInitList.push(item);
        });
        this.shareOptions = this.shareList.map(item => {
          this.$set(item, 'option', item.name + ' ' + item.tel);
          return item;
        });
        this.loading = false;
      });
      this.loading = false;
    },
    // 确定分享视频
    shareEnter() {
      const deleteShared = [];
      if (this.shareValue.length) {
        this.sharedList.filter(item => {
          if (this.shareValue.includes(item.user_id)) {
            deleteShared.push(item.name);
            const index = this.shareValue.indexOf(item.user_id);
            if (index !== -1) this.shareValue.splice(index, 1);
          }
        });
        // return;
        if (this.shareValue.length === 0) {
          this.$message.error(deleteShared + '已被分享');
          return;
        } else {
          const id = this.convId;
          this.submitLoading = true;
          // 调用视频分享接口
          (0, _videoDetail.shareInterview)(id, this.shareValue).then(res => {
            if (res.code === 20000) {
              let message = '已成功分享' + this.shareValue.length + '人';
              if (deleteShared.length > 0) {
                message = message + ', ' + deleteShared + '已被分享';
              }
              this.$message({
                message: message,
                center: true,
                type: 'success'
              });
              // this.visible = false
              this.shareValue = [];
              this.shareList = [];
              this.shareOptions = [];
              this.shareInitList = [];
              this.$refs.selectMembers.selection = [];
              this.getSharedList();
            }
            this.submitLoading = false;
          });
        }
      } else {
        this.$message({
          message: '请输入被分享人的姓名',
          center: true,
          type: 'warning'
        });
      }
    },
    getSharedList() {
      (0, _videoDetail.getSharedList)(this.convId).then(res => {
        if (res.code === 20000) {
          this.sharedList = res.results.shared_list;
          this.shareAll = !!res.results.is_public;
        }
      });
    },
    delOneSharedUser(user) {
      this.$confirm(`是否取消对 ${user.name} 的分享`, '取消分享', {
        confirmButtonText: '确 认',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '返 回',
        type: 'warning'
      }).then(() => {
        (0, _videoDetail.delOneSharedUser)(this.convId, user.user_id).then(res => {
          if (res.code === 20000) {
            this.getSharedList();
          }
        });
      }).catch(() => {});
    },
    switchChange(val) {
      (0, _videoDetail.shareToAll)(this.convId, val).then(res => {});
      this.$emit('changePublic', val);
    }
  }
};
exports.default = _default;