"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assignLibraryStudy = assignLibraryStudy;
exports.cancelLibraryStudy = cancelLibraryStudy;
exports.colletFolder = colletFolder;
exports.colletSnippet = colletSnippet;
exports.commentSnippet = commentSnippet;
exports.correctSnippetText = correctSnippetText;
exports.createCovnFlow = createCovnFlow;
exports.createFolder = createFolder;
exports.createSnippet = createSnippet;
exports.deleteConvFlow = deleteConvFlow;
exports.deleteVideos = deleteVideos;
exports.deteleFolds = deteleFolds;
exports.discollectFolder = discollectFolder;
exports.discollectSnippet = discollectSnippet;
exports.dislikeFolder = dislikeFolder;
exports.dislikeSnippet = dislikeSnippet;
exports.editCovnFlow = editCovnFlow;
exports.editFolderAccess = editFolderAccess;
exports.editSnippet = editSnippet;
exports.editSnippetComment = editSnippetComment;
exports.getConvFlowDetail = getConvFlowDetail;
exports.getConvFlowRule = getConvFlowRule;
exports.getConvFlowSubfolders = getConvFlowSubfolders;
exports.getFolderChildren = getFolderChildren;
exports.getFolderTree = getFolderTree;
exports.getFoldersRankingList = getFoldersRankingList;
exports.getFolds = getFolds;
exports.getLibraryStudyAssignList = getLibraryStudyAssignList;
exports.getLibraryStudyCount = getLibraryStudyCount;
exports.getLibraryStudyList = getLibraryStudyList;
exports.getMediaList = getMediaList;
exports.getMyCollectConvFlow = getMyCollectConvFlow;
exports.getMyCollectFolders = getMyCollectFolders;
exports.getMyCollectMediaList = getMyCollectMediaList;
exports.getMyCollectSnippets = getMyCollectSnippets;
exports.getParentFolderPermission = getParentFolderPermission;
exports.getSnipAtableUsers = getSnipAtableUsers;
exports.getSnipComments = getSnipComments;
exports.getSnipFilterVisiableUsers = getSnipFilterVisiableUsers;
exports.getSnippetDetailInfo = getSnippetDetailInfo;
exports.getSnippetSearch = getSnippetSearch;
exports.getSnippetSentences = getSnippetSentences;
exports.getSnippetsRankingList = getSnippetsRankingList;
exports.getTopPerformers = getTopPerformers;
exports.getVideos = getVideos;
exports.libraryStudyTime = libraryStudyTime;
exports.likeFolder = likeFolder;
exports.likeSnippet = likeSnippet;
exports.moveAllSnippetsToFolder = moveAllSnippetsToFolder;
exports.moveFolderToFolder = moveFolderToFolder;
exports.moveSnippetToFolder = moveSnippetToFolder;
exports.openFolder = openFolder;
exports.postSnippetPlayCount = postSnippetPlayCount;
exports.removeConversationFromConvFlow = removeConversationFromConvFlow;
exports.renameFolder = renameFolder;
exports.snippetsLibrarySearch = snippetsLibrarySearch;
exports.uploadMediaFile = uploadMediaFile;
var _request = require("@/utils/request");
// 创建文件夹
function createFolder(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/standard/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getFolderChildren(params) {
  return (0, _request.request)({
    url: '/library/folder/get_child',
    method: 'get',
    params
  });
}
function openFolder(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/open',
    method: 'get',
    params
  });
}
function moveFolderToFolder(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/move',
    method: 'post',
    data
  });
}
function renameFolder(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/update/name',
    method: 'post',
    data
  });
}
// 移动所有剪辑到文件夹
function moveAllSnippetsToFolder(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/move_all',
    method: 'post',
    data
  });
}

// 移动剪辑到文件夹
function moveSnippetToFolder(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/move',
    method: 'post',
    data
  });
}

// 编辑剪辑片段
function editSnippet(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/edit',
    method: 'post',
    data
  });
}
// 编辑文件夹权限
function editFolderAccess(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/edit_access_user',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 获取上级目录权限
function getParentFolderPermission(params) {
  return (0, _request.request)({
    url: '/library/folder/get_parent_access_user',
    method: 'get',
    params
  });
}
// 创建剪辑
function createSnippet(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/create',
    method: 'post',
    dataType: 'json',
    data
  });
}
function getFolderTree(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/get_tree',
    method: 'get',
    params
  });
}

// 获取剪辑详情页信息
function getSnippetDetailInfo(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/detail',
    method: 'get',
    params: {
      snippet_id
    }
  });
}

// 获取剪辑转录信息
function getSnippetSentences(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/sentences',
    method: 'get',
    params: {
      snippet_id
    }
  });
}

// 搜索剪辑文字
function getSnippetSearch(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/sentences/search',
    method: 'get',
    params
  });
}

/**
 *
 * 剪辑详情页的点赞收藏
 * **/
function likeSnippet(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/like',
    method: 'post',
    data: {
      snippet_id
    }
  });
}
function dislikeSnippet(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/dislike',
    method: 'post',
    data: {
      snippet_id
    }
  });
}
function colletSnippet(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/collect',
    method: 'post',
    data: {
      snippet_id
    }
  });
}
function discollectSnippet(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/discollect',
    method: 'post',
    data: {
      snippet_id
    }
  });
}

// 文件夹页的点赞收藏
function likeFolder(folder_id) {
  return (0, _request.webService)({
    url: '/training/library/folder/like',
    method: 'post',
    data: {
      folder_id
    }
  });
}
function dislikeFolder(folder_id) {
  return (0, _request.webService)({
    url: '/training/library/folder/dislike',
    method: 'post',
    data: {
      folder_id
    }
  });
}
function colletFolder(folder_id) {
  return (0, _request.webService)({
    url: '/training/library/folder/collect',
    method: 'post',
    data: {
      folder_id
    }
  });
}
function discollectFolder(folder_id) {
  return (0, _request.webService)({
    url: '/training/library/folder/discollect',
    method: 'post',
    data: {
      folder_id
    }
  });
}
// 我的收藏
function getMyCollectFolders(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/collect',
    method: 'get',
    params
  });
}
// 我的收藏
function getMyCollectSnippets(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/collect',
    method: 'get',
    params
  });
}
// 获取名人榜单list
function getTopPerformers(params) {
  return (0, _request.webService)({
    url: '/training/library/top_performers/get',
    method: 'get',
    params
  });
}

// 排行榜剪辑list
function getSnippetsRankingList(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/ranking',
    method: 'get',
    params
  });
}

// 排行榜文件夹list
function getFoldersRankingList(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/ranking',
    method: 'get',
    params
  });
}

// 上报用户点击剪辑视频的内容
function postSnippetPlayCount(snippet_id) {
  return (0, _request.webService)({
    url: '/training/library/snippet/play',
    method: 'post',
    data: {
      snippet_id
    }
  });
}

// 文件夹分页
function getFolds(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/child/folders',
    method: 'get',
    params
  });
}

// 剪辑分页
function getVideos(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/child/snippets',
    method: 'get',
    params
  });
}

// 剪辑删除
function deleteVideos(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/delete',
    method: 'post',
    data
  });
}

// 文件夹删除
function deteleFolds(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/standard/delete',
    method: 'post',
    data
  });
}

// 获取所有评论
function getSnipComments(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/comment/all',
    method: 'get',
    params
  });
}

// 获取可@人员
function getSnipAtableUsers(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/comment/visiable',
    method: 'get',
    params
  });
}

// 搜索可见成员
function getSnipFilterVisiableUsers(params) {
  return (0, _request.webService)({
    url: '/training/library/snippet/comment/views',
    method: 'get',
    params
  });
}

// 发送评论接口
function commentSnippet(data) {
  return (0, _request.request)({
    url: '/library/comment/sender',
    method: 'post',
    dataType: 'json',
    data
  });
}
// 编辑评论
function editSnippetComment(data) {
  return (0, _request.request)({
    url: '/library/comment/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 智能会话流相关接口
// 创建智能会话流
function createCovnFlow(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/smart/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑智能会话流
function editCovnFlow(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/smart/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除智能会话流
function deleteConvFlow(data) {
  return (0, _request.webService)({
    url: '/training/library/folder/smart/delete',
    method: 'post',
    data
  });
}

// 获取我收藏的智能会话流
function getMyCollectConvFlow(params) {
  return (0, _request.request)({
    url: '/library/folder/mycollect_smart',
    method: 'get',
    params
  });
}

// 获取智能会话流规则
function getConvFlowRule(params) {
  return (0, _request.request)({
    url: '/library/folder/get_smart_rule',
    method: 'get',
    params
  });
}

// 获取文件夹的子智能会话流列表
function getConvFlowSubfolders(params) {
  return (0, _request.request)({
    url: '/library/folder/get_child_smarts',
    method: 'get',
    params
  });
}

// 获取智能会话流详情
function getConvFlowDetail(params) {
  return (0, _request.request)({
    url: '/library/folder/smart/detail',
    method: 'get',
    params
  });
}

// 获取智能会话流详情
function removeConversationFromConvFlow(data) {
  return (0, _request.request)({
    url: '/library/folder/smart/remove_conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 上传音视频文件
function uploadMediaFile(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/create/upload',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取文件夹下的音视频列表
function getMediaList(params) {
  return (0, _request.webService)({
    url: '/training/library/folder/child/uploads',
    method: 'get',
    params
  });
}

// 获取收藏的音视频文件
function getMyCollectMediaList(params) {
  return (0, _request.webService)({
    url: '/training/library/upload/collect',
    method: 'get',
    params
  });
}

// 剪辑库模糊搜索
function snippetsLibrarySearch(params) {
  return (0, _request.webService)({
    url: '/training/library/search',
    method: 'get',
    params
  });
}

// 剪辑详情文字修正
function correctSnippetText(data) {
  return (0, _request.webService)({
    url: '/training/library/snippet/sentence/edit',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 剪辑库学习列表
function getLibraryStudyList(params) {
  return (0, _request.webService)({
    url: '/training/library/study/list',
    method: 'get',
    params
  });
}

// 剪辑库指派学习
function assignLibraryStudy(data) {
  return (0, _request.webService)({
    url: '/training/library/study/assign',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 剪辑库取消学习
function cancelLibraryStudy(data) {
  return (0, _request.webService)({
    url: '/training/library/study/cancel',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 剪辑库指派列表
function getLibraryStudyAssignList(params) {
  return (0, _request.webService)({
    url: '/training/library/study/assign_list',
    method: 'get',
    params
  });
}

// 剪辑库我的学习数量
function getLibraryStudyCount(params) {
  return (0, _request.webService)({
    url: '/training/library/study/count',
    method: 'get',
    params
  });
}

// 剪辑库习学统计时长
function libraryStudyTime(data) {
  return (0, _request.webService)({
    url: '/training/library/study/timing',
    method: 'post',
    dataType: 'json',
    data
  });
}