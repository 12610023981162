"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "items-flow"
  }, [_c("ul", {
    staticClass: "flow-items"
  }, [_vm._l(_vm.handleArray, function (item) {
    return [_c("li", {
      key: item.date,
      staticClass: "flow-item"
    }, [_c("p", {
      staticClass: "date-title"
    }, [_vm._v(_vm._s(item.dateText))]), _c("flow-item", {
      attrs: {
        infos: item.infos,
        "customer-name": _vm.customerName
      },
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.selectDate(item.date);
        }
      }
    })], 1)];
  })], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;