"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trade-info"
  }, [_vm._m(0), _c("div", {
    staticClass: "content"
  }, [_c("h1", [_vm._v(_vm._s(_vm.info.trade_name))]), _c("span", {
    staticClass: "ownby-desc"
  }, [_vm._v("跟进人: ")]), _c("span", {
    staticClass: "ownby-name"
  }, [_vm._v(_vm._s(_vm.info.own_by_name || "--"))])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-info-icon"
  }, [_c("i", {
    staticClass: "iconfont icon-deal4"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;