"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AnalysisConfigMixin = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/AnalysisConfigMixin"));
var _commonFunc = require("@/utils/commonFunc");
var _ConfigDialog = _interopRequireDefault(require("./ConfigDialog"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SecondStep',
  components: {
    ConfigDialog: _ConfigDialog.default
  },
  mixins: [_AnalysisConfigMixin.default],
  props: {
    editParams: {
      type: Object,
      default: () => ({})
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      configDialogvisible: false,
      isEdit: false,
      crrentEdit: 0,
      editConfigData: {}
    };
  },
  computed: {
    headerList() {
      const headerList = [{
        prop: 'name',
        field_name: '指标名称'
      }, {
        prop: 'configs.analysis_target_name',
        field_name: '指标类型'
      }, {
        prop: 'configs.analysis_target_key_name',
        field_name: '指标项'
      }];
      if (!this.editDisabled) {
        headerList.push({
          prop: 'operate',
          field_name: '操作'
        });
      }
      return headerList;
    },
    // 指标类型，指标项名称回填映射
    indicatorsOptionsData() {
      const indicatorsOptions = Object.values(this.indicatorsOptions).flat(Infinity).filter(item => item.field_name);
      return indicatorsOptions;
    }
  },
  watch: {
    editParams: {
      async handler(val) {
        if (val.monitor_configs) {
          // 指标类型，指标项名称回填映射
          this.tableData = this.dealMonitorConfigs(val.monitor_configs);
        } else {
          this.tableData = [];
        }
      },
      deep: true
    },
    'indicatorsOptions.deal': {
      handler(val) {
        if (val.length && this.editParams.monitor_configs) {
          this.tableData = this.dealMonitorConfigs(this.editParams.monitor_configs);
        }
      }
    }
  },
  methods: {
    // == 指标类型，指标项名称回填映射
    dealMonitorConfigs(val) {
      return val.map(item => {
        item.configs.analysis_target_name = this.getAnalysisTargetName(item.configs.analysis_target);
        item.configs.analysis_target_key_name = this.getAnalysisTargetKeyName(item.configs.analysis_target_key);
        return item;
      });
    },
    getAnalysisTargetKeyName(analysis_target_key) {
      var _this$indicatorsOptio;
      return (_this$indicatorsOptio = this.indicatorsOptionsData.filter(_ref => {
        let {
          field_name
        } = _ref;
        return field_name === analysis_target_key;
      })[0]) === null || _this$indicatorsOptio === void 0 ? void 0 : _this$indicatorsOptio.name;
    },
    getAnalysisTargetName(analysis_target) {
      // this.analysisTarget 来自 AnalysisConfigMixin
      return this.analysisTarget.filter(_ref2 => {
        let {
          type
        } = _ref2;
        return type === analysis_target;
      })[0].label;
    },
    // == 指标类型，指标项名称回填映射

    submit() {
      if (this.tableData.length) {
        this.$emit('change', {
          monitor_configs: this.tableData
        }, 'second');
      } else {
        this.$message({
          type: 'warning',
          message: '请添加监测指标',
          center: true
        });
      }
    },
    deleteConfig(index) {
      this.tableData.splice(index, 1);
    },
    editConfig(row, index) {
      this.crrentEdit = index;
      this.editConfigData = row;
      this.isEdit = true;
      this.configDialogvisible = true;
    },
    addConfig() {
      this.isEdit = false;
      this.configDialogvisible = true;
    },
    handleConfig(data) {
      const config = (0, _commonFunc.deepClone)(data);
      if (this.isEdit) {
        // 编辑
        this.$set(this.tableData, this.crrentEdit, config);
      } else {
        // 创建
        this.tableData.push(config);
      }
    }
  }
};
exports.default = _default;