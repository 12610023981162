"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LableRequireTooltipRemark = _interopRequireDefault(require("@/views/businessIntelligence/components/portraitConfigComponents/LableRequireTooltipRemark"));
var _index = _interopRequireDefault(require("@/views/business/components/dealFilter/index"));
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _timezone = require("@/utils/timezone");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    LableRequireTooltipRemark: _LableRequireTooltipRemark.default,
    DealCustomFilter: _index.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: true
    },
    operateType: {
      type: String,
      default: 'create'
    },
    getKanbanConfigLoading: {
      type: Boolean
    },
    eventOptions: {
      type: Array,
      default: () => []
    },
    metricsTypeList: {
      type: Array,
      default: () => []
    },
    customFiled: {
      type: Array,
      default: () => []
    },
    accountLabelList: {
      type: Array,
      default: () => []
    },
    accountLabelOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      kanbanConfig: {
        name: '',
        description: '',
        filter_config: []
      },
      submitLoading: false,
      resetCascaderKey: Symbol() // 级联选择器切换options时，改变key值重新渲染
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'timezone']),
    isShowDealCustomFilter() {
      return currentMetrics => {
        return currentMetrics.type && currentMetrics.type === 'deal_indicator' && currentMetrics.key;
      };
    },
    disabledArr() {
      return this.kanbanConfig.filter_config.map(_ref => {
        let {
          key
        } = _ref;
        return key;
      });
    },
    keyEventDisabled() {
      return this.kanbanConfig.filter_config.filter(_ref2 => {
        let {
          type
        } = _ref2;
        return type === 'event';
      }).map(_ref3 => {
        let {
          type
        } = _ref3;
        return type;
      });
    },
    getOperateType() {
      return {
        detail: {
          label: '查看客户群画像详情'
        },
        create: {
          label: '新建客户群画像',
          handle: _businessIntelligence.createCustomerBaseProfileKanban
        },
        edit: {
          label: '编辑客户群画像',
          handle: _businessIntelligence.editCustomerBaseProfileKanban
        }
      }[this.operateType] || {};
    }
  },
  methods: {
    cascaderOptions(type) {
      return type === 'event' ? this.eventOptions : this.accountLabelOptions;
    },
    getDealCustomConfig(_ref4) {
      let {
        key
      } = _ref4;
      return this.customFiled.find(_ref5 => {
        let {
          field_name
        } = _ref5;
        return field_name === key;
      });
    },
    changeMetricsType(itemMetrics) {
      itemMetrics.key = '';
      itemMetrics.value = '';
      this.resetCascaderKey = Symbol();
    },
    changeKeyEvent(itemMetrics, index) {
      const keyEventCascaderRef = this.$refs[`key-event-cascader-${index}`][0];
      const keyEventIds = keyEventCascaderRef.getCheckedNodes(false).map(item => item.value);
      itemMetrics.value = keyEventIds;
    },
    changeMetricsKey(itemMetrics) {
      itemMetrics.value = '';
    },
    customFilterChange(value, itemMetrics) {
      itemMetrics.value = value[itemMetrics.key];
    },
    handleClose() {
      this.kanbanConfig = this.$options.data().kanbanConfig;
      this.$refs['kanbanConfigForm'].resetFields();
      this.$emit('update:drawerVisible', false);
    },
    addMetrics() {
      this.kanbanConfig.filter_config.push({
        id: Symbol(),
        type: '',
        key: '',
        value: ''
      });
    },
    removeMetrics(index) {
      this.kanbanConfig.filter_config.splice(index, 1);
    },
    submit() {
      this.$refs['kanbanConfigForm'].validate(async valid => {
        if (valid) {
          const requestFn = this.getOperateType.handle;
          this.kanbanConfig.filter_config.forEach(item => {
            (0, _timezone.convertParams2UTCplus8)(item);
          });
          try {
            this.submitLoading = true;
            const res = await requestFn({
              ...this.kanbanConfig,
              workspace_id: this.workspaceInfo.id,
              time_zone: this.timezone
            });
            res.code === 200 && this.$message.success(this.operateType === 'edit' ? '编辑成功' : '新建成功');
            this.$emit('handleKanbanSuccess', this.kanbanConfig.name, this.kanbanConfig.description);
            this.handleClose();
          } catch (error) {
            throw new Error(error);
          } finally {
            this.submitLoading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;