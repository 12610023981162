"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'HitTag',
  props: {
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      statusObj: {
        1: '命中',
        2: '未命中',
        3: '不确定'
      }
    };
  }
};
exports.default = _default;