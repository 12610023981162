"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ClientDetailDrawer = _interopRequireDefault(require("./ClientDetailDrawer"));
var _CreateBusinessDialog = _interopRequireDefault(require("./components/CreateBusinessDialog"));
var _moveAccountSeaDialog = _interopRequireDefault(require("./components/moveAccountSeaDialog"));
var _ContactPersonDialog = _interopRequireDefault(require("./components/ContactPersonDialog"));
var _ClientOperateButtons = _interopRequireDefault(require("./components/ClientOperateButtons"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _LinkText = _interopRequireDefault(require("@/components/LinkText"));
var _formatContactFormDescMixins = _interopRequireDefault(require("./formatContactFormDescMixins"));
var _client = require("@/api/client");
var _doubleCallMixins = _interopRequireDefault(require("./doubleCallMixins"));
var _vuex = require("vuex");
var _UploadMediaDialog = _interopRequireDefault(require("@/components/UploadMediaDialog"));
var _clueAndClientSortMixin = _interopRequireDefault(require("@/mixin/clueAndClientSortMixin"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ClientTable',
  components: {
    ClientDetailDrawer: _ClientDetailDrawer.default,
    CreateBusinessDialog: _CreateBusinessDialog.default,
    moveAccountSeaDialog: _moveAccountSeaDialog.default,
    ContactPersonDialog: _ContactPersonDialog.default,
    ImageAvatar: _ImageAvatar.default,
    ClientOperateButtons: _ClientOperateButtons.default,
    LinkText: _LinkText.default,
    MeetingDialog: _MeetingDialog.default,
    UploadMediaDialog: _UploadMediaDialog.default
  },
  mixins: [_doubleCallMixins.default, _formatContactFormDescMixins.default, _clueAndClientSortMixin.default],
  props: {
    headerList: {
      type: Array,
      default: () => []
    },
    doubleCallPermission: {
      type: Number,
      default: 0
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    sortColumnList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: document.body.clientHeight - 126,
      clientDetailDrawerVisible: false,
      businessDialogVisible: false,
      uploadMediaDialogVisible: false,
      moveAccountSeaDialogVisible: false,
      rowInfo: {},
      mediaRowInfo: {},
      isCallUpAndCreateDealShow: true,
      formDesc: [],
      dynamicForm: [],
      contactList: [],
      activeClientId: null,
      contactPersonDialogVisible: false,
      contactFormDesc: [],
      currentOperateType: '',
      meetingDialogVisible: false,
      meetingDialogTitle: '安排在线会议',
      meetingProps: {},
      pageType: 'client',
      defaultSortColumn: 'create_at',
      // 客户公海table的优先取组件内的lastSort
      lastSort: {
        prop: 'create_at',
        order: 'descending'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['has_outbound', 'contactDynamicFormDescList', 'has_video_permission', 'businessStatusOptions', 'api_permissions']),
    operateColumnWidth() {
      const obj = this.operateButtons.filter(item => item.isShow === true);
      return (2 + (obj === null || obj === void 0 ? void 0 : obj.length)) * 60;
    },
    operateButtons() {
      if (this.doubleCallPermission) {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isShowList: false,
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'doubleCall',
          text: '双呼',
          isShowList: true,
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isShowList: true,
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isShowList: false,
          isCircle: true,
          isShow: this.has_video_permission
        }];
      } else {
        return [{
          operateType: 'upload',
          icon: 'upload-3',
          isShowList: false,
          isCircle: true,
          isShow: this.api_permissions.includes('/api/conversation/conversation/upload')
        }, {
          operateType: 'callout',
          icon: 'phone-outline',
          isShowList: true,
          isCircle: true,
          isShow: this.has_outbound
        }, {
          operateType: 'createMeeting',
          icon: 'reservation-2',
          isShowList: false,
          isCircle: true,
          isShow: this.has_video_permission
        }];
      }
    }
  },
  watch: {
    list(val) {
      this.$nextTick(() => {
        this.$refs['clientTable'].doLayout();
      });
    }
  },
  mounted() {
    var _this$$route, _this$$route$query;
    window.addEventListener('resize', this.setTableHeight);
    this.getBusinessStatusOptions();
    // eslint-disable-next-line no-undef
    if ((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id) {
      var _this$$route2;
      // eslint-disable-next-line no-undef
      this.goClientDetail((_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.query);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  methods: {
    selectable(row, index) {
      return row.has_edit;
    },
    tableRowClassName(_ref) {
      let {
        row,
        rowIndex
      } = _ref;
      if (row.highlight === true && rowIndex === 0) {
        return 'highlight-row';
      }
      return '';
    },
    getIsShowCallList(row, type) {
      var _this$contactList;
      return this.activeClientId === row.id && !!((_this$contactList = this.contactList) !== null && _this$contactList !== void 0 && _this$contactList.length) && this.currentOperateType === type;
    },
    handleSelectionChange(val) {
      this.$emit('accountSelectionChange', val);
    },
    freshClientTable() {
      this.$emit('freshClientTable');
      if (this.clientDetailDrawerVisible) {
        this.closeClientDetailDrawer();
      }
    },
    freshCustomerDetail(val) {
      this.$refs.clientDetail.id = val;
      this.$refs.clientDetail.getClientDetail();
    },
    // 关闭上传dialog
    closeUploadMediaDialog() {
      this.$refs['upload-media-dialog'].mediaFileList = [];
      this.uploadMediaDialogVisible = false;
    },
    closeBusinessDialog() {
      this.businessDialogVisible = false;
      this.formDesc = [];
    },
    closeClientDetailDrawer() {
      this.clientDetailDrawerVisible = false;
    },
    closeMoveAccountSeaDialog() {
      this.moveAccountSeaDialogVisible = false;
    },
    openCreateBusinessDialog(rowInfo) {
      this.rowInfo = rowInfo;
      if (this.$refs['createBusinessDeal']) {
        this.$refs['createBusinessDeal'].customer_name = rowInfo.name;
        this.$refs['createBusinessDeal'].account_id = rowInfo.id;
      }
      this.businessDialogVisible = true;
      this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)), rowInfo);
    },
    createDeal(val) {
      this.openCreateBusinessDialog(val);
    },
    async getBusinessStatusOptions() {
      await this.$store.dispatch('business/get_business_status_options');
      // 获取动态表单
      if (this.$refs['createBusinessDeal']) {
        this.$refs['createBusinessDeal'].loading = true;
      }
      // 参数：in_account: 1表示在客户模块，0表示在商机模块
      const dynamicForm = await (0, _client.getDynamicForm)({
        in_account: 1
      });
      if (dynamicForm.code === 200) {
        this.dynamicForm = dynamicForm.results;
      }
      if (this.$refs['createBusinessDeal']) {
        this.$refs['createBusinessDeal'].loading = false;
        this.formDesc = this.descFormat(JSON.parse(JSON.stringify(this.dynamicForm)), this.rowInfo);
        if (this.$refs['createBusinessDeal']) {
          this.$refs['createBusinessDeal'].loading = false;
        }
      }
    },
    // 表单描述格式化
    descFormat(results, currentAccount) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          default: item.default_value,
          tips: item.tips
        };
        if (item.from_type === 'select' && item.name === '商机阶段') {
          obj.options = this.businessStatusOptions;
        }
        if (item.name === '客户名称') {
          obj.default = currentAccount.name;
        }
        desc.push(obj);
      }
      return desc;
    },
    freshCurrentList() {
      this.$emit('freshCurrentList');
    },
    formatterTest(row, column, cellValue, index) {
      return cellValue || '-';
    },
    goClientDetail(row) {
      this.activeClientId = typeof row.id === 'number' ? row.id : Number(row.id);
      this.clientDetailDrawerVisible = true;
    },
    moveAccountSeaMultiple(ids) {
      this.openMoveAccountSeaDialog();
      this.$refs['moveAccountSeaDialog'].formData.id = ids;
    },
    openMoveAccountSeaDialog() {
      this.moveAccountSeaDialogVisible = true;
    },
    changeFollowUpBy(ids) {
      this.$emit('changeFollowUpBy', ids, 'clientChangeFollower');
    },
    handleCommand(row, command) {
      switch (command) {
        case 'edit':
          this.goClientDetail(row);
          break;
        case 'moveAccountSea':
          this.moveAccountSeaMultiple([row.id]);
          break;
        case 'changeFollowUpBy':
          // eslint-disable-next-line no-case-declarations
          const followerIds = [row.id];
          this.changeFollowUpBy(followerIds);
          break;
      }
    },
    setTableHeight() {
      this.tableHeight = document.body.clientHeight - 314;
    },
    clickOperateBtn(row, type) {
      this.rowInfo = row;
      this.activeClientId = row.id;
      this.currentOperateType = type;
    },
    openContactPersonDialog(row) {
      this.contactPersonDialogVisible = true;
      this.$nextTick(() => {
        this.$refs['createContactPersonDialog'].clearValidate();
      });
      this.contactFormDesc = this.descContactFormat(this.contactDynamicFormDescList, true, row);
    },
    closeContactPersonDialog() {
      this.contactPersonDialogVisible = false;
    },
    callOut(item, row) {
      if (!this.has_outbound) {
        this.$message.warning('暂无坐席');
        return;
      }
      const data = {
        id: row.id,
        type: 'account',
        company: row.company,
        contact_id: item.id,
        contact_person: item.name,
        phone: item.tel
      };
      this.$bus.$emit('openPhoneCall', data);
    },
    createMeeting(contactItem, clientInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      const {
        id,
        name
      } = clientInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName
      } = contactItem;
      this.meetingProps = {
        type: 'account',
        accountId: id,
        customerName: name,
        contactOptions,
        contactId,
        contactName,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    // 打开上传dialog
    openUploadMediaDialog(contactItem, rowInfo, contactList) {
      const {
        id,
        name
      } = contactItem;
      const currentContactInfo = {
        id,
        name
      };
      const contactInfo = {
        currentContactInfo,
        contactList
      };
      const clientInfo = {
        id: rowInfo.id,
        name: rowInfo.name
      };
      this.mediaRowInfo = {
        contactInfo,
        clientInfo,
        rowInfo
      };
      this.uploadMediaDialogVisible = true;
    },
    // 请求联系人信息
    async getAllContactPersonList() {
      (0, _client.getAllContactPersonList)(this.activeClientId).then(res => {
        if (res.code === 200) {
          this.contactList = res.results;
        }
      });
    },
    changeActiveClientId(id) {
      this.activeClientId = id;
    },
    clickContactPersonItem(contactItem, rowInfo, type, contactList, meetingType) {
      switch (type) {
        case 'callout':
          this.callOut(contactItem, rowInfo);
          break;
        case 'createMeeting':
          this.createMeeting(contactItem, rowInfo, contactList, meetingType);
          break;
        case 'doubleCall':
          this.doubleCallOut(contactItem, rowInfo, 'account');
          break;
        case 'upload':
          this.openUploadMediaDialog(contactItem, rowInfo, contactList);
          break;
        default:
          break;
      }
    }
  }
};
exports.default = _default;