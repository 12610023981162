"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _ConnectedContact = _interopRequireDefault(require("@/components/UploadMediaDialog/ConnectedContact.vue"));
var _useVuex = require("@/hooks/use-vuex");
var _validate = require("@/utils/validate");
var _uploadConversationForm = require("./hooks/uploadConversationForm.js");
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'UploadConversationForm',
  props: {},
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const rules = {
      host_salesman_id: [{
        required: true,
        message: '请选择会话归属人',
        trigger: 'change'
      }],
      begin_time: [{
        required: true,
        message: '请选择日期',
        trigger: 'change'
      }, {
        validator: _validate.validateTimeBeforeNow,
        trigger: 'change'
      }]
    };
    const form = (0, _vue.ref)({
      host_salesman_id: '',
      // 会话归属人
      begin_time: '',
      // 会话日期
      independent_trade_id: '',
      // 关联交易id,
      independent_contact_id: '',
      // 关联联系人id
      is_independent: true
    });
    const handleIndependentContactChange = val => {
      form.value.independent_contact_id = val;
      form.value.independent_trade_id = '';
    };
    const {
      getConversationCustomFieldApi,
      conversationDynamicFieldsDesc,
      conversationDynamicForm
    } = (0, _uploadConversationForm.useConversationCustomFieldForm)();
    const {
      independentTradeList,
      searchTradeApi
    } = (0, _uploadConversationForm.useIndependentTrade)(form, store);
    const {
      membersFinderRef,
      initialHostSalesMan,
      handleMemberChange
    } = (0, _uploadConversationForm.useHostSalesMan)(form, store);
    const {
      uploadMediaFormRef,
      conversationDynamicFormRef,
      uploadFormValid
    } = (0, _uploadConversationForm.useFormValid)();
    expose({
      form,
      conversationDynamicForm,
      uploadFormValid
    });
    (0, _vue.onMounted)(() => {
      initialHostSalesMan();
      getConversationCustomFieldApi();
    });
    return {
      __sfc: true,
      store,
      props,
      rules,
      form,
      handleIndependentContactChange,
      getConversationCustomFieldApi,
      conversationDynamicFieldsDesc,
      conversationDynamicForm,
      independentTradeList,
      searchTradeApi,
      membersFinderRef,
      initialHostSalesMan,
      handleMemberChange,
      uploadMediaFormRef,
      conversationDynamicFormRef,
      uploadFormValid,
      MembersFinder: _MembersFinder.default,
      DynamicForm: _DynamicForm.default,
      ConnectedContact: _ConnectedContact.default
    };
  }
};
exports.default = _default;