"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: '',
  props: {
    labelTitle: {
      type: String,
      default: ''
    },
    labelValueHtml: {
      type: String,
      default: '<span class="bold-text">28</span><span>分钟</span><span class="bold-text"> 34</span><span>秒</span>'
    },
    markValue: {
      // 合格 qualified，警惕 vigilant
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
exports.default = _default;