"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TricksScene = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/TricksScene.vue"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'LearnCard',
  components: {
    TricksScene: _TricksScene.default
  },
  props: {
    cardItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localCardItem: {}
    };
  },
  watch: {
    cardItem: {
      handler(val) {
        this.localCardItem = this.formatLocalCard(val);
      },
      immediate: true
    }
  },
  methods: {
    // 知识点下的话术 order
    formatLocalCard(val) {
      let order = 1;
      const localCard = (0, _commonFunc.deepClone)(val);
      localCard.scene_reply_list.forEach(trick => {
        var _trick$reply_list;
        (_trick$reply_list = trick.reply_list) === null || _trick$reply_list === void 0 ? void 0 : _trick$reply_list.forEach(i => {
          i.order = order++;
        });
      });
      return localCard;
    },
    async handleDragEnd(_ref) {
      let {
        scene_id,
        reply_list
      } = _ref;
    }
  }
};
exports.default = _default;