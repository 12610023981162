"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    staticClass: "mg-common-drawer",
    attrs: {
      title: "添加知识点",
      visible: _vm.knowledgesDrawerVisible,
      "before-close": _vm.cancel,
      size: "1000px",
      "custom-class": "select-knowledges-drawer",
      "wrapper-closable": false,
      "append-to-body": ""
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "search-part"
  }, [_c("el-select", {
    ref: "select",
    attrs: {
      "popper-class": "coaching-knowledges-select",
      multiple: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.searchKnowledges,
      placeholder: "搜索知识点"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.knowledgeArr,
      callback: function ($$v) {
        _vm.knowledgeArr = $$v;
      },
      expression: "knowledgeArr"
    }
  }, [_c("template", {
    slot: "prefix"
  }, [_c("i", {
    staticClass: "iconfont icon-search"
  })]), _vm.showRecommend ? _vm._l(_vm.recommendOptions, function (group) {
    return _c("el-option-group", {
      key: group.label,
      attrs: {
        label: group.label
      }
    }, _vm._l(group.options, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.name,
          value: item.id
        }
      }, [_c("option-template", {
        attrs: {
          item: item
        }
      })], 1);
    }), 1);
  }) : _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("option-template", {
      attrs: {
        item: item
      }
    })], 1);
  })], 2)], 1), _c("main", {
    staticClass: "main-content"
  }, [_c("coaching-tasks-content", {
    ref: "content",
    attrs: {
      visible: _vm.knowledgesDrawerVisible,
      "selected-knowledges": _vm.selectedKnowledges,
      "show-workspace": ""
    },
    on: {
      setSelectedKnowledges: function ($event) {
        return _vm.$emit("setSelectedKnowledges", $event);
      }
    }
  })], 1)]), _c("footer", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    staticClass: "mg-button mg-round-button",
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    staticClass: "mg-button mg-round-button",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addKnowledges
    }
  }, [_vm._v("添加")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;