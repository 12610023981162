"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dealFilter = _interopRequireDefault(require("@/views/business/components/dealFilter"));
var _ConversationFilterItem = _interopRequireDefault(require("../../components/ConversationFilterItem"));
var _TimeRangeFilter = _interopRequireDefault(require("../../components/TimeRangeFilter"));
var _ConvDate = _interopRequireDefault(require("../../components/ConvDate.vue"));
var _NoSpeakTimeFilter = _interopRequireDefault(require("../../components/NoSpeakTimeFilter"));
var _ConvTimesFilter = _interopRequireDefault(require("../../components/ConvTimesFilter.vue"));
var _AmountRangeInput = _interopRequireDefault(require("@/components/AmountRange/AmountRangeInput"));
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ConversationCustomFilters',
  props: {
    conversationCustomFiltersList: {
      type: Array,
      default: () => []
    },
    hideSavedFilters: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const conversationTypeList = [{
      type: 'audio',
      label: '电话',
      icon: 'icon-phone-outline'
    }, {
      type: 'video',
      label: '在线会议',
      icon: 'icon-video-camera'
    }, {
      type: 'doc',
      label: '文字',
      icon: 'icon-im'
    }];
    const hangUpPersonList = [{
      value: 1,
      label: '客户'
    }, {
      value: 2,
      label: '销售'
    }];
    const sceneTypeList = [{
      value: 'single',
      label: '单聊'
    }, {
      value: 'multiple',
      label: '群聊'
    }];

    /**
     * 会话类型逻辑
     */
    const selectConversationType = item => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.conversation_type = item.type;
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.conversation_scene_type = '';
    };

    /**
     * 会话场景选择
     */
    const selectSceneType = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.conversation_scene_type = val;
    };

    /**
     * 第几次会话筛选逻辑
     */
    const selectConvTimes = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.conv_times.value = val;
    };

    /**
     * 会话时长筛选逻辑
     */
    const selectConversationTime = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.duration.value = val;
    };
    /**
     * 最长无人发言时间
     */
    const selectNoSpeakTime = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.max_silence_duration = val;
    };
    /**
     * 会话挂断方逻辑
     */
    const selectHangUpPerson = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.hang_up = val;
    };
    /**
     * 销售/客户发言次数筛选
     */
    const speakCountsChange = (val, field_name) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters[field_name] = val;
    };

    /**
     * 会话自定义字段
     */
    const handleConversationCustomFilter = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.conv_custom_filters = {
        ...props.filters.conv_custom_filters,
        ...val
      };
    };
    return {
      __sfc: true,
      props,
      conversationTypeList,
      hangUpPersonList,
      sceneTypeList,
      selectConversationType,
      selectSceneType,
      selectConvTimes,
      selectConversationTime,
      selectNoSpeakTime,
      selectHangUpPerson,
      speakCountsChange,
      handleConversationCustomFilter,
      DealCustomFilter: _dealFilter.default,
      ConversationFilterItem: _ConversationFilterItem.default,
      TimeRangeFilter: _TimeRangeFilter.default,
      ConvDate: _ConvDate.default,
      NoSpeakTimeFilter: _NoSpeakTimeFilter.default,
      ConvTimesFilter: _ConvTimesFilter.default,
      AmountRangeInput: _AmountRangeInput.default,
      BatchSearchInput: _BatchSearchInput.default
    };
  }
};
exports.default = _default;