"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PresetPathForm = _interopRequireDefault(require("./PresetPathForm.vue"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PresetPathDialog',
  components: {
    PresetPathForm: _PresetPathForm.default
  },
  props: {
    setPathVisible: {
      type: Boolean,
      default: false
    },
    savePresetLoading: {
      type: Boolean,
      default: false
    },
    presetPathData: {
      type: Array,
      default: () => []
    },
    hasPresetPath: {
      type: Boolean,
      default: false
    },
    keyEventOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pathKeyEventsList: [],
      keyEventsForm: {
        pathKeyEventsList: []
      },
      needPresetTips: false
    };
  },
  watch: {
    setPathVisible(val) {
      if (val) {
        this.needPresetTips = !this.hasPresetPath;
        // 设置预设路径表单为非编辑状态
        if (this.hasPresetPath) {
          this.keyEventsForm.pathKeyEventsList = (0, _commonFunc.deepClone)(this.presetPathData);
        } else {
          var _this$$refs$presetPa;
          // 删除预设路径后,清除检验
          this.keyEventsForm.pathKeyEventsList = [{
            value: ''
          }, {
            value: ''
          }, {
            value: ''
          }];
          (_this$$refs$presetPa = this.$refs['preset-path']) === null || _this$$refs$presetPa === void 0 ? void 0 : _this$$refs$presetPa.clearValidate();
        }
      }
    }
  },
  methods: {
    // 删除预设业务路径
    deletePresetPath() {
      this.$confirm('确定删除已设置的预设路径？', '提示', {
        confirmButtonText: '删 除',
        confirmButtonClass: 'confirm-right',
        cancelButtonText: '取 消'
      }).then(() => {
        this.$emit('deletePresetPath');
        this.keyEventsForm.pathKeyEventsList = [{
          value: ''
        }, {
          value: ''
        }, {
          value: ''
        }];
      }).catch(() => {});
    },
    // 确认设置的业务路径
    confirmPathKeyEvents() {
      this.$refs['preset-path'].$refs['keyEventsForm'].validate(valid => {
        if (valid) {
          this.$emit('createPresetPath', this.keyEventsForm.pathKeyEventsList);
        }
      });
    },
    // 添加业务路径的关键事件
    addPathKeyEvents() {
      if (this.keyEventsForm.pathKeyEventsList.length >= 13) {
        this.$message({
          showClose: true,
          message: '最多可加十三步',
          center: true
        });
      } else {
        this.keyEventsForm.pathKeyEventsList.push({
          value: '',
          key: Date.now()
        });
      }
    },
    // 删除业务路径的关键事件
    deletePathKeyEvents(index) {
      this.keyEventsForm.pathKeyEventsList.splice(index, 1);
    },
    // 关闭编辑业务路径弹框
    handleEditPathClose() {
      this.$emit('closePresetPathDialog');
      if (!this.hasPresetPath) {
        this.$refs['preset-path'].$refs['keyEventsForm'].resetFields();
      }
    },
    // 编辑业务路径关键事件
    editkeyEventsForm() {
      this.needPresetTips = false;
    }
  }
};
exports.default = _default;