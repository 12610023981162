"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _timezone = require("@/utils/timezone");
var _default = {
  name: 'SavedFiltersContent',
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    stageOptions: {
      type: Array,
      default: () => []
    },
    customFilterArray: {
      type: Array,
      default: () => []
    },
    conversationListCustomFilter: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    getStatusName() {
      return val => {
        var _current$;
        const current = this.stageOptions.filter(item => {
          return item.id === val;
        });
        return (_current$ = current[0]) === null || _current$ === void 0 ? void 0 : _current$.stage_name;
      };
    },
    accountLabelOptions() {
      return this.getAccountLabelArray(this.customFilterArray.find(item => item.field_name === 'account_label').options, []);
    }
  },
  created() {},
  methods: {
    // 处理当前回显的筛选器数据
    handleFiltersItem(currentFilters) {
      var _this$conversationLis;
      if (!Object.keys(currentFilters).length) return;
      return {
        部门: currentFilters.departmentLabel,
        销售: this.arrayToString(currentFilters.host || currentFilters.user_ids),
        客户: this.arrayToString(currentFilters.companyName),
        交易: this.getText(currentFilters.deal_name),
        会话时交易阶段: this.getStatusName(currentFilters.conversation_deal_status),
        会话文本: this.getConvText(currentFilters),
        关键事件跟踪器: this.handleKeyEventShow(currentFilters),
        关键事件顺序: this.handleKeyEventOrderShow(currentFilters.event_engine_order),
        会话类别: this.getConversationTypeIcon(currentFilters.conversation_type),
        会话场景: this.getConversationScene(currentFilters.conversation_scene_type),
        会话时间: this.getConvDate(currentFilters),
        会话时长: this.getConvDuration(currentFilters.duration),
        最长无人发言时间: this.getSilenceDuration(currentFilters.max_silence_duration),
        会话挂断方: this.getHangUpPerson(currentFilters.hang_up),
        销售发言条数: this.getCustomFilterAmountRange(currentFilters.sale_sentence_count),
        客户发言条数: this.getCustomFilterAmountRange(currentFilters.custom_sentence_count),
        总发言条数: this.getCustomFilterAmountRange(currentFilters.sentence_count),
        第几次会话: this.formatConvTimesContent(currentFilters.conv_times),
        ...this.getScoreRuleConfigs(currentFilters.compliance_score_filters),
        ...this.getScoreRuleConfigs(currentFilters.conv_score_filters),
        ...this.getCustomFiltersInformation(currentFilters.custom_filters, this.customFilterArray),
        ...this.getCustomFiltersInformation(currentFilters.conv_custom_filters, ((_this$conversationLis = this.conversationListCustomFilter.find(item => item.name === '会话基本信息')) === null || _this$conversationLis === void 0 ? void 0 : _this$conversationLis.filters) || []),
        销售说话占比: this.getCustomFilterAmountRange(currentFilters.percent_speak, '%'),
        最长销售独白: this.getSilenceDuration(currentFilters.max_sales),
        最长客户叙述: this.getSilenceDuration(currentFilters.max_customer),
        深入讨论次数: this.getCustomFilterAmountRange(currentFilters.discuss, '次'),
        销售提问次数: this.getCustomFilterAmountRange(currentFilters.question, '次'),
        客户提问次数: this.getCustomFilterAmountRange(currentFilters.customer_question, '次'),
        销售语速: this.getCustomFilterAmountRange(currentFilters.sales_speed, '字/分钟'),
        客户语速: this.getCustomFilterAmountRange(currentFilters.customer_speed, '字/分钟')
      };
    },
    // 处理第几个沟通
    formatConvTimesContent(convTimes) {
      if (!convTimes) return;
      const [min, max] = convTimes.value;
      if (min === max) {
        switch (min) {
          case -1:
            return;
          case 1:
            return '首次';
          default:
            return `第 ${min} 次`;
        }
      } else {
        if (min === -1 && max !== -1) {
          return `小于等于 ${max} 次`;
        } else if (max === -1 && min !== -1) {
          return `大于等于 ${min} 次`;
        } else if (min !== -1 && max !== -1) {
          return `第 ${min} 次 ～ 第 ${max} 次`;
        }
      }
    },
    arrayToString(value) {
      // 兼容旧数据：当拓展筛选项后，保存的旧的筛选项会缺少新添加的筛选项字段而报错
      return value && value.map(item => item.name || item.label || item).join('；');
    },
    getConvText(data) {
      const relation = data.filter_relation;
      const convText = data.filter;
      if (!convText) return '';
      const isAddRemarks = convText.length > 1;
      let str = '';
      if (isAddRemarks) {
        str += `<span class="saved-filter-key-event-list">${relation === 'or' ? '或' : '且'} [ `;
      }
      if (convText.length) {
        convText.forEach((item, index) => {
          if (item.role === 'default') {
            str += item.inverse ? `<span class="inverse-show">(不包含) </span>${item.pattern}；` : `${item.pattern}；`;
          } else {
            if (item.role === 'host_salesman') {
              str += `<span class="salesman-speak">(销售说) </span>`;
              str += item.inverse ? `<span class="inverse-show">(不包含) </span>${item.pattern}；` : `${item.pattern}；`;
            } else {
              str += `<span class="customer-speak">(客户说) </span>`;
              str += item.inverse ? `<span class="inverse-show">(不包含) </span>${item.pattern}；` : `${item.pattern}；`;
            }
          }
        });
        // 去掉最后一个；
        str = str.substring(0, str.lastIndexOf('；'));
      }
      if (isAddRemarks) str += ` ]</span>`;
      return str;
    },
    // 处理关键事件显示
    handleKeyEventShow(data) {
      const relation = data.event_engine_relation;
      // 关键事件兼容
      const eventTracker = data.event_engine || data.keyEventCascaderList;
      if (!eventTracker) return '';
      const invalidEventTracker = data.event_invalid;
      const isAddRemarks = eventTracker.length > 1;
      let str = '';
      if (isAddRemarks) {
        str += `<span class="saved-filter-key-event-list">${relation === 'or' ? '或' : '且'} [ `;
      }
      if (eventTracker.length) {
        eventTracker.forEach(item => {
          if (item.inverse) {
            str += `<span class="inverse-show">(不包含) </span>${item.keyEventLabel || item.keyEventName}；`;
          } else {
            str += `${item.keyEventLabel || item.keyEventName}；`;
          }
        });
      }
      // 无效事件
      if (invalidEventTracker !== null && invalidEventTracker !== void 0 && invalidEventTracker.length) {
        invalidEventTracker.forEach(item => {
          if (item.inverse) {
            str += `<span class="invalid-key-event">(不包含) ${item.keyEventLabel || item.keyEventName}；</span>`;
          } else {
            str += `<span class="invalid-key-event">${item.keyEventLabel || item.keyEventName}；</span>`;
          }
        });
      }
      str = str.substring(0, str.lastIndexOf('；'));
      if (isAddRemarks) str += ` ]</span>`;
      return str;
    },
    handleKeyEventOrderShow(data) {
      const result = [];
      if (data) {
        const filterList = data.filter(e => e['keyEventName']);
        filterList.forEach(item => {
          result.push(`${item.inverse ? '不含' : ''}${item.keyEventName}`);
        });
      }
      return `${result.join(' → ')}`;
    },
    getConversationTypeIcon(type) {
      const MAP = {
        audio: 'phone-outline',
        video: 'video-camera',
        doc: 'im'
      };
      return MAP[type] ? `<i class="iconfont icon-${MAP[type]}"></i>` : '';
    },
    getConversationScene(scene) {
      const MAP = {
        single: '单聊',
        multiple: '群聊'
      };
      return MAP[scene];
    },
    // 处理会话日期
    getConvDate(currentFilters) {
      if (!currentFilters.date) return '';
      const {
        tag,
        range
      } = currentFilters.date;
      const newRange = range.map(item => {
        return this.$plus8ToCurrentZone(item).format('YYYY-MM-DD HH:mm');
      });
      return tag ? this.getDateTag(tag) : newRange.length ? `${newRange[0]} 至 ${newRange[1]}` : '';
    },
    getDateTag(tag) {
      const map = {
        today: '今天',
        yesterday: '昨天',
        'this week': '本周',
        'last week': '上周',
        'last month': '上个月'
      };
      return map[tag];
    },
    getConvDuration(duration) {
      if (!duration) return '';
      if (Array.isArray(duration)) {
        if (duration[0] === duration[1]) {
          return duration[0] + '分钟';
        } else if (duration[0] > 0) {
          if (duration[1] === 'unlimit') {
            return duration[0] + '分钟以上';
          } else {
            return duration[0] + '分钟 -' + duration[1] + '分钟';
          }
        }
      } else {
        if (duration.value[0] === duration.value[1]) {
          return this.formatTime(duration.value[0]);
        } else if (duration.value[0] > 0) {
          if (duration.value[1] === 'unlimit' || duration.value[1] === -1) {
            return this.formatTime(duration.value[0]) + '以上';
          } else {
            return this.formatTime(duration.value[0]) + ' - ' + this.formatTime(duration.value[1]);
          }
        } else if (duration.value[0] === 0 && duration.value[1] > 0) {
          return this.formatTime(duration.value[1]) + '以内';
        }
      }
    },
    getSilenceDuration(duration) {
      if (!(duration !== null && duration !== void 0 && duration.length)) return;
      if (duration[0] === duration[1]) {
        return duration[0] + '秒';
      } else if (duration[0] > 0) {
        if (duration[1] === 'unlimit' || duration[1] === -1) {
          return duration[0] + '秒以上';
        } else {
          return duration[0] + '秒 - ' + duration[1] + '秒';
        }
      } else if (duration[0] === 0 && duration[1] > 0 || duration[0] === -1) {
        return duration[1] + '秒以内';
      }
    },
    getHangUpPerson(value) {
      switch (value) {
        case 1:
          return '客户';
        case 2:
          return '销售';
      }
    },
    formatTime(time) {
      const minute = parseInt(time / 60) ? parseInt(time / 60) + '分钟' : '';
      const second = time % 60 ? time % 60 + '秒' : '';
      return minute + second;
    },
    // 交易自定义筛选器 范围数据 的特殊处理
    getCustomFilterAmountRange(value) {
      let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      if (!(value !== null && value !== void 0 && value.length)) return;
      if (value[0] === -1) {
        return '小于等于 ' + value[1] + unit;
      } else if (value[1] === -1) {
        return '大于等于 ' + value[0] + unit;
      } else if (value[0] === value[1]) {
        return value[0] + unit;
      } else {
        return value[0] + unit + ' - ' + value[1] + unit;
      }
    },
    // 获取保存到筛选器中交易自定义筛选器的信息
    getCustomFiltersInformation(custom_filters, customFilterArray) {
      const customFiltersInformation = {};
      for (const key in custom_filters) {
        customFilterArray.find(item => {
          if (item.field_name === key) {
            if (Array.isArray(custom_filters[key])) {
              switch (item.type) {
                // 关于时间和选择范围的得做处理
                case 'date':
                  customFiltersInformation[item.name] = this.getCustomFilterDateRange(custom_filters[key]);
                  break;
                case 'integer':
                case 'float':
                  // 关于有选择范围的筛选器处理
                  customFiltersInformation[item.name] = this.getCustomFilterAmountRange(custom_filters[key]);
                  break;
                case 'checkbox':
                  customFiltersInformation[item.name] = this.checkboxValuesToLabels({
                    field_name: item.field_name,
                    values: custom_filters[key]
                  }, customFilterArray);
                  break;
                default:
                  customFiltersInformation[item.name] = this.arrayToString(custom_filters[key]);
                  break;
              }
            } else {
              if (item.type === 'single_checkbox') {
                customFiltersInformation[item.name] = this.getCustomFilterBoolean(custom_filters[key]);
              } else if (item.field_name === 'account_label') {
                customFiltersInformation[item.name] = this.getAccountLabelFilterName(custom_filters[key]);
              } else if (item.type === 'text') {
                customFiltersInformation[item.name] = this.getText(custom_filters[key]);
              } else {
                customFiltersInformation[item.name] = custom_filters[key];
              }
            }
          }
        });
      }
      return customFiltersInformation;
    },
    // 通过 custom_filters[key] 找到对应的 customFilterArray 中 type 为  checkbox 的 label
    checkboxValuesToLabels(_ref, customFilterArray) {
      var _customFilterArray$fi;
      let {
        field_name,
        values
      } = _ref;
      const options = (_customFilterArray$fi = customFilterArray.find(item => item.field_name === field_name)) === null || _customFilterArray$fi === void 0 ? void 0 : _customFilterArray$fi.options;
      const res = [];
      options.map(option => {
        values.map(value => {
          if (option.value === value) {
            res.push(option.label);
          }
        });
      });
      return this.arrayToString(res);
    },
    getAccountLabelFilterName(account_label) {
      const {
        relation,
        value
      } = account_label;
      return (relation === 'or' ? '或' : '且') + ' [ ' + value.flat().map(item => {
        var _this$accountLabelOpt;
        return ((_this$accountLabelOpt = this.accountLabelOptions.find(ele => ele.id === item)) === null || _this$accountLabelOpt === void 0 ? void 0 : _this$accountLabelOpt.name) || '';
      }).join('；') + ' ] ';
    },
    getAccountLabelArray(options, accountLabelArray) {
      options.forEach(ele => {
        accountLabelArray.push({
          id: ele.id,
          name: ele.name
        });
        if (ele.children) {
          this.getAccountLabelArray(ele.children, accountLabelArray);
        }
      });
      return accountLabelArray;
    },
    getCustomFilterBoolean(value) {
      return value ? '是' : '否';
    },
    getText(value) {
      if (Object.prototype.toString.call(value) === '[object Object]') {
        const text = value.value.join('；');
        return text.length > 100 ? text.slice(0, 100) + '...' : text;
      }
    },
    // 交易自定义筛选器 日期数据 的特殊处理
    getCustomFilterDateRange(value) {
      return value && (0, _timezone.datePickerConvertToZone)(value).join(' 至 ');
    },
    getScoreRuleConfigs() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const scoreConfigs = {};
      data.forEach(_ref2 => {
        let {
          name,
          type,
          score_range
        } = _ref2;
        scoreConfigs[name] = type === 'qualified' ? '合格' : type === 'unqualified' ? '不合格' : score_range.join('-') + '分';
      });
      return scoreConfigs;
    }
  }
};
exports.default = _default;