"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "knowledge-filter"
  }, [_c("el-cascader", {
    attrs: {
      size: "medium",
      options: _vm.knowledgeList,
      props: _vm.defaultProps,
      placeholder: "请选择知识点",
      "show-all-levels": false,
      "collapse-tags": _vm.collapseTags,
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;