"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    ref: "dealKeyEventPopoverRef",
    attrs: {
      placement: "bottom-start",
      "popper-class": _vm.popperClass,
      trigger: "click"
    }
  }, [_c("event-tracker", {
    ref: "eventTracker",
    attrs: {
      filters: _vm.value,
      "key-events": _vm.keyEvents
    },
    on: {
      addKeyEventCascader: _vm.addKeyEventCascader,
      change: _vm.handleChange
    }
  }), _c("el-button", {
    staticClass: "button-pretend-input",
    class: [_vm.chosenCount ? "button-has-content" : ""],
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("span", {
    staticClass: "label-text"
  }, [_vm._v(" " + _vm._s(_vm.chosenCount ? "关键事件" : "请选择关键事件"))]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.chosenCount,
      expression: "chosenCount"
    }],
    staticClass: "chosen-count"
  }, [_vm._v(_vm._s(_vm.chosenCount))]), _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.chosenCount,
      expression: "chosenCount"
    }],
    staticClass: "iconfont icon-circle-close",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.clearKeyEvent.apply(null, arguments);
      }
    }
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;