"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "task-record-drawer-content"
  }, [_c("MgvInfoAlert", {
    staticClass: "tip",
    attrs: {
      "alert-key": "task-record-drawer-tip",
      closable: false
    }
  }, [_vm._v(" " + _vm._s(_setup.t("coaching.courseTaskListTip")) + " ")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.tableLoading,
      expression: "tableLoading"
    }],
    ref: "table",
    staticClass: "task-record-table",
    attrs: {
      data: _setup.tableData,
      "row-key": "id"
    },
    on: {
      "row-click": _setup.onRowClick
    }
  }, _vm._l(_setup.headerList, function (item, index) {
    return _c("el-table-column", {
      key: item.prop + index,
      attrs: {
        prop: item.prop,
        label: item.label,
        width: item.width ? item.width : 180,
        "show-overflow-tooltip": item.showOverflowTip
      },
      scopedSlots: _vm._u([item.prop === "name" ? {
        key: "default",
        fn: function (_ref) {
          let {
            row
          } = _ref;
          return [_c("div", {
            staticClass: "task-name"
          }, [_vm._v(" " + _vm._s(row.name) + " ")])];
        }
      } : item.prop === "status" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            class: _setup.getStatusClass(row)
          }, [_vm._v(_vm._s(_setup.getStatus(row)))])];
        }
      } : item.prop === "creator_info" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("span", {
            staticClass: "creator_info"
          }, [_c(_setup.ImageAvatar, {
            attrs: {
              avatar: row.creator_info.avatar,
              name: row.creator_info.name
            }
          }), _vm._v(_vm._s(row.creator_info.name))], 1)];
        }
      } : item.prop === "user_infos" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c(_setup.ExecutorList, {
            attrs: {
              list: row.user_infos
            }
          }), _c("span", {
            staticClass: "finish-count"
          }, [_vm._v("( "), _c("span", {
            staticClass: "count"
          }, [_vm._v(_vm._s(`${row.finished}/${row.count}`))]), _vm._v(" " + _vm._s(_setup.t("coaching.finish")) + ")")])];
        }
      } : item.prop === "start_time" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_vm._v(" " + _vm._s(_setup.plus8ToCurrentZone(row.start_time).format("YYYY-MM-DD HH:mm")) + " ")];
        }
      } : null], null, true)
    });
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;