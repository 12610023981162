"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConversationFilterItem = _interopRequireDefault(require("@/views/conversationPage/components/ConversationFilterItem"));
var _GradeRange = _interopRequireDefault(require("@/views/conversationPage/components/GradeRange"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TradeScorePopoverFilter',
  components: {
    ConversationFilterItem: _ConversationFilterItem.default,
    GradeRange: _GradeRange.default
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isShowClear() {
      var _this$value;
      return !!((_this$value = this.value) !== null && _this$value !== void 0 && _this$value.type);
    },
    btnContent() {
      var _this$value2;
      const typeObj = {
        qualified: '合格',
        unqualified: '不合格',
        score_range: ''
      };
      let btnContent = '';
      if (this.value && ((_this$value2 = this.value) === null || _this$value2 === void 0 ? void 0 : _this$value2.type) === 'score_range') {
        btnContent = this.value.score_range.join(' - ');
      } else {
        var _this$value3;
        btnContent = typeObj[(_this$value3 = this.value) === null || _this$value3 === void 0 ? void 0 : _this$value3.type];
      }
      return btnContent;
    }
  },
  methods: {
    handlerScoreChange() {
      let val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        type: '',
        score_range: []
      };
      this.$emit('change', {
        [this.item.field_name]: val
      });
    }
  }
};
exports.default = _default;