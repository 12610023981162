"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "correct-text-dialog"
  }, [_c("el-dialog", {
    attrs: {
      visible: _vm.correctTextDialogVisible,
      "before-close": _vm.closeDialog,
      title: "修改",
      width: "600px"
    }
  }, [_c("el-form", {
    ref: "correct-text-form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "text"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 8
    },
    model: {
      value: _vm.form.text,
      callback: function ($$v) {
        _vm.$set(_vm.form, "text", $$v);
      },
      expression: "form.text"
    }
  })], 1)], 1), _vm.textSource === "conversation" ? _c("div", {
    staticClass: "info-tip"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _vm._v("文本修改会重新处理关键事件等模块，请耐心等待！ ")]) : _vm._e(), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.saveText
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;