"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ExportTableButton = _interopRequireDefault(require("../components/ExportTableButton.vue"));
var _ScoreAnalysisCard = _interopRequireDefault(require("./components/ScoreAnalysisCard.vue"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _sopAnalysis = require("@/api/sopAnalysis.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SopAnalysisDashboard',
  components: {
    ExportTableButton: _ExportTableButton.default,
    ScoreAnalysisCard: _ScoreAnalysisCard.default,
    NoData: _index.default
  },
  props: {
    defaultOpeneds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      vedioSrc: require('@/assets/sop-overview-guide.mp4'),
      filtersConfigLoading: false,
      filtersConfig: {
        SopAnalysisConversation: {},
        SopAnalysisTrade: {}
      },
      exportDialogVisible: false,
      guideDialogVisible: false,
      exportType: 'SopAnalysisConversation',
      exportMethod: () => {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'sopDateFilter']),
    isEmpty() {
      var _this$filtersConfig$S, _this$filtersConfig$S2;
      return !this.filtersConfigLoading && !((_this$filtersConfig$S = this.filtersConfig.SopAnalysisConversation.socre_rules) !== null && _this$filtersConfig$S !== void 0 && _this$filtersConfig$S.length) && !((_this$filtersConfig$S2 = this.filtersConfig.SopAnalysisTrade.socre_rules) !== null && _this$filtersConfig$S2 !== void 0 && _this$filtersConfig$S2.length);
    },
    getScoreAnalysisCardConfig() {
      return [{
        type: 'SopAnalysisConversation',
        label: '会话',
        isShow: this.defaultOpeneds.includes(_routeMap.ROUTES.SopAnalysisConversation),
        link: _routeMap.ROUTES.SopAnalysisConversation,
        getDataMethod: _sopAnalysis.getSopAnalysisConversationData
      }, {
        type: 'SopAnalysisTrade',
        label: '交易',
        isShow: this.defaultOpeneds.includes(_routeMap.ROUTES.SopAnalysisTrade),
        link: _routeMap.ROUTES.SopAnalysisTrade,
        getDataMethod: _sopAnalysis.getSopAnalysisTradeData
      }].filter(item => item.isShow);
    }
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        val && this.getFiltersConfig();
      },
      immediate: true
    },
    sopDateFilter() {
      setTimeout(() => {
        this.$refs['score-analysis-card'].forEach(item => item.getData());
      });
    }
  },
  created() {
    this.startVideoGuide();
  },
  methods: {
    handleKnown() {
      this.guideDialogVisible = false;
      localStorage.setItem('sopOverviewVedioGuide', true);
    },
    startVideoGuide() {
      if (localStorage.getItem('sopOverviewVedioGuide') !== 'true') {
        this.guideDialogVisible = true;
      }
    },
    openExportTableDialog() {
      if (this.getScoreAnalysisCardConfig.length > 1) {
        this.exportDialogVisible = true;
      } else {
        this.exportType = this.getScoreAnalysisCardConfig[0].type;
        this.exportTable();
      }
    },
    exportTable() {
      this.exportMethod = this.exportType === 'SopAnalysisConversation' ? _sopAnalysis.exportSopAnalysisConversationOverviewTableData : _sopAnalysis.exportSopAnalysisTradeOverviewTableData;
      this.$nextTick(() => {
        const index = this.getScoreAnalysisCardConfig.findIndex(item => item.type === this.exportType);
        const filters = this.$refs['score-analysis-card'][index].filters;
        this.$refs['export-table-button'].exportTable({
          body: filters,
          module: 'sop',
          column: this.exportType === 'SopAnalysisConversation' ? 'view_conv_score_analysis' : 'view_trade_score_analysis'
        });
        this.handleClose();
      });
    },
    handleClose() {
      this.exportDialogVisible = false;
    },
    async getFiltersConfig() {
      this.filtersConfigLoading = true;
      const res = await (0, _sopAnalysis.getSopAnalysisFiltersConfig)({
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.filtersConfigLoading = false;
      });
      if (res.code === 20000) {
        const {
          conv,
          trade
        } = res.results;
        this.filtersConfig.SopAnalysisConversation = conv;
        this.filtersConfig.SopAnalysisTrade = trade;
      }
    }
  }
};
exports.default = _default;