"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-pagination", _vm._g(_vm._b({
    staticClass: "pagination global-pagination",
    class: {
      "hide-last-page": _vm.hideLastPage && _vm.total >= _vm.hideCount
    },
    attrs: {
      "current-page": _vm.currentPage,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.pageSize,
      layout: "total, prev, pager, next, sizes",
      total: _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  }, "el-pagination", _vm.$attrs, false), _vm.$listeners));
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;