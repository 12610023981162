"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "after-close-conv"
  }, [_c("div", {
    staticClass: "content-wrapper"
  }, [_c("div", {
    staticClass: "main-box"
  }, [_c("div", {
    staticClass: "conv-info"
  }, [_vm._v(" " + _vm._s(_vm.convTitle) + " ")]), _c("div", {
    staticClass: "main-part"
  }, [_vm.deal_id ? _c("div", {
    staticClass: "chance-form"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v("会话关联的商机信息")]), _c("deal-form", {
    ref: "deal-form",
    attrs: {
      type: "edit",
      "is-after-conv": "",
      "deal-id": _vm.deal_id
    },
    on: {
      submitSucceed: _vm.handleSubmitSucceed
    }
  })], 1) : _vm._e(), _vm.lead_id && _vm.leadFormData ? _c("div", {
    staticClass: "chance-form"
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v("会话关联的线索信息")]), _c("lead-form", {
    ref: "lead-form",
    attrs: {
      type: "edit",
      "is-after-conv": "",
      "lead-id": _vm.lead_id
    },
    on: {
      submitSucceed: _vm.handleSubmitSucceed
    }
  })], 1) : _vm._e()])]), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    directives: [{
      name: "preventReClick",
      rawName: "v-preventReClick"
    }],
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("保 存")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;