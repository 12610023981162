"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _LeaderPlanHeader = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/LeaderPlanHeader"));
var _LeaderPlanHeaderFilter = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/LeaderPlanHeaderFilter"));
var _LeaderPlanTable = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leaderPlan/LeaderPlanTable"));
var _SimpleTabs = _interopRequireDefault(require("./SimpleTabs.vue"));
var _CourseAnalysisTable = _interopRequireDefault(require("./CourseAnalysisTable.vue"));
var _download = require("@/utils/download");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  // 团队视图页面
  name: 'CoachingCenterLeaderPlan',
  components: {
    LeaderPlanHeader: _LeaderPlanHeader.default,
    LeaderPlanHeaderFilter: _LeaderPlanHeaderFilter.default,
    LeaderPlanTable: _LeaderPlanTable.default,
    SimpleTabs: _SimpleTabs.default,
    CourseAnalysisTable: _CourseAnalysisTable.default
  },
  data() {
    return {
      requestParams: {
        id: 0,
        page: 1,
        size: 10,
        order_field: '',
        order_type: '',
        is_complete: -1,
        score: [],
        tree_ids: [],
        user_ids: []
      },
      tableHead: [],
      tableData: [],
      tableLoading: false,
      totalCount: 0,
      taskInfo: {
        average_score: -1
      },
      leaderPlanTabs: [{
        name: 'completion',
        label: this.$t('coaching.completionStatus')
      }, {
        name: 'courseAnalysis',
        label: this.$t('coaching.courseAnalysis')
      }],
      activeLeaderPlanTab: 'completion',
      courseAnalysisTableLoading: false,
      courseAnalysisTableData: []
    };
  },
  created() {
    this.requestParams.id = this.$route.query.id;
    this.getTrainPlan({
      id: Number(this.requestParams.id)
    });
    this.getLeaderPlanList();
  },
  methods: {
    selectMember(val) {
      this.requestParams.tree_ids = val.tree_ids;
      this.requestParams.user_ids = val.user_ids;
      this.initData();
      this.getLeaderPlanList();
    },
    async getTrainPlan(data) {
      const res = await (0, _coachingCenter.getTrainPlan)(data);
      if (res.code === 20000) {
        this.taskInfo = res.results.data;
        this.getCourseAnalysisList();
      }
    },
    tableSortChange(val) {
      this.requestParams.order_field = val.prop;
      this.requestParams.order_type = val.order;
      this.initData();
      this.getLeaderPlanList();
    },
    async getLeaderPlanList() {
      this.tableLoading = true;
      this.requestParams.id = Number(this.requestParams.id);
      if (this.requestParams.is_complete === '') {
        this.requestParams.is_complete = -1;
      }
      const res = await (0, _coachingCenter.getLeaderPlanList)(this.requestParams).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        const {
          head,
          data,
          total_count
        } = res.results;
        this.tableHead = head;
        this.tableData = data;
        this.totalCount = total_count;
      }
    },
    initData() {
      this.requestParams.page = 1;
      this.tableLoading = false;
      this.totalCount = 0;
      this.tableData = [];
    },
    tableSizeChange(val) {
      this.requestParams.size = val;
      this.initData();
      this.getLeaderPlanList();
    },
    tableCurrentPageChange(val) {
      this.requestParams.page = val;
      this.getLeaderPlanList();
    },
    filterChange(val) {
      this.requestParams = {
        ...this.requestParams,
        ...val
      };
      this.initData();
      this.getLeaderPlanList();
    },
    async exportTable() {
      const res = await (0, _coachingCenter.exportPlanResults)(this.requestParams);
      if (res.code === 20000) {
        this.$message.success(this.$t('coaching.exportSuccessful'));
        (0, _download.downloadFileRename)(res.results.file_url, this.taskInfo.course_name);
      }
    },
    async getCourseAnalysisList(order_field, order_type) {
      if (this.taskInfo.course_type !== 3) return;
      this.courseAnalysisTableLoading = true;
      const res = await (0, _coachingCenter.getCourseAnalysisList)({
        plan_id: Number(this.requestParams.id),
        order_field: order_field || '',
        order_type: order_type || ''
      }).finally(() => {
        this.courseAnalysisTableLoading = false;
      });
      if (res.code === 20000) {
        this.courseAnalysisTableData = res.results.data;
      }
    },
    handleAllUrgeSuccess() {
      this.taskInfo.has_urged = 1;
    }
  }
};
exports.default = _default;