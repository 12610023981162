"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "coach-record-list"
  }, [_vm._l(_vm.recordList, function (item, index) {
    return _c("coach-detail-card", {
      key: index,
      ref: item.is_light ? "highlight" : "",
      refInFor: true,
      attrs: {
        item: item
      }
    });
  }), !_vm.recordList.length ? _c("no-data-holder", {
    attrs: {
      text: "暂无30天内接受辅导记录"
    }
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;