"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _library = require("@/api/library");
var _UploadMediaDialogHead = _interopRequireDefault(require("@/components/UploadMediaDialog/UploadMediaDialogHead.vue"));
var _UploadMedia = _interopRequireDefault(require("@/components/UploadMediaDialog/UploadMedia.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UploadLibraryFileDialog',
  components: {
    UploadMedia: _UploadMedia.default,
    UploadMediaDialogHead: _UploadMediaDialogHead.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentNode: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fileList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId', 'name'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.$store.commit('upload/SET_UPLOAD_HOST_SALESMAN_INFO', {
          id: this.userId,
          name: this.name
        });
        this.$store.dispatch('upload/getUploadDuration', this.userId);
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false);
    },
    updateFileList(list) {
      this.fileList = list;
    },
    cancel() {
      if (this.fileList.length) {
        this.$confirm('确认取消上传？', this.$t('coaching.prompt'), {
          confirmButtonText: '取消上传',
          cancelButtonText: this.$t('coaching.back'),
          type: 'warning',
          confirmButtonClass: 'confirmClass',
          cancelButtonClass: 'cancelClass'
        }).then(() => {
          this.clearData();
          this.closeDialog();
        });
      } else {
        this.closeDialog();
      }
    },
    async submit() {
      if (!this.fileList.every(item => item.video_id)) {
        this.$message.warning('音视频还未上传成功');
        return;
      }
      if (!this.fileList.length) {
        this.$message.warning('未上传音视频');
        return;
      }
      const params = {
        folder_id: this.currentNode.id,
        videos: this.fileList.map(item => ({
          id: item.video_id,
          name: item.name.split('.')[0]
        })),
        space_id: this.workspaceInfo.id
      };
      const res = await (0, _library.uploadMediaFile)(params);
      if (res.code === 20000) {
        this.clearData();
        this.closeDialog();
        this.$emit('uploadSuccess');
      }
    },
    clearData() {
      this.$nextTick(() => {
        this.$bus.$emit('abortUpload');
      });
      this.$refs.uploadMedia.clearData();
      this.fileList = [];
    }
  }
};
exports.default = _default;