"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "lead-detail"
  }, [_c("detail-header", {
    attrs: {
      title: _vm.clueObject.name,
      "back-button": ""
    },
    scopedSlots: _vm._u([{
      key: "header_left_bottom",
      fn: function () {
        return [_c("div", {
          staticClass: "top-left"
        }, [_c("div", [_c("span", {
          staticClass: "clue-status-desc"
        }, [_vm._v("线索状态：")]), _vm.clueObject.status === "converted" ? _c("span", {
          staticClass: "clue-status"
        }, [_vm._v("已转换")]) : _c("span", {
          staticClass: "clue-status no-clue-status"
        }, [_vm._v("未转换")])]), _vm.clueObject.status === "converted" ? _c("span", {
          staticClass: "divide-line"
        }) : _vm._e(), _vm.clueObject.status === "converted" ? _c("div", [_c("span", [_vm._v("客户名称：")]), _c("span", {
          staticClass: "account-name",
          on: {
            click: _vm.goAccountDetail
          }
        }, [_vm._v(_vm._s(_vm.customerName))])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "header_right",
      fn: function () {
        return [_c("div", {
          staticClass: "right-btn"
        }, [_c("clue-operate-buttons", {
          attrs: {
            "operate-buttons": _vm.operateButtons,
            "row-info": _vm.clueObject,
            "active-id": _vm.clueId
          },
          on: {
            clickContactPersonItem: _vm.clickContactPersonItem,
            changeactiveClueId: _vm.changeactiveClueId
          }
        }), _c("div", {
          staticClass: "top-right"
        }, [_c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: _vm.handleMoreCommand
          }
        }, [_c("el-button", {
          staticClass: "mg-button hover-show-bg",
          attrs: {
            plain: "",
            icon: "el-icon-more"
          }
        }), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            command: "edit"
          }
        }, [_c("i", {
          staticClass: "el-icon-edit"
        }), _vm._v("查看详情 ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "main-part"
  }, [_c("div", {
    staticClass: "calendar-directory",
    class: {
      "calender-fold": _vm.isCalenderFold
    }
  }, [_vm.infoObject ? _c("clue-calender-directoty", {
    attrs: {
      "is-calender-fold": _vm.isCalenderFold,
      "info-object": _vm.infoObject
    },
    on: {
      "update:isCalenderFold": function ($event) {
        _vm.isCalenderFold = $event;
      },
      "update:is-calender-fold": function ($event) {
        _vm.isCalenderFold = $event;
      }
    }
  }) : _vm._e()], 1), _vm.timelineArray && _vm.timelineArray.length > 0 ? _c("div", {
    staticClass: "flex-container",
    class: {
      "fold-height": _vm.isCalenderFold
    }
  }, [_c("div", {
    staticClass: "left-list"
  }, [_c("items-flow", {
    attrs: {
      "timeline-array": _vm.timelineArray,
      "customer-name": _vm.clueObject.name
    }
  })], 1), _c("div", {
    staticClass: "center-detail"
  }, [_c("item-detail", {
    attrs: {
      "customer-name": _vm.customerName,
      "detail-obj": _vm.getActiveDetail
    }
  })], 1)]) : _vm._e(), !_vm.timelineArray || _vm.timelineArray && _vm.timelineArray.length === 0 ? _c("div", {
    staticClass: "no-content"
  }, [_c("p", {
    staticClass: "no-text"
  }, [_vm._v("暂无会话")])]) : _vm._e()]), _c("clue-detail-drawer", {
    attrs: {
      visible: _vm.clueDetailDrawerVisible,
      "clue-object": _vm.clueObject,
      "clue-dynamic-form-desc": _vm.formDesc,
      "clue-id": _vm.clueId
    },
    on: {
      closeDrawer: _vm.closeDetailDrawer,
      changeFollowUpby: _vm.allocateToAnyone
    }
  }), _c("clues-allot-dialog", _vm._b({
    attrs: {
      "dialog-visible": _vm.allotDialogVisible
    },
    on: {
      closeDialog: function ($event) {
        _vm.allotDialogVisible = false;
      },
      refreshFollower: _vm.refreshDetail
    }
  }, "clues-allot-dialog", _vm.allotDialogProps, false)), _vm.has_video_permission ? _c("meeting-dialog", _vm._b({
    attrs: {
      title: _vm.meetingDialogTitle,
      "dialog-visible": _vm.meetingDialogVisible
    },
    on: {
      closeDialog: function ($event) {
        _vm.meetingDialogVisible = false;
      }
    }
  }, "meeting-dialog", _vm.meetingProps, false)) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;