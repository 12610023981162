"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SelectViewerType',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      typeOptions: [{
        value: 1,
        name: '个人视图',
        describe: '只有自己可查看',
        icon: 'icon-user-solid'
      }, {
        value: 2,
        name: '公开视图',
        describe: '空间所有人可查看、编辑、复制和删除',
        icon: 'icon-team-fill'
      }]
    };
  },
  methods: {
    selectActiveType(value) {
      this.$emit('change', value);
    }
  }
};
exports.default = _default;