"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _organization = require("@/api/organization");
var _default = {
  name: 'OpenNumberDesensibilisation',
  props: {
    isDesensitizationOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openNumberDesensitization: false
    };
  },
  watch: {
    isDesensitizationOpen() {
      this.openNumberDesensitization = this.isDesensitizationOpen;
    }
  },
  methods: {
    async setRoleChangeDesensitization(data) {
      const res = await (0, _organization.setRoleChangeDesensitization)(data);
      if (res.code === 20000) {
        this.$message.success('操作成功');
      }
    },
    handleChange(val) {
      this.$confirm(val ? '开启后，号码将会脱敏处理（例如：188****8888）' : '关闭后，号码将会全量展示。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.openNumberDesensitization = val;
        this.setRoleChangeDesensitization({
          status: this.openNumberDesensitization
        });
      }).catch();
    }
  }
};
exports.default = _default;