"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "form",
    staticClass: "field-right-form",
    attrs: {
      model: _vm.form,
      "hide-required-asterisk": "",
      "label-width": "80px",
      "label-position": "left",
      rules: _vm.rules
    }
  }, [_c("div", [_vm.desc.radios ? _c("el-form-item", {
    staticClass: "no-label radio-item",
    attrs: {
      prop: "from_type"
    }
  }, _vm._l(_vm.desc.radios, function (item, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: item.value,
        disabled: _vm.isEdit
      },
      model: {
        value: _vm.form.from_type,
        callback: function ($$v) {
          _vm.$set(_vm.form, "from_type", $$v);
        },
        expression: "form.from_type"
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("字段名称 "), _c("span", {
          staticClass: "dot",
          attrs: {
            slot: "label-suffix"
          },
          slot: "label-suffix"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c("el-input", {
    ref: "name-input",
    attrs: {
      maxlength: "32",
      placeholder: "请输入字段名称"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "tips",
      label: "提示文字"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "500",
      placeholder: "请输入提示文字"
    },
    model: {
      value: _vm.form.tips,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tips", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.tips"
    }
  }), _c("p", {
    staticClass: "explain-text"
  }, [_vm._v("显示在字段名右侧的说明文字")])], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.desc.options,
      expression: "desc.options"
    }],
    attrs: {
      prop: "options"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("选项内容 "), _c("span", {
          staticClass: "dot"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c("drag-inputs", {
    attrs: {
      "max-count": "100",
      "label-text": "选项"
    },
    on: {
      sender: _vm.handleSender
    },
    model: {
      value: _vm.form.options,
      callback: function ($$v) {
        _vm.$set(_vm.form, "options", $$v);
      },
      expression: "form.options"
    }
  })], 1), _vm.form.from_type === "money" || _vm.form.from_type === "number" ? _c("el-form-item", {
    attrs: {
      label: "小数点位数"
    }
  }, [_c("el-select", {
    staticClass: "precisions-select",
    attrs: {
      placeholder: "请选择小数点位数"
    },
    model: {
      value: _vm.form.precisions,
      callback: function ($$v) {
        _vm.$set(_vm.form, "precisions", $$v);
      },
      expression: "form.precisions"
    }
  }, _vm._l(_vm.precisionsList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm.form.from_type === "money" ? _c("el-form-item", {
    staticClass: "no-label width-change"
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.form.is_thousand_separate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_thousand_separate", $$v);
      },
      expression: "form.is_thousand_separate"
    }
  }, [_vm._v("千分位分隔")])], 1) : _vm._e(), _vm.systemPresetShow ? _c("el-form-item", {
    staticClass: "no-label"
  }, [_c("div", {
    staticClass: "system-preset"
  }, [_c("el-checkbox", {
    attrs: {
      value: true,
      disabled: ""
    }
  }), _vm._v("系统预设的手机号和邮箱至少有一项为必填 ")], 1)]) : _c("el-form-item", {
    staticClass: "no-label width-change",
    attrs: {
      prop: "is_require"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.form.is_require,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_require", $$v);
      },
      expression: "form.is_require"
    }
  }, [_vm._v("设为必填")])], 1), _vm.form.from_type === "text" ? _c("el-form-item", {
    staticClass: "batch-search width-change",
    attrs: {
      prop: "is_batch"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.form.is_batch,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_batch", $$v);
      },
      expression: "form.is_batch"
    }
  }, [_vm._v("批量搜索"), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "可以在筛选数据时做批量搜索",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1)], 1) : _vm._e(), _vm.desc.unique ? _c("el-form-item", {
    staticClass: "no-label",
    attrs: {
      prop: "is_unique"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.form.is_unique,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_unique", $$v);
      },
      expression: "form.is_unique"
    }
  }, [_vm._v("该字段不可重复")])], 1) : _vm._e(), false ? _c("el-form-item", {
    staticClass: "no-label",
    attrs: {
      prop: "is_hidden"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.form.is_hidden,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_hidden", $$v);
      },
      expression: "form.is_hidden"
    }
  }, [_vm._v("默认收起")])], 1) : _vm._e(), _vm.form.from_type === "money" ? _c("el-divider") : _vm._e(), _vm.form.from_type === "money" ? _c("el-form-item", {
    staticClass: "amount-preview",
    attrs: {
      label: "预览"
    }
  }, [_vm._v(" " + _vm._s(_vm.amountFormatExample) + " ")]) : _vm._e()], 1), _c("el-form-item", {
    staticClass: "button-item"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;