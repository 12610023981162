"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const state = {
  isShowStageIIComplianceWorkSpaceGuide: false
};
const mutations = {
  SET_SHOW_STATE_II_COMPLIANCE_WORKSPACE_GUIDE: function (state) {
    let isShowStageIIComplianceWorkSpaceGuide = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    state.isShowStageIIComplianceWorkSpaceGuide = isShowStageIIComplianceWorkSpaceGuide;
  }
};
var _default = {
  namespaced: true,
  state,
  mutations
};
exports.default = _default;