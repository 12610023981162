"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteSeeLater = deleteSeeLater;
exports.getBoxList = getBoxList;
exports.getConvList = getConvList;
exports.getFunnelData = getFunnelData;
exports.getMainDepartment = getMainDepartment;
exports.getOpenedClue = getOpenedClue;
exports.getQuarterlyPerformance = getQuarterlyPerformance;
exports.getRecentConversations = getRecentConversations;
exports.getRecentSchedule = getRecentSchedule;
exports.getSeeLaterDoneList = getSeeLaterDoneList;
exports.getSeeLaterList = getSeeLaterList;
exports.getStatisticData = getStatisticData;
exports.getTodoList = getTodoList;
exports.getUpcomingDeal = getUpcomingDeal;
exports.getUpcomingMeetings = getUpcomingMeetings;
exports.ignoreSeeLater = ignoreSeeLater;
exports.setTodoTime = setTodoTime;
exports.viewSeeLater = viewSeeLater;
var _request = require("@/utils/request");
// 销售漏斗数据
function getFunnelData(begin_date, end_date) {
  return (0, _request.request)({
    url: '/dashboard/funnel_data',
    method: 'get',
    params: {
      begin_date,
      end_date
    }
  });
}

// 最近会话
function getRecentConversations() {
  return (0, _request.request)({
    url: '/dashboard/recent_conversations',
    method: 'get'
  });
}

// 季度业绩
function getQuarterlyPerformance(begin_date, end_date) {
  return (0, _request.request)({
    url: '/dashboard/quarterly_performance',
    method: 'get',
    params: {
      begin_date,
      end_date
    }
  });
}

// 获取统计数据
function getStatisticData() {
  return (0, _request.request)({
    url: '/dashboard/statistic',
    method: 'get'
  });
}

// 获取即将成交的商机
function getUpcomingDeal() {
  return (0, _request.request)({
    url: '/dashboard/upcoming_deal',
    method: 'get'
  });
}

// 获取打开的线索
function getOpenedClue() {
  return (0, _request.request)({
    url: '/dashboard/opened_lead',
    method: 'get'
  });
}

// 获取主部门
function getMainDepartment() {
  return (0, _request.request)({
    url: '/dashboard/main_department',
    method: 'get'
  });
}

// 获取最近日程
function getRecentSchedule() {
  return (0, _request.request)({
    url: '/dashboard/recent_schedule',
    method: 'get'
  });
}

// 获取会话列表
function getConvList(url, params) {
  return (0, _request.request)({
    url,
    method: 'get',
    params
  });
}

// 获取待办
function getTodoList(data) {
  return (0, _request.webService)({
    url: '/notification_center/todo/list',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获取收件箱/发件箱列表
function getBoxList(params) {
  return (0, _request.webService)({
    url: '/notification_center/box/list',
    method: 'get',
    params
  });
}

// 获取稍后再看列表
function getSeeLaterList(url, params) {
  return (0, _request.request)({
    url,
    method: 'get',
    params
  });
}

// 从稍后再看中移除
function deleteSeeLater(id) {
  return (0, _request.webService)({
    url: '/notification_center/todo/delete',
    method: 'post',
    data: {
      id
    }
  });
}

// 设置为已查看
function viewSeeLater(id) {
  return (0, _request.webService)({
    url: '/notification_center/todo/complete',
    method: 'post',
    data: {
      id
    }
  });
}

// 设置为已忽略
function ignoreSeeLater(id) {
  return (0, _request.webService)({
    url: '/notification_center/todo/ignore',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取所有已查看和已忽略
function getSeeLaterDoneList() {
  return (0, _request.request)({
    url: '/home/todo/finished',
    method: 'get'
  });
}

// 设置待办时间
function setTodoTime(data) {
  return (0, _request.webService)({
    url: '/notification_center/todo/set_time',
    method: 'post',
    data
  });
}

// 获取已预约的会议

function getUpcomingMeetings() {
  return (0, _request.request)({
    url: '/home/meetings',
    method: 'get'
  });
}