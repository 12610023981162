"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _vue = require("vue");
var _useVuex = require("@/hooks/use-vuex");
var _SentenceImage = _interopRequireDefault(require("./SentenceImage.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SentenceItem',
  props: {
    taskType: {
      type: Number,
      default: 0
    },
    sentence: {
      type: Object,
      default: () => ({})
    },
    imageWidth: {
      type: Number,
      default: 300
    },
    imageAppendToBody: {
      type: Boolean,
      default: false
    },
    customerAvatar: {
      type: String,
      default: ''
    },
    practiceRule: {
      type: Object,
      default: () => ({})
    },
    currentPlayingAudioId: {
      type: [String, Number],
      default: ''
    },
    isSentenceRecords: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: 'bottom-end'
    }
  },
  emits: ['playAudio'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const store = (0, _useVuex.useStore)();
    const {
      avatar,
      name
    } = store.getters;
    const getScoreClass = _ref2 => {
      let {
        score,
        pass_score
      } = _ref2;
      return score >= pass_score ? 'green' : 'red';
    };
    const referencePopover = (0, _vue.ref)(null);
    (0, _vue.watch)(() => props.sentence.score_loading, () => {
      (0, _vue.nextTick)(() => {
        var _referencePopover$val;
        (_referencePopover$val = referencePopover.value) === null || _referencePopover$val === void 0 ? void 0 : _referencePopover$val.updatePopper();
      });
    });
    return {
      __sfc: true,
      emit,
      props,
      store,
      avatar,
      name,
      getScoreClass,
      referencePopover,
      t: _i18n.t,
      SentenceImage: _SentenceImage.default
    };
  }
};
exports.default = _default;