"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "template-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "tableRef",
    attrs: {
      data: _vm.data,
      "row-key": "id",
      "highlight-current-row": ""
    }
  }, [_vm._l(_setup.headers, function (header) {
    return _c("el-table-column", {
      key: header.prop,
      attrs: {
        label: header.label,
        "min-width": header.minWidth
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          let {
            row,
            $index
          } = _ref;
          return [header.prop === "drag" ? [_c("i", {
            staticClass: "iconfont move icon-drag-dot"
          })] : _vm._e(), header.prop === "order" ? [_c("span", [_vm._v(_vm._s($index + 1))])] : _vm._e(), header.prop === "departments" ? [_c(_setup.VirtualMoreUserListInPopover, {
            attrs: {
              list: row.departments,
              "exposed-count": 2,
              "append-to-body": ""
            },
            scopedSlots: _vm._u([{
              key: "exposed-avatar",
              fn: function (_ref2) {
                let {
                  exposedItem
                } = _ref2;
                return [_c("MgvTag", {
                  attrs: {
                    icon: "iconfont icon-department",
                    round: ""
                  }
                }, [_vm._v(" " + _vm._s(exposedItem.name) + " ")])];
              }
            }, {
              key: "virtual-list-item",
              fn: function (_ref3) {
                let {
                  virtualListItem
                } = _ref3;
                return [_c("MgvTag", {
                  attrs: {
                    icon: "iconfont icon-department",
                    round: ""
                  }
                }, [_vm._v(" " + _vm._s(virtualListItem.name) + " ")])];
              }
            }], null, true)
          })] : _vm._e(), header.prop === "create_by" ? [_c("MgvAvatarName", {
            attrs: {
              size: 20,
              src: row.user_info.avatar,
              name: row.user_info.name
            }
          }), _c("span", {
            staticClass: "name"
          }, [_vm._v(_vm._s(row.user_info.name))])] : _vm._e(), header.prop === "create_at" ? [_vm._v(" " + _vm._s(_vm.$plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm")) + " ")] : _vm._e(), header.prop === "name" ? [_vm._v(" " + _vm._s(row[header.prop]) + " ")] : _vm._e()];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      "class-name": "operate-table-head",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row,
          $index
        } = _ref4;
        return [_c(_setup.MgvButton, {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _setup.openEditTemplateDialog(row);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c(_setup.MgvButton, {
          attrs: {
            type: "text",
            color: "danger"
          },
          on: {
            click: function ($event) {
              return _setup.deleteTemplate(row, $index);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;