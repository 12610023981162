"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$item$instruction_, _vm$item$instruction_2, _vm$item$instruction_3, _vm$item$summary_resu, _vm$item$summary_resu2, _vm$item$summary_resu3, _vm$item$summary_resu4, _vm$item$summary_resu5, _vm$item$summary_resu6;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "converItem" + _vm.getHandledItem.id,
    staticClass: "list-card-item",
    class: [_vm.isIframe ? "narrower" : "", _vm.isBaseConvInDrawer ? "is-in-conv-flow" : "", {
      "hight-light": _vm.getHandledItem.highlight
    }]
  }, [_c("div", {
    staticClass: "card-top",
    on: {
      click: function ($event) {
        return _vm.goDetails(_vm.getHandledItem.id);
      }
    }
  }, [!_vm.isIframe ? _c("i", {
    class: ["medium-icon", "iconfont", _vm.getMediumIcon(_vm.getHandledItem.conversation_type)]
  }) : _vm._e(), _c("div", {
    staticClass: "base-info"
  }, [_c("div", {
    staticClass: "item-logo-title"
  }, [_vm.getHandledItem.origin_type === "wework_internal_app" ? _c("img", {
    staticClass: "item-logo",
    attrs: {
      src: require("@/assets/wework.svg"),
      alt: "logo"
    }
  }) : _vm.getHandledItem.origin_type === "tencent_meeting" ? _c("img", {
    staticClass: "item-logo",
    attrs: {
      src: require("@/assets/tencent_meeting.svg"),
      alt: "logo"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.getHandledItem.name))]), _vm.getHandledItem.compliance_status && _vm.interfacePermission.getConvEvaluationRecord ? _c("span", {
    staticClass: "compliance-status",
    class: _vm.getComplianceStatusIcon(_vm.getHandledItem.compliance_status)
  }, [_c("i", {
    class: `iconfont icon-${_vm.getComplianceStatusIcon(_vm.getHandledItem.compliance_status)}`
  }), _vm._v(_vm._s(_vm.getComplianceStatus(_vm.getHandledItem.compliance_status)))]) : _vm._e()]), _c("div", {
    staticClass: "item-infos"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话归属人",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [_vm.getHandledItem.host ? _c("div", {
    staticClass: "info-content"
  }, [_vm.getHandledItem.host.avatar || _vm.getHandledItem.host.name ? _c("image-avatar", {
    attrs: {
      avatar: _vm.getHandledItem.host.avatar,
      name: _vm.getHandledItem.host.name
    }
  }) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.getHandledItem.host.name))])], 1) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话时长",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [_vm.getHandledItem.conversation_type !== "doc" ? _c("div", {
    staticClass: "info-tag duration",
    domProps: {
      innerHTML: _vm._s(_vm.getDuration(_vm.getHandledItem.duration))
    }
  }) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "会话日期和时间",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [_c("div", {
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-date i-light-color"
  }), _c("span", {
    staticClass: "date-light-color"
  }, [_vm._v(_vm._s(_vm.getDate(_vm.getHandledItem.date)))])])]), !_vm.isIframe ? _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "评论数",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getHandledItem.comment_count,
      expression: "getHandledItem.comment_count"
    }],
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-chat-line-square"
  }), _c("span", [_vm._v(_vm._s(_vm.getHandledItem.comment_count))])])]) : _vm._e(), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "交易名称",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [!_vm.isIframe && _vm.modulePermission.trade ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getHandledItem.entity_type === "deal" && _vm.getHandledItem.entity_status,
      expression: "\n              getHandledItem.entity_type === 'deal' &&\n              getHandledItem.entity_status\n            "
    }],
    staticClass: "info-tag deal-tag"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-deal4"
  }), _c("span", [_vm._v(_vm._s(_vm.getHandledItem.deal_name))])]) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      content: "会话时交易阶段",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [!_vm.isIframe && _vm.modulePermission.trade ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getHandledItem.entity_type === "deal" && _vm.getHandledItem.entity_status,
      expression: "\n              getHandledItem.entity_type === 'deal' &&\n              getHandledItem.entity_status\n            "
    }],
    staticClass: "info-tag"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-deal5"
  }), _c("span", [_vm._v(_vm._s(_vm.getHandledItem.entity_status))])]) : _vm._e()]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "客户",
      placement: "top",
      "open-delay": 200,
      enterable: false
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getHandledItem.company_name,
      expression: "getHandledItem.company_name"
    }],
    staticClass: "info-content"
  }, [_c("i", {
    staticClass: "info-icon iconfont icon-office-building"
  }), _c("span", [_vm._v(_vm._s(_vm.getHandledItem.company_name))])])])], 1)]), _c("div", {
    staticClass: "key-events",
    class: _vm.isIframe ? "wider" : ""
  }, [_vm.getHandledItem.event_engines_complete ? _c("div", {
    staticClass: "key-title"
  }, [_c("span", {
    staticClass: "item-child-text"
  }, [_vm._v("共")]), _c("span", {
    staticClass: "key-number",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.openEventKeyDrawer(_vm.getHandledItem.id, _vm.getHandledItem.conversation_type, _vm.getHandledItem.participants, false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getHandledItem.event_engine_count) + " ")]), _c("span", {
    staticClass: "item-child-text"
  }, [_vm._v("个")])]) : _vm._e(), !_vm.getHandledItem.event_engines_complete ? _c("div", {
    staticClass: "keyevent-loading"
  }, [_vm._v(" " + _vm._s(_vm.eventLoadingText) + " ")]) : _vm.getHandledItem.event_engines_complete && _vm.getHandledItem.event_engines && _vm.getHandledItem.event_engines.length > 0 ? _c("div", {
    staticClass: "list-wrapper"
  }, [_vm._l(_vm.getHandledItem.event_engines, function (event, index) {
    return _c("div", {
      key: index,
      staticClass: "key-event-list"
    }, [_c("el-button", {
      staticClass: "event-button",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openEventKeyDrawer(_vm.getHandledItem.id, _vm.getHandledItem.conversation_type, _vm.getHandledItem.participants, true, event.id);
        }
      }
    }, [_vm._v(" " + _vm._s(event.type) + " "), _c("span", {
      staticClass: "divide-line"
    }, [_vm._v("|")]), _vm._v(" " + _vm._s(event.count) + " ")])], 1);
  }), _c("div", {
    staticClass: "key-event-list"
  }, [_vm.getHandledItem.event_more_count ? _c("el-button", {
    staticClass: "event-button",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.openEventKeyDrawer(_vm.getHandledItem.id, _vm.getHandledItem.conversation_type, _vm.getHandledItem.participants, false);
      }
    }
  }, [_vm._v("+" + _vm._s(_vm.getHandledItem.event_more_count))]) : _vm._e()], 1)], 2) : _vm.getHandledItem.event_engines_complete && _vm.getHandledItem.event_engines && _vm.getHandledItem.event_engines.length === 0 ? _c("div", {
    staticClass: "no-keyevent"
  }, [_vm._v(" 暂无关键事件 ")]) : _vm._e()]), _vm.getHasExtractInfo() ? _c("el-popover", {
    attrs: {
      "popper-class": "extract-info-popover-container",
      placement: "bottom-start",
      width: "350",
      trigger: "hover",
      "open-delay": 500,
      disabled: !((_vm$item$instruction_ = _vm.item.instruction_set_result) !== null && _vm$item$instruction_ !== void 0 && _vm$item$instruction_.length)
    }
  }, [_c("div", {
    staticClass: "extract-info-results"
  }, _vm._l(_vm.item.instruction_set_result, function (str, index) {
    return _c("p", {
      key: index
    }, [_vm._v(" " + _vm._s(str) + " ")]);
  }), 0), _c("div", {
    staticClass: "extract-info-review",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [(_vm$item$instruction_2 = _vm.item.instruction_set_result) !== null && _vm$item$instruction_2 !== void 0 && _vm$item$instruction_2.length ? _c("span", {
    on: {
      click: [_vm.goExtractInfo, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" " + _vm._s((_vm$item$instruction_3 = _vm.item.instruction_set_result) === null || _vm$item$instruction_3 === void 0 ? void 0 : _vm$item$instruction_3.join(";")) + " ")]) : _c("span", [_vm._v("-")])])]) : _vm._e(), _vm.getHasSummary() ? _c("el-popover", {
    attrs: {
      "popper-class": "ai-summary-popover-container",
      placement: "bottom-start",
      width: "350",
      trigger: "hover",
      content: (_vm$item$summary_resu = _vm.item.summary_result) === null || _vm$item$summary_resu === void 0 ? void 0 : _vm$item$summary_resu.result,
      "open-delay": 500,
      disabled: !((_vm$item$summary_resu2 = _vm.item.summary_result) !== null && _vm$item$summary_resu2 !== void 0 && (_vm$item$summary_resu3 = _vm$item$summary_resu2.result) !== null && _vm$item$summary_resu3 !== void 0 && _vm$item$summary_resu3.length)
    }
  }, [_c("div", {
    staticClass: "words-counts",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [(_vm$item$summary_resu4 = _vm.item.summary_result) !== null && _vm$item$summary_resu4 !== void 0 && _vm$item$summary_resu4.result ? _c("span", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("MgvButton", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.goSummary
    }
  }, [_vm._v(_vm._s(`${(_vm$item$summary_resu5 = _vm.item.summary_result) === null || _vm$item$summary_resu5 === void 0 ? void 0 : (_vm$item$summary_resu6 = _vm$item$summary_resu5.result) === null || _vm$item$summary_resu6 === void 0 ? void 0 : _vm$item$summary_resu6.length}字`))])], 1) : _c("span", [_vm._v("-")])])]) : _vm._e(), !_vm.isInQualityTesting && !_vm.isBaseConvInDrawer ? [_vm._l(_vm.conversationScoreHead, function (_ref) {
    let {
      field_name,
      id
    } = _ref;
    return _c("div", {
      key: id,
      class: "score" + ` column_${id}`
    }, [_vm.item[field_name] && _vm.item[field_name].item.length ? _c("GradeProgressPopover", {
      attrs: {
        "score-rule": _vm.item[field_name]
      },
      on: {
        goScoreDetail: function ($event) {
          return _vm.goConversationScoreDetail("conversation_score");
        }
      }
    }) : _c("span", [_vm._v("无评分")])], 1);
  }), _vm._l(_vm.complianceScoreHead, function (_ref2) {
    let {
      field_name,
      id
    } = _ref2;
    return _c("div", {
      key: id,
      class: "score" + ` column_${id}`
    }, [_vm.item[field_name] && _vm.item[field_name].item.length ? _c("GradeProgressPopover", {
      attrs: {
        "score-rule": _vm.item[field_name]
      },
      on: {
        goScoreDetail: function ($event) {
          return _vm.goConversationScoreDetail("compliance_score");
        }
      }
    }) : _c("span", [_vm._v("无评分")])], 1);
  })] : _vm._e()], 2), _vm.isBaseConvInDrawer || _vm.isInQualityTesting ? _c("div", {
    staticClass: "border-bottom"
  }) : _vm._e(), _vm.getHandledItem.sentences && _vm.getHandledItem.sentences.length > 0 ? _c("div", {
    staticClass: "detail-part"
  }, [_c("card-detail", _vm._b({
    attrs: {
      "detail-data": _vm.getHandledItem.sentences,
      "conversation-type": _vm.getHandledItem.conversation_type,
      "conversation-id": _vm.getHandledItem.id
    },
    on: {
      unfold: _vm.uifoldDetail
    }
  }, "card-detail", _vm.handleIdentify(_vm.getHandledItem.participants), false))], 1) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;