"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _routeMap = require("@/router/routeMap");
var _default = {
  methods: {
    handleCommand(command, row) {
      switch (command) {
        case 'edit':
          this.$emit('editTask', row);
          break;
        case 'delete':
          this.deleteTask(row);
          break;
        case 'ABTest':
          this.goABTest(row);
          break;
        case 'copyTask':
          this.$emit('copyTask', row);
          break;
      }
    },
    deleteTask(row) {
      const msg = row.status === 0 ? this.$t('coaching.deleteTaskPrompt') : this.$t('coaching.confirmDeletion');
      this.$confirm(msg, this.$t('coaching.deleteTask'), {
        confirmButtonText: this.$t('coaching.delete'),
        confirmButtonClass: 'confirm-right',
        cancelButtonText: this.$t('coaching.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await (0, _coachingCenter.deleteCoachingTask)({
          id: row.pid
        });
        if (res.code === 20000) {
          this.$message.success(this.$t('coaching.deletedSuccessfully'));
          this.$emit('delete', row.id);
        }
      });
    },
    goABTest(row) {
      this.$router.push({
        path: _routeMap.ROUTES.biExperimentDetail,
        query: {
          id: row.abtest_id,
          source: 'coaching',
          workspaceId: row.space_id
        }
      });
    }
  }
};
exports.default = _default;