"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tree = _interopRequireDefault(require("./src/tree.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* istanbul ignore next */
_tree.default.install = function (Vue) {
  Vue.component(_tree.default.name, _tree.default);
};
var _default = _tree.default;
exports.default = _default;