"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _commonFunc = require("@/utils/commonFunc");
var _vuex = require("vuex");
var _default = {
  name: 'EventSelect',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    isCheckedRoot: {
      type: Boolean,
      default: false
    },
    isStrictly: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isFilterEventId: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    isSetDisable: {
      type: Boolean,
      default: false
    },
    disabledList: {
      type: Array,
      default: () => []
    }
  },
  // 给vue实例上添加hasCache，cacheResult属性用于组件多次使用时缓存请求数据
  privateData: {
    hasCache: false,
    cacheResult: null,
    currentSpaceId: null
  },
  data() {
    return {
      defaultProps: {
        expandTrigger: 'hover',
        checkStrictly: this.isStrictly,
        children: 'children',
        label: 'name',
        value: 'id',
        multiple: this.isMultiple
      },
      event_id: [],
      defalutEvent: [],
      eventOptions: [],
      optionsLoading: true
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  watch: {
    'workspaceInfo.id': {
      handler: function (val) {
        if (this.$options.privateData.currentSpaceId !== val) {
          this.$options.privateData.hasCache = false;
          this.eventOptions = [];
          this.$options.privateData.currentSpaceId = val;
        }
        if (this.$options.privateData.hasCache) {
          this.handleRequestResults(this.$options.privateData.cacheResult);
        } else {
          this.getEventOptions();
        }
      },
      immediate: true
    }
  },
  methods: {
    collapsePane() {
      this.$refs['event-select'].dropDownVisible = false;
    },
    getEventOptions() {
      this.$options.privateData.hasCache = true;
      this.$options.privateData.cacheResult = new Promise(resolve => {
        (0, _conversationList.getAllKeyEvent)({
          workspace_id: this.workspaceInfo.id
        }).then(res => {
          resolve(res);
        });
      });
      this.handleRequestResults(this.$options.privateData.cacheResult);
    },
    handleRequestResults(results) {
      this.optionsLoading = true;
      results.then(res => {
        if (res.code === 20000) {
          if (!res.results.events) return;
          this.eventOptions = (0, _commonFunc.deepClone)(res.results.events);
          this.eventOptions.length && this.setDefaultValue(this.eventOptions);
        }
      }).finally(() => {
        this.optionsLoading = false;
      });
    },
    setDefaultValue(val) {
      if (!this.isCheckedRoot) return;
      const firstId = val[0].id;
      this.defalutEvent = this.isMultiple ? this.getDefaultIds([], [], [val[0]])[0] : [firstId];
      this.setBoxplotLabel(val[0].name);
      const result = this.isMultiple ? this.defalutEvent : this.isFilterEventId ? this.getFilteredId(firstId) : firstId;
      this.$emit('changeEvent', result);
    },
    changeEvent(val) {
      if (this.isMultiple) {
        console.log('--------');
        this.event_id = val;
        this.setBoxplotLabel(this.getChosenLabels());
      } else {
        const value = val[val.length - 1];
        const id = this.isFilterEventId ? this.getFilteredId(value) : value;
        this.event_id = id;
        const eventLabel = this.$refs['event-select'].getCheckedNodes()[0].label;
        this.setBoxplotLabel(eventLabel);
      }
      this.$emit('changeEvent', this.event_id);
    },
    setBoxplotLabel(val) {
      sessionStorage.setItem('bi-boxplot-event-label', val);
    },
    getDefaultIds(list, prevArray, source) {
      return source.map(item => {
        const array = [...prevArray, item.id];
        item.children ? this.getDefaultIds(list, array, item.children) : list.push(array);
        return list;
      });
    },
    getFilteredId(id) {
      return id.replace(/[^0-9]/gi, '');
    },
    getChosenLabels() {
      const nodes = this.$refs['event-select'].getCheckedNodes();
      const labelList = [...new Set(nodes.map(item => this.getRootLabel(item)))];
      return labelList.join('，');
    },
    getRootLabel(node) {
      if (node.level === 1) return node.label;
      while (node.level !== 1) {
        node = node.parent;
      }
      return node.label;
    },
    setDisabled() {
      if (!this.isSetDisable) return;
      const currentEventIds = this.defalutEvent.map(item => item[item.length - 1]);
      const newDisabledList = this.disabledList.filter(item => !currentEventIds.includes(item));
      const handleEventOptions = options => {
        options.forEach(item => {
          this.$set(item, 'disabled', newDisabledList.includes(item.id));
          if (item.children) {
            handleEventOptions(item.children);
          }
        });
      };
      handleEventOptions(this.eventOptions);
    }
  }
};
exports.default = _default;