"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dealFilter = _interopRequireDefault(require("@/views/business/components/dealFilter"));
var _DealkeyEventFilter = _interopRequireDefault(require("@/views/business/components/DealkeyEventFilter"));
var _DealKeyEventOrderFilter = _interopRequireDefault(require("@/views/business/components/DealKeyEventOrderFilter"));
var _BatchSearchInput = _interopRequireDefault(require("@/components/BatchSearchInput"));
var _vuex = require("vuex");
var _user = require("@/api/user");
var _organization = require("@/api/organization");
var _business = require("@/api/business");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const defaultFilter = [{
  field_name: 'user_ids',
  name: '成员'
}, {
  field_name: 'department_tree_ids',
  name: '部门'
}, {
  field_name: 'trade_name',
  name: '交易名称'
}, {
  field_name: 'account_name',
  name: '客户名称'
}, {
  field_name: 'contact_tel',
  name: '联系人手机号'
}, {
  field_name: 'keyEventCascaderList',
  name: '关键事件'
}, {
  field_name: 'keyEventOrderCascaderList',
  name: '关键事件顺序'
}];
var _default = {
  name: 'AllFilterItems',
  components: {
    DealCustomFilter: _dealFilter.default,
    DealKeyEventFilter: _DealkeyEventFilter.default,
    BatchSearchInput: _BatchSearchInput.default,
    DealKeyEventOrderFilter: _DealKeyEventOrderFilter.default
  },
  props: {
    customFilterObject: {
      type: Array,
      default: () => []
    },
    // 需要回填的筛选项的值
    filterConfigValue: {
      type: Object,
      default: () => ({})
    },
    kanbanId: {
      type: [Number, String]
    },
    defaultFilterObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      filters: [],
      // 部门
      isPreventMemberClear: false,
      // 关键事件
      keyEvent: {
        keyEventCascaderList: [{
          keyEventName: [],
          inverse: false
        }],
        event_engine_relation: 'and'
      },
      keyEventOrder: {
        keyEventOrderCascaderList: [{
          keyEventName: [],
          inverse: false
        }, {
          keyEventName: [],
          inverse: false
        }]
      },
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      userBackfillData: [],
      departmentBackfillData: [],
      backFillData: null // 回填数据
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'orgId']),
    // 处理select的下拉选项
    filterOptions() {
      return [{
        name: '默认字段',
        filters: defaultFilter
      }, ...this.customFilterObject];
    },
    allFilters() {
      return this.filterOptions.map(_ref => {
        let {
          filters
        } = _ref;
        return filters;
      }).flat();
    },
    isAddButtonVisible() {
      return this.filters.length < this.allFilters.length;
    },
    isDeleteButtonVisible() {
      return this.filters.length > 0;
    },
    filterValues() {
      return (0, _commonFunc.deepClone)(this.filters.map(_ref2 => {
        let {
          params
        } = _ref2;
        return params;
      }).filter(item => item));
    },
    selectDisabled() {
      return function (value) {
        return this.filters.map(_ref3 => {
          let {
            type
          } = _ref3;
          return type;
        }).includes(value);
      };
    }
  },
  watch: {
    kanbanId: {
      handler: function () {
        this.initEditKanban(this.filterConfigValue, true);
      }
    },
    customFilterObject: {
      handler() {
        this.initEditKanban(this.filterConfigValue, true);
      },
      immediate: true
    },
    keyEvent: {
      handler: function (val) {
        if (val) {
          var _val$keyEventCascader;
          var keyEventCount = (_val$keyEventCascader = val.keyEventCascaderList) === null || _val$keyEventCascader === void 0 ? void 0 : _val$keyEventCascader.filter(item => item.keyEventName.length).length;
          if (keyEventCount) {
            const typeItem = this.filters.find(item => item.type === 'keyEventCascaderList');
            if (typeItem) {
              typeItem.params = val;
            } else {
              this.handleAddSelect('keyEventCascaderList');
              this.handleChangeSelect(this.filters.length - 1, 'keyEventCascaderList');
              this.setFilterParams('keyEventCascaderList', val);
            }
          } else {
            this.filters.find(item => {
              if (item.type === 'keyEventCascaderList') {
                item.params = null;
              }
            });
          }
          this.$emit('keyeventSelectChange', val);
        }
      },
      deep: true
    },
    keyEventOrder: {
      handler: function (val) {
        if (val) {
          var keyEventCount = val.keyEventOrderCascaderList.filter(item => item.keyEventName.length).length;
          if (keyEventCount) {
            const typeItem = this.filters.find(item => item.type === 'keyEventOrderCascaderList');
            if (typeItem) {
              typeItem.params = val;
            } else {
              this.handleAddSelect('keyEventOrderCascaderList');
              this.handleChangeSelect(this.filters.length - 1, 'keyEventOrderCascaderList');
              this.setFilterParams('keyEventOrderCascaderList', val);
            }
          } else {
            this.filters.find(item => {
              if (item.type === 'keyEventOrderCascaderList') {
                item.params = null;
              }
            });
          }
          this.$emit('keyeventOrderSelectChange', val);
        }
      },
      deep: true
    },
    filterValues: {
      handler: function (val, oldval) {
        if (val && JSON.stringify(val) !== JSON.stringify(oldval)) {
          this.$emit('filterChange', this.filters);
        }
      },
      deep: true
    },
    defaultFilterObj: {
      handler: function (val) {
        if (val) {
          const default_filter = ['trade_name', 'account_name', 'contact_tel'];
          default_filter.forEach(item => {
            const filter = val[item];
            this.handleValueBackfill(item, filter, val);
            this.$nextTick(() => {
              if (!filter) {
                this.handleDeleteSelect(item);
              }
            });
          });
        }
      },
      deep: true
    }
  },
  methods: {
    getUserList: _organization.getUserList,
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    getOrgTree: _organization.getOrgTree,
    // 搜索客户/商机推荐项
    async getFuzzySearchList(filter, cb, type) {
      const res = await (0, _business.getFuzzySearchList)({
        filter,
        type,
        workspace_id: this.workspaceInfo.id
      });
      cb(res.results.map(value => {
        return {
          value
        };
      }));
    },
    handleClear(type) {
      this.$refs[type].activated = true;
    },
    matchCustomFilterItem(type) {
      return this.allFilters.find(item => item.field_name === type);
    },
    customFilterChange(val) {
      const key = Object.keys(val)[0];
      const value = Object.values(val)[0];
      this.setFilterParams(key, value);
      this.customChange();
    },
    isFilterValueEmpty(filter, type, filterObj) {
      let keyeventEmpty = false;
      if (type === 'keyEventCascaderList' && filter) {
        keyeventEmpty = filter.length === 1 && !filter[0].keyEventName.length;
      }
      return !filter || Array.isArray(filter) && !filter.length || keyeventEmpty;
    },
    initEditKanban(filterObj) {
      let needReset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.reset();
      // 对看板名字赋值
      if (!filterObj) return;
      this.allFilters.forEach((item, index) => {
        const filter = filterObj[item.field_name];
        this.$emit('departmentSelectChange', []); // 先清空部门
        this.$emit('keyeventSelectChange', this.$options.data().keyEvent); // 清空关键事件
        this.$emit('keyeventOrderSelectChange', this.$options.data().keyEventOrder);
        if (this.isFilterValueEmpty(filter, item.field_name, filterObj)) return;
        this.handleValueBackfill(item.field_name, filter, filterObj);
      });
    },
    handleValueBackfill(type, filter, filterObj) {
      this.handleAddSelect(type);
      this.handleChangeSelect(this.filters.length - 1, type);
      // 对部门进行单独处理
      if (type === 'department_tree_ids') {
        // this.isPreventMemberClear = true;
        this.$nextTick(() => {
          filter = Array.isArray(filter) ? filter : [filter];
          this.departmentBackfillData = filter;
        });
      }
      // 对成员进行单独处理
      if (type === 'user_ids' && Array.isArray(filter)) {
        var _filter;
        // 回填成员数据
        (_filter = filter) === null || _filter === void 0 ? void 0 : _filter.forEach(item => {
          const {
            is_department,
            parent_department
          } = item;
          item.isDepartment = is_department;
          item.preNode = parent_department;
        });
        this.userBackfillData = (0, _commonFunc.deepClone)(filter);
      }
      // 对关键事件进行单独处理
      if (type === 'keyEventCascaderList') {
        this.keyEvent.keyEventCascaderList = filter;
        this.keyEvent.event_engine_relation = filterObj.event_engine_relation;
      }
      if (type === 'keyEventOrderCascaderList') {
        this.keyEventOrder.keyEventOrderCascaderList = filter;
      }
      this.setFilterParams(type, filter);
    },
    // 校验规则清空
    reset() {
      this.filters = [];
      this.departmentBackfillData = [];
      this.userBackfillData = [];
      this.keyEvent = this.$options.data().keyEvent;
      this.keyEventOrder = this.$options.data().keyEventOrder;
    },
    handleChangeSelect(index, type) {
      const typeItem = this.filters.find(item => {
        return item.type === type;
      });
      if (typeItem) return;
      // 设置filters的type和label
      this.filters[index].type = type;
      const filter = this.allFilters.find(item => item.field_name === type);
      this.filters[index].label = filter.name;
      this.filters[index].params = null;
    },
    // 添加select的方法
    handleAddSelect(type) {
      const typeItem = this.filters.find(item => item.type === type);
      if (typeItem) return;
      const id = Math.random();
      this.isNoNeedEmit = true;
      this.filters.push({
        id,
        type: '',
        label: '',
        params: null,
        typeError: false,
        valueError: false
      });
    },
    isEmpty(value) {
      if (['', null, undefined].includes(value)) {
        return true;
      } else if (Array.isArray(value) && !value.length) {
        return true;
      } else if (typeof value === 'object' && !Object.keys(value).length) {
        return true;
      } else {
        return false;
      }
    },
    handleDeleteSelect(type) {
      const index = this.filters.findIndex(filter => filter.type === type);
      let deleteItem = null;
      if (index !== -1) {
        deleteItem = this.filters.splice(index, 1);
      }
      if (type === 'keyEventCascaderList') {
        this.keyEvent = this.$options.data().keyEvent;
      }
      if (type === 'keyEventOrderCascaderList') {
        this.keyEventOrder = this.$options.data().keyEventOrder;
      }
      if (type === 'department_tree_ids') {
        this.departmentBackfillData = [];
      }
      if (type === 'user_ids') {
        this.userBackfillData = [];
      }
      return deleteItem;
    },
    handleCustomDeleteSelect(type) {
      const deleteItem = this.handleDeleteSelect(type);
      if (deleteItem && deleteItem[0] && !this.isEmpty(deleteItem[0].params)) {
        this.customChange();
      }
    },
    setFilterParams(type, value) {
      const filterIndex = this.filters.findIndex(filter => filter.type === type);
      if (filterIndex !== -1) {
        this.filters[filterIndex].params = value;
      }
    },
    handleUserValueChange(value, array) {
      this.setFilterParams('user_ids', value);
      this.customChange();
    },
    handleDepartmentValueChange(type, value) {
      this.setFilterParams(type, value);
      // 需要组件库支持方法
      // if (value.length) {
      this.customChange();
      // }
    },

    setDepartmentBackfillValue(val) {
      this.isNoNeedEmit = true;
      if (this.isFilterValueEmpty(val, 'department_tree_ids')) {
        this.handleDeleteSelect('department_tree_ids');
      } else {
        this.handleValueBackfill('department_tree_ids', val);
      }
    },
    setKeyeventBackfillValue(val) {
      this.isNoNeedEmit = true;
      if (this.isFilterValueEmpty(val.keyEventCascaderList, 'keyEventCascaderList', val)) {
        this.handleDeleteSelect('keyEventCascaderList');
      } else {
        this.handleValueBackfill('keyEventCascaderList', val.keyEventCascaderList, val);
      }
    },
    setKeyeventOrderBackfillValue(val) {
      this.isNoNeedEmit = true;
      if (this.isFilterValueEmpty(val.keyEventOrderCascaderList, 'keyEventOrderCascaderList', val)) {
        this.handleDeleteSelect('keyEventOrderCascaderList');
      } else {
        this.handleValueBackfill('keyEventOrderCascaderList', val.keyEventOrderCascaderList, val);
      }
    },
    debounceCustomChange: (0, _commonFunc.debounce)(function (val) {
      this.customChange();
    }, 500),
    customChange() {
      this.$emit('custom-change', this.filters);
    }
  }
};
exports.default = _default;