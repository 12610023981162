"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coachingCenter = require("@/api/coachingCenter");
var _vue = require("vue");
var _default = {
  __name: 'ttsAvatarNameSelect',
  props: {
    value: {
      type: Number,
      default: 4
    }
  },
  emits: 'immediate-change',
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const avatarNames = (0, _vue.ref)([]);
    const getAvatarNames = async () => {
      const res = await (0, _coachingCenter.getTtsAvatar)();
      avatarNames.value = res.results;
      emit('immediate-change', props.value, res.results.find(item => item.avatar_type === props.value));
    };
    (0, _vue.watch)(() => props.value, val => {
      emit('immediate-change', val, avatarNames.value.find(item => item.avatar_type === val));
    });
    (0, _vue.onMounted)(() => {
      getAvatarNames();
    });
    const onAvatarClick = (val, item) => {
      console.log('val', val);
      if (props.value === val) return;
      emit('input', val);
      emit('immediate-change', val, item);
    };
    return {
      __sfc: true,
      props,
      emit,
      avatarNames,
      getAvatarNames,
      onAvatarClick
    };
  }
};
exports.default = _default;