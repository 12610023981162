"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      title: _vm.editForm.hasOwnProperty("id") ? "编辑字段" : "新建字段",
      "before-close": _vm.closeDialog,
      "custom-class": "create-field-dialog",
      "append-to-body": "",
      width: "700px"
    }
  }, [_c("section", {
    staticClass: "content"
  }, [_c("CustomFieldTypeGroup", {
    attrs: {
      "is-edit": _vm.isEdit,
      type: _vm.type
    },
    model: {
      value: _vm.formType,
      callback: function ($$v) {
        _vm.formType = $$v;
      },
      expression: "formType"
    }
  }), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("CustomFieldForm", {
    ref: "form",
    attrs: {
      visible: _vm.visible,
      type: _vm.type,
      "form-type": _vm.formType,
      "is-edit": _vm.isEdit,
      "edit-form": _vm.editForm,
      "custom-field-list": _vm.customFieldList
    },
    on: {
      cancel: _vm.closeDialog,
      success: function ($event) {
        return _vm.$emit("success");
      },
      changeType: _vm.changeType
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;