"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.renderInfo.length ? _c("div", {
    staticClass: "link-tags"
  }, [_vm._l(_vm.renderInfo.slice(0, 3), function (item, index) {
    return _c("el-tag", {
      key: index,
      on: {
        click: function ($event) {
          return _vm.goLinkPage(item);
        }
      }
    }, [_c("i", {
      class: ["iconfont", _vm.getIconName(item.icon_type)]
    }), _c("span", {
      staticClass: "link-name"
    }, [_vm._v(_vm._s(item.name))])]);
  }), _c("el-popover", {
    attrs: {
      placement: "bottom",
      width: "200",
      trigger: "hover",
      "popper-class": "message-more-link-popover"
    }
  }, [_vm.renderInfo.length > 3 ? _c("el-tag", {
    staticClass: "more-executor",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v(" +" + _vm._s(_vm.renderInfo.length - 3) + " ")]) : _vm._e(), _vm._l(_vm.renderInfo.slice(3), function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "pop-item",
      on: {
        click: function ($event) {
          return _vm.goLinkPage(item);
        }
      }
    }, [_c("i", {
      class: ["iconfont", _vm.getIconName(item.icon_type)]
    }), _c("span", {
      staticClass: "link-name"
    }, [_vm._v(_vm._s(item.name))])]);
  })], 2)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;