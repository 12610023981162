"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logoIconG = _interopRequireDefault(require("@/assets/logo/logoIcon-g.png"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      logoIcon: _logoIconG.default,
      logoUrl: '',
      loading: false
    };
  },
  created() {
    this.getLogoUrl();
  },
  methods: {
    async getLogoUrl() {
      this.loading = true;
      this.$store.dispatch('user/getOrgInfo').then(res => {
        this.logoUrl = res.org_logo_url;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
exports.default = _default;