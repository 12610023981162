"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "form",
    staticClass: "third-step",
    attrs: {
      model: _vm.form,
      label: "top",
      rules: _vm.rules
    }
  }, [_c("div", {
    staticClass: "form-item"
  }, [_c("h3", {
    staticClass: "header test-group-header"
  }, [_c("span", {
    staticClass: "name label"
  }, [_vm._v("实验组")]), _c("span", {
    staticClass: "tips"
  }, [_vm._v("接受指导、培训或者管理干预的组别")])]), _c("el-form-item", {
    staticClass: "test-group-item",
    attrs: {
      prop: "test_group"
    }
  }, [_c("members-finder", {
    class: {
      "disabled-members-finder": _vm.editDisabled
    },
    attrs: {
      placeholder: "请选择成员",
      params: {
        type: JSON.stringify(["deal", "conversation"])
      },
      "has-self": false,
      "tree-config": _vm.treeConfig,
      "member-info": _vm.testMemberInfo,
      disabled: _vm.editDisabled
    },
    on: {
      change: function ($event) {
        return _vm.handleSelectChange($event);
      }
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-item"
  }, [_c("h3", {
    staticClass: "header"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v("对照组")]), _c("span", {
    staticClass: "tips"
  }, [_vm._v("与实验组进行对比的组别")])]), _vm._l(_vm.form.control_group, function (item, index) {
    return _c("div", {
      key: item.id
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v("对照组" + _vm._s(_vm.label[index]))]), _c("el-form-item", {
      attrs: {
        prop: `control_group.${index}.member_ids`,
        rules: [{
          type: "array",
          required: true,
          message: "请选择成员",
          trigger: "change"
        }]
      }
    }, [_c("members-finder", {
      class: {
        "disabled-members-finder": _vm.editDisabled
      },
      attrs: {
        placeholder: "请选择成员",
        params: {
          type: JSON.stringify(["deal", "conversation"])
        },
        "has-self": false,
        "tree-config": _vm.treeConfig,
        "member-info": _vm.controlMemberInfo[index],
        disabled: _vm.editDisabled
      },
      on: {
        change: function ($event) {
          return _vm.handleControlGroupChange(index, $event);
        }
      }
    }), _c("el-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.editDisabled,
        expression: "!editDisabled"
      }],
      staticClass: "delete-btn",
      attrs: {
        icon: "el-icon-close",
        size: "mini"
      },
      on: {
        click: function ($event) {
          return _vm.deleteItem(index);
        }
      }
    })], 1)], 1);
  }), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.control_group.length < 5 && !_vm.editDisabled,
      expression: "form.control_group.length < 5 && !editDisabled"
    }],
    staticClass: "add-btn",
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v(" 添加对照组 ")])], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;