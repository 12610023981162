"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clickoutside = _interopRequireDefault(require("element-ui/src/utils/clickoutside"));
var _ValueContainer = _interopRequireDefault(require("./ValueContainer"));
var _SearchContainer = _interopRequireDefault(require("./SearchContainer"));
var _TreeContainer = _interopRequireDefault(require("./TreeContainer"));
var _throttleDebounce = require("throttle-debounce");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TreeSelect',
  components: {
    ValueContainer: _ValueContainer.default,
    SearchContainer: _SearchContainer.default,
    DepartmentTree: _TreeContainer.default
  },
  directives: {
    Clickoutside: _clickoutside.default
  },
  props: {
    showTagCount: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: '部门'
    },
    requestParams: {
      type: [Object, String],
      default: () => ({})
    },
    requestMethod: {
      require: true,
      type: Function
    },
    setCheckedRootNode: {
      type: Boolean,
      default: false
    },
    selectedNotExpandable: {
      type: Boolean,
      default: false
    },
    backfillList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSearching: false,
      popoverVisible: false,
      selectionList: [],
      searchOptions: []
    };
  },
  watch: {
    backfillList: {
      immediate: true,
      handler(val) {
        // 如果有数据要回填则回填数据
        this.$nextTick(() => {
          this.$refs['department-tree'].backFillData(val);
        });
      }
    },
    selectionList() {
      this.setOptionsIsChecked();
    }
  },
  mounted() {
    // 设置popover最小宽度和输入框一致
    this.$refs['tree-select-options-container'].style.minWidth = this.$refs['value-container'].$el.offsetWidth + 'px';
  },
  methods: {
    hanldeClosePopover() {
      this.popoverVisible = false;
    },
    treeCheckedChange(val, isThrowData) {
      this.selectionList = val;
      isThrowData && this.throwData(val);
      if (this.showTagCount === -1) {
        this.$nextTick(() => {
          this.$refs['optionsPopover'].updatePopper();
        });
      }
    },
    throwData: (0, _throttleDebounce.debounce)(100, false, function (val) {
      this.$emit('change', val.map(_ref => {
        let {
          id
        } = _ref;
        return id;
      }), val);
    }),
    removeTag(nodeData) {
      // 取消树上选中节点
      this.$refs['department-tree'].handleTag(nodeData, false);
    },
    addTag(nodeData) {
      // 设置树上节点选中
      this.$refs['department-tree'].handleTag(nodeData, true);
    },
    clear() {
      this.selectionList.forEach(item => {
        this.removeTag(item);
      });
    },
    // 处理搜索部门的结果
    getDepartmentTreeData(filter) {
      this.searchOptions = [];
      if (!filter.length) return;
      const treeData = this.$refs['department-tree'].orgTreeData;
      this.searchTreeNode(treeData, filter);
      this.setOptionsIsChecked();
    },
    searchTreeNode(node, filter) {
      node.forEach(item => {
        var _item$children;
        if (item.name.toLowerCase().includes(filter.toLowerCase())) {
          this.searchOptions.push(item);
        }
        if ((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) this.searchTreeNode(item.children, filter);
      });
    },
    setOptionsIsChecked() {
      this.searchOptions.forEach((item, index) => {
        this.$set(this.searchOptions[index], 'isChecked', this.selectionList.some(_ref2 => {
          let {
            id
          } = _ref2;
          return id === item.id;
        }));
      });
    }
  }
};
exports.default = _default;