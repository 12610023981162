"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _RulesConfig = _interopRequireDefault(require("./RulesConfig.vue"));
var _ScoreRuleConfig = _interopRequireDefault(require("./ScoreRuleConfig.vue"));
var _MgAutoTooltip = _interopRequireDefault(require("@/components/MgAutoTooltip"));
var _PicDisplay = _interopRequireDefault(require("@/components/CommentInput/components/PicDisplay.vue"));
var _PicUploadButton = _interopRequireDefault(require("@/components/CommentInput/components/PicUploadButton.vue"));
var _index = _interopRequireDefault(require("@/mgv-ui/packages/button/index"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TalkConfigDrawer',
  components: {
    RulesConfig: _RulesConfig.default,
    ScoreRuleConfig: _ScoreRuleConfig.default,
    MgAutoTooltip: _MgAutoTooltip.default,
    PicDisplay: _PicDisplay.default,
    PicUploadButton: _PicUploadButton.default
  },
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      roleConfig: {},
      isEditRoleName: false,
      branchImages: []
    };
  },
  computed: {
    getRoleConfig() {
      const roleConfig = {
        customer: {
          description: 'coaching.customerSaysConfigure',
          branchesMaxLength: 500,
          branchePlaceholder: 'coaching.customerSaysExample'
        },
        salesman: {
          icon: 'icon-chat-dot-square',
          description: 'coaching.salesmanConfigureTip',
          branchesMaxLength: 1000,
          branchePlaceholder: 'coaching.salesSaysExample'
        },
        narrator: {
          icon: 'icon-narration',
          description: 'coaching.narrationConfigure',
          branchesMaxLength: 500,
          branchePlaceholder: 'coaching.narrationExample'
        }
      };
      return roleConfig[this.config.role] || {};
    },
    canUploadPic() {
      return this.config.role === 'narrator' || this.config.role === 'customer';
    },
    roleName() {
      return this.roleConfig.role === 'narrator' ? 'coaching.narrationContent' : this.roleConfig.role === 'customer' ? 'coaching.customerSaysContent' : 'coaching.salesSaysContent';
    }
  },
  watch: {
    config: {
      handler: function () {
        this.roleConfig = (0, _commonFunc.deepClone)(this.config);
      },
      deep: true
    }
  },
  mounted() {
    this.$refs.cardContainerRef.addEventListener('paste', this.imagePasteListener);
  },
  beforeDestroy() {
    this.$refs.cardContainerRef.removeEventListener('paste', this.imagePasteListener);
  },
  methods: {
    keywordInput(index) {
      const textAreaRef = this.$refs['text-textarea'][0];
      textAreaRef.value.split('').reduce((acc, curVal, curIndex) => {
        const len = acc + (curVal.charCodeAt() > 255 ? 1 : 0.2);
        // if (Math.ceil(len) > 5) {
        if (Math.ceil(len) > this.getRoleConfig.branchesMaxLength) {
          this.roleConfig.branches[index].text = textAreaRef.value.slice(0, curIndex);
          return undefined;
        }
        return len;
      }, 0);
    },
    searchScript() {
      this.$emit('searchScript', this.roleConfig.branches[0].text);
    },
    editRoleName() {
      this.isEditRoleName = true;
      this.$nextTick(() => {
        this.$refs['role-name-input'].focus();
      });
    },
    addTalk() {
      this.$emit('addBranch');
      console.log('2312', this.$refs['text-textarea']);
    },
    deleteTalk(index) {
      this.$emit('removeBranch', undefined, this.roleConfig.branches[index].id);
    },
    updateRoleConfigData() {
      this.isEditRoleName = false;
      this.$emit('updateRoleNodeData', this.roleConfig);
    },
    getBranchRatio(index) {
      const weight = this.roleConfig.branches[index].weight;
      const totalWeight = this.roleConfig.branches.reduce((acc, cur) => {
        return acc + Number(cur.weight);
      }, 0);
      const persentValue = weight / totalWeight;
      if (isNaN(persentValue)) return '-';
      return Math.round(persentValue * 1000) / 10 + '%';
    },
    onWeightInputNumberInput(val, index) {
      if (!val) {
        this.roleConfig.branches[index].weight = 1;
      }
      this.updateRoleConfigData();
    },
    deletePic(index, item) {
      item.images.splice(index, 1);
      this.updateRoleConfigData();
    },
    imagePasteListener(e) {
      const clipboardData = e.clipboardData;
      const items = clipboardData.items;
      const index = e.target.dataset.refindex;
      const uploadRef = this.$refs['pic-upload-button'][index];
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const file = items[i].getAsFile();
            uploadRef.upload(file);
            e.preventDefault();
          }
        }
      }
    }
  }
};
exports.default = _default;