"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-coach-task-detail-container"
  }, [_c("header", [_c("div", {
    staticClass: "go-back",
    on: {
      click: _vm.goMyTask
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), _c("task-info", {
    attrs: {
      "task-info": _vm.taskInfo
    }
  })], 1), _c("article", [_c("div", {
    staticClass: "session-record"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_vm.sceneList.length || _vm.scriptList.length ? _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("coaching.keyScripts"),
      name: "keyScriptReview"
    }
  }, [_vm.courseType === 6 ? _c("KeyScriptReview", {
    attrs: {
      "scene-list": _vm.sceneList
    }
  }) : _vm.courseType === 7 ? _c("KeyScriptReviewNoScene", {
    attrs: {
      "script-list": _vm.scriptList
    }
  }) : _vm._e()], 1) : _vm._e(), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("coaching.dialogueRecord"),
      name: "dialogueList"
    }
  }, [!_vm.conversationList.length ? _c("div", {
    staticClass: "generating"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.dialogueRecordBeingGenerated"))), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _c("DialogueList", {
    attrs: {
      narration: _vm.narration,
      "customer-avatar": _vm.customerAvatar,
      "conversation-list": _vm.conversationList
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "task-evaluation"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.taskScore")) + "：")]), _vm.isGenerating ? _c("div", {
    staticClass: "generating"
  }, [_vm._v(" 评分中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _c("div", {
    staticClass: "score"
  }, [_vm._v(" " + _vm._s(_vm.aiCommentAndScore.score === -1 ? 0 : _vm.aiCommentAndScore.score)), _c("span", [_vm._v("分")])]), _c("div", {
    staticClass: "analysis-container"
  }, [_c("div", {
    staticClass: "comments"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-ai-assistant-fill"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.aIReview")) + "：")])]), _vm.isGenerating ? _c("div", {
    staticClass: "generating"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.commenting"))), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _c("ul", {
    staticClass: "comments-list"
  }, _vm._l(_vm.aiCommentAndScore.comment, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item || _vm.$t("coaching.noCommentsAvailable")))])]);
  }), 0)]), _c("div", {
    staticClass: "comments"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "iconfont icon-analysis-3-fill"
  }), _c("span", {
    staticClass: "comment-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.abilityAnalysis")) + "：")])]), !_vm.abilityAnalysis.length ? _c("div", {
    staticClass: "generating"
  }, [_vm._v(" 分析中"), _c("span", {
    staticClass: "ellipsis"
  }, [_vm._v("…")])]) : _c("ul", {
    staticClass: "comments-list"
  }, _vm._l(_vm.abilityAnalysis, function (item) {
    return _c("li", {
      key: item
    }, [_c("span", [_vm._v("✦")]), _c("span", [_vm._v(_vm._s(item))])]);
  }), 0)])]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goMyTask
    }
  }, [_vm._v(_vm._s(_vm.$t("coaching.returnToList")))])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;