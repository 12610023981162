"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-tips"
  }, [_c("div", {
    staticClass: "score-tips-top"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("知识点")]), _c("el-popover", {
    attrs: {
      placement: "bottom",
      trigger: "hover",
      "visible-arrow": false,
      "popper-class": "knowledge-score-correction-tips",
      width: "500"
    }
  }, [_c("div", {
    staticClass: "tips-content"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("分数矫正是什么意思？")]), _c("section", {
    staticClass: "content"
  }, [_vm._v(" 本系统给出的知识点得分可能与您的判断有不同。当您觉得知识点得分不准确的时候，可以通过数据矫正对每个知识点的标注数据进行修改。修改的数据进行全局应用之后，系统会根据新的评分标准对其余知识点进行重新评分 ")]), _c("p", {
    staticClass: "title"
  }, [_vm._v("如何进行分数矫正？")]), _c("section", {
    staticClass: "content"
  }, [_c("p", [_vm._v("1. 选择需要矫正的知识点")]), _c("p", [_vm._v("2. 矫正每个知识点会话片段得分，完成后保存。")]), _c("p", [_vm._v(" 3. 所有已经保存的矫正数据将在第二天0点进行数据重跑，重跑结束后完成全局应用。 ")])]), _c("p", {
    staticClass: "tip"
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  }), _vm._v("系统将在 0:00 点统一对已保存数据进行重跑实现全局应用。 ")])]), _c("i", {
    staticClass: "iconfont icon-info-2",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })])], 1), _vm._m(0)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "score-tips-bottom"
  }, [_c("div", {
    staticClass: "ico"
  }, [_c("i", {
    staticClass: "iconfont icon-time"
  })]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 系统将在 0:00 点统一对已保存数据进行重跑实现全局应用。 ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;