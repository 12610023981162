"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "choice"
  }, [_c("div", {
    staticClass: "choice-title"
  }, [_vm._v("选择企业")]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.invitations || !_vm.organizations,
      expression: "!invitations || !organizations"
    }],
    staticClass: "content-wrapper",
    class: {
      "content-wrapper-no-team": _vm.hasNoTeamShow
    }
  }, [_vm.hasTeamShow ? _c("div", {
    staticClass: "choice-content"
  }, [_vm._l(_vm.invitations, function (item) {
    return [_c("div", {
      key: item.organization_id,
      class: ["choice-item", {
        "is-inactive-item": !item.is_active
      }],
      on: {
        click: function ($event) {
          return _vm.acceptInvitation(item.organization_id);
        }
      }
    }, [_c("div", {
      staticClass: "invite-left"
    }, [_c("div", {
      staticClass: "invitation-tip"
    }, [_vm._v("新的公司邀请")]), _c("span", {
      staticClass: "team-name"
    }, [_vm._v(_vm._s(item.name))])]), item.is_active ? _c("div", [_c("div", {
      staticClass: "choice-sign accept"
    }, [_vm._v("进入公司")]), _c("svg-icon", {
      staticClass: "right-icon",
      attrs: {
        "icon-class": "arrow-right"
      }
    })], 1) : _c("div", [_vm._m(0, true)])])];
  }), _vm._l(_vm.organizations, function (item) {
    return [_c("div", {
      key: item.organization_id,
      class: ["choice-item", {
        "is-inactive-item": !item.is_active
      }],
      on: {
        click: function ($event) {
          return _vm.goOrg(item.organization_id, item.is_active);
        }
      }
    }, [_c("span", {
      staticClass: "team-name"
    }, [_vm._v(_vm._s(item.name))]), item.is_active ? _c("div", [_c("div", {
      staticClass: "choice-sign"
    }, [_vm._v("进入公司")]), item.is_active ? _c("svg-icon", {
      staticClass: "right-icon",
      attrs: {
        "icon-class": "arrow-right"
      }
    }) : _vm._e()], 1) : _c("div", [_vm._m(1, true)])])];
  })], 2) : _vm._e(), _vm.hasNoTeamShow ? _c("div", {
    staticClass: "no-choose-team"
  }, [_vm._m(2), _c("div", {
    staticClass: "no-team"
  }, [_vm._v(" " + _vm._s(`您的账号没有在任何团队中，请联系管理员${_vm.isDeveloper ? "将您添加为开发者" : ""}。`) + " ")])]) : _vm._e()]), _c("div", {
    staticClass: "go-login"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    nativeOn: {
      click: function ($event) {
        return _vm.goToLogin.apply(null, arguments);
      }
    }
  }, [_vm._v("切换账号")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "is-inactive"
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated"
  }), _vm._v(" 已禁用 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "is-inactive"
  }, [_c("i", {
    staticClass: "iconfont icon-eliminated"
  }), _vm._v(" 已禁用 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-team-iconfont"
  }, [_c("i", {
    staticClass: "iconfont icon-info-2"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;