"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.optionsLoading,
      expression: "!optionsLoading"
    }],
    staticClass: "bi-boxplot-event-select"
  }, [_c("el-cascader", {
    ref: "event-select",
    attrs: {
      options: _vm.eventOptions,
      props: _vm.defaultProps,
      placeholder: _vm.placeholder,
      "collapse-tags": !_vm.collapseTags,
      clearable: _vm.isMultiple,
      filterable: "",
      "show-all-levels": false,
      "popper-class": "bi-boxplot-event-select-popper"
    },
    on: {
      change: _vm.changeEvent,
      "visible-change": val => val && _vm.setDisabled()
    },
    model: {
      value: _vm.defalutEvent,
      callback: function ($$v) {
        _vm.defalutEvent = $$v;
      },
      expression: "defalutEvent"
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;