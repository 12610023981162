"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dynamicChart = _interopRequireDefault(require("./dynamicChart.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DynamicTabChart',
  components: {
    DynamicChart: _dynamicChart.default
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: ''
    },
    currentTab: {
      type: String,
      default: ''
    },
    pageConfigs: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentSelectTab: ''
    };
  },
  watch: {
    currentSelectTab(val, oldVal) {
      if (oldVal) this.$parent.currentTab = val;
    }
  }
};
exports.default = _default;