"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskTableFilter = _interopRequireDefault(require("@/views/coaching/coach/coachTask/components/TaskTableFilter"));
var _LeaderTaskTable = _interopRequireDefault(require("@/views/coaching/coach/coachTask/leader/LeaderTaskTable"));
var _coachingCenter = require("@/api/coachingCenter");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _routeMap = require("@/router/routeMap");
var _taskUtils = require("../task-utils.js");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CoachingCenterLeader',
  components: {
    TaskTableFilter: _TaskTableFilter.default,
    LeaderTaskTable: _LeaderTaskTable.default,
    NoData: _NoData.default
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.includes(_routeMap.ROUTES.coaching)) {
      sessionStorage.setItem('coachingTaskListParamsByLeaderView', JSON.stringify(this.requestParams));
    }
    next();
  },
  props: {
    refreshSign: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      requestParams: {
        name: '',
        space_id: '',
        user_ids: [],
        tree_ids: [],
        status: -1,
        page: 1,
        size: 10,
        order_field: '',
        order_type: ''
      },
      totalCount: 0,
      cachePageNumber: 0
    };
  },
  watch: {
    refreshSign() {
      this.initData();
      this.getLeaderTaskList();
    }
  },
  created() {
    const {
      page,
      size
    } = JSON.parse(sessionStorage.getItem('coachingTaskListParamsByLeaderView') || '{}');
    this.cachePageNumber = page;
    this.requestParams.size = size || 10;
    this.requestParams.page = page || 1;
  },
  methods: {
    tableSortChange(val) {
      this.requestParams.order_field = val.prop;
      this.requestParams.order_type = val.order;
      this.initData();
      this.getLeaderTaskList();
    },
    async getLeaderTaskList() {
      let deleteId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      // 后端数据更新有延迟，暂定前端过滤需删除的数据项（多请求一条数据->过滤需删除的数据）
      this.tableLoading = true;
      const params = this.formatParams();
      if (deleteId) {
        params.size = params.size + 1;
      }
      const res = await (0, _coachingCenter.getLeaderTaskList)(params).finally(() => {
        this.tableLoading = false;
      });
      if (res.code === 20000) {
        const {
          data: _data,
          total_count
        } = res.results;
        const data = (0, _taskUtils.formatCombineTaskList)(_data);
        this.tableData = deleteId ? data.filter(i => i.id !== deleteId) : data;
        this.totalCount = deleteId ? total_count - 1 : total_count;
      }
    },
    initData() {
      this.requestParams.page = this.cachePageNumber || 1;
      this.cachePageNumber = 0;
    },
    tableSizeChange(val) {
      this.requestParams.size = val;
      this.initData();
      this.getLeaderTaskList();
    },
    tableCurrentPageChange(val) {
      this.requestParams.page = val;
      this.getLeaderTaskList();
    },
    filterChange(val) {
      this.requestParams = {
        ...this.requestParams,
        ...val
      };
      this.initData();
      this.getLeaderTaskList();
    },
    handleDelete(id) {
      this.initData();
      this.getLeaderTaskList(id);
    },
    formatParams() {
      // 后端 0 是有意义的，所以判断空串
      const params = {
        ...this.requestParams
      };
      if (params.status === '') {
        params.status = -1;
      }
      if (params.category_id === '') {
        params.category_id = -1;
      }
      params.space_id = params.space_id || 0;
      params.course_type = params.course_type || 0;
      delete params.creator_id;
      return params;
    }
  }
};
exports.default = _default;