"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _errorLog = require("./error-log");
var _store = _interopRequireDefault(require("@/store"));
var _axios = _interopRequireDefault(require("axios"));
var _config = require("@/utils/config");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
class WebsocketConn {
  constructor() {
    let isEcho = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    let path = arguments.length > 1 ? arguments[1] : undefined;
    let parmas = arguments.length > 2 ? arguments[2] : undefined;
    const session = (0, _auth.getToken)();
    this.url = `${_config.config.wsUrl}${path || '/ws'}?${new URLSearchParams({
      session,
      ...parmas
    })}`;
    this.ws = null;
    this.lockReconnect = false; // 避免ws重复连接
    this.timeout = 30000; // 心跳检测时间
    this.heartCheckTimer = null; // 心跳检测定时器
    this.heartCheckServerTimer = null;
    this.errorTime = 0;
    this.errorStartTime = Date.parse(new Date());
    this.instance = null;
    this.isEcho = isEcho;
  }
  static getInstance() {
    if (!this.instance) {
      return new WebsocketConn();
    }
    return this.instance;
  }
  cb(event) {
    if (event && this.isJSON(event.data)) {
      const data = JSON.parse(event.data);
      _store.default.commit('app/SET_WS_MSG', data);
    }
  }
  isJSON(str) {
    if (typeof str === 'string') {
      try {
        var obj = JSON.parse(str);
        if (typeof obj === 'object' && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
  }
  heartCheckToReset() {
    clearTimeout(this.heartCheckTimer);
    clearTimeout(this.heartCheckServerTimer);
    return this;
  }
  heartCheckToStart() {
    this.heartCheckTimer = setTimeout(() => {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage 拿到返回的心跳就说明连接正常
      if (this.ws.readyState === 1) {
        this.ws.send('ping');
        window.log('ping!');
      }
      this.heartCheckServerTimer = setTimeout(() => {
        // 如果超过一定时间还没重置，说明后端主动断开了
        window.log('timer close');
        this.ws.close();
        // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, this.timeout);
    }, this.timeout);
  }
  create() {
    try {
      if ('WebSocket' in window) {
        this.ws = new WebSocket(this.url);
      }
      this.initEventHandle();
    } catch (e) {
      this.reconnect();
      window.log(e);
    }
  }
  initEventHandle() {
    this.ws.onclose = e => {
      const session = (0, _auth.getToken)();
      if (session) {
        this.reconnect();
      }
    };
    this.ws.onerror = e => {
      window.log(e);
      this.reconnect();
      if (navigator.onLine) {
        // 根据两次触发ws断开时间间隔是否大于半小时确定是否累加报错次数，如果大于半小时的清零累加报错次数
        if (this.errorStartTime - Date.parse(new Date()) < -1800000) {
          this.errorTime = 0;
          this.errorStartTime = Date.parse(new Date());
        } else {
          // 如果上次触发ws断开累计了，那么更新报错开始时间
          this.errorTime++;
          this.errorStartTime = Date.parse(new Date());
        }
      }
      if (this.errorTime > 6 && navigator.onLine) {
        (function formatError(e) {
          const obj = Array.isArray(e) ? [] : {};
          if (e && typeof e === 'object') {
            for (const key in e) {
              if (e[key] && typeof e[key] === 'object') {
                obj[key] = formatError(e[key]);
              } else {
                obj[key] = e[key];
              }
            }
          }
          window.obj = obj;
        })(e);
        var upErrorLog = function () {
          this.errorTime = 0;
          (0, _errorLog.postErrorLog)('websocket Error ' + 'netIsOnline:' + navigator.onLine + ' userId:' + _store.default.getters.userId + ' orgId:' + _store.default.getters.orgId + ' userName:' + _store.default.getters.name,
          // eslint-disable-next-line no-undef
          obj);
        };
        // 请求百度，确认是否能正常访问百度，如果能正常访问百度，说明用户网络时可用的，但是连接不到咱们的websocket,需要上报日志。
        _axios.default.get('https://www.baidu.com/').then(res => {
          upErrorLog();
        });
      }
    };
    this.ws.onopen = () => {
      this.heartCheckToReset().heartCheckToStart(); // 心跳检测重置
      if (this.errorTime > 0) {
        // 如果确定已经连上，将重连失败的计数清为0
        this.errorTime = 0;
      }
      window.log(`websocket连接成功! ${new Date().toLocaleString()}`);
    };
    this.ws.onmessage = event => {
      // 如果获取到消息，心跳检测重置
      this.heartCheckToReset().heartCheckToStart(); // 拿到任何消息都说明当前连接是正常的
      this.cb(event);
      window.log(`websocket收到消息啦! ${event.data}; 当前连接状态: ${event.target.readyState}`);
      if (this.isEcho && event.target.readyState === 1) {
        this.ws.send(event.data);
        window.log(`给服务端发消息${event.data}`);
      }
    };
  }
  reconnect() {
    if (this.lockReconnect) return;
    this.lockReconnect = true;
    setTimeout(() => {
      // 重连，设置延迟避免请求过多
      this.create && this.create();
      this.lockReconnect = false;
    }, 2000);
  }
  close() {
    this.instance = null;
  }
}
var _default = WebsocketConn;
exports.default = _default;