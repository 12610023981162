"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersTreeSelect = _interopRequireDefault(require("@/components/MgTreeSelect/MembersTreeSelect"));
var _vuex = require("vuex");
var _commonFunc = require("@/utils/commonFunc");
var _user = require("@/api/user");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ScopeOfOpen',
  components: {
    MembersTreeSelect: _MembersTreeSelect.default
  },
  props: {
    backFillValue: {
      type: Object,
      default: () => ({})
    },
    requireValidate: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localIsPublic: true,
      basicMembersTreeSelectBindProps: {
        'is-prevent-not-selected': false,
        'is-show-main-space-member': true,
        'request-function': _user.getOrgMemberTree
      },
      isShowError: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo']),
    membersTreeSelectBindProps() {
      const backFillValue = (0, _commonFunc.deepClone)(this.backFillValue);
      delete backFillValue.isPublic;
      return {
        key: this.workspaceInfo.id,
        ...this.basicMembersTreeSelectBindProps,
        ...backFillValue
      };
    }
  },
  watch: {
    backFillValue: {
      handler: function (val) {
        this.localIsPublic = val.isPublic;
        if (!val.isPublic && (!val['backfill-list'] || !val['backfill-list'].length)) {
          this.isShowError = true;
        }
      },
      deep: true
    },
    isShowError: {
      handler: function (val) {
        this.$emit('handleScopeError', val);
      }
    }
  },
  methods: {
    handleMemberChange(ids, val) {
      if (this.requireValidate) {
        val.length ? this.isShowError = false : this.isShowError = true;
      }
      this.$emit('scopeChange', {
        is_public: false,
        visible_members: ids
      });
    },
    handleRadioChange(val) {
      if (val && this.requireValidate) {
        this.isShowError = false;
      } else {
        this.isShowError = true;
      }
      this.$emit('scopeChange', {
        is_public: val,
        visible_members: {}
      });
    }
  }
};
exports.default = _default;