"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ManageDeveloperTable = _interopRequireDefault(require("@/views/appManagement/components/ManageDeveloperTable"));
var _AddDeveloperDialog = _interopRequireDefault(require("@/views/appManagement/components/AddDeveloperDialog"));
var _applicationIntegration = require("@/api/applicationIntegration");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ManageDeveloper',
  components: {
    ManageDeveloperTable: _ManageDeveloperTable.default,
    AddDeveloperDialog: _AddDeveloperDialog.default
  },
  data() {
    return {
      isVisible: false,
      tableLoading: false,
      developerList: [],
      paramsObj: {
        page: 1,
        size: 20
      },
      total: 0
    };
  },
  watch: {
    paramsObj: {
      handler: function (newVal) {
        this.getDeveloperList(newVal);
      },
      deep: true
    }
  },
  created() {
    this.getDeveloperList(this.paramsObj);
  },
  methods: {
    goBack() {
      const reg = /(\/[\w|-]+){2}/g;
      this.$router.push({
        path: `${this.$route.path.match(reg)[0]}`
      });
    },
    async getDeveloperList(params) {
      try {
        this.tableLoading = true;
        const res = await (0, _applicationIntegration.getDeveloperList)(params);
        if (res.code === 20000) {
          this.developerList = res.results.developers;
          this.total = res.results.total;
        }
      } finally {
        this.tableLoading = false;
      }
    },
    async deleteDeveloper(developerId) {
      const res = await (0, _applicationIntegration.deleteDeveloper)({
        user_id: developerId
      });
      if (res.code === 20000) {
        this.getDeveloperList(this.paramsObj);
      }
    },
    openAddDeveloperDialog() {
      this.isVisible = true;
    },
    closeAddDeveloperDialog(dialogState) {
      this.isVisible = dialogState;
    },
    async confirmAddDeveloper(developerIds) {
      const res = await (0, _applicationIntegration.addDeveloper)({
        user_ids: developerIds
      });
      if (res.code === 20000) {
        this.getDeveloperList(this.paramsObj);
      }
    },
    handleSizeChange(size) {
      this.paramsObj.page = 1;
      this.paramsObj.size = size;
    },
    handleCurrentChange(page) {
      this.paramsObj.page = page;
    }
  }
};
exports.default = _default;