"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _convItem = _interopRequireDefault(require("./convItem.vue"));
var _MgInfiniteScroll = _interopRequireDefault(require("@/components/MgInfiniteScroll"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    convItem: _convItem.default,
    MgInfiniteScroll: _MgInfiniteScroll.default,
    NoData: _NoData.default
  },
  props: ['convList', 'nomore', 'loading', 'listLoading', 'activeName'],
  data() {
    return {};
  },
  methods: {
    goConv() {
      this.$router.push({
        path: _routeMap.ROUTES.conversation
      });
    },
    load() {
      this.$emit('loadMore');
    }
  }
};
exports.default = _default;