"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-ability-insight-table"
  }, [_vm.heads.length ? _c("el-table", {
    attrs: {
      data: _vm.data,
      "row-class-name": "table-row-height"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "128px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", {
          staticClass: "dot",
          style: {
            backgroundColor: scope.row.color
          }
        }), _c("span", {
          staticClass: "text"
        }, [_vm._v(_vm._s(scope.row.name))])];
      }
    }], null, false, 2300211148)
  }), _vm._l(_vm.heads, function (item, index) {
    return [_c("el-table-column", {
      key: index,
      attrs: {
        label: item
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c("span", [_vm._v(_vm._s(scope.row.value[index]))])];
        }
      }], null, true)
    })];
  })], 2) : _c("span", {
    staticClass: "no-data"
  }, [_vm._v("暂无数据")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;