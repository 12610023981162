"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      riskPopoverVisiable: false
    };
  },
  created() {},
  methods: {
    handleRisk() {
      // document.body.click();
      this.riskPopoverVisiable = true;
      this.$emit('riskDrawerClick');
    },
    formatRisk(val) {
      switch (val) {
        case 0:
          return '截止日期已过期';
        case 1:
          return '在临近结单日期更改了决策人';
        case 2:
          return '在临近结单日期还没确认预计成交金额';
        case 3:
          return '14 天内没有活动';
        default:
          break;
      }
    }
  }
};
exports.default = _default;