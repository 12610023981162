"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SpaceDescriptionPopover',
  data() {
    return {
      helpUrl: 'https://www.megaview.com/help-center/doc?docx=Ik3odwYwEo2NmpxVQQIcZcLvnbh&anchor=AAEOdgiEMoAW0kxGOcWcLAFvnsd',
      showGif: false
    };
  }
};
exports.default = _default;