"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _DialogueList = _interopRequireDefault(require("@/views/coaching/coach/coachTask/salesPlan/DialogueList.vue"));
var _useRouter = require("@/hooks/use-router.js");
var _vue = require("vue");
var _coachingCenter = require("@/api/coachingCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'SmartTrainingCourseTask',
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    currentHistoryId: {
      type: String,
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  emits: [],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const route = (0, _useRouter.useRoute)();
    const conversationList = (0, _vue.ref)([]);
    const narration = (0, _vue.ref)('');
    const getDialogueListLoading = (0, _vue.ref)(false);
    const getSmartTrainingListData = async () => {
      getDialogueListLoading.value = true;
      const res = await (0, _coachingCenter.getSmartTrainingList)({
        plan_id: props.taskId,
        user_id: props.userInfo.id,
        conv_id: props.currentHistoryId
      }).finally(() => {
        getDialogueListLoading.value = false;
      });
      if (res.code === 20000) {
        const {
          avatar,
          name
        } = props.userInfo;
        narration.value = res.results.scene;
        conversationList.value = res.results.data.map(item => {
          return {
            ...item,
            speaker_party: item.speaker_party || 'host_salesman',
            avatar,
            name: item.speaker_party === 'host_salesman' ? name : (0, _i18n.t)('coaching.customer')
          };
        });
        emit('updateTaskInfo', {
          score: res.results.score
        });
      }
    };
    (0, _vue.watchEffect)(() => {
      getSmartTrainingListData();
    });
    return {
      __sfc: true,
      props,
      emit,
      route,
      conversationList,
      narration,
      getDialogueListLoading,
      getSmartTrainingListData,
      t: _i18n.t,
      DialogueList: _DialogueList.default
    };
  }
};
exports.default = _default;