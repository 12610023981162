"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "course-analysis-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      data: _vm.tableData,
      "row-style": {
        cursor: "pointer"
      },
      height: _vm.showCourseName ? "calc(100vh - 235px)" : "calc(100vh - 215px)"
    },
    on: {
      "sort-change": _setup.handleSortChange,
      "row-click": _setup.openDetailDrawer
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "standard_content",
      label: _setup.t("coaching.standardScript"),
      "min-width": "650"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("el-tooltip", {
          directives: [{
            name: "mg-tooltip-auto-show",
            rawName: "v-mg-tooltip-auto-show"
          }],
          attrs: {
            placement: "top",
            effect: "dark",
            content: row.standard_content
          }
        }, [_c("div", {
          staticClass: "standard-content"
        }, [_vm._v(_vm._s(row.standard_content))])])];
      }
    }])
  }), _c("el-table-column", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCourseName,
      expression: "showCourseName"
    }],
    attrs: {
      prop: "course_name",
      label: _setup.t("coaching.所属课程"),
      "min-width": "200",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "mistake_user_infos",
      label: _setup.t("coaching.errantMember"),
      width: "200px",
      sortable: "custom",
      "sort-orders": ["ascending", "descending", null]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("el-popover", {
          staticClass: "user-pop",
          attrs: {
            placement: "top-start",
            width: "200",
            trigger: "hover",
            "visible-arrow": false,
            disabled: !row.mistake_user_infos.length,
            "popper-class": "course-analysis-table-user-pop"
          }
        }, [_c("div", {
          staticClass: "user-list"
        }, _vm._l(row.mistake_user_infos, function (user) {
          return _c("div", {
            key: user.id,
            staticClass: "user-item"
          }, [_c(_setup.ImageAvatar, {
            staticClass: "user-avatar",
            attrs: {
              name: user.name,
              avatar: user.avatar,
              shape: "circle"
            }
          }), _c("span", [_vm._v(_vm._s(user.name))])], 1);
        }), 0), _c("div", {
          staticClass: "user-count",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_vm._v(" " + _vm._s(row.mistake_user_infos.length) + " ")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "max_miss_point",
      label: _setup.t("coaching.commonMistakes"),
      "min-width": "340px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("div", {
          staticClass: "miss-point-container"
        }, [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: row.max_miss_point.user_count,
            expression: "row.max_miss_point.user_count"
          }],
          staticClass: "miss-point-label"
        }, [_vm._v(" " + _vm._s(_setup.t("coaching.peopleMissedVar", [row.max_miss_point.user_count])) + "： ")]), _c("MgvTag", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: row.max_miss_point.user_count,
            expression: "row.max_miss_point.user_count"
          }],
          attrs: {
            "max-width": "200px"
          }
        }, [_vm._v(_vm._s(row.max_miss_point.score_point))]), _c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !row.max_miss_point.user_count,
            expression: "!row.max_miss_point.user_count"
          }]
        }, [_vm._v(" " + _vm._s(_setup.t("coaching.onlyNone")) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _setup.t("coaching.operation"),
      width: "240px",
      "label-class-name": "operate-label",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_c("mgv-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _setup.openDetailDrawer(row);
            }
          }
        }, [_vm._v(_vm._s(_setup.t("coaching.detailsOfPointsLost")) + " ")])];
      }
    }])
  })], 1), _c("el-drawer", {
    attrs: {
      visible: _setup.missPointDetailDrawerVisible,
      title: _setup.t("coaching.detailsOfPointsLost"),
      size: "600px",
      "custom-class": "miss-point-detail-drawer"
    },
    on: {
      "update:visible": function ($event) {
        _setup.missPointDetailDrawerVisible = $event;
      }
    }
  }, [_setup.missPointDetailDrawerVisible ? _c(_setup.MissPointDetailDrawerContent, {
    attrs: {
      "card-info": _setup.currentCard
    }
  }) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;