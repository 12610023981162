"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _websocket = _interopRequireDefault(require("@/utils/websocket"));
var _appTip = _interopRequireDefault(require("@/assets/appTip.png"));
var _OneStopCall = _interopRequireDefault(require("@/components/OneStopCall"));
var _callNote = _interopRequireDefault(require("@/components/OneStopCall/PhoneCall/callNote"));
var _uploadDialog = _interopRequireDefault(require("@/views/conversationPage/uploadDialog"));
var _BatchUpload = _interopRequireDefault(require("@/components/BatchUpload"));
var _GlobalUploadFile = _interopRequireDefault(require("@/components/GlobalUploadFile"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'App',
  components: {
    UploadDialog: _uploadDialog.default,
    OneStopCall: _OneStopCall.default,
    CallNote: _callNote.default,
    BatchUpload: _BatchUpload.default,
    GlobalUploadFile: _GlobalUploadFile.default
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      websocket: null,
      tipImg: _appTip.default,
      isHiddenOutboundPage: false,
      isRouterAlive: true,
      uploadFlag: false,
      confirmBox: null,
      isIframe: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['token', 'webSocketMsg', 'need_refresh_router', 'orgId', 'has_outbound', 'callNoteVisible', 'callIsCloseNote', 'callConverId', 'callNoteTime', 'callCustomerName', 'globalUploadFileList']),
    isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    url() {
      const url = window.location.href;
      return url;
    },
    key() {
      return this.$route.path;
    },
    isShowOutbound() {
      return this.has_outbound && !this.isHiddenOutboundPage;
    }
  },
  watch: {
    webSocketMsg: {
      handler: function (newVal) {
        if (newVal.type === 'notify_request') {
          this.handlePermissionChange();
        }
      },
      deep: true
    },
    token(value) {
      if (!value) {
        if (this.websocket) {
          this.websocket.close();
        }
      }
    },
    orgId(value) {
      if (value && this.token) {
        if (!this.websocket) {
          this.createWebSocket();
        }
        this.getWorkspaceInfo();
      }
    },
    $route: {
      handler: function (to) {
        const path = to.path;
        this.isIframe = to.meta.is_iframe;
        if (path === '/vri' || path === '/vrc' || path === '/login' || this.isIframe) {
          this.isHiddenOutboundPage = true;
        } else {
          this.isHiddenOutboundPage = false;
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    // 解决刷新页面时websocket断的问题
    if (this.token && this.orgId) {
      if (!this.websocket) {
        this.createWebSocket();
      }
    } else {
      if (this.websocket) {
        this.websocket.close();
      }
    }
  },
  mounted() {
    this.$bus.$on('openUploadMedia', this.openUploadMedia);
    const version = localStorage.getItem('version');
    window.log(`%c版本发布时间：${new Date(Number(version)).toLocaleString()}`, 'padding: 4px 8px; background: #4461ec; border-radius: 4px; color: #fff');
  },
  beforeDestroy() {
    this.$bus.$off('openUploadMedia');
  },
  destroyed() {
    if (this.websocket) {
      this.websocket.close();
    }
    clearInterval(this.intervalTimer);
  },
  methods: {
    createWebSocket() {
      // 开发环境关闭WebSocket
      if (process.env.NODE_ENV === 'development') return;
      this.websocket = _websocket.default.getInstance();
      this.websocket.create();
    },
    handlePermissionChange() {
      if (this.confirmBox) return;
      const content = this.$t('management.您的权限已发生变动，是否刷新页面？');
      const title = this.$t('management.提示');
      this.confirmBox = this.$confirm(content, title, {
        confirmButtonText: this.$t('management.刷新'),
        cancelButtonText: this.$t('management.取消')
      }).then(() => {
        location.reload()();
        this.confirmBox = null;
      }).catch(() => {
        this.confirmBox = null;
      });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    openUploadMedia() {
      this.uploadFlag = true;
    },
    changeUploadDialogFlag() {
      this.uploadFlag = false;
    },
    getWorkspaceInfo() {
      const storedSpaceId = sessionStorage.getItem('storedSpaceId');
      const spaceId = storedSpaceId === null ? '' : Number(storedSpaceId);
      this.$store.dispatch('workspace/getWorkspaceList', spaceId);
      sessionStorage.removeItem('storedSpaceId');
    }
  }
};
exports.default = _default;