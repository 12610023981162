"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.closeDialog,
      title: "移动到",
      width: "400px"
    }
  }, [_c("el-select", {
    ref: "select",
    attrs: {
      placeholder: "选择文件夹",
      "popper-class": "event-select-poover"
    },
    model: {
      value: _vm.label,
      callback: function ($$v) {
        _vm.label = $$v;
      },
      expression: "label"
    }
  }, [_c("el-option", {
    attrs: {
      value: true
    }
  }, [_c("event-tree", {
    attrs: {
      event: _vm.event,
      data: _vm.hideLeafTree,
      "is-silence": ""
    },
    on: {
      nodeClick: _vm.handleNodeClick
    }
  })], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading,
      disabled: !_vm.folderId
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;