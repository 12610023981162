"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "新建线索",
      visible: _vm.createClueDialogVisible,
      "before-close": _vm.closeDialog,
      "custom-class": "create-client",
      "append-to-body": "",
      width: "700px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.createClueDialogVisible = $event;
      }
    }
  }, [_c("dynamic-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getFormloading,
      expression: "getFormloading"
    }],
    ref: "dynamicForm",
    attrs: {
      desc: _vm.formDesc,
      "is-edit": "",
      "label-position": "top"
    },
    model: {
      value: _vm.createClueForm,
      callback: function ($$v) {
        _vm.createClueForm = $$v;
      },
      expression: "createClueForm"
    }
  }), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;