"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart-container"
  }, [_vm.title ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.subText ? _c("h5", [_vm._v(_vm._s(_vm.subText))]) : _vm._e(), _vm.isSampled && _vm.total ? _c("p", [_vm._v("基于" + _vm._s(_vm.total) + "条数据，进行抽样展示")]) : _vm._e(), _vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;