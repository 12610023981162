"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CreateTaskForm = _interopRequireDefault(require("./CreateTaskForm.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateTask',
  components: {
    CreateTaskForm: _CreateTaskForm.default
  },
  data() {
    return {
      isCreateTask: false
    };
  },
  methods: {
    createTask() {
      this.isCreateTask = true;
      this.$nextTick(() => {
        this.$refs['createTaskForm'].$refs['task-content'].focus();
      });
    }
  }
};
exports.default = _default;