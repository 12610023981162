"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.list && _vm.list.length ? _c("div", {
    staticClass: "risk-button-style"
  }, [_c("el-button", {
    staticClass: "risk-button",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.handleRisk();
      }
    }
  }, [_c("div", {
    staticClass: "risk-text"
  }, [_c("i", {
    staticClass: "iconfont icon-risk"
  }), _vm._v(" " + _vm._s(_vm.list.length) + " ")])])], 1) : _c("div", {
    staticClass: "placeholder"
  }, [_vm._v("-")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;