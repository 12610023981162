"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'ChartContainer',
  props: {
    title: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    isSampled: {
      type: Boolean,
      default: false
    },
    total: {
      type: [String, Number],
      default: 0
    }
  }
};
exports.default = _default;