"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SpeechBubble',
  props: {
    blueSize: {
      type: String,
      default: 'medium'
    },
    redSize: {
      type: String,
      default: 'medium'
    },
    type: {
      type: String,
      default: 'conversation'
    },
    translate: {
      type: Number,
      default: 0
    },
    datasetDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      translateX: 0
    };
  },
  computed: {
    marginLarge() {
      return this.redSize === 'large' && this.blueSize === 'large';
    },
    marginMedium() {
      return this.redSize === 'medium' || this.blueSize === 'medium';
    },
    marginSmall() {
      return this.redSize === 'small' || this.blueSize === 'small';
    },
    // eslint-disable-next-line vue/return-in-computed-property
    iconClass() {
      switch (this.type) {
        case 'conversation':
          return 'video-camera-solid-2';
        case 'telephone':
          return 'phone';
        case 'doc':
          return 'im-fill';
      }
    }
  },
  watch: {
    translate: {
      handler(val) {
        // 为了动画
        this.$nextTick(() => {
          this.translateX = val;
        });
      },
      immediate: true
    }
  }
};
exports.default = _default;