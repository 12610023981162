"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _routeMap = require("@/router/routeMap");
var _ConversationLink = _interopRequireDefault(require("./ConversationLink.vue"));
var _rapidCreateModel = require("@/api/rapidCreateModel");
var _vue = require("vue");
var _dataSet = require("@/api/dataSet");
var _fixTrainDataset = require("@/views/management/rapidModeling/hooks/fixTrainDataset");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrainDataDetailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 0
    },
    datasetId: {
      type: Number,
      default: 0
    },
    canAnnotate: {
      type: Boolean,
      default: true
    },
    getAllData: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['update:visible', 'updateAnnotationStatus'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const updateVisible = val => {
      emit('update:visible', val);
    };
    const checkList = (0, _vue.ref)([]);
    const modelInfo = (0, _vue.inject)('modelInfo');
    const context = (0, _vue.ref)({
      conversation_name: '',
      confidence: 0,
      sentence: [],
      status: 0
    });
    const currentIndex = (0, _vue.ref)(0);
    const currentId = (0, _vue.computed)(() => {
      var _props$ids;
      return (_props$ids = props.ids) === null || _props$ids === void 0 ? void 0 : _props$ids[currentIndex.value];
    });
    const hasNext = (0, _vue.computed)(() => {
      var _props$ids2;
      return currentIndex.value < ((_props$ids2 = props.ids) === null || _props$ids2 === void 0 ? void 0 : _props$ids2.length) - 1;
    });
    const hasPre = (0, _vue.computed)(() => {
      return currentIndex.value > 0;
    });
    const next = () => {
      currentIndex.value++;
    };
    const pre = () => {
      currentIndex.value--;
    };
    (0, _vue.watch)(() => props.visible, val => {
      if (val) {
        currentIndex.value = props.ids.findIndex(id => id === props.id);
      }
    }, {
      immediate: true
    });
    const {
      deleteAnnotationApi
    } = (0, _fixTrainDataset.useDeleteAnnotation)({
      successCallBack: props.getAllData
    });
    const deleteAnnotation = async () => {
      await deleteAnnotationApi({
        annotation_ids: [currentId.value]
      });
      if (!hasNext.value) {
        pre();
      }
    };
    const getAnnotatePath = () => {
      const basePath = _routeMap.ROUTES.annotate;
      const {
        dataset_data_id
      } = context.value;
      return `${basePath}?id=${props.datasetId}&datasetDataId=${dataset_data_id}`;
    };
    const trainDataDetailRef = (0, _vue.ref)(null);
    const sentenceTextRef = (0, _vue.ref)(null);
    const contentLoading = (0, _vue.ref)(false);
    (0, _vue.watchEffect)(() => {
      if (currentId.value && props.visible) {
        contentLoading.value = true;
        checkList.value = [];
        (0, _rapidCreateModel.getAnnotationContext)({
          annotation_id: currentId.value,
          model_id: modelInfo.id
        }).then(async res => {
          context.value = res.results.data;
          await (0, _vue.nextTick)();
          // 默认选中
          context.value.sentence.map((item, index) => {
            if (item.is_similar_sentence) {
              checkList.value.push(index);
            }
          });
          scrollToSimilarSentence();
        }).finally(() => {
          contentLoading.value = false;
        });
      }
    });
    function scrollToSimilarSentence() {
      const similarSentenceIndex = context.value.sentence.findIndex(item => item.is_similar_sentence);
      if (similarSentenceIndex === -1) return;
      const activeSentence = sentenceTextRef.value[similarSentenceIndex];
      activeSentence.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
    const handleCheckListInput = val => {
      if (!val.length) return;
      checkList.value = val;
    };
    const handleAnnotateChange = async val => {
      if (!val.length) {
        return;
      }
      const sentences = context.value.sentence.filter((item, index) => checkList.value.includes(index));
      const res = await (0, _rapidCreateModel.updateAnnotate)({
        dataset_annotation_id: currentId.value,
        status: context.value.status,
        dataset_id: props.datasetId,
        sentences
      });
      if (res.code === 20000) {
        context.value.sentence.map((item, index) => {
          if (val.includes(index)) {
            item.is_similar_sentence = true;
          } else {
            item.is_similar_sentence = false;
          }
        });
        emit('updateTableListAnnotationStatus', {
          id: currentId.value,
          status: context.value.status,
          sentences
        });
      }
    };
    const btnLoading = (0, _vue.ref)(false);
    const updateTrainDataStatus = async status => {
      btnLoading.value = true;
      const basicParams = {
        status,
        dataset_id: props.datasetId
      };
      const sentences = context.value.sentence.filter((item, index) => checkList.value.includes(index));
      const requestApi = props.canAnnotate ? _rapidCreateModel.updateAnnotate : _rapidCreateModel.batchUpdateTrainData;
      const params = props.canAnnotate ? {
        ...basicParams,
        sentences,
        dataset_annotation_id: currentId.value
      } : {
        basicParams,
        dataset_annotation_ids: [currentId.value]
      };
      const res = await requestApi(params).finally(() => {
        btnLoading.value = false;
      });
      if (res.code === 20000) {
        context.value.status = status;
        emit('updateTableListAnnotationStatus', {
          id: currentId.value,
          status,
          sentences
        });
        if (hasNext.value) {
          next();
        }
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      updateVisible,
      checkList,
      modelInfo,
      context,
      currentIndex,
      currentId,
      hasNext,
      hasPre,
      next,
      pre,
      deleteAnnotationApi,
      deleteAnnotation,
      getAnnotatePath,
      trainDataDetailRef,
      sentenceTextRef,
      contentLoading,
      scrollToSimilarSentence,
      handleCheckListInput,
      handleAnnotateChange,
      btnLoading,
      updateTrainDataStatus,
      ConversationLink: _ConversationLink.default
    };
  }
};
exports.default = _default;