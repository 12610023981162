"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filterSelect = _interopRequireDefault(require("@/components/Filters/BiFilters/filterSelect"));
var _DetailNavbar = _interopRequireDefault(require("@/layout/components/DetailNavbar"));
var _userInfoCard = _interopRequireDefault(require("./components/userInfoCard"));
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'BusinessIntelligencePersonal',
  components: {
    BiFilter: _filterSelect.default,
    Navbar: _DetailNavbar.default,
    UserInfoCard: _userInfoCard.default
  },
  data() {
    return {
      filters: {},
      userInfo: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'biPersonalFilterTime', 'routesTree']),
    layout() {
      var _this$filters, _this$filters2, _this$filters3;
      const currentRoute = this.$route.path;
      let layout = [];
      switch (currentRoute) {
        case _routeMap.ROUTES.biPersonalOverview:
          // 个人分析
          return layout = [{
            type: 'date',
            tooltip: '不包含当日数据'
          }];
        case _routeMap.ROUTES.biPersonalActivity:
          // 会话活动
          return layout = [{
            type: 'date',
            tooltip: ((_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : _this$filters.entity_type) === 'deal' ? '交易创建时间' : '会话时间',
            showTooltip: this.showDataPickerTooltip
          }, {
            type: 'conversation_type'
          }, {
            type: 'conversation_scene_type'
          }, {
            type: 'entity_type'
          }];
        case _routeMap.ROUTES.biPersonalDimension:
          // 会话维度
          return layout = [{
            type: 'date',
            tooltip: ((_this$filters2 = this.filters) === null || _this$filters2 === void 0 ? void 0 : _this$filters2.entity_type) === 'deal' ? '交易创建时间' : '会话时间',
            showTooltip: this.showDataPickerTooltip
          }, {
            type: 'conversation_type'
          }, {
            type: 'entity_type'
          }];
        case _routeMap.ROUTES.biPersonalKeyEvent:
          // 关键事件
          return layout = [{
            type: 'date',
            tooltip: ((_this$filters3 = this.filters) === null || _this$filters3 === void 0 ? void 0 : _this$filters3.analysis_basis) === 'conversation' ? '会话时间' : '交易创建时间'
          }, {
            type: 'analysis_basis',
            customClass: 'flex-end-radio-group'
          }];
        case _routeMap.ROUTES.biPersonalKnowledge: // 知识点
        case _routeMap.ROUTES.biTopic:
          // 话题
          return layout = [{
            type: 'date',
            tooltip: '会话时间'
          }];
        default:
          break;
      }
      return layout;
    },
    hasDealCustomFieldFilter() {
      return [_routeMap.ROUTES.biPersonalActivity, _routeMap.ROUTES.biPersonalDimension, _routeMap.ROUTES.biPersonalKeyEvent].includes(this.$route.path);
    },
    biTab: {
      get() {
        return this.$route.name;
      },
      set() {}
    },
    tabArray() {
      var _this$recursionGet;
      return (_this$recursionGet = this.recursionGet({
        recurArr: this.routesTree,
        targetValue: 'BIPersonal',
        targetAttr: 'name',
        getAttr: 'children'
      })) === null || _this$recursionGet === void 0 ? void 0 : _this$recursionGet.sort((a, b) => a.meta.order - b.meta.order);
    }
  },
  watch: {
    filters: {
      handler: function (filters) {
        this.$store.commit('bi/SET_BI_PERSONAL_TIME_FILTER', [this.$plus8ToCurrentZone(filters.begin_date).format('YYYY-MM-DD'), this.$plus8ToCurrentZone(filters.end_date).format('YYYY-MM-DD')]);
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.indexOf('/bi/') === -1 && to.path !== _routeMap.ROUTES.conversationDetail) {
      sessionStorage.setItem('biFilters', '');
      sessionStorage.setItem('biDepartmentOptions', '');
    }
    next();
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      const res = await (0, _organization.getMemberInfo)(this.$route.query.id);
      if (res.code === 200 || res.code === 20000) {
        this.userInfo = res.results;
      }
    },
    recursionGet: _commonFunc.recursionGet,
    handleFilterChange(filters) {
      const {
        date,
        entity_type,
        custom_filters,
        analysis_basis
      } = filters;
      const [begin_date, end_date] = JSON.parse(date);
      this.filters = {
        tree_id: this.orgId,
        entity_type,
        analysis_basis,
        begin_date,
        end_date,
        custom_filters
      };
    },
    handleTabClick(tab) {
      const {
        id,
        name,
        kanban_id
      } = this.$route.query;
      this.$router.replace({
        name: tab.name,
        query: {
          id,
          name,
          kanban_id
        }
      });
    },
    getDate(val) {
      const arr = val.split('-');
      const year = arr[0];
      const month = arr[1] - 1;
      const begin_date = this.$moment(year).month(month).startOf('month').format('YYYY-MM-DD');
      const end_date = this.$moment(year).month(month).endOf('month').format('YYYY-MM-DD');
      return [begin_date, end_date];
    }
  }
};
exports.default = _default;