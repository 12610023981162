"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _timeFormatter = require("@/utils/time-formatter");
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _routeMap = require("@/router/routeMap");
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _SnippetVideoButton = _interopRequireDefault(require("@/views/convDetail/snippetWord/SnippetVideoButton"));
var _ConversationSelector = _interopRequireDefault(require("./ConversationSelector"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ComplianceWorkSpaceHeader',
  components: {
    DetailHeader: _DetailHeader.default,
    ImageAvatar: _ImageAvatar.default,
    SnippetVideoButton: _SnippetVideoButton.default,
    ConversationSelector: _ConversationSelector.default
  },
  props: {
    originType: {
      type: String,
      default: ''
    },
    workspace: {
      type: Object,
      default: () => ({})
    },
    isVideoError: {
      type: Boolean,
      default: false
    },
    isSnippetStatus: {
      type: Boolean,
      default: false
    },
    mediaType: {
      type: String,
      default: ''
    },
    detailResult: {
      type: Object,
      default: () => ({})
    },
    commentsCount: {
      type: Number,
      default: 0
    },
    convId: {
      type: [Number, String],
      default: ''
    },
    hasEvaluation: {
      type: Boolean,
      default: false
    },
    rightBarActive: {
      type: String,
      default: 'compliance'
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'conversationTitleInfo', 'audio', 'webRoutes', 'hasVideo', 'currentOperateName']),
    convDate() {
      const _date = this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('YYYY-MM-DD');
      return _date;
    },
    convTime() {
      const _time = this.$plus8ToCurrentZone(this.conversationTitleInfo.convTime).format('HH:mm');
      return _time;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    convDuration() {
      if (this.conversationTitleInfo.convDuration) {
        const seconds = this.conversationTitleInfo.convDuration;
        const {
          s,
          m,
          h
        } = (0, _timeFormatter.second2HMS)(seconds);
        if (h) {
          return m ? `${h}小时${m}分` : `${h}小时`;
        } else if (m) {
          return s ? `${m}分${s}秒` : `${m}分`;
        } else if (s) {
          return `${s}秒`;
        } else {
          return `0秒`;
        }
      }
    },
    getDealName() {
      var _this$detailResult;
      if (((_this$detailResult = this.detailResult) === null || _this$detailResult === void 0 ? void 0 : _this$detailResult.type) === 'trade') {
        return this.detailResult.type_info.name;
      }
      return '';
    },
    getCustomerName() {
      var _this$detailResult2;
      if (JSON.stringify(this.detailResult) === '{}') return;
      const {
        name,
        customer_name
      } = this.detailResult.type_info;
      return {
        trade: customer_name,
        account: name
      }[(_this$detailResult2 = this.detailResult) === null || _this$detailResult2 === void 0 ? void 0 : _this$detailResult2.type] || '';
    },
    hasCustomerPermission() {
      return this.webRoutes.find(item => item.name === 'CustomerIndex');
    },
    getSalesman() {
      var _this$detailResult3, _this$detailResult3$p;
      return ((_this$detailResult3 = this.detailResult) === null || _this$detailResult3 === void 0 ? void 0 : (_this$detailResult3$p = _this$detailResult3.participants) === null || _this$detailResult3$p === void 0 ? void 0 : _this$detailResult3$p.find(item => !!item.is_host)) || {};
    }
  },
  methods: {
    onCopy() {
      this.$message({
        message: '已复制到剪贴板',
        type: 'success',
        center: true
      });
    },
    // 添加剪辑
    addVideoToSnippet() {
      if (this.currentOperateName === 'hitKeyEvent') {
        return;
      }
      this.$emit('addVideoToSnippet');
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', 'snippet');
    },
    // 取消剪辑
    onCancelSnippet() {
      this.$emit('onCancelSnippet');
      this.$store.commit('video/SET_CURRENT_OPERATE_NAME', '');
    },
    openRightDrawer(type) {
      this.$emit('openRightDrawer', type);
    },
    goTradePage() {
      var _this$detailResult4;
      if (((_this$detailResult4 = this.detailResult) === null || _this$detailResult4 === void 0 ? void 0 : _this$detailResult4.type) !== 'trade') return;
      const {
        is_access,
        id
      } = this.detailResult.type_info;
      if (is_access) {
        const routeData = this.$router.resolve({
          path: _routeMap.ROUTES.tradeDetail,
          query: {
            id,
            isBack: true
          }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$message.warning('无交易详情查看权限');
      }
    },
    goCustomerPage() {
      var _this$detailResult5;
      if (!this.hasCustomerPermission) return;
      const {
        is_access,
        id,
        account_is_access,
        account_id
      } = this.detailResult.type_info;
      switch ((_this$detailResult5 = this.detailResult) === null || _this$detailResult5 === void 0 ? void 0 : _this$detailResult5.type) {
        case 'trade':
          if (account_is_access) {
            const routeData = this.$router.resolve({
              path: _routeMap.ROUTES.customer,
              query: {
                id: account_id
              }
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message.warning('无客户详情查看权限');
          }
          break;
        case 'account':
          if (is_access) {
            const routeData = this.$router.resolve({
              path: _routeMap.ROUTES.customer,
              query: {
                id
              }
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message.warning('无客户详情查看权限');
          }
          break;
        default:
          return;
      }
    },
    getMediumIcon(type) {
      return {
        doc: 'icon-im-fill',
        audio: 'icon-phone-outline-fill',
        video: 'icon-video-camera-solid-2'
      }[type];
    }
  }
};
exports.default = _default;