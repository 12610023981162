"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conversationList = require("@/api/conversationList");
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _vuex = require("vuex");
var _mediaUpload = require("@/api/mediaUpload");
var _ConnectedContact = _interopRequireDefault(require("./ConnectedContact.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'UploadMediaForm',
  components: {
    MembersFinder: _MembersFinder.default,
    DynamicForm: _DynamicForm.default,
    ConnectedContact: _ConnectedContact.default
  },
  props: {
    pageType: {
      type: String,
      default: ''
    },
    mediaRowInfo: {
      type: Object,
      default: () => ({})
    },
    file: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      disabledModifyMeetingName: false,
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      form: {
        meeting_name: '',
        deal_id: '',
        // 商机id
        trade_id: '',
        // 交易id
        lead_id: '',
        // 线索id
        account_id: '',
        // 客户id
        contact_id: '',
        // 联系人id（线索下传0）
        independent_trade_id: '',
        // 关联交易id,
        independent_contact_id: '',
        // 关联联系人id
        customer_name: '',
        conversation_date: '',
        conversation_time: '',
        host_salesman_id: ''
      },
      contactList: [],
      // 关联交易搜索列表
      independentTradeList: [],
      rules: {
        // meeting_name: [
        //   { required: true, message: '请输入会话名称', trigger: 'change' }
        // ],
        deal_id: [{
          required: true,
          message: '请输入客户名称'
        }],
        trade_id: [{
          required: true,
          message: '请输入客户名称'
        }],
        lead_id: [{
          required: true,
          message: '请输入客户名称'
        }],
        id: [{
          required: true,
          message: '请选择联系人',
          trigger: 'change'
        }],
        conversation_date: [{
          required: true,
          message: '请选择日期',
          trigger: 'blur'
        }],
        conversation_time: [{
          required: true,
          message: '请选择时间',
          trigger: 'blur'
        }],
        host_salesman_id: [{
          required: true,
          message: '请选择会话归属人',
          trigger: 'change'
        }]
      },
      submitLoading: false,
      conversationDynamicFieldsDesc: [],
      conversationFieldForm: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo', 'userId', 'name', 'avatar', 'orgId']),
    uploadBtnDisabled() {
      return this.file.every(item => item.video_id);
    },
    timePickerOptions: function () {
      const option = this.form.conversation_date === this.$moment().format('YYYY-MM-DD') ? '00:00:00 - ' + this.$moment().format('HH:mm:ss') : '00:00:00 - 23:59:59';
      const range = {
        selectableRange: option
      };
      return range;
    },
    setLabel: function () {
      return function (type) {
        switch (type) {
          case 'clue':
            return '线索名称';
          case 'client':
            return '客户名称';
          case 'business':
            return '商机名称';
          case 'trade':
            return '交易名称';
        }
      };
    },
    setIdToString() {
      return function (id) {
        switch (id) {
          case 'clue':
            return this.form.lead_id.toString();
          case 'client':
            return this.form.account_id.toString();
          case 'business':
            return this.form.deal_id.toString();
          case 'trade':
            return this.form.trade_id.toString();
        }
      };
    }
  },
  watch: {
    mediaRowInfo: {
      handler: function (val) {
        var _list$contactInfo, _list$contactInfo2, _list$clientInfo;
        const list = {
          ...val
        };
        this.contactList = list === null || list === void 0 ? void 0 : (_list$contactInfo = list.contactInfo) === null || _list$contactInfo === void 0 ? void 0 : _list$contactInfo.contactList;
        this.form.contact_id = list === null || list === void 0 ? void 0 : (_list$contactInfo2 = list.contactInfo) === null || _list$contactInfo2 === void 0 ? void 0 : _list$contactInfo2.currentContactInfo.id;
        this.form.customer_name = list === null || list === void 0 ? void 0 : (_list$clientInfo = list.clientInfo) === null || _list$clientInfo === void 0 ? void 0 : _list$clientInfo.name;
        switch (this.pageType) {
          case 'clue':
            this.form.lead_id = list.clientInfo.id;
            this.handleDefaultHostSalesMan(val.rowInfo.follow_up_by.id, val.rowInfo.follow_up_by);
            break;
          case 'client':
            this.form.account_id = list.clientInfo.id;
            this.handleDefaultHostSalesMan(val.rowInfo.follow_up_by.id, val.rowInfo.follow_up_by);
            break;
          case 'business':
            this.form.deal_id = list.clientInfo.id;
            this.handleDefaultHostSalesMan(val.rowInfo.follow_up_by.id, val.rowInfo.follow_up_by);
            break;
          case 'trade':
            this.form.trade_id = list.clientInfo.id;
            this.handleDefaultHostSalesMan(val.rowInfo.own_by.id, val.rowInfo.own_by);
            break;
          case 'conversation':
            {
              const uploaderInfo = localStorage.getItem(`${this.orgId}${this.userId}conversationListUploaderInfo`);
              if (uploaderInfo) {
                const uploaderInfoObj = JSON.parse(uploaderInfo);
                console.log(uploaderInfoObj, 'uploaderInfoObj');
                this.handleDefaultHostSalesMan(uploaderInfoObj.hostSalesmanId, uploaderInfoObj === null || uploaderInfoObj === void 0 ? void 0 : uploaderInfoObj.hostSalesmanInfo);
              } else {
                this.handleDefaultHostSalesMan(this.userId, {
                  id: this.userId,
                  name: this.name,
                  avatar: this.avatar
                });
              }
            }
            break;
          default:
            break;
        }
      },
      immediate: true,
      deep: true
    },
    file: {
      handler: function () {
        // this.form.meeting_name = this.file[0]?.name;
      },
      deep: true
    },
    conversationDynamicFieldsDesc(val) {
      this.conversationFieldForm = {
        ...this.conversationDynamicFieldsDesc['default_value']
      };
    }
  },
  mounted() {
    this.getConversationCustomField();
    this.$bus.$on('disabledModifyMeetingName', val => {
      this.disabledModifyMeetingName = val;
    });
  },
  beforeDestroy() {
    this.$bus.$off('disabledModifyMeetingName');
  },
  methods: {
    async tradeAssociateToContact() {
      if (this.form.independent_contact_id && this.form.independent_trade_id) {
        await (0, _mediaUpload.tradeAssociateToContact)({
          trade_id: this.form.independent_trade_id,
          contact_id: this.form.independent_contact_id
        });
      }
    },
    handleIndependentContactChange(val) {
      this.form.independent_contact_id = val;
      this.form.independent_trade_id = '';
    },
    formatConversationCustomFieldFormDesc(formDesc) {
      const desc = [];
      for (const item of formDesc) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          halfRow: true,
          default: item.default_value,
          tips: item.tips,
          precisions: item.precisions,
          is_thousand_separate: item.is_thousand_separate,
          is_money: item.is_money
        };
        desc.push(obj);
      }
      return desc;
    },
    async getConversationCustomField() {
      const res = await (0, _mediaUpload.getConversationCustomField)();
      if (res.code === 20000) {
        this.conversationDynamicFieldsDesc = this.formatConversationCustomFieldFormDesc(res.results.fields);
      }
    },
    async searchTrade(val) {
      if (!this.form.independent_contact_id) return;
      const res = await (0, _mediaUpload.searchTrade)({
        filter: val,
        workspace_id: this.workspaceInfo.id,
        contact_id: this.form.independent_contact_id
      });
      if (res.code === 20000) {
        this.independentTradeList = res.results.datas;
      }
    },
    handleDefaultHostSalesMan(memberId, memberObj) {
      this.$nextTick(() => {
        const node = this.$refs['membersFinderRef'];
        if (node) {
          node.selection = [memberId];
          node.memberOptions = [memberObj];
          node.selectionObj = [memberObj];
        }
      });
    },
    handleMemberChange(value, selectionObj) {
      const [hostSalesmanId] = value;
      const [hostSalesmanInfo] = selectionObj;
      if (this.pageType === 'conversation') {
        localStorage.setItem(`${this.orgId}${this.userId}conversationListUploaderInfo`, JSON.stringify({
          hostSalesmanId,
          hostSalesmanInfo
        }));
      }
      this.$store.commit('upload/SET_UPLOAD_HOST_SALESMAN_INFO', hostSalesmanInfo);
      this.form.host_salesman_id = hostSalesmanId;
      this.$emit('hostSalesManChange', {
        host_salesman_id: hostSalesmanId
      });
    },
    handlerEnter(event) {
      if (event.keyCode === 13) {
        this.submitMediaForm();
      }
    },
    async postMediaForm(data) {
      this.submitLoading = true;
      const res = await (0, _conversationList.postMediaForm)(data).finally(() => this.submitLoading = false);
      if (res.code === 200) {
        if (this.pageType === 'conversation') {
          res.results.highlight = true;
          res.results.name = data.meeting_name;
          this.$bus.$emit('uploadConversationSuccess', res.results);
        }
        this.$emit('closeUploadMediaDialog');
        this.$message.success('上传音视频成功');
      }
    },
    // 表单需要上传的数据
    formatFormData(file) {
      var _this$mediaRowInfo, _this$mediaRowInfo$co;
      const data = {
        meeting_name: file.name,
        begin_time: this.$currentZoneToPlus8(`${this.form.conversation_date} ${this.form.conversation_time}`).format('YYYY-MM-DD HH:mm'),
        host_salesman_id: this.form.host_salesman_id,
        video_id: file.video_id,
        custom_field: this.conversationFieldForm
      };
      const list = this.mediaRowInfo.clientInfo;
      let [deal_id, lead_id, account_id, trade_id] = ['', '', '', ''];
      if (this.pageType === 'business') {
        deal_id = list.id;
      }
      if (this.pageType === 'clue') {
        lead_id = list.id;
      }
      // 客户页面上传
      if (this.pageType === 'client') {
        account_id = list.id;
      }
      if (this.pageType === 'trade') {
        trade_id = list.id;
      }
      const contact_id = (_this$mediaRowInfo = this.mediaRowInfo) === null || _this$mediaRowInfo === void 0 ? void 0 : (_this$mediaRowInfo$co = _this$mediaRowInfo.contactInfo) === null || _this$mediaRowInfo$co === void 0 ? void 0 : _this$mediaRowInfo$co.currentContactInfo.id;
      switch (this.pageType) {
        case 'business':
          return {
            ...data,
            contact_id,
            deal_id
          };
        case 'clue':
          return {
            ...data,
            contact_id: 0,
            lead_id
          };
        case 'client':
          return {
            ...data,
            contact_id,
            account_id
          };
        case 'trade':
          return {
            ...data,
            contact_id,
            trade_id
          };
        case 'conversation':
          return {
            ...data,
            is_independent: true,
            independent: {
              independent_contact_id: this.form.independent_contact_id,
              independent_trade_id: this.form.independent_trade_id
            }
          };
        default:
          return;
      }
    },
    // 提交表单
    submitMediaForm(formName) {
      // if (!this.file[0]?.video_id) {
      //   return;
      // }
      if (this.file.every(item => item.id)) {
        this.$message.warning('音视频还未上传成功');
        return;
      }
      const conversationDynamicValid = this.$refs['conversationDynamicFieldsRef'].validate();
      if (!conversationDynamicValid) {
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.file.forEach(async item => {
            if (this.pageType === 'conversation') {
              await this.tradeAssociateToContact();
            }
            const data = this.formatFormData(item);
            await this.postMediaForm(data);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;