"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
var _default = {
  name: 'PrecisionDetailTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    selectedVersions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localTableData: []
    };
  },
  watch: {
    tableData: {
      handler: function (val) {
        if (val && val.length) {
          this.localTableData = (0, _commonFunc.deepClone)(val);
        }
      },
      immediate: true
    },
    selectedVersions: {
      handler: function (val) {
        this.localTableData = (0, _commonFunc.deepClone)(this.tableData);
        this.localTableData.forEach(event => {
          const datas = [];
          this.selectedVersions.forEach(item => {
            event.datas.forEach(versionData => {
              if (versionData.version === item) {
                datas.push(versionData);
              }
            });
          });
          if (this.selectedVersions.length < 2) {
            this.$set(event, 'maxPrecision', []);
            this.$set(event, 'minPrecision', []);
            this.$set(event, 'maxRecall', []);
            this.$set(event, 'minRecall', []);
            this.$set(event, 'maxF1', []);
            this.$set(event, 'minF1', []);
          } else {
            const minPrecision = this.findMixObjects(datas, 'precision');
            const maxPrecision = this.findMaxObjects(datas, 'precision');
            const minRecall = this.findMixObjects(datas, 'recall');
            const maxRecall = this.findMaxObjects(datas, 'recall');
            const minF1 = this.findMixObjects(datas, 'f1');
            const maxF1 = this.findMaxObjects(datas, 'f1');
            this.$set(event, 'maxPrecision', maxPrecision);
            this.$set(event, 'minPrecision', minPrecision);
            this.$set(event, 'maxRecall', maxRecall);
            this.$set(event, 'minRecall', minRecall);
            this.$set(event, 'maxF1', maxF1);
            this.$set(event, 'minF1', minF1);
          }
        });
      },
      deep: true
    }
  },
  methods: {
    openEventAnnotationDetail(row) {
      this.$emit('openEventAnnotationDetail', row);
    },
    getRowValue(_ref) {
      var _rowInfo$datas$find;
      let {
        rowInfo,
        attr,
        version
      } = _ref;
      return (_rowInfo$datas$find = rowInfo.datas.find(item => item.version === version)) === null || _rowInfo$datas$find === void 0 ? void 0 : _rowInfo$datas$find[attr];
    },
    findMaxObjects(objArr, attr) {
      if (objArr.length === 0) {
        return [];
      }
      let maxValue = objArr[0][attr];
      let maxObjects = [objArr[0]];
      for (let i = 1; i < objArr.length; i++) {
        const currValue = objArr[i][attr];
        if (currValue > maxValue) {
          maxValue = currValue;
          maxObjects = [objArr[i]];
        } else if (currValue === maxValue) {
          maxObjects.push(objArr[i]);
        }
      }
      return maxObjects.map(item => item.version);
    },
    findMixObjects(objArr, attr) {
      if (objArr.length === 0) {
        return [];
      }
      let mixValue = objArr[0][attr];
      let mixObjects = [objArr[0]];
      for (let i = 1; i < objArr.length; i++) {
        const currValue = objArr[i][attr];
        if (currValue < mixValue) {
          mixValue = currValue;
          mixObjects = [objArr[i]];
        } else if (currValue === mixValue) {
          mixObjects.push(objArr[i]);
        }
      }
      return mixObjects.map(item => item.version);
    }
  }
};
exports.default = _default;