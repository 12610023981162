"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MemberSingleSelectDialog',
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    currentUser: {
      type: Object,
      default: function () {
        return {
          name: '',
          id: 0
        };
      }
    },
    isSetHead: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      memberIdForm: {
        id: []
      },
      rules: {
        id: [{
          required: true,
          message: this.$t('management.请选择成员'),
          trigger: 'change'
        }]
      },
      submitLoading: false
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val && this.currentUser.id) {
          this.$nextTick(() => {
            const memberFinderNode = this.$refs['memberFinder'];
            if (memberFinderNode) {
              memberFinderNode.selection = [this.currentUser.id];
              memberFinderNode.memberOptions = [this.currentUser];
            }
          });
        }
      },
      deep: true
    }
  },
  methods: {
    handleMemberChange(val) {
      if (val[0]) {
        this.memberIdForm.id = val;
      } else {
        this.memberIdForm.id = [];
      }
    },
    submitFormData() {
      this.$refs['memberIdForm'].validate(async valid => {
        if (valid) {
          this.$emit('submit', this.memberIdForm.id);
          this.$emit('getUserId', this.memberIdForm.id[0]);
          this.submitLoading = true;
        }
      });
    },
    handleClose() {
      this.submitLoading = false;
      this.reset();
      this.$emit('update:visible', false);
    },
    reset() {
      this.$refs['memberFinder'].selection = [];
      this.$nextTick(() => {
        this.$refs['memberIdForm'].resetFields();
      });
    }
  }
};
exports.default = _default;