"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "annotate-result-table"
  }, [_c("el-table", {
    attrs: {
      data: _vm.tableData,
      height: "100%",
      "default-sort": {
        prop: "create_at",
        order: "descending"
      }
    },
    on: {
      "sort-change": _setup.handleSortChange,
      "selection-change": _setup.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "序号",
      width: "48px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          $index
        } = _ref;
        return [_vm._v(" " + _vm._s(_vm.startIndex + $index) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "event_engine.data",
      label: "文本句",
      "min-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "sentence-container"
        }, _vm._l(row.data, function (sentence, index) {
          return _c("p", {
            key: index,
            staticClass: "model-sentence",
            on: {
              click: function ($event) {
                return _setup.onClickModelSentence(row);
              }
            }
          }, [sentence.entity_type === "host_salesman" ? _c("span", {
            staticClass: "sale-role"
          }, [_vm._v("销售：")]) : _c("span", {
            staticClass: "customer-role"
          }, [_vm._v("客户：")]), _c("span", [_vm._v(_vm._s(sentence.content))])]);
        }), 0)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "标注人",
      width: "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _row$creator, _row$creator2, _row$creator3;
        let {
          row
        } = _ref3;
        return [_c("div", {
          staticClass: "annotator"
        }, [_c("MgvAvatarName", {
          attrs: {
            src: row === null || row === void 0 ? void 0 : (_row$creator = row.creator) === null || _row$creator === void 0 ? void 0 : _row$creator.avatar,
            name: row === null || row === void 0 ? void 0 : (_row$creator2 = row.creator) === null || _row$creator2 === void 0 ? void 0 : _row$creator2.name,
            size: 28
          }
        }), _c("span", {
          staticClass: "operator-name"
        }, [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$creator3 = row.creator) === null || _row$creator3 === void 0 ? void 0 : _row$creator3.name))])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "create_at",
      label: "创建时间",
      width: "140px",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        let {
          row
        } = _ref4;
        return [_vm._v(" " + _vm._s(_setup.plus8ToCurrentZone(row.create_at).format("YYYY-MM-DD HH:mm")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "update_at",
      label: "更新时间",
      width: "140px",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        let {
          row
        } = _ref5;
        return [_vm._v(" " + _vm._s(_setup.plus8ToCurrentZone(row.update_at).format("YYYY-MM-DD HH:mm")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: 250,
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", {
          staticClass: "operation-header"
        }, [_c("span", [_vm._v("命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.dataSetStatistics.annotate_hit_count))]), _c("span", [_vm._v("未命中")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.dataSetStatistics.annotate_miss_count))]), _c("span", [_vm._v("不确定")]), _c("span", {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.dataSetStatistics.annotate_uncertain_count))])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref6) {
        let {
          row
        } = _ref6;
        return [_c("div", {
          staticClass: "operate-line"
        }, [_c(_setup.HitButtons, {
          attrs: {
            status: row.status
          },
          on: {
            change: function ($event) {
              return _setup.updateAnnotate($event, row);
            }
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right",
      width: 70
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        let {
          row
        } = _ref7;
        return [_c("el-popconfirm", {
          attrs: {
            title: "确定删除此标注吗？",
            "confirm-button-text": "删除",
            "cancel-button-text": "取消",
            "popper-class": "delete-popover"
          },
          on: {
            onConfirm: function ($event) {
              return _setup.deleteAnnotation(row);
            }
          }
        }, [_c("MgvButton", {
          attrs: {
            slot: "reference",
            type: "text",
            color: "danger",
            size: "medium"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;