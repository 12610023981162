"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _modelInfo = require("../hooks/modelInfo");
var _useTrainSuperParams = require("@/views/management/modelManagement/hooks/use-train-super-params");
var _modelManagement = require("@/api/modelManagement.js");
var _TriggerRulesFormItem = _interopRequireDefault(require("@/views/management/keyEventCreateRuleModel/components/CreateRuleModel/TriggerRulesFormItem"));
var _TrainSuperParamTable = _interopRequireDefault(require("@/components/TrainSuperParamTable"));
var _CombineRuleGroup = _interopRequireDefault(require("@/views/management/modelManagement/components/combineModel/CombineRuleGroup.vue"));
var _TrainAndTestDataSet = _interopRequireDefault(require("@/views/management/rapidModeling/components/TrainAndTestDataSet.vue"));
var _ModelPredictionResult = _interopRequireDefault(require("@/views/management/rapidModeling/components/ModelPredictionResult.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'TrainDetailDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentVersion: {
      type: Number,
      default: 0
    },
    modelId: {
      type: Number,
      default: 0
    },
    modelType: {
      type: String,
      default: ''
    }
  },
  emits: ['update:visible'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const isReadOnly = (0, _vue.ref)(true);
    (0, _vue.provide)('isReadOnly', isReadOnly);

    // 数据集列表
    const {
      isAutoTest,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType
    } = (0, _useTrainSuperParams.useTrainAndTestDatasetParams)();

    // 大模型后处理hooks
    const {
      postProcessType,
      isEnableLLM,
      promptContent
    } = (0, _useTrainSuperParams.usePredictionResult)();

    // 规则模型配置form
    const ruleEventForm = (0, _vue.ref)({
      name: '',
      description: '',
      tracker_groups: [{
        key: '1',
        rule: [{
          apply_to: 3,
          include: [{
            type: 'keywords',
            value: []
          }],
          exclude: [{
            type: 'keywords',
            value: []
          }]
        }],
        context: []
      }]
    });
    const autoTuningParams = (0, _vue.ref)(false);
    const ruleCount = (0, _vue.computed)(() => {
      return ruleEventForm.value.tracker_groups.reduce((prev, current) => {
        const ruleLength = current.rule && current.rule.length;
        const contextLength = current.context && current.context.length;
        return prev + ruleLength + contextLength;
      }, 0);
    });

    // 组合模型combine_config
    const combineConfig = (0, _vue.ref)({});

    // 获取训练列表
    const tableLoading = (0, _vue.ref)(false);
    async function getModelDetailData() {
      if (!props.modelId) return;
      tableLoading.value = true;
      const params = {
        id: props.modelId,
        version: activeVersion.value
      };
      const res = await (0, _modelManagement.getModelDetail)(params).finally(() => {
        tableLoading.value = false;
      });
      if (res.code === 20000) {
        var _res$results$postproc, _res$results$postproc2;
        ruleEventForm.value = res.results;
        autoTuningParams.value = !!res.results.train_param_mode;
        datasetSplitType.value = res.results.dataset_split_type;
        trainDatasetList.value = res.results.train_datasets.slice(0, 1);
        trialDatasetList.value = res.results.trial_datasets.slice(0, 1);
        isAutoTest.value = res.results.auto_trial;
        postProcessType.value = res.results.postprocess_type;
        isEnableLLM.value = res.results.postprocess_llm_enable;
        promptContent.value = (_res$results$postproc = res.results.postprocess) === null || _res$results$postproc === void 0 ? void 0 : (_res$results$postproc2 = _res$results$postproc.prompt_info) === null || _res$results$postproc2 === void 0 ? void 0 : _res$results$postproc2.prompt_content;
        combineConfig.value = res.results.combine_config;
      }
    }
    // 获取所有版本
    const {
      getModelVersionListApi,
      allVersionsList
    } = (0, _modelInfo.useGetModelAllVersions)({
      modelId: props.modelId,
      currentPage: 1,
      pageSize: 9999,
      total: 0
    });
    const activeVersion = (0, _vue.ref)('');
    (0, _vue.watch)(() => props.currentVersion, async val => {
      await getModelVersionListApi();
      activeVersion.value = val;
    }, {
      immediate: true
    });
    (0, _vue.watchEffect)(() => {
      // 给当前版本赋值
      getModelDetailData();
    });
    // 抽屉关闭

    const updateVisible = () => emit('update:visible', false);

    // 跳转到数据集
    const {
      goDataSet
    } = (0, _modelInfo.useGoDataSet)();
    return {
      __sfc: true,
      props,
      isReadOnly,
      isAutoTest,
      trainDatasetList,
      trialDatasetList,
      datasetSplitType,
      postProcessType,
      isEnableLLM,
      promptContent,
      ruleEventForm,
      autoTuningParams,
      ruleCount,
      combineConfig,
      tableLoading,
      getModelDetailData,
      getModelVersionListApi,
      allVersionsList,
      activeVersion,
      emit,
      updateVisible,
      goDataSet,
      TriggerRulesFormItem: _TriggerRulesFormItem.default,
      TrainSuperParamTable: _TrainSuperParamTable.default,
      CombineRuleGroup: _CombineRuleGroup.default,
      TrainAndTestDataSet: _TrainAndTestDataSet.default,
      ModelPredictionResult: _ModelPredictionResult.default
    };
  }
};
exports.default = _default;