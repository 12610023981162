"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DialogTag = _interopRequireDefault(require("./DialogTag.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TableHeadTips',
  components: {
    DialogTag: _DialogTag.default
  },
  props: {
    type: {
      type: String,
      default: 'coachRecord'
    }
  },
  data() {
    return {
      todoCoachList: [{
        type: 'ask-me',
        text: '该成员向我申请的辅导'
      }, {
        type: 'ask-other',
        text: '该成员向其他人申请的辅导'
      }],
      coachRecordList: [{
        type: 'me-coach',
        text: '我为该成员提供的辅导'
      }, {
        type: 'other-coach',
        text: '其他人为该成员提供的辅导'
      }]
    };
  }
};
exports.default = _default;