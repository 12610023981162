"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _recorderCore = _interopRequireDefault(require("recorder-core"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  data() {
    return {
      currentTemplateVoiceId: null,
      audioPlayer: new Audio()
    };
  },
  methods: {
    play(id, src) {
      // 如果有正在录制的录音则禁止播放
      if (_recorderCore.default.IsOpen()) return;
      this.currentTemplateVoiceId = id;
      this.audioPlayer.src = src;
      this.audioPlayer.play();
      this.audioPlayer.onended = () => {
        this.currentTemplateVoiceId = null;
      };
    },
    pause() {
      var _this$audioPlayer, _this$audioPlayer$pau;
      (_this$audioPlayer = this.audioPlayer) === null || _this$audioPlayer === void 0 ? void 0 : (_this$audioPlayer$pau = _this$audioPlayer.pause) === null || _this$audioPlayer$pau === void 0 ? void 0 : _this$audioPlayer$pau.call(_this$audioPlayer);
      this.currentTemplateVoiceId = null;
    }
  }
};
exports.default = _default;