"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "workspace-table"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "名称",
      "min-width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        let {
          row
        } = _ref;
        return [_c("i", {
          staticClass: "iconfont icon-workspace-3-fill",
          style: {
            color: row.icon_color
          }
        }), _c("span", {
          staticClass: "workspace-name"
        }, [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "描述",
      "class-name": "description",
      "min-width": "280"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "data_scope",
      label: "成员人数",
      "min-width": "198"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        let {
          row
        } = _ref2;
        return [_c("div", {
          staticClass: "member-count"
        }, [_c("i", {
          staticClass: "el-icon-user"
        }), _c("span", [_vm._v(_vm._s(row.member_count))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "operate",
      label: "操作",
      width: "200",
      "class-name": "operate-table-head"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("mg-operate-column", {
          attrs: {
            list: _vm.operateList(row)
          },
          on: {
            command: function ($event) {
              return _vm.handleCommand(row, $event);
            }
          }
        })];
      }
    }])
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;