"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _readExcel = require("@/utils/readExcel.js");
var _default = {
  name: 'ImportDrawer',
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: '',
      status: '',
      percentage: 30,
      progressTimer: null
    };
  },
  computed: {
    currentStatusContent() {
      const options = {
        upload: {
          icon: 'iconfont icon-refresh',
          text: 'coaching.isImporting',
          tip: 'coaching.notRefreshOrLeaveTip'
        },
        success: {
          icon: 'iconfont icon-success-green',
          text: 'coaching.importCompleted',
          tip: '',
          reload: 'coaching.importAgain'
        },
        error: {
          icon: 'iconfont icon-warning',
          text: 'coaching.parsingFailed',
          tip: 'coaching.fileContentNotMatch',
          reload: 'coaching.reUpload'
        },
        fail: {
          icon: 'iconfont icon-error-red',
          text: 'coaching.uploadFailed',
          tip: 'coaching.fileNotExistTip',
          reload: 'coaching.reUpload'
        }
      };
      return options[this.status];
    }
  },
  methods: {
    handleFail() {
      this.status = 'fail';
      this.percentage = 0;
      this.progressTimer && clearInterval(this.progressTimer);
    },
    handleSuccess() {
      this.status = 'success';
      this.percentage = 100;
      this.progressTimer && clearInterval(this.progressTimer);
      this.$message.success(this.$t('coaching.importSuccessful'));
    },
    uploadFile(item) {
      if (!item.file) return;
      this.readFile(item.file);
    },
    beforeFileUpload(file) {
      if (/\.(?:xlsx|xls)$/.test(file.name)) {
        this.fileName = file.name;
        this.status = 'upload';
        this.increaseProgress();
      } else {
        this.$message.warning(this.$t('coaching.fileFormatTip'));
      }
    },
    async readFile(file) {
      // 读取文件
      const data = await (0, _readExcel.readExcel)(file); // file指的是获取的excel文件对象，如e.file
      // 校验文件
      // 导入话术模板填写说明：
      // 1.对话类型分为旁白、客户说、销售说3种；
      // 2.“旁白”内容上限100字，“客户说”内容上限300字，“销售说”内容上限1000字
      const content = data.map(item => Object.values(item));
      const pass = content.every(item => [this.$t('coaching.narration'), this.$t('coaching.customerSays'), this.$t('coaching.salespersonSays')].includes(item[1]));
      if (pass) {
        this.$emit('importData', content);
      } else {
        this.status = 'error';
      }
    },
    increaseProgress() {
      this.progressTimer && clearInterval(this.progressTimer);
      this.percentage = 0;
      this.progressTimer = setInterval(() => {
        if (this.percentage <= 90) {
          this.percentage += 10;
        }
      }, 100);
    },
    downloadExcel() {
      const currentLanguage = localStorage.getItem('lang') || 'zh';
      const a = document.createElement('a');
      a.href = `/static/coachSmartTraining/${currentLanguage}.xlsx`;
      a.download = this.$t('coaching.scriptDialogueTemplate') + '.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    closeDrawer() {
      this.$emit('update:drawerVisible', false);
    }
  }
};
exports.default = _default;