"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_c("div", {
    ref: "phone",
    staticClass: "phone-wrapper"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.status !== ("HANGUP" && "INIT"),
      expression: "status !== ('HANGUP' && 'INIT')"
    }],
    staticClass: "phone-call"
  }, [_c("div", {
    staticClass: "call-header"
  }, [_c("el-button", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "top-btn",
    style: {
      visibility: _vm.showLeftArrow ? "visible" : "hidden"
    },
    attrs: {
      icon: "el-icon-arrow-left"
    }
  }), _c("el-button", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "top-btn",
    style: {
      visibility: _vm.showDownArrow ? "visible" : "hidden"
    },
    attrs: {
      icon: "el-icon-arrow-down"
    },
    on: {
      click: _vm.closeCallDialog
    }
  })], 1), _vm.status === "RINGING" || _vm.status === "CALLING" || _vm.status === "INCOMINGRING" || _vm.status === "INCOMINGCALLING" ? _c("div", {
    staticClass: "phone-content"
  }, [_c("div", {
    staticClass: "main-info"
  }, [_vm._v(" " + _vm._s(_vm.contact_person ? _vm.contact_person : _vm.phoneNumber) + " ")]), _c("div", {
    staticClass: "sub-info"
  }, [_vm._v(" " + _vm._s(_vm.contact_person ? _vm.phoneNumber : "陌生号码") + " ")]), _c("div", {
    staticClass: "status"
  }, [_vm._v(_vm._s(_vm.statusValue))])]) : _vm._e(), _vm.status === "CALLING" || _vm.status === "INCOMINGCALLING" ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showTelDial,
      expression: "!showTelDial"
    }],
    staticClass: "call-operation"
  }, [_c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon operation-btn",
    class: {
      "operation-btn-active": _vm.mute === true
    },
    on: {
      click: _vm.handleMuteClick
    }
  }, [_c("i", {
    staticClass: "el-icon-turn-off-microphone"
  }), _c("span", {
    staticClass: "operate-name"
  }, [_vm._v(_vm._s(_vm.mute ? "取消静音" : "静音"))])]), _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon operation-btn",
    class: {
      "operation-btn-active": _vm.callHold === true
    },
    on: {
      click: _vm.handleHoldClick
    }
  }, [_c("i", {
    staticClass: "el-icon-video-pause"
  }), _c("span", {
    staticClass: "operate-name"
  }, [_vm._v(_vm._s(_vm.callHold ? "恢复" : "保持"))])]), _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon operation-btn",
    class: {
      "operation-btn-active": _vm.showTelDial === true,
      "margin-right-init": _vm.status === "INCOMINGCALLING"
    },
    on: {
      click: _vm.handleTelDialClick
    }
  }, [_c("i", {
    staticClass: "iconfont icon-dialpad"
  }), _c("span", {
    staticClass: "operate-name"
  }, [_vm._v("拨号盘")])]), _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon operation-btn",
    class: {
      "operation-btn-active": _vm.callNoteVisible === true
    },
    on: {
      click: _vm.handleNoteClick
    }
  }, [_c("i", {
    staticClass: "el-icon-notebook-2"
  }), _c("span", {
    staticClass: "operate-name"
  }, [_vm._v("笔记")])])]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTelDial,
      expression: "showTelDial"
    }],
    staticClass: "number-keys"
  }, _vm._l(_vm.keys, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "key-item"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _c("div", {
    staticClass: "hangup-wrapper"
  }, [_vm.status === "RINGING" || _vm.status === "CALLING" || _vm.status === "INCOMINGRING" || _vm.status === "INCOMINGCALLING" ? _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon hangup",
    on: {
      click: _vm.handleHangup
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "#fff"
    },
    attrs: {
      "icon-class": "phone-hangup"
    }
  })], 1) : _vm._e(), _vm.status === "INCOMINGRING" && _vm.phone7MData && _vm.phone7MData.ExtenType === "sip" ? _c("div", {
    directives: [{
      name: "stopdrag",
      rawName: "v-stopdrag"
    }],
    staticClass: "el-button-icon answer",
    on: {
      click: _vm.handleAnswerCall
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "#fff"
    },
    attrs: {
      "icon-class": "phone"
    }
  })], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTelDial,
      expression: "showTelDial"
    }],
    staticClass: "hide-dial",
    on: {
      click: function ($event) {
        _vm.showTelDial = false;
      }
    }
  }, [_vm._v(" 隐藏拨号盘 ")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.status === "INCOMINGRING" && _vm.phone7MData && _vm.phone7MData.ExtenType === "Local",
      expression: "\n            status === 'INCOMINGRING' &&\n            phone7MData &&\n            phone7MData.ExtenType === 'Local'\n          "
    }],
    staticClass: "hide-dial"
  }, [_vm._v(" 请用手机接听 ")])])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;