"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-filter-detail"
  }, [_vm._l(_vm.getCustomFiltersInformation, function (value, name, index) {
    return [value ? _c("span", {
      key: index,
      staticClass: "item"
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(name) + "：")]), _c("span", {
      staticClass: "value",
      domProps: {
        innerHTML: _vm._s(value)
      }
    })]) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;