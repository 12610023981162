"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-data-chart"
  }, _vm._l(_vm.renderInfo, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "data-chart"
    }, [_c("div", {
      staticClass: "chart-title"
    }, [_vm._v(_vm._s(item.name))]), _c("chart-template", {
      attrs: {
        "chart-config": _vm.setOptions(item, item)
      }
    })], 1);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;