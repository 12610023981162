"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conv-recycle-bin-header"
  }, [_c("div", {
    staticClass: "conv-recycle-bin-header__left"
  }, [_c("el-button", {
    staticClass: "go-back-btn hover-show-bg",
    on: {
      click: _setup.goBack
    }
  }, [_c("svg-icon", {
    staticClass: "go-back",
    attrs: {
      "icon-class": "arrow-left"
    }
  })], 1), _c("div", {
    staticClass: "title"
  }, [_vm._v("会话回收站")]), _c(_setup.SpaceButton), _c("span", {
    staticClass: "desc"
  }, [_vm._v("数据默认保留30天，30天后将自动永久删除。只展示我有编辑权限的数据，编辑权限可由管理员前往后台- "), _c("a", {
    attrs: {
      href: _setup.dataPermissionPath,
      target: "_blank"
    }
  }, [_vm._v("数据权限")]), _vm._v(" 配置")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;