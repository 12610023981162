"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/components/LoginProcessComp/layout"));
var _ChooseTeamComp = _interopRequireDefault(require("@/components/LoginProcessComp/ChooseTeamComp"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ChooseTeam',
  components: {
    LoginLayout: _layout.default,
    // 布局组件
    ChooseTeamComp: _ChooseTeamComp.default
  }
};
exports.default = _default;