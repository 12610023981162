"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _workspace = require("@/api/workspace");
var _default = {
  __name: 'WorkspaceSelector',
  props: {
    value: {
      type: [Number, String, Array],
      default: ''
    },
    isLazy: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择空间'
    }
  },
  emits: ['input', 'change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handleInput = val => {
      emit('input', val);
    };
    const handleChange = val => {
      emit('change', val);
    };
    const workspaceList = (0, _vue.ref)([]);
    async function getAllWorkspaceAsync() {
      createLoading.value = true;
      const res = await (0, _workspace.getSwitchWorkspaceList)().finally(() => {
        createLoading.value = false;
      });
      if (res.code === 20000) {
        workspaceList.value = res.results.workspaces;
      }
    }
    const createLoading = (0, _vue.ref)(false);
    if (!props.isLazy) {
      getAllWorkspaceAsync();
    }
    const handleVisibleChange = () => {
      if (workspaceList.value.length === 0 && props.isLazy) {
        getAllWorkspaceAsync();
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      handleInput,
      handleChange,
      workspaceList,
      getAllWorkspaceAsync,
      createLoading,
      handleVisibleChange
    };
  }
};
exports.default = _default;