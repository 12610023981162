"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonFunc = require("@/utils/commonFunc");
// 最大值
const MAX_SCORE = 9999.99;
var _default = {
  name: 'GradeRange',
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {
        type: '',
        score_range: []
      }
    };
  },
  computed: {
    typeOptions() {
      return [{
        label: '合格',
        icon: 'icon-check',
        value: 'qualified'
      }, {
        label: '不合格',
        icon: 'icon-close',
        value: 'unqualified'
      }, {
        label: '具体分数',
        value: 'score_range'
      }];
    }
  },
  watch: {
    'params.type': {
      handler: function (type) {
        this.data = (0, _commonFunc.deepClone)(this.params);
      }
    }
  },
  methods: {
    handleNumberLimit(value) {
      return ('' + value // 第一步：转成字符串
      ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数)
      ;
    },

    handleMinScoreInput(value) {
      this.$set(this.data.score_range, 0, this.handleNumberLimit(value));
      if (Number(this.data.score_range[0]) > MAX_SCORE) {
        this.data.score_range[0] = MAX_SCORE;
      }
    },
    handleMaxScoreInput(value) {
      this.$set(this.data.score_range, 1, this.handleNumberLimit(value));
      if (Number(this.data.score_range[1]) > MAX_SCORE) {
        this.data.score_range[1] = MAX_SCORE;
      }
    },
    toggleType(val) {
      if (val === 'score_range') {
        if (this.data.score_range.length === 2) {
          this.$emit('filterChange', this.data);
        }
      } else {
        this.$emit('filterChange', this.data);
      }
    },
    change(val, index) {
      const num = Number(val) || (index ? MAX_SCORE : 0);
      this.$set(this.data.score_range, index, num);
      let [min, max] = this.data.score_range;
      if (index) {
        if (!min) {
          min = 0;
        }
      } else {
        if (!max) {
          max = MAX_SCORE;
        }
      }
      if (min > max) {
        [min, max] = [max, min];
      }
      this.data.score_range = [min, max];
      this.toggleType();
    }
  }
};
exports.default = _default;