"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }]
  }, [_vm.commentList.length > 0 ? _c("mg-infinite-scroll", {
    ref: "message",
    staticClass: "message",
    staticStyle: {
      overflow: "auto"
    },
    attrs: {
      loading: _vm.loading,
      nomore: _vm.nomore
    },
    on: {
      loadMore: _vm.load
    }
  }, _vm._l(_vm.commentList, function (item, index) {
    return _c("comment-item", {
      key: item.id || index,
      attrs: {
        item: item,
        "active-name": _vm.activeName
      },
      on: {
        handleClick: function ($event) {
          return _vm.handleClick(item);
        }
      }
    });
  }), 1) : _vm._e(), _vm.commentList.length == 0 && !_vm.listLoading && _vm.activeName == "inbox" ? _c("NoData", {
    staticClass: "no-content",
    attrs: {
      tips: "暂无信息"
    }
  }) : _vm._e(), _vm.commentList.length == 0 && !_vm.listLoading && _vm.activeName == "outbox" ? _c("NoData", {
    staticClass: "no-content",
    attrs: {
      tips: "您还没有发送过评论"
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;