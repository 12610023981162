"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _businessIntelligence = require("@/api/businessIntelligence");
var _vuex = require("vuex");
var _default = {
  name: 'ReportOverview',
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      tableHead: [{
        label: '组别名称',
        field_name: 'name'
      }],
      tableData: [],
      loading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['workspaceInfo'])
  },
  created() {
    this.getReport();
  },
  methods: {
    async getReport() {
      this.loading = true;
      const res = await (0, _businessIntelligence.getReport)({
        id: this.id,
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.loading = false;
      });
      if (res.code === 20000) {
        const {
          list
        } = res.results;
        this.tableData = list;
        list[0].data.forEach(_ref => {
          let {
            name
          } = _ref;
          this.tableHead.push({
            label: name,
            field_name: 'data'
          });
        });
      }
    }
  }
};
exports.default = _default;