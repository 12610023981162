"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeConv = _interopRequireDefault(require("./KnowledgeConv"));
var _sortCaret = _interopRequireDefault(require("@/components/ConversationPageComp/sortCaret"));
var _KnowledgePointCard = _interopRequireDefault(require("./KnowledgePointCard"));
var _vuex = require("vuex");
var _businessIntelligence = require("@/api/businessIntelligence");
var _routeMap = require("@/router/routeMap");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgePoint',
  components: {
    KnowledgeConv: _KnowledgeConv.default,
    SortCaret: _sortCaret.default,
    KnowledgePointCard: _KnowledgePointCard.default
  },
  data() {
    return {
      knowledgeTypeList: [],
      knowledgeList: [],
      knowledgePointParams: {
        sort_by: 'total',
        sort_order: 'descending',
        type_id: ''
      },
      activeKnowledgeIndex: 0,
      activeKnowledge: {},
      knowledgePointLoading: false,
      knowledgeLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'name', 'webRoutes']),
    hasTeamDataPermission() {
      return this.webRoutes.find(item => item.name === 'KnowledgeTeamData');
    },
    hasCorrectionScorePermission() {
      return this.webRoutes.find(item => item.name === 'KnowledgeScoreCorrection');
    }
  },
  async created() {
    await this.getKnowledgeAllType();
    await this.getKnowledgeList();
    this.$nextTick(() => {
      if (this.$refs['total_sort']) {
        this.$refs['total_sort'].activeStatus = 'descending';
      }
    });
  },
  methods: {
    goScoreCorrection() {
      this.$router.push({
        path: _routeMap.ROUTES.biKnowledgeScoreCorrection
      });
    },
    changeKnowledgeType(value) {
      this.knowledgePointParams.type_id = value.type_id;
      this.getKnowledgeList();
    },
    goMyKnowledge() {
      this.$router.push({
        path: _routeMap.ROUTES.biPersonalKnowledge,
        query: {
          id: this.userId,
          name: this.name
        }
      });
    },
    goTeamDataAnalysis() {
      this.$router.push({
        path: _routeMap.ROUTES.biKnowledgeTeamData
      });
    },
    handleClick(knowledgeItem, idx) {
      this.activeKnowledge = knowledgeItem;
      this.activeKnowledgeIndex = idx;
    },
    sortData(sort_by, sort_order) {
      this.knowledgePointParams.sort_by = sort_by;
      this.knowledgePointParams.sort_order = sort_order;
      this.sortInitOther(sort_by);
      this.getKnowledgeList();
    },
    sortInitOther(sort_by) {
      const refArray = ['total', 'all_score', 'my_score'];
      refArray.forEach(item => {
        if (item !== sort_by) {
          this.$refs[item + '_sort'].init();
        }
      });
    },
    // 所有知识点的类型
    async getKnowledgeAllType() {
      this.knowledgeLoading = true;
      const res = await (0, _businessIntelligence.getKnowledgeAllType)();
      if (res.code === 200) {
        this.knowledgeTypeList = res.results;
        this.knowledgePointParams.type_id = this.knowledgeTypeList.length && this.knowledgeTypeList[0].id;
      }
    },
    async getKnowledgeList() {
      if (!(this.knowledgeTypeList && this.knowledgeTypeList.length)) {
        this.knowledgeLoading = false;
        return;
      }
      this.knowledgePointLoading = true;
      const res = await (0, _businessIntelligence.getKnowledgeList)({
        ...this.knowledgePointParams
      });
      if (res.code === 200) {
        this.knowledgeList = res.results;
        this.activeKnowledgeIndex = 0;
        this.activeKnowledge = res.results[0];
      }
      this.knowledgePointLoading = false;
      this.knowledgeLoading = false;
    },
    // 类型发生变化的时候
    knowledgeTypeChange(typeId) {
      this.$nextTick(() => {
        if (this.$refs['total_sort']) {
          this.$refs['total_sort'].activeStatus = 'descending';
        }
      });
      this.sortData('total', 'descending');
    }
  }
};
exports.default = _default;