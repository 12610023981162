"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("mg-infinite-scroll", {
    staticClass: "coach-detail-list",
    attrs: {
      disabled: _vm.requestDisabled,
      loading: _vm.loading,
      nomore: _vm.noMore
    },
    on: {
      loadMore: _vm.load
    }
  }, [_c("div", {
    staticClass: "block"
  }, [_c("p", {
    staticClass: "block-title"
  }, [_vm._v("向我申请的")]), _vm._l(_vm.undoList, function (item, index) {
    return _c("coach-detail-card", {
      key: index,
      attrs: {
        item: item,
        "is-todo": true
      }
    });
  }), !_vm.undoList.length ? _c("no-data-holder", {
    attrs: {
      text: "暂无申请"
    }
  }) : _vm._e()], 2), _c("div", {
    staticClass: "block"
  }, [_c("p", {
    staticClass: "block-title"
  }, [_vm._v("向其他人申请的")]), _vm._l(_vm.otherUndoList, function (item, index) {
    return _c("coach-detail-card", {
      key: index,
      attrs: {
        item: item,
        "is-todo": true
      }
    });
  }), !_vm.otherUndoList.length ? _c("no-data-holder", {
    attrs: {
      text: "暂无申请"
    }
  }) : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;