"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getKanbanListLoading,
      expression: "getKanbanListLoading"
    }],
    staticClass: "customer-base-profile"
  }, [!_vm.getKanbanListLoading && !_vm.kanbanList.length ? _c("init-kanban-card-view", {
    attrs: {
      config: {
        title: "看板",
        tooltipTitle: "客户群画像看板",
        tooltip: ["根据关键事件和交易指标等条件圈选出固定的人群", "根据圈选出的人群选择指标进行分析", "创建成功后，系统将自动生成图表，也可根据实际情况自行编辑"]
      }
    },
    on: {
      createKanban: _vm.createKanban
    }
  }, [_c("img", {
    staticClass: "right",
    attrs: {
      slot: "right-img",
      src: require("@/assets/BI/pie-doughnut.svg")
    },
    slot: "right-img"
  })]) : _vm._e(), _vm.kanbanList.length ? [_c("BI-kanban", {
    ref: "bi-kanban",
    attrs: {
      "is-draggable": true,
      "active-id": _vm.currentKanban.kanban_id,
      "extra-command-list": _vm.getExtraCommandlist(),
      "kanban-list": _vm.kanbanList,
      "drag-kanban-method": _vm.moveCustomerBaseProfileKanban
    },
    on: {
      toggleKanban: _vm.toggleKanban,
      createKanban: _vm.createKanban,
      handleKanban: _vm.handleKanban
    }
  }), _c("chart-container", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoadingChart,
      expression: "isLoadingChart"
    }],
    attrs: {
      "sub-text": _vm.currentKanban.description
    }
  }, [_c("data-overview", {
    attrs: {
      "over-view-data": _vm.overViewData
    }
  }), _c("card-chart", {
    attrs: {
      "has-handle-premission": !_vm.currentKanban.is_share,
      "get-chart-list-loading": _vm.getChartListLoading,
      "chart-list": _vm.chartList,
      "move-customer-base-profile-chart": _vm.moveCustomerBaseProfileChart
    },
    on: {
      addChart: _vm.addChart,
      handleCommand: _vm.handleCommand,
      dragEnd: _vm.dragEnd,
      toggleFullScreen: _vm.toggleFullScreen
    }
  })], 1)] : _vm._e(), _c("chart-config", {
    ref: "chart-config",
    attrs: {
      "chart-config-visible": _vm.chartConfigVisible,
      "is-edit-chart": _vm.isEditChart,
      "chart-config-loading": _vm.chartConfigLoading,
      "metrics-type-list": _vm.metricsTypeList,
      "event-options": _vm.eventOptions,
      "account-label-options": _vm.accountLabelOptions,
      "custom-filed": _vm.chartBusinessIndicatorList,
      "kanban-id": _vm.currentKanban.kanban_id,
      "chart-id": _vm.chartId
    },
    on: {
      "update:chartConfigVisible": function ($event) {
        _vm.chartConfigVisible = $event;
      },
      "update:chart-config-visible": function ($event) {
        _vm.chartConfigVisible = $event;
      },
      handleChartSuccess: _vm.handleChartSuccess
    }
  }), _c("customer-base-profile-config", {
    ref: "customer-base-profile-config",
    attrs: {
      "operate-type": _vm.operateType,
      "drawer-visible": _vm.drawerVisible,
      "metrics-type-list": _vm.metricsTypeList,
      "event-options": _vm.eventOptions,
      "account-label-options": _vm.accountLabelOptions,
      "custom-filed": _vm.customFiled,
      "get-kanban-config-loading": _vm.getKanbanConfigLoading
    },
    on: {
      "update:drawerVisible": function ($event) {
        _vm.drawerVisible = $event;
      },
      "update:drawer-visible": function ($event) {
        _vm.drawerVisible = $event;
      },
      handleKanbanSuccess: _vm.handleKanbanSuccess
    }
  }), _c("ShareConfig", {
    attrs: {
      visible: _vm.shareKanbanConfigVisible,
      description: "被分享成员可以查看和复制，不可编辑和删除。",
      params: {
        kanban_id: _vm.currentKanban.kanban_id,
        type: 1
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.shareKanbanConfigVisible = $event;
      },
      handleClose: _vm.changeKanbanStatus
    }
  }), _c("EditKanbanNameDialog", {
    attrs: {
      visible: _vm.copyKanbanConfigVisible,
      "kanban-name": `${_vm.currentKanban.name}_副本`,
      params: {
        id: _vm.currentKanban.kanban_id,
        type: 1
      }
    },
    on: {
      "update:visible": function ($event) {
        _vm.copyKanbanConfigVisible = $event;
      },
      copyKanbanSuccess: _vm.handleKanbanSuccess
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;