"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "wrap",
    staticClass: "table-wrap"
  }, [_vm.data.length ? _c("el-button", {
    staticClass: "export-table-button",
    on: {
      click: _vm.exportXlsx
    }
  }, [_c("i", {
    staticClass: "iconfont icon-export"
  }), _vm._v("导出表格")]) : _vm._e(), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data
    }
  }, _vm._l(_vm.tableHeader, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        prop: item.prop,
        width: _vm.isLargeScreen ? undefined : _vm.getWidth(item)
      }
    }, [_c("template", {
      slot: "header"
    }, [_c("el-popover", {
      attrs: {
        placement: "top",
        trigger: "hover",
        "popper-class": "hint",
        disabled: item.name === "交易阶段",
        content: item.tooltip
      }
    }, [item.name !== "交易阶段" ? _c("i", {
      staticClass: "iconfont icon-question2 icon-size",
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }) : _vm._e()]), _vm._v(" " + _vm._s(item.name) + " ")], 1)], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;