"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _knowledgeLibrary = require("@/api/knowledgeLibrary");
var _videoDetail = require("@/api/videoDetail");
var _TricksScene = _interopRequireDefault(require("./TricksScene.vue"));
var _KnowledgeCatalogPositionSelect = _interopRequireDefault(require("./KnowledgeCatalogPositionSelect.vue"));
var _SingleTreeSelect = _interopRequireDefault(require("./SingleTreeSelect.vue"));
var _MoveKnowledgeScene = _interopRequireDefault(require("./MoveKnowledgeScene.vue"));
var _MgDraggable = _interopRequireDefault(require("@/components/MgDraggable"));
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CreateKnowledgeDrawer',
  components: {
    TricksScene: _TricksScene.default,
    KnowledgeCatalogPositionSelect: _KnowledgeCatalogPositionSelect.default,
    SingleTreeSelect: _SingleTreeSelect.default,
    MoveKnowledgeScene: _MoveKnowledgeScene.default,
    MgDraggable: _MgDraggable.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isHiddenType: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    knowledgePosition: {
      type: Object,
      default: () => ({})
    },
    extraSubmitParams: {
      type: Object,
      default: () => ({})
    },
    // 销售回答添加到某个知识库的某个知识点（BI高频问题)
    variableKnowledgeBase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePosition = (rule, value, callback) => {
      if (!value && this.knowledgeConfig.type === 'create') {
        return callback(new Error('请选择所在目录位置'));
      }
      callback();
    };
    return {
      treeData: [],
      knowledgeConfig: {
        type: 'create',
        addToKonwledgeId: 0,
        name: '',
        description: '',
        position: 0,
        question_list: [],
        skill_list: [''],
        scene_reply_list: [{
          scene_id: 0,
          scene_content: '',
          reply_list: [{
            reply_id: 0,
            reply_content: ''
          }]
        }]
      },
      rules: {
        name: [{
          required: true,
          message: '请输入客户问题',
          trigger: 'change'
        }],
        description: [{
          max: 100,
          message: '长度不能超过100个字符',
          trigger: 'change'
        }],
        position: [{
          required: true,
          validator: validatePosition,
          trigger: 'change'
        }]
      },
      submitLoading: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission', 'knowledgeBaseInfo']),
    scenesNum() {
      return this.knowledgeConfig.scene_reply_list.length;
    },
    showDragIcon() {
      return replyList => {
        return this.interfacePermission.operateKnowledge && replyList.length > 1;
      };
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.knowledgeConfig.skill_list.length) {
            this.knowledgeConfig.skill_list = [''];
          }
        });
        if (!this.variableKnowledgeBase) {
          this.getCatalogData();
          this.$nextTick(() => {
            this.$refs.positionSelect.getCatalogData();
          });
        }
      }
    },
    // BI高频问题切换知识库
    'knowledgeBaseInfo.id': {
      handler: function (val) {
        if (this.variableKnowledgeBase && val) {
          this.getCatalogData();
          this.$nextTick(() => {
            this.$refs.positionSelect.getCatalogData();
          });
        }
      }
    }
  },
  created() {
    this.variableKnowledgeBase && this.$store.commit('training/SET_KNOWLEDGE_BASE_INFO', {});
  },
  methods: {
    searchKnowledges: _videoDetail.searchKnowledges,
    async getCatalogData() {
      const res = await (0, _knowledgeLibrary.getCatalogData)({
        id: this.knowledgeBaseInfo.id || this.$route.query.knowledgeBaseId,
        is_know: true
      });
      if (res.code === 20000) {
        this.treeData = res.results.datas;
      }
    },
    toggleType() {
      if (this.knowledgeConfig.type === 'update') {
        // 清空客户问题
        this.knowledgeConfig.name = '';
        this.initKnowledgeName();
      } else {
        //  清空知识点所在目录位置
        this.initKnowledgePosition();
      }
    },
    initKnowledgeName() {
      this.$refs['radio-tree-select'].clear();
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
    initKnowledgePosition() {
      this.knowledgeConfig.position = 0;
      this.$refs.positionSelect.clearData();
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
    knowledgeIdChange(val) {
      this.knowledgeConfig.name = val.name;
      this.knowledgeConfig.addToKonwledgeId = val.id;
    },
    addQuestions() {
      if (!this.checkNumValid('question_list')) return;
      this.knowledgeConfig.question_list.push('');
    },
    // 添加回答技巧
    addAnswers() {
      if (!this.checkNumValid('skill_list')) return;
      this.knowledgeConfig.skill_list.push('');
    },
    // 添加场景
    addScene() {
      if (this.scenesNum >= 10) {
        this.$message.warning('最多只能创建10项');
        return;
      }
      this.knowledgeConfig.scene_reply_list.push({
        scene_id: 0,
        scene_content: '',
        reply_list: [{
          reply_id: 0,
          reply_content: ''
        }]
      });
      this.scrollToBottom();
    },
    // 场景内添加话术
    addTrickToScene(index) {
      if (!this.checkTricksOfSceneNumValid(index)) return;
      this.knowledgeConfig.scene_reply_list[index].reply_list.push({
        reply_id: 0,
        reply_content: ''
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs['knowledgeConfig'];
        container === null || container === void 0 ? void 0 : container.scrollTo({
          top: container === null || container === void 0 ? void 0 : container.scrollHeight,
          behavior: 'smooth'
        });
      });
    },
    backfillConfig(val) {
      this.$nextTick(() => {
        if (!val.type) {
          val.type = 'create';
        }
        this.knowledgeConfig = val;
      });
    },
    deleteItem(key, index) {
      this.knowledgeConfig[key].splice(index, 1);
    },
    deleteTrickItem(index, replyIndex) {
      this.knowledgeConfig.scene_reply_list[index].reply_list.splice(replyIndex, 1);
    },
    checkNumValid(key) {
      if (this.knowledgeConfig[key].length >= 10) {
        this.$message.warning('最多只能创建10项');
        return false;
      }
      return true;
    },
    checkTricksOfSceneNumValid(index) {
      if (this.knowledgeConfig.scene_reply_list[index].reply_list.length >= 10) {
        this.$message.warning('最多只能创建10项');
        return false;
      }
      return true;
    },
    handleClose() {
      this.knowledgeConfig = this.$options.data().knowledgeConfig;
      this.$refs['form'].resetFields();
      this.$refs.positionSelect.clearData();
      this.initKnowledgeName();
      this.$emit('update:visible', false);
      this.variableKnowledgeBase && this.$store.commit('training/SET_KNOWLEDGE_BASE_INFO', {});
    },
    handleSceneMove(type, order) {
      const list = JSON.parse(JSON.stringify(this.knowledgeConfig.scene_reply_list));
      const item = list[order];
      const insertIndex = type === 'up' ? order - 1 : order + 1;
      list.splice(order, 1);
      list.splice(insertIndex, 0, item);
      this.knowledgeConfig.scene_reply_list = list;
    },
    selectPosition(id) {
      this.knowledgeConfig.position = id;
    },
    submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const formattedData = this.getFormattedParams(this.knowledgeConfig);
          const params = {
            ...formattedData,
            ...this.extraSubmitParams
          };
          if (this.isEdit) {
            params.id = this.knowledgeConfig.id;
            params.catalog_id = this.knowledgeConfig.catalog.id;
            params.new_catalog_id = this.knowledgeConfig.position;
          }
          if (this.knowledgeConfig.type === 'update') {
            params.id = this.knowledgeConfig.addToKonwledgeId;
          }
          this.submitLoading = true;
          const fn = this.isEdit ? _knowledgeLibrary.updateKnowledge : this.knowledgeConfig.type === 'create' ? _knowledgeLibrary.createKnowledge : _knowledgeLibrary.appendReply;
          const res = await fn(params).finally(() => {
            this.submitLoading = false;
          });
          if (res.code === 20000) {
            this.$message.success(this.isEdit ? '编辑成功' : '新建成功');
            if (!this.isEdit) this.knowledgeConfig.id = res.results.id;
            this.$emit('createSuccess', this.knowledgeConfig);
            this.handleClose();
          }
        }
      });
    },
    getFormattedParams(params) {
      // 过滤一些空值
      const {
        name,
        description,
        position,
        question_list: questions,
        skill_list: answers,
        scene_reply_list: tricks
      } = params;
      const question_list = questions.filter(item => item);
      const skill_list = answers.filter(item => item);
      const scene_reply_list = tricks.map(item => {
        return {
          ...item,
          reply_list: item.reply_list.filter(item => item.reply_content),
          scene_content: item.scene_content
        };
      });
      return {
        name,
        description,
        catalog_id: position,
        question_list,
        scene_reply_list,
        skill_list
      };
    }
  }
};
exports.default = _default;