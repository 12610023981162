"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = require("@/mixin/mixin");
var _timeFormatter = require("@/utils/time-formatter");
var _vuex = require("vuex");
var _routeMap = require("@/router/routeMap");
var _RecommendedAnswer = _interopRequireDefault(require("./RecommendedAnswer.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KeyEventDetailList',
  components: {
    RecommendedAnswer: _RecommendedAnswer.default
  },
  mixins: [_mixin.handleTextHighLightMixin],
  props: {
    mediaType: {
      type: String,
      default: 'audio'
    },
    list: {
      type: Array,
      default: () => []
    },
    defaultIndex: {
      type: Number,
      default: 0
    },
    noEventClick: {
      type: Boolean,
      default: false
    },
    canEditKeyEvent: {
      type: Boolean,
      default: false
    },
    forbidSelectDefaultEvent: {
      type: Boolean,
      default: false
    },
    deleteOperateLoading: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: -1,
      deletePopperVisible: false,
      annotateTypeObj: {
        rule: '规则标注',
        artificial: '人工标注',
        ai: 'AI标注'
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['interfacePermission', 'audio']),
    correctKeyEventPermission() {
      return this.interfacePermission.correctConvText;
    },
    nameMap() {
      const nameMap = {};
      this.participants.forEach(item => {
        nameMap[item.entity_type + item.entity_id] = item.name;
      });
      return nameMap;
    }
  },
  watch: {
    defaultIndex: {
      handler: function (val) {
        this.setCurTracker(val);
      }
    },
    audio(val) {
      if (val.length && !this.noEventClick) {
        this.setCurTracker(this.defaultIndex);
      }
    }
  },
  methods: {
    handleDeleteKeyEventDetailTracker(tracker, idx) {
      this.$emit('deleteKeyEventDetailTracker', tracker, idx);
    },
    closePopover(index) {
      this.$refs['delete-popover' + index][0].doClose();
    },
    handleKeyToDetail(index) {
      this.$emit('clickToDetail', index);
    },
    secondToTime: _timeFormatter.secondToTime,
    setCurTracker(index) {
      if (!(this.list && this.list.length)) return;
      this.activeIndex = index;
      // 异常处理
      if (!this.list[index]) {
        this.activeIndex = 0;
      }
      const activeTracker = this.list[this.activeIndex];
      const {
        order,
        begin_time: beginTime
      } = activeTracker.data[0];
      this.$store.commit('video/SET_IS_VIDEO_PAUSE', true);
      this.$store.dispatch('video/set_cur_order', {
        order,
        time: new Date().getTime(),
        beginTime
      }); // 跳转到指定order；
    },

    openKnowLedgeDetail(id) {
      this.$emit('openKnowLedgeDetail', id);
    }
  }
};
exports.default = _default;