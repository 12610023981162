"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-select", {
    staticClass: "select-box role-select",
    attrs: {
      value: _vm.value,
      placeholder: _vm.placeholder,
      "popper-class": "workspace-selector",
      multiple: _vm.multiple,
      "collapse-tags": "",
      clearable: ""
    },
    on: {
      input: _setup.handleInput,
      change: _setup.handleChange,
      "visible-change": _setup.handleVisibleChange
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function () {
        return [_c("div", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: _setup.createLoading,
            expression: "createLoading"
          }],
          staticClass: "empty-option"
        })];
      },
      proxy: true
    }])
  }, _vm._l(_setup.workspaceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("i", {
      staticClass: "iconfont icon-workspace-3-fill",
      style: {
        color: item.icon_color,
        "margin-right": "4px"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;