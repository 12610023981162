"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TranserDepartmentFinder = _interopRequireDefault(require("@/components/TranserDepartmentFinderDialog/TranserDepartmentFinder"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TransferDepartmentFinderDialog',
  components: {
    TransferDepartmentFinder: _TranserDepartmentFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    defaultChecked: {
      type: Array,
      default: () => []
    },
    manageTree: {
      type: Array,
      default: () => []
    },
    remoteMethod: {
      type: Function,
      default: _permissions.getDepartmentWithAuth
    },
    title: {
      type: String,
      default: '编辑'
    }
  },
  data() {
    return {
      selection: [],
      rootTree: []
    };
  },
  methods: {
    getDepartmentWithAuth: _permissions.getDepartmentWithAuth,
    handleClose() {
      this.$emit('update:visible', false);
    },
    getManageTree(manageTree) {
      this.rootTree = manageTree;
    },
    async submit() {
      this.$emit('submit', this.selection);
      this.handleClose();
    },
    handleChange(selection, checkedIdList) {
      this.selection = selection;
    }
  }
};
exports.default = _default;