"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    top: {
      type: [Number, String],
      default: 0
    },
    left: {
      type: [Number, String],
      default: 100
    },
    visible: {
      type: Boolean,
      default: true
    }
  }
};
exports.default = _default;