"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _MgOperateColumn = _interopRequireDefault(require("@/components/MgOperateColumn"));
var _ShareKeyEventDrawer = _interopRequireDefault(require("./ShareKeyEventDrawer.vue"));
var _KeyEventDataScope = _interopRequireDefault(require("@/components/KeyEventDataScope"));
var _keyEvent = require("@/api/keyEvent");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'EventTable',
  components: {
    ShareKeyEventDialog: _ShareKeyEventDrawer.default,
    MgOperateColumn: _MgOperateColumn.default,
    KeyEventDataScope: _KeyEventDataScope.default
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currRow: {},
      shareKeyEventDialogVisible: false,
      eventIds: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'workspaceInfo', 'modulePermission']),
    tableHead() {
      var _this$eventIds;
      let headList = [];
      const allHead = [{
        prop: 'name',
        label: (_this$eventIds = this.eventIds) !== null && _this$eventIds !== void 0 && _this$eventIds.length ? `已选${this.eventIds.length}个关键事件` : '关键事件',
        width: 180
      }, {
        prop: 'messages',
        label: '描述',
        width: 180
      }, {
        prop: 'model_id',
        label: '模型',
        width: 180
      }, {
        prop: 'folder_path',
        label: '所属文件夹',
        width: 180
      }, {
        prop: 'status',
        label: '状态',
        width: 120
      }, {
        prop: 'data_scope',
        label: '应用范围',
        width: 300
      }, {
        prop: 'update_by',
        label: '更新人',
        width: 160
      }, {
        prop: 'update_at',
        label: '更新时间',
        width: 160
      }];
      if (!this.modulePermission.ModelManagement) {
        headList = allHead.filter(item => item.prop !== 'model_id');
      } else {
        headList = allHead;
      }
      return headList;
    }
  },
  methods: {
    selectable(row) {
      return row.can_set_scope;
    },
    async handleSwitchEventStatus(row) {
      const requestFunction = row.status === 2 ? _keyEvent.disableKeyEvent : _keyEvent.enableKeyEvent;
      const res = await requestFunction({
        workspace_id: this.workspaceInfo.id,
        event_ids: [row.id]
      });
      if (res.code === 20000) {
        row.status === 2 ? this.$message.success('禁用成功') : this.$message.success('启用成功');
        this.refreshTableData();
      }
    },
    handleSelectionChange(val) {
      this.eventIds = val.map(item => item.id);
      this.$emit('selectionChange', val);
    },
    getOuterOperateList(row) {
      const {
        can_move,
        can_set_scope,
        can_online,
        can_annotate,
        can_edit,
        can_share,
        can_delete
      } = row;
      const list = [{
        label: '编辑',
        command: 'edit',
        isShow: can_edit
      }, {
        label: '配置模型',
        command: 'setModel',
        'data-v-name': 'config-model-btn',
        isShow: can_edit
      }, {
        label: '移动',
        command: 'move',
        isShow: can_move,
        icon: 'icon-folder-move'
      }, {
        command: 'share',
        label: '分享',
        isShow: can_share,
        icon: 'icon-share-line'
      }, {
        command: 'scope',
        label: '设置应用范围',
        isShow: can_set_scope,
        icon: 'icon-filter'
      }, {
        command: 'delete',
        label: '删除',
        isShow: can_delete,
        icon: 'icon-delete',
        class: 'delete'
      }];
      return list;
    },
    refreshTableData() {
      this.$emit('refreshTableData');
    },
    handleCommand(val, row) {
      this.currRow = row;
      switch (val) {
        case 'edit':
          this.$emit('editEvent', row);
          break;
        case 'move':
          this.$emit('moveEvent', row);
          break;
        case 'scope':
          this.$emit('setKeyEventApplyPermission', row);
          break;
        case 'share':
          this.shareKeyEventDialogVisible = true;
          break;
        case 'delete':
          this.$emit('deleteEvent', row);
          break;
        case 'setModel':
          this.$emit('setModel', row);
          break;
        default:
          break;
      }
    },
    getStatus(val) {
      let map = {};
      if (this.modulePermission.ModelManagement) {
        map = {
          1: '已禁用',
          2: '已启用'
        };
      } else {
        map = {
          1: '待上线',
          2: '已上线'
        };
      }
      return map[val] || '';
    }
  }
};
exports.default = _default;