"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChart = _interopRequireDefault(require("../components/BarChart.vue"));
var _LineChart = _interopRequireDefault(require("../components/LineChart.vue"));
var _ExportTableButton = _interopRequireDefault(require("../components/ExportTableButton.vue"));
var _index = _interopRequireDefault(require("@/components/NoData/index"));
var _routeMap = require("@/router/routeMap");
var _organization = require("@/api/organization");
var _user = require("@/api/user");
var _vuex = require("vuex");
var _sopAnalysis = require("@/api/sopAnalysis.js");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'SopAnalysisConversation',
  components: {
    BarChart: _BarChart.default,
    LineChart: _LineChart.default,
    ExportTableButton: _ExportTableButton.default,
    NoData: _index.default
  },
  data() {
    return {
      barChartLoading: false,
      barChartData: {},
      lineChartLoading: false,
      lineChartData: {},
      dateInterval: 'day',
      tableLoading: false,
      tableData: null,
      changKey: true,
      tableDataHead: null,
      dimension: 1,
      // 1===department部门 ; 2 === member成员
      visibleOpen: false,
      treeIds: [],
      userIds: [],
      fillBackValue: null,
      convRuleValue: '',
      // url带参，无参默认选中第一个
      column: 'score_percent',
      // url带参，无参默认选中score_percent
      convRuleOptionsLoading: false,
      convRuleOptions: [],
      defaultSort: {
        prop: 'score_percent',
        order: 'descending'
      },
      propDefault: 'score_percent',
      tableSortby: [['score_percent', 'descending']],
      page: 1,
      size: 10,
      total: 0,
      mgvCallbacks: {
        onSkip: this.onSkip
      },
      tableGuideShow: 0,
      multiSteps: [{
        content: '可以切换 部门 / 成员 维度查看指标项数值',
        target: '[data-v-step="multiple-1"]',
        // 模块-功能-step-id
        placement: 'top'
      }, {
        content: '可以切换 评分规则 维度查看指标项数值',
        target: '[data-v-step="multiple-2"]',
        placement: 'top'
      }, {
        content: '点击指标列名称切换上方图表显示对应值、调整排序',
        target: '[data-v-step="multiple-3"]',
        placement: 'top'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['timezone', 'workspaceInfo', 'sopDateFilter']),
    setTableListParam() {
      return {
        date: this.sopDateFilter,
        tree_ids: this.treeIds,
        user_ids: this.userIds,
        score_rule_id: Number(this.convRuleValue),
        view_type: Number(this.dimension),
        workspace_id: this.workspaceInfo.id,
        time_zone: this.timezone,
        page: this.page,
        size: this.size,
        sort_by: this.tableSortby
      };
    }
  },
  watch: {
    dimension: {
      handler(val) {
        this.changKey = !this.changKey;
      },
      immediate: true
    },
    convRuleValue: {
      handler(val) {
        if (val) {
          this.changKey = !this.changKey;
        }
      },
      immediate: true
    },
    'workspaceInfo.id': {
      handler: function (val) {
        if (val) {
          this.getScoreRule().then(() => {
            this.getData();
          });
        }
      },
      immediate: true
    },
    sopDateFilter() {
      this.getData();
    }
  },
  methods: {
    getOrgTree: _organization.getOrgTree,
    getUserList: _organization.getUserList,
    getDataOrgMemberTree: _user.getDataOrgMemberTree,
    exportSopAnalysisConversationTableData: _sopAnalysis.exportSopAnalysisConversationTableData,
    handleQuery() {
      const isEmptyObject = JSON.stringify(this.$route.query);
      if (isEmptyObject !== '{}') {
        const {
          scoreRuleId,
          column,
          viewType
        } = this.$route.query;
        this.convRuleValue = scoreRuleId;
        this.column = column;
        this.dimension = viewType;
        this.defaultSort.prop = column;
        this.tableSortby[0] = [column, 'descending'];
        this.propDefault = column;
      }
    },
    getSetRulePageLink() {
      return _routeMap.ROUTES.conversationScoreSetting;
    },
    exportTableData() {
      this.$refs['export-table-button'].exportTable({
        body: this.setTableListParam,
        module: 'sop',
        column: 'conv_score_analysis'
      });
    },
    departmentMultipleChange(ids, val) {
      this.treeIds = ids;
      this.page = 1;
      this.getData();
    },
    async getScoreRule() {
      this.convRuleOptionsLoading = true;
      const res = await (0, _sopAnalysis.getSopAnalysisFiltersConfig)({
        workspace_id: this.workspaceInfo.id
      }).finally(() => {
        this.convRuleOptionsLoading = false;
      });
      if (res.code === 20000) {
        this.convRuleOptions = res.results.conv.socre_rules;
        if (!this.convRuleValue && this.$route.query.scoreRuleId) {
          this.handleQuery();
        } else {
          this.convRuleValue = this.convRuleOptions[0].id;
        }
      }
    },
    handleMemberChange(ids, val) {
      this.userIds = ids['user_ids'];
      this.treeIds = ids['tree_ids'];
      if (JSON.stringify(this.fillBackValue) === JSON.stringify(val)) return;
      this.fillBackValue = val;
      this.page = 1;
      this.getData();
    },
    handleConvRuleChange(val) {
      this.convRuleValue = val;
      this.page = 1;
      this.getData();
    },
    getData() {
      if (!this.convRuleValue) return;
      this.getBarChartData();
      this.getLineChartData();
      this.getTableList();
    },
    scrollToTop(top) {
      this.$nextTick(() => {
        var _this$$refs$container;
        (_this$$refs$container = this.$refs['container']) === null || _this$$refs$container === void 0 ? void 0 : _this$$refs$container.scrollTo({
          top
        });
      });
    },
    async getBarChartData() {
      this.barChartLoading = true;
      const res = await (0, _sopAnalysis.getSopAnalysisConversationData)({
        ...this.setTableListParam,
        column: this.column,
        size: 5
      }).finally(() => {
        this.barChartLoading = false;
      });
      if (res.code === 20000) {
        setTimeout(() => {
          this.barChartData = res.results;
          this.barChartData.dimension = this.dimension === 1 ? '按部门' : '按成员';
        });
      }
    },
    checkDateRangeEquals() {
      const dateFrom = JSON.stringify(this.sopDateFilter[0]);
      const dateTo = JSON.stringify(this.sopDateFilter[1]);
      if (dateFrom === dateTo) {
        this.dateInterval = 'hour';
        this.$refs['convLineChart'].dateInterval = 'hour';
      }
    },
    dateIntervalChange(val) {
      this.dateInterval = val;
      this.getLineChartData();
    },
    async getLineChartData() {
      // this.checkDateRangeEquals();
      this.lineChartLoading = true;
      const res = await (0, _sopAnalysis.getSopAnalysisConversationLineChartData)({
        ...this.setTableListParam,
        date_interval: this.dateInterval,
        column: this.column
      }).finally(() => {
        this.lineChartLoading = false;
      });
      if (res.code === 20000) {
        this.lineChartData = res.results;
        this.lineChartData.dimension = this.dimension === 1 ? '按部门' : '按成员';
      }
    },
    async getTableList() {
      var _this$$refs$container2;
      const pageScrollTop = (_this$$refs$container2 = this.$refs['container']) === null || _this$$refs$container2 === void 0 ? void 0 : _this$$refs$container2.scrollTop;
      this.tableLoading = true;
      const {
        results
      } = await (0, _sopAnalysis.getSopAnalysisConversationTableData)(this.setTableListParam).finally(() => {
        this.tableLoading = false;
      });
      this.total = results.total_count;
      this.tableData = results.list;
      this.tableDataHead = results.head;
      this.scoreRuleTip = results.score_rule_tip;
      this.changKey = !this.changKey;
      setTimeout(() => {
        this.scrollToTop(pageScrollTop);
        this.getGuideShow();
      });
    },
    getGuideShow() {
      this.$nextTick(() => {
        if (localStorage.getItem('isShowSopTableGuide') !== 'true') {
          this.$refs['multiple'].start();
          this.tableGuideShow++;
        }
      });
    },
    handleDone(tour) {
      localStorage.setItem('isShowSopTableGuide', true);
      tour.stop();
    },
    onSkip() {
      localStorage.setItem('isShowSopTableGuide', true);
    },
    dropDownChange(val) {
      this.$nextTick(() => {
        this.dimension = val;
        if (val === 1) {
          this.userIds = [];
          this.fillBackValue = null;
        }
        this.treeIds = [];
        this.page = 1;
        this.getData();
      });
    },
    handleSortChange(param) {
      const sortOrder = param.order || 'ascending';
      const sortKey = param.prop;
      this.defaultSort = {
        prop: sortKey,
        order: sortOrder
      };
      const sortParam = [sortKey, sortOrder];
      sortOrder ? this.tableSortby[0] = sortParam : this.tableSortby = [];
      this.column = sortKey;
      this.propDefault = sortKey;
      this.getData();
    },
    sizeChange(val) {
      this.size = val;
      this.getData();
    },
    currentChange(val) {
      const maxPage = 10000 / this.size;
      this.page = this.size * val > 10000 ? maxPage : val;
      this.getData();
    }
  }
};
exports.default = _default;