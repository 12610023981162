"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _configDetailShow = _interopRequireDefault(require("@/views/businessIntelligence/components/AnalysisConfig/configDetailShow"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
var _formatDataUnit = require("@/components/Charts/formatDataUnit");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DetailFilter',
  components: {
    ImageAvatar: _ImageAvatar.default,
    ConfigDetailShow: _configDetailShow.default
  },
  mixins: [_ExportMixins.default],
  props: {
    id: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
      default: 0
    },
    monitorGroups: {
      type: Array,
      default: () => []
    },
    monitorTargets: {
      type: Array,
      default: () => []
    },
    memberOptions: {
      type: Array,
      default: () => []
    },
    exportParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      exportDataLoading: false,
      target: 0,
      group: '整体情况',
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      filterMembers: [],
      dateInterval: 'day',
      dateOptions: Object.freeze([{
        value: 'day',
        name: '天'
      }, {
        value: 'week',
        name: '周'
      }, {
        value: 'month',
        name: '月'
      }])
    };
  },
  computed: {
    defaultMembers() {
      const members = this.memberOptions.map(_ref => {
        let {
          user_id
        } = _ref;
        return user_id;
      });
      return members.slice(0, 20);
    }
  },
  watch: {
    group(val) {
      this.$emit('groupChange', 'group_name', val);
    },
    dateInterval(val) {
      this.$emit('filterChange', 'date_interval', val);
    },
    target(val) {
      this.$emit('filterChange', 'monitor_config', this.monitorTargets[val]);
    },
    filterMembers(val) {
      // 默认选择前20筛选人员
      const user_ids = val.length ? val.slice(0, 20) : this.defaultMembers;
      this.$emit('filterChange', 'user_ids', user_ids);
    }
  },
  methods: {
    async exportData() {
      this.exportDataLoading = true;
      const res = await (0, _businessIntelligence.exportExperimentTable)(this.exportParams).finally(() => {
        this.exportDataLoading = false;
      });
      if (res.code === 20000) {
        const {
          data,
          filename: fileName
        } = res.results;
        // eslint-disable-next-line vue/no-mutating-props
        this.exportParams.monitor_config.configs.draw_style = this.exportParams.monitor_config.configs.dimension;
        data.forEach(sheetItem => {
          sheetItem.sheetName = sheetItem.Name;
          sheetItem.data = sheetItem.ColInfo[0].map((_, columnIndex) => {
            return sheetItem.ColInfo.map((rowItem, rowIndex) => {
              if (columnIndex) {
                if (rowIndex) {
                  return (0, _formatDataUnit.formatDataUnit)(this.exportParams.monitor_config.configs, rowItem[columnIndex]).replace(/<[^>]+>/g, '');
                } else {
                  // 时间列
                  return this.handleDate(rowItem[columnIndex]);
                }
              } else {
                // 标题栏
                return rowItem[columnIndex];
              }
            });
          });
        });
        (0, _download.downloadBlob)(this.handleFileToBuf(data, true), fileName);
      }
    },
    handleDate(date) {
      switch (this.exportParams.date_interval) {
        // 周维度展示 年+月+日+“当周”
        case 'week':
          return this.$moment(date).format('YYYY-MM-DD') + '当周';
        // 月维度展示 年+月 '2022-12-24' => '2022-12'
        case 'month':
          return this.$moment(date).format('YYYY-MM');
        default:
          // 默认 day
          return this.$moment(date).format('YYYY-MM-DD');
      }
    },
    handleMemberChange(val) {
      const tree_ids = [];
      const user_ids = [];
      val.forEach(item => {
        String(item).startsWith('tree_id_') ? tree_ids.push(Number(item.replace('tree_id_', ''))) : user_ids.push(Number(item));
      });
      this.$emit('memberChange', tree_ids, user_ids);
    }
  }
};
exports.default = _default;