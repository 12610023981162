"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _MgTag = _interopRequireDefault(require("@/components/MgTag"));
var _num_formatter = require("@/utils/num_formatter");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const ICONS = ['el-icon-chat-line-square', 'iconfont icon-at', 'iconfont icon-reply', 'iconfont icon-hand-2', 'iconfont icon-suggest'];
var _default = {
  name: 'CommentItem',
  components: {
    ImageAvatar: _ImageAvatar.default,
    MgTag: _MgTag.default
  },
  inject: ['getConvTime'],
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    iconClass() {
      var _this$item;
      return ICONS[((_this$item = this.item) === null || _this$item === void 0 ? void 0 : _this$item.content_type) - 1];
    }
  },
  created() {},
  methods: {
    formatCommentContent: _commonFunc.formatCommentContent,
    handleClick(item) {
      this.$emit('handleClick', item);
    },
    parseFormatNum(num, n) {
      return (0, _num_formatter.parseFormatNum)(num, n);
    },
    handleStatusMsg(status) {
      switch (status) {
        case 'win':
          return {
            iconClass: 'el-icon-circle-check',
            type: 'success'
          };
        case 'loss':
          return {
            iconClass: 'el-icon-circle-close',
            type: 'failure'
          };
        case 'invalidate':
          return {
            iconClass: 'el-icon-remove-outline',
            type: 'invalid'
          };
        default:
          break;
      }
      return {
        iconClass: '',
        type: ''
      };
    },
    handleConvTitle(item) {
      if (item.entity_type === 'video') {
        return 'iconfont icon-video-camera';
      } else if (item.entity_type === 'audio') {
        return 'iconfont icon-phone-outline';
      } else if (item.params && item.params.entity_type === 'deal') {
        return 'iconfont icon-deal4';
      }
    }
  }
};
exports.default = _default;