"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'CustomEventInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup(__props, _ref) {
    let {
      expose,
      emit
    } = _ref;
    const props = __props;
    const CUSTOM_MARK = '✏️ ';
    const localValue = (0, _vue.computed)(() => props.value.replace(CUSTOM_MARK, ''));
    const customName = (0, _vue.computed)(() => CUSTOM_MARK + localValue.value);
    const onInput = value => {
      emit('input', value);
    };
    const onBlur = e => {
      emit('blur', e, customName.value);
    };
    const getName = () => {
      return customName.value;
    };
    const inputRef = (0, _vue.ref)(null);
    const focus = () => {
      inputRef.value.focus();
    };
    expose({
      getName,
      focus
    });
    return {
      __sfc: true,
      CUSTOM_MARK,
      props,
      emit,
      localValue,
      customName,
      onInput,
      onBlur,
      getName,
      inputRef,
      focus
    };
  }
};
exports.default = _default;