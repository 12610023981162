"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "setting-subtitle"
  }, [_c("span", {
    staticClass: "setting-subtitle__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("span", {
    staticClass: "setting-subtitle__subtitle"
  }, [_vm._v(_vm._s(_vm.subTitle))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;