"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty-help-tip"
  }, [_c("h2", [_vm._v(_vm._s(_vm.tipInfo.mainIntroduction.title))]), _vm.tipInfo.mainIntroduction.content ? _c("p", {
    staticClass: "main-content"
  }, [_vm._v(" " + _vm._s(_vm.tipInfo.mainIntroduction.content) + " ")]) : _vm._e(), _vm._l(_vm.tipInfo.description, function (item) {
    return [_vm.tipInfo.description.length ? _c("div", {
      key: item.title,
      staticClass: "description"
    }, [item.title ? _c("h3", [_vm._v(_vm._s(item.title))]) : _vm._e(), _vm._l(item.content, function (sentence) {
      return _c("p", {
        key: sentence,
        staticClass: "description-content"
      }, [_vm._v(" " + _vm._s(sentence) + " ")]);
    })], 2) : _vm._e()];
  }), _c("footer", [_vm._t("operate-btn"), _c("a", {
    staticClass: "know-more",
    attrs: {
      href: _vm.tipInfo.helpUrl,
      target: "_blank"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-question3"
  }), _c("span", [_vm._v("了解更多")])])], 2)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;