"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const state = {
  noteVisible: false,
  conversationId: null,
  customerName: '',
  leadId: null,
  dealId: null,
  noteTime: '00:00',
  isCloseNote: false
};
const mutations = {
  SET_NOTE_VISIBLE: (state, val) => {
    state.noteVisible = val;
  },
  SET_CONVERSATION_ID: (state, val) => {
    state.conversationId = val;
  },
  SET_CUSTOMER_NAME: (state, val) => {
    state.customerName = val;
  },
  SET_NOTE_TIME: (state, val) => {
    state.noteTime = val;
  },
  SET_DEAL_ID: (state, val) => {
    state.dealId = val;
    state.leadId = null;
  },
  SET_LEAD_ID: (state, val) => {
    state.leadId = val;
    state.dealId = null;
  },
  SET_IDS_INIT: state => {
    state.leadId = null;
    state.dealId = null;
    state.conversationId = null;
  },
  SET_IS_CLOSE_NOTE: (state, val) => {
    state.isCloseNote = val;
  }
};
const actions = {};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;