"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/i18n");
var _default = {
  __name: 'ImportResults',
  props: {
    importResult: {
      type: Object,
      default: () => {
        return {
          fail_count: 0,
          success_count: 0,
          total: 0,
          failed_datas: []
        };
      }
    }
  },
  emits: ['retryImport'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const handleRetryImport = () => {
      emit('retryImport');
    };
    return {
      __sfc: true,
      props,
      emit,
      handleRetryImport,
      t: _i18n.t
    };
  }
};
exports.default = _default;