"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "select-metrics-dialog mg-common-dialog border custom-body",
    attrs: {
      title: "评分指标",
      visible: _vm.visible,
      width: "1000px"
    },
    on: {
      "update:visible": function ($event) {
        return _setup.emit("update:visible", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "footer-content"
        }, [_c("MgvButton", {
          attrs: {
            type: "text",
            icon: "icon-plus"
          },
          on: {
            click: function ($event) {
              _setup.metricCreateDialogVisible = true;
            }
          }
        }, [_vm._v("新增评分指标")]), _c("div", {
          staticClass: "btn-group"
        }, [_c("span", {
          staticClass: "gist-count"
        }, [_vm._v(" 已关联 " + _vm._s(_setup.selectedList.length) + " / 8 个指标 ")]), _c("el-button", {
          on: {
            click: function ($event) {
              return _setup.emit("update:visible", false);
            }
          }
        }, [_vm._v("取 消")]), _c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: _setup.confirmValue
          }
        }, [_vm._v("确 定")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "dialog-content"
  }, [_c("aside", {
    staticClass: "content-aside"
  }, [_c(_setup.DimensionList, {
    ref: "dimensionListRef",
    on: {
      "node-click": _setup.dimensionNodeClick
    }
  })], 1), _c("div", {
    staticClass: "content-metrics"
  }, [_c(_setup.MetricsTable, {
    ref: "metricsTableRef",
    attrs: {
      data: _setup.metricList,
      "table-height": 480,
      selection: _setup.activeSelection,
      selectable: _setup.selectable
    },
    on: {
      "selection-change": _setup.onSelectionChange
    }
  })], 1)]), _c(_setup.MetricCreateDialog, {
    attrs: {
      visible: _setup.metricCreateDialogVisible,
      "dimension-id": _setup.activeDimension.id
    },
    on: {
      "update:visible": function ($event) {
        _setup.metricCreateDialogVisible = $event;
      },
      createSuccess: _setup.getScoreMetricsList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;