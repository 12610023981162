"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "conv-recycle-bin-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "convRecycleBinTableRef",
    attrs: {
      data: _vm.tableData
    }
  }, [_vm._l(_setup.tableHeader, function (item) {
    return _c("el-table-column", {
      key: item.prop,
      attrs: {
        label: item.label,
        prop: item.prop,
        "show-overflow-tooltip": "",
        "min-width": "120px"
      },
      scopedSlots: _vm._u([item.prop === "user" ? {
        key: "default",
        fn: function (_ref) {
          var _row$user, _row$user2, _row$user3;
          let {
            row
          } = _ref;
          return [_c("MgvAvatarName", {
            attrs: {
              src: row === null || row === void 0 ? void 0 : (_row$user = row.user) === null || _row$user === void 0 ? void 0 : _row$user.avatar,
              name: row === null || row === void 0 ? void 0 : (_row$user2 = row.user) === null || _row$user2 === void 0 ? void 0 : _row$user2.name,
              size: 28
            }
          }), _c("span", {
            staticClass: "operator-name"
          }, [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$user3 = row.user) === null || _row$user3 === void 0 ? void 0 : _row$user3.name))])];
        }
      } : item.prop === "left_time" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_vm._v(" " + _vm._s(row.left_time) + " ")];
        }
      } : null], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        let {
          row
        } = _ref3;
        return [_c("el-popconfirm", {
          attrs: {
            "confirm-button-text": "恢复",
            "cancel-button-text": "取消",
            "confirm-button-type": "primary",
            "popper-class": "recover-popover",
            title: "确定恢复吗?",
            icon: "iconfont "
          },
          on: {
            onConfirm: function ($event) {
              return _setup.handleCommand("recover", row);
            }
          }
        }, [_c("el-button", {
          staticClass: "recover-btn",
          attrs: {
            slot: "reference",
            type: "text"
          },
          slot: "reference"
        }, [_vm._v("恢复")])], 1), _c("el-popconfirm", {
          attrs: {
            "confirm-button-text": "删除",
            "cancel-button-text": "取消",
            "popper-class": "recover-popover",
            "confirm-button-type": "danger",
            icon: "iconfont icon-warning",
            title: "确定永久删除吗?"
          },
          on: {
            onConfirm: function ($event) {
              return _setup.handleCommand("delete", row);
            }
          }
        }, [_c("el-button", {
          staticClass: "delete-btn",
          attrs: {
            slot: "reference",
            type: "text"
          },
          slot: "reference"
        }, [_vm._v("永久删除")])], 1)];
      }
    }])
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;