"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TradeDetailDrawer = _interopRequireDefault(require("./components/TradeDetailDrawer"));
var _busiCalendarDirectory = _interopRequireDefault(require("@/components/BusinessDetail/busiCalendarDirectory"));
var _itemsFlow = _interopRequireDefault(require("@/components/BusinessDetail/itemsFlow"));
var _itemDetail = _interopRequireDefault(require("@/components/BusinessDetail/itemDetail"));
var _progressTags = _interopRequireDefault(require("@/components/BusinessDetail/progressTags.vue"));
var _DetailHeader = _interopRequireDefault(require("@/layout/components/DetailHeader"));
var _workspace = require("@/api/workspace");
var _trade = require("@/api/trade");
var _MeetingDialog = _interopRequireDefault(require("@/components/MeetingDialog"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// import CommentsArea from '@/components/Detail/CommentsArea';
// import BusinessAllotDialog from '@/components/BusinessAllotDialog';
// import DealStageLine from '@/components/DealForm/DealStageLine.vue';
// import DealOperateButton from '@/views/trade/components/DealOperateButton';
// import { ROUTES } from '@/router/routeMap';
var _default = {
  name: 'TradeDetail',
  components: {
    TradeDetailDrawer: _TradeDetailDrawer.default,
    BusiCalenderDirectoty: _busiCalendarDirectory.default,
    ItemsFlow: _itemsFlow.default,
    ItemDetail: _itemDetail.default,
    ProgressTags: _progressTags.default,
    // CommentsArea,
    DetailHeader: _DetailHeader.default,
    MeetingDialog: _MeetingDialog.default
    // BusinessAllotDialog,
    // DealStageLine
    // DealOperateButton,
  },

  inject: ['reload'],
  provide() {
    return {
      getActiveCommentId: this.getActiveCommentId,
      commentType: 'business',
      init: this.init
    };
  },
  async beforeRouteEnter(to, from, next) {
    let workspaceInfo = JSON.parse(sessionStorage.getItem('workspaceInfo'));
    if (!workspaceInfo) {
      (0, _workspace.getLatestWorkspaceInfo)().then(async ans => {
        if (ans.code === 20000) {
          workspaceInfo = ans.results;
          sessionStorage.setItem('workspaceInfo', JSON.stringify(workspaceInfo));
        }
        next();
      });
    } else {
      next();
    }
  },
  data() {
    return {
      tradeId: null,
      isCalenderFold: false,
      business: {},
      tradeInfo: {},
      rightBarActive: false,
      // 评论显隐
      activeCommentId: null,
      // 消息转过来的打开评论的页面传递的commentId
      allotDialogProps: {
        dialogVisible: false
      },
      detailLoading: false,
      callVisible: false,
      // 有多个联系人时显示
      progressObj: {
        status: 'init',
        curLabel: '客户验证',
        type: 'in_process',
        progressArray: []
      },
      dealDrawerVisible: false,
      stageValue: 1,
      // 当前交易阶段id值
      stageType: '',
      // 当前交易阶段type
      dealDynamicForm: [],
      formDesc: [],
      meetingDialogVisible: false,
      meetingDialogTitle: '预约会议',
      meetingProps: {},
      tradeStageOptions: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'busiActiveDate', 'busiActiveConver', 'has_outbound', 'has_video_permission', 'api_permissions', 'workspaceInfo', 'timezone']),
    backButton() {
      var _this$$route, _this$$route$query;
      return !((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route$query = _this$$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.noback);
    },
    isStagePermission() {
      return this.api_permissions.includes('/api/deal/detail/change_stage');
    },
    isStageReadonly() {
      return ['win', 'loss', 'invalidate'].includes(this.stageType);
    },
    timelineArray() {
      // 深拷贝出对象，避免公用对象间的混乱
      const obj = JSON.parse(JSON.stringify(this.tradeInfo));
      return obj.timeline;
    },
    getActiveDetail() {
      const timelineArray = this.tradeInfo.timeline;
      if (timelineArray) {
        let itemObj = {};
        timelineArray.forEach(item => {
          if (item.date === this.busiActiveDate) {
            item.infos.forEach(itemInfo => {
              if (itemInfo.id === this.busiActiveConver) {
                itemObj = itemInfo;
              }
            });
          }
        });
        return itemObj;
      } else {
        return {};
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getRightDialogTitle() {
      if (this.rightBarActive) {
        return '评论';
      }
    },
    slotOperateButtons() {
      return [{
        operateType: 'createMeeting',
        icon: 'reservation-2',
        text: '预约会议',
        isShow: this.has_video_permission
      }, {
        operateType: 'callout',
        icon: 'phone-outline',
        text: '呼叫',
        isShow: this.api_permissions.includes('/api/micro_record/outbound/config/get'),
        isShowList: true
      }];
    }
  },
  watch: {
    rightBarActive(val) {
      if (!val) {
        this.activeCommentId = null;
      }
    },
    webSocketMsg: {
      handler: function (newVal) {
        var _newVal$extra_info, _newVal$extra_info2;
        // 如果是评论相关的消息，是本会话的需要刷新评论数据
        if (newVal.type === 2 &&
        // eslint-disable-next-line eqeqeq
        ((_newVal$extra_info = newVal.extra_info) === null || _newVal$extra_info === void 0 ? void 0 : _newVal$extra_info.entity_type) === 'deal' && ((_newVal$extra_info2 = newVal.extra_info) === null || _newVal$extra_info2 === void 0 ? void 0 : _newVal$extra_info2.entity_id) === Number(this.tradeId)) {
          if (this.rightBarActive) {
            if (this.$refs.comments) {
              this.$refs.comments.getComments();
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.tradeId = this.$route.query.id;
    this.init();
    this.getDynamicForm();
  },
  mounted() {
    // 如果页面上有评论id, 则打开右侧的评论
    const commentId = this.$route.query.commentId ? this.$route.query.commentId : '';
    if (commentId) {
      this.rightBarActive = true;
      this.activeCommentId = Number(commentId);
    }
  },
  methods: {
    getLatestWorkspaceInfo() {},
    async init() {
      this.$store.commit('business/SET_BUSI_ACTIVE_DATE', '');
      this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', null);
      await this.getTradeStageList();
      this.$store.dispatch('business/get_business_status_options').then(async () => {
        await this.getTradeDetail();
      });
      await this.getTradeTimeline();
    },
    // 获取交易阶段
    async getTradeStageList() {
      const res = await (0, _trade.getTradeStageList)({
        workspace_id: JSON.parse(sessionStorage.getItem('workspaceInfo')).id
      });
      if (res.code === 20000) {
        this.tradeStageOptions = res.results.stages;
      }
    },
    async getTradeTimeline() {
      const res = await (0, _trade.getTradeTimeline)({
        trade_id: this.$route.query.id,
        workspace_id: JSON.parse(sessionStorage.getItem('workspaceInfo')).id,
        time_zone: this.timezone
      });
      if (res.code === 20000) {
        const {
          customer_timeline,
          salesman_timeline,
          timeline
        } = res.results;
        if (this.$route.query.date) {
          this.setDefaultActiveConverBydate(this.$route.query.date, timeline);
        } else if (this.$route.query.convId) {
          this.setDefaultActiveConverById(Number(this.$route.query.convId), timeline);
        } else {
          this.setDefaultActiveVal(timeline);
        }
        this.tradeInfo = {
          ...this.tradeInfo,
          customer_timeline,
          salesman_timeline,
          timeline
        };
      }
    },
    async getDynamicForm() {
      const res = await (0, _trade.getTradeForm)();
      if (res.code === 20000) {
        var _this$$route$query2;
        this.dealDynamicForm = res.results.fields;
        const isOpenDetailDrawer = (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.openDetailDrawer;
        if (isOpenDetailDrawer) {
          this.openDealDrawer();
        }
      }
    },
    // 表单描述格式化
    descFormat(results, currentAccount) {
      const desc = [];
      for (const item of results) {
        const obj = {
          id: String(item.id),
          label: item.name,
          type: item.from_type,
          required: !!item.is_require,
          unique: item.is_unique,
          preset: !!item.field_type,
          action: '',
          options: item.options,
          disabled: item.is_edit === 0,
          default: item.default_value,
          tips: item.tips,
          table: [],
          field_name: item.field_name
        };
        if (item.name === '客户名称') {
          obj.default = currentAccount.account_name;
        }
        if (item.from_type === 'table') {
          obj.table.push(...item.table);
        }
        if (item.from_type === 'floatnumber') {
          obj.precisions = item.precisions;
          obj.is_thousand_separate = item.is_thousand_separate;
          obj.is_money = item.is_money;
        }
        desc.push(obj);
      }
      return desc;
    },
    openDealDrawer() {
      this.dealDrawerVisible = true;
      const dealDynamicForm = this.dealDynamicForm.filter(item => {
        return item.field_name !== 'trade_stage';
      });
      this.formDesc = this.descFormat(dealDynamicForm, this.tradeInfo);
    },
    // async getOneBusiness() {
    //   const res = await getOneBusiness(
    //     this.$route.query.id,
    //     this.workspaceInfo.id
    //   );
    //   if (res.code === 200) {
    //     this.business = res.results;
    //   }
    // },
    changeCommuDrawer(val) {
      this.rightBarActive = val;
    },
    async getTradeDetail() {
      this.detailLoading = true;
      const params = {
        trade_id: this.$route.query.id,
        workspace_id: JSON.parse(sessionStorage.getItem('workspaceInfo')).id,
        time_zone: this.timezone
      };
      const res = await (0, _trade.getTradeDetail)(params);
      if (res.code === 20000) {
        const {
          stage,
          plan_to_deal_at,
          account_name
        } = res.results;
        this.stageValue = stage;
        this.getStageTypeByid(stage);
        this.handleProgressObj(this.stageValue, plan_to_deal_at);
        this.tradeInfo = {
          ...res.results,
          ...this.tradeInfo
        };
        this.tradeInfo.amount = this.tradeInfo.amount_money; // 与form返回金额字段不一致，需要转换
        // 兼容客户和预计成交日期字段,busiCalenDirectory公共组件使用
        this.tradeInfo.customer_name = account_name;
        this.tradeInfo.predeal = plan_to_deal_at;
        this.tradeInfo.has_edit = true;
        this.detailLoading = false;
      }
    },
    closeCallDialog() {
      this.callVisible = false;
    },
    setDefaultActiveVal(timelineArray) {
      timelineArray.forEach((item, index) => {
        if (index === 0) {
          this.$store.commit('business/SET_BUSI_ACTIVE_DATE', item.date);
          this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', item.infos[0].id);
        }
      });
    },
    setDefaultActiveConverBydate(date, timelineArray) {
      timelineArray.forEach(item => {
        if (this.$moment(item.date).isSame(date)) {
          this.$store.commit('business/SET_BUSI_ACTIVE_DATE', date);
          this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', item.infos[0].id);
        }
      });
    },
    setDefaultActiveConverById(id, timelineArray) {
      timelineArray.forEach(item => {
        item.infos.forEach(conv => {
          if (conv.id === id) {
            this.$store.commit('business/SET_BUSI_ACTIVE_DATE', item.date);
            this.$store.commit('business/SET_BUSI_ACTIVE_CONVER', id);
          }
        });
      });
    },
    handleProgressObj(stage, plan_to_deal_at) {
      this.progressObj.status = stage;
      this.progressObj.type = this.stageType;
      if (plan_to_deal_at) {
        this.progressObj.plan_to_deal_at = plan_to_deal_at;
      }
      let curIndex = 0;
      this.progressObj.progressArray = this.handleBusinessStatusOptions(this.stageValue, this.stageType);
      this.progressObj.progressArray.forEach((item, index) => {
        if (item.name === stage) {
          this.progressObj.curLabel = item.label;
          item.date = true;
          curIndex = index;
        }
      });
      this.progressObj.progressArray.forEach((item, index) => {
        if (index <= curIndex) {
          item.date = true;
        } else {
          item.date = false;
        }
      });
    },
    handleBusinessStatusOptions(currentStage, currentStageType) {
      const readonlyValues = ['win', 'loss', 'invalidate'];
      const progressArray = [];
      this.tradeStageOptions.forEach(item => {
        if (item.stage_type === 'in_process') {
          const itemObj = {
            label: item.stage_name,
            name: item.id,
            date: false,
            visible: false
          };
          progressArray.push(itemObj);
        } else {
          if (currentStage === item.id) {
            const closeItemObj = {
              label: item.stage_name,
              name: item.id,
              date: false,
              visible: false
            };
            progressArray.push(closeItemObj);
          }
        }
      });
      if (!readonlyValues.includes(currentStageType)) {
        progressArray.push({
          label: '结束',
          name: 'close',
          date: false,
          visible: false
        });
      }
      return progressArray;
    },
    getActiveCommentId() {
      return this.activeCommentId;
    },
    // 根据stage id值名获取该交易类型
    getStageTypeByid(stage) {
      function checkStage(item) {
        return item.id === stage;
      }
      const stageItem = this.tradeStageOptions.find(checkStage);
      // eslint-disable-next-line no-undef
      this.stageType = stageItem === null || stageItem === void 0 ? void 0 : stageItem.stage_type;
    },
    // 修改交易阶段
    async changeStage(val) {
      const res = await (0, _trade.changeTradeStage)({
        workspace_id: this.workspaceInfo.id,
        trade_id: this.tradeId,
        stage_id: val
      });
      if (res.code === 20000) {
        this.$message.success('修改成功！');
        this.stageValue = val;
        this.getStageTypeByid(val);
        this.handleProgressObj(val);
        // this.getOneBusiness();
      }
    },

    clickContactPersonItem(contactItem, dealInfo, type, contactList, meetingType) {
      if (type === 'createMeeting') {
        this.createMeeting(contactItem, dealInfo, contactList, meetingType);
      } else {
        this.callOut(contactItem, dealInfo);
      }
    },
    callOut(contactItem, info) {
      if (!this.has_outbound) {
        this.$message.warning('暂无坐席');
        return;
      }
      const _data = {
        id: info.trade_id,
        type: 'deal',
        company: '',
        contact_id: contactItem.id,
        contact_person: contactItem.name,
        phone: contactItem.tel
      };
      this.$bus.$emit('openPhoneCall', _data);
    },
    createMeeting(contactItem, dealInfo, contactList, meetingType) {
      if (!this.has_video_permission) {
        this.$message.warning('暂未开通视频会议功能');
        return;
      }
      this.meetingDialogTitle = '安排在线会议';
      const {
        trade_id,
        customer_name
      } = dealInfo;
      const contactOptions = contactList;
      const {
        id: contactId,
        name: contactName
      } = contactItem;
      this.meetingProps = {
        type: 'business',
        businessId: trade_id,
        customerName: customer_name,
        contactOptions,
        contactId,
        contactName,
        meetingType
      };
      this.meetingDialogVisible = true;
    },
    closeMeetingDialog() {
      this.meetingDialogVisible = false;
      this.init();
    }
  }
};
exports.default = _default;