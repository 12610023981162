"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = _interopRequireDefault(require("@/assets/default.svg"));
var _ImageAvatar = _interopRequireDefault(require("@/components/ImageAvatar"));
var _SelectedLabel = _interopRequireDefault(require("./SelectedLabel.vue"));
var _PicDisplay = _interopRequireDefault(require("@/components/CommentInput/components/PicDisplay.vue"));
var _vuex = require("vuex");
var _EditCommentWidget = _interopRequireDefault(require("./EditCommentWidget.vue"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CommentBox',
  components: {
    ImageAvatar: _ImageAvatar.default,
    SelectedLabel: _SelectedLabel.default,
    PicDisplay: _PicDisplay.default,
    EditCommentWidget: _EditCommentWidget.default
  },
  inject: ['getActiveCommentId'],
  props: {
    comment: {
      type: Object,
      default: () => ({})
    },
    isReply: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    isOnlyDisplay: {
      type: Boolean,
      default: true
    },
    entityType: {
      type: String,
      default: 'conversation'
    },
    hasTimestamp: Boolean
  },
  data() {
    return {
      defaultPhoto: _default2.default,
      popoverVisibles: {},
      //
      isShowDelete: true,
      isEdit: false
    };
  },
  watch: {
    isEdit() {
      this.$nextTick(() => {
        this.$emit('changeEdit', this.isEdit);
      });
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['userId', 'curMediaType']),
    openIcon() {
      switch (this.comment.open_scope) {
        case 'all':
          return 'office-building';
        case 'some':
          return 'user-dbl';
        case 'self':
          return 'lock';
        default:
          return '';
      }
    },
    formatTime() {
      const mediaType = this.comment.media_type || this.curMediaType;
      return mediaType === 'doc' && this.comment.comment_timestamp ? this.$moment(this.comment.comment_timestamp * 1000).format('YYYY-MM-DD HH:mm') : this.realFormatSecond(this.comment.comment_timestamp);
    },
    activeCommentId() {
      const val = this.getActiveCommentId();
      if (val === this.comment.comment_id) {
        this.replyComment();
      }
      return val;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.index === 0 || this.index === 1) {
        this.$emit('getHeight', this.$refs['commentBox'].offsetHeight + 6);
      }
    });
  },
  methods: {
    replyComment() {
      this.$emit('reply', this.comment);
    },
    dropVideo(time, isVideoPlay) {
      this.$bus.$emit('dropVideo', time, isVideoPlay);
    },
    realFormatSecond(second) {
      var secondType = typeof second;
      if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);
        var hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        var mimute = Math.floor(second / 60);
        second = second - mimute * 60;
        if (hours) {
          return ('0' + hours).slice(-2) + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
        } else {
          return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
        }
      } else {
        return '00:00';
      }
    },
    formatCommentContent: _commonFunc.formatCommentContent,
    delComment(comment_id) {
      this.popoverVisibles[comment_id] = false;
      this.$emit('delComment', comment_id);
    },
    handleCommand(command) {
      switch (command) {
        case 'delete':
          this.$confirm('确定删除此评论？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delComment(this.comment.comment_id);
          });
          break;
        case 'edit':
          this.editComment(this.comment.comment_id);
          break;
      }
    },
    editComment(comment_id) {
      this.isEdit = true;
      this.popoverVisibles[comment_id] = false;
      this.$nextTick(() => {
        this.$refs.editCommentWidgetRef.fillComment(this.comment);
      });
    },
    onCommentEditSuccess() {
      this.isEdit = false;
      this.$emit('onCommentEditSuccess');
    }
  }
};
exports.default = _default;