"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "evaluate-form-widget"
  }, [_c(_setup.FormItemHeader, {
    attrs: {
      name: _setup.schema.name,
      description: _setup.schema.description,
      "is-title": ""
    },
    on: {
      "update:name": function ($event) {
        return _vm.$set(_setup.schema, "name", $event);
      },
      "update:description": function ($event) {
        return _vm.$set(_setup.schema, "description", $event);
      }
    }
  }), _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _setup.model
    }
  }, _vm._l(_setup.schema.fields, function (formItem, index) {
    return _c("div", {
      key: formItem.id,
      staticClass: "form-item-container"
    }, [_c(_setup.FormItemHeader, {
      attrs: {
        name: formItem.name,
        description: formItem.prompt,
        required: !!formItem.required,
        index: index + 1
      },
      on: {
        "update:name": function ($event) {
          return _vm.$set(formItem, "name", $event);
        },
        "update:description": function ($event) {
          return _vm.$set(formItem, "prompt", $event);
        }
      }
    }), _c("el-form-item", {
      attrs: {
        rules: _setup.rules[formItem.id],
        prop: formItem.id + ""
      }
    }, [_c(_setup.WidgetFormItem, {
      attrs: {
        "field-type": formItem.field_type,
        options: formItem.options,
        "option-ids": formItem.option_ids,
        "cascader-ids": formItem.cascader_ids
      },
      on: {
        optionFieldChange: function ($event) {
          return _setup.handleOptionFieldChange($event, formItem);
        },
        cascaderFieldChange: function ($event) {
          return _setup.handleCascaderFieldChange($event, formItem);
        }
      },
      model: {
        value: _setup.model[formItem.id],
        callback: function ($$v) {
          _vm.$set(_setup.model, formItem.id, $$v);
        },
        expression: "model[formItem.id]"
      }
    })], 1)], 1);
  }), 0)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;