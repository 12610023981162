"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _xlsx = _interopRequireDefault(require("xlsx"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FunnelTable',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    exportXlsx() {
      const arr = this.data.map(item => Object.values(item));
      arr.unshift(['交易阶段', '交易数', '交易金额', '预测赢单率', '预测金额']);
      arr.unshift(['交易漏斗预测']);
      const worksheet = _xlsx.default.utils.aoa_to_sheet(arr);
      const workbook = {
        SheetNames: ['funnel'],
        Sheets: {
          funnel: worksheet
        }
      };
      const wpts = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      };
      const workbookOut = _xlsx.default.write(workbook, wpts);
      const blob = new Blob([s2ab(workbookOut)], {
        type: 'application/octet-stream'
      });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      (0, _download.downloadBlob)(blob, `交易漏斗预测.xlsx`);
    }
  }
};
exports.default = _default;