"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _businessIntelligence = require("@/api/businessIntelligence");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function (nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}
var _default = {
  name: 'BusinessPathChart',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    chartLoading: {
      type: Boolean,
      default: false
    },
    nodePathParams: {
      type: Object,
      default: () => ({})
    },
    isPreset: {
      type: Boolean,
      default: false
    },
    treeNodeIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null,
      childData: {},
      options: {
        toolbox: {
          showTitle: false,
          right: 48,
          top: 14,
          itemSize: 12,
          // 设置图标大小
          iconStyle: {
            color: '#576575',
            borderWidth: 0
          },
          emphasis: {
            iconStyle: {
              color: '#4461ec' // 图标hover颜色
            }
          },

          feature: {
            restore: {
              show: this.isPreset,
              title: '复原',
              icon: 'M772 795.008q-87.008 78.016-197.504 96T357.984 864q-106.016-48-167.488-142.016T128 511.968h64q0.992 100.992 55.488 180.992t148.512 116.992q94.016 36 188.512 14.016t163.488-96h-92.992q-14.016 0-23.008-8.992t-8.992-22.496 8.992-22.496 23.008-10.016h148.992q12.992 0.992 22.016 10.016t10.016 22.016v148.992q-0.992 14.016-10.016 23.008t-22.496 8.992-22.496-8.992-8.992-23.008v-50.016zM276 296h92.992q14.016 0 23.008 8.992t8.992 22.496-8.992 22.496-23.008 10.016H220q-12.992-0.992-22.016-10.016t-10.016-22.016V178.976q0.992-14.016 10.016-23.008t22.496-8.992 22.496 8.992 8.992 23.008v50.016q87.008-78.016 197.504-96T666.976 160q106.016 48 167.008 142.016T896 512.032h-64q-0.992-100.992-55.488-180.992t-148.512-116.992q-94.016-36-188.512-14.016t-163.488 96z'
            }
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            position: 'top',
            appendToBody: true,
            formatter: function (param) {
              return `<div class="business-path-toolbox-tooltip">${param.title}</div>`;
            },
            backgroundColor: 'rgba(55, 68, 111, 0.8)',
            borderRadius: 4,
            borderWidth: 0,
            textStyle: {
              fontSize: 10
            }
          }
        },
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          triggerOn: 'mousemove',
          textStyle: {
            height: 66,
            color: '#576575;',
            fontSize: 14,
            lineHeight: 20
          },
          borderColor: '#fff',
          formatter: param => {
            let tooltip;
            if (param.dataIndex === 1) {
              tooltip = `实际业务路径`;
            } else {
              var _param$data;
              const step = (_param$data = param.data) === null || _param$data === void 0 ? void 0 : _param$data.node_path.length;
              const title = `<div> 第 ${step} 步 </div>`;
              const ratioToPercent = this.getKeyEventProportion(param.data.ratio);
              // 节点的路径是唯一的,name后面拼接路径,可以保证每个节点的名字唯一,动画不会错乱,展示时删除路径
              const labelName = this.formatLabel(param.name);
              const text = `${labelName}: ${ratioToPercent}`;
              tooltip = title + text;
            }
            return tooltip;
          }
        },
        series: [{
          type: 'tree',
          data: [this.data],
          top: '7%',
          left: '10%',
          bottom: '1%',
          right: '10%',
          symbolSize: 7,
          // 节点大小
          roam: true,
          // 移动+缩放
          // roam: scale, // 缩放
          // roam: 'move', // 移动
          scaleLimit: {
            // 缩放比例
            min: 0.7,
            // 最小的缩放值
            max: 4 // 最大的缩放值
          },

          label: {
            position: 'bottom',
            distance: 6,
            fontSize: 12,
            color: '#333333',
            formatter: param => {
              let name;
              if (param.dataIndex === 1) {
                name = [param.name].join('');
              } else {
                const ratioToPercent = this.getKeyEventProportion(param.data.ratio);
                // 节点的路径是唯一的,name后面拼接路径,可以保证每个节点的名字唯一,动画不会错乱,展示时删除路径
                const labelName = this.formatLabel(param.name);
                name = [labelName, ratioToPercent].join('');
              }
              return name;
            }
          },
          leaves: {
            label: {
              position: 'bottom',
              distance: 6,
              fontSize: 12
            }
          },
          lineStyle: {
            // 该节点对应的边的样式。
            width: 1.5,
            curveness: 0.5 // 树图边的曲度
          },

          emphasis: {
            focus: 'ancestor' // ancestor聚焦所有祖先节点'descendant' 聚焦所有子孙节点
          },

          // labelLayout: {
          //   hideOverlap: true // 是否隐藏重叠的标签
          // },
          initialTreeDepth: -1,
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750
        }]
      }
    };
  },
  watch: {
    data: {
      handler(val) {
        if (this.isPreset) {
          this.setPresetPathStyle();
        } else {
          // 判断子节点是否可以展开 && '>5'的项目合并为'其他' && 计算'其他'的比例
          this.treeCollapsed(val);
          // 高亮 Top 路径
          this.highlightProportion(val);
        }
        this.$nextTick(() => {
          this.options.series[0].data = [val];
          this.initChart();
        });
      },
      immediate: true
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.chartResize);
    window.addEventListener('beforeunload', this.clearChart);
  },
  beforeDestroy() {
    this.clearChart();
    window.removeEventListener('resize', this.chartResize);
    window.removeEventListener('beforeunload', this.clearChart);
  },
  methods: {
    getKeyEventProportion(val) {
      if (val > 0.001 || val === 0) {
        return (val * 100).toFixed(2) + '%';
      } else if (val > 0.000001) {
        return (val * 100).toFixed(4) + '%';
      } else {
        return 0.0001 + '%';
      }
    },
    // 根据接口获取数据
    async fetchChildData(nodePath) {
      // eslint-disable-next-line vue/no-mutating-props
      this.nodePathParams.node_path = nodePath;
      const res = await (0, _businessIntelligence.getBusinessPathChart)(this.nodePathParams);
      if (res.code === 200) {
        this.childData = JSON.parse(JSON.stringify(res.results));
      }
    },
    // 初始化 Chart
    initChart() {
      if (this.data) {
        this.clearChart();
        this.chart = echarts.init(this.$refs.chart);
        // 获取容器的dom并初始化图表
        this.chart.setOption(this.options);
        this.chart.on('click', this.handleClick);
      }
    },
    // 节点点击(分为点击子节点与'其他'两种情况)
    formatLabel(name) {
      return name.replace(/-|\d*/g, '');
    },
    handleClick(params) {
      params.data.collapsed = !params.data.collapsed;
      // 预设业务路径只有一条直接返回即可
      if (this.isPreset) return;
      // 区分是否是其他项
      // 节点的路径是唯一的,name后面拼接路径,可以保证每个节点的名字唯一,动画不会错乱,展示时删除路径
      const labelName = this.formatLabel(params.name);
      // 如果节点可以展开&&不是‘其他’项
      if (params.data.is_extensible && !params.data.children && labelName !== '其他') {
        // 展开当前节点的子节点
        this.expandChildNodes(params);
      }
      // 点击'其他'节点，根据 node_path，找到该节点的父级，并获取该父级的子节点并替换'其他'节点
      if (labelName === '其他') {
        this.expandOthersNodes(params);
      }
    },
    // 点击可展开的&&非'其他'子节点动态获取子节点数据
    async expandChildNodes(currentNode) {
      // 获取子节点数据
      await this.fetchChildData(currentNode.data.node_path);
      // 再次判断子节点是否可以展开&&'>5'的项目合并为'其他'&&并设置label
      this.treeCollapsed(this.childData);
      // 添加当前节点的子节点
      currentNode.data.children = this.childData.children;
      currentNode.data.symbol = 'emptyCircle';
      // 获取树数据
      const treeData = this.chart.getOption().series[0].data;
      this.options.series[0].data = treeData;
      this.chart.setOption(this.options);
    },
    // 点击'其他'节点，根据 node_path，找到该节点的父级，并获取该父级的子节点并替换'其他'节点
    async expandOthersNodes(currentNode) {
      // 获取树的对象数据
      const treeData = this.chart.getOption().series[0].data[0];
      const pathLength = currentNode.data.node_path.length;
      // 点击第二层的时候，直接加在treeData.children
      if (pathLength === 1) {
        this.handleOtherNodes(treeData, currentNode);
      } else {
        const nodePath = currentNode.data.node_path.slice(0, pathLength - 1);
        // 获取父节点的路径 node_path
        const parentNodePath = nodePath.join('');
        // 获取该父级的子节点并替换'其他'节点
        this.getParentNode(treeData.children, parentNodePath, currentNode);
      }
      this.options.series[0].data = [treeData];
      this.chart.setOption(this.options);
    },
    // 根据node_path获取该父级的子节点并替换'其他'节点
    getParentNode(list, node_path, currentNode) {
      for (const parentNode of list) {
        // 找到父节点
        if (parentNode.node_path.join('') === node_path) {
          // 删除'其他'项,展开隐藏的兄弟节点
          this.handleOtherNodes(parentNode, currentNode);
        } else if (Object.prototype.hasOwnProperty.call(parentNode, 'children') && parentNode.children !== undefined) {
          this.getParentNode(parentNode.children, node_path, currentNode);
        }
      }
    },
    // 处理展开'其他'项时的兄弟节点
    handleOtherNodes(data, currentNode) {
      const otherNodes = currentNode.data.otherNodes;
      // 判断展开的兄弟节点是否能再次展开
      otherNodes.forEach(item => {
        this.nodeIsExtensible(item);
      });
      data.children.pop();
      data.children.push(...otherNodes);
    },
    // 判断子节点是否可以展开&&'>5'的项目合并为'其他'&&并设置label
    treeCollapsed(item) {
      // 每一步骤默认展开前五项
      if (Object.prototype.hasOwnProperty.call(item, 'children')) {
        item.children.forEach((child, index) => {
          if (!Object.prototype.hasOwnProperty.call(child, 'children')) {
            // 有子节点默认展开，判断其余项即可
            this.nodeIsExtensible(child);
          }
          // 节点的node_path是唯一的
          const nodePath = child.node_path.join('');
          child.name += nodePath;
          this.treeCollapsed(child);
        });
        let otherNodes = [];
        // 节点大于5的项合并为'其他项'
        if (item.children.length > 5) {
          // 如果其他项有 children || 其他项节点路径 === treeNodeIds,即其他项存在top路径
          const otherHasPathHasTop = item.children.slice(5).some(child => {
            return child.node_path.toString() === this.treeNodeIds.toString() || Object.prototype.hasOwnProperty.call(child, 'children');
          });
          // 其他项存在top路径,把top路径放在第六项，并折叠大于6的项为其他项，否则折叠大于5的项为其他项
          if (otherHasPathHasTop) {
            item.children.slice(5).forEach((child, index) => {
              if (child.node_path.toString() === this.treeNodeIds.toString() || Object.prototype.hasOwnProperty.call(child, 'children')) {
                const topPathNode = item.children.splice(5 + index, 1);
                item.children.splice(5, 0, topPathNode[0]);
              }
            });
            otherNodes = item.children.splice(6, item.children.length - 6);
          } else {
            otherNodes = item.children.splice(5, item.children.length - 5);
          }
          // '其他项'占比为大于5的项之和
          // reduce 求'其他项'占比
          const otherRatio = otherNodes.reduce((total, item) => {
            return total + item.ratio;
          }, 0);
          let otherName = '其他';
          // 节点的路径是唯一的,name后面拼接路径,可以保证每个节点的名字唯一,动画不会错乱
          const nodePathArr = otherNodes[0].node_path;
          const nodePath = nodePathArr.join('');
          otherName += nodePath;
          item.children.push({
            ...otherNodes[0],
            name: otherName,
            node_path: nodePathArr,
            symbol: 'circle',
            ratio: otherRatio,
            otherNodes
          });
        }
      }
    },
    // 判断节点是否能够展开(是否有子节点)(展开'其他'的时候,只需要判断节点是否可以展开即可)
    nodeIsExtensible(node) {
      if (node.is_extensible) {
        // 需要请求接口加载数据
        node.symbol = 'circle';
        node.collapsed = true;
      }
    },
    // 高亮每一层级的top项
    highlightProportion(item) {
      // 高亮每一层的top项
      if (Object.prototype.hasOwnProperty.call(item, 'children')) {
        // top项
        item.children.forEach(child => {
          if (Object.prototype.hasOwnProperty.call(child, 'children')) {
            this.setTopPathStyle(child);
            this.highlightProportion(child);
          } else if (JSON.stringify(child.node_path) === JSON.stringify(this.treeNodeIds)) {
            this.setTopPathStyle(child);
          }
        });
      }
    },
    setTopPathStyle(child) {
      child.lineStyle = {
        color: '#3393FF'
      };
      child.label = {
        formatter: this.setLabelFormatter,
        rich: {
          a: {
            width: 16,
            height: 16,
            borderRadius: 8,
            align: 'center',
            fontSize: 10,
            backgroundColor: '#3393FF',
            color: '#FFFFFF'
          }
        }
      };
    },
    setPresetPathStyle() {
      this.options.series[0].lineStyle = {
        color: '#33CC8C'
      };
      this.options.series[0].label = {
        position: 'bottom',
        distance: 6,
        fontSize: 12,
        color: '#333333',
        formatter: this.setLabelFormatter,
        rich: {
          a: {
            width: 16,
            height: 16,
            lineHeight: 16,
            borderRadius: 8,
            fontSize: 10,
            align: 'center',
            backgroundColor: '#33CC8C',
            color: '#FFFFFF'
          }
        }
      };
      this.options.series[0].leaves = {
        position: 'right',
        distance: 6
      };
    },
    // 设置 label的 formatter 设置
    setLabelFormatter(param) {
      let name;
      if (param.dataIndex === 1) {
        name = [param.name].join('');
      } else {
        const step = param.data.node_path.length;
        const ratioToPercent = this.getKeyEventProportion(param.data.ratio);
        // 节点的路径是唯一的,name后面拼接路径,可以保证每个节点的名字唯一,动画不会错乱,展示时删除路径
        const labelName = this.formatLabel(param.name);
        name = [`{a|${step}}`, ' ', labelName, ratioToPercent].join('');
      }
      return name;
    },
    // 窗口大小变化时resize chart
    chartResize() {
      setTimeout(() => {
        this.chart.resize();
      }, 0);
    },
    clearChart() {
      var _this$chart;
      // 销毁实例，实例销毁后无法再被使用
      (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : _this$chart.dispose();
    }
  }
};
exports.default = _default;