"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "konwledge-base-config",
    attrs: {
      title: _vm.isCreate ? "新建知识库" : "重命名",
      visible: _vm.visible,
      "close-on-click-modal": false,
      width: "480px",
      "before-close": _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-form", {
    ref: "configForm",
    attrs: {
      model: _vm.knowledgeBaseConfig
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "知识库名称",
      prop: "name",
      rules: [{
        required: true,
        message: "知识库名称不能为空"
      }]
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入名称",
      maxlength: "20"
    },
    model: {
      value: _vm.knowledgeBaseConfig.name,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeBaseConfig, "name", $$v);
      },
      expression: "knowledgeBaseConfig.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "简介"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入简介",
      maxlength: "50"
    },
    model: {
      value: _vm.knowledgeBaseConfig.profile,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeBaseConfig, "profile", $$v);
      },
      expression: "knowledgeBaseConfig.profile"
    }
  })], 1), _vm.isCreate ? _c("el-form-item", {
    staticClass: "column-form",
    attrs: {
      label: "公开范围"
    }
  }, [_c("div", {
    staticClass: "radio-group"
  }, [_c("el-radio", {
    attrs: {
      label: 0
    },
    model: {
      value: _vm.knowledgeBaseConfig.public_scope,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeBaseConfig, "public_scope", $$v);
      },
      expression: "knowledgeBaseConfig.public_scope"
    }
  }, [_vm._v("仅知识库成员可见")]), _c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.knowledgeBaseConfig.public_scope,
      callback: function ($$v) {
        _vm.$set(_vm.knowledgeBaseConfig, "public_scope", $$v);
      },
      expression: "knowledgeBaseConfig.public_scope"
    }
  }, [_vm._v("全公司可见")])], 1)]) : _vm._e()], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;