"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'RapidModelingHeader',
  setup(__props) {
    const modelInfo = (0, _vue.inject)('modelInfo');
    return {
      __sfc: true,
      modelInfo
    };
  }
};
exports.default = _default;