"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SwitchViewButton',
  props: {
    viewType: {
      type: String,
      default: 'card'
    }
  },
  methods: {
    selectView(type) {
      this.$emit('selectView', type);
    }
  }
};
exports.default = _default;