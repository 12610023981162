"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.associateContactDialogVisible,
      "before-close": _vm.closeDialog,
      title: "关联联系人",
      "custom-class": "associate-contact-dialog",
      "modal-append-to-body": false
    }
  }, [_c("associate-contact-form", {
    ref: "associateContactForm",
    attrs: {
      contactList: _vm.contactList
    }
  }), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.saveLoading
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;