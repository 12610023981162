"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardTextMainData = _interopRequireDefault(require("@/components/Charts/CardTextMainData.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ReportDataTable',
  components: {
    CardTextMainData: _CardTextMainData.default
  },
  props: {
    renderInfo: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  computed: {
    warningTotal() {
      const warningData = this.renderInfo.find(item => item.type === 'warning');
      return warningData === null || warningData === void 0 ? void 0 : warningData.content;
    },
    digitDataList() {
      const digitData = this.renderInfo.filter(item => item.type === 'Digit');
      return digitData || [];
    }
  }
};
exports.default = _default;