"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DetailNavbar = _interopRequireDefault(require("@/layout/components/DetailNavbar"));
var _KnowledgeScoreCorrectionAside = _interopRequireDefault(require("./components/KnowledgeScoreCorrectionAside"));
var _KnowledgeScoreTipsDialog = _interopRequireDefault(require("./components/KnowledgeScoreTipsDialog"));
var _businessIntelligence = require("@/api/businessIntelligence");
var _KnowledgeScoreCorrectionRightPart = _interopRequireDefault(require("./components/KnowledgeScoreCorrectionRightPart"));
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'KnowledgeScoreCorrection',
  components: {
    Navbar: _DetailNavbar.default,
    KnowledgeScoreCorrectionAside: _KnowledgeScoreCorrectionAside.default,
    KnowledgeScoreTipsDialog: _KnowledgeScoreTipsDialog.default,
    KnowledgeScoreCorrectionRightPart: _KnowledgeScoreCorrectionRightPart.default
  },
  data() {
    return {
      knowledgeScoreTipsDialogVisible: false,
      allKnowledgeList: [],
      activeTabKnowledgeId: '',
      scoreCorrectionContentList: [],
      loading: false,
      rightPartLoading: false,
      isFirstEntryScoreCorrectionPage: false
    };
  },
  computed: {
    activeTabName() {
      return this.recursionGet({
        recurArr: this.allKnowledgeList,
        targetValue: Number(this.activeTabKnowledgeId),
        targetAttr: 'id',
        getAttr: 'name'
      });
    }
  },
  watch: {
    activeTabKnowledgeId: {
      handler: function (val) {
        this.getScoreCorrectionTemplateConv(val);
      }
    }
  },
  async created() {
    var _this$allKnowledgeLis, _this$allKnowledgeLis2;
    this.isFirstEntryScoreCorrectionPage = localStorage.getItem('isFirstEntryScoreCorrectionPage');
    if (!this.isFirstEntryScoreCorrectionPage) {
      this.knowledgeScoreTipsDialogVisible = true;
    }
    await this.getScoreCorrectionKnowledgeList();
    this.activeTabKnowledgeId = this.allKnowledgeList.length && ((_this$allKnowledgeLis = this.allKnowledgeList[0]) === null || _this$allKnowledgeLis === void 0 ? void 0 : (_this$allKnowledgeLis2 = _this$allKnowledgeLis.children[0]) === null || _this$allKnowledgeLis2 === void 0 ? void 0 : _this$allKnowledgeLis2.id);
  },
  methods: {
    recursionGet: _commonFunc.recursionGet,
    correctScoreSuccess() {
      this.recursionSet({
        recurArr: this.allKnowledgeList,
        targetValue: Number(this.activeTabKnowledgeId),
        targetAttr: 'id',
        setAttr: 'is_save',
        setValue: true
      });
    },
    async getScoreCorrectionTemplateConv(knowledge_id) {
      !this.loading && (this.rightPartLoading = true);
      const res = await (0, _businessIntelligence.getScoreCorrectionTemplateConv)(knowledge_id);
      if (res.code === 200) {
        this.scoreCorrectionContentList = res.results;
      }
      this.rightPartLoading = false;
      this.loading = false;
    },
    recursionSet(_ref) {
      let {
        recurArr = [],
        targetValue,
        targetAttr,
        setAttr,
        setValue
      } = _ref;
      for (let index = 0; index < recurArr.length; index++) {
        if (recurArr[index][targetAttr] === targetValue) {
          recurArr[index][setAttr] = setValue;
          break;
        }
        if (recurArr[index].children instanceof Array && recurArr[index].children.length > 0) {
          this.recursionSet({
            recurArr: recurArr[index].children,
            targetValue,
            targetAttr,
            setAttr,
            setValue
          });
        }
      }
    },
    async getScoreCorrectionKnowledgeList() {
      this.loading = true;
      const res = await (0, _businessIntelligence.getScoreCorrectionKnowledgeList)();
      if (res.code === 200) {
        this.allKnowledgeList = res.results;
      }
    },
    activeTabsChange(key) {
      this.activeTabKnowledgeId = key;
    },
    closeKnowledgeScoreTipsDialog() {
      this.knowledgeScoreTipsDialogVisible = false;
      localStorage.setItem('isFirstEntryScoreCorrectionPage', true);
      this.isFirstEntryScoreCorrectionPage = true;
    }
  }
};
exports.default = _default;