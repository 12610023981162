"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @reference https://editorjs.io/internationalization
 */
var _default = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': '点击调整'
        }
      },
      inlineToolbar: {
        converter: {
          'Convert to': '转换至'
        }
      },
      toolbar: {
        toolbox: {
          Add: '添加'
        }
      }
    },
    tools: {
      list: {
        Ordered: '有序列表',
        Unordered: '无序列表'
      },
      link: {
        'Add a link': '添加一个链接'
      }
    },
    toolNames: {
      Text: '正文',
      Heading: '标题',
      List: '列表',
      Link: '链接',
      Bold: '粗体',
      Italic: '斜体'
    },
    blockTunes: {
      delete: {
        Delete: '删除'
      },
      moveUp: {
        'Move up': '向上移动'
      },
      moveDown: {
        'Move down': '向下移动'
      }
    }
  }
};
exports.default = _default;