"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchTransferConversation = batchTransferConversation;
exports.bookMeeting = bookMeeting;
exports.checkTransfer = checkTransfer;
exports.conversationBindContact = conversationBindContact;
exports.correctConvText = correctConvText;
exports.createAutomationProcessRule = createAutomationProcessRule;
exports.createExtractInfoInstruction = createExtractInfoInstruction;
exports.deleteAutomationProcessRule = deleteAutomationProcessRule;
exports.deleteConv = deleteConv;
exports.deleteExtractInfoInstruction = deleteExtractInfoInstruction;
exports.deleteFilter = deleteFilter;
exports.editConversation = editConversation;
exports.getAccountOrDeal = getAccountOrDeal;
exports.getAllKeyEvent = getAllKeyEvent;
exports.getAllList = getAllList;
exports.getAutomationProcessRuleList = getAutomationProcessRuleList;
exports.getCompleteTime = getCompleteTime;
exports.getConversationList = getConversationList;
exports.getConversationListCount = getConversationListCount;
exports.getConversationListFilter = getConversationListFilter;
exports.getConversationListTableHead = getConversationListTableHead;
exports.getConversationScoreRuleList = getConversationScoreRuleList;
exports.getConversationSelectCustomField = getConversationSelectCustomField;
exports.getCorrectTextHistory = getCorrectTextHistory;
exports.getEchartsData = getEchartsData;
exports.getExtractInfoInstructionList = getExtractInfoInstructionList;
exports.getFilters = getFilters;
exports.getHostPersonOrTeam = getHostPersonOrTeam;
exports.getKeyEvent = getKeyEvent;
exports.getMyList = getMyList;
exports.getScoreRuleDetail = getScoreRuleDetail;
exports.getScoreRuleList = getScoreRuleList;
exports.getStageOptions = getStageOptions;
exports.getTodoList = getTodoList;
exports.getTopic = getTopic;
exports.postMedia = postMedia;
exports.postMediaForm = postMediaForm;
exports.postSaveFilter = postSaveFilter;
exports.renameFilter = renameFilter;
exports.updateAutomationProcessRule = updateAutomationProcessRule;
exports.updateConvTime = updateConvTime;
exports.updateExtractInfoInstruction = updateExtractInfoInstruction;
exports.uploadConversation = uploadConversation;
exports.uploadMedia = uploadMedia;
var _request = require("@/utils/request");
var _axios = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function getMyList(params) {
  return (0, _request.request)({
    url: '/conversation/conversation/my',
    method: 'get',
    params
  });
}
function getAllList(params) {
  return (0, _request.request)({
    url: '/conversation/conversation/all',
    method: 'get',
    params
  });
}

// 获取筛选项中主持人
function getHostPersonOrTeam(params) {
  return (0, _request.request)({
    url: '/profile/organization_tree_or_user',
    method: 'get',
    params
  });
}

// 获取筛选项中公司名
function getAccountOrDeal(params) {
  return (0, _request.request)({
    url: '/conversation/conversation/search',
    method: 'get',
    params
  });
}

// 获取事件跟踪器
function getAllKeyEvent(params) {
  return (0, _request.webService)({
    url: '/conversation/all_events',
    method: 'get',
    params
  });
}

// 获取事件跟踪器
function getKeyEvent() {
  return (0, _request.request)({
    url: '/conversation/conversation/events',
    method: 'get'
  });
}

// 获取商机阶段
function getStageOptions(params) {
  return (0, _request.request)({
    url: '/deal/deal/get_status_options',
    method: 'get',
    params
  });
}

// 获取列表页上部的echarts数据
function getEchartsData(data, config) {
  return (0, _request.request)({
    url: '/conversation/conversation/all_by_time',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

// 根据所有参数获取列表信息
function getConversationList(data, config) {
  return (0, _request.request)({
    url: '/conversation/conversation/list',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}
function getTodoList() {
  return (0, _request.request)({
    url: '/conversation/conversation/todo',
    method: 'get'
  });
}
function updateConvTime(conversation_id, date) {
  return (0, _request.request)({
    url: '/conversation/conversation/update_time',
    method: 'post',
    data: {
      conversation_id,
      date
    }
  });
}
// 取消预约
function deleteConv(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/conversation/delete',
    method: 'post',
    data: {
      conversation_id
    }
  });
}
function getCompleteTime(conversation_id) {
  return (0, _request.request)({
    url: '/conversation/conversation/complete_time',
    method: 'get',
    params: {
      conversation_id
    }
  });
}

// 保存筛选器
function postSaveFilter(name, filters, workspace_id) {
  return (0, _request.webService)({
    url: '/conversation/filter/add',
    method: 'post',
    dataType: 'json',
    data: {
      name,
      filters,
      workspace_id
    }
  });
}

// 筛选器的列表
function getFilters(params) {
  return (0, _request.webService)({
    url: '/conversation/filter/get',
    method: 'get',
    params
  });
}

// 删除筛选器
function deleteFilter(params) {
  return (0, _request.request)({
    url: '/conversation/filter/delete',
    method: 'post',
    params
  });
}

// 重命名筛选器
function renameFilter(data) {
  return (0, _request.request)({
    url: '/conversation/filter/rename',
    method: 'post',
    data
  });
}

// 话题
function getTopic(params) {
  return (0, _request.request)({
    url: '/conversation/detail/detail/topic',
    method: 'get',
    params
  });
}

// 上传音视频
function uploadMedia(data, config) {
  return (0, _request.request)({
    url: '/conversation/conversation/upload',
    method: 'post',
    timeout: 0,
    data,
    ...config
  });
}

// 上传音视频文件
function postMedia(data, cb, that) {
  if (_store.default.getters.token) {
    // 如果
    const {
      orgId,
      userId
    } = _store.default.getters;
    const token = (0, _auth.getToken)();
    const apiMap = {
      snippet: '/api/library/snippet/upload',
      conversation: '/api/conversation/conversation/upload'
    };
    const url = apiMap[that.sourceType];
    const axiosConfig = {
      url,
      method: 'post',
      data,
      headers: {
        'X-Org-Id': orgId,
        'X-User-Id': userId,
        'X-Token': token
      }
    };
    axiosConfig.cancelToken = new _axios.default.CancelToken(cancel => {
      that.axiosCancel.push({
        cancel
      });
    });
    if (cb instanceof Object) {
      for (const key in cb) {
        axiosConfig[key] = cb[key];
      }
    }
    return (0, _axios.default)(axiosConfig);
  } else {
    return;
  }
}

// 上传音视频关联表单
function postMediaForm(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_by_file',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 转录文字修正
function correctConvText(data) {
  return (0, _request.request)({
    url: '/conversation/detail/correction/edit',
    method: 'POST',
    dataType: 'json',
    data
  });
}

// 转录文字修正历史
function getCorrectTextHistory(params) {
  return (0, _request.webService)({
    url: '/conversation/detail/correction/list',
    method: 'GET',
    params
  });
}

// 上传会话
function uploadConversation(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/upload_conversation',
    method: 'post',
    data
  });
}

// 预约会议
function bookMeeting(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/create_conversation',
    method: 'post',
    data
  });
}

// 编辑会话
function editConversation(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/edit_conversation',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 会话关联联系人
function conversationBindContact(data) {
  return (0, _request.request)({
    url: '/contact/contact/bind_contact',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 校验是否可以批量转移会话
function checkTransfer() {
  return (0, _request.webService)({
    url: '/conversation/detail/workspace/trans_check',
    method: 'get'
  });
}

// 批量转移筛选出的会话到指定空间
function batchTransferConversation(data) {
  return (0, _request.request)({
    url: '/conversation/conversation/list/workspace/transfer/batch',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获得会话列表筛选的数量接口
function getConversationListCount(data, config) {
  return (0, _request.request)({
    url: '/conversation/conversation/list/count',
    method: 'post',
    dataType: 'json',
    data,
    ...config
  });
}

// 获取评分规则列表
function getScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/visible_list',
    method: 'get',
    params
  });
}

// 获取评分规则详情
function getScoreRuleDetail(params) {
  return (0, _request.webService)({
    url: '/conversation/score_rule/score_result',
    method: 'get',
    params
  });
}

// 获取会话列表页面筛选项
function getConversationListFilter(params) {
  return (0, _request.webService)({
    url: '/conversation/filter/search_list',
    method: 'get',
    params
  });
}

// 获取会话列表页面列表视图表头
function getConversationListTableHead(params) {
  return (0, _request.webService)({
    url: '/conversation/head/get',
    method: 'get',
    params
  });
}

/**  信息提取相关接口 */
// 获取信息提取指令列表
function getExtractInfoInstructionList(params) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set/list',
    method: 'get',
    params
  });
}

// 创建信息提取指令
function createExtractInfoInstruction(data) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑信息提取指令
function updateExtractInfoInstruction(data) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除信息提取指令
function deleteExtractInfoInstruction(data) {
  return (0, _request.webService)({
    url: '/conversation/instruction_set/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 自动化流程相关接口
// 获取自动化流程列表
function getAutomationProcessRuleList(params) {
  return (0, _request.webService)({
    url: '/automation/rule/list',
    method: 'get',
    params
  });
}

// 创建自动化流程
function createAutomationProcessRule(data) {
  return (0, _request.webService)({
    url: '/automation/rule/create',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 编辑自动化流程
function updateAutomationProcessRule(data) {
  return (0, _request.webService)({
    url: '/automation/rule/update',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 删除自动化流程
function deleteAutomationProcessRule(data) {
  return (0, _request.webService)({
    url: '/automation/rule/delete',
    method: 'post',
    dataType: 'json',
    data
  });
}

// 获得开启的会话评分规则列表接口
function getConversationScoreRuleList(params) {
  return (0, _request.webService)({
    url: '/automation/score_rule/list',
    method: 'get',
    params
  });
}

// 获得单选的会话自定义字段
function getConversationSelectCustomField(params) {
  return (0, _request.webService)({
    url: '/automation/select_field/list',
    method: 'get',
    params
  });
}