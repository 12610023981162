"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-table", {
    staticClass: "task-table",
    attrs: {
      data: _vm.tableData
    }
  }, _vm._l(_vm.tableHeads, function (_ref) {
    let {
      prop,
      label,
      width
    } = _ref;
    return _c("el-table-column", {
      key: prop,
      attrs: {
        prop: prop,
        label: label,
        width: width,
        fit: "",
        "class-name": prop === "operate" ? "operate-table-head" : ""
      },
      scopedSlots: _vm._u([prop === "name" ? {
        key: "default",
        fn: function (_ref2) {
          let {
            row
          } = _ref2;
          return [_c("div", {
            staticClass: "task-info"
          }, [_c("i", {
            class: _setup.getIcon(row.type)
          }), _c("el-tooltip", {
            directives: [{
              name: "mg-tooltip-auto-show",
              rawName: "v-mg-tooltip-auto-show"
            }],
            attrs: {
              placement: "top",
              effect: "dark",
              content: row.name
            }
          }, [_c("div", {
            staticClass: "task-name",
            on: {
              click: function ($event) {
                return _setup.handleCommand("", row);
              }
            }
          }, [_vm._v(" " + _vm._s(row.name) + " ")])])], 1)];
        }
      } : prop === "assigned_user.name" ? {
        key: "default",
        fn: function (_ref3) {
          let {
            row
          } = _ref3;
          return [_c("div", {
            staticClass: "executor-info"
          }, [_c("MgvAvatarName", {
            attrs: {
              name: row.assigned_user.name,
              avatar: row.assigned_user.avatar,
              size: 24
            }
          }), _c("span", [_vm._v(_vm._s(row.assigned_user.name))])], 1)];
        }
      } : prop === "study_time" ? {
        key: "default",
        fn: function (_ref4) {
          let {
            row
          } = _ref4;
          return [_c("span", [_vm._v(_vm._s(_setup.secondToCNTime(row.study_time, ["小时", "分钟", "秒"])))])];
        }
      } : prop === "operate" ? {
        key: "default",
        fn: function (_ref5) {
          let {
            row
          } = _ref5;
          return [_c(_setup.MgOperateColumn, {
            attrs: {
              list: _setup.operateList
            },
            on: {
              command: function ($event) {
                return _setup.handleCommand($event, row);
              }
            }
          })];
        }
      } : null], null, true)
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;