"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-info"
  }, [_vm.isDepartment ? _c("mgv-avatar-name", {
    staticClass: "department-icon",
    attrs: {
      icon: "iconfont icon-department",
      size: 40
    }
  }) : _c("mgv-avatar-name", {
    staticClass: "upper-avatar",
    attrs: {
      name: _vm.transferFromUserInfo.name,
      src: _vm.transferFromUserInfo.avatar,
      size: 40
    }
  }), _c("div", {
    staticClass: "user-department-info"
  }, [_c("div", {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.transferFromUserInfo.name))]), !_vm.isDepartment && _vm.transferFromUserInfo.department.length ? _c("div", [_c("div", {
    staticClass: "department"
  }, [_vm._v(" " + _vm._s(_vm.mainDepartmentName) + " "), _vm.mainDepartmentName ? _c("span", {
    staticClass: "main-department"
  }, [_vm._v(_vm._s(_vm.$t("management.主部门")))]) : _vm._e(), _vm._l(_vm.otherDepartmentName, function (item, index) {
    return _c("span", {
      key: index
    }, [index || !index && _vm.mainDepartmentName ? _c("span", [_vm._v("、")]) : _vm._e(), _vm._v(_vm._s(item))]);
  })], 2)]) : _vm.isDepartment && _vm.transferFromUserInfo.super_fid_names.length ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.transferFromUserInfo.super_fid_names.join("/"),
      placement: "top",
      disabled: !_vm.isShowTooltip
    }
  }, [_c("div", {
    staticClass: "parent-department",
    on: {
      mouseenter: function ($event) {
        return _vm.tooltipVisibilityChange($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.transferFromUserInfo.super_fid_names.join("/")) + " ")])]) : _c("div", {
    staticClass: "department"
  }, [_vm._v(_vm._s(_vm.$t("management.未分配")))])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;