"use strict";

var _router = _interopRequireDefault(require("./router"));
var _axios = _interopRequireDefault(require("axios"));
var _router2 = require("@/router");
var _store = _interopRequireDefault(require("./store"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
var _errorLog = require("@/utils/error-log");
var _handleRoute = require("@/utils/handleRoute");
var _routeMap = require("@/router/routeMap");
var _settings = _interopRequireDefault(require("@/settings"));
var _performanceTracking = _interopRequireDefault(require("@/utils/performance-tracking"));
var _config = require("@/utils/config");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
// progress bar
// progress bar style
// get token from cookie

/**
 * 在当前页面改变了version，所有同源页面都刷新
 * 当前页面不会触发
 */
window.addEventListener('storage', e => {
  if (e.key === 'version') {
    location.reload(true);
  }
});

// 监控路由变化，是否要跟新版本
function watchVersionChange(to, next) {
  return new Promise((resolve, reject) => {
    (0, _axios.default)({
      method: 'get',
      url: '/version.txt',
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(res => {
      const version = localStorage.getItem('version');
      // eslint-disable-next-line eqeqeq
      if (version != res.data) {
        localStorage.setItem('version', res.data);
        history.pushState('', '', to.fullPath);
        location.reload(true);
      }
      resolve();
    }).catch(() => {
      resolve();
    });
  });
}

// 生成完整路由
function addPermissionRouter() {
  (0, _router2.resetRouter)();
  const dynamicRoutes = (0, _handleRoute.generateRouter)(_store.default.getters.routesTree);
  const allRoutes = _router2.constantRoutes.concat(dynamicRoutes).concat(_router2.notFoundRoutes);
  _router.default.options.routes = allRoutes;
  _router.default.matcher.addRoutes(dynamicRoutes.concat(_router2.notFoundRoutes));
}

// 请求info生成完整路由
async function addAsyncRouter(to, next) {
  try {
    await _store.default.dispatch('user/getInfo').then(res => {
      addPermissionRouter();
      next({
        ...to,
        replace: true
      });
    });
  } catch (error) {
    _store.default.commit('user/RESET_INFO');
    next(_routeMap.ROUTES.login);
  }
}

// 设置跳转页面时进度条的配置项
_nprogress.default.configure({
  showSpinner: false // 禁用进度环
});

// 不需要重定向的白名单路由
const whiteList = ['/login', '/xiaozhan/login', '/resetPassword', '/xiaozhan/resetPassword', '/vrc', '/videoRoomCand', '/meeting-pc', '/meeting-mobile'];
_router.default.beforeEach(async (to, from, next) => {
  // NOTE 存储redirect_uri
  _performanceTracking.default.start();
  if (to.query.redirect_uri) {
    _store.default.commit('user/SET_REDIRECT_URI', to.query.redirect_uri);
  }

  // NOTE 嵌入第三方登录时，判断后端返回的redirect_url中是否有session,有则主动写入
  if (to.query.session && to.path && to.path !== '/404') {
    (0, _auth.setToken)(to.query.session);
    sessionStorage.setItem('embed', to.path);
  }
  if (_store.default.getters.orgId === 125) {
    document.title = '小站教育培训系统';
  } else {
    const title = to.meta.subtitle || to.meta.title;
    if (title) {
      document.title = `${title}${_config.config.isMegaview ? '-Megaview' : ''}`;
    } else {
      document.title = _settings.default.title;
    }
  }
  // 进度条开始
  _nprogress.default.start();
  // 获取用户登录的token
  const hasToken = (0, _auth.getToken)();

  // 处理官网跳转登录后的重定向
  if (to.path === '/dashboard' && to.query.redirect_uri) {
    location.href = to.query.redirect_uri;
  }
  // 控制小站登录页面到进入应用内之前的路由增加xiaozhan标识，目前主要是登录页，重置密码页，团队选择页，这三者之间的跳转。
  if (from.path.indexOf('xiaozhan') !== -1 && to.path.indexOf('xiaozhan') === -1 && to.path !== '/' && to.path !== '/dashboard') {
    next('/xiaozhan' + to.path);
  }
  if (hasToken) {
    // 用户已登录
    const orgId = _store.default.getters.orgId;
    if (!orgId) {
      const userId = _store.default.getters.userId;
      if (!userId) {
        // 刷新页面的时候有session，但是vuex会清空，所以没有用户和团队信息，这时需要重新请求info
        addAsyncRouter(to, next);
      } else {
        // 如果没有orgId,不在白名单，就跳转到选择团队页
        if (whiteList.includes(to.path)) {
          next();
        } else if (to.path.includes(_routeMap.ROUTES.chooseTeam)) {
          (0, _router2.resetRouter)();
          next();
        } else {
          next(_routeMap.ROUTES.chooseTeam);
        }
      }
    } else {
      // 如果有orgId
      if (whiteList.includes(to.path) && to.path.includes(_routeMap.ROUTES.login) || to.path.includes(_routeMap.ROUTES.resetPassword)) {
        // 已经有用户和团队信息的就直接进首页
        next('/');
      } else if (to.path.includes(_routeMap.ROUTES.chooseTeam)) {
        (0, _router2.resetRouter)();
        next();
      } else {
        if (_router.default.options.routes.length <= _router2.constantRoutes.length + _router2.notFoundRoutes.length) {
          // 如果路由信息未加载异步路由，则请求info,加载异步路由
          addAsyncRouter(to, next);
        } else {
          next();
        }
      }
    }
  } else {
    // 用户未登录
    if (whiteList.includes(to.path) || to.path.includes(`${_routeMap.ROUTES.login}/`)) {
      // 如果login页面有参数，如/login/fbt，不进行重定向
      if (to.query.app && to.query.origin_id) {
        localStorage.setItem('iframeApp', to.query.app);
        localStorage.setItem('iframeOriginId', to.query.origin_id);
      }
      next();
    } else {
      _store.default.commit('user/RESET_INFO');
      if (to.meta.is_iframe) {
        var _to$query;
        localStorage.setItem('iframeDealCode', to.query.deal_code);
        localStorage.setItem('iframeApp', to.query.app);
        localStorage.setItem('iframeOriginId', to.query.origin_id);
        localStorage.setItem('iframeUserId', (_to$query = to.query) === null || _to$query === void 0 ? void 0 : _to$query.user_id);
        // eslint-disable-next-line no-undef
        next({
          path: `/login${to.query.app ? '/' + to.query.app : ''}`
        });
      } else {
        // RE: 增加redirect参数
        next({
          path: _routeMap.ROUTES.login,
          query: to.query
        });
      }
    }
  }
  if (to.path === '/404') {
    // 404时上报日志
    if (from.path === '/') {
      (0, _errorLog.postErrorLog)('路由重定向到404', ` | routerFrom: ${to.redirectedFrom}`, 'warning');
      return;
    }
    if (to.redirectedFrom) {
      (0, _errorLog.postErrorLog)('路由重定向到404', ` | routerFrom: ${to.redirectedFrom}`);
    }
  }

  // 拦截版本更新时的路由跳转
  await watchVersionChange(to, next);
});
_router.default.afterEach(() => {
  // 进度条结束消失
  _nprogress.default.done();
});