"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.isEdit ? "编辑销售画像" : "创建销售画像",
      visible: _vm.drawerVisible,
      "before-close": _vm.handleClose,
      size: "800px",
      "custom-class": "portrait-config",
      "wrapper-closable": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawerVisible = $event;
      }
    }
  }, [_vm.kanbanConfig ? _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getPortraitConfigLoading,
      expression: "getPortraitConfigLoading"
    }],
    ref: "form",
    attrs: {
      model: _vm.kanbanConfig,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    staticClass: "kanban-name",
    attrs: {
      label: "看板名称",
      prop: "config_name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.kanbanConfig.config_name,
      callback: function ($$v) {
        _vm.$set(_vm.kanbanConfig, "config_name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "kanbanConfig.config_name"
    }
  })], 1), _c("div", {
    staticClass: "tab-header"
  }, _vm._l(_vm.kanbanConfig.axis_config, function (tabHeaderItem, tabHeaderIndex) {
    return _c("span", {
      key: tabHeaderIndex,
      class: {
        active: _vm.activeTab === tabHeaderIndex
      },
      on: {
        click: function ($event) {
          return _vm.changeTab(tabHeaderIndex);
        }
      }
    }, [_vm._v(_vm._s(tabHeaderItem.title) + "配置")]);
  }), 0), _c("div", {
    ref: "scroll-form",
    staticClass: "scroll-form",
    on: {
      scroll: _vm.scrollForm
    }
  }, _vm._l(_vm.kanbanConfig.axis_config, function (axisConfig, index) {
    return _c("div", {
      key: index,
      ref: `tab-container-${index}`,
      refInFor: true,
      staticClass: "tab-container"
    }, [_c("el-form-item", {
      attrs: {
        label: `${axisConfig.title}名称`,
        prop: axisConfig.name,
        rules: _vm.checkAxisConfigName
      }
    }, [_c("el-input", {
      model: {
        value: axisConfig.name,
        callback: function ($$v) {
          _vm.$set(axisConfig, "name", $$v);
        },
        expression: "axisConfig.name"
      }
    })], 1), _c("lable-require-tooltip-remark", {
      attrs: {
        config: {
          label: "计算指标配置",
          require: true,
          tooltip: [{
            title: _vm.tooltip[index].title,
            content: _vm.tooltip[index].content
          }],
          remark: axisConfig.remark
        }
      }
    }), _vm._l(axisConfig.config_items, function (indicatorsItem, indicatorsIndex) {
      return [_c("el-form-item", {
        key: indicatorsItem.id,
        staticClass: "indicators-item",
        attrs: {
          prop: JSON.stringify([index, indicatorsIndex]),
          rules: _vm.checkIndicatorsItem
        }
      }, [axisConfig.config_items.length > 1 ? _c("el-button", {
        staticClass: "delete-btn",
        attrs: {
          icon: "el-icon-circle-close"
        },
        on: {
          click: function ($event) {
            return _vm.deleteIndicator(index, indicatorsIndex);
          }
        }
      }) : _vm._e(), _c("div", [_c("el-select", {
        attrs: {
          placeholder: "请选择指标"
        },
        on: {
          change: function ($event) {
            return _vm.metricsTypeChange(indicatorsItem);
          }
        },
        model: {
          value: indicatorsItem.metrics_type,
          callback: function ($$v) {
            _vm.$set(indicatorsItem, "metrics_type", $$v);
          },
          expression: "indicatorsItem.metrics_type"
        }
      }, _vm._l(_vm.indicatorsType, function (item) {
        return _c("el-option", {
          key: item.type,
          attrs: {
            label: item.name,
            value: item.type
          }
        });
      }), 1), indicatorsItem.metrics_type ? _c("custom-cascader", {
        key: indicatorsItem.metrics_type,
        attrs: {
          indicators: indicatorsItem,
          "is-multiple": indicatorsItem.metrics_type !== "conversation",
          "is-multiple-father-radio": indicatorsItem.metrics_type === "businessOptions",
          options: _vm.indicatorsValueOptions[indicatorsItem.metrics_type]
        },
        on: {
          change: function ($event) {
            return _vm.metricsValueChange(indicatorsItem);
          }
        }
      }) : _vm._e(), _c("div", {
        staticClass: "scope-box"
      }, [_c("span", {
        staticClass: "tooltip"
      }, [_vm._v(_vm._s(indicatorsItem.weight) + "分")]), _c("el-slider", {
        attrs: {
          min: 1
        },
        model: {
          value: indicatorsItem.weight,
          callback: function ($$v) {
            _vm.$set(indicatorsItem, "weight", $$v);
          },
          expression: "indicatorsItem.weight"
        }
      })], 1)], 1), _c("div", {
        staticClass: "data-type-box",
        class: {
          show: Object.keys(_vm.getDateTypeConfig(indicatorsItem)).length
        }
      }, _vm._l(_vm.getDateTypeConfig(indicatorsItem), function (dateTypeItem, dateTypeItemKey) {
        return _c("div", {
          key: dateTypeItemKey,
          staticClass: "data-type"
        }, [_c("lable-require-tooltip-remark", {
          attrs: {
            config: _vm.dataTypeConfig[dateTypeItemKey]
          }
        }), _c("el-radio-group", {
          staticClass: "data-type-contain",
          model: {
            value: indicatorsItem[_vm.dataTypeConfig[dateTypeItemKey].key],
            callback: function ($$v) {
              _vm.$set(indicatorsItem, _vm.dataTypeConfig[dateTypeItemKey].key, $$v);
            },
            expression: "\n                    indicatorsItem[dataTypeConfig[dateTypeItemKey].key]\n                  "
          }
        }, _vm._l(dateTypeItem, function (optionItem) {
          return _c("el-radio", {
            key: optionItem.type,
            attrs: {
              label: optionItem.type,
              disabled: optionItem.disabled
            }
          }, [_vm._v(" " + _vm._s(optionItem.name) + " ")]);
        }), 1)], 1);
      }), 0)], 1)];
    }), _c("el-button", {
      staticClass: "add-indicator",
      attrs: {
        icon: "el-icon-plus"
      },
      on: {
        click: function ($event) {
          return _vm.addIndicator(index);
        }
      }
    }, [_vm._v(" 增加指标 ")])], 2);
  }), 0)], 1) : _vm._e(), _c("div", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: _vm.previewPortrait
    }
  }, [_vm._v(" 预览 ")]), _c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 确定 ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;