import { render, staticRenderFns } from "./DepartmentTree.vue?vue&type=template&id=49b8778e&scoped=true&"
import script from "./DepartmentTree.vue?vue&type=script&lang=js&"
export * from "./DepartmentTree.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentTree.vue?vue&type=style&index=0&id=49b8778e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b8778e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/deploy/sales-api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49b8778e')) {
      api.createRecord('49b8778e', component.options)
    } else {
      api.reload('49b8778e', component.options)
    }
    module.hot.accept("./DepartmentTree.vue?vue&type=template&id=49b8778e&scoped=true&", function () {
      api.rerender('49b8778e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mgv-ui/packages/department-multi-select/src/components/DepartmentTree.vue"
export default component.exports