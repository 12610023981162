"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __name: 'index',
  props: {
    optionList: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props
    };
  }
};
exports.default = _default;