"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeLibraryCatalog = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeLibraryCatalog.vue"));
var _KnowledgeBaseDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/knowledgeLibrary/KnowledgeBaseDropdown.vue"));
var _KnowledgeSearch = _interopRequireDefault(require("@/views/coaching/knowledge/knowledgeLibrary/components/KnowledgeSearch.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'CatalogSelect',
  components: {
    KnowledgeLibraryCatalog: _KnowledgeLibraryCatalog.default,
    KnowledgeSearch: _KnowledgeSearch.default,
    KnowledgeBaseDropdown: _KnowledgeBaseDropdown.default
  },
  props: {
    catalogData: {
      type: Array,
      default: () => []
    },
    currentNode: {
      type: Object,
      default: () => ({})
    },
    showWorkspace: {
      type: Boolean,
      default: false
    },
    hasKnowledgesSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasCatalog() {
      return this.catalogData.length;
    }
  },
  methods: {
    searchKnowledge(val) {
      this.$emit('search', val);
    }
  }
};
exports.default = _default;