"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "combine-rule-group"
  }, [_c("div", {
    staticClass: "combine-rule-group__header"
  }, [_c("span", {
    staticClass: "combine-rule-group__title"
  }, [_vm._v("规则组" + _vm._s(_vm.ruleGroupIndex + 1))]), _vm.submitCount && _setup.showRuleGroupError ? _c("span", {
    staticClass: "combine-rule-group__error"
  }, [_vm._v("有规则未选择模型/版本")]) : _vm._e()]), _vm.ruleGroupLength > 1 && !_vm.disabled ? _c("MgvButton", {
    staticClass: "delete-rule-group-btn",
    attrs: {
      color: "info",
      type: "text",
      icon: "icon-delete"
    },
    on: {
      click: _setup.deleteRuleGroup
    }
  }) : _vm._e(), _c("div", {
    staticClass: "combine-rule-group__content"
  }, [_vm._l(_vm.ruleGroup.configs, function (ruleItem, ruleItemIndex) {
    return _c("div", {
      key: ruleItemIndex,
      staticClass: "combine-rule-group__content-item"
    }, [ruleItem.type === "and" ? _vm._l(ruleItem.configs, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "combine-rule-group__content-item-and"
      }, [_c("div", {
        staticClass: "combine-rule-group__content-item-and-content"
      }, [ruleItemIndex !== 0 ? [_c("span", [_vm._v("且")]), _c("el-select", {
        staticClass: "span-type-select",
        attrs: {
          disabled: _vm.disabled
        },
        model: {
          value: item.span_type,
          callback: function ($$v) {
            _vm.$set(item, "span_type", $$v);
          },
          expression: "item.span_type"
        }
      }, _vm._l(_setup.spanTypeOption, function (spanType) {
        return _c("el-option", {
          key: spanType.value,
          attrs: {
            value: spanType.value,
            label: spanType.label
          }
        });
      }), 1), [2, 4].includes(item.span_type) ? _c("el-input-number", {
        staticClass: "span-input-number",
        attrs: {
          disabled: _vm.disabled,
          controls: false,
          min: 1,
          precision: 0
        },
        model: {
          value: item.span,
          callback: function ($$v) {
            _vm.$set(item, "span", $$v);
          },
          expression: "item.span"
        }
      }) : _vm._e(), _c("span", [_vm._v("句内")]), _c("el-select", {
        staticClass: "action-select",
        attrs: {
          disabled: _vm.disabled
        },
        model: {
          value: item.action,
          callback: function ($$v) {
            _vm.$set(item, "action", $$v);
          },
          expression: "item.action"
        }
      }, _vm._l(_setup.actionOption, function (action) {
        return _c("el-option", {
          key: action.value,
          attrs: {
            value: action.value,
            label: action.label
          }
        });
      }), 1)] : [_c("span", [_vm._v("命中")])], _c(_setup.ModelAndVersionSelect, {
        key: ruleItemIndex + index,
        class: [{
          "not-model-id": _vm.submitCount && !item.model_id
        }, {
          "not-model-version": _vm.submitCount && !item.model_version
        }],
        attrs: {
          disabled: _vm.disabled,
          "filter-combine-model": "",
          "model-id": item.model_id,
          version: item.model_version,
          "default-selected-published-version": ""
        },
        on: {
          selectChange: function ($event) {
            return _setup.modelAndVersionSelectChange($event, item);
          }
        }
      })], 2), _c("div", {
        staticClass: "combine-rule-group__content-item-and-operate"
      }, [_vm.ruleGroup.configs.length > 1 && !_vm.disabled ? _c("MgvButton", {
        staticClass: "delete-row-btn",
        attrs: {
          type: "text",
          disabled: _vm.disabled,
          color: "info",
          icon: "icon-close"
        },
        on: {
          click: function ($event) {
            return _setup.deleteRow(ruleItemIndex);
          }
        }
      }) : _vm._e()], 1)]);
    }) : _vm._e()], 2);
  }), _c("div", {
    staticClass: "combine-rule-group__content-operate"
  }, [_c("el-tooltip", {
    attrs: {
      content: "单组最多设置10条原子规则",
      disabled: _vm.ruleGroup.configs.length < 10
    }
  }, [!_vm.disabled ? _c("MgvButton", {
    attrs: {
      icon: "icon-plus",
      type: "text",
      size: "mini",
      disabled: _vm.ruleGroup.configs.length >= 10
    },
    on: {
      click: _setup.addRow
    }
  }, [_vm._v("且（" + _vm._s(_vm.ruleGroup.configs.length) + "/10）")]) : _vm._e()], 1)], 1)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;