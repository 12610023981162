"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-management"
  }, [_c("div", [_c("header", {
    staticClass: "header"
  }, [_vm._v(" 欢迎您："), _c("span", {
    staticClass: "org-name"
  }, [_vm._v(_vm._s(_vm.orgName))]), _vm.usageData.model === "license" ? _c("span", {
    staticClass: "bill-mode"
  }, [_vm._v("按 license 计费")]) : _vm._e(), _vm.usageData.model === "usage" ? _c("span", {
    staticClass: "bill-mode"
  }, [_vm._v("按量计费")]) : _vm._e()]), _c("section", {
    staticClass: "content"
  }, [_c("div", [_c("div", {
    staticClass: "tenancy-term"
  }, [_vm._v("企业租期")]), _c("div", {
    staticClass: "tenancy-date"
  }, [_vm._v(" " + _vm._s(_vm.usageData.start_date)), _c("span", [_vm._v("至")]), _vm._v(_vm._s(_vm.usageData.end_date) + " ")])]), _vm.usageData.model === "license" ? _c("div", [_c("div", {
    staticClass: "user-quota"
  }, [_vm._v("用户配额")]), _c("div", {
    staticClass: "user-limit"
  }, [_c("span", [_vm._v(_vm._s(_vm.activeCount))]), _vm._v(" / " + _vm._s(_vm.limitCount) + " ")]), _c("div", {
    staticClass: "user-limit-desc"
  }, [_vm._v(" 已使用 / 人数上限"), _c("span", {
    on: {
      click: _vm.openUserScaleDialog
    }
  }, [_vm._v("申请扩容")])])]) : _vm._e(), _c("div", [_c("div", {
    staticClass: "product-usage"
  }, [_vm._v("产品用量")]), _vm._m(0), _vm.usageData.model === "license" ? _c("div", [_c("div", {
    staticClass: "product-usage-minute"
  }, [_c("span", [_vm._v(_vm._s(_vm.usageData.amount_used))]), _vm._v(" 分钟 ")]), _c("div", {
    staticClass: "product-usage-used"
  }, [_vm._v("已使用")])]) : _vm._e(), _vm.usageData.model === "usage" ? _c("div", {
    staticClass: "product-usage-content"
  }, [_c("div", [_c("div", {
    staticClass: "product-usage-minute"
  }, [_c("span", [_vm._v(_vm._s(_vm.usageData.amount_used))]), _vm._v(" 分钟 ")]), _c("div", {
    staticClass: "product-usage-used"
  }, [_vm._v("已使用")])]), _c("div", [_c("div", {
    staticClass: "product-usage-surplus"
  }, [_c("span", [_vm._v(_vm._s(_vm.usageData.total_amount - _vm.usageData.amount_used))]), _vm._v(" 分钟 ")]), _c("div", {
    staticClass: "product-usage-used"
  }, [_vm._v("剩余")])])]) : _vm._e()])])]), _c("user-scale-dialog", {
    attrs: {
      "user-scale-dialog-visible": _vm.userScaleDialogVisible
    },
    on: {
      closeUserScaleDialog: _vm.closeUserScaleDialog
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "product-usage-title"
  }, [_c("i", {
    staticClass: "iconfont icon-ci"
  }), _vm._v("会话智能分析 ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;