"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _biChart = _interopRequireDefault(require("@/components/BusinessIntelligence/biChart"));
var _ExportMixins = _interopRequireDefault(require("@/views/businessIntelligence/ExportMixins"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TabContent',
  components: {
    BiChart: _biChart.default
  },
  mixins: [_ExportMixins.default],
  props: {
    keyName: {
      type: String,
      default: ''
    },
    tab: {
      type: [Number, String],
      default: ''
    },
    desTitle: {
      type: String,
      default: ''
    },
    desDetail: {
      type: String,
      default: ''
    },
    contentArrays: {
      type: Array,
      default: function () {
        return null;
      }
    },
    avgObj: {
      type: Object,
      default: function () {
        return null;
      }
    },
    horizontalArray: {
      type: Array,
      default: function () {
        return null;
      }
    },
    chartType: {
      type: String,
      default: ''
    },
    desAllCount: {
      type: Number,
      default: 0
    },
    desCount: {
      type: Number,
      default: 0
    },
    // 首次进入判断
    contentType: {
      type: String,
      default: ''
    },
    secondLabel: {
      type: String,
      default: '时长'
    }
  },
  data() {
    return {
      sortType: 'descending'
    };
  },
  methods: {
    handleSortTypeChange(type, key) {
      this.sortType = type;
      if (this.contentArrays.length) {
        this.$emit('changeSortType', this.sortType);
        this.$emit('changeContentArrays');
      }
    }
  }
};
exports.default = _default;