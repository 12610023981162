"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "before-close": _vm.handleClose,
      width: "600px",
      "custom-class": "admin-role-department-finder-dialog",
      "modal-append-to-body": false,
      title: _vm.title
    },
    nativeOn: {
      keypress: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          staticClass: "mg-button",
          attrs: {
            plain: ""
          },
          on: {
            click: _vm.handleClose
          }
        }, [_vm._v("取消")]), _c("el-button", {
          staticClass: "mg-button",
          attrs: {
            type: "primary",
            disabled: !_vm.selection.length
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("确定")])];
      },
      proxy: true
    }])
  }, [_c("TransferDepartmentFinder", {
    staticClass: "transfer-department-finder",
    attrs: {
      "default-checked": _vm.defaultChecked,
      visible: _vm.visible,
      "remote-method": _vm.remoteMethod
    },
    on: {
      handleChange: _vm.handleChange,
      getManageTree: _vm.getManageTree
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;