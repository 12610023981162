"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatHistoryDate = formatHistoryDate;
exports.second2HMS = second2HMS;
exports.secondToCNTime = secondToCNTime;
exports.secondToCNTimeArr = secondToCNTimeArr;
exports.secondToCNTimeOfFixedTwo = secondToCNTimeOfFixedTwo;
exports.secondToENTime = secondToENTime;
exports.secondToTime = secondToTime;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable prefer-const */

function secondToTime(second) {
  const h = Math.floor(second / 3600) < 10 ? '0' + Math.floor(second / 3600) : Math.floor(second / 3600);
  const m = Math.floor(second / 60 % 60) < 10 ? '0' + Math.floor(second / 60 % 60) : Math.floor(second / 60 % 60);
  const s = Math.floor(second % 60) < 10 ? '0' + Math.floor(second % 60) : Math.floor(second % 60);
  let res = '';
  if (h !== '00') {
    res += `${h}:`;
  } else {
    // res += `00:`;
  }
  if (m !== '00') {
    res += `${m}:`;
  } else {
    res += `00:`;
  }
  res += `${s}`;
  return res;
}
function secondToCNTime(second) {
  let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['时', '分', '秒'];
  const h = Math.floor(second / 3600);
  const m = Math.floor(second / 60 % 60);
  const s = Math.floor(second % 60);
  let res = '';
  if (h !== 0) {
    res += `${h}${unit[0]}`;
  }
  if (m !== 0) {
    res += `${m}${unit[1]}`;
  }
  res += `${s}${unit[2]}`;
  return res;
}
function secondToCNTimeArr(second) {
  let isFull = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (typeof second === 'undefined') return [];
  let h = Math.floor(second / 3600);
  let m = Math.floor(second / 60 % 60);
  let s = Math.round(second % 60);
  if (s === 60) {
    s = 59;
    m++;
    if (m === 60) {
      m = 59;
      h++;
    }
  }
  const arr = [];
  if (h !== 0) {
    arr.push(h);
    arr.push('时');
  }
  if (m !== 0) {
    arr.push(m);
    arr.push('分');
  }
  if (arr.length < 3 || isFull) {
    arr.push(s);
    arr.push('秒');
  } else {
    if (s >= 30) {
      if (arr[2] === 59) {
        arr[2] = 0;
        arr[0]++;
      } else {
        arr[2]++;
      }
    }
  }
  return arr;
}
function secondToENTime(second) {
  const h = Math.floor(second / 3600);
  const m = Math.floor(second / 60 % 60);
  // eslint-disable-next-line no-unused-vars
  const s = Math.floor(second % 60);
  let res = '';
  if (h !== 0) {
    res += `${h}h `;
  }
  if (m !== 0) {
    res += `${m}m `;
  }
  res += `${s}s`;
  return res;
}
function second2HMS(seconds) {
  const s = _moment.default.duration(seconds * 1000).seconds();
  const m = _moment.default.duration(seconds * 1000).minutes();
  const h = Math.floor(_moment.default.duration(seconds * 1000).asHours());
  return {
    s,
    m,
    h
  };
}
function secondToCNTimeOfFixedTwo(seconds) {
  let {
    s,
    // eslint-disable-next-line prefer-const
    m,
    h
  } = second2HMS(seconds);
  if (h) {
    return m ? `${h}小时 ${m}分` : `${h}小时`;
  } else if (m) {
    return s ? `${m}分 ${s}秒` : `${m}分`;
  } else if (s) {
    s = _moment.default.duration(seconds * 1000).asSeconds().toFixed(2);
    return `${s}秒`;
  } else {
    return `0秒`;
  }
}

//   格式化历史时间(至今xx)
function formatHistoryDate(historyDate) {
  const date = (0, _moment.default)(historyDate).startOf('minute').fromNow();
  return date;
}