"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskCard = _interopRequireDefault(require("./TaskCard"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskList',
  components: {
    TaskCard: _TaskCard.default
  },
  props: {
    taskTypeList: {
      type: Array,
      default: () => []
    },
    currentMenu: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeNames: ['overdue', 'today']
    };
  },
  computed: {
    taskTag() {
      return function (tag) {
        switch (tag) {
          case 'overdue':
            return '已逾期';
          case 'today':
            return '今天';
          case 'next_seven':
            return '未来七天';
          case 'future':
            return '以后';
        }
      };
    }
  },
  methods: {
    // 完成任务
    handleOperateTask(item, id) {
      this.$emit('operateTask', item.tag, id);
    },
    goTaskDetail(data) {
      this.$emit('goTaskDetail', data.task_info.task_id);
    }
  }
};
exports.default = _default;