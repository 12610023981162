"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "go-back",
    on: {
      click: _vm.goCoachTodo
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  }), _vm._v(" " + _vm._s(_vm.$t("coaching.back")) + " ")]), Object.keys(_vm.knowledge).length ? [_c("div", {
    staticClass: "question-info"
  }, [_c("div", {
    staticClass: "process"
  }, [_c("span", {
    staticClass: "bold-text"
  }, [_vm._v(" " + _vm._s(_vm.knowledge.order) + " ")]), _c("span", {
    staticClass: "bias"
  }, [_vm._v("/")]), _c("span", [_vm._v(_vm._s(_vm.knowledge.total_count))])]), _c("span", {
    staticClass: "bold-text knowledge-name"
  }, [_vm._v(_vm._s(_vm.knowledge.name))])]), _c("div", {
    staticClass: "answer-sheet",
    on: {
      click: _vm.showAnswerSheet
    }
  }, [_c("i", {
    staticClass: "iconfont icon-menu-line"
  }), _c("span", [_vm._v(_vm._s(_vm.$t("coaching.answerSheet")))])])] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;