"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "ruleForm",
    staticClass: "form-container",
    attrs: {
      model: _vm.formData,
      "hide-required-asterisk": ""
    }
  }, [_c("FormItemCard", {
    attrs: {
      title: "抽样范围",
      explain: "抽样只会抽取未被分配过的会话"
    }
  }, [_c("el-form-item", {
    staticClass: "data-scope",
    attrs: {
      prop: "data_scope"
    }
  }, [_c("div", {
    staticClass: "radio-container"
  }, [_c("el-radio", {
    attrs: {
      label: false
    },
    model: {
      value: _vm.formData.is_all_data,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "is_all_data", $$v);
      },
      expression: "formData.is_all_data"
    }
  }, [_vm._v("符合筛选条件的会话")]), _c("el-radio", {
    attrs: {
      label: true
    },
    model: {
      value: _vm.formData.is_all_data,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "is_all_data", $$v);
      },
      expression: "formData.is_all_data"
    }
  }, [_vm._v("全部会话")])], 1), _c("sample-scope-selector", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.formData.is_all_data,
      expression: "!formData.is_all_data"
    }],
    attrs: {
      "stage-options": _vm.stageOptions,
      "custom-filter-array": _vm.customFilterArray,
      "rule-id": _vm.formData.rule_id
    },
    on: {
      change: _vm.getRoleInfo
    }
  })], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.formData.is_all_data,
      expression: "!formData.is_all_data"
    }],
    attrs: {
      prop: "data"
    }
  }, [_c("conv-date", {
    staticClass: "conv-date",
    attrs: {
      filters: _vm.formData,
      "no-tooltip": "",
      shortcuts: []
    }
  })], 1), _c("div", {
    staticClass: "conv-count-content"
  }, [_c("div", {
    staticClass: "conv-count"
  }, [_c("span", {
    staticClass: "unassigned-count"
  }, [_c("i", {
    staticClass: "iconfont icon-video-camera-solid-2"
  }), _c("p", [_vm._v(" 共"), _c("b", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_vm._v(_vm._s(_vm.unassignedCount))]), _vm._v(_vm._s(_vm.unit) + "会话 ")])]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.assignedCount && !_vm.loading,
      expression: "assignedCount && !loading"
    }],
    staticClass: "assigned-count"
  }, [_vm._v(" " + _vm._s(_vm.assignedCount) + " 条已分配质检的会话不参与抽样 ")])]), !_vm.formData.is_all_data && _vm.api_permissions.includes("/webapi/compliance/account_sale/conv/list") ? _c("el-checkbox", {
    model: {
      value: _vm.formData.account_sale,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "account_sale", $$v);
      },
      expression: "formData.account_sale"
    }
  }, [_vm._v("同客同销视为一组"), _c("el-tooltip", {
    attrs: {
      content: "所选抽样范围内，销售为同一人且客户为同一人的会话将视为同一组会话，并按组进行抽样，被抽中的同组会话将被分配给同一质检员进行质检。",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-info-1"
  })])], 1) : _vm._e()], 1), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.formData.is_all_data && _vm.formData.account_sale && _vm.unassignedCount > 40000,
      expression: "\n          !formData.is_all_data &&\n          formData.account_sale &&\n          unassignedCount > 40000\n        "
    }],
    staticClass: "warning-text"
  }, [_vm._v(" 同客同销抽样范围需小于40000组 ")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;