"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TranserMembersFinder = _interopRequireDefault(require("@/components/MembersFinder/TranserMembersFinder"));
var _organization = require("@/api/organization");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'MembersFinderDialog',
  components: {
    TranserMembersFinder: _TranserMembersFinder.default
  },
  props: {
    value: Boolean,
    treeId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      selection: []
    };
  },
  methods: {
    handleClose() {
      this.$emit('input', false);
    },
    async submit() {
      if (!this.selection.length) return;
      const user_departments = this.paramsFormatter();
      const params = {
        user_departments,
        to_department_id: this.treeId
      };
      const res = await (0, _organization.moveUserFromOtherDepartment)(params);
      if (res.code === 200) {
        this.$message.success(this.$t('management.加入成功！'));
        this.handleClose();
        this.$emit('submitSucceed');
      }
    },
    handleChange(selection) {
      this.selection = selection;
    },
    paramsFormatter() {
      const user_department = {};
      this.selection.map(item => {
        const departmentIds = item.departments.map(item => item.id);
        user_department[item.id] = departmentIds;
      });
      return user_department;
    }
  }
};
exports.default = _default;