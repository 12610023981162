"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "feedback-form",
    class: {
      "height-change": _vm.submitSuccess
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.submitSuccess,
      expression: "!submitSuccess"
    }]
  }, [_c("el-form", {
    ref: "feedbackForm",
    attrs: {
      model: _vm.feedbackData
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "type",
      rules: {
        required: true,
        message: "请选择反馈类型",
        trigger: "blur"
      }
    }
  }, [_c("span", {
    staticClass: "form-label"
  }, [_vm._v("反馈类型"), _c("i", {
    staticClass: "required-dot"
  }, [_vm._v("*")])]), _c("div", {
    staticClass: "type-tab"
  }, [_c("filter-tab", {
    ref: "filterTab",
    attrs: {
      "is-show-all": false,
      list: _vm.typeList
    },
    on: {
      tabChange: _vm.typeTabClick
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      prop: "description",
      rules: {
        required: true,
        message: "请输入详细描述",
        trigger: "change"
      }
    }
  }, [_c("span", {
    staticClass: "form-label"
  }, [_vm._v("详细描述"), _c("i", {
    staticClass: "required-dot"
  }, [_vm._v("*")])]), _c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 3
      },
      placeholder: _vm.textareaPlaceholder,
      maxlength: "300",
      "show-word-limit": "",
      resize: "none"
    },
    model: {
      value: _vm.feedbackData.description,
      callback: function ($$v) {
        _vm.$set(_vm.feedbackData, "description", $$v);
      },
      expression: "feedbackData.description"
    }
  })], 1), _c("el-form-item", {
    staticClass: "upload-item",
    attrs: {
      prop: "images"
    }
  }, [_c("span", {
    staticClass: "form-label"
  }, [_vm._v("上传图片")]), _c("el-upload", {
    ref: "upload",
    attrs: {
      multiple: "",
      action: "#",
      "list-type": "picture-card",
      accept: "image/jpeg, image/jpg, image/png",
      "on-exceed": _vm.onExceed,
      "http-request": _vm.uploadFeedbackImages,
      "on-preview": _vm.handlePictureCardPreview,
      "on-change": _vm.handleImagesChange,
      "before-upload": _vm.beforeImageUpload,
      limit: 4
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _c("span", {
    staticClass: "upload-tip"
  }, [_vm._v("支持jpg、jpeg、png等格式的图片，最多可上传4张")]), _c("el-dialog", {
    attrs: {
      visible: _vm.previewDialogVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.previewDialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.previewImgUrl,
      alt: "预览图片"
    }
  })])], 1), _c("el-form-item", {
    attrs: {
      prop: "contact"
    }
  }, [_c("span", {
    staticClass: "form-label"
  }, [_vm._v("联系方式")]), _c("el-input", {
    attrs: {
      maxlength: 50,
      placeholder: "邮箱或手机号码"
    },
    model: {
      value: _vm.feedbackData.contact,
      callback: function ($$v) {
        _vm.$set(_vm.feedbackData, "contact", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "feedbackData.contact"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.initCloseDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      size: "medium",
      loading: _vm.submitLoading,
      disabled: !_vm.feedbackData.description,
      type: "primary"
    },
    on: {
      click: _vm.feedbackSubmit
    }
  }, [_vm._v("提交")])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.submitSuccess,
      expression: "submitSuccess"
    }]
  }, [_vm._m(0), _c("footer", {
    staticClass: "success-footer"
  }, [_c("el-button", {
    attrs: {
      size: "medium",
      type: "primary"
    },
    on: {
      click: _vm.initCloseDialog
    }
  }, [_vm._v("完成")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "success-container"
  }, [_c("img", {
    staticClass: "success-img",
    attrs: {
      src: require("@/assets/assist/feedback_success.png"),
      alt: "反馈完成"
    }
  }), _c("span", {
    staticClass: "success-tip"
  }, [_vm._v("反馈提交成功，感谢您的支持")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;