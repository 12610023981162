"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _VirtualMoreUserListInPopover = _interopRequireDefault(require("@/components/VirtualMoreUserListInPopover"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'ExecutorList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      VirtualMoreUserListInPopover: _VirtualMoreUserListInPopover.default
    };
  }
};
exports.default = _default;