"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rule-event-form"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.formItemlabel + "名称",
      prop: _vm.nameProp
    }
  }, [_c("el-input", {
    staticClass: "key-event-name",
    attrs: {
      value: _vm.name,
      placeholder: "请输入",
      maxlength: "20",
      type: "text",
      "show-word-limit": ""
    },
    on: {
      input: function ($event) {
        return _vm.$emit("update:name", $event);
      }
    }
  }, [_vm.formItemlabel === "关键事件" ? _c("template", {
    slot: "prepend"
  }, [_c("el-tooltip", {
    attrs: {
      content: "自定义关键事件标识，使用时与系统关键事件做区分",
      placement: "top"
    }
  }, [_c("span", [_vm._v("✏️")])])], 1) : _vm._e()], 2)], 1), _c("el-form-item", {
    attrs: {
      label: _vm.formItemlabel + "描述"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.description,
      type: "textarea",
      "show-word-limit": "",
      placeholder: "请输入模型描述",
      rows: 3,
      maxlength: "100"
    },
    on: {
      input: function ($event) {
        return _vm.$emit("update:description", $event);
      }
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;