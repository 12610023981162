"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ease-steps",
    class: {
      "is-vertical": _vm.direction === "vertical"
    }
  }, _vm._l(_vm.steps, function (text, index) {
    return _c("div", {
      key: index,
      staticClass: "ease-steps__step-container"
    }, [_c("div", {
      staticClass: "ease-steps__step",
      class: {
        "ease-steps__step--active": index + 1 === _vm.current,
        "ease-steps__step--finished": _vm.isFinish || index + 1 < _vm.current
      }
    }, [_c("div", {
      staticClass: "ease-step__icon-container"
    }, [_c("div", {
      staticClass: "ease-step__icon"
    }, [_c("span", [_vm._v(_vm._s(index + 1))])]), _c("div", {
      staticClass: "ease-step__icon-line"
    })]), _c("div", {
      staticClass: "ease-step__title"
    }, [_vm._v(_vm._s(text))]), index + 1 < _vm.steps.length ? _c("div", {
      staticClass: "ease-step__arrow"
    }, [_c("i", {
      staticClass: "iconfont icon-arrow-right"
    })]) : _vm._e()])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;