"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const coaching = {
  zh: {
    coaching: {
      首页: '首页',
      培训: '培训',
      courseManagement: '课程管理',
      courseClassification: '课程分类',
      searchByCourseName: '按课程名称搜索',
      noResults: '未搜索到“{0}”相关结果',
      defaultClassification: '默认分类',
      creator: '创建人',
      pleaSeselectCreator: '请选择创建人',
      courseType: '课程类型',
      startDate: '开始日期',
      endDate: '结束日期',
      newCourse: '新建课程',
      totalNum: '共{0}条',
      perPage: '{0}条/页',
      noCourses: '暂无课程',
      addClassification: '添加分类',
      newHasVar: '新建{0}',
      category: '分类',
      nameHasVar: '{0}名称',
      cannotBeEmpty: '不能为空',
      pleaseEnterContent: '请输入内容',
      publicScope: '公开范围',
      visibleToAll: '对所有人可见',
      visibleToSpecified: '对指定部门或成员可见',
      pleaseSelectMembers: '请选择成员',
      cancel: '取消',
      confirm: '确定',
      savedSuccessfully: '保存成功',
      rename: '重命名',
      renameHasVar: '重命名{0}',
      renameClassification: '重命名分类',
      editPublicScope: '编辑公开范围',
      search: '搜索',
      delete: '删除',
      note: '确 定',
      deleteCategoryWarning: '分类下的课程会自动转移至上级分类或默认分类，确认删除该分类？',
      deletedSuccessfully: '删除成功',
      onlySomeCreatorCanModifyTheClassification: '只有分类/父分类创建者才可以修改分类',
      newScriptReading: '新建课程-话术朗读',
      newScriptRecitation: '新建话术背诵',
      newScriptPractice: '新建剧本对练',
      newAICustomer: '新建AI客户',
      newAICustomerHasVar: '新建AI客户{0}',
      basicInformation: '基础信息',
      courseName: '课程名称',
      pleaseFillInTheCourseName: '请填写课程名称',
      courseNameCannotBeRepeated: '课程名称不可重复',
      maximumCharacters: '最多输入{0}字',
      courseContent: '课程内容',
      addKnowledgePointsAndScripts: '添加知识点话术',
      pleaseAddScriptPoints: '请添加话术点',
      courseRules: '课程规则',
      readingRequirements: '朗读要求',
      EachScriptNeedsRead: '{0}要求：每段话需{1}{2} 次, 且评分不低于 {3} 分',
      selectScript: '选择话术',
      save: '保存',
      newCourseScriptRecitation: '新建课程-话术背诵',
      courseNameNoRepeat: '课程名称不可重复',
      max20Char: '最多输入20字',
      pleaseAddScriptPpoints: '请添加话术点',
      recitationRequirements: '背诵要求',
      eachScriptNeedsToBeRecitedOnce: '每段话术需朗读1次且每次评分不低于60分（满分：100）',
      closedBookAssessment: '闭卷考核',
      newScriptPracticeCourse: '新建剧本对练课程',
      newBlankCourse: '新建空白课程',
      useTemplate: '使用模板',
      undefined: '学员回答',
      editCourseScriptPractice: '编辑课程-剧本对练',
      addNode: '添加节点',
      narration: '旁白',
      customerSays: '客户说',
      addThroughKnowledgeBase: '通过知识库添加',
      scriptPractice: '剧本对练',
      aiGeneratedCourse: 'AI生成课程',
      saveAsTemplate: '存为模板',
      templateName: '模板名称',
      max15Characters: '最多可输入15个字',
      enterTemplateName: '请输入模板名称',
      templateRemarks: '模板备注',
      Max30Characters: '最多可输入30个字',
      scriptPracticeTour1: '将节点拖拽至画布，可以创建该节点类型的卡片',
      skip: '跳过',
      next: '下一步',
      scriptPracticeTour2: '点击选中卡片，可以编辑卡片内容',
      previous: '上一步',
      scriptPracticeTour3: '拖拽圆点可以发起连线，将两个卡片相连；选中连线后按下退格键可删除连线。',
      scriptPracticeTour4: '搭建好对话流程后，可以在这里进行对话测试',
      scriptPracticeTour5: '复用性较高的课程，可以保存为模板',
      finish: '完成',
      narrationConfigure: '用于提示用户所处场景、客户背景等信息',
      narrationContent: '旁白内容',
      narrationExample: '例如：对方是一位首购客户',
      customerSaysContent: '客户要说的话',
      customerSaysExample: '例如：价格为什么这么贵？',
      addScript: '新增话术',
      customerSaysConfigure: '配置多条话术时，虚拟客户会随机说其中一条',
      searchScript: '搜索话术',
      searchKnowledgeBaseScript: '搜索知识库话术',
      allKnowledgeBase: '全部知识库',
      checkedScript: '已选：0个话术',
      searchTips: '暂无推荐，搜索一下试试吧~',
      add: '添加',
      salesSaysExample: '例如：我们的产品品质优良',
      jumpingRules: '跳转规则',
      normalJump: '正常往后跳转',
      conditionJump: '按条件跳转',
      knowledgeBase: '知识库',
      knowledgeBaseEmpty: '知识库为空，创建知识点或切换至其他知识库',
      importData: '导入数据',
      downloadTemplateTitle: '点击下载按钮，下载需要导入的模板文件',
      downloadTemplate: '下载模板',
      scriptDialogueTemplate: '剧本对练导入模板',
      uploadFileTitle: '文件填写完成后，在下方区域上传文件',
      uploadFileTip: '点击或将文件拖拽到这里上传',
      supportsExtensions: '支持扩展名：xls、xlsx',
      courseSettings: '课程设置',
      practiceMode: '练习模式',
      voiceMode: '语音模式',
      textMode: '文字模式',
      scriptPrompt: '话术提示',
      shortcutKeys: '快捷键',
      moveCanvas: '移动画布',
      moveCanvasTip: '鼠标左键拖动画布',
      zoomCanvas: '缩放画布',
      zoomCanvasTip: 'Control + 鼠标滑动',
      boxSelection: '框选 / 多选',
      boxSelectionTip: 'Shift + 鼠标',
      copy: '复制',
      paste: '粘贴',
      deleteTip: '选中节点卡片 / 连接线 + 回退键',
      undo: '撤销',
      redo: '重做',
      dialogueTest: '对话测试',
      conversationEnds: '会话结束',
      retest: '重新测试',
      start: '开始',
      courseNamePlaceholder: '请输入课程名称',
      dialogueScene: '对话场景',
      sceneCountLimitTips: '请为至少一个场景添加话术',
      department: '部门',
      pleaseSelectDepartment: '请选择部门',
      customer: '客户',
      sends: '秒',
      today: '今天',
      startTime: '开始时间',
      chinese: '中文',
      english: '英文',
      returnToList: '返回列表',
      restart: '重新开始',
      scriptReading: '话术朗读',
      scriptRecitation: '话术背诵',
      aICustomer: 'AI客户',
      aICustomerHasVar: 'AI客户{0}',
      lastWeek: '最近一周',
      lastMonth: '最近一个月',
      lastThreeMonths: '最近三个月',
      newTask: '新建任务',
      taskName: '任务名称',
      learnCourse: '学习课程',
      executor: '执行人',
      startAndEndTime: '起止时间',
      edit: '编辑',
      changeClassification: '更改分类',
      moveTo: '移动到',
      selectClassification: '选择分类',
      deleteCourse: '删除课程',
      tipsForDeletingCourses: '删除课程不会影响已派发的任务，是否删除该课程？',
      searchByName: '按名称搜索',
      pleaseSelectExecutor: '请选择执行人',
      status: '状态',
      notStarted: '未开始',
      inProgress: '进行中',
      completed: '已完成',
      deadlinePassed: '已截止',
      aiClient: 'AI客户',
      noTrainingTasksYet: '还没有培训任务',
      learningCourse: '学习课程',
      operation: '操作',
      details: '详情',
      noData: '暂无数据',
      assigner: '分配人',
      deleteTask: '删除任务',
      deleteTaskPrompt: '该任务已经开始，是否确认删除？',
      deleteSuccessful: '删除成功',
      copyTask: '复制任务',
      pleaseEnterTaskName: '请输入任务名称',
      pleaseSelectCourse: '请选择课程',
      completionStatus: '完成情况',
      taskScoreRange: '任务得分范围',
      below60Points: '低于60分',
      above60Points: '高于60分',
      custom: '自定义',
      notCompleted: '未完成',
      noScoreYet: '暂无得分',
      exportTable: '导出表格',
      keyScripts: '关键问题',
      sceneAverageScore: '场景平均分：{0}分',
      standardScript: '标准话术',
      intelligentReview: '智能点评',
      matchingPoints: '命中要点',
      onlyNone: '无',
      missingPoints: '未命中要点',
      dialogueRecord: '对话记录',
      aIReview: 'AI点评',
      abilityAnalysis: '能力分析',
      myTasks: '我的任务',
      searchByAssignee: '按分配人搜索',
      deadline: '截止时间',
      goPractice: '去练习',
      back: '返回',
      assignedBy: '由{0}分配',
      participantsHasVar: '{0}人参与',
      startPractice: '开始练习',
      conversationContent: '会话内容',
      endConversation: '结束会话',
      endThePracticeEarlyWarning: '客户还有关注的内容未说出，未说出的内容将以0分结算，是否提前结束对练？',
      sendNextWarning: '一轮对话完成后，可发送下句对话',
      practiceIsCompleted: '客户关注的问题已全部问出，练习已完成',
      continuePractice: '继续练习',
      leaveAICustomer: '离开AI客户',
      quitHalfwayWarning: '中途退出后，下次需从头开始练习，是否离开？',
      leave: '离开',
      ByUploadingRecording: '通过上传录音与客户进行对话',
      gotIt: '我知道了',
      pleaseStartSpeaking: '请开始发言',
      voiceNotRecognized: '未识别到语音，请检查麦克风或再次发言',
      话术提示: '话术提示',
      backToTaskList: '返回任务列表',
      viewPracticeDetails: '查看练习详情',
      completeCoachingTips: '您已完成本次培训',
      rePractice: '重练一遍',
      practiceScore: '练习得分',
      preparing: '准备中',
      recitationAssessment: '话术背诵',
      progress: '进度',
      deadlineHasVar: '{0}截止',
      commonCustomerQuestions: '客户常见问法',
      noQuestions: '暂无问法',
      answeringSkills: '回答技巧',
      noAnsweringSkills: '暂无回答技巧',
      finalScore: '最终分',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: '练习未完成，暂无得分',
      scoreDesc: '若练习次数要求为1，最终分=表现最好一次练习的得分；\n 若练习次数要求为N，最终分=表现最好N次练习的平均分',
      完成练习: '完成练习',
      questionName: '题目名称',
      questionScore: '题目得分',
      taskScore: '任务得分',
      completedQuestionsCount: '完成题目数',
      progressCompletion: '完成进度',
      to: '至',
      pleaseSelectHasVar: '请选择{0}',
      all: '全部',
      teamView: '团队视图',
      assignedByMe: '我分配的',
      score: '得分',
      practiceRecord: '练习记录',
      comprehensiveEvaluation: '综合评价',
      noKeyPhrasesAvailable: '暂无关键问题',
      scenarioPhrasesBeingGenerated: '场景话术生成中',
      notTriggered: '未触发',
      noCommentsAvailable: '暂无点评',
      dialogueRecordBeingGenerated: '对话记录生成中',
      commenting: '点评中',
      prompt: '提示',
      exit: '退出',
      scenarioPlaceholder: '请添加场景话术',
      scenceNameRepeat: '场景名称不能重名',
      quickReply: '快捷发送',
      replyPlaceholder: '输入回复（Enter发送）',
      intelligentGenerating: '智能生成中 ',
      generationFailed: '生成失败',
      clickToRetry: '点击重试',
      generateDialogueTip: '请先生成对话',
      generateNode: '生成节点',
      scoringRules: '评分规则',
      scoreByKeyPoints: '按要点评分',
      noScoring: '不评分',
      courseUnavailableTip: '若未配置要点，课程将无法使用',
      generating: '生成中',
      aiGenerateKeyPoint: 'AI生成要点',
      addKeyPoint: '添加要点',
      copySuccessfully: '复制成功',
      cannotCopyInfo: '请先选中节点再复制',
      cannotPasteInfo: '剪切板为空，不可粘贴',
      pasteSuccessfully: '粘贴成功',
      generatedSuccessfully: '生成成功',
      noNeedCompleteCard: '暂无需要补齐要点的节点卡片',
      ruleIsGenerating: '要点生成中',
      whenRuleHit: '当命中',
      whenRuleMiss: '当未命中',
      noScoreRuleConfigured: '未配置要点',
      addScriptTip: '请选择要添加的话术',
      templateNameTip: '请输入模板名称',
      inputMaxLength: '最多可输入{0}个字',
      scriptLengthLimit: '内容超出1000字上限，请删减后再添加',
      addSuccess: '添加成功',
      addScriptPlaceholder: '请选择要添加的话术',
      checkedKnowledgeScript: '已选：{0}个知识点',
      smartTrainingUseTip: '您可将左侧组件拖拽到画布上，完成卡片创建，再将卡片连线，完成对话流程配置',
      roleNamePlaceholder: '请输入角色名称',
      noConditionsHit: '以上条件都不满足',
      ruleHit: '命中',
      ruleMiss: '未命中',
      ruleKeywordPlaceholder: '填写内容要点，最多5个，Enter完成',
      templateInstructionsTip: '请仔细阅读模板中的说明，',
      templateRequiredFormat: '并按要求格式填写导入的数据',
      autoCompletePoints: '自动补齐要点',
      createCourseScriptPractice: '新建课程-剧本对练',
      passingScore: '及格分',
      fullScore: '(满分：100）',
      modelLanguage: '模型语言',
      modelLanguageTip: '模型语言影响模型对不同语种要点提取和评分的能力',
      isImporting: '正在导入...',
      ' notRefreshOrLeaveTip': "请不要刷新或离开，同时保持网络畅通'",
      importCompleted: '导入完成',
      importAgain: '再次导入',
      parsingFailed: '解析失败',
      fileContentNotMatch: '文件内容不匹配，请修改后重新上传',
      reUpload: '重新上传',
      uploadFailed: '上传失败',
      fileNotExistTip: '文件不存在，请重新上传',
      editTask: '编辑任务',
      expand: '展开',
      collapse: '收起',
      creationTime: '创建时间',
      noMatchingResults: '暂无匹配结果',
      goToCreate: '前往创建',
      pleaseSelectTheStartTime: '请选择开始时间',
      pleaseSelectTheEndTime: '请选择截止时间',
      onlyMainSpaceAreDisplayed: '仅展示以当前空间为主空间的成员',
      numberOfCoursesCannotExceed: '课程数量不能超过{0}',
      pleaseSelectTheTime: '请选择时间',
      duplicate: '副本',
      editedSuccessfully: '编辑成功',
      createdSuccessfully: '创建成功',
      goToView: '前往查看',
      learnMore: '了解更多',
      removeFoldersWarning: '文件夹下的知识点会一并删除，确认删除该文件夹？',
      points: '分',
      pointsHasVar: '{0}分',
      onlyTheTaskCompletionStatus: '仅展示有权限查看的执行人任务完成情况',
      times: '{0}次',
      someRhetoricIsNotCompleted: '部分话术未完成',
      noViewingPermission: '无查看权限',
      averageScoreOfAllQuestions: '所有题目的平均分即为课程得分',
      exportSuccessful: '导出成功',
      comment: '点评',
      scoreNotLessThanPoints: '{0}要求：{1}评分不低于 {2} 分',
      practiceAgain: '再次练习',
      startTraining: '开始培训',
      taskHasEnded: '任务已截止',
      taskHasEndedAndCannotBePracticed: '任务已截止, 无法练习',
      answerSheet: '答题卡',
      connecting: '连接中',
      keyIssue: '关键问题',
      noContent: '暂无内容',
      sessionEnded: '会话已结束',
      leavingTheScriptForPractice: '离开剧本对练',
      pleaseOpenTheMicrophone: '请打开麦克风录制权限或检查设备连接状态',
      sessionRecoverySuccessful: '会话恢复成功',
      sureToLeave: '您有未完成的录音，确定离开？',
      detailsOfKnowledgePointExercises: '知识点练习详情',
      conversationWithCustomersByEnteringText: '通过输入文字与客户进行对话',
      sessionModelDoesNotExist: '会话模型不存在，无法继续练习',
      taskHasBeenDeleted: '任务已被删除，无法继续练习',
      sessionCreationFailed: '会话创建失败，请重试',
      sessionRecoveryFailed: '会话恢复失败，请重试',
      connectionHasBeenDisconnected: '连接已断开，请重新连接',
      endSessionEarly: '提前结束会话',
      conductingTestWarning: '请结束当前录音后再进行测试！',
      proceedingWithOtherRecordingsWarning: '请结束当前录音后再进行其他录音！',
      deleteKnowledgePointWarning: '确认删除该知识点？',
      systemVerifyDataTip: '系统会校验数据格式准确性，校验无误后，会将数据导入系统中',
      addConditions: '新增条件',
      conversationMaterialEmptyTip: '对话素材不能为空',
      conversationSceneEmptyTip: '对话场景不能为空',
      readAloud: '朗读',
      recite: '背诵',
      chooseLanguagePlaceholder: '请选择模型语言',
      simplifiedChinese: '简体中文',
      englishLanguage: '英语',
      frenchLanguage: '法语',
      germanLanguage: '德语',
      spanishLanguage: '西班牙语',
      portugueseLanguage: '葡萄牙语',
      italianLanguage: '意大利语',
      train: '培训',
      intelligentGenerationConversation: '智能生成会话',
      taskAllocationTour: '分配的任务在这里进行管理',
      InternalError: '服务器内部错误',
      deleteTemplateConfirm: '确认要删除此模板吗？',
      operationSuccessful: '操作成功',
      noSearchResult: '未搜索到相关结果',
      scoringPointsAreBeingGenerated: '评分要点生成中，生成完毕后才能分配任务',
      unconfiguredScoringRules: '课程不可用，请完善后再分配任务',
      dialogueImportTemplate: '对话导入模板',
      fileFormatTip: '选择xlsx或xls格式的文件',
      importSuccessful: '导入成功',
      taskManagement: '任务管理',
      deletedTask: '该培训任务已被删除',
      switchoverSuccessful: '切换成功',
      voicePromptIsTurnedOn: '话术提示已开启',
      voicePromptIsTurnedOff: '话术提示已关闭',
      theHighestScore: '最高分',
      startNow: '立即开始',
      timedStart: '定时开始',
      confirmDeletion: '是否确认删除？',
      leavingTheCourse: '离开课程',
      successfullyMoved: '移动成功',
      thereIsNoMore: '没有更多了',
      dialogueMaterials: '对话素材',
      editCourse: '编辑课程',
      完成了任务: '完成了任务',
      任务完成通知: '任务完成通知',
      '完成了任务，整个任务已完成': '完成了任务，整个任务已完成',
      完成了你的任务: '完成了你的任务',
      删除了任务: '删除了任务',
      任务删除通知: '任务删除通知',
      分配给我的任务: '分配给我的任务',
      任务执行通知: '任务执行通知',
      给我分配了一个任务: '给我分配了一个任务',
      noTaskCurrently: '当前没有任务',
      goToTraining: '前往培训内容',
      executive: '执行人员',
      completionNotice: '完成通知',
      enforcementNotice: '执行通知',
      allRead: '全部已读',
      task: '任务',
      messageCenter: '消息中心',
      notification: '通知',
      review: '评论',
      tutor: '辅导',
      bulletin: '简报',
      allAreSetToReadSuccessfully: '全部设为已读成功',
      noKnowledgeBaseAvailable: '暂无知识库',
      noNetwork: '无网络，请检查您的网络',
      serverException: '服务器异常，请稍后再试',
      parameterError: '参数错误',
      requestTimeout: '请求超时',
      requestTimeoutTryAgain: '请求超时，请检查网络或稍后再试',
      loginStatusChangeTip: '当前用户登录状态已发生变更，即将回到首页',
      contactAdministrator: '请联系管理员',
      loginFailureTip: '登录失效提醒',
      loginAgain: '重新登录',
      noAccessRights: '无访问权限，请联系管理员',
      customerNotExist: '客户不存在或无权查看',
      scorePointPlaceholder: '请输入评分要点',
      ratingInProgress: '评分中请稍候',
      courseScore: '课程分数',
      courseTotalScoreCalculationRule: '课程总分计算规则',
      theAverageScoreOfEachSentence: '每句得分的平均值',
      theSumOfPointsForEachSentence: '每句得分的和',
      singleSentencePassMark: '单句及格分',
      theProportionOfTheSentenceFullMarks: '该句满分的',
      courseTotalPassingMark: '课程及格分',
      sendAndWrapPlaceholder: 'Enter发送，Ctrl+Enter换行',
      courseMix: '课程组合',
      totalOfSectionsHasVar: '共 {0} 处',
      pointsLostSection: '失分环节',
      courseAnalysis: '课程分析',
      detailsOfPointsLost: '失分详情',
      errantMember: '错误人数',
      commonMistakes: '常见错误',
      peopleMissedVar: '{0}人未命中',
      getCourseScore: '课程得分',
      cumulativeScore: '累计得分',
      cumulativeScoreRange: '累计得分范围',
      overview: '总览',
      noIncorrectAnswers: '暂无错误回答',
      questionScoreRange: '题目得分范围',
      keyPointExtractionFailed: '要点提取失败，话术内容触发风控，建议手动填写要点',
      weight: '权重',
      probability: '说出概率',
      taskRecord: '任务记录',
      courseTaskListTip: '仅展示包含该课程且有权限查看的任务',
      someKeyPointExtractionFailed: '部分要点提取失败,话术内容触发风控,建议手动填写',
      averageStudentScore: '学员平均分',
      averageStudentScoreTip: '仅统计练习成员的平均分',
      coursesVar: '共 {0} 个课程',
      hasReminded: '今日已催促',
      remindAll: '全部催促',
      不提醒: '不提醒',
      '5 minutes': '截止前5分钟提醒',
      '15 minutes': '截止前15分钟提醒',
      '30 minutes': '截止前30分钟提醒',
      '1 hours': '截止前1小时提醒',
      '2 hours': '截止前2小时提醒',
      '4 hours': '截止前4小时提醒',
      '1 days': '截止前1天提醒',
      '3 days': '截止前3天提醒',
      confirmReminderTitle: '全部催促确认',
      confirmReminderContent: '是否向全部未完成成员发送催促消息,提醒其尽快完成任务?',
      提醒你尽快完成任务: '提醒你尽快完成任务',
      expirationReminder: '到期提醒',
      任务到期提醒: '任务到期提醒',
      '任务还有5分钟截止，请及时完成': '任务还有5分钟截止,请及时完成',
      '任务还有15分钟截止，请及时完成': '任务还有15分钟截止,请及时完成',
      '任务还有30分钟截止，请及时完成': '任务还有30分钟截止,请及时完成',
      '任务还有1小时截止，请及时完成': '任务还有1小时截止,请及时完成',
      '任务还有2小时截止，请及时完成': '任务还有2小时截止,请及时完成',
      '任务还有4小时截止，请及时完成': '任务还有4小时截止,请及时完成',
      '任务还有1天截止，请及时完成': '任务还有1天截止,请及时完成',
      '任务还有3天截止，请及时完成': '任务还有3天截止,请及时完成',
      保存为常用执行人组合: '保存为常用执行人组合',
      存为常用: '存为常用',
      导入常用: '导入常用',
      导入: '导入',
      导入常用执行人组合: '导入常用执行人组合',
      组合名称: '组合名称',
      最多保存30个常用组合: '最多保存30个常用组合',
      全部催促成功: '全部催促成功',
      提醒时间: '提醒时间',
      '因成员或权限变动，暂无可用执行人': '因成员或权限变动，暂无可用执行人',
      请输入名称: '请输入名称',
      '请输入分类名称，最多20个字': '请输入分类名称，最多20个字',
      管理任务分类: '管理任务分类',
      '暂无分类，点击左下方添加分类': '暂无分类，点击左下方添加分类',
      添加分类: '添加分类',
      删除分类: '删除分类',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': '删除分类后，已派发任务的该分类标签会一并清除，是否删除？',
      分类: '分类',
      无分类: '无分类',
      任务分类: '任务分类',
      分类数量最多30: '分类数量最多30',
      该名称已重复: '该名称已重复',
      编辑模板公开范围: '编辑模板公开范围',
      '练习平均分=学员所有已完成课程得分的平均分': '平均得分=学员所有已完成课程得分的平均分',
      暂只支持剧本对练课程类型的错误汇总: '暂只支持剧本对练课程类型的错误汇总',
      错误汇总: '错误汇总',
      所属课程: '所属课程',
      youCanAddScripts: '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其朗读话术',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文',
      salespersonSays: '学员说',
      salesSaysConfigure: '配置学员要说的标准话术',
      salesSaysContent: '学员要说的话',
      jumpTip: '您可以填写内容要点，如”辱骂“，若学员发言带有辱骂内容，则会按条件连线跳转到下一步',
      scriptPromptTip: '开启后，学员可以在练习过程中查看话术提示',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': '客户画像字段有助于学员提出挖需问题时，AI客户据此回答',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等',
      startAndEndTimeTip: '到达截止日期后，学员无法再练习课程',
      salesResponse: '学员回答',
      salesSaysContentTip: '请先填写学员要说的话',
      autoCompleteConfirmContent: '有 {0} 个学员说卡片未配置要点，该课程不可用，是否确认离开？您可以点击“补齐”按钮快速补齐要点',
      salesContent: '当学员要说的话',
      finalScoreOfAICustomerCourse: 'AI客户课程最终得分=学员最高一次练习的得分',
      selfIntroduction: '你好，我是{0}公司的学员，有什么可以帮你的吗',
      dialogueExample: '客户接到了学员的电话，学员向客户介绍产品，客户希望充分了解产品的特点是否符合自己的需求，并且希望以一个比较便宜的价格购买到产品。',
      taskScoreDescription: '根据课程配置，课程得分=学员所有题目的平均分或总和',
      scoreReminder: '学员表现低于及格分时，会红色打分提醒其表现不合格',
      salesmanConfigureTip: '当某个节点同时连接了多张学员说卡片时，会根据学员实际发言取最相似的一张学员说卡片进行评分和跳转',
      validateEdgeMessage: '单点发起多条连线时，目标只能是学员说卡片，且最多连接5张',
      放弃练习: '放弃练习',
      暂存离开: '暂存离开',
      '确认提交本次练习？': '确认提交本次练习',
      '其他途径正在进行或已完成本次练习，请稍后重试': '其他途径正在进行或已完成本次练习，请稍后重试',
      继续练习: '继续练习',
      开始第x次练习: '开始第{0}次练习',
      继续第x次练习: '继续第{0}次练习',
      '任务截止前可继续练习，确认离开？': '任务截止前可继续练习，确认离开？',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': '其他途径正在进行或已完成本次练习，当前途径无法继续操作',
      失败提示: '失败提示',
      提前完成练习: '提前完成练习',
      任务截止前可继续本次练习: '任务截止前可继续本次练习',
      退出并清除本次练习记录: '退出并清除本次练习记录',
      确认提交: '确认提交',
      离开练习: '离开练习',
      不显示子分类的课程: '不显示子分类的课程',
      放弃练习成功: '放弃练习成功',
      上传图片: '上传图片',
      最多上传x张图片: '最多上传{0}张图片',
      图片大小不能超过x: '图片大小不能超过{0}',
      请上传图片格式的文件: '请上传图片格式的文件',
      加载失败: '加载失败'
    }
  },
  en: {
    coaching: {
      首页: 'Home',
      培训: 'Training',
      courseManagement: 'Course',
      courseClassification: 'Course Classification',
      searchByCourseName: 'Search by Course Name',
      noResults: 'No relevant results found for "{0}"',
      defaultClassification: 'Default Classification',
      creator: 'Creator',
      pleaSeselectCreator: 'Please select the creator',
      courseType: 'Course Type',
      startDate: 'Start Date',
      endDate: 'End Date',
      newCourse: 'New Course',
      totalNum: 'Total {0}',
      perPage: '{0} per page',
      noCourses: 'No courses available',
      addClassification: 'Add Classification',
      newHasVar: 'Create {0}',
      category: 'Category',
      nameHasVar: '{0} Name',
      cannotBeEmpty: 'Cannot be empty',
      pleaseEnterContent: 'Please enter content',
      publicScope: 'Permission',
      visibleToAll: 'Visible to all',
      visibleToSpecified: 'Visible to specified departments or members',
      pleaseSelectMembers: 'Please select members',
      cancel: 'Cancel',
      confirm: 'Confirm',
      savedSuccessfully: 'Saved successfully',
      rename: 'Rename',
      renameHasVar: 'Rename {0}',
      renameClassification: 'Rename Classification',
      editPublicScope: 'Edit Permission',
      search: 'Search',
      delete: 'Delete',
      note: 'Note',
      deletedSuccessfully: 'Deleted successfully',
      onlySomeCreatorCanModifyTheClassification: 'Only the creator can modify the category',
      newScriptReading: 'New Course-Script Reading',
      newScriptRecitation: 'New Script Recitation',
      newScriptPractice: 'New Script Practice',
      newAICustomer: 'New AI Customer',
      newAICustomerHasVar: 'New AI Customer {0}',
      basicInformation: 'Basic Information',
      courseName: 'Course Name',
      pleaseFillInTheCourseName: 'Please fill in the course name',
      courseNameCannotBeRepeated: 'Course name cannot be duplicated',
      maximumCharacters: 'Maximum {0} characters',
      courseContent: 'Course Content',
      addKnowledgePointsAndScripts: 'Add Knowledge Points and Scripts',
      pleaseAddScriptPoints: 'Please add a script',
      courseRules: 'Course Rules',
      readingRequirements: 'Reading Requirements',
      EachScriptNeedsRead: '{0} requirement: Each paragraph needs to be {1} {2} times, and the score should not be less than {3} points',
      selectScript: 'Select Script',
      save: 'Save',
      newCourseScriptRecitation: 'New Course-Script Recitation',
      courseNameNoRepeat: 'Course name cannot be repeated',
      max20Char: 'Maximum 20 characters',
      pleaseAddScriptPpoints: 'Please add script points',
      recitationRequirements: 'Recitation Requirements',
      eachScriptNeedsToBeRecitedOnce: 'Each script needs to be recited once and each score should not be lower than 60 points (full score: 100)',
      closedBookAssessment: 'Closed-book Assessment',
      newScriptPracticeCourse: 'New Script Practice Course',
      newBlankCourse: 'Create a New Course',
      useTemplate: 'Use',
      undefined: 'TTrainee answers',
      editCourseScriptPractice: 'Edit Course - Script Practice',
      addNode: 'Add Node',
      narration: 'Narration',
      customerSays: 'Customer says',
      addThroughKnowledgeBase: 'Add Knowledge Point',
      scriptPractice: 'Script Practice',
      aiGeneratedCourse: 'AI-generated Course',
      saveAsTemplate: 'Save as template',
      templateName: 'Template name',
      max15Characters: 'Up to 15 characters',
      enterTemplateName: 'Please enter template name',
      templateRemarks: 'Template notes',
      Max30Characters: 'Up to 30 characters',
      scriptPracticeTour1: 'Drag the node to the canvas to create a card of this node type',
      skip: 'Skip',
      next: 'Next',
      scriptPracticeTour2: 'Click to select the card and edit the card content',
      previous: 'Previous',
      scriptPracticeTour3: 'Drag the dot to initiate a connection and connect two cards; select the connection and press the backspace key to delete the connection.',
      scriptPracticeTour4: 'After building the dialogue flow, you can test the dialogue here',
      scriptPracticeTour5: 'Courses with high reusability can be saved as templates',
      finish: 'Finish',
      narrationConfigure: 'Used to prompt users of the scene and customer background information',
      narrationContent: 'Narration Content',
      narrationExample: 'For example: The other party is a first-time customer',
      customerSaysContent: 'What the customer wants to say',
      customerSaysExample: 'For example: Why is the price so high?',
      addScript: 'Add Script',
      customerSaysConfigure: 'When configuring multiple scripts, the virtual customer will randomly say one of them',
      searchScript: 'Search Script',
      searchKnowledgeBaseScript: 'Search for scripts',
      allKnowledgeBase: 'All Knowledge Base',
      checkedScript: 'Selected: 0 scripts',
      searchTips: 'No recommendations, try searching',
      add: 'Add',
      salesSaysExample: 'For example: Our product has excellent quality',
      jumpingRules: 'Jumping Rules',
      normalJump: 'normal jump backwark',
      conditionJump: 'Jump by condition',
      knowledgeBase: 'Knowledge Base',
      knowledgeBaseEmpty: 'The content of the knowledge base is empty',
      importData: 'Import Data',
      downloadTemplateTitle: 'Click the download button to download the template file to be imported',
      downloadTemplate: 'Download Template',
      scriptDialogueTemplate: 'Script Practice Template',
      uploadFileTitle: 'After filling in the file, upload it in the area below',
      uploadFileTip: 'Click or drag the file here to upload',
      supportsExtensions: 'Supports extensions: xls, xlsx',
      courseSettings: 'Course Settings',
      practiceMode: 'Practice Mode',
      voiceMode: 'Voice Mode',
      textMode: 'Text Mode',
      scriptPrompt: 'Script Prompt',
      shortcutKeys: 'Shortcut Keys',
      moveCanvas: 'Move the canvas',
      moveCanvasTip: 'Drag the canvas with the left mouse button',
      zoomCanvas: 'Zoom the canvas',
      zoomCanvasTip: 'Control + mouse scroll',
      boxSelection: 'Box selection / multiple selection',
      boxSelectionTip: 'Shift + mouse',
      copy: 'Copy',
      paste: 'Paste',
      deleteTip: 'Select node card / connection line + backspace key',
      undo: 'Undo',
      redo: 'Redo',
      dialogueTest: 'Dialogue Test',
      conversationEnds: 'Conversation ends',
      retest: 'Retest',
      start: 'Start',
      courseNamePlaceholder: 'Please enter the course name',
      dialogueScene: 'Dialogue Scene',
      sceneCountLimitTips: 'Please add scripts to at least one scene',
      department: 'Department',
      pleaseSelectDepartment: 'Please select department',
      customer: 'Customer',
      sends: 'Seconds',
      today: 'Today',
      startTime: 'Start Time',
      chinese: 'Chinese',
      english: 'English',
      returnToList: 'Return to List',
      restart: 'Restart',
      scriptReading: 'Script Reading',
      scriptRecitation: 'Script Recitation',
      aICustomer: 'AI Customer',
      aICustomerHasVar: 'AI Customer {0}',
      lastWeek: 'Last week',
      lastMonth: 'Last month',
      lastThreeMonths: 'Last three months',
      newTask: 'New Task',
      taskName: 'Task Name',
      learnCourse: 'Learn Course',
      executor: 'Executor',
      startAndEndTime: 'Start and End Time',
      edit: 'Edit',
      changeClassification: 'Change Classification',
      moveTo: 'Move to',
      selectClassification: 'Select Classification',
      deleteCourse: 'Delete Course',
      tipsForDeletingCourses: 'Deleting the course will not affect the tasks that have been assigned. Are you sure you want to delete this course?',
      searchByName: 'Search by Name',
      pleaseSelectExecutor: 'Please Select Executor',
      status: 'Status',
      notStarted: 'Not Started',
      inProgress: 'In Progress',
      completed: 'Completed',
      deadlinePassed: 'Closed',
      aiClient: 'AI Client',
      noTrainingTasksYet: 'No Training Tasks Yet',
      learningCourse: 'Learning Course',
      operation: 'Operation',
      details: 'Details',
      noData: 'No Data',
      assigner: 'Assigner',
      deleteTask: 'Delete Task',
      deleteTaskPrompt: 'The task has already started, do you confirm to delete?',
      deleteSuccessful: 'Delete Successful',
      copyTask: 'Copy Task',
      pleaseEnterTaskName: 'Please Enter Task Name',
      pleaseSelectCourse: 'Please Select Course',
      completionStatus: 'Completion Status',
      taskScoreRange: 'Task Score Range',
      below60Points: 'Below 60 Points',
      above60Points: 'Above 60 Points',
      custom: 'Custom',
      notCompleted: 'Not Completed',
      noScoreYet: 'No Score Yet',
      exportTable: 'Export Table',
      keyScripts: 'Key Scripts',
      sceneAverageScore: 'Scene Average Score: {0} Points',
      standardScript: 'Standard Script',
      intelligentReview: 'Intelligent Review',
      matchingPoints: 'Matching points',
      onlyNone: 'None',
      missingPoints: 'missing points',
      dialogueRecord: 'Dialogue Record',
      aIReview: 'AI Review',
      abilityAnalysis: 'Ability Analysis',
      myTasks: 'My Tasks',
      searchByAssignee: 'Search by Assignee',
      deadline: 'Deadline',
      goPractice: 'Practice',
      back: 'Back',
      assignedBy: 'Assigned by {0}',
      participantsHasVar: '{0} Participants',
      startPractice: 'Start Practice',
      conversationContent: 'Conversation Content',
      endConversation: 'End Conversation',
      endThePracticeEarlyWarning: 'The customer still has some questions unasked, unasked questions will be settled with 0 points, do you want to end the practice early?',
      sendNextWarning: 'After one round of conversation is completed, the next line of dialogue can be sent',
      practiceIsCompleted: 'All questions of concern to the customer have been asked, the practice is completed',
      continuePractice: 'Continue Practice',
      leaveAICustomer: 'Leave AI Customer',
      quitHalfwayWarning: 'If you quit halfway, you need to start over next time, do you want to leave?',
      leave: 'Leave',
      ByUploadingRecording: 'Speak to the customer by uploading a recording',
      gotIt: 'I Got It',
      pleaseStartSpeaking: 'Please Start Speaking',
      voiceNotRecognized: 'No voice detected, please check the microphone',
      话术提示: 'Script Tips',
      backToTaskList: 'Back to Task List',
      viewPracticeDetails: 'View Practice Details',
      completeCoachingTips: 'You have completed this training',
      rePractice: 'Re-practice',
      practiceScore: 'Practice Score',
      preparing: 'Preparing',
      recitationAssessment: 'Recitation Assessment',
      progress: 'Progress',
      deadlineHasVar: '{0} Deadline',
      commonCustomerQuestions: 'Common Customer Questions',
      noQuestions: 'No Questions',
      answeringSkills: 'Answering Skills',
      noAnsweringSkills: 'No Answering Skills',
      finalScore: 'Final Score',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: 'Practice not completed, no score yet',
      scoreDesc: '"If the number of practice times required is 1, the final score = the score of the best practice;If the number of practice times required is N, the final score = the average score of the best N practices"',
      完成练习: 'Complete Practice',
      questionName: 'Question Name',
      questionScore: 'Question Score',
      taskScore: 'Task Score',
      completedQuestionsCount: 'Number of Completed Questions',
      progressCompletion: 'Progress Completion',
      to: ' to ',
      pleaseSelectHasVar: 'Please select {0}',
      all: 'All',
      teamView: 'Team View',
      assignedByMe: 'Assigned by Me',
      score: 'Score',
      practiceRecord: 'Practice Record',
      comprehensiveEvaluation: 'Comprehensive Evaluation',
      noKeyPhrasesAvailable: 'No Key Phrases Available',
      scenarioPhrasesBeingGenerated: 'Scenario Phrases Being Generated',
      notTriggered: 'Not Triggered',
      noCommentsAvailable: 'No Comments Available',
      dialogueRecordBeingGenerated: 'Dialogue Record Being Generated',
      commenting: 'Commenting',
      prompt: 'Prompt',
      exit: 'Exit',
      scenarioPlaceholder: 'Please Add Scenario Phrases',
      scenceNameRepeat: 'Scenario Names Cannot Be Duplicated',
      quickReply: 'Quick Send',
      replyPlaceholder: 'Enter Reply (Press Enter to Send)',
      intelligentGenerating: 'Intelligent Generation in Progress',
      generationFailed: 'Generation Failed',
      clickToRetry: 'Click to Retry',
      generateDialogueTip: 'Please Generate Dialogue First',
      generateNode: 'Generate Node',
      scoringRules: 'Scoring Rules',
      scoreByKeyPoints: 'Score by Points',
      noScoring: 'No Scoring',
      courseUnavailableTip: 'If key points are not configured, the course cannot be used',
      generating: 'Generatin',
      aiGenerateKeyPoint: 'AI generated',
      addKeyPoint: 'Add Key Points',
      copySuccessfully: 'Copy Successful',
      cannotCopyInfo: 'Please Select a Node to Copy',
      cannotPasteInfo: 'Clipboard is Empty, Cannot Paste',
      pasteSuccessfully: 'Paste Successful',
      generatedSuccessfully: 'Generation Successful',
      noNeedCompleteCard: 'No Node Cards Needing Key Point Completion',
      ruleIsGenerating: 'Generating key points',
      whenRuleHit: 'When Hit',
      whenRuleMiss: 'When Not Hit',
      noScoreRuleConfigured: 'No key points',
      addScriptTip: 'Please select the rhetoric to add',
      templateNameTip: 'Please enter the template name',
      inputMaxLength: 'You can enter up to {0} characters',
      scriptLengthLimit: 'Content exceeds the limit of 1000 characters, please reduce and add again',
      addSuccess: 'Added successfully',
      addScriptPlaceholder: 'Please select the rhetoric to add',
      checkedKnowledgeScript: 'Selected: {0} knowledge points',
      smartTrainingUseTip: 'You can drag the components on the left to the canvas to create a card, then connect the cards to complete the dialogue flow configuration',
      roleNamePlaceholder: 'Please enter the role name',
      noConditionsHit: 'When none of the above conditions are met',
      ruleHit: 'Hit',
      ruleMiss: 'Not hit',
      ruleKeywordPlaceholder: 'Fill in the content points, up to 5, Enter to complete',
      templateInstructionsTip: 'Please read the instructions in the template carefully,',
      templateRequiredFormat: 'And fill in the imported data according to the required format',
      autoCompletePoints: 'complete points',
      createCourseScriptPractice: 'New Course - Script Practice',
      passingScore: 'Passing score',
      fullScore: '(Full score: 100)',
      modelLanguage: 'Model language',
      modelLanguageTip: "The model language affects the model's ability to extract and score points in different languages",
      isImporting: 'Importing...',
      ' notRefreshOrLeaveTip': 'Please do not refresh or leave, and keep the network smooth',
      importCompleted: 'Import completed',
      importAgain: 'Import again',
      parsingFailed: 'Parsing failed',
      fileContentNotMatch: 'The content of the file does not match, please modify and re-upload',
      reUpload: 'Re-upload',
      uploadFailed: 'Upload failed',
      fileNotExistTip: 'The file does not exist, please re-upload',
      editTask: 'Edit task',
      expand: 'Expand',
      collapse: 'Collapse',
      creationTime: 'Creation time',
      noMatchingResults: 'No matching results',
      goToCreate: 'Go to create',
      pleaseSelectTheStartTime: 'Please select the start time',
      pleaseSelectTheEndTime: 'Please select the end time',
      onlyMainSpaceAreDisplayed: 'Only members with the current space as the main space are displayed',
      numberOfCoursesCannotExceed: 'The number of courses cannot exceed {0}',
      pleaseSelectTheTime: 'Please select the time',
      duplicate: 'Copy',
      editedSuccessfully: 'Edited successfully',
      createdSuccessfully: 'Created successfully',
      goToView: 'Go to view',
      learnMore: 'Learn more',
      removeFoldersWarning: 'The knowledge points in the folder will be deleted together, confirm to delete this folder?',
      points: 'Points',
      pointsHasVar: '{0} points',
      onlyTheTaskCompletionStatus: 'Only the task completion status of the executor who has the permission to view is displayed',
      times: '{0} times',
      someRhetoricIsNotCompleted: 'Some rhetoric is not completed',
      noViewingPermission: 'No viewing permission',
      averageScoreOfAllQuestions: 'The course score is equal to the average score of all questions',
      exportSuccessful: 'Export successful',
      comment: 'Comment',
      scoreNotLessThanPoints: '{0} requirement: {1} score not less than {2} points',
      practiceAgain: 'Practice again',
      startTraining: 'Start training',
      taskHasEnded: 'The task has ended',
      taskHasEndedAndCannotBePracticed: 'The task has ended, unable to practice',
      answerSheet: 'Answer card',
      connecting: 'Connecting',
      keyIssue: 'Key question',
      noContent: 'No content',
      sessionEnded: 'The conversation has ended',
      leavingTheScriptForPractice: 'Leave script practice',
      pleaseOpenTheMicrophone: 'Please open the microphone recording permission or check the device connection status',
      sessionRecoverySuccessful: 'Conversation recovery successful',
      sureToLeave: 'You have unfinished recordings, are you sure you want to leave?',
      detailsOfKnowledgePointExercises: 'Knowledge point practice details',
      conversationWithCustomersByEnteringText: 'Converse with customers by entering text',
      sessionModelDoesNotExist: 'The conversation model does not exist and cannot continue to practice',
      taskHasBeenDeleted: 'The task has been deleted and cannot continue to practice',
      sessionCreationFailed: 'Failed to create conversation, please try again',
      sessionRecoveryFailed: 'Failed to recover conversation, please try again',
      connectionHasBeenDisconnected: 'The connection has been disconnected, please reconnect',
      endSessionEarly: 'End the conversation early',
      conductingTestWarning: 'Please finish the current recording before testing!',
      proceedingWithOtherRecordingsWarning: 'Please finish the current recording before making other recordings!',
      deleteKnowledgePointWarning: 'Are you sure you want to delete this knowledge point?',
      systemVerifyDataTip: 'The system will verify the accuracy of the data format. After verification without errors, the data will be imported into the system',
      addConditions: 'New conditions',
      conversationMaterialEmptyTip: 'Dialogue material cannot be empty',
      conversationSceneEmptyTip: 'Dialogue scene cannot be empty',
      readAloud: 'Read aloud',
      recite: 'Recite',
      chooseLanguagePlaceholder: 'Please select model language',
      simplifiedChinese: 'Simplified Chinese',
      englishLanguage: 'English',
      frenchLanguage: 'French',
      germanLanguage: 'German',
      spanishLanguage: 'Spanish',
      portugueseLanguage: 'Portuguese',
      italianLanguage: 'Italian',
      train: 'Training',
      intelligentGenerationConversation: 'Intelligent conversation generation',
      taskAllocationTour: 'Task allocation is managed here',
      InternalError: 'Internal server error',
      deleteTemplateConfirm: 'Are you sure you want to delete this template?',
      operationSuccessful: 'Operation successful',
      noSearchResult: 'No relevant results found',
      scoringPointsAreBeingGenerated: 'The scoring points are being generated, tasks can be assigned after completion',
      unconfiguredScoringRules: 'The course is not available, please complete it before assigning tasks',
      dialogueImportTemplate: 'Dialogue Import Template',
      fileFormatTip: 'Choose a file in xlsx or xls format',
      importSuccessful: 'Import successful',
      taskManagement: 'Coach task',
      deletedTask: 'The task has been deleted',
      switchoverSuccessful: 'Switch successful',
      voicePromptIsTurnedOn: 'Speech prompt on',
      voicePromptIsTurnedOff: 'Speech prompt off',
      theHighestScore: 'Highest score',
      startNow: 'Start immediately',
      timedStart: 'Scheduled start',
      confirmDeletion: 'Are you sure you want to delete?',
      leavingTheCourse: 'Leave the course',
      successfullyMoved: 'Move successful',
      thereIsNoMore: 'No more',
      dialogueMaterials: 'Material',
      editCourse: 'Edit Course',
      完成了任务: 'has completed the task',
      任务完成通知: 'Task completion notification',
      '完成了任务，整个任务已完成': 'has completed the task',
      完成了你的任务: 'has completed the task',
      删除了任务: 'has deleted the task',
      任务删除通知: 'Task deletion notification',
      分配给我的任务: 'has assigned me a task',
      任务执行通知: 'Task execution notification',
      给我分配了一个任务: 'has assigned me a task',
      noTaskCurrently: 'Currently, there are no tasks',
      goToTraining: 'Proceed to training content',
      executive: 'Executor',
      completionNotice: 'Completion Notice',
      enforcementNotice: 'Execution Notice',
      allRead: 'All Read',
      task: 'Task',
      messageCenter: 'Message Center',
      notification: 'Notification',
      review: 'Comment',
      tutor: 'Tutoring',
      bulletin: 'Briefing',
      allAreSetToReadSuccessfully: 'Set as Read Successfully',
      noKnowledgeBaseAvailable: 'No available knowledge base',
      noNetwork: 'No network',
      serverException: 'Server exception, please try again later',
      parameterError: 'Parameter error',
      requestTimeout: 'Request Timeout',
      requestTimeoutTryAgain: 'Request timeout, please check the network or try again later',
      loginStatusChangeTip: "The user's login status has changed, will return to the homepage soon",
      contactAdministrator: 'Please contact the administrator',
      loginFailureTip: 'Login invalid reminder',
      loginAgain: 'Log in again',
      noAccessRights: 'No access permission, please contact the administrator',
      customerNotExist: 'The customer does not exist or has no right to view',
      scorePointPlaceholder: 'Enter key points',
      ratingInProgress: 'Please wait for the evaluation',
      courseScore: 'Course Score',
      courseTotalScoreCalculationRule: 'Course Total Score Calculation Rules',
      theAverageScoreOfEachSentence: 'Average score of each sentence',
      theSumOfPointsForEachSentence: 'Sum of scores for each sentence',
      singleSentencePassMark: 'Passing score for a single sentence',
      theProportionOfTheSentenceFullMarks: 'Full score for the sentence',
      courseTotalPassingMark: 'Course passing score',
      sendAndWrapPlaceholder: 'Enter to send, Ctrl+Enter to line break',
      courseMix: 'Course Combination',
      totalOfSectionsHasVar: 'Total of {0} sections',
      pointsLostSection: 'Points Lost Section',
      courseAnalysis: 'Course Analysis',
      detailsOfPointsLost: 'Details of Points Lost',
      errantMember: 'Errant member',
      commonMistakes: 'Common Mistakes',
      peopleMissedVar: '{0} people missed',
      getCourseScore: 'Course Score',
      cumulativeScore: 'Cumulative Score',
      cumulativeScoreRange: 'Score Range',
      overview: 'Overview',
      noIncorrectAnswers: 'No Incorrect Answers',
      questionScoreRange: 'Topic Scoring Range',
      keyPointExtractionFailed: 'Key point extraction failed due to the presence of sensitive vocabulary; it is recommended to manually fill in the key points',
      weight: 'Weight',
      probability: 'Probability',
      taskRecord: 'Task Record',
      courseTaskListTip: 'Only display tasks that include this course and for which there is permission to view',
      someKeyPointExtractionFailed: 'Partial key points extraction failed, the script content triggered risk control, manual input is recommended',
      averageStudentScore: 'Average Trainee Score',
      averageStudentScoreTip: 'Only the scores of practice members are calculated',
      coursesVar: '{0} courses',
      hasReminded: "Today's Reminders Sent",
      remindAll: 'Remind All',
      不提醒: 'No Reminder',
      '5 minutes': '5-minute deadline reminder',
      '15 minutes': '15-minute deadline reminder',
      '30 minutes': '30-minute deadline reminder',
      '1 hours': '1-hour deadline reminder',
      '2 hours': '2-hour deadline reminder',
      '4 hours': '4-hour deadline reminder',
      '1 days': '1-day deadline reminder',
      '3 days': '3-day deadline reminder',
      confirmReminderTitle: 'Confirm Reminder',
      confirmReminderContent: 'Would you like to send a reminder to members who have not completed the task to finish it as soon as possible?',
      提醒你尽快完成任务: 'reminds you to complete the task promptly.',
      expirationReminder: 'Expiration Reminder',
      任务到期提醒: 'Expiration Reminder',
      '任务还有5分钟截止，请及时完成': 'Task ends in 5 minutes, please complete promptly.',
      '任务还有15分钟截止，请及时完成': 'Task ends in 15 minutes, please complete promptly.',
      '任务还有30分钟截止，请及时完成': 'Task ends in 30 minutes, please complete promptly.',
      '任务还有1小时截止，请及时完成': 'Task ends in 1 hour, please complete promptly.',
      '任务还有2小时截止，请及时完成': 'Task ends in 2 hours, please complete promptly.',
      '任务还有4小时截止，请及时完成': 'Task ends in 4 hours, please complete promptly.',
      '任务还有1天截止，请及时完成': 'Task ends in 1 day, please complete promptly.',
      '任务还有3天截止，请及时完成': 'Task ends in 3 days, please complete promptly.',
      保存为常用执行人组合: 'Save as Favorite Combo',
      存为常用: 'Save as Favorite',
      导入常用: 'Import Favorites',
      导入: 'Import',
      导入常用执行人组合: 'Import Favorite Combo',
      组合名称: 'Combo Name',
      最多保存30个常用组合: 'Save up to 30 Favorite Combos',
      全部催促成功: 'Reminder sent',
      提醒时间: 'reminder time',
      '因成员或权限变动，暂无可用执行人': 'Due to changes in membership or permissions, there are temporarily no available executors',
      请输入名称: 'Please enter a name',
      '请输入分类名称，最多20个字': 'Please enter the category name, up to 20 characters',
      管理任务分类: 'Task Category Management',
      '暂无分类，点击左下方添加分类': 'No categories available, click the bottom left to add a category',
      添加分类: 'Add Category',
      删除分类: 'Delete Category',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'After deleting a category, the category tags of any assigned tasks will also be removed. Do you want to delete?',
      分类: 'Category',
      无分类: 'Uncategorized',
      任务分类: 'Task Category',
      分类数量最多30: 'A maximum of 30 categories',
      该名称已重复: 'The name has already been duplicated',
      编辑模板公开范围: 'Edit the Public Scope of the Template',
      '练习平均分=学员所有已完成课程得分的平均分': 'average score= average of all completed course scores for the participant',
      暂只支持剧本对练课程类型的错误汇总: 'Summary of errors for Script Practice course types only, for the time being',
      错误汇总: 'Error Summary',
      所属课程: 'Course',
      youCanAddScripts: 'You can add scripts to the course and assign the course to trainees through tasks, requiring them to read the scripts',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'You can add scripts to the course and assign the course to trainees through tasks, requiring them to recite the scripts',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': 'Once enabled, trainees can only see the first 10 characters of the script before practicing, and cannot view the entire script',
      salespersonSays: 'Trainee says',
      salesSaysConfigure: 'Configure the standard script for the trainee to say',
      salesSaysContent: 'What the trainee needs to say',
      jumpTip: 'You can fill in key points, such as "insult". If the trainee\'s speech contains insulting content, it will jump to the next step according to the conditions',
      scriptPromptTip: 'Once enabled, trainees can view script prompts during practice',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'AI customers will dialogue with trainees based on the script of the knowledge base you choose, initiate questions and score.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Establish different dialogue scenarios, add scripts to the scenarios, and AI customers will ask script questions to assess trainees',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'Customer portrait fields help when trainees ask probing questions, AI customers answer based on this',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': 'At the beginning of the dialogue, you can explain information to trainees through asides, such as practice precautions, practice background information, etc.',
      startAndEndTimeTip: 'After the deadline, trainees can no longer practice the course',
      salesResponse: 'Trainee answers',
      salesSaysContentTip: 'Please fill in what the trainee needs to say first',
      autoCompleteConfirmContent: 'There are {0} trainee speaking cards without key points configured, this course is not available, do you confirm to leave? You can click the "Complete" button to quickly complete the key points',
      salesContent: 'When the trainee needs to say something',
      finalScoreOfAICustomerCourse: "The final score of the AI customer course = the highest score of the trainee's practice",
      selfIntroduction: 'Hello, I am a trainee of {0} company, what can I help you with?',
      dialogueExample: 'The customer received a call from the trainee, the trainee introduced the product to the customer, the customer hopes to fully understand whether the product features meet their needs, and hopes to buy the product at a cheaper price.',
      taskScoreDescription: 'According to the course configuration, the course score = the average or total score of all trainee questions',
      scoreReminder: "When a trainee's performance is below the passing score, a red score will be given to remind them that their performance is not qualified",
      salesmanConfigureTip: 'When a node is connected to multiple trainee speaking cards at the same time, the most similar student speaking card will be selected for scoring and jumping based on the actual speech of the student',
      validateEdgeMessage: 'When multiple lines are initiated from a single point, the target can only be a trainee speaking card, and a maximum of 5 can be connected.',
      放弃练习: 'Abandon Practice',
      暂存离开: 'Save and Exit',
      '确认提交本次练习？': 'Confirm Submission',
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Exercise in progress or already completed elsewhere, please retry later',
      继续练习: 'Continue Practice',
      开始第x次练习: 'Start Exercise {0}',
      继续第x次练习: 'Continue Exercise {0}',
      '任务截止前可继续练习，确认离开？': 'You can continue practicing until the deadline, confirm exit?',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Exercise in progress or completed elsewhere, unable to continue on this path',
      失败提示: 'Failure Notice',
      提前完成练习: 'Complete Practice Early',
      任务截止前可继续本次练习: 'Continue this practice until the deadline',
      退出并清除本次练习记录: 'Exit and Clear This Practice Record',
      确认提交: 'Submit',
      离开练习: 'Exit Practice',
      不显示子分类的课程: 'Hide Subcategories',
      放弃练习成功: 'Abandon Success',
      上传图片: 'Upload Image',
      最多上传x张图片: 'Maximum of {0} images can be uploaded',
      图片大小不能超过x: 'Image size must not exceed {0}',
      请上传图片格式的文件: 'Please upload a file in image format',
      加载失败: 'Loading failed'
    }
  },
  fr: {
    coaching: {
      首页: 'Accueil',
      培训: 'Formation',
      courseManagement: 'Cours',
      courseClassification: 'Classification des cours',
      searchByCourseName: 'Recherche par nom de cours',
      noResults: 'Aucun résultat trouvé pour "{0}"',
      defaultClassification: 'Catégorie par défaut',
      creator: 'Créateur',
      pleaSeselectCreator: 'Veuillez choisir un créateur',
      courseType: 'Type de cours',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      newCourse: 'Créer un nouveau cours',
      totalNum: 'Total de {0}',
      perPage: '{0} par page',
      noCourses: 'Pas de cours pour le moment',
      addClassification: 'Ajouter une catégorie',
      newHasVar: 'Créer {0}',
      category: 'Catégorie',
      nameHasVar: '{0} Nom',
      cannotBeEmpty: 'Ne peut pas être vide',
      pleaseEnterContent: 'Veuillez entrer le contenu',
      publicScope: 'Autorisations',
      visibleToAll: 'Visible pour tous',
      visibleToSpecified: 'Visible pour les départements ou membres spécifiques',
      pleaseSelectMembers: 'Veuillez choisir un membre',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      savedSuccessfully: 'Sauvegardé avec succès',
      rename: 'Renommer',
      renameHasVar: 'Renommer {0}',
      renameClassification: 'Renommer la catégorie',
      editPublicScope: 'Modifier les autorisations',
      search: 'Rechercher',
      delete: 'Supprimer',
      note: 'Remarque',
      deleteCategoryWarning: "Après la suppression d'une catégorie, les cours de cette catégorie seront transférés à la catégorie supérieure ou à la catégorie par défaut, voulez-vous supprimer",
      deletedSuccessfully: 'Supprimé avec succès',
      onlySomeCreatorCanModifyTheClassification: 'Seul le créateur peut modifier la catégorie',
      newScriptReading: 'Créer un nouveau cours - Lecture de script',
      newScriptRecitation: 'Nouvelle récitation de script',
      newScriptPractice: 'Nouvelle pratique de script',
      newAICustomer: 'Créer un nouveau client AI',
      newAICustomerHasVar: 'Créer un nouveau client AI {0}',
      basicInformation: 'Informations de base',
      courseName: 'Nom du cours',
      pleaseFillInTheCourseName: 'Veuillez entrer le nom du cours',
      courseNameCannotBeRepeated: 'Le nom du cours ne peut pas être dupliqué',
      maximumCharacters: 'Entrée maximale de {0} caractères',
      courseContent: 'Contenu du cours',
      addKnowledgePointsAndScripts: 'Ajouter un point de connaissance du script',
      pleaseAddScriptPoints: 'Veuillez ajouter un script',
      courseRules: 'Règles du cours',
      readingRequirements: 'Exigences de lecture',
      EachScriptNeedsRead: '{0} exigence: Chaque paragraphe doit être {1} {2} fois, et le score ne doit pas être inférieur à {3} points',
      selectScript: 'Choisir un script',
      save: 'Sauvegarder',
      newCourseScriptRecitation: 'Créer un nouveau cours - Récitation de script',
      courseNameNoRepeat: 'Le nom du cours ne peut pas être dupliqué',
      max20Char: 'Entrée maximale de 20 caractères',
      pleaseAddScriptPpoints: 'Veuillez ajouter un point de script',
      recitationRequirements: 'Exigences de récitation',
      eachScriptNeedsToBeRecitedOnce: 'Chaque section du script doit être lue une fois et chaque note ne doit pas être inférieure à 60 points (score total : 100)',
      closedBookAssessment: 'Examen à livre fermé',
      newScriptPracticeCourse: 'Nouveau cours de pratique de script',
      newBlankCourse: 'Créer un nouveau cours',
      useTemplate: 'utiliser',
      undefined: "Réponses de l'étudiant",
      editCourseScriptPractice: 'Modifier le cours - Pratique de script',
      addNode: 'Ajouter un nœud',
      narration: 'Narration',
      customerSays: 'Le client dit',
      addThroughKnowledgeBase: 'Ajouter à partir de la base de connaissances',
      scriptPractice: 'Pratique de script',
      aiGeneratedCourse: 'Générer un cours IA',
      saveAsTemplate: 'Enregistrer comme modèle',
      templateName: 'Nom du modèle',
      max15Characters: 'Entrée maximale de 15 caractères',
      enterTemplateName: 'Veuillez entrer le nom du modèle',
      templateRemarks: 'Remarques sur le modèle',
      Max30Characters: 'Entrée maximale de 30 caractères',
      scriptPracticeTour1: 'Glissez et déposez le nœud sur le canevas pour créer une carte de ce type de nœud',
      skip: 'Passer',
      next: 'Étape suivante',
      scriptPracticeTour2: 'Cliquez pour sélectionner la carte, vous pouvez éditer le contenu de la carte',
      previous: 'Étape précédente',
      scriptPracticeTour3: 'Glissez le point pour créer une ligne, connectez deux cartes ; appuyez sur la touche de retour pour supprimer la ligne sélectionnée.',
      scriptPracticeTour4: 'Une fois le flux de dialogue construit, vous pouvez tester le dialogue ici',
      scriptPracticeTour5: 'Les cours à haute réutilisabilité peuvent être enregistrés comme modèles',
      finish: 'Terminer',
      narrationConfigure: "Utilisé pour indiquer à l'utilisateur le scénario, le contexte du client, etc.",
      narrationContent: 'Contenu de la narration',
      narrationExample: "Par exemple : L'autre partie est un premier acheteur",
      customerSaysContent: 'Ce que le client doit dire',
      customerSaysExample: 'Par exemple : Pourquoi le prix est-il si élevé ?',
      addScript: 'Ajouter un nouveau script',
      customerSaysConfigure: "Lors de la configuration de plusieurs scripts, le client virtuel dira l'un d'eux au hasard",
      searchScript: 'Rechercher un script',
      searchKnowledgeBaseScript: 'Rechercher un script',
      allKnowledgeBase: 'Toute la base de connaissances',
      checkedScript: 'Sélectionné : 0 script',
      searchTips: 'Pas de recommandation pour le moment, essayez de rechercher ~',
      add: 'Ajouter',
      salesSaysExample: 'Par exemple : La qualité de nos produits est excellente',
      jumpingRules: 'Règles de redirection',
      normalJump: 'saut normal en arrière',
      conditionJump: 'Redirection conditionnelle',
      knowledgeBase: 'Base de connaissances',
      knowledgeBaseEmpty: 'Le contenu de la base de connaissances est vide',
      importData: 'Importer des données',
      downloadTemplateTitle: "Cliquez sur le bouton de téléchargement pour télécharger le fichier modèle nécessaire à l'importation",
      downloadTemplate: 'Télécharger le modèle',
      scriptDialogueTemplate: 'Modèle de pratique de script',
      uploadFileTitle: 'Une fois le fichier rempli, téléchargez-le dans la zone ci-dessous',
      uploadFileTip: 'Cliquez ou faites glisser le fichier ici pour le télécharger',
      supportsExtensions: 'Extensions supportées : xls, xlsx',
      courseSettings: 'Paramètres du cours',
      practiceMode: "Mode d'entraînement",
      voiceMode: 'Mode vocal',
      textMode: 'Mode texte',
      scriptPrompt: 'Conseils de discours',
      shortcutKeys: 'Raccourcis',
      moveCanvas: 'Déplacer le canevas',
      moveCanvasTip: 'Faites glisser le canevas avec le bouton gauche de la souris',
      zoomCanvas: 'Zoomer sur le canevas',
      zoomCanvasTip: 'Control + défilement de la souris',
      boxSelection: 'Sélection multiple / encadrée',
      boxSelectionTip: 'Shift + souris',
      copy: 'Copier',
      paste: 'Coller',
      deleteTip: 'Sélectionnez la carte de nœud / ligne de connexion + touche de retour',
      undo: 'Annuler',
      redo: 'Refaire',
      dialogueTest: 'Test de dialogue',
      conversationEnds: 'Fin de la conversation',
      retest: 'Re-tester',
      start: 'Commencer',
      courseNamePlaceholder: 'Veuillez entrer le nom du cours',
      dialogueScene: 'Scénario de dialogue',
      sceneCountLimitTips: 'Veuillez ajouter un discours à au moins un scénario',
      department: 'Département',
      pleaseSelectDepartment: 'Veuillez sélectionner un département',
      customer: 'Client',
      sends: 'Seconde',
      today: "Aujourd'hui",
      startTime: 'Date de début',
      chinese: 'Chinois',
      english: 'Anglais',
      returnToList: 'Retour à la liste',
      restart: 'Recommencer',
      scriptReading: 'Lecture de script',
      scriptRecitation: 'Récitation de script',
      aICustomer: 'IA client',
      aICustomerHasVar: 'IA client {0}',
      lastWeek: 'La semaine dernière',
      lastMonth: 'Le mois dernier',
      lastThreeMonths: 'Les trois derniers mois',
      newTask: 'Nouvelle tâche',
      taskName: 'Nom de la tâche',
      learnCourse: "Cours d'apprentissage",
      executor: 'Exécutant',
      startAndEndTime: 'Dates de début et de fin',
      edit: 'Éditer',
      changeClassification: 'Changer de catégorie',
      moveTo: 'Déplacer vers',
      selectClassification: 'Sélectionner une catégorie',
      deleteCourse: 'Supprimer le cours',
      tipsForDeletingCourses: "La suppression du cours n'affectera pas les tâches déjà attribuées, voulez-vous supprimer ce cours ?",
      searchByName: 'Rechercher par nom',
      pleaseSelectExecutor: "Veuillez sélectionner l'exécutant",
      status: 'Statut',
      notStarted: 'Non commencé',
      inProgress: 'En cours',
      completed: 'Terminé',
      deadlinePassed: 'Fermé',
      aiClient: 'Client IA',
      noTrainingTasksYet: "Il n'y a pas encore de tâche de formation",
      learningCourse: "Cours d'apprentissage",
      operation: 'Opération',
      details: 'Détails',
      noData: 'Pas de données',
      assigner: 'Personne assignée',
      deleteTask: 'Supprimer la tâche',
      deleteTaskPrompt: 'La tâche a déjà commencé, confirmez-vous la suppression ?',
      deleteSuccessful: 'Suppression réussie',
      copyTask: 'Copier la tâche',
      pleaseEnterTaskName: 'Veuillez entrer le nom de la tâche',
      pleaseSelectCourse: 'Veuillez sélectionner un cours',
      completionStatus: "Statut d'achèvement",
      taskScoreRange: 'Plage de scores de tâches',
      below60Points: 'Moins de 60 points',
      above60Points: 'Plus de 60 points',
      custom: 'Personnalisé',
      notCompleted: 'Non terminé',
      noScoreYet: 'Pas de score pour le moment',
      exportTable: 'Exporter le tableau',
      keyScripts: 'Script clé',
      sceneAverageScore: 'Score moyen de la scène : {0} points',
      standardScript: 'Script standard',
      intelligentReview: 'Critique intelligente',
      matchingPoints: 'Points touchés',
      onlyNone: 'Aucun',
      missingPoints: 'Points manqués',
      dialogueRecord: 'Enregistrement de la conversation',
      aIReview: 'Critique IA',
      abilityAnalysis: 'Analyse des compétences',
      myTasks: 'Ma mission',
      searchByAssignee: 'Recherche par personne assignée',
      deadline: 'Date limite',
      goPractice: 'Pratique',
      back: 'Retour',
      assignedBy: 'Assigné par {0}',
      participantsHasVar: '{0} personnes participent',
      startPractice: 'Commencer la pratique',
      conversationContent: 'Contenu de la conversation',
      endConversation: 'Fin de la conversation',
      endThePracticeEarlyWarning: "Le client a encore des questions non posées, les questions non posées seront réglées à 0 point, voulez-vous terminer la pratique à l'avance ?",
      sendNextWarning: "Après qu'un tour de conversation soit terminé, la prochaine réplique peut être envoyée",
      practiceIsCompleted: 'Toutes les questions qui intéressent le client ont été posées, la pratique est terminée',
      continuePractice: 'Continuer la pratique',
      leaveAICustomer: 'Quitter le client AI',
      quitHalfwayWarning: 'Si vous quittez en cours de route, vous devrez recommencer la pratique la prochaine fois, voulez-vous partir ?',
      leave: 'Partir',
      ByUploadingRecording: 'Dialoguer avec le client en téléchargeant un enregistrement',
      gotIt: 'Je comprends',
      pleaseStartSpeaking: 'Veuillez commencer à parler',
      voiceNotRecognized: 'Aucun son détecté, veuillez vérifier le microphone',
      话术提示: 'Conseil de script',
      backToTaskList: 'Retour à la liste des tâches',
      viewPracticeDetails: 'Voir les détails de la pratique',
      completeCoachingTips: 'Vous avez terminé cette formation',
      rePractice: "Répéter l'exercice",
      practiceScore: 'Score de pratique',
      preparing: 'En préparation',
      recitationAssessment: 'Évaluation de récitation',
      progress: 'Progression',
      deadlineHasVar: '{0} Date limite',
      commonCustomerQuestions: 'Questions fréquemment posées par les clients',
      noQuestions: 'Pas de questions',
      answeringSkills: 'Conseils de réponse',
      noAnsweringSkills: 'Pas de conseils de réponse',
      finalScore: 'Score final',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: "La pratique n'est pas terminée, pas de score pour le moment",
      scoreDesc: 'Si le nombre de pratiques requises est de 1, le score final = le score de la meilleure pratique ;Si le nombre de pratiques requises est de N, le score final = la moyenne des scores des N meilleures pratiques"',
      完成练习: 'Terminer la pratique',
      questionName: 'Nom de la question',
      questionScore: 'Score de la question',
      taskScore: 'Score de la tâche',
      completedQuestionsCount: 'Nombre de questions terminées',
      progressCompletion: 'Progression accomplie',
      to: ' à ',
      pleaseSelectHasVar: 'Veuillez sélectionner {0}',
      all: 'Tout',
      teamView: "Vue d'équipe",
      assignedByMe: "Ce que j'ai assigné",
      score: 'Score',
      practiceRecord: "Enregistrement d'exercice",
      comprehensiveEvaluation: 'Évaluation globale',
      noKeyPhrasesAvailable: 'Pas de phrases clés pour le moment',
      scenarioPhrasesBeingGenerated: 'Génération de phrases de scénario en cours',
      notTriggered: 'Non déclenché',
      noCommentsAvailable: 'Pas de commentaires pour le moment',
      dialogueRecordBeingGenerated: "Génération d'enregistrements de dialogue en cours",
      commenting: 'Commentaires en cours',
      prompt: 'Conseil',
      exit: 'Sortir',
      scenarioPlaceholder: 'Veuillez ajouter des phrases de scénario',
      scenceNameRepeat: 'Le nom du scénario ne peut pas être dupliqué',
      quickReply: 'Envoi rapide',
      replyPlaceholder: 'Entrez une réponse (Envoyer avec Enter)',
      intelligentGenerating: 'Génération intelligente en cours',
      generationFailed: 'Échec de la génération',
      clickToRetry: 'Cliquez pour réessayer',
      generateDialogueTip: "Veuillez d'abord générer un dialogue",
      generateNode: 'Génération de nœuds',
      scoringRules: 'Règles de notation',
      scoreByKeyPoints: 'Score par points',
      noScoring: 'Ne pas noter',
      courseUnavailableTip: "Si aucun point clé n'est configuré, le cours ne pourra pas être utilisé",
      generating: 'Génération en cours',
      aiGenerateKeyPoint: "Généré par l'IA",
      addKeyPoint: 'Ajouter des points clés',
      copySuccessfully: 'Copie réussie',
      cannotCopyInfo: "Veuillez d'abord sélectionner un nœud avant de copier",
      cannotPasteInfo: 'Le presse-papiers est vide, impossible de coller',
      pasteSuccessfully: 'Collage réussi',
      generatedSuccessfully: 'Génération réussie',
      noNeedCompleteCard: 'Pas de cartes de nœuds nécessitant des points clés pour le moment',
      ruleIsGenerating: 'Génération de points clés',
      whenRuleHit: "Lorsqu'il est touché",
      whenRuleMiss: "Lorsqu'il n'est pas touché",
      noScoreRuleConfigured: 'Pas de points clés',
      addScriptTip: 'Veuillez sélectionner le discours à ajouter',
      templateNameTip: 'Veuillez entrer le nom du modèle',
      inputMaxLength: "Vous pouvez entrer jusqu'à {0} caractères",
      scriptLengthLimit: "Le contenu dépasse la limite de 1000 caractères, veuillez le réduire avant d'ajouter",
      addSuccess: 'Ajout réussi',
      addScriptPlaceholder: 'Veuillez sélectionner le discours à ajouter',
      checkedKnowledgeScript: 'Sélectionné : {0} points de connaissance',
      smartTrainingUseTip: 'Vous pouvez faire glisser les composants de gauche sur la toile pour créer une carte, puis connecter les cartes pour configurer le flux de dialogue',
      roleNamePlaceholder: 'Veuillez entrer le nom du personnage',
      noConditionsHit: "Lorsque aucune des conditions ci-dessus n'est remplie",
      ruleHit: 'Touché',
      ruleMiss: 'Non touché',
      ruleKeywordPlaceholder: "Remplissez les points clés du contenu, jusqu'à 5, Enter pour terminer",
      templateInstructionsTip: 'Veuillez lire attentivement les instructions du modèle,',
      templateRequiredFormat: 'et remplir les données importées selon le format requis',
      autoCompletePoints: 'points complets',
      createCourseScriptPractice: 'Nouveau cours - Pratique de scénario',
      passingScore: 'Score de passage',
      fullScore: '(Note maximale : 100)',
      modelLanguage: 'Langue du modèle',
      modelLanguageTip: 'La langue du modèle affecte la capacité du modèle à extraire et à noter les points clés dans différentes langues',
      isImporting: 'Importation en cours...',
      ' notRefreshOrLeaveTip': 'Ne rafraîchissez pas ou ne quittez pas, et assurez-vous que votre connexion Internet est stable',
      importCompleted: 'Importation terminée',
      importAgain: 'Importer à nouveau',
      parsingFailed: "Échec de l'analyse",
      fileContentNotMatch: 'Le contenu du fichier ne correspond pas, veuillez le modifier et le télécharger à nouveau',
      reUpload: 'Télécharger à nouveau',
      uploadFailed: 'Échec du téléchargement',
      fileNotExistTip: "Le fichier n'existe pas, veuillez le télécharger à nouveau",
      editTask: 'Modifier la tâche',
      expand: 'Développer',
      collapse: 'Réduire',
      creationTime: 'Date de création',
      noMatchingResults: 'Aucun résultat correspondant',
      goToCreate: 'Aller à la création',
      pleaseSelectTheStartTime: 'Veuillez sélectionner une date de début',
      pleaseSelectTheEndTime: 'Veuillez sélectionner une date de fin',
      onlyMainSpaceAreDisplayed: "Seuls les membres dont l'espace principal est l'espace actuel sont affichés",
      numberOfCoursesCannotExceed: 'Le nombre de cours ne peut pas dépasser {0}',
      pleaseSelectTheTime: 'Veuillez sélectionner une date',
      duplicate: 'Copie',
      editedSuccessfully: 'Modification réussie',
      createdSuccessfully: 'Création réussie',
      goToView: 'Aller voir',
      learnMore: 'En savoir plus',
      removeFoldersWarning: 'Les points de connaissance dans le dossier seront également supprimés, confirmez-vous la suppression de ce dossier ?',
      points: 'Points',
      pointsHasVar: '{0} points',
      onlyTheTaskCompletionStatus: 'Seules les performances des exécutants autorisés à voir sont affichées',
      times: '{0} fois',
      someRhetoricIsNotCompleted: 'Certains discours ne sont pas terminés',
      noViewingPermission: 'Pas de permission de visualisation',
      averageScoreOfAllQuestions: 'La note du cours est égale à la moyenne de tous les scores des questions',
      exportSuccessful: 'Exportation réussie',
      comment: 'Critique',
      scoreNotLessThanPoints: '{0} exigences : {1} score pas moins de {2} points',
      practiceAgain: 'Pratiquer à nouveau',
      startTraining: 'Commencer la formation',
      taskHasEnded: 'La tâche est terminée',
      taskHasEndedAndCannotBePracticed: 'La tâche est terminée, impossible de pratiquer',
      answerSheet: 'Carte de réponse',
      connecting: 'Connexion en cours',
      keyIssue: 'Question clé',
      noContent: 'Pas de contenu',
      sessionEnded: 'La conversation est terminée',
      leavingTheScriptForPractice: 'Quitter la pratique du script',
      pleaseOpenTheMicrophone: "Veuillez activer l'autorisation d'enregistrement du microphone ou vérifier l'état de la connexion de l'appareil",
      sessionRecoverySuccessful: 'La conversation a été restaurée avec succès',
      sureToLeave: 'Vous avez un enregistrement non terminé, êtes-vous sûr de vouloir partir ?',
      detailsOfKnowledgePointExercises: 'Détails de la pratique des points de connaissance',
      conversationWithCustomersByEnteringText: 'Dialoguer avec le client en entrant du texte',
      sessionModelDoesNotExist: "Le modèle de conversation n'existe pas, impossible de continuer à pratiquer",
      taskHasBeenDeleted: 'La tâche a été supprimée, impossible de continuer à pratiquer',
      sessionCreationFailed: 'Échec de la création de la conversation, veuillez réessayer',
      sessionRecoveryFailed: 'Échec de la restauration de la conversation, veuillez réessayer',
      connectionHasBeenDisconnected: 'La connexion a été interrompue, veuillez vous reconnecter',
      endSessionEarly: "Terminer la conversation à l'avance",
      conductingTestWarning: "Veuillez terminer l'enregistrement actuel avant de tester !",
      proceedingWithOtherRecordingsWarning: "Veuillez terminer l'enregistrement actuel avant de faire d'autres enregistrements !",
      deleteKnowledgePointWarning: 'Confirmez-vous la suppression de ce point de connaissance ?',
      systemVerifyDataTip: "Le système vérifiera l'exactitude du format des données. Après vérification sans erreurs, les données seront importées dans le système",
      addConditions: 'Nouvelle condition',
      conversationMaterialEmptyTip: 'Le matériel de dialogue ne peut pas être vide',
      conversationSceneEmptyTip: 'Le scénario de dialogue ne peut pas être vide',
      readAloud: 'Lecture',
      recite: 'Récitation',
      chooseLanguagePlaceholder: 'Veuillez choisir la langue du modèle',
      simplifiedChinese: 'Chinois simplifié',
      englishLanguage: 'Anglais',
      frenchLanguage: 'Français',
      germanLanguage: 'Allemand',
      spanishLanguage: 'Espagnol',
      portugueseLanguage: 'Portugais',
      italianLanguage: 'Italien',
      train: 'Formation',
      intelligentGenerationConversation: 'Génération de dialogue intelligente',
      taskAllocationTour: 'La gestion des tâches assignées se fait ici',
      InternalError: 'Erreur interne du serveur',
      deleteTemplateConfirm: 'Confirmez-vous la suppression de ce modèle ?',
      operationSuccessful: 'Opération réussie',
      noSearchResult: 'Aucun résultat pertinent trouvé',
      scoringPointsAreBeingGenerated: "Génération des points de notation en cours, les tâches ne peuvent être attribuées qu'après leur achèvement",
      unconfiguredScoringRules: "Le cours n'est pas disponible, veuillez le terminer avant d'attribuer des tâches",
      dialogueImportTemplate: "Modèle d'importation de dialogue",
      fileFormatTip: 'Choisissez un fichier au format xlsx ou xls',
      importSuccessful: 'Importation réussie',
      taskManagement: 'Tâche',
      deletedTask: 'La tâche a été supprimée',
      switchoverSuccessful: 'Changement réussi',
      voicePromptIsTurnedOn: 'Prompt vocal activé',
      voicePromptIsTurnedOff: 'Prompt vocal désactivé',
      theHighestScore: 'record',
      startNow: 'Commencer immédiatement',
      timedStart: "Commencer à l'heure prévue",
      confirmDeletion: 'Êtes-vous sûr de vouloir supprimer?',
      leavingTheCourse: 'Quitter le cours',
      successfullyMoved: 'Déplacement réussi',
      thereIsNoMore: 'Plus rien',
      dialogueMaterials: 'Matériel',
      editCourse: 'Modifier le cours',
      完成了任务: 'a terminé la tâche',
      任务完成通知: 'Notification de fin de tâche',
      '完成了任务，整个任务已完成': 'a terminé la tâche',
      完成了你的任务: 'a terminé la tâche',
      删除了任务: ' a supprimé la tâche',
      任务删除通知: 'Notification de suppression de tâche',
      分配给我的任务: "m'a assigné une tâche",
      任务执行通知: "Notification d'exécution de tâche",
      给我分配了一个任务: "m'a assigné une tâche",
      noTaskCurrently: "Actuellement, il n'y a pas de tâches",
      goToTraining: 'Passez au contenu de la formation',
      executive: 'Exécutant',
      completionNotice: 'Avis de fin',
      enforcementNotice: "Avis d'exécution",
      allRead: 'Tout lu',
      task: 'Tâche',
      messageCenter: 'Centre de messages',
      notification: 'Notification',
      review: 'Commentaire',
      tutor: 'Tutorat',
      bulletin: 'Briefing',
      allAreSetToReadSuccessfully: 'Défini comme lu avec succès',
      noKnowledgeBaseAvailable: 'Aucune base de connaissances disponible',
      noNetwork: 'Pas de réseau',
      serverException: 'Exception du serveur, veuillez réessayer plus tard',
      parameterError: 'Erreur de paramètre',
      requestTimeout: 'Demande expirée',
      requestTimeoutTryAgain: 'La demande a expiré, veuillez vérifier le réseau ou réessayer plus tard',
      loginStatusChangeTip: "Le statut de connexion de l'utilisateur a changé, retour à la page d'accueil bientôt",
      contactAdministrator: "Veuillez contacter l'administrateur",
      loginFailureTip: 'Rappel de connexion invalide',
      loginAgain: 'Se connecter à nouveau',
      noAccessRights: "Pas de permission d'accès, veuillez contacter l'administrateur",
      customerNotExist: "Le client n'existe pas ou n'a pas le droit de voir",
      scorePointPlaceholder: 'Entrez les points clés',
      ratingInProgress: "Veuillez attendre l'évaluation",
      courseScore: 'Score du cours',
      courseTotalScoreCalculationRule: 'Règles de calcul du score total du cours',
      theAverageScoreOfEachSentence: 'Score moyen de chaque phrase',
      theSumOfPointsForEachSentence: 'Somme des scores pour chaque phrase',
      singleSentencePassMark: 'Score de passage pour une seule phrase',
      theProportionOfTheSentenceFullMarks: 'Score complet pour la phrase',
      courseTotalPassingMark: 'Score de passage du cours',
      sendAndWrapPlaceholder: 'Entrer pour envoyer, Ctrl+Entrer pour sauter une ligne',
      courseMix: 'Combinaison de cours',
      totalOfSectionsHasVar: 'Total de {0} sections',
      pointsLostSection: 'Section des points perdus',
      courseAnalysis: 'Analyse du cours',
      detailsOfPointsLost: 'Détails des points perdus',
      errantMember: 'membre égaré',
      commonMistakes: 'Erreurs communes',
      peopleMissedVar: '{0} personnes ont manqué',
      getCourseScore: 'Note du cours',
      cumulativeScore: 'Score cumulatif',
      cumulativeScoreRange: 'Plage de scores',
      overview: 'Aperçu',
      noIncorrectAnswers: 'Pas de réponses incorrectes',
      questionScoreRange: 'Gamme de notation des sujets',
      keyPointExtractionFailed: "L'extraction des points clés a échoué en raison de la présence de vocabulaire sensible ; il est recommandé de remplir manuellement les points clés",
      weight: 'Poids',
      probability: 'Probabilité',
      taskRecord: 'Registre de tâche',
      courseTaskListTip: 'Afficher uniquement les tâches qui incluent ce cours et pour lesquelles il y a une autorisation de visualisation',
      someKeyPointExtractionFailed: "L'extraction partielle des points clés a échoué, le contenu du script a déclenché le contrôle des risques, une saisie manuelle est recommandée",
      averageStudentScore: 'Score Moyen des Étudiants',
      averageStudentScoreTip: 'Seuls les scores des membres pratiquants sont calculés',
      coursesVar: '{0} cours',
      hasReminded: "Rappels d'aujourd'hui envoyés",
      remindAll: 'Rappeler à tous',
      不提醒: 'Pas de rappel',
      '5 minutes': "Rappel de l'échéance de 5 minutes",
      '15 minutes': "Rappel de l'échéance de 15 minutes",
      '30 minutes': "Rappel de l'échéance de 30 minutes",
      '1 hours': "Rappel de l'échéance de 1 heure",
      '2 hours': "Rappel de l'échéance de 2 heures",
      '4 hours': "Rappel de l'échéance de 4 heures",
      '1 days': "Rappel de l'échéance de 1 jour",
      '3 days': "Rappel de l'échéance de 3 jours",
      confirmReminderTitle: 'Confirmer le rappel',
      confirmReminderContent: "Souhaitez-vous envoyer un rappel aux membres qui n'ont pas terminé la tâche pour qu'ils la finissent dès que possible ?",
      提醒你尽快完成任务: 'vous rappelle de terminer la tâche rapidement.',
      expirationReminder: "Rappel d'expiration",
      任务到期提醒: "Rappel d'expiration",
      '任务还有5分钟截止，请及时完成': 'La tâche se termine dans 5 minutes, veuillez terminer rapidement.',
      '任务还有15分钟截止，请及时完成': 'La tâche se termine dans 15 minutes, veuillez terminer rapidement.',
      '任务还有30分钟截止，请及时完成': 'La tâche se termine dans 30 minutes, veuillez terminer rapidement.',
      '任务还有1小时截止，请及时完成': 'La tâche se termine dans 1 heure, veuillez terminer rapidement.',
      '任务还有2小时截止，请及时完成': 'La tâche se termine dans 2 heures, veuillez terminer rapidement.',
      '任务还有4小时截止，请及时完成': 'La tâche se termine dans 4 heures, veuillez terminer rapidement.',
      '任务还有1天截止，请及时完成': 'La tâche se termine dans 1 jour, veuillez terminer rapidement.',
      '任务还有3天截止，请及时完成': 'La tâche se termine dans 3 jours, veuillez terminer rapidement.',
      保存为常用执行人组合: 'Enregistrer comme Combo Favori',
      存为常用: 'Enregistrer comme Favori',
      导入常用: 'Importer les Favoris',
      导入: 'Importer',
      导入常用执行人组合: 'Importer Combo Favori',
      组合名称: 'Nom du Combo',
      最多保存30个常用组合: "Enregistrer jusqu'à 30 Combos Favoris",
      全部催促成功: 'Rappel envoyé',
      提醒时间: 'heure de rappel',
      '因成员或权限变动，暂无可用执行人': "En raison de changements de membres ou de permissions, il n'y a temporairement aucun exécutant disponible",
      请输入名称: 'Veuillez entrer un nom',
      '请输入分类名称，最多20个字': "Veuillez entrer le nom de la catégorie, jusqu'à 20 caractères",
      管理任务分类: 'Gestion des catégories de tâches',
      '暂无分类，点击左下方添加分类': 'Aucune catégorie disponible, cliquez en bas à gauche pour ajouter une catégorie',
      添加分类: 'Ajouter une catégorie',
      删除分类: 'Supprimer une catégorie',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'Après avoir supprimé une catégorie, les étiquettes de catégorie des tâches assignées seront également retirées. Voulez-vous supprimer ?',
      分类: 'Catégorie',
      无分类: 'Non catégorisé',
      任务分类: 'Catégorie de tâche',
      分类数量最多30: 'Un maximum de 30 catégories',
      该名称已重复: 'Le nom a déjà été dupliqué',
      编辑模板公开范围: 'Modifier la portée publique du modèle',
      '练习平均分=学员所有已完成课程得分的平均分': 'score moyen = moyenne de toutes les notes obtenues par le participant dans le cadre du cours.',
      暂只支持剧本对练课程类型的错误汇总: 'Résumé des erreurs pour les types de cours "Script Practice" uniquement, pour le moment',
      错误汇总: 'Résumé des erreurs',
      所属课程: 'Cours',
      youCanAddScripts: 'Vous pouvez ajouter des scripts au cours et attribuer le cours aux étudiants par le biais de tâches, en leur demandant de lire les scripts',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'Vous pouvez ajouter des scripts au cours et attribuer le cours aux étudiants par le biais de tâches, en leur demandant de réciter les scripts',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': "Une fois activé, les étudiants ne peuvent voir que les 10 premiers caractères du script avant de s'exercer, et ne peuvent pas voir le script entier",
      salespersonSays: "L'étudiant dit",
      salesSaysConfigure: "Configurez le script standard pour ce que l'étudiant doit dire",
      salesSaysContent: "Ce que l'étudiant doit dire",
      jumpTip: 'Vous pouvez remplir des points clés, comme "insulte". Si le discours de l\'étudiant contient du contenu insultant, il passera à l\'étape suivante selon les conditions',
      scriptPromptTip: 'Une fois activé, les étudiants peuvent voir les invites de script pendant la pratique',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'Les clients IA dialogueront avec les étudiants en fonction du script de la base de connaissances que vous choisissez, poseront des questions et noteront.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Établissez différents scénarios de dialogue, ajoutez des scripts aux scénarios, et les clients IA poseront des questions de script pour évaluer les étudiants',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'Les champs de portrait de client aident lorsque les étudiants posent des questions de sondage, les clients IA répondent en fonction de cela',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': 'Au début du dialogue, vous pouvez expliquer des informations aux étudiants par le biais de remarques, comme des précautions de pratique, des informations de fond sur la pratique, etc.',
      startAndEndTimeTip: 'Après la date limite, les étudiants ne peuvent plus pratiquer le cours',
      salesResponse: "Réponses de l'étudiant",
      salesSaysContentTip: "Veuillez d'abord remplir ce que l'étudiant doit dire",
      autoCompleteConfirmContent: 'Il y a {0} cartes de parole d\'étudiant sans points clés configurés, ce cours n\'est pas disponible, confirmez-vous de partir? Vous pouvez cliquer sur le bouton "Terminer" pour compléter rapidement les points clés',
      salesContent: "Quand l'étudiant a besoin de dire quelque chose",
      finalScoreOfAICustomerCourse: "Le score final du cours client IA = le score le plus élevé de la pratique de l'étudiant",
      selfIntroduction: "Bonjour, je suis un étudiant de l'entreprise {0}, comment puis-je vous aider?",
      dialogueExample: "Le client a reçu un appel de l'étudiant, l'étudiant a présenté le produit au client, le client espère comprendre pleinement si les caractéristiques du produit répondent à leurs besoins, et espère acheter le produit à un prix moins cher.",
      taskScoreDescription: 'Selon la configuration du cours, le score du cours = la moyenne ou le total des scores de toutes les questions des étudiants',
      scoreReminder: "Lorsqu'un étudiant obtient un score inférieur au score de passage, un score rouge sera donné pour leur rappeler que leur performance n'est pas qualifiée",
      salesmanConfigureTip: "Lorsqu'un nœud est connecté à plusieurs cartes de parole d'étudiant en même temps, la carte de parole d'étudiant la plus similaire sera sélectionnée pour la notation et le saut en fonction du discours réel de l'étudiant",
      validateEdgeMessage: "Lorsque plusieurs lignes sont initiées à partir d'un seul point, la cible ne peut être qu'une carte de parole d'étudiant, et un maximum de 5 peuvent être connectées.",
      放弃练习: "Abandonner l'exercice",
      暂存离开: 'Mettre en pause et quitter',
      '确认提交本次练习？': 'Confirmer la soumission de cet exercice',
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Un autre chemin est en cours ou a déjà terminé cet exercice, veuillez réessayer plus tard',
      继续练习: "Continuer l'exercice",
      开始第x次练习: "Commencer l'exercice n°{0}",
      继续第x次练习: "Continuer l'exercice n°{0}",
      '任务截止前可继续练习，确认离开？': 'Vous pouvez continuer à vous exercer avant la date limite, confirmez-vous votre départ ?',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Un autre chemin est en cours ou a déjà terminé cet exercice, impossible de continuer sur la voie actuelle',
      失败提示: 'Échec',
      提前完成练习: "Terminer l'exercice en avance",
      任务截止前可继续本次练习: 'Vous pouvez continuer cet exercice avant la date limite',
      退出并清除本次练习记录: "Quitter et effacer l'enregistrement de cet exercice",
      确认提交: 'Soumettre',
      离开练习: "Quitter l'exercice",
      不显示子分类的课程: 'Masquer les sous-catégories',
      放弃练习成功: 'Abandon Réussi',
      上传图片: 'Télécharger une image',
      最多上传x张图片: 'Nombre maximum de {0} images à télécharger',
      图片大小不能超过x: "La taille de l'image ne doit pas dépasser {0}",
      请上传图片格式的文件: 'Veuillez télécharger un fichier au format image',
      加载失败: 'Échec du chargement'
    }
  },
  de: {
    coaching: {
      首页: 'Startseite',
      培训: 'Training',
      courseManagement: 'Kurse',
      courseClassification: 'Kurskategorien',
      searchByCourseName: 'Suche nach Kursnamen',
      noResults: 'Keine Ergebnisse für "{0}" gefunden',
      defaultClassification: 'Standardkategorie',
      creator: 'Ersteller',
      pleaSeselectCreator: 'Bitte wählen Sie den Ersteller',
      courseType: 'Kurstyp',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      newCourse: 'Neuen Kurs erstellen',
      totalNum: 'Insgesamt {0}',
      perPage: '{0} pro Seite',
      noCourses: 'Keine Kurse',
      addClassification: 'Kategorie hinzufügen',
      newHasVar: 'Erstellen {0}',
      category: 'Kategorie',
      nameHasVar: '{0} Name',
      cannotBeEmpty: 'Darf nicht leer sein',
      pleaseEnterContent: 'Bitte geben Sie den Inhalt ein',
      publicScope: 'Berechtigungen',
      visibleToAll: 'Sichtbar für alle',
      visibleToSpecified: 'Sichtbar für bestimmte Abteilungen oder Mitglieder',
      pleaseSelectMembers: 'Bitte wählen Sie Mitglieder',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      savedSuccessfully: 'gespeichert',
      rename: 'Umbenennen',
      renameHasVar: 'Umbenennen {0}',
      renameClassification: 'Kategorie umbenennen',
      editPublicScope: 'Bearbeitungsberechtigungen',
      search: 'Suchen',
      delete: 'Löschen',
      note: 'Hinweis',
      deleteCategoryWarning: 'Nach dem Löschen der Kategorie werden die Kurse in der Kategorie in die übergeordnete Kategorie oder die Standardkategorie verschoben. Kategorie löschen?',
      deletedSuccessfully: 'Gelöscht',
      onlySomeCreatorCanModifyTheClassification: 'Nur der Ersteller kann die Kategorie ändern',
      newScriptReading: 'Neuen Kurs - Sprechtraining erstellen',
      newScriptRecitation: 'Neues Skriptrezitation',
      newScriptPractice: 'Neues Skriptpraxis',
      newAICustomer: 'Neuen AI-Kunden erstellen',
      newAICustomerHasVar: 'Neuen AI-Kunden erstellen {0}',
      basicInformation: 'Grundinformationen',
      courseName: 'Kursname',
      pleaseFillInTheCourseName: 'Bitte geben Sie den Kursnamen ein',
      courseNameCannotBeRepeated: 'Der Kursname darf nicht doppelt sein',
      maximumCharacters: 'Maximal {0} Zeichen',
      courseContent: 'Kursinhalt',
      addKnowledgePointsAndScripts: 'Wissen hinzufügen',
      pleaseAddScriptPoints: 'Bitte fügen Sie ein Skript hinzu',
      courseRules: 'Kursregeln',
      readingRequirements: 'Lesen Anforderungen',
      selectScript: 'Wissen auswählen',
      save: 'Speichern',
      newCourseScriptRecitation: 'Neuen Kurs - Rezitations-Training erstellen',
      courseNameNoRepeat: 'Kursname darf nicht doppelt sein',
      max20Char: 'Maximal 20 Zeichen',
      pleaseAddScriptPpoints: 'Bitte fügen Sie Wissen hinzu',
      recitationRequirements: 'Aufsagensanforderungen',
      eachScriptNeedsToBeRecitedOnce: 'Jeder Abschnitt muss 1 Mal gelesen werden und jede Bewertung darf nicht weniger als 60 Punkte betragen (maximal: 100)',
      closedBookAssessment: 'Geschlossenes Buch Prüfung',
      newScriptPracticeCourse: 'Neuer Skript-Praxis-Kurs',
      newBlankCourse: 'Neuen Kurs erstellen',
      useTemplate: 'benutzen',
      undefined: 'Schüler antwortet',
      editCourseScriptPractice: 'Kurs bearbeiten - Skriptübung',
      addNode: 'Knoten hinzufügen',
      narration: 'Erzähler',
      customerSays: 'Kunde sagt',
      addThroughKnowledgeBase: 'Wissenspunkt hinzufügen',
      scriptPractice: 'Skriptpraxis',
      aiGeneratedCourse: 'AI generierter Kurs',
      saveAsTemplate: 'Als Vorlage speichern',
      templateName: 'Vorlagenname',
      max15Characters: 'Maximal 15 Zeichen',
      enterTemplateName: 'Bitte geben Sie den Vorlagennamen ein',
      templateRemarks: 'Vorlagenkommentar',
      Max30Characters: 'Maximal 30 Zeichen',
      scriptPracticeTour1: 'Ziehen Sie Knoten auf die Leinwand, um eine Karte dieses Knotentyps zu erstellen',
      skip: 'Überspringen',
      next: 'Nächster Schritt',
      scriptPracticeTour2: 'Klicken Sie auf eine Karte, um den Inhalt der Karte zu bearbeiten',
      previous: 'Vorheriger Schritt',
      scriptPracticeTour3: 'Ziehen Sie den Punkt, um eine Verbindung zu starten und zwei Karten zu verbinden; Drücken Sie die Rücktaste, um eine ausgewählte Verbindung zu löschen.',
      scriptPracticeTour4: 'Nachdem Sie den Dialogprozess erstellt haben, können Sie hier einen Dialogtest durchführen',
      scriptPracticeTour5: 'Kurse mit hoher Wiederverwendbarkeit können als Vorlage gespeichert werden',
      finish: 'Fertig',
      narrationConfigure: 'Wird verwendet, um Benutzer über die Szene, den Kundenhintergrund usw. zu informieren',
      narrationContent: 'Erzählerinhalt',
      narrationExample: 'Zum Beispiel: Der andere ist ein Erstkäufer',
      customerSaysContent: 'Was der Kunde sagt',
      customerSaysExample: 'Zum Beispiel: Warum ist der Preis so hoch?',
      addScript: 'Neues Wissen hinzufügen',
      customerSaysConfigure: 'Wenn mehrere Wissenspunkte konfiguriert sind, wird der virtuelle Kunde zufällig einen davon sagen',
      searchScript: 'Wissen suchen',
      searchKnowledgeBaseScript: 'Suche nach Skript',
      allKnowledgeBase: 'Gesamte Wissensdatenbank',
      checkedScript: 'Ausgewählt: 0 Wissen',
      searchTips: 'Keine Empfehlungen, versuchen Sie es mit der Suche~',
      add: 'Hinzufügen',
      salesSaysExample: 'Zum Beispiel: Unsere Produktqualität ist hervorragend',
      jumpingRules: 'Sprungregel',
      normalJump: 'normaler Sprung rückwärts',
      conditionJump: 'Springen nach Bedingung',
      knowledgeBase: 'Wissensdatenbank',
      knowledgeBaseEmpty: 'Der Inhalt der Wissensdatenbank ist leer',
      importData: 'Daten importieren',
      downloadTemplateTitle: 'Klicken Sie auf die Schaltfläche Herunterladen, um die Vorlagendatei zu importieren',
      downloadTemplate: 'Vorlage herunterladen',
      scriptDialogueTemplate: 'Skript-Übungs-Vorlage',
      uploadFileTitle: 'Nachdem die Datei ausgefüllt ist, laden Sie die Datei im unteren Bereich hoch',
      uploadFileTip: 'Klicken Sie hier oder ziehen Sie die Datei zum Hochladen hierher',
      supportsExtensions: 'Unterstützte Erweiterungen: xls, xlsx',
      courseSettings: 'Kurseinstellungen',
      practiceMode: 'Übungsmodus',
      voiceMode: 'Sprachmodus',
      textMode: 'Textmodus',
      scriptPrompt: 'Gesprächshinweise',
      shortcutKeys: 'Tastenkombinationen',
      moveCanvas: 'Bewegen Sie die Leinwand',
      moveCanvasTip: 'Ziehen Sie die Leinwand mit der linken Maustaste',
      zoomCanvas: 'Leinwand zoomen',
      zoomCanvasTip: 'Steuerung + Mausrad',
      boxSelection: 'Auswahl / Mehrfachauswahl',
      boxSelectionTip: 'Shift + Maus',
      copy: 'Kopieren',
      paste: 'Einfügen',
      deleteTip: 'Ausgewählte Knotenkarte / Verbindungslinie + Rücktaste',
      undo: 'Rückgängig machen',
      redo: 'Wiederholen',
      dialogueTest: 'Dialogtest',
      conversationEnds: 'Gespräch beenden',
      retest: 'Test erneut starten',
      start: 'Start',
      courseNamePlaceholder: 'Bitte geben Sie den Kursnamen ein',
      dialogueScene: 'Dialogszene',
      sceneCountLimitTips: 'Bitte fügen Sie mindestens einer Szene ein Gespräch hinzu',
      department: 'Abteilung',
      pleaseSelectDepartment: 'Bitte wählen Sie eine Abteilung',
      customer: 'Kunden',
      sends: 'Sekunde',
      today: 'Heute',
      startTime: 'Startzeit',
      chinese: 'Chinesisch',
      english: 'Englisch',
      returnToList: 'Zurück zur Liste',
      restart: 'Neu starten',
      scriptReading: 'Sprechtechnik Lesen',
      scriptRecitation: 'Sprechtechnik Rezitieren',
      aICustomer: 'AI-Kunde',
      aICustomerHasVar: 'AI-Kunde {0}',
      lastWeek: 'Letzte Woche',
      lastMonth: 'Letzter Monat',
      lastThreeMonths: 'Letzte drei Monate',
      newTask: 'Neue Aufgabe',
      taskName: 'Aufgabenname',
      learnCourse: 'Lernkurs',
      executor: 'Ausführender',
      startAndEndTime: 'Start- und Endzeit',
      edit: 'Bearbeiten',
      changeClassification: 'Kategorie ändern',
      moveTo: 'Verschieben nach',
      selectClassification: 'Kategorie auswählen',
      deleteCourse: 'Kurs löschen',
      tipsForDeletingCourses: 'Das Löschen eines Kurses beeinträchtigt nicht die bereits zugewiesenen Aufgaben. Möchten Sie diesen Kurs wirklich löschen?',
      searchByName: 'Suche nach Namen',
      pleaseSelectExecutor: 'Bitte wählen Sie den Ausführenden',
      status: 'Status',
      notStarted: 'nicht gestartet',
      inProgress: 'in Arbeit',
      completed: 'Abgeschlossen',
      deadlinePassed: 'geschl.',
      aiClient: 'AI-Kunde',
      noTrainingTasksYet: 'Es gibt noch keine Trainingsaufgaben',
      learningCourse: 'Lernkurs',
      operation: 'Aktion',
      details: 'Details',
      noData: 'Keine Daten',
      assigner: 'Zuweisender',
      deleteTask: 'Aufgabe löschen',
      deleteTaskPrompt: 'Die Aufgabe hat bereits begonnen, möchten Sie sie wirklich löschen?',
      deleteSuccessful: 'Erfolgreich gelöscht',
      copyTask: 'Aufgabe kopieren',
      pleaseEnterTaskName: 'Bitte geben Sie den Aufgabennamen ein',
      pleaseSelectCourse: 'Bitte wählen Sie einen Kurs',
      completionStatus: 'Abschlussstatus',
      taskScoreRange: 'Aufgabenpunktebereich',
      below60Points: 'Unter 60 Punkte',
      above60Points: 'Über 60 Punkte',
      custom: 'Benutzerdefiniert',
      notCompleted: 'Nicht abgeschlossen',
      noScoreYet: 'Keine Punkte',
      exportTable: 'Tabelle exportieren',
      keyScripts: 'Schlüssel-Sprechtechnik',
      sceneAverageScore: 'Durchschnittliche Szene Punktzahl: {0} Punkte',
      standardScript: 'Standard-Sprechtechnik',
      intelligentReview: 'Intelligente Bewertung',
      matchingPoints: 'Getroffene Punkte',
      onlyNone: 'Keine',
      missingPoints: 'Verpasste Punkte',
      dialogueRecord: 'Dialogaufzeichnung',
      aIReview: 'AI-Bewertung',
      abilityAnalysis: 'Fähigkeitsanalyse',
      myTasks: 'Meine Aufgaben',
      searchByAssignee: 'Suche nach Zuweiser',
      deadline: 'Frist',
      goPractice: 'Üben',
      back: 'Zurück',
      assignedBy: 'Zugewiesen von {0}',
      participantsHasVar: '{0} Teilnehmer',
      startPractice: 'Übung starten',
      conversationContent: 'Gesprächsinhalt',
      endConversation: 'Gespräch beenden',
      endThePracticeEarlyWarning: 'Der Kunde hat noch einige unbeantwortete Fragen, unbeantwortete Fragen werden mit 0 Punkten bewertet, möchten Sie das Rollenspiel vorzeitig beenden?',
      sendNextWarning: 'Nachdem eine Runde des Gesprächs abgeschlossen ist, kann die nächste Dialogzeile gesendet werden',
      practiceIsCompleted: 'Alle Fragen des Kunden wurden gestellt, Übung abgeschlossen',
      continuePractice: 'Übung fortsetzen',
      leaveAICustomer: 'AI-Kunde verlassen',
      quitHalfwayWarning: 'Wenn Sie das Training vorzeitig verlassen, müssen Sie beim nächsten Mal von vorne beginnen. Möchten Sie wirklich gehen?',
      leave: 'Verlassen',
      ByUploadingRecording: 'Führen Sie ein Gespräch mit dem Kunden, indem Sie eine Aufnahme hochladen',
      gotIt: 'Ich habe verstanden',
      pleaseStartSpeaking: 'Bitte beginnen Sie zu sprechen',
      voiceNotRecognized: 'Keine Stimme erkannt, bitte überprüfen Sie das Mikrofon',
      话术提示: 'Sprechhinweis',
      backToTaskList: 'Zurück zur Aufgabenliste',
      viewPracticeDetails: 'Übungsdetails anzeigen',
      completeCoachingTips: 'Sie haben dieses Training abgeschlossen',
      rePractice: 'Nochmal üben',
      practiceScore: 'Übungspunkte',
      preparing: 'In Vorbereitung',
      recitationAssessment: 'Auswendiglernen Prüfung',
      progress: 'Fortschritt',
      deadlineHasVar: '{0} Frist',
      commonCustomerQuestions: 'Häufige Kundenfragen',
      noQuestions: 'Keine Fragen',
      answeringSkills: 'Antworttechniken',
      noAnsweringSkills: 'Keine Antworttechniken',
      finalScore: 'Endpunktzahl',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: 'Übung nicht abgeschlossen, keine Punkte',
      scoreDesc: '"Wenn die Anzahl der Übungen 1 beträgt, ist die Endpunktzahl = Punktzahl der besten Übung;Wenn die Anzahl der Übungen N beträgt, ist die Endpunktzahl = Durchschnittspunktzahl der besten N Übungen"',
      完成练习: 'Übung abschließen',
      questionName: 'Fragename',
      questionScore: 'Fragenpunkte',
      taskScore: 'Aufgabenpunkte',
      completedQuestionsCount: 'Anzahl der abgeschlossenen Fragen',
      progressCompletion: 'Fertigstellungsfortschritt',
      to: ' bis ',
      pleaseSelectHasVar: 'Bitte wählen Sie {0}',
      all: 'Alles',
      teamView: 'Teamansicht',
      assignedByMe: 'Mir zugewiesen',
      score: 'Punktzahl',
      practiceRecord: 'Übungsprotokoll',
      comprehensiveEvaluation: 'Gesamtbewertung',
      noKeyPhrasesAvailable: 'Keine Schlüsselphrasen vorhanden',
      scenarioPhrasesBeingGenerated: 'Szenario-Phrasen werden generiert',
      notTriggered: 'Nicht ausgelöst',
      noCommentsAvailable: 'Keine Bewertungen vorhanden',
      dialogueRecordBeingGenerated: 'Dialogprotokoll wird generiert',
      commenting: 'Bewertung läuft',
      prompt: 'Hinweis',
      exit: 'Beenden',
      scenarioPlaceholder: 'Bitte fügen Sie Szenario-Phrasen hinzu',
      scenceNameRepeat: 'Szenariennamen dürfen nicht doppelt sein',
      quickReply: 'Schnellsendung',
      replyPlaceholder: 'Antwort eingeben (Enter zum Senden)',
      intelligentGenerating: 'Intelligente Generierung läuft',
      generationFailed: 'Generierung fehlgeschlagen',
      clickToRetry: 'Klicken Sie zum erneuten Versuch',
      generateDialogueTip: 'Bitte generieren Sie zuerst den Dialog',
      generateNode: 'Knoten generieren',
      scoringRules: 'Bewertungsregeln',
      scoreByKeyPoints: 'Punktzahl',
      noScoring: 'Keine Punkte vergeben',
      courseUnavailableTip: 'Wenn keine Schlüsselpunkte konfiguriert sind, kann der Kurs nicht verwendet werden',
      generating: 'Generierung läuft',
      aiGenerateKeyPoint: 'Von KI generiert',
      addKeyPoint: 'Schlüsselpunkte hinzufügen',
      copySuccessfully: 'Erfolgreich kopiert',
      cannotCopyInfo: 'Bitte wählen Sie zuerst einen Knoten aus, bevor Sie kopieren',
      cannotPasteInfo: 'Die Zwischenablage ist leer, kann nicht eingefügt werden',
      pasteSuccessfully: 'Einfügen erfolgreich',
      generatedSuccessfully: 'Generierung erfolgreich',
      noNeedCompleteCard: 'Keine Knotenkarten vorhanden, die Schlüsselpunkte benötigen',
      ruleIsGenerating: 'Generierung von Schlüsselpunkten',
      whenRuleHit: 'Wenn getroffen',
      whenRuleMiss: 'Wenn nicht getroffen',
      noScoreRuleConfigured: 'Keine Schlüsselpunkte',
      addScriptTip: 'Bitte wählen Sie die Phrase, die Sie hinzufügen möchten',
      templateNameTip: 'Bitte geben Sie den Vorlagennamen ein',
      inputMaxLength: 'Sie können maximal {0} Zeichen eingeben',
      scriptLengthLimit: 'Der Inhalt überschreitet das Limit von 1000 Zeichen, bitte kürzen Sie ihn und fügen Sie ihn dann hinzu',
      addSuccess: 'Erfolgreich hinzugefügt',
      addScriptPlaceholder: 'Bitte wählen Sie die Phrase, die Sie hinzufügen möchten',
      checkedKnowledgeScript: 'Ausgewählt: {0} Wissenspunkte',
      smartTrainingUseTip: 'Sie können die Komponenten auf der linken Seite auf die Leinwand ziehen, um die Karte zu erstellen, und dann die Karten verbinden, um die Dialogflusskonfiguration abzuschließen',
      roleNamePlaceholder: 'Bitte geben Sie den Rollennamen ein',
      noConditionsHit: 'Wenn keine der oben genannten Bedingungen erfüllt ist',
      ruleHit: 'Treffer',
      ruleMiss: 'Kein Treffer',
      ruleKeywordPlaceholder: 'Geben Sie die Inhalts-Highlights ein, maximal 5, Enter zum Abschließen',
      templateInstructionsTip: 'Bitte lesen Sie die Anweisungen in der Vorlage sorgfältig durch',
      templateRequiredFormat: 'und füllen Sie die zu importierenden Daten gemäß dem erforderlichen Format aus',
      autoCompletePoints: 'vollständige Punkte',
      createCourseScriptPractice: 'Neuer Kurs - Skriptpraxis',
      passingScore: 'Bestehensnote',
      fullScore: '(Vollpunktzahl: 100)',
      modelLanguage: 'Modellsprache',
      modelLanguageTip: 'Die Modellsprache beeinflusst die Fähigkeit des Modells, Punkte in verschiedenen Sprachen zu extrahieren und zu bewerten',
      isImporting: 'Import läuft...',
      ' notRefreshOrLeaveTip': 'Bitte aktualisieren Sie nicht oder verlassen Sie die Seite, und stellen Sie sicher, dass die Netzwerkverbindung stabil ist',
      importCompleted: 'Import abgeschlossen',
      importAgain: 'Erneut importieren',
      parsingFailed: 'Analyse fehlgeschlagen',
      fileContentNotMatch: 'Der Inhalt der Datei stimmt nicht überein, bitte ändern Sie ihn und laden Sie ihn erneut hoch',
      reUpload: 'Erneut hochladen',
      uploadFailed: 'Upload fehlgeschlagen',
      fileNotExistTip: 'Die Datei existiert nicht, bitte laden Sie sie erneut hoch',
      editTask: 'Aufgabe bearbeiten',
      expand: 'Erweitern',
      collapse: 'Einklappen',
      creationTime: 'Erstellungszeit',
      noMatchingResults: 'Keine passenden Ergebnisse',
      goToCreate: 'Zur Erstellung gehen',
      pleaseSelectTheStartTime: 'Bitte wählen Sie die Startzeit',
      pleaseSelectTheEndTime: 'Bitte wählen Sie die Endzeit',
      onlyMainSpaceAreDisplayed: 'Es werden nur Mitglieder angezeigt, deren Hauptbereich der aktuelle Bereich ist',
      numberOfCoursesCannotExceed: 'Die Anzahl der Kurse darf {0} nicht überschreiten',
      pleaseSelectTheTime: 'Bitte wählen Sie die Zeit',
      duplicate: 'Kopie',
      editedSuccessfully: 'Bearbeitung erfolgreich',
      createdSuccessfully: 'Erstellung erfolgreich',
      goToView: 'Zur Ansicht gehen',
      learnMore: 'Mehr erfahren',
      removeFoldersWarning: 'Die Wissenspunkte im Ordner werden ebenfalls gelöscht, bestätigen Sie die Löschung dieses Ordners?',
      points: 'Punkte',
      pointsHasVar: '{0} Punkte',
      onlyTheTaskCompletionStatus: 'Es werden nur die Aufgabenerfüllungssituationen der Ausführungspersonen angezeigt, die die Berechtigung zum Anzeigen haben',
      times: '{0} Mal',
      someRhetoricIsNotCompleted: 'Einige Phrasen sind nicht abgeschlossen',
      noViewingPermission: 'Keine Berechtigung zum Anzeigen',
      averageScoreOfAllQuestions: 'Die Kursnote entspricht dem Durchschnitt aller Fragepunkte',
      exportSuccessful: 'Export erfolgreich',
      comment: 'Bewertung',
      scoreNotLessThanPoints: '{0} Anforderung: {1} Die Bewertung sollte nicht weniger als {2} Punkte betragen',
      practiceAgain: 'Erneut üben',
      startTraining: 'Training beginnen',
      taskHasEnded: 'Aufgabe abgeschlossen',
      taskHasEndedAndCannotBePracticed: 'Die Aufgabe ist abgeschlossen, Übung ist nicht möglich',
      answerSheet: 'Antwortkarte',
      connecting: 'Verbindung herstellen',
      keyIssue: 'Schlüsselfragen',
      noContent: 'Kein Inhalt',
      sessionEnded: 'Die Konversation ist beendet',
      leavingTheScriptForPractice: 'Skriptübung verlassen',
      pleaseOpenTheMicrophone: 'Bitte öffnen Sie die Mikrofonaufnahmeberechtigung oder überprüfen Sie den Geräteverbindungsstatus',
      sessionRecoverySuccessful: 'Die Konversation wurde erfolgreich wiederhergestellt',
      sureToLeave: 'Sie haben eine unvollständige Aufnahme, sind Sie sicher, dass Sie gehen möchten?',
      detailsOfKnowledgePointExercises: 'Details zur Wissenspunkteübung',
      conversationWithCustomersByEnteringText: 'Führen Sie ein Gespräch mit dem Kunden, indem Sie Text eingeben',
      sessionModelDoesNotExist: 'Das Konversationsmodell existiert nicht, die Übung kann nicht fortgesetzt werden',
      taskHasBeenDeleted: 'Die Aufgabe wurde gelöscht, die Übung kann nicht fortgesetzt werden',
      sessionCreationFailed: 'Die Erstellung der Konversation ist fehlgeschlagen, bitte versuchen Sie es erneut',
      sessionRecoveryFailed: 'Die Wiederherstellung der Konversation ist fehlgeschlagen, bitte versuchen Sie es erneut',
      connectionHasBeenDisconnected: 'Die Verbindung wurde getrennt, bitte verbinden Sie sich erneut',
      endSessionEarly: 'Beenden Sie das Gespräch vorzeitig',
      conductingTestWarning: 'Bitte beenden Sie die aktuelle Aufnahme, bevor Sie den Test durchführen!',
      proceedingWithOtherRecordingsWarning: 'Bitte beenden Sie die aktuelle Aufnahme, bevor Sie eine andere Aufnahme machen!',
      deleteKnowledgePointWarning: 'Bestätigen Sie die Löschung dieses Wissenspunkts?',
      systemVerifyDataTip: 'Das System wird die Genauigkeit des Datenformats überprüfen. Nach der Überprüfung ohne Fehler werden die Daten in das System importiert',
      addConditions: 'Neue Bedingungen hinzufügen',
      conversationMaterialEmptyTip: 'Dialogmaterial darf nicht leer sein',
      conversationSceneEmptyTip: 'Dialogszenario darf nicht leer sein',
      readAloud: 'Vorlesen',
      recite: 'Auswendig lernen',
      chooseLanguagePlaceholder: 'Bitte wählen Sie die Modellsprache aus',
      simplifiedChinese: 'Vereinfachtes Chinesisch',
      englishLanguage: 'Englisch',
      frenchLanguage: 'Französisch',
      germanLanguage: 'Deutsch',
      spanishLanguage: 'Spanisch',
      portugueseLanguage: 'Portugiesisch',
      italianLanguage: 'Italienisch',
      train: 'Training',
      intelligentGenerationConversation: 'Intelligenter Dialoggenerator',
      taskAllocationTour: 'Die zugewiesenen Aufgaben werden hier verwaltet',
      InternalError: 'Interner Serverfehler',
      deleteTemplateConfirm: 'Sind Sie sicher, dass Sie diese Vorlage löschen möchten?',
      operationSuccessful: 'Operation erfolgreich',
      noSearchResult: 'Keine relevanten Ergebnisse gefunden',
      scoringPointsAreBeingGenerated: 'Die Erstellung von Bewertungskriterien ist im Gange, Aufgaben können erst nach Abschluss zugewiesen werden',
      unconfiguredScoringRules: 'Der Kurs ist nicht verfügbar, bitte schließen Sie ihn ab, bevor Sie Aufgaben zuweisen',
      dialogueImportTemplate: 'Dialogimportvorlage',
      fileFormatTip: 'Wählen Sie eine Datei im xlsx- oder xls-Format',
      importSuccessful: 'Import erfolgreich',
      taskManagement: 'Aufgabe',
      deletedTask: 'Die Aufgabe wurde gelöscht',
      switchoverSuccessful: 'Wechsel erfolgreich',
      voicePromptIsTurnedOn: 'Sprachaufforderung ein',
      voicePromptIsTurnedOff: 'Sprachaufforderung aus',
      theHighestScore: 'Höchste',
      startNow: 'Sofort starten',
      timedStart: 'Geplanter Start',
      confirmDeletion: 'Sind Sie sicher, dass Sie löschen möchten?',
      leavingTheCourse: 'Verlassen Sie den Kurs',
      successfullyMoved: 'Bewegung erfolgreich',
      thereIsNoMore: 'Nichts mehr',
      dialogueMaterials: 'Material',
      editCourse: 'Kurs bearbeiten',
      完成了任务: 'hat die Aufgabe abgeschlossen',
      任务完成通知: 'Benachrichtigung über die Aufgabenerfüllung',
      '完成了任务，整个任务已完成': 'hat die Aufgabe abgeschlossen',
      完成了你的任务: 'hat die Aufgabe abgeschlossen',
      删除了任务: 'hat die Aufgabe gelöscht',
      任务删除通知: 'Benachrichtigung über die Aufgabenlöschung',
      分配给我的任务: 'hat mir eine Aufgabe zugewiesen',
      任务执行通知: 'Benachrichtigung über die Aufgabenausführung',
      给我分配了一个任务: 'hat mir eine Aufgabe zugewiesen',
      noTaskCurrently: 'Derzeit gibt es keine Aufgaben',
      goToTraining: 'Weiter zum Schulungsinhalt',
      executive: 'Ausführender',
      completionNotice: 'Fertigstellungsbenachrichtigung',
      enforcementNotice: 'Ausführungsbenachrichtigung',
      allRead: 'Alles gelesen',
      task: 'Aufgabe',
      messageCenter: 'Nachrichtenzentrum',
      notification: 'Benachrichtigung',
      review: 'Kommentar',
      tutor: 'Nachhilfe',
      bulletin: 'Briefing',
      allAreSetToReadSuccessfully: 'Erfolgreich als gelesen markiert',
      noKnowledgeBaseAvailable: 'Keine verfügbare Wissensbasis',
      noNetwork: 'Kein Netzwerk',
      serverException: 'Serverausnahme, bitte versuchen Sie es später noch einmal',
      parameterError: 'Parameterfehler',
      requestTimeout: 'Anforderung Zeitüberschreitung',
      requestTimeoutTryAgain: 'Anforderung Zeitüberschreitung, bitte überprüfen Sie das Netzwerk oder versuchen Sie es später noch einmal',
      loginStatusChangeTip: 'Der Anmeldestatus des Benutzers hat sich geändert, wird bald zur Startseite zurückkehren',
      contactAdministrator: 'Bitte kontaktieren Sie den Administrator',
      loginFailureTip: 'Anmeldung ungültig Erinnerung',
      loginAgain: 'Erneut anmelden',
      noAccessRights: 'Keine Zugriffsberechtigung, bitte kontaktieren Sie den Administrator',
      customerNotExist: 'Der Kunde existiert nicht oder hat kein Recht zu sehen',
      scorePointPlaceholder: 'Geben Sie die Schlüsselpunkte ein',
      ratingInProgress: 'Bitte warten Sie auf die Bewertung',
      courseScore: 'Kursbewertung',
      courseTotalScoreCalculationRule: 'Regeln zur Berechnung der Gesamtpunktzahl des Kurses',
      theAverageScoreOfEachSentence: 'Durchschnittliche Punktzahl jedes Satzes',
      theSumOfPointsForEachSentence: 'Summe der Punkte für jeden Satz',
      singleSentencePassMark: 'Bestehensnote für einen einzelnen Satz',
      theProportionOfTheSentenceFullMarks: 'Volle Punktzahl für den Satz',
      courseTotalPassingMark: 'Kursbestehensnote',
      sendAndWrapPlaceholder: 'Eingabe zum Senden, Strg+Eingabe zum Zeilenumbruch',
      courseMix: 'Kurskombination',
      totalOfSectionsHasVar: 'Insgesamt {0} Abschnitte',
      pointsLostSection: 'Punkteverlust-Abschnitt',
      courseAnalysis: 'Kursanalyse',
      detailsOfPointsLost: 'Details der verlorenen Punkte',
      errantMember: 'abtrünniges Mitglied',
      commonMistakes: 'Häufige Fehler',
      peopleMissedVar: '{0} Personen haben verpasst',
      getCourseScore: 'Kursbewertung',
      cumulativeScore: 'Gesamtpunktzahl',
      cumulativeScoreRange: 'Punktebereich',
      overview: 'Überblick',
      noIncorrectAnswers: 'Keine falschen Antworten',
      questionScoreRange: 'Themenbewertungsbereich',
      keyPointExtractionFailed: 'Die Extraktion der Schlüsselpunkte ist aufgrund des Vorhandenseins sensibler Vokabeln fehlgeschlagen; es wird empfohlen, die Schlüsselpunkte manuell auszufüllen',
      weight: 'Gewicht',
      probability: 'Wahrscheinlichkeit',
      taskRecord: 'Aufgabenprotokoll',
      courseTaskListTip: 'Nur Aufgaben anzeigen, die diesen Kurs beinhalten und für die eine Ansichtsberechtigung besteht',
      someKeyPointExtractionFailed: 'Teilweise Schlüsselpunktextraktion fehlgeschlagen, der Skriptinhalt hat die Risikokontrolle ausgelöst, manuelle Eingabe wird empfohlen',
      averageStudentScore: 'Durchschnittliche Schülerbewertung',
      averageStudentScoreTip: 'Es werden nur die Punktzahlen der Übungsmitglieder berechnet',
      coursesVar: '{0} Kurse',
      hasReminded: 'Erinnerungen von heute gesendet',
      remindAll: 'Alle erinnern',
      不提醒: 'Keine Erinnerung',
      '5 minutes': '5-Minuten-Frist Erinnerung',
      '15 minutes': '15-Minuten-Frist Erinnerung',
      '30 minutes': '30-Minuten-Frist Erinnerung',
      '1 hours': '1-Stunde-Frist Erinnerung',
      '2 hours': '2-Stunden-Frist Erinnerung',
      '4 hours': '4-Stunden-Frist Erinnerung',
      '1 days': '1-Tag-Frist Erinnerung',
      '3 days': '3-Tage-Frist Erinnerung',
      confirmReminderTitle: 'Erinnerung bestätigen',
      confirmReminderContent: 'Möchten Sie eine Erinnerung an Mitglieder senden, die die Aufgabe noch nicht abgeschlossen haben, um sie so schnell wie möglich zu beenden?',
      提醒你尽快完成任务: 'erinnert Sie daran, die Aufgabe rechtzeitig zu erledigen.',
      expirationReminder: 'Ablauferinnerung',
      任务到期提醒: 'Ablauferinnerung',
      '任务还有5分钟截止，请及时完成': 'Aufgabe endet in 5 Minuten, bitte zügig abschließen.',
      '任务还有15分钟截止，请及时完成': 'Aufgabe endet in 15 Minuten, bitte zügig abschließen.',
      '任务还有30分钟截止，请及时完成': 'Aufgabe endet in 30 Minuten, bitte zügig abschließen.',
      '任务还有1小时截止，请及时完成': 'Aufgabe endet in 1 Stunde, bitte zügig abschließen.',
      '任务还有2小时截止，请及时完成': 'Aufgabe endet in 2 Stunden, bitte zügig abschließen.',
      '任务还有4小时截止，请及时完成': 'Aufgabe endet in 4 Stunden, bitte zügig abschließen.',
      '任务还有1天截止，请及时完成': 'Aufgabe endet in 1 Tag, bitte zügig abschließen.',
      '任务还有3天截止，请及时完成': 'Aufgabe endet in 3 Tagen, bitte zügig abschließen.',
      保存为常用执行人组合: 'Als Lieblingskombination speichern',
      存为常用: 'Als Favorit speichern',
      导入常用: 'Favoriten importieren',
      导入: 'Importieren',
      导入常用执行人组合: 'Lieblingskombination importieren',
      组合名称: 'Kombinationsname',
      最多保存30个常用组合: 'Bis zu 30 Lieblingskombinationen speichern',
      全部催促成功: 'Erinnerung gesendet',
      提醒时间: 'Erinnerungszeit',
      '因成员或权限变动，暂无可用执行人': 'Aufgrund von Mitglieder- oder Berechtigungsänderungen gibt es vorübergehend keine verfügbaren Ausführenden',
      请输入名称: 'Bitte geben Sie einen Namen ein',
      '请输入分类名称，最多20个字': 'Bitte geben Sie den Kategorienamen ein, bis zu 20 Zeichen',
      管理任务分类: 'Aufgabenkategorien-Verwaltung',
      '暂无分类，点击左下方添加分类': 'Keine Kategorien verfügbar, klicken Sie unten links, um eine Kategorie hinzuzufügen',
      添加分类: 'Kategorie hinzufügen',
      删除分类: 'Kategorie löschen',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'Nach dem Löschen einer Kategorie werden auch die Kategorietags aller zugewiesenen Aufgaben entfernt. Möchten Sie löschen?',
      分类: 'Kategorie',
      无分类: 'Nicht kategorisiert',
      任务分类: 'Aufgabenkategorie',
      分类数量最多30: 'Maximal 30 Kategorien',
      该名称已重复: 'Der Name wurde bereits dupliziert',
      编辑模板公开范围: 'Den öffentlichen Bereich der Vorlage bearbeiten',
      '练习平均分=学员所有已完成课程得分的平均分': 'Durchschnittswert = Durchschnitt aller abgeschlossenen Kurse des Teilnehmers',
      暂只支持剧本对练课程类型的错误汇总: 'Zusammenfassung der Fehler nur für Skript-Praxis-Kurstypen, vorläufig',
      错误汇总: 'Zusammenfassung der Fehler',
      所属课程: 'Kurse',
      youCanAddScripts: 'Sie können Skripte zum Kurs hinzufügen und den Kurs über Aufgaben den Schülern zuweisen, die verlangen, dass sie die Skripte lesen',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'Sie können Skripte zum Kurs hinzufügen und den Kurs über Aufgaben den Schülern zuweisen, die verlangen, dass sie die Skripte rezitieren',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': 'Einmal aktiviert, können Schüler vor dem Üben nur die ersten 10 Zeichen des Skripts sehen und können das gesamte Skript nicht einsehen',
      salespersonSays: 'Schüler sagt',
      salesSaysConfigure: 'Konfigurieren Sie das Standardskript für den Schüler zu sagen',
      salesSaysContent: 'Was der Schüler sagen muss',
      jumpTip: 'Sie können Schlüsselpunkte einfüllen, wie "Beleidigung". Wenn die Rede des Schülers beleidigenden Inhalt enthält, wird es gemäß den Bedingungen zum nächsten Schritt springen',
      scriptPromptTip: 'Einmal aktiviert, können Schüler während der Übung Skript-Hinweise einsehen',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'KI-Kunden werden auf der Grundlage des Skripts der von Ihnen gewählten Wissensbasis mit Schülern dialogisieren, Fragen stellen und bewerten.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Errichten Sie verschiedene Dialogszenarien, fügen Sie Skripte zu den Szenarien hinzu, und KI-Kunden werden Skriptfragen stellen, um Schüler zu bewerten',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'Kundenporträtfelder helfen, wenn Schüler sondierende Fragen stellen, KI-Kunden antworten auf dieser Grundlage',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': 'Zu Beginn des Dialogs können Sie den Schülern Informationen durch Beiseite sprechen erklären, wie Übungsvorsichtsmaßnahmen, Übungshintergrundinformationen usw.',
      startAndEndTimeTip: 'Nach der Frist können Schüler den Kurs nicht mehr üben',
      salesResponse: 'Schüler antwortet',
      salesSaysContentTip: 'Bitte füllen Sie zuerst ein, was der Schüler sagen muss',
      autoCompleteConfirmContent: 'Es gibt {0} Schüler-Sprech-Karten ohne konfigurierte Schlüsselpunkte, dieser Kurs ist nicht verfügbar, bestätigen Sie zu verlassen? Sie können auf die Schaltfläche "Fertig" klicken, um die Schlüsselpunkte schnell abzuschließen',
      salesContent: 'Wenn der Schüler etwas sagen muss',
      finalScoreOfAICustomerCourse: 'Die Endnote des KI-Kundenkurses = die höchste Note der Übung des Schülers',
      selfIntroduction: 'Hallo, ich bin ein Schüler der Firma {0}, womit kann ich Ihnen helfen?',
      dialogueExample: 'Der Kunde erhielt einen Anruf vom Schüler, der Schüler stellte dem Kunden das Produkt vor, der Kunde hofft, vollständig zu verstehen, ob die Produkteigenschaften ihren Bedürfnissen entsprechen, und hofft, das Produkt zu einem günstigeren Preis zu kaufen.',
      taskScoreDescription: 'Gemäß der Kurskonfiguration ist die Kursnote = der Durchschnitt oder die Gesamtnote aller Schülerfragen',
      scoreReminder: 'Wenn die Leistung eines Schülers unter der Bestehensnote liegt, wird eine rote Note gegeben, um sie daran zu erinnern, dass ihre Leistung nicht qualifiziert ist',
      salesmanConfigureTip: 'Wenn ein Knoten gleichzeitig mit mehreren Schüler-Sprech-Karten verbunden ist, wird die ähnlichste Schüler-Sprech-Karte für die Bewertung und das Springen auf der Grundlage der tatsächlichen Rede des Schülers ausgewählt',
      validateEdgeMessage: 'Wenn mehrere Linien von einem einzigen Punkt aus eingeleitet werden, kann das Ziel nur eine Schüler-Sprech-Karte sein, und es können maximal 5 verbunden werden.',
      放弃练习: 'Übung abbrechen',
      暂存离开: 'Zwischenspeichern und verlassen',
      '确认提交本次练习？': 'Übungseinreichung bestätigen',
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Die Übung wird gerade auf einem anderen Weg durchgeführt oder ist bereits abgeschlossen, bitte versuchen Sie es später erneut.',
      继续练习: 'Übung fortsetzen',
      开始第x次练习: 'Starte Übung Nr. {0}',
      继续第x次练习: 'Setze Übung Nr. {0} fort',
      '任务截止前可继续练习，确认离开？': 'Möchten Sie die Übung wirklich verlassen, obwohl Sie bis zum Abgabetermin weiterüben können?',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Die Übung wird gerade auf einem anderen Weg durchgeführt oder ist bereits abgeschlossen, der aktuelle Vorgang kann nicht fortgesetzt werden.',
      失败提示: 'Fehlermeldung',
      提前完成练习: 'Übung vorzeitig abschließen',
      任务截止前可继续本次练习: 'Sie können die Übung bis zum Abgabetermin fortsetzen',
      退出并清除本次练习记录: 'Übung abbrechen und Aufzeichnungen löschen',
      确认提交: 'Einreichen',
      离开练习: 'Übung verlassen',
      不显示子分类的课程: 'Unterkategorien ausblenden',
      放弃练习成功: 'Erfolg Abbrechen',
      上传图片: 'Bild hochladen',
      最多上传x张图片: 'Maximal {0} Bilder hochladbar',
      图片大小不能超过x: 'Bildgröße darf {0} nicht überschreiten',
      请上传图片格式的文件: 'Bitte laden Sie eine Datei im Bildformat hoch',
      加载失败: 'Laden fehlgeschlagen'
    }
  },
  es: {
    coaching: {
      首页: 'Inicio',
      培训: 'Entrenamiento',
      courseManagement: 'Curso',
      courseClassification: 'Categoría de cursos',
      searchByCourseName: 'Buscar por nombre de curso',
      noResults: 'No se encontraron resultados relacionados con "{0}"',
      defaultClassification: 'Categoría predeterminada',
      creator: 'Creador',
      pleaSeselectCreator: 'Seleccione el creador',
      courseType: 'Tipo de curso',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      newCourse: 'Crear nuevo curso',
      totalNum: '{0} en total',
      perPage: '{0} por página',
      noCourses: 'No hay cursos disponibles',
      addClassification: 'Agregar categoría',
      newHasVar: 'Crear {0}',
      category: 'Categoría',
      nameHasVar: '{0} Nombre',
      cannotBeEmpty: 'No puede estar vacío',
      pleaseEnterContent: 'Por favor ingrese el contenido',
      publicScope: 'Permisos',
      visibleToAll: 'Visible para todos',
      visibleToSpecified: 'Visible para departamentos o miembros específicos',
      pleaseSelectMembers: 'Seleccione miembros',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      savedSuccessfully: 'Guardado exitoso',
      rename: 'Renombrar',
      renameHasVar: 'Renombrar {0}',
      renameClassification: 'Renombrar categoría',
      editPublicScope: 'Editar permisos',
      search: 'Buscar',
      delete: 'Eliminar',
      note: 'Nota',
      deleteCategoryWarning: 'Después de eliminar la categoría, los cursos en la categoría se moverán a la categoría superior o a la categoría predeterminada, ¿desea eliminar?',
      deletedSuccessfully: 'Eliminado con éxito',
      onlySomeCreatorCanModifyTheClassification: 'Solo el creador puede modificar la categoría',
      newScriptReading: 'Crear curso - Lectura de guión',
      newScriptRecitation: 'Nueva recitación de guión',
      newScriptPractice: 'Nueva práctica de guión',
      newAICustomer: 'Crear cliente de IA',
      newAICustomerHasVar: 'Crear cliente de IA {0}',
      basicInformation: 'Información básica',
      courseName: 'Nombre del curso',
      pleaseFillInTheCourseName: 'Por favor ingrese el nombre del curso',
      courseNameCannotBeRepeated: 'El nombre del curso no puede repetirse',
      maximumCharacters: 'Máximo {0} caracteres',
      courseContent: 'Contenido del curso',
      addKnowledgePointsAndScripts: 'Agregar puntos de conocimiento del guión',
      pleaseAddScriptPoints: 'Por favor, añade un guión',
      courseRules: 'Reglas del curso',
      readingRequirements: 'Requisitos de lectura',
      EachScriptNeedsRead: '{0} requisito: Cada párrafo debe ser {1} {2} veces, y la puntuación no debe ser inferior a {3} puntos',
      selectScript: 'Seleccionar guión',
      save: 'Guardar',
      newCourseScriptRecitation: 'Crear curso - Recitación de guión',
      courseNameNoRepeat: 'El nombre del curso no puede repetirse',
      max20Char: 'Máximo 20 caracteres',
      pleaseAddScriptPpoints: 'Por favor agregue puntos de guión',
      recitationRequirements: 'Requisitos de recitación',
      eachScriptNeedsToBeRecitedOnce: 'Cada guión debe leerse una vez y la puntuación no debe ser inferior a 60 cada vez (puntuación máxima: 100)',
      closedBookAssessment: 'Examen cerrado',
      newScriptPracticeCourse: 'Nuevo curso de práctica de guión',
      newBlankCourse: 'Crear nuevo curso',
      useTemplate: 'usar',
      undefined: 'Respuestas del estudiante',
      editCourseScriptPractice: 'Editar curso - Práctica de guión',
      addNode: 'Agregar nodo',
      narration: 'Narrador',
      customerSays: 'El cliente dice',
      addThroughKnowledgeBase: 'Agregar Punto de Conocimiento',
      scriptPractice: 'Práctica de guión',
      aiGeneratedCourse: 'Crear curso de IA',
      saveAsTemplate: 'Guardar como plantilla',
      templateName: 'Nombre de la plantilla',
      max15Characters: 'Máximo 15 caracteres',
      enterTemplateName: 'Ingrese el nombre de la plantilla',
      templateRemarks: 'Notas de la plantilla',
      Max30Characters: 'Máximo 30 caracteres',
      scriptPracticeTour1: 'Arrastre el nodo al lienzo para crear una tarjeta de este tipo de nodo.',
      skip: 'Saltar',
      next: 'Siguiente paso',
      scriptPracticeTour2: 'Haga clic para seleccionar la tarjeta y puede editar el contenido de la tarjeta.',
      previous: 'Paso anterior',
      scriptPracticeTour3: 'Arrastre el punto para iniciar la conexión y conecte dos tarjetas; después de seleccionar la conexión, presione la tecla de retroceso para eliminar la conexión.',
      scriptPracticeTour4: 'Después de construir el flujo de diálogo, puede realizar pruebas de diálogo aquí.',
      scriptPracticeTour5: 'Los cursos con alta reutilización se pueden guardar como plantillas.',
      finish: 'Completado',
      narrationConfigure: 'Se utiliza para indicar la situación del usuario, el contexto del cliente, etc.',
      narrationContent: 'Contenido del narrador',
      narrationExample: 'Por ejemplo: el otro lado es un cliente de primera compra.',
      customerSaysContent: 'Lo que el cliente quiere decir',
      customerSaysExample: 'Por ejemplo: ¿Por qué es tan caro?',
      addScript: 'Agregar guión',
      customerSaysConfigure: 'Configure varias líneas de guión, el cliente virtual dirá una de ellas al azar.',
      searchScript: 'Buscar guión',
      searchKnowledgeBaseScript: 'Buscar guión',
      allKnowledgeBase: 'Toda la base de conocimientos',
      checkedScript: 'Seleccionado: 0 guiones',
      searchTips: 'No hay recomendaciones, ¡intente buscar!',
      add: 'Añadir',
      salesSaysExample: 'Por ejemplo: Nuestros productos son de alta calidad',
      jumpingRules: 'Reglas de salto',
      normalJump: 'Salto normal hacia adelante',
      conditionJump: 'Salto condicional',
      knowledgeBase: 'Biblioteca de conocimientos',
      knowledgeBaseEmpty: 'El contenido de la biblioteca de conocimientos está vacío',
      importData: 'Importar datos',
      downloadTemplateTitle: 'Haga clic en el botón de descarga para descargar el archivo de plantilla que necesita importar',
      downloadTemplate: 'Descargar plantilla',
      scriptDialogueTemplate: 'Plantilla de práctica de guión',
      uploadFileTitle: 'Una vez que haya completado el llenado del archivo, cargue el archivo en el área de abajo',
      uploadFileTip: 'Haga clic o arrastre el archivo aquí para subirlo',
      supportsExtensions: 'Extensiones soportadas: xls, xlsx',
      courseSettings: 'Configuración del curso',
      practiceMode: 'Modo de práctica',
      voiceMode: 'Modo de voz',
      textMode: 'Modo de texto',
      scriptPrompt: 'Sugerencias de discurso',
      shortcutKeys: 'Atajos',
      moveCanvas: 'Mover el lienzo',
      moveCanvasTip: 'Arrastre el lienzo con el botón izquierdo del ratón',
      zoomCanvas: 'Zoom en el lienzo',
      zoomCanvasTip: 'Control + desplazamiento del ratón',
      boxSelection: 'Selección de cuadro / múltiple',
      boxSelectionTip: 'Shift + ratón',
      copy: 'Copiar',
      paste: 'Pegar',
      deleteTip: 'Tarjeta de nodo seleccionada / línea de conexión + tecla de retroceso',
      undo: 'Deshacer',
      redo: 'Rehacer',
      dialogueTest: 'Prueba de diálogo',
      conversationEnds: 'Fin de la conversación',
      retest: 'Volver a probar',
      start: 'Empezar',
      courseNamePlaceholder: 'Por favor, introduzca el nombre del curso',
      dialogueScene: 'Escenario de diálogo',
      sceneCountLimitTips: 'Por favor, agregue un guión a al menos un escenario',
      department: 'Departamento',
      pleaseSelectDepartment: 'Por favor, seleccione un departamento',
      customer: 'Cliente',
      sends: 'Segundos',
      today: 'Hoy',
      startTime: 'Fecha de inicio',
      chinese: 'Chino',
      english: 'Inglés',
      returnToList: 'Volver a la lista',
      restart: 'Empezar de nuevo',
      scriptReading: 'Lectura de guión',
      scriptRecitation: 'Recitación de guión',
      aICustomer: 'Cliente AI',
      aICustomerHasVar: 'Cliente AI {0}',
      lastWeek: 'La última semana',
      lastMonth: 'El último mes',
      lastThreeMonths: 'Los últimos tres meses',
      newTask: 'Nueva tarea',
      taskName: 'Nombre de la tarea',
      learnCourse: 'Curso de estudio',
      executor: 'Ejecutor',
      startAndEndTime: 'Fecha de inicio y fin',
      edit: 'Editar',
      changeClassification: 'Cambiar categoría',
      moveTo: 'Mover a',
      selectClassification: 'Seleccionar categoría',
      deleteCourse: 'Eliminar curso',
      tipsForDeletingCourses: 'Eliminar el curso no afectará las tareas ya asignadas, ¿desea eliminar este curso?',
      searchByName: 'Buscar por nombre',
      pleaseSelectExecutor: 'Por favor, seleccione al ejecutor',
      status: 'Estado',
      notStarted: 'No iniciado',
      inProgress: 'en curso',
      completed: 'Completado',
      deadlinePassed: 'Cerrado',
      aiClient: 'Cliente AI',
      noTrainingTasksYet: 'Aún no hay tareas de entrenamiento',
      learningCourse: 'Curso de estudio',
      operation: 'Operación',
      details: 'Detalles',
      noData: 'No hay datos',
      assigner: 'Persona asignada',
      deleteTask: 'Eliminar tarea',
      deleteTaskPrompt: 'La tarea ya ha comenzado, ¿estás seguro de que quieres eliminarla?',
      deleteSuccessful: 'Eliminación exitosa',
      copyTask: 'Copiar tarea',
      pleaseEnterTaskName: 'Por favor, introduzca el nombre de la tarea',
      pleaseSelectCourse: 'Por favor, seleccione un curso',
      completionStatus: 'Estado de finalización',
      taskScoreRange: 'Rango de puntuación de la tarea',
      below60Points: 'Menos de 60 puntos',
      above60Points: 'Más de 60 puntos',
      custom: 'Personalizado',
      notCompleted: 'No completado',
      noScoreYet: 'Sin puntuación',
      exportTable: 'Exportar tabla',
      keyScripts: 'Guión clave',
      sceneAverageScore: 'Puntuación media de la escena:{0} puntos',
      standardScript: 'Guión estándar',
      intelligentReview: 'Revisión inteligente',
      matchingPoints: 'Puntos acertados',
      onlyNone: 'Ninguno',
      missingPoints: 'Puntos perdidos',
      dialogueRecord: 'Registro de diálogo',
      aIReview: 'Revisión AI',
      abilityAnalysis: 'Análisis de habilidades',
      myTasks: 'Mi tarea',
      searchByAssignee: 'Buscar por asignador',
      deadline: 'Fecha límite',
      goPractice: 'Practicar',
      back: 'Regresar',
      assignedBy: 'Asignado por {0}',
      participantsHasVar: '{0} personas participando',
      startPractice: 'Comenzar práctica',
      conversationContent: 'Contenido de la conversación',
      endConversation: 'Finalizar conversación',
      endThePracticeEarlyWarning: 'El cliente todavía tiene algunas preguntas sin hacer, las preguntas no hechas se calificarán con 0, ¿desea finalizar la práctica antes de tiempo?',
      sendNextWarning: 'Una vez completada una ronda de conversación, se puede enviar la siguiente línea de diálogo',
      practiceIsCompleted: 'Todas las preguntas de interés del cliente se han hecho, la práctica se ha completado',
      continuePractice: 'Continuar practicando',
      leaveAICustomer: 'Salir del cliente AI',
      quitHalfwayWarning: 'Si sale a mitad de camino, la próxima vez tendrá que comenzar desde el principio, ¿desea salir?',
      leave: 'Salir',
      ByUploadingRecording: 'Hablar con el cliente subiendo una grabación',
      gotIt: 'Entendido',
      pleaseStartSpeaking: 'Por favor, comienza a hablar',
      voiceNotRecognized: 'No se detectó ninguna voz, por favor, compruebe el micrófono',
      话术提示: 'Sugerencia de guión',
      backToTaskList: 'Regresar a la lista de tareas',
      viewPracticeDetails: 'Ver detalles de la práctica',
      completeCoachingTips: 'Has completado este entrenamiento',
      rePractice: 'Practicar de nuevo',
      practiceScore: 'Puntuación de la práctica',
      preparing: 'Preparándose',
      recitationAssessment: 'Evaluación de recitación',
      progress: 'Progreso',
      deadlineHasVar: '{0} Fecha límite',
      commonCustomerQuestions: 'Preguntas frecuentes del cliente',
      noQuestions: 'No hay preguntas',
      answeringSkills: 'Técnicas de respuesta',
      noAnsweringSkills: 'No hay técnicas de respuesta',
      finalScore: 'Puntuación final',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: 'La práctica no está completa, no hay puntuación',
      scoreDesc: '"Si el número requerido de prácticas es 1, la puntuación final = la puntuación de la mejor práctica;Si el número requerido de prácticas es N, la puntuación final = la puntuación promedio de las mejores prácticas de N"',
      完成练习: 'Completar práctica',
      questionName: 'Nombre del tema',
      questionScore: 'Puntuación del tema',
      taskScore: 'Puntuación de la tarea',
      completedQuestionsCount: 'Número de temas completados',
      progressCompletion: 'Progreso completado',
      to: ' a ',
      pleaseSelectHasVar: 'Por favor seleccione {0}',
      all: 'Todo',
      teamView: 'Vista de equipo',
      assignedByMe: 'Asignado por mí',
      score: 'Puntuación',
      practiceRecord: 'Registro de práctica',
      comprehensiveEvaluation: 'Evaluación integral',
      noKeyPhrasesAvailable: 'No hay frases clave temporales',
      scenarioPhrasesBeingGenerated: 'Generando guiones de escena',
      notTriggered: 'No activado',
      noCommentsAvailable: 'No hay comentarios por el momento',
      dialogueRecordBeingGenerated: 'Generando registros de diálogo',
      commenting: 'Comentando',
      prompt: 'Sugerencia',
      exit: 'Salir',
      scenarioPlaceholder: 'Por favor, añade guiones de escena',
      scenceNameRepeat: 'El nombre de la escena no puede ser duplicado',
      quickReply: 'Envío rápido',
      replyPlaceholder: 'Introduce una respuesta (Enter para enviar)',
      intelligentGenerating: 'Generación inteligente en progreso',
      generationFailed: 'Falló la generación',
      clickToRetry: 'Haz clic para reintentar',
      generateDialogueTip: 'Por favor, genera el diálogo primero',
      generateNode: 'Generar nodo',
      scoringRules: 'Reglas de puntuación',
      scoreByKeyPoints: 'Puntuación por puntos',
      noScoring: 'No puntuar',
      courseUnavailableTip: 'Si no se configuran los puntos clave, el curso no podrá ser utilizado',
      generating: 'Generando',
      aiGenerateKeyPoint: 'Generado por IA',
      addKeyPoint: 'Añadir puntos clave',
      copySuccessfully: 'Copia exitosa',
      cannotCopyInfo: 'Por favor, selecciona un nodo antes de copiar',
      cannotPasteInfo: 'El portapapeles está vacío, no se puede pegar',
      pasteSuccessfully: 'Pegado exitoso',
      generatedSuccessfully: 'Generación exitosa',
      noNeedCompleteCard: 'No hay tarjetas de nodo que necesiten completar los puntos clave por el momento',
      ruleIsGenerating: 'Generando puntos clave',
      whenRuleHit: 'Cuando se acierta',
      whenRuleMiss: 'Cuando se falla',
      noScoreRuleConfigured: 'Sin puntos clave',
      addScriptTip: 'Por favor, elija el discurso que desea agregar',
      templateNameTip: 'Por favor, introduzca el nombre de la plantilla',
      inputMaxLength: 'Puede introducir hasta {0} caracteres',
      scriptLengthLimit: 'El contenido excede el límite de 1000 caracteres, por favor reduzca y luego agregue',
      addSuccess: 'Agregado con éxito',
      addScriptPlaceholder: 'Por favor, elija el discurso que desea agregar',
      checkedKnowledgeScript: 'Seleccionado: {0} puntos de conocimiento',
      smartTrainingUseTip: 'Puede arrastrar y soltar los componentes de la izquierda en el lienzo para crear tarjetas, luego conectar las tarjetas para completar la configuración del flujo de diálogo',
      roleNamePlaceholder: 'Por favor, introduzca el nombre del personaje',
      noConditionsHit: 'Cuando ninguna de las condiciones anteriores se cumple',
      ruleHit: 'Golpeado',
      ruleMiss: 'No golpeado',
      ruleKeywordPlaceholder: 'Rellene los puntos clave del contenido, hasta 5, Enter para completar',
      templateInstructionsTip: 'Por favor, lea cuidadosamente las instrucciones en la plantilla,',
      templateRequiredFormat: 'y rellene los datos importados según el formato requerido',
      autoCompletePoints: 'puntos completos',
      createCourseScriptPractice: 'Crear un nuevo curso - Práctica de guión',
      passingScore: 'Puntuación de aprobado',
      fullScore: '(Puntuación máxima: 100)',
      modelLanguage: 'Idioma del modelo',
      modelLanguageTip: 'El idioma del modelo afecta la capacidad del modelo para extraer y puntuar puntos clave en diferentes idiomas',
      isImporting: 'Importando...',
      ' notRefreshOrLeaveTip': 'Por favor, no actualice ni abandone, y mantenga la red abierta',
      importCompleted: 'Importación completada',
      importAgain: 'Importar de nuevo',
      parsingFailed: 'Análisis fallido',
      fileContentNotMatch: 'El contenido del archivo no coincide, por favor modifique y vuelva a subir',
      reUpload: 'Volver a subir',
      uploadFailed: 'Subida fallida',
      fileNotExistTip: 'El archivo no existe, por favor vuelva a subir',
      editTask: 'Editar tarea',
      expand: 'Expandir',
      collapse: 'Colapsar',
      creationTime: 'Hora de creación',
      noMatchingResults: 'No hay resultados coincidentes',
      goToCreate: 'Ir a crear',
      pleaseSelectTheStartTime: 'Por favor, seleccione la hora de inicio',
      pleaseSelectTheEndTime: 'Por favor, seleccione la hora de finalización',
      onlyMainSpaceAreDisplayed: 'Solo se muestran los miembros cuyo espacio principal es el espacio actual',
      numberOfCoursesCannotExceed: 'El número de cursos no puede exceder {0}',
      pleaseSelectTheTime: 'Por favor, seleccione la hora',
      duplicate: 'Copia',
      editedSuccessfully: 'Edición exitosa',
      createdSuccessfully: 'Creación exitosa',
      goToView: 'Ir a ver',
      learnMore: 'Aprender más',
      removeFoldersWarning: 'Los puntos de conocimiento en la carpeta se eliminarán junto con ella, ¿está seguro de que desea eliminar esta carpeta?',
      points: 'Puntos',
      pointsHasVar: '{0} Puntos ',
      onlyTheTaskCompletionStatus: 'Solo se muestran los resultados de las tareas completadas por los ejecutores con permiso de visualización',
      times: '{0} veces',
      someRhetoricIsNotCompleted: 'Algunos discursos no están completos',
      noViewingPermission: 'No tiene permiso para ver',
      averageScoreOfAllQuestions: 'La puntuación del curso es igual al promedio de todas las calificaciones de las preguntas',
      exportSuccessful: 'Exportación exitosa',
      comment: 'Comentario',
      scoreNotLessThanPoints: '{0} Requisitos: {1} La puntuación no debe ser inferior a {2} puntos',
      practiceAgain: 'Practicar de nuevo',
      startTraining: 'Comenzar la formación',
      taskHasEnded: 'La tarea ha terminado',
      taskHasEndedAndCannotBePracticed: 'La tarea ha terminado, no se puede practicar',
      answerSheet: 'Tarjeta de respuesta',
      connecting: 'Conectando',
      keyIssue: 'Pregunta clave',
      noContent: 'No hay contenido',
      sessionEnded: 'La conversación ha terminado',
      leavingTheScriptForPractice: 'Dejar la práctica de guión',
      pleaseOpenTheMicrophone: 'Por favor, abra el permiso de grabación del micrófono o compruebe el estado de la conexión del dispositivo',
      sessionRecoverySuccessful: 'La conversación se ha recuperado con éxito',
      sureToLeave: 'Tiene una grabación sin terminar, ¿está seguro de que quiere salir?',
      detailsOfKnowledgePointExercises: 'Detalles de la práctica del punto de conocimiento',
      conversationWithCustomersByEnteringText: 'Hable con el cliente introduciendo texto',
      sessionModelDoesNotExist: 'El modelo de conversación no existe, no se puede continuar la práctica',
      taskHasBeenDeleted: 'La tarea ha sido eliminada, no se puede continuar la práctica',
      sessionCreationFailed: 'La creación de la conversación ha fallado, por favor intente de nuevo',
      sessionRecoveryFailed: 'La recuperación de la conversación ha fallado, por favor intente de nuevo',
      connectionHasBeenDisconnected: 'La conexión se ha interrumpido, por favor reconéctese',
      endSessionEarly: 'Terminar la conversación anticipadamente',
      conductingTestWarning: 'Por favor, termine la grabación actual antes de hacer la prueba!',
      proceedingWithOtherRecordingsWarning: 'Por favor, termine la grabación actual antes de hacer otra grabación!',
      deleteKnowledgePointWarning: '¿Está seguro de que quiere eliminar este punto de conocimiento?',
      systemVerifyDataTip: 'El sistema verificará la precisión del formato de los datos. Después de la verificación sin errores, los datos se importarán al sistema',
      addConditions: 'Nuevas condiciones',
      conversationMaterialEmptyTip: 'El material de diálogo no puede estar vacío',
      conversationSceneEmptyTip: 'El escenario de diálogo no puede estar vacío',
      readAloud: 'Leer en voz alta',
      recite: 'Recitar de memoria',
      chooseLanguagePlaceholder: 'Seleccione el idioma del modelo',
      simplifiedChinese: 'Chino simplificado',
      englishLanguage: 'Inglés',
      frenchLanguage: 'Francés',
      germanLanguage: 'Alemán',
      spanishLanguage: 'Español',
      portugueseLanguage: 'Portugués',
      italianLanguage: 'Italiano',
      train: 'Entrenamiento',
      intelligentGenerationConversation: 'Generación inteligente de diálogos',
      taskAllocationTour: 'La tarea asignada se gestiona aquí',
      InternalError: 'Error interno del servidor',
      deleteTemplateConfirm: '¿Estás seguro de que quieres eliminar esta plantilla?',
      operationSuccessful: 'Operación exitosa',
      noSearchResult: 'No se encontraron resultados relacionados',
      scoringPointsAreBeingGenerated: 'Generando puntos de calificación, solo se pueden asignar tareas después de completar la generación',
      unconfiguredScoringRules: 'El curso no está disponible, por favor complétalo antes de asignar tareas',
      dialogueImportTemplate: 'Plantilla de importación de diálogo',
      fileFormatTip: 'Elija un archivo en formato xlsx o xls',
      importSuccessful: 'Importación exitosa',
      taskManagement: 'Tarea',
      deletedTask: 'La tarea ha sido eliminada',
      switchoverSuccessful: 'Cambio exitoso',
      voicePromptIsTurnedOn: 'Indicación de voz activada',
      voicePromptIsTurnedOff: 'Indicación de voz desactivada',
      theHighestScore: 'Más alto',
      startNow: 'Comenzar inmediatamente',
      timedStart: 'Comenzar programado',
      confirmDeletion: '¿Estás seguro de que quieres eliminar?',
      leavingTheCourse: 'Deja el curso',
      successfullyMoved: 'Movimiento exitoso',
      thereIsNoMore: 'No hay más',
      dialogueMaterials: 'Material',
      editCourse: 'Editar curso',
      完成了任务: 'ha completado la tarea',
      任务完成通知: 'Notificación de finalización de tarea',
      '完成了任务，整个任务已完成': 'ha completado la tarea',
      完成了你的任务: 'ha completado la tarea',
      删除了任务: 'ha eliminado la tarea',
      任务删除通知: 'Notificación de eliminación de tarea',
      分配给我的任务: 'me ha asignado una tarea',
      任务执行通知: 'Notificación de ejecución de tarea',
      给我分配了一个任务: 'me ha asignado una tarea',
      noTaskCurrently: 'Actualmente, no hay tareas',
      goToTraining: 'Proceder al contenido de la capacitación',
      executive: 'Ejecutor',
      completionNotice: 'Aviso de finalización',
      enforcementNotice: 'Aviso de ejecución',
      allRead: 'Todo leído',
      task: 'Tarea',
      messageCenter: 'Centro de mensajes',
      notification: 'Notificación',
      review: 'Comentario',
      tutor: 'Tutoría',
      bulletin: 'Información',
      allAreSetToReadSuccessfully: 'Marcado como leído con éxito',
      noKnowledgeBaseAvailable: 'No hay base de conocimientos disponible',
      noNetwork: 'Sin red',
      serverException: 'Excepción del servidor, por favor intente de nuevo más tarde',
      parameterError: 'Error de parámetro',
      requestTimeout: 'Solicitud de tiempo de espera',
      requestTimeoutTryAgain: 'Solicitud de tiempo de espera, por favor revise la red o intente de nuevo más tarde',
      loginStatusChangeTip: 'El estado de inicio de sesión del usuario ha cambiado, volverá a la página de inicio pronto',
      contactAdministrator: 'Por favor contacte al administrador',
      loginFailureTip: 'Recordatorio de inicio de sesión no válido',
      loginAgain: 'Iniciar sesión de nuevo',
      noAccessRights: 'Sin permiso de acceso, por favor contacte al administrador',
      customerNotExist: 'El cliente no existe o no tiene derecho a ver',
      scorePointPlaceholder: 'Ingrese los puntos clave',
      ratingInProgress: 'Por favor, espere la evaluación',
      courseScore: 'Puntuación del curso',
      courseTotalScoreCalculationRule: 'Reglas de cálculo de la puntuación total del curso',
      theAverageScoreOfEachSentence: 'Puntuación media de cada frase',
      theSumOfPointsForEachSentence: 'Suma de puntuaciones para cada frase',
      singleSentencePassMark: 'Puntuación de aprobado para una sola frase',
      theProportionOfTheSentenceFullMarks: 'Puntuación completa para la frase',
      courseTotalPassingMark: 'Puntuación de aprobado del curso',
      sendAndWrapPlaceholder: 'Enter para enviar, Ctrl+Enter para saltar de línea',
      courseMix: 'Combinación de cursos',
      totalOfSectionsHasVar: 'Total de {0} secciones',
      pointsLostSection: 'Sección de Puntos Perdidos',
      courseAnalysis: 'Análisis del curso',
      detailsOfPointsLost: 'Detalles de los puntos perdidos',
      errantMember: 'miembro errante',
      commonMistakes: 'Errores comunes',
      peopleMissedVar: '{0} personas no acertaron',
      getCourseScore: 'Puntuación del curso',
      cumulativeScore: 'Puntuación acumulada',
      cumulativeScoreRange: 'Rango de puntuación',
      overview: 'Resumen',
      noIncorrectAnswers: 'No hay respuestas incorrectas',
      questionScoreRange: 'Rango de puntuación del tema',
      keyPointExtractionFailed: 'La extracción de puntos clave falló debido a la presencia de vocabulario sensible; se recomienda rellenar manualmente los puntos clave',
      weight: 'Peso',
      probability: 'Probabilidad',
      taskRecord: 'Registro de tareas',
      courseTaskListTip: 'Mostrar solo las tareas que incluyen este curso y para las cuales hay permiso para ver',
      someKeyPointExtractionFailed: 'La extracción parcial de puntos clave falló, el contenido del script activó el control de riesgos, se recomienda la entrada manual',
      averageStudentScore: 'Puntuación Media del Estudiante',
      averageStudentScoreTip: 'Solo se calculan las puntuaciones de los miembros que practican',
      coursesVar: '{0} cursos',
      hasReminded: 'Recordatorios de hoy enviados',
      remindAll: 'Recordar a todos',
      不提醒: 'Sin recordatorio',
      '5 minutes': 'Recordatorio de plazo de 5 minutos',
      '15 minutes': 'Recordatorio de plazo de 15 minutos',
      '30 minutes': 'Recordatorio de plazo de 30 minutos',
      '1 hours': 'Recordatorio de plazo de 1 hora',
      '2 hours': 'Recordatorio de plazo de 2 horas',
      '4 hours': 'Recordatorio de plazo de 4 horas',
      '1 days': 'Recordatorio de plazo de 1 día',
      '3 days': 'Recordatorio de plazo de 3 días',
      confirmReminderTitle: 'Confirmar recordatorio',
      confirmReminderContent: '¿Le gustaría enviar un recordatorio a los miembros que no han completado la tarea para que la terminen lo antes posible?',
      提醒你尽快完成任务: 'le recuerda completar la tarea a tiempo.',
      expirationReminder: 'Recordatorio de vencimiento',
      任务到期提醒: 'Recordatorio de vencimiento',
      '任务还有5分钟截止，请及时完成': 'La tarea termina en 5 minutos, por favor completa pronto.',
      '任务还有15分钟截止，请及时完成': 'La tarea termina en 15 minutos, por favor completa pronto.',
      '任务还有30分钟截止，请及时完成': 'La tarea termina en 30 minutos, por favor completa pronto.',
      '任务还有1小时截止，请及时完成': 'La tarea termina en 1 hora, por favor completa pronto.',
      '任务还有2小时截止，请及时完成': 'La tarea termina en 2 horas, por favor completa pronto.',
      '任务还有4小时截止，请及时完成': 'La tarea termina en 4 horas, por favor completa pronto.',
      '任务还有1天截止，请及时完成': 'La tarea termina en 1 día, por favor completa pronto.',
      '任务还有3天截止，请及时完成': 'La tarea termina en 3 días, por favor completa pronto.',
      保存为常用执行人组合: 'Guardar como Combo Favorito',
      存为常用: 'Guardar como Favorito',
      导入常用: 'Importar Favoritos',
      导入: 'Importar',
      导入常用执行人组合: 'Importar Combo Favorito',
      组合名称: 'Nombre del Combo',
      最多保存30个常用组合: 'Guardar hasta 30 Combos Favoritos',
      全部催促成功: 'Recordatorio enviado',
      提醒时间: 'hora de recordatorio',
      '因成员或权限变动，暂无可用执行人': 'Debido a cambios en los miembros o permisos, temporalmente no hay ejecutores disponibles',
      请输入名称: 'Por favor, introduzca un nombre',
      '请输入分类名称，最多20个字': 'Por favor, introduzca el nombre de la categoría, hasta 20 caracteres',
      管理任务分类: 'Gestión de Categorías de Tareas',
      '暂无分类，点击左下方添加分类': 'No hay categorías disponibles, haga clic en la parte inferior izquierda para añadir una categoría',
      添加分类: 'Añadir Categoría',
      删除分类: 'Eliminar Categoría',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'Después de eliminar una categoría, también se eliminarán las etiquetas de categoría de cualquier tarea asignada. ¿Desea eliminar?',
      分类: 'Categoría',
      无分类: 'Sin categorizar',
      任务分类: 'Categoría de Tarea',
      分类数量最多30: 'Un máximo de 30 categorías',
      该名称已重复: 'El nombre ya ha sido duplicado',
      编辑模板公开范围: 'Editar el alcance público de la plantilla',
      '练习平均分=学员所有已完成课程得分的平均分': 'puntuación media = media de las puntuaciones de todos los cursos completados por el participante',
      暂只支持剧本对练课程类型的错误汇总: 'Resumen de errores sólo para los tipos de cursos de Prácticas de Guión, por el momento',
      错误汇总: 'Resumen de errores',
      所属课程: 'Curso',
      youCanAddScripts: 'Puede agregar guiones al curso y asignar el curso a los estudiantes a través de tareas, requiriéndoles que lean los guiones',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'Puede agregar guiones al curso y asignar el curso a los estudiantes a través de tareas, requiriéndoles que reciten los guiones',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': 'Una vez habilitado, los estudiantes solo pueden ver los primeros 10 caracteres del guión antes de practicar, y no pueden ver el guión completo',
      salespersonSays: 'El estudiante dice',
      salesSaysConfigure: 'Configure el guión estándar para que el estudiante diga',
      salesSaysContent: 'Lo que el estudiante necesita decir',
      jumpTip: 'Puede llenar puntos clave, como "insulto". Si el discurso del estudiante contiene contenido insultante, saltará al siguiente paso según las condiciones',
      scriptPromptTip: 'Una vez habilitado, los estudiantes pueden ver las indicaciones del guión durante la práctica',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'Los clientes de IA dialogarán con los estudiantes en base al guión de la base de conocimientos que elija, iniciarán preguntas y puntuarán.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Establezca diferentes escenarios de diálogo, agregue guiones a los escenarios, y los clientes de IA harán preguntas de guión para evaluar a los estudiantes',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'Los campos de retrato del cliente ayudan cuando los estudiantes hacen preguntas de sondeo, los clientes de IA responden en base a esto',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': 'Al comienzo del diálogo, puede explicar información a los estudiantes a través de apartes, como precauciones de práctica, información de fondo de práctica, etc.',
      startAndEndTimeTip: 'Después de la fecha límite, los estudiantes ya no pueden practicar el curso',
      salesResponse: 'Respuestas del estudiante',
      salesSaysContentTip: 'Por favor, complete primero lo que el estudiante necesita decir',
      autoCompleteConfirmContent: 'Hay {0} tarjetas de habla de estudiantes sin puntos clave configurados, este curso no está disponible, ¿confirma salir? Puede hacer clic en el botón "Completar" para completar rápidamente los puntos clave',
      salesContent: 'Cuando el estudiante necesita decir algo',
      finalScoreOfAICustomerCourse: 'La puntuación final del curso del cliente de IA = la puntuación más alta de la práctica del estudiante',
      selfIntroduction: 'Hola, soy un estudiante de la empresa {0}, ¿en qué puedo ayudarte?',
      dialogueExample: 'El cliente recibió una llamada del estudiante, el estudiante presentó el producto al cliente, el cliente espera entender completamente si las características del producto satisfacen sus necesidades, y espera comprar el producto a un precio más barato.',
      taskScoreDescription: 'Según la configuración del curso, la puntuación del curso = la puntuación promedio o total de todas las preguntas del estudiante',
      scoreReminder: 'Cuando el rendimiento de un estudiante está por debajo de la puntuación de aprobación, se le dará una puntuación roja para recordarles que su rendimiento no es calificado',
      salesmanConfigureTip: 'Cuando un nodo está conectado a varias tarjetas de habla de estudiantes al mismo tiempo, se seleccionará la tarjeta de habla de estudiante más similar para puntuar y saltar en base al discurso real del estudiante',
      validateEdgeMessage: 'Cuando se inician múltiples líneas desde un solo punto, el objetivo solo puede ser una tarjeta de habla de estudiante, y se pueden conectar un máximo de 5.',
      放弃练习: 'Abandonar práctica',
      暂存离开: 'Guardar y salir',
      '确认提交本次练习？': 'Confirmar envío de la práctica',
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Otro método está en proceso o ya ha completado esta práctica, por favor, inténtalo más tarde',
      继续练习: 'Continuar práctica',
      开始第x次练习: 'Comenzar la práctica número {0}',
      继续第x次练习: 'Continuar la práctica número {0}',
      '任务截止前可继续练习，确认离开？': 'Puedes seguir practicando antes de la fecha límite, ¿confirmas que quieres salir?',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Otro método está en proceso o ya ha completado esta práctica, no se puede continuar con el método actual',
      失败提示: 'Aviso de fallo',
      提前完成练习: 'Completar la práctica anticipadamente',
      任务截止前可继续本次练习: 'Puedes continuar con esta práctica antes de la fecha límite',
      退出并清除本次练习记录: 'Salir y borrar el registro de esta práctica',
      确认提交: 'Enviar',
      离开练习: 'Salir del ejercicio',
      不显示子分类的课程: 'Ocultar subcategorías',
      放弃练习成功: 'Abandono Exitoso',
      上传图片: 'Subir imagen',
      最多上传x张图片: 'Máximo de {0} imágenes para subir',
      图片大小不能超过x: 'El tamaño de la imagen no debe superar {0}',
      请上传图片格式的文件: 'Por favor, suba un archivo en formato de imagen',
      加载失败: 'Fallo en la carga'
    }
  },
  pt: {
    coaching: {
      首页: 'Inicio',
      培训: 'Treinamento',
      courseManagement: 'Curso',
      courseClassification: 'Classificação de cursos',
      searchByCourseName: 'Pesquisar por nome de curso',
      noResults: 'Nenhum resultado relacionado a {0} foi encontrado',
      defaultClassification: 'Classificação padrão',
      creator: 'Criador',
      pleaSeselectCreator: 'Por favor, selecione o criador',
      courseType: 'Tipo de curso',
      startDate: 'Data de início',
      endDate: 'Data de término',
      newCourse: 'Novo curso',
      totalNum: '{0} no total',
      perPage: '{0} por página',
      noCourses: 'Nenhum curso disponível',
      addClassification: 'Adicionar classificação',
      newHasVar: 'Criar {0}',
      category: 'Categoria',
      nameHasVar: '{0} Nome',
      cannotBeEmpty: 'Não pode estar vazio',
      pleaseEnterContent: 'Por favor, insira o conteúdo',
      publicScope: 'Permissões',
      visibleToAll: 'Visível para todos',
      visibleToSpecified: 'Visível para departamentos ou membros específicos',
      pleaseSelectMembers: 'Por favor, selecione os membros',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      savedSuccessfully: 'Salvo com sucesso',
      rename: 'Renomear',
      renameHasVar: 'Renomear {0}',
      renameClassification: 'Renomear classificação',
      editPublicScope: 'Editar permissões',
      search: 'Pesquisar',
      note: 'Dica',
      deleteCategoryWarning: 'Após excluir a categoria, os cursos na categoria serão movidos para a categoria superior ou padrão, deseja excluir?',
      deletedSuccessfully: 'Excluído com sucesso',
      onlySomeCreatorCanModifyTheClassification: 'Apenas o criador pode modificar a categoria',
      newScriptReading: 'Novo curso - Discurso de leitura',
      newScriptRecitation: 'Nova recitação de roteiro',
      newScriptPractice: 'Nova prática de roteiro',
      newAICustomer: 'Novo cliente de IA',
      newAICustomerHasVar: 'Novo cliente de IA {0}',
      basicInformation: 'Informação básica',
      courseName: 'Nome do curso',
      pleaseFillInTheCourseName: 'Por favor, insira o nome do curso',
      courseNameCannotBeRepeated: 'O nome do curso não pode ser repetido',
      maximumCharacters: 'Máximo de {0} caracteres',
      courseContent: 'Conteúdo do curso',
      addKnowledgePointsAndScripts: 'Adicionar pontos de conhecimento de discurso',
      pleaseAddScriptPoints: 'Por favor, adicione um script',
      courseRules: 'Regras do curso',
      readingRequirements: 'Requisitos de leitura',
      EachScriptNeedsRead: '{0} requisito: Cada parágrafo precisa ser {1} {2} vezes, e a pontuação não deve ser inferior a {3} pontos',
      selectScript: 'Selecione o discurso',
      save: 'Salvo',
      newCourseScriptRecitation: 'Novo curso - Discurso de recitação',
      courseNameNoRepeat: 'O nome do curso não pode ser repetido',
      max20Char: 'Máximo de 20 caracteres',
      pleaseAddScriptPpoints: 'Por favor, adicione pontos de discurso',
      recitationRequirements: 'Requisitos de recitação',
      eachScriptNeedsToBeRecitedOnce: 'Cada discurso deve ser lido uma vez e a pontuação não deve ser inferior a 60 pontos (pontuação máxima: 100)',
      closedBookAssessment: 'Exame fechado',
      newScriptPracticeCourse: 'Novo curso de prática de roteiro',
      newBlankCourse: 'Criar novo curso',
      delete: 'Excluir',
      useTemplate: 'usar',
      undefined: 'Respostas do aluno',
      editCourseScriptPractice: 'Editar curso - Prática de roteiro',
      addNode: 'Adicionar nó',
      narration: 'Narrador',
      customerSays: 'O cliente diz',
      addThroughKnowledgeBase: 'Adicionar Ponto de Conhecimento',
      scriptPractice: 'Prática de roteiro',
      aiGeneratedCourse: 'Curso gerado por IA',
      saveAsTemplate: 'Salvar como modelo',
      templateName: 'Nome do modelo',
      max15Characters: 'Máximo de 15 caracteres',
      enterTemplateName: 'Por favor, insira o nome do modelo',
      templateRemarks: 'Observações do modelo',
      Max30Characters: 'Máximo de 30 caracteres',
      scriptPracticeTour1: 'Arraste o nó para a tela para criar um cartão do tipo de nó',
      skip: 'Pular',
      next: 'Próximo',
      scriptPracticeTour2: 'Clique para selecionar o cartão e edite o conteúdo do cartão',
      previous: 'Anterior',
      scriptPracticeTour3: 'Arraste o ponto para iniciar a conexão e conecte dois cartões; selecione a conexão e pressione a tecla de retrocesso para excluí-la.',
      scriptPracticeTour4: 'Após a construção do fluxo de diálogo, você pode testar o diálogo aqui',
      scriptPracticeTour5: 'Os cursos com alta reutilização podem ser salvos como modelos',
      finish: 'Concluído',
      narrationConfigure: 'Usado para fornecer informações sobre o cenário em que o usuário está, o fundo do cliente, etc.',
      narrationContent: 'Conteúdo do narrador',
      narrationExample: 'Por exemplo: O outro lado é um cliente de primeira compra',
      customerSaysContent: 'O que o cliente quer dizer',
      customerSaysExample: 'Por exemplo: Por que o preço é tão alto?',
      addScript: 'Adicionar discurso',
      customerSaysConfigure: 'Configure várias falas, o cliente virtual falará uma delas aleatoriamente',
      searchScript: 'Pesquisar discurso',
      searchKnowledgeBaseScript: 'Pesquisar script',
      allKnowledgeBase: 'Toda a base de conhecimento',
      checkedScript: 'Selecionado: 0 discursos',
      searchTips: 'Nenhuma recomendação, tente pesquisar',
      add: 'Adicionar',
      salesSaysExample: 'Por exemplo: Nossos produtos têm excelente qualidade',
      jumpingRules: 'Regras de salto',
      normalJump: 'Pular normalmente',
      conditionJump: 'Pular por condição',
      knowledgeBase: 'Base de conhecimento',
      knowledgeBaseEmpty: 'O conteúdo da base de conhecimento está vazio',
      importData: 'Importar dados',
      downloadTemplateTitle: 'Clique no botão de download para baixar o arquivo de modelo que precisa ser importado',
      downloadTemplate: 'Baixar modelo',
      scriptDialogueTemplate: 'Modelo de prática de roteiro',
      uploadFileTitle: 'Após preencher o arquivo, faça o upload na área abaixo',
      uploadFileTip: 'Clique ou arraste o arquivo aqui para fazer o upload',
      supportsExtensions: 'Suporta extensões: xls, xlsx',
      courseSettings: 'Configurações do curso',
      practiceMode: 'Modo de prática',
      voiceMode: 'Modo de voz',
      textMode: 'Modo de texto',
      scriptPrompt: 'Dicas de discurso',
      shortcutKeys: 'Teclas de atalho',
      moveCanvas: 'Mover tela',
      moveCanvasTip: 'Arraste a tela com o botão esquerdo do mouse',
      zoomCanvas: 'Zoom da tela',
      zoomCanvasTip: 'Control + roda do mouse',
      boxSelection: 'Seleção / Seleção múltipla',
      boxSelectionTip: 'Shift + mouse',
      copy: 'Copiar',
      paste: 'Colar',
      deleteTip: 'Selecione o cartão do nó / linha de conexão + tecla de retrocesso',
      undo: 'Desfazer',
      redo: 'Refazer',
      dialogueTest: 'Teste de diálogo',
      conversationEnds: 'Fim da conversa',
      retest: 'Teste novamente',
      start: 'Começar',
      courseNamePlaceholder: 'Por favor, insira o nome do curso',
      dialogueScene: 'Cenário de diálogo',
      sceneCountLimitTips: 'Por favor, adicione diálogos para pelo menos um cenário',
      department: 'Departamento',
      pleaseSelectDepartment: 'Por favor, selecione um departamento',
      customer: 'Cliente',
      sends: 'Segundos',
      today: 'Hoje',
      startTime: 'Hora de início',
      chinese: 'Chinês',
      english: 'Inglês',
      returnToList: 'Voltar para a lista',
      restart: 'Recomeçar',
      scriptReading: 'Leitura de script',
      scriptRecitation: 'Memorização de script',
      aICustomer: 'Cliente AI',
      aICustomerHasVar: 'Cliente AI {0}',
      lastWeek: 'Última semana',
      lastMonth: 'Último mês',
      lastThreeMonths: 'Últimos três meses',
      newTask: 'Nova tarefa',
      taskName: 'Nome da tarefa',
      learnCourse: 'Curso de estudo',
      executor: 'Executor',
      startAndEndTime: 'Período',
      edit: 'Editar',
      changeClassification: 'Alterar categoria',
      moveTo: 'Mover para',
      selectClassification: 'Selecione uma categoria',
      deleteCourse: 'Excluir curso',
      tipsForDeletingCourses: 'Excluir o curso não afetará as tarefas atribuídas. Deseja excluir este curso?',
      searchByName: 'Pesquisar por nome',
      pleaseSelectExecutor: 'Por favor, selecione o executor',
      status: 'Status',
      notStarted: 'Não iniciado',
      inProgress: 'Em progresso',
      completed: 'Concluído',
      deadlinePassed: 'Encerrado',
      aiClient: 'Cliente AI',
      noTrainingTasksYet: 'Ainda não há tarefas de treinamento',
      learningCourse: 'Curso de estudo',
      operation: 'Operação',
      details: 'Detalhes',
      noData: 'Sem dados',
      assigner: 'Pessoa designada',
      deleteTask: 'Excluir tarefa',
      deleteTaskPrompt: 'Esta tarefa já começou, você confirma a exclusão?',
      deleteSuccessful: 'Exclusão bem sucedida',
      copyTask: 'Copiar tarefa',
      pleaseEnterTaskName: 'Por favor, insira o nome da tarefa',
      pleaseSelectCourse: 'Por favor, selecione o curso',
      completionStatus: 'Status de Conclusão',
      taskScoreRange: 'Faixa de pontuação da tarefa',
      below60Points: 'Abaixo de 60 pontos',
      above60Points: 'Acima de 60 pontos',
      custom: 'Personalizado',
      notCompleted: 'Não concluído',
      noScoreYet: 'Sem pontuação',
      exportTable: 'Exportar tabela',
      keyScripts: 'Script chave',
      sceneAverageScore: 'Pontuação média da cena: {0} pontos',
      standardScript: 'Script padrão',
      intelligentReview: 'Revisão inteligente',
      matchingPoints: 'Pontos acertados',
      onlyNone: 'Nenhum',
      missingPoints: 'Pontos perdidos',
      dialogueRecord: 'Registro de diálogo',
      aIReview: 'Revisão AI',
      abilityAnalysis: 'Análise de habilidades',
      myTasks: 'A minha tarefa',
      searchByAssignee: 'Pesquisar por pessoa atribuída',
      deadline: 'Data limite',
      goPractice: 'Praticar',
      back: 'Voltar',
      assignedBy: 'Atribuído por {0}',
      participantsHasVar: '{0} pessoas participando',
      startPractice: 'Começar a prática',
      conversationContent: 'Conteúdo da conversa',
      endConversation: 'Terminar a conversa',
      endThePracticeEarlyWarning: 'O cliente ainda tem algumas perguntas não feitas, as perguntas não feitas serão resolvidas com 0 pontos, você quer terminar a prática antecipadamente?',
      sendNextWarning: 'Após uma rodada de conversa ser completada, a próxima fala pode ser enviada',
      practiceIsCompleted: 'Todas as perguntas de interesse do cliente foram feitas, a prática está concluída',
      continuePractice: 'Continuar a prática',
      leaveAICustomer: 'Deixar o cliente AI',
      quitHalfwayWarning: 'Se você sair no meio, terá que começar a prática do início na próxima vez, você quer sair?',
      leave: 'Sair',
      ByUploadingRecording: 'Converse com o cliente fazendo upload de uma gravação',
      gotIt: 'Eu entendi',
      pleaseStartSpeaking: 'Por favor, comece a falar',
      voiceNotRecognized: 'Nenhuma voz detectada, por favor, verifique o microfone',
      话术提示: 'Dica de roteiro',
      backToTaskList: 'Voltar à lista de tarefas',
      viewPracticeDetails: 'Ver detalhes da prática',
      completeCoachingTips: 'Você concluiu este treinamento',
      rePractice: 'Praticar novamente',
      practiceScore: 'Pontuação da prática',
      preparing: 'Preparando',
      recitationAssessment: 'Avaliação de memorização',
      progress: 'Progresso',
      deadlineHasVar: '{0} Prazo',
      commonCustomerQuestions: 'Perguntas frequentes do cliente',
      noQuestions: 'Sem perguntas',
      answeringSkills: 'Dicas de resposta',
      noAnsweringSkills: 'Sem dicas de resposta',
      finalScore: 'Pontuação final',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: 'A prática não está concluída, sem pontuação',
      scoreDesc: '"Se o número de práticas exigidas for 1, a pontuação final = a pontuação da prática com melhor desempenho;Se o número de práticas exigidas for N, a pontuação final = a média das pontuações das N práticas com melhor desempenho"',
      完成练习: 'Concluir a prática',
      questionName: 'Nome do problema',
      questionScore: 'Pontuação do problema',
      taskScore: 'Pontuação da tarefa',
      completedQuestionsCount: 'Número de problemas concluídos',
      progressCompletion: 'Progresso concluído',
      to: ' a ',
      pleaseSelectHasVar: 'Por favor, selecione {0}',
      all: 'Total',
      teamView: 'Visão da equipe',
      assignedByMe: 'Atribuído por mim',
      score: 'Pontuação',
      practiceRecord: 'Registro de exercícios',
      comprehensiveEvaluation: 'Avaliação geral',
      noKeyPhrasesAvailable: 'Sem palavras-chave temporárias',
      scenarioPhrasesBeingGenerated: 'Geração de script de cenário em andamento',
      notTriggered: 'Não acionado',
      noCommentsAvailable: 'Sem comentários por enquanto',
      dialogueRecordBeingGenerated: 'Geração de registro de diálogo em andamento',
      commenting: 'Comentando',
      prompt: 'Dica',
      exit: 'Sair',
      scenarioPlaceholder: 'Por favor, adicione um script de cenário',
      scenceNameRepeat: 'O nome do cenário não pode ser duplicado',
      quickReply: 'Envio rápido',
      replyPlaceholder: 'Digite uma resposta (Enter para enviar)',
      intelligentGenerating: 'Geração inteligente em andamento',
      generationFailed: 'Falha na geração',
      clickToRetry: 'Clique para tentar novamente',
      generateDialogueTip: 'Por favor, gere o diálogo primeiro',
      generateNode: 'Gerar nó',
      scoringRules: 'Regras de pontuação',
      scoreByKeyPoints: 'Pontuação por pontos',
      noScoring: 'Sem pontuação',
      courseUnavailableTip: 'Se os pontos-chave não forem configurados, o curso não poderá ser usado',
      generating: 'Gerando',
      aiGenerateKeyPoint: 'Gerado por IA',
      addKeyPoint: 'Adicionar pontos-chave',
      copySuccessfully: 'Cópia bem sucedida',
      cannotCopyInfo: 'Por favor, selecione o nó antes de copiar',
      cannotPasteInfo: 'A área de transferência está vazia, não pode colar',
      pasteSuccessfully: 'Colagem bem sucedida',
      generatedSuccessfully: 'Geração bem sucedida',
      noNeedCompleteCard: 'Sem cartões de nó que precisam ser preenchidos com pontos-chave por enquanto',
      ruleIsGenerating: 'Gerando pontos-chave',
      whenRuleHit: 'Quando acertar',
      whenRuleMiss: 'Quando errar',
      noScoreRuleConfigured: 'Sem pontos chave',
      addScriptTip: 'Por favor, escolha a fala que deseja adicionar',
      templateNameTip: 'Por favor, insira o nome do modelo',
      inputMaxLength: 'Você pode inserir no máximo {0} caracteres',
      scriptLengthLimit: 'O conteúdo excede o limite de 1000 caracteres, por favor, reduza e adicione novamente',
      addSuccess: 'Adicionado com sucesso',
      addScriptPlaceholder: 'Por favor, escolha a fala que deseja adicionar',
      checkedKnowledgeScript: 'Selecionado: {0} pontos de conhecimento',
      smartTrainingUseTip: 'Você pode arrastar o componente à esquerda para a tela para criar um cartão, e então conectar os cartões para configurar o fluxo de diálogo',
      roleNamePlaceholder: 'Por favor, insira o nome do personagem',
      noConditionsHit: 'Quando nenhuma das condições acima for atendida',
      ruleHit: 'Acertou',
      ruleMiss: 'Não acertou',
      ruleKeywordPlaceholder: 'Preencha os pontos principais do conteúdo, no máximo 5, Enter para concluir',
      templateInstructionsTip: 'Por favor, leia atentamente as instruções no modelo,',
      templateRequiredFormat: 'e preencha os dados importados de acordo com o formato solicitado',
      autoCompletePoints: 'pontos completos',
      createCourseScriptPractice: 'Novo curso - Prática de roteiro',
      passingScore: 'Nota de aprovação',
      fullScore: '(Pontuação máxima: 100)',
      modelLanguage: 'Idioma do modelo',
      modelLanguageTip: 'O idioma do modelo afeta a capacidade do modelo de extrair pontos principais e pontuar em diferentes idiomas',
      isImporting: 'Importando...',
      ' notRefreshOrLeaveTip': "Por favor, não atualize ou saia, e mantenha a conexão com a internet'",
      importCompleted: 'Importação concluída',
      importAgain: 'Importar novamente',
      parsingFailed: 'Falha na análise',
      fileContentNotMatch: 'O conteúdo do arquivo não corresponde, por favor, modifique e faça o upload novamente',
      reUpload: 'Fazer upload novamente',
      uploadFailed: 'Falha no upload',
      fileNotExistTip: 'O arquivo não existe, por favor, faça o upload novamente',
      editTask: 'Editar tarefa',
      expand: 'Expandir',
      collapse: 'Recolher',
      creationTime: 'Data de criação',
      noMatchingResults: 'Nenhum resultado correspondente',
      goToCreate: 'Ir para criar',
      pleaseSelectTheStartTime: 'Por favor, selecione a data de início',
      pleaseSelectTheEndTime: 'Por favor, selecione a data de término',
      onlyMainSpaceAreDisplayed: 'Apenas membros com o espaço atual como espaço principal são exibidos',
      numberOfCoursesCannotExceed: 'O número de cursos não pode exceder {0}',
      pleaseSelectTheTime: 'Por favor, selecione a data',
      duplicate: 'Cópia',
      editedSuccessfully: 'Edição bem sucedida',
      createdSuccessfully: 'Criação bem sucedida',
      goToView: 'Ir para visualizar',
      learnMore: 'Saiba mais',
      removeFoldersWarning: 'Os pontos de conhecimento na pasta serão excluídos juntamente, confirma a exclusão desta pasta?',
      points: 'Pontos',
      pointsHasVar: '{0} pontos',
      onlyTheTaskCompletionStatus: 'Apenas os executores com permissão de visualização são exibidos',
      times: '{0} vezes',
      someRhetoricIsNotCompleted: 'Algumas falas não foram concluídas',
      noViewingPermission: 'Sem permissão para visualizar',
      averageScoreOfAllQuestions: 'A pontuação do curso é igual à média de todas as notas das questões',
      exportSuccessful: 'Exportação bem sucedida',
      comment: 'Comentário',
      scoreNotLessThanPoints: '{0} requisitos: {1} pontuação não inferior a {2} pontos',
      practiceAgain: 'Praticar novamente',
      startTraining: 'Iniciar treinamento',
      taskHasEnded: 'A tarefa foi encerrada',
      taskHasEndedAndCannotBePracticed: 'A tarefa foi encerrada, não é possível praticar',
      answerSheet: 'Cartão de resposta',
      connecting: 'Conectando',
      keyIssue: 'Questão chave',
      noContent: 'Sem conteúdo',
      sessionEnded: 'A conversa terminou',
      leavingTheScriptForPractice: ' Deixe a prática do roteiro',
      pleaseOpenTheMicrophone: 'Por favor, abra a permissão de gravação do microfone ou verifique o status da conexão do dispositivo',
      sessionRecoverySuccessful: 'A conversa foi retomada com sucesso',
      sureToLeave: 'Você tem uma gravação não concluída, tem certeza de que deseja sair?',
      detailsOfKnowledgePointExercises: 'Detalhes da prática do ponto de conhecimento',
      conversationWithCustomersByEnteringText: 'Converse com o cliente digitando texto',
      sessionModelDoesNotExist: 'O modelo de conversa não existe, não é possível continuar a prática',
      taskHasBeenDeleted: 'A tarefa foi excluída, não é possível continuar a prática',
      sessionCreationFailed: 'Falha na criação da conversa, por favor, tente novamente',
      sessionRecoveryFailed: 'Falha na recuperação da conversa, por favor, tente novamente',
      connectionHasBeenDisconnected: 'A conexão foi interrompida, por favor, reconecte',
      endSessionEarly: 'Encerrar a conversa antecipadamente',
      conductingTestWarning: 'Por favor, termine a gravação atual antes de testar!',
      proceedingWithOtherRecordingsWarning: 'Por favor, termine a gravação atual antes de fazer outra gravação!',
      deleteKnowledgePointWarning: 'Confirma a exclusão deste ponto de conhecimento?',
      systemVerifyDataTip: 'O sistema verificará a precisão do formato dos dados. Após a verificação sem erros, os dados serão importados para o sistema.',
      addConditions: 'Novas condições',
      conversationMaterialEmptyTip: 'O material de diálogo não pode estar vazio',
      conversationSceneEmptyTip: 'A cena de diálogo não pode estar vazia',
      readAloud: 'Leitura',
      recite: 'Recitação',
      chooseLanguagePlaceholder: 'Por favor, selecione o idioma do modelo',
      simplifiedChinese: 'Chinês simplificado',
      englishLanguage: 'Inglês',
      frenchLanguage: 'Francês',
      germanLanguage: 'Alemão',
      spanishLanguage: 'Espanhol',
      portugueseLanguage: 'Português',
      italianLanguage: 'Italiano',
      train: 'Treinamento',
      intelligentGenerationConversation: 'Geração inteligente de diálogo',
      taskAllocationTour: 'A gestão das tarefas atribuídas é realizada aqui',
      InternalError: 'Erro interno do servidor',
      deleteTemplateConfirm: 'Tem certeza de que deseja excluir este modelo?',
      operationSuccessful: 'Operação bem sucedida',
      noSearchResult: 'Nenhum resultado relevante encontrado',
      scoringPointsAreBeingGenerated: 'Os pontos de classificação estão sendo gerados, as tarefas só podem ser atribuídas após a conclusão',
      unconfiguredScoringRules: 'O curso não está disponível, por favor complete-o antes de atribuir tarefas',
      dialogueImportTemplate: 'Modelo de importação de diálogo',
      fileFormatTip: 'Escolha um arquivo no formato xlsx ou xls',
      importSuccessful: 'Importação bem sucedida',
      taskManagement: 'Tarefa',
      deletedTask: 'A tarefa foi excluída',
      switchoverSuccessful: 'Mudança bem sucedida',
      voicePromptIsTurnedOn: 'Prompt de voz ativado',
      voicePromptIsTurnedOff: 'Prompt de voz desativado',
      theHighestScore: 'Mais alto',
      startNow: 'Comece imediatamente',
      timedStart: 'Comece programado',
      confirmDeletion: 'Tem certeza de que deseja excluir?',
      leavingTheCourse: 'Deixe o curso',
      successfullyMoved: 'Movimento bem sucedido',
      thereIsNoMore: 'Não há mais',
      dialogueMaterials: 'Material',
      editCourse: 'Editar curso',
      完成了任务: 'concluiu a tarefa',
      任务完成通知: 'Notificação de conclusão de tarefa',
      '完成了任务，整个任务已完成': 'concluiu a tarefa',
      完成了你的任务: 'concluiu a tarefa',
      删除了任务: 'excluiu a tarefa',
      任务删除通知: 'Notificação de exclusão de tarefa',
      分配给我的任务: 'me atribuiu uma tarefa',
      任务执行通知: 'Notificação de execução de tarefa',
      给我分配了一个任务: 'me atribuiu uma tarefa',
      noTaskCurrently: 'Atualmente, não há tarefas',
      goToTraining: 'Prossiga para o conteúdo do treinamento',
      executive: 'Executor',
      completionNotice: 'Aviso de conclusão',
      enforcementNotice: 'Aviso de execução',
      allRead: 'Tudo lido',
      task: 'Tarefa',
      messageCenter: 'Centro de mensagens',
      notification: 'Notificação',
      review: 'Comentário',
      tutor: 'Tutoria',
      bulletin: 'Briefing',
      allAreSetToReadSuccessfully: 'Marcado como lido com sucesso',
      noKnowledgeBaseAvailable: 'Nenhuma base de conhecimento disponível',
      noNetwork: 'Sem rede',
      serverException: 'Exceção do servidor, por favor tente novamente mais tarde',
      parameterError: 'Erro de parâmetro',
      requestTimeout: 'Solicitação de tempo limite',
      requestTimeoutTryAgain: 'Solicitação de tempo limite, verifique a rede ou tente novamente mais tarde',
      loginStatusChangeTip: 'O status de login do usuário mudou, retornará à página inicial em breve',
      contactAdministrator: 'Por favor, entre em contato com o administrador',
      loginFailureTip: 'Lembrete de login inválido',
      loginAgain: 'Faça login novamente',
      noAccessRights: 'Sem permissão de acesso, por favor, entre em contato com o administrador',
      customerNotExist: 'O cliente não existe ou não tem direito de visualizar',
      scorePointPlaceholder: 'Insira os pontos chave',
      ratingInProgress: 'Por favor, aguarde a avaliação',
      courseScore: 'Pontuação do curso',
      courseTotalScoreCalculationRule: 'Regras de cálculo da pontuação total do curso',
      theAverageScoreOfEachSentence: 'Pontuação média de cada frase',
      theSumOfPointsForEachSentence: 'Soma das pontuações para cada frase',
      singleSentencePassMark: 'Pontuação de aprovação para uma única frase',
      theProportionOfTheSentenceFullMarks: 'Pontuação total para a frase',
      courseTotalPassingMark: 'Pontuação de aprovação do curso',
      sendAndWrapPlaceholder: 'Enter para enviar, Ctrl+Enter para quebrar a linha',
      courseMix: 'Combinação de Cursos',
      totalOfSectionsHasVar: 'Total de {0} seções',
      pointsLostSection: 'Seção de Pontos Perdidos',
      courseAnalysis: 'Análise do Curso',
      detailsOfPointsLost: 'Detalhes dos Pontos Perdidos',
      errantMember: 'membro errante',
      commonMistakes: 'Erros Comuns',
      peopleMissedVar: '{0} pessoas erraram',
      getCourseScore: 'Pontuação do Curso',
      cumulativeScore: 'Pontuação Cumulativa',
      cumulativeScoreRange: 'Intervalo de Pontuação',
      overview: 'Visão Geral',
      noIncorrectAnswers: 'Sem Respostas Incorretas',
      questionScoreRange: 'Faixa de Pontuação do Tópico',
      keyPointExtractionFailed: 'A extração dos pontos-chave falhou devido à presença de vocabulário sensível; é recomendado preencher manualmente os pontos-chave',
      weight: 'Peso',
      probability: 'Probabilidade',
      taskRecord: 'Registro de tarefas',
      courseTaskListTip: 'Exibir apenas as tarefas que incluem este curso e para as quais existe permissão para visualizar',
      someKeyPointExtractionFailed: 'A extração parcial dos pontos-chave falhou, o conteúdo do script acionou o controle de risco, é recomendado o input manual',
      averageStudentScore: 'Pontuação Média do Aluno',
      averageStudentScoreTip: 'Apenas as pontuações dos membros praticantes são calculadas',
      coursesVar: '{0} cursos',
      hasReminded: 'Lembretes de hoje enviados',
      remindAll: 'Lembrar todos',
      不提醒: 'Sem lembrete',
      '5 minutes': 'Lembrete de prazo de 5 minutos',
      '15 minutes': 'Lembrete de prazo de 15 minutos',
      '30 minutes': 'Lembrete de prazo de 30 minutos',
      '1 hours': 'Lembrete de prazo de 1 hora',
      '2 hours': 'Lembrete de prazo de 2 horas',
      '4 hours': 'Lembrete de prazo de 4 horas',
      '1 days': 'Lembrete de prazo de 1 dia',
      '3 days': 'Lembrete de prazo de 3 dias',
      confirmReminderTitle: 'Confirmar lembrete',
      confirmReminderContent: 'Gostaria de enviar um lembrete aos membros que não completaram a tarefa para que a terminem o mais rápido possível?',
      提醒你尽快完成任务: 'lembra você de completar a tarefa prontamente.',
      expirationReminder: 'Lembrete de expiração',
      任务到期提醒: 'Lembrete de expiração',
      '任务还有5分钟截止，请及时完成': 'A tarefa termina em 5 minutos, por favor complete prontamente.',
      '任务还有15分钟截止，请及时完成': 'A tarefa termina em 15 minutos, por favor complete prontamente.',
      '任务还有30分钟截止，请及时完成': 'A tarefa termina em 30 minutos, por favor complete prontamente.',
      '任务还有1小时截止，请及时完成': 'A tarefa termina em 1 hora, por favor complete prontamente.',
      '任务还有2小时截止，请及时完成': 'A tarefa termina em 2 horas, por favor complete prontamente.',
      '任务还有4小时截止，请及时完成': 'A tarefa termina em 4 horas, por favor complete prontamente.',
      '任务还有1天截止，请及时完成': 'A tarefa termina em 1 dia, por favor complete prontamente.',
      '任务还有3天截止，请及时完成': 'A tarefa termina em 3 dias, por favor complete prontamente.',
      保存为常用执行人组合: 'Salvar como Combo Favorito',
      存为常用: 'Salvar como Favorito',
      导入常用: 'Importar Favoritos',
      导入: 'Importar',
      导入常用执行人组合: 'Importar Combo Favorito',
      组合名称: 'Nome do Combo',
      最多保存30个常用组合: 'Salvar até 30 Combos Favoritos',
      全部催促成功: 'Lembrete enviado',
      提醒时间: 'hora do lembrete',
      '因成员或权限变动，暂无可用执行人': 'Devido a alterações nos membros ou permissões, temporariamente não há executores disponíveis',
      请输入名称: 'Por favor, insira um nome',
      '请输入分类名称，最多20个字': 'Por favor, insira o nome da categoria, até 20 caracteres',
      管理任务分类: 'Gestão de Categorias de Tarefas',
      '暂无分类，点击左下方添加分类': 'Não há categorias disponíveis, clique no canto inferior esquerdo para adicionar uma categoria',
      添加分类: 'Adicionar Categoria',
      删除分类: 'Excluir Categoria',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'Após excluir uma categoria, as tags de categoria de quaisquer tarefas atribuídas também serão removidas. Você quer excluir?',
      分类: 'Categoria',
      无分类: 'Não categorizado',
      任务分类: 'Categoria de Tarefa',
      分类数量最多30: 'Um máximo de 30 categorias',
      该名称已重复: 'O nome já foi duplicado',
      编辑模板公开范围: 'Editar o Escopo Público do Modelo',
      '练习平均分=学员所有已完成课程得分的平均分': 'pontuação média = média de todas as pontuações dos cursos concluídos para o participante',
      暂只支持剧本对练课程类型的错误汇总: 'Resumo dos erros apenas para os tipos de cursos Script Practice, por enquanto',
      错误汇总: 'Resumo dos erros',
      所属课程: 'Curso',
      youCanAddScripts: 'Você pode adicionar scripts ao curso e atribuir o curso aos alunos através de tarefas, exigindo que eles leiam os scripts',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'Você pode adicionar scripts ao curso e atribuir o curso aos alunos através de tarefas, exigindo que eles recitem os scripts',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': 'Uma vez ativado, os alunos só podem ver os primeiros 10 caracteres do script antes de praticar, e não podem visualizar o script inteiro',
      salespersonSays: 'Aluno diz',
      salesSaysConfigure: 'Configure o script padrão para o aluno dizer',
      salesSaysContent: 'O que o aluno precisa dizer',
      jumpTip: 'Você pode preencher pontos-chave, como "insulto". Se o discurso do aluno contiver conteúdo insultuoso, ele passará para a próxima etapa de acordo com as condições',
      scriptPromptTip: 'Uma vez ativado, os alunos podem visualizar dicas de script durante a prática',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'Os clientes de IA dialogarão com os alunos com base no script da base de conhecimento que você escolher, farão perguntas e pontuarão.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Estabeleça diferentes cenários de diálogo, adicione scripts aos cenários, e os clientes de IA farão perguntas de script para avaliar os alunos',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'Os campos de retrato do cliente ajudam quando os alunos fazem perguntas investigativas, os clientes de IA respondem com base nisso',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': 'No início do diálogo, você pode explicar informações aos alunos através de apartes, como precauções de prática, informações de fundo de prática, etc.',
      startAndEndTimeTip: 'Após o prazo, os alunos não podem mais praticar o curso',
      salesResponse: 'Respostas do aluno',
      salesSaysContentTip: 'Por favor, preencha primeiro o que o aluno precisa dizer',
      autoCompleteConfirmContent: 'Existem {0} cartões de fala do aluno sem pontos-chave configurados, este curso não está disponível, você confirma a saída? Você pode clicar no botão "Concluir" para completar rapidamente os pontos-chave',
      salesContent: 'Quando o aluno precisa dizer algo',
      finalScoreOfAICustomerCourse: 'A pontuação final do curso do cliente de IA = a pontuação mais alta da prática do aluno',
      selfIntroduction: 'Olá, sou aluno da empresa {0}, como posso ajudá-lo?',
      dialogueExample: 'O cliente recebeu uma ligação do aluno, o aluno apresentou o produto ao cliente, o cliente espera entender completamente se as características do produto atendem às suas necessidades e espera comprar o produto a um preço mais barato.',
      taskScoreDescription: 'De acordo com a configuração do curso, a pontuação do curso = a média ou pontuação total de todas as perguntas do aluno',
      scoreReminder: 'Quando o desempenho de um aluno está abaixo da pontuação de aprovação, uma pontuação vermelha será dada para lembrá-los de que seu desempenho não é qualificado',
      salesmanConfigureTip: 'Quando um nó está conectado a vários cartões de fala do aluno ao mesmo tempo, o cartão de fala do aluno mais semelhante será selecionado para pontuação e salto com base no discurso real do aluno',
      validateEdgeMessage: 'Quando várias linhas são iniciadas a partir de um único ponto, o alvo só pode ser um cartão de fala do aluno, e no máximo 5 podem ser conectados.',
      放弃练习: 'Abandonar Exercício',
      暂存离开: 'Guardar e Sair',
      '确认提交本次练习？': 'Confirmar Submissão do Exercício',
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Outro método está em andamento ou já completou este exercício, tente novamente mais tarde',
      继续练习: 'Continuar Exercício',
      开始第x次练习: 'Iniciar o Exercício Nº{0}',
      继续第x次练习: 'Continuar o Exercício Nº{0}',
      '任务截止前可继续练习，确认离开？': 'Deseja sair antes do prazo final do exercício?',
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Outro método está em andamento ou já completou este exercício, não é possível continuar pelo caminho atual',
      失败提示: 'Aviso de Falha',
      提前完成练习: 'Concluir Exercício Antecipadamente',
      任务截止前可继续本次练习: 'Pode continuar o exercício antes do prazo final',
      退出并清除本次练习记录: 'Sair e Apagar o Registro deste Exercício',
      确认提交: 'Submeter',
      离开练习: 'Sair do exercício',
      不显示子分类的课程: 'Ocultar subcategorias',
      放弃练习成功: 'Abandono Bem-sucedido',
      上传图片: 'Carregar imagem',
      最多上传x张图片: 'Máximo de {0} imagens permitidas',
      图片大小不能超过x: 'O tamanho da imagem não pode exceder {0}',
      请上传图片格式的文件: 'Por favor, faça o upload de um arquivo em formato de imagem',
      加载失败: 'Falha no carregamento'
    }
  },
  it: {
    coaching: {
      首页: 'Home',
      培训: 'Formazione',
      courseManagement: 'Corso',
      courseClassification: 'Categorizzazione del corso',
      searchByCourseName: 'Cerca per nome del corso',
      noResults: 'Non sono stati trovati risultati correlati a "{0}"',
      defaultClassification: 'Categoria predefinita',
      creator: 'Creatore',
      pleaSeselectCreator: 'Seleziona il creatore',
      courseType: 'Tipo di corso',
      startDate: 'Data di inizio',
      endDate: 'Data di fine',
      newCourse: 'Crea un nuovo corso',
      totalNum: 'Totale {0}',
      perPage: '{0} per pagina',
      noCourses: 'Nessun corso disponibile',
      addClassification: 'Aggiungi categoria',
      newHasVar: 'Crea {0}',
      category: 'Categoria',
      nameHasVar: '{0} Nome',
      cannotBeEmpty: 'Non può essere vuoto',
      pleaseEnterContent: 'Inserisci il contenuto',
      publicScope: 'Permessi',
      visibleToAll: 'Visibile a tutti',
      visibleToSpecified: 'Visibile solo a dipartimenti o membri specifici',
      pleaseSelectMembers: 'Seleziona i membri',
      cancel: 'Annulla',
      confirm: 'Conferma',
      savedSuccessfully: 'Salvato con successo',
      rename: 'Rinomina',
      renameHasVar: 'Rinomina {0}',
      renameClassification: 'Rinomina la categoria',
      editPublicScope: 'Modifica permessi',
      search: 'Cerca',
      delete: 'Elimina',
      note: 'Suggerimento',
      deleteCategoryWarning: 'dopo aver eliminato la categoria, i corsi nella categoria verranno spostati nella categoria superiore o nella categoria predefinita, vuoi eliminare?',
      deletedSuccessfully: 'Eliminato con successo',
      onlySomeCreatorCanModifyTheClassification: 'Solo il creatore può modificare la categoria',
      newScriptReading: 'Crea un nuovo corso - Discorso da leggere',
      newScriptRecitation: 'Nuova recitazione di script',
      newScriptPractice: 'Nuova pratica di script',
      newAICustomer: 'Crea un nuovo cliente AI',
      newAICustomerHasVar: 'Crea un nuovo cliente AI {0}',
      basicInformation: 'Informazioni di base',
      courseName: 'Nome del corso',
      pleaseFillInTheCourseName: 'Inserisci il nome del corso',
      courseNameCannotBeRepeated: 'Il nome del corso non può essere duplicato',
      maximumCharacters: 'Inserisci massimo {0} caratteri',
      courseContent: 'Contenuto del corso',
      addKnowledgePointsAndScripts: 'Aggiungi punti di discorso di conoscenza',
      pleaseAddScriptPoints: 'Per favore aggiungi un discorso',
      courseRules: 'Regole del corso',
      readingRequirements: 'Requisiti di lettura',
      EachScriptNeedsRead: '{0} requisito: Ogni paragrafo deve essere {1} {2} volte, e il punteggio non dovrebbe essere inferiore a {3} punti',
      selectScript: 'Seleziona il discorso',
      save: 'Salva',
      newCourseScriptRecitation: 'Crea un nuovo corso - Discorso da recitare',
      courseNameNoRepeat: 'Il nome del corso non può essere duplicato',
      max20Char: 'Inserisci massimo 20 caratteri',
      pleaseAddScriptPpoints: 'Aggiungi punti di discorso',
      recitationRequirements: 'Requisiti di recitazione',
      eachScriptNeedsToBeRecitedOnce: 'Ogni punto di discorso deve essere letto una volta e ogni valutazione non deve essere inferiore a 60 punti (punteggio massimo: 100)',
      closedBookAssessment: 'Esame a libro chiuso',
      newScriptPracticeCourse: 'Nuovo corso di pratica di sceneggiatura',
      newBlankCourse: 'Crea un nuovo corso',
      useTemplate: 'usare',
      undefined: 'Lo studente risponde',
      editCourseScriptPractice: 'Modifica corso - Pratica di script',
      addNode: 'Aggiungi nodo',
      narration: 'Narratore',
      customerSays: 'Il cliente dice',
      addThroughKnowledgeBase: 'Aggiungi Punto di Conoscenza',
      scriptPractice: 'Pratica di script',
      aiGeneratedCourse: 'Genera corso AI',
      saveAsTemplate: 'Salva come modello',
      templateName: 'Nome del modello',
      max15Characters: 'Puoi inserire massimo 15 caratteri',
      enterTemplateName: 'Inserisci il nome del modello',
      templateRemarks: 'Nota del modello',
      Max30Characters: 'Puoi inserire massimo 30 caratteri',
      scriptPracticeTour1: 'Trascina il nodo sulla tela per creare una carta di quel tipo di nodo',
      skip: 'Salta',
      next: 'Prossimo passo',
      scriptPracticeTour2: 'Clicca sulla carta selezionata per modificarne il contenuto',
      previous: 'Passo precedente',
      scriptPracticeTour3: 'Trascina il punto per creare una linea di connessione tra due carte; premi il tasto backspace per eliminare la linea di connessione selezionata.',
      scriptPracticeTour4: 'Dopo aver costruito il flusso di dialogo, puoi testare il dialogo qui',
      scriptPracticeTour5: 'I corsi con alta riutilizzabilità possono essere salvati come modelli',
      finish: 'Fatto',
      narrationConfigure: "Usato per dare suggerimenti all'utente sullo scenario, sullo sfondo del cliente, ecc.",
      narrationContent: 'Contenuto del narratore',
      narrationExample: "Ad esempio: l'interlocutore è un cliente per la prima volta",
      customerSaysContent: 'Cosa dice il cliente',
      customerSaysExample: 'Ad esempio: perché il prezzo è così alto?',
      addScript: 'Aggiungi discorso',
      customerSaysConfigure: 'Quando si configurano più discorsi, il cliente virtuale dirà uno di essi a caso',
      searchScript: 'Cerca discorso',
      searchKnowledgeBaseScript: 'Cerca discorso',
      allKnowledgeBase: 'Tutta la libreria di conoscenza',
      checkedScript: 'Selezionato: 0 discorsi',
      searchTips: 'Non ci sono suggerimenti, prova a cercare~',
      add: 'Aggiungi',
      salesSaysExample: 'Ad esempio: la qualità dei nostri prodotti è eccellente',
      jumpingRules: 'Regole di salto',
      normalJump: 'Salta normalmente',
      conditionJump: 'Salta in base alle condizioni',
      knowledgeBase: 'Biblioteca di conoscenza',
      knowledgeBaseEmpty: 'Il contenuto della biblioteca di conoscenza è vuoto',
      importData: 'Importa dati',
      downloadTemplateTitle: 'Clicca sul pulsante di download per scaricare il file modello che devi importare',
      downloadTemplate: 'Scarica il modello',
      scriptDialogueTemplate: 'Modello di pratica di script',
      uploadFileTitle: "Dopo aver completato la compilazione del file, carica il file nell'area sottostante",
      uploadFileTip: 'Clicca o trascina il file qui per caricarlo',
      supportsExtensions: 'Estensioni supportate: xls, xlsx',
      courseSettings: 'Impostazioni del corso',
      practiceMode: 'Modalità di esercizio',
      voiceMode: 'Modalità vocale',
      textMode: 'Modalità testuale',
      scriptPrompt: 'Suggerimenti di discorso',
      shortcutKeys: 'Scorciatoie',
      moveCanvas: 'Sposta la tela',
      moveCanvasTip: 'Trascina la tela con il pulsante sinistro del mouse',
      zoomCanvas: 'Zoom sulla tela',
      zoomCanvasTip: 'Control + rotellina del mouse',
      boxSelection: 'Selezione multipla / selezione',
      boxSelectionTip: 'Shift + mouse',
      copy: 'Copia',
      paste: 'Incolla',
      deleteTip: 'Seleziona la scheda del nodo / linea di connessione + tasto di ritorno',
      undo: 'Annulla',
      redo: 'Rifai',
      dialogueTest: 'Test di dialogo',
      conversationEnds: 'Fine della conversazione',
      retest: 'Ritestare',
      start: 'Inizia',
      courseNamePlaceholder: 'Inserisci il nome del corso',
      dialogueScene: 'Scena di dialogo',
      sceneCountLimitTips: 'Aggiungi un discorso ad almeno una scena',
      department: 'Dipartimento',
      pleaseSelectDepartment: 'Seleziona il dipartimento',
      customer: 'Cliente',
      sends: 'Secondi',
      today: 'Oggi',
      startTime: 'Data di inizio',
      chinese: 'Cinese',
      english: 'Inglese',
      returnToList: 'Torna alla lista',
      restart: 'Ricomincia',
      scriptReading: 'Lettura del discorso',
      scriptRecitation: 'Recitazione del discorso',
      aICustomer: 'AI del cliente',
      aICustomerHasVar: 'AI del cliente {0}',
      lastWeek: 'Ultima settimana',
      lastMonth: 'Ultimo mese',
      lastThreeMonths: 'Ultimi tre mesi',
      newTask: 'Nuovo compito',
      taskName: 'Nome del compito',
      learnCourse: 'Corso di studio',
      executor: 'Esecutore',
      startAndEndTime: 'Periodo',
      edit: 'Modifica',
      changeClassification: 'Cambia categoria',
      moveTo: 'Sposta a',
      selectClassification: 'Seleziona categoria',
      deleteCourse: 'Cancella il corso',
      tipsForDeletingCourses: 'Cancellare il corso non influenzerà le attività già assegnate, vuoi cancellare questo corso?',
      searchByName: 'Cerca per nome',
      pleaseSelectExecutor: "Seleziona l'esecutore",
      status: 'Stato',
      notStarted: 'Non iniziato',
      inProgress: 'In corso',
      completed: 'Completato',
      deadlinePassed: 'Chiuso',
      aiClient: 'Cliente AI',
      noTrainingTasksYet: 'Non ci sono ancora compiti di formazione',
      learningCourse: 'Corso di studio',
      operation: 'Operazione',
      details: 'Dettagli',
      noData: 'Nessun dato',
      assigner: 'Persona assegnata',
      deleteTask: 'Elimina il compito',
      deleteTaskPrompt: 'Il compito è già iniziato, sei sicuro di volerlo eliminare?',
      deleteSuccessful: 'Eliminazione riuscita',
      copyTask: 'Copia il compito',
      pleaseEnterTaskName: 'Inserisci il nome del compito',
      pleaseSelectCourse: 'Seleziona il corso',
      completionStatus: 'Stato di Completamento',
      taskScoreRange: 'Gamma di punteggio del compito',
      below60Points: 'Inferiore a 60 punti',
      above60Points: 'Superiore a 60 punti',
      custom: 'Personalizzato',
      notCompleted: 'Non completato',
      noScoreYet: 'Nessun punteggio',
      exportTable: 'Esporta la tabella',
      keyScripts: 'Discorso chiave',
      sceneAverageScore: 'Punteggio medio della scena: {0}punti',
      standardScript: 'Discorso standard',
      intelligentReview: 'Recensione intelligente',
      matchingPoints: 'Punti colpiti',
      onlyNone: 'Nessuno',
      missingPoints: 'Punti mancati',
      dialogueRecord: 'Registrazione della conversazione',
      aIReview: 'Recensione AI',
      abilityAnalysis: 'Analisi delle competenze',
      myTasks: 'La mia missione',
      searchByAssignee: 'Cerca per assegnatario',
      deadline: 'Data di scadenza',
      goPractice: 'Pratica',
      back: 'Ritorna',
      assignedBy: 'Assegnato da {0}',
      participantsHasVar: '{0} persone partecipano',
      startPractice: "Inizia l'esercizio",
      conversationContent: 'Contenuto della conversazione',
      endConversation: 'Termina la conversazione',
      endThePracticeEarlyWarning: 'Il cliente ha ancora alcune domande da fare, le domande non poste saranno valutate con 0 punti, vuoi terminare la pratica in anticipo?',
      sendNextWarning: 'Dopo che un giro di conversazione è completato, la prossima battuta può essere inviata',
      practiceIsCompleted: "Tutte le domande di interesse del cliente sono state poste, l'esercizio è completato",
      continuePractice: "Continua l'esercizio",
      leaveAICustomer: 'Lascia il cliente AI',
      quitHalfwayWarning: "Se esci a metà strada, dovrai ricominciare dall'inizio la prossima volta, vuoi uscire?",
      leave: 'Lascia',
      ByUploadingRecording: 'Parla con il cliente caricando una registrazione',
      gotIt: 'Ho capito',
      pleaseStartSpeaking: 'Per favore inizia a parlare',
      voiceNotRecognized: 'Nessuna voce rilevata, si prega di controllare il microfono',
      话术提示: 'Suggerimento per il discorso',
      backToTaskList: 'Torna alla lista dei compiti',
      viewPracticeDetails: "Visualizza i dettagli dell'esercizio",
      completeCoachingTips: 'Hai completato questa formazione',
      rePractice: "Ripeti l'esercizio",
      practiceScore: "Punteggio dell'esercizio",
      preparing: 'In preparazione',
      recitationAssessment: 'Valutazione della recitazione',
      progress: 'Progresso',
      deadlineHasVar: '{0} Scadenza',
      commonCustomerQuestions: 'Domande frequenti del cliente',
      noQuestions: 'Nessuna domanda',
      answeringSkills: 'Tecniche di risposta',
      noAnsweringSkills: 'Nessuna tecnica di risposta',
      finalScore: 'Punteggio finale',
      theExerciseHasNotBeenCompletedAndThereIsNoScore: "L'esercizio non è completato, nessun punteggio disponibile",
      scoreDesc: '"Se il numero di esercizi richiesti è 1, il punteggio finale = il punteggio del miglior esercizio;Se il numero di esercizi richiesti è N, il punteggio finale = la media dei punteggi dei migliori esercizi N"',
      完成练习: "Completa l'esercizio",
      questionName: 'Nome del problema',
      questionScore: 'Punteggio del problema',
      taskScore: 'Punteggio del compito',
      completedQuestionsCount: 'Numero di problemi completati',
      progressCompletion: 'Progresso completato',
      to: ' a ',
      pleaseSelectHasVar: 'Si prega di selezionare {0}',
      all: 'Tutto',
      teamView: 'Vista squadra',
      assignedByMe: 'Assegnato da me',
      score: 'Punteggio',
      practiceRecord: 'Registro degli esercizi',
      comprehensiveEvaluation: 'Valutazione complessiva',
      noKeyPhrasesAvailable: 'Nessun discorso chiave per ora',
      scenarioPhrasesBeingGenerated: 'Generazione del discorso di scenario in corso',
      notTriggered: 'Non attivato',
      noCommentsAvailable: 'Nessuna recensione per ora',
      dialogueRecordBeingGenerated: 'Generazione del registro delle conversazioni in corso',
      commenting: 'Recensione in corso',
      prompt: 'Suggerimento',
      exit: 'Esci',
      scenarioPlaceholder: 'Per favore aggiungi un discorso di scenario',
      scenceNameRepeat: 'Il nome dello scenario non può essere duplicato',
      quickReply: 'Invio rapido',
      replyPlaceholder: 'Inserisci risposta (Invio per inviare)',
      intelligentGenerating: 'Generazione intelligente in corso',
      generationFailed: 'Generazione fallita',
      clickToRetry: 'Clicca per riprovare',
      generateDialogueTip: 'Per favore genera prima il dialogo',
      generateNode: 'Generazione del nodo',
      scoringRules: 'Regole di valutazione',
      scoreByKeyPoints: 'Punteggio per punti',
      noScoring: 'Non valutare',
      courseUnavailableTip: 'Se non sono configurati punti chiave, il corso non sarà utilizzabile',
      generating: 'Generazione in corso',
      aiGenerateKeyPoint: 'Generato da AI',
      addKeyPoint: 'Aggiungi punti chiave',
      copySuccessfully: 'Copia riuscita',
      cannotCopyInfo: 'Per favore seleziona prima un nodo da copiare',
      cannotPasteInfo: 'La clipboard è vuota, non può essere incollata',
      pasteSuccessfully: 'Incolla con successo',
      generatedSuccessfully: 'Generazione riuscita',
      noNeedCompleteCard: 'Nessuna carta nodo che richiede punti chiave da completare per ora',
      ruleIsGenerating: 'Generazione di punti chiave',
      whenRuleHit: 'Quando colpisci',
      whenRuleMiss: 'Quando non colpisci',
      noScoreRuleConfigured: 'Nessun punto chiave',
      addScriptTip: 'Si prega di selezionare il discorso da aggiungere',
      templateNameTip: 'Si prega di inserire il nome del modello',
      inputMaxLength: 'Puoi inserire al massimo {0} caratteri',
      scriptLengthLimit: 'Il contenuto supera il limite di 1000 caratteri, si prega di ridurre e poi aggiungere',
      addSuccess: 'Aggiunto con successo',
      addScriptPlaceholder: 'Si prega di selezionare il discorso da aggiungere',
      checkedKnowledgeScript: 'Selezionato: {0} punti chiave',
      smartTrainingUseTip: 'Puoi trascinare i componenti a sinistra sulla tela, completare la creazione della scheda, quindi collegare le schede per completare la configurazione del flusso di dialogo',
      roleNamePlaceholder: 'Si prega di inserire il nome del personaggio',
      noConditionsHit: 'Quando nessuna delle condizioni sopra è soddisfatta',
      ruleHit: 'Colpito',
      ruleMiss: 'Non colpito',
      ruleKeywordPlaceholder: 'Inserisci i punti chiave del contenuto, massimo 5, Enter per completare',
      templateInstructionsTip: 'Si prega di leggere attentamente le istruzioni nel modello,',
      templateRequiredFormat: 'e compilare i dati da importare secondo il formato richiesto',
      autoCompletePoints: 'punti completi',
      createCourseScriptPractice: 'Crea un nuovo corso -  Pratica di script',
      passingScore: 'Punteggio di passaggio',
      fullScore: '(Punteggio massimo: 100)',
      modelLanguage: 'Lingua del modello',
      modelLanguageTip: 'La lingua del modello influisce sulla capacità del modello di estrarre e valutare i punti chiave in diverse lingue',
      isImporting: 'Importazione in corso...',
      ' notRefreshOrLeaveTip': 'Si prega di non aggiornare o lasciare la pagina, e mantenere la connessione a Internet',
      importCompleted: 'Importazione completata',
      importAgain: 'Importa di nuovo',
      parsingFailed: 'Analisi fallita',
      fileContentNotMatch: 'Il contenuto del file non corrisponde, si prega di modificare e caricare di nuovo',
      reUpload: 'Carica di nuovo',
      uploadFailed: 'Caricamento fallito',
      fileNotExistTip: 'Il file non esiste, si prega di caricare di nuovo',
      editTask: 'Modifica attività',
      expand: 'Espandi',
      collapse: 'Riduci',
      creationTime: 'Data di creazione',
      noMatchingResults: 'Nessun risultato corrispondente',
      goToCreate: 'Vai a creare',
      pleaseSelectTheStartTime: 'Si prega di selezionare la data di inizio',
      pleaseSelectTheEndTime: 'Si prega di selezionare la data di fine',
      onlyMainSpaceAreDisplayed: 'Mostra solo i membri con lo spazio corrente come spazio principale',
      numberOfCoursesCannotExceed: 'Il numero di corsi non può superare {0}',
      pleaseSelectTheTime: 'Si prega di selezionare il tempo',
      duplicate: 'Copia',
      editedSuccessfully: 'Modifica con successo',
      createdSuccessfully: 'Creazione riuscita',
      goToView: 'Vai a vedere',
      learnMore: 'Scopri di più',
      removeFoldersWarning: 'I punti chiave nella cartella saranno cancellati insieme, confermi di cancellare questa cartella?',
      points: 'Punti',
      pointsHasVar: '{0} Punti',
      onlyTheTaskCompletionStatus: "Mostra solo l'andamento del completamento del compito dell'esecutore con il diritto di visualizzazione",
      times: '{0} volte',
      someRhetoricIsNotCompleted: 'Alcuni discorsi non sono completi',
      noViewingPermission: 'Nessun diritto di visualizzazione',
      averageScoreOfAllQuestions: 'Il punteggio del corso è uguale alla media dei punteggi di tutte le domande',
      exportSuccessful: 'Esportazione riuscita',
      comment: 'Commento',
      scoreNotLessThanPoints: '{0} richiede: {1} il punteggio non deve essere inferiore a {2} punti',
      practiceAgain: 'Esercitati di nuovo',
      startTraining: 'Inizia la formazione',
      taskHasEnded: 'Il compito è scaduto',
      taskHasEndedAndCannotBePracticed: 'Il compito è scaduto, non è possibile esercitarsi',
      answerSheet: 'Carta di risposta',
      connecting: 'Connessione in corso',
      keyIssue: 'Domanda chiave',
      noContent: 'Nessun contenuto',
      sessionEnded: 'La conversazione è finita',
      leavingTheScriptForPractice: 'Lascia la pratica dello script',
      pleaseOpenTheMicrophone: "Si prega di aprire l'autorizzazione alla registrazione del microfono o controllare lo stato di connessione del dispositivo",
      sessionRecoverySuccessful: 'Recupero della conversazione riuscito',
      sureToLeave: 'Hai una registrazione non completata, sei sicuro di voler uscire?',
      detailsOfKnowledgePointExercises: "Dettagli dell'esercizio sui punti chiave",
      conversationWithCustomersByEnteringText: 'Interagisci con il cliente inserendo il testo',
      sessionModelDoesNotExist: "Il modello di conversazione non esiste, non è possibile continuare l'esercizio",
      taskHasBeenDeleted: "Il compito è stato cancellato, non è possibile continuare l'esercizio",
      sessionCreationFailed: 'Creazione della conversazione fallita, si prega di riprovare',
      sessionRecoveryFailed: 'Recupero della conversazione fallito, si prega di riprovare',
      connectionHasBeenDisconnected: 'La connessione è stata interrotta, si prega di riconnettersi',
      endSessionEarly: 'Termina la conversazione in anticipo',
      conductingTestWarning: 'Si prega di terminare la registrazione corrente prima di testare!',
      proceedingWithOtherRecordingsWarning: 'Si prega di terminare la registrazione corrente prima di fare altre registrazioni!',
      deleteKnowledgePointWarning: 'Confermi di cancellare questo punto chiave?',
      systemVerifyDataTip: "Il sistema verificherà l'accuratezza del formato dei dati. Dopo la verifica senza errori, i dati verranno importati nel sistema",
      addConditions: 'Nuova condizione',
      conversationMaterialEmptyTip: 'Il materiale di conversazione non può essere vuoto',
      conversationSceneEmptyTip: 'La scena di conversazione non può essere vuota',
      readAloud: 'Lettura',
      recite: 'Recitazione',
      chooseLanguagePlaceholder: 'Seleziona la lingua del modello',
      simplifiedChinese: 'Cinese semplificato',
      englishLanguage: 'Inglese',
      frenchLanguage: 'Francese',
      germanLanguage: 'Tedesco',
      spanishLanguage: 'Spagnolo',
      portugueseLanguage: 'Portoghese',
      italianLanguage: 'Italiano',
      train: 'Formazione',
      intelligentGenerationConversation: 'Generazione intelligente di conversazioni',
      taskAllocationTour: 'La gestione dei compiti assegnati avviene qui',
      InternalError: 'Errore interno del server',
      deleteTemplateConfirm: 'Confermi di voler eliminare questo modello?',
      operationSuccessful: 'Operazione riuscita',
      noSearchResult: 'Nessun risultato correlato trovato',
      scoringPointsAreBeingGenerated: 'Generazione dei punti di valutazione in corso, solo dopo la generazione è possibile assegnare i compiti',
      unconfiguredScoringRules: 'Il corso non è disponibile, si prega di completarlo prima di assegnare compiti',
      dialogueImportTemplate: 'Modello di importazione dialogo',
      fileFormatTip: 'Scegli un file in formato xlsx o xls',
      importSuccessful: 'Importazione riuscita',
      taskManagement: 'Compito',
      deletedTask: 'Il compito è stato eliminato',
      switchoverSuccessful: 'Cambio riuscito',
      voicePromptIsTurnedOn: 'Prompt vocale attivato',
      voicePromptIsTurnedOff: 'Prompt vocale disattivato',
      theHighestScore: 'Più alto',
      startNow: 'Inizia subito',
      timedStart: 'Inizia programmato',
      confirmDeletion: 'Sei sicuro di voler eliminare?',
      leavingTheCourse: 'Lascia il corso',
      successfullyMoved: 'Movimento riuscito',
      thereIsNoMore: "Non c'è più",
      dialogueMaterials: 'Materiale',
      editCourse: 'Modifica corso',
      完成了任务: 'ha completato il compito',
      任务完成通知: 'Notifica di completamento del compito',
      '完成了任务，整个任务已完成': 'ha completato il compito',
      完成了你的任务: 'ha completato il compito',
      删除了任务: 'ha cancellato il compito',
      任务删除通知: 'Notifica di cancellazione del compito',
      分配给我的任务: 'mi ha assegnato un compito',
      任务执行通知: 'Notifica di esecuzione del compito',
      给我分配了一个任务: 'mi ha assegnato un compito',
      noTaskCurrently: 'Attualmente, non ci sono compiti',
      goToTraining: 'Procedere al contenuto della formazione',
      executive: 'Esecutore',
      completionNotice: 'Avviso di completamento',
      enforcementNotice: 'Avviso di esecuzione',
      allRead: 'Tutto letto',
      task: 'Compito',
      messageCenter: 'Centro messaggi',
      notification: 'Notifica',
      review: 'Commento',
      tutor: 'Tutoraggio',
      bulletin: 'Briefing',
      allAreSetToReadSuccessfully: 'Impostato come letto con successo',
      noKnowledgeBaseAvailable: 'Nessuna base di conoscenza disponibile',
      noNetwork: 'Nessuna rete',
      serverException: 'Eccezione del server, per favore riprova più tardi',
      parameterError: 'Errore di parametro',
      requestTimeout: 'Richiesta di timeout',
      requestTimeoutTryAgain: 'Richiesta di timeout, controlla la rete o riprova più tardi',
      loginStatusChangeTip: "Lo stato di accesso dell'utente è cambiato, tornerà presto alla homepage",
      contactAdministrator: "Si prega di contattare l'amministratore",
      loginFailureTip: 'Promemoria di accesso non valido',
      loginAgain: 'Accedi di nuovo',
      noAccessRights: "Nessun permesso di accesso, si prega di contattare l'amministratore",
      customerNotExist: 'Il cliente non esiste o non ha il diritto di visualizzare',
      scorePointPlaceholder: 'Inserisci i punti chiave',
      ratingInProgress: 'Si prega di attendere la valutazione',
      courseScore: 'Punteggio del corso',
      courseTotalScoreCalculationRule: 'Regole di calcolo del punteggio totale del corso',
      theAverageScoreOfEachSentence: 'Punteggio medio di ogni frase',
      theSumOfPointsForEachSentence: 'Somma dei punteggi per ogni frase',
      singleSentencePassMark: 'Punteggio di passaggio per una singola frase',
      theProportionOfTheSentenceFullMarks: 'Punteggio pieno per la frase',
      courseTotalPassingMark: 'Punteggio di passaggio del corso',
      sendAndWrapPlaceholder: 'Invio per inviare, Ctrl+Invio per andare a capo',
      courseMix: 'Combinazione di Corsi',
      totalOfSectionsHasVar: 'Totale di {0} sezioni',
      pointsLostSection: 'Sezione dei Punti Persi',
      courseAnalysis: 'Analisi del Corso',
      detailsOfPointsLost: 'Dettagli dei Punti Persi',
      errantMember: 'membro errante',
      commonMistakes: 'Errori Comuni',
      peopleMissedVar: '{0} persone hanno sbagliato',
      getCourseScore: 'Punteggio del corso',
      cumulativeScore: 'Punteggio cumulativo',
      cumulativeScoreRange: 'Intervallo di punteggio',
      overview: 'Panoramica',
      noIncorrectAnswers: 'Nessuna Risposta Errata',
      questionScoreRange: "Intervallo di punteggio dell'argomento",
      keyPointExtractionFailed: "L'estrazione dei punti chiave è fallita a causa della presenza di vocabolario sensibile; si raccomanda di compilare manualmente i punti chiave",
      weight: 'Peso',
      probability: 'Probabilità',
      taskRecord: 'Registro delle attività',
      courseTaskListTip: 'Mostra solo le attività che includono questo corso e per le quali è permesso visualizzare',
      someKeyPointExtractionFailed: "L'estrazione parziale dei punti chiave è fallita, il contenuto dello script ha attivato il controllo del rischio, si raccomanda l'inserimento manuale",
      averageStudentScore: 'Punteggio Medio dello Studente',
      averageStudentScoreTip: 'Vengono calcolati solo i punteggi dei membri che praticano',
      coursesVar: '{0} corsi',
      hasReminded: 'Promemoria di oggi inviati',
      remindAll: 'Ricorda a tutti',
      不提醒: 'Nessun promemoria',
      '5 minutes': 'Promemoria scadenza di 5 minuti',
      '15 minutes': 'Promemoria scadenza di 15 minuti',
      '30 minutes': 'Promemoria scadenza di 30 minuti',
      '1 hours': 'Promemoria scadenza di 1 ora',
      '2 hours': 'Promemoria scadenza di 2 ore',
      '4 hours': 'Promemoria scadenza di 4 ore',
      '1 days': 'Promemoria scadenza di 1 giorno',
      '3 days': 'Promemoria scadenza di 3 giorni',
      confirmReminderTitle: 'Conferma promemoria',
      confirmReminderContent: 'Vorresti inviare un promemoria ai membri che non hanno completato il compito per finirlo il prima possibile?',
      提醒你尽快完成任务: 'ti ricorda di completare il compito tempestivamente.',
      expirationReminder: 'Promemoria di scadenza',
      任务到期提醒: 'Promemoria di scadenza',
      '任务还有5分钟截止，请及时完成': 'Il compito termina in 5 minuti, si prega di completare prontamente.',
      '任务还有15分钟截止，请及时完成': 'Il compito termina in 15 minuti, si prega di completare prontamente.',
      '任务还有30分钟截止，请及时完成': 'Il compito termina in 30 minuti, si prega di completare prontamente.',
      '任务还有1小时截止，请及时完成': 'Il compito termina in 1 ora, si prega di completare prontamente.',
      '任务还有2小时截止，请及时完成': 'Il compito termina in 2 ore, si prega di completare prontamente.',
      '任务还有4小时截止，请及时完成': 'Il compito termina in 4 ore, si prega di completare prontamente.',
      '任务还有1天截止，请及时完成': 'Il compito termina in 1 giorno, si prega di completare prontamente.',
      '任务还有3天截止，请及时完成': 'Il compito termina in 3 giorni, si prega di completare prontamente.',
      保存为常用执行人组合: 'Salva come Combo Preferito',
      存为常用: 'Salva come Preferito',
      导入常用: 'Importa Preferiti',
      导入: 'Importa',
      导入常用执行人组合: 'Importa Combo Preferito',
      组合名称: 'Nome del Combo',
      最多保存30个常用组合: 'Salva fino a 30 Combo Preferiti',
      全部催促成功: 'Promemoria inviato',
      提醒时间: 'ora del promemoria',
      '因成员或权限变动，暂无可用执行人': 'A causa di cambiamenti nei membri o nei permessi, non ci sono esecutori disponibili temporaneamente',
      请输入名称: 'Si prega di inserire un nome',
      '请输入分类名称，最多20个字': 'Si prega di inserire il nome della categoria, fino a 20 caratteri',
      管理任务分类: 'Gestione delle Categorie di Attività',
      '暂无分类，点击左下方添加分类': 'Nessuna categoria disponibile, clicca in basso a sinistra per aggiungere una categoria',
      添加分类: 'Aggiungi Categoria',
      删除分类: 'Elimina Categoria',
      '删除分类后，已派发任务的该分类标签会一并清除，是否删除？': 'Dopo aver eliminato una categoria, verranno rimossi anche i tag di categoria di eventuali attività assegnate. Vuoi eliminare?',
      分类: 'Categoria',
      无分类: 'Non categorizzato',
      任务分类: 'Categoria Attività',
      分类数量最多30: 'Un massimo di 30 categorie',
      该名称已重复: 'Il nome è già stato duplicato',
      编辑模板公开范围: "Modificare l'ambito pubblico del template",
      '练习平均分=学员所有已完成课程得分的平均分': 'punteggio medio = media di tutti i punteggi del corso completato per il partecipante',
      暂只支持剧本对练课程类型的错误汇总: 'Riepilogo degli errori solo per i tipi di corso Script Practice, per ora',
      错误汇总: 'Riepilogo degli errori',
      所属课程: 'Corso',
      youCanAddScripts: 'È possibile aggiungere script al corso e assegnare il corso agli studenti attraverso compiti, richiedendo loro di leggere gli script',
      '您可以在课程中添加话术，并将课程通过任务派发给学员，要求其背诵话术': 'È possibile aggiungere script al corso e assegnare il corso agli studenti attraverso compiti, richiedendo loro di recitare gli script',
      '开启后，学员练习前只能查看话术原文开头10个字提示，不能再查看全部话术原文': "Una volta abilitato, gli studenti possono vedere solo i primi 10 caratteri dello script prima di esercitarsi e non possono visualizzare l'intero script",
      salespersonSays: 'Lo studente dice',
      salesSaysConfigure: 'Configura lo script standard per lo studente da dire',
      salesSaysContent: 'Cosa deve dire lo studente',
      jumpTip: 'È possibile inserire punti chiave, come "insulto". Se il discorso dello studente contiene contenuti offensivi, passerà al passaggio successivo in base alle condizioni',
      scriptPromptTip: 'Una volta abilitato, gli studenti possono visualizzare i suggerimenti dello script durante la pratica',
      'AI客户会跟AI客户会跟据您选择的知识库话术，与学员进行对话，发起提问并评分。据您选择的知识库话术，与学员进行对话，发起提问并评分。': 'I clienti AI dialogheranno con gli studenti in base allo script della base di conoscenza che scegli, faranno domande e daranno un punteggio.',
      '建立不同的对话场景，并为场景添加话术，AI客户将提出话术问题考核学员': 'Stabilire diversi scenari di dialogo, aggiungere script agli scenari e i clienti AI faranno domande sullo script per valutare gli studenti',
      '客户画像字段有助于学员提出挖需问题时，AI客户据此回答': 'I campi del ritratto del cliente aiutano quando gli studenti fanno domande di sondaggio, i clienti AI rispondono in base a questo',
      '对话开始时，可通过旁白向学员说明信息，如练习注意事项、练习背景信息等': "All'inizio del dialogo, è possibile spiegare informazioni agli studenti attraverso parentesi, come precauzioni per la pratica, informazioni di background sulla pratica, ecc.",
      startAndEndTimeTip: 'Dopo la scadenza, gli studenti non possono più praticare il corso',
      salesResponse: 'Lo studente risponde',
      salesSaysContentTip: 'Si prega di compilare prima cosa deve dire lo studente',
      autoCompleteConfirmContent: 'Ci sono {0} carte di parola dello studente senza punti chiave configurati, questo corso non è disponibile, confermi di andartene? Puoi fare clic sul pulsante "Completa" per completare rapidamente i punti chiave',
      salesContent: 'Quando lo studente ha bisogno di dire qualcosa',
      finalScoreOfAICustomerCourse: 'Il punteggio finale del corso del cliente AI = il punteggio più alto della pratica dello studente',
      selfIntroduction: "Ciao, sono uno studente dell'azienda {0}, come posso aiutarti?",
      dialogueExample: 'Il cliente ha ricevuto una chiamata dallo studente, lo studente ha presentato il prodotto al cliente, il cliente spera di capire pienamente se le caratteristiche del prodotto soddisfano le loro esigenze e spera di acquistare il prodotto a un prezzo più basso.',
      taskScoreDescription: 'Secondo la configurazione del corso, il punteggio del corso = la media o il punteggio totale di tutte le domande degli studenti',
      scoreReminder: 'Quando la performance di uno studente è al di sotto del punteggio di passaggio, verrà dato un punteggio rosso per ricordare loro che la loro performance non è qualificata',
      salesmanConfigureTip: 'Quando un nodo è collegato a più carte di parola dello studente allo stesso tempo, la carta di parola dello studente più simile sarà selezionata per la valutazione e il salto in base al discorso effettivo dello studente',
      validateEdgeMessage: "Quando vengono avviate più linee da un singolo punto, l'obiettivo può essere solo una carta di parola dello studente e ne possono essere collegati al massimo 5.",
      放弃练习: "Abbandona l'esercizio",
      暂存离开: 'Salva e esci',
      '确认提交本次练习？': "Conferma l'invio dell'esercizio",
      '其他途径正在进行或已完成本次练习，请稍后重试': 'Altri percorsi sono in corso o hanno già completato questo esercizio, riprova più tardi',
      继续练习: "Continua l'esercizio",
      开始第x次练习: "Inizia l'esercizio numero {0}",
      继续第x次练习: "Continua l'esercizio numero {0}",
      '任务截止前可继续练习，确认离开？': "Sei sicuro di voler uscire? Puoi continuare l'esercizio fino alla scadenza del compito.",
      '其他途径正在进行或已完成本次练习，当前途径无法继续操作': 'Altri percorsi sono in corso o hanno già completato questo esercizio, non è possibile procedere con il percorso attuale',
      失败提示: 'Errore',
      提前完成练习: "Completa l'esercizio in anticipo",
      任务截止前可继续本次练习: 'Puoi continuare questo esercizio fino alla scadenza del compito',
      退出并清除本次练习记录: 'Esci e cancella i dati di questo esercizio',
      确认提交: 'Invia',
      离开练习: "Lascia l'esercizio",
      不显示子分类的课程: 'Nascondi sottocategorie',
      放弃练习成功: 'Abbandono Riuscito',
      上传图片: 'Carica immagine',
      最多上传x张图片: 'Massimo di {0} immagini caricabili',
      图片大小不能超过x: "La dimensione dell'immagine non deve superare {0}",
      请上传图片格式的文件: 'Si prega di caricare un file in formato immagine',
      加载失败: 'Caricamento fallito'
    }
  }
};
var _default = coaching;
exports.default = _default;