"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MembersFinder = _interopRequireDefault(require("@/components/MembersFinder"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'AddMemberDialog',
  components: {
    MembersFinder: _MembersFinder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    roleInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      treeConfig: {
        noDisabled: true,
        noCheckDept: true,
        checkIsExpanded: true,
        addDepartmentPre: true
      },
      batchAddForm: {
        ids: [],
        batchUserIds: [],
        // 需要迁移的用户组
        batchDeptIds: []
      },
      rules: {
        ids: [{
          required: true,
          message: '请选择成员',
          trigger: 'change'
        }]
      },
      buttonLoading: false
    };
  },
  mounted() {},
  methods: {
    handleMemberChange(val) {
      this.batchAddForm.ids = val;
      const userId_Multiple = [];
      const deptId_Multiple = [];
      val.forEach(item => {
        const id = String(item).split('_').pop() - 0;
        if (String(item).indexOf('tree_id') === -1) {
          userId_Multiple.push(id);
        } else {
          deptId_Multiple.push(id);
        }
      });
      this.batchAddForm.batchUserIds = userId_Multiple;
      this.batchAddForm.batchDeptIds = deptId_Multiple;
    },
    submitFormData() {
      this.$refs['batchAddForm'].validate(async valid => {
        if (valid) {
          const data = {
            role_ids: [this.roleInfo.id],
            user_ids: this.batchAddForm.batchUserIds,
            tree_ids: this.batchAddForm.batchDeptIds
          };
          this.buttonLoading = true;
          try {
            const res = await (0, _permissions.addFuncMembers)(data);
            if (res.code === 20000) {
              setTimeout(() => {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.buttonLoading = false;
                this.$emit('fetchMemberData');
                this.handleClose();
              }, 1000);
            } else {
              this.buttonLoading = false;
            }
          } catch {
            this.buttonLoading = false;
          }
        }
      });
    },
    handleClose() {
      this.reset();
      this.$emit('update:visible', false);
    },
    reset() {
      this.$refs['batchAddForm'].resetFields();
    }
  }
};
exports.default = _default;