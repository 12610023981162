"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modelManagement = require("@/api/modelManagement");
var _vue = require("vue");
var _default = {
  __name: 'ModelSelect',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    isOnlyAi: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'change'],
  setup(__props, _ref) {
    let {
      emit
    } = _ref;
    const props = __props;
    const onInput = val => {
      emit('input', val);
    };
    const onChange = val => {
      emit('change', val);
    };
    const options = (0, _vue.ref)([]);
    const remoteMethod = q => {
      if (!q) {
        options.value = [];
        return;
      }
      const params = {
        model_name: q,
        page: 1,
        size: 9999
      };
      (0, _modelManagement.getModelList)(params).then(res => {
        if (props.isOnlyAi) {
          options.value = res.results.models.filter(item => item.model_type !== 1);
        } else {
          options.value = res.results.models;
        }
      });
    };
    return {
      __sfc: true,
      props,
      emit,
      onInput,
      onChange,
      options,
      remoteMethod
    };
  }
};
exports.default = _default;