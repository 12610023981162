"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "analysis-business-container"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "analysis-business"
  }, [_c("div", {
    staticClass: "boxplot"
  }, [_c("boxplot-chart", {
    attrs: {
      "chart-option": _vm.option,
      "auto-resize": true,
      type: "canvas"
    }
  })], 1), _c("suggestion-box", {
    staticClass: "suggestion",
    attrs: {
      text: "团队中离群点表明有需要关注的销售；箱体表示分布在25%至75%范围内的销售；箱体间距越大，表明团队内中段销售之间的能力差距越大；中位线越靠上，表明团队中普通销售的水平越好。"
    }
  }), _c("el-tabs", {
    attrs: {
      type: "card"
    },
    model: {
      value: _vm.tableTabName,
      callback: function ($$v) {
        _vm.tableTabName = $$v;
      },
      expression: "tableTabName"
    }
  }, _vm._l(_vm.tableData, function (data, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        name: _vm.departmentNames[index] + index,
        label: _vm.departmentNames[index]
      }
    }, [_c("business-analysis-table", {
      attrs: {
        "table-data": data,
        "event-name": _vm.eventName
      }
    })], 1);
  }), 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;