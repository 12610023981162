"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _FolderDropdown = _interopRequireDefault(require("./FolderDropdown"));
var _ConvFlowDropdown = _interopRequireDefault(require("./ConvFlowDropdown"));
var _AddFolderDropdown = _interopRequireDefault(require("@/views/coaching/knowledge/snippetsLibrary/components/AddFolderDropdown.vue"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'FolderTree',
  components: {
    FolderDropdown: _FolderDropdown.default,
    ConvFlowDropdown: _ConvFlowDropdown.default,
    AddFolderDropdown: _AddFolderDropdown.default
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    createable: {
      type: Boolean,
      default: false
    },
    hasRoot: Boolean,
    currentNode: {
      type: Object,
      default: () => ({})
    },
    filterConvFlow: {
      type: Boolean,
      default: false
    },
    spaceId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      treeProps: {
        label: 'name'
      },
      expandedKeys: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['folderTree']),
    virtualTree() {
      const root = [{
        id: 0,
        name: '剪辑库',
        children: this.folderTree
      }];
      const tree = this.hasRoot ? root : this.folderTree;
      return this.filterConvFlow ? this.getPureSnippetsFolders(tree) : tree;
    }
  },
  watch: {
    currentNode: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              var _this$$refs$folderTr;
              (_this$$refs$folderTr = this.$refs['folder-tree']) === null || _this$$refs$folderTr === void 0 ? void 0 : _this$$refs$folderTr.setCurrentKey(val.id); // 此处原因待排除，首次进入的时候不会选中，
            }, 100);
            const node = this.$refs['folder-tree'].getNode(val.id);
            this.openAllParent(node);
            this.$emit('nodeChange', node);
          });
        }
      }
    }
  },
  async created() {
    await this.$store.dispatch('library/get_tree', this.spaceId);
    this.$emit('fetchSucceed');
  },
  methods: {
    getIcon(data, node) {
      if (data.is_public) {
        return node.expanded ? 'icon-folder-opened-fill' : 'icon-folder-fill';
      } else {
        return node.expanded ? 'icon-folder-opened-lock-fill' : 'icon-folder-lock-fill';
      }
    },
    openAllParent(node) {
      if (node && node.parent) {
        node.parent.expanded = true;
        this.openAllParent(node.parent);
      }
    },
    getNode(id) {
      return this.$refs['folder-tree'].getNode(id);
    },
    remove(id) {
      this.$refs['folder-tree'].remove(id);
    },
    setCurrentKey(id) {
      this.$refs['folder-tree'].setCurrentKey(id);
    },
    handleClick(data, node, tree) {
      this.$emit('nodeClick', data, node, tree);
    },
    handleCommand(type, data) {
      this.$emit('folderOperation', type, data);
    },
    handleConvFlowCommand(type, data) {
      this.$emit('convFlowOperation', type, data);
    },
    getPureSnippetsFolders(tree) {
      let arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      if (!tree || !tree.length) return [];
      for (const item of tree) {
        if (item.type) continue;
        const node = {
          ...item,
          children: []
        };
        arr.push(node);
        if (item.children && item.children.length) {
          this.getPureSnippetsFolders(item.children, node.children);
        }
      }
      return arr;
    }
  }
};
exports.default = _default;