"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _KnowledgeFilter = _interopRequireDefault(require("./components/KnowledgeFilter"));
var _PersonalKnowledgeTable = _interopRequireDefault(require("./components/PersonalKnowledgeTable"));
var _PersonalKnowledgeConv = _interopRequireDefault(require("./components/PersonalKnowledgeConv"));
var _businessIntelligence = require("@/api/businessIntelligence");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'PersonalKnowledge',
  components: {
    KnowledgeFilter: _KnowledgeFilter.default,
    PersonalKnowledgeTable: _PersonalKnowledgeTable.default,
    PersonalKnowledgeConv: _PersonalKnowledgeConv.default
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      allKnowledgeList: [],
      knowledgeTableTotal: 0,
      knowledgeTableParams: {
        host_id: '',
        start_time: '',
        end_time: '',
        page_num: 1,
        page_size: 10,
        knowledge_ids: [],
        sort_by: 'score_my',
        sort_order: 'descending'
      },
      knowledgeTableList: [],
      tableLoading: false
    };
  },
  watch: {
    filters: {
      handler: function (val) {
        this.initData();
        if (val) {
          this.knowledgeTableParams.start_time = val.begin_date;
          this.knowledgeTableParams.end_time = val.end_date;
          this.knowledgeTableParams.host_id = Number(this.$route.query.id);
          this.getPersonalKnowledgeTable();
        }
      },
      deep: true
    }
  },
  created() {
    this.getAllKnowledge();
  },
  methods: {
    initData() {
      this.knowledgeTableParams.page_num = 1;
      this.knowledgeTableList = [];
    },
    // 获取左侧table
    async getPersonalKnowledgeTable() {
      this.tableLoading = true;
      const res = await (0, _businessIntelligence.personalKnowledgeTable)({
        ...this.knowledgeTableParams
      });
      if (res.code === 200) {
        this.knowledgeTableTotal = res.results.count;
        this.knowledgeTableList = res.results.data;
      }
      this.tableLoading = false;
    },
    // 获取所有知识点
    async getAllKnowledge() {
      const res = await (0, _businessIntelligence.getAllKnowledge)();
      if (res.code === 200) {
        this.allKnowledgeList = res.results;
      }
    },
    knowledgeChange(knowledgeIds) {
      this.knowledgeTableParams.knowledge_ids = knowledgeIds;
      this.initData();
      // 请求左侧表格
      this.getPersonalKnowledgeTable();
    },
    tableSizeChange(val) {
      this.knowledgeTableParams.page_size = val;
      this.initData();
      // 请求左侧表格
      this.getPersonalKnowledgeTable();
    },
    tableCurrentChange(val) {
      this.knowledgeTableParams.page_num = val;
      // 请求左侧表格
      this.getPersonalKnowledgeTable();
    },
    tableSortChange(_ref) {
      let {
        prop,
        order
      } = _ref;
      this.knowledgeTableParams.sort_by = prop;
      this.knowledgeTableParams.sort_order = order;
      this.initData();
      // 请求左侧表格
      this.getPersonalKnowledgeTable();
    }
  }
};
exports.default = _default;