"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dealFilter = _interopRequireDefault(require("@/views/business/components/dealFilter"));
var _ConversationFilterItem = _interopRequireDefault(require("../../components/ConversationFilterItem"));
var _vue = require("vue");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  __name: 'CustomerFilters',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    accountFilterList: {
      type: Array,
      default: () => []
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const showCustomFilterClear = (0, _vue.computed)(() => {
      return customFilter => {
        return !!(customFilter && customFilter.length);
      };
    });
    const clearCustomFilterItem = val => {
      if (Array.isArray(props.filters.custom_filters[val])) {
        // eslint-disable-next-line vue/no-mutating-props
        props.filters.custom_filters[val] = [];
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.filters.custom_filters[val] = '';
      }
    };
    const handleCustomFilter = val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.filterParams = {
        ...props.filterParams,
        ...val
      };
      customFilterChange(props.filterParams, val);
    };
    const customFilterChange = (params, val) => {
      // 深拷贝一下，不然watch不到this.filters的变化
      // eslint-disable-next-line vue/no-mutating-props
      props.filters.custom_filters = JSON.parse(JSON.stringify(params));
    };
    return {
      __sfc: true,
      props,
      showCustomFilterClear,
      clearCustomFilterItem,
      handleCustomFilter,
      customFilterChange,
      DealCustomFilter: _dealFilter.default,
      ConversationFilterItem: _ConversationFilterItem.default
    };
  }
};
exports.default = _default;