"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = require("@/api/business");
var _default = {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "allocateToAnyone"
    },
    dealId: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {
      val: [],
      options: []
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getAvailableAllocatorList();
      }
    }
  },
  computed: {
    title() {
      switch (this.$props.type) {
        case "normal":
          return "移交";
        case "super":
          return "改负责人";
      }
    },
    choosedId() {
      return this.val[this.val.length - 1];
    }
  },
  methods: {
    async getAvailableAllocatorList() {
      const res = await (0, _business.getAvailableAllocatorList)();
      this.options = this.treeFormating(res.results.options);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async submitForm() {
      const transFollower = this.$props.type === 'normal' ? _business.transFollowerNormal : _business.transFollowerSuper;
      const res = await transFollower({
        deal_id: this.$props.dealId,
        target_follow_up_by: this.choosedId
      });
      if (res.code === 200) {
        this.$message.success('修改成功！');
        this.$emit('renew');
      }
      this.closeDialog();
    },
    //     treeFormating(tree) {
    //   const resArr = [];
    //   const formatChildren = (tree) => {
    //     const obj = this.handleCurrent(tree.this);
    //     resArr.push(obj);
    //     if (tree.hasOwnProperty("children")) {
    //       for (const item of tree.children) {
    //         formatChildren(item, obj);
    //       }
    //     }
    //   };
    //   formatChildren(tree);
    //   return resArr;
    // },
    treeFormating(tree) {
      const resArr = [];
      const formatChildren = tree => {
        const obj = this.handleCurrent(tree.this);
        if (tree.hasOwnProperty("children")) {
          for (const item of tree.children) {
            //将部门插入至成员之前
            let _index = 0;
            for (const elem of obj.children) {
              if (!elem.hasOwnProperty("children")) {
                _index = obj.children.indexOf(elem);
                break;
              }
            }
            obj.children.splice(_index, 0, formatChildren(item));
          }
        }
        return obj;
      };
      resArr.push(formatChildren(tree));
      return resArr;
    },
    handleCurrent(current) {
      const {
        department_info,
        users
      } = current;
      const obj = {
        label: department_info.name,
        value: department_info.tree_id,
        children: []
      };
      for (const user of users) {
        obj.children.push({
          label: user.name,
          value: user.user_id
        });
      }
      return obj;
    }
  }
};
exports.default = _default;