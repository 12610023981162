"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-plan-header"
  }, [_vm.type === "leader" ? _c("div", {
    staticClass: "personal-plan-top"
  }, [_c("el-breadcrumb", {
    attrs: {
      "separator-class": "el-icon-arrow-right"
    }
  }, [_c("el-breadcrumb-item", {
    attrs: {
      to: {
        path: _vm.getSourceInfo("path")
      }
    }
  }, [_vm._v(_vm._s(_vm.getSourceInfo("label")))]), _vm.$route.query.taskName ? _c("el-breadcrumb-item", {
    attrs: {
      to: {
        path: _vm.ROUTES.coachingCenterLeaderPlan,
        query: {
          id: _vm.$route.query.taskId,
          source: _vm.$route.query.source
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$route.query.taskName))]) : _vm._e(), _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.userInfo.name))])], 1)], 1) : _vm._e(), _c("div", {
    staticClass: "personal-plan-bottom"
  }, [_c("div", {
    staticClass: "personal-plan-container"
  }, [_c("div", {
    staticClass: "personal-plan-header-content"
  }, [_c("div", {
    staticClass: "go-back",
    on: {
      click: _vm.goBack
    }
  }, [_c("i", {
    staticClass: "iconfont icon-arrow-left"
  })]), ["sales", "smart-training", "ai-customer"].includes(_vm.type) ? _c("task-info", {
    attrs: {
      "task-info": _vm.taskInfo
    }
  }) : _vm._e(), _vm.type === "leader" ? _c("div", {
    staticClass: "personal-plan-avatar"
  }, [_c("image-avatar", {
    attrs: {
      shape: "circle",
      name: _vm.userInfo.name,
      avatar: _vm.userInfo.avatar
    }
  }), _c("div", {
    staticClass: "assigner-name"
  }, [_vm._v(_vm._s(_vm.userInfo.name))])], 1) : _vm._e()], 1), _vm.type !== "ai-customer" ? _c("div", {
    staticClass: "personal-plan-data"
  }, [_vm.type !== "smart-training" ? _c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "data-title"
  }, [_vm._v(_vm._s(_vm.$t("coaching.getCourseScore")))]), _vm.taskScoreInfo.average_score >= 0 ? _c("div", [_c("span", {
    staticClass: "bold-num"
  }, [_vm._v(_vm._s(_vm.taskScoreInfo.average_score))]), _vm._v(" " + _vm._s(_vm.$t("coaching.points")) + " ")]) : _c("p", {
    staticClass: "no-score"
  }, [_vm._v(_vm._s(_vm.$t("coaching.noScoreYet")))])]) : _vm._e(), _vm.type !== "smart-training" ? _c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "data-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("coaching.completedQuestionsCount")) + " ")]), _c("div", [_c("span", {
    staticClass: "bold-num"
  }, [_vm._v(_vm._s(_vm.taskScoreInfo.finished))]), _vm._v("/" + _vm._s(_vm.taskScoreInfo.count) + " ")])]) : _c("ToggleTaskHistorySelect", {
    attrs: {
      "user-id": _vm.userId,
      "task-id": _vm.taskInfo.id,
      "conv-id": _vm.taskInfo.convId
    },
    on: {
      toggleHistory: _vm.toggleHistory
    }
  })], 1) : _vm._e()])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;