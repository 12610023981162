"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDataUnit = formatDataUnit;
exports.getTimeHMSHtml = getTimeHMSHtml;
exports.transToPercentData = transToPercentData;
var _timeFormatter = require("@/utils/time-formatter");
function formatDataUnit(unitConfig, data) {
  let currentUnitConfig = '';
  if (unitConfig.analysis_target === 'conversation') {
    switch (unitConfig.analysis_target_key) {
      case 'avg_duration': // 分秒
      case 'max_sales': // 分秒
      case 'max_customer': // 分秒
      case 'sum_duration': // 时分
      case 'avg_person_duration':
        currentUnitConfig = getTimeHMSHtml(data);
        break;
      case 'count':
      case 'avg_person_count':
        // 个
        currentUnitConfig = `<span>${data}</span><span>个</span>`;
        break;
      case 'percent_speak':
        // 百分比
        currentUnitConfig = transToPercentData(data);
        break;
      case 'question':
        // 次
        currentUnitConfig = `<span>${data}</span><span>次</span>`;
        break;
      default:
        // 无单位
        currentUnitConfig = `<span>${data}</span>`;
        break;
    }
  } else {
    // 非业务卡点转化类型统一根据绘图方式来确定单位
    if (unitConfig.analysis_target_key === 'business_trans') {
      currentUnitConfig = transToPercentData(data);
    } else {
      if (unitConfig.draw_style === 'count') {
        currentUnitConfig = `<span>${data}</span><span>个</span>`;
      } else {
        currentUnitConfig = transToPercentData(data);
      }
    }
  }
  return currentUnitConfig;
}
function getTimeHMSHtml(data) {
  return (0, _timeFormatter.secondToCNTimeArr)(data).reduce((str, el) => str + `<span>${el}</span>`, '');
}
function transToPercentData(data) {
  return `<span>${String(data).toLocaleString('', {
    maximumFractionDigits: 4
  })}</span><span>%</span>`;
}