"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TranserDepartmentFinderDialog = _interopRequireDefault(require("@/components/TranserDepartmentFinderDialog"));
var _permissions = require("@/api/permissions");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'DepartmentMultiSelect',
  components: {
    TranserDepartmentFinderDialog: _TranserDepartmentFinderDialog.default
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      departmentsFinderDialogVisible: false,
      selectedDepartmentInfo: []
    };
  },
  methods: {
    getDepartmentsWithOutAuth: _permissions.getDepartmentsWithOutAuth,
    openEditScopeDialog() {
      this.departmentsFinderDialogVisible = true;
    },
    submitManageScope(scope) {
      this.selectedDepartmentInfo = scope;
      this.$emit('submitManageScope', scope);
    }
  }
};
exports.default = _default;