"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _detailMarkLine = _interopRequireDefault(require("@/components/ConversationPageComp/conversationCard/detailMarkLine"));
var _timeFormatter = require("@/utils/time-formatter");
var _mixin = require("@/mixin/mixin");
var _routeMap = require("@/router/routeMap");
var _commonFunc = require("@/utils/commonFunc");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'ConverCardDetail',
  components: {
    MarkLine: _detailMarkLine.default
  },
  mixins: [_mixin.handleTextHighLightMixin],
  props: {
    detailData: {
      type: Array,
      default: () => {
        return null;
      }
    },
    conversationType: {
      type: String,
      default: ''
    },
    conversationId: {
      type: [String, Number],
      default: null
    },
    host_salesman: {
      type: Array,
      default: () => []
    },
    assistant_salesman: {
      type: Array,
      default: () => []
    },
    spectator_salesman: {
      type: Array,
      default: () => []
    },
    customer_contact: {
      type: Array,
      default: () => []
    }
  },
  inject: {
    goTimeDetail: {
      from: 'goTimeDetail',
      default: () => () => {}
    }
  },
  data() {
    return {
      isUnfold: false,
      maxShowNumber: 3,
      isbothShowUnfold: false,
      // 如果文本和关键事件同时搜素是是否显示展开
      noneleafArray: null,
      leafArray: null,
      textArray: null,
      markLineList: []
    };
  },
  computed: {
    getFoldData() {
      return this.getFoldDataMethods();
    }
  },
  watch: {
    detailData: {
      handler: function (val) {
        this.init();
      },
      deep: true
    },
    getFoldData: {
      handler: function (val) {
        this.handleData(val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    timeFormat(type, time) {
      return type === 'doc' ? this.$moment(time * 1000).format('YYYY-MM-DD HH:mm') : this.secondToTime(time);
    },
    getFoldDataMethods() {
      let foldData = [];
      const textArray = this.getDataItems('text');
      const leafArray = this.getDataItems('event_leaf');
      const noneleafArray = this.getDataItems('event_nonleaf');
      let modeNumber = 0;
      let eventLength = 0;
      this.textArray = null;
      this.leafArray = null;
      this.noneleafArray = null;
      if (textArray) {
        foldData = [...textArray, ...foldData];
        modeNumber += textArray.length;
        this.textArray = textArray;
      }
      if (leafArray) {
        let leafDataArray = [];
        leafArray.forEach(item => {
          leafDataArray = [...item.data, ...leafDataArray];
        });
        foldData = [...leafDataArray, ...foldData];
        modeNumber++;
        eventLength = leafDataArray.length;
        this.leafArray = leafArray;
      }
      if (noneleafArray) {
        let noneleafDataArray = [];
        noneleafArray.forEach(item => {
          noneleafDataArray = [...item.data, ...noneleafDataArray];
        });
        foldData = [...noneleafDataArray, ...foldData];
        modeNumber++;
        eventLength = noneleafDataArray.length;
        this.noneleafArray = noneleafArray;
      }
      if (modeNumber > 1) {
        this.maxShowNumber = 6;
        if (eventLength > 3 || textArray.some(item => item.items.length > 3)) {
          this.isbothShowUnfold = true;
        } else {
          this.isbothShowUnfold = false;
        }
      } else {
        this.maxShowNumber = 3;
      }
      return foldData.flat();
    },
    handleData(data) {
      this.markLineList = [];
      const markLineList = (0, _commonFunc.deepClone)(data);
      markLineList.forEach(item => {
        if (item.mode && item.mode === 'text') {
          this.markLineList.push(...item.items);
        } else {
          this.markLineList.push(item);
        }
      });
    },
    init() {
      // 初始化状态
      this.isUnfold = false;
      this.maxShowNumber = 3;
      this.isbothShowUnfold = false;
    },
    goDetailKeyEvent(conversationId, keywordId) {
      let itemIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      window.open(`${location.origin}${_routeMap.ROUTES.conversationDetail}?id=${conversationId}&keyWordId=${keywordId}&itemIndex=${itemIndex}&noback=true`);
    },
    foldOrUnfoldItems(type) {
      const isUnFold = type === 'unfold';
      this.isUnfold = isUnFold;
      if (this.noneleafArray) {
        this.noneleafArray.forEach(item => {
          item.isTextUnfold = isUnFold;
        });
      }
      if (this.leafArray) {
        this.leafArray.forEach(item => {
          item.isTextUnfold = isUnFold;
        });
      }
      if (this.textArray) {
        this.textArray.forEach(item => {
          item.isTextUnfold = isUnFold;
        });
      }
      if (type === 'unfold') {
        this.$emit('unfold', this.conversationId);
      }
    },
    secondToTime(second) {
      return (0, _timeFormatter.secondToTime)(second);
    },
    goDetail(conversationId, order, keyword) {
      this.goTimeDetail(conversationId, order, keyword);
    },
    handleName(_ref) {
      let {
        entity_type,
        entity_id
      } = _ref;
      if (this[entity_type]) {
        const list = this[entity_type].find(item => item.entity_id === entity_id);
        if (list) {
          return list.name;
        }
      }
    },
    getDataItems(mode) {
      const detailData = JSON.parse(JSON.stringify(this.detailData));
      if (mode === 'text') {
        const data = detailData.filter(item => {
          return item.mode === mode;
        });
        return data.length ? data : null;
      } else {
        const data = detailData.find(item => {
          return item.mode === mode;
        });
        return data ? data.items : null;
      }
    },
    switchEventFold(type, item, index, keyword) {
      if (type === 'text') {
        if (item.items && item.items.length < 4) {
          this.goDetail(this.conversationId, item.items[0].order, keyword);
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('isTextUnfold')) {
          item.isTextUnfold = !item.isTextUnfold;
        } else {
          item.isTextUnfold = true;
        }
        this.$set(this.textArray, index, item);
      }
      if (type === 'event_nonleaf') {
        if (!item.data || item.data.length < 2) {
          this.goDetailKeyEvent(this.conversationId, item.id);
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('isTextUnfold')) {
          item.isTextUnfold = !item.isTextUnfold;
        } else {
          item.isTextUnfold = true;
        }
        this.$set(this.noneleafArray, index, item);
      }
      if (type === 'event_leaf') {
        if (!item.data || item.data.length < 4) {
          this.goDetailKeyEvent(this.conversationId, item.id);
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('isTextUnfold')) {
          item.isTextUnfold = !item.isTextUnfold;
        } else {
          item.isTextUnfold = true;
        }
        this.$set(this.leafArray, index, item);
      }
      this.isShowExpandCollapse();
    },
    isShowExpandCollapse() {
      const arr = [];
      if (this.noneleafArray) {
        arr.push.apply(arr, this.noneleafArray.filter(item => item.data.length > 1));
      }
      if (this.leafArray) {
        arr.push.apply(arr, this.leafArray.filter(item => item.data.length > 3));
      }
      if (this.textArray) {
        arr.push.apply(arr, this.textArray.filter(item => item.items.length > 3));
      }
      if (arr.every(item => item.isTextUnfold)) {
        this.isUnfold = true;
      }
      if (arr.every(item => !item.isTextUnfold)) {
        this.isUnfold = false;
      }
    },
    isShowAllContent(isUnFold, index, type) {
      let defaultMaxIndex = 0;
      if (type === 'event_nonleaf') {
        defaultMaxIndex = 0;
      } else {
        defaultMaxIndex = 2;
      }
      if (isUnFold || index <= defaultMaxIndex) {
        return true;
      } else {
        return false;
      }
    }
  }
};
exports.default = _default;