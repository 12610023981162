"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chat-drawer",
    class: _vm.isDrawerFold ? "fold" : ""
  }, [_c("chat-unit", _vm._g(_vm._b({
    attrs: {
      foldable: "",
      "is-fold": _vm.isDrawerFold
    },
    on: {
      toggleFold: _vm.toggleFold
    }
  }, "chat-unit", _vm.$attrs, false), _vm.$listeners))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;