"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DynamicForm = _interopRequireDefault(require("@/components/DynamicForm"));
var _KeySettings = _interopRequireDefault(require("@/views/appManagement/components/KeySettings"));
var _applicationIntegration = require("@/api/applicationIntegration");
var _appNormal = _interopRequireDefault(require("@/assets/app-normal.svg"));
var _appFail = _interopRequireDefault(require("@/assets/app-fail.svg"));
var _routeMap = require("@/router/routeMap");
var _vuex = require("vuex");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  components: {
    DynamicForm: _DynamicForm.default,
    KeySettings: _KeySettings.default,
    NoData: _NoData.default
  },
  data() {
    return {
      drawerVisible: false,
      isCloseDrawer: false,
      appLoading: false,
      submitLoading: false,
      drawerLoading: false,
      desc: [],
      value: {},
      originDesc: [],
      originValue: {},
      privateKeyDesc: {},
      privateKeyValue: {},
      showKeySettings: false,
      formData: {},
      appList: [],
      appListLoading: false,
      privateAppList: [],
      privateAppListLoading: false,
      currentApp: {
        logo: '',
        name: '',
        id: ''
      },
      normalIcon: _appNormal.default,
      invalidIcon: _appFail.default
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['orgId', 'modulePermission'])
  },
  created() {
    try {
      this.appLoading = true;
      this.getAppList();
      this.getPrivateAppList();
    } finally {
      this.appLoading = false;
    }
  },
  methods: {
    async getPrivateAppList() {
      this.privateAppListLoading = true;
      const res = await (0, _applicationIntegration.getPrivateAppList)().finally(() => {
        this.privateAppListLoading = false;
      });
      if (res.code === 20000) {
        this.privateAppList = res.results.apps.filter(item => item.status === 2 || item.status === 3);
      }
    },
    async getAppList() {
      this.appListLoading = true;
      const res = await (0, _applicationIntegration.getAppList)().finally(() => {
        this.appListLoading = false;
      });
      if (res.code === 20000) {
        this.appList = res.results.data;
      }
    },
    handleClose() {
      if (JSON.stringify(this.originValue) !== JSON.stringify(this.formData)) {
        this.$confirm('您还未保存内容，确认离开？').then(() => {
          this.closeDrawer();
        }).catch(() => {});
      } else {
        this.closeDrawer();
      }
    },
    closeDrawer() {
      this.drawerVisible = false;
      this.$refs['form'].resetFields();
      const privateKey = this.$refs['privateKey'];
      privateKey && privateKey.resetValue();
      this.formData = JSON.parse(JSON.stringify(this.originValue));
      this.value = {};
      this.desc = [];
    },
    async handleSubmit() {
      if (this.$refs['form'].validate()) {
        this.submitLoading = true;
        const params = {
          app_permission_id: this.currentApp.id,
          Vals: this.formData
        };
        try {
          const res = await (0, _applicationIntegration.updateAppConfigInfo)(params);
          if (res.code === 20000) {
            this.$message({
              message: '更新成功',
              type: 'success',
              center: true
            });
            this.closeDrawer();
          }
        } catch (error) {
          throw new Error(error);
        }
        this.submitLoading = false;
      }
    },
    openDrawer(_ref) {
      let {
        url,
        nick_name,
        app_permission_id
      } = _ref;
      this.drawerVisible = true;
      this.currentApp = {
        logo: url,
        name: nick_name,
        id: app_permission_id
      };
      this.getAppConfig({
        app_permission_id
      });
    },
    handleInput(val) {
      this.value = val;
      this.formData = JSON.parse(JSON.stringify(val));
    },
    getFormatDesc(val) {
      return val.map(item => {
        const {
          display_name,
          form_type,
          configs,
          is_required
        } = item;
        return {
          label: display_name,
          type: form_type,
          options: configs || [],
          required: !!is_required,
          disabled: false,
          ...item
        };
      });
    },
    async getAppConfig(params) {
      this.drawerLoading = true;
      const res = await (0, _applicationIntegration.getAppConfigInfo)(params);
      if (res.code === 20000) {
        const {
          Vals,
          AppConfiguration
        } = res.results;
        this.originDesc = AppConfiguration;
        this.value = Vals;
        this.originValue = JSON.parse(JSON.stringify(Vals));
        const formatDesc = this.getFormatDesc(AppConfiguration);
        const keyItem = formatDesc.find(item => item.field_name === 'has_private_key');
        const keyValue = formatDesc.find(item => item.field_name === 'private_key');
        if (keyItem) {
          this.showKeySettings = true;
          this.desc = formatDesc.filter(item => item.id !== keyItem.id && item.id !== keyValue.id);
          this.privateKeyDesc = keyItem;
          this.privateKeyValue = this.value[keyItem.id];
        } else {
          this.showKeySettings = false;
          this.desc = formatDesc;
        }
      }
      this.drawerLoading = false;
    },
    handleHasPrivateKeyChange(val) {
      this.formData[this.privateKeyDesc.id] = val;
    },
    handlePrivateKeyChange(val) {
      const id = this.originDesc.find(item => item.field_name === 'private_key').id;
      const value = this.value[id];
      this.formData[id] = val || value;
    },
    goMegaviewOpenPlatform() {
      switch (location.hostname) {
        case 'localhost':
          window.open(`http://localhost:5173/manage-app`, '_blank');
          break;
        case 'jirui.test.mgvai.cn':
          window.open(`https://open.test.mgvai.cn/manage-app`, '_blank');
          break;
        case 'prepublish.megaview.com':
          window.open(`https://prepublish-open.megaview.com/manage-app`, '_blank');
          break;
        case 'app.megaview.com':
          window.open(`https://open.megaview.com/manage-app`, '_blank');
          break;
        default:
          break;
      }
    },
    openDeveloperManagement() {
      this.$router.push({
        path: _routeMap.ROUTES.manageDeveloper
      });
    }
  }
};
exports.default = _default;