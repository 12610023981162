"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _setup.getDialogueListLoading,
      expression: "getDialogueListLoading"
    }],
    staticClass: "container"
  }, [_c("p", {
    staticClass: "sticky-header"
  }, [_vm._v(_vm._s(_setup.t("coaching.practiceRecord")))]), _c(_setup.DialogueList, {
    attrs: {
      "conversation-list": _setup.conversationList,
      narration: _setup.narration,
      "task-type": 3
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;