"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TencentCaptcha = _interopRequireDefault(require("TencentCaptcha"));
var _user = require("@/api/user");
var _errorLog = require("@/utils/error-log");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'VerificationCodeInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    isButtonOutside: {
      type: Boolean,
      default: false
    },
    codeType: {
      type: String,
      required: true
    },
    tel: {
      type: String,
      required: true
    },
    email: {
      type: String,
      default: ''
    },
    sendingDestination: {
      type: String,
      default: 'tel'
    },
    maxLength: {
      type: String,
      default: '6'
    },
    noSlider: {
      type: Boolean,
      default: false
    },
    isDirectSend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      isSendIng: false,
      count: 60,
      codeTicket: '',
      // 发验证码前人机校验票据
      codeRandstr: '',
      // 发验证码前人机校验的随机字符串
      sendLoading: false
    };
  },
  computed: {
    textColor() {
      return this.isSendIng && this.count !== 0 ? '#8A98A8' : '#4461EC';
    },
    codeText() {
      if (this.isSendIng && this.count !== 0) {
        return this.count + 's';
      } else if (this.isSendIng && this.count === 0) {
        return this.$t('management.重新发送');
      } else {
        return this.$t('management.发送验证码');
      }
    }
  },
  mounted() {
    window.tencentVerifyCallback = this.tencentVerifyCallback;
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    sendCode() {
      if (this.isDirectSend) {
        this.postSendCode();
      } else {
        this.$emit('sendCode');
      }
    },
    showTencentVerify() {
      try {
        // 绑定一个元素
        // 验证码会读取dom上的`data-appid`和`data-cbfn`以及`data-biz-state`(可选)自动初始化
        // eslint-disable-next-line no-undef
        const buttonId = this.isButtonOutside ? 'tencentCaptchaRegisterCode' : 'tencentCaptchaCode';
        this.codeTencentCaptcha = new _TencentCaptcha.default(document.getElementById(buttonId), this.$config.codeCaptchaAppId, this.tencentVerifyCallback);
        console.log('verifica');
        this.codeTencentCaptcha.show();
      } catch (error) {
        this.tencentCaptchaloadErrorCallback(error);
      }
    },
    tencentVerifyCallback(res) {
      if (res.ticket) {
        this.codeTicket = res.ticket;
        this.codeRandstr = res.randstr;
        this.postSendCode();
      }
      if (res.errorCode) {
        var _this$codeTencentCapt;
        // eslint-disable-next-line no-undef
        (_this$codeTencentCapt = this.codeTencentCaptcha) === null || _this$codeTencentCapt === void 0 ? void 0 : _this$codeTencentCapt.show();
        (0, _errorLog.postErrorLog)(`tencentCaptchaloadError:{code:${res.errorCode},message:${res.errorMessage}}`);
      }
    },
    tencentCaptchaloadErrorCallback(error) {
      this.$message.warning(this.$t('management.验证码加载失败'));
      (0, _errorLog.postErrorLog)('tencentCaptchaloadError: 验证码js加载失败', error.stack);
    },
    formatSendData() {
      const data = {
        type: this.codeType
      };
      if (!this.noSlider) {
        data.ticket = this.codeTicket;
        data.randstr = this.codeRandstr;
      }
      if (this.sendingDestination === 'tel') {
        data.tel = this.tel;
      }
      if (this.sendingDestination === 'email') {
        data.email = this.email;
      }
      return data;
    },
    postSendCode() {
      if (this.sendLoading) return;
      if (this.isSendIng && this.count !== 0) return;
      const data = this.formatSendData();
      let method;
      if (this.noSlider) {
        if (this.sendingDestination === 'tel') {
          method = _user.sendTelCode;
        } else if (this.sendingDestination === 'email') {
          method = _user.sendEmailCode;
        }
      } else {
        if (this.sendingDestination === 'tel') {
          method = _user.sendTelCodeWithGraphCode;
        } else {
          method = _user.sendEmailCodeWithGraphCode;
        }
      }
      this.sendLoading = true;
      method(data).then(res => {
        if (res.code === 200 || res.code === 20000) {
          this.$message.success(this.$t('management.验证码已发送，请查收'));
          this.sendLoading = false;
          if (!this.timer) {
            this.count = 60;
            this.isSendIng = true;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      }).catch(err => {
        this.sendLoading = false;
        throw new Error(err);
      });
    },
    reset() {
      this.count = 0;
      this.isSendIng = false;
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
exports.default = _default;