"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TaskMenuContent = _interopRequireDefault(require("./components/TaskMenuContent"));
var _CollapseTab = _interopRequireDefault(require("./components/CollapseTab"));
var _taskCenter = require("@/api/taskCenter");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'TaskCenterDrawer',
  components: {
    TaskMenuContent: _TaskMenuContent.default,
    CollapseTab: _CollapseTab.default
  },
  provide() {
    return {
      getActiveManageViewTab: () => this.activeManageViewTab
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    currentTaskDetailData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      menuList: [{
        value: 'mytask',
        label: '我的任务',
        icon: 'icon-receiving'
      }, {
        value: 'mydistribute',
        label: '我分配的',
        icon: 'icon-user-assign'
      }, {
        value: 'complete',
        label: '已完成',
        icon: 'icon-circle-check'
      }],
      activeMenu: '',
      currentTaskDetailId: 0,
      manageViewTabs: [{
        label: '所有任务',
        value: 0
      }, {
        label: '普通任务',
        value: 1
      }, {
        label: '培训任务',
        value: 2
      }, {
        label: '助手任务',
        value: 3
      }],
      activeManageViewTab: null,
      newTagVisible: false
    };
  },
  watch: {
    visible(val) {
      if (!val) return;
      const newTagValue = localStorage.getItem('manageViewNewTag');
      this.newTagVisible = newTagValue === 'true';
      if (Object.keys(this.currentTaskDetailData).length) {
        // 消息中心跳转到任务详情
        this.currentTaskDetailId = this.currentTaskDetailData.id;
        // 消息列表，未完成（task_type ='mytask'）的跳到 我的任务，完成的（task_type ='complete'）跳到已完成
        this.activeMenu = this.currentTaskDetailData.task_type || 'mytask';
      } else {
        this.activeMenu = 'mytask';
      }
      // 查询未完成任务的任务个数
      this.getTodoTaskCount();
    },
    activeManageViewTab: {
      handler: function (val) {
        if (val) {
          this.activeMenu = 'manageView' + val.value;
        }
      },
      deep: true
    }
  },
  mounted() {
    const storageValue = localStorage.getItem('manageViewNewTag');
    if (storageValue !== 'hasKnown') {
      const uptime = this.$moment('2023-01-09');
      const diff = this.$moment().diff(uptime, 'days');
      if (diff < 8) {
        localStorage.setItem('manageViewNewTag', 'true');
      } else {
        localStorage.setItem('manageViewNewTag', 'false');
      }
    }
  },
  methods: {
    clearCurrentTaskDetailId() {
      this.currentTaskDetailId = 0;
    },
    // 点击完成任务和新建任务后重新获取任务总数
    handleTaskCountsChange() {
      this.getTodoTaskCount();
      this.$forceUpdate();
    },
    async getTodoTaskCount() {
      const res = await (0, _taskCenter.getTodoTaskCount)();
      if (res.code === 20000) {
        res.results.count_list.forEach(item => {
          if (item.type) {
            this.menuList[0].count = item.count;
          } else {
            this.menuList[1].count = item.count;
          }
        });
        this.$forceUpdate();
      }
    },
    handleSelect(item) {
      this.$nextTick(() => {
        this.activeMenu = item.value;
        this.activeManageViewTab = null;
        // 获取任务数列表
        this.handleTaskCountsChange();
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.activeMenu = '';
      this.activeManageViewTab = null;
      this.$emit('closeTaskDrawer');
      this.currentTaskDetailId = 0;
    },
    hiddenNewTag() {
      this.newTagVisible = false;
      localStorage.setItem('manageViewNewTag', 'hasKnown');
    }
  }
};
exports.default = _default;