"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "预约会议",
      visible: _vm.visible,
      width: "400px",
      "append-to-body": "",
      "destroy-on-close": ""
    },
    on: {
      close: _vm.closeDialog
    }
  }, [_c("dynamic-form", {
    ref: "form",
    attrs: {
      desc: _vm.desc,
      "label-position": "top"
    },
    scopedSlots: _vm._u([{
      key: "beginTime-label",
      fn: function () {
        return [_c("el-button", {
          staticClass: "set-now-button",
          attrs: {
            type: "text"
          },
          on: {
            click: _vm.setNow
          }
        }, [_vm._v("设为此刻")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c("div", {
    staticClass: "button-row",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;