"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NoPermission',
  data() {
    return {
      title: '',
      tip: [],
      info: ''
    };
  },
  created() {
    this.getEmbedUserStatus(this.$route.query);
  },
  methods: {
    getEmbedUserStatus(queryParams) {
      const {
        code,
        info
      } = queryParams;
      this.info = info;
      switch (code) {
        case '40401':
          this.title = '单点登录身份校验错误';
          this.tip = ['检查到系统参数传输信息有缺失，请联系Megaview技术人员'];
          break;
        case '40402':
          this.title = '单点登录身份校验错误';
          this.tip = ['检查到系统参数传输信息有缺失，请联系Megaview技术人员'];
          break;
        case '40403':
          this.title = '单点登录身份校验错误';
          this.tip = ['请检查您的网络是否已连接，是否存在防火墙问题', '请检查ticket校验接口的畅通性', '若问题还未解决，请联系Megaview技术人员'];
          break;
        case '40404':
          this.title = '用户不存在';
          this.tip = ['未检测到用户信息，请检查您是否注册了Megaview账号', '请检查Megaview登录手机号/用户id是否与业务系统一致', '若问题还未解决，请联系Megaview技术人员'];
          break;
        case '40405':
          this.title = '单点登录身份校验错误';
          this.tip = ['系统未找到您所在的团队，请联系Megaview技术人员'];
          break;
        default:
          break;
      }
    }
  }
};
exports.default = _default;