"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "operate-workspace-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.getDialogTitle,
      visible: _vm.dialogVisible,
      "before-close": _vm.closeDialog
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "operateWorkspaceForm",
    attrs: {
      model: _vm.operateWorkspaceForm,
      "hide-required-asterisk": true,
      rules: _vm.rules
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "名称"
    }
  }, [_c("el-input", {
    staticClass: "name-input",
    attrs: {
      type: "text",
      placeholder: "请输入空间名称",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.operateWorkspaceForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.operateWorkspaceForm, "name", $$v);
      },
      expression: "operateWorkspaceForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "icon_color",
      label: "图标颜色"
    }
  }, [_c("IconColorSelect", {
    attrs: {
      "icon-color": _vm.operateWorkspaceForm.icon_color,
      "predefine-colors": _vm.predefineColors
    },
    on: {
      select: _vm.selectColor
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "description",
      label: "描述"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入空间描述",
      maxlength: "100",
      "show-word-limit": ""
    },
    model: {
      value: _vm.operateWorkspaceForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.operateWorkspaceForm, "description", $$v);
      },
      expression: "operateWorkspaceForm.description"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      loading: _vm.buttonLoading,
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;