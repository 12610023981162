"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "func-privileges"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "成员",
      name: "first"
    }
  }, [_vm.activeName === "first" ? _c("member-card") : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "业务角色",
      name: "second"
    }
  }, [_vm.activeName === "second" ? _c("func-role-card") : _vm._e()], 1)], 1), _c("el-button", {
    staticClass: "import-members-btn",
    attrs: {
      icon: "iconfont icon-import"
    },
    on: {
      click: _vm.goImportPage
    }
  }, [_vm._v("导入成员角色")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;