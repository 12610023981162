"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _download = require("@/utils/download");
var _ExportMixins = _interopRequireDefault(require("../ExportMixins"));
var _PieChart = _interopRequireDefault(require("@/components/Charts/PieChart"));
var _BarChart = _interopRequireDefault(require("@/components/Charts/BarChart"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = {
  name: 'StatementItem',
  components: {
    PieChart: _PieChart.default,
    BarChart: _BarChart.default
  },
  mixins: [_ExportMixins.default],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      subColor: '',
      isSubLoading: false,
      subTitle: '',
      isSubShow: false
    };
  },
  methods: {
    handleEveryPieExport() {
      const count = this.item.data.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0);
      const res = this.item.data.map(item => [item.name, item.value, `${(item.value / count * 100).toFixed(2)}%`]);
      res.unshift([`${this.item.title}关键事件`, `出现次数`, `占比`]); // 添加表头
      res.push(['总计', count, `100%`]);
      const {
        activeTabName,
        departmentName,
        startDate,
        endDate,
        conversationFrom
      } = this.getBiFilter();
      const fileName = `${activeTabName}_${this.item.title}报表_${departmentName}_${startDate}至${endDate}_${conversationFrom}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    handleEveryBarExport() {
      const count = this.item.subData.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0);
      const res = this.item.subData.map(item => [item.name, item.value]);
      res.unshift([`${this.subTitle}关键事件`, `出现次数`]); // 添加表头
      res.push(['总计', count]);
      const {
        departmentName,
        startDate,
        endDate,
        conversationFrom
      } = this.getBiFilter();
      const fileName = `${this.item.title}报表_${this.subTitle}详情_${departmentName}_${startDate}至${endDate}_${conversationFrom}`;
      (0, _download.downloadBlob)(this.handleFileToBuf(res), `${fileName}.csv`);
    },
    async handlePieSelectChange(_ref, index) {
      let {
        fromAction,
        selected
      } = _ref;
      if (fromAction === 'select') {
        this.isSubShow = true;
        setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToRight(1000);
          });
        }, 300);
        this.$store.commit('bi/SET_STATEMENT_SUBDATA', {
          index,
          data: []
        });
        this.isSubLoading = true;
        await this.$store.dispatch('bi/get_statement_sub_data', {
          index,
          id: this.item.data[selected[0].dataIndex[0]].id
        });
        this.isSubLoading = false;
        this.scrollToRight(1000);
      } else {
        this.isSubShow = false;
        this.$store.commit('bi/SET_STATEMENT_SUBDATA', {
          index,
          data: []
        });
      }
    },
    scrollToRight(left) {
      this.$refs['chart-container'].scrollTo({
        left,
        behavior: 'smooth'
      });
    },
    handlePieClick(_ref2) {
      let {
        color,
        name
      } = _ref2;
      this.subTitle = name;
      this.subColor = color;
    }
  }
};
exports.default = _default;